import { DisplayArea, PageType, ComponentType } from '#/analytics/bertie/types';

export const VARIATION_KEY = 'searchContentTile';
export const FEATURE_KEY = 'GHS-<region>_search-content-tile';

export type ExperimentConfig = {
  searchContentTile: string;
  experiment?: string;
  variation?: string;
};

export enum SearchContentTileVariants {
  DISABLED = 'disabled',
  ENABLED = 'enabled',
}

export const SearchContentTileFeature = {
  featureKey: FEATURE_KEY,
  variationKey: VARIATION_KEY,
  useCustomerId: true,
  serviceConfig: {
    params: { [VARIATION_KEY]: SearchContentTileVariants.DISABLED },
  },
};

export const contentTypeName = 'content';
export const buttonText = 'See more';
export const buttonAriaLabel = 'Navigate to ';
export const searchContentAnalytics = {
  displayArea: 'grd' as DisplayArea,
  componentName: 'content tile',
  componentType: 'c' as ComponentType,
  pageType: 'sr' as PageType,
  modulePosition: 1,
};

export const contentCharLimits = {
  heading: 35,
  subText: 45,
  CTAText: 18,
};
