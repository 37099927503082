import PropTypes from 'prop-types';
import { compose } from 'react-recompose';
import React, { PureComponent } from 'react';
import helpers from '#/lib/decorators/helpers';
import Link from '#/components/link-check-spa';
import { getLanguageLink } from '#/reducers/app';
import analyticsBus from '#/analytics/analyticsBus';
import { COLLECTION } from '#/constants/shopping-methods';
import { connect } from '#/lib/render/connect-deep-compare';
import { getTrolleyShoppingMethod } from '#/selectors/trolley';
import { DELAY, COLLECT, SLOT_GRID } from '#/analytics/constants';
import { changeShoppingMethod } from '#/actions/slot-action-creators';
import { basicEvent, TBasicEventProps } from '#/analytics/types/basic';
import { SHOPPING_METHOD_PROP_TYPE } from '#/components/slots/prop-types';

const basicEventProps: TBasicEventProps = {
  action: DELAY,
  type: SLOT_GRID,
  value: COLLECT,
};

const mapStateToProps = (state: any) => ({
  collectionSlotsUrl: getLanguageLink(state, `/slots/${COLLECTION}`),
  currentShoppingMethod: getTrolleyShoppingMethod(state),
});

type TProps = {
  accessibleLabel: string;
  currentShoppingMethod: string;
  changeShoppingMethod: Function;
  t: (key: string) => string;
  slotId: string;
  collectionSlotsUrl: string;
  onKeyPressed: Function;
};

class UnavailableHDSlotWithCollectLink extends PureComponent<TProps> {
  static propTypes = {
    accessibleLabel: PropTypes.string.isRequired,
    changeShoppingMethod: PropTypes.func.isRequired,
    currentShoppingMethod: SHOPPING_METHOD_PROP_TYPE.isRequired,
    slotId: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
  };

  handleCollectLinkClick = () => {
    const { changeShoppingMethod, currentShoppingMethod } = this.props;

    basicEvent(analyticsBus, basicEventProps);
    changeShoppingMethod(COLLECTION, currentShoppingMethod);
  };

  renderCollectLink() {
    const { t: translate, collectionSlotsUrl, onKeyPressed, slotId } = this.props;

    return (
      <span className="unavailable-slot-text__cc-link">
        <input type="hidden" name="slotId" value={slotId} />
        <Link to={collectionSlotsUrl} onClick={this.handleCollectLinkClick} onKeyDown={onKeyPressed}>
          {translate('slots:try-click-collect')}
        </Link>
      </span>
    );
  }

  render() {
    const { accessibleLabel, t: translate } = this.props;

    return (
      <span className="unavailable-slot-text unavailable-slot-text--cc-link">
        <span className="visually-hidden">{accessibleLabel}</span>
        <span>{translate('slots:common.unavailable')}</span>
        {this.renderCollectLink()}
      </span>
    );
  }
}

const enhance = compose<TProps, {}>(helpers(['t']), connect(mapStateToProps, { changeShoppingMethod }));

export default enhance(UnavailableHDSlotWithCollectLink);
