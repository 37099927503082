import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { WidgetContainer } from '../widget-container';
import { SlidePicker } from './slide-picker';
import { Slide } from './slide';
import { Swiper } from '../swiper';

class ResponsiveSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSlide: 0
    };
    this.selectSlide = slideIndex => {
      this.setState({ activeSlide: slideIndex });
    };

    this.swipeLeft = this.swipeLeft.bind(this);
    this.swipeRight = this.swipeRight.bind(this);
  }

  swipeRight() {
    const currentSlide = this.state.activeSlide;

    if (currentSlide > 0) {
      this.selectSlide(currentSlide - 1);
    }
  }

  swipeLeft() {
    const currentSlide = this.state.activeSlide;

    if (currentSlide < this.props.slides.length - 1) {
      this.selectSlide(currentSlide + 1);
    }
  }

  render() {
    const isActive = slideIndex => this.state.activeSlide === slideIndex;
    const justOneSlide = this.props.slides.length === 1;
    const sliderStyles = classnames('ui-responsive-slider', {
      'just-one-slide': justOneSlide
    });

    return (
      <WidgetContainer widgetName="responsive-slider">
        <Swiper onSwipeLeft={this.swipeLeft} onSwipeRight={this.swipeRight}>
          <div className={sliderStyles}>
            {this.props.slides.map((slide, i) => (
              <style key={`style${i}`}>{`
                #ui-responsive-slider--${i}:checked ~ .ui-responsive-slider--slides .slide-${i} {
                    z-index: 2;
                    display: block;
                }
                #ui-responsive-slider--${i}:not(:checked) ~ .ui-responsive-slider--slide-picker .ui-responsive-slide-tab--${i} {
                  background-color: white;
                  color: #00539f;
                }
                #ui-responsive-slider--${i}:checked ~ .ui-responsive-slider--slide-picker .ui-responsive-slide-tab--${i} .slide-pointer{
                  display: block;
                }
                `}</style>
            ))}
            {this.props.slides.map((slide, i) => {
              const boundSelectSlide = this.selectSlide.bind(null, i);

              return (
                <input
                  key={`radio${i}`}
                  type="radio"
                  checked={isActive(i)}
                  className="ui-responsive-slider-radio"
                  id={`ui-responsive-slider--${i}`}
                  name="ui-responsive-slider"
                  onChange={boundSelectSlide}
                />
              );
            })}
            <SlidePicker
              slides={this.props.slides}
              onHover={this.selectSlide}
            />
            <div className="ui-responsive-slider--slides">
              {this.props.slides.map((slide, i) => (
                <Slide
                  key={`slide${i}`}
                  index={i}
                  isActive={isActive(i)}
                  onClick={this.props.componentClickHandler}
                  catId={this.props.catId}
                  {...slide}
                  ruleId={this.props.ruleId}
                />
              ))}
            </div>
          </div>
        </Swiper>
      </WidgetContainer>
    );
  }
}

ResponsiveSlider.propTypes = {
  catId: PropTypes.string.isRequired, // need by analytics
  componentClickHandler: PropTypes.func,
  ruleId: PropTypes.string,
  slides: PropTypes.array.isRequired
};

export { ResponsiveSlider };
