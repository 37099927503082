import {
  SINGLE_VALUE_MESSAGE,
  DOUBLE_VALUE_MESSAGE,
  REGULAR_PRICE_CLUBCARD
} from '#/constants/dcs';
import {
  VARIANT_HORIZONTAL,
  VARIANT_SQUARE
} from '#/components/cms-driven/value-tile/value-tile-const';
import { sanitiseBooleans, cloneSimpleObject } from '#/utils/misc';
import { includesRegularPrice } from '#/lib/dcs/dcs-helpers';
import { getValueTileTemplate } from '#/lib/dcs/adapter/value-tile-templates';
import { getDynamicFontSize } from '#/lib/dcs/adapter/value-tile-templates/get-font-size';
import formatNumber from '#/lib/string-formatting/number-formatter';

export const getValuesForTemplate = (
  valueTileType,
  { value1, value2, currencySymbol, unitSymbol }
) => {
  switch (valueTileType) {
    case SINGLE_VALUE_MESSAGE:
      return [value1, currencySymbol, unitSymbol];
    case DOUBLE_VALUE_MESSAGE:
      return [value1, value2, currencySymbol];
  }
};

export const convertValueTileItems = (
  data,
  currencySymbol,
  unitSymbol,
  region,
  isSquareVariant = false
) => {
  const {
    is_asterisk,
    is_flat_tile,
    value1,
    value2,
    products = null,
    is_similar_price = false,
    regularPrice = null
  } = data;

  let { valueTileType, valueTileColor, template_type = '' } = data;

  const hasRegularPrice = includesRegularPrice(data);

  const valueTileTemplates = !!hasRegularPrice
    ? getValueTileTemplate(REGULAR_PRICE_CLUBCARD, region)
    : getValueTileTemplate(valueTileType, region);

  const isFlatTile = sanitiseBooleans(is_flat_tile);
  const hasEachPrice = sanitiseBooleans(is_similar_price);

  const getTileVariant = shouldRenderSquareVariant =>
    shouldRenderSquareVariant ? VARIANT_SQUARE : VARIANT_HORIZONTAL;

  const shouldRenderSquareVariant = isSquareVariant || !isFlatTile;
  const tileVariant = getTileVariant(shouldRenderSquareVariant);

  if (!!hasRegularPrice) {
    let templateSuffix = shouldRenderSquareVariant ? '_square' : '_rect';
    templateSuffix += hasEachPrice ? '_each' : '';

    template_type += templateSuffix;
  }

  const valueTileTemplate =
    valueTileTemplates &&
    template_type &&
    valueTileTemplates[template_type.toLowerCase()];

  if (!valueTileTemplate) return;
  const templateType = cloneSimpleObject(valueTileTemplate);

  templateType.isFlatTile = isFlatTile;

  if (!!hasRegularPrice) {
    const regularPriceValue = formatNumber(
      regularPrice || products[0]?.price?.actual
    );

    const setRegularPriceText = (text, values) => {
      let regularText = text;

      values.forEach((value, i) => {
        regularText = regularText?.replace(`%{value${i + 1}}`, value);
      });

      return regularText;
    };

    const valuesForTemplate = getValuesForTemplate(SINGLE_VALUE_MESSAGE, {
      value1: regularPriceValue,
      value2: null,
      currencySymbol,
      unitSymbol
    });

    const regularPriceValueText = setRegularPriceText(
      templateType.regularPrice?.valueContent?.[1]?.text,
      valuesForTemplate
    );

    templateType.hasRegularPrice = true;
    templateType.hasEachPrice = hasEachPrice;
    templateType.regularPrice = {
      ...templateType.regularPrice,
      values: valuesForTemplate,
      regularPriceValueText
    };

    templateType.regularPrice.valueContent.map(lineItem => {
      const itemText = lineItem.text;
      const itemSize = lineItem.size;
      const regularPriceValue1 = templateType.regularPrice.values[0];
      const regularPriceValue2 = templateType.regularPrice.values[1];
      if (itemText) {
        if (typeof itemSize !== 'string') {
          const fontSize = getDynamicFontSize(
            itemText,
            itemSize,
            regularPriceValue1,
            regularPriceValue2,
            true
          );
          if (fontSize) {
            lineItem.size = fontSize;
          }
        }
      }
    });
  }

  const values = getValuesForTemplate(valueTileType, {
    value1,
    value2,
    currencySymbol,
    unitSymbol
  });

  if (values) {
    templateType.values = values;
  }

  const valueContent =
    templateType[tileVariant]?.valueContent || templateType.valueContent;

  templateType.valueContent = valueContent?.map(lineItem => {
    if (lineItem.text) {
      if (typeof lineItem.size !== 'string') {
        const fontSize = getDynamicFontSize(
          lineItem.text,
          lineItem.size,
          value1,
          value2
        );
        if (fontSize) {
          lineItem.size = fontSize;
        }
      }
    }
    return lineItem;
  });

  const isAsterisk = sanitiseBooleans(is_asterisk);
  if (isAsterisk) {
    templateType.isAsterisk = sanitiseBooleans(is_asterisk);
  }

  templateType.valueTileColor = valueTileColor;

  return templateType;
};
