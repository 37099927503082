import { ZONE_PAGE, APP_STATE } from '#/constants/spa-resource';

export const type = ZONE_PAGE;
export const params = [
  'pageName',
  'preview',
  'isAuthenticated',
  'isRegistered',
  'isFirstTimeBuyer'
];
export const dependencies = [APP_STATE];
