import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from '#/lib/render/connect-deep-compare';
import MenuTree from './menu-tree';
import NavItem from './nav-item';
import { toggleNavMenu } from '#/actions/ui-action-creators';
import {
  getFullTaxonomy,
  resetTaxonomy
} from '#/actions/taxonomy-action-creators';
import { getPlatformFromTaxonomyState } from '#/utils/render-utils';
import helpers from '#/lib/decorators/helpers';
import { getSelectedNavigationTab } from '#/reducers/taxonomy';
import { getIsAmendBasket, getIsOnDemandDelivery } from '#/selectors/trolley';
import { getDeviceType } from '#/reducers/app';
import FavNavTooltipContainer from '#/experiments/oop-1263/components';
import { getShowFavNavLinkTooltip } from '#/experiments/oop-1263/selectors';
import { getCMSNavResource } from '#/selectors/resources';
import { getFavChildMenu } from '#/experiments/oop-1716/helpers/tab-helper';
import FavMenuTree from '#/experiments/oop-1716/components/navigation/menu-tree/fav-menu-tree';
import { getShouldShowFavDropdown } from '#/experiments/oop-1716/selectors';
import { getIsGroceriesMenuOpen } from '#/selectors/ui';
import {
  getIsFavWithDropdownNav,
  updateFavTab
} from '#/experiments/oop-1716/helpers/helpers';
import { getIsFavsMenuOpen } from '#/experiments/oop-1716/selectors/ui';
import { MY_FAVORITES } from '#/constants/favorites';
import { GROCERIES } from '#/constants/ui';
import {
  getIsInspireMeTaxonomyNav,
  getIsInspireMeZonePageNav,
  getIsInspireMeMenuOpen
} from '#/experiments/oop-1890/helpers';
import {
  getIsZonePageVariant,
  getIsTaxonomyVariant
} from '#/experiments/oop-1890/selectors';

import {
  getIsMealsAndRecipeMenuKey,
  getIsMealsAndRecipeMenuOpen,
  updateMealsRecipeTab
} from '#/experiments/oop-1946/helpers';
import { getShouldShowMealsAndRecipes } from '#/experiments/oop-1946/selectors';
import PrimaryNavigation from '../primary-navigation';

const mapStateToProps = (state, { c: config, cmsNav, t: translate }) => {
  const isOnDemandDelivery = getIsOnDemandDelivery(state);
  const tabs = config('bigNav');

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { promotions, ...nonPromotionTabs } = tabs;
  const cmsNavResource = isOnDemandDelivery && getCMSNavResource(state);
  const cmsNavData = cmsNavResource?.data || cmsNav;
  const shouldShowFavDropdown = getShouldShowFavDropdown(state);
  const favMenuOpen = getIsFavsMenuOpen(state);

  let favChildMenu;

  if (favMenuOpen && shouldShowFavDropdown) {
    favChildMenu = getFavChildMenu(state, { config, translate });
  }

  return {
    isAmendMode: getIsAmendBasket(state),
    isOnDemandDelivery,
    deviceType: getDeviceType(state),
    menuOpen: getIsGroceriesMenuOpen(state),
    inspireMeMenuOpen: getIsInspireMeMenuOpen(state),
    isMealsAndRecipeOpen: getIsMealsAndRecipeMenuOpen(state),
    taxonomyState: state.taxonomy.taxonomyState,
    selectedTab: getSelectedNavigationTab(state, tabs, cmsNavData),
    tabs: isOnDemandDelivery ? nonPromotionTabs : tabs,
    renderFavTooltip: getShowFavNavLinkTooltip(state),
    favChildMenu,
    favMenuOpen,
    shouldShowFavDropdown,
    shouldShowInspireMeAsLink: getIsZonePageVariant(state),
    shouldShowInspireMeAsTaxonomy: getIsTaxonomyVariant(state),
    cmsNav: cmsNavData,
    isMealsAndRecipeEnabled: getShouldShowMealsAndRecipes(state)
  };
};

@helpers(['c', 't'])
@connect(mapStateToProps, {
  toggleNavMenu,
  resetTaxonomy,
  getFullTaxonomy
})
export default class Navigation extends Component {
  static propTypes = {
    cmsNav: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    deviceType: PropTypes.string.isRequired,
    getFullTaxonomy: PropTypes.func.isRequired,
    groceryTabInOpenState: PropTypes.bool,
    isAmendMode: PropTypes.bool,
    menuOpen: PropTypes.bool.isRequired,
    noOfPendingOrders: PropTypes.number,
    renderFavTooltip: PropTypes.bool.isRequired,
    resetTaxonomy: PropTypes.func.isRequired,
    selectedTab: PropTypes.object,
    t: PropTypes.func.isRequired,
    tabs: PropTypes.object.isRequired,
    taxonomyState: PropTypes.object.isRequired,
    toggleNavMenu: PropTypes.func.isRequired,
    favMenuOpen: PropTypes.bool,
    favChildMenu: PropTypes.array,
    shouldShowFavDropdown: PropTypes.bool,
    shouldShowInspireMeAsLink: PropTypes.bool,
    shouldShowInspireMeAsTaxonomy: PropTypes.bool
  };

  static contextTypes = {
    router: PropTypes.object.isRequired
  };

  handleNavItemEvent(navItem, event) {
    if (navItem.activate && this.props[navItem.activate]) {
      event.preventDefault();
      this.props.resetTaxonomy();
      this.props[navItem.activate](navItem.label);
    }
  }

  handleNavItemClick = navItem => event => {
    this.handleNavItemEvent(navItem, event);
  };

  handleNavItemKeyDown = navItem => event => {
    if (event.key === 'ArrowDown') {
      this.handleNavItemEvent(navItem, event);
    }
  };

  renderMenuTree(
    openStateInLargeView,
    tab,
    isGroceriesTab,
    isInspireMeMenuTab,
    isMealsAndRecipeTab
  ) {
    if (isGroceriesTab || isInspireMeMenuTab || isMealsAndRecipeTab) {
      const {
        taxonomyState,
        deviceType,
        menuOpen,
        inspireMeMenuOpen,
        isMealsAndRecipeOpen,
        isMealsAndRecipeEnabled,
        isAmendMode,
        resetTaxonomy
      } = this.props;
      const open =
        (isGroceriesTab && menuOpen) ||
        (isInspireMeMenuTab && inspireMeMenuOpen) ||
        (isMealsAndRecipeTab && isMealsAndRecipeOpen);
      return (
        <MenuTree
          taxonomyState={getPlatformFromTaxonomyState(
            taxonomyState,
            deviceType
          )}
          open={open}
          isGroceriesMenu={isGroceriesTab}
          isInspireMeMenu={isInspireMeMenuTab}
          isMealsAndRecipeMenu={isMealsAndRecipeEnabled && isMealsAndRecipeTab}
          openStateInLargeView={openStateInLargeView}
          isAmendMode={isAmendMode}
          resetTaxonomy={resetTaxonomy}
        />
      );
    }

    if (tab?.childMenu) return <FavMenuTree childMenu={tab?.childMenu} />;

    return null;
  }

  componentDidMount() {
    if (!this.props.taxonomyState.isTaxonomyLazyLoadedNow) {
      this.props.getFullTaxonomy();
    }
  }

  getIsGroceriesNav = key => key === GROCERIES;

  getIsFavNav = key => key === MY_FAVORITES;

  shouldRenderInspireMe = key => {
    const {
      shouldShowInspireMeAsLink,
      shouldShowInspireMeAsTaxonomy
    } = this.props;
    const isInspireMeZonePageNav = getIsInspireMeZonePageNav(key);
    const isInspireMeTaxonomyNav = getIsInspireMeTaxonomyNav(key);

    if (isInspireMeZonePageNav && !shouldShowInspireMeAsLink) return false;
    if (isInspireMeTaxonomyNav && !shouldShowInspireMeAsTaxonomy) return false;

    return true;
  };

  renderNavItems() {
    const {
      renderFavTooltip,
      tabs,
      groceryTabInOpenState,
      selectedTab,
      cmsNav,
      favChildMenu,
      menuOpen,
      favMenuOpen,
      shouldShowFavDropdown,
      inspireMeMenuOpen,
      isMealsAndRecipeOpen,
      isMealsAndRecipeEnabled
    } = this.props;

    updateMealsRecipeTab(tabs, isMealsAndRecipeEnabled);
    return Object.keys(tabs).map((key, index) => {
      const shouldRenderInspireMe = this.shouldRenderInspireMe(key);
      if (!shouldRenderInspireMe) {
        return null;
      }

      let tab = tabs[key];
      let active = tab === selectedTab;

      const isGroceriesTab = this.getIsGroceriesNav(key);
      const isFavWithDropdownNav = getIsFavWithDropdownNav(key);
      const isMealsAndRecipeTab = getIsMealsAndRecipeMenuKey(key);
      const isInspireMeMenuTab = getIsInspireMeTaxonomyNav(key);
      const isFavNav = this.getIsFavNav(key);

      const openStateInLargeView =
        isGroceriesTab &&
        groceryTabInOpenState &&
        !favMenuOpen &&
        !inspireMeMenuOpen &&
        !isMealsAndRecipeOpen;

      if (shouldShowFavDropdown && isFavNav) return null;
      if (!shouldShowFavDropdown && isFavWithDropdownNav) return null;

      const extraProps = {
        ...(isFavNav &&
          renderFavTooltip && {
            renderTooltip: this.getTooltipContent,
            tooltipContainerId: 'fav-nav-link'
          }),
        ...(isFavWithDropdownNav && { open: favMenuOpen }),
        ...(isInspireMeMenuTab && { open: inspireMeMenuOpen }),
        ...(isMealsAndRecipeTab && { open: isMealsAndRecipeOpen })
      };

      if (shouldShowFavDropdown && isFavWithDropdownNav) {
        tab = updateFavTab(tab, favChildMenu);
        active = tab?.url === selectedTab?.url;
      }

      return (
        <NavItem
          active={active}
          chevron={tab.chevron}
          cmsNav={cmsNav}
          configurable={tab.configurable}
          feature={tab.feature}
          key={index}
          navItemKey={key}
          navItem={tab}
          onClick={this.handleNavItemClick(tab)}
          onKeyDown={this.handleNavItemKeyDown(tab)}
          open={menuOpen}
          openStateInLargeView={openStateInLargeView}
          preventSpa={!!tab.activate || !!tab.chevron}
          menuTree={this.renderMenuTree(
            openStateInLargeView,
            tab,
            isGroceriesTab,
            isInspireMeMenuTab,
            isMealsAndRecipeTab
          )}
          {...extraProps}
        />
      );
    });
  }

  getTooltipContent = (domRef, boundingRef) => (
    <FavNavTooltipContainer
      id="fav-nav-link-tooltip"
      targetRef={domRef}
      boundingRef={boundingRef}
      tooltipRootID="fav-nav-link"
      observerId="content"
    />
  );

  render() {
    const { t: translate, c: config, cmsNav } = this.props;
    const isGlobalHeader = config('isGlobalHeader');
    if (isGlobalHeader) {
      return (
        <PrimaryNavigation
          pathName={this.context.router?.location?.pathname}
          cmsNav={cmsNav}
        />
      );
    } else {
      return (
        <nav
          className="navigation navigation--right-aligned"
          data-auto="navigation"
          aria-label={translate('navigation:grocery-navigation')}
        >
          <ul
            className={classnames('navigation__nav-items', {
              'hide-groceries': config('enableHorizontalTaxanomy')
            })}
            role="menubar"
            aria-label={translate('navigation:primary-navigation')}
          >
            {this.renderNavItems()}
          </ul>
        </nav>
      );
    }
  }
}
