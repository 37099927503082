import React from 'react';
import moment from 'moment-timezone';
import { getLanguage, getTimezone } from '#/reducers/app';
import { connect } from 'react-redux';
import helpers from '#/lib/decorators/helpers';
import { VisuallyHidden } from '#/components/shared/styled';
import { formatTimeFromConfig } from './utils';

interface IProps {
  startTime: moment.Moment | string;
  endTime: moment.Moment | string;
  timezone: string;
  language: string;
  c: (value: string) => string;
  t: (key: string) => string;
}

interface IMapStateToPropsReturn {
  language: string;
  timezone: string;
}

const mapStateToProps = (state: Store): IMapStateToPropsReturn => ({
  language: getLanguage(state),
  timezone: getTimezone(state),
});

const FormattedSlotTime = (props: IProps): JSX.Element => {
  const { startTime, endTime, timezone, language, c: config, t: translate } = props;

  const startMomentObject = startTime instanceof moment ? (startTime as moment.Moment) : moment(startTime);
  const endMomentObject = endTime instanceof moment ? (endTime as moment.Moment) : moment(endTime);

  const startTimeString = formatTimeFromConfig(startMomentObject, timezone, language, config);
  const endTimeString = formatTimeFromConfig(endMomentObject, timezone, language, config);

  const to = translate('slots:common.time-interval.time-to');
  const until = translate('slots:common.time-interval.time-until');
  const accessibleTimeInterval = `${startTimeString} ${to} ${endTimeString} ${until}`;

  return (
    <>
      <VisuallyHidden data-visually-hidden>{accessibleTimeInterval}</VisuallyHidden>
      <span aria-hidden="true">{`${startTimeString}-${endTimeString}`}</span>
    </>
  );
};

export default connect(mapStateToProps)(helpers(['c', 't'])(FormattedSlotTime));
