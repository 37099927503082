import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from '#/lib/render/connect-deep-compare';
import Link from '../../../link-check-spa';
import { getGuidePrice } from '#/selectors/trolley';
import { getLanguage } from '#/reducers/app';
import BasketSlotContextCardNoSlotBooked from '#/components/trolley/full-trolley/basket-slot-card/no-slot-booked';
import helpers from '#/lib/decorators/helpers';

export const mapStateToProps = state => {
  return {
    guidePrice: getGuidePrice(state),
    language: getLanguage(state)
  };
};

const NoSlotBooked = ({
  buttonLink,
  buttonText,
  introText,
  isAuthenticated,
  onClick,
  className,
  guidePrice,
  language,
  amountChanging,
  displayOnFullBasket = false,
  c: config
}) => {
  const isGlobalHeader = config('isGlobalHeader');
  return displayOnFullBasket ? (
    <BasketSlotContextCardNoSlotBooked
      buttonLink={buttonLink}
      buttonText={buttonText}
      introText={introText}
      onClick={onClick}
      className={className}
      isAuthenticated={isAuthenticated}
      guidePrice={guidePrice}
      language={language}
      amountChanging={amountChanging}
    />
  ) : (
    <div className={`context-cards--no-slot-booked ${className}`}>
      <div className="icon icon-icon_BAS" />
      <div
        className={classnames('context-card-content-container', {
          'context-card-global-header': isGlobalHeader
        })}
      >
        <div className="context-card-copy small">{introText}</div>
        <Link
          className="button button-secondary small"
          to={buttonLink}
          onClick={onClick}
        >
          {buttonText}
        </Link>
      </div>
    </div>
  );
};

NoSlotBooked.propTypes = {
  buttonLink: PropTypes.string.isRequired,
  buttonText: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  displayOnFullBasket: PropTypes.bool,
  introText: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

export default helpers(['c'])(connect(mapStateToProps)(NoSlotBooked));
