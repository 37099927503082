import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from '#/lib/render/connect-deep-compare';
import helpers from '#/lib/decorators/helpers';
import { hasValidSlot, hasItems } from '#/lib/trolley/trolley-utils';
import { isOnFavouritesPage } from '#/lib/favorites-helpers';
import { isOnDemandShoppingMethod } from '#/lib/shopping-method-util';
import { MiniTrolleyProvider } from '#/components/mini-trolley/mini-trolley/mini-trolley-provider';
import { getOptimizelyData } from '#/reducers/experiments';
import { get1426Variant } from '#/experiments/oop-1426/selectors';
import { formatExperiments } from './helpers';

import {
  getCharges,
  getCurrentValidSlot,
  getGuidePrice,
  getIsAmendBasket,
  getTrolleyRequestInProgress,
  getTrolleyShoppingMethod,
  getIsTrolleyUpdating,
  getItems,
  getSubscription,
  hasAtLeastOneItemUnavailable,
  getTotalSavings,
  getBasketMaxItemCountLimit,
  getTrolleyStateAndPendingQty,
  getUpdatingCatchWeightItem,
  getHasBasketBreachedByVolumeOrWeight,
  getBaskets,
  getHasOnlyMarketplaceItems,
  getIsMixedBasket,
  isGroceryOnlySubBasket
} from '#/selectors/trolley';
import {
  getCurrentUrl,
  getCurrency,
  getBrowserType,
  getAppRegion,
  getTimezone
} from '#/reducers/app';
import {
  getIsUserAuthenticated,
  getIsFirstTimeShopper,
  getIsUserRegistered
} from '#/reducers/user';
import { getStickyBarName, getDisplayCookieInterrupt } from '#/reducers/ui';
import { getLanguageLink, getLanguage } from '#/reducers/app';
import { NATIVE_STICKY_EXCEPTIONS_LIST } from '#/constants/sticky-trolley-exceptions';
import { BasketConnector } from '@mfe/basket';
import { IntlProvider } from 'react-intl';
import { StyledMiniTrolley } from './style';
import { getMessagesLocale } from '#/i18n/helpers';
import { ungroupMessages } from '@peas/translation-utils';
import en from '@mfe/basket/en.json';
import cs from '@mfe/basket/cs.json';
import hu from '@mfe/basket/hu.json';
import sk from '@mfe/basket/sk.json';
import keys from '@mfe/basket/keys.json';
import {
  getAmendBannerPositionStyleVariant,
  getOop1882bcdeBottom
} from '#/experiments/oop-1882/selectors';
import { addApmData } from '#/lib/apm';
import { getBasketTypeToNewRelicValue } from '#/utils/basket-type-to-new-relic-value-util';
import { getAttributesFromState } from '#/lib/optimizely-attributes';

const mapStateToProps = (state, ownProps) => {
  const isAuthenticated = getIsUserAuthenticated(state);
  const currentUrl = getCurrentUrl(state);
  const trolleyUrl = getLanguageLink(state, '/trolley');
  const bookedSlot = getCurrentValidSlot(state);
  const items = getItems(state);
  const hasValidSlotFlag = hasValidSlot(bookedSlot);
  const isFirstTimeShopper = getIsFirstTimeShopper(state);
  const displayCookieInterrupt = getDisplayCookieInterrupt(state);
  const { c: config } = ownProps;
  const isTrolleyEmpty = items.length < 1;
  const shoppingMethod = getTrolleyShoppingMethod(state);
  const isOnDemandDelivery = isOnDemandShoppingMethod(shoppingMethod);
  const baskets = getBaskets(state);

  const hasFavMiniTrolleyCTA = config('showMiniTrolleyFavsCTA');

  const showFavMiniTrolleyCTA =
    !isOnDemandDelivery &&
    isTrolleyEmpty &&
    hasValidSlotFlag &&
    !isFirstTimeShopper &&
    !isOnFavouritesPage(currentUrl) &&
    hasFavMiniTrolleyCTA;
  const savings = getTotalSavings(state);
  const hasBasketBreachedByVolumeOrWeight = getHasBasketBreachedByVolumeOrWeight(
    state
  );
  const isTrolleyUpdating = getIsTrolleyUpdating(state);
  const isCheckoutDisabled =
    !hasItems(items) || isTrolleyUpdating || !hasValidSlot || !isAuthenticated;

  return {
    baskets,
    bookedSlot,
    browserType: getBrowserType(state),
    items,
    charges: getCharges(state),
    currency: getCurrency(state),
    language: getLanguage(state),
    region: getAppRegion(state),
    currentUrl,
    guidePrice: getGuidePrice(state),
    hasItems: hasItems(items),
    hasValidSlot: hasValidSlotFlag,
    isAmendBasket: getIsAmendBasket(state),
    isAuthenticated,
    stickyBarName: getStickyBarName(state),
    isTrolleyLoading: getTrolleyRequestInProgress(state),
    isTrolleyUpdating,
    isRegistered: getIsUserRegistered(state),
    savings,
    subscription: getSubscription(state),
    showFavMiniTrolleyCTA,
    hasAtLeastOneItemUnavailable: hasAtLeastOneItemUnavailable(state),
    displayCookieInterrupt,
    isTrolleyEmpty,
    shoppingMethod,
    basketMaxItemCountLimit: getBasketMaxItemCountLimit(state),
    pendingQty: getTrolleyStateAndPendingQty(state).pendingQty,
    updatingCatchWeightItem: getUpdatingCatchWeightItem(state),
    hasBasketBreachedByVolumeOrWeight,
    trolleyUrl,
    groceriesUrl: getLanguageLink(state, ''), // pass an empty path as otherwise it defaults to '/'
    isCheckoutDisabled,
    timeZone: getTimezone(state),
    amendBannerVariant: getAmendBannerPositionStyleVariant(state),
    isMixedBasket: getIsMixedBasket(state),
    isMarketplaceOnlyBasket: getHasOnlyMarketplaceItems(state),
    isGroceriesOnlyBasket: isGroceryOnlySubBasket(state),
    experiments: getOptimizelyData(state),
    get1426Variant: get1426Variant(state),
    optimizelyAttributes: getAttributesFromState(state, false, {})
  };
};

@helpers(['t', 'c', 'f'])
@connect(mapStateToProps)
export default class MiniTrolley extends Component {
  constructor(props, context) {
    super(props, context);

    const browserType = props.browserType;
    this.state = {
      position: false,
      positionStickyAllowed: !NATIVE_STICKY_EXCEPTIONS_LIST.includes(
        browserType
      )
    };
  }

  static propTypes = {
    basketMaxItemCountLimit: PropTypes.number,
    baskets: PropTypes.arrayOf(PropTypes.object).isRequired,
    bookedSlot: PropTypes.object,
    browserType: PropTypes.string.isRequired,
    c: PropTypes.func.isRequired,
    charges: PropTypes.shape({
      bagCharges: PropTypes.number,
      minimumBasketValue: PropTypes.number,
      surcharge: PropTypes.number
    }).isRequired,
    currency: PropTypes.object.isRequired,
    currentUrl: PropTypes.string.isRequired,
    f: PropTypes.func.isRequired,
    groceriesUrl: PropTypes.string,
    guidePrice: PropTypes.number.isRequired,
    hasAtLeastOneItemUnavailable: PropTypes.bool,
    hasItems: PropTypes.bool,
    hasValidSlot: PropTypes.bool,
    isAmendBasket: PropTypes.bool.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    isRegistered: PropTypes.bool.isRequired,
    isSlotPage: PropTypes.bool,
    isStickyTrolley: PropTypes.bool.isRequired,
    isTrolleyEmpty: PropTypes.bool.isRequired,
    isTrolleyLoading: PropTypes.bool.isRequired,
    isTrolleyUpdating: PropTypes.bool.isRequired,
    items: PropTypes.array.isRequired,
    language: PropTypes.string,
    region: PropTypes.string,
    savings: PropTypes.number,
    shoppingMethod: PropTypes.string,
    showFavMiniTrolleyCTA: PropTypes.bool.isRequired,
    stickyBarName: PropTypes.string,
    subscription: PropTypes.object,
    t: PropTypes.func.isRequired,
    experiments: PropTypes.object,
    optimizelyAttributes: PropTypes.object
  };

  componentDidMount() {
    addApmData(
      'basketType',
      getBasketTypeToNewRelicValue(
        this.props.isGroceriesOnlyBasket,
        this.props.isMarketplaceOnlyBasket,
        this.props.isMixedBasket
      )
    );

    const { positionStickyAllowed } = this.state;

    const hasStickySupport =
      window.CSS && window.CSS.supports
        ? window.CSS.supports('position', 'sticky') ||
          window.CSS.supports('position', '-webkit-sticky')
        : false;
    this.usePositionSticky = hasStickySupport && positionStickyAllowed;

    if (!this.usePositionSticky && this.props.isStickyTrolley) {
      this.calcHeights();
      this.handleScroll();
      window.addEventListener('scroll', this.handleScroll);
      window.addEventListener('resize', this.calcHeights);
    }
  }

  componentDidUpdate() {
    if (!this.usePositionSticky && this.props.isStickyTrolley) {
      this.calcHeights();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.calcHeights);
  }

  calcHeights = () => {
    const stickyBar = document.querySelector('.sticky-bar');
    const main = document.querySelector('.main');
    const footer = document.querySelector('.footer-wrapper');

    this.stickyBarHeight = stickyBar
      ? stickyBar.getBoundingClientRect().height
      : 0;
    this.fixedTopPos = this.props.stickyBarName ? this.stickyBarHeight : 0;
    this.mainTop = main ? main.offsetTop : 0;
    this.winHeight = window.innerHeight;
    this.footerTop = footer ? footer.offsetTop : 0;
  };

  handleScroll = () => {
    const mainScrollPos = this.mainTop - window.pageYOffset;

    if (mainScrollPos > this.fixedTopPos) {
      //header is in view
      this.setState({ position: false });
    } else if (window.pageYOffset + this.winHeight > this.footerTop) {
      //footer in view
      this.setState({ position: 'bottom' });
    } else {
      //neither header or footer in view
      this.setState({ position: 'fixed' });
    }
  };

  render() {
    const {
      isAmendBasket,
      isStickyTrolley,
      isTrolleyEmpty,
      stickyBarName,
      displayCookieInterrupt,
      c: config,
      language,
      region,
      groceriesUrl,
      isAuthenticated,
      currentUrl,
      timeZone,
      isGroceriesOnlyBasket,
      isMarketplaceOnlyBasket,
      isMixedBasket,
      get1426Variant,
      experiments,
      optimizelyAttributes
    } = this.props;
    const { position, positionStickyAllowed } = this.state;
    const isCookieBanner =
      config('cookiePreferences') && displayCookieInterrupt;

    let langMap;
    try {
      langMap = {
        ...ungroupMessages('en', ['GB', 'CZ', 'HU', 'IE', 'SK'], en, { keys }),
        ...ungroupMessages('cs', ['CZ'], cs, { keys }),
        ...ungroupMessages('hu', ['HU'], hu, { keys }),
        ...ungroupMessages('sk', ['SK'], sk, { keys })
      };
    } catch (e) {
      console.error(e.message);
    }

    addApmData(
      'basketType',
      getBasketTypeToNewRelicValue(
        isGroceriesOnlyBasket,
        isMarketplaceOnlyBasket,
        isMixedBasket
      )
    );

    const messagesLocale = getMessagesLocale(language, region);

    const formattedExperiments = formatExperiments(
      experiments,
      optimizelyAttributes,
      {
        'ghs_uk_minibasket-v4': get1426Variant
      }
    );

    return (
      <MiniTrolleyProvider>
        <StyledMiniTrolley
          ref={el => (this.miniTrolley = el)}
          id="mini-trolley"
          className={classnames('mini-trolley', {
            'mini-trolley--native-sticky':
              positionStickyAllowed && isStickyTrolley,
            'mini-trolley--sticky': isStickyTrolley,
            'mini-trolley--sticky-bar': isStickyTrolley && stickyBarName,
            'mini-trolley--fixed': position === 'fixed',
            'mini-trolley--bottom': position === 'bottom',
            'mini-trolley--oop1882-bottom': getOop1882bcdeBottom(
              this.props.amendBannerVariant
            ),
            'mini-trolley--cookie-banner': isCookieBanner && !isAmendBasket,
            'mini-trolley--cookie-amend-banner':
              isCookieBanner && isAmendBasket,
            'mini-trolley--split-basket': isTrolleyEmpty
          })}
        >
          <IntlProvider
            messages={langMap[messagesLocale]}
            locale={language}
            timeZone={timeZone}
          >
            <BasketConnector
              groceriesUrl={groceriesUrl}
              locale={language}
              region={region}
              isAuthenticated={isAuthenticated}
              currentPath={currentUrl}
              formattedExperiments={formattedExperiments}
            />
          </IntlProvider>
        </StyledMiniTrolley>
      </MiniTrolleyProvider>
    );
  }
}
