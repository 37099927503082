import { isOnDemandShoppingMethod, isOnDemandDeliveryShoppingMethod } from '#/lib/shopping-method-util';
import { hasCollectionPointsWithPickup } from '#/utils/order-utils';
import { PlainObject } from '#/types';
import { DiscountTypes } from '#/constants/trolley';
import { DiscountCategory } from '#/lib/records/trolley.defs';

function getOrder(state: PlainObject): PlainObject {
  return state && state.order && state.order.order;
}

export const getOrderWorkingDaysForDelivery = (state: PlainObject): number | null => {
  const order = getOrder(state);

  return order?.slot?.countdown?.workingDays || null;
};

export const getDeliveryDate = (state: PlainObject): string | null => {
  const order = getOrder(state);
  return (order && order.deliveryDate) || null;
};

export const getOrderDate = (state: PlainObject): string => {
  const order = getOrder(state);
  return (order && order.orderDate) || null;
};

export const getIsOrderForUnmannedCC = ({ order: { collectionPoints } }: PlainObject): boolean =>
  collectionPoints?.some(hasCollectionPointsWithPickup);

export const getIsOnDemandOrder = ({ order: { order } }: PlainObject): boolean =>
  (order && isOnDemandShoppingMethod(order.shoppingMethod)) || false;

export const getIsOnDemandDeliveryOrder = ({ order: { order } }: PlainObject): boolean =>
  (order && isOnDemandDeliveryShoppingMethod(order.shoppingMethod)) || false;

export const getTotalSavingsForOrder = ({ discounts }: PlainObject): number =>
  discounts?.categories?.find((category: DiscountCategory) => category?.type === DiscountTypes.PROMOTIONS_TOTAL)
    ?.value || 0;
