/** ***************************************************************
 * Link.js - adds any language/country codes in front of link
 * e.g for en-GB ('/test' will become '/en-GB/test')
 * Any other links that do not require this will not be changed.
 * e.g for mailto:test@test.com - will remain unchanged
 * Optimised not to call `url.parse` always
 * But if we need to, we can pass third parameter as false
 ****************************************************************/

import url from 'url';
import { isBasePathInPath, isLanguageInPath } from '#/utils/render-utils';

export default function(
  language,
  port,
  groceryDomain,
  basePath,
  protocol = 'https'
) {
  if (typeof basePath !== 'string' || basePath === '') {
    throw 'basePath must be set';
  }

  return function(path = '/', site, relative = true) {
    if (path === null) {
      console.warn(new Error('path value cannot be null').stack); // eslint-disable-line no-console
      path = '/';
    }

    if (path.includes('mailto:') || path.includes('tel:')) {
      return path;
    }

    if (
      relative &&
      path[0] === '/' &&
      path[1] !== '/' &&
      !path.startsWith(`/${basePath}/`) &&
      !path.startsWith(`/${language}`)
    ) {
      return `/${basePath}/${language}${path}`;
    }

    const uri = url.parse(path);

    if (uri.protocol === null) {
      uri.pathname = uri.pathname || '';

      if (uri.pathname.charAt(0) !== '/') {
        uri.pathname = `/${uri.pathname}`;
      }

      if (!isLanguageInPath(basePath, uri.pathname)) {
        uri.pathname = `/${language}${uri.pathname}`;
      }

      if (!isBasePathInPath(basePath, uri.pathname)) {
        uri.pathname = `/${basePath}${uri.pathname}`;
      }
    }

    if (site) {
      uri.port = port;
      uri.slashes = true;
      uri.hostname = groceryDomain;
      uri.protocol = protocol;
    }

    return url.format(uri);
  };
}
