import React from 'react';
import PropTypes from 'prop-types';

const PickerNotes = ({ ariaLabel, children, label, onClick }) => (
  <div className="tile-content--lower-left picker-note">
    <button className="tile-content--lower-note-input" onClick={onClick}>
      <span className="icon-edit_pencil hidden-small" />
      <span className="instructions-label-container-cell instructions-label tile-content--note">
        {label}
        <span className="visually-hidden">{ariaLabel}</span>
      </span>
    </button>
    {children}
  </div>
);

PickerNotes.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default PickerNotes;
