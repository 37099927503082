export const VARIATION_KEY = 'ibyc_logic';
export const STRATEGY_VARIATION_KEY = 'ibyc_strategy';
export const FEATURE_KEY = 'GHS-<region>_trex-ibyc';

export enum IBYCCarouselVariants {
  FIRST_PRODUCT_SHOWN = 'first-product-shown',
  LAST_BASKET_ADD = 'last-basket-add',
  DISABLED = 'disabled',
}

export const IBYCCarouselFeature = {
  featureKey: FEATURE_KEY,
  variationKey: VARIATION_KEY,
  useCustomerId: true,
};

export const SEARCH_URL = '/search';
export const SHOP_URL = '/shop';
export const TOGGLE_RECS_CAROUSEL = 'TOGGLE_RECS_CAROUSEL';
export const UPDATE_RECS_CAROUSEL_DETAILS = 'UPDATE_RECS_CAROUSEL_DETAILS';
export const RECS_CAROUSEL_DISPLAY_THRESHOLD = 3;

//session key
export const BASKET_ADD_COUNT_RECS_CAROUSEL = 'basketAddCountForRecs';

//Config key
export const SEARCH_RECS_CONFIG_KEY = 'searchIBYC2349';
export const CATEGORY_RECS_CONFIG_KEY = 'categoryIBYC2349';

//Locale keys
export const IBYC_LOCALE_PREFIX = 'recommendations:oop-2349';

//analytics
export const IBYC_RECOMMENDATION_CAROUSEL_TYPE = 'sugProduct IBYC';
export const IBYC_IDENTIFIER = 'recommender:trex:IBYC';

//To render the recs carousel based on the viewport and number of items per row
export const BREAKPOINT_1512 = 1512;
export const BREAKPOINT_1184 = 1184;
export const BREAKPOINT_992 = 992;
export const BREAKPOINT_504 = 504;
export const ITEMS_PER_ROW_LARGE_DESKTOP = 5;
export const ITEMS_PER_ROW_DESKTOP = 4;
export const ITEMS_PER_ROW_TABLET = 3;
export const ITEMS_PER_ROW_MOBILE_LARGE = 2;
export const ITEMS_PER_ROW_MOBILE = 1;
