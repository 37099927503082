import { Trolley, Discounts, Charges, ClubcardPoints, PropositionalInfo } from '#/lib/records/trolley.defs';
import { Item } from '#/lib/records/item';

export enum BasketTypes {
  MARKETPLACE = 'MPBasketSummary',
  GROCERY = 'GHSBasketSummary',
  IGHS_GROCERY = 'BasketSummary',
}

// this shape has changed in the splitView basket
// at some point this will also be changed at the root
// fields are being renamed in our graphql query also to fit this new schema
export type ChargesV2 = Omit<Charges, 'packaging'> & {
  packaging: { charge: number };
};

export type SplitView = {
  id?: string;
  typename: BasketTypes;
  guidePrice: number;
  totalPrice: number;
  totalItems: number;
  items: Item[];
  charges: ChargesV2;
  discounts: Discounts;
  clubcardPoints: ClubcardPoints;
  propositionalInfo: PropositionalInfo[];
};

export type SplitViewBasket = Omit<SplitView, 'charges'> & {
  charges: Charges;
};

export type SplitViewBaskets = SplitView[];

export type SplitViewBasketState = SplitViewBasket[];

// the shape of the response and what we want to store in state is a little different
export type TrolleyMangoResponse = Omit<Trolley, 'splitView'> & {
  splitView: SplitView[];
};

// make split view charges compatible with root basket charges
const transformCharges = (charges: ChargesV2): Charges => {
  return {
    ...charges,
    packaging: charges.packaging?.charge,
  };
};

export const transformSplitBaskets = (trolley: TrolleyMangoResponse): SplitViewBasketState | undefined => {
  return trolley?.splitView?.reduce<SplitViewBasketState>((acc, basket) => {
    const { charges, ...restBasket } = basket;

    return [
      ...acc,
      {
        ...restBasket,
        charges: transformCharges(charges || {}),
      },
    ];
  }, []);
};
