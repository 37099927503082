import React from 'react';
import GridView from '#/components/products/product-list/grid-view';
import { GridViewProps } from '#/components/products/product-list/types/index.defs';
import { GRID, LIST } from '#/constants/favorites';

interface LayoutTypeProp {
  groupName: string;
  layoutType: typeof LIST | typeof GRID;
  listItems: Array<BannerAndList>;
}

type BannerAndList = {
  interruptBanners: Array<JSX.Element>;
  items: Array<JSX.Element>;
};

type ContainerProps = LayoutTypeProp & GridViewProps;

const reduceListToBannersAndProductItems = (listItems: Array<BannerAndList>): BannerAndList => {
  const list: BannerAndList = {
    interruptBanners: [],
    items: [],
  };
  return listItems.reduce((acc: BannerAndList, item: BannerAndList) => {
    acc.interruptBanners = [...acc.interruptBanners, ...item.interruptBanners];
    acc.items = [...acc.items, ...item.items];
    return acc;
  }, list);
};

export const Container = ({
  classes,
  dataAuto,
  dataAutoLastUpdated,
  listItems,
  groupName,
}: ContainerProps): JSX.Element => {
  const { interruptBanners, items } = reduceListToBannersAndProductItems(listItems);

  const componentProps = { classes, dataAuto, dataAutoLastUpdated, items, interruptBanners, groupName };

  return <GridView {...componentProps} />;
};

export default Container;
