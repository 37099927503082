import React from 'react';
import styled from 'styled-components';
import Icon from '@ddsweb/icon';
import { spacing } from '@ddsweb/selectors';

type ChevronProps = {
  newWindow: boolean;
  strokeColor?: string;
};

type CTATextWithChevron = ChevronProps & {
  textLabel: string;
};

const Wrapper = styled.div`
  display: flex;
`;

const StyledIcon = styled(Icon)`
  margin: 0 1px 0 ${spacing.xs};
`;

const TileChevron: React.FC<ChevronProps> = ({ newWindow, strokeColor }): JSX.Element => {
  const graphicType = newWindow ? 'externalLink' : 'forwardLink';
  return <StyledIcon graphic={graphicType} stroke={strokeColor} size="sm" />;
};

const CTATextWithChevron: React.FC<CTATextWithChevron> = ({ textLabel, newWindow, strokeColor }): JSX.Element => (
  <Wrapper>
    <div>{textLabel}</div>
    <TileChevron newWindow={newWindow} strokeColor={strokeColor} />
  </Wrapper>
);
export { CTATextWithChevron };
