import { DOWN_ARROW, ESCAPE, TAB, UP_ARROW } from '#/constants/keycodes';
import {
  FREE_TEXT,
  ICID,
  LANDING,
  PREDICT_NEXT,
  REFERRAL,
  STORAGE_KEY,
  SUGGESTED,
  emitMultiSearchEvent,
  storeDataForAnalytics
} from '#/analytics/types/search';
import {
  POPULAR,
  TITLE_POPULAR,
  TITLE_TRENDING
} from '#/experiments/oop-1742/constants';
import React, { createRef, PureComponent } from 'react';
import {
  clearSuggestions,
  setSearchTerm,
  setSuggestionsVisibility,
  updateSuggestions
} from '#/actions/search-action-creators';
import {
  getIsCurrentPage,
  getIsDesktop,
  getIsMobile,
  getLanguageLink,
  getMfeRolloutConfig
} from '#/reducers/app';
import {
  getIsPrediction,
  getSearchTerm,
  getSearchedTerm,
  getSuggestions,
  getSuggestionsVisibility
} from '#/reducers/search';
import { TITLE_PREDICTIVE_NEXT } from '#/experiments/oop-1824/constants';

import Button from '@ddsweb/button';
import { SearchIcon } from '@ddsweb/icon';
import { RIGHT } from '@ddsweb/constants';
import Link from '#/components/link';
import PropTypes, { object } from 'prop-types';
import SafeForm from '../../../shared/safe-form';
import SearchInput from '@ddsweb/search-input';
import SelectableList from '../../../shared/selectable-list';
import { areMultiSearchResults } from '#/selectors/results/are-multi-search-results';
import classnames from 'classnames';
import { clearTrolleyUserHistory } from '#/actions/trolley/trolley-action-creators';
import { connect } from '#/lib/render/connect-deep-compare';
import {
  getHeaderMultiSearchLinkVisibility,
  isHeaderSearchOpen
} from '#/reducers/ui';
import { getPredictiveSearchConfig } from '#/experiments/oop-1742/selectors';
import helpers from '#/lib/decorators/helpers';
import { safeJsonParse } from '#/utils/json-utils';
import { setMultiSearchLinkVisibility } from '#/actions/ui-action-creators';
import { triggerSearchInteractionAnalyticsEvent } from '#/experiments/oop-1742/helpers/analytics';
import { trimMultipleSpaces } from '#/lib/string-formatting/format';
import { updateParamsInUrl } from '#/lib/url/url-utils';
import MultiSearchTooltipContainer from '#/experiments/oop-1824/components/mult-search-tooltip-container';
import {
  getIsTooltipEnabled,
  getShouldShowImprovedMultiSearch,
  getShouldShowPredictiveHeading,
  getShouldShowTooltip,
  isMultiSearchLinkVisible,
  updateMultiSearchLinkVisibility
} from '#/experiments/oop-1824/selectors';
import { getShouldShowRelatedSearchInDropdown } from '#/experiments/oop-1826/selectors';
import {
  RELATED_SEARCH_HEADING,
  RELATED_SEARCH_TYPE
} from '#/experiments/oop-1826/constants';
import { getMovingSearchBarVariant } from '#/experiments/oop-1972/selectors';
import { getIsMovingSearchBarCenterWide } from '#/experiments/oop-1972/helpers';
import { getSearchContentConfig } from '#/experiments/oop-2002/selectors';
import {
  triggerSearchContentLinkAnalyticsEvent,
  triggerSearchContentLinkVisibleAnalyticsEvent
} from '#/experiments/oop-2002/helpers/analytics';
import {
  CONTENT_LINK_SEARCH,
  CONTENT_LINK_CLICK
} from '#/experiments/oop-2002/constants';

import { DELAY } from '#/analytics/constants';
import { isMFERouteAvailable } from '#/routes/spa-routes/spa-helpers';
import { SEARCH } from '#/constants/spa-resource';

const mapStateToProps = (state, { c: config }) => {
  const isMultiSearch = areMultiSearchResults(state);
  const enablePredictiveSearch = !!config('search:enablePredictiveSearch');
  const { searchContentLink, searchContentEnable } = getSearchContentConfig(
    state
  );
  const {
    isPredictiveSearch = false,
    predictiveSearchList = [],
    predictiveSuggestionType
  } = enablePredictiveSearch ? getPredictiveSearchConfig(state) : {};

  return {
    enableCancelButton: !!config('search:enableCancelButton'),
    enablePredictiveSearch,
    isDesktop: getIsDesktop(state),
    isMobile: getIsMobile(state),
    isPrediction: getIsPrediction(state),
    isPredictiveSearch,
    isSearchPage: getIsCurrentPage(state, ['/search']),
    isHomePage: getIsCurrentPage(state, ['/', '', '/preview']),
    multiSearchLinkUrl: getLanguageLink(state, '/multi-search'),
    multiSearchLinkVisible: getHeaderMultiSearchLinkVisibility(state),
    searchedTerm: !isMultiSearch ? getSearchedTerm(state) : '',
    searchTerm: getSearchTerm(state),
    searchIsVisible: isHeaderSearchOpen(state),
    searchUrl: getLanguageLink(state, '/search'),
    showImprovedMultiSearch: getShouldShowImprovedMultiSearch(state),
    improvedMultiSearchEnabled: getShouldShowTooltip(state),
    suggestions: getSuggestions(state),
    suggestionsEnabled: !!config('search:suggestionsUrl'),
    predictiveSearchList,
    searchContentLink,
    searchContentEnable,
    predictiveSuggestionType,
    suggestionsVisible: getSuggestionsVisibility(state),
    isPredictiveNextHeadingEnabled: getShouldShowPredictiveHeading(state),
    isTooltipEnabled: getIsTooltipEnabled(state),
    movingSearchBarVariant: getMovingSearchBarVariant(state),
    isRelatedSearchEnabled: getShouldShowRelatedSearchInDropdown(state),
    isSearchLeftAligned: config('isSearchLeftAligned'),
    legoTeamNumber: config('search:legoTeamNumber'),
    mfeRolloutConfig: getMfeRolloutConfig(state)
  };
};

@helpers(['c', 'f', 't'])
@connect(mapStateToProps, {
  clearSuggestions,
  setMultiSearchLinkVisibility,
  setSearchTerm,
  setSuggestionsVisibility,
  updateSuggestions,
  clearTrolleyUserHistory
})
export default class SearchBar extends PureComponent {
  static propTypes = {
    clearSuggestions: PropTypes.func.isRequired,
    clearTrolleyUserHistory: PropTypes.func.isRequired,
    enableCancelButton: PropTypes.bool.isRequired,
    enablePredictiveSearch: PropTypes.bool.isRequired,
    f: PropTypes.func.isRequired,
    isDesktop: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
    isPrediction: PropTypes.bool,
    isPredictiveSearch: PropTypes.bool.isRequired,
    isSearchPage: PropTypes.bool.isRequired,
    isHomePage: PropTypes.bool.isRequired,
    isTooltipEnabled: PropTypes.bool.isRequired,
    multiSearchLinkUrl: PropTypes.string.isRequired,
    multiSearchLinkVisible: PropTypes.bool.isRequired,
    predictiveSearchList: PropTypes.array,
    searchContentLink: PropTypes.object,
    searchContentEnable: PropTypes.bool,
    predictiveSuggestionType: PropTypes.string,
    searchConfig: PropTypes.string,
    searchedTerm: PropTypes.string,
    searchTerm: PropTypes.string.isRequired,
    searchUrl: PropTypes.string.isRequired,
    setMultiSearchLinkVisibility: PropTypes.func.isRequired,
    setSearchTerm: PropTypes.func.isRequired,
    setSuggestionsVisibility: PropTypes.func.isRequired,
    showImprovedMultiSearch: PropTypes.bool.isRequired,
    improvedMultiSearchEnabled: PropTypes.bool.isRequired,
    suggestions: PropTypes.array.isRequired,
    suggestionsEnabled: PropTypes.bool.isRequired,
    suggestionsVisible: PropTypes.bool,
    t: PropTypes.func.isRequired,
    updateSuggestions: PropTypes.func.isRequired,
    mfeRolloutConfig: object
  };

  static defaultProps = {
    isPrediction: false,
    isPredictiveSearch: false,
    searchedTerm: ''
  };

  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props, context) {
    super(props, context);
    const {
      f,
      t: translate,
      showImprovedMultiSearch,
      improvedMultiSearchEnabled
    } = props;

    this.targetRef = createRef();
    this.boundingRef = createRef();
    this.buttonRef = createRef();

    this.isSuggestionSelected = false;
    this.showRelatedSearch = false;
    this.state = {
      populateSearchTerm: false,
      selectedSuggestionIndex: -1,
      typedInputValue: '',
      selectionDisabled: false,
      selectedSuggestionTerm: '',
      showPredictiveSearch: false,
      showSearchContent: false,
      showMultiSearchLink: improvedMultiSearchEnabled
    };
    this.setInputRef = this.setInputRef.bind(this);
    this.handleClearButtonClick = this.handleClearButtonClick.bind(this);
    this.handleCancelButtonClick = this.handleCancelButtonClick.bind(this);
    this.handleInputBlur = this.handleInputBlur.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputFocus = this.handleInputFocus.bind(this);
    this.handleInputKeyDown = this.handleInputKeyDown.bind(this);
    this.handleSuggestionChange = this.handleSuggestionChange.bind(this);
    this.handleSuggestionReset = this.handleSuggestionReset.bind(this);
    this.handleSuggestionSelected = this.handleSuggestionSelected.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.handleRouteChange = this.handleRouteChange.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleMSLinkClick = this.handleMSLinkClick.bind(this);
    this.multiSearchEnabled = f('multiSearchEnabled');
    this.text = {
      cancelButtonText: translate('common:cancel'),
      multiSearchLinkText: showImprovedMultiSearch
        ? translate('search:multisearch-redesign-link-text')
        : translate('search:multisearch-link-text'),
      searchLabel: translate('layout:header.search')
    };
  }

  componentDidMount() {
    this.checkAnalyticsExists();

    if (this.context.router) {
      this.removeRouteChange = this.context.router.listenBefore(
        this.handleRouteChange
      );
    }
  }

  componentWillUnmount() {
    clearTimeout(this.multisearchVisibilityTimeout);

    if (this.removeRouteChange) {
      this.removeRouteChange();
    }

    if (this.listenerAdded) {
      this.removeMouseMoveListener();
    }
  }

  handleRouteChange(newRoute) {
    if (newRoute.pathname === this.props.searchUrl) {
      this.props.setSearchTerm(newRoute.query.query);
    } else {
      this.reset();
    }
    if (this.clearSuggestionsOnSPAChange) {
      this.props.clearTrolleyUserHistory();
    }
  }

  handleMouseMove(event) {
    // Some legacy IE browsers triggers `mousemove` event even when mouse is not moved,
    // I believe it is caused by slight movement on the page when the dropdown is re-rendered.
    // Detect minimum movement before updating state
    if (
      Math.abs(this.clientX - event.clientX) > 10 ||
      Math.abs(this.clientY - event.clientY) > 10
    ) {
      this.clientX = event.clientX;
      this.clientY = event.clientY;
      this.setState({ selectionDisabled: false });
    }
  }

  handleInputFocus(event) {
    const {
      isDesktop,
      isMobile,
      isPredictiveSearch,
      isRelatedSearchEnabled,
      predictiveSuggestionType,
      searchedTerm,
      updateSuggestions,
      searchContentEnable,
      legoTeamNumber
    } = this.props;

    const trimmedInputValue = trimMultipleSpaces(event.target.value);
    this.showRelatedSearch = false;
    if (!isMobile) {
      const inputMatchesSearchedTerm = trimmedInputValue === searchedTerm;

      if (inputMatchesSearchedTerm) {
        event.target.select();
      }
    }

    if (this.props.suggestionsEnabled) {
      this.props.setSuggestionsVisibility(true);

      if (!trimmedInputValue) {
        this.setState({ showPredictiveSearch: true, showSearchContent: true });
        updateSuggestions(null, true);
      }
      if (trimmedInputValue && isRelatedSearchEnabled) {
        this.showRelatedSearch = true;
        updateSuggestions(
          trimmedInputValue,
          false,
          isRelatedSearchEnabled,
          legoTeamNumber
        );
      }

      if (isDesktop && !this.listenerAdded) {
        this.addMouseMoveListener();
      }
      this.setSuggestionsVisibilityFlag(trimmedInputValue, true);
    }

    if (this.multiSearchEnabled && isMobile) {
      clearTimeout(this.multisearchVisibilityTimeout);
      this.props.setMultiSearchLinkVisibility(true);
    }
    this.setMultiSearchLinkVisibility(false);
    isPredictiveSearch &&
      triggerSearchInteractionAnalyticsEvent(
        predictiveSuggestionType,
        isPredictiveSearch
      );
    if (searchContentEnable) {
      triggerSearchContentLinkAnalyticsEvent(CONTENT_LINK_SEARCH);
      this.isSearchContentVisible(true) &&
        triggerSearchContentLinkVisibleAnalyticsEvent();
    }
  }

  handleInputChange(e) {
    const { value } = e.target;
    this.setState({
      populateSearchTerm: true,
      typedInputValue: value,
      selectionDisabled: this.props.isDesktop,
      showPredictiveSearch: value?.length === 0 ? true : false,
      showSearchContent: value?.length === 0
    });

    this.showRelatedSearch = false;
    this.props.setSearchTerm(e.target.value);

    if (this.props.isMobile && this.multiSearchEnabled) {
      clearTimeout(this.multisearchVisibilityTimeout);
      this.props.setMultiSearchLinkVisibility(true);
    }

    if (this.props.suggestionsEnabled) {
      this.setState({ selectedSuggestionIndex: -1 });
      this.props.updateSuggestions(
        trimMultipleSpaces(e.target.value),
        true,
        false,
        this.props.legoTeamNumber
      );
      this.setSuggestionsVisibilityFlag(e.target.value, true);
    }
  }

  handleInputBlur() {
    const { isMobile, suggestionsEnabled } = this.props;

    if (this.listenerAdded) {
      this.removeMouseMoveListener();
    }
    this.setMultiSearchLinkVisibility(true);
    if (suggestionsEnabled) {
      if (isMobile) {
        this.multisearchVisibilityTimeout = setTimeout(() => {
          this.props.setMultiSearchLinkVisibility(false);
        }, 10);
      }

      // only close the suggestions panel after all suggestions event handlers have run
      // otherwise, touchscreen users can't scroll and click a suggestion
      this.props.setSuggestionsVisibility(false);
      this.setState({ showPredictiveSearch: false });
      if (this.state.showSearchContent) {
        this.setState({ showSearchContent: false });
      }
      this.handleSuggestionReset();
    }
  }

  handleInputKeyDown(e) {
    if (!this.props.suggestionsEnabled) {
      return;
    }

    switch (e.keyCode) {
      case UP_ARROW:
        e.preventDefault();
        this.changeSelectedIndex(-1);
        break;
      case DOWN_ARROW:
        e.preventDefault();
        this.changeSelectedIndex(1);
        break;
      case TAB:
        this.props.setSuggestionsVisibility(false);
        break;
      case ESCAPE:
        this.reset();
        break;
      default:
        break;
    }
  }

  handleSuggestionReset() {
    if (!this.isSuggestionSelected) {
      this.setState({
        selectedSuggestionIndex: -1,
        selectedSuggestionTerm: ''
      });

      if (this.state.populateSearchTerm) {
        this.props.setSearchTerm(this.state.typedInputValue);
      }
    }
  }

  handleSuggestionChange(index, suggestion) {
    const { isPredictiveSearch, setSearchTerm } = this.props;
    const { typedInputValue } = this.state;
    const isSearchVisible = this.isSearchContentVisible();
    this.setState({ selectedSuggestionIndex: index });

    if (
      !this.isSuggestionSelected &&
      !this.showRelatedSearch &&
      !isSearchVisible
    ) {
      setSearchTerm(
        index >= 0 && !isPredictiveSearch ? suggestion : typedInputValue
      );
    }
  }

  handleSuggestionSelected(index, suggestion) {
    const {
      isPredictiveSearch,
      isRelatedSearchEnabled,
      setSearchTerm
    } = this.props;
    this.isSuggestionSelected = true;
    const isSearchContentVisible = this.isSearchContentVisible();
    if (
      isSearchContentVisible &&
      this.context &&
      this.context.router &&
      suggestion.url
    ) {
      this.context.router.push(suggestion.url);
      this.props.setSearchTerm('');
      triggerSearchContentLinkAnalyticsEvent(CONTENT_LINK_CLICK, DELAY);
      this.buttonRef?.current?.focus();
      return;
    }
    isPredictiveSearch
      ? this.setState({ selectedSuggestionTerm: suggestion })
      : setSearchTerm(suggestion);

    const isRelatedSearch = isRelatedSearchEnabled && this.showRelatedSearch;

    this.setState({ selectedSuggestionIndex: index }, () => {
      this.buttonRef?.current?.focus();

      let termSelectionMethod = SUGGESTED;
      if (this.props.isPrediction) {
        termSelectionMethod = PREDICT_NEXT;
      } else if (isRelatedSearch) {
        termSelectionMethod = RELATED_SEARCH_TYPE;
      }

      this.setAnalyticsData(termSelectionMethod);

      this.goToResultsPage(suggestion);
    });
  }

  setMultiSearchLinkVisibility = isVisible => {
    const { isHomePage, isMobile, improvedMultiSearchEnabled } = this.props;
    const improvedSearchParam = {
      isHomePage,
      improvedMultiSearchEnabled,
      isMobile
    };

    updateMultiSearchLinkVisibility(improvedSearchParam) &&
      this.setState({ showMultiSearchLink: isVisible });
  };

  setAnalyticsData(termSelectionMethod) {
    const {
      props: {
        isPredictiveSearch,
        predictiveSearchList,
        predictiveSuggestionType,
        searchTerm,
        suggestions
      },
      state: {
        typedInputValue,
        selectedSuggestionIndex,
        selectedSuggestionTerm
      }
    } = this;

    let suggestionLength = suggestions?.length;
    let searchType = termSelectionMethod;
    let updatedSearchTerm = searchTerm;

    if (isPredictiveSearch) {
      suggestionLength = predictiveSearchList.length;
      searchType = predictiveSuggestionType;
      updatedSearchTerm = selectedSuggestionTerm;
    }

    const options = {
      searchTermTyped: typedInputValue,
      searchTerm: updatedSearchTerm,
      termSelectionMethod: searchType,
      suggestions: suggestionLength,
      selectedSuggestionIndex: selectedSuggestionIndex + 1
    };

    storeDataForAnalytics(searchType, options);
  }

  getUpdatedSearchTerm = trimmedInputValue => {
    const { isPredictiveSearch } = this.props;
    const { selectedSuggestionTerm } = this.state;
    const isSearchContent = this.isSearchContentVisible();
    if (isPredictiveSearch || this.showRelatedSearch || isSearchContent) {
      return selectedSuggestionTerm;
    }
    return trimmedInputValue;
  };

  handleOnSubmit(e) {
    const { isPredictiveSearch, searchTerm, setSearchTerm } = this.props;
    const { selectedSuggestionIndex } = this.state;

    e.preventDefault();
    const isSearchContent = this.isSearchContentVisible();
    const searchTermValue =
      isSearchContent && searchTerm.name ? searchTerm.name : searchTerm;
    const trimmedInputValue = trimMultipleSpaces(searchTermValue);

    if (!trimmedInputValue && !isPredictiveSearch && !isSearchContent) {
      return;
    }

    if (selectedSuggestionIndex > -1) {
      const updatedTerm = this.getUpdatedSearchTerm(trimmedInputValue);
      this.handleSuggestionSelected(selectedSuggestionIndex, updatedTerm);
      isSearchContent &&
        triggerSearchContentLinkAnalyticsEvent(CONTENT_LINK_CLICK, DELAY);
      return;
    }

    if (trimmedInputValue) {
      setSearchTerm(trimmedInputValue);
      this.buttonRef?.current?.focus();

      this.setState(
        {
          typedInputValue: trimmedInputValue,
          selectedSuggestionIndex: -1
        },
        () => {
          this.setAnalyticsData(FREE_TEXT);
          this.goToResultsPage(trimmedInputValue);
        }
      );
    }
  }

  handleClearButtonClick() {
    const {
      setSearchTerm,
      setSuggestionsVisibility,
      updateSuggestions
    } = this.props;
    this.setState({
      populateSearchTerm: false,
      typedInputValue: ''
    });
    setSearchTerm('');
    updateSuggestions('');
    setSuggestionsVisibility(false);
  }

  handleCancelButtonClick() {
    this.props.setSuggestionsVisibility(false);
    this.blurSearchInput();
  }

  handleMSLinkClick() {
    emitMultiSearchEvent('start');
  }

  addMouseMoveListener() {
    this.listenerAdded = true;
    this.clientX = 0;
    this.clientY = 0;
    document.body.addEventListener('mousemove', this.handleMouseMove);
  }

  removeMouseMoveListener() {
    document.body.removeEventListener('mousemove', this.handleMouseMove);
    this.listenerAdded = false;
    this.clientX = 0;
    this.clientY = 0;
  }

  checkAnalyticsExists() {
    if (!this.state.inputValue || typeof sessionStorage === 'undefined') {
      return;
    }

    const sessionAnalytics = safeJsonParse(sessionStorage[STORAGE_KEY]);
    const currentLocation = this.context.router
      ? this.context.router.getCurrentLocation()
      : {};

    if (sessionAnalytics.searchTerm !== this.state.inputValue) {
      if (currentLocation.query && currentLocation.query[ICID]) {
        this.setAnalyticsData(REFERRAL);
      } else {
        this.setAnalyticsData(LANDING);
      }
    }
  }

  setRef = ele => {
    this.targetRef.current = ele;
  };

  reset() {
    const props = this.props;

    this.setState({
      populateSearchTerm: false,
      selectedSuggestionTerm: '',
      showPredictiveSearch: false,
      showSearchContent: false,
      typedInputValue: ''
    });

    props.setSearchTerm('');
    props.clearSuggestions(new Date());
    props.setMultiSearchLinkVisibility(false);
    props.setSuggestionsVisibility(false);

    this.blurSearchInput();
  }

  getSearchTerm(index) {
    return index >= 0
      ? this.props.suggestions[index]
      : this.state.typedInputValue;
  }

  getSearchLink(searchTerm) {
    const { searchUrl } = this.props;

    const queryStrings = { query: searchTerm };

    return updateParamsInUrl(searchUrl, queryStrings);
  }

  blurSearchInput() {
    if (this.searchInputElement) {
      this.searchInputElement.blur();
    }
  }

  goToResultsPage(searchTerm) {
    this.props.updateSuggestions('');
    this.blurSearchInput();
    const searchResultsPath = this.getSearchLink(searchTerm);

    if (
      this.context &&
      this.context.router &&
      !isMFERouteAvailable(this.props.mfeRolloutConfig, SEARCH)
    ) {
      this.context.router.push(searchResultsPath);
    } else {
      window.location.assign(searchResultsPath);
    }
  }

  getSuggestionLength = () => {
    const { showPredictiveSearch } = this.state;
    const {
      predictiveSearchList,
      suggestions,
      isPredictiveSearch,
      searchContentLink
    } = this.props;
    const isSearchContentVisible = this.isSearchContentVisible();
    if (isPredictiveSearch && showPredictiveSearch) {
      return predictiveSearchList?.length;
    }
    if (isSearchContentVisible) {
      return searchContentLink?.contentLinks?.length;
    }
    return suggestions?.length;
  };

  getNewSuggestionIndex(change) {
    const { selectedSuggestionIndex } = this.state;
    const suggestionLength = this.getSuggestionLength();
    const newIndex = selectedSuggestionIndex + change;

    if (newIndex < -1) {
      return suggestionLength - 1;
    } else if (newIndex >= suggestionLength) {
      return -1; // -1 means the input box is selected
    }
    return newIndex;
  }

  changeSelectedIndex(change) {
    const {
      isPredictiveSearch,
      predictiveSearchList,
      setSearchTerm,
      searchContentLink
    } = this.props;
    const newIndex = this.getNewSuggestionIndex(change);
    const updatedSearchTerm = this.getSearchTerm(newIndex);
    const suggestionTerm = predictiveSearchList[newIndex];

    const isSearchContentVisible = this.isSearchContentVisible();

    if (isPredictiveSearch) {
      this.setState({ selectedSuggestionTerm: suggestionTerm });
    } else if (this.showRelatedSearch) {
      this.setState({ selectedSuggestionTerm: updatedSearchTerm });
    } else if (updatedSearchTerm) {
      setSearchTerm(updatedSearchTerm);
    } else if (isSearchContentVisible) {
      this.setState({
        selectedSuggestionTerm: searchContentLink?.contentLinks[newIndex]
      });
    }
    this.setState({ selectedSuggestionIndex: newIndex });
  }

  setInputRef(el) {
    this.searchInputElement = el;
  }

  getTooltipContent = (domRef, boundingRef) => {
    return (
      <MultiSearchTooltipContainer
        id="multi-search-tooltip"
        targetRef={domRef}
        boundingRef={boundingRef}
        tooltipRootID="multisearch-link"
        observerId="content"
      />
    );
  };

  renderMultiSearchLink() {
    const {
      improvedMultiSearchEnabled,
      isHomePage,
      isTooltipEnabled,
      c
    } = this.props;
    const isGlobalHeader = c('isGlobalHeader');
    return (
      <Link
        id="multisearch-link"
        to={this.props.multiSearchLinkUrl}
        className={classnames(
          'search-bar__multi-search-link search-bar__multi-search-link--right-aligned',
          {
            'search-bar__multi-search-link-text':
              improvedMultiSearchEnabled && isHomePage,
            'search-bar__global-header-enabled': isGlobalHeader
          }
        )}
        onClick={this.handleMSLinkClick}
        data-auto="multisearch-link"
        domRef={this.setRef}
      >
        {this.text.multiSearchLinkText}
        {isTooltipEnabled &&
          this.getTooltipContent(this.targetRef, this.boundingRef)}
      </Link>
    );
  }

  setSuggestionsVisibilityFlag(searchTerm, predict) {
    const suggestionsVisibilityFlag =
      searchTerm.trim() !== '' || predict === true;
    this.props.setSuggestionsVisibility(suggestionsVisibilityFlag);
  }

  getSearchInputClassNames = () => {
    const {
      movingSearchBarVariant,
      isSearchLeftAligned,
      c: config
    } = this.props;
    const isSearchCenterMaxWidth = getIsMovingSearchBarCenterWide(
      movingSearchBarVariant
    );
    const isGlobalHeader = config('isGlobalHeader');
    return classnames('search-bar__input--right-aligned', {
      'search-bar__input--global-header-input': isGlobalHeader,
      'search-bar__input--left-aligned': isSearchLeftAligned,
      'search-bar__input--moving-center-aligned': isSearchCenterMaxWidth
    });
  };

  getSearchTitle = () => {
    const {
      searchContentEnable,
      searchContentLink,
      predictiveSuggestionType,
      isRelatedSearchEnabled,
      suggestions,
      isPredictiveNextHeadingEnabled
    } = this.props;
    const { showSearchContent } = this.state;
    const isSuggestionsExist = suggestions?.length;

    if (isPredictiveNextHeadingEnabled && isSuggestionsExist) {
      return TITLE_PREDICTIVE_NEXT;
    }
    if (
      isRelatedSearchEnabled &&
      isSuggestionsExist &&
      this.showRelatedSearch
    ) {
      return RELATED_SEARCH_HEADING;
    }
    if (predictiveSuggestionType === POPULAR) {
      return TITLE_POPULAR;
    }
    if (searchContentEnable && showSearchContent && searchContentLink) {
      return searchContentLink.heading;
    }
    return TITLE_TRENDING;
  };

  getSuggestionItem = () => {
    const { showPredictiveSearch } = this.state;
    const {
      searchContentLink,
      predictiveSearchList,
      suggestions,
      isPredictiveSearch
    } = this.props;
    if (this.isSearchContentVisible()) {
      return searchContentLink.contentLinks;
    }
    if (showPredictiveSearch && isPredictiveSearch) {
      return predictiveSearchList;
    }
    return suggestions;
  };

  isSearchContentVisible = (inFocus = false) => {
    const { suggestions, searchContentEnable, searchContentLink } = this.props;
    const { showSearchContent } = this.state;
    const isSuggestionsExist = suggestions?.length;

    return (
      !isSuggestionsExist &&
      (showSearchContent || inFocus) &&
      searchContentEnable &&
      searchContentLink?.contentLinks?.length > 0
    );
  };

  isSearchTitleVisible = () => {
    const { showPredictiveSearch } = this.state;
    const {
      isPredictiveNextHeadingEnabled,
      isPredictiveSearch,
      isRelatedSearchEnabled
    } = this.props;
    const showPredictiveSearchList = showPredictiveSearch && isPredictiveSearch;
    const showRelatedSearch = isRelatedSearchEnabled && this.showRelatedSearch;
    const searchContentVisible = this.isSearchContentVisible();
    return (
      showPredictiveSearchList ||
      isPredictiveNextHeadingEnabled ||
      showRelatedSearch ||
      searchContentVisible
    );
  };

  renderSearchInput(searchTerm, inputId) {
    this.clearSuggestionsOnSPAChange = true;
    const searchInputClassNames = this.getSearchInputClassNames();
    return (
      <SearchInput
        autoCapitalize="none"
        autoComplete="off"
        autoCorrect="off"
        className={searchInputClassNames}
        data-auto="search-input"
        id={inputId}
        name="query"
        onBlur={this.handleInputBlur}
        onChange={e => this.handleInputChange({ target: { value: e.value } })}
        onFocus={this.handleInputFocus}
        onKeyDown={this.handleInputKeyDown}
        placeholder={this.text.searchLabel}
        spellCheck="false"
        type="search"
        value={searchTerm}
      />
    );
  }

  getSearchBarClassName = () => {
    const {
      showImprovedMultiSearch,
      improvedMultiSearchEnabled,
      isHomePage,
      multiSearchLinkVisible,
      searchIsVisible,
      c
    } = this.props;
    const isGlobalHeader = c('isGlobalHeader');

    return classnames('search-bar', {
      'search-bar--global-header-enabled': isGlobalHeader,
      'search-bar--multi-search-enabled':
        !showImprovedMultiSearch && this.multiSearchEnabled,
      'search-bar--multi-search-visible':
        (!isGlobalHeader &&
          searchIsVisible &&
          improvedMultiSearchEnabled &&
          isHomePage) ||
        (multiSearchLinkVisible && !isGlobalHeader),
      'search-bar--improved-multi-search-visible':
        showImprovedMultiSearch &&
        searchIsVisible &&
        (!improvedMultiSearchEnabled || !isHomePage)
    });
  };

  render() {
    const {
      isPredictiveSearch,
      suggestions,
      suggestionsEnabled,
      suggestionsVisible,
      searchTerm = '',
      isHomePage,
      isMobile,
      improvedMultiSearchEnabled,
      movingSearchBarVariant
    } = this.props;

    const { showMultiSearchLink, typedInputValue } = this.state;

    const improvedSearchParam = {
      searchTerm,
      isHomePage,
      improvedMultiSearchEnabled,
      isMobile,
      showMultiSearchLink,
      typedInputValue
    };

    const isSuggestionsExist = suggestions?.length;

    const showPredictiveSearchList =
      (this.state.showPredictiveSearch && isPredictiveSearch) || false;

    const isSearchContentVisible = this.isSearchContentVisible();

    const showSelectableList =
      (suggestionsEnabled && suggestionsVisible && isSuggestionsExist) ||
      showPredictiveSearchList ||
      isSearchContentVisible;

    const searchTitle = this.getSearchTitle();
    const suggestedItems = this.getSuggestionItem();
    const isSearchTitleVisible = this.isSearchTitleVisible();

    const inputId = 'search-input';

    const searchBarClassName = this.getSearchBarClassName();
    const isSearchCenterMaxWidth = getIsMovingSearchBarCenterWide(
      movingSearchBarVariant
    );

    return (
      <div className={searchBarClassName} ref={this.boundingRef}>
        <SafeForm
          action={this.props.searchUrl}
          method="get"
          noValidate={true}
          role="search"
          onSubmit={this.handleOnSubmit}
          id="search-form"
          className={'search-bar--form-container'}
        >
          <label className="obscure" htmlFor={inputId}>
            {this.text.searchLabel}
          </label>

          {this.renderSearchInput(searchTerm, inputId)}

          <Button
            className={classnames('search-bar__submit', {
              'search-bar__center': isSearchCenterMaxWidth
            })}
            type="submit"
            aria-label={this.text.searchLabel}
            domRef={this.buttonRef}
            icon={<SearchIcon iconPosition={RIGHT} />}
          />
        </SafeForm>

        {showSelectableList && (
          <SelectableList
            items={suggestedItems}
            searchTitle={searchTitle}
            showSearchTitle={isSearchTitleVisible}
            onItemSelect={this.handleSuggestionSelected}
            onSelectionChange={this.handleSuggestionChange}
            onSelectionReset={this.handleSuggestionReset}
            selectedIndex={this.state.selectedSuggestionIndex}
            disabled={this.state.selectionDisabled}
            searchBarCenterWide={isSearchCenterMaxWidth}
            isSearchContent={isSearchContentVisible}
          />
        )}
        {this.multiSearchEnabled &&
          isMultiSearchLinkVisible(improvedSearchParam) &&
          this.renderMultiSearchLink()}
      </div>
    );
  }
}
