const VARIATION_KEY = 'newInBrowsePosition';
export const FEATURE_KEY = 'GHS-<region>_new-in-browse';

export type ExperimentConfig = {
  experiment: string;
  variation: string;
  newInBrowsePosition: string;
};

export enum NewInBrowsePositionVariants {
  DISABLED = '',
  TOP_LABEL = 'top-label',
  TOP = 'top',
  TOP_NO_LABEL = 'top-nolabel',
  BOTTOM_LABEL = 'bottom-label',
  BOTTOM_NO_LABEL = 'bottom-nolabel',
  BOTTOM = 'bottom',
}

export const NewInBrowsePositionFeature = {
  featureKey: FEATURE_KEY,
  variationKey: VARIATION_KEY,
  serviceConfig: { params: { [VARIATION_KEY]: NewInBrowsePositionVariants.DISABLED } },
};

export const NEW_BROWSE_QUERYSTRING = 'viewAll=new&new=new';
export const NEW_BROWSE_CAT_ID = '::allwithnew';
