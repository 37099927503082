export enum favRememberPaginationVariants {
  default = 'disabled',
  enabled = 'enabled',
  expandedPagination = 'expandedpagination',
  enabledExpandedPagination = 'enabled-expandedpagination',
}

const variationKey = 'favouritesPaginationRecall';

export const favRememberPaginationConfig = {
  featureKey: 'GHS-<region>_1801_remember-pagination-in-Favourites',
  variationKey: variationKey,
  useCustomerId: true,
};

export const FAV_LAST_STATE_STORAGE = 'FAV_LAST_STATE_STORAGE';
