import analyticsBus from '#/analytics/analyticsBus';
import { basicEvent } from '#/analytics/types/basic';
import { ON_DEMAND } from '#/constants/shopping-methods';

export const emitBasketModalAnalyticsEvent = (action: string, value: string): void =>
  basicEvent(analyticsBus, {
    action: action,
    type: ON_DEMAND,
    value,
  });
