import { TROLLEY_CONTENTS, LAST_ORDER } from '#/constants/spa-resource';
import { getLastOrderResource } from '#/selectors/resources';
import { receiveFavoritesData } from '#/actions/ui-action-creators';
import { updateResults } from '#/actions/results-actions';
import { updateCurrentUrl } from '#/actions/filter-action-creators';
import { Dispatch, GetStore } from '#/custom-typings/redux-store/common';
import { updateLastFulfilledOrder } from '#/actions/order-action-creator';

export const type = LAST_ORDER;
export const params = ['query'];
export const dependencies = [TROLLEY_CONTENTS];

type Options = {
  updateCurrentURL?: string;
};

export function handler(dispatch: Dispatch, getState: GetStore, options: Options = {}): void {
  const state = getState();
  const { data = null } = getLastOrderResource(state) || {};

  if (!data) {
    return;
  }

  const { results, selectedFavoritesTab, orderNo } = data;

  if (selectedFavoritesTab) {
    dispatch(receiveFavoritesData(selectedFavoritesTab));
  }

  const fulfilledOrder = {
    orderNo: orderNo,
    hasLastOrder: !!orderNo,
  };

  dispatch(updateLastFulfilledOrder(fulfilledOrder));

  if (results && results.productItems) {
    dispatch(updateResults(results, type, false));

    if (options.updateCurrentURL) {
      dispatch(updateCurrentUrl(options.updateCurrentURL));
    }
  }
}
