import { ParsedUrlQueryInput } from 'querystring';
import { CHANGE_ROUTE, CLOSE_COOKIE_POLICY, CLOSE_APP_BANNER, CLEAR_REFERER } from '../reducers/app';

export const changeRoute = (url: string, queryStringObject: ParsedUrlQueryInput, showCookiePolicy: boolean) => ({
  type: CHANGE_ROUTE,
  payload: {
    url,
    queryStringObject,
    showCookiePolicy,
  },
});

export const closeCookiePolicy = () => ({
  type: CLOSE_COOKIE_POLICY,
});

export const closeAppBanner = () => ({
  type: CLOSE_APP_BANNER,
});

export const clearReferrer = () => ({
  type: CLEAR_REFERER,
});
