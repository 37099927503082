import querystring from 'querystring';
import { createSelector, defaultMemoize } from 'reselect';
import url from 'url';
import {
  SMARTBANNER_CLOSE,
  SMARTBANNER_INSTALL
} from '../constants/app-banner';
import { IOS, SAFARI } from '../constants/platforms';
import { MOBILE, TABLET, DESKTOP } from '../constants/platforms';
import createLinkFn from '../lib/i18n/link';
import { addFromParamToUrl } from '../lib/auth/login-utils';
import { matchPath } from '../lib/url/url-utils';
import { ENV_DISABLED_TESCO_RECOMMENDATIONS } from '../constants/env-disabled-features';
import { envFeatureDisabled } from '../lib/env-feature-disabled';
import { parseQueryString } from '../lib/url/url-utils';

const RETURN_URL = 'returnUrl';
export const CHANGE_ROUTE = 'app:change-route';
export const CLOSE_COOKIE_POLICY = 'COOKIE_POLICY_CLOSE';
export const CLOSE_APP_BANNER = 'CLOSE_APP_BANNER';
export const CLEAR_REFERER = 'CLEAR_REFERER';

function setCookiePolicyAcceptedValue(newUrl, oldUrl, currentCookieValue) {
  // If the current cookiePolicyAccepted value is true, don't change
  // Else, compare the URLs to check if they have actually changed. If so, then update value to true
  return currentCookieValue || newUrl !== oldUrl;
}

export default (state = {}, action) => {
  switch (action.type) {
    case CHANGE_ROUTE:
      return {
        ...state,
        currentUrl: action.payload.url,
        queryStringObject: action.payload.queryStringObject,
        cookiePolicyAccepted: setCookiePolicyAcceptedValue(
          action.payload.url,
          state.currentUrl,
          state.cookiePolicyAccepted
        )
      };
    case CLOSE_COOKIE_POLICY:
      return {
        ...state,
        cookiePolicyAccepted: true
      };
    case CLOSE_APP_BANNER:
      return {
        ...state,
        displayAppBanner: false
      };
    case CLEAR_REFERER:
      return {
        ...state,
        referer: null
      };
    default:
      return state;
  }
};

export const getDfpEnabled = ({ app }) => app.dfpEnabled || false;

const isIosSafari = app =>
  app?.operatingSystem === IOS && app?.browserType === SAFARI;

export const getDefaultStateFromProps = props => {
  const app = (props && props.app) || null;

  return {
    alternateLanguage: app?.alternateLanguage ?? null,
    displayAppBanner:
      app &&
      !isIosSafari(app) &&
      !(
        app.appBannerCookie[SMARTBANNER_CLOSE] ||
        app.appBannerCookie[SMARTBANNER_INSTALL]
      ),
    appName: app?.appName ?? 'no-name',
    basePath: app?.basePath ?? null,
    buildVersion: app?.buildVersion ?? null,
    consumer: app?.consumer ?? null,
    cookiePolicyAccepted: app?.cookiePolicyAccepted ?? false,
    dfpEnabled: !!app && app.dfpEnabled,
    currency: app?.currency ?? null,
    currentUrl: app?.currentUrl ?? null,
    cutsMustard: app?.cutsMustard ?? null,
    showBrowserWarning: app?.showBrowserWarning ?? null,
    csrfToken: app?.csrfToken ?? '',
    disabledFeatures: app?.disabledFeatures ?? '',
    deviceType: app?.deviceType ?? null,
    externalSecureDomain: app?.externalSecureDomain ?? null,
    externalOneAccountDomain: app?.externalOneAccountDomain ?? null,
    groceryDomain: app?.groceryDomain ?? null,
    iosAppId: app?.iosAppId ?? null,
    language: app?.language ?? null,
    oneCheckoutBaseUrl: app?.oneCheckoutBaseUrl ?? '',
    queryStringObject: app?.queryStringObject ?? null,
    ISORegionCode: app?.ISORegionCode ?? null,
    region: app?.region ?? null,
    timezone: app?.timezone ?? null,
    userAgent: app?.userAgent ?? null,
    browserType: app?.browserType ?? null,
    browserVersion: app?.browserVersion ?? null,
    host: app?.host ?? null,
    protocol: app?.protocol ?? null,
    loginUrl: app?.loginUrl ?? null,
    operatingSystem: app?.operatingSystem ?? null,
    registerUrl: app?.registerUrl ?? null,
    referer: app?.referer ?? null,
    osName: app?.osName ?? null,
    osMajorVersion: app?.osMajorVersion ?? null,
    apigeeMangoEndpoint: app?.apigeeMangoEndpoint ?? null,
    apigeeMangoApiKey: app?.apigeeMangoApiKey ?? null,
    showBaglessPostLiveMessage: app?.showBaglessPostLiveMessage ?? null,
    traceId: app?.traceId ?? null,
    mfeRolloutConfig: app?.mfeRolloutConfig ?? null,
    mfeRolloutDebug: app?.mfeRolloutDebug ?? null
  };
};

export const getBuildVersion = ({ app: { buildVersion } }) => buildVersion;

export const getGroceryDomain = ({ app: { groceryDomain } }) => groceryDomain;

export const getOperatingSystem = ({ app: { operatingSystem } }) =>
  operatingSystem;

export const getAlternateLanguage = ({ app: { alternateLanguage } }) =>
  alternateLanguage;

export const getCsrfToken = ({ app: { csrfToken } }) => csrfToken;

export const getCurrency = ({ app: { currency } }) => currency;

export const getOsName = ({ app: { osName } }) => osName;

export const getOsMajorVersion = ({ app: { osMajorVersion } }) =>
  osMajorVersion;

export const getOsNameMajorVersion = ({ app: { osName, osMajorVersion } }) =>
  osName && osMajorVersion ? `${osName} ${osMajorVersion}` : null;

export const getTrexEnabled = ({ app: { disabledFeatures } }) =>
  !envFeatureDisabled(ENV_DISABLED_TESCO_RECOMMENDATIONS, disabledFeatures);

export const getDisabledFeatures = ({ app: { disabledFeatures } }) =>
  disabledFeatures;

export const getDeviceType = ({ app: { deviceType } }) => deviceType;

export const getBrowserType = ({ app: { browserType } }) => browserType;

export const getBrowserTypeAndVersion = ({
  app: { browserType, browserVersion }
}) => {
  if (browserType && browserVersion) {
    return `${browserType}${browserVersion}`;
  }

  return null;
};

export const getUserAgent = ({ app: { userAgent } }) => userAgent;

export const getLanguage = ({ app: { language } }) => language;

export const getLoginUrl = ({ app: { loginUrl } }) => loginUrl;

export const getHost = ({ app: { host } }) => host;

export const getProtocol = ({ app: { protocol } }) => protocol;

export const getRegisterUrl = ({ app: { registerUrl } }) => registerUrl;

export const getTimezone = ({ app: { timezone } }) => timezone;

export const getHasAcceptedCookiePolicy = ({ app: { cookiePolicyAccepted } }) =>
  cookiePolicyAccepted;

export const getHasAcceptedAppBanner = ({ app: { displayAppBanner } }) =>
  displayAppBanner;

export const getIsCurrentPage = (
  { app: { basePath, currentUrl, language } },
  matchUrls
) => {
  return matchPath(currentUrl, basePath, language, matchUrls);
};

export const getAppName = ({ app: { appName } }) => appName;

export const getAppISORegionCode = ({ app: { ISORegionCode } }) =>
  ISORegionCode;

export const getAppRegion = ({ app: { region } }) => region;

export const getBasePath = ({ app: { basePath } }) => basePath;

export const getCurrentPath = ({ app: { currentUrl, basePath } }) =>
  currentUrl.replace(new RegExp(`^(/${basePath})?/(\\w{2}-\\w{2})`, 'i'), '');

export const getReferer = ({ app: { referer } }) => referer;

export const getExternalSecureDomain = ({ app: { externalSecureDomain } }) =>
  externalSecureDomain;

export const getExternalSecureLink = (
  { app: { externalSecureDomain } },
  path
) => {
  if (!externalSecureDomain || !path) return null;

  return externalSecureDomain.concat(path);
};

export const getExternalOneAccountDomain = ({
  app: { externalOneAccountDomain }
}) => externalOneAccountDomain;

export const getExternalClubcardUrl = (state, config) => {
  const oneAccountDomain = getExternalOneAccountDomain(state);
  const clubCardOptInLink = config(`links:optInClubcard:${getLanguage(state)}`);
  const from = encodeURIComponent(getLanguageLink(state, '', 'grocery'));

  return `${oneAccountDomain}${clubCardOptInLink}?from=${from}`;
};

export const getMyAccountUrl = (state, externalMyAccountUrl) => {
  const { app: { language } = {} } = state;
  if (externalMyAccountUrl && externalMyAccountUrl[language]) {
    return getExternalSecureLink(state, externalMyAccountUrl[language]);
  }
  return getLanguageLink(state, '/account');
};

export const getIosAppId = ({ app: { iosAppId } }) => iosAppId;

export const getRedirectUrl = (
  state,
  pathname = '/',
  isAuthenticated = false,
  queryString
) => {
  const {
    app: { host, loginUrl, protocol }
  } = state;

  if (isAuthenticated) {
    return url.format({ pathname, search: queryString });
  } else {
    const fromValue = url.format({
      protocol,
      host,
      pathname,
      search: queryString
    });

    return addFromParamToUrl(loginUrl, fromValue);
  }
};

const addLanguageLink = (basePath, currentUri, language) => {
  const basePathRegex = new RegExp(`^\/${basePath}(\/)?$`, 'i');

  return basePathRegex.test(currentUri)
    ? currentUri.concat(language)
    : currentUri;
};

const parseHref = defaultMemoize(href => url.parse(href).path);

// TODO: Client side(SPA) may update the currentUrl,
// which we may not be updated in this particular context and it may contain a stale value,
// we may need an alternate fix similar to this https://github.com/reactjs/react-router-redux
export const getCurrentUrl = ({ app: { basePath, currentUrl, language } }) => {
  const currentUri = process.env.CLIENT_SIDE
    ? parseHref(window.location.href)
    : currentUrl;

  return addLanguageLink(basePath, currentUri, language);
};

export const getFullCurrentUrl = ({
  app: { basePath, currentUrl, language, protocol, host }
}) => {
  const currentUri = process.env.CLIENT_SIDE
    ? url.parse(window.location.href).path
    : currentUrl;

  const currentRelativeUrl = addLanguageLink(basePath, currentUri, language);
  return decodeURIComponent(
    url.format({
      host,
      protocol,
      pathname: currentRelativeUrl
    })
  );
};

// Returns the current path, but without query string and hash options (unlike getCurrentUrl,
// which returns query string parameters).
//
// e.g. for http://www.tesco.com/groceries?foo=bar#something
// - getCurrentUrl -> /groceries?foo=bar
// - getCurrentPathname -> /groceries
export const getCurrentPathname = ({
  app: { basePath, currentUrl, language }
}) => {
  const currentUri = process.env.CLIENT_SIDE
    ? url.parse(window.location.href).pathname
    : url.parse(currentUrl).pathname;

  return addLanguageLink(basePath, currentUri, language);
};

export const getQueryFromQueryStringFactory = query =>
  createSelector(
    getCurrentUrl,
    currentUrl => parseQueryString(currentUrl)[query]
  );

export const getCurrentQueryString = createSelector(
  getCurrentUrl,
  currentUrl => url.parse(currentUrl).search || ''
);

export const getCurrentQueryParams = createSelector(
  getCurrentQueryString,
  queryString => querystring.parse(queryString.substr(1))
);

export const getReturnUrlOrCurrentUrl = createSelector(
  getCurrentUrl,
  getQueryFromQueryStringFactory(RETURN_URL),
  (currentUrl, returnUrl) => returnUrl || currentUrl
);

/**
 * Returns a memoized version of the expensive createLinkFn function
 * if previously computed with the same props else computes if neccessary.
 */
const getLanguageLinkFunction = createSelector(
  [getBasePath, getGroceryDomain, getLanguage, getProtocol],
  (basePath, groceryDomain, language, protocol) => {
    return createLinkFn(language, null, groceryDomain, basePath, protocol);
  }
);

/**
 * Given the Redux state, will return a language-linked URL, equivalent to this.l(...), or this.languageLink(...)
 *
 * @param state
 * @returns {string}
 */
export function getLanguageLink(state, path, site = undefined) {
  const { app: { groceryDomain, language } = {} } = state;
  if (!groceryDomain || !language) return null;

  const url = getLanguageLinkFunction(state)(path, site);

  return path === '' ? url.replace(/\/$/, '') : url;
}

export const getCutsMustard = ({ app: { cutsMustard } }) => !!cutsMustard;

export const getShowBrowserWarning = ({ app: { showBrowserWarning } }) =>
  !!showBrowserWarning;

export const getConsumer = ({ app: { consumer } }) => consumer;

export const getIsMobile = ({ app: { deviceType } }) => deviceType === MOBILE;

export const getIsTablet = ({ app: { deviceType } }) => deviceType === TABLET;

export const getIsDesktop = ({ app: { deviceType } }) => deviceType === DESKTOP;

export const getApigeeMangoEndpoint = ({ app: { apigeeMangoEndpoint } }) =>
  apigeeMangoEndpoint;

export const getApigeeMangoApiKey = ({ app: { apigeeMangoApiKey } }) =>
  apigeeMangoApiKey;

export const getMakeLanguageLink = state => link =>
  getLanguageLink(state, link);

export const getShowBaglessPostLiveMessage = ({
  app: { showBaglessPostLiveMessage }
}) => showBaglessPostLiveMessage;

export const getOneCheckoutBaseUrl = ({ app: { oneCheckoutBaseUrl } }) =>
  oneCheckoutBaseUrl;

export const getTraceId = ({ app: { traceId } }) => traceId;

export const getMfeRolloutConfig = ({ app: { mfeRolloutConfig } }) =>
  mfeRolloutConfig;

export const getMfeRolloutDebug = ({ app: { mfeRolloutDebug } }) =>
  mfeRolloutDebug;
