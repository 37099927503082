import { basicEvent } from '../analytics/types/basic';
import analyticsBus from '../analytics/analyticsBus';

export const sendFeedbackAnalytics = location => {
  basicEvent(analyticsBus, {
    type: 'feedback',
    value: `opinionLab:${location}`,
    action: 'now'
  });
};

export const getActiveExperimentsFromWindow = () => {
  const optimizelyData = window.optimizelyData || [];
  const output = {};

  optimizelyData.map(experiment => {
    output[experiment.experiment] = experiment.variation;
  });

  return output;
};

export const openFeedbackWindow = (location, customerVariables) => {
  sendFeedbackAnalytics(location);

  if (typeof window !== 'undefined' && window.OOo) {
    window.OOo.oo_launchFeedback({
      ...customerVariables,
      ...getActiveExperimentsFromWindow()
    });
  }
};

export const enableFeedback = (config, feedbackLinks, cookieExperience) => {
  return config('cookiePreferences')
    ? feedbackLinks && cookieExperience
    : feedbackLinks;
};
