import { getResourceData } from '#/resources/resource-util';
import { BROWSE_ENTRYPOINTS } from '#/experiments/oop-1894/constants/spa-resource';
import { UPDATE_BROWSE_ENTRYPOINTS } from '#/experiments/oop-1894/constants/browseEntrypoints';
import { BrowseEntrypointsState, StampProps } from '#/experiments/oop-1894/types/index.def';

export const getDefaultStateFromProps = (props: {
  resources: { [resource: string]: { data: Store['browseEntrypoints'] } };
}): BrowseEntrypointsState => {
  return getResourceData(props.resources, BROWSE_ENTRYPOINTS) || [];
};

export function getBrowseEntrypoints({ browseEntrypoints }): Array<StampProps> | [] {
  return browseEntrypoints && Array.isArray(browseEntrypoints) ? browseEntrypoints : [];
}

export default (state = {}, action: { type: string; value: Store['browseEntrypoints'] }): BrowseEntrypointsState[] => {
  switch (action.type) {
    case UPDATE_BROWSE_ENTRYPOINTS:
      return action.value;
    default:
      return state as BrowseEntrypointsState[];
  }
};
