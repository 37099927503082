import { SET_DFP_SLOT_CONFIG } from '#/constants/action-types';
import { Dfp, DfpActions, SlotConfig } from '#/custom-typings/redux-store/dfp.defs';

function setSlotConfig(config: SlotConfig = {}): Dfp {
  return {
    slotConfig: config,
  };
}

export default function dfp(state: Dfp = { slotConfig: {} }, action: Partial<DfpActions> = {}): Dfp {
  switch (action.type) {
    case SET_DFP_SLOT_CONFIG:
      return {
        ...state,
        ...setSlotConfig(action.value),
      };
    default:
      return state;
  }
}
