import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import helpers from '#/lib/decorators/helpers';
import { connect } from '#/lib/render/connect-deep-compare';
import { updateParamsInUrl } from '#/lib/url/url-utils';
import {
  getCurrentPath,
  getLanguageLink,
  getRedirectUrl
} from '#/reducers/app';
import { getIsUserAuthenticated } from '#/reducers/user';
import WriteAReviewLink from '#/components/products/product-tile/tile-components/shared/tile-link';
import {
  CLUBCARD_OPT_IN_CONFIG_KEY,
  JUST_LOGGED_IN_QUERYSTRING_PARAM
} from '#/constants/clubcard-details';

export const mapStateToProps = (state, ownProps) => {
  const userAuthenticated = getIsUserAuthenticated(state);
  const currentPath = getCurrentPath(state);
  let baseReviewSubUrl = updateParamsInUrl(
    `/reviews/submission/${ownProps.id}`,
    {
      from: currentPath
    }
  );

  if (ownProps.c(CLUBCARD_OPT_IN_CONFIG_KEY) && !userAuthenticated) {
    baseReviewSubUrl = updateParamsInUrl(baseReviewSubUrl, {
      [JUST_LOGGED_IN_QUERYSTRING_PARAM]: true
    });
  }

  const reviewSubUrl = getLanguageLink(state, baseReviewSubUrl);
  const [pathname, queryString] = reviewSubUrl.split('?');

  return {
    reviewSubmissionUrl: getRedirectUrl(
      state,
      pathname,
      userAuthenticated,
      queryString
    )
  };
};

@helpers(['t', 'f', 'c'])
@connect(mapStateToProps)
export default class WriteAReview extends PureComponent {
  static propTypes = {
    f: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    productTitle: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    c: PropTypes.func.isRequired
  };

  render() {
    const { t, f, productTitle, reviewSubmissionUrl } = this.props;
    const ariaLabel = `${t(
      'ratings-reviews:write-a-review-for'
    )} ${productTitle}`;
    const reviewLinkText = t('ratings-reviews:write-a-review');

    return (
      f('enableRatingsReviewsSubmission') && (
        <WriteAReviewLink
          href={reviewSubmissionUrl}
          ariaLabel={ariaLabel}
          dataAuto="review-link"
          className="write-a-review-link"
        >
          {reviewLinkText}
        </WriteAReviewLink>
      )
    );
  }
}
