export enum TooltipVariants {
  INFO = 'info',
  DEFAULT = 'default',
}

export enum TooltipPositions {
  TOP = 'top',
  LEFT = 'left',
  RIGHT = 'right',
  BOTTOM = 'bottom',
}
export const TOOLTIP_CLASS = 'with-tooltip';
