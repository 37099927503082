import React from 'react';
import PropTypes from 'prop-types';
import { contextTypes as helpersContextTypes } from '../create-context';
import { helperNames } from '../../lib/decorators/helpers';

/**
 * Represents a base component that declares the context and easy to call
 * functions that avoid the `this.context.helpers.t` syntax. This means
 * any component that inherits this one does not have to also declare
 * the context.
 */
class Base extends React.Component {
  static contextTypes = {
    analyticsBus: PropTypes.func.isRequired,
    helpers: PropTypes.shape(helpersContextTypes).isRequired,
    router: PropTypes.object
  };
  constructor(props, context) {
    super(props, context);
  }
}

helperNames.forEach(prop => {
  Base.prototype[prop] = function(...propArgs) {
    return this.context.helpers[prop](...propArgs);
  };
});

export default Base;
