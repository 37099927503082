import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledDiv = styled.div`
  &.option-tabs__tabitem {
    &.ondemand {
      max-width: none;
      .ui-components-library--section-message {
        margin-top: 0;
      }
    }
  }
`;

/**
 * Represents an item in a Tab component.
 *
 * If a `className` is passed in then this is applied to the surrounding <div>.
 */
const TabItem = props => (
  <StyledDiv
    data-testid={props.dataTestid}
    className={`tabitem ${props.className}`}
  >
    {props.children}
  </StyledDiv>
);

TabItem.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  header: PropTypes.node
};

export default TabItem;
