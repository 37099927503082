import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from '#/lib/render/connect-deep-compare';
import * as filterType from '#/constants/facet-categories';
import page from 'page';
import delimitQuery from '#/components/mixins/delimit-query';
import helpers from '#/lib/decorators/helpers';
import analyticsBus from '#/analytics/analyticsBus';
import ActionableLayout from '#/components/layouts/actionable';
import OverlaySpinner from '#/components/overlay-spinner';
import CollapsibleList from '#/components/sort-n-filter/collapsible-list';
import FilterSortItem from '#/components/sort-n-filter/filter-sort-item';
import FilterTree from '#/components/sort-n-filter/filter-tree';
import { basicEvent } from '#/analytics/types/basic';
import {
  unlockBodyScroll,
  closeFilterMenu
} from '#/actions/ui-action-creators';
import { updateCancelUrl } from '#/actions/filter-action-creators';
import { scrollToTop } from '#/lib/browser/ui-util';
import { getFacets } from '#/reducers/filter';
import Link from '#/components/link-check-spa';
import LifestyleAndDietaryFooter from '#/components/sort-n-filter/lifestyle-and-dietary-footer';
import { getCurrentUrl, getIsCurrentPage } from '#/reducers/app';
import {
  AVAILABLE,
  PROMOTION,
  SUPERDEPARTMENT
} from '#/constants/facet-categories';
import { getTrolleyShoppingMethod } from '#/selectors/trolley';
import { isOnDemandShoppingMethod } from '#/lib/shopping-method-util';
import { isOnFavouritesPage } from '#/lib/favorites-helpers';
import { focusTrap } from '#/components/modals/incorrect-phone-number-modal/helper';
import { getFavFilterNavigationVariant } from '#/experiments/oop-1662/selectors';
import {
  getIsFilterDropDownEnabled,
  getIsFavFilterNavigationDisabled
} from '#/experiments/oop-1662/helpers';
import GHSMarketplaceFooter from '../ghs-marketplace-footer';
import { isShopUrl } from '#/selectors/product-details';
import { MULTI_SEARCH_URL, SEARCH_URL } from '#/selectors/ui/constants';

const topId = 'top-filter';

function categoryFacets(facetLists) {
  const categories = ['Superdepartment', 'Department', 'Aisle', 'Shelf'];
  const processedFacets = [];

  categories.forEach(category => {
    const userCategory = facetLists.find(el => el.category === category);

    if (userCategory) {
      processedFacets.push(userCategory);
    }
  });

  return processedFacets;
}

const mapStateToProps = state => {
  const shoppingMethod = getTrolleyShoppingMethod(state);
  const isOnDemandShopping = isOnDemandShoppingMethod(shoppingMethod);
  const currentUrl = getCurrentUrl(state);
  const displayed = !!state.ui.filterMenuOpen;

  const favFilterNavigationVariant = getFavFilterNavigationVariant(state);

  return {
    currentUrl,
    filterStoreFacets: getFacets(state),
    displayed,
    shouldRenderCategoriesFilter: !isOnDemandShopping,
    shouldRemoveFilters:
      isOnFavouritesPage(currentUrl) &&
      getIsFavFilterNavigationDisabled(favFilterNavigationVariant),
    shouldUpdateCategoryTitle: getIsFilterDropDownEnabled(
      favFilterNavigationVariant
    ),
    shouldRenderMarketPlaceFilter:
      !isOnDemandShopping &&
      (getIsCurrentPage(state, [SEARCH_URL, MULTI_SEARCH_URL]) ||
        isShopUrl(state))
  };
};

export const accessibilityFocus = selector => {
  const focusEl = document && document.querySelector(selector);
  if (focusEl) {
    focusEl.focus();
  }
};

const accessibilityFocusSelector = '.product-filter .sort-and-filter .button';

@connect(mapStateToProps, {
  unlockBodyScroll,
  closeFilterMenu,
  updateCancelUrl
})
@helpers(['t', 'c', 'f'])
export default class SortAndFilterRoot extends Component {
  static propTypes = {
    c: PropTypes.func.isRequired,
    cancelUrl: PropTypes.func,
    closeFilterMenu: PropTypes.func.isRequired,
    currentUrl: PropTypes.string.isRequired,
    displayed: PropTypes.bool.isRequired,
    f: PropTypes.func.isRequired,
    filterCancelUrl: PropTypes.string.isRequired,
    filters: PropTypes.array.isRequired,
    filterStoreFacets: PropTypes.array.isRequired,
    onCancel: PropTypes.func.isRequired,
    selectedFacetLists: PropTypes.array.isRequired,
    shouldRemoveFilters: PropTypes.bool.isRequired,
    showPromotionsFilter: PropTypes.bool,
    shouldUpdateCategoryTitle: PropTypes.bool,
    sortOptions: PropTypes.array,
    t: PropTypes.func.isRequired,
    unlockBodyScroll: PropTypes.func.isRequired,
    updateCancelUrl: PropTypes.func.isRequired,
    updating: PropTypes.bool,
    viewAllFlags: PropTypes.array
  };

  static defaultProps = {
    viewAllFlags: [],
    showPromotionsFilter: true,
    sortOptions: [],
    shouldUpdateCategoryTitle: false
  };

  constructor(props) {
    super(props);
    this.containerRef = createRef(null);
  }

  componentDidUpdate(prevProps) {
    const { displayed } = this.props;
    if (displayed && prevProps.displayed !== displayed) {
      const sortNFilterEl = this.containerRef.current;
      if (sortNFilterEl) {
        focusTrap(sortNFilterEl);
      }
    }
  }
  shouldComponentUpdate(nextProps) {
    const isVisibleOrVisibilityChanged =
      nextProps.displayed !== this.props.displayed || nextProps.displayed;

    return isVisibleOrVisibilityChanged;
  }

  changesMade() {
    return (
      this.props.currentUrl.split('#')[0] !==
      this.props.filterCancelUrl.split('#')[0]
    );
  }

  onCancel = evt => {
    evt.preventDefault();
    this.props.closeFilterMenu();
    scrollToTop();
    this.props.unlockBodyScroll();
    page(this.props.filterCancelUrl);
    accessibilityFocus(accessibilityFocusSelector);
    return this.props.onCancel;
  };

  onDone = evt => {
    evt.preventDefault();
    this.props.closeFilterMenu();
    scrollToTop();
    const newCancelUrl = this.props.cancelUrl || this.props.currentUrl;

    this.props.unlockBodyScroll();
    accessibilityFocus(accessibilityFocusSelector);

    if (this.changesMade()) {
      this.props.updateCancelUrl(newCancelUrl);
      basicEvent(analyticsBus, {
        type: 'click',
        value: 'filter apply'
      });
    }
  };

  renderNewFilter(facetMap) {
    if (!facetMap.New) {
      return null;
    }

    return (
      <div key="area-new">
        <CollapsibleList
          listLength={2}
          facets={facetMap.New.facets}
          category="new"
          singleSelection={false}
        />
      </div>
    );
  }

  renderPromotions(facetMap) {
    if (facetMap.Promotion && this.props.showPromotionsFilter) {
      const viewAllFlags = this.props.viewAllFlags || [];
      const viewAll = viewAllFlags.some(v => v === 'promotion');
      const hasSelected = this.props.selectedFacetLists.some(
        v => v.category === 'Promotion'
      );

      const { shouldRemoveFilters } = this.props;
      const filtersToRemove = shouldRemoveFilters ? [SUPERDEPARTMENT] : [];

      return (
        <div
          className="area area-promotion"
          key="area-promotion"
          id="area-promotion"
        >
          <span className="visually-hidden">
            {`${this.props.t('sort-and-filter:filter-by')}: ${this.props.t(
              'sort-and-filter:category-names.promotion'
            )}`}
          </span>
          <CollapsibleList
            listLength={2}
            facets={facetMap.Promotion.facets}
            category={filterType.PROMOTION.toLowerCase()}
            id={this.props.t('sort-and-filter:category-names.promotion')}
            expanded={viewAll}
            singleSelection={false}
            hasSelected={hasSelected}
            filtersToRemove={filtersToRemove}
          />
        </div>
      );
    }

    return null;
  }

  renderFilter(facetMap, filterName, filtersToRemove) {
    if (facetMap[filterName]) {
      const {
        viewAllFlags: viewAllFlagsFromProps,
        selectedFacetLists
      } = this.props;

      const viewAllFlags = viewAllFlagsFromProps || [];
      const viewAll = viewAllFlags.some(v => v === filterName);
      const hasSelected = selectedFacetLists.some(
        v => v.category === filterName
      );

      const id = `area area-${filterName}`;

      return (
        <div
          className={`area area-${filterName}`}
          key={`area-${filterName}`}
          id={id}
        >
          <CollapsibleList
            facets={facetMap[filterName].facets}
            category={filterName.toLowerCase()}
            expanded={viewAll}
            singleSelection={false}
            hasSelected={hasSelected}
            filtersToRemove={filtersToRemove}
          />
        </div>
      );
    }

    return null;
  }

  renderFavouritesFilter(facetMap) {
    if (!facetMap.Favourites) {
      return null;
    }

    return (
      <div key="area-favourites" className="area-favourites">
        <CollapsibleList
          facets={facetMap.Favourites.facets}
          category={filterType.FAVOURITES.toLowerCase()}
          singleSelection={false}
        />
      </div>
    );
  }

  renderLifeStyleAndDietaryFilter(facetMap) {
    const viewAllFlags = this.props.viewAllFlags || [];
    const viewAll = viewAllFlags.some(v => v === 'dietary');
    const hasSelected = this.props.selectedFacetLists.some(
      v => v.category === 'Dietary'
    );

    return (
      facetMap.Dietary && (
        <div
          key="area-dietary"
          className="area area-dietary"
          data-auto="filter-dietary"
        >
          <h2>
            <span className="visually-hidden">
              {this.props.t('sort-and-filter:filter-by')}
            </span>
            {this.props.t('sort-and-filter:lifestyle-and-dietary')}
          </h2>
          <h3 className="dietary-filters-instruction">
            {this.props.t('sort-and-filter:lifestyle-and-dietary-header')}
          </h3>
          <CollapsibleList
            facets={facetMap.Dietary.facets}
            category={filterType.DIETARY.toLowerCase()}
            singleSelection={false}
            expanded={viewAll}
            showAllOption={true}
            hasSelected={hasSelected}
            footer={<LifestyleAndDietaryFooter />}
          />
        </div>
      )
    );
  }

  renderCategories(facetMap) {
    const topCategory =
      facetMap.Superdepartment ||
      facetMap.Department ||
      facetMap.Aisle ||
      facetMap.Shelf;

    if (topCategory) {
      const facetLists = categoryFacets(this.props.filterStoreFacets);
      const filterCategories = [
        filterType.SUPERDEPARTMENT.toLowerCase(),
        filterType.DEPARTMENT.toLowerCase(),
        filterType.SHELF.toLowerCase()
      ];
      const viewAllFlags = this.props.viewAllFlags || [];
      const viewAll = viewAllFlags.some(v =>
        filterCategories.includes(v.toLowerCase())
      );
      const hasSelected = this.props.selectedFacetLists.some(
        v => v.category === topCategory.category
      );
      const isSingleSelection = [
        filterType.DEPARTMENT,
        filterType.SUPERDEPARTMENT
      ].some(
        facet => facet.toLowerCase() === topCategory.category.toLowerCase()
      );

      const { shouldRemoveFilters } = this.props;
      const filtersToRemove = shouldRemoveFilters ? [PROMOTION] : [];
      const title = this.props.shouldUpdateCategoryTitle
        ? this.props.t('sort-and-filter:dropdown-variant.categories')
        : this.props.t('sort-and-filter:category');

      return (
        <div
          className="area area-categories"
          data-auto="filter-categories"
          key="area-categories"
        >
          <h2>
            <span className="visually-hidden">
              {this.props.t('sort-and-filter:filter-by')}
            </span>
            {title.toUpperCase()}
          </h2>
          {facetLists.length > 1 ? (
            <FilterTree facetLists={facetLists} />
          ) : (
            <CollapsibleList
              listLength={2}
              id={title}
              facets={topCategory.facets}
              category={topCategory.category}
              expanded={viewAll}
              singleSelection={isSingleSelection}
              hasSelected={hasSelected}
              showAllOption={
                topCategory.category === filterType.SUPERDEPARTMENT
              }
              filtersToRemove={filtersToRemove}
            />
          )}
        </div>
      );
    }

    return null;
  }

  renderBrands(facetMap) {
    if (facetMap.Brand) {
      const viewAllFlags = this.props.viewAllFlags || [];
      const viewAll = viewAllFlags.some(v => v === 'brand');
      const hasSelected = this.props.selectedFacetLists.some(
        v => v.category === 'Brand'
      );

      return (
        <div
          className="area area-brand"
          data-auto="filter-brands"
          key="area-brand"
        >
          <h2>
            <span className="visually-hidden">
              {this.props.t('sort-and-filter:filter-by')}
            </span>
            {this.props.t('sort-and-filter:category-names.brand').toUpperCase()}
          </h2>
          <CollapsibleList
            listLength={2}
            facets={facetMap.Brand.facets}
            category="brand"
            id={this.props.t('sort-and-filter:category-names.brand')}
            expanded={viewAll}
            singleSelection={false}
            hasSelected={hasSelected}
          />
        </div>
      );
    }

    return null;
  }

  renderProductSource(facetMap) {
    const { selectedFacetLists, t } = this.props;
    if (facetMap[filterType.PRODUCT_SOURCE_FACET]) {
      const viewAllFlags = this.props.viewAllFlags || [];
      const viewAll = viewAllFlags.some(v => v === filterType.PRODUCT_SOURCE);
      const hasSelected = selectedFacetLists.some(
        v => v.categoryId === filterType.PRODUCT_SOURCE
      );

      return (
        <div
          className="area area-productSource"
          data-auto="filter-productSource"
          key="area-productSource"
        >
          <h2>
            <span className="visually-hidden">
              {t('sort-and-filter:filter-by')}
            </span>
            {t('sort-and-filter:tesco-marketplace-products')}
          </h2>
          <h3 className="product-source-filters-instruction">
            {t('sort-and-filter:tesco-marketplace-products-header')}
            <GHSMarketplaceFooter
              hideChevronIcon={true}
              showUnderline={true}
              showSmallText={true}
            />
          </h3>
          <CollapsibleList
            listLength={3}
            facets={facetMap[filterType.PRODUCT_SOURCE_FACET].facets}
            category="productSource"
            id={t('sort-and-filter:tesco-marketplace-products-header')}
            expanded={viewAll}
            singleSelection={true}
            hasSelected={hasSelected}
          />
        </div>
      );
    }

    return null;
  }

  renderSortOptions(currentUrl) {
    return (
      this.props.sortOptions.length > 0 && (
        <div className="sort-by">
          <h2>{this.props.t('sort-and-filter:sort-by')}</h2>
          <ul>
            {this.props.sortOptions.map(sortOption => (
              <FilterSortItem
                config={this.props.c}
                currentUrl={currentUrl}
                key={sortOption}
                sortOption={sortOption}
                translate={this.props.t}
              />
            ))}
          </ul>
        </div>
      )
    );
  }

  getFilters() {
    const {
      currentUrl,
      f: feature,
      filterStoreFacets,
      selectedFacetLists,
      t: translate,
      updating,
      shouldRenderCategoriesFilter,
      shouldRenderMarketPlaceFilter,
      c
    } = this.props;
    const clearAllQs = delimitQuery.parseQuerystring(currentUrl);

    delete clearAllQs.page;
    this.props.filters.forEach(filter => delete clearAllQs[filter]);

    const facetMap = filterStoreFacets.reduce((acc, curr) => {
      acc[curr.category] = curr;

      return acc;
    }, {});

    const filterBy = (selectedFacetLists || []).length > 0 && (
      <Link
        className="clear-all"
        to={`${delimitQuery.createHref(currentUrl, clearAllQs)}`}
      >
        {translate('sort-and-filter:clear-all')}
      </Link>
    );

    const filtersToRemoveForAvailable = [];

    const spinner = updating && <OverlaySpinner isLoading={true} />;

    const filters = filterStoreFacets.length && (
      <div className="filter-by" ref="filter-by" data-auto="filter-by">
        {filterBy}
        <h2>{translate('sort-and-filter:category-names.promotion')}</h2>
        {spinner}
        {this.renderNewFilter(facetMap)}
        {this.renderPromotions(facetMap)}
        {this.renderFilter(facetMap, AVAILABLE, filtersToRemoveForAvailable)}
        {this.renderFavouritesFilter(facetMap)}
        {c('showMarketPlaceFilter') &&
          shouldRenderMarketPlaceFilter &&
          this.renderProductSource(facetMap)}
        {shouldRenderCategoriesFilter && this.renderCategories(facetMap)}
        {this.renderBrands(facetMap)}
        {feature('enableLifestyleAndDietaryProductFilters') &&
          this.renderLifeStyleAndDietaryFilter(facetMap)}
      </div>
    );

    return filters;
  }

  render() {
    const {
      currentUrl,
      displayed,
      filterStoreFacets,
      t: translate
    } = this.props;
    const filters = displayed ? this.getFilters() : null;

    return (
      <div
        id="sort-n-filter"
        data-auto="sort-n-filter"
        className={classnames('sort-n-filter-container fancy-filter', {
          open: displayed
        })}
        ref={this.containerRef}
      >
        {displayed && (
          <ActionableLayout
            title={translate('sort-and-filter:sort-and-filter')}
            disableAnalytics={true}
            doneButtonEnabled={this.changesMade()}
            isOpen={false}
            onCancel={this.onCancel}
            onDone={this.onDone}
          >
            <div
              className={classnames({
                'no-results': !filterStoreFacets.length
              })}
              id="scrollable-overlay"
            >
              <div id={topId} />
              {this.renderSortOptions(currentUrl)}
              {filters}
            </div>
          </ActionableLayout>
        )}
      </div>
    );
  }
}
