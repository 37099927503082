import { getQueryParamByKey } from '#/lib/url/url-utils';

function getLabel(breadcrumb) {
  return breadcrumb ? breadcrumb.label : '';
}

function getCategoryId(breadcrumb) {
  return breadcrumb ? breadcrumb.catId : '';
}

export function buildData(options) {
  const {
    pageLanguage,
    pageTitle,
    pageURL,
    region,
    breadcrumbs = []
  } = options;

  const superDepartment = getLabel(breadcrumbs[1]);
  const department = getLabel(breadcrumbs[2]);
  const aisle = getLabel(breadcrumbs[3]);
  const superDepartmentId = getCategoryId(breadcrumbs[1]);
  const departmentId = getCategoryId(breadcrumbs[2]);
  const aisleId = getCategoryId(breadcrumbs[3]);
  const pageHierarchy = breadcrumbs.map(v => v.label).join();
  const shelf = getLabel(breadcrumbs[4]);

  // add preservedReferrer
  const preservedReferrer =
    getQueryParamByKey(window.location.href, 'preservedReferrer') || null;

  return {
    pageLanguage,
    pageTitle,
    pageURL,
    pageHierarchy,
    preservedReferrer,
    region,
    superDepartment,
    superDepartmentId,
    department,
    departmentId,
    aisle,
    aisleId,
    shelf
  };
}
