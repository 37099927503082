import {
  ORDER_LIST_DETAILS_FETCHED,
  UPDATE_ORDER_IM_HERE_ARRIVED
} from '#/constants/action-types';

export const updateOrderListDetails = value => ({
  type: ORDER_LIST_DETAILS_FETCHED,
  value
});

export const updateOrderListImHereArrived = value => ({
  type: UPDATE_ORDER_IM_HERE_ARRIVED,
  value
});
