import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import styled, { css } from 'styled-components';
import { media } from '@ddsweb/foundation';
import helpers from '#/lib/decorators/helpers';
import { productFilterOpenAnalyticsEvent } from '#/analytics/types/filter';
import { connect } from '#/lib/render/connect-deep-compare';
import { isOnDemandShoppingMethod } from '#/lib/shopping-method-util';
import { getTrolleyShoppingMethod } from '#/selectors/trolley';

const translationKeys = {
  genericLabel: 'sort-and-filter:select'
};

// TODO: Remove once after the other category filters available for on-demand
const FilterSelectContainer = styled.div`
  &&.filter-select-container.filter-brands {
    ${props =>
      props.isOnDemandShopping &&
      css`
        left: 0;
      `};
  }
  &&.filter-select-container.filter-lifestyle-and-dietary {
    ${props =>
      props.isOnDemandShopping &&
      css`
        left: 18%;
        ${media.aboveTablet` left: 25%; `}
        ${media.aboveDesktop` left: 18%; `}
      `};
  }
`;

FilterSelectContainer.displayName = 'FilterSelectContainer';

function mapStateToProps(state) {
  const shoppingMethod = getTrolleyShoppingMethod(state);

  return {
    // TODO: Remove once after the other category filters available for on-demand
    isOnDemandShopping: isOnDemandShoppingMethod(shoppingMethod)
  };
}

@helpers(['c', 't'])
@connect(mapStateToProps)
export default class FilterSelect extends Component {
  static propTypes = {
    controls: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
    isOnDemandShopping: PropTypes.bool,
    label: PropTypes.string,
    onClick: PropTypes.func,
    open: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      transitioning: false
    };

    this.endTransition = () => this.setState({ transitioning: false });
    this._clickHandler = e => {
      e.nativeEvent.stopImmediatePropagation();
      if (
        !this.props.open &&
        this.props.controls === 'filter-lifestyle-and-dietary'
      ) {
        productFilterOpenAnalyticsEvent();
      }
      this.props.onClick ? this.props.onClick() : null;
    };
  }

  componentDidMount() {
    ReactDOM.findDOMNode(this.refs.filterSelect).addEventListener(
      'transitionend',
      this.endTransition,
      false
    );
  }

  componentWillUnmount() {
    ReactDOM.findDOMNode(this.refs.filterSelect).removeEventListener(
      'transitionend',
      this.endTransition,
      false
    );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open !== this.props.open) {
      this.setState({
        transitioning: true
      });
    }
  }

  render() {
    const {
      count,
      controls,
      isOnDemandShopping,
      open,
      label,
      t: translate
    } = this.props;
    const hasItems = count > 0;

    const classes = classnames('button-text', 'filter-select', {
      'filter-options-open': open && hasItems,
      'filter-options-transitioning': this.state.transitioning,
      disabled: !hasItems
    });

    const labelText = label ? (
      <span className="filter-select--label specific-filter-label">
        {label}
      </span>
    ) : (
      <span className="filter-select--label generic-filter-label">
        {translate(translationKeys.genericLabel)}
      </span>
    );

    return (
      <FilterSelectContainer
        className={classnames('filter-select-container', controls)}
        isOnDemandShopping={isOnDemandShopping}
      >
        <button
          aria-controls={controls}
          aria-expanded={open}
          className={classes}
          onClick={hasItems ? this._clickHandler : null}
          ref="filterSelect"
          data-auto={`${controls}-select`}
        >
          {labelText}
          {hasItems && <span className="icon icon-header_down_triangle" />}
        </button>
      </FilterSelectContainer>
    );
  }
}
