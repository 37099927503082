import {
  PRODUCTS_BY_CATEGORY,
  TROLLEY_CONTENTS
} from '#/constants/spa-resource';
import { updateResults } from '#/actions/results-actions';
import {
  updateCurrentUrl,
  updateFromNewResults
} from '#/actions/filter-action-creators';
import { getProductsByCategoryResource } from '#/selectors/resources';

export const type = PRODUCTS_BY_CATEGORY;
export const params = [
  'aisle',
  'categoryId',
  'department',
  'isAmendBasket',
  'noindex',
  'query',
  'robots',
  'shelf',
  'slot',
  'superdepartment',
  'trolleyItems',
  'deliveryType'
];
export const dependencies = [TROLLEY_CONTENTS];

export function handler(dispatch, getState, options = {}) {
  const { data: { results } = {} } = getProductsByCategoryResource(getState());

  if (results && results.productItems) {
    dispatch(updateResults(results, type, true));

    if (options.updateCurrentURL) {
      dispatch(updateCurrentUrl(options.updateCurrentURL));
      dispatch(updateFromNewResults(results, options.updateCurrentURL));
    }
  }
}
