import React from 'react';
import PropTypes from 'prop-types';
import ErrorPopdown from '../error-popdown';
import InputValidation from '../input-validation';

export default class InputWithLimitedCharacters extends InputValidation {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      value: this.props.defaultValue,
      elementClass: ''
    };
  }

  static propTypes = {
    containerClassName: PropTypes.string,
    dataAuto: PropTypes.string,
    defaultValue: PropTypes.string,
    enableErrorPopdown: PropTypes.bool,
    maxLength: PropTypes.number,
    pattern: PropTypes.object.isRequired
  };

  static defaultProps = {
    containerClassName: '',
    enableErrorPopdown: true
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.defaultValue !== prevState.value)
      return {
        value: nextProps.defaultValue
      };
    return null;
  }

  validateInput = evt => {
    if (evt.charCode === 13) {
      return evt.target.blur();
    } else if (
      !this.props.pattern.test(String.fromCharCode(evt.charCode)) ||
      (this.props.maxLength &&
        evt.target.value.length + 1 > this.props.maxLength)
    ) {
      evt.preventDefault();
      this.setState({
        elementClass: 'animated-input pulse input-validation-error'
      });

      if (typeof window !== 'undefined' && window !== null) {
        return window.setTimeout(this.clearInputError, 500);
      }
    } else {
      return this.onKeyPress(evt);
    }
  };

  render() {
    const inputProps = {
      ref: 'input',
      key: 1,
      value: this.state.value,
      className: this.state.elementClass,
      onBlur: this.onBlur,
      onChange: this.onChange,
      onKeyPress: e => this.validateInput(e),
      type: 'text'
    };

    Object.assign(inputProps, this.props);
    delete inputProps.register;
    delete inputProps.enableErrorPopdown;
    delete inputProps.containerClassName;
    delete inputProps.defaultValue;
    delete inputProps.dataAuto;

    return (
      <div className={this.props.containerClassName}>
        <input data-auto={this.props.dataAuto} {...inputProps} />
        {this.props.enableErrorPopdown && (
          <ErrorPopdown errors={this.state.errors} />
        )}
      </div>
    );
  }
}
