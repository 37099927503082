import React from 'react';
import helpers from '#/lib/decorators/helpers';
import { connect } from '#/lib/render/connect-deep-compare';
import { getLanguage } from '#/reducers/app';
import { SectionMessage } from '#/components/cms-driven/section-message';
import CollectionSteps from '#/components/slots/collection-steps';

const mapStateToProps = (state: any) => {
  return {
    language: getLanguage(state),
  };
};

type TOwnProps = {
  t: (key: string, data?: object) => string;
  c: (key: string) => string;
  language: string;
};

const CollectionStepsSection: React.FC<TOwnProps> = (props: TOwnProps) => {
  const { t: translate, c: config, language } = props;

  return (
    <div className="hidden-small">
      <div className="book-a-slot__section-message">
        <SectionMessage size="large" id="how-collection-works" message={translate('slots:how-collection-works')} />
      </div>
      <CollectionSteps />
      <a
        className="book-a-slot--collection-info"
        href={config(`help:clickCollect:${language}`)}
        target="_blank"
        rel="noreferrer"
      >
        {translate('slots:common.click-collect-more-link')}
      </a>
    </div>
  );
};
export default helpers(['t', 'c'])(connect(mapStateToProps)(CollectionStepsSection));
