import { getLanguageLink } from '#/reducers/app';
import { updateTrolley } from '#/actions/trolley/trolley-action-creators';
import { request } from '#/lib/client-fetch';
import { hasUpdateErrors } from '#/utils/trolley-utils';
import { openModal } from '#/actions/ui-action-creators';
import { ITEMS_UNAVAILABLE_MODAL } from '#/constants/modal-names';
import { Dispatch } from '#/custom-typings/redux-store/common';
import { fireAddAllAnalytics } from '#/analytics/helpers/orders';
import commonActionRejectionsHandler from '#/actions/common-action-rejections-handler';
import { getProductId } from '#/selectors/item';
import { Item } from '#/lib/records/item';
import * as actionTypes from '#/constants/action-types';

type Updates = {
  errorCode: string;
  id: number;
  isAlcoholic: boolean | null;
  message: string;
  status: string;
  successful: boolean;
};

export const updateBasketWithUsuals = (dispatch: Dispatch, state: Store): void => {
  dispatch({
    type: actionTypes.START_ADD_ALL_USUALS_UPDATE,
  });
  request
    .post(getLanguageLink(state, '/trolley/add-usuals-to-basket'), {
      rejectOnClientError: true,
    })
    .then(data => {
      if (data) {
        if (data.redirectToUsuals) {
          window.location.replace(getLanguageLink(state, '/favorites?tab=usuals'));
        }
        dispatch({
          type: actionTypes.RESOLVE_ADD_ALL_USUALS_UPDATE,
        });
        dispatch(updateTrolley(data, false, true));
        const items = data.updates.items;

        if (hasUpdateErrors(items)) {
          dispatch(openModal(ITEMS_UNAVAILABLE_MODAL));
        }

        const itemsMap = new Map();
        const orderItems: Array<Item> = [];
        for (let i = 0; i < data.items.length; i++) {
          const item = data.items[i];
          itemsMap.set(getProductId(item), null);
          const { product, ...remainingData } = item;
          orderItems.push({ ...remainingData, productItem: { product } });
        }

        const filteredUpdates = data.updates.items.filter((item: Updates) => itemsMap.has(item.id));

        fireAddAllAnalytics({ ...data, orderItems, updates: { items: filteredUpdates } }, state);
      }
    })
    .catch(err => {
      commonActionRejectionsHandler(err, dispatch);
    })
    .then(() => {
      dispatch({
        type: actionTypes.RESOLVE_ADD_ALL_USUALS_UPDATE,
      });
    });
};
