import { CustomerUnitChoice, Promotion, Restriction } from '#/lib/records/item.defs';
import { DO_NOT_SUBSTITUTE, FIND_SUITABLE_ALTERNATIVE } from '#/constants/substitution-options';
import { WEIGHT_UNIT_KG as UNIT_KG, QUANTITY_TYPE as UNIT_PCS } from '#/constants/common';
import {
  getCatchWeightPrice,
  getCustomerUnitChoice,
  getDefaultUnit,
  getMaxKgs,
  getMaxPieces,
  getPieces,
  getProductAverageWeight,
  getQtyForAdjustedNItems,
  getQtyForNItems,
  getQtyInRange,
  getQuantity,
  getWeight,
} from '#/selectors/item';

import { cloneItem } from '#/utils/clone-item';
import { round } from '#/lib/string-formatting/format';
import { Item as ItemType } from '#/lib/records/item';

export function getItemPromotionLink(item: Promotion, offersIcid?: string): string {
  const icid = offersIcid ? `?icid=${offersIcid}` : '';
  return item.promotionType ? '/promotions/all' : `/promotions/${item.promotionId}${icid}`;
}

export function incItemBy(item: ItemType, qty: number): ItemType {
  if (!qty) {
    return item;
  }

  const currentQuantity = getQuantity(item);
  const unit = getCustomerUnitChoice(item) || getDefaultUnit(item);

  switch (unit) {
    case UNIT_KG:
      return cloneItem({
        ...item,
        quantity: getQtyInRange(item, round(currentQuantity + (getProductAverageWeight(item) || 0) * qty)),
      });
    case UNIT_PCS:
      return cloneItem({ ...item, quantity: getQtyInRange(item, round(currentQuantity + qty)) });
    default:
      console.warn(`Unexpected item unit: ${unit}`);
      return item;
  }
}

export function isViolated(restriction: Restriction): boolean {
  return restriction != null && restriction.isViolated;
}

function valueWithinMinMax(value: number, min: number, max: number): number {
  return Math.max(min, Math.min(value, max));
}

export function setCustomerUnitChoice(item: ItemType, unit: CustomerUnitChoice): ItemType {
  if (item.customerUnitChoice === unit || unit == null) {
    return item;
  }

  switch (unit) {
    case UNIT_KG: {
      const kgs = getWeight(item);
      const maxKgs = getMaxKgs(item);
      return cloneItem({
        ...item,
        customerUnitChoice: UNIT_KG,
        quantity: valueWithinMinMax(kgs, 0, maxKgs),
      });
    }
    case UNIT_PCS: {
      const pieces = getPieces(item);
      const maxPieces = getMaxPieces(item);
      return cloneItem({
        ...item,
        customerUnitChoice: UNIT_PCS,
        quantity: valueWithinMinMax(pieces, 0, maxPieces),
      });
    }
    default: {
      console.warn(`Unexpected item unit: ${unit}`);
      return item;
    }
  }
}

/**
 * Creates a new Item with the given number of items adjusted against its current
 * number of items. This will automatically update the underlying quantity
 * property to be reflective of number of items based on the underlying unit
 * type of the Item.
 *
 * If a negative number of items is provided then the Item will have its item
 * count reduced.
 *
 * @param item The item
 * @param numberOfItemsAdjustment The number of items to adjust by
 *
 * @returns The new Item
 */
export function adjustNumberOfItems(item: ItemType, numberOfItemsAdjustment: number): ItemType {
  if (numberOfItemsAdjustment === 0) {
    return item;
  }

  const newQuantity = getQtyForAdjustedNItems(item, numberOfItemsAdjustment);
  return cloneItem({ ...item, isNewlyAdded: item.isNewlyAdded || item.quantity === 0, quantity: newQuantity });
}

/**
 * Creates a new Item with the given number of items assigned to it. This will
 * automatically update the underlying quantity property to be reflective of
 * number of items based on the underlying unit type of the Item.
 *
 * If `numberOfItems` is not provided then the original `item` is returned.
 *
 * @param item The item
 * @param numberOfItems The new number of items
 *
 * @returns The new Item
 */
export function setNumberOfItems(item: ItemType, numberOfItems?: number): ItemType {
  if (numberOfItems == null) {
    return item;
  }

  const newQuantity = getQtyForNItems(item, numberOfItems);
  if (getQuantity(item) === newQuantity) {
    return item;
  }

  return cloneItem({ ...item, quantity: newQuantity });
}

export function setSubstitutionPreference(item: ItemType, preference?: boolean): ItemType {
  return cloneItem({ ...item, substitutionOption: preference ? FIND_SUITABLE_ALTERNATIVE : DO_NOT_SUBSTITUTE });
}

export function withCatchWeight(item: ItemType, weight: number): ItemType {
  if (getCatchWeightPrice(item, weight)) {
    return cloneItem({ ...item, catchWeight: weight });
  }

  return item;
}
