export const RELATED_SEARCH_CONFIG = 'relatedterms';
export const RELATED_SEARCH_HEADING = 'Related searches';
export const RELATED_SEARCH_ANALYTICS_TYPE = 'related searches';
export const RELATED_SEARCH_STORAGE_KEY = 'relatedSearchInteraction';
export const RELATED_SEARCH_TYPE = 'related';

const VARIATION_KEY = 'relatedSearch';
export const FEATURE_KEY = 'GHS-<region>_relatedSearch';
export const SEARCH_PAGE = '/search';

export type ExperimentConfig = {
  experiment: string;
  variation: string;
  relatedSearch: string;
};

export enum RelatedSearchVariants {
  DEFAULT = 'default',
  DROPDOWN = 'rs-dropDown',
  TOP_PILLS = 'rs-pillsTop',
}

export const RelatedSearchFeature = {
  featureKey: FEATURE_KEY,
  variationKey: VARIATION_KEY,
  serviceConfig: { params: { [VARIATION_KEY]: RelatedSearchVariants.DEFAULT } },
  useCustomerId: true,
};
