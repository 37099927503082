import React from 'react';
import moment from 'moment';
import Heading from '@ddsweb/heading';
import { SUCCESS, INFO, ERROR, WARNING } from '@ddsweb/constants';
import { SPALink } from '#/components/trolley/full-trolley/basket-slot-card/shared';
import { InLineMessaging } from '@ddsweb/messaging';
import {
  StyledBasketSlotContextCardContainer,
  StyledFullBasketSlotContextCardStatus,
  StyledInlineLink,
  StyledHeadingContainer,
  StyledHeadings,
  StyledText,
} from './styled';
import DateTile from '#/components/context-cards/slot/date-tile';
import { TConfigFunc } from '#/lib/records/helpers.defs';
import helpers from '#/lib/decorators/helpers';

type VariantType = typeof SUCCESS | typeof INFO | typeof ERROR | typeof WARNING;

export interface TProps {
  bagOptions?: {
    link: string;
    onClick: () => void;
    text: string | JSX.Element;
  } | null;
  buttonLink: string;
  buttonText: string;
  c: TConfigFunc;
  className?: string;
  end?: moment.Moment;
  header: string;
  onClick?: () => void;
  shoppingMethodText: string;
  slot?: { start?: string; end?: string; reservationExpiry?: string };
  slotMessage: string | JSX.Element;
  start?: moment.Moment;
  showSlotWindow?: boolean;
  useBagOptions?: boolean;
  variant: VariantType;
  amountChanging: number;
  guidePrice: number;
  language: string;
  charges: {
    minimumBasketValue: number;
    surcharge: number;
  };
  isValidSlot: boolean;
  localisation: {
    locale: string;
    currency: { iso: string };
  };
  hasBasketBreachedByVolumeOrWeight: boolean;
  constraints: {
    maxItemCount: number;
  };
  shoppingMethod: string;
}

const areSameHalfOfDay = (start?: moment.Moment, end?: moment.Moment): boolean =>
  start?.format('a') === end?.format('a');

const formatSlotWindow = (
  isSameMeridiem: boolean,
  shortStartTimeFormat: string,
  startTimeFormat: string,
  endStartTimeFormat: string,
  start?: moment.Moment,
  end?: moment.Moment,
): string =>
  `, ${isSameMeridiem ? start?.format(shortStartTimeFormat) : start?.format(startTimeFormat)}-${end?.format(
    endStartTimeFormat,
  )}`;

const BasketSlotContextCardSlotBooked: React.FC<TProps> = ({
  bagOptions,
  buttonLink,
  buttonText,
  className,
  end,
  header,
  onClick,
  shoppingMethodText,
  slot,
  slotMessage,
  start,
  showSlotWindow,
  useBagOptions,
  variant,
  c: config,
}) => {
  const isSameMeridiem = areSameHalfOfDay(start, end);

  const shortStartTimeFormat = config('shortStartTimeFormat') as string;
  const startTimeFormat = config('startTimeFormat') as string;
  const endTimeFormat = config('endTimeFormat') as string;

  const inlineLink = (
    <StyledInlineLink
      href={useBagOptions ? bagOptions?.link : buttonLink}
      onClick={useBagOptions ? bagOptions?.onClick : onClick}
      as={SPALink}
    >
      {useBagOptions ? bagOptions?.text : buttonText}
    </StyledInlineLink>
  );

  return (
    <StyledBasketSlotContextCardContainer className={className}>
      <StyledFullBasketSlotContextCardStatus>
        <Heading headingLevel="5">{shoppingMethodText}</Heading>
        {header ? (
          <StyledHeadingContainer>
            <StyledHeadings headingLevel="4">{header}</StyledHeadings>
            {inlineLink}
          </StyledHeadingContainer>
        ) : (
          <StyledHeadingContainer>
            <StyledHeadings headingLevel="4">
              <DateTile date={slot?.start} useFullBasketCardStyles={true} />
              {showSlotWindow &&
                `${formatSlotWindow(isSameMeridiem, shortStartTimeFormat, startTimeFormat, endTimeFormat, start, end)}`}
            </StyledHeadings>
            {inlineLink}
          </StyledHeadingContainer>
        )}
        <InLineMessaging bare={true} variant={variant} aria-live="off">
          <StyledText as="p">{slotMessage}</StyledText>
        </InLineMessaging>
      </StyledFullBasketSlotContextCardStatus>
    </StyledBasketSlotContextCardContainer>
  );
};

export default helpers(['c'])(BasketSlotContextCardSlotBooked);
