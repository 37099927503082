import { UPDATE_SLOT_GROUP } from '#/constants/action-types';
import { FULFILMENT_METADATA } from '#/constants/spa-resource';
import { getFulfilmentMetadataResource } from '#/selectors/resources';

export const type = FULFILMENT_METADATA;
export const params = ['preventFulfilmentMetadataFetch', 'shoppingMethod'];

export function handler(dispatch: Function, getState: Function) {
  const { data } = getFulfilmentMetadataResource(getState());

  if (!data) {
    return;
  }

  dispatch({
    type: UPDATE_SLOT_GROUP,
    value: data.availableSlotGroups,
  });
}
