import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Results from '../../results';
import PromotionsByDepartment from '../../promotions/promotions-by-department';
import { PROMOTIONS } from '#/constants/result-types';
import { connect } from '#/lib/render/connect-deep-compare';
import { getIsAmendBasket } from '#/selectors/trolley';
import {
  getPromotionsListResource,
  getPromotionsContentResource
} from '#/selectors/resources';
import { exposeActiveTestData } from '#/lib/optimizely-manager';
import helpers from '#/lib/decorators/helpers';
import { getIsMobile } from '#/reducers/app';
import { VERTICAL } from '#/components/product-tiles/common/constants';
import CategoryChips from '#/components/category-chips';
import { getIsSpecialOffersRedesignVariant } from '#/experiments/oop-2055/selectors';
import InteractiveStarRating from '#/components/star-rating/interactive-star-rating';
import MediaBanners, {
  gamBanner
} from '#/experiments/oop-2055/components/banners';
import Breadcrumbs from '#/components/breadcrumbs';
import AnalyticsContentEvent from '#/components/analytics/content-event';
import { categoryPillV2Variants } from '#/experiments/oop-2191/constants';
import dfpTargetingCreator from '#/lib/dfp-targeting-creator';
import { getUser } from '#/reducers/user';

const mapStateToProps = state => {
  return {
    isAmendBasket: getIsAmendBasket(state),
    promotionsListResourceData: getPromotionsListResource(state)?.data || {},
    promotionsContent: getPromotionsContentResource(state)?.data || {},
    isMobile: getIsMobile(state),
    isInSpecialOffersExperiment: getIsSpecialOffersRedesignVariant(state),
    user: getUser(state)
  };
};

@helpers(['c', 't'])
@connect(mapStateToProps)
export default class PromotionListPage extends Component {
  static propTypes = {
    c: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    isAmendBasket: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
    promotionsListResourceData: PropTypes.shape({
      results: PropTypes.object,
      facetLists: PropTypes.array,
      query: PropTypes.string,
      totalCount: PropTypes.number,
      user: PropTypes.object
    })
  };

  getStarRatingProps() {
    const { c: config, t: translate } = this.props;
    const legendText = translate('promotions:star-rating-feedback.legend-text');
    const legendAriaLabel = translate(
      'promotions:star-rating-feedback.legend-aria-label'
    );
    const refererUrl = config('opinionLab:refererUrls:specialOffers');

    return {
      legendAriaLabel,
      legendText,
      refererUrl
    };
  }

  componentDidMount() {
    const { promotionsConfig = {} } = this.props.promotionsListResourceData;

    exposeActiveTestData(promotionsConfig);
  }

  render() {
    const {
      isAmendBasket,
      isMobile,
      c: config,
      isInSpecialOffersExperiment,
      promotionsListResourceData,
      promotionsContent = {},
      user
    } = this.props;

    const {
      results = {},
      facetLists = [],
      query,
      totalCount,
      promotionsConfig,
      categoryChips,
      breadcrumbsData
    } = promotionsListResourceData;

    const { content } = promotionsContent;
    const showPromotionByDepartment = config('showPromotionsByDepartment');

    const experimentPageTitle =
      breadcrumbsData && breadcrumbsData[breadcrumbsData.length - 1].label;

    const targeting = dfpTargetingCreator({ user });

    return (
      <div
        className={isInSpecialOffersExperiment ? 'special-offers-redesign' : ''}
      >
        <div className="promotions results-page">
          {isInSpecialOffersExperiment ? (
            <>
              {<Breadcrumbs trails={breadcrumbsData} />}
              <MediaBanners targeting={targeting} />
              <h1>{experimentPageTitle}</h1>
              <div>
                {categoryChips?.length > 0 && (
                  <CategoryChips
                    menuItems={categoryChips}
                    categoryChipVariant={
                      categoryPillV2Variants.variants.default
                    }
                  />
                )}
              </div>
            </>
          ) : (
            showPromotionByDepartment && (
              <div className="promotions--content">
                <PromotionsByDepartment
                  isOpen={!isMobile}
                  facetLists={facetLists}
                  totalCount={totalCount}
                  promotionsConfig={promotionsConfig}
                />
              </div>
            )
          )}
          <Results
            {...this.props.promotionsListResourceData}
            isAmendBasket={isAmendBasket}
            showMiniTrolley={false}
            showBreadcrumb={false}
            showPromotionsFilter={false}
            showRecommendedCarousel={!isInSpecialOffersExperiment}
            query={query}
            results={results}
            productTileVariant={VERTICAL}
            resultType={PROMOTIONS}
            totalCount={totalCount}
            withListOverlay
            isInSpecialOffersExperiment={isInSpecialOffersExperiment}
          />
        </div>

        {isInSpecialOffersExperiment && (
          <>
            <div className="special-offers-redesign results-dfp">
              {gamBanner(targeting, 'bottom')}
            </div>
            <div className="star-rating-wrapper">
              <InteractiveStarRating {...this.getStarRatingProps()} />
            </div>
          </>
        )}
        {isInSpecialOffersExperiment && content && (
          <AnalyticsContentEvent
            data={{ content }}
            pageName="promotions"
            sectionOrder={['top', 'bottom']}
          />
        )}
      </div>
    );
  }
}
