export const convertLegoKeyIntoMfeKey = key =>
  key.toLowerCase().replace('-', '_');

export function formatExperiments(
  experiments = {},
  optimizelyAttributes,
  experimentSelectors
) {
  const featureFlags = experiments['featureFlags'];
  let formattedExperiments;

  if (featureFlags) {
    const matchingExperiments = featureFlags.filter(
      f => f.key === 'GHS-UK_minibasket-V4' // Add all experiments that we need in mfe basket here
    );

    formattedExperiments = matchingExperiments.reduce(
      (acc, experiment) => ({
        ...acc,
        // convert the key name from the LEGO format into the mfe orchestrator format
        [convertLegoKeyIntoMfeKey(experiment.key)]: {
          attributes: optimizelyAttributes,
          flagKey: experiment.key,
          variables: experiment.variables,
          variationKey:
            experimentSelectors[convertLegoKeyIntoMfeKey(experiment.key)]
        }
      }),
      {}
    );
  }

  return formattedExperiments;
}
