import formatNumber from '#/lib/string-formatting/number-formatter';
import { InLineMessaging } from '@ddsweb/messaging';
import { Text } from '@ddsweb/text';
import React, { ComponentProps } from 'react';
import { StyledInLineMessaging } from '../basket-summary-content/styled';
import { FreeDelivery } from '#/lib/records/slot.defs';

type Props = {
  helpers: {
    translate: (key: string, opt?: Record<string, string>) => string;
    config: (key: string) => Record<string, string | number | boolean> | string;
  };
  freeDelivery: FreeDelivery | Record<string, never>;
  hasValidSlot: boolean;
} & ComponentProps<typeof InLineMessaging>;

const FreeDeliveryMessage: React.FC<Props> = ({
  helpers: { translate, config },
  freeDelivery,
  hasValidSlot,
  ...props
}) => {
  if (!hasValidSlot) {
    return null;
  }

  const { qualifiesForFreeDelivery, deliveryThreshold } = freeDelivery;

  if (qualifiesForFreeDelivery) {
    return (
      <StyledInLineMessaging variant="success" data-testid="freeDelivery-success" {...props}>
        <Text>{translate('trolley:free-delivery-success')}</Text>
      </StyledInLineMessaging>
    );
  }

  if (qualifiesForFreeDelivery === false && deliveryThreshold) {
    const currency = config('currency') as object;
    return (
      <StyledInLineMessaging variant="info" data-testid="freeDelivery-info" {...props}>
        <Text>
          {translate('trolley:free-delivery-info', {
            'delivery-threshold': formatNumber(deliveryThreshold, { ...currency, showAsciiSymbol: true }),
          })}
        </Text>
      </StyledInLineMessaging>
    );
  }

  return null;
};

export default FreeDeliveryMessage;
