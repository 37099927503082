import React from 'react';
import { withTheme } from 'styled-components';
import { Theme } from '@ddsweb/types';
import { FlashSashContent, FlashSashText } from './styled';
import { getHexColorFromTheme } from '#/constants/colors';

export interface FlashSashProps {
  backgroundColor?: string | undefined;
  className?: string | undefined;
  text: string;
  tileType?: string | undefined;
  theme: Theme;
}

const FlashSash = ({ backgroundColor, className, text, tileType, theme }: FlashSashProps): JSX.Element => {
  const hexColor = getHexColorFromTheme(theme, backgroundColor);
  return (
    <FlashSashContent backgroundColor={backgroundColor} hexColor={hexColor} className={className} tileType={tileType}>
      <FlashSashText backgroundColor={backgroundColor} hexColor={hexColor} tileType={tileType} theme={theme}>
        {text}
      </FlashSashText>
    </FlashSashContent>
  );
};

export default withTheme(FlashSash);
