import { getFeatureVariableStringFromState, updateRegionInKey } from '#/lib/optimizely-manager';
import { NewInBrowsePositionFeature, NewInBrowsePositionVariants } from '#/experiments/oop-2022/constants';

export const getNewInBrowseVariant = (state: Store): NewInBrowsePositionVariants => {
  const { featureKey, variationKey } = NewInBrowsePositionFeature;
  const regionInKey = updateRegionInKey(featureKey, state);
  const featureVariable = getFeatureVariableStringFromState(state, regionInKey, variationKey);

  return featureVariable || NewInBrowsePositionVariants.DISABLED;
};

export const getShouldRenderNewTag = (browseVariant: string): boolean => {
  return (
    browseVariant === NewInBrowsePositionVariants.TOP_LABEL ||
    browseVariant === NewInBrowsePositionVariants.BOTTOM_LABEL
  );
};
