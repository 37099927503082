import React from 'react';
import PropTypes from 'prop-types';
import { connect } from '#/lib/render/connect-deep-compare';
import { getSlotConfig } from '#/selectors/dfp';
import DfpBannerSlot from '#/components/shared/dfp-banner-slot';
import stringify from '#/utils/stringify';
import { cyrb53 } from '#/utils/hashing';
import { logApmError } from '#/lib/apm';
import { getDfpEnabled, getLanguage } from '#/reducers/app';
import { getAdvertisingCookieAccepted } from '#/reducers/ui';
import { getIsSpecialOffersRedesignVariant } from '#/experiments/oop-2055/selectors';

function mapStateToProps(state) {
  return {
    slotConfig: getSlotConfig(state),
    dfpEnabled: getDfpEnabled(state),
    isAdvertisingCookieAccepted: getAdvertisingCookieAccepted(state),
    language: getLanguage(state),
    isInSpecialOffersExperiment: getIsSpecialOffersRedesignVariant(state)
  };
}

@connect(mapStateToProps)
export class DfpBanner extends React.Component {
  static propTypes = {
    adSizes: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    className: PropTypes.string,
    contentId: PropTypes.string,
    dfpEnabled: PropTypes.bool.isRequired,
    emitAnalytics: PropTypes.func,
    isAdvertisingCookieAccepted: PropTypes.bool,
    language: PropTypes.string,
    placement: PropTypes.number,
    posInPanel: PropTypes.number,
    seed: PropTypes.any,
    sizeMappings: PropTypes.array,
    slotConfig: PropTypes.object.isRequired,
    targeting: PropTypes.object,
    showFullBasketBanner: PropTypes.bool
  };

  static defaultProps = {
    adSizes: 'fluid',
    targeting: {},
    showFullBasketBanner: false
  };

  constructor(props) {
    super(props);

    this.onRender = this.onRender.bind(this);
    this.id = this.getBannerId(props);
  }

  getBannerId(props) {
    try {
      const { contentId } = props;
      const identifier = contentId || cyrb53(stringify(props));

      return `dfp-banner-${identifier}`;
    } catch (err) {
      const error = new Error(
        `Failed to generate the DFP slot ID: ${err.message}`
      );
      logApmError(error, {
        stack: err.stack
      });
    }
  }

  shouldComponentUpdate({ seed }) {
    const { seed: prevSeed } = this.props;

    return seed !== prevSeed;
  }

  onRender() {
    try {
      const props = this.props;
      const { emitAnalytics } = props;

      if (typeof emitAnalytics === 'function') {
        emitAnalytics(props);
      }
    } catch (err) {
      const error = new Error(`Failed emit DFP slot analytics: ${err.message}`);
      logApmError(error, {
        stack: err.stack
      });
    }
  }

  render() {
    const {
      adSizes,
      className,
      dfpEnabled,
      language,
      seed,
      slotConfig: { adUnitPath, ppid, targeting: globalTargeting },
      sizeMappings,
      targeting,
      isAdvertisingCookieAccepted,
      showFullBasketBanner,
      enableLazyLoad = false
    } = this.props;
    const { onRender, id } = this;

    if (!dfpEnabled || !id || !adUnitPath) {
      return null;
    }

    return (
      <DfpBannerSlot
        adUnitPath={adUnitPath}
        className={className}
        showFullBasketBanner={showFullBasketBanner}
        id={id}
        onRender={onRender}
        ppid={ppid}
        seed={seed}
        sizeMapping={sizeMappings}
        sizes={adSizes}
        language={language}
        targeting={{
          ...globalTargeting,
          ...targeting
        }}
        isAdvertisingCookieAccepted={isAdvertisingCookieAccepted}
        enableLazyLoad={enableLazyLoad}
      />
    );
  }
}
