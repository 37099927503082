import { BREAKPOINTS } from '@ddsweb/constants';
import { css } from 'styled-components';

export const appBarStyles = css`
  @media (max-width: ${BREAKPOINTS.tabletLarge - 1}px) {
    height: 4px;
    .ddsweb-global-header__grid {
      display: none;
    }
  }
`;
