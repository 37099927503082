import Modal from '@ddsweb/modal';
import styled from 'styled-components';
import { spacing } from '@ddsweb/selectors';
import { media } from '@ddsweb/foundation';

export const StyledModal = styled(Modal)`
  .ddsweb-modal__mask {
    z-index: 20;
  }

  .ddsweb-modal__modal-container {
    top: 25px;
    width: 480px;
    max-width: 100%;
  }

  .ddsweb-modal__close-link {
    &:hover,
    &:focus {
      box-shadow: rgba(255, 255, 255, 0.4) 0px 0px 0px 4px;
      transition-duration: 0.3s;
      transition-timing-function: ease;
      transition-delay: 0s;
      transition-property: box-shadow;

      &:after {
        opacity: 0;
      }
    }
  }

  .ddsweb-link__anchor {
    &:hover,
    &:focus {
      box-shadow: rgba(0, 83, 159, 0.4) 0px 0px 0px 4px;
      transition-duration: 0.3s;
      transition-timing-function: ease;
      transition-delay: 0s;
      transition-property: box-shadow;

      &:after {
        opacity: 0;
      }
    }
  }
`;

export const ModalButtonContainer = styled.div`
  margin-top: ${spacing.md};

  ${media.aboveTabletLarge`
    button {
      width: auto;
      min-width: 30%;
    }
  `}
`;

export const StyledTextContainer = styled.div`
  margin-top: ${spacing.sm};
`;
