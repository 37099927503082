import styled, { css } from 'styled-components';
import { ProductTile, ImageContainer } from '@ddsweb/one-tile';
import Checkbox from '@ddsweb/checkbox';
import { spacing } from '@ddsweb/selectors';
import { Heading } from '@ddsweb/heading';
import { Text } from '@ddsweb/text';
import { BLUE, BLUE_LIGHT_2, GREY_DARK_1, GREY_MID_2, WHITE } from '@ddsweb/constants';
import { mediumSmallMax, smallMax } from '#/constants/viewports';
import { MEDIUM, SMALL_MEDIUM } from '#/components/product-tiles/temp-style-constants';
import { StepErrorIcon } from '@ddsweb/icon';

export const Content = styled.div`
  margin-bottom: ${spacing.lg};
  .ddsweb-modal__modal-container {
    max-width: 100%;
  }

  .slot-booked-time {
    font-weight: bold;
  }
`;

export const ButtonContainer = styled.section`
  display: flex;
  gap: ${spacing.xs};
  padding-top: ${spacing.xx};

  & .ddsweb-button {
    min-width: 150px;
    width: auto;
  }

  @media (max-width: ${smallMax}) {
    flex-direction: column;
  }
`;

export const StyledHeading = styled(Heading)`
  margin-bottom: ${spacing.md};
`;

export const StyledBodyText = styled(Text)`
  margin-bottom: ${spacing.sm};
`;

export const StyledCheckoutText = styled(Text)`
  font-weight: bold;
  padding: ${spacing.xs} ${spacing.sm};
  background: ${BLUE_LIGHT_2};
  color: ${GREY_DARK_1};
  margin-bottom: ${spacing.sm};
`;

export const StyledContentHead = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${spacing.sm};
  padding-bottom: ${spacing.lg};
  position: sticky;
  top: 0px;
  z-index: 999;
  background: ${WHITE};
  @media (max-width: ${mediumSmallMax}) {
    min-width: 100%;
    display: block;
  }
`;

export const StyledContentTitle = styled.div`
  max-width: 100%;

  @media (max-width: ${mediumSmallMax}) {
    padding: 0 ${spacing.md};
  }
`;

export const StyledContentAction = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: ${spacing.sm};

  .ddsweb-button {
    min-width: 120px;
    width: auto;
  }

  @media (max-width: ${mediumSmallMax}) {
    background: ${WHITE};
    position: fixed;
    bottom: 0;
    justify-content: center;
    width: 100%;
    height: 64px;
    align-items: center;
    border: 1px solid ${GREY_MID_2};
    padding: 0 ${spacing.md};

    .ddsweb-button {
      width: inherit;
    }
  }
`;

export const StyledVerticalProductTile = styled(ProductTile)`
  width: 100%;

  .ddsweb-buybox__container {
    padding-bottom: 0;
  }

  .ddsweb-quantity-controls__container {
    display: none;
  }

  &.ddsweb-product-tile {
    grid-template-areas:
      'checkbox image .'
      'title title title'
      'marketplace marketplace marketplace'
      'details details details'
      'messaging messaging messaging'
      'promotion promotion promotion'
      'buy-box buy-box buy-box'
      'footer footer footer';

    @media ${SMALL_MEDIUM} {
      grid-template-areas:
        'checkbox image title'
        'checkbox image marketplace'
        'checkbox image details'
        'checkbox image buy-box'
        'messaging messaging messaging'
        'footer footer footer'
        'promotion promotion promotion';
      grid-template-columns: 20px 80px 1fr;
    }
  }

  @media ${MEDIUM} {
    height: 100%;
    min-height: 348px;
    max-width: 215px;
    padding: ${spacing.sm};
  }
`;

export const StyledTileBox = styled.div`
  position: relative;
  width: calc((100% - 16px)/3);
}

  .ddsweb-product-tile {
    ${({ selected }: { selected: boolean }): string =>
      css`
        border: 1px solid ${selected ? BLUE : GREY_MID_2};
        opacity: ${selected ? 1 : 0.7};
      `}
  }

  @media (max-width: ${mediumSmallMax}) {
    width: 100%;
  }
`;

export const StyledVerticalImageContainer = styled(ImageContainer)`
  display: flex;
  justify-content: center;
`;

export const StyledProductTileContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${spacing.xs};

  @media (max-width: ${mediumSmallMax}) {
    padding: 0 ${spacing.md};
    justify-content: flex-start;
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  position: absolute;
  z-index: 1;
  margin: ${spacing.sm} 0 0 17px;
`;

export const CheckboxPlaceholder = styled.div`
  grid-area: checkbox;
`;

export const StyledError = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const StyledStepErrorIcon = styled(StepErrorIcon)`
  height: 64px;
  width: 64px;
  margin-bottom: ${spacing.sm};
`;

export const StyledErrorHeading = styled(Heading)`
  margin-bottom: ${spacing.md};
`;
