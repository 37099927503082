export const VIEW_ACTION = 'VIEW_ACTION';
export const LOCATION_SELECTED = 'location:selected';
export const SHOW_LOCATIONS_PAGE = 'location:show-locations-page';
export const SHOW_FEWER_LOCATIONS = 'location:slow-less-locations';
export const RECEIVE_RAW_SLOTS = 'RECEIVE_RAW_SLOTS';

// slot action types
export const CHANGE_SLOT_DATE = 'CHANGE_SLOT_DATE';
export const CHANGE_SHOPPING_METHOD = 'CHANGE_SHOPPING_METHOD';
export const CHANGE_LOCATION_RANGE = 'CHANGE_LOCATION_RANGE';
export const NEW_SLOT_DATA = 'NEW_SLOT_DATA';
export const NEW_SINGLE_SLOT = 'NEW_SINGLE_SLOT';
export const NEW_SLOT_DATA_FROM_RESOURCES = 'NEW_SLOT_DATA_FROM_RESOURCES';
export const NEW_SLOT_DATA_PENDING = 'NEW_SLOT_DATA_PENDING';
export const NO_SLOT_DATA_PENDING = 'NO_SLOT_DATA_PENDING';
export const CHANGE_SLOT_TYPE = 'CHANGE_SLOT_TYPE';
export const CHANGE_SLOT_COOKIE_ACCEPTED = 'CHANGE_SLOT_COOKIE_ACCEPTED';
export const CLOSE_CHANGE_SLOT_TYPE = 'CLOSE_CHANGE_SLOT_TYPE';
export const ORDER_TYPE_CHANGE_COOKIE_ACCEPTED = 'ORDER_TYPE_CHANGE_COOKIE_ACCEPTED';
export const CHANGE_COLLECTION_LOCATION_COOKIE_ACCEPTED = 'CHANGE_COLLECTION_LOCATION_COOKIE_ACCEPTED';
export const CHANGE_DELIVERY_ADDRESS_COOKIE_ACCEPTED = 'CHANGE_DELIVERY_ADDRESS_COOKIE_ACCEPTED';
export const BOOK_WHOOSH_SLOT_COOKIE_ACCEPTED = 'BOOK_WHOOSH_SLOT_COOKIE_ACCEPTED';
export const RESET_SELECTED_LOCATION = 'RESET_SELECTED_LOCATION';
export const CHANGING_ADDRESS = 'CHANGING_ADDRESS';
export const CHANGING_ADDRESS_SUCCESS = 'CHANGING_ADDRESS_SUCCESS';
export const CLOSE_CHANGING_ADDRESS_SUCCESS_BANNER = 'CLOSE_CHANGING_ADDRESS_SUCCESS_BANNER';
export const BUSINESS_ADDRESS_WARNING_MODAL = 'BUSINESS_ADDRESS_WARNING_MODAL';
export const START_UPDATE_SLOT_RESERVATION_EXPIRY = 'START_UPDATE_SLOT_RESERVATION_EXPIRY';
export const COMPLETE_UPDATE_SLOT_RESERVATION_EXPIRY = 'COMPLETE_UPDATE_SLOT_RESERVATION_EXPIRY';
export const UPDATE_SLOT_GROUP = 'UPDATE_SLOT_GROUP';
export const UPDATE_SELECTED_SLOT_GROUP = 'UPDATE_SELECTED_SLOT_GROUP';
export const SYNC_SELECTED_ACTUAL_VALUES = 'SYNC_SELECTED_ACTUAL_VALUES';
export const RECEIVE_FULFILMENT_OPTIONS_DETAIL = 'RECEIVE_FULFILMENT_OPTIONS_DETAIL';
export const STORE_MAP_TOOGLE = 'STORE_MAP_TOOGLE';
export const RECEIVE_FULFILMENT_ESTIMATED_ARRIVAL = 'RECEIVE_FULFILMENT_ESTIMATED_ARRIVAL';
export const DISMISS_DS_ACQUISITION_BANNER = 'DISMISS_DELIVERYSAVER_ACQUISITION_BANNER';
export const DELIVERY_SAVER_BANNER_CLOSE = 'delivery saver banner:close';
export const DELIVERY_SAVER_BANNER_VIEW_PLANS = 'delivery saver banner:view plans';
export const OPEN_SLOT_CHANGED_IN_AMEND_MODAL = 'OPEN_SLOT_CHANGED_IN_AMEND_MODAL';
export const CLOSE_SLOT_CHANGED_IN_AMEND_MODAL = 'CLOSE_SLOT_CHANGED_IN_AMEND_MODAL';

export const NEW_LOCATIONS_DATA = 'NEW_LOCATIONS_DATA';
export const SEARCH_LOCATIONS_BY_SUGGESTION_ADDRESS = 'SEARCH_LOCATIONS_BY_SUGGESTION_ADDRESS';
export const CLEAR_SUGGESTION_SELECTED_ADDRESS = 'CLEAR_SUGGESTION_SELECTED_ADDRESS';

// instructions
export const INSTRUCTIONS_CHANGED = 'INSTRUCTIONS_CHANGED';
export const INSTRUCTIONS_ERROR = 'INSTRUCTIONS_ERROR';
export const INSTRUCTIONS_SAVING = 'INSTRUCTIONS_SAVING';
export const INSTRUCTIONS_SAVED = 'INSTRUCTIONS_SAVED';
export const SET_INSTRUCTIONS = 'SET_INSTRUCTIONS';
export const FETCHING_INSTRUCTIONS = 'FETCHING_INSTRUCTIONS';

// filter action types
export const UPDATE_CANCEL_URL = 'filter:update-cancel-url';
export const UPDATE_CURRENT_URL = 'filter:update-current-url';
export const UPDATING_START = 'filter:updating-start';
export const UPDATING_STOP = 'filter:updating-stop';
export const UPDATE_FILTERS = 'filter:update-filters';
export const UPDATE_FACETS = 'filter:update-facets';
export const UPDATE_FROM_NEW_RESULTS = 'filter:update-from-new-results';

// trolley and results action types
export const TROLLEY_REQUEST_IN_PROGRESS = 'TROLLEY_REQUEST_IN_PROGRESS';
export const TROLLEY_USER_HISTORY_PUSH = 'TROLLEY_USER_HISTORY_PUSH';
export const TROLLEY_USER_HISTORY_POP = 'TROLLEY_USER_HISTORY_POP';
export const TROLLEY_USER_HISTORY_CLEAR = 'TROLLEY_USER_HISTORY_CLEAR';
export const ADD_FROM_ORDER_REQUEST_IN_PROGRESS = 'ADD_FROM_ORDER_REQUEST_IN_PROGRESS';
export const UPDATE_RESULTS_PAGE = 'UPDATE_RESULTS_PAGE';
export const UPDATE_TOTAL_COUNT = 'UPDATE_TOTAL_COUNT';
export const UPDATE_COUNT = 'UPDATE_COUNT';
export const UPDATE_RESULTS_PAGE_NO = 'UPDATE_RESULTS_PAGE_NO';
export const UPDATE_REMOVE_FAVOURITE = 'UPDATE_REMOVE_FAVOURITE';
export const CLEAR_REMOVE_FAVOURITE = 'CLEAR_REMOVE_FAVOURITE';
export const CLEAR_PAGES = 'CLEAR_PAGES';
export const IS_RESULTS_UPDATING = 'IS_RESULTS_UPDATING';
export const CLEAR_IS_RESULTS_UPDATING = 'CLEAR_IS_RESULTS_UPDATING';
export const UPDATE_FAVORITES_CAROUSEL_ITEMS = 'UPDATE_FAVORITES_CAROUSEL_ITEMS';
export const RECEIVE_RESULT_ITEMS = 'receive-items';
export const RECEIVE_TROLLEY_ITEMS = 'receive-trolley-items';
export const ITEMS_TO_UPDATE = 'items-to-update';
export const RESOLVE_TROLLEY_UPDATE = 'resolve-trolley-update';
export const RESOLVE_TROLLEY_ITEM_UPDATE = 'resolve-trolley-item-update';
export const RESOLVE_TROLLEY_ITEM_FAILED = 'resolve-trolley-item-failed';
export const RESOLVE_ADD_ALL_USUALS_UPDATE = 'resolve-add-all-usuals-update';
export const TROLLEY_EMPTIED = 'trolley-emptied';
export const UPDATE_CATCH_WEIGHT = 'update-catch-weight';
export const START_TROLLEY_UPDATE = 'start-trolley-update';
export const START_MARKETPLACE_TROLLEY_UPDATE = 'start-marketplace-trolley-update';
export const START_GROCERY_TROLLEY_UPDATE = 'start-grocery-trolley-update';
export const START_ADD_ALL_USUALS_UPDATE = 'start-add-all-usuals-update';
export const START_TROLLEY_ITEM_UPDATE = 'start-trolley-item-update';
export const UPDATE_BAG_OPTION = 'UPDATE_BAG_OPTION';
export const RECEIVE_RAW_TROLLEY = 'RECEIVE_RAW_TROLLEY';
export const UPDATE_DELIVERY_ADDRESS = 'UPDATE_DELIVERY_ADDRESS';
export const UPDATE_USER_STORE = 'UPDATE_USER_STORE';
export const UPDATE_HYF_RECOMMENDATIONS = 'UPDATE_HYF_RECOMMENDATIONS';
export const UPDATE_TESCO_RECOMMENDATIONS = 'UPDATE_TESCO_RECOMMENDATIONS';
export const CLEAR_TESCO_RECOMMENDATIONS = 'CLEAR_TESCO_RECOMMENDATIONS';
export const UPDATE_BBLG_PRODUCT = 'UPDATE_BBLG_PRODUCT';
export const REQUEST_STARTED = 'REQUEST_STARTED';
export const REQUEST_FINISHED = 'REQUEST_FINISHED';
export const UPDATE_LINE_ITEM_SUBSTITUTION_PREFERENCES = 'UPDATE_LINE_ITEM_SUBSTITUTION_PREFERENCES';
export const UPDATE_GLOBAL_SUBSTITUTION_PREFERENCES = 'UPDATE_GLOBAL_SUBSTITUTION_PREFERENCES';
export const UPDATE_PAGE_SIZE = 'UPDATE_PAGE_SIZE';
export const CLEAR_TROLLEY_ITEM_ACTION_ANALYTICS = 'CLEAR_TROLLEY_ITEM_ACTION_ANALYTICS';
export const SET_TROLLEY_ITEM_ACTION_ANALYTICS = 'SET_TROLLEY_ITEM_ACTION_ANALYTICS';
export const UPDATE_PAGE_OFFSET = 'UPDATE_PAGE_OFFSET';
export const UPDATE_TROLLEY_CUSTOMER_PREFERENCES = 'UPDATE_TROLLEY_CUSTOMER_PREFERENCES';

// coupons action types
export const COUPON_FORM_SUBMITTING_START = 'checkout:coupon-form-submitting-start';
export const COUPON_FORM_RESET = 'checkout:coupon-form-reset';
export const ADD_COUPON = 'checkout:add-coupon';
export const REMOVE_COUPON = 'checkout:remove-coupon';
export const UPDATE_COUPON = 'checkout:update-code';
export const SELECT_COUPON = 'checkout:select-coupon';
export const DESELECT_COUPON = 'checkout:deselect-coupon';
export const COUPON_SUBMITTING_START = 'checkout:coupon-submitting-start';
export const COUPON_SUBMITTING_STOP = 'checkout:coupon-submitting-stop';

// taxonomy action types
export const RECEIVE_TAXONOMY_STATE = 'taxonomy:receive-state';
export const NEW_TAXONOMY_PATH = 'taxonomy:new-taxonomy-path';
export const RECEIVE_TAXONOMY = 'taxonomy:receive-taxonomy';

// Connection action type
export const CONNECTION_TIMEOUT = 'connection-timeout';

// account action types
export const UPDATE_CONTACT_PREFERENCES = 'UPDATE_CONTACT_PREFERENCES';
export const UPDATE_PERSONAL_DETAILS = 'UPDATE_PERSONAL_DETAILS';
export const UPDATE_CLUBCARD = 'UPDATE_CLUBCARD';
export const UPDATE_CLUBCARD_ERROR = 'UPDATE_CLUBCARD_ERROR';
export const RESET_CLUBCARD = 'RESET_CLUBCARD';
export const RECEIVE_ACCOUNT_PHONE_NUMBER = 'RECEIVE_ACCOUNT_PHONE_NUMBER';
// appState
export const UPDATE_APP_STATE = 'UPDATE_APP_STATE';

// search
export const SET_SEARCH_TERM = 'SET_SEARCH_TERM';
export const UPDATE_SEARCH_SUGGESTIONS = 'UPDATE_SUGGESTIONS';
export const CLEAR_SEARCH_SUGGESTIONS = 'CLEAR_SEARCH_SUGGESTIONS';
export const SET_SUGGESTIONS_VISIBILITY = 'SET_SUGGESTIONS_VISIBILITY';
export const UPDATE_MULTI_SEARCH_LIST = 'UPDATE_MULTI_SEARCH_LIST';
export const UPDATE_MULTI_SEARCH_LIST_ITEM = 'UPDATE_MULTI_SEARCH_LIST_ITEM';
export const CLEAR_MULTI_SEARCH_LIST = 'CLEAR_MULTI_SEARCH_LIST';
export const MULTI_SEARCH_LIST_LOADING = 'MULTI_SEARCH_LIST_LOADING';
export const SET_MULTI_SEARCH_PAGE_NUMBER = 'SET_MULTI_SEARCH_PAGE_NUMBER';
export const UPDATE_SEARCH = 'UPDATE_SEARCH';

// ui
export const ACKNOWLEDGE_SELECTED_BUSINESS_ADDRESS = 'ACKNOWLEDGE_SELECTED_BUSINESS_ADDRESS';
export const CLOSE_FILTER_MENU = 'CLOSE_FILTER_MENU';
export const CLOSE_LEFT_NAV = 'CLOSE_LEFT_NAV';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const CLOSE_NAV_MENU = 'CLOSE_NAV_MENU';
export const SET_ONBOARDING_BANNER_VISIBILITY = 'SET_ONBOARDING_BANNER_VISIBILITY';
export const HIDE_AMEND_ORDER_BAR = 'HIDE_AMEND_ORDER_BAR';
export const HIDE_HEADER_SEARCH = 'HIDE_HEADER_SEARCH';
export const LOCK_BODY_SCROLL = 'LOCK_BODY_SCROLL';
export const OPEN_FILTER_MENU = 'OPEN_FILTER_MENU';
export const OPEN_LEFT_NAV = 'OPEN_LEFT_NAV';
export const OPEN_MODAL = 'OPEN_MODAL';
export const OPEN_NAV_MENU = 'OPEN_NAV_MENU';
export const RECEIVE_FAVORITES_DATA = 'RECEIVE_FAVORITES_DATA';
export const SET_MULTI_SEARCH_LINK_VISIBILITY = 'SET_MULTI_SEARCH_LINK_VISIBILITY';
export const SET_STICKY_BAR_NAME = 'SET_STICKY_BAR_NAME';
export const SET_STICKY_BAR_EXPANDED = 'SET_STICKY_BAR_EXPANDED';
export const SHOW_AMEND_ORDER_BAR = 'SHOW_AMEND_ORDER_BAR';
export const SHOW_HEADER_SEARCH = 'SHOW_HEADER_SEARCH';
export const TOGGLE_AMEND_ORDER_BAR = 'TOGGLE_AMEND_ORDER_BAR';
export const TOGGLE_EMPTY_TROLLEY_MODAL = 'TOGGLE_EMPTY_TROLLEY_MODAL';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const TOGGLE_NAV_MENU = 'TOGGLE_NAV_MENU';
export const TOGGLE_PAYMENT_CARD = 'TOGGLE_PAYMENT_CARD';
export const UNLOCK_BODY_SCROLL = 'UNLOCK_BODY_SCROLL';
export const SPA_TRANSITION = 'SPA_TRANSITION';
export const RECEIVE_FAVORITES_LAYOUT_DATA = 'RECEIVE_FAVORITES_LAYOUT_DATA';

// dcs
export const RECEIVE_FAVORITE_INTERRUPTS = 'RECEIVE_FAVORITE_INTERRUPTS';
export const RECEIVE_PROMOTED_OFFERS = 'RECEIVE_PROMOTED_OFFERS';

// interrupt
export const UPDATE_INTERRUPTS = 'UPDATE_INTERRUPTS';

// Ratings and Reviews
export const DELETE_A_REVIEW = 'DELETE_A_REVIEW';
export const SUBMIT_REVIEW = 'SUBMIT_REVIEW';
export const RECEIVE_REVIEWS = 'RECEIVE_REVIEWS';
export const REPORT_A_REVIEW = 'REPORT_A_REVIEW';
export const UPDATE_REVIEWS = 'UPDATE_REVIEWS';

// experiments
export const UPDATE_EXPERIMENTS = 'UPDATE_EXPERIMENTS';

// order
export const ORDER_LIST_DETAILS_FETCHED = 'ORDER_LIST_DETAILS_FETCHED';
export const UPDATE_ORDER_IM_HERE_ARRIVED = 'UPDATE_ORDER_IM_HERE_ARRIVED';

// dfp
export const SET_DFP_SLOT_CONFIG = 'SET_DFP_SLOT_CONFIG';

// product details
export const UPDATE_PRODUCT_DETAILS_RESULT = 'updateProductDetailsPage';
export const SYNC_PRODUCT_WITH_TROLLEY = 'syncProductWithTrolley';

// addresses
export const RECEIVE_ADDRESSES = 'RECEIVE_ADDRESSES';

// form
export const UPDATE_VALIDATION_STATE = 'update-form-validation';
export const UPDATE_VALIDATION_STATE_BY_FIELD = 'update-form-validation-by-field';
export const UPDATE_EXTERNAL_VALIDATION_STATE = 'update-external-validation-state';

export const UPDATE_LAST_FULFILLED_ORDER = 'UPDATE_LAST_FULFILLED_ORDER';
export const CLEAR_COLLECTION_SELECTED_LOCATION = 'CLEAR_COLLECTION_SELECTED_LOCATION';

// Optimistic delete
export const REMOVE_OPTIMISTIC_DELETE_SELECTION = 'REMOVE_OPTIMISTIC_DELETE_SELECTION';
export const ADD_OPTIMISTIC_DELETE_SELECTION = 'ADD_OPTIMISTIC_DELETE_SELECTION';
export const CLEAR_OPTIMISTIC_DELETE_SELECTION = 'CLEAR_OPTIMISTIC_DELETE_SELECTION';
export const REMOVE_OPTIMISTIC_DELETE_SELECTION_FROM_UI = 'REMOVE_OPTIMISTIC_DELETE_SELECTION_FROM_UI';
export const ERROR_WHILE_OPTIMISTIC_DELETE = 'ERROR_WHILE_OPTIMISTIC_DELETE';
