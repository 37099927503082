import React, { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Checkbox from '#/components/shared/checkbox';
import Radio from '#/components/shared/radio';
import prepareLabel from './label';
import helpers from '#/lib/decorators/helpers';
import {
  FILTER_OPTION_CHECKBOX,
  FILTER_OPTION_RADIO
} from '#/constants/filter-option';

const MEDIUM_BREAKPOINT = 991;
const MINIMUM_LONG_LABEL_WIDTH = 204; // arbitrary; looks good though

function getPageWidth() {
  return (typeof document !== 'undefined' && document.body.clientWidth) || 0;
}

function averageLabelWidth() {
  const scrollBuffer = 18;
  const pageWidth = getPageWidth() + scrollBuffer;
  const wideScreen = pageWidth >= MEDIUM_BREAKPOINT;
  const nonLabelSpace = wideScreen ? 298 : 258;
  const columnCount = wideScreen ? 4 : 3;

  return (pageWidth - nonLabelSpace) / columnCount;
}

function block(line) {
  return (
    <span key="block" className="filter-label--line--block">
      {line}
    </span>
  );
}

function inline(line) {
  return (
    <span key="inline" className="filter-label--line--inline">
      {line}
    </span>
  );
}

function inlinePad(line) {
  return (
    <span key="inline-pad" className="filter-label--line--inline">
      &nbsp;{line}
    </span>
  );
}

function inlineTruncate(line, longLabelsAllowed) {
  const classes = classnames(
    'filter-label--line--inline',
    'filter-label--line--truncate',
    { 'filter-label--line--truncate--long': longLabelsAllowed }
  );

  return (
    <span key="inline-truncate" className={classes}>
      {line}
    </span>
  );
}

function markupLabel(labelDesc, includeSuffix, showFullText) {
  let markup = [];

  if (labelDesc.truncate && !showFullText) {
    if (labelDesc.lines.length > 1) {
      markup = [
        block(labelDesc.lines[0]),
        inlineTruncate(labelDesc.lines[1], labelDesc.useLongLabel)
      ];
    } else {
      markup = [inlineTruncate(labelDesc.lines[0], labelDesc.useLongLabel)];
    }
  } else {
    markup = [inline(labelDesc.lines[0])];
  }

  if (includeSuffix) markup.push(inlinePad(labelDesc.suffix));

  return markup;
}

@helpers(['t'])
export default class FilterOption extends PureComponent {
  static propTypes = {
    ariaLabelledBy: PropTypes.string,
    ariaLive: PropTypes.string,
    className: PropTypes.string,
    count: PropTypes.number,
    hasTooltip: PropTypes.bool,
    id: PropTypes.any.isRequired,
    label: PropTypes.string.isRequired,
    longLabelsAllowed: PropTypes.bool,
    mode: PropTypes.oneOf([FILTER_OPTION_RADIO, FILTER_OPTION_CHECKBOX]),
    onChange: PropTypes.func,
    renderTooltip: PropTypes.func,
    selected: PropTypes.bool,
    shouldTruncateLabel: PropTypes.bool,
    showCount: PropTypes.bool,
    t: PropTypes.func.isRequired,
    url: PropTypes.string
  };

  static defaultProps = {
    longLabelsAllowed: false,
    showCount: true,
    mode: FILTER_OPTION_CHECKBOX,
    hasTooltip: false
  };

  constructor(props) {
    super(props);
    this.state = {
      longLabelsAllowed: props.longLabelsAllowed
    };
    this.targetRef = createRef();
    this.boundingRef = createRef();
  }

  setLabelWidth = () => {
    const labelWidth = averageLabelWidth();

    this.setState({
      longLabelsAllowed: labelWidth > MINIMUM_LONG_LABEL_WIDTH
    });
  };

  componentDidMount() {
    if (!this.props.longLabelsAllowed) {
      window.addEventListener('resize', this.setLabelWidth);
      this.setLabelWidth();
    }
  }

  componentWillUnmount() {
    if (!this.props.longLabelsAllowed) {
      window.removeEventListener('resize', this.setLabelWidth);
    }
  }

  renderRadio(label, linkProps) {
    const { showCount, showFullText, count } = this.props;
    return (
      <Radio
        checked={this.props.selected}
        linkProps={linkProps}
        tooltip={this.props.label}
        disabled={showCount && !count && showFullText}
        showFullText={showFullText}
      >
        <span className="visually-hidden">
          {this.props.t('sort-and-filter:filter-by')}
        </span>
        {markupLabel(label, showCount, showFullText)}
      </Radio>
    );
  }

  getDomRef = ref => {
    this.targetRef.current = ref;
  };

  renderCheckbox(identifier, label, linkProps) {
    const {
      hasTooltip,
      renderTooltip,
      label: labelFromProp,
      onChange,
      showCount,
      selected,
      t: translate
    } = this.props;

    return (
      <Checkbox
        checked={selected}
        domRef={this.getDomRef}
        id={identifier}
        linkProps={linkProps}
        name={identifier}
        onChange={onChange}
        tooltip={labelFromProp}
        whiteIcon={true}
      >
        <span className="visually-hidden">
          {translate('sort-and-filter:filter-by')}
        </span>
        {markupLabel(label, showCount)}
        {hasTooltip &&
          renderTooltip(this.targetRef, this.boundingRef, this.props)}
      </Checkbox>
    );
  }

  renderOption() {
    const {
      ariaLabelledBy,
      ariaLive,
      count,
      id,
      url,
      onChange,
      mode,
      shouldTruncateLabel,
      showFullText,
      label: labelProp
    } = this.props;

    const shouldTruncate = shouldTruncateLabel || showFullText === false;
    const label = prepareLabel(
      this.state.longLabelsAllowed,
      labelProp,
      count,
      shouldTruncate
    );
    const identifier = `filter-option--${id}-${Math.round(
      Math.random() * 10000000
    )}`
      .replace(/ &/g, '')
      .replace(/ /g, '-');
    const linkProps = {
      className: classnames('filter-option--link', {
        'filter-option--link-full-text': showFullText
      }),
      href: url,
      onClick: onChange,
      ...(ariaLabelledBy && {
        'aria-labelledby': ariaLabelledBy
      })
    };

    switch (mode) {
      case FILTER_OPTION_RADIO:
        return this.renderRadio(label, linkProps);
      default:
        return this.renderCheckbox(identifier, label, {
          ...linkProps,
          ...(ariaLive && { 'aria-live': ariaLive })
        });
    }
  }

  render() {
    const { className, id, selected, showFullText } = this.props;
    const classes = classnames(className, 'filter-option__container', {
      selected: selected,
      'filter-option__container-fullText': showFullText
    });

    return (
      <li
        ref={this.boundingRef}
        className={classes}
        key={id}
        id={id}
        data-auto="filter-option"
        data-auto-selected={selected}
      >
        {this.renderOption()}
      </li>
    );
  }
}
