import { UserState, UserDetails, UserDetailsWithEmail, CheckoutStep } from '#/custom-typings/redux-store/user.defs';
import { TAccountSettings } from '#/reducers/account-page';

import {
  RECEIVE_ACCOUNT_PHONE_NUMBER,
  RESET_CLUBCARD,
  UPDATE_APP_STATE,
  UPDATE_CLUBCARD,
  UPDATE_CLUBCARD_ERROR,
  UPDATE_CONTACT_PREFERENCES,
  UPDATE_PERSONAL_DETAILS,
  UPDATE_USER_STORE,
} from '../constants/action-types';
import { sanitiseBooleans } from '../utils/misc';

type UserActionTypes =
  | 'RECEIVE_ACCOUNT_PHONE_NUMBER'
  | 'RESET_CLUBCARD'
  | 'UPDATE_APP_STATE'
  | 'UPDATE_CLUBCARD'
  | 'UPDATE_CLUBCARD_ERROR'
  | 'UPDATE_CONTACT_PREFERENCES'
  | 'UPDATE_PERSONAL_DETAILS'
  | 'UPDATE_USER_STORE';

export type UserStateAction = {
  errors?: string[];
  type?: UserActionTypes;
  userDetails?: UserDetails;
  value?: string | boolean;
};

export const defaultUserState: UserState = {
  accessToken: '',
  accountPhoneNumber: '',
  atrc: '',
  checkoutSteps: [],
  cid: '',
  displayName: '',
  email: '',
  firstName: '',
  hashedEmail: '',
  hashedUId: '',
  id: '',
  isAuthenticated: false,
  isFirstTimeBuyer: false,
  isFirstTimeShopper: false,
  isRegistered: false,
  lastName: '',
  storeId: '',
  locationUuid: '',
  title: '',
  uuid: '',
  segments: '',
};

export default (state: UserState = defaultUserState, action: UserStateAction): UserState => {
  let newState;
  switch (action.type) {
    case UPDATE_USER_STORE:
      return { ...state, ...updateStoreId(action.value as string) };
    case UPDATE_CONTACT_PREFERENCES:
      return { ...state, ...updateContactPreferences(action.value as boolean) };
    case UPDATE_CLUBCARD:
      return { ...state, ...updateClubcard(action.value as string) };
    case UPDATE_CLUBCARD_ERROR:
      return { ...state, ...updateClubcardValidationErrors(action.errors) };
    case RESET_CLUBCARD:
      return { ...state, ...resetClubcard() };
    case UPDATE_PERSONAL_DETAILS:
      if (action.userDetails) {
        const { title = '', firstName = '', lastName = '' } = action.userDetails;
        return { ...state, title, firstName, lastName };
      }
      return state;
    case UPDATE_APP_STATE:
      // if new state is identical to old then we shouldn't create a new copy
      newState = getDefaultStateFromProps({
        user: { ...state, ...((action.value as unknown) as UserState) },
        accountSettings: null,
      });

      if (JSON.stringify(newState) === JSON.stringify(state)) {
        return state;
      }

      return newState;
    case RECEIVE_ACCOUNT_PHONE_NUMBER:
      return { ...state, ...updateAccountPhoneNumber(action.value as string) };

    default:
      return state;
  }
};

const updateStoreId = (storeId: string): { storeId: string } => ({ storeId });

const updateContactPreferences = (canBeContacted: boolean): { canBeContacted: boolean } => ({ canBeContacted });

const updateClubcard = (
  clubcardNumber: string,
): {
  clubcardNumber: string;
  clubcardValidationErrors: string[];
} => ({
  clubcardNumber,
  clubcardValidationErrors: [],
});

const updateClubcardValidationErrors = (
  clubcardValidationErrors: string[] = [],
): { clubcardValidationErrors: string[] } => ({
  clubcardValidationErrors,
});

const resetClubcard = (): { clubcardValidationErrors: string[] } => ({ clubcardValidationErrors: [] });

const updateAccountPhoneNumber = (mobilePhone: string): { accountPhoneNumber: string } => {
  return {
    accountPhoneNumber: mobilePhone,
  };
};

export const getDefaultStateFromProps = ({
  user,
  accountSettings = null,
  resources,
}: {
  user?: UserState | null;
  accountSettings?: TAccountSettings | null;
  resources?: { accountPhoneNumber?: { data: string } };
}): UserState => {
  if (!user) {
    return defaultUserState;
  }

  return {
    atrc: user.atrc || '',
    canBeContacted:
      accountSettings && accountSettings.contactPreferences ? accountSettings.contactPreferences.allowContact : false,
    checkoutSteps: user.checkoutSteps || [],
    cid: user.cid || '',
    accountPhoneNumber: user.accountPhoneNumber || resources?.accountPhoneNumber?.data,
    clubcardNumber: accountSettings && accountSettings.clubcard ? accountSettings.clubcard.clubcardNumber : null,
    clubcardValidationErrors: accountSettings && accountSettings.clubcard ? accountSettings.clubcard.errors : [],
    displayName: user.displayName || '',
    email: user.email || (accountSettings && accountSettings.email ? accountSettings.email : ''),
    firstName: user.firstName,
    hasUpdatedMarketingPreferences:
      accountSettings && accountSettings.contactPreferences ? accountSettings.contactPreferences.updated : false,
    hashedEmail: user.hashedEmail || '',
    hashedUId: user.hashedUId || '',
    id: user.id,
    isAuthenticated: sanitiseBooleans(user.isAuthenticated),
    isFirstTimeBuyer: sanitiseBooleans(user.isFirstTimeBuyer),
    isFirstTimeShopper: user.isFirstTimeShopper,
    isRegistered: user.isRegistered || false,
    lastName: user.lastName,
    pendingPersonalDetails: (accountSettings && accountSettings.pendingPersonalDetails) || {},
    personalDetailsValidationError: (accountSettings && accountSettings.personalDetailsValidationError) || false,
    storeId: user.storeId || '',
    locationUuid: user.locationUuid || '',
    title: user.title,
    accessToken: user.accessToken,
    uuid: user.uuid,
    segments: user?.segments,
  };
};

//this needs to be deprecated need to check with analytics what they need and create suitable selector.
export const getUser = ({ user }: Store): UserState => ({
  atrc: user.atrc,
  checkoutSteps: user.checkoutSteps,
  cid: user.cid,
  accountPhoneNumber: user.accountPhoneNumber,
  displayName: user.displayName,
  email: user.email,
  firstName: user.firstName,
  hashedEmail: user.hashedEmail || '',
  hashedUId: user.hashedUId,
  id: user.id,
  isAuthenticated: user.isAuthenticated,
  isFirstTimeBuyer: user.isFirstTimeBuyer,
  isFirstTimeShopper: user.isFirstTimeShopper,
  isRegistered: user.isRegistered,
  lastName: user.lastName,
  storeId: user.storeId,
  locationUuid: user.locationUuid,
  title: user.title,
  accessToken: user.accessToken,
  uuid: user.uuid,
  segments: user?.segments,
});

export const getAccountFormUserDetails = ({ user }: Store): UserDetailsWithEmail => {
  if (user.personalDetailsValidationError) {
    return Object.assign({}, user.pendingPersonalDetails, {
      email: user.email,
    });
  }

  return {
    title: user.title,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
  };
};

export const getAtrc = ({ user }: Store): string => user.atrc;

export const getCID = ({ user }: Store): string => user.cid;

export const getCanContactUser = ({ user }: Store): boolean => user.canBeContacted || false;

export const getCheckoutSteps = ({ user }: Store): CheckoutStep[] => user.checkoutSteps;

export const getClubcardNumber = ({ user }: Store): string | null => user.clubcardNumber || null;

export const getClubcardNumberErrors = ({ user }: Store): string[] | null => user.clubcardValidationErrors || null;

export const getIsFirstTimeShopper = ({ user }: Store): boolean => user.isFirstTimeBuyer as boolean;

export const getIsUserAuthenticated = ({ user }: Store): boolean => user.isAuthenticated as boolean;

export const getIsUserRegistered = ({ user }: Store): boolean => user.isRegistered || false;

export const getAccessToken = ({ user }: Store): string => user.accessToken;

export const getUserDisplayName = ({ user }: Store): string => user.displayName || '';

export const getUserEmail = ({ user }: Store): string => user.email;

export const getUserFirstName = ({ user }: Store): string => user.firstName;

export const getUserHashedEmail = ({ user }: Store): string => user.hashedEmail || '';

export const getUserHashedUId = ({ user }: Store): string => user.hashedUId || '';

export const getUserLastName = ({ user }: Store): string => user.lastName;

export const getUserStoreId = ({ user }: Store): string => user.storeId;

export const getUserLocationUuid = ({ user }: Store): string => user.locationUuid;

export const getUserTitle = ({ user }: Store): string => user.title;

export const getUserAccountPhoneNumber = ({ user }: Store): string | null => user.accountPhoneNumber || null;

export const getUserUuid = ({ user }: Store): string => user.uuid || '';

type UserAnalyticsSegments = {
  [index: string]: boolean;
  'u-an': boolean;
  'u-li': boolean;
  'u-ft': boolean;
};

export const getAnalyticsSegments = (state: Store): string[] => {
  const isFirstTimeShopper = getIsFirstTimeShopper(state);
  const authenticated = getIsUserAuthenticated(state);
  const segments: UserAnalyticsSegments = {
    'u-an': !authenticated,
    'u-li': authenticated,
    'u-ft': isFirstTimeShopper,
  };

  return Object.keys(segments).filter(segment => segments[segment]);
};
