import { getFeatureVariableStringFromState } from '#/lib/optimizely-manager';
import { getIsAmendBasket } from '#/selectors/trolley';
import { skipOffersModal } from '#/constants/features';
import { getHasHYFRecommendations } from '#/reducers/recommendations';
import { showCheckoutRecommendations } from '#/selectors/experiments';
import { TConfigFunc } from '#/lib/records/helpers.defs';

const { featureKey, variationKey, useCustomerId } = skipOffersModal;

export function showModalFromVariation(variation: string | null): boolean {
  return variation === skipOffersModal.variants.showSkipModal;
}

export function getOffersSkipModalVariant(state: Store, config: TConfigFunc): string | undefined {
  const attributes = {
    isInAmend: getIsAmendBasket(state),
    hasHYF: getHasHYFRecommendations(state),
    hasOffers: showCheckoutRecommendations(state, config),
  };

  return getFeatureVariableStringFromState(state, featureKey, variationKey, { attributes, useCustomerId });
}
