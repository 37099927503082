import { getFirstRecommendation } from '#/reducers/recommendations';
import { getProductId } from '#/selectors/item';

export const getIBYCRecommendations = (
  { recommendations: { ibycRecommendations } },
  targetTPNB
) => {
  if (!ibycRecommendations) {
    return;
  }

  if (targetTPNB) {
    const recommendations = ibycRecommendations[targetTPNB];

    return recommendations && recommendations.productItems;
  }

  const firstRecommendation = getFirstRecommendation(ibycRecommendations);

  return firstRecommendation && firstRecommendation.productItems;
};

export const getIBYCTrexStatus = (
  { recommendations: { ibycRecommendations } },
  statusKey
) => {
  return !!ibycRecommendations && !!ibycRecommendations[statusKey];
};

export const getIbycPageId = ({ recommendations: { ibycRecommendations } }) => {
  if (!ibycRecommendations) {
    return;
  }
  const recommendation = getFirstRecommendation(ibycRecommendations);

  if (recommendation) {
    return recommendation.pageInformation.pageId || '';
  }
};

export const getIbycCarouselPageId = (
  { recommendations: { ibycRecommendations } },
  targetTPNB
) => {
  if (!ibycRecommendations) {
    return;
  }
  const recommendation = ibycRecommendations[targetTPNB];

  if (recommendation) {
    return recommendation.pageInformation.pageId || '';
  }
};

export const getAllIbycRecommendations = ({ recommendations }) => {
  const { ibycRecommendations } = recommendations || {};

  if (!ibycRecommendations) {
    return undefined;
  }

  const keys = Object.keys(ibycRecommendations);
  const firstKey = keys[0];

  if (
    (firstKey === 'empty' || firstKey === 'error') &&
    ibycRecommendations[firstKey]
  ) {
    return;
  }

  return ibycRecommendations || undefined;
};

const getItemId = item => getProductId(item);

export const getIbycProdPosAndPageId = (state, productId) => {
  const recommendations = getAllIbycRecommendations(state);
  const targetTPNBs = recommendations && Object.keys(recommendations);
  const response = {};

  targetTPNBs?.find(targetTPNB => {
    let i = 1;

    recommendations[targetTPNB].productItems.forEach(item => {
      if (productId === getItemId(item)) {
        response.position = i;
        response.pageId = getIbycCarouselPageId(state, targetTPNB);
      }
      i++;
    });
  });

  return response;
};
