export const TROLLEY = 'trolley';
export const REVIEW_TROLLEY = 'review-trolley';
export const HAVE_YOU_FORGOTTEN = 'have-you-forgotten';
export const CHECKOUT_RECOMMENDATIONS = 'recommendations';
export const ORDER_SUMMARY = 'order-summary';
export const ORDER_CONFIRMATION = 'order-confirmation';
export const PROMOTIONS = 'promotions';
export const PENDING_ORDER = 'pending-order';
export const ORDER_DETAILS = 'order-details';
export const SLOTS = 'slots';
export const SEARCH = 'search';
export const PDP = 'pdp';
export const BROWSE = 'browse';
export const HOME = 'home';
export const WRITE_SPA_REVIEW = 'writeReview';
export const NAVIGATION = 'navigation';
export const SHOP = 'shop';
