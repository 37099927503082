export const ccStoreLocationDetailsVariants = {
  key: '1627',
  featureKey: 'ghs-all_collapsiblemap-change-store',
  variationKey: 'enabled',
  variants: {
    default: 'false',
    simpleLocationDetails: 'true',
  },
  useCustomerId: true,
};

export const REGION_IE = 'IE';
