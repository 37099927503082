const CountdownCallbackService = () => {
  const callbacks = {};

  const register = (key, cb) => {
    callbacks[key] = {
      done: false,
      func: cb,
    };
  };

  const execute = key => {
    const cbObj = callbacks[key];
    if (cbObj && !cbObj.done) {
      const cbFunc = cbObj.func;
      cbObj.done = true;
      return cbFunc;
    }
    return null;
  };

  return {
    register,
    execute,
  };
};

export default CountdownCallbackService;
