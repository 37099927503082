import styled from 'styled-components';
import Link from '@ddsweb/link';
import { spacing, colors, fontSize } from '@ddsweb/selectors';

export const StyledContainer = styled.div`
  width: 100%;
  display: inline-block;
  padding: ${spacing.xxxl} ${spacing.sm} ${spacing.sm};

  @media (min-width: 600px) and (max-width: 991px) {
    width: 50%;
  }

  @media (min-width: 992px) {
    width: 33.33%;
  }

  & .ddsweb-content-stamp__panel-container {
    background: ${colors.background};
    min-height: 225px;
    margin-bottom: ${spacing.md};
    padding-bottom: 0;
  }

  & .ddsweb-content-stamp__container {
    background: ${colors.background};
    border: 0;
  }

  & .ddsweb-content-stamp__container:hover {
    text-decoration: none;
  }

  & .ddsweb-content-stamp__headline {
    font-size: ${fontSize.sm};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @supports (-webkit-line-clamp: 2) {
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      white-space: initial;
    }
  }
`;
StyledContainer.displayName = 'StyledContainer';

export const BorderContainer = styled.div`
  border: 1px solid ${colors.lines};
`;
BorderContainer.displayName = 'BorderContainer';

export const StyledDiv = styled.div`
  margin: ${spacing.md} 0;

  & i svg {
    position: absolute;
  }

  & span {
    color: ${colors.text};
    font-size: ${fontSize.xs};
    line-height: ${spacing.lg};
    vertical-align: top;
    padding-left: 14px;
    margin-left: 22px;
    display: inline-block;
  }
`;
StyledDiv.displayName = 'StyledDiv';

export const StyledLink = styled(Link)`
  font-size: ${fontSize.xs};
`;
StyledLink.displayName = 'StyledLink';
