import React, { SyntheticEvent, useState } from 'react';
import Notification from '@beans/notification';
import { StyledModal, StyledModalButton, StyledText, StyledBodyText, StyledRadioButtonGroup } from '../styled';
import { TTranslateFunc } from '#/lib/records/helpers.defs';
import analyticsBus from '#/analytics/analyticsBus';
import { basicEvent } from '#/analytics/types/basic';
import { NOW } from '#/analytics/constants';
import { ADD_TO_LIST_MODAL } from '#/experiments/oop-1693/constants';
import { AnalyticsData } from '#/experiments/oop-1693/components/type.defs';

type EventType = SyntheticEvent & {
  target: {
    value: string;
  };
};

export type Props = {
  translate: TTranslateFunc;
  closeModal: (selectedOption: string, shouldFireAnalytics: boolean) => void;
};

type Option = {
  id: string;
  labelText: string;
  value: string;
};

const getOptions = (translate: TTranslateFunc): Array<Option> => {
  const prefix = 'modals:promote-favorites-plp.question';

  return Array(3)
    .fill({})
    .map((item, index) => {
      const key = `option-${index + 1}`;
      const option = translate(`${prefix}.${key}`);

      return {
        ...item,
        id: key,
        labelText: option,
        value: option,
      };
    });
};

const AddToMyListModal = ({ translate, closeModal }: Props): JSX.Element | null => {
  const [isDisabled, setDisabled] = useState(true);
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedOption, setSelectedOption] = useState<string>('');

  const sendAnalytics = (analyticsData: AnalyticsData): void => {
    basicEvent(analyticsBus, analyticsData);
  };

  const buttonClickHandler = (e: EventType): void => {
    e.stopPropagation();

    setCurrentStep(currentStep + 1);
    sendAnalytics({ action: NOW, type: ADD_TO_LIST_MODAL, value: selectedOption });
    setSelectedOption('');
  };

  const onModalClose = (e: EventType): void => {
    e?.stopPropagation?.();
    closeModal(selectedOption, currentStep === 1);
  };

  const radioChangeHandler = (e: EventType): void => {
    setSelectedOption(e.target.value);
    setDisabled(false);
  };

  const options = getOptions(translate);

  const renderQuestionnaire = (): JSX.Element => (
    <>
      <Notification variant="info" title={translate('modals:promote-favorites-plp.notification.title')} />
      <StyledRadioButtonGroup
        radioButtons={options}
        legendText={translate(`modals:promote-favorites-plp.question.title`)}
        name="AddToMyListModal"
        required
        onChange={radioChangeHandler}
      />
      <StyledModalButton variant="primary" onClick={buttonClickHandler} disabled={isDisabled}>
        {translate(`modals:promote-favorites-plp.button.submit`)}
      </StyledModalButton>
    </>
  );

  const renderThankYouModal = (): JSX.Element => (
    <>
      <StyledText tagName="h3" emphasized>
        {translate('modals:promote-favorites-plp.thank-you-title')}
      </StyledText>
      <StyledBodyText>{translate('modals:promote-favorites-plp.thank-you-message')}</StyledBodyText>
      <StyledModalButton variant="primary" onClick={onModalClose}>
        {translate('modals:promote-favorites-plp.ok')}
      </StyledModalButton>
    </>
  );

  return (
    <StyledModal open ssr id="promote-favorites-plp" onChange={onModalClose}>
      {currentStep === 1 ? renderQuestionnaire() : renderThankYouModal()}
    </StyledModal>
  );
};

export default AddToMyListModal;
