import { SEARCH, TROLLEY_CONTENTS } from '#/constants/spa-resource';
import { getSearchResource } from '#/selectors/resources';
import { updateSearch } from '#/actions/search-action-creators';
import {
  updateCurrentUrl,
  updateFromNewResults
} from '#/actions/filter-action-creators';
import { updateResults } from '#/actions/results-actions';

export const type = SEARCH;

export const params = ['query', 'trolleyItems', 'slot', 'isAmendBasket'];

export const dependencies = [TROLLEY_CONTENTS];

export function handler(dispatch, getState, options = {}) {
  const state = getState();
  let { data } = getSearchResource(state);

  if (!data) {
    return;
  }

  if (!data.results) {
    data = {
      ...data,
      results: {
        pageInformation: { pageNo: 1 },
        productItems: []
      }
    };
  }

  dispatch(updateSearch(data));
  dispatch(updateResults(data.results, type, true));

  const { updateCurrentURL } = options;

  if (updateCurrentURL) {
    dispatch(updateCurrentUrl(updateCurrentURL));
    dispatch(updateFromNewResults(data.results, updateCurrentURL));
  }
}
