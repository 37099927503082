import { FAVORITES_TAB } from '#/constants/favorites';
import {
  ACKNOWLEDGE_SELECTED_BUSINESS_ADDRESS,
  BUSINESS_ADDRESS_WARNING_MODAL,
  CHANGE_COLLECTION_LOCATION_COOKIE_ACCEPTED,
  CHANGE_DELIVERY_ADDRESS_COOKIE_ACCEPTED,
  CHANGE_SLOT_COOKIE_ACCEPTED,
  CHANGE_SLOT_TYPE,
  CHANGING_ADDRESS,
  CLOSE_CHANGE_SLOT_TYPE,
  CLOSE_FILTER_MENU,
  CLOSE_LEFT_NAV,
  CLOSE_MODAL,
  CLOSE_NAV_MENU,
  SET_ONBOARDING_BANNER_VISIBILITY,
  CONNECTION_TIMEOUT,
  HIDE_AMEND_ORDER_BAR,
  HIDE_HEADER_SEARCH,
  LOCK_BODY_SCROLL,
  OPEN_FILTER_MENU,
  OPEN_LEFT_NAV,
  OPEN_MODAL,
  OPEN_NAV_MENU,
  ORDER_TYPE_CHANGE_COOKIE_ACCEPTED,
  BOOK_WHOOSH_SLOT_COOKIE_ACCEPTED,
  RECEIVE_FAVORITES_DATA,
  SET_MULTI_SEARCH_LINK_VISIBILITY,
  SET_STICKY_BAR_NAME,
  SET_STICKY_BAR_EXPANDED,
  SHOW_AMEND_ORDER_BAR,
  SHOW_HEADER_SEARCH,
  TOGGLE_AMEND_ORDER_BAR,
  TOGGLE_EMPTY_TROLLEY_MODAL,
  TOGGLE_MODAL,
  TOGGLE_NAV_MENU,
  TOGGLE_PAYMENT_CARD,
  UNLOCK_BODY_SCROLL,
  SPA_TRANSITION,
  STORE_MAP_TOOGLE,
  REMOVE_OPTIMISTIC_DELETE_SELECTION,
  ADD_OPTIMISTIC_DELETE_SELECTION,
  CLEAR_OPTIMISTIC_DELETE_SELECTION,
  REMOVE_OPTIMISTIC_DELETE_SELECTION_FROM_UI,
  ERROR_WHILE_OPTIMISTIC_DELETE,
  OPEN_SLOT_CHANGED_IN_AMEND_MODAL,
  CLOSE_SLOT_CHANGED_IN_AMEND_MODAL,
} from '#/constants/action-types';
import { TOGGLE_RECS_CAROUSEL, UPDATE_RECS_CAROUSEL_DETAILS } from '#/experiments/oop-2349/constants';
import {
  CONNECTION_TIMEOUT_MODAL,
  GENERIC_ERROR_MODAL,
  DELETE_A_REVIEW_MODAL,
  FAKE_DOOR_THANK_YOU_MODAL,
  CHANGE_SLOT_TYPE_MODAL,
  INCORRECT_PHONE_NUMBER_MODAL,
  SLOT_CHANGED_IN_AMEND_MODAL,
} from '#/constants/modal-names';
import { chooseStickyBarName } from '#/utils/sticky-bar-utils';
import { ONBOARDING_BANNER_NAMES, ONBOARDING_BANNER_STATES } from '#/constants/onboarding';
import { getResourceData } from '#/resources/resource-util';
import { FAVORITES, USUALS, LAST_ORDER } from '#/constants/spa-resource';
import {
  ORDER_TYPE_CHANGE,
  SLOT_CHANGE,
  CHANGE_COLLECTION_LOCATION,
  CHANGE_DELIVERY_ADDRESS,
  BOOK_WHOOSH_SLOT,
} from '#/constants/slot-warning-modal-types';
import { UIState } from '#/custom-typings/redux-store/ui.defs';
import {
  UIActions,
  FavoritesData,
  FavoritesLayoutData,
  DeleteAReviewModal as DeleteAReviewModalAction,
  ThankYouModal,
  IncorrectPhoneModal,
} from '#/actions/ui-action-creators.defs';
import { ModalData } from '#/reducers/types.defs';
import { ServerProps } from './types.defs';
import { RECEIVE_FAVORITES_LAYOUT_DATA } from '#/constants/action-types';
import { OFFERS_FOR_YOU } from '#/experiments/oop-1557/constants/spa-resource';
import { GROCERIES_MENU_KEY } from '#/selectors/ui/constants';

import { getGridViewWithSubsAndBannersVariant } from '#/experiments/oop-2011/selectors';
import { getDefaultFavLayout } from '#/experiments/oop-2011/helpers';

type TimeOutModal = {
  currentModal: typeof CONNECTION_TIMEOUT_MODAL;
};

const showTimeOutModal = (): TimeOutModal => ({
  currentModal: CONNECTION_TIMEOUT_MODAL,
});

type DeleteAReviewModal = {
  currentModal: typeof DELETE_A_REVIEW_MODAL;
  modalData: ModalData;
};

const showDeleteAReviewModal = ({ reviewId }: DeleteAReviewModalAction['value']): DeleteAReviewModal => ({
  currentModal: DELETE_A_REVIEW_MODAL,
  modalData: reviewId,
});

type Nav = {
  menuOpen?: string | null;
  overlayShown: boolean;
  groceriesOpen: boolean;
  leftNavOpen: boolean;
};

const openLeftNav = (): Nav => ({
  menuOpen: null,
  overlayShown: true,
  groceriesOpen: false,
  leftNavOpen: true,
});

const closeLeftNav = (): Partial<Nav> => ({
  leftNavOpen: false,
  overlayShown: false,
});

const openNavMenu = (menuKey = GROCERIES_MENU_KEY): Nav => ({
  leftNavOpen: false,
  overlayShown: true,
  menuOpen: menuKey || GROCERIES_MENU_KEY,
  groceriesOpen: menuKey === GROCERIES_MENU_KEY,
});

const closeNavMenu = (): Partial<Nav> => ({
  menuOpen: null,
  overlayShown: false,
  groceriesOpen: false,
});

const toggleNavMenu = (state: UIState, menuKey?: string): Partial<Nav> => {
  if (state.menuOpen === menuKey) return closeNavMenu();

  return openNavMenu(menuKey);
};

type HeaderSearch = {
  headerSearchVisible: boolean;
};

const showHeaderSearch = (): HeaderSearch => ({
  headerSearchVisible: true,
});

const hideHeaderSearch = (): HeaderSearch => ({
  headerSearchVisible: false,
});

type ToggleBodyScroll = {
  overlayShown: boolean;
  scrollLocked: boolean;
};

const lockBodyScroll = (): ToggleBodyScroll => ({
  overlayShown: true,
  scrollLocked: true,
});

const unlockBodyScroll = (): ToggleBodyScroll => ({
  overlayShown: false,
  scrollLocked: false,
});

type AddressWarningModal = {
  displayBusinessAddressModal: boolean;
};

const businessAddressWarningModal = (): AddressWarningModal => ({
  displayBusinessAddressModal: true,
});

const acknowledgeSelectedBusinessAddress = (): AddressWarningModal => ({
  displayBusinessAddressModal: false,
});

type FakeDoorThankYouModal = {
  currentModal: typeof FAKE_DOOR_THANK_YOU_MODAL;
  modalData: string;
};

const showThankYouFeedbackModal = (modalData: ThankYouModal['value']): FakeDoorThankYouModal => ({
  currentModal: FAKE_DOOR_THANK_YOU_MODAL,
  modalData,
});

type IncorrectPhoneNumberModal = {
  currentModal: typeof INCORRECT_PHONE_NUMBER_MODAL;
  modalData: IncorrectPhoneModal['value'];
};

const showIncorrectPhoneNumberModal = (modalData: IncorrectPhoneModal['value']): IncorrectPhoneNumberModal => ({
  currentModal: INCORRECT_PHONE_NUMBER_MODAL,
  modalData,
});

type EmptyTrolleyModalOpen = {
  emptyTrolleyModalOpen: boolean;
};

export const toggleEmptyTrolleyModal = (state: UIState): EmptyTrolleyModalOpen => ({
  emptyTrolleyModalOpen: !state.emptyTrolleyModalOpen,
});

type ToggleAmendOrderBar = {
  hideAmendOrderBar: boolean;
};

const hideAmendOrderBar = (): ToggleAmendOrderBar => ({
  hideAmendOrderBar: true,
});

const showAmendOrderBar = (): ToggleAmendOrderBar => ({
  hideAmendOrderBar: false,
});

type ToggleAmendOrderBarOpen = {
  amendOrderBarOpen: boolean;
};

const toggleAmendOrderBar = (state: UIState, isOpen: boolean): ToggleAmendOrderBarOpen => ({
  amendOrderBarOpen: isOpen,
});

type ToggleModal = {
  currentModal: string | null;
  scrollLocked: boolean;
  overlayShown: boolean;
};

const toggleModal = (state: UIState, modalName: string): ToggleModal => ({
  currentModal: modalName === state.currentModal ? null : modalName,
  scrollLocked: !state.scrollLocked,
  overlayShown: !state.overlayShown,
});

type OepnModal = {
  leftNavOpen: boolean;
  overlayShown: boolean;
  scrollLocked: boolean;
  currentModal: string;
  modalData: ModalData;
};

const openModal = (state: UIState, modalName: string, modalData: ModalData): OepnModal => ({
  leftNavOpen: false,
  overlayShown: true,
  scrollLocked: true,
  currentModal: modalName,
  modalData,
});

type CloseModal = {
  currentModal: string | null;
  scrollLocked: boolean;
  overlayShown: boolean;
};

const closeModal = (): CloseModal => ({
  currentModal: null,
  scrollLocked: false,
  overlayShown: false,
});

type EditCardDetails = {
  editCardDetails: boolean;
};

const toggleCardInput = (): EditCardDetails => ({
  editCardDetails: true,
});

const receiveFavoritesData = (favoritesData: FavoritesData['payload']): Partial<UIState> => ({
  selectedFavoritesTab: favoritesData.newTab,
  removedFavorites: {},
});

const receiveFavoritesLayoutData = (
  favoritesLayoutData: FavoritesLayoutData['payload'],
): { selectedFavoritesLayout: string } => ({
  selectedFavoritesLayout: favoritesLayoutData.newLayout,
});

export const getDisplayBusinessAddressModal = ({ ui }: Store): boolean => ui.displayBusinessAddressModal;

export const getJourney = ({ ui: { journey } }: Store): UIState['journey'] => journey;

export const getStickyBarName = ({ ui: { stickyBarName } }: Store): UIState['stickyBarName'] => stickyBarName;

export const getSlotExpiryThresholdMinutes = ({
  ui: { slotExpiryThresholdMinutes },
}: Store): UIState['slotExpiryThresholdMinutes'] => slotExpiryThresholdMinutes;

export const getChangeSlotCookieAccepted = ({
  ui: { changeSlotCookieAccepted },
}: Store): UIState['changeSlotCookieAccepted'] => changeSlotCookieAccepted;

export const getChangeLocationCookieAccepted = ({
  ui: { changeLocationCookieAccepted },
}: Store): UIState['changeLocationCookieAccepted'] => changeLocationCookieAccepted;

export const isStickyBarExpanded = ({ ui: { stickyBarExpanded } }: Store): UIState['stickyBarExpanded'] =>
  stickyBarExpanded;

export const isNavMenuOpen = ({ ui: { menuOpen, leftNavOpen } }: Store): boolean =>
  menuOpen === GROCERIES_MENU_KEY || leftNavOpen;

export const isHeaderSearchOpen = ({ ui: { headerSearchVisible } }: Store): UIState['headerSearchVisible'] =>
  headerSearchVisible;

export const getHeaderMultiSearchLinkVisibility = ({ ui }: Store): boolean => ui && ui.headerMultiSearchLinkVisible;

export function getOnboardingBannerVisibility({ ui }: Store, bannerName: string): boolean {
  return ui.onboardingBannersVisibility[bannerName];
}

export const getSelectedFavoritesTab = ({ ui }: Store): string => {
  return ui.selectedFavoritesTab || FAVORITES_TAB;
};

export const getSelectedFavoritesLayout = (state: Store): string => {
  const { ui } = state;
  return ui.selectedFavoritesLayout || getDefaultFavLayout(getGridViewWithSubsAndBannersVariant(state));
};

export const getDisplayCookieInterrupt = ({
  ui: { displayCookieInterrupt },
}: Store): UIState['displayCookieInterrupt'] => displayCookieInterrupt;

export const getExperienceCookieAccepted = ({
  ui: { isExperienceCookieAccepted },
}: Store): UIState['isExperienceCookieAccepted'] => isExperienceCookieAccepted;

export const getAdvertisingCookieAccepted = ({
  ui: { isAdvertisingCookieAccepted },
}: Store): UIState['isAdvertisingCookieAccepted'] => isAdvertisingCookieAccepted;

export function getHasAcceptedSlotWarningTypes(state: Store, type: string): boolean | undefined {
  const {
    ui: {
      orderTypeChangeCookieAccepted,
      changeSlotCookieAccepted,
      changeLocationCookieAccepted,
      changeAddressCookieAccepted,
      bookWhooshSlotCookieAccepted,
    },
  } = state;

  switch (type) {
    case BOOK_WHOOSH_SLOT:
      return bookWhooshSlotCookieAccepted;
    case ORDER_TYPE_CHANGE:
      return orderTypeChangeCookieAccepted;
    case SLOT_CHANGE:
      return changeSlotCookieAccepted;
    case CHANGE_COLLECTION_LOCATION:
      return changeLocationCookieAccepted;
    case CHANGE_DELIVERY_ADDRESS:
      return changeAddressCookieAccepted;
  }
}

export const getIsStoreMapOpenState = ({ ui: { isStoreMapOpen } }: Store): UIState['isStoreMapOpen'] => isStoreMapOpen;

// OOP-2349 - Recs carousel related details
export const getIsRecsCarouselVisible = ({ ui: { isRecsCarouselVisible } }: Store): UIState['isRecsCarouselVisible'] =>
  isRecsCarouselVisible;

export const getRecsCarouselDetails = ({ ui: { recsCarouselDetails } }: Store): UIState['recsCarouselDetails'] =>
  recsCarouselDetails;

export default function ui(state: UIState = {} as UIState, action: UIActions): UIState {
  switch (action.type) {
    case OPEN_LEFT_NAV:
      return { ...state, ...openLeftNav() };
    case CLOSE_LEFT_NAV:
      return { ...state, ...closeLeftNav() };
    case OPEN_NAV_MENU:
      const menuKey = action?.payload?.menuKey || state.menuOpen || undefined;
      return { ...state, ...openNavMenu(menuKey) };
    case CLOSE_NAV_MENU:
      return { ...state, ...closeNavMenu() };
    case TOGGLE_NAV_MENU:
      return { ...state, ...toggleNavMenu(state, action?.payload?.menuKey) };
    case SHOW_HEADER_SEARCH:
      return { ...state, ...showHeaderSearch() };
    case HIDE_HEADER_SEARCH:
      return { ...state, ...hideHeaderSearch() };
    case SET_MULTI_SEARCH_LINK_VISIBILITY: {
      return { ...state, headerMultiSearchLinkVisible: action.visibility };
    }
    case TOGGLE_EMPTY_TROLLEY_MODAL:
      return { ...state, ...toggleEmptyTrolleyModal(state) };
    case HIDE_AMEND_ORDER_BAR:
      return { ...state, ...hideAmendOrderBar() };
    case SHOW_AMEND_ORDER_BAR:
      return { ...state, ...showAmendOrderBar() };
    case TOGGLE_AMEND_ORDER_BAR:
      return { ...state, ...toggleAmendOrderBar(state, action.payload.isOpen) };
    case SET_STICKY_BAR_NAME: {
      if (state.stickyBarName === action.payload.name) {
        return state;
      }
      const stickyBarExpanded = state.stickyBarName === null && action.payload.name ? true : state.stickyBarExpanded;

      return {
        ...state,
        stickyBarName: action.payload.name,
        stickyBarExpanded,
      };
    }
    case SET_STICKY_BAR_EXPANDED:
      return { ...state, stickyBarExpanded: action.payload.isVisible };
    case LOCK_BODY_SCROLL:
      return { ...state, ...lockBodyScroll() };
    case UNLOCK_BODY_SCROLL:
      return { ...state, ...unlockBodyScroll() };
    case TOGGLE_MODAL:
      return { ...state, ...toggleModal(state, action.payload.modalName) };
    case OPEN_MODAL:
      return {
        ...state,
        ...openModal(state, action.payload.modalName, action.payload.modalData),
      };
    case CLOSE_MODAL:
      return { ...state, ...closeModal() };
    case TOGGLE_PAYMENT_CARD:
      return { ...state, ...toggleCardInput() };
    case SET_ONBOARDING_BANNER_VISIBILITY:
      return {
        ...state,
        onboardingBannersVisibility: {
          ...state.onboardingBannersVisibility,
          [action.bannerName]: action.visible,
        },
      };
    case RECEIVE_FAVORITES_DATA:
      return { ...state, ...receiveFavoritesData(action.payload) };
    case RECEIVE_FAVORITES_LAYOUT_DATA:
      return { ...state, ...receiveFavoritesLayoutData(action.payload) };
    case GENERIC_ERROR_MODAL:
    case CONNECTION_TIMEOUT:
      return { ...state, ...showTimeOutModal() };
    case ORDER_TYPE_CHANGE_COOKIE_ACCEPTED:
      return { ...state, orderTypeChangeCookieAccepted: true };
    case BOOK_WHOOSH_SLOT_COOKIE_ACCEPTED:
      return { ...state, bookWhooshSlotCookieAccepted: true };
    case CHANGE_SLOT_COOKIE_ACCEPTED:
      return { ...state, changeSlotCookieAccepted: true };
    case CHANGE_COLLECTION_LOCATION_COOKIE_ACCEPTED:
      return { ...state, changeLocationCookieAccepted: true };
    case CHANGE_DELIVERY_ADDRESS_COOKIE_ACCEPTED:
      return { ...state, changeAddressCookieAccepted: true };
    case OPEN_FILTER_MENU:
      return { ...state, filterMenuOpen: true };
    case CLOSE_FILTER_MENU:
      return { ...state, filterMenuOpen: false };
    case BUSINESS_ADDRESS_WARNING_MODAL:
      return { ...state, ...businessAddressWarningModal() };
    case ACKNOWLEDGE_SELECTED_BUSINESS_ADDRESS:
      return { ...state, ...acknowledgeSelectedBusinessAddress() };
    case DELETE_A_REVIEW_MODAL:
      return {
        ...state,
        ...showDeleteAReviewModal(action.value),
      };
    case FAKE_DOOR_THANK_YOU_MODAL:
      return {
        ...state,
        ...showThankYouFeedbackModal(action.value),
      };
    case SPA_TRANSITION:
      return { ...state, spaTransition: action.payload };
    case CHANGE_SLOT_TYPE:
      return { ...state, currentModal: CHANGE_SLOT_TYPE_MODAL };
    case OPEN_SLOT_CHANGED_IN_AMEND_MODAL:
      return { ...state, currentModal: SLOT_CHANGED_IN_AMEND_MODAL };
    case CLOSE_SLOT_CHANGED_IN_AMEND_MODAL:
      return { ...state, currentModal: null };
    case CLOSE_CHANGE_SLOT_TYPE:
      return { ...state, currentModal: null };
    case CHANGING_ADDRESS:
      return { ...state, currentModal: CHANGE_SLOT_TYPE_MODAL };
    case REMOVE_OPTIMISTIC_DELETE_SELECTION: {
      const {
        value: { page, id },
      } = action;

      const { selections = [] } = state.optimisticDeleteSelection[page] || {};
      const optimisticDeleteSelection = {
        ...state.optimisticDeleteSelection,
        [page]: {
          ...state.optimisticDeleteSelection[page],
          selections: selections.filter(ele => ele !== id),
          softDelete: false,
          error: false,
        },
      };

      return { ...state, optimisticDeleteSelection };
    }
    case REMOVE_OPTIMISTIC_DELETE_SELECTION_FROM_UI: {
      const {
        value: { page },
      } = action;

      const optimisticDeleteSelection = {
        ...state.optimisticDeleteSelection,
        [page]: { ...state.optimisticDeleteSelection[page], softDelete: true, error: false },
      };

      return { ...state, optimisticDeleteSelection };
    }

    case ERROR_WHILE_OPTIMISTIC_DELETE: {
      const {
        value: { page, hasError = true },
      } = action;

      const optimisticDeleteSelection = {
        ...state.optimisticDeleteSelection,
        [page]: { ...state.optimisticDeleteSelection[page], softDelete: true, error: hasError },
      };

      return { ...state, optimisticDeleteSelection };
    }

    case ADD_OPTIMISTIC_DELETE_SELECTION:
      const {
        value: { page, id },
      } = action;

      const { selections = [] } = state.optimisticDeleteSelection[page] || {};
      const optimisticDeleteSelection = {
        ...state.optimisticDeleteSelection,
        [page]: {
          ...state.optimisticDeleteSelection[page],
          selections: [...selections, id],
          softDelete: false,
          error: false,
        },
      };

      return { ...state, optimisticDeleteSelection };
    case CLEAR_OPTIMISTIC_DELETE_SELECTION: {
      const {
        value: { page },
      } = action;
      const optimisticDeleteSelection = {
        ...state.optimisticDeleteSelection,
        [page]: { selections: [], softDelete: false, error: false },
      };

      return { ...state, optimisticDeleteSelection };
    }
    case INCORRECT_PHONE_NUMBER_MODAL:
      return {
        ...state,
        ...showIncorrectPhoneNumberModal(action.value),
      };
    case STORE_MAP_TOOGLE:
      return { ...state, isStoreMapOpen: true };
    case TOGGLE_RECS_CAROUSEL:
      return { ...state, isRecsCarouselVisible: !state.isRecsCarouselVisible };
    case UPDATE_RECS_CAROUSEL_DETAILS:
      return { ...state, recsCarouselDetails: action.recsCarouselDetails };
    default:
      return state;
  }
}

type OnboardingBannersVisibility = UIState['onboardingBannersVisibility'];

function getOnboardingBannersVisibility(props: ServerProps): OnboardingBannersVisibility {
  const onboardingBannerCookieValues = props.onboardingBannerCookieValues || {};

  return Object.values(ONBOARDING_BANNER_NAMES).reduce<OnboardingBannersVisibility>((obj, bannerName) => {
    obj[bannerName] = onboardingBannerCookieValues[bannerName] !== ONBOARDING_BANNER_STATES.HIDDEN;

    return obj;
  }, {});
}

/*
 * Called on the server side to get default state from initial props from router
 */
export const getDefaultStateFromProps = (props: ServerProps): Partial<UIState> => {
  const isAmendBasket = props.trolley && props.trolley.isAmendBasket;
  const favoritesResource = getResourceData(props.resources, FAVORITES);
  const usualsResource = getResourceData(props.resources, USUALS);
  const lastOrderResource = getResourceData(props.resources, LAST_ORDER);
  const offersForYouResource = getResourceData(props.resources, OFFERS_FOR_YOU);
  const availableResource = favoritesResource || usualsResource || lastOrderResource || offersForYouResource;
  const selectedFavoritesTab = availableResource && availableResource.selectedFavoritesTab;
  const selectedFavoritesLayout = favoritesResource && favoritesResource.selectedFavoritesLayout;
  const slotExpiryThresholdMinutes = props.helpers.c('slotExpiryThresholdMinutes');
  const optimizelySiteId = props.helpers.c('optimizely:siteId');
  const debugExperiments = props.helpers.f('debugExperiments');
  const stickyBarName = chooseStickyBarName({
    isAmendBasket,
    journey: props.journey,
    displayCookieInterrupt: props.displayCookieInterrupt,
  });

  return {
    overlayShown: props.overlayShown || !!props.currentModal,
    scrollLocked: props.overlayShown || !!props.currentModal,
    groceriesOpen: !!props.groceriesOpen,
    optimizelyEnabled: props.optimizelyEnabled,
    amendOrderBarOpen: false,
    leftNavOpen: false,
    menuOpen: !!props.menuOpen ? GROCERIES_MENU_KEY : null,
    orderTypeChangeCookieAccepted: false,
    bookWhooshSlotCookieAccepted: false,
    openModal: false,
    headerSearchVisible: props.headerSearchVisible || true,
    headerMultiSearchLinkVisible: false,
    emptyTrolleyModalOpen: props.emptyTrolleyModalOpen,
    hideAmendOrderBar: false,
    currentModal: props.currentModal,
    modalData: props.modalData,
    isLoading: false,
    journey: props.journey || null,
    selectedFavoritesTab: props.selectedFavoritesTab || selectedFavoritesTab || FAVORITES_TAB,
    selectedFavoritesLayout: props.selectedFavoritesLayout || selectedFavoritesLayout,
    changeSlotCookieAccepted: false,
    changeLocationCookieAccepted: false,
    changeAddressCookieAccepted: false,
    expandLeftNav: !!props.expandLeftNav,
    displayBusinessAddressModal: false,
    slotExpiryThresholdMinutes: slotExpiryThresholdMinutes as number,
    stickyBarName,
    removedFavorites: {},
    stickyBarExpanded: false,
    onboardingBannersVisibility: getOnboardingBannersVisibility(props),
    isError: !!props.error,
    optimizelySiteId: optimizelySiteId as string,
    debugExperiments: debugExperiments as boolean,
    displayCookieInterrupt: props.displayCookieInterrupt,
    isExperienceCookieAccepted: props.isExperienceCookieAccepted,
    isAdvertisingCookieAccepted: props.isAdvertisingCookieAccepted,
    optimisticDeleteSelection: {},
    addToFavorites: {
      id: '',
    },
    isRecsCarouselVisible: false,
    recsCarouselDetails: { itemIndex: null },
  };
};
