export const AMEND_CANCEL_MODAL = 'amendCancel';
export const AMEND_EXPIRED_MODAL = 'amendExpired';
export const AMEND_SAVE_MODAL = 'amendSave';
export const AUTH_REFRESH_REQUIRED_MODAL = 'authRefreshRequired';
export const BAG_OPTIONS_MODAL = 'bagOptions';
export const CONNECTION_TIMEOUT_MODAL = 'connectionTimeout';
export const CHANGE_SLOT_TYPE_MODAL = 'changeSlotType';
export const ECOUPON_MODAL = 'eCoupon';
export const EMPTY_TROLLEY_MODAL = 'emptyTrolley';
export const FAKE_DOOR_TEST = 'fakeDoorTest';
export const FAKE_DOOR_TEST_WORKING_ON_IT = 'fakeDoorTestWorkingOnIt';
export const IMAGE_ZOOM_MODAL = 'ImageZoomModal';
export const ONE_ACCOUNT_ADDRESS_MODAL_BOOK_A_SLOT =
  'oneAccountAddressBookASlot';

export const NO_RESULT_MODAL = 'noResult';
export const PENDING_ORDER_CANCEL_MODAL = 'pendingOrderCancel';
export const REMOVE_FAVOURITE_MODAL = 'removeFavourite';
export const THREE_D_SECURE_MODAL = 'threeDSecure';
export const BOOK_SLOT_SELECT_UNDELIVERABLE_ADDRESS_MODAL =
  'bookASlotUndeliverableAddressSelected';
export const UNDELIVERABLE_ADDRESS_MODAL = 'undeliverableAddress';
export const FORESEE_MODAL = 'foreseeModal';
export const ITEMS_UNAVAILABLE_MODAL = 'itemsUnavailable';
export const GENERIC_ERROR_MODAL = 'error';
export const DELETE_A_REVIEW_MODAL = 'deleteReview';
export const FAKE_DOOR_THANK_YOU_MODAL = 'openThankYou';
export const BASKET_CAPACITY_EXCEED_MODAL = 'capacityExceeded';
export const INCORRECT_PHONE_NUMBER_MODAL = 'incorrectPhoneNumber';
export const CANCEL_SLOT_BOOKING_MODAL = 'cancelSlotBooking';
export const SLOT_EXPIRY_MODAL = 'slotExpiryModal';
export const CARD_SAVE_MESSAGE_MODAL = 'cardSaveMessage';
export const SKIP_OFFERS_MODAL = 'skipOffersModal';
export const CLUBCARD_OPT_IN_MODAL = 'clubcardOptInModal';
export const FAV_QUICK_BASKET_MODAL = 'favQuickBasketModal';
export const UNAVAILABLE_SHOPPING_METHOD_MODAL = 'unavailableShoppingMethod';
export const SLOT_REBOOK_MODAL = 'slotRebookModal';
export const SLOT_REMIND_MODAL = 'slotRemindModal';
export const INTERESTED_IN_THIS_SERVICE_MODAL = 'interestedInThisServiceModal';
export const SLOT_CHANGED_IN_AMEND_MODAL = 'slotChangedInAmendModal';
export const CHANGE_DETAILS_MODAL_BOOK_A_SLOT_EDIT_ADDRESS =
  'changeDetailsBookASlotEditAddress';
export const CHANGE_DETAILS_MODAL_BOOK_A_SLOT_ADD_ADDRESS =
  'changeDetailsBookASlotAddAddress';
export const RELOAD_PAGE_MODAL = 'reloadPageModal';
export const MARKETPLACE_PRODUCT_MODAL = 'marketPlaceProductModal';
