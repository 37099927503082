export const singleValueTileUKTemplate = {
  xxp: {
    valueContent: [
      {
        text: `%{value1}%{value3}`,
        size: 'xxl'
      }
    ]
  },
  x_pound: {
    valueContent: [
      {
        text: `%{value2}%{value1}`,
        size: ['xxxl', 'xxl', 'lg', 'sm', 'xs']
      }
    ]
  },
  xxp_each: {
    valueContent: [
      {
        text: `%{value1}%{value3}`,
        size: 'xxl'
      },
      {
        text: 'each',
        size: 'xx'
      }
    ]
  },
  x_pound_each: {
    valueContent: [
      {
        text: `%{value2}%{value1}`,
        size: ['xxxl', 'xxl', 'md', 'xs']
      },
      {
        text: 'each',
        size: 'xx'
      }
    ]
  },
  x_pound_or_less: {
    valueContent: [
      {
        text: `%{value2}%{value1}`,
        size: ['xxxl', 'xxxl', 'lg', 'sm']
      },
      {
        text: 'or less',
        size: 'xx'
      }
    ]
  },
  x_pound_meal_deal: {
    valueContent: [
      {
        text: `%{value2}%{value1}`,
        size: ['xxxl', 'xxxl', 'lg', 'sm']
      },
      {
        text: 'meal deal',
        size: 'xx'
      }
    ]
  },
  from_x_pound: {
    valueContent: [
      {
        text: 'From',
        size: 'xx'
      },
      {
        text: `%{value2}%{value1}`,
        size: ['xl', 'xl', 'md', 'xs']
      }
    ]
  },
  from_xxp: {
    valueContent: [
      {
        text: 'From',
        size: 'xx'
      },
      {
        text: `%{value1}%{value3}`,
        size: ['xl', 'xl', 'md', 'xs']
      }
    ]
  },
  save_x_pound: {
    valueContent: [
      {
        text: 'Save',
        size: 'xl'
      },
      {
        text: `%{value2}%{value1}`,
        size: ['xl', 'xl', 'md', 'xs']
      }
    ]
  },
  'save_xx%': {
    valueContent: [
      {
        text: 'Save',
        size: 'xl'
      },
      {
        text: `%{value1}%`,
        size: 'xl'
      }
    ]
  },
  'xx%_off': {
    valueContent: [
      {
        text: `%{value1}%`,
        size: 'xl'
      },
      {
        text: 'off',
        size: 'xl'
      }
    ]
  },
  save_xxp: {
    valueContent: [
      {
        text: 'Save',
        size: 'xl'
      },
      {
        text: `%{value1}%{value3}`,
        size: 'xl'
      }
    ]
  },
  xxp_clubcard_price: {
    horizontal: {
      valueContent: [
        {
          text: `%{value1}%{value3}`,
          size: 'sm'
        }
      ]
    },
    square: {
      valueContent: [
        {
          text: `%{value1}%{value3}`,
          size: 'xl'
        }
      ]
    },
    mode: 'clubcard'
  },
  x_pound_clubcard_price: {
    horizontal: {
      valueContent: [
        {
          text: `%{value2}%{value1}`,
          size: 'sm'
        }
      ]
    },
    square: {
      valueContent: [
        {
          text: `%{value2}%{value1}`,
          size: ['xl', 'xl', 'md', 'sm', 'xs']
        }
      ]
    },
    mode: 'clubcard'
  }
};
