import { bookedSlotPayload } from '../helpers/slot';
import { basketItem } from '../helpers/trolley';
import AnalyticsBus from '../analyticsBus';

function emitEvent(eventName, mandatoryData, optionalData = {}) {
  const {
    currency,
    guidePrice,
    items,
    shoppingMethod,
    slot,
    timezone
  } = mandatoryData;
  const hasActiveSlot = ![null, 'None', 'Expired'].includes(slot.status);
  const slotShoppingMethod = hasActiveSlot ? shoppingMethod : null;

  const payload = {
    guidePrice,
    ...(optionalData.totalPrice && { totalPrice: optionalData.totalPrice }),
    bookedSlot: bookedSlotPayload({
      start: slot.start,
      end: slot.end,
      charge: slot.charge,
      currency,
      method: slotShoppingMethod,
      timezone,
      locationId: slot.locationId
    })
  };
  if (items) {
    payload.items = items;
  }

  AnalyticsBus().emit(eventName, Object.assign(payload, optionalData));
}

const itemsToBasketItems = (items, currency) =>
  items.map(item => basketItem(item, currency));

export function emitTrolleyEvent(mandatoryData, optionalData) {
  if (mandatoryData.items) {
    mandatoryData.items = itemsToBasketItems(
      mandatoryData.items,
      mandatoryData.currency
    );
  }

  emitEvent('trolleyData', mandatoryData, optionalData);
}

export function emitTrolleyAmendEvent(mandatoryData) {
  const { orderNumber, paymentMode } = mandatoryData;

  const basketItems = itemsToBasketItems(
    mandatoryData.items,
    mandatoryData.currency
  );

  mandatoryData.items = basketItems.filter(item => item.qtyChange !== 0);

  emitEvent('trolleyAmend', mandatoryData, {
    orderNumber,
    paymentMode
  });
}
