import React from 'react';
import PropTypes from 'prop-types';
import { IndexRoute, Route, browserHistory } from 'react-router';
import page from 'page';
import deepEqual from 'deep-equal';
import { connect } from '#/lib/render/connect-deep-compare';
import { extractPropsFromWrappedResources } from '#/resources/spa-utils';
import { fetchResources } from '#/actions/resources-action-creators';
import { changeRoute, clearReferrer } from '#/actions/app-actions-creators';
import {
  openNavMenu,
  closeNavMenu,
  closeLeftNav,
  spaTransition
} from '#/actions/ui-action-creators';
import {
  newTaxonomyPath,
  resetTaxonomy
} from '#/actions/taxonomy-action-creators';
import Loading from '#/components/loading';
import Error from '#/components/error';
import AnalyticsSiteEvent from '#/components/analytics/site-event';
import AnalyticsCustomerEvent from '#/components/analytics/customer-event';
import AnalyticsBasketEvent from '#/components/analytics/basket-event';
import AnalyticsRouteRenderedEvent from '#/components/analytics/route-rendered';
import { SlotAnalytics } from '#/components/analytics/slot-analytics';
import helpers from '#/lib/decorators/helpers';
import getAnalytics from '#/analytics/analyticsBus';
import pageLoadedAnalyticsEvent from '#/analytics/types/page';
import ApmPageAttributes from '#/components/analytics/apm-page-attributes';
import {
  getAppRegion,
  getCsrfToken,
  getCurrentUrl,
  getIsMobile,
  getLanguage,
  getLoginUrl,
  getGroceryDomain,
  getMfeRolloutConfig
} from '#/reducers/app';
import { getIsUserAuthenticated } from '#/reducers/user';
import LazyComponent from '#/tmp-bundles/lazy-component';
import {
  updateRobots,
  updateCanonicalUrl,
  updatePageDescription,
  updatePageTitle,
  updateStructuredData,
  updateAltLanguageLinks
} from '#/utils/page-meta-data';
import clientFetchResourcesWrapper from '#/conditional-resources/client-fetch-resources-wrapper';
import getNamesFromResources from '#/conditional-resources/helpers/get-names-from-resources';
import getRelevantResourcesFromRoutes from '#/conditional-resources/get-relevant-resources-from-routes';
import relevantResourcesFromRoutesPropTypeValidation from '#/conditional-resources/relevant-resources-from-routes-prop-type-validation';
import { scrollToElement } from '#/lib/browser/ui-util';
import { EXTERNAL_SESSION_RENEW_REQUIRED } from '#/constants/error-codes';

const isClientSide = !!process.env.CLIENT_SIDE;

if (isClientSide) {
  window.initialPageLoad = true;
}

let previousUrl;
let currentUrl =
  typeof location !== 'undefined'
    ? location.pathname + location.search
    : undefined;

// We need the grocery menu to show up when going back from a browse page on mobile
// This does not always trigger a route change (it can be the same route)
// therefore we need to listen to the browser history for any location change
const setHistoryListener = (() => {
  let cancelListener;

  return props => {
    if (typeof browserHistory !== 'undefined') {
      cancelListener && cancelListener(); // eslint-disable-line no-unused-expressions
      cancelListener = browserHistory.listen(location => {
        // we need to control the visibility of the groceries menu based on query params
        const navParam = location.query.nav;
        const url = location.pathname + location.search;

        if (url !== currentUrl) {
          previousUrl = currentUrl;
          currentUrl = url;
        }

        if (props.isMobile && navParam) {
          props.newTaxonomyPath(navParam);
          props.openNavMenu();
        } else {
          props.closeNavMenu();
          props.resetTaxonomy();
        }
      });
    }
  };
})();

export const hasRouteChanged = (() => {
  const currentLocation = {};

  return (key, location = window.location) => {
    if (typeof location === 'undefined') {
      return false;
    }

    const { pathname, search } = location;
    const noChange =
      pathname === currentLocation[key]?.pathname &&
      search === currentLocation[key]?.search;

    currentLocation[key] = {};
    currentLocation[key].pathname = pathname;
    currentLocation[key].search = search;

    return !noChange;
  };
})();

const mapStateToProps = (state, ownProps) => ({
  csrfToken: getCsrfToken(state),
  currentUrl: getCurrentUrl(state),
  groceryDomain: getGroceryDomain(state),
  isMobile: getIsMobile(state),
  isUserAuthenticated: getIsUserAuthenticated(state),
  language: getLanguage(state),
  loginUrl: getLoginUrl(state),
  mfeRolloutConfig: getMfeRolloutConfig(state),
  region: getAppRegion(state),
  relevantResourcesFromRoutes: getRelevantResourcesFromRoutes(state, ownProps),
  resources: state.resources,
  user: state.user
});

export const dynamicRoute = Child => {
  @helpers(['asset', 'c', 't'])
  @connect(mapStateToProps, {
    changeRoute,
    clearReferrer,
    closeLeftNav,
    closeNavMenu,
    fetchResources,
    newTaxonomyPath,
    openNavMenu,
    resetTaxonomy,
    spaTransition
  })
  class DynamicRouteController extends React.Component {
    static propTypes = {
      additionalParams: PropTypes.object,
      asset: PropTypes.func.isRequired,
      c: PropTypes.func.isRequired,
      changeRoute: PropTypes.func.isRequired,
      clearReferrer: PropTypes.func.isRequired,
      closeLeftNav: PropTypes.func.isRequired,
      csrfToken: PropTypes.string.isRequired,
      currentUrl: PropTypes.string.isRequired,
      fetchResources: PropTypes.func.isRequired,
      isMobile: PropTypes.bool.isRequired,
      isUserAuthenticated: PropTypes.bool,
      loginUrl: PropTypes.string.isRequired,
      mfeRolloutConfig: PropTypes.object,
      relevantResourcesFromRoutes: PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.arrayOf(relevantResourcesFromRoutesPropTypeValidation)
        ])
      ),
      requiresAuthentication: PropTypes.bool,
      resources: PropTypes.object,
      routeProps: PropTypes.object.isRequired,
      spaTransition: PropTypes.func.isRequired,
      t: PropTypes.func.isRequired,
      user: PropTypes.object.isRequired
    };

    static defaultProps = {
      requiresAuthentication: false,
      resources: {}
    };

    constructor(props) {
      super(props);

      this.componentIsLive = true;

      this.getComponent();

      this.state = {
        routeParams: JSON.stringify(this.props.routeProps.routeParams),
        isPathSame: false,
        componentLoaded: !!this.Component,
        resourcesError: false,
        resourcesLoaded:
          !isClientSide ||
          window.initialPageLoad ||
          !getNamesFromResources(props.relevantResourcesFromRoutes).length
      };
    }

    configureBrowserScrollRestoration() {
      if ('scrollRestoration' in window.history) {
        if (window.location.hash) {
          window.history.scrollRestoration = 'manual';
        } else {
          window.history.scrollRestoration = 'auto';
        }
      }
    }

    delayedScrollToFragment(location) {
      if (location?.hash) {
        const fragment = location.hash?.substring(1);
        setTimeout(function() {
          scrollToElement(fragment);
        }, 0);
      }
    }

    componentDidMount() {
      const { status, url: urlWithError } = this.props.resources;

      if (!status || (urlWithError && urlWithError !== this.props.currentUrl)) {
        this.update(this.props);
      }
      setHistoryListener(this.props);

      this.configureBrowserScrollRestoration();
    }

    componentWillReceiveProps(nextProps) {
      const props = this.props;
      const haveParamsChanged = !deepEqual(
        this.combineParams(nextProps),
        this.combineParams(props)
      );

      const haveResourceNamesChanged = !deepEqual(
        getNamesFromResources(nextProps.relevantResourcesFromRoutes),
        getNamesFromResources(props.relevantResourcesFromRoutes)
      );

      const locationRoutePropsWithoutFragment = {
        ...props.routeProps.location,
        hash: undefined
      };
      const nextLocationRoutePropsWithoutFragment = {
        ...nextProps.routeProps.location,
        hash: undefined
      };

      const hasRouteChanged = !deepEqual(
        nextLocationRoutePropsWithoutFragment,
        locationRoutePropsWithoutFragment
      );

      const hasRouteFragmentUpdated =
        !hasRouteChanged &&
        nextProps.routeProps.location.hash &&
        nextProps.routeProps.location.hash !== props.routeProps.location.hash;

      if (haveParamsChanged || hasRouteChanged || haveResourceNamesChanged) {
        window.initialPageLoad = false;

        this.update(nextProps);
      } else if (hasRouteFragmentUpdated) {
        const fragment = nextProps.routeProps.location.hash?.substring(1);
        // this helps for navigating across the same page
        scrollToElement(fragment);
      }
    }

    componentWillUpdate(nextProps) {
      const {
        pathname,
        query = {},
        search = ''
      } = nextProps.routeProps.location;

      this.props.changeRoute(pathname + search, query);
    }

    componentWillUnmount() {
      window.initialPageLoad = false;
      this.componentIsLive = false;
    }

    shouldComponentUpdate(
      { routeProps: nextRouteProps, ...otherNextProps },
      nextState
    ) {
      // NOTE: following eslint-disable is intentional
      /* eslint-disable @typescript-eslint/no-unused-vars */
      const {
        relevantResourcesFromRoutes,
        resources,
        routeProps,
        ...otherProps
      } = this.props;
      /* eslint-enable @typescript-eslint/no-unused-vars */
      const { componentLoaded, resourcesLoaded } = this.state;

      const routePropsWithoutFragment = {
        ...routeProps,
        location: { ...routeProps.location, hash: undefined }
      };
      const nextRoutePropsWithoutFragment = {
        ...nextRouteProps,
        location: { ...nextRouteProps.location, hash: undefined }
      };
      const hasRoutePropsChanged = !deepEqual(
        routePropsWithoutFragment,
        nextRoutePropsWithoutFragment
      );

      const hasOtherPropsChanged = Object.keys(otherProps).some(
        key => otherProps[key] !== otherNextProps[key]
      );
      const hasLoaded = componentLoaded && resourcesLoaded;
      const nextHasLoaded =
        nextState.componentLoaded && nextState.resourcesLoaded;
      const hasLoadStateChanged = hasLoaded !== nextHasLoaded;

      if (!hasLoadStateChanged && !hasLoaded) {
        return false;
      }

      return (
        hasRoutePropsChanged || hasOtherPropsChanged || hasLoadStateChanged
      );
    }

    getRelevantResources(resources) {
      return getNamesFromResources(
        this.props.relevantResourcesFromRoutes
      ).reduce((acc, name) => {
        acc[name] = resources[name];

        return acc;
      }, {});
    }

    getTitle(routeProps, fetchedResourcesStatus, relevantResources) {
      const titleKeyFromRoute = routeProps.route.pageTitle;
      const webSiteName = this.props.t('common:website-name');

      let key;

      if (titleKeyFromRoute) {
        key = titleKeyFromRoute;
      }

      if (fetchedResourcesStatus) {
        key = 'common:pages.error';
      }

      if (key) {
        return `${this.props.t(key)} - ${webSiteName}`;
      }

      const relevantResourcesPropPageTitle = extractPropsFromWrappedResources(
        'pageTitle',
        relevantResources
      );

      if (relevantResourcesPropPageTitle) {
        return `${relevantResourcesPropPageTitle} - ${webSiteName}`;
      }

      return webSiteName;
    }

    combineParams(props) {
      const query = props.routeProps.location.query || {};

      return {
        ...props.routeProps.params,
        ...props.routeProps.route.additionalParams,
        referer: previousUrl,
        query
      };
    }

    getComponent() {
      if (!(Child instanceof LazyComponent)) {
        this.Component = Child;
        return;
      }

      this.Component = Child.fromCache();

      if (this.Component === null) {
        Child.fetch().then(Component => {
          if (this.componentIsLive) {
            this.Component = Component;

            this.setState({
              componentLoaded: true
            });
          }
        });
      }
    }

    getChildProps() {
      const { resources, ...otherProps } = this.props; // eslint-disable-line

      return { ...otherProps };
    }

    async update(nextProps) {
      if (!isClientSide || !this.componentIsLive) {
        return;
      }
      const {
        path: nextPath,
        requiresAuthentication
      } = nextProps.routeProps.route;

      const { isUserAuthenticated } = nextProps;

      if (requiresAuthentication && !isUserAuthenticated) {
        const url = nextProps.loginUrl.split('=');
        const encodedRedirectUrl = encodeURIComponent(window.location.href);

        this.setState({
          resourcesLoaded: false,
          resourcesError: EXTERNAL_SESSION_RENEW_REQUIRED
        });
        window.location.href = `${url[0]}=${encodedRedirectUrl}`;

        return;
      }

      if (window.initialPageLoad) {
        // this helps to scroll on initial page load
        this.delayedScrollToFragment(nextProps.routeProps.location);
        return;
      }

      const currentCallTimestamp = Date.now();
      this.latestCallTimestamp = currentCallTimestamp;

      const {
        pathname,
        query = {},
        search = ''
      } = nextProps.routeProps.location;
      const params = this.combineParams(nextProps);

      setTimeout(function() {
        window.scrollTo(0, 0);
      }, 0);

      this.props.closeLeftNav();
      this.props.spaTransition(true);

      let fetchedResources;

      const { path } = this.props.routeProps.route;
      await new Promise(resolve => {
        this.setState(
          {
            isPathSame: path === nextPath,
            resourcesLoaded: false
          },
          resolve
        );
      });

      fetchedResources = await clientFetchResourcesWrapper(
        this.props.relevantResourcesFromRoutes,
        this.props.fetchResources,
        params,
        {
          acceptWaitingRoom: true,
          updateCurrentURL: pathname + search,
          requiresAuthentication
        }
      );

      if (
        !this.componentIsLive ||
        this.latestCallTimestamp !== currentCallTimestamp
      ) {
        return;
      }

      if (fetchedResources?.sessionRefreshExternally) {
        this.setState({
          resourcesLoaded: false,
          resourcesError: EXTERNAL_SESSION_RENEW_REQUIRED
        });
        return;
      }

      if (!fetchedResources) {
        this.setState({
          resourcesLoaded: true,
          resourcesError: typeof fetchedResources === 'undefined'
        });
        return;
      }

      this.props.spaTransition(false);

      // this helps to scroll to fragment post spaTransition
      this.delayedScrollToFragment(nextProps.routeProps.location);

      this.props.changeRoute(pathname + search, query);

      if (hasRouteChanged('routeChanging')) {
        getAnalytics().emit('app:routeChanging', {});
        this.props.clearReferrer();
      }

      if (hasRouteChanged('routeChanged', nextProps.routeProps.location)) {
        const relevantResources = this.getRelevantResources(fetchedResources);

        updateAltLanguageLinks(
          extractPropsFromWrappedResources(
            'alternateLanguageLinks',
            relevantResources
          )
        );

        updateCanonicalUrl(
          extractPropsFromWrappedResources('canonicalUrl', relevantResources)
        );

        updatePageDescription(
          extractPropsFromWrappedResources('pageDescription', relevantResources)
        );

        updatePageTitle(
          this.getTitle(
            nextProps.routeProps,
            fetchedResources.status,
            relevantResources
          )
        );

        updateRobots(
          extractPropsFromWrappedResources('robots', relevantResources)
        );

        updateStructuredData(
          extractPropsFromWrappedResources('structuredData', relevantResources)
        );
      }

      this.sendPageLoadedAnalytics(fetchedResources.status);

      window.csrfToken = nextProps.csrfToken;

      this.setState({
        routeParams: JSON.stringify(nextProps.routeProps.routeParams),
        resourcesLoaded: true
      });
    }

    sendPageLoadedAnalytics(fetchedResourcesStatus) {
      const {
        currentUrl,
        language,
        groceryDomain,
        region,
        resources,
        routeProps
      } = this.props;

      const relevantResources = this.getRelevantResources(resources);

      pageLoadedAnalyticsEvent({
        breadcrumbs:
          extractPropsFromWrappedResources('breadcrumbs', relevantResources) ||
          [],
        pageLanguage: language,
        pageTitle: this.getTitle(
          routeProps,
          fetchedResourcesStatus,
          relevantResources
        ),
        pageURL: `${groceryDomain}${currentUrl}`,
        region
      });
    }

    showLoader() {
      const { additionalParams } = this.props.routeProps.route;
      const { componentLoaded, isPathSame, resourcesLoaded } = this.state;

      if (isPathSame) {
        return (
          !additionalParams?.disableLoader &&
          (!componentLoaded || !resourcesLoaded)
        );
      }

      return !componentLoaded || !resourcesLoaded;
    }

    render() {
      const childProps = this.getChildProps();
      const { resources: { status } = {} } = this.props;
      const { routeParams, resourcesError } = this.state;

      if (
        status ||
        (resourcesError && resourcesError !== EXTERNAL_SESSION_RENEW_REQUIRED)
      ) {
        page.stop();
        return <Error type={status || 500} />;
      }

      if (this.showLoader()) {
        return <Loading />;
      } else {
        const Comp = this.Component;
        return (
          <div>
            <Comp {...childProps} />
            <RouteAnalytics {...childProps} key={routeParams} />
          </div>
        );
      }
    }
  }

  return DynamicRouteController;
};

/* eslint-disable react/prop-types */
export const DynamicRouteController = RouteController => ({
  additionalParams,
  analyticsEvent,
  asyncPage,
  bundle,
  children,
  component,
  isRouteAvailable,
  mfeRouteName,
  mfeRouteNameMapper,
  pageTitle,
  path,
  resources,
  requiresAuthentication
}) =>
  typeof isRouteAvailable === 'boolean' && !isRouteAvailable ? null : (
    <RouteController
      additionalParams={additionalParams}
      analyticsEvent={analyticsEvent}
      asyncPage={asyncPage}
      bundle={bundle}
      mfeRouteName={mfeRouteName}
      mfeRouteNameMapper={mfeRouteNameMapper}
      component={dynamicRoute(component)}
      pageTitle={pageTitle}
      path={path}
      requiresAuthentication={requiresAuthentication}
      resources={resources}
    >
      {children}
    </RouteController>
  );

export const DynamicIndexRoute = DynamicRouteController(IndexRoute);
export const DynamicRoute = DynamicRouteController(Route);
/* eslint-enable react/prop-types */

export const RouteAnalytics = props => {
  const {
    analyticsEvent,
    asyncPage,
    additionalParams
  } = props.routeProps.route;

  return (
    <>
      <AnalyticsSiteEvent />
      <AnalyticsCustomerEvent />
      <AnalyticsBasketEvent {...props} />
      <ApmPageAttributes />
      <AnalyticsRouteRenderedEvent
        analyticsEvent={analyticsEvent}
        asyncPage={asyncPage}
      />
      {additionalParams?.slotAnalytics && <SlotAnalytics />}
    </>
  );
};

RouteAnalytics.propTypes = {
  alternateLanguageLinks: PropTypes.array,
  c: PropTypes.func.isRequired,
  canonicalUrl: PropTypes.string,
  children: PropTypes.node,
  pageDescription: PropTypes.string,
  paginationLinks: PropTypes.array,
  pendingOrders: PropTypes.array,
  robots: PropTypes.string,
  routeProps: PropTypes.object,
  structuredData: PropTypes.string,
  title: PropTypes.string
};
