import styled from 'styled-components';
import { BodyText, Signpost } from '@beans/typography';
import { Text } from '@beans/text';
import TextareaGroup from '@beans/textarea-group';
import Spinner from '@beans/spinner';
import Icon from '@beans/icon';
import { fontSize, spacing } from '@beans/selectors';
import { RED, GREEN, BLUE } from '@beans/constants';

export const StyledOrderInstructionsWrapper = styled.div`
  position: relative;
  #orderSummaryInstructions + p {
    font-size: ${fontSize.xx};
  }
`;
export const StatusDiv = styled.div`
  display: flex;
  position: absolute;
  margin-top: -18px;
`;

export const ErrorDiv = styled.div`
  display: flex;
  position: absolute;
  margin-top: -21px;
`;

export const ErrorText = styled(Text)`
  font-family: 'TESCO Modern', Arial, sans-serif;
  color: ${RED};
`;

export const SavedText = styled(Text)`
  margin-left: ${spacing.xs};
  color: ${GREEN};
`;

export const StyledSpinner = styled(Spinner)`
  margin-top: 1px;
  width: 16px;
`;

export const SavingText = styled(Text)`
  margin-left: ${spacing.xs};
  color: ${BLUE};
`;

export const StyledIcon = styled(Icon)`
  height: ${fontSize.xx};
  width: ${fontSize.xx};
  margin-right: ${spacing.xxx};
`;

export const StyledTextareaGroup = styled(TextareaGroup)`
  textarea {
    resize: none;
    height: 100px;
  }
`;

export const StyledSignpost = styled(Signpost)`
  margin: ${spacing.md} 0;
`;

export const StyledBodyText = styled(BodyText)`
  margin-bottom: ${spacing.md};
`;
