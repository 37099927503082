import { Dispatch } from '#/custom-typings/redux-store/common';
import { UPDATE_SUBSCRIPTIONS } from '#/constants/subscriptions';

export const updateSubscriptions = (subscriptions: Store['subscriptions']): ((dispatch: Dispatch) => void) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: UPDATE_SUBSCRIPTIONS,
      value: subscriptions,
    });
  };
};
