import React, { useEffect, useState } from 'react';
import helpers from '#/lib/decorators/helpers';
import { connect } from '#/lib/render/connect-deep-compare';
import { getSelectedShoppingMethod } from '#/reducers/slot';
import { getIsRenderTrialClickAndCollectNotification } from '#/selectors/slot';
import { getIsAmendBasket, getLastTrolleySlotStatus, getTrolleyShoppingMethod } from '#/selectors/trolley';
import { isDeliveryShoppingMethod, isOnDemandDeliveryShoppingMethod } from '#/lib/shopping-method-util';
import CancelOrderNotification from '#/components/slots/cancel-order-notification';
import TrialClickAndCollectNotification from '#/components/pages/book-a-slot/notification/trial-click-collect-notification';
import SlotAddress from '#/components/pages/book-a-slot/address';
import DCSBanner from '#/components/pages/book-a-slot/dcs-banner';
import SlotsSection from '#/components/pages/book-a-slot/slots-section';
import { StyledContainer, StyledTabContentContainer } from './styled';
import { COLLECTION, ShoppingMethod } from '#/constants/shopping-methods';
import { updateParamsInUrl, updatePageUrlWithoutRefresh } from '#/lib/url/url-utils';
import { getAppRegion, getCurrentUrl, getLanguageLink } from '#/reducers/app';
import { sessionStore } from '#/lib/data-store/client-store.js';
import { AddressData } from '#/reducers/addresses';
import { setSuggestedAddress } from '#/actions/location-action-creators';
import { SUGGESTED_ADDRESS } from '#/constants/spa-resource';
import { OnDemandTab } from '#/components/slots/on-demand-tab';
import { SlotStatus } from '#/custom-typings/redux-store/slot.defs';
import { TTranslateFunc } from '#/lib/records/helpers.defs';
import { FulfilmentOptionsTabs } from '#/components/slots/fulfilment-options-tabs';
import { OptionTabs } from '#/components/slots/option-tabs';
import { UK } from '#/constants/common';
import OverlaySpinner from '#/components/overlay-spinner';
import { getShowSlotUIReskinV2 } from '#/experiments/oop-2205/selectors';

export type TStateProps = {
  selectedShoppingMethod: ShoppingMethod;
  trolleyShoppingMethod: ShoppingMethod;
  shouldRenderTrialClickAndCollectNotification: boolean;
  shouldRenderDCSBanner: boolean;
  isAmendBasket: boolean;
  shouldRenderCancelOrdeNotification: boolean;
  slotStatus: SlotStatus | null | undefined;
  shouldUseFulfilmentOptionTabs: boolean;
  showSlotUIReskin: boolean;
  currentUrl: string;
  languageLink: (url: string) => string;
};

const mapStateToProps = (state: Store): TStateProps => {
  const selectedShoppingMethod = getSelectedShoppingMethod(state);
  const isAmendBasket = getIsAmendBasket(state);
  const isDelivery = isDeliveryShoppingMethod(selectedShoppingMethod);
  const region = getAppRegion(state);

  return {
    selectedShoppingMethod,
    trolleyShoppingMethod: getTrolleyShoppingMethod(state),
    shouldRenderTrialClickAndCollectNotification: getIsRenderTrialClickAndCollectNotification(state),
    shouldRenderDCSBanner: isDelivery,
    isAmendBasket,
    shouldRenderCancelOrdeNotification: isAmendBasket,
    slotStatus: getLastTrolleySlotStatus(state),
    currentUrl: getCurrentUrl(state),
    showSlotUIReskin: getShowSlotUIReskinV2(state),
    shouldUseFulfilmentOptionTabs: region === UK,
    languageLink: (url: string): string => getLanguageLink(state, url),
  };
};

type TOwnProps = {
  shouldRenderDCSBanner: boolean;
  isAmendBasket: boolean;
  shouldRenderTrialClickAndCollectNotification: boolean;
  shouldRenderCancelOrdeNotification: boolean;
  setSuggestedAddress: (data?: object) => void;
  t: TTranslateFunc;
};

type TProps = TOwnProps & TStateProps;

export const updateUrlAndStateWithAddressId = (
  currentUrl: string,
  setState: (address: Partial<AddressData>) => void,
): void => {
  const suggestedAddress: Partial<AddressData> = sessionStore?.get(SUGGESTED_ADDRESS);

  if (suggestedAddress && !currentUrl.includes(suggestedAddress.id as string)) {
    setState(suggestedAddress);
    updatePageUrlWithoutRefresh(window, updateParamsInUrl(currentUrl, { addressId: suggestedAddress.id }));
  }
};

export const ensureOnCorrectUrl = (
  trolleyShoppingMethodLink: string,
  currentUrl: string,
  isAmendBasket: boolean,
): void => {
  if (isAmendBasket && currentUrl !== trolleyShoppingMethodLink) {
    window.location.replace(trolleyShoppingMethodLink);
  }
};

const BookASlot: React.FC<TProps> = (props: TProps) => {
  const {
    currentUrl,
    isAmendBasket,
    languageLink,
    selectedShoppingMethod,
    setSuggestedAddress,
    showSlotUIReskin,
    shouldRenderCancelOrdeNotification,
    shouldRenderDCSBanner,
    shouldRenderTrialClickAndCollectNotification,
    shouldUseFulfilmentOptionTabs,
    slotStatus,
    trolleyShoppingMethod,
    t: translate,
  } = props;

  const [mfeTabContentLoading, setMfeTabContentLoading] = useState(false);

  useEffect(() => {
    const trolleyShoppingMethodLink = languageLink(`/slots/${trolleyShoppingMethod}`);
    ensureOnCorrectUrl(trolleyShoppingMethodLink, currentUrl, isAmendBasket);

    if (selectedShoppingMethod === COLLECTION) {
      updateUrlAndStateWithAddressId(currentUrl, setSuggestedAddress);
    }
  }, [selectedShoppingMethod]);

  return (
    <StyledContainer
      data-testid={`book-a-slot-${selectedShoppingMethod}-synthetics`}
      className={`book-a-slot ${selectedShoppingMethod}`}
      showSlotUIReskin={showSlotUIReskin}
    >
      <div className="content-section">
        <h1 className="page-title">{translate('common:pages.slots')}</h1>
        {shouldRenderTrialClickAndCollectNotification && <TrialClickAndCollectNotification />}
        {shouldRenderCancelOrdeNotification && <CancelOrderNotification shoppingMethod={selectedShoppingMethod} />}
        {shouldUseFulfilmentOptionTabs ? (
          <FulfilmentOptionsTabs
            shoppingMethod={selectedShoppingMethod}
            isAmendBasket={isAmendBasket}
            setMfeTabContentLoading={setMfeTabContentLoading}
          />
        ) : (
          <OptionTabs shoppingMethod={selectedShoppingMethod} slotStatus={slotStatus} isAmendBasket={isAmendBasket} />
        )}
        <StyledTabContentContainer>
          <OverlaySpinner isLoading={mfeTabContentLoading} />
          {isOnDemandDeliveryShoppingMethod(selectedShoppingMethod) && shouldUseFulfilmentOptionTabs && (
            <OnDemandTab shoppingMethod={selectedShoppingMethod} />
          )}
          {shouldRenderDCSBanner && <DCSBanner />}
          <SlotAddress />
          <SlotsSection />
        </StyledTabContentContainer>
      </div>
    </StyledContainer>
  );
};

export default helpers(['t'])(connect(mapStateToProps, { setSuggestedAddress })(BookASlot));
