import React from 'react';
import { TTranslateFunc } from '#/lib/records/helpers.defs';
import helpers from '#/lib/decorators/helpers';
import {
  StatusWrapper,
  PickerNotesProgressWrapper,
  StyledSpinner,
  StyledIcon,
  StyledFootnote,
} from '#/components/product-tiles/horizontal-tile/variant/trolley-tile/styled';
import { SAVING, SAVED, ERROR, IDLE } from '#/constants/instruction-status';

type Props = {
  t: TTranslateFunc;
  status?: typeof SAVING | typeof SAVED | typeof ERROR | typeof IDLE;
  showErrorMessage: boolean;
};

const PickerNotesProgress = ({ t: translate, status, showErrorMessage }: Props): React.ReactElement => {
  let statusMessage;

  if (status === SAVING) {
    statusMessage = translate('slots:common.instructions-saving');
  } else if (status === SAVED) {
    statusMessage = translate('slots:common.instructions-saved');
  } else if (status === ERROR) {
    statusMessage = translate('product-tile:picker-note-error');
  }

  return (
    <PickerNotesProgressWrapper>
      {showErrorMessage && (
        <StyledFootnote size="small" role="status">
          {translate('product-tile:picker-note-max-reached')}
        </StyledFootnote>
      )}
      {statusMessage && (
        <StatusWrapper>
          {status === SAVING && <StyledSpinner size="xx" />}
          {status === SAVED && <StyledIcon graphic="benefits" size="sm" />}
          <span
            role="status"
            className={status}
            data-auto="saved"
            aria-label={showErrorMessage ? translate('product-tile:picker-note-max-reached') : undefined}
          >
            {statusMessage}
          </span>
        </StatusWrapper>
      )}
    </PickerNotesProgressWrapper>
  );
};

export default helpers(['t'])(PickerNotesProgress);
