import { RECOMMENDATION_CAROUSEL_TYPE } from '#/constants/panel-types';
import { TREX_IDENTIFIER } from '#/analytics/constants';

export enum favPersonalisedOffersVariants {
  default = 'default',
  usualsCarousel = 'usuals-carousel',
  offersCarousel = 'offers-carousel',
  offersTab = 'offers-tab',
}

const experience = 'experience';
export const productCount = 'productCount';

/**
 * Experiment constants for fav personalised Offers
 */
export const favPersonalisedOffers = {
  featureKey: 'GHS-UK_myfavourites-personalisedoffers',
  variationKey: experience,
  serviceConfig: {
    params: {
      [experience]: favPersonalisedOffersVariants.default,
      [productCount]: '0',
    },
  },
  useCustomerId: true,
};

const PERSONALISED_OFFERS = 'personalizedoffers';
const POPULAR_OFFERS = 'popularoffers';
export const MY_USUALS = 'myusuals';

export const CONFIG_KEY = 'favoritesOffersForYou1557';

export const PERSONALISED_OFFERS_ANALYTICS_TYPE = `${RECOMMENDATION_CAROUSEL_TYPE}:${PERSONALISED_OFFERS}`;
export const PERSONALISED_OFFERS_TREX_IDENTIFIER = `${TREX_IDENTIFIER}:${PERSONALISED_OFFERS}`;

export const POPULAR_OFFERS_ANALYTICS_TYPE = `${RECOMMENDATION_CAROUSEL_TYPE}:${POPULAR_OFFERS}`;
export const POPULAR_OFFERS_TREX_IDENTIFIER = `${TREX_IDENTIFIER}:${POPULAR_OFFERS}`;

export const USUALS_TREX_IDENTIFIER = `carousel:${MY_USUALS}`;
