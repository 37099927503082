import React, { ChangeEvent, useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ConnectedProps } from 'react-redux';
import { compose } from 'react-recompose';
import { SM, GREY_DARK_2 } from '@beans/constants';
import { debounce } from '#/utils/misc';
import { connect } from '#/lib/render/connect-deep-compare';
import SearchInputNext, { OptionTypes } from '@ddsweb/search-input-next';
import FormGroup from '@ddsweb/form-group';
import Heading from '@beans/heading';
import { searchLocationsBySuggestedAddress } from '#/actions/location-action-creators';
import { clearCollectionSelectedLocation, getSuggestedAddresses } from '#/actions/slot-action-creators';
import helpers from '#/lib/decorators/helpers';
import { AddressSearchResponse, AddressSuggestion } from '#/lib/requests/addressSearch/address-search';
import { StyledAddressSearchWrapper, StyledAddressSearch } from '#/components/slots/address-search/styled';
import { formatSuggestedResults } from '#/components/slots/address-search/helpers';
import { sessionStore } from '#/lib/data-store/client-store.js';
import { updateParamsInUrl, updatePageUrlWithoutRefresh } from '#/lib/url/url-utils';
import { getCurrentUrl } from '#/reducers/app';
import { SUGGESTED_ADDRESS, DEBOUNCE_TIME_GET_SUGGESTED_ADDRESSES } from '#/constants/spa-resource';
import { basicEvent } from '#/analytics/types/basic';
import analyticsBus from '#/analytics/analyticsBus';
import { NOW } from '#/analytics/constants';
import { EVENT_CLICK_COLLECT_INTERACTION, EVENT_LOCATION_SEARCH } from './constants';
import Icon from '@beans/icon';

type HelperProps = {
  t: (key: string, options?: { [key: string]: string }) => string;
};

type AddressSearchOwnProps = {
  children: JSX.Element;
  inputId: string;
  onChange: () => void;
};

type StoreProps = {
  currentUrl: string;
};

const mapStateToProps = (state: Store): StoreProps => ({
  currentUrl: getCurrentUrl(state),
});

const connector = connect(mapStateToProps, {
  searchLocationsBySuggestedAddress,
  getSuggestedAddresses,
  clearCollectionSelectedLocation,
});

type TProps = HelperProps & AddressSearchOwnProps & ConnectedProps<typeof connector>;

const AddressSearch = ({
  children,
  inputId,
  t: translate,
  currentUrl,
  getSuggestedAddresses,
  ...props
}: TProps): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [suggestedAddresses, setSuggestedAddresses] = useState<AddressSuggestion[]>([]);
  const [selectedOption, setSelectedOption] = useState<AddressSuggestion | null>(null);
  const [addressSearchPending, setAddressSearchPending] = useState(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState('');
  const [inputHasFocus, setInputHasFocus] = useState(false);

  const getAddressList = useCallback(
    debounce(async (keyword: string) => {
      setSelectedOption(null);
      setAddressSearchPending(true);

      try {
        const {
          data: {
            address: {
              search: { addresses },
            },
          },
        } = (await getSuggestedAddresses(keyword)) as AddressSearchResponse;

        const newErrorMessage =
          addresses.length > 0
            ? translate('slots:address-search-form.error.select-address')
            : translate('slots:address-search-form.error.no-results');

        setSuggestedAddresses(addresses);
        setErrorMessage(newErrorMessage);
        setAddressSearchPending(false);
      } catch (error) {
        setErrorMessage(translate('slots:address-search-form.error.loading-content'));
        setSuggestedAddresses([]);
        setAddressSearchPending(false);
      }
    }, DEBOUNCE_TIME_GET_SUGGESTED_ADDRESSES),
    [],
  );

  const onClearHandler = (): void => {
    setSelectedOption(null);
    setInputHasFocus(false);
    setErrorMessage('');
    setInputValue('');
    setSuggestedAddresses([]);
  };

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement> | undefined): void => {
    const newInputValue = event?.target?.value || '';

    setInputValue(newInputValue);
    setErrorMessage('');

    if (!newInputValue) {
      setSuggestedAddresses([]);
    }

    if (newInputValue && newInputValue.length >= 3) {
      getAddressList(newInputValue);
    }
  };

  const onSelectOptionHandler = async ({ selected }: { selected: OptionTypes }): Promise<void> => {
    const { searchLocationsBySuggestedAddress, clearCollectionSelectedLocation, onChange } = props;

    clearCollectionSelectedLocation();
    setInputValue(selected.text || '');

    const selectedAddressData = suggestedAddresses.find(item => item.id === selected.key);
    (inputRef?.current as HTMLInputElement)?.blur();

    if (selectedAddressData) {
      basicEvent(analyticsBus, {
        type: EVENT_LOCATION_SEARCH,
        value: EVENT_CLICK_COLLECT_INTERACTION,
        action: NOW,
      });

      setSelectedOption(selectedAddressData);
      updatePageUrlWithoutRefresh(window, updateParamsInUrl(currentUrl, { addressId: selected.key }));
      sessionStore?.set(SUGGESTED_ADDRESS, selectedAddressData);
      await searchLocationsBySuggestedAddress(selectedAddressData);
      onChange();
    }
  };

  const renderAddressSearch = (): JSX.Element | string => {
    const transformedSuggestedAddresses = formatSuggestedResults(suggestedAddresses);
    let showError = false;
    let showNoMatchesFooter = false;

    if (errorMessage) {
      if (suggestedAddresses.length > 0 && !inputHasFocus && !selectedOption) {
        showError = true;
      } else if (suggestedAddresses.length <= 0) {
        if (inputHasFocus && errorMessage === translate('slots:address-search-form.error.no-results')) {
          showNoMatchesFooter = true;
        } else {
          showError = true;
        }
      }
    }

    return (
      <StyledAddressSearch>
        <FormGroup
          id={inputId}
          labelText={translate('slots:address-search-form.input-label')}
          error={showError}
          errorMessage={errorMessage}
          required
        >
          <SearchInputNext
            data-testid={`${inputId}-synthetics`}
            ref={inputRef}
            onBlur={(): void => {
              setInputHasFocus(false);
            }}
            onFocus={(): void => {
              setInputHasFocus(true);
            }}
            id={inputId}
            value={inputValue}
            onClear={onClearHandler}
            onChange={onChangeHandler}
            onSelectOption={onSelectOptionHandler}
            options={transformedSuggestedAddresses}
            isLoading={addressSearchPending}
            ariaLabels={{
              clearButton: translate('slots:address-search-form.aria-labels.clear'),
              searchIcon: translate('slots:address-search-form.aria-labels.search'),
            }}
            selectOptionOnFocus={false}
            leftChildren={<Icon graphic="search" size={SM} stroke={GREY_DARK_2} />}
            {...{
              inputFooter: showNoMatchesFooter && (
                <>
                  <Heading headingLevel="4" style={{ marginBottom: '4px' }} visualSize="headline5">
                    {translate('slots:address-search-form.no-results.title')}
                  </Heading>
                  <span>{translate('slots:address-search-form.no-results.text')}</span>
                </>
              ),
            }}
          />
        </FormGroup>
      </StyledAddressSearch>
    );
  };

  return (
    <StyledAddressSearchWrapper>
      <StyledAddressSearch>
        <label htmlFor={children.props.identifier}>{translate('slots:address-search-form.title')}</label>
        {children}
      </StyledAddressSearch>
      {renderAddressSearch()}
    </StyledAddressSearchWrapper>
  );
};

AddressSearch.propTypes = {
  children: PropTypes.node.isRequired,
  getSuggestedAddresses: PropTypes.func.isRequired,
  inputId: PropTypes.string,
  onChange: PropTypes.func,
  searchLocationsBySuggestedAddress: PropTypes.func.isRequired,
  t: PropTypes.func,
};

AddressSearch.defaultProps = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: (): void => {},
};

const enhance = compose(helpers(['t']), connector);

export default enhance(AddressSearch);
