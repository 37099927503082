import React, { memo, useMemo, useState } from 'react';
import classnames from 'classnames';
import deepEqual from 'fast-deep-equal/es6';
import helpers from '#/lib/decorators/helpers';
import { ProductTileConfiguration } from '#/components/product-tiles/common/tile-configurations';
import { HORIZONTAL_EMBEDDED } from '#/constants/tile-types';
import { SUB_IDENTIFIER } from '#/analytics/constants';
import { TTranslateFunc, TConfigFunc, TFeatureFunc } from '#/lib/records/helpers.defs';
import { Item } from '#/lib/records/item';
import { HORIZONTAL, LEGACY, VERTICAL, TROLLEY_TILE } from '#/components/product-tiles/common/constants';
import { PlainObject } from '#/types';
import { PAGE_IDENTIFIER, WNT_IDENTIFIER } from '#/analytics/constants';
import { AnalyticsSetting } from '#/components/products/prop-types';
import { FAVORITES as FAVORITES_TILE_TYPE } from '#/constants/tile-types';
import { connect } from '#/lib/render/connect-deep-compare';
import { isMultiSwapsDisabled, isMultiSwapsPanelVisibleByDefault } from '#/experiments/oop-1934/selectors';
import MultiSwapCarousel from '#/experiments/oop-1934/components/multi-swap-carousel';
import { StyledLI, StyledLIProductItem } from '#/experiments/oop-2011/components/fav-layout-tooltip/styled';

type ProductTileVatiant = typeof HORIZONTAL | typeof LEGACY | typeof VERTICAL | typeof TROLLEY_TILE;

type TOwnState = {
  showOneSubstitution: boolean;
  showMultSwapPanelByDefault: boolean;
};

const mapStateToProps = (state: Store): TOwnState => {
  return {
    showOneSubstitution: isMultiSwapsDisabled(state),
    showMultSwapPanelByDefault: isMultiSwapsPanelVisibleByDefault(state),
  };
};

type SubsProps = {
  translate: TTranslateFunc;
  config: TConfigFunc;
  feature: TFeatureFunc;
  substitutions: Array<Item>;
  isAmendBasket: boolean;
  listData: PlainObject;
  isRemoved: boolean;
  resultType: string;
  productTileVariant: ProductTileVatiant;
  itemIndex: number;
  tileType: string;
  substituteTextHeading: string | undefined;
  isSwapButtonToggled?: boolean;
  itemOriginalIndex?: number;
};

export const Substitution = ({
  translate,
  config,
  feature,
  substitutions,
  isAmendBasket,
  listData,
  isRemoved,
  resultType,
  productTileVariant,
  itemIndex,
  tileType,
  showOneSubstitution,
  substituteTextHeading = translate('product-tile:you-could-substitute-with'),
  isSwapButtonToggled,
  showMultSwapPanelByDefault,
  itemOriginalIndex,
}: SubsProps & TOwnState): JSX.Element => {
  const firstSub = substitutions[0];

  const type = [TROLLEY_TILE, FAVORITES_TILE_TYPE].includes(tileType) ? tileType : HORIZONTAL_EMBEDDED;

  const legacyProps = {
    type,
    isAmendBasket,
    item: firstSub,
    itemIndex,
    identifier: SUB_IDENTIFIER,
    listData,
    isRemoved,
    resultType,
    itemOriginalIndex,
  };

  const productTileProps = { ...legacyProps, translate, config, feature };

  return (
    <>
      {showOneSubstitution ? (
        <div className="substitute-container">
          {substituteTextHeading && <h3 className="substitute-text-heading">{substituteTextHeading}</h3>}
          <ProductTileConfiguration
            legacyProps={legacyProps}
            productTileVariant={productTileVariant}
            productTileProps={productTileProps}
          />
        </div>
      ) : (
        (isSwapButtonToggled || showMultSwapPanelByDefault) && (
          <MultiSwapCarousel
            substituteTextHeading={substituteTextHeading}
            substitutions={substitutions}
            productTileProps={productTileProps}
            legacyProps={legacyProps}
          />
        )
      )}
    </>
  );
};

export const Substitutions = connect(mapStateToProps)(Substitution);

type TProps = {
  productTileVariant: ProductTileVatiant;
  itemId: string;
  substitutions: Array<Item>;
  tileType: string;
  isAmendBasket: boolean;
  itemIndex: number;
  substituteIndex: number;
  item: Item & { isRemoved: boolean };
  offset: number;
  listData: PlainObject;
  onDetailsClick: Function;
  shouldShowSubs: boolean;
  shouldRenderSubsAsItem: boolean;
  resultType: string;
  t: TTranslateFunc;
  c: TConfigFunc;
  f: TFeatureFunc;
  substituteTextHeading: string | undefined;
  analyticsSettings: typeof AnalyticsSetting;
};

export const ListItem = ({
  c: config,
  f: feature,
  isAmendBasket,
  item,
  itemId,
  itemIndex: index,
  listData,
  offset,
  onDetailsClick,
  productTileVariant,
  resultType,
  shouldShowSubs,
  substituteIndex,
  substitutions,
  t: translate,
  tileType,
  substituteTextHeading,
  analyticsSettings,
  shouldRenderSubsAsItem = false,
}: TProps): JSX.Element => {
  const memoItem = useMemo(() => item, [item]);
  const itemIndex = index + substituteIndex + offset;
  const legacyProps = {
    type: tileType,
    isAmendBasket,
    item: memoItem,
    itemIndex,
    identifier: memoItem.isWhyNotTry ? WNT_IDENTIFIER : PAGE_IDENTIFIER,
    listData,
    isRemoved: memoItem.isRemoved,
    onDetailsClick,
    shouldShowSubs,
    resultType,
    analyticsSettings,
  };

  const [isSwapButtonToggled, setButtonToggle] = useState(false);
  const handleSubsContainerChange = (isSwapButtonActive: boolean): void => {
    setButtonToggle(isSwapButtonActive);
  };

  const productTileProps = { ...legacyProps, translate, config, feature, handleSubsContainerChange };

  const renderSubs = (): JSX.Element | null => {
    if (typeof substitutions?.length === 'undefined' || substitutions.length === 0) {
      return null;
    }

    return (
      <Substitutions
        isRemoved={memoItem.isRemoved}
        substitutions={substitutions}
        itemIndex={itemIndex}
        itemOriginalIndex={index}
        translate={translate}
        config={config}
        feature={feature}
        isAmendBasket={isAmendBasket}
        listData={listData}
        resultType={resultType}
        productTileVariant={productTileVariant}
        tileType={tileType}
        substituteTextHeading={!shouldRenderSubsAsItem && substituteTextHeading}
        isSwapButtonToggled={isSwapButtonToggled}
      />
    );
  };

  const subs = renderSubs();

  return (
    <>
      <StyledLIProductItem
        className={classnames('product-list--list-item', {
          first: index === 0,
          'with-subs': shouldRenderSubsAsItem && subs,
        })}
        key={itemId}
      >
        <ProductTileConfiguration
          legacyProps={legacyProps}
          productTileProps={productTileProps}
          productTileVariant={productTileVariant}
        />
        {!shouldRenderSubsAsItem && subs}
      </StyledLIProductItem>
      {shouldRenderSubsAsItem && subs && <StyledLI>{subs}</StyledLI>}
    </>
  );
};

const matchItems = (prev: PlainObject, next: PlainObject): boolean => deepEqual(prev.item, next.item);

export default memo(helpers(['t', 'f', 'c'])(ListItem), matchItems);
