import styled from 'styled-components';
import { media } from '@ddsweb/foundation';
import { spacing } from '@ddsweb/selectors';
import { ContentStamp } from '#/components/cms-driven';

export const ContainerComponent = styled.div`
  position: absolute;
  justify-content: space-between;
  width: ${(props: { isMenuSelected: boolean }): string =>
    props.isMenuSelected ? `calc((100% - 22px) / 4)` : `calc((100% - 22px) / 2)`};
  display: ${(props: { isMenuSelected: boolean }): string => (props.isMenuSelected ? 'block' : 'flex')};
  top: 0;
  right: ${(props: { isMenuSelected: boolean }): string => (props.isMenuSelected ? '5%' : '15%')};
  margin-top: ${spacing.lg};
  ${media.belowTablet`
    background-color: white;
    width: 100%;
    right: 0;
    display: block;
    padding: ${spacing.sm} ${spacing.md};
    margin-top: 0;
    position: relative;
  `};
`;

export const StyledContentStamp = styled(ContentStamp)`
  margin-bottom: ${spacing.sm};
  width: ${(props: { isMenuSelected: boolean }): string => (props.isMenuSelected ? '100%' : '48%')};
  .ddsweb-link__text {
    text-wrap: wrap;
  }
  & div > picture > img {
    min-height: auto;
  }
  ${media.belowTablet`
    margin-bottom:  ${spacing.sm};
    width: 100%;
  `};
`;
