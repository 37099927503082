import styled, { css } from 'styled-components';
import { spacing } from '@ddsweb/selectors';
import Notification from '@ddsweb/notification';
import { TNotificationProps } from './index.defs';
import { WARNING, SUCCESS } from '#/constants/notification-variants';

const borderColor = {
  [WARNING]: '#ff9900',
  [SUCCESS]: '#008500',
};

const StyledNotification = styled(Notification)`
  ${({ highlight, variant }: TNotificationProps) =>
    highlight &&
    css`
      border: 1px solid ${borderColor[variant]};
      margin-bottom: ${spacing.sm};
    `};
`;
StyledNotification.displayName = 'Notification';

export { StyledNotification };
