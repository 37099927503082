import analyticsBus from '#/analytics/analyticsBus';
import { basicEvent } from '#/analytics/types/basic';
import { CLOSE, NOW, SLOTS } from '#/analytics/constants';
import { TTranslateFunc } from '#/lib/records/helpers.defs';
import { sessionStore } from '#/lib/data-store/client-store';
import { setFocus } from '#/lib/browser/ui-util';
import { ModalData } from '#/reducers/types.defs';
import { DISMISS_INTERESTED_IN_SLOT_NOTIFY_ME, OK, UNAVAILABLE_NOTED_MODAL } from '#/experiments/oop-2034/constants';
import * as helpers from '#/experiments/oop-2034/helpers';
import { Moment } from 'moment';

export const focusModalTriggerElement = (modalTriggerRef: React.MutableRefObject<Element | null | undefined>): void => {
  // cannot pass ref object through the dispatch function that triggers this modal,
  // so we cannot use onCloseFocusRef prop provided by ddsweb, and we have to set the focus here
  const timeout = setTimeout(() => {
    setFocus(modalTriggerRef.current);
    clearTimeout(timeout);
  }, 0);
};

export const getNotifyMeThankYouModalData = (translate: TTranslateFunc, onCloseModal: () => void): ModalData => {
  const onClick = (): void => {
    helpers.triggerNotifyMeAnalyticsEvent(UNAVAILABLE_NOTED_MODAL + OK);
    onCloseModal();
  };

  const onChange = (): void => {
    helpers.triggerNotifyMeAnalyticsEvent(UNAVAILABLE_NOTED_MODAL + CLOSE);
    onCloseModal();
  };

  return {
    title: translate('modals:oop-2034-thank-you.heading'),
    bodyTextPrimary: translate('modals:oop-2034-thank-you.text'),
    buttonText: translate('common:ok'),
    onClick,
    onChange,
  };
};

export const enrichAnalyticsEventWithDate = (event: string, moment: Moment): string => {
  return moment
    .format(`[${event}:week] W[:]ddd[:]h:mma`)
    .toString()
    .toLowerCase();
};

export const dismissInterestedInSlotNotifyMe = (): boolean =>
  sessionStore?.get(DISMISS_INTERESTED_IN_SLOT_NOTIFY_ME) == true;

export const triggerNotifyMeAnalyticsEvent = (value: string): void => {
  if (!value) {
    return;
  }

  basicEvent(analyticsBus, {
    action: NOW,
    type: SLOTS,
    value: value,
  });
};
