import {
  INSTRUCTIONS_CHANGED,
  INSTRUCTIONS_ERROR,
  INSTRUCTIONS_SAVING,
  INSTRUCTIONS_SAVED,
  SET_INSTRUCTIONS,
  FETCHING_INSTRUCTIONS,
} from '../constants/action-types';
import { InstructionsState } from '#/custom-typings/redux-store/instructions.defs';

import { SAVED, SAVING, IDLE, ERROR, PENDING, CHANGED } from '../constants/instruction-status';

export interface InstructionsAction {
  instructions?: string;
  oldInstructions?: string;
  type:
    | 'INSTRUCTIONS_CHANGED'
    | 'INSTRUCTIONS_ERROR'
    | 'INSTRUCTIONS_SAVING'
    | 'INSTRUCTIONS_SAVED'
    | 'SET_INSTRUCTIONS'
    | 'FETCHING_INSTRUCTIONS';
}
export const instructionsChanged = ({ instructions }: InstructionsAction): InstructionsState => ({
  hasError: false,
  instructions,
  status: CHANGED,
});

export const instructionsError = (): InstructionsState => ({
  hasError: true,
  status: ERROR,
});

export const instructionsSaving = (): InstructionsState => ({
  status: SAVING,
});

const instructionsSaved = ({ oldInstructions }: InstructionsAction, currentStatus: string): InstructionsState => {
  return {
    hasError: false,
    oldInstructions: oldInstructions,
    status: currentStatus === SAVING ? SAVED : CHANGED,
  };
};

const setInstructions = ({ instructions }: InstructionsAction): InstructionsState => ({
  instructions,
  oldInstructions: instructions,
  status: IDLE,
});

export default (state: InstructionsState = {}, action: InstructionsAction): InstructionsState => {
  switch (action.type) {
    case INSTRUCTIONS_CHANGED:
      return { ...state, ...instructionsChanged(action) };
    case INSTRUCTIONS_ERROR:
      return { ...state, ...instructionsError() };
    case INSTRUCTIONS_SAVING:
      return { ...state, ...instructionsSaving() };
    case INSTRUCTIONS_SAVED:
      return { ...state, ...instructionsSaved(action, state.status || SAVING) };
    case SET_INSTRUCTIONS:
      return { ...state, ...setInstructions(action) };
    case FETCHING_INSTRUCTIONS:
      return { ...state, status: PENDING, hasError: false };
    default:
      return state;
  }
};

export const getDefaultStateFromProps = ({
  maxLength,
  trolley,
  instructions,
}: {
  maxLength: number;
  trolley: {
    deliveryPreferences: {
      deliveryInstruction: string;
    };
  };
  instructions: string;
}): InstructionsState => {
  const deliveryPreferences = trolley && trolley.deliveryPreferences;
  const deliveryInstruction = deliveryPreferences?.deliveryInstruction || instructions;

  return {
    hasError: !!deliveryInstruction && deliveryInstruction.length > maxLength,
    instructions: deliveryInstruction,
    oldInstructions: deliveryInstruction,
    status: 'idle',
  };
};

export const getInstructions = ({ instructions }: Store): string => instructions?.instructions || '';

export const getOldInstructions = ({ instructions }: Store): string | undefined => instructions?.oldInstructions;

export const getStatus = ({ instructions }: Store): string | undefined => instructions?.status;

export const getHasError = ({ instructions }: Store): boolean | undefined => instructions?.hasError;
