import React from 'react';
import IBYCRecommendation from '#/experiments/oop-2349/components';
import { BASKET_ADD_COUNT_RECS_CAROUSEL, RECS_CAROUSEL_DISPLAY_THRESHOLD } from '#/experiments/oop-2349/constants';
import { sessionStore } from '#/lib/data-store/client-store';

type RecsCarouselProps = {
  showRecsCarousel: boolean;
  recsCarouselIndex: number;
  itemIndex: number;
  isIBYCCarouselEnabled: boolean;
  pageId: string;
};

const shouldShowIBYCCarousel = (props: RecsCarouselProps): boolean => {
  const { showRecsCarousel, recsCarouselIndex, itemIndex, isIBYCCarouselEnabled } = props;
  const basketAddCountFromSession = sessionStore?.get(BASKET_ADD_COUNT_RECS_CAROUSEL) || 0;
  return (
    basketAddCountFromSession >= RECS_CAROUSEL_DISPLAY_THRESHOLD &&
    showRecsCarousel &&
    itemIndex === recsCarouselIndex &&
    isIBYCCarouselEnabled
  );
};

export const RecsCarousel: React.FC<RecsCarouselProps> = props => {
  const isIbycRecsCarousel = shouldShowIBYCCarousel(props);

  return isIbycRecsCarousel ? (
    <li className="product-list--list-ibyc-carousel" key={`list-ibyc-carousel-${props.itemIndex}`}>
      <IBYCRecommendation pageId={props?.pageId} />
    </li>
  ) : null;
};
