import produce from 'immer';
import { getResourceData } from '../resources/resource-util';
import { ORDER_LIST_DETAILS } from '../constants/spa-resource';
import {
  ORDER_LIST_DETAILS_FETCHED,
  UPDATE_ORDER_IM_HERE_ARRIVED
} from '#/constants/action-types';
import { imHereStatusValue } from '#/components/home/context-cards/im-here-button/selector';

export default function(state = {}, { type, value }) {
  return produce(state, draftState => {
    switch (type) {
      case ORDER_LIST_DETAILS_FETCHED:
        if (value.items) {
          return {
            ...value,
            items: withImHereStatus(value.items, draftState.items)
          };
        }
        return value;
      case UPDATE_ORDER_IM_HERE_ARRIVED:
        return updateOrderImHereArrived(state, value);
      default:
        return state;
    }
  });
}

const withImHereStatus = (newOrders, existingOrders = []) => {
  return newOrders.map(order => {
    if (
      existingOrders.find(item => item.orderNo === order.orderNo)
        ?.imHereStatus !== imHereStatusValue.RECEIVED
    ) {
      let status = imHereStatusValue.UNAVAILABLE;
      if (order?.fulfilment?.outlet?.isSmartPhoneRequired) {
        status = imHereStatusValue.AVAILABLE;
      }
      return { ...order, imHereStatus: status };
    }
    return { ...order, imHereStatus: imHereStatusValue.RECEIVED };
  });
};

export function updateOrderImHereArrived(state, value) {
  return produce(state, draftState => {
    const order = draftState.items.find(item => item.orderNo === value);
    order.imHereStatus = imHereStatusValue.RECEIVED;
  });
}

export function getDefaultStateFromProps(props) {
  const orderList = getResourceData(props.resources, ORDER_LIST_DETAILS) || {};

  // TODO: refactor this while refactoring my orders page to use new order list slice
  // my orders page will have pendingOrders in props, pushing it to store as it is required for analytics
  if (
    (!orderList?.items || orderList?.items?.length < 1) &&
    props?.pendingOrders?.length > 1
  )
    orderList.items = props.pendingOrders;
  if (orderList.items) {
    orderList.items = withImHereStatus(orderList.items);
  }
  return orderList;
}
