import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from '#/lib/render/connect-deep-compare';
import helpers from '#/lib/decorators/helpers';
import { itemsToContentData } from '#/analytics/bertie/utils/products';
import { emitRenderedContentOp } from '#/analytics/bertie/events';
import { getAppISORegionCode } from '#/reducers/app';
import { getProductId } from '#/selectors/item';

const mapStateToProps = state => ({
  ISORegionCode: getAppISORegionCode(state)
});

@helpers(['c'])
@connect(mapStateToProps)
export default class AnalyticsRenderedProductsEvent extends Component {
  static propTypes = {
    c: PropTypes.func.isRequired,
    componentId: PropTypes.string,
    componentName: PropTypes.string,
    componentType: PropTypes.string,
    displayArea: PropTypes.oneOf([
      'grd',
      'smp',
      'csl',
      'bnr',
      'pro',
      'hdr',
      'ftr',
      'ttl',
      'sld',
      'dfp',
      'div',
      'ukn',
      'ptl',
      'nav',
      'vid'
    ]),
    ISORegionCode: PropTypes.string,
    items: PropTypes.object.isRequired,
    itemsPerPage: PropTypes.number,
    layoutType: PropTypes.string,
    pageNo: PropTypes.number,
    pageType: PropTypes.string,
    resultsCount: PropTypes.number,
    seedLookup: PropTypes.string,
    sendSponsoredId: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.configKey = {
      currency: this.props.c('currency')
    };
  }

  isMatchingProduct = (a, b) => getProductId(a) === getProductId(b);

  isCountNotEqualOrItemsNotMatched = (a, b) => {
    const prevItems = a?.items ?? a;
    const nextItems = b?.items ?? b;

    const prevItemsCount = prevItems.size;
    const nextItemsCount = nextItems.size;

    return (
      prevItemsCount !== nextItemsCount ||
      ![...prevItems.values()].every(x =>
        [...nextItems.values()].some(y => this.isMatchingProduct(x, y))
      )
    );
  };

  shouldComponentUpdate(nextProps) {
    if (
      Object.keys(nextProps).length !== Object.keys(this.props).length ||
      this.props.layoutType !== nextProps.layoutType
    ) {
      return true;
    }

    for (const key in nextProps) {
      if (key === 'items') {
        const prevItems = this.props.items;
        const nextItems = nextProps.items;

        if (this.isCountNotEqualOrItemsNotMatched(prevItems, nextItems)) {
          return true;
        }
      } else if (this.props[key] !== nextProps[key]) {
        return true;
      }
    }

    return false;
  }

  componentDidUpdate() {
    this.dispatchRenderedProductsAnalyticsEvent();
  }

  componentDidMount() {
    this.dispatchRenderedProductsAnalyticsEvent();
  }

  dispatchRenderedProductsAnalyticsEvent() {
    const {
      c: config,
      componentName,
      componentType,
      displayArea,
      ISORegionCode,
      items,
      itemsPerPage,
      pageNo,
      pageType,
      resultsCount,
      seedLookup,
      sendSponsoredId,
      componentId
    } = this.props;

    emitRenderedContentOp({
      media: sendSponsoredId ? { catalogueCountry: ISORegionCode } : undefined,
      content: [
        {
          componentId,
          componentName,
          componentType,
          displayArea,
          pageType,
          panel: itemsToContentData({
            currency: config('currency').isoCode,
            items,
            seedLookup,
            sendSponsoredId
          })
        }
      ],
      itemsPerPage,
      pageNumber: pageNo,
      resultsCount,
      resultCountForPage: items.size
    });
  }

  render() {
    return null;
  }
}
