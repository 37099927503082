import React from 'react';
import classnames from 'classnames';
import { StyledProductActions } from '#/components/product-tiles/vertical-tile/core/styles';
import DeleteFavorite from '#/components/product-tiles/common/favorite-delete';
import { Item } from '#/lib/records/item';
import { TTranslateFunc } from '#/lib/records/helpers.defs';
import { favoritesPageShouldShowRemoveButton } from '#/selectors/beans-product-tile/remove-button-data';
import VerticalTile, { BaseProps } from '#/components/product-tiles/vertical-tile/core';
import { hasSquareOfferSash } from '#/components/products/product-tile/helpers';
import { connect } from '#/lib/render/connect-deep-compare';
import { getCurrentUrl } from '#/reducers/app';
import { isAvailableEpwOverride, isProductAvailable } from '#/selectors/beans-product-tile/product-availability-data';
import { getRestOfShelfLinkData } from '#/selectors/beans-product-tile/rest-of-shelf-link-data';
import {
  getRestOfShelfText,
  shouldShowRestOfShelfButton,
} from '#/selectors/beans-product-tile/rest-of-shelf-buybox-data';
import { getWriteAReviewData } from '#/selectors/beans-product-tile/write-a-review-data';
import { getProductId, getProductTitle, getRestOfShelfUrl, isSponsoredProduct } from '#/selectors/item';
import { FIXED } from '#/components/product-tiles/vertical-tile/core/constants';
import FavoriteOptimisticDelete from '#/components/pages/favorites/favourites-tab/favorite-optimistic-delete';
import { getOptimisticDeleteSelection, getSoftDeleteStatusForOptimisticDelete } from '#/selectors/ui';
import { FAVORITES_TAB } from '#/constants/favorites';

export type StateProps = {
  productId: string;
  productTitle: string;
  available: boolean;
  isSponsoredProduct?: boolean;
  showRestOfShelfButton?: boolean;
  restOfShelfLink?: ReturnType<typeof getRestOfShelfLinkData>;
  restOfShelfUrl?: string | null;
  restOfShelfText?: string | null;
  showSquareOfferSash?: boolean;
  variationKey?: string;
  writeAReviewData?: ReturnType<typeof getWriteAReviewData>;
  shouldRemoveFromList: boolean;
};

type OwnProps = BaseProps;

const mapStateToProps = (state: Store, { config, feature, item, translate }: OwnProps): StateProps => {
  const available = isProductAvailable(state, item);
  const showRestOfShelfButton =
    shouldShowRestOfShelfButton(item, config, getCurrentUrl(state)) && !isAvailableEpwOverride(state, item);
  let shouldRemoveFromList = false;
  const productId = getProductId(item);
  const selectionData = getOptimisticDeleteSelection(state, FAVORITES_TAB);
  const softDeleteStatus = getSoftDeleteStatusForOptimisticDelete(state, FAVORITES_TAB);
  shouldRemoveFromList = (softDeleteStatus && selectionData.includes(productId)) || false;

  return {
    available,
    isSponsoredProduct: isSponsoredProduct(item),
    restOfShelfLink: getRestOfShelfLinkData(state, item, translate, config),
    restOfShelfText: showRestOfShelfButton ? getRestOfShelfText(item, translate) : null,
    restOfShelfUrl: showRestOfShelfButton ? getRestOfShelfUrl(item) : null,
    showRestOfShelfButton,
    showSquareOfferSash: hasSquareOfferSash(item, available),
    writeAReviewData: getWriteAReviewData(state, item, feature, translate),
    productId,
    productTitle: getProductTitle(item),
    shouldRemoveFromList,
  };
};

export type FavoriteTileProps = BaseProps & StateProps & { item: Item; translate: TTranslateFunc };

export const FavoriteTile = (props: FavoriteTileProps): JSX.Element => {
  const { item, productId, productTitle, shouldRemoveFromList, config, ...otherProps } = props;
  const showRemoveButton = favoritesPageShouldShowRemoveButton(item);
  const deleteFavoriteComponent = config('showFavOptimisticDelete') ? (
    <FavoriteOptimisticDelete productId={productId} productTitle={productTitle} />
  ) : (
    <DeleteFavorite productId={productId} productTitle={productTitle} />
  );

  return (
    <VerticalTile
      config={config}
      {...otherProps}
      className={classnames('favorite-tile', { 'remove-from-list-with-animation': shouldRemoveFromList })}
      item={item}
      showFavHeartIcon={false}
      variant={FIXED}
      deleteActionButton={
        showRemoveButton && (
          <StyledProductActions data-auto="product-actions">{deleteFavoriteComponent}</StyledProductActions>
        )
      }
    />
  );
};

export default connect(mapStateToProps)(FavoriteTile);
