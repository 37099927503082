import moment from 'moment';

export const showCheckoutRecommendations = (
  extendOffersToAllSlots,
  currSlotEndDate,
  amendOffersEnabled,
  isAmend,
  customVariableCheckout,
  recommendationsPeriodDays = 7
) => {
  return (
    customVariableCheckout !== null &&
    customVariableCheckout !== 'default' &&
    (amendOffersEnabled || !isAmend) &&
    currSlotEndDate &&
    (extendOffersToAllSlots
      ? true
      : moment(currSlotEndDate).isBefore(
          extendedDate(recommendationsPeriodDays)
        ))
  );
};

/*
 * Derive new date from current date + number of days
 * We are not memorising the endDate, due to the page being rendered only on the server.
 */
export const extendedDate = days => {
  return moment.utc().add(days, 'day');
};
