import React from 'react';
import { compose } from 'react-recompose';
import classnames from 'classnames';
import Link from '#/components/link-check-spa';
import LazyImage from '#/components/image/lazy-image';
import Image from '#/components/image';
import Button from '@ddsweb/button';
import Icon from '@ddsweb/icon';
import analyticsBus from '#/analytics/analyticsBus';
import { basicEvent } from '#/analytics/types/basic';
import { VIEW_AND_BUY_TYPE_FAVORITES, VIEW_AND_BUY_TYPE_OFFERS } from '#/constants/display-types';
import { getIsDesktop, getIsMobile, getIsTablet } from '#/reducers/app';
import { connect } from '#/lib/render/connect-deep-compare';
import { openModal } from '#/actions/ui-action-creators';
import { IMAGE_ZOOM_MODAL } from '#/constants/modal-names';
import helpers from '#/lib/decorators/helpers';
import { updateParamsInUrl } from '#/lib/url/url-utils';

type TViewAndBuyType = typeof VIEW_AND_BUY_TYPE_FAVORITES | typeof VIEW_AND_BUY_TYPE_OFFERS;

type TProps = {
  alt: string;
  alwaysDisplayImage: boolean;
  available: boolean;
  clickable: boolean;
  hasPromotion: boolean;
  hideFromScreenReader: boolean;
  href: string;
  id: string;
  image: string;
  images: Array<string>;
  isDesktop: boolean;
  isLazyImage: boolean;
  isMobile: boolean;
  isPDP: boolean;
  isTablet: boolean;
  isUnavailable: boolean;
  title: string;
  viewAndBuyType: TViewAndBuyType;
  zoomable: boolean;
  t: (key: string, data?: object) => string;
  onClick: (event?: React.MouseEvent<HTMLElement>) => void;
  openModal: (type: string, options: { [key: string]: string }) => void;
};

const mapStateToProps = (state: { app: { deviceType: string } }): Partial<TProps> => ({
  isDesktop: getIsDesktop(state),
  isMobile: getIsMobile(state),
  isTablet: getIsTablet(state),
});

const connector = connect(mapStateToProps, { openModal });

const productAvailabilityChanged = (
  { isUnavailable: isUnavailable }: TProps,
  nextProps: { isUnavailable: boolean; image?: string },
): boolean => isUnavailable !== nextProps.isUnavailable;

const productImageChanged = (
  { image: image }: TProps,
  nextProps: { isUnavailable: boolean; image?: string },
): boolean => image !== nextProps.image;

const hasImage = (src: string | ''): boolean | '' => src && src.indexOf('/noimage') === -1;

const getImageSrc = ({ image: image, isPDP: isPDP }: TProps): Record<string, string> => {
  let src = image || '';
  let srcSet = '';

  //  90x90 doesn't have a 'no image'
  if (hasImage(src)) {
    let smallSrc;

    if (isPDP) {
      smallSrc = src;
      src = src.replace('_225x225', '_540x540');

      src = updateParamsInUrl(src, {
        h: 540,
        w: 540,
      });
    } else {
      src = src.replace('_135x135', '_225x225');
      smallSrc = src.replace('_225x225', '_90x90');
    }

    srcSet = `${smallSrc} 768w, ${src} 4000w`;
  }
  return { src, srcSet };
};

const getZoomButton = ({ t: translate }: TProps): JSX.Element => {
  return (
    <Button
      icon={<Icon graphic="zoomIn" />}
      className="product-image__zoom"
      variant="secondary"
      aria-label={translate('product-tile:open-zoom-modal')}
    />
  );
};

const getImage = ({ isLazyImage: isLazyImage }: TProps): typeof LazyImage | typeof Image =>
  isLazyImage ? LazyImage : Image;

const renderImage = (props: TProps, showZoomButton?: boolean): JSX.Element => {
  const className = classnames({
    'product-image': true,
    grayscale: props.isUnavailable,
    'product-image-visible': props.alwaysDisplayImage,
  });
  const ImageComponent = getImage(props);
  const { src, srcSet } = getImageSrc(props);

  return (
    <div className="product-image__container">
      <ImageComponent src={src} srcSet={srcSet} className={className} alt={props.alt} />
      {props.zoomable && showZoomButton && getZoomButton(props)}
    </div>
  );
};

const zoomModal = (props: TProps): void => {
  analyticsBus().emit('UIEventBasicEvent', {
    type: 'pdp',
    value: 'click zoom',
    action: 'now',
  });
  props.openModal(IMAGE_ZOOM_MODAL, {
    selectedUrl: getImageSrc(props).src,
  });
};

const handleClick = (props: TProps): void => {
  if (props.onClick) {
    props.onClick();
  } else if (props.viewAndBuyType) {
    basicEvent(analyticsBus, {
      type: `view and buy ${props.viewAndBuyType}`,
      value: 'product tile',
      action: 'delay',
    });
  }
};

export function ImageWrapper(props: TProps): JSX.Element {
  const { src: imageSrc } = getImageSrc(props);

  const image =
    props.zoomable && hasImage(imageSrc) ? (
      <div
        className="product-image--clickable"
        onClick={(): void => zoomModal(props)}
        onKeyDown={(): void => handleClick(props)}
        role="button"
        tabIndex={0}
      >
        {renderImage(props, true)}
      </div>
    ) : (
      renderImage(props)
    );

  // This ensures that `aria-hidden` isn't serialised if false (as its unnecessary code)
  const hideFromScreenReader = props.hideFromScreenReader || undefined;

  return props.href && props.clickable ? (
    <Link
      onClick={(): void => handleClick(props)}
      aria-hidden={hideFromScreenReader}
      className="product-image-wrapper"
      to={props.href}
      tabIndex={-1}
    >
      {image}
    </Link>
  ) : (
    <span aria-hidden={hideFromScreenReader} className="product-image-wrapper" tabIndex={-1}>
      {image}
    </span>
  );
}

React.memo(ImageWrapper, (props, nextProps) => {
  return productAvailabilityChanged(props, nextProps) || productImageChanged(props, nextProps);
});

ImageWrapper.defaultProps = {
  alt: ' ',
  hideFromScreenReader: false,
  clickable: true,
  image: '',
  isPDP: false,
  isUnavailable: false,
  isLazyImage: true,
  zoomable: false,
};

const enhance: Function = compose<TProps, {}>(helpers(['t']), connector);
export default enhance(ImageWrapper);
