import { Items } from '#/custom-typings/redux-store/trolley.defs';
import { getBulkBuyLimitGroupedItems } from '#/lib/records/product-utils';
import { cloneItem } from '#/utils/clone-item';
import { cloneItems } from '#/utils/clone-items';
import get from '#/utils/get';
import set from '#/utils/set';
import { Item } from '#/lib/records/item';

/**
 * Transfers keymaps properties to result list (PLP/PDP) based on Bulk Buy Limit GroupID
 * which is present in trolley and returns a new map of items. This will be data driven and
 * optimized to not to enter withMutations section if there is no group bulk buy limit item in
 * trolley and result list, for IGHS it wont execute withMutations section as there will not be
 * any Bulk Buy Limit GroupID data.
 *
 * @param baseItems : Result list from plp
 * @param trolleyItems : items from trolley
 * @param keymaps : props to be mapped
 * @returns {baseItems} : (baseItems + trolleyItems)
 */
export default function transferBulkBuyLimitProperties<BaseItems extends Items | Array<Item>>(
  baseItems: BaseItems,
  trolleyItems: Items | Array<Item>,
  keymaps: Array<Array<string>>,
): BaseItems {
  const groupedTrolleyItems = getBulkBuyLimitGroupedItems(trolleyItems);

  if (groupedTrolleyItems) {
    const groupedResultItems = getBulkBuyLimitGroupedItems(baseItems);

    if (groupedResultItems) {
      const mergedItems = cloneItems(baseItems);

      groupedTrolleyItems.forEach((items, id) => {
        const bulkByGroupItems = groupedResultItems.get(id);

        if (bulkByGroupItems) {
          const trolleyItem = items.values().next().value;

          bulkByGroupItems.forEach((_, key) => {
            keymaps.forEach(keymap => {
              if (mergedItems instanceof Map) {
                const item = mergedItems.get(key);
                if (item == null) {
                  return;
                }

                const itemToChange = cloneItem(item);

                set(itemToChange, keymap, get(trolleyItem, keymap));

                mergedItems.set(key, itemToChange);
              } else if (Array.isArray(mergedItems)) {
                const itemIdx = mergedItems.findIndex(x => x.product.id === key);
                if (itemIdx === -1) {
                  return;
                }

                const itemToChange = cloneItem(mergedItems[itemIdx]);

                set(itemToChange, keymap, get(trolleyItem, keymap));

                mergedItems[itemIdx] = itemToChange;
              }
            });
          });
        }
      });

      return mergedItems;
    }
  }

  return baseItems;
}
