import { updateRegionInKey, getFeatureVariableStringFromState } from '#/lib/optimizely-manager';
import { PlainObject } from '#/types';
import { favoritesSubsFeature } from '#/constants/features';

export function getSubstituteFeatureExperiment(state: PlainObject): string | undefined {
  const { featureKey, variationKey, useCustomerId } = favoritesSubsFeature;
  const regionInKey = updateRegionInKey(featureKey, state);
  const substituteValue = getFeatureVariableStringFromState(state, regionInKey, variationKey, { useCustomerId });
  return substituteValue && `sub:${substituteValue}`;
}
