import styled from 'styled-components';
import SkipToContent from '@ddsweb/skip-to-content';

export const SkipToContentStyled = styled(SkipToContent)`
      && {
        z-index:3003;
      }
      .ddsweb-skip-to-content {
        &__link {
          -webkit-clip: rect(0 0 0 0);
          clip: rect(0 0 0 0);
          height: 1px;
          overflow: hidden;
          width: 1px;
        },
        &__link:focus {
          -webkit-clip: auto;
          clip: auto;
          height: 40px;
          overflow: visible;
          width: auto;
        }
      }
    };
`;
SkipToContentStyled.displayName = 'SkipToContent';
