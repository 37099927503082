import bertie from '../analyticsBus';
import analyticBus from '../../analytics/analyticsBus';
import { basicEvent } from './basic';

export const STORAGE_KEY = 'searchAnalytics';
export const ICID = 'icid';

// term selection methods
export const FREE_TEXT = 'free text';
export const SUGGESTED = 'suggested';
export const PREDICT_NEXT = 'predict next';
export const REFERRAL = 'internal referral search';
export const LANDING = 'entry search';
export const MULTISEARCH = 'multisearch';

export function searchAnalyticsEvent(
  searchTerm,
  numberOfResults,
  listId,
  firstItemNumber,
  lastItemNumber,
  searchTermTyped = '',
  searchType = '',
  suggestionsReturned = 0,
  suggestionPosition = 0,
  matchType,
  multisearchListCount = 0,
  multisearchListPosition = 0
) {
  const data = {
    searchTerm,
    numberOfResults,
    firstVisibleItemNumber: firstItemNumber,
    lastVisibleItemNumber: lastItemNumber,
    listID: listId,
    searchTermTyped,
    searchType,
    suggestionsReturned,
    suggestionPosition,
    multisearchListCount,
    multisearchListPosition
  };

  if (matchType) {
    data.matchType = matchType;
  }

  bertie().emit('UISearch', data);
}

export function storeDataForAnalytics(termSelectionMethod, options) {
  sessionStorage.setItem(
    STORAGE_KEY,
    JSON.stringify({
      searchTerm: options.searchTerm,
      searchTermTyped: options.searchTermTyped || options.searchTerm,
      termSelectionMethod,
      suggestionsReturned: options.suggestions || 0,
      suggestionPosition: options.selectedSuggestionIndex || 0,
      msListCount: options.msListLength || 0,
      msListPosition: options.msListPosition + 1 || 0
    })
  );
}

export function emitMultiSearchEvent(type, action = 'delay') {
  basicEvent(analyticBus, {
    type: 'search',
    value: `multisearch:${type}`,
    action
  });
}
