import React, { forwardRef, MouseEventHandler } from 'react';
import { AppBarConfig, getIconPropertiesForMenuItem } from '../app-bar/utils';
import { StyledDiv } from './styled';
import { MenuType } from './mobile-menu-overlay';
import { StandaloneLink } from '@ddsweb/link';

export type Props = {
  active?: boolean;
  menuItem: AppBarConfig;
  index?: number;
  isAppbar?: boolean;
  onClick: MouseEventHandler<HTMLAnchorElement>;
};

const shouldStretchLink = ({ children, external, hasPopup, icon }: MenuType): boolean => {
  let stretch = true;

  if (icon && !external && !hasPopup && !children?.length) {
    stretch = false;
  }

  return stretch;
};

const MobileMenuItem = forwardRef<HTMLAnchorElement, Props>(({ menuItem, index = 0, isAppbar, onClick }, ref) => {
  return (
    <StyledDiv>
      <StandaloneLink
        className={isAppbar ? 'app-bar-link' : 'primary-link'}
        domRef={ref}
        target={menuItem.target}
        href={menuItem.href as string}
        icon={getIconPropertiesForMenuItem(menuItem)}
        onClick={onClick}
        stretch={shouldStretchLink(menuItem as MenuType)}
        data-testid={menuItem.text ? `menu-${menuItem.text?.toLowerCase()}-${index}` : undefined}
      >
        {menuItem.text}
      </StandaloneLink>
    </StyledDiv>
  );
});

export default MobileMenuItem;
