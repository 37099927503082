import url from 'url';
import {
  EXTERNAL_SESSION_RENEW_REQUIRED,
  LOCKED_ALREADY
} from '#/constants/error-codes';
import {
  expandResourcesList,
  getRequiredResources,
  requestResources,
  shouldUpdate as defaultShouldUpdate,
  handleResources
} from '#/utils/resources';
import {
  RESOURCES_RECEIVED,
  UPDATE_RESOURCE,
  RESOURCES_ERROR,
  SET_RESOURCES_REQUESTED_TIMESTAMP,
  SET_RESOURCES_RECEIVED_TIMESTAMP
} from '#/reducers/resources';
import {
  getHost,
  getLanguageLink,
  getProtocol,
  getRedirectUrl
} from '#/reducers/app';
import commonActionRejectionHandler from '#/actions/common-action-rejections-handler';
import { getResources } from '#/selectors/resources';
import { getCachingCriteria } from '#/selectors/resources/caching-criteria';
import { getIsRegistrationUrl } from '#/utils/url';
import { getStoreId, getTrolleyShoppingMethod } from '#/selectors/trolley';
import { addFromParamToUrl } from '#/lib/auth/login-utils';
import { getSearchedAddress } from '#/selectors/slot';

const resourcesBaseUrl = 'resources';

export const resourcesReceived = resources => ({
  type: RESOURCES_RECEIVED,
  resources
});

export const resourcesError = (resourceNames, status, url, requestedAt) => ({
  type: RESOURCES_ERROR,
  resourceNames,
  status,
  url,
  requestedAt
});

export const updateResource = (resourceName, data, requestedAt) => ({
  type: UPDATE_RESOURCE,
  resourceName,
  data,
  requestedAt
});

export const setResourcesRequestedTimestamp = resources => ({
  type: SET_RESOURCES_REQUESTED_TIMESTAMP,
  resources
});

export const setResourcesReceivedTimestamp = resources => ({
  type: SET_RESOURCES_RECEIVED_TIMESTAMP,
  resources
});

export const fetchResources = (
  resourceNames,
  sharedParams = {},
  options = {}
) => async (dispatch, getState) => {
  const requestedAt = Date.now();
  const {
    acceptWaitingRoom = false,
    forceFetch = false,
    shouldUpdate = defaultShouldUpdate,
    updateCurrentURL = null,
    requiresAuthentication = false
  } = options;
  const state = getState();
  const endpoint = getLanguageLink(state, resourcesBaseUrl);
  const cacheCriteria = getCachingCriteria(state);
  const deliveryType = getTrolleyShoppingMethod(state);
  const storeId = getStoreId(state);
  const suggestionSelectedAddress = getSearchedAddress(state);

  sharedParams = {
    ...sharedParams,
    deliveryType,
    storeId
  };

  if (suggestionSelectedAddress) {
    if (sharedParams.query) {
      sharedParams.query = {
        ...sharedParams.query,
        addressId: suggestionSelectedAddress.id
      };
    } else {
      sharedParams = {
        ...sharedParams,
        query: {
          addressId: suggestionSelectedAddress.id
        }
      };
    }
  }

  const expandedResources = expandResourcesList(
    resourceNames,
    sharedParams,
    cacheCriteria
  );

  const requiredResources = getRequiredResources(
    expandedResources,
    forceFetch ? null : state
  );

  if (!requiredResources.length) {
    dispatch(setResourcesRequestedTimestamp(resourceNames));
    dispatch(setResourcesReceivedTimestamp(resourceNames));
    // The resources are already in the state but still need to be synced because of some required mutations
    handleResources(dispatch, getState, resourceNames, options);

    return getResources(getState());
  }

  const requiredResourceNames = requiredResources.map(({ type }) => type);

  dispatch(setResourcesRequestedTimestamp(requiredResourceNames));

  try {
    const resourceData = await requestResources(
      requiredResources,
      sharedParams,
      {
        acceptWaitingRoom,
        endpoint,
        requiresAuthentication
      }
    );

    // If some resources were already in the state and were not fetched, they need to be synced because of some required mutations
    const combinedResourceNames = Object.keys(resourceData)
      .concat(resourceNames)
      .reduce(function(acc, value) {
        if (acc.indexOf(value) === -1) {
          acc.push(value);
        }

        return acc;
      }, []);
    if (shouldUpdate(getState, resourceData)) {
      dispatch(resourcesReceived(resourceData));
      handleResources(dispatch, getState, combinedResourceNames, options);

      return getResources(getState());
    }

    return false;
  } catch (error) {
    const { parsedBody, status, waitingRoomRedirectionUrl } = error;
    const redirectUrl = parsedBody?.['redirect-to'];
    const errorCode = parsedBody?.['errorCode'];

    if (
      errorCode === LOCKED_ALREADY &&
      (parsedBody || status === 401) &&
      updateCurrentURL
    ) {
      const redirectTo = decodeURI(
        getRedirectUrl(getState(), updateCurrentURL)
      );
      window.location.replace(redirectTo);
      return new Promise(() => {});
    }

    // if the error has a `waitingRoomRedirectionUrl` property, redirect to that URL
    if (waitingRoomRedirectionUrl) {
      const url = new URL(waitingRoomRedirectionUrl);
      const params = new URLSearchParams(url.search);

      params.set('t', window.location.href);
      url.search = params.toString();

      window.location.replace(url.toString());
      return new Promise(() => {});
    }

    if (status >= 300 && status <= 308) {
      window.location.replace(parsedBody['redirect-to']);
      return new Promise(() => {});
    }

    if (
      (status === 403 || status === 503) &&
      redirectUrl &&
      getIsRegistrationUrl(redirectUrl)
    ) {
      window.location.reload();
      return new Promise(() => {}); // The only purpose of this is to keep waiting for data until the page has reloaded.
    }

    const receivedAt = Date.now();
    const erroredResources = {};

    requiredResources.forEach(function(resource) {
      erroredResources[resource.type] = {
        ...resource,
        requestedAt,
        receivedAt
      };
    });

    const authProblem = status === 401 || status === 403;
    const csrfProblem = status === 419;

    // Avoid throwing an error if the resources have been replaced by newer ones that downloaded successfully
    if (
      !defaultShouldUpdate(getState, erroredResources) &&
      !authProblem &&
      !csrfProblem
    ) {
      return;
    }

    if (status !== 401 && status !== 503) {
      dispatch(
        resourcesError(
          requiredResourceNames,
          status,
          updateCurrentURL,
          requestedAt
        )
      );
    }

    const shouldRenewSession =
      errorCode === EXTERNAL_SESSION_RENEW_REQUIRED && updateCurrentURL;

    const isTokenExpired = status === 401;

    if (shouldRenewSession) {
      const { pathname, query } = url.parse(updateCurrentURL, true);

      const fromValue = url.format({
        protocol: getProtocol(getState()),
        host: getHost(getState()),
        pathname,
        query
      });

      error.parsedBody['redirect-to'] = addFromParamToUrl(
        redirectUrl,
        fromValue
      );
    } else if ((parsedBody || authProblem) && updateCurrentURL) {
      error.parsedBody = parsedBody || {};
      error.parsedBody['redirect-to'] = decodeURI(
        getRedirectUrl(getState(), updateCurrentURL)
      );
    }

    commonActionRejectionHandler(error, dispatch);

    if (status !== 401 && status !== 503) {
      return getResources(getState());
    }

    if (shouldRenewSession || isTokenExpired) {
      return { sessionRefreshExternally: true };
    }
    return false;
  }
};
