/**
 * This file serves an important part in Slots page flow and functionality.
 * Whenever making changes to this file make sure to update the related
 * documentation diagrams present in dir: web/docs/slots
 *
 * @author beetlejuice
 */

import {
  getCurrentSlotGroup,
  getSelectedDate,
  getSelectedLocationId,
  getSelectedShoppingMethod,
  hasFetchedActiveSlots,
} from '#/reducers/slot';
import { formatDate } from '#/lib/slot/slot-range-utils';
import { getTimezone } from '#/reducers/app';
import {
  NEW_LOCATIONS_DATA,
  NEW_SLOT_DATA,
  NEW_SLOT_DATA_PENDING,
  SYNC_SELECTED_ACTUAL_VALUES,
} from '#/constants/action-types';
import { COLLECTION, ShoppingMethod } from '#/constants/shopping-methods';
import { fetchResources } from '../resources-action-creators';
import { SLOT, LOCATION, TROLLEY_CONTENTS, FULFILMENT_METADATA, ADDRESSES } from '#/constants/spa-resource';
import { getResource, getResources } from '#/selectors/resources';
import { Dispatch, GetStore } from '#/custom-typings/redux-store/common';
import { SlotGroup } from '#/custom-typings/redux-store/slot.defs';
import { getDefaultAddressId } from '#/utils/address-utils';

type Params = {
  slotGroup: SlotGroup;
  shoppingMethod: ShoppingMethod;
  location: string | null;
  date?: string;
  addressId?: string;
};

type UpdateSlotsOptions = {
  forceUpdate: boolean;
};

type NewSlotsData = {
  availableSlotGroups: SlotGroup[];
  currentSlotGroup: SlotGroup;
  featureExperiments?: { [key: string]: string };
  fulfilmentLocation?: object;
  selectedDate: string;
  shoppingMethod: ShoppingMethod;
};

const newSlotsData = (
  data: NewSlotsData,
  timezone: string,
): {
  type: typeof NEW_SLOT_DATA;
  value: {
    data: NewSlotsData;
    timezone: string;
  };
} => {
  return {
    type: NEW_SLOT_DATA,
    value: {
      data,
      timezone,
    },
  };
};

export const getSlots = (params: Partial<Params> = {}) => async (
  dispatch: Dispatch,
  getState: GetStore,
): Promise<object> => {
  const resourcesList = [SLOT, FULFILMENT_METADATA, TROLLEY_CONTENTS];
  const state = getState();
  const currentSlotGroup = params.slotGroup || getCurrentSlotGroup(state);
  const shoppingMethod = params.shoppingMethod || getSelectedShoppingMethod(state);
  const selectedLocation = params.location || (shoppingMethod === COLLECTION && getSelectedLocationId(state)) || null;
  const date = params.date || getSelectedDate(state);
  const addressId = params.addressId || getDefaultAddressId(getResource(ADDRESSES));

  if (shoppingMethod === COLLECTION) resourcesList.push(LOCATION);

  const resourceParams = {
    date,
    shoppingMethod,
    query: {
      locationId: selectedLocation,
      addressId,
      slotGroup: currentSlotGroup,
    },
  };

  try {
    await dispatch(fetchResources(resourcesList, resourceParams));
  } catch (err) {
    console.error(err);
  }

  const resources = getResources(getState());
  const slotResourceData = resources[SLOT].data;
  const res = {
    shoppingMethod,
    slots: slotResourceData.slots,
    availableSlotGroups: resources[FULFILMENT_METADATA]?.data?.availableSlotGroups,
    currentSlotGroup,
    selectedDate: formatDate(slotResourceData.selectedDate),
    fulfilmentLocation: slotResourceData.fulfilmentLocation,
    featureExperiments: slotResourceData.featureExperiments,
  };
  const locations = resources[LOCATION]?.data ?? [];

  if (locations.length > 0) {
    dispatch({
      type: NEW_LOCATIONS_DATA,
      value: {
        locations,
      },
    });
  }

  dispatch(newSlotsData(res, getTimezone(state)));
  return res;
};

export const updateSlots = (params: Partial<Params> = {}, options: UpdateSlotsOptions | {} = {}) => (
  dispatch: Dispatch,
  getState: GetStore,
): Promise<object | void> => {
  if ((options as UpdateSlotsOptions).forceUpdate || !hasFetchedActiveSlots(getState())) {
    dispatch({
      type: NEW_SLOT_DATA_PENDING,
    });

    return dispatch(getSlots(params)).then(res => {
      dispatch({ type: SYNC_SELECTED_ACTUAL_VALUES });

      return res;
    });
  }

  dispatch({ type: SYNC_SELECTED_ACTUAL_VALUES });
  return Promise.resolve();
};
