import React from 'react';
import { ConnectedProps } from 'react-redux';
import { compose } from 'react-recompose';
import DDSLink from '@ddsweb/link';
import helpers from '#/lib/decorators/helpers';
import { connect } from '#/lib/render/connect-deep-compare';
import { getLanguageLink } from '#/reducers/app';
import { getDefaultSlotsPath } from '#/reducers/slot';
import Link from '#/components/link-check-spa';
import { Content, Header, HeaderText, LinkContainer } from './styled';
import { INFO, WARNING, ERROR } from '#/constants/notification-variants';
import { TSpaLink } from '#/custom-typings/utils/components.defs';

type OwnProps = {
  header: string;
  link: string | undefined;
  variant: typeof INFO | typeof WARNING | typeof ERROR;
  onClickHandler: () => void | undefined;
};

type HelperProps = {
  f: ((featureKey?: string | string[]) => string) | undefined;
};

type StateProps = {
  slotsPageUrl: string;
};

const mapStateToProps = (state: Store, { f }: HelperProps): StateProps => ({
  slotsPageUrl: getLanguageLink(state, getDefaultSlotsPath(f)(state)),
});

const connector = connect(mapStateToProps);

type Props = OwnProps & HelperProps & ConnectedProps<typeof connector>;

const spaLink = ({ children, href, onClick }: TSpaLink): JSX.Element => (
  <Link to={href} onClick={onClick}>
    {children}
  </Link>
);

export const BeansSlotDetails: React.FC<Props> = ({
  header,
  link,
  children,
  variant,
  slotsPageUrl,
  onClickHandler,
}) => {
  return (
    <>
      <Header showIcon={false} variant={variant}>
        <HeaderText forwardedAs="p">{header}</HeaderText>
      </Header>
      <Content>{children}</Content>
      {link && (
        <LinkContainer>
          <DDSLink
            href={slotsPageUrl}
            icon={{ graphic: 'forwardLink', position: { global: 'right' }, size: 'xs' }}
            variant="standalone"
            spaLink={spaLink}
            onClick={onClickHandler}
          >
            {link}
          </DDSLink>
        </LinkContainer>
      )}
    </>
  );
};

const enhance = compose<Props, OwnProps>(helpers(['f']), connector);

export default enhance(BeansSlotDetails);
