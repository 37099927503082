// Adapted from https://github.com/Microsoft/BingMapsV8CodeSamples/tree/master/Samples/Custom%20Overlays/CanvasLayer
function getAreas() {
  const map = this.getMap();
  const entitiesLength = map.entities.getLength();
  const output = [];

  for (let i = 0; i < entitiesLength; i++) {
    const current = map.entities.get(i);

    if (current.isHighlightedArea) {
      output.push(current);
    }
  }

  return output;
}

function removeAreas() {
  const map = this.getMap();
  const entitiesLength = map.entities.getLength();

  for (let i = 0; i < entitiesLength; i++) {
    const current = map.entities.get(i);

    if (current.isHighlightedArea) {
      map.entities.remove(current);
    }
  }
}

function createArea(options) {
  const center = {
    latitude: options.latitude,
    longitude: options.longitude
  };
  const radius = options.radius || 1000;
  const map = this.getMap();

  const locs = Microsoft.Maps.SpatialMath.getRegularPolygon(
    center,
    radius,
    36,
    Microsoft.Maps.SpatialMath.DistanceUnits.Meters
  );
  const poly = new Microsoft.Maps.Polygon(locs, {
    visible: false
  });

  poly.isHighlightedArea = true;
  poly.centerLocation = center;

  map.entities.push(poly);

  return poly;
}

function createAreas(areas = []) {
  areas.forEach(area => {
    this.createArea(area);
  });
}

function setAreas(areas) {
  this.removeAreas();
  this.createAreas(areas);
  this.redraw();
}

function onAdd() {
  const canvas = document.createElement('canvas');

  canvas.className = 'map__highlighted-areas';

  this.setHtmlElement(canvas);

  this.canvas = canvas;
}

function onViewchange() {
  const map = this.getMap();

  //Re-drawing the canvas as it moves would be too slow. Instead, scale and translate canvas element.
  const zoomCurrent = map.getZoom();
  const centerCurrent = map.getCenter();

  //Calculate map scale based on zoom level difference.
  const scale = Math.pow(2, zoomCurrent - this.zoomStart);

  //Calculate the scaled dimensions of the canvas.
  const newWidth = map.getWidth() * scale;
  const newHeight = map.getHeight() * scale;

  //Calculate offset of canvas based on zoom and center offsets.
  const pixelPoints = map.tryLocationToPixel(
    [this.centerStart, centerCurrent],
    Microsoft.Maps.PixelReference.control
  );
  const centerOffsetX = pixelPoints[1].x - pixelPoints[0].x;
  const centerOffsetY = pixelPoints[1].y - pixelPoints[0].y;
  const x = -(newWidth - map.getWidth()) / 2 - centerOffsetX;
  const y = -(newHeight - map.getHeight()) / 2 - centerOffsetY;

  //Update the canvas CSS position and dimensions.
  this.updatePosition(x, y, newWidth, newHeight);
}

function onLoad() {
  const map = this.getMap();
  const addHandler = Microsoft.Maps.Events.addHandler;

  this.zoomStart = map.getZoom();
  this.centerStart = map.getCenter();

  this.createAreas(this.options.areas);
  this.redraw();

  this.viewChangeEvent = addHandler(map, 'viewchange', this.onViewchange);
  this.viewChangeEndEvent = addHandler(map, 'viewchangeend', this.updateCanvas);
  this.mapResizeEvent = addHandler(map, 'mapresize', this.updateCanvas);

  const { onReady } = this.options;

  if (onReady) {
    onReady();
  }
}

function updateCanvas() {
  const map = this.getMap();

  this.canvas.style.display = '';

  this.updatePosition(0, 0, map.getWidth(), map.getHeight());
  this.redraw();

  this.zoomStart = map.getZoom();
  this.centerStart = map.getCenter();
}

function onRemove() {
  const removeHandler = Microsoft.Maps.Events.removeHandler;

  this.setHtmlElement(null);

  this.canvas = null;

  removeHandler(this.viewChangeEvent);
  removeHandler(this.viewChangeEndEvent);
  removeHandler(this.mapResizeEvent);
}

function updatePosition(x, y, w, h) {
  const canvas = this.canvas;

  canvas.style.left = x + 'px';
  canvas.style.top = y + 'px';
  canvas.style.width = w + 'px';
  canvas.style.height = h + 'px';
}

function drawHighlight(canvasCtx, map, area) {
  const pixelPoints = map.tryLocationToPixel(
    area.getLocations(),
    Microsoft.Maps.PixelReference.control
  );

  canvasCtx.beginPath();

  pixelPoints.forEach(function(point, index) {
    if (index !== 0) {
      canvasCtx.lineTo(point.x, point.y);
    } else {
      canvasCtx.moveTo(point.x, point.y);
    }
  });

  canvasCtx.fill();
}

function redraw() {
  const map = this.getMap();
  const width = map.getWidth();
  const height = map.getHeight();
  const canvas = this.canvas;
  const ctx = canvas.getContext('2d');

  canvas.width = width;
  canvas.height = height;

  ctx.globalCompositeOperation = 'source-over';

  ctx.fillStyle = 'rgba(0,0,0,0.2)';
  ctx.fillRect(0, 0, width, height);

  ctx.globalCompositeOperation = 'destination-out';
  ctx.fillStyle = 'rgba(0,0,0,1)';

  const entitiesLength = map.entities.getLength();

  for (let i = 0; i < entitiesLength; i++) {
    const current = map.entities.get(i);

    if (current.isHighlightedArea) {
      this.drawHighlight(ctx, map, current);
    }
  }
}

export default function createHighlightedAreas(map, options = {}) {
  function HighlightedAreasOverlay(map, options) {
    this.options = options;

    this.getAreas = this.getAreas.bind(this);
    this.onViewchange = this.onViewchange.bind(this);
    this.updateCanvas = this.updateCanvas.bind(this);
  }

  // We won't know if Microsoft.Maps.CustomOverlay is already loaded at start up so can't extend the class normally
  HighlightedAreasOverlay.prototype = new Microsoft.Maps.CustomOverlay({
    drawOrder: -1
  });

  HighlightedAreasOverlay.prototype.getAreas = getAreas;
  HighlightedAreasOverlay.prototype.removeAreas = removeAreas;
  HighlightedAreasOverlay.prototype.createArea = createArea;
  HighlightedAreasOverlay.prototype.createAreas = createAreas;
  HighlightedAreasOverlay.prototype.setAreas = setAreas;
  HighlightedAreasOverlay.prototype.onAdd = onAdd;
  HighlightedAreasOverlay.prototype.onViewchange = onViewchange;
  HighlightedAreasOverlay.prototype.onLoad = onLoad;
  HighlightedAreasOverlay.prototype.updateCanvas = updateCanvas;
  HighlightedAreasOverlay.prototype.onRemove = onRemove;
  HighlightedAreasOverlay.prototype.updatePosition = updatePosition;
  HighlightedAreasOverlay.prototype.drawHighlight = drawHighlight;
  HighlightedAreasOverlay.prototype.redraw = redraw;

  const overlay = new HighlightedAreasOverlay(map, options);

  map.layers.insert(overlay);

  return overlay;
}
