import PropTypes from 'prop-types';
import React from 'react';
import helpers from '#/lib/decorators/helpers';
import InfoMessage from '#/components/products/info-message';
import InfoMessageList from '#/components/products/info-message-list';
import { getDefaultSlotsPath } from '#/reducers/slot';
import {
  getAllInfoMessages,
  getAldiPriceMatchMessageProps,
  getLowEverydayPricingMessageProps,
  getRestrictedOrderAmendmentMessageProps
} from '#/lib/product-tile/product-info-messages-helper';
import { connect } from '#/lib/render/connect-deep-compare';
import { getLanguageLink } from '#/reducers/app';
import {
  isAldiPriceMatch,
  isClubcardPromotionOnItem,
  isExcluded,
  isInBasket,
  isLowEverydayPricing,
  getProductIsForSale,
  getIsNewlyAdded
} from '#/selectors/item';
import { PriceInfo } from '#/components/products/price-info';

export const mapStateToProps = (state, { f, item }) => ({
  slotsLanguageLink: getLanguageLink(state, getDefaultSlotsPath(f)(state)),
  showAldiPriceMatch: isAldiPriceMatch(item),
  showLowEverydayPricing: isLowEverydayPricing(item),
  isClubcardPromotion: isClubcardPromotionOnItem(item)
});

const RenderInfoMessages = props => {
  const {
    cssClassName,
    isAmendBasket,
    item,
    showAldiPriceMatch,
    showLowEverydayPricing,
    isClubcardPromotion,
    showOnlinePriceInfo
  } = props;

  const getInfoMessages = (item, excludedInAmendBasket) => {
    const { excludeMessages, slotsLanguageLink, t: translate } = props;

    const messagingProps = getAllInfoMessages(
      item,
      excludedInAmendBasket,
      excludeMessages,
      translate,
      slotsLanguageLink
    );
    return messagingProps ?? null;
  };

  const excludedInAmendBasket =
    isAmendBasket &&
    isInBasket(item) &&
    isExcluded(item) &&
    !getIsNewlyAdded(item);

  const availableForSale = getProductIsForSale(item) || excludedInAmendBasket;

  const restrictedOrderAmendMessage = getRestrictedOrderAmendmentMessageProps(
    item,
    availableForSale,
    props
  );

  const shouldRenderInfoMessage =
    !restrictedOrderAmendMessage && availableForSale;

  const infoMessages =
    shouldRenderInfoMessage && getInfoMessages(item, excludedInAmendBasket);

  const aldiPriceMatchMessage = getAldiPriceMatchMessageProps(
    showAldiPriceMatch,
    availableForSale
  );

  const lowEverydayPricingMessage = getLowEverydayPricingMessageProps(
    showLowEverydayPricing,
    availableForSale
  );

  const isMessageAvailable =
    infoMessages ||
    restrictedOrderAmendMessage ||
    aldiPriceMatchMessage ||
    lowEverydayPricingMessage ||
    showOnlinePriceInfo;

  return !isMessageAvailable ? null : (
    <div className={cssClassName}>
      {infoMessages ? <InfoMessageList {...infoMessages} /> : null}
      {restrictedOrderAmendMessage ? (
        <InfoMessage {...restrictedOrderAmendMessage} />
      ) : null}
      {aldiPriceMatchMessage && !isClubcardPromotion ? (
        <InfoMessage {...aldiPriceMatchMessage} />
      ) : null}
      {lowEverydayPricingMessage &&
      !showAldiPriceMatch &&
      !isClubcardPromotion ? (
        <InfoMessage {...lowEverydayPricingMessage} />
      ) : null}
      {showOnlinePriceInfo && <PriceInfo />}
    </div>
  );
};

RenderInfoMessages.propTypes = {
  cssClassName: PropTypes.string.isRequired,
  excludeMessages: PropTypes.array,
  f: PropTypes.func.isRequired,
  isAmendBasket: PropTypes.bool,
  item: PropTypes.object.isRequired,
  restrictedOrderAmendmentOverrideMessage: PropTypes.string,
  showOnlinePriceInfo: PropTypes.bool,
  slotsLanguageLink: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired
};

RenderInfoMessages.defaultProps = {
  excludeMessages: [],
  showOnlinePriceInfo: false
};

export default helpers(['f', 't'])(
  connect(mapStateToProps)(RenderInfoMessages)
);
