import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import analyticsBus from '#/analytics/analyticsBus';
import { basicEvent } from '#/analytics/types/basic';
import helpers from '#/lib/decorators/helpers';
import SafeForm from '#/components/shared/safe-form';
import Icon from '@ddsweb/icon';
import { getAlternateLanguage, getLanguageLink } from '#/reducers/app';
import { connect } from '#/lib/render/connect-deep-compare';

const mapStateToProps = state => ({
  alternateLanguage: getAlternateLanguage(state),
  actionUrl: getLanguageLink(state, '/language/switch')
});

@connect(mapStateToProps)
@helpers(['t'])
export default class LanguageSwitchLink extends PureComponent {
  static propTypes = {
    actionUrl: PropTypes.string.isRequired,
    alternateLanguage: PropTypes.string,
    returnUrl: PropTypes.string.isRequired,
    t: PropTypes.func
  };

  onClick = () => {
    basicEvent(analyticsBus, {
      type: 'languageChange',
      value: this.props.alternateLanguage
    });
  };

  render() {
    const alternateLanguage = this.props.alternateLanguage;
    const flag = alternateLanguage.split('-')[1].toLowerCase();

    return (
      <SafeForm
        action={this.props.actionUrl}
        className="change-language"
        method="post"
      >
        <input name="requestedLang" type="hidden" value={alternateLanguage} />
        <input name="returnUrl" type="hidden" value={this.props.returnUrl} />
        <button
          ref="languageSwitchBtn"
          onClick={this.onClick}
          lang={alternateLanguage}
        >
          <span className="icon-flag">
            <Icon graphic={flag} />
          </span>
          <span className="caption">
            <span className="visually-hidden">
              {this.props.t('layout:main-nav.switch-language-to', {
                context: alternateLanguage
              })}{' '}
            </span>
            {this.props.t('layout:main-nav.translate', {
              context: alternateLanguage
            })}
          </span>
        </button>
      </SafeForm>
    );
  }
}
