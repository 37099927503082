export const getCurrencyMinorSymbol = (currencyISO: string): string => {
  switch (currencyISO) {
    case 'CZK':
      return 'h';
    case 'EUR':
      return 'c';
    case 'HUF':
      return 'Ft';
    default:
      return 'p';
  }
};

export const getAmountWithCurrencyOrMinorCurrency = (
  amount: number,
  currencySymbol: string,
  currencyISO: string,
): string => {
  if (amount < 1) {
    const subUnitAmount = amount * 100;
    const currencyMinor = getCurrencyMinorSymbol(currencyISO);
    return `${Math.round(subUnitAmount)}${currencyMinor}`;
  } else {
    return `${currencySymbol}${amount.toFixed(2)}`;
  }
};
