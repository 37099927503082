import { MARKETPLACE_SEGMENT, MARKETPLACE_CHECKOUT_SEGMENT } from '#/constants/common';
import { UK, IE } from '#/constants/regions';

export const getUserUniqueId = (region: string, uuid: string, hashedUId: string): string => {
  return region === UK || region === IE ? uuid : hashedUId;
};

export const hasMarketplaceSegment = (segments: string | null): boolean => {
  if (segments) {
    return segments.split(',').includes(MARKETPLACE_SEGMENT);
  }
  return false;
};

export const hasMarketPlaceCheckoutSegment = (segments: string | null): boolean => {
  if (segments) {
    return segments.split(',').includes(MARKETPLACE_CHECKOUT_SEGMENT);
  }
  return false;
};
