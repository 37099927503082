import React from 'react';
import styled from 'styled-components';
import Button from '@ddsweb/button';
import { media } from '@ddsweb/foundation';
import { Text } from '@ddsweb/text';
import { Heading } from '@ddsweb/heading';
import { fontSize, spacing, colors } from '@ddsweb/selectors';
import Modal from '@ddsweb/modal';
import LinkButtonWithFeedback from '#/components/shared/link-button-with-feedback';

export const InlinePayAtDoorMessage = styled(Text)`
  display: inline-block;
  margin-top: ${spacing.sm};
  text-align: left;
`;

export const PayAtDoorButtonLink = styled(Button)`
  & * {
    display: inline;
    white-space: normal;
    font-weight: 400;
    vertical-align: baseline;
    &:hover {
      text-decoration: underline;
    }
  }

  &,
  & button {
    display: contents;
  }
`;

export const StyledModal = styled(Modal)`
  z-index: 9999;

  .ddsweb-modal__close-link {
    background-color: ${colors.white};
    color: ${colors.tescoBlue};

    &:hover,
    &:focus {
      box-shadow: rgba(255, 255, 255, 0.4) 0px 0px 0px 4px;
      transition-duration: 0.3s;
      transition-timing-function: ease;
      transition-delay: 0s;
      transition-property: box-shadow;
      &:after {
        opacity: 0;
      }
    }
  }

  .ddsweb-text {
    display: inline-block;
  }

  .ddsweb-modal__scrollable-container > *:not(:last-child) {
    margin-bottom: ${spacing.md};
  }
`;

export const ModalHeading = styled(Heading)`
  font-size: ${fontSize.md};
`;

export const ModalButtonContainer = styled.div`
  display: flex;
  flex-direction: column;

  & .ddsweb-button__text,
  & .ddsweb-link__text {
    font-size: ${fontSize.xs};
  }

  & > * {
    width: auto;
  }

  & > *:not(:last-child) {
    margin: 0 0 ${spacing.sm} 0;
  }

  ${media.aboveTablet`
    flex-direction: row;

    & > *:not(:last-child) {
      margin: 0 ${spacing.sm} 0 0;
    }
  `}
`;

export const PayOnlineButton = styled((props: Record<string, unknown>) => <LinkButtonWithFeedback {...props} />)`
  && {
    font-size: ${fontSize.xs};
  }
`;
