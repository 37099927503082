import React, { useEffect } from 'react';
import { compose } from 'react-recompose';
import Modal from '@ddsweb/modal';
import Text from '@ddsweb/text';
import analyticsBus from '#/analytics/analyticsBus';
import { basicEvent } from '#/analytics/types/basic';
import { StyledBookASlotButton, StyledButtonContainer, StyledNotNowButton, StyledTitle } from './styled';
import { getLanguageLink } from '#/reducers/app';
import { ConnectedProps, connect } from 'react-redux';
import helpers from '#/lib/decorators/helpers';
import { TTranslateFunc } from '#/lib/records/helpers.defs';
import { closeModal } from '#/actions/ui-action-creators';
import { NOW } from '#/analytics/constants';
import { BOOK_SLOT_MODAL, BOOK_SLOT_MODAL_LOCAL_STORE_KEY, PROMPT_SLOT_BOOKING } from '#/constants/trolley';
import { sessionStore } from '#/lib/data-store/client-store';

type OwnState = {
  groceriesUrl: string;
  currentModal: string;
};
type OwnProps = {
  t: TTranslateFunc;
};

const mapStateToProps = (state: Store): OwnState => ({
  groceriesUrl: getLanguageLink(state, ''),
  currentModal: state.ui.currentModal || '',
});

const mapDispatchToProps = {
  closeModal,
};

const sendAnalytics = (value: string): void => {
  basicEvent(analyticsBus, {
    action: NOW,
    type: PROMPT_SLOT_BOOKING,
    value: value,
  });
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = OwnProps & OwnState & ConnectedProps<typeof connector>;

export const BookSlotModal = ({ groceriesUrl, currentModal, t, closeModal }: Props): React.ReactElement => {
  useEffect(() => {
    sessionStore?.set(BOOK_SLOT_MODAL_LOCAL_STORE_KEY, true);
    sendAnalytics('modal:impression');
  }, []);
  const open = currentModal === BOOK_SLOT_MODAL;

  return (
    <Modal id="modal-1" ssr open={open} onChange={closeModal}>
      <StyledTitle headingLevel="3">{t('modals:book-a-slot.title')}</StyledTitle>
      <Text as="p" size="standard">
        {t('modals:book-a-slot.sub-title')}
      </Text>
      <StyledButtonContainer>
        <StyledBookASlotButton
          buttonVariant="primary"
          data-testid="book-slot-action"
          onClick={(): void => {
            sendAnalytics('modal:book a slot');
          }}
          href={`${groceriesUrl}/slots`}
          stretch
        >
          {t('modals:book-a-slot.book-a-slot')}
        </StyledBookASlotButton>
        <StyledNotNowButton
          variant="secondary"
          data-testid="book-slot-cancel-action"
          onClick={(): void => {
            sendAnalytics('modal:not now');
            closeModal(BOOK_SLOT_MODAL);
          }}
        >
          {t('modals:book-a-slot.not-now')}
        </StyledNotNowButton>
      </StyledButtonContainer>
    </Modal>
  );
};

const enhance = compose(helpers(['t']), connector);

export default enhance(BookSlotModal);
