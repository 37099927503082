export const amendBannerPositionStyle = {
  featureKey: 'ghs-all_onecheckout_amend-banner_pre-checkout',
  variationKey: 'amend-banner-pre-check',
  variants: {
    default: 'default', // stays as is
    amendPosition: 'amend-position', // prev + banner moves to bottom.
    amendPositionCTAs: 'amend-position-CTAs', // prev + save changes CTA. also change mweb/basket CTA copy
    amendPositionCTAsGuideCopyDetectCopy: 'amend-position-CTAs-guide-copy-detect-copy', // prev + diff copy. when new items are added also change copy
    amendPositionCTAsGuideCopyDetectCopyUI: 'amend-position-CTAs-guide-copy-detect-copy-UI', // prev + when new items are added also change color + icon
  },
  useCustomerId: true,
};
