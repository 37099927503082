import PropTypes from 'prop-types';
import React from 'react';
import Modal from '../../shared/modal';
import ModalContent from '../../shared/modal/content';
import helpers from '../../../lib/decorators/helpers';
import { connect } from '../../../lib/render/connect-deep-compare';
import { getCurrentUrl } from '../../../reducers/app';
import Link from '../../link-check-spa';

const mapStateToProps = state => ({
  currentUrl: getCurrentUrl(state)
});

const ErrorBoundaryModal = ({ t: translate, currentUrl }) => {
  return (
    <Modal
      className="modal__error-boundary"
      dismissable={false}
      showLink={false}
    >
      <ModalContent>
        <p>{translate('common:connection-error-message')}</p>
        <div className="modal-actions-button-container">
          <Link className="button button-primary small" to={currentUrl}>
            {translate('common:ok')}
          </Link>
        </div>
      </ModalContent>
    </Modal>
  );
};

ErrorBoundaryModal.propTypes = {
  currentUrl: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(helpers(['t'])(ErrorBoundaryModal));
