import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBackground } from '../responsive-background';

const TileImageContainer = ({ image, placement }) => (
  <div className="tile__image-wrapper">
    <div className="tile__image-container">
      <ResponsiveBackground
        className="tile__image"
        url={image.src}
        breakpoints={image.breakpoints}
        clipping={image.clipping}
        maxWidth={image.maxWidth}
        uid={placement}
      />
    </div>
  </div>
);

TileImageContainer.propTypes = {
  image: PropTypes.object.isRequired,
  placement: PropTypes.number
};

TileImageContainer.defaultProps = {
  placement: 1
};

export { TileImageContainer };
