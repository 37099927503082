import { getFeatureVariableStringFromState } from '#/lib/optimizely-manager';
import { productTileWeightToggleVariants } from '#/experiments/oop-1294/constants';

const {
  featureKey,
  variationKey,
  variants: { default: defaultVariant, hideWeightToggle },
} = productTileWeightToggleVariants;

const getProductTileWeightToggleVariant = (state: Store): string =>
  getFeatureVariableStringFromState(state, featureKey, variationKey) || defaultVariant;

export const getShouldHideWeightToggle = (state: Store): boolean => {
  return getProductTileWeightToggleVariant(state) === hideWeightToggle;
};
