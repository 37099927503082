import React from 'react';
import PropTypes from 'prop-types';
import helpers from '#/lib/decorators/helpers';
import { connect } from '#/lib/render/connect-deep-compare';
import { getLanguage, getLanguageLink } from '#/reducers/app';
import Accordion from '#/components/accordion';
import classNames from 'classnames';
import Link from '#/components/link';
import TextLink from '#/components/text-link';
import { getIsAmendBasket, hasValidSlot } from '#/selectors/trolley';
import { getIsDeliveryAvailable } from '#/selectors/available-shopping-methods.js';
import HowItWorksSection from '#/experiments/oop-1423/components/how-it-works-section';
import { getIsHIWSectionPositionAtTop } from '#/experiments/oop-1423/selectors';

const mapStateToProps = state => ({
  hasValidSlot: hasValidSlot(state),
  isAmendBasket: getIsAmendBasket(state),
  isDeliveryAvailable: getIsDeliveryAvailable(state),
  language: getLanguage(state),
  slotsDeliveryLink: getLanguageLink(state, '/slots/delivery'),
  slotsLightdeliveryLink: getLanguageLink(state, '/slots/lightdelivery'),
  isHIWSectionPositionAtTop: getIsHIWSectionPositionAtTop(state)
});

const CollectionTab = props => {
  const getCollectionAccordionHeader = () => {
    const { t: translate } = props;
    return (
      <div className="option-tabs__collection-accordion-header">
        <span className="icon-question" />
        <span className="option-tabs__collection-accordion-title">
          {translate('slots:collection.collection-notice-title')}
        </span>
      </div>
    );
  };

  const switchToLink = small => {
    const { isDeliveryAvailable, slotsDeliveryLink, t: translate } = props;

    const linkClass = !small && 'collection-point-notice-content__link';

    if (isDeliveryAvailable) {
      return (
        <Link to={slotsDeliveryLink} className={linkClass}>
          {translate('slots:collection.switch-to-delivery')}
        </Link>
      );
    }
  };

  const getCollectionAccordion = () => {
    const { t: translate } = props;
    return (
      <Accordion
        header={getCollectionAccordionHeader()}
        title="option-tabs--collection-tabitem--accordion"
      >
        <div className="options-tabs__content">
          <div className="option-tabs--collection-point-notice">
            <span className="hidden-small">
              <span className="icon-question" />
              <div>
                <div className="option-tabs__collection-accordion-title">
                  <strong>
                    {translate('slots:collection.collection-notice-title')}
                  </strong>
                </div>
                <div className="option-tabs__collection-point-notice-content">
                  <p>
                    {translate('slots:collection.collection-notice-content')}
                  </p>
                  <p>{switchToLink()}</p>
                </div>
              </div>
            </span>
            <div className="small-only">
              <p className="option-tabs__colection-point-notice-content">
                {translate('slots:collection.collection-notice-content')}
              </p>
              <p>{switchToLink(true)}</p>
            </div>
          </div>
        </div>
      </Accordion>
    );
  };

  const getLink = () => {
    const { t: translate, c: config } = props;
    const collectionTabLink = config('links:collectionTab');
    const target =
      (collectionTabLink || '').indexOf('http') > -1 ? '_blank' : '_self';

    return (
      <Link
        className="option-tabs__more-link"
        target={target}
        to={collectionTabLink || '#how-collection-works'}
      >
        {translate('slots:common.click-collect-more-link')}
        <span className="icon icon-chevron_right" />
      </Link>
    );
  };

  const getTabContent = () => {
    const { t: translate, c: config, isAmendBasket, language } = props;
    const collectionDescriptionTranslation = isAmendBasket
      ? 'slots:common.click-collect-desc'
      : 'slots:collection.description';
    const collectionDescriptionText = (
      <p className="option-tabs__desc">
        {translate(collectionDescriptionTranslation)}
      </p>
    );

    return (
      <>
        <div className="small-only">
          {collectionDescriptionText}
          <TextLink
            url={config(`help:clickCollect:${language}`)}
            textKey="slots:common.click-collect-more-link"
          />
        </div>
        <div className="hidden-small">
          {collectionDescriptionText}
          {getLink()}
        </div>
      </>
    );
  };

  const { c: config, isAmendBasket, isHIWSectionPositionAtTop } = props;

  const showClickCollect = config('help:showClickCollect');
  const contentClasses = classNames('option-tabs__main-content', {
    'option-tabs--full-width': !showClickCollect
  });

  const displayDeliveryTabDescription = config('displayDeliveryTabDescription');

  const collectionAccordion = showClickCollect
    ? getCollectionAccordion()
    : null;

  return (
    <>
      {isHIWSectionPositionAtTop ? (
        <HowItWorksSection />
      ) : (
        <>
          {displayDeliveryTabDescription && (
            <div className={contentClasses}>{getTabContent()}</div>
          )}
          {!isAmendBasket && collectionAccordion}
        </>
      )}
    </>
  );
};

CollectionTab.propTypes = {
  c: PropTypes.func.isRequired,
  hasValidSlot: PropTypes.bool.isRequired,
  isAmendBasket: PropTypes.bool.isRequired,
  isDeliveryAvailable: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
  slotsDeliveryLink: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(helpers(['c', 't'])(CollectionTab));
