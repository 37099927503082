import analyticsBus from '#/analytics/analyticsBus';

export function emitErrorDataEvent(props = {}) {
  const errorData = {
    type: 'action',
    timestamp: Date.now(),
    ...props
  };

  analyticsBus().emit('errorData', errorData);
}

export function emitViewErrorDataEvent(props = {}) {
  const errorData = {
    type: 'view',
    ...props
  };

  analyticsBus().emit('errorData', errorData);
}
