import React, { useEffect, useState } from 'react';
import { connect } from '#/lib/render/connect-deep-compare';
import { ConnectedProps } from 'react-redux';
import Modal from '@ddsweb/modal';
import { compose } from 'react-recompose';
import helpers from '#/lib/decorators/helpers';
import { StyledTitle, StyledButton, StyledText } from './styled';
import { getExternalSecureLink, getCurrentUrl } from '#/reducers/app';
import { closeModal } from '#/actions/ui-action-creators';
import { getTrolleyDeliveryAddressId } from '#/selectors/trolley';
import { removeModalFromUrl } from '#/lib/url/modal-utils';
import { focusTrap } from './helper';
import { TConfigFunc, TTranslateFunc } from '#/lib/records/helpers.defs';
import { PHONE_NUMBER_MODAL_TYPES } from './constants';

type State = {
  addressId: string;
  accountUrl: string;
  currentUrl: string;
};

export const mapStateToProps = (state: Store, { c: config }: HelperProps): State => ({
  addressId: getTrolleyDeliveryAddressId(state),
  accountUrl: getExternalSecureLink(state, config('externalEditAddressUrl')),
  currentUrl: getCurrentUrl(state),
});

type HelperProps = {
  t: TTranslateFunc;
  c: TConfigFunc;
  modalData: { type: string; action: string; delivery: string };
};

type ModalData = {
  type: string;
  action: string;
};

type ModalProps = {
  modalData: ModalData;
};

const connector = connect(mapStateToProps, { closeModal });
type Props = HelperProps & ConnectedProps<typeof connector> & ModalProps;

const IncorrectPhoneNumberModal = (props: Props): JSX.Element => {
  const {
    t: translate,
    accountUrl,
    addressId,
    closeModal,
    currentUrl,
    modalData = { type: PHONE_NUMBER_MODAL_TYPES.INVALID_NUMBER, action: 'title', delivery: '' },
  } = props;

  const [isUpdated, setUpdated] = useState(false);
  const { type } = modalData;

  useEffect(() => {
    const modal = document.getElementById('incorrectPhoneModal');
    if (modal) {
      focusTrap(modal);
    }
  });

  const buttonClickHandler = (): void => {
    window.open(getEditPhoneLink());
    setUpdated(true);
  };

  const getRefreshUrlWithoutModal = (): string | URL => removeModalFromUrl(currentUrl, modalData);

  const refreshPage = (): void => window.location.assign(getRefreshUrlWithoutModal());

  const getEditPhoneLink = (): string => `${accountUrl}?id=${addressId}`;

  const renderWarningDialog: JSX.Element = (
    <Modal
      open
      onChange={closeModal}
      className="warning-dialog"
      accessibleLabel="Incorrect phone number"
      id="incorrectPhoneModal"
      closeHref="#close-modal"
    >
      <StyledTitle tagName="h5" headingLevel="4">
        {translate(`modals:${type}.title`)}
      </StyledTitle>

      {translate(`modals:${type}.body.first-p`) != `modals:${type}.body.first-p` && (
        <StyledText forwardedAs="p" contentStyle="longForm">
          {translate(`modals:${type}.body.first-p`)}
        </StyledText>
      )}
      {translate(`modals:${type}.body.second-p`) != `modals:${type}.body.second-p` && (
        <StyledText forwardedAs="p" contentStyle="longForm">
          {translate(`modals:${type}.body.second-p`)}
        </StyledText>
      )}
      {translate(`modals:${type}.body.third-p`) != `modals:${type}.body.third-p` && (
        <StyledText forwardedAs="p" contentStyle="longForm">
          {translate(`modals:${type}.body.third-p`)}
        </StyledText>
      )}

      <StyledButton className="updateButton" onClick={buttonClickHandler}>
        {translate(`modals:${type}.button`)}
      </StyledButton>
    </Modal>
  );

  const confirmationKey = 'updated-phone-number';

  const renderConfirmationDialog: JSX.Element = (
    <Modal
      open
      onChange={refreshPage}
      className="confirmation-dialog"
      accessibleLabel="Thanks for updating your details"
      id="incorrectPhoneModal"
      closeHref="#close-modal"
    >
      <StyledTitle headingLevel="5" visualSize="headline4">
        {translate(`modals:${confirmationKey}.title`)}
      </StyledTitle>
      <StyledText forwardedAs="p" contentStyle="longForm">
        {translate(`modals:${confirmationKey}.body.text`)}
      </StyledText>
      <StyledButton onClick={refreshPage}>{translate(`modals:${confirmationKey}.button`)}</StyledButton>
    </Modal>
  );

  return isUpdated ? renderConfirmationDialog : renderWarningDialog;
};

const enhance = compose<Props, {}>(helpers(['t', 'c']), connector);

export default enhance(IncorrectPhoneNumberModal);
