import React from 'react';
import PropTypes from 'prop-types';
import { connect } from '#/lib/render/connect-deep-compare';
import Footer from '#/components/footer';
import LayoutModals from '#/components/modals/layout-modals';
import SimpleHeader from '#/components/headers/simple';
import {
  getCouponSavings,
  getGuidePrice,
  getIsAmendBasket
} from '#/selectors/trolley';
import helpers from '#/lib/decorators/helpers';

const mapStateToProps = state => ({
  couponSavings: getCouponSavings(state),
  guidePrice: getGuidePrice(state),
  isAmendBasket: getIsAmendBasket(state)
});

/**
 * A layout to be used when a simple logo header is required.
 *
 * `headerLeftHref`: the URL that should be passed to the `Header` left link
 * `headerLeftHref`: the text that should be passed to the `Header` left link
 * `children`: the content to render in the layout
 */

@helpers(['isChromeless'])
@connect(mapStateToProps)
export default class SimpleLayout extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    couponSavings: PropTypes.number.isRequired,
    guidePrice: PropTypes.number.isRequired,
    headerLeftHref: PropTypes.string,
    headerLeftText: PropTypes.string,
    isAmendBasket: PropTypes.bool.isRequired,
    isChromeless: PropTypes.func.isRequired,
    showFooter: PropTypes.bool
  };

  static defaultProps = {
    showFooter: true
  };

  render() {
    const isChromeless = this.props.isChromeless();

    const header = !isChromeless && (
      <SimpleHeader
        leftHref={this.props.headerLeftHref}
        leftText={this.props.headerLeftText}
      />
    );

    const footer = this.props.showFooter && !isChromeless && (
      <Footer shouldExpand={this.props.error} />
    );

    return (
      <div className="content-wrapper simple">
        {header}
        {this.props.children}
        {footer}
        <LayoutModals
          couponSavings={this.props.couponSavings}
          guidePrice={this.props.guidePrice}
          isAmendBasket={this.props.isAmendBasket}
        />
      </div>
    );
  }
}
