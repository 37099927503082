import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import helpers from '../../../lib/decorators/helpers';
import IconList from '../../shared/icon-list';
import { getCardIcons, getThreeDSIcons } from '../../../lib/card-icons';

const getAdditionalLogos = ({ c, t }) => {
  const footerLogos = c('footer:additionalFooterLogos') || [];

  if (footerLogos.length == 0) {
    return null;
  }

  return footerLogos.map(logo => {
    return {
      name: t(`footer:${logo.name}-logo`),
      className: `icon-${logo.name}`,
      url: logo.url
    };
  });
};

const renderIconList = (logoSet, className, listTitle) => {
  return logoSet.length > 0 ? (
    <IconList className={className} icons={logoSet} listTitle={listTitle} />
  ) : null;
};

const FooterLogo = props => {
  const additionalLogos = getAdditionalLogos(props);
  const cardLogos = getCardIcons(props.c('footer:cardLogos'));
  const threeDSLogos = getThreeDSIcons(props.c('footer:threeDSLogos'), props.t);

  return (
    <div
      className={classnames('footer-logo', {
        'footer-logo--simple-footer': props.simpleFooter
      })}
    >
      {props.showCardsInFooter && (
        <div className="footer-logo__payments-container">
          {renderIconList(
            cardLogos,
            'footer-logo__card-icons',
            props.t('checkout:payment-options.cards-accepted')
          )}
          {renderIconList(threeDSLogos, 'footer-logo__3ds-icons')}
        </div>
      )}
      {additionalLogos && (
        <div className="footer-logo__additional-logos-container">
          {renderIconList(additionalLogos)}
        </div>
      )}
    </div>
  );
};

FooterLogo.propTypes = {
  c: PropTypes.func.isRequired,
  showCardsInFooter: PropTypes.bool.isRequired,
  simpleFooter: PropTypes.bool,
  t: PropTypes.func.isRequired
};

export default helpers(['c', 't'])(FooterLogo);
