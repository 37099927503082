import isServer from '#/conditional-resources/conditions/is-server';
import { getSelectedFavoritesTab } from '#/reducers/ui';

export default (state: Store, tab: string): boolean => {
  if (isServer(state)) {
    return true;
  }

  const selectedFavoritesTab = getSelectedFavoritesTab(state);

  return selectedFavoritesTab === tab;
};
