import React, { useRef, useEffect } from 'react';
import { compose } from 'react-recompose';
import { PRIMARY, SECONDARY } from '@ddsweb/constants';
import { TextButtonLink } from '@ddsweb/link';
import {
  DELAY,
  MODAL_ANALYTICS_BOOK_CC,
  MODAL_ANALYTICS_BOOK_HD,
  MODAL_ANALYTICS_CLOSE,
  MODAL_ANALYTICS_IMPRESSION,
  NOW,
} from '#/analytics/constants';
import { connect } from '#/lib/render/connect-deep-compare';
import helpers from '#/lib/decorators/helpers';
import { TTranslateFunc } from '#/lib/records/helpers.defs';
import { formatUrl } from '#/lib/slot/slot-utils';
import { COLLECTION, DELIVERY } from '#/constants/shopping-methods';
import { getLanguageLink } from '#/reducers/app';
import {
  ButtonContainer,
  Content,
  StyledHeading,
  StyledBodyText,
  StyledModal,
} from '#/components/modals/shopping-method-unavailable-modal/styled';
import { closeModal } from '#/actions/ui-action-creators';
import { emitOnDemandAddressModalAnalytics } from '#/analytics/helpers/slot';

type OwnState = {
  homeDeliveryPageLink: string;
  collectionPageLink: string;
};

type OwnProps = {
  t: TTranslateFunc;
  closeModal: () => void;
};

const mapStateToProps = (state: Store): OwnState => ({
  homeDeliveryPageLink: getLanguageLink(state, formatUrl(DELIVERY)),
  collectionPageLink: getLanguageLink(state, formatUrl(COLLECTION)),
});

const connector = connect(mapStateToProps, { closeModal });

type Props = OwnProps & OwnState;

const ShoppingMethodUnavailableModal = (props: Props): JSX.Element => {
  const { closeModal, t: translate, homeDeliveryPageLink, collectionPageLink } = props;
  const tPrefix = `modals:shopping-method-unavailable-modal`;
  const lastActiveElement = useRef(typeof document !== 'undefined' ? (document?.activeElement as HTMLElement) : null);

  useEffect(() => {
    emitOnDemandAddressModalAnalytics(NOW, MODAL_ANALYTICS_IMPRESSION);
  }, []);

  const cancelButtonHandler = (): void => closeModal();

  const handleCCLinkClick = (): void => {
    emitOnDemandAddressModalAnalytics(DELAY, MODAL_ANALYTICS_BOOK_CC);
    cancelButtonHandler();
  };

  const handleDeliveryLinkClick = (): void => {
    emitOnDemandAddressModalAnalytics(DELAY, MODAL_ANALYTICS_BOOK_HD);
    cancelButtonHandler();
  };

  const onCloseModal = (): void => {
    cancelButtonHandler();
    lastActiveElement?.current?.focus();
    emitOnDemandAddressModalAnalytics(NOW, MODAL_ANALYTICS_CLOSE);
  };

  return (
    <StyledModal id="shopping-method-unavailable-modal" open ssr persist onChange={onCloseModal}>
      <Content>
        <StyledHeading>{translate(`${tPrefix}.title`)}</StyledHeading>
        <StyledBodyText>{translate(`${tPrefix}.description`)}</StyledBodyText>
      </Content>
      <ButtonContainer>
        <TextButtonLink buttonVariant={PRIMARY} href={homeDeliveryPageLink} onClick={handleDeliveryLinkClick}>
          {translate(`${tPrefix}.book-hd`)}
        </TextButtonLink>
        <TextButtonLink buttonVariant={SECONDARY} stretch href={collectionPageLink} onClick={handleCCLinkClick}>
          {translate(`${tPrefix}.book-cc`)}
        </TextButtonLink>
      </ButtonContainer>
    </StyledModal>
  );
};

const enhance = compose(helpers(['t']), connector);
export default enhance(ShoppingMethodUnavailableModal);
