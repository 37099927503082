import { spacing } from '@beans/selectors';
import styled from 'styled-components';

export const StyledMultiSash = styled.span`
  margin: 0 ${spacing.xx} ${spacing.xx} 0;
`;

export const StyledSashList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${spacing.xx};
  padding: ${({ isHorizontalTile }: { isHorizontalTile: boolean }): string =>
    isHorizontalTile ? ((spacing.sm as unknown) as string) : '0'};
  padding-bottom: ${({ isHorizontalTile }: { isHorizontalTile: boolean }): string =>
    isHorizontalTile ? '0' : ((spacing.sm as unknown) as string)};
  padding-right: ${spacing.xl};
`;
