import scroll from 'scroll';
import { isMobileViewport } from '#/lib/browser/viewport';

import { AMEND_STICKY_BAR_HEIGHT } from '../../constants/ui';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const scrollDoc = process.env.CLIENT_SIDE ? require('scroll-doc')() : null;

export function scrollToElement(scrollToEl, isAmendMode, extraPixel) {
  let scrollYPos = 0;

  if (scrollDoc && document) {
    if (scrollToEl instanceof HTMLElement) {
      scrollYPos =
        scrollToEl.getBoundingClientRect().top -
        scrollDoc.getBoundingClientRect().top;
    } else {
      const targetedElement = document.getElementById(scrollToEl);

      if (!targetedElement) {
        return;
      }

      scrollYPos =
        targetedElement.getBoundingClientRect().top -
        scrollDoc.getBoundingClientRect().top;
    }

    if (isAmendMode) {
      scrollYPos = scrollYPos - AMEND_STICKY_BAR_HEIGHT;
    }

    if (extraPixel) {
      scrollYPos = scrollYPos - extraPixel;
    }
  }

  scroll.top(scrollDoc, scrollYPos, { duration: 500 });
}

export function scrollToElementIfNeeded(element, isAmendMode, extraPixel) {
  if (scrollDoc && document) {
    if (!(element instanceof HTMLElement)) {
      element = document.getElementById(element);
    }

    let top = element.getBoundingClientRect().top;

    // if element above or below screen
    if (top < 0 || top > document.body.getBoundingClientRect().height)
      scrollToElement(element, isAmendMode, extraPixel);
  }
}

export function scrollToTop() {
  if (window) {
    window.scrollTo(0, 0);
  }
}

export function getElementHeightByClassName(className) {
  if (!isMobileViewport()) return 0;

  const brandHeaderElement = document.getElementsByClassName(className)[0];
  return (
    brandHeaderElement && brandHeaderElement.getBoundingClientRect().height
  );
}

export const scrollToError = (target, errorClass, bufferPixelToScroll) => {
  const firstErrorElement = target.querySelector(errorClass);

  scrollToElement(
    firstErrorElement,
    false,
    getElementHeightByClassName('brand-header-wrapper') + bufferPixelToScroll
  );
  setFocus(firstErrorElement);
};

export const putCursorAtEnd = (element, value) => {
  if (element) {
    const { length } = value;
    element.setSelectionRange && element.setSelectionRange(length, length);
  }
};

export const setFocus = element => element && element.focus();
