import { ContentData, DisplayArea, PageType } from '../../types';
import { contentData, ContentDataPayload } from './content';
import { getComponentNameByType, getPageType } from '#/analytics/bertie/formatters/misc';

export interface ContentInteractOpPayload {
  componentName?: string;
  componentType?: string;
  contextValue?: string;
  displayArea: DisplayArea;
  media?: {
    catalogueCountry: string;
  };
  modulePosition?: number;
  pageType: PageType;
  panel: ContentDataPayload[];
  interactionType?: string;
}

export interface ContentInteractOp {
  content: {
    componentName?: string;
    componentType?: string;
    displayArea: DisplayArea;
    modulePosition?: number;
    pageType?: PageType;
    panel: ContentData[];
  };
  contextValue?: string;
  interactionType?: string;
  media?: {
    catalogueCountry: string;
  };
}

export function getContentInteractOpPayload(data: ContentInteractOpPayload): ContentInteractOp {
  const {
    componentName,
    componentType,
    contextValue,
    displayArea,
    interactionType,
    media,
    modulePosition,
    pageType,
    panel,
  } = data;

  return {
    content: {
      componentName: componentName || getComponentNameByType(componentType),
      componentType,
      displayArea,
      modulePosition,
      pageType: getPageType({ pageType }),
      panel: panel.map((data, index) => contentData(data, index)),
    },
    contextValue,
    interactionType,
    media,
  };
}
