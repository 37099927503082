import React from 'react';
import { addApmData } from '#/lib/apm';
import { isClientPerfEnabled } from '#/utils/perf';

const withProfiler = componentName => ComposedComponent => {
  class Profiler extends React.Component {
    constructor(props) {
      super(props);
      this.isInitialRender = true;
      this.componentName = props.profilerKey
        ? `${componentName}_${props.profilerKey}`
        : componentName;
    }

    componentDidMount() {
      if (isClientPerfEnabled()) {
        const renderStartId = `RenderStart-${this.componentName}`;
        if (window.performance.getEntriesByName(renderStartId, 'mark').length) {
          const { performance } = window;
          const timeToRenderId = `TimeToRender-${this.componentName}`;
          performance.measure(timeToRenderId, renderStartId);
          const renderTime = performance.getEntriesByName(timeToRenderId)[0]
            .duration;

          addApmData(
            `client_component_render_time-${this.componentName}`,
            renderTime
          );

          performance.clearMarks(renderStartId);
          performance.clearMeasures(timeToRenderId);
        }
      }
    }

    render() {
      const { innerRef, ...restProps } = this.props;
      if (isClientPerfEnabled() && this.isInitialRender) {
        this.isInitialRender = false;
        window.performance.mark(`RenderStart-${this.componentName}`);
      }

      return <ComposedComponent {...restProps} ref={innerRef} />;
    }
  }

  const ComponentWithForwardRef = React.forwardRef((props, ref) => (
    <Profiler {...props} innerRef={ref} />
  ));
  ComponentWithForwardRef.WrappedComponent = ComposedComponent;

  return ComponentWithForwardRef;
};
export default withProfiler;
