import { simplePrice, priceBreakdown, pricePromotion } from './product';
import getAnalyticsBus from '../analyticsBus';
import { WEIGHT_UNIT_KG } from '#/constants/common';
import {
  getProductId,
  getProductTitle,
  getProductBrandName,
  getProductBaseProductId,
  getCost,
  getCostChange,
  getQtyChange,
  isCustomerUnitChoice,
  isInFavourites,
  getPieces,
  getWeight
} from '#/selectors/item';

export function basketItem(item, currency) {
  const event = {
    qty: getPieces(item),
    qtyChange: getQtyChange(item),
    weight: getWeight(item),
    productName: getProductTitle(item),
    tpnb: getProductBaseProductId(item) || getProductId(item),
    tpnc: getProductId(item),
    isFav: isInFavourites(item),
    brandName: getProductBrandName(item),
    price: simplePrice(getCost(item), currency),
    priceBreakdown: priceBreakdown(item, currency),
    'tesco:promotion': pricePromotion(item)
  };

  if (getQtyChange(item) !== 0) {
    event.priceChange = simplePrice(getCostChange(item), currency);
  }

  return event;
}

export function basket(
  orderID,
  basketId,
  items,
  guidePrice,
  deliveryPrice,
  currency,
  bagOption,
  basketFullness,
  status,
  secondBasketId
) {
  const basketItems =
    (items instanceof Map ? [...items.values()] : items) || [];

  return {
    orderID,
    bagOption,
    basketFullness,
    basketId,
    totalQty: basketItems.reduce((total, item) => total + getPieces(item), 0),
    guidePrice: simplePrice(guidePrice, currency),
    deliveryPrice: simplePrice(deliveryPrice, currency),
    totalUniqueProducts: basketItems.length,
    status,
    secondBasketId
  };
}

export function dispatchBasketChanged(
  orderID,
  basketId,
  items,
  guidePrice,
  deliveryPrice,
  currency,
  bagOption,
  basketFullness,
  secondBasketId,
  status
) {
  getAnalyticsBus().emit('UIEventBasket', {
    type: 'basketChanged',
    basket: basket(
      orderID,
      basketId,
      items,
      guidePrice,
      deliveryPrice,
      currency,
      bagOption,
      basketFullness,
      status,
      secondBasketId
    )
  });
}

export function transformFromOrder(items) {
  return items.map(item => {
    item.quantity = isCustomerUnitChoice(item, WEIGHT_UNIT_KG)
      ? item.weight
      : item.quantity;

    return {
      customerUnitChoice:
        item.unitChoice === 'Weight' ? WEIGHT_UNIT_KG : item.unitChoice,
      ...item.productItem,
      ...item
    };
  });
}
