import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from '#/lib/render/connect-deep-compare';
import { dispatchBasketChanged } from '#/analytics/helpers/trolley';
import { getById, itemsToMap } from '#/lib/records/product-utils';
import { createItemPayload, createItem } from '#/lib/records';
import {
  getBasketId,
  getGuidePrice,
  getItems,
  getLastSelectedSlot,
  getIsTrolleyUpdating,
  getIsAmendBasket,
  getTrolley,
  getIsCouponSubmitting,
  getIsBagless,
  getHasBasketBreachedByVolumeOrWeight,
  getAnalyticsBasketId,
  getAnalyticsOrderId,
  getAnalyticsMarketplaceBasketId,
  isGroceryOnlySubBasket,
  getGroceryBasket,
  getIsMixedBasket
} from '#/selectors/trolley';
import { getCurrency } from '#/reducers/app';
import { dispatchProductChangeAnalytics } from '#/actions/trolley/analytics-action-creators';
import {
  BASKET_LIMIT_BREACH,
  BASKET_WITHIN_LIMIT,
  GROCERY_BASKET,
  MARKETPLACE_BASKET,
  MIXED_BASKET
} from '#/analytics/constants';
import { isMarketPlaceOnlyBasket } from '#/lib/trolley/trolley-utils';

const mapStateToProps = (state, ownProps) => {
  const items = getItems(state);
  const selectedProduct =
    ownProps.trolleyPayloadSent &&
    getById(items, ownProps.trolleyPayloadSent.id);
  return {
    analyticsOrderId: getAnalyticsOrderId(state),
    analyticsBasketId: getAnalyticsBasketId(state),
    basketId: getBasketId(state),
    currency: getCurrency(state),
    guidePrice: getGuidePrice(state),
    isBagless: getIsBagless(state),
    isBasketBreached: getHasBasketBreachedByVolumeOrWeight(state),
    isCouponUpdating: getIsCouponSubmitting(state),
    isTrolleyUpdating: getIsTrolleyUpdating(state),
    items,
    slotCharge: getLastSelectedSlot(state).charge,
    isAmendBasket: getIsAmendBasket(state),
    selectedProduct: selectedProduct && selectedProduct,
    trolley: getTrolley(state),
    secondBasketId: getAnalyticsMarketplaceBasketId(state),
    isGroceryOnlyBasket: isGroceryOnlySubBasket(state),
    groceryBasket: getGroceryBasket(state),
    isMixedBasket: getIsMixedBasket(state)
  };
};

export const getBasketStatus = (
  trolley,
  isGroceryOnlyBasket,
  isMixedBasket
) => {
  const isMarketplaceOnlyBasket = isMarketPlaceOnlyBasket(trolley);
  if (isMarketplaceOnlyBasket) return MARKETPLACE_BASKET;
  if (isGroceryOnlyBasket) return GROCERY_BASKET;
  if (isMixedBasket) return MIXED_BASKET;
  return null;
};

@connect(mapStateToProps, { dispatchProductChangeAnalytics })
export default class BasketEvent extends Component {
  static propTypes = {
    analyticsBasketId: PropTypes.string,
    analyticsOrderId: PropTypes.string,
    basketId: PropTypes.string,
    currency: PropTypes.object.isRequired,
    dispatchProductChangeAnalytics: PropTypes.func.isRequired,
    guidePrice: PropTypes.number.isRequired,
    isAmendBasket: PropTypes.bool.isRequired,
    isBagless: PropTypes.bool.isRequired,
    isBasketBreached: PropTypes.bool.isRequired,
    isCouponUpdating: PropTypes.bool,
    isTrolleyUpdating: PropTypes.bool,
    items: PropTypes.array.isRequired,
    order: PropTypes.object,
    selectedProduct: PropTypes.object,
    slotCharge: PropTypes.number,
    trolley: PropTypes.object,
    trolleyPayloadSent: PropTypes.object
  };

  getBagOption(isBagless) {
    return isBagless ? 'without bags' : 'with bags';
  }

  getBasketFullness(isBasketBreached) {
    return isBasketBreached ? BASKET_LIMIT_BREACH : BASKET_WITHIN_LIMIT;
  }

  componentDidMount() {
    const { trolley, order } = this.props;

    // set default behaviour
    this.callback = () => {
      const {
        analyticsOrderId,
        analyticsBasketId,
        items,
        guidePrice,
        slotCharge,
        isBagless,
        isBasketBreached,
        secondBasketId
      } = this.props;
      const bagOption = this.getBagOption(isBagless);
      const basketFullness = this.getBasketFullness(isBasketBreached);

      this.emitChanges(
        analyticsOrderId,
        analyticsBasketId,
        items,
        guidePrice,
        slotCharge,
        bagOption,
        basketFullness,
        secondBasketId
      );
    };

    if (trolley) {
      const {
        analyticsOrderId,
        analyticsBasketId,
        isBagless,
        isBasketBreached,
        items,
        guidePrice,
        slotCharge,
        selectedProduct,
        trolleyPayloadSent,
        secondBasketId,
        isGroceryOnlyBasket,
        groceryBasket,
        isMixedBasket
      } = this.props;
      const bagOption = this.getBagOption(isBagless);
      const basketFullness = this.getBasketFullness(isBasketBreached);
      const status =
        groceryBasket &&
        getBasketStatus(trolley, isGroceryOnlyBasket, isMixedBasket);

      this.emitChanges(
        analyticsOrderId,
        analyticsBasketId,
        items,
        guidePrice,
        slotCharge,
        bagOption,
        basketFullness,
        secondBasketId,
        status
      );

      // initialPageLoad needed to stop duplicate events firing when using a spa https://jira.global.tesco.org/browse/LEGO-26138
      if (selectedProduct && window.initialPageLoad) {
        this.props.dispatchProductChangeAnalytics(
          [createItemPayload(trolleyPayloadSent)],
          true,
          createItem(selectedProduct)
        );
      }
    }

    // dispatch on the last step of checking out when order is known
    if (order) {
      //Send initial event straight away on page load with no buffer
      const bagOption = this.getBagOption(this.props.isBagless);
      const basketFullness = this.getBasketFullness(
        this.props.isBasketBreached
      );
      this.emitChanges(
        order.orderNo,
        order.orderId,
        Object.keys(order.items).map(key => order.items[key].productItem),
        order.guidePrice,
        order.slot && order.slot.charge,
        bagOption,
        basketFullness
      );
      this.callback = () => {};
    } else if (this.props.isAmendBasket) {
      this.callback = () => {
        const {
          analyticsOrderId,
          analyticsBasketId,
          isBagless,
          isBasketBreached,
          items,
          guidePrice,
          slotCharge
        } = this.props;
        const bagOption = this.getBagOption(isBagless);
        const basketFullness = this.getBasketFullness(isBasketBreached);

        this.emitChanges(
          analyticsOrderId,
          analyticsBasketId,
          items,
          guidePrice,
          slotCharge,
          bagOption,
          basketFullness
        );
      };
    }
  }

  componentDidUpdate(previousProps) {
    if (
      (previousProps.isTrolleyUpdating && !this.props.isTrolleyUpdating) ||
      previousProps.slotCharge !== this.props.slotCharge ||
      (previousProps.isCouponUpdating && !this.props.isCouponUpdating)
    ) {
      this.callback();
    }
  }

  emitChanges = (
    orderID,
    basketId,
    items,
    guidePrice,
    slotCharge,
    bagOption,
    basketFullness,
    secondBasketId,
    status
  ) => {
    const {
      currency: { symbol }
    } = this.props;
    dispatchBasketChanged(
      orderID,
      basketId,
      itemsToMap(items), // adding itemsToMap for consistent basket changed analytics
      guidePrice,
      slotCharge,
      symbol,
      bagOption,
      basketFullness,
      secondBasketId,
      status
    );
  };

  render = () => null;
}
