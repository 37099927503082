import analyticsBus from '#/analytics/analyticsBus';
import { basicEvent } from '#/analytics/types/basic';
import { DEPARTMENT, AISLE } from '#/constants/taxonomy-levels';
import { TaxonomyStateObj } from '#/custom-typings/redux-store/taxonomy.defs';
import {
  ANALYTICS_TYPE,
  DELAY,
  MEALS_AND_RECIPES_NAV,
  NOW,
  TESCO_REAL_FOOD,
  TESCO_REAL_FOOD_VALUE,
  TOP_NAV,
} from '#/experiments/oop-1946/constants';

type ExtendedTaxonomyState = TaxonomyStateObj & {
  department?: {
    name: string;
  };
};

type selectedMenuType = {
  name: string;
};

const menuForMealsAndRecipe = (
  taxonomyState: ExtendedTaxonomyState,
  menuType: string,
  selected: selectedMenuType,
): string | null => {
  switch (menuType) {
    case DEPARTMENT:
      return `${selected.name}`;
    case AISLE:
      return `${taxonomyState.department?.name}:${selected.name}`;
    default:
      return null;
  }
};

export const analyticsForMealsAndRecipeMenu = (
  taxonomyState: ExtendedTaxonomyState,
  menuType: string,
  selected: selectedMenuType,
): void => {
  let action = DELAY;
  let value = menuForMealsAndRecipe(taxonomyState, menuType, selected) as string;
  if (selected?.name === TESCO_REAL_FOOD) {
    action = NOW;
    value = TESCO_REAL_FOOD_VALUE;
  }

  basicEvent(analyticsBus, {
    type: ANALYTICS_TYPE,
    value: value,
    action: action,
  });
};

export const triggerTopNavAnalytics = (): void => {
  basicEvent(analyticsBus, {
    action: NOW,
    type: TOP_NAV,
    value: MEALS_AND_RECIPES_NAV,
  });
};
