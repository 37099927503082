import React from 'react';
import styled from 'styled-components';
import { ClubCardUKLogo } from '@ddsweb/logos';
import ClubcardLogo from '#/components/promotions/clubcard-logo';

import { promoType } from '#/components/product-tiles/constants';
import FlashSash from '#/components/promotions/flash-sash';

type TSpecialOfferSash = {
  flashSashInfo: {
    text: string;
    type: string;
    color?: string;
  };
  tileType: string;
  className?: string;
  showCCPriceLogoInSquare: boolean;
};

const StyledClubCardUKLogo = styled(ClubCardUKLogo)`
  overflow: hidden;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
`;

const SpecialOfferSash = (props: TSpecialOfferSash): null | JSX.Element => {
  if (!props.flashSashInfo) {
    return null;
  }

  const {
    flashSashInfo: { text, color, type },
    className,
    tileType,
    showCCPriceLogoInSquare,
  } = props;
  const { clubcardPrice } = promoType;
  if (type === clubcardPrice) {
    return showCCPriceLogoInSquare ? (
      <ClubcardLogo text={text} className={className} showCCPriceLogoInSquare={showCCPriceLogoInSquare} />
    ) : (
      <StyledClubCardUKLogo size="sm" className={className} />
    );
  }
  return <FlashSash text={text} backgroundColor={color} className={className} tileType={tileType} />;
};

export default SpecialOfferSash;
