import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from '../../../lib/render/connect-deep-compare';
import {
  removeCoupon,
  selectCoupon,
  deselectCoupon
} from '../../../actions/coupon-action-creators';
import helpers from '../../../lib/decorators/helpers';
import OverlaySpinner from '../../overlay-spinner';
import SafeForm from '../../shared/safe-form';
import { formatToShortDate } from '../../../lib/i18n/date-utils';
import {
  getCurrency,
  getCurrentUrl,
  getLanguage,
  getLanguageLink,
  getTimezone
} from '../../../reducers/app';
import RemoveItemButton from '../../buttons/remove-item';

function CouponTile(props) {
  const { coupon, currentUrl, language, languageLink, timezone } = props;

  const renderSelect = () => {
    if (coupon.qualified) {
      return renderSelectButton();
    } else if (coupon.selected) {
      return <span className="coupon-tile__alert icon-alert-circle-gray" />;
    }
  };

  const renderSelectButton = () => {
    const buttonClasses = classnames('coupon-tile__checkbox', {
      'icon-tick-white': coupon.selected
    });
    const hoverKey = coupon.selected
      ? 'trolley:ewallet.coupon-deselect-hover-text'
      : 'trolley:ewallet.coupon-select-hover-text';
    const hoverText = props.t(hoverKey, { code: coupon.code });

    return (
      <SafeForm
        action={`${languageLink}coupons/${coupon.id}`}
        method="post"
        onSubmit={e => {
          e.preventDefault();
          const { coupon, deselectCoupon, selectCoupon } = props;

          coupon.selected ? deselectCoupon(coupon.id) : selectCoupon(coupon.id);
        }}
      >
        <input type="hidden" name="select" value={!coupon.selected} />
        <input type="hidden" name="returnUrl" value={currentUrl} />
        <button className={buttonClasses} type="submit" title={hoverText}>
          <span className="visually-hidden">{hoverText}</span>
        </button>
      </SafeForm>
    );
  };

  const renderRemoveButton = () => {
    return (
      <SafeForm
        action={`${languageLink}coupons/${coupon.id}?_method=DELETE`}
        method="post"
        onSubmit={e => {
          e.preventDefault();
          props.removeCoupon(props.coupon.id);
        }}
      >
        <input type="hidden" name="returnUrl" value={currentUrl} />
        <RemoveItemButton
          className="coupon-tile__remove-button"
          type="submit"
          textKey="product-list:remove"
          extraText={coupon.code}
        />
      </SafeForm>
    );
  };

  const renderUnqualifiedWarning = () => {
    return (
      <p className="coupon-tile__warning">
        {props.t('trolley:ewallet.order-does-not-qualify')}
      </p>
    );
  };

  const couponIsSubmitting = !!coupon.submitting;
  const disabled = props.disabled || couponIsSubmitting;
  const tileClasses = classnames('coupon-tile', {
    'coupon-tile--selected-qualified': coupon.selected && coupon.qualified,
    'coupon-tile--unselected-qualified': !coupon.selected && coupon.qualified,
    'coupon-tile--selected-unqualified': coupon.selected && !coupon.qualified,
    'coupon-tile--unselected-unqualified': !coupon.selected && !coupon.qualified
  });
  const expiryDate = coupon.endDate
    ? formatToShortDate(coupon.endDate, timezone, language)
    : null;

  const VOUCHER = 'CLUBCARDVOUCHER';
  const {
    currency: { symbol },
    c: config,
    t: translate
  } = props;
  const { code, type, value, offerText, qualified } = coupon;

  return (
    <div className={tileClasses} data-auto={`coupon-code-${code}`}>
      <OverlaySpinner isLoading={disabled} hasSpinner={couponIsSubmitting} />
      {config('coupons:enableRemove') && renderRemoveButton()}
      {config('coupons:enableSelect') && renderSelect()}
      <div className="coupon-tile__description">
        {/* TODO: ROI-445: Keeping the voucher check to show voucher value for ROI Mobile App.
        To be cleaned up once when the native code is available for the ROI checkout
        */}
        <p>{type === VOUCHER ? `${symbol}${value}` : offerText}</p>
        {!qualified && renderUnqualifiedWarning()}
      </div>
      <div className="coupon-tile__info">
        <span data-auto="code">{`${translate('common:code')}: ${code}`}</span>
        {expiryDate && (
          <span data-auto="expiry">{`${translate(
            'common:expires-on'
          )} ${expiryDate}`}</span>
        )}
      </div>
    </div>
  );
}

CouponTile.propTypes = {
  c: PropTypes.func.isRequired,
  coupon: PropTypes.shape({
    code: PropTypes.string.isRequired,
    offerText: PropTypes.string.isRequired,
    endDate: PropTypes.string,
    id: PropTypes.oneOfType([
      PropTypes.string, // UK
      PropTypes.number // IGHS
    ]).isRequired,
    qualified: PropTypes.bool.isRequired,
    selected: PropTypes.bool.isRequired,
    submitted: PropTypes.bool
  }).isRequired,
  currency: PropTypes.shape({
    symbolPosition: PropTypes.string,
    symbol: PropTypes.string
  }),
  currentUrl: PropTypes.string.isRequired,
  deselectCoupon: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  language: PropTypes.string.isRequired,
  languageLink: PropTypes.string.isRequired,
  removeCoupon: PropTypes.func.isRequired,
  selectCoupon: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired
};

CouponTile.defaultProps = {
  disabled: false
};

const mapStateToProps = state => ({
  currency: getCurrency(state),
  currentUrl: getCurrentUrl(state),
  language: getLanguage(state),
  languageLink: getLanguageLink(state),
  timezone: getTimezone(state)
});

export default connect(mapStateToProps, {
  removeCoupon,
  selectCoupon,
  deselectCoupon
})(helpers(['c', 't'])(CouponTile));
