export const STICKY_EXCEPTIONS_LIST = [
  'IE11',
  'IE12',
  'Edge13',
  'Edge14',
  'Edge15',
  'Internet Explorer11',
  'Internet Explorer12',
  'Microsoft Edge13',
  'Microsoft Edge14',
  'Microsoft Edge15'
];

export const NATIVE_STICKY_EXCEPTIONS_LIST = ['Edge', 'Microsoft Edge'];
