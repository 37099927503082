import { getFeatureVariableStringFromState } from '#/lib/optimizely-manager';
import { topNavInspireMeConfig, InspireMeTopNavVariants } from '#/experiments/oop-1890/constants';

const getVariant = (state: Store): InspireMeTopNavVariants => {
  const { featureKey, useCustomerId, variationKey } = topNavInspireMeConfig;
  const attributes = {};

  return (
    getFeatureVariableStringFromState(state, featureKey, variationKey, { useCustomerId, attributes }) ||
    InspireMeTopNavVariants.default
  );
};

export const getIsZonePageVariant = (state: Store): boolean => {
  const variant = getVariant(state);
  return variant === InspireMeTopNavVariants.inspireMeZonePage;
};

export const getIsTaxonomyVariant = (state: Store): boolean => {
  const variant = getVariant(state);
  return variant === InspireMeTopNavVariants.inspireMeTaxonomy;
};
