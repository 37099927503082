export default function stringify(node) {
  let out = [];

  if (typeof node !== 'object' || node === null) {
    return JSON.stringify(node);
  }

  if (Array.isArray(node)) {
    out = node.map(item => stringify(item) || JSON.stringify(null));

    return `[${out.join(',')}]`;
  } else {
    const keys = Object.keys(node).sort();

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const value = stringify(node[key]);

      if (!value) continue;

      out.push(`${JSON.stringify(key)}:${value}`);
    }

    return `{${out.join(',')}}`;
  }
}
