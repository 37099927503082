import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import helpers from '#/lib/decorators/helpers';
import { getCurrentUrl } from '#/reducers/app';
import FilterOption from './filter-option';
import {
  replaceFilterUrl,
  addFilterUrl,
  removeFilterUrl,
  parseQueryString
} from '#/lib/filter-helpers';
import { removeFilterCategoryFromUrl } from '#/utils/product-filter-utils';
import page from 'page';
import { NUM_ROWS } from '#/constants/filter-options';
import { translateUnbrandedFacet } from '#/utils/product-filter-utils';
import {
  FILTER_OPTION_CHECKBOX,
  FILTER_OPTION_RADIO
} from '#/constants/filter-option';
import { connect } from '#/lib/render/connect-deep-compare';
import { getIsUpdating, getCurrentFacets } from '#/reducers/filter';
import { isOnFavouritesPage } from '#/lib/favorites-helpers';
import {
  PROMOTION,
  AVAILABLE,
  ALL_CATEGORIES,
  PRODUCT_SOURCE
} from '#/constants/facet-categories';
import { getFavFilterNavigationVariant } from '#/experiments/oop-1662/selectors';
import { getIsFavFilterNavigationDisabled } from '#/experiments/oop-1662/helpers';

const FEWER_OF_TYPE = 'fewer';
const MORE_OF_TYPE = 'more';

function mapStateToProps(state, ownProps) {
  const currentUrl = getCurrentUrl(state);

  const multiSelectionFiltersFromConfig = ownProps.c(
    'facetsAllowingMultipleSelections'
  );
  const isFavFilterNavigationDisabled = getIsFavFilterNavigationDisabled(
    getFavFilterNavigationVariant(state)
  );

  return {
    currentUrl,
    allowMultipleSelections: multiSelectionFiltersFromConfig.includes(
      ownProps.facetName
    ),
    isUpdating: getIsUpdating(state),
    currentFacets: getCurrentFacets(
      state,
      multiSelectionFiltersFromConfig,
      ownProps.c('categoryDelimiter')
    ),
    shouldRemoveFiltersFromUrl:
      isOnFavouritesPage(currentUrl) && isFavFilterNavigationDisabled
  };
}

@helpers(['currentPathname', 'c', 't'])
@connect(mapStateToProps)
export default class FacetOptions extends Component {
  static propTypes = {
    allowMultipleSelections: PropTypes.bool,
    c: PropTypes.func.isRequired,
    currentFacets: PropTypes.object.isRequired,
    currentPathname: PropTypes.func.isRequired,
    currentUrl: PropTypes.string.isRequired,
    descendantFacetNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    displayAllOption: PropTypes.bool,
    displayMode: PropTypes.oneOf([FILTER_OPTION_RADIO, FILTER_OPTION_CHECKBOX]),
    facetName: PropTypes.string.isRequired,
    isUpdating: PropTypes.bool.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        facetId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
          .isRequired,
        facetName: PropTypes.string.isRequired
      })
    ).isRequired,
    numColumns: PropTypes.number.isRequired,
    renderVeganMessage: PropTypes.func,
    shouldRemoveFiltersFromUrl: PropTypes.bool.isRequired,
    showLessLabel: PropTypes.string.isRequired,
    showMoreLabel: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired
  };

  static defaultProps = {
    allowMultipleSelections: false,
    displayAllOption: false,
    displayMode: FILTER_OPTION_CHECKBOX
  };

  constructor(props) {
    super(props);

    const { c: config, t: translate, currentPathname } = this.props;

    // TODO: required to be passed for legacy url-helpers, can be removed when url-helpers is refactored
    this.currentPathname = currentPathname.bind(this);
    this.c = config.bind(this);
    this.t = translate.bind(this);

    this.categoryDelimiter = config('categoryDelimiter');
    this.state = {
      expanded: false
    };
  }

  limit(array) {
    const { numColumns, displayAllOption } = this.props;

    if (this.state.expanded) {
      return array;
    }

    let count = NUM_ROWS * numColumns;

    if (displayAllOption) {
      count -= 1;
    }

    return array.slice(0, count);
  }

  shouldShowMore() {
    const {
      facetName,
      displayAllOption,
      currentFacets,
      items,
      numColumns
    } = this.props;

    if (
      facetName &&
      facetName !== 'brand' &&
      currentFacets[facetName] &&
      currentFacets[facetName].length
    ) {
      return false;
    }

    let itemCount = items.length;

    if (displayAllOption) {
      itemCount += 1;
    }

    return itemCount > NUM_ROWS * numColumns;
  }

  getFiltersToRemove = facetName => {
    const { shouldRemoveFiltersFromUrl } = this.props;
    if (!shouldRemoveFiltersFromUrl) {
      return [];
    }
    if (facetName === ALL_CATEGORIES) {
      return [PROMOTION, AVAILABLE];
    }
    return [PROMOTION];
  };

  onShowAllClicked = e => {
    const { facetName, currentUrl } = this.props;
    const filtersToRemove = this.getFiltersToRemove(ALL_CATEGORIES);

    const url = removeFilterCategoryFromUrl(
      facetName,
      currentUrl,
      this.currentPathname(),
      filtersToRemove
    );

    e.preventDefault();
    page(url);
  };

  renderShowAllOption() {
    const {
      currentUrl,
      currentFacets,
      isUpdating,
      facetName,
      displayMode,
      displayAllOption,
      t: translate,
      shouldRemoveFiltersFromUrl
    } = this.props;

    if (!displayAllOption) {
      return;
    }

    const qs = parseQueryString(currentUrl);
    const isPromotionFilterSelected = !!qs?.[PROMOTION.toLowerCase()];
    const filtersToRemove = this.getFiltersToRemove(ALL_CATEGORIES);

    const url = removeFilterCategoryFromUrl(
      facetName,
      currentUrl,
      this.currentPathname(),
      filtersToRemove
    );

    /* GIVEN shouldRemoveFiltersFromUrl is true
         WHEN special offers filter is selected
           THEN Show All option should be de-selected
        
        WHEN special offers filter is not selected
           THEN Show All option should be selected (by default)
    */
    const selected =
      !currentFacets[facetName] &&
      (!shouldRemoveFiltersFromUrl || !isPromotionFilterSelected);
    const classNames = classnames({
      'hiding-filter-option': isUpdating && currentFacets[facetName]
    });

    return (
      <FilterOption
        className={classNames}
        id="all"
        key="all"
        label={translate(`sort-and-filter:show-all-${facetName}`)}
        onChange={this.onShowAllClicked}
        selected={selected}
        url={url}
        mode={displayMode}
      />
    );
  }

  renderFewerButton() {
    return this.renderMoreButton(FEWER_OF_TYPE, this.state.expanded);
  }

  renderMoreButton(type = MORE_OF_TYPE, isVisible = !this.state.expanded) {
    const { showMoreLabel, showLessLabel } = this.props;
    const iconClasses = classnames('icon', 'icon-header_down_triangle', {
      'expand-icon': type === MORE_OF_TYPE,
      'contract-icon': type !== MORE_OF_TYPE
    });

    const listItemClasses = classnames('more-filters--container', {
      hidden: !isVisible
    });

    return (
      <li className={listItemClasses}>
        <button
          className="button-text more-filters"
          onClick={this.toggleExpanded}
        >
          {type === MORE_OF_TYPE ? showMoreLabel : showLessLabel}
          <span className={iconClasses} />
        </button>
      </li>
    );
  }

  toggleExpanded = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    const {
      allowMultipleSelections,
      currentFacets,
      currentUrl,
      descendantFacetNames,
      displayAllOption,
      displayMode,
      facetName,
      items,
      isUpdating,
      numColumns,
      renderVeganMessage,
      t: translate
    } = this.props;

    const availableFacets = this.limit(items, displayAllOption);
    const currentlySelectedFacetIds = (currentFacets[facetName] || []).map(x =>
      x.facetId.toString()
    );

    const isProductSourceSelected =
      facetName === PRODUCT_SOURCE &&
      availableFacets.some(({ facetId }) =>
        currentlySelectedFacetIds.includes(facetId)
      );

    const availableFilters = availableFacets.map(facet => {
      let url;
      const classNames = classnames({
        'hiding-filter-option': isUpdating
      });
      const facetsAllowingMultipleSelections = allowMultipleSelections
        ? [facetName]
        : [];

      // TODO: Change getCurrentFilterDetails to correctly set hasSelected
      let selected = currentlySelectedFacetIds.includes(
        facet.facetId.toString()
      );
      const label = translateUnbrandedFacet(facet.facetName, translate);

      if (displayMode === FILTER_OPTION_RADIO) {
        const filtersToRemove = this.getFiltersToRemove(facetName);

        url = replaceFilterUrl({
          category: facetName,
          currentFilters: currentFacets,
          currentPathname: this.currentPathname(),
          currentUrl: currentUrl,
          facet,
          filtersToRemove
        });
      } else if (selected) {
        url = removeFilterUrl({
          category: facetName,
          categoryDelimiter: this.categoryDelimiter,
          currentPathname: this.currentPathname(),
          currentUrl: currentUrl,
          facet,
          filterOrder: [facetName, ...descendantFacetNames],
          multiSelectionFacets: facetsAllowingMultipleSelections
        });
      } else {
        url = addFilterUrl({
          category: facetName,
          categoryDelimiter: this.categoryDelimiter,
          currentFilters: currentFacets,
          currentPathname: this.currentPathname(),
          currentUrl: currentUrl,
          facet,
          multiSelectionFacets: facetsAllowingMultipleSelections
        });
      }

      if (
        facetName === PRODUCT_SOURCE &&
        !isProductSourceSelected &&
        !selected &&
        facet.binCount > 0
      ) {
        selected = facet.isSelected;
      }

      return (
        <FilterOption
          className={classNames}
          count={facet.binCount}
          id={facet.facetId}
          key={facet.facetId}
          label={label}
          onChange={e => {
            e.preventDefault();
            page(url);
            renderVeganMessage;
          }}
          selected={selected}
          url={url}
          mode={displayMode}
          showFullText={facetName === PRODUCT_SOURCE}
        />
      );
    });

    const shouldShowMore = this.shouldShowMore();

    if (shouldShowMore) {
      availableFilters.splice(NUM_ROWS * numColumns, 0);
    }

    return (
      <ul>
        {this.renderShowAllOption()}
        {availableFilters}
        {shouldShowMore && this.renderMoreButton()}
        {shouldShowMore && this.renderFewerButton()}
      </ul>
    );
  }
}
