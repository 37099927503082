export const MAX_MENU_ITEMS_VISIBLE = {
  BELOW_DESKTOP: 5,
};

export const VISIBLE_MENU_ITEMS = {
  belowDesktop: MAX_MENU_ITEMS_VISIBLE.BELOW_DESKTOP,
};

export const PRIMARY_NAVIGATION = 'primaryNav:desktop';

export const PRIMARY_NAV_ALL_DEPT_MENU_ID = 'primary-nav-all-departments';
