import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from '#/lib/render/connect-deep-compare';
import Modal from '#/components/shared/modal';
import Price from '#/components/shared/price';
import SafeForm from '#/components/shared/safe-form';
import SmartSubmitButton from '#/components/shared/smart-submit-button';
import { SectionMessage } from '#/components/cms-driven/section-message';
import { removeModalFromUrl } from '#/lib/url/modal-utils';
import ModalActions from '#/components/shared/modal/actions';
import ModalContent from '#/components/shared/modal/content';
import ModalButtonContainer from '#/components/shared/modal/actions/button-container';
import { openModal, closeModal } from '#/actions/ui-action-creators';
import { BAG_OPTIONS_MODAL, ECOUPON_MODAL } from '#/constants/modal-names';
import ModalLink from '#/components/modals/modal-link';
import helpers from '#/lib/decorators/helpers';
import {
  getLastSelectedSlot,
  getAmendOrderNo,
  getCharges
} from '#/selectors/trolley';
import { getCurrentUrl } from '#/reducers/app';
import Link from '#/components/link-check-spa';

const mapStateToProps = (state, { c: config }) => {
  const charges = getCharges(state);
  return {
    surcharge: charges.surcharge,
    currentUrl: getCurrentUrl(state),
    displayAsGuidePrice: config('displayAsGuidePrice'),
    slotCharge: getLastSelectedSlot(state).charge,
    showBagOptionsLink: config('bagMessageAndLinkEnabled'),
    amendOrderNo: getAmendOrderNo(state)
  };
};

@helpers(['c', 'f', 'l', 't'])
@connect(mapStateToProps, {
  openModal,
  closeModal
})
class SaveModal extends Component {
  constructor(props, context) {
    super(props, context);
    const { c: config } = this.props;
    this.currency = config('currency');
    this.state = {
      submittingForm: false
    };
  }

  static propTypes = {
    amendOrderNo: PropTypes.string,
    bagCharge: PropTypes.number,
    c: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    couponDiscount: PropTypes.number,
    currentUrl: PropTypes.string.isRequired,
    displayAsGuidePrice: PropTypes.bool.isRequired,
    f: PropTypes.func.isRequired,
    guidePrice: PropTypes.number,
    isBagless: PropTypes.bool.isRequired,
    l: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    shoppingMethod: PropTypes.string.isRequired,
    slotCharge: PropTypes.number,
    surcharge: PropTypes.number,
    t: PropTypes.func.isRequired,
    totalPrice: PropTypes.number.isRequired
  };

  displayCouponDiscount = function(couponDiscount) {
    return couponDiscount > 0;
  };

  onSubmitForm = () => {
    this.setState({
      submittingForm: true
    });
  };

  getServiceChargeText() {
    const { f: feature, t: translate, shoppingMethod } = this.props;
    if (feature('useUpdatedServiceChargeLabels')) {
      return shoppingMethod === 'collection'
        ? translate('orders:common.pick-and-pack')
        : translate('orders:common.pick-pack-and-deliver');
    }

    return translate('orders:common.service-charge');
  }

  renderListItem(className, price, text) {
    return (
      <li className={className}>
        <span>{`${text} : `}</span>
        <Price value={price} {...this.currency} />
      </li>
    );
  }

  render() {
    const {
      amendOrderNo,
      bagCharge,
      couponDiscount,
      currentUrl,
      displayAsGuidePrice,
      t: translate,
      closeModal,
      openModal,
      guidePrice,
      isBagless,
      l,
      showBagOptionsLink,
      slotCharge = 0,
      surcharge,
      totalPrice
    } = this.props;

    const priceTitle = displayAsGuidePrice
      ? translate('common:guide-price')
      : translate('common:price');

    return (
      <Modal
        className="amend-modal"
        closeModal={closeModal}
        title={translate('orders:amend.save-changes')}
      >
        <ModalContent>
          <p>{translate('orders:amend.basket-may-have-changed')}</p>
          <ol className="price-breakdown">
            {this.renderListItem('guide-price', guidePrice, priceTitle)}
            {!!surcharge &&
              this.renderListItem(
                'minimum-basket-charge',
                surcharge,
                translate('checkout:order-summary.minimum-basket-charge')
              )}
            {this.renderListItem(
              'service-charge',
              slotCharge,
              this.getServiceChargeText()
            )}
            {this.displayCouponDiscount(couponDiscount) &&
              this.renderListItem(
                'coupon-discount',
                couponDiscount,
                translate('orders:common.ecoupon-discount')
              )}
            {!isBagless &&
              bagCharge > 0 &&
              this.renderListItem(
                'bag-charge',
                bagCharge,
                translate('checkout:bag-charge')
              )}
            {this.renderListItem(
              'total',
              totalPrice,
              translate('orders:amend.new-total')
            )}
          </ol>
          <ModalActions dual>
            <ModalButtonContainer>
              <Link
                className="js-confirm-changes-modal-no button button-secondary small"
                onClick={e => {
                  e.preventDefault();
                  closeModal();
                }}
                to={removeModalFromUrl(currentUrl)}
              >
                {translate('no')}
              </Link>
            </ModalButtonContainer>
            <ModalButtonContainer>
              <SafeForm
                action={l('/orders/amend?_method=PUT')}
                method="POST"
                onSubmit={this.onSubmitForm}
              >
                <input type="hidden" name="loggedInAction" value="save-amend" />
                <input type="hidden" name="returnUrl" value={currentUrl} />
                <input type="hidden" name="orderNo" value={amendOrderNo} />
                <SmartSubmitButton
                  buttonStyles="js-confirm-changes-modal-confirm button button-primary small"
                  primaryText={translate('yes')}
                  submitting={this.state.submittingForm}
                />
              </SafeForm>
            </ModalButtonContainer>
          </ModalActions>
          <Divider>{translate('checkout:common.lower-or')}</Divider>
          <ModalLink
            closeModal={closeModal}
            modalName={ECOUPON_MODAL}
            openModal={openModal}
            wrapperClassNames="save-modal--coupon-link"
          >
            {translate('orders:amend.add-ecoupons-vouchers')}
            <span className="icon icon-chevron_right" />
          </ModalLink>
          {showBagOptionsLink && (
            <ModalLink
              closeModal={closeModal}
              modalName={BAG_OPTIONS_MODAL}
              openModal={openModal}
              wrapperClassNames="save-modal--bag-options-link"
            >
              {translate('context-cards:change-bag-option')}
              <span className="icon icon-chevron_right" />
            </ModalLink>
          )}
        </ModalContent>
      </Modal>
    );
  }
}

export const Divider = props => (
  <div className="save-modal--divider">
    <SectionMessage message={props.children} />
  </div>
);

Divider.propTypes = {
  children: PropTypes.node.isRequired
};

export default SaveModal;
