/* eslint react/prop-types:0  */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RenderPrice from '#/components/products/product-tile/tile-components/shared/render-price';
import RenderPricePerUnit from '#/components/products/product-tile/tile-components/shared/render-price-per-unit';
import RenderPromotions from '#/components/products/product-tile/tile-components/shared/render-promotions';
import RenderQuantityLabel from '#/components/products/product-tile/tile-components/shared/render-quantity-label';
import RenderUnavailableProductMessages from '#/components/products/product-tile/tile-components/shared/render-unavailable-message';
import ProductImage from '#/components/products/product-tile/tile-components/product-image';
import ProductTitle from '#/components/products/product-tile/tile-components/product-title';
import ProductTileActions from '#/components/products/product-tile/tile-components/product-tile-actions';
import WriteAReview from '#/components/ratings-reviews/write-a-review';
import classNames from 'classnames';
import {
  getProductDefaultImageUrl,
  isProductBrowsableOnPDP,
  isExcluded,
  getProductIsForSale,
  getProductStatus,
  getProductTitle,
  isInFavourites,
  hasSubstitution,
  getProductId,
  getProductDrsChargeAmount
} from '#/selectors/item';
import { getAmountWithCurrencyOrMinorCurrency } from '#/lib/string-formatting/currency-formatter';
import { VisuallyHidden } from '#/components/shared/styled';

export default class PendingOrderTile extends PureComponent {
  static propTypes = {
    href: PropTypes.string,
    isAmendBasket: PropTypes.bool,
    isAvailableEpwOverride: PropTypes.bool,
    item: PropTypes.object.isRequired,
    selectedUnit: PropTypes.string,
    sellableUnitPrice: PropTypes.number,
    showUnavailableMessage: PropTypes.bool.isRequired,
    userRegistered: PropTypes.bool.isRequired
  };

  render() {
    const {
      isAvailableEpwOverride,
      item,
      href,
      productBrowsableOnPDP,
      isAmendBasket,
      sellableUnitPrice,
      selectedUnit,
      t: translate,
      currency
    } = this.props;
    const itemIsUnavailable = isAvailableEpwOverride
      ? false
      : !getProductIsForSale(item);
    const title = getProductTitle(item);
    const isInFav = isInFavourites(item);
    const isExcludedProduct = isExcluded(item);
    const id = getProductId(item);
    const productDRSAmount = getProductDrsChargeAmount(item);

    return (
      <div className="horizontal-tile pending-order-tile" key="pending-order">
        <div
          className={classNames('tile-content', {
            'tile-content--has-product-tile-actions': isInFav
          })}
          id={`tile-${id}`}
          data-auto-id={id}
        >
          {isInFav && <ProductTileActions item={item} />}
          <ProductImage
            alt={title}
            hideFromScreenReader={true}
            href={href}
            image={getProductDefaultImageUrl(item)}
            isUnavailable={itemIsUnavailable}
            clickable={productBrowsableOnPDP}
          />
          <div className="product-details--wrapper">
            <div className="product-details--content">
              <ProductTitle
                id={id}
                title={title}
                isPDPBrowsable={isProductBrowsableOnPDP(item)}
              />
              <RenderPromotions
                isAmendBasket={isAmendBasket}
                item={item}
                largeViewPromotion={true}
              />
              <RenderPricePerUnit
                isAvailableEpwOverride={isExcludedProduct}
                item={item}
              />
            </div>
            <WriteAReview id={id} productTitle={title} />
            <RenderQuantityLabel item={item} />
            <RenderPrice
              isAvailableEpwOverride={isExcludedProduct}
              item={item}
              sellableUnitPrice={sellableUnitPrice}
              selectedUnit={selectedUnit}
            />

            {!!productDRSAmount && (
              <>
                <VisuallyHidden data-visually-hidden>{`+ ${
                  currency?.symbol
                } ${productDRSAmount} ${translate('deposit')}`}</VisuallyHidden>
                <div
                  aria-hidden={true}
                  className="drs deposit-price"
                  style={{ clear: 'both', float: 'right' }}
                  data-testid="deposit-charge"
                >{`+ ${getAmountWithCurrencyOrMinorCurrency(
                  productDRSAmount,
                  currency?.symbol,
                  currency?.isoCode
                )} ${translate('deposit')}`}</div>
              </>
            )}
          </div>
          {this.props.showUnavailableMessage && !isExcludedProduct && (
            <RenderUnavailableProductMessages
              cssClassName="product-info-message-section"
              hasSubstitution={hasSubstitution(item)}
              status={getProductStatus(item)}
              isAvailable={getProductIsForSale(item)}
              isAvailableEpwOverride={isExcludedProduct}
            />
          )}
        </div>
        <RenderPromotions isAmendBasket={isAmendBasket} item={item} />
      </div>
    );
  }
}
