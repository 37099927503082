const isOriginalImage = path => {
  const splitPath = path.split('/');
  const fileName = splitPath.pop();

  return /original/.test(fileName);
};

const convertFileName = (original, width) => {
  const splitPath = original.split('/');
  const fileName = splitPath.pop();
  const newFileName = /original/.test(fileName)
    ? fileName.replace('original', `image${width}x1000`)
    : fileName;

  splitPath.push(newFileName);

  return splitPath.join('/');
};

export { isOriginalImage, convertFileName };
