import url from 'url';
import {
  isFromPDP,
  isFromSearch,
  isFromBrowse,
  getBackToResultLink
} from '#/lib/breadcrumb-helpers';
import { removeQueryString, updateParamsInUrl } from './url-utils';

export function getUrlWithJourneyParam(
  path,
  requestedJourneyName,
  acceptableJourneyNames
) {
  acceptableJourneyNames = Array.isArray(acceptableJourneyNames)
    ? acceptableJourneyNames
    : [];

  if (acceptableJourneyNames.includes(requestedJourneyName)) {
    path = updateParamsInUrl(path, { journey: requestedJourneyName });
  }

  return path;
}

export const getBackToProductPageUrl = (backToUrl, productId) => {
  if (!backToUrl) return;

  return isFromPDP(backToUrl)
    ? backToUrl
    : getBackToResultLink(backToUrl, productId);
};

export const getBackToProductPageTitle = (
  backToUrl,
  title,
  breadcrumbTrails,
  shelfName,
  restOfShelUrl
) => {
  if (!backToUrl) return;

  if (isFromPDP(backToUrl)) {
    return title;
  } else if (isFromSearch(backToUrl)) {
    const queryParams = url.parse(backToUrl, true).query;
    return queryParams.query;
  } else if (isFromBrowse(backToUrl)) {
    return backToUrl === restOfShelUrl
      ? shelfName
      : getLabelForUrl(breadcrumbTrails, backToUrl);
  }
  return getLabelForUrl(breadcrumbTrails, backToUrl);
};

const getLabelForUrl = (breadcrumbTrails, url) => {
  const path = removeQueryString(url); //enusre we get only path from url as breadcrumbs contain only path
  const matchingBreadcrumb = breadcrumbTrails.find(
    trial =>
      trial.linkTo &&
      getMatchingUrl(trial.linkTo.toLowerCase(), path.toLowerCase())
  );
  return matchingBreadcrumb && matchingBreadcrumb.label;
};

const getMatchingUrl = (linkTo, url) => {
  /* this is to ensure that following serach conditions pick the correct link label
    eg 1: backToUrl: /groceries/en-GB/shop/bakery/bread-and-rolls/batons
    breadcrumbs might have an equivalent url: /groceries/en-GB/shop/bakery/bread-and-rolls/batons/all
    eg 2: backToUrl: /groceries/en-GB/shop/bakery/bread-and-rolls/batons/
    breadcrumbs might have an equivalent url: /groceries/en-GB/shop/bakery/bread-and-rolls/batons/all
  */
  if (linkTo.endsWith('/all') && !url.endsWith('/all')) {
    const urlToCompare = url.endsWith('/')
      ? url.concat('all')
      : url.concat('/all');
    return linkTo === urlToCompare;
  }
  return linkTo === url;
};
