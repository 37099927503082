import React from 'react';
import PropTypes from 'prop-types';
import helpers from '#/lib/decorators/helpers';
import { connect } from '#/lib/render/connect-deep-compare';
import { getLanguage } from '#/reducers/app';
import TextLink from '#/components/text-link';

const mapStateToProps = state => ({
  language: getLanguage(state)
});

const DeliveryTab = ({ c: config, t: translate, language }) => {
  const deliveryTabUrl =
    config('links:homeDeliveryTab') ||
    config(`links:bookDelivery:${language}`) ||
    config('links:bookDelivery');

  const displayDeliveryTabDescription = config('displayDeliveryTabDescription');

  const brand = translate('common:brand', {
    context: config('brand')
  });

  return (
    <div>
      {displayDeliveryTabDescription && (
        <div className="option-tabs__main-content">
          <p className="option-tabs__desc">
            {translate('slots:common.home-delivery-desc', { brand })}
          </p>
          <p>
            <TextLink
              url={deliveryTabUrl}
              textKey={'slots:common.home-delivery-more-link'}
            />
          </p>
        </div>
      )}
    </div>
  );
};

DeliveryTab.propTypes = {
  c: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(helpers(['c', 't'])(DeliveryTab));
