/**
 * Gets the basket type and returns a string value, to be sent to New Relic for monitoring purposes
 * @param isGroceriesBasketOnly
 * @param isMarketplaceBasketOnly
 * @param isMixedBasket
 * @returns {string}
 */

export const getBasketTypeToNewRelicValue = (
  isGroceriesBasketOnly: boolean,
  isMarketplaceBasketOnly: boolean,
  isMixedBasket: boolean,
): string | undefined => {
  if (isGroceriesBasketOnly) return 'ghs';
  if (isMarketplaceBasketOnly) return 'marketplace';
  if (isMixedBasket) return 'ghs, marketplace';
  return;
};
