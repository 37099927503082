import Price from '#/components/shared/price';
import Accordion from '@ddsweb/accordion';
import Icon from '@ddsweb/icon';
import {
  StyledAccordionItem,
  StyledAccordionPanel,
  StyledSubTotal,
  StyledDivider,
  StyledAccordionButton,
  StyledAccordionIcon,
} from './styles';
import React from 'react';

interface CustomAccordionItemProps {
  id: string;
  heading: string;
  subHeading: string;
  price: number;
  moreInfo: JSX.Element;
  minimumChargeMessage?: JSX.Element | undefined;
  freeDeliveryMessage?: JSX.Element | undefined;
  currencyConfig: Record<string, string | number | boolean>;
  isMixedBasket: boolean;
}

export const CustomAccordionItem: React.FC<CustomAccordionItemProps> = ({
  id,
  heading,
  subHeading,
  price,
  moreInfo,
  currencyConfig,
  minimumChargeMessage,
  freeDeliveryMessage,
  isMixedBasket,
}) => (
  <StyledAccordionItem id={`${id}-accordion`} key={`${id}-accordion`}>
    <Accordion.Header>
      <StyledAccordionButton>
        {heading}
        <StyledAccordionIcon>
          <Icon graphic="expand" size="xs" />
        </StyledAccordionIcon>
      </StyledAccordionButton>
      {isMixedBasket && (
        <StyledSubTotal as="p">
          {`${subHeading}: `}
          <Price value={price} compactMode={true} {...currencyConfig} />
        </StyledSubTotal>
      )}
      {minimumChargeMessage}
      {freeDeliveryMessage}
    </Accordion.Header>
    <StyledAccordionPanel>{moreInfo}</StyledAccordionPanel>
    <StyledDivider />
  </StyledAccordionItem>
);
