import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import VideoPlayer from '#/components/video-player';
import { CTATextWithChevron } from '#/components/cms-driven/cta-link-with-chevron';
import Link from '#/components/link';
import { FULL, HALF, THIRD } from '#/constants/dcs';

const TradeTileVideo = props => {
  const { componentClickHandler, video, link, size } = props;

  //Uses basic trade tile styling

  const stampClasses = classnames(
    'trade-tile',
    'trade-tile--basic',
    `trade-tile--size-${size}`,
    {
      'trade-tile--tablet-width-half trade-tile--desktop-width-half':
        size === HALF,
      'trade-tile--mobile-horizontal trade-tile--tablet-width-third trade-tile--desktop-width-third':
        size === THIRD
    }
  );

  //Sending TradeTileVideo props for analytics in onClick and interactionType(autoplay, play, pause, ...) comes from VideoPlayer

  const emitVideoEvent = interactionType =>
    componentClickHandler(props, interactionType);

  return (
    <div className={stampClasses}>
      <VideoPlayer
        src={video.src}
        poster={video.poster}
        autoPlay={video.autoPlay}
        muteAudio={video.muteAudio}
        emitVideoEvent={emitVideoEvent}
      />
      {link && (
        <Link
          className="tile__parent-link"
          to={link.href}
          target={link.newWindow ? '_blank' : null}
        >
          <div className="tile__caption-wrapper">
            <p className="tile__cta-link">
              <CTATextWithChevron
                textLabel={link.label}
                newWindow={link.newWindow}
              />
            </p>
          </div>
        </Link>
      )}
    </div>
  );
};

TradeTileVideo.propTypes = {
  componentClickHandler: PropTypes.func,
  link: PropTypes.shape({
    href: PropTypes.string,
    label: PropTypes.string,
    newWindow: PropTypes.bool
  }).isRequired,
  placement: PropTypes.number,
  size: PropTypes.oneOf([FULL, HALF]).isRequired,
  video: PropTypes.shape({
    src: PropTypes.string.isRequired,
    poster: PropTypes.string
  }).isRequired
};

TradeTileVideo.defaultProps = {
  componentClickHandler: null,
  placement: 1
};

export { TradeTileVideo };
