import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { connect } from '#/lib/render/connect-deep-compare';
import helpers from '#/lib/decorators/helpers';
import { getSortOptions } from '#/reducers/filter';
import { getCurrentQueryParams } from '#/reducers/app';
import { camelCaseToKebabCase } from '#/utils/text-utils';

const mapStateToProps = state => ({
  filterStoreSortOptions: getSortOptions(state),
  sortQueryParam: getCurrentQueryParams(state)?.sortBy
});

@helpers(['t'])
@connect(mapStateToProps)
export default class ItemsCount extends PureComponent {
  static propTypes = {
    count: PropTypes.number.isRequired,
    filterStoreSortOptions: PropTypes.array,
    sortQueryParam: PropTypes.string,
    t: PropTypes.func.isRequired
  };

  render() {
    const { sortQueryParam, filterStoreSortOptions, t, count } = this.props;

    let sort;
    // if no sort query param is set, sort will be the first sorting option available
    if (sortQueryParam) {
      // sortQueryParam is camelCase
      sort = camelCaseToKebabCase(sortQueryParam);
    } else {
      // sort options are kebab-case
      sort = filterStoreSortOptions[0];
    }

    return (
      <div role="status" className="items-count__container">
        <span className="items-count__filter-caption" data-auto="items-count">
          {t('product-list:total-items', {
            smart_count: count
          })}
        </span>
        {sort && (
          <span className="visually-hidden">
            {t('sort-and-filter:sorted-by', {
              sort: t(`sort-and-filter:sort-options.${sort}`)
            })}
          </span>
        )}
      </div>
    );
  }
}
