import React from 'react';

export const AldiPriceMatchLogo: React.FC = () => {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M52.1301 27.8766C52.1301 14.5534 41.3293 3.75308 28.0066 3.75308C14.6834 3.75308 3.88306 14.5534 3.88306 27.8766C3.88306 41.1998 14.6834 52.0002 28.0066 52.0002C41.3293 52.0002 52.1301 41.1998 52.1301 27.8766Z"
        fill="#EE1C2E"
      />
      <path
        d="M19.6853 36.5687L31.8169 48.6998L52.6633 27.8998L51.9894 23.0423L31.886 43.1452L22.5438 33.803L19.6853 36.5687Z"
        fill="white"
      />
      <path
        d="M24.2699 12.0048L23.5594 9.93467L22.8484 12.0048H24.2699ZM24.6345 13.0711H22.4844L21.9776 14.5375H20.7515L22.9815 8.31722H24.1369L26.3669 14.5375H25.1408L24.6345 13.0711Z"
        fill="white"
      />
      <path d="M27.8359 14.5375H26.734V8.24609H27.8359V14.5375Z" fill="white" />
      <path
        d="M31.5684 13.2309C31.7849 12.9886 31.8927 12.6597 31.8927 12.2446C31.8927 11.8306 31.7849 11.5016 31.5684 11.2588C31.3524 11.0159 31.0811 10.8942 30.7558 10.8942C30.4299 10.8942 30.1587 11.0159 29.9427 11.2588C29.7267 11.5016 29.6184 11.8306 29.6184 12.2446C29.6184 12.6597 29.7267 12.9886 29.9427 13.2309C30.1587 13.4738 30.4299 13.5955 30.7558 13.5955C31.0811 13.5955 31.3524 13.4738 31.5684 13.2309ZM29.4673 14.3065C29.159 14.1049 28.9177 13.8234 28.7429 13.4619C28.5681 13.101 28.481 12.6952 28.481 12.2446C28.481 11.7888 28.5655 11.3815 28.7341 11.0231C28.9032 10.6648 29.1384 10.3848 29.4405 10.1837C29.7426 9.98212 30.0891 9.88158 30.48 9.88158C30.7883 9.88158 31.0579 9.93624 31.2889 10.0455C31.5199 10.1554 31.6978 10.3106 31.8221 10.5122V8.24609H32.9239V14.5375H31.8221V13.9863C31.6916 14.1704 31.5137 14.3194 31.2889 14.4349C31.0636 14.5504 30.797 14.6086 30.4892 14.6086C30.1159 14.6086 29.7751 14.5076 29.4673 14.3065Z"
        fill="white"
      />
      <path
        d="M34.9657 14.5375H33.8638V9.95224H34.9657V14.5375ZM33.9082 8.34408C34.0443 8.20796 34.2129 8.1399 34.4145 8.1399C34.6161 8.1399 34.7847 8.20796 34.9208 8.34408C35.0574 8.48071 35.1255 8.64931 35.1255 8.8504C35.1255 9.052 35.0574 9.22112 34.9208 9.35724C34.7847 9.49336 34.6161 9.56142 34.4145 9.56142C34.2129 9.56142 34.0443 9.49336 33.9082 9.35724C33.7715 9.22112 33.704 9.052 33.704 8.8504C33.704 8.64931 33.7715 8.48071 33.9082 8.34408Z"
        fill="white"
      />
      <path
        d="M19.6791 19.3347C19.8894 19.1506 19.9946 18.8779 19.9946 18.5169C19.9946 18.1565 19.8894 17.8838 19.6791 17.6997C19.4682 17.5156 19.183 17.4233 18.8221 17.4233H17.5027V19.6111H18.8221C19.183 19.6111 19.4682 19.5188 19.6791 19.3347ZM17.5027 23.9628H16.0374V16.0704H18.9345C19.461 16.0704 19.9157 16.1776 20.2988 16.3921C20.6824 16.6061 20.9753 16.8995 21.1784 17.2712C21.3816 17.6435 21.4826 18.0586 21.4826 18.5169C21.4826 18.9758 21.3816 19.3909 21.1784 19.7632C20.9753 20.1354 20.6824 20.4283 20.2988 20.6423C19.9157 20.8568 19.461 20.9635 18.9345 20.9635H17.5027V23.9628Z"
        fill="white"
      />
      <path
        d="M22.0396 18.1451H23.4379V19.0248C23.6029 18.7087 23.8359 18.4684 24.1365 18.3029C24.4376 18.1374 24.7717 18.0549 25.1404 18.0549C25.5235 18.0549 25.8241 18.1415 26.0422 18.3143L25.5689 19.4981C25.3729 19.3929 25.1662 19.3403 24.9486 19.3403C24.5124 19.3403 24.152 19.528 23.8663 19.9039C23.5802 20.2797 23.4379 20.8582 23.4379 21.6404V23.9627H22.0396V18.1451Z"
        fill="white"
      />
      <path
        d="M27.8879 23.9627H26.4896V18.1451H27.8879V23.9627ZM26.5458 16.1044C26.7191 15.9316 26.933 15.845 27.1888 15.845C27.444 15.845 27.658 15.9316 27.8312 16.1044C28.0039 16.2771 28.0906 16.4916 28.0906 16.7468C28.0906 17.0026 28.0039 17.217 27.8312 17.3898C27.658 17.5625 27.444 17.6491 27.1888 17.6491C26.933 17.6491 26.7191 17.5625 26.5458 17.3898C26.3731 17.217 26.287 17.0026 26.287 16.7468C26.287 16.4916 26.3731 16.2771 26.5458 16.1044Z"
        fill="white"
      />
      <path
        d="M30.0603 23.6693C29.6168 23.4141 29.2714 23.0588 29.0234 22.6041C28.7754 22.1493 28.6511 21.6327 28.6511 21.0537C28.6511 20.4752 28.7754 19.9585 29.0234 19.5038C29.2714 19.049 29.6168 18.6937 30.0603 18.438C30.5037 18.1828 31.011 18.0549 31.5823 18.0549C32.8749 18.0549 33.751 18.585 34.2093 19.6445L32.9353 20.2199C32.6424 19.6332 32.1913 19.3403 31.5823 19.3403C31.1466 19.3403 30.7857 19.4981 30.5001 19.8136C30.2144 20.1297 30.0716 20.5427 30.0716 21.0537C30.0716 21.5651 30.2144 21.9787 30.5001 22.2942C30.7857 22.6097 31.1466 22.7675 31.5823 22.7675C32.1913 22.7675 32.6424 22.4747 32.9353 21.8884L34.2093 22.4633C33.751 23.5229 32.8749 24.0529 31.5823 24.0529C31.011 24.0529 30.5037 23.9251 30.0603 23.6693Z"
        fill="white"
      />
      <path
        d="M38.727 20.3999C38.6744 20.0694 38.5223 19.7987 38.2707 19.5883C38.0186 19.3779 37.7015 19.2728 37.3179 19.2728C36.9946 19.2728 36.7048 19.3723 36.4496 19.5713C36.1939 19.7708 36.0175 20.0467 35.9196 20.3999H38.727ZM40.1588 21.5048H35.8633C35.9381 21.9333 36.115 22.2586 36.3934 22.4803C36.6713 22.702 37.0095 22.8129 37.4081 22.8129C37.7386 22.8129 38.015 22.7433 38.2367 22.6041C38.4584 22.4649 38.6708 22.253 38.874 21.9673L40.0573 22.6097C39.7716 23.0759 39.4092 23.4327 38.9693 23.6812C38.5295 23.9287 37.983 24.0529 37.3292 24.0529C36.7125 24.0529 36.1846 23.9086 35.7453 23.6188C35.3055 23.3295 34.9765 22.9557 34.7584 22.4968C34.5403 22.0385 34.4315 21.5574 34.4315 21.0537C34.4315 20.5504 34.546 20.0694 34.7754 19.611C35.0044 19.1526 35.3374 18.7783 35.7731 18.4891C36.2088 18.1998 36.7239 18.0549 37.3179 18.0549C37.9191 18.0549 38.4341 18.19 38.8626 18.4607C39.2911 18.7314 39.6139 19.0866 39.832 19.5264C40.0501 19.9657 40.1588 20.4339 40.1588 20.9299V21.5048Z"
        fill="white"
      />
      <path
        d="M16.3879 25.558H17.7399L19.2454 29.4977L20.751 25.558H22.1029V31.8684H20.9309V28.0282L19.6873 31.2915H18.8035L17.5599 28.0282V31.8684H16.3879V25.558Z"
        fill="white"
      />
      <path
        d="M25.4058 30.7146C25.6131 30.5341 25.7167 30.3119 25.7167 30.0474V29.9216H24.8335C24.5746 29.9216 24.3689 29.971 24.2158 30.07C24.0626 30.169 23.9858 30.3057 23.9858 30.48C23.9858 30.6424 24.0415 30.7671 24.1529 30.8543C24.2637 30.9414 24.4277 30.9847 24.6437 30.9847C24.9443 30.9847 25.1985 30.8945 25.4058 30.7146ZM23.6074 31.7782C23.3671 31.67 23.1748 31.5122 23.0304 31.3049C22.886 31.0976 22.8138 30.8496 22.8138 30.5609C22.8138 30.1046 22.9855 29.7364 23.3279 29.457C23.6703 29.1775 24.1333 29.0378 24.7159 29.0378H25.7167V28.8754C25.7167 28.6413 25.6357 28.4531 25.4733 28.3118C25.3109 28.1711 25.0918 28.1004 24.8154 28.1004C24.5927 28.1004 24.3916 28.1592 24.2111 28.2763C24.0312 28.3933 23.8956 28.5299 23.8059 28.6862L22.9494 28.1726C23.1113 27.8782 23.3516 27.6333 23.6703 27.4379C23.9889 27.2425 24.3705 27.145 24.8154 27.145C25.4522 27.145 25.9482 27.2982 26.3024 27.6044C26.6572 27.9112 26.8345 28.3526 26.8345 28.9295V31.8685H25.9152L25.7621 31.2735C25.6115 31.4776 25.4207 31.6401 25.1892 31.7602C24.9582 31.8803 24.686 31.9401 24.3735 31.9401C24.1029 31.9401 23.8476 31.8865 23.6074 31.7782Z"
        fill="white"
      />
      <path
        d="M27.8214 30.3V28.1726H27.1182V27.2167H27.8214V26.4417L28.9393 25.7384V27.2167H30.1381V28.1726H28.9393V30.2458C28.9393 30.6903 29.1166 30.9125 29.4709 30.9125C29.6333 30.9125 29.7895 30.8496 29.9395 30.7233L30.4443 31.5797C30.2881 31.706 30.1169 31.7978 29.9308 31.855C29.7446 31.9117 29.5549 31.9401 29.3626 31.9401C28.3355 31.9401 27.8214 31.3936 27.8214 30.3Z"
        fill="white"
      />
      <path
        d="M31.5487 31.6339C31.194 31.4297 30.9176 31.1456 30.7196 30.7821C30.5211 30.4186 30.4221 30.0056 30.4221 29.5426C30.4221 29.0801 30.5211 28.6666 30.7196 28.3031C30.9176 27.9396 31.194 27.6555 31.5487 27.4513C31.9034 27.2471 32.3087 27.145 32.7655 27.145C33.7993 27.145 34.4995 27.5683 34.8661 28.416L33.8473 28.8754C33.6132 28.4067 33.2528 28.1726 32.7655 28.1726C32.417 28.1726 32.1288 28.2989 31.9004 28.5511C31.6719 28.8037 31.558 29.1342 31.558 29.5426C31.558 29.9509 31.6719 30.282 31.9004 30.5341C32.1288 30.7867 32.417 30.9125 32.7655 30.9125C33.2528 30.9125 33.6132 30.6785 33.8473 30.2098L34.8661 30.6697C34.4995 31.5168 33.7993 31.9401 32.7655 31.9401C32.3087 31.9401 31.9034 31.8386 31.5487 31.6339Z"
        fill="white"
      />
      <path
        d="M35.3998 25.4863H36.5176V27.9473C36.662 27.707 36.8615 27.5132 37.1167 27.3657C37.3725 27.2188 37.6592 27.145 37.9778 27.145C38.4702 27.145 38.8579 27.2909 39.1405 27.5823C39.423 27.8736 39.5643 28.2804 39.5643 28.8037V31.8685H38.4465V29.0285C38.4465 28.7522 38.3758 28.5408 38.2346 28.3933C38.0933 28.2458 37.8907 28.1726 37.6262 28.1726C37.3199 28.1726 37.0585 28.3093 36.8419 28.5825C36.6254 28.8558 36.5176 29.275 36.5176 29.8401V31.8685H35.3998V25.4863Z"
        fill="white"
      />
    </svg>
  );
};
