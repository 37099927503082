import styled, { css } from 'styled-components';
import { BodyText } from '@ddsweb/typography';
import { colors, spacing } from '@ddsweb/selectors';
import { media } from '@ddsweb/foundation';

export const TooltipStyleDesktop = css`
  top: calc(100% + 16px);
  right: 4px;
  display: block;
  background-color: ${colors.backgroundInfoMessaging};
  & .ddsweb-popover__pointer {
    right: ${spacing.xl};
    top: -12px;
    rotate: 180deg;
  }
  & > svg {
    fill: ${colors.backgroundInfoMessaging};
    path {
      fill: ${colors.backgroundInfoMessaging};
    }
  }
  & .ddsweb-popover__close-button {
    margin: 0;
    svg {
      fill: ${colors.white};
      path {
        fill: ${colors.white};
      }
    }
    &:after {
      box-shadow: 0 0 0 ${spacing.xxx} ${colors.white};
    }
  }

  ${media.belowTablet`
    top: 92px;
    & .ddsweb-popover__pointer {
      right: 92px;
    }
  `}
  ${media.belowMobileLarge`
    right: calc((100vw - 352px)/2);
  `}
`;

export const StyledBodyText = styled(BodyText)`
  color: ${colors.white};
`;

export const StyledLIProductItem = styled('li')`
  &.with-subs {
    .product-image__container {
      opacity: 0.5;
    }
  }
`;

export const StyledLI = styled('li')`
  .sash-list-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
`;
