import React, { ReactElement, SyntheticEvent } from 'react';
import { connect } from 'react-redux';
import helpers from '#/lib/decorators/helpers';
import ListItemContent from '#/components/left-hand-navigation/list-item-content';
import { closeLeftNav, toggleNavMenu, showHeaderSearch } from '#/actions/ui-action-creators';
import { TTranslateFunc } from '#/lib/records/helpers.defs';
import { FAV_MENU_KEY } from '#/experiments/oop-1716/constants';

type Props = {
  closeLeftNav: () => void;
  toggleNavMenu: (menuKey: string) => void;
  showHeaderSearch: () => void;
  url: string;
  t: TTranslateFunc;
};

const FavListItemContent = (props: Props): ReactElement => {
  const { closeLeftNav, toggleNavMenu, showHeaderSearch, url, t: translate } = props;

  const listItemClick = (e: SyntheticEvent): void => {
    e.preventDefault();
    e.stopPropagation();
    closeLeftNav();
    showHeaderSearch();
    toggleNavMenu(FAV_MENU_KEY);
  };

  return (
    <ListItemContent
      iconLeft="favorite-heart-icon"
      link={url}
      onClick={listItemClick}
      text={translate('product-list:favorites')}
    />
  );
};

export default connect(null, {
  closeLeftNav,
  showHeaderSearch,
  toggleNavMenu,
})(helpers(['t'])(FavListItemContent));
