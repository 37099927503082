import React from 'react';
import { basicEvent } from '#/analytics/types/basic';
import analyticsBus from '#/analytics/analyticsBus';
import { connect } from '#/lib/render/connect-deep-compare';
import { getIsOnDemandDelivery } from '#/selectors/trolley';
import SafelyOutputString from '#/components/safely-output-string';
import TileLinkButton from '#/components/product-tiles/common/rest-of-shelf-link/tile-link';

type TRestOfShelfLink = {
  href: string;
  linkText: string;
  tileType: string;
  onClick?: () => any;
  showRestOfShelf: boolean;
};

const mapStateToProps = state => ({
  showRestOfShelf: !getIsOnDemandDelivery(state),
});

const RestOfShelfLink = ({ href, linkText, tileType, onClick, showRestOfShelf }: TRestOfShelfLink): JSX.Element => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }

    basicEvent(analyticsBus, {
      type: 'rest of shelf',
      value: tileType,
      action: 'delay',
    });
  };

  return (
    <>
      {!!showRestOfShelf && (
        <div>
          <TileLinkButton
            className="rest-of-shelf-link"
            dataAuto="rest-of-shelf-link"
            href={href}
            onClick={handleClick}
          >
            <SafelyOutputString>{linkText}</SafelyOutputString>
          </TileLinkButton>
        </div>
      )}
    </>
  );
};

export default connect(mapStateToProps)(RestOfShelfLink);
