import { storageKey } from '#/experiments/oop-1263/constants';
import { localStore } from '#/lib/data-store/client-store';
import { CLOSED } from '#/components/shared/tooltip/available-filter';

export const getStorageKey = (uuid: string, key: string = storageKey): string => `${key}${uuid}`;

export const getTooltipStorage = (key: string): string => localStore?.get(key);

export const setTooltipStorage = (key: string, value: string = CLOSED): void => {
  if (!getTooltipStorage(key)) {
    localStore?.set(key, value);
  }
};
