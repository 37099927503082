import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { TileImageContainer } from '#/components/cms-driven/tile-image-container';
import { CTATextWithChevron } from '#/components/cms-driven/cta-link-with-chevron';
import Link from '#/components/link';
import {
  FULL,
  HALF,
  THIRD,
  QUARTER,
  TWO_THIRDS,
  TWO_QUARTERS,
  LEFT,
  CENTER,
  RIGHT
} from '#/constants/dcs';

const TradeTileBasic = props => {
  const {
    componentClickHandler,
    image,
    link,
    mobileStyle,
    ping,
    placement,
    size
  } = props;

  const stampClasses = classnames(
    'trade-tile',
    'trade-tile--basic',
    `trade-tile--size-${size}`,
    {
      'trade-tile--tablet-width-half trade-tile--desktop-width-half':
        size === HALF,
      'trade-tile--mobile-width-half': size === HALF && mobileStyle === HALF,
      'trade-tile--mobile-horizontal trade-tile--tablet-width-third trade-tile--desktop-width-third':
        size === THIRD,
      'trade-tile--mobile-width-half trade-tile--tablet-width-half trade-tile--desktop-width-quarter':
        size === QUARTER,
      'trade-tile--mobile-two-thirds trade-tile--tablet-width-two-thirds trade-tile--desktop-width-two-thirds':
        size === TWO_THIRDS,
      'trade-tile--mobile-two-quarters trade-tile--desktop-width-half':
        size === TWO_QUARTERS
    }
  );

  const onClick = () => componentClickHandler?.(props);
  const OuterTag = link?.href ? Link : 'div';

  return (
    <div className={stampClasses} onClick={onClick}>
      <OuterTag
        className="tile__parent-link"
        to={link?.href}
        target={link?.newWindow ? '_blank' : null}
      >
        <TileImageContainer image={image} ping={ping} placement={placement} />
        {link?.href && (
          <div className="tile__caption-wrapper">
            <p className="tile__cta-link">
              <CTATextWithChevron
                textLabel={link.label}
                newWindow={link.newWindow}
              />
            </p>
          </div>
        )}
      </OuterTag>
    </div>
  );
};

TradeTileBasic.propTypes = {
  componentClickHandler: PropTypes.func,
  image: PropTypes.shape({
    breakpoints: PropTypes.array,
    clipping: PropTypes.oneOf([LEFT, CENTER, RIGHT]),
    maxWidth: PropTypes.number,
    src: PropTypes.string
  }).isRequired,
  link: PropTypes.shape({
    href: PropTypes.string,
    label: PropTypes.string,
    newWindow: PropTypes.bool
  }).isRequired,
  mobileStyle: PropTypes.oneOf([FULL, HALF]),
  ping: PropTypes.shape({
    copy: PropTypes.string,
    src: PropTypes.string
  }),
  placement: PropTypes.number,
  size: PropTypes.oneOf([FULL, HALF, THIRD, TWO_THIRDS, QUARTER, TWO_QUARTERS])
    .isRequired
};

TradeTileBasic.defaultProps = {
  componentClickHandler: null,
  mobileStyle: FULL,
  ping: null,
  placement: 1
};

export { TradeTileBasic };
