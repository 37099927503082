import { addApmData } from '#/lib/apm';
import logFontInitiator from '#/lib/apm/log-font-initiator';
import {
  getAppRegion,
  getBasePath,
  getBuildVersion,
  getConsumer,
  getExternalSecureDomain,
  getMfeRolloutDebug,
  getTraceId
} from '#/reducers/app';
import { getServerTime } from '#/reducers/resources';
import {
  getAtrc,
  getIsUserAuthenticated,
  getUserHashedEmail,
  getUserHashedUId
} from '#/reducers/user';
import PropTypes from 'prop-types';
import { Component } from 'react';
import setBrowserTransactionName from '../../lib/apm/set-browser-transaction-name';
import { connect } from '../../lib/render/connect-deep-compare';

const mapStateToProps = state => ({
  atrc: getAtrc(state),
  basePath: getBasePath(state),
  buildVersion: getBuildVersion(state),
  consumer: getConsumer(state),
  customerUuid: getUserHashedUId(state),
  fontDomain: getExternalSecureDomain(state),
  hashedEmail: getUserHashedEmail(state),
  isUserAuthenticated: getIsUserAuthenticated(state),
  region: getAppRegion(state),
  serverTime: getServerTime(state),
  traceId: getTraceId(state),
  mfeRolloutDebug: getMfeRolloutDebug(state)
});

@connect(mapStateToProps)
export default class ApmPageAttributes extends Component {
  static propTypes = {
    atrc: PropTypes.string.isRequired,
    basePath: PropTypes.string.isRequired,
    buildVersion: PropTypes.string.isRequired,
    consumer: PropTypes.string.isRequired,
    customerUuid: PropTypes.string.isRequired,
    fontDomain: PropTypes.string.isRequired,
    hashedEmail: PropTypes.string,
    isErrorPage: PropTypes.bool,
    isUserAuthenticated: PropTypes.bool.isRequired,
    region: PropTypes.string.isRequired,
    serverTime: PropTypes.string.isRequired,
    traceId: PropTypes.string.isRequired
  };

  static defaultProps = {
    isErrorPage: false
  };

  getTimeDifferenceBetweenServerAndClient() {
    const now = new Date();
    const serverTime = new Date(this.props.serverTime);

    const diff = (now - serverTime) / 1000;
    return diff;
  }

  componentDidMount() {
    setBrowserTransactionName(
      this.props.isErrorPage ? `/${this.props.basePath}/error` : location.href
    );
    addApmData('ci_version', this.props.buildVersion);
    addApmData('trace_id', this.props.traceId);
    addApmData('atrc', this.props.atrc);
    addApmData('authenticated', this.props.isUserAuthenticated);
    addApmData('region', this.props.region);
    addApmData('referrer', document.referrer);
    addApmData('path', location.pathname.split(/[a-z]{2}-[A-Z]{2}/)[1] || '/');
    addApmData('user_agent', navigator.userAgent);
    addApmData('consumer', this.props.consumer);
    addApmData('uuid', this.props.customerUuid);
    addApmData('user_time', new Date().toString());
    addApmData(
      'screen_wh',
      `${window.screen.availWidth}x${window.screen.availHeight}`
    );
    addApmData(
      'user_time_skew',
      this.getTimeDifferenceBetweenServerAndClient()
    );
    addApmData('mfe-rollout-debug', this.props.mfeRolloutDebug);

    if (this.props.hashedEmail) {
      addApmData('hashed_email', this.props.hashedEmail);
    }

    if (navigator.connection) {
      addApmData('connection_type', navigator.connection.type); // Network type that browser uses
      addApmData('connection_downlink', navigator.connection.downlink); // Effective bandwidth estimate (units are Mb/s)
      addApmData('connection_rtt', navigator.connection.rtt); // Effective round-trip time estimate (units are ms)
      addApmData('connection_downlinkMax', navigator.connection.downlinkMax); // Upper bound on the downlink (units are Mb/s)
      addApmData(
        'connection_effectiveType',
        navigator.connection.effectiveType
      ); // Effective connection type
    }

    // for initial early hints measurements, tracks what initiated the font load, e.g. early hints, link, css
    logFontInitiator(this.props.fontDomain);
  }

  render() {
    return null;
  }
}
