import { getProductIsForSale } from '#/selectors/item';

import { getCurrentUrl } from '#/reducers/app';
import { TPriceData, getPriceData } from '#/selectors/beans-product-tile/price-data';
import {
  getRestOfShelfBuyboxData,
  TRestOfShelfBuyboxData,
} from '#/selectors/beans-product-tile/rest-of-shelf-buybox-data';
import { getQuantityControlData, TQuantityControlsData } from '#/selectors/beans-product-tile/quantity-controls-data';
import { Item } from '#/lib/records/item';
import { isAvailableEpwOverride } from '#/selectors/beans-product-tile/product-availability-data';

export const VIEW_AND_BUY_BUYBOX = 'view-and-buy';
export const QUANTITY_CONTROLS_BUYBOX = 'quantity-controls';
export const REST_OF_SHELF_BUYBOX = 'rest-of-shelf';
export const UNAVAILABLE_WITH_PRICE_BUYBOX = 'unavailable-with-price';
export const UNAVAILABLE_WITH_PRICE_REST_OF_SHELF_BUYBOX = 'unavailable-with-price-rest-of-shelf';
export const NO_BUYBOX = 'no-buybox';

export type TBuyboxViewAndBuyData = TPriceData & {
  buyboxType: typeof VIEW_AND_BUY_BUYBOX;
  href: string;
  text: string;
  onActionLinkClick?: React.MouseEventHandler<HTMLSpanElement>;
};

export type TBuyboxQuantityControlData = TQuantityControlsData &
  TPriceData & {
    buyboxType: typeof QUANTITY_CONTROLS_BUYBOX;
  };

type BuyboxActionRenderProp = { renderBuyboxActions?: (props?: { classes: string }) => JSX.Element };

export type TBuyboxRestOfShelfData = TRestOfShelfBuyboxData & {
  buyboxType: typeof REST_OF_SHELF_BUYBOX;
} & BuyboxActionRenderProp;

export type TBuyboxUnavailableWithPriceRestOfShelfData = TPriceData &
  TRestOfShelfBuyboxData & {
    buyboxType: typeof UNAVAILABLE_WITH_PRICE_REST_OF_SHELF_BUYBOX;
  };

export type TBuyboxUnavailableWithPriceData = TPriceData & {
  buyboxType: typeof UNAVAILABLE_WITH_PRICE_BUYBOX;
} & BuyboxActionRenderProp;

export type TBuyboxData =
  | TBuyboxViewAndBuyData
  | TBuyboxQuantityControlData
  | TBuyboxUnavailableWithPriceData
  | TBuyboxUnavailableWithPriceRestOfShelfData
  | TBuyboxRestOfShelfData
  | { buyboxType: typeof NO_BUYBOX };

export const getBuyBoxData = (
  state: any,
  item: Item, // will eventually be derived from storestate
  translation: (...args: any[]) => string,
  config: any,
  identifier: string,
  viewAndBuyButtonUrl?: string, // this is set by ownProps
  tileVariant?: string,
  shouldShowPriceOnUnavailableTiles?: boolean,
  shouldShowRestOfShelf = true,
  renderBuyboxActions: BuyboxActionRenderProp['renderBuyboxActions'] = undefined,
  isTrolley?: boolean,
): TBuyboxData => {
  if (getProductIsForSale(item) || isAvailableEpwOverride(state, item)) {
    if (viewAndBuyButtonUrl) {
      return {
        buyboxType: VIEW_AND_BUY_BUYBOX,
        href: viewAndBuyButtonUrl,
        text: translation('common:view-and-buy'),
        ...getPriceData(state, item, translation, isTrolley),
      };
    }

    return {
      buyboxType: QUANTITY_CONTROLS_BUYBOX,
      ...getPriceData(state, item, translation, isTrolley),
      ...getQuantityControlData(state, item, identifier, translation),
    };
  }

  const currentUrl = getCurrentUrl(state);
  const restOfShelfData = getRestOfShelfBuyboxData(state, item, translation, config, currentUrl);

  if (shouldShowPriceOnUnavailableTiles && restOfShelfData !== null && !isTrolley) {
    return {
      buyboxType: UNAVAILABLE_WITH_PRICE_REST_OF_SHELF_BUYBOX,
      ...getPriceData(state, item, translation, isTrolley),
      ...restOfShelfData,
    };
  }

  if (shouldShowPriceOnUnavailableTiles && (restOfShelfData === null || isTrolley)) {
    return {
      buyboxType: UNAVAILABLE_WITH_PRICE_BUYBOX,
      ...getPriceData(state, item, translation, isTrolley),
      renderBuyboxActions,
    };
  }

  if (!shouldShowPriceOnUnavailableTiles && restOfShelfData !== null && shouldShowRestOfShelf) {
    return {
      buyboxType: REST_OF_SHELF_BUYBOX,
      ...restOfShelfData,
      renderBuyboxActions,
    };
  }

  return {
    buyboxType: NO_BUYBOX,
  };
};
