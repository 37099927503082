import analyticsBus from '../analyticsBus';
import { basicEvent } from './basic';
import {
  SUPERDEPARTMENT,
  DEPARTMENT,
  AISLE
} from '../../constants/taxonomy-levels';
import {
  DELAY,
  INSPIRATION_AND_EVENTS,
  NAVIGATION,
  NEW,
  TOP_NAV
} from '../constants';
import { getIsExploreAll } from '#/experiments/oop-1890/helpers';
import { ALL } from '#/experiments/oop-1890/constants';

export function buildNavigationMenuString(
  taxonomyState,
  menuType,
  selected,
  isInspireMeMenu = false
) {
  const isAllOrPromotionNode = selected.allNode || selected.allPromotionNode;
  const isNewBrowseNode = selected.newBrowseNode || false;
  const inspireMeKey = isInspireMeMenu ? INSPIRATION_AND_EVENTS : undefined;
  let selectedNameSuffix = '';
  if (!isAllOrPromotionNode) {
    selectedNameSuffix = `:${selected.name}`;
    if (isNewBrowseNode) {
      selectedNameSuffix = `:${NEW} ${selected.name}`;
    }
  }

  const superDepartmentName = taxonomyState.superdepartment?.name;
  if (isInspireMeMenu && getIsExploreAll(selected.name)) {
    return `${superDepartmentName}:${ALL}`;
  }
  switch (menuType) {
    case SUPERDEPARTMENT:
      return selected.name;
    case DEPARTMENT:
      return `${superDepartmentName ?? inspireMeKey}${selectedNameSuffix}`;
    case AISLE:
      return `${superDepartmentName ?? inspireMeKey}:${
        taxonomyState.department?.name
      }${selectedNameSuffix}`;
    default:
      return null;
  }
}

export function navigationMenuAnalyticsEvent(
  taxonomyState,
  menuType,
  selected,
  isInspireMeMenu = false
) {
  const value = buildNavigationMenuString(
    taxonomyState,
    menuType,
    selected,
    isInspireMeMenu
  );

  if (isInspireMeMenu) {
    menuType === AISLE &&
      basicEvent(analyticsBus, { type: TOP_NAV, value, action: DELAY });
  } else if (value) {
    basicEvent(analyticsBus, { type: NAVIGATION, value, menuType });
  }
}
