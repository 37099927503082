import filterResourcesByDependenciesResults from '#/conditional-resources/helpers/filter-resources-by-dependencies-results';
import getConditionalResources from '#/conditional-resources/helpers/get-conditional-resources';
import getUnconditionalResourceNames from '#/conditional-resources/helpers/get-unconditional-resource-names';

export default async function recursivelyFetchResources(
  resources,
  fetchResources,
  resourceData
) {
  const fetchedResourceData = await fetchResources(
    getUnconditionalResourceNames(resources),
    resourceData
  );

  if (!fetchedResourceData) {
    return resourceData;
  }

  const mergedResourceData = resourceData
    ? { ...fetchedResourceData, ...resourceData }
    : fetchedResourceData;

  const conditionalResources = getConditionalResources(resources);

  if (!conditionalResources.length) {
    return mergedResourceData;
  }

  const filteredConditionalResources = filterResourcesByDependenciesResults(
    conditionalResources,
    mergedResourceData
  );

  if (!filteredConditionalResources.length) {
    return mergedResourceData;
  }

  return recursivelyFetchResources(
    filteredConditionalResources,
    fetchResources,
    mergedResourceData
  );
}
