import {
  ORDER_TYPE_CHANGE_COOKIE_ACCEPTED,
  CHANGE_SLOT_COOKIE_ACCEPTED,
  CHANGE_COLLECTION_LOCATION_COOKIE_ACCEPTED,
  CHANGE_DELIVERY_ADDRESS_COOKIE_ACCEPTED,
  BOOK_WHOOSH_SLOT_COOKIE_ACCEPTED,
} from './action-types';

export const ORDER_TYPE_CHANGE = 'orderTypeChangeWarningAccepted';
export const BOOK_WHOOSH_SLOT = 'bookWhooshSlotWarningAccepted';
export const SLOT_CHANGE = 'slotChangeWarningAccepted';
export const CHANGE_COLLECTION_LOCATION = 'changeCollectionLocationAccepted';
export const CHANGE_DELIVERY_ADDRESS = 'changeAddressCookieAccepted';

export const CHANGING_DELIVERY_ADDRESS = 'changingAddress';
export const MISSED_DELIVERY_SLOT = 'MissedDeliverySlot';
export const MISSED_QUANTITY = 'MissedQuantity';

export type WarningTypes =
  | typeof ORDER_TYPE_CHANGE
  | typeof BOOK_WHOOSH_SLOT
  | typeof SLOT_CHANGE
  | typeof CHANGE_COLLECTION_LOCATION
  | typeof CHANGE_DELIVERY_ADDRESS;

export const warningTypes = {
  [ORDER_TYPE_CHANGE]: ORDER_TYPE_CHANGE_COOKIE_ACCEPTED,
  [SLOT_CHANGE]: CHANGE_SLOT_COOKIE_ACCEPTED,
  [BOOK_WHOOSH_SLOT]: BOOK_WHOOSH_SLOT_COOKIE_ACCEPTED,
  [CHANGE_COLLECTION_LOCATION]: CHANGE_COLLECTION_LOCATION_COOKIE_ACCEPTED,
  [CHANGE_DELIVERY_ADDRESS]: CHANGE_DELIVERY_ADDRESS_COOKIE_ACCEPTED,
};
