import React, { useMemo } from 'react';
import { compose } from 'react-recompose';
import { createSPALink, menuDataAdapter } from '@ddsweb/helpers';
import Link from '#/components/link-check-spa';
import { SHOP } from '#/constants/common';
import { StyledMainMenu, StyledMenuContainer } from '#/components/horizontal-taxonomy/styled';
import { taxonomyAdapter } from '#/components/horizontal-taxonomy/helpers/adapter';
import { getTaxonomyData, fireAnalyticsOnMenuSelection } from '#/components/horizontal-taxonomy/helpers/taxonomy';
import { INSPIRE_AND_EVENTS_KEY, OwnState, VISIBLE_MENU_ITEMS } from '#/components/horizontal-taxonomy/types';
import helpers from '#/lib/decorators/helpers';
import { connect } from '#/lib/render/connect-deep-compare';
import { getDeviceType, getLanguageLink } from '#/reducers/app';
import { getNavList } from '#/reducers/taxonomy';
import { POSTFIX_LOADING } from '#/constants/horizontal-taxanomy';
import { TTranslateFunc } from '#/lib/records/helpers.defs';
import { SlidingSubmenu } from '@ddsweb/local-navigation';
import { basicEvent } from '#/analytics/types/basic';
import analyticsBus from '#/analytics/analyticsBus';
import { NOW, TOP_NAV } from '#/analytics/constants';
import { DEPARTMENT } from '#/constants/taxonomy-levels';

export type HorizontalTaxonomyProps = OwnState & {
  t: TTranslateFunc;
};

const mapStateToProps = (state: Store): OwnState => {
  return {
    deviceType: getDeviceType(state),
    // @ts-expect-error
    navList: getNavList(state),
    shopUrl: getLanguageLink(state, SHOP),
  };
};

const connector = connect(mapStateToProps, {});

const HorizontalTaxonomy = ({
  navList,
  t: translate,
  shopUrl,
  selectedSuperDepartment,
}: HorizontalTaxonomyProps): JSX.Element => {
  const [selectedMenuItemID, setSelectedMenuItemID] = React.useState<null | string>(null);

  const onChangeHandler = ({ selectedMenuItemID }: { selectedMenuItemID: string }): void => {
    setSelectedMenuItemID(selectedMenuItemID);
  };

  const superDepartmentsList = useMemo(
    () => navList.filter(navItem => !navItem?.name?.toLowerCase().includes(INSPIRE_AND_EVENTS_KEY)),
    [navList],
  );
  const taxonomyData = useMemo(() => getTaxonomyData([...superDepartmentsList]), [superDepartmentsList]);

  const onClickHandler = (e: MouseEvent): void => {
    const target = e.target as HTMLElement | null;
    const link = target?.closest('a');
    const navigable = link?.getAttribute('aria-haspopup') === 'false';
    const elemId = link?.getAttribute('id') ?? '';
    const label = link?.getAttribute('label') ?? '';

    if (new RegExp(POSTFIX_LOADING, 'i').test(elemId)) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    navigable && setSelectedMenuItemID(null); //close taxonomy
    fireAnalyticsOnMenuSelection(elemId, label, navigable);
  };

  const SPALink = createSPALink(Link, props => ({
    onClick: props.onClick,
    to: props.href,
    className: props.className,
    role: props.role,
    id: props.id,
    name: props.name,
    label: props.label,
    ['aria-current']: props['aria-current'],
    ['aria-expanded']: props['aria-expanded'],
    ['aria-haspopup']: props['aria-haspopup'],
  }));

  const menuItems = useMemo(() => menuDataAdapter(taxonomyAdapter(translate, shopUrl), taxonomyData), [taxonomyData]);

  const moreMenuItem = useMemo(() => {
    const moreMenuText = translate('navigation:more');
    const moreMenuAriaLabel = translate('navigation:more-aria-label');

    return {
      href: '/',
      text: moreMenuText,
      ariaLabel: moreMenuAriaLabel,
      id: moreMenuText,
      onClick: () =>
        basicEvent(analyticsBus, {
          type: TOP_NAV,
          value: `${DEPARTMENT}:${moreMenuText.toLowerCase()}`,
          action: NOW,
        }),
    };
  }, [translate]);

  const mobileMenuItem = useMemo(() => {
    return {
      href: '/',
      text: translate('navigation:groceries'),
      ariaLabel: translate('navigation:groceries'),
      id: translate('navigation:groceries'),
    };
  }, [translate]);

  return (
    <StyledMenuContainer isMenuOpen={selectedMenuItemID}>
      <StyledMainMenu
        className={'menu-items'}
        retainHighlightOfPreviousItem={false}
        menuItems={menuItems}
        mobileMenuItem={mobileMenuItem}
        moreMenuItem={moreMenuItem}
        spaLink={SPALink}
        onChange={onChangeHandler}
        onClick={onClickHandler}
        selectedMenuItemID={selectedMenuItemID}
        currentMenuItemID={selectedSuperDepartment ? `superDepartment|${selectedSuperDepartment}` : null}
        root={false}
        lockPage={false}
        visibleMenuItems={VISIBLE_MENU_ITEMS}
        closeLinkText={translate('close')}
      >
        <SlidingSubmenu />
      </StyledMainMenu>
    </StyledMenuContainer>
  );
};

const enhance = compose(helpers(['t']), connector);
export default enhance(HorizontalTaxonomy);
