import React, { MouseEvent, useEffect } from 'react';
import { ContainerComponent, StyledContentStamp } from '#/experiments/oop-1894/components/styled';
import { StampProps } from '#/experiments/oop-1894/types/index.def';
import { emitTaxonomyStampAnalyticsEvent } from '#/experiments/oop-1894/helpers/analytics';
import { DELAY, NOW } from '#/analytics/constants';
import { LINK_NEW_WINDOW_YES, ANALYTICS_IMPRESSION, STAMP_IMAGE_WIDTH } from '#/experiments/oop-1894/constants';

const ContentStampList = ({
  isMenuSelected,
  stampsList,
}: {
  isMenuSelected: boolean;
  stampsList: Array<StampProps>;
}): JSX.Element => {
  useEffect(() => {
    stampsList.forEach((item: StampProps) => {
      const {
        props: { title },
      } = item;
      emitTaxonomyStampAnalyticsEvent(NOW, `${title}${ANALYTICS_IMPRESSION}`);
    });
  }, []);

  const renderStampList = (): Array<JSX.Element> => {
    return stampsList.map((stamp: StampProps) => {
      const {
        props: { contentId },
      } = stamp;
      return <StampItem details={stamp} key={contentId} isMenuSelected={isMenuSelected} />;
    });
  };

  return <ContainerComponent isMenuSelected={isMenuSelected}>{renderStampList()}</ContainerComponent>;
};

export const StampItem = ({
  details,
  isMenuSelected,
}: {
  details: StampProps;
  isMenuSelected: boolean;
}): JSX.Element => {
  const {
    props: { title, image, linkText, linkNewWindow },
  } = details;

  const clickHandler = (event: MouseEvent<HTMLDivElement, MouseEvent>): void => {
    emitTaxonomyStampAnalyticsEvent(DELAY, title);

    if (linkNewWindow === LINK_NEW_WINDOW_YES) {
      event.stopPropagation();
      event.preventDefault();
      window?.open(linkText, '_blank', 'noopener,noreferrer');
    }
  };

  const imageUrl = `${image}?w=${STAMP_IMAGE_WIDTH}`;

  return (
    <StyledContentStamp
      ariaLabel={title}
      onClick={clickHandler}
      href={linkText}
      image={{
        alt: title,
        src: imageUrl,
      }}
      isMenuSelected={isMenuSelected}
      link={{
        text: title,
      }}
    />
  );
};

export default ContentStampList;
