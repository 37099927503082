import {
  SINGLE_VALUE_MESSAGE,
  DOUBLE_VALUE_MESSAGE,
  FIXED_MESSAGE,
  REGULAR_PRICE_CLUBCARD
} from '#/constants/dcs';
import { doubleValueTileTemplate } from './double-value-tile-template';
import { singleValueTileTemplate } from './single-value-tile-template';
import { fixedMessageTileTemplate } from './fixed-message-tile-template';
import { regularPriceClubCardTileTemplate } from './regular-price-club-card-tile-template';
import { doubleValueTileUKTemplate } from './double-value-tile-template-uk';
import { singleValueTileUKTemplate } from './single-value-tile-template-uk';
import { fixedMessageTileUKTemplate } from './fixed-message-tile-template-uk';
import { regularPriceClubCardTileUKTemplate } from './regular-price-club-card-tile-template-uk';

const ValueTileTemplates = {
  [SINGLE_VALUE_MESSAGE]: () => singleValueTileTemplate,
  [DOUBLE_VALUE_MESSAGE]: () => doubleValueTileTemplate,
  [FIXED_MESSAGE]: () => fixedMessageTileTemplate,
  [REGULAR_PRICE_CLUBCARD]: () => regularPriceClubCardTileTemplate
};

const ValueTileUKTemplates = {
  [SINGLE_VALUE_MESSAGE]: () => singleValueTileUKTemplate,
  [DOUBLE_VALUE_MESSAGE]: () => doubleValueTileUKTemplate,
  [FIXED_MESSAGE]: () => fixedMessageTileUKTemplate,
  [REGULAR_PRICE_CLUBCARD]: () => regularPriceClubCardTileUKTemplate
};

const getValueTileTemplate = (valueTileType, region) => {
  const currentTemplate =
    region === 'UK'
      ? ValueTileUKTemplates[valueTileType]
      : ValueTileTemplates[valueTileType];

  if (valueTileType && currentTemplate) {
    return currentTemplate();
  }
};

export {
  doubleValueTileTemplate,
  singleValueTileTemplate,
  fixedMessageTileTemplate,
  regularPriceClubCardTileTemplate,
  singleValueTileUKTemplate,
  getValueTileTemplate
};
