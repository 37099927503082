import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Link from '../../link-check-spa';
import Notification from '@beans/notification';
import { BodyText } from '@beans/typography';
import SafelyOutputString from '#/components/safely-output-string';

export default class BagMessage extends PureComponent {
  constructor(props, context) {
    super(props, context);
  }

  static propTypes = {
    className: PropTypes.string,
    messageHeading: PropTypes.string,
    messageLink: PropTypes.object,
    messages: PropTypes.array.isRequired,
    showNotificationIcon: PropTypes.bool
  };

  static defaultProps = {
    showNotificationIcon: false
  };

  render() {
    const {
      className,
      messageHeading,
      messages,
      messageLink,
      showNotificationIcon
    } = this.props;
    return (
      <div className={`bag-message ${className}`}>
        <Notification title={messageHeading} showIcon={showNotificationIcon}>
          {messages.map((messageText, index) => (
            <BodyText key={index}>
              <SafelyOutputString>{messageText}</SafelyOutputString>
            </BodyText>
          ))}
          {messageLink && (
            <BodyText>
              <Link to={messageLink.href} target="_blank">
                <span>{messageLink.text}</span>
                <span className="icon icon-question-dark" />
              </Link>
            </BodyText>
          )}
        </Notification>
      </div>
    );
  }
}
