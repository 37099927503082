import { ADDRESSES } from '#/constants/spa-resource';
import { RECEIVE_ADDRESSES, UPDATE_PERSONAL_DETAILS } from '#/constants/action-types';
import { AddressesState } from '#/custom-typings/redux-store/addresses.defs';

export interface AddressData {
  id: string;
  addressLine1: string;
  addressLine2?: string;
  addressLine3?: string;
  addressLine4?: string;
  addressLine5?: string;
  isBaglessDelivery: boolean;
  isBillingAddress: boolean;
  isBlockedAddress: boolean;
  isBusinessAddress: boolean;
  mobilePhone: string;
  name: string;
  postcode: string;
  primaryPhone: string;
  secondaryPhone?: string;
  storeId: string;
}

export interface TReceiveAddressesAction {
  type: typeof RECEIVE_ADDRESSES;
  payload: AddressesState;
}

export interface TUserDetails {
  title: string;
  firstName: string;
  lastName: string;
  primaryPhone: string;
  secondaryPhone?: string;
}

export interface TUpdatePersonalDetailsAction {
  type: typeof UPDATE_PERSONAL_DETAILS;
  userDetails: TUserDetails;
}

export type TAddressesAction = TReceiveAddressesAction;

const defaultState: AddressesState = { defaultAddress: undefined, allAddresses: undefined };

function receiveAddresses(state: AddressesState, action: TReceiveAddressesAction): AddressesState {
  const { defaultAddress, allAddresses } = action.payload || {};

  return {
    ...state,
    defaultAddress,
    allAddresses,
  };
}

function updatePhoneNumber(state: AddressesState, action: TUpdatePersonalDetailsAction): AddressesState {
  const { userDetails } = action || {};
  const defaultAddressClone: AddressData = {
    ...state.defaultAddress,
    primaryPhone: userDetails?.primaryPhone || '',
    secondaryPhone: userDetails?.secondaryPhone || '',
  } as AddressData;

  return {
    ...state,
    defaultAddress: defaultAddressClone,
  };
}

export default function addresses(state = defaultState, action: TAddressesAction | TUpdatePersonalDetailsAction) {
  switch (action.type) {
    case RECEIVE_ADDRESSES:
      return receiveAddresses(state, action);
    case UPDATE_PERSONAL_DETAILS:
      return updatePhoneNumber(state, action);
    default:
      return state;
  }
}

export function getDefaultStateFromProps(props): AddressesState {
  const { defaultAddress, allAddresses } = props.resources[ADDRESSES]?.data || {};

  return {
    defaultAddress,
    allAddresses,
  };
}
