import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const PropTile = props => {
  const { desc, icon, title } = props;
  const iconClasses = classnames('prop-icon', icon);

  return (
    <div className="prop-tile">
      <div className="prop-icon-container">
        <div className={iconClasses} />
      </div>
      <div className="prop-text-container">
        <span className="prop-title">{title}</span>
        <span className="prop-desc">{desc}</span>
      </div>
    </div>
  );
};

PropTile.propTypes = {
  desc: PropTypes.string.isRequired,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired
};

export { PropTile };
