import { createSelector } from 'reselect';
import {
  getResultsPage,
  getResultsPageByTypeFactory
} from '#/selectors/results/get-page';
import { getResultsType } from '#/selectors/results/get-results-type';

export const getPageResultsItems = createSelector(
  [getResultsPage],
  page => page
);

export const getPageResultsItemsByTypeFactory = resultsType =>
  createSelector(getResultsPageByTypeFactory(resultsType), page => page);

// create a new selector each time the resultsType changes and memoize it
// this is a way of memoising different
// memoising selectors for different results types
export const getPageResultsItemsByTypeSelector = createSelector(
  getResultsType,
  resultsType => {
    return getPageResultsItemsByTypeFactory(resultsType);
  }
);
