import React, { useEffect, useState, SyntheticEvent, MouseEventHandler } from 'react';
import { compose } from 'react-recompose';
import { Text } from '@ddsweb/text';
import { Heading } from '@ddsweb/heading';
import Button from '@ddsweb/button';
import { connect } from '#/lib/render/connect-deep-compare';
import helpers from '#/lib/decorators/helpers';
import { getUserUuid } from '#/reducers/user';
import { localStore } from '#/lib/data-store/client-store';
import { FloatingTooltip, TooltipVariants, TooltipPositions, TRef } from '#/components/shared/tooltip';
import analyticsBus from '#/analytics/analyticsBus';
import { basicEvent } from '#/analytics/types/basic';
import { TooltipStyleDesktop, TooltipStyleMobile } from './styled';
import { TTranslateFn } from '#/components/slots/delivery-content-section/index.defs';
import { TOOLTIP_WIDTH_DESKTOP, TOOLTIP_WIDTH_MOBILE, GOT_IT, CLOSED, CLOSE_X } from './constants';

export type Props = {
  id: string;
  targetRef: TRef;
  position?: string;
  mobileOnly?: boolean;
  tooltipRootID?: string;
  boundingRef?: TRef;
  portalRootID?: string;
  observerId?: string;
  shouldForceClose: boolean;
  t: TTranslateFn;
};

type TOwnState = {
  uuid: string;
};

const mapStateToProps = (state: Store): TOwnState => ({
  uuid: getUserUuid(state),
});

const connector = connect(mapStateToProps, {});

type TProps = Props & TOwnState;

const getStorageKey = (cid: string): string => `fav-unavailable-filter-tooltip_${cid}`;

const renderTooltipBody = (onClick: MouseEventHandler, translate: TTranslateFn): Array<JSX.Element> => [
  <Heading headingLevel="4" key="available-filter-tooltip-heading">
    {translate('favorites:filter-tooltip.available-filter.title')}
  </Heading>,
  <Text as="p" key="available-filter-tooltip-body" id="available-selector-tooltip-accessibility-content">
    {translate('favorites:filter-tooltip.available-filter.body')}
  </Text>,
  <Button key="available-filter-tooltip-button" fullWidth onClick={onClick} variant="secondary">
    {translate('favorites:filter-tooltip.available-filter.footer-cta')}
  </Button>,
];

const TooltipContainer = ({
  targetRef,
  id,
  tooltipRootID,
  boundingRef,
  portalRootID,
  observerId,
  shouldForceClose = false,
  mobileOnly = false,
  t: translate,
  uuid,
}: TProps): JSX.Element | null => {
  const tooltipWidth = mobileOnly ? TOOLTIP_WIDTH_MOBILE : TOOLTIP_WIDTH_DESKTOP;
  const styles = mobileOnly ? TooltipStyleMobile : TooltipStyleDesktop;

  const getTooltipStorage = (): string => localStore?.get(getStorageKey(uuid));

  const [isOpen, setOpen] = useState(!getTooltipStorage());

  const setTooltipStorage = (): void => {
    localStore?.set(getStorageKey(uuid), CLOSED);
  };

  useEffect(() => {
    if (shouldForceClose && isOpen) {
      setTooltipStorage();
      setOpen(false);
    }
  }, [shouldForceClose]);

  const sendTooltipEvent = (value: string): void => {
    basicEvent(analyticsBus, {
      action: 'now',
      type: 'available items:tooltip',
      value,
    });
  };

  const onClickHandler = (e: SyntheticEvent): void => {
    e.stopPropagation();
  };

  const changeHandler = (e: { action: string }): void => {
    const value = e.action === 'close' ? CLOSE_X : GOT_IT;
    sendTooltipEvent(value);
    setOpen(false);
  };

  const buttonClickHandler = (e: SyntheticEvent): void => {
    e.stopPropagation();
    changeHandler({ action: GOT_IT });
  };

  if (!isOpen) {
    return null;
  }

  return (
    <FloatingTooltip
      targetRef={targetRef}
      boundingRef={boundingRef}
      id={id}
      position={TooltipPositions.BOTTOM}
      tooltipWidth={tooltipWidth}
      tooltipVariant={TooltipVariants.INFO}
      styles={styles}
      isOpen={isOpen}
      onChange={changeHandler}
      onClick={onClickHandler}
      tooltipRootID={tooltipRootID}
      portalRootID={portalRootID}
      onUnmount={setTooltipStorage}
      observerId={observerId}
    >
      {renderTooltipBody(buttonClickHandler, translate)}
    </FloatingTooltip>
  );
};

const enhance = compose<TProps, Props>(connector);
export default helpers(['t'])(enhance(TooltipContainer));
