import React from 'react';
import helpers from '../../lib/decorators/helpers';
import { SkipToContentStyled } from './styled';

export type SkipLinksProps = {
  includeMiniTrolleyLink?: boolean;
  includeSearchLink?: boolean;
  t: (key: string, options?: { [key: string]: string }) => string;
};

const SkipLinks: React.FC<SkipLinksProps> = ({ includeMiniTrolleyLink, includeSearchLink, t: translate }) => {
  const links = [
    {
      focus: true,
      id: 'main',
      text: translate('layout:skip-to-main'),
    },
  ];

  if (includeSearchLink) {
    links.push({
      focus: true,
      id: 'search-input',
      text: translate('layout:skip-to-search'),
    });
  }

  if (includeMiniTrolleyLink) {
    links.push({
      focus: true,
      id: 'mini-trolley',
      text: translate('layout:skip-to-basket'),
    });
  }
  const handleSkipLink = (anchor: HTMLAnchorElement): void => {
    const skipToId = anchor.hash.slice(1);
    const skipToElement: HTMLElement | null = document.getElementById(skipToId);

    if (skipToElement) {
      if (!skipToElement.hasAttribute('tabindex')) {
        skipToElement.setAttribute('tabindex', '-1');
      }
      skipToElement.scrollIntoView();
      skipToElement.focus();
    }
  };

  const handleSkipLinksClick = (event: React.MouseEvent): void => {
    const element = event.target as HTMLAnchorElement;

    if (isAnchorLink(element)) {
      event.preventDefault();
      handleSkipLink(element);
    }
  };

  const handleSkipLinksKeydown = (event: React.KeyboardEvent): void => {
    const element = event.target as HTMLAnchorElement;
    const isEnterKey = event?.key === 'Enter' || event?.key === 'NumpadEnter';

    if (isEnterKey && isAnchorLink(element)) {
      event.preventDefault();
      handleSkipLink(element);
    }
  };

  const isAnchorLink = (element: HTMLElement): boolean => {
    if (element.tagName.toLowerCase() === 'a') {
      const anchor = element as HTMLAnchorElement;
      return Boolean(anchor?.hash);
    }

    return false;
  };

  return (
    <nav id="skip-links" aria-label="Skip links">
      <div role="presentation" onClick={handleSkipLinksClick} onKeyDown={handleSkipLinksKeydown}>
        <SkipToContentStyled className="skip-to-content" links={links} />
      </div>
    </nav>
  );
};

export default helpers(['t'])(SkipLinks);
