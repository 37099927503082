import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import helpers from '../../../lib/decorators/helpers';

/**
 * Renders the icon set in the required `name` prop.
 */
const Icon = ({
  className,
  name,
  hiddenText,
  externalWindow,
  size,
  t: translate
}) => (
  <span
    className={classnames(
      `icon icon-${name}`,
      { [`icon--${size}`]: size },
      className
    )}
  >
    {hiddenText && (
      <span className="visually-hidden">
        {hiddenText}
        {externalWindow && ` ${translate('common:new-window')}`}
      </span>
    )}
  </span>
);

Icon.propTypes = {
  className: PropTypes.string,
  externalWindow: PropTypes.bool,
  hiddenText: PropTypes.string,
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['large']),
  t: PropTypes.func.isRequired
};

export default helpers(['t'])(Icon);
