import React from 'react';
import { SeparatorWrapper, LeftLine, Arrow, RightLine } from './styled';

export const Separator = (): JSX.Element => (
  <SeparatorWrapper>
    <LeftLine />
    <Arrow />
    <RightLine />
  </SeparatorWrapper>
);
