
  const componentCache = new Map();

  /*
  preventLoadingComponentCache flag is set in the jest configuration
  and it prevents the loading of global componentCache while running 
  unit tests. This saves us from some nasty circular dependency issues
  which jest can't handle and fails while preparing the dependency tree.
  */
  if (!process.env.CLIENT_SIDE && !global.preventLoadingComponentCache) {
    componentCache.set('Home', require('../components/pages/home').default);
componentCache.set('Zone', require('../components/pages/zone').default);
componentCache.set('BookASlotHub', require('../components/pages/book-a-slot-hub').default);
componentCache.set('BookASlot', require('../components/pages/book-a-slot').default);
componentCache.set('ChooseALocation', require('../components/pages/choose-a-location').default);
componentCache.set('BuylistGroup', require('../components/pages/buylist-group').default);
componentCache.set('Buylist', require('../components/pages/buy-list').default);
componentCache.set('Categories', require('../components/pages/categories').default);
componentCache.set('Shop', require('../components/pages/shop-all-groceries').default);
componentCache.set('Search', require('../components/pages/search-results').default);
componentCache.set('MultiSearch', require('../components/pages/multi-search-list').default);
componentCache.set('Trolley', require('../components/pages/trolley').default);
componentCache.set('PromotionsHome', require('../components/pages/promotions').default);
componentCache.set('PromotionsABRedesign', require('../components/pages/promotions/promotions-ab-redesign').default);
componentCache.set('PromotionList', require('../components/pages/promotion-list').default);
componentCache.set('Favorites', require('../components/pages/favorites').default);
componentCache.set('ProductDetails', require('../components/pages/product-details').default);
componentCache.set('RatingsReviewsSubmission', require('../components/pages/ratings-reviews/submission').default);
componentCache.set('RatingsReviewsThankyou', require('../components/pages/ratings-reviews/thankyou').default);
  }

  export const addToCache = (name, component) => {
    componentCache.set(name, component);
  };

  const promiseCache = new Map();

  export default class LazyComponent {

    constructor(name) {
      this.name = name;
    }

    // returns cached version if there is one and null otherwise
    // on the server components are always in the cache.
    fromCache() {
      if (componentCache.has(this.name)) {
        return componentCache.get(this.name);
      }
      return null;
    }

    fetch() {
      if (process.env.CLIENT_SIDE) {
        if (promiseCache.has(this.name)) return promiseCache.get(this.name);
        let componentPromise;
        // dynamic imports must have a string literal argument rather than any expression so
        // the dependencies can be statically analysed at build time. This means we need this
        // ugly switch/case statement here rather than building the imports from a map.
        switch (this.name) {
            case 'Home':
                 componentPromise = import(/* webpackChunkName: "dynamic-home.bundle" */ '../components/pages/home').then(component => component.default);
                 break;
case 'Zone':
                 componentPromise = import(/* webpackChunkName: "dynamic-zone.bundle" */ '../components/pages/zone').then(component => component.default);
                 break;
case 'BookASlotHub':
                 componentPromise = import(/* webpackChunkName: "dynamic-book-a-slot-hub.bundle" */ '../components/pages/book-a-slot-hub').then(component => component.default);
                 break;
case 'BookASlot':
                 componentPromise = import(/* webpackChunkName: "dynamic-book-a-slot.bundle" */ '../components/pages/book-a-slot').then(component => component.default);
                 break;
case 'ChooseALocation':
                 componentPromise = import(/* webpackChunkName: "dynamic-choose-a-location.bundle" */ '../components/pages/choose-a-location').then(component => component.default);
                 break;
case 'BuylistGroup':
                 componentPromise = import(/* webpackChunkName: "dynamic-buylist-group.bundle" */ '../components/pages/buylist-group').then(component => component.default);
                 break;
case 'Buylist':
                 componentPromise = import(/* webpackChunkName: "dynamic-buylist.bundle" */ '../components/pages/buy-list').then(component => component.default);
                 break;
case 'Categories':
                 componentPromise = import(/* webpackChunkName: "dynamic-categories.bundle" */ '../components/pages/categories').then(component => component.default);
                 break;
case 'Shop':
                 componentPromise = import(/* webpackChunkName: "dynamic-shop-all-groceries.bundle" */ '../components/pages/shop-all-groceries').then(component => component.default);
                 break;
case 'Search':
                 componentPromise = import(/* webpackChunkName: "dynamic-search.bundle" */ '../components/pages/search-results').then(component => component.default);
                 break;
case 'MultiSearch':
                 componentPromise = import(/* webpackChunkName: "dynamic-multi-search.bundle" */ '../components/pages/multi-search-list').then(component => component.default);
                 break;
case 'Trolley':
                 componentPromise = import(/* webpackChunkName: "dynamic-trolley.bundle" */ '../components/pages/trolley').then(component => component.default);
                 break;
case 'PromotionsHome':
                 componentPromise = import(/* webpackChunkName: "dynamic-promotions-home.bundle" */ '../components/pages/promotions').then(component => component.default);
                 break;
case 'PromotionsABRedesign':
                 componentPromise = import(/* webpackChunkName: "dynamic-promotions-ab-redesign.bundle" */ '../components/pages/promotions/promotions-ab-redesign').then(component => component.default);
                 break;
case 'PromotionList':
                 componentPromise = import(/* webpackChunkName: "dynamic-promotion-list.bundle" */ '../components/pages/promotion-list').then(component => component.default);
                 break;
case 'Favorites':
                 componentPromise = import(/* webpackChunkName: "dynamic-favorites.bundle" */ '../components/pages/favorites').then(component => component.default);
                 break;
case 'ProductDetails':
                 componentPromise = import(/* webpackChunkName: "dynamic-product-details.bundle" */ '../components/pages/product-details').then(component => component.default);
                 break;
case 'RatingsReviewsSubmission':
                 componentPromise = import(/* webpackChunkName: "dynamic-ratings-reviews-submission.bundle" */ '../components/pages/ratings-reviews/submission').then(component => component.default);
                 break;
case 'RatingsReviewsThankyou':
                 componentPromise = import(/* webpackChunkName: "dynamic-ratings-reviews-thankyou.bundle" */ '../components/pages/ratings-reviews/thankyou').then(component => component.default);
                 break;
          default:
            throw new Error('Unknown lazy component');
        }

        const componentPromiseWithCaching = new Promise((resolve) => {
          componentPromise.then((Component) => {
            addToCache(this.name, Component);
            resolve(Component);
          });
        });

        promiseCache.set(this.name, componentPromiseWithCaching);
        return componentPromiseWithCaching;
      } else {
        throw new Error('There\'s no need to lazy load components on the server - use "fromCache" method instead');
      }
    }
  }
