import React, { memo } from 'react';
import { InfoMessage, InfoMessageLink } from '#/components/product-tiles/common/info-messages/components';
import {
  TRestrictionMessagesData,
  TRestrictedOrderAmendmentMessageFormat,
} from '#/selectors/beans-product-tile/info-message-data';
import { STOCK_QUANTITY_EXCEEDED } from '#/constants/restrictions';
import { AldiPriceMatchInfoMessage } from '#/components/product-tiles/common/info-messages/aldi-price-match-info-message';
import { LowEveryPriceMatchInfoMessage } from './low-everyday-price-message';

type TInfoMessageProps = {
  text?: string[];
  message?: string | null;
  restrictionType?: string;
  backgroundColorClass?: string | null;
} | null;

const renderInfoMessages = (messageData, showStockQtyExceedMessage, hasBackgroundColor): JSX.Element | null => {
  const { data, display } = messageData;
  if (!data) {
    return null;
  }

  const { messages, changeSlotLink } = data;
  if (!display || !messages) return null;
  return (
    <>
      <ul>
        {messages.map((props: TInfoMessageProps, i) => {
          let messageText: string[];
          if (props) {
            const { text, backgroundColorClass, message, restrictionType } = props;
            if (text) {
              messageText = text;
            } else if (message) {
              messageText = !showStockQtyExceedMessage && restrictionType === STOCK_QUANTITY_EXCEEDED ? [] : [message];
            } else {
              messageText = [];
            }
            return (
              <li key={`info-message-${i}`}>
                <InfoMessage
                  messages={messageText}
                  backgroundColorClass={backgroundColorClass}
                  hasBackgroundColor={hasBackgroundColor}
                />
              </li>
            );
          }
        })}
      </ul>
      {changeSlotLink && <InfoMessageLink link={changeSlotLink} />}
    </>
  );
};

const renderRestrictedOrderAmendmentMessages = ({
  display,
  data,
}: {
  display: boolean;
  data: TRestrictedOrderAmendmentMessageFormat;
}): JSX.Element | null => {
  if (!data) return null;

  const { message, backgroundColorClass } = data;
  if (!display || !message) return null;

  return <InfoMessage messages={[message]} backgroundColorClass={backgroundColorClass} />;
};

const DEFAULT_VALUE = { display: false, data: null };

export const InfoMessagesWrapper = ({
  showStockQtyExceedMessage,
  className,
  infoMessagesData,
  hasBackgroundColor,
}: {
  showStockQtyExceedMessage?: boolean;
  className?: string;
  infoMessagesData: TRestrictionMessagesData;
  hasBackgroundColor?: boolean;
}): JSX.Element => {
  const {
    infoMessages = DEFAULT_VALUE,
    restrictedOrderAmendmentMessage = DEFAULT_VALUE,
    aldiPriceMatchMessage = DEFAULT_VALUE,
    lowEverydayPricingMessage = DEFAULT_VALUE,
  } = infoMessagesData;

  return (
    <div className={className}>
      <div className="product-info-message-list">
        {renderInfoMessages(infoMessages, showStockQtyExceedMessage, hasBackgroundColor)}
        {renderRestrictedOrderAmendmentMessages(restrictedOrderAmendmentMessage)}
        {aldiPriceMatchMessage.display && <AldiPriceMatchInfoMessage />}
        {lowEverydayPricingMessage.display && <LowEveryPriceMatchInfoMessage />}
      </div>
    </div>
  );
};

export default memo(InfoMessagesWrapper);
