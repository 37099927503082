import { createMetaTag, getDocument, getWindow } from '#/lib/html-helpers';

export function updatePageDescription(description) {
  if (typeof getWindow() === 'undefined') return;

  const document = getDocument();
  const metaElement = document.head.querySelector("meta[name='description']");
  description = description || '';

  if (metaElement) {
    metaElement.setAttribute('content', description);
  } else {
    createMetaTag({
      name: 'description',
      content: description
    });
  }
}

export function updateCanonicalUrl(canonicalUrl) {
  if (typeof getWindow() === 'undefined') return;

  const document = getDocument();
  let canonicalElement = document.head.querySelector("link[rel='canonical']");

  if (!canonicalUrl) {
    if (canonicalElement) {
      document.head.removeChild(canonicalElement);
    }

    return;
  }

  canonicalElement = canonicalElement || document.createElement('link');
  canonicalElement.setAttribute('rel', 'canonical');
  canonicalElement.setAttribute('href', canonicalUrl);

  if (!canonicalElement.parentNode) {
    document.head.appendChild(canonicalElement);
  }
}

export function updatePageTitle(title) {
  if (typeof getWindow() === 'undefined') return;

  const document = getDocument();
  const titleElement =
    document.head.querySelector('title') || document.createElement('title');

  title = title || '';

  titleElement.textContent = title;

  if (!titleElement.parentNode) {
    document.head.appendChild(titleElement);
  }
}

export function updateRobots(robots) {
  if (typeof getWindow() === 'undefined') return;

  const document = getDocument();
  const metaElement = document.head.querySelector("meta[name='robots']");
  robots = robots || 'index';

  if (metaElement) {
    metaElement.setAttribute('content', robots);
  } else {
    createMetaTag({
      name: 'robots',
      content: robots
    });
  }
}

export function updateStructuredData(structuredData) {
  if (typeof getWindow() === 'undefined') return;

  const document = getDocument();
  const scriptElement =
    document.head.querySelector("script[type='application/ld+json']") ||
    document.createElement('script');

  scriptElement.textContent = structuredData || '';

  if (!scriptElement.parentNode) {
    scriptElement.setAttribute('type', 'application/ld+json');
    document.head.appendChild(scriptElement);
  }
}

export function updateAltLanguageLinks(alternateLanguageLinks) {
  if (typeof getWindow() === 'undefined') return;

  const document = getDocument();
  const alternateLinkElements = document.head.querySelectorAll(
    "link[rel='alternate'][hreflang]"
  );

  if (alternateLinkElements) {
    for (let i = 0; i < alternateLinkElements.length; i++) {
      document.head.removeChild(alternateLinkElements[i]);
    }
  }

  if (alternateLanguageLinks) {
    for (let i = 0; i < alternateLanguageLinks.length; i++) {
      const link = document.createElement('link');
      const altLangLink = alternateLanguageLinks[i];

      link.setAttribute('rel', 'alternate');
      link.setAttribute('hreflang', altLangLink.language);
      link.setAttribute('href', altLangLink.url);

      document.head.appendChild(link);
    }
  }
}
