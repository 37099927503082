import React from 'react';
import PropTypes from 'prop-types';
import { connect } from '../../lib/render/connect-deep-compare';
import helpers from '../../lib/decorators/helpers';
import PaginationButton from './pagination-button';
import { updateParamsInUrl } from '../../lib/url/url-utils';
import { getCurrentUrl } from '../../reducers/app';
import { getItemsPerPage } from '../../reducers/results';
import {
  DEFAULT_PAGE_NAME,
  MULTI_SEARCH_PAGE,
  PAGE_BUTTONS,
  PAGE_NUMBER
} from '../../constants/query-strings';

const handleClick = event => {
  event.nativeEvent.stopPropagation();
};

const mapStateToProps = state => ({
  currentUrl: getCurrentUrl(state),
  itemsPerPage: getItemsPerPage(state)
});

function generateUrl(
  currentUrl,
  pageNo,
  itemsPerPage,
  pageQueryName = DEFAULT_PAGE_NAME
) {
  return updateParamsInUrl(currentUrl, { [pageQueryName]: pageNo });
}

function renderPageButtonsDisplayMode(
  currentPage,
  currentUrl,
  pageQueryName,
  t,
  spread,
  total,
  pageButtons,
  itemsPerPage,
  currentPageLabel,
  goToPageXLabel
) {
  return [
    <PaginationButton
      highlight={currentPage === 1}
      disabled={currentPage === 1}
      href={generateUrl(currentUrl, 1, itemsPerPage, pageQueryName)}
      key={1}
      value="1"
      label={currentPage === 1 ? t(currentPageLabel) : `${t(goToPageXLabel)} 1`}
      onClickHandler={handleClick}
    />,
    currentPage > spread + 3 && (
      <li key={`${currentPage + 1}-${spread + 3}`}>
        <span className="pagination--ellipsis icon-dots" />
      </li>
    ),
    pageButtons,
    currentPage <= total - (spread + 3) && (
      <li key={`${currentPage + 1}-${total - (spread + 3)}`}>
        <span className="pagination--ellipsis icon-dots" />
      </li>
    ),
    total > 1 && (
      <PaginationButton
        highlight={currentPage === total}
        disabled={currentPage === total}
        href={generateUrl(currentUrl, total, itemsPerPage, pageQueryName)}
        key={total}
        value={String(total)}
        label={currentPage !== total ? `${t(goToPageXLabel)} ${total}` : null}
        onClickHandler={handleClick}
      />
    )
  ];
}

function renderPageNumberDisplayMode(currentPage, totalPages, t) {
  return (
    <span className="pagination--slim-page-selector">
      {t('navigation:slim-pagination-page-count', { currentPage, totalPages })}
    </span>
  );
}

const PageSelector = props => {
  const {
    currentPageNumber: currentPage,
    currentUrl,
    itemsPerPage,
    spread,
    paginationLabels,
    totalPages: total
  } = props;
  const currentPageLabel = paginationLabels.currentPageLabel;
  const goToPageXLabel = paginationLabels.changePageLabel;
  const pageButtons = [];
  const prevUrl = generateUrl(
    currentUrl,
    currentPage - 1,
    itemsPerPage,
    props.pageQueryName
  );
  const nextUrl = generateUrl(
    currentUrl,
    currentPage + 1,
    itemsPerPage,
    props.pageQueryName
  );
  let i = currentPage - spread;
  let len = currentPage + spread;

  if (i === 3) {
    //  prevent 1 ... 3 pagination
    i = 2;
  }

  if (len === total - 2) {
    //  prevent 8 ... 10 pagination
    len++;
  }

  for (; i <= len; i++) {
    if (i > 1 && i < total) {
      pageButtons.push(
        <PaginationButton
          highlight={i === currentPage}
          disabled={i === currentPage}
          href={generateUrl(currentUrl, i, itemsPerPage, props.pageQueryName)}
          key={i}
          value={String(i)}
          onClickHandler={handleClick}
          label={
            i === currentPage
              ? props.t(currentPageLabel)
              : `${props.t(goToPageXLabel)} ${i}`
          }
        />
      );
    }
  }

  function handlePageClick(e, change, url) {
    handleClick(e);

    if (typeof props.onPageChange === 'function') {
      e.preventDefault();
      props.onPageChange(currentPage + change, url);
    }
  }

  function handlePrevPageClick(e) {
    handlePageClick(e, -1, prevUrl);
  }

  function handleNextPageClick(e) {
    handlePageClick(e, 1, nextUrl);
  }

  return (
    <nav className="pagination--page-selector-wrapper">
      <ul>
        <PaginationButton
          className="prev-next"
          disabled={currentPage <= 1}
          href={prevUrl}
          icon="icon-icon_whitechevronleft"
          key="prev"
          label={props.t(currentPageLabel)}
          onClickHandler={handlePrevPageClick}
        />
        {props.displayMode === PAGE_BUTTONS
          ? renderPageButtonsDisplayMode(
              currentPage,
              currentUrl,
              props.pageQueryName,
              props.t,
              spread,
              total,
              pageButtons,
              itemsPerPage,
              currentPageLabel,
              goToPageXLabel
            )
          : renderPageNumberDisplayMode(currentPage, total, props.t)}
        <PaginationButton
          className="prev-next"
          href={nextUrl}
          disabled={currentPage === total}
          icon="icon-icon_whitechevronright"
          key="next"
          label={props.t(goToPageXLabel)}
          onClickHandler={handleNextPageClick}
        />
      </ul>
    </nav>
  );
};

PageSelector.propTypes = {
  currentPageNumber: PropTypes.number.isRequired,
  currentUrl: PropTypes.string.isRequired,
  displayMode: PropTypes.oneOf([PAGE_BUTTONS, PAGE_NUMBER]),
  itemsPerPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func,
  pageQueryName: PropTypes.oneOf([DEFAULT_PAGE_NAME, MULTI_SEARCH_PAGE]), // e.g: page/multiSearchPage
  paginationLabels: PropTypes.shape({
    currentPageLabel: PropTypes.string.isRequired,
    changePageLabel: PropTypes.string.isRequired
  }),
  spread: PropTypes.number, // The number of buttons to display each side of the current page
  t: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired
};

PageSelector.defaultProps = {
  displayMode: PAGE_BUTTONS,
  pageQueryName: DEFAULT_PAGE_NAME,
  spread: 2
};

export default connect(mapStateToProps)(helpers(['c', 't'])(PageSelector));
