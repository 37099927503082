import {
  getBookedSlotStatus,
  getCurrentSlotGroup,
  getSelectedLocation,
  getSelectedShoppingMethod,
  getSlotsPendingStatus,
} from '#/reducers/slot';
import {
  getIsAmendBasket,
  getLastSelectedSlot,
  getTrolleyDeliveryAddress,
  getTrolleyShoppingMethod,
  getTrolleySlotGroup,
} from '#/selectors/trolley';
import { isDeliveryShoppingMethod, isOnDemandShoppingMethod } from '#/lib/shopping-method-util';

import { AddressData } from '#/reducers/addresses';
import DeliveryContentSection from '#/components/slots/delivery-content-section';
import React from 'react';
import { Slot } from '#/lib/records/slot.defs';
import SlotSelector from '#/components/slots/slot-selector';
import { TrolleyState } from '#/custom-typings/redux-store/trolley.defs';
import { connect } from '#/lib/render/connect-deep-compare';
import { getRecentNonMarketplacePendingOrder } from '#/selectors/order-list-details';
import { hasDeliverySaverPlan } from '#/reducers/customer';
import helpers from '#/lib/decorators/helpers';
import { ShoppingMethod } from '#/constants/shopping-methods';
import { SlotGroup } from '#/custom-typings/redux-store/slot.defs';

type SelectedLocation = {
  displayOrder: number;
  locationId: string;
  isSelected: boolean;
};

export type StateProps = {
  selectedShoppingMethod: ShoppingMethod;
  currentShoppingMethod: ShoppingMethod;
  isDelivery: boolean;
  trolleyDeliveryAddress: AddressData;
  areSlotsPending: boolean;
  bookedSlot: Slot | undefined;
  currentSlotGroup: SlotGroup;
  slotGroup: number | null;
  selectedLocation: SelectedLocation;
  showSlotExpiredNotification: boolean;
  isAmendBasket: boolean;
  slotDeliveryAddress: AddressData;
  isBlockedOrBusinessAddress: boolean;
  isOnDemandDelivery: boolean;
  showDeliverySaverMessage: boolean;
};

const mapStateToProps = (state: Store): StateProps => {
  const selectedShoppingMethod = getSelectedShoppingMethod(state);
  const currentShoppingMethod = getTrolleyShoppingMethod(state);
  const slot = getLastSelectedSlot(state);
  const bookedSlot = currentShoppingMethod === selectedShoppingMethod ? slot : undefined;
  const isAmendBasket = getIsAmendBasket(state);
  const recentPendingOrder = getRecentNonMarketplacePendingOrder(state);
  const isDeliverySaverPlan = hasDeliverySaverPlan(state);
  const trolleyDeliveryAddress = getTrolleyDeliveryAddress(state);
  const { isBusinessAddress, isBlockedAddress } = trolleyDeliveryAddress;

  return {
    selectedShoppingMethod,
    currentShoppingMethod,
    isDelivery: isDeliveryShoppingMethod(selectedShoppingMethod),
    trolleyDeliveryAddress,
    areSlotsPending: getSlotsPendingStatus(state),
    bookedSlot,
    currentSlotGroup: getCurrentSlotGroup(state),
    slotGroup: getTrolleySlotGroup(state),
    selectedLocation: getSelectedLocation(state),
    showSlotExpiredNotification: getBookedSlotStatus(state),
    isAmendBasket,
    slotDeliveryAddress: isAmendBasket ? recentPendingOrder?.address : trolleyDeliveryAddress,
    isBlockedOrBusinessAddress: isBusinessAddress || isBlockedAddress,
    isOnDemandDelivery: isOnDemandShoppingMethod(selectedShoppingMethod),
    showDeliverySaverMessage: isDeliverySaverPlan,
  };
};

type TOwnProps = {
  t: (key: string, data?: object) => string;
  selectedShoppingMethod: TrolleyState['shoppingMethod'];
  currentShoppingMethod: TrolleyState['shoppingMethod'];
  isDelivery: boolean;
  isOnDemandDelivery: boolean;
  trolleyDeliveryAddress: AddressData | undefined;
  areSlotsPending: boolean;
  bookedSlot?: Slot;
  currentSlotGroup: object | number;
  slotGroup: number | null;
  slotDeliveryAddress: AddressData | undefined;
  selectedLocation: object | string;
  showSlotExpiredNotification: boolean;
  isAmendBasket: boolean;
  isBlockedOrBusinessAddress: boolean;
  isSlotExpired: boolean;
  showDeliverySaverMessage: boolean;
};

const SlotSelectorSection: React.FC<TOwnProps> = (props: TOwnProps) => {
  const {
    t: translate,
    selectedShoppingMethod,
    currentShoppingMethod,
    trolleyDeliveryAddress,
    areSlotsPending,
    bookedSlot,
    currentSlotGroup,
    slotGroup,
    slotDeliveryAddress,
    selectedLocation,
    showSlotExpiredNotification,
    isAmendBasket,
    isBlockedOrBusinessAddress,
    isDelivery,
    isOnDemandDelivery,
    isSlotExpired,
  } = props;

  const getUndeliverableMessage = (trolleyDeliveryAddress: AddressData): string => {
    const { isBusinessAddress, name: address } = trolleyDeliveryAddress;
    return isBusinessAddress
      ? translate('slots:delivery.business-address-no-slots')
      : translate('slots:delivery.blocked-address-no-slots', {
          address,
        });
  };

  if ((isDelivery || isOnDemandDelivery) && isBlockedOrBusinessAddress && trolleyDeliveryAddress) {
    const undeliverableMessage = getUndeliverableMessage(trolleyDeliveryAddress);
    return (
      <div className="book-a-slot--delivery-address">
        <div className="undeliverable-address-warning">{undeliverableMessage}</div>
      </div>
    );
  }

  if (isOnDemandDelivery) {
    const DeliveryContentSectionProps = {
      areSlotsPending,
      shoppingMethod: selectedShoppingMethod,
      isSlotExpired,
    };
    return <DeliveryContentSection {...DeliveryContentSectionProps} />;
  }

  return (
    <div>
      <SlotSelector
        areSlotsPending={areSlotsPending}
        bookedSlot={bookedSlot}
        currentShoppingMethod={currentShoppingMethod}
        currentSlotGroup={currentSlotGroup || slotGroup}
        deliveryAddress={slotDeliveryAddress}
        isAmendBasket={isAmendBasket}
        selectedLocation={selectedLocation}
        showSlotExpiredNotification={showSlotExpiredNotification}
        shoppingMethod={selectedShoppingMethod}
      />
    </div>
  );
};

export default helpers(['t'])(connect(mapStateToProps)(SlotSelectorSection));
