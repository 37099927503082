import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class OverlaySpinner extends React.Component {
  static propTypes = {
    hasSpinner: PropTypes.bool,
    isLoading: PropTypes.bool,
    largeOverlay: PropTypes.bool
  };

  static defaultProps = {
    hasSpinner: true
  };

  render() {
    const { largeOverlay } = this.props;
    return (
      <div>
        {this.props.hasSpinner && this.props.isLoading ? (
          <div className="overlay-spinner--spinner" />
        ) : null}
        <div
          data-testid={this.props.isLoading && 'loading-spinner-synthetics'}
          className={classNames({
            'overlay-spinner--overlay': true,
            open: this.props.isLoading,
            'overlay-spinner--large': largeOverlay
          })}
          data-auto="overlay-spinner"
        />
      </div>
    );
  }
}

export default OverlaySpinner;
