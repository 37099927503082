export const WITHOUT_BAGS = 'withoutBags';
export const WITH_BAGS = 'withBags';

export const DEFAULT_LAYOUT = 'default';
export const FEEDBACK_BTN_AND_TEXT_LAYOUT = 'feedback-btn-and-text';
export const WHY_BTN_AND_TEXT_LAYOUT = 'why-btn-and-text';
export const WHY_BTN_LAYOUT = 'why-btn';

export const BAGGING_OPTION = 'bagging option';

export const PREF_WITHOUT_BAGS = 'without bags';
export const PREF_WITH_BAGS = 'with bags';
