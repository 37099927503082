import { CustomerUnitChoice, SubstitutionOption } from '#/lib/records/item.defs';

export type ItemPayload = {
  adjustment?: boolean;
  catchWeight?: number;
  gridPos?: number;
  id?: string;
  identifier?: string;
  newValue: number;
  newUnitChoice?: CustomerUnitChoice;
  oldUnitChoice?: CustomerUnitChoice;
  oldValue: number;
  pageId?: string;
  position?: number;
  seedProduct?: string;
  substitutionOption?: SubstitutionOption | null;
};

export const defaults = {
  catchWeight: undefined,
  gridPos: undefined,
  id: undefined,
  identifier: undefined,
  newUnitChoice: undefined,
  newValue: 0,
  oldUnitChoice: undefined,
  oldValue: 0,
  pageId: undefined,
  position: undefined,
  seedProduct: undefined,
};

const createItemPayload = (args: Partial<ItemPayload>): ItemPayload => Object.assign({ ...defaults }, args);

export default createItemPayload;
