/**
 * This file serves an important part in Slots page flow and functionality.
 * Whenever making changes to this file make sure to update the related
 * documentation diagrams present in dir: web/docs/slots
 *
 * @author beetlejuice
 */

import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import moment from 'moment-timezone';
import { request } from '#/lib/client-fetch';
import { COLLECTION, ShoppingMethod } from '#/constants/shopping-methods';
import { BOOK } from '#/constants/mutate-fulfilment-types';
import { getTrolley } from '#/actions/trolley/trolley-action-creators';
import { chooseStickyBar, openModal } from '#/actions/ui-action-creators';
import { formatDate } from '#/lib/slot/slot-range-utils';
import { setSlotExpiryTimer, slotCurrentlyBooked } from '#/lib/slot/slot-utils';
import {
  getLanguageLink,
  getCurrency,
  getTimezone,
  getIsDesktop,
  getAppRegion,
  getApigeeMangoEndpoint,
  getApigeeMangoApiKey,
  getIsMobile,
} from '#/reducers/app';
import { getSlotExpiryThresholdMinutes } from '#/reducers/ui';
import { getAtrc } from '#/reducers/user';
import {
  warningTypes,
  CHANGE_DELIVERY_ADDRESS,
  CHANGING_DELIVERY_ADDRESS,
  WarningTypes,
} from '#/constants/slot-warning-modal-types';
import { addressSearch, AddressSearchResponse } from '#/lib/requests/addressSearch/address-search';

import {
  RECEIVE_FULFILMENT_OPTIONS_DETAIL,
  RECEIVE_FULFILMENT_ESTIMATED_ARRIVAL,
  NEW_SLOT_DATA_PENDING,
  CHANGE_SLOT_DATE,
  CHANGE_SLOT_TYPE,
  CHANGING_ADDRESS,
  CLOSE_CHANGE_SLOT_TYPE,
  NO_SLOT_DATA_PENDING,
  UPDATE_USER_STORE,
  CHANGE_SHOPPING_METHOD,
  UPDATE_SLOT_GROUP,
  UPDATE_SELECTED_SLOT_GROUP,
  CLEAR_SUGGESTION_SELECTED_ADDRESS,
  CLEAR_COLLECTION_SELECTED_LOCATION,
  DISMISS_DS_ACQUISITION_BANNER,
  OPEN_SLOT_CHANGED_IN_AMEND_MODAL,
  CLOSE_SLOT_CHANGED_IN_AMEND_MODAL,
} from '#/constants/action-types';
import { bookedSlotAnalyticsEvent } from '#/analytics/types/slot';
import commonActionRejectionsHandler from '#/actions/common-action-rejections-handler';
import {
  getSelectedShoppingMethod,
  getActiveWeek,
  getIsBookedSlotAvailableInState,
  getSelectedWeekSlots,
  getSelectedDateSlots,
  getSelectedLocationId,
} from '#/reducers/slot';
import { NOW } from '#/analytics/constants';
import { getTaxonomy } from '#/actions/taxonomy-action-creators';
import { getCMSNav } from '#/actions/taxonomy-action-creators';
import { updateSlots } from '#/actions/slot-action-creators/update-slots';
import { addToUrlAndDispatchModal, removeFromUrlAndDispatchModal } from '#/lib/url/modal-utils';
import { sessionStore } from '#/lib/data-store/client-store';
import {
  CHANGE_SLOT_TYPE_MODAL,
  GENERIC_ERROR_MODAL,
  INCORRECT_PHONE_NUMBER_MODAL,
  FAV_QUICK_BASKET_MODAL,
  SLOT_CHANGED_IN_AMEND_MODAL,
} from '#/constants/modal-names';
import {
  getCurrentValidSlot,
  hasBookedSlot,
  getTrolleyShoppingMethod,
  getStoreId,
  hasAtLeastOneItemUnavailable,
  getItems,
  getIsAmendBasket,
} from '#/selectors/trolley';
import { getSlotStart } from '#/selectors/slot';
import { Slot } from '#/lib/records/slot.defs';
import { Dispatch, GetStore } from '#/custom-typings/redux-store/common';
import { Trolley } from '#/lib/records/trolley.defs';
import { FulfilmentOptionsDetail, SlotGroup } from '#/custom-typings/redux-store/slot.defs';
import { FulfilmentEstimatedArrival } from '#/resources/fulfilment-estimated-arrival.defs';
import { INCORRECT_PHONE_NUMBER } from '#/constants/error-codes';
import { emitSlotOp } from '#/analytics/bertie/events';
import { getDeliveryAddressForTrollyId } from '#/selectors/detailed-addresses';
import { BASKET_ITEMS_UNAVAILABLE_MODAL_NAME } from '#/experiments/oop-1583/constants';
import { showItemUnavailableModal } from '#/experiments/oop-1583/selectors';
import { emitBasketModalAnalyticsEvent } from '#/experiments/oop-1583/helpers/analytics';
import { ITEM_UNAVAILABLE_MODAL_IMPRESSION } from '#/experiments/oop-1583/constants';
import { SHOWN_SLOT_REBOOK_MODAL_KEY, SHOWN_SLOT_REMIND_MODAL_KEY } from '#/experiments/oop-2210/constants';
import { AMEND_MODAL_ANALYTICS_EVENT_TYPE } from '#/constants/slot-amend-change';
import { basicEvent } from '#/analytics/types/basic';
import analyticsBus from '#/analytics/analyticsBus';
import { UK } from '#/constants/common';

const changeSelectedDate = (date: {
  selectedDate: string;
  timezone: string;
}): {
  type: typeof CHANGE_SLOT_DATE;
  value: {
    selectedDate: string;
    timezone: string;
  };
} => ({
  type: CHANGE_SLOT_DATE,
  value: date,
});

const changeSelectedShoppingMethod = (
  shoppingMethod: ShoppingMethod,
): { type: typeof CHANGE_SHOPPING_METHOD; value: ShoppingMethod } => ({
  type: CHANGE_SHOPPING_METHOD,
  value: shoppingMethod,
});

const noSlotDataPending = (timezone: string): { type: typeof NO_SLOT_DATA_PENDING; value: { timezone: string } } => ({
  type: NO_SLOT_DATA_PENDING,
  value: { timezone },
});

export const slotDataPending = (): { type: typeof NEW_SLOT_DATA_PENDING } => ({
  type: NEW_SLOT_DATA_PENDING,
});

const updateUserStore = (value: unknown): { type: typeof UPDATE_USER_STORE; value: unknown } => ({
  type: UPDATE_USER_STORE,
  value,
});

export const updateSlotGroup = (value: unknown): { type: typeof UPDATE_SLOT_GROUP; value: unknown } => ({
  type: UPDATE_SLOT_GROUP,
  value,
});

/**
 *  Slot modal dispatch functions
 */
export const showOrderTypeChangeWarning = (slot: Record<string, unknown>): ((dispatch: Dispatch) => void) =>
  addToUrlAndDispatchModal(
    CHANGE_SLOT_TYPE_MODAL,
    null,
    {
      type: CHANGE_SLOT_TYPE,
      value: slot,
    },
    CLOSE_CHANGE_SLOT_TYPE,
  );

export const closeChangeOrderTypeModal = (): unknown =>
  removeFromUrlAndDispatchModal({
    type: CLOSE_CHANGE_SLOT_TYPE,
  });

export const orderTypeChangeCookieAccepted = (cookieType: string): { type: string } => ({
  type: (warningTypes as { [key: string]: WarningTypes })[cookieType],
});

export const showChangingAddressWarning = (value: unknown): ((dispatch: Dispatch) => unknown) =>
  addToUrlAndDispatchModal(CHANGE_SLOT_TYPE_MODAL, null, {
    type: CHANGING_ADDRESS,
    value,
  });

export const changeShoppingMethod = (
  selectedShoppingMethod: ShoppingMethod,
  bookedSlotShoppingMethod: ShoppingMethod,
) => (dispatch: Dispatch, getState: GetStore): void => {
  dispatch(slotDataPending());
  dispatch(changeSelectedShoppingMethod(selectedShoppingMethod));

  const state = getState();
  const {
    trolley: { bookedSlot },
  } = state;

  const timezone = getTimezone(state);
  const bookedSlotDate = getSlotStart(bookedSlot);

  // If you are changing delivery method we need to make
  // sure the tab with the users slot is open
  const isDeliverySlotBooked =
    bookedSlotDate && bookedSlotShoppingMethod === selectedShoppingMethod && slotCurrentlyBooked(bookedSlot);

  const selectedDate = isDeliverySlotBooked ? formatDate(bookedSlotDate) : getActiveWeek(state);

  dispatch(changeSelectedDate({ selectedDate, timezone }));
};

export const updateSlotsForLocation = (location: string) => async (dispatch: Dispatch): Promise<void> => {
  await dispatch(updateSlots({ location }));
};

export const changeSlotDate = (date: string) => async (dispatch: Dispatch, getState: GetStore): Promise<void> => {
  dispatch(
    changeSelectedDate({
      selectedDate: date,
      timezone: getTimezone(getState()),
    }),
  );
  await dispatch(updateSlots({ date }));
  emitSlotEvent(getState());
};

export const changeSlotGroup = (nextGroup: SlotGroup, date: string) => async (dispatch: Dispatch): Promise<void> => {
  dispatch({
    type: UPDATE_SELECTED_SLOT_GROUP,
    value: {
      slotGroup: nextGroup,
      date,
    },
  });

  await dispatch(updateSlots({ date, slotGroup: nextGroup }));
};

function getBookSlotRequestPayload(slot: Slot): Slot {
  const payload = Object.assign({}, slot);

  delete payload.deliveryAddress;
  delete payload.deliveryAddresses;

  return payload;
}

let clearSlotExpiryTimer: Function | undefined;

// Required to update the trolley state so that it reflects the correctly booked slot
const updateTrolleyState = (prevStoreId: string, callback?: Function) => async (
  dispatch: Dispatch,
  getState: GetStore,
): Promise<void> => {
  await dispatch(
    getTrolley((trolley: Trolley) => {
      const { storeId: currentStoreId, slot: bookedSlot } = trolley;
      const state = getState();

      dispatch(updateUserStore(currentStoreId));

      if (typeof clearSlotExpiryTimer === 'function') {
        clearSlotExpiryTimer();
      }

      clearSlotExpiryTimer = setSlotExpiryTimer(bookedSlot, getSlotExpiryThresholdMinutes(state), () => {
        dispatch(chooseStickyBar());
      });

      // Refresh Taxonomy when storeId differs from last booked storeId
      if (currentStoreId !== prevStoreId) {
        dispatch(getTaxonomy({ includeChildren: true }));
        dispatch(getCMSNav());
      }

      if (typeof callback === 'function') {
        callback();
      }
    }),
  );
};

export const getShouldOpenQuickBasketSlotInfoModal = (state: Store): boolean => {
  const items = getItems(state) || [];
  const isTrolleyEmpty = !items.length;
  const isAmendBasket = getIsAmendBasket(state);
  const appRegion = getAppRegion(state);

  return appRegion === UK && !isAmendBasket && isTrolleyEmpty && !getIsMobile(state);
};

export const openQuickBasketModal = (dispatch: Dispatch, state: Store): void => {
  if (getShouldOpenQuickBasketSlotInfoModal(state)) {
    dispatch(openModal(FAV_QUICK_BASKET_MODAL, null, false));
  }
};

export const bookSlot = (slot: Slot) => (dispatch: Dispatch, getState: GetStore): Promise<unknown> => {
  dispatch(slotDataPending());
  const state = getState();
  const {
    trolley: { storeId: prevStoreId },
  } = state;

  return request
    .put(getLanguageLink(state, '/slots/current?_method=PUT'), {
      body: JSON.stringify(getBookSlotRequestPayload(slot)),
    })
    .then(async () => {
      await dispatch(updateTrolleyState(prevStoreId, () => sessionStore?.remove(SHOWN_SLOT_REBOOK_MODAL_KEY)));

      bookedSlotAnalyticsEvent(
        (slot as unknown) as {
          start: moment.Moment | string | null;
          end: moment.Moment | string | null;
          charge: number;
          currency: {
            symbolPosition: string;
            symbol: string;
          } | null;
          shoppingMethod: ShoppingMethod;
          timezone: string;
          locationId: string;
          deliverySaverCurrent: { saving: number };
        },
      );

      await dispatch(
        updateSlots(
          {},
          {
            forceUpdate: hasBookedSlot(state) && !getIsBookedSlotAvailableInState(state),
          },
        ),
      );

      dispatch(noSlotDataPending(getTimezone(state)));
      openQuickBasketModal(dispatch, state);
    })
    .catch(res => {
      if (res.status !== 401 && res.status !== 503) {
        return dispatch({ type: GENERIC_ERROR_MODAL });
      }
      commonActionRejectionsHandler(res, dispatch, () => dispatch(updateTrolleyState(prevStoreId)));
    });
};

export const cancelSlot = (cb: Function) => (dispatch: Dispatch, getState: GetStore): Promise<unknown> => {
  dispatch(slotDataPending());

  const state = getState();
  const shoppingMethod = getSelectedShoppingMethod(state);

  return request
    .del(getLanguageLink(state, '/slots/current'))
    .then(() => {
      dispatch(
        getTrolley((trolley: Trolley) => {
          // We shouldn't really put skipCache here as it will go and fetch the latest slots which is
          // not great for perf but there is a bug where the selected address changes and we end
          // up with a mismatch of slots/location data, collection only.
          (dispatch as (asyncAction: ThunkAction<object, Store, undefined, Action>) => Promise<unknown>)(
            updateSlots(
              {},
              {
                forceUpdate: !getIsBookedSlotAvailableInState(state.trolley) || shoppingMethod === COLLECTION,
              },
            ),
          ).then(() => {
            if (trolley.storeId === '0') {
              dispatch(updateUserStore('0'));
            }

            dispatch(noSlotDataPending(getTimezone(state)));
          });
        }),
      );
    })
    .then(() => {
      if (cb) {
        cb();
      }
    })
    .catch(res => commonActionRejectionsHandler(res, dispatch));
};

const getParsedDeliveryPreferences = (deliveryPreferences: string): object | boolean => {
  try {
    return JSON.parse(deliveryPreferences);
  } catch (e) {
    return false;
  }
};

export const clearSuggestionSelectedAddress = (): { type: typeof CLEAR_SUGGESTION_SELECTED_ADDRESS } => ({
  type: CLEAR_SUGGESTION_SELECTED_ADDRESS,
});

export const clearCollectionSelectedLocation = (): { type: typeof CLEAR_COLLECTION_SELECTED_LOCATION } => ({
  type: CLEAR_COLLECTION_SELECTED_LOCATION,
});

export const checkSlotWarnings = (currentSlot: Slot) => (dispatch: Dispatch, getState: GetStore): void => {
  const { changeSlotInfo } = getState().ui;

  if (changeSlotInfo) {
    if (changeSlotInfo.modalType === CHANGING_DELIVERY_ADDRESS) {
      dispatch(showChangingAddressWarning(changeSlotInfo));
    } else {
      let showWarning = false;

      if (changeSlotInfo.shoppingMethod) {
        showWarning = true;
      }
      if (changeSlotInfo.modalType === CHANGE_DELIVERY_ADDRESS) {
        showWarning = true;
      }
      if (changeSlotInfo.deliveryPreferences) {
        const parsedDeliveryPreferences = getParsedDeliveryPreferences(changeSlotInfo.deliveryPreferences as string);

        if (parsedDeliveryPreferences) {
          const locationId = ((currentSlot as Slot).locationId as string).split('-')[1];

          if (locationId !== (parsedDeliveryPreferences as { id: string }).id) {
            showWarning = true;
          }
        }
      }

      if (showWarning) {
        dispatch(showOrderTypeChangeWarning(changeSlotInfo));
      }
    }
  }
};

export const fulfilmentOptionsDetail = (
  fulfilmentOptionsDetail: FulfilmentOptionsDetail,
): { type: typeof RECEIVE_FULFILMENT_OPTIONS_DETAIL; value: FulfilmentOptionsDetail } => ({
  type: RECEIVE_FULFILMENT_OPTIONS_DETAIL,
  value: fulfilmentOptionsDetail,
});

export const fulfilmentEstimatedArrival = (
  fulfilmentEstimatedArrival: FulfilmentEstimatedArrival,
): { type: typeof RECEIVE_FULFILMENT_ESTIMATED_ARRIVAL; value: FulfilmentEstimatedArrival } => ({
  type: RECEIVE_FULFILMENT_ESTIMATED_ARRIVAL,
  value: fulfilmentEstimatedArrival,
});

export const fireOnMutateFulfilment = (state: Store): void => {
  return bookedSlotAnalyticsEvent({
    ...getCurrentValidSlot(state),
    currency: getCurrency(state),
    timezone: getTimezone(state),
    shoppingMethod: getTrolleyShoppingMethod(state),
  } as {
    start: moment.Moment | string | null;
    end: moment.Moment | string | null;
    charge: number;
    currency: {
      symbolPosition: string;
      symbol: string;
    } | null;
    shoppingMethod: ShoppingMethod;
    timezone: string;
    locationId: string;
    deliverySaverCurrent: { saving: number };
  });
};

const fulfilmentGenericErrorHandler = (
  err: Error,
  dispatch: Dispatch,
  state: Store,
  showGenericErrorMessage: boolean,
): { hasError: boolean } => {
  dispatch(noSlotDataPending(getTimezone(state)));

  if (showGenericErrorMessage) {
    commonActionRejectionsHandler(err, dispatch, () => {
      dispatch({ type: GENERIC_ERROR_MODAL });
    });
  }

  return { hasError: true };
};

const fulfilmentPhoneNumberErrorHandler = (dispatch: Dispatch, state: Store): { hasError: boolean } => {
  dispatch({ type: INCORRECT_PHONE_NUMBER_MODAL });
  dispatch(noSlotDataPending(getTimezone(state)));
  return { hasError: true };
};

export const openUnavailableItemModal = (dispatch: Dispatch, state: Store): void => {
  if (showItemUnavailableModal(state) && hasAtLeastOneItemUnavailable(state)) {
    const addToUrl = false,
      modalData = null;
    emitBasketModalAnalyticsEvent(NOW, ITEM_UNAVAILABLE_MODAL_IMPRESSION);
    dispatch(openModal(BASKET_ITEMS_UNAVAILABLE_MODAL_NAME, modalData, addToUrl));
  }
};

export const mutateFulfilment = (
  payload: { [key: string]: string | undefined | null },
  showGenericErrorMessage = false,
) => (dispatch: Dispatch, getState: GetStore): Promise<{ hasError?: boolean }> => {
  dispatch(slotDataPending());
  const state = getState();
  const {
    trolley: { storeId: prevStoreId },
  } = state;

  return request
    .post(getLanguageLink(state, '/slots/mutate'), {
      body: JSON.stringify(payload),
    })
    .then(async res => {
      const errors = res.fulfilment?.errors;
      const hasError = errors && Array.isArray(errors) && errors.length > 0;

      if (hasError) {
        let phoneNumberError, genericError;

        errors.forEach((err: { code: string; status: number }) => {
          if (err.code === INCORRECT_PHONE_NUMBER && err.status === 400) {
            phoneNumberError = err;
          } else {
            genericError = err;
          }
        });

        if (phoneNumberError) {
          return fulfilmentPhoneNumberErrorHandler(dispatch, state);
        }

        if (genericError) {
          return fulfilmentGenericErrorHandler(genericError, dispatch, state, showGenericErrorMessage);
        }
      } else {
        await dispatch(
          updateTrolleyState(prevStoreId, () => {
            const updatedState = getState();
            if (payload.slotAction === BOOK) {
              openUnavailableItemModal(dispatch, updatedState);
              openQuickBasketModal(dispatch, state);
              fireOnMutateFulfilment(updatedState);
            }
            dispatch(noSlotDataPending(getTimezone(state)));
            sessionStore?.remove(SHOWN_SLOT_REBOOK_MODAL_KEY);
            sessionStore?.remove(SHOWN_SLOT_REMIND_MODAL_KEY);
          }),
        );

        return res;
      }
    })
    .catch(err => {
      dispatch(noSlotDataPending(getTimezone(state)));

      if (showGenericErrorMessage) {
        commonActionRejectionsHandler(err, dispatch, () => {
          dispatch({ type: GENERIC_ERROR_MODAL });
        });
      }

      return { hasError: true };
    });
};

function emitSlotEvent(state: Store): void {
  const isDesktop = getIsDesktop(state);
  const selectedWeekSlots = getSelectedWeekSlots(state);
  const selectedDateSlots = getSelectedDateSlots(state);
  const selectedShoppingMethod = getSelectedShoppingMethod(state);
  const storeId = getStoreId(state);
  const locationId = getSelectedLocationId(state) as string;
  const addressId = getDeliveryAddressForTrollyId(state) as string;
  const timezone = getTimezone(state);

  emitSlotOp(
    isDesktop ? selectedWeekSlots : selectedDateSlots,
    selectedShoppingMethod,
    storeId,
    locationId,
    addressId,
    timezone,
  );
}

export const getSuggestedAddresses = (keyword: string) => async (
  dispatch: Dispatch,
  getState: GetStore,
): Promise<AddressSearchResponse | { error?: boolean }> => {
  const state = getState();
  const requestConfig = {
    apiKey: getApigeeMangoApiKey(state),
    endpoint: getApigeeMangoEndpoint(state),
    region: getAppRegion(state),
    atrc: getAtrc(state),
  };

  return await addressSearch({ keyword }, requestConfig);
};

export const dismissDeliverySaverAcquisitionBanner = () => (dispatch: Dispatch): void => {
  dispatch({
    type: DISMISS_DS_ACQUISITION_BANNER,
  });
};

export const showSlotChangedInAmendWarning = (): ((dispatch: Dispatch) => void) =>
  addToUrlAndDispatchModal(
    SLOT_CHANGED_IN_AMEND_MODAL,
    null,
    {
      type: OPEN_SLOT_CHANGED_IN_AMEND_MODAL,
    },
    CLOSE_SLOT_CHANGED_IN_AMEND_MODAL,
  );

export const closeSlotChangedInAmendWarning = (eventType: string): unknown => {
  basicEvent(analyticsBus, {
    type: AMEND_MODAL_ANALYTICS_EVENT_TYPE,
    value: eventType,
    action: NOW,
  });

  return removeFromUrlAndDispatchModal({
    type: CLOSE_SLOT_CHANGED_IN_AMEND_MODAL,
  });
};
