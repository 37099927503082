import { getCurrentPath } from '#/reducers/app';
import { getProduct } from '#/selectors/item';
import { toJS } from '#/utils/immutable-utils';
import { SHOP_URL } from '../ui/constants';

const getProductDetails = state => state.productDetails;

export const getServerSideExperiments = state => getProductDetails(state).experiments;

export const getBackToUrl = state => getProductDetails(state).backToUrl;

export const getReferer = state => getProductDetails(state).referer;

export const getBreadcrumbs = state => getProductDetails(state).breadcrumbs;

export const getRestOfShelfUrl = state => getProductDetails(state).restOfShelfUrl;

export const getProductItem = state => getProductDetails(state).item;

export const getAlcoholLegalNotice = state => {
  const item = getProductItem(state);
  const product = getProduct(item);

  return (
    toJS(product.details?.alcoholInfo?.legalNotice) ?? {
      message: [],
      link: '',
    }
  );
};

export const isShopUrl = (state: Store): boolean => {
  const currentPage = getCurrentPath(state);
  const regex = new RegExp(SHOP_URL);
  return !!currentPage.match(regex)?.length;
};
