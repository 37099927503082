export const bookingModalVariants = {
  key: '2210',
  featureKey: 'ghs-all_slot-rebook-modal',
  variationKey: 'variationKey',
  variants: {
    default: '2210-a',
    rebookModal: '2210-b',
    remindModal: '2210-c',
    rebookOrRemindModal: '2210-d',
  },
  useCustomerId: true,
};

export const SHOWN_SLOT_REBOOK_MODAL_KEY = 'shownSlotRebookModal';
export const SHOWN_SLOT_REMIND_MODAL_KEY = 'shownSlotRemindModal';
