import { NOW } from '#/analytics/constants';
import { sessionStore } from '#/lib/data-store/client-store';
import { SELECTED_TAXONOMY } from '#/lib/taxonomy/taxonomy';
import {
  DEPARTMENT,
  AISLE,
  SUPERDEPARTMENT
} from '#/constants/taxonomy-levels';
import { TAXONOMY_PERSISTED } from '../constants';

export function getSelectedTaxonomyDetails(navList, taxonomies) {
  const selectedTaxonomies = {};
  Object.keys(taxonomies).forEach(menuType => {
    const taxonomyDetails = findNodeRecursively(
      taxonomies[menuType],
      navList,
      menuType
    );
    selectedTaxonomies[menuType] = taxonomyDetails ?? null;
  });

  return selectedTaxonomies;
}

export function findNodeRecursively(taxonomyId, itemDetails, menuType) {
  for (const node of itemDetails) {
    const { catId, label, children, url, allNode } = node;

    if (
      (catId === taxonomyId || url === taxonomyId) &&
      (allNode === true ||
        label.toLowerCase().indexOf(menuType.toLowerCase()) !== -1)
    )
      return node;
    if (children) {
      const child = findNodeRecursively(taxonomyId, children, menuType);
      if (child) return child;
    }
  }
}

export function getGroceriesAnalyticsLabel(label, isPersistTaxonomyEnabled) {
  const taxonomies = sessionStore?.get(SELECTED_TAXONOMY);
  const taxonomyPersisted =
    taxonomies &&
    Object.keys(taxonomies).some(
      menuType =>
        taxonomies[menuType] &&
        [DEPARTMENT, SUPERDEPARTMENT, AISLE].includes(menuType)
    );

  label =
    taxonomyPersisted && isPersistTaxonomyEnabled
      ? `${label}${TAXONOMY_PERSISTED}`
      : label;

  return { label, action: NOW };
}
