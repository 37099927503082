import { largeMin } from '#/constants/viewports';
import styled, { css } from 'styled-components';
import { spacing } from '@ddsweb/selectors';

export const ValueMessageWrapper = styled.div`
${({ sideBasket }: { sideBasket: boolean }): string =>
  sideBasket
    ? css`
        padding-bottom: ${spacing.sm};
      `
    : css`
        display: inline-block;
        width: 100%;
        padding-top: ${spacing.sm};
      `}
  @media (max-width: ${largeMin}) {
    & div > h4 {
      margin: 0;
    }
  }
`;
