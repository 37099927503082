import moment, { unitOfTime } from 'moment-timezone';

type TimeUnit = unitOfTime.DurationConstructor;

export default function incrementIsoTimestamp(
  originalDate: string,
  quantityToIncrement: number,
  timeUnit: TimeUnit,
): string {
  const originalDateUtc = moment.utc(originalDate);

  return originalDateUtc.add(quantityToIncrement, timeUnit).format();
}
