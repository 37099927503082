import { ActionLink } from '@beans/buy-box';
import React from 'react';

import analyticsBus from '#/analytics/analyticsBus';
import { basicEvent } from '#/analytics/types/basic';
import SafelyOutputString from '#/components/safely-output-string';
import { PRODUCT_TILE, UNAVAILABLE_PRODUCT } from '#/constants/analytics';
import { connect } from '#/lib/render/connect-deep-compare';
import { getIsOnDemandDelivery } from '#/selectors/trolley';

import { StyledRestOfShelfButton, StyledRestOfShelfLink } from './styles';

export type TRestOfShelfButton = {
  restOfShelfText: string;
  restOfShelfUrl: string | undefined;
  showRestOfShelf: boolean;
};

const mapStateToProps = (state): Pick<TRestOfShelfButton, 'showRestOfShelf'> => ({
  showRestOfShelf: !getIsOnDemandDelivery(state),
});

const handleRestOfShelfClick = (): void => {
  basicEvent(analyticsBus, {
    type: 'rest of shelf',
    value: `${PRODUCT_TILE}:${UNAVAILABLE_PRODUCT}`,
    action: 'delay',
  });
};

const RestOfShelfButton = ({
  restOfShelfText,
  restOfShelfUrl,
  showRestOfShelf,
}: TRestOfShelfButton): JSX.Element | null => {
  if (!showRestOfShelf) return null; // TODO: Move to parent tiles?

  return (
    <>
      {showRestOfShelf && (
        <StyledRestOfShelfButton>
          <StyledRestOfShelfLink className="rest-of-shelf" to={restOfShelfUrl} onClick={handleRestOfShelfClick}>
            <ActionLink buttonVariant="secondary" stretch>
              <SafelyOutputString>{restOfShelfText}</SafelyOutputString>
            </ActionLink>
          </StyledRestOfShelfLink>
        </StyledRestOfShelfButton>
      )}
    </>
  );
};

export default connect(mapStateToProps)(RestOfShelfButton);
