import styled, { css } from 'styled-components';
import { media } from '@beans/foundation';
import { base, spacing, fontSize } from '@beans/selectors';

const textStyle = css`
  font-size: ${fontSize.xs};
  line-height: ${spacing.lg};
  font-family: ${base.fontFamily};
`;

export const StyledAddressSearchWrapper = styled.div`
  ${textStyle}
  padding: ${spacing.sm};

  button[type='submit'] {
    display: none;
  }

  label {
    font-weight: bold;
    margin: 0 0 ${spacing.xs};
  }
`;

export const StyledAddressSearch = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 0 0 ${spacing.sm};

  ${media.belowTabletLarge`
    .multiple-address-selector,
    label {
      flex: 1 0 50%;
    }
  `}

  strong {
    color: inherit;
  }
`;
