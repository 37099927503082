import React from 'react';
import { compose } from 'react-recompose';
// @ts-ignore
import ResponsiveImage from '@ddsweb/responsive-image';
import { BANNER_IMAGE } from '#/components/home/main-banner/constants';
import helpers from '#/lib/decorators/helpers';

const COVER = 'cover';

enum Positioning {
  SIDES = 'sides',
  ENDS = 'ends',
  CENTER = 'center',
}

type OwnProps = {
  altText: string;
  positioning: string;
  src: string;
  asset: (file: string) => string;
};

export const BackgroundImage: React.FC<OwnProps> = ({ altText, asset, positioning, src }: OwnProps) => {
  const objectPositioning =
    positioning === Positioning.SIDES || positioning === Positioning.ENDS ? Positioning.CENTER : positioning;
  const fallbackSrc = asset(BANNER_IMAGE);

  return (
    <ResponsiveImage
      alt={altText}
      objectFit={COVER}
      fallbackSrc={fallbackSrc}
      src={src}
      positioning={objectPositioning}
    />
  );
};
const enhance = compose<OwnProps, OwnProps>(helpers(['asset']));
export default enhance(BackgroundImage);
