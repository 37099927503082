import { FAV_MY_PRODUCT_OOP_KEY } from '#/experiments/oop-1716/constants';
import { ChildMenu } from '#/experiments/oop-1716/helpers/tab-helper';
import { closeAnalyticsConfig, openAnalyticsConfig } from '#/experiments/oop-1716/constants';

type Tab = {
  label: string;
  url: string;
  chevron: boolean;
  activate: string;
};

interface TabMenu extends Tab {
  childMenu: ChildMenu[];
}

export const updateFavTab = (navTab: Tab, favChildMenu = []): TabMenu => {
  const favTab = { ...navTab, childMenu: favChildMenu };

  return favTab;
};

export const getIsFavWithDropdownNav = (key: string): boolean => key === FAV_MY_PRODUCT_OOP_KEY;

export const getFavDropDownNavAnalyticsConfig = (open: boolean): { action: string; type: string; label: string } => {
  return open ? closeAnalyticsConfig : openAnalyticsConfig;
};
