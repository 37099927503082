import React from 'react';
import PropTypes from 'prop-types';

const ProgressBar = props => (
  <input
    type="range"
    min="0"
    title="Seek bar"
    max="100"
    value={props.progress}
    onChange={props.onSeek}
    aria-valuetext={`${Math.round(props.progress)}%`}
    onFocus={props.onFocus}
    onBlur={props.onBlur}
    style={{
      background: `linear-gradient(to right,
        #008cda ${props.progress}%,
        #ccc ${props.progress}%)`
    }}
    className="video-seek-bar"
  />
);

ProgressBar.propTypes = {
  onBlur: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onSeek: PropTypes.func.isRequired,
  progress: PropTypes.number
};

ProgressBar.defaultProps = {
  progress: 0
};

export default ProgressBar;
