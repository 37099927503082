import React from 'react';
import PropTypes from 'prop-types';
import helpers from '../../../lib/decorators/helpers';
import Modal from '../../shared/modal';
import ModalActions from '../../shared/modal/actions';
import { connect } from '#/lib/render/connect-deep-compare';
import ModalButton from '../../shared/modal/actions/button-container';
import { removeModalFromUrl } from '../../../lib/url/modal-utils';
import { isDesktopViewport } from '../../../lib/browser/viewport';
import { scrollToElement } from '../../../lib/browser/ui-util';
import { COLLECTION } from '../../../constants/shopping-methods';
import { getCurrentUrl } from '#/reducers/app';
import { ACKNOWLEDGE_SELECTED_BUSINESS_ADDRESS } from '../../../constants/action-types';

const BookASlotUndeliverableAddressModal = ({
  currentUrl,
  dispatch,
  l,
  t,
  shoppingMethod
}) => {
  let title, primaryButton, warningMessage, secondaryButton;

  const acknowledgeBusinessAddress = () => {
    if (dispatch) {
      dispatch({
        type: ACKNOWLEDGE_SELECTED_BUSINESS_ADDRESS
      });
    }
  };

  const scroll = () => {
    const scrollElem = document.getElementsByClassName('tabs-header-container');

    scrollToElement(scrollElem[0]);
  };

  const focusPostcode = () => {
    acknowledgeBusinessAddress();
    const identifier = isDesktopViewport() ? 'large' : 'small';
    const element = document.getElementById(`postcode-input-${identifier}`);

    element.focus();
    scroll();
  };

  const focusAddressDropDown = () => {
    acknowledgeBusinessAddress();
    const identifier = isDesktopViewport() ? 'large' : 'small';
    const element = document.getElementById(`address-selector-${identifier}`);

    element.focus();
    scroll();
  };

  const handleOnClick = (event, onClickEvent, defaultPrevent) => {
    defaultPrevent && event.preventDefault();
    onClickEvent();
  };

  const renderButton = (
    className,
    href,
    text,
    onClick,
    defaultPrevent = true
  ) => (
    <a
      className={`button button-${className}`}
      href={href}
      onClick={event => handleOnClick(event, onClick, defaultPrevent)}
    >
      {text}
    </a>
  );

  const chooseNewAddress = t('modals:undeliverable-address.choose-new-address');
  const url = removeModalFromUrl(currentUrl);

  if (shoppingMethod === COLLECTION) {
    title = t(
      'modals:undeliverable-address.click-and-collect-business-address-warning-title'
    );
    warningMessage = t(
      'modals:undeliverable-address.click-and-collect-business-address-warning'
    );
    primaryButton = renderButton(
      'primary',
      url,
      chooseNewAddress,
      focusAddressDropDown
    );
    secondaryButton = renderButton(
      'secondary',
      url,
      t('modals:undeliverable-address.search-by-postcode'),
      focusPostcode
    );
  } else {
    title = t('modals:undeliverable-address.title');
    warningMessage = t('slots:delivery.business-address-warning');
    primaryButton = renderButton(
      'primary',
      l('/slots/collection'),
      t('modals:undeliverable-address.choose-click-collect-slot'),
      acknowledgeBusinessAddress,
      false
    );
    secondaryButton = renderButton(
      'secondary',
      url,
      chooseNewAddress,
      acknowledgeBusinessAddress
    );
  }

  return (
    <Modal className="book-a-slot--business-modal" title={title}>
      <div className="yellow-background">
        <p>{warningMessage}</p>
      </div>
      <ModalActions>
        <ModalButton>
          {primaryButton}
          {secondaryButton}
        </ModalButton>
      </ModalActions>
    </Modal>
  );
};

BookASlotUndeliverableAddressModal.propTypes = {
  currentUrl: PropTypes.string.isRequired,
  dispatch: PropTypes.func,
  l: PropTypes.func.isRequired,
  shoppingMethod: PropTypes.oneOf(['delivery', 'collection']),
  t: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  currentUrl: getCurrentUrl(state)
});

export default connect(mapStateToProps)(
  helpers(['l', 't'])(BookASlotUndeliverableAddressModal)
);
