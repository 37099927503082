import styled from 'styled-components';
import { media } from '@beans/foundation';
import { spacing } from '@beans/selectors';
import { WHITE } from '@beans/constants';
import { MEDIUM_LARGE } from '#/components/product-tiles/temp-style-constants';

export const StickyHeaderWrapper = styled('div')`
  ${media.aboveTablet`
    position: sticky;
    top: -82px;
    z-index: 19;

    .brand-header__main-column {
      position: sticky;
      top: 0;

      .brand-header__main {
        position: sticky;
        top: 0;
        z-index: 2;
        width: 60%;
      }

      .brand-header__main {
        max-width: calc(100% - 434px);
      }
      .brand-header__logo {
        padding-top: 26px;
      }
      .brand-header__search {
        position: sticky;
        top: 0;
        background: ${WHITE};
        z-index: 1;

        .search-bar > form {
          display: flex;
          justify-content: center;

          .search-bar__input {
            display: inline-block;
          }
          .search-bar__submit {
            display: inline-block;
            position: inherit;
          }
        }

        .icon-search-faded {
          display: none;
        }
      }
    }

    &.with-amend-bar {
      top: calc(-82px + 67px);
      
      .brand-header__search,
      .brand-header__main {
        top: 67px;
      }

      .header-sticky-observer {
        bottom: 213px;
      }
    }

    &.with-cookie-bar {
      top: calc(-82px + 158px);

      .brand-header__search,
      .brand-header__main {
        top: 158px;
      }

      .header-sticky-observer{
        bottom: 304px;
      }
    }

    &.with-cookie-and-amend-bar {
      top: calc(-82px + 67px + 158px);
      
      .brand-header__search,
      .brand-header__main {
        top: 219px;
      }
      .header-sticky-observer {
        bottom: 375px;
      }
    }

    &.sticky-search-enabled {
      .brand-header__sidebar,
      .search-bar__multi-search-link {
        display: none;
      }

      .brand-header-wrapper > div {
        display: none;
      }
      
      .search-bar {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
      }

      .brand-header__backdrop {
        border-bottom: none;
        background-color: unset;
      }

      .brand-header {
        max-width: initial;
      }

      .brand-header {
        height: 121px;
      }

      .brand-header__main-column {
        height: 121px;
        width: calc(100% - 200px);
      }
      
      .navigation .nav-item__link {
        margin-top: 0px;
        &:after {
          opacity: 0;
        }
      }
      
      .brand-header__main-column {
        box-shadow: 0px ${spacing.xx} ${spacing.xs} rgba(0, 0, 0, 0.2);
      }

      .brand-header__navigation {
        visibility: hidden;
      }
    }
  `}

  @media ${MEDIUM_LARGE} {
    &.sticky-search-enabled .brand-header__main-column {
      width: calc(100% - 240px);
    }
  }

  ${media.aboveDesktop`
    &.sticky-search-enabled .brand-header__main-column {
      box-shadow: 0px ${spacing.xx} ${spacing.xs} rgba(0, 0, 0, 0.2);
      width: calc(100% - (100% - 1200px) / 2 - 240px);
      padding-left: calc((100% - 1200px) / 2);
    }
  `}
`;

export const StickyObserver = styled('div')`
  ${media.aboveTablet`
    position: absolute;
    bottom: 148px;
    left: 10px;
    height: 1px;
    width: 1px;
    z-index: 100;
  `}
`;
