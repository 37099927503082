import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { connect } from '#/lib/render/connect-deep-compare';
import classNames from 'classnames';
import helpers from '#/lib/decorators/helpers';
import { formatUrl, formatWeekText } from '#/lib/slot/slot-utils';
import { emitSlotOp } from '#/analytics/bertie/events';
import DaySelector from '#/components/slots/slot-selector/day-selector';
import GroupSelector from '#/components/slots/slot-selector/group-selector';
import Link from '#/components/link';
import OverlaySpinner from '#/components/overlay-spinner';
import SlotContainer from '#/components/slots/slot-selector/slot-container';
import Tabs from '#/components/tabs';
import TabItem from '#/components/tabs/tab-item';
import { COLLECTION, DELIVERY } from '#/constants/shopping-methods';
import Experiment from '#/components/experiment';
import Variation from '#/components/experiment/variation';
import {
  getIsDesktop,
  getLanguage,
  getLanguageLink,
  getTimezone
} from '#/reducers/app';
import {
  getAvailableSlotGroups,
  getBookedSlotStatus,
  getDisplayDeliverySaverAcquisitionBanner,
  getLocationsExpanded,
  getLocationsPage,
  getSelectedDate,
  getSelectedDateSlots,
  getSelectedLocationId,
  getSelectedWeekRange,
  getSelectedWeekSlots,
  getSlotRange,
  getSlotRangeByWeeks
} from '#/reducers/slot';
import {
  changeSlotDate,
  changeSlotGroup
} from '#/actions/slot-action-creators';
import { formatDate } from '#/lib/slot/slot-range-utils';
import { updatePageUrlWithoutRefresh } from '#/lib/url/url-utils';
import {
  showLessLocations,
  showLocationsPage
} from '#/actions/location-action-creators';
import { SHOPPING_METHOD_PROP_TYPE } from '#/components/slots/prop-types';
import { StyledHeading } from './styled';
import { getStoreId } from '#/selectors/trolley';
import { getDeliveryAddressForTrollyId } from '#/selectors/detailed-addresses';
import { getDisplayCookieInterrupt } from '#/reducers/ui';
import { getIsStickyHeaderSlotCellsUiUpdateVariant } from '#/experiments/oop-1335/selectors';
import { VisuallyHidden } from '#/components/shared/styled';
import {
  getIsStickyHeaderVariant,
  getIsTimesOfDayVariantROI,
  getIsTimesOfTheDayVariant
} from '#/experiments/oop-1338/selectors';
import { getHeaderOffset } from '#/experiments/oop-1338/helpers';
import { CHANGE_SLOT_TYPE_MODAL } from '#/constants/modal-names';
import { getIsTimesOfTheDaySplitVariant } from '#/experiments/oop-1834/selectors';
import { timesOfTheDayRowClass } from '#/experiments/oop-1338/constants';
import { getShowSlotUIReskinV2 } from '#/experiments/oop-2205/selectors';
import SlotWeekTabs from '#/experiments/oop-2205/components/SlotWeekTabs';
import { StyledDeliverySaverSlotContainer } from '../ds-acquisition-banner/styled';
import DeliverySaverAcquisitionBanner from '#/components/slots/ds-acquisition-banner';
import { getCanAmendSlotChange } from '#/selectors/slot';

const mapStateToProps = (state, { c: config }) => {
  const displayCookieInterrupt = getDisplayCookieInterrupt(state);
  const selectedDateSlots = getSelectedDateSlots(state);
  return {
    addressId: getDeliveryAddressForTrollyId(state),
    applyStickyHeaderVariant:
      selectedDateSlots.length > 0 && getIsStickyHeaderVariant(state),
    applyTimesOfTheDayVariant:
      getIsTimesOfTheDaySplitVariant(state) || getIsTimesOfTheDayVariant(state),
    canAmendSlotChange: getCanAmendSlotChange(state),
    currentModal: state.ui.currentModal,
    displayCookieInterrupt,
    isCookieBanner: config('cookiePreferences') && displayCookieInterrupt,
    isDesktop: getIsDesktop(state),
    language: getLanguage(state),
    languageLink: url => getLanguageLink(state, url),
    locationsExpanded: getLocationsExpanded(state),
    locationId: getSelectedLocationId(state),
    locationsPage: getLocationsPage(state),
    selectedDate: getSelectedDate(state),
    selectedDateSlots,
    selectedWeekRange: getSelectedWeekRange(state),
    selectedWeekSlots: getSelectedWeekSlots(state),
    showSlotExpiredNotification: getBookedSlotStatus(state),
    showSlotUIReskin: getShowSlotUIReskinV2(state),
    slotGroups: getAvailableSlotGroups(state),
    slotRange: getSlotRange(state),
    slotRangeByWeeks: getSlotRangeByWeeks(state),
    storeId: getStoreId(state),
    stickyHeaderVariant:
      getIsStickyHeaderSlotCellsUiUpdateVariant(state) &&
      !getIsTimesOfDayVariantROI(state) &&
      selectedDateSlots.length > 0,
    timezone: getTimezone(state),
    showDeliverySaverAcquisitionBanner: getDisplayDeliverySaverAcquisitionBanner(
      config,
      state
    )
  };
};

const mapDispatchToProps = {
  changeSlotGroup,
  showLocationsPage,
  showLessLocations,
  changeSlotDate
};

@helpers(['t', 'c', 'browserTypeVersion'])
@connect(mapStateToProps, mapDispatchToProps)
export default class SlotSelector extends Component {
  constructor(props) {
    super(props);
    this.browserTypeVersion = this.props.browserTypeVersion();
  }

  static propTypes = {
    addressId: PropTypes.string.isRequired,
    applyStickyHeaderVariant: PropTypes.bool,
    areSlotsPending: PropTypes.bool,
    bookedSlot: PropTypes.object,
    browserTypeVersion: PropTypes.func.isRequired,
    c: PropTypes.func.isRequired,
    canAmendSlotChange: PropTypes.bool,
    changeSlotDate: PropTypes.func.isRequired,
    changeSlotGroup: PropTypes.func.isRequired,
    currentShoppingMethod: SHOPPING_METHOD_PROP_TYPE,
    currentSlotGroup: PropTypes.number,
    displayCookieInterrupt: PropTypes.bool,
    isAmendBasket: PropTypes.bool,
    isCookieBanner: PropTypes.bool,
    isDesktop: PropTypes.bool.isRequired,
    language: PropTypes.string.isRequired,
    languageLink: PropTypes.func.isRequired,
    locationId: PropTypes.string.isRequired,
    locationsExpanded: PropTypes.bool,
    locationsPage: PropTypes.number,
    onKeyPressed: PropTypes.func,
    selectedDate: PropTypes.object.isRequired,
    selectedDateSlots: PropTypes.array.isRequired,
    selectedLocation: PropTypes.object,
    selectedWeekRange: PropTypes.object.isRequired,
    shoppingMethod: SHOPPING_METHOD_PROP_TYPE,
    showDeliverySaverAcquisitionBanner: PropTypes.bool.isRequired,
    showLessLocations: PropTypes.func.isRequired,
    showLocationsPage: PropTypes.func.isRequired,
    showSlotExpiredNotification: PropTypes.bool,
    slotGroups: PropTypes.array,
    slotRange: PropTypes.object.isRequired,
    slotRangeByWeeks: PropTypes.array.isRequired,
    stickyHeaderVariant: PropTypes.bool,
    storeId: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    timezone: PropTypes.string.isRequired
  };

  setStickyHeaderOffset = () => {
    const { c: config, displayCookieInterrupt, isAmendBasket } = this.props;
    const isCookieBanner =
      config('cookiePreferences') && displayCookieInterrupt;

    document.documentElement.style.setProperty(
      '--slots-sticky-header-offset-top',
      getHeaderOffset(isCookieBanner, isAmendBasket) + 'px'
    );
  };

  componentDidMount() {
    const { applyStickyHeaderVariant } = this.props;

    if (applyStickyHeaderVariant) {
      this.setStickyHeaderOffset();
    }
  }

  getSlotExpiredNotification() {
    const { showSlotExpiredNotification, t: translate } = this.props;

    if (showSlotExpiredNotification) {
      return (
        <div className="slot-expired-warning">
          <p className="info-message">
            {translate('slots:common.amend-slot-expiry-warning')}
          </p>
        </div>
      );
    }

    return null;
  }

  getBASHeader(monthHeader) {
    const {
      selectedDate,
      selectedDateSlots,
      selectedLocation,
      currentSlotGroup,
      shoppingMethod,
      slotRange,
      isCookieBanner,
      stickyHeaderVariant,
      applyStickyHeaderVariant,
      showDeliverySaverAcquisitionBanner
    } = this.props;

    return (
      <div
        className={classNames(
          'slot-selector__day-selector hidden-medium-small hidden-large',
          {
            sticky: stickyHeaderVariant,
            'slot-selector__day-selector--cookie-banner': isCookieBanner,
            sticky__header: applyStickyHeaderVariant
          }
        )}
      >
        <div className="slot-selector__month-header">
          <p>{monthHeader}</p>
        </div>
        <span>
          {showDeliverySaverAcquisitionBanner && (
            <div className="delivery-saver-banner delivery-saver-banner--list">
              <StyledDeliverySaverSlotContainer>
                <DeliverySaverAcquisitionBanner />
              </StyledDeliverySaverSlotContainer>
            </div>
          )}
        </span>
        <DaySelector
          selectedDate={selectedDate}
          selectedDateSlots={selectedDateSlots}
          selectedLocation={selectedLocation}
          currentSlotGroup={currentSlotGroup}
          shoppingMethod={shoppingMethod}
          slotRange={slotRange}
        />
      </div>
    );
  }

  slotDateChangeHandler = (event, weekStartDate) => {
    event.preventDefault();

    const {
      showLessLocations,
      showLocationsPage,
      locationsExpanded,
      locationsPage,
      changeSlotDate,
      currentSlotGroup
    } = this.props;

    const formattedDate = formatDate(weekStartDate);

    if (locationsExpanded) {
      showLocationsPage(parseInt(locationsPage || 1, 10));
    } else {
      showLessLocations();
    }

    updatePageUrlWithoutRefresh(
      window,
      this.getSlotUrl(weekStartDate, currentSlotGroup)
    );

    changeSlotDate(formattedDate);
  };

  onChangeSlotGroup = async slotGroup => {
    const { changeSlotGroup, selectedDate } = this.props;

    updatePageUrlWithoutRefresh(
      window,
      this.getSlotUrl(selectedDate, slotGroup)
    );
    const formattedDate = formatDate(selectedDate);
    await changeSlotGroup(slotGroup, formattedDate);
    this.emitSlotEvent();
  };

  emitSlotEvent() {
    const {
      isDesktop,
      locationId,
      selectedDateSlots,
      selectedWeekSlots,
      shoppingMethod,
      storeId,
      addressId,
      timezone
    } = this.props;

    emitSlotOp(
      isDesktop ? selectedWeekSlots : selectedDateSlots,
      shoppingMethod,
      storeId,
      locationId,
      addressId,
      timezone
    );
  }

  getSlotUrl = (date, slotGroup) => {
    const { languageLink, shoppingMethod, selectedLocation } = this.props;

    return languageLink(
      formatUrl(
        shoppingMethod,
        date,
        selectedLocation !== null && shoppingMethod === COLLECTION
          ? selectedLocation.locationId
          : null,
        slotGroup
      )
    );
  };

  onKeyPressedOnTabs = e => {
    const tabIds = this.props.slotRangeByWeeks.map(week => {
      const accessibleTitle = formatWeekText(
        week,
        this.props.timezone,
        this.props.language,
        {
          dateRange: {
            long: 'MMMM Do',
            short: 'Do'
          }
        }
      );
      return `${this.props.t(
        'slots:common.between'
      )}-${accessibleTitle}`.toLowerCase();
    });
    const focusedTabIndex = tabIds.indexOf(e.currentTarget.id);
    switch (e.key) {
      case 'ArrowRight':
        document
          .getElementById(tabIds[(focusedTabIndex + 1) % tabIds.length])
          .focus();
        break;
      case 'ArrowLeft':
        const index = (focusedTabIndex - 1) % tabIds.length;
        document
          .getElementById(tabIds[index < 0 ? tabIds.length - 1 : index])
          .focus();
        break;
      case 'Home':
        document.getElementById(tabIds[0]).focus();
        break;
      case 'End':
        document.getElementById(tabIds[tabIds.length - 1]).focus();
        break;
      case ' ':
        e.preventDefault();
        e.currentTarget.click();
        break;
      default:
        break;
    }
  };

  onKeyPressedOnSlots = e => {
    const { applyTimesOfTheDayVariant } = this.props;
    const bookSlotKeys = [' ', 'Tab', 'Enter'];
    if (bookSlotKeys.includes(e.key)) {
      return true;
    }

    const activeElement = document.activeElement;
    const buttonSelector = 'button';
    const unavailableSelector = '.unavailable';
    const aSelector = 'a';
    const tdSelector = 'td';
    const ulSelector = 'ul';
    const liSelector = '.slot-list--item';
    const trSelector = 'tr';
    const currentTdElement = activeElement.closest(tdSelector);
    const currentLiElement = activeElement.closest(liSelector);
    const tableEl = activeElement.closest('table');
    const firstRowIndex = applyTimesOfTheDayVariant ? 2 : 1;

    let selector;
    let el;

    if (currentLiElement) {
      const siblingEl =
        e.key === 'ArrowDown'
          ? currentLiElement.nextSibling
          : currentLiElement.previousSibling;
      const ulElement = currentLiElement.closest(ulSelector);
      if (siblingEl) {
        selector = siblingEl.classList.contains(unavailableSelector.slice(1))
          ? aSelector
          : buttonSelector;
        siblingEl.querySelector(selector).focus();
      } else if (e.key === 'ArrowDown') {
        const firstChildEl = ulElement.firstChild;
        selector = firstChildEl.classList.contains(unavailableSelector.slice(1))
          ? aSelector
          : buttonSelector;
        firstChildEl.querySelector(selector).focus();
      } else if (e.key === 'ArrowUp') {
        const lastChildEl = ulElement.lastChild;
        selector = lastChildEl.classList.contains(unavailableSelector.slice(1))
          ? aSelector
          : buttonSelector;
        lastChildEl.querySelector(selector).focus();
      }
      return;
    }

    if (currentTdElement) {
      switch (e.key) {
        case 'ArrowLeft':
          const previousSiblingEl = currentTdElement.previousSibling;

          selector = previousSiblingEl.querySelector(unavailableSelector)
            ? aSelector
            : buttonSelector;
          if (currentTdElement.cellIndex === 1) {
            el = currentTdElement.parentElement.lastChild;
            selector = el.querySelector(unavailableSelector)
              ? aSelector
              : buttonSelector;
          } else if (previousSiblingEl) {
            el = previousSiblingEl;
          }

          break;
        case 'ArrowRight':
          const nextSiblingEl = currentTdElement.nextSibling;

          if (nextSiblingEl) {
            selector = nextSiblingEl.querySelector(unavailableSelector)
              ? aSelector
              : buttonSelector;
            el = nextSiblingEl;
          } else {
            el = currentTdElement.parentElement.children[1];
            selector = el.querySelector(unavailableSelector)
              ? aSelector
              : buttonSelector;
          }
          break;
        case 'ArrowUp':
        case 'ArrowDown':
          const currentCellIndex = currentTdElement.cellIndex;
          const currentRowIndex = activeElement.closest(trSelector).rowIndex;

          if (e.key === 'ArrowUp') {
            if (currentRowIndex === firstRowIndex) {
              el =
                tableEl.rows[tableEl.rows.length - 1].children[
                  currentCellIndex
                ];
            } else {
              if (
                applyTimesOfTheDayVariant &&
                tableEl.rows[currentRowIndex - 1].classList.contains(
                  timesOfTheDayRowClass
                )
              ) {
                el =
                  tableEl.rows[currentRowIndex - 2].children[currentCellIndex];
              } else {
                el =
                  tableEl.rows[currentRowIndex - 1].children[currentCellIndex];
              }
            }
            selector = el.querySelector(unavailableSelector)
              ? aSelector
              : buttonSelector;
          } else if (e.key === 'ArrowDown') {
            if (currentRowIndex + 1 === tableEl.rows.length) {
              el = tableEl.rows[firstRowIndex].children[currentCellIndex];
            } else {
              if (
                applyTimesOfTheDayVariant &&
                tableEl.rows[currentRowIndex + 1].classList.contains(
                  timesOfTheDayRowClass
                )
              ) {
                el =
                  tableEl.rows[currentRowIndex + 2].children[currentCellIndex];
              } else {
                el =
                  tableEl.rows[currentRowIndex + 1].children[currentCellIndex];
              }
            }

            selector = el.querySelector(unavailableSelector)
              ? aSelector
              : buttonSelector;
          }
          break;
        case 'Home':
          el = tableEl.rows[1].firstChild;
          selector = el.querySelector(unavailableSelector)
            ? aSelector
            : buttonSelector;
          break;
        case 'End':
          el = tableEl.rows[tableEl.rows.length].lastChild;
          selector = el.querySelector(unavailableSelector)
            ? aSelector
            : buttonSelector;
          break;
        default:
          break;
      }
    }

    if (el && selector) {
      el.querySelector(selector)?.focus();
    }
  };

  render() {
    const {
      applyStickyHeaderVariant,
      areSlotsPending,
      c: config,
      canAmendSlotChange,
      currentModal,
      currentSlotGroup,
      isAmendBasket,
      language,
      selectedLocation,
      selectedWeekRange,
      shoppingMethod,
      showSlotUIReskin,
      slotGroups,
      slotRangeByWeeks,
      t: translate,
      timezone
    } = this.props;

    const changeSlotInAmend =
      isAmendBasket && currentModal === CHANGE_SLOT_TYPE_MODAL;

    const activeTabContent = (
      <SlotContainer {...this.props} onKeyPressed={this.onKeyPressedOnSlots} />
    );
    const { browserTypeVersion } = this;

    const weekSpaceClassName = `slot-selector--${slotRangeByWeeks.length}-week-tab-space`;
    const tabItems = slotRangeByWeeks.map(week => {
      const active = week.start.isSame(selectedWeekRange.start, 'day');
      const title = formatWeekText(week, timezone, language, config('format'));
      const accessibleTitle = formatWeekText(
        week,
        timezone,
        language,
        config('accessibleFormat')
      );
      const accessibleReadableTitle = accessibleTitle
        .replace('-', ` ${translate('slots:common.time-interval.date-to')} `)
        .concat(translate('slots:common.time-interval.date-until'))
        .split('.')
        .join('');
      const header = (
        <div data-testid="week-selector-tabs-synthetics">
          <Link
            className="slot-selector--week-tabheader-link"
            href={this.getSlotUrl(week.start, currentSlotGroup)}
            onClick={event => this.slotDateChangeHandler(event, week.start)}
            aria-controls={`${translate(
              'slots:common.between'
            )}-${accessibleTitle}-tab`.toLocaleLowerCase()}
            id={`${translate(
              'slots:common.between'
            )}-${accessibleTitle}`.toLocaleLowerCase()}
            tabIndex={active ? '0' : '-1'}
            onKeyPressed={this.onKeyPressedOnTabs}
            data-testid="week-selector-tabs-synthetics"
          >
            <VisuallyHidden data-visually-hidden>
              {accessibleReadableTitle}
            </VisuallyHidden>
            <span aria-hidden="true">{title}</span>
          </Link>
        </div>
      );

      return (
        <TabItem
          className="slot-selector--week-tab"
          data-week={week.start}
          header={header}
          headerClassName={`slot-selector--week-tabheader ${weekSpaceClassName}`}
          isActive={active}
          dataTestid={`${active && 'week-active-synthetics'}`}
          key={title}
          tabPanelAttributes={{
            tabIndex: 0,
            'aria-labelledby': `${translate(
              'slots:common.between'
            )}-${accessibleTitle}`.toLocaleLowerCase(),
            id: `${translate(
              'slots:common.between'
            )}-${accessibleTitle}-tab`.toLocaleLowerCase()
          }}
        >
          {active ? activeTabContent : ''}
        </TabItem>
      );
    });

    const groupSelector = slotGroups &&
      slotGroups.length > 1 &&
      shoppingMethod === DELIVERY && (
        <GroupSelector
          currentSlotGroup={currentSlotGroup}
          onChangeSlotGroup={this.onChangeSlotGroup}
          slotGroups={slotGroups}
          isSlotUIReskinV2Variant={showSlotUIReskin}
        />
      );

    const startMonth = moment
      .utc(selectedWeekRange.start)
      .locale(language)
      .format('MMMM');
    const endMonth = moment
      .utc(selectedWeekRange.end)
      .locale(language)
      .format('MMMM');
    const monthHeader =
      startMonth !== endMonth ? `${startMonth}-${endMonth}` : startMonth;

    const chooseASlot = config('showGroupAndSlotTitle') ? (
      <StyledHeading className="visually-hidden group-selector--title">
        {translate('slots:common.choose-a-slot-date')}
      </StyledHeading>
    ) : null;

    return (
      <div
        className={classNames('slot-selector', {
          'slot-selector--sticky-header': applyStickyHeaderVariant
        })}
        data-testid="slot-selector-synthetics"
      >
        <OverlaySpinner
          isLoading={
            !!(canAmendSlotChange
              ? areSlotsPending && !changeSlotInAmend
              : areSlotsPending)
          }
        />
        {groupSelector}
        <div
          data-auto="slot-matrix"
          id="slot-matrix"
          className={classNames('group-selector no-border', {
            sticky__wrapper: applyStickyHeaderVariant,
            'group-selector--reskin': showSlotUIReskin
          })}
        >
          {chooseASlot}
          {this.getSlotExpiredNotification()}
          <Experiment
            defaultVariant="370-a"
            name="oop370"
            useCustomerId={true}
            attributes={{ browserTypeVersion }}
          >
            <Variation variant="370-a">
              {this.getBASHeader(monthHeader)}
            </Variation>
            <Variation variant="370-b">
              {this.getBASHeader(monthHeader, '370-b')}
            </Variation>
          </Experiment>
          {showSlotUIReskin ? (
            <SlotWeekTabs
              className={'tabs '}
              shoppingMethod={shoppingMethod}
              selectedLocation={selectedLocation}
              currentSlotGroup={currentSlotGroup}
            >
              {tabItems}
            </SlotWeekTabs>
          ) : (
            <Tabs tabAriaLabel={translate('slots:common.choose-a-slot-date')}>
              {tabItems}
            </Tabs>
          )}
        </div>
      </div>
    );
  }
}
