import { getFeatureVariableStringFromState } from '#/lib/optimizely-manager';
import { favPersonalisedOffers, favPersonalisedOffersVariants, productCount } from '#/experiments/oop-1557/constants';
import { isOnDemandDeliveryShoppingMethod } from '#/lib/shopping-method-util';
import { getTrolleyShoppingMethod } from '#/selectors/trolley';

type ExperimentConfig = {
  variation: favPersonalisedOffersVariants;
  productCount: number;
};

const getVariant = (state: Store, variationKey: string, defaultValue: string): favPersonalisedOffersVariants => {
  const { useCustomerId, featureKey } = favPersonalisedOffers;
  const attributes = {};

  return (
    getFeatureVariableStringFromState(state, featureKey, variationKey, { useCustomerId, attributes }) || defaultValue
  );
};

export const getFavPersonalisedOffersConfig = (state: Store): ExperimentConfig => {
  const { variationKey } = favPersonalisedOffers;
  const defaultValue = favPersonalisedOffersVariants.default;
  const isOnDemandShoppingMethod = isOnDemandDeliveryShoppingMethod(getTrolleyShoppingMethod(state));

  const config = {
    variation: favPersonalisedOffersVariants.default,
    productCount: 0,
  };

  if (isOnDemandShoppingMethod) return config;

  const variation = getVariant(state, variationKey, defaultValue);

  if (variation === favPersonalisedOffersVariants.default) {
    return config;
  }

  const count = getVariant(state, productCount, '0');

  return { ...config, variation, productCount: parseInt(count) };
};
