/**
 * Experiment constants for showing 'how it works' section on Top | click + collect page
 */
export const howItWorksSectionVariants = {
  key: 'oop1423',
  variants: {
    default: '1423-a',
    variantB: '1423-b',
  },
  useCustomerId: true,
};
