/**
 * Experiment constants for Recipe Zone Page
 */
export const recipeZonePageVariants = {
  key: 'oop812',
  variants: {
    default: '812-a',
    showRecipeZonePage: '812-b',
  },
  useCustomerId: false,
};
