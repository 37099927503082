const weekEndings = 'vasárnap hétfőn kedden szerdán csütörtökön pénteken szombaton'.split(
  ' '
);

function translate(number, withoutSuffix, key, isFuture) {
  const num = number;

  switch (key) {
    case 's':
      return isFuture || withoutSuffix
        ? 'néhány másodperc'
        : 'néhány másodperce';
    case 'm':
      return `egy${isFuture || withoutSuffix ? ' perc' : ' perce'}`;
    case 'mm':
      return num + (isFuture || withoutSuffix ? ' perc' : ' perce');
    case 'h':
      return `egy${isFuture || withoutSuffix ? ' óra' : ' órája'}`;
    case 'hh':
      return num + (isFuture || withoutSuffix ? ' óra' : ' órája');
    case 'd':
      return `egy${isFuture || withoutSuffix ? ' nap' : ' napja'}`;
    case 'dd':
      return num + (isFuture || withoutSuffix ? ' nap' : ' napja');
    case 'M':
      return `egy${isFuture || withoutSuffix ? ' hónap' : ' hónapja'}`;
    case 'MM':
      return num + (isFuture || withoutSuffix ? ' hónap' : ' hónapja');
    case 'y':
      return `egy${isFuture || withoutSuffix ? ' év' : ' éve'}`;
    case 'yy':
      return num + (isFuture || withoutSuffix ? ' év' : ' éve');
  }

  return '';
}

function week(isFuture) {
  return `${isFuture ? '' : '[múlt] '}[${weekEndings[this.day()]}] LT[-kor]`;
}

const config = {
  months: 'január_február_március_április_május_június_július_augusztus_szeptember_október_november_december'.split(
    '_'
  ),
  monthsShort: 'jan_feb_márc_ápr_máj_jún_júl_aug_szept_okt_nov_dec'.split('_'),
  weekdays: 'vasárnap_hétfő_kedd_szerda_csütörtök_péntek_szombat'.split('_'),
  weekdaysShort: 'vas_hét_kedd_sze_csüt_pén_szo'.split('_'),
  weekdaysMin: 'v_h_k_sze_cs_p_szo'.split('_'),
  longDateFormat: {
    LT: 'H:mm',
    L: 'YYYY.MM.DD.',
    LL: 'YYYY. MMMM D.',
    LLL: 'YYYY. MMMM D., LT',
    lll: 'YYYY. MMM D., ddd',
    LLLL: 'YYYY. MMMM D., dddd LT'
  },
  meridiem(hours, minutes, isLower) {
    if (hours < 12) {
      return isLower === true ? 'de' : 'DE';
    }

    return isLower === true ? 'du' : 'DU';
  },
  calendar: {
    sameDay: '[ma] LT[-kor]',
    nextDay: '[holnap] LT[-kor]',
    nextWeek() {
      return week.call(this, true);
    },
    lastDay: '[tegnap] LT[-kor]',
    lastWeek() {
      return week.call(this, false);
    },
    sameElse: 'L'
  },
  relativeTime: {
    future: '%s múlva',
    past: '%s',
    s: translate,
    m: translate,
    mm: translate,
    h: translate,
    hh: translate,
    d: translate,
    dd: translate,
    M: translate,
    MM: translate,
    y: translate,
    yy: translate
  },
  ordinal: '%d.',
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 7 // The week that contains Jan 1st is the first week of the year.
  }
};

export default config;
