import { findAndSetTrolleyItems, itemsToMap } from '#/lib/records/product-utils';
import {
  UPDATE_RESULTS_PAGE_NO,
  UPDATE_REMOVE_FAVOURITE,
  CLEAR_REMOVE_FAVOURITE,
  CLEAR_PAGES,
  IS_RESULTS_UPDATING,
  CLEAR_IS_RESULTS_UPDATING,
  UPDATE_TOTAL_COUNT,
  UPDATE_COUNT,
  UPDATE_PAGE_SIZE,
  UPDATE_PAGE_OFFSET,
  UPDATE_RESULTS_PAGE,
  UPDATE_FAVORITES_CAROUSEL_ITEMS,
} from '#/constants/action-types';
import { createSelector } from 'reselect';
import { getNavList } from '#/reducers/taxonomy';
import { findSelectedTaxon } from '#/lib/helpers';
import { isObject } from '#/utils/misc';
import {
  getProductAisleName,
  getProductAisleId,
  getIsSubstitute,
  getProductIsForSale,
  getProductId,
  getGroupName,
} from '#/selectors/item';
import { Items } from '#/custom-typings/redux-store/trolley.defs';
import { Item } from '#/lib/records/item';

export interface ResultsStateFromServer {
  pageInformation: {
    pageSize: number;
    pageNo: number;
    count: number;
    totalCount: number;
    offset: number;
  };
  productItems: Array<Item>;
  // Currently unclear what the experiments looks like
  // eslint-disable-next-line
  experiments?: Array<any>;
  // eslint-disable-next-line
  shouldOverridePreviousItem?: boolean;
  supportsFlexiPageSize?: boolean;
}

export interface Results {
  pages: Array<Items>;
  totalCount: number;
  count: number;
  itemsPerPage: number;
  pageNo: number;
  supportsFlexiPageSize: boolean;
  offset: number;
  isUpdating: boolean;
  resultsType: string;
  // Currently unclear what the experiments, favExperiments or favCarouselItems types look like
  // eslint-disable-next-line
  experiments: Array<any>;
  // eslint-disable-next-line
  favExperiments: Array<any>;
  favCarouselItems: Array<Item>;
  shouldOverridePreviousItem: boolean;
  favToRemove: string | null;
}

interface ResultsState {
  results: Results;
}

const defaultState: Results = {
  pages: [],
  totalCount: 0,
  pageNo: 0,
  count: 0,
  itemsPerPage: 0,
  supportsFlexiPageSize: false,
  offset: 0,
  isUpdating: false,
  resultsType: 'none',
  experiments: [],
  favExperiments: [],
  favCarouselItems: [],
  shouldOverridePreviousItem: false,
  favToRemove: null,
};

export default function results(
  state: Results = defaultState,
  // value can be anything used by any of these actions so leaving as optional any for now
  // eslint-disable-next-line
  action: { type?: string; value?: any } = {},
): ResultsState['results'] {
  switch (action.type) {
    case CLEAR_PAGES:
      return { ...state, pages: [] };
    case UPDATE_RESULTS_PAGE: {
      const {
        value: { pageNo, pageItems, resultsType, supportsFlexiPageSize, experiments },
      } = action;
      const pagesArr = [...state.pages];
      pagesArr[pageNo] = pageItems;

      return {
        ...state,
        isUpdating: false,
        supportsFlexiPageSize,
        pages: pagesArr,
        resultsType,
        experiments,
      };
    }
    case UPDATE_TOTAL_COUNT:
      return { ...state, totalCount: action.value };
    case UPDATE_COUNT:
      return { ...state, count: action.value };
    case UPDATE_PAGE_SIZE:
      return { ...state, itemsPerPage: action.value };
    case UPDATE_PAGE_OFFSET:
      return { ...state, offset: action.value };
    case UPDATE_RESULTS_PAGE_NO:
      return { ...state, pageNo: action.value };
    case UPDATE_REMOVE_FAVOURITE:
      return { ...state, favToRemove: action.value };
    case CLEAR_REMOVE_FAVOURITE:
      return { ...state, favToRemove: null };
    case IS_RESULTS_UPDATING:
      return { ...state, isUpdating: true };
    case CLEAR_IS_RESULTS_UPDATING:
      return { ...state, isUpdating: false };
    case UPDATE_FAVORITES_CAROUSEL_ITEMS: {
      const {
        value: { items: favCarouselItems, experiments: favExperiments },
      } = action;
      return { ...state, favCarouselItems, favExperiments };
    }

    default:
      return state;
  }
}

const extractSupportsFlexiPageSize = (res: { supportsFlexiPageSize?: boolean }): boolean =>
  !!(res && res.supportsFlexiPageSize);

/**
 * The method finds the first instance of a resource with results data
 * and uses this to derive the resultsType. This looks like an unsafe
 * way to select results from the resources (and it is), but
 * currently there should only ever be one resource returned
 * with results as part of a page response.
 */
function getResultsFromResourceProps(resources: {
  [resource: string]: { data: { results: ResultsStateFromServer } };
}): { results: ResultsStateFromServer | null; resultsType: string | 'none' } {
  let resultsType = 'none';

  if (isObject(resources)) {
    resultsType =
      Object.keys(resources).find(resourceName => {
        const resource = resources[resourceName];
        if (!resource?.data?.results) return false;
        return true;
      }) || 'none';
  }

  let results;

  if (resultsType) {
    results = resources?.[resultsType]?.data?.results;
  } else {
    results = null;
  }

  return { results, resultsType };
}

export const getDefaultStateFromProps = (props: {
  // trolley and resources is currently not properly typed
  // eslint-disable-next-line
  trolley: any;
  resources: { [resource: string]: { data: { results: ResultsStateFromServer } } };
  results: ResultsStateFromServer;
}): Results => {
  const resultsFromResources = getResultsFromResourceProps(props.resources);

  const pageInformation = resultsFromResources.results?.pageInformation || props.results?.pageInformation || {};
  const resultsType = resultsFromResources.resultsType;
  const resultsToAddToState = resultsFromResources.results || props.results || {};

  const { pageSize: itemsPerPage = 24, pageNo = 0, count = 0, totalCount = 0, offset = 0 } = pageInformation;

  const trolleyItems = props.trolley?.items || [];

  const supportsFlexiPageSize = extractSupportsFlexiPageSize(resultsToAddToState);
  const noOfPages = Math.ceil(totalCount / itemsPerPage);
  const items = resultsToAddToState.productItems || [];

  const initialPageItems = items.length > 0 ? findAndSetTrolleyItems(items, trolleyItems) : [];

  const initialPage = itemsToMap(initialPageItems);

  const pages: Array<Items> = Array.from({ length: noOfPages });

  pages[pageNo - 1] = initialPage;

  return {
    ...defaultState,
    totalCount,
    count,
    itemsPerPage,
    pageNo,
    pages,
    supportsFlexiPageSize,
    offset,
    isUpdating: false,
    resultsType,
    experiments: resultsToAddToState.experiments || [],
    shouldOverridePreviousItem: resultsToAddToState.shouldOverridePreviousItem || false,
  };
};

/**
 * Selector functions
 *
 * These functions give a consistent view of the state to the consuming UI components, given the store.
 */

/**
 * @returns Array<Item>
 */
export const getFavCarouselItems = (state: Store): Results['favCarouselItems'] => {
  const { results } = state;

  return results.favCarouselItems || [];
};

export const getFavExperiments = ({ results: { favExperiments } }: ResultsState): Results['favExperiments'] =>
  favExperiments;

export function getShowMoreCount({ results: { pageNo, itemsPerPage, totalCount } }: ResultsState): number {
  const leftOver = Math.max(totalCount - itemsPerPage * pageNo, 0);

  return Math.min(leftOver, itemsPerPage);
}

export const getServerSideExperiments = ({ results: { experiments } }: ResultsState): Results['experiments'] =>
  experiments;

export const getPages = ({ results: { pages } }: ResultsState): Results['pages'] => pages;

export const getPageNo = ({ results: { pageNo } }: ResultsState): Results['pageNo'] => pageNo;

export const getAllItems = ({ results: { pages } }: ResultsState): Items => {
  return pages
    .reduce<Array<Item>>((acc, curr) => acc.concat([...(curr || new Map()).values()]), [])
    .reduce((map, curr) => {
      if (curr) {
        map.set(getProductId(curr), curr);
        return map;
      }

      return map;
    }, new Map());
};

export const getItemsByGroupname = <GroupName extends string>(
  state: ResultsState,
  groups: Array<GroupName>,
): { items: Record<GroupName, Array<Item>>; groups: Array<GroupName> } => {
  const allItems = getAllItems(state);
  const items = groups.reduce<Record<GroupName, Array<Item>>>(
    (accum: Record<GroupName, Array<Item>>, groupName: GroupName) => {
      const filterItems = [...allItems.values()].filter(
        item => typeof item !== 'undefined' && getGroupName(item) === groupName,
      );

      return { ...accum, [groupName]: filterItems };
    },
    {} as Record<GroupName, Array<Item>>,
  );

  return { items, groups };
};

export const getPageItems = createSelector(
  getPages,
  getPageNo,

  (pages, pageNo) => pages[pageNo - 1],
);

export const getPageFirstItemId = (state: ResultsState): string | undefined => {
  const pageItems = getPageItems(state);
  const firstItem = pageItems && pageItems.values().next().value;
  return firstItem && getProductId(firstItem);
};

export const getPageLastItemId = (state: ResultsState): string | undefined => {
  const pageItems = getPageItems(state);
  const lastItem = pageItems && [...pageItems.values()].pop();

  return lastItem && getProductId(lastItem);
};

export const getFavToRemove = ({ results: { favToRemove } }: ResultsState): Results['favToRemove'] => favToRemove;

export const getSupportsFlexiPageSize = ({
  results: { supportsFlexiPageSize },
}: ResultsState): Results['supportsFlexiPageSize'] => supportsFlexiPageSize;

export const getUpdatingStatus = ({ results: { isUpdating } }: ResultsState): Results['isUpdating'] => isUpdating;

export const getTotalCount = ({ results: { totalCount } }: ResultsState): Results['totalCount'] => totalCount;

export const getCount = ({ results: { count } }: ResultsState): Results['count'] => count;

export const getOffset = ({ results: { offset } }: ResultsState): number => offset || 0;

export const shouldOverridePreviousItem = (
  { results: { shouldOverridePreviousItem } }: ResultsState,
  item: Item,
): boolean => shouldOverridePreviousItem && !getIsSubstitute(item);

export const getItemsPerPage = ({ results: { itemsPerPage } }: ResultsState): Results['itemsPerPage'] => itemsPerPage;

export const getIndexOfFirstPageItem = ({ results: { itemsPerPage, pageNo } }: ResultsState): number =>
  pageNo > 0 ? itemsPerPage * (pageNo - 1) + 1 : 0;

// If you're at end of search results can only see up to totalCount
export const getIndexOfLastPageItem = ({ results: { itemsPerPage, pageNo, totalCount } }: ResultsState): number =>
  Math.min(itemsPerPage * pageNo, totalCount) || 0;

export const getAvailableItems = (state: ResultsState): [] | Items => {
  if (!getPages(state)) return [];

  const items = new Map();
  getAllItems(state).forEach((item, id) => {
    if (getProductIsForSale(item)) items.set(id, item);
  });

  return items;
};

export const getAisleNameFromItem = (
  // taxonomy state is currently unknown
  // eslint-disable-next-line
  state: ResultsState & { taxonomy: any },
  item: Item,
): string | null | undefined => {
  let aisleName = getProductAisleName(item);

  if (!aisleName) {
    const category = findSelectedTaxon({ children: getNavList(state) }, 'catId', getProductAisleId(item));

    aisleName = category && category.name;
  }

  return aisleName;
};
