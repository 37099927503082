import styled from 'styled-components';
import { spacing } from '@ddsweb/selectors';
import Button from '@ddsweb/button';
import { media } from '@ddsweb/foundation';
import Modal from '@ddsweb/modal';
import { Text } from '@ddsweb/text';
import { defaultTheme } from '@ddsweb/theme';

export const ModalButtonContainer = styled.div`
  margin-top: ${spacing.md};

  ${media.aboveTabletLarge`
    justify-content: left;
    display: flex;
  `}
`;

export const StyledButton = styled(Button)`
  ${media.aboveTabletLarge`
    margin-left: ${spacing.sm};
  `}

  ${media.belowTabletLarge`
    margin-top: ${spacing.sm};
  `}
`;

export const StyledTextContainer = styled.div`
  margin-top: ${spacing.sm};
`;

export const StyledModal = styled(Modal)`
  ${Modal.ModalContainer} {
    height: auto;
  }

  ${Modal.CloseLink} {
    margin: 0;
  }
`;

export const StyledText = styled(Text)`
  margin-top: ${defaultTheme.lineHeightNext.text.sm};
`;
