import React from 'react';
import PropTypes from 'prop-types';
import { connect } from '#/lib/render/connect-deep-compare';
import DefaultLayout from '#/components/layouts/default';
import { getIsAmendBasket, getSlot } from '#/selectors/trolley';
import DefaultWebViewLayout from '#/components/layouts/default-webview';
import helpers from '#/lib/decorators/helpers';

const mapStateToProps = state => ({
  isAmendBasket: getIsAmendBasket(state),
  bookedSlot: getSlot(state)
});

@helpers(['c'])
@connect(mapStateToProps)
export default class DefaultLayoutSPA extends React.Component {
  static propTypes = {
    bookedSlot: PropTypes.object,
    c: PropTypes.func.isRequired,
    children: PropTypes.any.isRequired,
    isAmendBasket: PropTypes.bool.isRequired
  };

  render() {
    const { c: config } = this.props;

    const isWebView = config('useWebViewLayout');

    return isWebView ? (
      <DefaultWebViewLayout {...this.props} />
    ) : (
      <DefaultLayout {...this.props} headerFixed={true} />
    );
  }
}
