import { Component } from 'react';
import PropTypes from 'prop-types';
import getAnalytics from '#/analytics/analyticsBus';
import helpers from '#/lib/decorators/helpers';

@helpers(['c'])
export default class AnalyticsRouteRendered extends Component {
  static propTypes = {
    analyticsEvent: PropTypes.string,
    asyncPage: PropTypes.bool,
    c: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.handleRouteChangedEvent();
  }

  handleRouteChangedEvent() {
    if (window.initialPageLoad) {
      return;
    }

    const { analyticsEvent, c: config } = this.props;
    const analytics = getAnalytics();
    const waitDuration = parseInt(config('waitForAsyncPage'), 10);
    const routeChangedCb = () =>
      analytics.emit('app:routeChanged', {
        analyticsEvent
      });

    if (this.props.asyncPage) {
      analytics.onceTimed(
        'app:asyncPageComplete',
        routeChangedCb,
        waitDuration
      );
    } else {
      setTimeout(routeChangedCb, 0);
    }
  }

  render() {
    return null;
  }
}
