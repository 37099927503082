import {
  CLOSE_FILTER_MENU,
  CLOSE_LEFT_NAV,
  CLOSE_MODAL,
  CLOSE_NAV_MENU,
  SET_ONBOARDING_BANNER_VISIBILITY,
  HIDE_AMEND_ORDER_BAR,
  HIDE_HEADER_SEARCH,
  LOCK_BODY_SCROLL,
  OPEN_FILTER_MENU,
  OPEN_LEFT_NAV,
  OPEN_MODAL,
  OPEN_NAV_MENU,
  RECEIVE_FAVORITES_DATA,
  SET_MULTI_SEARCH_LINK_VISIBILITY,
  SET_STICKY_BAR_NAME,
  SET_STICKY_BAR_EXPANDED,
  SHOW_AMEND_ORDER_BAR,
  SHOW_HEADER_SEARCH,
  TOGGLE_AMEND_ORDER_BAR,
  TOGGLE_EMPTY_TROLLEY_MODAL,
  TOGGLE_MODAL,
  TOGGLE_NAV_MENU,
  TOGGLE_PAYMENT_CARD,
  UNLOCK_BODY_SCROLL,
  SPA_TRANSITION,
  REMOVE_OPTIMISTIC_DELETE_SELECTION,
  ADD_OPTIMISTIC_DELETE_SELECTION,
  REMOVE_OPTIMISTIC_DELETE_SELECTION_FROM_UI,
  CLEAR_OPTIMISTIC_DELETE_SELECTION,
  ERROR_WHILE_OPTIMISTIC_DELETE,
  RECEIVE_FAVORITES_LAYOUT_DATA,
} from '#/constants/action-types';
import { addToUrlAndDispatchModal, removeFromUrlAndDispatchModal } from '#/lib/url/modal-utils';
import { request } from '#/lib/client-fetch';
import { NO_RESULT_MODAL } from '#/constants/modal-names';
import { ONBOARDING_COOKIE_PREFIX, ONBOARDING_BANNER_STATES } from '#/constants/onboarding';
import { getIsAmendBasket } from '#/selectors/trolley';
import { getDisplayCookieInterrupt, getJourney } from '#/reducers/ui';
import { chooseStickyBarName } from '#/utils/sticky-bar-utils';
import { DELETE_A_REVIEW_MODAL, FAKE_DOOR_THANK_YOU_MODAL } from '#/constants/modal-names';
import { Dispatch, GetStore, ThunkAction } from '#/custom-typings/redux-store/common';
import {
  OpenLeftNav,
  CloseLeftNav,
  OpenNavMenu,
  CloseNavMenu,
  NavMenu,
  HeaderSearch,
  EmptyTrolleyModal,
  AmendOrderBar,
  ToggleAmendOrderBar,
  StickyBarIsExpanded,
  SpaTransition,
  LockUnlockBodyScroll,
  ToggleModal,
  FavoritesData,
  FilterMenu,
  MultiSearchLinkVisibility,
  DeleteAReviewModal,
  ThankYouModal,
  CardInput,
  OptimisticDeleteSelection,
  ClearOptimisticDeleteSelection,
  ErrorOptimisticDelete,
  FavoritesLayoutData,
} from './ui-action-creators.defs';
import { ModalData } from '#/reducers/types.defs';

export const openLeftNav = (): OpenLeftNav => ({
  type: OPEN_LEFT_NAV,
});

export const closeLeftNav = (): CloseLeftNav => ({
  type: CLOSE_LEFT_NAV,
});

/**
 * Sets UI state to open Nav Menu
 */
export const openNavMenu = (menuKey?: string): OpenNavMenu => ({
  type: OPEN_NAV_MENU,
  payload: { menuKey },
});

/**
 * Sets UI state to close Nav Menu
 */
export const closeNavMenu = (): CloseNavMenu => ({
  type: CLOSE_NAV_MENU,
});

/**
 * Sets UI state to toggle Nav Menu
 */
export const toggleNavMenu = (menuKey?: string): NavMenu => ({
  type: TOGGLE_NAV_MENU,
  payload: { menuKey },
});

export const showHeaderSearch = (): HeaderSearch => ({
  type: SHOW_HEADER_SEARCH,
});

export const hideHeaderSearch = (): HeaderSearch => ({
  type: HIDE_HEADER_SEARCH,
});

/**
 * toggles the empty trolley modal on trolley page.
 */
export const toggleEmptyTrolleyModal = (): EmptyTrolleyModal => ({
  type: TOGGLE_EMPTY_TROLLEY_MODAL,
});

export const hideAmendOrderBar = (): AmendOrderBar => ({
  type: HIDE_AMEND_ORDER_BAR,
});

export const showAmendOrderBar = (): AmendOrderBar => ({
  type: SHOW_AMEND_ORDER_BAR,
});

export const toggleAmendOrderBar = (isOpen: boolean): ToggleAmendOrderBar => ({
  type: TOGGLE_AMEND_ORDER_BAR,
  payload: {
    isOpen,
  },
});

export const setStickyBarIsExpanded = (isVisible: boolean): StickyBarIsExpanded => ({
  type: SET_STICKY_BAR_EXPANDED,
  payload: {
    isVisible,
  },
});

export const spaTransition = (transitioning: boolean): SpaTransition => ({
  type: SPA_TRANSITION,
  payload: transitioning,
});

export const chooseStickyBar = () => (dispatch: Dispatch, getState: GetStore): void => {
  const state = getState();

  const stickyBarName = chooseStickyBarName({
    isAmendBasket: getIsAmendBasket(state),
    journey: getJourney(state),
    displayCookieInterrupt: getDisplayCookieInterrupt(state),
  });

  dispatch({
    type: SET_STICKY_BAR_NAME,
    payload: {
      name: stickyBarName,
    },
  });
};

/**
 * locks the body of the page from scrolling
 * usually used when a panel or overlay is applied
 */
export const lockBodyScroll = (): LockUnlockBodyScroll => ({
  type: LOCK_BODY_SCROLL,
});

/**
 * unlocks the body of the page from scrolling
 * usually used when a panel or overlay is removed
 */
export const unlockBodyScroll = (): LockUnlockBodyScroll => ({
  type: UNLOCK_BODY_SCROLL,
});

export const toggleModal = (modalName: string): ToggleModal => ({
  type: TOGGLE_MODAL,
  payload: {
    modalName,
  },
});

export const openModal = (modalName: string, modalData: ModalData | null = null, addToUrl = true) => (
  dispatch: Dispatch,
): void => {
  const dispatchValue = {
    type: OPEN_MODAL,
    payload: {
      modalName,
      modalData: modalData && typeof modalData === 'object' ? { ...modalData } : modalData,
    },
  };
  const closeDispatchValue = {
    type: CLOSE_MODAL,
  };

  return addToUrl
    ? dispatch(addToUrlAndDispatchModal(modalName, modalData, dispatchValue, closeDispatchValue))
    : dispatch(dispatchValue);
};

export const closeModal = (_?: unknown, keysToRemove = {}): ThunkAction<void> => {
  return removeFromUrlAndDispatchModal(
    {
      type: CLOSE_MODAL,
    },
    keysToRemove,
  );
};
export const toggleCardInput = (): CardInput => ({
  type: TOGGLE_PAYMENT_CARD,
});

export const setOnboardingBannerVisibility = (
  actionLink: string,
  bannerName: string,
  visible: boolean,
): ThunkAction<Promise<void>> | ThunkAction<void> => {
  const cookieValue = visible ? ONBOARDING_BANNER_STATES.VISIBLE : ONBOARDING_BANNER_STATES.HIDDEN;

  return (dispatch: Dispatch): void => {
    request
      .put(actionLink, {
        body: JSON.stringify({
          cookieName: ONBOARDING_COOKIE_PREFIX + bannerName,
          cookieValue,
        }),
      })
      .catch(() => {
        // This action is fire & forget
      });

    dispatch({
      type: SET_ONBOARDING_BANNER_VISIBILITY,
      bannerName,
      visible,
    });
  };
};

export const receiveFavoritesData = (tab: string): FavoritesData => ({
  type: RECEIVE_FAVORITES_DATA,
  payload: {
    newTab: tab,
  },
});

export const openFilterMenu = (): FilterMenu => ({
  type: OPEN_FILTER_MENU,
});

export const closeFilterMenu = (): FilterMenu => ({
  type: CLOSE_FILTER_MENU,
});

export function setMultiSearchLinkVisibility(visible: boolean): MultiSearchLinkVisibility {
  return {
    type: SET_MULTI_SEARCH_LINK_VISIBILITY,
    visibility: visible,
  };
}

export const openDeleteAReviewModal = (reviewId: string): DeleteAReviewModal => ({
  type: DELETE_A_REVIEW_MODAL,
  value: { reviewId },
});

export const openThankYouModal = (modalData: string): ThankYouModal => ({
  type: FAKE_DOOR_THANK_YOU_MODAL,
  value: modalData,
});

export const displayNoResultModal = () => (dispatch: Dispatch): void => {
  dispatch(openModal(NO_RESULT_MODAL));
};
export const removeOptimisticDeleteSelection = (
  modalData: OptimisticDeleteSelection['value'],
): OptimisticDeleteSelection => ({
  type: REMOVE_OPTIMISTIC_DELETE_SELECTION,
  value: modalData,
});

export const addOptimisticDeleteSelection = (
  modalData: OptimisticDeleteSelection['value'],
): OptimisticDeleteSelection => ({
  type: ADD_OPTIMISTIC_DELETE_SELECTION,
  value: modalData,
});

export const removeOptimisticDeleteSelectionFromUI = (page: string): ClearOptimisticDeleteSelection => ({
  type: REMOVE_OPTIMISTIC_DELETE_SELECTION_FROM_UI,
  value: {
    page,
  },
});

export const clearOptimisticDeleteSelection = (page: string): ClearOptimisticDeleteSelection => ({
  type: CLEAR_OPTIMISTIC_DELETE_SELECTION,
  value: {
    page,
  },
});

export const receiveFavoritesLayoutData = (layout: string): FavoritesLayoutData => ({
  type: RECEIVE_FAVORITES_LAYOUT_DATA,
  payload: {
    newLayout: layout,
  },
});

export const updateOptimisticDeleteErrorState = (page: string, hasError: boolean): ErrorOptimisticDelete => ({
  type: ERROR_WHILE_OPTIMISTIC_DELETE,
  value: {
    page,
    hasError,
  },
});
