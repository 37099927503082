import React, { Component } from 'react';
import PropTypes from 'prop-types';
import page from 'page';
import * as filterType from '#/constants/facet-categories';
import { translateUnbrandedFacet } from '#/utils/product-filter-utils';
import { getCurrentFacets } from '#/reducers/filter';
import helpers from '#/lib/decorators/helpers';
import { getCurrentUrl } from '#/reducers/app';
import { connect } from '#/lib/render/connect-deep-compare';
import { pluck } from '#/lib/object-helpers';

import {
  removeAllFiltersUrl,
  removeFilterUrl
} from '../../../lib/filter-helpers'; // cannot be statically imported due to injectr

const categoryHierarchy = [
  filterType.SUPERDEPARTMENT,
  filterType.DEPARTMENT,
  filterType.AISLE,
  filterType.SHELF
].map(category => category.toLowerCase());

const filterByTypes = [
  filterType.PROMOTION,
  filterType.NEW_PRODUCT,
  filterType.FAVOURITES
].map(type => type.toLowerCase());

const brandHierarchy = [filterType.BRAND].map(brand => brand.toLowerCase());

function mapStateToProps(state, ownProps) {
  return {
    currentFacets: getCurrentFacets(
      state,
      ownProps.c('facetsAllowingMultipleSelections'),
      ownProps.c('categoryDelimiter')
    ),
    currentUrl: getCurrentUrl(state)
  };
}

@helpers(['c', 'currentPathname', 't'])
@connect(mapStateToProps)
export default class AppliedFilters extends Component {
  static propTypes = {
    c: PropTypes.func.isRequired,
    currentFacets: PropTypes.object,
    currentPathname: PropTypes.func.isRequired,
    currentUrl: PropTypes.string.isRequired,
    showPromotionAsApplied: PropTypes.bool,
    t: PropTypes.func.isRequired
  };

  static defaultProps = {
    showPromotionAsApplied: false
  };

  constructor(props, context) {
    super(props, context);

    // TODO: required to be passed for legacy url-helpers, can be removed when url-helpers is refactored
    this.currentPathname = this.props.currentPathname.bind(this);
    this.categoryDelimiter = this.props.c('categoryDelimiter');
  }

  removeRelatedFiltersFromUrl(category, filter) {
    let relatedFilters;

    switch (category) {
      case filterType.BRAND.toLowerCase():
        relatedFilters = brandHierarchy;
        break;
      case filterType.SUPERDEPARTMENT.toLowerCase():
      case filterType.DEPARTMENT.toLowerCase():
      case filterType.AISLE.toLowerCase():
      case filterType.SHELF.toLowerCase():
        relatedFilters = categoryHierarchy;
        break;
      default:
        relatedFilters = [];
    }

    return removeFilterUrl({
      category,
      categoryDelimiter: this.categoryDelimiter,
      currentPathname: this.props.currentPathname(),
      currentUrl: this.props.currentUrl,
      facet: filter,
      filterOrder: relatedFilters,
      multiSelectionFacets: this.props.c('facetsAllowingMultipleSelections')
    });
  }

  hasCurrentFilters() {
    const currentFacets = this.props.currentFacets;

    return Object.keys(currentFacets).some(category => {
      if (category !== 'sortBy' && category !== filterType.PRODUCT_SOURCE) {
        return typeof currentFacets[category] !== 'undefined';
      }

      return false;
    });
  }

  onClickHandler(e, key, facet) {
    e.preventDefault();
    page(this.removeRelatedFiltersFromUrl(key, facet));
  }

  onClearFilterClickHandler(e) {
    e.preventDefault();
    page(removeAllFiltersUrl(this.props.currentUrl));
  }

  render() {
    const { showPromotionAsApplied } = this.props;
    let typesFilter = [...filterByTypes, filterType.PRODUCT_SOURCE];
    if (showPromotionAsApplied) {
      typesFilter = typesFilter.filter(
        item => item !== filterType.PROMOTION.toLowerCase()
      );
    }

    const currentlyAppliedFilters = pluck(
      this.props.currentFacets,
      filter => !typesFilter.includes(filter)
    );

    return (
      <div>
        <ul className="applied-filters" data-auto="applied-filters">
          {Object.keys(currentlyAppliedFilters).map(key =>
            currentlyAppliedFilters[key].map(facet => [
              <li
                className="applied-filter"
                key={facet.facetId}
                data-auto="applied-filter"
              >
                <a href="#" onClick={e => this.onClickHandler(e, key, facet)}>
                  <span className="icon icon-delete-item-blue2" />
                  {facet.category === 'promotion'
                    ? 'Promotions'
                    : translateUnbrandedFacet(facet.facetName, this.props.t)}
                </a>
              </li>,
              ' '
            ])
          )}
          {this.hasCurrentFilters() && (
            <li className="clear-filters">
              <a
                data-auto="clear-filters"
                href={removeAllFiltersUrl(this.props.currentUrl)}
                onClick={e => this.onClearFilterClickHandler(e)}
              >
                {this.props.t('sort-and-filter:clear-all')}
              </a>
            </li>
          )}
        </ul>
      </div>
    );
  }
}
