import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { connect } from '#/lib/render/connect-deep-compare';
import analyticsBus from '#/analytics/analyticsBus';
import { basicEvent } from '#/analytics/types/basic';
import { getLastSelectedSlot } from '#/selectors/trolley';
import helpers from '#/lib/decorators/helpers';
import ProductQuantityControls from '#/components/products/product-quantity-controls';
import SimpleButton from '#/components/shared/simple-button';
import ViewAndBuyButton from '#/components/products/product-tile/tile-components/shared/view-and-buy';
import RenderPrice from '#/components/products/product-tile/tile-components/shared/render-price';
import RenderPricePerUnit from '#/components/products/product-tile/tile-components/shared/render-price-per-unit';
import { shouldShowRestOfShelf } from '#/lib/url/url-utils';
import { getCurrency, getCurrentUrl } from '#/reducers/app';
import {
  VIEW_AND_BUY_TYPE_OFFERS,
  VIEW_AND_BUY_TYPE_FAVORITES
} from '#/constants/display-types';
import { AnalyticsSetting } from '#/components/products/prop-types';
import { FAVORITES } from '#/constants/tile-types';
import {
  getProductShelfId,
  getProductShelfName,
  getProductAisleId,
  canBeDelivered,
  showLink as getShowLink,
  getRestOfShelfUrl,
  generateSlugs,
  isRdgRestricted,
  getProductIsForSale,
  hasSubstitution,
  getUnit,
  getQuantity,
  getProductDrsChargeAmount
} from '#/selectors/item';
import { setCustomerUnitChoice } from '#/lib/records/item-utils';
import { getIsOnDemandDelivery } from '#/selectors/trolley';
import { getAmountWithCurrencyOrMinorCurrency } from '#/lib/string-formatting/currency-formatter';
import { VisuallyHidden } from '#/components/shared/styled';

const mapStateToProps = (state, { type, item }) => {
  const shouldShowPriceOnUnavailableTiles =
    type === FAVORITES && hasSubstitution(item);

  return {
    slot: getLastSelectedSlot(state),
    currentUrl: getCurrentUrl(state),
    shouldShowPriceOnUnavailableTiles,
    showRestOfShelf: !getIsOnDemandDelivery(state),
    currency: getCurrency(state)
  };
};

@connect(mapStateToProps)
@helpers(['c', 'l', 't'])
export default class RenderControls extends Component {
  static propTypes = {
    analyticsSettings: AnalyticsSetting,
    c: PropTypes.func.isRequired,
    catchWeight: PropTypes.number,
    currentUrl: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    displayAvailable: PropTypes.bool,
    hideQuantityToggle: PropTypes.bool,
    identifier: PropTypes.string,
    isAmendBasket: PropTypes.bool,
    isAvailableEpwOverride: PropTypes.bool.isRequired,
    isSpecialOfferVariant: PropTypes.bool,
    item: PropTypes.object.isRequired,
    itemIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    l: PropTypes.func.isRequired,
    resultType: PropTypes.string.isRequired,
    selectedUnit: PropTypes.string,
    sellableUnitPrice: PropTypes.number,
    shouldShowPriceOnUnavailableTiles: PropTypes.bool,
    showRestOfShelf: PropTypes.bool,
    showUnitPrice: PropTypes.bool,
    slot: PropTypes.object,
    t: PropTypes.func.isRequired,
    tileElementId: PropTypes.string,
    updateCatchWeight: PropTypes.func,
    updateReturnUrlWithProductId: PropTypes.bool,
    updateSelectedUnit: PropTypes.func,
    userRegistered: PropTypes.bool,
    viewAndBuyButtonUrl: PropTypes.string,
    viewAndBuyType: PropTypes.oneOf([
      VIEW_AND_BUY_TYPE_OFFERS,
      VIEW_AND_BUY_TYPE_FAVORITES
    ])
  };

  static defaultProps = {
    displayAvailable: false,
    shouldRenderViewAndBuyButton: null,
    updateReturnUrlWithProductId: false,
    analyticsSettings: {
      enableMissedOfferTracking: false
    },
    showUnitPrice: true,
    isSpecialOfferVariant: false
  };

  onClick = () => {
    basicEvent(analyticsBus, {
      type: 'rest of shelf',
      value: 'product tile',
      action: 'delay'
    });
  };

  render() {
    const {
      analyticsSettings,
      c: config,
      catchWeight,
      currentUrl,
      disabled,
      displayAvailable,
      hideQuantityToggle,
      identifier,
      isAmendBasket,
      isAvailableEpwOverride,
      isSpecialOfferVariant,
      item: initialItem,
      itemIndex,
      l: languageLink,
      selectedUnit,
      sellableUnitPrice,
      shouldShowPriceOnUnavailableTiles,
      showRestOfShelf,
      showUnitPrice,
      slot,
      t: translate,
      tileElementId,
      updateCatchWeight,
      updateReturnUrlWithProductId,
      updateSelectedUnit,
      userRegistered,
      viewAndBuyButtonUrl,
      viewAndBuyType,
      resultType,
      currency
    } = this.props;

    const item = setCustomerUnitChoice(initialItem, selectedUnit);
    const showLink = getShowLink(
      item,
      config('showProductUnavailableMessages'),
      currentUrl
    );
    const aisleId = getProductAisleId(item);
    const shelfId = getProductShelfId(item) || aisleId;
    const shelfName = getProductShelfName(item);
    const decodedUrl = decodeURIComponent(currentUrl);

    const { shelfIdSlug, aisleIdSlug } = generateSlugs(item);
    const productDRSAmount = getProductDrsChargeAmount(item);

    let control;

    if (viewAndBuyButtonUrl) {
      control = (
        <div className="view-and-buy--price-button-wrapper">
          <div className="price-details--wrapper">
            <RenderPrice
              isAvailableEpwOverride={isAvailableEpwOverride}
              item={item}
              sellableUnitPrice={sellableUnitPrice}
              selectedUnit={selectedUnit}
            />

            <RenderPricePerUnit
              isAvailableEpwOverride={isAvailableEpwOverride}
              compactMode={true}
              item={item}
            />
            {!!productDRSAmount && (
              <>
                <VisuallyHidden data-visually-hidden>{`+ ${
                  currency?.symbol
                } ${productDRSAmount} ${translate('deposit')}`}</VisuallyHidden>
                <div
                  aria-hidden={true}
                  className="drs deposit-price"
                  data-testid="deposit-charge"
                >{`+ ${getAmountWithCurrencyOrMinorCurrency(
                  productDRSAmount,
                  currency?.symbol,
                  currency?.isoCode
                )} ${translate('deposit')}`}</div>
              </>
            )}
          </div>

          <ViewAndBuyButton
            viewAndBuyType={viewAndBuyType}
            viewAndBuyButtonUrl={viewAndBuyButtonUrl}
          />
        </div>
      );
    } else if (
      displayAvailable ||
      getProductIsForSale(item) ||
      isAvailableEpwOverride
    ) {
      const disabledControls =
        isRdgRestricted(initialItem) || !canBeDelivered(initialItem, slot);

      control = (
        <ProductQuantityControls
          analyticsSettings={analyticsSettings}
          anchorId={tileElementId}
          catchWeight={catchWeight}
          changeUnits={updateSelectedUnit}
          disabled={disabledControls || disabled}
          hideQuantityToggle={hideQuantityToggle}
          identifier={identifier}
          isAmendBasket={isAmendBasket}
          isAvailableEpwOverride={isAvailableEpwOverride}
          item={item}
          itemIndex={itemIndex}
          key="controls"
          oldQuantity={getQuantity(initialItem)}
          oldUnitChoice={getUnit(initialItem)}
          selectedUnit={selectedUnit}
          sellableUnitPrice={sellableUnitPrice}
          showUnitPrice={showUnitPrice}
          updateCatchWeight={updateCatchWeight}
          updateReturnUrlWithProductId={updateReturnUrlWithProductId}
          userRegistered={userRegistered}
          viewAndBuyButtonUrl={viewAndBuyButtonUrl}
          resultType={resultType}
        />
      );
    } else if (
      shelfId &&
      showLink &&
      !hasSubstitution(item) &&
      shouldShowRestOfShelf(decodedUrl, shelfId, shelfIdSlug, aisleIdSlug) &&
      showRestOfShelf
    ) {
      const restOfShelfText = translate('product-tile:more-in-this-category');

      const restOfCatUrl = `categories/${shelfId}`;

      control = (
        <SimpleButton
          href={languageLink(getRestOfShelfUrl(item) || restOfCatUrl)}
          onClick={this.onClick}
          type="secondary"
          buttonText={restOfShelfText}
          buttonClass="more-in-this-category hidden-small hidden-medium-small-only icon-chevron_right-button "
          {...(shelfName && {
            accessibilityText: `${restOfShelfText} ${shelfName}`
          })}
        />
      );
    } else if (shouldShowPriceOnUnavailableTiles) {
      control = (
        <div className="view-and-buy--price-button-wrapper">
          <div className="price-details--wrapper">
            <RenderPrice
              isAvailableEpwOverride={isAvailableEpwOverride}
              item={item}
              sellableUnitPrice={sellableUnitPrice}
              selectedUnit={selectedUnit}
              isUnavailableWithSubstitute={true}
            />

            <RenderPricePerUnit
              isAvailableEpwOverride={isAvailableEpwOverride}
              compactMode={true}
              item={item}
              isUnavailableWithSubstitute={true}
            />
          </div>
        </div>
      );
    }

    if (control) {
      const classes = classnames('product-controls__wrapper', {
        'browse-category-wrapper': showLink,
        'product-controls__wrapper-variant': isSpecialOfferVariant
      });

      return <div className={classes}>{control}</div>;
    }

    return null;
  }
}
