import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ECOUPON_MODAL } from '#/constants/modal-names';
import { openModal } from '#/actions/ui-action-creators';
import { addModalToUrl } from '#/lib/url/modal-utils';
import { getCurrentUrl } from '#/reducers/app';
import { connect } from '#/lib/render/connect-deep-compare';
import { getUrlWithJourneyParam } from '#/lib/url/journey-utils';
import { AMEND_COUPONS_JOURNEY } from '#/constants/journey-names';
import { getCheckoutEntryPoint } from '#/utils/checkout-utils';
import helpers from '#/lib/decorators/helpers';

const mapStateToProps = (state, { c: config }) => ({
  checkoutEntryPoint: getCheckoutEntryPoint(state),
  currentUrl: getCurrentUrl(state),
  checkoutInAmend: config('checkout:enableInAmendMode')
});

@helpers(['c', 'l'])
@connect(mapStateToProps, { openModal })
export default class CouponModalLink extends PureComponent {
  static propTypes = {
    checkoutEntryPoint: PropTypes.string.isRequired,
    checkoutInAmend: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    currentUrl: PropTypes.string.isRequired,
    openModal: PropTypes.func.isRequired
  };

  onClick = e => {
    const { openModal } = this.props;

    e.preventDefault();
    openModal(ECOUPON_MODAL);
  };

  render() {
    const {
      checkoutEntryPoint,
      className,
      currentUrl,
      children,
      checkoutInAmend
    } = this.props;

    if (checkoutInAmend) {
      const href = getUrlWithJourneyParam(
        checkoutEntryPoint,
        AMEND_COUPONS_JOURNEY,
        [AMEND_COUPONS_JOURNEY]
      );
      return (
        <a className={className} href={href}>
          {children}
        </a>
      );
    }

    return (
      <a
        className={className}
        href={addModalToUrl(currentUrl, ECOUPON_MODAL)}
        onClick={this.onClick}
      >
        {children}
      </a>
    );
  }
}
