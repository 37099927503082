import { updateRegionInKey, getFeatureVariableStringFromState } from '#/lib/optimizely-manager';
import { PlainObject } from '#/types';
import { baggingOptionsFeatureToggle } from '#/constants/features';

export function getHideBaggingOptionsFeature(state: PlainObject): boolean {
  const { featureKey, variationKey, useCustomerId } = baggingOptionsFeatureToggle;
  const regionInKey = updateRegionInKey(featureKey, state);

  return getFeatureVariableStringFromState(state, regionInKey, variationKey, { useCustomerId }) === 'off';
}
