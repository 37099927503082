import { Request, Response } from 'express';
import { getFeatureConfigurationFromReqRes } from '#/lib/optimizely-manager';
import {
  INSPIRE_ME_ZONE_PAGE_KEY,
  INSPIRE_ME_TAXONOMY_KEY,
  InspireMeTopNavConfig,
  topNavInspireMeConfig,
  INSPIRE_ME_MENU_KEY,
  ALL,
} from '#/experiments/oop-1890/constants';
import { getNavList } from '#/reducers/taxonomy';

const INSPIRE_AND_EVENTS_KEY = 'inspiration & events';

type InspireMeNavList = {
  catId: string | null;
  label: string;
  name: string;
  url: string;
  allUrl: string;
  children: [];
};

const { featureKey, useCustomerId, serviceConfig } = topNavInspireMeConfig;

export const getInspireMeTopNavConfig = (req: Request, res: Response): InspireMeTopNavConfig =>
  getFeatureConfigurationFromReqRes(req, res, featureKey, {
    useCustomerId,
    serviceConfig,
  });

export const getIsInspireMeZonePageNav = (key: string): boolean => key === INSPIRE_ME_ZONE_PAGE_KEY;
export const getIsInspireMeTaxonomyNav = (key: string): boolean => key === INSPIRE_ME_TAXONOMY_KEY;
export const getIsInspireMeMenuKey = (key: string): boolean => key === INSPIRE_ME_MENU_KEY;
export const getIsInspireMeMenuOpen = ({ ui: { menuOpen } }: Store): boolean => menuOpen === INSPIRE_ME_MENU_KEY;
export const getIsExploreAll = (name: string): boolean => name?.toLowerCase().includes(ALL);

export function getInspireMeNavList(state: Store): InspireMeNavList | object {
  // @ts-expect-error
  const navListData = getNavList(state);

  if (navListData) {
    const navItems = navListData.filter(nav => {
      return nav.name?.toLowerCase().includes(INSPIRE_AND_EVENTS_KEY);
    });
    if (navItems.length && navItems[0].children?.length) {
      return navItems[0] || {};
    }
  }

  return {};
}
