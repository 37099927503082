/**
 * Used to notify mobile apps when webview basket updateSearch.
 */
export const refreshBasket = (): void => {
  if (window?.webkit) {
    // ios
    window.webkit.messageHandlers?.nativeBus?.postMessage('refreshBasket');
  } else if (window?.ZonePageInterface) {
    // android
    window.ZonePageInterface.refreshBasket();
  }
};
