import { updateRegionInKey, getFeatureVariableStringFromState } from '#/lib/optimizely-manager';
import { favNavLinkTooltipConfig } from '#/experiments/oop-1263/constants';
import { getIsCurrentPage } from '#/reducers/app';
import { getIsFirstTimeShopper } from '#/reducers/user';

export type Config = { heading: string; body: string; buttonText: string };

export function getFavNavLinkTooltipConfig(state: Store): Config {
  const { featureKey, useCustomerId } = favNavLinkTooltipConfig;
  const regionInKey = updateRegionInKey(featureKey, state);
  const isFirstTimeShopper = getIsFirstTimeShopper(state);
  const attributes = { hasFavourites: String(!isFirstTimeShopper) };

  return {
    heading: getFeatureVariableStringFromState(state, regionInKey, 'popover-heading', { useCustomerId, attributes }),
    body: getFeatureVariableStringFromState(state, regionInKey, 'popover-copy', { useCustomerId, attributes }),
    buttonText: getFeatureVariableStringFromState(state, regionInKey, 'popover-button', { useCustomerId, attributes }),
  };
}

export function getShowFavNavLinkTooltip(state: Store): boolean {
  const {
    featureKey,
    useCustomerId,
    variationKey,
    variants: { off },
  } = favNavLinkTooltipConfig;
  const isOnHomePage = getIsCurrentPage(state, ['']);

  if (!isOnHomePage) {
    return false;
  }

  const regionInKey = updateRegionInKey(featureKey, state);
  const isFirstTimeShopper = getIsFirstTimeShopper(state);
  const attributes = { hasFavourites: String(!isFirstTimeShopper) };
  const enabled =
    getFeatureVariableStringFromState(state, regionInKey, variationKey, { useCustomerId, attributes }) || off;

  return enabled !== off;
}
