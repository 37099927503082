import styled from 'styled-components';
import { LinkButton } from '@ddsweb/button';
import { fontSize } from '@ddsweb/selectors';

export const StyledButton = styled(LinkButton)`
  font-weight: normal;
  font-size: ${fontSize.xx};
  height: 100%;

  @media only screen and (min-width: 992px) and (max-width: 1016px) {
    ${LinkButton.IconContainer} {
      display: none;
    }
  }
`;
