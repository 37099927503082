import styled from 'styled-components';
import { spacing } from '@ddsweb/selectors';
import { Heading } from '@ddsweb/heading';
import Button from '@ddsweb/button';
import { media } from '@ddsweb/foundation';
import { Text } from '@ddsweb/text';

export const StyledTitle = styled(Heading)`
  && {
    margin: ${spacing.md} 0px;
  }
`;

export const StyledButton = styled(Button)`
  margin-top: ${spacing.md};
  border-radius: 20px;
  width: 100%;

  ${media.aboveTabletLarge`
    width: 216px;
  `}
`;

export const StyledText = styled(Text)`
  && {
    margin-bottom: ${spacing.xs};
  }
`;
