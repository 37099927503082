import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { TradeTileBase } from '../trade-tile-base';
import {
  FULL,
  HALF,
  QUARTER,
  THREE_FIFTHS,
  HORIZONTAL,
  VERTICAL
} from '#/constants/dcs';

const TradeTileHorizontal = props => {
  const {
    background,
    body,
    componentClickHandler,
    ctaColor,
    desktopInvert,
    image,
    mobileBody,
    mobileStyle,
    panelColour,
    ping,
    placement,
    primaryLink,
    secondaryLink,
    size,
    tabletStyle,
    textColor,
    title
  } = props;

  const stampClasses = classnames(
    'trade-tile--horizontal',
    `trade-tile--size-${size}`,
    {
      'trade-tile--desktop-invert':
        [HALF, THREE_FIFTHS].includes(size) && desktopInvert,
      'trade-tile--desktop-horizontal': [HALF, THREE_FIFTHS].includes(size),
      'trade-tile--tablet-horizontal':
        [HALF, THREE_FIFTHS].includes(size) && tabletStyle === HORIZONTAL,
      'trade-tile--tablet-width-half':
        [HALF, THREE_FIFTHS].includes(size) && tabletStyle === VERTICAL,
      'trade-tile--tablet-horizontal trade-tile--desktop-horizontal trade-tile--desktop-width-half':
        size === QUARTER,
      'trade-tile--mobile-horizontal trade-tile--mobile-hide-body':
        size === QUARTER && !mobileBody,
      'trade-tile--desktop-image-three-fifths': size === THREE_FIFTHS,
      'trade-tile--mobile-width-half':
        (size === QUARTER && mobileBody) ||
        (size === HALF && mobileStyle === HALF),
      'trade-tile--mobile-hide-body': size === HALF && !mobileBody,
      'trade-tile--panel-colour': panelColour
    }
  );

  const onClick = () => componentClickHandler?.(props);

  return (
    <TradeTileBase
      background={background}
      body={body}
      ctaColor={ctaColor}
      image={image}
      onClick={onClick}
      panelColour={panelColour}
      primaryLink={primaryLink}
      ping={ping}
      placement={placement}
      secondaryLink={secondaryLink}
      stampClasses={stampClasses}
      textColor={textColor}
      title={title}
    />
  );
};

TradeTileHorizontal.propTypes = {
  background: PropTypes.shape({
    texturedImageUrl: PropTypes.string
  }),
  body: PropTypes.string.isRequired,
  componentClickHandler: PropTypes.func,
  ctaColor: PropTypes.shape({
    color: PropTypes.string
  }),
  desktopInvert: PropTypes.bool,
  image: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    maxWidth: PropTypes.number
  }).isRequired,
  mobileBody: PropTypes.bool,
  mobileStyle: PropTypes.oneOf([FULL, HALF]),
  panelColour: PropTypes.object,
  ping: PropTypes.shape({
    src: PropTypes.string,
    label: PropTypes.string
  }),
  placement: PropTypes.number,
  primaryLink: PropTypes.shape({
    href: PropTypes.string,
    label: PropTypes.string,
    newWindow: PropTypes.bool
  }).isRequired,
  secondaryLink: PropTypes.shape({
    href: PropTypes.string,
    label: PropTypes.string,
    newWindow: PropTypes.bool
  }),
  size: PropTypes.oneOf([THREE_FIFTHS, HALF, QUARTER]).isRequired,
  tabletStyle: PropTypes.oneOf([FULL, VERTICAL, HORIZONTAL]),
  textColor: PropTypes.shape({
    color: PropTypes.string
  }),
  title: PropTypes.string.isRequired
};

TradeTileHorizontal.defaultProps = {
  componentClickHandler: null,
  desktopInvert: false,
  mobileBody: true,
  mobileStyle: FULL,
  panelColour: null,
  ping: null,
  placement: 1,
  secondaryLink: null,
  tabletStyle: FULL
};

export { TradeTileHorizontal };
