import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import helpers from '#/lib/decorators/helpers';
import { gaussianRound } from '#/lib/string-formatting/format';
import { parseQueryString } from '#/lib/url/url-utils';
import { WEIGHT_UNIT_KG, WEIGHT } from '#/constants/common';
import {
  ADD_MODE,
  ADDED_MODE,
  BLOCKED_UPDATE_MODE,
  BLOCKED_ADD_MODE,
  BLOCKED_ADDED_MODE,
  UPDATE_MODE
} from './constants';
import SelectWeight from './select-weight';
import TileButtons from './tile-buttons';
import InfoMessage from '../../../info-message';
import { UNDELIVERABLE_ADDRESS_MODAL } from '#/constants/modal-names';
import { AnalyticsSetting } from '../../../prop-types';
import {
  markInteractionEnd,
  markInteractionStart
} from '#/utils/interaction-perf';

const ENTER = 'Enter';

@helpers(['t', 'c'])
export default class InputControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.getInputValue(props),
      focused: false,
      shouldUpdate: false,
      isItemUpdated: false
    };
  }

  static propTypes = {
    analyticsSettings: AnalyticsSetting,
    averageWeight: PropTypes.number,
    basketId: PropTypes.string,
    c: PropTypes.func.isRequired,
    currency: PropTypes.object.isRequired,
    currentUrl: PropTypes.string.isRequired,
    customerUnitChoice: PropTypes.string.isRequired,
    deliveryAddress: PropTypes.object,
    disableAdd: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    disableRemove: PropTypes.bool.isRequired,
    identifier: PropTypes.string,
    incrementItemBy: PropTypes.func.isRequired,
    isAmendBasket: PropTypes.bool.isRequired,
    isAvailableEpwOverride: PropTypes.bool.isRequired,
    isCatchWeightProduct: PropTypes.bool.isRequired,
    isCatchWeightUpdated: PropTypes.bool.isRequired,
    isItemRemoved: PropTypes.bool,
    itemAtMaxQty: PropTypes.bool.isRequired,
    itemAtMinQty: PropTypes.bool.isRequired,
    itemBulkBuyLimit: PropTypes.number.isRequired,
    itemDefaultUnit: PropTypes.string.isRequired,
    itemId: PropTypes.string.isRequired,
    itemInBasket: PropTypes.bool.isRequired,
    itemIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    itemPrice: PropTypes.number.isRequired,
    itemTitle: PropTypes.string.isRequired,
    openModal: PropTypes.func.isRequired,
    originalCatchWeight: PropTypes.number,
    originalCustomerUnitChoice: PropTypes.string,
    originalProductWeight: PropTypes.number,
    originalQuantity: PropTypes.number.isRequired,
    quantity: PropTypes.number.isRequired,
    removeItem: PropTypes.func.isRequired,
    resultType: PropTypes.string.isRequired,
    selectedUnit: PropTypes.string,
    setItemQuantityTo: PropTypes.func.isRequired,
    shouldOverridePreviousItem: PropTypes.bool,
    substitutionFor: PropTypes.object,
    t: PropTypes.func.isRequired,
    userRegistered: PropTypes.bool.isRequired,
    withCatchWeight: PropTypes.func.isRequired
  };

  static defaultProps = {
    analyticsSettings: {
      enableMissedOfferTracking: false
    }
  };

  componentWillReceiveProps(nextProps) {
    const inputValue = this.getInputValue(
      nextProps,
      this.state.isItemUpdated,
      this.state.inputValue
    );
    const selectedUnitChanged =
      this.props.selectedUnit !== nextProps.selectedUnit;

    if (selectedUnitChanged && !nextProps.isItemRemoved) {
      inputValue.inputValue = this.state.inputValue;
    }

    if (!inputValue.inputValue) {
      inputValue.inputValue = 1;
    }

    let newState = { ...inputValue };

    if (selectedUnitChanged) {
      //catches scenario when user updates quantity/weight and toggles quantity/weight before updating
      newState.shouldUpdate =
        (this.state.shouldUpdate &&
          this.props.quantity !== nextProps.quantity) ||
        false;
    }

    const { itemId } = this.props;

    this.setState(newState, () => {
      markInteractionEnd(itemId);
    });
  }

  getInputValue(props, isItemUpdated, currentInputValue) {
    let inputValue;
    let showError = false;

    const {
      averageWeight,
      c,
      currentUrl,
      isItemRemoved,
      itemAtMinQty,
      itemId,
      itemInBasket,
      originalCatchWeight,
      originalCustomerUnitChoice,
      originalQuantity,
      originalProductWeight,
      quantity,
      shouldOverridePreviousItem
    } = props;
    const queryStringValue = parseQueryString(currentUrl)['invalidUpdate'];
    const qs = queryStringValue ? queryStringValue.split('|') : [];
    const customerUnitChoice = props.customerUnitChoice;
    let unitChoice;
    let catchWeight = props.catchWeight;

    if (shouldOverridePreviousItem) {
      if (itemInBasket) {
        catchWeight =
          isItemUpdated || props.isCatchWeightUpdated
            ? catchWeight
            : originalCatchWeight;
        inputValue =
          currentInputValue && isItemUpdated ? quantity : originalQuantity;

        if (isItemUpdated) {
          unitChoice = customerUnitChoice;
        } else if (!currentInputValue || !isItemUpdated) {
          unitChoice = originalCustomerUnitChoice;
        }
      } else {
        if (!currentInputValue) {
          catchWeight = originalCatchWeight;

          if (originalQuantity) {
            inputValue = originalQuantity;
            unitChoice = originalCustomerUnitChoice;
          } else {
            inputValue = c('defaultProductQuantity');
            unitChoice = customerUnitChoice;
          }
          inputValue = originalQuantity;
          unitChoice = originalCustomerUnitChoice;
        } else {
          inputValue = currentInputValue;
        }
      }
    } else {
      if (itemInBasket) {
        inputValue = quantity;
        unitChoice = customerUnitChoice;
      } else {
        inputValue = c('defaultProductQuantity');
        unitChoice = customerUnitChoice;
      }
    }

    if (qs.length) {
      showError = itemId === qs[0];
    }

    if (unitChoice === WEIGHT_UNIT_KG) {
      if (shouldOverridePreviousItem) {
        const weight = originalProductWeight;

        if (!isItemUpdated || isItemRemoved) {
          inputValue = weight / averageWeight;
        }

        if (isItemUpdated) {
          inputValue = (itemInBasket ? quantity : weight) / averageWeight;
        }
      } else if (!shouldOverridePreviousItem && !itemAtMinQty) {
        inputValue = inputValue / averageWeight;
      }
    }

    return {
      inputValue: Math.round(inputValue),
      customerUnitChoice: unitChoice,
      catchWeight,
      showError
    };
  }

  handleQuantityInputChange = e => {
    const format = new RegExp(this.props.c('inputProductQuantityFormat'));
    const itemQty = this.props.quantity;
    const value = e.target.value;

    if (!value || !format || format.test(value)) {
      const newQuantity = value && !isNaN(value) ? Number(value) : '';

      this.canIncreaseQuantity(newQuantity) &&
        this.setState({
          inputValue: newQuantity,
          shouldUpdate: itemQty !== newQuantity && itemQty > 0,
          showError: false
        });
    }
  };

  addItemBlocked() {
    const { deliveryAddress, openModal } = this.props;

    openModal(UNDELIVERABLE_ADDRESS_MODAL, deliveryAddress.name);
  }

  canIncreaseQuantity(newQuantity) {
    const { isAmendBasket, itemIsExcluded, originalQuantity } = this.props;

    return !(isAmendBasket && itemIsExcluded && newQuantity > originalQuantity);
  }

  shouldBeDisabled() {
    const state = this.state;
    const { atMaxQty, disabled } = this.props;

    return (
      !!disabled ||
      (!state.shouldUpdate && atMaxQty) ||
      state.showError ||
      state.inputValue === '' ||
      state.inputValue === null ||
      +state.inputValue < 0 ||
      (!state.inputValue && !state.shouldUpdate)
    );
  }

  handleQuantityKeyPress = (event, buttonsRenderMode) => {
    if (this.props.userRegistered && event.key === ENTER) {
      const shouldUpdate = !this.shouldBeDisabled();
      const blockedModes = [
        BLOCKED_ADD_MODE,
        BLOCKED_ADDED_MODE,
        BLOCKED_UPDATE_MODE
      ];

      if (shouldUpdate) {
        if (blockedModes.includes(buttonsRenderMode)) {
          this.addItemBlocked();
        } else {
          this.onUpdate();
        }
      }

      event.preventDefault();
    } else if (
      !this.props.userRegistered &&
      event.key === ENTER &&
      !Number(this.state.inputValue)
    ) {
      event.preventDefault();
    }
  };

  handleWeightSelectionChange = e => {
    const newQuantity = Number(e.target.selectedIndex + 1);
    const selectedValue = e.target.options[e.target.selectedIndex].getAttribute(
      'value'
    );

    this.setState({
      inputValue: newQuantity,
      shouldUpdate:
        gaussianRound(this.props.quantity, 2) !== Number(selectedValue) &&
        this.props.quantity > 0
    });
  };

  scrollToAddedSubstitute = () => {
    window.scrollTo(0, 0); // Assume added substitute is at top of page
  };

  onAdd = () => {
    const {
      identifier,
      incrementItemBy,
      analyticsSettings,
      itemIndex,
      withCatchWeight,
      itemId,
      resultType
    } = this.props;

    markInteractionStart(`${resultType}_buybox_legacy_add`, itemId);

    const qty = this.state.inputValue;

    this.setState({ isItemUpdated: true }, () => {
      if (this.props.substitutionFor) {
        incrementItemBy(qty, withCatchWeight(this.state.catchWeight), {
          identifier: 'RCMD:SUB',
          gridPos: itemIndex,
          ...analyticsSettings
        });
        this.props.removeItem(this.props.substitutionFor, {
          sendAnalytics: false
        });
        this.scrollToAddedSubstitute();
      } else {
        incrementItemBy(qty, withCatchWeight(this.state.catchWeight), {
          identifier,
          gridPos: itemIndex,
          ...analyticsSettings
        });
      }
    });
  };

  onUpdateQuantity = qty => {
    const { itemId, resultType } = this.props;

    if (qty === 1) {
      markInteractionStart(`${resultType}_buybox_legacy_plus`, itemId);
    } else {
      markInteractionStart(`${resultType}_buybox_legacy_minus`, itemId);
    }

    const {
      identifier,
      withCatchWeight,
      analyticsSettings,
      itemIndex
    } = this.props;

    this.setState({ isItemUpdated: true }, () => {
      this.props.incrementItemBy(qty, withCatchWeight(this.state.catchWeight), {
        identifier,
        gridPos: itemIndex,
        ...analyticsSettings
      });
    });
  };

  onUpdate = () => {
    if (
      this.state.inputValue !== this.props.quantity ||
      this.props.shouldOverridePreviousItem
    ) {
      const {
        identifier,
        withCatchWeight,
        analyticsSettings,
        itemIndex,
        onAdd,
        itemId,
        resultType
      } = this.props;

      markInteractionStart(`${resultType}_buybox_legacy_update`, itemId);

      if (typeof onAdd === 'function' && !this.props.quantity) {
        onAdd();
      }

      this.setState({ shouldUpdate: false, isItemUpdated: true }, () => {
        this.props.setItemQuantityTo(
          this.state.inputValue,
          withCatchWeight(this.state.catchWeight),
          { identifier, gridPos: itemIndex, ...analyticsSettings }
        );
      });
    }
  };

  onFocus = () => {
    const qty = this.props.quantity;

    this.setState({
      focused: true,
      shouldUpdate: qty && this.state.inputValue !== qty
    });
  };

  onBlur = () => {
    this.setState({
      focused: false
    });
  };

  render() {
    const {
      averageWeight,
      basketId,
      catchWeight,
      currentUrl,
      disableAdd,
      disableRemove,
      isAvailableEpwOverride,
      isCatchWeightProduct,
      itemBulkBuyLimit,
      itemDefaultUnit,
      itemId,
      itemInBasket,
      itemIndex,
      itemPrice,
      itemTitle,
      selectedUnit,
      shouldOverridePreviousItem,
      userRegistered,
      deliveryAddress
    } = this.props;
    const itemQuantity = this.props.quantity;
    const inputValue = this.state.inputValue;
    const inputMaxValue = this.props.c('inputMaxValue');
    const disabled = this.props.disabled;
    const productId = `id_${itemId}`;
    const isQuantityUnit = selectedUnit !== WEIGHT_UNIT_KG;
    let controlId = productId;
    let buttonsRenderMode = ADD_MODE;
    let textKey =
      selectedUnit === 'kg'
        ? 'product-list:weight-of'
        : 'product-list:quantity-of';

    if (typeof itemIndex !== 'undefined') {
      controlId = productId + '_' + itemIndex;
    }

    if (userRegistered && deliveryAddress && deliveryAddress.isBlockedAddress) {
      buttonsRenderMode = BLOCKED_ADD_MODE;

      if (itemQuantity > 0) {
        buttonsRenderMode = this.state.shouldUpdate
          ? BLOCKED_UPDATE_MODE
          : BLOCKED_ADDED_MODE;
      }
    } else if (itemQuantity > 0) {
      if (shouldOverridePreviousItem) {
        buttonsRenderMode = ADD_MODE;

        if (itemInBasket) {
          const quantity =
            this.state.customerUnitChoice === WEIGHT_UNIT_KG &&
            itemDefaultUnit !== WEIGHT
              ? gaussianRound(this.state.inputValue * averageWeight, 2)
              : this.state.inputValue;

          const isQuantityNotEqual =
            (isCatchWeightProduct && this.state.catchWeight !== catchWeight) ||
            quantity !== itemQuantity;

          buttonsRenderMode =
            this.state.shouldUpdate ||
            (!this.state.isItemUpdated && isQuantityNotEqual)
              ? UPDATE_MODE
              : ADDED_MODE;
        }
      } else {
        buttonsRenderMode = this.state.shouldUpdate ? UPDATE_MODE : ADDED_MODE;
      }

      textKey = 'common:update';
    }

    const accessibilityText = `${this.props.t(textKey)} ${itemTitle}`;

    return (
      <div
        className={classNames('inputControl-wrapper', {
          'weight-type': selectedUnit === WEIGHT_UNIT_KG
        })}
      >
        {!this.props.disableAdd && (
          <label className="visually-hidden" htmlFor={controlId}>
            {accessibilityText}
          </label>
        )}

        {isQuantityUnit && (
          <input
            data-auto="product-input"
            className="product-input"
            autoComplete="off"
            name="newValue"
            type="number"
            disabled={disabled}
            id={controlId}
            value={inputValue}
            onChange={this.handleQuantityInputChange}
            onKeyPress={event =>
              this.handleQuantityKeyPress(event, buttonsRenderMode)
            }
            onBlur={this.onBlur}
            onFocus={this.onFocus}
            max={inputMaxValue}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={this.state.focused}
          />
        )}
        {!isQuantityUnit && (
          <SelectWeight
            currency={this.props.currency}
            displayIndex={inputValue || 1}
            id={controlId}
            disabled={isAvailableEpwOverride}
            averageWeight={averageWeight}
            max={itemBulkBuyLimit}
            price={itemPrice}
            onChange={this.handleWeightSelectionChange}
          />
        )}

        <TileButtons
          basketId={basketId}
          currentUrl={currentUrl}
          deliveryAddress={this.props.deliveryAddress}
          disableAdd={disableAdd}
          disabled={disabled}
          disableRemove={disableRemove}
          inputValue={inputValue.toString()}
          itemInBasket={itemInBasket}
          itemTitle={itemTitle}
          onAdd={this.onAdd}
          onUpdate={this.onUpdate}
          openModal={this.props.openModal}
          renderMode={buttonsRenderMode}
          unit={selectedUnit}
          updateQuantity={this.onUpdateQuantity}
          userRegistered={userRegistered}
        />

        {this.state.showError && (
          <InfoMessage
            backgroundColorClass="error-message"
            message={this.props.t('product-tile:input-validation-error')}
          />
        )}
      </div>
    );
  }
}
