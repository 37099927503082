type ExtraInfo = { [key: string]: boolean | number | null | string | undefined | Array<ExtraInfo> | ExtraInfo };

export class ClientFetchError extends Error {
  extraInfo?: ExtraInfo;
  status: number;

  constructor(message: string, status: number, extraInfo?: ExtraInfo) {
    super(message);
    this.status = status;
    this.extraInfo = extraInfo;
  }
}
