import isClient from '#/conditional-resources/conditions/is-client';

export default function isServer(state) {
  // NOTE: The state check is here because this function will
  // get called by the DynamicRouteComponent in mapStateToProps
  // during server-side render (at which point it will have state).
  // At the moment we do not want this to return true at this
  // point. The function also gets called in the spa-server-router
  // file, at which point we do want this function to return true.
  return !isClient() && !state;
}
