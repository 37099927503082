/**
 * Experiment constants for showing usuals or previously bought cta in the empty mini basket
 */
export const usualsCtaInMiniBasketVariants = {
  key: '1426',
  variants: {
    default: '1426-a',
    variantB: '1426-b',
    variantC: '1426-c',
    variantD: '1426-d',
  },
  useCustomerId: true,
};
