import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from '#/lib/render/connect-deep-compare';
import { localStore } from '#/lib/data-store/client-store';
import AnalyticsBus from '#/analytics/analyticsBus';
import { bookedSlotPayload } from '#/analytics/helpers/slot';
import deliverySaverPayload from '#/analytics/helpers/delivery-saver';
import Slot from '#/lib/records/slot';
import { getDeliverySaverPlan } from '#/reducers/customer';
import {
  getAmendOrderId,
  getIsAmendBasket,
  getLastSelectedSlot,
  getTrolleyDeliveryAddressId,
  getTrolleyShoppingMethod
} from '#/selectors/trolley';
import {
  getIsFirstTimeShopper,
  getIsUserAuthenticated,
  getUserHashedUId,
  getUserHashedEmail
} from '#/reducers/user';
import {
  slotExpiresWithinThreshold,
  msUntilSlotExpiry
} from '#/lib/slot/slot-utils';
import helpers from '#/lib/decorators/helpers';
import { getTimezone } from '#/reducers/app';
import { isBooked } from '#/selectors/slot/slot-record';
import {
  getSlotCharge,
  getSlotEnd,
  getSlotLocationId,
  getSlotReservationExpiry,
  getSlotStart,
  getSlotStatus
} from '#/selectors/slot';
import { getPendingOrders } from '#/selectors/order-list-details';

const mapStateToProps = state => {
  return {
    addressId: getTrolleyDeliveryAddressId(state),
    amendOrderId: getAmendOrderId(state),
    deliverySaverPlan: getDeliverySaverPlan(state),
    hashedEmail: getUserHashedEmail(state),
    isAmendBasket: getIsAmendBasket(state),
    isFirstTimeShopper: getIsFirstTimeShopper(state),
    isUserAuthenticated: getIsUserAuthenticated(state),
    pendingOrders: getPendingOrders(state),
    slot: getLastSelectedSlot(state),
    shoppingMethod: getTrolleyShoppingMethod(state),
    timezone: getTimezone(state),
    userHashedUId: getUserHashedUId(state)
  };
};

@helpers(['c'])
@connect(mapStateToProps)
export default class AnalyticsCustomerEvent extends Component {
  static propTypes = {
    addressId: PropTypes.string,
    amendOrderId: PropTypes.string,
    c: PropTypes.func.isRequired,
    deliverySaverPlan: PropTypes.object,
    hashedEmail: PropTypes.string,
    isAmendBasket: PropTypes.bool.isRequired,
    isFirstTimeShopper: PropTypes.bool.isRequired,
    isUserAuthenticated: PropTypes.bool.isRequired,
    orderCount: PropTypes.number,
    pendingOrders: PropTypes.array,
    shoppingMethod: PropTypes.string,
    slot: PropTypes.object,
    timezone: PropTypes.string.isRequired,
    userHashedUId: PropTypes.string.isRequired
  };

  static defaultProps = {
    amendOrderId: '',
    orderCount: 0,
    pendingOrders: [],
    userHashedUId: ''
  };

  componentDidMount() {
    this.firePageLoadedEvent();
  }

  getBreakpoint() {
    const breakpointMarkers = this.refs['breakpoint-markers'];
    const breakpoints = Array.from(breakpointMarkers.childNodes);

    let breakpoint;

    breakpoints.forEach(breakpointNode => {
      if (getComputedStyle(breakpointNode).display === 'block') {
        breakpoint = breakpointNode.getAttribute('data-breakpoint');
      }
    });

    return breakpoint;
  }

  getSlotState(slot) {
    const slotStatus = getSlotStatus(slot);
    const reservationExpiry = getSlotReservationExpiry(slot);
    const bookedSlotData = {
      slotStatus,
      reservationExpiry
    };
    if (slotStatus === null || slotStatus === 'None') {
      return null;
    }
    if (msUntilSlotExpiry(bookedSlotData) <= 0) {
      return 'expired';
    }
    if (
      slotExpiresWithinThreshold(
        bookedSlotData,
        this.props.c('slotExpiryThresholdMinutes')
      )
    ) {
      return 'expiring';
    }
    if (isBooked(slot)) {
      return 'active';
    }
  }

  getPendingOrdersIds() {
    const { pendingOrders } = this.props;

    return Object.keys(pendingOrders).map(
      index => pendingOrders[index].formattedOrderId
    );
  }

  firePageLoadedEvent() {
    const props = this.props;
    const { c: config, addressId, timezone } = props;

    const breakpoint = this.getBreakpoint();
    const pendingOrderIds = this.getPendingOrdersIds();
    const viewportWidth = document.documentElement.clientWidth;

    const slotStatus = getSlotStatus(props.slot);
    const hasActiveSlot =
      slotStatus !== null && slotStatus !== 'None' && slotStatus !== 'Expired';
    const slot = hasActiveSlot ? props.slot : Slot();
    const shoppingMethod = hasActiveSlot ? props.shoppingMethod : null;
    const state = this.getSlotState(slot);
    const locationId = getSlotLocationId(slot);

    let amendStart = false;

    if (localStore && localStore.isSupported) {
      if (props.isAmendBasket) {
        amendStart = !localStore.get('inAmendMode');
        localStore.set('inAmendMode', true);
      } else {
        localStore.remove('inAmendMode');
      }
    }

    AnalyticsBus().emit('customerData', {
      UUID: props.userHashedUId,
      hashedEmail: props.hashedEmail,
      locationId,
      flags: {
        addressId,
        amendMode: props.isAmendBasket,
        amendStart,
        authenticated: props.isUserAuthenticated,
        bookedSlot: bookedSlotPayload({
          start: getSlotStart(slot),
          end: getSlotEnd(slot),
          charge: getSlotCharge(slot),
          currency: config('currency'),
          method: shoppingMethod,
          timezone,
          locationId,
          state
        }),
        deliverySaverCurrent: deliverySaverPayload(props.deliverySaverPlan),
        device: {
          breakpoint: breakpoint,
          width: viewportWidth
        },
        firstTimeShopper: props.isFirstTimeShopper,
        orderCount: props.orderCount,
        orderID: props.amendOrderId,
        pendingOrders: pendingOrderIds
      }
    });
  }

  // Render a div for each breakpoint shown or hidden via CSS, so JS is able to analyse the breakpoints defined in the CSS
  render() {
    return (
      <div ref="breakpoint-markers">
        <div
          data-breakpoint="small"
          className="analytics-customer-event--breakpoint analytics-customer-event--small"
        />
        <div
          data-breakpoint="small-medium"
          className="analytics-customer-event--breakpoint analytics-customer-event--small-medium"
        />
        <div
          data-breakpoint="medium"
          className="analytics-customer-event--breakpoint analytics-customer-event--medium"
        />
        <div
          data-breakpoint="large"
          className="analytics-customer-event--breakpoint analytics-customer-event--large"
        />
        <div
          data-breakpoint="extra-large"
          className="analytics-customer-event--breakpoint analytics-customer-event--extra-large"
        />
      </div>
    );
  }
}
