import moment from 'moment';
import momentRange from 'moment-range';

const momentHandler = {
  tag: 'moment',
  class: moment.fn.constructor,
  write: momentObj => (momentObj.isValid() ? momentObj.format() : null),
  read: momentStr => moment(momentStr)
};

const momentRangeHandler = {
  tag: 'moment-range',
  class: momentRange,
  write: momentObj => momentObj.toString(),
  read: momentStr => {
    const [momentStrStart, momentStrEnd] = momentStr.split('/');

    return moment.range(moment.utc(momentStrStart), moment.utc(momentStrEnd));
  }
};

export const mapHandler = {
  tag: 'map',
  class: Map,
  write: map => [...map.entries()],
  read: mapArray => new Map(mapArray)
};

export const transitHandlers = [momentHandler, momentRangeHandler, mapHandler];

export function serializer(key) {
  const value = this[key];
  const handler = transitHandlers.find(handler => {
    try {
      return value instanceof handler.class;
    } catch (e) {
      return false;
    }
  });

  if (!handler) {
    return value;
  }

  return { __classTag__: handler.tag, serializedData: handler.write(value) };
}

export function deserializer(_, value) {
  const handler = transitHandlers.find(
    handler => value?.__classTag__ === handler.tag
  );

  if (!handler) {
    return value;
  }

  const { serializedData } = value;
  return handler.read(serializedData);
}

function toJSON(jsObject) {
  return JSON.stringify(jsObject, serializer);
}

function fromJSON(jsString) {
  return JSON.parse(jsString, deserializer);
}

export const defaultTransit = {
  toJSON,
  fromJSON
};
