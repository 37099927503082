import analyticsBus from '#/analytics/analyticsBus';
import helpers from '#/lib/decorators/helpers';
import React, { Fragment, useCallback } from 'react';
import PayAtDoorButton from '../pay-at-door-button';
import IconList from '#/components/shared/icon-list';
import { getCardIcons } from '#/lib/card-icons';
import { StyledButtonContainer, StyledLinkButtonWithFeedback } from './styled';
import { COLLECTION } from '#/constants/shopping-methods';
import { PAY_AT_DOOR_CASH, PAY_AT_DOOR_CODE } from '#/constants/payment';
import { connect } from '#/lib/render/connect-deep-compare';
import { isInlineMessagingEnabled } from '#/experiments/oop-1725/selectors';
import { InlinePaymentOptionsButtons } from '#/experiments/oop-1725/components/inline-payment-options-buttons';

export type OwnProps = {
  c: Function;
  confirmationUrl: string;
  continueLinkHref: string;
  legendText?: string;
  showCardIcons: boolean;
  t: Function;
  shoppingMethod: string;
  payAtDoorMethods: Array<string>;
};

type StateProps = {
  inlineMessagingEnabled: boolean;
};

export type Props = OwnProps & StateProps;

const mapStateToProps = (state: Store): StateProps => ({
  inlineMessagingEnabled: isInlineMessagingEnabled(state),
});

const getPayAtDoorTranslation = (
  shoppingMethod: string,
  payAtDoorMethods: Array<string>,
  translate: Function,
): string => {
  if (shoppingMethod === COLLECTION) return translate('checkout:pay-at.pay-at_collection');

  const payAtDoorCash = payAtDoorMethods.includes(PAY_AT_DOOR_CASH);
  const payAtDoorCard = payAtDoorMethods.includes(PAY_AT_DOOR_CODE);

  if (payAtDoorCard && payAtDoorCash) return translate('checkout:pay-at.title-by-card-or-cash_delivery');

  if (payAtDoorCash) return translate('checkout:pay-at.title-by-cash_delivery');

  return translate('checkout:pay-at.pay-at_door_with_card');
};

const PaymentOptionsButtons: React.FC<Props> = props => {
  const {
    c: config,
    confirmationUrl,
    continueLinkHref,
    t: translate,
    legendText,
    showCardIcons,
    shoppingMethod,
    payAtDoorMethods,
    inlineMessagingEnabled,
  } = props;

  const onPayOnlineButtonClick = useCallback(() => {
    analyticsBus().emit('UIEventBasicEvent', {
      action: 'delay',
      type: 'order summary',
      value: 'pay online',
    });
  }, []);

  const payAtDoorTranslation = getPayAtDoorTranslation(shoppingMethod, payAtDoorMethods, translate);

  return (
    <Fragment>
      {legendText && <h3>{legendText}</h3>}
      <StyledButtonContainer>
        {inlineMessagingEnabled ? (
          <InlinePaymentOptionsButtons
            payAtDoorHref={confirmationUrl}
            payOnlineHref={continueLinkHref}
            onPayOnlineButtonClick={onPayOnlineButtonClick}
            shoppingMethod={shoppingMethod}
          />
        ) : (
          <>
            <StyledLinkButtonWithFeedback
              ddl
              primaryText={translate('checkout:pay-online.action-text')}
              href={continueLinkHref}
              onClick={onPayOnlineButtonClick}
            />
            <PayAtDoorButton confirmationUrl={confirmationUrl} text={payAtDoorTranslation} variant="secondary" />
          </>
        )}
      </StyledButtonContainer>
      {showCardIcons && (
        <IconList
          icons={getCardIcons(config('creditCardsSupport'))}
          listHeader={translate('checkout:common.we-accept')}
          listTitle={translate('checkout:payment-options.cards-accepted')}
        />
      )}
    </Fragment>
  );
};

export default helpers(['c', 't'])(connect(mapStateToProps)(PaymentOptionsButtons));
