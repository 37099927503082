import React from 'react';
import { AuthButtonContainer } from './styled';
import AuthCtaContainer from '../auth-container/auth-cta-container';

export const AuthContainer = (): JSX.Element => {
  return (
    <AuthButtonContainer>
      <AuthCtaContainer />
    </AuthButtonContainer>
  );
};
