import React from 'react';
import Button from '@ddsweb/button';
import { connect } from '#/lib/render/connect-deep-compare';
import { UNBOOK } from '#/constants/mutate-fulfilment-types';
import SafeForm from '#/components/shared/safe-form';
import { getLanguageLink, getCurrentUrl } from '#/reducers/app';
import { getBookedSlot } from '#/selectors/trolley';
import { mutateFulfilment } from '#/actions/slot-action-creators';
import { ConnectedProps } from 'react-redux';
import helpers from '#/lib/decorators/helpers';
import { closeModal } from '#/actions/ui-action-creators';
import { TTranslateFn } from '#/components/slots/delivery-content-section/index.defs';
import { CANCEL_SLOT_BOOKING_MODAL } from '#/constants/modal-names';
import analyticsBus from '#/analytics/analyticsBus';
import { basicEvent } from '#/analytics/types/basic';
import { NOW, CANCEL } from '#/analytics/constants';
import { ON_DEMAND } from '#/constants/shopping-methods';

type TPayload = {
  slotId?: string | null;
  slotAction: string;
};

type HelperProps = {
  t: TTranslateFn;
};

export type TBookedSlot = {
  slotId?: string | null;
  status?: string | null;
  countdown?: { deliveryDate: string };
} | null;

type TCancelBookedSlotProps = {
  bookedSlot?: TBookedSlot;
  currentUrl: string;
  languageLink: string;
};

type TProps = HelperProps & ConnectedProps<typeof connector> & TCancelBookedSlotProps;

const mapStateToProps = (state: Store): TCancelBookedSlotProps => {
  return {
    currentUrl: getCurrentUrl(state),
    languageLink: getLanguageLink(state, '/slots/mutate'),
    bookedSlot: getBookedSlot(state),
  };
};

const mapDispatchToProps = {
  mutateFulfilment,
  closeModal,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

const CancelBookedSlotButton = helpers(['t'])((props: TProps) => {
  const { bookedSlot, currentUrl, languageLink, t: translate, mutateFulfilment, closeModal } = props;

  const handleSubmit = (payload: TPayload) => (e: React.SyntheticEvent): void => {
    e.preventDefault();
    closeModal(CANCEL_SLOT_BOOKING_MODAL);
    // TODO - Handle this doing a .reject
    mutateFulfilment(payload, true).then(() => {
      basicEvent(analyticsBus, { type: ON_DEMAND, value: CANCEL, action: NOW });
    });
  };

  const payload = {
    slotId: bookedSlot?.slotId,
    slotAction: UNBOOK,
  };

  return (
    <SafeForm action={languageLink} method="POST" onSubmit={handleSubmit(payload)}>
      <input type="hidden" name="slotId" value={payload.slotId ?? undefined} />
      <input type="hidden" name="slotAction" value={payload.slotAction} />
      <input type="hidden" name="returnUrl" value={currentUrl} />
      <Button type="submit" data-auto="cancel-booked-slot">
        {translate('modals:cancel-booked-slot.button.cancel-slot')}
      </Button>
    </SafeForm>
  );
});

export default connector(CancelBookedSlotButton);
