import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classSet from 'classnames';

export default class Radio extends PureComponent {
  static propTypes = {
    checked: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    focus: PropTypes.bool,
    linkProps: PropTypes.shape({
      className: PropTypes.string,
      href: PropTypes.string,
      onClick: PropTypes.func,
      rel: PropTypes.string,
      tabIndex: PropTypes.number
    }).isRequired,
    tooltip: PropTypes.string,
    isDisable: PropTypes.bool,
    showFullText: PropTypes.bool
  };

  static defaultProps = {
    focus: false
  };

  componentDidMount() {
    if (this.props.focus && this.refs.link) {
      this.refs.link.focus();
    }
  }

  render() {
    const {
      focus,
      tooltip,
      linkProps,
      disabled,
      checked,
      showFullText,
      children
    } = this.props;
    return (
      <div
        className={classSet('radio', disabled && 'radio--disabled')}
        data-auto="radio-button"
      >
        <a {...linkProps} ref={focus ? 'link' : null} title={tooltip}>
          <div
            className={classSet(
              'radio-button',
              checked ? 'radio-button--on' : 'radio-button--off',
              disabled && 'radio-button--disabled',
              showFullText && 'radio-button-full-text'
            )}
          />
          <div
            className={classSet(
              'radio__label',
              showFullText && 'radio__label--wrap'
            )}
          >
            {children}
          </div>
        </a>
      </div>
    );
  }
}
