import { RECOMMENDATION_CAROUSEL_TYPE } from '#/constants/panel-types';
import {
  CATEGORY_DISPLAY_AREA,
  SEARCH_DISPLAY_AREA,
  PROMOTIONS_DISPLAY_AREA,
  FAVORITES_DISPLAY_AREA
} from '#/constants/recommendations';
import {
  SEARCH,
  CATEGORY,
  PROMOTIONS,
  FAVORITES
} from '#/constants/result-types';
import { itemsToContentData } from '#/analytics/bertie/utils/products';
import { emitRenderedContentOp } from '#/analytics/bertie/events';
import analyticsBus from '#/analytics/analyticsBus';
import uuidv4 from 'uuid/v4';

export const createTrexTraceId = () => uuidv4() + '-trex';

export const getRecommenderConfig = ({ configKey, c }) =>
  c(`recommenders:${configKey}`);

export const getDisplayArea = (breadcrumbs, resultType) => {
  if (resultType === SEARCH) {
    return SEARCH_DISPLAY_AREA;
  }

  if (resultType === CATEGORY) {
    const breadcrumbLength = breadcrumbs.length;

    if (breadcrumbLength) {
      return CATEGORY_DISPLAY_AREA[breadcrumbLength - 2];
    }
  }

  if (resultType === PROMOTIONS) {
    return PROMOTIONS_DISPLAY_AREA;
  }

  if (resultType === FAVORITES) {
    return FAVORITES_DISPLAY_AREA;
  }

  return null;
};

export const dispatchTrexAnalytics = (
  props,
  recommenderConfig,
  lastTrex,
  overridenComponentType = null
) => {
  const {
    analyticsSegments,
    c: config,
    componentType: displayArea = 'csl',
    pageId,
    panelPos,
    panelType = RECOMMENDATION_CAROUSEL_TYPE,
    trexId,
    recommendations,
    analyticsComponentName
  } = props;
  const currency = config('currency');

  emitRenderedContentOp({
    content: [
      {
        componentId: trexId,
        componentName: analyticsComponentName || panelType,
        componentType: overridenComponentType || recommenderConfig.panelType,
        displayArea,
        modulePosition: panelPos,
        pageType:
          recommenderConfig.analyticsEventType ||
          pageId ||
          recommenderConfig.pageId,
        panel: itemsToContentData({
          contentSegment: analyticsSegments,
          currency: currency.isoCode,
          items: recommendations,
          sendSponsoredId: true
        })
      }
    ],
    itemsPerPage: recommendations.size,
    resultsCount: recommendations.size,
    resultCountForPage: recommendations.size
  });

  lastTrex && analyticsBus().emit('app:asyncPageComplete', {});
};
