import { Request, Response } from 'express';
import { favPersonalisedOffersVariants, favPersonalisedOffers } from '#/experiments/oop-1557/constants';
import { getFeatureConfigurationFromReqRes } from '#/lib/optimizely-manager';

export type ExperimentConfig = {
  experiment: string;
  variation: string;
  experience: favPersonalisedOffersVariants;
  productCount: number;
};

const getVariant = (req: Request, res: Response): ExperimentConfig => {
  const featureKey = favPersonalisedOffers.featureKey;

  return getFeatureConfigurationFromReqRes(req, res, featureKey, favPersonalisedOffers);
};

export const getFavPersonalisedOffersConfig = (req: Request, res: Response): ExperimentConfig => {
  const config = getVariant(req, res);

  return {
    ...config,
    productCount: parseInt(config.productCount + ''),
  };
};

export const getShouldShowUsualsCarousel = (variant: favPersonalisedOffersVariants): boolean =>
  variant === favPersonalisedOffersVariants.usualsCarousel;

export const getShouldShowOffersCarousel = (variant: favPersonalisedOffersVariants): boolean =>
  variant === favPersonalisedOffersVariants.offersCarousel;

export const getShouldShowOffersTab = (variant: favPersonalisedOffersVariants): boolean =>
  variant === favPersonalisedOffersVariants.offersTab;
