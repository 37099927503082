import React, { useState, useRef, useMemo, Children, ReactNode, useEffect, ReactChild, ReactFragment } from 'react';
import { StyledCardStack } from './styled';

type LayoutTransition = {
  lastChildrenArray: (ReactChild | ReactFragment)[];
  phantomChildren: ReactNode[] | null;
};

type Props = {
  children: ReactNode;
};

const newChildInitialTransform = 'translate3d(100%, 0, 0)';

export const CardStack = ({ children }: Props): JSX.Element => {
  const container = useRef<HTMLDivElement>(null);
  const childrenRefs = useRef(new Map<number, HTMLElement>()).current;

  const childrenArray: (ReactChild | ReactFragment)[] = useMemo(() => Children?.toArray(children), [children]);
  const [baseLayoutTransition, setLayoutTransition] = useState<LayoutTransition>({
    lastChildrenArray: childrenArray,
    phantomChildren: null,
  });

  let layoutTransition: LayoutTransition;
  if (baseLayoutTransition.lastChildrenArray.length > childrenArray.length) {
    layoutTransition = {
      lastChildrenArray: childrenArray,
      phantomChildren: baseLayoutTransition.lastChildrenArray.slice(childrenArray.length),
    };
  } else if (baseLayoutTransition.lastChildrenArray !== childrenArray) {
    layoutTransition = {
      lastChildrenArray: childrenArray,
      phantomChildren: null,
    };
  } else {
    layoutTransition = baseLayoutTransition;
  }

  if (layoutTransition !== baseLayoutTransition) {
    setLayoutTransition(layoutTransition);
  }

  const renderedChildren =
    layoutTransition.phantomChildren != null ? childrenArray.concat(layoutTransition.phantomChildren) : childrenArray;

  const columnIndex = childrenArray.length - 1;
  useEffect(() => {
    if (container.current == null) {
      return undefined;
    }

    let cancelled = false;
    Promise.all(
      Array.from(childrenRefs, ([index, element]) => {
        const offset = index - columnIndex;

        return new Promise<void>((res, rej) => {
          if (index !== 0 && element.style.transform === '') {
            element.style.transform = newChildInitialTransform;
          }

          const transform = `translate3d(${offset * 100}%, 0, 0)`;
          if (offset >= 0) {
            element.hidden = false;
          }
          const animation = element.animate([{ transform }], {
            duration: 200,
            easing: 'ease-in-out',
          });

          animation.addEventListener('finish', () => {
            element.style.transform = transform;
            if (offset < 0) {
              element.hidden = true;
            }
            res();
          });
          animation.addEventListener('cancel', () => rej());
        });
      }),
    ).then(
      () => {
        if (!cancelled) {
          setLayoutTransition(s => ({
            lastChildrenArray: s.lastChildrenArray,
            phantomChildren: null,
          }));
        }
      },
      () => {
        // Ignore promise cancellation
      },
    );

    return (): void => {
      cancelled = true;
    };
  }, [childrenRefs, columnIndex]);

  return (
    <StyledCardStack ref={container}>
      {renderedChildren.map((child, index) => (
        <div
          key={index}
          ref={(element): void => {
            if (element != null) {
              childrenRefs.set(index, element);
            } else {
              childrenRefs.delete(index);
            }
          }}
          className="cardStackColumn"
        >
          {child}
        </div>
      ))}
    </StyledCardStack>
  );
};
