import { Ref } from '../common-types.defs';
import { TOOLTIP_CLASS } from '../constants';

export const setRefStyle = (targetRef: Ref): void => {
  targetRef.current?.classList.add(TOOLTIP_CLASS);
};

export const removeRefStyle = (targetRef: Ref): void => {
  targetRef.current?.classList.remove(TOOLTIP_CLASS);
};
