import { AddressSuggestion } from '#/lib/requests/addressSearch/address-search';
import { OptionSet } from '@ddsweb/search-input-next';

// Helper function used to transform the response addressLines to a format which is accepted by the DDS component
export const formatSuggestedResults = (addresses: AddressSuggestion[]): OptionSet => {
  return addresses?.map(({ id, addressLines }) => {
    return {
      text: addressLines.join(', '),
      key: id,
    };
  });
};
