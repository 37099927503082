import React, { useState } from 'react';
import Button from '@ddsweb/button';
import Icon from '@ddsweb/icon';
import { TTranslateFunc } from '#/lib/records/helpers.defs';
import { MultiSwapButtonWrapper } from './styled';
import { FIND_SWAP, MULTI_SUBS_TYPE } from '../constants';
import { NOW } from '#/analytics/constants';
import analyticsBus from '#/analytics/analyticsBus';
import { basicEvent } from '#/analytics/types/basic';

type TOwnProps = {
  translate: TTranslateFunc;
  handleSubsContainerChange: (isSwapButtonActive: boolean) => void;
};

export const sendAnalytics = (): void => {
  basicEvent(analyticsBus, {
    type: MULTI_SUBS_TYPE,
    value: FIND_SWAP,
    action: NOW,
  });
};

const MultiSwapButton: React.FC<TOwnProps> = (props: TOwnProps) => {
  const { translate, handleSubsContainerChange } = props;
  const [isSwapButtonActive, setIsActive] = useState(false);

  return (
    <MultiSwapButtonWrapper>
      <Button
        variant="secondary"
        icon={isSwapButtonActive ? <Icon graphic="contract" /> : <Icon graphic="expand" />}
        iconPosition="right"
        onClick={(): void => {
          setIsActive(!isSwapButtonActive);
          handleSubsContainerChange(!isSwapButtonActive);
          sendAnalytics();
        }}
      >
        {isSwapButtonActive ? translate('trolley:hide-swaps') : translate('trolley:find-a-swap')}
      </Button>
    </MultiSwapButtonWrapper>
  );
};

export default MultiSwapButton;
