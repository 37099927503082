import styled, { css } from 'styled-components';
import { spacing } from '@beans/selectors';

const noMoreFav = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
`;

export const StyledDiv = styled('div')`
  ${noMoreFav}
  & .title {
    margin-top: ${spacing.xs};
    margin-bottom: ${spacing.xx};
  }
`;
