import {
  TROLLEY_REQUEST_IN_PROGRESS,
  TROLLEY_USER_HISTORY_PUSH,
  TROLLEY_USER_HISTORY_POP,
  TROLLEY_USER_HISTORY_CLEAR,
  ADD_FROM_ORDER_REQUEST_IN_PROGRESS,
  ITEMS_TO_UPDATE,
  TROLLEY_EMPTIED,
  RECEIVE_RAW_TROLLEY,
  UPDATE_DELIVERY_ADDRESS,
  RECEIVE_RESULT_ITEMS,
  RECEIVE_TROLLEY_ITEMS,
  REQUEST_FINISHED,
  REQUEST_STARTED,
  RESOLVE_TROLLEY_ITEM_FAILED,
  RESOLVE_TROLLEY_ITEM_UPDATE,
  RESOLVE_TROLLEY_UPDATE,
  START_TROLLEY_UPDATE,
  START_MARKETPLACE_TROLLEY_UPDATE,
  START_GROCERY_TROLLEY_UPDATE,
  START_TROLLEY_ITEM_UPDATE,
  UPDATE_BAG_OPTION,
  UPDATE_BBLG_PRODUCT,
  UPDATE_CATCH_WEIGHT,
  UPDATE_GLOBAL_SUBSTITUTION_PREFERENCES,
  UPDATE_LINE_ITEM_SUBSTITUTION_PREFERENCES,
  COUPON_FORM_SUBMITTING_START,
  ADD_COUPON,
  REMOVE_COUPON,
  UPDATE_COUPON,
  COUPON_SUBMITTING_START,
  COUPON_SUBMITTING_STOP,
  COUPON_FORM_RESET,
  SELECT_COUPON,
  DESELECT_COUPON,
  START_UPDATE_SLOT_RESERVATION_EXPIRY,
  COMPLETE_UPDATE_SLOT_RESERVATION_EXPIRY,
  CLEAR_TROLLEY_ITEM_ACTION_ANALYTICS,
  SET_TROLLEY_ITEM_ACTION_ANALYTICS,
  UPDATE_TROLLEY_CUSTOMER_PREFERENCES,
  START_ADD_ALL_USUALS_UPDATE,
  RESOLVE_ADD_ALL_USUALS_UPDATE,
} from '#/constants/action-types';
import {
  COUPON_STATUS_ERROR,
  COUPON_STATUS_SUCCESS_SELECTED_QUALIFIED,
  COUPON_STATUS_SUCCESS_SELECTED_UNQUALIFIED,
} from '#/constants/coupons';
import { Items as ItemsMap, TrolleyState } from '#/custom-typings/redux-store/trolley.defs';
import { createItem, createTrolley } from '#/lib/records';
import { Item } from '#/lib/records/item';
import { adjustNumberOfItems } from '#/lib/records/item-utils';
import {
  diffItems,
  getById,
  getItemsWithBblgLimitReached,
  hasExtraItems,
  idInItems,
  itemsToMap,
  merge,
  mergeItems,
  setById,
} from '#/lib/records/product-utils';
import Slot from '#/lib/records/slot';
import { getQuantity, getProductBulkBuyLimitGroupId, getProductId } from '#/selectors/item';
import {
  getCanExtendSlot as getCanExtendSlotSelector,
  getLatestItems,
  getItems,
  getItemsToUpdate,
} from '#/selectors/trolley';
import { cloneItems } from '#/utils/clone-items';
import { PlainObject } from '#/types';
import { AddressData } from '#/reducers/addresses';
import { Trolley, DeliveryPreferences } from '#/lib/records/trolley.defs';

type PartialTrolley = Partial<TrolleyState>;

const updateSlotReservationExpiry = (state: TrolleyState, newTime: unknown): PartialTrolley => {
  const newState = {
    ...state,
    slotExtensionSubmitting: false,
  };

  if (newTime) {
    newState.bookedSlot = Slot({
      ...state.bookedSlot,
      reservationExpiry: newTime,
    });
    newState.trolley = createTrolley({
      ...state.trolley,
      slot: {
        ...state.trolley.slot,
        reservationExpiry: newTime,
      },
      canExtendSlot: getCanExtendSlotSelector(state.trolley),
    });
  }

  return newState;
};

const incrementNumberOfRequestsInProgress = ({ numberOfRequestsInProgress }: TrolleyState): PartialTrolley => ({
  numberOfRequestsInProgress: ++numberOfRequestsInProgress,
});

const decrementNumberOfRequestsInProgress = ({ numberOfRequestsInProgress }: TrolleyState): PartialTrolley => ({
  numberOfRequestsInProgress: --numberOfRequestsInProgress,
});

const startTrolleyUpdate = (): PartialTrolley => ({
  isBagUpdating: true,
  isUpdating: true,
});

const startMarketplaceTrolleyUpdate = (): PartialTrolley => ({
  isMarketplaceUpdating: true,
  isGroceryUpdating: false,
});

const startGroceryTrolleyUpdate = (): PartialTrolley => ({
  isMarketplaceUpdating: false,
  isGroceryUpdating: true,
});

const startAddAllUsualsUpdate = (): PartialTrolley => ({
  isAddingUsuals: true,
});

const resolveAddAllUsualsUpdate = (): PartialTrolley => ({
  isAddingUsuals: false,
});

const pushTrolleyUserHistory = (state: TrolleyState, tpnb: string): PartialTrolley => {
  const trolleyUserHistory = [...state.trolleyUserHistory];
  trolleyUserHistory.push(tpnb);
  return { trolleyUserHistory };
};

const clearTrolleyUserHistory = (): PartialTrolley => {
  return { trolleyUserHistory: [] };
};

const popTrolleyUserHistory = (state: TrolleyState, tpnb: string | null): PartialTrolley => {
  const trolleyUserHistory = [...state.trolleyUserHistory];
  const index = tpnb === null ? -1 : trolleyUserHistory.indexOf(tpnb);
  if (index !== -1) {
    trolleyUserHistory.splice(index, 1);
  } else {
    // This is to remove if the tpnb if null,
    // this can happen in the catch of the update trolley call,
    // which has no context of last added item
    trolleyUserHistory.pop();
  }
  return { trolleyUserHistory };
};

const startTrolleyItemUpdate = (): PartialTrolley => ({
  isUpdating: true,
  isBagUpdating: true,
  isUserItemPreferencesUpdating: 'saving',
});

const trolleyEmptied = (): PartialTrolley => ({
  isBagUpdating: false,
  isUpdating: false,
  trolleyEmptied: true,
});

const updateBBLGProduct = (state: TrolleyState): Pick<TrolleyState, 'items'> | undefined => {
  const serverItemsWithBblglimit = getItemsWithBblgLimitReached(getLatestItems(state));

  if (Object.keys(serverItemsWithBblglimit).length) {
    let newItems: Array<Item> = [];

    getItems(state).forEach(item => {
      const bblgGroupId = getProductBulkBuyLimitGroupId(item);

      if (bblgGroupId) {
        const products = serverItemsWithBblglimit[bblgGroupId];

        if (products) {
          const product = products[getProductId(item)];

          if (product) {
            const newItem = createItem({
              ...item,
              quantity: product.quantity,
              groupLimitReached: true,
            });

            newItems = setById(newItems, newItem);
          }

          const newItem = createItem({
            ...item,
            quantity: 0,
          });

          newItems = setById(newItems, newItem);
        }
      }

      newItems = setById(newItems, item);
    });

    const itemsArr = [...state.items];
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    itemsArr[itemsArr.length - 1] = [...merge(getItems(state)!, newItems).values()];

    return {
      items: itemsArr,
    };
  }
};

const removeCoupon = (action: PlainObject): PartialTrolley => ({
  addCouponFormCouponCode: action.code || '',
  addCouponFormErrorInfo: action.error ? action.message : '',
  addCouponFormStatus: '',
});

const updateCoupon = (action: PlainObject): PartialTrolley => ({
  addCouponFormCouponCode: action.code,
  addCouponFormErrorInfo: '',
  addCouponFormStatus: '',
});

const resetCouponForm = (): PartialTrolley => ({
  addCouponFormCouponCode: '',
  addCouponFormErrorInfo: '',
  addCouponFormStatus: '',
  addCouponFormSubmitting: false,
});

const selectCoupon = (state: TrolleyState, action: PlainObject): PartialTrolley => ({
  addCouponFormCouponCode: action.error ? state.addCouponFormCouponCode : '',
  addCouponFormErrorInfo: action.error ? action.message : '',
  addCouponFormStatus: action.error ? COUPON_STATUS_ERROR : '',
});

const deselectCoupon = (state: TrolleyState, action: PlainObject): PartialTrolley => ({
  addCouponFormCouponCode: action.error ? state.addCouponFormCouponCode : '',
  addCouponFormErrorInfo: action.error ? action.message : '',
  addCouponFormStatus: action.error ? COUPON_STATUS_ERROR : '',
});

const returnConditionalData = (
  trolley: Trolley,
): { deliveryPreferences: DeliveryPreferences } | Record<string, unknown> =>
  trolley.deliveryPreferences
    ? {
        deliveryPreferences: trolley.deliveryPreferences,
      }
    : {};

const receiveTrolley = (trolley: Trolley): PartialTrolley => ({
  storeId: trolley.storeId,
  bookedSlot: Slot(trolley.slot || {}),
  issues: trolley.issues || [],
  previousSlot: Slot(trolley.previousSlot || trolley.slot || {}),
  radishItems: [...itemsToMap(trolley.items).values()] || [],
  shoppingMethod: trolley.shoppingMethod,
  constraints: trolley.constraints,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore TODO this should be redundant but leaving in just in case
  canExtendSlot: trolley.canExtendSlot,
  trolley: createTrolley(trolley),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore TODO: .totalItems doesn't exist on Trolley, should it be added to the Type or removed here?
  totalItems: trolley.totalItems,
  ...returnConditionalData(trolley),
});

const updateDeliveryAddress = (deliveryAddress: AddressData | undefined): PartialTrolley => ({
  deliveryAddress: deliveryAddress || ({} as AddressData),
});

const receiveRestrictedItems = (items: Array<Array<Item>>, restrictedItemsToAdd: Array<Item>): Array<Array<Item>> => {
  const newItems: Array<Array<Item>> = [];

  if (restrictedItemsToAdd) {
    restrictedItemsToAdd.forEach(restrictedItem => {
      const id = getProductId(restrictedItem);
      const existingItems = items[items.length - 1];
      let updatedItems = cloneItems(existingItems);
      const item = getById(updatedItems, id);
      updatedItems = setById(updatedItems, item ? adjustNumberOfItems(item, 1) : restrictedItem);

      newItems.push(updatedItems);
    });
  }

  return newItems;
};

function setCouponSubmittingState(state: TrolleyState, couponCode: string, submitting: boolean): PartialTrolley {
  return {
    ...state,
    trolley: createTrolley({
      ...state.trolley,
      coupons: state.trolley.coupons.map(coupon => {
        if (coupon.id === couponCode) {
          return Object.assign({}, coupon, {
            submitting,
          });
        }

        return coupon;
      }),
    }),
  };
}

const couponSubmittingStart = (state: TrolleyState, action: PlainObject): PartialTrolley =>
  setCouponSubmittingState(state, action.code, true);

const couponSubmittingStop = (state: TrolleyState, action: PlainObject): PartialTrolley =>
  setCouponSubmittingState(state, action.code, false);

const couponFormSubmittingStart = (): PartialTrolley => ({
  addCouponFormSubmitting: true,
});

const addCoupon = (state: TrolleyState, action: PlainObject): PartialTrolley => {
  let status;

  if (action.error) {
    status = COUPON_STATUS_ERROR;
  } else {
    const fitted = action.coupon ? action.coupon.qualified && action.coupon.selected : true;

    status = fitted ? COUPON_STATUS_SUCCESS_SELECTED_QUALIFIED : COUPON_STATUS_SUCCESS_SELECTED_UNQUALIFIED;
  }

  return {
    addCouponFormCouponCode: action.error ? state.addCouponFormCouponCode : '',
    addCouponFormErrorInfo: action.error ? action.message : '',
    addCouponFormStatus: status,
    addCouponFormSubmitting: false,
  };
};

const getSyncedItems = (clientItems: Array<Item>, serverItems: Array<Item>): Array<Item> => {
  const filteredItems: Array<Item> = [];

  clientItems.forEach(item => {
    const id = getProductId(item);
    const serverItem = getById(serverItems, id);

    if (!!serverItem && getQuantity(serverItem) === getQuantity(item)) {
      filteredItems.push(item);
    }
  });

  return filteredItems;
};

const receiveItem = (state: TrolleyState, items: ItemsMap): Pick<TrolleyState, 'items'> => {
  let newItems;
  let serverItems = getLatestItems(state);

  // Transfer a set of properties from items to clientItems
  let clientItems = cloneItems(getItems(state));

  items.forEach((item, id) => {
    const baseItem = getById(clientItems, id);

    if (baseItem) {
      clientItems = setById(
        clientItems,
        createItem({
          ...baseItem,
          cost: item.cost,
          charges: item?.charges,
          pickerNote: item.pickerNote,
          product: {
            ...baseItem.product,
            isForSale: item.product.isForSale,
            restrictions: item.product.restrictions,
          },
          promotions: item.promotions,
          substitutionOption: item.substitutionOption,
        }),
      );
    }
  });

  const hasExtraServerItems = hasExtraItems(serverItems, clientItems);
  const hasExtraClientItems = hasExtraItems(clientItems, serverItems);

  const itemsInSync = (!hasExtraServerItems && !hasExtraClientItems) || !serverItems.length;

  const existingItemsArr = [...state.items.values()];

  if (itemsInSync) {
    existingItemsArr[existingItemsArr.length - 1] = clientItems;
    newItems = existingItemsArr;
  } else {
    const itemsToUpdate = getItemsToUpdate(state);
    const removedItems = itemsToUpdate.filter(item => !getQuantity(item));

    if (removedItems.length > 0) {
      const filteredItems: Array<Item> = [];

      serverItems.forEach(item => {
        const id = getProductId(item);

        if (!idInItems(id, removedItems)) {
          filteredItems.push(item);
        }
      });

      serverItems = filteredItems;
    }

    if (hasExtraClientItems) {
      clientItems = getSyncedItems(clientItems, serverItems);
      const pendingItemsToUpdate = diffItems(itemsToUpdate, serverItems);

      if (pendingItemsToUpdate.size > 0) {
        clientItems = [...merge(clientItems, pendingItemsToUpdate).values()];
      }
    }

    existingItemsArr[existingItemsArr.length - 1] = [...merge(serverItems, clientItems).values()];
    newItems = existingItemsArr;
  }

  return {
    items: newItems,
  };
};

const receiveItems = (
  state: TrolleyState,
  items: Array<Item> | ItemsMap,
): Pick<TrolleyState, 'items' | 'trolleyEmptied'> => {
  const existingItems = [...state.items];

  const currentTrolleyItems = getItems(state);
  const reversedTrolleyItems = cloneItems(currentTrolleyItems).reverse();

  const mergedItems = mergeItems(reversedTrolleyItems, itemsToMap(items));
  const newTrolleyItems = [...mergedItems.values()].reverse();

  let filteredTrolleyItems: Array<Item> = [];

  newTrolleyItems.forEach(item => {
    if (getQuantity(item)) {
      filteredTrolleyItems = setById(filteredTrolleyItems, item);
    }
  });

  const newItems = existingItems.concat([filteredTrolleyItems]);

  return {
    items: newItems,
    trolleyEmptied: newItems.length === 0,
  };
};

const updateCatchWeight = (value: Item | null): PartialTrolley => ({
  updatingCatchWeightItemDetails: value,
});

const resolveTrolley = (): PartialTrolley => ({
  isBagUpdating: false,
  isSubstitutionPreferencesUpdating: false,
  isUpdating: false,
  isMarketplaceUpdating: false,
  isGroceryUpdating: false,
  isUserItemPreferencesUpdating: 'saved',
  itemsToUpdate: [] as Array<Item>,
  updatingCatchWeightItemDetails: null,
});

const resolveTrolleyItemFailed = (): PartialTrolley => ({
  isUserItemPreferencesUpdating: 'error',
});

const itemsToUpdate = (value: Array<Item>): { itemsToUpdate: Array<Item> } => ({
  itemsToUpdate: value,
});

const updateBagOption = (): PartialTrolley => ({
  isBagUpdating: true,
  isUpdating: true,
});

const updateLineItemSubstitutionPreferences = (state: TrolleyState, items: Item[]): { radishItems: Array<Item> } => {
  const currentItems = itemsToMap(items) as ItemsMap;

  // Transfer pickerNote and substitutionOption from currentItems to radishItems
  let radishItems = cloneItems(getItems(state));
  currentItems.forEach((item, id) => {
    const baseItem = getById(radishItems, id);
    if (baseItem) {
      radishItems = setById(
        radishItems,
        createItem({
          ...baseItem,
          pickerNote: item.pickerNote,
          substitutionOption: item.substitutionOption,
        }),
      );
    }
  });

  return { radishItems };
};

const updateGlobalSubstitutionPreferences = (): PartialTrolley => ({
  isSubstitutionPreferencesUpdating: true,
});

const defaultState = {
  numberOfRequestsInProgress: 0,
  trolleyActionAnalytics: {},
} as TrolleyState;

export default function trolley(state: TrolleyState = defaultState, action: PlainObject): TrolleyState {
  switch (action.type) {
    case TROLLEY_REQUEST_IN_PROGRESS: {
      const showPendingQty = typeof action.showPendingQty !== 'undefined' ? action.showPendingQty : true;

      return {
        ...state,
        trolleyRequestInProgress: action.value,
        showPendingQty,
      };
    }
    case ADD_FROM_ORDER_REQUEST_IN_PROGRESS:
      return { ...state, addFromOrderRequestInProgress: action.value };
    case ITEMS_TO_UPDATE:
      return { ...state, ...itemsToUpdate(action.value) };
    case TROLLEY_USER_HISTORY_PUSH:
      return { ...state, ...pushTrolleyUserHistory(state, action.value) };
    case TROLLEY_USER_HISTORY_POP:
      return {
        ...state,
        ...popTrolleyUserHistory(state, action.value || null),
      };
    case TROLLEY_USER_HISTORY_CLEAR:
      return { ...state, ...clearTrolleyUserHistory() };
    case START_ADD_ALL_USUALS_UPDATE:
      return {
        ...state,
        ...startAddAllUsualsUpdate(),
      };
    case RESOLVE_ADD_ALL_USUALS_UPDATE:
      return {
        ...state,
        ...resolveAddAllUsualsUpdate(),
      };
    case START_TROLLEY_UPDATE:
      return {
        ...state,
        ...startTrolleyUpdate(),
      };
    case START_MARKETPLACE_TROLLEY_UPDATE:
      return {
        ...state,
        ...startMarketplaceTrolleyUpdate(),
      };
    case START_GROCERY_TROLLEY_UPDATE:
      return {
        ...state,
        ...startGroceryTrolleyUpdate(),
      };
    case START_TROLLEY_ITEM_UPDATE:
      return {
        ...state,
        ...startTrolleyItemUpdate(),
      };
    case TROLLEY_EMPTIED:
      return {
        ...state,
        ...trolleyEmptied(),
      };
    case RECEIVE_RAW_TROLLEY:
      return {
        ...state,
        ...receiveTrolley(action.value),
      };
    case UPDATE_DELIVERY_ADDRESS:
      return {
        ...state,
        ...updateDeliveryAddress(action.value),
      };
    case RECEIVE_RESULT_ITEMS:
      return {
        ...state,
        ...receiveItems(state, action.value),
      };
    case RECEIVE_TROLLEY_ITEMS:
      return {
        ...state,
        ...receiveItem(state, action.value),
      };
    case REQUEST_STARTED:
      return {
        ...state,
        ...incrementNumberOfRequestsInProgress(state),
      };
    case REQUEST_FINISHED:
      return {
        ...state,
        ...decrementNumberOfRequestsInProgress(state),
      };
    case RESOLVE_TROLLEY_UPDATE:
      return {
        ...state,
        ...resolveTrolley(),
      };
    case RESOLVE_TROLLEY_ITEM_FAILED:
      return {
        ...state,
        ...resolveTrolleyItemFailed(),
      };
    case RESOLVE_TROLLEY_ITEM_UPDATE:
      return {
        ...state,
        ...resolveTrolley(),
      };
    case UPDATE_BAG_OPTION:
      return {
        ...state,
        ...updateBagOption(),
      };
    case UPDATE_CATCH_WEIGHT:
      return { ...state, ...updateCatchWeight(action.value) };
    case UPDATE_BBLG_PRODUCT: {
      const updatedItems = updateBBLGProduct(state);
      if (!updatedItems) return state;
      return { ...state, ...updatedItems };
    }
    case UPDATE_LINE_ITEM_SUBSTITUTION_PREFERENCES:
      return {
        ...state,
        ...updateLineItemSubstitutionPreferences(state, action.value),
      };
    case UPDATE_GLOBAL_SUBSTITUTION_PREFERENCES:
      return {
        ...state,
        ...updateGlobalSubstitutionPreferences(),
      };
    case COUPON_SUBMITTING_START:
      return {
        ...state,
        ...couponSubmittingStart(state, action),
      };
    case COUPON_FORM_SUBMITTING_START:
      return {
        ...state,
        ...couponFormSubmittingStart(),
      };
    case ADD_COUPON:
      return {
        ...state,
        ...addCoupon(state, action),
      };
    case REMOVE_COUPON:
      return {
        ...state,
        ...removeCoupon(action),
      };
    case UPDATE_COUPON:
      return {
        ...state,
        ...updateCoupon(action),
      };
    case COUPON_FORM_RESET:
      return {
        ...state,
        ...resetCouponForm(),
      };
    case COUPON_SUBMITTING_STOP:
      return {
        ...state,
        ...couponSubmittingStop(state, action),
      };
    case SELECT_COUPON:
      return {
        ...state,
        ...selectCoupon(state, action),
      };
    case DESELECT_COUPON:
      return {
        ...state,
        ...deselectCoupon(state, action),
      };
    case START_UPDATE_SLOT_RESERVATION_EXPIRY:
      return {
        ...state,
        slotExtensionSubmitting: true,
      };
    case COMPLETE_UPDATE_SLOT_RESERVATION_EXPIRY:
      return {
        ...state,
        ...updateSlotReservationExpiry(state, action.value),
      };
    case CLEAR_TROLLEY_ITEM_ACTION_ANALYTICS: {
      const newTrolleyActionAnalytics = {
        ...state.trolleyActionAnalytics,
      };

      delete newTrolleyActionAnalytics[action.itemId];

      return {
        ...state,
        trolleyActionAnalytics: newTrolleyActionAnalytics,
      };
    }
    case SET_TROLLEY_ITEM_ACTION_ANALYTICS:
      return {
        ...state,
        trolleyActionAnalytics: {
          ...state.trolleyActionAnalytics,
          [action.itemId]: action.data,
        },
      };
    case UPDATE_TROLLEY_CUSTOMER_PREFERENCES: {
      return {
        ...state,
        customerPreferences: action.value,
      };
    }
    default:
      return state;
  }
}

/*
 * Called on the server side to get default state from initial props from router
 */
export const getDefaultStateFromProps = (props): TrolleyState => {
  const trolleyFromResources =
    props.resources && props.resources.trolleyContents && props.resources.trolleyContents.data;

  const { items, customerPreferences, subscription, ...trolleyWithoutItems } =
    props.trolley || trolleyFromResources || {};

  const {
    charges,
    deliveryAddress,
    deliveryPreferences,
    previousSlot,
    shoppingMethod,
    slot,
    storeId,
    issues,
    totalItems,
    constraints,
  } = trolleyWithoutItems;

  const itemsMap = itemsToMap(items || []);
  const radishItems = [...itemsMap.values()];
  const itemsToUpdate = [] as Array<Item>;
  let trolleyItems = [[...itemsMap.values()]];
  const bookedSlot = Slot(slot || {});
  const restrictedItems = receiveRestrictedItems(trolleyItems, [
    ...itemsToMap(props.restrictedItemsToAdd || []).values(),
  ]);

  trolleyItems = trolleyItems.concat(restrictedItems);
  const addCouponForm = (props && props.addCouponForm) || {};

  return {
    addCouponFormCouponCode: addCouponForm.inputValue || '',
    addCouponFormErrorInfo: addCouponForm.errorInfo || '',
    addCouponFormStatus: addCouponForm.status || '',
    addCouponFormSubmitting: false,
    addFromOrderRequestInProgress: {},
    bookedSlot,
    charges: charges || {},
    customerPreferences: customerPreferences || {},
    deliveryAddress: deliveryAddress || {},
    deliveryPreferences: deliveryPreferences || {},
    isAddingUsuals: false,
    isBagUpdating: false,
    isSubstitutionPreferencesUpdating: false,
    isUpdating: false,
    isMarketplaceUpdating: false,
    isGroceryUpdating: false,
    items: trolleyItems,
    itemsToUpdate,
    constraints: constraints || {},
    issues,
    numberOfRequestsInProgress: 0,
    previousSlot: Slot(previousSlot || slot || {}),
    radishItems,
    shoppingMethod,
    showPendingQty: true,
    slotExtensionSubmitting: false,
    storeId,
    subscription: subscription || {},
    totalItems,
    trolley: createTrolley(trolleyWithoutItems),
    trolleyActionAnalytics: {},
    trolleyEmptied: false,
    trolleyRequestInProgress: false,
    trolleyUserHistory: [],
    updatingCatchWeightItemDetails: null,
  };
};
