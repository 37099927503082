import React from 'react';
import PropTypes from 'prop-types';
import Link from '#/components/link-check-spa';
import helpers from '#/lib/decorators/helpers';
import { PLP, NOW, BACK_TO_TOP } from '#/analytics/constants';
import analyticsBus from '#/analytics/analyticsBus';
import { basicEvent } from '#/analytics/types/basic';
import { scrollToTop } from '#/lib/browser/ui-util';

let backToTopWasPreviouslyClickedButton = false;

export const backToTopHandler = () => {
  const focusableEls = document.querySelectorAll(
    'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])'
  );
  const firstFocusableEl = focusableEls[0];
  backToTopWasPreviouslyClickedButton = true;

  scrollToTop();
  if (focusableEls && firstFocusableEl) {
    document.addEventListener('keydown', function(e) {
      if (e.key === 'Tab' && backToTopWasPreviouslyClickedButton) {
        backToTopWasPreviouslyClickedButton = false;
        firstFocusableEl.focus();
      }
    });
  }
};

const handleBackToTopClick = event => {
  event.preventDefault();

  backToTopHandler();
  basicEvent(analyticsBus, {
    action: NOW,
    type: PLP,
    value: BACK_TO_TOP
  });
};

const BackToTopLink = props => {
  return (
    <div className="back-to-top">
      <Link
        className="back-to-top__link"
        to="#"
        onClick={handleBackToTopClick}
        rel="nofollow"
      >
        {props.t('common:back-to-top')}
        <span className="back-to-top__icon-chevron--up icon-chevron_up" />
      </Link>
    </div>
  );
};

BackToTopLink.propTypes = {
  t: PropTypes.func.isRequired
};

export default helpers(['t'])(BackToTopLink);
