import uuidv4 from 'uuid/v4';
import { addApmData } from '#/lib/apm';
import { isClientPerfEnabled } from '#/utils/perf';

const interactionTypeById: Record<string, string> = {};

/**
 * Mark start of interaction, to measure its duration in APM
 * @param type Description of interaction (e.g. AddToBasket)
 * @param id Optional unique interactionID (generated if not given)
 * @returns Unique interaction ID if performance API supported, otherwise `undefined`
 */
export const markInteractionStart = (type: string, id?: string): string | undefined => {
  if (!isClientPerfEnabled()) return;

  const updatedType = type.replace(/[-\s]/gi, '_');

  if (id === undefined) id = uuidv4() as string;
  interactionTypeById[id] = updatedType;

  const { performance } = window;
  performance.mark(id);

  return id;
};

/**
 * Mark end of interaction, log duration to APM
 * @param id Interaction ID given to or returned by `markInteractionStart`
 */

export const markInteractionEnd = (id: string): void => {
  if (!isClientPerfEnabled()) return;

  if (!(id in interactionTypeById)) return;
  const interactionType = interactionTypeById[id];

  const markEntries = window.performance.getEntriesByName(id, 'mark');

  if (!markEntries.length) return;

  const { performance } = window;
  performance.measure(id, id);

  const interactionTime = performance.getEntriesByName(id, 'measure')[0].duration;

  addApmData(`interaction_time_${interactionType}`, interactionTime);

  performance.clearMarks(id);
  performance.clearMeasures(id);

  delete interactionTypeById[id];
};
