import { DISCOUNTS } from './discounts';
const BASKET = `fragment Basket on BasketInterface {
  basketId: id
  splitView {
    typename: __typename
    ... on GHSBasketSummary {
      id
    }
    ... on MPBasketSummary {
      id
    }
    guidePrice
    totalPrice
    totalItems
    propositionalInfo {
      __typename
      id
      proposition {
        __typename
        criteria {
          ... on PropositionCriteriaBoolean {
            name
            value
          }
          ... on PropositionCriteriaThreshold {
            thesholdName: name
            threshold
            thresholdCriteria
          }
        }
      }
      status
      criteria {
        criterion {
          ... on PropositionCriteriaBoolean {
            name
            value
          }
          ... on PropositionCriteriaThreshold {
            thesholdName: name
            threshold
            thresholdCriteria
          }
        }
        met
      }
    }
    items {
      product {
        typename: __typename
        id
      }
    }
    charges {
      bagCharge: bags
      fulfilment
      minimumBasketValue: minimumValue
      packaging
    }
    discounts {
      total
      categories {
        type
        value
      }
    }
    discounts {
      ...Discounts
    }
    clubcardPoints @include(if: $includeCoupons) {
      greenPoints: green
      promoPoints: promotional
      standardPoints: standard
      totalPoints: total
    }
  }
  storeId
  locationUuid
  orderNo
  guidePrice
  eCouponsTotal
  totalPrice @include(if: $includeCoupons)
  savings
  staffDiscount {
    percentage
    discount
  }
  discounts {
    ...Discounts
  }
  totalItems
  authorizationAmount
  constraints {
    maxItemCount
  }
  issues {
    exceptions {
      level
      reason
    }
  }
  shoppingMethod
  isAmendBasket: isInAmend
  isAmended
  IsBagless: isBagless
  amendExpiry
  amendOrderId
  orderId
  canExtendSlot
  deliveryAddress @include(if: $includeDeliveryAddress) {
    name
    id
    uuid
    addressLine1
    isBusinessAddress
    isBlockedAddress
  }
  collectionLocation {
    postcode
  }
  deliveryPreferences @include(if: $includeDeliveryPreferences) {
    forcedBagOption
    deliveryInstruction
    isBagless
    mobilePhone
    dayPhone
    eveningPhone
  }
  customerPreferences @include(if: $includeCustomerPreferences) {
    substituteAllItems
  }
  slot {
    ...Slot
  }
  previousSlot {
    ...Slot
  }
  coupons: paymentItems @include(if: $includeCoupons) {
    ...PaymentItem
  }
  clubcardPoints @include(if: $includeCoupons) {
    greenPoints: green
    promoPoints: promotional
    standardPoints: standard
    totalPoints: total
  }
  charges {
    depositCharge
    surcharge
    minimumBasketValue: minimum
    bagCharge
    packaging {
      charge
    }
  }
  subscription @include(if: $includeSubscription) {
    planName
    planId
    planType
    planDurationInMonths
    applicableDays
    status
    valid
    basketvalue
    freeSameDayDelivery
    isTrialPlan
  }
  promotions {
    startDate
    endDate
    description
    unitSellingInfo
    price {
      beforeDiscount
      afterDiscount
    }
    id
    timesTriggered
    missed
    warnings {
      threshold
      type
    }
  }
  items {
    charges {
      charges {
        __typename
        ... on DepositReturnCharge {
          amount
        }
      }
    }
    quantity
    cost
    weight
    originalQuantity
    originalWeight
    isNewlyAdded
    substitutionOptions {
      isBlocked
    }
    substitutionOption
    pickerNote
    customerUnitChoice: unit
    product {
      ...BasketProductItem
      substitutions @include(if: $includeSubstitutions) {
        ...BasketProductItem
      }
    }
    itemLimitReached: bulkBuyLimitReached
    groupLimitReached: groupBulkBuyLimitReached
    groupBulkBuyQuantity
    issues {
      ... on BasketItemAvailabilityIssue {
        willBeRemoved
        reason
      }
    }
  }
}
${DISCOUNTS}`;

export default BASKET;
