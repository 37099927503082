import styled, { css } from 'styled-components';
import { spacing, colors, fontSize } from '@beans/selectors';
import { BodyText } from '@beans/typography';

export const TooltipStyleDesktop = css`
  top: 50px;
  left: ${spacing.md};
  & .ddsweb-popover__pointer {
    left: ${spacing.lg};
  }
`;

export const HideTooltip = css`
  display: none;
`;

export const TooltipStyleMobile = css`
  top: 51px;
  left: auto;
  right: 0;
  & .ddsweb-popover__pointer {
    right: ${spacing.lg};
    left: auto;
  }
`;

export const StyledBodyText = styled(BodyText)`
  strong {
    color: ${colors.grayscale};
  }
`;

export const StyledButtonContainer = styled.div`
  font-size: ${fontSize.xs};
`;
