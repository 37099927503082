import {
  initApm as baseInitApm,
  logApmDatum,
  logApmData as baseLogApmData,
  logApmError as baseLogApmError,
  logCustomEvent as baseLogCustomEvent,
  ApmTool,
  logApiRequestEvent as baseLogApiRequestEvent,
  LogApmDatumOptions,
  ApiRequestAttributes,
} from '@peas/apm';

type ApmLogData = Record<string, string | number | null>;
type ApmConfig = boolean;

const IS_PROD = process.env.NODE_ENV === 'production';

function reportError(error?: string): void | Error {
  if (IS_PROD) {
    console.error(error);
    return;
  }

  if (process.env.SUPPRESS_ERROR === 'true') {
    return;
  }

  throw new Error(error);
}

const errorHandlerConfig = {
  reportError,
};

const initApm = (name: ApmTool, config: ApmConfig): void => {
  baseInitApm(name, config);
};

const addApmData = (key: string, value: string | number | boolean): void => {
  const options: LogApmDatumOptions = {
    evaluateCustomAttributeLimit: process.env.NEW_RELIC_EVALUATE_CUSTOM_ATTRIBUTE_LIMIT === 'true',
  };

  logApmDatum(key, value, options);
};

const logApmData = (data: ApmLogData): void => {
  baseLogApmData(data);
};

const logApmError = (error: Error, message?: any): void => {
  baseLogApmError(error, message);
};

const logCustomEvent = (schemaName: string, event: ApmLogData | ApmLogData[]): void => {
  baseLogCustomEvent(schemaName, event);
};

const logApiRequestEvent = (apiRequestAttributes: ApiRequestAttributes): void => {
  baseLogApiRequestEvent(apiRequestAttributes);
};

export {
  addApmData,
  logApmData,
  errorHandlerConfig,
  logApmError,
  logCustomEvent,
  initApm,
  ApmTool,
  logApiRequestEvent,
};
