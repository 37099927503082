import { Request } from 'express';
import { DateRange } from 'moment-range';
import moment from 'moment-timezone';
import url from 'url';
import { ShoppingMethod, DELIVERY } from '#/constants/shopping-methods';
import { BOOKED, RESERVED, AVAILABLE, EXPIRED, UNAVAILABLE } from '#/constants/slot-statuses';
import { SlotViewModes } from '#/constants/slot-views';
import { millisecondsUntilDate } from '#/lib/date-helpers';
import { isBooked } from '#/selectors/slot/slot-record';
import { SLOT_FOR_ONE_HOUR } from '#/constants/slot-group';
import { ADDRESSES, TROLLEY_CONTENTS } from '#/constants/spa-resource';
import { SlotViewMode, SlotStatus, SlotGroup } from '#/custom-typings/redux-store/slot.defs';
import { TOrder, TOrderAddress } from '#/custom-typings/redux-store/orders-ddl.defs';
import { Slot } from '#/lib/records/slot.defs';
import { Trolley, TrolleyContents } from '#/lib/records/trolley.defs';
import { User } from '#/custom-typings/express/user.defs';
import { AddressData } from '#/reducers/addresses';
import { AddressesState } from '#/custom-typings/redux-store/addresses.defs';
import { formattedSlotTime, IConfig } from '#/components/shared/formatted-time/utils';
import { flatten } from '#/utils/misc';

export type SlotsByDate = {
  [key: string]: Slot[] | undefined;
};

type AppState = {
  cid?: unknown;
  displayName: string;
  email: string;
  firstName: string;
  hashedEmail?: string;
  hashedUId: string;
  isAuthenticated: boolean;
  isFirstTimeBuyer: boolean;
  isRegistered: boolean;
  isStaff: boolean;
  lastName: string;
  segments: string;
  serverTime: string;
  storeId: string;
  title: string;
};

type LoadedResources = {
  addresses: Required<AddressesState>;
  appState: AppState;
  trolleyContents: TrolleyContents;
};

type FormattedUrlQuery = {
  locationId?: string;
  postcode?: string;
  slotGroup?: number;
  addressChange?: boolean;
  slotViewMode?: SlotViewMode;
};

export function getLocalDate(date: moment.Moment, timezone: string, language: string): moment.Moment {
  return moment
    .utc(date)
    .tz(timezone)
    .locale(language);
}

export function getSlotTimeIsoWeekday(slotTime: string): number {
  return moment(slotTime)
    .utc()
    .isoWeekday();
}

export function getDay(slot: Slot, timezone: string, language: string): string {
  return getLocalDate(slot.start as moment.Moment, timezone, language).format('dddd');
}

export function getIsSameDay(slot: Slot): boolean {
  return moment(slot.start).isSame(moment(), 'day');
}

export function getIsSameDayFromMoment(date: moment.Moment): boolean {
  return date.isSame(moment(), 'days');
}

export function getIsNextDayFromMoment(date: moment.Moment): boolean {
  return date.isSame(moment().add(1, 'days'), 'days');
}

export function sanitiseDate(date: moment.Moment): moment.Moment {
  return moment
    .utc()
    .dayOfYear(date.dayOfYear())
    .year(date.year())
    .startOf('day');
}

export function formatUrl(
  shoppingMethod?: string | null,
  date?: moment.Moment | null,
  locationId?: string | null,
  slotGroup?: number | null,
  addressChange?: boolean | null,
  slotViewMode?: SlotViewMode | null,
): string {
  const query: FormattedUrlQuery = {};

  if (locationId != null) {
    query.locationId = locationId;
  }

  if (slotGroup != null) {
    query.slotGroup = slotGroup;
  }

  if (addressChange) {
    query.addressChange = true;
  }

  const shoppingMethodPath = shoppingMethod ? `/${shoppingMethod}` : '';
  const datePath = date ? `/${date.format('YYYY-MM-DD')}` : '';

  if (slotViewMode) {
    query.slotViewMode = slotViewMode;
  }

  return url.format({
    pathname: `/slots${shoppingMethodPath}${datePath}`,
    query,
  });
}

export function getWeekDates(week: DateRange, tz: string, lang: string): { start: moment.Moment; end: moment.Moment } {
  return {
    start: getLocalDate(week.start as moment.Moment, tz, lang),
    end: getLocalDate(week.end.startOf('day') as moment.Moment, tz, lang),
  };
}

export function formatWeekText(
  week: DateRange,
  tz: string,
  lang: string,
  formats: { dateRange?: { long: string; short: string }; month?: string } = {},
): string {
  const formatsDateRangeLong = formats.dateRange ? formats.dateRange.long : 'DD MMM';
  const formatsDateRangeShort = formats.dateRange ? formats.dateRange.short : 'DD';
  let startFormat;
  let endFormat;

  if (week.start.month() !== week.end.month()) {
    startFormat = endFormat = formatsDateRangeLong;
  } else if (/^d/i.exec(formatsDateRangeLong)) {
    startFormat = formatsDateRangeShort;
    endFormat = formatsDateRangeLong;
  } else {
    startFormat = formatsDateRangeLong;
    endFormat = formatsDateRangeShort;
  }

  const dates = getWeekDates(week, tz, lang);
  const start = dates.start.format(startFormat);
  const end = dates.end.format(endFormat);

  return `${start}-${end}`;
}

export function slotCurrentlyBooked(slot: Slot): boolean {
  return slot ? slot.status === BOOKED || slot.status === RESERVED : false;
}

export function isSlotAvailable(slot: Slot): boolean {
  return slot && slot.status === AVAILABLE;
}

export function isSlotUnavailable(slot: Slot): boolean {
  return slot && slot.status === UNAVAILABLE;
}

export function hasSlotExpired(slot: Slot): boolean {
  return slot && slot.status === EXPIRED;
}

export function getFirstAvailableSlotForDay(slotsListForDay: Slot[]): Slot | undefined {
  const actionableStates = [AVAILABLE, BOOKED, RESERVED];

  return slotsListForDay.find(slot => {
    return actionableStates.includes(slot.status as SlotStatus);
  });
}

export const isBookedSlot = (slot: Slot, bookedSlot: Slot, timezone: string): boolean => {
  const bookedSlotDate = bookedSlot && moment.utc(bookedSlot.start).tz(timezone);

  return (
    bookedSlotDate &&
    bookedSlotDate.isSame(slot.start) &&
    isBooked(bookedSlot) &&
    bookedSlot.locationId === slot.locationId &&
    (slot.group && bookedSlot.group ? bookedSlot.group === slot.group : true)
  );
};

export const isPreviousSlot = (slot: Slot, previousSlot?: Slot | null, timezone?: string): boolean => {
  const previousSlotDate = previousSlot && moment.utc(previousSlot.start).tz(timezone as string);

  return (
    !!previousSlotDate &&
    previousSlotDate.isSame(slot.start) &&
    isBooked(previousSlot as Slot) &&
    (previousSlot as Slot).locationId === slot.locationId &&
    ((previousSlot as Slot).group === 0 || (previousSlot as Slot).group === slot.group)
  );
};

export function msUntilSlotExpiry(bookedSlot: Slot): number {
  return millisecondsUntilDate(bookedSlot.reservationExpiry as string | number | Date);
}

export function msUntilSlotExpiryThreshold(bookedSlot: Slot, slotExpiryThresholdMinutes: number): number {
  const expiryThresholdInMs = slotExpiryThresholdMinutes * 60 * 1000;

  return msUntilSlotExpiry(bookedSlot) - expiryThresholdInMs;
}

export function slotExpiresWithinThreshold(bookedSlot: Slot, slotExpiryThresholdMinutes: number): boolean {
  const msUntilExpiry = msUntilSlotExpiry(bookedSlot);
  const msUntilThreshold = msUntilSlotExpiryThreshold(bookedSlot, slotExpiryThresholdMinutes);

  return msUntilExpiry > 0 && msUntilThreshold < 0 && !hasSlotExpired(bookedSlot);
}

export function setSlotExpiryTimer(
  bookedSlot: Slot,
  slotExpiryThresholdMinutes: number,
  callback: () => void,
): (() => void) | undefined {
  if (bookedSlot && !hasSlotExpired(bookedSlot)) {
    const milliseconds = msUntilSlotExpiryThreshold(bookedSlot, slotExpiryThresholdMinutes);
    const timeBuffer = 100; // Ensure we're comfortably within the expiry threshold
    const timeout = window.setTimeout(callback, Math.max(milliseconds + timeBuffer, 0));

    return (): void => {
      window.clearTimeout(timeout);
    };
  }
}

export function getMinutesUntilSlotExpiry(bookedSlot: Slot): number {
  return Math.round(msUntilSlotExpiry(bookedSlot) / 1000 / 60);
}

export function getSlotPageAnchorTag(slotViewMode = SlotViewModes.GRID, slotStart: string, slotEnd: string): string {
  return `${slotViewMode}_${slotStart}_${slotEnd}`.replace(/\+/g, '-');
}

export const getShoppingMethods = (
  req: { params: { shoppingMethod: ShoppingMethod } },
  trolley: Trolley,
): {
  trolleyShoppingMethod: ShoppingMethod;
  selectedShoppingMethod: ShoppingMethod;
} => ({
  trolleyShoppingMethod: trolley.shoppingMethod,
  selectedShoppingMethod: req.params.shoppingMethod || trolley.shoppingMethod || DELIVERY,
});

export const getSlotGroup = (
  req: Request,
  trolley: Trolley,
  selectedShoppingMethod: ShoppingMethod,
  trolleyShoppingMethod: ShoppingMethod,
): number | '' | null | undefined => {
  const currentSlotGroup = req.query.slotGroup && parseInt(req.query.slotGroup as string, 10);
  return selectedShoppingMethod === trolleyShoppingMethod
    ? currentSlotGroup || (trolley.slot || {}).group
    : currentSlotGroup;
};

const shouldJumpToFinalWeek = (amountOfWeeksVisible: number): boolean => {
  const customersToJumpToFinalWeek = process.env.SHOW_LAST_SLOTS_WEEK_TO_CUSTOMERS;

  if (typeof customersToJumpToFinalWeek === 'string') {
    return customersToJumpToFinalWeek.split(',').includes(amountOfWeeksVisible.toString());
  }

  return false;
};

export const getSelectedDate = (
  dateFromParams: moment.MomentInput,
  trolley: Trolley,
  timezone: string,
  changeSlotInfo: { start: moment.MomentInput },
  amountOfWeeksVisible: number,
): moment.Moment => {
  if (changeSlotInfo && changeSlotInfo.start) {
    return moment.utc(changeSlotInfo.start);
  }

  if (dateFromParams) {
    return moment.utc(dateFromParams);
  }

  if (trolley && slotCurrentlyBooked(trolley.slot)) {
    return moment.utc(trolley.slot.start).tz(timezone as string);
  }

  if (shouldJumpToFinalWeek(amountOfWeeksVisible as number)) {
    return moment()
      .add((amountOfWeeksVisible as number) - 1, 'weeks')
      .utc()
      .tz(timezone as string);
  }

  return moment.utc().tz(timezone as string);
};

export const getIsAddressUndeliverable = (req: Request, loadedResources: LoadedResources): boolean => {
  const trolley = loadedResources[TROLLEY_CONTENTS];
  const addresses = loadedResources[ADDRESSES];

  if (!trolley) {
    throw new Error(`The '${TROLLEY_CONTENTS}' resource is missing`);
  }
  if (!addresses) {
    throw new Error(`The '${ADDRESSES}' resource is missing`);
  }

  let deliveryAddress = trolley.shoppingMethod === DELIVERY ? trolley.deliveryAddress : addresses.defaultAddress;

  if (addresses.allAddresses) {
    // we can get additional information from allAddresses not available in trolley
    deliveryAddress =
      addresses.allAddresses.find((address: AddressData) => address.id === deliveryAddress.id) || deliveryAddress;
  }

  return deliveryAddress.isBlockedAddress || deliveryAddress.isBusinessAddress || (req.user as User).storeId === '0';
};

export const isSlotActionable = (slot: Slot): boolean => isSlotAvailable(slot) || slotCurrentlyBooked(slot);

export const hasAvailableSlots = (slots: Slot[]): boolean => {
  return slots && slots.filter(slot => isSlotActionable(slot)).length > 0;
};

export const hasSlotsForSameDay = (slotsForDay: Slot[]): boolean =>
  Array.isArray(slotsForDay) && slotsForDay.length > 0 && !!slotsForDay.find(slot => isSlotActionable(slot));

export const isSameAddress = (address1: TOrderAddress, address2: TOrderAddress): boolean => {
  if (!address1 || !address2) {
    return false;
  }
  return address1.name === address2.name;
};

export const hasPendingOrderWithSameAddressForDate = (
  pendingOrders: TOrder[],
  selectedAddress: { address: AddressData },
): boolean => {
  if (!pendingOrders) {
    return false;
  }

  return pendingOrders.some(pendingOrder =>
    isSameAddress(pendingOrder.address, selectedAddress.address as TOrderAddress),
  );
};

export const getSlotTimesInUtcWithTimezone = (slots: Slot[], timezone: string): Slot[] => {
  if (!Array.isArray(slots)) {
    return [];
  }
  if (!timezone) {
    return slots;
  }
  return slots.map(slot => {
    const start = moment.utc(slot.start).tz(timezone);
    const end = moment.utc(slot.end).tz(timezone);

    return { ...slot, start, end };
  });
};

export function getSlotsByDate(slots: Slot[]): SlotsByDate {
  const slotsByDate: SlotsByDate = {};
  slots = slots || [];

  slots.forEach(slot => {
    const slotDate = moment.utc(slot.start).format('YYYY-MM-DD');

    if (!slotsByDate[slotDate]) {
      slotsByDate[slotDate] = [];
    }

    (slotsByDate[slotDate] as Slot[]).push(slot);
  });

  return slotsByDate;
}
export const isSlotValid = (slot: Slot): boolean => !!slot.status && !hasSlotExpired(slot);

export const hasBookableSlot = (slots: Slot[]): boolean => slots.some(slot => isSlotAvailable(slot));

export const isOneHourSlotGroup = (slotGroup: SlotGroup): boolean => slotGroup === SLOT_FOR_ONE_HOUR;

export const formatBookedDaysHumanized = (
  bookedDays: string[],
  translate: (p: string) => string,
  onPrefix = true,
): string[] => {
  const lowerCaseFirst = (word: string): string => `${word.charAt(0).toLowerCase()}${word.slice(1)}`;
  return bookedDays.map((day: string) => {
    if (moment().isSame(day, 'day')) {
      return lowerCaseFirst(translate('common:today'));
    } else if (moment(day).isSame(moment().add(1, 'days'), 'day')) {
      return lowerCaseFirst(translate('common:tomorrow'));
    } else {
      return `${(onPrefix && `${translate('common:on')} `) || ''}${moment(day).format('dddd DD MMMM')}`;
    }
  });
};

export const formatSlotIntervalHumanized = (
  start: moment.Moment,
  end: moment.Moment,
  timezone: string,
  language: string,
  config: IConfig,
): { date: string; time: string } => {
  const date = getLocalDate(start, timezone, language).format('dddd D MMMM');
  const time = formattedSlotTime(start, end, timezone, language, config).replace(/\s/g, '');
  return { date, time };
};

export const getSlotsWithoutToday = (slots: SlotsByDate, todayDate: string): SlotsByDate =>
  Object.keys(slots)
    .filter(day => day !== todayDate)
    .reduce((obj, key) => {
      (obj as SlotsByDate)[key] = slots[key];
      return obj;
    }, {});

export const getAvailableSlotsWithDistinctCharges = (slots: Slot[]): null | Slot[] => {
  const actionableSlots = flatten(Object.values(slots))
    .filter(isSlotActionable)
    .sort((a, b) => (a.charge > b.charge ? 1 : -1));

  if (!actionableSlots.length) {
    return null;
  }

  const allSlotsHaveSameCharge = actionableSlots[0].charge === actionableSlots[actionableSlots.length - 1].charge;

  return allSlotsHaveSameCharge ? null : actionableSlots;
};
