import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tabs from '#/components/tabs';
import TabItem from '#/components/tabs/tab-item';
import helpers from '#/lib/decorators/helpers';
import OptionTabsHeader from '#/components/slots/option-tabs/header';
import { connect } from '#/lib/render/connect-deep-compare';
import {
  getShoppingMethodsTabs,
  getAvailableShoppingMethods,
  getOnDemandDynamicDeliveryTime,
  getOnDemandServiceAvailable,
  getDeliveryCharge
} from '#/selectors/slot';
import { SHOPPING_METHOD_PROP_TYPE } from '#/components/slots/prop-types';
import CollectionTab from '#/components/slots/option-tabs/collection-tab';
import DeliveryTab from '#/components/slots/option-tabs/delivery-tab';
import { OnDemandTab } from '#/components/slots/on-demand-tab';

import { DELIVERY, COLLECTION, ON_DEMAND } from '#/constants/shopping-methods';
import { ondemandStatusAnalytics } from '#/analytics/helpers/slot';
import {
  ONDEMAND_DELIVERY_AVAILABLE,
  ONDEMAND_DELIVERY_UNAVAILABLE
} from '#/analytics/constants';
import { getCurrency, getLanguage } from '#/reducers/app';

/**
 * Represents the tabs for delivery and collection within Book a slot.
 *
 * Passing `shoppingMethod` and `slotStatus` is required to fire an action
 * when the user selects a tab. *
 */

const TabComponentConfig = {
  [DELIVERY]: DeliveryTab,
  [COLLECTION]: CollectionTab,
  [ON_DEMAND]: OnDemandTab
};

function showTabIcons(availableShoppingMethods) {
  return !availableShoppingMethods.includes(ON_DEMAND);
}

const mapStateToProps = (state, { shoppingMethod }) => {
  const availableShoppingMethods = getAvailableShoppingMethods(state);
  const currency = getCurrency(state);
  const charge = getDeliveryCharge(state, shoppingMethod);
  const language = getLanguage(state);
  const onDemandDynamicDeliveryTime = getOnDemandDynamicDeliveryTime(state);

  return {
    charge,
    currency,
    language,
    onDemandDynamicDeliveryTime,
    showIcons: showTabIcons(availableShoppingMethods),
    shoppingMethodsTabs: getShoppingMethodsTabs(state),
    isOnDemandAvailable: availableShoppingMethods.includes(ON_DEMAND)
      ? getOnDemandServiceAvailable(state)
      : false
  };
};

@helpers(['t', 'c'])
@connect(mapStateToProps)
export class OptionTabs extends Component {
  static propTypes = {
    c: PropTypes.func.isRequired,
    isAmendBasket: PropTypes.bool,
    shoppingMethod: SHOPPING_METHOD_PROP_TYPE.isRequired,
    shoppingMethodsTabs: PropTypes.array.isRequired,
    showIcons: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.fireUIBasicEvent();
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.shoppingMethodsTabs?.includes(ON_DEMAND)) {
      this.fireUIBasicEvent();
    }
  }

  onKeyPressed = e => {
    const { shoppingMethodsTabs } = this.props;
    const focusedTabIndex = shoppingMethodsTabs.indexOf(
      shoppingMethodsTabs.find(method =>
        document.getElementById(method).matches(':focus')
      )
    );
    let selectorStr;
    switch (e.key) {
      case 'ArrowRight':
        selectorStr =
          shoppingMethodsTabs[
            (focusedTabIndex + 1) % shoppingMethodsTabs.length
          ];
        break;
      case 'ArrowLeft':
        const index = (focusedTabIndex - 1) % shoppingMethodsTabs.length;
        selectorStr =
          shoppingMethodsTabs[
            index < 0 ? shoppingMethodsTabs.length - 1 : index
          ];
        break;
      case 'Home':
        selectorStr = shoppingMethodsTabs[0];
        break;
      case 'End':
        selectorStr = shoppingMethodsTabs[shoppingMethodsTabs.length - 1];
        break;
      case ' ':
        e.preventDefault();
        document.activeElement.click();
        break;
    }
    document.getElementById(selectorStr)?.focus();
  };

  fireUIBasicEvent() {
    const { shoppingMethodsTabs, isOnDemandAvailable } = this.props;

    if (shoppingMethodsTabs.includes(ON_DEMAND)) {
      const ondemandStatus = isOnDemandAvailable
        ? ONDEMAND_DELIVERY_AVAILABLE
        : ONDEMAND_DELIVERY_UNAVAILABLE;
      ondemandStatusAnalytics(ondemandStatus);
    }
  }

  render() {
    const {
      charge,
      currency,
      isAmendBasket,
      language,
      onDemandDynamicDeliveryTime,
      shoppingMethod,
      shoppingMethodsTabs,
      showIcons,
      t: translate
    } = this.props;

    const selectedShoppingMethod = shoppingMethod;
    const tabItems = shoppingMethodsTabs.map(shoppingMethodsTab => {
      let isActive = selectedShoppingMethod === shoppingMethodsTab;
      let TabComponent = TabComponentConfig[shoppingMethodsTab];

      const header = (
        <OptionTabsHeader
          showIcons={showIcons}
          isEnabled={!isAmendBasket || isActive}
          shoppingMethod={shoppingMethodsTab}
          onKeyPressed={this.onKeyPressed}
        />
      );

      return (
        <TabItem
          className={`option-tabs__tabitem ${shoppingMethodsTab}`}
          header={header}
          headerClassName={`option-tabs__tabheader ${shoppingMethodsTab}`}
          isActive={isActive}
          key={shoppingMethodsTab}
          tabPanelAttributes={{
            tabIndex: 0,
            'aria-labelledby': shoppingMethodsTab,
            id: `${shoppingMethodsTab}-tab`
          }}
        >
          {TabComponent && (
            <TabComponent
              charge={charge}
              currency={currency}
              language={language}
              onDemandDynamicDeliveryTime={onDemandDynamicDeliveryTime}
              shoppingMethod={shoppingMethodsTab}
            />
          )}
        </TabItem>
      );
    });

    return (
      <Tabs
        showIcons={showIcons}
        className="option-tabs"
        tabAriaLabel={translate('common:pages.slots')}
      >
        {tabItems}
      </Tabs>
    );
  }
}
