function onAdd() {
  const container = document.createElement('div');
  const pinColor = this.options.color;

  container.className = 'map__viewport-center-pin-layer';
  container.innerHTML = `<div class="map__viewport-center-pin"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.2 49.45" height="41.2" width="49.45"><path d="M6.82,34.91C1.78,29.87.6,26,.6,20.5a20,20,0,0,1,40,0c0,5.47-1.18,9.37-6.19,14.38L20.6,48.6Zm22-14.41a8.24,8.24,0,1,0-8.24,8.2A8.22,8.22,0,0,0,28.84,20.5Z" fill="${pinColor}" stroke="${pinColor}"/></svg></div>`;

  this.setHtmlElement(container);
  this.container = container;
}

function onLoad() {
  const container = this.container;
  const map = this.getMap();

  container.style.width = `${map.getWidth()}px`;
  container.style.height = `${map.getHeight()}px`;

  this.mapResizeEvent = Microsoft.Maps.Events.addHandler(
    map,
    'mapresize',
    this.onResize
  );

  const { onReady } = this.options;

  if (onReady) {
    onReady();
  }
}

function onRemove() {
  this.setHtmlElement(null);

  this.container = null;

  Microsoft.Maps.Events.removeHandler(this.mapResizeEvent);
}

function onResize() {
  const container = this.container;
  const map = this.getMap();

  container.style.width = `${map.getWidth()}px`;
  container.style.height = `${map.getHeight()}px`;
}

export default function createCenterPin(map, options) {
  function CenterPinOverlay(map, options) {
    this.options = options;

    this.onResize = this.onResize.bind(this);
  }

  // We won't know if Microsoft.Maps.CustomOverlay is already loaded at start up so can't extend the class normally
  CenterPinOverlay.prototype = new Microsoft.Maps.CustomOverlay({
    drawOrder: 1
  });

  CenterPinOverlay.prototype.onResize = onResize;
  CenterPinOverlay.prototype.onAdd = onAdd;
  CenterPinOverlay.prototype.onLoad = onLoad;
  CenterPinOverlay.prototype.onRemove = onRemove;

  const overlay = new CenterPinOverlay(map, options);

  map.layers.insert(overlay);

  return overlay;
}
