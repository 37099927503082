import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import SlotDetails from '#/components/context-cards/slot/slot-details';
import { OnDemandSlotDetails } from '#/components/context-cards/slot/beans-slot-details';
import Link from '#/components/link-check-spa';
import { addModalToUrl } from '#/lib/url/modal-utils';
import { openModal } from '#/actions/ui-action-creators';
import { SLOT_EXPIRED, AMEND_MODE } from '../constants';
import {
  BAG_OPTIONS_MODAL,
  SLOT_REBOOK_MODAL,
  SLOT_REMIND_MODAL
} from '#/constants/modal-names';
import { INFO, WARNING, ERROR } from '#/constants/notification-variants';
import { connect } from '#/lib/render/connect-deep-compare';
import helpers from '#/lib/decorators/helpers';
import { getLocalizedTimeFactory } from '#/lib/time';
import {
  getLanguage,
  getTimezone,
  getCurrentUrl,
  getLanguageLink,
  getCurrency
} from '#/reducers/app';
import {
  getIsOnDemandDelivery,
  getGuidePrice,
  getCharges,
  getCurrentValidSlot,
  getHasBasketBreachedByVolumeOrWeight,
  getBasketMaxItemCountLimit
} from '#/selectors/trolley';
import BasketSlotContextCardSlotBooked from '#/components/trolley/full-trolley/basket-slot-card/slot-booked';
import { hasValidSlot } from '#/lib/trolley/trolley-utils';
import { IntlProvider } from 'react-intl';
import { getCountryTranslation } from '#/i18n/helpers';
import { getFormattedTime } from '#/components/shared/formatted-time/selector';
import { ON_DEMAND } from '#/constants/shopping-methods';
import {
  showSlotRebookModal,
  showSlotRemindModal,
  showBothModals,
  getIsRebookModalVariant,
  getIsRemindModalVariant,
  getIsRebookOrRemindModalVariant
} from '#/experiments/oop-2210/selectors';
import { msUntilSlotExpiry } from '#/lib/slot/slot-utils';
import { sessionStore } from '#/lib/data-store/client-store';
import {
  SHOWN_SLOT_REBOOK_MODAL_KEY,
  SHOWN_SLOT_REMIND_MODAL_KEY
} from '#/experiments/oop-2210/constants';

export const mapStateToProps = (state, { c: config, f: feature, slot }) => {
  const timezone = getTimezone(state);
  const language = getLanguage(state);
  const currentUrl = getCurrentUrl(state);
  const currencyIso = getCurrency(state).isoCode;
  const standardizeTime = getLocalizedTimeFactory(timezone, language);
  const bagOptionsLanguageLink = getLanguageLink(
    state,
    addModalToUrl(currentUrl, BAG_OPTIONS_MODAL)
  );

  return {
    guidePrice: getGuidePrice(state),
    standardizeTime,
    bagOptionsLanguageLink,
    showAmendChangeSlot: feature('allowAmendSlot'),
    isOnDemandDelivery: getIsOnDemandDelivery(state),
    charges: getCharges(state),
    hasBasketBreachedByVolumeOrWeight: getHasBasketBreachedByVolumeOrWeight(
      state
    ),
    constraints: { maxItemCount: getBasketMaxItemCountLimit(state) },
    isValidSlot: hasValidSlot(getCurrentValidSlot(state)),
    expiryTime: getFormattedTime(state, slot.reservationExpiry, config),
    currencyIso,
    shouldShowRebookModal: showSlotRebookModal(state),
    shouldShowRemindModal: showSlotRemindModal(state),
    shouldShowBothModals: showBothModals(state),
    isRebookModalVariant: getIsRebookModalVariant(state),
    isRemindModalVariant: getIsRemindModalVariant(state),
    isRebookOrRemindModalVariant: getIsRebookOrRemindModalVariant(state)
  };
};

const FIVE_MINUTES = 5 * 60 * 1000;
const TEN_MINUTES = 10 * 60 * 1000;

@helpers(['c', 't', 'f'])
@connect(mapStateToProps, {
  openModal
})
export default class SlotBooked extends Component {
  static propTypes = {
    amountChanging: PropTypes.number,
    bagOptionsLanguageLink: PropTypes.string.isRequired,
    buttonLink: PropTypes.string,
    buttonText: PropTypes.node,
    c: PropTypes.func.isRequired,
    checkboxId: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    currencyIso: PropTypes.string.isRequired,
    displayOnFullBasket: PropTypes.bool,
    expandable: PropTypes.bool,
    f: PropTypes.func.isRequired,
    isAboutToExpire: PropTypes.bool,
    isLHN: PropTypes.bool,
    language: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    openModal: PropTypes.func.isRequired,
    shoppingMethod: PropTypes.string,
    showAmendChangeSlot: PropTypes.bool,
    slot: PropTypes.object,
    slotsLanguageLink: PropTypes.string,
    standardizeTime: PropTypes.func.isRequired,
    status: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    shouldShowRebookModal: PropTypes.bool,
    shouldShowRemindModal: PropTypes.bool,
    isRebookModalVariant: PropTypes.bool,
    isRemindModalVariant: PropTypes.bool,
    isRebookOrRemindModalVariant: PropTypes.bool
  };

  static defaultProps = {
    displayOnFullBasket: false
  };

  componentDidMount() {
    const {
      slot,
      shouldShowRebookModal,
      shouldShowRemindModal,
      shouldShowBothModals,
      isRebookModalVariant,
      isRemindModalVariant,
      isRebookOrRemindModalVariant,
      openModal,
      shoppingMethod
    } = this.props;
    let triggerTime = msUntilSlotExpiry(slot);

    const triggerTimeOffset =
      shoppingMethod === ON_DEMAND ? FIVE_MINUTES : TEN_MINUTES;

    const setBookingModalTime = (modal, time, offset) => {
      setTimeout(() => {
        openModal(modal, null, false);
      }, Math.max(time - offset, 0));
    };

    if (isRebookModalVariant && shouldShowRebookModal) {
      setBookingModalTime(SLOT_REBOOK_MODAL, triggerTime, 0);
    }

    if (isRemindModalVariant && shouldShowRemindModal) {
      if (triggerTime >= 0) {
        setBookingModalTime(SLOT_REMIND_MODAL, triggerTime, triggerTimeOffset);
      }
    }

    if (isRebookOrRemindModalVariant && shouldShowBothModals) {
      if (!sessionStore?.get(SHOWN_SLOT_REBOOK_MODAL_KEY)) {
        setBookingModalTime(SLOT_REBOOK_MODAL, triggerTime, 0);
      }

      if (!sessionStore?.get(SHOWN_SLOT_REMIND_MODAL_KEY)) {
        setBookingModalTime(SLOT_REMIND_MODAL, triggerTime, triggerTimeOffset);
      }
    }
  }

  getMessage(expiryTime, useSlotExpiredMessage = false) {
    const { t: translate, status, displayOnFullBasket, c: config } = this.props;
    const timeFormat = config('timeFormat');

    switch (status) {
      case SLOT_EXPIRED:
        return useSlotExpiredMessage
          ? translate('context-cards:slot-expired-full-trolley')
          : translate('context-cards:book-new-slot');
      case AMEND_MODE:
        return translate('orders:amend.making-changes');
      default:
        return displayOnFullBasket
          ? translate('context-cards:checkout-by-full-trolley', {
              time: expiryTime.format(timeFormat)
            })
          : translate('context-cards:checkout-by', {
              time: expiryTime.format('HH:mm')
            });
    }
  }

  getBagOptions() {
    const { t: translate, bagOptionsLanguageLink, openModal } = this.props;

    return {
      link: bagOptionsLanguageLink,
      onClick: e => {
        e.preventDefault();
        openModal(BAG_OPTIONS_MODAL);
      },
      text: translate('context-cards:change-bag-option')
    };
  }

  getShoppingMethodText() {
    const {
      t: translate,
      status,
      shoppingMethod,
      displayOnFullBasket
    } = this.props;
    if (displayOnFullBasket) {
      if (status === SLOT_EXPIRED) {
        return translate('common:slot-context-card.book-new-slot');
      }
      return translate(`common:slot-context-card.${shoppingMethod}`);
    }
    return translate(`common:shopping-method_${shoppingMethod}`);
  }

  getStatusIndicatorVariant = () => {
    const { isAboutToExpire, status } = this.props;
    if (isAboutToExpire) return WARNING;

    switch (status) {
      case SLOT_EXPIRED:
        return ERROR;
      case AMEND_MODE:
        return INFO;
      default:
        return INFO;
    }
  };

  render() {
    const {
      buttonText,
      buttonLink,
      c: config,
      className,
      checkboxId,
      displayOnFullBasket,
      expandable,
      guidePrice,
      language,
      onClick,
      showAmendChangeSlot,
      slot: { start, end, reservationExpiry },
      standardizeTime,
      shoppingMethod,
      slot,
      slotsLanguageLink,
      status,
      isOnDemandDelivery,
      amountChanging,
      charges,
      hasBasketBreachedByVolumeOrWeight,
      constraints,
      isValidSlot,
      currencyIso
    } = this.props;

    if (isOnDemandDelivery) {
      return (
        <OnDemandSlotDetails
          slot={slot}
          slotsLanguageLink={slotsLanguageLink}
          shoppingMethod={shoppingMethod}
          amountChanging={amountChanging}
          isLHN={this.props.isLHN}
          displayOnFullBasket={displayOnFullBasket}
          status={status}
        />
      );
    }

    const startTime = standardizeTime(start);
    const endTime = standardizeTime(end);
    const expiryTime = standardizeTime(reservationExpiry);
    const bagChargesLinkEnabled =
      !showAmendChangeSlot &&
      config('bagChargesEnabled') &&
      config('bagMessageAndLinkEnabled');
    const bagOptions = bagChargesLinkEnabled ? this.getBagOptions() : null;

    const localisation = {
      currency: {
        iso: currencyIso
      },
      locale: language
    };
    const countryTranslation = getCountryTranslation(language);

    let TogglerTag;
    let togglerProps;
    let togglerInput;
    let button;

    if (expandable) {
      TogglerTag = 'label';
      togglerProps = {
        htmlFor: checkboxId
      };
      togglerInput = (
        <input
          className="context-card-expander"
          type="checkbox"
          id={checkboxId}
        />
      );
      button = (
        <Link className="context-card-button" to={buttonLink} onClick={onClick}>
          {buttonText}
          <span className="icon icon-chevron_right" />
        </Link>
      );
    } else {
      TogglerTag = 'div';
      button = (
        <div>
          <Link
            className="context-card-button"
            to={buttonLink}
            onClick={onClick}
          >
            {buttonText}
            <span className="icon icon-chevron_right" />
          </Link>
          {bagChargesLinkEnabled && status === AMEND_MODE && (
            <Link
              className="context-card-button bag-option-link"
              to={bagOptions.link}
              onClick={bagOptions.onClick}
            >
              {bagOptions.text}
              <span className="icon icon-chevron_right" />
            </Link>
          )}
        </div>
      );
    }

    return displayOnFullBasket ? (
      <IntlProvider messages={countryTranslation} locale={language}>
        <BasketSlotContextCardSlotBooked
          bagOptions={bagOptions}
          buttonLink={buttonLink}
          buttonText={buttonText}
          className={className}
          end={endTime}
          onClick={onClick}
          shoppingMethodText={this.getShoppingMethodText()}
          slot={slot}
          slotBooked={status !== SLOT_EXPIRED}
          slotMessage={this.getMessage(expiryTime, true)}
          start={startTime}
          showSlotWindow={true}
          useBagOptions={bagChargesLinkEnabled && status === AMEND_MODE}
          variant={this.getStatusIndicatorVariant()}
          guidePrice={guidePrice}
          language={language}
          charges={charges}
          localisation={localisation}
          hasBasketBreachedByVolumeOrWeight={hasBasketBreachedByVolumeOrWeight}
          constraints={constraints}
          shoppingMethod={shoppingMethod}
          isValidSlot={isValidSlot}
          amountChanging={amountChanging}
        />
      </IntlProvider>
    ) : (
      <div
        className={classnames('context-cards--slot-booked', className)}
        data-auto="header-slots-context-card"
      >
        <TogglerTag {...togglerProps}>
          <SlotDetails
            end={endTime}
            shoppingMethod={shoppingMethod}
            slot={slot}
            start={startTime}
            status={status}
            showSlotWindow={true}
          />
        </TogglerTag>
        {togglerInput}
        <div className="context-card-dropdown">
          <p className="slot-message" data-auto="slot-message">
            {this.getMessage(expiryTime)}
          </p>
          {buttonLink && (
            <div className="context-card-button-container">{button}</div>
          )}
        </div>
        {expandable && <div className="expanded-indicator icon-chevron_down" />}
      </div>
    );
  }
}
