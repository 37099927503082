import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ResponsiveBackground } from '../responsive-background';
import Link from '../../link-check-spa';

const BuyListTile = props => {
  const {
    hoverEffect,
    image,
    link,
    placement,
    selected,
    componentClickHandler
  } = props;

  const stampClasses = classnames('trade-tile', 'trade-tile--buylist', {
    'trade-tile--selected': selected,
    'trade-tile--hover-effect': hoverEffect
  });

  const onClick = () => componentClickHandler?.(props);

  return (
    <div className={stampClasses} onClick={onClick}>
      <Link
        className="tile__parent-link"
        to={link && link.href && link.href !== 'none' ? link.href : null}
      >
        <div className="tile__image-container">
          <ResponsiveBackground
            className="tile__image"
            url={image && image.src}
            clipping={image && image.clipping}
            maxWidth={image.maxWidth}
            uid={placement}
          />
        </div>
        <div className="tile__caption-wrapper">
          <p className="tile__cta-link">{link.label}</p>
        </div>
      </Link>
    </div>
  );
};

BuyListTile.propTypes = {
  componentClickHandler: PropTypes.func,
  hoverEffect: PropTypes.bool,
  image: PropTypes.shape({
    breakpoints: PropTypes.array,
    clipping: PropTypes.oneOf(['left', 'center', 'right']),
    maxWidth: PropTypes.number,
    src: PropTypes.string
  }).isRequired,
  link: PropTypes.shape({
    href: PropTypes.string,
    label: PropTypes.string
  }).isRequired,
  placement: PropTypes.number,
  selected: PropTypes.bool
};

BuyListTile.defaultProps = {
  componentClickHandler: null,
  hoverEffect: false,
  placement: 0,
  selected: false
};

export { BuyListTile };
