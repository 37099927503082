import { useReducer } from 'react';
export const QUANTITY_EXTERNAL_UPDATE = 'quantityExternal_update';
export const QUANTITY_INTERNAL_UPDATE = 'quantityInternal_update';
export const VARIANT_UPDATE = 'variant_update';
export const QUANTITY_FIELD_UPDATE = 'quantityField_update';
export const DISABLE_CONTROLS_ON_EMPTY_INPUT = 'emptyInput_disableControls';
export const ENABLE_CONTROLS_ON_VALID_INPUT = 'validInput_enableControls';
export const UPDATE_CONTROLS_STATUS_ON_BASKET_QTY_CHANGE = 'basketQtyChange_controlsStatusUpdate';
export const ENABLE_CONTROLS_ON_EMPTY_INPUT_IN_KG_VARIANT = 'emptyInputKgVariant_enableControls';
interface Action<T, P> {
  readonly type: T;
  readonly payload: P;
}

type TQuantityState = {
  quantityValue: number;
  basketValue: number;
  variantValue?: string | number;
  controlsDisabledStatusLocal:
    | {
        add: boolean;
        remove: boolean;
      }
    | boolean;
  wasLastQtyValueEmpty: boolean;
  switchMadeFromEmptyToValue: boolean;
};

export type TQuantityStateChange = Partial<TQuantityState>;

type TExternalQuantityUpdatePayload = {
  basketValue: number;
  quantityValue: number;
  variantValue: string | number | undefined;
};
type TExternalQuantityUpdate = Action<typeof QUANTITY_EXTERNAL_UPDATE, TExternalQuantityUpdatePayload>;
export const externalQuantityUpdate = ({
  basketValue,
  quantityValue,
  variantValue,
}: TExternalQuantityUpdatePayload): TExternalQuantityUpdate => ({
  type: QUANTITY_EXTERNAL_UPDATE,
  payload: {
    basketValue,
    quantityValue,
    variantValue,
  },
});

type TVariantUpdatePayload = {
  variantValue: string;
};
type TVariantUpdate = Action<typeof VARIANT_UPDATE, TVariantUpdatePayload>;
export const variantUpdate = ({ variantValue }: TVariantUpdatePayload): TVariantUpdate => ({
  type: VARIANT_UPDATE,
  payload: {
    variantValue,
  },
});

type TQuantityFieldUpdatePayload = {
  quantityValue: number;
};
type TQuantityFieldUpdate = Action<typeof QUANTITY_FIELD_UPDATE, TQuantityFieldUpdatePayload>;
export const quantityFieldUpdate = ({ quantityValue }: TQuantityFieldUpdatePayload): TQuantityFieldUpdate => ({
  type: QUANTITY_FIELD_UPDATE,
  payload: {
    quantityValue,
  },
});

type TDisableControlsOnEmptyInputPayload = {
  controlsDisabledStatusLocal:
    | {
        add: boolean;
        remove: boolean;
      }
    | boolean;
  wasLastQtyValueEmpty: boolean;
  switchMadeFromEmptyToValue: boolean;
};

type TDisableControlsOnEmptyInput = Action<typeof DISABLE_CONTROLS_ON_EMPTY_INPUT, TDisableControlsOnEmptyInputPayload>;
export const disableControlsOnEmptyInput = ({
  controlsDisabledStatusLocal,
  wasLastQtyValueEmpty,
  switchMadeFromEmptyToValue,
}: TDisableControlsOnEmptyInputPayload): TDisableControlsOnEmptyInput => ({
  type: DISABLE_CONTROLS_ON_EMPTY_INPUT,
  payload: {
    controlsDisabledStatusLocal,
    wasLastQtyValueEmpty,
    switchMadeFromEmptyToValue,
  },
});

type TEnableControlOnValidInputPayload = {
  controlsDisabledStatusLocal:
    | {
        add: boolean;
        remove: boolean;
      }
    | boolean;
  wasLastQtyValueEmpty: boolean;
  switchMadeFromEmptyToValue: boolean;
};

type TEnableControlOnValidInput = Action<typeof ENABLE_CONTROLS_ON_VALID_INPUT, TEnableControlOnValidInputPayload>;

export const enableControlOnValidInput = ({
  controlsDisabledStatusLocal,
  wasLastQtyValueEmpty,
  switchMadeFromEmptyToValue,
}: TEnableControlOnValidInputPayload): TEnableControlOnValidInput => ({
  type: ENABLE_CONTROLS_ON_VALID_INPUT,
  payload: {
    controlsDisabledStatusLocal,
    wasLastQtyValueEmpty,
    switchMadeFromEmptyToValue,
  },
});

type TUpdateControlsStatusOnBasketQtyChangePayload = {
  basketValue: number;
  controlsDisabledStatusLocal:
    | {
        add: boolean;
        remove: boolean;
      }
    | boolean;
  quantityValue: number;
  variantValue: string;
};

type TUpdateControlsStatusOnBasketQtyChange = Action<
  typeof UPDATE_CONTROLS_STATUS_ON_BASKET_QTY_CHANGE,
  TUpdateControlsStatusOnBasketQtyChangePayload
>;

export const updateControlsStatusOnBasketQtyChange = ({
  basketValue,
  controlsDisabledStatusLocal,
  quantityValue,
  variantValue,
}: TUpdateControlsStatusOnBasketQtyChangePayload): TUpdateControlsStatusOnBasketQtyChange => ({
  type: UPDATE_CONTROLS_STATUS_ON_BASKET_QTY_CHANGE,
  payload: {
    basketValue,
    controlsDisabledStatusLocal,
    quantityValue,
    variantValue,
  },
});

type TEnableControlsOnEmptyInputForKgVariantPayload = {
  controlsDisabledStatusLocal:
    | {
        add: boolean;
        remove: boolean;
      }
    | boolean;
  quantityValue: number;
};

type TEnableControlsOnEmptyInputForKgVariant = Action<
  typeof ENABLE_CONTROLS_ON_EMPTY_INPUT_IN_KG_VARIANT,
  TEnableControlsOnEmptyInputForKgVariantPayload
>;

export const enableControlsOnEmptyInputForKgVariant = ({
  controlsDisabledStatusLocal,
  quantityValue,
}: TEnableControlsOnEmptyInputForKgVariantPayload): TEnableControlsOnEmptyInputForKgVariant => ({
  type: ENABLE_CONTROLS_ON_EMPTY_INPUT_IN_KG_VARIANT,
  payload: {
    controlsDisabledStatusLocal,
    quantityValue,
  },
});

type TQuantityStateActions =
  | TExternalQuantityUpdate
  | TVariantUpdate
  | TQuantityFieldUpdate
  | TDisableControlsOnEmptyInput
  | TEnableControlOnValidInput
  | TUpdateControlsStatusOnBasketQtyChange
  | TEnableControlsOnEmptyInputForKgVariant;

const quantityStateReducer = (state: TQuantityState, action: TQuantityStateActions): TQuantityState => {
  switch (action.type) {
    case QUANTITY_EXTERNAL_UPDATE:
    case VARIANT_UPDATE:
    case QUANTITY_FIELD_UPDATE:
    case DISABLE_CONTROLS_ON_EMPTY_INPUT:
    case ENABLE_CONTROLS_ON_VALID_INPUT:
    case UPDATE_CONTROLS_STATUS_ON_BASKET_QTY_CHANGE:
    case ENABLE_CONTROLS_ON_EMPTY_INPUT_IN_KG_VARIANT:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export function useQuantityState(initalState: TQuantityState) {
  return useReducer(quantityStateReducer, initalState);
}
