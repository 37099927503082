export function findLast<T>(array: T[], func: (item: T, index: number) => T): T | undefined {
  for (let i = array.length - 1; i >= 0; i--) {
    if (func(array[i], i)) {
      return array[i];
    }
  }
}

export function insertSorted<T>(array: T[], element: T, comparator: (itemA: T, itemB: T) => number): void {
  let i = 0;
  for (i = 0; i < array.length && comparator(array[i], element) < 0; i++) {}
  array.splice(i, 0, element);
}
