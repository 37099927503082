import React from 'react';
import PropTypes from 'prop-types';
import classSet from 'classnames';
import { compose } from 'react-recompose';
import helpers from '#/lib/decorators/helpers';
import { withLoadMore } from '#/components/results/has-results/with-load-more';
import { NOW } from '#/analytics/constants';
import analyticsBus from '#/analytics/analyticsBus';
import { basicEvent } from '#/analytics/types/basic';

export const analyticsHandler = () => {
  basicEvent(analyticsBus, {
    type: 'click',
    value: 'show more',
    action: NOW
  });
};

const ShowMore = props => {
  const accessibilityText = props.t('product-list:show-more-products', {
    num: props.showMoreCount
  });

  const clickHandler = e => {
    e.preventDefault();

    props?.handleClick(analyticsHandler);
  };

  return (
    <div
      className={classSet('show-more', {
        'is-updating': props.isUpdating
      })}
    >
      <div className="updating">
        <div className="spinner" />
        <span>{props.t('product-list:loading-more')}</span>
      </div>
      <a
        className="button button-primary small"
        onClick={clickHandler}
        rel="nofollow"
        href={props.href}
        aria-label={accessibilityText}
      >
        {props.t('product-list:show-more', { num: props.showMoreCount })}
      </a>
    </div>
  );
};

ShowMore.propTypes = {
  handleClick: PropTypes.func.isRequired,
  href: PropTypes.string.isRequired,
  isUpdating: PropTypes.bool,
  showMoreCount: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired
};

const enhance = compose(withLoadMore, helpers(['t']));

export default enhance(ShowMore);
