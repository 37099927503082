import { NOW, TOP_NAV, CLOSE } from '#/analytics/constants';
/**
 * Experiment constants drop-down navigation on My Favourites
 */

export enum favDropdownNavigationVariations {
  DEFAULT = 'default',
  DROPDOWN = 'dropdown',
}

export type ExperimentConfig = {
  experiment: string;
  variation: string;
};

export const favDropdownNavigation = {
  featureKey: 'GHS-UK_myFavouritesTopNavLinks',
  variationKey: 'myFavouritesTopNavLinks',
  useCustomerId: true,
};

export const FAV_MENU_KEY = 'my-favourites';
export const FAV_MY_PRODUCT_OOP_KEY = 'myProductsOop1716';
export const FAV_ANALYTICS_NAVIGATION = 'navigation';

export const closeAnalyticsConfig = {
  action: NOW,
  type: FAV_ANALYTICS_NAVIGATION,
  label: `${FAV_MENU_KEY}:${CLOSE}`,
};

export const openAnalyticsConfig = {
  action: NOW,
  type: TOP_NAV,
  label: FAV_MENU_KEY,
};
