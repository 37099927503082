import { buildGetTescoRecommendationsQuery } from '../graphql-queries/get-multiple-tesco-recommendations';
import { RECOMMENDER_ITEM_KEY, RECOMMENDER_ITEM_VALUE } from '../graphql-queries/fragments/recommender-item';
import { buildDynamicPageInformationFragment } from '../graphql-queries/fragments/page-information';
import { ClientTescoRecommendationsResponse } from '../get-tesco-recommendations';
import {
  Recommendation,
  RecommendationProvider,
  RecommendationVariables,
} from '../graphql-queries/recommendation-types';
import { TREX, DCS } from '#/constants/recommendations';
import { Product } from '#/lib/records/item.defs';
import { itemsToMap, transferBulkBuyLimitProperties, transformProduct } from '#/lib/records/product-utils';
import { Item } from '#/lib/records/item';

export type PageInformation = {
  total: number;
  page: number;
  count: number;
  pageSize: number;
  title: string;
  pageId?: string | null;
};

export type RecommenderItem = Omit<Product, 'price'> & {
  price: {
    price: number;
    unitOfMeasure: string;
    unitPrice: number;
    value?: number;
  };
};

export type TescoRecommendationsData = {
  [key: string]: {
    productItems: RecommenderItem[];
    pageInformation: PageInformation;
  };
};

export interface TescoRecommendations {
  data: TescoRecommendationsData;
  errors?: {
    message: string;
    name: string;
    status: number;
    locations: {
      path: string[];
      locations: {
        line: number;
        column: number;
      }[];
    }[];
  }[];
  traceId: string;
  cache: {
    maxAge: number;
  };
}

export type Strategy = {
  position?: number;
  strategy?: string;
  variant?: string;
  type?: RecommendationProvider;
  pageSize: number | undefined;
  superDepartment?: string;
  tpnb?: string;
};

export type RecommendationsProductData = {
  productItems: Array<Product>;
  pageInformation: PageInformation;
};

export const getQuery = (strategies: Strategy[]): string => {
  const fragments = [
    {
      key: RECOMMENDER_ITEM_KEY,
      value: RECOMMENDER_ITEM_VALUE,
    },
    {
      ...buildDynamicPageInformationFragment(['total', 'page', 'count', 'pageSize', 'title', 'pageId']),
    },
  ];
  return buildGetTescoRecommendationsQuery(strategies, fragments);
};

export const getProviderKeyBasedOnStrategy = (
  provider: string,
  index: number,
  strategy?: string,
  variant?: string,
  superDepartment?: string,
  tpnb?: string,
): string => {
  const key = (strategy
    ? variant
      ? `${provider}_${strategy}_${variant}`
      : `${provider}_${strategy}`
    : `${provider}_${superDepartment || index}`
  ).replace(/-/g, '_');

  if (tpnb) return `${key}_${index + 1}`;

  return key;
};

export const buildVariables = (
  recommendationVariables: Recommendation,
  strategies: Strategy[],
): RecommendationVariables => {
  const variables: RecommendationVariables = {};
  Object.assign(variables, recommendationVariables);

  strategies.forEach(({ position, strategy, variant, type, pageSize, superDepartment, tpnb }, index) => {
    const provider = type ? type.toUpperCase() : DCS;
    const key = getProviderKeyBasedOnStrategy(provider, index, strategy, variant, superDepartment, tpnb);

    variables[`provider_${key}`] = provider;
    variables[`pageSize_${key}`] = pageSize;

    if (type === TREX) {
      if (variant) {
        variables[`variant_${key}`] = variant;
      }
      variables[`position_${key}`] = position;
    } else {
      variables[`promotion_${key}`] = strategy;
    }

    if (superDepartment) variables[`superDepartment_${key}`] = superDepartment;
    if (tpnb) variables[`tpnb_${key}`] = tpnb;
  });

  return variables;
};

export const formatRecommendationsResponse = (
  data: TescoRecommendationsData,
  trolleyItems: Array<Item>,
): ClientTescoRecommendationsResponse => {
  if (!data) {
    return {};
  }

  const formattedRecommendations: ClientTescoRecommendationsResponse = {};
  let recommendationTPNBsCount = 0;

  Object.keys(data).forEach(groupName => {
    const recommendationsData = (data[groupName] as unknown) as RecommendationsProductData;

    if (!recommendationsData) {
      return;
    }

    const recommendations = transformProduct(recommendationsData) as RecommendationsProductData;

    if (!recommendations.productItems.length) {
      return;
    }

    const productItems = transferBulkBuyLimitProperties(
      itemsToMap(recommendations.productItems as Array<Item>),
      trolleyItems,
      [['groupLimitReached'], ['groupBulkBuyQuantity']],
    );

    formattedRecommendations[groupName] = {
      pageInformation: recommendations.pageInformation,
      productItems: [...productItems.values()],
    };

    recommendationTPNBsCount++;
  });

  if (!recommendationTPNBsCount) {
    return { empty: true };
  }

  return formattedRecommendations;
};
