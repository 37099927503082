import styled from 'styled-components';
import { TESCO_MODERN_FONT_FAMILY } from '@ddsweb/constants';
import { fontSize } from '@ddsweb/selectors';

export const StyledHumanizedDay = styled.span`
  display: block;
  font-size: ${fontSize.xs};
  font-weight: bold;
  font-family: ${TESCO_MODERN_FONT_FAMILY};
  text-transform: capitalize;
`;
