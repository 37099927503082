import analyticsBus from '#/analytics/analyticsBus';
import { basicEvent } from '#/analytics/types/basic';
import { CONTENT_SEARCH_STORAGE_KEY, BERTIE_EVENT_TYPE_SEARCH, CONTENT_LINK } from '#/experiments/oop-2002/constants';
import { sessionStore } from '#/lib/data-store/client-store';
import { NOW } from '#/analytics/constants';

export const triggerSearchContentLinkAnalyticsEvent = (
  value: string,
  action: string = NOW,
  type: string = BERTIE_EVENT_TYPE_SEARCH,
): void => {
  basicEvent(analyticsBus, {
    action,
    type,
    value,
  });
};

export function triggerSearchContentLinkVisibleAnalyticsEvent(): void {
  const fetchSessionStorage = (sessionStore?.get(CONTENT_SEARCH_STORAGE_KEY) || 0) as number;
  const suggestionTypeCount = fetchSessionStorage + 1;
  const suggestionTypeValue = `${CONTENT_LINK}${suggestionTypeCount}:impression`;
  sessionStore?.set(CONTENT_SEARCH_STORAGE_KEY, suggestionTypeCount);
  triggerSearchContentLinkAnalyticsEvent(suggestionTypeValue);
}
