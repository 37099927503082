import React from 'react';
import { compose } from 'react-recompose';
import helpers from '#/lib/decorators/helpers';
import { InlineLink } from '@ddsweb/link';
import { Text } from '@ddsweb/text';
import { COLLECTION, ON_DEMAND } from '#/constants/shopping-methods';
import { TTranslateFunc } from '#/lib/records/helpers.defs';
import { SplitViewBasket } from '#/lib/records/split-basket';
import {
  StyledBasketSummaryContainer,
  StyledGuidePriceLink,
  StyledBody,
  StyledDivider,
  StyledItemList,
  StyledHeader,
  StyledInLineMessaging,
  StyledHeading,
  StyledTotal,
  StyledValueMessageContainer,
  StyledHiddenOnMobile,
  StyledValue,
  StyledDD,
  StyledDT,
  VisuallyHiddenLabel,
  HiddenOnTabletDesktop,
  StyledSubTitle,
  StyledAccordion,
  StyledDividerMobile,
  ScrollableContainer,
  FixedBody,
  StyledTotalPrice,
  StyledDeliveryMessage,
} from './styled';
import formatNumber from '#/lib/string-formatting/number-formatter';
import Price from '#/components/shared/price';
import { CustomAccordionItem } from './custom-accordion-item';
import ValueBar from '@ddsweb/value-bar';
import { Heading } from '@ddsweb/heading';
import OverlaySpinner from './overlay-spinner';
import SafelyOutputString from '#/components/safely-output-string';
import { FreeDelivery } from '#/lib/records/slot.defs';
import FreeDeliveryMessage from '../free-delivery';

export interface TProps {
  componentTitle: string;
  bagOptions: { charge: number; enabled: boolean; hideBaggingOptions: boolean; isBagless: boolean };
  basketMaxItemCountLimit: number;
  items: number;
  displayAsGuidePrice: boolean;
  language: string;
  t: TTranslateFunc;
  config: (key: string) => Record<string, string | number | boolean> | string;
  guidePrice: number;
  savings: number;
  charges: {
    surcharge: number;
    packaging: { charge: number };
    minimumBasketValue: number;
    depositCharge: number;
  };
  shoppingMethod: string;
  serviceCharge: number;
  coupons: number;
  staffDiscount: number;
  totalPrice: number;
  isAuthenticated: boolean;
  hasValidSlot: boolean;
  clubcardPoints: {
    standardPoints: number;
    greenPoints: number;
    promoPoints: number;
  };
  slotIsBooked: boolean;
  isAmendBasket: boolean;
  isTrolleyLoading: boolean;
  marketplaceBasket: SplitViewBasket | undefined;
  groceryBasket: SplitViewBasket;
  hasMarketplaceOnlyBasket: boolean;
  freeDeliveryDetails: FreeDelivery | Record<string, never>;
}

export const BasketSummaryContent: React.FC<TProps> = ({
  t: translate,
  componentTitle,
  bagOptions,
  basketMaxItemCountLimit,
  items,
  displayAsGuidePrice,
  language,
  config,
  guidePrice,
  savings,
  shoppingMethod,
  serviceCharge,
  coupons,
  staffDiscount,
  clubcardPoints,
  charges,
  isAuthenticated,
  hasValidSlot,
  children,
  slotIsBooked,
  isAmendBasket,
  isTrolleyLoading,
  marketplaceBasket,
  groceryBasket,
  hasMarketplaceOnlyBasket,
  freeDeliveryDetails,
}) => {
  const isMixedBasket = !!marketplaceBasket && !!groceryBasket && groceryBasket?.totalItems > 0;

  const displayBagOptions = hasValidSlot
    ? bagOptions
    : { charge: 0, enabled: false, isBagless: false, hideBaggingOptions: false };
  const { charge: bagCharge, enabled, hideBaggingOptions, isBagless } = displayBagOptions;
  const bagChargeValue = !isBagless ? bagCharge : 0;
  const shouldShowBagCharge = !hideBaggingOptions && enabled && !isBagless && bagChargeValue >= 0;

  const currencyConfig = config('currency') as Record<string, string | number | boolean>;
  const shouldShowClubcardPoints = config('clubcardInBasketSummary') as Record<string, string | number | boolean>;
  const options = {
    decimalPlaces: config('REGION') === 'UK' ? 0 : currencyConfig.decimalPlaces,
    decimalMark: currencyConfig.decimalMark,
    symbol: currencyConfig.symbol,
    showAsciiSymbol: true,
    compactMode: !currencyConfig.spaceBetweenSymbolAndValue,
    symbolPosition: currencyConfig.symbolPosition,
    thousandsSeparator: currencyConfig.thousandsSeparator,
  };

  const minBasketCharge = formatNumber(Math.abs(charges.surcharge), options);
  const minBasketThreshold = formatNumber(Math.abs(charges.minimumBasketValue), options);
  const pickAndPackValue = serviceCharge;
  const surchargeValue = hasValidSlot ? charges.surcharge : 0;
  const pickAndPackLinkKey = (): string => {
    switch (shoppingMethod) {
      case COLLECTION:
        return config(`links:basketSurchargeInfoClickCollect:${language}`) as string;
      case ON_DEMAND:
        return config(`links:basketSurchargeInfoOnDemand:${language}`) as string;
      default:
        return config(`links:basketSurchargeInfoHomeDelivery:${language}`) as string;
    }
  };

  const minBasketChargeLinkText = translate('trolley:warnings.min-basket-charge.link');
  const minBasketChargeText = translate(`trolley:warnings.min-basket-charge.slot`, {
    'min-basket-charge': minBasketCharge,
    'min-basket-threshold': minBasketThreshold.toLocaleString().replace(' ', '&nbsp;'),
  });

  let minBasketChargeText1, minBasketChargeText2;
  if (language === 'sk-SK' || language === 'hu-HU') {
    minBasketChargeText1 = translate(`trolley:warnings.min-basket-charge.slot1`, {
      'min-basket-threshold': minBasketThreshold,
      'min-basket-charge': minBasketCharge,
    });
    minBasketChargeText2 = translate(`trolley:warnings.min-basket-charge.slot2`, {
      'min-basket-charge': minBasketCharge,
    });
  }

  const helpGuidePriceLinkKey = (): string => {
    if (shoppingMethod === ON_DEMAND) {
      return 'help:guidePriceOnDemand';
    } else {
      return 'help:guidePrice';
    }
  };

  const clubcardPointsKey = 'clubcard-points-earned';
  let points = 0;
  if (clubcardPoints && (clubcardPoints.standardPoints || clubcardPoints.greenPoints || clubcardPoints.promoPoints)) {
    if (clubcardPoints.standardPoints) {
      points = clubcardPoints.standardPoints;
    }
    if (clubcardPoints.greenPoints) {
      points = clubcardPoints.greenPoints;
    }
    if (clubcardPoints.promoPoints) {
      points = clubcardPoints.promoPoints;
    }
  }

  const calculatedSavingsPrice = savings?.toLocaleString(language, {
    style: 'currency',
    currency: currencyConfig.isoCode as string,
  });

  const offerSavings = savings > 0 && (
    <>
      <VisuallyHiddenLabel>{`${translate(
        'checkout:payment-summary.offer-savings-summary',
      )} ${calculatedSavingsPrice}`}</VisuallyHiddenLabel>
      <StyledValueMessageContainer aria-hidden>
        <ValueBar rightLabel={<Text as="p">{calculatedSavingsPrice}</Text>}>
          <Heading headingLevel="5">{translate('checkout:payment-summary.offer-savings-summary')}</Heading>
        </ValueBar>
      </StyledValueMessageContainer>
    </>
  );

  const minimumBasketCharge = surchargeValue > 0 && (
    <StyledItemList data-testid="min-basket-charge">
      <dt>{translate('checkout:order-summary.minimum-basket-charge')}</dt>
      <StyledDD>
        <Price value={charges.surcharge} compactMode={true} {...currencyConfig} />
      </StyledDD>
    </StyledItemList>
  );

  const pickAndPack = (slotIsBooked || isAmendBasket) && !hasMarketplaceOnlyBasket && (
    <StyledItemList data-testid="pick-and-pack">
      <dt>
        {shoppingMethod === COLLECTION
          ? translate('checkout:payment-summary.pick-and-pack-summary')
          : translate('checkout:payment-summary.pick-pack-and-deliver-summary')}
      </dt>
      <StyledDD>
        <Price value={pickAndPackValue} compactMode={true} {...currencyConfig} />
      </StyledDD>
    </StyledItemList>
  );

  const bagChargeDisplay = shouldShowBagCharge && (
    <StyledItemList data-testid="bag-charge">
      <dt>{translate('checkout:bag-charge')}</dt>
      <StyledDD>
        <Price value={bagChargeValue} compactMode={true} {...currencyConfig} />
      </StyledDD>
    </StyledItemList>
  );

  const eCupons = coupons > 0 && (
    <StyledItemList discount data-testid="coupons">
      <dt>{translate('checkout:payment-summary.ecoupons-discount')}</dt>
      <StyledDD>
        <Price value={-Math.abs(coupons)} compactMode={true} {...currencyConfig} />
      </StyledDD>
    </StyledItemList>
  );

  const discount = staffDiscount > 0 && (
    <StyledItemList discount data-testid="staff-discount">
      <dt>{translate('checkout:payment-summary.staff-discount')} </dt>
      <StyledDD>
        <Price value={-Math.abs(staffDiscount)} compactMode={true} {...currencyConfig} />
      </StyledDD>
    </StyledItemList>
  );

  const guidePriceContent = (
    <>
      <StyledTotalPrice pad={false} highlight={!marketplaceBasket} data-testid="guide-price">
        <dt>{displayAsGuidePrice ? translate('common:guide-price') : translate('common:price')}</dt>
        <StyledDD>
          <Price
            data-auto="guide-price"
            value={groceryBasket?.guidePrice || guidePrice}
            compactMode={true}
            {...currencyConfig}
          />
        </StyledDD>
      </StyledTotalPrice>
      <StyledGuidePriceLink
        rel="noopener"
        target="_blank"
        ariaLabel={translate('checkout:payment-summary.guide-price-terms-accessibility')}
        href={config(`${helpGuidePriceLinkKey()}:${language}`)}
      >
        {translate('checkout:payment-summary.guide-price-information-link-text')}
      </StyledGuidePriceLink>
    </>
  );
  const fulfilment = marketplaceBasket?.charges?.fulfilment;
  const mpDeliveryValue = typeof fulfilment === 'number' && fulfilment > 0 ? fulfilment : 0;

  const totalEstimatedValue =
    hasMarketplaceOnlyBasket && marketplaceBasket
      ? marketplaceBasket.guidePrice + mpDeliveryValue - coupons - staffDiscount
      : guidePrice +
        mpDeliveryValue +
        pickAndPackValue +
        surchargeValue +
        bagChargeValue +
        charges?.depositCharge -
        coupons -
        staffDiscount;

  const totalText =
    hasMarketplaceOnlyBasket && marketplaceBasket
      ? translate('orders:common.total-mp')
      : translate('orders:common.total-estimated');

  const totalEstimated = totalEstimatedValue > 0 && (
    <StyledTotal data-testid="total-estimated">
      <dt>{totalText} </dt>
      <StyledDD>
        <Price value={totalEstimatedValue} compactMode={true} {...currencyConfig} />
      </StyledDD>
    </StyledTotal>
  );
  const clubCardPoints = shouldShowClubcardPoints && clubcardPoints && (
    <StyledItemList data-testid="clubcard-points" pad={true}>
      <dt>{translate(`trolley:header.${clubcardPointsKey}`)}</dt>
      <StyledDD>
        <StyledValue>{points}</StyledValue>
      </StyledDD>
    </StyledItemList>
  );

  const depositCharges = charges.depositCharge > 0 && (
    <StyledItemList data-testid="includes-drs-charge" pad={true}>
      <dt>{translate('trolley:header.includes-drs-charge')}</dt>
      <StyledDD>
        <Price value={charges.depositCharge} compactMode={true} {...currencyConfig} />
      </StyledDD>
    </StyledItemList>
  );

  const mpTotalPrice = marketplaceBasket && (
    <StyledTotalPrice highlight={hasMarketplaceOnlyBasket}>
      <dt>{translate(`trolley:header.marketplace-total-price`)}</dt>
      <StyledDD>
        <StyledValue>
          <Price value={marketplaceBasket.guidePrice} compactMode={true} {...currencyConfig} />
        </StyledValue>
      </StyledDD>
    </StyledTotalPrice>
  );

  const getMarketplaceDeliveryValue = (): JSX.Element => {
    if (typeof fulfilment === 'number') {
      if (fulfilment === 0) {
        return <StyledDeliveryMessage>{translate('trolley:marketplace-free-delivery-message')}</StyledDeliveryMessage>;
      }

      return <Price value={fulfilment} compactMode={true} {...currencyConfig} />;
    }

    return <StyledDeliveryMessage>{translate('trolley:marketplace-delivery-message')}</StyledDeliveryMessage>;
  };

  const mpDelivery = marketplaceBasket && (
    <StyledItemList data-testid="marketplace-delivery">
      <dt>{translate(`trolley:header.marketplace-delivery`)}</dt>
      <StyledDD>{getMarketplaceDeliveryValue()}</StyledDD>
    </StyledItemList>
  );

  const subTotal = (subTotalValue: number): JSX.Element | null =>
    subTotalValue ? (
      <StyledItemList highlight data-testid="subtotal">
        <dt>{translate(`trolley:header.subtotal`)}</dt>
        <StyledDD>
          <StyledValue>
            <Price value={subTotalValue} compactMode={true} {...currencyConfig} />
          </StyledValue>
        </StyledDD>
      </StyledItemList>
    ) : null;

  const grocerySubTotal = groceryBasket.guidePrice + pickAndPackValue + surchargeValue - coupons - staffDiscount;

  const groceriesMoreInfo = (
    <>
      <StyledItemList>
        <StyledDT>
          <div>{`${translate('checkout:order-summary.items-in-trolley-summary')}`}</div>

          {basketMaxItemCountLimit > 0 && (
            <div>
              {`${translate('checkout:order-summary.items-in-trolley-maximum', {
                limit: basketMaxItemCountLimit,
              })}`}
            </div>
          )}
        </StyledDT>
        <dd>
          <StyledValue>{groceryBasket?.totalItems || items}</StyledValue>
        </dd>
      </StyledItemList>
      <StyledDivider />
      {guidePriceContent}
      {minimumBasketCharge}
      {pickAndPack}
      {bagChargeDisplay}
      {eCupons}
      {discount}
      {depositCharges}

      {isMixedBasket && subTotal(grocerySubTotal)}
    </>
  );

  const marketplaceMoreInfo = (marketplaceBasket: SplitViewBasket): JSX.Element => (
    <>
      <StyledItemList>
        <StyledDT>
          <div>{`${translate('checkout:order-summary.items-in-trolley-summary')}`}</div>
        </StyledDT>
        <dd>
          <StyledValue>{marketplaceBasket.totalItems}</StyledValue>
        </dd>
      </StyledItemList>

      <StyledDivider />

      {mpTotalPrice}
      {mpDelivery}
      {isMixedBasket && subTotal(marketplaceBasket.totalPrice)}
    </>
  );

  const minimumChargeMessage =
    charges.surcharge > 0 && isAuthenticated && hasValidSlot ? (
      language === 'sk-SK' || language === 'hu-HU' ? (
        <>
          <StyledInLineMessaging
            variant="warning"
            assistiveText={minBasketChargeText1 + minBasketChargeLinkText + minBasketChargeText2}
          >
            <Text>
              <span>{minBasketChargeText1}</span>
              <InlineLink href={pickAndPackLinkKey()} rel="noopener noreferrer" target="_blank">
                {minBasketChargeLinkText}
              </InlineLink>
              <span>{minBasketChargeText2}</span>
            </Text>
          </StyledInLineMessaging>
        </>
      ) : (
        <>
          <StyledInLineMessaging variant="warning" assistiveText={minBasketChargeText + minBasketChargeLinkText}>
            <Text>
              <SafelyOutputString aria-hidden>{minBasketChargeText}</SafelyOutputString>
              <InlineLink href={pickAndPackLinkKey()} rel="noopener noreferrer" target="_blank">
                {minBasketChargeLinkText}
              </InlineLink>
            </Text>
          </StyledInLineMessaging>
        </>
      )
    ) : (
      undefined
    );

  const freeDeliveryMessage = (
    <FreeDeliveryMessage
      freeDelivery={freeDeliveryDetails}
      helpers={{ translate, config }}
      hasValidSlot={hasValidSlot}
    />
  );

  const customAccordion = (
    <StyledAccordion behaviour="multiple">
      {groceryBasket && !hasMarketplaceOnlyBasket && (
        <CustomAccordionItem
          id={groceryBasket.typename}
          heading={translate(`trolley:header.groceries-sub-heading`)}
          subHeading={translate(`trolley:header.subtotal`)}
          price={grocerySubTotal}
          moreInfo={groceriesMoreInfo}
          currencyConfig={currencyConfig}
          minimumChargeMessage={minimumChargeMessage}
          freeDeliveryMessage={freeDeliveryMessage}
          isMixedBasket={isMixedBasket}
        />
      )}
      {marketplaceBasket && (
        <CustomAccordionItem
          id={marketplaceBasket.typename}
          heading={translate(`trolley:header.marketplace-sub-heading`)}
          subHeading={translate(`trolley:header.subtotal`)}
          price={marketplaceBasket.totalPrice}
          moreInfo={marketplaceMoreInfo(marketplaceBasket)}
          currencyConfig={currencyConfig}
          isMixedBasket={isMixedBasket}
        />
      )}
    </StyledAccordion>
  );

  return (
    <StyledBasketSummaryContainer>
      <ScrollableContainer>
        {isTrolleyLoading && <OverlaySpinner translate={translate} />}
        <StyledHeader>
          <StyledHeading headingLevel="2">{componentTitle}</StyledHeading>
        </StyledHeader>
        <StyledDividerMobile />

        <HiddenOnTabletDesktop>{customAccordion}</HiddenOnTabletDesktop>

        <StyledBody>
          {isMixedBasket && (
            <StyledHiddenOnMobile>
              <StyledSubTitle headingLevel="3">{translate(`trolley:header.groceries-sub-heading`)}</StyledSubTitle>
            </StyledHiddenOnMobile>
          )}
          <StyledHiddenOnMobile>
            {!hasMarketplaceOnlyBasket && (
              <>
                {minimumChargeMessage}
                {freeDeliveryMessage}
                {groceriesMoreInfo}
              </>
            )}
          </StyledHiddenOnMobile>

          {marketplaceBasket && (
            <>
              {isMixedBasket && (
                <StyledHiddenOnMobile>
                  <StyledDivider />
                  <StyledSubTitle headingLevel="3">
                    {translate(`trolley:header.marketplace-sub-heading`)}
                  </StyledSubTitle>
                </StyledHiddenOnMobile>
              )}
              <StyledHiddenOnMobile>{marketplaceMoreInfo(marketplaceBasket)}</StyledHiddenOnMobile>
            </>
          )}
        </StyledBody>
      </ScrollableContainer>
      <FixedBody>
        <StyledHiddenOnMobile>
          <StyledDivider />
        </StyledHiddenOnMobile>
        {totalEstimated}
        {offerSavings}
        {clubCardPoints}
        {(totalEstimated || clubCardPoints || offerSavings) && (
          <StyledHiddenOnMobile>
            <StyledDivider />
          </StyledHiddenOnMobile>
        )}

        {children}
      </FixedBody>
    </StyledBasketSummaryContainer>
  );
};

const enhance: Function = compose(helpers(['t']));

export default enhance(BasketSummaryContent);
