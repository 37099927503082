import React from 'react';
import { ALDI_PRICE_MATCH_MESSAGE } from '#/constants/product-tile';
import { WhiteBackground } from '#/components/product-tiles/common/info-messages/low-everyday-price-message/styled';
import { DarkGreyBodyText } from '#/components/product-tiles/common/info-messages/aldi-price-match-info-message/styled';
import { AldiPriceMatchLogo } from '#/components/product-tiles/common/info-messages/aldi-price-match-info-message//aldi-price-match-icon';

export const AldiPriceMatchInfoMessage: React.FC = () => (
  <WhiteBackground>
    <AldiPriceMatchLogo />
    <DarkGreyBodyText as="p">{ALDI_PRICE_MATCH_MESSAGE}</DarkGreyBodyText>
  </WhiteBackground>
);
