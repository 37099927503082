import { ContentDataPayload } from '../formatters/events/content';
import { getCampaignTitle, PricePayload } from '#/analytics/bertie/formatters/misc';
import { OfferDataPayload } from '#/analytics/bertie/formatters/sash';
import { ProductDataPayload, ProductDataRatingPayload, WeightPayload } from '../formatters/products';
import { getGtin13FromImageUrl } from '#/lib/records/product-utils';
import { Item } from '#/lib/records/item';
import { PlainObject } from '#/types';
import { isAldiPriceMatch, isLowEverydayPricing } from '#/selectors/item';
import { Promotion } from '#/lib/records/item.defs';
import { ALDI_PRICE_MATCH, LOW_EVERYDAY_PRICE } from '#/analytics/constants';
import { GHS_SELLER_ID } from '#/constants/analytics';

export interface ContentDataBasePayload {
  campaignId?: string;
  campaignTitle?: string;
  componentId?: string;
  contentSegment?: PlainObject;
  currency: string;
  posInModule?: number;
  ruleId?: string;
  seedLookup?: string;
}

export interface RawProductData extends ProductDataRatingPayload {
  adId?: string;
  baseProductId?: string;
  defaultImageUrl?: string;
  displayType?: string;
  id: string;
  unitOfMeasure?: string;
  gtin?: string;
  isForSale?: boolean;
  isInFavourites?: boolean;
  status?: string;
  substitutions: {
    [propName: string]: RawItemData;
  };
  price?: string;
  productType?: string;
  unitPrice?: string;
  title: string;
  isNew?: boolean;
  seller?: {
    id: string;
  };
  foodIcons?: Array<string>;
  modelMetadata?: {
    name: string;
    version: string;
  };
}

export interface SeedProduct {
  gtin13?: string | null;
  id: string;
  name: string;
}

export interface SellerData {
  id: string;
}

export interface RawItemData {
  catchWeight?: number;
  isSubstitute: boolean;
  product: RawProductData;
  promotions?: OfferDataPayload[];
  quantity?: number;
  seedProduct: SeedProduct;
  isWhyNotTry?: boolean;
  isSponsoredProduct?: boolean;
  isNewlyAdded?: boolean;
  foodIcons?: Array<string>;
}

export interface SellerData {
  id: string;
}

export interface ItemToContentDataPayload extends ContentDataBasePayload {
  item: Item | RawItemData;
}

export function getItemSeedLookup(item: RawItemData, seedLookup?: string): string | undefined {
  const { isSubstitute } = item;

  if (!isSubstitute) {
    return;
  }
  return seedLookup || 'sub:gapi';
}

export function getSellerId(sellerInfo?: SellerData): string {
  if (sellerInfo?.id) return sellerInfo?.id;
  return GHS_SELLER_ID;
}

export function getItemSeedProduct(item: RawItemData): ProductDataPayload | undefined {
  const { seedProduct } = item;

  if (!seedProduct || !Object.keys(seedProduct).length) {
    return;
  }

  const { gtin13, id, name } = seedProduct;

  return {
    gtin: gtin13,
    name,
    tpnb: id,
  };
}

export function getItemPrice(item: RawItemData, currency: string): PricePayload | undefined {
  const {
    catchWeight,
    product: { price, productType, unitPrice },
  } = item;

  let calculatedPrice = 0;

  switch (productType) {
    case 'CatchWeightProducts':
      if (typeof catchWeight !== 'number' || typeof unitPrice !== 'number') {
        return;
      }

      calculatedPrice = catchWeight * unitPrice;

      break;
    default:
      if (typeof price !== 'number') {
        return;
      }

      calculatedPrice = price;
  }

  return {
    currency,
    price: parseFloat(calculatedPrice.toFixed(2)),
  };
}

export function getItemWeight(item: RawItemData): WeightPayload | undefined {
  const {
    catchWeight,
    product: { unitOfMeasure },
  } = item;

  if (typeof catchWeight === 'undefined') {
    return;
  }

  return {
    weight: catchWeight,
    weightMeasure: unitOfMeasure,
  };
}

function addProposition(item: Item): (Promotion | { proposition: string })[] {
  const { promotions } = item;

  if (isAldiPriceMatch(item)) {
    if (!promotions) {
      return [{ proposition: ALDI_PRICE_MATCH }];
    }
    return [...promotions, { proposition: ALDI_PRICE_MATCH }];
  }

  if (isLowEverydayPricing(item)) {
    if (!promotions) {
      return [{ proposition: LOW_EVERYDAY_PRICE }];
    }
    return [...promotions, { proposition: LOW_EVERYDAY_PRICE }];
  }
  return promotions;
}

export function itemToContentData(payload: ItemToContentDataPayload, startIndex = 0): ContentDataPayload[] {
  const { campaignId, campaignTitle, contentSegment, currency, item: itemRecord, seedLookup, ruleId } = payload;
  const item = itemRecord as RawItemData;
  const itemIndex = startIndex + 1;

  const {
    product: {
      baseProductId,
      defaultImageUrl,
      displayType,
      gtin,
      id,
      isForSale,
      isInFavourites,
      reviews,
      status,
      substitutions = {},
      title,
      isNew,
      seller,
      foodIcons,
      modelMetadata,
    },
    isSponsoredProduct,
    isWhyNotTry,
  } = item;

  return [
    {
      campaignId,
      campaignTitle: getCampaignTitle(campaignTitle),
      contentSegment,
      posInModule: itemIndex,
      product: {
        displayType,
        isForSale,
        isInFavourites,
        gtin: gtin || getGtin13FromImageUrl(defaultImageUrl),
        sellerId: getSellerId(seller),
        name: title,
        promotions: addProposition(itemRecord as Item),
        reviews,
        seedLookup: getItemSeedLookup(item, seedLookup),
        seedProduct: getItemSeedProduct(item),
        status,
        tpnb: baseProductId || id,
        tpnc: id,
        isNew,
        isSponsoredProduct,
        isWhyNotTry,
        ...(foodIcons && { foodIcons }),
        ...getItemPrice(item, currency),
        ...getItemWeight(item),
        ...(modelMetadata ? { personalisationModel: `${modelMetadata?.version}-${modelMetadata?.name}` } : {}),
      },
      ruleId,
    },
    ...Object.values(substitutions).reduce(function(
      acc: ContentDataPayload[],
      substitution: RawItemData,
      index: number,
    ) {
      const subIndex = itemIndex + index;

      return [
        ...acc,
        ...itemToContentData(
          {
            ...payload,
            item: substitution,
          },
          subIndex,
        ),
      ];
    },
    []),
  ];
}

export interface ItemsToContentDataPayload extends ContentDataBasePayload {
  items: Map<string, Item> | Item[];
  sendSponsoredId?: boolean;
}

export function itemsToContentData(payload: ItemsToContentDataPayload, startIndex = 0): ContentDataPayload[] {
  const {
    campaignId,
    campaignTitle,
    componentId,
    contentSegment,
    currency,
    items,
    ruleId,
    seedLookup,
    sendSponsoredId,
  } = payload;
  const itemsArray = Array.isArray(items) ? items : Array.from(items.values());

  return itemsArray.reduce(function(acc: ContentDataPayload[], item: Item | RawItemData) {
    return [
      ...acc,
      ...itemToContentData(
        {
          campaignId: sendSponsoredId && item.product?.adId ? item.product?.adId : campaignId,
          campaignTitle,
          componentId,
          contentSegment,
          currency,
          item,
          ruleId,
          seedLookup,
        },
        startIndex + acc.length,
      ),
    ];
  }, []);
}

export function itemToProductPayload(item: RawItemData | Item, currency: string): ProductDataPayload {
  const {
    product: {
      baseProductId,
      unitOfMeasure,
      defaultImageUrl,
      gtin,
      id,
      isInFavourites,
      isForSale,
      status,
      title,
      displayType,
      isNew,
      seller,
      foodIcons,
    },
    isSponsoredProduct,
    isWhyNotTry,
    quantity,
  } = item as RawItemData;

  return {
    gtin: gtin || getGtin13FromImageUrl(defaultImageUrl),
    isInFavourites,
    isForSale,
    status,
    name: title,
    promotions: addProposition(item as Item),
    quantity,
    displayType,
    seedProduct: getItemSeedProduct(item as RawItemData),
    tpnb: baseProductId || id,
    tpnc: id,
    currency,
    weightMeasure: unitOfMeasure,
    ...getItemPrice(item as RawItemData, currency),
    ...getItemWeight(item as RawItemData),
    isNew,
    isSponsoredProduct,
    isWhyNotTry,
    sellerId: getSellerId(seller),
    ...(foodIcons && { foodIcons }),
  };
}
