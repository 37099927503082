import React from 'react';
import PropTypes from 'prop-types';
import SafeForm from '#/components/shared/safe-form';

const SubstitutionPreference = ({
  action,
  ariaButton,
  ariaLabel,
  checked,
  label,
  onClick,
  productId,
  returnUrl,
  substitutionOption,
  value
}) => (
  <div className="tile-content--lower-left">
    <div className="trolley-tile--substitution-option">
      <SafeForm key={`safeForm-${productId}`} action={action} method="post">
        <input
          type="hidden"
          id="productID"
          name="productID"
          value={productId}
        />
        <input
          type="hidden"
          id="returnUrl"
          name="returnUrl"
          value={returnUrl}
        />
        <input
          type="hidden"
          id="pickerNotes"
          name="pickerNotes"
          value={value}
        />
        <div className="checkbox">
          <input
            type="checkbox"
            id={`substitution-checkbox-${productId}`}
            checked={checked}
          />
          {checked ? (
            <div className="icon-checkbox-square-checked" />
          ) : (
            <div className="icon-checkbox-square-unchecked" />
          )}
          <label
            className="substitution-label-container-cell"
            htmlFor={`substitution-checkbox-${productId}`}
          >
            {label}
            <span className="visually-hidden">{ariaLabel}</span>
          </label>
          <button
            type="submit"
            onClick={onClick}
            name="substitutionOption"
            value={substitutionOption}
            id={`substitution-submit-${productId}`}
            className="substitution-submit-button"
          >
            <span className="visually-hidden">{ariaButton}</span>
          </button>
        </div>
      </SafeForm>
    </div>
  </div>
);

SubstitutionPreference.propTypes = {
  action: PropTypes.string.isRequired,
  ariaButton: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  productId: PropTypes.string.isRequired,
  returnUrl: PropTypes.string.isRequired,
  substitutionOption: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};

export default SubstitutionPreference;
