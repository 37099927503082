import { HAVE_YOU_FORGOTTEN } from '#/constants/spa-resource';
import { updateHYFRecommendations } from '#/actions/recommendations-action-creators';
import { getHaveYouForgottenResource } from '#/selectors/resources';

export const type = HAVE_YOU_FORGOTTEN;

export function handler(dispatch, getState) {
  const { data } = getHaveYouForgottenResource(getState());

  if (!data) {
    return;
  }

  dispatch(updateHYFRecommendations(data));
}
