import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from '#/lib/render/connect-deep-compare';
import { getIsUserAuthenticated, getIsUserRegistered } from '#/reducers/user';
import Link from '#/components/link-check-spa';
import { WidgetContainer } from '#/components/cms-driven/widget-container';
import CMSAnalytics from '#/components/cms-driven/helpers/cms-analytics';
import { HomeWidget } from '#/components/cms-driven/home-widget';
import { ResponsiveImage } from '#/components/cms-driven/responsive-image';
import { getTrolley } from '#/selectors/trolley';

const displayImage = imageUrl => (
  <span className="discover-widget--image-wrapper">
    <ResponsiveImage
      alt=""
      className="discover-widget--image"
      maxWidth={285}
      src={imageUrl}
    />
  </span>
);

const mapStateToProps = state => ({
  isUserAuthenticated: getIsUserAuthenticated(state),
  isUserRegistered: getIsUserRegistered(state),
  shoppingMethod: getTrolley(state).shoppingMethod
});

const DiscoverComponent = props => {
  const {
    caption,
    doubleMedium,
    doubleLarge,
    theme,
    title,
    titleSeparator,
    background,
    icon,
    link,
    placement,
    isUserAuthenticated,
    isUserRegistered,
    hide,
    shoppingMethod
  } = props;

  const isAuthenticatedRegisteredUser = isUserAuthenticated && isUserRegistered;

  const key = (isAuthenticatedRegisteredUser && shoppingMethod) || 'anonymous';
  const isHidden = hide?.[key] || false;

  if (isHidden) {
    return null;
  }

  const name = 'discover';
  const hasBorder = ['a', 'f'].indexOf(theme.toLowerCase()) > -1;
  const imageUrl = icon && icon.src;
  const toHref = link?.[shoppingMethod]?.href || link.href;

  return (
    <CMSAnalytics data={props}>
      <WidgetContainer
        doubleMedium={doubleMedium}
        doubleLarge={doubleLarge}
        widgetName={name}
      >
        <HomeWidget
          backgroundImageUrl={background && background.src}
          border={hasBorder}
          cropDirection={background ? background.clipping : null}
          widgetName={name}
          maxWidth={285}
          uid={placement}
        >
          <div
            className={classnames(
              `discover-widget theme-${theme.toLowerCase()}`,
              { 'has-background-image': !!background }
            )}
          >
            <Link to={toHref}>
              <span
                className={classnames('discover-widget--content-wrapper', {
                  'no-image': !imageUrl
                })}
              >
                <span className="discover-widget--title-wrapper">
                  {title ? (
                    <span className="discover-widget--title">{title}</span>
                  ) : (
                    []
                  )}
                  {!imageUrl && title && titleSeparator ? (
                    <span className="discover-widget--icon" />
                  ) : (
                    []
                  )}
                </span>
                {imageUrl ? displayImage(imageUrl) : []}
                <span className="discover-widget--caption">
                  <span className="discover-widget--caption-text">
                    {caption}
                  </span>
                </span>
              </span>
            </Link>
          </div>
        </HomeWidget>
      </WidgetContainer>
    </CMSAnalytics>
  );
};

DiscoverComponent.propTypes = {
  background: PropTypes.shape({
    src: PropTypes.string,
    clipping: PropTypes.oneOf(['left', 'right', 'center'])
  }),
  caption: PropTypes.string,
  doubleLarge: PropTypes.bool,
  doubleMedium: PropTypes.bool,
  hide: PropTypes.shape({
    anonymous: PropTypes.bool
  }),
  icon: PropTypes.shape({
    src: PropTypes.string
  }),
  isUserAuthenticated: PropTypes.bool.isRequired,
  isUserRegistered: PropTypes.bool.isRequired,
  link: PropTypes.shape({
    href: PropTypes.string
  }).isRequired,
  placement: PropTypes.number,
  theme: PropTypes.string,
  title: PropTypes.string,
  titleSeparator: PropTypes.bool
};

DiscoverComponent.defaultProps = {
  titleSeparator: false
};

displayImage.propTypes = {
  imageUrl: PropTypes.string
};

const Discover = connect(mapStateToProps)(DiscoverComponent);

export { Discover };
