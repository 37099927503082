import { Items } from '#/custom-typings/redux-store/trolley.defs';
import { CustomerUnitChoice, SubstitutionOption } from '#/lib/records/item.defs';
import { getPickerNote, getProductId, getQuantity, getSubstitutionOption, getUnit } from '#/selectors/item';
import { Item } from '#/lib/records/item';
import { getById } from '#/lib/records/product-utils';
import { PreferencePayload } from '#/lib/records/product-utils/preference-payload';

export default function preferencePayloadDiff(
  baseItems: Items | Array<Item>,
  items: Items | Array<Item>,
): Array<PreferencePayload> {
  const payload = [] as Array<PreferencePayload>;

  items.forEach((item: Item) => {
    const id = getProductId(item);
    const pickerNote = getPickerNote(item) as string;
    const unit = getUnit(item) as CustomerUnitChoice;
    const substitutionOption = getSubstitutionOption(item) as SubstitutionOption;
    const baseItem = getById(baseItems, id) as Item;

    if (substitutionOption !== baseItem?.substitutionOption || pickerNote !== baseItem?.pickerNote) {
      const qty = getQuantity(item);

      payload.push({
        adjustment: true,
        id,
        newValue: qty,
        oldValue: qty,
        newUnitChoice: unit,
        oldUnitChoice: unit,
        pickerNote,
        substitutionOption,
      });
    }
  });

  return payload;
}
