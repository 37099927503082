import { getResourceData } from '#/resources/resource-util';
import { SUBSCRIPTIONS } from '#/constants/spa-resource';
import { UPDATE_SUBSCRIPTIONS } from '#/constants/subscriptions';

export enum SubscriptionTypeEnum {
  DELIVERY = 'DELIVERY',
  ONLINECLUB = 'ONLINECLUB',
}

export enum SubscriptionStatusTypeEnum {
  ALL = 'ALL',
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED',
  CANCELLED = 'CANCELLED',
  PENDING_STOP = 'PENDING_STOP',
  ORDER_INITIATED = 'ORDER_INITIATED',
  STOPPED = 'STOPPED',
}

export type TimingsType = {
  startTime: string;
  endTime: string;
};

export type ApplicableWeeklySlots = {
  dayOfWeek: string;
  slotTimings: TimingsType[];
};

export type FulfilmentsType = {
  type: string;
  applicableDays: string;
  applicableWeeklySlots: ApplicableWeeklySlots[];
  isSlotRestricted: boolean;
  minBasketValue: number;
  fulfilmentMethod: string;
};

export enum PlanOfferTypeEnum {
  TRIAL = 'TRIAL',
  PAID = 'PAID',
}

export enum PlanStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum EntitlementStatusEnumType {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
}

export type PlanPaymentType = {
  type: string;
};

export enum TenureEnumType {
  months,
}

export type TenureType = {
  period: number;
  type: TenureEnumType;
};

export type PresentExpenseType = {
  paidAmount: number;
  beforeDiscount: number;
};

export type EnrollmentType = {
  planAmount: number;
  subscriptionAmount: number;
  installmentAmount: number;
  remainingInstallments: number;
  payment: PlanPaymentType;
  tenure: TenureType;
  presentExpense: PresentExpenseType;
};

export type DeliveryType = {
  type: string;
  applicableDays: string;
  applicableWeeklySlots: ApplicableWeeklySlots[];
  isSlotRestricted: boolean;
  minBasketValue: number;
  fulfilmentMethod: string;
};

export type ISOCurrencyType = {
  GBP: 'Great Britain';
  EUR: 'Republic of Ireland';
  HUF: 'Hungary';
  CZK: 'Czech Republic';
  PLN: 'Poland';
};

export type CurrencyType = {
  iso: ISOCurrencyType;
  symbol: string;
};

export type LocalisationType = {
  currency: CurrencyType;
};

export type PlanChanges = {
  planId: string;
  name: string;
  amount: number;
  offerType: PlanOfferTypeEnum;
  tenure: TenureType;
  installmentAmount: number;
  payment: PlanPaymentType;
};

export type SubscriptionState = {
  planId: string;
  customerSubscriptionId: string;
  recurringPaymentId: string;
  name: string;
  description: string;
  amount: number;
  subscriptionType: SubscriptionTypeEnum;
  subscriptionStatus: SubscriptionStatusTypeEnum;
  offerType: PlanOfferTypeEnum;
  status: PlanStatusEnum;
  startDate: string;
  endDate: string;
  countryCurrency: string;
  nextRenewalDate: string;
  nextPaymentDate: string;
  previousRenewalDate: string;
  fulfilments: FulfilmentsType[];
  enrollment: EnrollmentType;
  delivery: DeliveryType;
  localisation: LocalisationType;
  planChanges: PlanChanges;
};

export const getDefaultStateFromProps = (props: {
  resources: { [resource: string]: { data: Store['subscriptions'] } };
}): SubscriptionState => {
  return getResourceData(props.resources, SUBSCRIPTIONS) || [];
};

export const getSubscriptionsState = ({ subscriptions }: Store): SubscriptionState[] => subscriptions;

export const hasActiveDeliverySaverSubscriptions = (subscriptions: Store['subscriptions']): boolean => {
  return !!subscriptions.find(
    (subscription: SubscriptionState) =>
      subscription.subscriptionStatus === SubscriptionStatusTypeEnum.ACTIVE ||
      subscription.subscriptionStatus === SubscriptionStatusTypeEnum.PENDING_STOP,
  );
};

export default (state = {}, action: { type: string; value: Store['subscriptions'] }): SubscriptionState[] => {
  switch (action.type) {
    case UPDATE_SUBSCRIPTIONS:
      return action.value;
    default:
      return state as SubscriptionState[];
  }
};
