import React from 'react';
import { HORIZONTAL, VERTICAL } from '@ddsweb/constants';
import classnames from 'classnames';
import { DietarySvgIconComponent } from '#/experiments/oop-1922/components/assets/dietary-svg';
import { DietaryTag, DietaryTagList, StateProps } from '#/experiments/oop-1922/types';
import { getProductTileDietaryInfoVariant, getIsDietaryInfoFeatureEnabled } from '#/experiments/oop-1922/selectors';
import { productTileDietaryInfoVariants } from '#/experiments/oop-1922/constants';
import {
  StyledDietaryIconWithTextHorizontalContainer,
  StyledDietaryIconWithTextComponent,
  StyledDietaryIconTextContainer,
} from '#/experiments/oop-1922/styled';
import { connect } from '#/lib/render/connect-deep-compare';
import { DietaryText } from '#/experiments/oop-1922/components/dietary-text';

const mapDispatchToProps = (state: Store): StateProps => ({
  isDietaryInfoFeatureEnabled: getIsDietaryInfoFeatureEnabled(state),
  dietaryVariantType: getProductTileDietaryInfoVariant(state),
});

const { dietaryIcon } = productTileDietaryInfoVariants;

export const DietaryIconWithTextComponent = (props: DietaryTag): JSX.Element => {
  const { label, tileType } = props;

  const content = (
    <>
      <DietarySvgIconComponent label={label} />
      <DietaryText label={label} textType={dietaryIcon} />
    </>
  );

  if (tileType === VERTICAL) return <StyledDietaryIconWithTextComponent>{content}</StyledDietaryIconWithTextComponent>;

  return <StyledDietaryIconWithTextHorizontalContainer>{content}</StyledDietaryIconWithTextHorizontalContainer>;
};

const Index = (props: DietaryTagList & StateProps): JSX.Element | null => {
  const { content, tileType, isDietaryInfoFeatureEnabled, dietaryVariantType } = props;

  if (!content?.length || !isDietaryInfoFeatureEnabled || dietaryVariantType !== dietaryIcon) return null;

  return (
    <StyledDietaryIconTextContainer className={classnames({ 'flex-horizontal-container': tileType === HORIZONTAL })}>
      {content.map((item, index) => (
        <DietaryIconWithTextComponent tileType={tileType} key={`${index}-${item?.toLowerCase()}`} label={item} />
      ))}
    </StyledDietaryIconTextContainer>
  );
};

export const DietaryIconWithText = connect(mapDispatchToProps)(Index);
