import styled from 'styled-components';
import Modal from '@ddsweb/modal';
import { spacing, fontSize } from '@ddsweb/selectors';
import { media } from '@ddsweb/foundation';
import { Text } from '@ddsweb/text';
import { Heading } from '@ddsweb/heading';

export const Content = styled.div`
  margin-bottom: ${spacing.lg};
`;

export const ButtonContainer = styled.div`
  margin: 40px ${spacing.md} ${spacing.xs} 0;
  display: flex;
  justify-content: flex-end;
  ${media.belowMobileLarge`
    flex-direction: column;
  `};

  > * {
    width: 50%;
    height: 40px;
    ${media.belowMobileLarge`
      width: 100%;
    `};
    margin: ${spacing.xx};
    font-size: ${fontSize.xx};
  }
`;

export const StyledModal = styled(Modal)`
  && {
    z-index: 10000;
  }
`;

export const StyledHeading = styled(Heading)`
  && {
    margin: ${spacing.md} 0 ${spacing.md};
  }
`;

export const StyledBodyText = styled(Text)`
  padding: ${spacing.xs} 0;
`;
