/*
      precisionRound
      Taken from lodash 4.17.4

      Rounds to a specified percision/ decimal place and
      avoids floating point round errors.
 */

export function precisionRound(number, precision) {
  precision = precision === null ? 0 : Math.min(precision, 292);

  if (precision) {
    // Shift with exponential notation to avoid floating-point issues.
    // See [MDN](https://mdn.io/round#Examples) for more details.
    let pair = `${number}e`.split('e');
    const value = Math.round(`${pair[0]}e${+pair[1] + precision}`);

    pair = `${value}e`.split('e');

    return +`${pair[0]}e${+pair[1] - precision}`;
  }

  return Math.round(number);
}
