import moment from 'moment';
import { isObject } from '../../utils/misc';

export function formatDateAsISO(date) {
  // because microsoft barfs on the milliseconds
  return `${moment(date).format('YYYY-MM-DDTHH:mm:ss')}Z`;
}

export function portNumber(req) {
  const hostMatch = req.hostname.match(/:(\d+)$/);

  return (hostMatch ? hostMatch[1] : '') || '';
}

export function capitalize(str) {
  return str[0].toUpperCase() + str.slice(1);
}

export function capitalizeFirstOnly(str) {
  return str[0].toUpperCase() + str.slice(1).toLowerCase();
}

export function trimMultipleSpaces(str) {
  return str.replace(/\s+/g, ' ').trim();
}

// rounding method based on results here: http://jsperf.com/decimal-rounding-tofixed-vs-math-round
export function round(number, decimalPlaces) {
  const x = Math.pow(10, decimalPlaces || 3);

  return Math.round(number * x) / x;
}

// Gaussian Rounding as used by C# - http://stackoverflow.com/questions/3108986/gaussian-bankers-rounding-in-javascript
// GAPI calculates Guide Price using C# Math.Round
// C# uses Gaussian rounding -              0.125 will round to 0.12, -0.125 will round to 0.12
// JS uses Asymmetric Arithmetic Rounding - 0.125 will round to 0.13, -0.125 will round to 0.12.
// We calculate the total price of a tile (e.g. mini-trolley-tile) by doing price * quantity.
// If the price is a fraction (e.g. 2.475, which it can be when we do  (PricePerKilo / MaxWeight)), then we run in to
// problems when we uses the native JS Math.round compared to C#'s Math.Round.
// More interesting reading: https://en.wikipedia.org/wiki/Rounding#Round_half_to_even &
// http://anderly.com/2009/07/28/to-round-up-or-to-round-down-that-is-the-question/
export function gaussianRound(number, decimalPlaces = 0) {
  const decimalPower = Math.pow(10, decimalPlaces);
  const numberPowerFixed = +(decimalPlaces
    ? number * decimalPower
    : number
  ).toFixed(8); // Avoid rounding errors
  const fixedLowerNumberPower = Math.floor(numberPowerFixed);
  const remainingDecimal = numberPowerFixed - fixedLowerNumberPower;
  const roundingFixAmount = 1e-8; // Allow for rounding errors in f
  let result;

  if (
    remainingDecimal > 0.5 - roundingFixAmount &&
    remainingDecimal < 0.5 + roundingFixAmount
  ) {
    if (fixedLowerNumberPower % 2 === 0) {
      result = fixedLowerNumberPower;
    } else {
      result = fixedLowerNumberPower + 1;
    }
  } else {
    result = Math.round(numberPowerFixed);
  }

  return decimalPlaces ? result / decimalPower : result;
}

// Can handle addresses that are an array of lines (as returned from /collection-points),
// or as an object containing a series of keys called "addressLines1", "addressLines2", etc.
export function formatAddress(address) {
  if (isObject(address)) {
    address = Object.values(address);
  }

  if (Array.isArray(address)) {
    return address
      .filter(data => typeof data === 'string' && data.toLowerCase() !== 'null')
      .map(data => data.trim())
      .filter(trimmedData => !!trimmedData)
      .join(', ');
  }

  return '';
}

// ensure trim if there is / at the end
export const trimTrailingSlash = str => str.replace(/(\/?)$/, '');

// Parse any JSON string to object
export const getParsedObject = string => {
  const source = string || '{}';
  let parsedValue;

  try {
    parsedValue = JSON.parse(source);
  } catch (e) {
    return false;
  }

  return parsedValue;
};
