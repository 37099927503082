import {
  getVariationForExperiment,
  getFeatureVariableStringFromState,
  updateRegionInKey
} from '#/lib/optimizely-manager';
import {
  buylistBottomRecommenders,
  checkoutPromotionRecommendationsFeature,
  favCSATConfig
} from '#/constants/experiments';
import { BOTTOM } from '#/constants/common';
import { getItemsByGroupname } from '#/reducers/results';
import { getBrowserTypeAndVersion } from '#/reducers/app';
import { getSlot, getIsAmendBasket } from '#/selectors/trolley';
import { parseCheckoutRecommendationsConfig } from '#/lib/experiment-helper';
import * as checkoutRecommendationsHelpers from '#/lib/checkout-recommendations-helpers';
import { lastOrderCtaInMinibasketFeature } from '#/constants/features';
import { getOffersExtendToAllSlots } from '#/experiments/oop-1822/helpers';

export const getBuylistRecommmendersType = state => {
  const bottomVariations = buylistBottomRecommenders.variants;

  if (
    getVariationForExperiment(
      state,
      buylistBottomRecommenders.key,
      {},
      buylistBottomRecommenders.useCustomerId
    ) === bottomVariations.enabled
  ) {
    return BOTTOM;
  }

  return false;
};

export const showCheckoutRecommendations = (state, config) => {
  const slotEnd = getSlot(state)?.end;
  const isAmend = getIsAmendBasket(state);
  const amendOffersEnabled = config('checkout:enableAmendOffers');
  const checkoutRecommendationsConfig = getCheckoutRecommendationsConfig(state);

  return checkoutRecommendationsHelpers.showCheckoutRecommendations(
    getOffersExtendToAllSlots(state, config),
    slotEnd,
    amendOffersEnabled,
    isAmend,
    checkoutRecommendationsConfig
  );
};

export const getCheckoutRecommendationsConfig = state => {
  const {
    featureKey,
    variationKey,
    useCustomerId
  } = checkoutPromotionRecommendationsFeature;

  const config = getFeatureVariableStringFromState(
    state,
    featureKey,
    variationKey,
    {
      useCustomerId,
      attributes: {
        browserTypeAndVersion: String(getBrowserTypeAndVersion(state))
      }
    }
  );

  return parseCheckoutRecommendationsConfig(config);
};

export const getAllItemsForCarousel = state => {
  const config = getCheckoutRecommendationsConfig(state);
  const order = config && config !== 'default' ? config : [];
  const types = order.map(i => i.name);

  return getItemsByGroupname(state, types);
};

export const getLastOrderMiniBasketCTAVariant = (state, hasLastOrder) => {
  if (!process.env.CLIENT_SIDE) return '';

  const {
    featureKey,
    variationKey,
    useCustomerId
  } = lastOrderCtaInMinibasketFeature;
  const regionInKey = updateRegionInKey(featureKey, state);
  const options = {
    useCustomerId,
    attributes: {
      hasLastOrder
    }
  };

  return getFeatureVariableStringFromState(
    state,
    regionInKey,
    variationKey,
    options
  );
};

export const getCSATConfig = (state, page, attributes = {}) => {
  const feature = { ...favCSATConfig };
  const { featureKey, useCustomerId, keys } = feature;
  const updatedFeatureKey = featureKey.replace('<page>', page);

  const config = keys.reduce((accumulator, key) => {
    accumulator[key] = getFeatureVariableStringFromState(
      state,
      updatedFeatureKey,
      key,
      {
        useCustomerId,
        attributes
      }
    );

    return accumulator;
  }, {});

  return config;
};
