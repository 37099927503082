import styled from 'styled-components';
import { MEDIUM, SPACING_MEDIUM } from '#/components/product-tiles/temp-style-constants';

export const StyledBuyBoxContainer = styled.div`
  display: flex;
  padding-top: ${SPACING_MEDIUM};
  padding-left: 0;
  padding-right: 0;
  flex-grow: 1;
  align-items: flex-end;
  width: 100%;

  .beans-buybox__container {
    width: 100%;
    .beans-radio-button-with-label__label-with-description {
      width: auto;
    }
    .beans-quantity-controls__remove-button:disabled {
      background-color: rgb(204, 204, 204);
      color: rgb(255, 255, 255);
    }

    .beans-quantity-controls__container {
      display: block;
      .beans-form-group__container {
        max-width: none;
        .beans-quantity-controls__input,
        .beans-quantity-controls__dropdown {
          max-width: none;
          margin-right: ${SPACING_MEDIUM};
        }
      }
    }

    // TODO: There is a bug in Beans where
    // very narrow tiles look squashed.
    // This is a temporary override.
    .beans-buybox__weight-input-controls {
      width: 100%;
    }
  }

  @media ${MEDIUM} {
    padding-top: 0;
  }

  form {
    width: 100%;
  }
`;
