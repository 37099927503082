import React from 'react';
import styled from 'styled-components';
import Link from '#/components/link-check-spa';
import { MEDIUM, SPACING_MEDIUM } from '#/components/product-tiles/temp-style-constants';

export const StyledRestOfShelfButton = styled.div`
  padding: 16px 0;
  @media ${MEDIUM} {
    display: flex;
    padding: ${SPACING_MEDIUM} 0;
    margin: auto 0 20px 0;
  }
`;

export const StyledRestOfShelfLink = styled(props => <Link {...props} />)`
  :empty {
    display: none;
  }

  width: auto;

  .ddsweb-link__anchor {
    min-height: 40px;
    height: auto;

    .ddsweb-link__text {
      text-overflow: unset;
      white-space: unset;
      text-align: center;
    }
  }

  @media ${MEDIUM} {
    width: 100%;
  }
`;
