import { getCouponFromCouponsArray } from '#/lib/trolley/trolley-utils';
import { COUPON_STATUS_INCLUDED_IN_SAVINGS, COUPON_QUALIFIED, COUPON_UNQUALIFIED } from '#/constants/coupons';
import { Coupon } from '#/lib/records/trolley.defs';

export const getTrolleyCoupon = (trolley: Store['trolley']['trolley']) => trolley.coupons;

export const getCoupons = (
  {
    trolley: {
      trolley: { coupons },
    },
  }: Store,
  couponType?: typeof COUPON_QUALIFIED | typeof COUPON_UNQUALIFIED,
) => {
  if (coupons) {
    switch (couponType) {
      case COUPON_QUALIFIED:
        return coupons.filter(coupon => coupon.qualified);
      case COUPON_UNQUALIFIED:
        return coupons.filter(coupon => !coupon.qualified);
      default:
        return coupons;
    }
  }
};

export const getCoupon = (state: Store, couponCodeOrId: string) => {
  const coupons = getTrolleyCoupon(state.trolley.trolley);

  return getCouponFromCouponsArray(coupons, couponCodeOrId) as Coupon;
};

export const getCouponSavings = ({ trolley }: Store) => {
  const coupons = trolley.trolley && trolley.trolley.coupons;
  return coupons
    ? coupons.reduce((total, coupon) => {
        if (coupon.status === COUPON_STATUS_INCLUDED_IN_SAVINGS || !coupon.status) {
          return coupon.value + total;
        }

        return total;
      }, 0)
    : 0;
};

export const getIsCouponSubmitting = ({ trolley }: Store) =>
  trolley.addCouponFormSubmitting || getTrolleyCoupon(trolley.trolley).some(coupon => coupon.submitting) || false;
