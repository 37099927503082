import React, { FunctionComponent } from 'react';
import styled, { withTheme } from 'styled-components';
import { Theme } from '@ddsweb/types';
import Icon from '@ddsweb/icon';
import { spacing } from '@ddsweb/selectors';
import helpers from '#/lib/decorators/helpers';
import { TTranslateFunc } from '#/lib/records/helpers.defs';

export type OwnProps = Record<string, never>;
export type Props = OwnProps & { t: TTranslateFunc; theme: Theme };

const PriceInfoWrapper = styled.div`
  display: flex;
  margin-top: ${spacing.sm};
`;

const StyledIcon = styled(Icon)`
  margin-right: ${spacing.xs};
  flex-shrink: 0;
`;

const Message = styled.span`
  line-height: 1.2em;
`;

const PriceInfoComponent: FunctionComponent<Props> = ({ t: translate, theme }) => (
  <PriceInfoWrapper>
    <StyledIcon graphic="info" inverse background={theme.colors.primary} size="xx" />
    <Message>{translate('product-tile:price-info')}</Message>
  </PriceInfoWrapper>
);

export const PriceInfo: FunctionComponent<OwnProps> = helpers(['t'])(withTheme(PriceInfoComponent));
