import { WHY_NOT_TRY } from '#/constants/display-types';
import { Price, Product } from '#/lib/records/item.defs';
import { transformSubstitutions } from '#/lib/records/product-utils';
import { getIsForSale } from '#/selectors/item';

export type ProductData = {
  productItems: Array<Product>;
};

type Options = {
  filterOutUnavailableProducts?: boolean;
};

export default function transformProduct(
  data: ProductData,
  { filterOutUnavailableProducts = false }: Options = {},
): ProductData | undefined {
  if (data) {
    const { productItems, ...rest } = data;

    if (!productItems) {
      return { ...rest, productItems: [] };
    }

    let updatedProductItems = productItems;

    /* 
    Filtering out unavailable product on UI as mango does not have any option in favorites query which would filter out all unavailable products.
    filterOutUnavailableProduct will only be true in case of favorites.
    This code will be removed after LEGO-28666 is done.
    */
    if (productItems && filterOutUnavailableProducts) {
      updatedProductItems = productItems.filter(product => getIsForSale(product));
    }

    return {
      ...rest,
      productItems: (updatedProductItems.map(item => {
        const { price, promotions, ...product } = item;

        const isWhyNotTry = !!(product.context && product.context.type === WHY_NOT_TRY);
        const isSponsoredProduct = !!product.adId;
        const substitutions = transformSubstitutions(product as Product);

        return {
          promotions,
          isSponsoredProduct,
          isWhyNotTry,
          product: {
            ...product,
            ...(price as Price),
            substitutions,
          },
        };
      }) as unknown) as Array<Product>,
    };
  }
}
