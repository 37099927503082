import classnames from 'classnames';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from '#/lib/render/connect-deep-compare';
import helpers from '#/lib/decorators/helpers';
import {
  closeNavMenu,
  hideHeaderSearch,
  openLeftNav,
  showHeaderSearch
} from '#/actions/ui-action-creators';
import { resetTaxonomy } from '#/actions/taxonomy-action-creators';
import { basicEvent } from '#/analytics/types/basic';
import analyticsBus from '#/analytics/analyticsBus';
import { getIsMobile, getLanguageLink, getRedirectUrl } from '#/reducers/app';
import { getIsUserAuthenticated } from '#/reducers/user';
import { getHeaderMultiSearchLinkVisibility } from '#/reducers/ui';
import BrandLogoLink from '#/components/brand-logo-link';
import HeaderGuidePrice from '#/components/headers/header/header-guide-price';
import Link from '#/components/link-check-spa';
import Navigation from '#/components/navigation';
import Overlay from '#/components/shared/overlay';
import SearchBar from '#/components/headers/header/search-bar';
import SlotContextCard from '#/components/context-cards/slot';
import { isHeaderSearchOpen } from '#/reducers/ui';
import AccPriceStatus from '#/components/headers/header/acc-price-status';
import { getFavDropDownNavAnalyticsConfig } from '#/experiments/oop-1716/helpers/helpers';
import { getIsFavsMenuOpen } from '#/experiments/oop-1716/selectors/ui';
import { getShouldShowImprovedMultiSearch } from '#/experiments/oop-1824/selectors';
import {
  getIsMovingSearchBarCenterWide,
  getIsMovingSearchBarEnabled
} from '#/experiments/oop-1972/helpers';
import { getMovingSearchBarVariant } from '#/experiments/oop-1972/selectors';
import HorizontalTaxonomy from '#/components/horizontal-taxonomy';
import MobileMenuButton from '../mobile-menu-button';
import { StyledAuthWrapper } from './styled';
import AuthCtaContainer from '../auth-container/auth-cta-container';
import MobileIcons from '../mobile-icons';

const mapStateToProps = (state, ownProps) => {
  const languageLink = getLanguageLink(state);
  const isUserAuthenticated = getIsUserAuthenticated(state);

  return {
    isUserAuthenticated,
    isMobile: getIsMobile(state),
    menuOpen: !!state.ui.menuOpen,
    isFavMenuOpen: getIsFavsMenuOpen(state),
    multiSearchEnabled: ownProps.f('multiSearchEnabled'),
    isHorizontalTaxonomyEnabled: ownProps.c('enableHorizontalTaxanomy'),
    multiSearchLinkVisible: getHeaderMultiSearchLinkVisibility(state),
    openNavText: ownProps.t('navigation:open-navigation'),
    searchIsVisible: isHeaderSearchOpen(state),
    trolleyTitle: ownProps.t('common:pages.trolley'),
    showImprovedMultiSearch: getShouldShowImprovedMultiSearch(state),
    trolleyUrl: getRedirectUrl(
      state,
      languageLink + 'trolley',
      isUserAuthenticated
    ),
    movingSearchBarVariant: getMovingSearchBarVariant(state),
    isSearchLeftAligned: ownProps.c('isSearchLeftAligned'),
    isGlobalHeader: ownProps.c('isGlobalHeader')
  };
};

@helpers(['t', 'f', 'c'])
@connect(mapStateToProps, {
  closeNavMenu,
  hideHeaderSearch,
  openLeftNav,
  resetTaxonomy,
  showHeaderSearch
})
export default class BrandHeader extends PureComponent {
  static propTypes = {
    c: PropTypes.func.isRequired,
    closeNavMenu: PropTypes.func.isRequired,
    cmsNav: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    f: PropTypes.func.isRequired,
    groceryTabInOpenState: PropTypes.bool.isRequired,
    hideHeaderSearch: PropTypes.func.isRequired,
    isFavMenuOpen: PropTypes.bool,
    isSticky: PropTypes.bool,
    isUserAuthenticated: PropTypes.bool.isRequired,
    menuOpen: PropTypes.bool.isRequired,
    multiSearchEnabled: PropTypes.bool.isRequired,
    isHorizontalTaxonomyEnabled: PropTypes.bool.isRequired,
    multiSearchLinkVisible: PropTypes.bool.isRequired,
    noOfPendingOrders: PropTypes.number,
    openLeftNav: PropTypes.func.isRequired,
    openNavText: PropTypes.string.isRequired,
    osNameMajorVersion: PropTypes.string,
    resetTaxonomy: PropTypes.func.isRequired,
    searchIsVisible: PropTypes.bool.isRequired,
    showHeaderSearch: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    taxonomyState: PropTypes.object.isRequired,
    toggleableDivID: PropTypes.string,
    trolleyTitle: PropTypes.string.isRequired,
    trolleyUrl: PropTypes.string.isRequired,
    showImprovedMultiSearch: PropTypes.bool.isRequired,
    isGlobalHeader: PropTypes.bool.isRequired
  };

  static defaultProps = {
    groceryTabInOpenState: false,
    isSticky: false,
    toggleableDivID: 'brand-header-toggleable'
  };

  static contextTypes = {
    router: PropTypes.object
  };

  handleOverlayClose = () => {
    const { isFavMenuOpen } = this.props;
    if (isFavMenuOpen) {
      const { type, label, action } = getFavDropDownNavAnalyticsConfig(
        isFavMenuOpen
      );
      basicEvent(analyticsBus, { type, value: label, action });
    }

    this.props.closeNavMenu();
    this.props.resetTaxonomy();
  };

  getNavButton = () => (
    <a
      aria-controls="nav"
      aria-haspopup={true}
      aria-expanded="false"
      role="button"
      data-auto="burger-menu-icon"
      className="icon-burger_menu_icon"
      href="#nav"
      onClick={event => {
        this.props.openLeftNav();
        event.preventDefault();
      }}
    >
      <span className="visually-hidden">{this.props.openNavText}</span>
    </a>
  );

  getHeaderClassNames = () => {
    const {
      isSticky,
      isUserAuthenticated,
      isMobile,
      showImprovedMultiSearch,
      searchIsVisible,
      multiSearchEnabled,
      multiSearchLinkVisible,
      isGlobalHeader
    } = this.props;
    return classnames('brand-header brand-header--right-aligned', {
      'global-header': isGlobalHeader,
      'brand-header--sticky': isSticky,
      'brand-header--authenticated': isUserAuthenticated,
      'brand-header--with-search':
        !(isMobile && showImprovedMultiSearch) && searchIsVisible,
      'brand-header--with-improved-search':
        isMobile && showImprovedMultiSearch && searchIsVisible,
      'brand-header--multi-search-enabled': multiSearchEnabled,
      'brand-header--multi-search-visible': multiSearchLinkVisible,
      'brand-header--global-header': isGlobalHeader
    });
  };

  getSearchBarClassNames = () => {
    const {
      movingSearchBarVariant,
      isSearchLeftAligned,
      isGlobalHeader
    } = this.props;
    const isEnableMovingSearchBar = getIsMovingSearchBarEnabled(
      movingSearchBarVariant
    );
    const isSearchCenterMaxWidth = getIsMovingSearchBarCenterWide(
      movingSearchBarVariant
    );

    return classnames('brand-header__search', {
      'global-header-search-bar': isGlobalHeader,
      'brand-header__search--moving-search-bar':
        isEnableMovingSearchBar || isSearchLeftAligned,
      'brand-header__search--right-aligned':
        !isEnableMovingSearchBar && !isSearchLeftAligned,
      'brand-header__search--center-aligned-width': isSearchCenterMaxWidth
    });
  };

  getHeaderMainClassNames = () => {
    const {
      movingSearchBarVariant,
      isSearchLeftAligned,
      isGlobalHeader
    } = this.props;
    const isSearchCenterMaxWidth = getIsMovingSearchBarCenterWide(
      movingSearchBarVariant
    );
    return classnames('brand-header__main', {
      'global-header-wrapper': isGlobalHeader,
      'brand-header--search-left-aligned': isSearchLeftAligned,
      'brand-header__main--search-left-aligned': isSearchLeftAligned,
      'brand-header__main--search-right-aligned': !isSearchLeftAligned,
      'brand-header__main--search-center-aligned': isSearchCenterMaxWidth
    });
  };

  getSearchToggleButton = () => {
    const buttonName = this.props.t(
      this.props.searchIsVisible
        ? 'layout:header.hide-search'
        : 'layout:header.show-search'
    );
    return (
      <button
        aria-expanded={this.props.searchIsVisible}
        name={buttonName}
        title={buttonName}
        className="brand-header__search-toggle icon-search noselect"
        onClick={event => {
          if (this.props.searchIsVisible) {
            this.props.hideHeaderSearch();
          } else {
            this.props.showHeaderSearch();
          }

          event.preventDefault();
        }}
      />
    );
  };

  getGuidePriceLink = () => {
    return (
      <>
        <Link
          rel="nofollow"
          to={this.props.trolleyUrl}
          title={this.props.trolleyTitle}
        >
          <HeaderGuidePrice />
        </Link>
      </>
    );
  };

  renderSlotContextCard() {
    return (
      <div className="brand-header__sidebar">
        <SlotContextCard
          id="context-card-header"
          expandable={false}
          showCheckoutButtonForFullTrolley={false}
        />
      </div>
    );
  }

  render() {
    const {
      isSticky,
      isUserAuthenticated,
      isMobile,
      menuOpen,
      isHorizontalTaxonomyEnabled,
      c: config,
      isGlobalHeader
    } = this.props;

    const isDisplayPrideLogo = config('prideLogoDisplay');
    const prideLogoClass = isDisplayPrideLogo ? 'brand-logo-link--pride' : '';
    const showSlotContextCard = isUserAuthenticated && isMobile;

    const isDisplayBlackLogo = config('blackLogoDisplay');
    const blackLogoClass = isDisplayBlackLogo ? 'brand-logo-link--black' : '';

    const logoClass = blackLogoClass ? blackLogoClass : prideLogoClass;
    const headerClassNames = this.getHeaderClassNames();
    const searchClassName = this.getSearchBarClassNames();
    const headerMainClassName = this.getHeaderMainClassNames();
    const showGlobalHeaderForUnAuth = isGlobalHeader && !isUserAuthenticated;
    return (
      <div className="brand-header-wrapper">
        <header className={headerClassNames}>
          <div className="brand-header__main-column brand-header__main-column--right-aligned">
            <div
              className={classnames({
                'top-bar-container': isGlobalHeader
              })}
            >
              <div className={headerMainClassName}>
                {isGlobalHeader ? (
                  <div className="brand-header__mobile-menu">
                    <MobileMenuButton cmsNav={this.props.cmsNav} />
                  </div>
                ) : (
                  <div className="brand-header__nav-button">
                    {this.getNavButton()}
                  </div>
                )}

                <div
                  className={classnames('brand-header__logo', {
                    'brand-header__global_logo': isGlobalHeader
                  })}
                >
                  <BrandLogoLink logoClass={logoClass} />
                </div>
                {!isGlobalHeader && (
                  <div
                    className={classnames('brand-header__guide-price', {
                      'brand-header__global-header-guide-price': isGlobalHeader
                    })}
                  >
                    {this.getSearchToggleButton()}
                    {this.getGuidePriceLink()}
                  </div>
                )}
              </div>
              <div className={searchClassName} id={this.props.toggleableDivID}>
                <SearchBar />
              </div>
              {isGlobalHeader && (
                <div className="mobile-icons">
                  <MobileIcons authenticated={isUserAuthenticated} />
                </div>
              )}
              {showGlobalHeaderForUnAuth && (
                <StyledAuthWrapper
                  className="auth-wrapper"
                  data-testid="auth-cta-container"
                >
                  <AuthCtaContainer />
                </StyledAuthWrapper>
              )}
            </div>
            <div className="brand-header__navigation">
              <Navigation
                cmsNav={this.props.cmsNav}
                taxonomyState={this.props.taxonomyState}
                noOfPendingOrders={this.props.noOfPendingOrders}
                groceryTabInOpenState={this.props.groceryTabInOpenState}
              />
            </div>
          </div>
          {showSlotContextCard && (
            <>
              {!isGlobalHeader && this.renderSlotContextCard()}
              <AccPriceStatus />
            </>
          )}
        </header>
        {isSticky && <div className="brand-header__spacer" />}
        {isHorizontalTaxonomyEnabled && (
          <HorizontalTaxonomy
            selectedSuperDepartment={
              this.context.router?.params?.superdepartment
            }
          />
        )}

        <Overlay close={this.handleOverlayClose} isOpen={menuOpen} />

        <div
          className={classnames('brand-header__backdrop', {
            'brand-header--open': menuOpen
          })}
        />
      </div>
    );
  }
}
