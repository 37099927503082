/* eslint-disable prettier/prettier */
import React from 'react';
import { fontSize } from '@beans/selectors';
import classnames from 'classnames';
import styled from 'styled-components';
import AddressInfo from '#/components/shared/address-info';
import Calendar from '#/components/shared/calendar';
import SlotTime from '#/components/shared/slot-info';
import { connect } from '#/lib/render/connect-deep-compare';
import helpers from '#/lib/decorators/helpers';
import { isOnDemandShoppingMethod, getOnDemandDynamicDeliveryData } from '#/lib/shopping-method-util';
import { TTranslateFunc } from '#/lib/records/helpers.defs';
import { ShoppingMethod } from '#/constants/shopping-methods';
import { DynamicDeliveryTime } from '#/custom-typings/redux-store/slot.defs';
import { getFulfilmentEstimatedTime } from '#/selectors/slot';
import { getCurrentUrl } from '#/reducers/app';
import { isOnOrdersPage } from '#/lib/page-helper';

type TAddress = {
  name: string;
  addressLine1?: string;
};

type TSlot = {
  start: string;
  end: string;
};

type TEstimatedDeliveryTime = {
  shoppingMethod: ShoppingMethod;
  t: TTranslateFunc;
};
type TSlotAddressInfo = TEstimatedDeliveryTime & {
  address: TAddress;
  fulfilmentEstimatedTime: DynamicDeliveryTime;
  showDeliveryToday: boolean;
};

type TSlotDeliveryDetails = {
  address: TAddress;
  message: string;
  slot: TSlot;
  shoppingMethod: ShoppingMethod;
};

type OwnState = {
  fulfilmentEstimatedTime: DynamicDeliveryTime;
  showDeliveryToday: boolean;
};

const mapStateToProps = (state: Store): OwnState => ({
  fulfilmentEstimatedTime: getFulfilmentEstimatedTime(state),
  showDeliveryToday: isOnOrdersPage(getCurrentUrl(state)),
});

export const DeliveryTimeInfo = styled.div`
  font-size: ${fontSize.xs};
`;

const SlotAddressInfoComponent = helpers(['t'])(
  ({
    shoppingMethod,
    address,
    t: translate,
    fulfilmentEstimatedTime,
    showDeliveryToday,
  }: TSlotAddressInfo): JSX.Element | null => {
    const isOnDemandDelivery = isOnDemandShoppingMethod(shoppingMethod);

    const { onDemandDynamicDeliverySuffix, unit, min, max } = getOnDemandDynamicDeliveryData(
      isOnDemandDelivery,
      true,
      fulfilmentEstimatedTime,
      showDeliveryToday,
    );

    const deliveryTimeInfo = isOnDemandDelivery
      ? translate(`checkout:order-summary.${shoppingMethod}.delivery-time-info${onDemandDynamicDeliverySuffix}`, {
          unit,
          min,
          max,
        })
      : null;

    return (
      <>
        {deliveryTimeInfo && <DeliveryTimeInfo>{deliveryTimeInfo}</DeliveryTimeInfo>}
        <AddressInfo name={address.name} addressLine1={address.addressLine1} />
      </>
    );
  },
);

export const SlotAddressInfo = connect(mapStateToProps)(SlotAddressInfoComponent);

export const SlotDeliveryDetails = ({ address, message, slot, shoppingMethod }: TSlotDeliveryDetails): JSX.Element => {
  const isOnDemand = isOnDemandShoppingMethod(shoppingMethod);

  return (
    <div
      className={classnames('delivery-details-row', {
        'has-message': message,
      })}
    >
      <div className="delivery-details-calendar">
        <Calendar date={slot.start} message={message} monthFormat="MMM" />
      </div>
      <div className="delivery-details__address-slot">
        <SlotTime
          className="slot-time"
          slot={slot}
          showStaticDeliveryTimeText={isOnDemand}
          shoppingMethod={shoppingMethod}
        />
        <div className="delivery-details__address">
          <SlotAddressInfo className="slot-address-info" shoppingMethod={shoppingMethod} address={address} />
        </div>
      </div>
    </div>
  );
};
