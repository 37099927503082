import React from 'react';

export const extractPropsFromWrappedResources = (propName, resources) => {
  if (!resources) return;

  const resourceNames = Object.keys(resources);

  return resourceNames.reduce((result, resourceName) => {
    const resource = resources[resourceName];

    return (resource && resource.data && resource.data[propName]) || result;
  }, null);
};

export const extractPropsFromResources = (propName, resources) => {
  if (!resources) return;

  const resourceNames = Object.keys(resources);

  return resourceNames.reduce((result, resourceName) => {
    const resource = resources[resourceName];

    return (resource && resource[propName]) || result;
  }, null);
};

export const createElementFactory = payload => {
  return (Component, routeProps) => {
    const props = { ...payload, children: routeProps.children, routeProps };

    return <Component {...props} />;
  };
};

export function areParamsEqual(a, b) {
  const normaliseParams = params => {
    if (typeof params !== 'object') {
      return params;
    }

    const keys = [];
    const result = {};

    for (const key in params) {
      if (params[key] !== null) {
        keys.push(key);
      }
    }

    keys.forEach(key => {
      if (
        typeof params[key] === 'object' &&
        Object.keys(params[key]).length === 0
      ) {
        params[key] = undefined;
      }

      if (typeof params[key] !== 'undefined') {
        result[key] = params[key];
      }
    });

    return Object.keys(result).length > 0 ? result : undefined;
  };

  a = normaliseParams(a); // eslint-disable-line no-param-reassign
  b = normaliseParams(b); // eslint-disable-line no-param-reassign

  if (typeof a === 'object' && typeof b === 'object') {
    const aKeys = Object.keys(a);
    const bKeys = Object.keys(b);

    if (aKeys.length !== bKeys.length) {
      return false;
    }

    return aKeys.reduce(
      (result, key) => result && areParamsEqual(a[key], b[key]),
      true
    );
  }

  return a === b;
}
