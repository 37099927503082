// Default overrides for all regions
const DEFAULT_OVERRIDES = {
  breakpoints: {
    desktopLarge: null,
    desktop: 1199,
    mobile: 330,
    mobileLarge: 500,
    tablet: 768,
    tabletLarge: 992
  }
};

// Any theme specific changes that should override the default theme from @beans (styled-components)
export const getDefaultThemeOverrides = () => {
  // Clone default overrides
  let overrides = JSON.parse(JSON.stringify(DEFAULT_OVERRIDES));

  return overrides;
};

export default DEFAULT_OVERRIDES;
