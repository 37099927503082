import styled from 'styled-components';
import { Heading } from '@ddsweb/heading';
import { spacing, fontSize } from '@ddsweb/selectors';
import { media } from '@ddsweb/foundation';
import { InLineMessaging } from '@ddsweb/messaging';

export const StyledHeading = styled(Heading)`
  && {
    float: left;
    margin-right: 5px;
  }
`;

export const StyledTitle = styled(Heading)`
   {
    margin-bottom: ${spacing.md};
  }
`;

export const StyledAddressHeading = styled(Heading)`
  && {
    font-size: ${fontSize.xx};
    line-height: ${spacing.lg};
    ${media.belowTablet`
      margin: 15px 0 ${spacing.sm};
    `};
  }
`;

export const StyledInLineMessaging = styled(InLineMessaging)`
  margin-bottom: ${spacing.md};
`;
