import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import PricePerSellableUnit from '../../../price-per-sellable-unit/index';
import TotalPrice from '../../../total-price';
import {
  getProductAverageWeight,
  getProductDisplayType,
  getPostDiscountCost,
  getProductIsForSale
} from '#/selectors/item';

export default class RenderPrice extends PureComponent {
  static propTypes = {
    displayAvailable: PropTypes.bool,
    isAvailableEpwOverride: PropTypes.bool,
    isUnavailableWithSubstitute: PropTypes.bool,
    isUpdating: PropTypes.bool,
    item: PropTypes.object.isRequired,
    selectedUnit: PropTypes.string,
    sellableUnitPrice: PropTypes.number,
    showTotalPrice: PropTypes.bool
  };

  static defaultProps = {
    showTotalPrice: false,
    displayAvailable: false,
    isAmendBasket: false,
    isAvailableEpwOverride: false,
    isUnavailableWithSubstitute: false,
    isUpdating: false
  };

  render() {
    const {
      displayAvailable,
      isAvailableEpwOverride,
      isUpdating,
      item,
      selectedUnit,
      sellableUnitPrice,
      showTotalPrice,
      isUnavailableWithSubstitute
    } = this.props;

    if (
      displayAvailable ||
      getProductIsForSale(item) ||
      isAvailableEpwOverride ||
      isUnavailableWithSubstitute
    ) {
      let price;

      if (showTotalPrice) {
        price = (
          <TotalPrice
            cost={getPostDiscountCost(item)}
            isUpdating={isUpdating}
            key="totalPrice"
          />
        );
      } else {
        price = (
          <PricePerSellableUnit
            averageWeight={getProductAverageWeight(item)}
            displayType={getProductDisplayType(item)}
            price={sellableUnitPrice}
            selectedUnit={selectedUnit}
          />
        );
      }

      return <div className="price-control-wrapper">{price}</div>;
    }

    return null;
  }
}
