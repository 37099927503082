import { Slot as SlotState } from './slot.defs';

const createSlot = (args?): SlotState =>
  Object.assign(
    {
      start: null,
      end: null,
      expiry: null,
      slotId: null,
      charge: 0,
      status: null,
      group: null,
      locationId: null,
      reservationBooked: null,
      reservationExpiry: null,
      countdown: null,
    },
    args,
  );

export default createSlot;
