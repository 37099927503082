import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Link from '../../../link';
import { basicEvent } from '../../../../analytics/types/basic';
import analyticsBus from '../../../../analytics/analyticsBus';

/**
 * Represents a link that sits in the `SimpleHeader`
 *
 * `href` the URL the link should point to.
 * `text` the text that should be in the link
 */
export default class SimpleHeaderLink extends PureComponent {
  static propTypes = {
    href: PropTypes.string.isRequired,
    text: PropTypes.node.isRequired
  };

  sendAnalytics = () => {
    basicEvent(analyticsBus, {
      type: 'logo',
      value: 'home',
      action: 'delay'
    });
  };
  render() {
    return (
      <Link
        href={this.props.href}
        processHref={false}
        disableAnalytics={true}
        onClick={this.sendAnalytics}
      >
        <span className="simple-header-link__text">{this.props.text}</span>
      </Link>
    );
  }
}
