import moment, { Moment } from 'moment-timezone';
import { ORDINALSUFFIXES, ORDINAL, YEAR, MONTH, DAY, LONG, EN_GB } from '#/constants/date-format';

export function getTimezoneAndLocaleAppliedMoment(
  date: string | Moment | null | undefined,
  tz: string,
  lang: string,
): Moment {
  return moment(date)
    .tz(tz)
    .locale(lang);
}

export function millisecondsUntilDate(isoDateStr: string | number | Date): number {
  return new Date(isoDateStr).valueOf() - Date.now();
}

export function timestampToDateStringWithDayOrdinal(date: string, tz: string, lang: string): string {
  return getTimezoneAndLocaleAppliedMoment(date, tz, lang).format('Do MMMM YYYY');
}

export function getFullYear(tz: string): string {
  return moment.tz(tz).format('YYYY');
}

export function getDateOnly(dateTime: string | Moment, dateFormat = 'YYYY-MM-DD'): string {
  return moment(dateTime).format(dateFormat);
}

export function getSlotStartDifference(
  x: {
    slot: { start: string };
  },
  y: {
    slot: { start: string };
  },
): number {
  return moment(y.slot.start).diff(moment(x.slot.start));
}

export const isSlotExpiring = (
  reservationExpiry: Moment | string | null | undefined,
  minutesBeforeExpiry: number,
  timezone: string,
  language: string,
): { isExpiring: boolean; timeLeft: number } => {
  const now = moment()
    .tz(timezone)
    .locale(language);

  const expiryTime = getTimezoneAndLocaleAppliedMoment(reservationExpiry, timezone, language);
  const timeLeft = expiryTime.diff(now, 'minutes');

  return { isExpiring: timeLeft > 0 && timeLeft < minutesBeforeExpiry, timeLeft };
};

function setDayInOrdinalFormat(day: number, locale: string = EN_GB): string {
  const ordinalPluralRules = new Intl.PluralRules(locale, { type: ORDINAL });

  if (typeof day != 'number') throw new TypeError(`Expected Number but received ${typeof day}`);
  if (day < 1) throw new RangeError(`Expected a number > 0 but received ${day}`);

  const ordinal = ordinalPluralRules.select(day);
  const suffixes = ORDINALSUFFIXES[locale] ?? ORDINALSUFFIXES[EN_GB];

  if (!(ordinal in suffixes)) throw new Error(`Unexpected ordinal ${ordinal}`);
  const suffix = suffixes[ordinal];

  return `${day}${suffix}`;
}

export function dateWithDayOrdinalFormat(date: string, tz?: string, lang?: string): string {
  const dateObj = new Date(date);
  const longFormat = new Intl.DateTimeFormat(lang, {
    // @ts-ignore
    dateStyle: LONG,
    timeZone: tz,
  });
  const parts = longFormat.formatToParts(dateObj);

  const getDateParts = (type: string): string => {
    let val;

    if (typeof parts != undefined) {
      val = parts.find(p => p.type === type)?.value;
    }

    return val || '';
  };

  const year = getDateParts(YEAR);
  const month = getDateParts(MONTH);
  const day = Number(getDateParts(DAY));

  return `${setDayInOrdinalFormat(day, lang)} ${month} ${year}`;
}
