import { batchActions } from 'redux-batched-actions';
import commonActionRejectionsHandler from '#/actions/common-action-rejections-handler';
import { updateResource } from '#/actions/resources-action-creators';
import {
  ClearSuggestions,
  SetMultiSearchListLoading,
  SetMultiSearchPageNumber,
  SetSearchTerm,
  SetSuggestionsVisibility,
  SuggestionData,
  SuggestionResult,
  UpdateMultiSearchList,
  UpdateMultiSearchListItem,
  UpdateSearch,
} from '#/actions/search-action-creators.defs';
import {
  CLEAR_MULTI_SEARCH_LIST,
  CLEAR_SEARCH_SUGGESTIONS,
  MULTI_SEARCH_LIST_LOADING,
  SET_MULTI_SEARCH_PAGE_NUMBER,
  SET_SEARCH_TERM,
  SET_SUGGESTIONS_VISIBILITY,
  UPDATE_MULTI_SEARCH_LIST,
  UPDATE_MULTI_SEARCH_LIST_ITEM,
  UPDATE_SEARCH,
  UPDATE_SEARCH_SUGGESTIONS,
} from '#/constants/action-types';
import { MULTI_SEARCH_LIST } from '#/constants/spa-resource';
import { Dispatch, GetStore } from '#/custom-typings/redux-store/common';
import { SearchState } from '#/custom-typings/redux-store/search.defs';
import { PREDICTIVE_NEXT } from '#/experiments/oop-1742/constants';
import { triggerSearchInteractionAnalyticsEvent } from '#/experiments/oop-1742/helpers/analytics';
import { request } from '#/lib/client-fetch';
import { sanitizeList } from '#/lib/multi-search';
import { debounce } from '#/utils/misc';
import { fetchRelatedSearches } from '#/experiments/oop-1826/actions';
import { getTraceParentId } from '#/lib/suggestions-helper';

const DEBOUNCE_WAIT = 100;
const DEFAULT_LEGO_TEAM_NUMBER = '272';

export const setSearchTerm = (searchTerm: string): SetSearchTerm => ({
  type: SET_SEARCH_TERM,
  searchTerm,
});

export const setMultiSearchListLoading = (value: boolean): SetMultiSearchListLoading => ({
  type: MULTI_SEARCH_LIST_LOADING,
  value,
});

const fetchSuggestions = debounce(function(
  url: string,
  searchTerm: string,
  legoTeamNumber: string,
  success: (data: SuggestionData) => void,
  fail: (data: SuggestionData) => void,
  prediction = false,
) {
  const predictNextSuffix = '&config=predictnext';
  const encodedSearchTerm = prediction
    ? encodeURIComponent(searchTerm) + predictNextSuffix
    : encodeURIComponent(searchTerm);

  fetch(`${url}${encodedSearchTerm}`, {
    method: 'GET',
    headers: {
      teamnumber: legoTeamNumber,
      traceparent: getTraceParentId(),
    },
  })
    .then(res => res.json())
    .then(success)
    .catch(fail);
},
DEBOUNCE_WAIT);

const onFetchSuggestionsSuccess = (
  data: SuggestionData,
  dispatch: Dispatch,
  searchTerm: SearchState['searchTerm'] = '',
  searchState: SearchState,
  initiatedAt: Date,
  prediction = false,
): void => {
  if (searchState.suggestionsFrom && searchState.suggestionsFrom < initiatedAt && searchTerm.length > 0) {
    const suggestions = Array.isArray(data.results) ? data.results.map((result: SuggestionResult) => result.query) : [];
    dispatch({
      type: UPDATE_SEARCH_SUGGESTIONS,
      suggestions,
      suggestionsFor: searchTerm,
      isPrediction: !!prediction,
      suggestionsFrom: initiatedAt,
    });
    if (prediction) {
      triggerSearchInteractionAnalyticsEvent(PREDICTIVE_NEXT);
    }
  }
};

export const updateSuggestions = (
  searchTerm: string,
  prediction = false,
  relatedSearch = false,
  legoTeamNumber = DEFAULT_LEGO_TEAM_NUMBER,
) => (dispatch: Dispatch, getState: GetStore): void => {
  const initiatedAt = new Date();
  const {
    search: searchState,
    trolley: { trolleyUserHistory },
  } = getState();
  const lastItemAdded = trolleyUserHistory && trolleyUserHistory[trolleyUserHistory.length - 1];

  if (!searchState.suggestionsUrl) {
    return;
  }

  if (searchTerm) {
    if (searchTerm !== searchState.suggestionsFor && !relatedSearch) {
      fetchSuggestions(
        searchState.suggestionsUrl,
        searchTerm,
        legoTeamNumber,
        (data: SuggestionData) =>
          onFetchSuggestionsSuccess(data, dispatch, getState().search.searchTerm, searchState, initiatedAt),
        () => {
          dispatch(clearSuggestions(initiatedAt));
        },
      );
    } else {
      fetchRelatedSearches(dispatch, searchTerm, searchState.suggestionsUrl);
    }
  } else if (lastItemAdded) {
    if (lastItemAdded !== searchState.suggestionsFor) {
      fetchSuggestions(
        searchState.suggestionsUrl,
        lastItemAdded,
        legoTeamNumber,
        (data: SuggestionData) =>
          onFetchSuggestionsSuccess(data, dispatch, lastItemAdded, searchState, initiatedAt, prediction),
        () => {
          dispatch(clearSuggestions(initiatedAt));
        },
        prediction,
      );
    }
  } else {
    debounce(function(initiatedAt: Date) {
      dispatch(clearSuggestions(initiatedAt));
    }, DEBOUNCE_WAIT)(initiatedAt);
  }
};

export const updateSearch = (data: SuggestionData): UpdateSearch => ({
  type: UPDATE_SEARCH,
  payload: data,
});

export const clearSuggestions = (initiatedAt: Date): ClearSuggestions => ({
  type: CLEAR_SEARCH_SUGGESTIONS,
  suggestionsFrom: initiatedAt,
});

export const setSuggestionsVisibility = (visible: boolean): SetSuggestionsVisibility => ({
  type: SET_SUGGESTIONS_VISIBILITY,
  visibility: visible,
});

export const updateMultiSearchList = (listItems: string[]): UpdateMultiSearchList => {
  return {
    type: UPDATE_MULTI_SEARCH_LIST,
    listItems: listItems,
  };
};

export const updateMultiSearchListItem = (index: number, value: string): UpdateMultiSearchListItem => {
  return {
    type: UPDATE_MULTI_SEARCH_LIST_ITEM,
    index,
    value,
  };
};

export function clearMultiSearchList(url: string) {
  return function(dispatch: Dispatch): Promise<void> {
    dispatch(setMultiSearchListLoading(true));

    return request
      .del(url)
      .then(res => {
        if (!res.success) {
          return Promise.reject();
        }

        dispatch(
          batchActions([
            setMultiSearchListLoading(false),
            {
              type: CLEAR_MULTI_SEARCH_LIST,
            },
          ]),
        );
      })
      .catch(() => {
        dispatch(setMultiSearchListLoading(false));

        return Promise.reject();
      });
  };
}

export function saveMultiSearchList(url?: string, returnUrl?: string) {
  return function(dispatch: Dispatch, getState: GetStore): Promise<void> {
    const listItems = sanitizeList(getState().search.listItems);

    if (listItems.length === 0) {
      return Promise.resolve();
    }

    dispatch(setMultiSearchListLoading(true));

    const requestedAt = Date.now();

    return request
      .post(url, { body: JSON.stringify({ returnUrl, listItems }) })
      .then(res => {
        if (!res.success) {
          return Promise.reject();
        }

        dispatch(
          batchActions([
            setMultiSearchListLoading(false),
            updateMultiSearchList(res.listItems),
            updateResource(MULTI_SEARCH_LIST, { list: res.listItems }, requestedAt),
          ]),
        );
      })
      .catch(res => {
        dispatch(setMultiSearchListLoading(false));
        commonActionRejectionsHandler(res, dispatch);

        return Promise.reject();
      });
  };
}

export const setMultiSearchPageNumber = (page: number): SetMultiSearchPageNumber => ({
  type: SET_MULTI_SEARCH_PAGE_NUMBER,
  page,
});
