import React from 'react';
import PropTypes from 'prop-types';
import { TextButtonLink } from '@ddsweb/link';
import helpers from '#/lib/decorators/helpers';
import analyticsBus from '#/analytics/analyticsBus';
import Link from '#/components/link-check-spa';
import { basicEvent } from '#/analytics/types/basic';

/**
 * Wraps a <a> tag but correctly formats the href according to the props passed
 * in.
 *
 * `href` the URL for the link to point to
 * `processHref` tells the component whether or not to pass the `href` through the link helper
 * `site` is optional and is passed when a change of domains is also needed. For
 * example, a link to `/login` from the home page.
 *
 * To disable this link you can use the classname 'button-disabled', but you must also pass
 * in the prop `disabled`. This will disable the button in IE 10 & under due to `pointer-events: none`
 * not being supported.
 */
@helpers(['l'])
export default class FormattedLink extends React.Component {
  static propTypes = {
    buttonVariant: PropTypes.string,
    children: PropTypes.node,
    ddl: PropTypes.bool,
    disableAnalytics: PropTypes.bool,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    href: PropTypes.string,
    l: PropTypes.func.isRequired,
    onClick: PropTypes.func,
    onKeyPressed: PropTypes.func,
    processHref: PropTypes.bool,
    rel: PropTypes.string,
    site: PropTypes.oneOf(['grocery', 'login'])
  };

  static defaultProps = {
    processHref: true,
    ddl: false
  };

  handleClick = e => {
    if (!this.props.disableAnalytics && this.props.href) {
      basicEvent(analyticsBus, { type: 'basicLink', value: this.props.href });
    }

    if (this.props.onClick) {
      this.props.onClick(e);
    }
  };

  render() {
    const props = Object.assign({}, this.props);

    if (props.ddl) {
      return (
        <TextButtonLink
          {...props}
          onClick={this.handleClick}
          onKeyDown={this.props.onKeyPressed}
        >
          {props.children}
        </TextButtonLink>
      );
    }
    /*
    This condition enables us to convert link-check-spa
    component to link component without making any changes
    to the existing props. This might prove handy in removing
    circular dependencies from our codebase by breaking
    the link-check-spa component's import cycle.
    */
    if (!props.to) {
      props.to = props.processHref
        ? this.props.l(props.href, props.site)
        : props.href;

      if (props.fullWidth) {
        props.style = { ...(props.style || {}) };
        props.style.display = 'inline-block';
        props.style.width = '100%';
      }
    }

    //delete props that are invalid for link-check-spa component
    [
      'chevronName',
      'disableAnalytics',
      'fullWidth',
      'href',
      'l',
      'processHref',
      'site'
    ].forEach(p => {
      delete props[p];
    });

    return (
      <Link
        {...props}
        onClick={this.handleClick}
        onKeyDown={this.props.onKeyPressed}
      >
        {props.children}
      </Link>
    );
  }
}
