import React from 'react';
import PropTypes from 'prop-types';
import UnitToggle from './unit-toggle';
import helpers from '../../../lib/decorators/helpers';

const quantityUnit = 'pcs';
const weightUnit = 'kg';

@helpers(['t'])
class ToggleControl extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleQuantityChange = this.handleQuantityChange.bind(this);
    this.handleWeightChange = this.handleWeightChange.bind(this);
  }

  static propTypes = {
    anchorId: PropTypes.string,
    changeUnits: PropTypes.func.isRequired,
    productTitle: PropTypes.string,
    quantityChecked: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired
  };

  handleQuantityChange() {
    this.props.changeUnits(quantityUnit);
  }

  handleWeightChange() {
    this.props.changeUnits(weightUnit);
  }

  render() {
    const { quantityChecked, productTitle } = this.props;
    return (
      <div className="controls--unit-toggle">
        <fieldset>
          <legend className="visually-hidden">
            {this.props.t('product-tile:select-product-by', {
              productTitle
            })}
          </legend>

          <UnitToggle
            changeUnits={this.handleQuantityChange}
            checked={quantityChecked}
            displayType={'quantity'}
            displayUnit={quantityUnit}
          />

          <UnitToggle
            changeUnits={this.handleWeightChange}
            checked={!quantityChecked}
            displayType={'weight'}
            isLastToggle={true}
            displayUnit={weightUnit}
          />
        </fieldset>
      </div>
    );
  }
}

export default ToggleControl;
