import React from 'react';
import PropTypes from 'prop-types';
import { connect } from '../../../lib/render/connect-deep-compare';
import Modal from '../../shared/modal';
import ModalContent from '../../shared/modal/content';
import ModalCloseXLink from '../modal-close-x-link';
import helpers from '../../../lib/decorators/helpers';
import {
  capitalize,
  capitalizeFirstOnly
} from '../../../lib/string-formatting/format';
import { getBrowserFamily } from '../../../lib/browser/device-characteristics';
import {
  addModalToUrl,
  removeModalFromUrl
} from '../../../lib/url/modal-utils';
import { updateParamsInUrl } from '../../../lib/url/url-utils';
import { UPDATE_ADDRESS } from '../../../constants/query-strings';
import { closeModal } from '../../../actions/ui-action-creators';
import Link from '../../link-check-spa';
import { getTrolleyDeliveryAddressId } from '#/selectors/trolley';
import { ADD, EDIT } from '../../../constants/martini-actions';
import {
  getBrowserType,
  getCurrentUrl,
  getAppRegion,
  getExternalSecureLink
} from '#/reducers/app';

const SCREEN_1 = 'screen1';
const SCREEN_2 = 'screen2';
const SCREEN_3 = 'screen3';
const SCREEN_4 = 'screen4';
const GENERIC = 'generic';

const mapStateToProps = (state, ownProps) => ({
  browserType: getBrowserType(state),
  currentUrl: getCurrentUrl(state),
  externalAddAddressSecureLink: getExternalSecureLink(
    state,
    ownProps.c('externalAddAddressUrl')
  ),
  externalEditAddressSecureLink: getExternalSecureLink(
    state,
    ownProps.c('externalEditAddressUrl')
  ),
  addressId: getTrolleyDeliveryAddressId(state),
  region: getAppRegion(state)
});
@helpers(['c', 't'])
@connect(mapStateToProps, { closeModal })
export default class OneAccountAddressModal extends React.Component {
  static propTypes = {
    addressId: PropTypes.string.isRequired,
    browserType: PropTypes.string.isRequired,
    c: PropTypes.func.isRequired,
    cancelButtonText: PropTypes.string.isRequired,
    closeModal: PropTypes.func.isRequired,
    currentUrl: PropTypes.string.isRequired,
    externalAddAddressSecureLink: PropTypes.string.isRequired,
    externalEditAddressSecureLink: PropTypes.string.isRequired,
    forceTrolleyUpdateOnRefresh: PropTypes.bool.isRequired,
    modalData: PropTypes.string,
    modalName: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    oneAccountAction: PropTypes.string,
    page: PropTypes.string.isRequired,
    region: PropTypes.string,
    t: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      openScreen: SCREEN_1
    };
  }

  switchScreen(screen) {
    return e => {
      e && e.preventDefault();
      this.setState({ openScreen: screen });
    };
  }

  closeModal = () => {
    this.switchScreen()();
    this.props.closeModal();
  };

  getModalScreenUrl = screen =>
    addModalToUrl(this.props.currentUrl, this.props.modalName, screen);

  getRefreshUrl = url => {
    if (this.props.forceTrolleyUpdateOnRefresh) {
      return updateParamsInUrl(url, { [UPDATE_ADDRESS]: true });
    } else {
      return url;
    }
  };

  getEditAddressLink() {
    const { addressId, externalEditAddressSecureLink, region } = this.props;

    return region === 'IE'
      ? `${externalEditAddressSecureLink}?id=GHS_${addressId}`
      : `${externalEditAddressSecureLink}?id=${addressId}`;
  }

  getMartiniLink = () => {
    switch (this.props.oneAccountAction) {
      case EDIT:
        return this.getEditAddressLink();
      case ADD:
        return this.props.externalAddAddressSecureLink;
      default:
        throw new Error(`${this.props.oneAccountAction} is not recognised`);
    }
  };

  getRefreshUrlWithoutModal = () =>
    this.getRefreshUrl(removeModalFromUrl(this.props.currentUrl));

  refreshPage = () => {
    const refreshUrlWithoutModal = removeModalFromUrl(this.props.currentUrl);
    window.location.replace(refreshUrlWithoutModal);
  };

  getBrowserType = () => {
    const browserType = getBrowserFamily(this.props.browserType, this.props.c);

    return (
      this.props.c('addressModalTabIconBrowserTypes')[browserType] || GENERIC
    );
  };

  skip = () => (
    <div className="one-account-address-modal--skip">
      <Link
        onClick={this.switchScreen(SCREEN_3)}
        to={this.getModalScreenUrl(SCREEN_3)}
      >
        {this.props.t('modals:one-account-address-modal.skip')}
      </Link>
      <span className="icon-chevron_right" />
    </div>
  );

  nextButton = screen => (
    <Link
      className="button button-primary one-account-address-button--next-button"
      onClick={this.switchScreen(screen)}
      to={this.getModalScreenUrl(screen)}
    >
      {capitalize(this.props.t('common:next'))}
    </Link>
  );

  previousButton = screen => (
    <Link
      className="button button-secondary one-account-address-button--previous-button"
      onClick={this.switchScreen(screen)}
      to={this.getModalScreenUrl(screen)}
    >
      {capitalize(this.props.t('common:previous'))}
    </Link>
  );

  progressCounter = screenNum => (
    <div className="one-account-address-modal--one-third-column one-account-address-modal--progress-counter">
      {`${screenNum}/3`}
    </div>
  );

  emptyBlock = () => (
    <div className="one-account-address-modal--one-third-column">&nbsp;</div>
  );

  getTabIcon = (browserType, step) =>
    `icon-amend-address-${browserType}-${step}-${this.props.page}`;

  screen = (screenNo, tabIcon, previousScreen, nextScreen) => (
    <ModalContent className={`one-account-address-modal--screen${screenNo}`}>
      <ModalCloseXLink
        onClick={this.closeModal}
        linkUrl={this.getRefreshUrlWithoutModal()}
        className="one-account-address-modal--close-x"
      />
      <div className="tab-info">
        {this.props.t(`slots:delivery.modal.screen${screenNo}`)}
      </div>
      <div className={tabIcon} />
      <div className="one-account-address-modal--bottom">
        <div className="one-account-address-modal--nav">
          {previousScreen
            ? this.previousButton(previousScreen)
            : this.emptyBlock()}
          {this.progressCounter(screenNo)}
          {this.nextButton(nextScreen)}
        </div>
        {this.skip()}
      </div>
    </ModalContent>
  );

  openNewWindow = () => {
    window.open(this.getMartiniLink(), '_blank');
    this.switchScreen(SCREEN_4)();
  };

  screen3 = () => (
    <ModalContent className="one-account-address-modal--screen3">
      <ModalCloseXLink
        onClick={this.closeModal}
        linkUrl={this.getRefreshUrlWithoutModal()}
        className="one-account-address-modal--close-x"
      />
      <div className="one-account-address-modal--text">
        <p>
          {this.props.t('modals:one-account-address-modal.screen3.willOpen')}
        </p>
        <p>
          {this.props.t('modals:one-account-address-modal.screen3.proceed')}
        </p>
      </div>
      <div className="one-account-address-modal--screen3-buttons">
        <button
          onClick={this.openNewWindow}
          className="button button-primary one-account-address-button--primary-button"
        >
          {this.props.t('modals:one-account-address-modal.yes')}
        </button>
        <button
          onClick={this.closeModal}
          className="button button-secondary one-account-address-button--secondary-button"
        >
          {this.props.cancelButtonText}
        </button>
      </div>
      <div className="one-account-address-modal--bottom one-account-address-modal--nav">
        {this.previousButton(SCREEN_2)}
        {this.progressCounter(3)}
        {this.emptyBlock()}
      </div>
    </ModalContent>
  );

  screen3NonJS = () => (
    <ModalContent className="one-account-address-modal--screen3">
      <ModalCloseXLink
        onClick={this.closeModal}
        linkUrl={this.getRefreshUrlWithoutModal()}
        className="one-account-address-modal--close-x"
      />
      <div className="one-account-address-modal--subhead">
        {this.props.t('modals:one-account-address-modal.screen3.nonJS.step1')}
      </div>
      <div className="one-account-address-modal--text">
        {this.props.t('modals:one-account-address-modal.screen3.willOpen')}
      </div>
      <div className="one-account-address-modal--screen3-buttons">
        <Link
          className="button button-primary one-account-address-button--primary-button"
          to={this.getMartiniLink()}
          target="_blank"
          rel="noopener"
        >
          {this.props.t(
            'modals:one-account-address-modal.screen3.nonJS.proceed'
          )}
        </Link>
        <Link
          className="button button-secondary one-account-address-button--secondary-button"
          to={this.getRefreshUrlWithoutModal()}
        >
          {this.props.cancelButtonText}
        </Link>
        <hr className="one-account-address-modal--hr" />
        <div className="one-account-address-modal--subhead">
          {this.props.t('modals:one-account-address-modal.screen3.nonJS.step2')}
        </div>
        <div className="one-account-address-modal--text">
          {this.props.t(
            'modals:one-account-address-modal.screen3.nonJS.updateInfo'
          )}
        </div>
        <Link
          className="button button-primary one-account-address-button--primary-button one-account-address-button--refresh-button"
          to={this.getRefreshUrlWithoutModal()}
        >
          {this.props.t('modals:one-account-address-modal.screen4.button')}
        </Link>
      </div>
      <div className="one-account-address-modal--bottom one-account-address-modal--nav">
        {this.previousButton(SCREEN_2)}
        {this.progressCounter(3)}
        {this.emptyBlock()}
      </div>
    </ModalContent>
  );

  screen4 = () => (
    <ModalContent className="one-account-address-modal--screen4">
      <ModalCloseXLink
        onClick={this.refreshPage}
        linkUrl={this.getRefreshUrlWithoutModal()}
        className="one-account-address-modal--close-x"
      />
      <div>{`${this.props.t('home:welcome-message')}!`}</div>
      <button
        onClick={this.refreshPage}
        className="button button-primary one-account-address-button--primary-button"
      >
        {this.props.t('modals:one-account-address-modal.screen4.button')}
      </button>
      <div>{this.props.t('modals:one-account-address-modal.screen4.text')}</div>
    </ModalContent>
  );

  nbsp = '\xa0';
  modal = (modalContent, toggleFunc, title = this.nbsp) => (
    <Modal
      className="one-account-address-modal"
      title={title}
      closeModal={toggleFunc}
      href={this.getRefreshUrlWithoutModal()}
    >
      {modalContent}
    </Modal>
  );

  getModal = () => {
    const title = `${this.props.t('common:hi')} ${capitalizeFirstOnly(
      this.props.name
    )},`;
    const switchVal = this.props.modalData || this.state.openScreen;
    const browserType = this.getBrowserType();

    switch (switchVal) {
      case SCREEN_1:
        return this.modal(
          this.screen(
            1,
            this.getTabIcon(browserType, 'step1'),
            undefined,
            SCREEN_2
          ),
          this.closeModal,
          title
        );
      case SCREEN_2:
        return this.modal(
          this.screen(
            2,
            this.getTabIcon(browserType, 'step2'),
            SCREEN_1,
            SCREEN_3
          ),
          this.closeModal
        );
      case SCREEN_3: {
        if (!this.props.modalData) {
          return this.modal(this.screen3(), this.closeModal);
        } else {
          return this.modal(this.screen3NonJS());
        }
      }
      case SCREEN_4:
        return this.modal(this.screen4(), this.refreshPage);
      default:
        return this.modal(
          this.screen(
            1,
            this.getTabIcon(browserType, 'step1'),
            undefined,
            SCREEN_2
          ),
          this.closeModal,
          title
        );
    }
  };

  render() {
    return this.getModal();
  }
}
