export const getUniqueIdForStateOrProps = args => {
  const { uniqueId, useCustomerId, atrc } = args;

  if (useCustomerId) {
    return uniqueId;
  } else {
    return atrc;
  }
};

export const setHoldoutAttributes = (additionalAttributes, uniqueId) => {
  additionalAttributes['holdoutPercentage'] = holdoutGroupIntGenerator(
    uniqueId
  );
};

/**
 *
 * @param {String} uniqueId : "1d400072-2459-d07b-b1d1-7e76c34a1fed" or "32123445"
 * @return {Int}       : between 0-99
 */
function holdoutGroupIntGenerator(uniqueId) {
  let sum = 0,
    mul = 1;

  if (!uniqueId?.length) return 0;

  for (let i = 0; i < uniqueId.length; i++) {
    const chr = uniqueId.charCodeAt(i);

    // Fold the string 4 bytes at a time
    mul = i % 4 == 0 ? 1 : mul * 32;
    sum += chr * mul;
  }

  return Math.abs(sum) % 100;
}
