import React from 'react';
import helpers from '#/lib/decorators/helpers';
import { connect } from 'react-redux';
import { closeModal } from '#/actions/ui-action-creators';
import { TTranslateFunc } from '#/lib/records/helpers.defs';
import { GenericSlotsModal } from '#/components/modals/generic-slots-modal/GenericSlotsModal';

type HelpersProps = {
  t: TTranslateFunc;
};
type DispatchProps = {
  closeModal: () => void;
};
export type ReloadPageModalProps = {
  isOpen: boolean;
} & HelpersProps &
  DispatchProps;

const ReloadPageModal = ({ isOpen, t: translate, closeModal }: ReloadPageModalProps): JSX.Element => {
  return (
    <GenericSlotsModal
      modalId="reload-page"
      headingText={translate('modals:reload-page-modal.title')}
      bodyText={translate('modals:reload-page-modal.body')}
      buttons={[
        {
          onClick: (): void => {
            closeModal();
            window.location.reload();
          },
          text: translate('modals:reload-page-modal.reload-button'),
        },
      ]}
      isModalOpen={isOpen}
    />
  );
};

export default helpers(['t'])(connect(null, { closeModal })(ReloadPageModal));
