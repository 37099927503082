import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { connect } from '#/lib/render/connect-deep-compare';
import { isSelectedTabFavorites } from '#/lib/favorites-helpers';
import { getCurrentUrl } from '#/reducers/app';
import { getIsAmendBasket } from '#/selectors/trolley';
import { getDisplayCookieInterrupt } from '#/reducers/ui';
import { getObserver } from '#/lib/browser/intersection-observer';
import { isStickySearchFilterEnabled } from '#/experiments/oop-1662/selectors';
import {
  StickyHeaderWrapper,
  StickyObserver,
} from '#/experiments/oop-1662/components/headers/header/header-wrapper-container/styles/';

type BrandHeaderWrapperStateProps = {
  isFavouritesPage: boolean;
  isStickySearchFilterEnabled: boolean;
  isAmendBarEnabled: boolean;
  isCookieBarEnabled: boolean;
};

const mapStateToProps = (state: Store): BrandHeaderWrapperStateProps => ({
  isFavouritesPage: isSelectedTabFavorites(getCurrentUrl(state)),
  isStickySearchFilterEnabled: isStickySearchFilterEnabled(state),
  isAmendBarEnabled: getIsAmendBasket(state),
  isCookieBarEnabled: getDisplayCookieInterrupt(state),
});

export const HeaderWrapperContainer = (props: React.PropsWithChildren<BrandHeaderWrapperStateProps>): JSX.Element => {
  const { children, isFavouritesPage, isStickySearchFilterEnabled, isAmendBarEnabled, isCookieBarEnabled } = props;
  const [isInStickyMode, setIsInStickyMode] = useState(false);
  const stickyRef = useRef<HTMLDivElement>();

  const intersectionHandler = (entries: IntersectionObserverEntry[]): void => {
    setIsInStickyMode(!entries[0].isIntersecting);
  };

  useEffect(() => {
    let observer: IntersectionObserver | null;
    if (isFavouritesPage) {
      observer = getObserver(intersectionHandler, { rootMargin: '-1px', threshold: 1 });
      if (stickyRef.current && observer) {
        observer.observe(stickyRef.current);
      }
    }

    return () => {
      observer && observer.disconnect();
    };
  }, [isFavouritesPage]);

  if (isFavouritesPage && isStickySearchFilterEnabled) {
    return (
      <StickyHeaderWrapper
        className={classNames('header-wrapper', {
          'sticky-search-enabled': isInStickyMode,
          'with-amend-bar': isAmendBarEnabled,
          'with-cookie-bar': isCookieBarEnabled,
          'with-cookie-and-amend-bar': isAmendBarEnabled && isCookieBarEnabled,
        })}
      >
        <StickyObserver ref={stickyRef} className="header-sticky-observer" />
        {children}
      </StickyHeaderWrapper>
    );
  }

  return <div className="header-wrapper">{children}</div>;
};

export default connect(mapStateToProps)(HeaderWrapperContainer);
