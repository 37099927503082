import React, { useEffect, useRef, useState, useCallback } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { createClassNameFactory, debounce } from '@ddsweb/helpers';
import { BackwardLinkIcon, ForwardLinkIcon } from '@ddsweb/icon';
import { Theme } from '@ddsweb/types';
import {
  Container,
  CarouselContainer,
  ContentContainer,
  CarouselItem,
  CarouselItemsContainer,
  Control,
  Controls,
  ScrollBarMask,
  StyledHeading,
} from '#/experiments/oop-1826/components/search-pills/styled';
import {
  ContentCarouselPropTypes,
  ContentCarouselStateTypes,
  SlideItemMetaTypes,
} from '#/experiments/oop-1826/components/search-pills/types';
import {
  ARROW,
  ACTIVATION_KEY,
  BACKWARD_CONTROL_CLASS_NAME,
  CAROUSEL_CLASS,
  CONTENT_BACKWARD_CONTROL_LABEL,
  CONTENT_FORWARD_CONTROL_LABEL,
  DEBOUNCE_RATE,
  FORWARD_CONTROL_CLASS_NAME,
  KEY_DOWN,
  LEFT,
  RIGHT,
  OBSERVER_THRESHOLD,
} from '#/experiments/oop-1826/components/search-pills/constants';
import { RELATED_SEARCH_HEADING } from '#/experiments/oop-1826/constants';
import {
  animateScrolling,
  buildContainerAriaLabel,
  buildItemAriaLabel,
  convertEntryToSlideItemMeta,
  getContainerWidth,
  getFirstFocusableElement,
  getNextScrollItem,
  getVisibleItemsManually,
  hideScrollbar,
  isControlDisabled,
  isIntersectionObserverSupported,
  isPreviousSiblingVisible,
  sanitizeItemWidthProp,
} from '#/experiments/oop-1826/components/search-pills/helpers';
import { sendAnalyticsData } from '#/experiments/oop-1826/helpers/analytics';

const classNameFactory = createClassNameFactory(CAROUSEL_CLASS);

const ContentCarousel = ({
  ariaLabels,
  className,
  children,
  id,
  itemWidth,
  onControlClick = (): void => {},
  root,
  styles,
}: ContentCarouselPropTypes): JSX.Element => {
  const containerRef = useRef<HTMLDivElement>(null);
  const itemRefs = useRef<HTMLLIElement[]>([]);

  const theme = useTheme() as Theme;
  const itemPadding: number = parseInt(theme?.spacing.sm ?? '0', 10) * 2;
  const childCount: number = React.Children.count(children);

  const [controls, setControls] = useState<ContentCarouselStateTypes>({
    prevControlDisabled: true,
    nextControlDisabled: false,
  });
  const [slideItemsMeta, setSlideItemsMeta] = useState<SlideItemMetaTypes[]>([]);

  useEffect(() => {
    setControls({
      prevControlDisabled: isControlDisabled(LEFT, slideItemsMeta),
      nextControlDisabled: isControlDisabled(RIGHT, slideItemsMeta),
    });
  }, [slideItemsMeta]);

  const handleFocusOnFirstElement = useCallback(
    debounce(() => {
      const firstVisibleItem = itemRefs?.current?.find(item => item.getAttribute('aria-hidden') === 'false');
      getFirstFocusableElement(firstVisibleItem)?.focus();
    }, DEBOUNCE_RATE),
    [],
  );

  const handleOnScroll = useCallback(
    debounce(() => {
      setSlideItemsMeta(
        getVisibleItemsManually({
          containerRef,
          itemRefs,
          itemPadding,
        }),
      );
    }, DEBOUNCE_RATE),
    [],
  );

  const handleBackwardSlide = (
    event: React.KeyboardEvent<HTMLButtonElement> | React.MouseEvent<HTMLButtonElement>,
  ): void => {
    event.preventDefault();

    const targetSlideItem = getNextScrollItem({
      direction: LEFT,
      slideItemsMeta,
      containerRef,
      itemPadding,
    });

    animateScrolling(
      {
        containerRef,
        direction: LEFT,
        startValue: containerRef?.current?.scrollLeft || 0,
        endValue: targetSlideItem.targetOffsetLeft,
      },
      event.type === KEY_DOWN ? handleFocusOnFirstElement : undefined,
    );

    sendAnalyticsData(`${ARROW}:${LEFT}`);
    onControlClick(LEFT, event);
  };

  const handleForwardSlide = (
    event: React.KeyboardEvent<HTMLButtonElement> | React.MouseEvent<HTMLButtonElement>,
  ): void => {
    event.preventDefault();

    const nextItemToScroll = getNextScrollItem({
      direction: RIGHT,
      slideItemsMeta,
      containerRef,
      itemPadding,
    });

    animateScrolling(
      {
        containerRef,
        direction: RIGHT,
        startValue: containerRef?.current?.scrollLeft || 0,
        endValue: nextItemToScroll.targetOffsetLeft,
      },
      event.type === KEY_DOWN ? handleFocusOnFirstElement : undefined,
    );

    sendAnalyticsData(`${ARROW}:${RIGHT}`);
    onControlClick(RIGHT, event);
  };

  const { prevControlDisabled, nextControlDisabled } = controls;

  const handleObserverCallback = useCallback(
    (observedItems: IntersectionObserverEntry[]) => {
      setSlideItemsMeta(prev => convertEntryToSlideItemMeta(prev, observedItems));
    },
    [slideItemsMeta],
  );

  // This block of code responds to tabbing focus and must ignore click focuses.
  // Since there is no consistent way of telling from where the `onFocus` event came
  // we are employing a toggling mechanishm `focusCameFromPointer` that toggles every mouse-up and down.
  const focusCameFromPointer = useRef<boolean>(false);
  const handleBringSlideItemToView = (event: React.FocusEvent<HTMLLIElement>, index: number): void => {
    // Only continue when, the current focused item is considered hidden and that the focus event came from keyboard
    if (!containerRef?.current || focusCameFromPointer.current || slideItemsMeta[index].isVisible) return;
    event.preventDefault();

    const direction = isPreviousSiblingVisible(index, slideItemsMeta) ? RIGHT : LEFT;
    const targetSlideItem = getNextScrollItem({
      direction,
      slideItemsMeta,
      containerRef,
      itemPadding,
    });

    animateScrolling({
      containerRef,
      direction,
      startValue: containerRef.current?.scrollLeft || 0,
      endValue: targetSlideItem.targetOffsetLeft,
    });

    // Reset to false in case there are intances users uses a drag and drop gesture
    focusCameFromPointer.current = false;
  };

  useEffect(() => {
    hideScrollbar(containerRef);

    if (!isIntersectionObserverSupported()) return undefined;

    const observer = new IntersectionObserver(handleObserverCallback, {
      // 0.95 value seem to be the sweet-spot upon tests.
      // the missing 0.05 to form the 1.00 value represents the padding (12px)
      // that we don't want to be considered as part of the item when
      // watching for intersection.
      threshold: OBSERVER_THRESHOLD,

      // We want to anchor our listener to the parent div only and not the entire window
      // Although, it would have been cool to announce it when the user's viewport eventually reaches the carousel
      root: containerRef.current,
    });

    itemRefs.current.forEach(element => observer.observe(element));
    return () => {
      itemRefs.current.forEach(element => observer.unobserve(element));
      observer.disconnect(); // Disconnect the observer
    };
  }, []);

  useEffect(() => {
    if (isIntersectionObserverSupported()) return;

    setSlideItemsMeta(
      getVisibleItemsManually({
        containerRef,
        itemRefs,
        itemPadding,
      }),
    );
  }, []);

  const renderCarouselItems = (): JSX.Element[] => {
    const childArray = React.Children.toArray(children) as React.ReactElement[];

    return childArray.map((child, index) => {
      const isAriaHidden = !slideItemsMeta[index]?.isVisible;

      return (
        <CarouselItem
          as="li"
          key={index}
          data-key={index}
          aria-hidden={isAriaHidden}
          aria-label={buildItemAriaLabel({ ariaLabels, index, slideCount: itemRefs.current.length })}
          className={classNameFactory('item')}
          id={`ddsweb-carousel-item-${index}`}
          itemWidth={sanitizeItemWidthProp(itemWidth, itemPadding)}
          onMouseDown={(): void => {
            focusCameFromPointer.current = true;
          }}
          onFocus={(event: React.FocusEvent<HTMLLIElement>): void => handleBringSlideItemToView(event, index)}
          onMouseUp={(): void => {
            focusCameFromPointer.current = false;
          }}
          ref={(element: HTMLLIElement | null): void => {
            if (element) itemRefs.current[index] = element;
          }}
        >
          {/* tabIndex will be then picked-up by the slide item children, and they must then follow this value */}
          {React.cloneElement(child, { tabIndex: isAriaHidden ? -1 : 0 })}
        </CarouselItem>
      );
    });
  };

  const ControlLeftComponent = (): JSX.Element => (
    <Controls className={classNames(classNameFactory('controls'), 'control-left')}>
      <Control
        aria-controls={id}
        aria-label={ariaLabels?.backwardControl || CONTENT_BACKWARD_CONTROL_LABEL}
        className={classNameFactory(BACKWARD_CONTROL_CLASS_NAME)}
        disabled={prevControlDisabled}
        tabIndex={prevControlDisabled ? -1 : 0}
        aria-hidden={prevControlDisabled}
        variant="secondary"
        icon={<BackwardLinkIcon className="backward-link-icon" />}
        onKeyDown={(event: React.KeyboardEvent<HTMLButtonElement>): boolean | void =>
          ACTIVATION_KEY.includes(event.key) && handleBackwardSlide(event)
        }
        onClick={handleBackwardSlide}
        size="sm"
      />
    </Controls>
  );

  const ControlRightComponent = (): JSX.Element => (
    <Controls className={classNames(classNameFactory('controls'), 'control-right')}>
      <Control
        aria-controls={id}
        aria-label={ariaLabels?.forwardControl || CONTENT_FORWARD_CONTROL_LABEL}
        className={classNameFactory(FORWARD_CONTROL_CLASS_NAME)}
        disabled={nextControlDisabled}
        tabIndex={nextControlDisabled ? -1 : 0}
        aria-hidden={nextControlDisabled}
        variant="secondary"
        icon={<ForwardLinkIcon className="forward-link-icon" />}
        onKeyDown={(event: React.KeyboardEvent<HTMLButtonElement>): boolean | void =>
          ACTIVATION_KEY.includes(event.key) && handleForwardSlide(event)
        }
        onClick={handleForwardSlide}
        size="sm"
      />
    </Controls>
  );

  return (
    <Container
      as="section"
      aria-label={ariaLabels?.carouselDescription}
      className={classNames(className, classNameFactory('container'))}
      root={root}
      styles={styles}
    >
      <StyledHeading headingLevel="4" visualSize="headline5">
        {RELATED_SEARCH_HEADING}
      </StyledHeading>

      <CarouselContainer>
        <ControlLeftComponent />
        <ScrollBarMask className={classNameFactory('scrollbar-mask')}>
          <ContentContainer
            aria-label={buildContainerAriaLabel({ ariaLabels, slideItemsMeta })}
            aria-live={'polite'}
            className={classNameFactory('content-container')}
            id={id}
            ref={containerRef}
            onScroll={!isIntersectionObserverSupported() ? handleOnScroll : undefined}
          >
            <CarouselItemsContainer
              as="ul"
              className={classNameFactory('items-container')}
              containerWidth={getContainerWidth({ itemWidth, childCount, itemPadding })}
            >
              {renderCarouselItems()}
            </CarouselItemsContainer>
          </ContentContainer>
        </ScrollBarMask>
        <ControlRightComponent />
      </CarouselContainer>
    </Container>
  );
};

ContentCarousel.displayName = 'ContentCarousel';

ContentCarousel.propTypes = {
  /**
   * Accessibility labels for carousel to be read by screen readers.
   */
  ariaLabels: PropTypes.shape({
    /**
     * Label for the backward navigation control.
     */
    backwardControl: PropTypes.string,

    /**
     * Label for carousel section, should be use to describe the content
     */
    carouselDescription: PropTypes.string,

    /**
     * Label for the carousel items container. Will replace
     * `%{start}`, `%{end}` and `%{itemsCount}` with their actual
     * values. For example 'Viewing %{start} through ${end} of
     * %{itemsCount} items'.
     */
    container: PropTypes.string,

    /**
     * Label for the forward navigation control.
     */
    forwardControl: PropTypes.string,

    /**
     * Label for each carousel item. Will replace `%{position}`
     * and `%{itemsCount}` with their actual values. For example
     * '%{position} out of %{itemsCount} items.
     */
    item: PropTypes.string,
  }),

  /**
   * The content of the carousel. It should be an array
   * of JSX elements.
   */
  children: PropTypes.node.isRequired,

  /**
   * Any classes to apply to the component root element.
   */
  className: PropTypes.string,

  /**
   * The carousel identifier.
   */
  id: PropTypes.string.isRequired,

  /**
   * The width of each carousel item, this has to be a pixel amount.
   * This can be configured globally and from a breakpoint and up.
   * For example, if you apply it to 'mobileLarge', the value tile
   * will take that minHeight from 'mobileLarge' upward.
   */
  itemWidth: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      aboveDesktop: PropTypes.string,
      aboveDesktopLarge: PropTypes.string,
      aboveMobile: PropTypes.string,
      aboveMobileLarge: PropTypes.string,
      aboveTablet: PropTypes.string,
      aboveTabletLarge: PropTypes.string,
      global: PropTypes.string,
    }),
  ]).isRequired,

  /**
   * The function to be called when the forward or backword controls are clicked.
   *
   * @argument {string} controlDirection - The control direction which is
   * one of "left" or "right".
   * @argument {Object} event - The default event object
   */
  onControlClick: PropTypes.func,

  /**
   * If the component is being used in isolation, set root to true,
   * but if the component is being used within another library
   * component then set it to false.
   */
  root: PropTypes.bool,

  /**
   * Styles to pass down to the Styled Component. Should be
   * the output of using the `css` function.
   */
  styles: PropTypes.arrayOf(PropTypes.any),
};

ContentCarousel.defaultProps = {
  ariaLabels: undefined,
  className: undefined,
  onControlClick: undefined,
  root: false,
  styles: undefined,
};

export default ContentCarousel;
