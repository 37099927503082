import React from 'react';
import { SpinnerWrapper } from '../styled';
import { TTranslateFunc } from '#/lib/records/helpers.defs';
import Spinner from '@ddsweb/spinner';

type TOwnProps = {
  translate: TTranslateFunc;
};

const OverlaySpinner: React.FC<TOwnProps> = ({ translate }) => {
  const spinnerText = translate('orders:orders-ddl.please-wait');
  return (
    <SpinnerWrapper>
      <Spinner accessibleLabel={spinnerText} size="xl" />
    </SpinnerWrapper>
  );
};

export default OverlaySpinner;
