import styled from 'styled-components';
import Link from '#/components/link-check-spa';
import { Text } from '@ddsweb/text';
import { spacing, colors, fontSize } from '@ddsweb/selectors';
import { media } from '@ddsweb/foundation';

export const ImageButtonWrapper = styled.div`
  display: flex;
  align-items: center;

  ${media.belowTabletLarge`
    height: unset;
  `};
`;

export const OuterLinkBox = styled(Link)`
  width: max-content;
  display: flex;
  flex-wrap: wrap;
  padding: ${spacing.xx} ${spacing.xs} ${spacing.xx} ${spacing.xx};
  border: 1px solid ${colors.disabled};
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: flex-start;
  background: ${colors.inverse};

  &:focus,
  &:hover {
    border: 1px solid ${colors.linkActive};
    box-shadow: inset 0px 0px 0px 1px ${colors.linkActive};
    .label-text {
      color: ${colors.linkActive};
      text-decoration: underline;
    }
  }

  &.image-button--active {
    background: ${colors.linkActive};
    border: 1px solid ${colors.linkActive};
    .label-text {
      color: ${colors.inverse};
    }
  }
`;

export const ButtonText = styled(Text)`
  font-size: ${fontSize.xx};
  line-height: ${spacing.md};
  color: ${colors.tescoBlue};
  font-weight: 700;
  margin-left: ${spacing.xs};
`;
