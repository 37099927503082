import { getFeatureVariableStringFromState } from '#/lib/optimizely-manager';
import { basketMultiProductSwaps } from '#/experiments/oop-1934/constants';
import { isTrolleyPageByUrl } from '#/components/products/product-tile/tile-components/product-promotions/product-promotion/hooks';
import { getCurrentUrl } from '#/reducers/app';

const {
  useCustomerId,
  variationKey,
  variants: {
    default: defaultVariant,
    multiSwapsUserInitiated: multiSwapsUserInitiatedVariant,
    multiSwapsDefaultPanel: multiSwapsDefaultPanelVariant,
  },
  featureKey,
} = basketMultiProductSwaps;

const getVariant = (state: Store): string =>
  getFeatureVariableStringFromState(state, featureKey, variationKey, { useCustomerId }) || defaultVariant;

export const isMultiSwapsUserInitiated = (state: Store): boolean => {
  return getVariant(state) === multiSwapsUserInitiatedVariant;
};

export const isMultiSwapsPanelVisibleByDefault = (state: Store): boolean => {
  return getVariant(state) === multiSwapsDefaultPanelVariant;
};

export const isMultiSwapsDisabled = (state: Store): boolean => {
  const currentUrl = getCurrentUrl(state);
  return getVariant(state) === defaultVariant || !isTrolleyPageByUrl(currentUrl);
};

export const isMultiSwapsEnabled = (state: Store): boolean => {
  return getVariant(state) !== defaultVariant;
};
