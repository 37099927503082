import React from 'react';
import PropTypes from 'prop-types';
import Price from '#/components/shared/price';
import Base from '#/components/base';
import { StyledSpinner } from '../styled';

export default class TotalPrice extends Base {
  static displayName = 'Total-Price';
  static propTypes = {
    cost: PropTypes.number.isRequired,
    isUpdating: PropTypes.bool
  };
  static defaultProps = {
    isUpdating: false
  };
  render() {
    const { cost, isUpdating } = this.props;
    const priceProps = Object.assign(
      {
        className: 'price',
        value: cost
      },
      this.c('currency')
    );

    return (
      <div data-auto="total-price" className="total-price">
        <span className="total-heading">{this.t('product-tile:total')}</span>
        {isUpdating ? (
          <StyledSpinner
            accessibleLabel={this.t('orders:orders-ddl.please-wait')}
            size="xx"
          />
        ) : (
          <Price {...priceProps} />
        )}
      </div>
    );
  }
}
