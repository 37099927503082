export const DATA_CENTRE_ORIGIN_HEADER = 'DCO';
export const DATE_FORMAT = 'DD/MM/YYYY';
export const DAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];
export const MID_WEEK_DAYS = ['Tuesday', 'Wednesday', 'Thursday'];
export const HOUR = 60 * 60 * 1000;
export const FORMAT_IN_12_HOUR = 'ha';
export const FORMAT_DAY = 'dddd';
export const FULL_TROLLEY = '/trolley';
export const HAVE_YOU_FORGOTTEN = '/checkout/have-you-forgotten';
export const CHECKOUT_BASE = '/checkout';
export const CHECKOUT_RECOMMENDATIONS = '/checkout/recommendations';
export const ORDER_SUMMARY = '/checkout/order-summary';
export const PAYMENT_OPTIONS = '/checkout/payment-options';
export const ORDER_CONFIRMATION = '/checkout/confirmation';
export const BOUNTY_PAYMENT_MAIN = '/breakout';
export const BOUNTY_PAYMENT_BREAKOUT = '/payment';
export const BOUNTY_PAYMENT_VERIFY = '/verify';
export const BOUNTY_PAYMENT_3DS_COMPLETE = '/3ds/complete';

export const RATINGS_REVIEWS_SUBMISSION_PAGE = '/reviews/submission';
export const RATINGS_REVIEWS_THANKYOU_PAGE = '/reviews/thankyou';

export const UK = 'UK';
export const WEIGHT_UNIT_KG = 'kg';
export const QUANTITY_TYPE = 'pcs';
export const WEIGHT = 'Weight';
export const CMS_PRODUCT_TYPE_OFFERS = 'offers';
export const CMS_PRODUCT_TYPE_WNT = 'favourite interrupts'; // DCS value

export const HAVE_YOU_FORGOTTEN_COOKIE = 'HYF';
export const ONE_CHECKOUT_VALID_REF_COOKIE = 'oc_ref';
export const EVENTS = 'events';
export const DCS = 'DCS';
export const TREX = 'TREX';

export const CHECKOUT_SCENARIO = 'checkout';
export const LOGIN_SCENARIO = 'login';
export const SLOTS_SCENARIO = 'slots';

export const TOP = 'top';
export const BOTTOM = 'bottom';

export const SECONDARY = 'secondary';

export const PREV = 'prev';
export const NEXT = 'next';

export const BOOK_COLLECTION_IMAGE = '/images/book_collection.png';
export const OUT_OF_CATCHMENT_DESKTOP_IMAGE =
  'images/out-of-catchment/blurred-desktop.jpg';
export const OUT_OF_CATCHMENT_TABLET_IMAGE =
  'images/out-of-catchment/blurred-tablet.jpg';
export const OUT_OF_CATCHMENT_MOBILE_IMAGE =
  'images/out-of-catchment/blurred-mobile.jpg';
export const ON_DEMAND_DUMMY_SLOT_ID =
  'REVMSVZFUllfVEhJUkRfUEFSVFlfT05ERU1BTkQjIyMj';

export const SORT_BY = {
  ASC: 'asc',
  DESC: 'desc'
};

export const PRD = 'prd';
export const PROD_TEST = 'prodtest';

export const SHOP = 'shop';
export const GHS_PRODUCT = 'ProductType';
export const IGHS_PRODUCT = 'IGHSProduct';
export const MARKETPLACE_PRODUCT = 'MPProduct';
export const POLITE = 'polite';
export const TIME_FORMAT = 'h:mma';

export const HEAD = 'head';
export const FOOT = 'foot';
export const PRODUCT_DEPOSIT_RETURN_CHARGE = 'ProductDepositReturnCharge';
export const DEPOSIT_RETURN_CHARGE = 'DepositReturnCharge';
export const MARKETPLACE_SEGMENT = 'MARKETPLACE';
export const MARKETPLACE_CHECKOUT_SEGMENT = 'MP-CHECKOUT';
