import React from 'react';
import PropTypes from 'prop-types';
import { connect } from '../../../lib/render/connect-deep-compare';
import Modal from '../../shared/modal';
import ModalContent from '../../shared/modal/content';
import ModalActions from '../../shared/modal/actions';
import ModalButtonContainer from '../../shared/modal/actions/button-container';
import helpers from '../../../lib/decorators/helpers';
import { closeModal } from '../../../actions/ui-action-creators';
import { removeModalFromUrl } from '../../../lib/url/modal-utils';

const authRefreshHandler = (e, closeModal, loginUrl) => {
  e.preventDefault();
  closeModal();
  window.location.assign(removeModalFromUrl(loginUrl()));
};

const AuthRefreshRequiredModal = props => {
  const textLine2 = props.t(
    'common:auth-refresh-required-modal-line2',
    {},
    true
  );

  return (
    <Modal
      dismissable={false}
      title={props.t('common:auth-refresh-required-modal-title')}
      className="refresh-required-modal"
    >
      <ModalContent>
        <p>{props.t('common:auth-refresh-required-modal-line1')}</p>
        {textLine2 ? <p>{textLine2}</p> : null}
        <ModalActions>
          <ModalButtonContainer>
            <button
              onClick={e =>
                authRefreshHandler(e, props.closeModal, props.loginUrl)
              }
              className="button button-primary small"
            >
              {props.t('common:auth-refresh-required-modal-buttontext')}
            </button>
          </ModalButtonContainer>
        </ModalActions>
      </ModalContent>
    </Modal>
  );
};

AuthRefreshRequiredModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  loginUrl: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

export default connect(null, { closeModal })(
  helpers(['loginUrl', 't'])(AuthRefreshRequiredModal)
);
