import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from '#/lib/render/connect-deep-compare';
import helpers from '#/lib/decorators/helpers';
import * as components from '#/components/cms-driven';
import { getPromotionsContentResource } from '#/selectors/resources';
import { emitContentInteractOp } from '#/analytics/bertie/events';
import { DfpPanelWrappper } from '#/components/cms-driven/dfp-panel-wrapper';

const mapStateToProps = state => {
  return {
    promotionsContentResourceData:
      getPromotionsContentResource(state)?.data || {}
  };
};

export const gamBanner = (targeting, pos = 'top') => (
  <div
    style={{ marginBottom: '12px' }}
    className="results-dfp results__dfp-panel"
  >
    <components.DfpContainer pageId="specialoffers" targeting={targeting} />
    <DfpPanelWrappper targeting={{ pos }} enableLazyLoad />
  </div>
);

@helpers(['c'])
@connect(mapStateToProps)
export default class MediaBanners extends Component {
  static propTypes = {
    targeting: PropTypes.object,
    promotionsContentResourceData: PropTypes.shape({
      content: PropTypes.shape({
        top: PropTypes.array,
        bottom: PropTypes.array
      })
    })
  };

  componentClickHandler(componentData) {
    emitContentInteractOp({
      displayArea: 'm-of',
      pageType: 'special-offers',
      panel: [
        {
          campaignId: componentData.title
            ? componentData.title.trim()
            : componentData.catId,
          contentSegment: componentData.segments,
          posInModule: componentData.posInPanel + 1
        }
      ],
      modulePosition: componentData.placement
    });
  }

  render() {
    const {
      promotionsContentResourceData: { content },
      targeting
    } = this.props;
    const dcsProps = {
      shared: {
        componentClickHandler: this.componentClickHandler.bind(this)
      }
    };

    const dcsBannerData = content && content.top ? [content.top[0]] : [];

    const dcsBanners = content && content.top && (
      <components.ComponentTree
        components={components}
        data={dcsBannerData}
        {...dcsProps}
      />
    );

    return (
      <>
        {gamBanner(targeting)}
        {dcsBanners}
      </>
    );
  }
}
