import { createSelector } from 'reselect';
import { getResults, getResultsByTypeFactory } from '#/selectors/results';

export const getResultsPages = createSelector(
  getResults,
  results => results.pages || []
);

export const getResultsPagesByTypeFactory = resultsType =>
  createSelector(
    getResultsByTypeFactory(resultsType),
    results => results.pages || []
  );
