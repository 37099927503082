import styled from 'styled-components';
import { spacing, breakpoints } from '@ddsweb/selectors';

export const StyledDfpBanner = styled.div`
  margin-top: ${spacing.sm};

  @media (min-width: ${breakpoints.tabletLarge}px) {
    margin-top: ${spacing.md};
  }
`;

export const StyledBasketDfpBanner = styled.div`
  margin-bottom: ${spacing.md};
`;
