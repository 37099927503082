import React, { useRef, useMemo, useEffect, useCallback } from 'react';
// @ts-ignore
import Buybox, { QuantityControls } from '@beans/buy-box';
import SafeForm from '#/components/shared/safe-form';
import {
  useQuantityState,
  externalQuantityUpdate,
  quantityFieldUpdate,
  variantUpdate,
  disableControlsOnEmptyInput,
  enableControlOnValidInput,
  updateControlsStatusOnBasketQtyChange,
  enableControlsOnEmptyInputForKgVariant,
} from '#/components/product-tiles/common/buybox-container/quantity-controls-buybox/quantity-state-hook';
import { TBuyboxQuantityControlData } from '#/selectors/beans-product-tile/buybox-data';
import {
  getAdditionalQuantityControlsStyles,
  SafeFormWrapper,
} from '#/components/product-tiles/common/buybox-container/quantity-controls-buybox/styles';
import { WEIGHT, WEIGHT_UNIT_KG } from '#/constants/common';
import { QUANTITY } from '#/constants/display-types';
import { CATCHWEIGHT_QUANTITY_TYPE } from '#/selectors/beans-product-tile/quantity-controls-data';
import {
  TBuyboxFunctionalityProps,
  TcontrolsDisabledStateStatus,
} from '#/components/product-tiles/common/buybox-container/buybox.defs';
import { markInteractionEnd, markInteractionStart } from '#/utils/interaction-perf';
import { CustomerUnitChoice } from '#/lib/records/item.defs';

export type TProps = TBuyboxQuantityControlData &
  TBuyboxFunctionalityProps & {
    buyboxStyles?: string;
    resultType: string;
    onAnonymousUserAdd?: (quantityValue: number, variantValue: CustomerUnitChoice) => void;
  };

type TonQuantityChange = {
  basketValue: number;
  variantValue: CustomerUnitChoice;
  quantityValue: number;
};

type TAriaLabels = {
  add: string;
  remove: string;
  plus: string;
  minus: string;
  quantity: string;
  update: string;
};

export function QuantityControlsBuybox({
  productTitle,
  buyboxStyles,
  buttonText,
  increment,
  itemQuantityInStore,
  itemUnitInStore,
  maxQuantity,
  onCatchWeightChange,
  assistiveDrsText,
  drsText,
  priceSubtext,
  priceText,
  quantityControlsId,
  variantOptions,
  formData,
  initialVariantValue,
  successMessage,
  options,
  disabled,
  quantityType,
  translate,
  addFormParams,
  identifier,
  isProductQtyIncrementable,
  resultType,
  onAnonymousUserAdd,
}: TProps): JSX.Element {
  const isAnonymous = !!formData;
  // Show "Update" button on serverside renders
  const hidePlusMinusButton = !process.env.CLIENT_SIDE;

  const [quantityState, dispatch] = useQuantityState({
    quantityValue: itemQuantityInStore || 1,
    basketValue: itemQuantityInStore,
    variantValue: initialVariantValue || undefined,
    controlsDisabledStatusLocal:
      typeof disabled === 'boolean'
        ? disabled
        : {
            add: disabled.add,
            remove: disabled.remove,
          },
    wasLastQtyValueEmpty: false,
    switchMadeFromEmptyToValue: false,
  });
  const isInitialRender = useRef(true);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
    } else {
      markInteractionEnd(quantityControlsId);
    }
  }, [quantityState.basketValue]);

  const disabledStateSwitchData = useRef<TcontrolsDisabledStateStatus>({
    prevDisableStatusGlobal: disabled,
    useGlobalDisabledState: false,
  });
  const isDisabledBoolean = typeof disabled === 'boolean';
  const { prevDisableStatusGlobal } = disabledStateSwitchData.current;

  const hasDisabledStateChanged = isDisabledBoolean
    ? disabled !== prevDisableStatusGlobal
    : // eslint-disable-next-line
      // @ts-ignore
      disabled?.add !== prevDisableStatusGlobal?.add || disabled?.remove !== prevDisableStatusGlobal?.remove;

  if (hasDisabledStateChanged) {
    disabledStateSwitchData.current.useGlobalDisabledState = true;
    disabledStateSwitchData.current.prevDisableStatusGlobal = disabled;
  }

  if (itemUnitInStore === WEIGHT_UNIT_KG && quantityType != CATCHWEIGHT_QUANTITY_TYPE) {
    if (options?.length && quantityState.basketValue >= 1 && options[quantityState.basketValue - 1]) {
      const displayUnit = options[quantityState.basketValue - 1].text;
      successMessage = `${displayUnit} ${translate('product-tile:in-trolley')}`;
    }
  }

  const maxVariantValue = useMemo(() => {
    if (options?.length) {
      return quantityState.variantValue === WEIGHT_UNIT_KG ? options[options.length - 1].value : options.length;
    }

    return maxQuantity;
  }, [quantityState.variantValue, options, maxQuantity]);

  let shouldDisableControls;
  if (quantityState.basketValue >= 1) {
    shouldDisableControls = typeof quantityState.quantityValue === 'number';
  } else {
    shouldDisableControls = quantityState.quantityValue;
  }

  if (!quantityState.wasLastQtyValueEmpty && !shouldDisableControls) {
    dispatch(
      disableControlsOnEmptyInput({
        controlsDisabledStatusLocal: {
          add: true,
          remove: true,
        },
        wasLastQtyValueEmpty: true,
        switchMadeFromEmptyToValue: false,
      }),
    );
  }

  if (quantityState.wasLastQtyValueEmpty && !quantityState.switchMadeFromEmptyToValue && shouldDisableControls) {
    dispatch(
      enableControlOnValidInput({
        controlsDisabledStatusLocal: {
          add: false,
          remove: false,
        },
        wasLastQtyValueEmpty: false,
        switchMadeFromEmptyToValue: true,
      }),
    );
  }

  const onQuantityChange = (args: TonQuantityChange): void => {
    const { basketValue, variantValue, quantityValue } = args;

    if (typeof basketValue === 'string' && typeof quantityValue === 'string') return;

    if (quantityState.basketValue === 0 && basketValue === 1) {
      markInteractionStart(`${resultType}_buybox_beans_add`, quantityControlsId);
    } else if (quantityState.basketValue === basketValue - 1) {
      markInteractionStart(`${resultType}_buybox_beans_plus`, quantityControlsId);
    } else if (quantityState.basketValue === basketValue + 1) {
      markInteractionStart(`${resultType}_buybox_beans_minus`, quantityControlsId);
    } else if (quantityState.basketValue !== basketValue) {
      markInteractionStart(`${resultType}_buybox_beans_update`, quantityControlsId);
    }

    disabledStateSwitchData.current.useGlobalDisabledState = false;

    if (basketValue !== itemQuantityInStore) {
      if (isAnonymous && onAnonymousUserAdd) return onAnonymousUserAdd(quantityValue, variantValue);
      increment(basketValue - itemQuantityInStore, variantValue);
    } else if (variantValue !== quantityState.variantValue) {
      onCatchWeightChange(variantValue);
    }

    dispatch(
      updateControlsStatusOnBasketQtyChange({
        controlsDisabledStatusLocal:
          typeof quantityState.controlsDisabledStatusLocal !== 'boolean'
            ? {
                add: !isProductQtyIncrementable,
                remove: false,
              }
            : quantityState.controlsDisabledStatusLocal,
        basketValue,
        quantityValue,
        variantValue,
      }),
    );
  };

  const onQuantityFieldChange = (args: Pick<TonQuantityChange, 'quantityValue'>): void => {
    const { quantityValue } = args;

    disabledStateSwitchData.current.useGlobalDisabledState = false;

    dispatch(quantityFieldUpdate({ quantityValue }));
    dispatch(
      enableControlOnValidInput({
        controlsDisabledStatusLocal: {
          add: typeof quantityValue === 'string',
          remove: false,
        },
        wasLastQtyValueEmpty: quantityState.wasLastQtyValueEmpty,
        switchMadeFromEmptyToValue: quantityState.switchMadeFromEmptyToValue,
      }),
    );
  };

  const onVariantChange = (args: Pick<TonQuantityChange, 'variantValue'>): void => {
    const { variantValue } = args;

    disabledStateSwitchData.current.useGlobalDisabledState = false;
    dispatch(variantUpdate({ variantValue }));
    if (variantValue === WEIGHT_UNIT_KG && !quantityState.quantityValue) {
      dispatch(
        enableControlsOnEmptyInputForKgVariant({
          controlsDisabledStatusLocal: {
            add: false,
            remove: false,
          },
          quantityValue: 1,
        }),
      );
    }
  };

  useEffect(() => {
    if (itemQuantityInStore !== quantityState.basketValue) {
      dispatch(
        externalQuantityUpdate({
          basketValue: itemQuantityInStore,
          quantityValue: itemQuantityInStore || 1,
          variantValue: quantityType === CATCHWEIGHT_QUANTITY_TYPE ? quantityState.variantValue : itemUnitInStore,
        }),
      );
    }
  }, [itemQuantityInStore]);

  const action = addFormParams?.action;
  const formParameters = addFormParams?.params;
  const { id, itemForRemoval, removedProductId, anchorId, returnUrl, backToUrl, oldItemQuantity, oldItemUnitChoice } =
    formParameters || {};

  const onSubmitTrolleyForm = useCallback((event): void => event.preventDefault(), []);

  const shouldDisableBothButtons = useMemo(
    (): boolean => (isDisabledBoolean ? disabled : disabled.add && disabled.remove),
    [isDisabledBoolean, disabled],
  );

  const disabledState =
    disabledStateSwitchData.current.useGlobalDisabledState || shouldDisableBothButtons
      ? disabled
      : quantityState.controlsDisabledStatusLocal;

  const disableAdd = isDisabledBoolean ? disabled : disabled.add;

  const additionalQuantityControlsStyles = useMemo(() => {
    return getAdditionalQuantityControlsStyles(disableAdd, buyboxStyles);
  }, [buyboxStyles, disableAdd]);

  const addAriaLabel = translate('product-tile:type-of-product', {
    quantity: quantityState.basketValue ? 1 : quantityState.quantityValue,
    title: productTitle,
  });

  const addTypeOfQuantityAriaLabel = translate('product-tile:type-of-product-quantity', {
    title: productTitle,
    type: quantityState.variantValue === WEIGHT_UNIT_KG ? WEIGHT : QUANTITY,
  });

  const updateAriaLabel = `${translate('common:update')} ${productTitle}`;
  const ariaLabels: TAriaLabels = {
    add: addAriaLabel,
    remove: translate('product-tile:remove-from-trolley_UK', { quantity: 1, title: productTitle }),
    plus: translate('common:increase-by-1'),
    minus: translate('common:decrease-by-1'),
    quantity: itemQuantityInStore > 0 ? updateAriaLabel : addTypeOfQuantityAriaLabel,
    update: updateAriaLabel,
  };

  return (
    <SafeFormWrapper quantityType={quantityType}>
      <SafeForm action={action} method="POST" onSubmit={onSubmitTrolleyForm}>
        <input type="submit" className="hidden" />
        <input name="id" type="hidden" value={id} />
        {identifier && <input name="identifier" type="hidden" value={identifier} />}
        {itemForRemoval && <input name="removeProductId" type="hidden" value={removedProductId} />}

        <input name="anchorId" type="hidden" value={anchorId} />
        <input name="returnUrl" type="hidden" value={returnUrl} />
        <input name="backToUrl" type="hidden" value={backToUrl} />
        <input name="oldValue" type="hidden" value={oldItemQuantity} />
        <input name="oldUnitChoice" type="hidden" value={oldItemUnitChoice} />
        <input name="newUnitChoice" type="hidden" value={oldItemUnitChoice} />
        <Buybox
          assistiveDrsText={assistiveDrsText}
          drsText={drsText}
          action={
            <QuantityControls
              id={quantityControlsId}
              data-auto="quantity-controls-buybox-action"
              ariaLabels={ariaLabels}
              buttonText={buttonText}
              maxValue={maxVariantValue}
              options={options}
              name="newValue"
              hidePlusMinus={hidePlusMinusButton}
            />
          }
          disabled={!process.env.CLIENT_SIDE ? false : disabledState}
          basketValue={quantityState.basketValue}
          data-auto="quantity-controls-buybox"
          onQuantityChange={onQuantityChange}
          onQuantityFieldChange={onQuantityFieldChange}
          onVariantChange={onVariantChange}
          priceSubtext={priceSubtext}
          priceText={priceText}
          quantityValue={quantityState.quantityValue}
          styles={additionalQuantityControlsStyles}
          variantOptions={variantOptions}
          variantValue={quantityState.variantValue}
          successMessage={successMessage}
        />
      </SafeForm>
    </SafeFormWrapper>
  );
}
