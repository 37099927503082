import { getFeatureVariableStringFromState, updateRegionInKey } from '#/lib/optimizely-manager';
import { BrowseEntryPointFeature, BrowseEntryPointVariants } from '#/experiments/oop-1894/constants';

export const getBrowseEntryPointVariant = (state: Store): BrowseEntryPointVariants => {
  const { featureKey, variationId, useCustomerId } = BrowseEntryPointFeature;
  const regionInKey = updateRegionInKey(featureKey, state);
  const featureVariable = getFeatureVariableStringFromState(state, regionInKey, variationId, { useCustomerId });

  return featureVariable || BrowseEntryPointVariants.DISABLED;
};

export const getShouldShowContentStamp = (state: Store): boolean => {
  // @ts-ignore
  const taxonomyState = state.taxonomy.taxonomyState;
  const { superdepartment, department } = taxonomyState;

  return (
    (getBrowseEntryPointVariant(state) === BrowseEntryPointVariants.PLP_PERSIST && !department) ||
    (getBrowseEntryPointVariant(state) === BrowseEntryPointVariants.PLP_NON_PERSIST && !superdepartment) ||
    (getBrowseEntryPointVariant(state) === BrowseEntryPointVariants.ZONE_PERSIST && !department) ||
    (getBrowseEntryPointVariant(state) === BrowseEntryPointVariants.ZONE_NON_PERSIST && !superdepartment)
  );
};
