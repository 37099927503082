import styled from 'styled-components';
import { spacing, colors } from '@ddsweb/selectors';
import Text from '@ddsweb/text';
import { Heading } from '@ddsweb/heading';
import { ErrorIcon } from '@ddsweb/icon';

export const RecommenderErrorWrapper = styled.div`
  height: 472px;
  background-color: ${colors.background};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const ErrorText = styled(Text)`
  padding: 0 ${spacing.md};
`;
export const ErrorIconWrapper = styled(ErrorIcon)`
  background-color: ${colors.error};
  border-radius: 50%;
  color: ${colors.background};
`;

export const ErrorHeader = styled(Heading)`
  color: ${colors.base};
  margin: ${spacing.sm} 0;
`;

export const StyledIBYCRecommenderWrapper = styled.div`
  width: inherit;
  margin-bottom: ${spacing.lg};

  & .ibyc-title {
    font-size: ${spacing.xxl};
    margin: ${spacing.lg} 0;
    color: ${colors.heading};
  }

  & .recommender--carousel.recommender--placeholder .recommender__loading-wrapper {
    top: 50%;
  }

  & .ddsweb-carousel__items-container li {
    margin-left: 6px;
    margin-right: 6px;
  }

  & .ddsweb-carousel__items-container li:first-child {
    margin-left: ${spacing.sm};
  }

  & .ddsweb-carousel__items-container li:last-child {
    margin-right: ${spacing.sm};
  }

  & .ddsweb-button:disabled {
    background-color: ${colors.white};
    border: 2px solid ${colors.link};
  }
`;
