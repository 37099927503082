import React from 'react';
import VerticalTile, { BaseProps } from '#/components/product-tiles/vertical-tile/core';
import { FIXED } from '#/components/product-tiles/vertical-tile/core/constants';
import { connect } from '#/lib/render/connect-deep-compare';
import { getIsMobile } from '#/reducers/app';
import { getIsSubstitute, isWhyNotTry, isSponsoredProduct } from '#/selectors/item';
import { isProductAvailable } from '#/selectors/beans-product-tile/product-availability-data';
import { getRestOfShelfLinkData } from '#/selectors/beans-product-tile/rest-of-shelf-link-data';
import { getWriteAReviewData } from '#/selectors/beans-product-tile/write-a-review-data';

export type StateProps = {
  available: boolean;
  getIsSubstitute?: boolean;
  isMobile: boolean;
  isWhyNotTry?: boolean;
  restOfShelfLink?: ReturnType<typeof getRestOfShelfLinkData>;
  writeAReviewData?: ReturnType<typeof getWriteAReviewData>;
  isSponsoredProduct?: boolean;
};

type OwnProps = BaseProps & {
  showRestOfShelfLink: boolean;
  showWriteAReviewLink: boolean;
};

const mapStateToProps = (
  state: Store,
  { item, feature, translate, config, showRestOfShelfLink = false, showWriteAReviewLink = false }: OwnProps,
): StateProps => {
  return {
    available: isProductAvailable(state, item),
    getIsSubstitute: getIsSubstitute(item),
    isMobile: getIsMobile(state),
    isWhyNotTry: isWhyNotTry(item),
    restOfShelfLink: showRestOfShelfLink ? getRestOfShelfLinkData(state, item, translate, config) : undefined,
    writeAReviewData: showWriteAReviewLink ? getWriteAReviewData(state, item, feature, translate) : undefined,
    isSponsoredProduct: isSponsoredProduct(item),
  };
};

type Props = OwnProps & StateProps;

export const CarouselTile = (props: Props): JSX.Element => {
  const { renderTermText = true, ...otherProps } = props;

  return <VerticalTile {...otherProps} variant={FIXED} renderTermText={renderTermText} />;
};

export default connect(mapStateToProps)(CarouselTile);
