import styled from 'styled-components';
import { media } from '@ddsweb/foundation';
import Modal from '@ddsweb/modal';
import Tooltip from '@ddsweb/popover';

export const StyledTooltip = styled(Tooltip)`
  z-index: 2;
  ${props =>
    props.withOverlay &&
    `
    z-index: 14;
  `}
  
  width: ${({ containerWidth }) =>
    containerWidth ? `${containerWidth}px` : 'auto'};
   white-space: normal;
`;

StyledTooltip.displayName = 'Tooltip';

export const StyledModal = styled(Modal)`
  .ddsweb-modal__mask {
    z-index: 13;
    ${media.belowTablet`
     z-index:2;
   `};
  }
  .ddsweb-modal__modal-container {
    display: none;
  }
`;
