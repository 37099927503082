import styled, { css } from 'styled-components';
import { media } from '@beans/foundation';
import { colors, spacing } from '@beans/selectors';

const getSVGurl = (icon: string): string | undefined => {
  switch (icon) {
    case 'pickupTime':
      return `url("data:image/svg+xml,%3Csvg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.80066 7V4.6H3.00066V9.4H21.8007V4.6H18.0007V7H16.8007V4.6H8.00066V7H6.80066ZM16.8007 3.4H8.00066V1H6.80066V3.4H2.40066H1.80066V4V22V22.6H2.40066H12.4007V21.4H3.00066V10.6H21.8007V13H23.0007V4V3.4H22.4007H18.0007V1H16.8007V3.4ZM18.4007 21.8C20.4993 21.8 22.2007 20.0987 22.2007 18C22.2007 15.9013 20.4993 14.2 18.4007 14.2C16.302 14.2 14.6007 15.9013 14.6007 18C14.6007 20.0987 16.302 21.8 18.4007 21.8ZM18.4007 23C21.1621 23 23.4007 20.7614 23.4007 18C23.4007 15.2386 21.1621 13 18.4007 13C15.6392 13 13.4007 15.2386 13.4007 18C13.4007 20.7614 15.6392 23 18.4007 23ZM17.8007 15V18V18.6H18.4007H20.4007V17.4H19.0007V15H17.8007Z' fill='%2300539F'/%3E%3C/svg%3E%0A")`;
    case 'basket':
      return `url("data:image/svg+xml,%3Csvg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_11_24)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.90875 3.10002H1.40063V1.90002H4.40063H4.89252L4.98898 2.38235L5.49252 4.90002H22.9006H23.6405L23.4877 5.62396L21.5877 14.624L21.4872 15.1H21.0006H6.80063H6.30875L6.21229 14.6177L3.90875 3.10002ZM5.73252 6.10002L7.29252 13.9H20.5141L22.1607 6.10002H5.73252ZM7.50063 19.5C7.50063 18.7274 8.12801 18.1 8.90063 18.1C9.67326 18.1 10.3006 18.7274 10.3006 19.5C10.3006 20.2727 9.67326 20.9 8.90063 20.9C8.12801 20.9 7.50063 20.2727 7.50063 19.5ZM8.90063 16.9C7.46526 16.9 6.30063 18.0647 6.30063 19.5C6.30063 20.9354 7.46526 22.1 8.90063 22.1C10.336 22.1 11.5006 20.9354 11.5006 19.5C11.5006 18.0647 10.336 16.9 8.90063 16.9ZM17.5006 19.5C17.5006 18.7274 18.128 18.1 18.9006 18.1C19.6733 18.1 20.3006 18.7274 20.3006 19.5C20.3006 20.2727 19.6733 20.9 18.9006 20.9C18.128 20.9 17.5006 20.2727 17.5006 19.5ZM18.9006 16.9C17.4653 16.9 16.3006 18.0647 16.3006 19.5C16.3006 20.9354 17.4653 22.1 18.9006 22.1C20.336 22.1 21.5006 20.9354 21.5006 19.5C21.5006 18.0647 20.336 16.9 18.9006 16.9ZM17.3006 8.00002V12H18.5006V8.00002H17.3006ZM13.3006 12V8.00002H14.5006V12H13.3006ZM9.30063 8.00002V12H10.5006V8.00002H9.30063Z' fill='%2300539F'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_11_24'%3E%3Crect width='24' height='24' fill='white' transform='translate(0.400635)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A")`;
    case 'helpingHand':
      return `url("data:image/svg+xml,%3Csvg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.3303 3.50538L12.1788 4.35391L13.0274 3.50538L13.3809 3.15183C14.2791 2.25362 15.7354 2.25362 16.6336 3.15183C17.5318 4.05004 17.5318 5.50632 16.6336 6.40452L12.1788 10.8593L7.72406 6.40452C6.82586 5.50632 6.82586 4.05004 7.72406 3.15183C8.62227 2.25362 10.0785 2.25362 10.9768 3.15183L11.3303 3.50538ZM12.1788 12.5563L13.0274 11.7078L17.4821 7.25305C18.849 5.88621 18.849 3.67014 17.4821 2.3033C16.1153 0.936467 13.8992 0.936467 12.5324 2.3033L12.1788 2.65685L11.8253 2.3033C10.4584 0.936467 8.24237 0.936467 6.87553 2.3033C5.5087 3.67014 5.5087 5.88621 6.87553 7.25305L11.3303 11.7078L12.1788 12.5563ZM2.40066 14.2151H1.80066V14.8151V22.2854V22.8854H2.40066H5.60218H6.20218V22.3656H16.9991H17.2476L17.4233 22.1898L23.1075 16.5057C23.7744 15.8388 23.7744 14.7576 23.1075 14.0908C22.4504 13.4337 21.3886 13.4227 20.718 14.0659L17.9283 16.742C17.8261 15.9191 17.1244 15.2823 16.2739 15.2823H13.2386L10.7132 13.7671L10.5707 13.6816H10.4045H8.27012H8.10393L7.96142 13.7671L6.20218 14.8226V14.8151V14.2151H5.60218H2.40066ZM10.9843 18.6167H10.9381V17.4167H16.2739C16.5319 17.4167 16.7411 17.2075 16.7411 16.9495C16.7411 16.6915 16.5319 16.4823 16.2739 16.4823H13.0724H12.9062L12.7637 16.3968L10.2383 14.8816H8.43631L6.20218 16.222V21.1656H16.7505L22.259 15.6571C22.4572 15.4589 22.4572 15.1375 22.259 14.9393C22.0637 14.744 21.748 14.7407 21.5487 14.9319L17.8808 18.4503L17.7067 18.6173H17.4655L10.9843 18.6173V18.6167ZM3.00066 21.6854V15.4151H5.00218V21.6854H3.00066Z' fill='%2300539F'/%3E%3C/svg%3E%0A")`;
    case 'ccInfo':
      return `url("data:image/svg+xml,%3Csvg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.0103 3.58145L12.6417 1.54944L12.1596 0.450554L7.25048 2.6044L6.97279 2.72624L6.90619 3.02208L5.96429 7.20629L2.85939 5.47589L2.72321 5.4H2.5673H1.40063V6.6H2.4114L9.43617 10.515L12.2173 16.63V19.4H8.00063V19C8.00063 15.3549 5.04571 12.4 1.40063 12.4V13.6C4.38297 13.6 6.80063 16.0177 6.80063 19V20V20.6H7.40063H12.8173H13.4173V20V16.5V16.37L13.3635 16.2516L10.4468 9.83855L10.3651 9.65892L10.1927 9.56285L7.05721 7.81539L8.0103 3.58145ZM19.4006 7.6H17.4006V6.4H19.4006V7.6ZM1.40063 15.6C3.2784 15.6 4.80063 17.1222 4.80063 19C4.80063 20.8778 3.2784 22.4 1.40063 22.4V23.6C3.94114 23.6 6.00063 21.5405 6.00063 19C6.00063 16.4595 3.94114 14.4 1.40063 14.4V15.6ZM15.6006 5.2H21.2006V10.8H15.6006V5.2ZM14.4006 4H15.6006H21.2006H22.4006V5.2V10.8V12H21.2006H15.6006H14.4006V10.8V5.2V4Z' fill='%2300539F'/%3E%3C/svg%3E%0A")`;
  }
};

const collectionStepIcon = css`
  background-repeat: no-repeat;
  background-position: 0 0;
  content: '';
  height: ${spacing.xl};
  width: ${spacing.xl};
  background-size: ${spacing.xl} ${spacing.xl};
`;

export const FulfilmentStepsContainer = styled.div`
  border: 1px solid #b4b4b4;
  border-radius: 6px;
  padding: ${spacing.md};

  ${media.aboveTabletLarge`
    padding:${spacing.xxxl} ${spacing.xxl};
  `}
`;

export const IconContainer = styled.div`
  align-items: center;
  background-color: ${colors.backgroundDarkest};
  display: flex;
  justify-content: center;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  margin-right: ${spacing.sm};

  ${media.aboveTabletLarge`
    margin: 0 0 ${spacing.lg};
    width: 56px;
    height: 56px;
  `};
`;

export const IconStamp = styled.section`
  display: flex;
  margin-bottom: ${spacing.sm};
  align-items: start;

  ${media.aboveTabletLarge`
    align-items: center;
    flex-direction: column;
    margin: 0 ${spacing.md};
    text-align: center;
    width: 25%;
  `};

  p {
    width: 100%;
  }
`;

export const StepsContainer = styled.div`
  ${media.aboveTabletLarge`
    display: flex;
  `};
`;

export const StyledStepIcon = styled('svg')`
${collectionStepIcon}
  background-image: ${(props: { icon: string }): string | undefined => getSVGurl(props.icon)};
`;
