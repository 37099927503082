import React from 'react';

export const VeganIcon = (): JSX.Element => (
  <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.8572 0.0119843C13.6668 -0.0234709 11.1657 -0.0782652 8.99553 1.43986C8.6583 1.67516 8.36479 1.94913 8.10562 2.24244L7.78713 2.63567C7.55919 2.94832 7.36248 3.27386 7.20011 3.59941L7.13453 3.73478C6.93157 4.15702 6.77857 4.56959 6.65992 4.94026L6.61933 5.07886C6.47882 5.54944 6.40075 5.93945 6.36328 6.16507L6.51628 6.23598C7.67785 1.81053 11.4248 0.650178 13.3826 0.59216C13.4919 1.56234 13.3545 2.42939 13.0704 3.18361L13.011 3.33833C12.2897 5.09497 10.7878 6.21665 9.89481 6.60021L9.48264 6.74525C8.48657 7.04501 7.82148 6.98377 7.82148 6.98377C7.83397 6.85484 8.30234 4.84679 10.0197 3.37701C10.1977 3.22552 10.9252 2.5841 12.2429 2.11351L12.2304 2.02648C10.1883 2.51641 9.01739 3.88305 8.93932 3.9733C7.7965 5.16911 7.37184 7.09658 7.33125 7.35444C7.57168 7.39634 9.37959 7.52849 10.9939 6.55508C11.0033 6.54864 12.0899 5.84275 12.6769 5.07241C12.7925 4.92092 12.8612 4.82745 12.908 4.77265C14.4599 2.54864 13.9197 0.231162 13.8635 0.0184307L13.8572 0.0119843Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 2.46155H1.94931L6.38666 11.8788L8.23719 7.94846L8.64242 8.36702C8.65204 8.36588 8.66148 8.36477 8.6707 8.36367C8.7799 8.35063 8.87065 8.33837 8.94834 8.32284L10.1818 8.07635L6.38211 16L0 2.46155Z"
      fill="white"
    />
  </svg>
);

export const VegetarianIcon = (): JSX.Element => (
  <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0.230598H1.91473L6.27489 9.64608L10.656 0.230598H12.5717L6.2724 13.7694L0 0.230598Z"
      fill="white"
    />
  </svg>
);

export const VegetarianLogoIcon = (): JSX.Element => (
  <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.187875 0.799999H2.45988L5.00388 8.8L7.54788 0.799999H9.81988L6.12388 12H3.88388L0.187875 0.799999Z"
      fill="white"
    />
  </svg>
);

export const VeganLogoIcon = (): JSX.Element => (
  <svg width="19" height="13" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.469125 0.799999H2.74113L5.28513 8.8L7.82913 0.799999H10.1011L6.40513 12H4.16513L0.469125 0.799999ZM18.0634 8.512H11.9674C12.074 9.12 12.3247 9.584 12.7194 9.904C13.114 10.2133 13.594 10.368 14.1594 10.368C14.6287 10.368 15.018 10.272 15.3274 10.08C15.6474 9.87733 15.9514 9.57333 16.2394 9.168L17.9194 10.08C17.514 10.7413 16.9967 11.248 16.3674 11.6C15.7487 11.952 14.9754 12.128 14.0474 12.128C13.1727 12.128 12.4207 11.9253 11.7914 11.52C11.1727 11.104 10.7087 10.5707 10.3994 9.92C10.09 9.26933 9.93538 8.58667 9.93538 7.872C9.93538 7.15733 10.0954 6.47467 10.4154 5.824C10.746 5.17333 11.2207 4.64533 11.8394 4.24C12.458 3.824 13.1887 3.616 14.0314 3.616C14.8847 3.616 15.6154 3.808 16.2234 4.192C16.8314 4.576 17.29 5.08267 17.5994 5.712C17.9087 6.33067 18.0634 6.992 18.0634 7.696V8.512ZM16.0314 6.944C15.9567 6.47467 15.738 6.09067 15.3754 5.792C15.0234 5.49333 14.5754 5.344 14.0314 5.344C13.5727 5.344 13.162 5.488 12.7994 5.776C12.4367 6.05333 12.186 6.44267 12.0474 6.944H16.0314Z"
      fill="white"
    />
  </svg>
);
