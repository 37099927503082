import { FULFILMENT_OPTIONS_DETAIL } from '#/constants/spa-resource';
import { getFulfilmentOptionsDetailResource } from '#/selectors/resources';
import { RECEIVE_FULFILMENT_OPTIONS_DETAIL } from '#/constants/action-types';

export const type = FULFILMENT_OPTIONS_DETAIL;

export const handler = (dispatch, getState) => {
  const { data } = getFulfilmentOptionsDetailResource(getState());

  if (!data) throw new Error('No data available in fulfilment resource response');

  dispatch({
    type: RECEIVE_FULFILMENT_OPTIONS_DETAIL,
    value: data,
  });
};
