import {
  FAVORITES,
  PRODUCTS_BY_CATEGORY,
  SEARCH,
  PROMOTIONS_ID_OR_TYPE,
  PROMOTIONS_ALL,
} from '#/constants/spa-resource';
import {
  UPDATE_CANCEL_URL,
  UPDATE_CURRENT_URL,
  UPDATING_START,
  UPDATING_STOP,
  UPDATE_FILTERS,
  UPDATE_FACETS,
  UPDATE_FROM_NEW_RESULTS,
} from '#/constants/action-types';
import { getResourceData } from '#/resources/resource-util';
import { getCurrentUrl } from './app';
import { createSelector } from 'reselect';
import url from 'url';
import { FilterState } from '#/custom-typings/redux-store/filter.defs';
import { Actions } from '#/actions/filter-action-creators.defs';
import { Facet, FacetList } from '#/lib/requests/graphql-queries/fragments/facet-lists.defs';
import { ServerProps } from '#/reducers/types.defs';
import { FILTER_OPTIONS_PRODUCT_SOURCE } from '#/constants/filter-options';

function getSortOptionsFromResults(results?: { options?: { sortBy: Array<string> } }): Array<string> | undefined {
  return results && results.options && results.options.sortBy;
}

export const getDefaultStateFromProps = (props: ServerProps): FilterState => {
  props.app = props.app || {};
  const favoritesResource = getResourceData(props.resources, FAVORITES) || {};
  const resourceProductData = getResourceData(props.resources, PRODUCTS_BY_CATEGORY) || {};
  const searchData = getResourceData(props.resources, SEARCH) || {};
  const promoData =
    getResourceData(props.resources, PROMOTIONS_ID_OR_TYPE) || getResourceData(props.resources, PROMOTIONS_ALL) || {};
  const results =
    props.results ||
    favoritesResource.results ||
    resourceProductData.results ||
    searchData.results ||
    promoData.results;
  const sortOptionsFromResults = getSortOptionsFromResults(results);
  const defaultSortOptions = props.helpers.c('defaultSortOptions') as Array<string>;
  const sortOptions = sortOptionsFromResults || defaultSortOptions;

  return {
    currentUrl: props.app.currentUrl || '',
    currentFilters:
      props.currentFilters ||
      resourceProductData.currentFilters ||
      searchData.currentFilters ||
      promoData.results ||
      {},
    cancelUrl: props.cancelUrl || `${props.app.currentUrl}#`,
    facets: (results && results.facetLists) || [],
    popFilters: (results && results.popFilters) || [],
    filters: [
      'superdepartment',
      'department',
      'area',
      'aisle',
      'shelf',
      'brand',
      'promotion',
      'sortBy',
      'new',
      'favourites',
      'dietary',
      'available',
      'productSource',
    ],
    isUpdating: props.isUpdating || false,
    sortOptions,
  };
};

export default function filter(state: FilterState = {} as FilterState, actions: Actions = {} as Actions): FilterState {
  switch (actions.type) {
    case UPDATE_CANCEL_URL:
      return { ...state, cancelUrl: actions.payload };
    case UPDATE_CURRENT_URL:
      return { ...state, currentUrl: actions.payload };
    case UPDATING_START:
      return { ...state, isUpdating: true };
    case UPDATING_STOP:
      return { ...state, isUpdating: false };
    case UPDATE_FILTERS:
      return { ...state, currentFilters: actions.payload };
    case UPDATE_FACETS:
      return { ...state, facets: actions.payload };
    case UPDATE_FROM_NEW_RESULTS:
      const { results = {}, currentFilters = {} } = actions.payload;
      const sortOptions = getSortOptionsFromResults(results) || state.sortOptions;

      return {
        ...state,
        currentUrl: state.currentUrl,
        currentFilters: currentFilters || {},
        facets: results.facetLists || [],
        popFilters: results.popFilters || [],
        isUpdating: false,
        sortOptions,
      };
    default:
      return state;
  }
}

export const getFacets = ({ filter: { facets } }: Store): FacetList => facets;
export const getFilters = ({ filter: { filters } }: Store): Array<string> => filters;
export const getPopFilters = ({ filter: { popFilters } }: Store): Array<string> => popFilters;
export const getIsUpdating = ({ filter: { isUpdating } }: Store): boolean => isUpdating;
export const getSortOptions = ({ filter: { sortOptions } }: Store): Array<string> => sortOptions;
export const getCancelUrl = ({ filter: { cancelUrl } }: Store): string => cancelUrl;

export const getCurrentFilters = createSelector(
  [getCurrentUrl, getFilters],
  (currentUrl: string, filterTypes: Array<string>) => {
    const qs = url.parse(currentUrl, true).query;
    const filters: Record<string, string | string[]> = {};

    Object.keys(qs)
      .filter(key => filterTypes.indexOf(key) !== -1)
      .forEach(filterType => {
        filters[filterType] = qs[filterType];
      });

    return filters;
  },
);

const getCurrentFacetsHelper = (
  currentUrl: string,
  filterTypes: Array<string>,
  facets: FacetList,
  facetsAllowingMultiple: Array<string>,
  delimiter: string,
): Record<string, Array<Facet>> => {
  const qs = url.parse(currentUrl, true).query;

  return Object.keys(qs)
    .filter(key => filterTypes.indexOf(key) !== -1)
    .reduce<Record<string, Array<Facet>>>((currentFilterDetails, currentFilter) => {
      const matchingFilters = facets.find(
        facet =>
          currentFilter === facet.category.toLowerCase() ||
          (currentFilter === FILTER_OPTIONS_PRODUCT_SOURCE && currentFilter === facet.categoryId),
      );
      const matchingFacets = (currentFilterDetails[currentFilter] = [] as Array<Facet>);

      if (matchingFilters) {
        const filterIdsCombined: string = qs[currentFilter] as string;
        const filterIds = facetsAllowingMultiple.includes(currentFilter)
          ? filterIdsCombined.split(delimiter)
          : [filterIdsCombined];

        matchingFacets.push(...matchingFilters.facets.filter(facet => filterIds.includes(facet.facetId.toString())));
      }

      return currentFilterDetails;
    }, {});
};

export function getCurrentFacets(
  state: Store,
  facetsAllowingMultiple: Array<string>,
  delimiter: string,
): Record<string, Array<Facet>> {
  return getCurrentFacetsHelper(
    getCurrentUrl(state),
    getFilters(state),
    getFacets(state),
    facetsAllowingMultiple,
    delimiter,
  );
}
