import React from 'react';
import PropTypes from 'prop-types';
import { connect } from '../../../lib/render/connect-deep-compare';
import Link from '../../link';
import helpers from '../../../lib/decorators/helpers';
import { getCurrentUrl, getLanguageLink } from '#/reducers/app';
import Modal from '../../shared/modal';
import ModalActions from '../../shared/modal/actions';
import ModalButtonContainer from '../../shared/modal/actions/button-container';
import ModalContent from '../../shared/modal/content';
import { PENDING_ORDER_CANCEL_MODAL } from '../../../constants/modal-names';
import { removeModalFromUrl } from '../../../lib/url/modal-utils';
import SafeForm from '../../shared/safe-form';
import SmartSubmitButton from '../../shared/smart-submit-button';
import { closeModal } from '../../../actions/ui-action-creators';
import analyticsBus from '../../../analytics/analyticsBus';
import { basicEvent } from '../../../analytics/types/basic';

const mapStateToProps = (state, { modalData }) => ({
  currentUrl: getCurrentUrl(state),
  cancelOrderLink: getLanguageLink(
    state,
    `/orders/${modalData.orderNo}?_method=DELETE`
  )
});

@connect(mapStateToProps, { closeModal })
@helpers(['t', 'l'])
class CancelPendingOrderModal extends React.Component {
  static propTypes = {
    cancelOrderLink: PropTypes.string.isRequired,
    closeModal: PropTypes.func.isRequired,
    currentUrl: PropTypes.string.isRequired,
    l: PropTypes.func.isRequired,
    modalData: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
  };

  constructor() {
    super();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      submitting: false
    };
  }

  handleSubmit() {
    const { modalData } = this.props;
    basicEvent(analyticsBus, {
      orderNumber: modalData.orderNo,
      type: 'action',
      value: 'cancelOrder',
      action: 'now'
    });
    this.setState({
      submitting: true
    });
  }

  closeModal = () => {
    const { modalData, closeModal } = this.props;
    return closeModal(PENDING_ORDER_CANCEL_MODAL, modalData);
  };

  render() {
    const {
      t: translate,
      currentUrl,
      modalData,
      closeModal,
      cancelOrderLink
    } = this.props;

    return (
      <Modal
        closeModal={this.closeModal}
        title={translate('orders:my-orders.cancel-order-modal.title')}
      >
        <ModalContent>
          <p>{translate('orders:my-orders.cancel-order-modal.content')}</p>
          <ModalActions dual>
            <ModalButtonContainer>
              <Link
                className="button button-secondary small"
                href={removeModalFromUrl(currentUrl, modalData)}
                onClick={e => {
                  e.preventDefault();
                  closeModal(PENDING_ORDER_CANCEL_MODAL, modalData);
                }}
              >
                {translate('common:no')}
              </Link>
            </ModalButtonContainer>
            <ModalButtonContainer>
              <SafeForm
                className={'button pending-order--modal-action-cancel'}
                action={cancelOrderLink}
                method={'post'}
                onSubmit={this.handleSubmit}
              >
                <SmartSubmitButton
                  buttonStyles="button button-primary small"
                  primaryText={translate(
                    'orders:my-orders.cancel-order-modal.yes-cancel'
                  )}
                  submitting={this.state.submitting}
                  name="submit"
                  type="submit"
                />
              </SafeForm>
            </ModalButtonContainer>
          </ModalActions>
        </ModalContent>
      </Modal>
    );
  }
}

export default CancelPendingOrderModal;
