import React, { SyntheticEvent, useState, KeyboardEvent } from 'react';
import { compose } from 'react-recompose';
// ts-ignore
import ActionsMenu from '@beans/colleague-actions-menu';
import { ButtonProps } from '@beans/button';
import { connect } from '#/lib/render/connect-deep-compare';
import { StyledDiv, StyledIcon, StyledButton } from '#/experiments/oop-1693/components/styled';
import { TTranslateFunc } from '#/lib/records/helpers.defs';
import Link from '#/components/link-check-spa';
import { PlainObject } from '#/types';
import AddToMyListModal from '#/experiments/oop-1693/components/add-to-my-list-modal';
import { getFavoritesLanguageLink } from '#/utils/favorite-utils';
import { removeFromFavorites } from '#/experiments/oop-1693/actions/results-actions';
import analyticsBus from '#/analytics/analyticsBus';
import { basicEvent } from '#/analytics/types/basic';
import { NOW, FAVORITES, DELAY } from '#/analytics/constants';
import {
  OPEN,
  HEART,
  EVENT_TYPE,
  CLOSE,
  ADD_TO_LIST_MODAL,
  ARROW_UP,
  ARROW_DOWN,
  promoteFavoritesOnPLPVariants,
} from '#/experiments/oop-1693/constants';
import { AnalyticsData } from '#/experiments/oop-1693/components/type.defs';
import { getPromoteFavoritesVariant } from '#/experiments/oop-1693/selectors';

type DispatchProps = {
  removeFromFavorites: typeof removeFromFavorites;
};
type TOwnProps = { translate: TTranslateFunc; productId: string; isInFavourites: boolean };
type TOwnState = {
  favoritesUrl: string;
  variant: promoteFavoritesOnPLPVariants;
};
type MenuItem = {
  id: string;
  href: string;
  text: string;
  as?: (props: PlainObject) => JSX.Element;
};
type TProps = TOwnProps & TOwnState & DispatchProps;

const mapStateToProps = (state: Store): TOwnState => ({
  favoritesUrl: getFavoritesLanguageLink(state, '/favorites'),
  variant: getPromoteFavoritesVariant(state),
});

const mapDispatchToProps: DispatchProps = {
  removeFromFavorites,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

ActionsMenu.Button = (props: ButtonProps): JSX.Element => {
  const { children, ...restProps } = props;

  return (
    <StyledButton {...restProps} variant="link">
      {children}
    </StyledButton>
  );
};

const updateIcon = (isIconFilled: boolean, iconNames: Array<string> = []): void => {
  const icons: Array<JSX.Element> = iconNames.map(iconName => (
    <StyledIcon key={iconName} isIconFilled={isIconFilled} graphic={iconName} className={'icon-' + iconName} />
  ));

  ActionsMenu.Icon = (): JSX.Element | Array<JSX.Element> => icons;
};

const SpaLink = (props: PlainObject): JSX.Element => {
  const { href, children, className, id, ...restProps } = props;
  return (
    <Link to={href} className={className} id={id} {...restProps}>
      {children}
    </Link>
  );
};

enum MenuItemIds {
  removeFavorites = 'remove-favorites',
  goToFavorites = 'go-to-favorites',
  addToMyList = 'add-to-my-list',
}

const getTranslationKey = (variant: promoteFavoritesOnPLPVariants, prefix: string): string => {
  const { heartIconPlusFaves, heartListIconPlusFaves } = promoteFavoritesOnPLPVariants;
  if (variant === heartIconPlusFaves || variant === heartListIconPlusFaves) {
    return `product-tile:promote-favorites-plp.${prefix}-favorites`;
  }

  return `product-tile:promote-favorites-plp.${prefix}-previously-bought`;
};

const getIcons = (variant: promoteFavoritesOnPLPVariants): Array<string> => {
  const { heartListIconPlusFaves, heartListIconPlusPreviouslyBought } = promoteFavoritesOnPLPVariants;
  const iconList = ['favourites'];
  if (variant === heartListIconPlusFaves || variant === heartListIconPlusPreviouslyBought) {
    iconList.unshift('listView');
  }

  return iconList;
};

export const getMenuItems = (
  translate: TTranslateFunc,
  favoritesUrl: string,
  isInFavourites: boolean,
  variant: promoteFavoritesOnPLPVariants,
): Array<MenuItem> => {
  let items = [
    {
      href: favoritesUrl,
      id: MenuItemIds.goToFavorites,
      text: translate(getTranslationKey(variant, 'go-to')),
      as: SpaLink,
    },
    {
      href: '/',
      id: MenuItemIds.addToMyList,
      text: translate('product-tile:promote-favorites-plp.add-to-my-list'),
    },
  ];

  if (isInFavourites) {
    items = [
      {
        href: '/',
        id: MenuItemIds.removeFavorites,
        text: translate(getTranslationKey(variant, 'remove')),
      },
      ...items,
    ];
  }
  return items;
};

const onKeyDownHandler = (event: KeyboardEvent): void => {
  const key = event.key;
  if (key === ARROW_UP || key === ARROW_DOWN) {
    event.stopPropagation();
    event.preventDefault();
  }
};

const FavoriteHeartIconWithDropDown = ({
  translate,
  favoritesUrl,
  removeFromFavorites,
  productId,
  isInFavourites,
  variant,
}: TProps): JSX.Element | null => {
  const [open, setOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  updateIcon(isInFavourites, getIcons(variant));

  const sendAnalytics = (analyticsData: AnalyticsData): void => {
    basicEvent(analyticsBus, analyticsData);
  };

  const onMenuItemClick = (menuItem: MenuItem, event: SyntheticEvent): void => {
    const { id } = menuItem;
    if (id !== MenuItemIds.goToFavorites) {
      event.preventDefault();
    }

    switch (id) {
      case MenuItemIds.addToMyList:
        setIsModalOpen(true);
        sendAnalytics({
          action: NOW,
          type: EVENT_TYPE,
          value: translate('product-tile:promote-favorites-plp.add-to-my-list').toLowerCase(),
        });
        break;
      case MenuItemIds.removeFavorites:
        removeFromFavorites(productId);
        sendAnalytics({
          action: NOW,
          type: EVENT_TYPE,
          value: translate(getTranslationKey(variant, 'remove')).toLowerCase(),
        });
        break;
      case MenuItemIds.goToFavorites:
        sendAnalytics({
          action: DELAY,
          type: EVENT_TYPE,
          value: translate(getTranslationKey(variant, 'go-to')).toLowerCase(),
        });
        break;
    }
  };

  const onChange = ({ action }: { action: string }): void => {
    if (action === OPEN) {
      setOpen(true);
      sendAnalytics({
        action: NOW,
        type: FAVORITES,
        value: HEART,
      });
    } else {
      setOpen(false);
      sendAnalytics({
        action: NOW,
        type: EVENT_TYPE,
        value: CLOSE,
      });
    }
  };

  const closeModalHandler = (selectedOption: string, shouldFireAnalytics: boolean): void => {
    setIsModalOpen(false);
    if (shouldFireAnalytics) {
      const value = !!selectedOption ? `${selectedOption}:${CLOSE}` : CLOSE;
      sendAnalytics({
        action: NOW,
        type: ADD_TO_LIST_MODAL,
        value,
      });
    }
  };

  return (
    <>
      <StyledDiv
        onKeyDown={onKeyDownHandler}
        {...(isInFavourites && { title: translate('common:favorite-heart-icon.filled') })}
      >
        <ActionsMenu
          open={open}
          id={`fav-icon-action-menu-${productId}`}
          className="fav-icon-action-menu"
          menuItems={getMenuItems(translate, favoritesUrl, isInFavourites, variant)}
          onChange={onChange}
          onMenuItemClick={onMenuItemClick}
        />
      </StyledDiv>
      {isModalOpen && <AddToMyListModal translate={translate} closeModal={closeModalHandler} />}
    </>
  );
};

const enhance = compose<TProps, TOwnProps>(connector);
export default enhance(FavoriteHeartIconWithDropDown);
