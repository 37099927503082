import React from 'react';
import Notification from '@ddsweb/notification';
import { Text } from '@ddsweb/text';
import { InlineLink } from '@ddsweb/link';
import { connect } from '#/lib/render/connect-deep-compare';
import { getIsMobile } from '#/reducers/app';
import { HP_NOTIFICATION_EVT_VALUE, NOW, NOTIFICATION_BANNER } from '#/analytics/constants';
import analyticsBus from '#/analytics/analyticsBus';
import { basicEvent } from '#/analytics/types/basic';

type OwnProps = {
  bodyText?: string;
  link?: {
    label: string;
    href: string;
  };
  title: string;
  variant?: 'success' | 'info' | 'warning' | 'error';
  isMobile: boolean;
};

const emitMoreAboutNotificationEvent = () => {
  basicEvent(analyticsBus, {
    type: NOTIFICATION_BANNER,
    value: HP_NOTIFICATION_EVT_VALUE,
    action: NOW,
  });
};

type State = {
  isMobile: boolean;
};

const mapStateToProps = (state: Store): State => ({
  isMobile: getIsMobile(state),
});

const getQueryParams = (href: string): string => {
  try {
    const url = new URL(href);
    url.searchParams.append('icid', 'ghsweb_dchp_hplink_homebanner');
    return url.href;
  } catch (_) {
    return href;
  }
};

const NotificationBannerComponent: React.FC<OwnProps> = ({ bodyText, link, title, variant, isMobile }: OwnProps) => (
  <Notification variant={variant} title={title} showIcon={!isMobile}>
    <Text>
      {bodyText ? `${bodyText} ` : ''}
      {link && (
        <InlineLink
          href={getQueryParams(link.href)}
          onClick={emitMoreAboutNotificationEvent}
          target="_blank"
          rel="noreferrer noopener"
        >
          {link.label}
        </InlineLink>
      )}
    </Text>
  </Notification>
);

NotificationBannerComponent.defaultProps = {
  variant: 'warning',
};

const NotificationBanner = connect(mapStateToProps)(NotificationBannerComponent);

export { NotificationBanner };
