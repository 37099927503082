import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { openFeedbackWindow } from '#/lib/open-feedback-window';
import { getCutsMustard, getDeviceType, getTraceId } from '#/reducers/app';
import { getIsFirstTimeShopper, getUserHashedUId } from '#/reducers/user';
import { connect } from '#/lib/render/connect-deep-compare';

const mapStateToProps = state => ({
  cutsMustard: getCutsMustard(state),
  deviceType: getDeviceType(state),
  isFirstTimeShopper: getIsFirstTimeShopper(state),
  hashedUId: getUserHashedUId(state),
  traceId: getTraceId(state)
});

@connect(mapStateToProps)
export default class Feedback extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    cutsMustard: PropTypes.bool,
    deviceType: PropTypes.string,
    hashedUId: PropTypes.string.isRequired,
    isFirstTimeShopper: PropTypes.bool,
    location: PropTypes.string.isRequired,
    tag: PropTypes.node
  };

  static defaultProps = {
    className: '',
    tag: 'div'
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {
      children,
      className,
      cutsMustard,
      deviceType,
      isFirstTimeShopper,
      location,
      tag,
      hashedUId,
      traceId
    } = this.props;
    const Element = tag;
    const customerVariables = {
      cutsMustard: String(cutsMustard),
      deviceType,
      isFirstTimeShopper: String(isFirstTimeShopper),
      ghsUUID: hashedUId,
      ghs_customerID: hashedUId,
      traceId
    };

    return (
      <Element
        className={classnames(className, 'display-only-in-js')}
        onClick={() => openFeedbackWindow(location, customerVariables)}
      >
        {children}
      </Element>
    );
  }
}
