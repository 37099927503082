import { Item } from '#/lib/records/item';
import { FAVORITES } from '#/constants/tile-types';
import { getRdgDateRestriction, isLeadTimeProductInOrder } from '#/utils/restrictions-util';
import {
  hasRestrictedOrderAmendment,
  getRdgRestrictions,
  isInBasket,
  getIsSubstitute,
  isWhyNotTry,
} from '#/selectors/item';

export const favoritesPageShouldShowRemoveButton = (item: Item): boolean => {
  return !isWhyNotTry(item) && !getIsSubstitute(item);
};

export const trolleyPageShouldShowRemoveButton = (item: Item): boolean => {
  return !(
    (getIsSubstitute(item) && !isInBasket(item)) ||
    hasRestrictedOrderAmendment(item) ||
    isLeadTimeProductInOrder(getRdgDateRestriction(getRdgRestrictions(item)))
  );
};

export const shouldShowRemoveButton = (productTileType: string, item: Item): boolean => {
  if (productTileType === FAVORITES) {
    return favoritesPageShouldShowRemoveButton(item);
  }

  return false;
};
