import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ResponsiveImage } from '../responsive-image';

const ResponsiveBackground = ({
  url,
  alt,
  className,
  breakpoints,
  maxWidth,
  clipping,
  uid
}) => {
  const normaliseURI = uri => uri && (/\%/.test(uri) ? uri : encodeURI(uri));

  return (
    <span
      id={`responsive-background--${uid}`}
      className={classnames('responsive-background', className)}
    >
      {url && (
        <ResponsiveImage
          className={['responsive-background--image']
            .concat(className)
            .join(' ')}
          src={normaliseURI(url)}
          alt={alt}
          breakpoints={breakpoints}
          maxWidth={maxWidth}
          clipping={clipping}
        />
      )}
    </span>
  );
};

ResponsiveBackground.propTypes = {
  alt: PropTypes.string,
  breakpoints: PropTypes.arrayOf(
    PropTypes.shape({
      imageWidth: PropTypes.number,
      screenWidth: PropTypes.number
    })
  ),
  className: PropTypes.string,
  clipping: PropTypes.oneOf(['left', 'center', 'right']),
  maxWidth: PropTypes.number.isRequired,
  uid: PropTypes.number,
  url: PropTypes.string
};

export { ResponsiveBackground };
