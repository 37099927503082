import React from 'react';
import helpers from '#/lib/decorators/helpers';
import Notification from '@ddsweb/notification';
import { BodyText } from '@ddsweb/typography';
import Link from '@ddsweb/link';
import { spacing } from '@ddsweb/selectors';
import { css } from 'styled-components';

const notificationStyles = css`
  margin-bottom: ${spacing.xl};
`;

type TOwnProps = {
  t: (key: string, data?: object) => string;
  c: (key: string) => string;
};

const TrialClickAndCollectNotification: React.FC<TOwnProps> = (props: TOwnProps) => {
  const { t: translate, c: config } = props;
  const faqLink = config('links:unmannedClickAndCollectStoreFaq');
  const unmannedSlotsTitle = translate('common:unmanned-slots.title');
  const unmannedSlotsDesc = translate('common:unmanned-slots.description');
  const unmannedSlotsLinkText = translate('common:unmanned-slots.findout-more-link-text');

  return (
    <Notification styles={notificationStyles} title={unmannedSlotsTitle}>
      <BodyText>
        {unmannedSlotsDesc}
        <Link href={faqLink} target="_blank" rel="noopener">
          {unmannedSlotsLinkText}
        </Link>
      </BodyText>
    </Notification>
  );
};
export default helpers(['t', 'c'])(TrialClickAndCollectNotification);
