import { Dispatch } from '#/custom-typings/redux-store/common';
import { fetchResources } from '#/actions/resources-action-creators';
import { UPDATE_BROWSE_ENTRYPOINTS } from '#/experiments/oop-1894/constants/browseEntrypoints';
import { BROWSE_ENTRYPOINTS } from '#/experiments/oop-1894/constants/spa-resource';
import { logApmError } from '#/lib/apm';

export const updateBrowseEntrypoints = (
  browseEntrypoints: Store['browseEntrypoints'],
): ((dispatch: Dispatch) => void) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: UPDATE_BROWSE_ENTRYPOINTS,
      value: browseEntrypoints,
    });
  };
};

export const getBrowseEntrypoints = () => (dispatch: Dispatch): void => {
  try {
    dispatch(fetchResources([BROWSE_ENTRYPOINTS]));
  } catch (err) {
    logApmError(
      err as Error,
      `Failed to dispatch the browseEntrypoints resources, message: ${(err as Error).message}.`,
    );
  }
};
