import React from 'react';
import { AccordionGroupProps } from '../accordion.types';
import { BasicAccordion } from '@ddsweb/accordion';

const AccordionSection = (props: AccordionGroupProps): JSX.Element => {
  const { flush = false, behaviour = 'toggle', className, preExpanded, content } = props;

  return (
    <BasicAccordion
      behaviour={behaviour}
      content={content}
      preExpanded={preExpanded}
      flush={flush}
      containerClassName={className}
    />
  );
};

export default AccordionSection;
