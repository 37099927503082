import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Heading from '@ddsweb/heading';
import { connect } from '#/lib/render/connect-deep-compare';
import * as components from '#/components/cms-driven';
import PromotionsByDepartment from '#/components/promotions/promotions-by-department';
import PromotionList from '#/components/promotions/promotion-list';
import InteractiveStarRating from '#/components/star-rating/interactive-star-rating';
import AnalyticsRenderedProductsEvent from '#/components/analytics/rendered-products-event';
import AnalyticsContentEvent from '#/components/analytics/content-event';
import BackToTopFixed from '#/components/shared/back-to-top/back-to-top-fixed';
import {
  emitRenderedContentOp,
  emitContentInteractOp
} from '#/analytics/bertie/events';
import { itemsToMap, transferItems } from '#/lib/records/product-utils';
import dfpTargetingCreator from '#/lib/dfp-targeting-creator';
import helpers from '#/lib/decorators/helpers';
import { exposeActiveTestData } from '#/lib/optimizely-manager';
import {
  getIsUserAuthenticated,
  getIsUserRegistered,
  getUserStoreId,
  getUserHashedUId
} from '#/reducers/user';
import { getAppRegion } from '#/reducers/app';
import { getIsAmendBasket, getItems } from '#/selectors/trolley';
import { getPromotionsByDepartmentResource } from '#/selectors/resources';
import { getPromotionsContentResource } from '#/selectors/resources';
import { getShouldUseClubcardPricesName } from '#/experiments/oop-1029/selectors';
import { VERTICAL } from '#/components/product-tiles/common/constants';
import { PROMOTIONS } from '#/constants/result-types';

const mapStateToProps = state => ({
  isAmendBasket: getIsAmendBasket(state),
  isUserRegistered: getIsUserRegistered(state),
  isUserAuthenticated: getIsUserAuthenticated(state),
  items: getItems(state),
  promotionsByDepartmentResource:
    getPromotionsByDepartmentResource(state)?.data || {},
  promotionsContentResource: getPromotionsContentResource(state)?.data || {},
  showClubcardPricesName: getShouldUseClubcardPricesName(state),
  userHashedUId: getUserHashedUId(state),
  userStoreId: getUserStoreId(state),
  isIghs: getAppRegion(state) !== 'UK'
});

@connect(mapStateToProps)
@helpers(['t', 'c'])
export default class Promotions extends Component {
  static propTypes = {
    c: PropTypes.func.isRequired,
    isAmendBasket: PropTypes.bool.isRequired,
    isUserAuthenticated: PropTypes.bool.isRequired,
    isUserRegistered: PropTypes.bool.isRequired,
    items: PropTypes.array.isRequired,
    promotionsByDepartmentResource: PropTypes.shape({
      facetLists: PropTypes.array,
      pageInformation: PropTypes.object,
      productItems: PropTypes.array,
      promotionLists: PropTypes.array
    }),
    promotionsContentResource: PropTypes.shape({
      content: PropTypes.shape({
        top: PropTypes.array,
        bottom: PropTypes.array
      })
    }),
    t: PropTypes.func.isRequired,
    userHashedUId: PropTypes.string,
    userStoreId: PropTypes.string
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      headerHeight: 43,
      cookieHeight: 0
    };
  }

  componentDidMount() {
    const {
      promotionLists,
      promotionsConfig = {}
    } = this.props.promotionsByDepartmentResource;
    if (!Array.isArray(promotionLists)) {
      emitRenderedContentOp({
        content: [
          {
            pageType: 'promotions'
          }
        ]
      });
    }

    exposeActiveTestData(promotionsConfig);
  }

  updateItemsQuantityFromTrolley(itemsArray, trolleyItems) {
    return transferItems(itemsToMap(itemsArray), trolleyItems);
  }

  componentClickHandler(componentData) {
    emitContentInteractOp({
      displayArea: 'm-of',
      pageType: 'special-offers',
      panel: [
        {
          campaignId: componentData.title
            ? componentData.title.trim()
            : componentData.catId,
          contentSegment: componentData.segments,
          posInModule: componentData.posInPanel + 1
        }
      ],
      modulePosition: componentData.placement
    });
  }

  getAllRenderedItems(promotionLists) {
    let items = [];

    if (promotionLists) {
      items = promotionLists.reduce(
        (prev, next) => prev.concat(Array.from(next.items.values())),
        []
      );
    }

    return itemsToMap(items);
  }

  getStarRatingProps() {
    const { c: config, t: translate } = this.props;
    const legendText = translate('promotions:star-rating-feedback.legend-text');
    const legendAriaLabel = translate(
      'promotions:star-rating-feedback.legend-aria-label'
    );
    const refererUrl = config('opinionLab:refererUrls:specialOffers');

    return {
      legendAriaLabel,
      legendText,
      refererUrl
    };
  }

  render() {
    const {
      facetLists = [],
      pageInformation,
      promotionLists,
      promotionsConfig = {}
    } = this.props.promotionsByDepartmentResource;

    const items = this.props.items;

    const { content } = this.props.promotionsContentResource;
    const {
      isUserAuthenticated,
      showClubcardPricesName,
      userStoreId,
      userHashedUId,
      isIghs,
      t: translate
    } = this.props;

    // Harcoding the title for the oop-1029 experiment
    // This should move into web/locales/en/promotions.json when productionising
    const title = showClubcardPricesName
      ? 'Clubcard Prices'
      : translate('promotions:title');
    const carouselWrapperId = 'carouselWrapper';
    const totalCount = pageInformation ? pageInformation.totalCount : 0;

    const processedPromotionLists = Array.isArray(promotionLists)
      ? promotionLists
          .filter(list => list.productItems.length)
          .map(list => {
            return {
              facetId: list.facet.facetId,
              facetName: list.facet.facetName,
              items: this.updateItemsQuantityFromTrolley(
                list.productItems.slice(0, 4),
                items
              )
            };
          })
      : null;

    const targeting = dfpTargetingCreator({
      user: {
        isAuthenticated: isUserAuthenticated,
        storeId: userStoreId,
        hashedUId: userHashedUId
      }
    });

    const dcsProps = {
      shared: {
        componentClickHandler: this.componentClickHandler.bind(this)
      }
    };

    return (
      <div>
        <div id={carouselWrapperId} className="promotions">
          <div className="heading-label">
            <Heading headingLevel="1" className="heading query">
              {title}
            </Heading>
          </div>
          <div className="promotions--content">
            <div className="promotions--by-department">
              <PromotionsByDepartment
                isOpen={true}
                facetLists={facetLists}
                totalCount={totalCount}
                promotionsConfig={promotionsConfig}
              />
            </div>
            <div className="content-area">
              <components.DfpContainer
                pageId="specialoffers"
                targeting={targeting}
              />
              {content && content.top && (
                <components.ComponentTree
                  components={components}
                  data={content.top || []}
                  {...dcsProps}
                />
              )}
              {processedPromotionLists &&
                processedPromotionLists.map(list => {
                  return (
                    <PromotionList
                      key={list.facetId}
                      isAmendBasket={this.props.isAmendBasket}
                      listLink={
                        '/promotions/all?superdepartment=' + list.facetId
                      }
                      listTitle={list.facetName}
                      items={list.items}
                      userRegistered={this.props.isUserRegistered}
                      productTileVariant={VERTICAL}
                    />
                  );
                })}
              {this.props.c('showBackToTopFixed') && (
                <BackToTopFixed analyticsType={PROMOTIONS} />
              )}
              {content && content.bottom && (
                <components.ComponentTree
                  components={components}
                  data={content.bottom || []}
                  {...dcsProps}
                />
              )}
              {!isIghs && (
                <InteractiveStarRating {...this.getStarRatingProps()} />
              )}
              {content && (
                <AnalyticsContentEvent
                  data={{ content }}
                  pageName="promotions"
                  sectionOrder={['top', 'bottom']}
                />
              )}
            </div>
          </div>
        </div>
        <AnalyticsRenderedProductsEvent
          items={this.getAllRenderedItems(processedPromotionLists)}
          pageType="promotions"
        />
      </div>
    );
  }
}
