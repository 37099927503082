import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { getTimezone, getCurrency } from '#/reducers/app';
import { getModifiedPromotion } from '#/selectors/get-inclusive-end-date-promotions';
import helpers from '#/lib/decorators/helpers';
import ProductPromotion from './product-promotion';

const mapStateToProps = state => ({
  timezone: getTimezone(state),
  currency: getCurrency(state)
});

class ProductPromotions extends PureComponent {
  static propTypes = {
    c: PropTypes.func.isRequired,
    currency: PropTypes.object.isRequired,
    hideSmall: PropTypes.bool,
    isAlreadyShowingRdgMsg: PropTypes.bool,
    isAmendBasket: PropTypes.bool,
    itemPromotions: PropTypes.array.isRequired,
    offersIcid: PropTypes.string,
    showCompletePromoMessage: PropTypes.bool,
    showDatesOnlyOnMissedSlot: PropTypes.bool,
    tile: PropTypes.string,
    viewAndBuyButtonUrl: PropTypes.string,
    hasDeposit: PropTypes.bool
  };

  static defaultProps = {
    showCompletePromoMessage: true
  };

  render() {
    const {
      hideSmall,
      itemPromotions,
      offersIcid,
      isAlreadyShowingRdgMsg,
      isAmendBasket,
      showCompletePromoMessage,
      showDatesOnlyOnMissedSlot,
      tile,
      viewAndBuyButtonUrl,
      timezone,
      c: config,
      hasDeposit,
      currency: { symbol }
    } = this.props;
    const showReconstructedOfferMsg = config('showReconstructedOfferMsg');
    const reconstructOfferArgs = { showReconstructedOfferMsg, symbol };

    return (
      <div
        className={classNames('promotions-wrapper', {
          'hidden-small': hideSmall,
          'hidden-medium-small-only': hideSmall,
          'hidden-medium': !hideSmall
        })}
      >
        <ul className="product-promotions">
          {itemPromotions.map(promotion => {
            const promotionInclusive = getModifiedPromotion(
              promotion,
              timezone,
              reconstructOfferArgs
            );

            return (
              <ProductPromotion
                key={`${promotion.promotionId}*${offersIcid}`}
                isAlreadyShowingRdgMsg={isAlreadyShowingRdgMsg}
                isAmendBasket={isAmendBasket}
                showCompletePromoMessage={showCompletePromoMessage}
                showDatesOnlyOnMissedSlot={showDatesOnlyOnMissedSlot}
                promotion={promotionInclusive}
                showPromotionInfoBox={true}
                tile={tile}
                offersIcid={offersIcid}
                viewAndBuyButtonUrl={viewAndBuyButtonUrl}
                hasDeposit={hasDeposit}
              />
            );
          })}
        </ul>
      </div>
    );
  }
}

export default connect(mapStateToProps)(helpers(['c'])(ProductPromotions));
