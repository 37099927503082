import { mergeValuesAndStringify, parseString } from './helpers';

export default class ClientStoreWrapper {
  constructor(storage) {
    this.isSupported = this.isStorageSupported(storage);
    this.storage = this.isSupported && storage;
  }

  isStorageSupported = storage => {
    try {
      const dummyKey = 'key';

      storage.setItem(dummyKey, '');
      storage.removeItem(dummyKey);

      return true;
    } catch (e) {
      return false;
    }
  };

  /**
   * eg for value: if we already have a key user-Preferences with value { itemCount: 20, defaultView: grid } in storage
   * to add another key value under user-Preferences, request can have key:user-Preferences value:{ agreedTerms: true }
   * to remove agreedTerms request can be key:user-Preferences value:{ agreedTerms: undefined }
   * to update agreedTerms request can be key:user-Preferences value:{ agreedTerms: false }
   * also we can set a primitive type into storage eg: key: uuid value: 324234-dfs-234
   */
  /*: (key: string, value: var)*/
  set = (key, value) => {
    if (this.storage) {
      const existingValue = this.storage.getItem(key);

      if (typeof value === 'object') {
        //get existing value if already exists
        value = mergeValuesAndStringify(existingValue, value);
      }

      this.storage.setItem(key, value);
    }
  };

  /*: (key: string): var */
  get = key => {
    if (this.storage) {
      const value = this.storage.getItem(key);

      if (typeof value !== 'string') {
        return value;
      }

      return parseString(value);
    }
  };

  /*: (key: string) */
  remove = key => {
    if (this.storage) {
      this.storage.removeItem(key);
    }
  };
}
