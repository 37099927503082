import React from 'react';
import LinkCheckSpa from '#/components/link-check-spa';
import Link from '@ddsweb/link';

type TTitleLinkCommonProps = {
  href: string;
  children?: string | React.ReactElement;
  onClick?: () => void;
  className?: string;
};

export type TTileLinkButton = TTitleLinkCommonProps & {
  'data-auto': string;
  ariaLabel: string;
};

type TSpaLink = TTitleLinkCommonProps & {
  'aria-label': string;
};

const spaLink = ({ children, className, href, onClick, 'aria-label': ariaLabel }: TSpaLink): JSX.Element => (
  <LinkCheckSpa to={href} data-auto="review-link" className={className} onClick={onClick} aria-label={ariaLabel}>
    {children}
  </LinkCheckSpa>
);

const TileLinkButton = ({ href, children, onClick, ariaLabel, className }: TTileLinkButton): JSX.Element => (
  <Link
    variant="standalone"
    as={spaLink}
    className={className}
    href={href}
    icon={{
      graphic: 'forwardLink',
      position: { global: 'right' },
      size: 'xs',
    }}
    onClick={onClick}
    aria-label={ariaLabel}
  >
    {children}
  </Link>
);

export default TileLinkButton;
