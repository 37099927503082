import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import deepEqual from 'fast-deep-equal/es6';
import { connect } from '#/lib/render/connect-deep-compare';
import { getIsSameDay, isSlotAvailable } from '#/lib/slot/slot-utils';
import AvailableSlot from '#/components/slots/slot-selector/available-slot';
import {
  getShowAmendSlotChangeCopy,
  isUnavailableHDSlotWithCollectLink
} from '#/selectors/slot';
import BookedSlot from '#/components/slots/slot-selector/booked-slot';
import PreviouslyBookedSlot from '#/components/slots/slot-selector/previously-booked-slot';
import { getLanguage, getTimezone } from '#/reducers/app';
import { getShouldApplySamedaySurcharge } from '#/reducers/slot';
import {
  getPreviousSlot,
  getLastSelectedSlot,
  getIsAmendBasket
} from '#/selectors/trolley';
import helpers from '#/lib/decorators/helpers';
import { getSlotPageAnchorTag } from '#/lib/slot/slot-utils';
import {
  isBookedSlot,
  isPreviousSlot,
  getLocalDate
} from '#/lib/slot/slot-utils';
import { GRID, LIST, SlotViewModes } from '#/constants/slot-views';
import UnavailableHDSlotWithCollectLink from '#/components/slots/slot-selector/slot/unavailable-hd-slot-with-cc-link';
import { getFormattedSlotTime } from '#/components/shared/formatted-time/selector';
import { getIsHyphenVariant } from '#/experiments/oop-1335/selectors';
import { getIsSlotNotifyMeVariant } from '#/experiments/oop-2034/selectors';
import UnavailableHDSlotWithNotifyButton from '#/experiments/oop-2034/components/notify-me-hd-slot';

export const getSlotAfterDiscountPriceOrCharge = slot => {
  const { charge, price } = slot;
  return price?.afterDiscount ?? charge;
};

@helpers(['t', 'c'])
@connect((state, { slot, c: config }) => ({
  bookedSlot: getLastSelectedSlot(state),
  isAmendBasket: getIsAmendBasket(state),
  language: getLanguage(state),
  previousSlot: getPreviousSlot(state),
  timezone: getTimezone(state),
  shouldApplySamedaySurcharge: getShouldApplySamedaySurcharge(state),
  availableSlot: isSlotAvailable(slot),
  applyCollectLinkStyleVariant: isUnavailableHDSlotWithCollectLink(
    state,
    config
  ),
  slotTime: getFormattedSlotTime(state, slot.start, slot.end, config),
  showAmendSlotChangeCopy: getShowAmendSlotChangeCopy(state),
  isOop1335Hyphen: getIsHyphenVariant(state),
  showNotifyMeLink: getIsSlotNotifyMeVariant(state)
}))
export default class Slot extends Component {
  static propTypes = {
    applyCollectLinkStyleVariant: PropTypes.bool.isRequired,
    availableSlot: PropTypes.bool,
    bookedSlot: PropTypes.object,
    hidePriceInRecommenderButton: PropTypes.bool,
    isAmendBasket: PropTypes.bool,
    isOop1335Hyphen: PropTypes.bool,
    isLargeScreen: PropTypes.bool,
    isSelectedShoppingMethodSame: PropTypes.bool.isRequired,
    language: PropTypes.string.isRequired,
    previousSlot: PropTypes.object,
    showAmendSlotChangeCopy: PropTypes.bool,
    slot: PropTypes.object.isRequired,
    slotTime: PropTypes.string.isRequired,
    slotViewMode: PropTypes.oneOf([
      GRID,
      LIST,
      SlotViewModes.SLOT_RECOMMENDER,
      SlotViewModes.SLOT_RECOMMENDER_TILE
    ]).isRequired,
    t: PropTypes.func.isRequired,
    timezone: PropTypes.string.isRequired,
    showNotifyMeLink: PropTypes.bool
  };

  saveButtonRef = el => {
    this.buttonEl = el;
  };

  componentWillUpdate() {
    if (this.buttonEl === document.activeElement) {
      this.focusOnUpdate = true;
    }
  }

  componentDidUpdate() {
    if (this.focusOnUpdate && this.buttonEl) {
      this.buttonEl.focus();
      delete this.focusOnUpdate;
    }
  }

  shouldComponentUpdate({
    slot: nextPropSlot,
    previousSlot: nextPropPreviousSlot,
    bookedSlot: nextPropBookedSlot,
    applyCollectLinkStyleVariant: nextPropApplyCollectLinkStyleVariant
  }) {
    const {
      slot,
      previousSlot,
      bookedSlot,
      applyCollectLinkStyleVariant
    } = this.props;

    return (
      !deepEqual(slot, nextPropSlot) ||
      !deepEqual(previousSlot, nextPropPreviousSlot) ||
      !deepEqual(bookedSlot, nextPropBookedSlot) ||
      applyCollectLinkStyleVariant !== nextPropApplyCollectLinkStyleVariant
    );
  }

  render() {
    const {
      bookedSlot,
      previousSlot,
      isAmendBasket,
      isBestValue,
      slot,
      slotViewMode,
      timezone,
      t: translate,
      shouldApplySamedaySurcharge,
      availableSlot,
      isSelectedShoppingMethodSame,
      applyCollectLinkStyleVariant,
      onKeyPressed,
      isOop1335Hyphen,
      hidePriceInRecommenderButton,
      showNotifyMeLink,
      showAmendSlotChangeCopy,
      isLargeScreen
    } = this.props;

    const isBookedOrPrevious =
      isSelectedShoppingMethodSame &&
      (isBookedSlot(slot, bookedSlot, timezone) ||
        isPreviousSlot(slot, previousSlot, timezone));

    const bookedSlotDateStart =
      bookedSlot && moment.utc(bookedSlot.start).tz(timezone);
    const bookedSlotDateEnd =
      bookedSlot && moment.utc(bookedSlot.end).tz(timezone);
    const previousSlotStartDate =
      previousSlot && moment.utc(previousSlot.start).tz(timezone);
    const previousSlotEndDate =
      previousSlot && moment.utc(previousSlot.end).tz(timezone);

    const accessibleLabel = this._accessibleLabel(slot);
    const slotPageAnchorTag = getSlotPageAnchorTag(
      slotViewMode,
      slot.start.format(),
      slot.end.format()
    );

    if (
      (isBookedOrPrevious && !previousSlot) ||
      (isBookedOrPrevious &&
        ((bookedSlot.start === previousSlot.start &&
          bookedSlot.end === previousSlot.end) ||
          bookedSlot.status === 'Expired')) ||
      (isBookedOrPrevious &&
        bookedSlotDateStart.isSame(slot.start) &&
        bookedSlotDateEnd.isSame(slot.end))
    ) {
      return (
        <BookedSlot
          slotPageAnchorTag={slotPageAnchorTag}
          slotViewMode={slotViewMode}
          locationId={slot.locationId}
          disableUnbooking={
            isAmendBasket && bookedSlotDateStart.isSame(previousSlotStartDate)
          }
          slotStart={slot.start}
          saveButtonRef={this.saveButtonRef}
          updated={
            previousSlot.start &&
            (!previousSlotStartDate.isSame(slot.start) ||
              (previousSlotStartDate.isSame(slot.start) &&
                !previousSlotEndDate.isSame(slot.end)))
          }
          showAmendSlotChangeCopy={showAmendSlotChangeCopy}
          accessibleLabel={accessibleLabel}
          onKeyPressed={onKeyPressed}
          isLargeScreen={isLargeScreen}
        />
      );
    }
    if (isBookedOrPrevious && previousSlotStartDate.isSame(slot.start)) {
      return (
        <PreviouslyBookedSlot
          accessibleLabel={accessibleLabel}
          showAmendSlotChangeCopy={showAmendSlotChangeCopy}
        />
      );
    }

    if (!availableSlot) {
      if (showNotifyMeLink) {
        return (
          <UnavailableHDSlotWithNotifyButton
            accessibleLabel={accessibleLabel}
            onKeyPressed={onKeyPressed}
            start={slot.start}
          />
        );
      }
      if (applyCollectLinkStyleVariant) {
        return (
          <UnavailableHDSlotWithCollectLink
            accessibleLabel={accessibleLabel}
            slotId={slot.slotId}
            onKeyPressed={onKeyPressed}
          />
        );
      }

      return (
        <span className="unavailable-slot-text">
          <span className="visually-hidden">{accessibleLabel}</span>
          {isOop1335Hyphen ? (
            <>
              <span className="visually-hidden">
                {translate('slots:common.unavailable')}
              </span>
              <span aria-hidden="true">-</span>
            </>
          ) : (
            <span>{translate('slots:common.unavailable')}</span>
          )}
        </span>
      );
    }

    return (
      <AvailableSlot
        isBestValue={isBestValue}
        isLargeScreen={isLargeScreen}
        slotPageAnchorTag={slotPageAnchorTag}
        slotViewMode={slotViewMode}
        locationId={slot.locationId}
        hidePriceInRecommenderButton={hidePriceInRecommenderButton}
        slotCharge={getSlotAfterDiscountPriceOrCharge(slot)}
        slotIsSameDay={getIsSameDay(slot)}
        slotBeforeDiscountPrice={slot.price?.beforeDiscount}
        slotEnd={slot.end
          .clone()
          .tz(timezone)
          .format()}
        slotId={slot.slotId}
        slotStart={slot.start
          .clone()
          .tz(timezone)
          .format()}
        saveButtonRef={this.saveButtonRef}
        accessibleLabel={accessibleLabel}
        shouldApplySamedaySurcharge={shouldApplySamedaySurcharge(slot.start)}
        onKeyPressed={onKeyPressed}
      />
    );
  }

  _accessibleLabel(slot) {
    const { language, t: translate, timezone, slotTime } = this.props;
    const date = getLocalDate(slot.start, timezone, language).format(
      'dddd Do MMMM'
    );

    return `${date}, ${translate('slots:common.between')} ${slotTime}.`;
  }
}
