export const specialOffersRedesignVariants = {
  key: '2055',
  featureKey: 'ghs-all_specialoffers_media',
  variationKey: 'variationKey',
  variants: {
    default: '2055-a',
    specialOffersRedesign: '2055-b',
  },
  useCustomerId: true,
};
