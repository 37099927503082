import querystring from 'querystring';

export default {
  removeFromQuerystring(querystring, key, value, delimiter = ',') {
    const query = (querystring[key] || '')
      .split(delimiter)
      .filter(it => it !== `${value}`)
      .join(delimiter);

    return this.updateQuerystring(querystring, query, key);
  },

  addToQuerystring(querystring, key, value, delimiter = ',') {
    let query;

    if (querystring[key]) {
      const hasValue = querystring[key]
        .split(delimiter)
        .some(it => it === value);

      if (hasValue) {
        query = querystring[key];
      } else {
        query = `${querystring[key]}${delimiter}${value}`;
      }
    } else {
      query = value;
    }

    return this.updateQuerystring(querystring, query, key);
  },

  updateQuerystring(querystring, query, key) {
    if (`${query}`.length > 0) {
      querystring[key] = query;
    } else {
      delete querystring[key];
    }

    return querystring;
  },

  /**
   * @deprecated Use parseQuerystring() in url-utils instead
   */
  parseQuerystring(url) {
    let query;

    if (url.includes('?')) {
      query = url.split('?')[1];
    } else {
      query = '';
    }

    return querystring.parse(query);
  },

  createHref(url, qs, decoded = false) {
    let base;

    if (url.includes('?')) {
      base = url.split('?')[0];
    } else {
      base = url;
    }

    let query = querystring.stringify(qs);

    if (decoded) {
      query = decodeURIComponent(query);
    }

    if (query.length > 0) {
      return `${base}?${query}`;
    }

    return `${base}`;
  }
};
