import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { ON_DEMAND, ShoppingMethod } from '#/constants/shopping-methods';
import { ONDEMAND_DELIVERY_AVAILABLE, ONDEMAND_DELIVERY_UNAVAILABLE } from '#/analytics/constants';
import { ondemandStatusAnalytics } from '#/analytics/helpers/slot';
import { DynamicDeliveryTime } from '#/custom-typings/redux-store/slot.defs';
import { getCurrency, getLanguage, getLanguageLink, getMfeRolloutConfig } from '#/reducers/app';
import {
  getAvailableShoppingMethods,
  getDeliveryCharge,
  getOnDemandDynamicDeliveryTime,
  getOnDemandServiceAvailable,
  getShoppingMethodsTabs,
} from '#/selectors/slot';
import helpers from '#/lib/decorators/helpers';
import { isOnDemandShoppingMethod } from '#/lib/shopping-method-util';
import { TTranslateFunc } from '#/lib/records/helpers.defs';
import { StyledFulfilmentOptionsTabsMenu } from './styled';
import { MenuItemProps } from '@ddsweb/tabs-menu';
import { changeShoppingMethod } from '#/actions/slot-action-creators';
import { LinkProps } from '@ddsweb/link';
import Link from '#/components/link-check-spa';
import { isMFERouteAvailable } from '#/routes/spa-routes/spa-helpers';

function showTabIcons(availableShoppingMethods: ShoppingMethod[]): boolean {
  return !availableShoppingMethods.includes(ON_DEMAND);
}
type TStateProps = {
  charge?: number;
  currency: {
    isoCode: string;
    symbol: string;
  };
  language: string;
  onDemandDynamicDeliveryTime: DynamicDeliveryTime;
  showIcons: boolean;
  shoppingMethodsTabs: string[];
  isOnDemandAvailable: boolean;
  homeDeliveryUrl: string;
  collectionUrl: string;
  onDemandUrl: string;
  mfeRolloutConfig: object;
};
const mapDispatchToProps = {
  changeShoppingMethod,
};

const mapStateToProps = (state: Store, { shoppingMethod }: { shoppingMethod: ShoppingMethod }): TStateProps => {
  const availableShoppingMethods = getAvailableShoppingMethods(state);
  const currency = getCurrency(state);
  const charge = getDeliveryCharge(state, shoppingMethod);
  const language = getLanguage(state);
  const onDemandDynamicDeliveryTime = getOnDemandDynamicDeliveryTime(state);
  const shoppingMethodsTabs = getShoppingMethodsTabs(state);
  const homeDeliveryUrl = getLanguageLink(state, `/slots/${shoppingMethodsTabs[0]}`);
  const collectionUrl = getLanguageLink(state, `/slots/${shoppingMethodsTabs[1]}`);
  const onDemandUrl = getLanguageLink(state, `/slots/${shoppingMethodsTabs[2]}`);

  return {
    charge,
    currency,
    language,
    onDemandDynamicDeliveryTime,
    showIcons: showTabIcons(availableShoppingMethods),
    shoppingMethodsTabs,
    isOnDemandAvailable: availableShoppingMethods.includes(ON_DEMAND) ? getOnDemandServiceAvailable(state) : false,
    homeDeliveryUrl,
    collectionUrl,
    onDemandUrl,
    mfeRolloutConfig: getMfeRolloutConfig(state),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type TOwnProps = ConnectedProps<typeof connector> & {
  t: TTranslateFunc;
  isAmendBasket: boolean;
  shoppingMethod: ShoppingMethod;
  setMfeTabContentLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const SPALink = (props: LinkProps): JSX.Element => {
  const { children, href, ...otherProps } = props;
  return (
    <Link to={href} {...otherProps}>
      {children}
    </Link>
  );
};

const FulfilmentOptionsTabsComponent: React.FC<TOwnProps> = (props: TOwnProps) => {
  const {
    isAmendBasket,
    shoppingMethod,
    shoppingMethodsTabs,
    t: translate,
    homeDeliveryUrl,
    collectionUrl,
    onDemandUrl,
    changeShoppingMethod,
    setMfeTabContentLoading,
    mfeRolloutConfig,
  } = props;

  function fireUIBasicEvent(): void {
    const { shoppingMethodsTabs, isOnDemandAvailable } = props;

    if (shoppingMethodsTabs.includes(ON_DEMAND)) {
      const ondemandStatus = isOnDemandAvailable ? ONDEMAND_DELIVERY_AVAILABLE : ONDEMAND_DELIVERY_UNAVAILABLE;
      ondemandStatusAnalytics(ondemandStatus);
    }
  }

  const [currentShoppingMethod, setCurrentShoppingMethod] = React.useState(shoppingMethod);

  useEffect(() => {
    setCurrentShoppingMethod(shoppingMethod);
  }, [shoppingMethod]);

  useEffect(() => {
    fireUIBasicEvent();
  }, [shoppingMethodsTabs]);

  const getTabLink = (shoppingMethod: string): string => {
    switch (shoppingMethod) {
      case 'delivery':
        return homeDeliveryUrl;
      case 'collection':
        return collectionUrl;
      case 'ondemand':
        return onDemandUrl;
      default:
        return '';
    }
  };

  const tabMenuItems = shoppingMethodsTabs.map(shoppingMethodsTab => {
    const tabText = isOnDemandShoppingMethod(shoppingMethodsTab)
      ? translate(`common:shopping-method_${shoppingMethodsTab}-tab-header`)
      : translate(`common:shopping-method_${shoppingMethodsTab}`);

    return {
      disabled: isAmendBasket && shoppingMethodsTab !== shoppingMethod,
      href: getTabLink(shoppingMethodsTab),
      id: `${shoppingMethodsTab}-tab`,
      label: tabText,
      spaLink: SPALink,
      'data-testid': `${shoppingMethodsTab}-tab`,
    };
  });

  const onTabsMenuChangeHandler = ({ nextTab }: { nextTab: MenuItemProps }): void => {
    const nextMethod = nextTab?.id?.split('-')[0] as ShoppingMethod;

    if (isMFERouteAvailable(mfeRolloutConfig, `slots-${nextMethod}`)) {
      setMfeTabContentLoading(true);
    } else {
      changeShoppingMethod(nextMethod, nextMethod);
    }
    setCurrentShoppingMethod(nextMethod);
  };

  return (
    <StyledFulfilmentOptionsTabsMenu
      activeTabID={`${currentShoppingMethod}-tab`}
      menuItems={tabMenuItems}
      onTabsMenuChange={onTabsMenuChangeHandler}
    />
  );
};

export const FulfilmentOptionsTabs = connector(helpers(['t'])(FulfilmentOptionsTabsComponent));
