import React from 'react';
import PropTypes from 'prop-types';
import page from 'page';
import { compose } from 'react-recompose';
import FilterOption from '../filter-option';
import { addFilterCategoryToUrl } from '#/lib/filter-helpers';
import { removeFilterCategoryFromUrl } from '#/utils/product-filter-utils';
import helpers from '#/lib/decorators/helpers';
import { getCurrentUrl, getCurrentPathname } from '#/reducers/app';
import { connect } from '#/lib/render/connect-deep-compare';

const mapStateToProps = (state: Store): { currentUrl: string; currentPathname: string } => ({
  currentUrl: getCurrentUrl(state),
  currentPathname: getCurrentPathname(state),
});

type TFilterectorSelectorProps = {
  t: (key: string, data?: object) => string;
  c: (key: string) => string;
};

type TOwnProps = {
  ariaLabelledBy: string;
  filterData: object;
  currentPathname: string;
  currentUrl: string;
  selected: boolean;
  filterName: string;
  filtersToRemove?: Array<string>;
  translationKeys: string;
  hasTooltip?: boolean;
  renderTooltip?: Function;
  id: string;
};

type TProps = TOwnProps & TFilterectorSelectorProps;

const FilterSelector: React.FC<TProps> = (props: TProps) => {
  let url: string;
  const {
    ariaLabelledBy,
    selected,
    currentUrl,
    currentPathname,
    filterData,
    c: config,
    t: translate,
    filterName,
    translationKeys,
    filtersToRemove = [],
    hasTooltip = false,
    renderTooltip,
    id,
  } = props;

  if (selected) {
    url = removeFilterCategoryFromUrl(filterName, currentUrl, currentPathname);
  } else {
    url = addFilterCategoryToUrl({
      categoryDelimiter: config('categoryDelimiter'),
      currentPathname,
      currentUrl,
      filterData,
      filterName,
      filtersToRemove,
    });
  }

  const label = translate(translationKeys);

  const changeHandler = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    page(url);
  };

  return (
    <FilterOption
      className="available-selector"
      showCount={false}
      id={id}
      label={label}
      onChange={changeHandler}
      selected={selected}
      url={url}
      longLabelsAllowed={true}
      hasTooltip={hasTooltip}
      renderTooltip={renderTooltip}
      ariaLabelledBy={ariaLabelledBy}
    />
  );
};

FilterSelector.propTypes = {
  c: PropTypes.func.isRequired,
  currentPathname: PropTypes.string.isRequired,
  currentUrl: PropTypes.string.isRequired,
  filterData: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};
const enhance = compose<TProps, TOwnProps>(helpers(['c', 't']), connect(mapStateToProps));

export default enhance(FilterSelector);
