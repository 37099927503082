import { isOnDemandShoppingMethod } from '#/lib/shopping-method-util';
import { slotCurrentlyBooked, hasSlotExpired } from '#/lib/slot/slot-utils';

export const isOnDemandDeliverySlotBooked = () => resources => {
  const { trolleyContents } = resources || {};

  return (
    isOnDemandShoppingMethod(trolleyContents?.shoppingMethod) &&
    slotCurrentlyBooked(trolleyContents?.slot)
  );
};

export const isOnDemandDeliverySlotExpired = () => resources => {
  const { trolleyContents } = resources || {};

  return (
    isOnDemandShoppingMethod(trolleyContents?.shoppingMethod) &&
    hasSlotExpired(trolleyContents?.slot)
  );
};
