import { getFeatureVariableStringFromState } from '#/lib/optimizely-manager';
import { favoritesRecentOrder, favoritesRecentOrderVariants } from '#/experiments/oop-1720/constants';
import { getShouldShowDDSDropdown, getShouldShowNewDropDown } from '#/experiments/oop-1720/helpers';

const getVariant = (state: Store): favoritesRecentOrderVariants => {
  const { useCustomerId, featureKey, variationKey } = favoritesRecentOrder;

  return (
    getFeatureVariableStringFromState(state, featureKey, variationKey, { useCustomerId }) ||
    favoritesRecentOrderVariants.default
  );
};

export const getRecentOrderVariant = (state: Store): favoritesRecentOrderVariants => getVariant(state);

export const getShouldShowOrderDropdown = (state: Store): boolean => {
  const variant = getVariant(state);

  return getShouldShowDDSDropdown(variant) || getShouldShowNewDropDown(variant);
};
