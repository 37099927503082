import { precisionRound } from '../../utils/number-utils';

function separateThousandsAndDecimals(number, decimalMark, thousandsSeparator) {
  return `${number}`
    .replace(/\./g, decimalMark)
    .replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator);
}

function addSymbol(number, symbol, position, compactMode) {
  const spaceOrEmpty = compactMode ? '' : ' ';

  if (position === 'left') {
    return `${symbol}${spaceOrEmpty}${number}`;
  }

  return `${number}${spaceOrEmpty}${symbol}`;
}

const defaultOptions = {
  value: 0,
  symbolPosition: 'left',
  decimalMark: '.',
  thousandsSeparator: ',',
  decimalPlaces: 2,
  maxDecimalPlaces: false,
  maxLimit: 100000,
  enableMaxLimit: false,
  showAsciiSymbol: false,
  symbol: '',
  compactMode: false
};

export default function formatNumber(value = 0, customOptions = {}) {
  const options = Object.assign({}, defaultOptions, customOptions);
  let number = isNaN(options.value) ? 0 : parseFloat(value || options.value);

  if (options.enableMaxLimit && number >= options.maxLimit) {
    number = `${options.maxLimit}+`;
  } else {
    number = precisionRound(number, options.decimalPlaces).toFixed(
      options.decimalPlaces
    );

    if (options.maxDecimalPlaces) {
      number = (+number).toString();
    }
  }

  number = separateThousandsAndDecimals(
    number,
    options.decimalMark,
    options.thousandsSeparator
  );

  if (options.showAsciiSymbol) {
    number = addSymbol(
      number,
      options.symbol,
      options.symbolPosition,
      options.compactMode
    );
  }

  if (options.truncateWholeValues) {
    return number.replace(new RegExp(`\\${options.decimalMark}0+$`), '');
  }

  return number;
}
