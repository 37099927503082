import React, { useMemo } from 'react';
import { TTranslateFunc } from '#/lib/records/helpers.defs';
import { Item } from '#/lib/records/item';
import { getProductTitle } from '#/selectors/item';
import { StyledCheckboxWithLabel } from './styled';

export interface SubstitutionsCheckboxProps {
  checked: boolean;
  item: Item;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  translate: TTranslateFunc;
}

export const SubstitutionsCheckbox = ({
  checked,
  item,
  onChange,
  translate,
}: SubstitutionsCheckboxProps): JSX.Element => {
  const labelText = useMemo(() => translate('product-tile:allow-subs-per-item'), [translate]);

  const ariaLabel = useMemo(
    () =>
      `${
        checked ? translate('product-tile:press-to-not-allow-subs') : translate('product-tile:press-to-allow-subs')
      } ${translate('product-tile:allow-preference-for')} ${getProductTitle(item)}`,
    [item, labelText, translate],
  );

  return (
    <StyledCheckboxWithLabel
      checkboxProps={{ 'aria-label': ariaLabel, 'data-auto': 'tile-subs-checkbox' }}
      checked={checked}
      labelText={labelText}
      onChange={onChange}
    />
  );
};

export default SubstitutionsCheckbox;
