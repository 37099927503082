import bertie from '../analyticsBus';
import {
  CLUBCARD_POINTS,
  COUPON_TYPE_POINTS,
  COUPON_TYPE_MONETARY
} from '../../constants/coupons';

export const emitCouponEvent = (coupon, action, fitted) => {
  const discount = {};

  if (coupon.rewardType === CLUBCARD_POINTS) {
    discount.type = COUPON_TYPE_POINTS;
    discount.value = coupon.clubcardPoints;
  } else {
    //default will be Monetary type of coupon
    discount.type = COUPON_TYPE_MONETARY;
    discount.value = coupon.value;
  }

  bertie().emit('couponOp', {
    action,
    couponCode: coupon.code,
    discount: discount.value,
    discountType: discount.type,
    fitted
  });
};

export const emitCouponErrorEvent = ({ errorCode, status }) => {
  bertie().emit('errorData', {
    code: status,
    text: errorCode,
    type: 'coupon'
  });
};
