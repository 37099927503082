import styled from 'styled-components';
import { colors, spacing } from '@ddsweb/selectors';

export const StyledAccordionContent = styled.div`
  position: relative;

  &:before {
    content: '';
    border-top: 1px solid ${colors.lines};
    position: absolute;
    top: -${spacing.xs};
    left: 0;
    right: 0;
  }
`;

export const StyledAccordionWrapper = styled.div`
  .whoosh__how-it-works {
    border: 1px solid ${colors.lines};
    margin-top: ${spacing.lg};
    padding: ${spacing.xx};
  }

  [data-accordion-button] {
    height: auto;
    margin: 0;
  }
`;
