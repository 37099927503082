import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Base from '../../base';
import Price from '../../shared/price';
import { WEIGHT_UNIT_KG, QUANTITY_TYPE } from '../../../constants/common';

export default class PricePerSellableUnit extends Base {
  static propTypes = {
    displayType: PropTypes.oneOf([
      'Quantity',
      'Weight',
      'QuantityOrWeight',
      'CatchWeight'
    ]).isRequired,
    isOnPromotion: PropTypes.bool,
    prePromoPrice: PropTypes.number,
    price: PropTypes.number.isRequired,
    selectedUnit: PropTypes.oneOf([QUANTITY_TYPE, WEIGHT_UNIT_KG]).isRequired
  };

  static defaultProps = {
    isOnPromotion: false,
    prePromoPrice: 0
  };

  render() {
    const isWeight = this.props.displayType.toLowerCase() !== 'quantity';
    const currency = this.c('currency');

    return (
      <div
        className={classnames(
          'price-per-sellable-unit',
          'price-per-sellable-unit--price',
          {
            'price-per-sellable-unit--price-per-weight': isWeight,
            'price-per-sellable-unit--price-per-item': !isWeight
          }
        )}
      >
        {this.props.isOnPromotion &&
        this.props.prePromoPrice &&
        this.props.prePromoPrice !== this.props.price ? (
          <div className="price-per-sellable-unit--pre-promo-price">
            <Price value={this.props.prePromoPrice} {...currency} />
          </div>
        ) : null}
        <div
          className={classnames({
            'on-promotion': this.props.isOnPromotion
          })}
        >
          <Price value={this.props.price} {...currency} />
        </div>
      </div>
    );
  }
}
