export default function mergeResourceConfigOptions(optionsOne, optionsTwo) {
  return Object.keys(optionsOne).reduce(
    (mergedOptions, key) => {
      if (!mergedOptions[key]) {
        mergedOptions[key] = [...optionsOne[key]];
      } else if (key === 'dependentOn') {
        mergedOptions[key] = [
          ...new Set([...optionsOne[key], ...mergedOptions[key]])
        ];
      } else {
        mergedOptions[key] = [...mergedOptions[key], ...optionsOne[key]];
      }

      return mergedOptions;
    },
    { ...optionsTwo }
  );
}
