export const SLOT = `fragment Slot on SlotInterface {
  slotId: id
  start
  end
  expiry
  reservationExpiry
  charge
  status
  group
  locationId
  countdown {
    workingDays
    deliveryDate
  }
  freeDelivery{
    deliveryThreshold
    deliveryMessageThreshold
    qualifiesForFreeDelivery
  }
}`;
