import { addApmData } from '#/lib/apm';
import { Item } from '#/lib/records/item';
import { getProductStatus } from '#/selectors/item';
import {
  EPW_WITHOUT_AMEND_AVAILABILITY_OVERRIDE,
  EPW_WITH_AMEND_AVAILABILITY_OVERRIDE,
  EXCLUDED_PRODUCT,
  PERMANENTLY_UNAVAILABLE,
  TEMPORARY_UNAVAILABLE,
  AVAILABLE_FOR_SALE,
} from '#/constants/unavailable-product-type';
import { getLanguageLink } from '#/reducers/app';
import { getUserUuid } from '#/reducers/user';
import {
  getFavLastStateStorageKey,
  getLastFavStateFromStorage,
  getShouldPersistFilters,
} from '#/experiments/oop-1801/helpers';
import { updateParamsInUrl } from '#/lib/url/url-utils';
import { getShouldRememberPaginationVariant } from '#/experiments/oop-1801/selectors';
import { isOnDemandDeliveryShoppingMethod } from '#/lib/shopping-method-util';
import { getTrolleyShoppingMethod } from '#/selectors/trolley';
import { QUERY_LAYOUT, STORAGE_KEY } from '#/constants/favorites';
import { localStore } from '#/lib/data-store/client-store';

type FavouriteTotalsAPMRecord = {
  totalCount?: number;
  facets?: string[];
  itemsReceived?: number;
  itemsRequested?: number;
  available?: number;
  permanentlyUnavailable?: number;
  temporaryUnavailable?: number;
  excludedProduct?: number;
  EPWNoAmend?: number;
  EPWAmend?: number;
  pageNo?: number;
};

/**
 * This function will log data relating to the favorites resource data to APM.
 *
 * We have initially added this to help diagnose any possible discrepancies in
 * the amount of favourites the server has vs what is being communicated to the
 * customer via the favourites tab.
 * https://tescosupportcentre.zendesk.com/agent/tickets/15872749
 *
 * @param resourceResult The resource result for favourites tab
 */
const getProductStatusCount = (data: any, status: string) => {
  return data?.results?.productItems?.reduce(
    (acc: number, item: Item) => (getProductStatus(item) === status ? acc + 1 : acc),
    0,
  );
};

export const addResultsAPMData = (resourceResult: any, page: string): void => {
  const totalCount = resourceResult?.results?.pageInformation?.totalCount;
  const itemsRequested = resourceResult?.results?.pageInformation?.pageSize;

  const available = getProductStatusCount(resourceResult, AVAILABLE_FOR_SALE);
  const permanentlyUnavailable = getProductStatusCount(resourceResult, PERMANENTLY_UNAVAILABLE);
  const temporaryUnavailable = getProductStatusCount(resourceResult, TEMPORARY_UNAVAILABLE);
  const excludedProduct = getProductStatusCount(resourceResult, EXCLUDED_PRODUCT);
  const EPWNoAmend = getProductStatusCount(resourceResult, EPW_WITHOUT_AMEND_AVAILABILITY_OVERRIDE);
  const EPWAmend = getProductStatusCount(resourceResult, EPW_WITH_AMEND_AVAILABILITY_OVERRIDE);

  const itemsReceived = resourceResult?.results?.productItems?.length;
  const pageNo = resourceResult?.results?.pageInformation?.pageNo;

  const record: FavouriteTotalsAPMRecord = {
    facets: resourceResult?.results?.facetLists?.[0]?.facets.reduce((acc, facet) => {
      const { facetId, isSelected } = facet;
      if (isSelected) {
        acc.push(facetId);
      }
      return acc;
    }, []),
    totalCount,
    itemsReceived,
    itemsRequested,
    pageNo,
    available,
    permanentlyUnavailable,
    temporaryUnavailable,
    excludedProduct,
    EPWNoAmend,
    EPWAmend,
  };

  addApmData(page, JSON.stringify(record));
};

export function getFavoritesLanguageLink(state: Store, path: string, site = undefined): string {
  const uuid = getUserUuid(state);
  const url = getLanguageLink(state, path, site);
  const isEnabledFavsForOnDemand = isOnDemandDeliveryShoppingMethod(getTrolleyShoppingMethod(state));

  if (!isEnabledFavsForOnDemand && getShouldPersistFilters(getShouldRememberPaginationVariant(state))) {
    const favLastStateSessionKey = getFavLastStateStorageKey(state);
    const favLastState = getLastFavStateFromStorage(favLastStateSessionKey);
    if (favLastState) {
      return updateParamsInUrl(url, favLastState);
    }
  }

  const layout = getFavLayoutFromStorage(uuid);

  if (layout) {
    return updateParamsInUrl(url, { [QUERY_LAYOUT]: layout });
  }
  return url;
}

export const getStorageKey = (uuid: string): string => `${STORAGE_KEY}-${uuid}`;

export const getQueryStrings = (view: string): string => `${QUERY_LAYOUT}=${view}`;

export const getFavLayoutFromStorage = (uuid: string): string | undefined => localStore?.get(getStorageKey(uuid));

export const setFavLayoutToStorage = (uuid: string, value: string): void => localStore?.set(getStorageKey(uuid), value);

export const waitHandler = (time: number): Promise<void> => {
  return new Promise((resolve: () => void) => {
    setTimeout(() => {
      resolve();
    }, time);
  });
};
