const firstWordCharRegEx = /\b\w/g;

// Currently does not properly support strings with accents
export function capitalise(str) {
  return str.replace(firstWordCharRegEx, function(match) {
    return match.toUpperCase();
  });
}

export function getKeyWithoutSuffix(key, delimiter = '_') {
  return typeof key === 'string' ? key.split(delimiter)[0] : key;
}

export const getTruncatedString = (string, length = 15, ending = '...') => {
  if (string.trim().length > length) {
    const substring = string.substring(0, length - ending.length);

    return `${substring.trim()}${ending.trim()}`;
  }

  return string;
};
