import React from 'react';
import { ConnectedProps } from 'react-redux';
import { compose } from 'react-recompose';
import classnames from 'classnames';
import { TextButtonLink } from '@ddsweb/link';
import { openModal } from '#/actions/ui-action-creators';
import { SKIP_OFFERS_MODAL } from '#/constants/modal-names';
import { TROLLEY } from '#/constants/page-names';
import { connect } from '#/lib/render/connect-deep-compare';
import helpers from '#/lib/decorators/helpers';
import { TConfigFunc } from '#/lib/records/helpers.defs';
import { getOrderSummaryUrlFromState } from '#/utils/checkout-utils';
import { getIsAmendBasket, hasValidSlot } from '#/selectors/trolley';
import { getOffersSkipModalVariant, showModalFromVariation } from '#/selectors/checkout';
import {
  getRecsSkipModalVariant,
  showModalFromRecVariation,
  showCopyFromRecVariation,
} from '#/experiments/oop-1971/selectors';

type HelperProps = {
  c: TConfigFunc;
};

type ComponentProps = {
  disabled: boolean;
  inverse?: boolean;
  buttonText: string;
  page: string;
  continueHref: string;
  classes?: string;
  analyticsTracking?: () => void;
  renderButton: (url: string) => React.ReactElement;
};

type State = {
  hasValidSlot: boolean;
  offersSkipModalVariant: string | null;
  recsSkipModalVariant: string | null;
  recsSkipModal: boolean;
  orderSummaryUrl: string;
  isInAmend: boolean;
};

const mapDispatchToProps = {
  openModal,
};

const mapStateToProps = (state: Store, { c: config }: HelperProps): State => {
  const recsSkipModalVariant = getRecsSkipModalVariant(state, config) || null;
  return {
    hasValidSlot: hasValidSlot(state),
    offersSkipModalVariant: getOffersSkipModalVariant(state, config) || null,
    recsSkipModalVariant,
    recsSkipModal: showModalFromRecVariation(recsSkipModalVariant, state),
    orderSummaryUrl: getOrderSummaryUrlFromState(state),
    isInAmend: getIsAmendBasket(state),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = HelperProps & ComponentProps & ConnectedProps<typeof connector>;

export const ContinueCheckoutButton = ({
  disabled,
  inverse,
  buttonText,
  continueHref,
  classes = '',
  offersSkipModalVariant = null,
  recsSkipModalVariant,
  recsSkipModal,
  renderButton,
  openModal,
  hasValidSlot,
  analyticsTracking,
}: Props): React.ReactElement => {
  const showSkipModal = showModalFromVariation(offersSkipModalVariant) || recsSkipModal;

  const showRecCopy = showCopyFromRecVariation(recsSkipModalVariant);

  const openSkipModal = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    openModal(SKIP_OFFERS_MODAL, { continueHref, showRecCopy });
    // for experiment OOP-1882 we're adding aditional analytics tracking to the amend banner
    typeof analyticsTracking === 'function' && analyticsTracking();
  };

  return hasValidSlot && showSkipModal ? (
    <TextButtonLink
      stretch
      onClick={openSkipModal}
      disabled={disabled}
      className={classnames(classes)}
      inverse={inverse}
    >
      {buttonText}
    </TextButtonLink>
  ) : (
    renderButton(continueHref)
  );
};

const ContinueCheckoutButtonWrapper = (props: Props): React.ReactElement => {
  const { continueHref, page, renderButton, isInAmend } = props;
  // we're only running this experiment on the trolley page and inAmend
  return isInAmend && page === TROLLEY ? (
    <ContinueCheckoutButton data-testid="continue-checkout-wrapper-button" {...props} />
  ) : (
    renderButton(continueHref)
  );
};

const enhance = compose<Props, ComponentProps>(helpers(['c']), connector);
export default enhance(ContinueCheckoutButtonWrapper);
