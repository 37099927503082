import { incrementItemBy, removeItem } from '#/actions/trolley/trolley-action-creators';
import analyticsBus from '#/analytics/analyticsBus';
import {
  BASKET_LOGO,
  BOOK_A_SLOT,
  CHANGE_SLOT,
  CHECKOUT,
  DELAY,
  SHOP_FAVOURITES,
  SIDEBAR,
  VIEW_BASKET,
  ADD_USUALS,
  SHOP_USUALS,
  SHOP_PREVIOUSLY_BOUGHT,
} from '#/analytics/constants';
import { basicEvent } from '#/analytics/types/basic';
import LinkCheckSpa from '#/components/link-check-spa';
import { Dispatch, GetStore } from '#/custom-typings/redux-store/common';
import helpers from '#/lib/decorators/helpers';
import { Item } from '#/lib/records/item';
import { connect } from '#/lib/render/connect-deep-compare';
import { getCurrency, getLanguage } from '#/reducers/app';
import { getCurrencyLocale } from '#/i18n/helpers';
import { getIsFirstTimeShopper } from '#/reducers/user';
import { atMaxQty, getProductId, getQuantity, getUnit } from '#/selectors/item';
import {
  getAnalyticsOrderId,
  getBaskets,
  getGroceryBasket,
  getIsAmendBasket,
  getIsTrolleyItemUpdating,
  getOnDemandSlot,
  getPreviousSlot,
  getSlot,
  getTrolleyItem,
  getTrolleyStateAndPendingQty,
  getUpdatingCatchWeightItem,
} from '#/selectors/trolley';
import { getPendingOrders } from '#/selectors/order-list-details';
import {
  BasketContext,
  BasketState,
  BASKET_LOGO_ANALYTICS_EVENT,
  BOOK_SLOT_ANALYTICS_EVENT,
  CHANGE_SLOT_ANALYTICS_EVENT,
  CHECKOUT_BUTTON_ANALYTICS_EVENT,
  ComponentContext,
  Components,
  DECREMENT_ITEM_EVENT,
  FULL_BASKET_BUTTON_ANALYTICS_EVENT,
  INCREMENT_ITEM_EVENT,
  Item as MFEItem,
  MISSED_OFFER_ANALYTICS_EVENT,
  REMOVE_ITEM_EVENT,
  Slot,
  SHOP_FAVOURITES_BUTTON_ANALYTICS_EVENT,
  ADD_USUALS_EVENT,
  SHOP_USUALS_EVENT,
  PREVIOUSLY_BOUGHT_EVENT,
  SplitViewBasket,
} from '@mfe/basket';
import { on } from '@peas/event-bus';
import React, { Component, FC, FunctionComponent, useEffect } from 'react';
import { updateBasketWithUsuals } from '#/experiments/oop-1426/actions/trolley-action-creators';
import { getOnDemandDynamicDeliveryTime } from '#/selectors/slot';

export const emitChangeSlotAnalyticsEvent = (): void =>
  basicEvent(analyticsBus, {
    type: SIDEBAR,
    value: CHANGE_SLOT,
    action: DELAY,
  });

export const emitBookSlotAnalyticsEvent = (): void =>
  basicEvent(analyticsBus, {
    type: SIDEBAR,
    value: BOOK_A_SLOT,
    action: DELAY,
  });

export const emitMissedOfferAnalyticsEvent = (): void =>
  basicEvent(analyticsBus, {
    type: 'missed offers',
    value: 'side basket',
    action: 'delay',
  });

export const emitBasketLogoAnalyticsEvent = (): void =>
  basicEvent(analyticsBus, {
    type: BASKET_LOGO,
    value: VIEW_BASKET,
    action: DELAY,
  });

export const emitCheckoutButtonAnalyticsEvent = (): void =>
  basicEvent(analyticsBus, {
    type: SIDEBAR,
    value: CHECKOUT,
    action: DELAY,
  });

export const emitFullBasketButtonAnalyticsEvent = (): void =>
  basicEvent(analyticsBus, {
    type: SIDEBAR,
    value: VIEW_BASKET,
    action: DELAY,
  });

export const emitBasketShopFavouritesAnalyticsEvent = (): void =>
  basicEvent(analyticsBus, {
    type: SIDEBAR,
    value: SHOP_FAVOURITES,
    action: DELAY,
  });

export const emitBasketAddUsualsAnalyticsEvent = (): void =>
  basicEvent(analyticsBus, {
    type: SIDEBAR,
    value: ADD_USUALS,
    action: DELAY,
  });

export const emitBasketShopUsualsAnalyticsEvent = (): void =>
  basicEvent(analyticsBus, {
    type: SIDEBAR,
    value: SHOP_USUALS,
    action: DELAY,
  });

export const emitBasketPreviouslyBoughtAnalyticsEvent = (): void =>
  basicEvent(analyticsBus, {
    type: SIDEBAR,
    value: SHOP_PREVIOUSLY_BOUGHT,
    action: DELAY,
  });

const getAllBasketsItems = (state: Store): Item[] =>
  getBaskets(state).reduce<Item[]>((acc, basket) => [...acc, ...basket.items], []);

export const mapStateToProps = (state: Store, { c: config }: { c: (k: string) => boolean }): BasketState => {
  const trolley = state.trolley;
  const slot = getSlot(state);
  const updatingCatchWeightItem = getUpdatingCatchWeightItem(state);
  const amountChange = updatingCatchWeightItem
    ? getQuantity(updatingCatchWeightItem)
    : getTrolleyStateAndPendingQty(state).pendingQty;

  const items = getAllBasketsItems(state);
  const basket = getGroceryBasket(state);
  const currencyIso = getCurrency(state).isoCode;
  const currencyLocale = getCurrencyLocale(currencyIso, getLanguage(state));
  let { unit, min, max } = getOnDemandSlot(state);
  if (!unit) {
    const dynamicDeliveryDetails = getOnDemandDynamicDeliveryTime(state);
    unit = dynamicDeliveryDetails.unit;
    min = dynamicDeliveryDetails.range?.min;
    max = dynamicDeliveryDetails.range?.max;
  }

  const splitViewBaskets = (getBaskets(state) as unknown) as SplitViewBasket;
  return {
    loading: trolley.isUpdating,
    loadingMarkeplaceBasket: trolley.isMarketplaceUpdating,
    loadingGroceryBasket: trolley.isGroceryUpdating,
    orders: getPendingOrders(state),
    isFirstTimeShopper: getIsFirstTimeShopper(state),
    onDemandSlot: {
      unit: unit,
      min: min,
      max: max,
    },
    basket: {
      splitView: splitViewBaskets,
      isAddingUsuals: trolley.isAddingUsuals,
      shoppingMethod: basket.shoppingMethod,
      discounts: basket.discounts,
      charges: {
        minimum: basket.charges?.minimumBasketValue || 0,
        surcharge: basket.charges?.surcharge || 0,
        depositCharge: basket.charges?.depositCharge || 0,
      },
      orderId: getAnalyticsOrderId(state) || '',
      constraints: basket.constraints || { maxItemCount: 0 },
      guidePrice: trolley.trolley.guidePrice,
      totalPrice: basket.totalPrice,
      slot: slot as Slot,
      previousSlot: getPreviousSlot(state) as Slot,
      localisation: {
        currency: {
          iso: currencyIso,
          locale: currencyLocale,
        },
      },
      isAmendBasket: getIsAmendBasket(state),
      totalAmountChanging: amountChange,
      issues: {
        exceptions: (basket.issues?.exceptions || []).map(exception => ({
          level: 'warning',
          reason: exception.reason,
        })),
      },

      items: items.map<MFEItem>(item => {
        const product = item.product;

        const converted: MFEItem = {
          id: product.id,
          charges: item.charges!,
          quantity: item.quantity!,
          cost: item.cost!,
          isLoading: getIsTrolleyItemUpdating(state, getProductId(item)),
          weight: item.weight!,
          unit: getUnit(item),
          isNewlyAdded: item.isNewlyAdded!,
          groupBulkBuyLimitReached: item.groupLimitReached || false,
          // This is not used by the shared basket component, it's only used within the MFE specific hooks
          // and is not stable after hydration, causing a rerender. rather than execute more code to get
          // LEGO's version of what this should be, we just hard-code it to 0 so no updates are caused.
          groupBulkBuyQuantity: 0,
          bulkBuyLimitReached: atMaxQty(item) || false,
          product: {
            // @ts-ignore TODO: add typename to mfe basket type
            __typename: product.typename,
            id: product.id,
            title: product.title,
            defaultImageUrl: product.defaultImageUrl!,
            bulkBuyLimit: product.bulkBuyLimit,
            bulkBuyLimitGroupId: product.bulkBuyLimitGroupId!,
            groupBulkBuyLimit: product.groupBulkBuyLimit!,
            averageWeight: product.averageWeight!,
            productType: product.productType!,
            restrictions: (product.restrictions || []).map(restriction => ({
              isViolated: restriction.isViolated!,
              message: restriction.message!,
              shortMessage: restriction.shortMessage!,
              type: restriction.type!,
            })),
            isForSale: product.isForSale,
            status: product.status!,
            promotions: (item.promotions || []).map(promotion => ({
              description: promotion.offerText!,
              missed: promotion.missedPromotion!,
              id: promotion.promotionId!,
              promotionType: promotion.promotionType!,
              timesTriggered: promotion.timesTriggered!,
              warnings: promotion.warnings
                ? {
                    threshold: promotion.warnings?.threshold,
                    type: promotion.warnings?.warningType,
                  }
                : undefined,
              attributes: promotion.attributes!,
            })),
            price: {
              actual: product.price as number,
              unitPrice: product.unitPrice,
              unitOfMeasure: product.unitOfMeasure,
            },
          },
        };

        return converted;
      }),
    },
  };
};

const createUpdateQuantity = (quantity: number, dispatch: Dispatch, c: (key: string) => boolean) => (
  itemId: string,
): void => {
  dispatch((thunkDispatch: Dispatch, getState: GetStore) => {
    const legoItem = getTrolleyItem(getState(), itemId)!;
    thunkDispatch(
      incrementItemBy(quantity, legoItem, {
        identifier: 'Side Basket',
        enableMissedOfferTracking: c('enableMissedOfferTrackingInAnalytics'),
      }),
    );
  });
};

export interface BasketActions {
  incrementItem: (itemId: string) => void;
  decrementItem: (itemId: string) => void;
  removeItem: (itemId: string) => void;
  addUsualsToBasket: () => void;
  error?: Error;
}

const mapDispatchToProps = (dispatch: Dispatch, { c }: { c: (k: string) => boolean }): BasketActions => ({
  removeItem: (itemId: string): void => {
    dispatch((thunkDispatch: Dispatch, getState: GetStore) => {
      const legoItem = getTrolleyItem(getState(), itemId)!;
      thunkDispatch(removeItem(legoItem, { identifier: 'Side Basket' }));
    });
  },

  incrementItem: createUpdateQuantity(1, dispatch, c),

  decrementItem: createUpdateQuantity(-1, dispatch, c),

  addUsualsToBasket: (): void => {
    dispatch((thunkDispatch: Dispatch, getState: GetStore) => {
      updateBasketWithUsuals(thunkDispatch, getState());
    });
    emitBasketAddUsualsAnalyticsEvent();
  },
});

const AnchorLink: FC<Record<string, unknown>> = ({ children, href, ...props }) => {
  return (
    <LinkCheckSpa to={href} {...props}>
      {children}
    </LinkCheckSpa>
  );
};

const components: Components = {
  anchor: AnchorLink as FC<JSX.IntrinsicElements['a']>,
};

export const UnconnectedMiniTrolleyProvider: FunctionComponent<BasketState & BasketActions> = props => {
  const { children, ...value } = props;

  useEffect(() => {
    const unsubs: Array<() => void> = [];
    unsubs.push(on(INCREMENT_ITEM_EVENT, props.incrementItem));
    unsubs.push(on(DECREMENT_ITEM_EVENT, props.decrementItem));
    unsubs.push(on(REMOVE_ITEM_EVENT, props.removeItem));

    return (): void => {
      unsubs.forEach(x => x());
    };
  }, [props]);

  useEffect(() => {
    const unsubs: Array<() => void> = [];
    unsubs.push(on(ADD_USUALS_EVENT, props.addUsualsToBasket));
    unsubs.push(on(MISSED_OFFER_ANALYTICS_EVENT, emitMissedOfferAnalyticsEvent));
    unsubs.push(on(BASKET_LOGO_ANALYTICS_EVENT, emitBasketLogoAnalyticsEvent));
    unsubs.push(on(CHECKOUT_BUTTON_ANALYTICS_EVENT, emitCheckoutButtonAnalyticsEvent));
    unsubs.push(on(FULL_BASKET_BUTTON_ANALYTICS_EVENT, emitFullBasketButtonAnalyticsEvent));
    unsubs.push(on(CHANGE_SLOT_ANALYTICS_EVENT, emitChangeSlotAnalyticsEvent));
    unsubs.push(on(BOOK_SLOT_ANALYTICS_EVENT, emitBookSlotAnalyticsEvent));
    unsubs.push(on(SHOP_FAVOURITES_BUTTON_ANALYTICS_EVENT, emitBasketShopFavouritesAnalyticsEvent));
    unsubs.push(on(SHOP_USUALS_EVENT, emitBasketShopUsualsAnalyticsEvent));
    unsubs.push(on(PREVIOUSLY_BOUGHT_EVENT, emitBasketPreviouslyBoughtAnalyticsEvent));

    return (): void => {
      unsubs.forEach(x => x());
    };
  }, []);

  return (
    <ComponentContext.Provider value={components}>
      <BasketContext.Provider value={value}>{children}</BasketContext.Provider>
    </ComponentContext.Provider>
  );
};

export const MiniTrolleyProvider: Component<BasketState & BasketActions> = helpers(['c', 'f', 'l', 't'])(
  connect(mapStateToProps, mapDispatchToProps)(UnconnectedMiniTrolleyProvider),
);
