import React from 'react';
import Link from '#/components/link-check-spa';
import { StyledSPALinkComponent } from '#/components/slots/option-tabs/header/styled';

type TClickableTabProps = {
  isActive?: boolean;
  showVariant?: boolean;
};

const ClickableTab: React.FC<TClickableTabProps> = ({ isActive, showVariant, ...props }) => {
  if (showVariant) {
    return <StyledSPALinkComponent isActive={isActive} {...props} />;
  }

  return <Link {...props} />;
};

export default ClickableTab;
