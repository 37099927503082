import React from 'react';
import PropTypes from 'prop-types';
import helpers from '../../../../lib/decorators/helpers';

const ResultTitle = props => {
  const HeadingTag = props.headingTag;
  const {
    headingTextMessage,
    headingSubTextMessage,
    headingTextQuery,
    headingSubTextQuery
  } = props;
  const getDisplayTextMessage = (message, query) => {
    return query
      ? props.t(message, {
          query
        })
      : props.t(message);
  };

  return (
    <div>
      <HeadingTag className="heading query">
        {getDisplayTextMessage(headingTextMessage, headingTextQuery)}
      </HeadingTag>
      <p className="results-title">
        {getDisplayTextMessage(headingSubTextMessage, headingSubTextQuery)}
      </p>
    </div>
  );
};

ResultTitle.propTypes = {
  headingSubTextMessage: PropTypes.string.isRequired,
  headingSubTextQuery: PropTypes.string,
  headingTag: PropTypes.string,
  headingTextMessage: PropTypes.string.isRequired,
  headingTextQuery: PropTypes.string,
  t: PropTypes.func.isRequired
};

export default helpers(['t'])(ResultTitle);
