import {
  MEALS_AND_RECIPE_MENU_KEY,
  MEALS_AND_RECIPES_MENU_GHS,
  MEALS_AND_RECIPES_MENU_DEMAND,
  Menu,
  RECIPE_MENU_KEY,
} from '../constants';
import { isOnDemandShoppingMethod } from '#/lib/shopping-method-util';

export type Nav = {
  label: string;
  url: string;
  chevron?: boolean;
  activate?: string;
  feature?: string;
  cmsNav?: boolean;
};

export interface BigNav {
  groceries?: Nav;
  myProducts: Nav;
  promotions: Nav;
  events: Nav;
  mealsAndRecipes: Nav;
  recipes: Nav;
}

export const getIsMealsAndRecipeMenuKey = (key: string): boolean => key === MEALS_AND_RECIPE_MENU_KEY;

export const getIsRecipeMenuKey = (key: string): boolean => key === RECIPE_MENU_KEY;

export const getIsMealsAndRecipeMenuOpen = ({ ui: { menuOpen } }: Store): boolean => {
  return menuOpen === MEALS_AND_RECIPE_MENU_KEY;
};

export const getMealsAndReceipeMenu = (state: Store): Menu => {
  const shoppingMethod = state?.trolley?.shoppingMethod;
  if (isOnDemandShoppingMethod(shoppingMethod)) {
    return MEALS_AND_RECIPES_MENU_DEMAND;
  }
  return MEALS_AND_RECIPES_MENU_GHS;
};

export const updateMealsRecipeTab = (tabs: BigNav, isMealsAndRecipeEnabled: boolean): void => {
  const menuKeyToDelete = isMealsAndRecipeEnabled ? RECIPE_MENU_KEY : MEALS_AND_RECIPE_MENU_KEY;
  delete tabs[menuKeyToDelete];
};
