import { Request, Response } from 'express';
import { getFeatureConfigurationFromReqRes } from '#/lib/optimizely-manager';
import {
  DietaryInfoFeatureConfig,
  productTileDietaryInfo,
  productTileDietaryInfoVariants,
} from '#/experiments/oop-1922/constants';

const { featureKey, useCustomerId, serviceConfig } = productTileDietaryInfo;

export const getDietaryInfoFeatureConfig = (req: Request, res: Response): DietaryInfoFeatureConfig =>
  getFeatureConfigurationFromReqRes(req, res, featureKey, {
    useCustomerId,
    serviceConfig,
  });

export const isDietaryInfoEnabled = (variant: productTileDietaryInfoVariants): boolean =>
  variant === productTileDietaryInfoVariants.dietaryTags ||
  variant === productTileDietaryInfoVariants.dietaryIcon ||
  variant === productTileDietaryInfoVariants.dietaryLogo;

export const getIsDietaryInfoFeatureEnabled = (req: Request, res: Response): boolean => {
  const { productTileUuidConfig: variant } = getDietaryInfoFeatureConfig(req, res);
  return isDietaryInfoEnabled(variant);
};

export const getShouldShowProductTileDietaryInfoTags = (variant: productTileDietaryInfoVariants): boolean =>
  variant === productTileDietaryInfoVariants.dietaryTags;

export const getShouldShowProductTileDietaryInfoIcon = (variant: productTileDietaryInfoVariants): boolean =>
  variant === productTileDietaryInfoVariants.dietaryIcon;

export const getShouldShowProductTileDietaryInfoLogo = (variant: productTileDietaryInfoVariants): boolean =>
  variant === productTileDietaryInfoVariants.dietaryLogo;

export const getShouldShowDefaultProductTile = (variant: productTileDietaryInfoVariants): boolean =>
  variant === productTileDietaryInfoVariants.default;

export const showDietaryIcons = (includeDietaryIcons = ''): boolean => /^true$/i.test(includeDietaryIcons);
