import {
  UPDATE_EXTERNAL_VALIDATION_STATE,
  UPDATE_VALIDATION_STATE,
  UPDATE_VALIDATION_STATE_BY_FIELD,
} from '#/constants/action-types';
import { Dispatch, GetStore } from '#/custom-typings/redux-store/common';
import {
  FormAction,
  UpdateFormExternalValidation,
  UpdateFormValidation,
  UpdateFormValidationByField,
} from '#/custom-typings/redux-store/form.actions.defs';
import { Fields } from '#/custom-typings/redux-store/form.defs';
import { evaluateFormValidationState, FormValidators, hasValidationFailed } from '#/lib/validation/form-validation';

export const updateFormValidationState = (
  formId: string,
  payload?: UpdateFormValidation['value']['payload'],
): UpdateFormValidation => ({
  type: UPDATE_VALIDATION_STATE,
  value: {
    formId,
    payload,
  },
});

export const updateValidationStateByField = (
  formId: string,
  fieldName: string,
  payload: UpdateFormValidationByField['value']['payload'],
): UpdateFormValidationByField => ({
  type: UPDATE_VALIDATION_STATE_BY_FIELD,
  value: {
    formId,
    fieldName,
    payload,
  },
});

export const updateExternalValidationState = (
  formId: string,
  externalErrors: UpdateFormExternalValidation['value']['externalErrors'],
): UpdateFormExternalValidation => ({
  type: UPDATE_EXTERNAL_VALIDATION_STATE,
  value: {
    formId,
    externalErrors,
  },
});

export function getFormStateValues(formState: Fields): Record<string, string | undefined> {
  return Object.keys(formState).reduce<Record<string, string | undefined>>((accm, key) => {
    accm[key] = (formState[key] || {}).value;
    return accm;
  }, {});
}

export const validateFormOnSubmit = (formId: string, validators: FormValidators) => (
  dispatch: Dispatch<FormAction>,
  getState: GetStore,
): boolean => {
  const { form } = getState();
  const formState = form[formId] || {};
  const formStateValues = getFormStateValues(formState);
  const nextFormValidationState = evaluateFormValidationState(validators, formStateValues);
  dispatch(updateFormValidationState(formId, nextFormValidationState)); //update state in reducer

  return hasValidationFailed(nextFormValidationState);
};
