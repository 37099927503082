import React from 'react';
import PropTypes from 'prop-types';
import SectionDivider from '#/components/home/section-divider-widget';
import ProductList from '#/components/products/product-list';
import { PAGE_IDENTIFIER } from '#/analytics/constants';

const PromotionList = ({
  isAmendBasket,
  items,
  listLink,
  listTitle,
  userRegistered,
  productTileVariant
}) => (
  <div className="promotion-list">
    <div className="promotion-list--section-divider">
      <SectionDivider title={listTitle} link={listLink} />
    </div>
    <div className="promotion-list--product-list">
      <ProductList
        productTileVariant={productTileVariant}
        isGrid
        identifier={PAGE_IDENTIFIER}
        tileType="flexi"
        items={items}
        isAmendBasket={isAmendBasket}
        listData={{}}
        triggerAnalyticsEvent={false}
        userRegistered={userRegistered}
      />
    </div>
  </div>
);

PromotionList.propTypes = {
  isAmendBasket: PropTypes.bool.isRequired,
  items: PropTypes.object.isRequired,
  listLink: PropTypes.string,
  listTitle: PropTypes.string.isRequired,
  productTileVariant: PropTypes.string,
  userRegistered: PropTypes.bool
};

export default PromotionList;
