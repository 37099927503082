import resourceSignatures from '#/resource-signatures';
import {
  getResources,
  getResourceRequestTimestamps
} from '#/selectors/resources';
import { request } from '#/lib/client-fetch';
import stringify from './stringify';
import { cyrb53 } from './hashing';

export function getResourceSignature(type) {
  const signature = resourceSignatures[type];

  if (!signature) {
    throw new ReferenceError(`The resource of type '${type}' is invalid.`);
  }

  return signature;
}

export function filterParams(type, sharedParams = {}) {
  const { params = [] } = getResourceSignature(type);

  return params.reduce((filteredParams, key) => {
    if (typeof sharedParams[key] !== 'undefined') {
      filteredParams[key] = sharedParams[key];
    }

    return filteredParams;
  }, {});
}

export function getResourceHash(type, params, cachingCriteria = '') {
  const str = stringify({
    type,
    params,
    cachingCriteria
  });

  return cyrb53(str);
}

export function expandResourcesList(
  resourceTypes,
  sharedParams = {},
  cachingCriteria
) {
  return resourceTypes.map(function(type) {
    const params = filterParams(type, sharedParams);

    //so ugly that this is here.
    const hash = getResourceHash(type, params, cachingCriteria);

    return {
      type,
      params,
      hash
    };
  });
}

export function filterLoadedResources(resources, state) {
  const resourcesState = getResources(state);

  return resources.filter(function(resource) {
    const { type, hash } = resource;
    const { cacheable } = getResourceSignature(type);
    const existingResource = resourcesState[type];

    if (cacheable === false) {
      return true;
    } else if (existingResource && existingResource.hash === hash) {
      return false;
    }

    return true;
  });
}

export function getResourcesAndDependencies(resources, sharedParams) {
  const addedResources = {};
  const output = [];

  function gatherDependencies(resource) {
    const { type } = resource;

    if (addedResources[type]) {
      return;
    }

    const { dependencies = [] } = getResourceSignature(type);
    const expandedDependencies = expandResourcesList(
      dependencies,
      sharedParams
    );

    expandedDependencies.forEach(function(dependency) {
      gatherDependencies(dependency);
    });

    if (!addedResources[type]) {
      addedResources[type] = true;
      output.push(resource);
    }
  }

  resources.forEach(function(resource) {
    gatherDependencies(resource);
  });

  return output;
}

export function getRequiredResources(resources, state, cacheCriteria) {
  if (state) {
    resources = filterLoadedResources(resources, state, cacheCriteria);
  }

  return resources;
}

export async function requestResources(resources, sharedParams, options) {
  const { acceptWaitingRoom, endpoint, requiresAuthentication } = options;
  const requestedAt = Date.now();

  return request
    .post(endpoint, {
      headers: {
        'x-queueit-ajaxpageurl': acceptWaitingRoom
      },
      body: JSON.stringify({
        resources,
        sharedParams,
        requiresAuthentication
      })
    })
    .then(function(response) {
      const receivedAt = Date.now();
      const receivedResources = Object.keys(response);

      receivedResources.forEach(function(type) {
        response[type].requestedAt = requestedAt;
        response[type].receivedAt = receivedAt;
      });

      return response;
    });
}

export function shouldUpdate(getState, newResourceData) {
  const requestTimestamps = getResourceRequestTimestamps(getState()) || {};
  const resourceNames = Object.keys(newResourceData);

  return resourceNames.every(function(type) {
    const { requestedAt, receivedAt } = newResourceData[type];
    const resourceTimestamps = requestTimestamps[type];

    if (!resourceTimestamps) {
      return true;
    }

    if (!resourceTimestamps.requestedAt) {
      if (!resourceTimestamps.receivedAt) {
        return true;
      }

      return receivedAt >= resourceTimestamps.receivedAt;
    }

    return requestedAt >= resourceTimestamps.requestedAt;
  });
}

export function handleResources(
  dispatch,
  getState,
  resourceNames,
  options = {}
) {
  resourceNames.forEach(resName => {
    const { handler } = getResourceSignature(resName);

    if (handler) {
      handler(dispatch, getState, options);
    }
  });
}
