import { ContentData, DisplayArea, RenderedContentOp, RenderedContentOpContent } from '../../types';
import {
  getComponentName,
  ComponentNamePayload,
  componentId,
  ComponentIdPayload,
  getComponentType,
  getCampaignTitle,
  ComponentTypePayload,
  DisplayAreaPayload,
  getPageType,
  PageTypePayload,
  segment,
  displayArea,
  getComponentNameByType,
} from '../misc';
import { productData, ProductDataPayload } from '../products';
import { PlainObject } from '#/types';

export interface ContentDataPayload {
  campaignId?: string;
  campaignTitle?: string;
  contentFlag?: string;
  contentSegment?: PlainObject;
  posInModule?: number;
  product?: ProductDataPayload;
  ruleId?: string;
  trackingId?: string;
}

export function contentData(payload: ContentDataPayload, index = 0): ContentData {
  const { campaignId, campaignTitle, contentSegment, posInModule = index + 1, product, ruleId, trackingId } = payload;
  return {
    campaignId: trackingId || campaignId,
    campaignTitle: getCampaignTitle(campaignTitle),
    contentFlag: trackingId ? '1' : '0',
    contentSegment: contentSegment && segment(contentSegment),
    posInModule,
    product: productData(product, ''),
    ruleId,
  };
}

export interface RenderedContentOpContentPayload
  extends ComponentNamePayload,
    ComponentIdPayload,
    ComponentTypePayload,
    DisplayAreaPayload,
    PageTypePayload {
  displayArea?: DisplayArea;
  modulePosition?: number;
  panel?: ContentDataPayload[];
}

export function renderedContentOpContent(
  payload: RenderedContentOpContentPayload,
  index = 0,
): RenderedContentOpContent | undefined {
  const { modulePosition = index + 1, panel = [] } = payload;

  const formattedPanel = panel.map((data, index) => contentData(data, index));

  if (!formattedPanel.length) {
    return;
  }

  const componentType = getComponentType(payload);
  const componentName = getComponentName(payload) || getComponentNameByType(componentType);

  return {
    componentName,
    componentId: componentId(payload),
    componentType,
    displayArea: displayArea(payload),
    modulePosition,
    pageType: getPageType(payload),
    panel: formattedPanel,
  };
}

export interface RenderedContentOpPayload {
  content?: RenderedContentOpContentPayload[];
  itemsPerPage?: number;
  media?: {
    catalogueCountry: string;
  };
  pageNumber?: number;
  resultsCount?: number;
  resultCountForPage?: number;
}

export function renderedContentOp(payload: RenderedContentOpPayload): RenderedContentOp | undefined {
  const { content = [], itemsPerPage = 0, media, pageNumber = 1, resultsCount = 0, resultCountForPage = 0 } = payload;
  const calculatedItemsPerPage = Math.max(itemsPerPage, resultCountForPage);
  const calculatedResultsCount = Math.max(resultsCount, resultCountForPage);
  let itemsRangeStart = 0;
  let itemsRangeEnd = 0;

  if (resultCountForPage) {
    itemsRangeStart = calculatedItemsPerPage * (pageNumber - 1) + 1;
    itemsRangeEnd = Math.min(calculatedItemsPerPage * pageNumber, calculatedResultsCount);
  }

  const formattedContent = content
    .map((data, index) => renderedContentOpContent(data, index))
    .filter(element => !!element);

  if (!formattedContent.length) {
    return;
  }

  return {
    content: formattedContent as RenderedContentOpContent[],
    itemsRangeStart,
    itemsRangeEnd,
    media,
    listSize: calculatedResultsCount,
    pageNumber,
    resultCountForPage: itemsPerPage,
  };
}
