export const SUPERDEPARTMENT = 'superdepartment';
export const DEPARTMENT = 'department';
export const AREA = 'area';
export const AISLE = 'aisle';
export const AISLES = 'aisles';
export const SHELF = 'shelf';
export const POSTFIX_ALL = '::all';
export const SUPERDEPARTMENT_CAMEL_CASE = 'superDepartment';
export const POSTFIX_ALL_PROMOTION = '::allwithpromotion';
export const OFFERS_QUERYSTRING = 'viewAll=promotion&promotion=offers';
