import React from 'react';
import helpers from '#/lib/decorators/helpers';
import { connect } from '#/lib/render/connect-deep-compare';
import { getSelectedShoppingMethod } from '#/reducers/slot';
import { getTrolleyShoppingMethod, hasValidSlot } from '#/selectors/trolley';
import { TConfigFunc, TTranslateFunc } from '#/lib/records/helpers.defs';

type IState = {
  shoppingMethod: string;
};

const mapStateToProps = (state: Store): IState => ({
  shoppingMethod: hasValidSlot(state) ? getTrolleyShoppingMethod(state) : getSelectedShoppingMethod(state),
});

interface TOwnProps extends IState {
  t: TTranslateFunc;
  c: TConfigFunc;
}

const AgeRestrictionMessage: React.FC<TOwnProps> = (props: TOwnProps) => {
  const { t: translate, c: config, shoppingMethod } = props;

  const ageRestrictionTranslationKey = `slots:${shoppingMethod}.over-18-description`;

  const ageRestrictionMessage = translate(ageRestrictionTranslationKey, {
    age: config('customAgeRestriction'),
  });

  return (
    <div className="hidden-large">
      <div className="book-a-slot--info-message">{ageRestrictionMessage}</div>
    </div>
  );
};
export default helpers(['t', 'c'])(connect(mapStateToProps)(AgeRestrictionMessage));
