/**
 * Experiment constants for predictive - popular & trending search
 */

export const predictiveSearch = {
  featureKey: 'GHS-UK_search-predictiveUI',
  variationKey: 'searchTerms',
  useCustomerId: true,
};

export const POPULAR = 'popular';
export const TITLE_POPULAR = 'Popular Searches';
export const TITLE_TRENDING = 'Trending Searches';

//analytics
export const NOW = 'now';
export const PREDICTIVE_NEXT = 'pns';
export const SEARCH_BAR = 'search bar';
export const SEARCH_EVENT_TYPE = 'header';
export const SEARCH_STORAGE_KEY = 'searchInteraction';
