/* eslint-disable @typescript-eslint/no-non-null-assertion */

import {
  AMEND_RESTRICTED_TYPES,
  RDG_RESTRICTIONS,
  RDG_VIOLATIONS,
  RESTRICTED_ORDER_AMENDMENT_WITH_ITEM,
  ROA_RESTRICTIONS,
} from '#/constants/restrictions';
import {
  AllergenInfo,
  CookingInstructions,
  CustomerUnitChoice,
  DailyAmounts,
  Details,
  GuidelineDailyAmount,
  HazardInfo,
  ItemChange,
  NutritionalInfo,
  PackSize,
  Price,
  Product,
  Promotion,
  SellerInfo,
  ProductStarRatingData,
  ProductCharge,
} from '#/lib/records/item.defs';
import { CATCH_WEIGHT_PRODUCTS, DisplayType, QUANTITY, VARIABLE_WEIGHT_PRODUCTS } from '#/constants/display-types';
import {
  EPW_WITHOUT_AMEND_AVAILABILITY_OVERRIDE,
  EPW_WITH_AMEND_AVAILABILITY_OVERRIDE,
  EXCLUDED_PRODUCT,
  PERMANENTLY_UNAVAILABLE,
  TEMPORARY_UNAVAILABLE,
} from '#/constants/unavailable-product-type';
import { ALDI } from '#/constants/competitors';
import { GREY, TESCO_BLUE, YELLOW, BLUE } from '#/constants/colors';
import {
  WEIGHT_UNIT_KG as UNIT_KG,
  QUANTITY_TYPE as UNIT_PCS,
  MARKETPLACE_PRODUCT,
  IGHS_PRODUCT,
  PRODUCT_DEPOSIT_RETURN_CHARGE,
  DEPOSIT_RETURN_CHARGE,
} from '#/constants/common';
import { WEIGHT, QUANTITY_OR_WEIGHT } from '#/constants/display-types';
import { gaussianRound, round } from '#/lib/string-formatting/format';
import { getSlug, getSlugId } from '#/lib/url/url-utils';
import { setNumberOfItems, withCatchWeight } from '#/lib/records/item-utils';

import { isBooked } from '#/selectors/slot/slot-record';
import moment from 'moment';
import { toJS } from '#/utils/immutable-utils';
import { insertSorted } from '#/lib/array-helpers';
import { Item } from '#/lib/records/item';
import { promotionTypes } from '#/constants/promotion-types';
import { TConfigFunc } from '#/lib/records/helpers.defs';
import { tags, promoType } from '#/components/product-tiles/constants';
import { GetFlashSashInfoArgs } from '#/components/products/product-tile/helpers';
import { TFlashSashInfo } from '#/selectors/beans-product-tile/promotions-data';
import { ResultType } from '#/experiments/oop-2021/types';
import { GHS } from '#/analytics/constants';
import { getUpdatedSashListWithProbations } from '#/experiments/oop-2011/selectors/items';

export const isWhyNotTry = (item: Item) => item.isWhyNotTry;

export const isSponsoredProduct = (item: Item) => !!item.isSponsoredProduct;

export const getPromotionId = (promotion: Promotion) => promotion.promotionId;

export const getChangeIdentifer = (change: ItemChange) => change.identifier;

export const getChangeNewUnitChoice = (change: ItemChange) => change.newUnitChoice;

export const getChangeNewValue = (change: ItemChange) => change.newValue;

export const getChangeOldUnitChoice = (change: ItemChange) => change.oldUnitChoice;

export const getChangeOldValue = (change: ItemChange) => change.oldValue;

export const getChangeGridPos = (change: ItemChange) => change.gridPos;

export const getChangePageId = (change: ItemChange) => change.pageId;

export const getChangePosition = (change: ItemChange) => change.position;

export const getChangeSeedProduct = (change: ItemChange) => change.seedProduct;

export const getHazardInfoStatements = (hazardInfo: HazardInfo) => hazardInfo.statements;

export const getHazardInfoSignalWord = (hazardInfo: HazardInfo) => hazardInfo.signalWord;

export const getHazardInfoSymbolCodes = (hazardInfo: HazardInfo) => hazardInfo.symbolCodes;

export const getHazardInfoChemicalName = (hazardInfo: HazardInfo) => hazardInfo.chemicalName;

export const getHazardInfoProductName = (hazardInfo: HazardInfo) => hazardInfo.productName;

export const getGuidelineDailyAmount = (details: Details) => details.guidelineDailyAmount;

export const getIngredientsInfo = (details: Details) => details.ingredients;

export const getPackSize = (details: Details) => details.packSize;

export const getAllergenInfo = (details: Details) => details.allergenInfo;

export const getNutritionInfo = (details: Details) => details.nutritionInfo;

export const getProductGda = (details: Details) => details.gda;

export const getDailyAmounts = (guideLineDailyAmounts: GuidelineDailyAmount) => guideLineDailyAmounts.dailyAmounts;

export const getGuidelineDailyAmountTitle = (guideLineDailyAmounts: GuidelineDailyAmount) =>
  guideLineDailyAmounts.title;

export const getDailyAmountName = (dailyAmounts: DailyAmounts) => dailyAmounts.name;

export const getDailyAmountRating = (dailyAmounts: DailyAmounts) => dailyAmounts.rating;

export const getDailyAmountValue = (dailyAmounts: DailyAmounts) => dailyAmounts.value;

export const getDailyAmountPercent = (dailyAmounts: DailyAmounts) => dailyAmounts.percent;

export const getPackSizeValue = (packSize: PackSize) => packSize.value;

export const getPackSizeUnits = (packSize: PackSize) => packSize.units;

export const getCookingInstructions = (cookingInstructions: CookingInstructions) => cookingInstructions.instructions;

export const getCookingInstructionName = (cookingInstructions: CookingInstructions) => cookingInstructions.name;

export const getAllergenInfoName = (allergenInfo: AllergenInfo) => allergenInfo.name;

export const getAllergenInfoValues = (allergenInfo: AllergenInfo) => allergenInfo.values;

export const getNutritionalInfoItemServing = (nutritionalInfo: NutritionalInfo) => nutritionalInfo.itemServing;

export const getNutritionalInfoPerComp = (nutritionalInfo: NutritionalInfo) => nutritionalInfo.perComp;

export const getNutritionalInfoName = (nutritionalInfo: NutritionalInfo) => nutritionalInfo.name;

export const getAverageWeight = (product: Product) => product.averageWeight;

export const getUnitPrice = (product: Product) => product.unitPrice;

export const getMaxWeight = (product: Product) => product.maxWeight;

export const getStatus = (product: Product) => product.status;

export const getIsForSale = (product: Product) => product.isForSale;

export const getIsDiscontinued = (product: Product) => product.status === PERMANENTLY_UNAVAILABLE;

export const getId = (item: Item | Product) => item.id;

export const getItemIsSubstitute = (item: Item) => item.isSubstitute;

export const getProductSubstitutions = (item: Item) => getProduct(item).substitutions || [];

export const getPromotions = (item: Item) => item.promotions;

export const getProductStatus = (item: Item) => getProduct(item).status;

export const getSeedProduct = (item: Item) => item.seedProduct;

export const getName = (item: Item) => item.name;

export const getGroupLimitReached = (item: Item) => item.groupLimitReached;

export const getGroupName = (item: Item) => item.groupName;

export const getOriginalCatchWeight = (item: Item) => item.originalCatchWeight;

export const getCatchWeight = (item: Item) => item.catchWeight;

export const getQuantity = (item: Item) => item.quantity!;

export const getStockAvailableQuantity = (item: Item) => getProduct(item).availability?.quantity;

export const getNappySize = (item: Item) => item.nappySize;

export const getCustomerUnitChoice = (item: Item) => item.customerUnitChoice;

export const getRestrictionDeliveryGroups = (item: Item) => item.restrictionDeliveryGroups;

export const getProduct = (item: Item) => item.product || {};

export const getProductId = (item: Item) => getProduct(item).id;

export const getOriginalCustomerUnitChoice = (item: Item) => item.originalCustomerUnitChoice;

export const getOriginalProductWeight = (item: Item) => item.originalProductWeight;

export const getOriginalQuantity = (item: Item) => item.originalQuantity;

export const getProductBaseProductId = (item: Item): string => getProduct(item).baseProductId;

export const getProductBulkBuyLimitGroupId = (item: Item) => getProduct(item).bulkBuyLimitGroupId;

export const getProductDescription = (item: Item) => getProduct(item).description;

export const getProductTitle = (item: Item) => getProduct(item).title;

export const getProductAverageWeight = (item: Item) => getProduct(item).averageWeight;

export const getProductBrandName = (item: Item) => getProduct(item).brandName;

export const getProductDetails = (item: Item) => getProduct(item).details;

export const getProductBulkBuyLimit = (item: Item) => getProduct(item).bulkBuyLimit;

export const getProductShelfId = (item: Item) => getProduct(item).shelfId;

export const getProductShelfName = (item: Item) => getProduct(item).shelfName;

export const getProductShelfLife = (item: Item) => getProduct(item).shelfLife;

export const getProductAisleName = (item: Item) => getProduct(item).aisleName;

export const getProductAisleId = (item: Item) => getProduct(item).aisleId;

export const getProductDepartmentName = (item: Item) => getProduct(item).departmentName;

export const getProductSuperDepartmentName = (item: Item) => getProduct(item).superDepartmentName;

export const getProductUnitOfMeasure = (item: Item) => getProduct(item).unitOfMeasure;

export const getProductDepositAmount = (item: Item) => getProduct(item).depositAmount;

export const getProductUnitPrice = (item: Item) => getProduct(item).unitPrice;

export const getProductDisplayType = (item: Item) => getProduct(item).displayType;

export const getProductDefaultImageUrl = (item: Item) => getProduct(item).defaultImageUrl;

export const getProductDisplayImages = (item: Item) => {
  if (Array.isArray(getProduct(item).images)) return undefined;

  // @ts-ignore
  return getProduct(item).images?.display;
};

export const getProductIsForSale = (item: Item) => item?.product?.isForSale;

// ============ Simple getter =====================

export const getSubstitutionOption = (item: Item) => item.substitutionOption;

export const getIsSubstitutionBlocked = (item: Item) => item.substitutionOptions?.isBlocked || false;

export const getPickerNote = (item: Item) => item.pickerNote;

export const getProductIsNew = (item: Item) => getProduct(item).isNew;

export const getProductPrice = (item: Item) => getProduct(item).price as number;

export const isInBasket = (item: Item) => Boolean(item.quantity!);

export const getItemLimitReached = (item: Item) => item.itemLimitReached;

export const atMinQty = (item: Item) => item.quantity! === 0;

export const getIsSubstitute = (item: Item) => item.isSubstitute;

export const hasPromotion = (item: Item) => item.promotions && !!item.promotions.length;

export const isClubcardPromotionOnItem = (item: Item): boolean =>
  item.promotions.some(promo => isClubcardPromotion(promo));

export const isClubcardPromotion = (promotion: Promotion): boolean =>
  promotion.attributes?.includes('CLUBCARD_PRICING') || promotion.promotionType === promotionTypes.CLUBCARD;

export const showClubcardPriceInPromotionMessages = (promotion: Promotion, config: TConfigFunc): boolean =>
  !!(isClubcardPromotion(promotion) && config('showClubcardPriceInPromotions'));

export const hasMissedPromotion = (item: Item) => item.promotions.some(promotion => promotion.missedPromotion);

export const getAllMissedPromotions = (item: Item) => item.promotions.filter(promotion => promotion.missedPromotion);

export const getSeedProductId = (item: Item) => item.seedProduct?.id;

export const hasToggle = (item: Item) => getProduct(item).displayType === QUANTITY_OR_WEIGHT;

export const isWeightOnly = (item: Item) => getProduct(item).displayType === WEIGHT;

export const isVariableWeightProduct = (item: Item) => getProduct(item).productType === VARIABLE_WEIGHT_PRODUCTS;

export const isAldiPriceMatch = (item: Item): boolean => {
  return (
    item.product?.details?.components?.some(
      component =>
        component &&
        component.competitors?.some(competitor => competitor.id === ALDI && competitor.priceMatch.isMatching),
    ) || false
  );
};

export const isLowEverydayPricing = (item: Item): boolean => {
  return item.product?.details?.components?.some(component => component && component.isLowEverydayPricing) || false;
};

const originalQty = (item: Item) =>
  item.customerUnitChoice === UNIT_KG ? item.originalWeight! : item.originalQuantity!;

export const atRestrictedMaxQty = (item: Item) => item.quantity! >= originalQty(item);

export const hasGroupBulkBuyLimit = (item: Item) => !!getProduct(item).groupBulkBuyLimit;

export const isInFavourites = (item: Item) => !!getProduct(item).isInFavourites;

const getProductReview = (item: Item) => getProduct(item).reviews;

export const getProductImages = (item: Item) => getProduct(item).images;

export const hasSubstitution = (item: Item) => {
  const substitutions = getProductSubstitutions(item);

  return substitutions.length > 0;
};

export const getIsNewlyAdded = (item: Item) => item.isNewlyAdded;

export const getProductMultiPackDetails = (item: Item) => getProduct(item).multiPackDetails;

export const metPromotion = (item: Item) => item.promotions.some(promotion => promotion.missedPromotion === false);

export const getUnit = (item: Item): CustomerUnitChoice => item?.customerUnitChoice || UNIT_PCS;

export const getAdId = (item: Item) => getProduct(item)?.adId;

// BaseProduct = TPNB
export const getBaseProductId = (item: Item): string =>
  getProductBaseProductId(item) ? getProductBaseProductId(item) : getProductId(item);

export const getSashData = (item: Item) => {
  // TODO: Add isSponsoredProduct after https://github.dev.global.tesco.org/Online-Technology/lego-web/pull/12488 is merged to master
  if (!getProductIsForSale(item)) {
    return null;
  }

  if (isClubcardPromotionOnItem(item)) {
    return {
      textKey: 'product-tile:clubcard-price',
      displayNew: false,
      type: promoType.clubcardPrice,
    };
  }

  if (item.isWhyNotTry) {
    return {
      textKey: 'product-tile:why-not-try',
      color: GREY,
      displayNew: false,
      type: promoType.whyNotTry,
    };
  }

  if (hasPromotion(item)) {
    return {
      textKey: 'promotions:offer-flash',
      color: YELLOW,
      displayNew: false,
      type: promoType.offer,
    };
  }

  if (getProductIsNew(item)) {
    return {
      textKey: 'sort-and-filter:new',
      color: TESCO_BLUE,
      displayNew: false,
      type: promoType.newProduct,
    };
  }

  return null;
};

const DECIMAL_PLACES = 2;

export const getCostByMaxWeight = (item: Item) => {
  const product = item.product;
  const maxWeightPrice = product.unitPrice * (product.maxWeight || 0);

  return gaussianRound(item.quantity! * maxWeightPrice, DECIMAL_PLACES);
};

export const getCostByActualPrice = (item: Item) => {
  const product = item.product;
  const actualPrice = (product.price as Price)?.actual || (product.price as number);
  return gaussianRound(item.quantity! * actualPrice, DECIMAL_PLACES);
};

export const getCostByAverageWeight = (item: Item) => {
  const product = item.product;
  const unitPrice = (product.price as Price)?.unitPrice || product.unitPrice;

  return gaussianRound(item.quantity! * unitPrice * product.averageWeight!, DECIMAL_PLACES);
};

export const getCostByUnitPrice = (item: Item) => {
  return gaussianRound(item.quantity! * getProductUnitPrice(item), DECIMAL_PLACES);
};

export const getPriceByAverageWeight = (item: Item) => {
  const product = item.product;
  const unitPrice = (product.price as Price)?.unitPrice || product.unitPrice;

  return product.averageWeight! * unitPrice;
};

export const getPrice = (item: Item) => {
  if (getProductDisplayType(item) === QUANTITY) {
    return getProductPrice(item);
  }

  return getPriceByAverageWeight(item);
};

export const getRawQtyUnit = (item: Item, weightQty?: number, unit?: string) => {
  const _weightQty = weightQty || item.quantity!;
  const _unit = unit || getUnit(item);

  if (_unit === UNIT_KG) {
    return _weightQty / getProductAverageWeight(item)!;
  }

  return _weightQty;
};

export const getRawWeightQtyUnit = (item: Item, qty?: number, unit?: string) => {
  const _qty = qty || item.quantity!;
  const _unit = unit || getUnit(item);

  if (_unit !== UNIT_KG) {
    return _qty * getProductAverageWeight(item)!;
  }

  return _qty;
};

export const getMaxKgs = (item: Item): number => {
  return round(getProductBulkBuyLimit(item) * getProductAverageWeight(item)!);
};

export const getMaxPieces = (item: Item): number => {
  return getProductBulkBuyLimit(item);
};

export const getMaxQty = (item: Item): number => {
  switch (item.customerUnitChoice) {
    case UNIT_PCS:
      return getMaxPieces(item) || Infinity;
    case UNIT_KG:
      return getMaxKgs(item);
    default: {
      throw new Error(`Invalid customer unit choice: ${item.customerUnitChoice}`);
    }
  }
};

export const getRestrictions = (item: Item) => {
  const restrictions = getProduct(item).restrictions;

  if (!restrictions) return [];

  return toJS(restrictions);
};

export const getRestrictedOrderAmendmentRestriction = (item: Item) => {
  const restrictions = getRestrictions(item);

  return restrictions.find(restriction => ROA_RESTRICTIONS.includes(restriction.type));
};

export const hasRestrictedOrderAmendment = (item: Item) => {
  const restrictedOrderAmendmentRestriction = getRestrictedOrderAmendmentRestriction(item);

  if (restrictedOrderAmendmentRestriction) {
    return (
      restrictedOrderAmendmentRestriction.type === RESTRICTED_ORDER_AMENDMENT_WITH_ITEM ||
      restrictedOrderAmendmentRestriction.isViolated
    );
  }

  return false;
};

const getCatchWeightPriceByUnit = (item: Item) => {
  if (!item.catchWeight) {
    return;
  }

  return gaussianRound(item.catchWeight * getProduct(item).unitPrice, DECIMAL_PLACES);
};

export const getProductCatchWeightList = (item: Item) => {
  const catchWeight = getCatchWeight(item);
  const catchWeightList = getProduct(item).catchWeightList;

  if (!catchWeightList) {
    return;
  }

  if (!catchWeightList.some(catchWeightListItem => catchWeightListItem.weight === catchWeight)) {
    const catchWeightPrice = getCatchWeightPriceByUnit(item);
    insertSorted(
      catchWeightList,
      {
        weight: catchWeight,
        price: catchWeightPrice,
      },
      (firstCatchWeightListItem, secondCatchWeightListItem) =>
        (firstCatchWeightListItem.weight ?? 0) - (secondCatchWeightListItem.weight ?? 0),
    );
  }

  return catchWeightList;
};

export const isCatchWeightProduct = (item: Item): boolean => {
  const catchWeightList = getProduct(item).catchWeightList;

  const hasCatchWeightListItems = catchWeightList && catchWeightList.length > 0;
  const canAddRemovedCatchWeight = catchWeightList && item.catchWeight && getProduct(item).unitPrice;

  return (
    getProduct(item).productType === CATCH_WEIGHT_PRODUCTS && (!!hasCatchWeightListItems || !!canAddRemovedCatchWeight)
  );
};

export const getCatchWeightPrice = (item: Item, val?: number) => {
  const _val = val || item.catchWeight;

  if (!isCatchWeightProduct(item)) {
    return;
  }

  const catchWeight = getProductCatchWeightList(item)!.find(cw => cw.weight === _val);

  return catchWeight ? catchWeight.price : null;
};

export const getCostByCatchWeight = (item: Item) => {
  const cost = (getCatchWeightPrice(item) || 0) * item.quantity!;

  return cost !== 0 ? gaussianRound(cost, DECIMAL_PLACES) : item.cost;
};

export const getPostDiscountCost = (item: Item) => item.cost as number;

export const getCost = (item: Item) => {
  let cost;

  if (isCatchWeightProduct(item)) {
    cost = getCostByCatchWeight(item);
  } else if (item.customerUnitChoice === UNIT_PCS) {
    const productDisplayType = getProduct(item).displayType.toLowerCase();

    if (productDisplayType === 'quantity') {
      if (isVariableWeightProduct(item)) {
        cost = getCostByMaxWeight(item);
      } else {
        cost = getCostByActualPrice(item);
      }
    } else {
      cost = getCostByAverageWeight(item);
    }
  } else if (item.customerUnitChoice === UNIT_KG) {
    cost = getCostByUnitPrice(item);
  }

  return cost;
};

/**
 * Gets the cost for an item if it had the given number of items applied to it.
 *
 * It will respect the underlying unit type for the item when calculating the
 * cost.
 *
 * Does not mutate the Item.
 *
 * @param item The item
 * @param numberOfItems The number of items to get the cost for
 *
 * @returns The cost of the item with the given number of items applied
 */
export const getCostOfNItems = (item: Item, numberOfItems: number): number => {
  return getCost(setNumberOfItems(item, numberOfItems));
};

export const getQuantityForDisplay = (item: Item, enableCatchWeightTransitioning = false): string => {
  const quantity = round(item.quantity!, 2);
  const isCatchWeightItem = isCatchWeightProduct(item);
  const isCatchWeightTransitioning = isCatchWeightItem && enableCatchWeightTransitioning;
  const isUnitKg = item.customerUnitChoice === UNIT_KG;
  const isLooseWeightItemWithKgSelected = hasToggle(item) && isUnitKg;

  if (!isUnitKg && !isCatchWeightItem) {
    return String(item.quantity!);
  }

  if (isLooseWeightItemWithKgSelected || isCatchWeightTransitioning) {
    return `${quantity}kg`;
  }

  return !isCatchWeightItem ? `${quantity}kg` : `${quantity} x ${item.catchWeight}kg`;
};

export const getRestrictedOrderAmendmentMessage = (item: Item, showShortMessage = false) => {
  const restrictedOrderAmendmentRistriction = getRestrictedOrderAmendmentRestriction(item);

  if (restrictedOrderAmendmentRistriction) {
    return showShortMessage
      ? restrictedOrderAmendmentRistriction.shortMessage
      : restrictedOrderAmendmentRistriction.message;
  }

  return null;
};

export const getDefaultUnit = (item: Item) => {
  const displayType = getProductDisplayType(item);

  switch (displayType) {
    case 'Weight':
      return UNIT_KG;
    case 'Quantity':
    case 'QuantityOrWeight':
    default:
      return UNIT_PCS;
  }
};

export const isRdgViolation = (item: Item) => {
  const rdgViolationRestrictions = getRestrictions(item).filter(({ type }) => RDG_VIOLATIONS.includes(type));

  return rdgViolationRestrictions.some(restriction => !!restriction && restriction.isViolated);
};

export const hitsRestrictionLeadTimeLimit = (item: Item, slot) => {
  const leadTime = item.restrictionDeliveryGroups?.LEAD_TIME;

  if (leadTime && slot) {
    const bookedDate = moment(slot.start);
    const difference = (bookedDate.unix() - moment().unix()) / 3600;

    return isBooked(slot) && difference < leadTime.leadTimeHours;
  }

  return false;
};

export const canBeDelivered = (item: Item, slot) => {
  return ![hitsRestrictionLeadTimeLimit(item, slot)].reduce((result, next) => result || next, false);
};

export const showLink = (item: Item, showUnavailableMsg: boolean | undefined, currentUrl: string): boolean => {
  const isUnavailable = !getProductIsForSale(item);
  const aisleId = getProductAisleId(item);
  const aisleIdSlug = aisleId && getSlug(aisleId.replace(/aisle:/gi, ''));

  if (
    isUnavailable &&
    currentUrl &&
    ((aisleId && currentUrl.includes(aisleId)) || (aisleIdSlug && currentUrl.includes(aisleIdSlug)))
  ) {
    return false;
  }

  if (showUnavailableMsg) {
    switch (getProductStatus(item)) {
      case EXCLUDED_PRODUCT:
      case TEMPORARY_UNAVAILABLE:
      case EPW_WITH_AMEND_AVAILABILITY_OVERRIDE:
      case EPW_WITHOUT_AMEND_AVAILABILITY_OVERRIDE:
        return true;
      default:
        return false;
    }
  }

  return isUnavailable;
};

export const isProductBrowsableOnPDP = (item: Item) => {
  if (!getProductIsForSale(item)) {
    switch (getProductStatus(item)) {
      case EXCLUDED_PRODUCT:
      case TEMPORARY_UNAVAILABLE:
      case EPW_WITH_AMEND_AVAILABILITY_OVERRIDE:
      case EPW_WITHOUT_AMEND_AVAILABILITY_OVERRIDE:
        return true;
      default:
        return false;
    }
  }

  return true;
};

export const isExcluded = (item: Item) => {
  switch (getProductStatus(item)) {
    case EPW_WITH_AMEND_AVAILABILITY_OVERRIDE:
    case EXCLUDED_PRODUCT:
      return true;
    default:
      return false;
  }
};

export const isInBasketAndExcluded = (item: Item) => isExcluded(item) && isInBasket(item);

export const isAvailableOrExcluded = (item: Item) => getProductIsForSale(item) || isExcluded(item);

export const existingInBasketAndExcluded = (item: Item) => !getIsNewlyAdded(item) && isInBasketAndExcluded(item);

export const getRestOfAisleUrl = (item: Item) => {
  const superDepartment = getProductSuperDepartmentName(item);
  const department = getProductDepartmentName(item);
  const aisle = getProductAisleName(item);

  if (superDepartment && department && aisle) {
    const superDepartmentSlug = getSlug(superDepartment);
    const departmentSlug = getSlug(department);
    const aisleSlug = getSlug(aisle);

    return `/shop/${superDepartmentSlug}/${departmentSlug}/${aisleSlug}`;
  }

  return null;
};

export const getRestOfShelfUrl = (item: Item) => {
  const superDepartment = getProductSuperDepartmentName(item);
  const department = getProductDepartmentName(item);
  const aisle = getProductAisleName(item) || item.aisleName;
  const shelf = getProductShelfName(item) || item.shelfName;

  if (superDepartment && department && aisle && shelf) {
    const superDepartmentSlug = getSlug(superDepartment);
    const departmentSlug = getSlug(department);
    const aisleSlug = getSlug(aisle);
    const shelfSlug = getSlug(shelf);

    return `/shop/${superDepartmentSlug}/${departmentSlug}/${aisleSlug}/${shelfSlug}`;
  }
};

export const getTaxonomyNode = (item: Item): string => {
  const superDepartment = getProductSuperDepartmentName(item);
  const department = getProductDepartmentName(item);
  const aisle = getProductAisleName(item);
  const shelf = getProductShelfName(item);

  return `/${superDepartment}/${department}/${aisle}/${shelf}`;
};

export const getTaxonomyNodeWithSuperDepartmentAndDepartment = (item: Item): string => {
  const superDepartment = getProductSuperDepartmentName(item) || '';
  const department = getProductDepartmentName(item) || '';

  return `/${superDepartment}/${department}`;
};

export const generateSlugs = (item: Item): { shelfIdSlug: string; aisleIdSlug: string } => {
  const aisleId = getProductAisleId(item);
  const shelfName = getProductShelfName(item);

  // IGHS api - doesn't return shelf data. we need the aisleId for International
  const shelfNameOrAisleId = shelfName || aisleId;
  const aisleName = getProductAisleName(item);

  return {
    shelfIdSlug: getSlugId(shelfNameOrAisleId),
    aisleIdSlug: getSlugId(aisleName),
  };
};

export const hasAisleId = (item: Item, aisleId: string) => {
  const primaryAisleId = getProductAisleId(item);

  if (primaryAisleId === aisleId) {
    return true;
  }

  const alternativeCategories = toJS(getProduct(item).alternativeCategories || item.alternativeCategories);

  return !!alternativeCategories && alternativeCategories.some(category => category.aisleId === aisleId);
};

export const getRdgRestrictions = (item: Item) => {
  const restrictions = getRestrictions(item);

  return restrictions.filter(restriction => RDG_RESTRICTIONS.includes(restriction.type));
};

export const isRdgRestricted = (item: Item) => {
  const rdgRestrictions = getRdgRestrictions(item);

  return rdgRestrictions.some(restriction => !!restriction && restriction.isViolated);
};

export const isAmendRestricted = (item: Item) => {
  const restrictions = getRestrictions(item);

  return restrictions.some(({ type }) => AMEND_RESTRICTED_TYPES.includes(type));
};

export const getReviewsStats = (item: Item) => {
  const reviews = getProductReview(item);

  if (reviews) {
    const reviewStats = reviews.stats;
    return reviewStats && toJS(reviewStats);
  }

  return null;
};

export const qtyDecrementable = (item: Item) => {
  return !atMinQty(item) && !hasRestrictedOrderAmendment(item);
};

export function isCustomerUnitChoice(item: Item, unit?: string) {
  if (typeof unit !== 'string') return false;

  return typeof item.customerUnitChoice === 'string' && item.customerUnitChoice.toLowerCase() === unit.toLowerCase();
}

export const getWithCatchWeight = (item: Item, val: number) => withCatchWeight(item, val);

export function getPieces(item: Item): number {
  if (item.customerUnitChoice === UNIT_PCS) {
    return item.quantity!;
  }

  return round(Math.max(item.quantity!, 0) / getProductAverageWeight(item)!);
}

export function getWeight(item: Item): number {
  if (item.customerUnitChoice === UNIT_KG) {
    return item.quantity!;
  }

  return round(Math.max(item.quantity!, 0) * getProductAverageWeight(item)!);
}

export const atMaxQty = (item: Item) => {
  const itemPiecesAreAtBulkBuyLimit = getPieces(item) >= getProductBulkBuyLimit(item);

  if (!getProductBulkBuyLimitGroupId(item)) {
    return itemPiecesAreAtBulkBuyLimit;
  }

  return itemPiecesAreAtBulkBuyLimit || getItemLimitReached(item) || getGroupLimitReached(item);
};

export const qtyIncrementable = (item: Item) => {
  return (
    !atMaxQty(item) &&
    !hasRestrictedOrderAmendment(item) &&
    !(getProductStatus(item) === EPW_WITH_AMEND_AVAILABILITY_OVERRIDE && atRestrictedMaxQty(item))
  );
};

export const getQtyChange = (item: Item) => {
  const origQty = item.originalQuantity;

  if (typeof origQty === 'number') {
    return getPieces(item) - origQty;
  }

  return 0;
};

export const getQtyInRange = (item: Item, qty: number) => {
  return Math.max(0, Math.min(qty, getMaxQty(item)));
};

/**
 * Gets a quantity for an Item if the given adjustment of number of items
 * applied against the given items quantity.
 *
 * This will take into account the unit type of the underlying Item.
 *
 * The original Item is not mutated.
 *
 * @param item The item
 * @param numberOfItemsAdjustment The number of items to adjust by
 *
 * @returns The quantity representing the adjusted number of items
 */
export const getQtyForAdjustedNItems = (item: Item, numberOfItemsAdjustment: number): number => {
  if (numberOfItemsAdjustment === 0) {
    return getQuantity(item);
  }

  const unit = getCustomerUnitChoice(item) || getDefaultUnit(item);

  switch (unit) {
    case UNIT_KG:
      return getQtyInRange(
        item,
        round(getQuantity(item) + (getProductAverageWeight(item) || 0) * numberOfItemsAdjustment),
      );
    case UNIT_PCS:
      return getQtyInRange(item, round(getQuantity(item) + numberOfItemsAdjustment));
    default:
      console.warn(`Unexpected item unit: ${unit}`);
      return 0;
  }
};

/**
 * Gets a quantity for an Item if it had the given the number of items applied
 * against it.
 *
 * This will take into account the unit type of the underlying Item.
 *
 * The original Item is not mutated.
 *
 * @param item The item
 * @param numberOfItems The number of items to determine the quantity for
 *
 * @returns The quantity representing the number of items
 */
export const getQtyForNItems = (item: Item, numberOfItems: number): number => {
  const unit = getCustomerUnitChoice(item) || getDefaultUnit(item);

  switch (unit) {
    case UNIT_KG:
      return getQtyInRange(item, round(Math.max(numberOfItems, 0) * (getProductAverageWeight(item) || 0)));
    case UNIT_PCS:
      return getQtyInRange(item, numberOfItems);
    default:
      console.warn(`Unexpected item unit: ${unit}`);
      return 0;
  }
};

export const getCostChange = (item: Item) => {
  const qtyChange = getQtyChange(item);

  if (qtyChange === 0) {
    // No change in quantity, therefore no price change
    return 0;
  }

  if (qtyChange < 0) {
    // A reduced quantity, therefore a reduction in prince
    return getCostOfNItems(item, qtyChange * -1) * -1;
  }

  // An increased quantity, therefore an increase in price
  return getCostOfNItems(item, qtyChange);
};

/**
 * Determines if the given item has a customer unit choice of "kg".
 * Returns true if it does, else it returns false.
 *
 * @param item - The item
 */
export const hasCustomerUnitChoiceOfKg = (item: Item): boolean => {
  return getCustomerUnitChoice(item) === UNIT_KG;
};

/**
 * Determines if the given item has a customer unit choice of "pcs".
 * Returns true if it does, else it returns false.
 *
 * @param item - The item
 */
export const hasCustomerUnitChoiceOfPieces = (item: Item): boolean => {
  return getCustomerUnitChoice(item) === UNIT_PCS;
};

/**
 * Determines if the given item has an original customer unit choice of "pcs".
 * Returns true if it does, else it returns false.
 *
 * @param item - The item
 */
export const hasOriginalCustomerUnitChoiceOfPieces = (item: Item): boolean => {
  return getOriginalCustomerUnitChoice(item) === UNIT_PCS;
};

/**
 * Determines if the provided item's product has the given display type.
 * Returns true if it does, else it returns false.
 *
 * @param item - The item
 * @param displayType - The display type to check against the item
 */
export const hasProductDisplayType = (item: Item, displayType: DisplayType): boolean => {
  return getProductDisplayType(item) === displayType;
};

export const getProductType = (item: Item): string | undefined => item?.product?.typename;

export const getIsMarketplaceProduct = (item: Item): boolean => getProductType(item) === MARKETPLACE_PRODUCT;

export const getSellerInfo = (item: Item): SellerInfo => {
  const isPartneredProduct = getIsMarketplaceProduct(item);

  if (!isPartneredProduct) return { isPartneredProduct };

  const { seller } = item.product;

  return {
    isPartneredProduct,
    name: seller?.name,
    sellerId: seller?.id,
  };
};

export const getIsIghsProduct = (item: Item): boolean => {
  return getProductType(item) === IGHS_PRODUCT;
};

export const extractClubcardPrice = (promotion: Promotion, regexp: string): number | undefined => {
  const matches = promotion?.offerText?.match(new RegExp(regexp, 'i'));

  if (matches && matches[1]) {
    let clubcardPrice = matches[1];
    if (clubcardPrice.indexOf('p') !== -1) {
      clubcardPrice = '0.' + clubcardPrice.replace('p', '');
    }
    const parsedPrice = parseFloat(clubcardPrice);
    return isNaN(parsedPrice) ? undefined : parsedPrice;
  }
};

export function getClubcardPromotion(item: Item): Promotion | undefined {
  if (hasPromotion(item)) {
    for (const promotion of item.promotions) {
      if (isClubcardPromotion(promotion)) {
        return promotion;
      }
    }
  }
}

export const getProductStartRatings = (item: Item): ProductStarRatingData => {
  const { overallRating, noOfReviews, overallRatingRange } = item.product?.reviews?.stats || {};

  return {
    averageRating: overallRating,
    noOfReviews,
    overallRatingRange,
  };
};

export const getTagList = (
  { translate, item }: GetFlashSashInfoArgs,
  isInFavoritesGrid = false,
): TFlashSashInfo[] | null => {
  const sashList: TFlashSashInfo[] | null = [];
  if (!getProductIsForSale(item)) {
    return null;
  }

  if (isSponsoredProduct(item)) {
    sashList.push({
      text: translate('product-tile:sponsored'),
      color: BLUE,
      type: tags.sponsored,
    });
  }

  if (getProductIsNew(item)) {
    sashList.push({
      text: translate('sort-and-filter:new'),
      color: TESCO_BLUE,
      type: tags.newProduct,
    });
  }

  if (getIsMarketplaceProduct(item)) {
    sashList.push({
      text: translate('product-tile:market-place'),
      color: TESCO_BLUE,
      type: tags.marketplace,
    });
  }

  if (item.isWhyNotTry) {
    sashList.push({
      text: translate('product-tile:why-not-try'),
      color: GREY,
      type: tags.whyNotTry,
    });
  }

  sashList.push(...getUpdatedSashListWithProbations({ item, translate, isInFavoritesGrid }));

  if (!getIsMarketplaceProduct(item) && hasPromotion(item) && !isClubcardPromotionOnItem(item)) {
    sashList.push({
      text: translate('promotions:offer-flash'),
      color: YELLOW,
      type: tags.offer,
    });
  }

  return sashList;
};

export const isProduct = (item: Item): boolean => !item.type || item.type === ResultType.PRODUCT;

export const getSellerId = (item: Item): string => {
  const sellerInfo = getSellerInfo(item);
  if (sellerInfo.isPartneredProduct && sellerInfo?.sellerId) {
    return sellerInfo?.sellerId;
  }
  return GHS;
};

export const getGtin = (item: Item): string | undefined | null => getProduct(item)?.gtin;

export const getProductDrsChargeAmount = (item: Item, isTrolley = false): number | null => {
  const findDrsCharge = (charges: ProductCharge[], typeName: string): number | null => {
    return charges.find(charge => charge.__typename === typeName)?.amount || null;
  };
  if (isTrolley && item?.charges && Array.isArray(item.charges?.charges)) {
    return findDrsCharge(item.charges.charges, DEPOSIT_RETURN_CHARGE);
  } else if (Array.isArray(item.product?.charges)) {
    return findDrsCharge(item.product.charges, PRODUCT_DEPOSIT_RETURN_CHARGE);
  }
  return null;
};
