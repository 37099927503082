import React from 'react';
import { ConnectedProps } from 'react-redux';
import Icon from '@beans/icon';
import helpers from '#/lib/decorators/helpers';
import { DeleteButton } from '#/components/product-tiles/common/delete-button';
import { getLanguageLink } from '#/reducers/app';
import { deleteFavouriteItemJSOnly } from '#/actions/results-actions';
import { connect } from '#/lib/render/connect-deep-compare';
import { TTranslateFunc } from '#/lib/records/helpers.defs';
import { removeFromFavorites } from '#/actions/results-actions';
import { FAVORITES_TAB } from '#/constants/favorites';
import { clearOptimisticDeleteSelection, removeOptimisticDeleteSelectionFromUI } from '#/actions/ui-action-creators';
import { getOptimisticDeleteSelection } from '#/selectors/ui';
import { OptimisticDelete } from '#/custom-typings/redux-store/ui.defs';
import { addOptimisticDeleteSelection } from '#/actions/ui-action-creators';
import analyticsBus from '#/analytics/analyticsBus';
import { basicEvent } from '#/analytics/types/basic';
import { NOW, FAVORITES } from '#/analytics/constants';
import { waitHandler } from '#/utils/favorite-utils';

type OwnProps = {
  productId: string;
  accessibleText: string;
  t: TTranslateFunc;
};

type OwnState = {
  deleteFavoriteUrl: string;
  selectionData: OptimisticDelete['selections'];
};

export const mapStateToProps = (state: Store, { productId }: OwnProps): OwnState => ({
  deleteFavoriteUrl: getLanguageLink(state, `/favorites/items/${productId}`),
  selectionData: getOptimisticDeleteSelection(state, FAVORITES_TAB),
});

const mapDispatchToProps = {
  deleteFavouriteItemJSOnly,
  addOptimisticDeleteSelection,
  removeOptimisticDeleteSelectionFromUI,
  clearOptimisticDeleteSelection,
  removeFromFavorites,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = OwnProps & OwnState & ConnectedProps<typeof connector>;

const DeleteWithCrossIcon = (props: Props): JSX.Element => {
  const {
    deleteFavoriteUrl,
    deleteFavouriteItemJSOnly,
    accessibleText,
    t: translate,
    addOptimisticDeleteSelection,
    removeOptimisticDeleteSelectionFromUI,
    clearOptimisticDeleteSelection,
    removeFromFavorites,
    productId,
  } = props;

  const triggerAnalytics = (): void => {
    const analyticPayload = {
      type: FAVORITES,
      value: 'delete favourites items',
      action: NOW,
    };

    basicEvent(analyticsBus, analyticPayload);
  };

  const deleteButtonHandler = async (): Promise<void> => {
    triggerAnalytics();
    deleteFavouriteItemJSOnly(deleteFavoriteUrl, false, true);
    const payload = { page: FAVORITES_TAB, id: productId };
    addOptimisticDeleteSelection(payload);
    removeOptimisticDeleteSelectionFromUI(FAVORITES_TAB);
    await waitHandler(800);
    removeFromFavorites([productId]);
    clearOptimisticDeleteSelection(FAVORITES_TAB);
  };

  return (
    <DeleteButton
      className="delete-button"
      handleDeleteClick={deleteButtonHandler}
      accessibleText={accessibleText}
      size="sm"
      title={translate('favorites:multi-delete.tooltip.single-delete-cross-icon')}
      variant="secondary"
    >
      <Icon graphic="close" fill="#00539f" />
    </DeleteButton>
  );
};

export default connector(helpers(['t'])(DeleteWithCrossIcon));
