/*
 ** PLEASE NOTE: this is a temporary fix!
 ** jira ticket: LEGO-17059 and LEGO-17060
 **
 ** Desc: Beans components currently do not support non-JS. Legacy add functionailty for nonregistered user
 ** fallsback on to nonJS functionaility where a form is submit and the backend reroutes the user. SO we create
 ** a form submission on the fly.
 */
import { TFormAddData } from '#/selectors/beans-product-tile/add-form-params-data';
import { getCatchWeight, getUnit } from '#/selectors/item';
import { Item } from '#/lib/records/item';
import { setCustomerUnitChoice, withCatchWeight, adjustNumberOfItems } from '#/lib/records/item-utils';
import { CustomerUnitChoice } from '#/lib/records/item.defs';
import { TrolleyAnalytics, TrolleyTrexAnalytics } from '#/custom-typings/redux-store/trolley.defs';

type TDynamicFormParams = {
  newUnitChoice: CustomerUnitChoice;
  newValue: number;
  oldUnitChoice: CustomerUnitChoice;
  oldValue: number;
  catchWeight?: number;
  catchWeightChoice?: number;
};

const getDynamicFormParams = (
  item: Item,
  quantityValue: number,
  variantValue: CustomerUnitChoice = getUnit(item),
): TDynamicFormParams => {
  const updatedItem = withCatchWeight(
    setCustomerUnitChoice(adjustNumberOfItems(item, quantityValue), variantValue),
    getCatchWeight(item) || 0,
  );

  return {
    newUnitChoice: updatedItem.customerUnitChoice || variantValue,
    newValue: updatedItem.quantity || quantityValue,
    oldUnitChoice: updatedItem.customerUnitChoice || variantValue,
    oldValue: 0,
    catchWeight: updatedItem.catchWeight,
    catchWeightChoice: updatedItem.catchWeight,
  };
};

export const anonymousUserAdd = (
  item: Item,
  derivedFormData: TFormAddData,
  quantityValue: number,
  variantValue: CustomerUnitChoice,
  itemActionAnalytics: TrolleyAnalytics | TrolleyTrexAnalytics,
): void => {
  const { action, params } = derivedFormData;
  const formParams: Record<string, any> = {
    ...params,
    ...getDynamicFormParams(item, quantityValue, variantValue),
    ...itemActionAnalytics,
  };
  const form = document.createElement('form');

  form.method = 'POST';
  form.action = action;

  for (const key of Object.keys(formParams)) {
    const hiddenField = document.createElement('input');
    hiddenField.type = 'hidden';
    hiddenField.name = key;
    hiddenField.value = formParams[key];

    form.appendChild(hiddenField);
  }

  document.body.appendChild(form);

  form.submit();
};
