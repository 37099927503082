import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../shared/modal';
import ModalContent from '../../shared/modal/content';
import ModalActions from '../../shared/modal/actions';
import ModalButton from '../../shared/modal/actions/button-container';
import ModalCloseXLink from '../modal-close-x-link';
import { closeModal } from '../../../actions/ui-action-creators';
import { removeModalFromUrl } from '../../../lib/url/modal-utils';
import { connect } from '../../../lib/render/connect-deep-compare';
import helpers from '../../../lib/decorators/helpers';
import Link from '../../link-check-spa';
import FormattedLink from '../../link';
import { getCurrentUrl } from '../../../reducers/app';
import SafelyOutputString from '#/components/safely-output-string';

const mapStateToProps = state => ({
  currentUrl: getCurrentUrl(state)
});

@connect(mapStateToProps, { closeModal })
@helpers(['t'])
export default class ItemsUnavailableModal extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    currentUrl: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.text = {
      title: this.props.t('modals:items-unavailable.title'),
      content: this.props.t('modals:items-unavailable.content'),
      continueShopping: this.props.t(
        'modals:items-unavailable.continue-shopping'
      ),
      viewFullBasket: this.props.t('modals:items-unavailable.view-full-basket')
    };
    this.closeLink = removeModalFromUrl(this.props.currentUrl);
  }

  handleButtonClick = evt => {
    if (evt) {
      evt.preventDefault();
    }

    this.props.closeModal();
  };

  render() {
    return (
      <Modal
        closeModal={this.props.closeModal}
        title={this.text.title}
        className="items-unavailable-modal"
      >
        <ModalContent>
          <ModalCloseXLink
            onClick={this.handleButtonClick}
            linkUrl={this.closeLink}
          />
          <div className="items-unavailable-modal__alert-box">
            <span className="items-unavailable-modal__alert-icon icon-alert-circle-gray" />
            <div>
              <SafelyOutputString>{this.text.content}</SafelyOutputString>
            </div>
          </div>
        </ModalContent>

        <ModalActions stacked={true}>
          <ModalButton>
            <Link
              className="button button-primary"
              to={this.closeLink}
              onClick={this.handleButtonClick}
            >
              {this.text.continueShopping}
            </Link>
          </ModalButton>
          <ModalButton>
            <FormattedLink
              className="button button-secondary"
              href={'/trolley'}
            >
              {this.text.viewFullBasket}
            </FormattedLink>
          </ModalButton>
        </ModalActions>
      </Modal>
    );
  }
}
