import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RenderRemoveItem from '#/components/products/product-tile/tile-components/shared/render-remove-item';
import RenderUnavailableMessage from '#/components/products/product-tile/tile-components/shared/render-unavailable-message';
import ProductImage from '#/components/products/product-tile/tile-components/product-image';
import ProductTitle from '#/components/products/product-tile/tile-components/product-title';
import RenderBrowseCategorySuggestion from '#/components/products/product-tile/tile-components/shared/render-browse-category-suggestion';
import {
  isProductBrowsableOnPDP,
  getProductDefaultImageUrl,
  getProductTitle,
  getProductId,
  getProductStatus
} from '#/selectors/item';

export default class UnavailableTile extends PureComponent {
  static propTypes = {
    href: PropTypes.string,
    isAmendBasket: PropTypes.bool,
    isAvailableEpwOverride: PropTypes.bool,
    isOnDemandDelivery: PropTypes.bool,
    item: PropTypes.object.isRequired,
    productBrowsableOnPDP: PropTypes.bool,
    userRegistered: PropTypes.bool
  };

  static defaultProps = {
    isAmendBasket: false
  };

  render() {
    const {
      href,
      item,
      isAmendBasket,
      isAvailableEpwOverride,
      isOnDemandDelivery,
      productBrowsableOnPDP,
      userRegistered
    } = this.props;
    const title = getProductTitle(item);
    const productId = getProductId(item);
    const productDefaultImgUrl = getProductDefaultImageUrl(item);
    const isPDPBrowsable = isProductBrowsableOnPDP(item);
    const productStatus = getProductStatus(item);

    return (
      <div
        className="horizontal-tile trolley-tile"
        key="unavailable-trolley-tile"
      >
        <div className="tile-content" id={`tile-${productId}`}>
          <ProductImage
            alt={title}
            hideFromScreenReader={true}
            href={href}
            image={productDefaultImgUrl}
            isUnavailable={true}
            clickable={productBrowsableOnPDP}
          />
          <div className="product-details--wrapper">
            <div className="product-details--content">
              <ProductTitle
                id={productId}
                title={title}
                isPDPBrowsable={isPDPBrowsable}
                enabled={false}
              />
            </div>
            <RenderUnavailableMessage
              cssClassName="unavailable-message-section"
              item={item}
              isTrolley={true}
              isAmendBasket={isAmendBasket}
              showAsUnavailable={true}
              status={productStatus}
              isOnDemandDelivery={isOnDemandDelivery}
            />
          </div>
          <RenderUnavailableMessage
            cssClassName="hidden-medium product-info-section-small"
            item={item}
            isTrolley={true}
            isAmendBasket={isAmendBasket}
            showAsUnavailable={true}
            status={productStatus}
            isOnDemandDelivery={isOnDemandDelivery}
          />
        </div>
        <RenderRemoveItem item={item} userRegistered={userRegistered} />
        <RenderBrowseCategorySuggestion
          item={item}
          isAmendBasket={isAmendBasket}
          isAvailableEpwOverride={isAvailableEpwOverride}
        />
      </div>
    );
  }
}
