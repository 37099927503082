import React, { PureComponent } from 'react';
import { connect } from '../../lib/render/connect-deep-compare';
import PropTypes from 'prop-types';
import helpers from '../../lib/decorators/helpers';
import getAnalytics from '../../analytics/analyticsBus';
import { getIsMobile } from '../../reducers/app';

const mapStateToProps = state => ({
  isMobile: getIsMobile(state)
});

@connect(mapStateToProps)
@helpers(['t'])
export default class LifestyleAndDietaryFooter extends PureComponent {
  static propTypes = {
    isMobile: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired
  };

  recordLifestyleAndDietaryLinkClick = () => {
    getAnalytics().emit('UIEventBasicEvent', {
      type: 'filters',
      value: 'dietary:more info',
      action: 'now'
    });
  };

  getDietaryFilterFooterDesktopView = () => {
    return (
      <span>
        {this.props.t('sort-and-filter:lifestyle-and-dietary-footer-text')}
        &nbsp;
        {this.getFindMoreLink()}
      </span>
    );
  };

  getFindMoreLink = () => {
    return (
      <a
        href="https://www.tesco.com/help/terms-and-conditions/#Dietary"
        className="filter-options-footer--lifestyle-and-dietary-link"
        target="_blank"
        rel="noopener"
        onClick={this.recordLifestyleAndDietaryLinkClick}
      >
        {this.props.t('sort-and-filter:lifestyle-and-dietary-footer-link')}
        <span className="icon icon-chevron_right" />
      </a>
    );
  };

  getDietaryFilterFooterMobileView = () => {
    return (
      <span className="filter-options-footer--lifestyle-and-dietary-mobile">
        {this.getFindMoreLink()}
        <div className="filter-options-footer--lifestyle-and-dietary-text-mobile">
          {this.props.t('sort-and-filter:lifestyle-and-dietary-footer-text')}
        </div>
      </span>
    );
  };

  render() {
    return (
      <span>
        {this.props.isMobile
          ? this.getDietaryFilterFooterMobileView()
          : this.getDietaryFilterFooterDesktopView()}
      </span>
    );
  }
}
