import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

/*
 * Handles the width of widgets on the home page
 * Decides the number of columns each widget should take up
 */

const WidgetContainer = props => {
  const {
    children,
    className,
    doubleMedium,
    doubleLarge,
    element,
    height,
    widgetName
  } = props;

  const doubleClasses = classnames(className || '', {
    'double-medium': doubleMedium,
    'double-large': doubleLarge
  });
  const Element = element || 'div';
  const styles = { height };

  return (
    <Element
      className={`widget-container widget-container--${widgetName} ${doubleClasses}`}
      style={styles}
    >
      {children}
    </Element>
  );
};

WidgetContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  doubleLarge: PropTypes.bool,
  doubleMedium: PropTypes.bool,
  doubleSmall: PropTypes.bool,
  element: PropTypes.string,
  height: PropTypes.string,
  widgetName: PropTypes.string.isRequired
};

WidgetContainer.defaultProps = {
  element: 'div'
};

export { WidgetContainer };
