import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from '#/lib/render/connect-deep-compare';
import CouponsWithForm from '#/components/coupon/coupons-with-form';
import Modal from '#/components/shared/modal';
import Price from '#/components/shared/price';
import ModalContent from '#/components/shared/modal/content';
import { BAG_OPTIONS_MODAL } from '#/constants/modal-names';
import { openModal, closeModal } from '#/actions/ui-action-creators';
import helpers from '#/lib/decorators/helpers';
import AmendModalLinks from '#/components/modals/amend-modal-links';
import { getCoupons, getTotalPrice } from '#/selectors/trolley';

const mapStateToProps = state => ({
  coupons: getCoupons(state),
  totalPrice: getTotalPrice(state)
});

@connect(mapStateToProps, {
  openModal,
  closeModal
})
@helpers(['c', 't'])
class EcouponModal extends Component {
  static propTypes = {
    c: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    coupons: PropTypes.array.isRequired,
    couponSavings: PropTypes.number,
    guidePrice: PropTypes.number,
    openModal: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    totalPrice: PropTypes.number.isRequired
  };

  renderTotal() {
    if (this.props.coupons.length > 0) {
      return (
        <div className="ecoupon-modal--savings">
          <div className="ecoupon-modal--total">
            <span>{`${this.props.t('orders:amend.new-total')}: `}</span>
            <Price
              className="amount"
              value={this.props.totalPrice}
              enableMaxLimit={true}
              {...this.props.c('currency')}
            />
          </div>
        </div>
      );
    }
  }

  render() {
    return (
      <Modal
        className="ecoupon-modal"
        closeModal={this.props.closeModal}
        title={this.props.t('orders:common.add-ecoupons')}
      >
        <ModalContent>
          <CouponsWithForm compactMode />
          {this.renderTotal()}
          <AmendModalLinks
            openModal={this.props.openModal}
            closeModal={this.props.closeModal}
            dividerClass="ecoupon-modal--divider"
            showBagOptions
            linkContinueClass="ecoupon-modal--link"
            linkClass="save-modal--bag-options-link"
            linkName="context-cards:change-bag-option"
            modalName={BAG_OPTIONS_MODAL}
          />
        </ModalContent>
      </Modal>
    );
  }
}

export default EcouponModal;
