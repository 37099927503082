import { logApmError } from '#/lib/apm';
import { clientGraphqlPost } from '#/lib/requests/client-graphql';
import UPDATE_BASKET from '#/lib/requests/graphql-queries/update-basket';
import BASKET from '#/lib/requests/graphql-queries/fragments/basket';
import BASKET_PRODUCT_ITEM from '#/lib/requests/graphql-queries/fragments/basket-item';
import { SLOT } from '#/lib/requests/graphql-queries/fragments/slot';
import { PAYMENT_ITEM } from '#/lib/requests/graphql-queries/fragments/payment-item';
import { concatQueries } from '#/lib/requests/query-util';
import { ItemPayload } from '#/lib/records/item-payload';
import { TrolleyMangoResponse } from '#/lib/records/split-basket';

export type RequestConfig = {
  apiKey: string;
  region: string;
  atrc: string;
};

type ErrorResponse = {
  status: number;
  message: string;
  parsedBody?: {
    errors?: Error[];
  };
};

type Updates = {
  errorCode: string;
  id: number;
  isAlcoholic: boolean | null;
  message: string;
  status: string;
  successful: boolean;
};

type BasketData = {
  basket: TrolleyMangoResponse;
  updates: {
    items: Array<Updates>;
  };
};

export type AddAllUsualsToBasket = {
  error: boolean;
  data: BasketData;
};

export type RequestContext = {
  region: string;
  traceId: string;
  'x-apikey': string;
  Authorization?: string;
};

type Error = {
  message: string;
  name: string;
  status: number;
};

const ERROR_MESSAGE = 'Add all to basket Error - Message:';

export const addAllToBasket = async (
  payload: { items: Array<ItemPayload> },
  context: RequestContext,
  endPoint: string,
): Promise<AddAllUsualsToBasket | { error?: boolean; data: undefined }> => {
  const { items } = payload;
  const updateBasketQuery = concatQueries([UPDATE_BASKET, BASKET, BASKET_PRODUCT_ITEM, SLOT, PAYMENT_ITEM]);
  const queryVariables = {
    forceUpdate: false,
    includeCoupons: false,
    includeSubstitutions: false,
    includeDeliveryPreferences: false,
    includeCustomerPreferences: false,
    includeSubscription: false,
    items,
  };

  try {
    const response = await clientGraphqlPost(endPoint, context, updateBasketQuery, queryVariables);
    const { data = undefined, errors = [], status } = response || {};

    if (Array.isArray(errors) && errors.length > 0) {
      let messageStr = `${ERROR_MESSAGE} GraphQL Mutation status is ${status}, GraphQL Errors:`;
      errors.forEach(({ message }: Error) => {
        messageStr += ` ${message}`;
      });
      logApmError(new Error(messageStr), { traceId: context.traceId });

      return { error: true, data: undefined };
    }

    return { error: false, data };
  } catch (errResponse) {
    const res = errResponse as ErrorResponse;
    if (window) {
      const error = res?.parsedBody?.errors?.[0];
      const statusCode = res?.status || 'unknown';
      const errorMessage = error?.message || res.message || 'unknown';
      const message = `${ERROR_MESSAGE} ${errorMessage}, Status: ${statusCode}`;

      logApmError(new Error(message), { traceId: context.traceId });
    }

    return {
      error: true,
      data: undefined,
    };
  }
};
