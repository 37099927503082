import React from 'react';
import { VERTICAL } from '#/components/product-tiles/common/constants';
import ProductList from '#/components/products/product-list';
import { FLEXI } from '#/constants/tile-types';
import { Item } from '#/lib/records/item';
import { SHOPPING_LISTS } from '#/constants/result-types';
import { itemsToMap, transferItems } from '#/lib/records/product-utils';
import { getItems } from '#/selectors/trolley';
import { getIsUserRegistered } from '#/reducers/user';
import { connect } from 'react-redux';

type BaseProps = {
  productList: Map<string, Item>;
};

type StateProps = {
  trolleyItems: Array<Item>;
  userRegistered: ReturnType<typeof getIsUserRegistered>;
};

const mapStateToProps = (state: Store): StateProps => ({
  trolleyItems: getItems(state),
  userRegistered: getIsUserRegistered(state),
});

const connector = connect(mapStateToProps, null);

const DCSProductList = (props: BaseProps & StateProps): JSX.Element => {
  const { productList, trolleyItems, userRegistered } = props;
  return (
    <ProductList
      className="zone-page"
      displayUnavailable
      hideQuantityToggle={false}
      items={itemsToMap(transferItems(productList, trolleyItems))}
      showFilters={false}
      showTotals
      tileType={FLEXI}
      isGrid
      productTileVariant={VERTICAL}
      resultType={SHOPPING_LISTS}
      userRegistered={userRegistered}
      listData={{}}
    />
  );
};

export default connector(DCSProductList);
