import React from 'react';
import PropTypes from 'prop-types';
import helpers from '../../lib/decorators/helpers';
import ShowMoreLessLink from './show-more-less-link';
import { connect } from '../../lib/render/connect-deep-compare';
import { TOP, BOTTOM } from '../../constants/common';
import {
  getCount,
  getItemsPerPage,
  getPageNo,
  getTotalCount,
  getOffset
} from '../../reducers/results';
import { getSortOptions } from '#/reducers/filter';
import { getCurrentQueryParams } from '#/reducers/app';
import SafelyOutputString from '#/components/safely-output-string';
import { camelCaseToKebabCase } from '#/utils/text-utils';

const mapStateToProps = state => ({
  itemsPerPage: getItemsPerPage(state),
  offset: getOffset(state),
  pageNo: getPageNo(state),
  productsInPage: getCount(state),
  total: getTotalCount(state),
  filterStoreSortOptions: getSortOptions(state),
  sortQueryParam: getCurrentQueryParams(state)?.sortBy
});

const getStartIndex = (offset, pageNo, itemsPerPage) =>
  (offset || (pageNo - 1) * itemsPerPage) + 1;

const ShowMoreLess = props => {
  const {
    className,
    itemsPerPage,
    offset,
    pageNo,
    position,
    productsInPage,
    t,
    total,
    sortQueryParam,
    filterStoreSortOptions,
    withStatusRole = false
  } = props;
  const min = getStartIndex(offset, pageNo, itemsPerPage);
  const max = min - 1 + productsInPage;
  const displayShowMoreLessLink = total > max;

  let sort;
  // if no sort query param is set, sort will be the first sorting option available
  if (sortQueryParam) {
    // sortQueryParam is camelCase
    sort = camelCaseToKebabCase(sortQueryParam);
  } else {
    // sort options are kebab-case
    sort = filterStoreSortOptions[0];
  }

  return (
    <div className={className}>
      <div
        className={'pagination__items-displayed'}
        data-auto="product-bin-count"
        {...(withStatusRole ? { role: 'status' } : {})}
      >
        <SafelyOutputString>
          {t('search:results-count', { min, max, total })}
        </SafelyOutputString>
        {sort && (
          <span className="visually-hidden">
            {t('sort-and-filter:sorted-by', {
              sort: t(`sort-and-filter:sort-options.${sort}`)
            })}
          </span>
        )}
      </div>
      {displayShowMoreLessLink && <ShowMoreLessLink position={position} />}
    </div>
  );
};

ShowMoreLess.propTypes = {
  className: PropTypes.string.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  offset: PropTypes.number,
  pageNo: PropTypes.number.isRequired,
  position: PropTypes.oneOf([TOP, BOTTOM]),
  productsInPage: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired
};

export default connect(mapStateToProps)(helpers(['t'])(ShowMoreLess));
