import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { TradeTileBase } from '../trade-tile-base';
import { FULL, HALF, THIRD, QUARTER, FIFTH } from '../../../constants/dcs';

const TradeTileVertical = props => {
  const {
    body,
    componentClickHandler,
    image,
    landscapeImage,
    mobileBody,
    mobileStyle,
    ping,
    placement,
    primaryLink,
    secondaryLink,
    size,
    title
  } = props;

  const stampClasses = classnames(
    'trade-tile',
    'trade-tile--vertical',
    `trade-tile--size-${size}`,
    {
      'trade-tile--desktop-width-half': size === HALF,
      'trade-tile--mobile-width-half trade-tile--tablet-width-half':
        size === HALF && mobileStyle === HALF,
      'trade-tile--mobile-horizontal trade-tile--tablet-horizontal trade-tile--desktop-width-third':
        size === THIRD,
      'trade-tile--image-landscape': size === THIRD && !!landscapeImage,
      'trade-tile--mobile-width-half trade-tile--tablet-width-half trade-tile--desktop-width-quarter':
        size === QUARTER,
      'trade-tile--mobile-horizontal trade-tile--tablet-horizontal trade-tile--desktop-width-fifth':
        size === FIFTH,
      'trade-tile--mobile-hide-body':
        (size === HALF && !mobileBody) ||
        size === THIRD ||
        (size === QUARTER && !mobileBody) ||
        size === FIFTH
    }
  );

  const onClick = () => componentClickHandler?.(props);

  return (
    <TradeTileBase
      stampClasses={stampClasses}
      onClick={onClick}
      primaryLink={primaryLink}
      secondaryLink={secondaryLink}
      image={image}
      ping={ping}
      placement={placement}
      title={title}
      body={body}
    />
  );
};

TradeTileVertical.propTypes = {
  body: PropTypes.string,
  componentClickHandler: PropTypes.func,
  image: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    maxWidth: PropTypes.number
  }).isRequired,
  landscapeImage: PropTypes.bool,
  mobileBody: PropTypes.bool,
  mobileStyle: PropTypes.oneOf([HALF, FULL]),
  ping: PropTypes.shape({
    src: PropTypes.string,
    label: PropTypes.string
  }),
  placement: PropTypes.number,
  primaryLink: PropTypes.shape({
    href: PropTypes.string,
    label: PropTypes.string,
    newWindow: PropTypes.bool
  }).isRequired,
  secondaryLink: PropTypes.shape({
    href: PropTypes.string,
    label: PropTypes.string,
    newWindow: PropTypes.bool
  }),
  size: PropTypes.oneOf([HALF, THIRD, QUARTER, FIFTH]).isRequired,
  title: PropTypes.string.isRequired
};

TradeTileVertical.defaultProps = {
  body: null,
  componentClickHandler: null,
  landscapeImage: false,
  mobileBody: true,
  mobileStyle: FULL,
  placement: 1,
  ping: null,
  secondaryLink: null
};

export { TradeTileVertical };
