import styled, { css } from 'styled-components';
import { media } from '@beans/foundation';
import Button from '@beans/button';
import { Heading } from '@beans/heading';
import Icon from '@beans/icon';
import BeansLink from '@beans/link';
import Text from '@beans/text';
import { InLineMessaging } from '@beans/messaging';
import { colors, fontSize, spacing } from '@beans/selectors';

export const MultiSearchFormWrapper = styled.div`
  position: relative;
  background-color: ${colors.background};
  border: 1px solid ${colors.lines};
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${spacing.md} ${spacing.md} 0;
  align-items: flex-start;
  justify-content: flex-start;
  gap: ${spacing.md};
  text-align: left;
  margin: ${spacing.lg} 0;
  ${media.belowTablet`
  padding: ${spacing.md} ${spacing.sm} 0;
`}
`;

export const HeaderGroup = styled.header`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  position: sticky;
  z-index: 11;
  background-color: ${colors.background};
  padding: ${spacing.xx} 0;
  top: ${({ isAmendBasket }: { isAmendBasket: boolean }): number | string => (isAmendBasket ? '67px' : 0)};
  ${media.belowTablet`
  top: 0;
  align-items: start;
`}
  @media (max-width: 599px) {
    top: ${({ searchIsVisible }: { searchIsVisible: boolean }): string => (searchIsVisible ? '133px' : '33px')};
  }
`;

export const HeaderContainer = styled.div`
  position: relative;
  width: 100%;
  height: 46px;
  ${media.belowTablet`
    height: auto;
  `}
`;

export const HeaderTextButton = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: ${spacing.xs};
  margin-top: ${spacing.sm};
  ${media.belowTablet`
    align-items: flex-start;
    flex-direction: column;
    margin-top: 0;
 `}
`;

export const MyShoppingText = styled(Heading)`
  ${media.belowTablet`
    font-size: ${spacing.md};
  `}
`;

export const ClearListButton = styled(Button)`
  &.disableBackground {
    background-color: ${colors.background};
  }
`;

export const HeaderTextWrapper = styled.div`
  position: absolute;
  top: ${spacing.xl};
  width: 100%;
  height: 22px;
`;

export const HeaderCopyText = styled(Heading)`
  &.fontWeightColor {
    font-weight: 400;
    color: ${colors.grayscale};
  }
`;

export const HeaderButton = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
`;

export const SearchAllButton = styled(Button)`
  &.disableColor {
    color: ${colors.grayscale};
    background-color: ${colors.backgroundDarkest};
  }
  ${media.belowTablet`
    width: 100%;
  `}
`;
export const ListItemContainer = styled.ul`
  margin: 0;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  text-align: left;
  ${media.belowTablet`
  margin-bottom: 30px;
`}
`;

export const InfoMessage = styled.div`
  width: 100%;
  ${media.belowTablet`
    margin-top: ${spacing.md}};
  `}
`;

const MultiSearchListItem = styled.li`
  padding: ${spacing.xx} ${spacing.xs} ${spacing.xs} 0;
  min-height: 32px;
  max-width: 50%;
  display: inline-block;
  ${media.aboveTablet`
  display: ${({ isItemInCurrentPage }: { isItemInCurrentPage: boolean }): string =>
    isItemInCurrentPage ? 'inline-block' : 'none'};
    width: 20%;
  `}
`;

const StyledLink = styled(BeansLink)`
  width: 100%;
  text-overflow: ellipsis;
  font-size: ${fontSize.xs};

  ${({ isItemSelected }: { isItemSelected: boolean }): string =>
    isItemSelected &&
    css`
      color: ${colors.background};
      background-color: ${colors.linkActive};
      font-weight: bold;
      border-color: ${colors.linkActive};
    `}
`;

const StyledButtonWrapper = styled(Button)`
  display: inline-block;
  width: ${spacing.xl};
  min-width: ${spacing.xl};
  height: ${spacing.xl};
  border: solid 2px ${colors.tescoBlue};
  text-align: center;
  border-radius: ${spacing.sm};
  margin-left: ${spacing.xs};
  &:disabled {
    border: solid 2px ${colors.disabled};
  }
`;

const StyledIcon = styled(Icon)`
  width: 9px;
  height: 15px;
`;

const TooltipStyleDesktop = css`
  top: 86px;
  left: 7px;
  & .ddsweb-popover__pointer {
    left: 19px;
  }
`;

export const InfoText = styled(Text)`
  color: ${colors.text};
`;

const StyledInLineMessaging = styled(InLineMessaging)`
  ${media.aboveMobileLarge`
    display: none;
  `}
`;

export { StyledButtonWrapper, MultiSearchListItem, StyledIcon, StyledLink, TooltipStyleDesktop, StyledInLineMessaging };
