import React from 'react';
import PropTypes from 'prop-types';
import { InfoPanel } from './info-panel';
import { ResponsiveBackground } from '../responsive-background';
import Link from '../../link-check-spa';

const Slide = slide => {
  const { background, index, linkURL, tabTitle, onClick, catId } = slide;
  const clickHandler =
    onClick &&
    onClick.bind(null, {
      title: tabTitle,
      catId,
      ...slide.props
    });

  return (
    <Link
      to={linkURL}
      className={`ui-responsive-slider--slide slide-${index}`}
      onClick={clickHandler}
    >
      <ResponsiveBackground
        url={background.src}
        clipping={background.clipping}
        breakpoints={background.breakpoints}
        maxWidth={background.maxWidth}
        uid={slide.props.placement}
      />
      <InfoPanel {...slide} />
    </Link>
  );
};

Slide.propTypes = {
  slide: PropTypes.shape({
    backgroundImageURL: PropTypes.string,
    clipping: PropTypes.oneOf(['left', 'right', 'center']),
    linkURL: PropTypes.string,
    mainTitle: PropTypes.string,
    onClick: PropTypes.func,
    catId: PropTypes.string,
    placement: PropTypes.number
  })
};

export { Slide };
