import styled from 'styled-components';
import { media } from '@ddsweb/foundation';
import { colors, spacing } from '@ddsweb/selectors';
import { Heading } from '@ddsweb/heading';
import { TescoLogo } from '@ddsweb/branding';

export const StyledCardContainer = styled.div`
  padding: ${spacing.lg} ${spacing.md};
  border: 1px solid ${colors.lines};
  border-radius: 0;
  margin-bottom: ${spacing.xl};

  .slot-selector & {
    margin: 0;
  }

  ${media.aboveTabletLarge`
    padding: ${spacing.lg} ${spacing.md};

    .slot-selector & {
      margin: ${spacing.md};
    }
  `};
`;

export const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: ${spacing.sm};
  a {
    text-align: right;
  }
`;

export const StyledHeading = styled(Heading)`
  display: flex;
  color: ${colors.primary};
  font-weight: normal;
  line-height: 0.9;
  min-width: fit-content;
`;

export const StyledLogo = styled(TescoLogo)`
  width: 70px;
  margin-right: ${spacing.xs};
  padding-top: ${spacing.xx};
`;
