import React from 'react';
import PropTypes from 'prop-types';

const CMSAnalytics = ({ children, data }) => {
  const onClick = () => data?.componentClickHandler?.(data);
  return <div onClick={onClick}>{children}</div>;
};

CMSAnalytics.propTypes = {
  children: PropTypes.node,
  data: PropTypes.object
};

export default CMSAnalytics;
