import { TConfigFunc, TTranslateFunc } from '#/lib/records/helpers.defs';
import { basicEvent } from '#/analytics/types/basic';
import analyticsBus from '#/analytics/analyticsBus';
import { TOP_NAV, DELAY, NOW, ALL_DEPARTMENTS } from '#/analytics/constants';
import { menuDataAdapter } from '@ddsweb/helpers';
import { taxonomyAdapter } from '#/lib/taxonomy/adapator';
import { PrimaryMenu, PrimaryNavigationList, PrimaryNavigationItem, PrimaryNavItem } from '../types';
import { PRIMARY_NAV_ALL_DEPT_MENU_ID, PRIMARY_NAVIGATION } from '../constants';

type AllGroceriesList = {
  desktop: boolean;
  mobile: string;
  id: string;
  href: string;
  name: string;
  children: PrimaryNavigationList;
  ariaLabel: string;
  onClick?: (event: React.SyntheticEvent) => void;
};

export type CmsNav = { id: string; link: string; tabName: string };

export const getAllGroceries = (navList: PrimaryNavigationList, translate: TTranslateFunc): AllGroceriesList => {
  return {
    desktop: true,
    mobile: 'Groceries',
    id: PRIMARY_NAV_ALL_DEPT_MENU_ID,
    href: '',
    name: translate('navigation:all-departments'),
    children: navList,
    ariaLabel: translate('navigation:all-departments-aria-label'),
    onClick: (): void => {
      basicEvent(analyticsBus, {
        type: TOP_NAV,
        value: ALL_DEPARTMENTS,
        action: NOW,
      });
    },
  };
};

const getAllGroceriesRightTaxonomyMenus = (
  navList: PrimaryNavigationList,
  translate: TTranslateFunc,
): PrimaryNavigationList => {
  const allGroceries = getAllGroceries(navList, translate);
  return [allGroceries];
};

export const getTaxonomyData = (navList: PrimaryNavigationList, translate: TTranslateFunc): PrimaryNavigationList => {
  const taxonomyMenus: PrimaryNavigationList = getAllGroceriesRightTaxonomyMenus(navList, translate);
  return taxonomyMenus;
};

export const processString = (inputStr: string, node: string): string => {
  // Find the index of the last colon
  const lastIndex = inputStr.lastIndexOf(':');

  if (lastIndex !== -1) {
    // Return the substring from the start to the last colon and append ":all"
    return inputStr.substring(0, lastIndex) + ':' + node;
  }
  return inputStr;
};

export const createPrimaryNavigation = ({
  navList,
  translate,
  config,
  shopUrl,
  languageLink,
  cmsNav,
}: {
  navList: PrimaryNavigationList;
  translate: TTranslateFunc;
  config: TConfigFunc;
  languageLink: (value: string) => string;
  shopUrl: string;
  cmsNav: CmsNav;
}): Array<PrimaryMenu> => {
  const bigNav = config(PRIMARY_NAVIGATION) as Array<PrimaryNavItem>;
  const taxonomyData = getTaxonomyData(navList, translate);
  let menuItems = menuDataAdapter(taxonomyAdapter(translate, shopUrl), taxonomyData);

  const updatedNavItems: PrimaryNavigationList = bigNav.reduce((acc, value: PrimaryNavigationItem & PrimaryNavItem) => {
    if (value.cmsNav) {
      if (cmsNav) {
        acc.push({
          id: cmsNav.id,
          href: cmsNav.link,
          text: cmsNav.tabName,
          onClick: () =>
            basicEvent(analyticsBus, {
              type: TOP_NAV,
              value: cmsNav.tabName?.toLowerCase(),
              action: DELAY,
            }),
        });
      }
    } else {
      acc.push({
        id: value.id,
        href: value.target ? value.href : languageLink(value.href),
        text: translate(`navigation:${value.id}`),
        target: value.target || '_self',
        onClick: () =>
          basicEvent(analyticsBus, {
            type: TOP_NAV,
            value: value.analyticsValue,
            action: value.analyticsAction || DELAY,
          }),
      });
    }
    return acc;
  }, [] as PrimaryNavigationList);

  menuItems = [...menuItems, ...updatedNavItems];

  return menuItems;
};
