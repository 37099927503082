import React, { Component } from 'react';
import PropTypes from 'prop-types';
import helpers from '#/lib/decorators/helpers';
import EmptySection from '#/components/shared/empty-section';
import {
  FAVORITES,
  USUALS,
  SEARCH,
  CATEGORY,
  MISSED_PROMOTIONS,
  PROMOTIONS_HOMEPAGE,
  PROMOTIONS,
  ORDERS,
  BUYLIST,
  FAVORITES_CATEGORY
} from '#/constants/result-types';
import { connect } from '#/lib/render/connect-deep-compare';
import { emitRenderedContentOp } from '#/analytics/bertie/events';
import { getIsOnDemandDelivery } from '#/selectors/trolley';
import { LAST_ORDER_TAB } from '#/constants/favorites';

const mapStateToProps = state => ({
  isOnDemand: getIsOnDemandDelivery(state)
});

@helpers(['t', 'f', 'c'])
@connect(mapStateToProps)
export default class NoResults extends Component {
  static propTypes = {
    expanded: PropTypes.bool,
    f: PropTypes.func.isRequired,
    isOnDemand: PropTypes.bool.isRequired,
    query: PropTypes.string,
    t: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired
  };

  static defaultProps = {
    expanded: false,
    query: ''
  };

  componentDidMount() {
    const { type } = this.props;

    emitRenderedContentOp({
      content: [
        {
          pageType: type
        }
      ]
    });
  }

  renderFavContent = () => {
    const { isOnDemand, t: translate } = this.props;
    const headingText = isOnDemand
      ? translate('product-list:express-delivery-favourites-unavailable')
      : translate('product-list:you-currently-have-no-favourites');
    const subHeadingText = isOnDemand
      ? translate('search:no-results-favorites-express-delivery')
      : translate('search:no-results-favorites');
    return (
      <EmptySection
        headingText={headingText}
        subHeadingText={subHeadingText}
        icon="favorites2"
        showSubHeadingMobile
      />
    );
  };

  renderUsualsContent = () => {
    const { isOnDemand, t: translate, c: config } = this.props;
    const useNewLayoutUsuals = config('useNewLayoutUsuals');
    const headingText = translate(
      isOnDemand
        ? 'product-list:you-currently-have-no-usuals_ondemand'
        : useNewLayoutUsuals
        ? 'favorites:no-more-usuals.heading'
        : 'product-list:you-currently-have-no-usuals'
    );
    const isNotOnDemandAndNewLayout = !isOnDemand && useNewLayoutUsuals;
    const subHeadingText = translate(
      isNotOnDemandAndNewLayout
        ? 'favorites:no-more-usuals.subheading'
        : 'search:no-results-usuals'
    );
    const icon = isNotOnDemandAndNewLayout ? 'favourites' : 'usuals_icon-light';
    const primaryButtonText = translate(
      isNotOnDemandAndNewLayout
        ? 'favorites:no-more-usuals.primary-button-text'
        : 'home:new-user-banner.browse-the-store'
    );
    const className = isNotOnDemandAndNewLayout ? 'usuals-empty-section' : '';

    return (
      <EmptySection
        headingText={headingText}
        icon={icon}
        subHeadingText={subHeadingText}
        showSubHeadingMobile
        showEmptyTrolleyButtons={false}
        showBeansIcon={isNotOnDemandAndNewLayout}
        primaryButtonText={primaryButtonText}
        className={className}
      />
    );
  };

  render() {
    const { query, type, f: feature, t: translate } = this.props;

    switch (type) {
      case SEARCH:
        return (
          <EmptySection
            headingText={translate(
              'search:no-results.we-could-not-find-anything',
              { query: `“${query}”` }
            )}
            icon="search-no-results"
            subHeadingText={translate('search:no-results.search-tips-content')}
            showSubHeadingMobile
            feedbackEnabled={feature('feedbackLinks')}
            type={type}
          />
        );
      case FAVORITES:
        return this.renderFavContent();
      case FAVORITES_CATEGORY:
        return (
          <EmptySection
            headingText={translate(
              'product-list:you-currently-have-no-favourites-in-this-category'
            )}
            icon="favorites2"
            subHeadingText={translate('search:no-results-favorites')}
            showSubHeadingMobile
          />
        );

      case USUALS:
        return this.renderUsualsContent();
      case LAST_ORDER_TAB:
        return (
          <EmptySection
            headingText={translate(
              'product-list:you-havenot-placed-an-order-yet'
            )}
            icon="empty-trolley"
            subHeadingText={translate('search:no-results-last-order')}
            showSubHeadingMobile
            type={type}
          />
        );
      case MISSED_PROMOTIONS:
        return (
          <EmptySection
            headingText={translate('trolley:missed-promotions.all-set')}
            icon="zero-missed-promotions"
            subHeadingText={translate('trolley:missed-promotions.tap-continue')}
          />
        );
      case ORDERS:
        return (
          <EmptySection
            headingText={translate('orders:my-orders.no-orders')}
            icon="orders"
            subHeadingText={translate('trolley:empty-trolley.sub-heading')}
          />
        );
      case CATEGORY:
        return (
          <EmptySection
            headingText={translate('product-list:no-products-available')}
            icon="info2"
            subHeadingText={translate('product-list:widen-your-category')}
          />
        );
      case BUYLIST:
        return (
          <EmptySection
            headingText={translate(
              'Search:no-results.sorry-but-we-cant-find-these-products-anymore'
            )}
            icon="buylist"
            subHeadingText={translate(
              'Search:no-results.find-what-you-are-looking-for'
            )}
          />
        );
      case PROMOTIONS:
        return (
          <EmptySection
            headingText={translate('promotions:no-promotions-category')}
            icon="promo-bubble"
            subHeadingText={translate(
              'promotions:no-promotions-current-offers'
            )}
            primaryButton="promotions"
          />
        );
      case PROMOTIONS_HOMEPAGE:
        return (
          <EmptySection
            headingText={translate('promotions:no-promotions-available')}
            icon="promo-bubble"
            subHeadingText=""
          />
        );
      default:
        return (
          <EmptySection
            headingText={translate('search:no-products')}
            icon="search-no-results"
            subHeadingText=""
          />
        );
    }
  }
}
