import { clientGraphqlPost } from './client-graphql';
import { createTrexTraceId } from '#/lib/recommender-helpers';
import {
  buildVariables,
  getQuery,
  Strategy,
  formatRecommendationsResponse,
  PageInformation,
} from './helpers/get-tesco-recommendations-helper';
import { logApmError } from '#/lib/apm';
import { Recommendation } from './graphql-queries/recommendation-types';
import { Item } from '../records/item';
import { GET_TESCO_RECOMMENDATIONS } from '#/constants/request-names';

export type RequestConfig = {
  accessToken: string;
  apiKey: string;
  atrc: string;
  endpoint: string;
  region: string;
  trace_id: string;
};

export type ClientTescoRecommendationsResponse =
  | {
      [key: string]: {
        productItems: Array<Item>;
        pageInformation: PageInformation;
      };
    }
  | { empty: boolean };
type ErrorResponse = {
  status: number;
  message: string;
  parsedBody?: {
    errors?: Error[];
  };
};

export const fetchTescoRecommendations = async (
  variables: Recommendation,
  strategies: Strategy[],
  { accessToken, apiKey, atrc, endpoint, region, trace_id }: RequestConfig,
): Promise<ClientTescoRecommendationsResponse | { error?: boolean }> => {
  const graphqlQuery = getQuery(strategies);
  const queryVariables = buildVariables(variables, strategies);

  const traceId = createTrexTraceId();
  const context: Record<string, string> = {
    region,
    traceId,
    'x-apikey': apiKey,
  };

  if (accessToken) {
    context.Authorization = `Bearer ${accessToken}`;
  }

  try {
    const requestName = GET_TESCO_RECOMMENDATIONS;
    const response = await clientGraphqlPost(
      endpoint,
      context,
      graphqlQuery,
      queryVariables,
      atrc,
      requestName,
      trace_id,
    );

    return formatRecommendationsResponse(response.data, variables.trolleyItems || []);
  } catch (errResponse) {
    const res = errResponse as ErrorResponse;

    if (window) {
      const { pageId } = queryVariables;

      const error = res?.parsedBody?.errors?.[0];
      const statusCode = res?.status || 'unknown';
      const errorMessage = error?.message || res.message || 'unknown';
      const message = `Recommendation Error - Message: ${errorMessage}, Status: ${statusCode}, PageId: ${pageId}`;

      logApmError(new Error(message), { traceId });
    }

    return {
      error: true,
    };
  }
};
