import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Link from '../../link-check-spa';
import { removeModalFromUrl } from '../../../lib/url/modal-utils';
import helpers from '../../../lib/decorators/helpers';

const ModalCloseXLink = ({ onClick, className, linkUrl, t }) => {
  const otherProps = {};

  if (onClick && typeof onClick === 'function') {
    otherProps.onClick = event => {
      event && event.preventDefault();
      onClick();
    };
  }

  return (
    <Link
      className={classnames('modal-buttons__close', 'icon-cancel', className)}
      to={removeModalFromUrl(linkUrl)}
      {...otherProps}
    >
      <span className="visually-hidden"> {t('common:close')} </span>
    </Link>
  );
};

ModalCloseXLink.propTypes = {
  className: PropTypes.string,
  linkUrl: PropTypes.string,
  onClick: PropTypes.func,
  t: PropTypes.func.isRequired
};

export default helpers(['t'])(ModalCloseXLink);
