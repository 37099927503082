import styled, { css } from 'styled-components';
import { colors, spacing } from '@ddsweb/selectors';
import { Heading } from '@ddsweb/heading';
import { DELIVERY, ShoppingMethod } from '#/constants/shopping-methods';

export const StyledAddressSlotInfoReskinContainer = styled.div`
  border: 1px solid ${colors.lines};
  padding: ${spacing.lg} ${spacing.md};
  margin-bottom: ${spacing.lg};

  ${({ shoppingMethod }: { shoppingMethod: ShoppingMethod }): string | false =>
    shoppingMethod !== DELIVERY &&
    css`
      margin-top: ${spacing.md};
    `}
`;

export const StyledHeading = styled(Heading)`
  && {
    float: left;
    margin-right: ${spacing.xx};
  }
`;
