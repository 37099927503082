import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Link from '#/components/link-check-spa';

// Temp overrides for fonts until its relaced by the beans link.

export const StyledLink = styled(props => <Link {...props} />)`
  display: block;
  font-weight: bold;
  padding: 5px 0 6px;
  font-family: 'TESCO Modern', Arial, sans-serif;
  :after {
    margin-top: 2px;
  }
`;

export const TileLink = ({ dataAuto, ariaLabel, href, children, onClick }) => (
  <StyledLink
    className="icon-chevron_right-link"
    to={href}
    aria-label={ariaLabel}
    data-auto={dataAuto}
    onClick={onClick}
  >
    {children}
  </StyledLink>
);

TileLink.propTypes = {
  ariaLabel: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

export default memo(TileLink);
