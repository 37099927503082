import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Text } from '@ddsweb/text';
import { Heading } from '@ddsweb/heading';
import Button from '@ddsweb/button';
import { closeModal } from '#/actions/ui-action-creators';
import { getCurrentUrl } from '#/reducers/app';
import { updateParamsInUrl } from '#/lib/url/url-utils';
import { connect } from '#/lib/render/connect-deep-compare';
import {
  ModalButtonContainer,
  StyledModal,
  StyledTextContainer
} from './styled';

const mapStateToProps = state => {
  const currentUrl = getCurrentUrl(state);
  return {
    closeHref: updateParamsInUrl(currentUrl, {
      currentModal: null,
      modalData: null
    }),
    currentUrl
  };
};

@connect(mapStateToProps, { closeModal })
export default class FakeDoorTestModal extends Component {
  static propTypes = {
    closeHref: PropTypes.string.isRequired,
    closeModal: PropTypes.func.isRequired,
    modalData: PropTypes.shape({
      title: PropTypes.string,
      bodyTextPrimary: PropTypes.string,
      bodyTextSecondary: PropTypes.string,
      selectOptions: PropTypes.node,
      buttonText: PropTypes.string,
      buttonDisabled: PropTypes.bool,
      isNextModal: PropTypes.bool,
      onClick: PropTypes.func,
      onChange: PropTypes.func
    })
  };

  handleOnClose = () => {
    const {
      closeModal,
      modalData: { onClick }
    } = this.props;

    onClick ? onClick() : closeModal();
  };

  handleOnChange = () => {
    const {
      closeModal,
      modalData: { onChange }
    } = this.props;

    onChange ? onChange() : closeModal();
  };

  componentDidUpdate(prevProps) {
    if (this.props.currentUrl !== prevProps.currentUrl) {
      this.props.closeModal();
    }
  }

  render() {
    const {
      title,
      bodyTextPrimary,
      bodyTextSecondary,
      selectOptions,
      buttonText,
      buttonDisabled
    } = this.props.modalData;
    return (
      <StyledModal
        open
        ssr
        closeHref={this.props.closeHref}
        className="fake-door-test-modal"
        id="fakeDoorTestModal"
        onChange={this.handleOnChange}
      >
        <div className="fake-door-test-modal__content">
          <Heading headingLevel="1" visualSize="headline3">
            {title}
          </Heading>

          <StyledTextContainer>
            <Text as="p" margin className="fake-door-test-modal__body">
              {bodyTextPrimary}
            </Text>
            {bodyTextSecondary && (
              <Text as="p" margin>
                {bodyTextSecondary}
              </Text>
            )}
            {selectOptions && (
              <Text as="p" margin className="fake-door-test-modal__body">
                {selectOptions}
              </Text>
            )}
          </StyledTextContainer>

          <ModalButtonContainer>
            <Button
              id="fake-door-test-modal__ok-btn"
              variant="primary"
              onClick={this.handleOnClose}
              disabled={buttonDisabled}
            >
              {buttonText}
            </Button>
          </ModalButtonContainer>
        </div>
      </StyledModal>
    );
  }
}
