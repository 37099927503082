import React from 'react';
import PropTypes from 'prop-types';
import ProductPromotions from '#/components/products/product-tile/tile-components/product-promotions';
import { PRODUCT_TILE } from '#/constants/analytics';
import {
  getProductIsForSale,
  getPromotions,
  hasPromotion,
  getProductDrsChargeAmount
} from '#/selectors/item';

export default class RenderPromotions extends React.PureComponent {
  static propTypes = {
    displayAvailable: PropTypes.bool,
    isAlreadyShowingRdgMsg: PropTypes.bool,
    isAmendBasket: PropTypes.bool,
    item: PropTypes.object.isRequired,
    largeViewPromotion: PropTypes.bool,
    showCompletePromoMessage: PropTypes.bool,
    showDatesOnlyOnMissedSlot: PropTypes.bool,
    tile: PropTypes.string,
    viewAndBuyButtonUrl: PropTypes.string
  };

  static defaultProps = {
    largeViewPromotion: false,
    displayAvailable: false,
    showDatesOnlyOnMissedSlot: false,
    showCompletePromoMessage: true,
    tile: PRODUCT_TILE
  };

  render() {
    const {
      item,
      largeViewPromotion,
      showCompletePromoMessage,
      showDatesOnlyOnMissedSlot,
      isAlreadyShowingRdgMsg,
      isAmendBasket,
      tile,
      displayAvailable,
      offersIcid
    } = this.props;
    const hasDeposit = !!getProductDrsChargeAmount(item);
    if (hasPromotion(item) && (displayAvailable || getProductIsForSale(item))) {
      return (
        <ProductPromotions
          hasDeposit={hasDeposit}
          hideSmall={largeViewPromotion}
          showCompletePromoMessage={showCompletePromoMessage}
          showDatesOnlyOnMissedSlot={showDatesOnlyOnMissedSlot}
          isAlreadyShowingRdgMsg={isAlreadyShowingRdgMsg}
          isAmendBasket={isAmendBasket}
          offersIcid={offersIcid}
          itemPromotions={getPromotions(item)}
          tile={tile}
        />
      );
    }

    return null;
  }
}
