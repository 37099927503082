import { SYNC_PRODUCT_WITH_TROLLEY, UPDATE_PRODUCT_DETAILS_RESULT } from '#/constants/action-types';
import { PRODUCT_DETAILS } from '#/constants/spa-resource';
import { createItem } from '#/lib/records';
import { findAndSetTrolleyItems, getById } from '#/lib/records/product-utils';
import { getResourceData } from '#/resources/resource-util';

export default function(state = {}, action) {
  switch (action.type) {
    case UPDATE_PRODUCT_DETAILS_RESULT:
      return {
        ...state,
        ...action.payload,
      };

    case SYNC_PRODUCT_WITH_TROLLEY:
      return {
        ...state,
        item: action.payload.item,
      };

    default:
      return state;
  }
}

export function getDefaultStateFromProps(props) {
  const {
    aisleId,
    aisleName,
    backToUrl,
    brandBankAttributes,
    breadcrumbs,
    departmentId,
    departmentName,
    experiments,
    hazardInformation,
    product,
    promotions,
    recommendations,
    referer,
    restOfShelfUrl,
    shelfName,
    superDepartmentId,
    superDepartmentName,
    template,
  } = getResourceData(props.resources, PRODUCT_DETAILS) || {};

  const productId = product?.id;

  const item = createItem({
    product: {
      ...product,
      aisleId,
      shelfName,
      aisleName,
      departmentId,
      departmentName,
      superDepartmentId,
      superDepartmentName,
    },
    promotions,
  });

  const trolleyItems = props.trolley?.items ?? [];
  const transformedItems = findAndSetTrolleyItems([item], trolleyItems);
  const transformedItem = getById(transformedItems, productId) || item;

  return {
    backToUrl,
    brandBankAttributes,
    breadcrumbs,
    experiments,
    hazardInformation,
    item: transformedItem,
    product,
    recommendations,
    referer,
    restOfShelfUrl,
    template,
  };
}
