import queryString from 'querystring';
import delimitQuery from '../components/mixins/delimit-query';
import { cloneDeep } from '#/utils/clone-deep';
import { getProductSourceText } from '#/selectors/resources';

export function removeFilterCategoryFromUrl(
  category,
  currentUrl,
  currentPathname,
  filtersToRemove = []
) {
  category = category.toLowerCase();

  const qs = delimitQuery.parseQuerystring(currentUrl);

  delete qs.page;
  delete qs.offset;
  delete qs[category];
  const view = (qs.viewAll || '').split(',');

  view.splice(view.indexOf(category), 1);

  if (view.length) {
    qs.viewAll = view.join(',');
  } else {
    delete qs.viewAll;
  }

  if (filtersToRemove.length) {
    for (let i = 0; i < filtersToRemove.length; i++) {
      let key = filtersToRemove[i].toLowerCase();
      if (qs?.[key]) {
        delete qs?.[key];
      }
    }
  }

  const urlParams = queryString.stringify(qs);

  return currentPathname + (urlParams.length ? `?${urlParams}` : '');
}

/*
  Translates the facet if the brand is Unbranded, if not returns the original facet name - lego 2061
*/
export function translateUnbrandedFacet(facetName, translate) {
  const updatedFacetName = getProductSourceText(facetName, translate);
  return typeof translate !== 'function' || updatedFacetName !== 'Unbranded'
    ? updatedFacetName
    : translate('sort-and-filter:unbranded');
}

export function isFacetSelected(
  facetType,
  facetName,
  facetList,
  delimit,
  multiSelectionFacets
) {
  if (!facetList) return false;

  if (multiSelectionFacets.includes(facetType)) {
    return facetList.split(delimit).some(currFacet => currFacet === facetName);
  }

  return facetList === facetName;
}

export function setSelectedFacets(
  facets,
  queryParams,
  delimit,
  multiSelectionFacets
) {
  return cloneDeep(facets).map(facetList => {
    const facetType = facetList.category.toLowerCase();

    facetList.facets = facetList.facets.map(facet => {
      facet.isSelected = isFacetSelected(
        facetType,
        facet.facetId.toString(),
        queryParams[facetType],
        delimit,
        multiSelectionFacets
      );

      return facet;
    });

    return facetList;
  });
}
