export const ENV_DISABLED_HOMEPAGE_FAV_CAROUSEL = 'favouritesHomepageCarousel';
export const ENV_DISABLED_HYF = 'hyf';
export const ENV_DISABLED_MVT = 'mvt';
export const ENV_DISABLED_TESCO_RECOMMENDATIONS = 'tescoRecommendations';
export const ENV_DISABLED_DCS_UK_HOMEPAGE = 'dcsUKHomepage';
export const ENV_DISABLED_DCS_CE_HOMEPAGE = 'dcsCEHomepage';
export const ENV_DISABLED_DCS_AP_HOMEPAGE = 'dcsAPHomepage';
export const ENV_DISABLED_DCS_CONTENT = 'DCSContent';
export const ENV_DISABLED_PRODUCT_REVIEWS = 'productReviews';
export const ENV_DISABLED_ADD_ALL_USUALS = 'addAllUsuals';
