import recursivelyFetchResources from '#/conditional-resources/helpers/recursively-fetch-resources';
import { logApmError } from '#/lib/apm';

function fetchResourceData(fetchResources, params, options) {
  return async resourceNames => {
    try {
      return await fetchResources(resourceNames, params, options);
    } catch (error) {
      logApmError(error);
      return undefined;
    }
  };
}

export default async function clientFetchResourcesWrapper(
  relevantResourcesFromRoutes,
  ...rest
) {
  return recursivelyFetchResources(
    relevantResourcesFromRoutes,
    fetchResourceData(...rest)
  );
}
