import React from 'react';
import PropTypes from 'prop-types';
import AddressSlotInfo from '../address-slot-info';
import Map from '../map';

const CollectionAddress = props => {
  const { bookedSlotLocation, ...rest } = props;
  const point = {
    ...bookedSlotLocation
  };

  delete point.displayOrder;

  return (
    <div className="collection-address--section">
      <AddressSlotInfo {...rest} />
      <Map
        center={{
          latitude: bookedSlotLocation.latitude,
          longitude: bookedSlotLocation.longitude
        }}
        points={[point]}
        disablePanning={true}
        disableZooming={true}
        disableTouchInput={true}
      />
    </div>
  );
};

CollectionAddress.propTypes = {
  address: PropTypes.object,
  bookedSlotLocation: PropTypes.object.isRequired,
  shoppingMethod: PropTypes.string,
  slot: PropTypes.object
};

export default CollectionAddress;
