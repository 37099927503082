import { TTranslateFunc } from '#/lib/records/helpers.defs';
import { AdaptMenuDataOptions, PropsAdapter } from '@beans/helpers/lib/types/menu-data-adapter/menu-data-adapter.types';

export const taxonomyAdapter = (translate: TTranslateFunc, shopUrl: string): PropsAdapter => ({
  id: (menuItemData: PropsAdapter & { name: string }) => getMenuId(menuItemData),
  hasPopup: (menuItemData: PropsAdapter) => !!menuItemData.children && !!menuItemData.children.length,
  href: (menuItemData: PropsAdapter) => getMenuUrl(menuItemData, shopUrl),
  popupIcon: (_: AdaptMenuDataOptions, context: AdaptMenuDataOptions) => context.level === 0,
  text: (menuItemData: PropsAdapter) => getMenuName(menuItemData, translate),
});

export const getMenuName = (menuData: PropsAdapter, translate: TTranslateFunc): string => {
  if (menuData.allNode) return `${translate('shop-all')} ${menuData.name}`;
  if (menuData.allPromotionNode) return `${translate('shop-all-with-promotion')} ${menuData.name}`;
  return menuData.name as string;
};

const isPathEndingWithAll = (url: string): boolean => /\/all(\/)?(\?|$)/.test(url);

export const getMenuUrl = (menuData: PropsAdapter, shopUrl: string): string => {
  const { externalUrl, url, children, href } = menuData;

  if (externalUrl) return externalUrl as string;

  if (url && !isPathEndingWithAll(url as string) && (!children || children.length === 0)) {
    return `${shopUrl}${url}/all`;
  }

  const menuUrl = href || url || '/';

  return `${shopUrl}${menuUrl}`;
};

const getMenuId = ({ id, name, label }: PropsAdapter): string => {
  //@ts-ignore
  const itemId: string = (id || (name as string))?.toLowerCase();

  return label ? `${label}|${itemId}` : itemId;
};
