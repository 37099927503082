import React, { PureComponent } from 'react';
import Link from '../../link-check-spa';
import { emitRegisterAnalytics } from '#/analytics/helpers/register';
import helpers from '#/lib/decorators/helpers';
import { connect } from '#/lib/render/connect-deep-compare';
import PropTypes from 'prop-types';
import { getLoginUrl, getRegisterUrl } from '#/reducers/app';

const mapStateToProps = state => ({
  loginUrl: getLoginUrl(state),
  registrationUrl: getRegisterUrl(state)
});

@helpers(['t'])
@connect(mapStateToProps)
export default class NavigationHeaderSignedOut extends PureComponent {
  static propTypes = {
    loginUrl: PropTypes.string.isRequired,
    registrationUrl: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired
  };
  handleClick = value => () => {
    emitRegisterAnalytics(value);
  };

  render() {
    const { t, loginUrl, registrationUrl } = this.props;
    return (
      <header className="main-nav-header">
        <Link
          className="button button-primary main-nav-header-button"
          to={loginUrl}
        >
          {t('common:sign-in')}
        </Link>
        <div className="vertical-divider" />
        <Link
          className="button button-primary main-nav-header-button"
          to={registrationUrl}
          onClick={this.handleClick('header')}
        >
          {t('common:register')}
        </Link>
      </header>
    );
  }
}
