import React, { Component } from 'react';
import PropTypes from 'prop-types';
import helpers from '#/lib/decorators/helpers';
import * as mapConfigurations from '../configurations';

@helpers(['c'])
export default class StaticMap extends Component {
  static propTypes = {
    c: PropTypes.func.isRequired,
    center: PropTypes.shape({
      latitude: PropTypes.string.isRequired,
      longitude: PropTypes.string.isRequired
    }).isRequired,
    provider: PropTypes.string.isRequired,
    withPin: PropTypes.bool,
    zoom: PropTypes.number
  };

  static defaultProps = {
    zoom: 13,
    withPin: true
  };

  constructor(props) {
    super(props);
    this.mapAttributes = new mapConfigurations[props.provider]();
  }

  render() {
    const { c: config, zoom, center, withPin } = this.props;
    const mapSrc = this.mapAttributes.getStaticMapSrc(
      config,
      zoom,
      center.latitude,
      center.longitude,
      withPin
    );
    const key = this.mapAttributes.mapKey(config);
    const mapImageSrc = this.mapAttributes.appendKey(mapSrc, key);

    return (
      <div
        className="map static-map"
        style={{
          background: `url('${mapImageSrc}') center center`,
          backgroundSize: 'cover'
        }}
      />
    );
  }
}
