import { getFeatureVariableStringFromState } from '#/lib/optimizely-manager';
import { slotsUIReskinV2Variants } from '#/experiments/oop-2205/constants';
import { getCurrentUrl } from '#/reducers/app';
import { COLLECTION, DELIVERY } from '#/constants/shopping-methods';

const {
  featureKey,
  variationKey,
  useCustomerId,
  variants: { default: defaultVariant, addNewAddressHeader, addNewAddressHeaderAndSimplify },
} = slotsUIReskinV2Variants;

export const shouldShowSlotUIReskinV2 = (state: Store): boolean => {
  const url = getCurrentUrl(state);

  return url.includes(COLLECTION) || url.includes(DELIVERY);
};

export const getSlotUIReskinV2Variant = (state: Store): string => {
  const variant = getFeatureVariableStringFromState(state, featureKey, variationKey, { useCustomerId });

  return variant ?? defaultVariant;
};

export const getShowSlotUIReskinV2 = (state: Store): boolean => {
  return shouldShowSlotUIReskinV2(state) && getSlotUIReskinV2Variant(state) !== defaultVariant;
};

export const getShowAddNewAddressHeaderVariant = (state: Store): boolean =>
  shouldShowSlotUIReskinV2(state) && getSlotUIReskinV2Variant(state) === addNewAddressHeader;

export const getShowAddNewAddressHeaderAndSimplifyVariant = (state: Store): boolean =>
  shouldShowSlotUIReskinV2(state) && getSlotUIReskinV2Variant(state) === addNewAddressHeaderAndSimplify;
