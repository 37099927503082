import styled from 'styled-components';
import { Text } from '@ddsweb/text';
import Heading from '@ddsweb/heading';
import { TESCO_MODERN_FONT_FAMILY } from '@ddsweb/constants';
import { colors, fontSize, spacing } from '@ddsweb/selectors';
import { media } from '@ddsweb/foundation';

export const StyledHeading = styled(Heading)`
  margin-bottom: ${spacing.sm};
`;

export const StyledText = styled(Text)`
  font-family: ${TESCO_MODERN_FONT_FAMILY};
  font-size: ${fontSize.xs};
  color: ${colors.grayscale};
  margin-bottom: ${spacing.md};
`;

export const StyledButtonContainer = styled.div`
  justify-content: left;
  display: flex;
  gap: ${spacing.sm};
  font-size: ${fontSize.xs};

  ${media.belowTabletLarge`
      flex-direction: column;
  `}
`;
