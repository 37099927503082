import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Price from '../../shared/price';

const OrderSummaryLineItem = props => (
  <dl
    className={classnames('trolley-order-summary--row', props.rowClassName)}
    data-auto={props.dataAuto}
  >
    <dt className="trolley-order-summary--label">{props.children}</dt>
    <dd className={props.priceClassName}>
      {props.currency ? (
        <Price
          {...Object.assign(
            {
              className: 'amount',
              value: props.charge
            },
            props.currency
          )}
        />
      ) : (
        props.charge
      )}
    </dd>
  </dl>
);

OrderSummaryLineItem.propTypes = {
  charge: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  currency: PropTypes.object,
  dataAuto: PropTypes.string,
  priceClassName: PropTypes.string,
  rowClassName: PropTypes.string
};

export default OrderSummaryLineItem;
