import React from 'react';
import PropTypes from 'prop-types';
import Button from '@ddsweb/button';
import Link from '@ddsweb/link';
import { connect } from '#/lib/render/connect-deep-compare';
import analyticsBus from '#/analytics/analyticsBus';
import { basicEvent } from '#/analytics/types/basic';
import helpers from '#/lib/decorators/helpers';
import { removeModalFromUrl } from '#/lib/url/modal-utils';
import { getCurrentUrl } from '#/reducers/app';
import { getAmendOrderNo } from '#/selectors/trolley';
import { closeModal } from '#/actions/ui-action-creators';
import { AMEND_CANCEL_MODAL } from '#/constants/modal-names';
import SafeForm from '#/components/shared/safe-form';
import DialogContent from '#/components/modals/dialog-content/dialog-content';
import { StyledModal } from '../styled';
import { setFocus } from '#/lib/browser/ui-util';

const mapStateToProps = state => ({
  currentUrl: getCurrentUrl(state),
  amendOrderNo: getAmendOrderNo(state)
});
@connect(mapStateToProps, { closeModal })
@helpers(['t', 'l'])
export default class AmendOrderCancelModal extends React.Component {
  static propTypes = {
    amendOrderNo: PropTypes.string.isRequired,
    cancelAmendRedirectUrl: PropTypes.string,
    closeModal: PropTypes.func.isRequired,
    currentUrl: PropTypes.string.isRequired,
    l: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
  };

  constructor() {
    super();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      submitting: false
    };
    this.lastActiveElement = React.createRef();
    this.lastActiveElement.current = document.activeElement;
  }

  handleSubmit() {
    this.setState({
      submitting: true
    });

    basicEvent(analyticsBus, {
      type: 'click',
      value: 'amend cancel',
      action: 'now'
    });
  }

  onCancelClick = () => {
    this.props.closeModal(AMEND_CANCEL_MODAL);
    this.timeout = setTimeout(() => {
      setFocus(this.lastActiveElement.current);
      clearTimeout(this.timeout);
    }, 0);
  };

  render() {
    const {
      amendOrderNo,
      cancelAmendRedirectUrl,
      currentUrl,
      l: language,
      t: translate
    } = this.props;

    const returnUrl = removeModalFromUrl(currentUrl);

    const backButton = (
      <Link
        buttonVariant="secondary"
        variant="textButton"
        disabled={this.state.submitting}
        href={returnUrl}
        onClick={e => {
          e.preventDefault();
          this.onCancelClick();
        }}
      >
        {translate('orders:amend.go-back')}
      </Link>
    );

    const primaryButton = (
      <SafeForm
        action={language('/orders/amend?_method=DELETE')}
        method={'post'}
        onSubmit={this.handleSubmit}
      >
        <input
          type="hidden"
          name="returnUrl"
          value={cancelAmendRedirectUrl || returnUrl}
        />
        <input name="loggedInAction" type="hidden" value="cancel-amend" />
        <input type="hidden" name="orderNo" value={amendOrderNo} />
        <Button name="submit" type="submit" isLoading={this.state.submitting}>
          {translate('orders:amend.yes-cancel')}
        </Button>
      </SafeForm>
    );

    return (
      <StyledModal
        open
        onChange={this.onCancelClick}
        closeLink={!this.state.submitting}
        persist={this.state.submitting}
      >
        <DialogContent
          title={translate('orders:amend.cancel-changes?')}
          body={translate('orders:amend.changes-not-saved')}
          backButton={backButton}
          primaryButton={primaryButton}
        />
      </StyledModal>
    );
  }
}
