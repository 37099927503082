import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { EventButton } from '../event-button';
import { generateIdPropFromTitle } from '#/lib/dcs/dcs-helpers';

const InfoTextModule = props => {
  const {
    componentClickHandler,
    dividerColor,
    hasLine,
    link,
    priority,
    secLink,
    secPriority,
    secTitle,
    subTitleColor,
    tableBackground,
    title,
    titleColor
  } = props;

  const onClick = () => componentClickHandler?.(props);

  const listOne = props.item.map((item, index) => {
    if (!item) return null;

    const { listTitle, listSubtitle } = item;

    return (
      <li
        key={index}
        className={classnames('info-text-module_item', [
          `info-text-module_item_${priority}`
        ])}
      >
        <i className="icon icon-dot-grey" />
        {listTitle ? (
          <div
            {...generateIdPropFromTitle(listTitle)}
            className="item-title"
            style={titleColor}
          >
            {listTitle}
          </div>
        ) : null}
        {listSubtitle ? (
          <div
            className={classnames('item-description', {
              'item-no--title': !listTitle
            })}
            style={subTitleColor}
          >
            {listSubtitle}
          </div>
        ) : null}
      </li>
    );
  });

  const listTwo = props.secItem.map((secItem, index) => {
    if (!secItem) return null;

    const { listTitle, listSubtitle } = secItem;

    return (
      <li
        key={index}
        className={classnames('info-text-module_item sec-list', [
          `info-text-module_item_${secPriority}`
        ])}
      >
        <i className="icon icon-tick-grey" />
        {listTitle ? (
          <div
            {...generateIdPropFromTitle(listTitle)}
            className="item-title"
            style={titleColor}
          >
            {listTitle}
          </div>
        ) : null}
        {listSubtitle ? (
          <div
            className={classnames('item-description', {
              'item-no--title': !listTitle
            })}
            style={subTitleColor}
          >
            {listSubtitle}
          </div>
        ) : null}
      </li>
    );
  });

  return (
    <div className="info-text-module" style={tableBackground}>
      <h3
        {...generateIdPropFromTitle(title)}
        className="info-text-module_title"
      >
        {title}
      </h3>
      <ul className="info-text-module_list clear">{listOne}</ul>

      {link && link.label ? (
        <EventButton link={link} button={{ margin: true }} onClick={onClick} />
      ) : null}

      {hasLine ? (
        <span
          className={classnames({ divider: hasLine })}
          style={dividerColor}
        />
      ) : null}

      {secTitle ? (
        <h3
          {...generateIdPropFromTitle(secTitle)}
          className="info-text-module_title"
        >
          {secTitle}
        </h3>
      ) : null}

      {listTwo ? (
        <ul className="info-text-module_list clear">{listTwo}</ul>
      ) : null}

      {secLink && secLink.label ? (
        <EventButton
          link={secLink}
          button={{ margin: true }}
          onClick={onClick}
        />
      ) : null}
    </div>
  );
};

InfoTextModule.propTypes = {
  componentClickHandler: PropTypes.func,
  dividerColor: PropTypes.object,
  hasLine: PropTypes.bool,
  item: PropTypes.array,
  link: PropTypes.shape({ label: PropTypes.string, href: PropTypes.string }),
  priority: PropTypes.string,
  secItem: PropTypes.array,
  secLink: PropTypes.shape({ label: PropTypes.string, href: PropTypes.string }),
  secPriority: PropTypes.string,
  secTitle: PropTypes.string,
  subTitleColor: PropTypes.object,
  tableBackground: PropTypes.object,
  title: PropTypes.string.isRequired,
  titleColor: PropTypes.object
};

export { InfoTextModule };
