/* eslint react/prop-types:0  */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import RenderBrowseCategoryLink from '#/components/products/product-tile/tile-components/shared/render-browse-category-link';
import RenderBrowseCategorySuggestion from '#/components/products/product-tile/tile-components/shared/render-browse-category-suggestion';
import RenderControls from '#/components/products/product-tile/tile-components/shared/render-controls';
import RenderPromotions from '#/components/products/product-tile/tile-components/shared/render-promotions';
import RenderUnavailableBrowseCategoryLink from '#/components/products/product-tile/tile-components/shared/render-unavailable-browse-category-link';
import RenderInfoMessages from '#/components/products/product-tile/tile-components/shared/render-info-messages';
import RenderUnavailableProductMessages from '#/components/products/product-tile/tile-components/shared/render-unavailable-message';
import ProductImage from '#/components/products/product-tile/tile-components/product-image';
import ProductTitle from '#/components/products/product-tile/tile-components/product-title';
import ProductTileActions from '#/components/products/product-tile/tile-components/product-tile-actions';
import WriteAReview from '#/components/ratings-reviews/write-a-review';
import { connect } from '#/lib/render/connect-deep-compare';
import { getAisleNameFromItem } from '#/reducers/results';
import { getCurrentPathname } from '#/reducers/app';
import {
  getProductShelfId,
  getProductShelfName,
  getProductAisleId,
  getProductDefaultImageUrl,
  isProductBrowsableOnPDP,
  getRestOfShelfUrl,
  isInBasket,
  getProductIsForSale,
  getProductStatus,
  getProductTitle,
  isInFavourites,
  hasSubstitution,
  getProductId
} from '#/selectors/item';

const mapStateToProps = (state, { item }) => {
  return {
    aisleName: getAisleNameFromItem(state, item),
    currentPathname: getCurrentPathname(state)
  };
};

@connect(mapStateToProps)
export default class HorizontalEmbeddedTile extends PureComponent {
  static propTypes = {
    aisleName: PropTypes.string.isRequired,
    basketFeedbackRender: PropTypes.func.isRequired,
    controlProps: PropTypes.object,
    currentPathname: PropTypes.string.isRequired,
    href: PropTypes.string,
    isAmendBasket: PropTypes.bool,
    isAvailableEpwOverride: PropTypes.bool.isRequired,
    item: PropTypes.object.isRequired,
    onDetailsClick: PropTypes.func,
    onRestOfShelfClick: PropTypes.func,
    selectedUnit: PropTypes.string,
    sellableUnitPrice: PropTypes.number,
    showUnavailableMessage: PropTypes.bool.isRequired,
    userRegistered: PropTypes.bool
  };

  renderUnavailableMessage(className) {
    const {
      item,
      isAmendBasket,
      isAvailableEpwOverride,
      showUnavailableMessage
    } = this.props;
    return (
      showUnavailableMessage && (
        <RenderUnavailableProductMessages
          cssClassName={className}
          hasSubstitution={hasSubstitution(item)}
          status={getProductStatus(item)}
          isAvailable={getProductIsForSale(item)}
          isAmendBasket={isAmendBasket}
          isAvailableEpwOverride={isAvailableEpwOverride}
        />
      )
    );
  }

  renderInfoMessages(className) {
    return (
      <RenderInfoMessages
        cssClassName={className}
        item={this.props.item}
        isAmendBasket={this.props.isAmendBasket}
      />
    );
  }

  render() {
    const {
      aisleName,
      basketFeedbackRender,
      currentPathname,
      item
    } = this.props;
    const itemIsUnavailable = !this.props.isAvailableEpwOverride
      ? !getProductIsForSale(item)
      : false;
    const title = getProductTitle(item);
    const isInFav = isInFavourites(item);
    const productId = getProductId(item);

    return (
      <div
        className="horizontal-tile horizontal-embedded-tile"
        key="horizontal-embedded-tile"
      >
        <div
          className={classNames('tile-content', {
            'tile-content--has-product-tile-actions': isInFav
          })}
          id={`tile-${productId}`}
          data-auto-id={productId}
        >
          <div className="tile-content--upper">
            {isInFav && <ProductTileActions item={item} />}
            <ProductImage
              alt={title}
              hideFromScreenReader={true}
              href={this.props.href}
              image={getProductDefaultImageUrl(item)}
              isUnavailable={itemIsUnavailable}
              clickable={this.props.productBrowsableOnPDP}
              onClick={this.props.onDetailsClick}
            />
            <div className="product-details--wrapper">
              <div className="product-details--content">
                <ProductTitle
                  id={getProductId(item)}
                  title={title}
                  isPDPBrowsable={isProductBrowsableOnPDP(item)}
                  onClick={this.props.onDetailsClick}
                />
                <RenderPromotions
                  isAmendBasket={this.props.isAmendBasket}
                  item={item}
                  largeViewPromotion={true}
                />
                <WriteAReview id={productId} productTitle={title} />
                <RenderBrowseCategoryLink
                  aisleName={aisleName}
                  aisleId={getProductAisleId(item)}
                  currentPathname={currentPathname}
                  isAvailableOrHasSub={
                    getProductIsForSale(item) || hasSubstitution(item)
                  }
                  restOfShelfUrl={getRestOfShelfUrl(item)}
                  shelfId={getProductShelfId(item)}
                  shelfName={getProductShelfName(item)}
                  onClick={this.props.onRestOfShelfClick}
                />
              </div>
              {this.renderUnavailableMessage('unavailable-message-section')}
              {this.renderInfoMessages('product-info-message-section')}
            </div>
            <div className="product-controls-with-feedback">
              <RenderControls {...this.props.controlProps} />
              <div className="basket-feedback__wrapper">
                {isInBasket(item) && basketFeedbackRender()}
              </div>
            </div>
            {this.renderInfoMessages(
              'hidden-medium product-info-section-small'
            )}
            {this.renderUnavailableMessage(
              'hidden-medium product-info-section-small'
            )}
          </div>
        </div>
        <RenderPromotions
          isAmendBasket={this.props.isAmendBasket}
          item={item}
        />
        <RenderBrowseCategorySuggestion
          isAvailableEpwOverride={this.props.isAvailableEpwOverride}
          item={item}
        />
        <RenderUnavailableBrowseCategoryLink
          item={item}
          isAmendBasket={this.props.isAmendBasket}
        />
      </div>
    );
  }
}
