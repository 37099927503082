import React from 'react';
import PropTypes from 'prop-types';
import ChevronLink from '../../link/chevron-link';

const ContextualBanner = props => {
  return (
    <div
      className="contextual-banner"
      style={{ backgroundColor: props.panelColor }}
    >
      <h3>{props.heading}</h3>
      <p className="contextual-banner__sub-heading">{props.subHeading}</p>
      <p className="contextual-banner__link">
        {props.callToAction.text && (
          <ChevronLink
            to={props.callToAction.url}
            target={props.callToAction.openInNewTab ? '_blank' : '_self'}
          >
            {props.callToAction.text}
          </ChevronLink>
        )}
      </p>
    </div>
  );
};

ContextualBanner.propTypes = {
  callToAction: PropTypes.shape({
    url: PropTypes.string,
    openInNewTab: PropTypes.bool,
    text: PropTypes.string
  }),
  heading: PropTypes.string.isRequired,
  panelColor: PropTypes.string,
  subHeading: PropTypes.string
};

export default ContextualBanner;
