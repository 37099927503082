import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import classnames from 'classnames';
import { compose } from 'react-recompose';
import helpers from '#/lib/decorators/helpers';
import { closeModal } from '#/actions/ui-action-creators';
import { getUsualsResource } from '#/selectors/resources';
import { fetchResources } from '#/actions/resources-action-creators';
import { Usuals } from '#/resources/favorites/type.defs';
import { getCurrentUrl } from '#/reducers/app';
import { isHeaderSearchOpen } from '#/reducers/ui';
import SlotInfo from '#/components/slots/slot-info';
import { QUICK_BASKET_CLOSE } from '#/analytics/constants';
import { emitQuickBasketClickUIEvent } from '#/analytics/helpers/slot';
import { StyledQuickBasketModal } from './styled';

type OwnProps = {
  showEssentials: boolean;
  showError: boolean;
};

type State = {
  usualsResource: Usuals;
  currentUrl: string;
  headerSearchOpen: boolean;
};

const mapStateToProps = (state: Store): State => ({
  usualsResource: getUsualsResource(state)?.data,
  currentUrl: getCurrentUrl(state),
  headerSearchOpen: isHeaderSearchOpen(state),
});

const connector = connect(mapStateToProps, { closeModal, fetchResources });
type Props = OwnProps & ConnectedProps<typeof connector>;

const QuickBasketModal = ({ closeModal, headerSearchOpen, showError = false }: Props): React.ReactElement | null => {
  const onCloseModal = (): void => {
    const analyticsVal = QUICK_BASKET_CLOSE;
    emitQuickBasketClickUIEvent(analyticsVal);
    closeModal();
  };

  return (
    <StyledQuickBasketModal
      id="quick-basket-modal"
      className={classnames({ 'with-header-search': headerSearchOpen, 'small-modal': showError })}
      open
      onChange={onCloseModal}
    >
      <SlotInfo />
    </StyledQuickBasketModal>
  );
};

const enhance = compose<Props, null>(helpers(['t', 'c']), connector);
export default enhance(QuickBasketModal);
