import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styled from 'styled-components';
import { connect } from '#/lib/render/connect-deep-compare';
import { spacing } from '@ddsweb/selectors';
import { getLocalDate } from '#/lib/slot/slot-utils';
import Price from '#/components/shared/price';
import SlotContextButtons from '#/components/slots/slot-context-card/slot-context-buttons';
import helpers from '#/lib/decorators/helpers';
import { selectTaxonomyItem } from '#/actions/taxonomy-action-creators';
import { getShowSlotUIReskinV2 } from '#/experiments/oop-2205/selectors';
import {
  getSelectedShoppingMethod,
  getSlotsPendingStatus
} from '#/reducers/slot';
import OverlaySpinner from '#/components/overlay-spinner';
import { formatTimeFromConfig } from '#/components/shared/formatted-time/utils';

import {
  getAppRegion,
  getCurrency,
  getIsMobile,
  getLanguage,
  getTimezone
} from '#/reducers/app';
import { getFirstNavItem } from '#/reducers/taxonomy';
import {
  isClickAndCollectShoppingMethod,
  isOnDemandShoppingMethod
} from '#/lib/shopping-method-util';
import {
  getIsAmendBasket,
  getAmendExpiryTime,
  getLastSelectedSlot,
  getPreviousSlot,
  getDeliveryThresholds,
  hasValidSlot,
  getItems,
  getTrolleySlotStatus,
  getTrolleySlotCharge,
  getTrolleySlotReservationExpiry,
  getTrolleySlotStartTime,
  getTrolleySlotEndTime
} from '#/selectors/trolley';
import {
  getOnDemandDynamicDeliveryTime,
  showIncludedInYourPlanCopy
} from '#/selectors/slot';
import { getIsFirstTimeShopper } from '#/reducers/user';
import SafelyOutputString from '#/components/safely-output-string';
import {
  QUALIFY_FOR_FREE_DELIVERY,
  NOT_QUALIFY_FOR_FREE_DELIVERY,
  SPEND_MORE_FOR_FREE_DELIVERY
} from '#/constants/spend-threshold';
import formatNumber from '#/lib/string-formatting/number-formatter';
import { getOnDemandDynamicDeliveryData } from '#/lib/shopping-method-util';
import { BOOKED } from '#/constants/slot-statuses';
import CountdownTimer from '#/components/shared/countdown-timer';
import { CountdownTimeText } from '#/components/home/context-cards/styled';
import { CALLBACK_KEYS } from '#/components/shared/countdown-timer/callback-keys';
import SimpleButton from '#/components/shared/simple-button';
import { openModal } from '#/actions/ui-action-creators';
import { CANCEL_SLOT_BOOKING_MODAL } from '#/constants/modal-names';
import FormattedSlotTime from '#/components/shared/formatted-time/formattedSlotTime';
import { INFO } from '@ddsweb/constants';
import { StyledInPageMessaging } from '#/components/slots/delivery-content-section/styled';
import QuickBasketSlotContextButtons from '../slot-context-quick-basket-buttons';
import { UK } from '#/constants/common';

export const StyledContextCardInPageMessaging = styled(StyledInPageMessaging)`
  margin-top: ${spacing.md};
`;

const mapStateToProps = (
  state,
  { c: config, f: feature, isFDOXMessagingEnabled, shoppingMethod }
) => {
  const items = getItems(state);
  const isValidSlot = hasValidSlot(state);
  const isFirstTimeShopper = getIsFirstTimeShopper(state);
  const isOnDemandDelivery = isOnDemandShoppingMethod(shoppingMethod);
  const isTrolleyEmpty = items.length < 1;

  const showContextButtons =
    isTrolleyEmpty && isValidSlot && !isFirstTimeShopper && !isOnDemandDelivery;
  const shopFavoritesCTA = showContextButtons && config('slotPageFavCTA');
  const isAmendBasket = getIsAmendBasket(state);
  const showAmendChangeSlot = feature('allowAmendSlot');
  const showAmendAddItems = showAmendChangeSlot && isAmendBasket;
  const slotCharge = getTrolleySlotCharge(state);
  const appRegion = getAppRegion(state);

  const showQuickBasketContextButtons =
    appRegion === UK &&
    getIsMobile(state) &&
    !isAmendBasket &&
    isTrolleyEmpty &&
    isValidSlot;

  return {
    amendExpiry: getAmendExpiryTime(state),
    bookedSlot: getLastSelectedSlot(state),
    currency: getCurrency(state),
    firstMenuItem: getFirstNavItem(state),
    language: getLanguage(state),
    previousSlot: getPreviousSlot(state),
    timezone: getTimezone(state),

    isSlotPending: getSlotsPendingStatus(state),
    deliveryThresholds: isFDOXMessagingEnabled
      ? getDeliveryThresholds(state)
      : null,
    isValidSlot,
    isAmendBasket,
    isSlotUIReskin: getShowSlotUIReskinV2(state),
    selectedShoppingMethod: getSelectedShoppingMethod(state),
    slotStatus: getTrolleySlotStatus(state),
    slotCharge,
    reservationExpiry: getTrolleySlotReservationExpiry(state),
    slotStart: getTrolleySlotStartTime(state),
    slotEnd: getTrolleySlotEndTime(state),
    shopFavoritesCTA,
    showAmendAddItems,
    showStaticDeliveryTime: isOnDemandDelivery,
    showSlotExpiryTimer: isOnDemandDelivery,
    isTrolleyEmpty,
    isOnDemandDelivery,
    onDemandDynamicDeliveryTime: getOnDemandDynamicDeliveryTime(state),
    showQuickBasketContextButtons,
    showIncludedInYourPlanCopy: showIncludedInYourPlanCopy(state, slotCharge)
  };
};

@helpers(['f', 'c', 't'])
@connect(mapStateToProps, {
  selectTaxonomyItem,
  openModal
})
export default class SlotContextCard extends Component {
  static propTypes = {
    addItemsLink: PropTypes.string,
    address: PropTypes.object.isRequired,
    amendExpiry: PropTypes.string,
    bookedSlot: PropTypes.object,
    c: PropTypes.func.isRequired,
    currency: PropTypes.object.isRequired,
    deliveryThresholds: PropTypes.object,
    f: PropTypes.func.isRequired,
    firstMenuItem: PropTypes.object.isRequired,
    freeDeliveryStatus: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    hasTrolleyItems: PropTypes.bool.isRequired,
    isAmendBasket: PropTypes.bool,
    isFDOXMessagingEnabled: PropTypes.bool.isRequired,

    isTrolleyEmpty: PropTypes.bool.isRequired,
    isValidSlot: PropTypes.bool.isRequired,
    language: PropTypes.string.isRequired,
    onDemandDynamicDeliveryTime: PropTypes.shape({
      isDynamicDeliveryTimeAvailable: PropTypes.bool.isRequired,
      value: PropTypes.number,
      unit: PropTypes.string,
      range: PropTypes.shape({
        min: PropTypes.number,
        max: PropTypes.number
      })
    }),
    previousSlot: PropTypes.object,
    reservationExpiry: PropTypes.string,
    isSlotUIReskin: PropTypes.bool,
    selectedShoppingMethod: PropTypes.string,
    selectTaxonomyItem: PropTypes.func.isRequired,
    shopFavoritesCTA: PropTypes.bool.isRequired,
    shoppingMethod: PropTypes.string.isRequired,
    showAmendAddItems: PropTypes.bool,
    showIncludedInYourPlanCopy: PropTypes.bool,
    showSlotExpiryTimer: PropTypes.bool.isRequired,
    showStaticDeliveryTime: PropTypes.bool.isRequired,
    slotCharge: PropTypes.number,
    slotEnd: PropTypes.string,
    slotStart: PropTypes.string,
    slotStatus: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    timezone: PropTypes.string.isRequired
  };

  static defaultProps = {
    addItemsLink: '/shop'
  };

  localDate(date) {
    return getLocalDate(date, this.props.timezone, this.props.language);
  }

  getServiceChargeText() {
    const { f: feature, t: translate, shoppingMethod } = this.props;

    if (!feature('useUpdatedServiceChargeLabels')) {
      return translate('slots:common.charge');
    }

    return isClickAndCollectShoppingMethod(shoppingMethod)
      ? translate('orders:common.pick-and-pack')
      : translate('orders:common.pick-pack-and-deliver');
  }

  getSpendThresholdText() {
    const {
      freeDeliveryStatus,
      shoppingMethod,
      deliveryThresholds,
      c: config,
      t: translate
    } = this.props;
    const currencyConfig = config('currency');
    const options = {
      decimalMark: currencyConfig.decimalMark,
      decimalPlaces: currencyConfig.decimalPlaces,
      thousandsSeparator: currencyConfig.thousandsSeparator,
      symbol: currencyConfig.symbol,
      showAsciiSymbol: true,
      compactMode: !currencyConfig.spaceBetweenSymbolAndValue,
      symbolPosition: currencyConfig.symbolPosition
    };
    const { deliveryThreshold } = deliveryThresholds;
    let msg;

    switch (freeDeliveryStatus) {
      case SPEND_MORE_FOR_FREE_DELIVERY:
      case NOT_QUALIFY_FOR_FREE_DELIVERY:
        msg = translate(
          `slots:${shoppingMethod}.spend-threshold.spend-more-to-qualify`,
          { amount: formatNumber(deliveryThreshold, options) }
        );
        break;
      case QUALIFY_FOR_FREE_DELIVERY:
      default:
        // Not showing message when status is QUALIFY_FOR_FREE_DELIVERY or undefined
        msg = null;
        break;
    }
    return msg;
  }

  renderContextButtons() {
    const {
      hasTrolleyItems,
      shopFavoritesCTA,
      showAmendAddItems,
      showQuickBasketContextButtons
    } = this.props;

    if (showAmendAddItems) {
      return null;
    }

    let renderButtons = (
      <SlotContextButtons
        hasTrolleyItems={hasTrolleyItems}
        hasSlot
        shopFavoritesCTA={shopFavoritesCTA}
      />
    );

    if (showQuickBasketContextButtons) {
      renderButtons = <QuickBasketSlotContextButtons />;
    }

    return (
      <div
        id="slot-context-card__wrapper__button"
        className="slot-context-card__wrapper__buttons"
      >
        {renderButtons}
      </div>
    );
  }

  renderDeliveryTime() {
    const {
      shoppingMethod,
      slotEnd,
      slotStart,
      t: translate,
      showStaticDeliveryTime,
      onDemandDynamicDeliveryTime
    } = this.props;

    if (showStaticDeliveryTime) {
      const isOnDemandShopping = isOnDemandShoppingMethod(shoppingMethod);
      const {
        onDemandDynamicDeliverySuffix,
        unit,
        min,
        max
      } = getOnDemandDynamicDeliveryData(
        isOnDemandShopping,
        true,
        onDemandDynamicDeliveryTime
      );

      return (
        <div>
          {translate(
            `slots:${shoppingMethod}.delivered-by${onDemandDynamicDeliverySuffix}`,
            {
              unit,
              min,
              max
            }
          )}
        </div>
      );
    }

    return (
      <div className="slot-context-card__wrapper__main__details__date-time">
        {' '}
        <div
          className="slot-context-card--date"
          data-date={this.localDate(slotStart).format('YYYY-MM-DD')}
          data-auto="slot-context-card--date"
        >
          {`${this.localDate(slotStart).format('lll')},`}
        </div>{' '}
        <div className="slot-context-card--time-slot">
          <span className="hidden-large">
            {`${translate('slots:common.between')}: `}
          </span>
          <strong
            className="slot-context-card--time-slot-period"
            data-auto="slot-context-card--time-slot-period"
          >
            <FormattedSlotTime startTime={slotStart} endTime={slotEnd} />
          </strong>
        </div>
      </div>
    );
  }

  renderExpiryText() {
    const cb = () => {
      onDemandSlotExpiredCB();
    };
    const {
      shoppingMethod,
      t: translate,
      c: config,
      slotStatus,
      amendExpiry,
      reservationExpiry: reservationExpiryProp,
      showSlotExpiryTimer,
      isAmendBasket,
      onDemandSlotExpiredCB,
      timezone,
      language
    } = this.props;

    if (showSlotExpiryTimer && reservationExpiryProp) {
      return (
        <>
          <span>{translate(`slots:${shoppingMethod}.expiry-text`)}</span>
          <CountdownTimeText>
            <CountdownTimer
              countdownEnd={reservationExpiryProp}
              cbKey={CALLBACK_KEYS.ONDEMAND_SLOT_CONTEXT_CARD}
              cb={cb}
            />
          </CountdownTimeText>
        </>
      );
    }

    let reservationExpiry;
    const slotBooked = slotStatus === BOOKED;
    const slotBeforeExpiry = this.localDate(amendExpiry).isBefore(
      this.localDate(reservationExpiryProp)
    );

    if (isAmendBasket && (slotBooked || slotBeforeExpiry)) {
      reservationExpiry = amendExpiry;
    } else {
      reservationExpiry = reservationExpiryProp;
    }

    return (
      <SafelyOutputString>
        {translate('slots:expiry-time.expiry-today', {
          time: formatTimeFromConfig(
            this.localDate(reservationExpiry),
            timezone,
            language,
            config
          )
        })}
      </SafelyOutputString>
    );
  }

  renderCustomAgeRestriction() {
    const {
      isOnDemandDelivery,
      c: config,
      t: translate,
      shoppingMethod
    } = this.props;

    const showAgeRestriction = config('showAgeRestrictionMessage');

    const translationTextKey = translate(
      `slots:${shoppingMethod}.over-18-description`,
      { age: config('customAgeRestriction') }
    );

    if (!showAgeRestriction && !isOnDemandDelivery) return null;

    return (
      <div className="large-only">
        {isOnDemandDelivery ? (
          <StyledContextCardInPageMessaging
            showIcon
            variant={INFO}
            title={translationTextKey}
            assistiveText={translate('common:information')}
          />
        ) : (
          <p className="book-a-slot--info-message">{translationTextKey}</p>
        )}
      </div>
    );
  }

  renderPrice() {
    const { currency, slotCharge } = this.props;

    const price = (
      <strong
        className="slot-context-card--service-charge-price"
        data-auto="slot-context-card--service-charge-price"
      >
        <Price {...currency} value={slotCharge} truncateWholeValues />
      </strong>
    );

    return price;
  }

  componentDidMount() {
    const footerSelector = document.getElementsByClassName(
      'footer-logo__payments-container'
    )[0];

    if (footerSelector && !this.props.isAmendBasket) {
      document.documentElement.style.setProperty('--padding-bottom', '75px');
    }
  }

  componentWillUnmount() {
    document.documentElement.style.setProperty('--padding-bottom', '0');
  }

  render() {
    const {
      bookedSlot,
      previousSlot,
      shoppingMethod,
      t: translate,
      address,
      deliveryThresholds,
      isValidSlot,
      showAmendAddItems,
      isSlotUIReskin,
      isOnDemandDelivery,
      showIncludedInYourPlanCopy
    } = this.props;
    const title =
      bookedSlot && previousSlot && bookedSlot.start !== previousSlot.start
        ? translate(`slots:${shoppingMethod}.title`) +
          ' ' +
          translate(`slots:${shoppingMethod}.slot-updated`)
        : translate(`slots:${shoppingMethod}.title`) +
          ' ' +
          translate(`slots:${shoppingMethod}.slot-booked`);

    const spendThresholdText =
      deliveryThresholds && this.getSpendThresholdText();

    const openCancelSlotModal = event => {
      event.preventDefault();
      this.props.openModal(CANCEL_SLOT_BOOKING_MODAL);
    };

    return (
      <div
        data-auto="slots-page-slot-context-card"
        data-testid="slots-page-slot-context-card-synthetics"
        className={classnames(`${shoppingMethod} slot-context-card`, {
          'no-slot': !isValidSlot,
          'slot-context-card__with-radius': !isSlotUIReskin
        })}
      >
        <div
          className={classnames('slot-context-card__wrapper', {
            'slot-context-card__wrapper--isOnDemandDelivery': isOnDemandDelivery
          })}
        >
          <OverlaySpinner isLoading={this.props.isSlotPending} />

          <h3
            className={`slot-context-card__wrapper__main__title ${shoppingMethod} hidden-large`}
            data-auto="slot-context-card--title"
          >
            {title}
          </h3>
          <div className="slot-context-card__wrapper__main">
            <div className="slot-context-card--icon icon-context-card-03" />
            <div className="slot-context-card__wrapper__main__details">
              <h3
                className={`slot-context-card__wrapper__main__details__title ${shoppingMethod} large-only`}
              >
                {title}
              </h3>
              <div
                className="slot-context-card--address-name"
                data-auto="slot-context-card--address-name"
              >
                <strong className="slot-context-card--address-name-copy">
                  {address.name}
                </strong>
              </div>
              <div className="hidden-large text-fade">{address.address}</div>
              {this.renderDeliveryTime()}
              <p className="slot-context-card--service-charge">
                <span>{`${this.getServiceChargeText()}: `}</span>
                {this.renderPrice()}
              </p>
              {showIncludedInYourPlanCopy && (
                <strong className="slot-context-card--included-in-your-plan">
                  {translate('slots:delivery-saver.included-in-your-plan')}
                </strong>
              )}

              {spendThresholdText && (
                <p className="fdox-message">
                  <span>
                    <SafelyOutputString>
                      {spendThresholdText}
                    </SafelyOutputString>
                  </span>
                </p>
              )}
            </div>
          </div>
          <div
            className={classnames('slot-context-card__wrapper__checkout', {
              'slot-context-card--add-items': showAmendAddItems
            })}
          >
            <p className="slot-context-card__wrapper__checkout__expiry-copy">
              {this.renderExpiryText()}
            </p>
            {isOnDemandDelivery && (
              <div className="slot-context-card__wrapper__checkout__buttons">
                {this.renderContextButtons()}
                <SimpleButton
                  buttonClass={'cancel-slot-button'}
                  buttonText={translate('slots:common.cancel-booked-slot')}
                  type="secondary"
                  onClick={openCancelSlotModal}
                />
              </div>
            )}
          </div>
          {!isOnDemandDelivery && this.renderContextButtons()}
        </div>
        {this.renderCustomAgeRestriction()}
      </div>
    );
  }
}
