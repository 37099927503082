export const VARIATION_KEY = 'searchContentLinks';
export const FEATURE_KEY = 'GHS-<region>_search-content-links';

export const ContentLinkSearchFeature = {
  featureKey: FEATURE_KEY,
  variationKey: VARIATION_KEY,
};

export const CONTENT_LINK_SEARCH = 'content links:search box click';
export const CONTENT_LINK_CLICK = 'content links:link click';
export const CONTENT_SEARCH_STORAGE_KEY = 'contentSearchInteraction';
export const NOW = 'now';
export const BERTIE_EVENT_TYPE_SEARCH = 'search';
export const ACTION_DELAY = 'delay';
export const CONTENT_LINK = 'content links:';
