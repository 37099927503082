import React from 'react';
import { number, string } from 'prop-types';
import classnames from 'classnames';

const CharacterCount = ({
  count,
  characterLimit,
  preText,
  customClassName
}) => (
  <span
    className={classnames('character-count', {
      [customClassName]: customClassName
    })}
  >
    {`${preText} ${count} / ${characterLimit}`}
  </span>
);

CharacterCount.propTypes = {
  characterLimit: number.isRequired,
  count: number.isRequired,
  customClassName: string,
  preText: string
};

CharacterCount.defaultProps = {
  preText: '',
  customClassName: ''
};

export default CharacterCount;
