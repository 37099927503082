import React, { FC } from 'react';
import { connect } from '#/lib/render/connect-deep-compare';
import { closeModal } from '#/actions/ui-action-creators';
import { ConnectedProps } from 'react-redux';
import {
  ModalButtonContainer,
  ModalHeading,
  PayOnlineButton,
  StyledModal,
} from '#/experiments/oop-1725/components/styled';
import helpers from '#/lib/decorators/helpers';
import { TTranslateFunc } from '#/lib/records/helpers.defs';
import { Text } from '@ddsweb/text';
import PayAtDoorButton from '#/components/checkout/pay-at-door-button';

const connector = connect(null, { closeModal });

export type OwnProps = {
  payAtDoorHref: string;
  payOnlineHref: string;
  onPayOnlineButtonClick?: () => void;
};

type HelperProps = {
  t: TTranslateFunc;
};

export type Props = OwnProps & HelperProps & ConnectedProps<typeof connector>;

const CardSaveMessageModalComponent: FC<Props> = ({
  closeModal,
  t: translate,
  payAtDoorHref,
  payOnlineHref,
  onPayOnlineButtonClick,
}) => {
  const closeCardMessageModal = (): void => closeModal();

  return (
    <StyledModal ssr open id={'card-save-message-modal'} onChange={closeCardMessageModal}>
      <ModalHeading headingLevel="4">{translate('checkout:order-summary.oop-1725.card-save-modal-title')}</ModalHeading>
      <Text>{translate('checkout:order-summary.oop-1725.card-save-modal-body')}</Text>
      <ModalButtonContainer>
        <PayAtDoorButton
          confirmationUrl={payAtDoorHref}
          text={translate('checkout:order-summary.oop-1725.card-save-modal-pay-at-door-action')}
          variant="secondary"
        />
        <PayOnlineButton
          ddl
          primaryText={translate('checkout:order-summary.oop-1725.card-save-modal-pay-online-action')}
          href={payOnlineHref}
          onClick={onPayOnlineButtonClick}
        />
      </ModalButtonContainer>
    </StyledModal>
  );
};

export const CardSaveMessageModal: FC<OwnProps> = helpers(['t'])(connector(CardSaveMessageModalComponent));
