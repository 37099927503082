import React, { useState, useEffect } from 'react';
import { SECONDARY, PRIMARY } from '@beans/constants';
import Spinner from '@beans/spinner';
import helpers from '#/lib/decorators/helpers';
import { TTranslateFunc } from '#/lib/records/helpers.defs';
import SafeForm from '#/components/shared/safe-form';
import { StyledButton } from './slot-recommender-tile-styled';

export type UnBookSlotProps = {
  t: TTranslateFunc;
  onKeyDown: () => void;
  onSubmit: (e: Event) => void;
  disableUnbooking: boolean;
  isSlotLoading: boolean;
};

const SlotRecommenderTileBookedSlotButton = (props: UnBookSlotProps): JSX.Element => {
  const { t: translate, onKeyDown, onSubmit, disableUnbooking, isSlotLoading } = props;
  const [isBooked, setIsBooked] = useState<boolean>(true);

  useEffect(() => {
    return (): void => {
      setIsBooked(false);
    };
  }, []);

  return (
    <SafeForm method="post" onSubmit={onSubmit}>
      <StyledButton
        isBookedState={isBooked && !isSlotLoading}
        onKeyDown={onKeyDown}
        disabled={disableUnbooking}
        block={true}
        variant={isSlotLoading ? SECONDARY : PRIMARY}
        type="submit"
      >
        {isSlotLoading ? <Spinner /> : translate('slots:common.slot-recommendations.booked')}
      </StyledButton>
    </SafeForm>
  );
};

export default helpers(['t'])(SlotRecommenderTileBookedSlotButton);
