import moment from 'moment';
import 'moment-range';
import 'moment-timezone';
import { findLast } from '../array-helpers';

const DATE_FORMAT = 'YYYY-MM-DD';
const bookedStatuses = ['Booked', 'Reserved'];

/**
 * Returns the number of weeks in a range, this will be the same as the week limit passed
 * when creating the range
 * @param range
 * @returns {*}
 */
function rangeLength(range) {
  // the range end is the last day of the last week
  // which technically means the length is 3 weeks
  // so we tick the end over a day
  return range.end
    .clone()
    .add(1, 'd')
    .diff(range.start, 'weeks');
}

/**
 * Gets the indexes of the start of each week in the range
 * @param limit
 * @private
 */
function _weekStartIndexes(limit) {
  const indexes = [];

  for (let i = 0; i < limit; i++) {
    indexes.push(i * 7);
  }

  return indexes;
}

/**
 * The number of days into the week the specified day is
 * @param range
 * @param day
 * @returns {*}
 * @private
 */
function _getIndexForDayInRange(range, day) {
  return day.utc().diff(range.start.utc(), 'days');
}

/**
 * Returns the index of the first day of the next week from the target day
 * @param range
 * @param day
 * @returns {*}
 * @private
 */
function _getNextWeekIndex(range, day) {
  const weekLimit = rangeLength(range);
  const weekStartIndexes = _weekStartIndexes(weekLimit);
  const dayIndex = _getIndexForDayInRange(range, day);

  if (dayIndex > (weekLimit - 1) * 7) {
    // day is in the last week
    return -1;
  }

  return weekStartIndexes.find(i => i > dayIndex) || -1;
}

/**
 * Gets the range of weeks for a start date, the start is usually today
 * @param start
 */
function calculateRange(start, weekLimit) {
  const rangeStart = moment.utc(start);
  const rangeEnd = rangeStart
    .clone()
    .subtract(1, 's')
    .add(weekLimit, 'w');

  return moment.range(rangeStart, rangeEnd);
}

export function formatDate(date, timezone) {
  let momentDate = moment.isMoment(date)
    ? date.clone().utc()
    : moment.utc(date);

  // With refactor and removal of moment-timezone from codebase, these custom timezone conversions will go away
  if (timezone) {
    momentDate = momentDate.tz(timezone);
  }

  return momentDate.format(DATE_FORMAT);
}

/**
 * Returns a slotRange object
 * @param weekLimit
 * @param startDate
 * @param timezone
 * @returns {*}
 */
export function createSlotRange(weekLimit, startDate, timezone) {
  const start = formatDate(startDate, timezone);
  const range = calculateRange(start, weekLimit);

  return {
    weekLimit,
    start,
    range,
    /**
     * Return the construction data so we can create this again on the client (via the store)
     * @return {[type]} [description]
     */
    dehydrate() {
      return {
        start,
        weekLimit
      };
    }
  };
}

/**
 * Return the week range a selected day is in
 * e.g. the 8th day will return the 2nd week
 * @param slotRange
 * @param day
 * @returns {*}
 */
export function getWeekRangeForDay(slotRange, day) {
  const fullRange = slotRange.range;
  const limit = slotRange.weekLimit;

  if (!fullRange.contains(day)) {
    return calculateRange(fullRange.start, 1);
  }

  const dayIndex = _getIndexForDayInRange(fullRange, day);
  const weekIndexes = _weekStartIndexes(limit);

  const weekIndex = findLast(weekIndexes, i => i <= dayIndex);
  const weekStart = fullRange.start.clone().add(weekIndex, 'days');

  return calculateRange(weekStart, 1);
}

/**
 * Return the first day of the week where the passed in day lies
 * @param slotRange
 * @param day
 * @returns {*}
 */

export function getFirstDayOfWeekRangeForDay(slotRange, day) {
  const { weekLimit, start } = slotRange;
  const startDate = moment.utc(start);

  day = moment.utc(day);

  for (let i = 0; i < weekLimit; i++) {
    const weekStart = startDate.clone().add(i * 7, 'days');
    const weekRange = calculateRange(weekStart, 1);

    if (weekRange.contains(day)) {
      return weekStart;
    }
  }
}

/**
 * Return the first day in the next week or an invalid moment if there is no next week
 * @param slotRange
 * @param selectedDay
 * @returns {*}
 */
function getStartOfNextWeek(slotRange, selectedDay) {
  const range = slotRange.range;
  const nextWeekIndex = _getNextWeekIndex(range, selectedDay);

  return nextWeekIndex >= 0
    ? range.start.clone().add(nextWeekIndex, 'days')
    : moment.invalid();
}

/**
 * Returns the index of the first day of the previous week from the target day
 * @param range
 * @param day
 * @returns {*}
 * @private
 */
function _getPreviousWeekIndex(range, day) {
  const weekLimit = rangeLength(range);
  const weekStartIndexes = _weekStartIndexes(weekLimit);
  const dayIndex = _getIndexForDayInRange(range, day);

  if (dayIndex < 7) {
    // there is no previous week
    return -1;
  }

  const weekStart = findLast(weekStartIndexes, i => i <= dayIndex);

  return weekStart - 7;
}

/**
 * Returns the first day in the previous week or an invalid moment if there is no previous week
 * @param slotRange
 * @param selectedDay
 * @returns {*}
 */
function getStartOfPreviousWeek(slotRange, selectedDay) {
  const range = slotRange.range;
  const previousWeekIndex = _getPreviousWeekIndex(range, selectedDay);

  return previousWeekIndex >= 0
    ? range.start.clone().add(previousWeekIndex, 'days')
    : moment.invalid();
}

/**
 * Finds the first booked slot in the slots array
 * @param slots
 */
function getLastSelectedSlot(slots) {
  return slots.find(s => bookedStatuses.includes(s.status));
}

/**
 * Turn a three 1*Xweek range into a X*1week range
 * @param slotRange
 * @returns {Array}
 */
export function byWeek(slotRange) {
  const weeks = [];

  slotRange.range.by('weeks', weekStart => {
    weeks.push(calculateRange(weekStart, 1));
  });

  return weeks;
}

export default {
  createSlotRange,
  rangeLength,
  calculateRange,
  getStartOfNextWeek,
  getStartOfPreviousWeek,
  getWeekRangeForDay,
  getLastSelectedSlot,
  formatDate,
  byWeek
};
