import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import helpers from '#/lib/decorators/helpers';
import Link from '#/components/link-check-spa';
import analyticsBus from '#/analytics/analyticsBus';
import { basicEvent } from '#/analytics/types/basic';
import InfoMessageList from '#/components/products/info-message-list';
import { formatToShortDate } from '#/lib/i18n/date-utils';
import {
  getLanguage,
  getTimezone,
  getLanguageLink,
  getCurrency
} from '#/reducers/app';
import { COMPLETE_MESSAGE_BACKGROUND_CLASS } from '#/constants/product-tile.js';
import { getDefaultSlotsPath } from '#/reducers/slot';
import { getModifiedPromotion } from '#/selectors/get-inclusive-end-date-promotions';
import {
  showMissedOfferWithChangeSlotLink,
  getMissedDeliveryProps,
  getIncompletePromotionMessage
} from '#/lib/product-tile/promo-messaging-helper';
import { getItemPromotionLink } from '#/lib/records/item-utils';
import { showClubcardPriceInPromotionMessages } from '#/selectors/item';

const mapStateToProps = (state, { f, promoData }) => ({
  language: getLanguage(state),
  promoLink: getLanguageLink(state, getItemPromotionLink(promoData)),
  slotsLanguageLink: getLanguageLink(state, getDefaultSlotsPath(f)(state)),
  timezone: getTimezone(state),
  currency: getCurrency(state)
});

@helpers(['c', 'f', 't', 'l'])
@connect(mapStateToProps)
export default class PromoMessaging extends Component {
  static propTypes = {
    c: PropTypes.func,
    currency: PropTypes.object.isRequired,
    f: PropTypes.func.isRequired,
    isAmendBasket: PropTypes.bool.isRequired,
    isMiniTrolley: PropTypes.bool,
    l: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    promoData: PropTypes.object.isRequired,
    promoLink: PropTypes.string.isRequired,
    showChangeSlot: PropTypes.bool,
    showCompletePromoMessage: PropTypes.bool,
    slotsLanguageLink: PropTypes.string.isRequired,
    t: PropTypes.func,
    timezone: PropTypes.string.isRequired
  };

  static defaultProps = {
    isMiniTrolley: false,
    showChangeSlot: true,
    showCompletePromoMessage: true
  };

  state = {
    cleanedPromoData: {}
  };

  static getDerivedStateFromProps(props) {
    const {
      promoData,
      timezone,
      c: config,
      currency: { symbol }
    } = props;
    const showReconstructedOfferMsg = config('showReconstructedOfferMsg');
    const reconstructOfferArgs = {
      showReconstructedOfferMsg,
      symbol
    };

    if (promoData) {
      // [SM] - I have moved this logic here to fix a state mutation, but we
      // should address the logic of changing the endDate for a promo to make
      // it more centralised. Ideally this sort of update should be done at
      // the reducer injest level, rather than have the logic sporadically
      // placed throughout the codebase.

      const { endDate, offerText } = getModifiedPromotion(
        promoData,
        timezone,
        reconstructOfferArgs
      );

      return {
        cleanedPromoData: {
          ...promoData,
          endDate,
          offerText
        }
      };
    }

    return {};
  }

  missedOffersHandleClickTrolley = () => {
    basicEvent(analyticsBus, {
      type: 'basket',
      value: 'change slot',
      action: 'delay'
    });
  };

  missedOffersHandleClickMiniTrolley = () => {
    basicEvent(analyticsBus, {
      type: 'missed offers',
      value: 'side basket',
      action: 'delay'
    });
  };

  renderPromotionsLink(missedPromoMessage) {
    const { isMiniTrolley, promoLink } = this.props;
    const { cleanedPromoData } = this.state;
    return isMiniTrolley ? (
      <span>
        {missedPromoMessage}
        <Link
          className="product-info-message--mini-tile-link product-info-message__mini-tile-link--offer-text-color"
          to={promoLink}
          onClick={this.missedOffersHandleClickMiniTrolley}
        >
          {cleanedPromoData.offerText}
        </Link>
      </span>
    ) : null;
  }

  getMissedDeliveryContent() {
    const {
      isMiniTrolley,
      timezone,
      language,
      t: translate,
      slotsLanguageLink
    } = this.props;
    const { cleanedPromoData } = this.state;

    const analyticsClick = isMiniTrolley
      ? this.missedOffersHandleClickMiniTrolley
      : this.missedOffersHandleClickTrolley;

    const promoDates = formatToShortDate(
      cleanedPromoData.endDate,
      timezone,
      language
    );
    const args = {
      translate,
      slotsLanguageLink,
      promoDates,
      analyticsClick,
      isMiniTrolley
    };

    const messagingProps = getMissedDeliveryProps(args);

    return <InfoMessageList {...messagingProps} />;
  }

  getIncompleteContent() {
    const { t: translate, isMiniTrolley } = this.props;
    const { cleanedPromoData } = this.state;

    const promoMessage = getIncompletePromotionMessage(
      cleanedPromoData,
      translate,
      isMiniTrolley
    );

    const messageWithLink = this.renderPromotionsLink(promoMessage.message);

    const messagingProps = messageWithLink
      ? { ...promoMessage, message: messageWithLink }
      : promoMessage;

    return <InfoMessageList messages={[messagingProps]} />;
  }

  getCompleteContent() {
    const { t, c, promoData } = this.props;

    const offerCompleteMessage = {
      backgroundColorClass: COMPLETE_MESSAGE_BACKGROUND_CLASS,
      message: showClubcardPriceInPromotionMessages(promoData, c)
        ? t('promotions:clubcard-price')
        : t('promotions:offer-complete')
    };

    return <InfoMessageList messages={[offerCompleteMessage]} />;
  }

  render() {
    const {
      showCompletePromoMessage,
      isAmendBasket,
      showChangeSlot,
      c: config
    } = this.props;
    const { cleanedPromoData } = this.state;

    // Missed Slot - This will include promos that are incomplete and missed slot
    if (
      showMissedOfferWithChangeSlotLink(
        cleanedPromoData,
        isAmendBasket,
        showChangeSlot,
        config
      )
    ) {
      return this.getMissedDeliveryContent();
    }

    // Missed Promo
    if (cleanedPromoData.missedPromotion) {
      return this.getIncompleteContent();
    }

    // Offer Complete
    if (
      typeof cleanedPromoData.missedPromotion === 'boolean' &&
      !cleanedPromoData.missedPromotion &&
      showCompletePromoMessage
    ) {
      return this.getCompleteContent();
    }

    return null;
  }
}
