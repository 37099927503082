import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { AllHtmlEntities } from 'html-entities';

const entities = new AllHtmlEntities();

const tokenDefns = [
  {
    type: 'startClosingTag',
    re: /^<\//
  },
  {
    type: 'startTag',
    re: /^</
  },
  {
    type: 'endTag',
    re: /^>/
  },
  {
    type: 'endSelfClosingTag',
    re: /^\/>/
  },
  {
    type: 'identifier',
    re: /^[^<>="„\s\/]+/
  },
  {
    type: 'equals',
    re: /^=/
  },
  {
    type: 'quotedString',
    re: /^".*?"/
  },
  {
    type: 'whitespace',
    re: /^\s+/
  },
  {
    type: 'genericToken',
    re: /^[^<\s]+/
  }
];

const tagWhitelist = {
  a: ['class', 'id', 'href', 'target', 'title', 'rel'],
  b: [],
  br: [],
  caption: [],
  div: ['class', 'id'],
  em: [],
  h1: ['class', 'id'],
  h2: ['class', 'id'],
  h3: ['class', 'id'],
  h4: ['class', 'id'],
  h5: ['class', 'id'],
  h6: ['class', 'id'],
  img: ['class', 'id', 'src', 'alt'],
  p: ['class', 'id'],
  span: ['class', 'id'],
  strong: ['class', 'id'],
  ol: ['class', 'id'],
  ul: ['class', 'id'],
  li: ['class', 'id'],
  sup: [],
  sub: [],
  table: [],
  tbody: [],
  th: [],
  thead: [],
  td: [],
  tr: []
};

// Void tags are those that are not allowed to have children and therefore are
// allowed to be written without being closed. e.g. <br> is just as valid as <br />
// Include all void tags in html spec here rather than just those
// in the whitelist to reduce the chance of mistakes when adding to the whitelist later
const voidTags = [
  'area',
  'base',
  'br',
  'col',
  'command',
  'hr',
  'img',
  'input',
  'keygen',
  'link',
  'meta',
  'param',
  'source'
];

const tryUntilMatch = (arr, cb) => {
  for (let i = 0; i < arr.length; i++) {
    const result = cb(arr[i]);

    if (typeof result !== 'undefined') {
      return result;
    }
  }
};

const getNextToken = input => {
  return tryUntilMatch(tokenDefns, defn => {
    const matches = input.match(defn.re);

    if (matches) {
      return {
        type: defn.type,
        value: matches[0]
      };
    }
  });
};

const lex = input => {
  const tokens = [];

  while (input.length) {
    const token = getNextToken(input);

    if (!token) {
      throw new Error(`Unexpected input: ${input}`);
    }

    tokens.push(token);
    input = input.slice(token.value.length);
  }

  return tokens;
};

const parseTag = tokens => {
  let token = tokens[0];

  tokens = tokens.slice(1);

  if (token.type !== 'identifier') {
    throw new Error(
      `Expected tag to start with identifier but instead started with ${token.type}`
    );
  }

  const tagName = token.value.toLowerCase();
  const allowedAttributes = tagWhitelist[tagName];

  if (typeof allowedAttributes === 'undefined') {
    throw new Error(`"${tagName}" is not one of the whitelisted tags`);
  }

  const attrs = {};

  while (
    tokens.length &&
    tokens[0].type !== 'endTag' &&
    tokens[0].type !== 'endSelfClosingTag'
  ) {
    token = tokens[0];
    switch (token.type) {
      case 'whitespace':
        tokens = tokens.slice(1);
        break;
      case 'identifier':
        if (!allowedAttributes.includes(token.value)) {
          tokens = tokens.slice(1);
          if (
            tokens[0].type === 'equals' &&
            tokens[1].type === 'quotedString'
          ) {
            tokens = tokens.slice(2);
          }
          break;
        }

        attrs[token.value] = null;
        tokens = tokens.slice(1);

        if (tokens[0].type === 'equals' && tokens[1].type === 'quotedString') {
          attrs[token.value] = tokens[1].value.replace(/"/g, '');
          tokens = tokens.slice(2);
        }

        break;
      default:
        throw new Error(
          `Unexpected token in "${tagName}" tag: "${token.type}"`
        );
    }
  }

  const endTag = tokens[0];
  const remainingTokens = tokens.slice(1);
  let tokensAfter;
  let node;

  if (endTag.type === 'endTag' && !voidTags.includes(tagName)) {
    const result = parseSection(remainingTokens);

    tokensAfter = result.remainingTokens;

    node = {
      type: 'element',
      tagName,
      attrs,
      children: result.result
    };
  } else if (
    endTag.type === 'endSelfClosingTag' ||
    (endTag.type === 'endTag' && voidTags.includes(tagName))
  ) {
    tokensAfter = tokens.slice(1);
    node = {
      type: 'element',
      tagName,
      attrs,
      children: []
    };
  } else {
    throw new Error(
      `Expected tag identifier and attributes to be followed by the end of the tag but found ${endTag.type}`
    );
  }

  return {
    node,
    tokensAfter
  };
};

const parseSection = tokens => {
  const result = [];

  while (tokens && tokens.length) {
    const token = tokens[0];

    tokens = tokens.slice(1);
    switch (token.type) {
      case 'identifier':
      case 'whitespace':
      case 'equals':
      case 'quotedString':
      case 'genericToken':
        result.push({
          type: token.type,
          value: entities.decode(token.value) // React encodes them so we need this to prevent double encoding
        });
        break;
      case 'startTag': {
        const { node, tokensAfter } = parseTag(tokens);

        result.push(node);
        tokens = tokensAfter;
        break;
      }
      case 'startClosingTag':
        return { result, remainingTokens: tokens.slice(2) };
      default:
        throw new Error(`Unexpected token: ${JSON.stringify(token)}`);
    }
  }

  return {
    result,
    remainingTokens: []
  };
};

const parse = tokens => {
  const { result, remainingTokens } = parseSection(tokens);

  if (remainingTokens.length) {
    result.concat(parseSection(remainingTokens));
  }

  return result;
};

const isStringLike = node => {
  return [
    'string',
    'identifier',
    'whitespace',
    'equals',
    'quotedString',
    'genericToken'
  ].includes(node && node.type);
};

const compressStrings = AST => {
  const result = [];

  while (AST.length) {
    const currentToken = AST[0];
    const nextToken = AST[1];

    if (currentToken.type === 'element') {
      result.push({
        ...currentToken,
        children: compressStrings(currentToken.children)
      });
      AST = AST.slice(1);
    } else if (isStringLike(currentToken) && isStringLike(nextToken)) {
      AST = [
        {
          type: 'string',
          value: currentToken.value + nextToken.value
        }
      ].concat(AST.slice(2));
    } else if (isStringLike(currentToken)) {
      result.push({
        type: 'string',
        value: currentToken.value
      });
      AST = AST.slice(1);
    } else {
      result.push(currentToken);
      AST = AST.slice(1);
    }
  }

  return result;
};

const propAliases = {
  class: 'className',
  for: 'htmlFor' // label isn't whitelisted yet but this is so easy to forget I added it anyway
};

const convertAttrsToProps = attrs =>
  Object.keys(attrs).reduce((props, attr) => {
    const key = propAliases[attr] || attr;

    props[key] = attrs[attr];

    return props;
  }, {});

const render = ast => (
  <Fragment>
    {ast.map((node, i) => {
      switch (node.type) {
        case 'string':
          return node.value;
        case 'element': {
          const Component = node.tagName; // eslint-disable-line @typescript-eslint/no-unused-vars
          const props = convertAttrsToProps(node.attrs);

          return (
            <Component key={i} {...props}>
              {node.children.length ? render(node.children) : null}
            </Component>
          );
        }
        default:
          throw new Error(`Unknown node type ${node.type}`);
      }
    })}
  </Fragment>
);

const SafelyOutputString = ({ children }) => {
  let content = children;

  try {
    content = render(compressStrings(parse(lex(children))));
  } catch (e) {
    if (process.env.NODE_ENV !== 'production') {
      console.warn(e, children); // eslint-disable-line no-console
    }
  }

  return <Fragment>{content}</Fragment>;
};

SafelyOutputString.propTypes = {
  children: PropTypes.string.isRequired
};

export { lex, parse, compressStrings, render };

export default SafelyOutputString;
