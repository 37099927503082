interface SuffixesInterface {
  [key: string]: {
    [key: string]: string;
  };
}

export const YEAR = 'year';
export const MONTH = 'month';
export const DAY = 'day';
export const LONG = 'long';
export const ORDINAL = 'ordinal';
export const EN_GB = 'en-GB';
export const ORDINALSUFFIXES: SuffixesInterface = {
  'en-GB': {
    one: 'st',
    two: 'nd',
    few: 'rd',
    other: 'th',
  },
  'cs-CZ': {
    other: '.',
  },
  'hu-HU': {
    one: '.',
    other: '.',
  },
  'sk-SK': {
    other: '.',
  },
};
