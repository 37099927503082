import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import SimpleHeaderLink from './link';
import BrandLogoLink from '#/components/brand-logo-link';

/**
 * Represents the header a customer sees during checkout.
 *
 * `leftHref`: the URL that should be passed to the left `SimpleHeaderLink`
 * `leftText`: the text that should be passed to the left `SimpleHeaderLink`
 */

export default class SimpleHeader extends PureComponent {
  static propTypes = {
    leftHref: PropTypes.string,
    leftText: PropTypes.string
  };

  render() {
    return (
      <header className="simple-header">
        <div className="simple-header__wrapper">
          {this.props.leftHref && this.props.leftText && (
            <div className="simple-header__left-content">
              <SimpleHeaderLink
                href={this.props.leftHref}
                text={this.props.leftText}
              />
            </div>
          )}
          <BrandLogoLink logoClass="brand-logo-link--centered" />
        </div>
      </header>
    );
  }
}
