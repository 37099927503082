export default `
  query AddressSearch($query: String, $count: Int, $geoCoordinates: GeoCoordinatesInputType) {
    address {
      search(query: $query, count: $count, geoCoordinates: $geoCoordinates) {
        info {
          query
          count
        }
        addresses {
          id
          addressLines
          geoCoordinates {
            latitude
            longitude
          }
        }
      }
    }
  }
`;
