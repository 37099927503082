import {
  ADDRESSES,
  LOCATION,
  TROLLEY_CONTENTS
} from '#/constants/spa-resource';
import { getLocationResource } from '#/selectors/resources';
import { updateLocations } from '#/actions/location-action-creators';

export const type = LOCATION;

export const params = ['query'];

export const dependencies = [ADDRESSES, TROLLEY_CONTENTS];

export const handler = (dispatch, getState) => {
  const { data: locations } = getLocationResource(getState());

  if (!locations) {
    return;
  }
  dispatch(updateLocations({ locations }));
};
