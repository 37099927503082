import { logApmError } from '#/lib/apm';
import { clientGraphqlPost } from '#/lib/requests/client-graphql';
import graphqlQuery from '#/lib/requests/graphql-queries/address-search';
import uuidv4 from 'uuid/v4';

export type RequestConfig = {
  apiKey: string;
  endpoint: string;
  region: string;
  atrc: string;
};

type ErrorResponse = {
  status: number;
  message: string;
  parsedBody?: {
    errors?: Error[];
  };
};

export type AddressSuggestion = {
  id: string;
  addressLines: string[];
  geoCoordinates: {
    latitude: string;
    longitude: string;
  };
};

export type AddressSearchResponse = {
  data: {
    address: {
      search: {
        info: {
          query: string;
          count: number;
        };
        addresses: AddressSuggestion[];
      };
    };
    cache: {
      maxAge: number;
    };
  };
};

type PayloadKeyValue = string | number | undefined | null;

export const addressSearch = async (
  payload: { [key: string]: PayloadKeyValue },
  { apiKey, endpoint, region, atrc }: RequestConfig,
): Promise<AddressSearchResponse | { error?: boolean }> => {
  const uuid = uuidv4();
  const traceId = `${atrc}:${uuid}`;
  const context = { 'x-apikey': apiKey, traceId, region };
  const queryVariables: { query: PayloadKeyValue; count?: PayloadKeyValue } = {
    query: payload.keyword,
  };

  if (payload.count) {
    queryVariables.count = payload.count;
  }

  try {
    const response = await clientGraphqlPost(endpoint, context, graphqlQuery, queryVariables);
    return response;
  } catch (responseError) {
    const res = responseError as ErrorResponse;
    const error = res?.parsedBody?.errors?.[0];
    const statusCode = res?.status || 'unknown';
    const errorMessage = error?.message || res.message || 'unknown';
    const message = `Address Search Error - Message: ${errorMessage}, Status: ${statusCode}`;

    logApmError(new Error(message), { traceId });
    return { error: true };
  }
};
