import React from 'react';
import PropTypes from 'prop-types';
import { connect } from '#/lib/render/connect-deep-compare';
import classNames from 'classnames';
import Link from '#/components/link';
import analyticsBus from '#/analytics/analyticsBus';
import { addModalToUrl } from '#/lib/url/modal-utils';
import { AMEND_SAVE_MODAL } from '#/constants/modal-names';
import { getCurrentUrl } from '#/reducers/app';
import { basicEvent } from '#/analytics/types/basic';
import { openModal } from '#/actions/ui-action-creators';

function SaveChangesButton({
  disabled,
  classes,
  currentUrl,
  buttonText,
  openModal
}) {
  return (
    <Link
      className={classNames(
        'button button-primary',
        {
          'button-disabled': disabled
        },
        classes
      )}
      disabled={disabled}
      href={addModalToUrl(currentUrl, AMEND_SAVE_MODAL)}
      onClick={e => {
        e.preventDefault();
        openModal(AMEND_SAVE_MODAL);
        basicEvent(analyticsBus, {
          type: 'contextCard',
          value: 'save changes'
        });
      }}
    >
      <span>{buttonText}</span>
    </Link>
  );
}

SaveChangesButton.propTypes = {
  buttonText: PropTypes.string.isRequired,
  classes: PropTypes.string,
  currentUrl: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  openModal: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  openModal: state.ui.openModal,
  currentUrl: getCurrentUrl(state)
});

export default connect(mapStateToProps, { openModal })(SaveChangesButton);
