import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import helpers from '#/lib/decorators/helpers';
import DisplayOnlyInNoJs from '#/components/shared/display-only-in-no-js';
import DisplayOnlyInJs from '#/components/shared/display-only-in-js';
import analyticsBus from '#/analytics/analyticsBus';
import { debounce } from '#/utils/misc';
import { connect } from '#/lib/render/connect-deep-compare';
import { updateLineItemsPreference } from '#/actions/trolley/trolley-action-creators';
import {
  FIND_SUITABLE_ALTERNATIVE,
  DO_NOT_SUBSTITUTE
} from '#/constants/substitution-options';
import {
  getIsUserItemPreferencesUpdating,
  getSubstituteAllItems,
  getTrolleyShoppingMethod
} from '#/selectors/trolley';
import { getCurrentUrl } from '#/reducers/app';
import { isValidText } from '#/utils/text-utils';
import PickerNotes from './picker-notes';
import PickerNotesDropdown from './picker-notes-dropdown';
import PickerNotesProgress from './picker-notes-progress';
import SubstitutionPreference from './substitution-preference';
import {
  getProductTitle,
  getSubstitutionOption,
  getPickerNote,
  getProductId
} from '#/selectors/item';
import { isOnDemandShoppingMethod } from '#/lib/shopping-method-util';
import { getIsWhooshDeliverySubstitutionsEnabled } from '#/toggles/whoosh-delivery-substitutions';

function getSubstitution(isSubstitute) {
  return isSubstitute ? FIND_SUITABLE_ALTERNATIVE : DO_NOT_SUBSTITUTE;
}

const mapStateToProps = (state, { f: feature, isAvailable }) => ({
  currentUrl: getCurrentUrl(state),
  showLineItemSubstitutionPreference:
    ((feature('showLineItemSubstitutionPreference') &&
      !isOnDemandShoppingMethod(getTrolleyShoppingMethod(state))) ||
      getIsWhooshDeliverySubstitutionsEnabled(state)) &&
    isAvailable,
  isUserItemPreferencesUpdating: getIsUserItemPreferencesUpdating(state),
  isSubstitureAllowedPreference: getSubstituteAllItems(state)
});

@helpers(['f', 't', 'l'])
@connect(mapStateToProps, {
  updateLineItemsPreference
})
export default class LineItemPreference extends PureComponent {
  static propTypes = {
    currentUrl: PropTypes.string.isRequired,
    f: PropTypes.func.isRequired,
    isAvailable: PropTypes.bool,
    isSubstitureAllowedPreference: PropTypes.bool.isRequired,
    isUserItemPreferencesUpdating: PropTypes.string,
    item: PropTypes.object.isRequired,
    l: PropTypes.func.isRequired,
    pickerNotesInputLimit: PropTypes.number,
    showLineItemSubstitutionPreference: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    updateLineItemsPreference: PropTypes.func.isRequired
  };

  static defaultProps = {
    isUserItemPreferencesUpdating: 'idle'
  };

  constructor(...args) {
    super(...args);

    const value = getPickerNote(this.props.item) || '';

    this.state = {
      value,
      active: false,
      expanded: value.length > 0,
      isUpdating: 'idle'
    };

    const { t: translate, f: feature } = this.props;

    this.feature = {
      showPickerNotes: feature('showPickerNotes')
    };

    this.text = {
      productTilePickerNoteUpdateAddNote: translate(
        'product-tile:picker-note-update-add-note'
      ),
      productTileAllowPreferenceFor: translate(
        'product-tile:allow-preference-for'
      ),
      productTilePickerNoteError: translate('product-tile:picker-note-error'),
      productTilePickerUpdateNonJs: translate(
        'product-tile:picker-note-update-non-js'
      ),
      productTilePickerNoteInstructions: translate(
        'product-tile:picker-note-instructions'
      ),
      productTilePickerNoteInstructionsAria: translate(
        'product-tile:picker-note-instructions-aria',
        { title: getProductTitle(this.props.item) }
      ),
      productTilePickerNotePlaceholder: translate(
        'product-tile:picker-note-placeholder'
      ),
      productTileAllowSubsPerItem: translate(
        'product-tile:allow-subs-per-item'
      ),
      productTilePickerNoteMaxReached: translate(
        'product-tile:picker-note-max-reached'
      ),
      productTileNotAllowSubstitutionsSelection: translate(
        'product-tile:substitutions.not-allow-substitutions-selection'
      ),
      productTileAllowSubstitutionsSelection: translate(
        'product-tile:substitutions.allow-substitutions-selection'
      ),
      slotsCommonInstructionsSaved: translate(
        'slots:common.instructions-saved'
      ),
      slotsCommonInstructionsSaving: translate(
        'slots:common.instructions-saving'
      )
    };

    this.link = {
      updateUserItemPreference: this.props.l('/update-user-item-preference')
    };

    this.handleSubstitutionToggle = this.handleSubstitutionToggle.bind(this);
    this.handlePickerNoteToggle = this.handlePickerNoteToggle.bind(this);
    this.handlePickerNoteFocus = this.handlePickerNoteFocus.bind(this);
    this.handlePickerNoteBlur = this.handlePickerNoteBlur.bind(this);
    this.handlePickerNoteChange = this.handlePickerNoteChange.bind(this);

    this.savePickerNoteChange = debounce(pickerNote => {
      this.updateItem({
        pickerNote
      });
      this.tellBertieAboutUpdatedPickerNotes();
    }, 500);
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.isUpdating !== nextProps.isUserItemPreferencesUpdating) {
      if (this.state.active || this.state.isUpdating === 'saving') {
        this.setState({
          isUpdating: nextProps.isUserItemPreferencesUpdating
        });
      }
    }

    if (this.props.item !== nextProps.item) {
      const value = getPickerNote(nextProps.item) || '';

      if (this.state.value !== value) {
        this.setState({
          value
        });
      }
    }
  }

  handleSubstitutionToggle(event) {
    event.preventDefault();

    const subs = !this.isSubstitutionSelected();

    this.updateItem({
      subs
    });
    this.tellBertieAboutUpdatedSubstitution(subs);
  }

  handlePickerNoteToggle() {
    if (this.state.value.length === 0) {
      this.setState({
        expanded: !this.state.expanded
      });
    }
  }

  handlePickerNoteFocus() {
    this.hasToldBertieDuringThisFocusSession = false;
    this.setState({
      active: true
    });
  }

  handlePickerNoteBlur() {
    this.setState({
      active: false
    });
  }

  handlePickerNoteChange(e) {
    const value = e.target.value;

    if (
      value.length <= this.props.pickerNotesInputLimit &&
      this.state.value !== value &&
      isValidText(value)
    ) {
      this.setState({
        value,
        isUpdating: 'saving'
      });
      this.savePickerNoteChange(value);
    }
  }

  tellBertieAboutUpdatedSubstitution(subs) {
    analyticsBus().emit('UIEventBasicEvent', {
      type: 'substitution',
      value: subs ? 'product tile:opt in' : 'product tile:opt out',
      action: 'now'
    });
  }

  tellBertieAboutUpdatedPickerNotes() {
    if (!this.hasToldBertieDuringThisFocusSession) {
      this.hasToldBertieDuringThisFocusSession = true;

      analyticsBus().emit('UIEventBasicEvent', {
        type: 'picker note',
        value: 'product tile',
        action: 'now'
      });
    }
  }

  updateItem(data) {
    const subs = data.hasOwnProperty('subs')
      ? data.subs
      : this.isSubstitutionSelected();
    const pickerNote = data.hasOwnProperty('pickerNote')
      ? data.pickerNote
      : getPickerNote(this.props.item);

    this.props.updateLineItemsPreference(this.props.item, {
      subs,
      pickerNote
    });
  }

  isSubstitutionSelected() {
    const { item, isSubstitureAllowedPreference } = this.props;
    const itemSubstitutionOption = getSubstitutionOption(item);

    return (
      itemSubstitutionOption === FIND_SUITABLE_ALTERNATIVE ||
      (!itemSubstitutionOption && isSubstitureAllowedPreference)
    );
  }

  renderSubstitutionPreference(data) {
    const substitutionsAccessibilityText = data.isSubstitutionSelected
      ? this.text.productTileNotAllowSubstitutionsSelection
      : this.text.productTileAllowSubstitutionsSelection;

    return (
      <SubstitutionPreference
        action={this.link.updateUserItemPreference}
        ariaButton={substitutionsAccessibilityText}
        ariaLabel={`${this.text.productTileAllowPreferenceFor} ${data.title}`}
        checked={data.isSubstitutionSelected}
        label={this.text.productTileAllowSubsPerItem}
        onClick={this.handleSubstitutionToggle}
        productId={data.productId}
        returnUrl={`${this.props.currentUrl}#tile-${data.productId}`}
        substitutionOption={getSubstitution(!data.isSubstitutionSelected)}
        value={data.pickerNote}
      />
    );
  }

  renderPickerNotesDropdown(data, children = null) {
    return (
      <PickerNotesDropdown
        label={this.text.productTilePickerNoteInstructions}
        ariaLabel={this.text.productTilePickerNoteInstructionsAria}
        action={this.link.updateUserItemPreference}
        value={data.pickerNote}
        onBlur={this.handlePickerNoteBlur}
        onChange={this.handlePickerNoteChange}
        onFocus={this.handlePickerNoteFocus}
        placeholder={this.text.productTilePickerNotePlaceholder}
        maxLength={this.props.pickerNotesInputLimit}
        productId={data.productId}
        substitutionOption={getSubstitution(data.isSubstitutionSelected)}
        returnUrl={`${this.props.currentUrl}#tile-${data.productId}`}
        submitButton={this.text.productTilePickerUpdateNonJs}
      >
        {children}
      </PickerNotesDropdown>
    );
  }

  renderPickerNotes(data) {
    const progress = (
      <PickerNotesProgress
        count={data.pickerNote.length}
        limit={this.props.pickerNotesInputLimit}
        status={this.state.isUpdating}
        maxReached={this.text.productTilePickerNoteMaxReached}
        saved={this.text.slotsCommonInstructionsSaved}
        saving={this.text.slotsCommonInstructionsSaving}
        error={this.text.productTilePickerNoteError}
      />
    );

    return (
      <PickerNotes
        ariaLabel={`${this.text.productTileAllowPreferenceFor} ${data.title}`}
        label={this.text.productTilePickerNoteUpdateAddNote}
        onClick={this.handlePickerNoteToggle}
      >
        <DisplayOnlyInNoJs>
          {this.renderPickerNotesDropdown(data)}
        </DisplayOnlyInNoJs>
        <DisplayOnlyInJs>
          {this.state.expanded ? (
            this.renderPickerNotesDropdown(data, progress)
          ) : (
            <div />
          )}
        </DisplayOnlyInJs>
      </PickerNotes>
    );
  }

  render() {
    const data = {
      pickerNote: this.state.value,
      productId: getProductId(this.props.item),
      title: getProductTitle(this.props.item),
      isSubstitutionSelected: this.isSubstitutionSelected()
    };

    const { showLineItemSubstitutionPreference } = this.props;

    return (
      <div>
        {showLineItemSubstitutionPreference &&
          this.renderSubstitutionPreference(data)}
        {this.feature.showPickerNotes && this.renderPickerNotes(data)}
      </div>
    );
  }
}
