import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Image from '../image';

export default class ErrorTemplate extends PureComponent {
  static propTypes = {
    additionalInformation: PropTypes.node,
    children: PropTypes.node,
    classname: PropTypes.string,
    diagnostics: PropTypes.string,
    explanation: PropTypes.string,
    heading: PropTypes.string
  };

  render() {
    return (
      <section className={this.props.classname}>
        <Image src={'/images/sadphone.png'} alt={this.props.explanation} />
        <h1>{this.props.heading}</h1>
        <p>{this.props.explanation}</p>
        <p>{this.props.additionalInformation}</p>

        {this.props.diagnostics && (
          <p className="error-page__diagnostics">{this.props.diagnostics}</p>
        )}
      </section>
    );
  }
}
