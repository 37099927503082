import { setTransactionName } from '@peas/apm';
import { updatePageUrlWithoutRefresh, updateParamsInUrl } from './url-utils';

export function removeModalFromUrl(url, { ...keysToRemove } = {}) {
  Object.entries(keysToRemove).forEach(([param]) => {
    keysToRemove[param] = null;
  });
  return updateParamsInUrl(url, {
    currentModal: null,
    modalData: null,
    ...keysToRemove
  });
}

export function addModalToUrl(url, modalName, modalData) {
  if (!modalName) {
    return url;
  }

  const urlWithoutModalParams = removeModalFromUrl(url);
  const urlWithModalName = updateParamsInUrl(urlWithoutModalParams, {
    currentModal: modalName
  });

  if (modalData) {
    if (typeof modalData === 'object') {
      return updateParamsInUrl(urlWithModalName, modalData);
    }
    return updateParamsInUrl(urlWithModalName, { modalData: modalData });
  }

  return urlWithModalName;
}

const modalHistoryStateHandler = (
  dispatch,
  closeDispatchValue,
  keysToRemove
) => {
  dispatch(removeFromUrlAndDispatchModal(closeDispatchValue, keysToRemove));
};
let _modalHistoryStateHandler;

export function addToUrlAndDispatchModal(
  modalName,
  modalData,
  dispatchValue,
  closeDispatchValue
) {
  return dispatch => {
    if (typeof window !== 'undefined') {
      const newUrl = addModalToUrl(window.location.href, modalName, modalData);

      updatePageUrlWithoutRefresh(window, newUrl);
      _modalHistoryStateHandler = modalHistoryStateHandler.bind(
        this,
        dispatch,
        closeDispatchValue,
        modalData
      );
      window.addEventListener('popstate', _modalHistoryStateHandler);
    }

    return dispatch(dispatchValue);
  };
}

export function removeFromUrlAndDispatchModal(dispatchValue, keysToRemove) {
  return dispatch => {
    if (typeof window !== 'undefined') {
      const urlParams = new URLSearchParams(window.location.search);

      setTransactionName(`closeModal/${urlParams.get('currentModal')}`);

      const newUrl = removeModalFromUrl(window.location.href, keysToRemove);

      window.history.replaceState(null, null, newUrl);
      window.removeEventListener('popstate', _modalHistoryStateHandler);
    }

    return dispatch(dispatchValue);
  };
}

export const hasShownBasketBreachModal = () => {
  if (process.env.CLIENT_SIDE) {
    return sessionStorage.getItem('shownBasketBreachModal') === 'true';
  }

  return true;
};

export const clearUserSessionData = key => {
  if (typeof window !== undefined) {
    window.sessionStorage.removeItem(key);
  }
};
