import { Items } from '#/custom-typings/redux-store/trolley.defs';
import { getProductBulkBuyLimitGroupId, getProductId } from '#/selectors/item';
import { Item } from '#/lib/records/item';

type GroupedItems = Map<string, Items>;

export default function getBulkBuyLimitGroupedItems(items: Items | Array<Item>): GroupedItems {
  const groupedItems = new Map();

  items.forEach((item, idOrIdx) => {
    const groupKey = getProductBulkBuyLimitGroupId(item);
    const id = Array.isArray(items) ? getProductId(item) : idOrIdx;

    if (groupKey) {
      if (groupedItems.has(groupKey)) {
        const group = groupedItems.get(groupKey);

        group.set(id, item);
        groupedItems.set(groupKey, group);
      } else {
        const newGroup = new Map();

        newGroup.set(id, item);
        groupedItems.set(groupKey, newGroup);
      }
    }
  });

  return groupedItems;
}
