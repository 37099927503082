import { getFeatureVariableStringFromState } from '#/lib/optimizely-manager';
import {
  bookingModalVariants,
  SHOWN_SLOT_REBOOK_MODAL_KEY,
  SHOWN_SLOT_REMIND_MODAL_KEY,
} from '#/experiments/oop-2210/constants';
import { getCurrentUrl } from '#/reducers/app';
import { sessionStore } from '#/lib/data-store/client-store';
import { getIsAmendBasket } from '#/selectors/trolley';

const {
  featureKey,
  variationKey,
  useCustomerId,
  variants: { default: defaultVariant, rebookModal, remindModal, rebookOrRemindModal },
} = bookingModalVariants;

const getBookingModalVariant = (state: Store): string => {
  const variant = getFeatureVariableStringFromState(state, featureKey, variationKey, { useCustomerId });
  return variant ?? defaultVariant;
};

const isAmendMode = (state: Store): boolean => getIsAmendBasket(state);
export const getIsRebookModalVariant = (state: Store): boolean => getBookingModalVariant(state) === rebookModal;
export const getIsRemindModalVariant = (state: Store): boolean => getBookingModalVariant(state) === remindModal;
export const getIsRebookOrRemindModalVariant = (state: Store): boolean =>
  getBookingModalVariant(state) === rebookOrRemindModal;

export const hideBookingModalOnPage = (currentPage: string): boolean =>
  currentPage?.includes('slots') ||
  currentPage?.includes('buylists') ||
  currentPage?.includes('checkout') ||
  currentPage?.includes('zone');

export const showSlotRemindModal = (state: Store): boolean => {
  return (
    !isAmendMode(state) &&
    !sessionStore?.get(SHOWN_SLOT_REMIND_MODAL_KEY) &&
    !hideBookingModalOnPage(getCurrentUrl(state)) &&
    getIsRemindModalVariant(state)
  );
};

export const showSlotRebookModal = (state: Store): boolean => {
  return (
    !isAmendMode(state) &&
    !sessionStore?.get(SHOWN_SLOT_REBOOK_MODAL_KEY) &&
    !hideBookingModalOnPage(getCurrentUrl(state)) &&
    getIsRebookModalVariant(state)
  );
};

export const showBothModals = (state: Store): boolean => {
  return !isAmendMode(state) && !hideBookingModalOnPage(getCurrentUrl(state)) && getIsRebookOrRemindModalVariant(state);
};
