import { getResourceData } from '../resources/resource-util';
import { FAVORITES } from '../constants/spa-resource';
import { RECEIVE_FAVORITE_INTERRUPTS } from '../constants/action-types';
import {
  InterruptsObject,
  InterruptsState,
  UpdateInterruptsAction,
  InterruptsProps,
} from '#/custom-typings/redux-store/interrupts.defs';

export default (state: InterruptsState = {}, action: UpdateInterruptsAction): InterruptsState => {
  switch (action.type) {
    case RECEIVE_FAVORITE_INTERRUPTS:
      return { ...state, interrupts: action.value };
    default:
      return state;
  }
};

export const getDefaultStateFromProps = (props: InterruptsProps): InterruptsState => {
  const favoritesResource = getResourceData(props.resources, FAVORITES);
  const favoritesResourceInterrupts = favoritesResource?.interrupts;
  const interrupts = favoritesResourceInterrupts || props.interrupts || null;
  return { interrupts };
};

export const getInterrupts = ({ interrupts }: Store): InterruptsObject => interrupts.interrupts;
