import { PageInformationEnum, Fragment } from './types';

export const PAGE_INFORMATION = `fragment PageInformation on ListInfoInterface {
  totalCount: total
  pageNo: page
  count
  pageSize
  matchType
  offset
}`;

export const buildDynamicPageInformationFragment = (keys: PageInformationEnum[]): Fragment => {
  const options = keys.map(key => `  ${key}`).join('\n');

  return {
    key: `pageInformation: info {
      ...PageInformation
    }`,
    value: `fragment PageInformation on ListInfoInterface {
    ${options}
    }`,
  };
};
