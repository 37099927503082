import React from 'react';
import { connect } from '#/lib/render/connect-deep-compare';
import { TTranslateFunc } from '#/lib/records/helpers.defs';
import { getRestOfShelfUrl } from '#/selectors/item';
import { Item } from '#/lib/records/item';
import { StyledTextButtonLink } from './styled';
import Link from '#/components/link-check-spa';
import analyticsBus from '#/analytics/analyticsBus';
import { basicEvent } from '#/analytics/types/basic';
import { DELAY } from '#/analytics/constants';
import {
  getUnavailableItemHeightVariant,
  getShouldShowSeeAlternativesOnSubs,
  getShouldShowSeeAlternativesAsBuyboxAction,
} from '#/experiments/oop-1017/selectors';
import {
  UnavailableItemHeightVariants,
  UNAVAILABLE_PRODUCT_WITHOUT_SUBS,
  UNAVAILABLE_PRODUCT_WITH_SUBS,
  SUBSTITUTED_PRODUCT,
  SEE_ALTERNATIVES,
} from '#/experiments/oop-1017/constants';
import { getIsSubstitute, hasSubstitution } from '#/selectors/item';
import { getLanguageLink } from '#/reducers/app';

const iconConfig = { graphic: 'forwardLink', position: { global: 'right' } };

type OwnState = {
  restOfShelfUrl: ReturnType<typeof getRestOfShelfUrl>;
  variant: UnavailableItemHeightVariants;
};

type OwnProps = {
  item: Item;
  translate: TTranslateFunc;
  classes: string;
};

type SPALink = { children: JSX.Element; className: string; href: string; onClick: Function };

const mapStateToProps = (state: Store, { item }: OwnProps): OwnState => {
  return {
    restOfShelfUrl: getLanguageLink(state, getRestOfShelfUrl(item)),
    variant: getUnavailableItemHeightVariant(state),
  };
};

type Props = OwnProps & OwnState;
type BertieEvent = { type: string; value: string; action: string };

const getBertieEventConfig = (variant: UnavailableItemHeightVariants, item: Item): BertieEvent => {
  let type = UNAVAILABLE_PRODUCT_WITHOUT_SUBS;

  if (getShouldShowSeeAlternativesAsBuyboxAction(variant) && hasSubstitution(item)) {
    type = UNAVAILABLE_PRODUCT_WITH_SUBS;
  }

  if (getShouldShowSeeAlternativesOnSubs(variant) && getIsSubstitute(item)) {
    type = SUBSTITUTED_PRODUCT;
  }

  return {
    type,
    value: SEE_ALTERNATIVES,
    action: DELAY,
  };
};

const SeeAlternativesButton = ({ translate, restOfShelfUrl, classes, variant, item }: Props): JSX.Element => {
  const clickHandler = (): void => {
    basicEvent(analyticsBus, getBertieEventConfig(variant, item));
  };
  const spaLink = ({ children, className, href, onClick }: SPALink): JSX.Element => (
    <Link
      to={href}
      className={className}
      onClick={onClick}
      data-auto="see-alternatives"
      aria-label={translate('product-tile:see-alternatives')}
    >
      {children}
    </Link>
  );

  return (
    <StyledTextButtonLink
      className={classes}
      buttonVariant="secondary"
      variant="textButton"
      href={restOfShelfUrl}
      icon={iconConfig}
      spaLink={spaLink}
      onClick={clickHandler}
    >
      {translate('product-tile:see-alternatives')}
    </StyledTextButtonLink>
  );
};

export default connect(mapStateToProps)(SeeAlternativesButton);
