import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from '#/lib/render/connect-deep-compare';
import Link from '#/components/link';
import { SectionMessage } from '#/components/cms-driven/section-message';
import helpers from '#/lib/decorators/helpers';
import ModalLink from '#/components/modals/modal-link';
import { AMEND_SAVE_MODAL } from '#/constants/modal-names';
import { getLanguageLink } from '#/reducers/app';

const mapStateToProps = state => ({
  homePageLink: getLanguageLink(state, '/')
});

@helpers(['t', 'c'])
@connect(mapStateToProps)
export default class AmendModalLinks extends PureComponent {
  static propTypes = {
    c: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    dividerClass: PropTypes.string.isRequired,
    homePageLink: PropTypes.string.isRequired,
    linkClass: PropTypes.string.isRequired,
    linkContinueClass: PropTypes.string.isRequired,
    linkName: PropTypes.string.isRequired,
    modalName: PropTypes.string.isRequired,
    openModal: PropTypes.func.isRequired,
    showBagOptions: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired
  };

  onClick = () => {
    const closeModal = this.props.closeModal;

    if (typeof closeModal === 'function' && window.isSPA) {
      closeModal();
    }
  };

  render() {
    const {
      c: config,
      closeModal,
      dividerClass,
      homePageLink,
      linkClass,
      linkContinueClass,
      linkName,
      modalName,
      openModal,
      showBagOptions,
      t: translate
    } = this.props;

    return (
      <div>
        <ModalLink
          wrapperClassNames="js-change-bar-save-changes button button-primary small"
          closeModal={closeModal}
          modalName={AMEND_SAVE_MODAL}
          openModal={openModal}
        >
          {translate('orders:amend.save-changes')}
        </ModalLink>
        <div className={dividerClass}>
          <SectionMessage message={translate('checkout:common.lower-or')} />
        </div>
        <div className={linkContinueClass}>
          <div>
            <Link href={homePageLink} onClick={this.onClick}>
              {translate('slots:common.click-continue-shopping')}
              <span className="icon icon-chevron_right" />
            </Link>
          </div>
          {config('bagMessageAndLinkEnabled') && showBagOptions ? (
            <div>
              <ModalLink
                wrapperClassNames={linkClass}
                closeModal={closeModal}
                modalName={modalName}
                openModal={openModal}
              >
                {translate(linkName)}
                <span className="icon icon-chevron_right" />
              </ModalLink>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

AmendModalLinks.defaultProps = {
  showBagOptions: true
};
