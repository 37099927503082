import { Item } from '#/lib/records/item';
import { idInItems } from '#/lib/records/product-utils';
import { getProductId } from '#/selectors/item';

export default function setById(items: Array<Item>, itemToUpdate: Item): Array<Item> {
  const id = getProductId(itemToUpdate);

  if (!idInItems(id, items)) {
    return [...items, itemToUpdate];
  }

  return items.map((item: Item) => (getProductId(item) === id ? itemToUpdate : item));
}
