import { FAVORITES_CAROUSEL, TROLLEY_CONTENTS } from '#/constants/spa-resource';
import { getFavoritesCarouselResource } from '#/selectors/resources';
import { updateFavoritesCarousel } from '#/actions/results-actions';

export const type = FAVORITES_CAROUSEL;
export const params = ['trolleyItems', 'slot', 'isAmendBasket'];
export const dependencies = [TROLLEY_CONTENTS];

export function handler(dispatch, getState) {
  const { data } = getFavoritesCarouselResource(getState());

  if (!data) {
    return;
  }

  dispatch(updateFavoritesCarousel(data));
}
