import {
  StyledButton,
  StyledButtonContainer,
  StyledHeading,
  StyledModal,
  StyledText,
} from '#/components/modals/generic-slots-modal/styled';
import React from 'react';
import { ModalProps } from '@ddsweb/modal';
import { PRIMARY } from '@ddsweb/constants';

type Variants = 'primary' | 'secondary';
export type GenericSlotsModalButtonProps = {
  onClick?: () => void;
  isLoading?: boolean;
  text: string;
  variant?: Variants;
  testId?: string;
};
export type GenericSlotsModalProps = {
  buttons: Array<GenericSlotsModalButtonProps>;
  bodyText: React.ReactNode;
  onClose?: () => void;
  isModalOpen: boolean;
  headingText: string;
  modalId: string;
  headlineVisualSize?: string;
  bodyContainerDisplayStyle?: string;
} & Pick<ModalProps, 'accessibleLabel' | 'onCloseFocusRef'>;

export const GenericSlotsModal = ({
  modalId,
  isModalOpen,
  headingText,
  bodyText,
  buttons,
  onClose,
  accessibleLabel,
  onCloseFocusRef,
  headlineVisualSize = 'headline4',
  bodyContainerDisplayStyle = 'grid',
}: GenericSlotsModalProps): JSX.Element => (
  <StyledModal
    accessibleLabel={accessibleLabel ?? headingText}
    accessibleBehaviour
    closeLink={!!onClose}
    id={modalId}
    onChange={onClose}
    onCloseFocusRef={onCloseFocusRef}
    open={isModalOpen}
    ssr
  >
    <StyledHeading headingLevel="1" visualSize={headlineVisualSize}>
      {headingText}
    </StyledHeading>
    <StyledText>{bodyText}</StyledText>
    <StyledButtonContainer bodyContainerDisplayStyle={bodyContainerDisplayStyle}>
      {buttons.map(({ onClick, text, variant = PRIMARY, isLoading, testId }) => (
        <StyledButton
          isLoading={isLoading}
          variant={variant}
          key={`${modalId}-${text}}`}
          onClick={onClick}
          data-testid={testId}
        >
          {text}
        </StyledButton>
      ))}
    </StyledButtonContainer>
  </StyledModal>
);
