import { PlainObject } from '#/types';

export default (state: Partial<Store> = {}, action: { type: string; value: PlainObject }): PlainObject => {
  switch (action.type) {
    default:
      return state;
  }
};

export const getDefaultStateFromProps = (
  props: PlainObject,
): {
  order: PlainObject;
  isAmendedOrder: boolean;
  collectionPoints: PlainObject[];
} => {
  const { order, isAmendedOrder, collectionPoints } = props;

  return {
    order,
    isAmendedOrder,
    collectionPoints,
  };
};

export const getOrderStoreId = ({ order: { order } }: PlainObject): string | number | null => {
  return order?.storeId ?? null;
};
