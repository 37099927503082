import React from 'react';
import PropTypes from 'prop-types';

const StringToNode = ({ text, delimiter }) => {
  if (!text) return null;
  const regex = new RegExp(`${delimiter}(.*?)${delimiter}`, 'g');

  const allMatched = text.match(regex);

  if (!allMatched) return <span>{text}</span>;

  let startIndex = 0;
  const lastMatch = allMatched[allMatched.length - 1];
  let endIndex = text.lastIndexOf(lastMatch) + lastMatch.length;

  const result = allMatched.map((match, index) => {
    let matchIndex = text.indexOf(match);
    const preText = text.substring(startIndex, matchIndex);
    startIndex = matchIndex + match.length;
    return (
      <span key={index}>
        {preText}
        <strong>{match.replace(regex, '$1')}</strong>
        {index + 1 === allMatched.length && endIndex !== text.length && (
          <span>{text.substring(endIndex, text.length)}</span>
        )}
      </span>
    );
  });
  return result;
};

StringToNode.defaultProps = {
  delimiter: '##'
};

StringToNode.propTypes = {
  delimiter: PropTypes.string,
  text: PropTypes.string.isRequired
};

export default StringToNode;
