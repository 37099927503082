import React, { PureComponent } from 'react';
import { number, string, bool, func, shape, arrayOf } from 'prop-types';
import classnames from 'classnames';
import CharacterCount from './character-count';
import DisplayOnlyInJs from '#/components/shared/display-only-in-js';

export const PropTypeRules = arrayOf(
  shape({
    getMessage: func.isRequired,
    validate: func.isRequired
  })
);

export default class InputWithLabel extends PureComponent {
  static defaultProps = {
    customClassName: '',
    disabled: false,
    multiLine: false,
    hasError: false,
    wordCountSettings: {
      display: false,
      preText: '',
      customClassName: ''
    },
    defaultValue: '',
    rules: []
  };

  static propTypes = {
    customClassName: string,
    defaultValue: string,
    disabled: bool,
    errorMessage: string,
    hasError: bool,
    id: string.isRequired,
    label: shape({
      primaryText: string.isRequired,
      secondaryText: string
    }).isRequired,
    maxLength: number,
    multiLine: bool,
    name: string.isRequired,
    onBlur: func,
    onChange: func,
    placeholder: string,
    rules: PropTypeRules,
    wordCountSettings: shape({
      characterLimit: number,
      customClassName: string,
      display: bool,
      preText: string
    })
  };

  constructor(props) {
    super(props);

    this.state = {
      count: (props.defaultValue || '').length,
      hasError: props.hasError,
      errorMessage: props.errorMessage
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.hasError !== prevState.hasError ||
      nextProps.errorMessage !== prevState.errorMessage
    )
      return {
        hasError: nextProps.hasError,
        errorMessage: nextProps.errorMessage
      };
    return null;
  }

  onHandleChange = event => {
    const value = event.target.value;

    this.setState({
      count: value.length
    });
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  };

  renderCharacterCount() {
    const {
      wordCountSettings: { display, characterLimit, preText, customClassName }
    } = this.props;
    const { hasError, count } = this.state;

    return (
      display && (
        <CharacterCount
          characterLimit={characterLimit}
          count={count}
          preText={preText}
          customClassName={classnames(customClassName, {
            'input-validation-error': hasError
          })}
        />
      )
    );
  }

  renderSecondaryText(label) {
    return (
      label.secondaryText && (
        <span className="ui-ddl-input__heading-secondary-text">
          {label.secondaryText}
        </span>
      )
    );
  }

  render() {
    const {
      id,
      name,
      placeholder,
      defaultValue,
      maxLength,
      customClassName,
      multiLine,
      disabled,
      label
    } = this.props;
    const { hasError, errorMessage } = this.state;
    const Tag = multiLine ? 'textarea' : 'input';
    const optionalProps = {};

    if (maxLength) {
      optionalProps.maxLength = maxLength;
    }

    return (
      <div className="input-with-label__container">
        <label
          className={classnames('ui-ddl-input__heading-label', {
            'input-validation-error': hasError
          })}
          htmlFor={name}
        >
          {label.primaryText}
          {this.renderSecondaryText(label)}
        </label>
        <div
          className={classnames('ui-ddl-input', {
            [customClassName]: customClassName,
            'input-validation-error': hasError
          })}
        >
          <Tag
            name={name}
            id={id}
            onChange={this.onHandleChange}
            placeholder={placeholder}
            className={`input-with-label__${Tag}`}
            defaultValue={defaultValue}
            disabled={disabled}
            {...optionalProps}
          />
          <DisplayOnlyInJs>{this.renderCharacterCount()}</DisplayOnlyInJs>
        </div>
        {hasError && (
          <span className="ui-ddl-input__error-message" aria-live="assertive">
            {errorMessage}
          </span>
        )}
      </div>
    );
  }
}
