import { removeFilterCategoryFromUrl, setSelectedFacets } from '#/utils/product-filter-utils';
import { productFilterAnalyticsEvent } from '#/analytics/types/filter';
import { openModal } from '#/actions/ui-action-creators';
import { parseQueryString } from '#/lib/url/url-utils';
import { getFacets, getFilters, getCurrentFilters } from '#/reducers/filter';

import { NO_RESULT_MODAL } from '#/constants/modal-names';
import { FAVOURITES } from '#/constants/facet-categories';
import {
  UPDATE_CANCEL_URL,
  UPDATE_CURRENT_URL,
  UPDATING_START,
  UPDATING_STOP,
  UPDATE_FILTERS,
  UPDATE_FACETS,
  UPDATE_FROM_NEW_RESULTS,
} from '#/constants/action-types';
import { fetchResources } from '#/actions/resources-action-creators';
import { getTotalCount } from '#/reducers/results';
import { batchActions } from 'redux-batched-actions';
import { resultsAreUpdating, clearResultsAreUpdating } from '#/actions/results-actions';
import {
  Results,
  UpdateCancelUrl,
  UpdateCurrentUrl,
  UpdatingStart,
  UpdatingStop,
  UpdatingFilters,
  UpdatingFacets,
} from '#/actions/filter-action-creators.defs';
import { FacetList } from '#/lib/requests/graphql-queries/fragments/facet-lists.defs';
import { Dispatch, GetStore } from '#/custom-typings/redux-store/common';
import { getFilterRelevantResourcesFromRoutes } from '#/conditional-resources/get-relevant-resources-from-routes';

export const updateCancelUrl = (currentUrl: string): UpdateCancelUrl => ({
  type: UPDATE_CANCEL_URL,
  payload: removeFilterCategoryFromUrl(FAVOURITES, currentUrl, currentUrl.split('?')[0]),
});

export const updateCurrentUrl = (currentUrl: string): UpdateCurrentUrl => ({
  type: UPDATE_CURRENT_URL,
  payload: currentUrl,
});

const isUpdating = (): UpdatingStart => ({ type: UPDATING_START });
const clearIsUpdating = (): UpdatingStop => ({ type: UPDATING_STOP });

export const updateFilters = (queryParams: Record<string, unknown>, filters: Array<string>): UpdatingFilters => {
  const currentFilters = filters.reduce<Record<string, unknown>>((obj, filter) => {
    obj[filter] = queryParams[filter];

    return obj;
  }, {});

  return {
    type: UPDATE_FILTERS,
    payload: currentFilters,
  };
};

export const updateFacets = (facetsList: FacetList): UpdatingFacets => ({
  type: UPDATE_FACETS,
  payload: facetsList,
});

export const filter = (
  { params, routes }: { params: Record<string, unknown>; routes: Array<{ resources: unknown }> },
  { path, queryParams }: { path: string; queryParams: Record<string, unknown> },
  multiSelectionFacets: unknown,
  delimit: string,
  changedFilter: string,
) => async (dispatch: Dispatch, getState: GetStore): Promise<void> => {
  const appState = getState();
  const facets = getFacets(appState);
  dispatch(
    batchActions([
      isUpdating(),
      updateFilters(queryParams, getFilters(appState)),
      updateFacets(setSelectedFacets(facets, queryParams, delimit, multiSelectionFacets)),
    ]),
  );

  dispatch(resultsAreUpdating);

  const filteredResources = getFilterRelevantResourcesFromRoutes(getState(), [routes[routes.length - 1]]);

  try {
    await dispatch(
      fetchResources(
        filteredResources,
        { ...params, query: queryParams },
        {
          forceFetch: true,
          updateCurrentURL: path,
        },
      ),
    );
  } catch (error) {
    // no catch
  }

  // LEGO-28192: the state gets updated with the previous dispatch to get the updated value for sortBy filter and passed to productFilterAnalyticsEvent
  const updatedAppState = getState();
  productFilterAnalyticsEvent(getCurrentFilters(updatedAppState), getFacets(updatedAppState));

  const totalCount = getTotalCount(getState());

  if (totalCount === 0) {
    dispatch(openModal(NO_RESULT_MODAL, changedFilter));
  }

  dispatch(clearResultsAreUpdating);
  dispatch(clearIsUpdating());
};

export const updateFromNewResults = (results: Results, path: string) => (
  dispatch: Dispatch,
  getState: GetStore,
): void => {
  let currentFilters;

  if (path) {
    const queryParams = parseQueryString(path);

    currentFilters = updateFilters(queryParams, getFilters(getState())).payload;
  }

  dispatch({
    type: UPDATE_FROM_NEW_RESULTS,
    payload: { results, currentFilters },
  });
};
