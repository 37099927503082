import React from 'react';
import { Route } from 'react-router';
import App from '../../components/app'; // fails if aliased
import ChooseALocationLayout from '#/components/layouts/spa/choose-a-location';
import HomeLayout from '#/components/layouts/spa/home';
import TrolleyLayout from '#/components/layouts/spa/trolley';
import DefaultLayout from '#/components/layouts/spa/default';
import FavoritesLayout from '#/components/layouts/favorites';
import { BookASlotLayoutSPA } from '#/components/layouts/spa/book-a-slot';
import { DynamicRoute } from '#/routes/spa-routes/dynamic-route-components';
import {
  ACCOUNT_PHONE_NUMBER,
  ADDRESSES,
  APP_STATE,
  BUYLIST,
  BUYLISTBYID,
  DCS_PAGE,
  EXPERIMENTS,
  FAVORITES,
  FORM,
  FULFILMENT_METADATA,
  FULFILMENT_OPTIONS_DETAIL,
  HOME_PAGE,
  LAST_ORDER,
  LOCATION,
  MULTI_SEARCH_LIST,
  ORDER_LIST_DETAILS,
  PRODUCTS_BY_CATEGORY,
  PRODUCT_CATEGORIES,
  PRODUCT_DETAILS,
  PROMOTIONS_ALL,
  PROMOTIONS_BY_DEPARTMENT,
  PROMOTIONS_CONTENT,
  PROMOTIONS_ID_OR_TYPE,
  SEARCH,
  SLOT,
  TAXONOMY,
  TROLLEY_CONTENTS,
  USUALS,
  ZONE_PAGE,
  BROWSE
} from '#/constants/spa-resource';
import { SLOTS, WRITE_SPA_REVIEW } from '#/constants/page-names';
import onlyRenderIfSuccessful from '#/components/higher-order/only-render-if-successful';
import LazyComponent from '#/tmp-bundles/lazy-component'; // This is required due code structure and naming in dynamic-route-components
import isRegistered from '#/conditional-resources/conditions/is-registered';
import isAuthenticated from '#/conditional-resources/conditions/is-authenticated';
import checkCurrentTab from '#/conditional-resources/conditions/check-current-tab';
import isClickAndCollect from '#/conditional-resources/conditions/is-click-and-collect';
import isWhoosh from '#/conditional-resources/conditions/is-whoosh';
import isServer from '#/conditional-resources/conditions/is-server';
import {
  USUALS_TAB,
  LAST_ORDER_TAB,
  FAVORITES_TAB
} from '#/constants/favorites';
import {
  isOnDemandDeliverySlotBooked,
  isOnDemandDeliverySlotExpired
} from '#/conditional-resources/conditions/is-ondemand-delivery';
import { FULFILMENT_ESTIMATED_ARRIVAL } from '#/constants/spa-resource';
import { SUBSCRIPTIONS } from '#/constants/spa-resource';
import { OFFERS_FOR_YOU } from '#/experiments/oop-1557/constants/spa-resource';
import { OFFERS_FOR_YOU_TAB } from '#/experiments/oop-1557/constants/favorites';
import isClient from '#/conditional-resources/conditions/is-client';
/* eslint-disable new-cap */ export default (locales, f, config) => {
  const rootPath = `/(groceries)(/)${locales
    .map(locale => `(${locale})`)
    .join('')}(/)`;

  const isRouteAvailable = flag => {
    const isAvailable = f(flag);

    if (typeof isAvailable === 'boolean') {
      return isAvailable;
    }

    /**
     * Return false by default.
     * i.e. if you are asking if a route is available and the flag value
     * is not configured in the environment then return false by default.
     */
    return false;
  };

  return (
    <Route component={App}>
      <Route component={onlyRenderIfSuccessful(ChooseALocationLayout)}>
        {DynamicRoute({
          path: `${rootPath}slots/collection/stores`,
          component: new LazyComponent('ChooseALocation'),
          mfeRouteName: 'slots-collection',
          resources: [SLOT, LOCATION],
          analyticsEvent: 'dcSlots',
          requiresAuthentication: true,
          bundle: 'choose-a-location'
        })}
      </Route>
      <Route
        component={onlyRenderIfSuccessful(DefaultLayout)}
        resources={[
          APP_STATE,
          EXPERIMENTS,
          TROLLEY_CONTENTS,
          [
            TAXONOMY,
            {
              conditions: [isServer]
            }
          ],
          [
            FULFILMENT_OPTIONS_DETAIL,
            {
              conditions: [
                config('fulfilmentCard:onlyCollectionVariantPossible')
              ]
            }
          ],
          [
            FULFILMENT_ESTIMATED_ARRIVAL,
            {
              dependentOn: [TROLLEY_CONTENTS],
              conditions: [isAuthenticated, isOnDemandDeliverySlotBooked]
            }
          ]
        ]}
      >
        {DynamicRoute({
          path: `${rootPath}buy-lists/:buylistName`,
          component: new LazyComponent('Buylist'),
          resources: [BUYLISTBYID],
          analyticsEvent: 'dcBuylist',
          bundle: 'buylist'
        })}
        {DynamicRoute({
          path: `${rootPath}buylists/:groupName`,
          component: new LazyComponent('BuylistGroup'),
          resources: [BUYLIST],
          analyticsEvent: 'dcBuylist',
          bundle: 'buylist-group'
        })}
        {DynamicRoute({
          path: `${rootPath}buylists/:groupName/:buylistName`,
          component: new LazyComponent('BuylistGroup'),
          resources: [BUYLIST],
          analyticsEvent: 'dcBuylist',
          bundle: 'buylist-group'
        })}
        {DynamicRoute({
          path: `${rootPath}buylists/:groupName/:buylistName/preview`,
          component: new LazyComponent('BuylistGroup'),
          additionalParams: { preview: true },
          resources: [BUYLIST],
          analyticsEvent: 'dcBuylist',
          bundle: 'buylist-group'
        })}
        {DynamicRoute({
          path: `${rootPath}buylists/:groupName/:buylistName/:categoryName`,
          component: new LazyComponent('BuylistGroup'),
          resources: [BUYLIST],
          analyticsEvent: 'dcBuylist',
          bundle: 'buylist-group'
        })}
        {DynamicRoute({
          path: `${rootPath}buylists/:groupName/:buylistName/:categoryName/preview`,
          component: new LazyComponent('BuylistGroup'),
          additionalParams: { preview: true },
          resources: [BUYLIST],
          analyticsEvent: 'dcBuylist',
          bundle: 'buylist-group'
        })}
        {DynamicRoute({
          //  IGHS categories
          path: `${rootPath}categories/:categoryId`,
          component: new LazyComponent('Categories'),
          resources: [PRODUCTS_BY_CATEGORY],
          analyticsEvent: 'dcBrowse',
          bundle: 'categories'
        })}
        {DynamicRoute({
          path: `${rootPath}shop/:superdepartment/all`,
          asyncPage: true,
          mfeRouteName: BROWSE,
          component: new LazyComponent('Categories'),
          resources: [PRODUCTS_BY_CATEGORY],
          analyticsEvent: 'dcBrowse',
          bundle: 'categories'
        })}
        {DynamicRoute({
          path: `${rootPath}shop/:superdepartment/:department/:aisle`,
          asyncPage: true,
          mfeRouteName: BROWSE,
          component: new LazyComponent('Categories'),
          resources: [PRODUCTS_BY_CATEGORY],
          analyticsEvent: 'dcBrowse',
          bundle: 'categories'
        })}
        {DynamicRoute({
          //  UK PLP
          path: `${rootPath}shop/:superdepartment/:department/:aisle/:shelf`,
          asyncPage: true,
          mfeRouteName: BROWSE,
          component: new LazyComponent('Categories'),
          resources: [PRODUCTS_BY_CATEGORY],
          analyticsEvent: 'dcBrowse',
          bundle: 'categories'
        })}
        {DynamicRoute({
          //  taxonomy
          path: `${rootPath}shop(/:superdepartment)(/:department)`,
          additionalParams: { robots: 'noindex' },
          component: new LazyComponent('Shop'),
          resources: [PRODUCT_CATEGORIES],
          bundle: 'shop-all-groceries'
        })}
        {DynamicRoute({
          path: `${rootPath}zone/:pageName`,
          component: new LazyComponent('Zone'),
          additionalParams: { preview: false },
          resources: [ZONE_PAGE],
          analyticsEvent: 'dcZone',
          bundle: 'zone'
        })}
        {DynamicRoute({
          path: `${rootPath}zone/:pageName/preview`,
          component: new LazyComponent('Zone'),
          additionalParams: { preview: true },
          resources: [ZONE_PAGE],
          analyticsEvent: 'dcZone',
          bundle: 'zone'
        })}
        {DynamicRoute({
          path: `${rootPath}multi-search-results`,
          asyncPage: true,
          component: new LazyComponent('Search'),
          resources: [MULTI_SEARCH_LIST, SEARCH],
          analyticsEvent: 'dcSearch',
          bundle: 'search'
        })}
        {DynamicRoute({
          path: `${rootPath}search`,
          asyncPage: true,
          mfeRouteName: SEARCH,
          component: new LazyComponent('Search'),
          resources: [SEARCH],
          analyticsEvent: 'dcSearch',
          bundle: 'search'
        })}
        {DynamicRoute({
          path: `${rootPath}multi-search`,
          component: new LazyComponent('MultiSearch'),
          resources: [MULTI_SEARCH_LIST],
          analyticsEvent: 'dcSearch',
          bundle: 'multi-search',
          requiresAuthentication: true
        })}
        {DynamicRoute({
          path: `${rootPath}promotions`,
          component: new LazyComponent('PromotionsABRedesign'),
          resources: [
            PROMOTIONS_ALL,
            PROMOTIONS_BY_DEPARTMENT,
            PROMOTIONS_CONTENT
          ],
          analyticsEvent: 'dcPromotions',
          bundle: 'promotion-list'
        })}
        {DynamicRoute({
          path: `${rootPath}promotions/preview`,
          component: new LazyComponent('PromotionsABRedesign'),
          additionalParams: { preview: true },
          resources: [
            PROMOTIONS_ALL,
            PROMOTIONS_BY_DEPARTMENT,
            PROMOTIONS_CONTENT
          ],
          analyticsEvent: 'dcPromotions',
          bundle: 'promotions-new-home'
        })}
        {DynamicRoute({
          path: `${rootPath}promotions/all`,
          component: new LazyComponent('PromotionList'),
          resources: [PROMOTIONS_ALL, PROMOTIONS_CONTENT],
          analyticsEvent: 'dcPromotions',
          bundle: 'promotion-list'
        })}
        {DynamicRoute({
          path: `${rootPath}promotions/:idOrType`,
          component: new LazyComponent('PromotionList'),
          resources: [PROMOTIONS_ID_OR_TYPE, PROMOTIONS_CONTENT],
          analyticsEvent: 'dcPromotions',
          bundle: 'promotion-list'
        })}
        {DynamicRoute({
          path: `${rootPath}products/:id`,
          asyncPage: true,
          component: new LazyComponent('ProductDetails'),
          mfeRouteName: 'pdp',
          additionalParams: {
            deleteTaxonomyParent: false,
            includeReviews: true
          },
          resources: [PRODUCT_DETAILS],
          analyticsEvent: 'dcProductDetails',
          bundle: 'product-details'
        })}
        {DynamicRoute({
          path: `${rootPath}reviews/submission/:id`,
          component: new LazyComponent('RatingsReviewsSubmission'),
          isRouteAvailable: isRouteAvailable('enableRatingsReviewsSubmission'),
          resources: [FORM, PRODUCT_DETAILS],
          additionalParams: { robots: 'noindex', pageName: WRITE_SPA_REVIEW },
          analyticsEvent: 'dcRatingsReviewsSubmission',
          requiresAuthentication: true,
          bundle: 'ratings-reviews-submission'
        })}
        {DynamicRoute({
          path: `${rootPath}reviews/thankyou/:id`,
          component: new LazyComponent('RatingsReviewsThankyou'),
          isRouteAvailable: isRouteAvailable('enableRatingsReviewsSubmission'),
          resources: [PRODUCT_DETAILS],
          analyticsEvent: 'dcReview',
          requiresAuthentication: true,
          bundle: 'ratings-reviews-thankyou'
        })}
        <Route component={onlyRenderIfSuccessful(FavoritesLayout)}>
          {DynamicRoute({
            path: `${rootPath}favorites`,
            asyncPage: true,
            component: new LazyComponent('Favorites'),
            mfeRouteName: 'favourites',
            resources: [
              [
                FAVORITES,
                {
                  conditions: [
                    isClient,
                    state => checkCurrentTab(state, FAVORITES_TAB)
                  ]
                }
              ],
              [
                USUALS,
                { conditions: [state => checkCurrentTab(state, USUALS_TAB)] }
              ],
              [
                LAST_ORDER,
                {
                  conditions: [state => checkCurrentTab(state, LAST_ORDER_TAB)]
                }
              ],
              [
                OFFERS_FOR_YOU,
                {
                  conditions: [
                    state => checkCurrentTab(state, OFFERS_FOR_YOU_TAB)
                  ]
                }
              ]
            ],
            bundle: 'favorites',
            requiresAuthentication: true,
            analyticsEvent: 'dcFavourites'
          })}
        </Route>
      </Route>
      <Route component={onlyRenderIfSuccessful(DefaultLayout)}>
        {DynamicRoute({
          path: `${rootPath}slots`,
          pageTitle: 'common:pages.slots',
          component: new LazyComponent('BookASlotHub'),
          mfeRouteName: 'slots',
          analyticsEvent: 'dcSlotsHub',
          requiresAuthentication: true,
          bundle: 'book-a-slot-hub',
          resources: [
            APP_STATE,
            EXPERIMENTS,
            TROLLEY_CONTENTS,
            [TAXONOMY, { conditions: [isServer] }],
            FULFILMENT_OPTIONS_DETAIL
          ]
        })}
      </Route>
      <Route
        component={onlyRenderIfSuccessful(BookASlotLayoutSPA)}
        resources={[
          APP_STATE,
          EXPERIMENTS,
          TROLLEY_CONTENTS,
          [
            TAXONOMY,
            {
              conditions: [isServer]
            }
          ],
          [
            SUBSCRIPTIONS,
            {
              conditions: [isAuthenticated]
            }
          ]
        ]}
      >
        {DynamicRoute({
          path: `${rootPath}slots/:shoppingMethod`,
          pageTitle: 'common:pages.slots',
          component: new LazyComponent('BookASlot'),
          mfeRouteName: 'slots',
          mfeRouteNameMapper,
          additionalParams: {
            disableLoader: true,
            dfpReportingRequired: false,
            pageName: SLOTS,
            slotAnalytics: true
          },
          resources: [
            [
              ADDRESSES,
              {
                conditions: [isAuthenticated, isRegistered]
              }
            ],
            FULFILMENT_OPTIONS_DETAIL,
            DCS_PAGE,
            [
              ORDER_LIST_DETAILS,
              {
                conditions: [isAuthenticated, isRegistered]
              }
            ],
            ACCOUNT_PHONE_NUMBER,
            FULFILMENT_METADATA,
            SLOT,
            [
              LOCATION,
              {
                conditions: [isClickAndCollect]
              }
            ],
            [
              ACCOUNT_PHONE_NUMBER,
              {
                conditions: [isWhoosh]
              }
            ]
          ],
          analyticsEvent: 'dcSlots',
          requiresAuthentication: true,
          bundle: 'book-a-slot'
        })}
        {DynamicRoute({
          path: `${rootPath}slots/:shoppingMethod/preview`,
          pageTitle: 'common:pages.slots',
          component: new LazyComponent('BookASlot'),
          mfeRouteName: 'slots',
          mfeRouteNameMapper,
          additionalParams: {
            dfpReportingRequired: false,
            pageName: SLOTS,
            preview: true
          },
          resources: [
            ADDRESSES,
            FULFILMENT_OPTIONS_DETAIL,
            DCS_PAGE,
            ORDER_LIST_DETAILS,
            FULFILMENT_METADATA,
            SLOT,
            [
              LOCATION,
              {
                conditions: [isClickAndCollect]
              }
            ]
          ],
          requiresAuthentication: true,
          bundle: 'book-a-slot'
        })}
        {DynamicRoute({
          path: `${rootPath}slots/:shoppingMethod/:date`,
          pageTitle: 'common:pages.slots',
          component: new LazyComponent('BookASlot'),
          mfeRouteName: 'slots',
          mfeRouteNameMapper,
          additionalParams: {
            dfpReportingRequired: false,
            pageName: SLOTS,
            slotAnalytics: true
          },
          resources: [
            ADDRESSES,
            FULFILMENT_OPTIONS_DETAIL,
            DCS_PAGE,
            ORDER_LIST_DETAILS,
            FULFILMENT_METADATA,
            SLOT,
            [
              LOCATION,
              {
                conditions: [isClickAndCollect]
              }
            ]
          ],
          analyticsEvent: 'dcSlots',
          requiresAuthentication: true,
          bundle: 'book-a-slot'
        })}
      </Route>
      <Route
        component={onlyRenderIfSuccessful(TrolleyLayout)}
        resources={[
          APP_STATE,
          EXPERIMENTS,
          TROLLEY_CONTENTS,
          [
            TAXONOMY,
            {
              conditions: [isServer]
            }
          ],
          [
            FULFILMENT_OPTIONS_DETAIL,
            {
              conditions: [
                config('fulfilmentCard:onlyCollectionVariantPossible')
              ]
            }
          ],
          [
            FULFILMENT_ESTIMATED_ARRIVAL,
            {
              dependentOn: [TROLLEY_CONTENTS],
              conditions: [isAuthenticated, isOnDemandDeliverySlotBooked]
            }
          ]
        ]}
      >
        {DynamicRoute({
          path: `${rootPath}trolley`,
          pageTitle: 'common:pages.trolley',
          component: new LazyComponent('Trolley'),
          mfeRouteName: 'trolley',
          additionalParams: {
            pageName: 'trolley',
            includeHYF: true,
            includeSubstitutions: true,
            preview: false
          },
          resources: [DCS_PAGE],
          forceFetchResources: true,
          requiresAuthentication: true,
          analyticsEvent: 'dcTrolley',
          bundle: 'trolley'
        })}
        {DynamicRoute({
          path: `${rootPath}trolley/preview`,
          component: new LazyComponent('Trolley'),
          additionalParams: {
            pageName: 'trolley',
            includeHYF: true,
            includeSubstitutions: true,
            preview: true
          },
          resources: [DCS_PAGE],
          analyticsEvent: 'dcTrolley',
          bundle: 'trolley'
        })}
      </Route>
      <Route
        component={onlyRenderIfSuccessful(HomeLayout)}
        resources={[
          APP_STATE,
          EXPERIMENTS,
          TROLLEY_CONTENTS,
          [
            TAXONOMY,
            {
              conditions: [isServer]
            }
          ]
        ]}
      >
        {DynamicRoute({
          asyncPage: true,
          path: rootPath,
          component: new LazyComponent('Home'),
          mfeRouteName: 'homepage',
          resources: [
            [
              ADDRESSES,
              {
                conditions: [isAuthenticated, isRegistered]
              }
            ],
            [
              FULFILMENT_OPTIONS_DETAIL,
              {
                dependentOn: [TROLLEY_CONTENTS],
                conditions: [
                  isAuthenticated,
                  isRegistered,
                  config('fetchGetFulfilmentOptionOnHomePage') ||
                    isOnDemandDeliverySlotExpired
                ]
              }
            ],
            HOME_PAGE,
            FULFILMENT_METADATA,
            [
              ORDER_LIST_DETAILS,
              {
                conditions: [isAuthenticated, isRegistered]
              }
            ],
            [
              FULFILMENT_ESTIMATED_ARRIVAL,
              {
                dependentOn: [TROLLEY_CONTENTS],
                conditions: [isAuthenticated, isOnDemandDeliverySlotBooked]
              }
            ]
          ],
          additionalParams: {
            shouldTrackLiveOrder: true,
            preventFulfilmentMetadataFetch: true
          },
          analyticsEvent: 'dcHomepage',
          bundle: 'home'
        })}
        {DynamicRoute({
          path: `${rootPath}preview`,
          asyncPage: true,
          component: new LazyComponent('Home'),
          mfeRouteName: 'homepage',
          additionalParams: {
            preview: true,
            shouldTrackLiveOrder: true,
            preventFulfilmentMetadataFetch: true
          },
          resources: [
            [
              ADDRESSES,
              {
                conditions: [isAuthenticated, isRegistered]
              }
            ],
            FULFILMENT_OPTIONS_DETAIL,
            HOME_PAGE,
            FULFILMENT_METADATA,
            [
              ORDER_LIST_DETAILS,
              {
                conditions: [isAuthenticated, isRegistered]
              }
            ],
            [
              FULFILMENT_ESTIMATED_ARRIVAL,
              {
                dependentOn: [TROLLEY_CONTENTS],
                conditions: [isAuthenticated, isOnDemandDeliverySlotBooked]
              }
            ]
          ],
          analyticsEvent: 'dcHomepage',
          bundle: 'home'
        })}
      </Route>
    </Route>
  );
};

export const mfeRouteNameMapper = params => {
  switch (params?.shoppingMethod) {
    case 'collection':
      return 'slots-collection';
    case 'ondemand':
      return 'slots-ondemand';
    case 'delivery':
      return 'slots-delivery';
    default:
      return 'slots';
  }
};
