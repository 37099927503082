import { openModal } from '#/actions/ui-action-creators';
import { MARKETPLACE_PRODUCT_MODAL } from '#/constants/modal-names';
import { Dispatch, GetStore } from '#/custom-typings/redux-store/common';
import { sessionStore } from '#/lib/data-store/client-store';
import { getUser } from '#/reducers/user';
import { hasMarketPlaceCheckoutSegment } from '#/utils/user-utils';

export const showMarketPlaceProductModal = (isMarketPlaceProduct: boolean, qty: number) => {
  return (dispatch: Dispatch, getState: GetStore): void => {
    const marketPlaceProductModalShownInSession = sessionStore?.get(MARKETPLACE_PRODUCT_MODAL) || false;
    const oldMarketPlaceCustomer = hasMarketPlaceCheckoutSegment(getUser(getState()).segments);

    isMarketPlaceProduct &&
      qty >= 1 &&
      !oldMarketPlaceCustomer &&
      !marketPlaceProductModalShownInSession &&
      dispatch(openModal(MARKETPLACE_PRODUCT_MODAL, null, false));
  };
};
