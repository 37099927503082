import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Expander } from '@mfe/basket-expander';
import { SectionMessage } from '#/components/cms-driven/section-message';
import { SlimTradeBanner } from '#/components/cms-driven/slim-trade-banner';
import helpers from '#/lib/decorators/helpers';
import {
  getAppISORegionCode,
  getCurrentUrl,
  getIsMobile
} from '#/reducers/app';
import { FAVORITES } from '#/constants/tile-types';
import { BRAND_BANNER } from '#/constants/dcs-component-types';
import { FAVORITES as ANALYTICS_FAVORITES_TILE_TYPE } from '#/analytics/constants';
import { groupAndSort } from '#/lib/records/product-utils';
import withProfiler from '#/utils/perf-profiler';
import {
  getTaxonomyNode,
  getTaxonomyNodeWithSuperDepartmentAndDepartment,
  getProductSubstitutions,
  existingInBasketAndExcluded,
  getProductId,
  getSellerId,
  getGtin
} from '#/selectors/item';
import { LEGACY } from '#/components/product-tiles/common/constants';
import {
  emitContentInteractOp,
  emitRenderedContentOp
} from '#/analytics/bertie/events';
import { getBasketId } from '#/selectors/trolley';
import ListItem from '#/components/products/product-list/item';
import Container from '#/components/products/product-list/container';
import { GRID, LIST } from '#/constants/favorites';
import { sortByDepartment } from '#/components/products/product-list/list/helpers';
import { updateIBYCCarouselIndex } from '#/experiments/oop-2349/helpers';
import { RecsCarousel } from '#/experiments/oop-2349/components/recs-carousel';
import { isSelectedTabFavorites } from '#/lib/favorites-helpers';

const mapStateToProps = state => {
  return {
    basketId: getBasketId(state),
    isMobile: getIsMobile(state),
    ISORegionCode: getAppISORegionCode(state),
    shouldShowGridView: isSelectedTabFavorites(getCurrentUrl(state))
  };
};

@withProfiler('ProductList_List')
@helpers(['t', 'c', 'f'])
@connect(mapStateToProps)
export default class List extends Component {
  static propTypes = {
    basketId: PropTypes.string,
    experiments: PropTypes.object,
    hideQuantityToggle: PropTypes.bool,
    identifier: PropTypes.string,
    interrupts: PropTypes.object,
    isAmendBasket: PropTypes.bool,
    isGrid: PropTypes.bool,
    isGroceriesBasket: PropTypes.bool,
    isGrouped: PropTypes.bool,
    isMobile: PropTypes.bool,
    shouldShowGridView: PropTypes.bool,
    items: PropTypes.object.isRequired,
    listData: PropTypes.object.isRequired,
    onRenderRecommendations: PropTypes.func,
    pageNo: PropTypes.number,
    previousGroupName: PropTypes.string,
    productTileVariant: PropTypes.string,
    query: PropTypes.string,
    ISORegionCode: PropTypes.string,
    sectionMessageHeadingTag: PropTypes.string,
    tileType: PropTypes.string,
    totalCount: PropTypes.number,
    useExpander: PropTypes.bool
  };
  static defaultProps = {
    productTileVariant: LEGACY,
    experiments: {},
    useExpander: false
  };

  constructor(props) {
    super(props);

    /*
     We have to keep the UK spelling of favorites for analytics
     as the page value would be skewed by changing this
    */
    this._tileType =
      props.tileType === FAVORITES
        ? ANALYTICS_FAVORITES_TILE_TYPE
        : props.tileType;

    this.state = {
      recsCarouselIndex: -1,
      showRecsCarousel: false
    };
    this.maxItemIndex = -1;
  }

  componentDidMount() {
    this._dispatchInterruptAnalytics();
  }

  componentDidUpdate(prevProps) {
    const {
      state: { showRecsCarousel },
      props: {
        pageNo,
        experiments: { isIBYCCarouselEnabled = false, recsCarouselItemIndex }
      },
      _dispatchInterruptAnalytics,
      maxItemIndex
    } = this;
    const ibycCarouselIndex = updateIBYCCarouselIndex(
      prevProps.pageNo,
      _dispatchInterruptAnalytics,
      {
        isIBYCCarouselEnabled,
        pageNo,
        recsCarouselItemIndex,
        showRecsCarousel,
        maxItemIndexVal: maxItemIndex
      }
    );

    ibycCarouselIndex && this.updatedCarouselIndex(ibycCarouselIndex);
  }

  updatedCarouselIndex(updatedIndex) {
    if (this.state.recsCarouselIndex !== updatedIndex) {
      this.setState({
        recsCarouselIndex: updatedIndex,
        showRecsCarousel: true
      });
    }
  }

  _analyticsInterruptImpressions = [];

  _dispatchInterruptAnalytics = () => {
    if (this.props.interrupts) {
      const impressions = this._analyticsInterruptImpressions.map(
        impression => ({
          campaignId: impression.tracking?.id || impression.identifier,
          trackingId: impression.tracking?.id,
          posInModule: 1
        })
      );

      if (impressions.length > 0) {
        emitRenderedContentOp({
          content: [
            {
              componentId: null,
              componentType: 'c',
              displayArea: 'fi',
              modulePosition: 1,
              pageType: this._tileType,
              panel: impressions
            }
          ]
        });
      }
    }
  };

  _interruptOnClick = interruptProps => {
    const trackingId = interruptProps.tracking?.id;
    emitContentInteractOp({
      pageType: this._tileType,
      displayArea: 'fi',
      panel: [
        {
          campaignId: trackingId || interruptProps.identifier,
          trackingId,
          posInModule: 1
        }
      ],
      modulePosition:
        this._analyticsInterruptImpressions.findIndex(
          impression => impression.identifier === interruptProps.identifier
        ) + 1
    });
  };

  getItemDetailsClickHandler = (item = {}, index) => () => {
    const { isSponsoredProduct, product, isWhyNotTry, isNew } = item;
    const { basketId, pageType, ISORegionCode } = this.props;

    if (isSponsoredProduct) {
      emitContentInteractOp({
        interactionType: 'sponsored:citrus',
        contextValue: basketId,
        componentType: 'd',
        componentName: 'sponsored',
        media: {
          catalogueCountry: ISORegionCode
        },
        modulePosition: index + 1,
        pageType: pageType,
        displayArea: 'grd',
        panel: [
          {
            posInModule: 1,
            campaignId: product?.adId,
            product: {
              tpnb: product?.baseProductId,
              gtin: getGtin(item),
              isNew,
              isWhyNotTry,
              isSponsoredProduct,
              foodIcons: product?.foodIcons,
              sellerId: getSellerId(item),
              ...(product?.modelMetadata && {
                personalisationModel: `${product?.modelMetadata?.version}-${product?.modelMetadata?.name}`
              })
            }
          }
        ]
      });
    }

    if (product?.modelMetadata && !isSponsoredProduct) {
      emitContentInteractOp({
        interactionType: 'personalised',
        contextValue: basketId,
        componentType: 's',
        componentName: 'standard',
        media: {
          catalogueCountry: ISORegionCode
        },
        modulePosition: index + 1,
        pageType: pageType,
        displayArea: 'ukn',
        panel: [
          {
            posInModule: 1,
            campaignId: product?.adId,
            product: {
              tpnb: product?.baseProductId,
              gtin: getGtin(item),
              isNew,
              isWhyNotTry,
              isSponsoredProduct,
              foodIcons: product?.foodIcons,
              sellerId: getSellerId(item),
              personalisationModel: `${product?.modelMetadata?.version}-${product?.modelMetadata?.name}`
            }
          }
        ]
      });
    }
  };

  getShouldRenderSubstituteTile = item => {
    const isEBSRestrictedInAmendMode = existingInBasketAndExcluded(item);

    return !isEBSRestrictedInAmendMode;
  };

  getSubstitutions(item) {
    if (this.getShouldRenderSubstituteTile(item)) {
      return getProductSubstitutions(item);
    }

    return [];
  }

  makeProductTiles(items, offset) {
    const {
      tileType,
      isAmendBasket,
      listData,
      interrupts,
      isMobile,
      pageType,
      productTileVariant,
      resultType,
      isGrid,
      shouldShowGridView,
      experiments = {}
    } = this.props;
    const { showRecsCarousel, recsCarouselIndex } = this.state;

    let lastActionedTaxonomyNode;
    let substituteIndex = 0;
    const interruptKeys = (interrupts && Object.keys(interrupts)) || [];
    const { shouldRenderSubsInGrid, isFavGridViewBannerEnabled } = experiments;
    const itemList = [...items.values()].filter(
      item => typeof item !== 'undefined'
    );

    if (
      this.maxItemIndex !== itemList?.length - 1 &&
      experiments.isIBYCCarouselEnabled
    ) {
      this.maxItemIndex = itemList?.length - 1;
    }

    return itemList.map((originalItem, index) => {
      // structure for recent orders is different from the others so we need this check here
      if (Array.isArray(originalItem) && originalItem[1]) {
        originalItem = originalItem[1];
      }

      const item = originalItem;

      const itemId = getProductId(item);
      const interruptBanners = [];
      const layoutType = shouldShowGridView && isGrid ? GRID : LIST;
      const shouldRenderSub =
        shouldRenderSubsInGrid || (shouldShowGridView ? !isGrid : true);
      const {
        substituteTextHeading,
        analyticsSettings,
        isIBYCCarouselEnabled
      } = experiments;

      if (interruptKeys.length) {
        const taxonomyId =
          !isFavGridViewBannerEnabled && shouldShowGridView && isGrid
            ? getTaxonomyNodeWithSuperDepartmentAndDepartment(item)
            : getTaxonomyNode(item);

        if (taxonomyId !== lastActionedTaxonomyNode) {
          let i = interruptKeys.length;

          while (i--) {
            const key = interruptKeys[i];
            if (taxonomyId.includes(key)) {
              let targetInterupts = interrupts[key];
              if (isMobile) {
                targetInterupts =
                  interrupts[key].filter(
                    interrupt => interrupt.bannerType !== BRAND_BANNER
                  ) || [];
              }

              if (
                Array.isArray(targetInterupts) &&
                targetInterupts.length > 0 &&
                targetInterupts[0] != null
              ) {
                const interrupt = targetInterupts[0];

                const interruptProps = { ...interrupt };
                interruptProps.componentClickHandler = () =>
                  this._interruptOnClick(interruptProps);
                interruptProps.placement = this._analyticsInterruptImpressions.push(
                  interruptProps
                );

                interruptBanners.push(
                  <li
                    key={`index_${i}`}
                    className="product-list-interrupt ui-components-library"
                  >
                    <SlimTradeBanner {...interruptProps} />
                  </li>
                );
              }

              interruptKeys.splice(i, 1);
            }
          }

          lastActionedTaxonomyNode = taxonomyId;
        }
      }

      const substitutions = shouldRenderSub ? this.getSubstitutions(item) : [];
      if (
        typeof substitutions?.length !== 'undefined' &&
        substitutions?.length > 0
      ) {
        substituteIndex += substitutions?.length;
      }

      const listItems = (
        <Fragment key={itemId}>
          <ListItem
            isAmendBasket={isAmendBasket}
            item={item}
            itemId={itemId}
            itemIndex={index}
            key={itemId}
            listData={listData}
            offset={offset}
            onDetailsClick={this.getItemDetailsClickHandler(item, index)}
            productTileVariant={productTileVariant}
            resultType={resultType}
            shouldShowSubs={this.getShouldRenderSubstituteTile(item)}
            substituteIndex={substituteIndex}
            substitutions={substitutions}
            tileType={tileType}
            substituteTextHeading={substituteTextHeading}
            shouldRenderSubsAsItem={
              shouldRenderSubsInGrid && shouldShowGridView && isGrid
            }
            {...(analyticsSettings && { analyticsSettings })}
          />
          <RecsCarousel
            showRecsCarousel={showRecsCarousel}
            recsCarouselIndex={recsCarouselIndex}
            itemIndex={index}
            isIBYCCarouselEnabled={isIBYCCarouselEnabled}
            pageId={pageType}
          />
        </Fragment>
      );

      const pageItems = [...interruptBanners, listItems];

      if (layoutType !== LIST) {
        if (isFavGridViewBannerEnabled) {
          return { interruptBanners: [], items: pageItems };
        }
        return { interruptBanners, items: [listItems] };
      }
      return pageItems;
    });
  }

  renderSectionMessage(groupName) {
    const { previousGroupName, sectionMessageHeadingTag } = this.props;

    if (groupName !== previousGroupName) {
      return (
        <div className="product-list__section-message">
          <SectionMessage
            message={groupName}
            headingTag={sectionMessageHeadingTag}
          />
        </div>
      );
    }

    return null;
  }

  _expanderSuffix() {
    const { isGroceriesBasket, t } = this.props;

    return isGroceriesBasket
      ? t('trolley:expander.groceries')
      : t('trolley:expander.marketplace');
  }

  createList(items, offset = 0, groupName = '') {
    const classes = classnames('product-list', { grid: this.props.isGrid });
    const {
      isGrid,
      superDepartments,
      t,
      shouldShowGridView,
      useExpander
    } = this.props;

    const layoutType = shouldShowGridView && isGrid ? GRID : LIST;
    const dataProps = {
      classes,
      dataAuto: 'product-list',
      dataAutoLastUpdated: Date.now(),
      layoutType: layoutType
    };
    const listItems = superDepartment => {
      if (superDepartment) {
        // Filters the items by superDepartment
        let itemsFromSuperDepartment = [];
        items.forEach(item => {
          const superDepartmentFromItem = item.product.superDepartmentName;
          if (
            superDepartmentFromItem === superDepartment ||
            (!superDepartmentFromItem &&
              superDepartment === t('trolley:discontinued'))
          )
            itemsFromSuperDepartment.push(item);
        });
        return this.makeProductTiles(
          sortByDepartment(itemsFromSuperDepartment),
          offset
        );
      }
      return this.makeProductTiles(items, offset);
    };

    if (layoutType !== LIST) {
      return (
        <Container
          {...dataProps}
          layoutType={layoutType}
          listItems={listItems()}
          groupName={groupName}
        />
      );
    }
    if (superDepartments) {
      superDepartments.push(
        superDepartments.splice(
          superDepartments.indexOf(t('trolley:discontinued')),
          1
        )[0]
      );

      return (
        <div className="product-list-split-basket">
          {superDepartments.map(superDepartment => {
            return (
              <div key={superDepartment}>
                <h2 className="product-list-super-department">
                  <span className="title__split-basket" key="deparment">
                    {superDepartment}
                  </span>
                </h2>
                <ul
                  className={classes}
                  data-auto={dataProps.dataAuto}
                  data-auto-last-updated={dataProps.dataAutoLastUpdated}
                >
                  {listItems(superDepartment)}
                </ul>
              </div>
            );
          })}
        </div>
      );
    }

    if (useExpander && listItems().length > 3) {
      return (
        <Expander
          className={'expander-list'}
          contractDisplayLabel={`${t(
            'trolley:expander.seeFewer'
          )} ${this._expanderSuffix()}`}
          expandDisplayLabel={`${t(
            'trolley:expander.seeAll'
          )} ${this._expanderSuffix()}`}
          itemsToShow={3}
        >
          {listItems().flat()}
        </Expander>
      );
    }

    return (
      <ul
        className={classes}
        data-auto={dataProps.dataAuto}
        data-auto-last-updated={dataProps.dataAutoLastUpdated}
      >
        {listItems()}
      </ul>
    );
  }

  getProductList = () => {
    if (!this.props.isGrouped) {
      return this.createList(this.props.items);
    }

    let startIndex = 0;
    const groupedItems = groupAndSort(this.props.items);

    const lists = [];
    groupedItems.forEach((items, groupName) => {
      let subsSize = 0;
      [...items.values()]
        .filter(item => typeof item !== 'undefined')
        .map(originalItem => {
          const substitutions = this.getSubstitutions(originalItem);
          subsSize += substitutions.length;
        });

      lists.push(
        <div className="product-list-group" key={groupName}>
          {this.renderSectionMessage(groupName)}
          {this.createList(items, startIndex, groupName)}
        </div>
      );

      startIndex += items.size + subsSize;
    });

    return lists;
  };

  render() {
    this._analyticsInterruptImpressions = [];
    const className =
      typeof this.props.pageNo === 'number' && this.props.pageNo >= 0
        ? `list-page-${this.props.pageNo}`
        : null;

    return <div className={className}>{this.getProductList()}</div>;
  }
}
