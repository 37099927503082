import { parseQueryString } from '#/lib/url/url-utils';
import { CATCH_WEIGHT_CHOICE } from '#/constants/query-strings';

export default function getCatchWeightInQueryString(id: string, currentUrl: string): number | undefined {
  const catchWeightChoiceQuery = parseQueryString(currentUrl)[CATCH_WEIGHT_CHOICE] as string;

  if (!catchWeightChoiceQuery || !catchWeightChoiceQuery.length) {
    return;
  }

  const catchWeightChoiceQueryValues = catchWeightChoiceQuery.split(/:/);

  if (catchWeightChoiceQueryValues.length !== 2 || catchWeightChoiceQueryValues[0] !== id) {
    return;
  }

  return parseFloat(catchWeightChoiceQueryValues[1]);
}
