import React from 'react';
import { GREY_DARK_2 } from '@ddsweb/constants';
import { StyledDDSIcon, VisuallyHidden } from './styled';
import { TTranslateFunc } from '#/lib/records/helpers.defs';
import { SM } from '@ddsweb/constants';

type Props = {
  translate: TTranslateFunc;
};

const FavoriteHeartIcon = ({ translate }: Props): JSX.Element => (
  <>
    <VisuallyHidden>{translate('common:favorite-heart-icon.filled')}</VisuallyHidden>
    <StyledDDSIcon
      graphic="favourites"
      fill={GREY_DARK_2}
      stroke={GREY_DARK_2}
      title={translate('common:favorite-heart-icon.filled')}
      size={SM}
    />
  </>
);

export default FavoriteHeartIcon;
