import React, { SyntheticEvent } from 'react';
import Link from '@beans/link';
import Text from '@beans/text';
import { DISMISS } from '#/experiments/oop-1824/constants';
import { StyledInLineMessaging } from '#/experiments/oop-1824/components/styled';

type OwnProps = {
  bodyText: string;
  label?: string;
  onClick: (e: SyntheticEvent) => void;
};

const MultiSearchNotificationBanner: React.FC<OwnProps> = ({ bodyText, label, onClick }: OwnProps) => (
  <StyledInLineMessaging variant="info">
    <Text>
      {bodyText}
      <Link emphasized onClick={onClick}>
        {label}
      </Link>
    </Text>
  </StyledInLineMessaging>
);

MultiSearchNotificationBanner.defaultProps = {
  label: DISMISS,
};

export default MultiSearchNotificationBanner;
