import { QUANTITY, QUANTITY_OR_WEIGHT } from '#/constants/display-types';
import { EPW_WITHOUT_AMEND_AVAILABILITY_OVERRIDE } from '#/constants/restrictions';
import { Items } from '#/custom-typings/redux-store/trolley.defs';
import { Item } from '#/lib/records/item';
import {
  isInBasketAndExcluded,
  getProductStatus,
  getIsNewlyAdded,
  getPieces,
  getProductDisplayType,
} from '#/selectors/item';

export default function getProductTotalWithUnavailableItems(items: Items | Array<Item>, isAmendBasket = false): number {
  let total = 0;

  items.forEach((item: Item) => {
    const isAddToTotal = !(
      (isAmendBasket && getIsNewlyAdded(item) && isInBasketAndExcluded(item)) ||
      (isAmendBasket && getProductStatus(item) === EPW_WITHOUT_AMEND_AVAILABILITY_OVERRIDE)
    );

    if (isAddToTotal) {
      if ([QUANTITY, QUANTITY_OR_WEIGHT].includes(getProductDisplayType(item))) {
        total += getPieces(item);
      } else {
        total += 1;
      }
    }
  });

  return total;
}
