import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { bindActionCreators } from 'redux';
import { getAmountWithCurrencyOrMinorCurrency } from '#/lib/string-formatting/currency-formatter';
import helpers from '#/lib/decorators/helpers';
import { connect } from '#/lib/render/connect-deep-compare';
import RenderPrice from '../../products/product-tile/tile-components/shared/render-price';
import RenderPricePerUnit from '../../products/product-tile/tile-components/shared/render-price-per-unit';
import RenderDepositNotice from '../../products/product-tile/tile-components/shared/render-deposit-notice';
import CatchWeightControl from '../catch-weight-control';
import ToggleControl from '../toggle-control';
import TrolleyForm from '../trolley-form';
import InputControl from '../../products/product-tile/tile-components/input-control';
import {
  getBasketId,
  getTrolleyDeliveryAddress,
  getIsAmendBasket,
  getTrolleyItemByBaseProductId,
  isItemRemoved
} from '#/selectors/trolley';
import { shouldOverridePreviousItem } from '#/reducers/results';
import {
  getQueryFromQueryStringFactory,
  getCurrency,
  getCurrentUrl
} from '#/reducers/app';
import { getUserStoreId } from '#/reducers/user';
import {
  incrementItemBy,
  removeItem,
  setItemQuantityTo
} from '#/actions/trolley/trolley-action-creators';
import { openModal } from '#/actions/ui-action-creators';
import { QUANTITY_OR_WEIGHT } from '#/constants/display-types';
import { UNIT_CHOICE } from '#/constants/query-strings';
import { WEIGHT_UNIT_KG, QUANTITY_TYPE } from '#/constants/common';
import { AnalyticsSetting } from '../prop-types';
import {
  getProductAverageWeight,
  getProductBulkBuyLimit,
  getProductDepositAmount,
  getPrice,
  atMaxQty,
  hasRestrictedOrderAmendment,
  getDefaultUnit,
  isCatchWeightProduct,
  getCatchWeightPrice,
  isExcluded,
  qtyIncrementable,
  qtyDecrementable,
  getProductPrice,
  isInBasket,
  atMinQty,
  getSeedProductId,
  hasToggle,
  getProductTitle,
  getQuantity,
  getUnit,
  getProductId,
  getCustomerUnitChoice,
  getIsNewlyAdded,
  getOriginalCatchWeight,
  getOriginalCustomerUnitChoice,
  getOriginalQuantity,
  getOriginalProductWeight,
  hasProductDisplayType,
  hasCustomerUnitChoiceOfPieces,
  hasOriginalCustomerUnitChoiceOfPieces,
  getProductDrsChargeAmount
} from '#/selectors/item';
import { withCatchWeight } from '#/lib/records/item-utils';
import { getShouldHideWeightToggle } from '#/experiments/oop-1294/selectors';
import { VisuallyHidden } from '#/components/shared/styled';

const getQueryFromQueryStringUnitChoice = getQueryFromQueryStringFactory(
  UNIT_CHOICE
);

const mapStateToProps = (state, ownProps) => {
  const productItem = ownProps.item;
  const seedProductId = getSeedProductId(productItem);

  return {
    basketId: getBasketId(state),
    currency: getCurrency(state),
    currentUrl: getCurrentUrl(state),
    deliveryAddress: getTrolleyDeliveryAddress(state),
    isAmendBasket: getIsAmendBasket(state),
    isItemRemoved: isItemRemoved(state, getProductId(productItem)),
    shouldOverridePreviousItem: shouldOverridePreviousItem(state, productItem),
    shouldUseUnitChoiceQuery:
      getQueryFromQueryStringUnitChoice(state) &&
      hasProductDisplayType(productItem, QUANTITY_OR_WEIGHT),
    substitutionFor:
      seedProductId && getTrolleyItemByBaseProductId(state, seedProductId),
    userStoreId: getUserStoreId(state),
    shouldHideWeightToggle: getShouldHideWeightToggle(state),
    currencyIso: getCurrency(state).isoCode
  };
};

@connect(mapStateToProps, dispatch => ({
  ...bindActionCreators(
    {
      incrementItemBy,
      openModal,
      removeItem,
      setItemQuantityTo
    },
    dispatch
  ),
  dispatch
}))
@helpers(['t', 'c'])
export default class ProductQuantityControls extends Component {
  constructor(props) {
    super(props);
    const { oldQuantity, oldUnitChoice } = this.props;

    this.state = {
      oldItemQuantity: oldQuantity,
      oldItemUnitChoice: oldUnitChoice,
      isUnitUpdated: false,
      isCatchWeightUpdated: false,
      overrideCustomerUnitChoice: true
    };
  }

  static propTypes = {
    analyticsSettings: AnalyticsSetting,
    anchorId: PropTypes.string,
    backToUrl: PropTypes.string,
    catchWeight: PropTypes.number,
    changeUnits: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    fullControlsOnly: PropTypes.bool,
    hideQuantityToggle: PropTypes.bool,
    identifier: PropTypes.string,
    isAvailableEpwOverride: PropTypes.bool,
    isItemRemoved: PropTypes.bool,
    item: PropTypes.object.isRequired,
    itemIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    oldQuantity: PropTypes.number.isRequired,
    oldUnitChoice: PropTypes.string.isRequired,
    resultType: PropTypes.string.isRequired,
    selectedUnit: PropTypes.string,
    sellableUnitPrice: PropTypes.number,
    shouldHideWeightToggle: PropTypes.bool.isRequired,
    shouldOverridePreviousItem: PropTypes.bool,
    shouldUseUnitChoiceQuery: PropTypes.bool,
    showUnitPrice: PropTypes.bool,
    t: PropTypes.func.isRequired,
    c: PropTypes.func.isRequired,
    updateCatchWeight: PropTypes.func,
    updateReturnUrlWithProductId: PropTypes.bool,
    userRegistered: PropTypes.bool.isRequired,
    whiteIcons: PropTypes.bool
  };

  static defaultProps = {
    fullControlsOnly: false,
    showUnitPrice: true,
    isAvailableEpwOverride: false,
    whiteIcons: false,
    backToUrl: '#',
    selectedUnit: 'pcs',
    updateReturnUrlWithProductId: false,
    shouldUseUnitChoiceQuery: false,
    analyticsSettings: {
      enableMissedOfferTracking: false
    }
  };

  static getDerivedStateFromProps(nextProps) {
    const { item } = nextProps;

    return {
      oldItemQuantity: getQuantity(item),
      oldItemUnitChoice: getUnit(item),
      isUnitUpdated: false,
      overrideCustomerUnitChoice: false
    };
  }

  changeUnits = selectedUnit => {
    const props = this.props;

    if (props.shouldOverridePreviousItem) {
      this.setState({
        isUnitUpdated: true,
        overrideCustomerUnitChoice: false
      });
    }

    props.changeUnits(selectedUnit);
  };

  updateCatchWeight = catchWeight => {
    const props = this.props;

    if (props.shouldOverridePreviousItem) {
      this.setState({
        isCatchWeightUpdated: true,
        overrideCustomerUnitChoice: false
      });
    }

    props.updateCatchWeight(catchWeight);
  };

  render() {
    const props = this.props;
    const state = this.state;
    const {
      backToUrl,
      basketId,
      currency,
      currentUrl,
      deliveryAddress,
      identifier,
      incrementItemBy,
      isAmendBasket,
      isAvailableEpwOverride,
      isItemRemoved,
      itemIndex,
      openModal,
      removeItem,
      setItemQuantityTo,
      showUnitPrice,
      shouldOverridePreviousItem,
      shouldHideWeightToggle,
      substitutionFor,
      userRegistered,
      userStoreId,
      resultType,
      currencyIso,
      t: translate,
      c: config
    } = props;
    let item = props.item;
    let catchWeight;
    const productDrsChargeAmount = getProductDrsChargeAmount(props.item);
    if (shouldOverridePreviousItem) {
      catchWeight =
        state.isCatchWeightUpdated && !isItemRemoved
          ? props.catchWeight
          : getOriginalCatchWeight(item);
    } else {
      catchWeight = props.catchWeight;
    }

    item = catchWeight ? withCatchWeight(item, catchWeight) : item;
    const shouldOverrideCustomerUnitChoice =
      shouldOverridePreviousItem &&
      state.overrideCustomerUnitChoice &&
      !props.shouldUseUnitChoiceQuery;

    if (shouldOverrideCustomerUnitChoice) {
      // [Sean Matheson]
      // This is a code smell. We are mutating the item instance. :(
      // There is a utility function for converting an item between unit
      // choice types (setCustomerUnitChoice), however, the utility
      // function also updates the associated item quantity to be reflective
      // of a change in unit choice. I am unaware of the potential impact and
      // regresessions that this might cause. Therefore we have opened a
      // ticket to address this issue in isolation:
      // https://jira.global.tesco.org/browse/LEGO-21360
      item.customerUnitChoice = getOriginalCustomerUnitChoice(item);
    }

    const hasToggleControl = hasToggle(item) && !props.hideQuantityToggle;

    const quantityChecked =
      shouldOverridePreviousItem && !state.isUnitUpdated && isItemRemoved
        ? hasOriginalCustomerUnitChoiceOfPieces(item)
        : hasCustomerUnitChoiceOfPieces(item);

    let selectedUnit;

    if (hasToggleControl) {
      selectedUnit = quantityChecked ? QUANTITY_TYPE : WEIGHT_UNIT_KG;
    } else {
      selectedUnit = props.selectedUnit;
    }

    const isCatchWeight = isCatchWeightProduct(item);
    const isCatchWeightTransitioning =
      isCatchWeight && config('productDetails:enableCatchWeightTransitioning');

    const getSellableUnitPrice = () => {
      const productPrice = getProductPrice(item);

      if (isCatchWeightTransitioning) {
        return productPrice;
      }

      if (isCatchWeight) {
        return getCatchWeightPrice(item, catchWeight);
      }

      return productPrice;
    };

    const sellableUnitPrice = getSellableUnitPrice();
    const itemId = getProductId(item);
    const tileId = `#tile-${getProductId(item)}`;
    const averageWeight = getProductAverageWeight(item);
    const customerUnitChoice = getCustomerUnitChoice(item);
    const depositProduct = !!getProductDepositAmount(item);
    const itemAtMaxQty = atMaxQty(item);
    const itemAtMinQty = atMinQty(item);
    const itemBulkBuyLimit = getProductBulkBuyLimit(item);
    const itemDefaultUnit = getDefaultUnit(item);
    const itemInBasket = isInBasket(item);
    const itemIsExcluded = isExcluded(item);
    const itemTitle = getProductTitle(item);
    const itemPrice = getPrice(item);
    const originalCatchWeight = getOriginalCatchWeight(item);
    const originalCustomerUnitChoice = getOriginalCustomerUnitChoice(item);
    const originalProductWeight = getOriginalProductWeight(item);
    const originalQuantity = getOriginalQuantity(item);
    const isQtyDecrementable = qtyDecrementable(item);
    const isQtyIncrementable = qtyIncrementable(item);
    const quantity = getQuantity(item);
    const itemWithCatchWeight = val => withCatchWeight(item, val);

    const classes = classnames('price-details--wrapper', {
      'controls__deposit--type': depositProduct
    });

    const isProductExcludedInAmend =
      !getIsNewlyAdded(item) && isAmendBasket && itemIsExcluded;

    return (
      <TrolleyForm
        anchorId={tileId}
        backToUrl={backToUrl}
        identifier={identifier}
        item={item}
        userRegistered={userRegistered}
        oldItemQuantity={state.oldItemQuantity}
        oldItemUnitChoice={state.oldItemUnitChoice}
        updateReturnUrlWithProductId={props.updateReturnUrlWithProductId}
      >
        {!hasToggleControl && (
          <input name="newUnitChoice" type="hidden" value={selectedUnit} />
        )}
        <div data-auto="product-controls" className="controls">
          {isCatchWeight && !isCatchWeightTransitioning && (
            <div className="controls--catch-weight-container">
              <CatchWeightControl
                shouldOverridePreviousItem={shouldOverridePreviousItem}
                item={item}
                isItemRemoved={isItemRemoved}
                disableWeightChange={
                  isAvailableEpwOverride || hasRestrictedOrderAmendment(item)
                }
                updateCatchWeight={this.updateCatchWeight}
              />
            </div>
          )}
          {hasToggleControl && !shouldHideWeightToggle && (
            <ToggleControl
              anchorId={props.anchorId}
              quantityChecked={quantityChecked}
              changeUnits={this.changeUnits}
              productTitle={itemTitle}
            />
          )}
          <div className={classes}>
            <RenderPrice
              isAvailableEpwOverride={isAvailableEpwOverride}
              item={item}
              sellableUnitPrice={sellableUnitPrice}
              selectedUnit={selectedUnit}
            />
            {showUnitPrice && (
              <RenderPricePerUnit
                isAvailableEpwOverride={isAvailableEpwOverride}
                compactMode={true}
                item={item}
              />
            )}
            <RenderDepositNotice item={item} />
            {!!productDrsChargeAmount && (
              <>
                <VisuallyHidden data-visually-hidden>{`+ ${
                  currency?.symbol
                } ${productDrsChargeAmount} ${translate(
                  'deposit'
                )}`}</VisuallyHidden>
                <div
                  aria-hidden={true}
                  className="drs deposit-price"
                >{`+ ${getAmountWithCurrencyOrMinorCurrency(
                  productDrsChargeAmount,
                  currency?.symbol,
                  currencyIso
                )} ${translate('deposit')}`}</div>
              </>
            )}
          </div>
          <InputControl
            analyticsSettings={props.analyticsSettings}
            averageWeight={averageWeight}
            basketId={basketId}
            catchWeight={catchWeight}
            currency={currency}
            currentUrl={currentUrl}
            customerUnitChoice={customerUnitChoice}
            deliveryAddress={deliveryAddress}
            disableAdd={isProductExcludedInAmend || !isQtyIncrementable}
            disabled={props.disabled}
            disableRemove={!isQtyDecrementable}
            identifier={props.identifier}
            incrementItemBy={incrementItemBy}
            isAmendBasket={isAmendBasket}
            isAvailableEpwOverride={isAvailableEpwOverride}
            isCatchWeightProduct={isCatchWeight}
            isCatchWeightUpdated={state.isCatchWeightUpdated}
            isItemRemoved={isItemRemoved}
            itemAtMaxQty={itemAtMaxQty}
            itemAtMinQty={itemAtMinQty}
            itemBulkBuyLimit={itemBulkBuyLimit}
            itemDefaultUnit={itemDefaultUnit}
            itemId={itemId}
            itemInBasket={itemInBasket}
            itemIndex={itemIndex}
            itemPrice={itemPrice}
            itemIsExcluded={itemIsExcluded}
            itemTitle={itemTitle}
            openModal={openModal}
            originalCatchWeight={originalCatchWeight}
            originalCustomerUnitChoice={originalCustomerUnitChoice}
            originalProductWeight={originalProductWeight}
            originalQuantity={originalQuantity}
            quantity={quantity}
            removeItem={removeItem}
            selectedUnit={selectedUnit}
            setItemQuantityTo={setItemQuantityTo}
            shouldOverridePreviousItem={shouldOverridePreviousItem}
            substitutionFor={substitutionFor}
            userRegistered={userRegistered}
            userStoreId={userStoreId}
            withCatchWeight={itemWithCatchWeight}
            resultType={resultType}
          />
        </div>
      </TrolleyForm>
    );
  }
}
