import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { YELLOW, GREY_DARK_1 } from '@ddsweb/constants';
import Tag from '@ddsweb/tag';
import Price from '#/components/shared/price';
import { connect } from '#/lib/render/connect-deep-compare';
import {
  bookSlot,
  showOrderTypeChangeWarning,
  checkSlotWarnings,
  showChangingAddressWarning
} from '#/actions/slot-action-creators';
import SlotForm from './slot-form';
import helpers from '#/lib/decorators/helpers';
import {
  ORDER_TYPE_CHANGE,
  SLOT_CHANGE,
  CHANGE_COLLECTION_LOCATION
} from '#/constants/slot-warning-modal-types';
import { COLLECTION } from '#/constants/shopping-methods';
import { GRID, LIST, SlotViewModes } from '#/constants/slot-views';
import { slotCurrentlyBooked } from '#/lib/slot/slot-utils';
import { getCurrentUrl, getTimezone } from '#/reducers/app';
import {
  getSelectedShoppingMethod,
  getSlotsPendingStatus
} from '#/reducers/slot';
import {
  getChangeSlotInfo,
  isUnavailableHDSlotWithCollectLink
} from '#/selectors/slot';
import { updateParamsInUrl } from '#/lib/url/url-utils';
import {
  getItemsCount,
  getSlot,
  getTrolleyShoppingMethod
} from '#/selectors/trolley';
import { getHasAcceptedSlotWarningTypes } from '#/reducers/ui';

import { triggerBestValueEvent } from '#/analytics/best-value-slots';
import { HIGHLIGHT_SLOT } from '#/analytics/constants';
import SlotRecommenderTileAvailableSlotButton from '../slot-recommender/slot-recommender-tile-available-slot-button';
import { SLOT_BEFORE_DISCOUNT_PRICE } from '#/constants/slot-views';
import { sessionStore } from '#/lib/data-store/client-store';

const mapStateToProps = (state, { c: config }) => ({
  changeSlotInfo: getChangeSlotInfo(state),
  currentOrderType: getTrolleyShoppingMethod(state),
  currentSlot: getSlot(state),
  currentUrl: getCurrentUrl(state),
  itemCount: getItemsCount(state),
  hasAcceptedSlotWarningCollectionChange: getHasAcceptedSlotWarningTypes(
    state,
    CHANGE_COLLECTION_LOCATION
  ),
  hasAcceptedSlotWarningOrderChange: getHasAcceptedSlotWarningTypes(
    state,
    ORDER_TYPE_CHANGE
  ),
  hasAcceptedSlotWarningSlotChange: getHasAcceptedSlotWarningTypes(
    state,
    SLOT_CHANGE
  ),
  orderTypeChangeCookieAccepted: state.ui.orderTypeChangeCookieAccepted,
  changeSlotCookieAccepted: state.ui.changeSlotCookieAccepted,
  changeLocationCookieAccepted: state.ui.changeLocationCookieAccepted,
  shoppingMethod: getSelectedShoppingMethod(state),
  timezone: getTimezone(state),
  isSlotLoading: getSlotsPendingStatus(state),
  applyCollectLinkStyleVariant: isUnavailableHDSlotWithCollectLink(
    state,
    config
  )
});

@helpers(['c', 't'])
@connect(mapStateToProps, {
  bookSlot,
  checkSlotWarnings,
  showChangingAddressWarning,
  showOrderTypeChangeWarning
})
export default class AvailableSlot extends React.Component {
  static propTypes = {
    accessibleLabel: PropTypes.string.isRequired,
    applyCollectLinkStyleVariant: PropTypes.bool.isRequired,
    bookSlot: PropTypes.func.isRequired,
    c: PropTypes.func.isRequired,
    changeLocationCookieAccepted: PropTypes.bool.isRequired,
    changeSlotCookieAccepted: PropTypes.bool.isRequired,
    changeSlotInfo: PropTypes.object,
    checkSlotWarnings: PropTypes.func.isRequired,
    currentOrderType: PropTypes.string.isRequired,
    currentSlot: PropTypes.object,
    currentUrl: PropTypes.string,
    hasAcceptedSlotWarningCollectionChange: PropTypes.bool.isRequired,
    hasAcceptedSlotWarningOrderChange: PropTypes.bool.isRequired,
    hasAcceptedSlotWarningSlotChange: PropTypes.bool.isRequired,
    hidePriceInRecommenderButton: PropTypes.bool,
    isDeliverySaverApplicableToSlot: PropTypes.bool,
    isSlotLoading: PropTypes.bool,
    itemCount: PropTypes.number.isRequired,
    isLargeScreen: PropTypes.bool,
    locationId: PropTypes.string,
    onKeyPressed: PropTypes.func.isRequired,
    orderTypeChangeCookieAccepted: PropTypes.bool.isRequired,
    saveButtonRef: PropTypes.func.isRequired,
    shoppingMethod: PropTypes.oneOf(['delivery', 'collection']).isRequired,
    shouldApplySamedaySurcharge: PropTypes.bool.isRequired,
    showOrderTypeChangeWarning: PropTypes.func.isRequired,
    slotBeforeDiscountPrice: PropTypes.number,
    slotCharge: PropTypes.number.isRequired,
    slotEnd: PropTypes.string.isRequired,
    slotId: PropTypes.string.isRequired,
    slotIsSameDay: PropTypes.bool.isRequired,
    slotPageAnchorTag: PropTypes.string.isRequired,
    slotStart: PropTypes.string.isRequired,
    slotViewMode: PropTypes.oneOf([
      GRID,
      LIST,
      SlotViewModes.SLOT_RECOMMENDER,
      ,
      SlotViewModes.SLOT_RECOMMENDER_TILE
    ]).isRequired,
    t: PropTypes.func.isRequired,
    timezone: PropTypes.string.isRequired
  };

  static defaultProps = {
    shouldApplySamedaySurcharge: false
  };

  constructor(props) {
    super(props);
    props.checkSlotWarnings(props.currentSlot);
  }

  getDeliverySaverAppliedSlotCharge({
    c,
    isDeliverySaverApplicableToSlot,
    slotCharge,
    shouldApplySamedaySurcharge
  }) {
    const sameDaySlotSurcharge = c('sameDaySlotSurcharge');
    if (shouldApplySamedaySurcharge && sameDaySlotSurcharge) {
      return sameDaySlotSurcharge;
    }
    if (isDeliverySaverApplicableToSlot) {
      return 0;
    }
    return slotCharge;
  }

  getWarningType = () => {
    const {
      itemCount,
      currentSlot,
      currentOrderType,
      shoppingMethod,
      locationId,
      changeLocationCookieAccepted,
      hasAcceptedSlotWarningCollectionChange,
      hasAcceptedSlotWarningOrderChange,
      orderTypeChangeCookieAccepted,
      changeSlotCookieAccepted,
      hasAcceptedSlotWarningSlotChange
    } = this.props;

    if (!itemCount) {
      return null;
    }

    if (!(currentSlot && slotCurrentlyBooked(currentSlot))) {
      return null;
    }

    const currentLocationId = currentSlot.locationId;

    if (currentOrderType === COLLECTION && shoppingMethod === COLLECTION) {
      if (
        locationId !== currentLocationId &&
        !(
          changeLocationCookieAccepted || hasAcceptedSlotWarningCollectionChange
        )
      ) {
        return CHANGE_COLLECTION_LOCATION;
      }
    }

    if (currentOrderType !== shoppingMethod) {
      if (
        !(orderTypeChangeCookieAccepted || hasAcceptedSlotWarningOrderChange)
      ) {
        return ORDER_TYPE_CHANGE;
      }
    } else if (
      currentOrderType === shoppingMethod &&
      locationId === currentLocationId &&
      !(changeSlotCookieAccepted || hasAcceptedSlotWarningSlotChange)
    ) {
      return SLOT_CHANGE;
    }

    return null;
  };

  onSubmit = event => {
    event.preventDefault();

    const slot = {
      shoppingMethod: this.props.shoppingMethod,
      slotId: this.props.slotId,
      start: this.props.slotStart,
      end: this.props.slotEnd,
      locationId: this.props.locationId,
      charge: this.props.slotCharge,
      currency: this.props.c('currency'),
      timezone: this.props.timezone
    };
    let analyticsPayload;
    const type = this.getWarningType();
    const { isBestValue } = this.props;

    if (isBestValue) {
      triggerBestValueEvent(HIGHLIGHT_SLOT, event.target);
    }

    if (this.props.hasDeliverySaverPlan) {
      analyticsPayload = {
        deliverySaverCurrent: {
          saving:
            this.props.slotCharge -
            this.getDeliverySaverAppliedSlotCharge(this.props)
        }
      };
    } else {
      analyticsPayload = {};
    }

    if (type) {
      slot.modalType = type;
      this.props.showOrderTypeChangeWarning({ ...slot, ...analyticsPayload });
    } else {
      this.props.bookSlot({ ...slot, ...analyticsPayload });
    }

    if (typeof this.props.slotBeforeDiscountPrice === 'number') {
      sessionStore?.set(
        SLOT_BEFORE_DISCOUNT_PRICE,
        this.props.slotBeforeDiscountPrice
      );
    }
  };

  getReturnurl() {
    let returnUrl = updateParamsInUrl(this.props.currentUrl, {
      slotViewMode: this.props.slotViewMode,
      reload: null
    });
    if (this.props.slotPageAnchorTag) {
      returnUrl = `${returnUrl}#${this.props.slotPageAnchorTag}`;
    }
    return returnUrl;
  }

  render() {
    const {
      accessibleLabel,
      c,
      t: translate,
      saveButtonRef,
      slotCharge,
      hidePriceInRecommenderButton,
      slotPageAnchorTag,
      applyCollectLinkStyleVariant,
      onKeyPressed,
      slotViewMode,
      isBestValue,
      isSlotLoading,
      isLargeScreen
    } = this.props;

    const modalType = this.getWarningType();
    const submitUrl = modalType
      ? this.props.currentUrl
      : '/slots/current?_method=PUT';
    const submitMethod = modalType ? 'GET' : 'POST';

    let price = (
      <Price {...c('currency')} value={slotCharge} truncateWholeValues={true} />
    );

    if (slotViewMode === SlotViewModes.SLOT_RECOMMENDER_TILE) {
      return (
        <SlotRecommenderTileAvailableSlotButton
          isSlotLoading={isSlotLoading}
          onKeyDown={onKeyPressed}
          onSubmit={this.onSubmit}
          slotPrice={!hidePriceInRecommenderButton && slotCharge}
        />
      );
    }

    const bestValueTag = (
      <Tag
        textColor={GREY_DARK_1}
        backgroundColor={YELLOW}
        as="span"
        className="best-value-tag"
      >
        {translate('slots:common.slot-recommendations.best-value')}
      </Tag>
    );

    return (
      <div data-testid={isLargeScreen && 'slot-available-button-synthetics'}>
        <SlotForm
          {...this.props}
          modalType={modalType}
          onSubmit={this.onSubmit}
          returnUrl={this.getReturnurl()}
          submitUrl={submitUrl}
          method={submitMethod}
          onKeyPressed={onKeyPressed}
        >
          <button
            ref={saveButtonRef}
            className={classnames(
              'button button-secondary small available-slot--button slot-selector__button',
              {
                'available-slot--button--cc-link-enabled': applyCollectLinkStyleVariant,
                'available-slot--button--best-value': isBestValue
              }
            )}
            type="submit"
          >
            {isBestValue && bestValueTag}
            <span className="visually-hidden">{accessibleLabel}</span>
            {price}
            {
              /* non-js workaround: offset an anchor from sticky header to scroll to the slot after acted upon
             1) offset class applied needed mainly in mobile slot list view,
             2) offset class is useful when a sticky modal in desktop is shown
             3) when a <span> is used here, the offset css position on it doesnt seem to be honored by browsers
             4) need a separate span as it would css offset it's content
             */
              <a
                id={slotPageAnchorTag}
                className="slot-anchor-offset-from-header"
              />
            }
          </button>
        </SlotForm>
      </div>
    );
  }
}
