import { addApmData } from '#/lib/apm';

interface InitatorAttribute {
  initiator_type: string;
  delivery_type?: string;
}

// deliveryType is an experimental attribute so not part of existing type
export interface ExperimentalPerformanceResourceTiming extends PerformanceResourceTiming {
  deliveryType?: string;
}

export const ATTRIBUTE_NAME = 'font_initator';
export const FONT_PATH = `/fonts/TESCOModern-Regular.woff2`;

export default function logFontInitiator(fontDomain: string): void {
  // record what initiated the font load e.g. early-hints, link, css
  if (typeof performance !== 'undefined' && fontDomain) {
    const fontUrl = `${fontDomain}${FONT_PATH}`;
    const entries: PerformanceEntry[] = performance.getEntriesByName?.(fontUrl) || [];

    if (entries.length > 0) {
      const fontPerformanceEntry = entries[0] as ExperimentalPerformanceResourceTiming;

      if (fontPerformanceEntry?.initiatorType) {
        const value: InitatorAttribute = {
          initiator_type: fontPerformanceEntry.initiatorType,
        };

        if (fontPerformanceEntry?.deliveryType) {
          value.delivery_type = fontPerformanceEntry.deliveryType;
        }

        addApmData('font_initator', JSON.stringify(value));
      }
    }
  }
}
