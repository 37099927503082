import React from 'react';
import PropTypes from 'prop-types';

const isDev = process.env.NODE_ENV !== 'production';

const componentNotFound = type => {
  if (isDev) {
    /* eslint-disable no-console */
    console.warn(`Can't find component type: ${type}`);
    /* eslint-enable no-console */
  }

  return null;
};

export const renderTree = (components, tree, shared) =>
  tree &&
  tree.map((component, i) => {
    if (component) {
      const props = { key: i, ...shared, ...component.props };
      const convertName = name =>
        name
          .split('-')
          .map(part => part[0].toUpperCase() + part.slice(1))
          .join('');
      const componentType = convertName(component.type);

      const Component = components[componentType];

      if (Component) {
        const children = component.children
          ? renderTree(components, component.children, shared)
          : [];

        return <Component {...props}>{children}</Component>;
      }

      return componentNotFound(componentType);
    }

    return componentNotFound('Component was undefined');
  });

const ComponentTree = ({ components, data, shared }) => (
  <div className="component-tree ui-components-library">
    {renderTree(components, data, shared)}
  </div>
);

ComponentTree.propTypes = {
  components: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  shared: PropTypes.object
};

export { ComponentTree };
