import React from 'react';
import { connect } from '#/lib/render/connect-deep-compare';
import { getSelectedShoppingMethod } from '#/reducers/slot';
import { getRecentNonMarketplacePendingOrder } from '#/selectors/order-list-details';
import { getIsAmendBasket, getCurrentValidSlot } from '#/selectors/trolley';
import { isOnDemandShoppingMethod } from '#/lib/shopping-method-util';
import DetailedDeliveryAddress from '#/components/slots/detailed-delivery-address';
import DeliveryAddress from '#/components/delivery-address';
import { TrolleyState } from '#/custom-typings/redux-store/trolley.defs';
import { AddressData } from '#/reducers/addresses';
import { Slot } from '#/lib/records/slot.defs';
import { getShowSlotUIReskinV2 } from '#/experiments/oop-2205/selectors';

type TOwnProps = {
  selectedShoppingMethod: TrolleyState['shoppingMethod'];
  detailedDeliveryAddress: AddressData | undefined;
  currentValidSlot: Slot;
  isAmendBasket: boolean;
  recentPendingOrder: object;
};

const mapStateToProps = (state: any) => ({
  selectedShoppingMethod: getSelectedShoppingMethod(state),
  currentValidSlot: getCurrentValidSlot(state),
  isAmendBasket: getIsAmendBasket(state),
  recentPendingOrder: getRecentNonMarketplacePendingOrder(state),
  showSlotUIReskin: getShowSlotUIReskinV2(state),
});

type TProps = TOwnProps & ReturnType<typeof mapStateToProps>;

const DeliveryAddressSection: React.FC<TProps> = (props: TProps) => {
  const { currentValidSlot, isAmendBasket, recentPendingOrder, selectedShoppingMethod, showSlotUIReskin } = props;
  const isOnDemandDeliveryPage = isOnDemandShoppingMethod(selectedShoppingMethod);
  if (isAmendBasket) {
    return (
      <div className="book-a-slot__section-container">
        <DeliveryAddress
          shoppingMethod={selectedShoppingMethod}
          address={recentPendingOrder?.address}
          slot={currentValidSlot}
          showSlotUIReskin={showSlotUIReskin}
        />
      </div>
    );
  }

  return (
    <div
      className={`book-a-slot__section-container ${
        showSlotUIReskin && !isOnDemandDeliveryPage ? 'book-a-slot__section-container--reskin' : ''
      }`}
    >
      <DetailedDeliveryAddress />
    </div>
  );
};

export default connect(mapStateToProps)(DeliveryAddressSection);
