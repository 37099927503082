import styled from 'styled-components';
import Modal from '@ddsweb/modal';
import { fontSize, spacing } from '@ddsweb/selectors';
import { Text } from '@ddsweb/text';
import { media } from '@ddsweb/foundation';
import Link from '@ddsweb/link';
import Button from '@ddsweb/button';

export const StyledModal = styled(Modal)`
  ${Modal.ModalContainer} {
    height: auto;
  }
  ${Modal.CloseLink} {
    margin: 0;
  }
`;

export const ModalButtonContainer = styled.div`
  margin-top: ${spacing.md};
  ${media.aboveTabletLarge`
    justify-content: left;
    display: flex;
  `}
`;

export const RemindModalButtons = styled(ModalButtonContainer)`
  ${media.aboveMobileLarge`
    min-width: 480px;
  `}
`;
export const StyledLink = styled(Link)`
  font-size: ${fontSize.xs};
`;

export const RemindModalStyledLink = styled(StyledLink)`
  width: 100%;
  ${media.aboveMobileLarge`
    width: fit-content;
  `}
`;

export const StyledButton = styled(Button)`
  ${media.aboveTabletLarge`
    margin-left: ${spacing.sm};
  `}
  ${media.belowTabletLarge`
    margin-top: ${spacing.sm};
  `}
`;

export const RemindModalButton = styled(Button)`
  width: 100%;
  ${media.aboveMobileLarge`
    width: fit-content;
  `}
`;

export const StyledText = styled(Text)`
  margin-top: ${spacing.xs};
`;
