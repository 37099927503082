import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from '#/lib/render/connect-deep-compare';
import classnames from 'classnames';
import Menu from '../menu';
import {
  selectTaxonomyItem,
  resetTaxonomy
} from '#/actions/taxonomy-action-creators';
import { openNavMenu, closeNavMenu } from '#/actions/ui-action-creators';
import {
  SUPERDEPARTMENT,
  DEPARTMENT,
  AISLE
} from '#/constants/taxonomy-levels';
import {
  NAV_ITEM_HEIGHT,
  NAV_LIST_EXPANDED_VERTICAL_PADDING
} from '#/constants/ui';
import { basicEvent } from '#/analytics/types/basic';
import {
  buildNavigationMenuString,
  navigationMenuAnalyticsEvent
} from '#/analytics/types/navigation-menu';
import helpers from '#/lib/decorators/helpers';
import { getNavList, getServerSideExperiment } from '#/reducers/taxonomy';
import Spinner from '#/components/shared/spinner';
import { getStickyBarName } from '#/reducers/ui';
import { getIsUserAuthenticated } from '#/reducers/user';
import {
  isBrowsable,
  isExternalLink,
  storeSelectedTaxonomy,
  SELECTED_TAXONOMY
} from '#/lib/taxonomy/taxonomy';
import { shouldPersistTaxonomyPosition } from '#/experiments/oop-1406/selectors';
import { getSelectedTaxonomyDetails } from '#/experiments/oop-1406/helpers';
import { sessionStore } from '#/lib/data-store/client-store';
import { setDefaultTaxonomy } from '#/experiments/oop-1406/actions';
import { getInspireMeNavList } from '#/experiments/oop-1890/helpers';
import { NAVIGATION } from '#/constants/page-names';
import { DELAY } from '#/analytics/constants';
import { getMealsAndReceipeMenu } from '#/experiments/oop-1946/helpers';
import { analyticsForMealsAndRecipeMenu } from '#/experiments/oop-1946/analytics';
import { getShouldShowContentStamp } from '#/experiments/oop-1894/selectors';
import { getBrowseEntrypoints } from '#/experiments/oop-1894/actions/update-browseEntrypoints';
import { getBrowseEntrypoints as getBrowseEntrypointsFromState } from '#/experiments/oop-1894/reducers/browseEntrypoints';
import ContentStamp from '#/experiments/oop-1894/components/content-stamp';
import { exposeActiveTestDataBatch } from '#/lib/optimizely-manager';

const mapStateToProps = state => {
  return {
    browseEntrypoints: getBrowseEntrypointsFromState(state),
    experiment: getServerSideExperiment(state),
    navList: getNavList(state),
    inspireMeNavList: getInspireMeNavList(state),
    isStickyBarShown: !!getStickyBarName(state),
    isUserAuthenticated: getIsUserAuthenticated(state),
    isPersistTaxonomyEnabled: shouldPersistTaxonomyPosition(state),
    mealsAndRecipesList: getMealsAndReceipeMenu(state),
    showContentStamps: getShouldShowContentStamp(state)
  };
};
@connect(mapStateToProps, {
  getBrowseEntrypoints,
  selectTaxonomyItem,
  resetTaxonomy,
  closeNavMenu,
  openNavMenu,
  setDefaultTaxonomy
})
@helpers(['l', 't'])
export default class MenuTree extends Component {
  static propTypes = {
    browseEntrypoints: PropTypes.array,
    closeNavMenu: PropTypes.func.isRequired,
    experiment: PropTypes.object,
    focusParent: PropTypes.func,
    getBrowseEntrypoints: PropTypes.func,
    isAmendMode: PropTypes.bool,
    isGroceriesMenu: PropTypes.bool,
    isPersistTaxonomyEnabled: PropTypes.bool,
    isUserAuthenticated: PropTypes.bool.isRequired,
    l: PropTypes.func.isRequired,
    navList: PropTypes.array,
    open: PropTypes.bool,
    openNavMenu: PropTypes.func,
    openStateInLargeView: PropTypes.bool,
    resetTaxonomy: PropTypes.func.isRequired,
    selectTaxonomyItem: PropTypes.func.isRequired,
    setDefaultTaxonomy: PropTypes.func,
    showContentStamps: PropTypes.bool,
    t: PropTypes.func.isRequired,
    taxonomyState: PropTypes.object.isRequired
  };

  static contextTypes = {
    router: PropTypes.object,
    analyticsBus: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.menuType = null;
    this.selected = null;
    this.selectedTaxonomies = {};
    this.shopAllSelected = null;
  }

  componentDidMount() {
    const {
      browseEntrypoints,
      experiment,
      getBrowseEntrypoints,
      open,
      showContentStamps
    } = this.props;

    !!experiment && exposeActiveTestDataBatch(experiment);

    if (open) {
      this.addBlurListeners();
      this.applyDefaultTaxonomy();

      if (showContentStamps && !browseEntrypoints.length) {
        getBrowseEntrypoints();
      }
    }
  }

  componentWillUnmount() {
    this.removeBlurListeners();
  }

  componentDidUpdate(prevProps) {
    const { open, showContentStamps, getBrowseEntrypoints } = this.props;

    if (!prevProps.open && open) {
      this.addBlurListeners();
      this.applyDefaultTaxonomy();

      if (showContentStamps && !prevProps.browseEntrypoints.length) {
        getBrowseEntrypoints();
      }
    } else if (!open) {
      this.removeBlurListeners();
    }
  }

  applyDefaultTaxonomy(navList = this.props.navList) {
    const taxonomies = sessionStore?.get(SELECTED_TAXONOMY);
    const { isPersistTaxonomyEnabled, setDefaultTaxonomy } = this.props;

    if (!isPersistTaxonomyEnabled || !navList.length || !taxonomies) {
      return;
    }

    this.selectedTaxonomies = getSelectedTaxonomyDetails(navList, taxonomies);
    setDefaultTaxonomy(this.selectedTaxonomies);
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.open || this.shopAllSelected) {
      return;
    }

    const allSuperDepartmentWithChildren = nextProps.navList.every(
      superdepartment => {
        return superdepartment.children.length > 0;
      }
    );

    if (
      nextProps !== this.props &&
      allSuperDepartmentWithChildren &&
      !this.selectedTaxonomies.department
    ) {
      this.applyDefaultTaxonomy(nextProps.navList);
    }

    if (
      nextProps !== this.props &&
      this.selected &&
      allSuperDepartmentWithChildren
    ) {
      const selected = nextProps.navList.find(
        data =>
          data.name === this.selected.name && data.label === this.selected.label
      );
      selected && selected.children && this.selectItem(this.menuType, selected);
    }
  }

  resetTaxonomy = () => {
    this.shopAllSelected = true;
    this.selectedTaxonomies = {};
    this.props.resetTaxonomy();
  };

  addBlurListeners() {
    ['focusin', 'click', 'keydown'].forEach(eventType => {
      window.addEventListener(eventType, this.blurListener);
    });
  }

  removeBlurListeners() {
    ['focusin', 'click', 'keydown'].forEach(eventType => {
      window.removeEventListener(eventType, this.blurListener);
    });
  }

  blurListener = event => {
    if (this.isDescendantOfMenuTree(event.target) === false) {
      this.closeMenuTree();
    }
  };

  isDescendantOfMenuTree(domNode) {
    if (!domNode || !this.menuTree) {
      return;
    }

    let parent = domNode.parentNode;

    while (parent) {
      if (parent === this.menuTree.parentNode) {
        return true;
      }

      parent = parent.parentNode;
    }

    return false;
  }

  getBrowseUrl = (selectedCat, type) => {
    const { l: languageLink } = this.props;
    const node =
      selectedCat?.allNode ||
      selectedCat?.allPromotionNode ||
      selectedCat?.newBrowseNode;

    let link =
      selectedCat.externalUrl ||
      `/shop${
        type === DEPARTMENT && !node
          ? `${selectedCat.url}/all`
          : selectedCat.url
      }`;

    return languageLink(link);
  };

  navigationAnalytics = (menuType, selected) => {
    const { taxonomyState, isInspireMeMenu } = this.props;

    let value = buildNavigationMenuString(taxonomyState, menuType, selected);

    if (selected.allNode) value += ':all';
    if (selected.allPromotionNode) value += ':offers';

    !isInspireMeMenu &&
      basicEvent(this.context.analyticsBus, {
        type: NAVIGATION,
        menuType: selected.label,
        value: value,
        action: DELAY
      });
  };

  selectItem = (menuType, selected) => {
    this.menuType = menuType;
    this.selected = selected;

    const {
      taxonomyState,
      closeNavMenu,
      resetTaxonomy,
      openNavMenu,
      selectTaxonomyItem,
      isPersistTaxonomyEnabled,
      isInspireMeMenu,
      isMealsAndRecipeMenu
    } = this.props;

    !isMealsAndRecipeMenu &&
      navigationMenuAnalyticsEvent(
        taxonomyState,
        menuType,
        selected,
        isInspireMeMenu
      );

    if (isBrowsable(menuType, selected.children) || selected.allNode) {
      if (isMealsAndRecipeMenu) {
        analyticsForMealsAndRecipeMenu(taxonomyState, menuType, selected);
      } else {
        this.navigationAnalytics(menuType, selected);
      }

      const url = this.getBrowseUrl(selected, menuType);

      if (this.context.router && !isExternalLink(url)) {
        this.context.router.push(url);
        closeNavMenu();
        resetTaxonomy();
      } else {
        selected?.newTab
          ? window.open(url, '_blank')
          : window.location.assign(url);
      }
    } else {
      openNavMenu();
      selectTaxonomyItem(menuType, selected);
    }
    isPersistTaxonomyEnabled &&
      storeSelectedTaxonomy(menuType, selected.catId || selected.url);
  };

  closeMenu = type => {
    const parent =
      type === AISLE
        ? DEPARTMENT
        : type === DEPARTMENT
        ? SUPERDEPARTMENT
        : null;

    if (parent) {
      this.props.selectTaxonomyItem(parent, null);
    } else {
      this.closeMenuTree();
    }
  };

  closeMenuTree() {
    this.selectedTaxonomies = {};
    this.props.closeNavMenu();
    this.props.resetTaxonomy();
    this.props.focusParent();
    this.selected = null;
  }

  getWrapperStyles(superdepartment, department, aisle, open) {
    const superdeparmentLength = superdepartment && superdepartment.length;
    const departmentLength = department && department.length;
    const aisleLength = aisle ? aisle.length : 0;

    const maxItems = this.props.isInspireMeMenu
      ? Math.max(departmentLength, aisleLength)
      : Math.max(superdeparmentLength, departmentLength, aisleLength);

    const minHeight = open
      ? maxItems * NAV_ITEM_HEIGHT + NAV_LIST_EXPANDED_VERTICAL_PADDING
      : maxItems * NAV_ITEM_HEIGHT;

    return { minHeight: `${minHeight}px` };
  }

  render() {
    const {
      browseEntrypoints,
      open,
      isAmendMode,
      isGroceriesMenu,
      t: translate,
      navList,
      openStateInLargeView,
      taxonomyState,
      isPersistTaxonomyEnabled,
      isInspireMeMenu,
      inspireMeNavList,
      isMealsAndRecipeMenu,
      mealsAndRecipesList,
      showContentStamps
    } = this.props;

    let { superdepartment, department, aisle } = taxonomyState;

    const aisleMenu = department && (
      <Menu
        active={aisle}
        chevron={false}
        isOpen={open}
        items={department.children}
        label={department.name}
        selectItem={this.selectItem}
        scrollToElement={true}
        isAmendMode={isAmendMode}
        type={AISLE}
        t={translate.bind(this)}
        closeMenu={this.closeMenu}
        isFocused={!!aisle}
        isPersistTaxonomyEnabled={isPersistTaxonomyEnabled}
      />
    );

    let superDepartment = superdepartment;

    if (isMealsAndRecipeMenu) {
      superDepartment = mealsAndRecipesList;
    }
    if (isInspireMeMenu) {
      superDepartment = inspireMeNavList;
    }

    const departmentMenu =
      superDepartment &&
      superDepartment.children &&
      superDepartment.children.length > 0 ? (
        <Menu
          active={department}
          isOpen={open}
          chevron={true}
          items={superDepartment.children}
          label={superDepartment.name}
          selectItem={this.selectItem}
          scrollToElement={true}
          isAmendMode={isAmendMode}
          type={DEPARTMENT}
          t={translate.bind(this)}
          childMenu={aisleMenu}
          closeMenu={this.closeMenu}
          isFocused={!!(department && !aisle)}
        />
      ) : (
        <Spinner showSpinner={true} />
      );

    const superdepartmentMenu = (
      <Menu
        active={superdepartment}
        isOpen={open}
        chevron={true}
        items={navList}
        label={translate('sort-and-filter:categories')}
        selectItem={this.selectItem}
        type={SUPERDEPARTMENT}
        t={translate.bind(this)}
        childMenu={departmentMenu}
        closeMenu={this.closeMenu}
        isFocused={!!(superdepartment && !department && !aisle)}
      />
    );
    const classes = classnames('menu-tree', {
      'menu-tree--open': open,
      'menu-tree--department': superdepartment,
      'menu-tree--aisle': department,
      'main-level-menu-tree--open': !open && openStateInLargeView,
      'menu-tree--hide-down-chevrons': true
    });
    const buttonClasses = classnames(
      'menu-tree__all-button',
      'menu__link',
      'menu__link--active',
      {
        'menu__link--current-parent':
          isInspireMeMenu || isMealsAndRecipeMenu
            ? !department
            : !superdepartment
      }
    );
    const styles = this.getWrapperStyles(
      navList,
      superdepartment && superdepartment.children,
      department && department.children,
      open
    );

    let buttonLabel = translate('common:pages.groceries');

    if (isInspireMeMenu) {
      buttonLabel = translate('common:pages.inspireMe');
    }

    if (isMealsAndRecipeMenu) {
      buttonLabel = translate('common:pages.meals-recipes');
    }

    return (
      <div
        ref={el => (this.menuTree = el)}
        className={classes}
        data-auto="menu-tree"
      >
        {this.props.open && (
          <div className={classnames('menu-tree__backdrop')} />
        )}
        <button
          type="button"
          className={buttonClasses}
          onClick={this.resetTaxonomy}
        >
          {buttonLabel}
        </button>
        <div
          className={classnames('menu-tree__inner', {
            'menu-tree__inner--content-stamp': showContentStamps,
            'menu-tree__inner--padding': isMealsAndRecipeMenu
          })}
          style={styles}
        >
          {isInspireMeMenu || isMealsAndRecipeMenu
            ? departmentMenu
            : superdepartmentMenu}

          {isGroceriesMenu &&
          open &&
          showContentStamps &&
          browseEntrypoints.length ? (
            <ContentStamp
              isMenuSelected={!!superdepartment}
              stampsList={browseEntrypoints}
            />
          ) : null}
        </div>
      </div>
    );
  }
}
