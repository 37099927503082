import React from 'react';
import PropTypes from 'prop-types';
import { convertValueTileItems } from '#/lib/dcs/adapter/valueTileAdapter';
import helpers from '#/lib/decorators/helpers';
import { connect } from '#/lib/render/connect-deep-compare';
import { getObserver } from '#/lib/browser/intersection-observer';
import { getCurrency, getCutsMustard } from '#/reducers/app';
import LazyImage from '#/components/image/lazy-image';
import TradeCta from '#/components/cms-driven/trade_cta';
import { ValueTile } from '#/components/cms-driven/value-tile';
import Link from '#/components/link-check-spa';
import classNames from 'classnames';
import { includesRegularPrice } from '#/lib/dcs/dcs-helpers';

const mapStateToProps = state => ({
  currency: getCurrency(state),
  cutsMustard: getCutsMustard(state)
});

@helpers(['asset', 'c'])
@connect(mapStateToProps)
export class TradeBanner extends React.PureComponent {
  constructor(props) {
    super(props);

    this.bannerContentRef = React.createRef();

    this.state = {
      isVisible: !props.cutsMustard
    };
  }

  static propTypes = {
    asset: PropTypes.func.isRequired,
    background: PropTypes.shape({
      src: PropTypes.string,
      texturedImageUrl: PropTypes.string
    }),
    bannerColor: PropTypes.shape({ backgroundColor: PropTypes.string }),
    brandLogo: PropTypes.shape({ src: PropTypes.string }),
    c: PropTypes.func.isRequired,
    callToAction: PropTypes.shape({
      desktop: PropTypes.object,
      mobile: PropTypes.object
    }),
    currency: PropTypes.object.isRequired,
    cutsMustard: PropTypes.bool.isRequired,
    headline: PropTypes.string.isRequired,
    link: PropTypes.shape({ href: PropTypes.string }),
    panelColor: PropTypes.shape({ backgroundColor: PropTypes.string }),
    placement: PropTypes.number,
    priority: PropTypes.string,
    product1: PropTypes.shape({ src: PropTypes.string }),
    product2: PropTypes.shape({ src: PropTypes.string }),
    subHeadline: PropTypes.string,
    t: PropTypes.func.isRequired,
    termsText: PropTypes.string,
    textColor: PropTypes.shape({ color: PropTypes.string }),
    valueTile: PropTypes.shape({ valueContent: PropTypes.array })
  };

  static defaultProps = {
    panelColor: {}
  };

  componentDidMount() {
    if (!this.state.isVisible) {
      this.observer = getObserver(this.intersection);

      this.observe();
    }
  }

  componentWillUnmount() {
    if (this.observer) {
      this.observer.disconnect();
      this.observer = null;
    }
  }

  load() {
    this.setState({
      isVisible: true
    });
  }

  intersection = elements => {
    // Are we in viewport?
    if (this.observer && elements[0].intersectionRatio > 0) {
      // Stop watching and load content
      this.observer.disconnect();
      this.load();
    }
  };

  observe() {
    if (this.observer) {
      this.observer.observe(this.bannerContentRef.current);
    } else {
      this.load();
    }
  }

  render() {
    const props = this.props;
    const {
      asset,
      c: config,
      currency: { symbol, unitSymbol },
      background,
      bannerColor,
      brandLogo,
      callToAction,
      headline,
      link,
      panelColor,
      placement,
      priority,
      product1,
      product2,
      subHeadline,
      termsText,
      textColor,
      valueTile
    } = props;

    const shouldShowValueTile = valueTile && valueTile.template_type;
    const backgroundImg =
      background && background.src
        ? { backgroundImage: `url(${props.asset(background.src)})` }
        : {};
    const bannerContentStyles = Object.assign(
      {
        backgroundImage:
          background && background.texturedImageUrl && this.state.isVisible
            ? `url("${asset(background.texturedImageUrl)}")`
            : null
      },
      panelColor
    );
    const hasLink = !!(link && link.href);
    const LinkElement = hasLink ? Link : 'div';
    const hasBackgroundImg = backgroundImg?.backgroundImage;
    const hasProduct1 = product1?.src;
    const hasProduct2 = product2?.src;
    const shouldRenderImageContainer =
      hasBackgroundImg || hasProduct1 || hasProduct2;

    const isRegularPriceEnabledRegion = config('regularPriceDisplay');
    const hasRegularPrice = !!(
      !!shouldShowValueTile && includesRegularPrice(valueTile)
    );

    const linkElementClasses = classNames('slim-trade-banner__link', {
      'slim-trade-banner--fixed-height': isRegularPriceEnabledRegion
    });

    const valueTileClasses = classNames(
      'slim-trade-banner__container',
      'slim-trade-banner__container--value-tile',
      {
        'slim-trade-banner__container--regular-price--value-tile': hasRegularPrice
      }
    );

    const imageClasses = classNames(
      'slim-trade-banner__container',
      'slim-trade-banner__container--image',
      {
        'slim-trade-banner__container--regular-price--image': hasRegularPrice
      },
      {
        'slim-trade-banner--fixed-height': isRegularPriceEnabledRegion
      }
    );

    const panelClasses = classNames(
      'slim-trade-banner__container slim-trade-banner__container--panel',
      {
        'slim-trade-banner__container--panel--full-width': !shouldRenderImageContainer
      },
      {
        'slim-trade-banner__container--regular-price--panel': hasRegularPrice
      },
      {
        'slim-trade-banner--fixed-height': isRegularPriceEnabledRegion
      }
    );
    const region = config('REGION');

    return (
      <div className="slim-trade-banner__inner" style={bannerColor}>
        <LinkElement
          className={linkElementClasses}
          to={hasLink ? link.href : undefined}
        >
          {shouldShowValueTile && (
            <div className={valueTileClasses}>
              <ValueTile
                {...convertValueTileItems(
                  valueTile,
                  symbol,
                  unitSymbol,
                  region,
                  true
                )}
                isSlimTradeBanner={true}
                offerDetailHiddenText={props.t('promotions:offer-details')}
                termsApplyHiddenText={props.t('promotions:terms-apply')}
              />
            </div>
          )}
          <div className="slim-trade-banner__container slim-trade-banner__container--main">
            {shouldRenderImageContainer && (
              <div className={imageClasses} style={backgroundImg}>
                <div>
                  {hasProduct1 && (
                    <LazyImage
                      className="slim-trade-banner__image slim-trade-banner__image--1"
                      src={product1.src}
                      alt={product1.alt || ' '}
                    />
                  )}
                  {hasProduct2 && (
                    <LazyImage
                      className="slim-trade-banner__image slim-trade-banner__image--2"
                      src={product2.src}
                      alt={product2.alt || ' '}
                    />
                  )}
                </div>
              </div>
            )}
            <div
              ref={this.bannerContentRef}
              className={panelClasses}
              style={bannerContentStyles}
            >
              <div className="slim-trade-banner__panel" style={textColor}>
                {priority === 'high' && brandLogo && brandLogo.src && (
                  <div className="slim-trade-banner__brand-logo">
                    <LazyImage src={brandLogo.src} alt={brandLogo.alt || ' '} />
                  </div>
                )}
                <div className="slim-trade-banner__text">
                  <p className="slim-trade-banner__title">{headline}</p>
                  {subHeadline && (
                    <p className="slim-trade-banner__subheadline">
                      {subHeadline}
                    </p>
                  )}
                  {termsText && (
                    <p className="slim-trade-banner__terms">{termsText}</p>
                  )}
                </div>
              </div>
            </div>
            {hasLink && <TradeCta uid={placement} ctaColor={callToAction} />}
          </div>
        </LinkElement>
      </div>
    );
  }
}
