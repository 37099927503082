import React from 'react';
import PropTypes from 'prop-types';
import { connect } from '#/lib/render/connect-deep-compare';
import classnames from 'classnames';
import LayoutModals from '#/components/modals/layout-modals';
import {
  getBagCharge,
  getCouponSavings,
  getGuidePrice,
  getIsAmendBasket,
  getIsBagless,
  getIsTrolleyUpdating,
  getTotalPrice
} from '#/selectors/trolley';
import { getIosAppId } from '#/reducers/app';

import { createMetaTag } from '#/lib/html-helpers';
import { IOS_APP } from '#/constants/app-banner';
import { getTrolleyShoppingMethod } from '#/selectors/trolley';

const mapStateToProps = state => {
  return {
    bagCharge: getBagCharge(state),
    couponSavings: getCouponSavings(state),
    guidePrice: getGuidePrice(state),
    isAmendBasket: getIsAmendBasket(state),
    isBagless: getIsBagless(state),
    isUpdating: getIsTrolleyUpdating(state),
    iosAppId: getIosAppId(state),
    shoppingMethod: getTrolleyShoppingMethod(state),
    totalPrice: getTotalPrice(state)
  };
};

/**
 * This is the layout used when consumer = webview-ios or webview-android
 */
@connect(mapStateToProps)
export default class DefaultWebViewLayout extends React.Component {
  static propTypes = {
    bagCharge: PropTypes.number.isRequired,
    children: PropTypes.any.isRequired,
    className: PropTypes.string,
    couponSavings: PropTypes.number.isRequired,
    customClassName: PropTypes.string,
    guidePrice: PropTypes.number.isRequired,
    includeAppBanner: PropTypes.bool,
    iosAppId: PropTypes.string,
    isAmendBasket: PropTypes.bool.isRequired,
    isBagless: PropTypes.bool.isRequired,
    isHomePage: PropTypes.bool,
    isUpdating: PropTypes.bool.isRequired,
    shoppingMethod: PropTypes.string,
    totalPrice: PropTypes.number.isRequired
  };

  static defaultProps = {
    customClassName: '',
    isHomePage: false
  };

  constructor(props) {
    super(props);
  }

  addRemoveIOSAppBanner() {
    const appBannerMetaTag = document.getElementById(IOS_APP);

    if (!this.props.isHomePage && appBannerMetaTag) {
      appBannerMetaTag.parentNode.removeChild(appBannerMetaTag);
    } else if (this.requireIOSMetaTag(appBannerMetaTag)) {
      createMetaTag({
        id: IOS_APP,
        name: 'apple-itunes-app',
        content: `app-id=${this.props.iosAppId}`
      });
    }
  }

  requireIOSMetaTag(appBannerMetaTag) {
    return (
      this.props.isHomePage &&
      this.props.iosAppId &&
      !appBannerMetaTag &&
      this.props.includeAppBanner
    );
  }

  componentDidMount() {
    this.addRemoveIOSAppBanner();
  }

  render() {
    const { props } = this;

    const {
      bagCharge,
      children,
      couponSavings,
      className,
      customClassName,
      guidePrice,
      isAmendBasket,
      isBagless,
      isHomePage,
      isUpdating,
      shoppingMethod,
      totalPrice
    } = props;

    const classes = classnames(
      className,
      `content-wrapper ${customClassName} `,
      {
        'amend-mode': isAmendBasket,
        'home-page-container': isHomePage
      }
    );

    return (
      <div className={classes}>
        <div role="main" id="main" className="main">
          <div className="main__content" key="main__content">
            {children}
          </div>
        </div>
        <LayoutModals
          couponSavings={couponSavings}
          guidePrice={guidePrice}
          isAmendBasket={isAmendBasket}
          isUpdating={isUpdating}
          isBagless={isBagless}
          bagCharge={bagCharge}
          totalPrice={totalPrice}
          shoppingMethod={shoppingMethod}
          selectedShoppingMethod={shoppingMethod}
        />
      </div>
    );
  }
}
