import React from 'react';

import { connect } from '#/lib/render/connect-deep-compare';
import Price from '#/components/shared/price';
import helpers from '#/lib/decorators/helpers';
import { getGuidePrice, getIsTrolleyUpdating } from '#/selectors/trolley';
import { getCurrency } from '#/reducers/app';
import { TCurrency } from '#/custom-typings/redux-store/app.defs';

type StoreProps = {
  currency: TCurrency;
  guidePrice: number;
  isTrolleyUpdating: boolean;
};

const mapStateToProps = (state: Store): StoreProps => {
  return {
    currency: getCurrency(state),
    guidePrice: getGuidePrice(state),
    isTrolleyUpdating: getIsTrolleyUpdating(state),
  };
};

type OwnProps = {
  t: (key: string, data?: Record<string, unknown>) => string;
};

export type TProps = OwnProps & ReturnType<typeof mapStateToProps>;

const AccPriceStatus: React.FC<TProps> = (props: TProps) => {
  const { t: translate, currency, guidePrice, isTrolleyUpdating } = props;

  return (
    <div className="visually-hidden" role="status">
      {!isTrolleyUpdating && (
        <>
          <span>{translate('common:guide-price-long')}</span>
          <Price
            {...Object.assign(
              {
                className: 'amount',
                value: guidePrice,
                enableMaxLimit: true,
              },
              currency,
            )}
          />
        </>
      )}
    </div>
  );
};

export default helpers(['t'])(connect(mapStateToProps)(AccPriceStatus));
