import styled from 'styled-components';
import { spacing } from '@ddsweb/selectors';
import Icon from '@ddsweb/icon';
import { visuallyHide } from '@ddsweb/foundation';

export const VisuallyHidden = styled.span`
  ${visuallyHide};
`;

export const StyledDDSIcon = styled(Icon)`
  top: ${spacing.sm};
  right: 14px;
`;
