import {
  IBYCCarouselVariants,
  IBYCCarouselFeature,
  SEARCH_URL,
  SHOP_URL,
  STRATEGY_VARIATION_KEY,
} from '#/experiments/oop-2349/constants';
import { getFeatureVariableStringFromState, updateRegionInKey } from '#/lib/optimizely-manager';
import { getIsCurrentPage, getCurrentPath } from '#/reducers/app';

export const getIBYCCarouselVariant = (state: Store): IBYCCarouselVariants => {
  const { featureKey, variationKey, useCustomerId } = IBYCCarouselFeature;
  const regionInKey = updateRegionInKey(featureKey, state);
  const featureVariable = getFeatureVariableStringFromState(state, regionInKey, variationKey, { useCustomerId });

  return featureVariable || IBYCCarouselVariants.DISABLED;
};

export const getIBYCCarouselStrategy = (state: Store): IBYCCarouselVariants => {
  const { featureKey, useCustomerId } = IBYCCarouselFeature;
  const regionInKey = updateRegionInKey(featureKey, state);
  const featureVariableStrategy = getFeatureVariableStringFromState(state, regionInKey, STRATEGY_VARIATION_KEY, {
    useCustomerId,
  });

  return featureVariableStrategy || IBYCCarouselVariants.DISABLED;
};

export const getIsIBYCCarouselEnabled = (state: Store): boolean => {
  return getIBYCCarouselVariant(state) !== IBYCCarouselVariants.DISABLED;
};

const getShopUrl = (state: Store): boolean => {
  const currentPage = getCurrentPath(state);
  const regex = new RegExp(SHOP_URL);
  return !!currentPage.match(regex)?.length;
};

export const getIsIBYCCarouselEnabledForShopOrSearch = (state: Store): boolean => {
  const isSearchOrShopPage = getIsCurrentPage(state, [SEARCH_URL]) || getShopUrl(state);
  return isSearchOrShopPage && getIsIBYCCarouselEnabled(state);
};

export const getIBYCCarouselVariantStrategy = (state: Store): string => getIBYCCarouselStrategy(state);

export const getIBYCCarouselVariantLogic = (state: Store): IBYCCarouselVariants => getIBYCCarouselVariant(state);
