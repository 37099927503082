export const VARIATION_KEY = 'experience';
export const VARIATION_ID = 'variationId';
export const FEATURE_KEY = 'GHS-<region>_entrypoints-in-navigation';

export type ExperimentConfig = {
  experiment: string;
  variation: string;
  experience?: string;
  variationId?: string;
};

export enum BrowseEntryPointVariants {
  DISABLED = '1894-a',
  PLP_PERSIST = '1894-b',
  PLP_NON_PERSIST = '1894-c',
  ZONE_PERSIST = '1894-d',
  ZONE_NON_PERSIST = '1894-e',
}

export const BrowseEntryPointFeature = {
  featureKey: FEATURE_KEY,
  variationKey: VARIATION_KEY,
  variationId: VARIATION_ID,
  useCustomerId: true,
  serviceConfig: {
    params: { [VARIATION_KEY]: BrowseEntryPointVariants.DISABLED, [VARIATION_ID]: BrowseEntryPointVariants.DISABLED },
  },
};

export const TAXONOMY_CONTENT_STAMPS = 'taxonomy-content-stamps';
export const MAXIMUM_STAMPS_TO_SHOW = 2;
//  UIEventBasicEvent analytics type & type value
const MEDIA_ENTRYPOINT_KEY = 'media entrypoint';
export const MEDIA_ENTRYPOINT_CLICK_EVENT_KEY = `${MEDIA_ENTRYPOINT_KEY}:`;
export const ANALYTICS_IMPRESSION = ':impression';
export const LINK_NEW_WINDOW_YES = 'Yes';
export const STAMP_IMAGE_WIDTH = '400';
