export const USUALS_TAB = 'usuals';
export const FAVORITES_TAB = 'favourites';
export const LAST_ORDER_TAB = 'last-order';
export const FAVOURITES_CAROUSEL = 'FavouritesCarousel';
export const DEFAULT_COUNT_FAVS_CAROUSEL = 30;
export const DEFAULT_SORT_BY = 'default';
export const REMOVE_FROM_FAVORITES = 'remove-from-favorites';
export const FREQUENTLY_BOUGHT = 'Frequently Bought';
export const USUALS = 'Usuals';
export const FAV_SCROLL_POS_Y = 'FavScrollPosY';
export const ONLY_AVAILABLE_PRODUCTS = 'ONLY_AVAILABLE_PRODUCTS';
export const FREQUENTLY_BOUGHT_FACET_ID = 'frequentlyBought';
export const MY_FAVORITES = 'myProducts';

export const LIST = 'list';
export const GRID = 'grid';
export const STORAGE_KEY = 'fav-layout';
export const QUERY_LAYOUT = 'layout';
export const TIMEOUT_LIMIT = 2000;
