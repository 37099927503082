/**
 * Experiment constants for favorites homepage nav link tooltip
 */
export const favNavLinkTooltipConfig = {
  featureKey: 'GHS-UK_homepage-nav_myfavourites-popover',
  variationKey: 'popover-enabled',
  variants: {
    on: 'on',
    off: 'off',
  },
  useCustomerId: true,
};

export const storageKey = 'fav-nav-link-tooltip_';
export const analyticsKey = 'fav-nav-link-tooltip-render_';
