import { imageStyle, imageType } from '#/constants/category-chips';
import { AISLE, DEPARTMENT, POSTFIX_ALL, SUPERDEPARTMENT } from '#/constants/taxonomy-levels';
import {
  CategoryChipButton,
  CategoryChipList,
  NavItemWithImage,
  TaxonomyImageStyle,
  TaxonomyImageType,
} from '#/custom-typings/redux-store/taxonomy.defs';

/**
 * TODO: Check whether if there are any other similar use cases so that these helpers
 * can be moved to generic utils
 */
const findNodeRecursivelyByCatId = (
  categoryId: string,
  itemDetails: NavItemWithImage[],
): NavItemWithImage | undefined => {
  for (const node of itemDetails) {
    const { children, catId } = node;

    if (catId === categoryId) return node;
    if (children) {
      const child = findNodeRecursivelyByCatId(categoryId, children);
      if (child) return child;
    }
  }
};

export const getCategoryChipsByMenuType = (
  menuItems: NavItemWithImage[],
  selectedCategory: NavItemWithImage,
  noImage: string,
): CategoryChipList => {
  const { catId, label, parent } = selectedCategory;
  const selectedLabel = label.toLowerCase();

  const categoryId: string = selectedLabel === AISLE ? parent : catId;

  const categoryDetails: NavItemWithImage | undefined = findNodeRecursivelyByCatId(categoryId, menuItems);
  const categoryChips: CategoryChipList = [];

  if (categoryDetails && categoryDetails.children) {
    categoryDetails.children.forEach((node: NavItemWithImage) => {
      const { images, url, name, label, allNode, allPromotionNode = false, newBrowseNode = false } = node;
      const categoryInfo: CategoryChipButton = {
        image: getTaxonomyImage(images, noImage),
        url,
        name,
        label,
        allNode,
        hideChip: allPromotionNode || newBrowseNode,
        allPromotionNode,
        selected:
          selectedLabel === DEPARTMENT || selectedLabel === SUPERDEPARTMENT
            ? `${catId}${POSTFIX_ALL}` === node.catId
            : catId === node.catId,
      };

      categoryChips.push(categoryInfo);
    });
  }
  return categoryChips;
};

const extractImageFromNode = (node: Array<TaxonomyImageStyle>, noImage: string): string => {
  if (!node) {
    return noImage;
  }

  const thumbnailImages: TaxonomyImageStyle[] = node.filter(item => item.style === imageStyle);

  if (thumbnailImages.length) {
    const standardImage: TaxonomyImageType[] =
      thumbnailImages[0].images && thumbnailImages[0].images.filter(image => image.type === imageType);

    return standardImage.length ? standardImage[0].url : noImage;
  }

  return noImage;
};

export const getTaxonomyImage = (node: Array<TaxonomyImageStyle>, noImage: string): string =>
  extractImageFromNode(node, noImage);

export const getCategoryUrl = (type: string, { allNode, url = '' }: NavItemWithImage): string =>
  type === DEPARTMENT && !allNode && url.indexOf('/all') === -1 ? `${url}/all` : url;
