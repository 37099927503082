import analyticsBus from '#/analytics/analyticsBus';
import { basicEvent } from '#/analytics/types/basic';
import {
  NOW,
  PREDICTIVE_NEXT,
  SEARCH_BAR,
  SEARCH_EVENT_TYPE,
  SEARCH_STORAGE_KEY,
} from '#/experiments/oop-1742/constants';
import { sessionStore } from '#/lib/data-store/client-store';

interface SuggestionTypesWithCount {
  [key: string]: number;
}

type suggestionTypes = 'pns' | 'popular' | 'trending';

export const triggerSearchAnalyticsEvent = (type: string, value: string, action: string = NOW): void =>
  basicEvent(analyticsBus, {
    action,
    type,
    value,
  });

export function triggerSearchInteractionAnalyticsEvent(
  predictiveSuggestionType: suggestionTypes,
  isPredictiveSearch = false,
): void {
  const bertieEventType = isPredictiveSearch ? `${predictiveSuggestionType} searches` : PREDICTIVE_NEXT;

  const fetchSessionStorage = (sessionStore?.get(SEARCH_STORAGE_KEY) || {}) as SuggestionTypesWithCount;
  const suggestionTypeCount = (fetchSessionStorage[predictiveSuggestionType] ?? 0) + 1;
  const suggestionTypeValue = `${suggestionTypeCount}:impression`;

  fetchSessionStorage[predictiveSuggestionType] = suggestionTypeCount;
  sessionStore?.set(SEARCH_STORAGE_KEY, fetchSessionStorage);

  triggerSearchAnalyticsEvent(SEARCH_EVENT_TYPE, SEARCH_BAR);
  triggerSearchAnalyticsEvent(bertieEventType, suggestionTypeValue);
}
