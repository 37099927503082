import React from 'react';
import { BasicAccordion } from '@ddsweb/accordion';
import { generateIdPropFromTitle } from '#/lib/dcs/dcs-helpers';

type OwnProps = {
  children: JSX.Element;
  title: string;
};

export const SecondaryNavigation = ({ children, title }: OwnProps): JSX.Element => (
  <BasicAccordion
    preExpanded={[generateIdPropFromTitle(title)?.id || 'accordion']}
    behaviour="toggle"
    content={[
      {
        headingLevel: 'h3',
        iconVariant: 'primary',
        heading: title,
        id: generateIdPropFromTitle(title)?.id || 'accordion',
        panel: children,
      },
    ]}
  />
);

export default SecondaryNavigation;
