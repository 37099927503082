import analyticsBus from '#/analytics/analyticsBus';
import { basicEvent } from '#/analytics/types/basic';
import { NAVIGATION } from '#/constants/page-names';
import { DELAY } from '#/analytics/constants';
import { MEDIA_ENTRYPOINT_CLICK_EVENT_KEY } from '#/experiments/oop-1894/constants';

export const emitTaxonomyStampAnalyticsEvent = (action: string = DELAY, value: string): void =>
  basicEvent(analyticsBus, {
    action,
    type: NAVIGATION,
    value: `${MEDIA_ENTRYPOINT_CLICK_EVENT_KEY}${value}`,
  });
