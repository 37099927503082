/**
 * Experiment constants for basket item unavailable - ondemand shopping method
 */
export const basketUnavailableModalVariants = {
  key: '1583',
  variants: {
    default: '1583-a',
    showItemUnavailableModal: '1583-b',
  },
  useCustomerId: true,
};

export const BASKET_ITEMS_UNAVAILABLE_MODAL_NAME = 'basketItemUnavailable';

//  UIEventBasicEvent analytics type & type value
const ITEM_UNAVAILABLE_ANALYTICS_KEY = 'item unavailable modal';
export const ITEM_UNAVAILABLE_MODAL_IMPRESSION = `${ITEM_UNAVAILABLE_ANALYTICS_KEY}:impression`;
export const ITEM_UNAVAILABLE_MODAL_CLOSE = `${ITEM_UNAVAILABLE_ANALYTICS_KEY}:close`;
export const ITEM_UNAVAILABLE_MODAL_GOTO_BASKET = `${ITEM_UNAVAILABLE_ANALYTICS_KEY}:go to basket`;
export const ITEM_UNAVAILABLE_MODAL_CONTINUE_SHOPPING = `${ITEM_UNAVAILABLE_ANALYTICS_KEY}:continue shopping`;
