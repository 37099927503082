import React from 'react';
import PropTypes from 'prop-types';
import classSet from 'classnames';
import PromoMessaging from '../promo-messaging';
import SpecialOffersText from '#/components/products/special-offers-promo-text';
import { StyledPromotionSecondaryText } from '#/components/products/styled';

export const PromoContent = ({
  offerText,
  promotionsText,
  showPromotionsText,
  promotionSecondaryText
}) => (
  <div className="list-item-content promo-content-small">
    <span className="offer-text">{offerText}</span>
    {promotionSecondaryText && (
      <StyledPromotionSecondaryText>
        {promotionSecondaryText}
      </StyledPromotionSecondaryText>
    )}
    {promotionsText && showPromotionsText && (
      <>
        <span className="dates">{promotionsText}</span>
      </>
    )}
  </div>
);

PromoContent.propTypes = {
  hasDeposit: PropTypes.bool,
  offerText: PropTypes.string,
  promotionSecondaryText: PropTypes.string,
  promotionsText: PropTypes.string,
  showPromotionsText: PropTypes.bool
};

export const ProductPromotionItem = ({
  currentUrl,
  handleClick,
  viewAndBuyButtonUrl,
  isTrolleyPage,
  isAlreadyShowingRdgMsg,
  showCompletePromoMessage,
  missedPromotion,
  showDatesOnlyOnMissedSlot,
  promotionsLink,
  item,
  tile,
  isAmendBasket,
  promotionsText,
  hasDeposit
}) => (
  <li
    className={classSet({
      'product-promotion': true,
      'has-missed-promotion': missedPromotion
    })}
  >
    {currentUrl !== promotionsLink ? (
      <>
        <SpecialOffersText
          hasDeposit={hasDeposit}
          hasLink={true}
          promoData={item}
          promotionsLink={promotionsLink}
          handleClick={handleClick}
          offerText={item.offerText}
          promotionsText={promotionsText}
        />
        {isTrolleyPage && !viewAndBuyButtonUrl && (
          <PromoMessaging
            promoData={item}
            showCompletePromoMessage={showCompletePromoMessage}
            showDatesOnlyOnMissedSlot={showDatesOnlyOnMissedSlot}
            tile={tile}
            isAmendBasket={isAmendBasket}
            showChangeSlot={!isAlreadyShowingRdgMsg}
          />
        )}
      </>
    ) : (
      <SpecialOffersText
        hasDeposit={hasDeposit}
        hasLink={false}
        promoData={item}
        offerText={item.offerText}
        promotionsText={promotionsText}
      />
    )}
  </li>
);

ProductPromotionItem.propTypes = {
  c: PropTypes.func,
  currentUrl: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  hasFeature: PropTypes.func,
  isAlreadyShowingRdgMsg: PropTypes.bool,
  isAmendBasket: PropTypes.bool.isRequired,
  item: PropTypes.object.isRequired,
  missedPromotion: PropTypes.bool,
  promotionsLink: PropTypes.string.isRequired,
  promotionsText: PropTypes.string,
  showCompletePromoMessage: PropTypes.bool,
  showDatesOnlyOnMissedSlot: PropTypes.bool,
  t: PropTypes.func,
  tile: PropTypes.string,
  viewAndBuyButtonUrl: PropTypes.string
};

ProductPromotionItem.defaultProps = {
  missedPromotion: false,
  tile: 'product tile',
  isAlreadyShowingRdgMsg: false,
  showDatesOnlyOnMissedSlot: false,
  showCompletePromoMessage: true
};
