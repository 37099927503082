import Bertie from '@peas/bertie';
import harold from '@peas/harold';
import { logApmError } from '#/lib/apm';
import Helpers from '#/lib/helpers';
import { globalPayload } from '#/analytics/helpers/global-payload';
import { PRD, PROD_TEST } from '#/constants/common';

type Logger = typeof console;

type PPEConfig = {
  path?: string;
  querystring?: string;
};

type Settings = PPEConfig & {
  ppe: boolean;
};

type DataLayer = {
  cookiePreferences: {
    experience: boolean;
    advertising: boolean;
  };
};

const analyticsSchema = process.env.NODE_ENV === 'production' ? null : require('./schema.json');

const isExperienceAvailable = (): boolean => {
  const { dataLayer } = window;
  if (!dataLayer?.length) {
    return false;
  }

  const { cookiePreferences } = (dataLayer.find(data => data.hasOwnProperty('cookiePreferences')) || {}) as DataLayer;
  return cookiePreferences?.experience;
};

let bertie: Bertie;

function getLogger(): Logger {
  const logger = Object.assign({}, console);

  logger.error = (errorMsg, errorObj): void => {
    logApmError(errorObj, errorMsg);
  };
  return logger;
}

export default function getAnalyticsBus(): Bertie {
  if (bertie) {
    return bertie;
  }

  //  Dispatch DOM event to inform bertie consumers that bertie is ready to be listened to
  const isClientWindow = typeof window !== 'undefined';

  const logger = isClientWindow ? getLogger() : console;

  try {
    bertie = Bertie(isClientWindow ? window : null, analyticsSchema, 50, 50, logger, globalPayload());
  } catch (e) {
    // Integration test case: Bertie cannot be instantiated
    bertie = {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      emit: (): void => {},
    };
  }

  //  Dispatch DOM event to inform bertie consumers that bertie is ready to be listened to
  if (isClientWindow) {
    const event = document.createEvent('Event');

    event.initEvent('bertieLoaded', true, true);
    const contentElem = document.getElementById('content');

    if (contentElem) {
      contentElem.dispatchEvent(event);

      const helpers = new Helpers(document);

      if (helpers.c('TAP:connector') && isExperienceAvailable()) {
        let settings: Settings = {
          ppe: false,
        };

        const ppeConfig = helpers.c('TAP:PPE') as PPEConfig;
        const appName = document.body.getAttribute('data-app-name')?.toLowerCase();
        const isProd = appName && [PROD_TEST, PRD].includes(appName);

        if (!isProd && ppeConfig) {
          settings = {
            ppe: true,
            ...ppeConfig,
          };
        }

        harold(bertie, settings);
      }
    }
  }

  return bertie;
}
