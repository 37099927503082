import React from 'react';
import { StyledCardContainer, StyledHeading, StyledLogo, StyledWrapper } from '#/components/delivery-saver-info/styled';

type OwnProps = {
  title: string;
  headerLink: JSX.Element;
  children: JSX.Element;
};

export const DeliverySaverInfo: React.FC<OwnProps> = (props: OwnProps): JSX.Element => {
  const { title, headerLink, children } = props;

  return (
    <StyledCardContainer>
      <StyledWrapper>
        <StyledHeading visualSize="headline4" headingLevel="3">
          <StyledLogo scale={true} />
          {title}
        </StyledHeading>

        {headerLink}
      </StyledWrapper>

      {children}
    </StyledCardContainer>
  );
};
