import React from 'react';
import classnames from 'classnames';
import { compose } from 'react-recompose';
import helpers from '../../lib/decorators/helpers';

const GHSMarketplaceFooter = ({
  hideChevronIcon,
  t,
  showSmallText,
  showUnderline,
  c
}) => {
  const linkText = `sort-and-filter:${
    showSmallText
      ? 'ghs-marketPlace-footer-link-small'
      : 'ghs-marketPlace-footer-link'
  }`;

  return (
    <div className="filter-options-footer-container">
      <a
        href={c && c('marketPlaceFaqLink')}
        className={classnames('filter-options-footer--ghs-marketPlace-link', {
          'ghs-marketPlace-link-underline': showUnderline
        })}
        target="_blank"
        rel="noopener"
      >
        <span> {t && t(linkText)}</span>
      </a>
      {!hideChevronIcon && (
        <span className="icon icon-chevron_right link-icon-product-source" />
      )}
    </div>
  );
};
const enhance = compose(helpers(['t', 'c']));
export default enhance(GHSMarketplaceFooter);
