import {
  PROMOTIONS_ID_OR_TYPE,
  TROLLEY_CONTENTS
} from '#/constants/spa-resource';
import { getPromotionsIdOrTypeResource } from '#/selectors/resources';
import {
  updateCurrentUrl,
  updateFromNewResults
} from '#/actions/filter-action-creators';
import { updateResults } from '#/actions/results-actions';

export const type = PROMOTIONS_ID_OR_TYPE;

export const params = [
  'idOrType',
  'query',
  'trolleyItems',
  'slot',
  'isAmendBasket'
];

export const dependencies = [TROLLEY_CONTENTS];

export function handler(dispatch, getState, options = {}) {
  const state = getState();
  const resource = getPromotionsIdOrTypeResource(state);
  const { data: { results } = {} } = resource;

  if (!results || !results.productItems) {
    return;
  }

  const { updateCurrentURL } = options;

  dispatch(updateResults(results, type, true));

  if (updateCurrentURL) {
    dispatch(updateCurrentUrl(updateCurrentURL));
    dispatch(updateFromNewResults(results, updateCurrentURL));
  }
}
