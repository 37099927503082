export const tags = {
  clubcardPrice: 'Clubcard Price',
  offer: 'bestValue',
  newProduct: 'new',
  whyNotTry: 'whyNotTry',
  sponsored: 'sponsored',
  marketplace: 'marketplace',
};

export const promoType = {
  clubcardPrice: 'Clubcard Price',
  offer: 'Offer',
  newProduct: 'New',
  whyNotTry: 'Why not try',
  sponsored: 'sponsored',
};

export const competitorsType = {
  aldiPriceMatch: 'Aldi Price Match',
};
