export enum UnavailableItemHeightVariants {
  default = 'default',
  removeLinks = 'removedlinks',
  removeLinksWithPositiveWording = 'positivewording',
  seeAlternativesUnavailable = 'seealternativesunavailable',
  seeAlternativesSub = 'seealternativessub',
}

const VARIATION_KEY = 'myfavouritesRestofshelfCta';

export const UNAVAILABLE_PRODUCT_WITHOUT_SUBS = 'favourites:product tile without sub';
export const UNAVAILABLE_PRODUCT_WITH_SUBS = 'favourites:product tile with sub';
export const SUBSTITUTED_PRODUCT = 'favourites:sub product tile';
export const SEE_ALTERNATIVES = 'see alternatives';

export const favRestOfShelfCtaFeature = {
  featureKey: 'GHS-UK_myfavourites_restofshelf-on-unavailables',
  variationKey: VARIATION_KEY,
  useCustomerId: true,
};
