import React from 'react';
import Button from '@ddsweb/button';
import analyticsBus from '#/analytics/analyticsBus';
import { NOW } from '#/analytics/constants';
import { basicEvent } from '#/analytics/types/basic';
import { PRIMARY, SECONDARY, WARNING, XX } from '@ddsweb/constants';
import { ConnectedProps } from 'react-redux';
import { compose } from 'react-recompose';
import moment from 'moment-timezone';
import Link from '@ddsweb/link';
import { connect } from '#/lib/render/connect-deep-compare';
import helpers from '#/lib/decorators/helpers';
import { TTranslateFn } from '#/components/slots/delivery-content-section/index.defs';
import { TConfigFunc } from '#/lib/records/helpers.defs';
import { openModal } from '#/actions/ui-action-creators';
import {
  StyledHeading,
  StyledTextContainer,
  StyledButtonContainer,
  StyledFormattedSlotTime,
  StyledFormattedCheckoutTime,
  StyledStatusIndicator,
} from './styled';
import { StyledModal } from '#/components/modals/styled';
import { getCurrentUrl, getReturnUrlOrCurrentUrl, getTimezone, getLanguage } from '#/reducers/app';
import { closeSlotChangedInAmendWarning } from '#/actions/slot-action-creators';
import { getSlotReservationExpiry, getSlotStart, getSlotStatus, getSlotEnd } from '#/selectors/slot';
import { getFormattedTime } from '#/components/shared/formatted-time/selector';
import { getLastSelectedSlot, getPreviousSlot } from '#/selectors/trolley';
import SafelyOutputString from '#/components/safely-output-string';
import { IConfig } from '#/components/shared/formatted-time/utils';
import FormattedSlotTime from '#/components/shared/formatted-time/formattedSlotTime';
import { getCheckoutEntryPoint } from '#/utils/checkout-utils';
import {
  CLOSE_AMEND_MODAL_ANALYTICS_VALUE,
  AMEND_MODAL_ANALYTICS_EVENT_TYPE,
  CONTINUE_SHOPPING_ANALYTICS_VALUE,
  CHECHOUT_NOW_ANALYTICS_VALUE,
} from '#/constants/slot-amend-change';

type HelperProps = {
  t: TTranslateFn;
  c: TConfigFunc;
};

type StateProps = {
  currentUrl: string;
  returnUrl: string;
  slotExpiryTime: string | undefined;
  timezone: string;
  language: string;
  slotStart: string | moment.Moment | null;
  slotEnd: string | moment.Moment | null;
  checkoutUrl: string;
};

type Props = {
  closeModal: () => void;
  slotExpiryTime: string | undefined;
  expiryDateTime: Date;
  returnUrl: string;
  timezone: string;
  language: string;
  slotStart: moment.Moment;
  slotEnd: moment.Moment;
};

const mapDispatchToProps = {
  openModal,
  closeSlotChangedInAmendWarning,
};

const mapStateToProps = (state: Store, { c: config }: { c: TConfigFunc }): StateProps => {
  const bookedSlot = getLastSelectedSlot(state);
  const previousSlot = getPreviousSlot(state);
  const slotExpiry =
    getSlotStart(previousSlot) !== getSlotStart(bookedSlot) &&
    getSlotStatus(bookedSlot) !== 'Expired' &&
    moment(getSlotReservationExpiry(bookedSlot));

  return {
    slotExpiryTime: slotExpiry ? getFormattedTime(state, slotExpiry, config as IConfig) : undefined,
    currentUrl: getCurrentUrl(state),
    returnUrl: getReturnUrlOrCurrentUrl(state),
    timezone: getTimezone(state),
    language: getLanguage(state),
    slotStart: getSlotStart(bookedSlot),
    slotEnd: getSlotEnd(bookedSlot),
    checkoutUrl: getCheckoutEntryPoint(state),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type SlotChangedInAmendModalProps = HelperProps & ConnectedProps<typeof connector> & Props;

const SlotChangedInAmendModal = (props: SlotChangedInAmendModalProps): JSX.Element => {
  const closeModal = (eventType: string): void => {
    const { closeSlotChangedInAmendWarning } = props;

    closeSlotChangedInAmendWarning(eventType);
  };

  const checkoutClick = (): void => {
    basicEvent(analyticsBus, {
      type: AMEND_MODAL_ANALYTICS_EVENT_TYPE,
      value: CHECHOUT_NOW_ANALYTICS_VALUE,
      action: NOW,
    });
  };

  const { slotExpiryTime, t: translate, slotStart, slotEnd, timezone, language, checkoutUrl } = props;
  const slotStartLocalDate = moment(slotStart)
    .tz(timezone)
    .locale(language)
    .format('dddd Do MMMM');

  return (
    <StyledModal open dynamicHeight onChange={(): void => closeModal(CLOSE_AMEND_MODAL_ANALYTICS_VALUE)}>
      <StyledHeading headingLevel="3">{translate('orders:amend.slot-change-modal-title')}</StyledHeading>
      <StyledTextContainer>
        <StyledFormattedSlotTime as="p">
          <FormattedSlotTime startTime={slotStart} endTime={slotEnd} />
          {`, ${slotStartLocalDate}`}
        </StyledFormattedSlotTime>
        <StyledFormattedCheckoutTime as="p">
          <StyledStatusIndicator variant={WARNING} size={XX} />
          <SafelyOutputString>
            {translate('orders:amend.checkout-by', {
              time: slotExpiryTime,
            })}
          </SafelyOutputString>
        </StyledFormattedCheckoutTime>
      </StyledTextContainer>
      <StyledButtonContainer>
        <Link
          variant="textButton"
          buttonVariant={PRIMARY}
          href={checkoutUrl}
          data-auto="slot-checkout-button"
          onClick={checkoutClick}
        >
          {translate('orders:amend.slot-change-modal-checkout-now')}
        </Link>
        <Button variant={SECONDARY} onClick={(): void => closeModal(CONTINUE_SHOPPING_ANALYTICS_VALUE)}>
          {translate('slots:common.click-continue-shopping')}
        </Button>
      </StyledButtonContainer>
    </StyledModal>
  );
};

const enhance = compose<SlotChangedInAmendModalProps, Props>(helpers(['c', 't']), connector);

export default enhance(SlotChangedInAmendModal);
