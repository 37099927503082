import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RenderBrowseCategoryLink from '#/components/products/product-tile/tile-components/shared/render-browse-category-link';
import RenderBrowseCategorySuggestion from '#/components/products/product-tile/tile-components/shared/render-browse-category-suggestion';
import RenderControls from '#/components/products/product-tile/tile-components/shared/render-controls';
import RenderPromotions from '#/components/products/product-tile/tile-components/shared/render-promotions';
import RenderInfoMessages from '#/components/products/product-tile/tile-components/shared/render-info-messages';
import RenderUnavailableBrowseCategoryLink from '#/components/products/product-tile/tile-components/shared/render-unavailable-browse-category-link';
import RenderUnavailableProductMessages from '#/components/products/product-tile/tile-components/shared/render-unavailable-message';
import ProductImage from '#/components/products/product-tile/tile-components/product-image';
import ProductTitle from '#/components/products/product-tile/tile-components/product-title';
import { PERMANENTLY_UNAVAILABLE } from '#/constants/unavailable-product-type';
import {
  VIEW_AND_BUY_TYPE_FAVORITES,
  VIEW_AND_BUY_TYPE_OFFERS
} from '#/constants/display-types';
import ProductTileActions from '#/components/products/product-tile/tile-components/product-tile-actions';
import classNames from 'classnames';
import WriteAReview from '#/components/ratings-reviews/write-a-review';
import { connect } from '#/lib/render/connect-deep-compare';
import { getAisleNameFromItem } from '#/reducers/results';
import { getCurrentPathname } from '#/reducers/app';
import {
  getProductShelfId,
  getProductShelfName,
  getProductDefaultImageUrl,
  getProductAisleId,
  isProductBrowsableOnPDP,
  getRestOfShelfUrl,
  isInBasket,
  getProductIsForSale,
  getProductStatus,
  getProductTitle,
  isInFavourites,
  hasSubstitution,
  getProductId
} from '#/selectors/item';

const mapStateToProps = (state, { item }) => ({
  aisleName: getAisleNameFromItem(state, item),
  currentPathname: getCurrentPathname(state)
});

@connect(mapStateToProps)
export default class FlexiTile extends PureComponent {
  static propTypes = {
    aisleName: PropTypes.string.isRequired,
    basketFeedbackRender: PropTypes.func.isRequired,
    controlProps: PropTypes.object,
    currentPathname: PropTypes.string.isRequired,
    excludeMessages: PropTypes.array,
    href: PropTypes.string,
    isAmendBasket: PropTypes.bool,
    isAvailableEpwOverride: PropTypes.bool.isRequired,
    item: PropTypes.object.isRequired,
    offersIcid: PropTypes.string,
    onDetailsClick: PropTypes.func,
    onRestOfShelfClick: PropTypes.func,
    ospUrl: PropTypes.string,
    productBrowsableOnPDP: PropTypes.bool.isRequired,
    selectedUnit: PropTypes.string,
    sellableUnitPrice: PropTypes.number,
    showRestOfShelf: PropTypes.bool,
    showUnavailableMessage: PropTypes.bool.isRequired,
    showWriteReview: PropTypes.bool,
    tile: PropTypes.string,
    userRegistered: PropTypes.bool.isRequired,
    viewAndBuyButtonUrl: PropTypes.string,
    viewAndBuyType: PropTypes.oneOf([
      VIEW_AND_BUY_TYPE_FAVORITES,
      VIEW_AND_BUY_TYPE_OFFERS
    ])
  };

  static defaultProps = {
    showRestOfShelf: true,
    showWriteReview: true,
    excludeMessages: []
  };

  isCarouselTile = tileType => tileType && tileType === 'carousel';

  renderUnavailableMessage(className) {
    const {
      item,
      isAmendBasket,
      isAvailableEpwOverride,
      showUnavailableMessage
    } = this.props;
    return (
      showUnavailableMessage && (
        <RenderUnavailableProductMessages
          cssClassName={className}
          hasSubstitution={hasSubstitution(item)}
          status={getProductStatus(item)}
          isAvailable={getProductIsForSale(item)}
          isAmendBasket={isAmendBasket}
          isAvailableEpwOverride={isAvailableEpwOverride}
        />
      )
    );
  }

  renderInfoMessages(className) {
    const { item, isAmendBasket, excludeMessages } = this.props;
    return (
      <RenderInfoMessages
        cssClassName={className}
        item={item}
        isAmendBasket={isAmendBasket}
        excludeMessages={excludeMessages}
      />
    );
  }

  renderPromotions(item, offersIcid) {
    const { isAmendBasket, tile, recommendationDisplayType } = this.props;

    const additionalStyling =
      this.isCarouselTile(tile) && recommendationDisplayType === 'sidelist'
        ? { margin: '6px 0 12px 88px' }
        : {};
    return (
      <div className="product-details--content" style={additionalStyling}>
        <RenderPromotions
          isAmendBasket={isAmendBasket}
          item={item}
          offersIcid={offersIcid}
          largeViewPromotion={true}
          tile={tile}
        />
      </div>
    );
  }

  changeTrexSpecialOffersBannerStyling = identifierVariant => {
    if (identifierVariant === 'recommender:trex')
      return 'product-details--wrapper-variant';
    return 'product-details--wrapper';
  };

  render() {
    const {
      aisleName,
      basketFeedbackRender,
      currentPathname,
      item,
      viewAndBuyButtonUrl,
      ospUrl,
      offersIcid,
      viewAndBuyType,
      href,
      productBrowsableOnPDP,
      showRestOfShelf,
      showWriteReview,
      isAmendBasket,
      tile,
      isAvailableEpwOverride
    } = this.props;

    const isInFav = isInFavourites(item);
    const itemIsUnavailable = !isAvailableEpwOverride
      ? !getProductIsForSale(item)
      : false;
    const title = getProductTitle(item);
    const productId = getProductId(item);
    const productStatus = getProductStatus(item);
    const isProductBrowsable = isProductBrowsableOnPDP(item);

    return (
      <div className="flexi-tile" key="flexi">
        <div
          className={classNames('tile-content', {
            'tile-content--has-product-tile-actions': isInFav
          })}
          id={`tile-${productId}`}
          data-auto-id={productId}
        >
          {isInFav && <ProductTileActions item={item} tileType="flexi" />}
          <ProductImage
            alt={title}
            hideFromScreenReader={true}
            href={viewAndBuyButtonUrl || ospUrl || href}
            image={getProductDefaultImageUrl(item)}
            isUnavailable={itemIsUnavailable}
            viewAndBuyType={viewAndBuyType}
            clickable={productBrowsableOnPDP}
            onClick={this.props.onDetailsClick}
          />

          <div
            className={this.changeTrexSpecialOffersBannerStyling(
              this.props.identifier
            )}
          >
            <div className="product-details--content">
              <ProductTitle
                id={productId}
                title={title}
                isPDPBrowsable={isProductBrowsable}
                viewAndBuyButtonUrl={viewAndBuyButtonUrl}
                ospUrl={ospUrl}
                viewAndBuyType={viewAndBuyType}
                onClick={this.props.onDetailsClick}
              />
            </div>
            {!this.isCarouselTile(tile) &&
              this.renderPromotions(item, offersIcid)}
            <div className="product-details--content">
              {showWriteReview &&
                !viewAndBuyType &&
                productStatus !== PERMANENTLY_UNAVAILABLE &&
                isProductBrowsable && (
                  <WriteAReview id={productId} productTitle={title} />
                )}
              {showRestOfShelf && (
                <RenderBrowseCategoryLink
                  aisleName={aisleName}
                  aisleId={getProductAisleId(item)}
                  currentPathname={currentPathname}
                  isAvailableOrHasSub={
                    getProductIsForSale(item) || hasSubstitution(item)
                  }
                  restOfShelfUrl={getRestOfShelfUrl(item)}
                  shelfId={getProductShelfId(item)}
                  shelfName={getProductShelfName(item)}
                  tileType={tile}
                  onClick={this.props.onRestOfShelfClick}
                />
              )}
              {this.renderUnavailableMessage(
                'product-info-message-section unavailable-messages'
              )}
              {this.renderInfoMessages('product-info-message-section')}
            </div>
          </div>
          {this.isCarouselTile(tile) && this.renderPromotions(item, offersIcid)}
          <RenderControls {...this.props.controlProps} />
          {this.renderUnavailableMessage(
            'hidden-medium product-info-section-small'
          )}
          <div className="basket-feedback__wrapper">
            {isInBasket(item) && basketFeedbackRender()}
          </div>
          {this.renderInfoMessages('hidden-medium product-info-section-small')}
        </div>
        <RenderPromotions
          isAmendBasket={isAmendBasket}
          item={item}
          tile={tile}
        />
        <RenderBrowseCategorySuggestion
          item={item}
          isAvailableEpwOverride={isAvailableEpwOverride}
        />
        <RenderUnavailableBrowseCategoryLink item={item} />
      </div>
    );
  }
}
