import React from 'react';
import helpers from '#/lib/decorators/helpers';
import PropTypes from 'prop-types';

const FavoriteHeartIcon = ({
  className,
  t: translate,
  renderFilledHeartIcon,
  enableFavorite
}) => {
  const commonHeartFilledIcon = translate('common:favorite-heart-icon.filled');

  const getFilledHeartIcon = () => {
    if (enableFavorite && renderFilledHeartIcon) {
      return (
        <div className={className}>
          <span className="favorite-heart-icon" title={commonHeartFilledIcon} />
          <span className="visually-hidden">{commonHeartFilledIcon}</span>
        </div>
      );
    }
    return null;
  };

  return getFilledHeartIcon();
};

FavoriteHeartIcon.propTypes = {
  className: PropTypes.string,
  enableFavorite: PropTypes.bool,
  renderFilledHeartIcon: PropTypes.bool,
  t: PropTypes.func.isRequired
};

export default helpers(['t'])(FavoriteHeartIcon);
