import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { request } from '#/lib/client-fetch';
import SafeForm from '../safe-form';
import helpers from '#/lib/decorators/helpers';
import {
  MOBILE,
  TABLET,
  ANDROID,
  IOS,
  SAFARI,
  IOS_NON_SAFARI
} from '#/constants/platforms';
import {
  IOS_APP,
  SMARTBANNER_CLOSE,
  SMARTBANNER_INSTALL
} from '#/constants/app-banner';
import analyticsBus from '#/analytics/analyticsBus';
import { getBrowserFamily } from '#/lib/browser/device-characteristics';
import { basicEvent } from '#/analytics/types/basic';
import { closeAppBanner } from '#/actions/app-actions-creators';
import { connect } from '#/lib/render/connect-deep-compare';
import {
  getHasAcceptedAppBanner,
  getBrowserType,
  getLanguageLink,
  getIsTablet,
  getIsMobile,
  getOperatingSystem
} from '#/reducers/app';

function getIOSBrowserType(browserType, OS) {
  if (OS === IOS && browserType === SAFARI) {
    return SAFARI;
  } else if (OS === IOS && browserType !== SAFARI) {
    return IOS_NON_SAFARI;
  }

  return null;
}

function setDeviceType(OS, iOSBrowserType) {
  let deviceOSType = '';

  if (OS === ANDROID) {
    deviceOSType = ANDROID.toLowerCase();
  } else if (iOSBrowserType === IOS_NON_SAFARI) {
    //Show App banner component in iOS only if browser is not Safari
    //as Safari has Native App Banner Support
    deviceOSType = IOS.toLowerCase();
  }

  return deviceOSType;
}

const mapStateToProps = state => ({
  browserType: getBrowserType(state),
  displayAppBanner: getHasAcceptedAppBanner(state),
  appDownloadCookie: getLanguageLink(state, '/app-download-cookie?_method=PUT'),
  isTablet: getIsTablet(state),
  isMobile: getIsMobile(state),
  operatingSystem: getOperatingSystem(state)
});

@connect(mapStateToProps, { closeAppBanner })
@helpers(['c', 't'])
class SmartBanner extends Component {
  constructor(props, context) {
    super(props, context);
    this.browserType = getBrowserFamily(props.browserType, props.c);
    this.iOSBrowserType = getIOSBrowserType(
      this.browserType,
      props.operatingSystem
    );
    this.close = this.close.bind(this);
    this.install = this.install.bind(this);
    this.deviceOSType = setDeviceType(
      props.operatingSystem,
      this.iOSBrowserType
    );
  }

  static propTypes = {
    appDownloadCookie: PropTypes.string.isRequired,
    browserType: PropTypes.string.isRequired,
    c: PropTypes.func.isRequired,
    closeAppBanner: PropTypes.func.isRequired,
    displayAppBanner: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
    isTablet: PropTypes.bool.isRequired,
    operatingSystem: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired
  };

  componentDidMount() {
    if (this.iOSBrowserType === SAFARI) {
      const iosAppMeta = document.getElementById(IOS_APP);

      iosAppMeta &&
        iosAppMeta.addEventListener('click', this.sendAppAnalytics(), false);
    }
  }

  componentWillUnmount() {
    if (this.iOSBrowserType === SAFARI) {
      const iosAppMeta = document.getElementById(IOS_APP);

      iosAppMeta &&
        iosAppMeta.removeEventListener('click', this.sendAppAnalytics(), false);
    }
  }

  sendAppAnalytics() {
    basicEvent(analyticsBus, {
      action: 'now',
      type: 'notification',
      value: 'app download'
    });
  }

  close(event) {
    event.preventDefault();
    this.props.closeAppBanner();
    request.post(this.props.appDownloadCookie, {
      body: JSON.stringify({ cookieName: SMARTBANNER_CLOSE })
    });
  }

  install() {
    const smartBannerDisplay =
      this.props.operatingSystem === ANDROID ||
      this.iOSBrowserType === IOS_NON_SAFARI;

    if (smartBannerDisplay) {
      this.sendAppAnalytics();
    }
  }

  getDeviceType() {
    const { isTablet } = this.props;
    // Define device type - desktop does not need to be defined in this instance
    if (isTablet) {
      return TABLET;
    }

    return MOBILE;
  }

  getAppUrl() {
    const deviceType = this.getDeviceType();

    // Extract config and map operating system and device type to pull the correct link - expecting the keys of
    // this.props.c('links:appDownload') to match the exact string from this.props.operatingSystem
    // (if device type is applicable - i.e. `iOS`, `Android`), otherwise the link will be stubbed by default.
    const appDownloadConfig = this.props.c('links:appDownload:url');

    return (
      appDownloadConfig?.[this.props.operatingSystem]?.[deviceType] ||
      appDownloadConfig?.[ANDROID]?.[MOBILE]
    );
  }

  retrieveAppStoreInfo() {
    const appStoreType = this.props.t(
      `notifications:app-banner-download-store-type.${this.deviceOSType}`
    );
    const appStorePrice = this.props.t(
      'notifications:app-banner-download-price',
      { appStoreType }
    );

    return appStorePrice;
  }

  hideAppBanner() {
    const { displayAppBanner, isMobile, isTablet } = this.props;
    return (
      !this.props.c('appBanner:bannerEnabled') ||
      !displayAppBanner ||
      this.deviceOSType === '' ||
      !(isMobile || isTablet)
    );
  }

  render() {
    if (this.hideAppBanner()) {
      return null;
    }

    const wrapperClassName = `smartbanner-show smartbanner smartbanner-${this.deviceOSType}`;

    return (
      <div className={wrapperClassName}>
        <div className="smartbanner-container">
          <SafeForm
            className="smartbanner-close-container"
            action={this.props.appDownloadCookie}
            method="POST"
            onSubmit={this.close}
          >
            <input name="cookieName" type="hidden" value={SMARTBANNER_CLOSE} />
            <button
              className="icon-cross_blue_background smartbanner-close"
              title={this.props.t('common:close')}
              type="submit"
            />
          </SafeForm>
          <span className="smartbanner-icon" />
          <div className="smartbanner-info">
            <div className="smartbanner-title">
              {this.props.t('notifications:app-banner-download-title')}
            </div>
            <span>{this.retrieveAppStoreInfo()}</span>
          </div>
          <SafeForm
            className="smartbanner-button"
            action={this.props.appDownloadCookie}
            method="POST"
            onSubmit={this.install}
          >
            <input
              name="cookieName"
              type="hidden"
              value={SMARTBANNER_INSTALL}
            />
            <input name="appUrl" type="hidden" value={this.getAppUrl()} />
            <button
              ref="smartbannerInstall"
              className="button button-secondary smartbanner-button-text"
              title={this.props.t('common:close')}
              type="submit"
            >
              <span>
                {this.props.t('notifications:app-banner-download-text')}
              </span>
            </button>
          </SafeForm>
        </div>
      </div>
    );
  }
}

export default SmartBanner;
