import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Button from '@ddsweb/button';
import Link from '@ddsweb/link';
import { connect } from '#/lib/render/connect-deep-compare';
import SlotForm from '#/components/slots/slot-selector/available-slot/slot-form';
import SafeForm from '#/components/shared/safe-form';
import Checkbox from '#/components/shared/checkbox';
import {
  bookSlot,
  closeChangeOrderTypeModal,
  orderTypeChangeCookieAccepted
} from '#/actions/slot-action-creators';
import { CHANGING_DELIVERY_ADDRESS } from '#/constants/slot-warning-modal-types';
import { request } from '#/lib/client-fetch';
import { changeDeliveryAddress } from '#/actions/trolley/trolley-action-creators';
import analyticsBus from '#/analytics/analyticsBus';
import {
  ACCORDION_EXPAND,
  CHANGE_DELIVERY_POINT,
  DROPDOWN,
  NOW,
  UI_EVENT_BASIC_EVENT
} from '#/analytics/constants';
import { bookedSlotAnalyticsEvent } from '#/analytics/types/slot';
import * as warningModalTypes from '#/constants/slot-warning-modal-types';
import { SLOT_VIEW_MODE_URL_PARAM } from '#/constants/slot-views';
import { DELIVERY, ON_DEMAND } from '#/constants/shopping-methods';
import {
  getIsAmendBasket,
  getTrolleyDeliveryAddress
} from '#/selectors/trolley';
import {
  getQueryFromQueryStringFactory,
  getReturnUrlOrCurrentUrl
} from '#/reducers/app';
import { getAllAddresses } from '#/selectors/addresses';
import { getNewSlotGroupFromShoppingMethod } from '#/reducers/composed-selectors';
import {
  getCurrency,
  getCurrentUrl,
  getLanguage,
  getTimezone
} from '#/reducers/app';
import { getLocalDate, getSlotPageAnchorTag } from '#/lib/slot/slot-utils';
import { updateParamsInUrl } from '#/lib/url/url-utils';
import { scrollToElement } from '#/lib/browser/ui-util';
import slotRangeUtils from '#/lib/slot/slot-range-utils';
import helpers from '#/lib/decorators/helpers';
import {
  getSelectedShoppingMethod,
  getSlotsPendingStatus
} from '#/reducers/slot';
import { SHOPPING_METHOD_PROP_TYPE } from '#/components/slots/prop-types';
import { isOnDemandShoppingMethod } from '#/lib/shopping-method-util';
import ChangeAddressModalSlotBooked from '#/components/modals/change-address-modal-slot-booked';
import DialogContent from '#/components/modals/dialog-content/dialog-content';
import { formatSlotIntervalHumanized } from '#/lib/slot/slot-utils';
import { StyledModal } from '../styled';
import { setFocus } from '#/lib/browser/ui-util';
import { isDeliveryShoppingMethod } from '#/lib/shopping-method-util';
import {
  getCanAmendSlotChange,
  getIsOnDemandShoppingMethodSelected
} from '#/selectors/slot';
import { showSlotChangedInAmendWarning } from '#/actions/slot-action-creators';

const getQueryFromQueryStringSlotsView = getQueryFromQueryStringFactory(
  SLOT_VIEW_MODE_URL_PARAM
);

const mapStateToProps = state => {
  return {
    currency: getCurrency(state),
    currentUrl: getCurrentUrl(state),
    deliveryAddress: getTrolleyDeliveryAddress(state),
    deliveryAddresses: getAllAddresses(state),
    language: getLanguage(state),
    isAmendBasket: getIsAmendBasket(state),
    returnUrl: getReturnUrlOrCurrentUrl(state),
    shoppingMethod: getSelectedShoppingMethod(state),
    showOnDemandChangeAddressModal: getIsOnDemandShoppingMethodSelected(state),
    slotGroup: getNewSlotGroupFromShoppingMethod(state),
    slotViewMode: getQueryFromQueryStringSlotsView(state),
    slotsPendingStatus: getSlotsPendingStatus(state),
    canAmendSlotChange: getCanAmendSlotChange(state),
    timezone: getTimezone(state)
  };
};

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      changeDeliveryAddress,
      orderTypeChangeCookieAccepted,
      closeChangeOrderTypeModal,
      bookSlot,
      showSlotChangedInAmendWarning
    },
    dispatch
  ),
  dispatch
});

@connect(mapStateToProps, mapDispatchToProps)
@helpers(['c', 't', 'l', 'f'])
export default class ChangeSlotTypeModal extends Component {
  state = {
    hasAccepted: false
  };

  static propTypes = {
    addressId: PropTypes.string,
    bookSlot: PropTypes.func.isRequired,
    c: PropTypes.func.isRequired,
    callback: PropTypes.func,
    canAmendSlotChange: PropTypes.bool,
    changeDeliveryAddress: PropTypes.func.isRequired,
    closeChangeOrderTypeModal: PropTypes.func.isRequired,
    currency: PropTypes.object.isRequired,
    currentUrl: PropTypes.string.isRequired,
    deliveryAddress: PropTypes.object,
    deliveryAddresses: PropTypes.array,
    end: PropTypes.string,
    f: PropTypes.func.isRequired,
    l: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    locationId: PropTypes.string,
    modalType: PropTypes.string.isRequired,
    orderTypeChangeCookieAccepted: PropTypes.func.isRequired,
    returnUrl: PropTypes.string.isRequired,
    shoppingMethod: SHOPPING_METHOD_PROP_TYPE,
    showOnDemandChangeAddressModal: PropTypes.bool,
    showSlotChangedInAmendWarning: PropTypes.func.isRequired,
    slotGroup: PropTypes.number,
    slotId: PropTypes.string,
    slotsPendingStatus: PropTypes.bool.isRequired,
    slotToBeBooked: PropTypes.object,
    slotViewMode: PropTypes.string,
    start: PropTypes.string,
    t: PropTypes.func.isRequired,
    timezone: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.currency = props.currency;
    this.timezone = props.timezone;
    this.language = props.language;
    this.lastActiveElement = React.createRef();
    this.lastActiveElement.current =
      typeof document !== 'undefined' && document?.activeElement;
  }

  componentDidMount() {
    slotAddressChangeModalAnalytics();

    if (
      this.props.modalType === CHANGING_DELIVERY_ADDRESS &&
      this.props.slotToBeBooked
    ) {
      const slot = {
        ...this.props.slotToBeBooked,
        currency: this.currency,
        timezone: this.timezone,
        shoppingMethod: DELIVERY
      };

      bookedSlotAnalyticsEvent(slot);
    }
  }

  componentWillUnmount() {
    const focusTimeout = setTimeout(() => {
      setFocus(this.lastActiveElement.current);
      clearTimeout(focusTimeout);
    }, 0);
  }

  setCookie() {
    request.post(this.props.l('/set-cookie?_method=PUT'), {
      body: JSON.stringify({
        cookieName: this.props.modalType,
        cookieValue: '1'
      })
    });
  }

  closeAndSetCookie = () => {
    if (this.state.hasAccepted) {
      this.props.orderTypeChangeCookieAccepted(this.props.modalType);
      this.setCookie();
    }
  };

  onSubmit = async (event, slotStart, slotEnd, slotGroup) => {
    const { canAmendSlotChange } = this.props;
    let slotChangeInAmendMode = false;

    if (canAmendSlotChange) {
      event?.preventDefault();
      const { modalType, isAmendBasket } = this.props;

      slotChangeInAmendMode =
        modalType === warningModalTypes.SLOT_CHANGE && isAmendBasket;

      if (!slotChangeInAmendMode) {
        this.onClose(event, true, 'continue');
      }
    } else {
      this.onClose(event, true, 'continue');
    }

    if (this.props.modalType === warningModalTypes.CHANGE_DELIVERY_ADDRESS) {
      const response = await this.props.changeDeliveryAddress(
        this.props.addressId,
        slotStart,
        slotEnd,
        slotGroup
      );
      if (response) {
        analyticsBus().emit(UI_EVENT_BASIC_EVENT, {
          action: NOW,
          type: `${ACCORDION_EXPAND}:${CHANGE_DELIVERY_POINT}`,
          value: DROPDOWN
        });
      }
    } else {
      const { callback } = this.props;

      if (typeof callback === 'function') {
        callback();
        return;
      }

      this.props
        .bookSlot({
          ...this.props,
          currency: this.currency,
          timezone: this.timezone
        })
        .then(() => {
          if (canAmendSlotChange && slotChangeInAmendMode) {
            this.onClose(event, true, 'continue');
            this.props.showSlotChangedInAmendWarning();
          }
        });
    }
  };

  hasAccepted = event => {
    this.setState({
      hasAccepted: event.target.checked
    });
  };

  getKeys() {
    const { modalType, shoppingMethod, t: translate } = this.props;
    let postfix = '';
    if (isOnDemandShoppingMethod(shoppingMethod)) {
      postfix = `-${ON_DEMAND}`;
    }

    const keys = {};

    switch (modalType) {
      case warningModalTypes.ORDER_TYPE_CHANGE:
        keys.title = translate(`slots:changing-order-type${postfix}`);
        keys.body = translate(`slots:changing-order-type--warning${postfix}`);
        keys.yesButton = translate(`slots:changing-order-type--yes${postfix}`);

        return keys;
      case warningModalTypes.SLOT_CHANGE:
        keys.title = translate('slots:changing-slot-title');
        keys.body = translate('slots:change-slot--warning');
        keys.yesButton = translate('slots:change-slot--yes');

        return keys;
      case warningModalTypes.CHANGE_COLLECTION_LOCATION:
        keys.title = translate('slots:changing-collection-location');
        keys.body = translate('slots:order-type-change--warning');
        keys.yesButton = translate('slots:change-collection-location--yes');

        return keys;
      case warningModalTypes.CHANGE_DELIVERY_ADDRESS:
        keys.title = translate('slots:change-address-title');
        keys.body = translate('slots:change-address-body');
        keys.yesButton = translate('slots:change-address--yes');

        return keys;
      case warningModalTypes.BOOK_WHOOSH_SLOT:
        keys.title = translate('slots:ondemand.book-whoosh-modal-title');
        keys.body = translate(
          'slots:ondemand.book-whoosh-modal-product-warning'
        );
        keys.yesButton = translate('slots:ondemand.book-whoosh-modal-yes');

        return keys;
      default:
        return keys;
    }
  }

  onClose = (event, shouldSetCookie = false, actionValue) => {
    event?.preventDefault && event.preventDefault();
    this.handleModalClose(actionValue);
    shouldSetCookie && this.closeAndSetCookie();
  };

  scrollAndClose = (event, scrollToEl, shouldSetCookie = false) => {
    if (isDeliveryShoppingMethod(this.props.shoppingMethod)) {
      this.lastActiveElement.current = document.getElementsByClassName(
        'slot-selector--week-tabheader-link'
      )?.[0];
    }
    this.onClose(event, shouldSetCookie, 'cancel');
    scrollToElement(scrollToEl);
  };

  handleModalClose = (actionValue = 'cancel') => {
    const { shoppingMethod, modalType } = this.props;

    const isOrderTypeChanged =
      modalType === warningModalTypes.ORDER_TYPE_CHANGE;

    if (isOrderTypeChanged) {
      analyticsBus().emit('UIEventBasicEvent', {
        type: `${shoppingMethod}:changing shopping method`,
        value: actionValue,
        action: NOW
      });
    }
    this.props.closeChangeOrderTypeModal();
  };

  renderCloseLink() {
    return (
      <a
        className="icon-cancel"
        onClick={event => this.onClose(event, false, 'cancel')}
        href={this.props.returnUrl || this.props.currentUrl}
      >
        <span className="visually-hidden">{this.props.t('common:close')}</span>
      </a>
    );
  }

  renderSlotForm(slot, buttonText, slotPageAnchorTag) {
    const { slotEnd, slotGroup, slotStart } = slot;
    const {
      slotsPendingStatus,
      canAmendSlotChange,
      isAmendBasket
    } = this.props;
    slotPageAnchorTag = slotPageAnchorTag ? `#${slotPageAnchorTag}` : '';

    return (
      <SlotForm
        {...slot}
        returnUrl={`${this.props.returnUrl ||
          this.props.currentUrl}${slotPageAnchorTag}`}
        onSubmit={event => this.onSubmit(event, slotStart, slotEnd, slotGroup)}
        submitUrl={this.props.c('updateSlotUrl')}
        modalType={this.props.modalType}
      >
        <Button
          type="submit"
          data-auto="slot-continue-button"
          isLoading={canAmendSlotChange && isAmendBasket && slotsPendingStatus}
        >
          {buttonText}
        </Button>
      </SlotForm>
    );
  }

  renderAddressChangeForm(slot, buttonText) {
    const { slotEnd, slotGroup, slotStart } = slot;
    const submitUrl = this.props.c('changeAddressUrl');
    const { addressId, shoppingMethod } = this.props;
    const returnUrl = updateParamsInUrl(this.props.returnUrl, { slotGroup });

    return (
      <SafeForm
        action={this.props.l(submitUrl)}
        method="post"
        onSubmit={event => this.onSubmit(event, slotStart, slotEnd, slotGroup)}
      >
        <input type="hidden" name="addressId" value={addressId} />
        <input type="hidden" name="returnUrl" value={returnUrl} />
        <input
          type="hidden"
          name="modalType"
          value={CHANGING_DELIVERY_ADDRESS}
        />
        <input type="hidden" name="shoppingMethod" value={shoppingMethod} />
        <input type="hidden" name="start" value={slotStart} />
        <input type="hidden" name="end" value={slotEnd} />
        <input type="hidden" name="slotGroup" value={slotGroup} />
        <Button type="submit">{buttonText}</Button>
      </SafeForm>
    );
  }

  renderCloseButton(
    shouldSetCookie = false,
    scrollToEl = '',
    slotPageAnchorTag = '',
    buttonVariant = 'secondary',
    buttonText = null
  ) {
    const {
      canAmendSlotChange,
      isAmendBasket,
      slotsPendingStatus
    } = this.props;
    // non-js: {reload: true} here is to change url so as to close modal, but retain scroll anchor
    let returnUrl = updateParamsInUrl(
      this.props.returnUrl || this.props.currentUrl,
      {
        slotViewMode: this.props.slotViewMode,
        reload: true
      }
    );
    if (slotPageAnchorTag) {
      returnUrl = `${returnUrl}#${slotPageAnchorTag}`;
    }
    return (
      <Link
        disabled={canAmendSlotChange && isAmendBasket && slotsPendingStatus}
        data-auto="slot-cancel-button"
        buttonVariant={buttonVariant}
        href={returnUrl}
        variant="textButton"
        onClick={event =>
          this.onCloseButtonClick(event, shouldSetCookie, scrollToEl)
        }
      >
        {buttonText ?? this.props.t('slots:go-back')}
      </Link>
    );
  }

  renderCheckbox = () => {
    const { t: translate } = this.props;
    const keys = this.getKeys();

    if (!keys?.dontShow) return null;

    return (
      <Checkbox
        id="slot-change-warning"
        checked={this.state.hasAccepted}
        onChange={this.hasAccepted}
      >
        {keys?.dontShow ? translate(keys.dontShow) : ''}
      </Checkbox>
    );
  };

  onCloseButtonClick(event, shouldSetCookie, scrollToEl) {
    const {
      canAmendSlotChange,
      isAmendBasket,
      slotsPendingStatus
    } = this.props;

    if (canAmendSlotChange && isAmendBasket && slotsPendingStatus) {
      return;
    }
    scrollToEl
      ? this.scrollAndClose(event, scrollToEl)
      : this.onClose(event, shouldSetCookie, 'cancel');
  }

  changeAddressWarningContent(keys, slot) {
    const { modalType, slotViewMode } = this.props;

    const slotPageAnchorTag = getSlotPageAnchorTag(
      slotViewMode,
      slot.slotStart,
      slot.slotEnd
    );

    const primaryButton =
      modalType === warningModalTypes.CHANGE_DELIVERY_ADDRESS
        ? this.renderAddressChangeForm(slot, keys.yesButton)
        : this.renderSlotForm(slot, keys.yesButton, slotPageAnchorTag);

    const backButton = this.renderCloseButton(true, '', slotPageAnchorTag);

    const { title, body } = keys;

    return (
      <DialogContent
        title={title}
        body={body}
        primaryButton={primaryButton}
        backButton={backButton}
      />
    );
  }

  getNoSlotsAvailableContent() {
    const { title, body, button } = this.getWarningData();

    return <DialogContent title={title} body={body} primaryButton={button} />;
  }

  getWarningData() {
    const {
      start,
      end,
      c: config,
      t: translate,
      deliveryAddresses = [],
      deliveryAddress
    } = this.props;

    const address = deliveryAddresses.find(
      addr => addr.id === deliveryAddress.id
    );

    const { date, time } = formatSlotIntervalHumanized(
      start,
      end,
      this.timezone,
      this.language,
      config
    );

    const data = {
      deliveryAddressName: address.name,
      time,
      date
    };

    return {
      title: translate('slots:changing-address.sorry-title'),
      body: translate('slots:changing-address.sorry-message', data),
      button: this.renderCloseButton(
        false,
        'slot-matrix',
        '',
        'primary',
        translate('slots:changing-address.choose-new-slot')
      )
    };
  }

  getCurrentSlotUrl() {
    const { slotToBeBooked } = this.props;

    if (!slotToBeBooked) {
      return this.props.currentUrl;
    }

    const { l: link, slotGroup } = this.props;

    const selectedDate = slotRangeUtils.formatDate(
      getLocalDate(slotToBeBooked.start, this.timezone, this.language)
    );

    return `${link(
      '/slots'
    )}/${DELIVERY}/${selectedDate}?slotGroup=${slotGroup}`;
  }

  render() {
    const { showOnDemandChangeAddressModal, addressId } = this.props;
    if (
      this.props.modalType === CHANGING_DELIVERY_ADDRESS &&
      !this.props.start &&
      !this.props.end
    ) {
      // Don't display the CHANGING_DELIVERY_ADDRESS modal if we haven't a slot booked
      return null;
    }

    const translateKeys = this.getKeys();
    const showChangingAddressModal =
      this.props.modalType === CHANGING_DELIVERY_ADDRESS;
    let modalContent = null;
    let [shouldSetCookie, scrollToEl] = [false, ''];
    if (showChangingAddressModal) {
      modalContent = this.getNoSlotsAvailableContent();
    } else {
      const {
        shoppingMethod,
        slotId,
        start: slotStart,
        end: slotEnd,
        locationId,
        slotGroup
      } = this.props;

      const slot = {
        shoppingMethod,
        slotId,
        slotStart,
        slotEnd,
        locationId,
        slotGroup
      };

      modalContent = Object.keys(translateKeys).length
        ? this.changeAddressWarningContent(translateKeys, slot)
        : null;
      [shouldSetCookie, scrollToEl] = [true, ''];
    }

    if (!modalContent || typeof window === 'undefined') return null;

    if (
      showOnDemandChangeAddressModal &&
      this.props.modalType === warningModalTypes.CHANGE_DELIVERY_ADDRESS
    ) {
      return (
        <ChangeAddressModalSlotBooked
          addressId={addressId}
          closeModal={this.handleModalClose}
          dontShowAgainCheckbox={this.renderCheckbox()}
          handleGoBack={this.onClose}
        />
      );
    }
    return (
      <div data-testid="slot-change-modal-synthetics">
        <StyledModal
          className="order-type-change-modal"
          open
          onChange={event =>
            this.onCloseButtonClick(event, shouldSetCookie, scrollToEl)
          }
        >
          {modalContent}
        </StyledModal>
      </div>
    );
  }
}

function slotAddressChangeModalAnalytics() {
  analyticsBus().emit('errorData', {
    code: 0,
    text: 'slot address changed please confirm',
    type: 'action'
  });
}
