import styled from 'styled-components';
import { media } from '@ddsweb/foundation';
import { colors, spacing, fontSize } from '@beans/selectors';
import Button from '@ddsweb/button';
import Link from '@ddsweb/link';

export const StyledDSAcquisitionContainer = styled.div`
  color: ${colors.grayscale};
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content min-content min-content;
  grid-template-areas:
    'dsbannertext'
    'dsbannerbutton'
    'dsbannerfootnote';
  ${media.aboveMobileLarge`
    display: grid;
    grid-template-columns: 1fr min-content;
    grid-template-rows: min-content min-content;
    grid-template-areas: 
      'dsbannertext dsbannerbutton' 
      'dsbannerfootnote dsbannerbutton';
  `};

  .ds-acquisition-banner__text {
    grid-area: dsbannertext;
    margin: 0 0 ${spacing.sm};
    font-size: ${fontSize.xs};

    ${media.aboveMobileLarge`
      margin: 0 0 ${spacing.xs} 0;
    `};
  }

  .oop-2141_ds-acquisition-banner__footnote {
    grid-area: dsbannerfootnote;
    font-size: calc(${fontSize.xs} - 2px);
    color: ${colors.grayscale};
    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .oop-2141_ds-acquisition-banner__button {
    grid-area: dsbannerbutton;
    justify-self: end;
    align-self: stretch;
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    margin: 0 0 0 ${spacing.lg};
    ${media.belowMobileLarge`
      justify-self: stretch;
      margin: 0 0 ${spacing.sm} 0;
      justify-content: stretch
    `};
  }
`;

export const StyledCloseButton = styled(Button)`
  color: ${colors.primary};
`;

export const StyledLink = styled(Link)`
  font-size: ${fontSize.xs};

  ${media.belowMobileLarge`
    width: 100%
  `};
`;

export const StyledDeliverySaverSlotContainer = styled.div`
  border-bottom: 0;
  padding: 0 ${spacing.sm} ${spacing.sm};
`;
