import React from 'react';
import { Item } from '#/lib/records/item';
import { TConfigFunc, TFeatureFunc, TTranslateFunc } from '#/lib/records/helpers.defs';

import { VERTICAL } from '#/components/product-tiles/common/constants';
import helpers from '#/lib/decorators/helpers';
import { itemsToMap, transferItems } from '#/lib/records/product-utils';
import { compose } from 'react-recompose';
import { StyledContentListItem, StyledListItem, StyledShoppableTilesWrapper } from './styled';
import { TradeStamp } from '../trade_stamp';
import { TradeStamp as TradeStampType } from './types';
import FlexiTile from '#/components/product-tiles/vertical-tile/variant/flexi-tile';
import { getItems } from '#/selectors/trolley';
import { connect } from 'react-redux';

type StateProps = {
  trolleyItems: Array<Item>;
};

type Props = StateProps & {
  productList: Array<Item>;
  tradeStamp: TradeStampType;
  c: TConfigFunc;
  f: TFeatureFunc;
  t: TTranslateFunc;
};

const mapStateToProps = (state: Store): StateProps => ({
  trolleyItems: getItems(state),
});

const connector = connect(mapStateToProps, null);

const ShoppableTilesWrapper = (props: Props): JSX.Element => {
  const { productList, tradeStamp, trolleyItems, c, f, t } = props;
  const items = itemsToMap(transferItems(productList, trolleyItems));
  const itemsArray = Array.isArray(items) ? items : Array.from(items.values());
  return (
    <StyledShoppableTilesWrapper>
      {itemsArray?.map(item => (
        <StyledListItem key={item?.product?.id}>
          <FlexiTile
            className="dcs-flexi-tile"
            item={item}
            tileVariant={VERTICAL}
            config={c}
            translate={t}
            feature={f}
          />
        </StyledListItem>
      ))}
      <StyledContentListItem>
        <TradeStamp {...tradeStamp.props} />
      </StyledContentListItem>
    </StyledShoppableTilesWrapper>
  );
};

const enhance = compose(helpers(['f', 't', 'c']));
export default enhance(connector(ShoppableTilesWrapper));
