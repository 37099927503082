import React, { SyntheticEvent, useRef } from 'react';
import { compose } from 'react-recompose';
import { SECONDARY } from '@ddsweb/constants';
import Button from '@ddsweb/button';
import { connect } from '#/lib/render/connect-deep-compare';
import helpers from '#/lib/decorators/helpers';
import { TTranslateFunc } from '#/lib/records/helpers.defs';
import analyticsBus from '#/analytics/analyticsBus';
import {
  ButtonContainer,
  Content,
  StyledHeading,
  StyledBodyText,
  StyledModal,
  StyledCheckbox,
} from '#/components/modals/change-address-modal-slot-booked/styled';
import { getNewSlotGroupFromShoppingMethod } from '#/reducers/composed-selectors';
import { changeDeliveryAddress } from '#/actions/trolley/trolley-action-creators';
import { UI_EVENT_BASIC_EVENT, NOW, ACCORDION_EXPAND, CHANGE_DELIVERY_POINT, DROPDOWN } from '#/analytics/constants';

type OwnProps = {
  addressId: string;
  changeDeliveryAddress: (addressId: string, slotStart: string, slotEnd: string, slotGroup: number) => Promise<string>;
  closeModal: () => void;
  dontShowAgainCheckbox: JSX.Element;
  handleGoBack: (e: SyntheticEvent, shouldSetCookie: boolean, action: string) => void;
  slotGroup: number;
  t: TTranslateFunc;
};

type ModalOnChangePayload = { action: string; open: boolean; id: string };

type OwnState = { slotGroup: number | null };

const mapDispatchToProps = {
  changeDeliveryAddress,
};

const mapStateToProps = (state: Store): OwnState => ({
  slotGroup: getNewSlotGroupFromShoppingMethod(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = OwnProps & OwnState;

const ChangeAddressModalSlotBooked = (props: Props): JSX.Element => {
  const {
    closeModal,
    t: translate,
    dontShowAgainCheckbox,
    handleGoBack,
    addressId,
    slotGroup,
    changeDeliveryAddress,
  } = props;
  const tPrefix = `modals:change-address-modal-slot-booked`;
  const lastActiveElement = useRef<HTMLElement>(document.activeElement as HTMLElement);

  const setFocusToDropdown = (): void => lastActiveElement?.current?.focus();

  const handleAddressChange = async (e: SyntheticEvent): Promise<void> => {
    onGoBackClick(e);
    const response = await changeDeliveryAddress(addressId, '', '', slotGroup);

    if (response) {
      analyticsBus().emit(UI_EVENT_BASIC_EVENT, {
        action: NOW,
        type: `${ACCORDION_EXPAND}:${CHANGE_DELIVERY_POINT}`,
        value: DROPDOWN,
      });
    }
  };

  const cancelButtonHandler = (payload: ModalOnChangePayload): void => {
    if (payload.action === 'close' && payload.open === true) {
      setFocusToDropdown();
    }
    closeModal();
  };

  const onGoBackClick = (e: SyntheticEvent): void => {
    handleGoBack(e, true, 'cancel');
    setFocusToDropdown();
  };

  const renderFooter = (): JSX.Element => {
    return (
      <ButtonContainer>
        <Button variant={SECONDARY} onClick={onGoBackClick}>
          {translate(`${tPrefix}.go-back`)}
        </Button>
        <Button onClick={handleAddressChange}>{translate(`${tPrefix}.change-address`)}</Button>
      </ButtonContainer>
    );
  };

  return (
    <StyledModal id="change-address-modal-slot-booked" open ssr persist onChange={cancelButtonHandler}>
      <Content>
        <StyledHeading>{translate(`${tPrefix}.title`)}</StyledHeading>
        <StyledBodyText>{translate(`${tPrefix}.description-1`)}</StyledBodyText>
        <StyledBodyText>{translate(`${tPrefix}.description-2`)}</StyledBodyText>
      </Content>
      {renderFooter()}
      <StyledCheckbox>{dontShowAgainCheckbox}</StyledCheckbox>
    </StyledModal>
  );
};

const enhance = compose(helpers(['t']), connector);
export default enhance(ChangeAddressModalSlotBooked);
