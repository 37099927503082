import { Items } from '#/custom-typings/redux-store/trolley.defs';
import { Item } from '#/lib/records/item';
import { getById } from '#/lib/records/product-utils';
import { getProductId } from '#/selectors/item';

/**
 *  Transfers items found in one collection into a base collection
 *
 * @param {Map} baseItems Base map of items.
 * @param {Map} items Map of items to transfer from.
 */
export default function transferItems(baseItems: Items | Array<Item>, items: Items | Array<Item>): Items {
  const newItems = new Map();

  baseItems.forEach((item: Item) => {
    const id = getProductId(item);
    newItems.set(id, getById(items, id) || item);
  });

  return newItems;
}
