import { shouldShowRestOfShelf, generateSlugs } from '#/lib/url/url-utils';
import { getAisleNameFromItem } from '#/reducers/results';
import { getCurrentPathname } from '#/reducers/app';
import {
  getProductShelfId,
  getProductShelfName,
  getProductAisleId,
  getRestOfShelfUrl,
  getProductIsForSale,
  hasSubstitution,
} from '#/selectors/item';
import { getLanguageLink } from '#/reducers/app';
import { Item } from '#/lib/records/item';
import { TConfigFunc, TTranslateFunc } from '#/lib/records/helpers.defs';
import { getShouldShowUnavailablePrice } from '../trolley';

export type TRestOfShelfData = {
  href: string;
  linkText: string;
} | null;

export const getRestOfShelfLinkData = (
  state: any,
  item: Item,
  translate: TTranslateFunc,
  config: TConfigFunc,
): TRestOfShelfData => {
  const isAvailableOrHasSub = getProductIsForSale(item) || hasSubstitution(item);
  const disableRestOfShelfLink = config('product:disableRestOfShelfLink');

  if (disableRestOfShelfLink || (!isAvailableOrHasSub && getShouldShowUnavailablePrice(state, config))) return null;

  const aisleName = getAisleNameFromItem(state, item);
  const aisleId = getProductAisleId(item);
  const restOfShelfUrl = getRestOfShelfUrl(item);
  const shelfId = getProductShelfId(item);
  const shelfName = getProductShelfName(item);
  const { shelfIdSlug, aisleIdSlug } = generateSlugs(aisleName, shelfName, aisleId);
  const currentPathname = decodeURIComponent(getCurrentPathname(state));

  if (shouldShowRestOfShelf(currentPathname, shelfId || aisleId, shelfIdSlug, aisleIdSlug)) {
    let restOfCatUrl;
    let restOfCatName;
    if (shelfId && shelfName) {
      restOfCatUrl = getLanguageLink(state, restOfShelfUrl || `categories/${shelfId}`);
      restOfCatName = shelfName;
    } else if (aisleId) {
      restOfCatUrl = getLanguageLink(state, `categories/${aisleId}`);
      restOfCatName = aisleName || '';
    } else {
      return null;
    }

    const linkText = `${translate('product-tile:rest-of-shelf-long', {
      shelfName: restOfCatName,
    })}`;
    const href = getLanguageLink(state, restOfCatUrl);

    return {
      href,
      linkText,
    };
  }

  return null;
};
