import { ClientFetchError } from '#/lib/errors/client-fetch-error';
import { timeoutPromise } from '../utils/misc';
import { logApiRequestEvent } from '#/lib/apm';
import { parse } from 'url';

// Have to retrieve this manually for now. We can't really use the helpers
// as they are no longer global and live in the React context. It's either this
// or pass it in every call we make.
const getCsrfToken = (() => {
  let csrfFromDoc = '';

  if (process.env.CLIENT_SIDE) {
    csrfFromDoc = document.body.getAttribute('data-csrf-token');
  }

  return () => window.csrfToken || csrfFromDoc;
})();

const getTescoHeaders = isCorsRequest => {
  const defaultHeaders = {
    'X-Requested-With': 'XMLHttpRequest',
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };

  if (isCorsRequest) {
    return defaultHeaders;
  }

  return {
    ...defaultHeaders,
    'x-csrf-token': getCsrfToken()
  };
};

function onError(res, extraInfo) {
  const { message, name, stack, status } = res;

  return Promise.reject(
    new ClientFetchError(message || 'Something went wrong', status || 503, {
      ...extraInfo,
      originalErrorInfo: {
        message: message && String(message),
        name: name && String(name),
        stack: stack && String(stack),
        response: JSON.stringify(res)
      }
    })
  );
}

async function parseResponseBody(resp) {
  // we are not certain 204s will return a content-type, e.g. empty basket
  // so check for 204 and return empty object.
  if (resp.status === 204) {
    resp.parsedBody = {};

    return resp;
  }

  const contentTypes = resp.headers.get('Content-Type') || '';
  const contentType = contentTypes.split(';')[0];

  switch (contentType) {
    case 'application/json':
      resp.parsedBody = await resp.json();
      break;
    case 'text/plain':
    case 'text/html':
      resp.parsedBody = await resp.text();
      break;
    case 'application/x-www-form-urlencoded':
      resp.parsedBody = await resp.formData();
      break;
    default:
      // eslint-disable-next-line no-console
      console.error(
        `[client-fetch] Unsupported response format: ${contentType}`
      );

      throw new Error(
        `[client-fetch] Unsupported response format: ${contentType}`
      );
  }

  return resp;
}

function rejectDenylistedStatusCodes(resp) {
  const STATUSES_TO_REJECT = [301, 302, 307, 401, 403, 404, 412, 419, 500];

  if (STATUSES_TO_REJECT.includes(resp.status) || resp.status > 500) {
    return Promise.reject(resp);
  }

  return resp;
}

function checkForWaitingRoomRedirectionHeader(response) {
  const waitingRoomRedirectionUrl = response.headers.get('x-queueit-redirect');

  if (waitingRoomRedirectionUrl) {
    const error = new Error('waitingRoomRedirectionHandler');
    error.waitingRoomRedirectionUrl = decodeURIComponent(
      waitingRoomRedirectionUrl
    );
    throw error;
  }
  return response;
}
/**
 * Builds the fetch request augmenting it with required tesco customizations.
 * @param {string} path
 * @param {Object} options
 */
function fetchBuilder(path, options) {
  const defaultHeaders = getTescoHeaders(options.corsRequest);

  options.credentials = options.credentials || 'include';
  options.headers = options.headers
    ? { ...defaultHeaders, ...options.headers }
    : defaultHeaders;

  if (window && !window.navigator.onLine) {
    return Promise.reject(
      new ClientFetchError('Network Error: User is offline', 503)
    );
  }
  const timeInMilliseconds = 40000;
  const start = Date.now();

  const { pathname: requestPath, host: requestHost } = parse(path);

  const {
    atrc,
    method: requestMethod,
    requestName,
    trace_id: traceId
  } = options;

  return timeoutPromise(
    window.fetch(path, options),
    timeInMilliseconds,
    new ClientFetchError(`Request Timed out after ${timeInMilliseconds}ms`, 503)
  )
    .catch(error =>
      onError(error, {
        path,
        traceId: traceId || options?.headers?.traceId,
        atrc: atrc || options?.headers?.atrc
      })
    )
    .then(checkForWaitingRoomRedirectionHeader)
    .then(parseResponseBody)
    .then(response => {
      const responseTimeMs = Date.now() - start;

      // we filter on named requests to exclude client calls to lego server
      if (requestName !== undefined && requestName !== null) {
        const eventData = {
          apiRequestTraceId: options?.headers?.traceId,
          atrc: atrc || options?.headers?.atrc || null,
          region: options?.headers?.region,
          requestHost,
          requestMethod,
          requestName,
          requestPath,
          responseStatus: response?.status,
          responseTimeMs,
          traceId: traceId || options?.headers?.traceId || null
        };

        logApiRequestEvent(eventData);
      }
      return response;
    })
    .then(rejectDenylistedStatusCodes)
    .then(resp => resp.parsedBody);
}

export const request = {
  get: (path, options) => fetchBuilder(path, { method: 'GET', ...options }),
  put: (path, options) => fetchBuilder(path, { method: 'PUT', ...options }),
  post: (path, options) => fetchBuilder(path, { method: 'POST', ...options }),
  del: (path, options) => fetchBuilder(path, { method: 'DELETE', ...options })
};
