import React from 'react';
import PropTypes from 'prop-types';

const ButtonContainer = props => (
  <div className="modal-actions-button-container">{props.children}</div>
);

ButtonContainer.propTypes = {
  children: PropTypes.node
};

export default ButtonContainer;
