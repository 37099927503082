import { basicEvent } from '#/analytics/types/basic';
import analyticsBus from '#/analytics/analyticsBus';
import { ALL_DEPARTMENTS, DELAY, NOW, SUPERDEPARTMENT, SUPERDEPARTMENTS, TOP_NAV } from '#/analytics/constants';
import { PRIMARY_NAV_ALL_DEPT_MENU_ID } from '../constants';

/**
 * Constructs an analytics value string for leaf menu items in the 'All Departments' menu.
 *
 * This function processes a hierarchical menu item identifier (elementId) by:
 * 1. Removing the 'primary-nav-all-departments::' prefix.
 * 2. Splitting the remaining string by '::' and extracting the values after '|'.
 * 3. Replacing the last segment of the identifier with the provided 'text', converted to lowercase.
 * 4. Joining the modified segments with ':' and prepending 'all departments:' to form the final string.
 *
 * For ex:
 *  Converts 'primary-nav-all-departments::superDepartment|fresh food::superDepartment|fresh food'
 *  with the 'itemText' as 'all fresh food' to 'all departments:fresh food:all fresh food'
 *
 */
const constructAnalyticsValueForLeafItems = (elementId: string, text: string): string => {
  let analyticsValue = '';
  const COLON = ':';
  const DOUBLE_COLON = '::';
  const PIPE = '|';

  analyticsValue = elementId.replace(`${PRIMARY_NAV_ALL_DEPT_MENU_ID}${DOUBLE_COLON}`, '');

  const parts = analyticsValue.split(DOUBLE_COLON).map(part => part.split(PIPE)[1]);
  parts[parts.length - 1] = text;

  return `${ALL_DEPARTMENTS}${COLON}${parts.join(COLON)}`;
};

/**
 * Fires analytics event when a menu item of first level menu (ie, superdepartment menu items)
 * or a leaf menu item (ie, a menu item with no further sub-menus) of the 'All Departments' menu
 * is clicked.
 */

export const fireAnalyticsForAllDepartmentsMenuItem = ({
  id,
  name,
  innerText,
  label,
  hasPopup,
}: {
  id: string;
  name: string;
  innerText: string;
  label: string;
  hasPopup: boolean;
}): void => {
  if (!id.includes(PRIMARY_NAV_ALL_DEPT_MENU_ID)) {
    return;
  }

  const isLeafMenuItem = !hasPopup;
  const isFirstLevelMenuItem = !isLeafMenuItem && (label === SUPERDEPARTMENT || label === SUPERDEPARTMENTS);

  if (isLeafMenuItem) {
    basicEvent(analyticsBus, {
      type: TOP_NAV,
      value: constructAnalyticsValueForLeafItems(id, innerText),
      action: DELAY,
    });
  } else if (isFirstLevelMenuItem) {
    basicEvent(analyticsBus, {
      type: TOP_NAV,
      value: `${ALL_DEPARTMENTS}:${name}`,
      action: NOW,
    });
  }
};
