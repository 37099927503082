import { getFeatureVariableStringFromState } from '#/lib/optimizely-manager';
import { isDeliveryShoppingMethod } from '#/lib/shopping-method-util';
import { getSelectedShoppingMethod } from '#/reducers/slot';
import { dsBannerVariants } from '../constants';

const {
  featureKey,
  variationKey,
  useCustomerId,
  variants: { default: defaultVariant },
} = dsBannerVariants;

export const getDsBannerOnSlotMatrixVariant = (state: Store): string => {
  const shoppingMethod = getSelectedShoppingMethod(state);

  const variant = isDeliveryShoppingMethod(shoppingMethod)
    ? getFeatureVariableStringFromState(state, featureKey, variationKey, { useCustomerId })
    : defaultVariant;
  return variant ?? defaultVariant;
};
