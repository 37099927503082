import { UI_EVENT_BASIC_EVENT } from '#/analytics/constants';

export type TBasicEventProps = {
  action?: string;
  type: string;
  menuType?: string;
  value?: string | number;
  orderNo?: string;
};

export function basicEvent(bertie: Function, props: TBasicEventProps) {
  bertie().emit(UI_EVENT_BASIC_EVENT, props);
}
