import React from 'react';
import PropTypes from 'prop-types';
import Link from '../link';

const FavoritesTabHeader = ({
  ariaControls,
  iconClass,
  isActive,
  mainTextLong,
  subText,
  url,
  onKeyPressed
}) => {
  return (
    <Link
      to={url}
      href={url}
      disabled={isActive}
      disableAnalytics={true}
      role="tab"
      tabIndex={isActive ? '0' : '-1'}
      aria-selected={isActive ? 'true' : 'false'}
      aria-controls={`${ariaControls}-tab`}
      id={ariaControls}
      onKeyPressed={onKeyPressed}
    >
      <div className="favorites-tab-header__icon-wrapper">
        <span className={`favorites-tab-header__icon ${iconClass}`} />
      </div>
      <div>
        <h2 className="favorites-tab-header__main-text favorites-tab-header__main-text--long">
          {mainTextLong}
        </h2>
        <span className="favorites-tab-header__sub-text">{subText}</span>
      </div>
    </Link>
  );
};

FavoritesTabHeader.propTypes = {
  ariaControls: PropTypes.string,
  iconClass: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  mainTextLong: PropTypes.string.isRequired,
  onKeyPressed: PropTypes.func.isRequired,
  subText: PropTypes.string,
  url: PropTypes.string.isRequired
};

export default FavoritesTabHeader;
