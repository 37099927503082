import React from 'react';
import { connect } from '#/lib/render/connect-deep-compare';
import { getSelectedShoppingMethod } from '#/reducers/slot';
import { getIsTrolleyUpdating } from '#/selectors/trolley';
import OverlaySpinner from '#/components/overlay-spinner';
import SlotContextCardContainer from '#/components/pages/book-a-slot/slot-context-card-section/slot-context-card-container';
import { TrolleyState } from '#/custom-typings/redux-store/trolley.defs';
import { StyledDiv } from './styled';

interface StateProps {
  selectedShoppingMethod: TrolleyState['shoppingMethod'];
  isTrolleyPending: boolean;
}

interface TOwnProps extends StateProps {
  onDemandSlotExpiredCB: () => void;
  isSlotExpired: boolean;
}

const mapStateToProps = (state: Store): StateProps => ({
  selectedShoppingMethod: getSelectedShoppingMethod(state),
  isTrolleyPending: getIsTrolleyUpdating(state),
});

const SlotContextCardSection: React.FC<TOwnProps> = (props: TOwnProps) => {
  const { isTrolleyPending, onDemandSlotExpiredCB, isSlotExpired } = props;

  return (
    <StyledDiv>
      <SlotContextCardContainer onDemandSlotExpiredCB={onDemandSlotExpiredCB} isSlotExpired={isSlotExpired} />
      <OverlaySpinner isLoading={isTrolleyPending} />
    </StyledDiv>
  );
};

export default connect(mapStateToProps)(SlotContextCardSection);
