import { formatToShortDate } from '#/lib/i18n/date-utils';
import { getItemPromotionLink } from '#/lib/records/item-utils';
import { getLanguageLink, getCurrentUrl, getTimezone, getLanguage, getCurrency } from '#/reducers/app';
import { getModifiedPromotion } from '#/selectors/get-inclusive-end-date-promotions';
import {
  hasPromotion,
  getProductIsForSale,
  getPromotions,
  showClubcardPriceInPromotionMessages,
  getIsMarketplaceProduct,
} from '#/selectors/item';
import { toJS } from '#/utils/immutable-utils';
import { Item } from '#/lib/records/item';
import { getPromotionDateDisplayVariantWithState } from '#/experiments/oop-892/selectors';
import { promotionDateDisplayFeature } from '#/experiments/oop-892/constants';
import { hasValidSlot } from '#/selectors/trolley/slots';
import { TConfigFunc, TTranslateFunc } from '#/lib/records/helpers.defs';
import { Promotion } from '#/lib/records/item.defs';

const getPromotionLink = (state: Store, currentUrl: string, promotion: Promotion): string | null => {
  const promotionLink = getLanguageLink(state, getItemPromotionLink(promotion));

  return currentUrl === promotionLink ? null : promotionLink;
};

export type TFlashSashInfo = {
  text: string;
  color?: string;
  type: string;
};

type TPromotion = {
  link: string | undefined;
  terms: string | undefined;
  text: string;
  missedPromotion?: boolean | null;
  promotionMessage: string;
  unitSellingInfo?: string;
  isMarketPlaceProduct?: boolean;
};

export type TPromotionsData = TPromotion[];

export const getPromotionsData = (
  state: Store,
  item: Item,
  translation: TTranslateFunc,
  config: TConfigFunc,
): TPromotionsData => {
  if (!hasPromotion(item) || !getProductIsForSale(item)) return [];

  const promotions = toJS(getPromotions(item));
  const oop892variant = getPromotionDateDisplayVariantWithState(state); // FIXME: Fetch Experiment code runs for each item

  // FIXME: THIS WOULD NOT CHANGE for individual item in a page.
  const validSlotBooked = hasValidSlot(state);
  const currentUrl = getCurrentUrl(state);
  const timezone = getTimezone(state);
  const currency = getCurrency(state);
  const language = getLanguage(state);

  const showReconstructedOfferMsg = (config('showReconstructedOfferMsg') as boolean) || false;
  const reconstructOfferArgs = { showReconstructedOfferMsg, symbol: currency.symbol };

  const showPromoDateTermText =
    oop892variant === promotionDateDisplayFeature.variants.showActivePromotionsWithoutDateRanges
      ? !validSlotBooked
      : true;

  const isMarketPlaceProduct = getIsMarketplaceProduct(item);

  return promotions.map((promotion: Promotion) => {
    const promotionInclusive = getModifiedPromotion(promotion, timezone, reconstructOfferArgs);

    const start = formatToShortDate(promotionInclusive.startDate, timezone, language);
    const time = formatToShortDate(promotionInclusive.endDate, timezone, language);
    const shouldTranslateStart = start && (config('showPromotionStartDate') as boolean);
    const disablePromotionLink = config('product:disablePromotionLink');

    const { missedPromotion, offerText, unitSellingInfo } = promotionInclusive;

    let promotionMessage;
    if (missedPromotion) {
      promotionMessage = translation('common:pages.missed-promotions');
    } else if (showClubcardPriceInPromotionMessages(promotionInclusive, config)) {
      promotionMessage = translation('promotions:clubcard-price');
    } else {
      promotionMessage = translation('promotions:offer-complete');
    }

    let promotionTerms: string | null = null;
    let promotionText: string = offerText;

    if (showPromoDateTermText) {
      promotionTerms = translation('product-tile:valid-until', shouldTranslateStart ? { start, time } : { time });
    }
    if (isMarketPlaceProduct) {
      const beforeDiscount = `${currency?.symbol}${promotion.price?.beforeDiscount}`;
      const afterDiscount = `${currency?.symbol}${promotion.price?.afterDiscount}`;

      promotionText = translation('product-tile:offer-text', { beforeDiscount, afterDiscount });
      promotionTerms = translation('product-tile:offer-valid-until', { time });
    }

    return {
      link: disablePromotionLink ? null : getPromotionLink(state, currentUrl, promotionInclusive),
      terms: promotionTerms,
      text: promotionText,
      missedPromotion,
      promotionMessage,
      unitSellingInfo,
      isMarketPlaceProduct,
    };
  });
};
