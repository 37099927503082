import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import url from 'url';
import Feedback from '#/components/shared/feedback';
import FooterLink from '#/components/footer/footer-link';
import { connect } from '#/lib/render/connect-deep-compare';
import { getLanguage } from '#/reducers/app';
import { getSlug } from '#/lib/url/url-utils';
import helpers from '#/lib/decorators/helpers';
import { getShowAltSitemapTitle } from '#/experiments/oop-1646/selectors';

const mapStateToProps = state => ({
  language: getLanguage(state),
  showAltSitemapTitle: getShowAltSitemapTitle(state)
});

@connect(mapStateToProps)
@helpers(['t', 'l', 'c', 'f', 'externalSecureLink'])
export default class FooterList extends PureComponent {
  static propTypes = {
    c: PropTypes.func.isRequired,
    externalSecureLink: PropTypes.func.isRequired,
    f: PropTypes.func.isRequired,
    l: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    links: PropTypes.arrayOf(PropTypes.object).isRequired,
    shouldExpand: PropTypes.bool,
    t: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      closed: !props.shouldExpand
    };
  }

  handleHeaderClick = () => {
    this.setState(prevState => ({ closed: !prevState.closed }));
  };

  isExternalAddress = href => {
    try {
      const parsedUrl = url.parse(href);
      return !!parsedUrl.protocol;
    } catch (err) {
      return true;
    }
  };

  getLinkInfo(link) {
    const {
      c: config,
      externalSecureLink,
      language,
      l: languageLink
    } = this.props;

    let href = config([link.href, language]); // Fetch href from config using cascading specificity
    let isExternal = false;

    if (typeof href === 'string') {
      href = link.externalSecureDomain ? externalSecureLink(href) : href;
      isExternal = this.isExternalAddress(href); // Is link address external?
      href = isExternal ? href : languageLink(href, 'grocery');
    } else {
      href = undefined;
    }

    return { href, isExternal };
  }

  getLinkTranslatedText(link) {
    const { c: config, t: translate } = this.props;
    const { data, text } = link;

    return data ? config(text) : translate(text);
  }

  renderLinkItem(link) {
    const { f: feature, showAltSitemapTitle } = this.props;
    const { text } = link;

    if (text === 'common:feedback') {
      return (
        feature('feedbackLinks') && (
          <Feedback
            className="footer-list-item"
            tag="li"
            key={text}
            location="footer"
          >
            <FooterLink link={link} />
          </Feedback>
        )
      );
    }

    // Temporary experiment test
    // https://jira.global.tesco.org/browse/LEGO-32438
    const experimentLink = { ...link };
    if (text === 'footer:sitemap' && showAltSitemapTitle) {
      experimentLink.translatedText = 'Site map';
    }

    return (
      <li className="footer-list-item" key={text}>
        <FooterLink link={experimentLink} />
      </li>
    );
  }

  renderLinks() {
    const { links } = this.props;

    return links.map(link => {
      const { href, isExternal } = this.getLinkInfo(link);
      const translatedText = this.getLinkTranslatedText(link);

      if (!href) {
        return null;
      }

      return this.renderLinkItem({
        ...link,
        href,
        isExternal,
        translatedText
      });
    });
  }

  render() {
    const { shouldExpand, t: translate, title } = this.props;
    const { closed } = this.state;

    return (
      <section
        className={`footer-list ${
          closed ? 'footer-list--closed ' : ''
        }${getSlug(title)}`}
      >
        <h4 className="footer-list__title" onClick={this.handleHeaderClick}>
          {translate(title)}
          {!shouldExpand && (
            <span className="footer-list__chevron icon-chevron_down-gray" />
          )}
        </h4>
        <ul className="footer-list__list">{this.renderLinks()}</ul>
      </section>
    );
  }
}
