import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from '#/lib/render/connect-deep-compare';
import classnames from 'classnames';
import Accordion from '#/components/accordion';
import LinkButtonWithFeedback from '#/components/shared/link-button-with-feedback';
import helpers from '#/lib/decorators/helpers';
import { ORDER_CONFIRMATION as ORDER_CONFIRMATION_PATH } from '#/constants/common';
import { SHOW_ONE_CHECKOUT_ORDER_CONFIRMATION_CONFIG } from '#/constants/checkout';
import OrderSummaryLineItem from '#/components/trolley/order-summary-line-item';
import ItemsInBasketMessage from '#/components/trolley/trolley-order-summary/items-in-basket-message';
import OverlaySpinner from '#/components/overlay-spinner';
import {
  TROLLEY,
  HAVE_YOU_FORGOTTEN,
  CHECKOUT_RECOMMENDATIONS,
  ORDER_DETAILS,
  ORDER_SUMMARY,
  ORDER_CONFIRMATION,
  PENDING_ORDER
} from '#/constants/page-names';
import Link from '#/components/link-check-spa';
import Price from '#/components/shared/price';
import Icon from '#/components/shared/icon';
import analyticsBus from '#/analytics/analyticsBus';
import {
  getCurrentValidSlot,
  getFreeDeliveryStatus,
  getGroceryBasket,
  getIsAmendBasket,
  getFreeDeliveryThresholds
} from '#/selectors/trolley';
import Warning from '#/components/trolley/trolley-warnings/warnings';
import {
  getLanguage,
  getTimezone,
  getCurrentUrl,
  getLanguageLink
} from '#/reducers/app';
import AmendInfoMessage from '#/components/trolley/trolley-order-summary/amend-info';
import { getIsUserAuthenticated } from '#/reducers/user';
import { getBasketMaxItemCountLimit } from '#/selectors/trolley';
import { QUALIFY_FOR_FREE_DELIVERY } from '#/constants/spend-threshold';
import { SHOPPING_METHOD_PROP_TYPE } from '#/components/slots/prop-types';
import {
  isOnDemandShoppingMethod,
  isClickAndCollectShoppingMethod
} from '#/lib/shopping-method-util';
import { getHideBaggingOptionsFeature } from '#/selectors/bagging-options';
import { emitContentInteractOp } from '#/analytics/bertie/events';
import PaymentOptionsButtons from '#/components/checkout/payment-options-buttons';
import AmendPaymentOptionButton from '#/components/checkout/amend-payment-option-button';
import {
  getMarketplaceBasket,
  getHasOnlyMarketplaceItems,
  hasValidSlot
} from '#/selectors/trolley';
import BasketSummaryContent from './basket-summary-content';
import { BasketSavings } from '#/components/basket-savings';
import ContinueCheckoutButtonWrapper from '#/components/continue-checkout-wrapper';
import { isInlineMessagingEnabled } from '#/experiments/oop-1725/selectors';
import { ReferrerPolicies } from '#/constants/referrer-policy';
import { updateParamsInUrl } from '#/lib/url/url-utils';
import {
  getAmendBannerPositionStyleVariant,
  getOop1882cdeSaveButton
} from '#/experiments/oop-1882/selectors';
import FreeDeliveryMessage from './free-delivery';

const mapStateToProps = (state, { f: feature, shoppingMethod, c: config }) => {
  const isFDOXMessagingEnabled = feature('showFDOXMessaging');
  const hasSlot = hasValidSlot(state);
  const currentUrl = getCurrentUrl(state);
  const isAmendOrderAvailable = !isOnDemandShoppingMethod(shoppingMethod);
  const isCollection = isClickAndCollectShoppingMethod(shoppingMethod);
  const inlineMessagingEnabled = isInlineMessagingEnabled(state);
  const showOneCheckoutConfirmation = config(
    SHOW_ONE_CHECKOUT_ORDER_CONFIRMATION_CONFIG
  );

  const orderConfirmationLink = getLanguageLink(
    state,
    updateParamsInUrl(ORDER_CONFIRMATION_PATH, {
      ...(getIsAmendBasket(state) ? { action: 'amend' } : {}),
      ...(showOneCheckoutConfirmation
        ? { showOneCheckoutConfirmation: 'true' }
        : {})
    })
  );

  return {
    bookedSlot: getCurrentValidSlot(state),
    hideBaggingOptions: getHideBaggingOptionsFeature(state),
    isAuthenticated: getIsUserAuthenticated(state),
    language: getLanguage(state),
    timezone: getTimezone(state),
    inlineMessagingEnabled,
    isFDOXMessagingEnabled,
    freeDeliveryStatus: isFDOXMessagingEnabled && getFreeDeliveryStatus(state),
    isAmendOrderAvailable,
    currentUrl,
    hasSlot,
    basketMaxItemCountLimit: getBasketMaxItemCountLimit(state),
    orderConfirmationLink,
    isCollection,
    hasMarketplaceOnlyBasket: getHasOnlyMarketplaceItems(state),
    marketplaceBasket: getMarketplaceBasket(state),
    groceryBasket: getGroceryBasket(state),
    amendBannerVariant: getAmendBannerPositionStyleVariant(state),
    freeDeliveryDetails: config('showFreeDeliveryMessage')
      ? getFreeDeliveryThresholds(state)
      : {}
  };
};

@helpers(['c', 'f', 't'])
@connect(mapStateToProps)
export default class TrolleyOrderSummary extends Component {
  static propTypes = {
    accordion: PropTypes.bool,
    amendCutoff: PropTypes.string,
    bagOptions: PropTypes.shape({
      charge: PropTypes.number,
      enabled: PropTypes.bool,
      isBagless: PropTypes.bool
    }),
    bookedSlot: PropTypes.object,
    c: PropTypes.func.isRequired,
    charges: PropTypes.shape({
      bagCharge: PropTypes.number,
      minimumBasketValue: PropTypes.number,
      surcharge: PropTypes.number,
      packaging: PropTypes.shape({
        charge: PropTypes.number
      })
    }).isRequired,
    clubcardPoints: PropTypes.shape({
      greenPoints: PropTypes.number,
      promoPoints: PropTypes.number,
      standardPoints: PropTypes.number
    }).isRequired,
    continueLinkDisplayed: PropTypes.bool,
    continueLinkHref: PropTypes.string,
    continueLinkText: PropTypes.string,
    coupons: PropTypes.number,
    dcsProps: PropTypes.shape({
      bottom: PropTypes.array,
      top: PropTypes.array
    }),
    disabled: PropTypes.bool,
    displayAsGuidePrice: PropTypes.bool.isRequired,
    eCouponsTotal: PropTypes.number,
    enableScroll: PropTypes.bool,
    f: PropTypes.func.isRequired,
    formattedOrderNumber: PropTypes.string,
    freeDeliveryStatus: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    guidePrice: PropTypes.number.isRequired,
    hideBaggingOptions: PropTypes.bool.isRequired,
    hideSmall: PropTypes.bool,
    isAmendBasket: PropTypes.bool,
    isAmendOrderAvailable: PropTypes.bool,
    isCollection: PropTypes.bool,
    isFDOXMessagingEnabled: PropTypes.bool.isRequired,
    isPayAtDoorAmendOrder: PropTypes.bool.isRequired,
    isTrolleyLoading: PropTypes.bool,
    items: PropTypes.number.isRequired,
    language: PropTypes.string.isRequired,
    orderConfirmationLink: PropTypes.string.isRequired,
    orderDueForDelivery: PropTypes.bool,
    page: PropTypes.oneOf([
      HAVE_YOU_FORGOTTEN,
      CHECKOUT_RECOMMENDATIONS,
      ORDER_CONFIRMATION,
      ORDER_DETAILS,
      ORDER_SUMMARY,
      PENDING_ORDER,
      TROLLEY
    ]).isRequired,
    payAtDoorMethods: PropTypes.array.isRequired,
    paymentMethod: PropTypes.string,
    savings: PropTypes.number.isRequired,
    serviceCharge: PropTypes.number,
    shoppingMethod: SHOPPING_METHOD_PROP_TYPE.isRequired,
    showBagChargesInfoLink: PropTypes.bool,
    slotIsBooked: PropTypes.bool,
    staffDiscount: PropTypes.number.isRequired,
    subscription: PropTypes.object,
    summaryType: PropTypes.oneOf(['trolley', 'order']),
    supportsPayAtDoor: PropTypes.bool,
    t: PropTypes.func.isRequired,
    timezone: PropTypes.string.isRequired,
    totalPrice: PropTypes.number.isRequired
  };

  static defaultProps = {
    accordion: false,
    bagOptions: {
      enabled: false,
      charge: 0,
      isBagless: true
    },
    clubcardPoints: {
      standardPoints: 0,
      greenPoints: 0,
      promoPoints: 0
    },
    continueLinkDisplayed: true,
    coupons: 0,
    displayAsGuidePrice: true,
    enableScroll: true,
    guidePrice: 0,
    hideSmall: true,
    items: 0,
    isAmendBasket: false,
    isTrolleyLoading: false,
    orderDueForDelivery: false,
    savings: 0,
    serviceCharge: 0,
    showBagChargesInfoLink: false,
    staffDiscount: 0,
    summaryType: 'order',
    splitView: []
  };

  setTradingBannerAnalytics(data) {
    emitContentInteractOp({
      displayArea: 'of',
      pageType: 'basket',
      panel: [
        {
          campaignId: data.ruleId ? data.ruleId.trim() : 'full basket banner',
          contentSegment: data.segments,
          posInModule: data.posInPanel,
          ruleId: data.ruleId
        }
      ],
      modulePosition: data.placement
    });
  }

  componentClickHandler = cmsProps => {
    this.setTradingBannerAnalytics(cmsProps);
  };

  onPayOnlineButtonClick() {
    analyticsBus().emit('UIEventBasicEvent', {
      action: 'delay',
      type: 'order summary',
      value: 'pay online'
    });
  }

  renderPaymentMethod() {
    const { paymentMethod, t: translate } = this.props;
    if (paymentMethod) {
      return (
        <div className="trolley-order-summary--row payment-method">
          {`${translate('orders:common.payment-method')}: `}
          <span className="trolley-order-summary--payment-method">
            {paymentMethod}
          </span>
        </div>
      );
    }
  }

  renderServiceCharge(serviceCharge, currency) {
    //Service charge will show on all the listed pages and the trolley page only if a slot is booked or if it is being viewed while in amend basket mode.
    const {
      f: feature,
      isAmendBasket,
      marketplaceBasket,
      hasMarketplaceOnlyBasket,
      page,
      slotIsBooked,
      t: translate,
      isCollection
    } = this.props;
    if (hasMarketplaceOnlyBasket && marketplaceBasket) {
      return null;
    }
    if (
      (page === TROLLEY && (slotIsBooked || isAmendBasket)) ||
      [
        HAVE_YOU_FORGOTTEN,
        CHECKOUT_RECOMMENDATIONS,
        ORDER_CONFIRMATION,
        ORDER_DETAILS,
        ORDER_SUMMARY,
        PENDING_ORDER
      ].includes(page)
    ) {
      let label;

      if (feature('useUpdatedServiceChargeLabels')) {
        label = isCollection
          ? translate('checkout:payment-summary.pick-and-pack')
          : translate('checkout:payment-summary.pick-pack-and-deliver');
      } else {
        label = translate('checkout:payment-summary.service-charge');
      }

      const props = {
        dataAuto: 'service-charge',
        priceClassName: 'trolley-order-summary--serviceCharge',
        currency: currency,
        charge: serviceCharge
      };

      return this.renderLineItem(label, props);
    }
  }

  renderDepositCharge(depositCharge, currency) {
    if (!depositCharge) return;
    const { t: translate } = this.props;
    let label = translate('orders:common.includes-deposit-text');

    const props = {
      dataAuto: 'deposit-charge',
      rowClassName: 'trolley-order-summary--depositCharge',
      currency: currency,
      charge: depositCharge
    };

    return this.renderLineItem(label, props);
  }

  renderSavings(savings, currency) {
    const { c: config, t: translate, page } = this.props;
    if (config('trolleyOrderSummary:showSavings') && savings) {
      if (page === TROLLEY) {
        return (
          <BasketSavings savings={savings} currency={currency} t={translate} />
        );
      } else {
        const label = `${translate('trolley:header.include-savings')} `;

        return (
          <div className="trolley-order-summary--savings">
            <span>{label}</span>
            <Price
              className="trolley-order-summary--savings-price"
              value={savings}
              enableMaxLimit={true}
              {...currency}
            />
          </div>
        );
      }
    }

    return null;
  }

  renderClubcardPoints(clubcardPoints) {
    if (
      clubcardPoints &&
      (clubcardPoints.standardPoints ||
        clubcardPoints.greenPoints ||
        clubcardPoints.promoPoints)
    ) {
      return (
        <div className="trolley-order-summary-cc-section">
          {clubcardPoints.standardPoints
            ? this.renderStandardClubcardPoints(clubcardPoints.standardPoints)
            : null}
          {clubcardPoints.greenPoints
            ? this.renderGreenClubcardPoints(clubcardPoints.greenPoints)
            : null}
          {clubcardPoints.promoPoints
            ? this.renderPromoClubcardPoints(clubcardPoints.promoPoints)
            : null}
        </div>
      );
    }
  }

  renderGreenClubcardPoints(points) {
    const { t: translate } = this.props;
    const label = translate('trolley:header.green-clubcard-points');
    const props = {
      dataAuto: 'green-clubcard-points',
      priceClassName: 'trolley-order-summary--green-clubcard-points',
      charge: points
    };

    return this.renderLineItem(label, props);
  }

  renderStandardClubcardPoints(points) {
    const { t: translate } = this.props;
    const label = translate('trolley:header.clubcard-points-earned');
    const props = {
      dataAuto: 'clubcard-points',
      priceClassName: 'trolley-order-summary--standard-clubcard-points',
      charge: points
    };

    return this.renderLineItem(label, props);
  }

  renderPromoClubcardPoints(points) {
    const { t: translate } = this.props;
    const label = translate('trolley:header.promo-clubcard-points');
    const props = {
      dataAuto: 'promo-points',
      priceClassName: 'trolley-order-summary--promo-clubcard-points',
      charge: points
    };

    return this.renderLineItem(label, props);
  }

  renderCouponsDiscount(coupons, currency) {
    if (coupons) {
      const { t: translate } = this.props;
      const label = translate('checkout:payment-summary.ecoupons-discount');
      const props = {
        dataAuto: 'coupon-discount',
        rowClassName: 'discount',
        priceClassName: 'trolley-order-summary--coupons',
        currency,
        charge: -Math.abs(coupons)
      };

      return this.renderLineItem(label, props);
    }
  }

  renderStaffDiscount(staffDiscount, currency) {
    if (staffDiscount) {
      const { t: translate } = this.props;
      const label = translate('checkout:payment-summary.staff-discount');
      const props = {
        dataAuto: 'staff-discount',
        rowClassName: 'discount',
        priceClassName: 'trolley-order-summary--staff-discount',
        currency,
        charge: -Math.abs(staffDiscount)
      };

      return this.renderLineItem(label, props);
    }
  }

  renderTotal(totalPrice = 0, currency, textVariant) {
    const { t: translate } = this.props;
    const label = translate(textVariant);
    const props = {
      dataAuto: 'total',
      rowClassName: 'total',
      priceClassName: 'trolley-order-summary--total',
      currency,
      charge: totalPrice
    };

    return this.renderLineItem(label, props);
  }

  renderInfoMessage() {
    const {
      orderDueForDelivery,
      isAmendOrderAvailable,
      t: translate
    } = this.props;
    if (orderDueForDelivery) {
      return (
        <div className="trolley-order-summary--order-due-container">
          <div
            className={`order-item--actions ${
              isAmendOrderAvailable ? 'margin-bottom' : ''
            }`}
          >
            <div className="info-message no-border-radius">
              <div className="info-message-content">
                <span className="icon icon-context-card-05">
                  {translate('orders:my-orders.order-is-due')}
                </span>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  renderOrderNumber() {
    const { formattedOrderNumber, t: translate } = this.props;
    const orderNumberTranslationKey = 'orders:common.order-number';

    if (formattedOrderNumber) {
      return (
        <div className="trolley-order-summary--info-row">
          <div className="trolley-order-summary--order-number-title">{`${translate(
            orderNumberTranslationKey
          )}:`}</div>
          <span className="trolley-order-summary--order-number-value">
            {formattedOrderNumber}
          </span>
        </div>
      );
    }

    return null;
  }

  renderGuidePrice(guidePrice, savings, currency, displayAsGuidePrice) {
    const { c: config, language, t: translate } = this.props;
    const priceTranslationKey = displayAsGuidePrice
      ? 'common:guide-price'
      : 'common:price';
    const helpLinkKey = 'help:guidePrice';

    return (
      <dl className="trolley-order-summary--guide-price-row">
        <dt className="trolley-order-summary--guide-price-label">
          <span>{translate(priceTranslationKey)}</span>
          {displayAsGuidePrice && (
            <Link
              to={config(`${helpLinkKey}:${language}`)}
              target="_blank"
              rel="help noopener noreferrer"
            >
              <Icon
                name="question-dark"
                hiddenText={translate(
                  'checkout:payment-summary.guide-price-terms-accessibility'
                )}
                externalWindow={true}
              />
            </Link>
          )}
        </dt>
        <dd className="trolley-order-summary--guide-price">
          <Price
            data-auto="guide-price"
            className="amount"
            value={guidePrice}
            {...currency}
          />
        </dd>
        {this.renderSavings(savings, currency)}
      </dl>
    );
  }

  renderBagCharge(bagOptions, currency) {
    const { hideBaggingOptions } = this.props;
    const { enabled, isBagless, charge } = bagOptions;

    if (enabled && !isBagless) {
      if (hideBaggingOptions && charge <= 0) {
        return null;
      }
      const { c: config, showBagChargesInfoLink, t: translate } = this.props;
      const label = translate('checkout:bag-charge');
      const props = {
        dataAuto: 'bag-charge',
        priceClassName: 'trolley-order-summary--bag-charge',
        currency,
        charge
      };

      const contents = (
        <React.Fragment>
          {label}
          {showBagChargesInfoLink && (
            <Link
              to={config('links:carrierBagSurchargeInfo')}
              target="surcharge-accessibility"
              rel="noopener noreferrer"
              externalWindow={true}
            >
              <Icon
                name="question-dark"
                hiddenText={translate(
                  'trolley:warnings.basket-surcharge-accessibility'
                )}
              />
            </Link>
          )}
        </React.Fragment>
      );

      return this.renderLineItem(contents, props);
    }
  }

  renderSurcharge(surcharge, currency) {
    if (surcharge) {
      const { t: translate } = this.props;
      const label = translate('checkout:order-summary.minimum-basket-charge');
      const props = {
        dataAuto: 'surcharge',
        priceClassName: 'trolley-order-summary--surcharge',
        currency: currency,
        charge: surcharge
      };

      return this.renderLineItem(label, props);
    }
  }

  renderLineItem(children, props) {
    return <OrderSummaryLineItem {...props}>{children}</OrderSummaryLineItem>;
  }

  isBasketEditable() {
    const { page } = this.props;
    return [
      TROLLEY,
      HAVE_YOU_FORGOTTEN,
      CHECKOUT_RECOMMENDATIONS,
      ORDER_SUMMARY
    ].includes(page);
  }

  renderContinueButton(showOrderSummary = false) {
    const {
      c: config,
      continueLinkHref,
      continueLinkText,
      disabled,
      page,
      isAmendBasket,
      t: translate
    } = this.props;

    const bref = page === ORDER_SUMMARY ? 'OrderSummaryContinueBtn' : null;

    let divClass = 'trolley-order-summary--button';
    let checkoutButtonText = continueLinkText;

    if (!showOrderSummary) {
      divClass += ' dds-button';

      if (isAmendBasket) {
        divClass += ' amend-mode';
        checkoutButtonText = getOop1882cdeSaveButton(
          this.props.amendBannerVariant
        )
          ? translate('trolley:continue-checkout-oop1882-cde')
          : translate('trolley:continue-checkout');
      }
    }
    return (
      <div data-auto="continue-checkout-button" className={divClass}>
        <ContinueCheckoutButtonWrapper
          buttonText={checkoutButtonText}
          continueHref={continueLinkHref}
          disabled={disabled}
          page={page}
          renderButton={continueHref => (
            <LinkButtonWithFeedback
              enableSpinner={config('checkout:enableSubmittingSpinner')}
              primaryText={checkoutButtonText}
              disabled={disabled}
              href={continueHref}
              bref={bref}
              ddl={!showOrderSummary}
              referrerPolicy={ReferrerPolicies.NO_REFERRER_WHEN_DOWNGRADE}
            />
          )}
        />
      </div>
    );
  }

  renderActionButtons(showOrderSummary = false) {
    const { page, supportsPayAtDoor } = this.props;

    const shouldRenderPaymentOptions =
      page === ORDER_SUMMARY && supportsPayAtDoor;
    let divClass = 'hidden-small-medium';

    if (!showOrderSummary) {
      divClass += ' dds-button';
    }

    return shouldRenderPaymentOptions ? (
      <div className={divClass}>{this.renderPaymentOptions()}</div>
    ) : (
      this.renderContinueButton(showOrderSummary)
    );
  }

  renderPaymentOptions() {
    const {
      continueLinkHref,
      inlineMessagingEnabled,
      isAmendBasket,
      isPayAtDoorAmendOrder,
      orderConfirmationLink,
      t: translate,
      shoppingMethod,
      payAtDoorMethods
    } = this.props;

    if (isAmendBasket) {
      return (
        <AmendPaymentOptionButton
          confirmationUrl={orderConfirmationLink}
          isPayAtDoorAmendOrder={isPayAtDoorAmendOrder}
          payOnlineUrl={continueLinkHref}
        />
      );
    }

    return (
      <PaymentOptionsButtons
        confirmationUrl={orderConfirmationLink}
        continueLinkHref={continueLinkHref}
        legendText={
          !inlineMessagingEnabled &&
          translate('checkout:payment-options.choose-option')
        }
        showCardIcons
        shoppingMethod={shoppingMethod}
        payAtDoorMethods={payAtDoorMethods}
      />
    );
  }

  renderOverlaySpinner() {
    if (this.isBasketEditable()) {
      const { isTrolleyLoading } = this.props;
      return <OverlaySpinner isLoading={isTrolleyLoading} />;
    }
  }

  renderOrderInfo() {
    const { basketMaxItemCountLimit, t, items } = this.props;

    return (
      <div className="order-summary--info">
        {this.renderOrderNumber()}
        <ItemsInBasketMessage
          basketMaxItemCountLimit={basketMaxItemCountLimit}
          items={items}
          t={t}
        />
      </div>
    );
  }
  renderAmendInfo() {
    const {
      page,
      isAmendOrderAvailable,
      amendCutoff,
      orderDueForDelivery
    } = this.props;
    return (
      <AmendInfoMessage
        page={page}
        isAmendOrderAvailable={isAmendOrderAvailable}
        amendCutoff={amendCutoff}
        isOrderDue={orderDueForDelivery}
      />
    );
  }

  renderFreeDeliveryInfo() {
    const { t, c, freeDeliveryDetails, hasSlot } = this.props;

    return (
      <div className="trolley-order-summary--amend-message-container">
        <FreeDeliveryMessage
          freeDelivery={freeDeliveryDetails}
          helpers={{ translate: t, config: c }}
          hasValidSlot={hasSlot}
          className="info-message-comp"
        />
      </div>
    );
  }

  renderOrderSummary(props, currency) {
    const {
      bagOptions,
      charges,
      clubcardPoints,
      coupons,
      serviceCharge,
      staffDiscount,
      totalPrice
    } = props;
    return (
      <div>
        {this.renderSurcharge(charges.surcharge, currency)}
        {this.renderServiceCharge(serviceCharge, currency)}
        {this.renderBagCharge(bagOptions, currency)}
        {this.renderStaffDiscount(staffDiscount, currency)}
        {this.renderCouponsDiscount(coupons, currency)}
        {this.renderTotal(totalPrice, currency, 'orders:common.total')}
        {this.renderDepositCharge(charges?.depositCharge, currency)}
        {this.renderClubcardPoints(clubcardPoints)}
        {this.renderPaymentMethod()}
        {this.renderInfoMessage()}
        {this.renderAmendInfo()}
      </div>
    );
  }

  render() {
    const {
      bagOptions,
      eCouponsTotal,
      marketplaceBasket,
      hasMarketplaceOnlyBasket,
      groceryBasket,
      staffDiscount,
      totalPrice
    } = this.props;

    const marketplaceOnlyBasket = hasMarketplaceOnlyBasket && marketplaceBasket;

    const marketplaceOnlyBasketTotal =
      marketplaceOnlyBasket &&
      marketplaceBasket.guidePrice - eCouponsTotal - staffDiscount;

    const props = {
      ...this.props,
      bagOptions: marketplaceOnlyBasket
        ? { charge: 0, enabled: false, isBagless: false }
        : bagOptions,
      charges: marketplaceOnlyBasket
        ? {
            bagCharge: 0,
            minimumBasketValue: 0,
            packaging: { charge: 0 },
            surcharge: 0
          }
        : this.props.charges,
      totalPrice: marketplaceOnlyBasketTotal
        ? marketplaceOnlyBasketTotal
        : totalPrice
    };

    const {
      charges,
      continueLinkDisplayed,
      guidePrice,
      subscription,
      displayAsGuidePrice,
      freeDeliveryStatus,
      isFDOXMessagingEnabled,
      page,
      bookedSlot,
      hideBaggingOptions,
      isAuthenticated,
      isAmendBasket,
      summaryType,
      c: config,
      t: translate,
      enableScroll,
      hideSmall,
      accordion,
      savings,
      basketMaxItemCountLimit,
      items,
      language,
      shoppingMethod,
      serviceCharge,
      clubcardPoints,
      hasSlot,
      slotIsBooked,
      isTrolleyLoading,
      freeDeliveryDetails
    } = props;

    const bagOptionsWithFeature = { ...bagOptions, hideBaggingOptions };
    const currency = config('currency');
    const showDDSStyleSummary = summaryType === 'trolley';
    const showOrderSummary =
      summaryType === 'order' ||
      (isAmendBasket && config('trolleyOrderSummary:fullSummaryInAmend'));

    const contextBasedCSSModifiers = classnames(`-${page}`, {
      '-amend-basket': isAmendBasket
    });
    const trolleyWrapperClasses = classnames({
      'trolley-order-summary--wrapper': enableScroll
    });
    const trolleyOrderSummaryClasses = classnames(
      'trolley-order-summary',
      contextBasedCSSModifiers
    );
    const trolleyOrderSummaryContainerClasses = classnames({
      'trolley-order-summary--container': true,
      'hidden-small-medium': hideSmall
    });
    const trolleyOrderSummaryButtonClasses = classnames(
      {
        'trolley-order-summary--button': true,
        'ddsweb-hidden-small-medium': true
      },
      contextBasedCSSModifiers
    );

    const content = this.renderOrderSummary(props, currency);
    const componentTitle = showOrderSummary
      ? translate('checkout:order-summary.title')
      : translate('orders:order-details.trolley-summary');

    let formattedContent; // Undefined by default, will only be set if there is content to serve

    if (content) {
      // Only generate component if content exists, particularly in the case of the Accordion
      formattedContent = accordion ? (
        <Accordion
          classes="trolley-order-summary--accordion"
          header={translate('common:view-more')}
          headerOnOpen={translate('common:view-less')}
          icon="icon-chevron-down-small-blue"
          isOpen={false}
          title="Order summary"
        >
          {content}
        </Accordion>
      ) : (
        content
      );
    }

    const shouldRenderActionButtons =
      continueLinkDisplayed && this.isBasketEditable();

    const displayContent = (
      <div className={trolleyOrderSummaryClasses} ref="trolley-order-summary">
        <h2 className="trolley-order-summary--large-header">
          {componentTitle}
        </h2>
        <Warning
          bookedSlot={bookedSlot}
          isAuthenticated={isAuthenticated}
          guidePrice={guidePrice}
          minimumBasketValue={charges.minimumBasketValue}
          surcharge={charges.surcharge}
          subscription={subscription}
        />

        {page === TROLLEY &&
          isFDOXMessagingEnabled &&
          freeDeliveryStatus &&
          freeDeliveryStatus !== QUALIFY_FOR_FREE_DELIVERY && (
            <div className="spend-threshold-warning-container">
              <Warning spendThresholdWarning status={freeDeliveryStatus} />
            </div>
          )}
        {this.renderFreeDeliveryInfo()}
        {this.renderOrderInfo()}
        <div className="order-summary--rows">
          {this.renderOverlaySpinner()}
          {this.renderGuidePrice(
            guidePrice,
            savings,
            currency,
            displayAsGuidePrice
          )}
          {formattedContent}
        </div>
      </div>
    );

    return (
      <div
        className={trolleyWrapperClasses}
        ref="trolley-order-summary--wrapper"
      >
        <div
          data-auto="order-summary-container"
          className={trolleyOrderSummaryContainerClasses}
          ref="trolley-order-summary--container"
        >
          {showDDSStyleSummary ? (
            <BasketSummaryContent
              data-auto="basket-summary-content"
              componentTitle={componentTitle}
              bagOptions={bagOptionsWithFeature}
              basketMaxItemCountLimit={basketMaxItemCountLimit}
              items={items}
              displayAsGuidePrice={displayAsGuidePrice}
              language={language}
              config={config}
              guidePrice={guidePrice}
              savings={savings}
              charges={charges}
              shoppingMethod={shoppingMethod}
              serviceCharge={serviceCharge}
              coupons={eCouponsTotal}
              staffDiscount={staffDiscount}
              clubcardPoints={clubcardPoints}
              hasValidSlot={hasSlot}
              isAuthenticated={isAuthenticated}
              slotIsBooked={slotIsBooked}
              isAmendBasket={isAmendBasket}
              isTrolleyLoading={isTrolleyLoading}
              marketplaceBasket={marketplaceBasket}
              groceryBasket={groceryBasket}
              hasMarketplaceOnlyBasket={hasMarketplaceOnlyBasket}
              freeDeliveryDetails={freeDeliveryDetails}
            >
              <div className={`${trolleyOrderSummaryButtonClasses} dds-button`}>
                {shouldRenderActionButtons && this.renderActionButtons()}
              </div>
            </BasketSummaryContent>
          ) : (
            <>
              {displayContent}

              <div className={trolleyOrderSummaryButtonClasses}>
                {shouldRenderActionButtons && this.renderActionButtons(true)}
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}
