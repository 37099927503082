import isServer from '#/conditional-resources/conditions/is-server';
import { isClickAndCollectShoppingMethod } from '#/lib/shopping-method-util';
import { COLLECTION } from '#/constants/shopping-methods';

export default (state, props) =>
  isServer(state)
    ? props?.req?.path.includes(COLLECTION) ?? false
    : isClickAndCollectShoppingMethod(
        props?.routeProps?.params?.shoppingMethod
      );
