import styled from 'styled-components';
import {
  BaseElement,
  breakpointStyles,
  RootElement,
  scrollableHorizontal
} from '@ddsweb/foundation';
import { Heading } from '@ddsweb/heading';
import Button from '@ddsweb/button';
import Link from '@ddsweb/link';
import { colors, spacing, breakpoints } from '@ddsweb/selectors';

export const Container = styled(RootElement)`
  ${({ styles }) => styles};
  margin-top: ${spacing.md};

  @media (min-width: ${breakpoints.tabletLarge}px) {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: ${spacing.xxxl};
  }
`;

export const StyledHeading = styled(Heading)`
  margin-bottom: ${spacing.xs};

  @media (min-width: ${breakpoints.tabletLarge}px) {
    margin: ${spacing.xs} ${spacing.md} 0 0;
  }
`;

export const CarouselContainer = styled(RootElement)`
  display: flex;
  width: 100%;

  @media (min-width: ${breakpoints.tabletLarge}px) {
    width: 79%;
  }

  @media (min-width: ${breakpoints.desktop}px) {
    width: 84%;
  }
`;

export const ScrollBarMask = styled(BaseElement)`
  overflow: hidden;
`;

export const ContentContainer = styled(BaseElement)`
  ${scrollableHorizontal};
  padding-top: ${spacing.xx};
  height: 40px;

  @media (min-width: ${breakpoints.mobileLarge}px) {
    padding: ${spacing.xx} ${spacing.md} 0;
  }

  @media (min-width: ${breakpoints.tabletLarge}px) {
    height: 55px;
  }
`;

export const CarouselItemsContainer = styled(BaseElement)`
  display: inline-flex;
  list-style: none;
  padding: 0;
`;

export const CarouselItem = styled(BaseElement)`
  align-items: stretch;
  display: inline-flex;
  margin: 0;
  padding-right: ${spacing.xs};
  vertical-align: top;
  white-space: normal;
  ${breakpointStyles('itemWidth')};

  > * {
    width: 100%;
  }

  &: last-child {
    padding-right: 0;
  }
`;

export const StyledTextButton = styled(Link)`
  height: ${spacing.xxxl};
  border-radius: ${spacing.lg};

  &:hover {
    box-shadow: 0 0 0 ${spacing.xx} rgba(0, 83, 159, 0.4);
    transition: box-shadow 0.3s;
  }

  &:after {
    box-shadow: none;
  }

  & .ddsweb-icon__svg {
    width: 14px;
    height: 14px;
    stroke-width: 3px;
  }
`;

export const Controls = styled(BaseElement)`
  display: none;

  & .ddsweb-button--icon-button:after {
    box-shadow: none;
  }

  @media (min-width: ${breakpoints.mobileLarge}px) {
    display: flex;
    align-items: center;
    height: ${spacing.xxxl};
    margin-top: ${spacing.xx};

    &.control-left {
      border-right: 1px solid ${colors.disabled};
      padding-right: ${spacing.sm};
    }

    &.control-right {
      border-left: 1px solid ${colors.disabled};
      padding-left: ${spacing.sm};
    }
  }
`;

export const Control = styled(Button)`
  width: ${spacing.xxxl};
  height: ${spacing.xxxl};

  &:hover {
    box-shadow: 0 0 0 ${spacing.xx} rgba(0, 83, 159, 0.4);
    transition: box-shadow 0.3s;
  }

  &[disabled],
  &:hover[disabled] {
    background-color: ${colors.white};
    border: 2px solid ${colors.disabledGrey};
    box-shadow: none;
  }

  &[disabled] span.ddsweb-button__container {
    color: ${colors.disabled.base};
  }
`;
