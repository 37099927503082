import { ContentLinkSearchFeature } from '#/experiments/oop-2002/constants';
import { getFeatureVariableJsonFromState, updateRegionInKey } from '#/lib/optimizely-manager';

const { featureKey, variationKey } = ContentLinkSearchFeature;

type SearchContentList = {
  heading: string;
  contentLinks: Array<links>;
};

type links = {
  name: string;
  url: string;
};

type SearchContent = {
  searchContentLink: SearchContentList;
  searchContentEnable?: boolean;
};

const getVariant = (state: Store, keyToRead: string, defaultResponse: {}): SearchContent => {
  const regionInKey = updateRegionInKey(featureKey, state);
  const searchContent = getFeatureVariableJsonFromState(state, regionInKey, keyToRead, { attributes: {} }) || {};
  return {
    searchContentLink: searchContent || defaultResponse,
    searchContentEnable: searchContent.contentLinks?.length > 0,
  };
};

export const getSearchContentConfig = (state: Store): SearchContent => getVariant(state, variationKey, {});
