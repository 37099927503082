import {
  UPDATE_EXTERNAL_VALIDATION_STATE,
  UPDATE_VALIDATION_STATE,
  UPDATE_VALIDATION_STATE_BY_FIELD,
} from '#/constants/action-types';
import {
  UpdateFormExternalValidation,
  UpdateFormValidation,
  UpdateFormValidationByField,
  FormAction,
} from '#/custom-typings/redux-store/form.actions.defs';
import { ExternalErrors, Fields, Field, FormState } from '#/custom-typings/redux-store/form.defs';
import { FORM } from '#/constants/spa-resource';
import { getResourceData } from '#/resources/resource-util';

const FIELD_DEFAULT_VALIDATION_STATE = {
  value: null,
  hasError: false,
  errorMessage: '',
};

export default function form(state: FormState = {}, action: FormAction): FormState {
  switch (action.type) {
    case UPDATE_VALIDATION_STATE:
      return updateFormValidationState(state, action.value);
    case UPDATE_VALIDATION_STATE_BY_FIELD:
      return updateFormValidationStateByField(state, action.value);
    case UPDATE_EXTERNAL_VALIDATION_STATE:
      return updateExternalErrorState(state, action.value);
    default:
      return state;
  }
}

const updateFormValidationStateByField = (
  state: FormState,
  { formId, fieldName, payload }: UpdateFormValidationByField['value'],
): FormState => {
  const formState = {
    ...(state[formId] || {}),
    [fieldName]: payload,
  };
  return {
    ...state,
    [formId]: formState,
  };
};

const updateExternalErrorState = (
  state: FormState,
  { formId, externalErrors }: UpdateFormExternalValidation['value'],
): FormState => {
  const formState: Fields = ({
    ...(state[formId] || {}),
    externalErrors,
  } as unknown) as Fields; // You can't create an instance of an indexed union in Typescript, however you can declare such a type.
  return {
    ...state,
    [formId]: formState,
  };
};

const updateFormValidationState = (state: FormState, { formId, payload }: UpdateFormValidation['value']): FormState => {
  return {
    ...state,
    [formId]: payload,
  };
};

export const getDefaultStateFromProps = (props: {
  resources?: { form: { data: FormState } };
  formValidationState?: FormState;
}): FormState => getResourceData(props.resources, FORM) || props.formValidationState || {};

export const getFieldValidationState = ({ form }: Store, formId: string, fieldName: string): Field => {
  const formState = form[formId] || {};
  return formState[fieldName] || FIELD_DEFAULT_VALIDATION_STATE;
};

export const getFormValidationState = ({ form }: Store, formId: string): Fields => form[formId] || {};

export const getExternalValidationState = ({ form }: Store, formId: string): ExternalErrors =>
  (form[formId] || {}).externalErrors || {};
