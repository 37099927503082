import React from 'react';
import { BasketIcon } from '@ddsweb/icon';
import { BasketButton } from './styled';

export type GuidePriceContentV2Props = {
  className?: string;
  mainLabel: React.ReactNode;
  subLabel: React.ReactNode;
};

const GuidePriceContentV2: React.FC<GuidePriceContentV2Props> = ({
  className,
  mainLabel,
  subLabel,
}: GuidePriceContentV2Props) => {
  return (
    <div className={`header-guide-price--container global-header-trolley ${className}`}>
      <BasketButton icon={<BasketIcon />} />
      <div className="header-guide-global-label">
        <div className="header-guide-price--main-label" data-auto="basket-guide-price">
          {mainLabel}
        </div>
        <span className="header-guide-price--sub-label">{subLabel}</span>
      </div>
    </div>
  );
};

export default GuidePriceContentV2;
