export type PrimaryNavigationItem = {
  catId?: string;
  desktop?: boolean;
  href?: string;
  id?: string;
  mobile?: string;
  name?: string;
  children?: Array<PrimaryNavigationItem> | null;
  onClick?: string;
  text?: string;
  hasPopup?: boolean;
  ariaLabel?: string;
  label?: string;
  url?: string;
  target?: string;
};

export type PrimaryNavigationList = Array<PrimaryNavigationItem>;

export type OwnState = {
  navList: PrimaryNavigationList;
  deviceType: string;
  shopUrl: string;
  selectedSuperDepartment?: string;
};

export const INSPIRE_AND_EVENTS_KEY = 'inspiration & events';
export const ALL_DEPARTMENTS_KEY = 'all departments';

export const MAX_MENU_ITEMS_VISIBLE = {
  BELOW_DESKTOP: 8,
  ABOVE_DESKTOP: 11,
};

export const VISIBLE_MENU_ITEMS = {
  aboveDesktop: MAX_MENU_ITEMS_VISIBLE.ABOVE_DESKTOP,
  belowDesktop: MAX_MENU_ITEMS_VISIBLE.BELOW_DESKTOP,
};
