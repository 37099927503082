import React from 'react';
import Buybox from '@beans/buy-box';
import { TBuyboxUnavailableWithPriceData } from '#/selectors/beans-product-tile/buybox-data';

const renderNoActions = (): JSX.Element => <></>;

export const UnavailableWithPriceBuybox = ({
  priceSubtext,
  priceText,
  assistiveDrsText,
  drsText,
  renderBuyboxActions = renderNoActions,
}: TBuyboxUnavailableWithPriceData): JSX.Element => (
  <Buybox
    assistiveDrsText={assistiveDrsText}
    drsText={drsText}
    data-auto="unavailable-with-price-buybox"
    priceText={priceText}
    priceSubtext={priceSubtext}
    action={renderBuyboxActions({ classes: 'hidden-small' })}
  />
);
