import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { addFilterUrl, removeFilterUrl } from '../../lib/filter-helpers';
import helpers from '../../lib/decorators/helpers';
import { getCurrentFilters } from '../../reducers/filter';
import { connect } from '../../lib/render/connect-deep-compare';
import { getCurrentUrl } from '#/reducers/app';
import { cloneDeep } from '#/utils/clone-deep';

const categories = ['Superdepartment', 'Department', 'Aisle', 'Shelf'];

const mapStateToProps = state => ({
  currentFilters: getCurrentFilters(state),
  currentUrl: getCurrentUrl(state)
});

@helpers(['c', 'currentPathname'])
@connect(mapStateToProps)
export default class FilterTree extends PureComponent {
  static propTypes = {
    c: PropTypes.func.isRequired,
    currentFilters: PropTypes.object.isRequired,
    currentPathname: PropTypes.func.isRequired,
    currentUrl: PropTypes.string.isRequired,
    facetLists: PropTypes.array.isRequired
  };

  categoryFacets(facetLists) {
    return facetLists.filter(
      facetList => categories.indexOf(facetList.category) !== -1
    );
  }

  pruneFacetLists(facetLists) {
    const result = cloneDeep(facetLists);

    // for all but the bottom level of filters only the one currently selected should be displayed
    // If one superdepartment, department, or aisle is selected only show that one even if it is
    // the bottom level
    for (let i = 0; i < result.length - 1; i++) {
      result[i].facets = result[i].facets.filter(facet => facet.isSelected);
    }

    const lastList = result[result.length - 1];
    const limitedCategories = ['Superdepartment', 'Department', 'Aisle'];

    if (limitedCategories.indexOf(lastList.category) + 1) {
      const selectedFacets = lastList.facets.filter(facet => facet.isSelected);

      if (selectedFacets.length) {
        result[result.length - 1].facets = selectedFacets;
      }
    }

    return result;
  }

  filterTree(facetLists) {
    const facetList = facetLists.shift();

    return (
      <ul className="filter-tree" data-auto="filter-options">
        {facetList.facets.map(facet => {
          const filterElementClasses = classnames('filter-element', {
            selected: facet.isSelected
          });

          const checkboxClasses = classnames('checked-status', {
            'icon-checkbox-square-checked': facet.isSelected,
            'icon-checkbox-square-unchecked': !facet.isSelected
          });

          const category = facetList.category.toLowerCase();
          const categoryDelimiter = this.props.c('categoryDelimiter');
          const currentPathname = this.props.currentPathname();
          const currentUrl = this.props.currentUrl;
          const multiSelectionFacets = this.props.c(
            'facetsAllowingMultipleSelections'
          );
          let url;

          if (facet.isSelected) {
            url = removeFilterUrl({
              category,
              categoryDelimiter,
              currentPathname,
              currentUrl,
              facet,
              filterOrder: categories.map(category => category.toLowerCase()),
              multiSelectionFacets
            });
          } else {
            url = addFilterUrl({
              category,
              categoryDelimiter,
              currentFilters: this.props.currentFilters,
              currentPathname,
              currentUrl,
              facet,
              multiSelectionFacets
            });
          }

          return (
            <li key={facet.facetId}>
              <a
                className={filterElementClasses}
                href={url}
                data-auto="filter-option"
                data-auto-selected={facet.isSelected}
              >
                <span className="label-wrapper">
                  <span className="label">{facet.facetName}</span>
                  <span className="count">{`(${facet.binCount})`}</span>
                </span>
                <span className={checkboxClasses} />
              </a>
              {!!facetLists.length && this.filterTree(facetLists)}
            </li>
          );
        })}
      </ul>
    );
  }

  render() {
    return (
      <div>
        {this.filterTree(
          this.pruneFacetLists(this.categoryFacets(this.props.facetLists))
        )}
      </div>
    );
  }
}
