import React from 'react';
import PropTypes from 'prop-types';
import TradeCta from '#/components/cms-driven/trade_cta';
import Link from '#/components/link-check-spa';
import helpers from '#/lib/decorators/helpers';

@helpers(['asset'])
export class FullBasketBanner extends React.PureComponent {
  static propTypes = {
    brandLogo: PropTypes.shape({ src: PropTypes.string }),
    callToAction: PropTypes.shape({
      ctaUrl: PropTypes.string.isRequired,
      ctaOpenNewTab: PropTypes.string.isRequired,
      ctaBackgroundColor: PropTypes.string.isRequired,
      ctaChevronColor: PropTypes.string
    }).isRequired,
    catId: PropTypes.string.isRequired,
    componentClickHandler: PropTypes.func,
    componentMountHandler: PropTypes.func, // eslint-disable-line react/no-unused-prop-types
    heading: PropTypes.shape({
      headingText: PropTypes.string.isRequired,
      headingColor: PropTypes.string.isRequired
    }).isRequired,
    panelColor: PropTypes.shape({
      backgroundColor: PropTypes.string.isRequired,
      keyLineColor: PropTypes.string
    }).isRequired,
    subHeading: PropTypes.shape({
      subHeadingText: PropTypes.string,
      subHeadingColor: PropTypes.string
    })
  };

  onClick = () => {
    const props = this.props;
    const handler = props.componentClickHandler;

    if (typeof handler === 'function') {
      props.componentClickHandler(props);
    }
  };

  render() {
    const props = this.props;
    const {
      panelColor,
      callToAction,
      brandLogo,
      heading,
      subHeading,
      catId
    } = props;
    const keyLineColor = panelColor.keyLineColor || '#ccc';
    const panelStyles = {
      backgroundColor: panelColor.backgroundColor,
      border: `1px solid ${keyLineColor}`
    };
    const logo = brandLogo &&
      brandLogo.src && {
        backgroundImage: `url(${props.asset(props.brandLogo.src)})`
      };
    const ctaBackgroundColor = callToAction.ctaBackgroundColor && {
      sd_backgroundColor: callToAction.ctaBackgroundColor,
      md_backgroundColor: callToAction.ctaBackgroundColor
    };
    const chevronColor = callToAction.ctaChevronColor && {
      sm_color: callToAction.ctaChevronColor,
      md_color: callToAction.ctaChevronColor
    };
    const ctaColor = {
      ...ctaBackgroundColor,
      ...chevronColor
    };
    const openInNewTab = callToAction.ctaOpenNewTab.toLowerCase() === 'yes';
    const panelContainerWidth = !logo ? { width: '83%' } : null;

    return (
      <div
        className="full-basket-banner"
        onClick={this.onClick}
        style={panelStyles}
      >
        <div className="full-basket-banner--wrap">
          <Link
            className="stamp--a"
            to={callToAction.ctaUrl}
            target={openInNewTab && 'fullBasketBanner'}
            rel="noopener noreferrer"
          >
            {logo && <span className="img--container" style={logo} />}
            <span className="panel--container" style={panelContainerWidth}>
              <span className="panel--inner">
                <span className="caption--container">
                  <span className="caption--wrapper">
                    <span
                      className="caption--title"
                      style={{ color: heading.headingColor }}
                    >
                      {heading.headingText}
                    </span>
                    {subHeading.subHeadingText && (
                      <span
                        className="caption--sub_headline"
                        style={{ color: subHeading.subHeadingColor }}
                      >
                        {subHeading.subHeadingText}
                      </span>
                    )}
                  </span>
                </span>
              </span>
            </span>
            <TradeCta uid={parseInt(catId, 10)} ctaColor={ctaColor} />
          </Link>
        </div>
      </div>
    );
  }
}
