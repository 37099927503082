import { APP_STATE } from '#/constants/spa-resource';
import { getAppStateResource } from '#/selectors/resources';
import { updateAppState } from '#/actions/app-state-action-creators';

export const type = APP_STATE;
export const cacheable = false;

export function handler(dispatch, getState) {
  const state = getState();
  const { data } = getAppStateResource(state);

  if (!data) {
    return;
  }

  dispatch(updateAppState(data));
}
