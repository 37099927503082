import { getLanguage, getTimezone } from '#/reducers/app';
import moment from 'moment-timezone';
import { formattedDateTimeFromConfig, formattedSlotTime, formatTimeFromConfig, IConfig } from './utils';

export const getFormattedTime = (state: Store, time: moment.Moment | string, config: IConfig): string => {
  const language = getLanguage(state);
  const timezone = getTimezone(state);
  const momentObject = time instanceof moment ? (time as moment.Moment) : moment(time);

  return formatTimeFromConfig(momentObject, timezone, language, config);
};

export const getFormattedSlotTime = (
  state: Store,
  startTime: moment.Moment | string | null,
  endTime: moment.Moment | string | null,
  config: IConfig,
): string => {
  const language = getLanguage(state);
  const timezone = getTimezone(state);
  const startMomentObject = startTime instanceof moment ? (startTime as moment.Moment) : moment(startTime);
  const endMomentObject = endTime instanceof moment ? (endTime as moment.Moment) : moment(endTime);

  return formattedSlotTime(startMomentObject, endMomentObject, timezone, language, config);
};

export const getFormattedDateTime = (
  state: Store,
  dateTime: moment.Moment | string | null,
  config: IConfig,
): string => {
  const language = getLanguage(state);
  const timezone = getTimezone(state);
  const momentObject = dateTime instanceof moment ? (dateTime as moment.Moment) : moment(dateTime);

  return formattedDateTimeFromConfig(momentObject, timezone, language, config);
};
