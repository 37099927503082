export const FULL_TROLLEY = 'full-trolley';
export const MINI_TROLLEY = 'mini-trolley';
export enum DiscountTypes {
  MULTIBUY = 'MULTIBUY',
  PROMOTIONS_TOTAL = 'PROMOTIONS_TOTAL',
  STAFF_DISCOUNT = 'STAFF_DISCOUNT',
  COUPONS = 'COUPONS',
  SURCHARGE_DISCOUNT = 'SURCHARGE_DISCOUNT',
}

export const BOOK_SLOT_MODAL = 'bookSlotModal';
export const SLOT_PROMPT_QUANTITY = 3;
export const PROMPT_SLOT_BOOKING = 'prompt slot booking';
export const BOOK_SLOT_MODAL_LOCAL_STORE_KEY = 'showBookSlotModal';
