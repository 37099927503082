import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import helpers from '../../../lib/decorators/helpers';
import isValid from '../../../lib/validation/is-valid';

import analyticsBus from '../../../analytics/analyticsBus';

import SafelyOutputString from '#/components/safely-output-string';

import Modal from '../../shared/modal';
import ModalActions from '../../shared/modal/actions';
import ModalButtonContainer from '../../shared/modal/actions/button-container';
import ModalContent from '../../shared/modal/content';

const FORSEE_CLOSE = 'foresee: close';
const FORSEE_NO = 'foresee: no';
const FORSEE_SUBMIT = 'foresee: submit email';
const FORSEE_YES = 'foresee: yes';

@helpers(['t'])
export default class ForeseeEmailRequest extends Component {
  static propTypes = {
    abandonHandler: PropTypes.func.isRequired,
    acceptHandler: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    declineHandler: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    const { t } = this.props;

    this.state = {
      step: 0
    };

    this.content = [
      {
        copy: t('modals:foresee.emailRequest.step1.copy'),
        ctas: [
          {
            action: this.handleYesClick,
            style: 'primary',
            text: t('modals:foresee.emailRequest.step1.cta1')
          },
          {
            action: this.handleNoClick,
            style: 'secondary',
            text: t('modals:foresee.emailRequest.step1.cta2')
          }
        ],
        title: t('modals:foresee.emailRequest.step1.title')
      },
      {
        copy: t('modals:foresee.emailRequest.step2.copy'),
        form: {
          action: this.handleSubmit,
          id: 'foresee-email',
          label: t('modals:foresee.emailRequest.step2.form.label'),
          submitButton: {
            style: 'primary',
            text: t('modals:foresee.emailRequest.step2.form.submitButton'),
            type: 'submit'
          },
          validationMessage: t(
            'modals:foresee.emailRequest.step2.form.validationMessage'
          )
        },
        title: t('modals:foresee.emailRequest.step2.title')
      },
      {
        copy: t('modals:foresee.emailRequest.step3.copy'),
        ctas: [
          {
            action: this.handleContinueClick,
            style: 'primary',
            text: t('modals:foresee.emailRequest.step3.cta')
          }
        ],
        title: t('modals:foresee.emailRequest.step3.title')
      }
    ];
  }

  handleYesClick = () => {
    this.setState({
      step: 1
    });
    this.dispatchForeseeFeedbackAnalytics(FORSEE_YES);
  };

  handleNoClick = () => {
    this.props.declineHandler();
    this.props.closeModal();
    this.dispatchForeseeFeedbackAnalytics(FORSEE_NO);
  };

  handleSubmit = evt => {
    if (evt) {
      evt.preventDefault();
    }

    const { value } = this.input;

    if (value && isValid('email_format', value)) {
      this.props.acceptHandler(value);
      this.setState({
        emailValid: true,
        step: 2
      });
      this.dispatchForeseeFeedbackAnalytics(FORSEE_SUBMIT);
    } else {
      this.setState({
        emailValid: false
      });
    }
  };

  handleContinueClick = () => {
    this.props.closeModal();
  };

  handleClose = () => {
    if (this.state.step === 0) {
      this.props.declineHandler();
    } else if (this.state.step === 1) {
      this.props.abandonHandler();
    }

    if (this.state.step !== 2) {
      this.dispatchForeseeFeedbackAnalytics(FORSEE_CLOSE);
    }

    this.props.closeModal();
  };

  saveInputRef = el => {
    this.input = el;
  };

  dispatchForeseeFeedbackAnalytics = value => {
    analyticsBus().emit('UIEventBasicEvent', {
      action: 'now',
      type: 'feedback',
      value
    });
  };

  renderForm(form) {
    const formClassNames = classnames('foresee-modal__form', {
      'foresee-modal__form--invalid': !this.state.emailValid
    });

    return (
      <form onSubmit={form.action} className={formClassNames}>
        <label htmlFor={form.id} className="foresee-modal__label">
          <span className="foresee-modal__label-text">{form.label}</span>
          <input
            className="foresee-modal__input"
            defaultValue={form.defaultValue}
            ref={this.saveInputRef}
            id={form.id}
          />
          {!this.state.emailValid && (
            <span className="foresee-modal__validation-message">
              {form.validationMessage}
            </span>
          )}
        </label>
        {this.renderCtas([form.submitButton], this.state.step)}
      </form>
    );
  }

  renderCtas(ctas, step) {
    return ctas.map((cta, ind) => (
      <ModalButtonContainer key={`${step}-${ind}`}>
        <button
          className={`button button-${cta.style} small`}
          onClick={cta.action}
          type={cta.type || 'button'}
        >
          {cta.text}
        </button>
      </ModalButtonContainer>
    ));
  }

  render() {
    const { step } = this.state;
    const content = this.content[step];

    return (
      <Modal
        className="foresee-modal"
        closeModal={this.handleClose}
        hasCloseButton={true}
        title={content.title}
      >
        <ModalContent>
          <button
            className="icon-cancel modal-buttons__close"
            onClick={this.handleClose}
            type="button"
          >
            <span className="visually-hidden">{'close dialogue'}</span>
          </button>
          <div>
            <SafelyOutputString>{content.copy}</SafelyOutputString>
          </div>
          <ModalActions>
            {content.form && this.renderForm(content.form)}
            {content.ctas && this.renderCtas(content.ctas, step)}
          </ModalActions>
        </ModalContent>
      </Modal>
    );
  }
}
