import { getFeatureVariableStringFromState } from '#/lib/optimizely-manager';
import { REGION_IE, ccStoreLocationDetailsVariants } from '#/experiments/oop-1627/constants';

const { useCustomerId, variationKey, variants, featureKey } = ccStoreLocationDetailsVariants;

const getVariant = (state: Store): string =>
  getFeatureVariableStringFromState(state, featureKey, variationKey, { useCustomerId }) || variants.default;

const getRegion = (state: Store): string | undefined => state?.app?.region;

export const isSimpleLocationDetails = (state: Store): boolean => {
  // LEGO-37498 - productionize for ie, retain holdout group for uk
  if (getRegion(state) === REGION_IE) {
    return true;
  }
  const variant = getVariant(state);
  return variant === variants.simpleLocationDetails;
};
