export const doubleValueTileUKTemplate = {
  x_for_x_pound: {
    valueContent: [
      {
        text: `%{value1} for`,
        size: 'xx'
      },
      {
        text: `%{value3}%{value2}`,
        size: ['xl', 'lg', 'md', 'sm']
      }
    ]
  },
  any_x_for_x_pound: {
    valueContent: [
      {
        text: `Any %{value1} for`,
        size: 'xx'
      },
      {
        text: `%{value3}%{value2}`,
        size: ['xl', 'lg', 'md', 'sm']
      }
    ]
  },
  any_x_for_x: {
    valueContent: [
      {
        text: 'Any',
        size: 'xs'
      },
      {
        text: `%{value1} for %{value2}`,
        size: 'xs'
      }
    ]
  },
  'buy_x_get_xx%_off': {
    valueContent: [
      {
        text: `Buy %{value1} get`,
        size: 'xxx'
      },
      {
        text: `%{value2}%`,
        size: 'lg'
      },
      {
        text: 'off',
        size: 'lg'
      }
    ]
  },
  was_x_pound_now_x_pound: {
    valueContent: [
      {
        text: `%{value3}%{value1}`,
        size: 'xx',
        strikethrough: true
      },
      {
        text: `%{value3}%{value2}`,
        size: ['xxxl', 'xxl', 'md', 'xs']
      }
    ]
  },
  buy_x_get_x_free: {
    valueContent: [
      {
        text: `Buy %{value1} get %{value2}`,
        size: 'xxx'
      },
      {
        text: 'free',
        size: 'lg'
      }
    ]
  },
  'save_x_/_x': {
    valueContent: [
      {
        text: 'Save',
        size: 'xl'
      },
      {
        text: '%{value1}/%{value2}',
        size: 'lg'
      }
    ]
  },
  any_x_for_x_clubcard_price: {
    horizontal: {
      valueContent: [
        {
          text: 'Any %{value1} for %{value2}',
          size: 'sm'
        }
      ]
    },
    square: {
      valueContent: [
        {
          text: 'Any',
          size: 'xxxx'
        },
        {
          text: '%{value1} for %{value2}',
          size: 'xs'
        }
      ]
    },
    mode: 'clubcard'
  },
  any_x_for_x_pound_clubcard_price: {
    horizontal: {
      valueContent: [
        {
          text: 'Any %{value1} for %{value3}%{value2}',
          size: 'sm'
        }
      ]
    },
    square: {
      valueContent: [
        {
          text: 'Any %{value1} for',
          size: 'xxxx'
        },
        {
          text: '%{value3}%{value2}',
          size: 'xs'
        }
      ]
    },
    mode: 'clubcard'
  },
  'xx%_off_x_clubcard_price': {
    horizontal: {
      valueContent: [
        {
          text: 'Extra %{value1}% off %{value2} or more',
          size: 'sm'
        }
      ]
    },
    square: {
      valueContent: [
        {
          text: 'Extra',
          size: 'xxxx'
        },
        {
          text: '%{value1}% off',
          size: 'xx'
        },
        {
          text: '%{value2} or more',
          size: 'xxxx'
        }
      ]
    },
    mode: 'clubcard'
  }
};
