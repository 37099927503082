const email = /^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/;
const numbers = /\d+/;
const letters = /[A-z]+/;
export const numbersOnly = /^\d+$/;
const numbersLettersWordWhiteSpaceOnly = /^[\d\w\s]+$/;
const numbersLettersWordOnly = /^[\d\w]+$/;
const coupon = /^[a-zA-Z0-9\\-]+$/;
const ukPostcode = /^(GIR ?0AA|[A-PR-UWYZ]([0-9]{1,2}|([A-HK-Y][0-9]([0-9ABEHMNPRV-Y])?)|[0-9][A-HJKPS-UW]) ?[0-9][ABD-HJLNP-UW-Z]{2})$/i; // from http://stackoverflow.com/questions/164979/uk-postcode-regex-comprehensive

// Regex to validate numbers and single dashes, and no dashes
// in the beginning or end
const phoneNumbersDashesOnly = /^(\d+-?)+\d+$/;

function hasValue(value) {
  return (
    typeof value !== 'undefined' &&
    value !== null &&
    (typeof value === 'string' ? value.trim().length > 0 : true)
  );
}

function phoneNumberLength(value, length) {
  return !hasValue(value) || value.length >= length;
}

const validators = {
  required(value) {
    return hasValue(value);
  },
  email_format(value) {
    return !hasValue(value) || email.test(value);
  },
  email_missing_at(value) {
    return !hasValue(value) || value.indexOf('@') !== -1;
  },
  password_length(value) {
    return !hasValue(value) || value.length >= 8;
  },
  numbers(value) {
    return !hasValue(value) || numbers.test(value);
  },
  numbers_only(value) {
    return !hasValue(value) || numbersOnly.test(value);
  },
  letters(value) {
    return !hasValue(value) || letters.test(value);
  },
  numbers_letters_ws_only(value) {
    return !hasValue(value) || numbersLettersWordWhiteSpaceOnly.test(value);
  },
  numbers_letters_words_only(value) {
    return !hasValue(value) || numbersLettersWordOnly.test(value);
  },
  must_match(value1, value2) {
    return value1 === value2;
  },
  must_not_match(value1, value2) {
    return value1 !== value2;
  },
  phone_number_five_digits(value) {
    return phoneNumberLength(value, 5);
  },
  phone_number_seven_digits(value) {
    return phoneNumberLength(value, 7);
  },
  phone_number_format(value) {
    return !hasValue(value) || numbersOnly.test(value);
  },
  phone_number_max_length(value) {
    return !hasValue(value) || value.length <= 20;
  },
  phone_numbers_dashes_only(value) {
    return !hasValue(value) || phoneNumbersDashesOnly.test(value);
  },
  postcode_five_digits(value) {
    return !hasValue(value) || (numbersOnly.test(value) && value.length === 5);
  },
  cvc_min_length(value) {
    return !hasValue(value) || value.length >= 3;
  },
  coupon_format(value) {
    return hasValue(value) && coupon.test(value);
  },
  clubcard_length(value, value2) {
    return (
      !hasValue(value) || (numbersOnly.test(value) && value.length === value2)
    );
  },
  membercard_length(value) {
    return !hasValue(value) || (numbersOnly.test(value) && value.length === 18);
  },
  uk_postcode(value) {
    return hasValue(value) && ukPostcode.test(value);
  }
};

export default function isValid(name, value1, value2) {
  return validators[name](value1, value2);
}

const getLength = text => (text || '').trim().length;
export const isEmpty = text => !hasValue(text);
export const isGreaterThan = (text, limit) => getLength(text) > limit;
export const isLesserThan = (text, limit) => getLength(text) < limit;
export const hasOnlyNumber = text => hasValue(text) && numbersOnly.test(text);
export const isAlphanumeric = text => /^[a-zA-Z0-9]+$/.test(text);
