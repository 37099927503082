import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import SlotGrid from '#/components/slots/slot-selector/slot-grid';
import SlotList from '#/components/slots/slot-selector/slot-list';
import DaySelector from '#/components/slots/slot-selector/day-selector';
import { DELIVERY } from '#/constants/shopping-methods';
import { connect } from '#/lib/render/connect-deep-compare';
import helpers from '#/lib/decorators/helpers';
import TextLink from '#/components/text-link';
import { getDeliverySaverApplicableDays } from '#/reducers/customer';
import { getIsMobile, getTimezone } from '#/reducers/app';
import { SHOPPING_METHOD_PROP_TYPE } from '#/components/slots/prop-types';
import {
  isSameDayMultipleDeliveryWarningForDate,
  getSelectedDate,
  getSelectedDateSlots,
  getDisplayDeliverySaverAcquisitionBanner
} from '#/reducers/slot';
import { exposeActiveTestDataBatch } from '#/lib/optimizely-manager';
import { getDisplayCookieInterrupt } from '#/reducers/ui';
import StickySlotListHeader from '#/experiments/oop-1335/components/sticky-slot-list-header';
import StickyElement from '#/experiments/oop-1335/components/sticky-element';
import {
  getIsHyphenVariant,
  getIsStickyHeaderSlotCellsUiUpdateVariant
} from '#/experiments/oop-1335/selectors';
import {
  getIsStickyHeaderVariant,
  getIsTimesOfDayVariantROI
} from '#/experiments/oop-1338/selectors';
import DeliverySaverAcquisitionBanner from '#/components/slots/ds-acquisition-banner';
import { StyledDeliverySaverSlotContainer } from '../../ds-acquisition-banner/styled';

const mapStateToProps = (state, { c: config }) => {
  const selectedDate = getSelectedDate(state);
  const selectedDateSlots = getSelectedDateSlots(state);
  const displayCookieInterrupt = getDisplayCookieInterrupt(state);
  const isStickyHeaderSlotCellsUiUpdateVariant = getIsStickyHeaderSlotCellsUiUpdateVariant(
    state
  );

  return {
    deliverySaverApplicableDays: getDeliverySaverApplicableDays(state),
    isMobile: getIsMobile(state),
    applyStickyHeaderVariant:
      selectedDateSlots.length > 0 &&
      getIsStickyHeaderVariant(state) &&
      !getIsTimesOfDayVariantROI(state),
    selectedDate,
    selectedDateSlots,
    showSameDayMultipleDeliveryWarning: isSameDayMultipleDeliveryWarningForDate(
      state,
      {
        date: selectedDate.format('YYYY-MM-DD'),
        slots: selectedDateSlots
      }
    ),
    timezone: getTimezone(state),
    displayCookieInterrupt,
    stickyHeaderVariant:
      getIsStickyHeaderSlotCellsUiUpdateVariant(state) &&
      selectedDateSlots.length > 0,
    isStickyHeaderSlotCellsUiUpdateVariant,
    isHyphenVariant: getIsHyphenVariant(state),
    showDeliverySaverAcquisitionBanner: getDisplayDeliverySaverAcquisitionBanner(
      config,
      state
    )
  };
};

@connect(mapStateToProps)
@helpers(['c', 't'])
class SlotContainer extends StickyElement {
  static propTypes = {
    applyStickyHeaderVariant: PropTypes.bool,
    areSlotsPending: PropTypes.bool,
    bookedSlot: PropTypes.object,
    c: PropTypes.func.isRequired,
    currentShoppingMethod: SHOPPING_METHOD_PROP_TYPE,
    currentSlotGroup: PropTypes.number,
    deliveryAddress: PropTypes.object,
    deliverySaverApplicableDays: PropTypes.array.isRequired,
    deliverySlotFeatureExperiments: PropTypes.shape({
      oop808: PropTypes.string
    }),
    displayCookieInterrupt: PropTypes.bool,
    isAmendBasket: PropTypes.bool,
    isHyphenVariant: PropTypes.bool.isRequired,
    isStickyHeaderSlotCellsUiUpdateVariant: PropTypes.bool.isRequired,
    onKeyPressed: PropTypes.func,
    previousSlot: PropTypes.object,
    selectedDate: PropTypes.object,
    selectedDateSlots: PropTypes.array,
    selectedLocation: PropTypes.object,
    selectedWeekRange: PropTypes.object,
    shoppingMethod: SHOPPING_METHOD_PROP_TYPE,
    slotGroups: PropTypes.array,
    slotRange: PropTypes.object,
    slotRangeByWeeks: PropTypes.array,
    stickyHeaderVariant: PropTypes.bool,
    t: PropTypes.func.isRequired,
    timezone: PropTypes.string.isRequired,
    showDeliverySaverAcquisitionBanner: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);
  }

  getDeliverySaverWarning(bookedSlot, currentShoppingMethod) {
    const { deliverySaverApplicableDays, timezone } = this.props;

    if (
      !bookedSlot ||
      !bookedSlot.start ||
      deliverySaverApplicableDays.length === 0
    )
      return false;

    const bookedSlotDay = moment
      .utc(bookedSlot.start)
      .tz(timezone)
      .isoWeekday();

    return (
      currentShoppingMethod === DELIVERY &&
      !deliverySaverApplicableDays.includes(bookedSlotDay)
    );
  }

  showSameDayMultipleDeliveryWarning() {
    const { c: config, showSameDayMultipleDeliveryWarning } = this.props;

    return (
      config('showSameDayMultipleDeliveryWarning') &&
      showSameDayMultipleDeliveryWarning
    );
  }

  render() {
    const {
      isStickyHeaderSlotCellsUiUpdateVariant,
      isHyphenVariant,
      isMobile,
      applyStickyHeaderVariant,
      bookedSlot,
      currentShoppingMethod,
      c: config,
      currentSlotGroup,
      selectedDate,
      selectedLocation,
      shoppingMethod,
      slotRange,
      previousSlot,
      deliveryAddress,
      deliverySlotFeatureExperiments,
      onKeyPressed,
      stickyHeaderVariant,
      areSlotsPending,
      showDeliverySaverAcquisitionBanner
    } = this.props;
    exposeActiveTestDataBatch(deliverySlotFeatureExperiments);

    const deliverySaverWarning = this.getDeliverySaverWarning(
      bookedSlot,
      currentShoppingMethod
    );

    const sameDayMultipleDeliveryWarning = this.showSameDayMultipleDeliveryWarning();

    return (
      <div
        className={classnames('slot-selector--tab-content', {
          'slot-selector--padding-bottom': !config('showAboutOurChargesLink'),
          'slot-selector--tab-content--oop1335': isStickyHeaderSlotCellsUiUpdateVariant,
          'slot-selector--tab-content--oop1335-hyphen': isHyphenVariant
        })}
      >
        <div
          className={classnames('hidden-large', {
            sticky__wrapper: applyStickyHeaderVariant
          })}
        >
          <span>
            {showDeliverySaverAcquisitionBanner && (
              <div className="delivery-saver-banner delivery-saver-banner--list">
                <StyledDeliverySaverSlotContainer className="hidden-small">
                  <DeliverySaverAcquisitionBanner />
                </StyledDeliverySaverSlotContainer>
              </div>
            )}
          </span>
          {stickyHeaderVariant && (
            <StickySlotListHeader
              fixedHeader={this.state.fixedHeader}
              headerOffset={this.state.headerOffset}
              headerAnimation={this.state.headerAnimation}
              currentSlotGroup={currentSlotGroup}
              selectedDate={selectedDate}
              selectedLocation={selectedLocation}
              shoppingMethod={shoppingMethod}
              slotRange={slotRange}
            />
          )}
          {/* Ref element associated to oop-1335 */}
          <div
            ref={this.topRef}
            className={classnames('hidden-small slot-selector--single-week', {
              'hide-from-view': stickyHeaderVariant && this.state.fixedHeader,
              sticky__header: applyStickyHeaderVariant
            })}
          >
            <DaySelector
              currentSlotGroup={currentSlotGroup}
              selectedDate={selectedDate}
              selectedLocation={selectedLocation}
              shoppingMethod={shoppingMethod}
              slotRange={slotRange}
            />
          </div>
          {/* Ref element associated to oop-1335 */}
          <div ref={this.bottomRef}>
            <SlotList
              bookedSlot={bookedSlot}
              previousSlot={previousSlot}
              selectedDate={selectedDate}
              shoppingMethod={shoppingMethod}
              deliverySaverWarning={deliverySaverWarning}
              sameDayMultipleDeliveryWarning={sameDayMultipleDeliveryWarning}
              onKeyPressed={onKeyPressed}
              areSlotsPending={areSlotsPending}
            />
          </div>
        </div>
        <div className="hidden-small hidden-medium-small-only hidden-medium-only">
          {!isMobile && (
            <SlotGrid
              isLargeScreen={true}
              bookedSlot={bookedSlot}
              deliverySaverWarning={deliverySaverWarning}
              deliveryAddress={deliveryAddress}
              previousSlot={previousSlot}
              selectedLocation={selectedLocation}
              shoppingMethod={shoppingMethod}
              onKeyPressed={onKeyPressed}
              areSlotsPending={areSlotsPending}
            />
          )}
        </div>
        <div
          className={classnames({
            'hidden-our-charges':
              !config('showAboutOurChargesLink') ||
              sameDayMultipleDeliveryWarning,
            'our-charges-bottom-padding': !(
              !config('showAboutOurChargesLink') ||
              sameDayMultipleDeliveryWarning
            )
          })}
        >
          {config('showAboutOurChargesLink') && (
            <TextLink
              textKey={`slots:${shoppingMethod}.about-our-charges`}
              url={config('links:deliveryCollectionCost')}
            />
          )}
        </div>
      </div>
    );
  }
}

export default SlotContainer;
