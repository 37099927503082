import Link from '@ddsweb/link';
import React, { memo } from 'react';
import { H3 } from '../../common/product-title/styles';
import LinkCheckSpa from '#/components/link-check-spa';
import { getAdditionalTitleStyles } from '#/components/product-tiles/horizontal-tile/core/styles';

type H3TitleProps = {
  disabled: boolean;
  preventDisabledTitleStyle: boolean | undefined;
  href: string;
  title: string;
};

const ProductSpaLink = (props: {
  'data-auto': string;
  href: string;
  className: string;
  onClick: () => void;
  children: React.ReactChildren;
}): JSX.Element => {
  const { 'data-auto': dataAuto, href, className, onClick, children } = props;
  return (
    <LinkCheckSpa data-auto={dataAuto} to={href} className={className} onClick={onClick}>
      {children}
    </LinkCheckSpa>
  );
};

export const ProductTitle = (props: H3TitleProps): JSX.Element => {
  const { disabled, preventDisabledTitleStyle, href, title } = props;
  return (
    <H3 disabled={disabled} preventDisabledTitleStyle={preventDisabledTitleStyle}>
      <Link
        variant="standalone"
        data-auto="product-tile--title"
        href={href}
        disabled={disabled}
        as={ProductSpaLink}
        styles={getAdditionalTitleStyles}
      >
        {title}
      </Link>
    </H3>
  );
};

export default memo(ProductTitle);
