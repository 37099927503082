import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import DepositNotice from '#/components/products/deposit-notice';
import { getProductDepositAmount, getProductIsForSale } from '#/selectors/item';

export default class RenderDepositNotice extends PureComponent {
  static propTypes = {
    displayAvailable: PropTypes.bool,
    item: PropTypes.object.isRequired
  };

  static defaultProps = {
    displayAvailable: false
  };

  render() {
    const { displayAvailable, item } = this.props;
    const depositAmount = getProductDepositAmount(item);

    if (depositAmount && (displayAvailable || getProductIsForSale(item))) {
      return <DepositNotice depositAmount={depositAmount} />;
    }

    return null;
  }
}
