import styled, { css } from 'styled-components';
import { media } from '@ddsweb/foundation';
import Link from '#/components/link-check-spa';
import { GREEN, GREY_DARK_2, GREY_MID_2, GREY_MID_3 } from '@ddsweb/constants';
import { PropsInterface } from '#/components/slots/option-tabs/header/styled/types.defs';

const styles = css`
  background: ${GREY_MID_3};
  color: ${(props: PropsInterface) => (props.isActive ? GREEN : GREY_DARK_2)};
  border-radius: 0;
  border-color: ${GREY_MID_2};
  border-top: ${(props: PropsInterface) => (props.isActive ? `1px solid ${GREY_MID_2}` : 'none')};
  border-right: ${(props: PropsInterface) => (props.isActive ? `1px solid ${GREY_MID_2}` : 'none')};
  border-left: ${(props: PropsInterface) => (props.isActive ? `1px solid ${GREY_MID_2}` : 'none')};
  padding: 0 16px;
  display: flex;
  font-size: 16px;
  height: 47px;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: ${(props: PropsInterface) => (props.isActive ? '1' : '0')};
  && {
    font-family: 'TESCO Modern', Arial, sans-serif;
    font-weight: ${(props: PropsInterface) => (props.isActive ? 'bold' : 'normal')};
  }

  ${(props: PropsInterface) =>
    props.isActive
      ? `
          &:after {
            content: '';
            position: absolute;
            bottom: 0;
            width: 80%;
            height: 4px;
            background: ${GREEN};
          }
        `
      : ''}

  && {
    ${media.belowMobileLarge`
        line-height: 1.25;
        height: 62px;
    `};
  }
`;

export const StyledSPALinkComponent = styled(Link)`
  ${styles}
`;

export const StyledLinkComponent = styled.a`
  ${styles}
`;
