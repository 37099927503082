import React, { useCallback, useMemo } from 'react';
import { compose } from 'react-recompose';
import { createSPALink } from '@ddsweb/helpers';
import { SlidingSubmenu } from '@ddsweb/local-navigation';
import Link from '#/components/link-check-spa';
import { SHOP } from '#/constants/common';
import { StyledMainMenu, StyledMenuContainer, StyledStaticSubmenu } from './styled';
import { createPrimaryNavigation } from '#/components/primary-navigation/helpers/taxonomy';
import { OwnState, PrimaryNavigationProps, PrimaryNavItem } from '#/components/primary-navigation/types';
import helpers from '#/lib/decorators/helpers';
import { connect } from '#/lib/render/connect-deep-compare';
import { getDeviceType, getLanguageLink } from '#/reducers/app';
import { getNavList } from '#/reducers/taxonomy';
import { PRIMARY_NAVIGATION, VISIBLE_MENU_ITEMS } from './constants';
import { ALL_DEPARTMENTS_KEY } from '../horizontal-taxonomy/types';
import { basicEvent } from '#/analytics/types/basic';
import { NOW, TOP_NAV } from '#/analytics/constants';
import analyticsBus from '#/analytics/analyticsBus';
import { fireAnalyticsForAllDepartmentsMenuItem } from './helpers/analytics';

const mapStateToProps = (state: Store): OwnState => {
  return {
    deviceType: getDeviceType(state),
    // @ts-expect-error
    navList: getNavList(state),
    shopUrl: getLanguageLink(state, SHOP),
  };
};

const connector = connect(mapStateToProps);

const PrimaryNavigation = (props: PrimaryNavigationProps): JSX.Element => {
  const { navList, t: translate, c: config, pathName, shopUrl, l: languageLink, cmsNav } = props;
  const menuItems = useMemo(
    () => createPrimaryNavigation({ navList, translate, config, shopUrl, languageLink, cmsNav }),
    [navList, translate, config, shopUrl, languageLink, cmsNav],
  );
  const configData = (config(PRIMARY_NAVIGATION) as Array<PrimaryNavItem>) || [];
  const [groceries, ...otherNav] = configData;

  const getSelectedNavId = (pathName?: string): null | string => {
    if (pathName) {
      const matchedItem = otherNav.find(item => pathName.includes(item.href));
      const selectedID = matchedItem ? matchedItem.id : pathName.includes(groceries.href) ? groceries.id : null;
      return selectedID;
    }
    return null;
  };
  const [selectedMenuItemID, setSelectedMenuItemID] = React.useState<null | string>(getSelectedNavId(pathName));

  // update selected menu item based on URL
  useMemo(() => {
    setSelectedMenuItemID(getSelectedNavId(pathName));
  }, [pathName]);

  const onChangeHandler = ({ selectedMenuItemID }: { selectedMenuItemID: string }): void => {
    setSelectedMenuItemID(selectedMenuItemID);
  };

  const moreMenuItem = useMemo(() => {
    const moreMenuText = translate('navigation:more');
    const moreMenuAriaLabel = translate('navigation:more-aria-label');

    return {
      href: '/',
      text: moreMenuText,
      ariaLabel: moreMenuAriaLabel,
      id: moreMenuText,
      onClick: () =>
        basicEvent(analyticsBus, {
          type: TOP_NAV,
          value: moreMenuText.toLowerCase(),
          action: NOW,
        }),
    };
  }, [translate]);

  const mobileMenuItem = useMemo(
    () => ({
      href: '/',
      text: translate('navigation:groceries'),
      ariaLabel: translate('navigation:groceries'),
      id: translate('navigation:groceries'),
    }),
    [translate],
  );

  /*
    This method programmatically sets the tab focus (when navigated using keyboard)
    to go inside the dropdown modal when a menu item with a pop-up is clicked.
    It then returns the focus back to the menu item when the dropdown modal is collapsed
    (by interacting with the menu item).
  */
  const setFocusInsideDropdown = (e: React.SyntheticEvent, { hasPopup }: { hasPopup: boolean }): void => {
    if (!hasPopup) {
      return;
    }

    const clickedElement = e.target as HTMLElement;
    const selectedMenuItem = clickedElement?.closest('a');

    /* 
      'targetNode' is the first common ancestor of the <ul/> the menu item of which was clicked 
      and the <ul/> of the dropdown modal that opened up consequentially. 
    */
    const targetNode = selectedMenuItem?.closest('ul')?.closest('div') as Node;

    const observer = new MutationObserver((mutationsList, observer) => {
      for (const mutation of mutationsList) {
        if (mutation.addedNodes.length > 0) {
          const dropdownModal = document.querySelector('#ddsweb-main-menu-slidedown-modal') as HTMLElement;
          const focusableElements = (dropdownModal.querySelectorAll(
            'a, button, [tabindex]:not([tabindex="-1"])',
          ) as unknown) as HTMLElement[];

          if (focusableElements.length > 0) {
            focusableElements[0].focus();
            observer.disconnect();
          }
          break;
        }

        if (mutation.removedNodes.length > 0) {
          selectedMenuItem?.focus();
          observer.disconnect();
          break;
        }
      }
    });

    observer.observe(targetNode, { childList: true });
  };

  const handleSlidingSubmenuClick = (e: React.SyntheticEvent): void => {
    const target = e.target as HTMLElement;
    const link = target?.closest('a');

    const id = link?.getAttribute('id') ?? '';
    const name = (link?.getAttribute('name') ?? '').toLowerCase();
    const innerText = (link?.textContent?.trim() || '').toLowerCase();
    const label = link?.getAttribute('label') ?? '';
    const hasPopup = link?.getAttribute('aria-haspopup') === 'true';

    fireAnalyticsForAllDepartmentsMenuItem({
      id,
      name,
      innerText,
      label,
      hasPopup,
    });
  };

  const getSlidingSubmenu = useCallback(() => {
    const allDepartmentItem = menuItems.find(nav => {
      return nav.name?.toLowerCase().includes(ALL_DEPARTMENTS_KEY);
    });

    if (allDepartmentItem) {
      const hasSubItems = allDepartmentItem.children?.some(item => {
        return item.children?.length;
      });

      if (hasSubItems) {
        return <SlidingSubmenu onClick={handleSlidingSubmenuClick} />;
      }
    }
    return <StyledStaticSubmenu />;
  }, [menuItems]);

  const SPALink = useMemo(
    () =>
      createSPALink(Link, props => ({
        onClick: props.onClick,
        to: props.href,
        className: props.className,
        role: props.role,
        id: props.id,
        name: props.name,
        label: props.label,
        target: props.target,
        ['aria-current']: props['aria-current'],
        ['aria-expanded']: props['aria-expanded'],
        ['aria-haspopup']: props['aria-haspopup'],
      })),
    [],
  );

  return (
    <StyledMenuContainer>
      <StyledMainMenu
        className={'menu-items'}
        menuItems={menuItems}
        retainHighlightOfPreviousItem={false}
        mobileMenuItem={mobileMenuItem}
        moreMenuItem={moreMenuItem}
        spaLink={SPALink}
        onChange={onChangeHandler}
        onClick={setFocusInsideDropdown}
        selectedMenuItemID={selectedMenuItemID}
        root={false}
        visibleMenuItems={VISIBLE_MENU_ITEMS}
        closeLinkText={translate('close')}
      >
        {getSlidingSubmenu()}
      </StyledMainMenu>
    </StyledMenuContainer>
  );
};

const enhance = compose(helpers(['t', 'c', 'l']), connector);
export default enhance(PrimaryNavigation);
