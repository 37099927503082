// todo: This is a temporary measure until we decide how too use breakpoints with Beans
const SMALLER_MAX = 500;
const SMALL_MAX = 599;
const MEDIUM_SMALL_MAX = 767;
const MEDIUM_MIN = 768;
const MEDIUM_LARGE_MIN = 992;

export const REST_OF_SHELF_MEDIUM = `(min-width: 470px)`;
export const SMALL_MIN = `(min-width: 504px)`;
export const SMALL = `(max-width: ${SMALL_MAX}px)`;
export const SMALLER = `(max-width: ${SMALLER_MAX}px)`;
export const SMALL_MEDIUM = `(max-width: ${MEDIUM_SMALL_MAX}px)`;
export const MEDIUM = `(min-width: ${MEDIUM_MIN}px)`;
export const MEDIUM_LARGE = `(min-width: ${MEDIUM_LARGE_MIN}px)`;
export const MEDIUM_LARGE_MAX = `(max-width: ${MEDIUM_LARGE_MIN - 1}px)`;

export const SPACING_SMALL = '6px';
export const SPACING_MEDIUM = '12px';

const BLUE_2 = '#6ea6df';
export const ORANGE = '#f90';
export const GREEN = '#008209';

export const TILE_BORDER_HOVER_COLOR = BLUE_2;
export const NOTIFICAION = {
  WARNING: '#fefadb',
};
