import moment from 'moment-timezone';
import { cloneDeep } from '#/utils/clone-deep';
import { COLLECTION, DELIVERY } from '#/constants/shopping-methods';
import { isOnDemandShoppingMethod } from '#/lib/shopping-method-util';
import {
  CANCELLED,
  SUPPORTED_WISMO_TRACKING_STATUS
} from '#/constants/order-statuses';
import { SORT_BY } from '#/constants/common';

export const isCancelled = status => {
  return status.toLowerCase() === CANCELLED.toLowerCase();
};

const isTescoInitiated = order => {
  return order?.statusInfo?.reason === 'TESCO_INITIATED';
};

export function isPendingOrder(order, timezone) {
  const now = moment().tz(timezone);
  const orderExpiry = moment(order.slot.end).tz(timezone);

  return now.isBefore(orderExpiry) && !isCancelled(order.status);
}

export const sortOrdersBySlotStartTime = (
  orders,
  timezone,
  clone = true,
  sortOrder = SORT_BY.ASC
) => {
  const result = clone ? cloneDeep(orders) : orders;

  if (!result || result.length <= 1) {
    return result;
  }

  const order = sortOrder === SORT_BY.ASC ? -1 : 1;

  const sortFn = (a, b) => {
    const aStart = moment(a.slot.start)
      .tz(timezone)
      .valueOf();
    const bStart = moment(b.slot.start)
      .tz(timezone)
      .valueOf();
    return order * (bStart - aStart);
  };

  return result.sort(sortFn);
};

export const isAfterAmendCutoff = (order, timezone) => {
  const now = moment()
    .utc()
    .tz(timezone);

  const orderAmentCutOffTime = moment(order.amendExpiryTimeStamp).tz(timezone);

  return now.isAfter(orderAmentCutOffTime);
};

export const showCancelledContextCard = (order, timezone) => {
  const now = moment()
    .utc()
    .tz(timezone);

  // date right now should be after midnight of
  // order due date.
  return (
    isCancelled(order.status) &&
    isTescoInitiated(order) &&
    !now.isAfter(moment(order.slot.end).endOf('day'))
  );
};

/**
 * Checks if order/orders is/are scheduled for today
 * Does this for a given order type
 * @param {Object/Array} orders
 * @param {String} timezone
 * @param {String/Array} types - The type(s) of order (collection/delivery)
 */
export function isOrderToday(orders, timezone, types = [COLLECTION, DELIVERY]) {
  const typeArray = [].concat(types);
  return []
    .concat(orders)
    .filter(order => typeArray.includes(order.shoppingMethod))
    .some(order => {
      return isDayOfDelivery(order, timezone);
    });
}

export const isDayOfDelivery = (order, timezone) =>
  moment()
    .tz(timezone)
    .isSame(moment(order.slot.start).tz(timezone), 'day');

export function checkAmendCutoff(amendCutoff, timezone) {
  const now = moment().tz(timezone);

  return now.isBefore(moment(amendCutoff));
}

export function isWismoEnabled(req) {
  const isWismoEnabledByEnvVar = process.env.WISMO_IS_ENABLED;

  const isWismoEnabled =
    isWismoEnabledByEnvVar === '' ||
    typeof isWismoEnabledByEnvVar === 'undefined'
      ? req.f('enableWismo')
      : isWismoEnabledByEnvVar === 'true';

  return isWismoEnabled;
}

export const isSupportedTrackingStatus = order =>
  Object.values(SUPPORTED_WISMO_TRACKING_STATUS).includes(
    getDeliveryTrackingStatus(order)
  );

export const isOrderDeliveryScheduled = order =>
  getDeliveryTrackingStatus(order) ===
  SUPPORTED_WISMO_TRACKING_STATUS.DELIVERY_SCHEDULED;

export const isOrderDelivered = order =>
  getDeliveryTrackingStatus(order) ===
  SUPPORTED_WISMO_TRACKING_STATUS.DELIVERED;

export const isWismoTrackingStatusUnAvailable = order =>
  !getDeliveryTrackingStatus(order) ||
  getDeliveryTrackingStatus(order) ===
    SUPPORTED_WISMO_TRACKING_STATUS.NOT_AVAILABLE;

export const getDeliveryTrackingStatus = order =>
  order?.deliveryTracking?.currentTrackingStatus?.status;

const isOrderDeliveredWithinDisplayWindow = (
  orderDeliveryTime,
  deliveryStatus,
  timezone,
  wismoOrderDeliveredDisplayInMinutes
) => {
  const now = moment()
    .utc()
    .tz(timezone);
  return (
    deliveryStatus === SUPPORTED_WISMO_TRACKING_STATUS.DELIVERED &&
    orderDeliveryTime &&
    now.isBefore(
      moment(orderDeliveryTime)
        .tz(timezone)
        .add(wismoOrderDeliveredDisplayInMinutes, 'minutes')
    )
  );
};

const isOrderToBeDeliveredToday = (order, timezone) => {
  const isDeliveryDay = isDayOfDelivery(order, timezone);
  return (
    isDeliveryDay &&
    order.status !== CANCELLED &&
    isSupportedTrackingStatus(order)
  );
};

export const isHomeDeliveryWismoOrder = (order, timezone) =>
  order.shoppingMethod === DELIVERY &&
  isOrderToBeDeliveredToday(order, timezone) &&
  isAfterAmendCutoff(order, timezone);

export const isLegacyWismoOrder = (order, timezone) =>
  isOrderToBeDeliveredToday(order, timezone) &&
  isOrderDeliveryScheduled(order) &&
  isAfterAmendCutoff(order, timezone);

export const isOnDemandWismoOrder = (
  order,
  timezone,
  wismoOrderDeliveredDisplayInMinutes
) =>
  isOnDemandShoppingMethod(order.shoppingMethod) &&
  isOrderToBeDeliveredToday(order, timezone) &&
  (!isOrderDelivered(order) ||
    isOrderDeliveredWithinDisplayWindow(
      order.deliveryTracking?.deliveryWindow?.end,
      getDeliveryTrackingStatus(order),
      timezone,
      wismoOrderDeliveredDisplayInMinutes
    ));

export const mapDeliveryTrackingStatus = order => {
  // TODO: During Wismo stepper productionizing,
  // move NOT_AVAILABLE statuse to use its own logic/UI
  // which does not translate to PREPARING_ORDER
  let newOrder = { ...order };

  if (isWismoTrackingStatusUnAvailable(order)) {
    newOrder.deliveryTracking = {
      currentTrackingStatus: {
        status: SUPPORTED_WISMO_TRACKING_STATUS.PREPARING_ORDER
      }
    };
  }

  return newOrder;
};

export const getWismoStepperOrder = orders => {
  if (!Array.isArray(orders) || orders.length === 0) return null;
  const order = orders && orders[0];
  order.showWismoStepper = true;
  return order;
};

export const sortOrdersByDeliveryStartTime = (orders, timezone) => {
  return orders.sort((a, b) => {
    const currentWismoOrderDeliveryStart =
      a.deliveryTracking?.deliveryWindow?.start;
    const nextOrderDeliveryStart = b.deliveryTracking?.deliveryWindow?.start;

    const currentWismoOrderDeliveryStartValue = currentWismoOrderDeliveryStart
      ? moment(currentWismoOrderDeliveryStart)
          .tz(timezone)
          .valueOf()
      : 0;
    const nextOrderDeliveryStartValue = nextOrderDeliveryStart
      ? moment(nextOrderDeliveryStart)
          .tz(timezone)
          .valueOf()
      : 0;

    return currentWismoOrderDeliveryStartValue - nextOrderDeliveryStartValue;
  });
};

export function transformProductItem(item) {
  const { product, ...info } = item;
  const { price, ...productInfo } = product;
  const substitutions = productInfo.substitutions || [];

  productInfo.substitutions = substitutions.reduce((result, substitute) => {
    substitute = { ...substitute, ...substitute.price };
    result.push({ product: substitute });

    return result;
  }, []);
  return {
    name: productInfo.title,
    ...info,
    product: { ...price, ...productInfo }
  };
}

export const isOrderWithinSlotWindow = (slot, timezone) => {
  const startTime = moment(slot.start).tz(timezone);
  const endTime = moment(slot.end).tz(timezone);
  const now = moment().tz(timezone);

  return now.isBetween(startTime, endTime, []);
};
