import { Product } from '#/lib/records/item.defs';
import { PlainObject } from '#/types';

export type ProductWithContent = Product & { node: PlainObject };

export type ContentDetails = {
  id: string;
  type: string;
  typeId: string;
  tracking: {
    id: string;
  };
  attributes: {
    altText: string;
    pageUrl: string;
    mediaUrl: string;
    title: string;
    subHeading: string;
    buttonText: string;
  };
};

export type ContentItem = {
  content: ContentDetails;
};

export enum ResultType {
  PRODUCT = 'ProductType',
  CONTENT = 'ContentType',
}
