import { Items } from '#/custom-typings/redux-store/trolley.defs';

type GroupedItems = Map<string, Items>;

export default function groupAndSort(items: Items): GroupedItems {
  const groupedItems = new Map() as GroupedItems;

  items.forEach((item, id) => {
    const groupKey = item.groupName || '';

    if (groupedItems.has(groupKey)) {
      const group = groupedItems.get(groupKey) as Items;

      group.set(id, item);
      groupedItems.set(groupKey, group);
    } else {
      const newGroup = new Map();

      newGroup.set(id, item);
      groupedItems.set(groupKey, newGroup);
    }
  });

  const filteredItems = new Map();

  groupedItems.forEach((items, groupedCategory) => {
    if (Array.from(items.values()).some(item => !item.isWhyNotTry)) {
      filteredItems.set(groupedCategory, items);
    }
  });

  return filteredItems;
}
