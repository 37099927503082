import React from 'react';
import PropTypes from 'prop-types';
import helpers from '#/lib/decorators/helpers';
import {
  ADD_MODE,
  ADDED_MODE,
  BLOCKED_MODE,
  UPDATE_MODE,
  BLOCKED_UPDATE_MODE,
  BLOCKED_ADD_MODE,
  BLOCKED_ADDED_MODE
} from './constants';
import AmountAdjustButton from '../../../amount-adjust-button';
import DisplayOnlyInJs from '#/components/shared/display-only-in-js';
import DisplayOnlyInNoJs from '#/components/shared/display-only-in-no-js';
import { UNDELIVERABLE_ADDRESS_MODAL } from '#/constants/modal-names';
import { addModalToUrl } from '#/lib/url/modal-utils';
import { WEIGHT_UNIT_KG as UNIT_KG } from '#/constants/common';

@helpers(['t', 'c'])
export default class TileButtons extends React.PureComponent {
  constructor(props) {
    super(props);
    this.addItemBlocked = this.addItemBlocked.bind(this);
    this.onAddItem = this.onAddItem.bind(this);
    this.onIncrementItem = this.onIncrementItem.bind(this);
    this.onDecrementItem = this.onDecrementItem.bind(this);
  }

  static propTypes = {
    basketId: PropTypes.string,
    currentUrl: PropTypes.string,
    deliveryAddress: PropTypes.object,
    disableAdd: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    disableRemove: PropTypes.bool.isRequired,
    inputValue: PropTypes.string,
    itemInBasket: PropTypes.bool.isRequired,
    itemTitle: PropTypes.string.isRequired,
    onAdd: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    renderMode: PropTypes.oneOf([
      ADD_MODE,
      ADDED_MODE,
      BLOCKED_MODE,
      UPDATE_MODE,
      BLOCKED_UPDATE_MODE,
      BLOCKED_ADDED_MODE,
      BLOCKED_ADD_MODE
    ]),
    t: PropTypes.func.isRequired,
    unit: PropTypes.string.isRequired,
    updateQuantity: PropTypes.func.isRequired,
    userRegistered: PropTypes.bool.isRequired
  };

  static defaultProps = {
    renderMode: UPDATE_MODE
  };

  onAddItem() {
    this.props.onAdd();
  }

  addItemBlocked() {
    const { deliveryAddress, openModal } = this.props;

    openModal(UNDELIVERABLE_ADDRESS_MODAL, deliveryAddress.name);
  }

  onIncrementItem() {
    this.props.updateQuantity(1);
  }

  onDecrementItem() {
    this.props.updateQuantity(-1);
  }

  renderAddButton(onAddFnName) {
    const {
      disabled,
      disableAdd,
      inputValue,
      itemInBasket,
      itemTitle,
      userRegistered,
      t: translate
    } = this.props;
    const isInputValid =
      (+inputValue >= 1 || itemInBasket) && this.isInputValid();

    const addAriaLabel = translate('product-tile:type-of-product', {
      quantity: itemInBasket ? 1 : inputValue,
      title: itemTitle
    });

    return (
      <button
        data-auto="add-button"
        className={'button small add-control button-secondary'}
        type="submit"
        disabled={disabled || disableAdd || !isInputValid}
        onClick={(userRegistered && this[onAddFnName]) || null}
      >
        <span aria-hidden="true">{translate('product-list:add')}</span>
        <span className="visually-hidden">{addAriaLabel}</span>
      </button>
    );
  }

  renderUpdateButton(onAddFnName) {
    const { disabled, itemTitle, onUpdate, userRegistered, t } = this.props;

    return (
      <button
        data-auto="update-button"
        className="button small add-control update-button button-primary"
        type="submit"
        disabled={disabled || !this.isInputValid()}
        onClick={userRegistered && (this[onAddFnName] || onUpdate)}
      >
        {t('common:update')}
        <span className="visually-hidden">{itemTitle}</span>
      </button>
    );
  }

  renderPlusMinusButtons(onAddFnName) {
    const {
      unit,
      disabled,
      disableAdd,
      disableRemove,
      t: translate,
      userRegistered,
      itemTitle
    } = this.props;
    const isUnitKg = unit === UNIT_KG;

    return (
      <span className="product-tile--amount-control">
        <AmountAdjustButton
          kgUnit={isUnitKg}
          disabled={disabled || disableRemove}
          iconClassName="icon-minus"
          label={`${translate('product-list:remove')} ${itemTitle}`}
          onClick={
            (userRegistered && (this[onAddFnName] || this.onDecrementItem)) ||
            null
          }
        />
        <AmountAdjustButton
          kgUnit={isUnitKg}
          disabled={disabled || disableAdd}
          iconClassName="icon-plus"
          label={translate('product-tile:type-of-product', {
            quantity: 1,
            title: itemTitle
          })}
          onClick={
            (userRegistered && (this[onAddFnName] || this.onIncrementItem)) ||
            null
          }
        />
      </span>
    );
  }

  renderLinkToDialog(buttonText) {
    const { currentUrl, deliveryAddress, itemTitle } = this.props;

    return (
      <a
        className={'button small add-control button-secondary'}
        href={addModalToUrl(
          currentUrl,
          UNDELIVERABLE_ADDRESS_MODAL,
          deliveryAddress
        )}
      >
        <span aria-hidden="true">{this.props.t(buttonText)}</span>
        <span className="visually-hidden">
          {this.props.t('product-tile:add-to-basket', {
            title: itemTitle
          })}
        </span>
      </a>
    );
  }

  isInputValid() {
    const { inputValue } = this.props;

    return (
      inputValue !== '' && inputValue !== null && +inputValue >= 0 && inputValue
    );
  }

  render() {
    switch (this.props.renderMode) {
      case ADD_MODE:
        return this.renderAddButton('onAddItem');
      case ADDED_MODE:
        return (
          <span className="added-state">
            <DisplayOnlyInJs HtmlTag="span">
              {this.renderPlusMinusButtons()}
            </DisplayOnlyInJs>
            <DisplayOnlyInNoJs HtmlTag="span">
              {this.renderUpdateButton('')}
            </DisplayOnlyInNoJs>
          </span>
        );
      case BLOCKED_ADD_MODE:
        return (
          <span>
            <DisplayOnlyInJs HtmlTag="span">
              {this.renderAddButton('addItemBlocked')}
            </DisplayOnlyInJs>
            <DisplayOnlyInNoJs HtmlTag="span">
              {this.renderLinkToDialog('product-list:add')}
            </DisplayOnlyInNoJs>
          </span>
        );
      case BLOCKED_UPDATE_MODE:
        return (
          <span>
            <DisplayOnlyInJs HtmlTag="span">
              {this.renderUpdateButton('addItemBlocked')}
            </DisplayOnlyInJs>
            <DisplayOnlyInNoJs HtmlTag="span">
              {this.renderLinkToDialog('common:update')}
            </DisplayOnlyInNoJs>
          </span>
        );
      case BLOCKED_ADDED_MODE:
        return (
          <span>
            <DisplayOnlyInJs HtmlTag="span">
              {this.renderPlusMinusButtons('addItemBlocked')}
            </DisplayOnlyInJs>
            <DisplayOnlyInNoJs HtmlTag="span">
              {this.renderLinkToDialog('common:update')}
            </DisplayOnlyInNoJs>
          </span>
        );
      default:
        return this.renderUpdateButton('');
    }
  }
}
