import { TAXONOMY } from '#/constants/spa-resource';
import { getTaxonomyLazyLoadedStatus } from '#/reducers/taxonomy';
import {
  setTaxonomyLazyLoadedStatus,
  resetTaxonomy
} from '#/actions/taxonomy-action-creators';
import { shouldPersistTaxonomyPosition } from '#/experiments/oop-1406/selectors';

export const type = TAXONOMY;
export const params = ['includeChildren', 'deliveryType', 'storeId'];

export function comparator(
  paramsFromState,
  paramsFromNewRequest,
  isMatchingParams
) {
  if (isMatchingParams) return false;

  return (
    !paramsFromState.includeChildren && !!paramsFromNewRequest.includeChildren
  );
}

export function handler(dispatch, getState) {
  const state = getState();
  const isTaxonomyLazyLoaded = getTaxonomyLazyLoadedStatus(state);
  const isPersistTaxonomyEnabled = shouldPersistTaxonomyPosition(state);

  !isPersistTaxonomyEnabled && dispatch(resetTaxonomy());

  if (isTaxonomyLazyLoaded) {
    dispatch(setTaxonomyLazyLoadedStatus(false));
  }
}
