import React from 'react';
import styled from 'styled-components';
import { ERROR, INFO } from '@ddsweb/constants';
import { Panel } from '@ddsweb/foundation';
import { spacing } from '@ddsweb/selectors';
import { InLineMessaging } from '@ddsweb/messaging';
import Text from '@ddsweb/text';
import helpers from '#/lib/decorators/helpers';
import { connect } from '#/lib/render/connect-deep-compare';
import { getLanguage, getTimezone } from '#/reducers/app';
import { getFormattedDate } from '#/lib/i18n/date-utils';
import { TConfigFunc, TTranslateFunc } from '#/lib/records/helpers.defs';
import { getOnDemandServiceAvailableTime, getOnDemandUnavailableReason } from '#/selectors/slot';
import { ON_DEMAND } from '#/constants/shopping-methods';
import { TIME_FORMAT } from '#/constants/common';

const StyledPanel = styled(Panel)`
  border-radius: 6px;
  margin-top: ${spacing.sm};
  border-color: #b4b4b4;
`;

type IState = {
  openingHour: number;
  closingHour: number;
  onDemandUnavailableReason?: string | null | false | undefined;
};

const mapStateToProps = (state: Store): IState => {
  const language = getLanguage(state);
  const timezone = getTimezone(state);

  const { startDateTime, endDateTime } = getOnDemandServiceAvailableTime(state) || {};
  const serviceUnavailableReason = getOnDemandUnavailableReason(state);

  const openingHour = getFormattedDate(startDateTime, TIME_FORMAT, timezone, language);
  const closingHour = getFormattedDate(endDateTime, TIME_FORMAT, timezone, language);

  return {
    openingHour,
    closingHour,
    onDemandUnavailableReason: serviceUnavailableReason,
  };
};

interface TOwnProps extends IState {
  t: TTranslateFunc;
  c: TConfigFunc;
}

const OnDemandRestrictionMessage: React.FC<TOwnProps> = (props: TOwnProps) => {
  const { t: translate, c: config, onDemandUnavailableReason, openingHour, closingHour } = props;

  const ageRestrictionTranslationKey = `slots:${ON_DEMAND}.over-18-description`;

  const ageRestrictionMessage = translate(ageRestrictionTranslationKey, {
    age: config('customAgeRestriction'),
  });

  let messageVariant: typeof INFO | typeof ERROR = INFO;
  let assistiveText = translate('common:information');

  let message = ageRestrictionMessage;

  if (onDemandUnavailableReason) {
    messageVariant = ERROR;
    assistiveText = translate('common:pages.error');
    message = translate(`slots:${ON_DEMAND}.delivery-${onDemandUnavailableReason}`, {
      openingHour,
      closingHour,
    });
  }

  return (
    <div className="hidden-large">
      <StyledPanel variant="border">
        <InLineMessaging variant={messageVariant} assistiveText={assistiveText}>
          <Text as="p">{message}</Text>
        </InLineMessaging>
      </StyledPanel>
    </div>
  );
};

export default helpers(['t', 'c'])(connect(mapStateToProps)(OnDemandRestrictionMessage));
