import React, { Component } from 'react';
import PropTypes from 'prop-types';
import deepEqual from 'fast-deep-equal/es6';
import classnames from 'classnames';
import { connect } from '#/lib/render/connect-deep-compare';
import { getTescoRecommendations } from '#/actions/recommendations-action-creators';
import helpers from '#/lib/decorators/helpers';
import Recommender from './recommender';
import {
  getHasTrexError,
  getIsTrexEmpty,
  getTrexRecommendations
} from '#/reducers/recommendations';
import { getTrexEnabled, getIsMobile } from '#/reducers/app';
import LazyComponent from '../shared/lazy-component';
import { getRecommenderConfig } from '#/lib/recommender-helpers';
import { getOptimizelyFeatureVariableString } from '#/actions/experiments-action-creators';
import { trexMultiPlacement } from '#/constants/features';
import { formatOptimizelyFeatureKey } from '#/lib/optimizely-manager';
import { getItems } from '#/selectors/trolley';
import {
  findAndSetTrolleyItems,
  itemsToMap
} from '#/lib/records/product-utils';

const mapStateToProps = state => ({
  hasTrexError: getHasTrexError(state),
  isTrexEmpty: getIsTrexEmpty(state),
  recommendations: getTrexRecommendations(state),
  trexEnabled: getTrexEnabled(state),
  enableInMobile: getIsMobile(state),
  trolleyItems: getItems(state)
});

@helpers(['c'])
@connect(mapStateToProps, {
  getTescoRecommendations,
  getOptimizelyFeatureVariableString
})
export default class RecommenderWrapper extends Component {
  static propTypes = {
    analyticsComponentName: PropTypes.string,
    c: PropTypes.func.isRequired,
    classes: PropTypes.string,
    configKey: PropTypes.string.isRequired,
    displayArea: PropTypes.string,
    excludedProducts: PropTypes.array,
    getOptimizelyFeatureVariableString: PropTypes.func.isRequired,
    getTescoRecommendations: PropTypes.func.isRequired,
    hasTrexError: PropTypes.bool,
    isChosenForYouCarousel: PropTypes.bool,
    isPdpPage: PropTypes.bool,
    isTrexEmpty: PropTypes.bool,
    moduleType: PropTypes.string,
    onRenderRecommendations: PropTypes.func,
    page: PropTypes.string,
    panelPos: PropTypes.number,
    preloaded: PropTypes.bool,
    query: PropTypes.string,
    recommendationDisplayType: PropTypes.string,
    recommendations: PropTypes.object,
    recommendationsCount: PropTypes.number,
    showBeansCarousel: PropTypes.bool,
    showLoading: PropTypes.bool,
    targetTPNBs: PropTypes.array,
    title: PropTypes.string,
    transitionIn: PropTypes.bool,
    trexEnabled: PropTypes.bool.isRequired,
    trexIdentifier: PropTypes.string,
    isIBYCRecommendation: PropTypes.bool
  };

  static defaultProps = {
    excludedProducts: [],
    panelPos: 1,
    preloaded: false,
    showBeansCarousel: false,
    recommendationsCount: 24
  };

  constructor(props) {
    super(props);
    this.recommenderConfig = getRecommenderConfig(props);
    this.isRecommendationEnabled =
      props.c('recommenders:enabled') && props.trexEnabled; // first flag from config, second one from env variable

    this.recommenderRef = React.createRef();

    this.state = {
      dataLoaded: props.preloaded,
      transitionedIn: false,
      inlineStyles: props.transitionIn
        ? { maxHeight: '0px', opacity: '0' }
        : { maxHeight: null, opacity: null }
    };
  }

  componentDidMount() {
    const { showLoading, transitionIn } = this.props;
    this.canReceiveUpdates = true;

    if (showLoading && transitionIn) {
      this.transitionFrame = window.requestAnimationFrame(this.transitionIn);
    }
  }

  componentDidUpdate() {
    const { recommendations, showLoading, transitionIn } = this.props;
    const { transitionedIn } = this.state;
    const hasRecommendations = recommendations?.size > 0;

    if (hasRecommendations && transitionIn && !transitionedIn && !showLoading) {
      this.transitionIn();
    }
  }

  transitionIn = () => {
    if (typeof window === 'undefined') {
      return;
    }

    const originalHeight = this.recommenderRef.current.scrollHeight;
    const currentInlineStyles = this.state.inlineStyles;

    window.cancelAnimationFrame(this.transitionFrame);

    this.transitionFrame = window.requestAnimationFrame(() => {
      this.setState({
        inlineStyles: {
          ...currentInlineStyles,
          maxHeight: `${originalHeight}px`,
          opacity: '1'
        },
        transitionedIn: true
      });

      this.transitionTimeout = setTimeout(this.clearTransitionIn, 400);
    });
  };

  clearTransitionIn = () => {
    const currentInlineStyles = this.state.inlineStyles;

    this.setState({
      inlineStyles: {
        ...currentInlineStyles,
        maxHeight: null,
        opacity: null
      }
    });
  };

  componentWillUnmount() {
    this.canReceiveUpdates = false;

    window.cancelAnimationFrame(this.transitionFrame);
    clearTimeout(this.transitionTimeout);
  }

  componentWillReceiveProps(nextProps) {
    if (!this.isRecommendationEnabled) {
      return;
    }

    const {
      configKey,
      excludedProducts,
      onRenderRecommendations,
      query,
      targetTPNBs,
      recommendations
    } = this.props;

    const {
      configKey: nextConfigKey,
      excludedProducts: nextExcludedProducts,
      query: nextQuery,
      targetTPNBs: nextTargetTPNBs,
      recommendations: nextRecommendations,
      recommendationsCount: nextRecommendationsCount
    } = nextProps;

    const nextFilteredRecommendations = this.getfilteredRecommendation(
      nextRecommendations,
      nextRecommendationsCount
    );

    const hasRecommendations = nextFilteredRecommendations?.size > 0;

    if (
      nextConfigKey !== configKey ||
      !deepEqual(nextExcludedProducts, excludedProducts) ||
      nextQuery !== query ||
      !deepEqual(nextTargetTPNBs, targetTPNBs)
    ) {
      this.recommenderConfig = getRecommenderConfig(nextProps);
      this.loadRecommendations(nextProps);
    } else if (
      hasRecommendations &&
      this.hasRecommendationsListChanged(
        recommendations,
        nextFilteredRecommendations
      ) &&
      typeof onRenderRecommendations === 'function' &&
      this.isVisible()
    ) {
      onRenderRecommendations(nextFilteredRecommendations);
    }
  }

  isVisible() {
    const element = this.recommenderRef.current;

    return !!(element && element.offsetHeight);
  }

  hasRecommendationsListChanged(recommendations, nextRecommendations) {
    const currentKeys = recommendations
      ? Array.from(recommendations.keys())
      : null;
    const nextKeys = nextRecommendations
      ? Array.from(nextRecommendations.keys())
      : null;

    if (currentKeys && nextKeys) {
      return JSON.stringify(currentKeys) !== JSON.stringify(nextKeys);
    }

    return currentKeys !== nextKeys;
  }

  loadRecommendations = props => {
    const {
      c: config,
      configKey,
      excludedProducts,
      getOptimizelyFeatureVariableString,
      getTescoRecommendations,
      query,
      targetTPNBs
    } = props || this.props;

    var { recommendationsCount } = props || this.props;
    const {
      pageId,
      position,
      pageName,
      variant: configVariant
    } = this.recommenderConfig;

    // The variant,position and strategy is pulled from a local config on lego and unless overridden are sent to mango in the getTescoRecommendations
    let variant = configVariant;

    const siteId = config('optimizely:siteId');
    const region = config('REGION');
    // We retrieve the `strategy` variable and  `position` variable from optimizely and pass this `strategy` to mango
    // in the `variant` key along with a `strategy` key set to 'variant'.
    // NB the default experiment values are also hard-coded into the config.
    const optimizelySpecificFeature = formatOptimizelyFeatureKey(
      siteId,
      region,
      `trex-${configKey}`
    );
    const optimizelySpecificVariant = getOptimizelyFeatureVariableString(
      optimizelySpecificFeature,
      'strategy'
    );
    if (optimizelySpecificVariant) {
      variant = optimizelySpecificVariant;
    } else {
      // If no optimizelySpecificVariant specific for the recommendation panel is sent we will
      // blanket feature for optimizely which can look at various strategies
      // NB the default experiment values are also hard-coded into the config.
      const optimizelyBlanketFeature = formatOptimizelyFeatureKey(
        siteId,
        region,
        trexMultiPlacement.key
      );
      const optimizelyVariantStrategyReponse = getOptimizelyFeatureVariableString(
        optimizelyBlanketFeature,
        configKey,
        trexMultiPlacement.useCustomerId
      );

      // for the blanket feature the strategy and variant are passed in a single string split with a slash
      // to be as defensive as possible we split it and only use if there are 2 strings in the array
      // must be exactly 2 for the strategy and variant
      const splitVariantStrategy =
        optimizelyVariantStrategyReponse &&
        optimizelyVariantStrategyReponse.split('/');
      if (splitVariantStrategy && splitVariantStrategy.length === 2) {
        // TODO: To streamline optimizely configs to adhere to new format (not passing strategy) and refactor code to not require string-split
        variant = splitVariantStrategy[1];
      }
    }

    return getTescoRecommendations({
      pageId,
      excludedProducts,
      position,
      pageName,
      variant,
      query,
      targetTPNBs: targetTPNBs || [excludedProducts[0]],
      count: recommendationsCount
    }).then(() => {
      return new Promise(resolve => {
        if (this.canReceiveUpdates) {
          this.setState(
            {
              dataLoaded: true
            },
            resolve
          );
        } else {
          resolve(false);
        }
      });
    });
  };

  filterRecommendation(recommendations, numOfFilteredProducts) {
    const filteredRecommendationsArray = Array.from(
      recommendations.entries()
    ).filter((itemTuple, index) => {
      return index < numOfFilteredProducts;
    });

    return new Map(filteredRecommendationsArray);
  }

  getfilteredRecommendation(recommendations, recommendationsCount) {
    var filteredRecommendations = recommendations;

    const isMoreNumberOfProducts =
      recommendations?.size >= recommendationsCount;

    if (isMoreNumberOfProducts) {
      filteredRecommendations = this.filterRecommendation(
        recommendations,
        recommendationsCount
      );
    }

    return filteredRecommendations;
  }
  mergeRecommendationItemsWithQuantities(recommendations) {
    const { trolleyItems } = this.props;

    const productItems = [...recommendations.values()];
    const productItemsWithQuantities = findAndSetTrolleyItems(
      productItems,
      trolleyItems
    );

    return itemsToMap(productItemsWithQuantities);
  }

  renderRecommender() {
    const {
      page,
      configKey,
      displayArea,
      hasTrexError,
      isTrexEmpty,
      moduleType,
      panelPos,
      preloaded,
      recommendations,
      recommendationDisplayType,
      showLoading,
      showBeansCarousel,
      isPdpPage,
      enableInMobile,
      title,
      isChosenForYouCarousel,
      analyticsComponentName,
      trexIdentifier,
      isIBYCRecommendation
    } = this.props;
    const { dataLoaded } = this.state;
    const recommenderConfig = this.recommenderConfig;
    const isSideList = recommendationDisplayType === 'sidelist';
    const showRestOfShelfMobilePdpPage = !(isPdpPage && enableInMobile);
    let recommendationsWithQuantities;

    if (isChosenForYouCarousel && dataLoaded && recommendations) {
      recommendationsWithQuantities = this.mergeRecommendationItemsWithQuantities(
        recommendations
      );
    }

    return (
      <Recommender
        configKey={configKey}
        displayArea={displayArea}
        hasTrexError={dataLoaded && hasTrexError}
        isTrexEmpty={dataLoaded && isTrexEmpty}
        panelPos={panelPos}
        recommendations={
          dataLoaded ? recommendationsWithQuantities || recommendations : null
        }
        lastTrex={true}
        pageId={page || recommenderConfig.pageId}
        moduleType={moduleType || recommenderConfig.moduleType}
        recommendationDisplayType={recommendationDisplayType}
        showLoading={showLoading && !preloaded}
        showRestOfShelf={showRestOfShelfMobilePdpPage && !isSideList}
        showWriteReview={showRestOfShelfMobilePdpPage && !isSideList}
        showBeansCarousel={showBeansCarousel}
        title={title}
        isChosenForYouCarousel={isChosenForYouCarousel}
        analyticsComponentName={analyticsComponentName}
        trexIdentifier={trexIdentifier}
        isIBYCRecommendation={isIBYCRecommendation}
      />
    );
  }

  renderLazyComponent() {
    return (
      <LazyComponent
        target=".recommender-wrapper"
        fetchData={this.loadRecommendations}
      />
    );
  }

  render() {
    if (!this.isRecommendationEnabled) {
      return null;
    }

    const { dataLoaded, inlineStyles } = this.state;
    const { classes } = this.props;
    return (
      <div
        className={classnames('recommender-wrapper', classes)}
        style={inlineStyles}
        ref={this.recommenderRef}
      >
        {!dataLoaded ? this.renderLazyComponent() : null}
        {this.renderRecommender()}
      </div>
    );
  }
}
