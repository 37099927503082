export default function deliverySaverPayload(data) {
  if (!Object.entries(data).length) {
    return null;
  }
  return {
    hasSubscription: data.planId !== null,
    planDuration: data.planDurationInMonths,
    hasFreeSameDayDelivery: data.freeSameDayDelivery,
    planType: data.isTrialPlan ? 'trial' : data.planType
  };
}
