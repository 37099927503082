import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withTheme } from 'styled-components';
import FooterList from './footer-list';
import FooterLogo from './footer-logo';
import helpers from '../../lib/decorators/helpers';
import { TescoLogo } from '@ddsweb/branding';
import { getFullYear } from '#/lib/date-helpers';
import { connect } from '#/lib/render/connect-deep-compare';
import { getTimezone } from '#/reducers/app';
import { getIsUserAuthenticated, getIsUserRegistered } from '#/reducers/user';

const mapStateToProps = state => {
  return {
    timezone: getTimezone(state),
    isUserAuthenticated: getIsUserAuthenticated(state),
    isUserRegistered: getIsUserRegistered(state)
  };
};

export function Footer(props) {
  const {
    c: config,
    isAmendBasket,
    shouldExpand,
    showCardsInFooter,
    simpleFooter,
    timezone,
    t: translate
  } = props;

  const renderFooterLists = () => {
    const { c: config, isUserAuthenticated, isUserRegistered } = props;

    if (
      config('footer:hideForAnonymous') &&
      (!isUserAuthenticated || !isUserRegistered)
    ) {
      return null;
    }

    const footerData = config('footer:data');

    return footerData.map(section => {
      return (
        <FooterList
          {...section}
          shouldExpand={shouldExpand}
          key={section.title}
        />
      );
    });
  };

  const getCopyrightText = () => {
    const fullYear = getFullYear(timezone);

    const copyrightConfig = config('footer:copyright');

    if (copyrightConfig) {
      return config('footer:copyright').replace(/\$copyrightYear/, fullYear);
    }

    return translate('footer:copyright', {
      fullYear
    });
  };

  const renderFooterLower = () => {
    return (
      <div className="footer__lower">
        <FooterLogo
          showCardsInFooter={showCardsInFooter}
          simpleFooter={simpleFooter}
        />
      </div>
    );
  };

  const renderLists = !simpleFooter;

  const renderLower =
    showCardsInFooter || config('footer:additionalFooterLogos');

  return (
    <div
      className={classnames('footer-wrapper', {
        'simple-footer': simpleFooter,
        'amend-mode': isAmendBasket
      })}
    >
      <footer className="footer">
        <div className="footer__upper">
          <div className="footer__header">
            <div className="footer__logo-wrapper">
              <TescoLogo scale={true} grayscale={true} />
            </div>
          </div>
          {renderLists && renderFooterLists()}
          <div className="footer__centered">
            {/* TODO tagline font needed */}
            <div className="footer__tagline icon-strapline" />
            <div className="footer__copyright">{`© ${getCopyrightText()}`}</div>
          </div>
        </div>
        {renderLower && renderFooterLower()}
      </footer>
    </div>
  );
}

Footer.propTypes = {
  c: PropTypes.func.isRequired,
  cardIcons: PropTypes.array,
  isAmendBasket: PropTypes.bool,
  isUserAuthenticated: PropTypes.bool.isRequired,
  isUserRegistered: PropTypes.bool.isRequired,
  securityIcons: PropTypes.array,
  shouldExpand: PropTypes.bool,
  showCardsInFooter: PropTypes.bool,
  showFooter: PropTypes.bool,
  simpleFooter: PropTypes.bool,
  t: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired
};

Footer.defaultProps = {
  cardIcons: ['visa', 'mastercard', 'maestro'],
  isAmendBasket: false,
  selectedFacetLists: [],
  securityIcons: ['safe_key', 'verified_by_visa', 'mastercard_securecode'],
  simpleFooter: false,
  showFooter: true,
  showCardsInFooter: true,
  shouldExpand: false
};

export default connect(mapStateToProps)(
  helpers(['c', 'f', 't'])(withTheme(Footer))
);
