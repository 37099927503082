import { RECEIVE_FULFILMENT_ESTIMATED_ARRIVAL } from '#/constants/action-types';
import { getFulfilmentEstimatedArrivalResource } from '#/selectors/resources';
import { Dispatch, GetStore } from '#/custom-typings/redux-store/common';
import { FULFILMENT_ESTIMATED_ARRIVAL } from '#/constants/spa-resource';

export const type = FULFILMENT_ESTIMATED_ARRIVAL;

export function handler(dispatch: Dispatch, getState: GetStore): void {
  const state = getState();
  const { data = null } = getFulfilmentEstimatedArrivalResource(state) || {};

  if (!data) {
    return;
  }

  dispatch({
    type: RECEIVE_FULFILMENT_ESTIMATED_ARRIVAL,
    value: data,
  });
}
