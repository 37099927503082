import React, { Component } from 'react';
import PropTypes from 'prop-types';
import page from 'page';
import classnames from 'classnames';
import queryString from 'querystring';
import FilterOption from './filter-option';
import helpers from '#/lib/decorators/helpers';
import { getCurrentUrl } from '#/reducers/app';
import { connect } from '#/lib/render/connect-deep-compare';
import * as filterType from '#/constants/facet-categories';

const mapStateToProps = state => ({
  currentUrl: getCurrentUrl(state)
});

@connect(mapStateToProps)
@helpers(['t', 'currentPathname'])
export default class FilterSelector extends Component {
  static propTypes = {
    ariaLive: PropTypes.string,
    category: PropTypes.string.isRequired,
    currentPathname: PropTypes.func.isRequired,
    currentUrl: PropTypes.string.isRequired,
    filter: PropTypes.object.isRequired,
    selected: PropTypes.bool.isRequired,
    showCount: PropTypes.bool,
    t: PropTypes.func.isRequired
  };

  static defaultProps = {
    showCount: true
  };

  onCheckboxClick = (event, count, url) => {
    event.preventDefault();

    if (count !== 0) page(url);
  };

  sumBinCount = facets => {
    return facets.reduce((prev, curr) => prev + curr.binCount, 0);
  };

  addFilterUrl = category => {
    const qs = queryString.parse(this.props.currentUrl.split('?')[1] || '');

    delete qs.page;
    delete qs.offset;
    const view = qs.viewAll ? qs.viewAll.split(',') : [];

    view.push(category);
    qs.viewAll = view.join(',');
    qs[category] =
      this.props.filter &&
      this.props.filter.facets.map(f => f.facetId).join(',');

    return this.props.currentPathname() + '?' + queryString.stringify(qs);
  };

  removeFilterUrl = category => {
    const qs = queryString.parse(this.props.currentUrl.split('?')[1] || '');

    delete qs.page;
    delete qs.offset;
    delete qs[category];
    let view = (qs.viewAll || '').split(',');

    view.splice(view.indexOf(category), 1);

    if (view.length) {
      qs.viewAll = view.join(',');
    } else {
      delete qs.viewAll;
    }

    const urlParams = queryString.stringify(qs);

    return (
      this.props.currentPathname() + (urlParams.length ? '?' + urlParams : '')
    );
  };

  render() {
    const { category, selected, showCount, ariaLive } = this.props;
    const url = selected
      ? this.removeFilterUrl(category)
      : this.addFilterUrl(category);
    const isFavourite = category === filterType.FAVOURITES.toLowerCase();
    const binCount = !isFavourite
      ? this.sumBinCount(this.props.filter.facets)
      : null;
    const className = classnames(`${category}-selector`, {
      disabled: binCount === 0
    });

    const label = this.props.t(`sort-and-filter:${category}`);

    return (
      <FilterOption
        ariaLive={ariaLive}
        className={className}
        count={binCount || 0}
        id={`${category}-selector`}
        label={label}
        onChange={e => this.onCheckboxClick(e, binCount, url)}
        selected={selected}
        url={url}
        longLabelsAllowed={true}
        showCount={showCount}
      />
    );
  }
}
