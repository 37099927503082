import { transformPromotedOfferProducts } from '../lib/records/product-utils';

const transformPromotionsContent = promotionsContent =>
  promotionsContent.map(panels => {
    const children = (panels.children || []).map(child => {
      const item = { ...child, props: { ...child.props } };

      if (item.type === 'Special-Offers') {
        item.props.content = (child.props.content || []).map(childContent => {
          const content = { ...childContent };

          if (content.products && content.products.length) {
            content.products = transformPromotedOfferProducts(content.products);
          }

          return content;
        });
      }

      return item;
    });

    return {
      ...panels,
      children
    };
  });

export const transformPromotionsSpecialOffersContent = (
  promotionsContent = {}
) => ({
  top:
    promotionsContent.top && transformPromotionsContent(promotionsContent.top),
  bottom:
    promotionsContent.bottom &&
    transformPromotionsContent(promotionsContent.bottom)
});
