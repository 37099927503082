import React from 'react';
import PropTypes from 'prop-types';
import { SPALink } from '#/components/trolley/full-trolley/basket-slot-card/shared';
import {
  StyledBasketSlotContextCardContainer,
  StyledBasketNoSlotContextCardStatus,
  StyledBasketNoSlotContextCardHeader,
  StyledInlineLink,
} from './styled';

export interface TProps {
  buttonLink: string;
  buttonText: string | JSX.Element;
  className: string;
  introText: string;
  isAuthenticated?: boolean;
  amountChanging?: number;
  language?: string;
  guidePrice: number;
  onClick: () => void;
}

const BasketSlotContextCardNoSlotBooked = ({
  buttonLink,
  buttonText,
  introText,
  onClick,
  className,
}: TProps): JSX.Element => {
  return (
    <StyledBasketSlotContextCardContainer className={className}>
      <StyledBasketNoSlotContextCardStatus>
        <StyledBasketNoSlotContextCardHeader headingLevel="5">{introText}</StyledBasketNoSlotContextCardHeader>
        <StyledInlineLink href={buttonLink} onClick={onClick} as={SPALink}>
          {buttonText}
        </StyledInlineLink>
      </StyledBasketNoSlotContextCardStatus>
    </StyledBasketSlotContextCardContainer>
  );
};

BasketSlotContextCardNoSlotBooked.propTypes = {
  buttonLink: PropTypes.string.isRequired,
  buttonText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  introText: PropTypes.string.isRequired,
};

export default BasketSlotContextCardNoSlotBooked;
