import { mediumMax } from '#/constants/viewports';
import styled from 'styled-components';
import { colors, spacing } from '@ddsweb/selectors';

export const StyledMiniTrolley = styled.div`
  .basket-tile {
    @media (max-width: ${mediumMax}) {
      grid-template-areas:
        'image name name name remove'
        'image price price price price'
        'image charges charges charges .'
        'image decrement number increment increment';
    }
  }
  .line-break {
    display: none;
    @media (max-width: ${mediumMax}) {
      display: initial;
    }
  }
  .ddsweb-value-bar__promo-text {
    @media (max-width: ${mediumMax}) {
      margin-right: 0;
    }
  }
  // Used for the mixed basket expander
  .basket-expander-button {
    background-color: ${colors.white};
    padding: ${spacing.sm};
    button {
      white-space: nowrap;
    }
  }
`;
