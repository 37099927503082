// these should match the api response
export const DELIVERY = 'delivery';
export const COLLECTION = 'collection';
export const ON_DEMAND = 'ondemand';

export const IMMEDIATE = 'IMMEDIATE';
export const GHS_DELIVERY = 'GHS_DELIVERY';
export const GHS_COLLECT = 'GHS_COLLECT';

export type ShoppingMethod = typeof COLLECTION | typeof DELIVERY | typeof ON_DEMAND;

//The below match the universal getFulfilment Mango query
export const DELIVERY_VAN = 'DELIVERY_VAN';
export const DELIVERY_THIRD_PARTY_ONDEMAND = 'DELIVERY_THIRD_PARTY_ONDEMAND';
export const DELIVERY_THIRD_PARTY_SAMEDAY = 'DELIVERY_THIRD_PARTY_SAMEDAY';
export const COLLECTION_STORE = 'COLLECTION_STORE';
export const DYNAMIC_DELIVERY = '-dynamic-delivery';
export const DELIVERY_TODAY = '-delivery-today';

//The below match the universal GetFulfilmentOptions Mango query
export const AVAILABLE = 'AVAILABLE';
export const UNSUPPORTED = 'UNSUPPORTED';
export const UNAVAILABLE = 'UNAVAILABLE';

//Ondemand shopping method unavailable states
export const OUT_OF_CATCHMENT = 'OUT_OF_CATCHMENT';
export const OUTSIDE_WORKING_HOURS = 'OUTSIDE_WORKING_HOURS';
export const FULFILMENT_CAPACITY_FULL = 'FULFILMENT_CAPACITY_FULL';

export enum ORDER_SHOPPING_METHOD {
  COLLECTION = 'collection',
  DELIVERY = 'delivery',
}
