/**
 * This file serves an important part in Slots page flow and functionality.
 * Whenever making changes to this file make sure to update the related
 * documentation diagrams present in dir: web/docs/slots
 *
 * @author beetlejuice
 */

import { NEW_SLOT_DATA_FROM_RESOURCES } from '#/constants/action-types';
import {
  ADDRESSES,
  SLOT,
  FULFILMENT_METADATA,
  FULFILMENT_OPTIONS_DETAIL,
  TROLLEY_CONTENTS
} from '#/constants/spa-resource';
import { getTimezone } from '#/reducers/app';
import {
  getSlotResource,
  getFulfilmentMetadataResource
} from '#/selectors/resources';

export const type = SLOT;

export const params = ['query', 'shoppingMethod', 'start', 'end', 'date'];

export const dependencies = [
  ADDRESSES,
  TROLLEY_CONTENTS,
  FULFILMENT_METADATA,
  FULFILMENT_OPTIONS_DETAIL
];

export const handler = (dispatch, getState) => {
  const state = getState();
  const { data } = getSlotResource(state);

  const availableSlotGroups = getFulfilmentMetadataResource(state)?.data
    ?.availableSlotGroups;

  if (!data) throw new Error('No data in Slots resource response');

  if (!data.slots) throw new Error('No slots in Slots resource response');

  const timezone = getTimezone(state);
  const newSlotData = {
    ...data,
    availableSlotGroups
  };

  dispatch({
    type: NEW_SLOT_DATA_FROM_RESOURCES,
    value: {
      data: newSlotData,
      timezone
    }
  });
};
