//This is for react-intl which the side basket uses for the mfe shared package
import en from '@mfe/basket/en.json';
import cs from '@mfe/basket/cs.json';
import hu from '@mfe/basket/hu.json';
import sk from '@mfe/basket/sk.json';

export const getMessagesLocale = (language: string, region: string): string => {
  if (language === 'en-GB') {
    switch (region) {
      case 'UK':
        return 'en-GB';
      case 'IE':
        return 'en-IE';
      case 'HU':
        return 'en-HU';
      case 'SK':
        return 'en-SK';
      case 'CZ':
        return 'en-CZ';
      default:
        return language;
    }
  }
  return language;
};

export const getCountryTranslation = (language: string): Object => {
  switch (language) {
    case 'en-GB':
      return en;
    case 'hu-HU':
      return hu;
    case 'sk-SK':
      return sk;
    case 'cs-CZ':
      return cs;
    default:
      return en;
  }
};

export const getCurrencyLocale = (currencyISO: string, locale: string): string => {
  switch (currencyISO) {
    case 'HUF':
      return 'hu-HU';
    case 'CZK':
      return 'cs-CZ';
    case 'EUR':
      if (locale === 'en-IE') return 'en-IE';
      return 'sk-SK';
    default:
      return 'en-GB';
  }
};
