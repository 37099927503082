import React from 'react';
import { TextButtonLink } from '@ddsweb/link';
import { connect } from '#/lib/render/connect-deep-compare';
import helpers from '#/lib/decorators/helpers';
import { getLoginUrl, getRegisterUrl } from '#/reducers/app';
import { emitRegisterAnalytics } from '#/analytics/helpers/register';
import { TTranslateFunc } from '#/lib/records/helpers.defs';

type StoreProps = {
  registrationUrl: string;
  loginUrl: string;
};

export type Props = StoreProps & {
  t: TTranslateFunc;
};

const AuthCtaContainer = ({ t: translate, registrationUrl, loginUrl }: Props): JSX.Element => {
  const fireAnalytics = (): void => {
    (window?.event?.target as HTMLElement)?.className.includes('register') && emitRegisterAnalytics('header');
  };
  return (
    <>
      <TextButtonLink data-testid="sign-in" href={loginUrl} buttonVariant="primary" stretch>
        {translate('common:sign-in')}
      </TextButtonLink>

      <TextButtonLink onClick={fireAnalytics} href={registrationUrl} buttonVariant="secondary" stretch>
        {translate('common:register')}
      </TextButtonLink>
    </>
  );
};

const mapStateToProps = (state: Store): StoreProps => {
  return {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    registrationUrl: getRegisterUrl(state as any),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    loginUrl: getLoginUrl(state as any),
  };
};

export default helpers(['t'])(connect(mapStateToProps)(AuthCtaContainer));
