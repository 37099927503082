import { updateParamsInUrl } from '../lib/url/url-utils';
import { MULTI_SEARCH_PAGE } from '../constants/query-strings';

/**
 *  Takes an array of strings and:
 *    * trims elements
 *    * removes empty elements
 *    * separates comma-delimited elements
 *    * removes duplicates (keeping first entry and preserving character case)
 */
export function sanitizeList(listItems) {
  const cleanedListItems = [];
  const uniqueValues = [];

  listItems.forEach(inputItem => {
    if (typeof inputItem === 'string') {
      const separatedItems = inputItem.includes(',')
        ? inputItem.split(',')
        : [inputItem];

      separatedItems.forEach(text => {
        const trimmedText = text.trim();
        const lowercaseText = trimmedText.toLowerCase();

        if (trimmedText && !uniqueValues.includes(lowercaseText)) {
          uniqueValues.push(lowercaseText);
          cleanedListItems.push(trimmedText);
        }
      });
    }
  });

  return cleanedListItems;
}

export function addMultiSearchParamsToUrl(
  urlStr,
  pageNumber,
  querystrings = {}
) {
  return updateParamsInUrl(urlStr, {
    [MULTI_SEARCH_PAGE]: String(pageNumber),
    ...querystrings
  });
}
