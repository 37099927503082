import { createSelector } from 'reselect';
import { isResultsTypeFactory } from '#/selectors/results/is-results-type';
import { SEARCH } from '#/constants/spa-resource';
import { isMultiSearch } from '#/selectors/search/is-multi-search';

const getResultsTypeIsSearch = isResultsTypeFactory(SEARCH);

export const areMultiSearchResults = createSelector(
  [getResultsTypeIsSearch, isMultiSearch],
  (isSearchResults, multiSearch) => isSearchResults && multiSearch
);
