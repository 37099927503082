import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from '#/lib/render/connect-deep-compare';
import { getIsFirstTimeShopper, getIsUserAuthenticated } from '#/reducers/user';
import { emitRenderedContentOp } from '#/analytics/bertie/events';
import {
  PANEL,
  DFP_BANNER,
  KNOWN_INTERACTIVE_TYPES
} from '#/constants/dcs-component-types';
import { getContentEventType } from '#/analytics/helpers/dcs';
const mapStateToProps = state => ({
  isFirstTimeShopper: getIsFirstTimeShopper(state),
  isUserAuthenticated: getIsUserAuthenticated(state)
});

@connect(mapStateToProps)
export default class ContentEvent extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    isFirstTimeShopper: PropTypes.bool.isRequired,
    isUserAuthenticated: PropTypes.bool.isRequired,
    pageName: PropTypes.string.isRequired,
    ruleId: PropTypes.string,
    sectionOrder: PropTypes.arrayOf(PropTypes.string).isRequired
  };

  componentDidMount() {
    this.contentRendered();
  }

  contentRendered() {
    const panels = this.panels().map(content => ({
      componentType: 'c',
      displayArea: 'm-of',
      modulePosition: content.panelPos,
      pageType: this.props.pageName,
      panel: content.components?.map(component => ({
        campaignId: component.campaign,
        campaignTitle: component.title,
        posInModule: component.posInPanel,
        ruleId: component.ruleId,
        contentSegment: component.segments,
        trackingId: component.trackingId
      }))
    }));
    emitRenderedContentOp({ content: panels });
  }

  getAnalyticsSegments() {
    const authenticated = this.props.isUserAuthenticated;
    const segments = {
      'u-an': !authenticated,
      'u-li': authenticated,
      'u-ft': this.props.isFirstTimeShopper
    };

    return Object.keys(segments).filter(segment => segments[segment]);
  }

  flattenPanels(data) {
    // Find all leaves and return an array.
    return data
      .filter(node => node)
      .reduce((result, node) => {
        if (node.children) {
          return result.concat(this.flattenPanels(node.children));
        }

        result.push(node);

        return result;
      }, []);
  }

  flattenSections(data, sectionOrder) {
    const mappedSections = sectionOrder.map(sectionKey => {
      if (
        data.content[sectionKey] &&
        Array.isArray(data.content[sectionKey].children)
      ) {
        return data.content[sectionKey];
      }

      return this.wrapOrphanedComponent(data.content[sectionKey]);
    });

    return mappedSections.reduce(
      (flattened, section) => [].concat(flattened, section),
      []
    );
  }

  groupByPanel(data) {
    // Group leaves by Panel
    let numPanels = 0;

    return data.reduce((acc, component) => {
      // If the components placement in the panel is 1, wrap it in a new generic Panel.
      if (component.props.posInPanel === 1) {
        return acc.concat({
          type: PANEL,
          props: {},
          children: [component],
          panelPos: ++numPanels
        });
      }

      if (acc.length) {
        // Else add it to the previous panel.
        const currentPanel = acc[acc.length - 1];

        if (currentPanel && currentPanel.children) {
          currentPanel.children.push(component);
        }
      }

      return acc;
    }, []);
  }

  panels() {
    // Our data allows arbitrarily nested panels but analytics assumes a flat list;
    // Meaning we only keep the leaves and we group those by panels.
    const segments = this.getAnalyticsSegments();
    const flatmap = this.flattenPanels(
      this.flattenSections(this.props.data, this.props.sectionOrder)
    );

    // Once we've located all the leaves and grouped them, they need to be decorated with extra tracking information.
    const filteredData = this.filterRedundantContent(flatmap);

    return this.groupByPanel(filteredData).map((panel, index) =>
      this.panelRendered(panel, index, segments)
    );
  }

  filterRedundantContent(componentData) {
    return componentData.filter(
      component =>
        this.isVisibleComponent(component) && this.isInteractive(component)
    );
  }

  isVisibleComponent(component) {
    return component && !component.props.hideAds;
  }

  isInteractive(component) {
    return (
      !!component.props.link || KNOWN_INTERACTIVE_TYPES.includes(component.type)
    );
  }

  wrapOrphanedComponent(componentData) {
    return {
      type: PANEL,
      props: {},
      children: [].concat(componentData)
    };
  }

  panelRendered(panelData, panelIndex, segments) {
    if (panelData.type !== PANEL) {
      panelData = this.wrapOrphanedComponent(panelData);
    }

    const panelPos = panelIndex + 1; // analytics guys like to count from 1 rather than 0

    return {
      panelPos,
      components: panelData.children
        .map(component => this.componentRendered(component, segments))
        .reduce(
          (components, next) =>
            Array.isArray(next)
              ? components.concat(next)
              : [...components, next],
          []
        )
    };
  }
  getComponentId(component) {
    return component.type === DFP_BANNER
      ? 'DfpBanner/DfpBannerComponent'
      : component.props.catId;
  }
  componentRendered(component, segments) {
    const { props, type: componentType } = component;
    const {
      slides,
      contentId,
      headline,
      title,
      posInPanel,
      placement,
      ruleId,
      trackingId
    } = props;

    if (slides) {
      return slides.map(this.slideRendered.bind(component));
    }
    return {
      campaign: (
        contentId ||
        props.type ||
        componentType ||
        headline ||
        ''
      ).trim(),
      title: title || headline,
      posInPanel,
      placement,
      id: this.getComponentId(component),
      ruleId,
      segments,
      contentType: getContentEventType(component.props),
      moduleType: 'm-of', // hard-coded for time being. May be dynamic if components library project is resurrected
      trackingId
    };
  }

  slideRendered(slide) {
    return {
      campaign: slide.props.contentId || '',
      title: slide.tabTitle || slide.props.title,
      posInPanel: slide.props.posInPanel,
      placement: slide.props.placement,
      id: 'Slider/SliderComponent',
      ruleId: this.props.ruleId,
      moduleType: 'm-of', // hard-coded for time being. May be dynamic if components library project is resurrected
      trackingId: slide.props.trackingId
    };
  }

  render() {
    return null;
  }
}
