import {
  RESTRICTED_ORDER_AMENDMENT,
  RESTRICTED_ORDER_AMENDMENT_WITH_ITEM,
  STOCK_QUANTITY_EXCEEDED,
} from '#/constants/restrictions';
import { WARNING_MESSAGE_BACKGROUND_CLASS } from '#/constants/product-tile';
import { PERMANENTLY_UNAVAILABLE, EPW_WITHOUT_AMEND_AVAILABILITY_OVERRIDE } from '#/constants/unavailable-product-type';
import {
  getProductBulkBuyLimit,
  atMaxQty,
  getQuantity,
  hasRestrictedOrderAmendment,
  getRestrictedOrderAmendmentRestriction,
  getRestrictedOrderAmendmentMessage,
  isRdgViolation,
  getRdgRestrictions,
  getItemLimitReached,
  getGroupLimitReached,
  isVariableWeightProduct,
  atRestrictedMaxQty,
  hasGroupBulkBuyLimit,
  hasSubstitution as getProductHasSubstitution,
  getProductStatus,
  getProductIsForSale,
  getStockAvailableQuantity,
  getIsSubstitutionBlocked,
} from '#/selectors/item';
import { Restriction } from '#/lib/records/item.defs';
import {
  RDG_DATE_RANGE,
  RDG_DAY_TIME,
  RDG_LEAD_TIME,
  RDG_UNAVAILABLE_DATE_RANGE,
  RDG_LEAD_TIME_WITH_ITEM,
} from '#/constants/restrictions';
import {
  TInfoMessageComponent,
  TInfoMessageFormat,
  TRestrictedOrderAmendmentMessageFormat,
} from '#/selectors/beans-product-tile/info-message-data';
import { getIsAmendBasket } from '#/selectors/trolley';
import { Item } from '#/lib/records/item';
import { isAvailableEpwOverride } from '#/selectors/beans-product-tile/product-availability-data';
import { onDemandTranslationSuffix } from '#/lib/shopping-method-util';

type TTranslateFunc = (...args: any[]) => string;
type TConfigFunc = (configKey?: string[] | string) => string;

export type TBulkBuyLimitMessageData = {
  message: string | null;
  backgroundColorClass: string;
};

export type TRDGMessage = {
  message: string;
  showChangeSlot: boolean;
  displayWarningMessage: boolean;
} | null;

export type TRDGRestrictionData = {
  message: string;
  showChangeSlot: boolean;
  displayWarningMessage: boolean;
} | null;

export type TCombinedRDGMessagesData = {
  text: string[];
  backgroundColorClass: string | null;
  showChangeSlot: boolean;
} | null;

export type TExcludedProductMessageData = {
  message: string;
  backgroundColorClass: string;
} | null;

export type TVariableWeightMessageData = {
  message: string;
} | null;

export type TUnavailableMessageData = {
  message: string;
  backgroundColorClass: string;
} | null;

export type TBlockedSubsMessageData = {
  message: string;
  backgroundColorClass: string;
} | null;

export type TStockQtyExceededMessage = {
  restrictionType: string;
  message: string;
  backgroundColorClass: string;
} | null;

/* --------------------------StockQtyExceeded Selectors  START-------------------------------------*/

const showStockQtyExceededMessage = (item: Item): boolean => {
  const availableQuantity = getStockAvailableQuantity(item);
  const quantityInBasket = getQuantity(item);
  if (availableQuantity === 0) return true;
  if (!availableQuantity) return false;
  return quantityInBasket > availableQuantity;
};

export const getStockQtyExceededMessage = (item: Item, translate: TTranslateFunc): TStockQtyExceededMessage => {
  const availableQuantity = getStockAvailableQuantity(item);
  const restrictionMessage =
    availableQuantity !== 0
      ? translate('product-tile:stock-quantity-exceeded', { availableQuantity })
      : translate('product-tile:zero-quantity-in-stock');
  return {
    restrictionType: STOCK_QUANTITY_EXCEEDED,
    message: restrictionMessage,
    backgroundColorClass: WARNING_MESSAGE_BACKGROUND_CLASS,
  };
};

/* --------------------------StockQtyExceeded Selectors  END-------------------------------------*/

/* --------------------------BulkBuyLimit Selectors  START-------------------------------------*/

const getBulkBuyLimitMessageText = (item: Item, translate: TTranslateFunc): string | null => {
  const bulkBuyLimitReached = atMaxQty(item);
  const qty = getProductBulkBuyLimit(item);
  const groupQty = qty;
  if (bulkBuyLimitReached && !hasGroupBulkBuyLimit(item)) {
    return translate('product-tile:item-limit-reached', { qty });
  }

  if (getGroupLimitReached(item)) {
    return translate('product-tile:group-limit-reached', {
      qty: groupQty,
    });
  }

  if (getItemLimitReached(item)) {
    return translate('product-tile:item-group-limit-reached-item', {
      qty,
      groupQty,
    });
  }
  return null;
};

export const getBulkLimitMessage = (item: Item, translate: TTranslateFunc): TBulkBuyLimitMessageData => {
  return {
    backgroundColorClass: WARNING_MESSAGE_BACKGROUND_CLASS,
    message: getBulkBuyLimitMessageText(item, translate),
  };
};

/* --------------------------BulkBuyLimit Selectors  END-------------------------------------*/

/* --------------------------RDG Selectors  START-------------------------------------*/

const combineRdgMessages = (
  timeMessage: TRDGMessage,
  dateMessage: TRDGMessage,
  unavailableMessage: TRDGMessage,
): { text: string[]; backgroundColorClass: string | null; showChangeSlot: boolean } | null => {
  const text: string[] = [];
  let showChangeSlot = false;
  let showWarningMessage = false;

  if (timeMessage) {
    text.push(timeMessage.message);
    showChangeSlot = showChangeSlot || timeMessage.showChangeSlot;
  }

  if (dateMessage) {
    text.push(dateMessage.message);
    showChangeSlot = showChangeSlot || dateMessage.showChangeSlot;
    showWarningMessage = dateMessage.displayWarningMessage || false;
  }

  if (unavailableMessage) {
    text.push(unavailableMessage.message);
    showChangeSlot = showChangeSlot || unavailableMessage.showChangeSlot;
  }

  return text.length
    ? {
        text,
        backgroundColorClass: showChangeSlot || showWarningMessage ? WARNING_MESSAGE_BACKGROUND_CLASS : null,
        showChangeSlot,
      }
    : null;
};

const isLeadTimeProductInOrder = (restriction: Restriction): boolean =>
  !!restriction && restriction.type === RDG_LEAD_TIME_WITH_ITEM;

const isRdgViolated = (restriction: Restriction): boolean => {
  if (!restriction) return false;
  const restrictions = Array.isArray(restriction) ? restriction : [restriction];
  return restrictions.some(
    restriction => restriction && (restriction.isViolated || isLeadTimeProductInOrder(restriction)),
  );
};

const buildRdgRestrictionMessage = (
  restriction: Restriction | undefined,
  isMiniTrolley = false,
): TRDGRestrictionData => {
  if (!restriction) return null;
  const isLeadTimeInBasket = restriction.type === RDG_LEAD_TIME_WITH_ITEM;
  const restrictionData = {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    message: isMiniTrolley ? restriction.shortMessage! : restriction.message!,
    showChangeSlot: !isLeadTimeInBasket && restriction.isViolated,
    displayWarningMessage: isLeadTimeInBasket,
  };
  const hasValidRestriction = !isMiniTrolley || (isMiniTrolley && isRdgViolated(restriction));
  if (!hasValidRestriction) return null;
  return restrictionData;
};

const filterByRestriction = (restrictions: Restriction[] = [], restrictionTypes: string[]): Restriction | undefined =>
  restrictions.find(({ type }) => restrictionTypes.includes(type));

const getRdgTimeRestriction = (restrictions: Restriction[]): Restriction | undefined =>
  filterByRestriction(restrictions, [RDG_DAY_TIME]);

const getRdgDateRestriction = (restrictions: Restriction[]): Restriction | undefined =>
  filterByRestriction(restrictions, [RDG_DATE_RANGE, RDG_LEAD_TIME, RDG_LEAD_TIME_WITH_ITEM]);

const getRdgUnavailableDateRestriction = (restrictions: Restriction[]): Restriction | undefined =>
  filterByRestriction(restrictions, [RDG_UNAVAILABLE_DATE_RANGE]);

export const getRdgMessage = (item: Item, excludeMessages: string[]): TCombinedRDGMessagesData => {
  const restrictions = getRdgRestrictions(item);

  const hasRdgRestrictions = !!restrictions && restrictions.length > 0;

  if (hasRdgRestrictions) {
    const restrictionMessages = combineRdgMessages(
      buildRdgRestrictionMessage(getRdgTimeRestriction(restrictions)),
      buildRdgRestrictionMessage(getRdgDateRestriction(restrictions)),
      buildRdgRestrictionMessage(getRdgUnavailableDateRestriction(restrictions)),
    );

    if (
      !excludeMessages.includes('blueRdgRestrictions') ||
      (restrictionMessages && restrictionMessages.showChangeSlot)
    ) {
      return restrictionMessages;
    }
  }

  return null;
};

/* --------------------------RDG Selectors  END-------------------------------------*/

/* --------------------------ROA Selectors  START-------------------------------------*/

export const buildRestrictedOrderAmendmentMessage = (
  item: Item,
  overrideMessage: boolean,
): TRestrictedOrderAmendmentMessageFormat => {
  const restrictedOrderAmendment = getRestrictedOrderAmendmentRestriction(item);
  const isRdgViolatedForRestrictedOrderAmendmentItemInBasket = () =>
    isRdgViolation(item) &&
    restrictedOrderAmendment &&
    restrictedOrderAmendment.type === RESTRICTED_ORDER_AMENDMENT_WITH_ITEM;

  const shouldOverrideRestrictedOrderAmendmentMessage =
    (restrictedOrderAmendment && restrictedOrderAmendment.type === RESTRICTED_ORDER_AMENDMENT) ||
    isRdgViolatedForRestrictedOrderAmendmentItemInBasket();

  return {
    message:
      shouldOverrideRestrictedOrderAmendmentMessage && overrideMessage
        ? getRestrictedOrderAmendmentMessage(item)
        : null,
    backgroundColorClass: `${WARNING_MESSAGE_BACKGROUND_CLASS} restrictedOrderAmendment-message`,
  };
};

/* --------------------------ROA Selectors  END-------------------------------------*/

/* --------------------------VariableWeight Selectors  START-------------------------------------*/

export const getVariableWeightMessage = (
  item: Item,
  excludeMessages: string[],
  translate: TTranslateFunc,
): TVariableWeightMessageData => {
  const variableWeightProduct = !excludeMessages.includes('variableWeightProduct') && isVariableWeightProduct(item);
  return variableWeightProduct
    ? {
        message: translate('product-tile:variable-weight-product-msg'),
      }
    : null;
};

/* --------------------------VariableWeight Selectors  END-------------------------------------*/

/* --------------------------ExcludedProduct Selectors  START-------------------------------------*/

const buildExcludedProductMessage = (translate: TTranslateFunc): TExcludedProductMessageData => ({
  message: translate('trolley:warnings.excluded-product-message'),
  backgroundColorClass: WARNING_MESSAGE_BACKGROUND_CLASS,
});

export const getExcludedProductMessage = (
  excludedInAmendBasket: boolean,
  itemAtRestrictedMaxQty: boolean,
  showChangeYourSlot: boolean,
  translate: TTranslateFunc,
): TExcludedProductMessageData => {
  const isExcludedProductAtRestrictedMax = excludedInAmendBasket && itemAtRestrictedMaxQty;
  const showExcludedProductMessages = !showChangeYourSlot && isExcludedProductAtRestrictedMax;
  return showExcludedProductMessages ? buildExcludedProductMessage(translate) : null;
};

/* --------------------------ExcludedProduct Selectors  END-------------------------------------*/

/* --------------------------UnavailableMessage Selectors  START-------------------------------------*/

export const getUnavailableMessageKey = (
  hasSubstitutions: boolean,
  isAmendBasket: boolean,
  status: string,
  isTrolley = false,
  isOnDemandDelivery = false,
): string => {
  const onDemandSuffix = onDemandTranslationSuffix(isOnDemandDelivery);

  if (isTrolley) {
    if (status === EPW_WITHOUT_AMEND_AVAILABILITY_OVERRIDE && isAmendBasket) {
      return 'trolley:epw-without-amend-availability-override-message-fullbasket';
    }

    return status === PERMANENTLY_UNAVAILABLE
      ? `trolley:permanently-unavailable${onDemandSuffix}`
      : 'product-tile:product-unavailable-trolley';
  } else if (hasSubstitutions) {
    return status === PERMANENTLY_UNAVAILABLE
      ? `product-tile:product-permanently-unavailable${onDemandSuffix}`
      : 'product-tile:product-unavailable';
  }

  return status === PERMANENTLY_UNAVAILABLE
    ? `product-tile:permanently-unavailable${onDemandSuffix}`
    : 'product-tile:product-unavailable';
};

export const getUnavailableInfoMessage = (
  state: any,
  item: Item,
  translation: TTranslateFunc,
  config: TConfigFunc,
  isTrolley?: boolean,
  isOnDemandDelivery?: boolean,
): TUnavailableMessageData => {
  const showInfoMessage =
    config('showProductUnavailableMessages') && !getProductIsForSale(item) && !isAvailableEpwOverride(state, item);

  return showInfoMessage
    ? {
        message: translation(
          getUnavailableMessageKey(
            getProductHasSubstitution(item),
            getIsAmendBasket(state),
            getProductStatus(item) as string,
            isTrolley,
            isOnDemandDelivery,
          ),
        ),
        backgroundColorClass: `${WARNING_MESSAGE_BACKGROUND_CLASS} unavailable-messages`,
      }
    : null;
};
/* --------------------------UnavailableMessage Selectors  END-------------------------------------*/

export const getBlockedSubstitutionMessage = (
  item: Item,
  translate: TTranslateFunc,
  isTrolley?: boolean,
  isOnDemandDelivery?: boolean,
): TBlockedSubsMessageData => {
  const isSubstitutionBlocked = getIsSubstitutionBlocked(item);
  const blockedSubstitutionsMessage =
    isSubstitutionBlocked && isTrolley && !isOnDemandDelivery
      ? {
          message: translate('product-tile:product-substitutions-blocked'),
          backgroundColorClass: `${WARNING_MESSAGE_BACKGROUND_CLASS} unavailable-messages`,
        }
      : null;

  return blockedSubstitutionsMessage;
};

/*-------------------- Merging Restriction Messages START ------------------------*/

const getMessagingProps = (
  messages: TInfoMessageComponent[],
  showChangeYourSlot: boolean,
  translate: TTranslateFunc,
  slotsLink: string,
): TInfoMessageFormat => {
  let messagingProps = { messages };

  if (showChangeYourSlot) {
    const changeSlotLink = {
      text: translate('context-cards:change-slot'),
      href: slotsLink,
      icon: 'icon-chevron_right',
    };
    //@ts-ignore
    messagingProps = { ...messagingProps, changeSlotLink };
  }
  return messagingProps;
};

export const getAllInfoMessages = (
  state: any,
  item: Item,
  excludedInAmendBasket: boolean,
  excludeMessages: string[],
  translate: TTranslateFunc,
  config: TConfigFunc,
  slotsLink: string,
  isTrolley?: boolean,
  isOnDemandDelivery?: boolean,
): TInfoMessageFormat => {
  const showBulkBuyLimitMessage = atMaxQty(item) && !hasRestrictedOrderAmendment(item);
  const bulkBuyLimitMessage = showBulkBuyLimitMessage ? getBulkLimitMessage(item, translate) : null;
  const stockQtyExceededMessage = showStockQtyExceededMessage(item)
    ? getStockQtyExceededMessage(item, translate)
    : null;
  const blockedSubstitutionsMessage = getBlockedSubstitutionMessage(item, translate, isTrolley, isOnDemandDelivery);

  const variableWeightProductMessage = getVariableWeightMessage(item, excludeMessages, translate);

  const rdgMessage = getRdgMessage(item, excludeMessages);

  const showChangeYourSlot = rdgMessage && rdgMessage.showChangeSlot ? true : false;

  const excludedProductMessage = getExcludedProductMessage(
    excludedInAmendBasket,
    atRestrictedMaxQty(item),
    showChangeYourSlot,
    translate,
  );

  const unavailableMessage = getUnavailableInfoMessage(state, item, translate, config, isTrolley, isOnDemandDelivery);
  const messages = [
    variableWeightProductMessage,
    excludedProductMessage,
    bulkBuyLimitMessage,
    rdgMessage,
    unavailableMessage,
    stockQtyExceededMessage,
    blockedSubstitutionsMessage,
  ].filter(message => message);

  const infoMessageProps = messages.length
    ? getMessagingProps(messages, showChangeYourSlot, translate, slotsLink)
    : null;

  return infoMessageProps;
};

/*-------------------- Merging Restriction Messages END ------------------------*/
