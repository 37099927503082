import styled from 'styled-components';
import Text from '@ddsweb/text';
import Heading from '@ddsweb/heading';
import { media } from '@ddsweb/foundation';
import { colors, fontSize, spacing } from '@ddsweb/selectors';
import StatusIndicator from '@ddsweb/status-indicator';

export const StyledHeading = styled(Heading)`
  margin-bottom: ${spacing.md};
`;

export const StyledTextContainer = styled.div`
  margin-bottom: ${spacing.md};
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const StyledFormattedSlotTime = styled(Text)`
  font-size: ${fontSize.xs};
  color: ${colors.text};
`;

export const StyledFormattedCheckoutTime = styled(Text)`
  font-size: ${fontSize.xx};
`;

export const StyledButtonContainer = styled.div`
  font-size: ${fontSize.xs};
  margin: 0 ${spacing.md} ${spacing.xs} 0;
  display: flex;
  gap: 10px;
  min-width: 480px;
  justify-content: flex-start;

  ${media.belowMobileLarge`
    min-width: 100%;
    flex-direction: column;
  `};

  > * {
    width: 40%;
    ${media.belowMobileLarge`
      width: 100%;
    `};
  }

  button:disabled {
    background-color: ${colors.tescoBlue};
    border: ${spacing.xxx} solid ${colors.tescoBlue};
  }
`;

export const StyledStatusIndicator = styled(StatusIndicator)`
  margin-right: ${spacing.xx};
`;
