import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import url from 'url';
import helpers from '#/lib/decorators/helpers';
import { getLanguage } from '#/reducers/app';
import { connect } from '#/lib/render/connect-deep-compare';
import StaticMap from './static-map';
import InteractiveMap from './interactive-map';
import {
  bingSupportedLanguages,
  DEFAULT_BING_MAP_LANGUAGE
} from './bing-supported-languages';

// This has been introduced to handle the 'callback' functionality of Bing Maps async load. The associated event
// listeners will be redundant (but not harmful) if not using Bing Maps
export const MAP_READY = 'mapReady';

function mapReady() {
  return `function GetMap() {var event = document.createEvent('Event');event.initEvent('${MAP_READY}', true, false);window.dispatchEvent(event);};`;
}

function getBingMapSupportedLang(language) {
  if (bingSupportedLanguages[language]) {
    return language;
  }
  return DEFAULT_BING_MAP_LANGUAGE;
}

function getMapSdkUrl(config, region, language) {
  const provider = config('maps:provider');
  const sdkUrl = url.parse(config('maps:interactiveAPI'));
  const clientId = config('maps:clientId');
  const clientKey = config('maps:key');

  switch (provider) {
    case 'Bing':
      const supportedLang = getBingMapSupportedLang(language);
      sdkUrl.query = {
        ...sdkUrl.query,
        callback: 'GetMap',
        key: clientId,
        setLang: supportedLang,
        UR: region
      };
      break;
    case 'Google':
      sdkUrl.query = {
        ...sdkUrl.query,
        key: clientKey,
        language,
        region
      };
      break;
  }
  return url.format(sdkUrl);
}

const mapStateToProps = state => {
  return {
    language: getLanguage(state).substr(0, 2)
  };
};

@connect(mapStateToProps)
export class Map extends React.Component {
  constructor(props) {
    super(props);

    this.mapRef = React.createRef();
  }
  state = {
    displayInteractiveMap: false
  };

  setInteractiveMap = () => {
    this.setState({
      displayInteractiveMap: true
    });
  };

  setStaticMap = () => {
    this.setState({
      displayInteractiveMap: false
    });
  };

  componentDidMount() {
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setInteractiveMap();

    if (!window.Microsoft || !window.Microsoft.Maps) {
      const { c: config, language } = this.props;
      const region = config('REGION');
      const mapSdkUrl = getMapSdkUrl(config, region, language);

      const callScriptNode = document.createElement('script');
      callScriptNode.type = 'text/javascript';
      callScriptNode.innerHTML = mapReady();
      this.callScriptNode = callScriptNode;
      document.head.appendChild(callScriptNode);

      const bingAssetScriptNode = document.createElement('script');
      bingAssetScriptNode.src = mapSdkUrl;
      bingAssetScriptNode.type = 'text/javascript';
      this.bingAssetScriptNode = bingAssetScriptNode;
      document.head.appendChild(bingAssetScriptNode);
    }

    window.addEventListener(MAP_READY, this.setInteractiveMap);
  }

  componentWillUnmount() {
    this.bingAssetScriptNode && this.bingAssetScriptNode.remove();
    this.callScriptNode && this.callScriptNode.remove();

    window.removeEventListener(MAP_READY, this.setInteractiveMap);
  }

  render() {
    const { props } = this;
    if (!props.center.latitude || !props.center.longitude) return null;
    const MapComponent = this.state.displayInteractiveMap
      ? InteractiveMap
      : StaticMap;

    const provider = props.c('maps:provider');

    return (
      <div className="map__wrapper">
        <MapComponent
          ref={this.mapRef}
          provider={provider}
          center={props.center}
          disablePanning={props.disablePanning}
          disableScrollWheelZoom={props.disableScrollWheelZoom}
          disableTouchInput={props.disableTouchInput}
          disableZooming={props.disableZooming}
          enableHighlightedAreas={props.enableHighlightedAreas}
          highlightedAreas={props.highlightedAreas}
          onClick={props.onClick}
          onDblClick={props.onDblClick}
          onMapTypeChanged={props.onMapTypeChanged}
          onPointClick={props.onPointClick}
          onReady={props.onReady}
          onResize={props.onResize}
          onRightClick={props.onRightClick}
          onViewChange={props.onViewChange}
          onViewChangeEnd={props.onViewChangeEnd}
          onViewChangeStart={props.onViewChangeStart}
          points={props.points}
          requireCalculations={props.requireCalculations}
          requireSearch={props.requireSearch}
          selectedPoint={props.selectedPoint}
          showViewportCenterPin={props.showViewportCenterPin}
          theme={props.theme}
          zoom={props.zoom}
          fallbackToStaticMap={this.setStaticMap}
          showLocateMeButton={props.showLocateMeButton}
        />
      </div>
    );
  }
}

Map.propTypes = {
  c: PropTypes.func.isRequired,
  center: PropTypes.shape({
    latitude: PropTypes.string.isRequired,
    longitude: PropTypes.string.isRequired
  }).isRequired,
  disablePanning: PropTypes.bool,
  disableScrollWheelZoom: PropTypes.bool,
  disableTouchInput: PropTypes.bool,
  disableZooming: PropTypes.bool,
  enableHighlightedAreas: PropTypes.bool,
  highlightedAreas: PropTypes.arrayOf(PropTypes.object),
  onClick: PropTypes.func,
  onDblClick: PropTypes.func,
  onMapTypeChanged: PropTypes.func,
  onPointClick: PropTypes.func,
  onReady: PropTypes.func,
  onResize: PropTypes.func,
  onRightClick: PropTypes.func,
  onViewChange: PropTypes.func,
  onViewChangeEnd: PropTypes.func,
  onViewChangeStart: PropTypes.func,
  points: PropTypes.arrayOf(PropTypes.object),
  requireCalculations: PropTypes.bool,
  requireSearch: PropTypes.bool,
  selectedPoint: PropTypes.string,
  showLocateMeButton: PropTypes.bool,
  showViewportCenterPin: PropTypes.bool,
  theme: PropTypes.object.isRequired,
  zoom: PropTypes.number
};

Map.defaultProps = {
  disablePanning: false,
  disableTouchInput: false,
  disableZooming: false,
  points: [],
  zoom: 15
};

export default helpers(['c'])(withTheme(Map));
