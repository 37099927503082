/**
 * Set of utility methods for manipulating the tree structure used with the DCS
 * similar to those on Array.prototype
 */

const treeForEach = (func, tree) => {
  const treeForEach = (() => {
    let leafNodeIndex = 0;

    return (func, tree, indexAtCurrentLevel = 0) => {
      if (Array.isArray(tree)) {
        tree.forEach((subTree, i) => treeForEach(func, subTree, i));
      } else if (tree.children && tree.children.length) {
        treeForEach(func, tree.children);
      } else if (tree.props && tree.props.slides && tree.props.slides.length) {
        treeForEach(func, tree.props.slides);
      } else {
        func.call(null, tree, leafNodeIndex++, indexAtCurrentLevel);
      }
    };
  })();

  treeForEach(func, tree);
};

const treeMap = (func, tree) => {
  const treeMap = (() => {
    let leafNodeIndex = 0;

    return (func, tree, indexAtCurrentLevel = 0) => {
      let result = {};

      if (Array.isArray(tree)) {
        result = tree.map((subTree, i) => treeMap(func, subTree, i));
      } else if (tree.children && tree.children.length) {
        Object.assign(result, tree);
        result.children = treeMap(func, tree.children);
      } else if (tree.props && tree.props.slides && tree.props.slides.length) {
        Object.assign(result, tree);
        result.props = Object.assign({}, tree.props);
        result.props.slides = treeMap(func, tree.props.slides);
      } else {
        const treeCopy = Object.assign({}, tree);

        treeCopy.props = Object.assign({}, tree.props);
        result = func.call(
          null,
          treeCopy,
          leafNodeIndex++,
          indexAtCurrentLevel
        );
      }

      return result;
    };
  })();

  return treeMap(func, tree);
};

const treeFind = (predicate, tree) => {
  let result = false;

  treeForEach(node => {
    if (predicate(node)) {
      result = true;
    }
  }, tree);

  return result;
};

export { treeForEach, treeMap, treeFind };
