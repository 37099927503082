import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Link from '#/components/link-check-spa';
import analyticsBus from '#/analytics/analyticsBus';
import { basicEvent } from '#/analytics/types/basic';
import {
  VIEW_AND_BUY_TYPE_FAVORITES,
  VIEW_AND_BUY_TYPE_OFFERS
} from '#/constants/display-types';
import LazyImage from '#/components/image/lazy-image';

export default class ProductImage extends Component {
  static propTypes = {
    alt: PropTypes.string,
    clickable: PropTypes.bool,
    hideFromScreenReader: PropTypes.bool,
    href: PropTypes.string,
    image: PropTypes.string,
    isUnavailable: PropTypes.bool,
    onClick: PropTypes.func,
    title: PropTypes.string,
    viewAndBuyType: PropTypes.oneOf([
      VIEW_AND_BUY_TYPE_FAVORITES,
      VIEW_AND_BUY_TYPE_OFFERS
    ])
  };

  static defaultProps = {
    alt: '',
    clickable: true,
    hideFromScreenReader: false,
    image: '',
    isLazyImage: true,
    isUnavailable: false
  };

  handleClick = () => {
    if (this.props.onClick) {
      this.props.onClick();
    } else if (this.props.viewAndBuyType) {
      basicEvent(analyticsBus, {
        type: `view and buy ${this.props.viewAndBuyType}`,
        value: 'product tile',
        action: 'delay'
      });
    }
  };

  hasImage = src => src && src.indexOf('/noimage') === -1;

  getImageSrc() {
    let src = this.props.image || '';
    let srcSet = '';

    //  90x90 doesn't have a 'no image'
    if (this.hasImage(src)) {
      const smallSrc = src.replace(/_135x135|_225x225/, '_90x90');
      src = src.replace('_135x135', '_225x225');

      srcSet = `${smallSrc} 768w, ${src} 4000w`;
    }

    return { src, srcSet };
  }

  renderImage() {
    const className = classnames({
      'product-image': true,
      grayscale: this.props.isUnavailable
    });
    const { src, srcSet } = this.getImageSrc();

    return (
      <div className="product-image__container">
        <LazyImage
          src={src}
          srcSet={srcSet}
          className={className}
          alt={this.props.alt}
        />
      </div>
    );
  }

  render() {
    const image = this.renderImage();

    // This ensures that `aria-hidden` isn't serialised if false (as its unnecessary code)
    const hideFromScreenReader = this.props.hideFromScreenReader || undefined;

    return this.props.href && this.props.clickable ? (
      <Link
        onClick={this.handleClick}
        aria-hidden={hideFromScreenReader}
        className="product-image-wrapper"
        to={this.props.href}
        tabIndex={-1}
      >
        {image}
      </Link>
    ) : (
      <span
        aria-hidden={hideFromScreenReader}
        className="product-image-wrapper"
        tabIndex={-1}
      >
        {image}
      </span>
    );
  }
}
