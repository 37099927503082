export default function filterResourcesByDependenciesResults(
  conditionalResources,
  fetchedResourceData
) {
  return conditionalResources.reduce((filtered, resource) => {
    const [name, configOptions] = resource;
    const { conditions, dependentOn } = configOptions;
    const fetchedResourceDataTypes = Object.keys(fetchedResourceData);

    // In case any of the dependency is not met, this resource is not added to the current load queue. Complete signature of
    // the resource (name, conditions, dependentOn) is retained to be able to re-evaluate dependencies at the next opportunity
    if (
      dependentOn.filter(
        resourceName => !fetchedResourceDataTypes.includes(resourceName)
      ).length
    ) {
      return [...filtered, resource];
    }

    // When there are no conditions to evaluate, the 'name' of the resource is extracted and it gets added to current load
    // queue of resources. Only the name is sufficient in order to locate the resource file and make an API
    // request with right set of arguments.
    if (!conditions) {
      return [...filtered, name];
    }

    const conditionResults = conditions.map(condition =>
      condition(fetchedResourceData)
    );

    if (conditionResults.some(result => !result)) {
      return filtered;
    }

    // When all conditions pass, the 'name' of the resource is extracted and it gets added to current load queue of
    // resources. Only the name is sufficient in order to locate the resource file and make an API
    // request with right set of arguments.
    return [...filtered, name];
  }, []);
}
