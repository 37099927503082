export enum favFilterNavigationVariants {
  default = 'default',
  filterDropDown = 'filters-dropdown',
  filterDropDownTick = 'filters-dropdown-tick',
  search = 'search',
}

/*
 * Experiment constants for filter navigation on My favorites
 */

const key = 'stickyConfig';

export const favFilterNavigation = {
  featureKey: 'GHS-UK_previouslybought_sticky-elements',
  variationKey: key,
  serviceConfig: {
    params: {
      [key]: favFilterNavigationVariants.default,
    },
  },
  useCustomerId: true,
};

export const FILTER_DROPDOWN_STICKY_HEIGHT = 100;
