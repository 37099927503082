import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { compose } from 'redux';
import helpers from '#/lib/decorators/helpers';
import { connect } from '#/lib/render/connect-deep-compare';
import { isUnavailableHDSlotWithCollectLink } from '#/selectors/slot';

const PreviouslyBookedSlot = ({
  accessibleLabel,
  applyCollectLinkStyleVariant,
  showAmendSlotChangeCopy,
  t: translate
}) => (
  <button
    className={classnames(
      'button button-primary small previously-booked-slot--button slot-selector__button',
      {
        'previously-booked-slot--button--cc-link-enabled': applyCollectLinkStyleVariant
      }
    )}
  >
    <span className="visually-hidden">{accessibleLabel}</span>
    <span
      className={classnames('slot-booked', {
        'slot-booked--previous': showAmendSlotChangeCopy
      })}
    >
      {showAmendSlotChangeCopy
        ? translate('slots:common.previous-slot')
        : translate('slots:common.original')}
    </span>
  </button>
);

PreviouslyBookedSlot.propTypes = {
  accessibleLabel: PropTypes.string.isRequired,
  applyCollectLinkStyleVariant: PropTypes.bool,
  showAmendSlotChangeCopy: PropTypes.bool,
  t: PropTypes.func.isRequired
};

const mapStateToProps = (state, { c: config }) => ({
  applyCollectLinkStyleVariant: isUnavailableHDSlotWithCollectLink(
    state,
    config
  )
});

const enhance = compose(helpers(['t', 'c']), connect(mapStateToProps));

export default enhance(PreviouslyBookedSlot);
