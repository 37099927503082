import { getSashData, hasPromotion, isClubcardPromotionOnItem } from '#/selectors/item';
import { PERMANENTLY_UNAVAILABLE, EPW_WITHOUT_AMEND_AVAILABILITY_OVERRIDE } from '#/constants/unavailable-product-type';
import { TFlashSashInfo } from '#/selectors/beans-product-tile/promotions-data';
import { Item } from '#/lib/records/item';
import { TTranslateFunc } from '#/lib/records/helpers.defs';
import { onDemandTranslationSuffix } from '#/lib/shopping-method-util';

export type GetFlashSashInfoArgs = {
  translate: TTranslateFunc;
  item: Item;
};

export const getFlashSashInfo = ({ translate, item }: GetFlashSashInfoArgs): TFlashSashInfo | null => {
  const flashSashInfo = getSashData(item);

  if (!flashSashInfo) {
    return null;
  }

  return {
    text: translate(flashSashInfo.textKey),
    color: flashSashInfo.color,
    type: flashSashInfo.type,
  };
};

export const getUnavailableMessageKey = (
  hasSubstitutions: boolean,
  isAmendBasket: boolean,
  isTrolley: boolean,
  status: string,
  isOnDemandDelivery: boolean,
): string => {
  const isPermanentlyUnavailable = status === PERMANENTLY_UNAVAILABLE;
  const onDemandSuffix = onDemandTranslationSuffix(isOnDemandDelivery);

  if (isTrolley) {
    if (status === EPW_WITHOUT_AMEND_AVAILABILITY_OVERRIDE && isAmendBasket) {
      return 'trolley:epw-without-amend-availability-override-message-fullbasket';
    } else if (hasSubstitutions) {
      return isPermanentlyUnavailable
        ? `product-tile:product-permanently-unavailable${onDemandSuffix}`
        : 'product-tile:product-no-longer-unavailable';
    }

    return isPermanentlyUnavailable
      ? `trolley:permanently-unavailable${onDemandSuffix}`
      : 'product-tile:product-unavailable-trolley';
  } else if (hasSubstitutions) {
    return isPermanentlyUnavailable
      ? `product-tile:product-permanently-unavailable${onDemandSuffix}`
      : 'product-tile:product-unavailable';
  }

  return isPermanentlyUnavailable
    ? `product-tile:permanently-unavailable${onDemandSuffix}`
    : 'product-tile:product-unavailable';
};

export const hasSquareOfferSash = (item: Item, available: boolean): boolean => {
  if (!available || !hasPromotion(item)) {
    return false;
  }

  if (isClubcardPromotionOnItem(item)) return true;

  return false;
};

// Currently we don't have support to show both Deposit and PPU same time in Clubcard
export const getPromotionSecondaryText = ({
  productDrsChargeAmount,
  pricePerUnit,
  translate,
}: {
  productDrsChargeAmount?: number | null;
  pricePerUnit?: string;
  translate: TTranslateFunc;
}): string => {
  if (productDrsChargeAmount && pricePerUnit) return `${pricePerUnit} + ${translate('deposit')}`;
  if (productDrsChargeAmount) return `+ ${translate('deposit')}`;
  if (pricePerUnit) return `(${pricePerUnit})`;

  return ``;
};
