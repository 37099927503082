import { createSelector } from 'reselect';
import { getSelectedShoppingMethod } from '../slot';
import {
  getCurrentValidSlot,
  getTrolleyShoppingMethod
} from '#/selectors/trolley';

export const getNewSlotGroupFromShoppingMethod = createSelector(
  getTrolleyShoppingMethod,
  getSelectedShoppingMethod,
  getCurrentValidSlot,
  (trolleyShoppingMethod, selectedShoppingMethod, slot) =>
    trolleyShoppingMethod === selectedShoppingMethod && slot ? slot.group : null
);
