export default function getConditionalResources(relevantResourcesFromRoutes) {
  return relevantResourcesFromRoutes.reduce((conditional, resource) => {
    if (
      Array.isArray(resource) &&
      resource[1] &&
      Array.isArray(resource[1].dependentOn) &&
      resource[1].dependentOn.length
    ) {
      return [...conditional, resource];
    }

    return conditional;
  }, []);
}
