import React from 'react';
import { compose } from 'react-recompose';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from '#/lib/render/connect-deep-compare';
import Link from '#/components/link';
import helpers from '#/lib/decorators/helpers';
import { getCurrentUrl } from '#/reducers/app';
import {
  getCheckoutEntryPoint,
  getRecommendationsUrlFromState
} from '#/utils/checkout-utils';
import { hasValidSlot } from '#/selectors/trolley';
import {
  getHasBasketBreachedByVolumeOrWeight,
  getAnalyticsOrderId
} from '#/selectors/trolley';
import { showCheckoutRecommendations } from '#/selectors/experiments';
import { isTrolleyPageByUrl } from '../products/product-tile/tile-components/product-promotions/product-promotion/hooks';
import { TROLLEY } from '#/constants/page-names';
import ContinueCheckoutButtonWrapper from '#/components/continue-checkout-wrapper';
import { basicEvent } from '#/analytics/types/basic';
import analyticsBus from '#/analytics/analyticsBus';

function AmendCheckoutButton(props) {
  const {
    classes,
    ddl,
    disabled,
    enableRecommendations,
    t: translate,
    isTrolleyPage,
    checkoutEntryPoint,
    referrerPolicy,
    recommendationsUrl,
    analyticsOrderId,
    inverse = false,
    uses1882cdeCopy
  } = props;

  const onClick = () => {
    basicEvent(analyticsBus, {
      type: 'amend',
      value: 'save changes',
      action: 'now',
      isDeferred: false,
      contextValue: analyticsOrderId
    });
  };

  const href =
    enableRecommendations && isTrolleyPage
      ? recommendationsUrl
      : checkoutEntryPoint;

  const continueLinkText = translate(
    uses1882cdeCopy
      ? 'trolley:checkout-amend-cta-oop1882-cde'
      : 'trolley:checkout-amend-cta'
  );

  return (
    <ContinueCheckoutButtonWrapper
      buttonText={continueLinkText}
      continueHref={href}
      disabled={disabled}
      inverse={inverse}
      page={isTrolleyPage ? TROLLEY : ''}
      classes={classnames('checkout-button', classes)}
      analyticsTracking={onClick}
      renderButton={continueHref =>
        ddl ? (
          <Link
            data-auto="amend-checkout-button"
            className={classnames('checkout-button', classes)}
            href={continueHref}
            onClick={onClick}
            disabled={disabled}
            ddl={ddl}
            buttonVariant="primary"
            stretch
            referrerPolicy={referrerPolicy}
            inverse={inverse}
          >
            {continueLinkText}
          </Link>
        ) : (
          <Link
            data-auto="amend-checkout-button"
            className={classnames(
              'button button-primary',
              {
                'button-disabled': disabled
              },
              classes
            )}
            href={continueHref}
            onClick={onClick}
            disabled={disabled}
            inverse={inverse}
          >
            <span aria-disabled={disabled}>{continueLinkText}</span>
          </Link>
        )
      }
    />
  );
}

AmendCheckoutButton.propTypes = {
  analyticsOrderId: PropTypes.string.isRequired,
  c: PropTypes.func.isRequired,
  checkoutEntryPoint: PropTypes.string.isRequired,
  classes: PropTypes.string.isRequired,
  currentUrl: PropTypes.string.isRequired,
  ddl: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
  enableRecommendations: PropTypes.bool.isRequired,
  l: PropTypes.func.isRequired,
  referrerPolicy: PropTypes.string,
  t: PropTypes.func.isRequired
};

const mapStateToProps = (state, { disabled, c: config }) => {
  const hasBasketBreached =
    hasValidSlot(state) && getHasBasketBreachedByVolumeOrWeight(state);
  const currentUrl = getCurrentUrl(state);
  return {
    checkoutEntryPoint: getCheckoutEntryPoint(state),
    currentUrl,
    isTrolleyPage: isTrolleyPageByUrl(currentUrl),
    enableRecommendations: showCheckoutRecommendations(state, config),
    disabled: disabled || hasBasketBreached,
    recommendationsUrl: getRecommendationsUrlFromState(state),
    analyticsOrderId: getAnalyticsOrderId(state)
  };
};

const enhance = compose(helpers(['c', 'l', 't']), connect(mapStateToProps));
export default enhance(AmendCheckoutButton);
