import url from 'url';
import queryString from 'querystring';
import { findLast } from '#/lib/array-helpers';

export const parseQueryString = givenUrl => url.parse(givenUrl, true).query;
const shallowCopy = obj => ({ ...obj });

export function addFilterUrl({
  category,
  categoryDelimiter,
  currentFilters = {},
  currentPathname,
  currentUrl,
  facet,
  multiSelectionFacets
}) {
  const qs = parseQueryString(currentUrl);

  delete qs.page;
  delete qs.offset;

  if (multiSelectionFacets.includes(category)) {
    qs[category] = (
      (qs[category] && qs[category].split(categoryDelimiter)) ||
      []
    )
      .concat(facet.facetId)
      .join(categoryDelimiter);
  } else {
    qs[category] = facet.facetId;
  }

  currentFilters = shallowCopy(currentFilters);
  currentFilters[category] = true;
  qs.viewAll = Object.keys(currentFilters)
    .filter(f => currentFilters[f])
    .join(',');

  return `${currentPathname}?${queryString.stringify(qs)}`;
}

export function replaceFilterUrl({
  category,
  currentFilters = {},
  currentPathname,
  currentUrl,
  facet,
  filtersToRemove = []
}) {
  const qs = parseQueryString(currentUrl);

  delete qs.page;
  delete qs.offset;
  qs[category] = facet.facetId;
  currentFilters = shallowCopy(currentFilters);
  currentFilters[category] = true;

  /*
    GIVEN filtersToRemove have filters
        WHEN user clicks on any of the category from categories filter
          THEN all filters which are part of filtersToRemove should be de-selected
    
    Following code will create categories filters url by removing all filters which are part of filtersToRemove.
    So, that when we click on categories filters then other(filters which are part of filtersToRemove) filter should be de-selected
   */
  if (filtersToRemove.length && Object.keys(currentFilters).length > 0) {
    for (let i = 0; i < filtersToRemove.length; i++) {
      let key = filtersToRemove[i].toLowerCase();
      if (currentFilters[key]) {
        delete currentFilters[key];
        delete qs[key];
      }
    }
  }

  qs.viewAll = Object.keys(currentFilters)
    .filter(f => currentFilters[f])
    .join(',');

  return `${currentPathname}?${queryString.stringify(qs)}`;
}

export function removeFilterUrl({
  category,
  categoryDelimiter,
  currentPathname,
  currentUrl,
  facet,
  filterOrder = [],
  multiSelectionFacets
}) {
  const categoryIndex = filterOrder.indexOf(category);
  const qs = parseQueryString(currentUrl);
  let filtersForCategory;

  // page no longer relevant after changes to filtering
  delete qs.page;
  delete qs.offset;

  // remove filter from that category in url
  if (multiSelectionFacets.includes(category)) {
    filtersForCategory = qs[category]
      ? qs[category].split(categoryDelimiter)
      : [];
    filtersForCategory.splice(
      filtersForCategory.indexOf(facet.facetId.toString()),
      1
    );
  } else {
    filtersForCategory = [];
  }

  // update viewAll parameter
  const view = (qs.viewAll || '')
    .split(',')
    .filter(cat => filterOrder.indexOf(cat) <= categoryIndex);

  // if no longer any filters for that category, remove whole category from url
  if (!filtersForCategory.length) {
    delete qs[category];
    view.splice(view.indexOf(category), 1);
  } else {
    qs[category] = filtersForCategory.join(categoryDelimiter);
  }

  // remove any more specific filters from url
  for (let i = 0; i < filterOrder.length; i++) {
    if (i > categoryIndex) {
      delete qs[filterOrder[i]];
    }
  }

  // assemble url
  if (view.length) {
    qs.viewAll = view.join(',');
  } else {
    delete qs.viewAll;
  }

  const urlParams = queryString.stringify(qs);

  return currentPathname + (urlParams.length ? `?${urlParams}` : '');
}

export function addFilterCategoryToUrl({
  categoryDelimiter,
  currentPathname,
  currentUrl,
  filterData,
  filterName,
  filtersToRemove = []
}) {
  const qs = parseQueryString(currentUrl);

  delete qs.page;
  delete qs.offset;

  const view = qs.viewAll ? qs.viewAll.split(',') : [];

  if (filtersToRemove.length) {
    for (let i = 0; i < filtersToRemove.length; i++) {
      let key = filtersToRemove[i].toLowerCase();
      if (qs[key]) {
        delete qs[key];
      }
      const index = view.indexOf(key);
      if (index > -1) {
        view.splice(index, 1);
      }
    }
  }

  if (qs[filterName]) {
    view.push(filterName);
  }

  const filter = filterName.toLowerCase();

  view.push(filter);
  qs.viewAll = view.join(',');
  qs[filter] = filterData?.facets?.map(f => f.facetId).join(categoryDelimiter);

  return `${currentPathname}?${queryString.stringify(qs)}`;
}

export function removeAllFiltersUrl(currentUrl) {
  const parsedUrl = url.parse(currentUrl, true);
  const paramsToRemove = [
    'superdepartment',
    'department',
    'area',
    'aisle',
    'shelf',
    'brand',
    'promotion',
    'page',
    'viewAll',
    'new',
    'favourites',
    'dietary',
    'available'
  ];

  paramsToRemove.forEach(param => delete parsedUrl.query[param]);
  delete parsedUrl.search;

  return url.format(parsedUrl);
}

export function extractFacetList(names, label, filterStoreFacets) {
  const facet = findLast(filterStoreFacets, filterCategoryItem => {
    const categoryMatch = names.includes(
      filterCategoryItem.category.toLowerCase()
    );
    const categoryIdMatch = names.includes(filterCategoryItem.categoryId);
    return categoryMatch || categoryIdMatch;
  });
  if (!facet) return null;

  return {
    label,
    category: facet.category,
    categoryId: facet.categoryId,
    facets: facet.facets
  };
}
