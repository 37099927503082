import React from 'react';
import PropTypes from 'prop-types';
import Overlay from '#/components/shared/overlay';
import Link from '#/components/link';
import helpers from '#/lib/decorators/helpers';

const ActionableLayout = props => {
  const {
    cancelText,
    cancelUrl,
    children,
    disableAnalytics,
    doneButtonEnabled,
    doneUrl,
    isOpen,
    onCancel,
    onDone,
    t,
    title
  } = props;

  const doneButtonType = doneButtonEnabled
    ? 'button-primary'
    : 'button-secondary done-button--disabled';

  return (
    <div className="actionable-layout" id="actionable-layout">
      <Overlay isOpen={isOpen} />
      <div className="actionable-layout--wrapper">
        <header className="actionable-header">
          <div>
            <h2 className="actionable-header--title">{title}</h2>
          </div>
        </header>
        <div className="actionable-layout--body">
          <div
            className="actionable-layout--scrollable"
            id="actionable-layout--scrollable"
          >
            {children}
          </div>
        </div>
        <div
          className="actionable-layout--footer"
          data-auto="actionable-footer"
        >
          <div className="footer--button" data-auto="button-cancel">
            <Link
              rel="nofollow"
              className="button cancel-button button-secondary"
              href={cancelUrl}
              onClick={onCancel}
              processHref={cancelUrl !== '#'}
            >
              {cancelText || t('common:cancel')}
            </Link>
          </div>
          <div
            className="footer--button"
            aria-disabled={!doneButtonEnabled}
            data-auto="button-done"
          >
            <Link
              rel="nofollow"
              className={`button done-button ${doneButtonType}`}
              href={doneUrl}
              onClick={doneButtonEnabled ? onDone : undefined}
              disableAnalytics={disableAnalytics}
              processHref={doneUrl !== '#'}
            >
              {t('common:done')}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

ActionableLayout.propTypes = {
  cancelText: PropTypes.string,
  cancelUrl: PropTypes.string,
  children: PropTypes.node,
  disableAnalytics: PropTypes.bool,
  doneButtonEnabled: PropTypes.bool,
  doneUrl: PropTypes.string,
  isFooterCancel: PropTypes.bool,
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  onDone: PropTypes.func,
  t: PropTypes.func,
  title: PropTypes.string
};

ActionableLayout.defaultProps = {
  cancelUrl: '#',
  doneButtonEnabled: true,
  doneUrl: '#',
  isOpen: true
};

export default helpers(['t'])(ActionableLayout);
