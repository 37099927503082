import styled from 'styled-components';
import { media } from '@beans/foundation';
import { spacing } from '@beans/selectors';
import { SMALL_MIN } from '#/components/product-tiles/temp-style-constants';

export const StyledShoppableTilesWrapper = styled.ol`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  justify-content: space-between;
  padding: ${spacing.xs};
`;

export const StyledListItem = styled.li`
  align-self: stretch;
  padding: ${spacing.xx};
  width: 100%;

  .dcs-flexi-tile {
    height: 100%;
    max-width: 100%;
  }
  @media ${SMALL_MIN} {
    width: 50%;
  }

  ${media.aboveTablet`
  width: 33.333%;
  `}

  ${media.aboveDesktop`
  width: 25%;
  `}
`;

export const StyledContentListItem = styled.li`
  flex: 1;
  padding: ${spacing.xx};
  .ui-stamp-widget {
    min-width: 25%;
    padding: 0px;
    width: 100%;
    height: 100%;
    .stamp-widget--inner {
      height: inherit;
      .stamp--a {
        height: inherit;
        display: flex;
        flex-direction: column;
        .stamp__img-container {
          flex: 1;
        }
        ${media.aboveMobile`   
        .stamp__img-container {
          height: calc(100% - 64px);
          padding-bottom: 35%;
        }
        `}
      }
    }
  }
`;
