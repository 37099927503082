import React, { ChangeEvent, PureComponent } from 'react';
import Button from '@ddsweb/button';
import TextareaGroup from '@ddsweb/textarea-group';
import { instructionsChanged, instructionsError, refreshInstructions } from '#/actions/instructions-action-creators';
import { hideAmendOrderBar, showAmendOrderBar } from '#/actions/ui-action-creators';
import analyticsBus from '#/analytics/analyticsBus';
import { basicEvent } from '#/analytics/types/basic';
import { IDLE, PENDING, SAVED, SAVING } from '#/constants/instruction-status';
import helpers from '#/lib/decorators/helpers';
import { connect } from '#/lib/render/connect-deep-compare';
import { getHasError, getInstructions, getOldInstructions, getStatus } from '#/reducers/instructions';
import { getDefaultSlotsPath } from '#/reducers/slot';
import { PlainObject } from '#/types';
import { isValidText } from '#/utils/text-utils';
import { ConnectedProps } from 'react-redux';
import OverlaySpinner from '../overlay-spinner';
import SafeForm from '../shared/safe-form';
import InstructionsDescription from './description';
import { StyledSaveButtonContainer, StyledTextareaContainer } from './styled';

type TOwnProps = {
  maxLength: number;
  shoppingMethod: string;
  onSubmit?: Function;
  redirectTo?: string;
  showLHDescription?: boolean;
};

type THelperProps = {
  f: (featureKey?: string | string[] | undefined) => string;
  l: Function;
  t: (key: string, data?: object) => string;
  c: (key: string) => string;
  hasFeature: Function;
};

const mapStateToProps = (state: Store, { f }: TOwnProps & THelperProps): PlainObject => ({
  defaultSlotsPath: getDefaultSlotsPath(f, true)(state),
  instructions: getInstructions(state),
  oldInstructions: getOldInstructions(state),
  hasError: getHasError(state),
  status: getStatus(state),
});

const mapDispatchToProps = {
  hideAmendOrderBar,
  showAmendOrderBar,
  instructionsChanged,
  instructionsError,
  refreshInstructions,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type TPropsFromRedux = ConnectedProps<typeof connector>;
type TProps = TOwnProps & TPropsFromRedux & THelperProps;

@helpers(['f', 'l', 'c', 't', 'hasFeature'])
class Instructions extends PureComponent<TProps> {
  static defaultProps = {
    showLHDescription: true,
    instructions: '',
  };

  link: { instructionsUrl: string; formInstructionsUrl: string };
  features: { customInstructions: boolean };
  config: { region: string; allowAllCharsInInstructions: string };
  text: {
    save: string;
    slotsCommonOptional: string;
  };

  constructor(props: TProps) {
    super(props);

    this.features = {
      customInstructions: this.props.hasFeature('customInstructions'),
    };

    this.config = {
      region: this.props.c('REGION'),
      allowAllCharsInInstructions: this.props.c('allowAllCharsInInstructions'),
    };

    this.text = {
      save: this.props.t('save'),
      slotsCommonOptional: this.props.t('slots:common.optional'),
    };

    this.link = {
      instructionsUrl: this.props.l('/slots/current/instructions'),
      formInstructionsUrl: this.props.l('/slots/current/instructions?_method=PUT'),
    };

    this.handleBlur = this.handleBlur.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount(): void {
    this.props.refreshInstructions({
      instructionsUrl: this.link.instructionsUrl,
    });
  }

  componentDidUpdate(): void {
    const { status, shoppingMethod } = this.props;

    if (status === SAVED) {
      basicEvent(analyticsBus, {
        type: 'instructions',
        action: 'now',
        value: shoppingMethod,
      });
    }
  }

  get disableSaveButton(): boolean {
    const { status } = this.props;
    return status === IDLE || status === SAVED || status === PENDING;
  }

  handleBlur(): void {
    this.props.showAmendOrderBar();
  }

  handleFocus(): void {
    this.props.hideAmendOrderBar();
  }

  handleChange(e: ChangeEvent<HTMLTextAreaElement>): void {
    const value = e.target.value;

    if (this.config.allowAllCharsInInstructions || isValidText(value)) {
      const { defaultSlotsPath, redirectTo } = this.props;

      this.props.instructionsChanged({
        instructions: value,
        returnUrl: redirectTo || defaultSlotsPath,
        saveUrl: this.link.instructionsUrl,
      });
    }
  }

  handleClick(): void {
    const { defaultSlotsPath, instructions, redirectTo } = this.props;

    this.props.instructionsChanged({
      instructions,
      returnUrl: redirectTo || defaultSlotsPath,
      saveUrl: this.link.instructionsUrl,
      userClick: true,
    });
  }

  renderDescription(): React.ReactNode {
    const { shoppingMethod, t } = this.props;
    const { customInstructions } = this.features;
    const { region } = this.config;
    const slotsShoppingMethodsInstructionsTitleKey = `slots:${shoppingMethod}.instructions-title`;
    const slotsShoppingMethodsInstructionsDescriptionKey = `slots:${shoppingMethod}.instructions-description`;

    const slotsShoppingMethodsInstructionsTitle = customInstructions
      ? t(slotsShoppingMethodsInstructionsTitleKey, { context: region })
      : t(slotsShoppingMethodsInstructionsTitleKey);

    const slotsShoppingMethodsInstructionsDescription = customInstructions
      ? t(slotsShoppingMethodsInstructionsDescriptionKey, { context: region })
      : t(slotsShoppingMethodsInstructionsDescriptionKey);

    return (
      <InstructionsDescription
        description={slotsShoppingMethodsInstructionsDescription}
        label={slotsShoppingMethodsInstructionsTitle}
      />
    );
  }

  render(): JSX.Element {
    const {
      defaultSlotsPath,
      instructions,
      maxLength,
      onSubmit,
      redirectTo,
      shoppingMethod,
      showLHDescription,
      status,
      region,
      t: translate,
    } = this.props;

    return (
      <div>
        <SafeForm method="POST" action={this.link.formInstructionsUrl} onSubmit={onSubmit}>
          <input type="hidden" name="returnUrl" value={redirectTo || defaultSlotsPath} />

          <div className="instructions" data-auto="custom-instructions">
            {showLHDescription && this.renderDescription()}
            <StyledTextareaContainer>
              <TextareaGroup
                id="customInstructions"
                // @ts-ignore
                optionalText={translate(`slots:common.optional`, { context: region })}
                labelText={translate(`slots:${shoppingMethod}.instructions-label`)}
                value={instructions}
                count={instructions.length}
                maxLength={maxLength}
                maxCount={maxLength}
                name="newValue"
                onBlur={this.handleBlur}
                onChange={this.handleChange}
                onFocus={this.handleFocus}
                className="instructions--textarea"
              />
              <StyledSaveButtonContainer role="status">
                <Button
                  className={status === SAVING ? 'is-loading' : ''}
                  data-auto="save-instructions-btn"
                  disabled={this.disableSaveButton}
                  isLoading={status === SAVING}
                  onClick={this.handleClick}
                >
                  {translate(
                    `slots:instructions-button--${status !== SAVED ? 'save-instructions' : 'instructions-saved'}`,
                  )}
                </Button>
              </StyledSaveButtonContainer>
            </StyledTextareaContainer>
          </div>
        </SafeForm>
        <OverlaySpinner isLoading={status === PENDING} />
      </div>
    );
  }
}
export default connector(Instructions);
