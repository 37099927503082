/**
 * Maps a list of supported cards & returns an object for the
 * IconList component to consume.
 */
export function getCardIcons(cards = [], translate, prefix = 'icon__card--') {
  return cards.map(icon => ({
    name: icon.name || icon,
    title: icon.title ? translate(icon.title) : null,
    className: `icon__card ${prefix}${icon.type || icon}`,
    type: icon.type || null
  }));
}
/**
 * Maps a list of ThreeDS/secure payment schemes & returns an object for the
 * IconList component to consume.
 */
export function getThreeDSIcons(secureIcons = [], t) {
  return getCardIcons(secureIcons, t, 'icon__');
}
