import classnames from 'classnames';
import React, { Component } from 'react';
import Icon from '@ddsweb/icon';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Link from '#/components/link';
import deepEqual from 'fast-deep-equal/es6';
import { isBrowsable } from '#/lib/taxonomy/taxonomy';
import NewBrowseLinkText from '#/experiments/oop-2022/components/new-browse-link-text';

export default class Menu extends Component {
  static propTypes = {
    active: PropTypes.object,
    chevron: PropTypes.bool,
    childMenu: PropTypes.element,
    closeMenu: PropTypes.func.isRequired,
    isAmendMode: PropTypes.bool,
    isFocused: PropTypes.bool,
    isOpen: PropTypes.bool,
    isPersistTaxonomyEnabled: PropTypes.bool,
    items: PropTypes.array.isRequired,
    label: PropTypes.string,
    selectItem: PropTypes.func,
    t: PropTypes.func,
    type: PropTypes.string
  };

  static defaultProps = {
    isAmendMode: false,
    items: [],
    scrollToElement: false,
    isPersistTaxonomyEnabled: false
  };

  componentDidMount() {
    this.applyFocusOnTaxonomy();
  }

  handleClick = event => {
    const { items, selectItem, type } = this.props;
    event.stopPropagation();
    event.preventDefault();
    const index = this.getLinkIndex(event.currentTarget);
    selectItem(type, items[index]);
  };

  handleItemKeyDown = event => {
    const { items, selectItem, type } = this.props;
    const index = this.getLinkIndex(event.target);
    const item = items[index];

    switch (event.key) {
      case 'ArrowRight':
        selectItem(type, item);
        break;
      case 'ArrowDown':
        event.preventDefault();
        this.focusItemAtIndex(index + 1);
        break;
      case 'ArrowUp':
        event.preventDefault();
        this.focusItemAtIndex(index - 1);
        break;
    }
  };

  handleListKeyDown = event => {
    if (event.key === 'Escape' || event.key === 'ArrowLeft') {
      event.stopPropagation();
      const { closeMenu, type } = this.props;
      closeMenu(type);
    }
  };

  linkDidUpdate = link => {
    const el = ReactDOM.findDOMNode(link);

    if (el) {
      const index = this.getLinkIndex(el);

      this.links[index] = link;

      const { childMenu, isOpen } = this.props;
      if (index === 0 && !childMenu && isOpen) {
        el.focus();
      }
    }
  };

  saveLinkRef = el => {
    this.links.push(el);
  };

  componentDidUpdate() {
    this.applyFocusOnTaxonomy();
  }

  focusItemAtIndex(index) {
    if (index >= 0 && index < this.props.items.length && this.links[index]) {
      const itemEl = ReactDOM.findDOMNode(this.links[index]);

      if (itemEl) {
        itemEl.focus();
      }
    }
  }

  getLinkIndex(linkEl) {
    return parseInt(linkEl.getAttribute('data-index'), 10);
  }

  getLinkText(item) {
    const { t: translate, type } = this.props;

    if (item.allNode) return `${translate('shop-all')} ${item.name}`;
    if (item.allPromotionNode)
      return `${translate('shop-all-with-promotion')} ${item.name} `;
    if (item.newBrowseNode) return <NewBrowseLinkText nodeName={item.name} />;
    return (
      <span>
        <span className="visually-hidden">{translate('shop')}</span>
        {item.name}
        <span className="visually-hidden">{translate(`taxonomy.${type}`)}</span>
      </span>
    );
  }

  applyFocusOnTaxonomy() {
    const { isOpen, active, items, isFocused } = this.props;
    if (isOpen && isFocused && active) {
      const activeIndex = items.indexOf(active);

      this.focusItemAtIndex(activeIndex);
    }
  }

  render() {
    this.links = [];
    const {
      active,
      childMenu,
      type,
      label,
      isPersistTaxonomyEnabled
    } = this.props;

    const items = (this.props.items || []).map((item, index) => {
      const isActive = deepEqual(item, active);
      const browsable = isBrowsable(type, item.children);

      const classes = classnames(
        `menu-${type}-${index}`, // hook for automated testing - do not remove
        'menu__item',
        `menu__item--${type}`,
        {
          'menu__item--active': isActive
        }
      );

      const linkClasses = classnames('menu__link', `menu__link--${type}`, {
        'menu__link--active': isActive,
        'menu__link--active-persisted': isActive && isPersistTaxonomyEnabled,
        'menu__link--current-parent':
          isActive && childMenu && !childMenu.props.childMenu,
        'menu__link--all': item.allNode
      });

      const expander = <span className="menu__icon icon-chevron_down-gray" />;
      const collapser = <span className="menu__icon icon-chevron_up-gray4" />;

      return (
        <li className={classes} key={index} role="none">
          <Link
            role="menuitem"
            aria-haspopup={item.allNode}
            aria-expanded={isActive}
            ref={this.saveLinkRef}
            handleUpdate={this.linkDidUpdate}
            data-index={index}
            data-auto={`menu-link-${type}`}
            className={linkClasses}
            disableAnalytics={true}
            target={item.newTab ? '_blank' : null}
            href={item.externalUrl || `/shop${item.url}?include-children=true`}
            onClick={this.handleClick}
            onKeyDown={this.handleItemKeyDown}
          >
            {this.getLinkText(item)}
            {!item.allNode && !isActive && !browsable && expander}
            {isActive && childMenu && collapser}
            {!item.allNode && !browsable && (
              <span className="hidden-small-medium">
                <span className="menu__icon icon-chevron_right" />
                <span className="menu__icon icon-chevron_right-light_div" />
                <span className="menu__icon icon-chevron_right-white" />
              </span>
            )}
            {item.showExternalIcon && (
              <span className="menu__link--icon-right">
                <Icon graphic="externalLink" size="sm" />
              </span>
            )}
          </Link>
          {isActive && childMenu}
        </li>
      );
    });
    const ulClasses = {
      menu: true,
      [`menu-${type}`]: true // hook for automated testing - do not remove
    };

    return (
      <ul
        onKeyDown={this.handleListKeyDown}
        className={classnames(ulClasses)}
        ref="menu"
        role="menu"
        aria-label={label}
      >
        {items}
      </ul>
    );
  }
}
