import React from 'react';
import classnames from 'classnames';
import { HORIZONTAL, VERTICAL } from '@ddsweb/constants';
import { DietarySvgIconComponent } from '#/experiments/oop-1922/components/assets/dietary-svg';
import { productTileDietaryInfoVariants } from '#/experiments/oop-1922/constants';
import { DietaryTag, DietaryTagList, StateProps } from '#/experiments/oop-1922/types';
import { getProductTileDietaryInfoVariant, getIsDietaryInfoFeatureEnabled } from '#/experiments/oop-1922/selectors';
import { connect } from '#/lib/render/connect-deep-compare';
import {
  DietaryLogoContainer,
  DietaryLogoFlexContainer,
  DietaryLogoHorizontalContainer,
} from '#/experiments/oop-1922/styled';

const mapDispatchToProps = (state: Store): StateProps => ({
  isDietaryInfoFeatureEnabled: getIsDietaryInfoFeatureEnabled(state),
  dietaryVariantType: getProductTileDietaryInfoVariant(state),
});

export const DietaryLogoComponent = (props: DietaryTag): JSX.Element => {
  const { label, tileType } = props;
  const icon = <DietarySvgIconComponent label={label} />;

  if (tileType === VERTICAL) return <DietaryLogoContainer>{icon}</DietaryLogoContainer>;

  return <DietaryLogoHorizontalContainer>{icon}</DietaryLogoHorizontalContainer>;
};

const Component = (props: DietaryTagList & StateProps): JSX.Element | null => {
  const { content, tileType, isDietaryInfoFeatureEnabled, dietaryVariantType } = props;

  const { dietaryLogo } = productTileDietaryInfoVariants;

  if (!content?.length || !isDietaryInfoFeatureEnabled) return null;

  if (dietaryVariantType !== dietaryLogo) return null;

  return (
    <DietaryLogoFlexContainer className={classnames({ 'flex-horizontal-container': tileType === HORIZONTAL })}>
      {content.map((item, index) => (
        <DietaryLogoComponent tileType={tileType} key={`${index}-${item?.toLowerCase()}`} label={item} />
      ))}
    </DietaryLogoFlexContainer>
  );
};

export const DietaryLogo = connect(mapDispatchToProps)(Component);
