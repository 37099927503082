import React, { useEffect } from 'react';
import { connect } from '#/lib/render/connect-deep-compare';
import { isProduct, getBaseProductId } from '#/selectors/item';
import helpers from '#/lib/decorators/helpers';
import { getPageResultsItemsByTypeSelector } from '#/selectors/results/get-page-items';
import { TTranslateFunc } from '#/lib/records/helpers.defs';
import {
  RecommenderErrorWrapper,
  ErrorText,
  ErrorIconWrapper,
  ErrorHeader,
  StyledIBYCRecommenderWrapper,
} from '#/experiments/oop-2349/components/styled';
import { Item } from '#/lib/records/item';
import { getIBYCTrexStatus } from '#/experiments/oop-2349/reducers';
import { CATEGORY_RECS_CONFIG_KEY, IBYC_LOCALE_PREFIX, SEARCH_RECS_CONFIG_KEY } from '#/experiments/oop-2349/constants';
import IBYCRecommenderWrapper from '#/experiments/oop-2349/components/ibyc-recommender-wrapper';
import { SEARCH } from '#/constants/spa-resource';
import { getIsRecsCarouselVisible } from '#/reducers/ui';
import { clearIbycRecommendation } from '#/experiments/oop-2349/actions/ibyc-recommendations-action-creators';
import {
  toggleRecsCarouselFlag,
  updateRecsCarouselDetails,
} from '#/experiments/oop-2349/actions/carousel-action-creator';

type OwnProps = {
  t: TTranslateFunc;
  items: Array<Item>;
  resultType: string;
  pageId: string;
  clearIbycRecommendation: Function;
  toggleRecsCarouselFlag: Function;
  updateRecsCarouselDetails: Function;
};

type OwnState = {
  items: Array<Item>;
  isIBYCTrexEmpty: boolean;
  resultType: string;
  hasIBYCTrexError: boolean;
  isRecsCarouselVisible: boolean;
};

type Props = OwnProps & OwnState;

const mapStateToProps = (state: Store): OwnState => {
  const getPageResultsItemsByType = getPageResultsItemsByTypeSelector(state);
  const { results } = state;
  return {
    items: getPageResultsItemsByType(state),
    isIBYCTrexEmpty: getIBYCTrexStatus(state, 'empty'),
    resultType: results?.resultsType,
    hasIBYCTrexError: getIBYCTrexStatus(state, 'error'),
    isRecsCarouselVisible: getIsRecsCarouselVisible(state),
  };
};

const connector = connect(mapStateToProps, {
  clearIbycRecommendation,
  toggleRecsCarouselFlag,
  updateRecsCarouselDetails,
});

const IBYCRecommendation = (props: Props): JSX.Element | null => {
  const { t: translate, items, isIBYCTrexEmpty, resultType, hasIBYCTrexError, pageId, isRecsCarouselVisible } = props;

  const getExcludedProductsTPNBs = (): string[] => {
    const excludeProductList: string[] = [];
    items && items?.forEach(item => isProduct(item) && excludeProductList.push(getBaseProductId(item)));
    return excludeProductList;
  };

  const excludedProductsList: string[] = getExcludedProductsTPNBs();

  const configKey = resultType === SEARCH ? SEARCH_RECS_CONFIG_KEY : CATEGORY_RECS_CONFIG_KEY;

  useEffect(() => {
    const { clearIbycRecommendation, toggleRecsCarouselFlag, updateRecsCarouselDetails } = props;
    return () => {
      if (isRecsCarouselVisible) {
        toggleRecsCarouselFlag();
        updateRecsCarouselDetails({ itemIndex: null });
        clearIbycRecommendation();
      }
    };
  }, [isRecsCarouselVisible]);

  return !isIBYCTrexEmpty ? (
    <StyledIBYCRecommenderWrapper>
      <h2 className="ibyc-title">{translate(`${IBYC_LOCALE_PREFIX}.ibyc-heading`)}</h2>

      {!hasIBYCTrexError ? (
        <IBYCRecommenderWrapper
          page={resultType}
          pageId={pageId}
          excludedProducts={excludedProductsList}
          configKey={configKey}
          showBeansCarousel
          isIBYCRecommendation={true}
        />
      ) : (
        <RecommenderErrorWrapper>
          <ErrorIconWrapper />
          <ErrorHeader headingLevel="3">{translate(`${IBYC_LOCALE_PREFIX}.ibyc-error-heading`)}</ErrorHeader>
          <ErrorText>{translate(`${IBYC_LOCALE_PREFIX}.ibyc-error-message`)}</ErrorText>
        </RecommenderErrorWrapper>
      )}
    </StyledIBYCRecommenderWrapper>
  ) : null;
};

export default helpers(['t'])(connector(IBYCRecommendation));
