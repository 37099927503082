import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { updateLineItemsPreference } from '#/actions/trolley/trolley-action-creators';
import getAnalyticsBus from '#/analytics/analyticsBus';
import { FIND_SUITABLE_ALTERNATIVE, DO_NOT_SUBSTITUTE } from '#/constants/substitution-options';
import HorizontalTile from '#/components/product-tiles/horizontal-tile/core';
import { TTranslateFunc, TConfigFunc, TFeatureFunc } from '#/lib/records/helpers.defs';
import { Item } from '#/lib/records/item';
import { Preference } from '#/lib/records/product-utils/preference-payload';
import { isOnDemandShoppingMethod } from '#/lib/shopping-method-util';
import RenderRemoveItem from '#/components/products/product-tile/tile-components/shared/render-remove-item';
import { isProductAvailable as getIsProductAvailable } from '#/selectors/beans-product-tile/product-availability-data';
import { trolleyPageShouldShowRemoveButton } from '#/selectors/beans-product-tile/remove-button-data';
import {
  getBaseProductId,
  getIsSubstitute,
  getSubstitutionOption,
  getIsSubstitutionBlocked,
  getSeedProductId,
  getQuantity,
  getProductId,
  getPickerNote,
  getProductTitle,
  getIsMarketplaceProduct,
} from '#/selectors/item';
import {
  getSubstituteAllItems,
  getTrolleyShoppingMethod,
  getTrolleyItemByBaseProductId,
  getShouldShowUnavailablePrice,
} from '#/selectors/trolley';
import SubstitutionsCheckbox from './substitutions-checkbox';
import PickerNotes from '#/components/product-tiles/horizontal-tile/variant/trolley-tile/picker-notes';
import { getIsWhooshDeliverySubstitutionsEnabled } from '#/toggles/whoosh-delivery-substitutions';
import {
  NOW,
  PICKER_NOTE,
  PRODUCT_TILE,
  PRODUCT_SUBSTITUTION_OPT_IN,
  PRODUCT_SUBSTITUTION_OPT_OUT,
  UI_EVENT_BASIC_EVENT,
} from '#/analytics/constants';

export interface TrolleyTileBaseProps {
  item: Item;
  translate: TTranslateFunc;
  config: TConfigFunc;
  feature: TFeatureFunc;
  shouldShowWriteAReview?: boolean;
  shouldShowRestOfShelf?: boolean;
}

interface TrolleyTileDispatchProps {
  updateLineItemsPreference: (item: Item, preference: Preference) => void;
}

interface TrolleyTileStateProps {
  shouldAllowSubstitutionsDefault: boolean;
  shouldShowSubstitutionsCheckbox: boolean;
  shouldShowRestOfShelf: boolean;
  shouldShowPriceOnUnavailableTiles: boolean;
  substitutionFor: Item | null;
  showTotalPrice: boolean;
  productId: string;
  pickerNote: string | undefined;
  shouldShowPickerNote: boolean;
  isSubstitute?: boolean;
  productTitle: string;
}

export type TrolleyTileProps = TrolleyTileBaseProps & TrolleyTileDispatchProps & TrolleyTileStateProps;

export const mapStateToProps = (
  state: Store,
  { feature, item, config }: TrolleyTileBaseProps,
): TrolleyTileStateProps => {
  const shoppingMethod = getTrolleyShoppingMethod(state);
  const isProductAvailable = getIsProductAvailable(state, item);
  const isSubstitute = getIsSubstitute(item);
  const isWhooshDeliverySubstitutionsEnabled = getIsWhooshDeliverySubstitutionsEnabled(state);
  const isSubstitutionBlocked = getIsSubstitutionBlocked(item);
  const isMarketplaceProduct = getIsMarketplaceProduct(item);

  const shouldShowSubstitutionsCheckbox =
    ((feature('showLineItemSubstitutionPreference') && !isOnDemandShoppingMethod(shoppingMethod)) ||
      isWhooshDeliverySubstitutionsEnabled) &&
    isProductAvailable &&
    !isSubstitute &&
    !isSubstitutionBlocked &&
    !isMarketplaceProduct;
  const seedProductId = getSeedProductId(item);
  const shouldShowUnavailableItemPrice = getShouldShowUnavailablePrice(state, config);

  return {
    isSubstitute,
    shouldAllowSubstitutionsDefault: shouldShowSubstitutionsCheckbox && getSubstituteAllItems(state),
    shouldShowSubstitutionsCheckbox,
    shouldShowPickerNote:
      feature('showPickerNotes') && (!shouldShowUnavailableItemPrice || isProductAvailable) && !isMarketplaceProduct,
    shouldShowRestOfShelf: isProductAvailable,
    shouldShowPriceOnUnavailableTiles: shouldShowUnavailableItemPrice,
    substitutionFor: seedProductId ? getTrolleyItemByBaseProductId(state, seedProductId) : null,
    showTotalPrice: !!getQuantity(item),
    productId: getProductId(item),
    pickerNote: getPickerNote(item),
    productTitle: getProductTitle(item),
  };
};

export const TrolleyTile = (props: TrolleyTileProps): JSX.Element => {
  const {
    item,
    shouldAllowSubstitutionsDefault,
    shouldShowSubstitutionsCheckbox,
    translate,
    updateLineItemsPreference,
    substitutionFor,
    showTotalPrice,
    productId,
    pickerNote,
    shouldShowPickerNote,
    productTitle,
  } = props;

  const handleCheckboxChange = useMemo(
    () => (event: React.ChangeEvent<HTMLInputElement>): void => {
      const shouldAllowSubstitutions = event.target.checked;

      updateLineItemsPreference(item, {
        pickerNote: getPickerNote(item) as string,
        subs: shouldAllowSubstitutions,
      });

      const analyticsBus = getAnalyticsBus();
      analyticsBus.emit(UI_EVENT_BASIC_EVENT, {
        action: NOW,
        type: PRODUCT_TILE,
        value: shouldAllowSubstitutions ? PRODUCT_SUBSTITUTION_OPT_IN : PRODUCT_SUBSTITUTION_OPT_OUT,
        contextValue: item.product?.baseProductId,
      });
    },
    [item, updateLineItemsPreference],
  );

  const shouldAllowSubstitutions = useMemo(() => {
    const substitutionsOption = getSubstitutionOption(item);
    return substitutionsOption ? substitutionsOption === FIND_SUITABLE_ALTERNATIVE : shouldAllowSubstitutionsDefault;
  }, [item, shouldAllowSubstitutionsDefault]);

  const handlePickerNoteChange = useMemo(
    () => (value: string): void => {
      updateLineItemsPreference(item, {
        pickerNote: value,
        subs: shouldAllowSubstitutions,
      });
    },
    [item, updateLineItemsPreference],
  );

  const handlePickerNoteBlur = useCallback((): void => {
    getAnalyticsBus().emit(UI_EVENT_BASIC_EVENT, {
      action: NOW,
      type: PRODUCT_TILE,
      value: PICKER_NOTE,
      contextValue: getBaseProductId(props?.item),
    });
  }, [item]);

  const shouldShowRemoveButton = useMemo(() => trolleyPageShouldShowRemoveButton(item), [item]);

  return (
    <HorizontalTile
      {...props}
      isTrolley
      shouldShowWriteAReview={false}
      deleteActionButton={shouldShowRemoveButton ? <RenderRemoveItem placement="relative" item={item} /> : undefined}
      substitutionFor={substitutionFor}
      showTotalPrice={showTotalPrice}
      showPromotionInfoBox={true}
    >
      {shouldShowSubstitutionsCheckbox ? (
        <SubstitutionsCheckbox
          checked={shouldAllowSubstitutions}
          item={item}
          onChange={handleCheckboxChange}
          translate={translate}
        />
      ) : null}
      {shouldShowPickerNote && (
        <PickerNotes
          item={item}
          label={translate('product-tile:picker-note-update-add-note')}
          ariaLabel={`${translate('product-tile:allow-preference-for')} ${productTitle}`}
          onChange={handlePickerNoteChange}
          onBlurHandler={handlePickerNoteBlur}
          productId={productId}
          pickerNote={pickerNote}
          substitutionOption={shouldAllowSubstitutions ? FIND_SUITABLE_ALTERNATIVE : DO_NOT_SUBSTITUTE}
        />
      )}
    </HorizontalTile>
  );
};

export default connect(mapStateToProps, { updateLineItemsPreference })(TrolleyTile);
