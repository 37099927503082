import { BASKET, BASKET_LIMIT_EXCEEDED } from '#/analytics/constants';
import { emitErrorDataEvent } from '#/analytics/types/error-data';
import { VOLUME_OR_WEIGHT_EXCEEDED } from '#/constants/error-codes';

export function emitOnBasketBreach() {
  emitErrorDataEvent({
    code: VOLUME_OR_WEIGHT_EXCEEDED,
    type: BASKET,
    text: BASKET_LIMIT_EXCEEDED
  });
}
