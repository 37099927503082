import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const OrderedList = ({ listItems, className }) => {
  const listClassNames = classnames('ordered-list', className);
  const listItemClassNames = classnames(
    'ordered-list__item',
    `${className}__item`
  );

  return (
    <ol className={listClassNames}>
      {listItems.map((item, ind) => (
        <li key={ind} className={listItemClassNames}>
          {item}
        </li>
      ))}
    </ol>
  );
};

OrderedList.propTypes = {
  className: PropTypes.string,
  listItems: PropTypes.array.isRequired
};

export { OrderedList };
