import classnames from 'classnames';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getSlug } from '../../lib/url/url-utils';
import { DDSAccordion } from '../trolley/trolley-order-summary/basket-summary-content/dds-accordion';
export default class Accordion extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    classes: PropTypes.string,
    header: PropTypes.node,
    headerOnOpen: PropTypes.node,
    icon: PropTypes.string,
    id: PropTypes.string,
    innerTag: PropTypes.string,
    isOpen: PropTypes.bool,
    tag: PropTypes.node,
    title: PropTypes.string.isRequired,
    toggleHandler: PropTypes.func
  };

  static defaultProps = {
    icon: 'icon-chevron_down',
    isOpen: false,
    tag: 'div',
    innerTag: 'button'
  };

  constructor(props) {
    super(props);

    this.state = {
      isOpen: props.isOpen
    };
  }

  toggle = () => {
    const openState = !this.state.isOpen;

    this.setState({ isOpen: openState });

    if (this.props.toggleHandler) {
      this.props.toggleHandler(openState);
    }
  };

  displayHeader() {
    return this.props.headerOnOpen && this.state.isOpen
      ? this.props.headerOnOpen
      : this.props.header;
  }

  displayIcon() {
    return this.state.isOpen ? 'contract' : 'expand';
  }

  render() {
    const {
      title,
      tag,
      innerTag,
      icon,
      children,
      id: accorId,
      showDDSAccordion
    } = this.props;
    const id = getSlug(title);
    const Tag = tag;
    const InnerTag = innerTag;
    const innerTagProps = {
      'aria-controls': `accordion-${id}`,
      'aria-expanded': this.state.isOpen,
      onClick: this.toggle,
      type: innerTag === 'button' ? innerTag : undefined,
      ...(accorId && { id: `accordion-${getSlug(accorId)}` })
    };
    const classes = classnames(
      'accordion',
      { 'is-open': this.state.isOpen },
      this.props.classes
    );

    return showDDSAccordion ? (
      <DDSAccordion
        isOpen={this.state.isOpen}
        classes={classes}
        onClick={this.toggle}
        displayHeader={this.displayHeader()}
        displayIcon={this.displayIcon()}
      >
        {children}
      </DDSAccordion>
    ) : (
      <Tag className={classes} data-auto="accordion">
        <InnerTag className="accordion--button" {...innerTagProps}>
          <span className="accordion--title">{this.displayHeader()}</span>
          <span className={`accordion--icon ${icon}`} />
        </InnerTag>
        <div className="accordion--content" id={`accordion-${id}`}>
          {children}
        </div>
      </Tag>
    );
  }
}
