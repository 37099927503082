import { createSelector } from 'reselect';
import { getResults, getResultsByTypeFactory } from '#/selectors/results';

export const getResultsPageNumber = createSelector(
  getResults,
  results => results.pageNo || 0
);

export const getResultsPageNumberByTypeFactory = resultsType =>
  createSelector(
    getResultsByTypeFactory(resultsType),
    results => results.pageNo || 0
  );
