import React, { Component } from 'react';
import PropTypes from 'prop-types';
import helpers from '#/lib/decorators/helpers';
import { sessionStore } from '#/lib/data-store/client-store';
import { getCurrentUrl, getLanguageLink } from '#/reducers/app';
import { connect } from '#/lib/render/connect-deep-compare';
import { getSelectedFavoritesTab } from '#/reducers/ui';
import {
  FAVORITES_TAB,
  USUALS_TAB,
  FAV_SCROLL_POS_Y,
  LAST_ORDER_TAB,
  QUERY_LAYOUT
} from '#/constants/favorites';
import Tabs from '../tabs';
import FavoritesTabHeader from './favorites-tab-header';
import FavTabs from '#/components/tabs/beans';
import { updateParamsInUrl } from '#/lib/url/url-utils';
import { OFFERS_FOR_YOU_TAB } from '#/experiments/oop-1557/constants/favorites';
import { getShouldShowOrderDropdown } from '#/experiments/oop-1720/selectors';
import { getFavoritesLanguageLink } from '#/utils/favorite-utils';
import { scrollToTop } from '#/lib/browser/ui-util';

const mapStateToProps = state => {
  return {
    currentUrl: getCurrentUrl(state),
    tabHeaderUrl: getLanguageLink(state, '/favorites'),
    favTabLink: getFavoritesLanguageLink(state, '/favorites'),
    selectedFavoritesTab: getSelectedFavoritesTab(state),
    shouldShowRecentOrderTitle: getShouldShowOrderDropdown(state)
  };
};

@helpers(['t'])
@connect(mapStateToProps)
export default class FavoritesPageTabs extends Component {
  static propTypes = {
    activeTab: PropTypes.oneOf([
      FAVORITES_TAB,
      USUALS_TAB,
      LAST_ORDER_TAB,
      OFFERS_FOR_YOU_TAB
    ]).isRequired,
    children: PropTypes.node.isRequired,
    currentUrl: PropTypes.string.isRequired,
    lastOrderEnabled: PropTypes.bool.isRequired,
    newFavNavEnabled: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    tabHeaderUrl: PropTypes.string.isRequired,
    shouldShowRecentOrderTitle: PropTypes.bool.isRequired,
    usualsEnabled: PropTypes.bool.isRequired,
    favTabLink: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    const {
      t: translate,
      usualsEnabled,
      lastOrderEnabled,
      newFavNavEnabled,
      offersForYouEnabled,
      shouldShowRecentOrderTitle,
      favTabLink,
      tabHeaderUrl
    } = props;

    this.tabs = [
      {
        href: this.updateQueryParameters(FAVORITES_TAB, favTabLink),
        id: FAVORITES_TAB,
        label: translate('product-list:previously-bought')
      }
    ];

    if (usualsEnabled) {
      this.tabs.push({
        href: this.updateQueryParameters(USUALS_TAB, tabHeaderUrl),
        id: USUALS_TAB,
        label: translate('product-list:usuals')
      });
    }

    if (newFavNavEnabled && lastOrderEnabled) {
      this.tabs.push({
        href: this.updateQueryParameters(LAST_ORDER_TAB, tabHeaderUrl),
        id: LAST_ORDER_TAB,
        label: shouldShowRecentOrderTitle
          ? translate('product-list:recent-order-tab.title')
          : translate('product-list:last-order-tab.title')
      });
    }

    if (newFavNavEnabled && offersForYouEnabled) {
      this.tabs.push({
        href: this.updateQueryParameters(OFFERS_FOR_YOU_TAB, tabHeaderUrl),
        id: OFFERS_FOR_YOU_TAB,
        label: translate('product-list:offers-for-you.title')
      });
    }

    this.setStorageData = this.setStorageData.bind(this);
  }

  updateQueryParameters(tab, url) {
    const params =
      tab === FAVORITES_TAB ? { tab } : { tab, [QUERY_LAYOUT]: null };
    return updateParamsInUrl(url, params);
  }

  onKeyPressed = e => {
    if (
      e.key === 'ArrowRight' ||
      e.key === 'ArrowLeft' ||
      e.key === 'Home' ||
      e.key === 'End'
    ) {
      const usualsTab = document.getElementById(USUALS_TAB);
      const favoritesTab = document.getElementById(FAVORITES_TAB);
      if (favoritesTab.matches(':focus')) {
        usualsTab.focus();
      } else if (this.props.usualsEnabled) {
        favoritesTab.focus();
      }
    } else if (e.key === ' ') {
      const usualsTab = document.getElementById(USUALS_TAB);
      const favoritesTab = document.getElementById(FAVORITES_TAB);
      if (
        (this.props.activeTab === FAVORITES_TAB &&
          usualsTab.matches(':focus')) ||
        (this.props.activeTab === USUALS_TAB && favoritesTab.matches(':focus'))
      ) {
        e.preventDefault();
        e.currentTarget.click();
      }
    }
  };

  isActiveTab = tab => this.props.activeTab === tab;

  renderFavoritesHeader() {
    const { t: translate, favTabLink } = this.props;

    const mainText = translate('product-list:my-favorites');

    return (
      <FavoritesTabHeader
        iconClass="favorite-heart-icon"
        mainTextLong={mainText}
        subText={translate('product-list:favourites-description')}
        url={this.updateQueryParameters(FAVORITES_TAB, favTabLink)}
        headerClassName="favorites-tabs__header favorites-tabs__header--favourites"
        isActive={this.isActiveTab(FAVORITES_TAB)}
        ariaControls={FAVORITES_TAB}
        onKeyPressed={this.onKeyPressed}
      />
    );
  }

  renderUsualsHeader() {
    const { t: translate, tabHeaderUrl } = this.props;

    const mainText = translate('product-list:usuals');

    return (
      <FavoritesTabHeader
        iconClass="icon-usuals_icon"
        mainTextLong={mainText}
        subText={translate('product-list:usuals-description')}
        url={this.updateQueryParameters(USUALS_TAB, tabHeaderUrl)}
        headerClassName="favorites-tabs__header favorites-tabs__header--usuals"
        isActive={this.isActiveTab(USUALS_TAB)}
        ariaControls={USUALS_TAB}
        onKeyPressed={this.onKeyPressed}
      />
    );
  }

  setStorageData() {
    const { selectedFavoritesTab } = this.props;

    let currentScrollPosY = window.scrollY || window.pageYOffset;

    sessionStore?.set(FAV_SCROLL_POS_Y, {
      [selectedFavoritesTab]: currentScrollPosY
    });

    scrollToTop();
  }

  componentDidMount() {
    window.addEventListener('unload', this.setStorageData);
  }

  componentWillUnmount() {
    this.setStorageData();
    window.removeEventListener('unload', this.setStorageData);
  }

  render() {
    const {
      selectedFavoritesTab,
      newFavNavEnabled,
      children,
      t: translate
    } = this.props;
    if (newFavNavEnabled) {
      return (
        <FavTabs tabs={this.tabs} selectedTab={selectedFavoritesTab}>
          {children}
        </FavTabs>
      );
    }

    return (
      <>
        <Tabs
          className="favorites-tabs"
          displayTabContent={false}
          tabAriaLabel={translate('product-list:my-favorites')}
        >
          {this.renderFavoritesHeader()}
          {this.props.usualsEnabled && this.renderUsualsHeader()}
        </Tabs>
        {children}
      </>
    );
  }
}
