import { openModal } from '#/actions/ui-action-creators';
import { GHS_PRODUCT, IGHS_PRODUCT, MARKETPLACE_PRODUCT } from '#/constants/common';
import { UK } from '#/constants/regions';
import { BOOK_SLOT_MODAL, BOOK_SLOT_MODAL_LOCAL_STORE_KEY, SLOT_PROMPT_QUANTITY } from '#/constants/trolley';
import { Dispatch } from '#/custom-typings/redux-store/common';
import { sessionStore } from '#/lib/data-store/client-store';
import { Item } from '#/lib/records/item';
import { getAppRegion } from '#/reducers/app';
import { getItems, hasValidSlot } from '#/selectors/trolley';

export const showBookSlotModal = ({ product: { typename } }: Item, state: Store, dispatch: Dispatch): void => {
  if (typename === MARKETPLACE_PRODUCT || getAppRegion(state) !== UK) return;

  const hasBookSlotModalDisplayed = sessionStore?.get(BOOK_SLOT_MODAL_LOCAL_STORE_KEY) || false;
  if (hasBookSlotModalDisplayed) return;

  const isSlotBookedOrReserved = hasValidSlot(state);

  if (isSlotBookedOrReserved) return;

  const totalGHSItems = getItems(state.trolley)
    .filter(({ product: { typename } }) => typename === IGHS_PRODUCT || typename == GHS_PRODUCT)
    .reduce((sum, item) => (item.quantity ? sum + item.quantity : sum), 0);

  if (totalGHSItems >= SLOT_PROMPT_QUANTITY) {
    dispatch(openModal(BOOK_SLOT_MODAL));
  }
};
