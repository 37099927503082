/**
 * Implements an <input type='checkbox' with a <label> that optionally allows
 * HTML content.
 *
 * If `multiline` is supplied an extra class is supplied to make sure the
 * check square stays in the top left relative to the <label> content.
 *
 * If `dangerously-set-inner-HTML` is supplied then HTML content is rendered
 * into the label instead of having children passed into it.
 *
 * Supplying a function for `on-change` will let parent components know if
 * the user has tried to change the value. The new value can then be passed
 * back in as `checked`.
 */
import React, { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import classSet from 'classnames';

export default class Checkbox extends PureComponent {
  static propTypes = {
    checked: PropTypes.bool,
    children: PropTypes.node,
    disabled: PropTypes.bool,
    domRef: PropTypes.func,
    error: PropTypes.bool,
    focus: PropTypes.bool,
    id: PropTypes.string.isRequired,
    linkProps: PropTypes.shape({
      className: PropTypes.string,
      href: PropTypes.string,
      onClick: PropTypes.func,
      rel: PropTypes.string,
      tabIndex: PropTypes.number
    }),
    multiline: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func,
    tooltip: PropTypes.string,
    value: PropTypes.string,
    whiteIcon: PropTypes.bool
  };

  static defaultProps = {
    disabled: false,
    error: false
  };

  constructor(props) {
    super(props);
    this.linkRef = createRef();
  }

  componentDidMount() {
    this.setFocus(this.linkRef.current);
  }

  setRef = ref => {
    this.linkRef.current = ref;
    this.props.domRef?.(ref);
  };

  setFocus = ele => {
    if (this.props.focus) {
      ele?.focus();
    }
  };

  render() {
    const {
      id,
      tooltip,
      multiline,
      error,
      children,
      checked,
      name,
      onChange,
      disabled,
      value,
      whiteIcon,
      linkProps
    } = this.props;
    const labelAttributes = {
      className: 'checkbox-label',
      'data-auto': 'checkbox-label',
      htmlFor: id,
      title: tooltip
    };

    const checkboxClasses = classSet({
      checkbox: true,
      'checkbox-multiline': multiline,
      'checkbox-error': error
    });

    const label = <label {...labelAttributes}>{children}</label>;

    const inputProps = {
      checked: checked,
      id: id,
      name: name,
      onChange: onChange,
      type: 'checkbox',
      disabled
    };

    if (typeof value !== 'undefined') {
      inputProps.value = value;
    }

    const iconClasses = classSet('checkbox-check', {
      'icon-tick': !disabled && !whiteIcon,
      'icon-tick-white': disabled || whiteIcon
    });

    return (
      <div className={checkboxClasses} data-auto="checkbox">
        <input {...inputProps} />
        {linkProps ? (
          <label className="checkbox-label" htmlFor={id} title={tooltip}>
            <a {...linkProps} ref={this.setRef}>
              <span className="checkbox-square checkbox-square-linked" />
              <span className={iconClasses} />
              {children}
            </a>
          </label>
        ) : (
          <div>
            <div className="checkbox-square" />
            <div className={iconClasses} />
            {label}
          </div>
        )}
      </div>
    );
  }
}
