export enum productTileDietaryInfoVariants {
  default = 'default',
  dietaryTags = 'dietaryTags',
  dietaryIcon = 'dietaryIcon',
  dietaryLogo = 'dietaryLogo',
}

export type DietaryInfoFeatureConfig = {
  experiment: string;
  variation: string;
  productTileUuidConfig: productTileDietaryInfoVariants;
};

export const productTileDietaryInfo = {
  featureKey: 'GHS-ALL_product-tile-testing-uuid',
  variationKey: 'productTileUuidConfig',
  useCustomerId: true,
  serviceConfig: {
    params: {
      productTileUuidConfig: productTileDietaryInfoVariants.default,
    },
  },
};

export enum DIETARY_ICONS {
  VEGETARIAN = 'vegetarian',
  VEGAN = 'vegan',
}

export const DIETARY_BACKGROUND_COLOR = '#005200';
