import {
  getProductCatchWeightList,
  getProductUnitOfMeasure,
  getCatchWeight,
  getOriginalCatchWeight,
  getProductId,
  getProductTitle,
} from '#/selectors/item';
import formatPrice from '#/lib/string-formatting/number-formatter';
import { getCurrency } from '#/reducers/app';
import { Item } from '#/lib/records/item';
import { TTranslateFunc } from '#/lib/records/helpers.defs';
export type TCatchweightDataOption = {
  price: string;
  unit: string;
  value?: number;
};

export type TCatchweightData = {
  variantOptions: {
    id: string;
    label: string;
    type: string;
    options: TCatchweightDataOption[];
  };
  initialVariantValue?: number;
};

export const getCatchweightData = (state: any, item: Item, translation: TTranslateFunc): TCatchweightData => {
  const catchWeightList = getProductCatchWeightList(item) || [];
  const unitOfMeasure = getProductUnitOfMeasure(item);
  const productId = getProductId(item);
  const productTitle = getProductTitle(item);
  const priceConfig = {
    ...getCurrency(state),
    showAsciiSymbol: true,
    compactMode: true,
  };

  const options = catchWeightList.map(({ price, weight }) => ({
    price: `${formatPrice(price, priceConfig)}`,
    unit: `${weight}${unitOfMeasure}`,
    value: weight,
  }));

  return {
    variantOptions: {
      type: 'dropdown',
      id: `catchWeight-${productId}`,
      label: translation('product-tile:select-weight-of', { productTitle }),
      options,
    },
    initialVariantValue: getCatchWeight(item) || getOriginalCatchWeight(item),
  };
};
