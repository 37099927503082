import React, { Component } from 'react';
import PropTypes from 'prop-types';
import helpers from '../../lib/decorators/helpers';
import SafeForm from './safe-form';
import SmartSubmitButton from './smart-submit-button';
import analyticsBus from '#/analytics/analyticsBus';
import { basicEvent } from '#/analytics/types/basic';
import { DELAY, MY_ORDERS, MAKE_CHANGES } from '#/analytics/constants';

@helpers(['l', 't'])
class AmendOrderButton extends Component {
  static propTypes = {
    amendCutoff: PropTypes.string.isRequired,
    buttonClasses: PropTypes.string,
    buttonDisabled: PropTypes.bool,
    buttonOnClickEvt: PropTypes.func,
    buttonText: PropTypes.string,
    dataAuto: PropTypes.string,
    ddl: PropTypes.bool,
    formClasses: PropTypes.string,
    l: PropTypes.func.isRequired,
    orderNo: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired
  };

  static defaultProps = {
    buttonDisabled: false
  };

  constructor() {
    super();
    this.state = {
      submitting: false
    };

    this.onSubmitForm = this.onSubmitForm.bind(this);
  }

  onSubmitForm() {
    basicEvent(analyticsBus, {
      action: DELAY,
      type: MY_ORDERS,
      value: MAKE_CHANGES
    });
    this.setState({
      submitting: true
    });
  }

  render() {
    const {
      orderNo,
      formClasses,
      buttonClasses,
      buttonDisabled,
      dataAuto,
      buttonText,
      buttonOnClickEvt,
      ddl,
      l: languageLink,
      t: translate,
      amendCutoff
    } = this.props;

    const { submitting } = this.state;

    return (
      <SafeForm
        action={languageLink(`/orders/${orderNo}/amend`)}
        className={formClasses}
        method="POST"
        onSubmit={this.onSubmitForm}
      >
        <SmartSubmitButton
          buttonStyles={buttonClasses}
          disabled={buttonDisabled}
          dataAuto={dataAuto}
          primaryText={translate(buttonText)}
          onClick={buttonOnClickEvt}
          submitting={submitting}
          ddl={ddl}
        />
        <input name="amendCutoff" type="hidden" value={amendCutoff} />
      </SafeForm>
    );
  }
}

export default AmendOrderButton;
