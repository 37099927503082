import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import DateTile from '#/components/context-cards/slot/date-tile';
import helpers from '#/lib/decorators/helpers';
import { SLOT_EXPIRED } from '#/components/context-cards/slot/constants';
import FormattedSlotTime from '#/components/shared/formatted-time/formattedSlotTime';

export const SlotDetails = props => {
  const getHeading = () => {
    const { status, shoppingMethod, t: translate } = props;

    return status === SLOT_EXPIRED
      ? translate('context-cards:slot-expired')
      : translate(`common:shopping-method_${shoppingMethod}`);
  };

  const { showSlotWindow, slot, start, end, t: translate, c: config } = props;
  const isGlobalHeader = config('isGlobalHeader');

  return (
    <span
      className={classnames('context-card-slot-details', {
        'context-card-slot-details--global-header': isGlobalHeader
      })}
    >
      <span role="heading" aria-level="3" className="visually-hidden">
        {translate('context-cards:delivery-information')}
      </span>
      <span>
        <span className="heading">{getHeading()}</span>
        <DateTile date={slot.start} />
        {showSlotWindow && (
          <span className="slot-time">
            <FormattedSlotTime startTime={start} endTime={end} />
          </span>
        )}
      </span>
    </span>
  );
};

SlotDetails.defaultProps = {
  showSlotWindow: true
};

SlotDetails.propTypes = {
  end: PropTypes.object.isRequired,
  shoppingMethod: PropTypes.string.isRequired,
  showSlotWindow: PropTypes.bool,
  slot: PropTypes.object.isRequired,
  start: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired
};

export default helpers(['t', 'c'])(SlotDetails);
