import React from 'react';
import { FontSizes, Text } from '@ddsweb/text';
import { TTranslateFunc } from '#/lib/records/helpers.defs';
import { SellerInfo } from '#/lib/records/item.defs';
import { Wrapper, Image, StyledFootnote } from '#/components/product-tiles/common/seller-info/styled';

type Props = SellerInfo & { translate: TTranslateFunc };

const SellerInformation = ({ name, logo, fulfilmentMessages, translate }: Props): JSX.Element => {
  const deliveryMessage =
    Array.isArray(fulfilmentMessages) && fulfilmentMessages.length > 0 ? fulfilmentMessages[0] : '';

  return (
    <Wrapper>
      <StyledFootnote as="p" size={FontSizes.small}>
        <span>{`${translate('product-tile:seller.sent-from')} `}</span>
        {logo ? <Image src={logo} alt={name} /> : <span>{name}</span>}
      </StyledFootnote>
      <Text as="p" size={FontSizes.small}>
        {deliveryMessage}
      </Text>
    </Wrapper>
  );
};

export default SellerInformation;
