import { promoteFavoritesOnPLPVariants, promoteFavoritesOnPLPFeatureConfig } from '#/experiments/oop-1693/constants';
import { getFeatureVariableStringFromState } from '#/lib/optimizely-manager';
import { getIsFirstTimeShopper } from '#/reducers/user';
import { SEARCH, PROMOTIONS, CATEGORY } from '#/constants/result-types';
import { VIEW_AND_BUY_TYPE_OFFERS } from '#/constants/display-types';

const resultTypes = [SEARCH, PROMOTIONS, VIEW_AND_BUY_TYPE_OFFERS, CATEGORY];

const getVariant = (state: Store): promoteFavoritesOnPLPVariants => {
  const { useCustomerId, featureKey, variationKey } = promoteFavoritesOnPLPFeatureConfig;
  const isFirstTimeShopper = getIsFirstTimeShopper(state);
  const attributes = { hasFavourites: String(!isFirstTimeShopper) };

  return (
    getFeatureVariableStringFromState(state, featureKey, variationKey, { useCustomerId, attributes }) ||
    promoteFavoritesOnPLPVariants.default
  );
};

export const getShouldShowPromoteFavorites = (state: Store, resultType: string): boolean => {
  if (!resultTypes.includes(resultType)) {
    return false;
  }
  const variant = getVariant(state);
  const { default: defaultVariant } = promoteFavoritesOnPLPVariants;

  return variant !== defaultVariant;
};

export const getPromoteFavoritesVariant = (state: Store): promoteFavoritesOnPLPVariants => getVariant(state);
