import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ConnectedProps } from 'react-redux';
import { compose } from 'react-recompose';
import { connect } from '#/lib/render/connect-deep-compare';
import PickerNotesDropdown from '../picker-notes-dropdown';
import { StyledButton, Wrapper } from '../styled';
import { getIsUserItemPreferencesUpdating } from '#/selectors/trolley';
import { Item } from '#/lib/records/item';
import { IDLE, SAVING } from '#/constants/instruction-status';

type OwnProps = {
  label: string;
  item: Item;
  onBlurHandler: () => void;
  onChange: (arg: string) => void;
  pickerNote: string | undefined;
  productId: string;
  substitutionOption: string;
  ariaLabel: string;
};

type OwnState = {
  status: string | undefined;
};

const mapStateToProps = (state: Store): OwnState => ({
  status: getIsUserItemPreferencesUpdating(state),
});

const connector = connect(mapStateToProps, {});
type Props = OwnProps & ConnectedProps<typeof connector>;

const PickerNotes = ({
  label,
  status: pickerNoteStatus,
  productId,
  onBlurHandler,
  onChange,
  pickerNote = '',
  substitutionOption,
  ariaLabel,
}: Props): JSX.Element => {
  const [showPickerNote, setShowPickerNote] = React.useState(pickerNote?.length > 0);
  const [status, setStatus] = React.useState<string | undefined>(IDLE);

  useEffect(() => {
    if (pickerNoteStatus !== status && status === SAVING) {
      setStatus(pickerNoteStatus);
    }
  }, [pickerNoteStatus]);

  const handleButtonClick = (): void => {
    setShowPickerNote(!showPickerNote);
  };

  const onChangeHandler = (value: string): void => {
    setStatus(SAVING);
    onChange(value);
  };

  return (
    <Wrapper>
      <StyledButton variant="link" onClick={handleButtonClick}>
        <span className="icon-edit_pencil" data-auto="edit-pencil-icon" />
        {label}
        <span className="visually-hidden">{ariaLabel}</span>
      </StyledButton>
      {showPickerNote && (
        <PickerNotesDropdown
          status={status}
          productId={productId}
          onChange={onChangeHandler}
          onBlur={onBlurHandler}
          defaultValue={pickerNote}
          substitutionOption={substitutionOption}
        />
      )}
    </Wrapper>
  );
};

PickerNotes.propTypes = {
  label: PropTypes.string.isRequired,
};

export default compose<Props, OwnProps>(connector)(PickerNotes);
