import React, { Component } from 'react';
import PropTypes from 'prop-types';

import helpers from '#/lib/decorators/helpers';
import PricePerUnit from '#/components/products/price-per-unit';
import {
  getProductUnitOfMeasure,
  getProductUnitPrice,
  getProductIsForSale
} from '#/selectors/item';

@helpers(['c', 't'])
export default class RenderPricePerUnit extends Component {
  static propTypes = {
    c: PropTypes.func.isRequired,
    compactMode: PropTypes.bool,
    displayAvailable: PropTypes.bool,
    extendedText: PropTypes.bool,
    isAvailableEpwOverride: PropTypes.bool,
    isUnavailableWithSubstitute: PropTypes.bool,
    item: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
  };

  static defaultProps = {
    extendedText: false,
    displayAvailable: false,
    isAvailableEpwOverride: false,
    compactMode: false,
    isUnavailableWithSubstitute: false
  };

  render() {
    const {
      c: config,
      compactMode,
      displayAvailable,
      extendedText,
      isAvailableEpwOverride,
      item,
      isUnavailableWithSubstitute,
      t: translate
    } = this.props;

    if (
      displayAvailable ||
      getProductIsForSale(item) ||
      isAvailableEpwOverride ||
      isUnavailableWithSubstitute
    ) {
      return (
        <PricePerUnit
          unitOfMeasure={getProductUnitOfMeasure(item)}
          unitPrice={getProductUnitPrice(item)}
          compactMode={compactMode}
        />
      );
    }

    return (
      !config('showProductUnavailableMessages') && (
        <p className="unavailable-product">
          <span className="unavailable-product-message">
            {extendedText
              ? translate('product-tile:product-unavailable-trolley')
              : translate('product-tile:product-unavailable')}
          </span>
        </p>
      )
    );
  }
}
