import {
  existingInBasketAndExcluded,
  isProductBrowsableOnPDP,
  getProductIsForSale,
  hasRestrictedOrderAmendment,
  isRdgRestricted,
  isInFavourites,
} from '#/selectors/item';
import { getIsAmendBasket } from '#/selectors/trolley';
import { Item } from '#/lib/records/item';

export const isAvailableEpwOverride = (state: any, item: Item): boolean =>
  getIsAmendBasket(state) && existingInBasketAndExcluded(item);

export const isProductDisabled = (item: Item): boolean => !isProductBrowsableOnPDP(item);

export const isProductAvailable = (state: any, item: Item): boolean =>
  getProductIsForSale(item) || isAvailableEpwOverride(state, item);

export const isProductRestrictedDisabled = (item: Item): boolean =>
  hasRestrictedOrderAmendment(item) || isRdgRestricted(item);

export const isProductInFavourites = (item: Item): boolean => isInFavourites(item);
