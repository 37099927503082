import React from 'react';
import helpers from '#/lib/decorators/helpers';
import { TTranslateFn } from '#/components/slots/delivery-content-section/index.defs';
import StringToNode from '#/components/shared/string-to-node';

type PaginationResultTextProps = {
  max: number;
  min: number;
  total: number;
  t: TTranslateFn;
};

export const PaginationResultText: React.FC<PaginationResultTextProps> = ({ max, min, t, total }) => {
  const transKey = min === 1 && max === total ? 'search:all-results-dds' : 'search:results-count-dds';
  max = total > max ? max : total;

  return <StringToNode text={t(transKey, { max, min, total })} />;
};

export default helpers(['t'])(PaginationResultText);
