import React from 'react';
import Link from '#/components/link-check-spa';
import { TPromotionsData } from '#/selectors/beans-product-tile/promotions-data';
import {
  ProductPromotionStyling,
  StyledPromotionInfoBox,
  StyledPromotionsWithClubcardPriceContainer,
} from '#/components/product-tiles/common/promotions/styles';
import { FontSizes, Text } from '@ddsweb/text';
import { getPromotionSecondaryText } from '#/components/products/product-tile/helpers';
import { TTranslateFunc } from '#/lib/records/helpers.defs';
import { Promotion as OneTilePromotionComponent } from '@ddsweb/one-tile';
import { ClubCardBar } from '@ddsweb/value-bar';

export interface PromotionsProps {
  className?: string;
  clickHandler(): void;
  clubcardFlashSashInfoText?: string;
  isMarketPlaceProduct?: boolean;
  isVerticalTile?: boolean;
  productDrsChargeAmount?: number;
  promotions: TPromotionsData;
  region: 'UK' | 'IE' | 'HU' | 'CZ' | 'SK';
  renderTermText?: boolean;
  showCCPriceLogoInSquare: boolean;
  showPromotionInfoBox?: boolean;
  tileVariant?: string;
  translate: TTranslateFunc;
}

interface TProductPromotionProps {
  clickHandler(): void;
  clubcardFlashSashInfoText?: string;
  isVerticalTile?: boolean;
  link: string | undefined;
  offerText: string;
  productDrsChargeAmount?: number;
  promotionSecondaryText?: string;
  region: 'UK' | 'IE' | 'HU' | 'CZ' | 'SK';
  renderTermText?: boolean;
  showCCPriceLogoInSquare: boolean;
  termsText?: string | null;
  tileVariant?: string;
}

const ProductPromotionWithClubcardPrice = ({
  clickHandler,
  clubcardFlashSashInfoText,
  link,
  offerText,
  promotionSecondaryText,
  region,
  renderTermText,
  termsText,
}: TProductPromotionProps): JSX.Element => {
  const terms = renderTermText && termsText ? termsText : undefined;
  const adjustedRegion: 'UK' | 'ROI' | 'HU' | 'CZ' | 'SK' = region === 'IE' ? 'ROI' : region;

  const clubCard = (
    <ClubCardBar
      spaLink={(): JSX.Element => <Link to={link} onClick={clickHandler()} />}
      href={link}
      content={offerText}
      terms={terms}
      subtext={promotionSecondaryText}
      region={adjustedRegion.toLowerCase() as 'uk' | 'roi' | 'hu' | 'cz' | 'sk'}
    />
  );
  return (
    <StyledPromotionsWithClubcardPriceContainer>
      {clubcardFlashSashInfoText && clubCard}
    </StyledPromotionsWithClubcardPriceContainer>
  );
};

const ProductPromotion = ({
  clickHandler,
  link,
  offerText,
  termsText,
  renderTermText,
  clubcardFlashSashInfoText,
  isVerticalTile,
  promotionSecondaryText,
}: TProductPromotionProps): JSX.Element => (
  <Link to={link} onClick={clickHandler}>
    <ProductPromotionStyling
      isVerticalTile={isVerticalTile}
      isYellowClubcardPromotion={!!clubcardFlashSashInfoText}
      hasPromotionSecondaryText={!!promotionSecondaryText}
    >
      <span className="offer-text">{offerText}</span>
      {promotionSecondaryText && <span className="offer-secondary-text">{promotionSecondaryText}</span>}
      {termsText && renderTermText && <span className="dates">{termsText}</span>}
    </ProductPromotionStyling>
  </Link>
);

type PromotionProps = {
  key: number;
  children: React.ReactNode;
};

const Promotion = (props: PromotionProps): JSX.Element => <>{props.children}</>;

export const Promotions = (props: PromotionsProps): JSX.Element => {
  const {
    className,
    clickHandler,
    clubcardFlashSashInfoText,
    isVerticalTile,
    productDrsChargeAmount,
    promotions,
    region,
    renderTermText = true,
    showCCPriceLogoInSquare,
    showPromotionInfoBox = false,
    tileVariant,
    translate,
  } = props;

  const ProductPromotionComponent = clubcardFlashSashInfoText ? ProductPromotionWithClubcardPrice : ProductPromotion;

  return (
    <div className={className}>
      {promotions.map(
        (
          { link, text, terms, missedPromotion, promotionMessage, unitSellingInfo: pricePerUnit, isMarketPlaceProduct },
          index,
        ) => (
          <Promotion key={index}>
            {isMarketPlaceProduct ? (
              <OneTilePromotionComponent
                promotionsUrl={link}
                offerTerms={terms || ''}
                shouldShowClubcardPromotion={false}
                description={text}
                shouldShowValueBarPromotion={true}
              />
            ) : (
              <ProductPromotionComponent
                clickHandler={clickHandler}
                clubcardFlashSashInfoText={clubcardFlashSashInfoText}
                data-test="product-offers"
                isVerticalTile={isVerticalTile}
                link={link}
                offerText={text}
                promotionSecondaryText={getPromotionSecondaryText({
                  translate,
                  pricePerUnit,
                  productDrsChargeAmount,
                })}
                region={region}
                renderTermText={renderTermText}
                showCCPriceLogoInSquare={showCCPriceLogoInSquare}
                termsText={terms}
                tileVariant={tileVariant}
              />
            )}
            {showPromotionInfoBox && promotionMessage && (
              <StyledPromotionInfoBox missedPromotion={missedPromotion}>
                <Text as="p" size={FontSizes.small} inheritColor>
                  {promotionMessage}
                </Text>
              </StyledPromotionInfoBox>
            )}
          </Promotion>
        ),
      )}
    </div>
  );
};

export default Promotions;
