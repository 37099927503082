import { GRID, LIST } from '#/constants/favorites';
import { getFeatureConfigurationFromReqRes } from '#/lib/optimizely-manager';
import { variations, gridViewWithSubsAndBannersVariants } from '#/experiments/oop-2011/constants';
import { getDeviceTypeFromReq } from '#/lib/device-type-utils';
import { DESKTOP, MOBILE, TABLET } from '#/constants/platforms';

export const getGridListViewSubsVariation = (req: Request, res: Response): variations => {
  const { featureKey, variationKey } = gridViewWithSubsAndBannersVariants;
  const deviceType = getDeviceTypeFromReq(req);
  const additionalAttributes = {
    isDesktop: String(deviceType === DESKTOP),
    isMobile: String(deviceType === MOBILE),
    isTablet: String(deviceType === TABLET),
  };
  return getFeatureConfigurationFromReqRes(
    req,
    res,
    featureKey,
    gridViewWithSubsAndBannersVariants,
    additionalAttributes,
  )[variationKey];
};

export const isBannersSubsTooltip2 = (variant: variations): boolean => {
  return variant && variations.bannersSubsTooltip2 === variant;
};

export const isGridViewBannerTooltipEnabled = (variant: variations): boolean => {
  return variant && variant !== variations.default;
};

export const isGridViewSubsEnabled = (variant: variations): boolean => {
  return [variations.bannersSubsTooltip1, variations.bannersSubsTooltip2].includes(variant);
};

export const getDefaultFavLayout = (variant?: variations): string => {
  return variant && isGridViewBannerTooltipEnabled(variant) ? GRID : LIST;
};
