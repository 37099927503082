import { getFeatureVariableStringFromState } from '#/lib/optimizely-manager';
import { gridViewWithSubsAndBannersVariants, variations } from '#/experiments/oop-2011/constants';

export const getGridViewWithSubsAndBannersVariant = (state: Store): variations => {
  const { featureKey, variationKey, useCustomerId } = gridViewWithSubsAndBannersVariants;
  const featureVariable = getFeatureVariableStringFromState(state, featureKey, variationKey, {
    useCustomerId,
  });

  return featureVariable || variations.default;
};
