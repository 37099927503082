import { getAppRegion, getCurrentUrl, getLanguage } from '#/reducers/app';
import { PROMOTIONS, SEARCH, SHOP } from '#/constants/page-names';
import { UK } from '#/constants/common';

export const getIsFiveTileEnabledOnPage = (state: Store): boolean => {
  const currentUrl = getCurrentUrl(state);
  const region = getAppRegion(state);
  const pageCheckRegex = new RegExp(`${getLanguage(state)}\/((${SEARCH})|(${PROMOTIONS})|(${SHOP}))`, 'i');
  return region === UK && pageCheckRegex.test(currentUrl);
};
