export const slotsUIReskinV2Variants = {
  key: '2205',
  featureKey: 'ghs-all_slot-matrix-atf-reskin-v2',
  variationKey: 'variationKey',
  variants: {
    default: '2205-a',
    updateAccordionCopy: '2205-b',
    addNewAddressHeader: '2205-c',
    addNewAddressHeaderAndSimplify: '2205-d',
  },
  useCustomerId: true,
};

// analytics constant
export const HOW_WHOOSH_WORKS = 'how whoosh works';
