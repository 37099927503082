import { Dispatch, GetStore } from '#/custom-typings/redux-store/common';
import { ACCOUNT_PHONE_NUMBER } from '#/constants/spa-resource';
import { receiveAccountPhoneNumber } from '#/actions/user-action-creators';
import { getAccountPhoneNumberStateResource } from '#/selectors/resources';

export const type = ACCOUNT_PHONE_NUMBER;

export function handler(dispatch: Dispatch, getState: GetStore): void {
  const state = getState();
  const data = getAccountPhoneNumberStateResource(state)?.data;

  if (!data) {
    return;
  }

  dispatch(receiveAccountPhoneNumber(data));
}
