import { ComponentType, DisplayArea, PageTitleType, PageType, Points, PointsRdfType, Price, Segment } from '../types';
import { PlainObject } from '#/types';
import { COMPONENT_TYPES, QUICK_BASKET_COMPONENT_NAME, QUICK_BASKET_PAGE_TYPE } from '#/analytics/constants';
import { HAVE_YOU_FORGOTTEN } from '#/constants/tile-types';

export interface ComponentIdPayload {
  componentId?: string;
}

export function componentId(payload: ComponentIdPayload): string | undefined {
  return payload.componentId;
}

export interface ComponentNamePayload {
  componentName?: string;
}

export function getComponentName(payload: ComponentNamePayload): string | undefined {
  return payload.componentName;
}

export interface ComponentTypePayload {
  componentType?: ComponentType;
}

export function getComponentType(payload: ComponentTypePayload): ComponentType {
  return payload.componentType || 's';
}

export interface DisplayAreaPayload {
  displayArea?: DisplayArea;
}

export function displayArea(payload: DisplayAreaPayload): DisplayArea {
  return payload.displayArea || 'ukn';
}

export interface PageTypePayload {
  pageType?: PageTitleType | PageType;
}

export function getPageType(payload: PageTypePayload): PageType | undefined {
  const pageType = payload.pageType && payload.pageType.toLowerCase();

  switch (pageType) {
    case 'a':
    case 'aisle':
      return 'a';
    case 'bl':
    case 'buylist':
      return 'bl';
    case HAVE_YOU_FORGOTTEN:
    case 'c':
    case 'checkout':
      return 'c';
    case 'category':
    case 'd':
    case 'department':
      return 'd';
    case 'f':
    case 'favorites':
    case 'favorites-category':
    case 'favourites':
    case 'lists':
    case 'usuals':
      return 'f';
    case 'tf':
    case 'basket':
    case 'full-basket':
      return 'tf';
    case 'h':
    case 'home':
      return 'h';
    case 'o':
    case 'orders':
    case 'pending-order':
    case 'previous-order':
      return 'o';
    case 'p':
    case 'product-details':
    case 'product_details':
      return 'p';
    case 'promotions':
    case 'promotions-homepage':
    case 'so':
    case 'special-offers':
    case 'promotionsPLP':
      return 'so';
    case 'sr':
    case 'search':
    case 'searchplp':
      return 'sr';
    case 's':
    case 'shelf':
      return 's';
    case 'sd':
    case 'super-department':
      return 'sd';
    case 'z':
    case 'zone':
      return 'z';
    case QUICK_BASKET_COMPONENT_NAME:
      return QUICK_BASKET_PAGE_TYPE;
  }
}

export interface PointsPayload {
  points?: number;
}

export function points(payload: PointsPayload): Points {
  const { points = 0 } = payload;

  return {
    points,
    rdfType: 'Points' as PointsRdfType,
  };
}

export interface PricePayload {
  currency?: string;
  price?: number;
}

export function price(payload: PricePayload): Price | undefined {
  const { currency, price } = payload;

  if (!currency || typeof price === 'undefined') {
    return;
  }

  return {
    price,
    priceCurrency: currency,
  };
}

export function segment(payload: PlainObject): Segment[] {
  return Object.keys(payload).map(function(name) {
    const value = payload[name];

    return {
      segmentName: name,
      segmentValue: value,
    };
  });
}

export function getComponentNameByType(componentType?: ComponentType | string): string | undefined {
  if (componentType && componentType in COMPONENT_TYPES) {
    return COMPONENT_TYPES[componentType];
  }
}

export function getCampaignTitle(campaignTitle?: string): string | undefined {
  return campaignTitle?.toString().trim() || undefined;
}
