import moment from 'moment-timezone';
import analyticsBus from '#/analytics/analyticsBus';
import {
  ONDEMAND_DELIVERY,
  NOW,
  QUICK_BASKET_ANALYTICS_TYPE,
  START_SHOPPING,
  SHOP_FAVORITES,
  QUICK_BASKET_IMPRESSION
} from '#/analytics/constants';
import { basicEvent } from '#/analytics/types/basic';
import { ON_DEMAND } from '#/constants/shopping-methods';
import { getIsFirstTimeShopper } from '#/reducers/user';

export function bookedSlotPayload({
  start,
  end,
  charge,
  currency,
  method,
  timezone,
  locationId,
  deliverySaverCurrent,
  state
}) {
  const payload = {
    locationId,
    start:
      (start &&
        moment
          .utc(start)
          .tz(timezone)
          .format()) ||
      '',
    end:
      (end &&
        moment
          .utc(end)
          .tz(timezone)
          .format()) ||
      '',
    charge: {
      price: typeof charge === 'number' ? charge.toFixed(2) : undefined,
      priceCurrency: currency.symbol
    },
    method
  };
  if (typeof state !== 'undefined') {
    payload.state = state;
  }
  if (typeof deliverySaverCurrent !== 'undefined') {
    payload.deliverySaverCurrent = deliverySaverCurrent;
    payload.deliverySaverCurrent.saving = deliverySaverCurrent.saving.toFixed(
      2
    );
  }
  return payload;
}

export const ondemandStatusAnalytics = deliveryStatus => {
  if (global?.window?.sessionStorage) {
    const alreadyFired = window.sessionStorage.getItem(
      'hasOndemandStatusAnalyticsFired'
    );
    if (!alreadyFired) {
      analyticsBus().emit('UIEventBasicEvent', {
        type: ONDEMAND_DELIVERY,
        value: deliveryStatus,
        action: NOW
      });
      window.sessionStorage.setItem('hasOndemandStatusAnalyticsFired', 'true');
    }
  }
};

export const emitOnDemandAddressModalAnalytics = (action, value) =>
  basicEvent(analyticsBus, { action, type: ON_DEMAND, value });

export const emitQuickBasketClickUIEvent = value => {
  basicEvent(analyticsBus, {
    type: QUICK_BASKET_ANALYTICS_TYPE,
    value: value,
    action: NOW
  });
};

export const getQuickBasketModalImpression = state => {
  const isFirstTimeShopper = getIsFirstTimeShopper(state);

  let analyticsValue = START_SHOPPING;

  if (!isFirstTimeShopper) {
    analyticsValue += `|${SHOP_FAVORITES}`;
  }

  return `${analyticsValue}:${QUICK_BASKET_IMPRESSION}`;
};
