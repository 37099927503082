import { getFeatureVariableStringFromState, updateRegionInKey } from '#/lib/optimizely-manager';
import { MovingSearchBarFeature, MovingSearchBarVariants } from '#/experiments/oop-1972/constants';

export const getMovingSearchBarVariant = (state: Store): MovingSearchBarVariants => {
  const { featureKey, variationKey } = MovingSearchBarFeature;
  const regionInKey = updateRegionInKey(featureKey, state);
  const featureVariant = getFeatureVariableStringFromState(state, regionInKey, variationKey);

  return featureVariant || MovingSearchBarVariants.DEFAULT;
};
