import React from 'react';
import { connect } from '../../lib/render/connect-deep-compare';
import SimpleLayout from '../layouts/simple';

export default WrappedComponent =>
  connect(({ resources }) => ({ resources }))(
    ({ resources, ...otherProps }) => {
      if (resources.status) {
        return <SimpleLayout {...otherProps} />;
      }

      return <WrappedComponent {...otherProps} />;
    }
  );
