import styled from 'styled-components';
import { fontSize, spacing } from '@ddsweb/selectors';
import { media } from '@ddsweb/foundation';
import TabsMenu from '@ddsweb/tabs-menu';

export const StyledFulfilmentOptionsTabsMenu = styled(TabsMenu)`
  ~ .book-a-slot__section-container {
    margin-top: 0;
  }

  font-size: ${fontSize.xs};

  ${media.belowMobileLarge`
    ${media.aboveMobile`
      font-size: ${fontSize.xx};
    `};
    font-size: ${fontSize.xxx};
    margin-bottom: ${spacing.xx};
    
    li[role=presentation] {
      min-width: auto;
      flex: 1 0 auto;

      &:not(:first-child) {
        margin-left: ${spacing.xx};
      }
    }

    [role=tablist] {
      display: flex;
      align-items: flex-end;
    }

    [role=tab] {
      padding: 0;
    }
  `};
`;
