import { Items as ItemMap } from '#/custom-typings/redux-store/trolley.defs';
import { Item } from '#/lib/records/item';
import { getProductId } from '#/selectors/item';

export default function idInItems(id: string, items: ItemMap | Array<Item>): boolean {
  if (items instanceof Map) {
    return items.has(id);
  }

  return items.some(item => getProductId(item) === id);
}
