import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import helpers from '#/lib/decorators/helpers';
import { convertValueTileItems } from '#/lib/dcs/adapter/valueTileAdapter';
import { connect } from '#/lib/render/connect-deep-compare';
import { getObserver } from '#/lib/browser/intersection-observer';
import { ResponsiveBackground } from '../responsive-background';
import { EventButton } from '../event-button';
import { ValueTile } from '../value-tile';
import Link from '../../link-check-spa';
import { getCurrency, getCutsMustard } from '#/reducers/app';
import { withErrorBoundary } from '#/components/shared/error-boundary';
import { CMS_ERROR_PREFIX } from '#/constants/dcs';

const mapStateToProps = state => ({
  currency: getCurrency(state),
  cutsMustard: getCutsMustard(state)
});

withErrorBoundary(CMS_ERROR_PREFIX, 'SimpleBanner');
@helpers(['asset', 't', 'c'])
@connect(mapStateToProps)
export class SimpleBanner extends React.PureComponent {
  constructor(props) {
    super(props);

    this.bannerContentRef = React.createRef();

    this.state = {
      isVisible: !props.cutsMustard
    };
  }

  static propTypes = {
    asset: PropTypes.func.isRequired,
    background: PropTypes.shape({
      breakpoints: PropTypes.array,
      clipping: PropTypes.oneOf(['left', 'center', 'right']),
      maxWidth: PropTypes.number,
      src: PropTypes.string,
      texturedImageUrl: PropTypes.string
    }),
    c: PropTypes.func.isRequired,
    componentClickHandler: PropTypes.func,
    currency: PropTypes.object.isRequired,
    cutsMustard: PropTypes.bool.isRequired,
    description: PropTypes.string,
    eventBorder: PropTypes.object,
    eventStyle: PropTypes.object,
    eventTerms: PropTypes.string,
    eventTitleColor: PropTypes.string,
    link: PropTypes.shape({
      label: PropTypes.string,
      href: PropTypes.string,
      style: PropTypes.object
    }),
    placement: PropTypes.number,
    t: PropTypes.func,
    title: PropTypes.string,
    valueTile: PropTypes.object
  };

  componentDidMount() {
    if (!this.state.isVisible) {
      this.observer = getObserver(this.intersection);
      this.observe();
    }
  }

  componentWillUnmount() {
    if (this.observer) {
      this.observer.disconnect();
      this.observer = null;
    }
  }

  load() {
    this.setState({
      isVisible: true
    });
  }

  intersection = elements => {
    // Are we in viewport?
    if (this.observer && elements[0].intersectionRatio > 0) {
      // Stop watching and load content
      this.observer.disconnect();
      this.load();
    }
  };

  observe() {
    if (this.observer) {
      this.observer.observe(this.bannerContentRef.current);
    } else {
      this.load();
    }
  }

  render() {
    const props = this.props;
    const {
      asset,
      currency: { symbol, unitSymbol },
      eventBorder,
      title,
      description,
      link,
      eventTerms,
      eventStyle,
      background,
      eventTitleColor,
      placement,
      componentClickHandler,
      valueTile,
      t,
      c
    } = props;

    const onClick = () => componentClickHandler?.(props);
    const bannerClasses = classnames('ui-simple-banner', {
      banner__eventBorder: eventBorder && eventBorder.borderColor
    });

    const returnOfferFlag = () => {
      const region = c('REGION');
      if (valueTile && valueTile.template_type) {
        return (
          <ValueTile
            {...convertValueTileItems(valueTile, symbol, unitSymbol, region)}
            offerDetailHiddenText={t('promotions:offer-details')}
            termsApplyHiddenText={t('promotions:terms-apply')}
          />
        );
      }

      return null;
    };

    const bannerContentStyles = {
      backgroundImage:
        background && background.texturedImageUrl && this.state.isVisible
          ? `url("${asset(background.texturedImageUrl)}")`
          : null
    };

    return (
      <div className={bannerClasses} onClick={onClick}>
        <Link
          className="simple-banner-slide"
          style={eventStyle}
          to={link && link.href}
        >
          <ResponsiveBackground
            className="simple-banner-stamp"
            url={background && background.src}
            breakpoints={background && background.breakpoints}
            maxWidth={(background && background.maxWidth) || 750}
            clipping={background && background.clipping}
            uid={placement}
            alt=" "
          />
          <div className="simple-banner-ping">{returnOfferFlag()}</div>
          <div
            ref={this.bannerContentRef}
            className="simple-banner-wrap"
            style={bannerContentStyles}
          >
            <div className="simple-banner-content clear">
              <h3 className="event-title" style={{ color: eventTitleColor }}>
                {title}
              </h3>
              <p className="event-description">{description}</p>
              <EventButton
                link={link}
                id={placement}
                button={{ noLink: true }}
              />
              {eventTerms && <p className="event-terms">{eventTerms}</p>}
            </div>
          </div>
        </Link>
        {eventBorder && eventBorder.borderColor && (
          <div className="ui-simple-banner--border" style={eventBorder} />
        )}
      </div>
    );
  }
}
