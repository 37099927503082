export const fixedMessageTileTemplate = {
  new: {
    valueContent: [
      {
        text: 'New',
        size: 'xl'
      }
    ],
    mode: 'new'
  },
  free: {
    valueContent: [
      {
        text: 'Free',
        size: 'lg'
      }
    ]
  },
  half_price: {
    valueContent: [
      {
        text: 'Half',
        size: 'lg'
      },
      {
        text: 'Price',
        size: 'lg'
      }
    ]
  },
  meal_deal: {
    valueContent: [
      {
        text: 'Meal',
        size: 'lg'
      },
      {
        text: 'Deal',
        size: 'lg'
      }
    ]
  },
  better_than_half_price: {
    valueContent: [
      {
        text: 'Better than',
        size: 'xxx'
      },
      {
        text: 'Half',
        size: 'lg'
      },
      {
        text: 'Price',
        size: 'lg'
      }
    ]
  },
  up_to_half_price: {
    valueContent: [
      {
        text: 'Up to',
        size: 'xxx'
      },
      {
        text: 'Half',
        size: 'lg'
      },
      {
        text: 'Price',
        size: 'lg'
      }
    ]
  },
  clubcard_price: {
    valueContent: [
      {
        text: 'Clubcard',
        size: 'xx'
      },
      {
        text: 'Price',
        size: 'xx'
      }
    ],
    mode: 'clubcard'
  }
};
