import React from 'react';
import helpers from '#/lib/decorators/helpers';
import { StyledContextCardText, DeliverySectionDetails } from './styled';
import { TTranslateFn } from './index.defs';
type TCapacityReachedContextCard = {
  t: TTranslateFn;
};

const CapacityReachedContextCard = helpers(['t'])(
  (props: TCapacityReachedContextCard): JSX.Element => {
    const { t: translate } = props;
    return (
      <DeliverySectionDetails>
        <StyledContextCardText>{translate('common:no-open-order.no-slot-available-title')}</StyledContextCardText>
        <StyledContextCardText>{translate('common:no-open-order.no-slot-available-detail-1')}</StyledContextCardText>
        <StyledContextCardText>{translate('common:no-open-order.no-slot-available-detail-2')}</StyledContextCardText>
      </DeliverySectionDetails>
    );
  },
);

export default CapacityReachedContextCard;
