import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Chip, Chipset } from '@ddsweb/chips';
import { BaseElement, scrollableHorizontal } from '@ddsweb/foundation';
import Link from '#/components/link-check-spa';
import { updateParamsInUrl, parseQueryString } from '#/lib/url/url-utils';
import analyticsBus from '#/analytics/analyticsBus';

export const StyledChip = styled(Chip)`
  background-color: ${props =>
    props.selected ? 'var(--ddsweb-chip-background)' : 'rgb(229, 229, 229)'};
  border-radius: 24px;
  border: 0px;
  padding: 10px;
  height: 32px;

  .ddsweb-button__icon-container {
    background-color: ${props =>
      props.selected
        ? 'var(--ddsweb-chip-icon-background)'
        : 'var(--ddsweb-chip-icon-color)'};
    color: ${props =>
      props.selected
        ? 'var(--ddsweb-chip-icon-color)'
        : 'var(--ddsweb-chip-icon-background)'};
  }
`;

export default class PopFilters extends PureComponent {
  static propTypes = {
    currentUrl: PropTypes.string.isRequired,
    filterByAccessibilityText: PropTypes.string.isRequired,
    popFilters: PropTypes.shape({
      category: PropTypes.string,
      facets: PropTypes.array
    }).isRequired,
    popularFiltersAccessibilityText: PropTypes.string.isRequired,
    popularFiltersText: PropTypes.string.isRequired
  };

  handleAnalytics = (facetName, position) => () => {
    if (!facetName) return;
    analyticsBus().emit('UIEventBasicEvent', {
      type: 'popular filter',
      value: `${facetName}:${position}`,
      action: 'delay'
    });
  };

  renderChips() {
    const popFilterFacets = this.props.popFilters.facets;
    const currentUrl = this.props.currentUrl;
    const urlParams = parseQueryString(currentUrl);
    const selectedPopFilter = popFilterFacets.find(
      popFilter => popFilter.isSelected
    );

    const chipSetStyle = { flexWrap: 'nowrap' };

    if (selectedPopFilter) {
      const newUrlParams = {
        ...urlParams,
        popFilter: null,
        department: null,
        aisle: null,
        shelf: null,
        page: null,
        viewAll: null
      };
      return (
        <Chipset styles={chipSetStyle}>
          <Link to={updateParamsInUrl(currentUrl, newUrlParams)}>
            <span className="visually-hidden">
              {this.props.popularFiltersAccessibilityText}
            </span>
            <StyledChip id="pop-filter-chip" selected={true}>
              {selectedPopFilter.facetName}
            </StyledChip>
          </Link>
        </Chipset>
      );
    }

    return (
      <Chipset styles={chipSetStyle}>
        {popFilterFacets.map((popFilter, index) => {
          const newUrlParams = {
            ...urlParams,
            popFilter: popFilter.facetId,
            page: null
          };

          return (
            <Link
              to={updateParamsInUrl(currentUrl, newUrlParams)}
              onClick={this.handleAnalytics(popFilter.facetName, index)}
              key={`pop-filter-chip-link--${index}`}
            >
              <span className="visually-hidden">
                {this.props.popularFiltersAccessibilityText}
              </span>
              <StyledChip
                id={`pop-filter-chip--${index}`}
                key={`pop-filter-chip--${index}`}
                index={index}
                facetName={popFilter.facetName}
              >
                {popFilter.facetName}
              </StyledChip>
            </Link>
          );
        })}
      </Chipset>
    );
  }

  render() {
    const ScrollableChipsetContainer = styled(BaseElement)`
      ${scrollableHorizontal};
    `;

    return (
      <div className="pop-filters">
        <h2 className="pop-filters__heading">
          <span className="visually-hidden">
            {this.props.filterByAccessibilityText}
          </span>
          {this.props.popularFiltersText}
        </h2>
        <div className="pop-filters__chip-set">
          <ScrollableChipsetContainer>
            {this.renderChips()}
          </ScrollableChipsetContainer>
        </div>
      </div>
    );
  }
}
