import styled from 'styled-components';
import Modal from '@ddsweb/modal';

export const StyledModal = styled(Modal)`
  & .ddsweb-modal__modal-container {
    height: unset;
    max-height: unset;
  }
  z-index: 3003;
`;
