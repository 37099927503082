const UPDATE_BASKET = `mutation UpdateBasket(
  $includeCoupons: Boolean!
  $includeSubstitutions: Boolean!
  $items: [BasketLineItemInputType]
  $orderId: ID
  $includeDeliveryAddress: Boolean! = true
  $includeDeliveryPreferences: Boolean!
  $includeCustomerPreferences: Boolean!
  $includeSubscription: Boolean!
  $substitutionsContext: SubstitutionsContextType
) {
  basket(
    items: $items
    orderId: $orderId
    substitutionsContext: $substitutionsContext
  ) {
  ...Basket
    updates {
      items {
        id
        issues {
          level
          reason
          message
        }
        isAlcoholic
        successful
      }
    }
  }
}`;

export default UPDATE_BASKET;
