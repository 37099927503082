import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Link from '#/components/link';
import helpers from '#/lib/decorators/helpers';

@helpers(['l'])
export default class MobileMapLink extends Component {
  static propTypes = {
    chevron: PropTypes.bool,
    l: PropTypes.func.isRequired,
    querystring: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
  };

  render() {
    const { querystring, text, chevron } = this.props;

    return (
      <div className="mobile-map-link" key="show-list-button">
        <Link to={this.props.l(`/slots/collection/stores?${querystring}`)}>
          {text}
          {chevron && <span className="icon icon-chevron_right" />}
        </Link>
      </div>
    );
  }
}
