import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { compose } from 'react-recompose';
import Map from '#/components/map';
import LocationList from '#/components/map/location-list';
import { connect } from '#/lib/render/connect-deep-compare';
import {
  updateParamsInUrl,
  updatePageUrlWithoutRefresh
} from '#/lib/url/url-utils';
import { getSelectedLocation, getSelectedLocationId } from '#/reducers/slot';

import { getCurrentUrl } from '#/reducers/app';
import { selectLocationId } from '#/actions/location-action-creators';
import { LIST_SELECT, MAP_SELECT } from '#/constants/analytics';
import { getLocations } from '#/reducers/location';

const mapStateToProps = state => ({
  currentUrl: getCurrentUrl(state),
  storeLocations: getLocations(state),
  selectedLocation: getSelectedLocation(state),
  selectedLocationId: getSelectedLocationId(state)
});

const mapDispatchToProps = {
  selectLocationId
};

const enhance = compose(connect(mapStateToProps, mapDispatchToProps));

const ChooseALocation = ({
  currentUrl,
  selectedDate,
  selectedLocation,
  selectedLocationId,
  selectLocationId,
  storeLocations
}) => {
  const [addressSelectType, setAddressSelectType] = useState(null);

  const updateLocation = useCallback((event, locationId) => {
    const newUrl = updateParamsInUrl(currentUrl, { locationId });
    selectLocationId(locationId);
    updatePageUrlWithoutRefresh(window, newUrl);

    event.preventDefault();
  }, []);

  const onLocationClick = useCallback((event, locationId) => {
    setAddressSelectType(LIST_SELECT);
    updateLocation(event, locationId);
  }, []);

  const onMapClick = useCallback((event, locationId) => {
    setAddressSelectType(MAP_SELECT);
    updateLocation(event, locationId);
  }, []);

  const { latitude, longitude } = selectedLocation;

  return (
    <div className="choose-a-location">
      <Map
        center={{ latitude, longitude }}
        points={storeLocations}
        selectedPoint={selectedLocationId}
        onPointClick={onMapClick}
      />
      <LocationList
        addressSelectType={addressSelectType}
        locations={storeLocations}
        selectedDate={selectedDate}
        selectedLocation={selectedLocation}
        onLocationClick={onLocationClick}
      />
    </div>
  );
};

ChooseALocation.displayName = 'ChooseALocation';

ChooseALocation.propTypes = {
  currentUrl: PropTypes.string.isRequired,
  selectedDate: PropTypes.string,
  selectedLocation: PropTypes.object.isRequired,
  selectedLocationId: PropTypes.string.isRequired,
  selectLocationId: PropTypes.func.isRequired,
  storeLocations: PropTypes.array.isRequired
};

export default enhance(ChooseALocation);
