import React from 'react';
import PropTypes from 'prop-types';
import ForeseeEmailRequest from './email-request';
import ForeseeBackgroundTab from './background-tab';
import { closeModal } from '../../../actions/ui-action-creators';
import { connect } from '../../../lib/render/connect-deep-compare';

const ForeseeModal = props =>
  props.modalData.inviteType === 'EMAIL' ? (
    <ForeseeEmailRequest closeModal={props.closeModal} {...props.modalData} />
  ) : (
    <ForeseeBackgroundTab closeModal={props.closeModal} {...props.modalData} />
  );

ForeseeModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  modalData: PropTypes.object.isRequired
};

export default connect(null, { closeModal })(ForeseeModal);
