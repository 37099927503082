import { Slot } from '#/lib/records/slot.defs';

export function isBooked(slotRecord: Slot) {
  return slotRecord.status === 'Reserved' || slotRecord.status === 'Booked';
}

export function isExpired(slotRecord: Slot) {
  return slotRecord.status === 'Expired';
}

export function isValid(slotRecord: Slot) {
  return slotRecord.status != null && !isExpired(slotRecord);
}

export function isAvailable(slotRecord: Slot) {
  return slotRecord.status === 'Available';
}

export function isUnAvailable(slotRecord: Slot) {
  return slotRecord.status === 'UnAvailable';
}

export function updateReservationExpiry(slotRecord: Slot, newTime: string) {
  return { ...slotRecord, reservationExpiry: newTime };
}
