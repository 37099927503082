/**
 * Submit button with alternate state
 * Displays alternative text and a spinner animation when submission is triggered
 *
 * Easy to setup when combined with SafeForm
 *
 * Params:
 *   buttonStyles  - string containing array of CSS classes that will be applied to the submit button
 *   name          - optional string, acts as an alternative to using the button as an input (button does not provide
 *                   input value when disabled)
 *   onClick       - optional callback triggered by submit button
 *   onSubmit      - optional callback triggered by submit button
 *   primaryText   - base caption for the submit button
 *   secondaryText - optional caption on the button when submission is in progress
 *   submitting    - bool, indicating is the submission is in progress
 *   value         - optional string, provides a value for the optional `name` input
 */

import React from 'react';
import PropTypes from 'prop-types';
import Spinner from '../spinner';
import classnames from 'classnames';
import Button from '@ddsweb/button';

const SmartSubmitButton = props => {
  const buttonText =
    props.submitting && props.secondaryText
      ? props.secondaryText
      : props.primaryText;

  return (
    <div className={classnames('smart-submit-button', props.className)}>
      {props.name && (
        <input name={props.name} type="hidden" value={props.value} />
      )}
      {props.ddl ? (
        <Button
          fullWidth
          onClick={props.onClick}
          disabled={props.submitting || props.disabled}
          type="submit"
          ariaLabel={props.accessibleText}
          data-auto={props.dataAuto}
          icon={<Spinner showSpinner={props.submitting && !props.disabled} />}
        >
          {buttonText}
        </Button>
      ) : (
        <button
          className={props.buttonStyles}
          data-auto={props.dataAuto}
          disabled={props.submitting || props.disabled}
          onClick={props.onClick}
          type="submit"
        >
          <span aria-hidden={!!props.accessibleText}>{buttonText}</span>
          {props.accessibleText && (
            <span className="visually-hidden">{props.accessibleText}</span>
          )}
        </button>
      )}
      {!props.ddl && props.enableSpinner && (
        <Spinner showSpinner={props.submitting && !props.disabled} />
      )}
    </div>
  );
};

SmartSubmitButton.propTypes = {
  accessibleText: PropTypes.string,
  buttonOnClickEvt: PropTypes.func,
  buttonStyles: PropTypes.string,
  className: PropTypes.string,
  dataAuto: PropTypes.string,
  ddl: PropTypes.bool,
  disabled: PropTypes.bool,
  enableSpinner: PropTypes.bool,
  name: PropTypes.string,
  onClick: PropTypes.func,
  primaryText: PropTypes.string.isRequired,
  secondaryText: PropTypes.node,
  submitting: PropTypes.bool,
  value: PropTypes.string
};

SmartSubmitButton.defaultProps = {
  submitting: false,
  enableSpinner: true,
  buttonStyles: 'button button-primary',
  disabled: false
};

export default SmartSubmitButton;
