import React, { Component } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BackgroundPanel } from '@ddsweb/foundation';
import { Text } from '@ddsweb/text';
import Link from '#/components/link-check-spa';
import { colors, base, spacing } from '@ddsweb/selectors';
import * as facets from '#/constants/facet-categories';
import { connect } from '#/lib/render/connect-deep-compare';
import page from 'page';
import { parse as parseUrl } from 'url';
import { getBaseRoute } from '#/utils/render-utils';
import { filter } from '#/actions/filter-action-creators';
import {
  getFacets,
  getPopFilters,
  getCurrentFilters,
  getFilters
} from '#/reducers/filter';
import { getCurrentUrl, getLanguage, getBasePath } from '#/reducers/app';
import { getTotalCount } from '#/reducers/results';
import pageLoadedAnalyticsEvent from '#/analytics/types/page';
import helpers from '#/lib/decorators/helpers';
import FilterBy from '#/components/sort-n-filter/filter-by';
import FilterByMobile from '#/components/sort-n-filter/filter-by-mobile';
import PopFilters from '#/components/sort-n-filter/pop-filters';
import { parseQueryString } from '#/lib/url/url-utils';
import { extractFacetList as extractFacetListHelper } from '#/lib/filter-helpers';
import setBrowserTransactionName from '#/lib/apm/set-browser-transaction-name';

const StyledBackgroundPanel = styled(BackgroundPanel)`
  && {
    background-color: ${colors.primary};
    padding: ${spacing.md} ${spacing.sm}};
    border: ${base.outline};
    margin-bottom: ${spacing.xs};
  }
`;
StyledBackgroundPanel.displayName = 'VeganMessagePanel';

const StyledBodyText = styled(Text)`
  && {
    color: ${colors.inverse};
    -webkit-font-smoothing: antialiased;
  }
`;
StyledBodyText.displayName = 'VeganMessageText';

const mapStateToProps = state => {
  return {
    basePath: getBasePath(state),
    currentUrl: getCurrentUrl(state),
    filterStoreFacets: getFacets(state),
    filterStoreCurrentFilters: getCurrentFilters(state),
    filterStoreFilters: getFilters(state),
    language: getLanguage(state),
    popFilters: getPopFilters(state),
    totalCount: getTotalCount(state)
  };
};

@helpers(['c', 't'])
@connect(mapStateToProps, { filter })
@withRouter
export default class ProductFilter extends Component {
  static subscriptions = {};

  static propTypes = {
    basePath: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.array.isRequired,
    c: PropTypes.func.isRequired,
    currentUrl: PropTypes.string.isRequired,
    facetLists: PropTypes.array,
    filter: PropTypes.func.isRequired,
    filterStoreCurrentFilters: PropTypes.object.isRequired,
    filterStoreFacets: PropTypes.array.isRequired,
    filterStoreFilters: PropTypes.arrayOf(PropTypes.string).isRequired,
    hideFiltersOverlay: PropTypes.func.isRequired,
    isStickyFilterVariant: PropTypes.bool,
    language: PropTypes.string.isRequired,
    params: PropTypes.objectOf(PropTypes.any).isRequired,
    popFilters: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
      .isRequired,
    routes: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
    showAvailabilityAsDropdown: PropTypes.bool,
    showBrandsFilter: PropTypes.bool,
    showCategoriesFilter: PropTypes.bool,
    showCount: PropTypes.bool,
    showDietaryFilter: PropTypes.bool,
    showOfferAsDropdown: PropTypes.bool,
    showPromotionsFilter: PropTypes.bool,
    showSuperDepartmentFilter: PropTypes.bool,
    sortOptions: PropTypes.array,
    totalCount: PropTypes.number
  };

  static defaultProps = {
    isStickyFilterVariant: false,
    showCategoriesFilter: false,
    showCount: true,
    showPromotionsFilter: false,
    showSuperDepartmentFilter: false,
    showAvailabilityAsDropdown: false,
    showOfferAsDropdown: false
  };

  componentDidMount() {
    const { basePath, language, currentUrl } = this.props;
    const baseRoute = getBaseRoute(basePath, language);
    const path = window.location.pathname.substring(
      window.location.pathname.length,
      baseRoute.length
    );

    this.route = `${baseRoute}${path}`;

    // ProductFilter sometimes gets unmounted (e.g. change Sort Order in Browse), so we need to track
    // which page() subscriptions we make so that we can remove them when the component gets unmounted,
    // otherwise old-unmounted components continue to fire a) analytics, and b) change-filter events (
    // with the wrong filters).
    //
    // We need to group the subscriptions by page URL, as SPA transitions between categories in browse
    // can cause a new ProductFilter component to be mounted, but under a new route.
    if (!ProductFilter.subscriptions.hasOwnProperty(this.route)) {
      const routeSubscriptions = (ProductFilter.subscriptions[this.route] = []);

      // so page.js can cope correctly with the back button past the point at which called page.start()
      window.history &&
        window.history.replaceState(
          {
            path: currentUrl
          },
          '',
          currentUrl
        );

      page(this.route, (ctx, next) => {
        let i = 0;

        function processNext() {
          if (i < routeSubscriptions.length) {
            routeSubscriptions[i++](ctx, processNext);
          } else {
            next();
          }
        }

        processNext();
      });
      page.start({ popstate: false, dispatch: false });
    }

    ProductFilter.subscriptions[this.route].push(this.handlePageChange);
  }

  componentWillUnmount() {
    const routeSubscriptions = ProductFilter.subscriptions[this.route];

    routeSubscriptions.splice(
      routeSubscriptions.indexOf(this.handlePageChange),
      1
    );
  }

  handlePageChange = (ctx, next) => {
    const {
      filter,
      params,
      routes,
      c: config,
      language,
      breadcrumbs
    } = this.props;
    const changedFilter = this.getChangedFilter(ctx.path);

    setBrowserTransactionName(ctx.path);

    if (changedFilter) {
      pageLoadedAnalyticsEvent({
        pageLanguage: language,
        pageTitle: document.title,
        pageURL: window.location.href,
        region: document.body.getAttribute('data-region'),
        breadcrumbs
      });

      const { productSource, ...rest } = parseQueryString(ctx.path);
      if (productSource) {
        rest.filterCriteria = [
          {
            name: facets.PRODUCT_SOURCE,
            values: [productSource]
          }
        ];
      }

      filter(
        { params: params, routes: routes },
        { path: ctx.path, queryParams: rest },
        config('facetsAllowingMultipleSelections'),
        config('categoryDelimiter'),
        changedFilter
      );

      this.hideFilter();
    } else {
      next();
    }
  };

  getChangedFilter(queries) {
    const { currentUrl, filterStoreFilters } = this.props;
    const oldQuery = parseUrl(queries, true).query;
    const newQuery = parseUrl(currentUrl, true).query;
    const filters = filterStoreFilters;

    return filters.find(filter => oldQuery[filter] !== newQuery[filter]);
  }

  hideFilter() {
    return this.props.hideFiltersOverlay();
  }

  extractFacetList = (names, label) => {
    const { filterStoreFacets } = this.props;

    return extractFacetListHelper(names, label, filterStoreFacets);
  };

  isVegan({ facetId, isSelected }) {
    return facetId === 'Vegan' && isSelected;
  }

  isVeganFilterSelected() {
    const { filterStoreCurrentFilters } = this.props;

    return !!(
      filterStoreCurrentFilters.dietary &&
      this.extractFacetList([facets.DIETARY])?.facets.some(this.isVegan)
    );
  }

  renderVeganMessage = () => {
    const { c: config, language, t: translate } = this.props;

    return (
      this.isVeganFilterSelected() &&
      config('showVeganMessaging') && (
        <StyledBackgroundPanel className="vegan_message">
          <StyledBodyText>
            {translate('sort-and-filter:vegan-filter-message')}
            <Link
              className="link--chevron"
              to={config(`links:termsConditionsDietary:${language}`)}
              target="_blank"
            >
              {translate('sort-and-filter:read-more')}
              <span className="icon icon-chevron_right-white" />
            </Link>
          </StyledBodyText>
        </StyledBackgroundPanel>
      )
    );
  };

  render() {
    const {
      currentUrl,
      filterStoreFacets,
      isStickyFilterVariant,
      popFilters,
      totalCount,
      showPromotionsFilter,
      showCategoriesFilter,
      showCount,
      showSuperDepartmentFilter,
      showBrandsFilter,
      showDietaryFilter,
      filterStoreCurrentFilters,
      sortOptions,
      showAvailabilityAsDropdown,
      showOfferAsDropdown,
      t: translate
    } = this.props;

    return (
      <div className="product-filter fancy-filter">
        {popFilters && popFilters.facets && popFilters.facets.length > 0 && (
          <PopFilters
            popFilters={popFilters}
            currentUrl={currentUrl}
            filterByAccessibilityText={translate('sort-and-filter:filter-by')}
            popularFiltersAccessibilityText={translate(
              'search:popular-filters-accessibility'
            )}
            popularFiltersText={translate('search:popular-filters')}
          />
        )}
        <div className="sort-and-filter">
          <FilterBy
            facets={filterStoreFacets}
            count={totalCount}
            showPromotionsFilter={showPromotionsFilter}
            showCategoriesFilter={showCategoriesFilter}
            showCount={showCount}
            showAvailabilityAsDropdown={showAvailabilityAsDropdown}
            showOfferAsDropdown={showOfferAsDropdown}
            isStickyFilterVariant={isStickyFilterVariant}
            showSuperDepartmentFilter={showSuperDepartmentFilter}
            showBrandsFilter={showBrandsFilter}
            showDietaryFilter={showDietaryFilter}
            currentFilters={filterStoreCurrentFilters}
            sortOptions={sortOptions}
            renderVeganMessage={this.renderVeganMessage}
            extractFacetList={this.extractFacetList}
          />

          <FilterByMobile count={totalCount} />

          {this.renderVeganMessage()}
        </div>
      </div>
    );
  }
}
