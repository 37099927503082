/**********************************
 *   Price Component
 *   This component takes in a value that is to be returned as a 'price' i.e. with symbols, decimal points, rounded
 *   e.g. '5000' could be returned as '£ 5,000.00'
 **********************************/

import React from 'react';
import PropTypes from 'prop-types';
import formatNumber from '#/lib/string-formatting/number-formatter';
import helpers from '#/lib/decorators/helpers';

@helpers(['c'])
export default class Price extends React.Component {
  static propTypes = {
    ariaHidden: PropTypes.bool,
    c: PropTypes.func.isRequired,
    className: PropTypes.string,
    compactMode: PropTypes.bool,
    decimalMark: PropTypes.string,
    decimalPlaces: PropTypes.number,
    enableMaxLimit: PropTypes.bool,
    maxLimit: PropTypes.number,
    symbol: PropTypes.string,
    symbolPosition: PropTypes.string,
    thousandsSeparator: PropTypes.string,
    truncateWholeValues: PropTypes.bool,
    value: PropTypes.number.isRequired
  };

  static defaultProps = {
    ariaHidden: false,
    compactMode: false,
    truncateWholeValues: false
  };

  render() {
    const value = formatNumber(Math.abs(this.props.value), this.props);
    const currencySpan = (
      <span className="currency" key={1}>
        {this.props.symbol || this.props.c('currency:symbol')}
      </span>
    );
    const spaceSpan = !this.props.compactMode ? (
      // eslint-disable-next-line prettier/prettier
      <span key={3}>{' '}</span>
    ) : null;
    const valueSpan = (
      <span data-auto="price-value" className="value" key={4}>
        {value}
      </span>
    );

    //right symbol
    let orderedSpans = [valueSpan, spaceSpan, currencySpan];

    if (this.props.symbolPosition === 'left') {
      //left symbol
      orderedSpans = [currencySpan, spaceSpan, valueSpan];
    }

    return (
      <span
        className={this.props.className}
        aria-hidden={this.props.ariaHidden}
      >
        {this.props.value < 0 ? (
          <span className="sign" key={5}>
            {'−'}
          </span>
        ) : null}
        {orderedSpans}
      </span>
    );
  }
}
