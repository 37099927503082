import styled, { css } from 'styled-components';

import Notification from '@ddsweb/notification';
import { colors, fontSize, spacing } from '@ddsweb/selectors';
import Text from '@ddsweb/text';
import { media } from '@ddsweb/foundation';

export const LinkContainer = styled.div`
  border-top: 1px solid ${colors.lines};
  padding: ${spacing.xx} 0px;
  margin-bottom: ${spacing.xx};

  a {
    font-family: 'TESCO Modern', Arial, sans-serif;
    font-weight: bold;
    padding: 0px ${spacing.sm};

    ${media.belowTablet` font-size: ${fontSize.xs};`};
  }

  .ddsweb-link__icon-container {
    vertical-align: middle;
    margin-bottom: 2px;
  }
`;

export const Content = styled.div`
  && {
    padding: ${spacing.xs} ${spacing.sm};
  }
`;

export const ContentRow = styled(Text)`
  && {
    color: ${colors.heading};
    font-size: ${fontSize.xx};

    &:first-child {
      margin-bottom: ${spacing.xx};
      font-weight: bold;
    }

    ${props =>
      props.error &&
      css`
        color: ${colors.error};
      `}

    ${props =>
      props.small &&
      css`
        font-size: ${fontSize.xxx};
      `}
  }
`;

export const HeaderText = styled(Text)`
  && {
    font-size: ${fontSize.xx};
    color: ${colors.black};
    font-weight: bold;
  }
`;

export const Header = styled(Notification)`
  && {
    padding: ${spacing.xx} ${spacing.xs};
  }
`;

export const icon = {
  graphic: 'forwardLink',
  position: { global: 'right' },
  size: 'xs',
};

export const TextSpan = styled.span`
  color: ${colors.text};
`;

export const CountdownTimerWrapper = styled.span`
  font-weight: bold;
  font-weight: normal;
`;
