import React, { Component } from 'react';
import PropTypes from 'prop-types';
import helpers from '#/lib/decorators/helpers';
import FormattedSlotTime from '../formatted-time/formattedSlotTime';

@helpers(['t'])
export default class SlotTime extends Component {
  static propTypes = {
    shoppingMethod: PropTypes.string,
    showStaticDeliveryTimeText: PropTypes.bool,
    slot: PropTypes.object.isRequired,
    t: PropTypes.func
  };
  render() {
    const {
      slot,
      showStaticDeliveryTimeText,
      t: translate,
      shoppingMethod
    } = this.props;

    return (
      <div className="slot-info">
        <div className="slot-time">
          {!!showStaticDeliveryTimeText ? (
            translate(
              `checkout:order-summary.${shoppingMethod}.static-delivery-time`
            )
          ) : (
            <FormattedSlotTime startTime={slot.start} endTime={slot.end} />
          )}
        </div>
      </div>
    );
  }
}
