import {
  RESTRICTED_ORDER_AMENDMENT,
  RESTRICTED_ORDER_AMENDMENT_WITH_ITEM
} from '#/constants/restrictions';
import {
  ALDI_PRICE_MATCH_MESSAGE,
  LOW_EVERYDAY_PRICING_MESSAGE,
  WARNING_MESSAGE_BACKGROUND_CLASS
} from '#/constants/product-tile';
import {
  combineRdgMessages,
  buildRdgRestrictionMessage,
  getRdgDateRestriction,
  getRdgTimeRestriction,
  getRdgUnavailableDateRestriction
} from '#/utils/restrictions-util';
import {
  getProductBulkBuyLimit,
  atMaxQty,
  hasRestrictedOrderAmendment,
  getRestrictedOrderAmendmentRestriction,
  getRestrictedOrderAmendmentMessage,
  isRdgViolation,
  getRdgRestrictions,
  getItemLimitReached,
  getGroupLimitReached,
  isVariableWeightProduct,
  atRestrictedMaxQty,
  hasGroupBulkBuyLimit
} from '#/selectors/item';

export const buildRestrictedOrderAmendmentMessage = (item, overrideMessage) => {
  const restrictedOrderAmendment = getRestrictedOrderAmendmentRestriction(item);

  const isRdgViolatedForRestrictedOrderAmendmentItemInBasket = () =>
    isRdgViolation(item) &&
    restrictedOrderAmendment.type === RESTRICTED_ORDER_AMENDMENT_WITH_ITEM;

  const shouldOverrideRestrictedOrderAmendmentMessage =
    restrictedOrderAmendment.type === RESTRICTED_ORDER_AMENDMENT ||
    isRdgViolatedForRestrictedOrderAmendmentItemInBasket();

  const overrideRestrictedOrderAmendmentMessage =
    shouldOverrideRestrictedOrderAmendmentMessage && overrideMessage;

  return {
    message:
      overrideRestrictedOrderAmendmentMessage ||
      getRestrictedOrderAmendmentMessage(item),
    backgroundColorClass: `${WARNING_MESSAGE_BACKGROUND_CLASS} restrictedOrderAmendment-message`
  };
};

export const getAllInfoMessages = (
  item,
  excludedInAmendBasket,
  excludeMessages,
  translate,
  slotsLink
) => {
  const bulkBuyLimitMessage = getBulkLimitMessage(item, translate);

  const variableWeightProductMessage = getVariableWeightMessage(
    item,
    excludeMessages,
    translate
  );

  const rdgMessage = getRdgMessage(item, excludeMessages);

  const showChangeYourSlot = rdgMessage && rdgMessage.showChangeSlot;

  const excludedProductMessage = getExcludedProductMessage(
    excludedInAmendBasket,
    atRestrictedMaxQty(item),
    showChangeYourSlot,
    translate
  );

  const messages = [
    variableWeightProductMessage,
    excludedProductMessage,
    bulkBuyLimitMessage,
    rdgMessage
  ].filter(message => message);

  const infoMessageProps = messages.length
    ? getMessagingProps(messages, showChangeYourSlot, translate, slotsLink)
    : null;

  return infoMessageProps;
};

export function getAldiPriceMatchMessageProps(
  isAldiPriceMatch,
  availableForSale
) {
  return isAldiPriceMatch && availableForSale
    ? { message: ALDI_PRICE_MATCH_MESSAGE, backgroundColorClass: null }
    : null;
}

export function getLowEverydayPricingMessageProps(
  isLowEverydayPricing,
  availableForSale
) {
  return isLowEverydayPricing && availableForSale
    ? { message: LOW_EVERYDAY_PRICING_MESSAGE, backgroundColorClass: null }
    : null;
}

export function getRestrictedOrderAmendmentMessageProps(
  item,
  availableForSale,
  props
) {
  const { restrictedOrderAmendmentOverrideMessage } = props;
  const shouldRenderRestrictedOrderAmendmentMessage =
    availableForSale && hasRestrictedOrderAmendment(item);

  const restrictedOrderAmendmentMessageProps =
    shouldRenderRestrictedOrderAmendmentMessage &&
    buildRestrictedOrderAmendmentMessage(
      item,
      restrictedOrderAmendmentOverrideMessage
    );

  return restrictedOrderAmendmentMessageProps
    ? restrictedOrderAmendmentMessageProps
    : null;
}

const getBulkLimitMessage = (item, translate) => {
  const showBulkLimitMessage =
    atMaxQty(item) && !hasRestrictedOrderAmendment(item);
  return showBulkLimitMessage && buildBulkBuyLimitMessage(item, translate);
};

const buildBulkBuyLimitMessage = (item, translate) => {
  const bulkBuyLimitReached = atMaxQty(item);
  const qty = getProductBulkBuyLimit(item);
  const groupQty = getProductBulkBuyLimit(item);
  const getMessage = () => {
    if (bulkBuyLimitReached && !hasGroupBulkBuyLimit(item)) {
      return translate('product-tile:item-limit-reached', { qty });
    }

    if (item.groupBulkBuyLimitReached || getGroupLimitReached(item)) {
      return translate('product-tile:group-limit-reached', {
        qty: groupQty
      });
    }

    if (getItemLimitReached(item)) {
      return translate('product-tile:item-group-limit-reached-item', {
        qty,
        groupQty
      });
    }
  };

  return {
    backgroundColorClass: WARNING_MESSAGE_BACKGROUND_CLASS,
    message: getMessage()
  };
};

const getRdgMessage = (item, excludeMessages) => {
  const restrictions = getRdgRestrictions(item);
  const hasRdgRestrictions = !!restrictions && restrictions.length > 0;

  if (hasRdgRestrictions) {
    const restrictionMessages = combineRdgMessages(
      buildRdgRestrictionMessage(getRdgTimeRestriction(restrictions)),
      buildRdgRestrictionMessage(getRdgDateRestriction(restrictions)),
      buildRdgRestrictionMessage(getRdgUnavailableDateRestriction(restrictions))
    );

    if (
      !excludeMessages.includes('blueRdgRestrictions') ||
      restrictionMessages.showChangeSlot
    ) {
      return restrictionMessages;
    }
  }

  return null;
};

const getVariableWeightMessage = (item, excludeMessages, translate) => {
  if (
    !excludeMessages.includes('variableWeightProduct') &&
    isVariableWeightProduct(item)
  ) {
    return {
      message: translate('product-tile:variable-weight-product-msg')
    };
  }

  return null;
};

const getExcludedProductMessage = (
  excludedInAmendBasket,
  itemAtRestrictedMaxQty,
  showChangeYourSlot,
  translate
) => {
  const isExcludedProductAtRestrictedMax =
    excludedInAmendBasket && itemAtRestrictedMaxQty;

  const showExcludedProductMessages =
    !showChangeYourSlot && isExcludedProductAtRestrictedMax;

  return showExcludedProductMessages && buildExcludedProductMessage(translate);
};

const buildExcludedProductMessage = translate => {
  return {
    message: translate('trolley:warnings.excluded-product-message'),
    backgroundColorClass: WARNING_MESSAGE_BACKGROUND_CLASS
  };
};

const getMessagingProps = (
  messages,
  showChangeYourSlot,
  translate,
  slotsLink
) => {
  let messagingProps = { messages };

  if (showChangeYourSlot) {
    const link = {
      text: translate('context-cards:change-slot'),
      href: slotsLink,
      icon: 'icon-chevron_right'
    };

    messagingProps = { ...messagingProps, link };
  }
  return messagingProps;
};
