import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ToggleControlLabel from './toggle-control-label';
import DisplayOnlyInJs from '../../shared/display-only-in-js';
import DisplayOnlyInNoJs from '../../shared/display-only-in-no-js';
import helpers from '../../../lib/decorators/helpers';

@helpers(['l'])
class UnitToggle extends React.PureComponent {
  static propTypes = {
    changeUnits: PropTypes.func.isRequired,
    checked: PropTypes.bool.isRequired,
    displayType: PropTypes.string,
    displayUnit: PropTypes.string,
    isLastToggle: PropTypes.bool,
    l: PropTypes.func
  };
  static defaultProps = {
    isLastToggle: false
  };

  render() {
    const {
      checked,
      changeUnits,
      displayType,
      displayUnit,
      isLastToggle
    } = this.props;
    const unitName = 'newUnitChoice';

    return (
      <div>
        <DisplayOnlyInJs HtmlTag="span">
          <ToggleControlLabel
            checked={checked}
            displayType={displayType}
            hasRightGutter={!isLastToggle}
          >
            <input
              className="controls--unit-toggle-js"
              name={unitName}
              onClick={changeUnits}
              defaultChecked={checked}
              type="radio"
              value={displayUnit}
            />
            <span
              className={classnames('controls--radio', {
                'icon-checkbox-checked': checked,
                'icon-checkbox-unchecked': !checked
              })}
            />
          </ToggleControlLabel>
        </DisplayOnlyInJs>
        <DisplayOnlyInNoJs
          HtmlTag="span"
          className="label controls--unit-label"
        >
          <ToggleControlLabel
            checked={checked}
            displayType={displayType}
            hasRightGutter={!isLastToggle}
          >
            <button
              className={classnames('controls--unit-toggle-non-js', {
                'controls--checkbox': true,
                'icon-checkbox-checked': checked,
                'icon-checkbox-unchecked': !checked
              })}
              name="unit"
              title={displayType}
              formAction={this.props.l('/products/change-unit')}
              type="submit"
              value={displayUnit}
            />
          </ToggleControlLabel>
        </DisplayOnlyInNoJs>
      </div>
    );
  }
}

export default UnitToggle;
