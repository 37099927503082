import React, { useState, SyntheticEvent, useEffect } from 'react';
import { compose } from 'react-recompose';
import { SubHeading } from '@beans/typography';
import Link from '#/components/link-check-spa';
import BeansLink from '@beans/link';
import helpers from '#/lib/decorators/helpers';
import { connect } from '#/lib/render/connect-deep-compare';
import { getCID } from '#/reducers/user';
import { FloatingTooltip, TooltipVariants, TooltipPositions } from '#/components/shared/tooltip';
import {
  TOOLTIP_WIDTH_DESKTOP,
  TOOLTIP_WIDTH_MOBILE,
  CLOSED,
  TooltipProps,
  CLOSE_X,
} from '#/components/shared/tooltip/available-filter';
import { TooltipStyleDesktop, TooltipStyleMobile, StyledBodyText, StyledButtonContainer, HideTooltip } from './styled';
import { getFavNavLinkTooltipConfig, Config } from '#/experiments/oop-1263/selectors';
import { TSpaLink } from '#/custom-typings/utils/components.defs';
import analyticsBus from '#/analytics/analyticsBus';
import { basicEvent } from '#/analytics/types/basic';
import { NOW, DELAY } from '#/analytics/constants';
import StringToNode from '#/components/shared/string-to-node';
import {
  getStorageKey,
  getTooltipStorage,
  setTooltipStorage as setTooltipStorageHelper,
} from '#/experiments/oop-1263/helpers';
import { isNavMenuOpen as getIsNavMenuOpen } from '#/reducers/ui';
import { TTranslateFunc } from '#/lib/records/helpers.defs';
import { getFavoritesLanguageLink } from '#/utils/favorite-utils';
import { analyticsKey, storageKey } from '#/experiments/oop-1263/constants';

const FAVORITES = 'favourites';
const IMPRESSION = 'impression';

type TOwnProps = TooltipProps & { t: TTranslateFunc };

type TOwnState = {
  customerId: string;
  heading: Config['heading'];
  body: Config['body'];
  buttonText: Config['buttonText'];
  favoritesLink: string;
  hideTooltip: boolean;
};

const sendTooltipEvent = (analyticsData: { action: string; value: string }): void => {
  basicEvent(analyticsBus, {
    type: 'homepage:tooltip',
    ...analyticsData,
  });
};

const mapStateToProps = (state: Store): TOwnState => {
  const { heading, body, buttonText } = getFavNavLinkTooltipConfig(state);

  return {
    customerId: getCID(state),
    heading,
    body,
    buttonText,
    favoritesLink: getFavoritesLanguageLink(state, '/favorites'),
    hideTooltip: getIsNavMenuOpen(state),
  };
};

const connector = connect(mapStateToProps, {});

type TProps = TOwnProps & TOwnState;

const FavNavLinkTooltipContainer = ({
  targetRef,
  id,
  customerId,
  tooltipRootID,
  boundingRef,
  portalRootID,
  observerId,
  mobileOnly = false,
  heading = '',
  body,
  buttonText,
  favoritesLink,
  hideTooltip,
  t: translate,
}: TProps): JSX.Element | null => {
  const tooltipWidth = mobileOnly ? TOOLTIP_WIDTH_MOBILE : TOOLTIP_WIDTH_DESKTOP;
  const styles = mobileOnly ? [TooltipStyleMobile] : [TooltipStyleDesktop];
  if (hideTooltip) {
    styles.push(HideTooltip);
  }
  const tooltipStorageKey = getStorageKey(customerId, storageKey);
  const tooltipRenderStorageKey = getStorageKey(customerId, analyticsKey);
  const [isOpen, setOpen] = useState(!getTooltipStorage(tooltipStorageKey));

  useEffect(() => {
    if (isOpen && !getTooltipStorage(tooltipRenderStorageKey)) {
      sendTooltipEvent({ action: NOW, value: IMPRESSION });
      setTooltipStorageHelper(tooltipRenderStorageKey, CLOSED);
      /* Currently Beans does not provide the option to override aria-label of close button.
         This code will be removed once https://github.dev.global.tesco.org/TescoSharedPlatform/beans/issues/1757 is done. 
      */
      const closeButton = document.querySelector(`#${id} .beans-tooltip__close-botton`);
      closeButton?.setAttribute('aria-label', translate('home:favorite-nav-item-tooltip'));
    }
  }, []);

  const setTooltipStorage = (): void => {
    setTooltipStorageHelper(tooltipStorageKey, CLOSED);
  };

  const onClickHandler = (e: SyntheticEvent): void => {
    e?.stopPropagation();
    e?.preventDefault();
  };

  const changeHandler = (e: { action: string }): void => {
    const analyticsData = e.action === 'close' ? { action: NOW, value: CLOSE_X } : { action: DELAY, value: FAVORITES };

    sendTooltipEvent(analyticsData);
    setOpen(false);
  };

  const buttonClickHandler = (e: SyntheticEvent): void => {
    e.stopPropagation();
    changeHandler({ action: FAVORITES });
  };

  const spaLink = ({ children, className, href, onClick }: TSpaLink): JSX.Element => (
    <Link to={href} className={className} onClick={onClick}>
      {children}
    </Link>
  );

  if (!isOpen) {
    return null;
  }

  return (
    <FloatingTooltip
      targetRef={targetRef}
      boundingRef={boundingRef}
      id={id}
      position={TooltipPositions.BOTTOM}
      tooltipWidth={tooltipWidth}
      tooltipVariant={TooltipVariants.INFO}
      styles={styles}
      isOpen={isOpen}
      onChange={changeHandler}
      onClick={onClickHandler}
      tooltipRootID={tooltipRootID}
      portalRootID={portalRootID}
      onUnmount={setTooltipStorage}
      observerId={observerId}
    >
      {heading.length > 0 && <SubHeading>{heading}</SubHeading>}
      <StyledBodyText>
        <StringToNode text={body} />
      </StyledBodyText>
      <StyledButtonContainer>
        <BeansLink
          variant="textButton"
          buttonVariant="secondary"
          as={spaLink}
          onClick={buttonClickHandler}
          href={favoritesLink}
          stretch
        >
          {buttonText}
        </BeansLink>
      </StyledButtonContainer>
    </FloatingTooltip>
  );
};

const enhance = compose<TProps, TOwnProps>(connector);
export default helpers(['t'])(enhance(FavNavLinkTooltipContainer));
