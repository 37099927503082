import { Trolley } from '#/lib/records/trolley.defs';

export const defaults = {
  amendExpiry: '',
  amendOrderId: undefined,
  basketId: undefined,
  orderId: undefined,
  orderNo: undefined,
  charges: undefined,
  clubcardPoints: undefined,
  coupons: [],
  guidePrice: 0,
  totalPrice: 0,
  isAmendBasket: undefined,
  isAmended: undefined,
  previousSlot: {},
  promotions: [],
  savings: 0,
  shoppingMethod: 'delivery',
  slot: {},
  staffDiscount: undefined,
  storeId: undefined,
  locationUuid: undefined,
  canExtendSlot: false,
  vat: null,
  freeDelivery: null,
};

const createTrolley = (args): Trolley => Object.assign({ ...defaults }, args);

export default createTrolley;
