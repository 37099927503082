import React from 'react';
import { connect } from '#/lib/render/connect-deep-compare';
import * as components from '#/components/cms-driven';
import { getDCSPageResource } from '#/selectors/resources';

type TOwnProps = {
  dcsProps: any;
};

const mapStateToProps = (state: any) => {
  const dcsPageResource = getDCSPageResource(state);
  const dcsProps = dcsPageResource?.data?.content || {};

  return {
    dcsProps,
  };
};

type TProps = TOwnProps & ReturnType<typeof mapStateToProps>;

const DCSBanner: React.FC<TProps> = (props: TProps) => {
  const { dcsProps } = props;
  const data = dcsProps?.top || [];

  return (
    dcsProps && (
      <div className="book-a-slot__dcs-banner">
        <components.ComponentTree components={components} data={data} />
      </div>
    )
  );
};

export default connect(mapStateToProps)(DCSBanner);
