import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from '#/lib/render/connect-deep-compare';
import BagOptionsForm from '#/components/bag-options/bag-options-form';
import AboutDeliverySection from '#/components/checkout/about-delivery';
import Panel from '#/components/shared/panel';
import SimpleButton from '#/components/shared/simple-button';
import helpers from '#/lib/decorators/helpers';
import { DELIVERY, COLLECTION } from '#/constants/shopping-methods';
import { getOrderWorkingDaysForDelivery } from '#/selectors/order';
import { getUserDisplayName } from '#/reducers/user';
import {
  getTrolleySlotWorkingDaysUntilDelivery,
  getTrolleyShoppingMethod
} from '#/selectors/trolley';
import { getDefaultSlotsPath } from '#/reducers/slot';
import { getLanguageLink } from '#/reducers/app';
import { isOnDemandShoppingMethod } from '#/lib/shopping-method-util';
import { SlotDeliveryDetails } from './components';
import OrderSummaryInstructions from '#/experiments/oop-1322/components/order-summary-instructions';

const mapStateToProps = (
  state,
  { f, showChangeDetailsButton, shoppingMethod }
) => {
  const defaultSlotsPath = getDefaultSlotsPath(f)(state);
  const trolleyShoppingMethod = getTrolleyShoppingMethod(state);
  const isOnDemandDelivery = isOnDemandShoppingMethod(shoppingMethod);

  const hideDeliveryInstructions = isOnDemandDelivery;
  const renderChangeDetailsButton =
    showChangeDetailsButton && !isOnDemandDelivery;

  return {
    displayName: getUserDisplayName(state),
    trolleyShoppingMethod,
    changeSlotDetailsHref: getLanguageLink(state, defaultSlotsPath),
    workingDaysUntilDelivery:
      getOrderWorkingDaysForDelivery(state) ||
      getTrolleySlotWorkingDaysUntilDelivery(state),
    renderChangeDetailsButton,
    isOnDemandDelivery,
    hideDeliveryInstructions,
    showAboutDeliverySection: isOnDemandDelivery
  };
};

@helpers(['c', 'f', 'hasFeature', 't'])
@connect(mapStateToProps)
export default class DeliveryDetails extends Component {
  static propTypes = {
    address: PropTypes.object.isRequired,
    bagOptions: PropTypes.object.isRequired,
    c: PropTypes.func.isRequired,
    changeSlotDetailsHref: PropTypes.string.isRequired,
    displayName: PropTypes.string,
    f: PropTypes.func.isRequired,
    hasFeature: PropTypes.func.isRequired,
    instructions: PropTypes.string,
    isPendingOrder: PropTypes.bool,
    isTrolleyUpdating: PropTypes.bool,
    message: PropTypes.string,
    renderChangeDetailsButton: PropTypes.bool,
    shoppingMethod: PropTypes.string.isRequired,
    showAboutDeliverySection: PropTypes.bool,
    showBagMessage: PropTypes.bool,
    showBagOptions: PropTypes.bool,
    showEditableDeliveryMessage: PropTypes.bool,
    showInstructions: PropTypes.bool,
    slot: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    trolleyShoppingMethod: PropTypes.string,
    workingDaysUntilDelivery: PropTypes.number.isRequired
  };

  static defaultProps = {
    isPendingOrder: false,
    isTrolleyUpdating: false,
    showBagOptions: false,
    showBagMessage: false,
    renderChangeDetailsButton: false,
    showInstructions: true,
    trolleyShoppingMethod: DELIVERY,
    showEditableDeliveryMessage: false
  };

  constructor(props) {
    super(props);
    this.state = {
      collectionTooltip: false
    };
  }

  renderInstructions = () => {
    const {
      c: config,
      hasFeature,
      instructions,
      showInstructions,
      t: translate,
      trolleyShoppingMethod
    } = this.props;

    if (config('showInstructions') && showInstructions) {
      return (
        <div className="delivery-note-container">
          <h3>
            {hasFeature('customInstructions')
              ? translate(`slots:${trolleyShoppingMethod}.instructions-title`, {
                  context: config('REGION')
                })
              : translate(`slots:${trolleyShoppingMethod}.instructions-title`)}
          </h3>
          <p className="delivery-details--instructions">
            {instructions
              ? instructions
              : translate('checkout:order-summary.empty-instructions')}
          </p>
        </div>
      );
    }
  };

  onChange = e => {
    this.setState({ collectionTooltip: e.target.checked });
  };

  renderDeliveryDetails = props => {
    const { address, message, slot, shoppingMethod } = props;
    return (
      <SlotDeliveryDetails
        address={address}
        message={message}
        slot={slot}
        shoppingMethod={shoppingMethod}
      />
    );
  };

  renderBagMessage = () => {
    const {
      bagOptions,
      t: translate,
      isOnDemandDelivery,
      showBagMessage
    } = this.props;

    const bagMessage = bagOptions.isBagless
      ? translate('checkout:order-confirmation.without-bag-text')
      : translate('checkout:order-confirmation.with-bag-text');

    if (isOnDemandDelivery || !showBagMessage) return null;
    return <div className="bags-confirmation-message">{bagMessage}</div>;
  };

  render() {
    const {
      bagOptions,
      c: config,
      changeSlotDetailsHref,
      isTrolleyUpdating,
      showAboutDeliverySection,
      showBagOptions,
      hideDeliveryInstructions,
      shoppingMethod,
      renderChangeDetailsButton,
      showEditableDeliveryMessage,
      t: translate
    } = this.props;

    const papersOnCollectionParagraphs = (
      config('checkout:papersOnCollectionParagraphs') || []
    ).map(translationKey => ` ${translate(translationKey)}`);

    return (
      <div className="delivery-details">
        <Panel
          headerTag={'h2'}
          title={translate(`checkout:common.${shoppingMethod}-details`)}
        >
          <div className="delivery-details-container">
            {this.renderDeliveryDetails(this.props)}
            {!hideDeliveryInstructions &&
              !showEditableDeliveryMessage &&
              this.renderInstructions()}
            {shoppingMethod === COLLECTION &&
              config('orderSummary:showCollectionInstructions') && (
                <div className="delivery-details--collection">
                  <div className="delivery-details--what-to-bring-title">
                    {translate(
                      'checkout:order-confirmation.papers-on-collection-title'
                    )}
                  </div>
                  <div>{translate('orders:common.order-number')}</div>
                  <div className="delivery-details-payment">
                    {translate(
                      'checkout:order-confirmation.papers-on-collection-id'
                    )}
                    <input
                      checked={this.state.collectionTooltip}
                      onChange={this.onChange}
                      type="checkbox"
                      id="collection-tooltip"
                    />
                    <div
                      className="delivery-details--collection-notice"
                      title={papersOnCollectionParagraphs.join('.')}
                    >
                      {/* LEGO-23699 */}
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label
                        className="icon-question"
                        htmlFor="collection-tooltip"
                      />
                      <span className="icon-tooltip-tip" />
                    </div>
                    <ul className="delivery-details--collection-info">
                      {papersOnCollectionParagraphs.map(
                        (translatedText, key) => (
                          <li key={key}>{translatedText}</li>
                        )
                      )}
                    </ul>
                  </div>
                </div>
              )}
            {renderChangeDetailsButton && (
              <SimpleButton
                type="secondary"
                buttonText={translate('common:change-details')}
                href={changeSlotDetailsHref}
              />
            )}
          </div>
          {showEditableDeliveryMessage && <OrderSummaryInstructions />}
          {showBagOptions && bagOptions.enabled && (
            <BagOptionsForm
              charge={bagOptions.charge}
              isBagless={bagOptions.isBagless}
              displayHeader={true}
              isTrolleyUpdating={isTrolleyUpdating}
            />
          )}
          {this.renderBagMessage()}
          {showAboutDeliverySection && (
            <AboutDeliverySection shoppingMethod={shoppingMethod} />
          )}
        </Panel>
      </div>
    );
  }
}
