import React, { Component } from 'react';
import PropTypes from 'prop-types';
import helpers from '../../../lib/decorators/helpers';
import { DELIVERY } from '../../../constants/shopping-methods';
import { BodyText } from '@ddsweb/typography';
import { StyledInPageMessaging } from './styled';
import SafelyOutputString from '#/components/safely-output-string';

@helpers(['l', 't'])
export default class CancelOrderNotification extends Component {
  static propTypes = {
    l: PropTypes.func.isRequired,
    shoppingMethod: PropTypes.string,
    t: PropTypes.func.isRequired
  };

  render() {
    const { t: translate, l: language } = this.props;

    const message =
      this.props.shoppingMethod === DELIVERY
        ? translate('slots:common.cancel-delivery-order-notification')
        : translate('slots:common.cancel-collection-order-notification');

    return (
      <StyledInPageMessaging
        title={message}
        assistiveText={translate('common:information')}
      >
        <BodyText>
          <SafelyOutputString>
            {translate('slots:common.cancel-order-notification-link', {
              link: language('/orders')
            })}
          </SafelyOutputString>
        </BodyText>
      </StyledInPageMessaging>
    );
  }
}
