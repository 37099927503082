import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SafeForm from '../../shared/safe-form';
import helpers from '../../../lib/decorators/helpers';
import Link from '../../link-check-spa';

@helpers(['c', 't', 'l', 'externalSecureLink'])
export default class LeftHandNavigationHeaderSignedIn extends Component {
  static propTypes = {
    c: PropTypes.func.isRequired,
    externalSecureLink: PropTypes.func.isRequired,
    isRegistered: PropTypes.bool.isRequired,
    l: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    name: PropTypes.string,
    registerUrl: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired
  };

  shouldComponentUpdate(nextProps) {
    /*jshint unused: false */
    return (
      nextProps.isRegistered !== this.props.isRegistered ||
      nextProps.name !== this.props.name
    );
  }

  render() {
    let usernameHref;
    let usernameText;
    const {
      c: config,
      externalSecureLink,
      isRegistered,
      l: languageLink,
      t: translate,
      name,
      registerUrl,
      language
    } = this.props;

    if (isRegistered) {
      usernameHref = config('useExternalMyAccount')
        ? externalSecureLink(config('externalMyAccountUrl')[language])
        : languageLink('/account');

      usernameText = translate('left-hand-navigation:header-signed-in.name', {
        name
      });
    } else {
      usernameHref = registerUrl;
      usernameText = translate('auth:continue-registration');
    }

    return (
      <header className={'main-nav-header'}>
        <Link
          className={'button main-nav-header-button text-fade-left-to-right'}
          to={usernameHref}
          id={'nav-username-button'}
        >
          {usernameText}
        </Link>
        <div className={'vertical-divider signed-in'} />
        <SafeForm action={this.props.l('/logout', 'grocery')} method={'post'}>
          <button
            className={'button-primary main-nav-header-button'}
            id={'nav-signout-button'}
            type={'submit'}
          >
            {this.props.t('common:sign-out')}
          </button>
        </SafeForm>
      </header>
    );
  }
}
