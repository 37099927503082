import {
  onFCP,
  onLCP,
  onCLS,
  onFID,
  onINP,
  CLSMetricWithAttribution,
  FCPMetricWithAttribution,
  FIDMetricWithAttribution,
  INPMetricWithAttribution,
  LCPMetricWithAttribution,
  CLSAttribution,
  FCPAttribution,
  FIDAttribution,
  INPAttribution,
  LCPAttribution,
  CLSReportCallbackWithAttribution,
  FCPReportCallbackWithAttribution,
  LCPReportCallbackWithAttribution,
  INPReportCallbackWithAttribution,
  FIDReportCallbackWithAttribution,
  LoadState,
} from 'web-vitals/attribution';
import { logCustomEvent } from '.';

export type EventWithAttribution =
  | CLSMetricWithAttribution
  | FCPMetricWithAttribution
  | FIDMetricWithAttribution
  | INPMetricWithAttribution
  | LCPMetricWithAttribution;

type Attribution = CLSAttribution | FCPAttribution | FIDAttribution | INPAttribution | LCPAttribution;

type WebVitals = 'CLS' | 'FCP' | 'FID' | 'INP' | 'LCP';

type CLSCustomAttributes = {
  targetElement: string | null;
  loadState: LoadState | null;
};

type InputDelayCustomAttributes = {
  targetElement: string | null;
  eventTime: number | null;
  eventTypeDispatched: string | null;
  loadState: LoadState | null;
};

type LCPCustomAttributes = {
  targetElement: string | null;
  lcpUrl: string | null;
  resourceLoadDelay: number;
  resourceLoadTime: number;
  elementRenderDelay: number;
};

type FCPCustomAttributes = {
  loadState: LoadState | null;
};

type TimingCustomAttributes =
  | CLSCustomAttributes
  | InputDelayCustomAttributes
  | LCPCustomAttributes
  | FCPCustomAttributes;

export const SOFT_NAVIGATION_TYPE = 'soft-navigation';
export const SOFT_NAVIGATION_EVENT_NAME = 'softNavigationTiming';

const SUPPORTED_METRICS = ['FCP', 'LCP', 'CLS', 'FID', 'INP'];

const getCLSAttributes = ({ largestShiftTarget, loadState }: CLSAttribution): CLSCustomAttributes => ({
  targetElement: largestShiftTarget ?? null,
  loadState: loadState ?? null,
});

const getInputDelayAttributes = ({
  eventTarget,
  eventTime,
  eventType,
  loadState,
}: INPAttribution | FIDAttribution): InputDelayCustomAttributes => ({
  targetElement: eventTarget ?? null,
  eventTime: eventTime ?? null,
  eventTypeDispatched: eventType ?? null,
  loadState: loadState ?? null,
});

const getLCPAttributes = ({
  element,
  url,
  resourceLoadDelay,
  resourceLoadTime,
  elementRenderDelay,
}: LCPAttribution): LCPCustomAttributes => ({
  targetElement: element ?? null,
  lcpUrl: url ?? null,
  resourceLoadDelay,
  resourceLoadTime,
  elementRenderDelay,
});

const getFCPAttributes = ({ loadState }: FCPAttribution): FCPCustomAttributes => ({
  loadState: loadState ?? null,
});

function getTimingAttributes(name: WebVitals, attribution: Attribution): TimingCustomAttributes {
  switch (name) {
    case 'CLS':
      return getCLSAttributes(attribution as CLSAttribution);
    case 'FID':
    case 'INP':
      return getInputDelayAttributes(attribution as FIDAttribution | INPAttribution);
    case 'LCP':
      return getLCPAttributes(attribution as LCPAttribution);
    case 'FCP':
      return getFCPAttributes(attribution as FCPAttribution);
  }
}

export function logSoftNavigationTiming(event: EventWithAttribution): void {
  if (event.navigationType === SOFT_NAVIGATION_TYPE && SUPPORTED_METRICS.includes(event.name)) {
    const { name, value, attribution = {} } = event;

    logCustomEvent(SOFT_NAVIGATION_EVENT_NAME, {
      webVitalName: name,
      webVitalTiming: value,
      ...getTimingAttributes(name, attribution),
    });
  }
}

function initSoftNavigationTimings(): void {
  if (window.PerformanceObserver?.supportedEntryTypes) {
    onFCP(logSoftNavigationTiming as FCPReportCallbackWithAttribution, { reportSoftNavs: true });
    onLCP(logSoftNavigationTiming as LCPReportCallbackWithAttribution, { reportSoftNavs: true });
    onCLS(logSoftNavigationTiming as CLSReportCallbackWithAttribution, { reportSoftNavs: true });
    onFID(logSoftNavigationTiming as FIDReportCallbackWithAttribution, { reportSoftNavs: true });
    onINP(logSoftNavigationTiming as INPReportCallbackWithAttribution, { reportSoftNavs: true });
  }
}

export default initSoftNavigationTimings;
