import { createItem } from '#/lib/records';
import { Item } from '#/lib/records/item';
import { getById, idInItems, setById, transferBulkBuyLimitProperties } from '#/lib/records/product-utils';
import { getProductSubstitutions, isProduct } from '#/selectors/item';
import { cloneItems } from '#/utils/clone-items';
import { setNumberOfItems } from '#/lib/records/item-utils';

export default function findAndSetTrolleyItems(items: Array<Item>, trolleyItems?: Array<Item>): Array<Item> {
  if (typeof trolleyItems === 'undefined') {
    return items;
  }

  let clonedItems = cloneItems(items);

  // if items are not in trolleyItems set quantity to 0
  clonedItems.forEach((clonedItem: Item) => {
    // This is only required for Product
    if (isProduct(clonedItem)) {
      const id = clonedItem?.product?.id;

      if (!idInItems(id, trolleyItems)) {
        clonedItem = setNumberOfItems(clonedItem, 0);
      }

      const subItems = getProductSubstitutions(clonedItem);

      if (subItems?.length) {
        const updatedSubItems = cloneItems(subItems).map(updatedItem => {
          if (!idInItems(id, trolleyItems)) {
            return { ...updatedItem, quantity: 0 };
          } else {
            return updatedItem;
          }
        });

        // Transfer a set of properties from trolleyItems to subItems
        trolleyItems.forEach((item: Item) => {
          const id = item.product.id;
          const baseItem = updatedSubItems.find(({ product }) => product.id === id);
          if (baseItem) {
            const {
              catchWeight,
              cost,
              customerUnitChoice,
              isNewlyAdded,
              itemLimitReached,
              originalQuantity,
              pickerNote,
              product: { isForSale },
              quantity,
              substitutionOption,
            } = item;

            updatedSubItems[updatedSubItems.indexOf(baseItem)] = createItem({
              ...baseItem,
              catchWeight,
              cost,
              customerUnitChoice,
              isNewlyAdded,
              itemLimitReached,
              originalQuantity,
              pickerNote,
              product: {
                ...baseItem.product,
                isForSale,
              },
              quantity,
              substitutionOption,
            });
          }
        });

        clonedItem.product.substitutions = transferBulkBuyLimitProperties(updatedSubItems, trolleyItems, [
          ['groupLimitReached'],
          ['groupBulkBuyQuantity'],
        ]);
      }

      clonedItems = setById(clonedItems, clonedItem);
    }
  });

  // Transfer a set of properties from trolleyItems to items
  trolleyItems.forEach((item: Item) => {
    const id = item.product.id;
    const baseItem = getById(clonedItems, id);
    if (baseItem) {
      const {
        catchWeight,
        cost,
        customerUnitChoice,
        isNewlyAdded,
        itemLimitReached,
        originalQuantity,
        pickerNote,
        product: { isForSale },
        quantity,
        substitutionOption,
      } = item;

      clonedItems = setById(
        clonedItems,
        createItem({
          ...baseItem,
          catchWeight,
          cost,
          customerUnitChoice,
          isNewlyAdded,
          itemLimitReached,
          originalQuantity,
          pickerNote,
          product: {
            ...baseItem.product,
            isForSale,
          },
          quantity,
          substitutionOption,
        }),
      );
    }
  });

  return transferBulkBuyLimitProperties(clonedItems, trolleyItems, [['groupLimitReached'], ['groupBulkBuyQuantity']]);
}
