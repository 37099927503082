import React from 'react';

export const CardIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Web Icons/Credit card">
        <path
          id="Union"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.23394 6.34331C3.81034 6.34331 2.6834 7.47294 2.6834 8.83364V23.9863C2.6834 25.347 3.81034 26.4766 5.23394 26.4766H28.2649C29.6888 26.4766 30.8167 25.3467 30.8167 23.9863V8.83364C30.8167 7.47326 29.6888 6.34331 28.2649 6.34331H5.23394ZM1.4834 8.83364C1.4834 6.79106 3.1669 5.14331 5.23394 5.14331H28.2649C30.3316 5.14331 32.0167 6.79075 32.0167 8.83364V23.9863C32.0167 26.0292 30.3316 27.6766 28.2649 27.6766H5.23394C3.1669 27.6766 1.4834 26.0289 1.4834 23.9863V8.83364ZM11.4167 22.3433H6.0834V21.1433H11.4167V22.3433ZM3.41673 10.4766H2.81673V11.0766V12.41V13.01H3.41673H30.0834H30.6834V12.41V11.0766V10.4766H30.0834H3.41673ZM4.01673 11.81V11.6766H29.4834V11.81H4.01673Z"
          fill="white"
        />
      </g>
    </svg>
  );
};
