import React from 'react';
import { StyledHeading, StyledText } from '../styled';

type TProps = {
  description: string;
  label: string;
};

const InstructionsDescription = ({ description, label }: TProps): React.ReactElement => (
  <div className="instructions--lefthand">
    <div className="instructions-label-container">
      <StyledHeading headingLevel="3" visualSize="headline5">
        {label}
      </StyledHeading>
    </div>

    <StyledText as="p" size="small">
      {description}
    </StyledText>
  </div>
);

export default InstructionsDescription;
