import { getCurrentUrl, getLanguageLink } from '#/reducers/app';
import { getProductId } from '#/selectors/item';
import { updateParamsInUrl } from '#/lib/url/url-utils';
import { getIsUserRegistered } from '#/reducers/user';
import { getCsrfToken } from '#/reducers/app';
import { getQuantity, getUnit, getSeedProductId } from '#/selectors/item';
import { getTrolleyItemByBaseProductId } from '#/selectors/trolley';
import { Item } from '#/lib/records/item';

export type TFormAddParams = {
  _csrf: string;
  adjustment: boolean;
  anchorId: string;
  backToUrl: string;
  id: string;
  returnUrl: string;
  oldItemQuantity: number;
  oldItemUnitChoice: string;
  itemForRemoval: string | Item | null | undefined;
  removedProductId: string | number | undefined;
};
export type TFormAddData = {
  action: string;
  params: TFormAddParams;
};

const getReturnUrl = (state: any, anchorId: string, userRegistered: boolean, identifier: string) => {
  const additionalParams = !userRegistered && identifier === 'Page' ? { redirectIfUnavailable: true } : {};
  const currentUrl = getCurrentUrl(state);
  const returnUrl = updateParamsInUrl(currentUrl, additionalParams);

  return `${returnUrl}${anchorId}`;
};

export const getAddFormParams = (
  state: any,
  item: Item,
  identifier: string,
  forAllusers?: boolean,
): TFormAddData | null => {
  const userRegistered = getIsUserRegistered(state);

  if (!forAllusers) {
    if (userRegistered) return null;
  }

  const seedProductId = getSeedProductId(item);
  const itemForRemoval = seedProductId && getTrolleyItemByBaseProductId(state, seedProductId);
  let removedProductId;

  if (itemForRemoval) {
    removedProductId = getProductId(itemForRemoval);
  }

  const id = getProductId(item);
  const anchorId = `#tile-${id}`;
  return {
    action: getLanguageLink(state, `/trolley/items/${id}?_method=PUT`),
    params: {
      _csrf: getCsrfToken(state),
      adjustment: true,
      anchorId,
      backToUrl: '#',
      id,
      returnUrl: getReturnUrl(state, anchorId, userRegistered, identifier),
      oldItemQuantity: getQuantity(item),
      oldItemUnitChoice: getUnit(item),
      itemForRemoval,
      removedProductId,
    },
  };
};
