import React, { useRef, useState } from 'react';
import { IconButton } from '@ddsweb/button';
import Icon from '@ddsweb/icon';
import MobileMenuOverlay from './mobile-menu-overlay';
import { MobileDiv } from './styled';
import helpers from '#/lib/decorators/helpers';
import { TTranslateFunc } from '#/lib/records/helpers.defs';
import { CmsNav } from '#/components/primary-navigation/helpers/taxonomy';

export type Props = {
  t: TTranslateFunc;
  cmsNav: CmsNav;
};

const MobileMenuButton = ({ t: translate, cmsNav }: Props): JSX.Element => {
  const triggerRef = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <MobileDiv>
      <IconButton
        domRef={triggerRef}
        variant="link"
        aria-label={translate('navigation:menu')}
        data-testid="Menu Button"
        icon={<Icon graphic="menu" />}
        onClick={(): void => setIsOpen(true)}
      />
      <MobileMenuOverlay
        isOpen={isOpen}
        onCloseFocusRef={triggerRef}
        onClose={(): void => setIsOpen(false)}
        cmsNav={cmsNav}
      />
    </MobileDiv>
  );
};

export default helpers(['t', 'c'])(MobileMenuButton);
