import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import Icon from '#/components/shared/icon';
import Link from '#/components/link';

const ChevronLink = props => (
  <Link {...props} className={classnames(props.className, 'link--chevron')}>
    {props.children}&nbsp;
    <Icon name={props.chevronName} />
  </Link>
);

ChevronLink.propTypes = {
  chevronName: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  target: PropTypes.string,
  to: PropTypes.string
};

ChevronLink.defaultProps = {
  chevronName: 'chevron_right'
};

export default ChevronLink;
