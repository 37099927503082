import styled from 'styled-components';
import { colors, spacing, fontSize } from '@ddsweb/selectors';
import { Heading } from '@ddsweb/typography';
import { Text } from '@ddsweb/text';
import ResponsiveImage from '@ddsweb/responsive-image';

export const CaptionWrapper = styled.div`
  padding: ${spacing.xl} ${spacing.sm};
  background-color: ${colors.white};
`;
export const ButtonContainer = styled.div`
  margin: ${spacing.md} 0 ${spacing.lg};

  & a {
    display: inline-flex;
  }
`;

export const ResponsiveImageContainer = styled.span`
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  & .responsive-image--image {
    vertical-align: middle;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const ImageWrapper = styled(ResponsiveImage)`
  object-position: 50% 50%;
`;

export const TileTitle = styled(Heading)`
  color: ${colors.base};
  font-size: ${fontSize.sm};
`;

export const SubText = styled(Text)`
  margin: ${spacing.sm} 0;
  color: ${colors.text};
`;
