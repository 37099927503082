import styled from 'styled-components';
import { InPageMessaging } from '@ddsweb/messaging';
import { BodyText } from '@ddsweb/typography';

export const StyledInLineMessaging = styled(InPageMessaging)`
  .beans-in-page-messaging__content-container > div {
    padding-top: 0;
  }
`;

export const StyledBodyText = styled(BodyText)`
  text-align: left;
`;
