import React from 'react';
import styled from 'styled-components';
import DcsTradeStamp from '#/experiments/oop-2021/components/trade_stamp';
import { media } from '@ddsweb/foundation';
import { StyledContentListItem } from '#/components/cms-driven/shoppable-tiles-wrapper/styled';
import { ContentDetails } from '#/experiments/oop-2021/types';

export const StyledShoppableTilesWrapper = styled.ol`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  justify-content: space-between;
  width: 100%;
  min-height: 285px;

  & li {
    padding: 0;
  }

  ${media.belowMobileLarge`
    min-height: 347px;
  `}
`;

export type ContentStampProps = {
  content: ContentDetails;
  index: string;
};

const ContentStamp = ({ content, index }: ContentStampProps): JSX.Element => {
  const itemPosition = parseInt(index) + 1;
  return (
    <StyledShoppableTilesWrapper className="ui-components-library">
      <StyledContentListItem>
        <DcsTradeStamp contentDetails={content} position={itemPosition} />
      </StyledContentListItem>
    </StyledShoppableTilesWrapper>
  );
};

export default ContentStamp;
