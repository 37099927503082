import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '../shared/icon';

export default class CarouselButton extends PureComponent {
  static propTypes = {
    direction: PropTypes.oneOf(['forward', 'backward']).isRequired,
    disabled: PropTypes.bool,
    icon: PropTypes.string,
    pressHandler: PropTypes.func,
    releaseHandler: PropTypes.func,
    scrollAmount: PropTypes.number,
    title: PropTypes.string
  };

  static defaultProps = {
    backgroundColor: 'blue',
    disabled: false,
    pressHandler: () => {},
    releaseHandler: () => {},
    scrollAmount: 180,
    icon: 'chevron_right-white'
  };

  onClick = e => {
    e.preventDefault();
  };

  onPointerDown = () => {
    const { direction, pressHandler, scrollAmount } = this.props;
    const forward = direction === 'forward';
    const scrollIncrement = forward ? scrollAmount : -1 * scrollAmount;

    pressHandler(scrollIncrement, direction);
  };

  onPointerUp = e => {
    const { releaseHandler } = this.props;

    releaseHandler();

    e.preventDefault();
  };

  render() {
    const { direction, disabled, icon, backgroundColor, title } = this.props;

    return (
      <button
        aria-disabled={disabled}
        title={title || direction}
        className={classnames(
          `carousel-button ${direction}`,
          `background-${backgroundColor}`,
          { disabled }
        )}
        onClick={this.onClick}
        onMouseDown={this.onPointerDown}
        onTouchStart={this.onPointerDown}
        onTouchEnd={this.onPointerUp}
        onMouseUp={this.onPointerUp}
        type="button"
      >
        <span className="carousel-button--icon">
          <Icon name={icon} />
        </span>
      </button>
    );
  }
}
