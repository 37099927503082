import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Controls from './controls';
import {
  PLAY,
  PAUSE,
  FULLSCREEN,
  MILE_1,
  MILE_2,
  MILE_3,
  COMPLETE
} from '../../analytics/constants';
import helpers from '#/lib/decorators/helpers';

@helpers(['t'])
export default class VideoPlayer extends React.Component {
  static propTypes = {
    autoPlay: PropTypes.bool,
    controlsTimeout: PropTypes.number,
    emitVideoEvent: PropTypes.func,
    loop: PropTypes.bool,
    muteAudio: PropTypes.bool,
    poster: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired
  };

  static defaultProps = {
    autoPlay: false,
    loop: false,
    muteAudio: false,
    controlsTimeout: 3000,
    emitVideoEvent: () => {}
  };

  constructor(props) {
    super(props);
    this.state = {
      isPlaying: props.autoPlay,
      isFullScreen: false,
      showPlayPause: !props.autoPlay,
      showControls: false,
      progress: 0,
      supportsFullScreen: true,
      isControlsFocused: false,
      progressMile: null,
      volume: props.muteAudio ? 0 : 1
    };
  }
  componentDidMount() {
    this.video.volume = this.props.muteAudio ? 0 : 1;

    //Setting up fullscreen api
    const videoContainer = this.videoContainer;

    this.requestFullScreen =
      videoContainer.requestFullscreen ||
      videoContainer.mozRequestFullScreen ||
      videoContainer.webkitRequestFullscreen;

    this.exitFullScreen =
      document.exitFullscreen ||
      document.mozCancelFullScreen ||
      document.webkitExitFullscreen;

    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({
      supportsFullScreen: !!this.requestFullScreen
    });

    document.addEventListener(
      'webkitfullscreenchange',
      this.handleEscapeFullScreen
    );

    document.addEventListener(
      'mozfullscreenchange',
      this.handleEscapeFullScreen
    );

    document.addEventListener('fullscreenchange', this.handleEscapeFullScreen);

    document.addEventListener(
      'MSFullscreenChange',
      this.handleEscapeFullScreen
    );
  }

  componentWillUnmount() {
    clearTimeout(this.hideControlTimer);

    document.removeEventListener(
      'webkitfullscreenchange',
      this.handleEscapeFullScreen
    );
    document.removeEventListener(
      'mozfullscreenchange',
      this.handleEscapeFullScreen
    );
    document.removeEventListener(
      'fullscreenchange',
      this.handleEscapeFullScreen
    );
    document.removeEventListener(
      'MSFullscreenChange',
      this.handleEscapeFullScreen
    );
  }
  handleEscapeFullScreen = () => {
    if (
      !document.webkitIsFullScreen &&
      !document.mozFullScreen &&
      !document.msFullscreenElement
    ) {
      this.setState({ isFullScreen: false });
    }
  };

  handlePlayPause = () => {
    const { isPlaying } = this.state;
    const videoEvent = {};

    if (isPlaying) {
      clearTimeout(this.hideControlTimer);
      this.video.pause();
      videoEvent.interactionType = PAUSE;
    } else {
      // When video continues playing, reset hideControlTimer
      this.toggleControlVisibility(!isPlaying);

      videoEvent.interactionType = PLAY;
      this.video.play();
    }

    this.setState({
      showPlayPause: true,
      showControls: true,
      isPlaying: !isPlaying
    });

    this.props.emitVideoEvent(videoEvent.interactionType);
  };

  handleVolume = volumeSlideEvent => {
    const volume = volumeSlideEvent.target.value / 100;

    this.setState({ volume });
    this.video.volume = volume;
  };

  handleMute = () => {
    const volume = this.state.volume > 0 ? 0 : 1;

    this.setState({ volume });

    this.video.volume = volume;
  };

  toggleControlVisibility = isPlaying => {
    const { showPlayPause, showControls } = this.state;

    if (isPlaying) {
      clearTimeout(this.hideControlTimer);

      this.hideControlTimer = setTimeout(() => {
        this.setState({
          showPlayPause: false,
          showControls: false
        });
      }, this.props.controlsTimeout);

      if (!showPlayPause || !showControls) {
        this.setState({
          showPlayPause: true,
          showControls: true
        });
      }
    }
  };

  handleVideoEnded = () => {
    clearTimeout(this.hideControlTimer);

    this.props.emitVideoEvent(COMPLETE);

    if (this.props.loop) {
      return;
    }

    this.video.currentTime = 0;

    this.setState({
      isPlaying: false,
      showPlayPause: true,
      showControls: false
    });
  };

  handleControlsFocus = () => {
    this.setState({ isControlsFocused: true });
  };

  handleControlsBlur = () => {
    this.setState({ isControlsFocused: false });
  };

  handleFullScreen = () => {
    if (!this.state.supportsFullScreen) {
      return;
    }
    if (!this.state.isFullScreen) {
      this.props.emitVideoEvent(FULLSCREEN);
      const videoContainer = this.videoContainer;

      this.requestFullScreen.call(videoContainer);
    } else {
      this.exitFullScreen.call(document);
    }

    this.setState({
      isFullScreen: !this.state.isFullScreen
    });
  };

  handleMouseMove = () => {
    this.toggleControlVisibility(this.state.isPlaying);
  };

  onSeek = progressEvent => {
    const progress = parseInt(progressEvent.target.value);
    this.video.currentTime = (progress * this.video.duration) / 100;
    this.setState({ progress });
  };

  onProgress = () => {
    const progress = (this.video.currentTime / this.video.duration) * 100;

    this.videoProgressEvent(progress);
    this.setState({
      progress
    });
  };

  videoProgressEvent = progress => {
    const progressEvent = {};
    // Fire events when the video reaches three milestones at 25%, 50% and 75%

    if (progress >= 75) {
      progressEvent.interactionType = MILE_3;
    } else if (progress >= 50) {
      progressEvent.interactionType = MILE_2;
    } else if (progress >= 25) {
      progressEvent.interactionType = MILE_1;
    }

    if (progressEvent.interactionType !== this.state.progressMile) {
      this.props.emitVideoEvent(progressEvent.interactionType);
      this.setState({ progressMile: progressEvent.interactionType });
    }
  };
  render() {
    const {
      showControls,
      isPlaying,
      isFullScreen,
      isControlsFocused,
      progress,
      supportsFullScreen
    } = this.state;

    const { t, src, poster, autoPlay, loop } = this.props;

    const videoContainerClass = classnames('video__container', {
      'video__container--fullscreen': isFullScreen
    });

    return (
      <div
        className={videoContainerClass}
        onMouseMove={this.handleMouseMove}
        ref={videoContainer => {
          this.videoContainer = videoContainer;
        }}
      >
        {showControls || !isPlaying ? (
          <button
            className="video__play-pause-icon-wrapper"
            onClick={this.handlePlayPause}
            title={isPlaying ? t('pause-video') : t('play-video')}
          >
            <div
              className={`video__play-pause-icon ${
                isPlaying ? 'icon-pause-dark-gray' : 'icon-play-dark-gray'
              }`}
            />
          </button>
        ) : null}
        {this.video && (
          <Controls
            isPlaying={isPlaying}
            onBlur={this.handleControlsBlur}
            isFullScreen={isFullScreen}
            onFocus={this.handleControlsFocus}
            currentTime={Math.round(this.video.currentTime)}
            duration={Math.round(this.video.duration)}
            playPauseHandler={this.handlePlayPause}
            collapse={!showControls && !isControlsFocused}
            fullScreenHandler={this.handleFullScreen}
            volume={this.video.volume}
            volumeHandler={this.handleVolume}
            muteHandler={this.handleMute}
            supportsFullScreen={supportsFullScreen}
            onSeek={this.onSeek}
            progress={progress}
            t={t}
          />
        )}
        <video
          ref={video => {
            this.video = video;
          }}
          className="video__player"
          src={src}
          poster={poster}
          onTimeUpdate={this.onProgress}
          onEnded={this.handleVideoEnded}
          autoPlay={autoPlay}
          loop={loop}
        />
      </div>
    );
  }
}
