import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import GlobalNavigation from '@ddsweb/global-navigation';
import delimitQuery from '#/components/mixins/delimit-query';
import { openFeedbackWindow } from '#/lib/open-feedback-window';
import { basicEvent } from '#/analytics/types/basic';
import analyticsBus from '#/analytics/analyticsBus';
import helpers from '#/lib/decorators/helpers';
import { connect } from '#/lib/render/connect-deep-compare';
import {
  getAlternateLanguage,
  getCurrentUrl,
  getCsrfToken,
  getLanguage,
  getLanguageLink,
  getLoginUrl,
  getMyAccountUrl,
  getRegisterUrl,
  getCutsMustard,
  getDeviceType,
  getTraceId
} from '#/reducers/app';
import {
  getUserDisplayName,
  getUserLastName,
  getIsUserAuthenticated,
  getIsUserRegistered,
  getUserTitle,
  getUserStoreId,
  getIsFirstTimeShopper,
  getUserHashedUId,
  getUserUuid
} from '#/reducers/user';
import { emitRegisterAnalytics } from '#/analytics/helpers/register';
import styled from 'styled-components';
import NameGreeter from './name-greeter';
import { clearUserSessionData } from '#/lib/url/modal-utils';
import { getExperienceCookieAccepted } from '#/reducers/ui';
import { getShowFavNavLinkTooltip } from '#/experiments/oop-1263/selectors';
import {
  setTooltipStorage,
  getStorageKey
} from '#/experiments/oop-1263/helpers';
import { SEARCH_STORAGE_KEY } from '#/experiments/oop-1742/constants';
import { CONTENT_SEARCH_STORAGE_KEY } from '#/experiments/oop-2002/constants';
import { getIsSlotNotifyMeVariant } from '#/experiments/oop-2034/selectors';
import { DISMISS_INTERESTED_IN_SLOT_NOTIFY_ME } from '#/experiments/oop-2034/constants';
import {
  SHOWN_SLOT_REBOOK_MODAL_KEY,
  SHOWN_SLOT_REMIND_MODAL_KEY
} from '#/experiments/oop-2210/constants';

// Temp fix for border sizing as border-box
// is applied to all elements on lego
export const StyledAppBar = styled(GlobalNavigation)`
  z-index: ${props => (props.isHorizontalTaxonomyEnabled ? '1' : '13')};
  position: relative;
  #language-switch-link svg,
  li a {
    box-sizing: initial;
  }
  .no-js && {
    .utility-header__feedback-link {
      border-right: none;
      a {
        display: none;
      }
    }
  }

  &&&& {
    a:focus,
    a:visited,
    a:hover {
      text-decoration: underline;
    }
  }
`;

const mapStateToProps = (state, ownProps) => {
  return {
    accountUrl: getMyAccountUrl(state, ownProps.c('externalMyAccountUrl')),
    alternateLanguage: getAlternateLanguage(state),
    currentUrl: getCurrentUrl(state),
    csrfToken: getCsrfToken(state),
    cutsMustard: getCutsMustard(state),
    deviceType: getDeviceType(state),
    firstName: getUserDisplayName(state),
    hashedUId: getUserHashedUId(state),
    isFirstTimeShopper: getIsFirstTimeShopper(state),
    isUserAuthenticated: getIsUserAuthenticated(state),
    isUserRegistered: getIsUserRegistered(state),
    language: getLanguage(state),
    languageSwitchLink: getLanguageLink(state, '/language/switch'),
    lastName: getUserLastName(state),
    loginUrl: getLoginUrl(state),
    logoutUrl: getLanguageLink(state, '/logout'),
    ordersUrl: getLanguageLink(state, '/orders'),
    registrationUrl: getRegisterUrl(state),
    storeId: getUserStoreId(state),
    traceId: getTraceId(state),
    title: getUserTitle(state),
    isExperienceCookieAccepted: getExperienceCookieAccepted(state),
    shouldSetFavNavTooltipStorage: getShowFavNavLinkTooltip(state),
    uuid: getUserUuid(state),
    shouldSetInterestedInSlotNotifyMeStorage: getIsSlotNotifyMeVariant(state),
    isHorizontalTaxonomyEnabled: ownProps.c('enableHorizontalTaxanomy')
  };
};

const UtilityHeader = props => {
  const { t } = props;
  const getGreeting = ({ title, firstName, lastName }) => ({
    id: 'utility-header-greetings',
    firstName,
    lastName,
    title,
    altNode: NameGreeter,
    text: ''
  });

  const getMyOrdersLink = ({ ordersUrl, t }) => ({
    id: 'utility-header-orders-link',
    href: ordersUrl,
    text: t('layout:main-nav.my-orders')
  });

  const getMyAccountLink = ({ accountUrl, t }) => ({
    id: 'utility-header-account-link',
    href: accountUrl,
    text: t('common:my-account')
  });

  const logoutLinkClickHandler = () => {
    clearUserSessionData('shownBasketBreachModal');
    clearUserSessionData('hasOndemandStatusAnalyticsFired');
    clearUserSessionData(SEARCH_STORAGE_KEY);
    clearUserSessionData(CONTENT_SEARCH_STORAGE_KEY);
    clearUserSessionData(SHOWN_SLOT_REBOOK_MODAL_KEY);
    clearUserSessionData(SHOWN_SLOT_REMIND_MODAL_KEY);
    if (props.shouldSetFavNavTooltipStorage) {
      setTooltipStorage(getStorageKey(props.uuid));
    }
    if (props.shouldSetInterestedInSlotNotifyMeStorage) {
      clearUserSessionData(DISMISS_INTERESTED_IN_SLOT_NOTIFY_ME);
    }
  };

  const getLogoutLink = ({ csrfToken, logoutUrl, t }) => ({
    id: 'utility-header-logout-link',
    action: logoutUrl,
    inputs: [
      {
        name: '_csrf',
        type: 'hidden',
        value: csrfToken
      }
    ],
    text: t('common:sign-out'),
    onClick: logoutLinkClickHandler
  });

  const onLanguageSwitchClick = altLanguage =>
    basicEvent(analyticsBus, {
      type: 'languageChange',
      value: altLanguage
    });

  const getLanguageSwitchLink = ({
    alternateLanguage,
    currentUrl,
    csrfToken,
    languageSwitchLink,
    t
  }) => {
    if (alternateLanguage) {
      const qs = delimitQuery.parseQuerystring(currentUrl);
      const returnUrl = delimitQuery.createHref(currentUrl, qs);
      const flag = alternateLanguage.split('-')[1].toLowerCase();

      return {
        id: 'utility-header-language-switch-link',
        action: languageSwitchLink,
        inputs: [
          {
            name: 'requestedLang',
            type: 'hidden',
            value: alternateLanguage
          },
          {
            name: 'returnUrl',
            type: 'hidden',
            value: returnUrl
          },
          {
            name: '_csrf',
            type: 'hidden',
            value: csrfToken
          }
        ],
        icon: flag,
        onClick: () => onLanguageSwitchClick(alternateLanguage),
        text: t('layout:main-nav.translate', { context: alternateLanguage })
      };
    }
  };

  const getContactUsLink = ({ c: config, t: translate }) => {
    let hasContactUsLink = config(`links:contact-us`);
    if (hasContactUsLink) {
      return {
        id: 'utility-header-contact-us-link',
        href: hasContactUsLink,
        text: translate('common:pages.contact'),
        rel: 'help noopener'
      };
    }
  };

  const getHelpLink = ({
    c: config,
    language,
    t: translate,
    isUserAuthenticated
  }) => {
    let href = config(`links:help:${language}`);
    const anonHelpHref = config(`links:anon-help:${language}`);
    if (!isUserAuthenticated && anonHelpHref) {
      href = anonHelpHref;
    }
    return {
      id: 'utility-header-help-link',
      href,
      text: translate('common:help'),
      target: '_blank',
      rel: 'help noopener'
    };
  };

  const onFeedbackClick = props => {
    basicEvent(analyticsBus, {
      type: 'feedback',
      value: `opinionLab:nav`
    });
    const {
      cutsMustard,
      deviceType,
      isFirstTimeShopper,
      storeId,
      hashedUId,
      traceId
    } = props;
    const customerVariables = {
      cutsMustard: String(cutsMustard),
      deviceType,
      isFirstTimeShopper: String(isFirstTimeShopper),
      storeId,
      ghsUUID: hashedUId,
      ghs_customerID: hashedUId,
      traceId
    };
    openFeedbackWindow('nav', customerVariables);
  };

  const getFeedbackLink = ({ f, t, isExperienceCookieAccepted }) =>
    f('feedbackLinks') && isExperienceCookieAccepted
      ? {
          href: '#',
          text: t('common:feedback'),
          className: 'utility-header__feedback-link',
          onClick: () => onFeedbackClick(props),
          id: 'utility-header-feedback-link'
        }
      : undefined;

  const getLoginLink = ({ loginUrl, t }) => ({
    id: 'utility-header-login-link',
    href: loginUrl,
    text: t('common:sign-in')
  });

  const fireAnalytics = () =>
    window?.event?.target?.className.includes('register') &&
    emitRegisterAnalytics('header');

  const getRegisterLink = ({ registrationUrl, t }) => ({
    id: 'utility-header-registration-link',
    href: registrationUrl,
    text: t('common:register'),
    onClick: () => fireAnalytics()
  });

  const getContinueRegistrationLink = ({ registrationUrl, t }) => ({
    id: 'utility-header-continue-registration-link',
    href: registrationUrl,
    text: t('auth:continue-registration')
  });

  const getSignedInLinks = props => [
    getGreeting(props),
    getMyOrdersLink(props),
    getMyAccountLink(props),
    getLogoutLink(props),
    getLanguageSwitchLink(props),
    getContactUsLink(props),
    getHelpLink(props),
    getFeedbackLink(props)
  ];

  const getPartialRegistrationLinks = props => [
    getContinueRegistrationLink(props),
    getLogoutLink(props),
    getLanguageSwitchLink(props),
    getHelpLink(props),
    getFeedbackLink(props)
  ];

  const getSignedOutLinks = props => [
    getRegisterLink(props),
    getLoginLink(props),
    getLanguageSwitchLink(props),
    getContactUsLink(props),
    getHelpLink(props),
    getFeedbackLink(props)
  ];

  const getRegistrationProcessLinks = props => [
    getLanguageSwitchLink(props),
    getContactUsLink(props),
    getHelpLink(props),
    getFeedbackLink(props)
  ];

  const getLinks = props => {
    let links = [];
    if (props.isUserAuthenticated && props.isUserRegistered) {
      links = getSignedInLinks(props);
    } else if (props.isUserAuthenticated) {
      links = getPartialRegistrationLinks(props);
    } else if (props.registrationProcess) {
      links = getRegistrationProcessLinks(props);
    } else {
      links = getSignedOutLinks(props);
    }

    return links.filter(menuItem => menuItem !== undefined);
  };

  const classes = classnames('utility-bar', {
    'utility-bar-sticky': props.isSticky
  });

  return (
    <StyledAppBar
      menu={getLinks(props)}
      className={classes}
      ariaLabel={t('common:aria.account-links')}
      root
    />
  );
};

UtilityHeader.propTypes = {
  accountUrl: PropTypes.string.isRequired,
  alternateLanguage: PropTypes.string,
  c: PropTypes.func.isRequired,
  csrfToken: PropTypes.string.isRequired,
  currentUrl: PropTypes.string.isRequired,
  f: PropTypes.func.isRequired,
  firstName: PropTypes.string,
  hashedUId: PropTypes.string.isRequired,
  isSticky: PropTypes.bool,
  isUserAuthenticated: PropTypes.bool.isRequired,
  isUserRegistered: PropTypes.bool,
  language: PropTypes.string.isRequired,
  languageSwitchLink: PropTypes.string.isRequired,
  lastName: PropTypes.string,
  loginUrl: PropTypes.string.isRequired,
  logoutUrl: PropTypes.string.isRequired,
  ordersUrl: PropTypes.string.isRequired,
  registrationProcess: PropTypes.bool,
  registrationUrl: PropTypes.string.isRequired,
  shouldSetFavNavTooltipStorage: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  title: PropTypes.string,
  uuid: PropTypes.string.isRequired
};

export default helpers(['c', 'f', 't'])(
  connect(mapStateToProps)(UtilityHeader)
);
