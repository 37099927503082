import {
  UPDATE_HYF_RECOMMENDATIONS,
  UPDATE_TESCO_RECOMMENDATIONS,
  CLEAR_TESCO_RECOMMENDATIONS
} from '#/constants/action-types';
import {
  UPDATE_IBYC_RECOMMENDATIONS,
  CLEAR_IBYC_RECOMMENDATIONS
} from '#/experiments/oop-2349/constants/action-types';
import { itemsToMap } from '#/lib/records/product-utils';

const initialState = {
  hasHYFRecommendations: false,
  tescoRecommendations: null,
  forcedRecommendation: null,
  ibycRecommendations: null
};

const updateHYFRecommendations = recommendations => ({
  hasHYFRecommendations: recommendations.hasHYFRecommendations
});

function getInflatedRecommendations(recommendations) {
  if (!recommendations) {
    return null;
  }

  const keys = Object.keys(recommendations);
  const output = {};

  keys.map(function(key) {
    const data = recommendations[key];

    if (key === 'empty' || key === 'error') {
      output[key] = data;

      return;
    }

    output[key] = {
      pageInformation: data.pageInformation,
      productItems: itemsToMap(data.productItems)
    };
  });

  return output;
}

export default (state = {}, action) => {
  switch (action.type) {
    case UPDATE_HYF_RECOMMENDATIONS:
      return { ...state, ...updateHYFRecommendations(action.value) };
    case UPDATE_TESCO_RECOMMENDATIONS:
      return {
        ...state,
        tescoRecommendations: getInflatedRecommendations(action.value)
      };
    case UPDATE_IBYC_RECOMMENDATIONS:
      return {
        ...state,
        ibycRecommendations: getInflatedRecommendations(action.value)
      };
    case CLEAR_TESCO_RECOMMENDATIONS:
      return {
        ...state,
        tescoRecommendations: initialState.tescoRecommendations
      };
    case CLEAR_IBYC_RECOMMENDATIONS:
      return {
        ...state,
        ibycRecommendations: initialState.ibycRecommendations
      };
    default:
      return state;
  }
};

export const getDefaultStateFromProps = (props = {}) => {
  const { recommendations = {}, resources = {} } = props;
  const productDetails = resources.productDetails || { data: {} };
  const productDetailsRecommendations =
    productDetails.data.recommendations || {};

  return {
    forcedRecommendation:
      recommendations.forcedRecommendation || initialState.forcedRecommendation,
    hasHYFRecommendations:
      recommendations.hasHYFRecommendations ||
      initialState.hasHYFRecommendations,
    tescoRecommendations:
      getInflatedRecommendations(recommendations.tescoRecommendations) ||
      getInflatedRecommendations(productDetailsRecommendations.data) ||
      initialState.tescoRecommendations,
    ibycRecommendations:
      recommendations.ibycRecommendations || initialState.ibycRecommendation
  };
};

export const getFirstRecommendation = recommendations => {
  if (!recommendations) {
    return;
  }

  const keys = Object.keys(recommendations);
  const firstKey = keys[0];

  if (
    (firstKey === 'empty' || firstKey === 'error') &&
    recommendations[firstKey]
  ) {
    return;
  }

  return keys.length ? recommendations[firstKey] : undefined;
};

export const getHasHYFRecommendations = ({ recommendations }) =>
  recommendations?.hasHYFRecommendations;

export const getAllTrexRecommendations = ({ recommendations }) => {
  const { tescoRecommendations } = recommendations || {};

  if (!tescoRecommendations) {
    return undefined;
  }

  const keys = Object.keys(tescoRecommendations);
  const firstKey = keys[0];

  if (
    (firstKey === 'empty' || firstKey === 'error') &&
    tescoRecommendations[firstKey]
  ) {
    return;
  }

  return tescoRecommendations || undefined;
};

export const getTrexRecommendations = (
  { recommendations: { tescoRecommendations } },
  targetTPNB
) => {
  if (!tescoRecommendations) {
    return;
  }

  if (targetTPNB) {
    const recommendations = tescoRecommendations[targetTPNB];

    return recommendations && recommendations.productItems;
  }

  const firstRecommendation = getFirstRecommendation(tescoRecommendations);

  return firstRecommendation && firstRecommendation.productItems;
};

export const getHasTrexError = ({
  recommendations: { tescoRecommendations }
}) => {
  return !!tescoRecommendations && !!tescoRecommendations.error;
};

export const getIsTrexEmpty = ({
  recommendations: { tescoRecommendations }
}) => {
  return !!tescoRecommendations && !!tescoRecommendations.empty;
};

export const getTrexTitle = (
  { recommendations: { tescoRecommendations } },
  targetTPNB
) => {
  if (!tescoRecommendations) {
    return;
  }

  let recommendation;

  if (targetTPNB) {
    recommendation = tescoRecommendations[targetTPNB];
  } else {
    recommendation = getFirstRecommendation(tescoRecommendations);
  }

  if (recommendation) {
    return recommendation.pageInformation.title || '';
  }
};

export const getTrexPageId = (
  { recommendations: { tescoRecommendations } },
  targetTPNB,
  recommendationsGroupName = null
) => {
  if (!tescoRecommendations) {
    return;
  }

  const recommendationsKey = targetTPNB || recommendationsGroupName;

  let recommendation;

  if (recommendationsKey) {
    recommendation = tescoRecommendations[recommendationsKey];
  } else {
    recommendation = getFirstRecommendation(tescoRecommendations);
  }

  if (recommendation) {
    return recommendation.pageInformation.pageId || '';
  }
};
