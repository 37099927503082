import { RECEIVE_TAXONOMY_STATE, NEW_TAXONOMY_PATH, RECEIVE_TAXONOMY } from '#/constants/action-types';
import { EVENTS } from '#/constants/common';
import { TAXONOMY } from '#/constants/spa-resource';
import { getCurrentUrl, getLanguageLink } from './app';
import { RESOURCES_RECEIVED } from './resources';
import { getResourceData } from '#/resources/resource-util';
import url from 'url';
import { TaxonomyState, NavList, NavItem, Tab } from 'custom-typings/redux-store/taxonomy.defs';
import { ExperimentConfig } from '#/experiments/oop-1919/constants';

const getNewTaxonomyState = (state, value): TaxonomyState => ({
  ...state,
  taxonomyState: {
    ...state.taxonomyState,
    ...value,
  },
});

const recieveTaxonomyResource = (state: TaxonomyState, navList: NavList): TaxonomyState => ({
  ...state,
  navList,
});

export function getNavList({ taxonomy: { navList } }): NavList {
  return navList && Array.isArray(navList) ? navList : [];
}

export function getServerSideExperiment({ taxonomy: { experiment } }): ExperimentConfig {
  return experiment;
}

const constructFromTaxonomyPath = (state, path): TaxonomyState => {
  const navList = getNavList(state);
  const parts = path.split('/').slice(1, -1); // we don't want leading undefined or most specific level
  const superdepartmentUrl = parts[0] && '/' + parts[0];
  const departmentUrl = parts[1] && `/${parts[0]}/${parts[1]}`;
  const superdepartment = navList.find(item => item.url === superdepartmentUrl);
  const department = superdepartment && superdepartment.children?.find(item => item.url === departmentUrl);

  return {
    ...state,
    taxonomyState: {
      superdepartment,
      department,
    },
  };
};

export default function taxonomy(state = {}, action): TaxonomyState {
  switch (action.type) {
    case RECEIVE_TAXONOMY:
      return recieveTaxonomyResource(state, action.value.navObj ? action.value.navObj : action.value);
    case RESOURCES_RECEIVED:
      if (action.resources && action.resources[TAXONOMY]) {
        let navList = action.resources[TAXONOMY].data;

        if (!Array.isArray(navList)) navList = navList?.navObj;

        return recieveTaxonomyResource(state, navList);
      }
      return state;
    case RECEIVE_TAXONOMY_STATE:
      return getNewTaxonomyState(state, action.value);
    case NEW_TAXONOMY_PATH:
      return constructFromTaxonomyPath(state, action.path);
    default:
      return state;
  }
}

export const getDefaultStateFromProps = (props): TaxonomyState => {
  const taxonomyResource = getResourceData(props.resources, TAXONOMY) || [];

  return {
    taxonomyState: {
      department: props.department || null,
      superdepartment: props.superdepartment || null,
      isTaxonomyLazyLoadedNow: false,
    },
    navList: taxonomyResource.navObj ?? taxonomyResource,
    experiment: taxonomyResource.experimentObj,
  };
};

export function getTaxonomyLazyLoadedStatus(state): boolean {
  return state.taxonomy && state.taxonomy.taxonomyState && state.taxonomy.taxonomyState.isTaxonomyLazyLoadedNow;
}

export const getFirstNavItem = (state: { taxonomy: { navList: NavList } }): NavItem => getNavList(state)[0];

export function getSelectedNavigationTab(state, tabs, cmsNav): Tab {
  const currentUrl = getCurrentUrl(state);
  const selectedTab = Object.keys(tabs).find(key => {
    const tabUrl = tabs[key].url;
    const matcher = new RegExp(getLanguageLink(state, tabUrl || ''));

    return (
      (tabUrl && currentUrl.match(matcher)) ||
      (key === EVENTS && cmsNav && cmsNav.link && currentUrl === url.parse(cmsNav.link).pathname)
    );
  });
  return selectedTab ? tabs[selectedTab] : undefined;
}
