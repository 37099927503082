import classnames from 'classnames';
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import ImageWrapper from '#/components/products/image-wrapper';
import { basicEvent } from '#/analytics/types/basic';
import analyticsBus from '#/analytics/analyticsBus';
import Carousel from '#/components/carousel';
import LazyImage from '#/components/image/lazy-image';
import { getIsMobile } from '#/reducers/app';
import { connect } from '#/lib/render/connect-deep-compare';
import Icon from '#/components/shared/icon';
import helpers from '#/lib/decorators/helpers';
import { toArray } from '#/utils/immutable-utils';

const mapStateToProps = state => ({
  isMobile: getIsMobile(state)
});

@helpers(['t'])
@connect(mapStateToProps)
export default class ImageViewer extends Component {
  static propTypes = {
    available: PropTypes.bool,
    defaultImageUrl: PropTypes.string.isRequired,
    hasPromotion: PropTypes.bool,
    id: PropTypes.string,
    images: PropTypes.array,
    isMobile: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    title: PropTypes.string,
    zoomable: PropTypes.bool
  };

  constructor(props) {
    super(props);

    this.state = {
      defaultImage: {
        currentIndex: 0,
        url: props.defaultImageUrl
      }
    };
    this.notifiedAnalytics = false;
  }

  onImageChange = () => {
    if (!this.notifiedAnalytics) {
      basicEvent(analyticsBus, {
        type: 'pdp',
        value: 'change image',
        action: 'now'
      });
    }

    this.notifiedAnalytics = true;
  };

  handleClick = (event, position, imageUrl) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      defaultImage: {
        currentIndex: position,
        url: imageUrl
      }
    });
    this.onImageChange();
  };

  renderImageFilmStrip(images, title) {
    const imageStrip = images.map((imageUrl, position) => (
      <button className="image-film__container" key={position} type="button">
        <LazyImage
          alt={this.getImageTitle(position, title)}
          className={classnames('image-film', {
            selected: position === this.state.defaultImage.currentIndex
          })}
          src={imageUrl}
          onClick={event => this.handleClick(event, position, imageUrl)}
        />
      </button>
    ));

    return (
      <Carousel
        backgroundColor="white"
        icon="chevron_right"
        goToNext={this.goToNext}
        goToNextTitle={this.props.t('product-tile:film-strip.go-to-next')}
        goToPrev={this.goToPrev}
        goToPrevTitle={this.props.t('product-tile:film-strip.go-to-previous')}
        notifyAnalytics={this.onImageChange}
        pressScrollButton={this.pressScrollButton}
      >
        {imageStrip}
      </Carousel>
    );
  }

  getFirst(operationFunc, isPrevious) {
    const currentImageIndex = this.state.defaultImage.currentIndex;

    return this.clampFirst(currentImageIndex, operationFunc, isPrevious);
  }

  setFirst(event, newFirst) {
    const first = this.state.defaultImage.currentIndex;

    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (first === newFirst) {
      return;
    }

    this.setState({
      defaultImage: {
        currentIndex: newFirst,
        url: toArray(this.props.images)[newFirst]
      }
    });
  }

  goToPrev = event => {
    this.setFirst(
      event,
      this.getFirst(value => --value, true)
    ); // eslint-disable-line no-param-reassign
  };

  goToNext = event => {
    this.setFirst(
      event,
      this.getFirst(value => ++value)
    ); // eslint-disable-line no-param-reassign
  };

  clampFirst(value, operationFunc, isPrevious) {
    const images = this.props.images;
    const totalImages = images.length - 1;
    const resetToStart = value >= totalImages && !isPrevious;

    if (value === 0 && isPrevious) {
      return 0;
    }

    if (value < 0 || resetToStart) {
      this.scrollToLeft();

      return 0;
    }

    if (value <= totalImages) {
      return operationFunc(value);
    }

    return value;
  }

  scrollToLeft = () =>
    setTimeout(
      () =>
        (document.getElementsByClassName('carousel__list')[0].scrollLeft = 0),
      0
    );

  pressScrollButton(scrollTo) {
    const scrollAmount = 80; // scroll amount considering the width of image only for Tab and desktop
    const newScrollPos = scrollTo > 0 ? scrollAmount : -1 * scrollAmount;

    requestAnimationFrame(() => {
      if (this.contentWrapper && this.contentWrapper.current) {
        this.contentWrapper.current.scrollLeft = newScrollPos;
      }
    });
    this.handleScrollChange(scrollTo);
    this.notifyAnalytics();
  }

  renderButton = (direction, clickHandler, title) =>
    this.props.isMobile && (
      <button
        className={classnames(
          `carousel-button ${direction}`,
          'background-white'
        )}
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();
          clickHandler(event);
          this.onImageChange();
        }}
        type="button"
      >
        <span className="visually-hidden">{title}</span>
        <span className="carousel-button--icon">
          <Icon name="chevron_right" />
        </span>
      </button>
    );

  renderImageCount = images =>
    this.props.isMobile && (
      <span className="image-film--count">
        {`${this.state.defaultImage.currentIndex + 1}/${images.length}`}
      </span>
    );

  getImageTitle = (index, title) =>
    this.props.t('product-tile:film-strip.image-alt-text', {
      index: index + 1,
      title
    });

  render() {
    const { available, id, images, hasPromotion, title } = this.props;
    const hasMultipleImages = images && images.length > 1;
    const imageAlt = hasMultipleImages
      ? this.getImageTitle(this.state.defaultImage.currentIndex, title)
      : title;

    const imageWrapper = (
      <ImageWrapper
        alt={imageAlt}
        alwaysDisplayImage={!hasMultipleImages}
        available={available}
        hasPromotion={hasPromotion}
        id={id}
        image={this.state.defaultImage.url}
        images={images}
        isLazyImage={false}
        isPDP
        isUnavailable={!available}
        title={title}
        zoomable={this.props.zoomable}
      />
    );

    return (
      <div className="product-image--wrapper">
        {hasMultipleImages ? (
          <Fragment>
            {this.renderButton(
              'backward',
              this.goToPrev,
              this.props.t('product-tile:film-strip.go-to-previous')
            )}
            {imageWrapper}
            {this.renderImageCount(images)}
            {this.renderButton(
              'forward',
              this.goToNext,
              this.props.t('product-tile:film-strip.go-to-next')
            )}
            <div className="multiple-image__wrapper">
              {this.renderImageFilmStrip(images, title)}
            </div>
          </Fragment>
        ) : (
          imageWrapper
        )}
      </div>
    );
  }
}
