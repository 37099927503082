import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FavoriteHeartIcon from '../shared/favorite-heart-icon';
import RenderRemoveItem from '../shared/render-remove-item';
import { REMOVE_FAVOURITE_MODAL } from '#/constants/modal-names';
import { isInFavourites } from '#/selectors/item';

const ProductTileActions = ({
  enableFavorite,
  item,
  removeType,
  showHeartIcon
}) => {
  const renderHeartIcon =
    showHeartIcon &&
    enableFavorite &&
    !item.isWhyNotTry &&
    isInFavourites(item);

  const isRemoveTypeAvailable = removeType !== 'none';

  return (
    <div
      className={classNames('product-tile-actions--has-no-text', {
        'product-tile-actions--has-remove-button': isRemoveTypeAvailable
      })}
    >
      {isRemoveTypeAvailable && (
        <RenderRemoveItem
          item={item}
          removeFromList={removeType === 'favorite'}
          removeActionModal={
            removeType === 'favorite' ? REMOVE_FAVOURITE_MODAL : null
          }
        />
      )}

      {
        <FavoriteHeartIcon
          item={item}
          className="favourite-icon"
          renderFilledHeartIcon={renderHeartIcon}
          enableFavorite={enableFavorite}
        />
      }
    </div>
  );
};

ProductTileActions.defaultProps = {
  enableFavorite: true,
  removeType: 'none',
  showHeartIcon: true,
  tileType: 'horizontal'
};

ProductTileActions.propTypes = {
  enableFavorite: PropTypes.bool,
  item: PropTypes.object.isRequired,
  removeType: PropTypes.oneOf(['favorite', 'trolley', 'none']),
  showHeartIcon: PropTypes.bool,
  tileType: PropTypes.string
};

export default ProductTileActions;
