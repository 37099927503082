/* eslint-disable @typescript-eslint/no-use-before-define */
import { Action } from 'redux';
import { batchActions } from 'redux-batched-actions';
import uuidv4 from 'uuid/v4';
import { addApmData } from '#/lib/apm';
import analyticsBus from '#/analytics/analyticsBus';
import commonActionRejectionsHandler, { openAuthRefreshModal } from '#/actions/common-action-rejections-handler';
import { BASKET_LIMIT_BREACH, BASKET_WITHIN_LIMIT } from '#/analytics/constants';
import { fireAddAllAnalytics } from '#/analytics/helpers/orders';
import { updateLocations } from '#/actions/location-action-creators';
import { syncProductWithTrolley } from '#/actions/product-details-action-creators';
import { fetchResources, updateResource } from '#/actions/resources-action-creators';
import { onReceiveTrolleyItem, updateTrolleyItems } from '#/actions/results-actions';
import { setSearchTerm } from '#/actions/search-action-creators';
import { getTaxonomy } from '#/actions/taxonomy-action-creators';
import {
  buildProductAnalyticsPayload,
  dispatchProductChangeAnalytics,
  notifyAnalytics,
} from '#/actions/trolley/analytics-action-creators';
import { chooseStickyBar, openModal } from '#/actions/ui-action-creators';
import * as actionTypes from '#/constants/action-types';
import { STORE_MAP_TOOGLE } from '#/constants/action-types';
import { BLOCKED_ADDRESS, POSTCODE_ERROR_MESSAGE, UNDELIVERABLE_ADDRESS } from '#/constants/error-codes';
import { CHANGE_SLOT_TYPE_MODAL, GENERIC_ERROR_MODAL, ITEMS_UNAVAILABLE_MODAL } from '#/constants/modal-names';
import { CHANGING_DELIVERY_ADDRESS } from '#/constants/slot-warning-modal-types';
import { TROLLEY_CONTENTS } from '#/constants/spa-resource';
import { DO_NOT_SUBSTITUTE, FIND_SUITABLE_ALTERNATIVE } from '#/constants/substitution-options';
import { Dispatch, GetStore } from '#/custom-typings/redux-store/common';
import {
  AddItemToTrolleyUserHistory,
  ClearTrolleyItemActionAnalytics,
  ClearTrolleyUserHistory,
  DecrementRequestsInProgress,
  IncrementRequestsInProgress,
  RemoveItemFromTrolleyUserHistory,
  SetAddFromOrderRequestInProgress,
  SetTrolleyRequestInProgressAction,
} from '#/custom-typings/redux-store/trolley.actions.defs';
import { Items } from '#/custom-typings/redux-store/trolley.defs';
import { request } from '#/lib/client-fetch';
import { ErrorWithStatusCode } from '#/lib/errors/error-with-status';
import { Item } from '#/lib/records/item';
import { ItemPayload } from '#/lib/records/item-payload';
import {
  adjustNumberOfItems,
  setCustomerUnitChoice,
  setNumberOfItems,
  withCatchWeight,
} from '#/lib/records/item-utils';
import { CustomerUnitChoice } from '#/lib/records/item.defs';
import {
  adjustPayloadSubstitutionValueForCore,
  findAndSetTrolleyItems,
  getById,
  itemsToMap,
  merge,
  payloadDiff,
  preferencePayload,
  preferencePayloadDiff,
  setById,
} from '#/lib/records/product-utils';
import { Preference, PreferencePayload } from '#/lib/records/product-utils/preference-payload';
import { setGroupByProductsLimit } from '#/lib/trolley/trolley-utils';
import { addToUrlAndDispatchModal } from '#/lib/url/modal-utils';
import { updateParamsInUrl } from '#/lib/url/url-utils';
import {
  getCurrency,
  getCurrentUrl,
  getLanguageLink,
  getTimezone,
  getAppRegion,
  getApigeeMangoApiKey,
  getApigeeMangoEndpoint,
} from '#/reducers/app';
import { getAllTrexRecommendations } from '#/reducers/recommendations';
import { RESOURCES_RECEIVED } from '#/reducers/resources';
import { getFavCarouselItems } from '#/reducers/results';
import { getSelectedShoppingMethod } from '#/reducers/slot';
import { getDisplayBusinessAddressModal } from '#/reducers/ui';
import { ResetVatInvoiceDetailsResponseJSON } from '#/routes/trolley/reset-vat-invoice-details';
import { VatInvoiceDetailsResponseJSON } from '#/routes/trolley/vat-invoice-details';
import {
  getBaseProductId,
  getCatchWeight,
  getPieces,
  getProductId,
  getQuantity,
  getUnit,
  getCustomerUnitChoice,
  getIsForSale,
  getGroupLimitReached,
  getItemLimitReached,
  getProductType,
} from '#/selectors/item';
import {
  getAnalyticsBasketId,
  getAnalyticsMarketplaceBasketId,
  getHasBasketBreachedByVolumeOrWeight,
  getItems,
  getItemsToUpdate,
  getLatestItems,
  getPreviousItems,
  getRadishItems,
  getTrolleyRequestInProgress,
} from '#/selectors/trolley';
import { cloneItems } from '#/utils/clone-items';
import { cloneItem } from '#/utils/clone-item';
import { hasUpdateErrors } from '#/utils/trolley-utils';
import { debounce } from '#/utils/misc';
import { emitBasketOp } from '#/analytics/bertie/events';
import { ProductData } from '#/analytics/bertie/types';
import { refreshBasket } from '#/lib/webview-notifier/refresh-basket';
import { showOnDemandUnavailableModal } from '#/selectors/slot';
import { MARKETPLACE_PRODUCT } from '#/constants/common';
import { showBookSlotModal } from '#/actions/book-slot-modal-action-creators';
import { CATCH_WEIGHT_PRODUCTS } from '#/constants/display-types';
import handleTrolleyResponse from '#/lib/trolley/handle-trolley-response';
import { transformSplitBaskets } from '#/lib/records/split-basket';
import isValid from '#/lib/validation/is-valid';
import { addAllToBasket } from '#/lib/requests/add-all-to-basket';
import { getAccessToken, getAtrc } from '#/reducers/user';
import { Trolley } from '#/lib/records/trolley.defs';
import { getResults } from '#/selectors/results';

type Updates = {
  errorCode: string;
  id: number;
  isAlcoholic: boolean | null;
  message: string;
  status: string;
  successful: boolean;
};

type BasketData = Trolley & {
  updates: {
    items: Array<Updates>;
  };
};
import { setRecsCarouselDetailsOnItemAdd } from '#/experiments/oop-2349/actions';
import { logSponsoredMedia } from '#/utils/log-sponsored-media';
import { showMarketPlaceProductModal } from '#/actions/market-place-modal-action-creators';

// Move to /web/routes/slots/change-address when it is converted to TS
type SlotsChangeAddressResponse = {
  changeSlotInfo?: Record<string, unknown>;
  errors?: Array<ServerResponseError>;
  preferencesData?: Record<string, unknown>;
  storeId?: any;
  locationId?: any;
  locations?: any;
  resources?: any;
  trolley?: any;
};

// Move to analytics-action-creators when it is converted to TS
export type AnalyticsOptions = {
  enableMissedOfferTracking?: boolean;
  gridPos?: number;
  identifier?: string;
  sendAnalytics?: boolean;
  position?: number;
};

// Move to slot-action-creators when it is converted to TS
type ChangeSlotInfo = {
  addressId: string;
  slotGroup: number;
  end: string;
  modalType: any;
  shoppingMethod: string;
  start: string;
};

type StringParams = {
  [param: string]: string;
};

type GetTrolleyParams =
  | {
      isAmendBasket: boolean;
    }
  | StringParams;

type VatInvoiceDetailsRequestParams = {
  vatNumber: string;
  company: string;
  address: string;
};

export const setTrolleyRequestInProgress = (
  inProgress: boolean,
  showPendingQty = true,
): SetTrolleyRequestInProgressAction => ({
  type: actionTypes.TROLLEY_REQUEST_IN_PROGRESS,
  value: inProgress,
  showPendingQty,
});

export const setAddFromOrderRequestInProgress = (
  orderNo: string,
  inProgress: boolean,
): SetAddFromOrderRequestInProgress => {
  if (typeof orderNo !== 'string' || orderNo === '') {
    throw new Error('orderNo should be numeric string');
  }

  return {
    type: actionTypes.ADD_FROM_ORDER_REQUEST_IN_PROGRESS,
    value: { [orderNo]: inProgress },
  };
};

const incrementRequestsInProgress = () => (dispatch: Dispatch): IncrementRequestsInProgress =>
  dispatch({ type: actionTypes.REQUEST_STARTED });

const decrementRequestsInProgress = () => (dispatch: Dispatch): DecrementRequestsInProgress =>
  dispatch({ type: actionTypes.REQUEST_FINISHED });

/**
 * Trolley history functions - used for keeping track of the last item that is added to the trolley
 */

export const addItemToTrolleyUserHistory = (value: string): AddItemToTrolleyUserHistory => ({
  type: actionTypes.TROLLEY_USER_HISTORY_PUSH,
  value,
});

/**
 * value could be null if the method is called from exception handler
 * which has no context
 */
export const removeItemFromTrolleyUserHistory = (value: string | null): RemoveItemFromTrolleyUserHistory => ({
  type: actionTypes.TROLLEY_USER_HISTORY_POP,
  value,
});

export const clearTrolleyUserHistory = (): ClearTrolleyUserHistory => ({
  type: actionTypes.TROLLEY_USER_HISTORY_CLEAR,
});

export const addAllFromOrder = (orderNo: string, analyticsOptions: AnalyticsOptions = {}) => (
  dispatch: Dispatch,
  getState: GetStore,
): void => {
  const state = getState();
  dispatch(setTrolleyRequestInProgress(true, false));
  dispatch(setAddFromOrderRequestInProgress(orderNo, true));
  request
    .put(getLanguageLink(state, '/trolley/add-from-order'), {
      body: JSON.stringify({ orderNo }),
      rejectOnClientError: true,
    })
    .then(data => {
      if (data) {
        dispatch(updateTrolley(data, false, true));

        const items = data.updates.items;

        if (hasUpdateErrors(items)) {
          dispatch(openModal(ITEMS_UNAVAILABLE_MODAL));
        }

        fireAddAllAnalytics(data, state, analyticsOptions);
      }
    })
    .catch(err => {
      commonActionRejectionsHandler(err, dispatch);
    })
    .then(() => {
      dispatch(setTrolleyRequestInProgress(false));
      dispatch(setAddFromOrderRequestInProgress(orderNo, false));
    });
};

/**
 * Empties the trolley on a successful response from
 * DELETE /trolley/items
 */
export const emptyTrolley = (analyticsOptions: AnalyticsOptions = {}) => {
  return (dispatch: Dispatch, getState: GetStore): Promise<void> => {
    const state = getState();
    const basketBreachedOrInLimit = getHasBasketBreachedByVolumeOrWeight(state)
      ? BASKET_LIMIT_BREACH
      : BASKET_WITHIN_LIMIT;

    dispatch({ type: actionTypes.START_TROLLEY_UPDATE });

    return request
      .del(getLanguageLink(state, '/trolley/items'))
      .then(res => {
        if (res) {
          dispatch({ type: actionTypes.TROLLEY_EMPTIED });
          dispatch(getTrolley());

          const products = [...getItems(state).values()];

          dispatch(clearTrolleyUserHistory());

          emitBasketOp({
            basketId: getAnalyticsBasketId(state),
            secondBasketId: getAnalyticsMarketplaceBasketId(state),
            basketFullness: basketBreachedOrInLimit,
            interactionType: 'removeAllProducts',
            flag: {
              isOpen: false,
            },
            product: products.map(item =>
              buildProductAnalyticsPayload(item, getPieces(item), getCurrency(state), analyticsOptions),
            ) as ProductData[],
            uniqueProducts: 0,
          });
        }
      })
      .catch(res => {
        commonActionRejectionsHandler(res, dispatch);
      });
  };
};

export const extendSlot = (url: string) => {
  return (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: actionTypes.START_UPDATE_SLOT_RESERVATION_EXPIRY,
    });

    return request
      .post(url)
      .then(extendResult => {
        const updatedSlot = extendResult.data.slot;

        dispatch({
          type: actionTypes.COMPLETE_UPDATE_SLOT_RESERVATION_EXPIRY,
          value: updatedSlot.reservationExpiry,
        });

        dispatch(chooseStickyBar());
      })
      .catch(() => {
        dispatch({
          type: actionTypes.COMPLETE_UPDATE_SLOT_RESERVATION_EXPIRY,
        });
      });
  };
};

/**
 * Sets the UI state to the last fetched trolley state.
 */
export const revertTrolley = () => {
  return (dispatch: Dispatch, getState: GetStore): void => {
    const radishItems = getLatestItems(getState().trolley);

    dispatch({
      type: actionTypes.RECEIVE_RESULT_ITEMS,
      value: radishItems,
    });
    dispatch(updateTrolleyItems(radishItems));
    dispatch(syncProductWithTrolley());
  };
};

/**
 * Fetches the latest trolley state from the server
 */
// eslint-disable-next-line
export const getTrolley = (cb?: (args: any) => any, params: GetTrolleyParams = {}) => async (
  dispatch: Dispatch,
  getState: GetStore,
): Promise<void> => {
  try {
    await dispatch(fetchResources([TROLLEY_CONTENTS], params));
  } catch (error) {
    // no catch
  }

  const resources = getState().resources;

  if (resources.trolleyContents && resources.trolleyContents.data && typeof cb === 'function') {
    cb(resources.trolleyContents.data);
  }
};

export const clearTrolleyItemActionAnalytics = (itemId: string): ClearTrolleyItemActionAnalytics => ({
  type: actionTypes.CLEAR_TROLLEY_ITEM_ACTION_ANALYTICS,
  itemId,
});

export const setTrolleyItemActionAnalytics = (itemId: string, analyticsOptions?: AnalyticsOptions) => {
  return (dispatch: Dispatch): void => {
    if (analyticsOptions) {
      dispatch({
        type: actionTypes.SET_TROLLEY_ITEM_ACTION_ANALYTICS,
        itemId,
        data: analyticsOptions,
      });
    } else {
      dispatch(clearTrolleyItemActionAnalytics(itemId));
    }
  };
};

const updateTescoRecommendations = (state: Store, dispatch: Dispatch, items: Items | Array<Item>): void => {
  const tescoRecommendations = getAllTrexRecommendations(state);

  if (!tescoRecommendations) {
    return;
  }

  const recommendationsWithProductItems = Object.keys(tescoRecommendations).reduce((acc, targetTPNB) => {
    const recommendation = tescoRecommendations[targetTPNB];
    const recommendationProductItemsArr = [...recommendation.productItems.values()];
    const itemsArr = items instanceof Map ? [...items.values()] : items;
    const productItemsArr = findAndSetTrolleyItems(recommendationProductItemsArr, itemsArr);
    const productItems = itemsToMap(productItemsArr);
    acc[targetTPNB] = {
      ...recommendation,
      productItems,
    };
    return acc;
  }, {});

  dispatch({
    type: actionTypes.UPDATE_TESCO_RECOMMENDATIONS,
    value: recommendationsWithProductItems,
  });
};

export const updateFavoritesCarousel = (state: Store, dispatch: Dispatch, items: Items | Array<Item>): void => {
  const favCarouselItems = getFavCarouselItems(state);

  if (favCarouselItems.length > 0) {
    const itemsArr = items instanceof Map ? [...items.values()] : items;

    dispatch({
      type: actionTypes.UPDATE_FAVORITES_CAROUSEL_ITEMS,
      value: {
        items: findAndSetTrolleyItems(favCarouselItems, itemsArr),
      },
    });
  }
};

export const dispatchItems = (items: Items | Array<Item>) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actionTypes.RECEIVE_RESULT_ITEMS,
      value: items,
    });
    dispatch(updateTrolleyItems(items));
    dispatch(syncProductWithTrolley());
  };
};

export const updateTrolley = (trolley: Store['trolley']['trolley'], revert?: boolean, forceUpdate?: boolean) => {
  return (dispatch: Dispatch, getState: GetStore): void => {
    dispatch({
      type: actionTypes.RECEIVE_RAW_TROLLEY,
      value: trolley,
    });

    const items = itemsToMap(trolley.items);

    dispatch({
      type: actionTypes.RECEIVE_TROLLEY_ITEMS,
      value: items,
    });
    const state = getState();
    const trolleyItems = getItems(state);

    dispatch(onReceiveTrolleyItem(trolleyItems));
    dispatch(syncProductWithTrolley());

    if (forceUpdate) {
      dispatch(dispatchItems(items));
    }

    if (revert) {
      dispatch(revertTrolley());
    }

    // a change in the trolley or slot may affect which (if any) sticky bar to show
    dispatch(chooseStickyBar());
  };
};

/**
 * Checks UI state for differences and update the server to match UI state
 * or dispatches a new items map removing zeroed items all is in sync.
 */
export const sendNewUpdates = (originalItem: Item) => {
  return (dispatch: Dispatch, getState: GetStore): void => {
    const state = getState();

    dispatch({
      type: actionTypes.UPDATE_BBLG_PRODUCT,
    });
    const trolleyItems = getItems(state);
    const latestItems = getLatestItems(state);

    const payload = payloadDiff(latestItems, trolleyItems);

    if (payload.length) {
      const latestBasketItems = Array.isArray(latestItems) ? latestItems : Object.values(latestItems);
      const hasReachedGroupLimit = payload.length
        ? latestBasketItems.some(
            item => (item as Item)?.product.id === payload[0].id && (item as Item)?.groupLimitReached,
          )
        : false;

      if (hasReachedGroupLimit) {
        dispatch(revertTrolley());
        dispatch({
          type: actionTypes.RESOLVE_TROLLEY_UPDATE,
        });
      } else {
        dispatch(sendTrolleyItems(payload, getCurrentUrl(state), originalItem));
      }
    } else {
      dispatch({
        type: actionTypes.RESOLVE_TROLLEY_UPDATE,
      });
    }
    showBookSlotModal(originalItem, state, dispatch);
  };
};

/**
 * Sends item quantities to the server.
 */
export const sendTrolleyItems = (items: Array<ItemPayload>, returnUrl: string, originalItem: Item) => {
  return (dispatch: Dispatch, getState: GetStore): Promise<void> => {
    const state = getState();

    if (getTrolleyRequestInProgress(state) || items.length < 1) {
      return Promise.resolve();
    }

    const options = {
      items,
      returnUrl,
    };

    dispatch(incrementRequestsInProgress());
    dispatch(setTrolleyRequestInProgress(true));

    const requestedAt = Date.now();

    const trolleyUpdateUrl = '/trolley/items?_method=PUT';

    return request
      .put(getLanguageLink(state, trolleyUpdateUrl), {
        body: JSON.stringify(options),
      })
      .then(res => {
        dispatch(setTrolleyRequestInProgress(false));
        const radishItems = getRadishItems(state);
        const clientBasketItems = getItems(state);

        // Notify app webview
        refreshBasket();

        const itemUpdates = (res.updates && res.updates.items) || [];

        if (itemUpdates.length > 0) {
          const invalids = itemUpdates
            .filter(response => `${response.status}`.toLowerCase() === 'invalid')
            .reduce((responseStatuses, response) => {
              responseStatuses[response.id] = response.status;

              return responseStatuses;
            }, {});

          if (Object.keys(invalids).length > 0) {
            const withoutInvalids = getItems(state).filter(item => invalids[getProductId(item)] === undefined);

            dispatch(dispatchItems(withoutInvalids));
          }
        }

        dispatch(updateTrolley(res));
        dispatch(updateResource(TROLLEY_CONTENTS, res, requestedAt));
        dispatch(sendNewUpdates(originalItem));
        dispatch(dispatchProductChangeAnalytics(items, false, originalItem, radishItems, clientBasketItems));
        dispatch(notifyAnalytics());
        dispatch(decrementRequestsInProgress());
      })
      .catch(res => {
        console.error(res); //Added for the reason below

        // TODO: DISCUSS: Exception handling anti-pattern?
        // We're using exceptions to catch legitimate code paths.
        // This catch is swallowing _actual_ exceptions making dev hard..!

        // Either the response should be a 200 with a 'success: false, errors: []' object,
        // or we need to be very specific with the errors we're catching here.

        // I'd never have known about the swallowed exceptions it without
        // 'Pause on caught exceptions'!

        dispatch(removeItemFromTrolleyUserHistory(null));
        dispatch(setTrolleyRequestInProgress(false));
        dispatch(decrementRequestsInProgress());

        if (res.status === 401) {
          // TODO: Remove this temporary workaround. See LEGO-4187.
          return openAuthRefreshModal(dispatch);
        }

        commonActionRejectionsHandler(res, dispatch, () => {
          dispatch(revertTrolley());
          dispatch({
            type: actionTypes.RESOLVE_TROLLEY_UPDATE,
          });
        });
      });
  };
};

const sendUpdatesItemPreference = () => {
  return (dispatch: Dispatch, getState: GetStore): void => {
    const state = getState();
    const items = preferencePayloadDiff(getLatestItems(state), getItems(state));

    if (items.length) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      dispatch(updateTrolleyPreference(items));
    } else {
      dispatch({
        type: actionTypes.RESOLVE_TROLLEY_UPDATE,
      });
    }
  };
};

const updateTrolleyPreference = (items: Array<PreferencePayload>) => {
  return (dispatch: Dispatch, getState: GetStore): Promise<void> => {
    const state = getState();

    if (getTrolleyRequestInProgress(state) || items.length < 1) {
      return Promise.resolve();
    }

    const options = {
      items,
      loggedInAction: 'update-trolley-item',
      returnUrl: getCurrentUrl(state),
    };

    dispatch(setTrolleyRequestInProgress(true));

    return request
      .post(getLanguageLink(state, '/update-user-item-preference'), {
        body: JSON.stringify(options),
      })
      .then(res => {
        dispatch(setTrolleyRequestInProgress(false));

        dispatch({
          type: actionTypes.UPDATE_LINE_ITEM_SUBSTITUTION_PREFERENCES,
          value: res.items,
        });
        dispatch(sendUpdatesItemPreference());
      })
      .catch(res => {
        dispatch(setTrolleyRequestInProgress(false));

        commonActionRejectionsHandler(res, dispatch, () => {
          dispatch(revertTrolley());
          dispatch({ type: actionTypes.RESOLVE_TROLLEY_ITEM_UPDATE });
        });
      });
  };
};

const debounceTrolleyUpdateRequest = debounce(
  (dispatch: Dispatch, payload: Array<ItemPayload>, originalItem: Item, currentUrl: string) => {
    dispatch(sendTrolleyItems(payload, currentUrl, originalItem));
  },
  66,
);

const debounceTrolleyPreferenceRequest = debounce((dispatch: Dispatch, payload: Array<PreferencePayload>): void => {
  dispatch(updateTrolleyPreference(payload));
}, 66);

const sendTrolleyPreferenceItems = (items: Items | Array<Item>, originalItem: Item, preference: Preference) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actionTypes.START_TROLLEY_ITEM_UPDATE,
    });
    dispatch(dispatchItems(items));
    const payload = preferencePayload(originalItem, preference);

    debounceTrolleyPreferenceRequest(dispatch, payload);
  };
};

const updateItemPreference = (item: Item, fn: (item: Item) => Item, preference: Preference) => {
  return (dispatch: Dispatch, getState: GetStore): void => {
    const id = getProductId(item);

    const items = getItems(getState().trolley);
    let updatedItems;

    updatedItems = cloneItems(items);
    const updatedItem = getById(updatedItems, id);
    if (updatedItem) {
      updatedItems = setById(updatedItems, fn(updatedItem));
    } else {
      // If item is not present in trolley it should be added at the top
      updatedItems = [fn(item), ...updatedItems];
    }

    dispatch(sendTrolleyPreferenceItems(updatedItems, item, preference));
  };
};

export const updateLineItemsPreference = (item: Item, preference: Preference) => {
  return (dispatch: Dispatch): void => {
    dispatch(
      updateItemPreference(
        item,
        updatingItem => {
          const { pickerNote, subs } = preference;
          return cloneItem(updatingItem, i => {
            i.pickerNote = pickerNote;
            i.substitutionOption = subs ? FIND_SUITABLE_ALTERNATIVE : DO_NOT_SUBSTITUTE;
          });
        },
        preference,
      ),
    );
  };
};

/**
 * Updates the trolley store with a new map of items.
 */
export const sendRawItems = (rawItems: Items | Array<Item>, itemsToUpdate: Items | Array<Item>, originalItem: Item) => {
  return (dispatch: Dispatch, getState: GetStore): void => {
    const state: Store = getState();

    const itemsToUpdateArray = itemsToUpdate instanceof Map ? [...itemsToUpdate.values()] : itemsToUpdate;
    const isMarketPlaceProduct = itemsToUpdateArray.some((item: Item) => getProductType(item) === MARKETPLACE_PRODUCT);

    dispatch({ type: actionTypes.START_TROLLEY_UPDATE });

    if (isMarketPlaceProduct) {
      dispatch({ type: actionTypes.START_MARKETPLACE_TROLLEY_UPDATE });
    } else {
      dispatch({ type: actionTypes.START_GROCERY_TROLLEY_UPDATE });
    }

    updateTescoRecommendations(state, dispatch, rawItems);
    updateFavoritesCarousel(state, dispatch, rawItems);
    dispatch(dispatchItems(rawItems));

    const isPreviousItemListSameAsLatest = !Boolean(
      payloadDiff(getPreviousItems(getState().trolley), getLatestItems(state)).length,
    );

    const isCurrentItemListSameAsLatest = !Boolean(
      payloadDiff(getItems(getState().trolley), getLatestItems(state)).length,
    );

    const shouldRestoreQuantity = !isPreviousItemListSameAsLatest && isCurrentItemListSameAsLatest;

    const diff = payloadDiff(
      getLatestItems(state),
      getItems(getState().trolley), //fetch items from latest state as previous dispatch might have updated the state
    );

    if ((diff && diff.length) || shouldRestoreQuantity) {
      dispatch({
        type: actionTypes.ITEMS_TO_UPDATE,
        value: [...merge(getItemsToUpdate(state), itemsToUpdate).values()],
      });
    }
    const adjustedDiff = adjustPayloadSubstitutionValueForCore(diff, originalItem);

    debounceTrolleyUpdateRequest(dispatch, adjustedDiff, originalItem, getCurrentUrl(state));
  };
};

export const setItem = (item: Item) => {
  return (dispatch: Dispatch, getState: GetStore): void => {
    const items = getItems(getState().trolley);

    let updatedItems = cloneItems(items);
    updatedItems = setById(updatedItems, item);

    dispatch(sendRawItems(updatedItems, [item], item));
  };
};

/**
 * Updates an item matched by id, within a map of items.
 * The callback function determines how the item should be updated.
 *
 * Returns a new map of items and dispatches it to the store.
 * @param {Item} item
 * @param {(Item) => Item} fn
 */
const updateItem = (item: Item, fn: (item: Item) => Item) => {
  logSponsoredMedia(item);

  return (dispatch: Dispatch, getState: GetStore): void => {
    const id = getProductId(item);
    const state = getState();

    const items = getItems(state);
    let updatedItems = cloneItems(items);

    const updatedItem = getById(updatedItems, id);
    if (updatedItem) {
      updatedItems = setById(updatedItems, fn(updatedItem));
    } else {
      // If item is not present in trolley it should be added at the top
      updatedItems = [fn(item), ...updatedItems];
    }

    const itemsWithBblgLimit = setGroupByProductsLimit(updatedItems);
    const itemToUpdate = [getById(itemsWithBblgLimit, id)] as Array<Item>;

    return dispatch(sendRawItems(itemsWithBblgLimit, itemToUpdate, item));
  };
};

export const setCatchWeightTo = (item: Item, catchWeight: number) => {
  return (dispatch: Dispatch): void => {
    if (getQuantity(item) > 0) {
      dispatch({
        type: actionTypes.UPDATE_CATCH_WEIGHT,
        value: item,
      });
      dispatch(
        updateItem(item, updatingItem => {
          const clone = cloneItem(updatingItem);
          return withCatchWeight(clone, catchWeight);
        }),
      );
    }
  };
};

export const clearTermOnAdd = (incrementation: number, itemId: string, dispatch: Dispatch): void => {
  if (incrementation > 0) {
    dispatch(addItemToTrolleyUserHistory(itemId));
    dispatch(setSearchTerm(''));
  } else {
    dispatch(removeItemFromTrolleyUserHistory(itemId));
  }
};

/**
 *  @function incrementItemBy {
 *  @param {number} qty
 *  @param {Item}   item
 *  @param {object} analyticsOptions object used to control analytics events
 *                  fired after successful trolley updates are sent to the server.
 *                  Possible options: identifier {string}, sendAnalytics: {bool}
 * @param {string}  itemUnit takes values for setting item quantity. Possible options: "kg" or "pcs"
 */
export const incrementItemBy = (
  qty: number,
  item: Item,
  analyticsOptions?: AnalyticsOptions,
  itemUnit: CustomerUnitChoice = getUnit(item),
) => {
  return (dispatch: Dispatch): void => {
    const isMarketPlaceProduct = getProductType(item) === MARKETPLACE_PRODUCT;

    dispatch(showMarketPlaceProductModal(isMarketPlaceProduct, qty));
    dispatch(setTrolleyItemActionAnalytics(getProductId(item), analyticsOptions));
    dispatch(setRecsCarouselDetailsOnItemAdd(analyticsOptions, item?.quantity));
    dispatch(
      updateItem(item, updatingItem => {
        const updated = setCustomerUnitChoice(adjustNumberOfItems(updatingItem, qty), itemUnit);

        return withCatchWeight(updated, getCatchWeight(item) || 0);
      }),
    );
    clearTermOnAdd(qty, getBaseProductId(item), dispatch);
  };
};

export const setItemQuantityTo = (qty: number, item: Item, analyticsOptions?: AnalyticsOptions) => {
  return (dispatch: Dispatch): void => {
    dispatch(setTrolleyItemActionAnalytics(getProductId(item), analyticsOptions));
    dispatch(
      updateItem(item, updatingItem => {
        const updated = setCustomerUnitChoice(setNumberOfItems(updatingItem, qty), getUnit(item));

        return withCatchWeight(updated, getCatchWeight(item) || 0);
      }),
    );
    clearTermOnAdd(qty - getQuantity(item), getBaseProductId(item), dispatch);
  };
};

export const removeItem = (item: Item, analyticsOptions: AnalyticsOptions) => {
  return (dispatch: Dispatch): void => dispatch(setItemQuantityTo(0, item, analyticsOptions));
};

export const updateBagOption = (value: boolean) => async (dispatch: Dispatch, getState: GetStore): Promise<void> => {
  dispatch({
    type: actionTypes.UPDATE_BAG_OPTION,
  });

  const bagOptions = {
    isBagless: value,
    returnUrl: getCurrentUrl(getState()),
  };

  try {
    const response = await request.put(getLanguageLink(getState(), '/trolley/bagless-preferences'), {
      body: JSON.stringify(bagOptions),
    });

    const { errors, basket } = response;

    // Why do we use throw as a 'goto' flow control statement here?
    // Error is not re thrown to the outer context therefore 'if' statement
    // could be used instead which would make intent more clear
    // put up modal to reload if response has errors
    if (errors?.length) {
      throw new ErrorWithStatusCode(errors[0].message, 503);
    }

    if (basket) {
      const items = itemsToMap(basket.items);

      const receiveRawTrolleyAction = {
        type: actionTypes.RECEIVE_RAW_TROLLEY,
        value: basket,
      } as Action;

      const receiveTrolleyItemsAction = {
        type: actionTypes.RECEIVE_TROLLEY_ITEMS,
        value: items,
      } as Action;

      const setInstructionsAction = {
        type: actionTypes.SET_INSTRUCTIONS,
        instructions: basket.deliveryPreferences.deliveryInstruction,
      } as Action;

      dispatch(batchActions([receiveRawTrolleyAction, receiveTrolleyItemsAction, setInstructionsAction]));
    }

    dispatch({
      type: actionTypes.RESOLVE_TROLLEY_UPDATE,
    });
  } catch (error) {
    // reset to initial state
    dispatch({
      type: actionTypes.RESOLVE_TROLLEY_UPDATE,
    });
    commonActionRejectionsHandler(error, dispatch);
  }
};

const emitUnableToDeliverAddressError = (res): void => {
  const response: Response = res.response;
  analyticsBus().emit('errorData', {
    code: response?.status ?? -1,
    text: 'unable to deliver to this address',
    type: 'action',
  });
};

const isInvalidAddressType = (res: SlotsChangeAddressResponse, type: string): boolean =>
  (res && res.errors && res.errors.some(error => error.message === type)) || false;

const sendSubstitutionPreferences = (options: { substituteAllItems: boolean; returnUrl: string }) => {
  return (dispatch: Dispatch, getState: GetStore): Promise<void> => {
    return request
      .put(getLanguageLink(getState(), '/trolley/substitution-preferences?_method=PUT'), {
        body: JSON.stringify(options),
      })
      .then(res => {
        // put up modal to reload if response has errors
        if (res.errors && res.errors.length) {
          throw new ErrorWithStatusCode(res.errors[0].message, 503);
        }

        dispatch({
          type: actionTypes.UPDATE_TROLLEY_CUSTOMER_PREFERENCES,
          value: res.basket.customerPreferences,
        });

        dispatch({
          type: actionTypes.RESOLVE_TROLLEY_UPDATE,
        });

        return dispatch(updateTrolley(res.basket));
      })
      .catch(res => {
        commonActionRejectionsHandler(res, dispatch);

        // reset to initial state
        dispatch({
          type: actionTypes.RESOLVE_TROLLEY_UPDATE,
        });
      });
  };
};

export const updateGlobalSubstitutionPreferences = (value: boolean) => {
  return (dispatch: Dispatch, getState: GetStore): void => {
    dispatch({
      type: actionTypes.UPDATE_GLOBAL_SUBSTITUTION_PREFERENCES,
    });

    const options = {
      substituteAllItems: value,
      returnUrl: getCurrentUrl(getState()),
    };

    dispatch(sendSubstitutionPreferences(options));
  };
};

const showChangingAddressWarning = (value: ChangeSlotInfo) =>
  addToUrlAndDispatchModal(
    CHANGE_SLOT_TYPE_MODAL,
    { slotGroup: value.slotGroup },
    {
      type: actionTypes.CHANGING_ADDRESS,
      value,
    },
  );

export const showChangingAddressSuccessBanner = (value: ChangeSlotInfo) => ({
  type: actionTypes.CHANGING_ADDRESS_SUCCESS,
  value,
});

export const changeDeliveryAddress = (
  addressId: string,
  slotStart: string,
  slotEnd: string,
  slotGroup: number,
) => async (dispatch: Dispatch, getState: GetStore): Promise<void> => {
  dispatch({
    type: actionTypes.NEW_SLOT_DATA_PENDING,
  });

  const state = getState();

  const changeAddressOptions = {
    addressId,
    slotGroup,
    end: slotEnd,
    modalType: CHANGING_DELIVERY_ADDRESS,
    returnUrl: updateParamsInUrl(getCurrentUrl(state), { slotGroup }),
    shoppingMethod: getSelectedShoppingMethod(state),
    start: slotStart,
  };

  try {
    const response = await request.post(getLanguageLink(state, '/slots/change-address'), {
      body: JSON.stringify(changeAddressOptions),
    });

    const { changeSlotInfo, errors, preferencesData, storeId, locationId, locations, resources, trolley } = response;
    const fulfilmentOptionsDetail = resources?.fulfilmentOptionsDetail?.data;

    // put up modal to reload if response has errors
    if (errors?.length) {
      const isUndeliverableAddress = isInvalidAddressType(response, UNDELIVERABLE_ADDRESS);

      const isBlockedAddress = isInvalidAddressType(response, BLOCKED_ADDRESS);

      if (isUndeliverableAddress) {
        window.location.reload(); // LEGO-2936 - temporary pending GAPI fixes, rather than faffing around here

        return;
      } else if (isBlockedAddress) {
        dispatch({
          type: actionTypes.BUSINESS_ADDRESS_WARNING_MODAL,
        });

        dispatch({
          type: actionTypes.NO_SLOT_DATA_PENDING,
          value: { timezone: getTimezone(state) },
        });

        emitUnableToDeliverAddressError(response);

        return;
      } else {
        throw new ErrorWithStatusCode(errors[0].message, 503);
      }
    }

    const instructions = preferencesData.basket.deliveryPreferences.deliveryInstruction;

    dispatch({
      type: actionTypes.NEW_SLOT_DATA,
      value: {
        data: response,
        timezone: getTimezone(state),
      },
    });
    dispatch({ type: actionTypes.SYNC_SELECTED_ACTUAL_VALUES });

    dispatch({ type: RESOURCES_RECEIVED, resources });

    if (locations.length > 0) {
      dispatch(updateLocations(response));
    }

    dispatch({
      type: actionTypes.RESET_SELECTED_LOCATION,
      value: {
        deliveryLocationId: storeId,
        collectionLocationId: locationId,
      },
    });

    dispatch({ type: actionTypes.RECEIVE_RAW_TROLLEY, value: trolley });
    dispatch({ type: actionTypes.UPDATE_DELIVERY_ADDRESS, value: trolley.deliveryAddress });
    dispatch({
      type: actionTypes.RECEIVE_RESULT_ITEMS,
      value: trolley.items,
    });
    dispatch(updateTrolleyItems(trolley.items));
    dispatch({ type: actionTypes.SET_INSTRUCTIONS, instructions });
    dispatch({ type: actionTypes.RESOLVE_TROLLEY_UPDATE });

    if (storeId) {
      dispatch({
        type: actionTypes.UPDATE_USER_STORE,
        value: storeId,
      });
    }

    dispatch(getTaxonomy({ includeChildren: true }));

    if (changeSlotInfo?.start && changeSlotInfo?.end) {
      changeSlotInfo.slotToBeBooked
        ? dispatch(showChangingAddressSuccessBanner(changeSlotInfo))
        : dispatch(showChangingAddressWarning(changeSlotInfo));
    }

    if (fulfilmentOptionsDetail) {
      dispatch({
        type: actionTypes.RECEIVE_FULFILMENT_OPTIONS_DETAIL,
        value: fulfilmentOptionsDetail,
      });
      showOnDemandUnavailableModal(state, dispatch, fulfilmentOptionsDetail?.availableShoppingMethods);
    }
    dispatch({
      type: STORE_MAP_TOOGLE,
    });

    return response;
  } catch (error) {
    if (getDisplayBusinessAddressModal(state)) {
      dispatch({
        type: actionTypes.ACKNOWLEDGE_SELECTED_BUSINESS_ADDRESS,
      });
    }

    // reset to initial state
    dispatch({
      type: actionTypes.RESOLVE_TROLLEY_UPDATE,
    });

    emitUnableToDeliverAddressError(error);

    if ((error as { message?: string })?.message !== POSTCODE_ERROR_MESSAGE) {
      commonActionRejectionsHandler(error, dispatch);
    }

    return;
  }
};

export const mutateVatInvoiceDetails = (data: VatInvoiceDetailsRequestParams) => async (
  dispatch: Dispatch,
  getState: GetStore,
): Promise<VatInvoiceDetailsResponseJSON | void> => {
  try {
    const response = await request.put(getLanguageLink(getState(), '/trolley/vat-invoice-details'), {
      body: JSON.stringify(data),
    });

    const { errors, hasValidationError } = response;

    if (hasValidationError || errors?.length) {
      // Why is this used as a control flow?
      throw new Error();
    }

    return response;
  } catch {
    // put up modal to reload if response has errors
    dispatch({ type: GENERIC_ERROR_MODAL });
  }
};

export const resetVatInvoiceDetails = () => async (
  dispatch: Dispatch,
  getState: GetStore,
): Promise<ResetVatInvoiceDetailsResponseJSON | void> => {
  try {
    const response = await request.del(getLanguageLink(getState(), '/trolley/vat-invoice-details'));

    const { errors } = response;

    if (errors?.length) {
      // Why is this used as a control flow?
      throw new Error();
    }

    return response;
  } catch {
    // put up modal to reload if response has errors
    dispatch({ type: GENERIC_ERROR_MODAL });
  }
};

export const addCurrentPageToBasket = (analyticsOptions: AnalyticsOptions = {}) => async (
  dispatch: Dispatch,
  getState: GetStore,
): Promise<void> => {
  const state = getState();
  const currentResourceData = getResults(state);
  const { pages, pageNo } = currentResourceData;
  const currentPageNo = pageNo - 1;
  const pageData = pages[currentPageNo];
  const items = pageData instanceof Map ? [...pageData.values()] : pageData;
  const itemsToAdd: Array<ItemPayload> = [];

  let basketUpdateQuantity = 0;
  for (let i = 0; i < items.length; i++) {
    const finalItem = items[i];
    const id = getProductId(finalItem);
    const canIncreaseQuantity =
      getIsForSale(finalItem.product) && !getGroupLimitReached(finalItem) && !getItemLimitReached(finalItem);

    const newUnitChoice = getCustomerUnitChoice(finalItem);
    const newValue = 1;
    const oldValue = 0;
    const productIdErrors = !isValid('required', id);
    const unitErrors = !isValid('required', newUnitChoice);
    const ignoreItem = productIdErrors || unitErrors;

    if (canIncreaseQuantity && !ignoreItem) {
      itemsToAdd.push({
        id,
        adjustment: true,
        oldValue,
        newValue,
        oldUnitChoice: getCustomerUnitChoice(finalItem),
        newUnitChoice,
        ...(finalItem.product.productType === CATCH_WEIGHT_PRODUCTS && {
          catchWeight: getCatchWeight(finalItem),
        }),
      });
      basketUpdateQuantity += newValue - oldValue;
    }
  }

  if (!itemsToAdd.length) {
    return;
  }

  addApmData('basket_update_quantity', basketUpdateQuantity);

  dispatch(addItemsToBasket(itemsToAdd, analyticsOptions));
};

export const addItemsToBasket = (
  itemsToAdd: Array<ItemPayload> = [],
  analyticsOptions: AnalyticsOptions = {},
) => async (dispatch: Dispatch, getState: GetStore): Promise<void> => {
  const state = getState();

  dispatch(setTrolleyRequestInProgress(true, false));

  const endpoint = getApigeeMangoEndpoint(state);
  const uuid = uuidv4();
  const atrc = getAtrc(state);
  const traceId = `${atrc}:${uuid}`;
  const accessToken = getAccessToken(state);
  const context = {
    region: getAppRegion(state),
    traceId,
    'x-apikey': getApigeeMangoApiKey(state),
    ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
  };
  const response = await addAllToBasket({ items: itemsToAdd }, context, endpoint);
  const { error = false, data: resData } = response;

  if (error) {
    dispatch(setTrolleyRequestInProgress(false));
    return;
  }

  if (resData?.basket) {
    const basket = resData?.basket;
    const splitBaskets = transformSplitBaskets(basket);
    const { updates, ...data } = handleTrolleyResponse({
      ...basket,
      splitView: splitBaskets || [],
    }) as BasketData;
    if (data) {
      dispatch(updateTrolley(data, false, true));
      const items = updates.items;

      if (hasUpdateErrors(items)) {
        dispatch(openModal(ITEMS_UNAVAILABLE_MODAL));
      }

      const itemsMap = new Map();
      const orderItems: Array<Item> = [];
      for (let i = 0; i < data.items.length; i++) {
        const item = data.items[i];
        itemsMap.set(getProductId(item), null);
        const { product, ...remainingData } = item;
        // @ts-ignore
        orderItems.push({ ...remainingData, productItem: { product } });
      }
      const filteredUpdates = updates.items.filter((item: Updates) => itemsMap.has(item.id));
      fireAddAllAnalytics({ ...data, orderItems, updates: { items: filteredUpdates } }, state, analyticsOptions);
    }
  }

  dispatch(setTrolleyRequestInProgress(false));
};
