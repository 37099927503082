/**
 * Normal event
 * event      | |      |
 * time     ----------------
 * callback   | |      |
 *
 * Call log only when it's been 100ms since the last scroll
 * scroll     | |      |
 * time     ----------------
 * callback         |      |
 *              |100|  |100|
 */
/* usage
 const handleScroll = debounce((e) => {
 console.log('Window scrolled.')
 }, 100)

 window.addEventListener('scroll', handleScroll)
 */

export function debounce(func, wait) {
  let timeout;

  return function(...args) {
    const context = this;

    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}

/**
 * Non-destructively reverses an array
 *
 * @param {Array} list Array to be reversed.
 */
export function reverse(list) {
  if (list && list.length) {
    return list.map((curr, i, array) => array[array.length - 1 - i]);
  }

  return list;
}

/**
 * Converts string boolean values to booleans
 */
export function sanitiseBooleans(value) {
  if (typeof value === 'string') {
    return value.toLowerCase() === 'true';
  }

  return !!value;
}

export function isUndefined(prop) {
  return typeof prop === 'undefined';
}

export function isObject(obj) {
  if (!obj) {
    return false;
  }

  return typeof obj === 'object' && !Array.isArray(obj);
}

export function timeoutPromise(
  promise,
  timeInMilliseconds,
  timeoutRejectValue
) {
  let timeout;
  const timerPromise = new Promise((resolve, reject) => {
    timeout = window.setTimeout(() => {
      reject(timeoutRejectValue);
    }, timeInMilliseconds);
  });

  function clearTimeoutAndReturnValue(v) {
    window.clearTimeout(timeout);

    return v;
  }

  promise.then(clearTimeoutAndReturnValue).catch(clearTimeoutAndReturnValue);

  return Promise.race([promise, timerPromise]);
}

export function firstFreeIndex(array) {
  return array.findIndex(hasValue => !hasValue);
}

export function pickByKey(currentObject, filterList) {
  if (!isObject(currentObject) || !Array.isArray(filterList)) {
    return {};
  }

  return filterList.reduce((filteredObject, validKeys) => {
    if (typeof currentObject[validKeys] !== 'undefined') {
      filteredObject[validKeys] = currentObject[validKeys];
    }

    return filteredObject;
  }, {});
}

export function isEmptyObject(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export function flatten(list) {
  if (!Array.isArray(list)) {
    return [];
  }

  return Array.prototype.concat(...list);
}

export function flattenDeep(list) {
  return Array.isArray(list)
    ? list.reduce((a, b) => [...flattenDeep(a), ...flattenDeep(b)], [])
    : [list];
}

/**
 * Clones objects by value
 * Will not clone non-enumerable properties, property descriptor configurations, or the inheritance chain
 */
export function cloneSimpleObject(oldObj) {
  let newObj = oldObj;

  if (oldObj && typeof oldObj === 'object') {
    newObj = Array.isArray(oldObj) ? [] : {};
    const keys = Object.keys(oldObj);

    for (let i = 0, l = keys.length; i < l; i++) {
      newObj[keys[i]] = cloneSimpleObject(oldObj[keys[i]]);
    }
  }

  return newObj;
}

export function nextFreeIndexFrom(array, startAt) {
  while (array[startAt]) {
    startAt = startAt > array.length ? 0 : ++startAt;
  }

  return startAt;
}

export function fillArrayBlanks(array, fillToIndex, valueToSet = '') {
  for (let c = 0, l = fillToIndex; c < l; c++) {
    if (!array[c]) {
      array[c] = valueToSet;
    }
  }

  return array;
}

export function removeDuplicatesFromArray(arr) {
  return Array.from(new Set(arr));
}

/**
 * Allows us to deeply compare 2 objects similarly to _.isEqual()
 * */
export function compareObjects(obj1, obj2) {
  for (let property in obj1) {
    if (obj1.hasOwnProperty(property) !== obj2.hasOwnProperty(property)) {
      return false;
    }

    if (obj1[property] === null && obj2[property] !== null) {
      return false;
    }

    if (obj2[property] === null && obj1[property] !== null) {
      return false;
    }

    switch (typeof obj1[property]) {
      case 'object':
        if (!compareObjects(obj1[property], obj2[property])) {
          return false;
        }

        break;
      case 'function':
        if (
          typeof obj2[property] === 'undefined' ||
          (property !== 'compare' &&
            obj1[property].toString() !== obj2[property].toString())
        ) {
          return false;
        }

        break;
      default:
        if (obj1[property] === '' && obj2[property] !== '') {
          return false;
        }

        if (obj2[property] === '' && obj1[property] !== '') {
          return false;
        }

        if (obj1[property] !== obj2[property]) {
          return false;
        }
    }
  }

  for (let property in obj2) {
    if (
      typeof obj1[property] === 'undefined' &&
      typeof obj2[property] === 'undefined'
    ) {
      return true;
    }
  }

  return true;
}

export function convertStringToInt(strValue) {
  return parseInt(strValue, 10) || undefined;
}

export function coerceStringToInt(strValue) {
  return (typeof strValue === 'string' && Number(strValue)) || strValue;
}

export function isPlainObject(value) {
  return (
    value && (value.constructor === Object || value.constructor === undefined)
  );
}
