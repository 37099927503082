import React, { Component } from 'react';
import { connect } from '#/lib/render/connect-deep-compare';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import formatNumber from '#/lib/string-formatting/number-formatter';
import helpers from '#/lib/decorators/helpers';
import { getLanguage, getTimezone } from '#/reducers/app';
import FormattedSlotTime from '#/components/shared/formatted-time/formattedSlotTime';

const mapStateToProps = state => ({
  language: getLanguage(state),
  timezone: getTimezone(state)
});

@connect(mapStateToProps)
@helpers(['f', 't', 'c'])
class AddressSlotInfo extends Component {
  static propTypes = {
    c: PropTypes.func.isRequired,
    f: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    shoppingMethod: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    timezone: PropTypes.string.isRequired
  };

  getServiceChargeText() {
    if (this.props.f('useUpdatedServiceChargeLabels')) {
      return this.props.shoppingMethod === 'collection'
        ? this.props.t('orders:common.pick-and-pack')
        : this.props.t('orders:common.pick-pack-and-deliver');
    } else {
      return this.props.t('orders:common.service-charge');
    }
  }

  render() {
    const { address, c, slot, timezone, language } = this.props;

    const formattedAddress = () => {
      if (!address) return;

      const addressSlotInfo = Object.keys(address)
        .filter(key => key.startsWith('addressLine') && address[key])
        .map(key => address[key].trim())
        .join(', ');

      if (!addressSlotInfo) return null;

      return <p className="address-slot-info--address">{addressSlotInfo}</p>;
    };

    const date = moment(slot.start)
      .tz(timezone)
      .locale(language)
      .format('dddd Do MMMM');

    const formattedSlot = (
      <p className="address-slot-info--formatted-slot">
        {`${date}, `}
        <span className="address-slot-info--time">
          <FormattedSlotTime startTime={slot.start} endTime={slot.end} />
        </span>
      </p>
    );

    const serviceCharge = () => {
      const charge = formatNumber(slot.charge, {
        showAsciiSymbol: true,
        compactMode: true,
        ...c('currency')
      });

      return (
        <p className="address-slot-info--formatted-charge">
          {`${this.getServiceChargeText()}: `}
          <span className="address-slot-info--charge">{charge}</span>
        </p>
      );
    };

    return (
      <div className="address-slot-info--container">
        <div className="address-wrapper">
          <div className="address-slot-info--address-block">
            <p className="address-slot-info--name">{address?.name}</p>
            {formattedAddress()}
          </div>
          {formattedSlot}
          {serviceCharge()}
        </div>
      </div>
    );
  }
}

export default AddressSlotInfo;
