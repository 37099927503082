/**
 * Experiment constants for persisting taxonomy details
 */
export const persistTaxonomyVariants = {
  key: '1406',
  variants: {
    default: '1406-a',
    persistTaxonomyPosition: '1406-b',
  },
  useCustomerId: true,
};

// Analytics
export const TAXONOMY_PERSISTED = ':persisted';

// Groceries menu name
export const GROCERIES = 'groceries';
