import React from 'react';
import { connect } from 'react-redux';
import { WithRouterProps } from 'react-router';
import withCheckedSPA, { CheckSPAForRedirect } from '#/components/withCheckedSPA';
import { compose } from 'react-recompose';
import helpers from '#/lib/decorators/helpers';
import classnames from 'classnames';
import { MY_FAVOURITES } from '#/constants/facet-categories';
import { closeNavMenu } from '#/actions/ui-action-creators';
import Menu from '#/components/navigation/menu';
import { receiveFavoritesData } from '#/actions/ui-action-creators';
import { TTranslateFn } from '#/components/slots/delivery-content-section/index.defs';
import { ChildMenu } from '#/experiments/oop-1716/helpers/tab-helper';
import { isOnFavouritesPage } from '#/lib/favorites-helpers';
import { getIsUserAuthenticated } from '#/reducers/user';
import { getCurrentUrl } from '#/reducers/app';
import { getIsFavsMenuOpen } from '#/experiments/oop-1716/selectors/ui';
import { emitFavTopDropdownItemClick } from '#/experiments/oop-1716/helpers/analytics';
import { StyledBackdrop } from '#/experiments/oop-1716/styled';
import { getQueryParamByKey } from '#/lib/url/url-utils';

interface Props extends WithRouterProps {
  t: TTranslateFn;
  childMenu: ChildMenu;
  closeNavMenu: () => void;
  receiveFavoritesData: (tab: string) => void;
}

interface StoreProps {
  favMenuOpen: boolean;
  isFavouritesPage: boolean;
  isUserAuthenticated: boolean;
}

const mapStateToProps = (state: Store): StoreProps => ({
  favMenuOpen: getIsFavsMenuOpen(state),
  isFavouritesPage: isOnFavouritesPage(getCurrentUrl(state)),
  isUserAuthenticated: getIsUserAuthenticated(state),
});

export const FavMenuTree = (
  props: Props & StoreProps & { checkSPAForRedirect: CheckSPAForRedirect },
): React.ReactElement => {
  const { favMenuOpen, t: translate, childMenu = [], closeNavMenu, router, receiveFavoritesData } = props;

  const classes = classnames('menu-tree', 'menu-tree--hide-down-chevrons', {
    'menu-tree--open': favMenuOpen,
    'menu-tree--fav-open': favMenuOpen,
    'main-level-menu-tree--open': !favMenuOpen,
  });

  const buttonClasses = classnames(
    'menu-tree__all-button',
    'menu__link',
    'menu__link--active',
    'menu__link--current-parent',
  );

  const selectItem = (_: string, selected: { name: string; externalUrl: string }): void => {
    const { isUserAuthenticated, isFavouritesPage, checkSPAForRedirect } = props;

    emitFavTopDropdownItemClick(selected.name.toLowerCase());

    const cleanUrl = selected?.externalUrl?.replace(/%3f/gi, '?');
    if (isUserAuthenticated && router) {
      const tabName = getQueryParamByKey(cleanUrl, 'tab');
      if (tabName && !isFavouritesPage) {
        receiveFavoritesData(tabName as string);
      }
      checkSPAForRedirect(cleanUrl);
    } else {
      window.location.assign(cleanUrl);
    }
  };

  return (
    <div className={classes} data-auto="fav-menu-tree">
      {favMenuOpen && <StyledBackdrop className={classnames('menu-tree__backdrop', 'menu-fav-tree__backdrop')} />}
      <button type="button" className={buttonClasses}>
        {translate('product-list:favorites')}
      </button>
      <div className={classnames('menu-tree__inner', 'fav-menu-tree__inner')}>
        <Menu
          active={MY_FAVOURITES}
          isOpen={favMenuOpen}
          chevron={false}
          items={childMenu}
          label={translate('product-list:favorites')}
          selectItem={selectItem}
          type={MY_FAVOURITES}
          t={translate}
          closeMenu={closeNavMenu}
          isFocused={favMenuOpen}
        />
      </div>
    </div>
  );
};

const enhance = compose(
  connect(mapStateToProps, { receiveFavoritesData, closeNavMenu }),
  helpers(['t', 'c', 'f']),
  withCheckedSPA,
);

export default enhance(FavMenuTree);
