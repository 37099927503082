import {
  CONTENT_CONTAINER_LABEL,
  ITEM_LABEL,
  LEFT,
  RIGHT,
  QUERY_FOCUSABLE,
} from '#/experiments/oop-1826/components/search-pills/constants';
import {
  AriaLabelsPropTypes,
  SlideItemMetaTypes,
  ItemWidthPropTypes,
} from '#/experiments/oop-1826/components/search-pills/types';

/**
 * Checks if browser fully supports IntersectionObserverEntry API
 *
 * @returns boolean
 */
export const isIntersectionObserverSupported = (): boolean =>
  !(
    !(typeof window !== 'undefined') ||
    !('IntersectionObserver' in window) ||
    !('IntersectionObserverEntry' in window) ||
    !('intersectionRatio' in window.IntersectionObserverEntry.prototype) ||
    !('isIntersecting' in window.IntersectionObserverEntry.prototype)
  );

/**
 * Get the index of first visible in the `slideItemMeta`
 *
 * @param slideItemMeta
 * @returns item position
 */
export const getFirstVisibleItemPosition = (slideItemMeta: SlideItemMetaTypes[] = []): number => {
  const visibleIndex = slideItemMeta.findIndex(({ isVisible }) => isVisible);
  return visibleIndex + 1;
};

/**
 * Determines if the previous item in array during iteration is visible
 *
 * @param {number} index
 * @param {SlideItemMetaTypes[]} slideItemMeta
 * @returns boolean
 */
export const isPreviousSiblingVisible = (index: number, slideItemMeta: SlideItemMetaTypes[] = []): boolean => {
  if (index === 0) return false;
  return slideItemMeta[index - 1].isVisible;
};

/**
 * Get the index of last visible in the `slideItemMeta`
 *
 * @param slideItemMeta
 * @returns item position
 */
export const getLastVisibleItemPosition = (slideItemMeta: SlideItemMetaTypes[]): number => {
  const visibleIndex = slideItemMeta.findIndex(
    ({ isVisible }, index) => !isVisible && isPreviousSiblingVisible(index, slideItemMeta),
  );
  return visibleIndex > 0 ? visibleIndex : slideItemMeta.length;
};

/**
 * Build aria-label for the container.
 * eg. Viewing %{start} through %{end} of %{itemsCount} items.
 *
 * Construct in a way that it recomputes every-time the visibility of items changes.
 *
 * @param object
 * @returns string aria-label
 */
export const buildContainerAriaLabel = ({
  ariaLabels,
  slideItemsMeta,
}: {
  ariaLabels?: AriaLabelsPropTypes;
  slideItemsMeta: SlideItemMetaTypes[];
}): string => {
  const labelTemplate = ariaLabels?.container || CONTENT_CONTAINER_LABEL;
  return labelTemplate
    .replace('%{start}', '1')
    .replace('%{end}', String(slideItemsMeta.length))
    .replace('%{itemsCount}', String(slideItemsMeta.length));
};

/**
 * Build aria-label for the individual slide item.
 * eg item %{position} of %{itemsCount}
 *
 * Construct in a way that it recomputes every-time the visibility of items changes.
 *
 * @param object
 * @returns string aria-label
 */
export const buildItemAriaLabel = ({
  ariaLabels,
  index,
  slideCount,
}: {
  ariaLabels?: AriaLabelsPropTypes;
  index: number;
  slideCount: number;
}): string => {
  const labelTemplate = ariaLabels?.item || ITEM_LABEL;
  return labelTemplate.replace('%{position}', String(index + 1)).replace('%{itemsCount}', String(slideCount));
};

/**
 * As the IntersectionObserver() executes callback we expect slide items to have updated their
 * positions be it hidden or visible.
 *
 * This method does a simple conversion to match our expected object, we then return the built object
 * and expect the calling method to handle the react state updating
 *
 * @param previousSlide
 * @param observerItems
 * @returns new state of SlideItemMetaTypes[]
 */
export const convertEntryToSlideItemMeta = (
  previousSlide: SlideItemMetaTypes[],
  observerItems: IntersectionObserverEntry[],
): SlideItemMetaTypes[] => {
  const slideItemMeta = [...previousSlide];
  observerItems.forEach(item => {
    const target = item.target as HTMLDivElement;
    slideItemMeta[parseInt(target.dataset.key || '0', 10)] = {
      isVisible: item.isIntersecting,
      offsetLeft: target.offsetLeft,
      offsetWidth: target.offsetWidth,
    };
  });
  return slideItemMeta;
};

/**
 * Determines if the carousel navigation should be disabled or not
 * LEFT button, must be disabled when slide has reached the left side of scroll
 * RIGHT button, must be disabled when slide has reached the right side of scroll
 *
 * @param direction
 * @param slideItemsMeta
 * @returns boolean
 */
export const isControlDisabled = (
  direction: typeof LEFT | typeof RIGHT,
  slideItemsMeta: SlideItemMetaTypes[],
): boolean => {
  if (slideItemsMeta.length <= 1) return true; // Not much of a carousel if less than 1 item
  return slideItemsMeta[direction === LEFT ? 0 : slideItemsMeta.length - 1].isVisible;
};

/**
 * Return the next slide item right after the last visible item.
 *
 * @argument {ControlDirectionEnum} direction - Direction of of slide
 * @argument {SlideItemMetaTypes[]} slideItemsMeta - Current statuses of slide metas
 * @returns
 */
export const getNextScrollItem = ({
  direction,
  slideItemsMeta,
  containerRef,
  itemPadding,
}: {
  direction: typeof LEFT | typeof RIGHT;
  slideItemsMeta: SlideItemMetaTypes[];
  containerRef?: React.RefObject<HTMLDivElement>;
  itemPadding: number;
}): SlideItemMetaTypes & { targetOffsetLeft: number } => {
  // If the scroll request is backward, we can just .reverse() the array
  // and we would get the correct item that we want to be part of the slide.
  // We need to be careful using reverse as it updates the reference, we must make a copy first [...slideItem]
  const formattedSlideItemMeta = direction === LEFT ? [...slideItemsMeta].reverse() : slideItemsMeta;

  const targetSlideItem = formattedSlideItemMeta.find(
    (item, index) => !item.isVisible && isPreviousSiblingVisible(index, formattedSlideItemMeta),
  );

  if (targetSlideItem && direction === LEFT) {
    // When carousel is in backward sliding, the anchor of slide items changes from left wall to now right wall of the carousel.
    // Meaning, the first slide is now considered the far right item that is visible,
    // ... and that far right item will align itself to the right wall of the carousel.
    const carouselWidth = containerRef?.current?.offsetWidth || 0;
    const targetSlideItemInnerWidth = targetSlideItem.offsetWidth - itemPadding;
    return {
      ...targetSlideItem,
      // The `targetOffsetLeft` computation takes into account the length of the carousel container
      // as well as the fine adjustment needed to get the actual `inner-width` of the item.
      // As of the moment, there is no true inner-width that we can use in js api, hence we must compute manually.
      targetOffsetLeft: targetSlideItem.offsetLeft + targetSlideItemInnerWidth - carouselWidth,
    };
  }

  if (targetSlideItem && direction === RIGHT) {
    return { ...targetSlideItem, targetOffsetLeft: targetSlideItem.offsetLeft };
  }

  return { isVisible: false, targetOffsetLeft: 0, offsetLeft: 0, offsetWidth: 0 };
};

/**
 * A scroll-smooth polyfill until `element.scrollTo({ ..., behaviour: 'smooth' })` becomes widely supported.
 * Provides a callback when the animation finishes.
 *
 * @returns void
 */
export const animateScrolling = (
  {
    containerRef,
    direction,
    startValue,
    endValue,
  }: {
    containerRef?: React.RefObject<HTMLDivElement>;
    direction: typeof LEFT | typeof RIGHT;
    startValue: number;
    endValue: number;
  },
  callback = (): void => {},
): void => {
  const stepSize = Math.ceil(Math.abs(endValue - startValue) / 20);

  let currentValue = startValue;
  let endScroll = false;

  const scroll = (): void => {
    if (endScroll || !containerRef?.current) return;

    currentValue = direction === LEFT ? currentValue - stepSize : currentValue + stepSize;

    const operators = {
      left: (a: number, b: number): boolean => {
        return a > b;
      },
      right: (a: number, b: number): boolean => {
        return a < b;
      },
    };

    if (operators[direction](currentValue, endValue)) {
      containerRef.current.scrollLeft = currentValue;
      requestAnimationFrame(scroll);
    } else {
      containerRef.current.scrollLeft = endValue;
      requestAnimationFrame(scroll);
      callback();
      endScroll = true;
    }
  };

  scroll();
};

/**
 * Hides scrollbar if rendered in non-mobile viewport
 *
 * @param containerRef
 * @returns void
 */
export const hideScrollbar = (containerRef?: React.RefObject<HTMLDivElement>): void => {
  if (!containerRef?.current) return;

  const { clientHeight, offsetHeight } = containerRef.current;
  const scrollbarHeight = offsetHeight - clientHeight;

  if (scrollbarHeight) {
    containerRef.current.style.marginBottom = `-${scrollbarHeight}px`;
  }
};

/**
 * Computes carousel container, by summing item's width together with their paddings.
 *
 * @param object
 * @returns {ItemWidthPropTypes}
 */
export const getContainerWidth = ({
  itemWidth,
  childCount,
  itemPadding,
}: {
  itemWidth: string | ItemWidthPropTypes;
  childCount: number;
  itemPadding: number;
}): ItemWidthPropTypes => {
  const getItemWidth = (width: string): number => {
    const itemWidthInt = parseInt(width, 10);
    return childCount * (itemWidthInt + itemPadding);
  };

  if (typeof itemWidth === 'string') {
    return { global: `width: ${getItemWidth(itemWidth)}px` };
  }

  // Cast the object first so lint knows that the key exist in that object
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const breakpointWidth: Record<string, any> = { ...itemWidth };
  Object.keys(breakpointWidth).forEach(key => {
    breakpointWidth[key] = `width: ${getItemWidth(breakpointWidth[key])}px`;
  });
  return breakpointWidth;
};

/**
 * Sanitizes width props, since it accepts string and object with string value.
 *
 * Acts much like `getContainerWidth` but it computes the per item width together with their padding
 *
 * @param itemWidth
 * @param itemPadding
 * @returns
 */
export const sanitizeItemWidthProp = (
  itemWidth: string | ItemWidthPropTypes,
  itemPadding: number,
): ItemWidthPropTypes => {
  const getItemWidth = (width: string): string => `width: calc(${width} + ${itemPadding}px)`;

  if (typeof itemWidth === 'string') {
    return { global: getItemWidth(itemWidth) };
  }

  // Cast the object first so lint knows that the key exist in that object
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const breakpointWidth: Record<string, any> = { ...itemWidth };
  Object.keys(breakpointWidth).forEach(key => {
    breakpointWidth[key] = getItemWidth(breakpointWidth[key]);
  });
  return breakpointWidth;
};

/**
 * This is manual computation of item intersection to their parent
 *
 * @param containerRef
 * @param itemRefs
 * @returns Array of boleans, where index of item represents the location of item in the slide
 */
export const getVisibleItemsManually = ({
  containerRef,
  itemRefs,
  itemPadding,
}: {
  containerRef?: React.RefObject<HTMLDivElement>;
  itemRefs?: React.RefObject<HTMLLIElement[]>;
  itemPadding: number;
}): SlideItemMetaTypes[] => {
  if (!containerRef?.current || !itemRefs?.current) return [];

  // Based on these two variables, we can determine the position and size of the viewport.
  // Imagine that the scrollable parent (viewport) is the one zipping thourgh back and fort on
  // it's children rather the children moving around.
  const viewPortLeft = containerRef.current.scrollLeft;
  const viewPortRight = viewPortLeft + containerRef.current.offsetWidth;

  // Position values (offset, offsetWidth, etc..) of children does not change on-scroll,
  // but parent does, so we can hook into them.
  return itemRefs.current.map(itemRef => {
    const { offsetLeft, offsetWidth } = itemRef;
    const offsetRight = offsetLeft + offsetWidth - itemPadding;
    const isItemLeftWallInViewPort = viewPortLeft <= offsetLeft && offsetLeft < viewPortRight;
    const isItemRightWallInViewPort = viewPortLeft < offsetRight && offsetRight <= viewPortRight;

    return {
      isVisible: isItemLeftWallInViewPort && isItemRightWallInViewPort,
      offsetLeft,
      offsetWidth,
    };
  });
};

/**
 * Gets keyboard-focusable elements within a specified element
 *
 * @param {HTMLElement} element element
 * @param {string} query A query string to selectively target possible interactive elements
 * @returns {element}
 */
export const getFirstFocusableElement = (
  element?: HTMLElement,
  query: string = QUERY_FOCUSABLE,
): HTMLElement | undefined => {
  if (!element) return undefined;

  const filteredElements = [...element.querySelectorAll(query)].filter(el => {
    if (el.hasAttribute('disabled')) return false;
    if (el.hasAttribute('aria-hidden') && el.getAttribute('aria-hidden') === 'true') return false;

    const tabIndex = parseInt(el.getAttribute('tabindex') || '', 10);
    if (!Number.isNaN(tabIndex) && tabIndex < 0) return false;

    return true;
  });

  return filteredElements.length ? (filteredElements[0] as HTMLElement) : undefined;
};
