import analyticsBus from '#/analytics/analyticsBus';
import { DELAY, NOW } from '#/analytics/constants';
import { basicEvent } from '#/analytics/types/basic';
import {
  CategoryChipAnalyticEventType,
  RightControlAnalyticsValue,
  SeeLessAnalyticsValue,
  SeeMoreAnalyticsValue,
} from '#/constants/category-chips';

export const emitAnalyticsEvent = (value: string): void =>
  basicEvent(analyticsBus, {
    type: CategoryChipAnalyticEventType,
    value,
    action: DELAY,
  });

export const emitAnalyticsEventForCarouselControl = (): void => {
  basicEvent(analyticsBus, {
    type: CategoryChipAnalyticEventType,
    value: RightControlAnalyticsValue,
    action: NOW,
  });
};

export const emitAnalyticsEventForSeeMoreButton = (): void => {
  basicEvent(analyticsBus, {
    type: CategoryChipAnalyticEventType,
    value: SeeMoreAnalyticsValue,
    action: NOW,
  });
};

export const emitAnalyticsEventForSeeLessButton = (): void => {
  basicEvent(analyticsBus, {
    type: CategoryChipAnalyticEventType,
    value: SeeLessAnalyticsValue,
    action: NOW,
  });
};
