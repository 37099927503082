/* Following helper formats input object(i.e. address) into array as specified in the format
for ex: if address = {
                      name: 'name',
                      addressLine1: 'Line1 data',
                      addressLine2: 'Line2 data',
                      addressLine3: 'Line3 data',
                      addressLine4: 'Line4 data'
                    }
and format = ['name', 'addressLine1, adressLine2', 'addressLine3', 'addressLine4']
output is ['name', 'Line1 data, Line2 data', 'Line3 data', 'Line4 data']
for more scenarios refer to spec file
*/

const addressToSpecifiedFormat = (address, format) =>
  format
    .map(lineFormat =>
      lineFormat.split(',').reduce((previousLine, currentLine) => {
        currentLine = currentLine.trim();
        const currentValue = address[currentLine];

        if (
          address.hasOwnProperty(currentLine) &&
          currentValue &&
          currentValue !== ' '
        ) {
          return previousLine
            ? previousLine.concat(', ', currentValue)
            : currentValue;
        }

        return previousLine !== '' ? previousLine : undefined;
      }, '')
    )
    .filter(data => data !== undefined);

export default addressToSpecifiedFormat;
