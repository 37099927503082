import React from 'react';

// @ts-ignore
import Buybox, { ActionLink } from '@beans/buy-box';
import Link from '#/components/link-check-spa';
import { TBuyboxViewAndBuyData } from '#/selectors/beans-product-tile/buybox-data';

export const ViewAndBuyBuybox = ({
  priceText,
  priceSubtext,
  assistiveDrsText,
  drsText,
  href,
  text,
  onActionLinkClick,
}: TBuyboxViewAndBuyData) => (
  <Buybox
    data-auto="view-and-buy-buybox"
    assistiveDrsText={assistiveDrsText}
    drsText={drsText}
    action={
      <span data-auto="view-and-buy-buybox-link">
        <Link to={href} onClick={onActionLinkClick}>
          <ActionLink>{text}</ActionLink>
        </Link>
      </span>
    }
    priceSubtext={priceSubtext}
    priceText={priceText}
  />
);
