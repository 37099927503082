import React from 'react';
import PropTypes from 'prop-types';
import { connect } from '#/lib/render/connect-deep-compare';
import { getLanguageLink } from '#/reducers/app';
import ProductTitleUI from './ui';

const mapStateToProps = (state, ownProps) => ({
  productLink: getLanguageLink(state, `/products/${ownProps.id}`)
});

const ProductTitle = props => {
  const {
    displayAvailable,
    viewAndBuyButtonUrl,
    ospUrl,
    productLink,
    title,
    enabled,
    isPDPBrowsable,
    onClick
  } = props;

  const isLinkEnabled = (isPDPBrowsable && enabled) || displayAvailable;
  const href = viewAndBuyButtonUrl || ospUrl || productLink;

  return (
    <ProductTitleUI
      href={href}
      onClick={onClick}
      enabled={isLinkEnabled}
      title={title}
    />
  );
};
export default connect(mapStateToProps)(ProductTitle);

ProductTitle.propTypes = {
  displayAvailable: PropTypes.bool,
  enabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  isPDPBrowsable: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  ospUrl: PropTypes.string,
  productLink: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  viewAndBuyButtonUrl: PropTypes.string
};

ProductTitle.defaultProps = {
  displayAvailable: false,
  enabled: true
};
