import styled from 'styled-components';
import { breakpoints, fontSize, spacing } from '@ddsweb/selectors';
import { BREAKPOINTS } from '@ddsweb/constants';

export const StyledAuthWrapper = styled.div`
  grid-aria: auth;
  display: none;
  margin-left: auto;
  height: 52px;
  font-size: ${fontSize.xs};
  @media (min-width: ${BREAKPOINTS.mobileLarge}px) {
    display: flex;
    a {
      margin-right: ${spacing.sm};
    }
  }

  @media (max-width: ${breakpoints.tablet}px) {
    margin-top: 10px;
  }
`;
