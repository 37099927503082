import { Component } from 'react';

export default class FormValidation extends Component {
  constructor(props) {
    super(props);

    this.validators = {};
    this.state = {
      hasErrors: false,
      submitting: false
    };

    this.register = this.register.bind(this);
    this.isRegistered = this.isRegistered.bind(this);
    this.getErrors = this.getErrors.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  register(child, validate) {
    if (child && child.props) {
      const name = child.props.name;

      return (this.validators[name] = validate);
    }

    return this.getErrors();
  }

  isRegistered(name) {
    return !!this.validators[name];
  }

  getErrors(force) {
    const errors = Object.keys(this.validators)
      .map(key => this.validators[key](force))
      .reduce((errorArray, error) => errorArray.concat(error), [])
      .filter(error => !!error);

    this.setState({
      hasErrors: errors.length > 0
    });

    return errors;
  }

  onSubmit(e) {
    this.setState({
      submitting: true
    });

    if (typeof this.localValidations === 'function') {
      this.localValidations(e);
    }

    const preventValidation = !!(
      e.target.querySelector('[name="prevent-validation"]') &&
      e.value === 'true'
    );

    if (!(preventValidation || this.getErrors(true).length === 0)) {
      e.preventDefault();
      this.setState({
        submitting: false
      });

      return false;
    }

    return true;
  }
}
