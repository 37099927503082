import { HOME_PAGE, APP_STATE } from '#/constants/spa-resource';

export const type = HOME_PAGE;
export const params = [
  'preview',
  'isAuthenticated',
  'isRegistered',
  'isFirstTimeBuyer'
];
export const dependencies = [APP_STATE];
