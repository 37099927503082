import React from 'react';
import Spinner from '#/components/shared/spinner';
import classNames from 'classnames';

type LoadingOwnProps = {
  className: string;
};

export default function Loading({ className }: LoadingOwnProps): JSX.Element {
  return (
    <div className={classNames('loading-spa', className)}>
      <Spinner />
    </div>
  );
}
