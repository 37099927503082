export const DELETE_ACTION_FORM = 'DeleteActionForm';
export const REVIEW_TEXT = 'text';
export const REVIEW_TITLE = 'summary';
export const RATING_STARS = 'rating';
export const RATINGS_AND_REVIEWS_FORM = 'RatingsAndReviewsSubmissionForm';
export const REPORT_ACTION_FORM = 'ReportActionForm';
export const TERMS_AND_CONDITIONS = 'termsAndConditions';
export const ERROR_USER_NOT_AUTHENTICATED = 'RR_1001';
export const ERROR_SECOND_REVIEW_FOR_PRODUCT = 'RR_1018';
export const PDP_REVIEW_IMPRESSION = 'pdp review impression';
export const STAR_PDP_REVIEW_IMPRESSION = 'star pdp review impression';
export const REVIEW_CONTAINER = 'review-container';

export const RULE_TYPES = {
  REVIEW_TEXT_EMPTY: 'REVIEW_TEXT_EMPTY_TEXT',
  REVIEW_TEXT_GREATER_THAN: 'REVIEW_TEXT_GREATER_THAN',
  REVIEW_TEXT_LESS_THAN: 'REVIEW_TEXT_LESS_THAN',
  REVIEW_TEXT_NO_LETTER: 'REVIEW_TEXT_HAS_ONLY_NUMBER',
  REVIEW_SUMMARY_GREATER_THAN: 'REVIEW_SUMMARY_GREATER_THAN',
  RATING_STARS_EMPTY: 'RATING_STARS_EMPTY',
  TERMS_AND_CONDITIONS_CHECKED: 'TERMS_AND_CONDITIONS_CHECKED',
  REPEAT_REVIEW_SUBMISSION: 'REPEAT_REVIEW_SUBMISSION'
};

export const RATINGS_REVIEWS_ANALYTICS = {
  [RULE_TYPES.REVIEW_TEXT_EMPTY]: 'character review submission',
  [RULE_TYPES.REVIEW_TEXT_GREATER_THAN]: 'character review submission',
  [RULE_TYPES.REVIEW_TEXT_LESS_THAN]: 'character review submission',
  [RULE_TYPES.RATING_STARS_EMPTY]: 'star review submission',
  [RULE_TYPES.REPEAT_REVIEW_SUBMISSION]: 'repeat review submission'
};
