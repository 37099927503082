import styled, { css } from 'styled-components';
import Text from '@ddsweb/text';
import { spacing } from '@ddsweb/selectors';
import Button from '@ddsweb/button';
import { mediumSmallMin } from '#/constants/viewports';
import { BREAKPOINTS } from '@ddsweb/constants';

export const StyledBodyText = styled(Text)`
  margin-top: ${spacing.xs};
`;

export const StyledIconContainer = styled.div`
  margin-top: 88px;
  margin-bottom: ${spacing.sm};
  text-align: center;
`;

export const StyledContainer = styled.div`
  @media (max-width: ${mediumSmallMin}) {
    padding: 0 6px;
    max-width: 100%;
  }
  ${({ showEmptyTrolleyButtons }: { showEmptyTrolleyButtons: boolean }): string =>
    !showEmptyTrolleyButtons &&
    css`
      width: auto;
    `}
`;

export const StyledButton = styled(Button)`
  width: auto;
  margin: auto;
  height: 40px;
  @media (max-width: ${BREAKPOINTS.mobileLarge - 1}px) {
    width: 100%;
    margin: inherit;
  }
`;
