import Modal from '@ddsweb/modal';
import Button from '@ddsweb/button';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import helper from '#/lib/decorators/helpers';
import SafeForm from '#/components/shared/safe-form';
import { deleteAReview } from '#/actions/ratings-reviews-action-creators';
import { DELETE_ACTION_FORM } from '#/constants/ratings-reviews';
import { updateParamsInUrl } from '#/lib/url/url-utils';
import { Text } from '@ddsweb/text';
import { Heading } from '@ddsweb/heading';
import { connect } from '#/lib/render/connect-deep-compare';
import { closeModal } from '#/actions/ui-action-creators';
import { getLanguageLink, getCurrentUrl } from '#/reducers/app';
import { getProductItem } from '#/selectors/product-details';
import { getBaseProductId } from '#/selectors/item';

const mapStateToProps = state => {
  const item = getProductItem(state);

  return {
    closeHref: updateParamsInUrl(getCurrentUrl(state), {
      currentModal: null,
      modalData: null
    }),
    deleteReviewActionUrl: getLanguageLink(state, '/reviews/delete'),
    tpnb: getBaseProductId(item)
  };
};

@connect(mapStateToProps, { deleteAReview, closeModal })
@helper(['t'])
export default class DeleteReviewModal extends PureComponent {
  static propTypes = {
    closeHref: PropTypes.string.isRequired,
    closeModal: PropTypes.func.isRequired,
    deleteAReview: PropTypes.func.isRequired,
    deleteReviewActionUrl: PropTypes.string.isRequired,
    reviewId: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    tpnb: PropTypes.string.isRequired
  };

  handleOnClose = event => {
    event && event.preventDefault && event.preventDefault();
    this.props.closeModal();
  };

  getTranslation = suffix => {
    return this.props.t(`ratings-reviews:delete-a-review.${suffix}`);
  };

  handleDeleteReview = event => {
    event.preventDefault();
    const { tpnb, reviewId, deleteAReview, deleteReviewActionUrl } = this.props;
    deleteAReview(tpnb, reviewId, deleteReviewActionUrl);
  };

  render() {
    return (
      <Modal
        open
        ssr
        closeHref={this.props.closeHref}
        className="delete-review-modal"
        id="delete-review-modal"
        onChange={this.handleOnClose}
      >
        <div className="delete-review-modal__content">
          <Heading headingLevel="4" className="delete-review-modal__heading">
            {this.getTranslation('heading')}
          </Heading>
          <Text as="p" className="delete-review-modal__body">
            {this.getTranslation('body-content')}
          </Text>
          <div className="delete-review-modal__buttons">
            <SafeForm
              action={this.props.deleteReviewActionUrl}
              method="POST"
              name={DELETE_ACTION_FORM}
            >
              <input
                type="hidden"
                name="reviewId"
                value={this.props.reviewId}
              />
              <input
                type="hidden"
                name="returnUrl"
                value={this.props.closeHref}
              />
              <Button
                className="delete-review-modal__confirm-btn"
                onClick={this.handleDeleteReview}
                type="submit"
              >
                {this.getTranslation('confirm-button')}
              </Button>
            </SafeForm>
            <SafeForm action={this.props.closeHref} method="GET">
              <Button
                variant="secondary"
                className="delete-review-modal__cancel-btn"
                onClick={this.handleOnClose}
                type="submit"
              >
                {this.getTranslation('cancel-button')}
              </Button>
            </SafeForm>
          </div>
        </div>
      </Modal>
    );
  }
}
