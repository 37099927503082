export default function filterResourcesByStateAndProps(
  resources,
  state,
  props
) {
  return resources.reduce((filtered, resource) => {
    if (typeof resource === 'string' || !resource[1]?.conditions) {
      return [...filtered, resource];
    }

    const [name, configOptions] = resource;
    const { conditions } = configOptions;

    const conditionResults = conditions.map(condition => {
      if (typeof condition === 'function') {
        return condition(state, props);
      }

      return condition;
    });

    if (conditionResults.some(result => !result)) {
      return filtered;
    }

    // When all conditions pass, the 'name' of the resource is extracted and it gets added to current load queue of
    // resources. Only the name of the resource is sufficient in order to locate the resource file and make an API
    // request with right set of arguments.

    // In case any of the conditions fails, this resource is not added to the current load queue. Complete signature of
    // the resource (name, conditions, dependentOn) is retained to be able to re-evaulate the conditions at the next opportunity

    return [
      ...filtered,
      conditionResults.every(result => result === true)
        ? name
        : [
            name,
            {
              ...configOptions,
              conditions: conditionResults.filter(
                condition => typeof condition === 'function'
              )
            }
          ]
    ];
  }, []);
}
