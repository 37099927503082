import { getFeatureVariableStringFromState } from '#/lib/optimizely-manager';
import { timesOfTheDaySplitVariants } from '#/experiments/oop-1834/constants';

const {
  useCustomerId,
  variationKey,
  variants: { default: defaultVariant, timesOfTheDaySplit: timesOfTheDaySplitVariant },
  featureKey,
} = timesOfTheDaySplitVariants;

const getVariant = (state: Store): string =>
  getFeatureVariableStringFromState(state, featureKey, variationKey, { useCustomerId }) || defaultVariant;

export const getIsTimesOfTheDaySplitVariant = (state: Store): boolean =>
  getVariant(state) === timesOfTheDaySplitVariant;
