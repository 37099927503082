import React from 'react';
import PropTypes from 'prop-types';
import { Panel } from '../panel';
import { DfpBanner } from '../dfp-banner';

const DfpPanelWrappper = ({ seed, targeting, classes, enableLazyLoad }) => {
  return (
    <div className="dfp-panel-wrapper content-area">
      <Panel classes={classes}>
        <DfpBanner
          seed={seed}
          targeting={targeting}
          enableLazyLoad={enableLazyLoad}
        />
      </Panel>
    </div>
  );
};

DfpPanelWrappper.propTypes = {
  classes: PropTypes.string,
  enableLazyLoad: PropTypes.bool,
  seed: PropTypes.any,
  targeting: PropTypes.object
};

export { DfpPanelWrappper };
