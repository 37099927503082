import {
  BASKET_LIMIT_BREACH,
  BASKET_WITHIN_LIMIT,
  MISSING_IDENTIFIER,
  PAGE_IDENTIFIER,
  TREX_IDENTIFIER
} from '#/analytics/constants';
import {
  GHS_PRODUCT,
  QUANTITY_TYPE as UNIT_PCS,
  MARKETPLACE_PRODUCT
} from '#/constants/common';
import { createItem } from '#/lib/records';
import {
  setCustomerUnitChoice,
  setNumberOfItems
} from '#/lib/records/item-utils';
import {
  getGtin13FromImageUrl,
  getById,
  payloadDiff
} from '#/lib/records/product-utils';
import { getAppISORegionCode, getCurrency } from '#/reducers/app';
import {
  getAllTrexRecommendations,
  getTrexPageId
} from '#/reducers/recommendations';
import {
  getChangeGridPos,
  getChangeIdentifer,
  getChangeNewUnitChoice,
  getChangeNewValue,
  getChangeOldUnitChoice,
  getChangeOldValue,
  getChangePageId,
  getChangePosition,
  getChangeSeedProduct,
  getId,
  getIsSubstitute,
  getPieces,
  getProductAverageWeight,
  getProductId,
  getRawQtyUnit,
  getSeedProduct,
  getWeight,
  getSeedProductId,
  getProductDefaultImageUrl,
  getProductSubstitutions,
  hasMissedPromotion,
  hasSubstitution,
  getReviewsStats
} from '#/selectors/item';
import { getSubstituteFeatureExperiment } from '#/selectors/substitution';
import {
  getAnalyticsBasketId,
  getAnalyticsMarketplaceBasketId,
  getHasBasketBreachedByVolumeOrWeight,
  getItems,
  getLatestItems,
  getPreviousItems,
  getTrolley as getTrolleyFromState,
  getTrolleyItemActionAnalytics
} from '#/selectors/trolley';
import { emitBasketOp } from '#/analytics/bertie/events';
import { getProductDataForBasket } from '#/analytics/bertie/formatters/products';
import { itemToProductPayload } from '#/analytics/bertie/utils/products';
import { ADD_PRODUCT, REMOVE_PRODUCT } from '#/analytics/constants';
import { getRecentOrderContextValue } from '#/experiments/oop-1720/helpers';
import { CAROUSEL_MULTI_SUBS } from '#/experiments/oop-1934/constants';
import { IBYC_IDENTIFIER } from '#/experiments/oop-2349/constants';
import { getIbycProdPosAndPageId } from '#/experiments/oop-2349/reducers';

export const dispatchProductChangeAnalytics = (
  diff,
  anonymous = false,
  originalItem,
  previousItems,
  currentItems
) => {
  return (dispatch, getState) => {
    const state = getState();
    const radishItems = previousItems || getLatestItems(state);
    const items = currentItems || getItems(state);

    return diff.forEach(change => {
      let difference = getChangeNewValue(change) - getChangeOldValue(change);

      if (difference === 0) {
        return;
      } // do nothing as no changes to item.

      if (change.newUnitChoice !== change.oldUnitChoice) {
        const newValue = getRawQtyUnit(
          originalItem,
          getChangeNewValue(change),
          getChangeNewUnitChoice(change)
        );
        const oldValue = getRawQtyUnit(
          originalItem,
          getChangeOldValue(change),
          getChangeOldUnitChoice(change)
        );
        difference = newValue - oldValue;
      }

      const eventType = difference > 0 ? ADD_PRODUCT : REMOVE_PRODUCT;
      const currentItem = getById(items, getId(change));
      const previousItem = getById(radishItems, getId(change));

      // This checks whether this is the first item added to an empty basket.
      const isOpen =
        (radishItems?.length === 0 && items.size > 0) ||
        getSplitBasketOpenFlag(radishItems, items);

      // LEGO-2833: ignore removals if already handled in previous execution (local and server items are undefined).
      if (previousItem || currentItem) {
        dispatch(
          emitProductAddRemoveAnalytics(
            currentItem,
            previousItem,
            change,
            eventType,
            anonymous,
            isOpen,
            originalItem,
            {
              gridPos: parseInt(getChangeGridPos(change), 10) || undefined,
              identifier: getChangeIdentifer(change),
              pageId: getChangePageId(change),
              position: parseInt(getChangePosition(change), 10) || undefined,
              seedProduct: getChangeSeedProduct(change)
            }
          )
        );
      }
    });
  };
};

const getItemId = item => getProductId(item);

export const getTrexProdPosAndPageId = (state, productId) => {
  const recommendations = getAllTrexRecommendations(state);
  const targetTPNBs = recommendations && Object.keys(recommendations);
  const response = {};

  targetTPNBs?.find(targetTPNB => {
    let i = 1;

    recommendations[targetTPNB].productItems.forEach(item => {
      if (productId === getItemId(item)) {
        response.position = i;
        response.pageId = getTrexPageId(state, targetTPNB);
      }
      i++;
    });
  });

  return response;
};

const emitProductAddRemoveAnalytics = (
  currentItem,
  previousItem,
  change,
  eventType,
  anonymous,
  isOpen,
  originalItem /* optional, to be converted when moved to TS */,
  itemActionAnalyticsPayload
) => {
  return (dispatch, getState) => {
    const state = getState();
    const currency = getCurrency(state);
    const ISORegionCode = getAppISORegionCode(state);

    const productRemovedFromBasket =
      eventType === REMOVE_PRODUCT && currentItem === undefined;

    let analyticsItem;
    let contextValue;
    let identifier;
    let payload;
    let placementData;
    let position;
    let product;
    let substituteFeatureExperiment;
    let media;

    if (currentItem && originalItem && hasSubstitution(originalItem)) {
      substituteFeatureExperiment = getSubstituteFeatureExperiment(state);

      if (substituteFeatureExperiment) {
        placementData = {
          substitutionAPI: substituteFeatureExperiment
        };
      }
      const subsProductIDs = getProductSubstitutions(originalItem).map(
        item => item.product.id
      );
      if (subsProductIDs.includes(currentItem.product.id)) {
        currentItem = createItem({
          ...currentItem,
          seedProduct: getSeedProduct(originalItem),
          isSubstitute: true
        });
      }
    }

    if (originalItem && getIsSubstitute(originalItem)) {
      substituteFeatureExperiment = getSubstituteFeatureExperiment(state);

      if (substituteFeatureExperiment) {
        placementData = {
          substitutionAPI: substituteFeatureExperiment
        };
      }

      if (currentItem) {
        currentItem = createItem({
          ...currentItem,
          seedProduct: getSeedProduct(originalItem),
          isSubstitute: true
        });
      } else {
        previousItem = createItem({
          ...previousItem,
          seedProduct: getSeedProduct(originalItem),
          isSubstitute: true
        });
      }
    }

    if (!previousItem) {
      previousItem = setNumberOfItems(
        setCustomerUnitChoice(currentItem, UNIT_PCS),
        0
      );
    } else {
      previousItem = setNumberOfItems(
        setCustomerUnitChoice(previousItem, getChangeOldUnitChoice(change)),
        getPieces({
          ...previousItem
        })
      );
    }

    if (productRemovedFromBasket) {
      payload = itemToProductPayload(previousItem, currency.isoCode);

      payload.quantity = getPieces(previousItem);
      payload.weight = getWeight(previousItem);

      analyticsItem = previousItem;
    } else {
      const diffItem = setNumberOfItems(
        setCustomerUnitChoice(currentItem, UNIT_PCS),
        Math.abs(getPieces(currentItem) - getPieces(previousItem))
      );

      analyticsItem = {
        ...diffItem,
        isSponsoredProduct: originalItem.isSponsoredProduct,
        isWhyNotTry: originalItem.isWhyNotTry,
        promotions: originalItem.promotions || [],
        product: {
          ...diffItem.product,
          isNew: !!originalItem?.product?.isNew
        }
      };

      payload = itemToProductPayload(analyticsItem, currency.isoCode);
      payload.quantity = Math.abs(getPieces(diffItem));
      payload.weight = Math.abs(
        getWeight(previousItem) - getWeight(currentItem)
      );
      const reviewStats = getReviewsStats(originalItem);
      payload.reviews = reviewStats ? { stats: reviewStats } : null;
    }

    if (getIsSubstitute(analyticsItem)) {
      payload.seedLookup = placementData?.substitutionAPI ?? 'sub:gapi';
      payload.seedProduct = {
        tpnb: getSeedProductId(analyticsItem),
        gtin: analyticsItem.seedProduct?.gtin13,
        name: analyticsItem.seedProduct?.name
      };
    }

    if (placementData) {
      payload.position = placementData.gridPos;
    }

    if (!payload.gtin) {
      payload.gtin = getGtin13FromImageUrl(
        getProductDefaultImageUrl(originalItem)
      );
    }

    product = getProductDataForBasket(payload, eventType);

    const itemActionAnalytics = {
      ...itemActionAnalyticsPayload,
      ...getTrolleyItemActionAnalytics(state, getItemId(previousItem))
    };

    if (product.offer && itemActionAnalytics.enableMissedOfferTracking) {
      product.offer.offerMet = !hasMissedPromotion(analyticsItem);
    }

    const anonymousPayload = anonymous ? { anonymous: true } : {};

    if (itemActionAnalytics.identifier?.includes(TREX_IDENTIFIER)) {
      const getPosAndPageId =
        itemActionAnalytics.identifier === IBYC_IDENTIFIER
          ? getIbycProdPosAndPageId
          : getTrexProdPosAndPageId;
      const productId = getItemId(currentItem || previousItem);
      const { position: pos, pageId } =
        itemActionAnalytics.position || itemActionAnalytics.pageId
          ? itemActionAnalytics
          : getPosAndPageId(state, productId);

      position = pos;
      contextValue = pageId;
    }

    // TODO: Remove this as part of LEGO-31735
    if (itemActionAnalytics.identifier?.includes(PAGE_IDENTIFIER)) {
      contextValue = getRecentOrderContextValue(state, contextValue);
    }

    if (itemActionAnalytics.identifier?.includes(CAROUSEL_MULTI_SUBS)) {
      position = itemActionAnalytics.position;
      contextValue = itemActionAnalytics.contextValue;
      product.seedLookup = itemActionAnalytics.product.seedLookup;
      product.seedProduct = itemActionAnalytics.product.seedProduct;
    }

    identifier =
      itemActionAnalytics.identifier ||
      getChangeIdentifer(change) ||
      MISSING_IDENTIFIER;

    if (itemActionAnalytics.sendAnalytics !== false) {
      const basketBreachedOrInLimit = getHasBasketBreachedByVolumeOrWeight(
        state
      )
        ? BASKET_LIMIT_BREACH
        : BASKET_WITHIN_LIMIT;

      if (itemActionAnalytics.gridPos !== undefined && !position)
        position = itemActionAnalytics.gridPos + 1;

      if (position && originalItem?.isSponsoredProduct) {
        identifier = 'sponsored:citrus';
        contextValue = originalItem?.product?.adId;
        media = {
          catalogueCountry: ISORegionCode
        };
      }

      originalItem.product.modelMetadata &&
        (product.personalisationModel = `${originalItem.product.modelMetadata.version}-${originalItem.product.modelMetadata.name}`);

      emitBasketOp({
        interactionType: eventType,
        product: [product],
        flag: {
          isOpen,
          ...anonymousPayload
        },
        basketId: getAnalyticsBasketId(state),
        secondBasketId: getAnalyticsMarketplaceBasketId(state),
        basketFullness: basketBreachedOrInLimit,
        uniqueProducts: getItems(state).length,
        position,
        contextValue,
        identifier,
        media
      });
    }
  };
};

export const buildProductAnalyticsPayload = (
  updatedItem,
  diff,
  currency,
  analyticsOptions
) => {
  const weight = Math.abs(getProductAverageWeight(updatedItem) * diff);
  const productPayload = itemToProductPayload(updatedItem, currency.isoCode);
  productPayload.weight = weight;

  const productData = getProductDataForBasket(productPayload);
  productData.qtyChange = Math.abs(diff);

  if (productData.offer && analyticsOptions.enableMissedOfferTracking) {
    productData.offer.offerMet = !hasMissedPromotion(updatedItem);
  }

  return productData;
};

export const notifyAnalytics = () => {
  return (dispatch, getState) => {
    const state = getState();
    const payload = payloadDiff(getPreviousItems(state), getLatestItems(state));

    dispatch({
      type: 'analytics:trolley-change',
      value: {
        diff: payload,
        trolley: getTrolleyFromState(state)
      }
    });
  };
};

export const getSplitBasketOpenFlag = (radishItems, items) => {
  const radishItemsList =
    (radishItems instanceof Map && [...radishItems.values()]) || radishItems;
  const itemsList = (items instanceof Map && [...items.values()]) || items;
  const groceryRadishItems = radishItemsList.some(
    ({ product }) => product?.typename === GHS_PRODUCT
  );
  const mpRadishItems = radishItemsList.some(
    ({ product }) => product?.typename === MARKETPLACE_PRODUCT
  );

  return (
    // Check if the first marketplace item was added to the basket when grocery items exist
    (!mpRadishItems &&
      itemsList.some(
        ({ product }) => product?.typename === MARKETPLACE_PRODUCT
      )) ||
    // Check if the first grocery item was added to the basket when marketplace items exist
    (!groceryRadishItems &&
      itemsList.some(({ product }) => product?.typename === GHS_PRODUCT))
  );
};
