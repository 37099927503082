export function isMobileViewport() {
  if (!process.env.CLIENT_SIDE) {
    return false;
  }

  return (window.innerWidth || document.documentElement.clientWidth) < 600;
}

export function isDesktopViewport() {
  if (!process.env.CLIENT_SIDE) {
    return false;
  }

  return (window.innerWidth || document.documentElement.clientWidth) > 991;
}
