import analyticsBus from '#/analytics/analyticsBus';
import { DELAY, NOW } from '#/analytics/constants';
import { basicEvent } from '#/analytics/types/basic';

const REBOOKING_MODAL_IMPRESSION_EVENT = 'slot rebooking modal:impression' as const;
const REBOOKING_MODAL_REBOOK_EVENT = 'slot rebooking modal:rebook slot' as const;
const REBOOKING_MODAL_NOT_NOW_EVENT = 'slot rebooking modal:not now' as const;
const REBOOKING_MODAL_CLOSE_EVENT = 'slot rebooking modal:close' as const;

const REMIND_MODAL_IMPRESSION_EVENT = 'slot expiry reminder modal:impression' as const;
const REMIND_MODAL_OK_EVENT = 'slot expiry reminder modal:ok' as const;
const REMIND_MODAL_CHECKOUT_EVENT = 'slot expiry reminder modal:check out' as const;
const REMIND_MODAL_CLOSE_EVENT = 'slot expiry reminder modal:close' as const;

type RebookEvent =
  | typeof REBOOKING_MODAL_CLOSE_EVENT
  | typeof REBOOKING_MODAL_IMPRESSION_EVENT
  | typeof REBOOKING_MODAL_NOT_NOW_EVENT
  | typeof REBOOKING_MODAL_REBOOK_EVENT
  | typeof REMIND_MODAL_IMPRESSION_EVENT
  | typeof REMIND_MODAL_OK_EVENT
  | typeof REMIND_MODAL_CHECKOUT_EVENT
  | typeof REMIND_MODAL_CLOSE_EVENT;

const SLOTS_TYPE = 'slots' as const;

const fireEvent = (value: RebookEvent, action: string = NOW): void => {
  basicEvent(analyticsBus, {
    type: SLOTS_TYPE,
    value,
    action,
  });
};

export const rebookModalImpression = (): void => fireEvent(REBOOKING_MODAL_IMPRESSION_EVENT);
export const rebookModalRebook = (): void => fireEvent(REBOOKING_MODAL_REBOOK_EVENT, DELAY);
export const rebookModalNotNow = (): void => fireEvent(REBOOKING_MODAL_NOT_NOW_EVENT, DELAY);
export const rebookModalClose = (): void => fireEvent(REBOOKING_MODAL_CLOSE_EVENT);

export const remindModalImpression = (): void => fireEvent(REMIND_MODAL_IMPRESSION_EVENT);
export const remindModalOk = (): void => fireEvent(REMIND_MODAL_OK_EVENT, DELAY);
export const remindModalCheckout = (): void => fireEvent(REMIND_MODAL_CHECKOUT_EVENT, DELAY);
export const remindModalClose = (): void => fireEvent(REMIND_MODAL_CLOSE_EVENT);
