import React from 'react';
import styled from 'styled-components';
import Button from '@ddsweb/button';
import { spacing } from '@ddsweb/selectors';
import Icon from '@ddsweb/icon';

export type AriaPressed = 'true' | 'false';

type TOwnProps = {
  className?: string;
  size?: string | undefined;
  handleDeleteClick(arg: React.SyntheticEvent): void;
  accessibleText?: string;
  graphic?: string;
  variant?: string;
  title?: string;
  ariaPressed?: AriaPressed;
  validProps?: { type: string };
};

export const DeleteButton: React.FC<TOwnProps> = ({
  className,
  handleDeleteClick,
  accessibleText = 'remove-item',
  variant = 'secondary',
  size = 'xs',
  title = '',
  ariaPressed = undefined,
  validProps,
}) => {
  const extraProps = {
    ...(ariaPressed && { 'aria-pressed': ariaPressed }),
    ...(validProps && { ...validProps }),
  };

  return (
    <RemoveButton
      variant={variant}
      className={className}
      onClick={handleDeleteClick}
      aria-label={accessibleText}
      size={size}
      title={title}
      data-auto="remove-item"
      icon={<Icon aria-hidden="true" graphic="close" size="xs" />}
      {...extraProps}
    />
  );
};

const RemoveButton = styled(Button)`
  border-color: transparent;
  float: right;
  margin: ${spacing.sm};
  padding: 0;
  width: 24px;
  height: 24px;
`;
