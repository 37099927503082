import styled from 'styled-components';
import { spacing, fontSize } from '@ddsweb/selectors';
import { SubHeading, BodyText } from '@ddsweb/typography';
import Modal from '@ddsweb/modal';

export const StyledModal = styled(Modal)`
  & .ddsweb-modal__modal-container {
    height: unset;
    max-height: unset;
  }
`;

export const Content = styled.div`
  margin-bottom: ${spacing.lg};
`;

export const ButtonContainer = styled.section`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  button {
    width: max-content;
    flex-grow: 1;
  }
`;

export const StyledHeading = styled(SubHeading)`
  && {
    margin: ${spacing.md} 0;
    font-size: ${fontSize.md};
  }
`;

export const StyledBodyText = styled(BodyText)`
  padding: ${spacing.xs} 0;
`;
