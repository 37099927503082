import {
  CLEAR_MULTI_SEARCH_LIST,
  CLEAR_SEARCH_SUGGESTIONS,
  SET_SEARCH_TERM,
  MULTI_SEARCH_LIST_LOADING,
  SET_MULTI_SEARCH_PAGE_NUMBER,
  SET_SUGGESTIONS_VISIBILITY,
  UPDATE_MULTI_SEARCH_LIST,
  UPDATE_MULTI_SEARCH_LIST_ITEM,
  UPDATE_SEARCH_SUGGESTIONS,
  UPDATE_SEARCH,
} from '../constants/action-types';
import { SearchActionTypes } from '#/actions/search-action-creators.defs';
import { SearchState } from '#/custom-typings/redux-store/search.defs';
import { MULTI_SEARCH_LIST, SEARCH } from '../constants/spa-resource';
import { getResourceData } from '../resources/resource-util';
import { fillArrayBlanks } from '../utils/misc';
import { ServerProps } from './types.defs';

const initialState: SearchState = {};

export default function search(state = initialState, action: SearchActionTypes): SearchState {
  switch (action.type) {
    case SET_SEARCH_TERM:
      return { ...state, searchTerm: action.searchTerm };
    case CLEAR_SEARCH_SUGGESTIONS:
      return {
        ...state,
        suggestions: [],
        suggestionsFor: '',
        suggestionsFrom: action.suggestionsFrom,
      };
    case SET_SUGGESTIONS_VISIBILITY:
      return { ...state, suggestionsVisible: action.visibility };
    case UPDATE_SEARCH_SUGGESTIONS:
      return {
        ...state,
        suggestions: action.suggestions,
        suggestionsFor: action.suggestionsFor,
        isPrediction: action.isPrediction,
        suggestionsFrom: action.suggestionsFrom,
      };
    case UPDATE_MULTI_SEARCH_LIST:
      return { ...state, listItems: action.listItems };
    case UPDATE_MULTI_SEARCH_LIST_ITEM: {
      const listItems = state.listItems ? [...state.listItems] : [];

      fillArrayBlanks(listItems, action.index);
      listItems[action.index] = action.value;

      return { ...state, listItems };
    }
    case CLEAR_MULTI_SEARCH_LIST:
      return { ...state, listItems: [] };
    case MULTI_SEARCH_LIST_LOADING:
      return { ...state, multiSearchListLoading: action.value };
    case SET_MULTI_SEARCH_PAGE_NUMBER:
      return { ...state, multiSearchPage: action.page };
    case UPDATE_SEARCH: {
      const { results, ...otherProps } = action.payload; // eslint-disable-line @typescript-eslint/no-unused-vars
      return { ...state, ...otherProps };
    }
    default:
      return state;
  }
}

export const getSuggestions = ({ search: { suggestions } }: Store): SearchState['suggestions'] => suggestions;

export const getIsPrediction = ({ search: { isPrediction } }: Store): SearchState['isPrediction'] => isPrediction;

export const getSuggestionsVisibility = ({
  search: { suggestionsVisible },
}: Store): SearchState['suggestionsVisible'] => suggestionsVisible;

export const getSearchTerm = ({ search: { searchTerm } }: Store): SearchState['searchTerm'] => searchTerm;

export const getSearchedTerm = ({ resources: { search } }: Store): SearchState['query'] =>
  search && search.data && search.data.query;

export const getMultiSearchListItems = ({ search: { listItems } }: Store): SearchState['listItems'] => listItems;

export const getMultiSearchPageNumber = ({ search }: Store): SearchState['multiSearchPage'] =>
  search && search.multiSearchPage;

export const getMultiSearchIsLoading = ({ search }: Store): SearchState['multiSearchListLoading'] =>
  search && search.multiSearchListLoading;

export const getDefaultStateFromProps = (props: ServerProps): SearchState => {
  const searchProps = (props && props.search) || null;
  const searchResource = getResourceData(props.resources, SEARCH);
  const multiSearchResource = getResourceData(props.resources, MULTI_SEARCH_LIST);

  return {
    searchTerm: searchResource ? searchResource.query : '',
    multiSearchPage: (searchResource && searchResource.multiSearchPage) || null,
    listItems: multiSearchResource ? multiSearchResource.list : [],
    multiSearchListLoading: false,
    suggestionsUrl: (searchProps && searchProps.suggestionsUrl) || null,
    suggestions: [],
    suggestionsVisible: true,
    suggestionsFor: null,
    suggestionsFrom: new Date(1970, 1, 1),
  };
};
