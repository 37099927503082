import React, { Component } from 'react';
import { ConnectedProps } from 'react-redux';
import deepEqual from 'fast-deep-equal/es6';
import { HORIZONTAL } from '@ddsweb/constants';
import FavoriteHeartIcon from '#/components/product-tiles/common/favorite-heart-icon';
import InfoMessagesWrapper from '#/components/product-tiles/common/info-messages';
import BeansLazyImage from '#/components/product-tiles/common/beans-image';
import RestOfShelfLink from '#/components/product-tiles/common/rest-of-shelf-link';
import { BuyboxContainer } from '#/components/product-tiles/common/buybox-container';
import { WriteAReviewLink } from '#/components/product-tiles/common/write-a-review-link';
import { removeItem } from '#/actions/trolley/trolley-action-creators';
import { connect } from '#/lib/render/connect-deep-compare';
import { Item } from '#/lib/records/item';
import { PRODUCT_TILE } from '#/constants/analytics';
import { QUANTITY_TYPE as UNIT_PCS } from '#/constants/common';
import {
  StyledBuyBoxWrapper,
  StyledHorizontalTile,
  StyledHorizontalTileWrapper,
  StyledInfoMessagesWrapper,
  StyledProductActions,
  StyledProductActionsContainer,
  StyledProductDetailsContent,
  StyledPromotionsAboveMobile,
  StyledPromotionsMobile,
  StyledTileContentWrapper,
  StyledTiledContent,
  StyledTotalPriceWrapper,
  SellerWrapper,
  StyledDrs,
} from '#/components/product-tiles/horizontal-tile/core/styles';
import { CATCH_WEIGHT } from '#/constants/display-types';
import {
  getProductDisplayType,
  getProductId,
  getProductTitle,
  getRestOfShelfUrl,
  hasPromotion,
  hasSubstitution,
  isInFavourites,
  qtyIncrementable,
  getTagList,
  getProductDrsChargeAmount,
} from '#/selectors/item';
import OffersSashList from '#/components/product-tiles/common/offers-sash-list';
import { isProductDisabled, isProductAvailable } from '#/selectors/beans-product-tile/product-availability-data';
import { TFlashSashInfo } from '#/selectors/beans-product-tile/promotions-data';
import { getBuyBoxData, NO_BUYBOX, TBuyboxUnavailableWithPriceData } from '#/selectors/beans-product-tile/buybox-data';
import { getInfoMessagesData } from '#/selectors/beans-product-tile/info-message-data';
import { getRestOfShelfLinkData, TRestOfShelfData } from '#/selectors/beans-product-tile/rest-of-shelf-link-data';
import { getWriteAReviewData } from '#/selectors/beans-product-tile/write-a-review-data';
import { hasSquareOfferSash } from '#/components/products/product-tile/helpers';
import { CustomerUnitChoice } from '#/lib/records/item.defs';
import { TROLLEY } from '#/constants/tile-types';
import { TFeatureFunc } from '#/lib/records/helpers.defs';
import { withCatchWeight } from '#/lib/records/item-utils';
import RenderPrice from '#/components/products/product-tile/tile-components/shared/render-price';
import { getFlashSashInfo } from '#/components/products/product-tile/helpers';
import { BaseProps as IBaseProps, BaseStateProps, withProductTile } from '#/components/product-tiles/with-product-tile';
import { promoType } from '#/components/product-tiles/constants';
import { anonymousUserAdd } from '#/components/product-tiles/common/buybox-container/quantity-controls-buybox/anonymous-user-add-util';
import SellerInformation from '#/components/product-tiles/common/seller-info';
import ProductTitle from './product-title';
import { DietaryLogoWithProductImageHorizontalWrapper } from '#/experiments/oop-1922/styled';
import { DietaryLogo } from '#/experiments/oop-1922/components/tag-variants/dietary-logo';
import { DietaryIconWithText } from '#/experiments/oop-1922/components/tag-variants/dietary-icon-with-text';
import { getAppRegion, getCurrency, getLanguageLink } from '#/reducers/app';
import { isMultiSwapsUserInitiated } from '#/experiments/oop-1934/selectors';
import MultiSwapButton from '#/experiments/oop-1934/components/multi-swap-button';
import { VisuallyHidden } from '#/components/shared/styled';
import { getAmountWithCurrencyOrMinorCurrency } from '#/lib/string-formatting/currency-formatter';
import { TCurrency } from '#/custom-typings/redux-store/app.defs';

type AnalyticsOptions = {
  enableMissedOfferTracking?: boolean;
  identifier?: string;
  sendAnalytics?: boolean;
  gridPos?: string;
};
type TConfigFunc = (configKey?: string[] | string) => string;

export interface BaseProps extends IBaseProps {
  deleteActionButton?: JSX.Element;
  isTrolley?: boolean;
  renderSeeAlternativesButton?: TBuyboxUnavailableWithPriceData['renderBuyboxActions'];
  shouldShowPriceOnUnavailableTiles?: boolean;
  shouldShowRestOfShelf?: boolean;
  shouldShowWriteAReview?: boolean;
  onClick: () => void;
  showFavHeartIcon?: boolean;
  substitutionFor: Item | null;
  showTotalPrice?: boolean;
  removeItem: (item: Item, analyticsOptions: AnalyticsOptions) => void;
  isInBasket?: boolean;
  isSubstitute?: boolean;
  feature: TFeatureFunc;
  item: Item;
  preventDisabledTitleStyle?: boolean;
  showPromotionInfoBox?: boolean;
  config: TConfigFunc;
  handleSubsContainerChange: (isSwapButtonActive: boolean) => void;
}

type StateProps = {
  productId: string;
  title: string;
  isProductQtyIncrementable: boolean;
  disabled: boolean;
  showFavHeartIcon?: boolean;
  hasActions: ReturnType<typeof isInFavourites>;
  buybox: ReturnType<typeof getBuyBoxData>;
  infoMessages: ReturnType<typeof getInfoMessagesData>;
  getProductDisplayType: ReturnType<typeof getProductDisplayType>;
  hasSubstitutes: ReturnType<typeof hasSubstitution>;
  available: boolean;
  restOfShelfUrl: ReturnType<typeof getRestOfShelfUrl>;
  restOfShelfLink: TRestOfShelfData | undefined;
  writeAReviewData: ReturnType<typeof getWriteAReviewData>;
  showSquareOfferSash: ReturnType<typeof hasSquareOfferSash>;
  flashSashInfo: TFlashSashInfo | null;
  showCCPriceLogoInSquare: boolean;
  hasPromotion: boolean;
  showFindASwapCTA: boolean;
  flashSashInfoList: TFlashSashInfo[] | null;
  productDrsChargeAmount: number | null;
  currency: TCurrency;
  region: 'UK' | 'IE' | 'HU' | 'CZ' | 'SK';
};

type PropsFromRedux = ConnectedProps<typeof connector>;
export type Props = BaseProps & StateProps & PropsFromRedux & BaseStateProps;

type State = {
  catchWeight?: number;
};

const mapStateToProps = (
  state: Store,
  {
    config,
    feature,
    identifier,
    isTrolley,
    item,
    shouldShowPriceOnUnavailableTiles,
    shouldShowRestOfShelf = true,
    shouldShowWriteAReview,
    translate,
    viewAndBuyButtonUrl,
    showFavHeartIcon,
    renderSeeAlternativesButton,
  }: BaseProps,
): StateProps => {
  const productId = getProductId(item);
  const available = isProductAvailable(state, item);
  const isDisabled = isTrolley ? !available : isProductDisabled(item);
  const isFavouriteItem = isInFavourites(item);
  const flashSashInfo = getFlashSashInfo({ translate, item });
  let restOfShelfLink;

  if (shouldShowRestOfShelf) {
    restOfShelfLink = getRestOfShelfLinkData(state, item, translate, config);
  }

  return {
    productId,
    title: getProductTitle(item),
    isProductQtyIncrementable: qtyIncrementable(item),
    disabled: isDisabled,
    showFavHeartIcon: showFavHeartIcon ?? isFavouriteItem,
    hasActions: isFavouriteItem,
    currency: getCurrency(state),
    buybox: getBuyBoxData(
      state,
      item,
      translate,
      config,
      identifier,
      viewAndBuyButtonUrl,
      HORIZONTAL,
      shouldShowPriceOnUnavailableTiles,
      shouldShowRestOfShelf,
      renderSeeAlternativesButton,
      isTrolley,
    ),
    infoMessages: getInfoMessagesData({
      state,
      item,
      translate,
      restrictedOrderAmendmentOverrideMessage: true,
      config,
      feature,
      isTrolley,
    }),
    getProductDisplayType: getProductDisplayType(item),
    hasSubstitutes: hasSubstitution(item),
    available,
    restOfShelfUrl: getLanguageLink(state, getRestOfShelfUrl(item)),
    restOfShelfLink,
    writeAReviewData: getWriteAReviewData(state, item, feature, translate, shouldShowWriteAReview),
    showSquareOfferSash: hasSquareOfferSash(item, available),
    flashSashInfo,
    showCCPriceLogoInSquare: !!config('showCCPriceLogoInSquare'),
    hasPromotion: hasPromotion(item),
    showFindASwapCTA: isMultiSwapsUserInitiated(state),
    flashSashInfoList: getTagList({ translate, item }),
    productDrsChargeAmount: getProductDrsChargeAmount(item, isTrolley),
    region: getAppRegion(state),
  };
};

const mapDispatchToProps = {
  removeItem,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

const scrollToAddedSubstitute = (): void => {
  window.scrollTo(0, 0); // Assume added substitute is at top of page
};

export class HorizontalTile extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      catchWeight: props.item?.catchWeight,
    };
  }

  shouldComponentUpdate(nextProps: BaseProps): boolean {
    return (
      !deepEqual(this.props.item, nextProps.item) ||
      !deepEqual(this.props.promotions, nextProps.promotions) ||
      !deepEqual(this.props.className, nextProps.className)
    );
  }

  onCatchWeightChange = (catchWeight: number): void => {
    this.props.setCatchWeightTo(this.props.item, catchWeight);
    this.setState({ catchWeight });
  };

  onQuantityChange = (quantityValue: number, variantValue: CustomerUnitChoice = UNIT_PCS): void => {
    const { identifier, itemIndex, analyticsSettings, item, substitutionFor, incrementItemBy, removeItem } = this.props;
    const { catchWeight } = this.state;

    if (substitutionFor) {
      const newItem = { ...item, isSubstitute: false, seedProduct: undefined };

      incrementItemBy(
        quantityValue,
        catchWeight ? withCatchWeight(newItem, catchWeight) : newItem,
        {
          identifier: 'RCMD:SUB',
          gridPos: itemIndex,
          ...analyticsSettings,
        },
        variantValue as CustomerUnitChoice,
      );

      removeItem(substitutionFor, { sendAnalytics: false });
      scrollToAddedSubstitute();
      return;
    }

    incrementItemBy(
      quantityValue,
      catchWeight ? withCatchWeight(item, catchWeight) : item,
      {
        identifier,
        gridPos: itemIndex,
        ...analyticsSettings,
      },
      variantValue as CustomerUnitChoice,
    );
  };

  onAnonymousUserAdd = (quantityValue: number, variantValue: CustomerUnitChoice = UNIT_PCS): void => {
    // @ts-ignore
    anonymousUserAdd(this.props.item, this.props.buybox.formData, quantityValue, variantValue);
  };

  render(): JSX.Element {
    const {
      addFormParams,
      renderTermText = true,
      buybox,
      beansImage: { image: beansDefaultImageUrl, itemIsUnavailable },
      currency,
      productId,
      identifier,
      isInBasket,
      isRestrictedDisabled,
      isProductQtyIncrementable,
      isCatchWeightProduct,
      getProductDisplayType,
      href,
      disabled,
      title,
      promotions,
      available,
      restOfShelfLink,
      translate,
      writeAReviewData,
      infoMessages,
      isSubstitute,
      hasSubstitutes,
      onPromotionsClick,
      deleteActionButton,
      children,
      isTrolley,
      showFavHeartIcon,
      showTotalPrice,
      item,
      hasActions,
      className,
      resultType,
      flashSashInfo,
      showPromotionInfoBox,
      showCCPriceLogoInSquare,
      hasPromotion,
      preventDisabledTitleStyle,
      sellerInfo,
      renderSeeAlternativesButton,
      shouldShowPriceOnUnavailableTiles,
      shouldShowRestOfShelf,
      showFindASwapCTA,
      handleSubsContainerChange,
      flashSashInfoList,
      productDrsChargeAmount,
      region,
    } = this.props;

    const { item: { product: { foodIcons } = { icons: [] } } = { item: { product: { foodIcons: [] } } } } = this.props;

    const showClubcardPriceOnPromotions = flashSashInfo?.type === promoType.clubcardPrice;
    const productDrsPromotionText = productDrsChargeAmount ? `+ ${translate('deposit')}` : '';

    return (
      <StyledHorizontalTileWrapper isSubstitute={isSubstitute} className={className}>
        <StyledHorizontalTile
          data-auto="product-tile"
          data-auto-type={isCatchWeightProduct ? CATCH_WEIGHT : getProductDisplayType}
          data-auto-available={available}
          data-auto-is-restricted-disabled={isRestrictedDisabled}
          data-auto-is-substitute={isSubstitute}
          isInBasket={isInBasket}
          isUnavailable={!available}
          isSubstitute={isSubstitute}
          hasSubstitutes={hasSubstitutes}
          hasPromotion={hasPromotion}
          isCatchWeightProduct={isCatchWeightProduct}
          showFindASwapCTA={showFindASwapCTA}
        >
          <StyledTileContentWrapper className="beans" key="beans">
            <OffersSashList
              OffersList={flashSashInfoList}
              tileType={PRODUCT_TILE}
              tileVariant={HORIZONTAL}
              showCCPriceLogoInSquare={showCCPriceLogoInSquare}
              foodIcons={foodIcons}
            />
            {showFavHeartIcon && (
              <StyledProductActionsContainer isDeleteButtonRendered={!!deleteActionButton}>
                <StyledProductActions data-auto="product-actions">
                  <FavoriteHeartIcon translate={translate} />
                </StyledProductActions>
              </StyledProductActionsContainer>
            )}
            {!!deleteActionButton && (
              <StyledProductActionsContainer>{deleteActionButton}</StyledProductActionsContainer>
            )}
            <StyledTiledContent
              hasPromotion={hasPromotion}
              hasActions={hasActions}
              id={`tile-${productId}`}
              data-auto-id={productId}
              isItemUnavailable={shouldShowPriceOnUnavailableTiles && !available}
            >
              <DietaryLogoWithProductImageHorizontalWrapper>
                <BeansLazyImage
                  data-auto="product-image"
                  alt={title}
                  hideFromScreenReader
                  href={href}
                  image={beansDefaultImageUrl}
                  isUnavailable={itemIsUnavailable}
                  clickable={!disabled}
                />
                <DietaryLogo content={foodIcons} tileType={HORIZONTAL} />
              </DietaryLogoWithProductImageHorizontalWrapper>
              <div className="product-details--wrapper">
                <ProductTitle
                  disabled={disabled}
                  preventDisabledTitleStyle={preventDisabledTitleStyle}
                  href={href}
                  title={title}
                />
                {sellerInfo?.isPartneredProduct && (
                  <SellerWrapper>
                    <SellerInformation {...sellerInfo} translate={translate} />
                  </SellerWrapper>
                )}
                {hasPromotion && !showClubcardPriceOnPromotions && (
                  <StyledPromotionsAboveMobile
                    clickHandler={onPromotionsClick}
                    data-auto="product-promotions"
                    productDrsChargeAmount={productDrsChargeAmount}
                    productDrsPromotionText={productDrsPromotionText}
                    promotions={promotions}
                    region={region}
                    renderTermText={renderTermText}
                    showCCPriceLogoInSquare={showCCPriceLogoInSquare}
                    showPromotionInfoBox={showPromotionInfoBox}
                    translate={translate}
                  />
                )}
                <DietaryIconWithText content={foodIcons} tileType={HORIZONTAL} />
                <StyledProductDetailsContent hasActions={hasActions}>
                  {writeAReviewData && <WriteAReviewLink {...writeAReviewData} />}
                  {restOfShelfLink && (
                    <RestOfShelfLink
                      href={restOfShelfLink.href}
                      linkText={restOfShelfLink.linkText}
                      tileType={isTrolley ? TROLLEY : PRODUCT_TILE}
                    />
                  )}
                  {isSubstitute && renderSeeAlternativesButton?.()}
                  {/*
                    Same StyledPromotionsAboveMobile as above but duplicated for
                    different positioning when Clubcard promotion
                  */}
                  {showClubcardPriceOnPromotions && (
                    <StyledPromotionsAboveMobile
                      clickHandler={onPromotionsClick}
                      clubcardFlashSashInfoText={showClubcardPriceOnPromotions && flashSashInfo?.text}
                      data-auto="product-promotions"
                      isYellowClubcardPromotion={showClubcardPriceOnPromotions}
                      productDrsChargeAmount={productDrsChargeAmount}
                      productDrsPromotionText={productDrsPromotionText}
                      promotions={promotions}
                      region={region}
                      renderTermText={renderTermText}
                      showCCPriceLogoInSquare={showCCPriceLogoInSquare}
                      showPromotionInfoBox={showPromotionInfoBox}
                      tileVariant={HORIZONTAL}
                      translate={translate}
                    />
                  )}
                  {(!shouldShowPriceOnUnavailableTiles || available) && (
                    <InfoMessagesWrapper
                      showStockQtyExceedMessage={this.props.isTrolley}
                      infoMessagesData={infoMessages}
                      hasBackgroundColor={isTrolley ? true : false}
                      className={'product-info-message-section'}
                    />
                  )}
                </StyledProductDetailsContent>
              </div>
              <StyledBuyBoxWrapper
                isUnavailable={buybox.buyboxType === NO_BUYBOX}
                isItemUnavailable={shouldShowPriceOnUnavailableTiles && !available}
              >
                <BuyboxContainer
                  data-auto="product-controls"
                  translate={translate}
                  buyboxData={buybox}
                  increment={this.onQuantityChange}
                  onCatchWeightChange={this.onCatchWeightChange}
                  addFormParams={addFormParams}
                  identifier={identifier}
                  isProductQtyIncrementable={isProductQtyIncrementable}
                  className="buybox-container"
                  resultType={resultType}
                  onAnonymousUserAdd={this.onAnonymousUserAdd}
                />
                {showTotalPrice && (
                  <StyledTotalPriceWrapper>
                    <RenderPrice item={item} showTotalPrice />
                  </StyledTotalPriceWrapper>
                )}
                {isTrolley && productDrsChargeAmount && available && (
                  <>
                    <VisuallyHidden data-visually-hidden>{`${translate('includes')} ${
                      currency?.symbol
                    }${productDrsChargeAmount} ${translate('deposit')}`}</VisuallyHidden>
                    <StyledDrs aria-hidden={true}>{`${translate('includes')} ${getAmountWithCurrencyOrMinorCurrency(
                      productDrsChargeAmount,
                      currency?.symbol,
                      currency.isoCode,
                    )} ${translate('deposit')}`}</StyledDrs>
                  </>
                )}
                {/* {TODO: if oop-1934 is productionized, rethink condition } */}
                {this.props.isTrolley && !available && hasSubstitutes && showFindASwapCTA && (
                  <MultiSwapButton translate={translate} handleSubsContainerChange={handleSubsContainerChange} />
                )}
              </StyledBuyBoxWrapper>
              {shouldShowPriceOnUnavailableTiles && !available && (
                <StyledInfoMessagesWrapper shouldShowRestOfShelf={shouldShowRestOfShelf}>
                  <InfoMessagesWrapper
                    showStockQtyExceedMessage={this.props.isTrolley}
                    infoMessagesData={infoMessages}
                    className={'product-info-message-section'}
                    hasBackgroundColor={isTrolley ? true : false}
                  />
                </StyledInfoMessagesWrapper>
              )}
              <InfoMessagesWrapper
                infoMessagesData={infoMessages}
                className={'hidden-medium product-info-section-small'}
                hasBackgroundColor={isTrolley ? true : false}
              />
              {!isSubstitute && renderSeeAlternativesButton?.({ classes: 'hidden-small-medium' })}
            </StyledTiledContent>
            {children}
            {hasPromotion && (
              <StyledPromotionsMobile
                clickHandler={onPromotionsClick}
                clubcardFlashSashInfoText={showClubcardPriceOnPromotions && flashSashInfo?.text}
                data-auto="product-promotions-details-mobile"
                isYellowClubcardPromotion={showClubcardPriceOnPromotions}
                productDrsChargeAmount={productDrsChargeAmount}
                productDrsPromotionText={productDrsPromotionText}
                promotions={promotions}
                region={region}
                renderTermText={renderTermText}
                showCCPriceLogoInSquare={showCCPriceLogoInSquare}
                showPromotionInfoBox={showPromotionInfoBox}
                tileVariant={HORIZONTAL}
                translate={translate}
              />
            )}
          </StyledTileContentWrapper>
        </StyledHorizontalTile>
      </StyledHorizontalTileWrapper>
    );
  }
}

export default connector(withProductTile(HorizontalTile));
