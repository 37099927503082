/**
 * Experiment constants for bagging option intervention on order summary page
 */
export const baggingOptionCopyVariants = {
  key: 'oop856',
  variants: {
    default: '856-a',
    variantB: '856-b',
    variantC: '856-c',
  },
  useCustomerId: true,
};
