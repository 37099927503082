/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import DdsWebValueTile from '@ddsweb/value-tile';
import styled, { css } from 'styled-components';
import {
  VARIANT_HORIZONTAL,
  VARIANT_SQUARE_LARGE,
  VARIANT_SQUARE,
  VALUE_TILE_NEW_MODE,
  VALUE_TILE_CLUBCARD_MODE,
  VALUE_TILE_REGULAR_PRICE,
  VARIANT_HORIZONTAL_MICRO,
  VALUE_TILE_REGULAR_PRICE_CLUBCARD,
} from './value-tile-const';
import { base } from '@ddsweb/selectors';

export type TProps = {
  isAsterisk: boolean;
  tileVariant?: typeof VARIANT_HORIZONTAL | typeof VARIANT_SQUARE_LARGE | typeof VARIANT_SQUARE;
  mode?: typeof VALUE_TILE_REGULAR_PRICE_CLUBCARD | typeof VALUE_TILE_CLUBCARD_MODE | typeof VALUE_TILE_NEW_MODE;
  offerDetailHiddenText: string;
  termsApplyHiddenText: string;
  valueContent: { text: string; size: string; styles?: string | object }[];
  values: string[];
  regularPrice?: {
    values: string[];
    valueContent: { text: string; size: string; styles?: string | object }[];
    mode?: typeof VALUE_TILE_REGULAR_PRICE;
    variant?: typeof VARIANT_HORIZONTAL | typeof VARIANT_HORIZONTAL_MICRO;
  };
  region: string;
};

const clubcardTileWrapperSquareLargeVariant = css`
  top: 0;
  right: 0;
  bottom: auto;
  left: auto;
  width: initial;
  flex-direction: column-reverse;
`;

const clubcardTileWrapper = css`
  top: auto;
  right: auto;
  bottom: 0;
  left: 0;
  width: 100%;
  flex-direction: column;
`;

const RegularPriceClubCardTileWrapper = styled.div`
  font-family: ${base.fontFamily};
  position: absolute;
  display: flex;
  ${({ variant }) => (variant !== VARIANT_SQUARE_LARGE ? clubcardTileWrapper : clubcardTileWrapperSquareLargeVariant)};
  & span {
    display: inline;
  }
`;

RegularPriceClubCardTileWrapper.displayName = 'RegularPriceClubCardTileWrapper';

export const RegularPriceClubcardTile: React.FC<TProps> = (props: TProps) => {
  const {
    isAsterisk = false,
    mode,
    offerDetailHiddenText,
    termsApplyHiddenText,
    valueContent,
    values,
    regularPrice,
    region,
    tileVariant,
  } = props;

  let regularPriceValues, regularPriceMode, regularPriceValueContent, regularPriceVariant;

  if ('values' in regularPrice!) regularPriceValues = regularPrice!.values;
  if ('valueContent' in regularPrice!) regularPriceValueContent = regularPrice!.valueContent;
  if ('mode' in regularPrice!) regularPriceMode = regularPrice!.mode;
  if ('variant' in regularPrice!) regularPriceVariant = regularPrice!.variant;

  const clubCardPriceValueContent = valueContent;
  const clubCardPriceMode = mode;
  const clubCardPriceVariant = tileVariant;

  if (
    !regularPriceValueContent ||
    !regularPriceMode ||
    !regularPriceVariant ||
    !clubCardPriceValueContent ||
    !clubCardPriceMode ||
    !clubCardPriceVariant
  )
    return null;

  return (
    <RegularPriceClubCardTileWrapper variant={tileVariant}>
      {regularPrice && (
        <DdsWebValueTile
          mode={regularPriceMode}
          template={regularPriceValueContent}
          values={regularPriceValues}
          variant={{
            global: regularPriceVariant,
          }}
        />
      )}
      <div>
        <span className="visually-hidden">{offerDetailHiddenText}</span>
        <DdsWebValueTile
          asterisk={isAsterisk}
          mode={clubCardPriceMode}
          template={clubCardPriceValueContent}
          region={region}
          values={values}
          variant={{ global: clubCardPriceVariant }}
        />
        {isAsterisk && <span className="visually-hidden">{termsApplyHiddenText}</span>}
      </div>
    </RegularPriceClubCardTileWrapper>
  );
};
