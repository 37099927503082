import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from '#/lib/render/connect-deep-compare';
import classnames from 'classnames';
import { ContentTitle } from '#/components/cms-driven/content-title';
import { PageHeader } from '#/components/cms-driven/page-header';
import { BuyListTile } from '#/components/cms-driven/buylist-tile';
import { AccordionDDL } from '#/components/cms-driven/accordion_ddl';
import { OrderedList } from '#/components/cms-driven';
import CompetitionBanner from '#/components/cms-driven/competition-banner';
import RecommenderWrapper from '#/components/recommender';
import helpers from '#/lib/decorators/helpers';
import Results from '#/components/results';
import { getIsAmendBasket } from '#/selectors/trolley';
import { getCurrentUrl, getLoginUrl, getLanguageLink } from '#/reducers/app';
import { getIsUserAuthenticated } from '#/reducers/user';
import Link from '#/components/link-check-spa';
import { BUYLIST as BUYLIST_RESULT_TYPE } from '#/constants/result-types';
import { BUYLIST as BUYLIST_TILE_TYPE } from '#/constants/tile-types';
import { BUYLIST_DISPLAY_AREA } from '#/constants/recommendations';
import { BOTTOM } from '#/constants/common';
import { getCutsMustard } from '#/reducers/app';
import {
  getBuylistGroup,
  getBuylistGroupCategories,
  getBuylistGroupName,
  getBuylistGroupRecipe,
  getBuylistGroupTitle
} from '#/reducers/buylist';
import { getBuylistGroupResource } from '#/selectors/resources';
import { getBuylistRecommmendersType } from '#/selectors/experiments';
import { HORIZONTAL } from '#/components/product-tiles/common/constants';
import { generateIdPropFromTitle } from '#/lib/dcs/dcs-helpers';

const mapStateToProps = state => ({
  buylists: getBuylistGroup(state),
  categories: getBuylistGroupCategories(state),
  name: getBuylistGroupName(state),
  recipe: getBuylistGroupRecipe(state),
  title: getBuylistGroupTitle(state),
  currentUrl: getCurrentUrl(state),
  cutsMustard: getCutsMustard(state),
  isAmendBasket: getIsAmendBasket(state),
  languageLink: url => getLanguageLink(state, url),
  loginUrl: getLoginUrl(state),
  isUserAuthenticated: getIsUserAuthenticated(state),
  buylistGroupResource: getBuylistGroupResource(state),
  recommendersType: getBuylistRecommmendersType(state)
});

const SHOW_SIBLING_BUYLIST = 'showSiblingBuylist';
const LINK_NEW_WINDOW = 'linkNewWindow';
@connect(mapStateToProps)
@helpers(['c', 't'])
export default class BuylistGroup extends Component {
  static propTypes = {
    buylistGroupResource: PropTypes.object.isRequired,
    buylists: PropTypes.array,
    categories: PropTypes.array,
    currentUrl: PropTypes.string.isRequired,
    cutsMustard: PropTypes.bool.isRequired,
    isAmendBasket: PropTypes.bool.isRequired,
    isUserAuthenticated: PropTypes.bool.isRequired,
    languageLink: PropTypes.func.isRequired,
    loginUrl: PropTypes.string.isRequired,
    name: PropTypes.string,
    preview: PropTypes.bool,
    recipe: PropTypes.object,
    recommendersType: PropTypes.oneOf([false, BOTTOM]),
    resources: PropTypes.shape({
      buylist: PropTypes.shape({
        data: PropTypes.shape({
          buylistGroup: PropTypes.shape({
            title: PropTypes.string,
            name: PropTypes.string,
            buylists: PropTypes.array,
            items: PropTypes.object
          })
        })
      })
    }),
    t: PropTypes.func.isRequired,
    title: PropTypes.string
  };

  static childContextTypes = {
    currentUrl: PropTypes.string
  };

  getChildContext() {
    return {
      currentUrl: this.props.currentUrl
    };
  }

  getListedTPNBs() {
    const items = this.props.buylistGroupResource.data.results.productItems;
    return items.map(item => {
      return item.product.baseProductId;
    });
  }

  getLinkNewWindowFromAttributes(attributes = []) {
    return attributes.some(
      ({ name, value }) => name === LINK_NEW_WINDOW && value === 'Yes'
    );
  }

  getShowSiblingBuylist(attributes = []) {
    return attributes.some(
      ({ name, value }) => name === SHOW_SIBLING_BUYLIST && value === 'Yes'
    );
  }

  renderRecommender() {
    return (
      <RecommenderWrapper
        displayArea={BUYLIST_DISPLAY_AREA}
        configKey={BUYLIST_RESULT_TYPE}
        page={BUYLIST_DISPLAY_AREA}
        excludedProducts={this.getListedTPNBs()}
      />
    );
  }

  render() {
    const {
      cutsMustard,
      recommendersType,
      title,
      name,
      buylists,
      categories,
      recipe
    } = this.props;
    const bottomRecommenders = recommendersType === BOTTOM;
    const buylist = buylists.find(bl => bl.isSelected);

    // DCS cleanup: Need to check name & title until DCS migrates all buylistGroups to have a name
    const buylistGroupUriValue = name || (title && encodeURIComponent(title));

    const generatePlacementID = (() => {
      let placementID = 0;

      return () => placementID++;
    })();

    const generateBuyListURI = (
      buylistGroupName,
      buylistName,
      category,
      preview,
      contentTitle
    ) => {
      let uri = `/buylists/${buylistGroupName.toLowerCase()}`;

      if (buylistName) {
        uri = uri.concat(`/${buylistName.toLowerCase()}`);

        if (category) {
          uri = uri.concat(`/${category}`);
        }
      }

      if (preview) {
        uri = uri.concat('/preview');
      }

      if (contentTitle) {
        uri = uri.concat(`#${generateIdPropFromTitle(contentTitle).id}`);
      }

      return this.props.languageLink(uri);
    };

    const getContentTitle = buylist => {
      const contentTitle = buylist?.label || buylist?.name || '';
      return contentTitle;
    };

    const navigationAccordion =
      buylists.length > 1 ? (
        <div className="buylist-accordion buylist-nav">
          <AccordionDDL
            id="buylist-accordion"
            title={`Show more ${title}`}
            textContent={''}
            queryString={'buy-lists'}
            cutsMustard={cutsMustard}
            startExpanded={this.getShowSiblingBuylist(
              buylist.banner?.bannerAttributes
            )}
            style={{
              indented: true,
              priority: 'high',
              roundedCorners: true
            }}
          >
            <ul className="buylist--nav-list">
              {buylists.map((bl, index) => {
                const image = {
                  src: bl.thumbnailUrl,
                  maxWidth: 145
                };
                const link = {
                  href: generateBuyListURI(
                    buylistGroupUriValue,
                    bl.name,
                    null,
                    this.props.preview,
                    getContentTitle(bl)
                  ),
                  label: bl.label || bl.name // DCS cleanup: Need to check label & name until DCS migrates all buylists to have a label
                };

                return (
                  <li className="buylists__nav-item" key={index}>
                    <BuyListTile
                      image={image}
                      link={link}
                      placement={generatePlacementID()}
                      hoverEffect={!bl.isSelected}
                      selected={bl.isSelected}
                    />
                  </li>
                );
              })}
            </ul>
          </AccordionDDL>
        </div>
      ) : null;

    const recipeAccordion = recipe && recipe.steps && recipe.steps.length > 1 && (
      <div className="recipe buylist-accordion">
        <AccordionDDL
          id="recipe-accordion"
          title={recipe.title}
          textContent={''}
          cutsMustard={cutsMustard}
          startExpanded={recipe.expandedByDefault}
          style={{
            indented: true,
            priority: 'high',
            roundedCorners: true
          }}
        >
          <OrderedList className="recipe" listItems={recipe.steps} />
        </AccordionDDL>
      </div>
    );

    const filterList =
      categories.length > 1 ? (
        <div>
          <ContentTitle
            title={`${this.props.t('sort-and-filter:filter-by')}:`}
            level={4}
          />
          <ul className="buylist--filter-list">
            {categories
              .filter(cat => cat.binCount > 0 && !cat.hiddenFromFilter)
              .map((cat, index) => (
                <li
                  key={index}
                  className={classnames({ FilterItemActive: cat.isSelected })}
                >
                  <Link
                    to={generateBuyListURI(
                      buylistGroupUriValue,
                      buylist.name,
                      cat.name,
                      this.props.preview,
                      getContentTitle(buylist)
                    )}
                  >
                    <span>
                      {cat.label || cat.name} {`(${cat.binCount})`}
                    </span>
                  </Link>
                  {/* DCS cleanup: Need to check label & name until DCS migrates all buylists to have a label */}
                </li>
              ))}
          </ul>
        </div>
      ) : null;

    let buylistBanner = null;
    const bannerURI =
      buylist && buylist.bannerLinkUrl
        ? this.props.languageLink(buylist.bannerLinkUrl)
        : '';
    const BannerComponent = {
      SimpleBanner: props =>
        props.imageUrl && (
          <PageHeader
            image={{ src: props.imageUrl, maxWidth: 900 }}
            placement={generatePlacementID()}
            linkUrl={bannerURI}
            openInNewTab={
              props.banner &&
              this.getLinkNewWindowFromAttributes(props.banner.bannerAttributes)
            }
          />
        ),
      CompetitionBanner: props => (
        <CompetitionBanner
          loginUrl={this.props.loginUrl}
          isUserAuthenticated={this.props.isUserAuthenticated}
          {...props}
        />
      )
    };

    if (buylist && buylist.imageUrl) {
      buylistBanner = (
        <PageHeader
          image={{
            src: buylist.imageUrl,
            maxWidth: 900
          }}
          placement={generatePlacementID()}
          linkUrl={bannerURI}
          openInNewTab={
            buylist.banner &&
            this.getLinkNewWindowFromAttributes(buylist.banner.bannerAttributes)
          }
        />
      );
    } else if (
      buylist &&
      buylist.banner &&
      typeof BannerComponent[buylist.banner.bannerType] === 'function'
    ) {
      buylistBanner = BannerComponent[buylist.banner.bannerType](
        buylist.banner
      );
    }
    return (
      <div className="buylists clear">
        <ContentTitle title={title} level={2} />
        {buylistBanner}
        {recipeAccordion}
        {navigationAccordion}
        <ContentTitle title={getContentTitle(buylist)} level={1} />
        {/* DCS cleanup: Need to check label & name until DCS migrates all buylists to have a label */}
        {filterList}
        <div className="buylists-results">
          <Results
            isSearchResult={false}
            isGrid={false}
            showFilters={false}
            resultType={BUYLIST_RESULT_TYPE}
            tileType={BUYLIST_TILE_TYPE}
            productTileVariant={HORIZONTAL}
            {...this.props}
          />
        </div>
        {bottomRecommenders && this.renderRecommender()}
      </div>
    );
  }
}
