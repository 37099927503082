import React, { useEffect, useMemo } from 'react';
import classnames from 'classnames';
import { connect } from '#/lib/render/connect-deep-compare';
import { fetchIBYCRecommendations } from '#/experiments/oop-2349/actions/ibyc-recommendations-action-creators';
import helpers from '#/lib/decorators/helpers';
import { getSearchResource } from '#/selectors/resources';
import Recommender from '#/components/recommender/recommender';
import { getIBYCRecommendations, getIBYCTrexStatus, getIbycPageId } from '#/experiments/oop-2349/reducers';
import { getTrexEnabled } from '#/reducers/app';
import { getRecommenderConfig } from '#/lib/recommender-helpers';
import { getItems } from '#/selectors/trolley';
import { findAndSetTrolleyItems, itemsToMap } from '#/lib/records/product-utils';
import { getIBYCCarouselVariantLogic, getIBYCCarouselVariantStrategy } from '#/experiments/oop-2349/selectors';
import { Item } from '#/lib/records/item';
import { Items } from '#/custom-typings/redux-store/trolley.defs';
import { TConfigFunc } from '#/lib/records/helpers.defs';
import {
  IBYCCarouselVariants,
  IBYC_IDENTIFIER,
  IBYC_RECOMMENDATION_CAROUSEL_TYPE,
} from '#/experiments/oop-2349/constants';
import { getTargetTPNBs } from '../../helpers';

type OwnProps = {
  c: TConfigFunc;
  classes?: string;
  configKey: string;
  excludedProducts?: Array<string>;
  fetchIBYCRecommendations: Function;
  isIBYCRecommendation: boolean;
  moduleType?: string;
  query?: string;
  pageId: string;
  recommendationsCount?: number;
  showBeansCarousel?: boolean;
  targetTPNBs?: Array<string>;
};

type StateProps = {
  recommendations: Array<Item>;
  trexEnabled: boolean;
  trolleyItems: Array<Item>;
  variant: string;
  isIBYCTrexEmpty: boolean;
  ibycPageId: string;
  query: string;
  ibycCarouselLogic: IBYCCarouselVariants;
};

type Props = OwnProps & StateProps;

const mapStateToProps = (state: Store): StateProps => ({
  recommendations: getIBYCRecommendations(state),
  // @ts-expect-error
  trexEnabled: getTrexEnabled(state),
  isIBYCTrexEmpty: getIBYCTrexStatus(state, 'empty'),
  trolleyItems: getItems(state),
  variant: getIBYCCarouselVariantStrategy(state),
  ibycCarouselLogic: getIBYCCarouselVariantLogic(state),
  ibycPageId: getIbycPageId(state),
  query: getSearchResource(state)?.data?.query || '',
});

const IBYCRecommenderWrapper: React.FC<Props> = props => {
  const { c: config, classes, trexEnabled, recommendations, trolleyItems } = props;

  const recommenderConfig = getRecommenderConfig(props);
  const isRecommendationEnabled = config('recommenders:enabled') && trexEnabled; // first flag from config, second one from env variable
  const dataLoaded = !(recommendations == null);

  const mergeRecommendationItemsWithQuantities = (recommendations: Array<Item> | Items = []): Items => {
    const { trolleyItems } = props;

    const productItems = [...recommendations.values()];
    const productItemsWithQuantities = findAndSetTrolleyItems(productItems, trolleyItems);

    return itemsToMap(productItemsWithQuantities);
  };

  const recommendationsWithQuantities = useMemo(() => {
    if (recommendations) {
      return mergeRecommendationItemsWithQuantities(recommendations);
    }
    return [];
  }, [recommendations, trolleyItems]);

  useEffect(() => {
    if (!dataLoaded) loadRecommendations();
  }, []);

  const loadRecommendations = (): void => {
    const {
      excludedProducts = [],
      fetchIBYCRecommendations,
      query,
      targetTPNBs,
      recommendationsCount = 24,
      variant,
      ibycCarouselLogic,
      trolleyItems,
    } = props;
    const { position, pageName, pageId } = recommenderConfig;

    fetchIBYCRecommendations({
      pageId,
      excludedProducts,
      position,
      pageName,
      variant,
      query,
      targetTPNBs: getTargetTPNBs(targetTPNBs, ibycCarouselLogic, trolleyItems, excludedProducts),
      count: recommendationsCount,
    });
  };

  const renderRecommender = (): JSX.Element => {
    const {
      configKey,
      moduleType,
      showBeansCarousel = false,
      isIBYCRecommendation,
      ibycPageId,
      isIBYCTrexEmpty,
      pageId,
    } = props;

    return (
      <Recommender
        key={configKey}
        configKey={configKey}
        panelPos={1}
        recommendations={dataLoaded ? recommendationsWithQuantities : null}
        lastTrex
        isTrexEmpty={dataLoaded && isIBYCTrexEmpty}
        pageId={pageId || recommenderConfig.pageId}
        panelType={IBYC_RECOMMENDATION_CAROUSEL_TYPE}
        moduleType={moduleType || recommenderConfig.moduleType}
        showLoading={!dataLoaded}
        showRestOfShelf
        showWriteReview
        showBeansCarousel={showBeansCarousel}
        trexIdentifier={IBYC_IDENTIFIER}
        isIBYCRecommendation={isIBYCRecommendation}
        ibycPageId={ibycPageId}
      />
    );
  };

  if (!isRecommendationEnabled) {
    return null;
  }

  return <div className={classnames('ibyc-recommender-wrapper', classes)}>{renderRecommender()}</div>;
};

const connector = connect(mapStateToProps, {
  fetchIBYCRecommendations,
});

export default helpers(['c'])(connector(IBYCRecommenderWrapper));
