import {
  getAppRegion,
  getConsumer,
  getIsDesktop,
  getIsMobile,
  getIsTablet,
  getLanguage,
  getUserAgent,
  getBrowserType,
  getBrowserTypeAndVersion
} from '#/reducers/app';
import { getTrolleyShoppingMethod } from '#/selectors/trolley';
import { NONE } from '#/constants/slot-statuses';

import {
  getAtrc,
  getIsUserAuthenticated,
  getUserStoreId,
  getUserUuid,
  getUserHashedUId
} from '#/reducers/user';
import { MOBILE, TABLET, DESKTOP } from '#/constants/platforms';
import {
  setHoldoutAttributes,
  getUniqueIdForStateOrProps
} from './optimizely-attributes-helpers';
import { getUserUniqueId } from '#/utils/user-utils';

const NO_SLOT_BOOKED = 'noslotbooked';
const getTrolleySlotStatus = ({ trolley }) => trolley?.trolley?.slot?.status;
const getFulfillmentMethod = (shoppingMethod, trolleyStatus = NONE) => {
  if (trolleyStatus === NONE) {
    return NO_SLOT_BOOKED;
  }
  return shoppingMethod;
};
// CORE ATTRIBUTES THAT MUST BE PASSED
// {
//   $opt_user_agent,
//   consumer,
//   fulfillmentMethod,
//   isDesktop,
//   isMobile,
//   isTablet,
//   language,
//   loggedIn,
//   site,
//   storeId,
//   trolleySlotStatus,
//   uniqueId,
// }
// Additional (Per Tests attributes should be passed in a additionalAttributes object

// These are the core attributes required by Optimizely.
// Additional attributes may be added but these MUST be included in all these functions
export const getAttributesFromState = (
  state,
  useCustomerId = false,
  additionalAttributes = {}
) => {
  const atrc = getAtrc(state);
  const region = getAppRegion(state);
  const uuid = getUserUuid(state);
  const hashedUId = getUserHashedUId(state);
  const userUniqueId = getUserUniqueId(region, uuid, hashedUId);
  const args = {
    region,
    uniqueId: userUniqueId,
    useCustomerId,
    atrc
  };

  const uniqueId = getUniqueIdForStateOrProps(args);

  if (useCustomerId) {
    setHoldoutAttributes(additionalAttributes, uniqueId);
  }
  return {
    $opt_user_agent: getUserAgent(state),
    consumer: String(getConsumer(state)),
    fulfillmentMethod: getFulfillmentMethod(
      getTrolleyShoppingMethod(state),
      getTrolleySlotStatus(state)
    ),
    isDesktop: String(getIsDesktop(state)),
    isMobile: String(getIsMobile(state)),
    isTablet: String(getIsTablet(state)),
    language: getLanguage(state),
    loggedIn: String(getIsUserAuthenticated(state)),
    site: `${state.ui.optimizelySiteId}-${getAppRegion(state)}`,
    storeId: getUserStoreId(state),
    trolleySlotStatus: getTrolleySlotStatus(state) || NONE,
    uniqueId,
    ...additionalAttributes
  };
};

// These are the core attributes required by optimizely.
// Additional attributes may be added but these MUST be included in all these functions
export const getAttributesFromReqAndRes = (
  req,
  res,
  useCustomerId,
  deviceTypeFromCall,
  additionalAttributes = {}
) => {
  const props = res.locals.props;

  const { slot, shoppingMethod = NO_SLOT_BOOKED } =
    res.locals.props?.trolley ?? {};
  const siteId = req.c('optimizely:siteId');
  const {
    consumer,
    language,
    region,
    deviceType = deviceTypeFromCall
  } = props.app;
  const $opt_user_agent =
    (props.app && props.app.userAgent) || req.get('User-Agent');

  const atrc = props.user?.atrc || req.cookies.atrc;
  const hashedUId = props.user?.hashedUId || (req.user && req.user.hashedUId);
  const uuid = props.user?.uuid || (req.user && req.user.uid);
  const userUniqueId = getUserUniqueId(region, uuid, hashedUId);

  const args = {
    region,
    uniqueId: userUniqueId,
    useCustomerId,
    atrc
  };
  let uniqueId = getUniqueIdForStateOrProps(args);
  const storeId =
    (props.user && props.user.storeId) || (req.user && req.user.storeId);

  if (useCustomerId) {
    setHoldoutAttributes(additionalAttributes, uniqueId);
  }

  return {
    $opt_user_agent, // The user agent will not be available in the props if not rendering through React
    browserName: String(getBrowserType(props)),
    browserTypeAndVersion: String(getBrowserTypeAndVersion(props)),
    consumer,
    fulfillmentMethod: getFulfillmentMethod(shoppingMethod, slot?.status),
    isDesktop: String(deviceType === DESKTOP),
    isMobile: String(deviceType === MOBILE),
    isTablet: String(deviceType === TABLET),
    language,
    loggedIn: String(req.isAuthenticated()),
    site: `${siteId}-${region}`,
    storeId,
    trolleySlotStatus: slot?.status || NONE,
    uniqueId,
    ...additionalAttributes
  };
};
