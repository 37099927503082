import styled from 'styled-components';
import Modal from '@ddsweb/modal';
import { spacing, fontSize, colors, base } from '@ddsweb/selectors';
import { SubHeading, BodyText } from '@ddsweb/typography';
import { media } from '@ddsweb/foundation';

export const StyledModal = styled(Modal)`
  .ddsweb-modal__mask {
    z-index: 16;
  }
  ${Modal.ContentContainer} {
    button {
      width: auto;
    }
  }
`;

export const Content = styled.div`
  margin-bottom: ${spacing.lg};
  margin-right: ${spacing.xxxl};

  ${media.belowMobileLarge`margin-right: 0`};
`;

export const ButtonContainer = styled.section`
  display: flex;
  justify-content: flex-start;
  gap: ${spacing.md};
`;

export const StyledHeading = styled(SubHeading)`
  margin: ${spacing.xs} 0;
  font-size: ${fontSize.md};
`;

export const StyledBodyText = styled(BodyText)`
  padding: ${spacing.xs} 0;
`;
export const StyledCheckbox = styled.div`
  margin-top: ${spacing.sm};
  font-family: ${base.fontFamily};
  color: ${colors.text};
  &label {
    font-size: ${spacing.md};
  }
`;
