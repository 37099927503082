import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Link from '../../link-check-spa';
import Icon from '../../shared/icon';

function renderIcon(icon) {
  return icon.url ? (
    <Link target="_blank" rel="noreferrer noopener" to={icon.url}>
      {icon.name}
    </Link>
  ) : (
    <span className="visually-hidden">{icon.name}</span>
  );
}

function renderIcons(icons) {
  return icons.map((icon, i) => {
    return (
      <li
        className={classnames('icon', icon.className)}
        key={i}
        title={icon.title}
      >
        {renderIcon(icon)}
      </li>
    );
  });
}

function renderHelpIcon(text, url, className) {
  return (
    <li className="icon icon-card-help">
      <Link
        target="_blank"
        rel="noreferrer noopeneer"
        to={url}
        className={`${className}__help-link`}
      >
        <Icon name="info2-small-blue" hiddenText={text} />
      </Link>
    </li>
  );
}

function IconList(props) {
  const shouldRenderHelpIcon =
    props.helpIconDisplayed && props.helpIconText && props.helpIconUrl;

  return (
    <div className={classnames('icon-list', props.className)}>
      {props.listHeader && (
        <p className="icon-list--list-header">{props.listHeader}</p>
      )}
      <ul
        className={classnames('icon-list', props.format)}
        title={props.listTitle}
      >
        {renderIcons(props.icons)}
        {shouldRenderHelpIcon &&
          renderHelpIcon(
            props.helpIconText,
            props.helpIconUrl,
            props.className
          )}
      </ul>
    </div>
  );
}

IconList.propTypes = {
  className: PropTypes.string,
  format: PropTypes.oneOf(['horizontal', 'vertical']),
  helpIconDisplayed: PropTypes.bool,
  helpIconText: PropTypes.string,
  helpIconUrl: PropTypes.string,
  icons: PropTypes.array.isRequired,
  listHeader: PropTypes.string,
  listTitle: PropTypes.string
};

IconList.defaultProps = {
  format: 'horizontal',
  helpIconDisplayed: false,
  helpIconText: null,
  helpIconUrl: null,
  listHeader: null
};

export default IconList;
