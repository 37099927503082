import { getVariationForExperiment } from '#/lib/optimizely-manager';
import { persistTaxonomyVariants } from '../constants';

const {
  key,
  useCustomerId,
  variants: { persistTaxonomyPosition },
} = persistTaxonomyVariants;

export const shouldPersistTaxonomyPosition = (state: Store): boolean => {
  const variant = getVariationForExperiment(state, key, {}, useCustomerId);
  return variant === persistTaxonomyPosition;
};
