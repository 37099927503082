import React, { Component } from 'react';
import { connect } from '#/lib/render/connect-deep-compare';
import PropTypes from 'prop-types';
import { getCsrfToken } from '#/reducers/app';

const mapStateToProps = state => ({
  csrfToken: getCsrfToken(state)
});

@connect(mapStateToProps)
export default class SafeForm extends Component {
  static propTypes = {
    action: PropTypes.string,
    children: PropTypes.any,
    csrfToken: PropTypes.string.isRequired,
    forwardRef: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.any })
    ]),
    method: PropTypes.string
  };
  render() {
    const { csrfToken, forwardRef = 'form', ...formProps } = this.props;

    delete formProps.dispatch;

    return (
      <form {...formProps} ref={forwardRef}>
        {this.props.children}
        <input name="_csrf" type="hidden" value={csrfToken} />
      </form>
    );
  }
}
