import React, { useState, FC, ChangeEvent } from 'react';
import { connect } from '#/lib/render/connect-deep-compare';
import helpers from '#/lib/decorators/helpers';
import { ConnectedProps } from 'react-redux';
import { getActiveExperimentsFromWindow } from '#/lib/open-feedback-window';
import { TTranslateFunc } from '#/lib/records/helpers.defs';
import { isValidText } from '#/utils/text-utils';
import { getCutsMustard, getDeviceType, getBrowserType } from '#/reducers/app';
import { getUserStoreId, getIsFirstTimeShopper, getUserHashedUId } from '#/reducers/user';
import { RatingBasicOwnProps, MapStateToPropsRetType, CustomWindow } from './index.defs';
import { StyledTextAreaFeedback, StyledSubmitButton, TextFeedbackWrapper } from './styled';
import analyticsBus from '#/analytics/analyticsBus';
import { basicEvent } from '#/analytics/types/basic';
import { NOW } from '#/analytics/constants';

const mapStateToProps = (state): MapStateToPropsRetType => ({
  browserType: getBrowserType(state),
  cutsMustard: getCutsMustard(state),
  deviceType: getDeviceType(state),
  hashedUId: getUserHashedUId(state),
  isFirstTimeShopper: getIsFirstTimeShopper(state),
  storeId: getUserStoreId(state),
});

const connector = connect(mapStateToProps);

type OwnProps = {
  t: TTranslateFunc;
  csatConfig: {
    title: string;
    type: string;
    cta: string;
  };
};

type TProps = OwnProps & RatingBasicOwnProps & ConnectedProps<typeof connector>;

const TextFeedback: FC<TProps> = ({
  browserType,
  cutsMustard,
  deviceType,
  hashedUId,
  isFirstTimeShopper,
  storeId,
  refererUrl,
  t: translate,
  csatConfig,
}) => {
  const [feedbackText, setFeedbackText] = useState('');
  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    const value = e.target.value;
    if (isValidText(value)) {
      setFeedbackText(value);
    }
  };

  const triggerAnalytics = (): void => {
    basicEvent(analyticsBus, {
      action: NOW,
      type: 'csat module',
      value: 'submit',
    });
  };

  const onSubmitClick = (event: React.SyntheticEvent): void => {
    event?.preventDefault();
    /* 
      'oo_launchTextFeedback' is the method that is defined during build time at 'web/assets/scripts/third-party/oo_conf_inline.js' (which is a 3rd party script)
      'ghs_customerID' and 'comment' are the variables that OnlineOpinion (a 3rd party) accepts.
      Hence, the TS rule for 'camelcasing' has been disabled below at necessary places
    */

    const customerVariables = {
      browserType,
      cutsMustard: String(cutsMustard),
      deviceType,
      ghsUUID: hashedUId,
      ghs_customerID: hashedUId,
      isFirstTimeShopper: String(isFirstTimeShopper),
      storeId,
      comment: feedbackText,
      url: refererUrl,
    };

    (window as CustomWindow).OOo.oo_launchTextFeedback({
      ...customerVariables,
      ...getActiveExperimentsFromWindow(),
    });
    triggerAnalytics();
  };

  return (
    <TextFeedbackWrapper>
      <StyledTextAreaFeedback
        id="text-feedback"
        labelText={csatConfig?.title}
        placeholder={translate('favorites:enter-suggestions')}
        value={feedbackText}
        onChange={handleChange}
        name="newValue"
        required={true}
        maxCount={200}
        rows={4}
        className="text-feedback--textarea"
      />
      <StyledSubmitButton disabled={!feedbackText.trim()} onClick={onSubmitClick}>
        {csatConfig.cta}
      </StyledSubmitButton>
    </TextFeedbackWrapper>
  );
};

export default helpers(['t'])(connector(TextFeedback));
