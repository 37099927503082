/***************************************************************
 * Left Hand Navigation Component
 * A component that renders the left hand nav on mobile
 * it uses the navigation.yaml to get the links to render.
 * Language switch form is also incorporated here
 *
 * functions include:
 *
 * data() - returns the data from the navigation.yaml
 *
 * getMenu() -  retrieves the menu from the navigation.yaml.
 *              On the first item we add "Shop all groceries"
 *              as it needs different behaviour
 *
 * getMenuItems() - gets the menuitems for a given object from
 *                  navigation.yaml
 *
 * languageSwitch() - adds the language switch form into the menu
 *
 * getText(text) - returns the text for the given text argument
 ****************************************************************/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Feedback from '../shared/feedback/';
import helpers from '#/lib/decorators/helpers';
import ListItemContent from './list-item-content';
import ContextCard from '../context-cards/slot';
import delimitQuery from '../mixins/delimit-query';
import {
  closeLeftNav,
  openNavMenu,
  showHeaderSearch
} from '#/actions/ui-action-creators';
import { connect } from '#/lib/render/connect-deep-compare';
import LanguageSwitchLink from '../language-switch-link';
import { MOBILE, TABLET } from '#/constants/platforms';
import {
  getHost,
  getLoginUrl,
  getProtocol,
  getIsTablet,
  getIsMobile,
  getCurrentUrl,
  getLanguageLink
} from '#/reducers/app';
import { updateParamsInUrl } from '#/lib/url/url-utils';
import { getDefaultSlotsPath } from '#/reducers/slot';
import {
  APP_DOWNLOAD,
  NOW,
  BURGER_MENU,
  TOP_NAV,
  INSPIRE_ME,
  DELAY
} from '#/analytics/constants';
import analyticsBus from '#/analytics/analyticsBus';
import { basicEvent } from '#/analytics/types/basic';
import { getLanguage, getOperatingSystem } from '#/reducers/app';
import { getIsAmendBasket, getIsOnDemandDelivery } from '#/selectors/trolley';
import { getShouldShowRecipeZonePage } from '#/experiments/oop-812/selectors';
import { getExperienceCookieAccepted } from '#/reducers/ui';
import { parseQueryString } from '#/lib/url/url-utils';
import { getShouldUseClubcardPricesName } from '#/experiments/oop-1029/selectors';
import FavListItemContent from '#/experiments/oop-1716/left-hand-navigation/list-item-content';
import { getShouldShowFavDropdown } from '#/experiments/oop-1716/selectors';
import { MEALS_AND_RECIPE_MENU_KEY } from '#/experiments/oop-1946/constants';
import { getShouldShowMealsAndRecipes } from '#/experiments/oop-1946/selectors';
import {
  getIsRecipeMenuKey,
  getIsMealsAndRecipeMenuKey
} from '#/experiments/oop-1946/helpers';
import { isOnFavouritesPage } from '#/lib/favorites-helpers';
import {
  getIsTaxonomyVariant,
  getIsZonePageVariant
} from '#/experiments/oop-1890/selectors';
import { getIsInspireMeMenuKey } from '#/experiments/oop-1890/helpers';
import { INSPIRE_ME_MENU_KEY } from '#/experiments/oop-1890/constants';
import { getShouldPersistFilters } from '#/experiments/oop-1801/helpers';
import { getFavoritesLanguageLink } from '#/utils/favorite-utils';
import { getShouldRememberPaginationVariant } from '#/experiments/oop-1801/selectors';
import { triggerTopNavAnalytics } from '#/experiments/oop-1946/analytics';

const mapStateToProps = (state, { f }) => ({
  defaultSlotsPath: getDefaultSlotsPath(f)(state),
  hideBookSlotLink: getIsAmendBasket(state) && !f('allowAmendSlot'),
  host: getHost(state),
  loginUrl: getLoginUrl(state),
  protocol: getProtocol(state),
  isTablet: getIsTablet(state),
  isMobile: getIsMobile(state),
  currentUrl: getCurrentUrl(state),
  shopUrl: getLanguageLink(state, '/shop'),
  language: getLanguage(state),
  operatingSystem: getOperatingSystem(state),
  showClubcardPricesName: getShouldUseClubcardPricesName(state),
  showRecipeZonePage: getShouldShowRecipeZonePage(state),
  hidePromotionLink: getIsOnDemandDelivery(state),
  isExperienceCookiesAccepted: getExperienceCookieAccepted(state),
  shouldShowFavDropdown: getShouldShowFavDropdown(state),
  shouldShowInspireMeAsLink: getIsZonePageVariant(state),
  shouldShowInspireMeAsTaxonomy: getIsTaxonomyVariant(state),
  shouldPersistFavoriteState: getShouldPersistFilters(
    getShouldRememberPaginationVariant(state)
  ),
  favTabLink: getFavoritesLanguageLink(state, '/favorites'),
  isMealsAndRecipeEnabled: getShouldShowMealsAndRecipes(state)
});

const mapDispatchToProps = dispatch => {
  return {
    onNavigationListItemClick: menuKey => {
      dispatch(closeLeftNav());
      dispatch(openNavMenu(menuKey));
      dispatch(showHeaderSearch());
    }
  };
};

@helpers(['c', 'externalSecureLink', 'f', 'l', 't'])
@connect(mapStateToProps, mapDispatchToProps)
export default class LeftHandNavigation extends Component {
  static propTypes = {
    c: PropTypes.func.isRequired,
    cmsNav: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    currentUrl: PropTypes.string.isRequired,
    defaultSlotsPath: PropTypes.string.isRequired,
    externalSecureLink: PropTypes.func.isRequired,
    f: PropTypes.func.isRequired,
    favTabLink: PropTypes.string,
    host: PropTypes.string.isRequired,
    isExperienceCookiesAccepted: PropTypes.bool,
    shouldShowFavDropdown: PropTypes.bool,
    isMobile: PropTypes.bool.isRequired,
    isTablet: PropTypes.bool.isRequired,
    isMealsAndRecipeEnabled: PropTypes.bool.isRequired,
    isUserAuthenticated: PropTypes.bool.isRequired,
    l: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    loginUrl: PropTypes.string.isRequired,
    noOfPendingOrders: PropTypes.number,
    onNavigationListItemClick: PropTypes.func.isRequired,
    operatingSystem: PropTypes.string.isRequired,
    protocol: PropTypes.string.isRequired,
    selectedFacetLists: PropTypes.array,
    shouldPersistFavoriteState: PropTypes.bool,
    shopUrl: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired
  };

  static defaultProps = {
    selectedFacetLists: [],
    shouldPersistFavoriteState: false
  };

  data() {
    const { c, language } = this.props;
    return c(`navigation:${language}`);
  }

  listItemClick = e => {
    this.props.onNavigationListItemClick();
    e.preventDefault();
    e.stopPropagation();
  };

  mealsAndRecipesClick = e => {
    triggerTopNavAnalytics();
    this.props.onNavigationListItemClick(MEALS_AND_RECIPE_MENU_KEY);
    e.preventDefault();
    e.stopPropagation();
  };

  getMenu() {
    return this.data().map((leftLink, i) => {
      const showShop = i === 0;
      const { shopUrl, t } = this.props;

      return [
        <li className="list-header" role="menuitem" key={`${i}-header`}>
          {t(leftLink.categoryTitle)}
        </li>,
        showShop ? (
          <li className="list-item" key={`${i}-shop`}>
            <ListItemContent
              iconLeft="icon-shop_all_groceries-01"
              link={shopUrl}
              onClick={this.listItemClick}
              text={t('layout:main-nav.shop-all-groceries')}
            />
          </li>
        ) : null,
        ...this.getMenuItems(leftLink)
      ];
    });
  }

  getMenuItems(item) {
    const {
      hideBookSlotLink,
      f: feature,
      hidePromotionLink,
      shouldShowInspireMeAsLink,
      shouldShowInspireMeAsTaxonomy,
      isMealsAndRecipeEnabled
    } = this.props;

    return item.links
      .filter(link => {
        if (getIsInspireMeMenuKey(link.key)) {
          return shouldShowInspireMeAsLink || shouldShowInspireMeAsTaxonomy;
        }
        if (getIsMealsAndRecipeMenuKey(link.key)) {
          return isMealsAndRecipeEnabled;
        }
        if (getIsRecipeMenuKey(link.key) && isMealsAndRecipeEnabled) {
          return false;
        }
        if (hideBookSlotLink) {
          const isMatch = link.url.match(new RegExp(/(\/slots)\b/));
          return isMatch === null;
        }
        if (hidePromotionLink) {
          const isMatch = link.url.match(new RegExp(/(\/promotions)\b/));
          return isMatch === null;
        }

        return !link.feature || feature(link.feature);
      })
      .map((link, b) => this.buildContent(link, b));
  }

  downloadAppUrl(defaultUrl) {
    // Define device type - desktop does not need to be defined in this instance
    let deviceType;
    const { isMobile, isTablet, l, c, operatingSystem } = this.props;
    if (isMobile) {
      deviceType = MOBILE;
    } else if (isTablet) {
      deviceType = TABLET;
    }

    // Extract config and map operating system and device type to pull the correct link - expecting the keys of
    // c('links:appDownload') to match the exact string from operatingSystem
    // (if device type is applicable - i.e. `iOS`, `Android`), otherwise the link will be stubbed by default.
    const appDownloadConfig = c('links:appDownload:url');
    const appLink =
      appDownloadConfig &&
      appDownloadConfig[operatingSystem] &&
      appDownloadConfig[operatingSystem][deviceType];

    return appLink || l(defaultUrl);
  }

  getNavUrl(link) {
    const {
      defaultSlotsPath,
      protocol,
      host,
      isUserAuthenticated,
      loginUrl,
      l,
      externalSecureLink
    } = this.props;

    if (link.url.startsWith('/slots')) {
      link.url = defaultSlotsPath;
    }

    // Build the URL, allowing handling for special cases, defined as attributes on the `link` object
    if (link.useExternalSecure) {
      return externalSecureLink(link.url);
    } else if (link.dynamicAppUrl) {
      return this.downloadAppUrl(link.url);
    } else if (link.requiresAuthentication && !isUserAuthenticated) {
      const url = `${protocol}//${host}${l(link.url)}`;

      return updateParamsInUrl(loginUrl, { from: url });
    }

    return l(link.url);
  }

  sendTopNavAnalytics = action => {
    basicEvent(analyticsBus, {
      action,
      type: TOP_NAV,
      value: INSPIRE_ME
    });
  };

  handleClick(link) {
    if (link.dynamicAppUrl) {
      return () =>
        basicEvent(analyticsBus, {
          action: NOW,
          type: APP_DOWNLOAD,
          value: BURGER_MENU
        });
    } else if (
      getIsInspireMeMenuKey(link.key) &&
      this.props.shouldShowInspireMeAsLink
    ) {
      return () => this.sendTopNavAnalytics(DELAY);
    }
    return null;
  }

  inspireMeClick = e => {
    this.sendTopNavAnalytics(NOW);
    this.props.onNavigationListItemClick(INSPIRE_ME_MENU_KEY);
    e.preventDefault();
    e.stopPropagation();
  };

  buildContent(link, key) {
    const {
      cmsNav,
      l: language,
      f: feature,
      shouldPersistFavoriteState,
      showRecipeZonePage,
      showClubcardPricesName,
      isExperienceCookiesAccepted,
      shouldShowFavDropdown,
      shouldShowInspireMeAsTaxonomy,
      favTabLink
    } = this.props;
    const cmsTabName = link.cmsNav && cmsNav && cmsNav.tabName;
    const show = !link.cmsNav || (cmsTabName && cmsTabName.length);
    const text =
      (link.cmsNav && cmsTabName) ||
      (link.url === '/promotions' && showClubcardPricesName
        ? 'Clubcard Prices'
        : this.getText(link.text));

    let url, newTab;
    let rightIcon = link?.rightIcon ?? '';
    const REAL_FOOD_LINK_KEY = 'layout:main-nav.real-food';

    if (shouldPersistFavoriteState && isOnFavouritesPage(link.url)) {
      link.url = favTabLink;
    }

    if (link.text === REAL_FOOD_LINK_KEY && showRecipeZonePage) {
      url = language(link.zonePageUrl);
      newTab = false;
      rightIcon = '';
    } else {
      const cmsUrl = link.cmsNav && cmsNav?.link && language(cmsNav.link);
      const navUrl = this.getNavUrl(link);
      url = cmsUrl || navUrl;
      newTab = link.newTab;
    }

    const isInspireMeAsTaxonomy =
      getIsInspireMeMenuKey(link.key) && shouldShowInspireMeAsTaxonomy;
    const isMealsAndRecipes = getIsMealsAndRecipeMenuKey(link.key);

    let onClickHandler = this.handleClick(link);
    if (isMealsAndRecipes) {
      onClickHandler = this.mealsAndRecipesClick;
    }
    if (isInspireMeAsTaxonomy) {
      onClickHandler = this.inspireMeClick;
    }

    const listItemContent = (
      <ListItemContent
        iconLeft={link.leftIcon}
        iconRight={rightIcon}
        link={url}
        newTab={newTab}
        text={text}
        onClick={onClickHandler}
      />
    );

    if (show) {
      if (link.feedbackLink && feature('feedbackLinks')) {
        if (isExperienceCookiesAccepted) {
          // Special case for Feedback only, as it requires an on-click handler rather than a simple href - the Feedback
          // component takes care of this
          return (
            <Feedback className="list-item" tag="li" location="nav" key={key}>
              {listItemContent}
            </Feedback>
          );
        } else {
          return null;
        }
      }

      if (shouldShowFavDropdown && isOnFavouritesPage(link.url)) {
        return (
          <li id="fav-dropdown-navigation" className="list-item" key={key}>
            <FavListItemContent url={url} />
          </li>
        );
      }

      return (
        <li className="list-item" key={key}>
          {listItemContent}
        </li>
      );
    }
    return null;
  }

  getText(text) {
    const { c, t } = this.props;
    const translatedText = c(text) ? c(text) : t(text);

    return text === 'common:help' ? (
      <span>
        <span aria-hidden="true">{translatedText}</span>
        <span className="visually-hidden">{t('layout:main-nav.help')}</span>
      </span>
    ) : (
      translatedText
    );
  }

  render() {
    const { currentUrl, isUserAuthenticated, c } = this.props;
    const qs = parseQueryString(currentUrl);
    const returnUrl = delimitQuery.createHref(currentUrl, qs);

    return (
      <ul className="list main-nav-list" role="menu" tabIndex="0">
        {isUserAuthenticated && (
          <li className="list-header context-card-li">
            <ContextCard isLHN id="context-card-nav" />
          </li>
        )}
        {this.getMenu()}
        {c('LANGUAGESWITCH') && (
          <li className="list-item">
            <LanguageSwitchLink returnUrl={returnUrl} />
          </li>
        )}
      </ul>
    );
  }
}
