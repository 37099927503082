import moment from 'moment';
/**
 * Creates a deep clone of the given value.
 *
 * Only simple values are supported. Primitives, Arrays, Objects, Maps, Sets.
 *
 * NOTE: This can be a potentially expensive operation. The utility should be
 * used very intentionally and carefully.
 *
 * We initially introduced this utility as a simple way of addressing issues
 * of immutable state being mutated outside of reducers. This util allows us
 * to clone the respective state for the respective context, allowing mutations
 * to be performed safely thereafter.
 *
 * @param value - the value to be cloned
 */
export const cloneDeep = <T>(value: T): T => {
  const anyValue = value as any;

  if (anyValue == null || typeof anyValue !== 'object') {
    // This is a primitive, return it
    return anyValue;
  }

  if (moment.isMoment(anyValue)) return moment(anyValue) as any;

  if (anyValue instanceof Map) {
    const cloned = new Map();
    for (const item of anyValue) {
      cloned.set(...cloneDeep(item));
    }
    return cloned as any;
  }

  if (anyValue instanceof Set) {
    const cloned = new Set();
    for (const item of anyValue) {
      cloned.add(cloneDeep(item));
    }
    return cloned as any;
  }

  if (Array.isArray(anyValue)) {
    return anyValue.map(cloneDeep) as any;
  }

  return Object.keys(anyValue).reduce<any>((acc, key) => {
    acc[key] = cloneDeep(anyValue[key]);
    return acc;
  }, {}) as any;
};
