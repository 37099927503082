import React from 'react';
import helpers from '#/lib/decorators/helpers';
import { TConfigFunc, TTranslateFunc } from '#/lib/records/helpers.defs';
import { getTrolleyDeliveryAddressId } from '#/selectors/trolley';
import { connect } from 'react-redux';
import { CHANGE_DETAILS_MODAL_BOOK_A_SLOT_EDIT_ADDRESS, RELOAD_PAGE_MODAL } from '#/constants/modal-names';
import { closeModal, openModal } from '#/actions/ui-action-creators';
import { GenericSlotsModal } from '#/components/modals/generic-slots-modal/GenericSlotsModal';
import { ModalData } from '#/reducers/types.defs';
import { getExternalSecureLink } from '#/reducers/app';

type HelpersProps = {
  t: TTranslateFunc;
  c: TConfigFunc;
};
type StateProps = {
  selectedAddressId?: string;
  externalAddAddressSecureLink: string;
  externalEditAddressSecureLink: string;
};
type DispatchProps = {
  closeModal: () => void;
  openModal: (modalName: string, modalData?: ModalData, addToUrl?: boolean) => void;
};
export type ChangeDetailsModalProps = {
  onClose: () => void;
  isOpen: boolean;
  modalName: string;
} & HelpersProps &
  StateProps &
  DispatchProps;

const mapStateToProps = (state: Store, props: ChangeDetailsModalProps): StateProps => ({
  selectedAddressId: getTrolleyDeliveryAddressId(state),
  externalAddAddressSecureLink: getExternalSecureLink(state, props.c('externalAddAddressUrl')),
  externalEditAddressSecureLink: getExternalSecureLink(state, props.c('externalEditAddressUrl')),
});

const ChangeDetailsModal = ({
  modalName,
  selectedAddressId,
  isOpen,
  t: translate,
  closeModal,
  openModal,
  externalAddAddressSecureLink,
  externalEditAddressSecureLink,
}: ChangeDetailsModalProps): JSX.Element => {
  const openReloadModal = (): void => {
    closeModal();
    openModal(RELOAD_PAGE_MODAL);
  };
  const isEditAddressModal = modalName === CHANGE_DETAILS_MODAL_BOOK_A_SLOT_EDIT_ADDRESS;
  const getEditLink = (): string => `${externalEditAddressSecureLink}?id=${selectedAddressId}`;

  const url = isEditAddressModal ? getEditLink() : externalAddAddressSecureLink;

  const headingText = translate(
    isEditAddressModal ? 'modals:change-details-modal.title-edit' : 'modals:change-details-modal.title-add',
  );

  const buttonText = translate(
    isEditAddressModal ? 'modals:change-details-modal.confirm-edit' : 'modals:change-details-modal.confirm-add',
  );

  return (
    <GenericSlotsModal
      onClose={closeModal}
      modalId="change-address"
      headingText={headingText}
      bodyText={translate('modals:change-details-modal.body')}
      buttons={[
        {
          onClick: (): void => {
            window.open(url, '_blank');
            openReloadModal();
          },
          text: buttonText,
        },
      ]}
      isModalOpen={isOpen}
    />
  );
};

export default helpers(['t', 'c'])(connect(mapStateToProps, { closeModal, openModal })(ChangeDetailsModal));
