import Polyglot from 'node-polyglot';
import url from 'url';
import { findInConfig } from './config-helpers';
import { getTranslator } from '#/utils/translate-utils';
import { addFromParamToUrl, getReturnUrl } from './auth/login-utils';
import createLinkFn from './i18n/link';
import { formAssetUrl } from './url/asset-utils.js';
import { parseCookieString } from '../lib/client-script-helpers';

const isClientSide = process.env.CLIENT_SIDE;
let configCache;
let getAttribute;
let getHtmlAttribute;

if (isClientSide) {
  configCache = {};
  /**
   * Retrieves the value of an attribute on the root node.
   */
  getAttribute = (document, attribute) => document.body.getAttribute(attribute);
  getHtmlAttribute = (document, attribute) =>
    document.documentElement.getAttribute(attribute);
}

/**
 * On the server-side this wraps helpers and stores the output when necessary
 * within the `data` object. This is then serialised afterwards into JSON and
 * placed in an attribute for each section on the root HTML node.
 *
 * On the client-side this wraps calls to retrieve the relevant data from the
 * attributes containing the serialised JSON and returns it for the component.
 */
class Helpers {
  constructor(data) {
    if (isClientSide) {
      this._document = data;
      this._cookie = parseCookieString(data.cookie);
      this._assets =
        JSON.parse(getAttribute(this._document, 'data-assets')) || {};
      this._config = JSON.parse(this._document.getElementById('config').text);
      this._device = JSON.parse(getAttribute(this._document, 'data-device'));
      this._features = JSON.parse(
        getAttribute(this._document, 'data-features')
      );
      this._groceryDomain = getAttribute(this._document, 'data-grocery-domain');
      this._hasAcceptedCookiePolicy =
        getAttribute(this._document, 'data-cookie-policy-accepted') != null;
      this._hasAcceptedOrderTypeChangeWarning =
        getAttribute(this._document, 'data-order-type-change-warning-cookie') !=
        null;
      this._hasAcceptedSlotChangeWarning =
        getAttribute(this._document, 'data-change-slot-warning-cookie') != null;
      this._hasAcceptedLocationChangeWarning =
        getAttribute(this._document, 'data-change-location-warning-cookie') !=
        null;
      this._hasAcceptedAddressChangeWarning =
        getAttribute(this._document, 'data-change-address-warning-cookie') !=
        null;
      this._isChromeless =
        getAttribute(this._document, 'data-is-chromeless') != null;
      // Data attributes can't be trusted for cached pages - could be stale.
      // Only an issue for language currently
      const languageInDataAttributes = getAttribute(
        this._document,
        'data-language'
      );

      this._lang = languageInDataAttributes;

      this._region = getAttribute(this._document, 'data-region');
      this._consumer = getAttribute(this._document, 'data-consumer');
      this._scenario = getAttribute(this._document, 'data-scenario');
      this._timezone = getAttribute(this._document, 'data-timezone');
      this._basePath = getAttribute(this._document, 'data-base-path');
      this._baseStaticUrl = getHtmlAttribute(
        this._document,
        'data-base-static-url'
      );
      this._translations = window.translations[this._lang.slice(0, 2)];
      this._externalSecureDomain = getAttribute(
        this._document,
        'data-external-secure-domain'
      );
      this._polyglot = new Polyglot({
        allowMissing: false,
        warn(message) {
          if (process.env.NODE_ENV === 'development') {
            console.warn(message); // eslint-disable-line no-console
          }
        },
        phrases: this._translations,
        locale: this._lang.slice(0, 2)
      });
    } else {
      this._helpers = data;
      this._polyglot = data._polyglot;
    }
  }

  asset(file = '') {
    if (isClientSide) {
      return formAssetUrl(file, this._baseStaticUrl, this._assets);
    }

    return this._helpers.asset(file);
  }

  browserTypeVersion() {
    if (process.env.CLIENT_SIDE) {
      return this._device.browserType + this._device.browserVersion;
    }

    return (
      this._helpers.device.browserType + this._helpers.device.browserVersion
    );
  }

  c(configKey) {
    if (Array.isArray(configKey)) {
      return this._cCascade(configKey);
    }

    if (isClientSide) {
      return findInConfig(configKey, this._region, this._consumer, key =>
        getConfig(key, this._config)
      );
    }

    return this._helpers.c(configKey);
  }

  _cCascade(arr) {
    const keys = scan1(
      (a, b) => [a, b].join(':'),
      arr.filter(data => !!data)
    ).reverse();

    const result = keys
      .map(key => {
        if (isClientSide) {
          return [
            key,
            findInConfig(key, this._region, this._consumer, key =>
              getConfig(key, this._config)
            )
          ];
        }

        return [key, this._helpers.c(key)];
      })
      .filter(([, config]) => !!config)
      .slice(0, 1)[0];

    return (result || [])[1];
  }

  currentPathname() {
    if (isClientSide) {
      return url.parse(this._document.location.href).pathname;
    }

    return url.parse(this._helpers.currentUrl).pathname;
  }

  f(feature) {
    if (isClientSide) {
      return this._features[feature];
    }

    return this._helpers.f(feature);
  }

  hasAcceptedCookiePolicy() {
    if (isClientSide) {
      return this._hasAcceptedCookiePolicy;
    }

    return this._helpers.hasAcceptedCookiePolicy;
  }

  hasFeature(k) {
    let v;

    v = this.c(k);

    return !!(v != null && v);
  }

  host() {
    if (isClientSide) {
      return this._document.location.host;
    }

    return this._helpers.host;
  }

  isChromeless() {
    if (isClientSide) {
      return this._isChromeless;
    }

    return this._helpers.isChromeless;
  }

  isStockAndroid() {
    if (isClientSide) {
      return this._device.isStockAndroid;
    }

    return this._helpers.device.isStockAndroid;
  }

  l(path, site) {
    const language = isClientSide ? this._lang : this._helpers.language;
    if (isClientSide) {
      this._link ||
        (this._link = createLinkFn(
          language,
          this._document.location.port,
          this._groceryDomain,
          this._basePath,
          'https'
        ));

      return this._link(path, site);
    }

    return this._helpers.l(path, site);
  }

  basePath() {
    if (isClientSide) {
      return this._basePath;
    }

    return this._helpers.basePath;
  }

  scenario() {
    if (isClientSide) {
      return this._scenario;
    }

    return this._helpers.scenario;
  }

  externalSecureLink(link) {
    if (isClientSide) {
      return this._externalSecureDomain + link;
    }

    return this._helpers.externalSecureDomain + link;
  }

  t(...args) {
    // using this so that the translator gets created after the Helpers class is created
    if (!this.translator) {
      this.translator = getTranslator(this.c.bind(this), this._polyglot);
    }

    return this.translator(...args);
  }

  timezone() {
    if (isClientSide) {
      return this._timezone;
    }

    return this._helpers.timezone;
  }

  loginUrl() {
    const language = isClientSide ? this._lang : this._helpers.language;
    const loginPath = this.c('externalLoginUrl')[language];
    return this.externalSecureLinkWithFrom(loginPath);
  }

  forgotPasswordUrl() {
    const language = isClientSide ? this._lang : this._helpers.language;
    const forgotPasswordPath = this.c('externalForgotPasswordUrl')[language];
    return this.externalSecureLinkWithFrom(forgotPasswordPath);
  }

  registrationUrl() {
    const language = isClientSide ? this._lang : this._helpers.language;
    const registrationPath = this.c('externalRegistrationUrl')[language];
    return this.externalSecureLinkWithFrom(registrationPath);
  }

  externalSecureLinkWithFrom(path) {
    let returnUrl;

    if (isClientSide) {
      returnUrl = this._document.location.href;
    } else {
      returnUrl = getReturnUrl(
        'https',
        this._helpers.host,
        this._helpers.currentUrl
      );
    }

    return this.externalSecureLink(addFromParamToUrl(path, returnUrl));
  }

  variations() {
    if (isClientSide) {
      return this._variations;
    }

    return this._helpers.variations;
  }
}

export const getConfig = (key, config) => {
  const cachedKey = configCache[key];

  if (cachedKey) {
    return cachedKey;
  }

  let keySections = key.split(':');
  let currentConfig = config;

  for (let i = 0; i < keySections.length; i++) {
    if (typeof currentConfig === 'undefined') {
      break;
    }

    currentConfig = currentConfig[keySections[i]];
  }

  configCache[key] = currentConfig;

  return currentConfig;
};

const scan = (func, first, list) => {
  let last = first;
  const results = [];

  for (let i = 0; i < list.length; ++i) {
    results.push((last = func(last, list[i])));
  }

  return [first].concat(results);
};

export const scan1 = (func, list) => {
  if (!list.length) {
    return;
  }

  return scan(func, list[0], list.slice(1));
};

export const findSelectedTaxon = function(node, key, value) {
  if (typeof value === 'undefined' || value === null) {
    return null;
  }

  const stack = node.children ? node.children.slice(0) : [];
  let length = stack.length;

  while (length > 0) {
    const child = stack.shift();
    const val = child[key];

    --length;

    if (val) {
      if (val === value) {
        return child;
      } else if (child.children) {
        length = stack.unshift(...child.children);
      }
    }
  }

  return null;
};

export default Helpers;
