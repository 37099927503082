import { getVariationForExperiment } from '#/lib/optimizely-manager';
import { hdSlotMatrixUiImprovements, REGION_IE } from '../constants';
import { isCollectionShoppingMethod, isDeliveryShoppingMethod } from '#/lib/shopping-method-util';
import { getSelectedShoppingMethod, getCurrentSlotGroup } from '#/reducers/slot';
import { isOneHourSlotGroup } from '#/lib/slot/slot-utils';

const {
  key,
  useCustomerId,
  variants: {
    default: defaultVariant,
    stickyHeader: stickyHeaderVariant,
    timesOfTheDay: timesOfTheDayVariant,
    stickyHeaderTimesOfTheDay: stickyHeaderTimesOfTheDayVariant,
  },
} = hdSlotMatrixUiImprovements;

export const getVariant = (state: Store): string => {
  if (!getIs1hrSlotHomeDelivery(state)) {
    return defaultVariant;
  }

  return getVariationForExperiment(state, key, {}, useCustomerId) || defaultVariant;
};

//TODO: remove when fully productionising OOP-1338, required while sticky header remains in UK holdout group
export const getIsTimesOfDayVariantROI = (state: Store): boolean => {
  const getRegion = (state: Store): string | undefined => state?.app?.region;

  const shoppingMethod = getSelectedShoppingMethod(state);
  return (
    getRegion(state) === REGION_IE &&
    (isDeliveryShoppingMethod(shoppingMethod) || isCollectionShoppingMethod(shoppingMethod))
  );
};

export const getIsStickyHeaderVariant = (state: Store): boolean =>
  [stickyHeaderVariant, stickyHeaderTimesOfTheDayVariant].includes(getVariant(state));

export const getIsTimesOfTheDayVariant = (state: Store): boolean =>
  [timesOfTheDayVariant, stickyHeaderTimesOfTheDayVariant].includes(getVariant(state));

export const getIs1hrSlotHomeDelivery = (state: Store): boolean =>
  isDeliveryShoppingMethod(getSelectedShoppingMethod(state)) && isOneHourSlotGroup(getCurrentSlotGroup(state));
