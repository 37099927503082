import { Item } from '#/lib/records/item';
import { ItemPayload } from '#/lib/records/item-payload';

export default function adjustPayloadSubstitutionValueForCore(
  diff: Array<ItemPayload>,
  originalItem: Item,
): Array<ItemPayload> {
  if (diff?.length === 1) {
    const newDiff = [{ ...diff[0], substitutionOption: originalItem.substitutionOption }];
    return newDiff;
  }
  return diff;
}
