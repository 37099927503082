import { TROLLEY_CONTENTS } from '#/constants/spa-resource';
import { OFFERS_FOR_YOU } from '#/experiments/oop-1557/constants/spa-resource';
import { getOffersForYouResource } from '#/experiments/oop-1557/selectors/resources';
import { receiveFavoritesData } from '#/actions/ui-action-creators';
import { updateResults } from '#/actions/results-actions';
import { updateCurrentUrl } from '#/actions/filter-action-creators';
import { Dispatch, GetStore } from '#/custom-typings/redux-store/common';

export const type = OFFERS_FOR_YOU;
export const params = ['query'];
export const dependencies = [TROLLEY_CONTENTS];

type Options = {
  updateCurrentURL?: string;
};

export function handler(dispatch: Dispatch, getState: GetStore, options: Options = {}): void {
  const state = getState();
  const { data = null } = getOffersForYouResource(state) || {};

  if (!data) {
    return;
  }

  const { results, selectedFavoritesTab } = data;

  if (selectedFavoritesTab) {
    dispatch(receiveFavoritesData(selectedFavoritesTab));
  }

  if (results?.productItems) {
    dispatch(updateResults(results, type, false));

    if (options.updateCurrentURL) {
      dispatch(updateCurrentUrl(options.updateCurrentURL));
    }
  }
}
