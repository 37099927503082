import React, { PureComponent } from 'react';
import {
  VIEW_AND_BUY_BUYBOX,
  QUANTITY_CONTROLS_BUYBOX,
  UNAVAILABLE_WITH_PRICE_BUYBOX,
  UNAVAILABLE_WITH_PRICE_REST_OF_SHELF_BUYBOX,
  REST_OF_SHELF_BUYBOX,
  NO_BUYBOX,
} from '#/selectors/beans-product-tile/buybox-data';
import { TBuyboxData } from '#/selectors/beans-product-tile/buybox-data';
import { ViewAndBuyBuybox } from '#/components/product-tiles/common/buybox-container/view-and-buy-buybox';
import { UnavailableWithPriceBuybox } from '#/components/product-tiles/common/buybox-container/unavailable-with-price-buybox';
import { UnavailableWithPriceRestOfShelfBuybox } from '#/components/product-tiles/common/buybox-container/unavailable-with-price-rest-of-shelf-buybox';
import { RestOfShelfBuybox } from '#/components/product-tiles/common/buybox-container/rest-of-shelf-buybox';
import { QuantityControlsBuybox } from '#/components/product-tiles/common/buybox-container/quantity-controls-buybox';
import { TBuyboxFunctionalityProps } from '#/components/product-tiles/common/buybox-container/buybox.defs';
import { basicEvent } from '#/analytics/types/basic';
import analyticsBus from '#/analytics/analyticsBus';
import { StyledBuyBoxContainer } from './styles';
import { CustomerUnitChoice } from '#/lib/records/item.defs';

export type TProps = TBuyboxFunctionalityProps & {
  buyboxStyles?: string;
  buyboxData: TBuyboxData;
  className?: string;
  resultType: string;
  onAnonymousUserAdd?: (quantityValue: number, variantValue: CustomerUnitChoice) => void;
};

const handleActionLinkClick = (): void => {
  basicEvent(analyticsBus, {
    type: `view and buy special offers`,
    value: 'product tile',
    action: 'delay',
  });
};

export class BuyboxContainer extends PureComponent<TProps> {
  render(): JSX.Element | null {
    const {
      buyboxData,
      buyboxStyles,
      identifier,
      addFormParams,
      increment,
      translate,
      onCatchWeightChange,
      className,
      isProductQtyIncrementable,
      resultType,
      onAnonymousUserAdd,
    } = this.props;
    if (buyboxData.buyboxType === NO_BUYBOX) return null;

    let buybox;
    if (buyboxData.buyboxType === VIEW_AND_BUY_BUYBOX) {
      buybox = <ViewAndBuyBuybox {...buyboxData} onActionLinkClick={handleActionLinkClick} />;
    } else if (buyboxData.buyboxType === UNAVAILABLE_WITH_PRICE_BUYBOX) {
      buybox = <UnavailableWithPriceBuybox {...buyboxData} />;
    } else if (buyboxData.buyboxType === UNAVAILABLE_WITH_PRICE_REST_OF_SHELF_BUYBOX) {
      buybox = <UnavailableWithPriceRestOfShelfBuybox {...buyboxData} />;
    } else if (buyboxData.buyboxType === REST_OF_SHELF_BUYBOX) {
      buybox = <RestOfShelfBuybox {...buyboxData} />;
    } else if (buyboxData.buyboxType === QUANTITY_CONTROLS_BUYBOX) {
      buybox = (
        <QuantityControlsBuybox
          {...buyboxData}
          buyboxStyles={buyboxStyles}
          increment={increment}
          translate={translate}
          onCatchWeightChange={onCatchWeightChange}
          addFormParams={addFormParams}
          identifier={identifier}
          isProductQtyIncrementable={isProductQtyIncrementable}
          resultType={resultType}
          onAnonymousUserAdd={onAnonymousUserAdd}
        />
      );
    }
    return (
      <StyledBuyBoxContainer className={className} data-auto="buybox-container">
        {buybox}
      </StyledBuyBoxContainer>
    );
  }
}
