import { clearUserSessionData } from '#/lib/url/modal-utils';
import { setTooltipStorage, getStorageKey } from '#/experiments/oop-1263/helpers';
import { SEARCH_STORAGE_KEY } from '#/experiments/oop-1742/constants';
import { SHOWN_SLOT_REBOOK_MODAL_KEY, SHOWN_SLOT_REMIND_MODAL_KEY } from '#/experiments/oop-2210/constants';
import { CONTENT_SEARCH_STORAGE_KEY } from '#/experiments/oop-2002/constants';
import { DISMISS_INTERESTED_IN_SLOT_NOTIFY_ME } from '#/experiments/oop-2034/constants';
import { basicEvent } from '#/analytics/types/basic';
import { openFeedbackWindow } from '#/lib/open-feedback-window';
import analyticsBus from '#/analytics/analyticsBus';
import { TConfigFunc } from '#/lib/records/helpers.defs';
import { IconPropTypes } from '@ddsweb/link/lib/types/link.types';

type SignoutInputConfig = {
  name: string;
  type: string;
  value: string;
};

export type AppBarConfig = {
  text: string;
  id: string;
  filterId?: string;
  href?: string | null;
  onClick?: () => void;
  action?: string;
  inputs?: SignoutInputConfig[];
  children?: AppBarConfig[] | null;
  external?: boolean;
  hasPopup?: boolean;
  icon?: string;
  target?: string;
  popupIcon?: string;
};

type FeedbackConfig = {
  cutsMustard: boolean;
  deviceType: string;
  isFirstTimeShopper: boolean;
  storeId: string;
  hashedUId: string;
  traceId: string;
};

type LogoutConfig = {
  shouldSetFavNavTooltipStorage: boolean;
  shouldSetInterestedInSlotNotifyMeStorage: boolean;
  uuid: string;
};

type HelpConfig = {
  config: TConfigFunc;
  language: string;
  isUserAuthenticated: boolean;
};

export type AppBarMenuItemConfig = FeedbackConfig &
  HelpConfig &
  LogoutConfig & {
    appBar: AppBarConfig[];
    isExperienceCookieAccepted: boolean;
    translate: (key: string) => string;
    ordersUrl: string;
    accountUrl: string;
    logoutUrl: string;
    csrfToken: string;
  };

const getHelpUrl = ({ config, language, isUserAuthenticated }: HelpConfig): string => {
  let href = config(`links:help:${language}`);
  const anonHelpHref = config(`links:anon-help:${language}`);
  if (!isUserAuthenticated && anonHelpHref) {
    href = anonHelpHref;
  }
  return href as string;
};

export const logoutLinkClickHandler = ({
  shouldSetFavNavTooltipStorage,
  shouldSetInterestedInSlotNotifyMeStorage,
  uuid,
}: LogoutConfig): void => {
  clearUserSessionData('shownBasketBreachModal');
  clearUserSessionData('hasOndemandStatusAnalyticsFired');
  clearUserSessionData(SEARCH_STORAGE_KEY);
  clearUserSessionData(CONTENT_SEARCH_STORAGE_KEY);
  clearUserSessionData(SHOWN_SLOT_REBOOK_MODAL_KEY);
  clearUserSessionData(SHOWN_SLOT_REMIND_MODAL_KEY);
  if (shouldSetFavNavTooltipStorage) {
    setTooltipStorage(getStorageKey(uuid));
  }
  if (shouldSetInterestedInSlotNotifyMeStorage) {
    clearUserSessionData(DISMISS_INTERESTED_IN_SLOT_NOTIFY_ME);
  }
};

export const onFeedbackClick = ({
  cutsMustard,
  deviceType,
  isFirstTimeShopper,
  storeId,
  hashedUId,
  traceId,
}: FeedbackConfig): void => {
  basicEvent(analyticsBus, {
    type: 'feedback',
    value: `opinionLab:nav`,
  });
  const customerVariables = {
    cutsMustard: String(cutsMustard),
    deviceType,
    isFirstTimeShopper: String(isFirstTimeShopper),
    storeId,
    ghsUUID: hashedUId,
    ghs_customerID: hashedUId,
    traceId,
  };
  openFeedbackWindow('nav', customerVariables);
};

export const getAppbarMenuItems = ({
  appBar,
  isUserAuthenticated,
  isExperienceCookieAccepted,
  translate,
  ordersUrl,
  config,
  accountUrl,
  logoutUrl,
  csrfToken,
  shouldSetFavNavTooltipStorage,
  cutsMustard,
  deviceType,
  isFirstTimeShopper,
  storeId,
  hashedUId,
  traceId,
  shouldSetInterestedInSlotNotifyMeStorage,
  uuid,
  language,
}: AppBarMenuItemConfig): AppBarConfig[] => {
  return appBar
    .filter(item => {
      switch (item.filterId) {
        case 'authLinks':
          return isUserAuthenticated;
        case 'experienceLinks': {
          return isExperienceCookieAccepted;
        }
        default:
          return true;
      }
    })
    .map(item => {
      let menuItem = {
        ...item,
        text: translate(item.text),
      };

      switch (item.id) {
        case 'utility-header-orders-link':
          menuItem = {
            ...menuItem,
            href: ordersUrl,
          };
          break;
        case 'utility-header-help-link':
          menuItem = {
            ...menuItem,
            href: getHelpUrl({ config, isUserAuthenticated, language }) as string,
          };
          break;
        case 'utility-header-feedback-link':
          menuItem = {
            ...menuItem,
            onClick: () =>
              onFeedbackClick({ cutsMustard, deviceType, isFirstTimeShopper, storeId, hashedUId, traceId }),
          };
          break;
        case 'utility-header-account-link':
          menuItem = {
            ...menuItem,
            href: accountUrl,
          };
          break;
        case 'utility-header-logout-link':
          menuItem = {
            ...menuItem,
            action: logoutUrl,
            inputs: [
              {
                name: '_csrf',
                type: 'hidden',
                value: csrfToken,
              },
            ],
            onClick: () =>
              logoutLinkClickHandler({ shouldSetFavNavTooltipStorage, shouldSetInterestedInSlotNotifyMeStorage, uuid }),
          };
      }
      return menuItem;
    });
};

export const getIconPropertiesForMenuItem = (menuItem: AppBarConfig): IconPropTypes | undefined => {
  const { children, hasPopup, icon } = menuItem;
  let iconProps: IconPropTypes | undefined = undefined;
  if (icon) {
    iconProps = {
      graphic: icon,
      position: { global: 'left' },
    } as IconPropTypes;
  }

  if (hasPopup || children?.length) {
    const graphic = 'forwardLink';

    iconProps = {
      graphic,
      position: { global: 'right' },
    } as IconPropTypes;
  }

  return iconProps;
};
