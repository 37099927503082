import styled from 'styled-components';
import Spinner from '@ddsweb/spinner';
import { spacing, lineHeight } from '@ddsweb/selectors';

export const StyledSpinner = styled(Spinner)`
  height: 18px;
  width: 18px;
  top: 5px;
`;

export const StyledPromotionSecondaryText = styled.span`
  color: #de1020;
  display: block;
  line-height: ${lineHeight.smHeading};
  margin-top: ${spacing.xx};
`;
