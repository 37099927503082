import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Link from '#/components/link-check-spa';
import styled from 'styled-components';
import { colors } from '@ddsweb/selectors';

export const StyledLink = styled(props => <Link {...props} />)`
  font-family: 'TESCO Modern', Arial, sans-serif;
`;

export const StyledTitle = styled.h3`
  margin: 0 0 15px;
  color: ${colors.disabledDark};
`;

export const ProductTitleUI = props => {
  const { enabled, title, onClick, href } = props;

  return (
    <StyledTitle>
      {enabled ? (
        <StyledLink data-auto="product-tile--title" onClick={onClick} to={href}>
          {title}
        </StyledLink>
      ) : (
        <>{title}</>
      )}
    </StyledTitle>
  );
};
export default memo(ProductTitleUI);

ProductTitleUI.propTypes = {
  enabled: PropTypes.bool.isRequired,
  href: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};
