import {
  toggleRecsCarouselFlag,
  updateRecsCarouselDetails,
} from '#/experiments/oop-2349/actions/carousel-action-creator';
import { Dispatch, GetStore } from '#/custom-typings/redux-store/common';
import { BASKET_ADD_COUNT_RECS_CAROUSEL, RECS_CAROUSEL_DISPLAY_THRESHOLD } from '#/experiments/oop-2349/constants';
import { getIsIBYCCarouselEnabledForShopOrSearch } from '#/experiments/oop-2349/selectors';
import { sessionStore } from '#/lib/data-store/client-store';
import { getIsRecsCarouselVisible } from '#/reducers/ui';

export type AnalyticsOptions = {
  gridPos?: number | null;
};

export const setRecsCarouselDetailsOnItemAdd = (analyticsOptions?: AnalyticsOptions, itemQuantity?: number) => {
  return (dispatch: Dispatch, getState: GetStore): void => {
    const state = getState();
    const isIBYCCarouselEnabled = getIsIBYCCarouselEnabledForShopOrSearch(state);
    const isRecsCarouselVisible = getIsRecsCarouselVisible(state);

    if (isIBYCCarouselEnabled) {
      const basketAddCountFromSession = (sessionStore?.get(BASKET_ADD_COUNT_RECS_CAROUSEL) || 0) as number;
      const { gridPos = null } = analyticsOptions || {};

      if (itemQuantity === 0 && typeof gridPos == 'number') {
        const basketAddCountForRecsCount = basketAddCountFromSession + 1;
        sessionStore?.set(BASKET_ADD_COUNT_RECS_CAROUSEL, basketAddCountForRecsCount);
        if (basketAddCountForRecsCount % RECS_CAROUSEL_DISPLAY_THRESHOLD === 0 && !isRecsCarouselVisible) {
          const productDetailForCarousel = { itemIndex: gridPos };

          dispatch(toggleRecsCarouselFlag());
          dispatch(updateRecsCarouselDetails(productDetailForCarousel));
        }
      }
    }
  };
};
