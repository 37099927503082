import React from 'react';
import Buybox, { ActionLink } from '@beans/buy-box';
import SafelyOutputString from '#/components/safely-output-string';
import { TBuyboxUnavailableWithPriceRestOfShelfData } from '#/selectors/beans-product-tile/buybox-data';

export const UnavailableWithPriceRestOfShelfBuybox = ({
  restOfShelfText,
  href,
  accessibilityText,
  priceSubtext,
  assistiveDrsText,
  drsText,
  priceText,
}: TBuyboxUnavailableWithPriceRestOfShelfData): JSX.Element => (
  <Buybox
    assistiveDrsText={assistiveDrsText}
    drsText={drsText}
    data-auto="unavailable-with-price-rest-of-shelf-buybox"
    aria-label={accessibilityText}
    action={
      <ActionLink href={href}>
        <SafelyOutputString>{restOfShelfText}</SafelyOutputString>
      </ActionLink>
    }
    priceText={priceText}
    priceSubtext={priceSubtext}
  />
);
