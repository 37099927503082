import styled from 'styled-components';
import { ContentCarousel } from '@ddsweb/carousel';
import { spacing } from '@ddsweb/selectors';
import { media } from '@ddsweb/foundation';
import { BLUE, BLUE_TESCO, WHITE, GREY_MID_2 } from '@ddsweb/constants';

export const StyledContentCarouselContainer = styled.div`
  margin: 0 6px ${spacing.xl};
  padding: ${spacing.md} ${spacing.sm};
  background-color: ${WHITE};
  border: 1px solid ${GREY_MID_2};
`;

export const StyledContentCarousel = styled(ContentCarousel)`
  display: flex;
  flex-direction: column-reverse;
  ${media.belowTablet` 
    row-gap: ${spacing.sm};
  `};

  .ddsweb-carousel__content-container {
    background-color: ${WHITE};
    overflow: hidden;
  }

  .ddsweb-carousel__items-container {
    width: fit-content;
    margin: 0;
  }

  ${media.aboveTablet` 
      margin-left: 64px;
      margin-right: 64px;
  `};

  .ddsweb-carousel__controls-container {
    border: none;
    background: none;
    width: 100%;
    height: auto;
    position: initial;
  }

  .ddsweb-carousel__item {
    padding-left: ${spacing.xs};
    padding-right: ${spacing.xs};
    margin: 0;
    width: auto;
  }

  .ddsweb-carousel__controls {
    width: 100%;
    justify-content: flex-end;
    ${media.aboveTablet` 
        position: unset;
    `};
  }

  .ddsweb-carousel__item-count {
    display: none;
  }

  .ddsweb-button--icon-button {
    ${media.aboveTablet` 
        position: absolute;
        top: 38%;
    `};
  }

  .ddsweb-button--icon-button:disabled {
    color: ${BLUE_TESCO};
    border: 2px solid ${BLUE_TESCO};
    background-color: ${WHITE};
  }

  .ddsweb-carousel__backward-control {
    ${media.aboveTablet` 
        left: -49px;
        margin-right: 0
    `};
  }

  .ddsweb-carousel__forward-control {
    margin-right: 0;
    ${media.aboveTablet` 
        right: -49px;
    `};
  }
`;

export const StyledContentStampWrapper = styled.div`
  width: 132px;
  height: fit-content;
  border-bottom: ${(props: { isSelected: boolean }): string => (props.isSelected ? `4px solid ${BLUE}` : 'none')};

  .ddsweb-content-stamp__container {
    border: none;
  }

  .ddsweb-content-stamp__responsive-image {
    min-height: 0;
    height: 80px;
    width: 80px;
    margin-left: 26px;
  }

  .ddsweb-content-stamp__image-container,
  .ddsweb-content-stamp__panel-container {
    background-color: ${WHITE};
    padding: ${spacing.xs} 0;
  }

  .ddsweb-content-stamp__panel-container {
    padding-top: 0;
  }

  .ddsweb-content-stamp__link-container {
    justify-content: center;
    text-align: center;
  }

  .ddsweb-content-stamp__link {
    color: ${(props: { isSelected: boolean }): string => (props.isSelected ? `${BLUE}` : 'default')};
  }

  .ddsweb-link__text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipses;
  }
`;
