import React, { useState, useEffect, SyntheticEvent } from 'react';
import { compose } from 'react-recompose';
import { BodyText } from '@beans/typography';
import { FloatingTooltip, TooltipVariants, TooltipPositions, TRef } from '#/components/shared/tooltip';
import { TTranslateFn } from '#/components/slots/delivery-content-section/index.defs';
import { TOOLTIP_WIDTH_DESKTOP, CLOSED } from '#/experiments/oop-1824/constants';
import MultiSearchNotificationBanner from '#/experiments/oop-1824/components/multi-search-notification-banner';
import { localStore } from '#/lib/data-store/client-store';
import helpers from '#/lib/decorators/helpers';
import { connect } from '#/lib/render/connect-deep-compare';
import { getIsMobile } from '#/reducers/app';
import { getUserUuid } from '#/reducers/user';
import { TooltipStyleDesktop } from './styled';

export type Props = {
  id: string;
  targetRef: TRef;
  position?: string;
  tooltipRootID?: string;
  boundingRef?: TRef;
  portalRootID?: string;
  observerId?: string;
  t: TTranslateFn;
};

type TOwnState = {
  isMobile: boolean;
  uuid: string;
};

const mapStateToProps = (state: Store): TOwnState => ({
  uuid: getUserUuid(state),
  isMobile: getIsMobile(state),
});

const connector = connect(mapStateToProps, {});

type TProps = Props & TOwnState;

const getStorageKey = (uuid: string): string => `multi-search-tooltip_${uuid}`;

const renderTooltipBody = (bodyText: string): JSX.Element => (
  <BodyText key="multi-search-tooltip-body" id="multi-search-tooltip-accessibility-content">
    {bodyText}
  </BodyText>
);

const MultiSearchTooltipContainer = ({
  targetRef,
  id,
  isMobile = false,
  tooltipRootID,
  boundingRef,
  portalRootID,
  observerId,
  t: translate,
  uuid,
}: TProps): JSX.Element | null => {
  const bodyText = translate('search:multisearch-tooltip-text');
  const getTooltipStorage = (): string => localStore?.get(getStorageKey(uuid));

  const [isOpen, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setOpen(!getTooltipStorage());
  }, []);

  const setTooltipStorage = (): void => {
    localStore?.set(getStorageKey(uuid), CLOSED);
  };

  const onClickHandler = (e: SyntheticEvent): void => {
    e.preventDefault();
    e.stopPropagation();
    setTooltipStorage();
  };

  const onBannerClickHandler = (e: SyntheticEvent): void => {
    e.preventDefault();
    setTooltipStorage();
    setOpen(false);
  };

  const changeHandler = (): void => {
    setOpen(false);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <>
      {isMobile ? (
        <MultiSearchNotificationBanner bodyText={bodyText} onClick={onBannerClickHandler} />
      ) : (
        <FloatingTooltip
          targetRef={targetRef}
          boundingRef={boundingRef}
          id={id}
          position={TooltipPositions.BOTTOM}
          tooltipWidth={TOOLTIP_WIDTH_DESKTOP}
          tooltipVariant={TooltipVariants.INFO}
          styles={TooltipStyleDesktop}
          isOpen={isOpen}
          onChange={changeHandler}
          onClick={onClickHandler}
          tooltipRootID={tooltipRootID}
          portalRootID={portalRootID}
          observerId={observerId}
        >
          {renderTooltipBody(bodyText)}
        </FloatingTooltip>
      )}
    </>
  );
};

const enhance = compose<TProps, Props>(connector);
export default helpers(['t'])(enhance(MultiSearchTooltipContainer));
