import {
  MISSED_DELIVERY_SLOT,
  MISSED_QUANTITY
} from '#/constants/slot-warning-modal-types';
import { WARNING_MESSAGE_BACKGROUND_CLASS } from '#/constants/product-tile';

export const showMissedOfferWithChangeSlotLink = (
  promoData,
  isAmendBasket,
  showChangeSlot,
  config
) => {
  const enableChangeSlotButton = !(
    isAmendBasket && !config('enableChangeSlotOnbasketTileInAmendmode')
  );

  const isMissedDeliverySlot = config('promotionWarnings')
    ? promoData.warnings &&
      promoData.warnings.warningType === MISSED_DELIVERY_SLOT
    : false;

  return (
    enableChangeSlotButton &&
    promoData.missedPromotion &&
    isMissedDeliverySlot &&
    showChangeSlot
  );
};

export const getMissedDeliveryProps = args => {
  const {
    translate,
    slotsLanguageLink,
    promoDates,
    isMiniTrolley,
    analyticsClick
  } = args;

  const changeYourSlotLink = {
    text: translate('context-cards:change-slot'),
    href: slotsLanguageLink,
    onClick: analyticsClick
  };
  const message = formMissedSlotMessage(isMiniTrolley, promoDates, translate);

  const offerMissedMessage = {
    backgroundColorClass: WARNING_MESSAGE_BACKGROUND_CLASS,
    message
  };
  return {
    messages: [offerMissedMessage],
    link: changeYourSlotLink
  };
};

const formMissedSlotMessage = (isMiniTrolley, promoDates, translate) => {
  return isMiniTrolley
    ? `${translate('common:pages.missed-promotions')} - ${translate(
        'trolley:warnings.offer-valid-until',
        {
          time: promoDates
        }
      )}`
    : translate('common:pages.missed-promotions');
};

export const getIncompletePromotionMessage = (
  promoData,
  translate,
  isMiniTrolley
) => {
  const missedQuantity =
    promoData.warnings && promoData.warnings.warningType === MISSED_QUANTITY;

  const message = missedQuantity
    ? getIncompletePromo(promoData.timesTriggered, translate, isMiniTrolley)
    : translate('common:pages.missed-promotions');

  return {
    backgroundColorClass: WARNING_MESSAGE_BACKGROUND_CLASS,
    message
  };
};

const getIncompletePromo = (timesTriggered, translate, isMiniTrolley) => {
  if (isMiniTrolley) {
    return translate(
      timesTriggered === 0
        ? 'trolley:warnings.offer-incomplete'
        : 'trolley:warnings.offer-partly-complete'
    );
  }

  return translate(
    timesTriggered === 0
      ? 'common:pages.missed-incomplete-promotions'
      : 'common:pages.missed-partial-promotions'
  );
};
