import { getStoreId, getSlot } from '#/selectors/trolley';
import stringify from '#/utils/stringify';

/**
 * This funciton is used for strengthening the resource.hash
 *
 * @param {object} state redux state
 * @return {string} a hash string
 *
 */

export const getCachingCriteria = (state): string => {
  const storeId = getStoreId(state);
  const bookedSlot = getSlot(state);
  return stringify({
    storeId,
    bookedSlotStart: bookedSlot && bookedSlot.start,
    bookedSlotEnd: bookedSlot && bookedSlot.end,
  });
};
