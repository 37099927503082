import React, { Component } from 'react';
import page from 'page';
import PropTypes from 'prop-types';
import queryString from 'querystring';
import { getCurrentUrl } from '#/reducers/app';
import { getIsUpdating } from '#/reducers/filter';
import { connect } from '#/lib/render/connect-deep-compare';
import helpers from '#/lib/decorators/helpers';
import classNames from 'classnames';

const mapStateToProps = state => ({
  currentUrl: getCurrentUrl(state),
  filterStoreIsUpdating: getIsUpdating(state)
});
@connect(mapStateToProps)
@helpers(['l', 't', 'currentPathname'])
export default class SortControl extends Component {
  static propTypes = {
    currentPathname: PropTypes.func.isRequired,
    currentUrl: PropTypes.string.isRequired,
    filterStoreIsUpdating: PropTypes.bool,
    l: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired
  };

  static contextTypes = {
    router: PropTypes.object
  };

  options = () =>
    this.props.options.map((option, i) => (
      <option key={i} value={option.value}>
        {option.label}
      </option>
    ));

  changeHandler = event => {
    const value = event.target.value;
    const qs = queryString.parse(this.props.currentUrl.split('?')[1] || '');

    delete qs.page;
    delete qs.offset;
    qs.sortBy = value;
    const sortUrl = `${this.props.currentPathname()}?${queryString.stringify(
      qs
    )}`;

    page(sortUrl);
  };

  currentSortType = () => {
    const qs = queryString.parse(this.props.currentUrl.split('?')[1] || '');

    return qs.sortBy || undefined;
  };

  render() {
    const {
      filterStoreIsUpdating,
      t: translate,
      isShowMarketPlaceFilter
    } = this.props;

    return (
      <div
        className={classNames('sort-control-container', {
          'sort-product-source': isShowMarketPlaceFilter
        })}
      >
        <label className="instructions-label" htmlFor="sort-by">
          {`${translate('sort-and-filter:sort-by')}: `}
        </label>
        <ul className="selects-row grid">
          <li>
            <div className="filter-select-container">
              <select
                className={classNames('filter-select sort-control icon', {
                  'filter-select--disabled': filterStoreIsUpdating
                })}
                id="sort-by"
                defaultValue={this.currentSortType()}
                onChange={this.changeHandler}
              >
                {this.options()}
              </select>
            </div>
          </li>
        </ul>
      </div>
    );
  }
}
