import React, { useRef } from 'react';
import { connect } from 'react-redux';
import Icon from '@ddsweb/icon';
import { Graphics } from '@ddsweb/types';
import { openModal, closeModal } from '#/actions/ui-action-creators';
import helpers from '#/lib/decorators/helpers';
import { VisuallyHidden } from '#/components/shared/styled';
import { FAKE_DOOR_THANK_YOU_MODAL, INTERESTED_IN_THIS_SERVICE_MODAL } from '#/constants/modal-names';
import { ModalData } from '#/reducers/types.defs';
import { StyledButton } from '#/experiments/oop-2034/components/styled';
import {
  dismissInterestedInSlotNotifyMe,
  focusModalTriggerElement,
  getNotifyMeThankYouModalData,
  triggerNotifyMeAnalyticsEvent,
  enrichAnalyticsEventWithDate,
} from '#/experiments/oop-2034/helpers';
import {
  IMPRESSION,
  UNAVAILABLE_MODAL,
  UNAVAILABLE_NOTED_MODAL,
  UNAVAILABLE_NOTIFY_ME,
} from '#/experiments/oop-2034/constants';
import { Moment } from 'moment';

export type UnavailableHDSlotWithNotifyButtonProps = {
  accessibleLabel: string;
  openModal: (modalName: string, modalData: ModalData | null, addToUrl: boolean) => void;
  closeModal: () => void;
  start: Moment;
  t: (key: string) => string;
};

const UnavailableHDSlotWithNotifyButton: React.FC<UnavailableHDSlotWithNotifyButtonProps> = (
  props: UnavailableHDSlotWithNotifyButtonProps,
) => {
  const { openModal, closeModal, start } = props;
  const notifyMeRef = useRef();

  const onChangeHandler = (): void => {
    closeModal();
    focusModalTriggerElement(notifyMeRef);
  };

  const handleNotifyMeLinkClick = (): void => {
    triggerNotifyMeAnalyticsEvent(enrichAnalyticsEventWithDate(UNAVAILABLE_NOTIFY_ME, start));
    let modalType = UNAVAILABLE_MODAL;

    if (dismissInterestedInSlotNotifyMe()) {
      modalType = UNAVAILABLE_NOTED_MODAL;

      openModal(FAKE_DOOR_THANK_YOU_MODAL, getNotifyMeThankYouModalData(translate, onChangeHandler), false);
    } else {
      openModal(INTERESTED_IN_THIS_SERVICE_MODAL, { onChangeHandler }, false);
    }

    triggerNotifyMeAnalyticsEvent(modalType + IMPRESSION);
  };

  const { accessibleLabel, t: translate } = props;

  return (
    <div className="unavailable-slot-text unavailable-slot-text--notify">
      <VisuallyHidden>{accessibleLabel}</VisuallyHidden>
      <div>{translate('slots:common.unavailable')}</div>
      <StyledButton
        domRef={notifyMeRef}
        onClick={handleNotifyMeLinkClick}
        icon={<Icon graphic={'notification' as keyof Graphics} />}
      >
        {translate('slots:oop-2034.notify-me')}
      </StyledButton>
    </div>
  );
};

export default helpers(['t'])(connect(null, { openModal, closeModal })(UnavailableHDSlotWithNotifyButton));
