import React, { MouseEvent, useState } from 'react';
import { compose } from 'react-recompose';
import { InjectedRouter } from 'react-router';
import withCheckedSPA, { CheckSPAForRedirect } from '#/components/withCheckedSPA';
import { connect } from '#/lib/render/connect-deep-compare';
import { getCurrentUrl } from '#/reducers/app';
import { Graphics, IconSizesKeys } from '@ddsweb/types';
import {
  Pagination,
  PaginationBar,
  PaginationControls,
  PaginationLink,
  PaginationPage,
  PaginationResult,
} from '@ddsweb/pagination';
import { generateUrl } from '#/experiments/oop-1801/helpers';
import helpers from '#/lib/decorators/helpers';

type DDSPaginationTemplate = {
  currentPage: number;
  pageCount: number;
  edgeActionClassName: string;
  delta: number;
  mobileDelta: number;
  paginationResultText: JSX.Element;
  currentUrl: string;
  router: InjectedRouter;
  className?: string;
};

type DDSPaginationTemplateStateProps = {
  currentUrl: string;
};

const mapStateToProps = (state: Store): DDSPaginationTemplateStateProps => ({
  currentUrl: getCurrentUrl(state),
});

const backWardLinkIcon = {
  graphic: 'backwardLink' as keyof Graphics,
  size: 'xs' as IconSizesKeys,
};

const forwardLinkIcon = {
  graphic: 'forwardLink' as keyof Graphics,
  size: 'xs' as IconSizesKeys,
};

export const DDSPaginationTemplate: React.FC<DDSPaginationTemplate &
  DDSPaginationTemplateStateProps & { checkSPAForRedirect: CheckSPAForRedirect }> = ({
  delta,
  edgeActionClassName,
  pageCount,
  paginationResultText,
  currentUrl,
  className,
  checkSPAForRedirect,
  ...props
}) => {
  const [currentPage, setCurrentPage] = useState(props.currentPage);

  const pageChangeHandler = (ev: MouseEvent, page: number): void => {
    ev.preventDefault();
    setCurrentPage(page);
    checkSPAForRedirect(generateUrl(currentUrl, page));
  };

  const pageNumberLinkHandler = (ev: MouseEvent): void => {
    const pageNumber = ev.currentTarget.getAttribute('data-page');
    pageChangeHandler(ev, Number(pageNumber));
  };

  const pageEls = Array.from(Array(pageCount), (_, index) => index + 1).map((i: number) => {
    const isCurrent = i === currentPage;
    return (
      <PaginationPage key={i}>
        <PaginationLink
          ariaLabel={`page: ${i}`}
          className="page"
          currentPage={currentPage}
          disabled={false}
          href={generateUrl(currentUrl, i)}
          inverse={isCurrent}
          isCurrentPage={isCurrent}
          data-page={i}
          onClick={pageNumberLinkHandler}
          variant="standalone"
        >
          {i}
        </PaginationLink>
      </PaginationPage>
    );
  });

  const nextLinkDisabled = currentPage === pageCount;
  const prevLinkDisabled = currentPage === 1;

  const prevActionHandler = (ev: MouseEvent): void => {
    pageChangeHandler(ev, currentPage - 1);
  };

  const nextActionHandler = (ev: MouseEvent): void => {
    pageChangeHandler(ev, currentPage + 1);
  };

  const prevActionEl = (
    <PaginationPage key="previous-page">
      <PaginationLink
        aria-label="Previous Page"
        className={edgeActionClassName}
        currentPage={currentPage}
        disabled={prevLinkDisabled}
        href={prevLinkDisabled ? '' : generateUrl(currentUrl, currentPage - 1)}
        icon={backWardLinkIcon}
        onClick={prevActionHandler}
        variant="iconButton"
      />
    </PaginationPage>
  );

  const nextActionEl = (
    <PaginationPage key="next-page">
      <PaginationLink
        aria-label="Next Page"
        className={edgeActionClassName}
        currentPage={currentPage}
        disabled={nextLinkDisabled}
        href={nextLinkDisabled ? '' : generateUrl(currentUrl, currentPage + 1)}
        icon={forwardLinkIcon}
        onClick={nextActionHandler}
        variant="iconButton"
      />
    </PaginationPage>
  );

  const paginationResultEl = paginationResultText ? <PaginationResult>{paginationResultText}</PaginationResult> : null;

  return (
    <PaginationBar className={className}>
      {paginationResultEl}
      <PaginationControls>
        <Pagination
          currentPage={currentPage}
          delta={delta}
          nextAction={nextActionEl}
          previousAction={prevActionEl}
          showTopBorder
        >
          {pageEls}
        </Pagination>
      </PaginationControls>
    </PaginationBar>
  );
};

const enhance = compose(connect(mapStateToProps), helpers(['l', 't', 'f']), withCheckedSPA);

export default enhance(DDSPaginationTemplate);
