import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from '#/lib/render/connect-deep-compare';
import classnames from 'classnames';
import { InLineMessaging } from '@ddsweb/messaging';
import { WARNING } from '@ddsweb/constants';
import Text from '@ddsweb/text';
import { Heading } from '@ddsweb/heading';
import helpers from '#/lib/decorators/helpers';
import Slot from '#/components/slots/slot-selector/slot';
import DeliverySaverWarning from '#/components/slots/delivery-saver/warning';
import NoSlotMessage from '#/components/slots/slot-selector/no-slot-msg';
import {
  getLastSelectedSlot,
  getTrolleyShoppingMethod
} from '#/selectors/trolley';
import { getSelectedDateSlots } from '#/reducers/slot';
import { isBookedSlot, hasAvailableSlots } from '#/lib/slot/slot-utils';
import { LIST } from '#/constants/slot-views';
import { getLanguage, getTimezone } from '#/reducers/app';
import { SHOPPING_METHOD_PROP_TYPE } from '#/components/slots/prop-types';
import FormattedSlotTime from '#/components/shared/formatted-time/formattedSlotTime';
import SafelyOutputString from '#/components/safely-output-string';
import styled from 'styled-components';
import { formatBookedDaysHumanized } from '#/lib/slot/slot-utils';
import {
  getIsTimesOfTheDayVariant,
  getIsTimesOfDayVariantROI
} from '#/experiments/oop-1338/selectors';
import { getSlotsGroupedByTimesOfTheDay } from '#/experiments/oop-1338/helpers';
import { getBestValueSlots } from '#/selectors/slot';
import {
  triggerBestValueEvent,
  triggerBestValueUpdateEvent
} from '#/analytics/best-value-slots';
import { HIGHLIGHT_SLOT_IMPRESSION } from '#/analytics/constants';
import { getIsTimesOfTheDaySplitVariant } from '#/experiments/oop-1834/selectors';

const StyledInlineMessaging = styled(InLineMessaging)`
  margin: 20px;
`;

const mapStateToProps = (state, ownProps) => {
  const slots = ownProps.sameDayMultipleDeliveryWarning
    ? []
    : getSelectedDateSlots(state);
  const areSlotsAvailable = hasAvailableSlots(slots);

  return {
    bookedSlot: getLastSelectedSlot(state),
    areSlotsAvailable,
    applyTimesOfTheDayVariant:
      areSlotsAvailable &&
      (getIsTimesOfDayVariantROI(state) ||
        getIsTimesOfTheDaySplitVariant(state) ||
        getIsTimesOfTheDayVariant(state)),
    bestValueSlots: areSlotsAvailable ? getBestValueSlots(state, slots) : null,
    language: getLanguage(state),
    slots,
    timezone: getTimezone(state),
    isSelectedShoppingMethodSame:
      ownProps.shoppingMethod === getTrolleyShoppingMethod(state)
  };
};

@helpers(['t'])
@connect(mapStateToProps)
export default class SlotList extends Component {
  static propTypes = {
    applyTimesOfTheDayVariant: PropTypes.bool,
    areSlotsAvailable: PropTypes.bool,
    areSlotsPending: PropTypes.bool,
    bestValueSlots: PropTypes.array,
    bookedSlot: PropTypes.object,
    deliverySaverWarning: PropTypes.bool,
    isSelectedShoppingMethodSame: PropTypes.bool.isRequired,
    language: PropTypes.string.isRequired,
    onKeyPressed: PropTypes.func,
    sameDayMultipleDeliveryWarning: PropTypes.bool,
    selectedDate: PropTypes.object,
    shoppingMethod: SHOPPING_METHOD_PROP_TYPE.isRequired,
    slots: PropTypes.arrayOf(PropTypes.object).isRequired,
    t: PropTypes.func,
    timezone: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.slotListRef = React.createRef();
  }

  componentDidMount() {
    const { areSlotsPending, bestValueSlots } = this.props;

    if (!areSlotsPending && bestValueSlots?.length > 0) {
      triggerBestValueEvent(
        HIGHLIGHT_SLOT_IMPRESSION,
        this.slotListRef?.current
      );
    }
  }

  componentDidUpdate({ bestValueSlots: prevBestValueSlots }) {
    const { bestValueSlots } = this.props;

    triggerBestValueUpdateEvent(
      prevBestValueSlots,
      bestValueSlots,
      this.slotListRef?.current
    );
  }

  getNoSlotsText() {
    const {
      sameDayMultipleDeliveryWarning,
      t: translate,
      shoppingMethod,
      selectedDate
    } = this.props;

    if (sameDayMultipleDeliveryWarning) {
      const text = translate(
        `slots:common.no-same-day-booking.${shoppingMethod}`,
        {
          smart_count: 1,
          days: formatBookedDaysHumanized([selectedDate], translate)[0]
        }
      );
      return (
        <StyledInlineMessaging
          variant={WARNING}
          assistiveText={translate('common:warning')}
        >
          <Text>
            <SafelyOutputString>{text}</SafelyOutputString>
          </Text>
        </StyledInlineMessaging>
      );
    }
    return (
      <div className="slot-list--none-available">
        <div className="info-message">
          <NoSlotMessage />
        </div>
      </div>
    );
  }

  createListItem = slot => {
    const {
      bestValueSlots,
      bookedSlot,
      deliverySaverWarning,
      timezone,
      isSelectedShoppingMethodSame,
      onKeyPressed
    } = this.props;
    const isBooked = isBookedSlot(slot, bookedSlot, timezone);
    const isBestValue = bestValueSlots?.includes(slot.slotId) || false;
    const status = isBooked ? 'booked' : slot?.status?.toLowerCase();

    const itemClasses = classnames(`slot-list--item ${status}`, {
      'delivery-saver': deliverySaverWarning
    });

    return (
      <li className={itemClasses} key={`list-slot-${slot.slotId}`}>
        <span className="slot-list--times">
          <FormattedSlotTime startTime={slot.start} endTime={slot.end} />
        </span>
        <div className="status">
          <Slot
            slotViewMode={LIST}
            slot={slot}
            isBestValue={isBestValue}
            isSelectedShoppingMethodSame={isSelectedShoppingMethodSame}
            onKeyPressed={onKeyPressed}
          />
        </div>
        {deliverySaverWarning && isBooked && <DeliverySaverWarning />}
      </li>
    );
  };

  render() {
    const {
      applyTimesOfTheDayVariant,
      areSlotsAvailable,
      timezone,
      t: translate
    } = this.props;
    const slots = this.props.slots || [];
    let slotItems;

    if (applyTimesOfTheDayVariant && slots.length > 1) {
      const slotsByTimesOfTheDay = getSlotsGroupedByTimesOfTheDay(
        slots,
        timezone
      );

      slotItems = Object.entries(slotsByTimesOfTheDay).map(
        ([timesOfTheDayName, slots], index) => (
          <div
            className={classnames('slot-list__group', {
              'slot-list__group--highlight': index === 1
            })}
            key={`slot-list-${timesOfTheDayName}`}
          >
            <Heading headingLevel="4" visualSize="headline5">
              {translate(`common:${timesOfTheDayName}`)}
            </Heading>

            <ul>{slots.map(this.createListItem)}</ul>
          </div>
        )
      );
    } else {
      slotItems = <ul>{slots.map(this.createListItem)}</ul>;
    }

    return (
      <div className="slot-list" ref={this.slotListRef}>
        {!areSlotsAvailable ? this.getNoSlotsText() : ''}
        {slotItems}
      </div>
    );
  }
}
