export function getBagOptions(bagChargesEnabled, charges, deliveryPref) {
  const bagOptions = {
    enabled: bagChargesEnabled
  };

  bagOptions.charge = charges ? charges.bagCharge : 0;
  bagOptions.isBagless = deliveryPref ? deliveryPref.isBagless : true;

  return bagOptions;
}
