import React from 'react';
import PropTypes from 'prop-types';
import Base from '#/components/base';
import { getCustomerUnitChoice, getQuantity } from '#/selectors/item';

export default class RenderQuantityLabel extends Base {
  static propTypes = {
    item: PropTypes.object.isRequired
  };

  render() {
    const unitType = getCustomerUnitChoice(this.props.item);
    const unitQuantity = getQuantity(this.props.item);
    let text;

    if (unitType === 'pcs') {
      text = this.t('checkout:payment-summary.items', {
        smart_count: unitQuantity
      });
    } else {
      text = `${unitQuantity}${unitType}`;
    }

    return <div className="quantity-label">{text}</div>;
  }
}
