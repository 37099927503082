import React from 'react';
import { HORIZONTAL, LEGACY, TROLLEY_TILE, VERTICAL } from '#/components/product-tiles/common/constants';
import { BaseProps as VerticalTileProps } from '#/components/product-tiles/vertical-tile/core';
import FlexiTile from '#/components/product-tiles/vertical-tile/variant/flexi-tile';
import { BaseProps } from '#/components/product-tiles/horizontal-tile/core';
import FavoriteTile, { FavoriteTileProps } from '#/components/product-tiles/horizontal-tile/variant/favorite-tile';
import TrolleyTile, { TrolleyTileBaseProps } from '#/components/product-tiles/horizontal-tile/variant/trolley-tile';
import ProductTile from '#/components/products/product-tile';
import HorizontalTile from '#/components/product-tiles/horizontal-tile/core';
import { PlainObject } from '#/types';
import { FAVORITES, USUALS } from '#/constants/tile-types';
import { getIsSubstitute, isInBasket as getIsInBasket } from '#/selectors/item';
import FavouriteVerticalTile from '#/components/product-tiles/vertical-tile/variant/favorites-tile';

type TileType = { type: string };
type TFavoriteTileProps = FavoriteTileProps & TileType;

export type ProductTileProps = {
  productTileVariant: typeof HORIZONTAL | typeof VERTICAL | typeof TROLLEY_TILE | typeof LEGACY;
  productTileProps: (BaseProps | TFavoriteTileProps | FavoriteTileProps | VerticalTileProps | TrolleyTileBaseProps) &
    TileType;
  legacyProps: PlainObject;
};

export const ProductTileConfiguration = (props: ProductTileProps): JSX.Element => {
  const { productTileVariant } = props;

  switch (productTileVariant) {
    case TROLLEY_TILE:
      return <TrolleyTile {...props.productTileProps} />;
    case VERTICAL:
      return getVerticalTile(props);
    case HORIZONTAL: // FIXME: SHOULD USE FAVORITES(SPEAK TO OPTIMIZELY TEAM).
      return getHorizontalTile(props);
    default:
      return <ProductTile {...props.legacyProps} />;
  }
};

const getHorizontalTile = (props: Pick<ProductTileProps, 'productTileProps'>): JSX.Element => {
  const { type: tileType } = props.productTileProps;
  switch (tileType) {
    case FAVORITES:
      return <FavoriteTile {...props.productTileProps} />;
    case USUALS:
      const { item } = props.productTileProps;
      const isSubstitute = getIsSubstitute(item);
      const isInBasket = getIsInBasket(item);

      return (
        <HorizontalTile
          {...props.productTileProps}
          showFavHeartIcon={false}
          isSubstitute={isSubstitute}
          isInBasket={isInBasket}
        />
      );
    default:
      return <HorizontalTile {...props.productTileProps} />;
  }
};

const getVerticalTile = (props: ProductTileProps): JSX.Element => {
  const {
    productTileProps: { type: tileType },
    productTileVariant,
  } = props;
  switch (tileType) {
    case FAVORITES:
      return <FavouriteVerticalTile {...props.productTileProps} />;
    case USUALS:
      return <FlexiTile {...props.productTileProps} tileVariant={productTileVariant} showFavHeartIcon={false} />;
    default:
      return <FlexiTile {...props.productTileProps} tileVariant={productTileVariant} />;
  }
};

export default ProductTileConfiguration;
