import PropTypes from 'prop-types';
import React from 'react';
import { GlobalMessaging } from '@ddsweb/messaging';
import { BrowserLink } from './styled';
import { CHROME, FIREFOX, EDGE } from '#/constants/browsers';
import helpers from '#/lib/decorators/helpers';

BrowserWarning.propTypes = {
  t: PropTypes.func.isRequired
};

export function BrowserWarning({ t: translate }) {
  return (
    <GlobalMessaging
      title={[
        `${translate('common:browser-warning.warning')} `,
        <BrowserLink href={CHROME.link} title={CHROME.name} target="_blank">
          {CHROME.name}
        </BrowserLink>,
        ', ',
        <BrowserLink href={FIREFOX.link} title={FIREFOX.name} target="_blank">
          {FIREFOX.name}
        </BrowserLink>,
        ` ${translate('common:browser-warning.or')} `,
        <BrowserLink href={EDGE.link} title={EDGE.name} target="_blank">
          {EDGE.name}
        </BrowserLink>
      ]}
      variant="error"
    />
  );
}

export default React.memo(helpers('t')(BrowserWarning));
