import React from 'react';
import PropTypes from 'prop-types';
import Count from './count';
import PageSelector from './page-selector';
import { connect } from '../../lib/render/connect-deep-compare';
import helpers from '../../lib/decorators/helpers';
import { getSupportsFlexiPageSize } from '../../reducers/results';
import ShowMoreLess from '../show-more-less';
import { BOTTOM } from '../../constants/common';

const mapStateToProps = state => ({
  supportsFlexiPageSize: getSupportsFlexiPageSize(state)
});

class Pagination extends React.PureComponent {
  render() {
    const {
      pageNo,
      itemsCount,
      itemsPerPage,
      totalCount,
      supportsFlexiPageSize,
      paginationLabels,
      c
    } = this.props;
    const firstItemIndex = (pageNo - 1) * itemsPerPage;
    const numPages = Math.ceil(totalCount / itemsPerPage);

    return (
      <div className="pagination-component grid">
        {supportsFlexiPageSize && c('displayShowMoreLess') ? (
          <ShowMoreLess
            className="pagination__results-count"
            position={BOTTOM}
          />
        ) : (
          <Count
            max={firstItemIndex + itemsCount}
            min={firstItemIndex + 1}
            total={totalCount}
          />
        )}
        <PageSelector
          currentPageNumber={pageNo}
          totalPages={numPages}
          paginationLabels={paginationLabels}
        />
      </div>
    );
  }
}

Pagination.propTypes = {
  c: PropTypes.func.isRequired,
  itemsCount: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  pageNo: PropTypes.number.isRequired,
  paginationLabels: PropTypes.shape({
    currentPageLabel: PropTypes.string.isRequired,
    changePageLabel: PropTypes.string.isRequired
  }),
  supportsFlexiPageSize: PropTypes.bool.isRequired,
  totalCount: PropTypes.number.isRequired
};

export default connect(mapStateToProps)(helpers(['c'])(Pagination));
