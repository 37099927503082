import React from 'react';
import withCheckedSPA from '../withCheckedSPA';
import PropTypes from 'prop-types';
import { connect } from '../../lib/render/connect-deep-compare';
import { compose } from 'react-recompose';
import Count from './count';
import helpers from '../../lib/decorators/helpers';
import PaginationButton from './pagination-button';
import { paginate } from '../../actions/results-actions';
import { updateParamsInUrl, parseQueryString } from '../../lib/url/url-utils';
import { SectionMessage } from '../cms-driven/section-message';
import scroll from 'scroll';
import { getCurrentUrl } from '#/reducers/app';

const scrollDoc = process.env.CLIENT_SIDE ? require('scroll-doc')() : null;

const generateUrl = (pageNo, currentUrl) =>
  updateParamsInUrl(currentUrl, { page: pageNo });

const generateOptions = (numPages, pageText) => {
  const optionList = new Array(numPages);

  for (let i = 0; i < numPages; i++) {
    optionList[i] = (
      <option key={i} value={String(i + 1)}>
        {`${pageText} ${i + 1}`}
      </option>
    );
  }

  return optionList;
};

const updatePage = (e, path, props) => {
  e.preventDefault();

  props.checkSPAForRedirect(props.l(path));
  props.paginate(
    { params: props.params, routes: props.routes },
    parseQueryString(path)
  );
};

const handlePageChange = (e, url, props) => {
  if (scrollDoc) {
    scroll.top(scrollDoc, props.scrollTopPos);
  }

  return updatePage(e, url, props);
};

const handlePageSelectionChange = (e, currentUrl, props) => {
  return handlePageChange(e, generateUrl(e.target.value, currentUrl), props);
};

const gotoPreviousOrNextResultsPage = (
  e,
  noPreviousOrNextPage,
  pageNo,
  currentUrl,
  props
) => {
  if (noPreviousOrNextPage) {
    return false;
  }

  return handlePageChange(e, generateUrl(pageNo, currentUrl), props);
};

// TODO: Component design is currently optimised for Mobile, but there is nothing stopping this component working in
// larger viewports
const MobilePagination = ({ pageNo, ...props }) => {
  const firstItemIndex = (pageNo - 1) * props.itemsPerPage;
  const numPages = Math.ceil(props.totalCount / props.itemsPerPage); // Eventually will surface as a field from mango
  const currentUrl = props.currentUrl;
  const pageText = props.t('common:page');

  // Treat any pageNo prop as as first/last page respectively if the number falls below/above the defined page range
  const firstPage = pageNo <= 1;
  const lastPage = pageNo >= numPages;

  return (
    <div className="pagination-component mobile">
      {props.showDivider && <SectionMessage />}
      <Count
        max={firstItemIndex + props.itemsCount}
        min={firstItemIndex + 1}
        total={props.totalCount}
      />
      <ul className="pagination--page-selector-wrapper">
        <PaginationButton
          className="prev-next"
          onClickHandler={e =>
            gotoPreviousOrNextResultsPage(
              e,
              firstPage,
              pageNo - 1,
              currentUrl,
              props
            )
          }
          disabled={firstPage}
          href={generateUrl(pageNo - 1, currentUrl)}
          icon="icon-icon_whitechevronleft"
          key="prev"
          label={props.t('navigation:go-to-prev-results-page')}
          value=""
        />
        <li className="pagination-btn-holder">
          <select
            className="pagination--page-selector selector"
            onChange={e => handlePageSelectionChange(e, currentUrl, props)}
            value={pageNo}
            aria-hidden="true"
          >
            {generateOptions(numPages, pageText)}
          </select>
        </li>
        <li className="pagination-btn-holder">
          <div className="pagination--page-selector display-only">{`${pageText} ${pageNo}`}</div>
        </li>
        <PaginationButton
          className="prev-next"
          onClickHandler={e =>
            gotoPreviousOrNextResultsPage(
              e,
              lastPage,
              pageNo + 1,
              currentUrl,
              props
            )
          }
          disabled={lastPage}
          href={generateUrl(pageNo + 1, currentUrl)}
          icon="icon-icon_whitechevronright"
          key="next"
          label={props.t('navigation:go-to-next-results-page')}
          value=""
        />
      </ul>
    </div>
  );
};

MobilePagination.propTypes = {
  c: PropTypes.func.isRequired,
  currentUrl: PropTypes.string.isRequired,
  f: PropTypes.func.isRequired,
  itemsCount: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  l: PropTypes.func.isRequired,
  pageNo: PropTypes.number.isRequired,
  paginate: PropTypes.func.isRequired,
  params: PropTypes.objectOf(PropTypes.any).isRequired,
  routes: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  scrollTopPos: PropTypes.number.isRequired,
  showDivider: PropTypes.bool,
  t: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired
};

const mapStateToProps = state => ({ currentUrl: getCurrentUrl(state) });

const enhance = compose(
  connect(mapStateToProps, { paginate }),
  helpers(['l', 't', 'f', 'c']),
  withCheckedSPA
);

export default enhance(MobilePagination);
