import React from 'react';
import PropTypes from 'prop-types';

export default class Select extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.array
  };

  onChange = e => {
    if (!this.props.onChange) {
      return;
    }

    this.props.onChange(e.target.value);

    e.target.blur();
    e.preventDefault();
  };

  render() {
    let selectedValue = 0;
    const { options: propOptions, id, name } = this.props;
    let options = propOptions.map(opt => {
      if (opt.selected) {
        selectedValue = opt.value;
      }

      return (
        <option key={opt.key} value={opt.value}>
          {opt.label}
        </option>
      );
    });

    return (
      <div className="select-wrapper">
        <select
          id={id}
          name={name}
          className="needsclick"
          onChange={this.onChange}
          value={selectedValue}
        >
          {options}
        </select>
      </div>
    );
  }
}
