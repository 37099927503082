import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const CheckboxDDL = ({ checked, disabled, error, id, onChange, children }) => {
  const classes = classNames('checkbox-ddl', {
    disabled: disabled,
    error: error
  });

  return (
    <div className={classes}>
      <input
        type="checkbox"
        checked={checked}
        disabled={disabled}
        id={id}
        onChange={onChange}
      />
      <label className="checkbox-label" htmlFor={id}>
        {children}
      </label>
      <label className="checkbox-option-mark" htmlFor={id}>
        <span className="icon-ddl-tick-white" />
      </label>
    </div>
  );
};

CheckboxDDL.propTypes = {
  checked: PropTypes.bool,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func
};

CheckboxDDL.defaultProps = {
  checked: false,
  disabled: false,
  error: false
};

export default CheckboxDDL;
