import getResourceNameFromConfig from '#/conditional-resources/helpers/get-resource-name-from-config';

export default function spliceMatchingResourceFromList(resourceList, resource) {
  const index = resourceList.findIndex(
    resourceListEntry =>
      getResourceNameFromConfig(resourceListEntry) ===
      getResourceNameFromConfig(resource)
  );

  if (index === -1) {
    return undefined;
  }

  return resourceList.splice(index, 1)[0];
}
