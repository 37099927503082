import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from '#/lib/render/connect-deep-compare';
import SafeForm from '#/components/shared/safe-form';
import { openModal } from '#/actions/ui-action-creators';
import { updateRemoveFavourite } from '#/actions/results-actions';
import RemoveItemButton from '#/components/buttons/remove-item';
import { getCurrentUrl, getLanguageLink } from '#/reducers/app';
import { getProductId, getProductTitle } from '#/selectors/item';

function mapStateToProps(state) {
  return {
    formAction: getLanguageLink(state, '/favorites/action'),
    redirectTo: getCurrentUrl(state)
  };
}

@connect(mapStateToProps, { openModal, updateRemoveFavourite })
export default class RemoveItem extends Component {
  static propTypes = {
    formAction: PropTypes.string.isRequired,
    item: PropTypes.object.isRequired,
    openModal: PropTypes.func.isRequired,
    redirectTo: PropTypes.string.isRequired,
    removeActionModal: PropTypes.string,
    updateRemoveFavourite: PropTypes.func.isRequired
  };

  remove = evt => {
    evt.preventDefault();

    if (this.props.removeActionModal) {
      this.props.openModal(this.props.removeActionModal);
    }

    this.props.updateRemoveFavourite(this.props.item);
  };

  render() {
    const itemId = { id: String(getProductId(this.props.item)) };

    return (
      <SafeForm action={this.props.formAction} method="post">
        <input type="hidden" name="redirectTo" value={this.props.redirectTo} />
        <input type="hidden" name="items" value={JSON.stringify(itemId)} />
        <RemoveItemButton
          onClick={this.remove}
          name="submit"
          type="submit"
          textKey="product-list:remove-from-favourites"
          textProps={{
            productName: getProductTitle(this.props.item)
          }}
        />
      </SafeForm>
    );
  }
}
