import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from '@ddsweb/heading';
import { connect } from '#/lib/render/connect-deep-compare';
import classnames from 'classnames';
import {
  getLocalDate,
  isBookedSlot,
  hasAvailableSlots,
  getIsSameDayFromMoment,
  getIsNextDayFromMoment,
  getSlotsWithoutToday
} from '#/lib/slot/slot-utils';
import Slot from '#/components/slots/slot-selector/slot';
import DeliverySaverWarning from '#/components/slots/delivery-saver/warning';
import {
  getLastSelectedSlot,
  getIsAmendBasket,
  getTrolleyShoppingMethod
} from '#/selectors/trolley';
import { getLanguage, getTimezone } from '#/reducers/app';
import {
  getBestValueSlots,
  isUnavailableHDSlotWithCollectLink
} from '#/selectors/slot';
import helpers from '#/lib/decorators/helpers';
import SameDayWarningMessage from '#/components/slots/same-day-warning-message';
import NoSlotMessage from '#/components/slots/slot-selector/no-slot-msg';
import { BOOKED, RESERVED } from '#/constants/slot-statuses';
import { GRID } from '#/constants/slot-views';
import {
  getDisplayDeliverySaverAcquisitionBanner,
  getSelectedWeekSlots
} from '#/reducers/slot';
import { SHOPPING_METHOD_PROP_TYPE } from '#/components/slots/prop-types';
import { getPendingOrdersByDate } from '#/selectors/order-list-details';
import { StyledHumanizedDay } from './styled';
import { OTHER, TODAY, TOMORROW } from './constants';
import FormattedSlotTime from '#/components/shared/formatted-time/formattedSlotTime';
import { getDisplayCookieInterrupt } from '#/reducers/ui';
import StickySlotGridHeader from '#/experiments/oop-1335/components/sticky-slot-grid-header';
import { getIsStickyHeaderSlotCellsUiUpdateVariant } from '#/experiments/oop-1335/selectors';
import StickyElement from '#/experiments/oop-1335/components/sticky-element';
import {
  getIsStickyHeaderVariant,
  getIsTimesOfDayVariantROI,
  getIsTimesOfTheDayVariant
} from '#/experiments/oop-1338/selectors';
import { getSlotsGroupedByTimesOfTheDay } from '#/experiments/oop-1338/helpers';
import { formatDate } from '#/lib/slot/slot-range-utils';
import {
  triggerBestValueEvent,
  triggerBestValueUpdateEvent
} from '#/analytics/best-value-slots';
import { HIGHLIGHT_SLOT_IMPRESSION } from '#/analytics/constants';
import { getIsTimesOfTheDaySplitVariant } from '#/experiments/oop-1834/selectors';
import DeliverySaverAcquisitionBanner from '#/components/slots/ds-acquisition-banner';
import { timesOfTheDayRowClass } from '#/experiments/oop-1338/constants';
import { StyledDeliverySaverSlotContainer } from '../../ds-acquisition-banner/styled';

const SAME_DAY_WARNING_MESSAGE_ID = 'same-day-warning-message';

export const mapStateToProps = (state, { shoppingMethod, c: config }) => {
  const slots = getSelectedWeekSlots(state);
  const displayCookieInterrupt = getDisplayCookieInterrupt(state);
  const areSlotsAvailable = Object.values(slots).some(slots =>
    hasAvailableSlots(slots)
  );
  const today = new Date();
  const formattedDate = formatDate(today, getTimezone(state));
  const slotsWithoutToday = getSlotsWithoutToday(slots, formattedDate);

  return {
    areSlotsAvailable,
    bestValueSlots: areSlotsAvailable
      ? getBestValueSlots(state, slotsWithoutToday)
      : null,
    bookedSlot: getLastSelectedSlot(state),
    isAmendBasket: getIsAmendBasket(state),
    applyStickyHeaderVariant:
      areSlotsAvailable &&
      getIsStickyHeaderVariant(state) &&
      !getIsTimesOfDayVariantROI(state),
    applyTimesOfTheDayVariant:
      areSlotsAvailable &&
      (getIsTimesOfDayVariantROI(state) ||
        getIsTimesOfTheDaySplitVariant(state) ||
        getIsTimesOfTheDayVariant(state)),
    language: getLanguage(state),
    pendingOrdersByDate: getPendingOrdersByDate(state),
    slots,
    timezone: getTimezone(state),
    isSelectedShoppingMethodSame:
      shoppingMethod === getTrolleyShoppingMethod(state),
    applyCollectLinkStyleVariant: isUnavailableHDSlotWithCollectLink(
      state,
      config
    ),
    displayCookieInterrupt,
    stickyHeaderVariant:
      getIsStickyHeaderSlotCellsUiUpdateVariant(state) && areSlotsAvailable,
    showDeliverySaverAcquisitionBanner: getDisplayDeliverySaverAcquisitionBanner(
      config,
      state
    )
  };
};

class SlotGrid extends StickyElement {
  static propTypes = {
    applyCollectLinkStyleVariant: PropTypes.bool.isRequired,
    applyStickyHeaderVariant: PropTypes.bool,
    applyTimesOfTheDayVariant: PropTypes.bool,
    areSlotsAvailable: PropTypes.bool,
    areSlotsPending: PropTypes.bool,
    bestValueSlots: PropTypes.array,
    bookedSlot: PropTypes.object,
    c: PropTypes.func.isRequired,
    deliverySaverWarning: PropTypes.bool,
    isLargeScreen: PropTypes.bool,
    displayCookieInterrupt: PropTypes.bool,
    isAmendBasket: PropTypes.bool,
    isSelectedShoppingMethodSame: PropTypes.bool.isRequired,
    language: PropTypes.string.isRequired,
    onKeyPressed: PropTypes.func,
    pendingOrdersByDate: PropTypes.object,
    shoppingMethod: SHOPPING_METHOD_PROP_TYPE.isRequired,
    showDeliverySaverAcquisitionBanner: PropTypes.bool,
    slots: PropTypes.object.isRequired,
    stickyHeaderVariant: PropTypes.bool,
    t: PropTypes.func.isRequired,
    timezone: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);

    this.slotGridRef = React.createRef();
  }

  componentDidMount() {
    const { areSlotsPending, bestValueSlots } = this.props;

    if (!areSlotsPending && bestValueSlots?.length > 0) {
      triggerBestValueEvent(
        HIGHLIGHT_SLOT_IMPRESSION,
        this.slotGridRef?.current
      );
    }
  }

  componentDidUpdate({ bestValueSlots: prevBestValueSlots }) {
    const { bestValueSlots } = this.props;

    triggerBestValueUpdateEvent(
      prevBestValueSlots,
      bestValueSlots,
      this.slotGridRef?.current
    );
  }

  getNotification() {
    const { c: config } = this.props;

    return config('showSameDayMultipleDeliveryWarning') ? (
      <SameDayWarningMessage id={SAME_DAY_WARNING_MESSAGE_ID} />
    ) : null;
  }

  getRelativeSlotGridDay(day) {
    const { t: translate } = this.props;

    return (
      <StyledHumanizedDay>
        {translate(`slots:common.${day}`)}
      </StyledHumanizedDay>
    );
  }

  getExplicitSlotGridDay(localDate) {
    return (
      <>
        <span aria-hidden="true" className="slot-grid--weekday">
          {localDate.format('ddd')}
        </span>
        <span className="slot-grid--day">{localDate.format('DD')}</span>
      </>
    );
  }

  getTableHeader() {
    const { slots, c: config, timezone, language } = this.props;
    const tableHeader = [
      <th key="empty" className="slot-grid--times-filler" />
    ];

    Object.entries(slots).forEach(([date]) => {
      const localDate = getLocalDate(date, timezone, language);
      const showFirstSlotColAsToday = config('showFirstSlotColAsToday');
      const showSecondSlotColAsTomorrow = config('showSecondSlotColAsTomorrow');
      const defaultHeaderComponent = this.getExplicitSlotGridDay(localDate);

      const headerComponent = {
        [TODAY]: showFirstSlotColAsToday
          ? this.getRelativeSlotGridDay(TODAY)
          : defaultHeaderComponent,
        [TOMORROW]: showSecondSlotColAsTomorrow
          ? this.getRelativeSlotGridDay(TOMORROW)
          : defaultHeaderComponent,
        [OTHER]: defaultHeaderComponent
      };

      const dayIs = getIsSameDayFromMoment(localDate)
        ? TODAY
        : getIsNextDayFromMoment(localDate)
        ? TOMORROW
        : OTHER;

      tableHeader.push(
        <th
          className="slot-grid--date"
          key={date}
          scope="col"
          title={localDate.format('dddd Do MMMM')}
        >
          {headerComponent[dayIs]}
        </th>
      );
    });

    return tableHeader;
  }

  getSlotStartTimes() {
    const slotStartTimes = {};
    Object.entries(this.props.slots).forEach(([, daySlots]) => {
      daySlots.forEach(slot => {
        slotStartTimes[
          slot.start
            .clone()
            .tz(this.props.timezone)
            .format('HHmm')
        ] = true;
      });
    });

    return Object.keys(slotStartTimes)
      .sort()
      .map(startTime => parseInt(startTime, 10));
  }

  getSlotsByTimeThenDate() {
    const slotsByDateAndTime = {};

    Object.entries(this.props.slots).forEach(([date, slotsForDay]) => {
      if (!slotsByDateAndTime[date]) {
        slotsByDateAndTime[date] = {};
      }

      slotsForDay.forEach(slot => {
        slotsByDateAndTime[date][
          parseInt(
            `${slot.start
              .clone()
              .tz(this.props.timezone)
              .format('HHmm')}`
          )
        ] = slot;
      });
    });

    const slotStartTimes = this.getSlotStartTimes(); // [ 700, 800, 900, 1000 ] etc

    return slotStartTimes.map(possibleSlotTime => {
      return Object.keys(slotsByDateAndTime).map(slotDate => {
        return slotsByDateAndTime[slotDate][possibleSlotTime];
      });
    });
  }

  createRow = (slots, runIndex) => {
    const {
      bestValueSlots,
      bookedSlot,
      deliverySaverWarning,
      isAmendBasket,
      pendingOrdersByDate,
      timezone,
      shoppingMethod,
      isSelectedShoppingMethodSame,
      applyCollectLinkStyleVariant,
      onKeyPressed,
      isLargeScreen
    } = this.props;
    const firstSlot = slots.find(slot => !!slot && slot.start);
    let rowHasBookedSlot = false;

    const slotsCells = slots.map((slot, columnNumber) => {
      if (!slot) return;

      const isBestValue = bestValueSlots?.includes(slot.slotId) || false;

      const isBooked =
        isSelectedShoppingMethodSame &&
        isBookedSlot(slot, bookedSlot, timezone);
      const showBookedSlotWarning = deliverySaverWarning && isBooked;
      const slotStartDate = slot.start.format('YYYY-MM-DD');
      const slotDayHasDelivery =
        pendingOrdersByDate.hasOwnProperty(slotStartDate) &&
        pendingOrdersByDate[slotStartDate].find(
          order =>
            order.shoppingMethod === shoppingMethod && !order.isInAmendMode
        );
      const status = isBooked ? BOOKED : slot.status;
      const gridItemStatusClass =
        !isAmendBasket && status === RESERVED
          ? 'reserved-non-amend-mode'
          : status.toLowerCase();
      const cellLabel = slotDayHasDelivery
        ? SAME_DAY_WARNING_MESSAGE_ID
        : undefined;

      rowHasBookedSlot = showBookedSlotWarning ? true : rowHasBookedSlot;

      return (
        <td
          key={`${runIndex}-${columnNumber}-${slot.locationId}`}
          className={classnames({
            'slot-grid--with-warning': showBookedSlotWarning,
            'slot-grid--with-warning--cc-link-enabled':
              showBookedSlotWarning && applyCollectLinkStyleVariant
          })}
          aria-labelledby={cellLabel}
        >
          <div
            className={classnames(`slot-grid--item ${gridItemStatusClass}`, {
              'slot-grid--item--cc-link-enabled': applyCollectLinkStyleVariant
            })}
          >
            <Slot
              isLargeScreen={isLargeScreen}
              slotViewMode={GRID}
              slot={slot}
              isBestValue={isBestValue}
              isSelectedShoppingMethodSame={isSelectedShoppingMethodSame}
              onKeyPressed={onKeyPressed}
            />
          </div>
          {showBookedSlotWarning && (
            <div className="arrow-wrap">
              <div className="icon-yellow_arrow_down" />
            </div>
          )}
        </td>
      );
    });

    const rows = [
      <tr key={'row-' + runIndex}>
        <th
          scope="row"
          className="slot-grid--times"
          key={'row-heading-' + runIndex}
        >
          <FormattedSlotTime
            startTime={firstSlot.start}
            endTime={firstSlot.end}
          />
        </th>
        {slotsCells}
      </tr>
    ];

    if (deliverySaverWarning && rowHasBookedSlot) {
      rows.push(
        <tr className="delivery-saver-warning--row">
          <td colSpan="8">
            <DeliverySaverWarning />
          </td>
        </tr>
      );
    }

    return rows;
  };

  render() {
    const {
      areSlotsAvailable,
      applyCollectLinkStyleVariant,
      applyStickyHeaderVariant,
      applyTimesOfTheDayVariant,
      showDeliverySaverAcquisitionBanner,
      stickyHeaderVariant,
      t: translate,
      timezone
    } = this.props;
    const slotsByTimeThenDate = this.getSlotsByTimeThenDate();

    let tableContent;

    if (applyTimesOfTheDayVariant && slotsByTimeThenDate.length > 1) {
      const slotsByTimesOfTheDay = getSlotsGroupedByTimesOfTheDay(
        slotsByTimeThenDate,
        timezone
      );

      tableContent = Object.entries(slotsByTimesOfTheDay).map(
        ([timesOfTheDayName, slots], index) => (
          <tbody
            className={classnames('slot-grid__table-body', {
              'slot-grid__table-body--highlight': index === 1
            })}
            key={`table-${timesOfTheDayName}`}
          >
            <tr
              key={`${timesOfTheDayRowClass}-row-${index}`}
              className={timesOfTheDayRowClass}
            >
              <td colSpan={8}>
                <Heading headingLevel="4" visualSize="headline5">
                  {translate(`common:${timesOfTheDayName}`)}
                </Heading>
              </td>
            </tr>

            {slots.map(this.createRow)}
          </tbody>
        )
      );
    } else {
      tableContent = (
        //Ref element associated to oop-1335
        <tbody ref={this.bottomRef}>
          {slotsByTimeThenDate.map(this.createRow)}
        </tbody>
      );
    }

    return (
      <div
        ref={this.slotGridRef}
        className={classnames('slot-grid', {
          'slot-grid--cc-link-enabled': applyCollectLinkStyleVariant
        })}
      >
        <span>
          {showDeliverySaverAcquisitionBanner && (
            <div className="delivery-saver-banner">
              <StyledDeliverySaverSlotContainer>
                <DeliverySaverAcquisitionBanner />
              </StyledDeliverySaverSlotContainer>
            </div>
          )}
        </span>
        {areSlotsAvailable ? (
          <div className="slot-grid__table-holder">
            {this.getNotification()}
            {stickyHeaderVariant && (
              <StickySlotGridHeader
                fixedHeader={this.state.fixedHeader}
                headerOffset={this.state.headerOffset}
                headerAnimation={this.state.headerAnimation}
                tableHeaderCells={this.getTableHeader()}
              />
            )}
            <table
              className={classnames('slot-grid__table', {
                sticky__wrapper: applyStickyHeaderVariant
              })}
            >
              {/* Ref element associated to oop-1335 */}
              <thead
                ref={this.topRef}
                className={
                  stickyHeaderVariant &&
                  this.state.fixedHeader &&
                  'hide-from-view'
                }
              >
                <tr
                  className={classnames({
                    sticky__header: applyStickyHeaderVariant
                  })}
                >
                  {this.getTableHeader()}
                </tr>
              </thead>
              {tableContent}
            </table>
          </div>
        ) : (
          <div className="book-a-slot--info-message">
            <NoSlotMessage />
          </div>
        )}
      </div>
    );
  }
}

export default helpers(['c', 't'])(connect(mapStateToProps)(SlotGrid));
