import React from 'react';
import styled from 'styled-components';
import { spacing } from '@ddsweb/selectors';
import { VERTICAL } from '@ddsweb/constants';
import { DietarySvgIconComponent } from '#/experiments/oop-1922/components/assets/dietary-svg';
import { DietaryText } from '#/experiments/oop-1922/components/dietary-text';
import { DietaryTag, DietaryTagList, StateProps } from '#/experiments/oop-1922/types';
import { getProductTileDietaryInfoVariant, getIsDietaryInfoFeatureEnabled } from '#/experiments/oop-1922/selectors';
import { productTileDietaryInfoVariants, DIETARY_BACKGROUND_COLOR } from '#/experiments/oop-1922/constants';
import { connect } from '#/lib/render/connect-deep-compare';

const DietaryTagsFullContainer = styled.div`
  display: flex;
  background: ${DIETARY_BACKGROUND_COLOR};
  height: ${spacing.xl};
  padding: 3px ${spacing.xs} 5px;
  gap: ${spacing.xx};
`;

const mapDispatchToProps = (state: Store): StateProps => ({
  isDietaryInfoFeatureEnabled: getIsDietaryInfoFeatureEnabled(state),
  dietaryVariantType: getProductTileDietaryInfoVariant(state),
});

const DietaryTagsFullHorizontalContainer = styled(DietaryTagsFullContainer)``;

const { dietaryTags } = productTileDietaryInfoVariants;

const DietaryTagsFullComponent = (props: DietaryTag): JSX.Element => {
  const { label, tileType } = props;
  const content = (
    <>
      <DietarySvgIconComponent label={label} />
      <DietaryText label={label} textType={dietaryTags} />
    </>
  );

  if (tileType === VERTICAL) return <DietaryTagsFullContainer>{content}</DietaryTagsFullContainer>;

  return <DietaryTagsFullHorizontalContainer>{content}</DietaryTagsFullHorizontalContainer>;
};

export const Component = (props: DietaryTagList & StateProps): JSX.Element | null => {
  const { content, tileType, isDietaryInfoFeatureEnabled, dietaryVariantType } = props;

  if (!content?.length || !isDietaryInfoFeatureEnabled) return null;

  if (dietaryVariantType !== dietaryTags) return null;

  return (
    <>
      {content.map((item, index) => (
        <DietaryTagsFullComponent tileType={tileType} key={`${index}-${item?.toLowerCase()}`} label={item} />
      ))}
    </>
  );
};

export const DietaryTagsFull = connect(mapDispatchToProps)(Component);
