import { getVariationForExperiment } from '#/lib/optimizely-manager';
import { sitemapFooterVariants } from '../constants';

const { key, useCustomerId, variants } = sitemapFooterVariants;
const getVariant = (state: Store): string => getVariationForExperiment(state, key, {}, useCustomerId);

export const getSitemapFooterVariant = (state: Store): string => {
  const variant = getVariant(state);
  return variant ?? variants.default;
};

export const getShowAltSitemapTitle = (state: Store): boolean => {
  const variant = getSitemapFooterVariant(state);
  return variant === variants.altSitemapTitle;
};
