import React from 'react';
import styled from 'styled-components';
import { ClubCardUKLogo } from '@ddsweb/logos';
import Tag, { TagProps } from '@ddsweb/tag';
import ClubcardLogo from '#/components/promotions/clubcard-logo';
import { tags } from '#/components/product-tiles/constants';

type TagVariant = Pick<TagProps, 'variant'>;

export type TFlashSashInfoType = TagVariant | string;

type TSpecialOfferSash = {
  flashSashInfo: {
    text: string;
    type: TFlashSashInfoType;
    color?: string;
    className?: string;
  };
  className?: string;
  tileType?: string;
  tileVariant?: string;
  showClubcardLogo?: boolean;
  showCCPriceLogoInSquare: boolean;
};

const StyledClubCardUKLogo = styled(ClubCardUKLogo)`
  overflow: hidden;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
`;

export const Sash = ({
  flashSashInfo,
  className,
  showCCPriceLogoInSquare,
  showClubcardLogo = false,
}: TSpecialOfferSash): JSX.Element | null => {
  if (!flashSashInfo) {
    return null;
  }

  const { text, type, color } = flashSashInfo;
  const { clubcardPrice } = tags;

  if (type === clubcardPrice) {
    if (showClubcardLogo) {
      return showCCPriceLogoInSquare ? (
        <ClubcardLogo
          text={text}
          className={className}
          hideOnMobile={false}
          showCCPriceLogoInSquare={showCCPriceLogoInSquare}
        />
      ) : (
        <StyledClubCardUKLogo size="sm" className={className} />
      );
    }

    return null;
  }

  return (
    <Tag variant={type as TagProps['variant']} backgroundColor={color}>
      {text}
    </Tag>
  );
};

export default Sash;
