export const skipRecsModal = {
  featureKey: 'ghs-all_onecheckout-skip-offers-v2',
  variationKey: 'experience',
  variants: {
    default: 'default',
    skipRecsAllAmends: 'skip-recs-all-amends',
    skipRecsAll2ndAmends: 'skip-recs-all-2nd-amends',
    skipOffersAll2ndAmends: 'skip-offers-all-2nd-amends',
    disabled: 'disabled',
  },
  useCustomerId: true,
};
