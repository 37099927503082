import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isUndefined } from '../../utils/misc';
import { connect } from '../../lib/render/connect-deep-compare';
import { getVariationForExperiment } from '#/lib/optimizely-manager';
import helpers from '#/lib/decorators/helpers';

const mapStateToProps = (state, ownProps) => {
  const forceDefaultVariant = ownProps.f(
    'bypassOptimizelyAndUseDefaultVariant'
  );
  const forceFirstNonDefaultVariant = ownProps.f(
    'bypassOptimizelyAndUseFirstNonDefaultVariant'
  );

  if (forceDefaultVariant) {
    return {
      forceDefaultVariant: !!forceDefaultVariant,
      variation: null
    };
  } else if (forceFirstNonDefaultVariant) {
    return {
      forceFirstNonDefaultVariant: !!forceFirstNonDefaultVariant,
      variation: {}
    };
  } else {
    return {
      variation: getVariationForExperiment(
        state,
        ownProps.name,
        ownProps.attributes || {},
        ownProps.useCustomerId
      )
    };
  }
};

@helpers(['f'])
@connect(mapStateToProps)
export default class Experiment extends Component {
  static defaultProps = {
    forceDefaultVariant: false,
    forceFirstNonDefaultVariant: false,
    tagName: 'div',
    attributes: {},
    useCustomerId: false
  };

  static propTypes = {
    attributes: PropTypes.object,
    children: PropTypes.node,
    defaultVariant: PropTypes.string.isRequired,
    forceDefaultVariant: PropTypes.bool,
    forceFirstNonDefaultVariant: PropTypes.bool,
    name: PropTypes.string.isRequired,
    tagName: PropTypes.string,
    useCustomerId: PropTypes.bool,
    variation: PropTypes.string
  };

  getVariantComponentToRender(
    variation,
    forceDefaultVariant,
    forceFirstNonDefaultVariant
  ) {
    if (variation === null || forceDefaultVariant) {
      return this.getDefaultVariant();
    }

    const { children } = this.props;
    const childrenArray = React.Children.toArray(children);

    if (!childrenArray.length) {
      return null;
    }

    if (forceFirstNonDefaultVariant) {
      return childrenArray[0];
    }

    const childToRender = childrenArray.find(child => {
      const childVarProp = child.props.variant;
      if (!Array.isArray(childVarProp)) return childVarProp === variation;
      return childVarProp.find(childVar => childVar === variation);
    });

    return !isUndefined(childToRender)
      ? childToRender
      : this.getDefaultVariant();
  }

  getDefaultVariant() {
    const { children, defaultVariant } = this.props;
    const childrenArray = React.Children.toArray(children);

    return childrenArray.find(child => child.props.variant === defaultVariant);
  }

  render() {
    const {
      children,
      variation,
      forceDefaultVariant,
      forceFirstNonDefaultVariant
    } = this.props;
    const childrenArray = React.Children.toArray(children);
    const chosenVariant = this.getVariantComponentToRender(
      variation,
      forceDefaultVariant,
      forceFirstNonDefaultVariant
    );

    // if the default variant to render does not contain children
    if (!childrenArray.length || !chosenVariant) {
      return null;
    }

    return <React.Fragment>{chosenVariant}</React.Fragment>;
  }
}
