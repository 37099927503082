import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../shared/modal';
import ModalContent from '../../shared/modal/content';
import ModalActions from '../../shared/modal/actions';
import ModalButton from '../../shared/modal/actions/button-container';
import SafeForm from '../../shared/safe-form';
import { emptyTrolley } from '../../../actions/trolley/trolley-action-creators';
import { closeModal } from '../../../actions/ui-action-creators';
import { removeModalFromUrl } from '../../../lib/url/modal-utils';
import { connect } from '../../../lib/render/connect-deep-compare';
import { EMPTY_TROLLEY_MODAL } from '../../../constants/modal-names';
import helpers from '../../../lib/decorators/helpers';
import Link from '../../link-check-spa';
import { getCurrentUrl } from '../../../reducers/app';

const mapStateToProps = state => ({
  currentUrl: getCurrentUrl(state)
});

@connect(mapStateToProps, { closeModal, emptyTrolley })
@helpers(['c', 'l', 't'])
export default class EmptyTrolleyModal extends React.Component {
  static propTypes = {
    c: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    currentUrl: PropTypes.string.isRequired,
    emptyTrolley: PropTypes.func.isRequired,
    l: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.configKey = {
      enableMissedOfferTracking: this.props.c(
        'enableMissedOfferTrackingInAnalytics'
      )
    };
  }

  render() {
    return (
      <Modal closeModal={this.props.closeModal} showHeader={false}>
        <ModalContent>
          <p className="text-centered">
            {this.props.t('trolley:header.empty-trolley-modal.heading')}
          </p>
        </ModalContent>

        <ModalActions dual>
          <ModalButton>
            <Link
              className="js-empty-trolley-no button button-secondary small"
              to={removeModalFromUrl(this.props.currentUrl)}
              onClick={e => {
                e.preventDefault();
                this.props.closeModal(EMPTY_TROLLEY_MODAL);
              }}
            >
              {this.props.t('trolley:header.empty-trolley-modal.reject')}
            </Link>
          </ModalButton>
          <ModalButton>
            <SafeForm
              action={this.props.l('/trolley/items?_method=DELETE')}
              className="empty-trolley-form"
              method="POST"
              onSubmit={evt => {
                evt.preventDefault();
              }}
            >
              <input
                name="returnUrl"
                type="hidden"
                value={removeModalFromUrl(this.props.currentUrl)}
              />
              <button
                className="js-empty-trolley-yes button button-primary small"
                onClick={e => {
                  e.preventDefault();
                  this.props.closeModal(EMPTY_TROLLEY_MODAL);
                  this.props.emptyTrolley({
                    enableMissedOfferTracking: this.configKey
                      .enableMissedOfferTracking
                  });
                }}
              >
                {this.props.t('trolley:header.empty-trolley-modal.accept')}
              </button>
            </SafeForm>
          </ModalButton>
        </ModalActions>
      </Modal>
    );
  }
}
