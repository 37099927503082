import { InjectedRouter } from 'react-router';
import { request } from '#/lib/client-fetch';
import { PRODUCT_DETAILS } from '#/constants/spa-resource';
import commonActionRejectionsHandler from './common-action-rejections-handler';
import {
  updateFormValidationState,
  updateExternalValidationState,
  getFormStateValues,
} from '#/actions/form-action-creators';
import { closeModal } from '#/actions/ui-action-creators';
import { fetchResources } from '#/actions/resources-action-creators';
import { RULE_TYPES, RATINGS_AND_REVIEWS_FORM, RATINGS_REVIEWS_ANALYTICS } from '#/constants/ratings-reviews';
import { formatReviews } from '#/reducers/ratings-reviews';
import { Reviews } from '#/lib/requests/get-product-reviews.defs';
import { emitErrorDataEvent } from '#/analytics/types/error-data';
import { REPORT_A_REVIEW, RECEIVE_REVIEWS, UPDATE_REVIEWS } from '#/constants/action-types';
import { GENERIC_ERROR_MODAL } from '#/constants/modal-names';
import { getLanguageLink } from '#/reducers/app';
import { getProductItem } from '#/selectors/product-details';
import { getProductId } from '#/selectors/item';
import { Dispatch, GetStore, ThunkAction } from '#/custom-typings/redux-store/common';
import { ProductsReviewDetails } from '#/custom-typings/redux-store/rating-reviews.defs';
import { Product } from '#/resources/product-details.defs';
import { Field } from '#/custom-typings/redux-store/form.defs';
import {
  ReportAReview,
  UpdateReview,
  RecieveReview,
  SubmitReview,
  SubmitReviewError,
  Response,
} from './ratings-reviews-action-creators.defs';

const reportAReviewAction = (tpnb: string, reviewId: string): ReportAReview => ({
  type: REPORT_A_REVIEW,
  value: { tpnb, reviewId },
});

export const receiveReviews = (resourceData: Product): RecieveReview => ({
  type: RECEIVE_REVIEWS,
  value: resourceData,
});

export const updateReviews = (resourceData: ProductsReviewDetails): UpdateReview => ({
  type: UPDATE_REVIEWS,
  value: resourceData,
});

export const resetRNRFormData = () => {
  return function(dispatch: Dispatch): void {
    dispatch(updateFormValidationState(RATINGS_AND_REVIEWS_FORM));
  };
};

export function submitReview({ tpnb, successUrl, formActionUrl, router }: SubmitReview): ThunkAction<Promise<void>> {
  return function(dispatch: Dispatch, getState: GetStore): Promise<void> {
    const state = getState();
    const reviewFormValues = getFormStateValues(state.form[RATINGS_AND_REVIEWS_FORM]);
    const body = { ...reviewFormValues, successUrl, tpnb };
    dispatch({
      type: 'REVIEW_SUBMISSION_START',
    });
    return request
      .post(formActionUrl, { body: JSON.stringify({ ...body }) })
      .then(response => {
        handleApiResponse(response, state, dispatch, router, successUrl);
      })
      .catch(err => {
        const { status } = err;
        // only status to ensure we donot redirect in case of JS and 401
        commonActionRejectionsHandler({ status }, dispatch, () => {
          updateExternalErrorState(dispatch, {
            errorSubmittingReview: true,
          });
        });
      });
  };
}

const updateExternalErrorState = (dispatch: Dispatch, externalErrors: SubmitReviewError): void => {
  if (externalErrors.errorSubmittingReview || externalErrors.secondTimeReview) {
    dispatch(updateExternalValidationState(RATINGS_AND_REVIEWS_FORM, externalErrors));

    if (externalErrors.secondTimeReview) {
      emitErrorDataEvent({
        text: RATINGS_REVIEWS_ANALYTICS[RULE_TYPES.REPEAT_REVIEW_SUBMISSION],
      });
    }
  }
};

const handleApiResponse = (
  response: Response,
  state: Store,
  dispatch: Dispatch,
  router: InjectedRouter,
  successUrl: string,
): void => {
  const { hasValidationError } = response;
  if (!hasValidationError) {
    router.push(successUrl);
  } else {
    const {
      formValidationState: { externalErrors },
    } = response;
    if (externalErrors.userNotAuthenticated) {
      commonActionRejectionsHandler({ status: 401 }, dispatch);
    } else {
      updateExternalErrorState(dispatch, externalErrors);
    }
  }
};

export function dispatchAnalyticsOnError() {
  return function(dispatch: Dispatch, getState: GetStore): void {
    const { form } = getState();
    const formState = form[RATINGS_AND_REVIEWS_FORM] || {};

    (Object.values(formState) as Array<Field>).forEach(({ hasError, type }) => {
      if (hasError && RATINGS_REVIEWS_ANALYTICS[type as string]) {
        emitErrorDataEvent({ text: RATINGS_REVIEWS_ANALYTICS[type as string] });
      }
    });
  };
}

export function reportAReview(tpnb: string, reviewId: string, reportUrl: string): ThunkAction<Promise<void>> {
  return function(dispatch: Dispatch): Promise<void> {
    return request
      .post(reportUrl, { body: JSON.stringify({ reviewId }) })
      .then(response => {
        const { errors } = response;
        const hasError = Array.isArray(errors) && errors.length > 0;
        if (hasError) {
          dispatch({ type: GENERIC_ERROR_MODAL });
        } else {
          dispatch(reportAReviewAction(tpnb, reviewId));
        }
      })
      .catch(err => {
        const { status } = err;
        commonActionRejectionsHandler({ status }, dispatch, () => {
          dispatch({ type: GENERIC_ERROR_MODAL });
        });
      });
  };
}

export function deleteAReview(tpnb: string, reviewId: string, deleteUrl: string): ThunkAction<Promise<void>> {
  return function(dispatch: Dispatch, getState: GetStore): Promise<void> {
    return request
      .post(deleteUrl, { body: JSON.stringify({ reviewId }) })
      .then(response => {
        const { errors } = response;
        const hasError = Array.isArray(errors) && errors.length > 0;

        if (hasError) {
          dispatch({ type: GENERIC_ERROR_MODAL });
        } else {
          const item = getProductItem(getState());

          dispatch(closeModal());
          dispatch(
            fetchResources([PRODUCT_DETAILS], {
              deleteTaxonomyParent: false,
              includeReviews: true,
              id: getProductId(item),
            }),
          );
        }
      })
      .catch(err => {
        const { status } = err;
        commonActionRejectionsHandler({ status }, dispatch, () => {
          dispatch({ type: GENERIC_ERROR_MODAL });
        });
      });
  };
}

export const updateProductIds = (response: Reviews, tpnb: string): Reviews => ({
  ...response,
  product: {
    ...response.product,
    tpnc: tpnb,
    tpnb,
  },
});

export const getReviews = (tpnb: string, page: string, isReviewsTransitioning = false) => (
  dispatch: Dispatch,
  getState: GetStore,
): Promise<void> => {
  const state = getState();
  const oldReviews = state.ratingsReviews[tpnb].reviews;
  const getReviewsUrl = getLanguageLink(state, `/reviews/${tpnb}?page=${page}`);
  return request
    .get(getReviewsUrl)
    .then(response => {
      const reviewsResponse = isReviewsTransitioning ? updateProductIds(response, tpnb) : response;
      const formattedReviewsResponse = formatReviews({ reviews: reviewsResponse });
      if (formattedReviewsResponse) {
        formattedReviewsResponse[tpnb].reviews = oldReviews.concat(formattedReviewsResponse[tpnb].reviews);
        dispatch(updateReviews(formattedReviewsResponse));
      }
    })
    .catch(err => {
      const { status } = err;
      commonActionRejectionsHandler({ status }, dispatch, () => {
        dispatch({ type: GENERIC_ERROR_MODAL });
      });
    });
};
