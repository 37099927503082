import React from 'react';
import Banner from '@ddsweb/banner';
import { PRIMARY, SECONDARY, BLANK_TARGET } from '@ddsweb/constants';
import { spacing } from '@ddsweb/selectors';
import styled from 'styled-components';

const StyledBanner = styled(Banner)`
  margin: ${spacing.xl} auto;
`;

export type BannerProps = {
  image: {
    alt: string;
    large: string;
    small?: string;
  };
  headline?: string;
  links?: {
    ariaLabel?: string;
    buttonVariant?: typeof PRIMARY | typeof SECONDARY;
    href: string;
    icon?: string;
    target?: typeof BLANK_TARGET;
    text: string;
  };
  logo?: {
    alt: string;
    src: string;
  };
  inverse?: boolean;
  bodyText?: string;
  variant?: 'hero' | 'billboard';
  terms?: string;
};

const HeroOrBillboardBanner = (props: BannerProps): JSX.Element => {
  const { bodyText, headline, image, inverse, links, logo, terms, variant } = props;

  return (
    <StyledBanner
      headline={headline}
      links={links}
      logo={logo}
      inverse={inverse}
      bodyText={bodyText}
      image={image}
      terms={terms}
      variant={variant}
    />
  );
};

export { HeroOrBillboardBanner };
