import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@ddsweb/text';
import styled, { withTheme } from 'styled-components';

export const FeedbackText = styled(Text)`
  && {
    font-size: 16px;
    color: ${props => props.theme.colors.success};
  }
`;

function BasketFeedback(props) {
  const { feedbackText, screenReaderText, region } = props;
  return (
    <FeedbackText
      region={region}
      data-auto="basket-feedback"
      aria-label={screenReaderText}
      as="p"
    >
      {feedbackText}
    </FeedbackText>
  );
}

BasketFeedback.propTypes = {
  feedbackText: PropTypes.string.isRequired,
  region: PropTypes.string.isRequired,
  screenReaderText: PropTypes.string.isRequired
};

export default withTheme(BasketFeedback);
