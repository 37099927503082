import { batchActions } from 'redux-batched-actions';
import { request } from '#/lib/client-fetch';
import { getLanguageLink } from '#/reducers/app';
import { getItems } from '#/selectors/trolley';
import { setCustomerUnitChoice } from '#/lib/records/item-utils';
import {
  findAndSetTrolleyItems,
  getById,
  itemsToMap
} from '#/lib/records/product-utils';
import {
  UPDATE_RESULTS_PAGE_NO,
  UPDATE_REMOVE_FAVOURITE,
  CLEAR_REMOVE_FAVOURITE,
  CLEAR_PAGES,
  IS_RESULTS_UPDATING,
  CLEAR_IS_RESULTS_UPDATING,
  UPDATE_TOTAL_COUNT,
  UPDATE_COUNT,
  UPDATE_PAGE_SIZE,
  UPDATE_PAGE_OFFSET,
  UPDATE_RESULTS_PAGE,
  CLEAR_TESCO_RECOMMENDATIONS,
  UPDATE_FAVORITES_CAROUSEL_ITEMS
} from '#/constants/action-types';
import { openModal } from './ui-action-creators';
import { NO_RESULT_MODAL } from '#/constants/modal-names';
import { FAVORITES, FAVORITES_CAROUSEL } from '#/constants/spa-resource';
import { fetchResources } from './resources-action-creators';
import { getTotalCount } from '#/reducers/results';
import {
  getCustomerUnitChoice,
  getProductId,
  getQuantity,
  isProduct
} from '#/selectors/item';
import { cloneItem } from '#/utils/clone-item';
import { cloneItems } from '#/utils/clone-items';
import createItem from '#/lib/records/item';
import {
  QUERY_LAYOUT,
  REMOVE_FROM_FAVORITES,
  FAVORITES_TAB
} from '#/constants/favorites';
import { getSelectedFavoritesLayout } from '#/reducers/ui';
import { getFilterRelevantResourcesFromRoutes } from '#/conditional-resources/get-relevant-resources-from-routes';
import { updateOptimisticDeleteErrorState } from '#/actions/ui-action-creators';

/**
 * Dispatch functions
 *
 * These functions offer shorthand notation for dispatch functions
 */

/**
 * @param {Map<string, Item>} items
 */
export const updatePage = (
  pageNo,
  items,
  resultsType,
  supportsFlexiPageSize = false,
  experiments
) => {
  const pageItems = items.size > 0 ? items : null;

  return {
    type: UPDATE_RESULTS_PAGE,
    value: {
      pageNo,
      pageItems,
      resultsType,
      supportsFlexiPageSize,
      experiments
    }
  };
};

const clearTescoRecommendations = () => ({
  type: CLEAR_TESCO_RECOMMENDATIONS
});

const clearPages = {
  type: CLEAR_PAGES
};

const updateTotalCount = totalCount => ({
  type: UPDATE_TOTAL_COUNT,
  value: totalCount
});

const updateCount = count => ({
  type: UPDATE_COUNT,
  value: count
});
const updatePageSize = pageSize => ({
  type: UPDATE_PAGE_SIZE,
  value: pageSize
});

const updatePageOffset = offset => ({
  type: UPDATE_PAGE_OFFSET,
  value: offset
});

const updatePageNo = pageNo => ({
  type: UPDATE_RESULTS_PAGE_NO,
  value: pageNo
});

export const resultsAreUpdating = {
  type: IS_RESULTS_UPDATING
};

export const clearResultsAreUpdating = {
  type: CLEAR_IS_RESULTS_UPDATING
};

export const updateRemoveFavourite = favToRemove => ({
  type: UPDATE_REMOVE_FAVOURITE,
  value: favToRemove
});

export const clearRemoveFavourite = () => ({
  type: CLEAR_REMOVE_FAVOURITE
});

export const paginate = ({ params, routes }, queryParams = {}) => async (
  dispatch,
  getState
) => {
  dispatch(resultsAreUpdating);

  const filteredResources = getFilterRelevantResourcesFromRoutes(getState(), [
    routes[routes.length - 1]
  ]);

  try {
    await dispatch(
      fetchResources(
        filteredResources,
        {
          ...params,
          query: queryParams
        },
        { forceFetch: true }
      )
    );
  } catch (error) {
    // no catch
  }

  dispatch(clearResultsAreUpdating);
};

export const updateFavoritesCarousel = favoritesData => (
  dispatch,
  getState
) => {
  const items = (favoritesData && favoritesData.productItems) || [];
  const trolleyItems = getItems(getState());

  dispatch({
    type: UPDATE_FAVORITES_CAROUSEL_ITEMS,
    value: {
      items: findAndSetTrolleyItems(items, trolleyItems),
      experiments: favoritesData && favoritesData.experiments
    }
  });
};

export const fetchFavoritesCarouselItems = () => dispatch => {
  return dispatch(fetchResources([FAVORITES_CAROUSEL]));
};

export function updateResultsBatchCreator(
  { productItems, pageInformation, supportsFlexiPageSize, experiments },
  resultsType,
  getState,
  flushRecommendations
) {
  const trolley = getState().trolley;
  const items = productItems || [];
  const { pageNo, offset, totalCount, count, pageSize } = pageInformation;
  const pageItemsArr = findAndSetTrolleyItems(items, trolley.radishItems);
  const pageItems = itemsToMap(pageItemsArr);
  const actions = [
    updatePage(
      pageNo - 1,
      pageItems,
      resultsType,
      supportsFlexiPageSize,
      experiments
    ),
    updatePageNo(pageNo),
    updatePageOffset(offset),
    updateTotalCount(totalCount),
    updateCount(count),
    updatePageSize(pageSize)
  ];

  flushRecommendations && actions.push(clearTescoRecommendations());

  if (pageNo === 1) {
    actions.unshift(clearPages);
  }

  return actions;
}

export function updateResults(results, resultsType, flushRecommendations) {
  return (dispatch, getState) =>
    dispatch(
      batchActions(
        updateResultsBatchCreator(
          results,
          resultsType,
          getState,
          flushRecommendations
        )
      )
    );
}

export const onReceiveTrolleyItem = trolleyItems => {
  return (dispatch, getState) => {
    const { pages, resultsType, supportsFlexiPageSize } = getState().results;

    pages.map((pageItemsMap, pageIndex) => {
      if (pageItemsMap) {
        const updatedPageItems = new Map();
        let needUpdate = false;

        [...pageItemsMap.entries()].map(([id, entry]) => {
          const item = cloneItem(entry);

          let quantity = 0;
          if (isProduct(entry)) {
            const trolleyItem = getById(trolleyItems, id);
            if (trolleyItem) {
              const unitChoice = getCustomerUnitChoice(entry);
              quantity = getQuantity(
                setCustomerUnitChoice(trolleyItem, unitChoice)
              );
            }

            item.quantity = quantity;
          }
          updatedPageItems.set(id, item);

          if (!needUpdate) {
            needUpdate = entry.quantity !== quantity;
          }
        });

        if (!needUpdate) {
          return;
        }

        // Transfer isForSale from trolleyItems to transferredPageItems
        const transferredPageItems = cloneItems(updatedPageItems);
        trolleyItems.forEach(item => {
          const id = getProductId(item);
          const baseItem = transferredPageItems.get(id);
          if (baseItem) {
            transferredPageItems.set(
              id,
              createItem({
                ...baseItem,
                product: {
                  ...baseItem.product,
                  isForSale: item.product.isForSale
                }
              })
            );
          }
        });

        dispatch(
          updatePage(
            pageIndex,
            transferredPageItems,
            resultsType,
            supportsFlexiPageSize
          )
        );
      }
    });
  };
};

/**
 * @param {Map|Array} trolleyItems
 */
export function updateTrolleyItems(trolleyItems) {
  return (dispatch, getState) => {
    const { pages, resultsType, supportsFlexiPageSize } = getState().results;

    pages.map((pageItems, pageIndex) => {
      if (pageItems) {
        const pageItemsArr = [...pageItems.values()];
        const trolleyItemsArr =
          trolleyItems instanceof Map
            ? [...trolleyItems.values()]
            : trolleyItems;
        const newPageItemsArr = findAndSetTrolleyItems(
          pageItemsArr,
          trolleyItemsArr
        );
        const newPageItems = itemsToMap(newPageItemsArr);
        dispatch(
          updatePage(
            pageIndex,
            newPageItems,
            resultsType,
            supportsFlexiPageSize
          )
        );
      }
    });
  };
}

export function deleteFavouriteItem() {
  return (dispatch, getState) => {
    const state = getState();
    const { favToRemove, itemsPerPage, pageNo } = state.results;

    if (favToRemove) {
      dispatch(resultsAreUpdating);
      request
        .del(
          getLanguageLink(
            state,
            `/favorites/items/${getProductId(favToRemove)}`
          )
        )
        .finally(() =>
          refreshFavourites(dispatch, getState, pageNo, itemsPerPage)
        );
      dispatch(clearRemoveFavourite());
    }
  };
}

export function deleteFavouriteItemJSOnly(
  url,
  showLoader = true,
  shouldFetchFavorites = true
) {
  return (dispatch, getState) => {
    if (!url) {
      return;
    }
    const state = getState();
    const { itemsPerPage, pageNo } = state.results;

    if (showLoader) {
      dispatch(resultsAreUpdating);
    }

    request
      .del(`${url}`)
      .catch(() => {
        dispatch(updateOptimisticDeleteErrorState(FAVORITES_TAB));
      })
      .finally(() => {
        if (shouldFetchFavorites) {
          refreshFavourites(dispatch, getState, pageNo, itemsPerPage);
        }
      });
  };
}

export function deleteMultiFavouriteItems(
  productIdsToRemove,
  showLoader = true
) {
  return (dispatch, getState) => {
    const state = getState();
    const { itemsPerPage, pageNo } = state.results;

    if (productIdsToRemove?.length) {
      const body = {
        submit: REMOVE_FROM_FAVORITES,
        items: JSON.stringify(productIdsToRemove)
      };
      if (showLoader) {
        dispatch(resultsAreUpdating);
      }
      request
        .post(getLanguageLink(state, '/favorites/action'), {
          body: JSON.stringify(body)
        })
        .finally(() =>
          refreshFavourites(dispatch, getState, pageNo, itemsPerPage)
        );
    }
  };
}

async function refreshFavourites(dispatch, getState) {
  const state = getState();
  let queryParams =
    ((state.resources[FAVORITES] || {}).params || {}).query || {};
  const layout = getSelectedFavoritesLayout(state);

  if (layout) {
    queryParams[QUERY_LAYOUT] = layout;
  }

  try {
    await dispatch(
      fetchResources([FAVORITES], { query: queryParams }, { forceFetch: true })
    );
  } catch (error) {
    // no catch
  }

  const totalCount = getTotalCount(getState());

  if (totalCount === 0) {
    dispatch(openModal(NO_RESULT_MODAL));
  }

  dispatch(clearResultsAreUpdating);
}

export const removeFromFavorites = productIds => (dispatch, getState) => {
  const {
    pages,
    pageNo,
    resultsType,
    supportsFlexiPageSize,
    experiments
  } = getState().results;
  const currentPageNo = pageNo - 1;
  const pageData = pages[currentPageNo];
  const items = pageData instanceof Map ? [...pageData.values()] : pageData;
  const newItems = items.filter(
    item => !productIds.includes(getProductId(item))
  );
  dispatch(
    updatePage(
      currentPageNo,
      itemsToMap(newItems),
      resultsType,
      supportsFlexiPageSize,
      experiments
    )
  );
};
