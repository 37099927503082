import React from 'react';
import { PRIMARY } from '@ddsweb/constants';
import { connect } from '#/lib/render/connect-deep-compare';
import helpers from '#/lib/decorators/helpers';
import { TTranslateFunc } from '#/lib/records/helpers.defs';
import { IConfig } from '#/components/shared/formatted-time/utils';
import { closeModal } from '#/actions/ui-action-creators';
import { StyledModal, RemindModalButtons, StyledText, RemindModalButton, RemindModalStyledLink } from '../styled';
import { Heading } from '@ddsweb/heading';
import { consts } from '@ddsweb/modal';
import { ON_DEMAND } from '#/constants/shopping-methods';
import { getTrolleyShoppingMethod, getItems } from '#/selectors/trolley';
import { getSlotReservationExpiry, getFulfilmentEstimatedTime } from '#/selectors/slot';
import { getFormattedTime } from '#/components/shared/formatted-time/selector';
import { getCheckoutEntryPoint } from '#/utils/checkout-utils';
import { DynamicDeliveryTime } from '#/custom-typings/redux-store/slot.defs';
import {
  remindModalImpression,
  remindModalOk,
  remindModalCheckout,
  remindModalClose,
} from '#/experiments/oop-2210/analytics';
import { sessionStore } from '#/lib/data-store/client-store';
import { SHOWN_SLOT_REMIND_MODAL_KEY } from '#/experiments/oop-2210/constants';

type SlotRemindModalStateProps = {
  checkoutEntryPoint: string;
  isWhoosh: boolean;
  hasItems: boolean;
  expiryTime: string;
  fulfilmentEstimatedTime: DynamicDeliveryTime;
};

type SlotRemindModalOwnProps = {
  closeModal: () => void;
  t: TTranslateFunc;
};

export type SlotRemindModalProps = SlotRemindModalOwnProps & SlotRemindModalStateProps;

const mapStateToProps = (state: Store, { c: config }: { c: IConfig }): SlotRemindModalStateProps => {
  const trolleyShoppingMethod = getTrolleyShoppingMethod(state);
  const slotReservationExpiry = getSlotReservationExpiry(state.trolley.trolley.slot) as string;
  return {
    checkoutEntryPoint: getCheckoutEntryPoint(state),
    isWhoosh: trolleyShoppingMethod === ON_DEMAND,
    hasItems: !!getItems(state).length,
    expiryTime: getFormattedTime(state, slotReservationExpiry, config),
    fulfilmentEstimatedTime: getFulfilmentEstimatedTime(state),
  };
};

const SlotRemindModal = (props: SlotRemindModalProps): JSX.Element | null => {
  const {
    closeModal,
    t: translate,
    checkoutEntryPoint,
    hasItems,
    expiryTime,
    isWhoosh,
    fulfilmentEstimatedTime,
  } = props;

  const tPrefix = `modals:oop-2210-slot-remind-modal`;
  const modalHeading = translate(`${tPrefix}.title`);
  const { range: { min, max } = {} } = fulfilmentEstimatedTime;
  const hasDynamicEta = min && max;

  const onChange = (props: { action: string }): void => {
    if (props.action === consts.CLOSE) {
      remindModalClose();
      sessionStore?.set(SHOWN_SLOT_REMIND_MODAL_KEY, true);
      closeModal();
    }
  };

  const handleButtonclick = (type: 'checkout' | 'ok') => (): void => {
    if (type === 'checkout') {
      remindModalCheckout();
    } else if (type === 'ok') {
      remindModalOk();
    }
    sessionStore?.set(SHOWN_SLOT_REMIND_MODAL_KEY, true);
    closeModal();
  };

  remindModalImpression();

  return (
    <StyledModal
      id="remind-expiry-slot-modal"
      accessibleLabel={modalHeading}
      onChange={onChange}
      dynamicHeight
      maxHeight={'572px'}
      open
      ssr
      persist
    >
      <Heading headingLevel="1" visualSize="headline3">
        {modalHeading}
      </Heading>
      <StyledText forwardedAs="p">
        {translate(
          `${tPrefix}${isWhoosh && hasDynamicEta ? '.whoosh' : ''}.description-${hasItems ? 'with' : 'no'}-items`,
          {
            time: expiryTime,
            ...(isWhoosh && hasDynamicEta && { min, max }),
          },
        )}
      </StyledText>
      <RemindModalButtons>
        {hasItems ? (
          <RemindModalStyledLink
            buttonVariant={PRIMARY}
            variant="textButton"
            href={checkoutEntryPoint}
            onClick={handleButtonclick('checkout')}
          >
            {translate(`${tPrefix}.checkout-with-items`)}
          </RemindModalStyledLink>
        ) : (
          <RemindModalButton variant={PRIMARY} onClick={handleButtonclick('ok')}>
            {translate(`${tPrefix}.checkout-no-items`)}
          </RemindModalButton>
        )}
      </RemindModalButtons>
    </StyledModal>
  );
};
export default helpers(['t'])(connect(mapStateToProps, { closeModal })(SlotRemindModal));
