import { getFeatureVariableStringFromState, updateRegionInKey } from '#/lib/optimizely-manager';
import { favRememberPaginationConfig, favRememberPaginationVariants } from '#/experiments/oop-1801/constants';

const getVariant = (state: Store): favRememberPaginationVariants => {
  const { useCustomerId, featureKey, variationKey } = favRememberPaginationConfig;
  const regionInKey = updateRegionInKey(featureKey, state);
  const attributes = {};

  const featureVariable = getFeatureVariableStringFromState(state, regionInKey, variationKey, {
    useCustomerId,
    attributes,
  });

  return featureVariable || favRememberPaginationVariants.default;
};

export const getShouldRememberPaginationVariant = (state: Store): favRememberPaginationVariants => getVariant(state);
