import styled from 'styled-components';
import { mediumMin, largeMax } from '#/constants/viewports';

export const StyledBackdrop = styled('div')`
  @media (min-width: ${mediumMin}) and (max-width: ${largeMax}) {
    &::after {
      content: '';
      width: 200vw;
      height: 100%;
      background: white;
      left: -50vw;
      position: absolute;
      top: 0;
      display: block;
      z-index: -1;
    }
  }
`;
