import React from 'react';
import { SECONDARY } from '@beans/constants';
import { StyledButtonWrapper, StyledIcon } from './styled';

interface NavigationButtonProps {
  disabled: boolean;
  href: string;
  icon: string;
  key?: string;
  label: string;
  onClickHandler: (e: React.MouseEvent) => void;
}

const NavigationButton: React.FC<NavigationButtonProps> = ({ disabled, href, icon, label, onClickHandler }) => {
  return (
    <StyledButtonWrapper
      ariaLabel={label}
      type="button"
      variant={SECONDARY}
      onClick={onClickHandler}
      to={href}
      disabled={disabled}
    >
      <StyledIcon graphic={icon} />
    </StyledButtonWrapper>
  );
};

export default NavigationButton;
