import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import formatPrice from '../../../lib/string-formatting/number-formatter';
import Price from '../../shared/price';
import helpers from '../../../lib/decorators/helpers';

@helpers(['c', 't'])
export default class DepositNotice extends Component {
  static displayName = 'DepositNotice';
  static propTypes = {
    c: PropTypes.func.isRequired,
    depositAmount: PropTypes.number.isRequired,
    t: PropTypes.func.isRequired
  };
  constructor() {
    super();
    this.state = { noticeOpen: false };
  }
  closeNotice = e => {
    let notice = ReactDOM.findDOMNode(this.refs.notice);

    if (notice && e.target !== notice && !notice.contains(e.target)) {
      this.setState({ noticeOpen: false });
    }
  };
  componentDidMount() {
    document.body.addEventListener('click', this.closeNotice);
  }
  componentWillUnmount() {
    document.body.removeEventListener('click', this.closeNotice);
  }

  onChange = e => {
    this.setState({ noticeOpen: e.target.checked });
  };

  render() {
    if (!this.props.depositAmount) {
      return null;
    }

    let priceConfig = Object.assign(
      { value: this.props.depositAmount },
      this.props.c('currency')
    );
    let titleText =
      this.props.t('product-tile:deposit-text-1') +
      formatPrice(
        this.props.depositAmount,
        Object.assign({}, priceConfig, { showAsciiSymbol: true })
      ) +
      this.props.t('product-tile:deposit-text-2');

    return (
      <div className="deposit-notice" ref="notice" title={titleText}>
        <label className="icon-deposit">
          <input
            checked={this.state.noticeOpen}
            onChange={this.onChange}
            type="checkbox"
          />
          <p className="deposit-notice--deposit-text">
            <span>{this.props.t('product-tile:deposit-text-1')}</span>
            <Price {...priceConfig} />
            <span>{this.props.t('product-tile:deposit-text-2')}</span>
            <span className="icon-tooltip-tip" />
          </p>
        </label>
      </div>
    );
  }
}
