import promotionsContent from '#/resources/serializers/promotions-content';

const serializers = {
  promotionsContent,
  defaults: {
    serialize: data => data,
    deserialize: data => data
  }
};

const getSerializers = resourceName => ({
  ...serializers.defaults,
  ...(serializers[resourceName] || {})
});

export const serialize = (resourceName, data) =>
  getSerializers(resourceName).serialize(data);
export const deserialize = (resourceName, data) =>
  getSerializers(resourceName).deserialize(data);

export const deserializeAll = resources => {
  const now = Date.now();

  return Object.keys(resources).reduce((deserialized, resourceName) => {
    deserialized[resourceName] = {
      requestedAt: now,
      receivedAt: now,
      ...resources[resourceName],
      data: deserialize(resourceName, resources[resourceName].data)
    };

    return deserialized;
  }, {});
};
