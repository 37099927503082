import React from 'react';
import { compose } from 'react-recompose';
import { ConnectedProps } from 'react-redux';
import Button from '@ddsweb/button';
import { connect } from '#/lib/render/connect-deep-compare';
import helpers from '#/lib/decorators/helpers';
import { getLanguageLink } from '#/reducers/app';
import { getFavoritesLanguageLink } from '#/utils/favorite-utils';
import { getIsFirstTimeShopper } from '#/reducers/user';
import { PRIMARY, SECONDARY } from '@ddsweb/constants';
import { WithRouterProps } from 'react-router';
import withCheckedSPA, { CheckSPAForRedirect } from '#/components/withCheckedSPA';
import { TConfigFunc, TTranslateFunc } from '#/lib/records/helpers.defs';
import { SHOP_FAVORITES, START_SHOPPING } from '#/analytics/constants';
import { emitQuickBasketClickUIEvent } from '#/analytics/helpers/slot';

type State = {
  startShoppingUrl: string;
  favoritesPageLink: string;
  isFirstTimeShopper: boolean;
};

interface Props extends WithRouterProps {
  t: TTranslateFunc;
  c: TConfigFunc;
}

const mapStateToProps = (state: Store): State => {
  return {
    startShoppingUrl: getLanguageLink(state, '/'),
    favoritesPageLink: getFavoritesLanguageLink(state, '/favorites'),
    isFirstTimeShopper: getIsFirstTimeShopper(state),
  };
};

const connector = connect(mapStateToProps);
export type OwnProps = Props & ConnectedProps<typeof connector> & { checkSPAForRedirect: CheckSPAForRedirect };
const QuickBasketSlotContextButtons = ({
  isFirstTimeShopper,
  t: translate,
  c: config,
  favoritesPageLink,
  startShoppingUrl,
  checkSPAForRedirect,
}: OwnProps): JSX.Element => {
  const onStartShoppingClickHandler = (): void => {
    emitQuickBasketClickUIEvent(START_SHOPPING);
    checkSPAForRedirect(startShoppingUrl);
  };

  const onShopFavClickHandler = (): void => {
    emitQuickBasketClickUIEvent(SHOP_FAVORITES);
    checkSPAForRedirect(favoritesPageLink);
  };

  return (
    <>
      <Button variant={isFirstTimeShopper ? PRIMARY : SECONDARY} onClick={onStartShoppingClickHandler}>
        {translate('modals:quick-basket.start-shopping-btn')}
      </Button>
      {!isFirstTimeShopper && config('slotPageFavCTA') && (
        <Button variant={PRIMARY} onClick={onShopFavClickHandler}>
          {translate('trolley:trolley-favourites.button-text')}
        </Button>
      )}
    </>
  );
};

const enhance = compose<OwnProps, null>(connector, helpers(['t', 'c', 'f']), withCheckedSPA);

export default enhance(QuickBasketSlotContextButtons);
