import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import CarouselButton from '#/components/carousel-button';
import withProfiler from '#/utils/perf-profiler';
import {
  DEFAULT_SCROLL_AMOUNT,
  FORWARD,
  BACKWARD,
  MULTI_ITEMS,
  SINGLE_ITEM
} from '#/constants/carousel.js';

@withProfiler('Carousel')
export default class Carousel extends Component {
  static propTypes = {
    backgroundColor: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.node),
    goToNext: PropTypes.func,
    goToNextTitle: PropTypes.string,
    goToPrev: PropTypes.func,
    goToPrevTitle: PropTypes.string,
    icon: PropTypes.string,
    notifyAnalytics: PropTypes.func,
    onLastItemCb: PropTypes.func,
    pressScrollButton: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    type: PropTypes.string
  };

  static defaultProps = {
    type: MULTI_ITEMS
  };

  constructor(props, context) {
    super(props, context);
    const { pressScrollButton } = props;
    this.contentWrapper = createRef();

    this.handleScroll = this.handleScroll.bind(this);
    this.pressScrollButton = this.getPressScrollHandler(pressScrollButton).bind(
      this
    );
    this.releaseScrollButton = this.releaseScrollButton.bind(this);
    this.timeout = null;
    this.state = {
      backDisabled: true,
      forwardDisabled: false,
      scrollLeft: 0,
      scrollAmount: DEFAULT_SCROLL_AMOUNT
    };
  }

  componentDidMount() {
    this.contentWrapper.current.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    this.contentWrapper.current.removeEventListener(
      'scroll',
      this.handleScroll
    );
  }

  getPressScrollHandler = pressScrollButton =>
    typeof pressScrollButton === 'function'
      ? pressScrollButton
      : this.pressScrollButton;

  constrainScroll = newScrollPos => {
    const maxScroll =
      this.contentWrapper?.current?.scrollWidth -
      this.contentWrapper?.current?.clientWidth;
    let scrollPos = newScrollPos;

    if (scrollPos < 0) {
      scrollPos = 0;
      this.releaseScrollButton();
    } else if (!isNaN(maxScroll) && scrollPos >= maxScroll) {
      scrollPos = maxScroll;
      this.releaseScrollButton();
    }

    this.scroll(scrollPos);
  };

  handleScroll() {
    const { onLastItemCb } = this.props;
    const scrollLeft = this.contentWrapper?.current?.scrollLeft;
    const scrollWidth = this.contentWrapper?.current?.scrollWidth;
    const contentWrapperWidth = this.contentWrapper?.current?.clientWidth;
    const isLastItem = scrollWidth - contentWrapperWidth === scrollLeft;

    if (!scrollWidth) {
      return;
    }

    // TODO: Remove ths callback and logic once we have the actual API immplementation
    if (isLastItem && onLastItemCb) {
      onLastItemCb();
    }

    this.setState({
      backDisabled: scrollLeft === 0,
      forwardDisabled: isLastItem,
      scrollLeft
    });
  }

  releaseScrollButton() {
    clearTimeout(this.timeout);
  }

  scroll = scrollLeft => {
    requestAnimationFrame(() => {
      if (this.contentWrapper && this.contentWrapper.current) {
        this.contentWrapper.current.scrollLeft = scrollLeft;
      }
    });
  };

  pressScrollButton = (incrementValue, direction = '') => {
    let scrollValue;
    const { type } = this.props;

    if (type === SINGLE_ITEM) {
      const directionForward = direction === FORWARD;

      scrollValue = directionForward
        ? this.contentWrapper?.current?.clientWidth
        : -1 * this.contentWrapper?.current?.clientWidth;
    } else {
      scrollValue = incrementValue;

      this.timeout = setTimeout(() => {
        this.pressScrollButton(scrollValue);
      }, 12);
    }
    this.constrainScroll(this.state.scrollLeft + scrollValue);
    this.handleScrollChange(scrollValue);

    this.notifyAnalytics(direction);
  };

  notifyAnalytics(direction) {
    const { notifyAnalytics } = this.props;

    if (notifyAnalytics) {
      notifyAnalytics(direction);
    }
  }

  handleScrollChange = scrollTo => {
    const { goToNext, goToPrev } = this.props;

    if (scrollTo > 0) {
      goToNext && goToNext();

      return;
    }

    goToPrev && goToPrev();
  };

  render() {
    const {
      backgroundColor,
      children,
      icon,
      goToPrevTitle,
      goToNextTitle
    } = this.props;
    const { scrollAmount, backDisabled, forwardDisabled } = this.state;

    if (!children) {
      return null;
    }

    const carouselListItems = children.map((item, i) => (
      <li className="carousel__list-item" key={i}>
        {item}
      </li>
    ));

    return (
      <div className="carousel__wrapper">
        <div className="carousel">
          <CarouselButton
            direction={BACKWARD}
            backgroundColor={backgroundColor}
            icon={icon}
            scrollAmount={scrollAmount}
            disabled={backDisabled}
            pressHandler={this.pressScrollButton}
            releaseHandler={this.releaseScrollButton}
            title={goToPrevTitle}
          />
          <ul className="carousel__list" ref={this.contentWrapper}>
            {carouselListItems}
          </ul>
          <CarouselButton
            direction={FORWARD}
            backgroundColor={backgroundColor}
            icon={icon}
            scrollAmount={scrollAmount}
            disabled={forwardDisabled}
            pressHandler={this.pressScrollButton}
            releaseHandler={this.releaseScrollButton}
            title={goToNextTitle}
          />
        </div>
      </div>
    );
  }
}
