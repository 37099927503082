import React, { Fragment, useEffect, useMemo, useState } from 'react';
import Icon from '@ddsweb/icon';
import helpers from '#/lib/decorators/helpers';
// import { Spinner } from '@ddsweb/spinner';
import { TConfigFunc, TTranslateFunc } from '#/lib/records/helpers.defs';
import { BackButton, CloseButton, Header, MenuBody, SignOutButton, StyledModal } from './styled';
import { AppBarConfig, getAppbarMenuItems } from '../app-bar/utils';
import { connect } from '#/lib/render/connect-deep-compare';
import {
  getIsFirstTimeShopper,
  getIsUserAuthenticated,
  getUserDisplayName,
  getUserHashedUId,
  getUserStoreId,
  getUserUuid,
} from '#/reducers/user';
import {
  getCsrfToken,
  getCutsMustard,
  getDeviceType,
  getLanguage,
  getLanguageLink,
  getMyAccountUrl,
  getTraceId,
} from '#/reducers/app';
import { getShowFavNavLinkTooltip } from '#/experiments/oop-1263/selectors';
import { getIsSlotNotifyMeVariant } from '#/experiments/oop-2034/selectors';
import { getExperienceCookieAccepted } from '#/reducers/ui';
import MobileMenuItem from './mobile-menu-item';
import { CardStack } from './card-stack';
import { AuthContainer } from './auth-container';
import ContextCard from '#/components/context-cards/slot';
import { getNavList } from '#/reducers/taxonomy';
import { SHOP } from '#/constants/common';
import { PrimaryNavigationList } from '#/components/primary-navigation/types';
import { CmsNav, createPrimaryNavigation } from '#/components/primary-navigation/helpers/taxonomy';
import { fireAnalyticsForAllDepartmentsMenuItem } from '#/components/primary-navigation/helpers/analytics';
import classNames from 'classnames';

type StoreProps = {
  ordersUrl: string;
  isUserAuthenticated: boolean;
  language: string;
  cutsMustard: boolean;
  deviceType: string;
  isFirstTimeShopper: boolean;
  storeId: string;
  hashedUId: string;
  traceId: string;
  accountUrl: string;
  logoutUrl: string;
  shouldSetInterestedInSlotNotifyMeStorage: boolean;
  shouldSetFavNavTooltipStorage: boolean;
  uuid: string;
  csrfToken: string;
  isExperienceCookieAccepted: boolean;
  navList: PrimaryNavigationList;
  shopUrl: string;
  userDisplayName: string;
};
export type Props = StoreProps & {
  t: TTranslateFunc;
  c: TConfigFunc;
  l: (value: string) => string;
  onClose: () => void;
  onCloseFocusRef: HTMLButtonElement;
  isOpen: boolean;
  cmsNav: CmsNav;
};

export type MenuType = {
  id: string;
  catId?: string;
  children?: MenuType[] | null;
  onClick?: (item: MenuType, e: React.MouseEvent) => void;
  external?: boolean;
  href: string;
  hasPopup: boolean;
  icon?: string;
  label: string;
  text: string;
};

const MobileMenuOverlay = (props: Props): JSX.Element => {
  const {
    isOpen,
    onClose,
    onCloseFocusRef,
    c: config,
    t: translate,
    l: languageLink,
    ordersUrl,
    isUserAuthenticated,
    language,
    cutsMustard,
    deviceType,
    isFirstTimeShopper,
    storeId,
    hashedUId,
    traceId,
    accountUrl,
    logoutUrl,
    shouldSetInterestedInSlotNotifyMeStorage,
    shouldSetFavNavTooltipStorage,
    uuid,
    csrfToken,
    isExperienceCookieAccepted,
    navList,
    shopUrl,
    cmsNav,
    userDisplayName,
  } = props;
  const [breadcrumbs, setBreadcrumbs] = useState<string[]>([]);
  const appBar = config('appBar') as AppBarConfig[];
  const menuItems = useMemo(
    () => createPrimaryNavigation({ navList, translate, config, shopUrl, languageLink, cmsNav }),
    [navList, translate, config, shopUrl, languageLink, cmsNav],
  );

  const usernameText = translate('left-hand-navigation:header-signed-in.name', {
    name: userDisplayName,
  });
  const appBarMenuItems = useMemo(() => {
    return getAppbarMenuItems({
      appBar,
      isUserAuthenticated,
      isExperienceCookieAccepted,
      ordersUrl,
      translate,
      config,
      accountUrl,
      logoutUrl,
      csrfToken,
      shouldSetFavNavTooltipStorage,
      shouldSetInterestedInSlotNotifyMeStorage,
      uuid,
      cutsMustard,
      deviceType,
      isFirstTimeShopper,
      storeId,
      hashedUId,
      language,
      traceId,
    });
  }, [
    translate,
    config,
    appBar,
    isUserAuthenticated,
    isExperienceCookieAccepted,
    ordersUrl,
    accountUrl,
    logoutUrl,
    csrfToken,
    shouldSetFavNavTooltipStorage,
    shouldSetInterestedInSlotNotifyMeStorage,
    uuid,
    cutsMustard,
    deviceType,
    isFirstTimeShopper,
    storeId,
    hashedUId,
    language,
    traceId,
  ]);

  const subMenus = useMemo(() => {
    let currentMenu = menuItems;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const out: Array<{ id: string; children: any[] }> = [];

    breadcrumbs.forEach(id => {
      const children = currentMenu?.find(item => item.id === id)?.children;
      if (children != null) {
        out.push({ id, children });
        currentMenu = children;
      }
    });

    return out;
  }, [menuItems, breadcrumbs]);

  useEffect(() => {
    const classes = classNames({
      'modal-shown': !isOpen,
    });

    document.body.className = classes;
  }, [isOpen]);

  const onSubMenuBackClick = (): void => {
    setBreadcrumbs(breadcrumbs.slice(0, -1));
  };

  const onMenuItemClick = (e: React.MouseEvent, menuItem: MenuType): void => {
    if (typeof menuItem.onClick === 'function') {
      menuItem.onClick(menuItem, e);
    }

    const text = menuItem.text?.toLowerCase();

    fireAnalyticsForAllDepartmentsMenuItem({
      ...menuItem,
      name: text,
      innerText: text,
    });

    if (!menuItem.children?.length && !menuItem.hasPopup) {
      return;
    }

    e.preventDefault();
    setBreadcrumbs([...breadcrumbs, menuItem.id]);
  };

  const renderMenuItem = (menuItem: AppBarConfig, index: number, isAppbar: boolean): JSX.Element => {
    if (menuItem.id === 'utility-header-logout-link') {
      return (
        <form action={menuItem.action} method="post">
          <input name="_csrf" type="hidden" value={menuItem.inputs?.[0]?.value} />

          <SignOutButton variant="link" type="submit">
            {translate('common:sign-out')}
          </SignOutButton>
        </form>
      );
    }
    return (
      <MobileMenuItem
        key={`${menuItem.id}:${index}`}
        index={index}
        menuItem={menuItem}
        isAppbar={isAppbar}
        onClick={(e): void => onMenuItemClick(e, menuItem as MenuType)}
      />
    );
  };

  const onDismiss = (): void => {
    onClose();
    setBreadcrumbs([]);
  };

  const mainMenu = (
    <>
      {!isUserAuthenticated && <AuthContainer />}
      {isUserAuthenticated && (
        <div>
          <div className="helloUser">
            {'Hello '} {usernameText}
          </div>
          <ContextCard isLHN id="context-card-nav" />
        </div>
      )}
      <MenuBody>
        {menuItems.map((item, index) => renderMenuItem(item as AppBarConfig, index, false))}
        {appBarMenuItems.map((item, index) => renderMenuItem(item, index, true))}
      </MenuBody>
    </>
  );

  return (
    <StyledModal
      id="mobile-menu-modal"
      closeLink={false}
      open={isOpen}
      variant="slidedown"
      forceClose={true}
      lockPage={true}
      onCloseFocusRef={onCloseFocusRef}
      onChange={onDismiss}
    >
      <Header
        id="mobile-menu"
        onKeyDown={(e: KeyboardEvent): void => {
          if (e.key === 'Escape') {
            onDismiss();
          }
        }}
      >
        {/* Conditionally render the back button based on if we are in a sub menu? */}
        {subMenus.length > 0 && (
          <BackButton icon={<Icon graphic="backwardLink" />} onClick={onSubMenuBackClick} size="xs" variant="link">
            {translate('common:back')}
          </BackButton>
        )}
        <CloseButton
          aria-controls="mobile-menu"
          externalText
          icon={<Icon graphic="close" size="xs" />}
          iconPosition="right"
          onClick={onDismiss}
          size="xs"
          variant="secondary"
        >
          {translate('common:close')}
        </CloseButton>
      </Header>
      <CardStack>
        {mainMenu}
        {subMenus.map(({ id, children }) => (
          <Fragment key={id}>{children.map((item, index) => renderMenuItem(item, index, false))}</Fragment>
        ))}
      </CardStack>
    </StyledModal>
  );
};

const mapStateToProps = (state: Store, ownProps: Props): StoreProps => {
  return {
    ordersUrl: getLanguageLink(state, '/orders'),
    isUserAuthenticated: getIsUserAuthenticated(state),
    language: getLanguage(state),
    cutsMustard: getCutsMustard(state),
    deviceType: getDeviceType(state),
    isFirstTimeShopper: getIsFirstTimeShopper(state),
    storeId: getUserStoreId(state),
    hashedUId: getUserHashedUId(state),
    traceId: getTraceId(state),
    accountUrl: getMyAccountUrl(state, ownProps.c('externalMyAccountUrl')),
    logoutUrl: getLanguageLink(state, '/logout'),
    shouldSetInterestedInSlotNotifyMeStorage: getIsSlotNotifyMeVariant(state),
    shouldSetFavNavTooltipStorage: getShowFavNavLinkTooltip(state),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    csrfToken: getCsrfToken(state as any),
    uuid: getUserUuid(state),
    isExperienceCookieAccepted: getExperienceCookieAccepted(state),
    // @ts-expect-error
    navList: getNavList(state),
    shopUrl: getLanguageLink(state, SHOP),
    userDisplayName: getUserDisplayName(state),
  };
};

export default helpers(['t', 'c', 'l'])(connect(mapStateToProps)(MobileMenuOverlay));
