import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import helpers from '../../../lib/decorators/helpers';
import { pickByKey } from '../../../utils/misc';
import { DeleteButton } from '#/components/product-tiles/common/delete-button';

const validButtonProps = ['disabled', 'name', 'onClick', 'type'];

const RemoveItemButton = props => {
  const buttonClasses = classNames(props.className);
  const validProps = pickByKey(props, validButtonProps);

  let accessibleText = props.t(props.textKey, props.textProps);

  if (props.extraText) {
    accessibleText += ` ${props.extraText}`;
  }

  return (
    <DeleteButton
      className={buttonClasses}
      accessibleText={accessibleText}
      title={accessibleText}
      handleDeleteClick={props.onClick}
      validProps={validProps}
    />
  );
};

RemoveItemButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  extraText: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
  t: PropTypes.func.isRequired,
  textKey: PropTypes.string,
  textProps: PropTypes.object,
  type: PropTypes.string
};

RemoveItemButton.defaultProps = {
  disabled: false,
  className: '',
  extraText: null,
  name: null,
  onClick: () => {},
  textKey: 'product-list:remove',
  textProps: {},
  type: 'button'
};

export default helpers(['t'])(RemoveItemButton);
