import React from 'react';
import PropTypes from 'prop-types';

const AddressInfo = ({ addressLine1, name }) => (
  <div className="destination">
    <div className="title">{name}</div>
    <div className="address">{addressLine1}</div>
  </div>
);

AddressInfo.propTypes = {
  addressLine1: PropTypes.string,
  name: PropTypes.string.isRequired
};

export default AddressInfo;
