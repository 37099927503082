import React from 'react';
import PropTypes from 'prop-types';
import { connect } from '#/lib/render/connect-deep-compare';
import analyticsBus from '#/analytics/analyticsBus';
import { basicEvent } from '#/analytics/types/basic';
import {
  PAGE_IDENTIFIER,
  VIEW_AND_BUY,
  PRODUCT_TILE,
  DELAY,
  OFFERS_HOPOS,
  OFFERS_PDP
} from '#/analytics/constants';
import {
  VIEW_AND_BUY_TYPE_FAVORITES,
  VIEW_AND_BUY_TYPE_OFFERS
} from '#/constants/display-types';
import { getLanguageLink } from '#/reducers/app';
import { getProductId } from '#/selectors/item';
import helpers from '#/lib/decorators/helpers';
import { BEANS, FLEXI } from '#/constants/tile-types';
import { ProductTileConfiguration } from '#/components/product-tiles/common/tile-configurations';
import { VERTICAL } from '#/components/product-tiles/common/constants';

const ViewAndBuyOffers = props => {
  const {
    addICIDToUrl,
    viewAndBuyType,
    groceryHomeLink,
    c: config,
    f: feature,
    t: translate
  } = props;

  const promotedOffers = [];
  const onClickAnalytics = () => {
    basicEvent(analyticsBus, {
      type: `${VIEW_AND_BUY} ${viewAndBuyType}`,
      value: PRODUCT_TILE,
      action: DELAY
    });
  };

  props.content.forEach((contentItem, contentItemIndex) => {
    let viewAndBuyUrl;

    switch (contentItem.type) {
      case 'promotion':
        viewAndBuyUrl = `${groceryHomeLink}promotions/${contentItem.linkTo}${
          addICIDToUrl ? '?icid=' + OFFERS_HOPOS : ''
        }`;
        break;
      case 'buylists':
        viewAndBuyUrl = contentItem.linkTo;
        break;
    }

    (contentItem.products || []).forEach((item, itemIndex) => {
      if (contentItem.type === 'tpnb') {
        viewAndBuyUrl = `${groceryHomeLink}products/${getProductId(item)}${
          addICIDToUrl ? '?icid=' + OFFERS_PDP : ''
        }`;
      }
      const legacyProps = {
        identifier: PAGE_IDENTIFIER,
        item,
        type: FLEXI,
        viewAndBuyButtonUrl: viewAndBuyUrl,
        offersIcid: addICIDToUrl ? OFFERS_HOPOS : null,
        viewAndBuyType: props.viewAndBuyType,
        isAmendBasket: false,
        listData: {},
        onDetailsClick: onClickAnalytics,
        resultType: props.viewAndBuyType
      };
      const productTileProps = {
        item,
        translate,
        config,
        viewAndBuyButtonUrl: viewAndBuyUrl,
        identifier: PAGE_IDENTIFIER,
        feature,
        type: BEANS,
        isAmendBasket: false,
        itemIndex,
        listData: {},
        isRemoved: item.isRemoved,
        resultType: props.viewAndBuyType
      };

      promotedOffers.push(
        <li
          className="product-list--list-item"
          key={`promoted-offer-${contentItemIndex}-${itemIndex}`}
        >
          <ProductTileConfiguration
            productTileVariant={VERTICAL}
            legacyProps={legacyProps}
            productTileProps={productTileProps}
          />
        </li>
      );
    });
  });

  return (
    <>
      <ul className="product-list grid">{promotedOffers}</ul>
    </>
  );
};

const mapStateToProps = state => ({
  groceryHomeLink: getLanguageLink(state)
});

ViewAndBuyOffers.propTypes = {
  addIcidToUrl: PropTypes.bool,
  c: PropTypes.func.isRequired,
  content: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['tpnb', 'promotion', 'buylists']),
      linkTo: PropTypes.string,
      products: PropTypes.array.isRequired
    })
  ),
  f: PropTypes.func.isRequired,
  groceryHomeLink: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  viewAndBuyType: PropTypes.oneOf([
    VIEW_AND_BUY_TYPE_FAVORITES,
    VIEW_AND_BUY_TYPE_OFFERS
  ])
};

export default connect(mapStateToProps)(
  helpers(['c', 't', 'f'])(ViewAndBuyOffers)
);
