import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../shared/modal';
import ModalContent from '../../shared/modal/content';
import ModalActions from '../../shared/modal/actions';
import ModalButtonContainer from '../../shared/modal/actions/button-container';
import helpers from '../../../lib/decorators/helpers';
import analyticsBus from '../../../analytics/analyticsBus';
import SafelyOutputString from '#/components/safely-output-string';

const FORSEE_YES = 'foresee: yes';
const FORSEE_NO = 'foresee: no';
const FORSEE_CLOSE = 'foresee: close';

@helpers(['t'])
export default class ForeseeBackgroundTab extends Component {
  static propTypes = {
    acceptHandler: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    declineHandler: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    const { t } = this.props;

    this.text = {
      title: t('modals:foresee.backgroundTab.title'),
      thanksTitle: t('modals:foresee.backgroundTab.thanksTitle'),
      copy: t('modals:foresee.backgroundTab.copy'),
      thanksCopy: t('modals:foresee.backgroundTab.thanksCopy'),
      close: t('modals:foresee.backgroundTab.close'),
      yesCta: t('modals:foresee.backgroundTab.yesCta'),
      opensInNewWindow: t('common:new-window'),
      noCta: t('modals:foresee.backgroundTab.noCta'),
      continueCta: t('modals:foresee.backgroundTab.continueCta')
    };

    this.state = {
      step: 0
    };
  }

  handleYesClick = () => {
    this.props.acceptHandler();
    this.setState({
      step: 1
    });
    this.dispatchForeseeFeedbackAnalytics(FORSEE_YES);
  };

  handleNoClick = () => {
    this.props.declineHandler();
    this.props.closeModal();
    this.dispatchForeseeFeedbackAnalytics(FORSEE_NO);
  };

  handleClose = () => {
    if (this.state.step === 0) {
      this.props.declineHandler();
      this.dispatchForeseeFeedbackAnalytics(FORSEE_CLOSE);
    }

    this.props.closeModal();
  };

  dispatchForeseeFeedbackAnalytics = value => {
    analyticsBus().emit('UIEventBasicEvent', {
      type: 'feedback',
      value,
      action: 'now'
    });
  };

  renderInitial() {
    return (
      <ModalContent>
        <div>
          <SafelyOutputString>{this.text.copy}</SafelyOutputString>
        </div>
        <ModalActions dual={true}>
          <ModalButtonContainer>
            <button
              className="button button-primary small"
              type="button"
              onClick={this.handleYesClick}
              title={this.text.opensInNewWindow}
            >
              {this.text.yesCta}
            </button>
          </ModalButtonContainer>
          <ModalButtonContainer>
            <button
              className="button button-secondary small"
              type="button"
              onClick={this.handleNoClick}
            >
              {this.text.noCta}
            </button>
          </ModalButtonContainer>
        </ModalActions>
      </ModalContent>
    );
  }

  renderThanks() {
    return (
      <ModalContent>
        <div>
          <SafelyOutputString>{this.text.thanksCopy}</SafelyOutputString>
        </div>
        <ModalActions>
          <ModalButtonContainer>
            <button
              className="button button-primary small"
              type="button"
              onClick={this.handleClose}
            >
              {this.text.continueCta}
            </button>
          </ModalButtonContainer>
        </ModalActions>
      </ModalContent>
    );
  }

  getTitleText() {
    return this.state.step === 0 ? this.text.title : this.text.thanksTitle;
  }

  renderContent() {
    return this.state.step === 0 ? this.renderInitial() : this.renderThanks();
  }

  render() {
    return (
      <Modal
        title={this.getTitleText()}
        className="foresee-modal"
        closeModal={this.handleClose}
      >
        <button
          type="button"
          className="icon-cancel modal-buttons__close"
          onClick={this.handleClose}
        >
          <span className="visually-hidden">{this.text.close}</span>
        </button>
        {this.renderContent()}
      </Modal>
    );
  }
}
