export * from '#/selectors/trolley/coupons';
export * from '#/selectors/trolley/slots';

import { createSelector } from 'reselect';
import { VOLUME_OR_WEIGHT_EXCEEDED } from '#/constants/error-codes';
import { COLLECTION, ShoppingMethod } from '#/constants/shopping-methods';
import {
  QUALIFY_FOR_FREE_DELIVERY,
  NOT_QUALIFY_FOR_FREE_DELIVERY,
  SPEND_MORE_FOR_FREE_DELIVERY,
  FreeDeliveryStatus,
} from '#/constants/spend-threshold';
import { Items as ItemsMap, TrolleyAnalytics } from '#/custom-typings/redux-store/trolley.defs';
import { isDeliveryShoppingMethod, isOnDemandShoppingMethod } from '#/lib/shopping-method-util';
import { Item } from '#/lib/records/item';
import {
  getById,
  getTotalQuantityOfProducts,
  idInItems,
  getProductTotalWithUnavailableItems,
} from '#/lib/records/product-utils';
import { getCurrentQueryParams, getAppRegion } from '#/reducers/app';
import { getAvailableShoppingMethods } from '#/selectors/available-shopping-methods';
import {
  getProductBaseProductId,
  getProductId,
  getProductIsForSale,
  getQuantity,
  getUnit,
  getPieces,
  getIsNewlyAdded,
} from '#/selectors/item';
import {
  Charges,
  ClubcardPoints,
  DeliveryPreferences,
  PropositionalInfo,
  Subscription,
  Trolley,
} from '#/lib/records/trolley.defs';
import { AddressData } from '#/reducers/addresses';
import { FreeDelivery } from '#/lib/records/slot.defs';
import { SplitViewBasket, BasketTypes, SplitViewBasketState } from '#/lib/records/split-basket';
import { UK } from '#/constants/regions';
import { DiscountTypes } from '#/constants/trolley';
import { MARKETPLACE_PRODUCT, GHS_PRODUCT, IGHS_PRODUCT } from '#/constants/common';
import { TConfigFunc } from '#/lib/records/helpers.defs';

export const getTrolleyDeliveryAddress = ({ trolley: { deliveryAddress } }: Store): AddressData => deliveryAddress;

export const getDeliveryLocation = ({ trolley: { deliveryAddress, trolley } }: Store): AddressData =>
  trolley.deliveryAddress ? trolley.deliveryAddress : deliveryAddress;

export const getTrolleyDeliveryAddressId = createSelector(
  getDeliveryLocation,
  trolleyDeliveryAddress => trolleyDeliveryAddress.id,
);

// this selector reads from the main trolley state as the trolley shoppingMethod is
// always hoisted up one level in both getDefaultStateFromProps and receiveTrolley
export const getTrolleyShoppingMethod = ({ trolley: { shoppingMethod } }: Store): ShoppingMethod => shoppingMethod;

export const getIsDelivery = (state: Store): boolean => isDeliveryShoppingMethod(getTrolleyShoppingMethod(state));

export const getIsOnDemandDelivery = (state: Store): boolean =>
  isOnDemandShoppingMethod(getTrolleyShoppingMethod(state));

export const getHasBasketBreached = ({ trolley: { issues } }: Store): boolean => {
  const { exceptions = [] } = issues || {};

  return Array.isArray(exceptions) && !!exceptions.length;
};

export const getBasketMaxItemCountLimit = ({ trolley: { constraints } }: Store): number => {
  return (constraints && constraints.maxItemCount) || 0;
};

export const getHasBasketBreachedByVolumeOrWeight = ({ trolley: { issues } }: Store): boolean => {
  const { exceptions = [] } = issues || {};
  return (
    Array.isArray(exceptions) &&
    !!exceptions.length &&
    exceptions.some(({ reason }) => reason === VOLUME_OR_WEIGHT_EXCEEDED)
  );
};

export const isOnlyCollectionAvailable = createSelector(getAvailableShoppingMethods, methods =>
  isOnlyDeliveryMethod(methods, COLLECTION),
);

export const isOnlyDeliveryMethod = (methods: Array<string>, toCheck: string): boolean => {
  if (!methods) {
    return false;
  }
  return methods.length === 1 && methods.includes(toCheck);
};

export const getCanExtendSlot = (trolley: Store['trolley']['trolley']): boolean => trolley.canExtendSlot;

// this selector reads from the main trolley state as the trolley storeId is
// always hoisted up one level in both getDefaultStateFromProps and receiveTrolley
export const getStoreId = ({ trolley: { storeId } }: Store): string => storeId;

// distinguish between
// - calling with full state
// - calling with the trolley state slice from the reducer
// - calling with the trolley property of the trolley state slice
const getTrolleySliceOrProp = (
  state: Store | Store['trolley'] | Store['trolley']['trolley'],
): Store['trolley'] | Trolley => {
  // both the main state and the trolley state slice have a trolley property.
  if ((state as Store['trolley']).trolley) {
    if ((state as Store).trolley.trolley) {
      // state is the main state, so return state.trolley
      return (state as Store).trolley;
    }
    // otherwise state is already the trolley state slice
    return state as Store['trolley'];
  }
  // otherwise (hopefully) the trolley prop of the trolley state slice
  return state as Store['trolley']['trolley'];
};

// this gets the trolley prop of the trolley slice
export const getTrolley = ({ trolley: { trolley } }: Store): Trolley => trolley;

// current items - this was previously overloaded for both trolley state and trolley prop (due to use of export-selectors)
// this should be only returning the items of the trolley slice (not the items of the trolley prop of the trolley slice)
// because only the slice items have a `last` function.
export const getItems = (state: Store | Store['trolley']): Array<Item> => {
  const { items } = getTrolleySliceOrProp(state) as Store['trolley'];

  /**
   * TODO: Is this check really required, can we remove it or can it be handled better...!??
   * Converting this function to TS requires lot of null check in various places wherever it is used.
   * Dodging the extra work for now with non-null assertions, as app is working fine till now.
   */
  // this should not happen, but you never know
  if (!items) {
    console.warn('trolley reducer getItems has no items in state:', state); // eslint-disable-line no-console
    return [];
  }

  const itemsHistory = [...items.values()];
  if (!itemsHistory.length) {
    return [];
  }

  const recentItems = itemsHistory[itemsHistory.length - 1];

  return [...recentItems.values()];
};

export const getIsTrolleyEmpty = createSelector(getItems, trolleyItems => trolleyItems?.length <= 0);

// this is getting an item from the items of the trolley slice (not the items of the trolley prop of the trolley slice)
export const getTrolleyItem = (state: Store, productId: string): Item | undefined => {
  const items = getItems(state);
  return getById(items, productId);
};

export function getItemAddedAnonymously(state: Store): Item | undefined {
  const { productAdded } = getCurrentQueryParams(state);

  if (!productAdded) {
    return;
  }

  return getTrolleyItem(state, productAdded as string);
}

// this is getting an item from the items of the trolley slice (not the items of the trolley prop of the trolley slice)
export const getTrolleyItemByBaseProductId = (state: Store, productId: string): Item | null => {
  if (!productId) {
    return null;
  }

  const items = getItems(state);

  return items.find(item => getProductBaseProductId(item) === productId) || null;
};

export const hasAtLeastOneItemUnavailable = (state: Store): boolean => {
  const items = getItems(state);

  let hasUnavailable = false;
  items.forEach(item => {
    hasUnavailable = hasUnavailable || !getProductIsForSale(item);
  });

  return hasUnavailable;
};

export const getHasAtLeastOneNewItem = (state: Store): boolean => {
  const items = getItems(state);

  return items.some(item => getIsNewlyAdded(item));
};

// last items
// overloaded for both trolley state and all state
export const getPreviousItems = (state: Store | Store['trolley']): ItemsMap | Array<Item> => {
  const { items, radishItems } = getTrolleySliceOrProp(state) as Store['trolley'];
  const asArray = [...items.values()];
  return asArray[asArray.length - 2] || radishItems;
};

// current server items
// overloaded for both trolley state and all state
export const getLatestItems = (state: Store | Store['trolley']): Array<Item> => {
  const { radishItems } = getTrolleySliceOrProp(state) as Store['trolley'];
  return radishItems;
};

// radish items
export const getRadishItems = ({ trolley: { radishItems } }: Store): Array<Item> => radishItems;

// overloaded for both trolley state and all state
// pass responsibility on to next reducers
export const getRemovedItems = (state: Store): Array<Item> => {
  const items = new Map();

  getItems(state).forEach(item => {
    if (getQuantity(item)) items.set(getProductId(item), item);
  });

  const removedItems: Array<Item> = [];

  getPreviousItems(state).forEach((item: Item) => {
    const id = getProductId(item);
    if (!items.get(id)) {
      removedItems.push(item);
    }
  });

  return removedItems;
};

export const isItemRemoved = (state: Store, productId: string): boolean => {
  const items = getRemovedItems(state);

  return idInItems(productId, items);
};

export const getTotalItems = ({ trolley: { totalItems } }: Store): number => totalItems;

export const getIsAmendBasket = ({
  trolley: {
    trolley: { isAmendBasket },
  },
}: Store): boolean => isAmendBasket || false;

export const getIsAmended = ({
  trolley: {
    trolley: { isAmended },
  },
}: Store): boolean => isAmended || false;

export const getAdjustedQty = (state: Store): number =>
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  getTotalItems(state) || getTotalQuantityOfProducts(getItems(state)!, getIsAmendBasket(state));

export const getItemQtyWithUnavailableItems = (state: Store): number =>
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  getProductTotalWithUnavailableItems(getItems(state)!, getIsAmendBasket(state));

export const getTrolleyStateAndPendingQty = ({
  trolley: { isUpdating, items, radishItems },
}: Store): { pendingQty: number; isUpdating: boolean } => {
  let prevQty = 0;
  radishItems.forEach(item => {
    prevQty += getPieces(item);
  });
  const itemsArray = [...items.values()];
  const lastItems = itemsArray[itemsArray.length - 1];
  let nextQty = 0;
  lastItems.forEach(item => {
    nextQty += getPieces(item);
  });
  const pendingQty = nextQty - prevQty;

  return {
    pendingQty,
    isUpdating: !pendingQty ? false : isUpdating,
  };
};

export const getGuidePrice = ({
  trolley: {
    trolley: { guidePrice },
  },
}: Store): number => guidePrice || 0;

export const getTotalSavings = ({
  trolley: {
    trolley: { discounts },
  },
}: Store): number =>
  discounts?.categories.find(category => category?.type === DiscountTypes.PROMOTIONS_TOTAL)?.value || 0;

export const getClubcardPoints = ({
  trolley: {
    trolley: { clubcardPoints },
  },
}: Store): ClubcardPoints =>
  clubcardPoints || {
    greenPoints: 0,
    promoPoints: 0,
    standardPoints: 0,
    totalPoints: 0,
  };

export const getUpdatingCatchWeightItem = ({ trolley: { updatingCatchWeightItemDetails } }: Store): Item | null =>
  updatingCatchWeightItemDetails;

export const getTrolleyRequestInProgress = ({ trolley: { trolleyRequestInProgress } }: Store): boolean =>
  trolleyRequestInProgress;

export const getAddFromOrderRequestInProgress = (
  { trolley: { addFromOrderRequestInProgress } }: Store,
  orderId: string,
): boolean => !!addFromOrderRequestInProgress[orderId];

export const getShowPendingQty = ({ trolley: { showPendingQty } }: Store): boolean => showPendingQty;

export const getIsTrolleyUpdating = ({ trolley: { isUpdating, numberOfRequestsInProgress } }: Store): boolean =>
  isUpdating || numberOfRequestsInProgress > 0;

export const getIsTrolleyItemUpdating = (
  { trolley: { itemsToUpdate, trolleyRequestInProgress } }: Store,
  productId: string,
): boolean => idInItems(productId, itemsToUpdate) && trolleyRequestInProgress;

export const getIsBagUpdating = ({ trolley: { isBagUpdating } }: Store): boolean => isBagUpdating;

export const getAmendExpiryTime = ({
  trolley: {
    trolley: { amendExpiry, slot },
  },
}: Store): string | null =>
  // for UK GAPIs don't currently return the "amendExpiry" time so we fall back to using slot expiry time in this case
  amendExpiry || (slot && slot.expiry);

export const getAmendOrderId = ({
  trolley: {
    trolley: { amendOrderId },
  },
}: Store): string | undefined => amendOrderId;

export const getAmendOrderNo = ({
  trolley: {
    trolley: { amendOrderId },
  },
}: Store): string | undefined => amendOrderId?.split('_')[0];

export const getBasketId = ({
  trolley: {
    trolley: { basketId },
  },
}: Store): string | undefined => basketId;

export const getAnalyticsOrderId = (state: Store): string | undefined => {
  const {
    trolley: {
      trolley: { amendOrderId, orderNo },
    },
  } = state;
  return getAppRegion(state) === UK ? orderNo : amendOrderId;
};

export const getAnalyticsBasketId = (state: Store): string | undefined => {
  const {
    trolley: {
      trolley: { basketId, orderId },
    },
  } = state;
  return getAppRegion(state) === UK ? orderId : basketId;
};

export const getAnalyticsMarketplaceBasketId = (state: Store): string | undefined | null => {
  const {
    trolley: {
      trolley: { splitView },
    },
  } = state;
  if (!splitView) return null;
  const marketplaceBasket = getMarketplaceSplitView(splitView as SplitViewBasketState);
  return marketplaceBasket ? marketplaceBasket.id : null;
};

export const getTrolleyEmptied = ({ trolley: { trolleyEmptied } }: Store): boolean => trolleyEmptied;

export const getIsBagless = ({ trolley: { deliveryPreferences } }: Store): boolean => {
  const preferenceObj = deliveryPreferences;

  if (preferenceObj) {
    // TODO: fixit as tech debt separately in mango
    // This is because International API returns 'withBag' and UK as 'isBagless'
    const baglessPref = preferenceObj.hasOwnProperty('withBag') ? !preferenceObj.withBag : preferenceObj.isBagless;

    return baglessPref === undefined ? true : baglessPref;
  }

  return true;
};

export const getIsBaglessStore = ({
  trolley: {
    deliveryPreferences: { forcedBagOption, isBagless },
  },
}: Store): boolean => forcedBagOption && isBagless;

export const getBagCharge = ({
  trolley: {
    trolley: { charges },
  },
}: Store): number => (charges && charges.bagCharge) || 0;

export const getCharges = ({
  trolley: {
    trolley: { charges },
  },
}: Store): Partial<Charges> => charges || {};

export const getDeliveryThresholds = ({
  trolley: {
    trolley: { slot },
  },
}: Store): FreeDelivery | Record<string, never> => {
  if (slot && slot.freeDelivery) {
    const { freeDelivery } = slot;
    if (freeDelivery.deliveryThreshold > 0) {
      return freeDelivery;
    }
  }
  return {};
};

export const getFreeDeliveryThresholds = ({
  trolley: {
    trolley: { slot },
  },
}: Store): FreeDelivery | Record<string, never> => {
  if (slot && slot.freeDelivery) {
    return slot.freeDelivery;
  }
  return {};
};

export const getFreeDeliveryStatus = (state: Store): FreeDeliveryStatus | undefined => {
  const {
    trolley: {
      trolley: { slot, guidePrice },
    },
  } = state;
  let status: FreeDeliveryStatus;
  if (!slot) {
    return;
  }

  const deliveryThresholds = getDeliveryThresholds(state);
  if (Object.keys(deliveryThresholds).length === 0) {
    return;
  }
  const { deliveryThreshold, deliveryMessageThreshold: stretchThreshold } = deliveryThresholds as FreeDelivery;

  //0 means user has not spent more then stretchThreshold
  if (stretchThreshold > 0) {
    status = SPEND_MORE_FOR_FREE_DELIVERY;
  } else if (guidePrice >= deliveryThreshold) {
    status = QUALIFY_FOR_FREE_DELIVERY;
  } else {
    status = NOT_QUALIFY_FOR_FREE_DELIVERY;
  }
  return status;
};

export const getTrolleySize = ({ trolley: { radishItems } }: Store): number => radishItems.length || 0;

export const getStaffDiscount = ({
  trolley: {
    trolley: { staffDiscount },
  },
}: Store): number => (staffDiscount && staffDiscount.discount) || 0;

export const getItemsCount = (state: Store): number => {
  let count = 0;
  getItems(state).forEach(item => {
    if (!getProductIsForSale(item)) {
      return;
    } else if (getUnit(item) === 'kg') {
      count = count + 1;
    }

    count = count + getQuantity(item);
  });

  return count;
};

export const getTotalPrice = ({
  trolley: {
    trolley: { totalPrice },
  },
}: Store): number => totalPrice || 0;

export const geteCouponsTotal = ({
  trolley: {
    trolley: { eCouponsTotal },
  },
}: Store): number => eCouponsTotal || 0;

export const getAuthorizationAmount = ({
  trolley: {
    trolley: { authorizationAmount },
  },
}: Store): number => authorizationAmount || 0;

export const getSubscription = ({ trolley: { subscription } }: Store): Subscription => subscription || {};

export const getItemsToUpdate = (state: Store | Store['trolley']): Array<Item> => {
  const { itemsToUpdate } = getTrolleySliceOrProp(state) as Store['trolley'];
  return itemsToUpdate;
};

export const getIsUserItemPreferencesUpdating = ({
  trolley: { isUserItemPreferencesUpdating },
}: Store): string | undefined => isUserItemPreferencesUpdating;

export const getTrolleyDeliveryPreference = ({ trolley: { deliveryPreferences } }: Store): DeliveryPreferences =>
  deliveryPreferences;

export const getBookedShoppingMethod = ({
  trolley: {
    trolley: { slot },
    shoppingMethod,
  },
}: Store): ShoppingMethod => slot && shoppingMethod;

export const getIsSubstitutionPreferencesUpdating = ({
  trolley: { isSubstitutionPreferencesUpdating },
}: Store): boolean => isSubstitutionPreferencesUpdating;

export const getSubstituteAllItems = ({ trolley: { customerPreferences } }: Store): boolean =>
  (customerPreferences && customerPreferences.substituteAllItems) || false;

export const getTrolleyItemActionAnalytics = (
  { trolley: { trolleyActionAnalytics } }: Store,
  itemId: string,
): TrolleyAnalytics => (trolleyActionAnalytics && trolleyActionAnalytics[itemId]) || {};

export const isExtendSlotSubmitting = ({ trolley }: Store): boolean => trolley.slotExtensionSubmitting;

export const isGroceryOnlySubBasket = (state: Store): boolean => {
  const { splitView } = state.trolley.trolley;
  const groceriesBasket = splitView?.find(
    subBasket => subBasket.typename === BasketTypes.GROCERY || subBasket.typename === BasketTypes.IGHS_GROCERY,
  );
  const mpBasket = splitView?.find(subBasket => subBasket.typename === BasketTypes.MARKETPLACE);
  if (mpBasket) return false;

  if (!groceriesBasket) return false;
  if (groceriesBasket?.items?.length === 0) return false;
  const items = getItems(state);
  return items.length === groceriesBasket.items.length;
};

export const getHasOnlyMarketplaceItems = ({ trolley: { trolley } }: Store): boolean =>
  !!trolley.hasOnlyMarketplaceItems;

export const getGroceryBasket = (state: Store): Store['trolley']['trolley'] => {
  const { trolley } = getTrolleySliceOrProp(state) as Store['trolley'];
  const items = getItems(state);
  const { splitView, ...restTrolley } = trolley;
  const groceryBasket = splitView?.find(
    basket => basket.typename === BasketTypes.GROCERY || basket.typename === BasketTypes.IGHS_GROCERY,
  );

  if (!groceryBasket) {
    return {
      ...state.trolley,
      ...state.trolley.trolley,
      items: items.filter(item => item.product.typename === GHS_PRODUCT || item.product.typename === IGHS_PRODUCT),
    };
  }

  const fullGroceryBasket = {
    // return all root trolley fields as they havent been moved to the grocery sub basket yet
    ...state.trolley,
    ...restTrolley,
    ...groceryBasket,
    // temporary until mango returns product __typename in master
    items: items.filter(
      item => !item.product.typename || item.product.typename === GHS_PRODUCT || item.product.typename === IGHS_PRODUCT,
    ),
    charges: {
      ...groceryBasket.charges,
      // we fallback to root trolley charges if they are not found in the splitView basket
      // we can remove this when mango removes root trolley charges
      ...trolley.charges,
    },
  };

  return fullGroceryBasket;
};

export const getIsMixedBasket = (state: Store): boolean => {
  const groceryBasket = getGroceryBasket(state);
  const marketplaceBasket = getMarketplaceBasket(state);
  return !!marketplaceBasket && !!groceryBasket && (groceryBasket?.totalItems as number) > 0;
};

export const getMarketplaceSplitView = (splitView: SplitViewBasketState): SplitViewBasket | undefined =>
  splitView?.find(basket => basket.typename === BasketTypes.MARKETPLACE);

export const getPropositionalInfo = (splitViewBasket: SplitViewBasketState): PropositionalInfo[] | [] => {
  const basket = getMarketplaceSplitView(splitViewBasket);
  if (!basket) return [];
  return basket.propositionalInfo;
};

export const getMarketplaceBasket = (state: Store): SplitViewBasket | undefined => {
  const { trolley } = getTrolleySliceOrProp(state) as Store['trolley'];
  const items = getItems(state);
  const { splitView } = trolley;
  const marketplaceBasket = getMarketplaceSplitView(splitView as SplitViewBasketState);

  if (!marketplaceBasket) return;

  return {
    ...marketplaceBasket,
    // temporary until mango returns product __typename in master
    items: items.filter(item => item.product.typename === MARKETPLACE_PRODUCT),
  };
};

// if we want to loop over baskets
export const getBaskets = (state: Store): (SplitViewBasket | Store['trolley']['trolley'])[] => {
  return [getMarketplaceBasket(state) as SplitViewBasket, getGroceryBasket(state)].filter(Boolean);
};

export const getShouldShowUnavailablePrice = (state: Store, config: TConfigFunc): boolean =>
  !getIsOnDemandDelivery(state) && (config('shouldShowPriceOnUnavailableTiles') as boolean);

export const getTrolleyLocationUuid = ({
  trolley: {
    trolley: { locationUuid },
  },
}: Store): string => locationUuid;
