const VARIATION_KEY = 'multisearchUi';
export const FEATURE_KEY = 'GHS-<region>_multisearchUi';

export const multiSearchUiConfig = {
  featureKey: FEATURE_KEY,
  variationKey: VARIATION_KEY,
  useCustomerId: true,
};

export enum MultiSearchUiVariants {
  DEFAULT = 'default',
  NEWUI = 'new-ui',
  NEWUI_WITH_TOOLTIP = 'new-ui-tooltip',
  PNS_HEADING = 'pns-heading',
}

export const INPUT_MAX_LENGTH = 99;
export const MIN_EMPTY_FIELDS = 2;
export const MAX_ITEM_FIELDS = 99;
export const MIN_ITEM_FIELDS = 10;

export const TEXT_REGEX_PATTERN = /,|\r?\n/;
export const TITLE_PREDICTIVE_NEXT = 'May we suggest';
export const MULTI_SEARCH_PILLS_PER_PAGE = 10;

export const WARNING_MESSAGE_TYPE = {
  TYPING: 'typing',
  PASTING: 'pasting',
  MAXROW: 'maxRow',
  NONE: '',
};

const warningMessageText = {
  typingMaxChar: 'search:multi-search.warning-message-max-char',
  copyPasteMaxChar: 'search:multi-search.warning-message-max-char',
  copyPasteMaxRow: 'search:multi-search.warning-message-copy-paste-max-row',
};

export const warningMessage = {
  [WARNING_MESSAGE_TYPE.TYPING]: warningMessageText.typingMaxChar,
  [WARNING_MESSAGE_TYPE.PASTING]: warningMessageText.copyPasteMaxChar,
  [WARNING_MESSAGE_TYPE.MAXROW]: warningMessageText.copyPasteMaxRow,
};
export const analyticsPrefixText = 'user friendly message:';
export const CLOSED = 'closed';
export const TOOLTIP_WIDTH_DESKTOP = 352;
export const DISMISS = 'Dismiss';
