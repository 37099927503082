import { getVariationForExperiment } from '#/lib/optimizely-manager';
import { PlainObject } from '#/types';
import { baggingOptionCopyVariants } from '../constants';

type TbaggingOptionInfo = {
  legendTextKey: string;
  withoutBagsLabelKey: string;
  withoutBagsDescriptionKey?: string;
  withBagsLabelKey: string;
  withBagsDescriptionKey?: string;
};

const getBaggingOptionCopyVariants = (state: PlainObject): string =>
  getVariationForExperiment(state, baggingOptionCopyVariants.key, {}, baggingOptionCopyVariants.useCustomerId);

export const getBaggingOptionInfo = (state: PlainObject): TbaggingOptionInfo => {
  const variant = getBaggingOptionCopyVariants(state);
  const { variantB, variantC } = baggingOptionCopyVariants.variants;

  switch (variant) {
    case variantB:
    case variantC: {
      return {
        legendTextKey: `checkout:order-summary.oop856.${variant}.add-carrier-bags`,
        withoutBagsLabelKey: `checkout:order-summary.oop856.${variant}.without-bags-option`,
        withBagsLabelKey: `checkout:order-summary.oop856.${variant}.with-bags-option`,
      };
    }
    default:
      return {
        legendTextKey: 'checkout:order-summary.add-carrier-bags',
        withoutBagsLabelKey: 'checkout:order-summary.without-bags-option',
        withoutBagsDescriptionKey: 'checkout:order-summary.without-bags-option-details',
        withBagsLabelKey: 'checkout:order-summary.with-bags-option',
        withBagsDescriptionKey: 'checkout:order-summary.with-bags-option-details',
      };
  }
};
