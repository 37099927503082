/**
 * Experiment constants for changing the delivery saver banner onto slot matrix page
 */

export const categoryPillV2Variants = {
  key: '2191',
  featureKey: 'GHS-<region>_category_pills',
  variationKey: 'category_pills_type',
  variants: {
    default: 'default',
    largeImageCarousel: 'large-imagery-carousel',
    largeImageNoCarousel: 'large-imagery-no-carousel',
  },
};

export const seeMoreAccordion = {
  defaultMobileItemsLength: 2,
  defaultTabletItemsLength: 4,
  defaultDesktopItemsLength: 8,
};
