import React from 'react';
import { compose } from 'react-recompose';
import { ConnectedProps } from 'react-redux';
import Button from '@ddsweb/button';
import { connect } from '#/lib/render/connect-deep-compare';
import helpers from '#/lib/decorators/helpers';
import { ButtonContainer, Content, StyledHeading, StyledBodyText, StyledModal } from './styled';
import { closeModal } from '#/actions/ui-action-creators';
import CancelBookedSlotButton from './cancel-slot-button';
import { TTranslateFn } from '#/components/slots/delivery-content-section/index.defs';

type HelperProps = {
  t: TTranslateFn;
};

const connector = connect(() => ({}), { closeModal });

type TProps = HelperProps & ConnectedProps<typeof connector>;

const CancelBookedSlotModal = (props: TProps): JSX.Element => {
  const { t: translate, closeModal } = props;

  const changeHandler = (_: void, event: React.SyntheticEvent): void => {
    onClose(event);
  };

  const onClose = (event: React.SyntheticEvent): void => {
    event.preventDefault();
    closeModal();
  };

  return (
    <StyledModal id="cancel-booked-slot-modal" open ssr onChange={changeHandler}>
      <Content>
        <StyledHeading>{translate('modals:cancel-booked-slot.title')}</StyledHeading>
        <StyledBodyText>{translate('modals:cancel-booked-slot.content')}</StyledBodyText>
      </Content>
      <ButtonContainer className="button__actions">
        <Button variant={'secondary'} onClick={onClose} data-auto="close-modal-button">
          {translate('modals:cancel-booked-slot.button.go-back')}
        </Button>
        <CancelBookedSlotButton />
      </ButtonContainer>
    </StyledModal>
  );
};

const enhance = compose<TProps, {}>(helpers(['t']), connector);
export default enhance(CancelBookedSlotModal);
