import React from 'react';
import helpers from '#/lib/decorators/helpers';
import FulfilmentStepsComponent, { GraphicsKeys } from '#/components/slots/fulfilment-steps';
import { ShoppingMethod } from '#/constants/shopping-methods';
import { colors } from '@ddsweb/selectors';
import { DynamicDeliveryTime } from '#/custom-typings/redux-store/slot.defs';
import { getOnDemandDynamicDeliveryData } from '#/lib/shopping-method-util';
import { TConfigFunc, TTranslateFunc } from '#/lib/records/helpers.defs';
import { DefaultStyledProps } from '@ddsweb/types';
import { CardIcon } from '#/components/slots/fulfilment-steps/assets/CardIcon';
import { getDeliveryCharge, getOnDemandDynamicDeliveryTime } from '#/selectors/slot';
import { getCurrency, getLanguage } from '#/reducers/app';
import { connect } from 'react-redux';

type TDefaultStyledProps = (props: DefaultStyledProps) => string;
const backgroundColor = (colors.tescoBlue as unknown) as TDefaultStyledProps;
const stroke = (colors.white as unknown) as TDefaultStyledProps;

const ONDEMAND_DELIVERY_STEPS: GraphicsKeys[] = [
  {
    icon: 'openingHours',
    backgroundColor,
    stroke,
  },
  {
    icon: 'basket',
    backgroundColor,
    stroke,
  },
  {
    icon: 'storeLocator',
    backgroundColor,
    stroke,
  },
  {
    icon: 'mobile',
    backgroundColor,
    stroke,
  },
];
export type TStateProps = {
  currency: {
    isoCode: string;
    symbol: string;
  };
  charge: number | undefined;
  language: string;
  onDemandDynamicDeliveryTime: DynamicDeliveryTime;
};

type TProps = TStateProps & {
  shoppingMethod: ShoppingMethod;
  t: TTranslateFunc;
  c: TConfigFunc;
};

const mapStateToProps = (state: Store, { shoppingMethod }: { shoppingMethod: ShoppingMethod }): TStateProps => {
  const currency = getCurrency(state);
  const charge = getDeliveryCharge(state, shoppingMethod);
  const language = getLanguage(state);
  const onDemandDynamicDeliveryTime = getOnDemandDynamicDeliveryTime(state);

  return {
    charge,
    currency,
    language,
    onDemandDynamicDeliveryTime,
  };
};

const OnDemandDeliveryTabComponent: React.FC<TProps> = (props: TProps) => {
  const { t: translate, c: config, currency, charge, shoppingMethod, language, onDemandDynamicDeliveryTime } = props;
  const moreAboutOnDemand = config(`links:moreAboutOnDemand`);
  const stepSelected = ONDEMAND_DELIVERY_STEPS;

  const steps = stepSelected.map((graphic, index) => {
    const stepNum = index + 1;
    let text;
    const { onDemandDynamicDeliverySuffix, unit, min, max } = getOnDemandDynamicDeliveryData(
      true,
      stepNum == 1,
      onDemandDynamicDeliveryTime,
    );

    if ((charge === null || charge === undefined) && stepNum === 1) {
      text = translate(`slots:${shoppingMethod}.how-it-works.step-1-no-charge${onDemandDynamicDeliverySuffix}`, {
        unit,
        min,
        max,
      });
    } else {
      text = translate(`slots:${shoppingMethod}.how-it-works.step-${stepNum}${onDemandDynamicDeliverySuffix}`, {
        charge: new Intl.NumberFormat(language, {
          style: 'currency',
          currency: currency.isoCode,
          minimumFractionDigits: 2,
        }).format(charge as number),
        unit,
        min,
        max,
      });
    }

    return {
      graphic,
      customIcon: index !== 2 ? null : <CardIcon />,
      text,
      title: translate(`slots:${shoppingMethod}.how-it-works.title.step-${stepNum}${onDemandDynamicDeliverySuffix}`, {
        unit,
        min,
        max,
      }),
    };
  });

  return (
    <FulfilmentStepsComponent
      deliverySteps={steps}
      sectionTitle={translate(`slots:${shoppingMethod}.how-it-works.heading`)}
      faqDesc={translate(`slots:${shoppingMethod}.how-it-works.faq-desc`)}
      faqLinkHref={moreAboutOnDemand}
      faqLinkText={translate(`slots:${shoppingMethod}.how-it-works.faq-link-text`)}
    />
  );
};

const OnDemandTab = helpers(['t', 'c'])(connect(mapStateToProps)(OnDemandDeliveryTabComponent));

export { OnDemandTab };
