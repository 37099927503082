import React from 'react';
import PropTypes from 'prop-types';

const Phone = ({ label, phone }) =>
  (phone && (
    <p>
      <span>{label}</span>
      <span>{phone}</span>
    </p>
  )) ||
  null;

Phone.propTypes = {
  label: PropTypes.string,
  phone: PropTypes.string
};

export default Phone;
