import { getFeatureVariableStringFromState, updateRegionInKey } from '#/lib/optimizely-manager';
import {
  ClassNameAndWrapperClassName,
  FilterLableAndClassName,
  FilterAttributesVariant,
  FilterTitleAndVariantClassName,
  MobileTextAndBackgroundColourVariant,
  OFFERS_AND_CLUBCARD_PRICES,
  SPECIAL_OFFERS,
  SpecialOffersFilterFeature,
  SpecialOffersFilterVariants,
  SEARCH_URL,
  SHOP_URL,
} from '../constants';
import { getIsCurrentPage, getCurrentPath } from '#/reducers/app';

const getShopUrl = (state: Store): boolean => {
  const currentPage = getCurrentPath(state);
  const regex = new RegExp(SHOP_URL);
  return !!currentPage.match(regex)?.length;
};

export const getSpecialOffersFilterVariant = (state: Store): SpecialOffersFilterVariants => {
  const { featureKey, variationKey } = SpecialOffersFilterFeature;
  const regionInKey = updateRegionInKey(featureKey, state);
  const featureVariant = getFeatureVariableStringFromState(state, regionInKey, variationKey);
  const isSearchOrShopPage = getIsCurrentPage(state, [SEARCH_URL]) || getShopUrl(state);

  return isSearchOrShopPage
    ? featureVariant || SpecialOffersFilterVariants.DEFAULT
    : SpecialOffersFilterVariants.DEFAULT;
};

export const getFilterAttributes = (state: Store): FilterAttributesVariant | null => {
  const { GREY, YELLOW, CLUB_CARD_GREY, CLUB_CARD_YELLOW } = SpecialOffersFilterVariants;
  switch (getSpecialOffersFilterVariant(state)) {
    case GREY:
      return {
        label: SPECIAL_OFFERS,
        desktopClassName: 'promotions-selector__grey-text',
        mobileClassName: GREY,
      };

    case YELLOW:
      return {
        label: SPECIAL_OFFERS,
        desktopClassName: 'promotions-selector__yellow-background',
        mobileClassName: YELLOW,
      };

    case CLUB_CARD_GREY:
      return {
        label: OFFERS_AND_CLUBCARD_PRICES,
        desktopClassName: 'promotions-selector__grey-text',
        mobileClassName: CLUB_CARD_GREY,
      };

    case CLUB_CARD_YELLOW:
      return {
        label: OFFERS_AND_CLUBCARD_PRICES,
        desktopClassName: 'promotions-selector__yellow-background',
        mobileClassName: CLUB_CARD_YELLOW,
      };

    default:
      return null;
  }
};

export const getMobileTextAndBackgroundColour = (variant: string): MobileTextAndBackgroundColourVariant => {
  const { GREY, YELLOW, CLUB_CARD_GREY, CLUB_CARD_YELLOW } = SpecialOffersFilterVariants;
  if (variant === GREY || variant === CLUB_CARD_GREY) {
    return {
      textColourClassName: 'label blue_text',
      backgroundColourClassName: null,
    };
  }

  if (variant === YELLOW || variant === CLUB_CARD_YELLOW) {
    return {
      textColourClassName: 'label black_text',
      backgroundColourClassName: 'label-wrapper yellow_background',
    };
  }

  return {
    textColourClassName: 'label',
    backgroundColourClassName: 'label-wrapper',
  };
};

export const getFilterTitleAndVariantClassName = (
  filterTitle: string,
  filterElementTitleAndVariantClassName: FilterAttributesVariant,
): FilterTitleAndVariantClassName => {
  if (filterTitle === SPECIAL_OFFERS && filterElementTitleAndVariantClassName) {
    return {
      filterTitle: filterElementTitleAndVariantClassName.label,
      variantClassName: filterElementTitleAndVariantClassName.mobileClassName,
    };
  }
  return {
    filterTitle: filterTitle,
    variantClassName: null,
  };
};

export const getFilterLabelAndClassName = (
  label: string,
  filterOptionLabelAndClassName: FilterAttributesVariant,
  className: string,
): FilterLableAndClassName => {
  if (label === SPECIAL_OFFERS && filterOptionLabelAndClassName) {
    return {
      label: filterOptionLabelAndClassName.label,
      className: filterOptionLabelAndClassName.desktopClassName,
    };
  }
  return { label, className };
};

export const isTruncate = (filterOptionLabelAndClassName: FilterAttributesVariant): boolean =>
  filterOptionLabelAndClassName?.label !== OFFERS_AND_CLUBCARD_PRICES;

export const getClassNameAndWrapperClassName = (
  variantClassName: string | null,
  isPromotion: boolean,
  count: number | null,
  className: string,
  wrapperClassName: string,
): ClassNameAndWrapperClassName => {
  if (variantClassName && isPromotion && count) {
    const variantDetails = getMobileTextAndBackgroundColour(variantClassName);
    const { textColourClassName, backgroundColourClassName } = variantDetails;
    return {
      className: textColourClassName,
      wrapperClassName: backgroundColourClassName || wrapperClassName,
    };
  }
  return { className, wrapperClassName };
};
