import { getProviderKeyBasedOnStrategy, Strategy } from '../helpers/get-tesco-recommendations-helper';
import { Fragment } from '../graphql-queries/fragments/types';
import { DCS, TREX } from '#/constants/recommendations';

const baseQueryInterface = [
  '$basketItems: [ID]',
  '$endDateTime: String',
  '$exclusionInfo: ExclusionInfoType',
  '$pageId: String',
  '$pageName: TescoRecommendationpageName',
  '$startDateTime: String',
  '$storeId: ID',
  '$locationUuid:ID',
  '$tpnc: ID',
  '$includeReviews: Boolean = false',
  '$includeDietaryIcons: Boolean = false',
];

const addRecommendationsToQuery = (recommendations: string, strategies: Strategy[], fragments: Fragment[]): string => {
  const dynamicQueryInterface = strategies
    .map(({ type = DCS, variant, strategy, superDepartment, tpnb }, index) => {
      const provider = type.toUpperCase();
      const key = getProviderKeyBasedOnStrategy(provider, index, strategy, variant, superDepartment, tpnb);

      const vars: string[] = [`$provider_${key}: RecommendationProvider`, `$pageSize_${key}: Int`];

      if (provider === TREX) {
        if (variant) {
          vars.push(`$variant_${key}: ID`);
        }
        vars.push(`$position_${key}: Int`);
      } else {
        vars.push(`$promotion_${key}: String`);
      }

      if (superDepartment) vars.push(`$superDepartment_${key}: String`);
      if (tpnb) vars.push(`$tpnb_${key}: ID`);

      return vars;
    })
    .reduce((prev, curr) => prev.concat(curr));

  const queryInterface = baseQueryInterface.concat(dynamicQueryInterface).join(`\n`);
  const fragmentsStr = fragments.map(({ value }) => `${value}\n`).join(`\n`);

  return `query GetTescoRecommendations(
    ${queryInterface}
  ) {
    ${recommendations}
  }
  ${fragmentsStr}`;
};

const generateRecommendations = (strategies: Strategy[], fragments: Fragment[]): string => {
  const fragmentsReferences = fragments.map(({ key }) => `${key}\n`).join('\n');

  return strategies
    .map(({ type = DCS, strategy, variant, superDepartment, tpnb }, index) => {
      const provider = type.toUpperCase();
      const key = getProviderKeyBasedOnStrategy(provider, index, strategy, variant, superDepartment, tpnb);

      const variantVariable = variant ? `variant: $variant_${key}` : '';
      const tpnbVariable = tpnb ? `tpnb: $tpnb_${key}` : '';
      if (provider === TREX) {
        return `${key}: recommendations(
          count: $pageSize_${key}
          basketItems: $basketItems
          endDateTime: $endDateTime
          exclusionInfo: $exclusionInfo
          pageId: $pageId
          pageName: $pageName
          provider: $provider_${key}
          startDateTime: $startDateTime
          storeId: $storeId
          locationUuid:$locationUuid
          position: $position_${key}
          tpnc: $tpnc
          ${variantVariable}
          ${tpnbVariable}
        ) {
          ${fragmentsReferences}
        }
        `;
      }
      return `${key}: promotionType(
        type: $promotion_${key}
        count: $pageSize_${key}
        provider: $provider_${key}
        superDepartment: $superDepartment_${key}
      ) {
        ${fragmentsReferences}
      }`;
    })
    .join('\n');
};

export const buildGetTescoRecommendationsQuery = (strategies: Strategy[], fragments: Fragment[]): string => {
  const recommendations = generateRecommendations(strategies, fragments);

  return addRecommendationsToQuery(recommendations, strategies, fragments);
};
