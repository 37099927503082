import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Link from '../../link-check-spa';

import { ResponsiveBackground } from '../responsive-background';

const PageHeader = ({ image, placement, linkUrl, openInNewTab }) => {
  const stampClasses = classnames('trade-tile', 'trade-tile--page-header');

  const content = (
    <span className="tile__image-container">
      <ResponsiveBackground
        className="tile__image"
        url={image.src}
        maxWidth={image.maxWidth}
        uid={placement}
      />
    </span>
  );

  return (
    <div className={stampClasses}>
      {linkUrl ? (
        <Link
          to={linkUrl}
          target={openInNewTab ? '_blank' : '_self'}
          rel={openInNewTab ? 'noopener noreferrer' : null}
        >
          {content}
        </Link>
      ) : (
        content
      )}
    </div>
  );
};

PageHeader.propTypes = {
  image: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    maxWidth: PropTypes.number
  }).isRequired,
  linkUrl: PropTypes.string,
  openInNewTab: PropTypes.bool,
  placement: PropTypes.number
};

PageHeader.defaultProps = {
  linkUrl: '',
  openInNewTab: false,
  placement: 0
};

export { PageHeader };
