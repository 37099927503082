import { getRegionalKeys } from '#/lib/config-helpers';

/**
 * The returned function has the following `returnOptions`
 * that are passed in the third argument object:
 *
 *  - returnNullIfFalsy - if the key is undefined in the polyglot.phrases, or the key value is empty or null
 */
export const getTranslator = (config, polyglot) => (
  k,
  data = {},
  options = {}
) => {
  // for backwards compatibility
  if (typeof options !== 'object') {
    options = { returnNullIfFalsy: !!options };
  }

  const keys = getRegionalKeys(k, data, config('REGION'));
  const key = keys.find(key => polyglot.phrases[key]);

  const { returnNullIfFalsy } = options;

  if (returnNullIfFalsy && !key) {
    return null;
  }

  return polyglot.t(key || k, data);
};
