import { hasPickupSelfService } from '#/utils/location-util';

export const getTranslatableOrderStatus = status => {
  // Map value that comes back from Mango to something translatable
  const orderStatuses = {
    Cancelled: 'cancelled',
    Collected: 'collected',
    Delivered: 'delivered',
    Pending: 'pending',
    Created: 'created',
    ReadyForCollection: 'ready-for-collection',
    ReadyForDelivery: 'ready-for-delivery',
    Processed: 'processed',
    Received: 'received'
  };

  const orderStatus = orderStatuses[status];
  // If it exists, translate it, otherwise just return an empty string
  return orderStatus ? `orders:status.${orderStatuses[status]}` : '';
};

export const hasCollectionPointsWithPickup = ({ collectionPointAttributes }) =>
  collectionPointAttributes?.some(hasPickupSelfService);
