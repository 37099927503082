import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled, { css } from 'styled-components';
import deepEqual from 'fast-deep-equal/es6';
import { connect } from '#/lib/render/connect-deep-compare';
import SpecialOfferSash from '#/components/products/offers-sash';
import FlashSash from '#/components/promotions/flash-sash';
import { parseQueryString } from '#/lib/url/url-utils';
import { getCatchWeightInQueryString } from '#/lib/records/product-utils';
import { QUANTITY_OR_WEIGHT, CATCH_WEIGHT } from '#/constants/display-types';
import { UNIT_CHOICE } from '#/constants/query-strings';
import { getTrolleyDeliveryAddress } from '#/selectors/trolley';
import { ProductTileConfiguration } from './tile-configurations';
import helpers from '#/lib/decorators/helpers';
import { setCatchWeightTo } from '#/actions/trolley/trolley-action-creators';
import {
  getAppRegion,
  getCurrency,
  getCurrentUrl,
  getLanguage,
  getLanguageLink
} from '#/reducers/app';
import {
  VIEW_AND_BUY_TYPE_OFFERS,
  VIEW_AND_BUY_TYPE_FAVORITES
} from '#/constants/display-types';
import { UNDELIVERABLE_ADDRESS_MODAL } from '#/constants/modal-names';
import { openModal } from '#/actions/ui-action-creators';
import {
  BUYLIST,
  FAVORITES,
  FLEXI,
  HAVE_YOU_FORGOTTEN,
  HORIZONTAL_EMBEDDED,
  PENDING_ORDER,
  PREVIOUS_ORDER,
  SHOPPING_LISTS,
  TROLLEY,
  USUALS,
  BEANS
} from '#/constants/tile-types';
import { getIsUserRegistered } from '#/reducers/user';
import { getFlashSashInfo } from '#/components/products/product-tile/helpers';
import Experiment from '#/components/experiment';
import Variation from '#/components/experiment/variation';
import BasketFeedback from '#/components/products/basket-feedback';
import {
  getProductId,
  hasRestrictedOrderAmendment,
  getQuantityForDisplay,
  isCatchWeightProduct,
  getCatchWeightPrice,
  isProductBrowsableOnPDP,
  isRdgRestricted,
  getProductPrice,
  getProductDisplayType,
  isInBasket,
  getIsSubstitute,
  getProductIsForSale,
  hasPromotion,
  getProductTitle,
  hasSubstitution,
  existingInBasketAndExcluded,
  getUnit,
  getCatchWeight,
  isClubcardPromotionOnItem,
  hasProductDisplayType
} from '#/selectors/item';
import { BLUE } from '#/constants/colors';
import { getTranslationForQuantity } from '#/lib/product-helpers';

export const StyledSpecialOfferSash = styled(SpecialOfferSash)`
  ${({ isSubstitute }) =>
    isSubstitute &&
    css`
      position: absolute;
      left: 18px;
    `};
`;

const mapStateToProps = (state, ownProps) => {
  const productId = getProductId(ownProps.item);

  return {
    userRegistered: getIsUserRegistered(state),
    currentUrl: getCurrentUrl(state),
    href: getLanguageLink(state, `/products/${productId}`),
    language: getLanguage(state),
    deliveryAddress: getTrolleyDeliveryAddress(state),
    region: getAppRegion(state),
    currency: getCurrency(state)
  };
};

@helpers(['t', 'c', 'f'])
@connect(mapStateToProps, { setCatchWeightTo, openModal })
export default class ProductTile extends Component {
  static propTypes = {
    c: PropTypes.func.isRequired,
    classes: PropTypes.string,
    currentUrl: PropTypes.string.isRequired,
    deliveryAddress: PropTypes.object,
    href: PropTypes.string,
    identifier: PropTypes.string,
    isAmendBasket: PropTypes.bool,
    isRemoved: PropTypes.bool,
    item: PropTypes.object.isRequired,
    itemIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    language: PropTypes.string.isRequired,
    listData: PropTypes.object.isRequired,
    onAdd: PropTypes.func,
    onDetailsClick: PropTypes.func,
    onRestOfShelfClick: PropTypes.func,
    openModal: PropTypes.func.isRequired,
    ospUrl: PropTypes.string,
    region: PropTypes.string,
    removedQuantity: PropTypes.number,
    setCatchWeightTo: PropTypes.func.isRequired,
    shouldShowSubs: PropTypes.bool,
    showInfoMessage: PropTypes.bool,
    showPromotion: PropTypes.bool,
    showRestOfShelf: PropTypes.bool,
    showWriteReview: PropTypes.bool,
    t: PropTypes.func.isRequired,
    tileType: PropTypes.string,
    type: PropTypes.oneOf([
      FLEXI,
      TROLLEY,
      FAVORITES,
      USUALS,
      PREVIOUS_ORDER,
      PENDING_ORDER,
      SHOPPING_LISTS,
      BUYLIST,
      HORIZONTAL_EMBEDDED,
      HAVE_YOU_FORGOTTEN,
      BEANS
    ]).isRequired,
    updateReturnUrlWithProductId: PropTypes.bool,
    userRegistered: PropTypes.bool.isRequired,
    viewAndBuyButtonUrl: PropTypes.string,
    viewAndBuyType: PropTypes.oneOf([
      VIEW_AND_BUY_TYPE_OFFERS,
      VIEW_AND_BUY_TYPE_FAVORITES
    ])
  };

  static defaultProps = {
    shouldShowSubs: true,
    showRestOfShelf: true,
    showWriteReview: true,
    showPromotion: true,
    showInfoMessage: true,
    classes: ''
  };

  constructor(props) {
    super(props);
    this.state = this.statesWithProps(props);
    this.updateSelectedUnit = this.updateSelectedUnit.bind(this);
  }

  componentWillReceiveProps(props) {
    this.setState(this.statesWithProps(props));
  }

  shouldComponentUpdate(nextProps, nextState) {
    const props = this.props;
    const state = this.state;

    return (
      !deepEqual(props.item, nextProps.item) ||
      props.isAmendBasket !== nextProps.isAmendBasket ||
      props.userRegistered !== nextProps.userRegistered ||
      props.isRemoved !== nextProps.isRemoved ||
      props.removedQuantity !== nextProps.removedQuantity ||
      state.selectedUnit !== nextState.selectedUnit ||
      state.sellableUnitPrice !== nextState.sellableUnitPrice ||
      props.classes !== nextProps.classes
    );
  }

  updateSelectedUnit(unit) {
    this.setState({
      selectedUnit: unit
    });
  }

  statesWithProps(props) {
    const item = props.item;
    const unitChoiceQuery = parseQueryString(props.currentUrl)[UNIT_CHOICE];
    const shouldUseUnitChoiceQuery =
      unitChoiceQuery && hasProductDisplayType(item, QUANTITY_OR_WEIGHT);
    const catchWeightInQueryString = getCatchWeightInQueryString(
      getProductId(item),
      props.currentUrl
    );

    const catchWeight = catchWeightInQueryString
      ? catchWeightInQueryString
      : getCatchWeight(item);

    return {
      catchWeight: catchWeight,
      sellableUnitPrice: isCatchWeightProduct(item)
        ? getCatchWeightPrice(item, catchWeight)
        : getProductPrice(item),
      selectedUnit: shouldUseUnitChoiceQuery ? unitChoiceQuery : getUnit(item)
    };
  }

  changeCatchWeightBlocked() {
    const { deliveryAddress, openModal } = this.props;

    openModal(UNDELIVERABLE_ADDRESS_MODAL, deliveryAddress.name);
  }

  updateCatchWeight = weight => {
    const props = this.props;
    const { item, deliveryAddress } = props;

    if (
      isInBasket(item) &&
      deliveryAddress &&
      deliveryAddress.isBlockedAddress
    ) {
      this.changeCatchWeightBlocked();

      return;
    }

    const catchWeight = parseFloat(weight);

    this.setState({
      catchWeight: catchWeight,
      sellableUnitPrice: getCatchWeightPrice(item, catchWeight)
    });

    if (props.userRegistered) {
      props.setCatchWeightTo(item, catchWeight);
    }
  };

  basketFeedbackRender = () => {
    const { t: translate, item, region } = this.props;

    const quantity = getTranslationForQuantity(
      translate,
      getQuantityForDisplay(item)
    );

    return (
      <BasketFeedback
        feedbackText={`${quantity} ${translate('product-tile:in-trolley')}`}
        screenReaderText={`${quantity} ${getProductTitle(item)} ${translate(
          'trolley:item-basket-change'
        )}`}
        region={region}
      />
    );
  };

  hasSquareOfferSash = isAvailable => {
    const { item } = this.props;

    return isAvailable && hasPromotion(item) && isClubcardPromotionOnItem(item);
  };

  render() {
    const {
      item,
      href,
      viewAndBuyButtonUrl,
      ospUrl,
      viewAndBuyType,
      shouldShowSubs,
      type,
      itemIndex,
      t: translate
    } = this.props;
    const isItemAvailable = getProductIsForSale(item);

    const isAvailableEpwOverride =
      this.props.isAmendBasket && existingInBasketAndExcluded(item);

    const isRestrictedDisabled =
      hasRestrictedOrderAmendment(item) || isRdgRestricted(item);
    const controlProps = Object.assign({}, this.props, {
      isAvailableEpwOverride,
      itemIndex,
      updateSelectedUnit: this.updateSelectedUnit,
      selectedUnit: this.state.selectedUnit,
      hideQuantityToggle: false,
      disabled: isRestrictedDisabled,
      catchWeight: this.state.catchWeight,
      updateCatchWeight: this.updateCatchWeight,
      sellableUnitPrice: this.state.sellableUnitPrice,
      viewAndBuyButtonUrl,
      ospUrl,
      viewAndBuyType
    });

    const showUnavailableMessage = this.props.c(
      'showProductUnavailableMessages'
    );

    const showCCPriceLogoInSquare = this.props.c('showCCPriceLogoInSquare');

    const props = Object.assign({}, this.props, {
      isAvailableEpwOverride,
      sellableUnitPrice: this.state.sellableUnitPrice,
      selectedUnit: this.state.selectedUnit,
      href,
      controlProps,
      showUnavailableMessage,
      productBrowsableOnPDP: isProductBrowsableOnPDP(item),
      viewAndBuyButtonUrl,
      ospUrl,
      viewAndBuyType,
      basketFeedbackRender: this.basketFeedbackRender
    });

    const isInTrolley =
      type !== BEANS && isInBasket(item) && type !== PENDING_ORDER;

    const available = isItemAvailable || isAvailableEpwOverride;

    const dataAutoType = isCatchWeightProduct(item)
      ? CATCH_WEIGHT
      : getProductDisplayType(item);

    const productTileRender = (variant = '', propsOverride = {}) => {
      const flashSashInfo = getFlashSashInfo({
        translate,
        item
      });

      return (
        <div className="product-tile-wrapper">
          {item.isSponsoredProduct ? (
            <FlashSash
              text={translate('product-tile:sponsored')}
              backgroundColor={BLUE}
              tileType={type}
            />
          ) : (
            flashSashInfo && (
              <StyledSpecialOfferSash
                flashSashInfo={flashSashInfo}
                tileType={type}
                isSubstitute={getIsSubstitute(item)}
                className="special-offer-sash"
                showCCPriceLogoInSquare={showCCPriceLogoInSquare}
              />
            )
          )}
          <div
            data-auto="product-tile"
            data-auto-type={dataAutoType}
            data-auto-available={available}
            data-auto-why-not-try={item.isWhyNotTry}
            data-auto-is-restricted-disabled={isRestrictedDisabled}
            data-auto-is-substitute={getIsSubstitute(item)}
            className={classNames(
              'product-tile',
              {
                'product-tile--why-not-try--328-b':
                  item.isWhyNotTry && variant === 'oop328-b',
                'product-tile--unavailable': !available,
                'product-tile--why-not-try': item.isWhyNotTry,
                'product-tile--sponsored': item.isSponsoredProduct,
                'is-in-trolley': isInTrolley,
                'has-promotion': hasPromotion(item),
                'product-tile--has-substitution':
                  shouldShowSubs && hasSubstitution(item),
                'product-tile--is-substitute': getIsSubstitute(item),
                'product-tile--catch-weight': isCatchWeightProduct(item),
                'square-offer-sash': this.hasSquareOfferSash(available)
              },
              this.props.classes
            )}
          >
            {ProductTileConfiguration[type](
              Object.assign(props, propsOverride)
            )}
          </div>
        </div>
      );
    };

    const basketTileTestProps = {
      controlProps: {
        ...controlProps,
        showUnitPrice: false
      },
      showHeartIcon: false,
      showRestOfShelf: false,
      showDatesOnlyOnMissedSlot: true,
      excludeMessages: [
        'variableWeightProduct',
        'blueRdgRestrictions',
        'completeOffer'
      ]
    };

    const productTileVariant = (variant, propsOverride = {}) => {
      return (
        <Variation variant={variant}>
          {productTileRender(variant, propsOverride)}
        </Variation>
      );
    };

    if (type === TROLLEY) {
      return (
        <Experiment defaultVariant="364-a" name="oop364">
          {productTileVariant('364-a')}
          {productTileVariant('364-b', basketTileTestProps)}
        </Experiment>
      );
    }

    return productTileRender();
  }
}
