import deepEqual from 'fast-deep-equal/es6';
import { Items } from '#/custom-typings/redux-store/trolley.defs';
import { Item } from '#/lib/records/item';
import { getById, idInItems } from '#/lib/records/product-utils';
import { getProductId } from '#/selectors/item';

/**
 *
 * Returns a new map apparently identical to 'items'. However, references are
 * reused from a base map of items for any data that has not changed.
 *
 * @param {Map} base Base map of items
 * @param {Map} items map of items
 */
export default function mergeItems(base: Items | Array<Item>, items: Items | Array<Item>): Items {
  const mergedItems = new Map();

  base.forEach((item: Item) => {
    const id = getProductId(item);

    if (idInItems(id, items)) {
      const updatedItem = getById(items, id);
      mergedItems.set(id, deepEqual(item, updatedItem) ? item : updatedItem);
    }
  });

  items.forEach((item: Item) => {
    const id = getProductId(item);

    if (!mergedItems.has(id)) {
      mergedItems.set(id, item);
    }
  });

  return mergedItems;
}
