import { Component } from 'react';
import PropTypes from 'prop-types';
import { FORESEE_MODAL } from '../../../constants/modal-names';
import helpers from '../../../lib/decorators/helpers';
import { connect } from '../../../lib/render/connect-deep-compare';
import { getIsAmendBasket } from '#/selectors/trolley';
import { getAtrc } from '#/reducers/user';
import { openModal } from '../../../actions/ui-action-creators';
import { getTraceId } from '#/reducers/app';

const mapStateToProps = state => ({
  atrc: getAtrc(state),
  isAmendBasket: getIsAmendBasket(state),
  isError: state.ui.isError,
  traceId: getTraceId(state)
});

@helpers(['asset'])
@connect(mapStateToProps, { openModal })
export default class ForeseeHelper extends Component {
  static propTypes = {
    asset: PropTypes.func.isRequired,
    atrc: PropTypes.string.isRequired,
    isAmendBasket: PropTypes.bool.isRequired,
    isError: PropTypes.bool.isRequired,
    openModal: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.ready = false;
    this.loaded = false;
  }

  componentDidMount() {
    this.onLoadEvent = `${this.props
      .asset('/scripts/foresee-config.js')
      .split('/')
      .pop()}:loaded`;
    this.addOnLoadListener();
    this.updateSuppressionState();
  }

  componentDidUpdate() {
    this.updateSuppressionState();
  }

  addOnLoadListener = () => {
    if (!this.loaded) {
      window.addEventListener(this.onLoadEvent, this.handleLoad);
    }
  };

  removeOnLoadListener = () => {
    window.removeEventListener(this.onLoadEvent, this.handleLoad);
  };

  handleLoad = () => {
    this.loaded = true;
    this.removeOnLoadListener();

    if (!this.ready) {
      window.fsReady(this.handleReady);
    }
  };

  handleReady = () => {
    const { atrc, traceId } = this.props;
    this.ready = true;
    this.updateSuppressionState();

    window.FSR.CPPS.set('traceId', traceId);
    window.FSR.CPPS.set('atrc', atrc);
    window.FSR.customInvitationRequested.subscribe(this.handleTrigger);
  };

  handleTrigger = (
    inviteType,
    acceptHandler,
    declineHandler,
    abandonHandler
  ) => {
    this.props.openModal(
      FORESEE_MODAL,
      { inviteType, acceptHandler, declineHandler, abandonHandler },
      false
    );
  };

  updateSuppressionState = () => {
    if (this.props.isAmendBasket || this.props.isError) {
      this.suppress();
    } else {
      this.unsuppress();
    }
  };

  suppress = () => {
    window.suppressForesee = true;
  };

  unsuppress = () => {
    window.suppressForesee = false;
  };

  render = () => null;
}
