import { TReceiveAddressesAction } from '#/reducers/addresses';
import { AddressesState } from '#/custom-typings/redux-store/addresses.defs';
import { RECEIVE_ADDRESSES } from '#/constants/action-types';

export function receiveAddresses(payload: AddressesState): TReceiveAddressesAction {
  return {
    type: RECEIVE_ADDRESSES,
    payload,
  };
}
