import React, { FC, forwardRef, RefAttributes } from 'react';
import { SECONDARY } from '@beans/constants';
import { createSPALink } from '@beans/helpers';
import Link from '#/components/link-check-spa';
import { MultiSearchListItem, StyledLink } from './styled';

type MultiSearchLinkProps = {
  href: string;
  item: string;
  itemNumber: number;
  isItemSelected: boolean;
  isItemInCurrentPage: boolean;
} & RefAttributes<HTMLLIElement>;

type SpaLinkProps = {
  onClick?: () => void;
  to: string;
};

const SpaLink: FC<SpaLinkProps> = createSPALink(Link, ({ onClick, href, ...restProps }) => ({
  onClick: onClick,
  to: href,
  ...restProps,
}));

const MultiSearchLink: FC<MultiSearchLinkProps> = forwardRef<HTMLLIElement, MultiSearchLinkProps>(
  ({ href, item, itemNumber, isItemInCurrentPage, isItemSelected }, forwardedRef) => {
    return (
      <MultiSearchListItem
        key={`listItem${itemNumber}`}
        data-auto="multisearch-results-list-item"
        ref={forwardedRef}
        isItemInCurrentPage={isItemInCurrentPage}
        title={item}
      >
        <StyledLink
          variant="textButton"
          buttonVariant={SECONDARY}
          href={href}
          domRef={forwardedRef}
          spaLink={SpaLink}
          isItemSelected={isItemSelected}
        >
          {item}
        </StyledLink>
      </MultiSearchListItem>
    );
  },
);

export default MultiSearchLink;
