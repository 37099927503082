import React from 'react';
import { StyledContentStampWrapper } from './styled';
import ContentStamp from '@ddsweb/content-stamps';

interface DOMEvent<T extends HTMLElement> extends Event {
  readonly currentTarget: T;
}

export interface Props {
  url: string;
  eIndex: number;
  label: string;
  active: boolean;
  image: string;
  key: string;
  onClick: (currentEle: Element) => void;
}

export const ContentStampWrapper: React.FC<Props> = (props: Props) => {
  const handleClick = (event: DOMEvent<HTMLInputElement>): void => {
    event.stopPropagation();
    event.preventDefault();
    props.onClick(event.currentTarget);
  };

  return (
    <StyledContentStampWrapper
      onClick={handleClick}
      to={props.url}
      data-index={props.eIndex}
      aria-label={props.label}
      isSelected={props.active}
    >
      <ContentStamp
        href={props.url}
        image={{
          alt: props.label,
          src: props.image,
        }}
        link={{
          text: props.label,
          icon: 'none',
        }}
      />
    </StyledContentStampWrapper>
  );
};
