import React, { Component } from 'react';
import PropTypes from 'prop-types';
import analyticsBus from '#/analytics/analyticsBus';
import { basicEvent } from '#/analytics/types/basic';
import helpers from '#/lib/decorators/helpers';
import { connect } from '#/lib/render/connect-deep-compare';
import {
  clearRemoveFavourite,
  deleteFavouriteItem
} from '#/actions/results-actions';
import Modal from '#/components/shared/modal';
import ModalContent from '#/components/shared/modal/content';
import ModalActions from '#/components/shared/modal/actions';
import ModalButtonContainer from '#/components/shared/modal/actions/button-container';
import { closeModal } from '#/actions/ui-action-creators';
import { getFavToRemove } from '#/reducers/results';
import { getProductTitle } from '#/selectors/item';
import StringToNode from '#/components/shared/string-to-node';

const mapStateToProps = state => ({
  favToRemove: getFavToRemove(state)
});

@helpers(['t'])
@connect(mapStateToProps, {
  clearRemoveFavourite,
  deleteFavouriteItem,
  closeModal
})
export default class RemoveFavouritesModal extends Component {
  static propTypes = {
    clearRemoveFavourite: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    deleteFavouriteItem: PropTypes.func.isRequired,
    favToRemove: PropTypes.object,
    t: PropTypes.func.isRequired
  };

  closeModal = () => {
    this.props.clearRemoveFavourite();
    this.props.closeModal();
  };

  confirmRemove = () => {
    this.props.deleteFavouriteItem();

    basicEvent(analyticsBus, {
      type: 'favourites',
      value: 'delete favourites items',
      action: 'now'
    });

    this.props.closeModal();
  };

  render() {
    const { favToRemove } = this.props;

    if (!favToRemove) {
      return null;
    }

    const title = getProductTitle(favToRemove);

    return (
      <Modal
        closeModal={this.props.closeModal}
        title={this.props.t('product-list:remove-from-favorites-question')}
        className="remove-favourites-modal"
      >
        <ModalContent>
          <p>
            <StringToNode
              text={this.props.t('product-list:remove-some-favorites', {
                items: title
              })}
            />
          </p>
        </ModalContent>

        <ModalActions dual>
          <ModalButtonContainer>
            <button
              className="js-cancel-changes-no button button-secondary small"
              onClick={this.closeModal}
            >
              {this.props.t('no')}
            </button>
          </ModalButtonContainer>

          <ModalButtonContainer>
            <button
              className="js-cancel-changes-yes button button-primary small"
              onClick={this.confirmRemove}
            >
              {this.props.t('yes')}
            </button>
          </ModalButtonContainer>
        </ModalActions>
      </Modal>
    );
  }
}
