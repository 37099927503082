import { request } from '../lib/client-fetch';
import {
  COUPON_FORM_SUBMITTING_START,
  COUPON_FORM_RESET,
  COUPON_SUBMITTING_START,
  COUPON_SUBMITTING_STOP,
  ADD_COUPON,
  DESELECT_COUPON,
  REMOVE_COUPON,
  SELECT_COUPON,
  UPDATE_COUPON
} from '../constants/action-types';
import { getLanguageLink } from '../reducers/app';
import { getCoupon } from '#/selectors/trolley';
import { getTrolley } from './trolley/trolley-action-creators';
import commonActionRejectionsHandler from './common-action-rejections-handler';
import {
  emitCouponEvent,
  emitCouponErrorEvent
} from '../analytics/types/coupon';

function makeCouponRequest(config) {
  const {
    type = 'post',
    url,
    body,
    preRequest = () => {},
    onSuccess = () => {},
    onFail = () => {}
  } = config;

  return function(dispatch, getState) {
    preRequest(dispatch);
    dispatch({
      type: COUPON_SUBMITTING_START,
      code: config.couponCode
    });

    const options = body ? { body: JSON.stringify(body) } : {};

    return request[type](getLanguageLink(getState(), url), options)
      .then(response => {
        if (!response.errorCode) {
          const getTrolleyParams = {
            forceUpdate: false,
            includeCoupons: true
          };

          dispatch(
            getTrolley(() => {
              const coupon = getCoupon(getState(), config.couponCode);

              dispatch({
                type: config.action,
                coupon
              });
              onSuccess(coupon);
            }, getTrolleyParams)
          );
        } else {
          dispatch({
            type: config.action,
            error: response.errorCode,
            message: response.message || response.errorCode || ''
          });
          dispatch({
            type: COUPON_SUBMITTING_STOP,
            code: config.couponCode
          });
          onFail(response);
        }
      })
      .catch(rej => {
        commonActionRejectionsHandler(rej, dispatch);
        dispatch({
          type: COUPON_FORM_RESET
        });
        dispatch({
          type: COUPON_SUBMITTING_STOP,
          code: config.couponCode
        });
      });
  };
}

export function addCoupon(code) {
  return makeCouponRequest({
    couponCode: code,
    action: ADD_COUPON,
    url: '/coupons',
    body: { code },
    preRequest: dispatch => {
      dispatch({
        type: COUPON_FORM_SUBMITTING_START
      });
    },
    onSuccess: coupon => {
      if (coupon) {
        const fitted = coupon.qualified && coupon.selected;

        emitCouponEvent(coupon, 'add', fitted);
      }
    },
    onFail: response => {
      emitCouponErrorEvent(response);
    }
  });
}

export function removeCoupon(code) {
  return makeCouponRequest({
    couponCode: code,
    action: REMOVE_COUPON,
    type: 'del',
    url: `/coupons/${code}`
  });
}

export function selectCoupon(code) {
  return makeCouponRequest({
    couponCode: code,
    action: SELECT_COUPON,
    url: `/coupons/${code}`,
    body: { select: true },
    onSuccess: coupon => {
      coupon && emitCouponEvent(coupon, 'select', true);
    },
    onFail: response => {
      emitCouponErrorEvent(response);
    }
  });
}

export function deselectCoupon(code) {
  return makeCouponRequest({
    couponCode: code,
    action: DESELECT_COUPON,
    url: `/coupons/${code}`,
    body: { select: false },
    onSuccess: coupon => {
      coupon && emitCouponEvent(coupon, 'deselect', false);
    },
    onFail: response => {
      emitCouponErrorEvent(response);
    }
  });
}

export function updateCoupon(code) {
  return {
    type: UPDATE_COUPON,
    code
  };
}
