import styled from 'styled-components';
import { spacing } from '@ddsweb/selectors';
import { media } from '@ddsweb/foundation';
import { Grid, Row, Column } from '@ddsweb/grid';
import { BLUE, GREY_MID_2, WHITE } from '@ddsweb/constants';

export const StyledContentStampContainer = styled.div`
  margin-bottom: ${spacing.xl};
  padding-bottom: ${spacing.sm};
  background-color: ${WHITE};
  border: 1px solid ${GREY_MID_2};
  display: flex;
  flex-direction: column;
  align-items: center;
  ${media.belowTablet` 
    margin: 6px 6px ${spacing.xl};
`};
`;

export const StyledContentStampGrid = styled(Grid)`
  background-color: ${WHITE};
  overflow: hidden;
  height: 168px;
`;

export const StyledContentStampRow = styled(Row)`
  row-gap: 28px;
  padding: ${spacing.md} ${spacing.sm};
`;

export const StyledContentStampColumn = styled(Column)`
  height: fit-content;
  margin: 0;
  border-bottom: ${(props: { isSelected: boolean }): string => (props.isSelected ? `4px solid ${BLUE}` : 'none')};

  .ddsweb-content-stamp__container {
    border: none;
  }

  .ddsweb-content-stamp__responsive-image {
    min-height: 0;
    height: 80px;
    width: 80px;
    margin-left: 0;
  }

  .ddsweb-content-stamp__image-container {
    display: flex;
    justify-content: center;
  }

  .ddsweb-content-stamp__link-container {
    justify-content: center;
    text-align: center;
  }

  .ddsweb-content-stamp__image-container,
  .ddsweb-content-stamp__panel-container {
    background-color: ${WHITE};
    padding: ${spacing.xs} 0;
  }

  .ddsweb-content-stamp__panel-container {
    padding-top: 0;
  }

  .ddsweb-content-stamp__link {
    color: ${(props: { isSelected: boolean }): string => (props.isSelected ? `${BLUE}` : 'default')};
  }

  .ddsweb-link__text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipses;
  }
`;
