import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from '#/lib/render/connect-deep-compare';
import BrowserWarning from '#/components/headers/browser-warning';
import CookiePolicy from '../../../shared/cookie-policy';
import BrandHeader from '../brand-header';
import UtilityHeader from '../utility-header';
import SkipLinks from '../../../skip-links';
import helpers from '#/lib/decorators/helpers';
import {
  getHasAcceptedCookiePolicy,
  getHasAcceptedAppBanner,
  getLanguageLink
} from '#/reducers/app';
import { getShowBrowserWarning } from '#/reducers/app';
import { getIsUserAuthenticated } from '#/reducers/user';
import { getStickyBarName } from '#/reducers/ui';
import { getDisplayCookieInterrupt } from '#/reducers/ui';
import HeaderWrapperContainer from '#/experiments/oop-1662/components/headers/header/header-wrapper-container/';
import AppBar from '../app-bar';
import { defaultTheme, ThemeProvider } from '@ddsweb/theme';
import { getDefaultThemeOverrides } from '#/components/styles/default-theme-provider-overrides';

/* BrandHeader should be sticky when AppBanner or cookiePolicy are not present in homepage,
   or on other pages when AppBanner has not been accepted and cookiePolicy is not present */
export const mapStateToProps = (state, ownProps) => {
  const { c: config } = ownProps;
  return {
    extendSlotPrimaryButtonLink: getLanguageLink(state, '/trolley'),
    hasAppBanner:
      !config('appBanner:bannerEnabled') || !getHasAcceptedAppBanner(state),
    hasCookieBanner: !getHasAcceptedCookiePolicy(state),
    displayCookieInterrupt: getDisplayCookieInterrupt(state),
    hasUtilityHeader: config('showUtilityHeader'),
    menuOpen: !!state.ui.menuOpen,
    isUserAuthenticated: getIsUserAuthenticated(state),
    showBrowserWarning:
      config('enableBrowserWarning') && getShowBrowserWarning(state),
    stickyBarName: getStickyBarName(state)
  };
};

@helpers(['c'])
@connect(mapStateToProps)
export default class HeaderWrapper extends PureComponent {
  static propTypes = {
    c: PropTypes.func.isRequired,
    cmsNav: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    extendSlotPrimaryButtonLink: PropTypes.string.isRequired,
    groceryTabInOpenState: PropTypes.bool,
    hasAppBanner: PropTypes.bool.isRequired,
    hasCookieBanner: PropTypes.bool,
    isHomePage: PropTypes.bool.isRequired,
    isUserAuthenticated: PropTypes.bool.isRequired,
    menuOpen: PropTypes.bool.isRequired,
    noOfPendingOrders: PropTypes.number,
    showBrowserWarning: PropTypes.bool.isRequired,
    showMiniTrolley: PropTypes.bool,
    stickyBarName: PropTypes.string,
    taxonomyState: PropTypes.object.isRequired
  };

  isBrandHeaderSticky() {
    const {
      displayCookieInterrupt,
      hasCookieBanner,
      menuOpen,
      hasAppBanner,
      isHomePage,
      c: config
    } = this.props;
    const cookieBanner = config('cookiePreferences')
      ? displayCookieInterrupt
      : hasCookieBanner;
    return !cookieBanner && !menuOpen && (hasAppBanner || !isHomePage);
  }

  render() {
    const {
      hasUtilityHeader,
      showBrowserWarning,
      showMiniTrolley,
      cmsNav,
      groceryTabInOpenState,
      noOfPendingOrders,
      taxonomyState,
      c: config
    } = this.props;

    const isSticky = this.isBrandHeaderSticky();
    const isCookiePreferences = config('cookiePreferences');
    const isGlobalHeader = config('isGlobalHeader');

    return (
      <ThemeProvider
        theme={isGlobalHeader ? defaultTheme : getDefaultThemeOverrides()}
      >
        <HeaderWrapperContainer>
          <SkipLinks
            includeMiniTrolleyLink={showMiniTrolley}
            includeSearchLink
          />
          {!isCookiePreferences && <CookiePolicy />}
          {showBrowserWarning && <BrowserWarning />}
          {isGlobalHeader ? (
            <AppBar data-testid="app-bar" />
          ) : (
            hasUtilityHeader && <UtilityHeader isSticky={isSticky} />
          )}
          <BrandHeader
            isSticky={!isGlobalHeader && isSticky}
            cmsNav={cmsNav}
            groceryTabInOpenState={groceryTabInOpenState}
            noOfPendingOrders={noOfPendingOrders}
            taxonomyState={taxonomyState}
          />
        </HeaderWrapperContainer>
      </ThemeProvider>
    );
  }
}
