import { parse } from 'url';
import fetch from 'isomorphic-fetch';
import { AbortController } from 'abortcontroller-polyfill/dist/cjs-ponyfill';
import { logApiRequestEvent } from '#/lib/apm';

export function inferName(url, options = {}) {
  if (options.headers && options.headers['mango-qname']) {
    return `graphQL${options.headers['mango-qname']}`;
  } else if (options.requestName) {
    return options.requestName;
  }

  const rules = [
    {
      match: /^\/api\/v\d+\/buy-lists\//,
      namer: () => 'buylists'
    },
    {
      match: /^\/api\/v\d+\/products\//,
      namer: () => 'products'
    },
    {
      match: /^(?:\/api)?\/v\d+\/trolley\/coupons\//,
      namer: () => 'trolleyCoupons'
    },
    {
      match: /^\/v\d+\/pages\/i?ghs-\w{2}\/(\w+)/,
      namer(path) {
        const match = path.match(/^\/v\d+\/pages\/i?ghs-\w{2}\/(\w+)/);

        if (match !== null) {
          return `dcs${match[1][0].toUpperCase()}${match[1]
            .slice(1)
            .toLowerCase()}`;
        }

        return null;
      }
    },
    {
      match: /^\/json\/\d+|\/datafiles\//,
      namer: () => 'optimizely'
    },
    {
      match: /^\/digitalcontent\/v\d+\/ugc\/ghs-uk\/reviews\/\d+?$/,
      namer: () => 'reviews'
    },
    {
      match: /^\/card-migration-stores/,
      namer: () => 'paymentMigration'
    },
    {
      match: /^(?:\/api)?\/v\d+\//g,
      namer(path) {
        const match = path.match(/^(?:\/api)?\/v\d+\/(\D+)/);

        if (match !== null) {
          const segments = match[1].split(/[-\/]/);

          return (
            segments[0].toLowerCase() +
            segments
              .slice(1)
              .map(
                word =>
                  (word[0] || '').toUpperCase() + word.slice(1).toLowerCase()
              )
              .join('')
          );
        }

        return null;
      }
    }
  ];

  for (const { match, namer } of rules) {
    if (match.test(url)) {
      const name = namer(url);

      // App D gets funny with certain characters in the key, so only allow alphanumerics.
      if (name !== null && /^[\w\d]+$/.test(name)) {
        return name;
      }
    }
  }

  return 'unknown';
}

export default function(url, options = {}) {
  const { pathname: path, host } = parse(url);

  const name = options?.graphqlQueryName || inferName(path, options);
  const start = Date.now();

  const { timeout } = options;
  let timeoutController;
  let timeoutId;

  if (timeout) {
    timeoutController = new AbortController();
    timeoutId = setTimeout(() => {
      timeoutController.abort();
    }, options.timeout);
  }

  return fetch(url, {
    ...options,
    signal: timeoutController?.signal
  })
    .then(res => {
      if (timeout) {
        clearTimeout(timeoutId);
      }

      const responseTimeMs = Date.now() - start;

      const eventData = {
        apiRequestTraceId: options?.headers?.traceid,
        atrc: options?.atrc,
        region: options?.headers?.region,
        requestHost: host,
        requestMethod: options?.method || 'GET',
        requestName: name,
        requestPath: path,
        responseStatus: res.status,
        responseTimeMs,
        traceId: options?.requestTraceID,
        customAttributes: {
          consumer: options?.consumer,
          dco: options?.DCO,
          currentPath: options?.currentPath,
          userAgent: options?.userAgent
        }
      };
      logApiRequestEvent(eventData);
      return res;
    })
    .catch(err => {
      if (timeoutController?.signal?.aborted) {
        throw new Error(`Request Timeout after ${timeout}ms`);
      }

      throw err;
    });
}
