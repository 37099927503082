import styled from 'styled-components';
import { Text } from '@beans/text';
import { spacing, colors } from '@beans/selectors';
import { media } from '@beans/foundation';
import { Heading } from '@beans/heading';

export const StyledBookASlotAccordion = styled.div`
  padding: ${spacing.lg} ${spacing.lg} ${spacing.sm};
  border: 1px solid ${colors.lines};
  border-radius: ${spacing.xs};
  gap: ${spacing.lg};
  &.--slot-ui-reskin {
    border-radius: 0;
    margin-bottom: ${spacing.lg};
    gap: ${spacing.sm};
    padding: ${spacing.lg} ${spacing.md} 0;
  }
  display: flex;
  flex-direction: column;

  @media (max-width: 991px) {
    padding: 0;
  }

  .book-a-slot-accordion-container {
    border-bottom: none;
    padding: 0;

    @media (max-width: 991px) {
      border: none;
    }
  }

  .book-a-slot-accordion-header {
    button {
      margin: 0;
      padding: 0;
    }

    @media (max-width: 991px) {
      margin: 0 ${spacing.lg};
      border-top: 1px solid ${colors.lines};
    }
    &.book-a-slot-accordion-header--slot-ui-reskin {
      margin: 0;
    }
  }

  .book-a-slot-accordion-panel {
    // accordion is expanded
    &[aria-hidden='false'] {
      overflow: visible;
    }

    > div {
      padding: 0;
    }
  }

  .selected-slot-map {
    border: none;
  }
`;

export const StyledBookASlotAccordionAddress = styled.div`
  display: flex;
  svg {
    flex-shrink: 0;
  }
  @media (max-width: 991px) {
    padding: ${spacing.lg} ${spacing.lg} 0;
    ${({ showSlotUIReskin }) =>
      showSlotUIReskin ? 'padding: 0;' : 'padding: 20px 20px 0;'}
  }
`;

export const StyledBookASlotAccordionAddressInfo = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export const StyledAddressMapWrapper = styled.div`
  ${media.belowMobileLarge`display: none`};
  flex-basis: 50%;
  display: flex;
  min-height: 192px;
  position: relative;
  .static-map {
    background-size: inherit !important;
  }
`;

export const StyledBookASlotAccordionHeading = styled(Heading)`
  color: ${colors.base};
`;

export const StyledBookASlotAccordionText = styled(Text)`
  color: ${colors.grayscale};
`;

export const StyledBookASlotAccordionHeader = styled(Heading)`
  display: inline;
  margin-right: 5px;
`;

export const StyledBookASlotAccordionAddressInfoTitle = styled(
  StyledBookASlotAccordionHeading
)`
  margin-bottom: ${spacing.md};
`;

export const StyledBookASlotAccordionAddressInfoContentDetailsHeading = styled(
  StyledBookASlotAccordionHeading
)`
  margin-bottom: ${spacing.xs};
`;

export const StyledBookASlotAccordionAddressInfoContent = styled.div`
  display: flex;
  gap: ${spacing.md};
`;

export const StyledBookASlotAccordionAddressInfoContentDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const StyledTitle = styled(Heading)`
   {
    margin-bottom: ${spacing.md};
  }
`;
