import React from 'react';
import styled from 'styled-components';
import helpers from '#/lib/decorators/helpers';
import { colors, base } from '@ddsweb/selectors';

const StyledNameGreeter = styled('div')`
  padding: 0 20px;
  height: 100%;
  display: flex;
  align-items: center;
  color: ${colors.inverse};
  font-family: ${base.fontFamily};
  font-weight: bold;
`;

const getTruncatedString = (string, length = 15, ending = '...') => {
  if (string.trim().length > length) {
    const substring = string.substring(0, length - ending.length);
    return `${substring.trim()}${ending.trim()}`;
  }
  return string;
};

const getTitle = (title, translate) =>
  title
    ? translate('common:display-name-title.' + title.trim().toLowerCase())
    : '';

const NameGreeter = props => {
  const { id, firstName, lastName, title, t: translate } = props;
  return (
    <StyledNameGreeter id={id}>
      {translate('account:homepage-greet-name', {
        firstName: getTruncatedString(firstName),
        lastName: getTruncatedString(lastName),
        title: getTitle(title, translate)
      })}
    </StyledNameGreeter>
  );
};

export default helpers(['t'])(NameGreeter);
