import React, { Component } from 'react';
import { compose } from 'react-recompose';
import { ConnectedProps } from 'react-redux';
import { connect } from '#/lib/render/connect-deep-compare';
import {
  getDeliveryThresholds,
  getBookedSlot,
  getTrolleyShoppingMethod,
  getItems,
  getIsTrolleyEmpty,
} from '#/selectors/trolley';
import { getCurrency, getLanguageLink, getLanguage, getCurrentUrl } from '#/reducers/app';
import { getDeliveryCharge, getDeliverySlot } from '#/selectors/slot';
import { getShowSlotUIReskinV2 } from '#/experiments/oop-2205/selectors';
import {
  mutateFulfilment,
  showOrderTypeChangeWarning as openChangeOrderTypeWarningModal,
} from '#/actions/slot-action-creators';
import OverlaySpinner from '#/components/overlay-spinner';
import { BOOK, UNBOOK } from '#/constants/mutate-fulfilment-types';
import { ShoppingMethod } from '#/constants/shopping-methods';
import { isOnDemandShoppingMethod } from '#/lib/shopping-method-util';
import { slotCurrentlyBooked } from '#/lib/slot/slot-utils';
import { getHasAcceptedSlotWarningTypes } from '#/reducers/ui';
import { ORDER_TYPE_CHANGE, BOOK_WHOOSH_SLOT } from '#/constants/slot-warning-modal-types';
import { hasItems } from '#/lib/trolley/trolley-utils';
import InfoBannerSection from './info-banner-section';
import OndemandInfoBannerSection from './ondemand/ondemand-info-banner-section';
import BookDeliverySection from './book-delivery-section';
import OndemandBookDeliverySection from './ondemand/ondemand-book-delivery-section';
import { TDeliverySlot, TBookedSlot, TTranslateFn, TLanguageLink } from './index.defs';
import { ContentWrapper, ContentWrapperOop2205, ShowLargeWrapper } from './styled';
import { getAvailableShoppingMethods } from '#/selectors/available-shopping-methods';
import { TTranslateFunc } from '#/lib/records/helpers.defs';

const mapStateToProps = (state: Store, { shoppingMethod }) => {
  const bookedSlot = getBookedSlot(state);
  const trolleyShoppingMethod = getTrolleyShoppingMethod(state) as ShoppingMethod;
  const trolleyHasItems = hasItems(getItems(state));
  const showChangeOrderTypeWarningModal =
    !getHasAcceptedSlotWarningTypes(state, ORDER_TYPE_CHANGE) && slotCurrentlyBooked(bookedSlot) && trolleyHasItems;
  const isOnDemandShopping = isOnDemandShoppingMethod(shoppingMethod);
  const deliverySlot = getDeliverySlot(state, shoppingMethod);
  const charge = getDeliveryCharge(state, shoppingMethod);
  const isSlotBooked = shoppingMethod === trolleyShoppingMethod && slotCurrentlyBooked(bookedSlot);

  return {
    charge,
    currentUrl: getCurrentUrl(state),
    currency: getCurrency(state),
    deliveryThresholds: getDeliveryThresholds(state),
    availableShoppingMethods: getAvailableShoppingMethods(state),
    deliverySlot,
    language: getLanguage(state),
    languageLink: (url: string): string => getLanguageLink(state, url),
    bookedSlot,
    showChangeOrderTypeWarningModal,
    isOnDemandShopping,
    trolleyShoppingMethod,
    isSlotBooked,
    isSlotUIReskin: getShowSlotUIReskinV2(state),
    isTrolleyEmpty: getIsTrolleyEmpty(state),
  };
};

const mapDispatchToProps = { mutateFulfilment, openChangeOrderTypeWarningModal };

const connector = connect(mapStateToProps, mapDispatchToProps);

type HelperProps = {
  t: TTranslateFunc;
};

type TParams = {
  slotId?: string;
  action: typeof BOOK | typeof UNBOOK;
};

type TDeliveryContentProps = {
  charge: number | null | undefined;
  t: TTranslateFn;
  deliveryThresholds: {
    deliveryThreshold?: number;
  };
  deliverySlot: TDeliverySlot;
  language: string;
  availableShoppingMethods: string[];
  currency: {
    isoCode: string;
    symbol: string;
  };
  areSlotsPending: boolean;
  bookedSlot?: TBookedSlot;
  shoppingMethod: ShoppingMethod;
  currentUrl: string;
  languageLink: TLanguageLink;
  isSlotExpired: boolean;
  isSlotUIReskin: boolean;
};

type TProps = HelperProps & ConnectedProps<typeof connector> & TDeliveryContentProps;
class DeliveryContentSection extends Component<TProps, { isBooked: boolean }> {
  constructor(props: TProps) {
    super(props);

    const { shoppingMethod, bookedSlot, trolleyShoppingMethod } = props;

    this.state = {
      isBooked: shoppingMethod === trolleyShoppingMethod && slotCurrentlyBooked(bookedSlot),
    };
  }

  handleSubmit = (payload: TParams) => (e: React.SyntheticEvent): void => {
    e.preventDefault();

    const {
      openChangeOrderTypeWarningModal,
      showChangeOrderTypeWarningModal,
      isOnDemandShopping,
      isTrolleyEmpty,
    } = this.props;

    const actionSlot = (): void => {
      const { mutateFulfilment } = this.props;
      const { isBooked } = this.state;

      mutateFulfilment(payload, true).then(res => {
        this.setState({
          isBooked: res.hasError ? isBooked : !isBooked,
        });
      });
    };

    if (showChangeOrderTypeWarningModal) {
      openChangeOrderTypeWarningModal({ callback: actionSlot, modalType: ORDER_TYPE_CHANGE, ...payload });
    } else if (isOnDemandShopping && !isTrolleyEmpty) {
      openChangeOrderTypeWarningModal({ callback: actionSlot, modalType: BOOK_WHOOSH_SLOT, ...payload });
    } else {
      actionSlot();
    }
  };

  render(): JSX.Element | null {
    const { props, handleSubmit } = this;
    const {
      charge,
      areSlotsPending,
      shoppingMethod,
      trolleyShoppingMethod,
      availableShoppingMethods,
      isOnDemandShopping,
      languageLink,
      deliveryThresholds,
      deliverySlot,
      language,
      currency,
      bookedSlot,
      currentUrl,
      isSlotExpired,
      isSlotBooked,
      isSlotUIReskin,
    } = props;
    if (isOnDemandShopping && isSlotBooked && !isSlotExpired) return null;

    const InfoBanner = isOnDemandShopping ? OndemandInfoBannerSection : InfoBannerSection;

    const infoBannerSection = (
      <InfoBanner
        availableShoppingMethods={availableShoppingMethods}
        languageLink={languageLink}
        shoppingMethod={shoppingMethod}
      />
    );

    const bookDeliverySlotSectionProps = {
      charge,
      handleSubmit,
      currency,
      language,
      bookButtonActionUrl: languageLink('/slots/mutate'),
      currentUrl,
      deliveryThresholds,
    };

    const DeliverySection = isOnDemandShopping ? OndemandBookDeliverySection : BookDeliverySection;

    const bookDeliverySlotSection = (
      <DeliverySection
        commonProps={bookDeliverySlotSectionProps}
        bookedSlot={bookedSlot}
        shoppingMethod={shoppingMethod}
        trolleyShoppingMethod={trolleyShoppingMethod}
        isBooked={isSlotBooked}
        deliverySlot={deliverySlot}
        isSlotExpired={isSlotExpired}
      />
    );

    const Wrapper = isSlotUIReskin ? ContentWrapperOop2205 : ContentWrapper;

    return (
      <Wrapper>
        <OverlaySpinner isLoading={areSlotsPending} />
        {bookDeliverySlotSection}
        <ShowLargeWrapper>{infoBannerSection}</ShowLargeWrapper>
      </Wrapper>
    );
  }
}

const enhance = compose<TProps, {}>(connector);

export default enhance(DeliveryContentSection);
