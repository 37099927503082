import React, { Component } from 'react';
import PropTypes from 'prop-types';
import helpers from '#/lib/decorators/helpers';
import { getLanguageLink } from '#/reducers/app';
import { connect } from '#/lib/render/connect-deep-compare';
import SimpleButton from '#/components/shared/simple-button';
import { getCheckoutEntryPoint } from '#/utils/checkout-utils';
import analyticsBus from '#/analytics/analyticsBus';
import { basicEvent } from '#/analytics/types/basic';
import {
  CONTINUE_SHOPPING,
  START_SHOPPING,
  SHOP_FAVORITES,
  SLOTS,
  DELAY
} from '#/analytics/constants';
import { openModal } from '#/actions/ui-action-creators';
import { CANCEL_SLOT_BOOKING_MODAL } from '#/constants/modal-names';
import { getFavoritesLanguageLink } from '#/utils/favorite-utils';

const CONTINUE_SHOPPING_BUTTON = 'continue-shopping-button';
const START_SHOPPING_BUTTON = 'start-shopping-button';

const mapStateToProps = (state, { startShoppingUrl }) => ({
  checkoutEntryPoint: getCheckoutEntryPoint(state),
  startShoppingUrl: startShoppingUrl || getLanguageLink(state, '/'),
  favoritesPageLink: getFavoritesLanguageLink(state, '/favorites')
});

@connect(mapStateToProps, { openModal })
@helpers(['t'])
export default class SlotContextButtons extends Component {
  static propTypes = {
    checkoutEntryPoint: PropTypes.string.isRequired,
    favoritesPageLink: PropTypes.string.isRequired,
    hasSlot: PropTypes.bool.isRequired,
    hasTrolleyItems: PropTypes.bool.isRequired,
    l: PropTypes.func.isRequired,
    shopFavoritesCTA: PropTypes.bool.isRequired,
    startShoppingUrl: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired
  };

  continueShoppingClickHandler = () => {
    this.emitAnalyticsEvent(CONTINUE_SHOPPING);
  };

  startShoppingClickHandler = () => {
    this.emitAnalyticsEvent(START_SHOPPING);
  };

  shopFavClickHandler = () => {
    this.emitAnalyticsEvent(SHOP_FAVORITES);
  };

  emitAnalyticsEvent(value) {
    basicEvent(analyticsBus, {
      type: SLOTS,
      value: value,
      action: DELAY
    });
  }

  openCancelSlotModal = event => {
    event.preventDefault();
    this.props.openModal(CANCEL_SLOT_BOOKING_MODAL);
  };

  renderCheckoutButton() {
    const { checkoutEntryPoint, hasSlot, t: translate } = this.props;

    return (
      <SimpleButton
        buttonClass="button--sticky"
        buttonText={translate('slots:common.click-checkout')}
        dataAuto="checkout-button"
        href={checkoutEntryPoint}
        disabled={!hasSlot}
      />
    );
  }

  render() {
    const {
      t: translate,
      startShoppingUrl,
      hasTrolleyItems,
      favoritesPageLink,
      shopFavoritesCTA
    } = this.props;
    let buttonText;
    let clickHandler;
    let dataAuto;

    if (hasTrolleyItems) {
      buttonText = translate('slots:common.click-continue-shopping');
      clickHandler = this.continueShoppingClickHandler;
      dataAuto = CONTINUE_SHOPPING_BUTTON;
    } else {
      buttonText = translate('slots:common.start-shopping');
      clickHandler = this.startShoppingClickHandler;
      dataAuto = START_SHOPPING_BUTTON;
    }

    return (
      <>
        {shopFavoritesCTA && (
          <SimpleButton
            buttonText={translate('trolley:trolley-favourites.button-text')}
            href={favoritesPageLink}
            type="primary"
            dataAuto="shop-favourites-button"
            onClick={this.shopFavClickHandler}
          />
        )}
        <SimpleButton
          buttonText={buttonText}
          href={startShoppingUrl}
          type="secondary"
          dataAuto={dataAuto}
          onClick={clickHandler}
        />
        {hasTrolleyItems && this.renderCheckoutButton()}
      </>
    );
  }
}
