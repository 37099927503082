import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default class SelectableListItem extends PureComponent {
  static propTypes = {
    index: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
    onMouseOut: PropTypes.func.isRequired,
    onMouseOver: PropTypes.func.isRequired,
    selected: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    isSearchContent: PropTypes.bool
  };

  static defaultProps = {
    selected: false
  };

  constructor(...args) {
    super(...args);

    this.handleClick = this.handleClick.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
  }

  handleClick() {
    this.props.onClick(this.props.index, this.props.value);
  }

  handleMouseDown(e) {
    e.preventDefault();
  }

  handleMouseOver() {
    this.props.onMouseOver(this.props.index, this.props.value);
  }

  handleMouseOut() {
    this.props.onMouseOut(this.props.index, this.props.value);
  }

  getListValue = () => {
    const { value, isSearchContent } = this.props;
    return isSearchContent ? value.name : value;
  };

  render() {
    const { selected, isSearchContent } = this.props;
    const listValue = this.getListValue();
    return (
      <li
        className={classnames('selectable-list__menu-item', {
          'selectable-list__menu-item--selected': selected && !isSearchContent
        })}
        onClick={this.handleClick}
        onMouseDown={this.handleMouseDown}
        onMouseOver={this.handleMouseOver}
        onMouseOut={this.handleMouseOut}
      >
        <span
          aria-hidden={!selected}
          aria-live="assertive"
          className={classnames('selectable-list__menu-item-text', {
            'menu-item-text-link': selected && isSearchContent
          })}
        >
          {listValue}
        </span>
      </li>
    );
  }
}
