import React from 'react';
import { compose } from 'react-recompose';
import { ConnectedProps } from 'react-redux';
import Tag from '@ddsweb/tag';
import helpers from '#/lib/decorators/helpers';
import { TTranslateFunc } from '#/lib/records/helpers.defs';
import { connect } from '#/lib/render/connect-deep-compare';
import { getNewInBrowseVariant, getShouldRenderNewTag } from '#/experiments/oop-2022/selectors';
import { StyledNodeName } from '#/experiments/oop-2022/styled';

type HelperProps = {
  t: TTranslateFunc;
};

type ParentProps = {
  nodeName: string;
};

const mapStateToProps = (state: Store): { browseVariant: string } => ({
  browseVariant: getNewInBrowseVariant(state),
});

const connector = connect(mapStateToProps, {});
type Props = HelperProps & ConnectedProps<typeof connector> & ParentProps;

const NewBrowseLinkText = (props: Props): React.ReactElement | string => {
  const { t: translate, nodeName, browseVariant } = props;

  if (getShouldRenderNewTag(browseVariant)) {
    return (
      <>
        <Tag variant="new">{translate('shop-new')}</Tag>
        <StyledNodeName>{` ${translate('shop-in')} ${nodeName}`}</StyledNodeName>
      </>
    );
  }

  return `${translate('shop-new')} ${translate('shop-in')} ${nodeName} `;
};

const enhance = compose<Props, {}>(helpers(['t']), connector);
export default enhance(NewBrowseLinkText);
