import { createSelector } from 'reselect';
import { PLAN_ANYTIME, PLAN_MIDWEEK, MIDWEEK_APPLICABLE_DAYS } from '#/constants/delivery-saver';
import { CustomerState, DeliverySaverPlan } from '#/custom-typings/redux-store/customer.defs';
import { Trolley } from '#/lib/records/trolley.defs';
import { User } from '#/custom-typings/express/user.defs';

export default (state: CustomerState = { deliverySaverPlan: {}, firstName: null }): CustomerState => state;

type CustomerStore = {
  resources: CustomerState;
  trolley: Trolley;
  user: User;
};

export const getDefaultStateFromProps = (props: CustomerStore): CustomerState => {
  const { firstName } = props.user || {};
  const deliverySaverPlan = (props.trolley && props.trolley.subscription) || {};
  return {
    firstName,
    deliverySaverPlan,
  };
};

export const getCustomerFirstName = ({ customer: { firstName } }: Store): string | null => firstName;

export const getDeliverySaverPlan = ({ customer: { deliverySaverPlan } }: Store): DeliverySaverPlan =>
  deliverySaverPlan;

export const getDeliverySaverPlanName = createSelector(getDeliverySaverPlan, deliverySaverPlan => {
  return deliverySaverPlan?.planName;
});

export const hasDeliverySaverPlan = createSelector(getDeliverySaverPlan, deliverySaverPlan => {
  return !!deliverySaverPlan?.valid;
});

export const isAnytimeDeliverySaverPlan = createSelector(getDeliverySaverPlan, deliverySaverPlan => {
  return deliverySaverPlan?.planType === PLAN_ANYTIME;
});

export const isMidweekDeliverySaverPlan = createSelector(getDeliverySaverPlan, deliverySaverPlan => {
  return deliverySaverPlan?.planType === PLAN_MIDWEEK;
});

export const getMidweekDeliverySaverApplicableDays = (): number[] => MIDWEEK_APPLICABLE_DAYS;

export const getDeliverySaverApplicableDays = createSelector(getDeliverySaverPlan, deliverySaverPlan => {
  return deliverySaverPlan?.applicableDays || [];
});

export const hasFreeSameDayDelivery = createSelector(getDeliverySaverPlan, deliverySaverPlan => {
  return deliverySaverPlan?.freeSameDayDelivery || false;
});
