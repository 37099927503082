import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from '#/lib/render/connect-deep-compare';
import Select from '#/components/shared/select';
import helpers from '#/lib/decorators/helpers';
import formatPrice from '#/lib/string-formatting/number-formatter';
import DisplayOnlyInNoJs from '#/components/shared/display-only-in-no-js';
import { getCurrency, getLanguageLink } from '#/reducers/app';
import {
  getProductUnitOfMeasure,
  getProductCatchWeightList,
  getCatchWeight,
  getOriginalCatchWeight
} from '#/selectors/item';

const mapStateToProps = state => ({
  currency: getCurrency(state),
  formAction: getLanguageLink(state, '/products/change-catch-weight')
});

@helpers(['t'])
@connect(mapStateToProps)
export default class CatchWeightControl extends Component {
  static propTypes = {
    currency: PropTypes.object.isRequired,
    disableWeightChange: PropTypes.bool,
    formAction: PropTypes.string.isRequired,
    isItemRemoved: PropTypes.bool,
    item: PropTypes.object.isRequired,
    shouldOverridePreviousItem: PropTypes.bool,
    t: PropTypes.func.isRequired,
    updateCatchWeight: PropTypes.func.isRequired
  };

  static defaultProps = {
    disableWeightChange: false
  };

  constructor(props, context) {
    super(props, context);
    this.priceConfig = {
      ...this.props.currency,
      showAsciiSymbol: true,
      compactMode: true
    };
    this.state = {
      isItemUpdated: false
    };
  }

  componentWillReceiveProps() {
    this.setState({
      isItemUpdated: true
    });
  }

  shouldComponentUpdate(nextProps) {
    return !(
      this.props.shouldOverridePreviousItem &&
      this.props.isItemRemoved &&
      !nextProps.isItemRemoved
    );
  }

  renderReadOnlyControl(options) {
    const displayWeight = options.find(item => item.selected);

    if (!displayWeight) {
      return null;
    }

    return (
      <div className="catch-weight--dropdown-read-only">
        <span>{displayWeight.label}</span>
      </div>
    );
  }

  renderControl(options) {
    const {
      item: {
        product: { title, id }
      }
    } = this.props;
    return (
      <div>
        <div
          data-auto="catch-weight-dropdown"
          className="catch-weight--dropdown"
        >
          <label className="visually-hidden" htmlFor={id}>
            {this.props.t('product-tile:select-weight-of', {
              productTitle: title
            })}
          </label>

          <Select
            id={id}
            name="catchWeightChoice"
            options={options}
            onChange={this.props.updateCatchWeight}
          />
        </div>
        <DisplayOnlyInNoJs>
          <div className="catch-weight--btn-update">
            <button
              className={'button button-secondary'}
              type="submit"
              formAction={this.props.formAction}
            >
              {this.props.t('common:update')}
            </button>
          </div>
        </DisplayOnlyInNoJs>
      </div>
    );
  }

  render() {
    const { item } = this.props;
    const catchWeightList = getProductCatchWeightList(item) || [];

    const unitOfMeasure = getProductUnitOfMeasure(item);
    const defaultCatchWeight =
      this.props.shouldOverridePreviousItem && !this.state.isItemUpdated
        ? getOriginalCatchWeight(item)
        : getCatchWeight(item);

    const options = catchWeightList.map(({ price, weight }) => {
      const priceFormatted = formatPrice(price, this.priceConfig);

      return {
        key: weight,
        value: weight,
        label: `${weight}${unitOfMeasure}/${priceFormatted}`,
        selected: weight === defaultCatchWeight
      };
    });

    return (
      <div className="controls--catch-weight">
        {this.props.disableWeightChange
          ? this.renderReadOnlyControl(options)
          : this.renderControl(options)}
      </div>
    );
  }
}
