import React, { MouseEventHandler, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { ConnectedProps } from 'react-redux';
import { compose } from 'react-recompose';
import { css } from 'styled-components';
import { connect } from '#/lib/render/connect-deep-compare';
import { getIsDesktop } from '#/reducers/app';
import { StyledTooltip, StyledModal } from './styled';
import { debounce } from '#/utils/misc';
import { Ref } from '../common-types.defs';
import { setRefStyle, removeRefStyle } from '../helpers';
import { TooltipVariants } from '#/components/shared/tooltip/variants/constants';

type OwnState = {
  isDesktop: boolean;
};

const mapStateToProps = (state: Store): OwnState => ({
  isDesktop: getIsDesktop(state),
});

type OwnProps = {
  accessibleLabel?: string;
  id: string;
  isDesktop?: boolean;
  targetRef: Ref;
  boundingRef?: Ref;
  position?: string;
  children: React.ReactNode;
  tooltipWidth?: number;
  tooltipVariant?: typeof TooltipVariants.INFO | typeof TooltipVariants.DEFAULT;
  isOpen: boolean;
  onChange?: Function;
  onClick?: MouseEventHandler;
  styles?: typeof css;
  withOverlay?: boolean;
  resizeHandler?: Function;
  tooltipRootID?: string;
  portalRootID?: string;
  onUnmount?: Function;
  observerId?: string;
  bareButton?: boolean;
};

const connector = connect(mapStateToProps, {});

type TProps = OwnProps & ConnectedProps<typeof connector>;

const FloatingTooltip: React.FC<TProps> = ({
  accessibleLabel,
  id,
  isDesktop,
  targetRef,
  position,
  children,
  tooltipWidth,
  tooltipVariant,
  isOpen,
  onChange,
  onClick,
  styles,
  withOverlay,
  resizeHandler,
  tooltipRootID,
  boundingRef,
  portalRootID,
  onUnmount,
  observerId,
  bareButton = false,
}) => {
  const [refreshToken, setRefreshToken] = useState(0);
  let observer: MutationObserver;

  const handleWindowResize = debounce((e: React.SyntheticEvent) => {
    if (resizeHandler) {
      resizeHandler(e);
    }
  }, 100);

  const addWindowEventListener = (): void => {
    if (isDesktop) {
      window.addEventListener('resize', handleWindowResize);
    } else {
      window.addEventListener('orientationchange', handleWindowResize);
    }
  };

  const removeWindowEventListener = (): void => {
    if (isDesktop) {
      window.removeEventListener('resize', handleWindowResize);
    } else {
      window.removeEventListener('orientationchange', handleWindowResize);
    }
  };

  const onTooltipChange = (e: { action: string }): void => {
    const closed = e.action === 'close';
    if (closed) {
      removeWindowEventListener();
      if (onChange) {
        onChange(e);
      }
    }
  };

  const setFocus = (targetRef: Ref): void => {
    targetRef?.current?.focus();
  };

  useEffect(() => {
    addWindowEventListener();

    if (withOverlay) {
      setRefStyle(targetRef);
      setFocus(targetRef);
    }

    return (): void => {
      setFocus(targetRef);
      if (onUnmount) {
        onUnmount();
      }
      if (observer) {
        observer.disconnect();
      }

      removeWindowEventListener();
      if (withOverlay) {
        removeRefStyle(targetRef);
      }
    };
  }, []);

  const getElement = (id: string): HTMLElement => document.getElementById(id) as HTMLElement;

  if (!isOpen) return null;

  const isClientSide = process.env.CLIENT_SIDE;
  const portalContainerID = portalRootID || 'content';

  if (tooltipRootID && isClientSide && !getElement(tooltipRootID)) {
    if (observerId) {
      observer = new MutationObserver(function() {
        if (getElement(tooltipRootID)) {
          observer.disconnect();
          setRefreshToken(Math.random());
        }
      });
      observer.observe(document.getElementById(observerId) as HTMLElement, {
        attributes: false,
        childList: true,
        characterData: false,
        subtree: true,
      });
    }

    return null;
  }

  return (
    <div onClick={onClick} aria-hidden="true">
      <StyledTooltip
        styles={styles}
        variant={tooltipVariant}
        containerWidth={tooltipWidth}
        refreshToken={String(refreshToken)}
        id={id}
        accessibleLabel={accessibleLabel}
        open
        persist
        targetRef={targetRef}
        boundingRef={boundingRef || targetRef}
        position={position}
        onChange={onTooltipChange}
        supressTargetFocusOnClose
        ssr={!isClientSide}
        rootID={tooltipRootID}
        withOverlay={withOverlay}
        bareButton={bareButton}
      >
        {children}
      </StyledTooltip>
      {isClientSide &&
        withOverlay &&
        ReactDOM.createPortal(
          <StyledModal open ssr id={`tooltip-overlay-${id}`} onChange={onTooltipChange} />,
          document.getElementById(portalContainerID) || document.getElementsByName('body')[0],
        )}
    </div>
  );
};

const enhance = compose<TProps, OwnProps>(connector);
export default enhance(FloatingTooltip);
