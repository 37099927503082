import React from 'react';
import helpers from '#/lib/decorators/helpers';
import PropTypes from 'prop-types';
import TrolleySurchargeWarning from '#/components/trolley/trolley-warnings/surcharge-warning';
import SpendThresholdWarning from '#/components/trolley/trolley-warnings/spend-threshold-warning';
import {
  QUALIFY_FOR_FREE_DELIVERY,
  SPEND_MORE_FOR_FREE_DELIVERY,
  NOT_QUALIFY_FOR_FREE_DELIVERY
} from '#/constants/spend-threshold';

const Warning = props => {
  const {
    f: feature,
    spendThresholdWarning,
    surcharge,
    isAuthenticated
  } = props;
  if (surcharge && isAuthenticated) {
    const { bookedSlot, minimumBasketValue, subscription } = props;
    return (
      <TrolleySurchargeWarning
        minimumBasketValue={minimumBasketValue}
        subscription={subscription}
        surcharge={surcharge}
        bookedSlot={bookedSlot}
      />
    );
  } else if (feature('showFDOXMessaging') && spendThresholdWarning) {
    const { status } = props;
    return <SpendThresholdWarning status={status} />;
  } else {
    return <div className="trolley-surcharge-warning--no-show-block" />;
  }
};

Warning.propTypes = {
  bookedSlot: PropTypes.object,
  f: PropTypes.func.isRequired,
  guidePrice: PropTypes.number.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  minimumBasketValue: PropTypes.number,
  status: PropTypes.oneOf([
    QUALIFY_FOR_FREE_DELIVERY,
    SPEND_MORE_FOR_FREE_DELIVERY,
    NOT_QUALIFY_FOR_FREE_DELIVERY,
    false
  ]),
  subscription: PropTypes.object,
  surcharge: PropTypes.number
};

export default helpers(['f'])(Warning);
