import React from 'react';
import PropTypes from 'prop-types';

const Variation = ({ children }) => {
  const isValidElement = React.isValidElement(children);
  const hasNoChildren = React.Children.count(children) === 0;

  /* return early if variant has no children
   * may be required if one of the variants is to render nothing
   */

  if (hasNoChildren) {
    return null;
  }

  return !isValidElement ? (
    <React.Fragment>{children}</React.Fragment>
  ) : (
    children
  );
};

Variation.propTypes = {
  children: PropTypes.any,
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired
};

export default Variation;
