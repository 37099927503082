import { shouldShowRestOfShelf as shouldShowRestOfShelfUtil } from '#/lib/url/url-utils';
import { getLanguageLink } from '#/reducers/app';
import {
  getProductShelfName,
  getRestOfShelfUrl,
  hasSubstitution,
  getProductAisleId,
  getProductShelfId,
  generateSlugs,
  showLink as showProductLink,
} from '#/selectors/item';
import { Item } from '#/lib/records/item';
import { TConfigFunc } from '#/lib/records/helpers.defs';

export type TRestOfShelfBuyboxData = {
  shelfName?: string | null;
  restOfShelfText: string;
  href: string;
  accessibilityText: string;
};

type TTranslateFunc = (...args: any[]) => string;

export const shouldShowRestOfShelfButton = (item: Item, config: TConfigFunc, currentUrl: string): boolean => {
  if (config('product:disableRestOfShelfLink')) return false;

  const shelfId = getProductShelfId(item) || getProductAisleId(item) || null;

  if (
    !!shelfId &&
    !hasSubstitution(item) &&
    showProductLink(item, !!config('showProductUnavailableMessages'), currentUrl)
  ) {
    const { shelfIdSlug, aisleIdSlug } = generateSlugs(item);

    return shouldShowRestOfShelfUtil(decodeURIComponent(currentUrl), shelfId, shelfIdSlug, aisleIdSlug);
  }

  return false;
};

export const getRestOfShelfBuyboxData = (
  state: any,
  item: Item,
  translation: TTranslateFunc,
  config: any,
  currentUrl: string,
): TRestOfShelfBuyboxData | null => {
  if (shouldShowRestOfShelfButton(item, config, currentUrl)) {
    const shelfName = getProductShelfName(item);
    // The product Aisle Id fall back is for CE products
    const restOfShelfUrl = getRestOfShelfUrl(item) || `/categories/${getProductAisleId(item)}`;
    const restOfShelfText = getRestOfShelfText(item, translation);

    return {
      shelfName,
      restOfShelfText,
      href: getLanguageLink(state, restOfShelfUrl),
      accessibilityText: `${restOfShelfText} ${shelfName}`,
    };
  }

  return null;
};

export const getRestOfShelfText = (item: Item, translation: TTranslateFunc): string => {
  const shelfName = getProductShelfName(item);
  const restOfShelfText = shelfName
    ? translation('product-tile:rest-of-shelf-long', { shelfName })
    : translation('product-tile:more-in-this-category');

  return restOfShelfText;
};
