import { Items } from '#/custom-typings/redux-store/trolley.defs';
import { createItem } from '#/lib/records';
import { CustomerUnitChoice } from '#/lib/records/item.defs';
import { getProductId } from '#/selectors/item';
import { Item as ItemType } from '#/lib/records/item';

type OrderItems = {
  catchWeight: number;
  customerUnitChoice: CustomerUnitChoice;
  quantity: number;
  weight: number;
};

type OrderItemsMap = Map<string, OrderItems>;

function buildOrderItemsMap(orderItems: Array<ItemType> = []): OrderItemsMap {
  return new Map(
    orderItems.map(orderItem => {
      const { productItem, quantity, weight, customerUnitChoice } = orderItem;
      const { product, catchWeight } = productItem as ItemType;

      return [
        String(product.id),
        {
          catchWeight: product.averageWeight || catchWeight,
          customerUnitChoice,
          quantity,
          weight: weight || quantity,
        },
      ];
    }),
  ) as OrderItemsMap;
}

export default function overrideItemsWithOrderItems(items: Items, orderItems): Items {
  const orderQuantityMap = buildOrderItemsMap(orderItems);

  const newItems = new Map();

  items.forEach((item, id) => {
    const productId = getProductId(item);
    const previousOrderQuantity = orderQuantityMap.get(productId);
    const { quantity, customerUnitChoice, weight, catchWeight } = previousOrderQuantity as ItemType;
    const newItem = createItem({
      ...item,
      originalQuantity: quantity,
      originalCustomerUnitChoice: customerUnitChoice,
      originalProductWeight: weight,
      originalCatchWeight: catchWeight,
    });

    newItems.set(id, newItem);
  });

  return newItems;
}
