import querystring from 'querystring';
import slugify from 'slugify';
import url from 'url';
import setBrowserTransactionName from '../apm/set-browser-transaction-name';
import { trimTrailingSlash } from '../string-formatting/format';

/**
 * Given the `basePath` and `language`, will provide a Regex expression that will match the path and enable matching
 * with the groceryLang router path. Will match what it can from inputs, but if no params are provided, it will just
 * match the entire path
 *
 * @param basePath
 * @param language
 * @returns {string}
 */
export function getBasepathLanguageRegex(basePath = '', language = '') {
  const bathPathRegex =
    basePath && typeof basePath === 'string' ? `(?:\/${basePath})?` : '';
  const languageRegex =
    language && typeof language === 'string' ? `(?:\/${language})?` : '';

  return `^${bathPathRegex}${languageRegex}(.*)$`;
}

export function parseQueryString(fullUrl) {
  return url.parse(fullUrl, true).query;
}

export function getQueryParamByKey(fullUrl, key) {
  if (!fullUrl || !key) return null;
  const queryParams = parseQueryString(fullUrl);
  return queryParams?.[key];
}

export function updateParamsInUrl(fullUrl, paramsObj) {
  const parsedUrl = url.parse(fullUrl, true);

  delete parsedUrl.search;
  Object.entries(paramsObj).forEach(([param, value]) => {
    if (value === null) {
      delete parsedUrl.query[param];
    } else {
      parsedUrl.query[param] = value;
    }
  });

  return url.format(parsedUrl);
}

export function updateUrlHash(fullUrl, hashValue) {
  return url.resolve(fullUrl, '#' + hashValue);
}

export function getUrlHash(fullUrl) {
  const hashValue = url.parse(fullUrl).hash;

  return hashValue ? hashValue.substring(1) : null;
}

export function removeQueryString(fullUrl) {
  const parsedUrl = url.parse(fullUrl);

  delete parsedUrl.search;

  return url.format(parsedUrl);
}

export function matchPath(fullUrl, basePath, language, arrayOfPaths) {
  const url = trimTrailingSlash(removeQueryString(fullUrl));
  const basepathAndLanguageRegex = new RegExp(
    getBasepathLanguageRegex(basePath, language),
    'i'
  );

  return arrayOfPaths.some(
    path => url.match(basepathAndLanguageRegex)[1] === trimTrailingSlash(path)
  );
}

export const validateHost = (req, uri) => {
  if (!uri) return false;

  const siteUrlHost = req.site.domain;
  return url.parse(uri).hostname === siteUrlHost;
};

/**
 * Returns the current 'page' based off the currentUrl
 * e.g. '/groceries/en-GB/trolley' => 'trolley'
 *
 * @param pathName
 * @returns {string}
 */
export function getPageFromPath(pathName) {
  return pathName.split('/').pop();
}

export function shouldShowRestOfShelf(
  currentUrl,
  shelfId,
  shelfIdSlug,
  aisleIdSlug
) {
  return (
    currentUrl.includes('/search') ||
    !(
      currentUrl.includes(decodeURIComponent(shelfId)) ||
      currentUrl.includes(`${shelfIdSlug}/${shelfIdSlug}`) ||
      (getPageFromPath(currentUrl) === shelfIdSlug &&
        aisleIdSlug !== shelfIdSlug)
    )
  );
}

export function getSlug(name) {
  return slugify(name, { lower: true });
}

export function getSlugId(name) {
  if (!name) return null;

  const slugId = getSlug(name);

  return slugId === '' ? name.replace(/\s/g, '-') : slugId;
}

export function generateSlugs(aisleName, shelfName, aisleId) {
  // IGHS api - doesn't return shelf data. we need the aisleId for International
  const shelfNameOrAisleId = shelfName || aisleId;

  return {
    shelfIdSlug: getSlugId(shelfNameOrAisleId),
    aisleIdSlug: getSlugId(aisleName)
  };
}

export function updatePageUrlWithoutRefresh(window, url) {
  // Updates the URL in the browser without reloading/refreshing/navigating to it
  if (!window || !url) return;

  setBrowserTransactionName(url);

  window.history.pushState({ ...window.history.state, path: url }, '', url);
}

/*
Given a pattern and request path return path excluding basepath
*/
export const getMatchedPath = (pattern, requestPath) => {
  const regex = new RegExp(pattern, 'i');

  return requestPath.match(regex)[1];
};

/**
 * Given the `basePath`, `language` and `path` from `req`, will provide a string URL containing the path excluding the
 * basePath and language from it.
 * An optional `requestedPath` can be supplied,
 *
 * @param basePath
 * @param language
 * @param path
 * @param requestedPath
 * @returns {string}
 */
export const getPath = (
  { site: { basePath }, language, originalUrl },
  requestedPath
) => {
  // requestedPath will be used if provided, otherwise req.path will be used.
  const pattern = getBasepathLanguageRegex(basePath, language);
  const matchedPath = getMatchedPath(pattern, requestedPath || originalUrl);

  return trimTrailingSlash(removeQueryString(matchedPath)) || '/';
};
/**
@Input:
 * GetQueryStringWithExcludedParams: Expect querysting
 * as input and the param which need to remove from the querysting.
 * Ex:
 *    qs    --> searchBox=col%20and%20coke&icid=tescohp_sws-1_col%20and%20coke
 *    param --> searchBox
 * @Output:
 * Remove the given param from querysting and decode it.
 * Ex:
 *    Result ---> icid=tescohp_sws-1_col and coke
 */
export function getQueryStringWithExcludedParams(qs, param) {
  qs = querystring.parse(qs);
  delete qs[param];
  qs = querystring.stringify(qs, '&', '=');

  return querystring.unescape(qs);
}

export function removeHashFromUrl(initialUrl) {
  const parsedUrl = url.parse(initialUrl);

  delete parsedUrl.hash;

  return url.format(parsedUrl);
}

export const getIsHomepage = url => {
  const trimmedUrl = url.split('?')[0];
  return /^(\/groceries)(\/|\/[a-z]{2}-[A-Z]{2}\/?)?$/.test(trimmedUrl);
};

export const getIsReferrerHomepage = referrer => {
  return getIsHomepage(url.parse(referrer).path);
};

export const getIsResourceRequest = url => {
  const trimmedUrl = url.split('?')[0];
  return /^(\/groceries)(\/|\/[a-z]{2}-[A-Z]{2}\/?)?(\/resources)$/.test(
    trimmedUrl
  );
};
