import { getFeatureVariableStringFromState } from '#/lib/optimizely-manager';
import { amendBannerPositionStyle } from '../constants';

const { featureKey, variationKey, useCustomerId } = amendBannerPositionStyle;
const {
  amendPosition,
  amendPositionCTAs,
  amendPositionCTAsGuideCopyDetectCopy,
  amendPositionCTAsGuideCopyDetectCopyUI,
} = amendBannerPositionStyle.variants;

export function getOop1882eDarkChange(variation: string | null): boolean {
  return variation === amendPositionCTAsGuideCopyDetectCopyUI;
}

export function getOop1882deDiffCopy(variation: string | null): boolean {
  return [amendPositionCTAsGuideCopyDetectCopy, amendPositionCTAsGuideCopyDetectCopyUI].includes(variation || '');
}

export function getOop1882cdeSaveButton(variation: string | null): boolean {
  return [amendPositionCTAs, amendPositionCTAsGuideCopyDetectCopy, amendPositionCTAsGuideCopyDetectCopyUI].includes(
    variation || '',
  );
}

export function getOop1882bcdeBottom(variation: string | null): boolean {
  return [
    amendPosition,
    amendPositionCTAs,
    amendPositionCTAsGuideCopyDetectCopy,
    amendPositionCTAsGuideCopyDetectCopyUI,
  ].includes(variation || '');
}

export function getAmendBannerPositionStyleVariant(state: Store): string | undefined {
  return getFeatureVariableStringFromState(state, featureKey, variationKey, { useCustomerId });
}
