import React from 'react';
import PropTypes from 'prop-types';
import DefaultLayout from '#/components/layouts/default';
import { connect } from '#/lib/render/connect-deep-compare';
import {
  getUser,
  getIsUserRegistered,
  getIsUserAuthenticated
} from '#/reducers/user';
import { getIsAmendBasket } from '#/selectors/trolley';
import { getIsMobile } from '#/reducers/app';

const mapStateToProps = state => ({
  isMobile: getIsMobile(state),
  isAmendBasket: getIsAmendBasket(state),
  isUserAuthenticated: getIsUserAuthenticated(state),
  isUserRegistered: getIsUserRegistered(state),
  user: getUser(state)
});

@connect(mapStateToProps)
export default class HomeLayoutSPA extends React.Component {
  static propTypes = {
    children: PropTypes.any.isRequired,
    isAmendBasket: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool,
    isUserAuthenticated: PropTypes.bool.isRequired
  };
  render() {
    const showMiniTrolley =
      this.props.isUserAuthenticated && !this.props.isMobile;

    return (
      <DefaultLayout
        {...this.props}
        includeAppBanner={true}
        isHomePage={true}
        groceryTabInOpenState={true}
        stickySearchExpanded={true}
        stickySearchIgnored={false}
        showMiniTrolley={showMiniTrolley}
      />
    );
  }
}
