import React from 'react';
import PropTypes from 'prop-types';
import { compose, withProps } from 'react-recompose';
import Dropdown, { Option } from '@ddsweb/dropdown';
import { connect } from '#/lib/render/connect-deep-compare';
import SafeForm from '#/components/shared/safe-form';
import helpers from '#/lib/decorators/helpers';
import {
  getTrolleyDeliveryAddressId,
  getTrolleyDeliveryPreference,
  getSlot
} from '#/selectors/trolley';
import { getSelectedLocationId, getSlotsPendingStatus } from '#/reducers/slot';
import { getLocations } from '#/reducers/location';
import { getAllAddresses, getDefaultAddress } from '#/selectors/addresses';
import {
  CHANGE_DELIVERY_ADDRESS,
  CHANGING_DELIVERY_ADDRESS
} from '#/constants/slot-warning-modal-types';
import { CHANGE_SLOT_TYPE_MODAL } from '#/constants/modal-names';
import { hasSlotExpired } from '#/lib/slot/slot-utils';
import { getCurrentUrl } from '#/reducers/app';
import { ensureEventTargetValueSet } from '#/lib/browser/accessibility-helpers';
import { getSearchedAddress } from '#/selectors/slot';
import { getTruncatedString } from '#/utils/string';
import { SUGGESTED_ADDRESSES_CHARACTERS_LIMIT } from '#/constants/spa-resource';

const mapStateToProps = state => ({
  addresses: getAllAddresses(state),
  suggestionSelectedAddress: getSearchedAddress(state),
  currentId: getCurrentId(state), //selectors needs to be define in a separate file, this is a temporary measure don't follow this
  currentBagPref: getTrolleyDeliveryPreference(state).isBagless,
  currentUrl: getCurrentUrl(state),
  locations: getLocations(state),
  locationId: getSelectedLocationId(state),
  isPending: getSlotsPendingStatus(state)
});

/*
 * lego-660 - temporary fix - the currentId should be picked up from trolley delivery address
              however, GAPI returns the store name, rather than the user’s address nickname. it
              also return addressId  as '0' and the display order in the dropdown becomes inconsistent
              with the slots displayed due to that. We try to add a cookie of with the selected address
              and override the trolley delivery address. refer /web/middleware/trolley-contents.js
              This is a fall back logic, in case, user booked a slot without selecting an address
              from the drodown (i.e. the default address and the cookie doesn't get set)
              and the default address is not the first address in the allAddress list in user profile
              This code needs to be changed when GAPI response changes.

lego-4217 - For C+C GAPI is unlinking the address, once slot expired and user come to this page.
            Added a check(hack), if slot is expired then Address should fallback to default address. In future
            this issue should be addressed from GAPI as this is a temporary fix.
*/
const getCurrentId = state => {
  const currentBasketAddressId = getTrolleyDeliveryAddressId(state);
  const customerDefaultAddressId = getDefaultAddress(state).id;
  const slot = getSlot(state);

  return currentBasketAddressId === '0' || hasSlotExpired(slot)
    ? customerDefaultAddressId
    : currentBasketAddressId;
};

export const aggregateCollectionLocations = (
  addresses,
  isPending,
  suggestionSelectedAddress
) => {
  if (suggestionSelectedAddress && !isPending) {
    return [
      ...addresses,
      {
        name: getTruncatedString(
          suggestionSelectedAddress.addressLines.join(', '),
          SUGGESTED_ADDRESSES_CHARACTERS_LIMIT
        ),
        id: suggestionSelectedAddress.id,
        isBusinessAddress: false
      }
    ];
  }

  return addresses;
};

const enhanceForCollection = compose(
  helpers(['t', 'l']),
  connect(mapStateToProps),
  withProps(({ ...props }) => {
    return {
      ...props,
      addresses: aggregateCollectionLocations(
        props.addresses,
        props.isPending,
        props.suggestionSelectedAddress
      )
    };
  })
);

const enhanceForDelivery = compose(
  helpers(['t', 'l']),
  connect(mapStateToProps)
);

export const MultipleAddressSelector = ({
  addresses,
  defaultAddressId,
  currentId,
  currentUrl,
  identifier,
  l,
  method,
  onChange,
  showChangeAddressModal,
  slotEnd,
  slotGroup,
  slotStart,
  submitUrl,
  suggestionSelectedAddress,
  t
}) => {
  // defaultAddressId only present for delivery
  const defaultId = defaultAddressId
    ? defaultAddressId
    : getDefaultId(addresses, suggestionSelectedAddress, currentId);
  const addressLength = addresses.length;

  const formProps = {
    action: l(submitUrl),
    method,
    className: 'multiple-address-selector'
  };

  const isGet = method.toUpperCase() === 'GET';

  return (
    <SafeForm {...formProps}>
      {addressLength > 1 ? (
        <Dropdown
          name="addressId"
          onChange={event => onChange(ensureEventTargetValueSet(event))}
          value={defaultId}
          id={identifier}
        >
          {addresses.map((address, i) => (
            <Option value={address.id} key={i}>
              {address.name}
            </Option>
          ))}
        </Dropdown>
      ) : (
        <strong>{addresses[0].name}</strong>
      )}
      <input type="hidden" name="returnUrl" value={currentUrl} />
      <input
        type="hidden"
        name="modalType"
        value={
          showChangeAddressModal
            ? CHANGE_DELIVERY_ADDRESS
            : CHANGING_DELIVERY_ADDRESS
        }
      />
      {isGet && (
        <input
          type="hidden"
          name="currentModal"
          value={CHANGE_SLOT_TYPE_MODAL}
        />
      )}
      {slotStart && <input type="hidden" name="start" value={slotStart} />}
      {slotStart && <input type="hidden" name="end" value={slotEnd} />}
      {slotGroup && <input type="hidden" name="slotGroup" value={slotGroup} />}
      {addressLength > 1 && (
        <button type="submit" className="button button-secondary small">
          {t('slots:common.address-selection-submit')}
        </button>
      )}
    </SafeForm>
  );
};

MultipleAddressSelector.propTypes = {
  addresses: PropTypes.array,
  currentBagPref: PropTypes.bool.isRequired,
  currentId: PropTypes.string.isRequired,
  currentUrl: PropTypes.string.isRequired,
  defaultAddressId: PropTypes.string,
  identifier: PropTypes.string,
  l: PropTypes.func.isRequired,
  locationId: PropTypes.string,
  locations: PropTypes.array,
  method: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  showChangeAddressModal: PropTypes.any,
  slotEnd: PropTypes.string,
  slotGroup: PropTypes.number,
  slotStart: PropTypes.string,
  submitUrl: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired
};

MultipleAddressSelector.defaultProps = {
  method: 'POST'
};

const getDefaultId = (addresses = [], suggestionSelectedAddress, currentId) => {
  const addressElem = addresses.find(
    address => address.id === suggestionSelectedAddress?.id
  );

  return addressElem ? addressElem.id : currentId;
};

export const DeliveryMultipleAddressSelector = enhanceForDelivery(
  MultipleAddressSelector
);
export const CollectionMultipleAddressSelector = enhanceForCollection(
  MultipleAddressSelector
);
