import React, { useEffect, useState } from 'react';
import helpers from '#/lib/decorators/helpers';
import moment from 'moment-timezone';
import countdownCallbackService from './callback-service';
import CountdownTimerCallbackKeys from '#/components/shared/countdown-timer/callback-keys.types';

const callbackService = countdownCallbackService();
interface TCustomCallback {
  cb: Function;
  cbKey: keyof CountdownTimerCallbackKeys;
  customTime: number;
}

type TCustomCallbacks = Array<TCustomCallback>;

type TOwnProps = {
  cb?: Function;
  cbKey?: keyof CountdownTimerCallbackKeys;
  countdownEnd: string;
  t: (key: string, data?: object) => string;
  customcb?: TCustomCallbacks;
};

const CountdownTimer: React.FC<TOwnProps> = (props: TOwnProps) => {
  const { t: translate, countdownEnd, cb, cbKey, customcb } = props;
  const [resultTimer, setResultTimer] = useState('');
  let customTimer;

  useEffect(() => {
    const minsText = translate('common:mins');
    const secsText = translate('common:secs');
    const endTime = new Date(countdownEnd);
    const currentTime = new Date();
    const diffTime = endTime.getTime() - currentTime.getTime();
    let duration: any = moment.duration(diffTime, 'milliseconds');
    const interval = 1000;
    const customTimeCB = {};
    if (cbKey && cb) {
      callbackService.register(cbKey, cb);
    }
    if (customcb?.length) {
      customcb.forEach(customCBobj => {
        const customTime = customCBobj['customTime'];
        const customCBKey = customCBobj['cbKey'];
        const customCBFunc = customCBobj['cb'];

        callbackService.register(customCBKey, customCBFunc);
        customTimeCB[customTime] = customCBKey;
      });
    }

    customTimer = setInterval(function() {
      duration = moment.duration(duration - interval, 'milliseconds');
      if (duration._milliseconds < 1000) {
        clearInterval(customTimer);
        if (cbKey && cb) {
          const resultFunc = callbackService.execute(cbKey);
          if (resultFunc) {
            cb();
          }
        }
      } else {
        const durationMins = duration.minutes(),
          durationSecs = duration.seconds();
        const cumulativeSeconds = durationMins * 60 + durationSecs;
        const mins = durationMins >= 10 ? durationMins : '0' + durationMins;
        const secs = durationSecs >= 10 ? durationSecs : '0' + durationSecs;
        let result = '';
        if (mins === '00' && secs && secs !== '00') {
          result = `${secs} ${secsText}`;
        } else if (mins === '00' && secs === '00') {
          result = '';
        } else if (mins && secs) {
          result = `${mins} ${minsText} ${secs} ${secsText}`;
        }
        setResultTimer(result);
        const customTimeKey = customTimeCB[cumulativeSeconds];
        if (customTimeKey) {
          const resultFunc = callbackService.execute(customTimeKey);
          if (resultFunc) {
            const resultCBObj = customcb?.filter(cb => cb.cbKey === customTimeKey);
            resultCBObj?.[0]?.cb();
          }
        }
      }
    }, interval);

    return () => {
      clearInterval(customTimer);
    };
  }, []);

  return (
    <span>
      <time>{resultTimer}</time>
    </span>
  );
};

export default helpers(['t'])(CountdownTimer);
