import {
  RDG_DATE_RANGE,
  RDG_DAY_TIME,
  RDG_LEAD_TIME,
  RDG_UNAVAILABLE_DATE_RANGE,
  RDG_LEAD_TIME_WITH_ITEM
} from '../constants/restrictions';
import { WARNING_MESSAGE_BACKGROUND_CLASS } from '../constants/product-tile';

export function buildRdgRestrictionMessage(restriction, isMiniTrolley = false) {
  if (!restriction) return null;

  const isLeadTimeInBasket = restriction.type === RDG_LEAD_TIME_WITH_ITEM;

  const restrictionData = {
    message: isMiniTrolley ? restriction.shortMessage : restriction.message,
    showChangeSlot: !isLeadTimeInBasket && restriction.isViolated,
    displayWarningMessage: isLeadTimeInBasket
  };

  const hasValidRestriction =
    !isMiniTrolley || (isMiniTrolley && isRdgViolated(restriction));

  if (!hasValidRestriction) return null;

  return restrictionData;
}

function filterByRestriction(restrictions = [], restrictionTypes) {
  return restrictions.find(({ type }) => restrictionTypes.includes(type));
}

export function isRdgViolated(restriction) {
  if (!restriction) return false;

  const restrictions = Array.isArray(restriction) ? restriction : [restriction];

  return restrictions.some(
    restriction =>
      restriction &&
      (restriction.isViolated || isLeadTimeProductInOrder(restriction))
  );
}

export function isLeadTimeProductInOrder(restriction) {
  return !!restriction && restriction.type === RDG_LEAD_TIME_WITH_ITEM;
}

export function getRdgTimeRestriction(restrictions) {
  return filterByRestriction(restrictions, [RDG_DAY_TIME]);
}

export function getRdgDateRestriction(restrictions) {
  return filterByRestriction(restrictions, [
    RDG_DATE_RANGE,
    RDG_LEAD_TIME,
    RDG_LEAD_TIME_WITH_ITEM
  ]);
}

export function getRdgUnavailableDateRestriction(restrictions) {
  return filterByRestriction(restrictions, [RDG_UNAVAILABLE_DATE_RANGE]);
}

export function combineRdgMessages(
  timeMessage,
  dateMessage,
  unavailableMessage
) {
  const messages = [];
  let showChangeSlot = false;
  let showWarningMessage = false;

  if (timeMessage) {
    messages.push(timeMessage.message);
    showChangeSlot = showChangeSlot || timeMessage.showChangeSlot;
  }

  if (dateMessage) {
    messages.push(dateMessage.message);
    showChangeSlot = showChangeSlot || dateMessage.showChangeSlot;
    showWarningMessage = dateMessage.displayWarningMessage || false;
  }

  if (unavailableMessage) {
    messages.push(unavailableMessage.message);
    showChangeSlot = showChangeSlot || unavailableMessage.showChangeSlot;
  }

  return messages.length
    ? {
        messages,
        backgroundColorClass:
          showChangeSlot || showWarningMessage
            ? WARNING_MESSAGE_BACKGROUND_CLASS
            : null,
        showChangeSlot
      }
    : null;
}
