import { INFO, WARNING, ERROR } from '#/constants/notification-variants';
import { VariantType } from './ondemand-slot-details';

export const getVariant = (isSlotBooked: boolean, isSlotExpiring = false): VariantType => {
  if (isSlotExpiring) {
    return WARNING;
  }

  if (isSlotBooked) {
    return INFO;
  }

  return ERROR;
};
