import React from 'react';
import { Heading } from '@ddsweb/heading';
import { StarRatingContainer, StarRatingWrapper } from './styled';
import { RatingWrappedOwnProps } from './index.defs';
import StarRatingBasic from './starRatingBasic';

export default (props: RatingWrappedOwnProps): JSX.Element => {
  return (
    <StarRatingWrapper isFavoritesPage={props.isFavoritesPage}>
      <Heading aria-label={props.legendAriaLabel} headingLevel="4">
        {props.legendText}
      </Heading>
      <StarRatingContainer>
        <StarRatingBasic
          ratingLabels={props.ratingLabels}
          ratingValue={props.ratingValue}
          noRatingCopy={props.noRatingCopy}
          refererUrl={props.refererUrl}
        />
      </StarRatingContainer>
    </StarRatingWrapper>
  );
};
