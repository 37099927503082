import analyticsBus from '#/analytics/analyticsBus';
import { basicEvent } from '#/analytics/types/basic';
import { BEST_VALUE, HIGHLIGHT_SLOT_IMPRESSION, NOW } from '#/analytics/constants';
import * as helpers from '#/analytics/best-value-slots';

export const triggerBestValueEvent = (type: string, node: HTMLElement | null): void => {
  if (!type || !node || !node.offsetParent) {
    return;
  }

  basicEvent(analyticsBus, {
    action: NOW,
    type: type,
    value: BEST_VALUE,
  });
};

export const triggerBestValueUpdateEvent = (
  prevBestValueSlots: string[] | null,
  currentBestValueSlots: string[] | null,
  node: HTMLElement | null,
): void => {
  if (
    !currentBestValueSlots ||
    !currentBestValueSlots.length ||
    !node ||
    !node.offsetParent ||
    prevBestValueSlots?.toString() === currentBestValueSlots.toString()
  ) {
    return;
  }

  helpers.triggerBestValueEvent(HIGHLIGHT_SLOT_IMPRESSION, node);
};
