import { UPDATE_LAST_FULFILLED_ORDER } from '#/constants/action-types';
import { LastOrderState } from '#/custom-typings/redux-store/last-order.defs';
import { Actions } from '#/actions/order-action.defs';
import { ServerProps } from './types.defs';
import { getResourceData } from '#/resources/resource-util';
import { LAST_ORDER } from '#/constants/spa-resource';

const defaultLastOrderState = {
  hasLastOrder: false,
  items: [],
  isGrid: false,
};

export default (state: LastOrderState = defaultLastOrderState, action: Actions): LastOrderState => {
  switch (action.type) {
    case UPDATE_LAST_FULFILLED_ORDER:
      return {
        ...state,
        ...action.value,
      };
    default:
      return state;
  }
};

export const getDefaultStateFromProps = (props: ServerProps): Partial<LastOrderState> => {
  const lastOrderResource = getResourceData(props.resources, LAST_ORDER);
  const orderNo = lastOrderResource?.orderNo;

  return {
    orderNo,
    hasLastOrder: !!orderNo,
  };
};
