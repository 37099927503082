import styled from 'styled-components';
import Button from '@ddsweb/button';
import { colors, spacing, fontSize } from '@ddsweb/selectors';
import Modal from '@ddsweb/modal';
import { BREAKPOINTS } from '@ddsweb/constants';

export const MobileDiv = styled.div`
  @media (min-width: ${BREAKPOINTS.tabletLarge}px) {
    display: none;
  }
`;

export const StyledModal = styled(Modal)`
  .ddsweb-modal__close-link {
    display: flex;
    justify-content: end;
    align-items: flex-start;
  }
  .ddsweb-modal__mask {
    z-index: 9999;
  }
  .ddsweb-link__anchor {
    padding: ${spacing.xs} ${spacing.sm};
    font-size: ${fontSize.xs};
    font-weight: 400;
  }
  .spinnerContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.5);
  }

  .helloUser {
    font-size: ${fontSize.sm};
    font-weight: 700;
    padding: ${spacing.sm};
    border-bottom: 1px solid ${colors.linesLight};
  }
`;

export const MenuBody = styled.div`
  //40 px is the height of the top header with close icon
  height: calc(100vh - 40px);
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const StyledDiv = styled.div`
  & .app-bar-link {
    heigth: 37px;
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    color: ${colors.inverse};
    background-color: ${colors.link};
    font-weight: 700;
    &:hover,
    &:focus,
    &:visited {
      color: ${colors.inverse};
      background-color: ${colors.link};
      transition: none;
    }
  }
  & .primary-link {
    heigth: 41px;
    width: 100%;
    border-bottom: 1px solid ${colors.linesLight};
    color: ${colors.link};
    background-color: ${colors.inverse};
    font-weight: 400;
    &:hover,
    &:focus,
    &:visited {
      color: ${colors.link};
      background-color: ${colors.inverse};
      transition: none;
    }
  }
`;

export const Header = styled.div`
  /*
    Fallback to position: relative when sticky is not available to ensure the
    header always creates a stacking context
  */
  z-index: 1;
  top: 0;
  display: flex;
  background: ${colors.background};
  padding: ${spacing.xs} ${spacing.sm};
  align-items: center;
  height: 41px;
  border-bottom: 1px solid ${colors.linesLight};
  justify-content: end;
`;

export const CloseButton: typeof Button = styled(Button)`
  display: flex;
  justify-content: end;
  height: ${spacing.xl};
  width: 84px;
  margin-left: auto;
`;

export const BackButton: typeof Button = styled(Button)`
  display: flex;
  align-items: center;
  height: ${spacing.xl};
  width: 54px;
`;

export const StyledCardStack = styled.div`
  position: relative;
  overflow: hidden;
  & div.cardStackColumn {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 40px);
    overflow: auto;
    /* Use transform3d to ensure GPU acceleration */
    transform: translate3d(0 0, 0);
  }
`;

export const AuthButtonContainer = styled.div`
  display: none;
  @media (max-width: ${BREAKPOINTS.mobileLarge - 1}px) {
    display: flex;
    padding: ${spacing.sm};
    border-bottom: 1px solid ${colors.linesLight};
    align-items: center;
    justify-content: space-between;
    column-gap: ${spacing.xl};
  }
`;

export const SignOutButton = styled(Button)`
  text-decoration: none;
  display: flex;
  padding: ${spacing.xs} ${spacing.sm};
  border-radius: 0;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  color: ${colors.inverse};
  background-color: ${colors.link};
  font-weight: 700;
  &:hover,
  &:focus,
  &:visited {
    color: ${colors.inverse};
    background-color: ${colors.link};
    transition: none;
  }
`;
