import React from 'react';
import PropTypes from 'prop-types';
import { connect } from '../../../lib/render/connect-deep-compare';
import { closeLeftNav } from '../../../actions/ui-action-creators';
import Link from '../../link-check-spa';
import { getCurrentUrl } from '#/reducers/app';

const mapStateToProps = state => ({ currentUrl: getCurrentUrl(state) });

@connect(mapStateToProps, { closeLeftNav })
export default class ListItemContent extends React.Component {
  static propTypes = {
    closeLeftNav: PropTypes.func.isRequired,
    currentUrl: PropTypes.string.isRequired,
    iconLeft: PropTypes.string,
    iconRight: PropTypes.string,
    link: PropTypes.string.isRequired,
    newTab: PropTypes.bool,
    onClick: PropTypes.func,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired
  };

  handleClick = evt => {
    if (this.props.onClick) {
      this.props.onClick(evt);
    } else if (this.props.link === this.props.currentUrl) {
      this.props.closeLeftNav();
      evt.preventDefault();
    }
  };

  render() {
    const { iconLeft, iconRight, link, newTab, text } = this.props;
    let leftIcon;
    let rightIcon;

    if (iconLeft) {
      leftIcon = (
        <span className={'list-item-icon list-item-icon-left ' + iconLeft} />
      );
    }

    if (iconRight) {
      rightIcon = (
        <span className={'list-item-icon list-item-icon-right ' + iconRight} />
      );
    }

    return (
      <Link
        to={link}
        onClick={this.handleClick}
        role="menuitem"
        target={newTab ? '_blank' : null}
      >
        {leftIcon}
        {rightIcon}
        <span
          data-auto="list-item-single-line"
          className="list-item-single-line"
        >
          {text}
        </span>
      </Link>
    );
  }
}
