import { getVariationForExperiment } from '#/lib/optimizely-manager';
import { howItWorksSectionVariants } from '../constants';

const { key, useCustomerId, variants } = howItWorksSectionVariants;
const getVariant = (state: Store): string => getVariationForExperiment(state, key, {}, useCustomerId);

const getHowItWorksVariant = (state: Store): string => {
  const variant = getVariant(state);
  return variant ?? variants.default;
};

export const getIsHIWSectionPositionAtTop = (state: Store): boolean => {
  const variant = getHowItWorksVariant(state);
  return variant === variants.variantB;
};
