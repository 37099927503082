import React, { Component } from 'react';
import PropTypes from 'prop-types';
import deepEqual from 'fast-deep-equal/es6';
import { connect } from 'react-redux';
import helpers from '#/lib/decorators/helpers';
import { getAppRegion } from '#/reducers/app';
import * as mapConfigurations from '../configurations';

const mapStateToProps = state => ({
  region: getAppRegion(state)
});

@helpers(['c'])
@connect(mapStateToProps)
export default class InteractiveMap extends Component {
  static propTypes = {
    c: PropTypes.func.isRequired,
    center: PropTypes.shape({
      latitude: PropTypes.string.isRequired,
      longitude: PropTypes.string.isRequired
    }).isRequired,
    disablePanning: PropTypes.bool,
    disableScrollWheelZoom: PropTypes.bool,
    disableTouchInput: PropTypes.bool,
    disableZooming: PropTypes.bool,
    enableHighlightedAreas: PropTypes.bool,
    fallbackToStaticMap: PropTypes.func.isRequired,
    highlightedAreas: PropTypes.arrayOf(PropTypes.object),
    onClick: PropTypes.func,
    onDblClick: PropTypes.func,
    onMapTypeChanged: PropTypes.func,
    onPointClick: PropTypes.func,
    onReady: PropTypes.func,
    onResize: PropTypes.func,
    onRightClick: PropTypes.func,
    onViewChange: PropTypes.func,
    onViewChangeEnd: PropTypes.func,
    onViewChangeStart: PropTypes.func,
    points: PropTypes.arrayOf(PropTypes.object),
    provider: PropTypes.string.isRequired,
    region: PropTypes.string.isRequired,
    requireCalculations: PropTypes.bool,
    requireSearch: PropTypes.bool,
    selectedPoint: PropTypes.string,
    showLocateMeButton: PropTypes.bool,
    showViewportCenterPin: PropTypes.bool,
    theme: PropTypes.object.isRequired,
    zoom: PropTypes.number
  };

  static defaultProps = {
    disablePanning: false,
    disableTouchInput: false,
    disableZooming: false,
    enableHighlightedAreas: false,
    highlightedAreas: [],
    points: [],
    showViewportCenterPin: false,
    zoom: 16
  };

  constructor(props) {
    super(props);

    this.mapRef = React.createRef();
    this.markers = [];
    this.mapAttributes = new mapConfigurations[props.provider]();
    this.getListenerProxy = this.getListenerProxy.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    const {
      center: nextCenterProps,
      points: nextPointsProps,
      highlightedAreas: nextHighlightedAreasProps,
      disablePanning: nextDisablePanningProps,
      disableZooming: nextDisableZoomingProps,
      selectedPoint: nextSelectedPointProps
    } = nextProps;
    const {
      center,
      points,
      highlightedAreas,
      disablePanning,
      disableZooming,
      selectedPoint
    } = this.props;

    return (
      !deepEqual(nextCenterProps, center) ||
      !deepEqual(nextPointsProps, points) ||
      !deepEqual(nextHighlightedAreasProps, highlightedAreas) ||
      nextSelectedPointProps !== selectedPoint ||
      nextDisablePanningProps !== disablePanning ||
      nextDisableZoomingProps !== disableZooming
    );
  }

  getListenerProxy(typeProp, props, prevProps = {}) {
    const instance = this;
    const proxyProp = typeProp + 'ListenerProxy';
    const typeKey = typeProp.slice(2).toLowerCase();
    const listener = props[typeProp];
    const prevListener = prevProps[typeProp];

    if (!listener) {
      delete this[proxyProp];
    } else if (listener !== prevListener) {
      this[proxyProp] = function() {
        listener({
          type: typeKey,
          target: instance
        });
      };
    }

    return this[proxyProp];
  }

  updateEventListeners(props, prevProps = {}) {
    const getListenerProxy = this.getListenerProxy;

    this.mapAttributes.setEventListeners(this.map, {
      click: getListenerProxy('onClick', props, prevProps),
      dblclick: getListenerProxy('onDblClick', props, prevProps),
      resize: getListenerProxy('onResize', props, prevProps),
      maptypechanged: getListenerProxy('onMapTypeChanged', props, prevProps),
      rightclick: getListenerProxy('onRightClick', props, prevProps),
      viewchange: getListenerProxy('onViewChange', props, prevProps),
      viewchangeend: getListenerProxy('onViewChangeEnd', props, prevProps),
      viewchangestart: getListenerProxy('onViewChangeStart', props, prevProps)
    });
  }

  _generatePoints() {
    const { points, selectedPoint, onPointClick } = this.props;
    return points.forEach(iPoint => {
      const pointOptions =
        iPoint.locationId === selectedPoint || points.length === 1
          ? this.mapAttributes.selectedPointAttributes({
              point: iPoint,
              map: this.map,
              type: iPoint.type
            })
          : this.mapAttributes.defaultPointAttributes({
              point: iPoint,
              map: this.map
            });

      const point = this.mapAttributes.createPoint({
        latitude: iPoint.latitude,
        longitude: iPoint.longitude,
        options: pointOptions,
        map: this.map
      });

      if (onPointClick) {
        this.mapAttributes.addPinEventHandler(point, 'click', () =>
          onPointClick(null, iPoint.locationId)
        );
      }

      this.markers.push(point);
    });
  }

  doesNestedVariableExistOnWindow(variableArray) {
    let variable = window;

    variableArray.forEach(key => {
      if (typeof variable === 'object' && variable !== null) {
        variable = variable[key];
      }
    });

    return typeof variable !== 'undefined';
  }

  getRequiredFeatures() {
    const { requireSearch, requireCalculations } = this.props;
    const output = [];

    if (requireSearch) {
      output.push('search');
    }

    if (requireCalculations) {
      output.push('calculations');
    }

    return output;
  }

  componentDidUpdate(prevProps) {
    const props = this.props;
    const {
      center: prevCenter,
      highlightedAreas: prevHighlightedAreas,
      points: prevPoints,
      disablePanning: prevDisablePanning,
      disableScrollWheelZoom: prevDisableScrollWheelZoom,
      disableZooming: prevDisableZooming,
      showLocateMeButton: prevShowLocateMeButton
    } = prevProps;
    const {
      center,
      highlightedAreas,
      points,
      fallbackToStaticMap,
      selectedPoint,
      disablePanning,
      disableScrollWheelZoom,
      disableZooming,
      showLocateMeButton,
      onResize
    } = props;

    if (
      prevDisablePanning !== disablePanning ||
      prevDisableScrollWheelZoom !== disableScrollWheelZoom ||
      prevDisableZooming !== disableZooming ||
      prevShowLocateMeButton !== showLocateMeButton
    ) {
      const mapUpdateOptions = {
        disablePanning,
        disableScrollWheelZoom,
        disableZooming,
        showLocateMeButton
      };

      this.mapAttributes.setOptions(this.map, mapUpdateOptions);
    }

    if (
      !this.mapAttributes.requiredGlobalVars.every(
        this.doesNestedVariableExistOnWindow
      )
    ) {
      fallbackToStaticMap();
      return;
    }

    if (!deepEqual(prevCenter, center)) {
      this.mapAttributes.setCenter(this.map, center.latitude, center.longitude);
    }

    this.updateEventListeners(props, prevProps);

    if (
      !deepEqual(prevPoints, points) ||
      prevProps.selectedPoint !== selectedPoint
    ) {
      this.markers.forEach(marker => {
        marker.setMap(null);
      });
      this.markers.length = 0;

      this._generatePoints();

      if (onResize) {
        onResize({
          target: this
        });
      }
    }

    const requiredFeatures = this.getRequiredFeatures();

    return this.mapAttributes
      .loadFeatures(requiredFeatures)
      .then(() => {
        if (!this.isMapMounted) {
          return;
        }

        if (!deepEqual(prevHighlightedAreas, highlightedAreas)) {
          this.mapAttributes.setHighlightedAreas(highlightedAreas);
        }
      })
      .catch(() => {
        if (!this.isMounted) {
          return;
        }

        fallbackToStaticMap();
      });
  }

  componentDidMount() {
    this.isMapMounted = true;

    if (
      !this.mapAttributes.requiredGlobalVars.every(
        this.doesNestedVariableExistOnWindow
      )
    ) {
      this.props.fallbackToStaticMap();
      return;
    }

    const requiredFeatures = this.getRequiredFeatures();

    return this.mapAttributes
      .loadFeatures(requiredFeatures)
      .then(() => {
        if (!this.isMapMounted) {
          return;
        }

        const {
          c: config,
          center,
          disablePanning,
          disableTouchInput,
          disableScrollWheelZoom,
          disableZooming,
          enableHighlightedAreas,
          highlightedAreas,
          onReady,
          showViewportCenterPin,
          theme,
          zoom,
          showLocateMeButton
        } = this.props;

        return this.mapAttributes
          .createMap(this.mapRef.current, {
            config,
            longitude: center.longitude,
            latitude: center.latitude,
            disablePanning,
            disableScrollWheelZoom,
            disableTouchInput,
            disableZooming,
            enableHighlightedAreas,
            highlightedAreas,
            showViewportCenterPin,
            theme: {
              pinColor: theme.colors.primary
            },
            zoom,
            showLocateMeButton
          })
          .then(map => {
            if (!this.isMapMounted) {
              return;
            }

            this.map = map;
            this.updateEventListeners(this.props);
            this._generatePoints();

            window.addEventListener('resize', this.handleResize);

            if (onReady) {
              onReady({
                type: 'ready',
                target: this
              });
            }
          });
      })
      .catch(() => {
        if (!this.isMapMounted) {
          return;
        }

        this.props.fallbackToStaticMap();
      });
  }

  componentWillUnmount() {
    this.isMapMounted = false;

    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    const center = this.mapAttributes.getCenter(this.map);

    if (
      center.latitude.toFixed(6) !== this.props.center.latitude ||
      center.longitude.toFixed(6) !== this.props.center.longitude
    ) {
      this.mapAttributes.setCenter(
        this.map,
        this.props.center.latitude,
        this.props.center.longitude,
        this.props.zoom
      );
    }
    if (this.props.onResize) {
      this.props.onResize({ target: this });
    }
  };

  containsLocation = (locationA, locationB) => {
    return this.mapAttributes.containsLocation(locationA, locationB);
  };

  getAddressSuggestions = query => {
    const options = {
      region: this.props.region
    };

    return this.mapAttributes.getAddressSuggestions(this.map, query, options);
  };

  geocode = query => {
    const { region } = this.props;

    return this.mapAttributes.geocode(this.map, `${query},${region}`);
  };

  reverseGeocode = location => {
    return this.mapAttributes.reverseGeocode(this.map, location);
  };

  getCenter() {
    return this.mapAttributes.getCenter(this.map);
  }

  getHighlightedAreas() {
    return this.mapAttributes.getHighlightedAreas();
  }

  render() {
    return <div className="map interactive-map" ref={this.mapRef} />;
  }
}
