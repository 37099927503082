import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../link';
import { getCurrentUrl } from '#/reducers/app';
import { connect } from '#/lib/render/connect-deep-compare';
import { addModalToUrl } from '../../../lib/url/modal-utils';

const ModalLink = props => (
  <div>
    <Link
      className={props.wrapperClassNames}
      href={addModalToUrl(props.currentUrl, props.modalName)}
      onClick={e => {
        e.preventDefault();
        props.closeModal();
        props.openModal(props.modalName);
      }}
    >
      {props.children}
    </Link>
  </div>
);

ModalLink.propTypes = {
  children: PropTypes.node.isRequired,
  closeModal: PropTypes.func.isRequired,
  currentUrl: PropTypes.string.isRequired,
  modalName: PropTypes.string.isRequired,
  openModal: PropTypes.func.isRequired,
  wrapperClassNames: PropTypes.string
};

const mapStateToProps = state => ({
  currentUrl: getCurrentUrl(state)
});

export default connect(mapStateToProps)(ModalLink);
