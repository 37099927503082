import { getIsCurrentPage } from '#/reducers/app';
import { getFeatureVariableStringFromState, updateRegionInKey } from '#/lib/optimizely-manager';
import { RelatedSearchFeature, RelatedSearchVariants, SEARCH_PAGE } from '#/experiments/oop-1826/constants';

export const getRelatedSearchVariant = (state: Store): RelatedSearchVariants => {
  const { featureKey, variationKey, useCustomerId } = RelatedSearchFeature;
  const regionInKey = updateRegionInKey(featureKey, state);
  const featureVariable = getFeatureVariableStringFromState(state, regionInKey, variationKey, {
    useCustomerId,
  });

  return featureVariable || RelatedSearchVariants.DEFAULT;
};

const isSearchPage = (state: Store): boolean => {
  return getIsCurrentPage(state, [SEARCH_PAGE]);
};

export const getShouldShowRelatedSearchInDropdown = (state: Store): boolean => {
  return isSearchPage(state) && getRelatedSearchVariant(state) === RelatedSearchVariants.DROPDOWN;
};

export const getShouldShowRelatedSearchInTop = (state: Store): boolean => {
  return isSearchPage(state) && getRelatedSearchVariant(state) === RelatedSearchVariants.TOP_PILLS;
};
