import { getVariationForExperiment } from '#/lib/optimizely-manager';
import { specialOffersPageNameVariants } from '../constants';

const { key } = specialOffersPageNameVariants;
const getVariant = (state: Store): string => getVariationForExperiment(state, key);

export const getShouldUseClubcardPricesName = (state: Store): boolean => {
  const variant = getVariant(state);
  return variant === specialOffersPageNameVariants.variants.showClubcardPricesName;
};
