import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import delimitQuery from '../mixins/delimit-query';
import FilterElement from './filter-element';
import Link from '../link-check-spa';
import { translateUnbrandedFacet } from '#/utils/product-filter-utils';
import { connect } from '#/lib/render/connect-deep-compare';
import helpers from '#/lib/decorators/helpers';
import {
  BRAND,
  DIETARY,
  SUPERDEPARTMENT,
  PROMOTION,
  AVAILABLE,
  PRODUCT_SOURCE
} from '#/constants/facet-categories';
import { getCurrentUrl, getCurrentPathname } from '#/reducers/app';
import { getCurrentFacets } from '#/reducers/filter';
import { isOnFavouritesPage } from '#/lib/favorites-helpers';
import { AVAILABLE_FACET_ID } from '#/constants/facet-ids';
import { getFavFilterNavigationVariant } from '#/experiments/oop-1662/selectors';
import { getIsFavFilterNavigationDisabled } from '#/experiments/oop-1662/helpers';
import {
  getFilterAttributes,
  getFilterTitleAndVariantClassName
} from '#/experiments/oop-2189/selectors';

const mapStateToProps = (state, ownProps) => {
  const currentUrl = getCurrentUrl(state);
  const isOnFavPage = isOnFavouritesPage(currentUrl);
  const isFavFilterNavigationDisabled = getIsFavFilterNavigationDisabled(
    getFavFilterNavigationVariant(state)
  );

  return {
    currentUrl,
    allowMultipleFilterSelections: !isOnFavPage,
    currentPathname: getCurrentPathname(state),
    currentFacets: getCurrentFacets(
      state,
      ownProps.c('facetsAllowingMultipleSelections'),
      ownProps.c('categoryDelimiter')
    ),
    shouldRemoveFilters: isOnFavPage && isFavFilterNavigationDisabled,
    filterAttributes: getFilterAttributes(state)
  };
};

const translationKeys = {
  fewerBrands: 'sort-and-filter:fewer-brands',
  fewerDietary: 'sort-and-filter:fewer-lifestyle-and-dietary',
  fewerCategories: 'sort-and-filter:fewer-categories',
  moreBrands: 'sort-and-filter:more-brands',
  moreDietary: 'sort-and-filter:more-lifestyle-and-dietary',
  moreCategories: 'sort-and-filter:more-categories',
  showAllDietary: 'sort-and-filter:show-all-dietary',
  showAllSuperDepartment: 'sort-and-filter:show-all-superdepartment',
  promotionsTitle: 'promotions:title'
};

@helpers(['c', 't'])
@connect(mapStateToProps)
export default class CollapsibleList extends Component {
  static propTypes = {
    ariaLabelledBy: PropTypes.string,
    c: PropTypes.func.isRequired,
    category: PropTypes.string.isRequired,
    currentFacets: PropTypes.object.isRequired,
    currentPathname: PropTypes.string.isRequired,
    currentUrl: PropTypes.string.isRequired,
    expanded: PropTypes.bool,
    facets: PropTypes.array,
    filtersToRemove: PropTypes.array,
    footer: PropTypes.node,
    hasSelected: PropTypes.bool,
    hasTooltip: PropTypes.bool,
    id: PropTypes.string,
    listLength: PropTypes.number,
    renderTooltip: PropTypes.func,
    showAllOption: PropTypes.bool,
    singleSelection: PropTypes.bool,
    filterAttributes: PropTypes.object,
    t: PropTypes.func.isRequired
  };

  static defaultProps = {
    listLength: 2,
    facets: [],
    expanded: false,
    showAllOption: false,
    singleSelection: false,
    filtersToRemove: [],
    hasTooltip: false
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      expanded: this.props.expanded
    };
    this.targetRef = createRef();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.expanded !== nextProps.expanded) {
      this.setState({ expanded: nextProps.expanded });
    }
  }

  toggleList = evt => {
    evt.preventDefault();
    this.setState({ expanded: !this.state.expanded });
  };

  getViewAllLabelExpanded = category => {
    switch (category) {
      case BRAND:
        return translationKeys.fewerBrands;
      case DIETARY:
        return translationKeys.fewerDietary;
      default:
        return translationKeys.fewerCategories;
    }
  };

  getViewAllLabelCollapsed = category => {
    switch (category) {
      case BRAND:
        return translationKeys.moreBrands;
      case DIETARY:
        return translationKeys.moreDietary;
      default:
        return translationKeys.moreCategories;
    }
  };

  getTitle = () => {
    switch (this.props.category) {
      case DIETARY:
        return translationKeys.showAllDietary;
      case SUPERDEPARTMENT:
        return translationKeys.showAllSuperDepartment;
      default:
        return translationKeys.promotionsTitle;
    }
  };

  renderShowAllOption(isSelected) {
    const {
      allowMultipleFilterSelections,
      t: translate,
      category,
      currentUrl,
      currentPathname,
      filtersToRemove: filtersToRemoveCollection,
      shouldRemoveFilters
    } = this.props;
    const title = translate(this.getTitle());

    const filtersToRemove = shouldRemoveFilters
      ? [PROMOTION, AVAILABLE]
      : filtersToRemoveCollection;

    return (
      <FilterElement
        title={title}
        id="all"
        name={title}
        category={category}
        listIsExpanded={this.state.expanded}
        selected={isSelected}
        key="all"
        currentUrl={currentUrl}
        showAllOption={true}
        singleSelection={true}
        currentPathname={currentPathname}
        allowMultipleFilterSelections={allowMultipleFilterSelections}
        filtersToRemove={filtersToRemove}
      />
    );
  }

  getSelectedIds() {
    const { category, currentFacets } = this.props;
    const categoryVal =
      category === PRODUCT_SOURCE ? category : category.toLowerCase();
    const categoryFacets = currentFacets[categoryVal];

    if (!categoryFacets) {
      return [];
    }

    return categoryFacets.map(facet => facet.facetId);
  }

  render() {
    const {
      ariaLabelledBy,
      currentUrl,
      currentPathname,
      category,
      facets,
      listLength,
      t: translate,
      singleSelection,
      allowMultipleFilterSelections,
      hasSelected,
      id: pId,
      showAllOption,
      footer,
      filtersToRemove,
      hasTooltip,
      renderTooltip,
      filterAttributes
    } = this.props;
    const { expanded } = this.state;
    const isPromotions = category.toLowerCase() === PROMOTION.toLowerCase();
    let qs = delimitQuery.parseQuerystring(currentUrl);

    if (expanded) {
      delete qs.viewAll;
    } else {
      qs = delimitQuery.addToQuerystring(qs, 'viewAll', category.toLowerCase());
    }

    const isPromotionFilterSelected = !!qs[PROMOTION.toLowerCase()];

    const selectedIds = this.getSelectedIds();
    const facetsData =
      expanded || isPromotions ? facets : facets.slice(0, listLength);

    const isProductSourceSelected =
      category === PRODUCT_SOURCE &&
      facetsData.some(({ facetId }) => selectedIds.includes(facetId));

    const filterElements = facetsData.map(facet => {
      let filterTitle = translate(translationKeys.promotionsTitle);
      let variantClassName;

      ({ filterTitle, variantClassName } = getFilterTitleAndVariantClassName(
        filterTitle,
        filterAttributes
      ));

      let isSlected = selectedIds.includes(facet.facetId.toString());

      if (
        !isProductSourceSelected &&
        category === PRODUCT_SOURCE &&
        !isSlected &&
        facet.binCount > 0
      ) {
        isSlected = facet.isSelected;
      }

      return (
        <FilterElement
          title={filterTitle}
          variantClassName={variantClassName}
          id={facet.facetId}
          name={translateUnbrandedFacet(facet.facetName, translate)}
          category={category}
          count={facet.binCount}
          listIsExpanded={expanded}
          selected={isSlected}
          key={`facet-${facet.facetName}`}
          currentUrl={currentUrl}
          currentPathname={currentPathname}
          singleSelection={singleSelection}
          allowMultipleFilterSelections={allowMultipleFilterSelections}
          filtersToRemove={filtersToRemove}
          ariaLabelledBy={ariaLabelledBy}
        />
      );
    });
    const viewLabel = expanded
      ? this.getViewAllLabelExpanded(category)
      : this.getViewAllLabelCollapsed(category);
    const viewMoreLess = !(facets.length <= listLength || isPromotions) && (
      <Link
        to={`${delimitQuery.createHref(currentUrl, qs)}#sort-n-filter`}
        className={classnames('button button-transparent toggle-list', {
          'has-selected': hasSelected,
          shrink: expanded
        })}
        onClick={this.toggleList}
      >
        <span className="view-all-label">{translate(viewLabel)}</span>
        <span className="icon-tiny-down-arrow" />
      </Link>
    );

    const id = pId?.replace(/ /g, '-');

    /* GIVEN allowMultipleFilterSelections is false
         WHEN sepecial offers filter is selected
           THEN Show All option should be de-selected
        
        WHEN sepecial offers filter is not selected
           THEN Show All option should be selected (by default)
    */
    const showAllOptionDefaultSelected =
      !selectedIds.length &&
      (allowMultipleFilterSelections || !isPromotionFilterSelected);

    const hasRemoveUnavailableId = selectedIds.includes(AVAILABLE_FACET_ID);

    return (
      <div className="collapsible-list" id={id} ref={this.targetRef}>
        {showAllOption &&
          this.renderShowAllOption(showAllOptionDefaultSelected)}
        {filterElements}
        {hasTooltip &&
          renderTooltip(this.targetRef, {
            ...this.props,
            shouldForceClose: hasRemoveUnavailableId
          })}
        {footer && expanded && (
          <div className="filter-options-footer">{footer}</div>
        )}
        {viewMoreLess}
      </div>
    );
  }
}
