import { UIState, OptimisticDelete } from '#/custom-typings/redux-store/ui.defs';
import { GROCERIES_MENU_KEY } from '#/selectors/ui/constants';

export const getUI = (state: Store): UIState => state.ui || {};

export const getIsFilterMenuOpen = ({ ui: { filterMenuOpen = false } }: Store): boolean => filterMenuOpen;

export const getIsGroceriesMenuOpen = ({ ui: { menuOpen } }: Store): boolean => menuOpen === GROCERIES_MENU_KEY;

export const getOptimisticDeleteSelection = (
  { ui: { optimisticDeleteSelection = {} } }: Store,
  page: string,
): OptimisticDelete['selections'] => {
  const { selections = [] } = optimisticDeleteSelection[page] || {};

  return selections;
};

export const getSoftDeleteStatusForOptimisticDelete = (
  { ui: { optimisticDeleteSelection = {} } }: Store,
  page: string,
): OptimisticDelete['softDelete'] => {
  const { softDelete = false } = optimisticDeleteSelection[page] || {};

  return softDelete;
};

export const getOptimisticDeleteError = (
  { ui: { optimisticDeleteSelection = {} } }: Store,
  page: string,
): OptimisticDelete['error'] => {
  const { error = false } = optimisticDeleteSelection[page] || {};

  return error;
};
