import { getCustomerUnitChoice } from '#/selectors/item';
import { getWeightOptions, TWeightOptionsData } from '#/selectors/beans-product-tile/weight-helpers';
import { WEIGHT_UNIT_KG } from '#/constants/common';
import { Item } from '#/lib/records/item';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TTranslation = (...args: any[]) => string;

type TWeightOnlyVariantOptionsData = [
  {
    fieldType: string;
    id: string;
    labelText: string;
    value: typeof WEIGHT_UNIT_KG;
  },
];

export type TWeightOnlyData = {
  options: TWeightOptionsData[];
  initialVariantValue?: string;
  variantOptions: {
    type: string;
    id: string;
    label: string;
    options: TWeightOnlyVariantOptionsData;
  };
};

const getWeightOnlyData = (state: Store, item: Item, translation: TTranslation): TWeightOnlyData => {
  const dataOptions: TWeightOptionsData[] = getWeightOptions(state, item);

  const variantOptionsData: TWeightOnlyVariantOptionsData = [
    {
      fieldType: 'dropdown',
      id: 'weight',
      labelText: translation('product-tile:toggle-weight'),
      value: WEIGHT_UNIT_KG,
    },
  ];

  return {
    variantOptions: {
      type: 'radio',
      id: 'toggle-quantity-weight',
      label: 'Toggle quantity and weight',
      options: variantOptionsData,
    },
    options: dataOptions,
    initialVariantValue: getCustomerUnitChoice(item),
  };
};

export default getWeightOnlyData;
