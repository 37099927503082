import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { connect } from '#/lib/render/connect-deep-compare';
import SearchFeedback from '#/components/search-feedback';
import MobilePagination from '#/components/pagination/mobile-pagination';
import ShowMore from './show-more';
import ProductList from '#/components/products/product-list';
import RecommenderWrapper from '#/components/recommender';
import helpers from '#/lib/decorators/helpers';
import {
  getTotalCount,
  getCount,
  getUpdatingStatus as resultsUpdating,
  getPages,
  getPageNo,
  getItemsPerPage
} from '#/reducers/results';
import { getIsUpdating as filtersUpdating } from '#/reducers/filter';
import { SEARCH, CATEGORY, PROMOTIONS } from '#/constants/result-types';
import { getDisplayArea } from '#/lib/recommender-helpers';
import { getPageResultsItemsByTypeSelector } from '#/selectors/results/get-page-items';
import { getBaseProductId, isProduct } from '#/selectors/item';
import { LEGACY } from '#/components/product-tiles/common/constants';
import { getAppRegion } from '#/reducers/app';
import Feedback from '#/components/feedback';
import BackToTopFixed from '#/components/shared/back-to-top/back-to-top-fixed';

import PaginationWrapperContainer from '#/experiments/oop-1801/components/pagination';
import { analyticsPageByName } from '#/constants/analytics';

const renderShowMore = () => <ShowMore />;

const mapStateToProps = state => {
  const getPageResultsItemsByType = getPageResultsItemsByTypeSelector(state);

  return {
    pages: getPages(state),
    items: getPageResultsItemsByType(state),
    pageNo: getPageNo(state),
    itemsPerPage: getItemsPerPage(state),
    totalCount: getTotalCount(state),
    count: getCount(state),
    isUpdating: resultsUpdating(state) || filtersUpdating(state),
    region: getAppRegion(state)
  };
};

@withRouter
@helpers(['c', 'f', 't'])
@connect(mapStateToProps)
class HasResults extends Component {
  static propTypes = {
    actionButtons: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    ),
    breadcrumbs: PropTypes.array,
    c: PropTypes.func.isRequired,
    count: PropTypes.number,
    dfp: PropTypes.object,
    experiments: PropTypes.object,
    f: PropTypes.func.isRequired,
    facetLists: PropTypes.array,
    hideFiltersOverlay: PropTypes.func,
    isAmendBasket: PropTypes.bool,
    isFixedBackToTopEnabled: PropTypes.bool,
    isGrid: PropTypes.bool,
    isGrouped: PropTypes.bool,
    isUpdating: PropTypes.bool,
    items: PropTypes.object.isRequired,
    itemsPerPage: PropTypes.number.isRequired,
    listData: PropTypes.object,
    pageNo: PropTypes.number.isRequired,
    pages: PropTypes.array.isRequired,
    productTileVariant: PropTypes.string,
    query: PropTypes.string,
    region: PropTypes.string,
    renderShowMore: PropTypes.func,
    resultType: PropTypes.string,
    scrollTopPos: PropTypes.number,
    sectionMessageHeadingTag: PropTypes.string,
    shouldUseDDSPagination: PropTypes.bool,
    showBrandsFilter: PropTypes.bool,
    showCategoriesFilter: PropTypes.bool,
    showDietaryFilter: PropTypes.bool,
    showFilters: PropTypes.bool,
    showMobilePagination: PropTypes.bool,
    showPagination: PropTypes.bool,
    showPromotionsFilter: PropTypes.bool,
    showRecommendedCarousel: PropTypes.bool,
    showSuperDepartmentFilter: PropTypes.bool,
    showUnavailableWarning: PropTypes.bool,
    sortOptions: PropTypes.array,
    t: PropTypes.func.isRequired,
    tileType: PropTypes.string,
    totalCount: PropTypes.number.isRequired,
    triggerAnalyticsEvent: PropTypes.bool,
    user: PropTypes.object,
    withListOverlay: PropTypes.bool
  };

  static defaultProps = {
    actionButtons: [],
    breadcrumbs: [],
    isGrouped: false,
    listData: {},
    renderShowMore: renderShowMore,
    resultType: '',
    scrollTopPos: 0,
    showMobilePagination: false,
    showFilters: true,
    showPromotionsFilter: true,
    showSuperDepartmentFilter: false,
    showCategoriesFilter: true,
    showUnavailableWarning: false,
    showPagination: true,
    triggerAnalyticsEvent: true,
    productTileVariant: LEGACY,
    withListOverlay: false,
    shouldUseDDSPagination: false
  };

  constructor(props) {
    super(props);
    this.configKey = {
      currency: this.props.c('currency'),
      recommendersEnabled: this.props.c('recommenders:enabled')
    };

    this.resultsWithTescoRecommendations = [SEARCH, CATEGORY, PROMOTIONS];
  }

  shouldComponentUpdate(nextProps) {
    // Dont trigger a re-render when the component status is already updating and hasn't changed...
    return !(
      this.props.isUpdating && this.props.isUpdating === nextProps.isUpdating
    );
  }

  renderMobilePagination(showDivider) {
    return (
      this.props.showPagination &&
      this.props.showMobilePagination &&
      this.props.totalCount > this.props.itemsPerPage && (
        <MobilePagination
          showDivider={showDivider}
          itemsCount={this.props.count || this.props.items.size}
          itemsPerPage={this.props.itemsPerPage}
          pageNo={this.props.pageNo}
          scrollTopPos={this.props.scrollTopPos}
          totalCount={this.props.totalCount}
        />
      )
    );
  }

  renderTescoRecommenders() {
    const { breadcrumbs, items, query, resultType } = this.props;

    const pageProductList = [];
    items.forEach(
      item => isProduct(item) && pageProductList.push(getBaseProductId(item))
    );

    const displayArea = getDisplayArea(breadcrumbs, resultType);
    const moduleType = resultType === CATEGORY ? displayArea : null;
    const page = resultType === CATEGORY ? moduleType : resultType;

    return (
      <RecommenderWrapper
        displayArea={displayArea}
        page={page}
        excludedProducts={pageProductList}
        moduleType={moduleType}
        query={query}
        configKey={resultType}
      />
    );
  }

  renderFeedback() {
    const {
      tileType,
      experiments: { shouldShowInteractiveStarRating = false } = {}
    } = this.props;

    return (
      <Feedback
        tileType={tileType}
        shouldShowInteractiveStarRating={shouldShowInteractiveStarRating}
      />
    );
  }

  render() {
    const {
      actionButtons,
      breadcrumbs,
      listData,
      resultType,
      query,
      pageNo,
      showPagination,
      items,
      itemsPerPage,
      count,
      totalCount,
      showMobilePagination,
      f,
      dfp,
      user,
      isAmendBasket,
      isGrid,
      isGrouped,
      pages,
      onRenderRecommendations,
      productTileVariant,
      sectionMessageHeadingTag,
      showUnavailableWarning,
      showRecommendedCarousel,
      facetLists,
      showFilters,
      showPromotionsFilter,
      showSuperDepartmentFilter,
      showCategoriesFilter,
      showDietaryFilter,
      showBrandsFilter,
      hideFiltersOverlay,
      tileType,
      triggerAnalyticsEvent,
      sortOptions,
      withListOverlay,
      experiments,
      renderProductFilters,
      shouldUseDDSPagination
    } = this.props;

    const paginator = showPagination && items && (
      <PaginationWrapperContainer
        shouldUseDDSPagination={shouldUseDDSPagination}
        pageNo={pageNo}
        itemsPerPage={itemsPerPage}
        itemsCount={count || items.size}
        totalCount={totalCount}
      />
    );

    const showMore =
      !showMobilePagination && this.props.renderShowMore?.(this.props);

    const mobilePagination = this.renderMobilePagination();
    const feedback = pageNo === 1 &&
      f('feedbackLinks') &&
      resultType === SEARCH && <SearchFeedback />;

    const showRecommendations =
      showRecommendedCarousel &&
      this.resultsWithTescoRecommendations.includes(resultType);

    return (
      <Fragment>
        {this.renderMobilePagination(true)}
        <ProductList
          actionButtons={actionButtons}
          breadcrumbs={breadcrumbs}
          dfp={dfp}
          experiments={experiments}
          userRegistered={!!(user || {}).isRegistered}
          isAmendBasket={isAmendBasket}
          isGrid={isGrid}
          isGrouped={isGrouped}
          query={query}
          pageNo={pageNo}
          pages={pages}
          items={items}
          itemsPerPage={itemsPerPage}
          totalCount={totalCount}
          onRenderRecommendations={onRenderRecommendations}
          productTileVariant={productTileVariant}
          sectionMessageHeadingTag={sectionMessageHeadingTag}
          showUnavailableWarning={showUnavailableWarning}
          facetLists={facetLists}
          showFilters={showFilters}
          showMobilePagination={showMobilePagination}
          showPromotionsFilter={showPromotionsFilter}
          showSuperDepartmentFilter={showSuperDepartmentFilter}
          showCategoriesFilter={showCategoriesFilter}
          showDietaryFilter={showDietaryFilter}
          showBrandsFilter={showBrandsFilter}
          hideFiltersOverlay={hideFiltersOverlay}
          listData={listData}
          tileType={tileType}
          triggerAnalyticsEvent={triggerAnalyticsEvent}
          resultType={resultType}
          sortOptions={sortOptions}
          withListOverlay={withListOverlay}
          {...(renderProductFilters && { renderProductFilters })}
        />
        {showMore}
        {this.renderFeedback()}
        {paginator}
        {!shouldUseDDSPagination && mobilePagination}
        {this.props.c('showBackToTopFixed') && (
          <BackToTopFixed analyticsType={analyticsPageByName[resultType]} />
        )}
        {showRecommendations && this.renderTescoRecommenders()}
        {feedback}
      </Fragment>
    );
  }
}

export default HasResults;
