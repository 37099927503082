import styled from 'styled-components';
import { spacing, colors, breakpoints } from '@ddsweb/selectors';

export const StyledDL = styled.dl`
  display: flex;
  justify-content: space-between;
  width: 94%;
  margin: 0 auto;
  padding: ${spacing.sm} 0;
  border-bottom: 1px solid ${colors.lines};
`;

export const StyledDT = styled.dt`
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakpoints.tabletLarge}px) {
    flex-direction: row;

    div:last-child {
      margin-left: ${spacing.xx};
    }
  }
`;
