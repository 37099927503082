import { createSelector } from 'reselect';
import { isDeliveryShoppingMethod, isOnDemandShoppingMethod } from '#/lib/shopping-method-util';
import { Slot } from '#/lib/records/slot.defs';
import { slotCurrentlyBooked, hasSlotExpired, getAvailableSlotsWithDistinctCharges } from '#/lib/slot/slot-utils';
import { getSelectedShoppingMethod, getDeliveryActiveSlotId, getSuggestionSelectedAddress } from '#/reducers/slot';
import { getLocations } from '#/reducers/location';
import { sessionStore } from '#/lib/data-store/client-store.js';
import { SUGGESTED_ADDRESS } from '#/constants/spa-resource';

import {
  getBookedShoppingMethod,
  getIsAmendBasket,
  getLastSelectedSlot,
  getSlot as getTrolleySlot,
  getTrolleyShoppingMethod,
  hasValidSlot,
} from '#/selectors/trolley';
import { isUnmannedClickCollectStore } from '#/selectors/location';
import {
  AVAILABLE,
  COLLECTION,
  DELIVERY,
  FULFILMENT_CAPACITY_FULL,
  IMMEDIATE,
  ON_DEMAND,
  OUTSIDE_WORKING_HOURS,
  ShoppingMethod,
  UNAVAILABLE,
} from '#/constants/shopping-methods';

import {
  DynamicDeliveryTime,
  FulfilmentOptionsDetail,
  GroupedShoppingMethod,
  Range,
  SlotState,
  SlotStatus,
  Variants,
} from '#/custom-typings/redux-store/slot.defs';
import { Moment } from 'moment-timezone';
import { Dispatch } from '#/custom-typings/redux-store/common';
import { openModal } from '#/actions/ui-action-creators';
import { UNAVAILABLE_SHOPPING_METHOD_MODAL } from '#/constants/modal-names';
import { getAppRegion } from '#/reducers/app';
import { IE, UK } from '#/constants/regions';
import { hasActiveDeliverySaverSubscriptions } from '#/reducers/subscriptions';
import { SLOT_BEFORE_DISCOUNT_PRICE } from '#/constants/slot-views';

const VALID_FULFILMENT_TILES: ShoppingMethod[] = [ON_DEMAND, DELIVERY, COLLECTION];

const sortByCategoryPriorityMap: { [key: string]: number } = {
  GHS_DELIVERY: 1,
  GHS_COLLECT: 2,
  SAME_DAY_DELIVERY: 4,
  IMMEDIATE: 6,
};

const sortByCategoryPriority = (a: GroupedShoppingMethod, b: GroupedShoppingMethod): number => {
  const groupedShoppingMethodA = sortByCategoryPriorityMap[a.category];
  const groupedShoppingMethodB = sortByCategoryPriorityMap[b.category];

  return groupedShoppingMethodA - groupedShoppingMethodB;
};

const sortGroupedShoppingMethods = (groupedShoppingMethods: GroupedShoppingMethod[]): GroupedShoppingMethod[] => {
  return [...groupedShoppingMethods].sort(sortByCategoryPriority);
};

const mapShoppingMethodTabs = (
  selectedShoppingMethod: ShoppingMethod,
  groupedShoppingMethods: GroupedShoppingMethod[],
): ShoppingMethod[] => {
  return groupedShoppingMethods.map(tab => {
    const { category, methods } = tab;

    if (category === IMMEDIATE) {
      return methods.includes(selectedShoppingMethod) ? selectedShoppingMethod : methods[0];
    }

    return methods[0];
  });
};

const getSlot = (state: Store): SlotState => {
  return state.slot || {};
};

export const getAvailableShoppingMethods = (state: Store): ShoppingMethod[] =>
  getSlot(state).fulfilmentOptionsDetail?.availableShoppingMethods;

export const getUnavailableShoppingMethods = (state: Store): string[] => {
  const options = getSlot(state).fulfilmentOptionsDetail?.options;
  const unavailableSM: string[] = [];

  options.map(({ variants }: { variants: Variants[] }) => {
    variants.map((variant: Variants) => {
      const { availability } = variant;
      if (availability?.status === UNAVAILABLE) {
        unavailableSM.push(variant.type);
      }
    });
  });
  return unavailableSM;
};

export const getShoppingMethodsTabs = (state: Store): ShoppingMethod[] => {
  const groupedShoppingMethods = getGroupedShoppingMethods(state);
  const orderedGroupedShoppingMethods = sortGroupedShoppingMethods(groupedShoppingMethods);
  const selectedShoppingMethod = getSelectedShoppingMethod(state);

  return mapShoppingMethodTabs(selectedShoppingMethod, orderedGroupedShoppingMethods);
};

export const getGroupedShoppingMethods = ({
  slot: {
    fulfilmentOptionsDetail: { groupedShoppingMethods },
  },
}: Store): GroupedShoppingMethod[] => groupedShoppingMethods;

export const getSlotHubFulfilmentTiles = (state: Store): string[] => {
  const availableShoppingMethods = getAvailableShoppingMethods(state) || [];
  const unAvailableShoppingMethods = getUnavailableShoppingMethods(state) || [];

  const sortedAvailableShoppingMethods = VALID_FULFILMENT_TILES.filter(shoppingMethod =>
    availableShoppingMethods.includes(shoppingMethod),
  ).sort((a, b) => availableShoppingMethods.indexOf(a) - availableShoppingMethods.indexOf(b));
  const sortedUnAvailableShoppingMethods = VALID_FULFILMENT_TILES.filter(shoppingMethod =>
    unAvailableShoppingMethods.includes(shoppingMethod),
  ).sort((a, b) => unAvailableShoppingMethods.indexOf(a) - unAvailableShoppingMethods.indexOf(b));

  return sortedAvailableShoppingMethods
    .concat(sortedUnAvailableShoppingMethods)
    .filter((v, i, a) => a.indexOf(v) === i);
};

export const getFulfilmentSlots = ({ slot }: Store): Slot[] | {} => slot.slots || {};

export const getChangeSlotInfo = ({ slot }: Store): string => slot.ui.changeSlotInfo as string;

export const getHomeDeliverySlot = createSelector(
  getDeliveryActiveSlotId,
  getFulfilmentSlots,
  (slotId, slots: { [key: string]: any }) => {
    return slots?.[(slotId as unknown) as string];
  },
);

export const getFulfilmentLocation = (state: Store) => getSlot(state).ui.fulfilmentLocation;

export const getSlotStart = (slot: Slot): string | Moment | null => slot.start;

export const getSlotStatus = (slot: Slot): string | null => slot.status as SlotStatus;

export const getSlotEnd = (slot: Slot): string | Moment | null => slot.end;

export const getSlotCharge = (slot: Slot): number | undefined => slot.charge;

export const getSlotLocationId = (slot: Slot): string | null => slot.locationId;

export const getSlotReservationExpiry = (slot: Slot): string | Moment | null | undefined => slot.reservationExpiry;

export const getIsCollectionSlotBookedOrExpired = (state: Store): string | boolean => {
  const bookedShoppingMethod = getBookedShoppingMethod(state);
  const currentSlot = getTrolleySlot(state);

  return (
    bookedShoppingMethod === COLLECTION &&
    currentSlot &&
    (slotCurrentlyBooked(currentSlot) || hasSlotExpired(currentSlot))
  );
};

export const getIsRenderTrialClickAndCollectNotification = (state: Store): boolean => {
  return getSelectedShoppingMethod(state) === COLLECTION && isUnmannedClickCollectStore(state);
};

export const getBookedSlotLocation = (state: Store) => {
  const locations = getLocations(state);
  const bookedSlot =
    getTrolleyShoppingMethod(state) === getSelectedShoppingMethod(state) ? getLastSelectedSlot(state) : undefined;
  return bookedSlot && locations.find((loc: { locationId: string }) => loc.locationId === bookedSlot.locationId);
};

export const getIsRenderShoppingMethodInstruction = (state: Store): boolean => {
  return hasValidSlot(state) && getSelectedShoppingMethod(state) === getTrolleyShoppingMethod(state);
};

const getDeliveryFulfilmentOption = ({ options }: FulfilmentOptionsDetail) =>
  options?.length > 0 &&
  options.find(({ type }: { type: string; variants: Variants[] }) => type?.toLowerCase() === DELIVERY);

const getFulfilmentOption = ({ variants }: Record<string, Variants[]>): Variants | undefined =>
  variants?.find(({ type }: { type: string; id: string }) => type?.toLowerCase() === ON_DEMAND);

export const getOnDemandFulfilmentDetails = createSelector(getDeliveryFulfilmentOption, deliveryFulfilmentDetails =>
  getFulfilmentOption((deliveryFulfilmentDetails as unknown) as Record<string, Variants[]>),
);

export const getOnDemandServiceAvailable = ({ slot: { fulfilmentOptionsDetail } }: Store): boolean =>
  fulfilmentOptionsDetail?.options?.length > 0 &&
  getOnDemandFulfilmentDetails(fulfilmentOptionsDetail)?.availability?.status === AVAILABLE;

export const getOnDemandServicePossible = ({ slot: { fulfilmentOptionsDetail } }: Store): boolean => {
  if (fulfilmentOptionsDetail?.options?.length < 1) {
    return false;
  }

  const { status, reason } = getOnDemandFulfilmentDetails(fulfilmentOptionsDetail)?.availability ?? {};
  return status === AVAILABLE || reason === OUTSIDE_WORKING_HOURS || reason === FULFILMENT_CAPACITY_FULL;
};

export const getServiceAvailableByShoppingMethod = (state: Store, shoppingMethod: ShoppingMethod): boolean => {
  let isServiceAvailable = true;
  if (shoppingMethod === ON_DEMAND) {
    isServiceAvailable = getOnDemandServiceAvailable(state);
  }

  return isServiceAvailable;
};

export const getOnDemandServiceAvailableTime = ({ slot: { fulfilmentOptionsDetail } }: Store): Range =>
  (fulfilmentOptionsDetail?.options?.length > 0 &&
    getOnDemandFulfilmentDetails(fulfilmentOptionsDetail)?.availability?.range) as Range;

export const getIsOndemandSlotBooked = (state: Store): boolean => {
  const bookedShoppingMethod = getBookedShoppingMethod(state);
  const currentSlot = getTrolleySlot(state);
  return isOnDemandShoppingMethod(bookedShoppingMethod) && currentSlot && slotCurrentlyBooked(currentSlot);
};

export const isUnavailableHDSlotWithCollectLink = (state: Store, config: Function): boolean => {
  const selectedShoppingMethod = getSelectedShoppingMethod(state);

  return (
    !getIsAmendBasket(state) &&
    isDeliveryShoppingMethod(selectedShoppingMethod) &&
    config('showCollectLinkForUnavailableHDSlot')
  );
};

export const getOnDemandUnavailableReason = (state: Store): string | null | false | undefined => {
  const {
    slot: { fulfilmentOptionsDetail },
  } = state;
  return (
    !getOnDemandServiceAvailable({ slot: { fulfilmentOptionsDetail } } as Store) &&
    fulfilmentOptionsDetail?.options?.length > 0 &&
    (getOnDemandFulfilmentDetails(fulfilmentOptionsDetail)?.availability?.reason as string)?.toLowerCase()
  );
};

export const getOnDemandDeliverySlot = ({ slot: { fulfilmentOptionsDetail } }: Store) => {
  return {
    slotId: getOnDemandFulfilmentDetails(fulfilmentOptionsDetail)?.id,
  };
};

export const getDeliverySlot = (state: Store, shoppingMethod: ShoppingMethod) => {
  if (shoppingMethod === ON_DEMAND) return getOnDemandDeliverySlot(state);
  return getHomeDeliverySlot(state);
};

export const getDeliveryCharge = (state: Store, shoppingMethod: ShoppingMethod): number | undefined => {
  const {
    slot: { fulfilmentOptionsDetail },
  } = state;
  if (shoppingMethod === ON_DEMAND) {
    const details = getOnDemandFulfilmentDetails(fulfilmentOptionsDetail);

    return details?.charge;
  }
  return;
};

export const getOnDemandSupported = createSelector<Store, ShoppingMethod[], boolean>(
  getAvailableShoppingMethods,
  availableShoppingMethods => {
    return availableShoppingMethods.includes(ON_DEMAND);
  },
);

export const getOrderedGroupedShoppingMethods = createSelector<
  Store,
  GroupedShoppingMethod[],
  boolean,
  GroupedShoppingMethod[]
>(getGroupedShoppingMethods, getOnDemandServicePossible, (groupedShoppingMethods, onDemandServiceAvailable) => {
  const orderedGroupedShoppingMethods = [...groupedShoppingMethods];
  const whoosh = orderedGroupedShoppingMethods.find(shoppingMethod => shoppingMethod.category === IMMEDIATE);

  if (!whoosh) return orderedGroupedShoppingMethods;

  const indexOfWhoosh = orderedGroupedShoppingMethods.findIndex(
    shoppingMethod => shoppingMethod.category === IMMEDIATE,
  );
  orderedGroupedShoppingMethods.splice(indexOfWhoosh, 1); //remove current whoosh

  if (onDemandServiceAvailable) {
    orderedGroupedShoppingMethods.unshift(whoosh);
  }

  return orderedGroupedShoppingMethods;
});

export const getHasWhooshShoppingMethod = createSelector<Store, GroupedShoppingMethod[], boolean>(
  getOrderedGroupedShoppingMethods,
  orderedGroupedShoppingMethods =>
    orderedGroupedShoppingMethods.find(shoppingMethod => shoppingMethod.category === IMMEDIATE) !== undefined,
);

export const getOnDemandDynamicDeliveryTime = ({ slot: { fulfilmentOptionsDetail } }: Store): DynamicDeliveryTime => {
  if (fulfilmentOptionsDetail?.options?.length > 0) {
    const onDemandFulfilmentDetails = getOnDemandFulfilmentDetails(fulfilmentOptionsDetail);

    if (onDemandFulfilmentDetails?.fulfilmentEstimatedArrival?.time) {
      return {
        ...onDemandFulfilmentDetails?.fulfilmentEstimatedArrival?.time,
        isDynamicDeliveryTimeAvailable: true,
      };
    }
  }
  return {
    isDynamicDeliveryTimeAvailable: false,
  };
};

export const getFulfilmentEstimatedTime = ({ slot: { fulfilmentEstimatedArrival } }: Store): DynamicDeliveryTime => {
  if (fulfilmentEstimatedArrival?.time) {
    return {
      ...fulfilmentEstimatedArrival.time,
      isDynamicDeliveryTimeAvailable: true,
    };
  }
  return {
    isDynamicDeliveryTimeAvailable: false,
  };
};

export const getIsOnDemandShoppingMethodSelected = (state: Store) => {
  const selectedShoppingMethod = getSelectedShoppingMethod(state);
  return isOnDemandShoppingMethod(selectedShoppingMethod);
};

export const showOnDemandUnavailableModal = (
  state: Store,
  dispatch: Dispatch,
  availableShoppingMethods: ShoppingMethod[],
): void => {
  const isOnDemandShoppingMethodSelected = getIsOnDemandShoppingMethodSelected(state);

  if (availableShoppingMethods && !availableShoppingMethods.includes(ON_DEMAND) && isOnDemandShoppingMethodSelected) {
    dispatch(openModal(UNAVAILABLE_SHOPPING_METHOD_MODAL, null, false));
  }
};

const getBestValueSlotsInFirstWeek = (actionableSlots: Slot[]): Slot[] => {
  const charges = actionableSlots.map((slot: Slot) => slot.charge!);
  const bestValue = Math.min(...charges);
  const allBestValueSlots: Slot[] = actionableSlots.filter((slot: Slot) => slot.charge === bestValue);

  return allBestValueSlots as Slot[];
};

export const getSearchedAddress = createSelector(
  getSuggestionSelectedAddress,
  suggestedSelectedAddress => suggestedSelectedAddress || sessionStore?.get(SUGGESTED_ADDRESS),
);

export const getBestValueSlots = (state: Store, slots: Slot[]): null | (string | null)[] => {
  if (hasActiveDeliverySaverSubscriptions(state.subscriptions) || getAppRegion(state) !== UK) {
    return null;
  }

  const actionableSlots = getAvailableSlotsWithDistinctCharges(slots);

  if (!actionableSlots) {
    return null;
  }

  const bestValueSlots = getBestValueSlotsInFirstWeek(actionableSlots);

  return bestValueSlots.map(({ slotId }) => slotId);
};

export const getSlotBeforeDiscountPrice = (): number | null => {
  if (!sessionStore) {
    return null;
  }

  return Number(sessionStore.get(SLOT_BEFORE_DISCOUNT_PRICE)) || null;
};

export const showIncludedInYourPlanCopy = (state: Store, slotCharge: number): boolean => {
  const slotBeforeDiscountPriceFromStorage = getSlotBeforeDiscountPrice();

  if (slotBeforeDiscountPriceFromStorage === null) {
    return false;
  }

  if (slotCharge > 0 && slotCharge === slotBeforeDiscountPriceFromStorage) {
    return false;
  }

  return slotBeforeDiscountPriceFromStorage > slotCharge && slotCharge === 0;
};

export const getCanAmendSlotChange = createSelector(
  getAppRegion,
  getIsOnDemandShoppingMethodSelected,
  (region, isOndemandShoppingMethod) => [UK, IE].includes(region) && !isOndemandShoppingMethod,
);

export const getShowAmendSlotChangeCopy = createSelector(
  getAppRegion,
  getIsOnDemandShoppingMethodSelected,
  getIsAmendBasket,
  (region, isOndemandShoppingMethod, isAmendBasket) =>
    [UK, IE].includes(region) && !isOndemandShoppingMethod && isAmendBasket,
);
