/**
 * Experiment constants for fav Last Order V2
 */

export enum favoritesRecentOrderVariants {
  default = 'default',
  newDropdown = 'new-dropdown',
  ddsDropdown = 'dds-dropdown',
}

export const favoritesRecentOrder = {
  featureKey: 'GHS-UK_myFavouritesRecentOrder',
  variationKey: 'experience',
  useCustomerId: true,
};

export const RECENT_ORDER_TYPE = 'favourites:recent order drop down';
export const PREVIOUS_ORDER_VALUE = 'favourites:previous order';
export const TOTAL_ORDER = 'total order';
export const RECENT_ORDER_IMPRESSION = 'impression';
export const RECENT_ORDER_SELECTED = 'selected';
export const RECENT_ORDER_ADD_ALL_PRODUCTS = 'addAllProducts';
export const RECENT_ORDER_DROPDOWN_ID = 'recent-order-dropdown-id';
