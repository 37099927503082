import doesResourceListInclude from '#/conditional-resources/helpers/does-resource-list-include';
import mergeResourceConfigOptions from '#/conditional-resources/helpers/merge-resource-config-options';
import spliceMatchingResourceFromList from '#/conditional-resources/helpers/splice-matching-resource-from-list';

export default function getResourcesFromRoutes(routes) {
  return routes.reduce((resources, route) => {
    if (!Array.isArray(route.resources) || !route.resources.length) {
      return resources;
    }

    return [...resources, ...route.resources].reduce(
      (routeResources, resource) => {
        if (!doesResourceListInclude(routeResources, resource)) {
          return [...routeResources, resource];
        }

        if (Array.isArray(resource)) {
          const matchingResource = spliceMatchingResourceFromList(
            routeResources,
            resource
          );

          if (typeof matchingResource === 'string') {
            return [...routeResources, resource];
          }

          return [
            ...routeResources,
            [
              resource[0],
              mergeResourceConfigOptions(matchingResource[1], resource[1])
            ]
          ];
        }

        return routeResources;
      },
      []
    );
  }, []);
}
