import React from 'react';
import PropTypes from 'prop-types';
import helpers from '#/lib/decorators/helpers';
import { SectionMessage } from '../section-message';
import {
  VIEW_AND_BUY_TYPE_FAVORITES,
  VIEW_AND_BUY_TYPE_OFFERS
} from '#/constants/display-types';
import { connect } from '#/lib/render/connect-deep-compare';
import { getPromotedOffers } from '#/reducers/promoted-offers';
import { getIsAmendBasket } from '#/selectors/trolley';
import ViewAndBuyOffers from './view-and-buy-offers';

const mapStateToProps = (state, ownProps) => ({
  content: ownProps.content || getPromotedOffers(state),
  isAmendBasket: getIsAmendBasket(state)
});

const SpecialOffers = props => {
  const { content, addICIDToUrl, type, t: translate } = props;
  const sectionText = translate('product-list:promoted-offers');

  return (
    !!content.length && (
      <div className="cms-special-offers">
        <div className="product-list__section-message">
          <SectionMessage message={sectionText} />
        </div>
        <div className="product-list-container">
          <div className="product-lists">
            <ViewAndBuyOffers
              addICIDToUrl={addICIDToUrl}
              content={content}
              viewAndBuyType={type}
            />
          </div>
        </div>
      </div>
    )
  );
};

SpecialOffers.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['tpnb', 'promotion', 'buylists']),
      linkTo: PropTypes.string,
      products: PropTypes.array.isRequired
    })
  ),
  isAmendBasket: PropTypes.bool,
  t: PropTypes.func.isRequired,
  type: PropTypes.oneOf([VIEW_AND_BUY_TYPE_FAVORITES, VIEW_AND_BUY_TYPE_OFFERS])
};

SpecialOffers.defaultProps = {
  type: VIEW_AND_BUY_TYPE_OFFERS
};

export default connect(mapStateToProps)(helpers('t')(SpecialOffers));
