import { DO_NOT_SUBSTITUTE, FIND_SUITABLE_ALTERNATIVE } from '#/constants/substitution-options';
import { CustomerUnitChoice, SubstitutionOption } from '#/lib/records/item.defs';
import { getQuantity, getUnit } from '#/selectors/item';
import { Item } from '#/lib/records/item';

export type Preference = {
  pickerNote: string;
  subs: boolean;
};

export type PreferencePayload = {
  adjustment: boolean;
  id: string;
  newUnitChoice: CustomerUnitChoice;
  newValue: number;
  oldUnitChoice: CustomerUnitChoice;
  oldValue: number;
  pickerNote: string;
  substitutionOption: SubstitutionOption;
};

export default function preferencePayload(item: Item, preference: Preference): Array<PreferencePayload> {
  const payload = [] as Array<PreferencePayload>;
  const qty = getQuantity(item) as number;
  const unit = getUnit(item) as CustomerUnitChoice;
  const { pickerNote, subs } = preference as Preference;
  const substitutionOption = (subs ? FIND_SUITABLE_ALTERNATIVE : DO_NOT_SUBSTITUTE) as SubstitutionOption;

  payload.push({
    adjustment: true,
    id: item.product.id,
    newUnitChoice: unit,
    oldUnitChoice: unit,
    oldValue: qty,
    newValue: qty,
    pickerNote,
    substitutionOption,
  });

  return payload;
}
