import { transformPromotionsSpecialOffersContent } from '#/utils/dcs-utils';

export default {
  deserialize: (data = {}) => {
    return {
      ...data,
      content: transformPromotionsSpecialOffersContent(
        (data && data.content) || {}
      )
    };
  }
};
