import React from 'react';
import PropTypes from 'prop-types';

const slideLabel = (slide, styles, onHover, index) => {
  const hoverHandler = onHover.bind(null, index);

  return (
    <li key={index} className="ui-responsive-slider--slideTab" style={styles}>
      <label
        htmlFor={`ui-responsive-slider--${index}`}
        className={`ui-responsive-slide-tab--${index}`}
        onMouseEnter={hoverHandler}
      >
        <span>{slide.tabTitle}</span>
        <span className="chevron icon-chevron_right-light_div" />
        <span className="slide-pointer" />
      </label>
    </li>
  );
};

const SlidePicker = ({ slides, onHover = () => {} }) => {
  const height = `${352 / slides.length}px`;
  const styles = {
    height,
    lineHeight: height
  };

  return (
    <ul className="ui-responsive-slider--slide-picker">
      {slides.map((slide, i) => slideLabel(slide, styles, onHover, i))}
    </ul>
  );
};

SlidePicker.propTypes = {
  onHover: PropTypes.func,
  slides: PropTypes.array.isRequired
};

export { SlidePicker };
