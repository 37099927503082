import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { ProductPromotionItem } from '#/components/products/product-promotion';
import {
  getCurrentUrl,
  getLanguage,
  getTimezone,
  getLanguageLink,
  getMakeLanguageLink
} from '#/reducers/app';

import { useProductPromotions } from './hooks';

import helpers from '#/lib/decorators/helpers';

const mapStateToProps = (state, ownProps) => ({
  currentUrl: getCurrentUrl(state),
  language: getLanguage(state),
  promotionsLink: getLanguageLink(state, ownProps.promotionsLink),
  timezone: getTimezone(state),
  makeLanguageLink: getMakeLanguageLink(state)
});

export const ProductPromotion = ({
  promotion,
  offersIcid,
  t: translation,
  c: config,
  timezone,
  language,
  showDatesOnlyOnMissedSlot,
  warnings,
  tile,
  currentUrl,
  showPromotionInfoBox,
  makeLanguageLink,
  ...props
}) => {
  const hooks = useProductPromotions(
    promotion,
    offersIcid,
    timezone,
    language,
    translation,
    config,
    showDatesOnlyOnMissedSlot,
    warnings,
    tile,
    currentUrl,
    showPromotionInfoBox,
    makeLanguageLink
  );

  return (
    <ProductPromotionItem
      currentUrl={currentUrl}
      item={promotion}
      tile={tile}
      {...props}
      {...hooks}
    />
  );
};

export default compose(
  connect(mapStateToProps),
  helpers(['c', 'hasFeature', 't'])
)(ProductPromotion);

ProductPromotion.propTypes = {
  isAlreadyShowingRdgMsg: PropTypes.bool,
  isAmendBasket: PropTypes.bool,
  offersIcid: PropTypes.string,
  promotion: PropTypes.object.isRequired,
  showCompletePromoMessage: PropTypes.bool,
  showDatesOnlyOnMissedSlot: PropTypes.bool,
  showPromotionInfoBox: PropTypes.bool,
  tile: PropTypes.string
};

ProductPromotion.defaultProps = {
  showCompletePromoMessage: true,
  isAmendBasket: false,
  showPromotionInfoBox: false,
  showDatesOnlyOnMissedSlot: false
};
