import { getLanguageLink } from '#/reducers/app';
import { getIsAmendBasket, getTrolleyShoppingMethod } from '#/selectors/trolley';
import { getDefaultSlotsPath } from '#/reducers/slot';
import {
  isInBasket,
  isExcluded,
  getIsNewlyAdded,
  getProductIsForSale,
  hasRestrictedOrderAmendment,
  isAldiPriceMatch,
  isLowEverydayPricing,
  isClubcardPromotionOnItem,
} from '#/selectors/item';
import {
  getAllInfoMessages,
  buildRestrictedOrderAmendmentMessage,
  TVariableWeightMessageData,
  TExcludedProductMessageData,
  TBulkBuyLimitMessageData,
  TCombinedRDGMessagesData,
  TUnavailableMessageData,
  TStockQtyExceededMessage,
} from '#/selectors/beans-product-tile/restrictions-data';
import { isProductAvailable } from '#/selectors/beans-product-tile/product-availability-data';
import { Item } from '#/lib/records/item';
import { isOnDemandShoppingMethod } from '#/lib/shopping-method-util';

type TTranslateFunc = (...args: any[]) => string;
type TConfigFunc = (configKey?: string[] | string) => string;
type TFeatureFunc = (featureKey?: string[] | string) => string;

export type TInfoMessageComponent =
  | TVariableWeightMessageData
  | TExcludedProductMessageData
  | TBulkBuyLimitMessageData
  | TCombinedRDGMessagesData
  | TUnavailableMessageData
  | TStockQtyExceededMessage;

export type TInfoMessageTexts = {
  messages: string[];
  backgroundColorClass?: string | null;
  hasBackgroundColor?: boolean;
};

export type TInfoMessageLinkComponent = {
  link: {
    href: string;
    onClick?: ({ ...props }: any) => any;
    styles?: object;
    text?: string;
  };
};

export type TInfoMessageFormat = {
  messages: TInfoMessageComponent[];
  changeSlotLink?: {
    href: string;
    onClick?: ({ ...props }: any) => any;
    styles?: object;
    text?: string;
  };
} | null;

export type TRestrictedOrderAmendmentMessageFormat = {
  message: string | null;
  backgroundColorClass: string;
} | null;

export type TRestrictionMessagesData = {
  infoMessages: {
    display: boolean;
    data: TInfoMessageFormat;
  };
  restrictedOrderAmendmentMessage: {
    display: boolean;
    data: TRestrictedOrderAmendmentMessageFormat;
  };
  aldiPriceMatchMessage: {
    display: boolean;
  };
  lowEverydayPricingMessage: {
    display: boolean;
  };
};

const isExcludedInAmendBasket = (item: Item, isAmendBasket: boolean): boolean =>
  isAmendBasket && isInBasket(item) && isExcluded(item) && !getIsNewlyAdded(item) ? true : false;

const shouldRestrictedOrderAmendmentMessageAppear = (item: Item): boolean =>
  getProductIsForSale(item) && hasRestrictedOrderAmendment(item);

export const getInfoMessages = (
  state: any,
  item: Item,
  isAmendBasket: boolean,
  excludeMessages: string[],
  translate: TTranslateFunc,
  config: TConfigFunc,
  feature?: TFeatureFunc,
  isTrolley?: boolean,
): TInfoMessageFormat => {
  // @ts-expect-error the typing of getLanguageLink is not correct typed in a js file
  const link = getLanguageLink(state, getDefaultSlotsPath(feature)(state), null);
  const shoppingMethod = getTrolleyShoppingMethod(state);
  const isOnDemandDelivery = isOnDemandShoppingMethod(shoppingMethod);

  return getAllInfoMessages(
    state,
    item,
    isExcludedInAmendBasket(item, isAmendBasket),
    excludeMessages,
    translate,
    config,
    link,
    isTrolley,
    isOnDemandDelivery,
  );
};

const getRestrictedOrderAmendmentMessage = (
  item: Item,
  restrictedOrderAmendmentOverrideMessage: boolean,
): TRestrictedOrderAmendmentMessageFormat | null => {
  return shouldRestrictedOrderAmendmentMessageAppear(item)
    ? buildRestrictedOrderAmendmentMessage(item, restrictedOrderAmendmentOverrideMessage)
    : null;
};

const showMessageForProduct = (item: Item, isAmendBasket: boolean, config: TConfigFunc): boolean => {
  return (
    !!config('showProductUnavailableMessages') ||
    getProductIsForSale(item) ||
    isExcludedInAmendBasket(item, isAmendBasket)
  );
};

const shouldInfoMessagesAppear = (
  restrictedOrderAmendmentOverrideMessage: boolean,
  item: Item,
  isAmendBasket: boolean,
  config: TConfigFunc,
): boolean =>
  !getRestrictedOrderAmendmentMessage(item, restrictedOrderAmendmentOverrideMessage) &&
  showMessageForProduct(item, isAmendBasket, config);

export const getInfoMessagesData = ({
  state,
  item,
  translate,
  restrictedOrderAmendmentOverrideMessage,
  config,
  feature,
  isTrolley,
}: {
  state: Store;
  item: Item;
  translate: TTranslateFunc;
  restrictedOrderAmendmentOverrideMessage: boolean;
  config: TConfigFunc;
  feature?: TFeatureFunc;
  isTrolley?: boolean;
}): TRestrictionMessagesData => {
  const isAmendBasket = getIsAmendBasket(state);
  const excludeMessages: string[] = [];
  const showInfoMessages = shouldInfoMessagesAppear(
    restrictedOrderAmendmentOverrideMessage,
    item,
    isAmendBasket,
    config,
  );
  const showRestrictedOrderAmendmentMessage = shouldRestrictedOrderAmendmentMessageAppear(item);
  const showAldiPriceMatch = isAldiPriceMatch(item);
  const showLowEverydayPricing = isLowEverydayPricing(item);
  const showClubcardPromotion = isClubcardPromotionOnItem(item);

  return {
    infoMessages: {
      display: showInfoMessages,
      data: showInfoMessages
        ? getInfoMessages(state, item, isAmendBasket, excludeMessages, translate, config, feature, isTrolley)
        : null,
    },
    restrictedOrderAmendmentMessage: {
      display: showRestrictedOrderAmendmentMessage,
      data: showRestrictedOrderAmendmentMessage
        ? getRestrictedOrderAmendmentMessage(item, restrictedOrderAmendmentOverrideMessage)
        : null,
    },
    aldiPriceMatchMessage: {
      display: showAldiPriceMatch && isProductAvailable(state, item) && !showClubcardPromotion,
    },
    lowEverydayPricingMessage: {
      display: showLowEverydayPricing && !showAldiPriceMatch && !showClubcardPromotion,
    },
  };
};
