import createItem, { Item } from '#/lib/records/item';
import { cloneItems } from '#/utils/clone-items';
import { ResultType } from '#/experiments/oop-2021/types';

type MutableItem = {
  -readonly [K in keyof Item]: Item[K];
};

export const cloneItem = (item: Item, cb?: (i: MutableItem) => void): Item => {
  if (!item?.typename || item?.typename === ResultType.PRODUCT) {
    const clonedItem = createItem({
      ...item,
      product: {
        ...item.product,
        ...{
          ...(item.product?.restrictions && { restrictions: [...item.product.restrictions] }),
          ...(item.product?.substitutions && { substitutions: cloneItems(item.product.substitutions) }),
        },
      },
      ...(item.alternativeCategories && {
        alternativeCategories: [...item.alternativeCategories],
      }),
      ...(item.promotions && { promotions: [...item.promotions] }),
      ...(item.seedProduct && { seedProduct: { ...item.seedProduct } }),
      ...(item.restrictionDeliveryGroups && { restrictionDeliveryGroups: { ...item.restrictionDeliveryGroups } }),
    });
    typeof cb === 'function' && cb(clonedItem);
    return clonedItem;
  }

  return item;
};
