import { getStoreId } from '#/selectors/trolley';
import { getFeatureVariableStringFromState } from '#/lib/optimizely-manager';
import { isOnDemandShoppingMethod } from '#/lib/shopping-method-util';
import { getTrolleyShoppingMethod } from '#/selectors/trolley';

const whooshSubstitutionConfig = {
  featureKey: 'ghs-uk_whoosh_subs',
  serviceConfig: {
    params: {
      whoosh_stores: [],
    },
  },
  useCustomerId: true,
};

export const getIsWhooshDeliverySubstitutionsEnabled = (state: Store): boolean => {
  const supportedWhooshStores = getFeatureVariableStringFromState(
    state,
    whooshSubstitutionConfig.featureKey,
    'whoosh_stores',
    {},
    true,
  );

  const storeId = getStoreId(state);
  const shoppingMethod = getTrolleyShoppingMethod(state);

  try {
    return (
      supportedWhooshStores
        .split(',')
        .map((element: string) => element.trim())
        .includes(storeId) && isOnDemandShoppingMethod(shoppingMethod)
    );
  } catch (e) {
    return false;
  }
};
