import { AddressSelectorState } from '#/custom-typings/redux-store/address-selector.defs';
import { TAccountSettings } from '#/reducers/account-page';

export interface TAddressSelectorProps {
  accountSettings?: TAccountSettings;
}

export default (state: AddressSelectorState = {}) => state;

export const getDefaultStateFromProps = ({ accountSettings }: TAddressSelectorProps): AddressSelectorState => {
  if (!accountSettings) {
    return {};
  }

  return {
    addressMetadata: accountSettings.addressMetadata || null,
  };
};

export const getAddressMetadata = ({ addressSelector = {} }: { addressSelector?: AddressSelectorState }) =>
  addressSelector.addressMetadata || null;
