import styled, { css, StyledComponent } from 'styled-components';
import Button from '@ddsweb/button';
import Heading from '@ddsweb/heading';
import Text from '@ddsweb/text';
import { colors, spacing } from '@ddsweb/selectors';
import Modal, { ModalProps } from '@ddsweb/modal';
import { media } from '@ddsweb/foundation';
import { defaultTheme } from '@ddsweb/theme';

export const StyledHeading = styled(Heading)`
  margin: ${spacing.xs} 0 ${spacing.md};
`;

export const StyledText = styled(Text)`
  color: ${colors.grayscale};
  margin-bottom: ${spacing.md};
  line-height: ${defaultTheme.lineHeightNext.text.md};
`;
// Receives Prop and pushes different styles for grid and flex
export const StyledButtonContainer = styled.div`
  display: ${(props: { bodyContainerDisplayStyle: string }): string => props.bodyContainerDisplayStyle};
  ${(props: { bodyContainerDisplayStyle: string }): string => {
    return props.bodyContainerDisplayStyle === 'grid' ? 'grid-template-columns: auto auto;' : '';
  }}
  margin-top: ${spacing.xl};
  gap: ${spacing.md};
  ${media.belowTabletLarge`
     ${(props: { bodyContainerDisplayStyle: string }): string => {
       return props.bodyContainerDisplayStyle === 'flex' ? 'flex-direction: column' : '';
     }}
  `}
  ${media.belowMobileLarge`
     ${(props: { bodyContainerDisplayStyle: string }): string => {
       return props.bodyContainerDisplayStyle === 'grid' ? ' grid-template-columns: 1fr' : '';
     }}
  `}
`;

const ModalButtonStyle = css`
  overflow: hidden;
`;

export const StyledButton = styled(Button)`
  ${ModalButtonStyle}
`;

export const StyledModal: StyledComponent<typeof Modal, ModalProps> = styled(Modal)`
  ${Modal.ModalContainer} {
    height: auto;
    min-width: 296px;
  }
`;
