import {
  RELATED_SEARCH_ANALYTICS_TYPE,
  RELATED_SEARCH_STORAGE_KEY,
  RELATED_SEARCH_CONFIG,
} from '#/experiments/oop-1826/constants';
import { sessionStore } from '#/lib/data-store/client-store';
import analyticsBus from '#/analytics/analyticsBus';
import { basicEvent } from '#/analytics/types/basic';
import { NOW } from '#/analytics/constants';

interface SuggestionTypesWithCount {
  [key: string]: number;
}

export function triggerRelatedSearchAnalyticsEvent(): void {
  const fetchSessionStorage = (sessionStore?.get(RELATED_SEARCH_STORAGE_KEY) || {}) as SuggestionTypesWithCount;
  const suggestionTypeCount = (fetchSessionStorage[RELATED_SEARCH_CONFIG] ?? 0) + 1;
  const suggestionTypeValue = `${suggestionTypeCount}:impression`;

  fetchSessionStorage[RELATED_SEARCH_CONFIG] = suggestionTypeCount;
  sessionStore?.set(RELATED_SEARCH_STORAGE_KEY, fetchSessionStorage);

  sendAnalyticsData(suggestionTypeValue);
}

export const sendAnalyticsData = (value: string): void => {
  basicEvent(analyticsBus, {
    action: NOW,
    type: RELATED_SEARCH_ANALYTICS_TYPE,
    value,
  });
};
