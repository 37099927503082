import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class AccordionDDL extends React.Component {
  constructor(props) {
    super(props);

    this.element = React.createRef();
    this.state = {
      expanded: !props.cutsMustard || props.startExpanded
    };
  }

  componentDidMount() {
    if (!this.state.expanded) {
      const locationHash = window.location.hash.substr(1);
      const isActive = locationHash && locationHash === this.getId();

      if (isActive) {
        // eslint-disable-next-line react/no-did-mount-set-state
        this.setState({
          expanded: true
        });
      }
    }

    window.addEventListener('resize', this.handleResize);

    this.collapsibleNode.addEventListener(
      'transitionend',
      this.handleTransitionend
    );
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    this.collapsibleNode.removeEventListener(
      'transitionend',
      this.handleTransitionend
    );
  }

  handleResize = () => {
    if (this.state.expanded && !this.resizing) {
      this.resizing = true;
      window.requestAnimationFrame(() => {
        this.resizing = false;
        this.resetCollapser();
        this.triggerResize();
      });
    }
  };

  handleTransitionend = () => {
    this.triggerResize();

    if (this.state.expanded && this.props.jumpToExpansion) {
      this.element.current.scrollIntoView();
    }
  };

  handleClick = () => {
    const isExpanding = !this.state.expanded;

    if (typeof window !== 'undefined' && this.props.jumpToExpansion) {
      const id = this.getId();
      const locationHash = window.location.hash.substr(1);

      if (!isExpanding && locationHash && locationHash === id) {
        window.location.hash = '';
      } else if (isExpanding && id) {
        window.location.hash = `#${id}`;
      }
    }

    this.setState({
      expanded: isExpanding
    });
  };

  resetCollapser() {
    const height = this.getContentHeight();

    this.collapsibleNode.style.height = height;
  }

  triggerResize() {
    // Expanding and collapsing the accordion changes the height of the document.
    // Any components that might need to recalculate their height will be listening
    // for the documentHeightChange event so we trigger it here just to be safe.
    // We do it this funny way as IE doesn't work with window.dispatchEvent(new Event('resize'))
    if (
      typeof window !== 'undefined' &&
      window.document &&
      window.document.createEvent
    ) {
      const evt = window.document.createEvent('UIEvents');

      evt.initUIEvent('documentHeightChange', true, false, window, 0);
      setTimeout(() => window.dispatchEvent(evt), 300); // need to allow time for animation to finish
    }
  }

  getContentHeight() {
    return this.contentNode ? `${this.contentNode.clientHeight}px` : 'auto';
  }

  saveCollapsibleRef = el => {
    this.collapsibleNode = el;
  };

  saveContentRef = el => {
    this.contentNode = el;
  };

  getId() {
    const idValue = this.props.id || this.props.queryString;

    return idValue ? `${idValue}-content` : false;
  }

  render() {
    const {
      style,
      title,
      headingLevel,
      textContent,
      cutsMustard,
      children
    } = this.props;
    const id = this.getId();

    const classes = classnames(
      'ui-ddl-accordion',
      { expanded: this.state.expanded },
      { accordion_smallOnly: style && style.smallOnly },
      {
        accordion_smallMediumOnly: style && style.smallMediumOnly
      },
      { accordion_indented: style && style.indented },
      {
        'ui-ddl-accordion--rounded-corners': style && style.roundedCorners
      },
      [style && style.priority ? `ui-ddl-accordion__${style.priority}` : null]
    );
    const wrapperStyles = this.state.expanded
      ? {
          height: this.getContentHeight()
        }
      : null;
    const HeadingTag = `h${headingLevel}`;

    return (
      <section className={classes} ref={this.element}>
        {cutsMustard ? (
          <HeadingTag className="ui-ddl-accordion__title ui-ddl-accordion__title--with-button">
            <button
              className="ui-ddl-accordion--expand-btn"
              type="button"
              onClick={this.handleClick}
              aria-expanded={this.state.expanded ? 'true' : 'false'}
              aria-controls={id}
            >
              <span className="ui-ddl-accordion--expand-title">{title}</span>
              <span className="ui-ddl-accordion--toggle-icon" />
            </button>
          </HeadingTag>
        ) : (
          <HeadingTag className="ui-ddl-accordion__title">{title}</HeadingTag>
        )}
        <div
          aria-hidden={!this.state.expanded}
          id={id}
          className="ui-ddl-accordion__content-wrapper"
          style={wrapperStyles}
          ref={this.saveCollapsibleRef}
        >
          <div className="ui-ddl-accordion--content" ref={this.saveContentRef}>
            {textContent && (
              <p className="ui-ddl-accordion--text-content">{textContent}</p>
            )}
            <div>{children}</div>
          </div>
        </div>
      </section>
    );
  }
}

AccordionDDL.contextTypes = {
  currentUrl: PropTypes.string
};

AccordionDDL.propTypes = {
  children: PropTypes.node,
  cutsMustard: PropTypes.bool,
  headingLevel: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
  id: PropTypes.string,
  jumpToExpansion: PropTypes.bool,
  queryString: PropTypes.string,
  startExpanded: PropTypes.bool,
  style: PropTypes.object,
  textContent: PropTypes.string,
  title: PropTypes.string.isRequired
};

AccordionDDL.defaultProps = {
  headingLevel: 2
};

export { AccordionDDL };
