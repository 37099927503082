export const TREX = 'TREX';
export const DCS = 'DCS';
export const HOME = 'home';
export const PRODUCT_DETAILS = 'product_details';
export const FAVORITES = 'favourites';
export const SUPER_DEPARTMENT = 'sd';
export const DEPARTMENT = 'd';
export const AISLE = 'a';
export const SHELF = 's';
export const CATEGORY_DISPLAY_AREA = [
  SUPER_DEPARTMENT,
  DEPARTMENT,
  AISLE,
  SHELF
];
export const SEARCH_DISPLAY_AREA = 'sr';
export const HOME_DISPLAY_AREA = 'h';
export const PROMOTIONS_DISPLAY_AREA = 'so';
export const BUYLIST_DISPLAY_AREA = 'bl';
export const FULL_BASKET_DISPLAY_AREA = 'tf';
export const PRODUCT_DISPLAY_AREA = 'p';
export const FAVORITES_DISPLAY_AREA = 'f';
export const TILE_COMPONENT_TYPE = 'tile';
export const CAROUSEL_COMPONENT_TYPE = 'csl';
export const UNKNOWN_PAGEID = 'unknown_pageId';
export const CAROUSEL = 'carousel';
export const SIDELIST = 'sidelist';
export const GRID = 'grid';
export const DCS_CAROUSEL_ANALYTICS_TYPE = 's';
export const POPULAR_OFFERS_TITLE = 'Popular offers';
