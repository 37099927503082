import { setTransactionName as baseSetTransactionName } from '@peas/apm';
import getPageGroup from '#/lib/apm/get-page-group';

export const DOMAIN = 'groceries';

export default function setBrowserTransactionName(url: string): void {
  const pageGroup = getPageGroup(url);

  baseSetTransactionName(pageGroup, DOMAIN);
}
