import { getIsAmendBasket, getIsAmended } from '#/selectors/trolley';
import { skipRecsModal } from '../constants';
import { TConfigFunc } from '#/lib/records/helpers.defs';
import { getHasHYFRecommendations } from '#/reducers/recommendations';
import { showCheckoutRecommendations } from '#/selectors/experiments';
import { getFeatureVariableStringFromState } from '#/lib/optimizely-manager';

const { featureKey, variationKey, useCustomerId } = skipRecsModal;

export function showModalFromRecVariation(variation: string | null, state: Store): boolean {
  const variants = skipRecsModal.variants;
  switch (variation) {
    case variants.default:
      return true;
    case variants.skipRecsAllAmends:
      return true;
    case variants.skipRecsAll2ndAmends:
    case variants.skipOffersAll2ndAmends:
      return getIsAmended(state);
    case variants.disabled:
      return false;
    default:
      return false;
  }
}

export function showCopyFromRecVariation(variation: string | null): boolean {
  const variants = skipRecsModal.variants;
  switch (variation) {
    case variants.skipRecsAllAmends:
    case variants.skipRecsAll2ndAmends:
      return true;
    default:
      return false;
  }
}

export function getRecsSkipModalVariant(state: Store, config: TConfigFunc): string | null {
  const attributes = {
    isInAmend: getIsAmendBasket(state),
    hasHYF: getHasHYFRecommendations(state),
    hasOffers: showCheckoutRecommendations(state, config),
  };

  return getFeatureVariableStringFromState(state, featureKey, variationKey, { attributes, useCustomerId });
}
