import React from 'react';
import Link from '@ddsweb/link';
import { Footnote } from '@ddsweb/typography';
import { ShoppingMethod, COLLECTION, DELIVERY } from '#/constants/shopping-methods';
import { isDeliveryShoppingMethod, isClickAndCollectShoppingMethod } from '#/lib/shopping-method-util';
import helpers from '#/lib/decorators/helpers';
import analyticsBus from '#/analytics/analyticsBus';
import { InfoBanner, StyledSpaLink } from './styled';
import { TDeliveryType, TTranslateFn, TLanguageLink } from './index.defs';

type TBannerDescription = {
  t: (...args: any[]) => string;
};

type TBannerCTALink = {
  hrefUrl: string;
  t: TTranslateFn;
  deliveryType: TDeliveryType;
  shoppingMethod: ShoppingMethod;
};

type TInfoBannerSection = {
  availableShoppingMethods: string[];
  languageLink: TLanguageLink;
  t: (key: string, options?: { [key: string]: string }) => string;
};

type TProps = TInfoBannerSection;

const triggerSwitchDeliveryAnalytics = (event: any) => {
  analyticsBus().emit('UIEventBasicEvent', {
    action: 'delay',
    type: 'slots',
    value: `${event.target.innerText}`,
  });
};

export const BannerDescription = helpers(['t'])(
  ({ t: translate }: TBannerDescription): JSX.Element => {
    return <Footnote>{translate('slots:delivery.product-notes')}</Footnote>;
  },
);

export const BannerCTALink = helpers(['t'])(
  ({ hrefUrl, t: translate, deliveryType }: TBannerCTALink): JSX.Element | null => {
    const spaLink = ({ children, href, onClick }): JSX.Element => (
      <StyledSpaLink to={href} onClick={onClick}>
        {children}
      </StyledSpaLink>
    );

    let translationTextKey;
    switch (deliveryType) {
      case DELIVERY:
        translationTextKey = 'link-home-delivery';
        break;
      case COLLECTION:
        translationTextKey = 'link-click-collect';
        break;
      default:
        translationTextKey = 'link-same-day-delivery';
    }

    return (
      <Link
        href={hrefUrl}
        icon={{ graphic: 'forwardLink', position: { global: 'right' }, size: 'xs' }}
        variant="standalone"
        onClick={triggerSwitchDeliveryAnalytics}
        spaLink={spaLink}
      >
        {translate(`slots:delivery.${translationTextKey}`)}
      </Link>
    );
  },
);

const InfoBannerSection: React.FC<TProps> = ({ availableShoppingMethods, languageLink }: TProps) => {
  let hasDelivery, hasCollection;

  availableShoppingMethods.forEach(shopping => {
    if (isDeliveryShoppingMethod(shopping)) {
      hasDelivery = true;
    }
    if (isClickAndCollectShoppingMethod(shopping)) {
      hasCollection = true;
    }
  });

  const shouldRenderBanner = hasDelivery || hasCollection;

  if (!shouldRenderBanner) return null;

  return (
    <InfoBanner>
      <BannerDescription />
      {hasDelivery && <BannerCTALink hrefUrl={languageLink(`/slots/${DELIVERY}`)} deliveryType={DELIVERY} />}
      {hasCollection && <BannerCTALink hrefUrl={languageLink(`/slots/${COLLECTION}`)} deliveryType={COLLECTION} />}
    </InfoBanner>
  );
};

export default helpers(['t'])(InfoBannerSection);
