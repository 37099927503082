import analyticsBus from '#/analytics/analyticsBus';
import { renderedContentOp, RenderedContentOpPayload } from '#/analytics/bertie/formatters/events/content';
import { BasketOp } from '#/analytics/bertie/types';
import {
  ContentInteractOpPayload,
  getContentInteractOpPayload,
} from '#/analytics/bertie/formatters/events/content-interact-op';
import { CONTENT_INTERACT_OP, IMPRESSED_SLOT_OP } from '#/analytics/constants';
import { getSlotOpPayload } from '#/analytics/bertie/formatters/events/slot-op';
import { ShoppingMethod } from '#/constants/shopping-methods';
import { Slot } from '#/lib/records/slot.defs';

export function emitRenderedContentOp(payload: RenderedContentOpPayload): RenderedContentOpPayload | undefined {
  const formattedPayload = renderedContentOp(payload);

  if (!formattedPayload) {
    return;
  }

  analyticsBus().emit('renderedContentOp', formattedPayload);
}

export function emitBasketOp(payload: BasketOp): BasketOp | undefined {
  if (!payload) {
    return;
  }

  analyticsBus().emit('basketOp', payload);
}

export function emitContentInteractOp(payload: ContentInteractOpPayload): ContentInteractOpPayload | undefined {
  const formattedPayload = getContentInteractOpPayload(payload);

  if (!formattedPayload) {
    return;
  }

  analyticsBus().emit(CONTENT_INTERACT_OP, formattedPayload);
}

export function emitSlotOp(
  slots: Record<string, Slot[]> | Slot[],
  selectedShoppingMethod: ShoppingMethod,
  storeId: string,
  locationId: string,
  addressId: string,
  timezone: string,
): void {
  const formattedPayload = getSlotOpPayload(slots, selectedShoppingMethod, storeId, locationId, addressId, timezone);

  analyticsBus().emit(IMPRESSED_SLOT_OP, formattedPayload);
}
