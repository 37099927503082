import React, { useRef, useEffect } from 'react';
import { compose } from 'react-recompose';

import { connect } from '#/lib/render/connect-deep-compare';
import helpers from '#/lib/decorators/helpers';
import { TTranslateFunc, TConfigFunc } from '#/lib/records/helpers.defs';

import { closeModal } from '#/actions/ui-action-creators';
import { sessionStore } from '#/lib/data-store/client-store';
import { MARKETPLACE_PRODUCT_MODAL } from '#/constants/modal-names';
import { basicEvent } from '#/analytics/types/basic';
import analyticsBus from '#/analytics/analyticsBus';
import { getLanguageLink } from '#/reducers/app';
import {
  BASKET_ADD,
  CLOSE,
  DELAY,
  FIND_OUT_MORE,
  GOT_IT,
  MARKETPLACE_ONBOARDING,
  MARKETPLACE_ONBOARDING_IMPRESSION,
  NOW,
} from '#/analytics/constants';
import { GenericSlotsModal } from '#/components/modals/generic-slots-modal/GenericSlotsModal';
import BodyContent from '#/components/modals/market-place-product-modal/component/market-place-product-body-content';

type OwnState = {
  findOutMoreLink: string;
};

type OwnProps = {
  t: TTranslateFunc;
  c: TConfigFunc;
  closeModal: () => void;
};

const mapStateToProps = (state: Store): OwnState => ({
  findOutMoreLink: getLanguageLink(state, `/zone/marketplace`),
});

const connector = connect(mapStateToProps, { closeModal });

type Props = OwnProps & OwnState;

type AnalyticsEvent = {
  type: string;
  value: string;
  action: string;
};

const MarketPlaceProductModal = (props: Props): JSX.Element => {
  const { closeModal, t: translate, c: config, findOutMoreLink } = props;
  const tPrefix = `modals:market-place-product-modal`;
  const lastActiveElement = useRef<HTMLElement>(document.activeElement as HTMLElement);
  const setFocusToDropdown = (): void => lastActiveElement?.current?.focus();

  const sendAnalyticsEvent = (event: AnalyticsEvent): void => {
    basicEvent(analyticsBus, event);
  };

  useEffect(() => {
    sessionStore?.set(MARKETPLACE_PRODUCT_MODAL, true);
    sendAnalyticsEvent({ type: BASKET_ADD, value: MARKETPLACE_ONBOARDING_IMPRESSION, action: NOW });
  }, []);

  const cancelButtonHandler = (): void => closeModal();

  const handleGotItLinkClick = (): void => {
    sendAnalyticsEvent({ type: MARKETPLACE_ONBOARDING, value: GOT_IT, action: NOW });
    cancelButtonHandler();
  };

  const handleFindOutMoreLinkClick = (): void => {
    sendAnalyticsEvent({ type: MARKETPLACE_ONBOARDING, value: FIND_OUT_MORE, action: DELAY });
    window.open(findOutMoreLink, '_self', 'noopener');
  };

  const onCloseModal = (): void => {
    sendAnalyticsEvent({ type: MARKETPLACE_ONBOARDING, value: CLOSE, action: NOW });
    cancelButtonHandler();
    setFocusToDropdown();
  };

  return (
    <GenericSlotsModal
      modalId="market-place-product-modal"
      headingText={translate(`${tPrefix}.title`)}
      headlineVisualSize="headline3"
      bodyText={<BodyContent t={translate} c={config} />}
      buttons={[
        {
          onClick: handleFindOutMoreLinkClick,
          text: translate(`${tPrefix}.find-out-more`),
          variant: 'secondary',
          testId: 'market-place-product-modal-find-out-more',
        },
        {
          onClick: handleGotItLinkClick,
          text: translate(`${tPrefix}.got-it`),
          testId: 'market-place-product-modal-got-it',
        },
      ]}
      isModalOpen
      onClose={onCloseModal}
      bodyContainerDisplayStyle="flex"
    />
  );
};

const enhance = compose(helpers(['t', 'c']), connector);
export default enhance(MarketPlaceProductModal);
