import React from 'react';
import PropTypes from 'prop-types';
import Base from '#/components/base';
import Link from '#/components/link-check-spa';
import { basicEvent } from '#/analytics/types/basic';
import analyticsBus from '#/analytics/analyticsBus';
import { getProductAisleId, getProductIsForSale } from '#/selectors/item';
import { connect } from '#/lib/render/connect-deep-compare';
import { getIsOnDemandDelivery } from '#/selectors/trolley';

const mapStateToProps = state => ({
  showRestOfShelf: !getIsOnDemandDelivery(state)
});

@connect(mapStateToProps)
export default class RenderUnavailableBrowseCategoryLink extends Base {
  static propTypes = {
    displayAvailable: PropTypes.bool,
    item: PropTypes.object.isRequired,
    showRestOfShelf: PropTypes.bool
  };

  static defaultProps = {
    displayAvailable: false
  };

  onClick = () => {
    basicEvent(analyticsBus, {
      type: 'rest of shelf',
      value: 'product tile',
      action: 'delay'
    });
  };

  render() {
    const { displayAvailable, item, showRestOfShelf } = this.props;

    const aisleId = getProductAisleId(item);

    if (
      showRestOfShelf &&
      this.f('seeMoreInCategory') &&
      aisleId &&
      (displayAvailable || getProductIsForSale(item))
    ) {
      return (
        <Link to={this.l('categories/' + aisleId)} onClick={this.onClick}>
          <div className="goto-aisle hidden-medium ">
            <p>{this.t('product-tile:more-in-this-category')}</p>
            <span className="icon-chevron_right" />
          </div>
        </Link>
      );
    }

    return null;
  }
}
