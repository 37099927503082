import { MULTI_SEARCH_LIST } from '#/constants/spa-resource';
import { getMultiSearchListResource } from '#/selectors/resources';
import { updateMultiSearchList } from '#/actions/search-action-creators';

export const type = MULTI_SEARCH_LIST;

export function handler(dispatch, getState) {
  const state = getState();
  const { data } = getMultiSearchListResource(state);

  if (!data) {
    return;
  }

  dispatch(updateMultiSearchList(data.list));
}
