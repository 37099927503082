import { predictiveSearch } from '#/experiments/oop-1742/constants';
import { getFeatureVariableJsonFromState } from '#/lib/optimizely-manager';
import { getSearchTerm } from '#/reducers/search';

const { useCustomerId, featureKey, variationKey } = predictiveSearch;

type SuggestionType = {
  popular?: Array<string>;
  trending?: Array<string>;
};

type Suggestion = {
  isPredictiveSearch: boolean;
  predictiveSearchList: SuggestionType[];
  predictiveSuggestionType: string;
};

const getIsLastItemAdded = ({ trolley: { trolleyUserHistory } }: Store): boolean => {
  const lastItemAdded = (trolleyUserHistory && trolleyUserHistory[trolleyUserHistory.length - 1]) || [];
  return lastItemAdded.length > 0;
};

const getIsPredictiveSearch = (state: Store, predictiveSearchList: SuggestionType[]): boolean => {
  const searchTerm = getSearchTerm(state) || '';
  const isLastItemAdded = getIsLastItemAdded(state) || false;

  return predictiveSearchList.length > 0 && !isLastItemAdded && searchTerm.length === 0;
};

const getVariant = (state: Store, keyToRead: string, defaultResponse: []): Suggestion => {
  const attributes = {};
  const predictiveSearch =
    getFeatureVariableJsonFromState(state, featureKey, keyToRead, { useCustomerId, attributes }) || {};
  const predictiveSearchList = predictiveSearch.popular || predictiveSearch.trending || defaultResponse;
  const isPredictiveSearch = getIsPredictiveSearch(state, predictiveSearchList);
  return {
    isPredictiveSearch,
    predictiveSearchList,
    predictiveSuggestionType: predictiveSearch && Object.keys(predictiveSearch)[0],
  };
};

export const getPredictiveSearchConfig = (state: Store): Suggestion => getVariant(state, variationKey, []);
