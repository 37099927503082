import PropTypes from 'prop-types';
import React from 'react';
import SelectableListItem from './item';
import classnames from 'classnames';
import helpers from '#/lib/decorators/helpers';

const SelectableList = ({
  disabled,
  items,
  onItemSelect,
  onSelectionChange,
  onSelectionReset,
  searchTitle,
  selectedIndex,
  showSearchTitle,
  searchBarCenterWide,
  isSearchContent,
  c: config
}) => {
  const isGlobalHeader = config('isGlobalHeader');
  return (
    <div
      className={classnames('selectable-list selectable-list--right-aligned', {
        'selectable-list--global-header': isGlobalHeader,
        'selectable-list--disabled': disabled,
        'selectable-list--centerWide': searchBarCenterWide
      })}
    >
      <ul className="selectable-list__menu">
        {showSearchTitle && (
          <li className="selectable-list__menu-search-title">{searchTitle}</li>
        )}
        {items.map((item, index) => (
          <SelectableListItem
            key={isSearchContent ? item.name : item}
            index={index}
            value={item}
            onClick={onItemSelect}
            onMouseOver={onSelectionChange}
            onMouseOut={onSelectionReset}
            selected={index === selectedIndex}
            isSearchContent={isSearchContent}
          />
        ))}
      </ul>
    </div>
  );
};

SelectableList.propTypes = {
  disabled: PropTypes.bool,
  items: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.object)
  ]),
  onItemSelect: PropTypes.func.isRequired,
  onSelectionChange: PropTypes.func.isRequired,
  onSelectionReset: PropTypes.func.isRequired,
  searchBarCenterWide: PropTypes.bool,
  selectedIndex: PropTypes.number
};

SelectableList.defaultProps = {
  disabled: false,
  selectedIndex: -1,
  showSearchTitle: false
};

export default helpers(['c'])(SelectableList);
