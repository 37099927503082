import React, { Component } from 'react';
import { compose } from 'react-recompose';
import { WARNING } from '@ddsweb/constants';
import { StyledInLineMessaging, StyledBodyText } from './styled';
import Link from '#/components/link-check-spa';
import { connect } from '#/lib/render/connect-deep-compare';
import { getSelectedShoppingMethod, getSlotsPendingStatus } from '#/reducers/slot';
import { getTrolleyShoppingMethod, getIsAmendBasket } from '#/selectors/trolley';
import { getLanguageLink } from '#/reducers/app';
import analyticsBus from '#/analytics/analyticsBus';
import { changeShoppingMethod } from '#/actions/slot-action-creators';
import helpers from '#/lib/decorators/helpers';
import { COLLECTION } from '#/constants/shopping-methods';
import { DELAY, SLOTS, BOOK_A_CC_SLOT } from '#/analytics/constants';
import { basicEvent, TBasicEventProps } from '#/analytics/types/basic';
import { isDeliveryShoppingMethod } from '#/lib/shopping-method-util';

const basicEventProps: TBasicEventProps = {
  action: DELAY,
  type: SLOTS,
  value: BOOK_A_CC_SLOT,
};

const mapStateToProps = (state: any) => {
  const currentShoppingMethod = getSelectedShoppingMethod(state);
  return {
    ccLinkUrl: getLanguageLink(state, `/slots/${COLLECTION}`),
    trolleyShoppingMethod: getTrolleyShoppingMethod(state),
    currentShoppingMethod,
    areSlotsPending: getSlotsPendingStatus(state),
    isDelivery: isDeliveryShoppingMethod(currentShoppingMethod),
    isAmend: getIsAmendBasket(state),
  };
};

type TProps = {
  ccLinkUrl: string;
  changeShoppingMethod: Function;
  currentShoppingMethod: string;
  trolleyShoppingMethod: string;
  t: Function;
  areSlotsPending: boolean;
  isDelivery: boolean;
  isAmend: boolean;
};

class NoSlotMessage extends Component<TProps> {
  shouldComponentUpdate({ areSlotsPending }) {
    return !areSlotsPending;
  }

  handleBookCollectLinkClick = () => {
    const { changeShoppingMethod, trolleyShoppingMethod } = this.props;

    basicEvent(analyticsBus, basicEventProps);
    changeShoppingMethod(COLLECTION, trolleyShoppingMethod);
  };

  render() {
    const { t: translate, ccLinkUrl, isDelivery, isAmend } = this.props;

    return (
      <StyledInLineMessaging variant={WARNING} assistiveText={translate('common:warning')}>
        {isDelivery ? (
          <StyledBodyText dark>
            <b>{translate('slots:common.no-slots-delivery-1')}</b>
            {!isAmend && (
              <>
                {translate('slots:common.no-slots-delivery-2')}
                <Link to={ccLinkUrl} emphasized onClick={this.handleBookCollectLinkClick}>
                  {translate('slots:common.no-slots-delivery-linkto-cc')}
                </Link>
              </>
            )}
          </StyledBodyText>
        ) : (
          <StyledBodyText dark emphasized>
            {translate('slots:common.no-slots-available')}
          </StyledBodyText>
        )}
      </StyledInLineMessaging>
    );
  }
}

const enhance = compose<TProps, {}>(helpers(['t']), connect(mapStateToProps, { changeShoppingMethod }));

export default enhance(NoSlotMessage);
