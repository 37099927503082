import { css } from 'styled-components';
import { spacing } from '@ddsweb/selectors';

export const TooltipStyleDesktop = css`
  top: 35px;
  left: 7px;
  & .ddsweb-popover__pointer {
    left: 19px;
  }
`;

export const TooltipStyleMobile = css`
  top: 51px;
  left: -8px;

  & .ddsweb-popover__pointer {
    left: ${spacing.sm};
  }
`;
