// @ts-nocheck
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import styled, { css } from 'styled-components';
import { colors, spacing, fontSize, fontFamily } from '@ddsweb/selectors';
import { Promotions } from '#/components/product-tiles/common/promotions';
import SpecialOfferSash from '#/components/product-tiles/common/offer-sash';

import {
  GREEN,
  MEDIUM,
  MEDIUM_LARGE,
  SMALL_MEDIUM,
  SPACING_SMALL,
  SPACING_MEDIUM,
  SMALL,
  MEDIUM_LARGE_MAX,
  ORANGE,
} from '#/components/product-tiles/temp-style-constants';

const HEART_ICON_WIDTH = 21;
const HEART_ICON_GUTTER = 12;

const PRODUCT_TILE_ACTIONS_WIDTH = '45px';

export const StyledHorizontalTile = styled.div`
  background-color: ${colors.background};
  border: 1px solid ${colors.lines};
  position: relative;
  max-width: 100%;

  ${({ isInBasket, isSubstitute }) =>
    isSubstitute &&
    css`
      border: 1px solid ${isInBasket ? GREEN : ORANGE};
    `}

  ${({ isUnavailable }) =>
    isUnavailable &&
    css`
      border-color: ${colors.lines};

      .product-info-message {
        display: none;

        &.with-warning-background {
          display: block;
        }
      }
    `}

  @media ${MEDIUM_LARGE} {
    height: 100%;
  }
`;
export const StyledHorizontalTileWrapper = styled.div`
  position: relative;
  max-width: 100%;

  ${({ isSubstitute }) =>
    isSubstitute &&
    css`
      @media ${MEDIUM_LARGE} {
        padding: 0 0 ${spacing.md} 0;
      }
    `}

  @media ${MEDIUM_LARGE} {
    width: 100%;
  }
`;

export const StyledTileContentWrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .product-image-wrapper {
    float: left;
    height: 80px;
    width: 80px;

    .product-image {
      height: 80px;
      width: 80px;
    }
  }

  .product-info-message-section {
    text-align: left;
    display: none;
  }

  .product-info-section-small {
    clear: both;
    margin-left: 0;
  }

  @media ${MEDIUM_LARGE} {
    display: flex;
    height: 100%;
    width: 100%;

    .product-image-wrapper {
      float: none;
      height: 80px;
      margin: 0 auto;
      width: 80px;

      .product-image {
        height: 80px;
        width: 80px;
      }
    }
  }

  @media ${MEDIUM} {
    .product-info-message-section {
      display: block;
      padding: 0;
    }
  }
`;

export const StyledTiledContent = styled.div`
  ${({ isItemUnavailable }) =>
    isItemUnavailable &&
    css`
      && {
        flex-wrap: wrap;
      }
    `}

  min-height: 104px;
  overflow: hidden;
  padding: ${SPACING_MEDIUM} ${SPACING_MEDIUM} ${spacing.lg} ${SPACING_MEDIUM};
  width: 100%;

  ${({ flashSashInfo, showSquareOfferSash }) =>
    flashSashInfo &&
    !showSquareOfferSash &&
    css`
      padding-top: 30px;
    `}

  ${({ showSquareOfferSash }) =>
    showSquareOfferSash &&
    css`
      padding-top: 68px;
    `}

  .product-details--wrapper {
    margin: ${SPACING_SMALL} 55px 0px 84px;
    padding-left: ${SPACING_MEDIUM};

    @media ${SMALL_MEDIUM} {
      margin: ${SPACING_SMALL} ${spacing.xl} 0px 84px;
    }

    h3 {
      margin-bottom: ${spacing.xs};

      a {
        padding: 0;
        margin-top: 0;
        ${({ hasActions }) =>
          hasActions &&
          css`
            padding-right: 33px;
            display: inline-block;
          `}
      }
    }
  }

  @media ${MEDIUM_LARGE} {
    flex-direction: row;
    display: flex;

    .product-details--wrapper {
      margin: 0;
      padding-top: ${spacing.xx};
      flex: 9;

      h3 {
        margin-bottom: 0;
        a {
          padding: 0 0 ${spacing.xs};
          margin-top: 0;
        }
      }
    }
  }
`;

export const StyledBuyBoxWrapper = styled.div`
  margin: ${spacing.xx} 0px 0px 84px;
  padding: ${spacing.xx} 0 0 ${spacing.sm};

  @media ${MEDIUM_LARGE} {
    margin: 0;
    padding: ${spacing.xx} 44px 0 24px;
    flex: 0 0 350px;
    align-items: flex-end;
    flex-direction: column;
  }

  ${({ isUnavailable }) =>
    isUnavailable &&
    css`
      && {
        flex: 0 0 0;
      }
    `}

  .beans-buybox__weight-input-controls {
    .beans-dropdown__container {
      width: calc(100% - ${spacing.sm});
    }
  }

  ${({ isItemUnavailable }) =>
    isItemUnavailable &&
    css`
      .buybox-container {
        align-items: center;
      }
    `}
`;

export const StyledProductDetailsContent = styled.div`
  a {
    padding: 0 0 ${spacing.xs};
    font-size: 14px;
  }

  @media ${MEDIUM_LARGE} {
    text-align: left;
    padding: 0;
  }

  ${({ hasActions }) =>
    hasActions &&
    css`
      padding-right: ${HEART_ICON_WIDTH} + ${HEART_ICON_GUTTER} + 'px';
    `}
`;

export const StyledPromotionsMobile = styled(p => <Promotions {...p} />)`
  // styled-components circular dependancy error when running jest tests
  padding: ${spacing.xx} ${spacing.sm} ${spacing.sm};
  border-top: 1px solid #ccc;
  padding-top: ${SPACING_SMALL};

  @media ${MEDIUM} {
    display: none;
  }

  ${({ isYellowClubcardPromotion }) =>
    isYellowClubcardPromotion &&
    css`
      padding-top: ${SPACING_MEDIUM};
    `};
`;

export const StyledPromotionsAboveMobile = styled(p => <Promotions {...p} />)`
  // styled-components circular dependancy error when running jest tests
  @media ${SMALL_MEDIUM} {
    display: none;
  }

  @media ${MEDIUM_LARGE_MAX} {
    && a > div {
      padding-left: 0;
    }
  }

  // Fixes margin issues when combining new clubcard style promotion with missing message
  ${({ isYellowClubcardPromotion }) =>
    isYellowClubcardPromotion &&
    css`
      && > div {
        margin-left: 0;
        margin-right: 0;
      }
    `};
`;

export const StyledProductActions = styled.div`
  position: relative;
  top: 0;
  right: 0;

  .favorite-heart-icon {
    margin: ${SPACING_MEDIUM};
    float: right;
  }

  form {
    float: right;
  }

  .favourite-icon {
    float: left;
    width: ${PRODUCT_TILE_ACTIONS_WIDTH};
  }

  @media ${SMALL} {
    max-width: ${PRODUCT_TILE_ACTIONS_WIDTH};
  }
`;

export const getAdditionalTitleStyles = css`
  @media ${SMALL_MEDIUM} {
    display: inline;
    margin: 0;
    padding: 0 0 0 ${spacing.sm};
    box-decoration-break: clone;
  }

  @media ${MEDIUM_LARGE} {
    margin-top: ${spacing.xl};
    padding: 0 ${spacing.sm};
  }
`;

export const StyledSpecialOfferSashWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

export const StyledSpecialOfferSash = styled(SpecialOfferSash)`
  ${({ isSubstitute, flashSashInfo }) =>
    isSubstitute &&
    flashSashInfo.type === 'Clubcard Price' &&
    css`
      @media ${MEDIUM_LARGE} {
        position: absolute;
        left: 18px;
      }
    `};
  ${({ isSubstitute, flashSashInfo }) =>
    isSubstitute &&
    flashSashInfo.type !== 'Clubcard Price' &&
    css`
      @media ${MEDIUM_LARGE} {
        position: relative;
        left: 18px;
      }
    `};
`;

export const StyledProductActionsContainer = styled.div`
  position: absolute;
  top: 0;
  right: ${({ isDeleteButtonRendered }) => (isDeleteButtonRendered ? '30px' : '0px')};

  @media ${SMALL_MEDIUM} {
    ${({ isDeleteButtonRendered }) =>
      isDeleteButtonRendered &&
      css`
        top: 30px;
        right: 0;
      `};
  }
`;

export const StyledTotalPriceWrapper = styled.div`
  width: 100%;
  font-size: ${fontSize.xs};
  font-family: ${fontFamily.text};

  .total-heading {
    margin-right: ${spacing.xs};
    color: ${colors.text};
  }
  .price {
    color: ${colors.textDark};
    font-weight: bold;
  }
  .space {
    display: none;
  }
`;

export const SellerWrapper = styled.div`
  @media ${MEDIUM_LARGE} {
    padding-left: ${spacing.sm};
  }
`;

export const StyledInfoMessagesWrapper = styled.div`
  flex-basis: 100%;
  padding: 0 36px 0 92px;
  margin-top: 6px;

  ${({ shouldShowRestOfShelf }) =>
    !shouldShowRestOfShelf &&
    css`
      margin-top: -12px;
    `}

  @media ${MEDIUM_LARGE_MAX} {
    margin-top: -${spacing.xxxl};
  }
`;

export const StyledDrs = styled.div`
  width: 100%;
  font-size: ${fontSize.xs};
  font-family: ${fontFamily.text};
  color: ${colors.text};
  margin-bottom: ${spacing.xs};
`;
