import styled from 'styled-components';
import { colors } from '@ddsweb/selectors';
import { InlineLink } from '@ddsweb/link';

export const BrowserLink = styled(InlineLink)`
  color: ${colors.white};
  &:hover {
    color: ${colors.white};
  }
  &:focus {
    color: ${colors.white};
  }
  &:visited {
    color: ${colors.white};
  }
  &:active {
    color: ${colors.white};
  }
  font-weight: 400;
`;
