// This is replica of error codes thrown at mango

// General
export const SERVER_ERROR = 'server-error';
export const REQUEST_FAILED = 'request-failed';
export const EXTERNAL_SESSION_RENEW_REQUIRED = 'external-session-renew-required';

// Orders
export const ORDER_NOT_CANCELLABLE = 'order-not-cancellable';
export const ORDER_ID_MISSING = 'order-id-missing';
export const ORDER_ALREADY_PLACED = 'order-already-placed';
export const INCORRECT_PHONE_NUMBER = 'incorrect-phone-number';

// Address
export const INVALID_POSTCODE_SEARCH = 'invalid-postcode-search';
export const POSTCODE_ERROR_MESSAGE = 'Cannot deliver to this postcode';
export const BLOCKED_ADDRESS = 'blocked-address';
export const UNDELIVERABLE_ADDRESS = 'undeliverable-address';
export const UNABLE_TO_UPDATE_ADDRESS_ERRORCODE = 'unable-to-update-address-information';

// Resources
export const UNAUTHENTICATED_USER_USING_AUTHENTICATED_RESOURCE =
  'Unauthenticated user attempting to access an authenticated resource.';

// Basket Breach
export const VOLUME_OR_WEIGHT_EXCEEDED = 'volume-or-weight-exceeded';
export const BASKET_WEIGHT_OR_VOLUME_PRE_CONDITION_ERROR = 'basket-weight/volume-pre-condition-error';
export const BASKET_WEIGHT_OR_VOLUME_BREACHED_ERROR_MESSAGE =
  'Failed, basket breached by weight/volume on confirm order';

// DCS
export const FETCH_DCS_REMOTE_DATA_DISABLED = 'Remote data disabled';
export const FETCH_DCS_CONTENT_ERROR = 'Failed to fetch DCS data';
export const FAILED_TO_RETRIEVE_COLLECTION_POINTS = 'Failed to retrieve collection points details';

//auth
export const EXPIRED_REFRESH_TOKEN = 'expired-refresh-token';
export const LOCKED_ALREADY = 'locked-already';
export const INVALID_CLIENT = 'invalid_client';
export const INVALID_GRANT = 'invalid_grant';
