import { COLLECTION } from '#/constants/shopping-methods';
import { Slot } from '#/lib/records/slot.defs';
import { getIsMobile, getTimezone } from '#/reducers/app';
import { isOrderWithinSlotWindow } from '#/utils/orders';
import { getImHereConfirmed, getImHereStatusFromRedux } from '#/selectors/order-list-details';

export const imHereStatusValue = {
  AVAILABLE: 'AVAILABLE',
  RECEIVED: 'RECEIVED',
  UNAVAILABLE: 'UNAVAILABLE',
};

export type TReturnType = typeof imHereStatusValue[keyof typeof imHereStatusValue];

export const getImHereStatus = (
  state: Store,
  locationUuid: string,
  orderNo: string,
  shoppingMethod: string,
  slot: Slot,
): TReturnType => {
  const isMobile = getIsMobile(state);
  if (!isMobile) {
    return imHereStatusValue.UNAVAILABLE;
  }

  const isImHereConfirmed: boolean = getImHereConfirmed(state, locationUuid, orderNo);
  if (isImHereConfirmed) {
    return imHereStatusValue.RECEIVED;
  }

  const isStoreImHereEnabled = getImHereStatusFromRedux(state, orderNo) === imHereStatusValue.AVAILABLE;
  const eligibleToShow = isStoreImHereEnabled && shoppingMethod === COLLECTION;

  if (!eligibleToShow) {
    return imHereStatusValue.UNAVAILABLE;
  }

  const timeZone = getTimezone(state);
  return isOrderWithinSlotWindow(slot, timeZone) ? imHereStatusValue.AVAILABLE : imHereStatusValue.UNAVAILABLE;
};

export const getImHereButtonSessionKeyTemplate = (locationUuid: string, orderNo: string): string =>
  `TSC-IMH#${locationUuid}#${orderNo}`;
