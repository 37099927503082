export const RDG_DAY_TIME = 'RdgDayTime';
export const RDG_DATE_RANGE = 'RdgDateRange';
export const RDG_LEAD_TIME = 'RdgLeadTime';
export const RDG_UNAVAILABLE_DATE_RANGE = 'RdgUnavailableDateRange';
export const RESTRICTED_ORDER_AMENDMENT = 'RestrictedOrderAmendment';
export const RESTRICTED_ORDER_AMENDMENT_WITH_ITEM =
  'RestrictedOrderAmendmentWithItemInBasket';
export const EXCLUDED_PRODUCT = 'ExcludedProduct';
export const EPW_WITH_AMEND_AVAILABILITY_OVERRIDE =
  'EPWwithAmendAvailabilityOverride';
export const EPW_WITHOUT_AMEND_AVAILABILITY_OVERRIDE =
  'EPWwithoutAmendAvailabilityOverride';
export const RDG_LEAD_TIME_WITH_ITEM = 'RdgLeadTimeWithItemInBasket';
export const ROA_RESTRICTIONS = [
  RESTRICTED_ORDER_AMENDMENT,
  RESTRICTED_ORDER_AMENDMENT_WITH_ITEM
];
export const RDG_VIOLATIONS = [
  RDG_LEAD_TIME,
  RDG_DATE_RANGE,
  RDG_DAY_TIME,
  RDG_UNAVAILABLE_DATE_RANGE
];
export const RDG_RESTRICTIONS = [...RDG_VIOLATIONS, RDG_LEAD_TIME_WITH_ITEM];
export const AMEND_RESTRICTED_TYPES = [
  RESTRICTED_ORDER_AMENDMENT_WITH_ITEM,
  RDG_LEAD_TIME_WITH_ITEM
];

export const STOCK_QUANTITY_EXCEEDED = 'stockQuantityExceeded';
