export const variationKey = 'topnav-inspireme';
export enum InspireMeTopNavVariants {
  default = 'default',
  inspireMeZonePage = 'inspireme-zonepage',
  inspireMeTaxonomy = 'inspireme-taxonomy',
}

export type InspireMeTopNavConfig = {
  experiment: string;
  variation: string;
  [variationKey]: InspireMeTopNavVariants;
};

export const topNavInspireMeConfig = {
  featureKey: 'GHS-UK_topnav-inspireme',
  variationKey,
  useCustomerId: false,
  serviceConfig: {
    params: {
      [variationKey]: InspireMeTopNavVariants.default,
    },
  },
};

export const INSPIRE_ME_MENU_KEY = 'inspire-me';
export const INSPIRE_ME_ZONE_PAGE_KEY = 'inspireMeZonePage';
export const INSPIRE_ME_TAXONOMY_KEY = 'inspireMeTaxonomy';
export const ALL = 'all';
