export const createMetaTag = attributes => {
  if (typeof document !== 'undefined') {
    const meta = document.createElement('meta');

    Object.keys(attributes).forEach(attribute => {
      meta.setAttribute(attribute, attributes[attribute]);
    });
    document.getElementsByTagName('head')[0].appendChild(meta);
  }
};

export const addMultipleMetaTags = tags => {
  tags.forEach(tag => createMetaTag(tag));
};

export const getWindow = () => window;
export const getDocument = () => document;
