import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Spinner = ({ showSpinner }) => {
  return (
    <div
      className={classnames('spinner', {
        hidden: !showSpinner
      })}
    />
  );
};

Spinner.propTypes = {
  showSpinner: PropTypes.bool
};

Spinner.defaultProps = {
  showSpinner: true
};

export default Spinner;
