// Links are rendered as <a> tags instead of React Router's Link tag as the
// the later only supports urls that are React Router Routes.
//
// When the link is clicked, this component will decide whether to navigate via
// React Router or allow the default browser behaviour.

import React from 'react';
import PropTypes from 'prop-types';
import { match, withRouter } from 'react-router';
import routes from '#/routes/spa-routes';
import helpers from '#/lib/decorators/helpers';
import { pickByKey } from '#/utils/misc';
import { isMFERouteAvailable } from '#/routes/spa-routes/spa-helpers.js';
import { connect } from 'react-redux';
import {
  getLanguage,
  getAlternateLanguage,
  getGroceryDomain,
  getMfeRolloutConfig
} from '#/reducers/app';

const validLinkProps = [
  'accesskey',
  'activeClassName',
  'activeStyle',
  'aria-controls',
  'aria-current',
  'aria-label',
  'aria-selected',
  'aria-hidden',
  'aria-describedby',
  'aria-haspopup',
  'aria-expanded',
  'children',
  'className',
  'data-auto',
  'data-index',
  'data-testid',
  'hash',
  'id',
  'label',
  'lang',
  'name',
  'onKeyDown',
  'onlyActiveOnIndex',
  'query',
  'rel',
  'referrerPolicy',
  'role',
  'state',
  'style',
  'tabIndex',
  'target',
  'title',
  'type'
];

let regexes; // used to store compiled regexes on the client

const mapStateToProps = state => ({
  currentHtmlStatusCode: state.resources && state.resources.status,
  alternateLanguage: getAlternateLanguage(state),
  language: getLanguage(state),
  groceryDomain: getGroceryDomain(state),
  mfeRolloutConfig: getMfeRolloutConfig(state)
});

@connect(mapStateToProps)
@helpers(['f', 'l', 'c'])
class LinkCheckSpa extends React.PureComponent {
  static propTypes = {
    alternateLanguage: PropTypes.string,
    applyFocus: PropTypes.bool,
    c: PropTypes.func.isRequired,
    currentHtmlStatusCode: PropTypes.number,
    f: PropTypes.func.isRequired,
    groceryDomain: PropTypes.string.isRequired,
    handleUpdate: PropTypes.func,
    l: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    mfeRolloutConfig: PropTypes.object,
    onClick: PropTypes.func,
    onKeyDown: PropTypes.func,
    preventSpa: PropTypes.bool.isRequired,
    router:
      typeof window !== 'undefined'
        ? PropTypes.object.isRequired
        : PropTypes.object,
    to: PropTypes.string
  };

  static defaultProps = {
    applyFocus: false,
    handleUpdate: () => {},
    onClick: () => {},
    preventSpa: false
  };

  constructor(props) {
    super(props);
    if (typeof window !== 'undefined') {
      this.protocol = 'https:';
      this.domain = this.props.groceryDomain;
      this.path = this.props.l('');
      this.onClick = this.onClick.bind(this);
      this.compileRegExps();
    }
  }

  componentDidUpdate() {
    this.props.handleUpdate(this.el);
  }

  saveElRef = el => {
    if (typeof this.props.domRef === 'function') {
      this.props.domRef(el);
    }
    this.el = el;
  };

  onClick(event) {
    this.props.onClick(event);

    // Need to check if the link is to an SPA page or not
    // We also need to check (temporarily) if the current page is an SPA page
    // this is done via a horrible global at the moment but will be removed
    // once the whole app is converted
    // Also, we do not use SPA links when the app is in an error state due to
    // the risk of inconsistent state
    const languages = [this.props.language];

    if (this.props.alternateLanguage) {
      languages.push(this.props.alternateLanguage);
    }

    this.internalRelativeUrl = this.getInternalRelative(this.props.to);

    if (
      !event.defaultPrevented &&
      !this.props.currentHtmlStatusCode &&
      !this.props.preventSpa &&
      window.isSPA &&
      this.internalRelativeUrl
    ) {
      match(
        {
          routes: routes(languages, this.props.f, this.props.c),
          location: this.internalRelativeUrl
        },
        (error, redirect, contextProps) => {
          if (contextProps) {
            const routes = contextProps.routes;
            const leafRoute = routes[routes.length - 1];

            let mfeRouteName = leafRoute?.mfeRouteName;
            if (typeof leafRoute?.mfeRouteNameMapper === 'function') {
              /*
              mfeRouteNameMapper is used to support routes with path params which determine what mfe is
              used e.g. slots-collection and slots-ondemand
              */
              mfeRouteName = leafRoute.mfeRouteNameMapper(contextProps.params);
            }

            if (
              isMFERouteAvailable(this.props.mfeRolloutConfig, mfeRouteName)
            ) {
              //If MFE route is enabled then stop SPA and do full server render.
              event.preventDefault();
              event.stopPropagation();

              window.location.href = this.internalRelativeUrl;
            } else if (leafRoute.path !== '*') {
              this.spaTransition(event);
            }
          }
        }
      );
    }
  }

  spaTransition(event) {
    event.preventDefault();
    this.props.router.push(this.internalRelativeUrl);
  }

  compileRegExps() {
    // this ensures we only compile the regexes once regardless of how many
    // links there are on the page.
    // We only need to do this on the client
    if (typeof regexes === 'undefined') {
      const { protocol, domain, path } = this;

      regexes = {
        protocol: new RegExp(`^${protocol}//`),
        domain: new RegExp(`^${domain}`),
        path: new RegExp(`^${path}`)
      };
    }
  }

  getInternalRelative(url) {
    // React router requires relative urls
    // returning null indicates the url was not internal and so should not use React Router
    if (typeof url !== 'string') {
      return null;
    }

    let result = url.trim();
    const { protocol, domain, path } = this;

    if (regexes.protocol.test(result)) {
      result = result.slice((protocol + '//').length);
    }

    if (regexes.domain.test(result)) {
      result = result.slice(domain.length);
    }

    if (regexes.path.test(result)) {
      result = result.slice(path.length - 1); // we don't want to take off the '/'
    }

    if (result[0] !== '/') {
      return null;
    }

    return path.slice(0, path.length - 1) + result;
  }

  render() {
    const validProps = pickByKey(this.props, validLinkProps);

    return (
      <a
        ref={this.saveElRef}
        onClick={this.onClick}
        href={this.props.to}
        {...validProps}
      />
    );
  }
}

export default withRouter(LinkCheckSpa);
