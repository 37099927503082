import React from 'react';
import PropTypes from 'prop-types';

const QandAContainer = props => {
  const { children } = props;

  return <dl className="qAndA-widget__container">{children}</dl>;
};

QandAContainer.propTypes = {
  children: PropTypes.node
};

export { QandAContainer };
