import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

function getTitle(primary, optional, headerTag) {
  const HeaderTag = headerTag;

  if (primary && optional) {
    return (
      <Fragment>
        <HeaderTag className="panel-comp__title">{primary}</HeaderTag>
        <span className="panel-comp__title-optional">{optional}</span>
      </Fragment>
    );
  }

  return (
    primary && <HeaderTag className="panel-comp__title">{primary}</HeaderTag>
  );
}

function Panel(props) {
  return (
    <section
      hidden={props.hidden}
      className={classNames('panel-comp', props.className, {
        [`panel-comp--${props.color}`]: props.color
      })}
    >
      {getTitle(props.title, props.optional, props.headerTag)}
      {props.children}
    </section>
  );
}

Panel.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  color: PropTypes.oneOf(['white', 'green']),
  headerTag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  hidden: PropTypes.bool,
  optional: PropTypes.string,
  title: PropTypes.string
};

Panel.defaultProps = {
  headerTag: 'h3',
  hidden: false,
  color: 'white'
};

export default Panel;
