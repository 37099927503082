import React from 'react';
import classnames from 'classnames';
import { connect } from '#/lib/render/connect-deep-compare';
import { StyledProductActions } from '#/components/product-tiles/horizontal-tile/core/styles';
import DeleteFavorite from '#/components/product-tiles/common/favorite-delete';
import { Item } from '#/lib/records/item';
import { TTranslateFunc, TConfigFunc } from '#/lib/records/helpers.defs';
import { PlainObject } from '#/types';
import {
  getIsSubstitute,
  hasSubstitution,
  getProductId,
  isInFavourites,
  isInBasket,
  getProductIsForSale,
  getProductTitle,
} from '#/selectors/item';
import { favoritesPageShouldShowRemoveButton } from '#/selectors/beans-product-tile/remove-button-data';
import FavoriteOptimisticDelete from '#/components/pages/favorites/favourites-tab/favorite-optimistic-delete';
import { StyledHorizontalTile } from './styled';
import { getOptimisticDeleteSelection, getSoftDeleteStatusForOptimisticDelete } from '#/selectors/ui';
import {
  getShouldShowLinks,
  getUnavailableItemHeightVariant,
  getShouldShowSeeAlternativesOnSubs,
  getShouldShowSeeAlternativesAsBuyboxAction,
} from '#/experiments/oop-1017/selectors';
import SeeAlternativesButton from '#/experiments/oop-1017/components';
import { FAVORITES_TAB } from '#/constants/favorites';

interface FavoriteTileBaseProps {
  item: Item;
  translate: TTranslateFunc;
  config: TConfigFunc;
}

const isExists = (selectionData: Array<string> = [], id: string): boolean => selectionData.includes(id);

export type FavoriteTileProps = FavoriteTileBaseProps;

const mapStateToProps = (state: Store, { item, config }: FavoriteTileBaseProps): PlainObject => {
  const productId = getProductId(item);
  const productTitle = getProductTitle(item);
  const isSubstitute = getIsSubstitute(item);
  let shouldRemoveFromList = false;

  if (config('showFavOptimisticDelete')) {
    const selectionData = getOptimisticDeleteSelection(state, FAVORITES_TAB);
    const softDeleteStatus = getSoftDeleteStatusForOptimisticDelete(state, FAVORITES_TAB);
    shouldRemoveFromList = softDeleteStatus && isExists(selectionData, productId);
  }

  const unavailableItemHeightVariant = getUnavailableItemHeightVariant(state);
  let shouldShowRestOfShelf = true;
  let shouldShowWriteAReview = true;
  let seeAlternativesButton = false;
  if (!getProductIsForSale(item) && !getShouldShowLinks(unavailableItemHeightVariant)) {
    const showLinks = getShouldShowLinks(unavailableItemHeightVariant);
    seeAlternativesButton = getShouldShowSeeAlternativesAsBuyboxAction(unavailableItemHeightVariant);
    shouldShowRestOfShelf = !hasSubstitution(item) && showLinks;
    shouldShowWriteAReview = showLinks;
  }
  if (isSubstitute && getShouldShowSeeAlternativesOnSubs(unavailableItemHeightVariant)) {
    seeAlternativesButton = true;
    shouldShowRestOfShelf = false;
    shouldShowWriteAReview = false;
  }

  return {
    isInBasket: isInBasket(item),
    productId,
    productTitle,
    isSubstitute,
    shouldShowPriceOnUnavailableTiles: hasSubstitution(item) && config('showPriceOnUnavailableTile'),
    shouldRemoveFromList,
    shouldShowRestOfShelf,
    shouldShowWriteAReview,
    seeAlternativesButton,
  };
};

type TProps = FavoriteTileProps & ReturnType<typeof mapStateToProps>;

export const FavoriteTile = (props: TProps): JSX.Element => {
  const {
    item,
    isSubstitute,
    shouldShowPriceOnUnavailableTiles,
    shouldRemoveFromList,
    seeAlternativesButton,
    config,
    productId,
    productTitle,
    ...otherProps
  } = props;

  const showRemoveButton = favoritesPageShouldShowRemoveButton(item);
  const deleteFavoriteComponent = config('showFavOptimisticDelete') ? (
    <FavoriteOptimisticDelete productId={productId} productTitle={productTitle} />
  ) : (
    <DeleteFavorite productId={productId} productTitle={productTitle} />
  );

  const renderSeeAlternativesButton = ({ classes = 'see-alternatives' }: { classes?: string } = {}): JSX.Element => (
    <SeeAlternativesButton classes={classes} translate={props.translate} item={item} />
  );

  return (
    <StyledHorizontalTile
      {...otherProps}
      config={config}
      className={classnames('favorite-tile', { 'remove-from-list-with-animation': shouldRemoveFromList })}
      isSubstitute={isSubstitute}
      item={item}
      shouldShowPriceOnUnavailableTiles={shouldShowPriceOnUnavailableTiles}
      showFavHeartIcon={isSubstitute && isInFavourites(item)}
      deleteActionButton={
        showRemoveButton && (
          <StyledProductActions data-auto="product-actions">{deleteFavoriteComponent}</StyledProductActions>
        )
      }
      {...(seeAlternativesButton && {
        renderSeeAlternativesButton,
      })}
    />
  );
};

export default connect(mapStateToProps)(FavoriteTile);
