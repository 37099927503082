import React, { useEffect } from 'react';
import { VERTICAL } from '#/components/product-tiles/common/constants';
import { CarouselWrapper, StyledHeading } from './styled';
import { ContentCarousel } from '@ddsweb/carousel';
import { ProductTileConfiguration } from '#/components/product-tiles/common/tile-configurations';
import { Item } from '#/lib/records/item';
import { PlainObject } from '#/types';
import {
  CAROUSEL_DISPLAY_AREA,
  CAROUSEL_SEED_LOOKUP,
  COMPONENT_TYPE,
  FULL_BASKET_PAGE_TYPE,
  MULTI_SUBS_COMPONENT,
  MULTI_SUBS_TYPE,
} from '#/experiments/oop-1934/constants';
import { basicEvent } from '#/analytics/types/basic';
import { NOW } from '#/analytics/constants';
import analyticsBus from '#/analytics/analyticsBus';
import { emitRenderedContentOp } from '#/analytics/bertie/events';
import { itemsToContentData } from '#/analytics/bertie/utils/products';

export const onCarouselButtonClick = (direction: string): void => {
  basicEvent(analyticsBus, {
    type: MULTI_SUBS_TYPE,
    value: direction,
    action: NOW,
  });
};

const MultiSwapCarousel: React.FC<{
  substituteTextHeading: string;
  substitutions: Array<Item>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  productTileProps: any;
  legacyProps: PlainObject;
}> = ({ substituteTextHeading, substitutions, productTileProps, legacyProps }) => {
  useEffect(() => {
    emitRenderedContentOp({
      content: [
        {
          componentName: MULTI_SUBS_COMPONENT,
          componentType: COMPONENT_TYPE,
          displayArea: CAROUSEL_DISPLAY_AREA,
          modulePosition: legacyProps.itemOriginalIndex + 1,
          pageType: FULL_BASKET_PAGE_TYPE,
          panel: itemsToContentData({
            items: substitutions,
            currency: 'GBP',
            seedLookup: CAROUSEL_SEED_LOOKUP,
          }),
        },
      ],
      itemsPerPage: substitutions.length,
      resultsCount: substitutions.length,
      resultCountForPage: substitutions.length,
    });
  }, []);

  return (
    <CarouselWrapper>
      <StyledHeading headingLevel="4">
        {substituteTextHeading.substring(0, substituteTextHeading.length - 3)}
      </StyledHeading>
      <ContentCarousel
        id={`trolley-carousel-content-${legacyProps.itemIndex}`}
        className="multi-swap"
        itemWidth="205px"
        onControlClick={(controlDirection): void => onCarouselButtonClick(controlDirection)}
      >
        {substitutions.map((substitution, index) => {
          const updatedLegacyProps = { ...legacyProps, item: substitution };
          const updatedProductTileProps = { ...productTileProps, item: substitution };
          return (
            <ProductTileConfiguration
              key={index}
              legacyProps={updatedLegacyProps}
              productTileVariant={VERTICAL}
              productTileProps={updatedProductTileProps}
            />
          );
        })}
      </ContentCarousel>
    </CarouselWrapper>
  );
};

export default MultiSwapCarousel;
