import url from 'url';

export function addFromParamToUrl(urlToChange, fromValue) {
  const parsedUrl = url.parse(urlToChange, true);

  parsedUrl.query.from = fromValue;

  delete parsedUrl.search;

  return url.format(parsedUrl);
}

export function getReturnUrl(protocol, host, fullUrl) {
  const newUrl = Object.assign(url.parse(fullUrl), {
    protocol: protocol,
    host: host
  });

  return url.format(newUrl);
}
