import { batchActions } from 'redux-batched-actions';
import {
  PRODUCT_DETAILS,
  TROLLEY_CONTENTS,
  TAXONOMY
} from '#/constants/spa-resource';
import { updateTescoRecommendations } from '#/actions/recommendations-action-creators';
import { updateProductDetails } from '#/actions/product-details-action-creators';
import { receiveReviews } from '#/actions/ratings-reviews-action-creators';
import { getProductDetailsResource } from '#/selectors/resources';

export const type = PRODUCT_DETAILS;
export const params = [
  'id',
  'includeReviews',
  'isAmendBasket',
  'nav',
  'pageName',
  'query',
  'referer',
  'slot',
  'trolleyItems'
];
export const dependencies = [TROLLEY_CONTENTS, TAXONOMY];

export function handler(dispatch, getState) {
  const { data } = getProductDetailsResource(getState());

  if (!data) {
    return;
  }
  const recommendations = data.recommendations || {};
  const actions = [
    receiveReviews(data.product),
    updateTescoRecommendations(recommendations.data)
  ];

  dispatch(updateProductDetails(data));
  dispatch(batchActions(actions));
}
