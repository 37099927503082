import { createSelector } from 'reselect';
import { isExpired, isValid } from '#/selectors/slot/slot-record';
import { NONE } from '#/constants/slot-statuses';
import { getFulfilmentEstimatedArrivalResource } from '#/selectors/resources';

export const getSlot = ({
  trolley: {
    trolley: { slot },
  },
}: Store) => slot;

export const getLastSelectedSlot = (state: Store) => {
  const {
    trolley: { bookedSlot },
  } = state;
  return bookedSlot;
};

export const getCurrentValidSlot = ({ trolley: { bookedSlot, previousSlot } }: Store) => {
  return ((!bookedSlot || isExpired(bookedSlot)) && previousSlot) || bookedSlot;
};

export const getTrolleySlotStatus = createSelector(
  getCurrentValidSlot,
  currentValidSlotJSObject => currentValidSlotJSObject.status,
);

export const getTrolleySlotCharge = createSelector(
  getCurrentValidSlot,
  currentValidSlotJSObject => currentValidSlotJSObject.charge,
);

export const getTrolleySlotReservationExpiry = createSelector(
  getCurrentValidSlot,
  currentValidSlotJSObject => currentValidSlotJSObject.reservationExpiry,
);

export const getTrolleySlotStartTime = createSelector(
  getCurrentValidSlot,
  currentValidSlotJSObject => currentValidSlotJSObject.start,
);

export const getTrolleySlotEndTime = createSelector(
  getCurrentValidSlot,
  currentValidSlotJSObject => currentValidSlotJSObject.end,
);

export const getLastTrolleySlotStatus = createSelector(
  getLastSelectedSlot,
  lastSelectedSlotJSObject => lastSelectedSlotJSObject.status,
);

export const getTrolleySlotGroup = createSelector(
  getLastSelectedSlot,
  lastSelectedSlotJSObject => lastSelectedSlotJSObject.group,
);

export const getHasSlot = createSelector(getLastSelectedSlot, slot => !!slot.status && slot.status !== NONE);

export const getTrolleySlotWorkingDaysUntilDelivery = (state: Store) => {
  const currentValidSlot = getCurrentValidSlot(state);

  if (currentValidSlot) {
    return currentValidSlot?.countdown?.workingDays || null;
  }

  return null;
};

export const hasValidSlot = (state: Store) => {
  const currentValidSlot = getCurrentValidSlot(state);
  return currentValidSlot && isValid(currentValidSlot);
};

// this selector needs to fetch from the main trolley state in case
// there is no previous slot in trolley in getDefaultStateFromProps
export const getPreviousSlot = ({ trolley: { previousSlot } }: Store) => previousSlot;

// this selector needs to fetch from the main trolley state
// as the bookedSlot prop is created in getDefaultStateFromProps
export const getBookedSlot = ({ trolley: { bookedSlot } = {} as Store['trolley'] }: Store) => bookedSlot;

export const hasBookedSlot = (state: Store) => !!getBookedSlot(state)?.status;

export const getOnDemandSlot = (state: Store) => {
  const deliveryDetails = getFulfilmentEstimatedArrivalResource(state);
  if (!deliveryDetails)
    return {
      unit: undefined,
      min: undefined,
      max: undefined,
    };
  return {
    unit: deliveryDetails.data?.time.unit,
    min: deliveryDetails.data?.time.range.min,
    max: deliveryDetails.data?.time.range.max,
  };
};
