import React from 'react';
import ContentStamp from '@ddsweb/content-stamps';
import Icon from '@ddsweb/icon';
import helpers from '#/lib/decorators/helpers';
import { StyledContainer, StyledLink, StyledDiv, BorderContainer } from './styled';
import { emitContentInteractOp } from '#/analytics/bertie/events';
import { GraphicsKeys } from '@ddsweb/types';

type OwnProps = {
  image: string;
  title: string;
  link: {
    href: string;
    target: string;
  };
  t: Function;
  recipeDetails: [{ icon: GraphicsKeys; text: string }];
  posInPanel: number;
  placement: number;
  catId: string;
  segments: [string];
  ruleId: string;
};

type CTAProps = {
  children: React.ReactNode;
};

const RecipeTileComponent: React.FC<OwnProps> = (props: OwnProps) => {
  const { recipeDetails, link, title, image, t: translate } = props;
  const recipeBodyText = (
    <>
      {recipeDetails.map(({ icon, text }, index) => (
        <StyledDiv key={index.toString()}>
          <i>
            <Icon graphic={icon} size="sm" />
          </i>
          <span>{text}</span>
        </StyledDiv>
      ))}
    </>
  );

  const renderCTA = ({ children }: CTAProps): JSX.Element => (
    <StyledLink stretch href={link.href} target={link.target} variant="textButton">
      {children}
    </StyledLink>
  );

  const clickHandler = (): void => {
    const { posInPanel: posInModule, placement: modulePosition, catId, segments: contentSegment, ruleId } = props;

    emitContentInteractOp({
      displayArea: 'm-of',
      pageType: 'evnt',
      panel: [
        {
          campaignId: title ? title.trim() : catId,
          contentSegment,
          posInModule,
          ruleId,
        },
      ],
      modulePosition,
    });
  };

  return (
    <StyledContainer>
      <BorderContainer>
        <ContentStamp
          ariaLabel={title}
          onClick={clickHandler}
          // @ts-ignore:next-line
          bodyText={recipeBodyText}
          headline={title}
          href={link.href}
          image={{
            alt: title,
            src: image,
          }}
          link={{
            text: translate('home:recipe-tile.button-title'),
          }}
          renderCTA={renderCTA}
        />
      </BorderContainer>
    </StyledContainer>
  );
};

const RecipeTile = helpers(['t'])(RecipeTileComponent);
export { RecipeTile };
