import styled from 'styled-components';
import Button from '@ddsweb/button';
import { breakpoints, fontSize, spacing } from '@ddsweb/selectors';

export const SubmitButton = styled(Button)`
  font-size: ${fontSize.xx};

  @media (min-width: ${breakpoints.tabletLarge}px) {
    font-size: 18px;
  }
`;

export const SpinnerContainer = styled.div`
  margin-left: ${spacing.xs};
  display: flex;
  align-items: center;
`;
