import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { ConnectedProps } from 'react-redux';
import { compose } from 'react-recompose';
import helpers from '#/lib/decorators/helpers';
import { connect } from '#/lib/render/connect-deep-compare';
import { getActiveExperimentsFromWindow } from '#/lib/open-feedback-window';
import { TTranslateFunc } from '#/lib/records/helpers.defs';
import { getBrowserType, getCutsMustard, getDeviceType, getTraceId } from '#/reducers/app';
import { getIsFirstTimeShopper, getUserHashedUId } from '#/reducers/user';
import analyticsBus from '#/analytics/analyticsBus';
import { basicEvent } from '#/analytics/types/basic';
import { NOW, STAR_RATING, STAR_RATING_TYPE } from '#/analytics/constants';
import {
  CustomWindow,
  MapStateToPropsRetType,
  RatingBasicLabelsOwnProps,
  RatingBasicOwnProps,
  ValueObj,
} from './index.defs';
import { colors, fontFamily } from '@ddsweb/selectors';
import DdsStarRating from '@ddsweb/star-rating';
import { getTrolleyLocationUuid } from '#/selectors/trolley';

const StarRatingZeroLabel = styled.p`
  font-family: ${fontFamily.text};
  font-size: 16px;
  color: ${colors.text};
`;

StarRatingZeroLabel.displayName = 'StarRatingZeroLabel';

const mapStateToProps = (state: Store): MapStateToPropsRetType => ({
  browserType: getBrowserType(state),
  cutsMustard: getCutsMustard(state),
  deviceType: getDeviceType(state),
  hashedUId: getUserHashedUId(state),
  isFirstTimeShopper: getIsFirstTimeShopper(state),
  locationUuid: getTrolleyLocationUuid(state),
  traceId: getTraceId(state),
});

const connector = connect(mapStateToProps);

type OwnProps = {
  t: TTranslateFunc;
};

type TProps = OwnProps & RatingBasicOwnProps & ConnectedProps<typeof connector>;

const getDefaultStarRatingLabels = (translate: TTranslateFunc): Array<RatingBasicLabelsOwnProps> => {
  const textRoot = 'common:star-rating';

  return [1, 2, 3, 4, 5].map(starNum => ({
    href: '#',
    hintText: translate(`${textRoot}.star-${starNum}.hint-text`),
    ariaLabel: translate(`${textRoot}.star-${starNum}.hint-aria-label`),
  }));
};

const sendAnalyticsEvent = (rating: number): void => {
  basicEvent(analyticsBus, {
    action: NOW,
    type: STAR_RATING_TYPE,
    value: `${STAR_RATING}:${rating}`,
  });
};

const InteractiveStarRating: FC<TProps> = ({
  t: translate,
  browserType,
  cutsMustard,
  deviceType,
  hashedUId,
  isFirstTimeShopper,
  ratingLabels,
  ratingValue = 0,
  locationUuid,
  traceId,
  refererUrl,
  noRatingCopy,
}) => {
  const [value, setValue] = useState(ratingValue);

  const onStarClick = ({ value: newValue }: ValueObj, e: Event): void => {
    e?.preventDefault();
    e?.stopPropagation?.();

    setValue(newValue);
    sendAnalyticsEvent(newValue);
    /*
      'oo_launchStarRating' is the method that is defined during build time at 'web/assets/scripts/third-party/oo_conf_inline.js' (which is a 3rd party script)
      'ghs_customerID' and 'overall_rating' are the variables that OnlineOpinion (a 3rd party) accepts.
      Hence, the TS rule for 'camelcasing' has been disabled below at necessary places
    */

    const customerVariables = {
      browserType,
      cutsMustard: String(cutsMustard),
      deviceType,
      ghsUUID: hashedUId,
      ghs_customerID: hashedUId,
      isFirstTimeShopper: String(isFirstTimeShopper),
      overall_rating: newValue,
      locationUuid,
      url: refererUrl,
      traceId,
    };

    (window as CustomWindow).OOo.oo_launchStarRating({
      ...customerVariables,
      ...getActiveExperimentsFromWindow(),
    });
  };

  const starRatingAccessibleLabel = `${value} stars out of 5`;
  const interactiveStars = ratingLabels || getDefaultStarRatingLabels(translate);

  return (
    <>
      <DdsStarRating
        accessibleLabel={starRatingAccessibleLabel}
        interactiveStars={interactiveStars}
        value={value}
        variant="interactive"
        onClick={onStarClick}
      />
      {noRatingCopy && value === 0 && <StarRatingZeroLabel>{noRatingCopy}</StarRatingZeroLabel>}
    </>
  );
};

const enhance = compose<TProps, RatingBasicOwnProps>(connector);

export default enhance(helpers(['t'])(InteractiveStarRating));
