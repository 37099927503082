export const regularPriceClubCardTileTemplate = {
  xxp_clubcard_price_square: {
    valueContent: [
      {
        text: `%{value1}%{value3}`,
        size: ['lg', 'lg', 'sm', 'xs', 'xx', 'xx']
      },
      {
        text: 'Clubcard',
        size: 'xxx'
      },
      {
        text: 'Price',
        size: 'xxx'
      }
    ],
    mode: 'clubcard',
    variant: 'squareLarge',
    regularPrice: {
      valueContent: [
        { size: 'xxx', text: 'Regular Price' },
        {
          size: 'sm',
          text: '%{value2}%{value1}'
        }
      ],
      mode: 'regularPrice',
      variant: 'horizontalMicro'
    }
  },
  xxp_clubcard_price_square_each: {
    valueContent: [
      {
        text: `%{value1}%{value3}`,
        size: ['sm', 'sm', 'xs', 'xx', 'xx', 'xx']
      },
      {
        text: 'each',
        size: 'xxx'
      },
      {
        text: 'Clubcard',
        size: 'xxx'
      },
      {
        text: 'Price',
        size: 'xxx'
      }
    ],
    mode: 'clubcard',
    variant: 'squareLarge',
    regularPrice: {
      valueContent: [
        { size: 'xxx', text: 'Regular Price' },
        {
          size: ['xs', 'xs', 'xs', 'xs', 'xs', 'xx', 'xx', 'xx'],
          text: '%{value2}%{value1} each'
        }
      ],
      mode: 'regularPrice',
      variant: 'horizontalMicro'
    }
  },
  xxp_clubcard_price_rect: {
    valueContent: [
      {
        text: 'Clubcard Price',
        size: 'xsc'
      },
      {
        text: `%{value1}%{value3}`,
        size: 'sm'
      }
    ],
    mode: 'regularPriceClubcard',
    variant: 'horizontal',
    regularPrice: {
      valueContent: [
        { size: 'xsc', text: 'Regular Price' },
        {
          size: 'sm',
          text: '%{value2}%{value1}'
        }
      ],
      mode: 'regularPrice',
      variant: 'horizontal'
    }
  },
  xxp_clubcard_price_rect_each: {
    valueContent: [
      {
        text: 'Clubcard Price',
        size: 'xsc'
      },
      {
        text: `%{value1}%{value3}`,
        size: 'sm'
      },
      {
        text: 'each',
        size: 'xsc'
      }
    ],
    mode: 'regularPriceClubcard',
    variant: 'horizontal',
    regularPrice: {
      valueContent: [
        { size: 'xsc', text: 'Regular Price' },
        {
          size: 'sm',
          text: '%{value2}%{value1}'
        },
        { size: 'xsc', text: 'each' }
      ],
      mode: 'regularPrice',
      variant: 'horizontal'
    }
  },
  x_pound_clubcard_price_square: {
    valueContent: [
      {
        text: `%{value2}%{value1}`,
        size: ['lg', 'lg', 'sm', 'xs', 'xx', 'xx']
      },
      {
        text: 'Clubcard',
        size: 'xxx'
      },
      {
        text: 'Price',
        size: 'xxx'
      }
    ],
    mode: 'clubcard',
    variant: 'squareLarge',
    regularPrice: {
      valueContent: [
        { size: 'xxx', text: 'Regular Price' },
        {
          size: 'sm',
          text: '%{value2}%{value1}'
        }
      ],
      mode: 'regularPrice',
      variant: 'horizontalMicro'
    }
  },
  x_pound_clubcard_price_square_each: {
    valueContent: [
      {
        text: `%{value2}%{value1}`,
        size: ['sm', 'sm', 'xs', 'xx', 'xx', 'xx']
      },
      {
        text: 'each',
        size: 'xxx'
      },
      {
        text: 'Clubcard',
        size: 'xxx'
      },
      {
        text: 'Price',
        size: 'xxx'
      }
    ],
    mode: 'clubcard',
    variant: 'squareLarge',
    regularPrice: {
      valueContent: [
        { size: 'xxx', text: 'Regular Price' },
        {
          size: ['xs', 'xs', 'xs', 'xs', 'xs', 'xx', 'xx', 'xx'],
          text: '%{value2}%{value1} each'
        }
      ],
      mode: 'regularPrice',
      variant: 'horizontalMicro'
    }
  },
  x_pound_clubcard_price_rect: {
    valueContent: [
      { size: 'xsc', text: 'Clubcard Price' },
      {
        size: 'sm',
        text: '%{value2}%{value1}'
      }
    ],
    mode: 'regularPriceClubcard',
    variant: 'horizontal',
    regularPrice: {
      valueContent: [
        { size: 'xsc', text: 'Regular Price' },
        {
          size: 'sm',
          text: '%{value2}%{value1}'
        }
      ],
      mode: 'regularPrice',
      variant: 'horizontal'
    }
  },
  x_pound_clubcard_price_rect_each: {
    valueContent: [
      { size: 'xsc', text: 'Clubcard Price' },
      {
        size: 'sm',
        text: '%{value2}%{value1}'
      },
      { size: 'xsc', text: 'each' }
    ],
    mode: 'regularPriceClubcard',
    variant: 'horizontal',
    regularPrice: {
      valueContent: [
        { size: 'xsc', text: 'Regular Price' },
        {
          size: 'sm',
          text: '%{value2}%{value1}'
        },
        { size: 'xsc', text: 'each' }
      ],
      mode: 'regularPrice',
      variant: 'horizontal'
    }
  },
  any_x_for_x_pound_clubcard_price_square: {
    valueContent: [
      {
        text: 'Any',
        size: 'xxx'
      },
      {
        text: ' %{value1} for %{value3}%{value2}',
        size: 'xx'
      },
      {
        text: 'Clubcard',
        size: 'xxx'
      },
      {
        text: 'Price',
        size: 'xxx'
      }
    ],
    mode: 'clubcard',
    variant: 'squareLarge',
    regularPrice: {
      valueContent: [
        { size: 'xxx', text: 'Regular Price' },
        {
          size: 'sm',
          text: '%{value2}%{value1}'
        }
      ],
      mode: 'regularPrice',
      variant: 'horizontalMicro'
    }
  },
  any_x_for_x_pound_clubcard_price_square_each: {
    valueContent: [
      {
        text: 'Any',
        size: 'xxx'
      },
      {
        text: ' %{value1} for %{value3}%{value2}',
        size: 'xx'
      },
      {
        text: 'Clubcard',
        size: 'xxx'
      },
      {
        text: 'Price',
        size: 'xxx'
      }
    ],
    mode: 'clubcard',
    variant: 'squareLarge',
    regularPrice: {
      valueContent: [
        { size: 'xxx', text: 'Regular Price' },
        {
          size: ['xs', 'xs', 'xs', 'xs', 'xs', 'xx', 'xx', 'xx'],
          text: '%{value2}%{value1} each'
        }
      ],
      mode: 'regularPrice',
      variant: 'horizontalMicro'
    }
  },
  any_x_for_x_pound_clubcard_price_rect: {
    valueContent: [
      {
        text: 'Clubcard Price',
        size: 'xsc'
      },
      {
        text: 'Any %{value1} for %{value3}%{value2}',
        size: 'sm'
      }
    ],
    mode: 'regularPriceClubcard',
    variant: 'horizontal',
    regularPrice: {
      valueContent: [
        { size: 'xsc', text: 'Regular Price' },
        {
          size: 'sm',
          text: '%{value2}%{value1}'
        }
      ],
      mode: 'regularPrice',
      variant: 'horizontal'
    }
  },
  any_x_for_x_pound_clubcard_price_rect_each: {
    valueContent: [
      {
        text: 'Clubcard Price',
        size: 'xsc'
      },
      {
        text: 'Any %{value1} for %{value3}%{value2}',
        size: 'sm'
      }
    ],
    mode: 'regularPriceClubcard',
    variant: 'horizontal',
    regularPrice: {
      valueContent: [
        { size: 'xsc', text: 'Regular Price' },
        {
          size: 'sm',
          text: '%{value2}%{value1}'
        },
        { size: 'xsc', text: 'each' }
      ],
      mode: 'regularPrice',
      variant: 'horizontal'
    }
  }
};
