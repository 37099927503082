import React from 'react';
import PropTypes from 'prop-types';
import { connect } from '#/lib/render/connect-deep-compare';
import helpers from '#/lib/decorators/helpers';
import { hasValidSlot, getIsOnDemandDelivery } from '#/selectors/trolley';
import formatNumber from '#/lib/string-formatting/number-formatter';
import Link from '#/components/link-check-spa';
import Icon from '#/components/shared/icon';
import SafelyOutputString from '#/components/safely-output-string';

const mapStateToProps = state => ({
  hasValidSlot: hasValidSlot(state),
  isOnDemandDelivery: getIsOnDemandDelivery(state)
});

@connect(mapStateToProps)
@helpers(['c', 't', 'f'])
export default class TrolleySurchargeWarning extends React.PureComponent {
  static propTypes = {
    c: PropTypes.func.isRequired,
    f: PropTypes.func.isRequired,
    isOnDemandDelivery: PropTypes.bool.isRequired,
    minimumBasketValue: PropTypes.number,
    surcharge: PropTypes.number,
    t: PropTypes.func.isRequired
  };

  getSurchargeWarningText = (minBasketCharge, minBasketThreshold) => {
    const { isOnDemandDelivery, t: translate, hasValidSlot } = this.props;

    if (hasValidSlot) {
      const basketChargeTextKey = isOnDemandDelivery
        ? 'ondemand-min-basket-charge'
        : 'min-basket-charge';
      return translate(`trolley:warnings.${basketChargeTextKey}.slot`, {
        'min-basket-charge': minBasketCharge,
        'min-basket-threshold': minBasketThreshold
      });
    }
    return translate('trolley:warnings.min-basket-charge.no-slot', {
      'min-basket-charge': minBasketCharge
    });
  };

  getMessage() {
    const { c: config, minimumBasketValue, surcharge } = this.props;
    const currencyConfig = config('currency');
    const options = {
      decimalPlaces: currencyConfig.decimalPlaces,
      decimalMark: currencyConfig.decimalMark,
      symbol: currencyConfig.symbol,
      showAsciiSymbol: true,
      compactMode: !currencyConfig.spaceBetweenSymbolAndValue,
      symbolPosition: currencyConfig.symbolPosition,
      thousandsSeparator: currencyConfig.thousandsSeparator
    };

    const minBasketCharge = formatNumber(surcharge, options);
    const minBasketThreshold = formatNumber(minimumBasketValue, options);
    return this.getSurchargeWarningText(minBasketCharge, minBasketThreshold);
  }

  render() {
    const { c: config, surcharge, t: translate } = this.props;

    return surcharge ? (
      <div className="trolley-surcharge-warning">
        <div className="trolley-surcharge-warning--link">
          <div>
            <span>
              <SafelyOutputString>{this.getMessage()}</SafelyOutputString>
            </span>
            {config('showMinBasketChargeHelpLink') && (
              <Link
                to={config('links:basketSurchargeInfo')}
                target="surcharge-accessibility"
                rel="noopener noreferrer"
                externalWindow={true}
              >
                <Icon
                  name="question-dark"
                  hiddenText={translate(
                    'trolley:warnings.basket-surcharge-accessibility'
                  )}
                />
              </Link>
            )}
          </div>
        </div>
      </div>
    ) : (
      <div className="trolley-surcharge-warning--no-show-block" />
    );
  }
}
