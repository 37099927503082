import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Link from '../link-check-spa';

const SimpleButton = ({
  accessibilityText,
  buttonClass,
  buttonText,
  dataAuto,
  href,
  onClick,
  type,
  disabled,
  domRef
}) => {
  const isPrimaryButton = type === 'primary';
  const classes = classnames(buttonClass, 'button', {
    'button-primary': isPrimaryButton,
    'button-secondary': !isPrimaryButton,
    'button-disabled': disabled
  });

  return (
    <Link
      className={classes}
      to={href}
      onClick={onClick}
      data-auto={dataAuto}
      {...(accessibilityText && { 'aria-label': accessibilityText })}
      domRef={domRef}
    >
      {buttonText}
    </Link>
  );
};

SimpleButton.propTypes = {
  accessibilityText: PropTypes.string,
  buttonClass: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  dataAuto: PropTypes.string,
  disabled: PropTypes.bool,
  domRef: PropTypes.func,
  href: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['primary', 'secondary']).isRequired
};

SimpleButton.defaultProps = {
  type: 'primary',
  buttonClass: '',
  disabled: false,
  href: '#'
};

export default SimpleButton;
