import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import SafelyOutputString from '#/components/safely-output-string';

const InfoMessage = props => {
  const classes = classnames('info-message-comp', props.className);

  if (props.html) {
    return (
      <div className={classes}>
        <SafelyOutputString>{props.children}</SafelyOutputString>
      </div>
    );
  }

  return <div className={classes}>{props.children}</div>;
};

InfoMessage.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  html: PropTypes.bool
};

export default InfoMessage;
