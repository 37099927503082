import { getOrderListDetailsResource } from '#/selectors/resources';
import { ORDER_LIST_DETAILS } from '#/constants/spa-resource';
import { updateOrderListDetails } from '#/actions/order-list-details-action-creators';

export const type = ORDER_LIST_DETAILS;

export const params = ['shouldTrackLiveOrder'];

export const handler = (dispatch, getState) => {
  const { data } = getOrderListDetailsResource(getState());

  dispatch(updateOrderListDetails(data));
};

export const cacheable = false;
