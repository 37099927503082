import produce from 'immer';
import { NEW_LOCATIONS_DATA } from '#/constants/action-types';

export const getLocations = state => state.location.locations || [];

export default function location(state = {}, { type, value }) {
  return produce(state, draft => {
    switch (type) {
      case NEW_LOCATIONS_DATA: {
        draft.locations = value.locations || [];
        break;
      }
    }
  });
}

export const getDefaultStateFromProps = ({
  locations,
  resources: { location } = {}
}) => {
  return {
    locations: (location && location.data) || locations || []
  };
};
