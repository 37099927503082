import {
  BREAKPOINT_1184,
  BREAKPOINT_992,
  BREAKPOINT_504,
  ITEMS_PER_ROW_DESKTOP,
  ITEMS_PER_ROW_TABLET,
  ITEMS_PER_ROW_MOBILE_LARGE,
  ITEMS_PER_ROW_MOBILE,
  RECS_CAROUSEL_DISPLAY_THRESHOLD,
  BASKET_ADD_COUNT_RECS_CAROUSEL,
  BREAKPOINT_1512,
  ITEMS_PER_ROW_LARGE_DESKTOP,
  IBYCCarouselVariants,
} from '#/experiments/oop-2349/constants';
import { sessionStore } from '#/lib/data-store/client-store';
import { Item } from '#/lib/records/item';
import { getBaseProductId } from '#/selectors/item';

type CarouselDetails = {
  isIBYCCarouselEnabled: boolean;
  pageNo: number;
  recsCarouselItemIndex: number;
  showRecsCarousel: boolean;
  maxItemIndexVal: number;
};

const viewport = typeof window !== 'undefined' ? window.innerWidth : null;

export const calculateIBYCCarouselIndex = (
  recsCarouselItemIndex: number,
  itemsPerRow: number,
  maxItemIndexValue: number,
): number => {
  const row = Math.floor(recsCarouselItemIndex / itemsPerRow);
  const carouselIndex = (row + 1) * itemsPerRow - 1;
  return maxItemIndexValue > -1 && carouselIndex > maxItemIndexValue ? maxItemIndexValue : carouselIndex;
};

export const updateIBYCCarouselIndex = (
  prevPropsPageNo: number,
  _dispatchInterruptAnalytics: () => void,
  carouselDetails: CarouselDetails,
): number | null => {
  const { isIBYCCarouselEnabled, pageNo, recsCarouselItemIndex, showRecsCarousel, maxItemIndexVal } = carouselDetails;
  let itemsPerRow = ITEMS_PER_ROW_MOBILE;
  let ibycCarouselIndex;

  const basketAddCountFromSession = sessionStore?.get(BASKET_ADD_COUNT_RECS_CAROUSEL) || 0;

  if (prevPropsPageNo !== pageNo) {
    _dispatchInterruptAnalytics();
  }

  if (
    !showRecsCarousel &&
    viewport !== null &&
    basketAddCountFromSession % RECS_CAROUSEL_DISPLAY_THRESHOLD === 0 &&
    recsCarouselItemIndex !== null &&
    isIBYCCarouselEnabled
  ) {
    if (viewport >= BREAKPOINT_1512) {
      itemsPerRow = ITEMS_PER_ROW_LARGE_DESKTOP;
    } else if (viewport >= BREAKPOINT_1184) {
      itemsPerRow = ITEMS_PER_ROW_DESKTOP;
    } else if (viewport >= BREAKPOINT_992) {
      itemsPerRow = ITEMS_PER_ROW_TABLET;
    } else if (viewport >= BREAKPOINT_504) {
      itemsPerRow = ITEMS_PER_ROW_MOBILE_LARGE;
    }
    ibycCarouselIndex = calculateIBYCCarouselIndex(recsCarouselItemIndex, itemsPerRow, maxItemIndexVal);

    return ibycCarouselIndex;
  }
  return null;
};

export const getTargetTPNBs = (
  targetTPNBs: Array<string> | undefined,
  ibycCarouselVariant: IBYCCarouselVariants,
  trolleyItems: Array<Item>,
  excludedProducts: Array<string>,
): Array<string> => {
  const lastItemAddedToBasket = getBaseProductId(trolleyItems[0]);
  if (ibycCarouselVariant === IBYCCarouselVariants.LAST_BASKET_ADD) {
    return [lastItemAddedToBasket];
  }

  return targetTPNBs || [excludedProducts[0]];
};
