import { fontSize } from '@ddsweb/selectors';
import styled, { css } from 'styled-components';
import { TOGGLE_QUANTITY_TYPE } from '#/selectors/beans-product-tile/quantity-controls-data';
import { SMALL } from '#/components/product-tiles/temp-style-constants';

const additionQuantityControlsStyles = css`
  align-self: flex-end;

  .beans-quantity-controls__buttons {
    display: flex;
    justify-content: space-between;
  }

  .beans-radio-button-with-label__label {
    font-size: ${fontSize.xx};
    line-height: 1.7;
  }
`;

const disabledAddButtonStyles = css`
  .beans-quantity-controls__add-button {
    background-color: #cccccc;
    color: #ffffff;
    pointer-events: none;
    text-decoration: none;
  }
  .beans-quantity-controls__remove-button:disabled {
    border: 2px solid;
  }
`;

export const SafeFormWrapper = styled.div`

  width: 100%;

  // TODO: There is a bug in Beans where
  // very narrow tiles look squashed.
  // This is a temporary override.
  ${({ quantityType }: { quantityType: typeof TOGGLE_QUANTITY_TYPE }): string =>
    quantityType === TOGGLE_QUANTITY_TYPE &&
    css`
      .beans-buybox__weight-input-controls {
        width: 100%;
      }
    `}
  @media ${SMALL} {
    .beans-button__text {
      font-size: 14px;
    }
  }
`;

// TODO: beans-quantity-controls__buttons is temporary and to be removed when @beans moves to SC5
export const getAdditionalQuantityControlsStyles = (disabled: boolean, buyboxStyles?: string): string =>
  disabled
    ? css`
        ${additionQuantityControlsStyles}
        ${disabledAddButtonStyles}
        ${buyboxStyles}
      `
    : css`
        ${additionQuantityControlsStyles}
        ${buyboxStyles}
      `;
