import styled, { css } from 'styled-components';
import { colors, spacing, fontSize } from '@beans/selectors';
import { TESCO_MODERN_FONT_FAMILY } from '@beans/constants';
import { Heading } from '@beans/heading';
import Text from '@beans/text';
import Tag from '@beans/tag';
import Button from '@beans/button';
import OpeningHours from '@beans/icon';
import StoreLocator from '@beans/icon';
import { media } from '@beans/foundation';
import Link from '@beans/link';

export const StyledBoldText = styled(Text)`
  font-weight: bold;
`;

export const StyledCardFlexedColumn = styled.div`
  position: relative;
  padding: 0;
  flex: 0 0 auto;
  width: 100%;

  ${media.belowTabletLarge`
    &:first-child {
      border-bottom: 1px solid ${colors.backgroundDarkest};
      margin-bottom: ${spacing.lg};
      padding-bottom: ${spacing.lg};
    }
  `}

  ${media.aboveTabletLarge`
    width: auto;
    padding: ${spacing.lg};

    &:not(:first-child) {
      flex: 1 1 auto;
      max-width: 340px;

      &:before {
        content: '';
        position: absolute;
        display: block;
        border-top: 0;
        border-left: 1px solid ${colors.backgroundDarkest};
        top: ${spacing.lg};
        bottom: ${spacing.lg};
        left: 0;
      }
    }
  `}
`;

const StyledFlexedContainerWithButton = css`
  display: block;

  ${media.belowTabletLarge`
    width: 100%;
  `}
`;

export const StyledFlexedContainer = styled.div`
  display: flex;
  align-items: flex-start;
  ${({ isButton }: { isButton: boolean }): string => isButton && StyledFlexedContainerWithButton};
`;

const StyledCardTilePending = css`
  display: flex;
  flex-direction: column;

  ${media.belowTabletLarge`
    ${StyledCardFlexedColumn}:last-of-type {
      justify-content: center;
      flex: 1 0 auto;
      align-items: 'center';
    }
  `}
`;

const getColsStyle = (cols: number): string =>
  (cols === 2 || cols === 4) &&
  css`
    ${StyledCardFlexedColumn}:last-of-type {
      display: flex;
      align-items: center;

      ${cols === 4 &&
        css`
          justify-content: center;

          ${media.belowTabletLarge`
            border-top: 1px solid ${colors.backgroundDarkest};
            margin-top: ${spacing.lg};
            padding-top: ${spacing.lg};
          `}
        `}
    }
  `;

export const StyledCardTile = styled.div`
  min-height: 290px;
  border-radius: ${spacing.xs};
  border: 1px solid ${colors.backgroundDarkest};
  padding: ${spacing.lg};
  margin: 0 0 ${spacing.sm} 0;
  background: ${colors.white};
  ${({ isPending }: { isPending: boolean }): string => isPending && StyledCardTilePending};
  ${({ cols }: { cols: number }): string => getColsStyle(cols)};

  ${media.aboveTabletLarge`
    display: flex;
    min-height: 110px;
    max-width: 100%;
    padding: 0;
    justify-content: flex-start;
    flex-direction: row;
  `}
 }
`;

export const StyledCardTitle = styled(Tag)`
  display: inline-flex;

  ${media.aboveTabletLarge`
    display: block;
    margin-top: ${spacing.xs};
  `}
`;

export const StyledTileSection = styled.section`
  margin-bottom: ${spacing.xs};
`;

const sharedIconStyles = css`
  flex: 0 0 auto;
  width: 36px;
  height: 36px;

  & svg {
    stroke-width: 2px;
  }
`;

export const StyledOpeningIcon = styled(OpeningHours)`
  ${sharedIconStyles};
`;

export const StyledStoreIcon = styled(StoreLocator)`
  ${sharedIconStyles};
`;

export const StyledTileHeading = styled(Heading)`
  color: ${colors.heading};

  ${media.belowTabletLarge`
    display: flex;
    justify-content: space-between;
  `}
`;

export const StyledCardBody = styled.div`
  padding-left: ${spacing.md};
  margin-bottom: ${spacing.md};
  color: ${colors.grayscale};
  font-size: ${fontSize.xs};
  font-family: ${TESCO_MODERN_FONT_FAMILY};

  ${({ isBold }: { isBold: boolean }): string => (isBold ? 'font-weight: bold' : '')};

  ${media.aboveTabletLarge`
      margin-bottom: 0;
  `}
`;

export const BlockOnDesktop = styled(StyledBoldText)`
  ${media.aboveTabletLarge`
    display: block;
  `}
`;

const BookedButtonState = css`
  background-color: ${colors.success};
`;

export const StyledButton = styled(Button)`
  min-width: 150px;
  border-radius: 24px;
  ${({ isBookedState }: { isBookedState: boolean }): string => isBookedState && BookedButtonState};
`;

export const StyledLink = styled(Link)`
  display: block;
  font-weight: bold;
  font-size: ${fontSize.xx};
  margin-top: ${spacing.xs};
`;
