// @ts-nocheck
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import styled, { css } from 'styled-components';
import { Theme } from '@ddsweb/types';
import { spacing } from '@ddsweb/selectors';
import { FlashSashProps } from '../index';
import { PRODUCT_DETAIL_TILE } from '#/constants/tile-types';

interface FlashSashPropsWithTheme extends FlashSashProps {
  theme: Theme;
  hexColor: string;
}

export const FlashSashContent = styled.p`
  height: 24px;
  ${({ hexColor, tileType }: FlashSashPropsWithTheme): string => {
    let styles = '';
    if (!tileType) {
      styles += `
        &::after {
          background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2067%2040%22%3E%3Cpath%20fill%3D%22${hexColor.replace(
            '#',
            '%23',
          )}%22%20d%3D%22M0%200v40h2.7c17.5-.6%2026-7.6%2036.8-18.2%200%200%2016.5-16.5%2027.6-21.8H0z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E');
          background-repeat: no-repeat;
          background-position: 0 0;
          content: "";
          height: 24px;
          width: 35px;
          background-size: 40px 24px;
          position: absolute;
        }
      `;
    }
    return styles;
  }};
`;

export const FlashSashText = styled.strong`
  position: relative;
  color: white;
  height: 100%;
  padding: 3px ${spacing.xs} 5px ${spacing.xs};
  float: left;
  font-family: 'TESCO Modern', Arial, sans-serif;
  display: flex;
  align-items: center;

  ${({ tileType, hexColor, backgroundColor, theme: { colors, fontSize } }: FlashSashPropsWithTheme): string => {
    let styles = `
      font-size: ${fontSize.xxx};
      background-color: ${hexColor};
    `;

    if (backgroundColor === 'yellow') {
      styles += `color: ${colors.black};`;
    }

    if (tileType === PRODUCT_DETAIL_TILE) {
      styles += `border-top-left-radius: 6px;`; // TODO: Introduce borderRadius in @beans (spacing doesn't have a 6px value);
    }
    return styles;
  }};
`;
