import React, { ReactNode } from 'react';
import { connect } from '#/lib/render/connect-deep-compare';
import { useTheme } from 'styled-components';
import { FORWARD_LINK, XX } from '@ddsweb/constants';
import { BasicAccordion } from '@ddsweb/accordion';
import Icon from '@ddsweb/icon';
import { DefaultStyledProps, Graphics } from '@ddsweb/types';
import { Footnote } from '@ddsweb/typography';
import {
  FulfilmentStepsContainer,
  StepsContainer,
  StyledMoreAboutLink,
  IconContainer,
  IconStamp,
  Title,
} from './styled';
import { SectionMessage } from '#/components/cms-driven';
import helpers from '#/lib/decorators/helpers';
import { TTranslateFunc } from '#/lib/records/helpers.defs';
import analyticsBus from '#/analytics/analyticsBus';
import { ACCORDION_COLLAPSE, ACCORDION_EXPAND, NOW, ONDEMAND_DELIVERY } from '#/analytics/constants';
import { basicEvent } from '#/analytics/types/basic';
import { getShowSlotUIReskinV2 } from '#/experiments/oop-2205/selectors';
import {
  StyledAccordionContent,
  StyledAccordionWrapper,
} from '#/experiments/oop-2205/components/fulfilment-steps/styled';
import { HOW_WHOOSH_WORKS } from '#/experiments/oop-2205/constants';

export interface GraphicsKeys {
  icon: keyof Graphics;
  backgroundColor: (props: DefaultStyledProps) => string;
  stroke: (props: DefaultStyledProps) => string;
}

type TProps = {
  deliverySteps: Array<{ graphic: GraphicsKeys; customIcon: React.ReactSVGElement; text: string; title?: string }>;
  sectionTitle: string;
  faqDesc: string;
  faqLinkHref: string;
  faqLinkText: string;
  t: TTranslateFunc;
};

type StateProps = {
  showSlotUIReskin: boolean;
};

const mapStateToProps = (state: Store): StateProps => ({
  showSlotUIReskin: getShowSlotUIReskinV2(state),
});

export type Props = TProps & StateProps;

const FulfilmentStepsComponent: React.FC<Props> = (props: Props) => {
  const { deliverySteps, sectionTitle, faqDesc, faqLinkHref, faqLinkText, t: translate, showSlotUIReskin } = props;
  const theme = useTheme();
  const fulfilmentSteps = deliverySteps.map(({ graphic, customIcon, text, title }, index) => {
    return (
      <IconStamp key={index}>
        <IconContainer backgroundColor={graphic.backgroundColor({ theme })} role="presentation">
          {customIcon ? customIcon : <Icon graphic={graphic.icon} stroke={graphic.stroke({ theme })} />}
        </IconContainer>
        <div>
          {!!title && <Title>{title}</Title>}
          <Footnote>{text}</Footnote>
        </div>
      </IconStamp>
    );
  });

  const renderMoreAboutLink = (): ReactNode => (
    <StyledMoreAboutLink
      variant="standalone"
      target="_blank"
      rel="noopener"
      href={faqLinkHref}
      title={translate('common:new-window')}
    >
      <span>{faqLinkText}</span>
      <Icon graphic={FORWARD_LINK} size={XX} />
    </StyledMoreAboutLink>
  );

  const triggerAccordionAnalyticsEvent = (
    event: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>,
  ): void => {
    const isOpen = (event.target as HTMLButtonElement).ariaExpanded;

    basicEvent(analyticsBus, {
      action: NOW,
      type: ONDEMAND_DELIVERY,
      value: `${isOpen === 'true' ? ACCORDION_COLLAPSE : ACCORDION_EXPAND}:${HOW_WHOOSH_WORKS}`,
    });
  };

  return showSlotUIReskin ? (
    <StyledAccordionWrapper>
      <BasicAccordion
        behaviour="toggle"
        containerClassName="whoosh__how-it-works"
        content={[
          {
            headingLevel: 'h2',
            heading: sectionTitle,
            iconVariant: 'primary',
            id: 'whoosh-how-id-works',
            panel: (
              <StyledAccordionContent>
                <StepsContainer>{fulfilmentSteps}</StepsContainer>
                <Footnote>
                  {faqDesc}
                  {renderMoreAboutLink()}
                </Footnote>
              </StyledAccordionContent>
            ),
            handlePress: triggerAccordionAnalyticsEvent,
          },
        ]}
      />
    </StyledAccordionWrapper>
  ) : (
    <>
      <SectionMessage message={sectionTitle} size="large" />
      <FulfilmentStepsContainer>
        <StepsContainer>{fulfilmentSteps}</StepsContainer>
        <Footnote>
          {faqDesc}
          {renderMoreAboutLink()}
        </Footnote>
      </FulfilmentStepsContainer>
    </>
  );
};

export default helpers(['t'])(connect(mapStateToProps)(FulfilmentStepsComponent));
