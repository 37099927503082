import { ToggleRecsCarouselState, CarouselDetails, RecsCarouselDetails } from '#/actions/ui-action-creators.defs';
import { TOGGLE_RECS_CAROUSEL, UPDATE_RECS_CAROUSEL_DETAILS } from '#/experiments/oop-2349/constants';

/**
 * toggles the Recs carousel flag.
 */
export const toggleRecsCarouselFlag = (): ToggleRecsCarouselState => ({
  type: TOGGLE_RECS_CAROUSEL,
});

/**
 * update Recs carousel details
 */
export const updateRecsCarouselDetails = (
  recsCarouselDetails: CarouselDetails['recsCarouselDetails'],
): RecsCarouselDetails => ({
  type: UPDATE_RECS_CAROUSEL_DETAILS,
  recsCarouselDetails,
});
