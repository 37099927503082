import React, { Component, createRef } from 'react';
import { connect } from '#/lib/render/connect-deep-compare';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '@ddsweb/icon';
import Link from '#/components/link-check-spa';
import helpers from '#/lib/decorators/helpers';
import {
  getLanguageLink,
  getRedirectUrl,
  getIsTablet,
  getLanguage
} from '#/reducers/app';
import { getIsUserAuthenticated, getUserUuid } from '#/reducers/user';
import analyticsBus from '#/analytics/analyticsBus';
import { DELAY, INSPIRE_ME, NOW, TOP_NAV } from '#/analytics/constants';
import { basicEvent } from '#/analytics/types/basic';
import { getShouldUseClubcardPricesName } from '#/experiments/oop-1029/selectors';
import { GROCERIES } from '#/experiments/oop-1406/constants';
import { shouldPersistTaxonomyPosition } from '#/experiments/oop-1406/selectors';
import { getGroceriesAnalyticsLabel } from '#/experiments/oop-1406/helpers';
import { getShouldShowRecipeZonePage } from '#/experiments/oop-812/selectors';
import {
  getFavDropDownNavAnalyticsConfig,
  getIsFavWithDropdownNav
} from '#/experiments/oop-1716/helpers/helpers';
import {
  CLUBCARD_OPT_IN_CONFIG_KEY,
  JUST_LOGGED_IN_QUERYSTRING_PARAM
} from '#/constants/clubcard-details';
import { updateParamsInUrl } from '#/lib/url/url-utils';
import {
  getIsTaxonomyVariant,
  getIsZonePageVariant
} from '#/experiments/oop-1890/selectors';
import { getIsInspireMeMenuKey } from '#/experiments/oop-1890/helpers';
import {
  getFavLastStateStorageKey,
  getLastFavStateFromStorage,
  getShouldPersistFilters
} from '#/experiments/oop-1801/helpers';
import { getShouldRememberPaginationVariant } from '#/experiments/oop-1801/selectors';
import { MEALS_AND_RECIPES_NAV } from '#/experiments/oop-1946/constants';
import { getIsMealsAndRecipeMenuKey } from '#/experiments/oop-1946/helpers';
import {
  getFavLayoutFromStorage,
  getQueryStrings
} from '#/utils/favorite-utils';
import { getMovingSearchBarVariant } from '#/experiments/oop-1972/selectors';
import { getIsMovingSearchBarEnabled } from '#/experiments/oop-1972/helpers';

const FAVORITES = 'myProducts';

export const mapStateToProps = (state, ownProps) => {
  const { requiresAuthentication, url, externalUrl } = ownProps.navItem;
  const config = ownProps.c;
  const navItemUrl = url && getLanguageLink(state, url);

  const shouldShowInspireMeAsLink = getIsZonePageVariant(state);
  const shouldShowInspireMeAsTaxonomy = getIsTaxonomyVariant(state);

  let queryString = '';
  if (ownProps.navItemKey === FAVORITES) {
    if (config('enableGridInFavorites')) {
      const favLayoutFromStorage = getFavLayoutFromStorage(getUserUuid(state));
      if (favLayoutFromStorage) {
        queryString = getQueryStrings(favLayoutFromStorage);
      }
    }

    const showFavLastState = getShouldPersistFilters(
      getShouldRememberPaginationVariant(state)
    );

    const favLastStateSessionKey = getFavLastStateStorageKey(state);
    const favLastState =
      showFavLastState && getLastFavStateFromStorage(favLastStateSessionKey);
    if (favLastState) {
      queryString = updateParamsInUrl('', favLastState);
    }
  }

  if (
    ownProps.c(CLUBCARD_OPT_IN_CONFIG_KEY) &&
    typeof navItemUrl === 'string' &&
    navItemUrl.includes('/favorites') &&
    !getIsUserAuthenticated(state)
  ) {
    queryString = updateParamsInUrl(queryString, {
      [JUST_LOGGED_IN_QUERYSTRING_PARAM]: true
    });
  }

  const navUrl = requiresAuthentication
    ? getRedirectUrl(
        state,
        navItemUrl,
        getIsUserAuthenticated(state),
        queryString
      )
    : navItemUrl;

  let navExternalUrl;

  if (typeof externalUrl === 'object') {
    const language = getLanguage(state);
    navExternalUrl = externalUrl[language];
  } else if (typeof externalUrl === 'string') {
    navExternalUrl = externalUrl;
  }

  return {
    navUrl,
    isTablet: getIsTablet(state),
    showClubcardPricesName: getShouldUseClubcardPricesName(state),
    showRecipeZonePage: getShouldShowRecipeZonePage(state),
    isPersistTaxonomyEnabled: shouldPersistTaxonomyPosition(state),
    navExternalUrl,
    movingSearchBarVariant: getMovingSearchBarVariant(state),
    shouldShowInspireMeAsLink,
    shouldShowInspireMeAsTaxonomy,
    isHorizontalTaxonomyEnabled: ownProps.c('enableHorizontalTaxanomy'),
    isSearchLeftAligned: ownProps.c('isSearchLeftAligned')
  };
};

@helpers(['c', 'f', 'l', 't'])
@connect(mapStateToProps)
class NavItem extends Component {
  static propTypes = {
    active: PropTypes.bool,
    c: PropTypes.func.isRequired,
    chevron: PropTypes.bool,
    cmsNav: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    configurable: PropTypes.string,
    f: PropTypes.func.isRequired,
    feature: PropTypes.string,
    isPersistTaxonomyEnabled: PropTypes.bool.isRequired,
    isTablet: PropTypes.bool.isRequired,
    l: PropTypes.func.isRequired,
    menuTree: PropTypes.element,
    navExternalUrl: PropTypes.string,
    navItem: PropTypes.object.isRequired,
    navUrl: PropTypes.string,
    onClick: PropTypes.func,
    open: PropTypes.bool,
    openStateInLargeView: PropTypes.bool,
    preventSpa: PropTypes.bool,
    renderTooltip: PropTypes.func,
    t: PropTypes.func.isRequired,
    tooltipContainerId: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.targetRef = createRef();
    this.boundingRef = createRef();
  }

  setRef = ele => {
    this.targetRef.current = ele;
  };

  focusLink = () => {
    ReactDOM.findDOMNode(this.linkEl).focus();
  };

  saveLinkRef = el => {
    this.linkEl = el;
  };

  isRecipeLink = label => {
    const RECIPES = 'recipes';
    return label === RECIPES && this.props.showRecipeZonePage;
  };

  isGroceriesLink = label => {
    return label === GROCERIES;
  };

  onClickFactory = label => event => {
    let action = NOW,
      type = TOP_NAV;

    const {
      navExternalUrl,
      isPersistTaxonomyEnabled,
      navItemKey,
      open,
      shouldShowInspireMeAsLink,
      shouldShowInspireMeAsTaxonomy
    } = this.props;

    if (!navExternalUrl || this.isRecipeLink(label)) {
      this.props.onClick(event);
      action = DELAY;
    }

    if (this.isGroceriesLink(label)) {
      ({ label, action } = getGroceriesAnalyticsLabel(
        label,
        isPersistTaxonomyEnabled
      ));
    }

    if (getIsFavWithDropdownNav(navItemKey)) {
      ({ type, label, action } = getFavDropDownNavAnalyticsConfig(open));
    }
    const isInspireMeMenuVariants =
      getIsInspireMeMenuKey(label) &&
      (shouldShowInspireMeAsLink || shouldShowInspireMeAsTaxonomy);
    if (isInspireMeMenuVariants) {
      label = INSPIRE_ME;
      action = shouldShowInspireMeAsLink ? DELAY : NOW;
    }

    if (getIsMealsAndRecipeMenuKey(label)) {
      action = NOW;
      label = MEALS_AND_RECIPES_NAV;
    }

    basicEvent(analyticsBus, { type, value: label, action });
  };

  render() {
    const {
      cmsNav,
      navItem,
      l: languageLink,
      navUrl,
      active,
      open,
      menuTree: menuTreeProp,
      openStateInLargeView,
      showClubcardPricesName,
      t: translate,
      c: config,
      f: featureFun,
      configurable,
      feature,
      isTablet,
      preventSpa,
      chevron,
      renderTooltip,
      tooltipContainerId,
      navExternalUrl,
      onKeyDown,
      navItemKey,
      movingSearchBarVariant,
      isHorizontalTaxonomyEnabled,
      isSearchLeftAligned
    } = this.props;

    const menuTreeOpen = open && menuTreeProp;
    const isFavMenuNav = getIsFavWithDropdownNav(navItemKey);
    const activeRoute = isFavMenuNav ? !open && active : active;

    const isEnableMovingSearchBar = getIsMovingSearchBarEnabled(
      movingSearchBarVariant
    );

    const cmsUrl =
      navItem.cmsNav && cmsNav && cmsNav.link && languageLink(cmsNav.link);
    const url = cmsUrl || navUrl;
    const classes = classnames({
      'nav-item__link nav-item__link--right-aligned': true,
      'nav-item__link nav-item__link--moving-search-bar':
        isEnableMovingSearchBar || isSearchLeftAligned,
      'nav-item__link--active': activeRoute,
      'nav-item__link--open': menuTreeOpen,
      'main-level-nav-item__link--open': openStateInLargeView,
      horizontal__taxonomy: isHorizontalTaxonomyEnabled
    });
    const cmsTabName = navItem.cmsNav && cmsNav && cmsNav.tabName;
    const cmsDataReceived =
      !navItem.cmsNav || (cmsTabName && cmsTabName.length);
    // Hardcoding the label for the oop-1029 experiment
    // This should move into web/locales/en/navigation.json when productionising
    const label =
      (navItem.cmsNav && cmsTabName) ||
      (navItem.label === 'promotions' && showClubcardPricesName
        ? 'Clubcard Prices'
        : translate(`navigation:${navItem.label}`));
    const keyShort = `navigation:${navItem.label}-short`;
    const labelShort =
      (navItem.cmsNav && cmsTabName) ||
      (translate(keyShort) !== keyShort ? translate(keyShort) : label);
    const configured = !configurable || config(configurable);
    const featureOn = !feature || featureFun(feature);
    // Specific check for the oop-1029 experiment. Should remove when productionising
    const navItemLabel = isTablet
      ? navItem.label === 'promotions' && showClubcardPricesName
        ? label
        : labelShort
      : label;
    const menuTree = menuTreeProp
      ? React.cloneElement(menuTreeProp, {
          focusParent: this.focusLink
        })
      : null;

    if (configured && featureOn && cmsDataReceived) {
      const externalUrl = !this.isRecipeLink(navItem.label) && navExternalUrl;
      return (
        <li
          className={classnames('nav-item', {
            'nav-item--open': menuTreeOpen,
            'nav-item--fav-open': menuTreeOpen && isFavMenuNav
          })}
          id={navItemKey}
          role="none"
          ref={this.boundingRef}
          {...(tooltipContainerId && { id: tooltipContainerId })}
        >
          <Link
            ref={this.saveLinkRef}
            handleUpdate={this.saveLinkRef}
            className={classes}
            data-auto="nav-item-link"
            to={externalUrl || url}
            onClick={this.onClickFactory(navItem.label)}
            onKeyDown={onKeyDown}
            preventSpa={preventSpa}
            target={(externalUrl && '_blank') || undefined}
            role="menuitem"
            domRef={this.setRef}
          >
            {navItemLabel}
            {chevron && <Icon graphic="expand" size="sm" />}
            {renderTooltip?.(this.targetRef, this.boundingRef)}
          </Link>
          {(open || openStateInLargeView) && menuTree}
        </li>
      );
    } else {
      return null;
    }
  }
}

export default NavItem;
