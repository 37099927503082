import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { logApmError } from '#/lib/apm';

import { StyledDfpBanner, StyledBasketDfpBanner } from './styled';

let pubadsService;

function isConsoleParamSet() {
  const searchParams = window.location.search.substr(1).split('&');
  const googleConsoleParams = [
    'google_console',
    'google_force_console',
    'googfc'
  ];

  return !!searchParams.reduce(
    (enabled, param) => enabled + googleConsoleParams.indexOf(param) + 1,
    0
  );
}

function isConsoleCookieSet() {
  const cookies = document.cookie.split('; ').map(cookie => cookie.split('='));
  const consoleCookie = cookies.find(
    cookie => cookie[0] === 'google_pubconsole'
  );

  return !!consoleCookie && consoleCookie[1].charAt(0) === '1';
}

function isConsoleEnabled() {
  try {
    return isConsoleParamSet() || isConsoleCookieSet();
  } catch (err) {
    logApmError(
      new Error(
        `Failed to check if the DFP console is enabled: ${err.message}`
      ),
      {
        stack: err.stack
      }
    );

    return false;
  }
}

function getGPTApiStatus() {
  const { googletag } = window;
  if (!googletag) {
    return 'Google publisher tag not loaded';
  }

  const tagProperties = JSON.stringify(Object.getOwnPropertyNames(googletag));

  if (googletag.apiReady) {
    return `Google publisher tag loaded: ${tagProperties}`;
  }

  return `Fallback GPT: ${tagProperties}`;
}

export default class DfpBannerSlot extends Component {
  static propTypes = {
    adUnitPath: PropTypes.string.isRequired,
    className: PropTypes.string,
    id: PropTypes.string.isRequired,
    isAdvertisingCookieAccepted: PropTypes.bool,
    language: PropTypes.string,
    onRender: PropTypes.func,
    ppid: PropTypes.string,
    seed: PropTypes.any,
    sizeMapping: PropTypes.arrayOf(PropTypes.array),
    sizes: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.arrayOf(PropTypes.number)
        ])
      )
    ]).isRequired,
    targeting: PropTypes.object,
    showFullBasketBanner: PropTypes.bool,
    enableLazyLoad: PropTypes.bool
  };

  static defaultProps = {
    targeting: {}
  };

  constructor(props) {
    super(props);

    this.initPubadsService = this.initPubadsService.bind(this);
    this.initSlot = this.initSlot.bind(this);
  }

  componentDidMount() {
    this.isLive = true;
    this.initPubadsService(this.initSlot);
  }

  componentWillUnmount() {
    this.isLive = false;

    try {
      const slot = this.slot;

      if (slot) {
        googletag.destroySlots([slot]);
      }
    } catch (err) {
      logApmError(new Error(`Failed to destroy the DFP slot: ${err.message}`), {
        googleTagStatus: getGPTApiStatus(),
        stack: err.stack
      });
    }
  }

  shouldComponentUpdate({ seed }) {
    const { seed: prevSeed } = this.props;

    if (seed !== prevSeed) {
      this.refresh();
    }

    // The rendered component should remain unchanged
    // GTM will handle the update of the slot content
    return false;
  }

  initPubadsService(cb) {
    const {
      ppid,
      language,
      isAdvertisingCookieAccepted,
      enableLazyLoad
    } = this.props;

    try {
      window.googletag = window.googletag || { cmd: [] };

      googletag.cmd.push(() => {
        if (!this.isLive) {
          return;
        } else if (pubadsService) {
          if (typeof cb === 'function') {
            cb(pubadsService);
          }

          return;
        }

        pubadsService = pubadsService || googletag.pubads();
        pubadsService.collapseEmptyDivs();

        pubadsService.setPrivacySettings({
          limitedAds: !isAdvertisingCookieAccepted
        });

        pubadsService.setTargeting('language', language);

        if (enableLazyLoad) {
          pubadsService.enableLazyLoad({
            // Fetch slots within 1 viewport.
            fetchMarginPercent: 100,
            // Render slots within same viewport.
            renderMarginPercent: 0,
            // Double the above values on mobile, where viewports are smaller
            // and users tend to scroll faster.
            mobileScaling: 2.0
          });
        }

        if (ppid) {
          pubadsService.setPublisherProvidedId(ppid);
        }

        googletag.enableServices();

        if (typeof cb === 'function') {
          cb(pubadsService);
        }

        if (isConsoleEnabled()) {
          googletag.openConsole();
        }
      });
    } catch (err) {
      logApmError(
        new Error(
          `Failed to initialize the Google Pubads service: ${err.message}`
        ),
        {
          googleTagStatus: getGPTApiStatus(),
          stack: err.stack
        }
      );
    }
  }

  initSlot(pubAds) {
    let slot;

    try {
      if (!this.isLive) {
        return;
      }

      const {
        adUnitPath,
        id,
        onRender,
        sizeMapping,
        sizes,
        targeting
      } = this.props;

      const googletag = window.googletag;
      slot = googletag.defineSlot?.(adUnitPath, sizes, id);

      if (!slot) {
        return;
      }

      slot.addService?.(pubAds);

      slot.updateTargetingFromMap?.(targeting);

      if (Array.isArray(sizeMapping)) {
        const mapping = googletag.sizeMapping?.();
        if (mapping) {
          sizeMapping.forEach(([viewportSize, slotSize]) => {
            mapping.addSize?.(viewportSize, slotSize);
          });

          mapping.build?.();
          slot.defineSizeMapping?.(mapping);
        }
      }

      googletag.display?.(slot);

      if (typeof onRender === 'function') {
        onRender();
      }

      this.slot = slot;
    } catch (err) {
      let slotProperties;

      if (slot) {
        slotProperties = Object.getOwnPropertyNames(slot);
      }

      logApmError(
        new Error(`Failed to initialize the DFP slot: ${err.message}`),
        {
          googleTagStatus: getGPTApiStatus(),
          slotProperties,
          stack: err.stack
        }
      );
    }
  }

  refresh() {
    try {
      const slot = this.slot;

      if (slot && this.isLive) {
        pubadsService.refresh([slot]);
      }
    } catch (err) {
      logApmError(new Error(`Failed to refresh the DFP slot: ${err.message}`), {
        googleTagStatus: getGPTApiStatus(),
        stack: err.stack
      });
    }
  }

  render() {
    const { className, id, showFullBasketBanner } = this.props;
    return showFullBasketBanner ? (
      <StyledBasketDfpBanner
        className={classnames('dfp-banner', className)}
        id={id}
      />
    ) : (
      <StyledDfpBanner
        className={classnames('dfp-banner', className)}
        id={id}
      />
    );
  }
}
