import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from '#/lib/render/connect-deep-compare';
import helpers from '#/lib/decorators/helpers';
import { removeItem } from '#/actions/trolley/trolley-action-creators';
import TrolleyForm from '#/components/products/trolley-form';
import RemoveItemButton from '#/components/buttons/remove-item';
import formatNumber from '#/lib/string-formatting/number-formatter';
import { getIsUserRegistered } from '#/reducers/user';
import {
  getProductTitle,
  getProductUnitOfMeasure,
  getUnit,
  getQuantity
} from '#/selectors/item';
import { setNumberOfItems } from '#/lib/records/item-utils';

import RemoveItem from '../remove-item';

function mapStateToProps(state) {
  return {
    userRegistered: getIsUserRegistered(state)
  };
}

@helpers(['t'])
@connect(mapStateToProps, { removeItem })
export default class RenderRemoveItem extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    placement: PropTypes.oneOf(['absolute', 'relative']),
    removeActionModal: PropTypes.string,
    removeActionType: PropTypes.string,
    removeFromList: PropTypes.bool,
    removeItem: PropTypes.func.isRequired,
    t: PropTypes.func,
    userRegistered: PropTypes.bool.isRequired
  };

  static defaultProps = {
    placement: 'absolute'
  };

  removeItem = e => {
    e.preventDefault();
    this.props.removeItem(this.props.item);
  };

  render() {
    const {
      removeFromList,
      item,
      removeActionModal,
      userRegistered,
      placement
    } = this.props;
    const quantity =
      item.customerUnitChoice === 'pcs'
        ? Math.ceil(getQuantity(item))
        : formatNumber(getQuantity(item), { decimalPlaces: 2 }) +
          getProductUnitOfMeasure(item);

    if (removeFromList) {
      return <RemoveItem item={item} removeActionModal={removeActionModal} />;
    }

    return (
      <div className="product-tile--delete-container">
        <TrolleyForm
          item={item}
          newItem={setNumberOfItems(item, 0)}
          userRegistered={userRegistered}
          oldItemQuantity={getQuantity(item)}
          oldItemUnitChoice={getUnit(item)}
        >
          <input name="newUnitChoice" type="hidden" value={getUnit(item)} />
          <StyledRemoveItemButton
            className="product-tile--delete"
            placement={placement}
            onClick={this.removeItem}
            name="newValue"
            type="submit"
            textKey="product-tile:remove-from-trolley"
            textProps={{
              title: getProductTitle(this.props.item),
              quantity: quantity
            }}
          />
        </TrolleyForm>
      </div>
    );
  }
}

const StyledRemoveItemButton = styled(RemoveItemButton)`
  position: ${p => p.placement};
`;
StyledRemoveItemButton.displayName = 'StyledRemoveItemButton';
