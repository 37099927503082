import { AddressesState } from '#/custom-typings/redux-store/addresses.defs';
import { AddressData } from '#/reducers/addresses';

export function getAddresses(state): AddressesState {
  return state.addresses;
}

export function getDefaultAddress(state): AddressData | undefined {
  return getAddresses(state)?.defaultAddress;
}

export function getAllAddresses(state): AddressData[] | undefined {
  return getAddresses(state)?.allAddresses;
}

export function getBillingAddress(state): AddressData | undefined {
  const defaultAddress = getDefaultAddress(state);

  if (defaultAddress && defaultAddress.isBillingAddress) {
    return defaultAddress;
  }

  const addresses: AddressData[] = getAllAddresses(state) || [];

  return (
    addresses.find(function(address) {
      return address.isBillingAddress;
    }) || defaultAddress
  );
}

export function getAddressById(state, addressId: string): AddressData | undefined {
  const defaultAddress = getDefaultAddress(state);

  if (defaultAddress && defaultAddress.id === addressId) {
    return defaultAddress;
  }

  const addresses: AddressData[] = getAllAddresses(state) || [];

  return addresses.find(function(address) {
    return address.id === addressId;
  });
}

export function getPrimaryPhone(state) {
  const defaultAddress = getDefaultAddress(state);

  return defaultAddress?.primaryPhone;
}

export function getSecondaryPhone(state) {
  const defaultAddress = getDefaultAddress(state);

  return defaultAddress?.secondaryPhone;
}

export function getDefaultDeliveryAddressLines(state) {
  const defaultAddress = getDefaultAddress(state);
  let address = '',
    lineNo = 1;
  for (const key in defaultAddress) {
    if (key === `addressLine${lineNo}` && defaultAddress[key]) {
      address += `${defaultAddress[key]}\n`;
      lineNo++;
    }
  }
  return address;
}
