import { FAVOURITES } from '#/constants/facet-categories';
import NotFoundError from '#/lib/errors/not-found';
import { incrementIsoTimestamp } from '#/lib/records/product-utils';

export function getResourceData(resources, resourceName) {
  const resource = resources && resources[resourceName];

  return resource && resource.data;
}

export function errorOnInvalidQuery(query, config) {
  if (!query.page) {
    return;
  }

  const page = parseInt(query.page, 10);

  if (page < 1 || page > config('productListing:maxPageNumber')) {
    throw new NotFoundError();
  }
}

function isAcceptedPageWithZeroResults(facetList) {
  return facetList.some(facet => {
    return (
      facet.category.toLowerCase() === FAVOURITES.toLowerCase() &&
      facet.facets[0].isSelected &&
      !facet.facets[0].binCount
    );
  });
}

export function errorOnInvalidPageNo(result) {
  const pageCount = result.pageInformation.count;
  const totalCount = result.pageInformation.totalCount;
  const facetList = result.facetLists;

  if ((totalCount > 0 && !pageCount) || pageCount < 0) {
    if (!isAcceptedPageWithZeroResults(facetList)) {
      throw new NotFoundError();
    }
  }
}

export const getCanonicalUrl = req =>
  `${req.protocol}://${req.hostname}${req.originalUrl.split('?').shift()}`;

export const incrementPromotionsEndDates = productItems => {
  productItems.forEach(productItem => {
    const originalPromotions = productItem.promotions;
    originalPromotions?.forEach(promotion => {
      promotion.endDate = incrementIsoTimestamp(
        promotion.endDate,
        1,
        'seconds'
      );
    });
  });
};
