import { TTranslateFunc } from '#/lib/records/helpers.defs';
import { Item } from '#/lib/records/item';

const variationKey = 'previouslyBought-gridView';

export const FEATURE_KEY = 'GHS-UK_previouslyBought-gridView';

export enum variations {
  default = 'default',
  bannersNosubsTooltip1 = 'banners-nosubs-tooltip1',
  bannersSubsTooltip1 = 'banners-subs-tooltip1',
  bannersSubsTooltip2 = 'banners-subs-tooltip2',
}

export const gridViewWithSubsAndBannersVariants = {
  key: 'oop2011',
  featureKey: FEATURE_KEY,
  variationKey,
  serviceConfig: {
    params: {
      [variationKey]: variations.default,
    },
  },
  useCustomerId: true,
};

export const promoTypeSubstitutions = {
  substitutions: 'Substitutions',
};

export const FAV_LAYOUT_TOOLTIP_OPTION_1 = 'favorites:oop-2011.tooltip-option-1';
export const FAV_LAYOUT_TOOLTIP_OPTION_2 = 'favorites:oop-2011.tooltip-option-2';

export type updateSashListParams = {
  item: Item;
  translate: TTranslateFunc;
};
