import filterResourcesByStateAndProps from '#/conditional-resources/helpers/filter-resources-by-state-and-props';
import getResourcesFromRoutes from '#/conditional-resources/helpers/get-resources-from-routes';
import validateResources from '#/conditional-resources/helpers/validate-resources';
import validateFilteredResources from '#/conditional-resources/helpers/validate-filtered-resources';

export default function getRelevantResourcesFromRoutes(state, props) {
  return validateFilteredResources(
    filterResourcesByStateAndProps(
      validateResources(getResourcesFromRoutes(props.routeProps.routes)),
      state,
      props
    )
  );
}

export const getFilterRelevantResourcesFromRoutes = (state, routes) => {
  return getRelevantResourcesFromRoutes(state, {
    routeProps: {
      routes
    }
  });
};
