import { parse, format } from 'url';

export function getIsRegistrationUrl(url: string) {
  return url.includes('register');
}

export function removeQueryParams(url: string, params: Array<string>) {
  const parsedUrl = parse(url, true);

  params.forEach(param => {
    delete parsedUrl.query[param];
  });

  return format({ ...parsedUrl, search: null });
}
