import React from 'react';
import PropTypes from 'prop-types';
import helpers from '#/lib/decorators/helpers';
import { connect } from '#/lib/render/connect-deep-compare';
import formatNumber from '#/lib/string-formatting/number-formatter';
import SafelyOutputString from '#/components/safely-output-string';
import {
  getDeliveryThresholds,
  getTrolleyShoppingMethod
} from '#/selectors/trolley';
import {
  QUALIFY_FOR_FREE_DELIVERY,
  SPEND_MORE_FOR_FREE_DELIVERY,
  NOT_QUALIFY_FOR_FREE_DELIVERY
} from '#/constants/spend-threshold';

const SpendThresholdWarning = props => {
  const getMessage = () => {
    const {
      c: config,
      t: translate,
      status,
      deliveryThresholds,
      shoppingMethod
    } = props;
    const currencyConfig = config('currency');
    const options = {
      decimalMark: currencyConfig.decimalMark,
      decimalPlaces: currencyConfig.decimalPlaces,
      thousandsSeparator: currencyConfig.thousandsSeparator,
      symbol: currencyConfig.symbol,
      showAsciiSymbol: true,
      compactMode: !currencyConfig.spaceBetweenSymbolAndValue,
      symbolPosition: currencyConfig.symbolPosition
    };
    const { deliveryThreshold, deliveryMessageThreshold } = deliveryThresholds;
    let msg;
    switch (status) {
      case SPEND_MORE_FOR_FREE_DELIVERY:
        msg = translate(
          `trolley:warnings.spend-threshold.${shoppingMethod}.spend-more-to-qualify`,
          { value: formatNumber(deliveryMessageThreshold, options) }
        );
        break;
      case QUALIFY_FOR_FREE_DELIVERY:
        msg = translate(
          `trolley:warnings.spend-threshold.${shoppingMethod}.qualify-for-free`
        );
        break;
      case NOT_QUALIFY_FOR_FREE_DELIVERY:
        msg = translate(
          `trolley:warnings.spend-threshold.${shoppingMethod}.do-not-qualify`,
          { value: formatNumber(deliveryThreshold, options) }
        );
        break;
      default:
        msg = null;
    }
    return msg;
  };

  const { status } = props;
  const message = getMessage();
  const bgClass = status === QUALIFY_FOR_FREE_DELIVERY ? 'success' : 'warning';

  return message ? (
    <div className={`spend-threshold ${bgClass}`}>
      <span>
        <SafelyOutputString>{message}</SafelyOutputString>
      </span>
    </div>
  ) : (
    ''
  );
};

SpendThresholdWarning.propTypes = {
  c: PropTypes.func.isRequired,
  deliveryThresholds: PropTypes.object.isRequired,
  shoppingMethod: PropTypes.string.isRequired,
  status: PropTypes.oneOf([
    QUALIFY_FOR_FREE_DELIVERY,
    SPEND_MORE_FOR_FREE_DELIVERY,
    NOT_QUALIFY_FOR_FREE_DELIVERY,
    false
  ]),
  t: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  deliveryThresholds: getDeliveryThresholds(state),
  shoppingMethod: getTrolleyShoppingMethod(state)
});

export default connect(mapStateToProps)(
  helpers(['c', 't'])(SpendThresholdWarning)
);
