import styled from 'styled-components';
import { fontSize, spacing, colors } from '@ddsweb/selectors';
import Heading from '@ddsweb/heading';
import Icon from '@ddsweb/icon';
import { Text } from '@ddsweb/text';

export const StyledTitle = styled(Heading)`
  margin-bottom: ${spacing.sm};
`;

export const StyledBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${spacing.sm};
`;

export const StyledBodyTextRow = styled.div`
  display: flex;
  margin-top: ${spacing.xs};
  margin-bottom: ${spacing.xs};
`;

export const StyledBodyText = styled(Text)`
  font-size: ${fontSize.xs};
  margin-left: ${spacing.xs};
`;

export const StyledIcon = styled(Icon)`
  padding: ${spacing.xs} 0 ${spacing.md};
  margin-right: ${spacing.md};
  color: ${colors.tescoBlue};
`;
