import { getResourceData } from '#/resources/resource-util';
import { RESOURCES_RECEIVED } from './resources';
import { BuyListState, Categories, Recipe, BuyListGroup, BuyList } from '#/custom-typings/redux-store/buylist.defs';

type BuyListResources = {
  buylist?: {
    data: BuyListGroup;
  };
  buylistById?: {
    data: BuyList;
  };
};

interface BuyListAction {
  resources: BuyListResources;
  type: string;
}

export default (state: BuyListState = {}, action: BuyListAction): BuyListState => {
  switch (action.type) {
    case RESOURCES_RECEIVED:
      if (action.resources) {
        if (action.resources.buylistById) {
          return {
            ...state,
            ...receiveBuylistResource(action.resources.buylistById.data),
          };
        } else if (action.resources.buylist) {
          return {
            ...state,
            ...receiveBuylistGroupResource(action.resources.buylist.data),
          };
        }
      }
      return state;
    default:
      return state;
  }
};

const receiveBuylistResource = (
  buylist: BuyList,
): {
  buylistById: {
    buylistName: string;
  };
} => ({
  buylistById: {
    buylistName: buylist.name || '',
  },
});

const receiveBuylistGroupResource = (
  buylistGroup: BuyListGroup,
): {
  buylistGroup: BuyListGroup;
} => ({
  buylistGroup: {
    buylists: buylistGroup.buylists || [],
    categories: buylistGroup.categories || [],
    name: buylistGroup.name || '',
    recipe: buylistGroup.recipe || null,
    title: buylistGroup.title || '',
  },
});

type BuyListStore = {
  resources: BuyListResources;
};

export const getDefaultStateFromProps = (props: BuyListStore): BuyListState => {
  const buylistByIdResource = getResourceData(props.resources, 'buylistById') || {};
  const buylistGroupResource = getResourceData(props.resources, 'buylist') || {};

  const { buylistName = '' } = buylistByIdResource;
  const { buylists = [], categories = [], name = '', recipe = null, title = '' } = buylistGroupResource;

  return {
    buylistById: {
      buylistName,
    },
    buylistGroup: {
      buylists,
      categories,
      name,
      recipe,
      title,
    },
  };
};

export const getBuylistName = ({
  buylist: {
    buylistById: { buylistName },
  },
}: {
  buylist: {
    buylistById: {
      buylistName: string;
    };
  };
}): string => buylistName;

export const getBuylistGroup = ({
  buylist: {
    buylistGroup: { buylists },
  },
}: {
  buylist: {
    buylistGroup: {
      buylists: BuyList[];
    };
  };
}): BuyList[] => buylists;

export const getBuylistGroupCategories = ({
  buylist: {
    buylistGroup: { categories },
  },
}: {
  buylist: {
    buylistGroup: {
      categories: Categories[];
    };
  };
}): Categories[] => categories;

export const getBuylistGroupName = ({
  buylist: {
    buylistGroup: { name },
  },
}: {
  buylist: {
    buylistGroup: {
      name: string;
    };
  };
}): string => name;

export const getBuylistGroupRecipe = ({
  buylist: {
    buylistGroup: { recipe },
  },
}: {
  buylist: {
    buylistGroup: {
      recipe: Recipe;
    };
  };
}): Recipe => recipe;

export const getBuylistGroupTitle = ({
  buylist: {
    buylistGroup: { title },
  },
}: {
  buylist: {
    buylistGroup: {
      title: string;
    };
  };
}): string => title;
