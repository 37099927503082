import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Spinner from '../../../../../shared/spinner';

const PickerNotesProgress = ({
  count,
  error,
  limit,
  maxReached,
  saved,
  saving,
  status
}) => (
  <div>
    <span className="tile-content--notes-word-count">
      {`${count}/${limit}`}
    </span>
    <div
      className={classnames(
        'tile-content--note-show-overflow',
        'picker-status',
        'instructions-status',
        status !== 'idle' && 'open',
        status
      )}
    >
      {count === limit && (
        <span className="tile-content--notes-word-count-limit">
          {maxReached}
        </span>
      )}
      <div className="message saved-message">
        <span className="icon icon-saved" />
        <span className="icon-label">{saved}</span>
      </div>
      <div className="message saving-message">
        <Spinner />
        <span className="icon-label">{saving}</span>
      </div>
      <div className="message error-message">
        <span className="icon icon-error-small" />
        <span className="icon-label">{error}</span>
      </div>
    </div>
  </div>
);

PickerNotesProgress.propTypes = {
  count: PropTypes.number.isRequired,
  error: PropTypes.string.isRequired,
  limit: PropTypes.number.isRequired,
  maxReached: PropTypes.string.isRequired,
  saved: PropTypes.string.isRequired,
  saving: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired
};

export default PickerNotesProgress;
