import { getFeatureVariableStringFromState } from '#/lib/optimizely-manager';
import { specialOffersRedesignVariants } from '../constants';

const {
  featureKey,
  variationKey,
  useCustomerId,
  variants: { default: defaultVariant, specialOffersRedesign },
} = specialOffersRedesignVariants;

const getSpecialOffersRedesignVariant = (state: Store): string => {
  const variant = getFeatureVariableStringFromState(state, featureKey, variationKey, { useCustomerId });
  return variant ?? defaultVariant;
};

export const getIsSpecialOffersRedesignVariant = (state: Store): boolean =>
  getSpecialOffersRedesignVariant(state) === specialOffersRedesign;
