import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const InfoMessage = props => {
  const messages = props.messages || [props.message];

  return (
    <div
      data-auto="info-message"
      className={classNames('product-info-message', props.backgroundColorClass)}
    >
      {messages.map((message, index) => (
        <p
          key={index}
          className={classNames({
            'top-spacer': index
          })}
        >
          {message}
        </p>
      ))}
    </div>
  );
};

InfoMessage.propTypes = {
  backgroundColorClass: PropTypes.string,
  message: PropTypes.node,
  messages: PropTypes.arrayOf(PropTypes.string)
};

export default InfoMessage;
