import { DESKTOP, MOBILE, TABLET } from '../../constants/platforms';

export default deviceCharacteristics => {
  const deviceInfo = parseHeader(deviceCharacteristics);

  return {
    browserType: deviceInfo.mobile_browser,
    deviceType: getDeviceType(deviceInfo),
    operatingSystem: deviceInfo.device_os
  };
};

const getDeviceType = deviceInfo => {
  if (deviceInfo.is_tablet === 'true') {
    return TABLET;
  } else if (deviceInfo.is_mobile === 'true') {
    return MOBILE;
  }

  return DESKTOP;
};

const parseHeader = header =>
  header.split(';').reduce((obj, keyValue) => {
    const keyValuePair = keyValue.split('=');

    obj[keyValuePair[0].trim()] = keyValuePair[1];

    return obj;
  }, {});

export const getBrowserFamily = (browserType, c) => {
  let browserMap = c('browserMap');

  browserMap = Object.assign(browserMap.akamai, browserMap.npm);

  return browserMap[browserType];
};
