import React from 'react';
import Icon from '#/components/shared/icon';
import Link from '#/components/link-check-spa';
import helpers from '#/lib/decorators/helpers';

const handleFooterClick = link => event => {
  const {
    currentTarget: { href, target, text }
  } = event;
  const { isExternal } = link;

  if (!target && isExternal) {
    event.preventDefault();
    window.open(href, text, 'noreferrer');
  }
};

const FooterLink = function({ link, t: translate }) {
  const {
    alignIcon,
    href,
    icon,
    isExternal,
    target,
    text,
    translatedText
  } = link;

  const linkIcon = <Icon className="footer-link__icon" name={icon} />;

  const helpTranslatedText = (
    <span>
      <span aria-hidden="true">{translatedText}</span>
      <span className="visually-hidden">
        {translate('layout:main-nav.help')}
      </span>
    </span>
  );

  const linkTranslatedText =
    text === 'footer:help' ? helpTranslatedText : translatedText;

  return (
    <Link
      className="footer-link"
      to={href}
      title={isExternal ? translate('common:new-window') : null}
      onClick={handleFooterClick(link)}
      target={target}
      rel="noreferrer"
    >
      {icon && alignIcon !== 'right' && linkIcon}
      <span className="footer-link__text">{linkTranslatedText}</span>
      {icon && alignIcon === 'right' && linkIcon}
    </Link>
  );
};

export default helpers(['t'])(FooterLink);
