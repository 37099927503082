import { Items } from '#/custom-typings/redux-store/trolley.defs';
import { Item } from '#/lib/records/item';
import { getById } from '#/lib/records/product-utils';
import { getProductId, getQuantity } from '#/selectors/item';

export default function diffItems(baseItems: Items | Array<Item>, items: Items | Array<Item>): Items {
  const filteredItems = new Map();

  baseItems.forEach((baseItem: Item) => {
    const id = getProductId(baseItem);
    const item = getById(items, id);

    if (!(!!item && getQuantity(baseItem) === getQuantity(item))) {
      filteredItems.set(id, baseItem);
    }
  });

  return filteredItems;
}
