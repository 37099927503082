function getTemplateValueLength(text, key, value, includeDecimal = false) {
  const containsValue = text.includes(key);
  if (!containsValue || !value) {
    return 0;
  }

  if (includeDecimal) {
    return value.toString().length;
  }

  const valueLength = value.toString().length;
  const charLength = value == Math.floor(value) ? valueLength : valueLength - 1;
  return charLength;
}

export function getDynamicFontSize(text, sizes, value1, value2, includeDecimal = false) {
  const value1Length = getTemplateValueLength(text, '%{value1}', value1, includeDecimal);
  const value2Length = getTemplateValueLength(text, '%{value2}', value2, includeDecimal);

  const valueLength = value1Length + value2Length;

  return sizes[valueLength - 1];
}
