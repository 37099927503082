import { Product } from '#/lib/records/item.defs';
import { Item } from '#/lib/records/item';

export default function transformSubstitutions(product: Product): Array<Item> {
  let substitutions = [] as Array<Item>;
  const productSubs = product.substitutions as Array<Item>;

  if (productSubs) {
    substitutions = ((productSubs || []).map(sub => {
      const { price, promotions, ...product } = sub;

      return {
        promotions,
        product: {
          ...product,
          ...price,
        },
      };
    }) as unknown) as Array<Item>;
  }

  return substitutions;
}
