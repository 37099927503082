import { getPrice, getProductAverageWeight, getProductBulkBuyLimit } from '#/selectors/item';
import { WEIGHT_UNIT_KG } from '#/constants/common';
import { getCurrency } from '#/reducers/app';
import { round, gaussianRound } from '#/lib/string-formatting/format';
import formatNumber from '#/lib/string-formatting/number-formatter';
import { Item } from '#/lib/records/item';
import { TCurrency } from '#/custom-typings/redux-store/app.defs';

const DECIMAL_PLACES = 2;

export type TWeightOptionsData = {
  text: string;
  selectedText: string;
  value: number;
};

export type TTogglePriceConfig = {
  currency: TCurrency;
  showAsciiSymbol: boolean;
};

const formatWeightOptions = (
  price: number,
  averageWeight: number,
  max: number,
  priceConfig: TTogglePriceConfig,
): TWeightOptionsData[] => {
  let displayQty: number, formattedPrice: number, displayLabel: string;
  const options: TWeightOptionsData[] = [];
  for (let i = 1; i <= max; i++) {
    displayQty = round(i * averageWeight, 2);
    formattedPrice = formatNumber(gaussianRound(i * price, DECIMAL_PLACES), priceConfig);
    displayLabel = `${displayQty}${WEIGHT_UNIT_KG} / ${formattedPrice}`;
    options.push({
      text: displayQty + WEIGHT_UNIT_KG,
      selectedText: displayLabel,
      value: i,
    });
  }
  return options;
};

export const getWeightOptions = (state: Store, item: Item): TWeightOptionsData[] => {
  const price = getPrice(item);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const averageWeight = getProductAverageWeight(item)!;
  const max = getProductBulkBuyLimit(item);

  const priceConfig: TTogglePriceConfig = {
    ...getCurrency(state),
    showAsciiSymbol: true,
  };

  return formatWeightOptions(price, averageWeight, max, priceConfig);
};
