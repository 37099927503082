import React from 'react';
import Spinner from '@beans/spinner';
import helpers from '#/lib/decorators/helpers';
import { TTranslateFunc, TConfigFunc } from '#/lib/records/helpers.defs';
import SafeForm from '#/components/shared/safe-form';
import formatPrice from '#/lib/string-formatting/number-formatter';
import { StyledButton } from './slot-recommender-tile-styled';

export type AvailableSlotProps = {
  onKeyDown: () => void;
  onSubmit: (e: Event) => void;
  c: TConfigFunc;
  t: TTranslateFunc;
  isSlotLoading: boolean;
  slotPrice: number;
};

const SlotRecommenderTileAvailableSlotButton = (props: AvailableSlotProps): JSX.Element => {
  const { t: translate, c: config, onKeyDown, onSubmit, slotPrice, isSlotLoading } = props;
  let buttonText = `${translate('slots:common.slot-recommendations.book')}`;

  if (slotPrice || slotPrice === 0) {
    const currency = config('currency');
    const formattedPrice = formatPrice(slotPrice, { ...(currency as object), showAsciiSymbol: true });
    buttonText = `${translate('slots:common.slot-recommendations.book-for')} ${formattedPrice}`;
  }

  return (
    <SafeForm onSubmit={onSubmit} onKeyDown={onKeyDown} isSlotLoading={isSlotLoading}>
      <StyledButton block={true} variant="secondary" type="submit">
        {isSlotLoading ? <Spinner /> : buttonText}
      </StyledButton>
    </SafeForm>
  );
};

export default helpers(['t', 'c'])(SlotRecommenderTileAvailableSlotButton);
