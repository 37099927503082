import styled from 'styled-components';
import Button from '@ddsweb/button';
import { GREEN, TESCO_MODERN_FONT_FAMILY } from '@ddsweb/constants';
import { media } from '@ddsweb/foundation';
import { spacing, colors, fontSize } from '@ddsweb/selectors';
import { Footnote, BodyText } from '@ddsweb/typography';
import Link from '#/components/link-check-spa';
import { InPageMessaging } from '@ddsweb/messaging';

const StyledButton = styled(Button)`
  && {
    width: 100%;
    ${media.aboveTablet`
      width: 254px;
    `};
  }
  &.booked {
    background-color: ${GREEN};
    color: ${colors.background};
  }
`;

const StyledBodyText = styled(BodyText)`
  && {
    color: ${colors.headingStateful};
    font-weight: bold;
    font-size: 18px;
  }
`;

const contentWrapperBaseStyles = `
  position: relative;
  overflow: auto;
  border: 1px solid #b4b4b4;
  background-color: ${colors.background};
  ${media.aboveTabletLarge`
    padding: ${spacing.sm};
  `};
`;

const ContentWrapper = styled.div`
  border-radius: 6px;
  ${contentWrapperBaseStyles}
`;

const ContentWrapperOop2205 = styled.div`
  ${contentWrapperBaseStyles}
`;

const DeliverySection = styled.div`
  ${media.aboveTabletLarge`
    display: flex;
    justify-content: space-between;
  `};
`;

const DeliverySectionDetails = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${colors.disabled};
  padding: ${spacing.md};
  ${props => props.isButtonDisabled && 'justify-content: center'};
  p:first-child {
    padding-bottom: 0;
  }
  ${media.aboveTabletLarge`
    border-bottom: 1px solid ${colors.background};
  `};
`;

const BookDeliveryButton = styled.div`
  padding: ${spacing.sm} ${spacing.md};
  ${media.belowTablet`
  text-align: center;
  max-width: 555px;
  margin: auto;
`};
  ${media.aboveTablet`
  display: flex;
  justify-content: flex-end;
`};
  ${media.aboveTabletLarge`
  flex-direction: column;
  margin-right: 2px;
`}
`;

const BookOnDemandDeliveryButton = styled(BookDeliveryButton)`
  padding: ${spacing.md} ${spacing.md};
  ${media.aboveTabletLarge`
  flex-direction: row;
  margin-right: 2px;
`}
`;

const SlotDetails = styled.div`
  > p {
    line-height: 1.2;
    text-align: center;
  }
  padding-bottom: ${spacing.sm};
  ${media.tabletLargeOnly`
    padding: 0 36px 0 0;
  `};
`;

const InfoBanner = styled.div`
  text-align: center;
  background-color: #fefadb;
  padding: ${spacing.sm} 5%;
  clear: both;
  border-radius: ${spacing.xx};
  margin-top: ${spacing.sm};
  ${media.belowTabletLarge`
    & p {
      display: inline;
    }
    border: 1px solid #b4b4b4;
  `};
  a {
    padding-right: ${spacing.md};
  }
  .beans-link__icon-container {
    vertical-align: middle;
    margin: 0 0 ${spacing.xxx} ${spacing.xx};
  }
`;

const StyledSpaLink = styled(Link)`
  && {
    font-family: ${TESCO_MODERN_FONT_FAMILY};
  }
`;

const ShowLargeWrapper = styled.div`
  display: none;
  padding: ${spacing.xx} ${spacing.md} ${spacing.md} ${spacing.md};
  ${media.aboveTabletLarge` display: block;`};
`;

const StyledFootnote = styled(Footnote)`
  && strong {
    font-family: ${TESCO_MODERN_FONT_FAMILY};
  }
`;

const StyledContextCardText = styled(Footnote)`
  && {
    color: ${colors.headingStateful};
  }
`;

const StyledInPageMessaging = styled(InPageMessaging)`
  && {
    padding: ${spacing.sm} ${spacing.sm} ${spacing.sm} 48px;
    font-size: ${fontSize.xs};
    color: ${colors.text};
    min-height: auto;
  }
  && > div:first-child {
    width: 24px;
    height: 24px;

    svg {
      width: auto;
      height: 50%;
    }
  }
`;

export {
  StyledButton,
  StyledFootnote,
  StyledBodyText,
  StyledContextCardText,
  ContentWrapper,
  ContentWrapperOop2205,
  DeliverySection,
  DeliverySectionDetails,
  BookDeliveryButton,
  BookOnDemandDeliveryButton,
  SlotDetails,
  InfoBanner,
  StyledSpaLink,
  ShowLargeWrapper,
  StyledInPageMessaging
};
