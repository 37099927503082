import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class Overlay extends React.Component {
  static propTypes = {
    close: PropTypes.func,
    isOpen: PropTypes.bool.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      animate: props.isOpen,
      isShown: props.isOpen
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    if (nextProps.isOpen) {
      this.setState({
        isShown: true
      });

      this.timeoutId = setTimeout(() => {
        this.setState({
          animate: true
        });
      }, 100);
    } else {
      this.setState({
        animate: false
      });

      this.timeoutId = setTimeout(() => {
        this.setState({
          isShown: false
        });
      }, 400);
    }
  }

  render() {
    return (
      <div
        aria-hidden={!this.state.isShown}
        className={classnames('overlay', {
          'overlay-visible': this.state.animate
        })}
        style={{ display: this.state.isShown ? 'block' : null }}
        onClick={this.props.close}
      />
    );
  }
}

export default Overlay;
