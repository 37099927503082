import { getFeatureVariableStringFromState } from '#/lib/optimizely-manager';
import { favFilterNavigation, favFilterNavigationVariants } from '#/experiments/oop-1662/constants';
import { getIsFirstTimeShopper } from '#/reducers/user';
import { getIsStickyFilterVariant, getIsStickySearchFilterEnabled } from '#/experiments/oop-1662/helpers';

const getVariant = (state: Store): favFilterNavigationVariants => {
  const { useCustomerId, featureKey, variationKey } = favFilterNavigation;
  const isFirstTimeShopper = getIsFirstTimeShopper(state);
  const attributes = { hasFavourites: String(!isFirstTimeShopper) };

  return (
    getFeatureVariableStringFromState(state, featureKey, variationKey, { useCustomerId, attributes }) ||
    favFilterNavigationVariants.default
  );
};

export const getFavFilterNavigationVariant = (state: Store): favFilterNavigationVariants => getVariant(state);

export const isStickySearchFilterEnabled = (state: Store): boolean => getIsStickySearchFilterEnabled(getVariant(state));

export const isStickyFavFilterEnabled = (state: Store): boolean => getIsStickyFilterVariant(getVariant(state));
