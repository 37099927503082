import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from '../../../lib/render/connect-deep-compare';
import Modal from '../../shared/modal';
import ModalContent from '../../shared/modal/content';
import { ECOUPON_MODAL } from '../../../constants/modal-names';
import { openModal, closeModal } from '../../../actions/ui-action-creators';
import BagOptionsForm from '../../bag-options/bag-options-form';
import helpers from '../../../lib/decorators/helpers';
import AmendModalLinks from '../amend-modal-links';

@connect(null, {
  openModal,
  closeModal
})
@helpers(['t', 'l'])
class BagOptionsModal extends Component {
  static propTypes = {
    bagCharge: PropTypes.number.isRequired,
    closeModal: PropTypes.func.isRequired,
    isAmendBasket: PropTypes.bool,
    isBagless: PropTypes.bool.isRequired,
    isUpdating: PropTypes.bool.isRequired,
    l: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
  };

  render() {
    return (
      <Modal
        className="bag-options-modal"
        closeModal={this.props.closeModal}
        title={this.props.t('context-cards:change-bag-option')}
      >
        <ModalContent>
          <div className="bag-options-list">
            <BagOptionsForm
              charge={this.props.bagCharge}
              isBagless={this.props.isBagless}
              isTrolleyUpdating={this.props.isUpdating}
            />
          </div>
          <AmendModalLinks
            openModal={this.props.openModal}
            closeModal={this.props.closeModal}
            dividerClass="bag-options-modal--divider"
            linkContinueClass="bag-options-modal--link"
            linkClass="save-modal--coupon-link"
            linkName="orders:amend.add-ecoupons-vouchers"
            modalName={ECOUPON_MODAL}
          />
        </ModalContent>
      </Modal>
    );
  }
}

export default BagOptionsModal;
