export const SEARCH = 'search';
export const CATEGORY = 'category';
export const EMPTY_SEARCH = 'empty_search';
export const PRODUCT_DETAILS = 'product_details';
export const SHOPPING_LISTS = 'lists';
export const USUALS = 'usuals';
export const FAVORITES = 'favorites';
export const MISSED_PROMOTIONS = 'missed-promotions';
export const PROMOTIONS = 'promotions';
export const ORDERS = 'orders';
export const PROMOTIONS_HOMEPAGE = 'promotions-homepage';
export const BUYLIST = 'buylist';
export const FULL_BASKET = 'full-basket';
export const FUZZY = 'fuzzy';
export const SPELLCHECKER = 'spellchecker';
export const FAVORITES_CATEGORY = 'favorites-category';
export const CHECKOUT = 'checkout';
export const CHECKOUT_RECOMMENDATIONS = 'checkout-recommendations';
