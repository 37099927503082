import { FAVORITES } from '#/analytics/constants';

export const ARROW_UP = 'ArrowUp';
export const ARROW_DOWN = 'ArrowDown';
export const OPEN = 'open';
export const HEART = 'heart';
export const EVENT_TYPE = `${FAVORITES}:${HEART}`;
export const CLOSE = 'close';
export const ADD_TO_LIST_MODAL = `${FAVORITES}:add to list modal`;

export const promoteFavoritesOnPLPFeatureConfig = {
  featureKey: 'GHS-UK_plp-favesheart',
  variationKey: 'experience',
  useCustomerId: true,
};

export enum promoteFavoritesOnPLPVariants {
  default = 'default',
  heartIconPlusFaves = 'heart-icon-plus-faves',
  heartIconPlusPreviouslyBought = 'heart-icon-plus-pb',
  heartListIconPlusFaves = 'heartlist-icon-plus-faves',
  heartListIconPlusPreviouslyBought = 'heartlist-icon-plus-pb',
}
