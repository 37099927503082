import React, { useEffect } from 'react';
import { SECONDARY } from '@ddsweb/constants';
import { TextButtonLink } from '@ddsweb/link';
import Link from '#/components/link';
import {
  ButtonContainer,
  CaptionWrapper,
  ResponsiveImageContainer,
  TileTitle,
  SubText,
  ImageWrapper,
} from '#/experiments/oop-2021/components/trade_stamp/styled';
import {
  buttonAriaLabel,
  buttonText,
  searchContentAnalytics,
  contentCharLimits,
} from '#/experiments/oop-2021/constants';
import { ContentDetails } from '#/experiments/oop-2021/types';
import { emitContentInteractOp, emitRenderedContentOp } from '#/analytics/bertie/events';
import { getTrimmedText } from '#/experiments/oop-2021/helpers';

export type DcsTradeStampProps = {
  contentDetails: ContentDetails;
  position: number;
};

const DcsTradeStamp = ({ contentDetails, position }: DcsTradeStampProps): JSX.Element => {
  const {
    typeId,
    tracking: { id: trackingId },
    attributes: { title, mediaUrl, pageUrl, subHeading, buttonText: CTAText },
  } = contentDetails;

  const analyticspayload = {
    ...searchContentAnalytics,
    panel: [
      {
        trackingId,
        posInModule: position,
      },
    ],
  };

  useEffect(() => {
    emitRenderedContentOp({
      content: [analyticspayload],
    });
  }, []);

  const clickHandler = (): void => {
    emitContentInteractOp(analyticspayload);
  };

  const icid = `ghsweb_${typeId}_search_${title.replace(' ', '-')}`;
  const pageLink = `${pageUrl}?icid=${icid}`;
  const ariaLabel = `${buttonAriaLabel}${title}`;
  return (
    <div className="ui-stamp-widget">
      <div className="stamp-widget--inner">
        <div className="stamp--a">
          <div className="stamp__img-container">
            <Link to={pageLink} onClick={clickHandler}>
              <ResponsiveImageContainer>
                <ImageWrapper className="responsive-image--image" src={mediaUrl} alt={title} maxWidth={200} />
              </ResponsiveImageContainer>
            </Link>
          </div>
          <CaptionWrapper>
            <TileTitle headingLevel="3" visualSize="headline5" aria-label={title}>
              {getTrimmedText(title, contentCharLimits.heading)}
            </TileTitle>
            <SubText as="p" aria-label={subHeading} title={subHeading}>
              {getTrimmedText(subHeading, contentCharLimits.subText)}
            </SubText>
            <ButtonContainer>
              <TextButtonLink buttonVariant={SECONDARY} href={pageLink} aria-label={ariaLabel} onClick={clickHandler}>
                {getTrimmedText(CTAText || buttonText, contentCharLimits.CTAText)}
              </TextButtonLink>
            </ButtonContainer>
          </CaptionWrapper>
        </div>
      </div>
    </div>
  );
};

export default DcsTradeStamp;
