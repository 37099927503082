import React from 'react';
import PropTypes from 'prop-types';
import { SectionMessage } from '../cms-driven/section-message';
import AddressSlotInfo from '../address-slot-info';
import helpers from '#/lib/decorators/helpers';
import AddressSlotInfoReskin from '#/experiments/oop-2205/components/address-slot-info-reskin';

const DeliveryAddress = props => {
  const { t: translate, showSlotUIReskin, ...rest } = props;

  return (
    <div className={!showSlotUIReskin && 'delivery-address--section'}>
      {showSlotUIReskin ? (
        <AddressSlotInfoReskin {...rest} />
      ) : (
        <>
          <SectionMessage
            className="delivery-address--section-message"
            message={translate('slots:common.delivery-address-heading')}
          />
          <AddressSlotInfo {...rest} />
        </>
      )}
    </div>
  );
};

DeliveryAddress.propTypes = {
  address: PropTypes.object,
  shoppingMethod: PropTypes.string,
  slot: PropTypes.object,
  t: PropTypes.func.isRequired
};

export default helpers(['t'])(DeliveryAddress);
