import { BREAKPOINTS } from '@ddsweb/constants';
import { spacing } from '@ddsweb/selectors';
import styled from 'styled-components';

export const AccountIcon = styled.a`
  grid-area: account;
  display: none;

  @media (max-width: ${BREAKPOINTS.mobileLarge - 1}px) {
    display: block;
    margin: ${spacing.xs} ${spacing.xs} 10px auto;
    border-width: 0;
  }
`;

export const TrolleyDiv = styled.div`
  display: none;
  @media (max-width: ${BREAKPOINTS.tabletLarge - 1}px) {
    display: block;
    border-width: 0;
  }
`;

export const StoreLocatorIcon = styled.a`
  grid-area: storelocator;
  display: none;

  @media (max-width: ${BREAKPOINTS.tabletLarge - 1}px) {
    display: block;
    margin: ${spacing.xs} auto;
    border-width: 1px;
  }
`;
