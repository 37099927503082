export const ACCOUNT_PHONE_NUMBER = 'accountPhoneNumber';
export const ADDRESSES = 'addresses';
export const SUGGESTED_ADDRESS = 'suggestedAddress';
export const DEBOUNCE_TIME_GET_SUGGESTED_ADDRESSES = 400;
export const SUGGESTED_ADDRESSES_CHARACTERS_LIMIT = 30;
export const APP_STATE = 'appState';
export const BUYLIST = 'buylist';
export const BUYLISTBYID = 'buylistById';
export const DCS_PAGE = 'dcsPage';
export const FAVORITES = 'favorites';
export const FORM = 'form';
export const FAVORITES_CAROUSEL = 'favoritesCarousel';
export const FULFILMENT_OPTIONS_DETAIL = 'fulfilmentOptionsDetail';
export const HAVE_YOU_FORGOTTEN = 'haveYouForgotten';
export const HOME_PAGE = 'homePage';
export const MULTI_SEARCH_LIST = 'multiSearchList';
export const ORDER_LIST_DETAILS = 'orderListDetails';
export const PRODUCT_CATEGORIES = 'productCategories';
export const PRODUCTS_BY_CATEGORY = 'productsByCategory';
export const PROMOTIONS_BY_DEPARTMENT = 'promotionsByDepartment';
export const PROMOTIONS_ALL = 'promotionsAll';
export const PROMOTIONS_ID_OR_TYPE = 'promotionsIdOrType';
export const PROMOTIONS_CONTENT = 'promotionsContent';
export const SEARCH = 'search';
export const TAXONOMY = 'taxonomy';
export const CMS_NAV = 'cmsNav';
export const TROLLEY_CONTENTS = 'trolleyContents';
export const USUALS = 'usuals';
export const LAST_ORDER = 'lastOrder';
export const ZONE_PAGE = 'zonePage';
export const EXPERIMENTS = 'experiments';
export const PRODUCT_DETAILS = 'productDetails';
export const PRODUCT_REVIEWS = 'productReviews';
export const LOCATION = 'location';
export const SLOT = 'slot';
export const FULFILMENT_METADATA = 'fulfilmentMetadata';

export const RESOURCE_REQUEST_TIMESTAMPS = 'resourceRequests';

export const FULFILMENT_ESTIMATED_ARRIVAL = 'fulfilmentEstimatedArrival';
export const SUBSCRIPTIONS = 'subscriptions';
export const BROWSE = 'browse';
