import React from 'react';
import { GREY_DARK_2, GREY_LIGHT_2 } from '@beans/constants';
import Icon from '@beans/icon';
import helpers from '#/lib/decorators/helpers';
import { TTranslateFunc } from '#/lib/records/helpers.defs';
import { StyledDiv } from '#/components/favorites/no-more-favorites/styled';

interface NoMoreFavoritesProps {
  t: TTranslateFunc;
}

const NoMoreFavorites = ({ t: translate }: NoMoreFavoritesProps): JSX.Element => (
  <StyledDiv>
    <Icon graphic="basket" size="sm" className="basket" background={GREY_LIGHT_2} stroke={GREY_DARK_2} />
    <h3 className="title">{translate('favorites:no-more-favorites.title')}</h3>
  </StyledDiv>
);

export default helpers(['t'])(NoMoreFavorites);
