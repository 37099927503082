import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import helpers from '../../../../../lib/decorators/helpers';
import {
  VIEW_AND_BUY_TYPE_FAVORITES,
  VIEW_AND_BUY_TYPE_OFFERS
} from '../../../../../constants/display-types';
import SimpleButton from '../../../../shared/simple-button';
import analyticsBus from '../../../../../analytics/analyticsBus';
import { basicEvent } from '../../../../../analytics/types/basic';

@helpers(['t'])
export default class ViewAndBuyButton extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    viewAndBuyButtonUrl: PropTypes.string.isRequired,
    viewAndBuyType: PropTypes.oneOf([
      VIEW_AND_BUY_TYPE_FAVORITES,
      VIEW_AND_BUY_TYPE_OFFERS
    ])
  };

  viewAndBuyClickHandler() {
    basicEvent(analyticsBus, {
      type: `view and buy ${this.props.viewAndBuyType}`,
      value: 'product tile',
      action: 'delay'
    });
  }

  constructor() {
    super();
    this.viewAndBuyClickHandler = this.viewAndBuyClickHandler.bind(this);
  }

  render() {
    const { viewAndBuyButtonUrl } = this.props;

    return (
      <SimpleButton
        href={viewAndBuyButtonUrl}
        type="secondary"
        buttonText={this.props.t('common:view-and-buy')}
        onClick={this.viewAndBuyClickHandler}
        buttonClass="view-and-buy"
      />
    );
  }
}
