import styled from 'styled-components';
import { colors, spacing } from '@ddsweb/selectors';
import { Text } from '@ddsweb/text';

export const Wrapper = styled.div`
  margin-bottom: ${spacing.sm};
`;

export const Image = styled.img`
  max-width: 128px;
  max-height: 14px;
`;

export const StyledFootnote = styled(Text)`
  > * {
    vertical-align: middle;
    color: ${colors.text};
  }
`;
