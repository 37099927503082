import classnames from 'classnames';
import React, { useEffect, useRef, LegacyRef } from 'react';
import Link from '#/components/link-check-spa';
import MultiSearchLink from '#/experiments/oop-1824/components/multi-search-link';
import { addMultiSearchParamsToUrl } from '#/lib/multi-search';

type MultiSearchListItemProps = {
  itemsPerPage: number;
  selectedListItem: string;
  multiSearchPage: number;
  multiSearchList: string[];
  searchResultsBaseUrl: string;
  showImprovedMultiSearch: boolean;
};

export const getPageRange = (
  currentPage: number,
  itemsPerPage: number,
  multiSearchList: string[],
): { start: number; end: number } => {
  const start = (currentPage - 1) * itemsPerPage;
  return {
    start,
    end: Math.min(start + itemsPerPage, multiSearchList.length) - 1,
  };
};

const MultiSearchListItem: React.FC<MultiSearchListItemProps> = ({
  itemsPerPage,
  selectedListItem,
  multiSearchPage,
  multiSearchList,
  searchResultsBaseUrl,
  showImprovedMultiSearch,
}) => {
  const listElementRef = useRef<HTMLUListElement | null>(null);
  const itemElements: Record<string, HTMLLIElement> = {};

  useEffect(() => {
    if (selectedListItem) {
      scrollItemIntoView(selectedListItem);
    }
  }, [selectedListItem]);

  const scrollItemIntoView = (item: string): void => {
    const itemElement = itemElements[item];

    if (itemElement && listElementRef.current) {
      listElementRef.current.scrollLeft = itemElement.offsetLeft;
    }
  };

  const setListItemRef = (name: string, el: HTMLLIElement | null): void => {
    if (el) {
      itemElements[name] = el;
    }
  };

  const setListRef = (el: HTMLUListElement | null): void => {
    listElementRef.current = el;
  };

  const currentPageRange = getPageRange(multiSearchPage, itemsPerPage, multiSearchList);
  const ulClassName = classnames('multi-search-results-list__list', {
    'multi-search-results-list__list--show-carousel': showImprovedMultiSearch,
  });

  return (
    <ul className={ulClassName} ref={setListRef as LegacyRef<HTMLUListElement>}>
      {multiSearchList.map((item, itemIndex) => {
        const isItemSelected = item === selectedListItem;
        const isItemInCurrentPage = itemIndex >= currentPageRange.start && itemIndex <= currentPageRange.end;
        const liClassName = classnames('multi-search-results-list__item', {
          'multi-search-results-list__item--is-selected': isItemSelected,
          'multi-search-results-list__item--current-page': isItemInCurrentPage,
        });
        const multiSearchHref = addMultiSearchParamsToUrl(searchResultsBaseUrl, multiSearchPage, {
          query: item,
        });

        return showImprovedMultiSearch ? (
          <MultiSearchLink
            href={multiSearchHref}
            item={item}
            itemNumber={itemIndex}
            isItemSelected={isItemSelected}
            isItemInCurrentPage={isItemInCurrentPage} // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
            ref={el => setListItemRef(item, el)}
            key={`listItem${itemIndex}`}
          />
        ) : (
          <li
            key={`listItem${itemIndex}`}
            className={liClassName} // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
            ref={el => setListItemRef(item, el)}
            data-auto="multisearch-results-list-item"
          >
            <Link to={multiSearchHref} onClick={(): void => scrollItemIntoView(item)}>
              {item}
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

export default MultiSearchListItem;
