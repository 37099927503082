import React from 'react';
import PropTypes from 'prop-types';
import { WidgetContainer } from '../widget-container';
import { SectionMessage } from '../section-message';
import { generateIdPropFromTitle } from '#/lib/dcs/dcs-helpers';

const SectionDivider = props => {
  const { title } = props;

  return (
    <WidgetContainer widgetName="section-message">
      <div className="section-divider-widget">
        <SectionMessage
          {...generateIdPropFromTitle(title)}
          message={title}
          size="large"
        />
      </div>
    </WidgetContainer>
  );
};

SectionDivider.propTypes = {
  title: PropTypes.string.isRequired
};

export { SectionDivider };
