import { deserializeAll } from '#/resources/serializers';
import { getAppStateResource } from '#/selectors/resources';
import { RESOURCE_REQUEST_TIMESTAMPS } from '#/constants/spa-resource';

export const RESOURCES_RECEIVED = 'RESOURCES_RECEIVED';
export const UPDATE_RESOURCE = 'UPDATE_RESOURCE';
export const RESOURCES_ERROR = 'RESOURCES_ERROR';
export const SET_RESOURCES_REQUESTED_TIMESTAMP =
  'SET_RESOURCES_REQUESTED_TIMESTAMP';
export const SET_RESOURCES_RECEIVED_TIMESTAMP =
  'SET_RESOURCES_RECEIVED_TIMESTAMP';

const removeErrorUrl = ({ status, url, ...remaining }) => remaining; // eslint-disable-line @typescript-eslint/no-unused-vars

function setResourcesRequestTimestamps(state, action) {
  const resourceRequests = state[RESOURCE_REQUEST_TIMESTAMPS] || {};
  const { resources, timestamp = Date.now(), type } = action;
  const output = {
    ...resourceRequests
  };

  resources.forEach(function(resourceName) {
    const resourceRequest = output[resourceName] || {};
    const newResourceRequest = {
      ...resourceRequest
    };

    if (type === SET_RESOURCES_REQUESTED_TIMESTAMP) {
      newResourceRequest.requestedAt = timestamp;
      newResourceRequest.receivedAt = null;
    } else if (type === SET_RESOURCES_RECEIVED_TIMESTAMP) {
      newResourceRequest.receivedAt = timestamp;
    }

    output[resourceName] = newResourceRequest;
  });

  return {
    ...state,
    [RESOURCE_REQUEST_TIMESTAMPS]: output
  };
}

function setErroredResources(state, action) {
  const { requestedAt, resourceNames, status, url } = action;

  return setResourcesRequestTimestamps(
    setResourcesRequestTimestamps(
      {
        ...state,
        ...resourceNames.reduce((newState, resourceName) => {
          newState[resourceName] = {
            data: undefined,
            params: null,
            hash: null
          };

          return newState;
        }, {}),
        status,
        url
      },
      {
        type: SET_RESOURCES_REQUESTED_TIMESTAMP,
        resources: resourceNames,
        timestamp: requestedAt
      }
    ),
    {
      type: SET_RESOURCES_RECEIVED_TIMESTAMP,
      resources: resourceNames
    }
  );
}

function setReceivedResources(state, action) {
  const resourceNames = [];
  const { resources } = action;
  const newResources = Object.keys(resources).reduce(
    (newState, resourceName) => {
      resourceNames.push(resourceName);

      return {
        ...newState,
        [resourceName]: resources[resourceName]
      };
    },
    {}
  );

  return setResourcesRequestTimestamps(
    {
      ...state,
      ...deserializeAll(newResources)
    },
    {
      type: SET_RESOURCES_RECEIVED_TIMESTAMP,
      resources: resourceNames
    }
  );
}

/**
 * This is to support updating the resources outside of the fetchResources
 * flow. For example, patching the state after an optimistic update.
 *
 */
function setUpdatedResource(state, action) {
  const { resourceName, data, requestedAt } = action;

  return setResourcesRequestTimestamps(
    setResourcesRequestTimestamps(
      {
        ...state,
        [resourceName]: {
          data,
          params: null,
          hash: null
        }
      },
      {
        type: SET_RESOURCES_REQUESTED_TIMESTAMP,
        resources: [resourceName],
        timestamp: requestedAt
      }
    ),
    {
      type: SET_RESOURCES_RECEIVED_TIMESTAMP,
      resources: [resourceName]
    }
  );
}

export default (state = {}, action) => {
  switch (action.type) {
    case RESOURCES_RECEIVED:
      return setReceivedResources(removeErrorUrl(state), action);
    case UPDATE_RESOURCE:
      return setUpdatedResource(removeErrorUrl(state), action);
    case RESOURCES_ERROR:
      return setErroredResources(state, action);
    case SET_RESOURCES_REQUESTED_TIMESTAMP:
      return setResourcesRequestTimestamps(state, action);
    case SET_RESOURCES_RECEIVED_TIMESTAMP:
      return setResourcesRequestTimestamps(state, action);
    default:
      return state;
  }
};

export const getDefaultStateFromProps = ({ resources }) => {
  return deserializeAll(resources);
};

export const getServerTime = state => {
  const appState = getAppStateResource(state);

  return appState && appState.data && appState.data.serverTime;
};
