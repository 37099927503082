import { Item } from '#/lib/records/item';
import {
  getProductBulkBuyLimitGroupId,
  getGroupLimitReached,
  hasGroupBulkBuyLimit,
  getQuantity,
  getProductId,
} from '#/selectors/item';

/**
 * Filter the products and arrange them by bblg group id in case
 * the limit has been been reached
 * @param items Map or array of items
 */
export default function getItemsWithBblgLimitReached(
  items: Array<Item>,
): Record<string, Record<string, Pick<Item, 'quantity'>>> {
  const returnValue: Record<string, Record<string, Pick<Item, 'quantity'>>> = {};

  items.forEach((item: Item) => {
    if (hasGroupBulkBuyLimit(item) && getGroupLimitReached(item)) {
      const key = getProductBulkBuyLimitGroupId(item) as string;
      const quantity = getQuantity(item);
      const productId = getProductId(item);

      if (!returnValue[key]) {
        returnValue[key] = {};
      }

      returnValue[key][productId] = { quantity };
    }
  });

  return returnValue;
}
