import React from 'react';
import Sash, { TFlashSashInfoType } from '#/components/product-tiles/common/offer-sash/';
import { StyledMultiSash, StyledSashList } from './styled';
import { HORIZONTAL } from '@ddsweb/constants';
import { TFlashSashInfo } from '#/selectors/beans-product-tile/promotions-data';
import { DietaryTagsFull } from '#/experiments/oop-1922/components/tag-variants/dietary-tag-full';

type ParentProps = {
  OffersList?: TFlashSashInfo[] | null;
  tileType: string;
  tileVariant?: string;
  showCCPriceLogoInSquare: boolean;
  className?: string;
  foodIcons?: string[];
};

export type OffersSashListProps = ParentProps;

export const OffersSashList = ({
  OffersList,
  tileType,
  tileVariant,
  showCCPriceLogoInSquare,
  className,
  foodIcons,
}: OffersSashListProps): JSX.Element | null => {
  const renderSpecialOfferSash = (): React.ReactNode => {
    if (!Array.isArray(OffersList) || OffersList.length < 1) return null;

    return OffersList.map((offer, index) => (
      <StyledMultiSash key={`${index}${offer?.type}`}>
        <Sash
          flashSashInfo={offer as { type: TFlashSashInfoType; text: string }}
          tileType={tileType}
          showCCPriceLogoInSquare={showCCPriceLogoInSquare}
        />
      </StyledMultiSash>
    ));
  };

  const renderFoodIcons = (): React.ReactNode => {
    if (!foodIcons) return null;

    return <DietaryTagsFull content={foodIcons} />;
  };

  const foodIconTag = renderFoodIcons();
  const specialOfferSash = renderSpecialOfferSash();

  if (!foodIconTag && !specialOfferSash) return null;

  return (
    <StyledSashList isHorizontalTile={tileVariant === HORIZONTAL} className={className}>
      {specialOfferSash}
      {foodIconTag}
    </StyledSashList>
  );
};
export default OffersSashList;
