import React from 'react';
import PropTypes from 'prop-types';
import { ContentTitle } from '../content-title';
import SafelyOutputString from '../../safely-output-string';

const TitleAndParagraph = props => {
  const { title, textContent } = props;
  return (
    <div className="ui-qa--content">
      {title && <ContentTitle level={3} title={title} noHorizontalSpacing />}
      <p>
        <SafelyOutputString>{textContent}</SafelyOutputString>
      </p>
    </div>
  );
};

TitleAndParagraph.propTypes = {
  textContent: PropTypes.string.isRequired,
  title: PropTypes.string
};

export { TitleAndParagraph };
