import styled from 'styled-components';
import { colors, fontSize, spacing } from '@ddsweb/selectors';
import { Heading } from '@ddsweb/heading';
import { media } from '@ddsweb/foundation';
import Card from '@ddsweb/card';
import TabsMenu from '@ddsweb/tabs-menu';

export const StyledSlotContainer = styled.div`
  border: 1px solid ${colors.lines};
  border-top-width: 0;
  display: flex;

  > * {
    width: 100%;
  }

  .slot-grid__table-holder {
    margin-top: 0;
  }
`;

export const StyledTabsMenu = styled(TabsMenu)`
  [role='tablist'] {
    display: flex;
  }
  font-size: ${fontSize.xs};

  @media (max-width: 600px) {
    display: none;
  }
`;

export const StyledAddressHeadingReskin = styled(Heading)`
  && {
    font-size: ${fontSize.xs};
    line-height: ${spacing.lg};
    ${media.belowTablet`
      margin: 15px ${spacing.xs} 0 0;
      font-size: ${fontSize.xx};
    `};
  }

  &:after {
    content: ':';
  }
`;

export const DeliveryAddressCard = styled(Card)`
  padding-bottom: 0;
  margin: ${spacing.sm} 0 ${spacing.lg} 0;
`;
