import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ProgressBar from './progress-bar';
import VolumeSlider from './volume';

// Function to convert seconds to duration format in ss:ss, e.g  90 seconds => 01:30
export const formatTime = seconds =>
  (seconds - (seconds %= 60)) / 60 + (seconds > 9 ? ':' : ':0') + seconds;

// Controls for the video player -  Play/Pause, Seeking, Mute/Unmute and Fullscreen
const Controls = props => {
  const {
    isPlaying,
    isFullScreen,
    onFocus,
    onBlur,
    progress,
    onSeek,
    playPauseHandler,
    volume,
    duration,
    currentTime,
    fullScreenHandler,
    collapse,
    muteHandler,
    volumeHandler,
    muteAudio,
    supportsFullScreen,
    t
  } = props;
  const pseudoControlsClass = classNames('video-controls__custom-wrapper', {
    'video-controls__custom-wrapper--collapse': collapse
  });

  const playPauseButtonClass = classNames('video-controls__play-pause-icon', {
    'icon-pause-dark-gray': isPlaying,
    'icon-play-dark-gray': !isPlaying
  });

  const fullscreenIconClass = classNames('video-controls__fullscreen-icon', {
    'icon-fullscreen-exit-dark-gray': isFullScreen,
    'icon-fullscreen-enter-dark-gray': !isFullScreen
  });

  return (
    <div className="video-controls__wrapper">
      <div className={pseudoControlsClass}>
        <ProgressBar
          onFocus={onFocus}
          onBlur={onBlur}
          progress={progress}
          onSeek={onSeek}
        />
        <div className="video-controls__collapsable-actions">
          <div className="video-controls__left-section">
            <button
              title={isPlaying ? t('pause-video') : t('play-video')}
              onClick={playPauseHandler}
              onFocus={onFocus}
              onBlur={onBlur}
              className={playPauseButtonClass}
            />
            <VolumeSlider
              volume={volume}
              muteHandler={muteHandler}
              volumeHandler={volumeHandler}
              muteAudio={muteAudio}
              onFocus={onFocus}
              onBlur={onBlur}
              t={t}
            />
            <span className="video-controls__progress-info">
              {`${formatTime(currentTime)}/${formatTime(duration)}`}
            </span>
          </div>
          {supportsFullScreen && (
            <button
              title={isFullScreen ? t('minimize-video') : t('maximize-video')}
              onFocus={onFocus}
              onBlur={onBlur}
              onClick={fullScreenHandler}
              className={fullscreenIconClass}
            />
          )}
        </div>
      </div>
    </div>
  );
};

Controls.defaultProps = {
  progress: 20,
  isPlaying: true,
  muteAudio: true,
  collapse: false,
  currentTime: 0,
  duration: 0,
  volume: 1
};

Controls.propTypes = {
  className: PropTypes.string,
  collapse: PropTypes.bool,
  currentTime: PropTypes.number,
  duration: PropTypes.number,
  fullScreenHandler: PropTypes.func.isRequired,
  isFullScreen: PropTypes.bool,
  isPlaying: PropTypes.bool,
  muteAudio: PropTypes.bool,
  muteHandler: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onSeek: PropTypes.func.isRequired,
  playPauseHandler: PropTypes.func.isRequired,
  progress: PropTypes.number,
  supportsFullScreen: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  volume: PropTypes.number,
  volumeHandler: PropTypes.func.isRequired
};

export default Controls;
