import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Volume = props => {
  const { t, muteHandler, onFocus, onBlur, volume, volumeHandler } = props;

  const muteButtonTitle = volume === 0 ? t('unmute-video') : t('mute-video');

  const muteButtonClass = classnames('volume__icon', {
    'icon-muted-dark-gray': volume === 0,
    'icon-unmuted-dark-gray': volume !== 0
  });

  return (
    <div className="volume__container">
      <button
        title={muteButtonTitle}
        onClick={muteHandler}
        onFocus={onFocus}
        onBlur={onBlur}
        className={muteButtonClass}
      />
      <input
        className="volume__slider"
        type="range"
        aria-label={t('volume-slider')}
        min="0"
        max="100"
        style={{
          background: `linear-gradient(to right, white ${volume *
            100}%, grey ${volume * 100}%)`
        }}
        value={volume * 100}
        onChange={volumeHandler}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </div>
  );
};

Volume.propTypes = {
  muteHandler: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  t: PropTypes.func,
  volume: PropTypes.number.isRequired,
  volumeHandler: PropTypes.func.isRequired
};
export default Volume;
