import { IMMUTABLE_PROPS } from '#/utils/immutable-utils/constants';

/**
 * @deprecated We are no longer utilising Immutable.js wrappers.
 */
export function toJS(customInstance) {
  return customInstance?.toJS?.() || customInstance;
}

/**
 * @deprecated We are no longer utilising Immutable.js wrappers.
 */
export function getFilteredKeys(customInstance) {
  return Object.keys(customInstance).filter(key => !IMMUTABLE_PROPS.includes(key));
}

/**
 * @deprecated We are no longer utilising Immutable.js wrappers.
 */
export function toObject(customInstance) {
  return customInstance?.toObject?.() || customInstance;
}

/**
 * @deprecated We are no longer utilising Immutable.js wrappers.
 */
export function toArray(customInstance) {
  return customInstance?.toArray?.() || getFilteredKeys(customInstance).map(key => customInstance[key]);
}

/**
 * @deprecated We are no longer utilising Immutable.js wrappers.
 */
export function toList(customInstance) {
  return customInstance?.toList?.() || customInstance;
}

/**
 * @deprecated We are no longer utilising Immutable.js wrappers.
 */
export function getKeys(customInstance) {
  return customInstance?.keys?.() || getFilteredKeys(customInstance);
}
