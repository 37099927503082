export const UNMATCHED = 'UNMATCHED';

const VALID_HOMEPAGE_PREFIX = ['?', '#'];

export default (url = ''): string => {
  const pathRegExp = /\/groceries(?:\/?)(?:[a-z]{2}-[a-zA-Z]{2}(?:\/?))?/;

  if (!url || !pathRegExp.test(url)) {
    return UNMATCHED;
  }

  const urlParts = url.split(pathRegExp);
  const trimmedPath = urlParts.slice(1).join('');

  if (trimmedPath === '' || VALID_HOMEPAGE_PREFIX.includes(trimmedPath[0])) {
    return 'homepage';
  }

  const regExpArray = [
    // modals
    {
      pattern: /([^\/|?|#]*).*?[?|&]currentModal=([a-zA-Z]*)?.*/,
      replace: '$1/modal/$2',
    },
    {
      pattern: /([^\/]*).*?[?|&]justLoggedIn=([a-zA-Z]*)?.*/,
      replace: '$1/justLoggedIn/$2',
    },
    {
      pattern: /^buy(-)?lists(\/.*)?/,
      replace: 'buylists',
    },
    // Slots routes
    {
      pattern: /^slots\/(collection|delivery|ondemand)?([\/|?].*)?/,
      replace: 'slots/$1',
    },
    // checkout routes
    {
      pattern: /^checkout\/([^\/|?]*)?.*/,
      replace: 'checkout/$1',
    },
    // favourites routes
    {
      pattern: /^favorites\/?.*?[?|&]tab=(?!favourites)([a-zA-Z]*)?.*/,
      replace: '$1',
    },
    // catch all
    {
      pattern: /([^\/|?|#]*)?.*/,
      replace: '$1',
    },
  ];

  let pageName = '';

  for (let i = 0; i < regExpArray.length; i++) {
    const match = regExpArray[i];

    if (match.pattern.test(trimmedPath)) {
      pageName = trimmedPath.replace(match.pattern, match.replace);
      break;
    }
  }

  return pageName || UNMATCHED;
};
