import { connect as reduxConnect } from 'react-redux';
import deepEqual from 'fast-deep-equal/es6';

export function connect(
  mapStateToProps,
  mapDispatchToProps,
  ownProps,
  options
) {
  return reduxConnect(mapStateToProps, mapDispatchToProps, ownProps, {
    areStatePropsEqual: deepEqual,
    ...options
  });
}
