export const CLUBCARD_POINTS = 'ClubCardPoints';
export const COUPON_TYPE_POINTS = 'points';
export const COUPON_TYPE_MONETARY = 'val';
export const DEFAULT_ERROR_CODE = 'notexist';
export const NOT_EXIST_ERROR_CODE = 'notexist';
export const TRANSITION_DURATION = 500;

export const COUPON_STATUS_SUCCESS_SELECTED_QUALIFIED = 'success-selected-qualified';
export const COUPON_STATUS_SUCCESS_SELECTED_UNQUALIFIED = 'success-selected-unqualified';
export const COUPON_STATUS_ERROR = 'error';
export const COUPON_STATUS_INCLUDED_IN_SAVINGS = 'FITTED';

export const COUPON_QUALIFIED = 'qualified';
export const COUPON_UNQUALIFIED = 'unqualified';
