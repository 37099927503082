import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import helpers from '../../../../../lib/decorators/helpers';
import Link from '../../../../link-check-spa';
import { basicEvent } from '../../../../../analytics/types/basic';
import analyticsBus from '../../../../../analytics/analyticsBus';
import { getLanguageLink } from '../../../../../reducers/app';
import { connect } from '../../../../../lib/render/connect-deep-compare';
import SafelyOutputString from '#/components/safely-output-string';

const mapStateToProps = (state, { restOfShelfUrl }) => ({
  restOfShelfUrl: getLanguageLink(state, restOfShelfUrl)
});

@helpers(['t'])
@connect(mapStateToProps)
export default class BrowseCategorySuggestion extends PureComponent {
  static propTypes = {
    restOfShelfUrl: PropTypes.string,
    shelfName: PropTypes.string,
    t: PropTypes.func.isRequired
  };

  onClick = () => {
    basicEvent(analyticsBus, {
      type: 'rest of shelf',
      value: 'product tile',
      action: 'delay'
    });
  };

  render() {
    const { shelfName, t: translate, restOfShelfUrl } = this.props;
    const restOfShelfText = shelfName
      ? translate('product-tile:rest-of-shelf-long', { shelfName })
      : translate('product-tile:more-in-this-category');

    return (
      <div className="browse-suggestion--wrapper hidden-medium">
        <div className="browse-suggestion--inner">
          <p className="browse-suggestion">
            <SafelyOutputString>
              {translate('product-tile:browse-suggestion')}
            </SafelyOutputString>
          </p>
          <p>
            <Link
              className="browse-category icon-chevron_right"
              to={restOfShelfUrl}
              onClick={this.onClick}
            >
              <SafelyOutputString>{restOfShelfText}</SafelyOutputString>
            </Link>
          </p>
        </div>
      </div>
    );
  }
}
