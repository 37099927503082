import { request } from '../client-fetch';

export const clientGraphqlPost = (
  endpoint,
  context,
  query,
  variables = {},
  atrc,
  requestName,
  trace_id
) => {
  return request.post(endpoint, {
    body: JSON.stringify({
      query,
      variables
    }),
    headers: {
      ...context,
      traceId: trace_id
    },
    corsRequest: true,
    atrc,
    requestName,
    trace_id
  });
};
