import moment from 'moment-timezone';
import { ImpressedSlotOp, SlotData, StoreData } from '#/analytics/bertie/types';
import { COLLECTION, ShoppingMethod } from '#/constants/shopping-methods';
import { Slot } from '#/lib/records/slot.defs';
import { isSlotAvailable } from '#/lib/slot/slot-utils';
import { flatten } from '#/utils/misc';

function getDaysFromToday(date: string | moment.Moment | null): number {
  return moment(date)
    .startOf('day')
    .diff(moment().startOf('day'), 'days');
}

function getFormattedLocalDate(date: string | moment.Moment | null, format: string, timezone: string): string {
  return moment(date)
    .tz(timezone)
    .format(format);
}

export function getSlotOpPayload(
  slots: Record<string, Slot[]> | Slot[],
  selectedShoppingMethod: ShoppingMethod,
  storeId: string,
  locationId: string,
  addressId: string,
  timezone: string,
): ImpressedSlotOp {
  const format = 'YYYY-MM-DDTHH:mm:ss';
  const slotData = flatten(Object.values(slots)).map(
    (slot: Slot): SlotData => ({
      daysFromToday: getDaysFromToday(slot.start),
      isAvailable: isSlotAvailable(slot),
      method: selectedShoppingMethod,
      slotEnd: getFormattedLocalDate(slot.end, format, timezone),
      slotStart: getFormattedLocalDate(slot.start, format, timezone),
    }),
  );
  const storeData: StoreData = { storeId, locationId };
  return {
    slot: slotData,
    bertieType: 'impressedSlotOp',
    store: storeData,
    addressId: selectedShoppingMethod === COLLECTION ? null : addressId,
  };
}
