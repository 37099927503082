import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Price from '#/components/shared/price';
import helpers from '#/lib/decorators/helpers';

@helpers(['c', 't'])
export default class PricePerUnit extends Component {
  static propTypes = {
    c: PropTypes.func,
    compactMode: PropTypes.bool,
    t: PropTypes.func,
    unitOfMeasure: PropTypes.string.isRequired,
    unitPrice: PropTypes.number.isRequired
  };
  render() {
    const priceProps = {
      value: this.props.unitPrice,
      ...this.props.c('currency')
    };

    return (
      <div className="price-per-quantity-weight">
        <Price {...priceProps} compactMode={this.props.compactMode} />
        <span className="weight">
          {'/' +
            (this.props.t(
              'product-tile:unit.' + this.props.unitOfMeasure,
              null,
              {
                returnNullIfFalsy: true
              }
            ) || this.props.unitOfMeasure)}
        </span>
      </div>
    );
  }
}
