import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BasicAccordion } from '@ddsweb/accordion';
import { StyledAccordionContent } from '#/components/promotions/promotions-by-department/styled';
import PromotionListItem from '../promotion-list-item';
import helpers from '../../../lib/decorators/helpers';
import { productFilterAnalyticsEvent } from '#/analytics/types/filter';
import { getCurrentFilters } from '#/selectors/promotions';
import { getSlugId } from '#/lib/url/url-utils';

@connect(appState => ({
  currentFilters: getCurrentFilters(appState)
}))
@helpers(['c', 't'])
export default class PromotionsByDepartment extends PureComponent {
  static propTypes = {
    c: PropTypes.func,
    facetLists: PropTypes.array.isRequired,
    isOpen: PropTypes.bool,
    promotionsConfig: PropTypes.object,
    t: PropTypes.func,
    totalCount: PropTypes.number
  };

  getFacetsByCategory(category) {
    const facetList = this.props.facetLists.find(list => {
      return list?.category?.toLowerCase() === category;
    });

    return facetList ? facetList.facets : [];
  }

  listItemClicked(facet) {
    const { currentFilters, facetLists } = this.props;

    const nextFacets = facetLists.map(list => ({
      ...list,
      facets: list.facets.map(listFacet => ({
        ...listFacet,
        isSelected: listFacet.facetId === facet.facetId
      }))
    }));

    productFilterAnalyticsEvent(currentFilters, nextFacets);
  }

  getListItems(category, isRed = false) {
    const items = this.getFacetsByCategory(category).map(facet => {
      const { facetId: id, facetName, binCount } = facet;

      const link = `/promotions/all?${category}=${encodeURIComponent(id)}`;

      return (
        <PromotionListItem
          key={id}
          link={link}
          name={facetName}
          count={binCount}
          red={isRed}
          // Removal of click event temporarily https://jira.global.tesco.org/browse/LEGO-24494
          // https://jira.global.tesco.org/browse/LEGO-24496 - ticket to re-add.
          // onClick={() => this.listItemClicked(facet)}
        />
      );
    });

    return items.length > 0 ? items : null;
  }

  render() {
    const byDepartmentListItems = this.getListItems('superdepartment');
    const byDepartmentList = byDepartmentListItems && (
      <ul className="promotions-by-department--facets promotions--department by-department">
        {byDepartmentListItems}
        <PromotionListItem
          key="all"
          link="/promotions/all"
          name={this.props.t('promotions:view-all')}
          count={this.props.totalCount}
        />
      </ul>
    );

    const accordionHeader = byDepartmentList
      ? this.props.t('promotions:view-offers-by-department')
      : this.props.t('promotions:view-offers-by-type');

    const id = getSlugId(accordionHeader);

    return (
      <div className="promotions-by-department">
        <BasicAccordion
          behaviour="toggle"
          id={`accordion-${id}`}
          preExpanded={this.props.isOpen && `accordion-content-${id}`}
          content={[
            {
              headingLevel: 'h2',
              iconVariant: 'primary',
              heading: accordionHeader,
              panelClassName: 'accordion-panel',
              id: `accordion-content-${id}`,
              iconClassName: 'accordion-icon-toggle',
              panel: (
                <StyledAccordionContent>
                  {byDepartmentList}
                </StyledAccordionContent>
              )
            }
          ]}
        />
      </div>
    );
  }
}
