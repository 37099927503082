import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from '#/lib/render/connect-deep-compare';
import classnames from 'classnames';
import moment from 'moment-timezone';
import Accordion from '#/components/accordion';
import Link from '#/components/link';
import CouponModalLink from '#/components/coupon/coupon-modal-link';
import StickyBar from '../';
import { AMEND_EXPIRED_MODAL } from '#/constants/modal-names';
import {
  toggleAmendOrderBar,
  openModal,
  openNavMenu
} from '#/actions/ui-action-creators';
import { hasItems } from '#/lib/trolley/trolley-utils';
import {
  getLastSelectedSlot,
  getPreviousSlot,
  getAmendExpiryTime,
  getItems,
  getIsAmendBasket,
  getHasAtLeastOneNewItem
} from '#/selectors/trolley';
import {
  getCurrentUrl,
  getLanguage,
  getTimezone,
  getLanguageLink
} from '#/reducers/app';
import { SUPERDEPARTMENT } from '#/constants/taxonomy-levels';
import { TABLET } from '#/constants/platforms';
import { selectTaxonomyItem } from '#/actions/taxonomy-action-creators';
import helpers from '#/lib/decorators/helpers';
import { getFirstNavItem } from '#/reducers/taxonomy';
import AmendCheckoutButton from '#/components/buttons/amend-checkout-button';
import CancelAmendButton from '#/components/buttons/cancel-amend-button';
import {
  getSlotReservationExpiry,
  getSlotStart,
  getSlotStatus
} from '#/selectors/slot';
import { getDefaultSlotsPath } from '#/reducers/slot';
import {
  getFormattedDateTime,
  getFormattedTime
} from '#/components/shared/formatted-time/selector';
import { ReferrerPolicies } from '#/constants/referrer-policy';
import {
  getAmendBannerPositionStyleVariant,
  getOop1882deDiffCopy,
  getOop1882cdeSaveButton,
  getOop1882bcdeBottom,
  getOop1882eDarkChange
} from '#/experiments/oop-1882/selectors';
import { BasketIcon } from '../styled';
import { getFormattedDate } from '#/lib/i18n/date-utils';
import SaveChangesButton from '#/components/buttons/save-changes-button';

const mapStateToProps = (state, { f: feature, c: config }) => {
  const amendBannerPositionStyleVariant = getAmendBannerPositionStyleVariant(
    state
  );
  const bookedSlot = getLastSelectedSlot(state);
  const previousSlot = getPreviousSlot(state);
  const slotExpiry =
    getSlotStart(previousSlot) !== getSlotStart(bookedSlot) &&
    getSlotStatus(bookedSlot) !== 'Expired' &&
    moment(getSlotReservationExpiry(bookedSlot));
  const expiryTime = getAmendExpiryTime(state);
  const expiryDateTime = getOop1882deDiffCopy(amendBannerPositionStyleVariant)
    ? getFormattedDate(
        expiryTime,
        'h.mma, ddd D MMM',
        getTimezone(state),
        getLanguage(state)
      )
    : getFormattedDateTime(state, expiryTime, config);

  return {
    currentUrl: getCurrentUrl(state),
    expiryTime,
    expiryDateTime,
    hideAmendOrderBar: state.ui.hideAmendOrderBar,
    hasItemsInTrolley: hasItems(getItems(state)),
    slotExpiry,
    slotExpiryTime: slotExpiry
      ? getFormattedTime(state, slotExpiry, config)
      : undefined,
    firstMenuItem: getFirstNavItem(state),
    language: getLanguage(state),
    timezone: getTimezone(state),
    shopLink: getLanguageLink(state, '/shop'),
    checkoutInAmend: config('checkout:enableInAmendMode'),
    showAmendChangeSlot: feature('allowAmendSlot'),
    slotsLanguageLink: getLanguageLink(
      state,
      getDefaultSlotsPath(feature)(state)
    ),
    isAmendBasket: getIsAmendBasket(state),
    amendBannerVariant: amendBannerPositionStyleVariant,
    hasAtLeastOneNewItem: getHasAtLeastOneNewItem(state)
  };
};

@helpers(['t', 'c', 'f'])
@connect(mapStateToProps, {
  toggleAmendOrderBar,
  openModal,
  openNavMenu,
  selectTaxonomyItem
})
export default class AmendOrderBar extends Component {
  static propTypes = {
    c: PropTypes.func.isRequired,
    currentUrl: PropTypes.string.isRequired,
    expiryTime: PropTypes.string,
    f: PropTypes.func.isRequired,
    firstMenuItem: PropTypes.object.isRequired,
    hasItemsInTrolley: PropTypes.bool.isRequired,
    hideAmendOrderBar: PropTypes.bool,
    isAmendBasket: PropTypes.bool.isRequired,
    language: PropTypes.string.isRequired,
    openModal: PropTypes.func.isRequired,
    openNavMenu: PropTypes.func.isRequired,
    selectTaxonomyItem: PropTypes.func.isRequired,
    showAmendChangeSlot: PropTypes.bool,
    slotExpiry: PropTypes.any,
    slotExpiryTime: PropTypes.string,
    slotsLanguageLink: PropTypes.string,
    t: PropTypes.func.isRequired,
    timezone: PropTypes.string.isRequired,
    toggleAmendOrderBar: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    const { expiryTime, openModal, isAmendBasket } = this.props;

    const isExpiredInAmend =
      isAmendBasket && moment(expiryTime).isBefore(moment(new Date()));

    if (isExpiredInAmend) {
      openModal(AMEND_EXPIRED_MODAL, null, false);
    }

    this.state = {
      oop1882bcdeBottom: getOop1882bcdeBottom(this.props.amendBannerVariant),
      oop1882deDiffCopy: getOop1882deDiffCopy(this.props.amendBannerVariant),
      oop1882cdeSaveButton: getOop1882cdeSaveButton(
        this.props.amendBannerVariant
      ),
      oop1882eDarkChange: getOop1882eDarkChange(this.props.amendBannerVariant)
    };
  }

  expiryTime = () => {
    const { timezone, expiryTime, language } = this.props;

    return moment(expiryTime)
      .utc()
      .tz(timezone)
      .locale(language);
  };

  expireText(expiryLabel) {
    const { t: translate } = this.props;

    return (
      <div className="expires-by-header">
        <span className="making-changes">
          {translate('orders:amend.making-changes')}
        </span>
        <span className="expiry-time">{expiryLabel}</span>
      </div>
    );
  }

  getAmendModeLink() {
    const {
      currentUrl,
      showAmendChangeSlot,
      slotsLanguageLink,
      shopLink,
      t: translate
    } = this.props;

    if (showAmendChangeSlot && !/\/slots/.test(currentUrl)) {
      return (
        <Link
          href={slotsLanguageLink}
          className="amend-menu-list-content change-your-slot"
        >
          <span>{translate('home:amend-order.change-your-slot')}</span>
          <span className="icon icon-chevron_right" />
        </Link>
      );
    }

    return (
      <Link
        href={shopLink}
        className="amend-menu-list-content amend-add-items"
        onClick={this.handleAmendAddItemsClick}
      >
        <span>{translate('orders:amend.add-items')}</span>
        <span className="icon icon-chevron_right" />
      </Link>
    );
  }

  getCouponLink() {
    const { t: translate } = this.props;

    return (
      <CouponModalLink className="amend-menu-list-content amend-add-ecoupons">
        <span>{translate('orders:amend.add-ecoupons-vouchers')}</span>
        <span className="icon icon-chevron_right" />
      </CouponModalLink>
    );
  }

  getMenu(expiryLabel) {
    const {
      c: config,
      t: translate,
      toggleAmendOrderBar,
      checkoutInAmend
    } = this.props;

    if (config('showAmendOrderBarMenu') && !checkoutInAmend) {
      const AmendModeLink = this.getAmendModeLink();
      const CouponLink = this.getCouponLink();

      return (
        <Accordion
          classes="hidden-medium"
          header={this.expireText(expiryLabel)}
          icon="icon-chevron_up"
          isOpen={false}
          title={translate('orders:amend.making-changes')}
          toggleHandler={toggleAmendOrderBar}
        >
          <div>
            <ul>
              <li className="amend-menu-list-item">{AmendModeLink}</li>
              <li className="amend-menu-list-item">{CouponLink}</li>
            </ul>
          </div>
        </Accordion>
      );
    }

    return null;
  }

  handleAmendAddItemsClick = e => {
    const { openNavMenu, selectTaxonomyItem, firstMenuItem } = this.props;

    e.preventDefault();
    e.stopPropagation();
    window.scrollTo(0, 0);
    openNavMenu();
    selectTaxonomyItem(SUPERDEPARTMENT, firstMenuItem, TABLET);
  };

  getCancelButtonText() {
    const { c: config, t: translate } = this.props;
    if (this.state.oop1882cdeSaveButton) {
      return translate('orders:amend.checkout-cancel-amend-cta-oop1882-cde');
    }

    if (config('amendOrderBarLongButtonCopy')) {
      return translate('orders:amend.cancel-changes');
    }

    return translate('common:cancel');
  }

  getCancelButton() {
    const { c: config } = this.props;
    const ddl = config('showNewAmendBar');
    return (
      <CancelAmendButton
        inverse={this.isAmendBannerDark()}
        buttonText={this.getCancelButtonText()}
        classes="js-change-bar-cancel-changes"
        ddl={ddl}
      />
    );
  }

  getSaveButton(isDisabled) {
    const { t: translate } = this.props;

    return (
      <SaveChangesButton
        buttonText={translate('orders:amend.save-changes')}
        classes="js-change-bar-save-changes small"
        disabled={isDisabled}
      />
    );
  }

  getCheckoutButton(isDisabled) {
    const { c: config } = this.props;
    const ddl = config('showNewAmendBar');

    return (
      <AmendCheckoutButton
        inverse={this.isAmendBannerDark()}
        classes={classnames('js-change-bar-save-changes', 'small', {
          'hidden-medium': !this.state.oop1882cdeSaveButton
        })}
        disabled={isDisabled}
        ddl={ddl}
        referrerPolicy={ReferrerPolicies.NO_REFERRER_WHEN_DOWNGRADE}
        uses1882cdeCopy={this.state.oop1882cdeSaveButton}
      />
    );
  }

  getAmendBannerTitleCopy() {
    const { t: translate, hasAtLeastOneNewItem } = this.props;

    if (!this.state.oop1882deDiffCopy) {
      return translate('orders:amend.making-changes');
    }

    return hasAtLeastOneNewItem
      ? translate('orders:amend.have-changes-oop1882-de')
      : translate('orders:amend.making-changes-oop1882-de');
  }

  getAmendExpiryCopy() {
    const {
      slotExpiry,
      slotExpiryTime,
      t: translate,
      expiryDateTime
    } = this.props;

    if (slotExpiry && !this.expiryTime().isBefore(slotExpiry)) {
      return translate('orders:amend.save-by-retain-slot', {
        time: slotExpiryTime
      });
    }

    if (this.state.oop1882deDiffCopy) {
      return translate('orders:amend.save-by-oop1882-de', {
        time: expiryDateTime
      });
    }

    return translate('orders:amend.save-by', {
      time: expiryDateTime
    });
  }

  isAmendBannerDark() {
    const { slotExpiry } = this.props;
    return (
      this.state.oop1882eDarkChange &&
      (this.props.hasAtLeastOneNewItem ||
        (slotExpiry && !this.expiryTime().isBefore(slotExpiry)))
    );
  }

  render() {
    const {
      hasItemsInTrolley,
      c: config,
      hideAmendOrderBar,
      checkoutInAmend
    } = this.props;
    const primaryButtonIsDisabled = !hasItemsInTrolley;

    const expiryLabel = this.getAmendExpiryCopy();
    const amendBannerTitleCopy = this.getAmendBannerTitleCopy();

    const stickyBarVariant = config('showNewAmendBar')
      ? 'primary'
      : 'secondary';

    return (
      <StickyBar
        variant={stickyBarVariant}
        hide={hideAmendOrderBar}
        bothButtonsAlwaysShown={!checkoutInAmend}
        amendBannerDark={this.isAmendBannerDark()}
        oop1882eDarkChange={this.state.oop1882eDarkChange}
        oop1882bcdeBottom={this.state.oop1882bcdeBottom}
      >
        <div
          data-auto="amend-order-bar-content"
          className={classnames('amend-order-bar-content sticky-bar-content', {
            'has-menu': config('showAmendOrderBarMenu') && !checkoutInAmend
          })}
        >
          {this.state.oop1882eDarkChange && (
            <span className="hidden-small-medium">
              <BasketIcon
                graphic="basket"
                background={this.isAmendBannerDark() ? '#ffffff' : '#007EB3'}
                stroke={this.isAmendBannerDark() ? '#0074E0' : '#ffffff'}
                size="sm"
              />
            </span>
          )}
          <div
            className={classnames('expires-by sticky-bar-message', {
              'oop1882-cde-cta': this.state.oop1882cdeSaveButton
            })}
          >
            <span className="making-changes">
              {amendBannerTitleCopy}
              &nbsp;
            </span>
            <span className="expiry-time">{expiryLabel}</span>
          </div>
          {this.getMenu(expiryLabel)}
          <div
            className={classnames('sticky-bar-controls', {
              'oop-1882-cde-cta': this.state.oop1882cdeSaveButton
            })}
          >
            {this.getCancelButton()}
            {checkoutInAmend
              ? this.getCheckoutButton(primaryButtonIsDisabled)
              : this.getSaveButton(primaryButtonIsDisabled)}
          </div>
        </div>
      </StickyBar>
    );
  }
}
