import styled from 'styled-components';
import { Accordion } from '@ddsweb/accordion';
import { colors, fontSize, spacing } from '@ddsweb/selectors';
import Text from '@ddsweb/text';
import Divider from '@ddsweb/divider';

export const StyledAccordionItem = styled(Accordion.Item)`
  && {
    border-top: 0;
  }
`;

export const StyledAccordionPanel = styled(Accordion.Panel)`
  div {
    padding-bottom: 0;
    padding-top: 0;
  }
`;

export const StyledAccordionButton = styled(Accordion.Button)`
  margin-top: 0;
`;

export const StyledAccordionIcon = styled(Accordion.Icon)`
  height: 24px;
  width: 24px;
`;

export const StyledSubTotal = styled(Text)`
  color: ${colors.text};
  margin-left: ${spacing.sm};
  margin-top: -${spacing.sm};
  padding-bottom: ${spacing.sm};
  font-size: ${fontSize.xs};
  font-weight: 400;
`;

export const StyledDivider = styled(Divider)`
  background-color: ${colors.linesLight};
  margin: ${spacing.xx} ${spacing.sm} 0 ${spacing.sm};
  width: auto;
`;
