import styled, { css } from 'styled-components';
import { base, colors } from '@ddsweb/selectors';
import { MEDIUM } from '#/components/product-tiles/temp-style-constants';
import { TClubcardLogo } from '#/components/promotions/clubcard-logo';
import { VERTICAL } from '#/components/product-tiles/common/constants';

const CLUBCARD_LOGO_SIZE = '56px';
const sharedStyles = 'display: flex; align-items: center; justify-content: center;';
const wrapperDefaultPosition = 'overflow: hidden; position: absolute; top: 0; left: 0; z-index: 1';

const getClubcardVariantStyle = (tileVariant: string): string => {
  if (tileVariant === VERTICAL) {
    return '';
  }

  return wrapperDefaultPosition;
};

const Wrapper = styled.div<TClubcardLogo>`
  ${({ tileVariant, hideOnMobile }: TClubcardLogo): string => css`
    background-color: ${colors.link};
    width: ${CLUBCARD_LOGO_SIZE};
    height: ${CLUBCARD_LOGO_SIZE};

    ${hideOnMobile ? `display: none; @media ${MEDIUM} { ${sharedStyles} }` : sharedStyles};
    ${getClubcardVariantStyle(tileVariant as string)};
  `}
`;

const TextWrapper = styled.strong`
  color: ${colors.base};
  font-size: 10px;
  line-height: 10px;
  text-align: center;
  font-family: ${base.fontFamily};
`;

const CircleWrapper = styled.span`
  background-color: ${colors.promo};
  width: 48px;
  height: 48px;
  border-radius: 50%;
  ${sharedStyles}
`;

const SquareWrapper = styled.span`
  background-color: ${colors.promo};
  width: 46px;
  height: 46px;
  border-radius: 14%;
  ${sharedStyles}
`;

export { Wrapper, TextWrapper, CircleWrapper, CLUBCARD_LOGO_SIZE, SquareWrapper };
