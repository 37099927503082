import { QUANTITY_TYPE as UNIT_PCS } from '#/constants/common';
import { getGtin13FromImageUrl } from '#/lib/records/product-utils';
import formatNumber from '#/lib/string-formatting/number-formatter';
import {
  getCost,
  getIsDiscontinued,
  getIsSubstitute,
  getProductAisleId,
  getProductAisleName,
  getProductAverageWeight,
  getProductBaseProductId,
  getProductBrandName,
  getProductDefaultImageUrl,
  getProductDepartmentName,
  getProductId,
  getProductIsForSale,
  getProductSuperDepartmentName,
  getProductTitle,
  hasPromotion,
  getReviewsStats,
  getSeedProductId,
  getUnit,
  isClubcardPromotionOnItem,
  isInFavourites
} from '#/selectors/item';
import {
  setCustomerUnitChoice,
  setNumberOfItems
} from '#/lib/records/item-utils';
import { toArray } from '#/utils/immutable-utils';

export function pricePromotion(product) {
  let promotion = {};

  if (hasPromotion(product)) {
    const promotions = toArray(product.promotions)[0];
    const {
      startDate,
      endDate,
      promotionId,
      offerText,
      promotionType
    } = promotions;

    promotion = {
      startDate,
      endDate,
      promotionID: promotionId,
      promotionName: offerText,
      promotionType,
      clubcardOffer: isClubcardPromotionOnItem(product)
    };
  }

  return {
    onPromotion: hasPromotion(product),
    ...promotion
  };
}

function productAisle(product) {
  return {
    aisleID: getProductAisleId(product)
  };
}

export function productHierarchy(product) {
  return {
    aisle: getProductAisleName(product),
    department: getProductDepartmentName(product),
    superDepartment: getProductSuperDepartmentName(product)
  };
}

export function productSimple(product, currency, placementData) {
  const id = getProductBaseProductId(product) || getProductId(product);
  const isAvailable = getProductIsForSale(product);
  const isDiscontinued = getIsDiscontinued(product);

  const values = {
    '@id': id,
    name: getProductTitle(product),
    brandName: getProductBrandName(product),
    gtin13: getGtin13FromImageUrl(getProductDefaultImageUrl(product)),
    hierarchy: productHierarchy(product),
    productID: id,
    offers: [
      {
        price: Math.abs(
          formatNumber(getCost(product), {
            decimalPlaces: 4,
            thousandsSeparator: ''
          })
        ),
        priceCurrency: currency && currency.symbol
      }
    ],
    isFav: isInFavourites(product),
    'tesco:aisle': productAisle(product),
    'tesco:promotion': pricePromotion(product),
    'tesco:flags': {
      available: isAvailable,
      discontinued: isDiscontinued,
      inStock: isAvailable
    }
  };

  if (getIsSubstitute(product)) {
    values['tesco:flags'].SUB = getIsSubstitute(product);
    values['tesco:flags'].seedLookup = `sub:${
      placementData && placementData.substitutionAPI
        ? placementData.substitutionAPI
        : 'gapi'
    }`;
    values.seedProduct = {
      '@id': getSeedProductId(product),
      gtin13: getGtin13FromImageUrl(getProductDefaultImageUrl(product)),
      name: product.seedProduct?.name
    };
  }

  if (product.isWhyNotTry) {
    values['tesco:flags'].WNT = true;
  }

  if (placementData) {
    values.gridPos = placementData.gridPos;
    values.panelType = placementData.panelType;
    if (placementData.panelName) {
      values.panelName = placementData.panelName;
    }
  }

  const reviewStats = getReviewsStats(product);
  if (reviewStats) {
    values.numberReviews = reviewStats.noOfReviews;
    values.starRating = reviewStats.overallRating;
  }

  return values;
}

export function simplePrice(price, priceCurrency) {
  return {
    price,
    priceCurrency
  };
}

export function priceBreakdown(item, currency) {
  const itemForCost = setNumberOfItems(
    setCustomerUnitChoice(item, UNIT_PCS),
    1
  );
  return {
    unitWeight: getProductAverageWeight(itemForCost),
    unitOfMeasure: getUnit(item),
    unitPrice: simplePrice(
      parseFloat(
        formatNumber(getCost(itemForCost), {
          decimalPlaces: 4,
          thousandsSeparator: ''
        })
      ),
      currency
    )
  };
}
