import { getFeatureVariableStringFromState, updateRegionInKey } from '#/lib/optimizely-manager';
import { multiSearchUiConfig, MultiSearchUiVariants } from '#/experiments/oop-1824/constants';
import { getIsCurrentPage, getIsDesktop, getIsTablet, getIsMobile } from '#/reducers/app';
import { getIsPrediction } from '#/reducers/search';

type paramType = {
  searchTerm?: string;
  isHomePage: boolean;
  improvedMultiSearchEnabled: boolean;
  isMobile: boolean;
  showMultiSearchLink?: boolean;
  typedInputValue?: string;
};

export const getMultiSearchUiVariant = (state: Store): MultiSearchUiVariants => {
  const { featureKey, variationKey, useCustomerId } = multiSearchUiConfig;
  const regionInKey = updateRegionInKey(featureKey, state);
  const featureVariable = getFeatureVariableStringFromState(state, regionInKey, variationKey, { useCustomerId });

  return featureVariable || MultiSearchUiVariants.DEFAULT;
};

export const getShouldShowImprovedMultiSearch = (state: Store): boolean => {
  const featureVariant = getMultiSearchUiVariant(state);
  return featureVariant === MultiSearchUiVariants.NEWUI || featureVariant === MultiSearchUiVariants.NEWUI_WITH_TOOLTIP;
};

const getIsLastItemAdded = ({ trolley: { trolleyUserHistory } }: Store): boolean => {
  const lastItemAdded = (trolleyUserHistory && trolleyUserHistory[trolleyUserHistory.length - 1]) || [];
  return lastItemAdded.length > 0;
};

export const getShouldShowTooltip = (state: Store): boolean => {
  const featureVariant = getMultiSearchUiVariant(state);
  return featureVariant === MultiSearchUiVariants.NEWUI_WITH_TOOLTIP;
};

export const isMultiSearchLinkVisible = (param: paramType): boolean => {
  const { searchTerm, isHomePage, improvedMultiSearchEnabled, isMobile, showMultiSearchLink, typedInputValue } = param;
  const isLinkVisible =
    !isHomePage || !improvedMultiSearchEnabled || !isMobile || (!searchTerm && !typedInputValue && showMultiSearchLink);
  return isLinkVisible ? true : false;
};

export const updateMultiSearchLinkVisibility = (param: paramType): boolean => {
  const { isHomePage, isMobile, improvedMultiSearchEnabled } = param;
  return isHomePage && isMobile && improvedMultiSearchEnabled;
};

export const getIsPNSHeadingVariant = (state: Store): boolean => {
  const featureVariant = getMultiSearchUiVariant(state);
  return featureVariant === MultiSearchUiVariants.PNS_HEADING;
};

export const getShouldShowPredictiveHeading = (state: Store): boolean => {
  const lastItemAdded = getIsLastItemAdded(state);
  const isPrediction = getIsPrediction(state);
  const isPNSHeading = getIsPNSHeadingVariant(state);

  return isPNSHeading && (isPrediction || lastItemAdded);
};

export const shouldShowTooltipOnHomePage = (state: Store): boolean => {
  const featureVariant = getMultiSearchUiVariant(state);
  const isHomePage = getIsCurrentPage(state, ['/', '', '/preview']);
  return featureVariant === MultiSearchUiVariants.NEWUI_WITH_TOOLTIP && isHomePage;
};

export const getIsTooltipEnabled = (state: Store): boolean => {
  const shouldShowTooltip = shouldShowTooltipOnHomePage(state);
  const isDesktopOrTablet = getIsDesktop(state) || getIsTablet(state);
  return shouldShowTooltip && isDesktopOrTablet;
};

export const getIsNotificationBannerEnabled = (state: Store): boolean => {
  const shouldShowTooltip = shouldShowTooltipOnHomePage(state);
  const isMobile = getIsMobile(state);
  return shouldShowTooltip && isMobile;
};
