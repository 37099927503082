import { getFeatureVariableStringFromState, updateRegionInKey } from '#/lib/optimizely-manager';
import { MealsAndRecipesFeature, MealsAndRecipesVariants } from '../constants';

export const getMealsAndRecipesVariant = (state: Store): MealsAndRecipesVariants => {
  const { featureKey, variationKey } = MealsAndRecipesFeature;
  const regionInKey = updateRegionInKey(featureKey, state);
  const featureVariant = getFeatureVariableStringFromState(state, regionInKey, variationKey);

  return featureVariant || MealsAndRecipesVariants.DEFAULT;
};

export const getShouldShowMealsAndRecipes = (state: Store): boolean => {
  return getMealsAndRecipesVariant(state) === MealsAndRecipesVariants.MEALS_AND_RECIPES;
};
