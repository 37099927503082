import React from 'react';
import Pagination from '#/components/pagination';
import DDSPaginationTemplate from '#/experiments/oop-1801/components/pagination/dds-pagination-template';
import PaginationResultText from '#/experiments/oop-1801/components/pagination/dds-pagination-template/pagination-result-text';

const paginationButtonLabels = {
  currentPageLabel: 'navigation:current-results-page',
  changePageLabel: 'navigation:go-to-results-page-x',
};

type PaginationOwnProps = {
  pageNo: number;
  count: number;
  itemsPerPage: number;
  itemsCount: number;
  totalCount: number;
  shouldUseDDSPagination: number;
};

const PaginationWrapperContainer = (props: PaginationOwnProps): JSX.Element => {
  const { pageNo, itemsPerPage, itemsCount, totalCount, shouldUseDDSPagination } = props;

  if (!shouldUseDDSPagination) {
    return (
      <Pagination
        pageNo={pageNo}
        itemsPerPage={itemsPerPage}
        itemsCount={itemsCount}
        totalCount={totalCount}
        paginationLabels={paginationButtonLabels}
      />
    );
  }

  const firstItemIndex = (pageNo - 1) * itemsPerPage;

  return (
    <DDSPaginationTemplate
      currentPage={pageNo}
      delta={5}
      mobileDelta={2}
      pageCount={Math.ceil(totalCount / itemsPerPage)}
      edgeActionClassName="dds-pagination-action"
      paginationResultText={
        <PaginationResultText max={firstItemIndex + itemsCount} min={firstItemIndex + 1} total={totalCount} />
      }
      className="dds-pagination"
    />
  );
};

export default PaginationWrapperContainer;
