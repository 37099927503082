import React from 'react';

export const LowEverydayPriceMatchLogo: React.FC = () => (
  <svg width="56" height="61" viewBox="0 0 56 61" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M30.8759 45.8182C40.0086 42.7996 46.6572 34.3312 46.9801 24.2629H47V23.502V22.7397H46.9801C46.5765 10.112 36.2237 0 23.4993 0C10.5209 0 0 10.5209 0 23.4993C0 33.8972 6.75323 42.7162 16.1109 45.8142L16.1294 42.8684C13.3741 41.8189 10.9007 40.1978 8.8508 38.1492C5.099 34.3961 2.78308 29.2256 2.78308 23.4993C2.78308 17.7744 5.10032 12.6039 8.8508 8.84947C12.6039 5.09768 17.7744 2.78175 23.5007 2.78175C29.2256 2.78175 34.3961 5.09768 38.1505 8.84947C41.9023 12.6026 44.2182 17.7731 44.2182 23.4993C44.2182 29.2256 41.901 34.3948 38.1505 38.1492C36.1033 40.1952 33.6338 41.815 30.8838 42.8644L30.8772 45.8182H30.8759Z"
      fill="#EE1C2E"
    />
    <path
      d="M9.82217 16.0368C10.5434 14.6962 11.4566 13.472 12.5245 12.4041C15.3354 9.59453 19.2116 7.85825 23.4994 7.85825C27.7871 7.85825 31.6633 9.59453 34.4742 12.4041C35.5435 13.4734 36.4553 14.6962 37.1765 16.0368H38.6256C35.8995 10.4309 30.1507 6.56662 23.498 6.56662C16.8454 6.56662 11.0966 10.4309 8.37042 16.0368H9.81952H9.82217Z"
      fill="black"
    />
    <path
      d="M38.2419 28.245C37.4743 30.5728 36.1694 32.6571 34.4742 34.3537C32.8385 35.9881 30.8428 37.2599 28.6142 38.038C28.605 38.0407 28.5825 38.0394 28.5878 38.0473C28.773 38.3278 28.7836 39.3429 28.7836 39.3429C33.9567 37.6318 38.0142 33.4763 39.593 28.245H38.2419Z"
      fill="black"
    />
    <path
      d="M12.5245 34.3537C10.4322 32.2601 8.9355 29.5763 8.30822 26.5722H6.99409C8.14941 32.5791 12.4994 37.4491 18.2085 39.3416C18.2111 39.3416 18.2177 39.3482 18.2177 39.3442C18.2627 38.2286 18.3898 38.0394 18.3898 38.0394C18.3818 38.0367 18.3726 38.0341 18.3646 38.0301C16.144 37.2519 14.1536 35.9828 12.5232 34.3524L12.5245 34.3537Z"
      fill="black"
    />
    <path d="M17.6645 15.8727H20.1062V16.8335H16.623V11.2289H17.6645V15.8727Z" fill="black" />
    <path
      d="M20.527 13.6745C20.7031 13.3516 20.9532 13.0975 21.2761 12.9136C21.599 12.7296 21.9682 12.637 22.3851 12.637C22.8019 12.637 23.1712 12.7296 23.4941 12.9136C23.817 13.0975 24.0658 13.3516 24.2431 13.6745C24.4191 13.9974 24.5065 14.3614 24.5065 14.7676C24.5065 15.1739 24.4191 15.5379 24.2431 15.8608C24.0671 16.1837 23.817 16.4364 23.4941 16.6217C23.1712 16.8057 22.8019 16.897 22.3851 16.897C21.9682 16.897 21.599 16.8057 21.2761 16.6217C20.9532 16.4378 20.7031 16.1837 20.527 15.8608C20.351 15.5379 20.2637 15.1739 20.2637 14.7676C20.2637 14.3614 20.351 13.9974 20.527 13.6745ZM21.5805 15.6477C21.7856 15.8727 22.0542 15.9838 22.3851 15.9838C22.7159 15.9838 22.9846 15.8713 23.1897 15.6477C23.3948 15.424 23.498 15.1303 23.498 14.7676C23.498 14.405 23.3948 14.1112 23.1897 13.8876C22.9846 13.6639 22.7159 13.5515 22.3851 13.5515C22.0542 13.5515 21.7856 13.6639 21.5805 13.8876C21.3753 14.1112 21.2721 14.405 21.2721 14.7676C21.2721 15.1303 21.3753 15.424 21.5805 15.6477Z"
      fill="black"
    />
    <path
      d="M29.1687 16.8334H28.208L27.5119 14.1271L26.8158 16.8334H25.855L24.566 12.7018H25.5347L26.3182 15.4082L27.0315 12.7018H27.9923L28.7056 15.4082L29.489 12.7018H30.4577L29.1687 16.8334Z"
      fill="black"
    />
    <path
      d="M8.61654 18.931H5.58202V20.4026H8.01572V21.6042H5.58202V23.5377H8.71579V24.738H4.27981V17.7294H8.61654V18.931Z"
      fill="black"
    />
    <path
      d="M8.73168 19.5715H9.9836L11.2051 23.0269L12.4266 19.5715H13.6785L11.7662 24.738H10.644L8.73168 19.5715Z"
      fill="black"
    />
    <path
      d="M18.7126 22.5558H14.8973C14.9635 22.9369 15.121 23.2254 15.3684 23.4226C15.6159 23.6198 15.9163 23.7177 16.2697 23.7177C16.5635 23.7177 16.8083 23.6555 17.0055 23.5324C17.2027 23.4093 17.3906 23.2201 17.5719 22.966L18.6226 23.5377C18.3686 23.9519 18.047 24.2682 17.6566 24.4892C17.2662 24.7089 16.7805 24.8201 16.1995 24.8201C15.6517 24.8201 15.1832 24.6917 14.7928 24.435C14.4024 24.1782 14.1099 23.8461 13.9167 23.4385C13.7235 23.0322 13.6269 22.6047 13.6269 22.1574C13.6269 21.7101 13.7288 21.2827 13.9326 20.8764C14.1364 20.4688 14.4315 20.1366 14.8192 19.8799C15.207 19.6231 15.6636 19.4948 16.1916 19.4948C16.7196 19.4948 17.1828 19.6152 17.5626 19.8547C17.9438 20.0956 18.2296 20.4106 18.4241 20.801C18.6174 21.1914 18.714 21.6069 18.714 22.0476V22.5584L18.7126 22.5558ZM17.4409 21.5751C17.3945 21.2813 17.2582 21.0418 17.0346 20.8539C16.8109 20.6673 16.5291 20.5733 16.1889 20.5733C15.9018 20.5733 15.645 20.662 15.4174 20.838C15.1911 21.0153 15.0336 21.2602 14.9463 21.5738H17.4395L17.4409 21.5751Z"
      fill="black"
    />
    <path
      d="M19.3849 19.5715H20.6263V20.3523C20.7732 20.0718 20.9809 19.8587 21.2469 19.7118C21.5142 19.5649 21.8107 19.4921 22.1376 19.4921C22.4644 19.4921 22.745 19.5689 22.9382 19.7224L22.5174 20.7732C22.344 20.6805 22.1601 20.6329 21.9668 20.6329C21.5791 20.6329 21.2588 20.7996 21.0061 21.1331C20.752 21.4666 20.6249 21.9814 20.6249 22.6749V24.738H19.3836V19.5715H19.3849Z"
      fill="black"
    />
    <path
      d="M24.8518 24.4178L22.9792 19.5715H24.2312L25.4434 22.8562L26.5841 19.5715H27.8255L25.1827 26.7813H23.981L24.8518 24.4178Z"
      fill="black"
    />
    <path
      d="M28.8074 24.4786C28.4607 24.251 28.1881 23.9347 27.9909 23.5271C27.7937 23.1195 27.6958 22.663 27.6958 22.1548C27.6958 21.6466 27.7911 21.1821 27.9816 20.7785C28.1722 20.3748 28.4369 20.0585 28.7783 19.8322C29.1184 19.6059 29.5088 19.4921 29.9495 19.4921C30.2962 19.4921 30.6006 19.5543 30.86 19.6774C31.1207 19.8005 31.3205 19.9765 31.4608 20.2028V17.65H32.7021V24.7393H31.4608V24.1187C31.3139 24.3251 31.1141 24.4945 30.86 24.6242C30.6059 24.7539 30.3055 24.8201 29.9588 24.8201C29.5379 24.8201 29.1541 24.7063 28.8074 24.48V24.4786ZM31.1763 23.2664C31.4198 22.9925 31.5415 22.6219 31.5415 22.1548C31.5415 21.6876 31.4198 21.3171 31.1763 21.0431C30.9328 20.7692 30.6271 20.6329 30.2605 20.6329C29.8939 20.6329 29.5882 20.7705 29.3447 21.0431C29.1012 21.3171 28.9795 21.6876 28.9795 22.1548C28.9795 22.6219 29.1012 22.9925 29.3447 23.2664C29.5882 23.5404 29.8939 23.6767 30.2605 23.6767C30.6271 23.6767 30.9328 23.5404 31.1763 23.2664Z"
      fill="black"
    />
    <path
      d="M34.2624 24.6388C33.9951 24.5183 33.782 24.3437 33.6219 24.1134C33.4618 23.8831 33.3824 23.6079 33.3824 23.2876C33.3824 22.7807 33.5729 22.3718 33.9527 22.0608C34.3339 21.7511 34.8473 21.595 35.4945 21.595H36.6061V21.415C36.6061 21.1543 36.5161 20.9465 36.3361 20.789C36.1562 20.6329 35.9127 20.5535 35.6056 20.5535C35.3582 20.5535 35.1345 20.6183 34.9347 20.7493C34.7349 20.879 34.584 21.0312 34.4834 21.2046L33.5319 20.6342C33.7119 20.3073 33.9792 20.0347 34.3325 19.8177C34.6859 19.6006 35.1094 19.4921 35.6043 19.4921C36.3123 19.4921 36.8629 19.6615 37.2559 20.003C37.6503 20.3431 37.8461 20.834 37.8461 21.4746V24.7393H36.8245L36.6538 24.079C36.487 24.3066 36.2753 24.4866 36.0172 24.6189C35.7605 24.7526 35.4574 24.8187 35.1107 24.8187C34.8103 24.8187 34.5271 24.7592 34.2598 24.6388H34.2624ZM36.2607 23.457C36.491 23.2572 36.6061 23.0097 36.6061 22.7159V22.5756H35.6255C35.3383 22.5756 35.1094 22.6312 34.94 22.741C34.7693 22.8509 34.6846 23.0031 34.6846 23.1963C34.6846 23.3763 34.7468 23.5152 34.8698 23.6118C34.9929 23.7084 35.1755 23.7574 35.4151 23.7574C35.7486 23.7574 36.0304 23.6568 36.2607 23.457V23.457Z"
      fill="black"
    />
    <path
      d="M39.7571 24.4178L37.8845 19.5715H39.1364L40.3473 22.8562L41.4894 19.5715H42.7308L40.088 26.7813H38.8863L39.7571 24.4178Z"
      fill="black"
    />
    <path
      d="M13.652 33.1164H12.6185V27.5515H14.6618C15.7985 27.5515 16.4589 28.3072 16.4589 29.2772C16.4589 30.2473 15.7985 31.0029 14.6618 31.0029H13.652V33.1177V33.1164ZM15.4095 29.2759C15.4095 28.7505 15.076 28.5044 14.5824 28.5044H13.652V30.0461H14.5824C15.0747 30.0461 15.4095 29.8 15.4095 29.2746V29.2759Z"
      fill="black"
    />
    <path
      d="M17.0981 29.0139H18.084V29.6345C18.3222 29.1899 18.7682 28.9503 19.2843 28.9503C19.5464 28.9503 19.7687 29.0139 19.9209 29.133L19.5874 29.968C19.4524 29.8966 19.3095 29.8569 19.1507 29.8569C18.5856 29.8569 18.0854 30.3015 18.0854 31.4793V33.1177H17.0994V29.0152L17.0981 29.0139Z"
      fill="black"
    />
    <path
      d="M20.9703 27.312C21.3356 27.312 21.6069 27.5899 21.6069 27.9485C21.6069 28.3072 21.3369 28.5851 20.9703 28.5851C20.6038 28.5851 20.3338 28.3072 20.3338 27.9485C20.3338 27.5899 20.6038 27.312 20.9703 27.312ZM21.4626 33.1164H20.4767V29.0139H21.4626V33.1164Z"
      fill="black"
    />
    <path
      d="M22.2355 31.0651C22.2355 29.8251 23.0785 28.9503 24.3026 28.9503C25.1774 28.9503 25.8205 29.2997 26.1553 30.0713L25.2568 30.4762C25.0583 30.0792 24.7565 29.8555 24.3026 29.8555C23.6899 29.8555 23.2373 30.3399 23.2373 31.0638C23.2373 31.7877 23.6899 32.272 24.3026 32.272C24.7552 32.272 25.0583 32.0497 25.2568 31.6514L26.1553 32.0563C25.8219 32.8279 25.1774 33.1772 24.3026 33.1772C23.0785 33.1772 22.2355 32.3025 22.2355 31.0625V31.0651Z"
      fill="black"
    />
    <path
      d="M30.5874 31.3827H27.5581C27.6693 32.0113 28.1153 32.3051 28.6473 32.3051C29.1316 32.3051 29.4109 32.0907 29.6808 31.7083L30.5159 32.1609C30.1348 32.7736 29.5617 33.1786 28.5917 33.1786C27.2167 33.1786 26.5484 32.0894 26.5484 31.0638C26.5484 30.0382 27.2723 28.949 28.5838 28.949C29.8952 28.949 30.5874 29.9667 30.5874 30.9765V31.3814V31.3827ZM29.5776 30.6033C29.5062 30.1586 29.1409 29.8079 28.5838 29.8079C28.1391 29.8079 27.7408 30.0938 27.5978 30.6033H29.5776V30.6033Z"
      fill="black"
    />
    <path
      d="M31.0294 32.2416L31.7374 31.6448C31.9677 32.0577 32.2866 32.3051 32.7551 32.3051C33.0965 32.3051 33.3281 32.1463 33.3281 31.8922C33.3281 31.6381 33.0105 31.4952 32.4613 31.343C31.785 31.1604 31.253 30.7713 31.253 30.1507C31.253 29.4665 31.8975 28.9503 32.7154 28.9503C33.4472 28.9503 33.876 29.3156 34.1142 29.7139L33.4776 30.2631C33.2553 29.9058 32.9602 29.7933 32.7061 29.7933C32.3726 29.7933 32.1728 29.9442 32.1728 30.1189C32.1728 30.365 32.3951 30.4762 33.0634 30.6522C33.7397 30.8269 34.2796 31.1855 34.2796 31.8922C34.2796 32.5989 33.7225 33.1799 32.6902 33.1799C31.9028 33.1799 31.3311 32.8464 31.0281 32.2416H31.0294Z"
      fill="black"
    />
    <path
      d="M23.5046 47.8549H24.2007C24.3979 47.8549 24.4045 47.8443 24.3609 47.6498C24.2643 47.2263 24.1796 46.8015 24.0724 46.382C24.0155 46.161 24.0287 46.0207 24.2391 45.8579C24.7473 45.4675 24.7658 44.7013 24.3212 44.2315C23.8792 43.7643 23.1288 43.763 22.6855 44.2275C22.2395 44.696 22.2514 45.4636 22.7609 45.854C22.9898 46.0286 22.9925 46.1822 22.9237 46.4018C22.9171 46.423 22.9118 46.4429 22.9078 46.464C22.8165 46.8743 22.7238 47.2859 22.6339 47.6961C22.6021 47.8443 22.614 47.8562 22.7715 47.8562C23.0163 47.8562 23.2611 47.8562 23.506 47.8562M23.4954 41.1361C24.2179 41.1361 24.9392 41.1334 25.6604 41.1374C25.7689 41.1374 25.8166 41.1096 25.8139 40.9932C25.8086 40.62 25.8139 40.2454 25.81 39.8723C25.806 39.5771 25.7517 39.29 25.634 39.0187C25.1602 37.9229 24.0406 37.3803 22.88 37.6807C22.0357 37.8991 21.329 38.668 21.2337 39.5374C21.1808 40.0139 21.2072 40.4982 21.1914 40.9786C21.1874 41.1056 21.235 41.1387 21.3568 41.1387C22.0701 41.1334 22.7834 41.1361 23.4967 41.1361M23.4967 50.6023C22.217 50.6023 20.9359 50.6023 19.6562 50.6023C18.6849 50.6023 17.9186 50.0041 17.699 49.0592C17.6619 48.903 17.654 48.735 17.654 48.5735C17.6513 46.7856 17.6566 44.9977 17.65 43.2112C17.6473 42.4899 17.9028 41.9037 18.4943 41.4815C18.8132 41.2539 19.1758 41.1427 19.5689 41.1374C19.7396 41.1348 19.7383 41.1321 19.7396 40.9627C19.7436 40.5035 19.7198 40.0403 19.7595 39.5851C19.9408 37.4822 21.7631 35.9683 23.862 36.1549C25.7584 36.3243 27.2578 37.96 27.2617 39.867C27.2617 40.2282 27.2617 40.5895 27.2617 40.9495C27.2617 41.1321 27.2617 41.1308 27.4457 41.1387C28.2159 41.1731 28.7796 41.5357 29.1436 42.212C29.3024 42.5071 29.3513 42.8274 29.3513 43.1595C29.3513 44.9779 29.3553 46.7949 29.3513 48.6132C29.3487 49.5965 28.732 50.3601 27.7845 50.5639C27.6429 50.5943 27.4946 50.6023 27.3491 50.6023C26.0641 50.6049 24.7804 50.6036 23.4954 50.6036"
      fill="black"
    />
  </svg>
);
