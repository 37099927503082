import React from 'react';
import PropTypes from 'prop-types';
import SafeForm from '../../../../../shared/safe-form';

const PickerNotesDropdown = ({
  action,
  children,
  label,
  ariaLabel,
  maxLength,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  productId,
  returnUrl,
  submitButton,
  substitutionOption,
  value
}) => {
  const hasChildren = React.Children.count(children) > 0;
  const elementIdSuffix = `${productId}-${hasChildren ? 'js' : 'nojs'}`;

  return (
    <div>
      <div>
        <div className="tile-content--lower-line tile-content--lower-line-left" />
        <div className="tile-content--lower-line tile-content--lower-arrow" />
        <div className="tile-content--lower-line tile-content--lower-line-right" />
      </div>
      <div className="tile-content-lower-note-instructions">
        <label htmlFor={`notes-${elementIdSuffix}`}>
          <span aria-hidden="true">{label}</span>
          <span className="visually-hidden">{ariaLabel}</span>
        </label>
        <SafeForm
          action={action}
          key={`safeForm-${elementIdSuffix}`}
          method="post"
        >
          <div className="tile-content-lower-note-box">
            <input
              id={`notes-${elementIdSuffix}`}
              type="text"
              name="pickerNotes"
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              onFocus={onFocus}
              placeholder={placeholder}
              maxLength={maxLength}
            />
            <input type="hidden" name="productID" value={productId} />
            <input
              type="hidden"
              name="substitutionOption"
              value={substitutionOption}
            />
            <input
              type="hidden"
              id="returnUrl"
              name="returnUrl"
              value={returnUrl}
            />
          </div>
          {hasChildren ? (
            children
          ) : (
            <button
              type="submit"
              className="button small button-secondary tile-content--lower-update-non-js"
            >
              {submitButton}
            </button>
          )}
        </SafeForm>
      </div>
    </div>
  );
};

PickerNotesDropdown.propTypes = {
  action: PropTypes.string.isRequired,
  children: PropTypes.node,
  label: PropTypes.string.isRequired,
  maxLength: PropTypes.number.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  productId: PropTypes.string.isRequired,
  returnUrl: PropTypes.string.isRequired,
  submitButton: PropTypes.string.isRequired,
  substitutionOption: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};

export default PickerNotesDropdown;
