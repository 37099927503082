import { SYNC_PRODUCT_WITH_TROLLEY, UPDATE_PRODUCT_DETAILS_RESULT } from '#/constants/action-types';
import { Dispatch, GetStore } from '#/custom-typings/redux-store/common';
import { createItem } from '#/lib/records';
import { Item } from '#/lib/records/item';
import { findAndSetTrolleyItems, getById } from '#/lib/records/product-utils';
import { getProductId } from '#/selectors/item';
import { getProductItem } from '#/selectors/product-details';
import { getItems } from '#/selectors/trolley';

type ItemInfo = {
  item: Item;
  productId: string;
  trolleyItems: Array<Item>;
};

export function transferTrolleyItemProperties({ productId, item, trolleyItems }: ItemInfo): Item {
  const transformedItems = findAndSetTrolleyItems([item], trolleyItems);

  return getById(transformedItems, productId) || item;
}

export const updateProductDetails = resourceData => {
  return (dispatch: Dispatch, getState: GetStore): void => {
    const state = getState();
    const trolleyItems = getItems(state);
    const {
      aisleId,
      aisleName,
      departmentId,
      departmentName,
      product,
      promotions,
      shelfName,
      superDepartmentId,
      superDepartmentName,
    } = resourceData;
    const productId = product.id;
    const item = createItem({
      product: {
        ...product,
        aisleId,
        aisleName,
        departmentId,
        departmentName,
        shelfName,
        superDepartmentId,
        superDepartmentName,
      },
      promotions,
    });

    const transformedItem = transferTrolleyItemProperties({
      productId,
      item,
      trolleyItems,
    });

    dispatch({
      type: UPDATE_PRODUCT_DETAILS_RESULT,
      payload: {
        ...resourceData,
        item: transformedItem,
      },
    });
  };
};

export const syncProductWithTrolley = () => {
  return (dispatch: Dispatch, getState: GetStore): void => {
    const state = getState();
    const item = getProductItem(state);

    if (item) {
      const trolleyItems = getItems(state);
      const productId = getProductId(item);
      const transformedItem = transferTrolleyItemProperties({
        productId,
        item,
        trolleyItems,
      });

      dispatch({
        type: SYNC_PRODUCT_WITH_TROLLEY,
        payload: {
          item: transformedItem,
        },
      });
    }
  };
};
