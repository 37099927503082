import { combineReducers } from 'redux';
import addresses, * as fromAddresses from './addresses';
import ui, * as fromUI from './ui';
import taxonomy, * as fromTaxonomy from './taxonomy';
import results, * as fromResults from './results';
import ratingsReviews, * as fromRatingsReviews from './ratings-reviews';
import filter, * as fromFilter from './filter';
import slot, * as fromSlot from './slot';
import trolley, * as fromTrolley from './trolley';
import instructions, * as fromInstructions from './instructions';
import interrupts, * as fromInterrupts from './interrupts';
import dfp from './dfp';
import customer, * as fromCustomer from './customer';
import experiments, * as fromExperiments from './experiments';
import user, * as fromUser from './user';
import app, * as fromApp from './app';
import recommendations, * as fromRecommendations from './recommendations';
import promotedOffers, * as fromPromotedOffers from './promoted-offers';
import order, {
  getDefaultStateFromProps as getDefaultOrderStateFromProps
} from './order';
import orderListDetails, {
  getDefaultStateFromProps as getDefaultOrderListDetailsStateFromProps
} from './order-list-details';
import search, * as fromSearch from './search';
import addressSelector, * as fromAddressSelector from './address-selector';
import accountPage, * as fromAccountPage from './account-page';
import resources, * as fromResources from './resources';
import productDetails, * as fromProductDetails from './product-details';
import form, * as fromFormReducer from './form';
import buylist, * as fromBuylistReducer from './buylist';
import location, {
  getDefaultStateFromProps as getDefaultLocationStateFromProps
} from './location';
import lastOrder, {
  getDefaultStateFromProps as getDefaultLastOrderStateFromProps
} from '#/reducers/last-order';
import subscriptions, {
  getDefaultStateFromProps as getDefaultSubscriptionsStateFromProps
} from '#/reducers/subscriptions';
import browseEntrypoints, {
  getDefaultStateFromProps as getDefaultBrowseEntrypointsStateFromProps
} from '#/experiments/oop-1894/reducers/browseEntrypoints';

export default combineReducers({
  accountPage,
  addresses,
  addressSelector,
  app,
  browseEntrypoints,
  buylist,
  customer,
  promotedOffers,
  dfp,
  experiments,
  resources,
  filter,
  form,
  instructions,
  interrupts,
  order,
  orderListDetails,
  productDetails,
  ratingsReviews,
  recommendations,
  results,
  search,
  slot,
  subscriptions,
  taxonomy,
  trolley,
  ui,
  user,
  location,
  lastOrder
});

/**
 * When called from the server side (from `/web/lib/render/spa-server.js`)
 * the fetched resources are passed in the `props` argument,
 * along with `locals.props`. These generated default states
 * are used to hydrate the server-side redux store.
 */
export const getDefaultStateFromProps = props => ({
  accountPage: fromAccountPage.getDefaultStateFromProps(props),
  addresses: fromAddresses.getDefaultStateFromProps(props),
  addressSelector: fromAddressSelector.getDefaultStateFromProps(props),
  app: fromApp.getDefaultStateFromProps(props),
  browseEntrypoints: getDefaultBrowseEntrypointsStateFromProps(props),
  buylist: fromBuylistReducer.getDefaultStateFromProps(props),
  customer: fromCustomer.getDefaultStateFromProps(props),
  experiments: fromExperiments.getDefaultStateFromProps(props),
  form: fromFormReducer.getDefaultStateFromProps(props),
  filter: fromFilter.getDefaultStateFromProps(props),
  instructions: fromInstructions.getDefaultStateFromProps(props),
  interrupts: fromInterrupts.getDefaultStateFromProps(props),
  order: getDefaultOrderStateFromProps(props),
  orderListDetails: getDefaultOrderListDetailsStateFromProps(props),
  productDetails: fromProductDetails.getDefaultStateFromProps(props),
  promotedOffers: fromPromotedOffers.getDefaultStateFromProps(props),
  ratingsReviews: fromRatingsReviews.getDefaultStateFromProps(props),
  recommendations: fromRecommendations.getDefaultStateFromProps(props),
  results: fromResults.getDefaultStateFromProps(props),
  resources: fromResources.getDefaultStateFromProps(props),
  search: fromSearch.getDefaultStateFromProps(props),
  slot: fromSlot.getDefaultStateFromProps(props),
  subscriptions: getDefaultSubscriptionsStateFromProps(props),
  taxonomy: fromTaxonomy.getDefaultStateFromProps(props),
  trolley: fromTrolley.getDefaultStateFromProps(props),
  ui: fromUI.getDefaultStateFromProps(props),
  user: fromUser.getDefaultStateFromProps(props),
  location: getDefaultLocationStateFromProps(props),
  lastOrder: getDefaultLastOrderStateFromProps(props)
});
