import styled, { css } from 'styled-components';
import { media } from '@ddsweb/foundation';
import { Heading } from '@ddsweb/typography';
import SafeForm from '#/components/shared/safe-form';
import { fontSize, spacing } from '@ddsweb/selectors';
import { Props } from '#/components/slots/slot-selector/group-selector';
import React from 'react';

type SlotUIReskinStyle = {
  isSlotUIReskinV2Variant: boolean;
};

// Extracting the unused var in order to prevent to being passed as DOM attribute and causing errors
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const StyledSafeForm = styled(({ isSlotUIReskinV2Variant, ...rest }: Props) => <SafeForm {...rest} />)`
  border: 1px solid #b4b4b4;
  margin-bottom: ${spacing.lg};
  ${({ isSlotUIReskinV2Variant }: SlotUIReskinStyle): string =>
    isSlotUIReskinV2Variant
      ? css`
          border-radius: 0;
          padding: ${spacing.lg} ${spacing.md};
        `
      : css`
          border-radius: 6px;
          padding: ${spacing.sm};
        `}
  .ddsweb-form-group {
    &__children {
      display: flex;
      flex-direction: column;
      ${({ isSlotUIReskinV2Variant }: SlotUIReskinStyle): string =>
        isSlotUIReskinV2Variant &&
        css`
          margin-top: ${spacing.md};
        `};
      .ddsweb-radio-button-group {
        &__radio-button-with-label:first-child {
          margin-right: ${spacing.lg};
          ${({ isSlotUIReskinV2Variant }: SlotUIReskinStyle): string =>
            isSlotUIReskinV2Variant &&
            css`
              ${media.aboveMobileLarge`
              margin: 0;
              `}
            `};
        }
      }
      ${media.aboveMobileLarge`
        flex-direction: row;
      `}
    }
  }
  .ddsweb-radio-button-with-label &__label-with-description {
    margin-top: 0;
  }
  .ddsweb-radio-button-with-label {
    &__description,
    &__label {
      font-size:  ${({ isSlotUIReskinV2Variant }: SlotUIReskinStyle): string | typeof fontSize.xs =>
        isSlotUIReskinV2Variant ? fontSize.xs : fontSize.xx};
  }
`;

export const StyledHeading = styled(Heading)`
  && {
    font-size: ${fontSize.xx};
    font-weight: normal;
    line-height: ${spacing.lg};
  }
`;
