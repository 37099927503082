import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { SectionMessage } from '../../cms-driven/section-message';
import Link from '../../link';
import helpers from '../../../lib/decorators/helpers';

@helpers(['t'])
class SectionDividerWidget extends PureComponent {
  static propTypes = {
    link: PropTypes.string,
    t: PropTypes.func,
    title: PropTypes.string.isRequired
  };
  render() {
    const link = this.props.link ? (
      <span className="section-divider-widget__link">
        <Link href={this.props.link} rel="nofollow">
          {this.props.t('common:view-all')}
          <span className="visually-hidden">{this.props.title}</span>
        </Link>
      </span>
    ) : null;

    return (
      <div className="section-divider-widget">
        <div className="section-divider-widget--section-message">
          <SectionMessage message={this.props.title} size="large" />
        </div>
        {link}
      </div>
    );
  }
}

export default SectionDividerWidget;
