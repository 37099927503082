import { Dispatch, GetStore } from '#/custom-typings/redux-store/common';
import { BROWSE_ENTRYPOINTS } from '#/experiments/oop-1894/constants/spa-resource';
import { MAXIMUM_STAMPS_TO_SHOW } from '#/experiments/oop-1894/constants';
import { getBrowseEntrypointsResource } from '#/experiments/oop-1894/selectors/resources';
import { getTwoRandomizedItemsFromArr } from '#/experiments/oop-1894/utils/random';
import { updateBrowseEntrypoints } from '#/experiments/oop-1894/actions/update-browseEntrypoints';

export const type = BROWSE_ENTRYPOINTS;
export const params = ['pageName', 'preview', 'dfpReportingRequired', 'deliveryType'];

export function handler(dispatch: Dispatch, getState: GetStore): void {
  const state = getState();
  const { data = null } = getBrowseEntrypointsResource(state) || [];
  let allStamps;

  if (!data) {
    return;
  }

  allStamps = data || [];

  if (allStamps.length > MAXIMUM_STAMPS_TO_SHOW) {
    allStamps = getTwoRandomizedItemsFromArr(allStamps);
  }

  dispatch(updateBrowseEntrypoints(allStamps));
}
