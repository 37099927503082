import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const AmountAdjustButton = props => {
  const { disabled, name, iconClassName, kgUnit, label, value } = props;
  const buttonClassName = kgUnit ? 'weight-adjust' : 'quantity-adjust';
  const iconCls = 'mini-tile--amount-control-icon ' + iconClassName;

  return (
    <button
      className={classNames(
        `button-secondary amount-adjust-button ${buttonClassName}`,
        props.className,
        {
          disabled: disabled
        }
      )}
      data-auto={iconClassName}
      name={name}
      type="submit"
      disabled={disabled}
      onClick={props.onClick}
      value={value}
    >
      <span className="visually-hidden">{label}</span>
      {!disabled && <span className={iconCls} />}
      {disabled && (
        <span className={iconCls + ' ' + iconClassName + '--gray'} />
      )}
    </button>
  );
};

AmountAdjustButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  iconClassName: PropTypes.string,
  kgUnit: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

AmountAdjustButton.defaultProps = {
  className: '',
  iconClassName: 'icon',
  disabled: false,
  kgUnit: false,
  name: null,
  value: null
};

export default AmountAdjustButton;
