import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import FavoritesPageTabs from '#/components/favorites/favorites-page-tabs';
import { DfpContainer } from '#/components/cms-driven';
import { connect } from '#/lib/render/connect-deep-compare';
import helpers from '#/lib/decorators/helpers';
import { DfpPanelWrappper } from '#/components/cms-driven/dfp-panel-wrapper';
import dfpTargetingCreator from '#/lib/dfp-targeting-creator';
import { getPageNo } from '#/reducers/results';
import { getUser } from '#/reducers/user';
import { getSelectedFavoritesTab } from '#/reducers/ui';
import { receiveFavoritesData } from '#/actions/ui-action-creators';
import { isOnFavouritesPage } from '#/lib/favorites-helpers';
import { LAST_ORDER_TAB } from '#/constants/favorites';
import { getFavPersonalisedOffersConfig } from '#/experiments/oop-1557/selectors';
import { getShouldShowOffersTab } from '#/experiments/oop-1557/helpers';

const setHistoryListener = (() => {
  let cancelListener;

  return props => {
    if (typeof browserHistory !== 'undefined') {
      cancelListener?.();
      cancelListener = browserHistory.listen(location => {
        if (isOnFavouritesPage(location.pathname)) {
          props.receiveFavoritesData(location.query?.tab);
        }
      });
    }
    return cancelListener;
  };
})();

export const mapStateToProps = state => {
  const selectedFavoritesTab = getSelectedFavoritesTab(state);

  const shouldRenderDFPBanner = selectedFavoritesTab !== LAST_ORDER_TAB;
  const showOffersForYouTab = getShouldShowOffersTab(
    getFavPersonalisedOffersConfig(state).variation
  );

  return {
    dfpSeed: `${selectedFavoritesTab}-${getPageNo(state)}`,
    selectedFavoritesTab,
    user: getUser(state),
    shouldRenderDFPBanner,
    showOffersForYouTab
  };
};

@helpers(['c', 't'])
@connect(mapStateToProps, { receiveFavoritesData })
export default class FavoritesLayoutSPA extends React.Component {
  static propTypes = {
    c: PropTypes.func.isRequired,
    children: PropTypes.node,
    dfpSeed: PropTypes.string.isRequired,
    selectedFavoritesTab: PropTypes.string.isRequired,
    shouldRenderDFPBanner: PropTypes.bool.isRequired,
    showOffersForYouTab: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    user: PropTypes.object
  };

  constructor(props) {
    super(props);
    const { c: config, showOffersForYouTab } = props;
    this.config = {
      usualsEnabled: config('usualsEnabled'),
      newFavNavEnabled: config('newFavNavEnabled'),
      lastOrderEnabled: config('lastOrderEnabled'),
      offersForYouEnabled: showOffersForYouTab
    };
  }

  componentDidMount() {
    setHistoryListener(this.props);
  }

  renderDfpBanner = pos => {
    const { dfpSeed } = this.props;
    const targeting = { pos };

    return (
      <div className={`favourites-dfp-slot favourites-dfp-slot--${pos}`}>
        <DfpPanelWrappper
          classes="favourites-dfp"
          seed={dfpSeed}
          targeting={targeting}
        />
      </div>
    );
  };

  render() {
    const {
      user,
      t: translate,
      selectedFavoritesTab,
      children,
      shouldRenderDFPBanner
    } = this.props;
    const targeting = shouldRenderDFPBanner && dfpTargetingCreator({ user });
    const pageId = 'favourites';

    return (
      <div className="my-favorites">
        {shouldRenderDFPBanner && (
          <DfpContainer pageId={pageId} targeting={targeting} />
        )}
        {shouldRenderDFPBanner && this.renderDfpBanner('top')}
        <h1 className="heading heading-label">
          {translate('product-list:my-favorites')}
        </h1>
        <FavoritesPageTabs activeTab={selectedFavoritesTab} {...this.config}>
          {children}
        </FavoritesPageTabs>

        {shouldRenderDFPBanner && this.renderDfpBanner('bottom')}
      </div>
    );
  }
}
