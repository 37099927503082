import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { addApmData } from '#/lib/apm';
import ErrorTemplate from './error-template';
import helpers from '../../lib/decorators/helpers';
import SafelyOutputString from '#/components/safely-output-string';

@helpers(['asset', 'c', 't', 'l'])
export default class Error extends PureComponent {
  static propTypes = {
    asset: PropTypes.func,
    c: PropTypes.func,
    l: PropTypes.func,
    t: PropTypes.func,
    traceId: PropTypes.string,
    type: PropTypes.number
  };

  componentDidMount() {
    addApmData('statusCode', this.props.type || 500);
  }

  componentWillUnmount() {
    // this clears the statusCode attribute as browser interaction events will persist the value across subsequent SPA transitions
    // stop gap until we have a function on apm pea to clear a previously set attribute
    addApmData('statusCode', '');
  }

  render() {
    const errorKeys = {
      400: '400',
      404: '404',
      500: '500',
      504: '500'
    };

    const errorKey = errorKeys[this.props.type] || errorKeys[500];
    const headingError = ` (${this.props.type})`;
    const translate = this.props.t;

    return (
      <main className="main error-page" role="main">
        <ErrorTemplate
          additionalInformation={
            <SafelyOutputString>
              {translate(`error:${errorKey}.additionalInformation`, {
                link: this.props.l('/', 'grocery')
              })}
            </SafelyOutputString>
          }
          classname="error-container"
          explanation={translate(`error:${errorKey}.explanation`)}
          heading={`${translate(`error:${errorKey}.heading`)}${headingError}`}
          diagnostics={
            this.props.traceId
              ? translate(
                  `error:${errorKey}.diagnostics`,
                  {
                    traceId: this.props.traceId
                  },
                  { returnNullIfFalsy: true }
                )
              : null
          }
        />
      </main>
    );
  }
}
