import { addApmData } from '#/lib/apm';
import { Item } from '#/lib/records/item';
import { getAdId } from '#/selectors/item';

export const logAllSponsoredMedia = (items: []): void => {
  const allAdIds: string[] = [];

  items.forEach(item => {
    const adId = getAdId(item);

    if (adId) allAdIds.push(adId);
  });

  if (allAdIds.length) {
    addApmData('all_adIds', allAdIds.join(','));
  } else {
    clearLoggedSponsoredMedia();
  }
};

export const logSponsoredMedia = (item: Item): void => {
  const adId = getAdId(item);

  addApmData('adId', adId ? adId : false);
};

export const clearLoggedSponsoredMedia = (): void => {
  addApmData('all_adIds', false);
};
