import React, { ChangeEvent, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { getTrolleyShoppingMethod } from '#/selectors/trolley';
import { getDefaultSlotsPath } from '#/reducers/slot';
import { getHasError, getInstructions, getOldInstructions, getStatus } from '#/reducers/instructions';
import { instructionsChanged, refreshInstructions } from '#/actions/instructions-action-creators';
import { DELIVERY, ShoppingMethod } from '#/constants/shopping-methods';
import { basicEvent } from '#/analytics/types/basic';
import { SAVED, SAVING } from '#/constants/instruction-status';
import {
  ErrorText,
  SavedText,
  SavingText,
  StatusDiv,
  StyledBodyText,
  StyledIcon,
  StyledOrderInstructionsWrapper,
  StyledSignpost,
  StyledSpinner,
  StyledTextareaGroup,
  ErrorDiv,
} from './styled';
import { GREEN } from '@beans/constants';
import { NOW } from '#/analytics/constants';
import { InlineLink } from '@beans/link';
import helpers from '#/lib/decorators/helpers';
import analyticsBus from '#/analytics/analyticsBus';

type HelperProps = {
  f: (featureKey?: string | string[] | undefined) => string;
  l: (key: string) => string;
  t: (key: string, data?: Record<string, unknown>) => string;
  c: (configKey?: string[] | string) => string | boolean | number | undefined;
};

type StateProps = {
  defaultSlotsPath: string;
  instructions: string;
  oldInstructions?: string;
  hasError?: boolean;
  status?: string;
  shoppingMethod: ShoppingMethod;
};

const mapStateToProps = (state: Store, { f }: HelperProps): StateProps => ({
  defaultSlotsPath: getDefaultSlotsPath(f, true)(state),
  instructions: getInstructions(state),
  oldInstructions: getOldInstructions(state),
  hasError: getHasError(state),
  status: getStatus(state),
  shoppingMethod: getTrolleyShoppingMethod(state),
});

const mapDispatchToProps = {
  instructionsChanged,
  refreshInstructions,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;
export type OrderSummaryInstructionsProps = ReduxProps & HelperProps;

const OrderSummaryInstructions = ({
  l,
  t: translate,
  c: config,
  defaultSlotsPath,
  instructions,
  hasError,
  status,
  shoppingMethod,
  instructionsChanged,
  refreshInstructions,
}: OrderSummaryInstructionsProps): JSX.Element => {
  const maxLength = config('deliveryInstructionsMaxCharacters');
  const instructionsUrl = l('/slots/current/instructions');

  const handleVariantTranslation = (): string =>
    shoppingMethod === DELIVERY ? `checkout:order-summary.editable-order-summary` : `slots:${shoppingMethod}`;

  const [numberOfChars, setNumberOfChars] = useState(instructions?.length || 0);

  useEffect(() => {
    refreshInstructions({
      instructionsUrl: instructionsUrl,
    });
  }, []);

  useEffect(() => {
    if (status === SAVED) {
      basicEvent(analyticsBus, {
        type: 'instructions',
        action: NOW,
        value: shoppingMethod,
      });
    }
  }, [status]);

  const changeInstructions = (value: string): void => {
    instructionsChanged({
      instructions: value,
      returnUrl: defaultSlotsPath,
      saveUrl: instructionsUrl,
    });
  };

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    const { value } = e.target;
    if (value.length <= (maxLength as number)) {
      setNumberOfChars(value.length);
      changeInstructions(value);
    }
  };

  const renderInstructionStatus = (): JSX.Element | null => {
    if (hasError) {
      return (
        <ErrorDiv>
          <ErrorText as={'span'} size={'small'}>
            {translate('slots:common.instructions-error')}
          </ErrorText>
          &nbsp;
          <InlineLink onClick={(): void => changeInstructions(instructions)}>
            {translate('slots:common.instructions-error-link')}
          </InlineLink>
        </ErrorDiv>
      );
    } else if (status === SAVING) {
      return (
        <StatusDiv>
          <StyledSpinner size={'xs'} accessibleLabel={translate('slots:common.instructions-saving')} />
          <SavingText as={'span'}>{translate('slots:common.instructions-saving')}</SavingText>
        </StatusDiv>
      );
    } else if (status === SAVED) {
      return (
        <StatusDiv>
          <StyledIcon fill={GREEN} stroke={GREEN} graphic={'checkmark'} size="xs" />
          <SavedText as={'span'}>{translate('slots:common.instructions-saved')}</SavedText>
        </StatusDiv>
      );
    } else {
      return null;
    }
  };

  return (
    <StyledOrderInstructionsWrapper>
      <StyledSignpost>{translate(`${handleVariantTranslation()}.instructions-title`)}</StyledSignpost>
      <StyledBodyText>{translate(`slots:${shoppingMethod}.instructions-description`)}</StyledBodyText>
      <StyledTextareaGroup
        id="orderSummaryInstructions"
        labelText={translate(`slots:${shoppingMethod}.instructions-label`)}
        hideLabel
        placeholder={translate(`${handleVariantTranslation()}.instructions-placeholder`)}
        maxCount={maxLength}
        count={numberOfChars}
        onChange={handleChange}
        value={instructions}
        error={hasError}
      />
      {renderInstructionStatus()}
    </StyledOrderInstructionsWrapper>
  );
};

export default helpers(['c', 't', 'l', 'f'])(connector(OrderSummaryInstructions));
