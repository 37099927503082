export const LEFT = 'left' as const;
export const RIGHT = 'right' as const;
export const PLAYING = 'playing' as const;
export const PAUSED = 'paused' as const;
export const PLAYING_RESET = 'playingReset' as const;
export const CAROUSEL_CLASS = 'ddsweb-carousel';
export const SIMPLE = 'simple' as const;
export const DEBOUNCE_RATE = 500;
export const KEY_DOWN = 'keydown';
export const OBSERVER_THRESHOLD = 0.95;
export const ENTER = 'Enter';
export const SPACE = ' ';
export const SEARCH = 'search';
export const SECONDARY = 'secondary';
export const ACTIVATION_KEY = [ENTER, SPACE];
export const CONTENT_CONTAINER_LABEL = 'Viewing %{start} through %{end} of %{itemsCount} items';
export const CONTENT_BACKWARD_CONTROL_LABEL = 'Go backward to previous set of carousel items';
export const CONTENT_FORWARD_CONTROL_LABEL = 'Go forward to next set of carousel items';
export const BACKWARD_CONTROL_CLASS_NAME = 'backward-control';
export const FORWARD_CONTROL_CLASS_NAME = 'forward-control';
export const ITEM_LABEL = 'item %{position}';
export const QUERY_FOCUSABLE = 'a[href], button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])';
export const ARROW = 'arrow' as const;
