import { BLUE_TESCO } from '@ddsweb/constants';
import { Heading } from '@ddsweb/heading';
import { spacing } from '@ddsweb/selectors';
import { Text } from '@ddsweb/text';
import styled from 'styled-components';

export const StyledHeading = styled(Heading)`
  && {
    color: ${BLUE_TESCO};
  }
`;

export const StyledTextareaContainer = styled('div')`
  width: 100%;

  textarea {
    min-height: 75px;
    resize: none;
  }
`;

export const StyledSaveButtonContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
  padding-top: 5px;
  width: 100%;

  button {
    width: auto;
    min-width: 180px;

    &.is-loading {
      background-color: ${BLUE_TESCO};
    }
  }
`;

export const StyledText = styled(Text)`
  && {
    margin: ${spacing.sm} 0;
  }
`;
