export const AISLE = 'Aisle';
export const BRAND = 'Brand';
export const DEPARTMENT = 'Department';
export const NEW_PRODUCT = 'New';
export const PROMOTION = 'Promotion';
export const SHELF = 'Shelf';
export const SUPERDEPARTMENT = 'Superdepartment';
export const POP_FILTERS = 'Pop Filters';
export const FAVOURITES = 'Favourites';
export const SUPERDEPARTMENTS = 'superDepartments';
export const DIETARY = 'dietary';
export const AREA = 'area';
export const OFFERS = 'Offers';
export const AVAILABLE = 'available';
export const ALL_CATEGORIES = 'all';
export const MY_FAVOURITES = 'myfavourites';
export const PRODUCT_SOURCE = 'productSource';
export const PRODUCT_SOURCE_FACET = 'Product Source';
