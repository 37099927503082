import React from 'react';
import PropTypes from 'prop-types';

import {
  BUYLIST,
  FAVORITES,
  FLEXI,
  HAVE_YOU_FORGOTTEN,
  HORIZONTAL_EMBEDDED,
  PENDING_ORDER,
  PREVIOUS_ORDER,
  SHOPPING_LISTS,
  TROLLEY,
  USUALS
} from '#/constants/tile-types';
import { getIsSubstitute } from '#/selectors/item';

import FlexiTile from '#/components/products/product-tile/tile-configurations/flexi';
import TrolleyTile from '#/components/products/product-tile/tile-configurations/trolley';
import HaveYouForgottenTile from '#/components/products/product-tile/tile-configurations/have-you-forgotten';
import PendingOrderTile from '#/components/products/product-tile/tile-configurations/pending-order';
import PreviousOrderTile from '#/components/products/product-tile/tile-configurations/previous-order';
import FavouritesTile from '#/components/products/product-tile/tile-configurations/favourites';
import HorizontalEmbeddedTile from '#/components/products/product-tile/tile-configurations/horizontal-embedded-tile';
import BuylistTile from '#/components/products/product-tile/tile-configurations/buylist';

function FavoritesTileRender(props) {
  return (
    <FavouritesTile
      canRemove={!props.item.isWhyNotTry && !getIsSubstitute(props.item)}
      {...props}
    />
  );
}

FavoritesTileRender.propTypes = {
  item: PropTypes.object.isRequired
};

export const ProductTileConfiguration = {
  [FLEXI]: props => <FlexiTile {...props} />,
  [TROLLEY]: props => <TrolleyTile {...props} />,
  [HAVE_YOU_FORGOTTEN]: props => <HaveYouForgottenTile {...props} />,
  [FAVORITES]: FavoritesTileRender,
  [SHOPPING_LISTS]: props => <FavouritesTile {...props} />,
  [PREVIOUS_ORDER]: props => <PreviousOrderTile {...props} />,
  [PENDING_ORDER]: props => <PendingOrderTile {...props} />,
  [BUYLIST]: props => <BuylistTile {...props} />,
  [HORIZONTAL_EMBEDDED]: props => <HorizontalEmbeddedTile {...props} />,
  [USUALS]: props => <FavouritesTile {...props} />
};
