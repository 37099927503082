import styled from 'styled-components';
import { spacing, breakpoints } from '@ddsweb/selectors';
import { DIETARY_BACKGROUND_COLOR } from '#/experiments/oop-1922/constants';
import { MEDIUM_LARGE_MAX } from '#/components/product-tiles/temp-style-constants';

export const StyledDietaryIconWithTextComponent = styled.div`
  display: flex;
  height: ${spacing.xl};
  @media (max-width: ${breakpoints.mobileLarge}) {
    margin: ${spacing.xs} 0;
  }
  margin: -${spacing.xs} 0 ${spacing.xs} 0;
`;

export const StyledDietaryIconWithTextHorizontalContainer = styled(StyledDietaryIconWithTextComponent)`
  margin: 0 0 ${spacing.xs} 0;
  align-items: center;
  gap: ${spacing.xx};
`;

export const DietaryLogoContainer = styled.div`
  background: ${DIETARY_BACKGROUND_COLOR};
`;

export const DietaryLogoHorizontalContainer = styled(DietaryLogoContainer)``;

export const DietaryLogoWithProductImageWrapper = styled.div`
  margin: auto;
  @media (max-width: ${breakpoints.mobileLarge}) {
    display: flex;
    align-items: flex-end;
    position: absolute;
  }
`;

export const DietaryLogoWithProductImageHorizontalWrapper = styled(DietaryLogoWithProductImageWrapper)`
  display: flex;
  align-items: flex-start;
  @media ${MEDIUM_LARGE_MAX} {
    position: absolute;
    flex-direction: column;
    gap: ${spacing.md};
  }
`;

export const DietaryLogoFlexContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: ${spacing.xx};
  z-index: 1;

  &.flex-horizontal-container {
    flex-direction: row;
    bottom: ${spacing.lg};
    @media ${MEDIUM_LARGE_MAX} {
      position: relative;
      bottom: 0px;
    }
  }
`;

export const StyledDietaryIconTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  &.flex-horizontal-container {
    flex-direction: row;
    padding: ${spacing.xs} 0px;
    gap: ${spacing.sm};
  }
`;
