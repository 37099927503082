/**
 * Sets the value at path of object. If a portion of path doesn’t exist it’s
 * created.
 *
 * @param target The object to modify.
 * @param path The path of the property to set.
 * @param value The value to set.
 * @return Returns the mutated target to support chaining.
 */
export default function set<Target extends object, Result extends object = Target>(
  target: Target,
  path: string[],
  value: any,
): Result {
  const parentPath = path.slice(0, -1);

  const key = path[path.length - 1];

  // We will now walk the parent path, resolving the parent object for the key
  // we are trying to set, ensuring that each level of the parent path is set as
  // an object
  const parent = parentPath.reduce((a, c) => {
    // Does the key not exist, or is it not an object?
    if (Object(a[c]) !== a[c]) {
      // Create the key
      a[c] = {};
    }
    // Then follow the path;
    return a[c];
  }, target);

  parent[key] = value;

  // Note: We cast it as any as we have an explicit return type
  return target as any;
}
