import React from 'react';
import Button from '@ddsweb/button';
import Icon from '@ddsweb/icon';
import { colors } from '@ddsweb/selectors';
import { AccountIcon, StoreLocatorIcon, TrolleyDiv } from './styled';
import { connect } from '#/lib/render/connect-deep-compare';
import { getLanguageLink, getLoginUrl, getRedirectUrl } from '#/reducers/app';
import HeaderGuidePrice from '../header-guide-price';
import helpers from '#/lib/decorators/helpers';
import { TTranslateFunc } from '#/lib/records/helpers.defs';
import Link from '#/components/link-check-spa';

type StoreProps = {
  loginUrl: string;
  trolleyUrl: string;
  trolleyTitle: string;
};

type OwnProps = {
  authenticated: boolean;
  t: TTranslateFunc;
};

export type Props = StoreProps & OwnProps;

const MobileIcons = ({ authenticated, loginUrl, trolleyUrl, trolleyTitle, t: translate }: Props): JSX.Element => {
  return (
    <>
      {!authenticated && (
        <AccountIcon href={loginUrl} aria-label={translate('common:aria.my-account')}>
          <Button
            aria-label={translate('common:aria.my-account')}
            data-testid="account-icon"
            icon={<Icon graphic="account" background={`${colors.primary}`} inverse={true} />}
          />
        </AccountIcon>
      )}
      <StoreLocatorIcon href="/store-locator/uk/?stop_mobi=yes'" aria-label={translate('common:aria.store-locator')}>
        <Button
          data-testid="store-locator-icon"
          aria-label={translate('common:aria.store-locator')}
          icon={<Icon graphic="storeLocator" background={`${colors.primary}`} inverse={true} />}
        />
      </StoreLocatorIcon>
      {authenticated && (
        <TrolleyDiv>
          <Link rel="nofollow" to={trolleyUrl} title={trolleyTitle}>
            <HeaderGuidePrice />
          </Link>
        </TrolleyDiv>
      )}
    </>
  );
};

const mapStateToProps = (state: Store, ownProps: OwnProps): StoreProps => {
  const languageLink = getLanguageLink(state, undefined);
  return {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    loginUrl: getLoginUrl(state as any),
    trolleyUrl: getRedirectUrl(state, languageLink + 'trolley', true),
    trolleyTitle: ownProps.t('common:pages.trolley') as string,
  };
};

export default helpers(['t'])(connect(mapStateToProps)(MobileIcons));
