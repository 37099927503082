import styled from 'styled-components';
import Modal from '@beans/modal';
import { spacing, fontSize, colors } from '@beans/selectors';
import { SubHeading, BodyText } from '@beans/typography';
import { media } from '@beans/foundation';

export const StyledModal = styled(Modal)`
  && {
    z-index: 10000;
  }

  .beans-modal__close-link {
    background-color: ${colors.white};
    color: ${colors.tescoBlue};
  }

  .beans-modal__modal-container {
    max-width: 570px;
  }
`;

export const Content = styled.div`
  margin-bottom: ${spacing.lg};
  margin-right: ${spacing.xxxl};

  ${media.belowMobileLarge`margin-right: 0`};
`;

export const ButtonContainer = styled.section`
  display: flex;
  justify-content: space-between;
  gap: ${spacing.md};

  ${media.belowMobileLarge`flex-wrap: wrap;`};
  & > a {
    font-size: ${fontSize.xs};
  }
`;

export const StyledHeading = styled(SubHeading)`
  && {
    margin: ${spacing.md} 0;
    font-size: ${fontSize.md};
  }
`;

export const StyledBodyText = styled(BodyText)`
  padding: ${spacing.xs} 0;
`;
