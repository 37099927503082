import React, { memo } from 'react';
import TileLink from '#/components/product-tiles/common/write-a-review-link/tile-link';

export type TWriteAReviewProps = {
  reviewSubmissionUrl: string;
  ariaLabel: string;
  reviewLinkText: string;
};

export const WriteAReviewLinkComponent = (props: TWriteAReviewProps): JSX.Element => {
  const { reviewSubmissionUrl, ariaLabel, reviewLinkText } = props;

  return (
    <TileLink
      href={reviewSubmissionUrl}
      ariaLabel={ariaLabel}
      data-auto="write-a-review-link"
      className="write-a-review-link"
    >
      {reviewLinkText}
    </TileLink>
  );
};

export const WriteAReviewLink = memo(WriteAReviewLinkComponent);
