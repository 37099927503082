import styled from 'styled-components';
import HorizontalTile from '#/components/product-tiles/horizontal-tile/core';
import { mediumMax } from '#/constants/viewports';

export const StyledHorizontalTile = styled(HorizontalTile)`
  ${({ shouldShowWriteAReview }: { shouldShowWriteAReview: boolean }): string | boolean =>
    !shouldShowWriteAReview &&
    `@media (max-width: ${mediumMax}) {
        .buybox-container {
          .beans-buybox__container {
            padding: 0;
          }
        }
      }
  `}
`;
