import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Image from './index';
import { connect } from '../../lib/render/connect-deep-compare';
import { getObserver } from '../../lib/browser/intersection-observer';
import { getCutsMustard } from '../../reducers/app';

const srcPlaceholder =
  'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
const mapStateToProps = state => ({
  cutsMustard: getCutsMustard(state)
});

@connect(mapStateToProps)
export default class LazyImage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: !props.cutsMustard
    };
  }

  static propTypes = {
    cutsMustard: PropTypes.bool.isRequired,
    src: PropTypes.string.isRequired,
    srcSet: PropTypes.string
  };

  componentDidMount() {
    if (!this.state.isVisible) {
      this.observer = getObserver(this.intersection);
      this.observe();
    }
  }

  componentWillUnmount() {
    if (this.observer) {
      this.observer.disconnect();
      this.observer = null;
    }
  }

  intersection = images => {
    // Are we in viewport?
    if (this.observer && images[0].intersectionRatio > 0) {
      // Stop watching and load the image
      this.observer.disconnect();
      this.load();
    }
  };

  observe() {
    if (this.observer) {
      this.observer.observe(this.image);
    } else {
      this.load();
    }
  }

  load() {
    this.setState({
      isVisible: true
    });
  }

  getSrcValue(key) {
    const value = this.props[key];

    if (value) {
      const { isVisible } = this.state;
      const placeholder = key === 'src' ? srcPlaceholder : void 0; // The srcset does not need a placeholder

      return !isVisible ? placeholder : value;
    }
  }

  render() {
    const imgProps = Object.assign({}, this.props, {
      imageRef: node => (this.image = node),
      src: this.getSrcValue('src'),
      srcSet: this.getSrcValue('srcSet')
    });

    delete imgProps.cutsMustard;
    delete imgProps.dispatch; // Added by `connect()`

    return <Image {...imgProps} />;
  }
}
