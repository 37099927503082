import React from 'react';
import PropTypes from 'prop-types';
import { DfpContainer } from '../dfp-container';
import { DfpBanner } from '../dfp-banner';
import { StyledContainer } from './styled';

const DfpReportOnly = ({ pageId, targeting }) => (
  <StyledContainer>
    <DfpContainer pageId={pageId} targeting={targeting} />
    <DfpBanner />
  </StyledContainer>
);

DfpReportOnly.propTypes = {
  pageId: PropTypes.string.isRequired,
  targeting: PropTypes.object
};

export { DfpReportOnly };
