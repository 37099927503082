import { treeFind } from './tree-utils';
import {
  SINGLE_VALUE_MESSAGE,
  DOUBLE_VALUE_MESSAGE,
  X_POUND_CLUBCARD_PRICE_TEMPLATE,
  XXP_CLUBCARD_PRICE_TEMPLATE,
  ANY_X_FOR_X_POUND_CLUBCARD_PRICE_TEMPLATE
} from '#/constants/dcs';

const ensureAtLeastOneDfpBanner = data => {
  const containsASlot = treeFind(
    node => node.type === 'DfpBanner',
    data.content.main
  );

  if (!containsASlot) {
    data.content.main.push({
      type: 'Panel',
      props: {},
      children: [
        {
          type: 'DfpBanner',
          props: {
            hideAd: true
          }
        }
      ]
    });
  }

  return data;
};

const includesRegularPrice = ({ products, regularPrice } = {}) =>
  !!(
    regularPrice > 0 ||
    (Array.isArray(products) &&
      products.length > 0 &&
      products[0]?.price?.actual)
  );

const templatesWithActualClubcardPrice = [
  {
    valueTileType: SINGLE_VALUE_MESSAGE,
    templateType: X_POUND_CLUBCARD_PRICE_TEMPLATE
  },
  {
    valueTileType: SINGLE_VALUE_MESSAGE,
    templateType: XXP_CLUBCARD_PRICE_TEMPLATE
  },
  {
    valueTileType: DOUBLE_VALUE_MESSAGE,
    templateType: ANY_X_FOR_X_POUND_CLUBCARD_PRICE_TEMPLATE
  }
];

const includesActualClubcardPrice = (valueTileType, templateType) =>
  templatesWithActualClubcardPrice.some(
    templateWithActualCCPrice =>
      valueTileType === templateWithActualCCPrice.valueTileType &&
      templateType === templateWithActualCCPrice.templateType
  );
const generateIdPropFromTitle = title => {
  const id =
    !!title &&
    typeof title === 'string' &&
    title
      .toLowerCase()
      .replace(/[^\w]/g, '-') // removes all special characters
      .replace(/-+/g, '-') // remove multiple hyphens
      .replace(/^-+(?!$)/, '') // remove '-' at the start
      .replace(/(?!^)-+$/, ''); // remove '-' at the end

  return id ? { id } : null;
};

export {
  ensureAtLeastOneDfpBanner,
  includesRegularPrice,
  includesActualClubcardPrice,
  generateIdPropFromTitle
};
