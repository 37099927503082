import { getFeatureVariableStringFromState } from '#/lib/optimizely-manager';
import { favDropdownNavigation, favDropdownNavigationVariations } from '#/experiments/oop-1716/constants';

const getVariant = (state: Store): favDropdownNavigationVariations => {
  const { useCustomerId, featureKey, variationKey } = favDropdownNavigation;
  const attributes = {};

  return (
    getFeatureVariableStringFromState(state, featureKey, variationKey, { useCustomerId, attributes }) ||
    favDropdownNavigationVariations.DEFAULT
  );
};

export const getShouldShowFavDropdown = (state: Store): boolean => {
  const variant = getVariant(state);
  return variant === favDropdownNavigationVariations.DROPDOWN;
};
