import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../shared/modal';
import ModalContent from '../../shared/modal/content';
import helpers from '../../../lib/decorators/helpers';
import { removeModalFromUrl } from '../../../lib/url/modal-utils';

@helpers(['t'])
export default class ConnectionTimeoutModal extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired
  };

  onClick = () =>
    window.location.assign(removeModalFromUrl(window.location.href));

  render() {
    return (
      <Modal
        className="connection-timeout--modal"
        dismissable={false}
        showLink={false}
      >
        <ModalContent>
          <p>{this.props.t('common:connection-error-message')}</p>
          <div className="modal-actions-button-container">
            <button
              className="button button-primary small"
              onClick={this.onClick}
            >
              {this.props.t('common:ok')}
            </button>
          </div>
        </ModalContent>
      </Modal>
    );
  }
}
