import React from 'react';
import Link from '#/components/link-check-spa';
import { StandaloneLink } from '@ddsweb/link';
import { TSpaLink } from '#/custom-typings/utils/components.defs';

type TTileLinkButton = {
  className?: string;
  dataAuto: string;
  href: string;
  children: JSX.Element;
  onClick?: () => void;
};

const spaLink = ({ children, className, href, onClick }: TSpaLink): JSX.Element => (
  <Link to={href} className={className} onClick={onClick} data-auto="rest-of-shelf-link">
    {children}
  </Link>
);

const TileLinkButton = ({ href, children, onClick, className }: TTileLinkButton): JSX.Element => (
  <StandaloneLink
    as={spaLink}
    className={className}
    emphasized
    href={href}
    icon={{
      graphic: 'forwardLink',
      position: { global: 'right' },
      size: 'xs',
    }}
    onClick={onClick}
  >
    {children}
  </StandaloneLink>
);

export default TileLinkButton;
