import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import helpers from '../../../lib/decorators/helpers';
import { ImageBanner } from './banners/image-banner';
import { TradeBanner } from './banners/trade-banner';
import { BRAND_BANNER } from '../../../constants/dcs-component-types';
import { withErrorBoundary } from '#/components/shared/error-boundary';
import { CMS_ERROR_PREFIX } from '#/constants/dcs';
import { UK } from '#/constants/regions';

const SlimTradeBanner = props => {
  const {
    bannerType,
    componentClickHandler,
    panelColor,
    priority,
    product2,
    c: config
  } = props;

  const region = config('REGION');
  const isUKRegion = region === UK;

  const hasPanel = !!(panelColor && panelColor.backgroundColor);
  const bannerHasProduct2 = !!(product2 && product2.src);
  const isImageBanner = bannerType === BRAND_BANNER;
  const stampClasses = classnames(
    'slim-trade-banner',
    `slim-trade-banner--${priority}`,
    { 'slim-trade-banner--new-cc-logo': isUKRegion },
    { 'slim-trade-banner--full-width': isImageBanner },
    { [`slim-trade-banner--has-panel`]: hasPanel },
    { [`slim-trade-banner--has-product-2`]: bannerHasProduct2 }
  );

  const onClick = () => componentClickHandler?.(props);

  return (
    <div className={stampClasses} onClick={onClick}>
      {isImageBanner ? (
        <ImageBanner link={props.link} />
      ) : (
        <TradeBanner {...props} />
      )}
    </div>
  );
};

SlimTradeBanner.propTypes = {
  background: PropTypes.shape({
    src: PropTypes.string,
    texturedImageUrl: PropTypes.string
  }),
  bannerColor: PropTypes.shape({ backgroundColor: PropTypes.string }),
  bannerType: PropTypes.string,
  brandLogo: PropTypes.shape({ src: PropTypes.string }),
  c: PropTypes.func.isRequired,
  callToAction: PropTypes.shape({
    desktop: PropTypes.object,
    mobile: PropTypes.object
  }),
  componentClickHandler: PropTypes.func,
  headline: PropTypes.string,
  link: PropTypes.shape({ href: PropTypes.string }),
  panelColor: PropTypes.shape({ backgroundColor: PropTypes.string }),
  placement: PropTypes.number,
  priority: PropTypes.string,
  product1: PropTypes.shape({ src: PropTypes.string }),
  product2: PropTypes.shape({ src: PropTypes.string }),
  subHeadline: PropTypes.string,
  t: PropTypes.func.isRequired,
  termsText: PropTypes.string,
  textColor: PropTypes.shape({ color: PropTypes.string }),
  valueTile: PropTypes.shape({ valueContent: PropTypes.array })
};

const wrappedSlimTradeBanner = withErrorBoundary(
  CMS_ERROR_PREFIX,
  'SlimTradeBanner'
)(helpers(['t', 'c'])(SlimTradeBanner));

export { wrappedSlimTradeBanner as SlimTradeBanner };
