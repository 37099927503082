export const AMEND_MODE = 'AMEND_MODE';
export const NO_SLOT_BOOKED = 'NO_SLOT_BOOKED';
export const PENDING_ORDER = 'PENDING_ORDER';
export const SLOT_BOOKED_EMPTY = 'SLOT_BOOKED_EMPTY';
export const SLOT_BOOKED_FULL = 'SLOT_BOOKED_FULL';
export const SLOT_EXPIRED = 'SLOT_EXPIRED';
export const SLOT_EXPIRY_WARNING = 30 * 60 * 1000; // 30 minutes
export const RESERVED = 'Reserved';
export const EXPIRED = 'Expired';
export const OUT_OF_CATCHMENT = 'OUT_OF_CATCHMENT';
export const UNAVAILABLE = 'UnAvailable';
