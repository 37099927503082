import {
  OPTIMIZELY_TRACK_SERVER,
  OPTIMIZELY_TRACK_CLIENT,
  OPTIMIZELY_INSTANCE_GETTER_FUNCTION
} from '#/constants/optimizely';

export default class OptimizelyWrapper {
  constructor(optimizely, options, wrapperOptions) {
    this.optimizelyInstance = optimizely.createInstance(options);
    this.cache = [];
    this.cacheSizeLimit = 500;
    this.activateDisabled = !wrapperOptions.optimizelyEnabled || false;
    this.trackingType = process.env.CLIENT_SIDE
      ? OPTIMIZELY_TRACK_CLIENT
      : OPTIMIZELY_TRACK_SERVER;
  }

  _limitCacheSize() {
    if (this.cache.length > this.cacheSizeLimit) {
      this.cache = this.cache.slice(1);
    }
  }

  _generateCacheKey(experiment, userId, attributes) {
    return [experiment, userId, JSON.stringify(attributes)].join('|');
  }

  _lookupInCache(cacheKey) {
    return this.cache.find(item => item.key === cacheKey);
  }

  _addToCache(item) {
    this.cache = this.cache.concat(item);
    this._limitCacheSize();
  }

  _getFeatureVariable(featureKey, variableKey, userId, attributes, getter) {
    const cacheKey = this._generateCacheKey(
      `${featureKey}-${variableKey}`,
      userId,
      attributes
    );
    let item = this._lookupInCache(cacheKey);

    if (!item) {
      item = {
        key: cacheKey,
        value: this.optimizelyInstance[getter](
          featureKey,
          variableKey,
          userId,
          attributes
        )
      };
      this._addToCache(item);
    }
    return item.value;
  }

  setEventTracking(eventName, userId, attributes) {
    this.optimizelyInstance.track(eventName, userId, attributes);
  }

  activate(experiment, userId, attributes) {
    if (this.activateDisabled || !userId) {
      return { variation: null };
    }

    const cacheKey = this._generateCacheKey(experiment, userId, attributes);
    let item = this._lookupInCache(cacheKey);
    const isCached = item ? true : false;

    if (!item) {
      item = {
        key: cacheKey,
        value: this.optimizelyInstance.activate(experiment, userId, attributes)
      };

      this._addToCache(item);
    }

    return { variation: item.value, isCached };
  }

  getFeatureVariableString(featureKey, variableKey, userId, attributes) {
    return this._getFeatureVariable(
      featureKey,
      variableKey,
      userId,
      attributes,
      OPTIMIZELY_INSTANCE_GETTER_FUNCTION.string
    );
  }

  getFeatureVariableJson(featureKey, variableKey, userId, attributes) {
    return this._getFeatureVariable(
      featureKey,
      variableKey,
      userId,
      attributes,
      OPTIMIZELY_INSTANCE_GETTER_FUNCTION.json
    );
  }

  isFeatureEnabled(featureKey, userId, attributes) {
    if (this.activateDisabled || !userId) {
      return { isEnabled: false, isCached: false };
    }

    const cacheKey = this._generateCacheKey(
      `isFeatureEnabled-${featureKey}`,
      userId,
      attributes
    );
    let item = this._lookupInCache(cacheKey);
    const isCached = item ? true : false;

    if (!item) {
      item = {
        key: cacheKey,
        value: this.optimizelyInstance.isFeatureEnabled(
          featureKey,
          userId,
          attributes
        )
      };
      this._addToCache(item);
    }
    return { isEnabled: item.value, isCached };
  }
}
