import {
  AlternativeCategories,
  Breadcrumb,
  ProductCharge,
  CustomerUnitChoice,
  Experiments,
  Price,
  Product,
  Promotion,
  Promotions,
  Recommandations,
  RestrictionDeliveryGroup,
  Seller,
  SubstitutionOption,
  SubstitutionOptions,
} from './item.defs';

import { CATCH_WEIGHT_PRODUCTS } from '#/constants/display-types';
import { getDefaultUnit } from '#/selectors/item';
import { ContentDetails } from '#/experiments/oop-2021/types';

export type Item = Readonly<{
  item?: Item;
  charges?: {
    charges: ProductCharge[];
  };
  type?: string;
  typename?: string;
  content?: ContentDetails;
  aisleId?: string;
  aisleName?: string;
  asyncPage?: boolean;
  alternativeCategories?: AlternativeCategories;
  backToUrl?: string | null;
  breadcrumbs?: Breadcrumb[];
  catchWeight?: number;
  canonicalUrl?: string | null;
  cost?: number;
  customerUnitChoice?: CustomerUnitChoice;
  departmentId?: string;
  departmentName?: string;
  defaultImageUrl?: string;
  experiments?: Experiments;
  groupName?: string | null;
  id?: string;
  isNewlyAdded?: boolean;
  isSubstitute?: boolean;
  isWhyNotTry?: boolean;
  isSponsoredProduct?: boolean;
  issues?: { willBeRemoved: boolean; reason: string }[];
  itemLimitReached?: boolean;
  groupBulkBuyQuantity?: number;
  groupLimitReached?: boolean;
  name?: string;
  nappySize?: string;
  originalCatchWeight?: number;
  originalCustomerUnitChoice?: CustomerUnitChoice | null;
  originalQuantity?: number;
  originalProductWeight?: number;
  originalWeight?: number;
  pickerNote?: string;
  pageTitle?: string;
  pageDescription?: string;
  availableQuantity?: number;
  price?: Price;
  product: Product;
  productItem?: Item;
  promotions: Promotions;
  quantity?: number;
  recommendations?: Recommandations;
  restrictionDeliveryGroups?: RestrictionDeliveryGroup;
  restOfShelfUrl?: string | null;
  seedProduct?: PartialDeep<Product>;
  shelfId?: string;
  shelfName?: string;
  substitutionOption?: SubstitutionOption | null;
  substitutionOptions?: SubstitutionOptions;
  structuredData?: string | null;
  promotionMessages?: object;
  promotionRewards?: object;
  title?: string;
  template?: string;
  weight?: number;
  referer?: string;
  robots?: string;
  superDepartmentName?: string;
  superDepartmentId?: string;
  seller?: Seller;
}>;
const defaults: Item = {
  cost: 0,
  quantity: 0,
  product: {} as Product,
  promotions: [],
  promotionMessages: {},
  promotionRewards: {},
  itemLimitReached: false,
  groupLimitReached: false,
  groupBulkBuyQuantity: 0,
  originalQuantity: 0,
  originalProductWeight: 0,
  originalCatchWeight: 0,
  isNewlyAdded: false,
  isSubstitute: false,
  seedProduct: {},
  isWhyNotTry: false,
  issues: [],
};

const getDefaultPromotions = (promotions?: Promotion[]): Promotion[] => {
  if (!promotions) {
    return [];
  }

  return promotions;
};

export const getDefaultCatchWeight = (item: PartialDeep<Item>): number | undefined => {
  if (item.product?.productType !== CATCH_WEIGHT_PRODUCTS) {
    return;
  }

  if (item.weight) {
    return item.weight;
  }

  const catchWeightList = item.product.catchWeightList || [];

  if (catchWeightList.length) {
    const median = Math.floor((catchWeightList.length - 1) / 2);

    return catchWeightList[median]?.weight;
  }
};

const decorate = (item: PartialDeep<Item>): Item => {
  const base = item as Item;
  const product = (item.product || {}) as Product;

  return {
    ...base,
    catchWeight: item.catchWeight || getDefaultCatchWeight(item),
    promotions: getDefaultPromotions(product.promotions || base.promotions),
    customerUnitChoice: item.customerUnitChoice || getDefaultUnit(item as Item),
    isSponsoredProduct: !!item.product?.adId,
    product: {
      ...product,
      aisleName: product.aisleName || item.aisleName,
      departmentName: product.departmentName || item.departmentName,
      superDepartmentName: product.superDepartmentName || item.superDepartmentName,
    },
    groupName: item.groupName,
  };
};

const createItem = (obj: PartialDeep<Item>): Item => {
  return decorate({ ...defaults, ...obj });
};

export default createItem;
