import React from 'react';

function createStar(classname) {
  return (
    <g className={classname}>
      <polygon points="13 19.05, 7.46 22.12, 8.52 15.61, 4 10.97 10.24, 10.02 13, 4.12 15.76, 10.02 22, 10.97 17.48, 15.61 18.54, 22.12 13 19.05" />
    </g>
  );
}

const star1 = createStar('star-rating__glyph-star-1');
const star2 = createStar('star-rating__glyph-star-2');

const StarIcon = () => (
  <div className="star-rating__glyph-container">
    <svg className="star-rating__glyph-source">
      <defs>
        <linearGradient id="halfGradient">
          <stop stopOpacity="1" offset="50%" stopColor="#ffd700" />
          <stop stopOpacity="0" offset="50%" />
        </linearGradient>
      </defs>
    </svg>
    <svg className="star-rating__glyph-star-container" viewBox="0 -1 28 28">
      {star1}
      {star2}
    </svg>
  </div>
);

export default StarIcon;
