import styled, { css } from 'styled-components';
import { base, breakpoints, colors, fontSize, spacing } from '@ddsweb/selectors';
import Heading from '@ddsweb/heading';
import { Text } from '@ddsweb/text';
import { GREY_LIGHT_2, BREAKPOINTS } from '@ddsweb/constants';
import { visuallyHide } from '@ddsweb/foundation';
import { InlineLink } from '@ddsweb/link';
import Divider from '@ddsweb/divider';

type Props = {
  slotBooked?: boolean;
};

export const StyledBasketSlotContextCardContainer = styled.div`
  font-family: ${base.fontFamily};
  @media (min-width: ${breakpoints.tablet}px) {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: ${spacing.md};
  }
  padding: ${spacing.md};
  border: 1px solid ${colors.lines};

  @media (max-width: ${BREAKPOINTS.tablet + 11}px) {
    margin: 0 calc(${spacing.xxx}*3) calc(${spacing.xxx}*3);
  }
`;

export const StyledDivider = styled(Divider)`
  && {
    margin-top: ${spacing.md};
    background-color: ${GREY_LIGHT_2};

    ${({ slotBooked }: Props): string =>
      !slotBooked &&
      css`
        margin-bottom: ${spacing.sm};
      `};
  }
`;

export const StyledBodyText = styled(Text)`
  margin-top: ${spacing.xs};
`;

export const VisuallyHiddenLabel = styled.span`
  ${visuallyHide}
`;

export const StyledHeadingContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const StyledFullBasketSlotContextCardStatus = styled.div`
  @media (min-width: ${breakpoints.tablet}px) {
    flex-grow: 1;
    margin: 0;
    padding: 0;
    border: 0 none;
    border-bottom: none;
  }
`;

export const StyledHeadings = styled(Heading)`
  margin: ${spacing.xx} ${spacing.md} ${spacing.xx} 0px;
`;

export const StyledInlineLink = styled(InlineLink)`
  font-size: ${fontSize.xs};
  text-decoration: underline;
  align-self: center;
  line-height: ${spacing.xl};
  &:hover {
    text-decoration: none;
    color: ${colors.linkActive};
  }
  @media (max-width: ${breakpoints.mobile}px) {
    margin-top: ${spacing.xxx};
    margin-bottom: ${spacing.xs};
  }
`;

export const StyledBasketNoSlotContextCardStatus = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (min-width: ${breakpoints.tablet}px) {
    flex-grow: 1;
    margin: 0;
    padding: 0;
    border: 0 none;
    border-bottom: none;
  }
`;

export const StyledBasketNoSlotContextCardHeader = styled(Heading)`
  margin: ${spacing.xxx} ${spacing.md} ${spacing.xxx} 0px;
`;

export const StyledText = styled.p`
  color: ${colors.text};
  font-size: ${fontSize.xs};
`;
