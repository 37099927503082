import React from 'react';
import { StylesList } from '#/components/products/product-list/styled';
import NoMoreFavorites from '#/components/favorites/no-more-favorites';
import { GridViewProps } from '#/components/products/product-list/types/index.defs';

export const GridView = ({
  classes,
  dataAuto,
  dataAutoLastUpdated,
  interruptBanners,
  items,
}: GridViewProps): JSX.Element => {
  const shouldRenderNoMoreFav = items.length === 1;

  return (
    <StylesList className={classes} data-auto={dataAuto} data-auto-last-updated={dataAutoLastUpdated}>
      {interruptBanners}
      {items}
      {shouldRenderNoMoreFav && (
        <li className="no-more-favorites">
          <NoMoreFavorites />
        </li>
      )}
    </StylesList>
  );
};

export default GridView;
