import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from '#/lib/render/connect-deep-compare';
import { Modals } from '../modals';
import helpers from '#/lib/decorators/helpers';
import { getCustomerFirstName } from '#/reducers/customer';
import { getMyAccountUrl } from '#/reducers/app';
import { getChangeSlotInfo } from '#/selectors/slot';
import ForeseeHelper from '../foresee-modal/helper';
import { SHOPPING_METHOD_PROP_TYPE } from '#/components/slots/prop-types';

const mapStateToProps = (state, ownProps) => ({
  currentModal: state.ui.currentModal,
  modalData: state.ui.modalData,
  changeSlotInfo: getChangeSlotInfo(state),
  customerFirstName: getCustomerFirstName(state),
  externalMyAccountUrl: getMyAccountUrl(
    state,
    ownProps.c('externalMyAccountUrl')
  )
});

@helpers(['c', 'externalSecureLink', 'l', 't', 'asset'])
@connect(mapStateToProps)
export default class LayoutModals extends Component {
  static propTypes = {
    asset: PropTypes.func.isRequired,
    bagCharge: PropTypes.number,
    c: PropTypes.func.isRequired,
    changeSlotInfo: PropTypes.object,
    close: PropTypes.string,
    couponSavings: PropTypes.number,
    currentModal: PropTypes.string,
    customerFirstName: PropTypes.string,
    externalSecureLink: PropTypes.func,
    guidePrice: PropTypes.number,
    isAmendBasket: PropTypes.bool.isRequired,
    isBagless: PropTypes.bool,
    isUpdating: PropTypes.bool,
    l: PropTypes.func.isRequired,
    modalData: PropTypes.any,
    selectedShoppingMethod: SHOPPING_METHOD_PROP_TYPE,
    shoppingMethod: PropTypes.string,
    t: PropTypes.func.isRequired,
    totalPrice: PropTypes.number
  };

  render() {
    const { currentModal } = this.props;

    return (
      <Fragment>
        <ForeseeHelper />
        {currentModal &&
          Modals[currentModal] &&
          Modals[currentModal](this.props)}
      </Fragment>
    );
  }
}
