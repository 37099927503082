import React from 'react';
import { InjectedRouter, match, withRouter } from 'react-router';
import routes from '#/routes/spa-routes';
import { isMFERouteAvailable } from '#/routes/spa-routes/spa-helpers.js';
import { getLanguage, getMfeRolloutConfig } from '#/reducers/app';
import { connect } from '#/lib/render/connect-deep-compare';
import { compose } from 'react-recompose';
import { TConfigFunc, TFeatureFunc } from '#/lib/records/helpers.defs';

export type WithCheckedSPAProps = {
  language: string;
  f: TFeatureFunc;
  c: TConfigFunc;
  mfeRolloutConfig: Record<string, boolean>;
  router: InjectedRouter;
};

export type CheckSPAForRedirect = (url: string) => void;

type State = {
  language: string;
  mfeRolloutConfig: Record<string, boolean>;
};

const mapStateToProps = (state: Store): State => ({
  language: getLanguage(state),
  mfeRolloutConfig: getMfeRolloutConfig(state),
});

function withCheckedSPA(WrappedComponent: React.ComponentType<{ checkSPAForRedirect: CheckSPAForRedirect }>) {
  return (props: WithCheckedSPAProps): JSX.Element => {
    const checkSPAForRedirect: CheckSPAForRedirect = url => {
      const languages = [props.language];
      match(
        {
          routes: routes(languages, props.f, props.c),
          location: url,
        },
        (_error, _redirect, contextProps) => {
          if (contextProps) {
            const { routes } = contextProps;
            const leafRoute = routes[routes.length - 1];

            let mfeRouteName = leafRoute?.mfeRouteName;
            if (typeof leafRoute?.mfeRouteNameMapper === 'function') {
              /*
            mfeRouteNameMapper is used to support routes with path params which determine what mfe is
            used e.g. slots-collection and slots-ondemand
            */
              mfeRouteName = leafRoute.mfeRouteNameMapper(contextProps.params);
            }

            if (
              // This can only return true in SPA
              isMFERouteAvailable(props.mfeRolloutConfig, mfeRouteName)
            ) {
              // If MFE route is enabled then stop SPA and do full server render.
              window.location.href = url;
            } else if (leafRoute.path !== '*') {
              props.router.push(url);
            }
          }
        },
      );
    };

    return <WrappedComponent checkSPAForRedirect={checkSPAForRedirect} {...props} />;
  };
}

export default compose(connect(mapStateToProps), withRouter, withCheckedSPA);
