import React from 'react';
import { compose } from 'react-recompose';
import { PRIMARY, SECONDARY } from '@beans/constants';
import { TextButtonLink } from '@beans/link';
import { DELAY, NOW } from '#/analytics/constants';

import { connect } from '#/lib/render/connect-deep-compare';
import helpers from '#/lib/decorators/helpers';
import { TTranslateFunc } from '#/lib/records/helpers.defs';

import { closeModal } from '#/actions/ui-action-creators';
import { getLanguageLink } from '#/reducers/app';

import { ButtonContainer, Content, StyledHeading, StyledBodyText, StyledModal } from '../styled';
import { emitBasketModalAnalyticsEvent } from '#/experiments/oop-1583/helpers/analytics';
import {
  ITEM_UNAVAILABLE_MODAL_CONTINUE_SHOPPING,
  ITEM_UNAVAILABLE_MODAL_GOTO_BASKET,
  ITEM_UNAVAILABLE_MODAL_CLOSE,
} from '#/experiments/oop-1583/constants';

type OwnState = { groceriesUrl: string; trollyUrl: string };

type OwnProps = {
  closeModal: () => void;
  t: TTranslateFunc;
};

const mapStateToProps = (state: Store): OwnState => ({
  groceriesUrl: getLanguageLink(state, ''),
  trollyUrl: getLanguageLink(state, '/trolley'),
});

const connector = connect(mapStateToProps, { closeModal });

type Props = OwnProps & OwnState;

const BasketItemUnavailableModal = (props: Props): JSX.Element | null => {
  const { closeModal, t: translate, groceriesUrl, trollyUrl } = props;

  const tPrefix = `modals:basket-item-unavailable`;

  const renderContent = (): JSX.Element => {
    return (
      <Content>
        <StyledHeading>{translate(`${tPrefix}.title`)}</StyledHeading>
        <StyledBodyText>{translate(`${tPrefix}.description`)}</StyledBodyText>
      </Content>
    );
  };

  const handleGoToBasketClick = (): void => {
    emitBasketModalAnalyticsEvent(DELAY, ITEM_UNAVAILABLE_MODAL_GOTO_BASKET);
    closeModal();
  };

  const handleContinueShoppingClick = (): void => {
    emitBasketModalAnalyticsEvent(DELAY, ITEM_UNAVAILABLE_MODAL_CONTINUE_SHOPPING);
    closeModal();
  };

  const handleModalCloseIconClick = (): void => {
    emitBasketModalAnalyticsEvent(NOW, ITEM_UNAVAILABLE_MODAL_CLOSE);
    closeModal();
  };

  const renderFooter = (): JSX.Element => (
    <ButtonContainer>
      <TextButtonLink buttonVariant={SECONDARY} stretch href={groceriesUrl} onClick={handleContinueShoppingClick}>
        {translate(`${tPrefix}.continue`)}
      </TextButtonLink>

      <TextButtonLink buttonVariant={PRIMARY} stretch href={trollyUrl} onClick={handleGoToBasketClick}>
        {translate(`${tPrefix}.go-basket`)}
      </TextButtonLink>
    </ButtonContainer>
  );

  return (
    <StyledModal id="basket-unavailable-item-modal" open ssr persist onChange={handleModalCloseIconClick}>
      {renderContent()}
      {renderFooter()}
    </StyledModal>
  );
};

const enhance = compose(helpers(['t']), connector);
export default enhance(BasketItemUnavailableModal);
