import {
  ACCOUNT_PHONE_NUMBER,
  ADDRESSES,
  APP_STATE,
  BUYLISTBYID,
  BUYLIST,
  CMS_NAV,
  DCS_PAGE,
  FAVORITES,
  FAVORITES_CAROUSEL,
  FULFILMENT_METADATA,
  FULFILMENT_OPTIONS_DETAIL,
  HOME_PAGE,
  HAVE_YOU_FORGOTTEN,
  MULTI_SEARCH_LIST,
  PRODUCT_CATEGORIES,
  PRODUCT_DETAILS,
  PRODUCTS_BY_CATEGORY,
  PROMOTIONS_ALL,
  PROMOTIONS_BY_DEPARTMENT,
  PROMOTIONS_CONTENT,
  PROMOTIONS_ID_OR_TYPE,
  SEARCH,
  TROLLEY_CONTENTS,
  USUALS,
  ZONE_PAGE,
  EXPERIMENTS,
  SLOT,
  LOCATION,
  RESOURCE_REQUEST_TIMESTAMPS,
  ORDER_LIST_DETAILS,
  LAST_ORDER,
  FULFILMENT_ESTIMATED_ARRIVAL,
  SUBSCRIPTIONS
} from '#/constants/spa-resource';

export const getResources = state => state.resources || {};

export const getResource = resourcesName => state => {
  return state?.resources?.[resourcesName];
};

export const getResourceRequestTimestamps = getResource(
  RESOURCE_REQUEST_TIMESTAMPS
);

export const getAddressesStateResource = getResource(ADDRESSES);
export const getAppStateResource = getResource(APP_STATE);
export const getBuylistByIdResource = getResource(BUYLISTBYID);
export const getBuylistGroupResource = getResource(BUYLIST);
export const getCMSNavResource = getResource(CMS_NAV);
export const getDCSPageResource = getResource(DCS_PAGE);
export const getFavoritesResource = getResource(FAVORITES);
export const getLastOrderResource = getResource(LAST_ORDER);

export const getFavoritesCarouselResource = getResource(FAVORITES_CAROUSEL);

export const getFulfilmentMetadataResource = getResource(FULFILMENT_METADATA);

export const getHomePageResource = getResource(HOME_PAGE);

export const getMultiSearchListResource = getResource(MULTI_SEARCH_LIST);

export const getOrderListDetailsResource = getResource(ORDER_LIST_DETAILS);

export const getProductCategoriesResource = getResource(PRODUCT_CATEGORIES);

export const getProductDetailsResource = getResource(PRODUCT_DETAILS);

export const getProductsByCategoryResource = getResource(PRODUCTS_BY_CATEGORY);

export const getPromotionsAllResource = getResource(PROMOTIONS_ALL);

export const getPromotionsByDepartmentResource = getResource(
  PROMOTIONS_BY_DEPARTMENT
);

export const getPromotionsContentResource = getResource(PROMOTIONS_CONTENT);

export const getHaveYouForgottenResource = getResource(HAVE_YOU_FORGOTTEN);

export const getPromotionsIdOrTypeResource = getResource(PROMOTIONS_ID_OR_TYPE);

export const getSearchResource = getResource(SEARCH);

export const getTrolleyContentsResource = getResource(TROLLEY_CONTENTS);

export const getUsualsResource = getResource(USUALS);
export const getZonePageResource = getResource(ZONE_PAGE);
export const getExperimentsResource = getResource(EXPERIMENTS);
export const getLocationResource = getResource(LOCATION);

export const getPromotionsListResource = state => {
  const promotionsAllResource = getPromotionsAllResource(state);
  const promotionsIdOrTypeResource = getPromotionsIdOrTypeResource(state);
  return promotionsAllResource || promotionsIdOrTypeResource;
};

export const getSlotResource = getResource(SLOT);

export const getFulfilmentOptionsDetailResource = getResource(
  FULFILMENT_OPTIONS_DETAIL
);

export const getAccountPhoneNumberStateResource = getResource(
  ACCOUNT_PHONE_NUMBER
);

export const getFulfilmentEstimatedArrivalResource = getResource(
  FULFILMENT_ESTIMATED_ARRIVAL
);

export const getSubscriptionsdResource = getResource(SUBSCRIPTIONS);

export const getProductSourceText = (label, translate) => {
  const labelTranslations = {
    Ghs: 'sort-and-filter:product-source-Ghs',
    GhsAndMarketplace: 'sort-and-filter:product-source-GhsAndMarketplace',
    MarketplaceOnly: 'sort-and-filter:product-source-MarketPlace'
  };
  return label && labelTranslations[label]
    ? translate(labelTranslations[label])
    : label;
};
