const LABEL_LIMIT_SHORT = 18;
const LABEL_LIMIT_LONG = 24;

function accumulativeSum(numbers) {
  const totals = [];
  let runningTotal = -1;

  numbers.forEach(n => {
    runningTotal += n + 1;
    totals.push(runningTotal);
  });

  return totals;
}

function getWhitespaceIndices(string) {
  const words = string.split(' ');
  const counts = words.map(word => word.length);

  return accumulativeSum(counts);
}

function getCentralBreak(textLength, limit, whitespaceIndices) {
  const halfwayPoint = Math.ceil(textLength * 0.6);
  const firstLineLastChar = Math.min(limit, halfwayPoint);

  return [...whitespaceIndices]
    .reverse()
    .find(char => char <= firstLineLastChar); // eslint-disable-line no-extra-parens
}

function describeTruncation(limit, text, fullLabel) {
  const whitespaceIndices = getWhitespaceIndices(text);
  const centralBreak = getCentralBreak(
    fullLabel.length,
    limit,
    whitespaceIndices
  );

  if (centralBreak === undefined) {
    return [text.slice(0, limit - 4)];
  }

  return [text.slice(0, centralBreak), text.slice(centralBreak + 1)];
}

function prepareLabel(useLongLabel, text, count, shouldTruncateLabel = true) {
  const limit = useLongLabel ? LABEL_LIMIT_LONG : LABEL_LIMIT_SHORT;
  let suffix;
  let fullLabel;

  if (typeof count === 'undefined' || count === null) {
    suffix = null;
    fullLabel = text;
  } else {
    suffix = `(${count})`;
    fullLabel = `${text} ${suffix}`;
  }

  const truncate = fullLabel.length > limit && shouldTruncateLabel;
  const lines = truncate ? describeTruncation(limit, text, fullLabel) : [text];

  return { lines, suffix, useLongLabel, truncate };
}

export default prepareLabel;
