export const DEFAULT_BING_MAP_LANGUAGE = 'en-GB';

export const bingSupportedLanguages = {
  'ar-SA': 'Arabic - Saudi Arabia',
  eu: 'Basque',
  bg: 'Bulgarian',
  'bg-BG': 'Bulgarian - Bulgaria',
  ca: 'Catalan Spanish',
  'ku-Arab': 'Central Kurdish',
  'zh-CN': 'Chinese - China',
  'zh-HK': 'Chinese - Hong Kong',
  'zh-Hans': 'Chinese - Simplified',
  'zh-TW': 'Chinese - Taiwan',
  'zh-Hant': 'Chinese - Traditional',
  cs: 'Czech',
  'cs-CZ': 'Czech - Czech Republic',
  da: 'Danish',
  'da-DK': 'Danish - Denmark',
  'nl-BE': 'Dutch - Belgium',
  nl: 'Dutch - Netherlands',
  'nl-NL': 'Dutch - Netherlands',
  'en-AU': 'English - Australia',
  'en-CA': 'English - Canada',
  'en-IN': 'English - India',
  'en-GB': 'English - United Kingdom',
  'en-US': 'English - United States',
  fi: 'Finnish',
  'fi-FI': 'Finnish - Finland',
  'fr-BE': 'French - Belgium',
  'fr-CA': 'French - Canada',
  fr: 'French - France',
  'fr-FR': 'French - France',
  'fr-CH': 'French - Switzerland',
  gl: 'Galician',
  de: 'German - Germany',
  'de-DE': 'German - Germany',
  el: 'Greek',
  he: 'Hebrew',
  'he-IL': 'Hebrew - Israel',
  hi: 'Hindi',
  'hi-IN': 'Hindi - India',
  hu: 'Hungarian',
  'hu-HU': 'Hungarian - Hungary',
  is: 'Icelandic',
  'is-IS': 'Icelandic - Iceland',
  it: 'Italian - Italy',
  'it-IT': 'Italian - Italy',
  ja: 'Japanese',
  'ja-JP': 'Japanese - Japan',
  ko: 'Korean',
  'Ko-KR': 'Korean - Korea',
  'ky-Cyrl': 'Kyrgyz',
  lv: 'Latvian',
  'lv-LV': 'Latvian - Latvia',
  lt: 'Lithuanian',
  'lt-LT': 'Lithuanian - Lithuania',
  nb: 'Norwegian - Bokmål',
  'nb-NO': 'Norwegian - Bokmål - Norway',
  nn: 'Norwegian - Nynorsk',
  pl: 'Polish',
  'pl-PL': 'Polish - Poland',
  'pt-BR': 'Portuguese - Brazil',
  'pt-PT': 'Portuguese - Portugal',
  ru: 'Russian',
  'ru-RU': 'Russian - Russia',
  'es-MX': 'Spanish - Mexico',
  es: 'Spanish - Spain',
  'es-ES': 'Spanish - Spain',
  'es-US': 'Spanish - United States',
  sv: 'Swedish - Sweden',
  'sv-SE': 'Swedish - Sweden',
  'tt-Cyrl': 'Tatar - Cyrillic',
  th: 'Thai',
  'th-TH': 'Thai - Thailand',
  tr: 'Turkish',
  'tr-TR': 'Turkish - Turkey',
  uk: 'Ukrainian',
  'uk-UA': 'Ukrainian - Ukraine',
  'ug-Arab': 'Uyghur',
  'ca-ES-valencia': 'Valencian',
  vi: 'Vietnamese',
  'vi-VN': 'Vietnamese - Vietnam'
};
