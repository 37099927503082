const VARIATION_KEY = 'horizontal-taxonomy';

export const horizontalTaxonomyFeature = {
  featureKey: 'GHS-<region>_horizontal-taxonomy',
  variationKey: VARIATION_KEY,
};

export const POSTFIX_LOADING = '-loading';

export const NAV_MAX_LENGTH = 20;
