import { ProductData, ProductDataRating, ProductMethod, Attribute } from '../types';
import { price } from './misc';
import { offerData, OfferDataPayload, getSashAnalyticsAttributes } from '#/analytics/bertie/formatters/sash';
import { REMOVE_PRODUCT } from '#/analytics/constants';
import { getDietaryAttributeValue } from '#/experiments/oop-1922/selectors/analytics';
export interface ProductMethodPayload {
  displayType?: string;
}

export function productMethod(payload: ProductMethodPayload): ProductMethod | undefined {
  const { displayType } = payload;

  switch (displayType) {
    case 'Quantity':
      return 'q';
    case 'Weight':
      return 'w';
    case 'QuantityOrWeight':
      return 'w';
  }
}

export interface WeightPayload {
  weight?: number;
  weightMeasure?: string;
}

export interface ProductDataRatingPayload {
  reviews?: {
    stats: {
      noOfReviews: number;
      overallRating: number;
    };
  };
}

export function productDataRating(payload: ProductDataRatingPayload): ProductDataRating | undefined {
  const { reviews } = payload;

  if (!reviews?.stats) {
    return;
  }

  const {
    stats: { noOfReviews, overallRating },
  } = reviews;

  return {
    averageRating: overallRating,
    noOfRatings: noOfReviews,
  };
}

export interface ProductDataPayload
  extends Partial<ProductDataRatingPayload>,
    Partial<ProductMethodPayload>,
    Partial<WeightPayload> {
  barcode?: string;
  currency?: string;
  gtin?: string | null;
  isFavourite?: boolean;
  isInFavourites?: boolean;
  isForSale?: boolean;
  name?: string;
  primaryId?: string;
  promotions?: OfferDataPayload[];
  quantity?: number;
  status?: string;
  secondaryId?: string;
  seedLookup?: string;
  seedProduct?: { tpnb?: string };
  tpna?: string;
  tpnb?: string;
  tpnc?: string;
  isNew?: boolean;
  isSponsoredProduct?: boolean;
  isWhyNotTry?: boolean;
  sellerId?: string | null;
  foodIcons?: Array<string>;
  personalisationModel?: string | null;
}

export function productData(payload?: ProductDataPayload, eventType?: string): ProductData | undefined {
  if (!payload) {
    return;
  }

  const {
    barcode,
    gtin,
    isInFavourites,
    primaryId,
    promotions,
    quantity,
    secondaryId,
    seedLookup,
    seedProduct,
    status,
    tpna,
    tpnb,
    tpnc,
    isNew,
    isSponsoredProduct,
    isWhyNotTry,
    sellerId,
    foodIcons,
    personalisationModel,
  } = payload;

  let attribute: Attribute[] = [];

  if (eventType !== REMOVE_PRODUCT) {
    attribute = [
      ...attribute,
      ...getDietaryAttributeValue(foodIcons),
      ...getSashAnalyticsAttributes({
        isNew,
        isSponsoredProduct,
        isWhyNotTry,
      }),
    ];
  }

  return {
    ...(attribute && attribute?.length > 0 && { attribute }),
    availability: status,
    barcode,
    gtin,
    ...(isInFavourites && { isFavourite: isInFavourites }),
    ...(promotions && promotions?.length > 0 && { offer: promotions?.map(offerData) }),
    primaryId,
    qtyChange: quantity,
    rating: productDataRating(payload),
    secondaryId,
    seedLookup,
    ...(seedProduct?.tpnb && { seedProduct: { tpnb: seedProduct.tpnb } }),
    tpna,
    tpnb,
    tpnc,
    sellerId,
    personalisationModel,
  };
}

export function getProductDataForBasket(payload?: ProductDataPayload, eventType = ''): ProductData | undefined {
  if (!payload) {
    return;
  }

  return {
    ...productData(payload, eventType),
    productMethod: productMethod(payload),
    singlePrice: price(payload),
  };
}
