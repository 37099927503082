import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { getDisplayName } from 'react-recompose';
import { validateRules } from '#/lib/validation/form-validation';
import { updateValidationStateByField } from '#/actions/form-action-creators';
import { getFieldValidationState } from '#/reducers/form';
import { connect } from '#/lib/render/connect-deep-compare';
import { PropTypeRules } from '#/components/shared/input-with-label';

const validateField = () => WrappedComponent => {
  class ValidateField extends PureComponent {
    static propTypes = {
      defaultValue: PropTypes.string,
      errorMessage: PropTypes.string,
      fieldName: PropTypes.string.isRequired,
      formName: PropTypes.string.isRequired,
      hasError: PropTypes.bool.isRequired,
      multiLine: PropTypes.bool,
      placeholder: PropTypes.string,
      rules: PropTypeRules,
      updateValidationStateByField: PropTypes.func.isRequired
    };

    static defaultProps = {
      rules: [],
      multiline: false,
      placeholder: '',
      defaultValue: ''
    };

    updateValidationState = value => {
      const { fieldName, formName, rules } = this.props;
      const validationState = validateRules(rules, value);
      this.props.updateValidationStateByField(
        formName,
        fieldName,
        validationState
      );
    };

    render() {
      return (
        <WrappedComponent
          updateFieldValidationState={this.updateValidationState}
          {...this.props}
        />
      );
    }
  }

  ValidateField.displayName = `ValidateField(${getDisplayName(
    WrappedComponent
  )})`;
  ValidateField.WrappedComponent = WrappedComponent;

  const mapStateToProps = (state, { fieldName, formName }) =>
    getFieldValidationState(state, formName, fieldName);

  return connect(mapStateToProps, {
    updateValidationStateByField
  })(ValidateField);
};

export default validateField;
