import styled from 'styled-components';
import { spacing } from '@ddsweb/selectors';
import { Text } from '@ddsweb/text';
import { MEDIUM } from '#/components/product-tiles/temp-style-constants';

export const GreyBackground = styled.div`
  display: flex;
  padding: ${spacing.xx};
  margin-top: ${spacing.xx};
  background-color: #e5e8ea;
  height: 56px;
`;

export const WhiteBackground = styled.div`
  display: flex;
  padding: ${spacing.xx} 0;
  margin-top: ${spacing.sm};
  height: 56px;
  @media ${MEDIUM} {
    margin: ${spacing.xx} 0;
`;

export const DarkGreyBodyText = styled(Text)`
  && {
    padding-top: ${spacing.sm};
    font-weight: bold;
  }
`;
