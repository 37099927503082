import React from 'react';
import { connect } from '#/lib/render/connect-deep-compare';
import { PromoContent } from '#/components/products/product-promotion';
import Link from '#/components/link-check-spa';
import { getPromotionDateDisplayVariantWithState } from '#/experiments/oop-892/selectors';
import { promotionDateDisplayFeature } from '#/experiments/oop-892/constants';
import { hasValidSlot } from '#/selectors/trolley';
import helpers from '#/lib/decorators/helpers';
import { TTranslateFunc } from '#/lib/records/helpers.defs';
import { getProductItem } from '#/selectors/product-details';
import { getProductDrsChargeAmount } from '#/selectors/item';
import { getPromotionSecondaryText } from '../product-tile/helpers';
import { Promotion } from '#/lib/records/item.defs';

type ClickHandler = (...args: any[]) => void;

export type TSpecialOffersTextComponent = {
  promotionsLink: string;
  handleClick: ClickHandler;
  hasLink: boolean;
  offerText: string;
  promotionsText: string;
  oop892variant: string;
  validSlotBooked: boolean;
  productDrsChargeAmount: number | null;
  pricePerUnit?: string;
  t: TTranslateFunc;
  hasDeposit: boolean;
};

const shouldShowDateTermsText = (validSlotBooked: boolean, oop892variant: string): boolean => {
  return oop892variant === promotionDateDisplayFeature.variants.showActivePromotionsWithoutDateRanges
    ? !validSlotBooked
    : true;
};

type IStoreProps = {
  validSlotBooked: boolean;
  oop892variant: string;
  productDrsChargeAmount: number | null;
  pricePerUnit?: string;
};

const mapStateToProps = (state: Store, { promoData }: { promoData: Promotion }): IStoreProps => {
  return {
    oop892variant: getPromotionDateDisplayVariantWithState(state),
    validSlotBooked: hasValidSlot(state),
    productDrsChargeAmount: getProductDrsChargeAmount(getProductItem(state)),
    pricePerUnit: promoData?.unitSellingInfo,
  };
};

export const SpecialOffersText = ({
  promotionsLink,
  handleClick,
  hasLink,
  offerText,
  promotionsText,
  oop892variant,
  validSlotBooked,
  productDrsChargeAmount,
  pricePerUnit,
  t: translate,
}: TSpecialOffersTextComponent): JSX.Element => {
  const content = (
    <PromoContent
      offerText={offerText}
      promotionsText={promotionsText}
      showPromotionsText={shouldShowDateTermsText(validSlotBooked, oop892variant)}
      promotionSecondaryText={getPromotionSecondaryText({
        translate,
        productDrsChargeAmount,
        pricePerUnit,
      })}
    />
  );

  if (hasLink) {
    return (
      <Link to={promotionsLink} onClick={handleClick}>
        {content}
      </Link>
    );
  }

  return content;
};

export default connect(mapStateToProps)(helpers(['t'])(SpecialOffersText));
