import { recursive as recursiveMerge } from 'merge';
import { getParsedObject } from '../string-formatting/format';

export const mergeValuesAndStringify = (oldValue, delta) => {
  if (oldValue) {
    const parsedValue = getParsedObject(oldValue);

    return parsedValue
      ? JSON.stringify(recursiveMerge(true, parsedValue, delta))
      : JSON.stringify(delta);
  }

  return JSON.stringify(delta);
};

export const parseString = stringToParse => {
  const parsedObject = getParsedObject(stringToParse);

  return parsedObject ? parsedObject : stringToParse;
};
