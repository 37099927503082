import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Link from '../../link-check-spa';
import helpers from '../../../lib/decorators/helpers';

const PromotionListItem = ({ count, l, link, name, red, t, onClick }) => (
  <li className="list-item list-item-large">
    <Link
      to={l(link)}
      onClick={e => {
        e.stopPropagation();
        onClick?.();
      }}
    >
      <span className="label-wrapper">
        <span
          className={classnames('name', {
            red: red
          })}
        >
          {name}
        </span>
        {count && (
          <span className="promotions--count">
            {`(${count})`}
            <span className="visually-hidden">{t('search:results')}</span>
          </span>
        )}
      </span>
    </Link>
  </li>
);

PromotionListItem.propTypes = {
  count: PropTypes.number,
  l: PropTypes.func.isRequired,
  link: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  red: PropTypes.bool,
  t: PropTypes.func.isRequired
};

PromotionListItem.defaultProps = {
  red: false
};

export default helpers(['c', 'l', 't'])(PromotionListItem);
