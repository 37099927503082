import React, { useState, useEffect } from 'react';
import { StandaloneLink } from '@ddsweb/link';
import { MD } from '@ddsweb/constants';
import helpers from '#/lib/decorators/helpers';
import { connect } from '#/lib/render/connect-deep-compare';
import { getSlotsPendingStatus, getSelectedShoppingMethod } from '#/reducers/slot';
import { getDisplayBusinessAddressModal } from '#/reducers/ui';
import { getIsFulfillableAddress } from '#/selectors/detailed-addresses';
import { isClickAndCollectShoppingMethod, isOnDemandShoppingMethod } from '#/lib/shopping-method-util';
import { SectionMessage } from '#/components/cms-driven/section-message';
import DCSContextualBanner from '#/components/pages/book-a-slot/dcs-banner/dcs-contextual-banner';
import ShoppingInstruction from '#/components/pages/book-a-slot/instructions/shopping-instruction';
import AgeRestrictionMessage from '#/components/pages/book-a-slot/messages/age-restriction-message';
import CollectionStepsSection from '#/components/pages/book-a-slot/messages/collection-steps-section';
import BookASlotUndeliverableAddressModal from '#/components/modals/book-a-slot-undeliverable-address-modal';
import SlotSelectorSection from '#/components/pages/book-a-slot/slots-section/slot-selector-section';
import SlotContextCardSection from '#/components/pages/book-a-slot/slot-context-card-section';
import { TrolleyState } from '#/custom-typings/redux-store/trolley.defs';
import { getTrolleyShoppingMethod, hasBookedSlot } from '#/selectors/trolley';
import { TConfigFunc, TTranslateFunc } from '#/lib/records/helpers.defs';
import { getIsHIWSectionPositionAtTop } from '#/experiments/oop-1423/selectors';
import InteractiveStarRating from '#/components/star-rating/interactive-star-rating';
import { DeliverySaverInfo } from '#/components/delivery-saver-info';
import { hasActiveDeliverySaverSubscriptions } from '#/reducers/subscriptions';
import { getShowSlotUIReskinV2 } from '#/experiments/oop-2205/selectors';
import OnDemandRestrictionMessage from '#/components/pages/book-a-slot/messages/on-demand-restriction-message';
import { VisuallyHidden } from '#/components/shared/styled';
import { ACTIVE_BANNER_IMPRESSION, DELAY, UI_EXPERIMENT_RENDERED } from '#/analytics/constants';
import getAnalyticsBus from '#/analytics/analyticsBus';
import { StylesStatusMessaging } from './styled';
import { getLanguage } from '#/reducers/app';
import { basicEvent } from '#/analytics/types/basic';
import analyticsBus from '#/analytics/analyticsBus';

export type IStateProps = {
  areSlotsPending: boolean;
  selectedShoppingMethod: TrolleyState['shoppingMethod'];
  showCollectionSteps: boolean;
  isFulfillableAddress: boolean;
  displayBusinessAddressModal: boolean;
  hasBookedSlot: boolean;
  currentShoppingMethod: string;
  isSlotUIReskin: boolean;
  hasDeliverySaverPlan: boolean;
  language: string;
};

export const mapStateToProps = (state: Store, ownProps: { c: (key: string) => string }): IStateProps => {
  const selectedShoppingMethod = getSelectedShoppingMethod(state);
  const hasDeliverySaverPlan = hasActiveDeliverySaverSubscriptions(state.subscriptions);

  return {
    areSlotsPending: getSlotsPendingStatus(state),
    selectedShoppingMethod,
    isFulfillableAddress: getIsFulfillableAddress(state),
    showCollectionSteps:
      isClickAndCollectShoppingMethod(selectedShoppingMethod) &&
      !!ownProps.c('help:showClickCollect') &&
      !getIsHIWSectionPositionAtTop(state),
    displayBusinessAddressModal: getDisplayBusinessAddressModal(state),
    hasBookedSlot: hasBookedSlot(state),
    currentShoppingMethod: getTrolleyShoppingMethod(state),
    isSlotUIReskin: getShowSlotUIReskinV2(state),
    hasDeliverySaverPlan,
    language: getLanguage(state),
  };
};

export interface TOwnProps extends IStateProps {
  c: TConfigFunc;
  t: TTranslateFunc;
}

const SlotsSection: React.FC<TOwnProps> = (props: TOwnProps) => {
  const {
    c: config,
    t: translate,
    selectedShoppingMethod,
    isFulfillableAddress,
    showCollectionSteps,
    displayBusinessAddressModal,
    hasBookedSlot,
    currentShoppingMethod,
    isSlotUIReskin,
    hasDeliverySaverPlan,
    language,
  } = props;

  const isOnDemandDeliveryPage = isOnDemandShoppingMethod(selectedShoppingMethod);

  const isCurrentOnDemand = isOnDemandShoppingMethod(currentShoppingMethod);

  const [isSlotExpired, setSlotExpired] = useState(false);

  const showAgeRestriction = config('showAgeRestrictionMessage');
  const showSlotBookingSurvey = config('showSlotBookingSurvey');

  const showBookASlotOpinionLabSurvey = showSlotBookingSurvey && hasBookedSlot;

  const getSlotBookingStarRatingConfig = (): { legendAriaLabel: string; legendText: string; refererUrl: string } => {
    const { t: translate, c: config } = props;
    const textRoot = 'slots:slot-booking-rating';
    const legendText = translate(`${textRoot}.rating-label`);
    const legendAriaLabel = translate(`${textRoot}.rating-aria-label`);

    return {
      legendAriaLabel,
      legendText,
      refererUrl: config('opinionLab:refererUrls:slotBooking') as string,
    };
  };

  const renderAgeRestrictionMessage = (): React.ReactElement | null => {
    if (isCurrentOnDemand || isOnDemandDeliveryPage) return <OnDemandRestrictionMessage />;

    if (showAgeRestriction) return <AgeRestrictionMessage />;

    return null;
  };

  const renderViewAccountLink = (): JSX.Element => (
    <StandaloneLink
      href={config(`links:deliverySaverAccount:${language}`).toString()}
      target="_blank"
      rel="noopener"
      textSize={MD}
    >
      <span aria-hidden="true">{translate(`slots:delivery-saver.view-account-text-link`)}</span>
      <VisuallyHidden data-visually-hidden>
        {translate(`slots:delivery-saver.view-account-text-link-screen`)}
      </VisuallyHidden>
    </StandaloneLink>
  );

  const renderSectionMessage = (): JSX.Element => (
    <div className="book-a-slot__section-message">
      <SectionMessage size="large" message={translate(`slots:${selectedShoppingMethod}.choose-slot`)} />
    </div>
  );

  if (isOnDemandDeliveryPage) {
    getAnalyticsBus().emit(UI_EXPERIMENT_RENDERED, {
      experimentName: 'OOP-2281',
      variationKey: '2281-LEGO',
    });
  }

  useEffect(() => {
    // invoke analytics event for non whoosh
    if (hasDeliverySaverPlan && !isOnDemandDeliveryPage) {
      basicEvent(analyticsBus, {
        action: DELAY,
        type: 'delivery saver',
        value: ACTIVE_BANNER_IMPRESSION,
      });
    }
  }, [selectedShoppingMethod, hasDeliverySaverPlan]);

  return (
    <div className="content-section">
      <div
        className={`book-a-slot__section-container extended-bot ${
          isSlotUIReskin && !isOnDemandDeliveryPage ? 'book-a-slot__section-container--reskin' : ''
        }`}
      >
        {isSlotUIReskin ? (
          <VisuallyHidden data-visually-hidden className="hide-section-message">
            {renderSectionMessage()}
          </VisuallyHidden>
        ) : (
          renderSectionMessage()
        )}
        {hasDeliverySaverPlan && !isOnDemandDeliveryPage && (
          <DeliverySaverInfo title={translate(`slots:delivery-saver.title`)} headerLink={renderViewAccountLink()}>
            <StylesStatusMessaging variant="success">
              {translate(`slots:delivery-saver.account-active-description`)}
            </StylesStatusMessaging>
          </DeliverySaverInfo>
        )}
        <DCSContextualBanner />
        <SlotSelectorSection isSlotExpired={isSlotExpired} />
        <ShoppingInstruction />

        {isFulfillableAddress && (
          <SlotContextCardSection
            onDemandSlotExpiredCB={(): void => setSlotExpired(true)}
            isSlotExpired={isSlotExpired}
          />
        )}

        {isFulfillableAddress && renderAgeRestrictionMessage()}

        {showCollectionSteps && <CollectionStepsSection />}

        {displayBusinessAddressModal && <BookASlotUndeliverableAddressModal shoppingMethod={selectedShoppingMethod} />}

        {showBookASlotOpinionLabSurvey && <InteractiveStarRating {...getSlotBookingStarRatingConfig()} />}
      </div>
    </div>
  );
};

export default helpers(['t', 'c'])(connect(mapStateToProps)(SlotsSection));
