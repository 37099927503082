export const PAYMENT_ITEM = `fragment PaymentItem on PaymentItemInterface {
    id
    code
    type
    qualified
    selected
    status
    offerText: description
    value
    endDate,
    rewardType,
    clubcardPoints
  }
  `;
