import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import delimitQuery from '../mixins/delimit-query';

const FilterRadioButton = ({
  currentUrl,
  defaultSortValue,
  value,
  sortValue
}) => {
  const queryString = delimitQuery.parseQuerystring(currentUrl);
  const active = queryString.sortBy
    ? queryString.sortBy === value
    : defaultSortValue === value;

  delete queryString.page;

  if (active) {
    delete queryString.sortBy;
  } else {
    queryString.sortBy = value;
  }

  const classes = classnames({
    'filter-radio-icon': true,
    'icon-radio-off': !active,
    'icon-radio-on': active
  });

  return (
    <a
      className="filter-radio-button"
      href={delimitQuery.createHref(currentUrl, queryString)}
    >
      <span className="label-wrapper">
        <span className={classes} />
        <span className="label">{sortValue}</span>
      </span>
    </a>
  );
};

FilterRadioButton.propTypes = {
  currentUrl: PropTypes.string.isRequired,
  defaultSortValue: PropTypes.string.isRequired,
  sortValue: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};

export default FilterRadioButton;
