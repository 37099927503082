import React, { Component, createRef, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Text from '@ddsweb/text';
import { connect } from '#/lib/render/connect-deep-compare';
import helpers from '#/lib/decorators/helpers';
import {
  CHANGE_DETAILS_MODAL_BOOK_A_SLOT_ADD_ADDRESS,
  CHANGE_DETAILS_MODAL_BOOK_A_SLOT_EDIT_ADDRESS,
  INCORRECT_PHONE_NUMBER_MODAL
} from '#/constants/modal-names';
import { CHANGE_DELIVERY_ADDRESS } from '#/constants/slot-warning-modal-types';
import { DELIVERY } from '#/constants/shopping-methods';
import {
  formatSlotIntervalHumanized,
  slotCurrentlyBooked
} from '#/lib/slot/slot-utils';
import { addModalToUrl } from '#/lib/url/modal-utils';
import { isOnDemandShoppingMethod } from '#/lib/shopping-method-util';
import {
  getCurrentUrl,
  getIsDesktop,
  getLanguage,
  getMyAccountUrl,
  getTimezone
} from '#/reducers/app';
import { getHasAcceptedSlotWarningTypes } from '#/reducers/ui';
import {
  getChangingAddressSuccessBanner,
  getCurrentSlotGroup,
  getSelectedCollectionLocationId,
  getSelectedDateSlots,
  getSelectedLocationId,
  getSelectedShoppingMethod,
  getSelectedWeekSlots,
  getSlotsPendingStatus
} from '#/reducers/slot';
import {
  getBookedShoppingMethod,
  getIsAmendBasket,
  getSlot,
  getStoreId,
  getTrolleyDeliveryAddress,
  getTrolleySize
} from '#/selectors/trolley';
import { getAllAddresses } from '#/selectors/addresses';
import { getDeliveryAddressForTrolly as getDetailedDeliveryAddress } from '#/selectors/detailed-addresses';
import {
  getAvailableShoppingMethods,
  getChangeSlotInfo
} from '#/selectors/slot';
import { changeDeliveryAddress } from '#/actions/trolley/trolley-action-creators';
import { openModal } from '#/actions/ui-action-creators';
import { showOrderTypeChangeWarning } from '#/actions/slot-action-creators';
import { SectionMessage } from '#/components/cms-driven/section-message';
import OverlaySpinner from '#/components/overlay-spinner';
import { DeliveryMultipleAddressSelector } from '#/components/slots/multiple-address-selector';
import ChevronLink from '#/components/link/chevron-link';
import Phone from '#/components/slots/phone-number';
import {
  StyledAddressHeading,
  StyledHeading,
  StyledInLineMessaging,
  StyledTitle
} from '#/components/slots/detailed-delivery-address/styled';
import { BasicAccordion } from '@ddsweb/accordion';
import { emitSlotOp } from '#/analytics/bertie/events';
import { SHOPPING_METHOD_PROP_TYPE } from '#/components/slots/prop-types';
import { getLocations } from '#/reducers/location';
import TextLink from '#/components/text-link';
import { PHONE_NUMBER_MODAL_TYPES } from '#/components/modals/incorrect-phone-number-modal/constants';
import {
  ACCORDION_COLLAPSE,
  ACCORDION_EXPAND,
  ADD_ADDRESS_VALUE,
  CHANGE_DELIVERY_POINT,
  DROPDOWN,
  EDIT_ADDRESS_VALUE,
  NOW,
  UI_EVENT_BASIC_EVENT
} from '#/analytics/constants';
import analyticsBus from '#/analytics/analyticsBus';
import { CLOSE_CHANGING_ADDRESS_SUCCESS_BANNER } from '#/constants/action-types';
import SafelyOutputString from '#/components/safely-output-string';
import {
  getShowSlotUIReskinV2,
  getShowAddNewAddressHeaderVariant,
  getShowAddNewAddressHeaderAndSimplifyVariant
} from '#/experiments/oop-2205/selectors';
import {
  StyledAddressHeadingReskin,
  DeliveryAddressCard
} from '#/experiments/oop-2205/components/styled';
import { formatAddress } from '#/experiments/oop-2205/components/helpers';

export const mapStateToProps = (state, ownProps) => {
  const selectedShoppingMethod = getSelectedShoppingMethod(state);
  const isAmendBasket = getIsAmendBasket(state);
  const isOnDemandDelivery = isOnDemandShoppingMethod(selectedShoppingMethod);
  const availableShoppingMethods = getAvailableShoppingMethods(state);
  const showDeliveryAddressInAccordion = !isAmendBasket;

  return {
    areSlotsPending: getSlotsPendingStatus(state),
    availableShoppingMethods,
    changeAddressCookieAccepted: state.ui.changeAddressCookieAccepted,
    changeSlotInfo: getChangeSlotInfo(state),
    changingAddressSuccessBanner: getChangingAddressSuccessBanner(state),
    currentSlot: getSlot(state),
    currentUrl: getCurrentUrl(state),
    deliveryAddresses: getAllAddresses(state),
    detailedDeliveryAddress: getDetailedDeliveryAddress(state),
    hasAcceptedSlotWarningTypes: getHasAcceptedSlotWarningTypes(
      state,
      CHANGE_DELIVERY_ADDRESS
    ),
    isDesktop: getIsDesktop(state),
    isOnDemandDelivery,
    itemCount: getTrolleySize(state),
    locationId: getSelectedLocationId(state),
    selectedDateSlots: getSelectedDateSlots(state),
    selectedShoppingMethod,
    selectedSlotGroup: getCurrentSlotGroup(state),
    selectedWeekSlots: getSelectedWeekSlots(state),
    shoppingMethod: getBookedShoppingMethod(state),
    showDeliveryAddressInAccordion,
    storeId: getStoreId(state),
    trolleyDeliveryAddress: getTrolleyDeliveryAddress(state),
    selectedCollectionLocationId: getSelectedCollectionLocationId(state),
    locations: getLocations(state),
    timezone: getTimezone(state),
    language: getLanguage(state),
    addressBookUrl: getMyAccountUrl(
      state,
      ownProps.c('externalAddressBookUrl')
    ),
    isSlotUIReskinExperiment: getShowSlotUIReskinV2(state),
    isAddNewAdressHeaderVariant: getShowAddNewAddressHeaderVariant(state),
    isAddNewAdressHeaderAndSimplifyVariant: getShowAddNewAddressHeaderAndSimplifyVariant(
      state
    )
  };
};

@helpers(['l', 't', 'c', 'f'])
@connect(mapStateToProps, dispatch => ({
  ...bindActionCreators(
    {
      changeDeliveryAddress,
      openModal,
      showOrderTypeChangeWarning
    },
    dispatch
  ),
  dispatch
}))
export default class DetailedDeliveryAddress extends Component {
  static propTypes = {
    addressId: PropTypes.string,
    areSlotsPending: PropTypes.bool,
    bookedDeliveryAddress: PropTypes.object,
    c: PropTypes.func.isRequired,
    changeAddressCookieAccepted: PropTypes.bool,
    changeDeliveryAddress: PropTypes.func.isRequired,
    changeSlotInfo: PropTypes.object,
    changingAddressSuccessBanner: PropTypes.bool,
    currentSlot: PropTypes.object,
    currentUrl: PropTypes.string.isRequired,
    deliveryAddresses: PropTypes.array,
    detailedDeliveryAddress: PropTypes.object,
    dispatch: PropTypes.func,
    f: PropTypes.func.isRequired,
    hasAcceptedSlotWarningTypes: PropTypes.bool.isRequired,
    isDesktop: PropTypes.bool.isRequired,
    isOnDemandDelivery: PropTypes.bool,
    itemCount: PropTypes.number.isRequired,
    l: PropTypes.func.isRequired,
    language: PropTypes.string,
    locationId: PropTypes.string,
    openModal: PropTypes.func,
    selectedDateSlots: PropTypes.array.isRequired,
    selectedShoppingMethod: SHOPPING_METHOD_PROP_TYPE,
    selectedSlotGroup: PropTypes.number,
    selectedWeekSlots: PropTypes.object.isRequired,
    shoppingMethod: PropTypes.string,
    showDeliveryAddressInAccordion: PropTypes.bool,
    showOrderTypeChangeWarning: PropTypes.func,
    storeId: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    timezone: PropTypes.string.isRequired,
    trolleyDeliveryAddress: PropTypes.object,
    addressBookUrl: PropTypes.string,
    isSlotUIReskinExperiment: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.linkToMartini = props.c('linkToMartini');
    this.allowMultipleAddresses = props.f('allowMultipleAddresses');
    this.accordionButtonRef = createRef();
  }

  oneAccountLink = (linkText, modal, modalData = null, eventValue = null) => {
    const handleClick = e => {
      e && e.preventDefault();
      if (eventValue) {
        analyticsBus().emit(UI_EVENT_BASIC_EVENT, {
          action: NOW,
          type: `${ACCORDION_EXPAND}:${CHANGE_DELIVERY_POINT}`,
          value: eventValue
        });
      }
      this.props.openModal(modal, modalData);
    };

    return (
      <TextLink
        url={addModalToUrl(this.props.currentUrl, modal)}
        textKey={linkText}
        onClick={handleClick}
      />
    );
  };

  state = { isLoading: false };

  componentDidUpdate(prevProps) {
    const { selectedShoppingMethod } = this.props;
    if (
      this.accordionButtonRef.current &&
      selectedShoppingMethod &&
      selectedShoppingMethod !== prevProps.selectedShoppingMethod
    ) {
      // there is a issue with Accordion we need to close Accordion when we change selectedShoppingMethod
      const ariaExpanded = this.accordionButtonRef.current.getAttribute(
        'aria-expanded'
      );
      if (ariaExpanded) {
        this.accordionButtonRef.current.click();
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.areSlotsPending !== prevState.isLoading) {
      return { isLoading: nextProps.areSlotsPending };
    }
    return null;
  }

  renderDeliveringTo(address) {
    const {
      t: translate,
      isOnDemandDelivery,
      isSlotUIReskinExperiment,
      isAddNewAdressHeaderVariant,
      isAddNewAdressHeaderAndSimplifyVariant
    } = this.props;

    const phoneNumber = address.mobilePhone;

    const text = phoneNumber
      ? translate('slots:delivery.edit-mobile')
      : translate('slots:delivery.add-new-mobile');

    const showAddressDetails = formatAddress(address);

    const getModalData = () => {
      const { ADD_NUMBER, EDIT_NUMBER } = PHONE_NUMBER_MODAL_TYPES;
      const data = {
        action: 'add',
        type: ADD_NUMBER,
        delivery: this.props.selectedShoppingMethod
      };
      if (phoneNumber) {
        data.action = 'edit';
        data.type = EDIT_NUMBER;
      }
      return data;
    };

    let headerText = '';
    if (isSlotUIReskinExperiment) {
      headerText = isAddNewAdressHeaderVariant
        ? translate('slots:oop2205.2205-c.delivery-address-heading')
        : translate('slots:oop2205.delivery-address-heading');
    } else {
      headerText = translate('slots:delivery.delivering-to');
    }

    return (
      <div
        className="book-a-slot__delivering-to-address"
        id="deliveringToAddress"
      >
        {isAddNewAdressHeaderVariant && (
          <StyledTitle headingLevel="2" visualSize="headline4">
            {translate('slots:oop2205.2205-c.your-delivery-address')}
          </StyledTitle>
        )}
        {isAddNewAdressHeaderAndSimplifyVariant && (
          <StyledTitle headingLevel="2" visualSize="headline4">
            {translate('slots:oop2205.2205-d.we-will-deliver-to')}
          </StyledTitle>
        )}
        {!isAddNewAdressHeaderAndSimplifyVariant && (
          <StyledHeading headingLevel={'3'} visualSize="headline5">
            {headerText}
          </StyledHeading>
        )}

        <Text as="p">{showAddressDetails}</Text>
        {!isSlotUIReskinExperiment && isOnDemandDelivery && (
          <div id="deliveringToMobile">
            <StyledHeading
              headingLevel="3"
              visualSize="headline5"
              className="book-a-slot__delivering-to-mobile"
            >
              {translate('common:mobile-number')}
            </StyledHeading>
            <Text as="p">
              {address.mobilePhone || ''}
              {'  '}
              <br className="hidden-medium hidden-large" />
              {this.oneAccountLink(
                text,
                INCORRECT_PHONE_NUMBER_MODAL,
                getModalData(),
                null
              )}
            </Text>
          </div>
        )}
      </div>
    );
  }

  renderChangeDeliveryDetails(address, addressId) {
    const {
      c: config,
      t: translate,
      changeSlotInfo,
      changingAddressSuccessBanner,
      dispatch,
      timezone,
      language,
      isSlotUIReskinExperiment
    } = this.props;

    let data = {};
    if (changeSlotInfo) {
      const { start, end } = changeSlotInfo;
      data = formatSlotIntervalHumanized(
        start,
        end,
        timezone,
        language,
        config
      );
    }

    return (
      <div className="book-a-slot__delivery-address book-a-slot__delivery-address--change-delivery-details">
        {changingAddressSuccessBanner && (
          <StyledInLineMessaging
            variant="success"
            assistiveText={translate('common:success')}
            withClose
            onCloseClick={() =>
              dispatch({
                type: CLOSE_CHANGING_ADDRESS_SUCCESS_BANNER
              })
            }
          >
            <Text>
              <SafelyOutputString>
                {translate('slots:changing-address.good-news-message', data)}
              </SafelyOutputString>
            </Text>
          </StyledInLineMessaging>
        )}
        {this.renderAddressSelector(addressId, address.name)}
        <div className="book-a-slot__delivery-address--wrapper">
          {isSlotUIReskinExperiment ? (
            <StyledAddressHeadingReskin
              headingLevel="4"
              className="book-a-slot__delivery-address--content book-a-slot__delivery-address--label"
            >
              {translate('account:address')}
            </StyledAddressHeadingReskin>
          ) : (
            <StyledAddressHeading className="book-a-slot__delivery-address--content book-a-slot__delivery-address--label">
              {translate('account:address')}
            </StyledAddressHeading>
          )}
          <div
            className="book-a-slot__delivery-address--content book-a-slot__delivery-address--address"
            data-auto="address-content"
          >
            {formatAddress(address)}
          </div>
        </div>
        {isSlotUIReskinExperiment ? (
          <>
            <div className="book-a-slot__delivery-address--wrapper">
              {address.mobilePhone && (
                <>
                  <StyledAddressHeadingReskin
                    headingLevel="4"
                    className="book-a-slot__delivery-address--content book-a-slot__delivery-address--label"
                  >
                    {translate('slots:delivery.mobile-phone')}
                  </StyledAddressHeadingReskin>
                  <div className="book-a-slot__delivery-address--content">
                    {address.mobilePhone}
                  </div>
                </>
              )}
            </div>

            <div className="book-a-slot__delivery-address--links book-a-slot__delivery-address--wrapper">
              {this.getEditAddressLink()}
              {this.getAddAddressLink()}
            </div>
          </>
        ) : (
          <div className="book-a-slot__delivery-address--wrapper">
            <StyledAddressHeading className="book-a-slot__delivery-address--content book-a-slot__delivery-address--label">
              {translate('slots:delivery.contact-details')}
            </StyledAddressHeading>
            <div className="book-a-slot__delivery-address--content">
              {address.primaryPhone && (
                <Phone
                  label={translate('slots:delivery.primary-phone')}
                  phone={address.primaryPhone}
                />
              )}
              {address.secondaryPhone && (
                <Phone
                  label={translate('slots:delivery.secondary-phone')}
                  phone={address.secondaryPhone}
                />
              )}
              {address.mobilePhone && (
                <Phone
                  label={translate('slots:delivery.mobile-phone')}
                  phone={address.mobilePhone}
                />
              )}
              <div className="book-a-slot__delivery-address--links book-a-slot__delivery-address--wrapper">
                {this.getEditAddressLink()}
                {this.getAddAddressLink()}
              </div>
            </div>
          </div>
        )}
        <OverlaySpinner isLoading={this.state.isLoading} />
      </div>
    );
  }

  slotBooked() {
    const { currentSlot } = this.props;

    return currentSlot && slotCurrentlyBooked(currentSlot);
  }

  deliverySlotBooked() {
    return this.props.shoppingMethod === DELIVERY && this.slotBooked();
  }

  cookiePreferencesSetForAddressChange() {
    return (
      this.props.changeAddressCookieAccepted ||
      this.props.hasAcceptedSlotWarningTypes
    );
  }
  showWarning() {
    return (
      this.props.c('showOrderTypeChangeWarning') &&
      this.props.itemCount &&
      this.slotBooked() &&
      !this.cookiePreferencesSetForAddressChange()
    );
  }

  getSubmitMethod() {
    if (this.showWarning()) {
      return 'GET';
    }

    return 'POST';
  }

  getSubmitUrl() {
    if (this.showWarning()) {
      return this.props.currentUrl;
    }

    return this.props.c('changeAddressUrl');
  }

  handleAddressChange = async e => {
    const { currentSlot, selectedSlotGroup } = this.props;
    const hasDeliverySlotBooked = this.deliverySlotBooked();
    const slotStart = hasDeliverySlotBooked ? currentSlot.start : '';
    const slotEnd = hasDeliverySlotBooked ? currentSlot.end : '';
    const slotGroup = hasDeliverySlotBooked
      ? currentSlot.group
      : selectedSlotGroup;

    e.stopPropagation();
    if (this.showWarning()) {
      const changeAddressInfo = {
        start: slotStart,
        end: slotEnd,
        addressId: e.target.value,
        modalType: CHANGE_DELIVERY_ADDRESS
      };

      return this.props.showOrderTypeChangeWarning(changeAddressInfo);
    }

    this.setState({
      isLoading: true
    });

    const response = await this.props.changeDeliveryAddress(
      e.target.value,
      slotStart,
      slotEnd,
      slotGroup
    );

    if (response) {
      analyticsBus().emit(UI_EVENT_BASIC_EVENT, {
        action: NOW,
        type: `${ACCORDION_EXPAND}:${CHANGE_DELIVERY_POINT}`,
        value: DROPDOWN
      });
    }

    this.emitSlotEvent();
  };

  emitSlotEvent() {
    const {
      isDesktop,
      locationId,
      selectedDateSlots,
      selectedShoppingMethod,
      selectedWeekSlots,
      storeId,
      detailedDeliveryAddress: address,
      timezone
    } = this.props;

    emitSlotOp(
      isDesktop ? selectedWeekSlots : selectedDateSlots,
      selectedShoppingMethod,
      storeId,
      locationId,
      address.id,
      timezone
    );
  }

  getEditAddressLink() {
    const linkText = this.props.t('slots:delivery.edit-details');

    if (this.linkToMartini) {
      return this.oneAccountLink(
        linkText,
        CHANGE_DETAILS_MODAL_BOOK_A_SLOT_EDIT_ADDRESS,
        null,
        EDIT_ADDRESS_VALUE
      );
    }

    const onEditClick = () => {
      analyticsBus().emit(UI_EVENT_BASIC_EVENT, {
        action: NOW,
        type: `${ACCORDION_EXPAND}:${CHANGE_DELIVERY_POINT}`,
        value: EDIT_ADDRESS_VALUE
      });
    };

    return (
      <ChevronLink to={this.props.addressBookUrl} onClick={onEditClick}>
        {linkText}
      </ChevronLink>
    );
  }

  getAddAddressLink() {
    if (this.linkToMartini && this.allowMultipleAddresses) {
      return this.oneAccountLink(
        this.props.t('slots:delivery.add-new-address'),
        CHANGE_DETAILS_MODAL_BOOK_A_SLOT_ADD_ADDRESS,
        null,
        ADD_ADDRESS_VALUE
      );
    }

    return null;
  }

  businessAddressWarning = () => {
    const {
      trolleyDeliveryAddress: { isBusinessAddress },
      isOnDemandDelivery
    } = this.props;
    const showBusinessAddressWarning = isBusinessAddress && !isOnDemandDelivery;

    return showBusinessAddressWarning ? (
      <div className="undeliverable-address-warning">
        {this.props.t('slots:delivery.business-address-warning')}
        <br />
        <a
          href={this.props.c('links:homeDeliveryTab')}
          rel="noopener"
          target="_blank"
        >
          {this.props.t('slots:delivery.business-address-warning-link')}
          <span className="icon icon-question-dark" />
        </a>
      </div>
    ) : null;
  };

  blockedAddressWarning = () => {
    const {
      trolleyDeliveryAddress: { isBlockedAddress },
      isOnDemandDelivery
    } = this.props;
    const showBlockedAddressWarning = isBlockedAddress && !isOnDemandDelivery;

    return (
      showBlockedAddressWarning && (
        <div className="undeliverable-address-warning">
          {this.props.t('slots:delivery.blocked-address-warning', {
            address: this.props.trolleyDeliveryAddress.name
          })}
          <br />
          <a
            href={this.props.c('links:homeDeliveryTab')}
            rel="noopener"
            target="_blank"
          >
            {this.props.t('slots:delivery.blocked-address-warning-link')}
            <span className="icon icon-question-dark" />
          </a>
        </div>
      )
    );
  };

  renderAddressSelector(addressId, addressName) {
    const {
      currentSlot,
      selectedSlotGroup,
      t,
      deliveryAddresses,
      trolleyDeliveryAddress: { isBusinessAddress },
      isSlotUIReskinExperiment
    } = this.props;

    const hasDeliverySlotBooked = this.deliverySlotBooked();
    const showAddressSelector =
      deliveryAddresses?.length > 1 && this.allowMultipleAddresses;
    const slotStart = hasDeliverySlotBooked ? currentSlot.start : '';
    const slotEnd = hasDeliverySlotBooked ? currentSlot.end : '';
    const slotGroup = hasDeliverySlotBooked
      ? currentSlot.group
      : selectedSlotGroup;
    const showChangeAddressModal = this.showWarning();
    const submitUrl = this.getSubmitUrl();
    const submitMethod = this.getSubmitMethod();

    let addressLabel;
    let addressSelector;

    if (showAddressSelector) {
      const addressSelectorId = 'delivery-address-selector';

      addressLabel = (
        <label
          className="book-a-slot__delivery-address--label"
          htmlFor={addressSelectorId}
        >
          {isSlotUIReskinExperiment
            ? t('slots:oop2205.choose-address')
            : t('slots:delivery.choose-delivery-address')}
        </label>
      );
      addressSelector = (
        <DeliveryMultipleAddressSelector
          submitUrl={submitUrl}
          method={submitMethod}
          onChange={this.handleAddressChange}
          slotStart={slotStart}
          slotEnd={slotEnd}
          showChangeAddressModal={showChangeAddressModal}
          slotGroup={slotGroup}
          defaultAddressId={addressId}
          identifier={addressSelectorId}
        />
      );
    } else {
      addressLabel = (
        <StyledAddressHeading
          headingLevel="4"
          className="book-a-slot__delivery-address--label"
        >
          {t('slots:delivery.delivery-address-title')}
        </StyledAddressHeading>
      );
      addressSelector = (
        <span className="book-a-slot__delivery-address-name">
          {addressName}
        </span>
      );
    }

    const addressSelectorFragment = (
      <div
        className={
          isSlotUIReskinExperiment
            ? 'book-a-slot__delivery-address--wrapper book-a-slot__delivery-address--selector'
            : 'book-a-slot__delivery-address--wrapper'
        }
      >
        <div className="book-a-slot__delivery-address--content">
          {addressLabel}
        </div>
        <div className="book-a-slot__delivery-address--content">
          {addressSelector}
        </div>
      </div>
    );

    // If isSlotUIReskinExperiment then we hide the address selection if only one address
    return (
      <>
        {isBusinessAddress
          ? this.businessAddressWarning()
          : this.blockedAddressWarning()}

        {isSlotUIReskinExperiment
          ? showAddressSelector && addressSelectorFragment
          : addressSelectorFragment}
      </>
    );
  }

  render() {
    const {
      detailedDeliveryAddress: address,
      t: translate,
      showDeliveryAddressInAccordion,
      isSlotUIReskinExperiment
    } = this.props;
    const addressId = address.id;

    const handleAccordionClick = event => {
      const isOpen = event.target.ariaExpanded;
      analyticsBus().emit(UI_EVENT_BASIC_EVENT, {
        action: NOW,
        type: isOpen === 'true' ? ACCORDION_COLLAPSE : ACCORDION_EXPAND,
        value: CHANGE_DELIVERY_POINT
      });
    };

    if (isSlotUIReskinExperiment) {
      return (
        <DeliveryAddressCard className="book-a-slot__delivery-address--reskin">
          {showDeliveryAddressInAccordion ? (
            <>
              {this.renderDeliveringTo(address)}
              <BasicAccordion
                behaviour="toggle"
                content={[
                  {
                    headingLevel: 'h3',
                    heading: translate('slots:oop2205.change-delivery-address'),
                    id: 'change-delivery-details-address',
                    buttonRef: this.accordionButtonRef,
                    panel: this.renderChangeDeliveryDetails(address, addressId),
                    handlePress: handleAccordionClick,
                    iconClassName: 'iconOne',
                    iconVariant: isSlotUIReskinExperiment
                      ? 'secondary'
                      : 'primary'
                  }
                ]}
                id="change-delivery-details"
                data-auto="change-delivery-details-accordion"
                containerClassName="book-a-slot__delivery-address--accordion"
              />
            </>
          ) : (
            this.renderChangeDeliveryDetails(address, addressId)
          )}
        </DeliveryAddressCard>
      );
    }

    return (
      <Fragment>
        <div className="book-a-slot__section-message">
          <SectionMessage
            size="large"
            message={translate(`slots:delivery.delivery-address-title`)}
          />
        </div>
        {showDeliveryAddressInAccordion ? (
          <>
            {this.renderDeliveringTo(address)}
            <BasicAccordion
              behaviour="toggle"
              content={[
                {
                  headingLevel: 'h4',
                  heading: translate('slots:delivery.change-delivery-details'),
                  id: 'change-delivery-details-address',
                  buttonRef: this.accordionButtonRef,
                  panel: this.renderChangeDeliveryDetails(address, addressId),
                  handlePress: handleAccordionClick,
                  iconClassName: 'iconOne',
                  iconVariant: 'primary'
                }
              ]}
              id="change-delivery-details"
              data-auto="change-delivery-details-accordion"
            />
          </>
        ) : (
          this.renderChangeDeliveryDetails(address, addressId)
        )}
      </Fragment>
    );
  }
}
