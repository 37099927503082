import React from 'react';
import { StyledLinkComponent } from '#/components/slots/option-tabs/header/styled';

type TDisabledTabProps = {
  showVariant?: boolean;
};

const DisabledTab: React.FC<TDisabledTabProps> = ({ showVariant, ...props }) => {
  if (showVariant) {
    return <StyledLinkComponent {...props} />;
  }

  return <a {...props} />;
};

export default DisabledTab;
