import { checkoutPromotionRecommendationsFeature } from '#/constants/experiments';
import { getFeatureConfigurationFromReqRes } from '#/lib/optimizely-manager';
import { TREX } from '#/constants/common';

export async function getCheckoutRecommendationsConfig(req, res) {
  const {
    featureKey,
    serviceConfig,
    useCustomerId
  } = checkoutPromotionRecommendationsFeature;

  const config = await getFeatureConfigurationFromReqRes(req, res, featureKey, {
    serviceConfig,
    useCustomerId
  });

  return parseCheckoutRecommendationsConfig(config.types);
}

export function parseCheckoutRecommendationsConfig(config) {
  if (!config || config === 'default') {
    return 'default';
  } else {
    try {
      return extractTrexStrategyVariantPairs(JSON.parse(config));
    } catch (err) {
      return [];
    }
  }
}

function extractTrexStrategyVariantPairs(config) {
  const TREX_DELIMITER = '_';
  const output = config.map(({ name, position, pageSize, type }) => {
    let strategy = name;
    let variant;
    if (type === TREX && name.includes(TREX_DELIMITER)) {
      [strategy, variant] = name.split(TREX_DELIMITER);
    }
    return {
      strategy,
      position,
      variant,
      type,
      pageSize
    };
  });

  return output;
}
