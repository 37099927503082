/* eslint react/prop-types:0  */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RenderBrowseCategorySuggestion from '#/components/products/product-tile/tile-components/shared/render-browse-category-suggestion';
import RenderControls from '#/components/products/product-tile/tile-components/shared/render-controls';
import RenderPrice from '#/components/products/product-tile/tile-components/shared/render-price';
import RenderPromotions from '#/components/products/product-tile/tile-components/shared/render-promotions';
import RenderBrowseCategoryLink from '#/components/products/product-tile/tile-components/shared/render-browse-category-link';
import ProductImage from '#/components/products/product-tile/tile-components/product-image';
import ProductTitle from '#/components/products/product-tile/tile-components/product-title';
import helpers from '#/lib/decorators/helpers';
import LineItemPreference from '#/components/products/product-tile/tile-configurations/trolley/itemPreference';
import RenderInfoMessages from '#/components/products/product-tile/tile-components/shared/render-info-messages';
import { connect } from '#/lib/render/connect-deep-compare';
import { getAisleNameFromItem } from '#/reducers/results';
import { getCurrentPathname } from '#/reducers/app';
import {
  getProductShelfId,
  getProductShelfName,
  getProductDefaultImageUrl,
  getProductAisleId,
  getQuantity,
  isProductBrowsableOnPDP,
  getRestOfShelfUrl,
  isInBasket,
  getProductIsForSale,
  getProductTitle,
  hasSubstitution,
  getProductId
} from '#/selectors/item';

const mapStateToProps = (state, { item }) => ({
  aisleName: getAisleNameFromItem(state, item),
  currentPathname: getCurrentPathname(state)
});

@connect(mapStateToProps)
@helpers(['c', 't'])
export default class HaveYouForgottenTile extends Component {
  static propTypes = {
    aisleName: PropTypes.string.isRequired,
    basketFeedbackRender: PropTypes.func.isRequired,
    c: PropTypes.func.isRequired,
    controlProps: PropTypes.object,
    currentPathname: PropTypes.string.isRequired,
    href: PropTypes.string,
    isAmendBasket: PropTypes.bool,
    isAvailableEpwOverride: PropTypes.bool.isRequired,
    item: PropTypes.object.isRequired,
    selectedUnit: PropTypes.string,
    sellableUnitPrice: PropTypes.number,
    showUnavailableMessage: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    userRegistered: PropTypes.bool
  };

  renderTrolleyTile(available) {
    const {
      aisleName,
      basketFeedbackRender,
      currentPathname,
      item,
      isAmendBasket,
      href
    } = this.props;
    const inTrolley = !!getQuantity(item);
    const title = getProductTitle(item);

    return (
      <div
        className="horizontal-tile trolley-tile"
        key="trolley-tile"
        id={`tile-${getProductId(item)}`}
      >
        <div className="tile-content">
          <div className="tile-content--upper">
            <ProductImage
              alt={title}
              hideFromScreenReader={true}
              href={href}
              image={getProductDefaultImageUrl(item)}
              isUnavailable={!available}
              clickable={this.props.productBrowsableOnPDP}
            />
            <div className="product-details--wrapper">
              <div className="product-details--content">
                <ProductTitle
                  id={getProductId(item)}
                  title={title}
                  isPDPBrowsable={isProductBrowsableOnPDP(item)}
                  displayAvailable={available}
                />
                <RenderPromotions
                  isAmendBasket={isAmendBasket}
                  item={item}
                  largeViewPromotion={true}
                  displayAvailable={available}
                />
                <RenderBrowseCategoryLink
                  aisleName={aisleName}
                  aisleId={getProductAisleId(item)}
                  currentPathname={currentPathname}
                  isAvailableOrHasSub={
                    getProductIsForSale(item) || hasSubstitution(item)
                  }
                  restOfShelfUrl={getRestOfShelfUrl(item)}
                  shelfId={getProductShelfId(item)}
                  shelfName={getProductShelfName(item)}
                />
                {!available && (
                  <p className="unavailable-product">
                    <span className="unavailable-product-message">
                      {this.props.t('product-tile:product-unavailable-trolley')}
                    </span>
                  </p>
                )}
              </div>
              <RenderInfoMessages
                cssClassName="product-info-message-section"
                item={item}
                isAmendBasket={this.props.isAmendBasket}
              />
            </div>
            <RenderControls
              isAmendBasket={this.props.isAmendBasket}
              displayAvailable={available}
              updateReturnUrlWithProductId
              {...this.props.controlProps}
            />
          </div>

          <div className="tile-content--lower">
            <div className="tile-content--lower-right">
              {available && (
                <div className="basket-feedback__wrapper">
                  {isInBasket(item) && basketFeedbackRender()}
                </div>
              )}
              {inTrolley && (
                <div className="product-price-total">
                  <RenderPrice
                    isAvailableEpwOverride={this.props.isAvailableEpwOverride}
                    item={item}
                    showTotalPrice={true}
                    sellableUnitPrice={this.props.sellableUnitPrice}
                    selectedUnit={this.props.selectedUnit}
                    displayAvailable={available}
                  />
                </div>
              )}
            </div>
            {inTrolley && (
              <LineItemPreference
                item={item}
                pickerNotesInputLimit={this.props.c('pickerNotesInputLimit')}
              />
            )}
            <RenderInfoMessages
              cssClassName="hidden-medium product-info-section-small"
              item={item}
              isAmendBasket={this.props.isAmendBasket}
            />
          </div>
        </div>
        <RenderPromotions
          isAmendBasket={isAmendBasket}
          item={item}
          displayAvailable={available}
        />
        <RenderBrowseCategorySuggestion
          item={item}
          displayAvailable={available}
          isAvailableEpwOverride={this.props.isAvailableEpwOverride}
        />
      </div>
    );
  }

  render() {
    const { item } = this.props;
    const available =
      getProductIsForSale(item) || this.props.isAvailableEpwOverride;

    return this.renderTrolleyTile(available);
  }
}
