/**
 * A function to take a string written in dot notation style, and use it to
 * find a nested object property inside of an object. reference: https://gist.github.com/jasonrhodes/2321581
 *
 * Useful in a plugin or module that accepts a JSON array of objects, but
 * you want to let the user specify where to find various bits of data
 * inside of each custom object instead of forcing a standardized
 * property list.
 *
 * @param Object object (optional) The object to search
 * @param String nested A dot notation style parameter reference (ie "urls.small")
 *
 * @return the value of the property in question
 */
export const getDescendantProp = (obj, path) =>
  path.split('.').reduce((acc, part) => acc && acc[part], obj);

/**
 * Returns a copy of "obj", containing only the keys matched by the second argument. Second argument can
 * either be an array of key names, e.g.;
 *
 *    pluck({ a: 1, b: 2 }, ['a']) --> { a: 1 }
 *
 * ... or a matcher function, e.g.;
 *
 *    pluck({ a: 1, b: 2 }, (key) => key !== 'a') --> { b: 2 }
 *
 * @param obj
 * @param matcher
 * @returns {{}}
 */
export function pluck(obj, matcher) {
  const matcherFunc =
    typeof matcher === 'function' ? matcher : matcher.includes.bind(matcher);

  return Object.keys(obj).reduce((ret, key) => {
    if (matcherFunc(key)) {
      ret[key] = obj[key];
    }

    return ret;
  }, {});
}
