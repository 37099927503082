export function isClientPerfEnabled(): boolean {
  return (
    !!process.env.CLIENT_SIDE &&
    typeof window !== 'undefined' &&
    !!window.performance &&
    typeof window.performance.mark === 'function' &&
    typeof window.performance.measure === 'function' &&
    typeof window.performance.clearMarks === 'function' &&
    typeof window.performance.clearMeasures === 'function' &&
    typeof window.performance.getEntriesByName === 'function'
  );
}
