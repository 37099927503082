import React from 'react';
import { compose } from 'react-recompose';
import { ConnectedProps } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@ddsweb/button';
import { connect } from '#/lib/render/connect-deep-compare';
import helpers from '#/lib/decorators/helpers';
import analyticsBus from '#/analytics/analyticsBus';
import { NOW, WEIGHT_LIMIT, SIZE_LIMIT } from '#/analytics/constants';
import { ButtonContainer, Content, StyledModal, StyledHeading, StyledBodyText } from './styled';
import { getTrolleyShoppingMethod } from '#/selectors/trolley';
import { isOnDemandDeliveryShoppingMethod } from '#/lib/shopping-method-util';
import { getBasketMaxItemCountLimit } from '#/selectors/trolley';
import { closeModal } from '#/actions/ui-action-creators';

type HelperProps = {
  t: (key: string, options?: object) => string;
};

const triggerBasketAlertAnalytics = (isExceededByVolumeOrWeight: boolean, selectedShoppingMethod: string) => {
  const weightOrSizeLimit = isExceededByVolumeOrWeight ? WEIGHT_LIMIT : SIZE_LIMIT;

  const payload = {
    action: NOW,
    value: weightOrSizeLimit,
    type: `${selectedShoppingMethod} alert`,
  };
  analyticsBus().emit('UIEventBasicEvent', payload);
};

const mapStateToProps = (state: Store) => {
  const selectedShoppingMethod = getTrolleyShoppingMethod(state);

  const basketMaxItemCountLimit = getBasketMaxItemCountLimit(state);
  const isExceededByVolumeOrWeight = isOnDemandDeliveryShoppingMethod(selectedShoppingMethod);
  return {
    isExceededByVolumeOrWeight,
    basketMaxItemCountLimit,
    selectedShoppingMethod,
  };
};

const connector = connect(mapStateToProps, { closeModal });

type TProps = HelperProps & ConnectedProps<typeof connector>;

const BasketCapacityExceedModal = (props: TProps) => {
  const { t: translate, isExceededByVolumeOrWeight, selectedShoppingMethod, closeModal } = props;

  const { bodyText, headingText, info } = (() => {
    const { basketMaxItemCountLimit } = props;

    if (isExceededByVolumeOrWeight) {
      return {
        headingText: translate(`modals:basket-capacity-exceed-modal.title`),
        bodyText: translate(`modals:basket-capacity-exceed-modal.content`),
        info: translate(`modals:basket-capacity-exceed-modal.info`),
      };
    }

    return {
      headingText: translate('trolley:basket.delivery-breached', {
        limit: basketMaxItemCountLimit,
      }),
      bodyText: translate('trolley:basket.delivery-breached-desc', {
        limit: basketMaxItemCountLimit,
      }),
      info: undefined,
    };
  })();

  const lastFocusedElement = document?.activeElement as HTMLElement;

  const onClose = (event: React.SyntheticEvent) => {
    event.preventDefault();
    closeModal();
    triggerBasketAlertAnalytics(isExceededByVolumeOrWeight, selectedShoppingMethod);
    lastFocusedElement?.focus();
  };

  return (
    <StyledModal id="basket-capacity-exceed-modal" open ssr closeLink={false}>
      <Content>
        <StyledHeading headingLevel="4">{headingText}</StyledHeading>
        <StyledBodyText data-auto="basket-breach-message">{bodyText}</StyledBodyText>
        <StyledBodyText>{info}</StyledBodyText>
      </Content>
      <ButtonContainer className="button__actions">
        <Button onClick={onClose} data-auto="close-modal-button">
          {translate('common:ok')}
        </Button>
      </ButtonContainer>
    </StyledModal>
  );
};

BasketCapacityExceedModal.propTypes = {
  basketMaxItemCountLimit: PropTypes.number.isRequired,
  isExceededByVolumeOrWeight: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

const enhance = compose<TProps, {}>(helpers(['t']), connector);
export default enhance(BasketCapacityExceedModal);
