import React, { Component } from 'react';
import PropTypes from 'prop-types';

import helpers from '#/lib/decorators/helpers';
import BrowseCategorySuggestion from '../browse-category-suggestion';
import { connect } from '#/lib/render/connect-deep-compare';
import { getCurrentUrl } from '#/reducers/app';
import {
  getProductShelfName,
  getRestOfShelfUrl,
  getProductIsForSale,
  hasSubstitution
} from '#/selectors/item';
import { getIsOnDemandDelivery } from '#/selectors/trolley';

const mapStateToProps = state => ({
  currentUrl: getCurrentUrl(state),
  isOnDemandDelivery: getIsOnDemandDelivery(state)
});

@helpers(['c'])
@connect(mapStateToProps)
export default class RenderBrowseCategorySuggestion extends Component {
  static propTypes = {
    c: PropTypes.func.isRequired,
    currentUrl: PropTypes.string.isRequired,
    displayAvailable: PropTypes.bool,
    isAvailableEpwOverride: PropTypes.bool,
    isOnDemandDelivery: PropTypes.bool.isRequired,
    item: PropTypes.object.isRequired
  };

  static defaultProps = {
    displayAvailable: false,
    isAvailableEpwOverride: false
  };

  shouldNotRender(restOfShelfUrl) {
    const {
      item,
      currentUrl,
      isAvailableEpwOverride,
      displayAvailable,
      isOnDemandDelivery
    } = this.props;
    const showLink = this.props.c('showProductUnavailableMessages');
    const alreadyOnItemShelf = currentUrl.indexOf(restOfShelfUrl) > -1;

    return (
      isOnDemandDelivery ||
      displayAvailable ||
      getProductIsForSale(item) ||
      hasSubstitution(item) ||
      isAvailableEpwOverride ||
      alreadyOnItemShelf ||
      !showLink ||
      !restOfShelfUrl
    );
  }

  render() {
    const { item } = this.props;
    const restOfShelfUrl = getRestOfShelfUrl(item);

    if (this.shouldNotRender(restOfShelfUrl)) {
      return null;
    }

    return (
      <BrowseCategorySuggestion
        restOfShelfUrl={restOfShelfUrl}
        shelfName={getProductShelfName(item)}
      />
    );
  }
}
