import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import helpers from '#/lib/decorators/helpers';
import InfoMessage from '#/components/products/info-message';
import { WARNING_MESSAGE_BACKGROUND_CLASS } from '#/constants/product-tile.js';
import { getUnavailableMessageKey } from '#/components/products/product-tile/helpers';

@helpers(['t'])
export default class RenderUnavailableProductMessages extends PureComponent {
  static propTypes = {
    cssClassName: PropTypes.string.isRequired,
    hasSubstitution: PropTypes.bool.isRequired,
    isAmendBasket: PropTypes.bool,
    isAvailable: PropTypes.bool.isRequired,
    isAvailableEpwOverride: PropTypes.bool,
    isOnDemandDelivery: PropTypes.bool,
    isTrolley: PropTypes.bool,
    showAsUnavailable: PropTypes.bool,
    status: PropTypes.string.isRequired,
    t: PropTypes.func
  };
  static defaultProps = {
    isTrolley: false,
    isAmendBasket: false,
    isAvailableEpwOverride: false
  };

  render() {
    const {
      cssClassName,
      hasSubstitutions,
      isAmendBasket,
      isOnDemandDelivery,
      isTrolley,
      status,
      showAsUnavailable,
      isAvailable,
      isAvailableEpwOverride,
      t: translate
    } = this.props;

    const unavailable =
      (showAsUnavailable || !isAvailable) && !isAvailableEpwOverride;

    const messageKey = getUnavailableMessageKey(
      hasSubstitutions,
      isAmendBasket,
      isTrolley,
      status,
      isOnDemandDelivery
    );
    return unavailable ? (
      <div className={cssClassName}>
        <InfoMessage
          message={translate(messageKey)}
          backgroundColorClass={WARNING_MESSAGE_BACKGROUND_CLASS}
        />
      </div>
    ) : null;
  }
}
