import { FULL_TROLLEY } from '#/constants/common';
import url from 'url';
import {
  getCurrentUrl,
  getLanguage,
  getLanguageLink,
  getOneCheckoutBaseUrl
} from '#/reducers/app';
import { getHasHYFRecommendations } from '#/reducers/recommendations';
import { isMarketPlaceOnlyBasket } from '#/lib/trolley/trolley-utils';

export const getCheckoutUrlFromState = (state, route) => {
  const baseURL = getOneCheckoutBaseUrl(state).replace(/\/$/, '');
  const language = getLanguage(state);
  return `${baseURL}/checkout/${language}/groceries/${route}`;
};

export function getCheckoutEntryPoint(state) {
  const parsedUrl = url.parse(getCurrentUrl(state));
  delete parsedUrl.search;
  const urlWithoutQuerystring = url.format(parsedUrl);

  if (getLanguageLink(state, FULL_TROLLEY) === urlWithoutQuerystring) {
    return getHasHYFRecommendations(state)
      ? getHYFUrlFromState(state)
      : getOrderSummaryUrlFromState(state);
  } else {
    return getLanguageLink(state, FULL_TROLLEY);
  }
}

export const getOrderSummaryUrl = req => {
  const { c: config } = req;

  const oneCheckoutReleaseUrl = config('oneCheckoutBaseUrl');

  return `${(oneCheckoutReleaseUrl || '').replace(/\/$/, '')}/checkout/${
    req.language
  }/groceries/order-summary`;
};

export const getOrderSummaryUrlFromState = state =>
  getCheckoutUrlFromState(state, 'order-summary');

export const getHYFUrlFromState = state =>
  getCheckoutUrlFromState(state, 'have-you-forgotten');

export const getRecommendationsUrlFromState = state =>
  getCheckoutUrlFromState(state, 'recommendations');

export const getPaymentOptionsLink = state => {
  const baseURL = getOneCheckoutBaseUrl(state).replace(/\/$/, '');
  const language = getLanguage(state);
  return `${baseURL}/checkout/api/groceries/payment-options?locale=${language}&groceries=${!isMarketPlaceOnlyBasket(
    state.trolley
  )}`;
};
