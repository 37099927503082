import React from 'react';
import PropTypes from 'prop-types';
import LazyImage from '../../../image/lazy-image';
import Link from '../../../link-check-spa';

const componentClassName = 'slim-trade-banner__banner-brand';

function renderBannerImage(link) {
  return (
    <LazyImage
      className="slim-trade-banner__banner-brand-img"
      src={link.href}
      alt={link.bannerImageAltText}
    />
  );
}

const ImageBanner = props => {
  const { link } = props;
  const hasLink = !!link.bannerImageLinkURL;

  if (hasLink) {
    return (
      <Link
        className={componentClassName}
        to={link.bannerImageLinkURL}
        title={link.bannerImageBrandName}
      >
        {renderBannerImage(link)}
      </Link>
    );
  } else {
    return <div className={componentClassName}>{renderBannerImage(link)}</div>;
  }
};

ImageBanner.propTypes = {
  link: PropTypes.shape({
    href: PropTypes.string.isRequired,
    bannerImageLinkURL: PropTypes.string,
    bannerImageAltText: PropTypes.string,
    bannerImageBrandName: PropTypes.string
  })
};

export { ImageBanner };
