import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';
import { connect } from '#/lib/render/connect-deep-compare';
import CouponForm from '#/components/coupon/coupon-form';
import CouponList from '#/components/coupon/coupon-list';
import helpers from '#/lib/decorators/helpers';
import { getCoupons } from '#/selectors/trolley';
import { COUPON_QUALIFIED, COUPON_UNQUALIFIED } from '#/constants/coupons';
import { getLanguage } from '#/reducers/app';

const mapStateToProps = state => ({
  language: getLanguage(state),
  availableCoupons: getCoupons(state, COUPON_QUALIFIED),
  unavailableCoupons: getCoupons(state, COUPON_UNQUALIFIED)
});
const enhance = compose(connect(mapStateToProps), helpers(['c', 't']));

const CouponsWithForm = props => {
  const showTermsAndInst = props.c('coupons:showTermsAndInst');

  return (
    <div className="coupons-with-form">
      {showTermsAndInst && (
        <p className="coupon-entry-instructions">
          {props.t('checkout:payment-summary.coupon-entry-instructions')}
        </p>
      )}
      <a
        className="checkout-help-link"
        href={props.c(`help:eCoupons:${props.language}`)}
        target="_blank"
        rel="noopener"
      >
        {props.t('checkout:payment-summary.ecoupons-help')}
        <span className="icon icon-question-dark" />
      </a>
      {showTermsAndInst && (
        <a
          className="checkout-coupon-terms"
          href={props.c(`help:eCouponTerms:${props.language}`)}
          target="_blank"
          rel="noopener"
        >
          {props.t('checkout:payment-summary.ecoupon-terms')}
          <span className="icon icon-question-dark" />
        </a>
      )}
      <CouponForm />
      {(props.availableCoupons.length > 0 ||
        props.unavailableCoupons.length > 0) && (
        <CouponList
          compactMode={props.compactMode}
          availableCoupons={props.availableCoupons}
          unavailableCoupons={props.unavailableCoupons}
        />
      )}
    </div>
  );
};

CouponsWithForm.defaultProps = {
  compactMode: false
};

CouponsWithForm.propTypes = {
  availableCoupons: PropTypes.array.isRequired,
  c: PropTypes.func.isRequired,
  compactMode: PropTypes.bool,
  language: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  unavailableCoupons: PropTypes.array.isRequired
};

export default enhance(CouponsWithForm);
