import createCenterPinOverlay from './overlays/viewport-center-pin';
import createHighlightedAreasOverlay from './overlays/highlighted-areas';
import { capitalise } from '#/utils/string';

function normalizeFloat(value) {
  return typeof value === 'number' ? value : parseFloat(value);
}

const featureModules = {
  search: ['Microsoft.Maps.Search', 'Microsoft.Maps.AutoSuggest'],
  calculations: ['Microsoft.Maps.SpatialMath']
};
const specialModuleNamespaces = {
  'Microsoft.Maps.AutoSuggest': 'Microsoft.Maps'
};

const eventTypeMap = {
  click: 'click',
  dblclick: 'dblclick',
  resize: 'mapresize',
  maptypechanged: 'maptypechanged',
  rightclick: 'rightclick',
  viewchange: 'viewchange',
  viewchangeend: 'viewchangeend',
  viewchangestart: 'viewchangestart'
};

function getFeatureModuleNamespaces(features) {
  const addedModules = {};

  return features.reduce(function(list, feature) {
    const modules = featureModules[feature];

    modules.forEach(function(moduleNamespace) {
      if (!addedModules[moduleNamespace]) {
        list.push(moduleNamespace);

        addedModules[moduleNamespace] = true;
      }
    });

    return list;
  }, []);
}

export default class Bing {
  requiredGlobalVars = [
    ['Microsoft', 'Maps', 'Map'],
    ['Microsoft', 'Maps', 'Location'],
    ['Microsoft', 'Maps', 'Events'],
    ['Microsoft', 'Maps', 'Infobox'],
    ['Microsoft', 'Maps', 'Polygon']
  ];
  queryParams = {
    key: 'key'
  };
  encodeKey = false;

  moduleRequirePromises = {};

  loadedModules = {};

  mapKey = config => config('maps:key');

  getModule(namespace) {
    if (specialModuleNamespaces[namespace]) {
      namespace = specialModuleNamespaces[namespace];
    }

    const parts = namespace.split('.');
    let loadedNs = Microsoft;

    for (let i = 1; i < parts.length; i++) {
      loadedNs = loadedNs[parts[i]];

      if (!loadedNs) {
        break;
      }
    }

    return loadedNs;
  }

  requireModule(namespace) {
    const loadedModules = this.loadedModules;

    if (loadedModules[namespace]) {
      return Promise.resolve(loadedModules[namespace]);
    }

    if (!this.moduleRequirePromises[namespace]) {
      this.moduleRequirePromises[namespace] = new Promise((resolve, reject) => {
        Microsoft.Maps.loadModule(namespace, {
          callback: () => {
            loadedModules[namespace] = this.getModule(namespace);

            resolve(loadedModules[namespace]);
          },
          errorCallback: err => {
            reject(err);
          }
        });
      });
    }

    return this.moduleRequirePromises[namespace];
  }

  loadFeatures(features) {
    const moduleNamespaces = getFeatureModuleNamespaces(features);

    return Promise.all(
      moduleNamespaces.map(moduleNamespace => {
        return this.requireModule(moduleNamespace);
      })
    );
  }

  requireSearchManager(map) {
    if (!this.searchManager) {
      this.searchManager = new Microsoft.Maps.Search.SearchManager(map);
    }

    return this.searchManager;
  }

  requireAutosuggestManager(map, options = {}) {
    if (!this.autosuggestManager) {
      this.autosuggestManager = new Microsoft.Maps.AutosuggestManager({
        countryCode: options.region ? options.region.toUpperCase() : null,
        map,
        placeSuggestions: false
      });
    }

    return this.autosuggestManager;
  }

  normalizeLocationResults(results) {
    return results.map(function(result) {
      const { address, location } = result;

      return {
        address: {
          addressLine: address.addressLine || null,
          postalCode: address.postalCode || null,
          locality: address.locality || null,
          district: address.district || null,
          adminDistrict: address.adminDistrict || null,
          country: address.countryRegion || null,
          countryCode: address.countryRegionISO2 || null
        },
        formattedAddress: address.formattedAddress || null,
        location: location
          ? {
              latitude: String(location.latitude),
              longitude: String(location.longitude)
            }
          : null
      };
    });
  }

  containsLocation(areas, location) {
    if (!Array.isArray(areas)) {
      areas = [areas];
    }

    const contains = Microsoft.Maps.SpatialMath.Geometry.contains;

    return (
      areas.find(function(area) {
        return contains(area, location);
      }) || false
    );
  }

  getAddressSuggestions(map, query, options) {
    const autosuggestManager = this.requireAutosuggestManager(map, options);
    return new Promise((resolve, reject) => {
      autosuggestManager.getSuggestions(query, suggestions => {
        if (!Array.isArray(suggestions)) {
          reject(new Error('Failed to load address suggestions'));
        }

        resolve(this.normalizeLocationResults(suggestions || []));
      });
    });
  }

  geocode(map, query) {
    const searchManager = this.requireSearchManager(map);

    return new Promise((resolve, reject) => {
      searchManager.geocode({
        where: query,
        callback: res => {
          resolve(this.normalizeLocationResults(res.results || []));
        },
        errorCallback: reject
      });
    });
  }

  reverseGeocode(map, { latitude, longitude }) {
    const searchManager = this.requireSearchManager(map);

    return new Promise((resolve, reject) => {
      searchManager.reverseGeocode({
        location: {
          latitude: normalizeFloat(latitude),
          longitude: normalizeFloat(longitude)
        },
        callback: res => {
          resolve(this.normalizeLocationResults([res])[0]);
        },
        errorCallback: reject
      });
    });
  }

  appendKey(mapSrc, key) {
    return `${mapSrc}&${this.queryParams.key}=${key}`;
  }

  setCenter(map, latitude, longitude, zoom) {
    map.setView({
      center: new Microsoft.Maps.Location(latitude, longitude),
      zoom
    });
  }

  setBestView(map, points) {
    const locations = points.map(point => ({
      latitude: point.latitude,
      longitude: point.longitude
    }));
    const iconWidth = 40;
    var rect = Microsoft.Maps.LocationRect.fromLocations(locations);
    map.setView({ bounds: rect, padding: 2 * iconWidth });
  }

  getCenter(map) {
    const center = map.getCenter();
    return {
      latitude: center.latitude,
      longitude: center.longitude
    };
  }

  createPoint({ latitude, longitude, options, map }) {
    const pin = new Microsoft.Maps.Infobox(
      new Microsoft.Maps.Location(latitude, longitude),
      options
    );

    pin.setMap(map);

    return pin;
  }

  getHighlightedAreas() {
    const overlay = this.highlightedAreasOverlay;

    if (!overlay) {
      return [];
    }

    return overlay.getAreas();
  }

  setHighlightedAreas(areas) {
    this.highlightedAreasOverlay.setAreas(areas);
  }

  addPinEventHandler(pin, event, pinFunction) {
    return Microsoft.Maps.Events.addHandler(pin, event, pinFunction);
  }

  selectedPointAttributes({ point, type }) {
    const className = type ? `icon-map-pointer--${type}` : '';
    return {
      zIndex: 2,
      htmlContent: `<div class="icon-map-pointer ${className} map--pointer"><span class="map__pointer-content map__pointer-content--selected">${point.displayOrder ||
        ''}</span></div>`
    };
  }

  defaultPointAttributes({ point }) {
    return {
      zIndex: 1,
      htmlContent: `<div class="icon-circle map--pointer"><span class="map__pointer-content">${point.displayOrder ||
        ''}</span></div>`
    };
  }

  setEventListeners = (map, listeners = {}) => {
    if (!map) {
      return;
    }

    const eventTypes = Object.keys(listeners);
    const { addHandler, removeHandler } = Microsoft.Maps.Events;

    eventTypes.forEach(event => {
      const eventProp = `eventListener${capitalise(event)}`;
      const eventListenerProp = eventProp + 'Listener';
      const providerEvent = eventTypeMap[event];
      const listener = listeners[event];

      if (this[eventProp] && this[eventListenerProp] !== listener) {
        removeHandler(this[eventProp]);

        delete this[eventProp];
        delete this[eventListenerProp];
      }

      if (listener && this[eventListenerProp] !== listener) {
        this[eventProp] = addHandler(map, providerEvent, listener);
        this[eventListenerProp] = listener;
      }
    });
  };

  createMap(
    element,
    {
      config,
      latitude,
      longitude,
      disablePanning,
      disableScrollWheelZoom = false,
      disableTouchInput,
      disableZooming,
      enableHighlightedAreas,
      highlightedAreas,
      showViewportCenterPin,
      theme,
      zoom,
      showLocateMeButton = false
    }
  ) {
    const map = new Microsoft.Maps.Map(element, {
      credentials: this.mapKey(config),
      showDashboard: true,
      center: new Microsoft.Maps.Location(latitude, longitude),
      disablePanning,
      disableScrollWheelZoom,
      disableTouchInput,
      disableZooming,
      zoom,
      enableSearchLogo: false,
      enableClickableLogo: false,
      showZoomButtons: true,
      showLocateMeButton,
      showMapTypeSelector: false
    });

    const overlayPromises = [];

    if (showViewportCenterPin) {
      overlayPromises.push(
        new Promise(resolve => {
          this.viewportCenterPinOverlay = createCenterPinOverlay(map, {
            color: theme.pinColor,
            onReady: resolve
          });
        })
      );
    }

    if (enableHighlightedAreas) {
      overlayPromises.push(
        new Promise(resolve => {
          this.highlightedAreasOverlay = createHighlightedAreasOverlay(map, {
            areas: highlightedAreas,
            onReady: resolve
          });
        })
      );
    }

    return Promise.all(overlayPromises).then(() => {
      return map;
    });
  }

  setOptions(map, options) {
    map.setOptions(options);
  }

  getStaticMapSrc(config, zoom, latitude, longitude, withPin = true) {
    return (
      `${config('maps:staticAPI')}` +
      `${latitude},${longitude}/` +
      `${zoom}?` +
      `mapSize=1200,350&` +
      `${(withPin && 'pp') || 'centerPoint'}=${latitude},${longitude}`
    );
  }
}
