import React from 'react';
import classnames from 'classnames';
import moment from 'moment-timezone';
import { DateRange } from 'moment-range';
import DaySelector from '#/components/slots/slot-selector/day-selector';
import { SHOPPING_METHOD_PROP_TYPE } from '#/components/slots/prop-types';
import { StyledSlotListHeader } from './styled';

export type StickySlotListHeaderProps = {
  fixedHeader: boolean;
  headerOffset: number;
  headerAnimation: string;
  currentSlotGroup: number;
  selectedDate: moment.Moment;
  selectedLocation: Record<string, unknown>;
  shoppingMethod: typeof SHOPPING_METHOD_PROP_TYPE;
  slotRange: DateRange;
};

const StickySlotListHeader = (props: StickySlotListHeaderProps): JSX.Element => {
  const {
    fixedHeader,
    headerOffset,
    headerAnimation,
    currentSlotGroup,
    selectedDate,
    selectedLocation,
    shoppingMethod,
    slotRange,
  } = props;
  return (
    <StyledSlotListHeader
      style={{ top: `${headerOffset}px` }}
      className={classnames('styled-slot-list-header', {
        animation: headerAnimation,
        slideIn: headerAnimation === 'slideIn',
        slideOut: headerAnimation === 'slideOut',
        'hide-from-view': !fixedHeader,
      })}
    >
      <DaySelector
        currentSlotGroup={currentSlotGroup}
        selectedDate={selectedDate}
        selectedLocation={selectedLocation}
        shoppingMethod={shoppingMethod}
        slotRange={slotRange}
      />
    </StyledSlotListHeader>
  );
};

export default StickySlotListHeader;
