import React, { Component, ErrorInfo } from 'react';
import { logApmError } from '#/lib/apm';
import { PRD, PROD_TEST } from '#/constants/common';

const IS_PROD = process.env.NODE_ENV === 'production';

interface Props {
  children: JSX.Element;
  fallbackHandler: () => JSX.Element | null;
  reportErrorHandler: (error: Error, errorInfo: ErrorInfo) => void;
}

interface State {
  hasError: boolean;
}

type RType = JSX.Element | null;

export class ErrorBoundary extends Component<Props, State> {
  state = {
    hasError: false,
  };

  static defaultProps = {
    fallbackHandler: (): void => {},
    reportErrorHandler: (): void => {},
  };

  static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  isOnProd = (): boolean => {
    if (!IS_PROD) {
      return false;
    }
    const appName = document.body.getAttribute('data-app-name')?.toLowerCase();
    const isProd = !!appName && [PROD_TEST, PRD].includes(appName);

    return isProd;
  };

  isOnClient = (): boolean => typeof window !== 'undefined';

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    const { reportErrorHandler } = this.props;
    if (this.isOnProd()) {
      reportErrorHandler(error, errorInfo);
    }
    this.setState({ hasError: true });
  }

  render(): RType {
    const { fallbackHandler, children } = this.props;
    const { hasError } = this.state;

    // Handle error only if in production mode.
    if (this.isOnClient() && hasError && this.isOnProd()) {
      return fallbackHandler();
    }

    return children;
  }
}

export const withErrorBoundary = (errorMessageTitle: string, componentName: string) => <P extends object>(
  Component: React.ComponentType<P>,
): React.ComponentClass => {
  class WithErrorBoundary extends React.Component {
    static displayName: string;
    static WrappedComponent: React.ComponentType<P>;

    fallbackHandler = (): null => {
      return null;
    };

    reportErrorHandler = (error: Error, errorInfo: ErrorInfo): void => {
      const isClientWindow = typeof window !== 'undefined';

      if (isClientWindow) {
        const formattedError = new Error(`${errorMessageTitle}_${componentName}:
                    ${error.stack}
                    componentStack:
                    ${errorInfo.componentStack}`);

        logApmError(formattedError);
      }

      // eslint-disable-next-line no-console
      console.error(error);
    };

    render(): JSX.Element {
      return (
        <ErrorBoundary fallbackHandler={this.fallbackHandler} reportErrorHandler={this.reportErrorHandler}>
          <Component {...(this.props as P)} />
        </ErrorBoundary>
      );
    }
  }

  WithErrorBoundary.displayName = `WithErrorBoundary(${componentName})`;
  WithErrorBoundary.WrappedComponent = Component;

  return WithErrorBoundary;
};
