export function selectedFacetLists(facetLists) {
  return facetLists
    .map(facetList => JSON.parse(JSON.stringify(facetList)))
    .map(facetList => {
      facetList.facets = facetList.facets.filter(facet => facet.isSelected);

      return facetList;
    })
    .filter(facetList => facetList.facets.length > 0);
}
