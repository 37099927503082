import React from 'react';
import PropTypes from 'prop-types';
import { isOriginalImage, convertFileName } from './responsive-helpers';
import LazyImage from '../../image/lazy-image';
import { cloneDeep } from '#/utils/clone-deep';

const getStyles = clipping => {
  let styles;

  switch (clipping) {
    case 'left':
      styles = { objectPosition: '0 50%' };
      break;
    case 'right':
      styles = { objectPosition: '100% 50%' };
      break;
    default:
      styles = { objectPosition: '50% 50%' };
  }

  return styles;
};

const generateSrcSet = (fileName, breakpoints) => {
  let result;

  if (isOriginalImage(fileName) && breakpoints && breakpoints.length) {
    result = cloneDeep(breakpoints)
      // order of these makes no logical difference but needs to be the same as in
      // the browser to prevent diff errors
      .sort((a, b) => b.screenWidth - a.screenWidth)
      .map(
        ({ imageWidth }) =>
          `${convertFileName(fileName, imageWidth)} ${imageWidth}w`
      )
      .join(', ');
  }

  return result;
};

const generateSizes = (fileName, breakpoints) => {
  let result;

  if (isOriginalImage(fileName) && breakpoints && breakpoints.length) {
    result = cloneDeep(breakpoints)
      .sort((a, b) => b.screenWidth - a.screenWidth)
      .map(
        ({ screenWidth, imageWidth }) =>
          `(min-width: ${screenWidth}px) ${imageWidth}px`
      )
      .join(', ');
  }

  return result;
};

const ResponsiveImage = ({
  className,
  alt,
  src,
  breakpoints,
  maxWidth,
  clipping
}) => (
  <LazyImage
    className={className}
    alt={alt || ' '}
    src={convertFileName(src, maxWidth)}
    srcSet={generateSrcSet(src, breakpoints)}
    sizes={generateSizes(src, breakpoints)}
    style={getStyles(clipping)}
  />
);

ResponsiveImage.defaultProps = {
  alt: ' '
};

ResponsiveImage.propTypes = {
  alt: PropTypes.string,
  breakpoints: PropTypes.arrayOf(
    PropTypes.shape({
      screenWidth: PropTypes.number,
      imageWidth: PropTypes.number
    })
  ),
  className: PropTypes.string,
  clipping: PropTypes.oneOf(['left', 'center', 'right']),
  maxWidth: PropTypes.number.isRequired,
  src: PropTypes.string.isRequired
};

export { ResponsiveImage };
