import { Component } from 'react';
import PropTypes from 'prop-types';
import helpers from '#/lib/decorators/helpers';
import { connect } from '#/lib/render/connect-deep-compare';
import { setSlotConfig } from '#/actions/dfp-action-creators';
import { logApmError } from '#/lib/apm';
import { getDfpEnabled } from '#/reducers/app';
import { getTrolleyShoppingMethod } from '#/selectors/trolley';

function mapStateToProps(state) {
  return {
    dfpEnabled: getDfpEnabled(state),
    shoppingMethod: getTrolleyShoppingMethod(state)
  };
}

@helpers(['c', 'f'])
@connect(mapStateToProps, {
  setSlotConfig
})
class DfpContainer extends Component {
  static propTypes = {
    c: PropTypes.func.isRequired,
    dfpEnabled: PropTypes.bool.isRequired,
    pageId: PropTypes.string,
    shoppingMethod: PropTypes.string,
    targeting: PropTypes.object
  };

  static defaultProps = {
    pageId: '',
    targeting: {}
  };

  constructor(props) {
    super(props);

    try {
      const {
        c: config,
        dfpEnabled,
        f: feature,
        pageId,
        shoppingMethod,
        targeting
      } = props;
      const targetingParams = { ...targeting, shoppingMethod };

      if (dfpEnabled) {
        const adUnitPath = feature('testDFPBanners')
          ? config('dfp:testAdUnitPath')
          : config('dfp:adUnitPath');

        // This is only to set the configuration for each of the slots present on the same page as the DFP container.
        // Required for backwards compatibility with the previous implementation.
        props.setSlotConfig({
          adUnitPath: adUnitPath + pageId,
          ppid: targeting.tppid,
          targeting: targetingParams
        });
      }
    } catch (err) {
      logApmError(
        new Error(`Failed to set DFP slot configuration: ${err.message}`),
        {
          stack: err.stack
        }
      );
    }
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    return null;
  }
}

export { DfpContainer };
