import styled from 'styled-components';
import { media } from '@ddsweb/foundation';
import Link from '@ddsweb/link';
import { colors, spacing } from '@ddsweb/selectors';
import { Footnote } from '@ddsweb/typography';

export const FulfilmentStepsContainer = styled.div`
  border: 1px solid #b4b4b4;
  border-radius: 6px;
  padding: ${spacing.md};

  ${media.aboveTabletLarge`
    padding:${spacing.lg} ${spacing.xxl};
  `}
`;

export const IconContainer = styled.div`
  align-items: center;
  background-color: ${({ backgroundColor }: { backgroundColor: string }): string => backgroundColor};
  display: flex;
  justify-content: center;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  margin-right: ${spacing.sm};

  ${media.aboveTabletLarge`
    margin: ${spacing.sm} 0 ${spacing.lg};
    width: 56px;
    height: 56px;
  `};
`;

export const StyledMoreAboutLink = styled(Link)`
  display: inline-flex;
  align-items: baseline;
  gap: 5px;
  line-height: 24px;

  svg {
    stroke-width: 2.8px;
    margin-left: ${spacing.xx};
  }

  ${media.aboveTabletLarge`
    margin-left: 10px;
  `}
`;

export const IconStamp = styled.section`
  display: flex;
  margin-bottom: ${spacing.sm};
  align-items: start;

  ${media.aboveTabletLarge`
    align-items: center;
    flex-direction: column;
    margin: 0 ${spacing.md};
    text-align: center;
    width: 25%;
  `};

  p {
    width: 100%;
  }
`;

export const StepsContainer = styled.div`
  margin-bottom: ${spacing.md};

  ${media.aboveTabletLarge`
    display: flex;
    margin-bottom: ${spacing.xxxl};
  `};
`;

export const Title = styled(Footnote)`
  && {
    font-weight: bold;
    color: ${colors.black};

    ${media.aboveTabletLarge`
      margin-bottom: ${spacing.xs};
    `};
  }
`;
