/**
 * withProductTile - An HOC component for Vertical and Horizontal tile component
 * This component is created to move out the common code between VerticalTile and HorizontalTile
 * While working on any new requirement, and we need to modify anything into the core tile (vertical and horizontal)
 * as common functionality, it can be done here instead of adding it at two places.
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Component } from 'react';
import { compose } from 'react-recompose';
import { DELAY, HOPOS, TREX_IDENTIFIER, CAROUSEL_FAVORITES_IDENTIFIER } from '#/analytics/constants';
import { TConfigFunc, TFeatureFunc, TTranslateFunc } from '#/lib/records/helpers.defs';
import { Item } from '#/lib/records/item';
import { getAddFormParams } from '#/selectors/beans-product-tile/add-form-params-data';
import { connect } from '#/lib/render/connect-deep-compare';
import { PRODUCT_TILE } from '#/constants/analytics';
import { incrementItemBy, setCatchWeightTo } from '#/actions/trolley/trolley-action-creators';
import { PlainObject } from '#/types';
import { CustomerUnitChoice, SellerInfo } from '#/lib/records/item.defs';
import { AnalyticsSetting } from '#/components/products/prop-types';
import { getBeansImageData } from '#/selectors/beans-product-tile/image-data';
import { getPDPHref } from '#/selectors/beans-product-tile/product-links-data';
import { isProductRestrictedDisabled } from '#/selectors/beans-product-tile/product-availability-data';
import { getSellerInfo, isCatchWeightProduct } from '#/selectors/item';
import { getPromotionsData, TPromotionsData } from '#/selectors/beans-product-tile/promotions-data';
import { basicEvent } from '#/analytics/types/basic';
import analyticsBus from '#/analytics/analyticsBus';
import helpers from '#/lib/decorators/helpers';
import { getWriteAReviewData } from '#/selectors/beans-product-tile/write-a-review-data';
import { TRestOfShelfData } from '#/selectors/beans-product-tile/rest-of-shelf-link-data';

export interface BaseProps {
  analyticsSettings: typeof AnalyticsSetting;
  children: React.ReactChildren;
  className: string;
  config: TConfigFunc;
  'data-auto': string;
  feature: TFeatureFunc;
  identifier: typeof TREX_IDENTIFIER | typeof CAROUSEL_FAVORITES_IDENTIFIER;
  incrementItemBy: (qty: number, item: Item, analyticsOptions: PlainObject, itemUnit: CustomerUnitChoice) => void;
  // ------Legacy TO BE DELETED props------
  item: Item & any; // any can be removed once the references to item have been removed
  itemIndex: string;
  onPromotionsClick: () => void;
  pageId: string;
  renderTermText?: boolean;
  setCatchWeightTo: (item: Item, catchWeight: number) => void;
  tile?: string;
  translate: TTranslateFunc;
  viewAndBuyButtonUrl?: string;
  resultType: string;
  promotions: TPromotionsData;
  restOfShelfLink?: TRestOfShelfData | undefined;
  writeAReviewData?: ReturnType<typeof getWriteAReviewData>;
}

type OwnProps = Pick<BaseProps, 'item' | 'identifier'> & {
  c: TConfigFunc;
  t: TTranslateFunc;
};

export type BaseStateProps = {
  addFormParams: ReturnType<typeof getAddFormParams>;
  beansImage: ReturnType<typeof getBeansImageData>;
  promotions: ReturnType<typeof getPromotionsData>;
  href: string;
  isCatchWeightProduct: ReturnType<typeof isCatchWeightProduct>;
  isRestrictedDisabled: boolean;
  disabled: boolean;
  isWebView: boolean;
  sellerInfo: SellerInfo;
};

type Props = BaseProps & OwnProps & BaseStateProps;

type DispatchProps = {
  incrementItemBy: typeof incrementItemBy;
  setCatchWeightTo: typeof setCatchWeightTo;
};

export const withProductTile = <P extends object>(WrappedProductTile: React.ComponentType<P>): React.ComponentClass => {
  const mapStateToProps = (state: Store, ownProps: Props): BaseStateProps => {
    const { item, identifier, t: translate, c: config } = ownProps;

    return {
      addFormParams: getAddFormParams(state, item, identifier, true),
      beansImage: getBeansImageData(state, item),
      promotions: getPromotionsData(state, item, translate, config),
      href: getPDPHref(state, item),
      isRestrictedDisabled: isProductRestrictedDisabled(item),
      isCatchWeightProduct: isCatchWeightProduct(item),
      disabled: !!config('product:disablePDPLink'),
      isWebView: !!config('useWebViewLayout'),
      sellerInfo: getSellerInfo(item),
    };
  };

  const mapDispatchToProps: DispatchProps = {
    incrementItemBy,
    setCatchWeightTo,
  };

  class WithProductTile extends Component<Props> {
    static defaultProps = {
      tile: PRODUCT_TILE,
    };

    constructor(props: Props) {
      super(props);

      this.handlePromotionsClick = this.handlePromotionsClick.bind(this);
    }

    handlePromotionsClick = (): void => {
      const { tile: value } = this.props;

      basicEvent(analyticsBus, {
        type: HOPOS,
        value,
        action: DELAY,
      });
    };

    render(): JSX.Element {
      const { t: translate, c: config, isWebView, ...props } = this.props;
      return (
        <WrappedProductTile
          {...(props as P)}
          config={config}
          translate={translate}
          onPromotionsClick={this.handlePromotionsClick}
          preventDisabledTitleStyle={props.disabled && isWebView}
        />
      );
    }
  }
  const enhance: Function = compose(helpers(['c', 't']), connect(mapStateToProps, mapDispatchToProps));

  return enhance(WithProductTile);
};
