import React from 'react';
import { connect } from '#/lib/render/connect-deep-compare';
import { INFO, ERROR } from '@ddsweb/constants';
import { InLineMessaging } from '@ddsweb/messaging';
import Text from '@ddsweb/text';
import { isDeliveryShoppingMethod, isClickAndCollectShoppingMethod } from '#/lib/shopping-method-util';
import helpers from '#/lib/decorators/helpers';
import { getFormattedDate } from '#/lib/i18n/date-utils';
import { getLanguage, getTimezone } from '#/reducers/app';
import { getOnDemandServiceAvailableTime, getOnDemandUnavailableReason } from '#/selectors/slot';
import { TLanguageLink } from '../index.defs';
import { ShoppingMethod } from '#/constants/shopping-methods';
import { ON_DEMAND } from '#/constants/shopping-methods';
import { getSelectedShoppingMethod } from '#/reducers/slot';
import { TIME_FORMAT } from '#/constants/common';

type TBannerDescription = {
  t: (key: string, options?: { openingHour: number; closingHour: number }) => string;
  onDemandUnavailableReason: string | null | false;
  openingHour: number;
  closingHour: number;
  shoppingMethod: ShoppingMethod;
};

type TMapStateToProps = {
  openingHour: number;
  closingHour: number;
  onDemandUnavailableReason: string | false | null | undefined;
  shoppingMethod: ShoppingMethod;
};

type TOndemandInfoBanner = {
  availableShoppingMethods: string[];
  languageLink: TLanguageLink;
  onDemandUnavailableReason?: string | null | false | undefined;
  openingHour?: number;
  closingHour?: number;
  t: (key: string, options?: { [key: string]: string }) => string;
};

const mapStateToProps = (state: Store): TMapStateToProps => {
  const language = getLanguage(state);
  const timezone = getTimezone(state);
  const shoppingMethod = getSelectedShoppingMethod(state);

  let startDateTime;
  let endDateTime;
  let serviceUnavailableReason;

  if (shoppingMethod === ON_DEMAND) {
    ({ startDateTime, endDateTime } = getOnDemandServiceAvailableTime(state) || {});
    serviceUnavailableReason = getOnDemandUnavailableReason(state);
  }

  const openingHour = getFormattedDate(startDateTime, TIME_FORMAT, timezone, language);
  const closingHour = getFormattedDate(endDateTime, TIME_FORMAT, timezone, language);

  return {
    openingHour,
    closingHour,
    onDemandUnavailableReason: serviceUnavailableReason,
    shoppingMethod,
  };
};

type TProps = TOndemandInfoBanner & ReturnType<typeof mapStateToProps>;

export const BannerDescription = helpers(['t'])(
  (props: TBannerDescription): JSX.Element => {
    const { t: translate, onDemandUnavailableReason, openingHour, closingHour, shoppingMethod } = props;

    let translationTextKey;
    let assistiveText = translate('common:information');

    let messageVariant: typeof INFO | typeof ERROR = INFO;

    if (onDemandUnavailableReason) {
      messageVariant = ERROR;
      assistiveText = translate('common:pages.error');
      translationTextKey = translate(`slots:ondemand.delivery-${onDemandUnavailableReason}`, {
        openingHour,
        closingHour,
      });
    } else {
      translationTextKey = translate(`slots:${shoppingMethod}.over-18-description`);
    }

    return (
      <InLineMessaging variant={messageVariant} assistiveText={assistiveText}>
        <Text as="p"> {translationTextKey}</Text>
      </InLineMessaging>
    );
  },
);

const OndemandInfoBanner: React.FC<TProps> = (props: TProps) => {
  const { availableShoppingMethods, onDemandUnavailableReason, openingHour, closingHour, shoppingMethod } = props;

  let hasDelivery, hasCollection;

  availableShoppingMethods.forEach(shopping => {
    if (isDeliveryShoppingMethod(shopping)) hasDelivery = true;
    if (isClickAndCollectShoppingMethod(shopping)) hasCollection = true;
  });

  const shouldRenderBanner = hasDelivery || hasCollection;

  if (!shouldRenderBanner) return null;

  return (
    <BannerDescription
      onDemandUnavailableReason={onDemandUnavailableReason}
      openingHour={openingHour}
      closingHour={closingHour}
      shoppingMethod={shoppingMethod}
    />
  );
};

export default helpers(['t'])(connect(mapStateToProps)(OndemandInfoBanner));
