import React, { useEffect, useRef } from 'react';
import { compose } from 'react-recompose';
import { ConnectedProps } from 'react-redux';
import { Text } from '@ddsweb/text';
import helpers from '#/lib/decorators/helpers';
import { connect } from '#/lib/render/connect-deep-compare';
import { getBasketMaxItemCountLimit, getHasBasketBreachedByVolumeOrWeight } from '#/selectors/trolley';
import { emitOnBasketBreach } from '#/analytics/basket-breach';
import { getSlot, getTrolleyShoppingMethod, hasValidSlot } from '#/selectors/trolley';
import { WARNING, SUCCESS } from '#/constants/notification-variants';
import { openModal } from '#/actions/ui-action-creators';
import { BASKET_CAPACITY_EXCEED_MODAL } from '#/constants/modal-names';
import { hasShownBasketBreachModal } from '#/lib/url/modal-utils';
import { isOnDemandShoppingMethod } from '#/lib/shopping-method-util';
import { StyledNotification } from './styled';
import { getFormattedSlotTime } from '../shared/formatted-time/selector';

export type TOwnProps = {
  highlight?: boolean;
  shoppingMethod: string;
  showBodyText: boolean;
  t: (key: string, data?: object) => string;
  c: (key: string) => string;
  openModal: Function;
  showBasketBreachModal: boolean;
  overrideMessage: boolean;
};

export const mapStateToProps = (state: any, ownProps: TOwnProps) => {
  const hasBasketBreachedByVolumeOrWeight = getHasBasketBreachedByVolumeOrWeight(state);
  const hasCurrentValidSlot = hasValidSlot(state);
  const shoppingMethod = getTrolleyShoppingMethod(state);
  const bookedSlot = getSlot(state);
  const bookedSlotTime = bookedSlot
    ? getFormattedSlotTime(state, bookedSlot.start, bookedSlot.end, ownProps.c)
    : undefined;
  return {
    hasValidSlot: hasCurrentValidSlot,
    shoppingMethod,
    basketMaxItemCountLimit: getBasketMaxItemCountLimit(state),
    hasBasketBreachedByVolumeOrWeight,
    bookedSlotTime,
    showBasketBreachModal: hasCurrentValidSlot && hasBasketBreachedByVolumeOrWeight && !hasShownBasketBreachModal(),
    overrideMessage: isOnDemandShoppingMethod(shoppingMethod),
  };
};

const connector = connect(mapStateToProps, { openModal });
type TProps = TOwnProps & ConnectedProps<typeof connector>;

function getBasketBreachMessage(props) {
  const { t: translate, overrideMessage, shoppingMethod, basketMaxItemCountLimit } = props;

  return overrideMessage
    ? translate(`trolley:basket.delivery-breached_${shoppingMethod}`)
    : translate('trolley:basket.delivery-breached', {
        limit: basketMaxItemCountLimit,
      });
}

const getTitle = props => {
  const { hasBasketBreachedByVolumeOrWeight, t: translate } = props;
  return hasBasketBreachedByVolumeOrWeight
    ? getBasketBreachMessage(props)
    : translate('trolley:basket.delivery-qualifies');
};

function getBasketBreachDescriptionMessage(props) {
  const { t: translate, overrideMessage, shoppingMethod, basketMaxItemCountLimit } = props;

  return overrideMessage
    ? translate(`trolley:basket.delivery-breached-desc_${shoppingMethod}`)
    : translate('trolley:basket.delivery-breached-desc', {
        limit: basketMaxItemCountLimit,
      });
}

const renderBodyText = props => {
  const { hasBasketBreachedByVolumeOrWeight } = props;
  const bodyText = hasBasketBreachedByVolumeOrWeight ? getBasketBreachDescriptionMessage(props) : null;

  return <Text>{bodyText}</Text>;
};

const getVariant = hasBasketBreachedByVolumeOrWeight => {
  return hasBasketBreachedByVolumeOrWeight ? WARNING : SUCCESS;
};

const BasketNotification = (props: TProps) => {
  const {
    highlight,
    showBodyText,
    hasValidSlot,
    hasBasketBreachedByVolumeOrWeight,
    openModal,
    showBasketBreachModal,
  } = props;

  const basketBreachAnalyticsFirePreCheck = useRef({ initialRender: true, currentSlotStatus: hasValidSlot });

  useEffect(() => {
    const slotStatusUnchanged = basketBreachAnalyticsFirePreCheck.current.currentSlotStatus === hasValidSlot;
    if (
      hasBasketBreachedByVolumeOrWeight &&
      !basketBreachAnalyticsFirePreCheck.current.initialRender &&
      slotStatusUnchanged
    ) {
      emitOnBasketBreach();
    }

    if (!slotStatusUnchanged) {
      basketBreachAnalyticsFirePreCheck.current.currentSlotStatus = hasValidSlot;
    }

    if (basketBreachAnalyticsFirePreCheck.current.initialRender) {
      basketBreachAnalyticsFirePreCheck.current.initialRender = false;
    }
    if (showBasketBreachModal) {
      window.sessionStorage.setItem('shownBasketBreachModal', 'true');
      openModal(BASKET_CAPACITY_EXCEED_MODAL);
    }
  }, [hasBasketBreachedByVolumeOrWeight, hasValidSlot]);

  const basketQualifiedForDelivery = !hasBasketBreachedByVolumeOrWeight;
  const hideBasketQualifiedMessage = basketQualifiedForDelivery;
  if (!hasValidSlot || hideBasketQualifiedMessage) return null;

  return (
    <StyledNotification
      showIcon={false}
      variant={getVariant(hasBasketBreachedByVolumeOrWeight)}
      title={getTitle(props)}
      highlight={highlight}
    >
      {showBodyText && renderBodyText(props)}
    </StyledNotification>
  );
};

BasketNotification.defaultProps = {
  highlight: false,
  showBodyText: false,
};

const enhance = compose<TProps, TOwnProps>(helpers(['t', 'c']), connector);
export default enhance(BasketNotification);
