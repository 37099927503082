import { getItems, getSlot, getPreviousSlot, getIsAmendBasket } from '#/selectors/trolley';
import { getSlotStart, getSlotEnd } from '#/selectors/slot';
import { getIsUserAuthenticated, getUserStoreId, getAccessToken, getAtrc } from '#/reducers/user';
import { getAppRegion, getApigeeMangoEndpoint, getApigeeMangoApiKey, getTraceId } from '#/reducers/app';
import { fetchTescoRecommendations } from '#/lib/requests/get-tesco-recommendations';
import { Dispatch, GetStore } from '#/custom-typings/redux-store/common';
import { Recommendation } from '#/lib/requests/graphql-queries/recommendation-types';
import { TREX } from '#/constants/recommendations';
import { Strategy } from '#/lib/requests/helpers/get-tesco-recommendations-helper';
import { CLEAR_IBYC_RECOMMENDATIONS, UPDATE_IBYC_RECOMMENDATIONS } from '#/experiments/oop-2349/constants/action-types';

type ClearIbycRecommendation = {
  type: typeof CLEAR_IBYC_RECOMMENDATIONS;
};

export const clearIbycRecommendation = (): ClearIbycRecommendation => ({
  type: CLEAR_IBYC_RECOMMENDATIONS,
});

export const fetchIBYCRecommendations = (recommendationOptions: Recommendation) => {
  return async (dispatch: Dispatch, getState: GetStore): Promise<void> => {
    const state = getState();
    const items = getItems(state);
    const itemIds = items.map(item => Number(item.product.id));
    const strategies: Strategy[] = [];

    dispatch(clearIbycRecommendation());

    const {
      position,
      pageName,
      variant,
      count,
      query,
      excludedProducts,
      targetTPNBs,
      ...options
    } = recommendationOptions;
    const slot = getSlot(state);
    const slotDetails = slot && {
      startDateTime: getSlotStart(slot) as string,
      endDateTime: getSlotEnd(slot) as string,
    };
    const body = {
      ...options,
      isAmendBasket: getIsAmendBasket(state),
      isAuthenticated: getIsUserAuthenticated(state),
      previousSlot: getPreviousSlot(state),
      slotDetails,
      basketItems: itemIds,
      exclusionInfo: {
        search: query || '',
        products: excludedProducts || [],
      },
      includeReviews: true,
      pageName,
    };

    const requestConfig = {
      accessToken: getAccessToken(state),
      apiKey: getApigeeMangoApiKey(state),
      atrc: getAtrc(state),
      endpoint: getApigeeMangoEndpoint(state),
      region: getAppRegion(state),
      storeId: getUserStoreId(state),
      trace_id: getTraceId(state),
    };

    const defaultStrategy: Strategy = {
      position,
      variant,
      pageSize: count,
      type: TREX,
    };

    if (targetTPNBs)
      targetTPNBs.forEach(tpnb => {
        strategies.push({ ...defaultStrategy, tpnb });
      });
    else strategies.push(defaultStrategy);

    return await fetchTescoRecommendations(body, strategies, requestConfig).then(response => {
      dispatch({
        type: UPDATE_IBYC_RECOMMENDATIONS,
        value: response,
      });
    });
  };
};
