import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import Base from '../../base';

class ToggleControlLabel extends Base {
  static propTypes = {
    changeUnits: PropTypes.func,
    checked: PropTypes.bool.isRequired,
    children: PropTypes.node,
    displayType: PropTypes.string.isRequired,
    hasRightGutter: PropTypes.bool
  };

  static defaultProps = {
    hasRightGutter: true
  };

  render() {
    const { checked, displayType, hasRightGutter } = this.props;

    return (
      <label
        data-auto={`controls-${displayType}`}
        className={classnames(`controls--${displayType} controls--unit-label`, {
          selected: checked
        })}
      >
        {this.props.children}
        <span
          className={classnames('controls--toggle-text', {
            'has-right-gutter': hasRightGutter
          })}
        >
          {this.t(`product-tile:toggle-${displayType}`)}
        </span>
      </label>
    );
  }
}

export default ToggleControlLabel;
