import { FAV_LAST_STATE_STORAGE, favRememberPaginationVariants } from '#/experiments/oop-1801/constants';
import { updateParamsInUrl } from '#/lib/url/url-utils';
import { DEFAULT_PAGE_NAME } from '#/constants/query-strings';
import { sessionStore } from '#/lib/data-store/client-store';
import { getUserUuid } from '#/reducers/user';

export const getShouldUseDDSPagination = (variant: favRememberPaginationVariants): boolean =>
  [favRememberPaginationVariants.expandedPagination, favRememberPaginationVariants.enabledExpandedPagination].includes(
    variant,
  );

export const getShouldPersistFilters = (variant: favRememberPaginationVariants): boolean =>
  [favRememberPaginationVariants.enabled, favRememberPaginationVariants.enabledExpandedPagination].includes(variant);

export const generateUrl = (currentUrl: string, pageNo: number, pageQueryName = DEFAULT_PAGE_NAME): string =>
  updateParamsInUrl(currentUrl, { [pageQueryName]: pageNo });

export const getFavLastStateStorageKey = (state: Store): string => `${FAV_LAST_STATE_STORAGE}-${getUserUuid(state)}`;

export const getLastFavStateFromStorage = (key: string): { [key: string]: string } | undefined => {
  return sessionStore?.get(key);
};

export const setLastFavStateFromStorage = (key: string, data: string): void => {
  sessionStore?.set(key, data);
};
