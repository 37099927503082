import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import helpers from '../../lib/decorators/helpers';

const srcSetSeparatorRegEx = /,(?![a-zA-Z0-9+/]+==)/g; // This prevents base64 encoded images from being split at the comma.

@helpers(['asset'])
class Image extends PureComponent {
  static propTypes = {
    alt: PropTypes.string,
    asset: PropTypes.func.isRequired,
    className: PropTypes.string,
    crossOrigin: PropTypes.oneOf(['anonymous', 'use-credentials']),
    decoding: PropTypes.oneOf(['sync', 'async', 'auto']),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    id: PropTypes.string,
    imageRef: PropTypes.func,
    ismap: PropTypes.bool,
    onClick: PropTypes.func,
    sizes: PropTypes.string,
    src: PropTypes.string.isRequired,
    srcSet: PropTypes.string,
    style: PropTypes.object,
    useMap: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  };

  static defaultProps = {
    alt: ' '
  };

  getSrcUrl(assetPath) {
    const output = this.props.asset(assetPath);

    return output;
  }

  getSrcSetUrls(srcSet) {
    if (!srcSet) {
      return;
    }

    const srcs = srcSet.trim().split(srcSetSeparatorRegEx);
    const updatedSrcSet = [];
    const srcsCount = srcs.length;

    for (let i = 0; i < srcsCount; i++) {
      const [src, srcSize] = srcs[i].trim().split(' ');
      const updatedSrc = `${this.getSrcUrl(src)} ${srcSize || ''}`;

      updatedSrcSet.push(updatedSrc.trim());
    }

    return updatedSrcSet.join(',');
  }

  render() {
    const { alt, imageRef, src, srcSet, ...otherProps } = this.props;
    const imgSrc = this.getSrcUrl(src);
    const imgSrcSet = this.getSrcSetUrls(srcSet);

    delete otherProps.asset;

    return (
      <img
        ref={imageRef}
        src={imgSrc}
        srcSet={imgSrcSet !== imgSrc ? imgSrcSet : void 0}
        alt={typeof alt === 'string' ? alt : ' '}
        {...otherProps}
      />
    );
  }
}

export default Image;
