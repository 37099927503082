import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SafeForm from '#/components/shared/safe-form';
import helpers from '#/lib/decorators/helpers';
import { CHANGE_SLOT_TYPE_MODAL } from '#/constants/modal-names';
import { SHOPPING_METHOD_PROP_TYPE } from '#/components/slots/prop-types';

@helpers(['l'])
export default class SlotForm extends Component {
  static propTypes = {
    children: PropTypes.node,
    l: PropTypes.func.isRequired,
    locationId: PropTypes.string.isRequired,
    method: PropTypes.string,
    modalType: PropTypes.string,
    onKeyPressed: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    returnUrl: PropTypes.string.isRequired,
    shoppingMethod: SHOPPING_METHOD_PROP_TYPE.isRequired,
    slotEnd: PropTypes.string,
    slotGroup: PropTypes.number,
    slotId: PropTypes.string.isRequired,
    slotStart: PropTypes.string,
    submitUrl: PropTypes.string
  };

  static defaultProps = {
    method: 'POST'
  };

  render() {
    const formProps = {
      action: this.props.l(this.props.submitUrl),
      method: this.props.method,
      onSubmit: event => this.props.onSubmit(event),
      onKeyDown: this.props.onKeyPressed
    };
    const isGet = this.props.method.toUpperCase() === 'GET';

    return (
      <SafeForm {...formProps}>
        <input
          name="shoppingMethod"
          type="hidden"
          value={this.props.shoppingMethod}
        />
        <input name="slotId" type="hidden" value={this.props.slotId} />
        <input name="start" type="hidden" value={this.props.slotStart} />
        <input name="end" type="hidden" value={this.props.slotEnd} />
        <input name="locationId" type="hidden" value={this.props.locationId} />
        {!!this.props.modalType && (
          <input name="modalType" type="hidden" value={this.props.modalType} />
        )}
        {!!isGet && (
          <input
            type="hidden"
            name="currentModal"
            value={CHANGE_SLOT_TYPE_MODAL}
          />
        )}
        {!!this.props.slotGroup && (
          <input name="slotGroup" type="hidden" value={this.props.slotGroup} />
        )}
        <input name="returnUrl" type="hidden" value={this.props.returnUrl} />
        {this.props.children}
      </SafeForm>
    );
  }
}
