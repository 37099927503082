import React from 'react';
import PropTypes from 'prop-types';
import analyticsBus from '#/analytics/analyticsBus';
import Link from '#/components/link-check-spa';
import { basicEvent } from '#/analytics/types/basic';
import { TescoLogo } from '@ddsweb/branding';
import { connect } from '#/lib/render/connect-deep-compare';
import { getExternalSecureDomain, getLanguageLink } from '#/reducers/app';
import helpers from '#/lib/decorators/helpers';
import classnames from 'classnames';

const mapStateToProps = state => ({
  homeUrl: getLanguageLink(state),
  rootDomain: getExternalSecureDomain(state)
});

function emitAnalyticsEvent() {
  basicEvent(analyticsBus, {
    type: 'logo',
    value: 'home',
    action: 'delay'
  });
}

const BrandLogoLink = props => {
  const { logoClass, rootDomain, c: config, ...otherProps } = props;
  const accessibleText = props.t('common:go-to-homepage');
  const isGlobalHeader = config('isGlobalHeader');
  const linkProps = {
    ...otherProps,
    to: isGlobalHeader ? rootDomain : props.homeUrl,
    title: accessibleText
  };

  return (
    <Link
      className={classnames(`brand-logo-link pull-left ${logoClass}`, {
        'global-logo': isGlobalHeader
      })}
      {...linkProps}
      onClick={emitAnalyticsEvent}
    >
      <TescoLogo scale={true} />
      <span className="visually-hidden">{accessibleText}</span>
    </Link>
  );
};

BrandLogoLink.propTypes = {
  c: PropTypes.func.isRequired,
  homeUrl: PropTypes.string.isRequired,
  logoClass: PropTypes.string,
  rootDomain: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(helpers(['t', 'c'])(BrandLogoLink));
