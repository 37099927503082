import React from 'react';
import { connect } from '#/lib/render/connect-deep-compare';
import { getSelectedShoppingMethod } from '#/reducers/slot';
import {
  isClickAndCollectShoppingMethod,
  isDeliveryShoppingMethod,
  isOnDemandShoppingMethod,
} from '#/lib/shopping-method-util';
import CollectionAddressSection from '#/components/pages/book-a-slot/address/collection-address';
import DeliveryAddressSection from '#/components/pages/book-a-slot/address/delivery-address';

type TOwnProps = {
  selectedShoppingMethod: string;
};

const mapStateToProps = (state: any) => ({
  selectedShoppingMethod: getSelectedShoppingMethod(state),
});

type TProps = TOwnProps & ReturnType<typeof mapStateToProps>;

const SlotAddress: React.FC<TProps> = (props: TProps) => {
  const { selectedShoppingMethod } = props;
  if (isDeliveryShoppingMethod(selectedShoppingMethod) || isOnDemandShoppingMethod(selectedShoppingMethod)) {
    return <DeliveryAddressSection />;
  }

  if (isClickAndCollectShoppingMethod(selectedShoppingMethod)) {
    return <CollectionAddressSection />;
  }

  return null;
};

export default connect(mapStateToProps)(SlotAddress);
