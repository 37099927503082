import { getVariationForExperiment } from '#/lib/optimizely-manager';
import { recipeZonePageVariants } from '../constants';
import { PlainObject } from '#/types';
import { getAppRegion } from '#/reducers/app';
import { UK } from '#/constants/regions';

const getRecipeZonePageVariant = (state: PlainObject): string => {
  const { key, useCustomerId } = recipeZonePageVariants;
  return getVariationForExperiment(state, key, {}, useCustomerId);
};

export const getShouldShowRecipeZonePage = (state: any): boolean => {
  const variant = getRecipeZonePageVariant(state);
  const appRegion = getAppRegion(state);
  return appRegion === UK && variant === recipeZonePageVariants.variants.showRecipeZonePage;
};
