import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Overlay from '#/components/shared/overlay';
import { removeModalFromUrl } from '#/lib/url/modal-utils';
import Link from '#/components/link-check-spa';
import { connect } from '#/lib/render/connect-deep-compare';
import { getCurrentUrl } from '#/reducers/app';

const mapStateToProps = state => ({
  currentUrl: getCurrentUrl(state)
});

@connect(mapStateToProps)
export default class Modal extends Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    closeModal: PropTypes.func,
    currentUrl: PropTypes.string.isRequired,
    dismissable: PropTypes.bool,
    hasCloseButton: PropTypes.bool,
    href: PropTypes.string,
    isOpen: PropTypes.bool,
    showHeader: PropTypes.bool,
    showLink: PropTypes.bool,
    title: PropTypes.string
  };

  static defaultProps = {
    className: '',
    closeModal: () => {},
    dismissable: true,
    hasCloseButton: false,
    isOpen: true,
    showHeader: true,
    showLink: true
  };

  handleContainerClick = event => {
    event.preventDefault();
    this.props.closeModal();
  };

  handleEsckey = event => {
    if (event.keyCode === 27 && this.props.isOpen) {
      this.props.closeModal();
    }
  };

  handleFocusin = event => {
    if (this.isDescendantOfModal(event.target) === false) {
      this.setFocus();
    }
  };

  setFocus() {
    this.modalNode && this.modalNode.focus();
  }

  trapFocus() {
    document.addEventListener('focusin', this.handleFocusin);
  }

  releaseFocus() {
    document.removeEventListener('focusin', this.handleFocusin);
  }

  isDescendantOfModal(domNode) {
    if (!domNode || !this.modalNode) {
      return;
    }

    let parent = domNode.parentNode;

    while (parent) {
      if (parent === this.modalNode.parentNode) {
        return true;
      }

      parent = parent.parentNode;
    }

    return false;
  }

  componentDidMount() {
    this.lastFocusedElement = document.activeElement;
    this.setFocus();
    this.trapFocus();

    if (this.props.dismissable) {
      document.addEventListener('keydown', this.handleEsckey);
    }
  }

  componentWillUnmount() {
    this.releaseFocus();
    this.lastFocusedElement && this.lastFocusedElement.focus();

    if (this.props.dismissable) {
      document.removeEventListener('keydown', this.handleEsckey);
    }
  }

  componentDidUpdate() {
    this.setFocus();
  }

  render() {
    const {
      isOpen,
      showHeader,
      title,
      showLink,
      children,
      className,
      currentUrl,
      href,
      hasCloseButton
    } = this.props;
    let header = null;
    const titleClassNames = classnames('modal-title', {
      'has-close-button': hasCloseButton
    });

    if (!isOpen) return null;

    if (showHeader) {
      header = (
        <div>
          <h1 className={titleClassNames} id="modal-title">
            {title}
          </h1>
        </div>
      );
    }

    const toHref = href || removeModalFromUrl(currentUrl);
    const containerLink = showLink && (
      <Link
        className="modal--link"
        to={toHref}
        onClick={this.handleContainerClick}
      />
    );

    return (
      <div className={className}>
        <Overlay isOpen />
        <div className="modal-container">
          {containerLink}
          <div
            className="modal"
            ref={modal => (this.modalNode = modal)}
            role="dialog"
            aria-modal
            aria-labelledby={header ? 'modal-title' : undefined}
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            tabIndex={0}
            data-auto="modal"
          >
            {header}
            {children}
          </div>
        </div>
      </div>
    );
  }
}
