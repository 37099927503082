import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { toRgba } from '../helpers/trade-tile';
import { withTheme } from 'styled-components';

export const TradeCta = ({ centered, ctaColor = {}, theme, uid }) => {
  const ctaClasses = classnames('cta', { cta_center: centered });
  const { desktop = {}, mobile = {} } = ctaColor;
  const defaultbackgroundColor = theme.colors.primary;
  const defaultTextColor = '#ffffff';
  const mobileBackgroundColor =
    mobile.backgroundColor || defaultbackgroundColor;
  const mobileTextColor = mobile.textColor || defaultTextColor;
  const desktopBackgroundColor =
    desktop.backgroundColor || defaultbackgroundColor;
  const desktopTextColor = desktop.textColor || defaultTextColor;

  return (
    <span className={ctaClasses}>
      <style>
        {`
        #cta_inner${uid} {
          background-image: url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2229%22%20height%3D%2229%22%20viewBox%3D%220%200%2058%2058%22%3E%3Cpath%20fill%3D%22${mobileTextColor.replace(
            '#',
            '%23'
          )}%22%20d%3D%22M19.917%2015.84a1.352%201.352%200%200%200%200%201.92L31.26%2029.005%2019.917%2040.248a1.356%201.356%200%200%200%200%201.924l1.457%201.44c.534.532%201.41.532%201.943%200l13.785-13.646a1.356%201.356%200%200%200%200-1.924L23.317%2014.396a1.39%201.39%200%200%200-1.943%200l-1.457%201.444z%22%2F%3E%3C%2Fsvg%3E);
          background-color: ${mobileBackgroundColor}
        }
        a:hover #cta_inner${uid},
        a:focus #cta_inner${uid} {
          box-shadow: 0 0 0 4px ${toRgba(mobileBackgroundColor, '0.4')};
        }
        @media (min-width: 600px) {
          #cta_inner${uid} {
            background-image: url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2229%22%20height%3D%2229%22%20viewBox%3D%220%200%2058%2058%22%3E%3Cpath%20fill%3D%22${desktopTextColor.replace(
              '#',
              '%23'
            )}%22%20d%3D%22M19.917%2015.84a1.352%201.352%200%200%200%200%201.92L31.26%2029.005%2019.917%2040.248a1.356%201.356%200%200%200%200%201.924l1.457%201.44c.534.532%201.41.532%201.943%200l13.785-13.646a1.356%201.356%200%200%200%200-1.924L23.317%2014.396a1.39%201.39%200%200%200-1.943%200l-1.457%201.444z%22%2F%3E%3C%2Fsvg%3E);
            background-color: ${desktopBackgroundColor}
          }
          a:hover #cta_inner${uid},
          a:focus #cta_inner${uid} {
            box-shadow: 0 0 0 4px ${toRgba(desktopBackgroundColor, '0.4')};
          }
        }
      `}
      </style>
      <span id={`cta_inner${uid}`} className="cta_inner" />
    </span>
  );
};

TradeCta.propTypes = {
  centered: PropTypes.bool,
  ctaColor: PropTypes.object,
  theme: PropTypes.object.isRequired,
  uid: PropTypes.number.isRequired
};

export default withTheme(TradeCta);
