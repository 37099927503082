import { getFeatureVariableStringFromState } from '#/lib/optimizely-manager';
import { slotNotifyMeVariants } from '#/experiments/oop-2034/constants';

const {
  featureKey,
  variationKey,
  useCustomerId,
  variants: { default: defaultVariant, slotNotifyMe: slotNotifyMeVariant },
} = slotNotifyMeVariants;

const getSlotNotifyMeVariant = (state: Store): string => {
  const variant = getFeatureVariableStringFromState(state, featureKey, variationKey, { useCustomerId });
  return variant ?? defaultVariant;
};

export const getIsSlotNotifyMeVariant = (state: Store): boolean =>
  getSlotNotifyMeVariant(state) === slotNotifyMeVariant;
