import uuidv4 from 'uuid/v4';
import { RECEIVE_TAXONOMY_STATE, NEW_TAXONOMY_PATH, RECEIVE_TAXONOMY } from '#/constants/action-types';
import { SUPERDEPARTMENT, DEPARTMENT } from '#/constants/taxonomy-levels';
import { TAXONOMY } from '#/constants/spa-resource';
import { CMS_NAV } from '#/constants/spa-resource';
import { fetchResources } from './resources-action-creators';
import { NewTaxonomyPath, ReceiveTaxonomyState } from '#/custom-typings/redux-store/taxonomy.actions.defs';
import { Item } from '#/lib/records/item';
import { Dispatch, GetStore } from '#/custom-typings/redux-store/common';
import { request } from '#/lib/client-fetch';
import { getLanguageLink } from '#/reducers/app';
import { logApmError } from '#/lib/apm';
import { getAtrc } from '#/reducers/user';

const taxonomyBaseUrl = 'taxonomy';

type Params = { includeChildren?: boolean };

type StringParams = {
  [param: string]: Item;
};

type StateChange =
  | {
      targetPlatform: string | null | undefined;
      department?: string | null;
      aisle?: string | null;
    }
  | StringParams;

type ErrorResponse = {
  status: number;
  message: string;
  parsedBody?: {
    errors?: Error[];
  };
};

export const selectTaxonomyItem = (
  menuType: string,
  selectedItem: Item,
  targetPlatform: string | null | undefined = null,
): ReceiveTaxonomyState => {
  const stateChange: StateChange | StringParams = { targetPlatform };

  stateChange[menuType] = selectedItem;

  if (menuType === SUPERDEPARTMENT) {
    stateChange.department = null;
    stateChange.aisle = null;
  } else if (menuType === DEPARTMENT) {
    stateChange.aisle = null;
  }

  return {
    type: RECEIVE_TAXONOMY_STATE,
    value: stateChange,
  };
};

export const newTaxonomyPath = (path: string): NewTaxonomyPath => ({
  type: NEW_TAXONOMY_PATH,
  path,
});

export const resetTaxonomy = (): ReceiveTaxonomyState => {
  const stateChange = {
    superdepartment: null,
    department: null,
  };

  return {
    type: RECEIVE_TAXONOMY_STATE,
    value: stateChange,
  };
};

export const setTaxonomyLazyLoadedStatus = (isTaxonomyLazyLoadedNow: boolean): ReceiveTaxonomyState => ({
  type: RECEIVE_TAXONOMY_STATE,
  value: { isTaxonomyLazyLoadedNow },
});

export const createTaxonomyTraceId = (): string => uuidv4() + '-taxonomy';

export const getFullTaxonomy = () => async (dispatch: Dispatch, getState: GetStore): Promise<void> => {
  try {
    const state = getState();

    const options = {
      headers: {
        traceId: createTaxonomyTraceId(),
        atrc: getAtrc(state),
      },
      requestName: 'GetFullTaxonomy',
      method: 'GET',
    };

    const endpoint = getLanguageLink(state, taxonomyBaseUrl);
    const response = await request.get(endpoint, options);

    dispatch({ type: RECEIVE_TAXONOMY, value: response });
  } catch (errResponse) {
    const res = errResponse as ErrorResponse;
    const error = res?.parsedBody?.errors?.[0];
    const statusCode = res?.status || 'unknown';
    const errorMessage = error?.message || res.message || 'unknown';
    const message = `Error while getting the full taxonomy - Message: ${errorMessage}, Status: ${statusCode}, errorObj:${JSON.stringify(
      res,
    )}`;

    console.warn(message);
    logApmError(new Error(message), message);
  } finally {
    dispatch(setTaxonomyLazyLoadedStatus(true));
  }
};

export const getTaxonomy = ({ includeChildren }: Params = {}) => async (dispatch: Dispatch): Promise<void> => {
  try {
    await dispatch(fetchResources([TAXONOMY], { includeChildren }));
  } catch (error) {
    // no catch
  }

  if (includeChildren) dispatch(setTaxonomyLazyLoadedStatus(true));
};

export const getCMSNav = () => (dispatch: Dispatch): void => {
  try {
    dispatch(fetchResources([CMS_NAV]));
  } catch (error) {
    // no catch
  }
};
