import { Items } from '#/custom-typings/redux-store/trolley.defs';
import { createItemPayload } from '#/lib/records';
import { ItemPayload } from '#/lib/records/item-payload';
import { setNumberOfItems } from '#/lib/records/item-utils';
import { getCatchWeight, getProductId, getQuantity, getUnit, getIsIghsProduct } from '#/selectors/item';
import { Item } from '#/lib/records/item';
import { getById } from '#/lib/records/product-utils';

const quantityHasChanged = (baseItem: Item, nextItem: Item): boolean => {
  const baseQuantity = getQuantity(baseItem) || 0;
  const nextQuantity = nextItem?.quantity || 0;

  return baseQuantity !== nextQuantity;
};

// IGHS Products can not change their catchweight.
const catchWeightHasChanged = (baseItem: Item, nextItem: Item): boolean => {
  return !getIsIghsProduct(baseItem) && getCatchWeight(baseItem) !== nextItem?.catchWeight;
};

const unitHasChanged = (baseItem: Item, nextItem: Item): boolean => {
  return getUnit(baseItem) !== getUnit(nextItem);
};

/**
 * Generates a payload based on the diff between items.
 * Items should be an Item Record instance, or follow
 * the same shape.
 *
 * @param {Map|Array} baseItems Items to transition from.
 * @param {Map|Array} nextItems Items to transition to.
 */
export default function payloadDiff(
  baseItems: Items | Array<Item>,
  nextItems: Items | Array<Item>,
): Array<ItemPayload> {
  const extendedBaseItems = new Map();

  baseItems.forEach((item: Item) => {
    const id = getProductId(item);
    extendedBaseItems.set(id, item);
  });

  nextItems.forEach((item: Item) => {
    const id = getProductId(item);

    if (!extendedBaseItems.has(id)) {
      const baseOfAddedItem = setNumberOfItems(item, 0);
      extendedBaseItems.set(id, baseOfAddedItem);
    }
  });

  const differences = [] as Array<ItemPayload>;
  extendedBaseItems.forEach((baseItem, id) => {
    const nextItem = getById(nextItems, id) as Item;

    if (
      quantityHasChanged(baseItem, nextItem) ||
      catchWeightHasChanged(baseItem, nextItem) ||
      unitHasChanged(baseItem, nextItem)
    ) {
      differences.push(
        createItemPayload({
          catchWeight: nextItem?.catchWeight || getCatchWeight(baseItem),
          id,
          newUnitChoice: nextItem?.customerUnitChoice || getUnit(baseItem),
          newValue: nextItem?.quantity || 0,
          oldValue: getQuantity(baseItem) || 0,
          oldUnitChoice: getUnit(baseItem),
        }),
      );
    }
  });

  return differences;
}
