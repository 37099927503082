import React from 'react';
import PropTypes from 'prop-types';
import Notification from '@beans/notification';
import { BodyText } from '@beans/typography';
import helpers from '#/lib/decorators/helpers';
import { WrapperDiv } from './styled';

const AboutDeliverySection = (props: { t: (key: string) => string; shoppingMethod: string }): JSX.Element => {
  const { t: translate, shoppingMethod } = props;
  return (
    <WrapperDiv>
      <Notification variant="info" title={translate(`checkout:about-delivery.${shoppingMethod}.title`)}>
        <BodyText>{translate(`checkout:about-delivery.${shoppingMethod}.description`)}</BodyText>
      </Notification>
    </WrapperDiv>
  );
};

AboutDeliverySection.propTypes = {
  shoppingMethod: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default helpers(['t'])(AboutDeliverySection);
