import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { PRIMARY, SECONDARY } from '@ddsweb/constants';
import helpers from '#/lib/decorators/helpers';
import {
  InnerWrapper,
  StickyBarWrapper,
  StickyBarCookieWrapper
} from '#/components/sticky-bar/styled';
import CookiePolicy from '#/components/shared/cookie-policy';
import { getDisplayCookieInterrupt } from '#/reducers/ui';
import { getIsAmendBasket } from '#/selectors/trolley';
import { connect } from '#/lib/render/connect-deep-compare';

const mapStateToProps = state => ({
  displayCookieInterrupt: getDisplayCookieInterrupt(state),
  isAmendBasket: getIsAmendBasket(state)
});

const COOKIE_BANNER_HEIGHT = 158;
const AMEND_BANNER_HEIGHT = 67;
const DEFAULT_HEIGHT = 0;

export const getStickyBannerHeight = (
  displayCookieInterrupt,
  isAmendBasket
) => {
  const cookieBannerHeight =
    (displayCookieInterrupt && COOKIE_BANNER_HEIGHT) || DEFAULT_HEIGHT;
  const amendBannerHeight =
    (!!isAmendBasket && AMEND_BANNER_HEIGHT) || DEFAULT_HEIGHT;

  return cookieBannerHeight + amendBannerHeight;
};
@helpers(['c'])
@connect(mapStateToProps)
export default class StickyBar extends Component {
  static propTypes = {
    bothButtonsAlwaysShown: PropTypes.bool,
    c: PropTypes.func.isRequired,
    children: PropTypes.any,
    className: PropTypes.string,
    hide: PropTypes.bool,
    hideAmendBanner: PropTypes.bool,
    slideIn: PropTypes.bool,
    variant: PropTypes.string,
    amendBannerDark: PropTypes.bool,
    oop1882eDarkChange: PropTypes.bool,
    oop1882bcdeBottom: PropTypes.bool
  };

  static defaultProps = {
    slideIn: false,
    variant: SECONDARY
  };

  constructor(props) {
    super(props);
    this.state = {
      offscreen: props.slideIn,
      stickyBarHeight: 0
    };
  }

  componentDidMount() {
    if (this.props.slideIn) {
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({
        offscreen: false
      });
    }
  }

  renderPrimaryStickyBarVariant = () => {
    const {
      hide,
      c: config,
      className,
      bothButtonsAlwaysShown,
      children,
      displayCookieInterrupt,
      hideAmendBanner,
      isAmendBasket,
      oop1882eDarkChange,
      oop1882bcdeBottom
    } = this.props;
    const { offscreen } = this.state;
    const isCookiePreferencesSet = config('cookiePreferences');
    const stickyBarHeight = getStickyBannerHeight(
      displayCookieInterrupt && isCookiePreferencesSet,
      isAmendBasket
    );

    return (
      <StickyBarWrapper
        stickyBarHeight={oop1882bcdeBottom ? 0 : stickyBarHeight}
        id="sticky-bar-wrapper"
        hidden={hide}
        className={classnames('sticky-bar-container', className, {
          'sticky-bar--both-buttons-always': bothButtonsAlwaysShown,
          'sticky-bar-container--collapsed': offscreen
        })}
      >
        <StickyBarCookieWrapper
          id="sticky-bar-cookie-wrapper"
          className="sticky-bar-ddl"
          oop1882bcdeBottom={this.props.oop1882bcdeBottom}
        >
          {isCookiePreferencesSet && <CookiePolicy />}
          {isAmendBasket && (
            <InnerWrapper
              oop1882bcdeBottom={this.props.oop1882bcdeBottom}
              isDark={this.props.amendBannerDark}
              hideAmendBanner={hideAmendBanner}
              hasBasketIcon={oop1882eDarkChange}
              className={classnames('sticky-bar', {
                'sticky-bar--offscreen': offscreen
              })}
            >
              {children}
            </InnerWrapper>
          )}
        </StickyBarCookieWrapper>
      </StickyBarWrapper>
    );
  };

  renderSecondaryStickyBar = () => {
    return (
      <div
        id="sticky-bar-wrapper"
        hidden={this.props.hide}
        className={classnames('sticky-bar-container', this.props.className, {
          'sticky-bar--both-buttons-always': this.props.bothButtonsAlwaysShown,
          'sticky-bar-container--collapsed': this.state.offscreen
        })}
        ref="sticky-bar-container"
      >
        <div
          className={classnames('sticky-bar', {
            'sticky-bar--offscreen': this.state.offscreen
          })}
        >
          {this.props.children}
        </div>
      </div>
    );
  };

  render() {
    const { variant, isAmendBasket } = this.props;

    if (variant === PRIMARY) {
      return this.renderPrimaryStickyBarVariant();
    } else if (isAmendBasket) {
      return this.renderSecondaryStickyBar();
    }

    return null;
  }
}
