import { createSelector } from 'reselect';
import { getFulfilmentOptionsDetailResource } from '#/selectors/resources';
import { DELIVERY } from '#/constants/shopping-methods';

export const getAvailableShoppingMethods = state =>
  (getFulfilmentOptionsDetailResource(state) || {})?.data
    ?.availableShoppingMethods || [];

export const getIsDeliveryAvailable = createSelector(
  getAvailableShoppingMethods,
  methods => methods.includes(DELIVERY)
);
