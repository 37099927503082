import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import helpers from '#/lib/decorators/helpers';
import { connect } from '#/lib/render/connect-deep-compare';
import { getTimezone, getLanguage } from '#/reducers/app';

const mapStateToProps = state => ({
  language: getLanguage(state),
  timezone: getTimezone(state)
});

@helpers(['c'])
@connect(mapStateToProps)
export default class Calendar extends React.Component {
  static displayName = 'Calendar';
  static propTypes = {
    c: PropTypes.func.isRequired,
    className: PropTypes.string,
    date: (props, propName, componentName) => {
      if (props[propName] == null) {
        return new Error(
          `Required ${propName} was not specified in ${componentName}.`
        );
      } else if (!moment(props[propName]).isValid()) {
        return new Error(
          `Expected ${propName} in ${componentName} to be a valid date.`
        );
      }
    },
    language: PropTypes.string.isRequired,
    message: PropTypes.string,
    monthFormat: PropTypes.string,
    timezone: PropTypes.string.isRequired,
    useTimezone: PropTypes.bool
  };
  static defaultProps = {
    className: '',
    monthFormat: 'MMMM',
    useTimezone: true
  };

  render() {
    const {
      c,
      className,
      date,
      language,
      message,
      monthFormat,
      timezone,
      useTimezone
    } = this.props;
    const formats = c('format') || {};
    const localisedDate = useTimezone
      ? moment(date)
          .tz(timezone)
          .locale(language)
      : moment(date);

    return (
      <div className={'calendar ' + className} data-date={localisedDate}>
        <div className="calendar-date">
          <div className="day">{localisedDate.format('ddd')}</div>
          <div className="date">
            {localisedDate.format(formats.dayOfMonth || 'DD')}
          </div>
          <div className="month">
            {localisedDate.format(formats.month || monthFormat)}
          </div>
        </div>
        {message ? (
          <div className="calendar-message">
            <span>{message}</span>
          </div>
        ) : null}
      </div>
    );
  }
}
