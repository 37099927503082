import React from 'react';
import { LOW_EVERYDAY_PRICING_MESSAGE } from '#/constants/product-tile';
import { DarkGreyBodyText, WhiteBackground } from './styled';
import { LowEverydayPriceMatchLogo } from './price-match-logo';

export const LowEveryPriceMatchInfoMessage: React.FC = () => (
  <WhiteBackground>
    <LowEverydayPriceMatchLogo />
    <DarkGreyBodyText as="p">{LOW_EVERYDAY_PRICING_MESSAGE}</DarkGreyBodyText>
  </WhiteBackground>
);
