import styled from 'styled-components';
import { spacing, colors } from '@beans/selectors';
import { InLineMessaging } from '@beans/messaging';
import { BodyText } from '@beans/typography';
import { media } from '@beans/foundation';
import { REST_OF_SHELF_MEDIUM } from '#/components/product-tiles/temp-style-constants';

export const StyledNotification = styled(InLineMessaging)`
  margin-bottom: ${spacing.lg};
  padding: ${spacing.sm};
`;
StyledNotification.displayName = 'StyledNotification';

export const StyledBodyText = styled(BodyText)`
  font-weight: 600;
  color: ${colors.base};
`;

export const StylesList = styled('ul')`
  &&& {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: ${spacing.xx};
    & > li {
      order 1;
      width: 50%;
      ${media.aboveMobileLarge`
        & > div {
          width: 100%;
        }
      `}
      ${media.aboveTabletLarge`
        width: 25%; 
      `}
      &.product-list-interrupt {
        width: 100%;
      }
      &.no-more-favorites {
        flex: 3 0px;
        justify-content: center;
        align-items: center;
      }
      .substitute-container .favorite-tile {
        width: auto;
      }
    }
    @media ${REST_OF_SHELF_MEDIUM} {
      & .rest-of-shelf {
        width: auto;
      }
    }

    &.grid .slim-trade-banner {
      width: 100%;
      padding: 0;
    }
    
  }
`;
