import { getCustomerUnitChoice } from '#/selectors/item';
import { WEIGHT_UNIT_KG, QUANTITY_TYPE } from '#/constants/common';
import { Item } from '#/lib/records/item';
import { getWeightOptions, TWeightOptionsData } from '#/selectors/beans-product-tile/weight-helpers';

// -- Types

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TTranslation = (...args: any[]) => string;

type TToggleWeightVariantOptionsData = [
  {
    id: string;
    labelText: string;
    value: typeof QUANTITY_TYPE;
  },
  {
    fieldType: string;
    id: string;
    labelText: string;
    value: typeof WEIGHT_UNIT_KG;
  },
];

export type TToggleWeightData = {
  options: TWeightOptionsData[];
  initialVariantValue?: string;
  variantOptions: {
    type: string;
    id: string;
    label: string;
    options: TToggleWeightVariantOptionsData;
  };
};

// -- Functions

const getToggleWeightData = (state: Store, item: Item, translation: TTranslation): TToggleWeightData => {
  const dataOptions: TWeightOptionsData[] = getWeightOptions(state, item);

  const variantOptionsData: TToggleWeightVariantOptionsData = [
    {
      id: 'quantity',
      labelText: translation('product-tile:toggle-quantity'),
      value: QUANTITY_TYPE,
    },
    {
      fieldType: 'dropdown',
      id: 'weight',
      labelText: translation('product-tile:toggle-weight'),
      value: WEIGHT_UNIT_KG,
    },
  ];

  return {
    variantOptions: {
      type: 'radio',
      id: 'toggle-quantity-weight',
      label: 'Toggle quantity and weight',
      options: variantOptionsData,
    },
    options: dataOptions,
    initialVariantValue: getCustomerUnitChoice(item),
  };
};

// -- Export

export default getToggleWeightData;
