import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const DisplayOnlyInNoJs = ({ children, display, HtmlTag }) => (
  <HtmlTag
    className={classnames({
      'display-only-in-no-js': true,
      'inline-block': display === 'inline-block'
    })}
  >
    {children}
  </HtmlTag>
);

DisplayOnlyInNoJs.propTypes = {
  children: PropTypes.node.isRequired,
  display: PropTypes.string,
  HtmlTag: PropTypes.string
};

DisplayOnlyInNoJs.defaultProps = {
  display: 'block',
  HtmlTag: 'div'
};

export default DisplayOnlyInNoJs;
