import { ON_DEMAND, DELIVERY, COLLECTION } from './shopping-methods';

// The following constants are important to keep static for the underlying API's / analytics in both UK/IGHS
// Changing these may cause failures in payment methods / analytic events
export const PAY_AT_DOOR_CODE = 'PaymentDoorCard';
export const PAY_ONLINE_CODE = 'PaymentOnline';
export const PAY_AT_DOOR_CASH = 'PaymentDoorCash';
export const paymentTranslations = {
  [ON_DEMAND]: {
    [PAY_ONLINE_CODE]: 'checkout:pay-online.title'
  },
  [DELIVERY]: {
    [PAY_AT_DOOR_CODE]: 'checkout:pay-at.title-by-card_delivery',
    [PAY_ONLINE_CODE]: 'checkout:pay-online.title'
  },
  [COLLECTION]: {
    [PAY_AT_DOOR_CODE]: 'checkout:pay-at.title-by-card-or-cash_collection',
    [PAY_ONLINE_CODE]: 'checkout:pay-online.title'
  }
};
