import React from 'react';
import styled, { css } from 'styled-components';
import { spacing, colors } from '@ddsweb/selectors';
import { InLineMessaging } from '@ddsweb/messaging';
import { StandaloneLink } from '@ddsweb/link';
import { INFO, TESCO_MODERN_FONT_NAME, WARNING } from '@ddsweb/constants';
import Link from '#/components/link-check-spa';
import { TSpaLink } from '#/custom-typings/utils/components.defs';
import { TInfoMessageLinkComponent, TInfoMessageTexts } from '#/selectors/beans-product-tile/info-message-data';

const InfoMessageComponent = styled(InLineMessaging)`
  color: ${colors.text};
  font-family: ${TESCO_MODERN_FONT_NAME};
  font-size: ${spacing.md};
  margin-bottom: ${spacing.sm};
`;

const linkStyles = css`
  -webkit-font-smoothing: auto;
  .ddsweb-link__icon {
    margin-left: 4px;
    svg {
      height: 11px;
      width: 10px;
      stroke-width: 2.8px;
    }
  }
`;

const infoMessageVariantColor = (backgroundColorClass?: string | null): string =>
  backgroundColorClass === 'with-warning-background unavailable-messages' ? WARNING : INFO;

const infoMessage = (
  i: number,
  message: string,
  hasBackgroundColor?: boolean,
  backgroundColorClass?: string | null,
): JSX.Element => {
  return (
    <InfoMessageComponent
      key={i}
      data-auto="info-message"
      variant={infoMessageVariantColor(backgroundColorClass)}
      bare={!hasBackgroundColor}
    >
      {message}
    </InfoMessageComponent>
  );
};

export const InfoMessage: React.FC<TInfoMessageTexts> = ({ messages, hasBackgroundColor, backgroundColorClass }) => {
  return <>{messages.map((message, i) => infoMessage(i, message, hasBackgroundColor, backgroundColorClass))}</>;
};

const spaLink = ({ children, className, href, onClick }: TSpaLink): JSX.Element => (
  <Link to={href} className={className} onClick={onClick}>
    {children}
  </Link>
);

export const InfoMessageLink: React.FC<TInfoMessageLinkComponent> = ({ link }) => {
  if (!link || !link.href) return <React.Fragment />;
  return (
    <div className="product-info-message__link">
      <StandaloneLink
        emphasized
        className="product-info-message__link-button"
        href={link.href}
        icon={{ graphic: 'forwardLink', position: { global: 'right' } }}
        onClick={link.onClick}
        as={spaLink}
        styles={linkStyles}
      >
        {link.text}
      </StandaloneLink>
    </div>
  );
};
