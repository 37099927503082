import React from 'react';
import { ConnectedProps } from 'react-redux';
import helpers from '#/lib/decorators/helpers';
import { TTranslateFunc } from '#/lib/records/helpers.defs';
import { connect } from '#/lib/render/connect-deep-compare';
import DeleteWithCrossIcon from './delete-with-cross-icon';

type OwnProps = {
  productId: string;
  productTitle: string;
  t: TTranslateFunc;
};

type OwnState = {
  title: string;
  productId: string;
};

export const mapStateToProps = (state: Store, { productId, productTitle }: OwnProps): OwnState => {
  return {
    title: productTitle,
    productId: productId,
  };
};

const connector = connect(mapStateToProps);

type Props = OwnProps & OwnState & ConnectedProps<typeof connector>;

const FavoriteOptimisticDelete = (props: Props): JSX.Element => {
  const { t: translate, title, productId } = props;

  const accessibleText = translate('product-list:remove-from-favourites', { productName: title });

  return <DeleteWithCrossIcon title={title} accessibleText={accessibleText} productId={productId} />;
};

export default connector(helpers(['t'])(FavoriteOptimisticDelete));
