import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Panel = ({ children, classes, styles }) => (
  <div className={classnames('ui-panel', classes)} style={styles}>
    {children}
  </div>
);

Panel.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.string,
  styles: PropTypes.object
};

export { Panel };
