/**
 * VoiceOver on MacOS & iOS allows the user to trigger an onChange event with the already-selected
 * option. In this situation, the event.target.value will be undefined, but this function will find
 * the selected option in event.target.options and set event.target.value from that.
 * @param   {object} event - React synthetic event
 * @returns {object} event - React synthetic event with the target.value set (if possible)
 */
export function ensureEventTargetValueSet(event) {
  if (typeof event.target.value !== 'string' && event.target.options) {
    const selectedOption = [].find.call(event.target.options, option =>
      option.hasAttribute('selected')
    );

    if (selectedOption) {
      event.target.value = selectedOption.value;
    }
  }

  return event;
}
