import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';
import helpers from '../../../../lib/decorators/helpers';
import SafelyOutputString from '#/components/safely-output-string';

const enhance = compose(helpers(['t']));

const DeliverySaverWarning = ({ t }) => (
  <div className="delivery-saver-warning">
    <h3>{t('slots:delivery-saver.not-active-title')}</h3>
    <div className="delivery-saver-warning--message">
      <SafelyOutputString>
        {t('slots:delivery-saver.not-active-message-midweek')}
      </SafelyOutputString>
    </div>
  </div>
);

DeliverySaverWarning.propTypes = {
  t: PropTypes.func.isRequired
};

export default enhance(DeliverySaverWarning);
