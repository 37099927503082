import { COMPETITORS } from './competitors';
import { ADDITIONAL_INFO } from './additional-info';

const BASKET_PRODUCT_ITEM = `fragment BasketProductItem on ProductInterface {
  typename: __typename
  id
  gtin
  seller{
    id
  }
  aisleId
  aisleName
  shelfId
  shelfName
  baseProductId
  title
  brandName
  shortDescription
  defaultImageUrl
  superDepartmentName
  departmentName
  displayType
  productType
  averageWeight
  bulkBuyLimit
  groupBulkBuyLimit
  bulkBuyLimitMessage
  bulkBuyLimitGroupId
  timeRestrictedDelivery
  restrictedDelivery
  isForSale
  isInFavourites
  isRestrictedOrderAmendment
  status
  maxWeight
  minWeight
  increment
  availability {
    ... on SelectedStoreProductAvailabilityType {
      quantity
      status
    }
  }
  details {
    components {
      ...Competitors
      ...AdditionalInfo
    }
  }
  catchWeightList{
    price
    weight
  }
  restrictions {
    type
    isViolated
    message
    shortMessage
  }
  price {
    price: actual
    unitPrice
    unitOfMeasure
  }
  charges { 
    ... on ProductDepositReturnCharge { 
     __typename
     amount
    }
  }
  promotions {
    promotionId: id
    promotionType
    startDate
    endDate
    unitSellingInfo
    offerText: description
    price {
      beforeDiscount
      afterDiscount
    }
    timesTriggered
    missedPromotion: missed
    attributes
    warnings {
      threshold
      warningType: type
    }
    statuses {
      rewards {
        timesTriggered
        savings
      }
      warnings {
        type
        threshold
      }
    }
  }
  ... on MPProduct {
      seller {
        id
        name
      }
  }
}
${COMPETITORS}
${ADDITIONAL_INFO}`;

export default BASKET_PRODUCT_ITEM;
