import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from '../../../lib/render/connect-deep-compare';
import Modal from '../../shared/modal';
import ModalContent from '../../shared/modal/content';
import ModalActions from '../../shared/modal/actions';
import ModalButtonContainer from '../../shared/modal/actions/button-container';
import FormattedLink from '../../link';
import helpers from '../../../lib/decorators/helpers';
import { getCurrentUrl } from '#/reducers/app';
import { closeModal } from '../../../actions/ui-action-creators';
import { removeModalFromUrl } from '../../../lib/url/modal-utils';
import analyticsBus from '../../../analytics/analyticsBus';

const mapStateToProps = state => ({
  currentUrl: getCurrentUrl(state)
});
@helpers(['c', 'l', 't'])
@connect(mapStateToProps, { closeModal })
export default class UndeliverableAddressModal extends Component {
  static propTypes = {
    c: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    currentUrl: PropTypes.string.isRequired,
    l: PropTypes.func.isRequired,
    modalData: PropTypes.string,
    t: PropTypes.func.isRequired
  };

  static defaultProps = {
    modalData: ''
  };

  componentDidMount() {
    analyticsBus().emit('errorData', {
      code: 'undeliverable-address',
      text: 'unable to deliver to this address',
      type: 'action'
    });
  }

  closeX(onClick, url) {
    return (
      <a
        className="close-x icon-cancel"
        onClick={event => {
          event.preventDefault();
          onClick();
        }}
        href={removeModalFromUrl(url)}
      />
    );
  }

  render() {
    return (
      <Modal
        dismissable={false}
        title={this.props.t('modals:undeliverable-address.title')}
        className="undeliverable-address-modal"
      >
        <ModalContent>
          {this.closeX(this.props.closeModal, this.props.currentUrl)}
          <div className="yellow-background">
            <p>{this.props.t('modals:undeliverable-address.line1')}</p>
            <p>
              <strong>
                {this.props.t('modals:undeliverable-address.line2')}
              </strong>
            </p>
            <a
              className="more-info"
              href={this.props.c('links:homeDeliveryTab')}
              rel="noopener"
              target="_blank"
            >
              {this.props.t('modals:undeliverable-address.line3')}
              <span className="icon icon-question-dark" />
            </a>
          </div>
          <ModalActions>
            <ModalButtonContainer>
              <FormattedLink
                className="button button-primary"
                href={this.props.l('/slots/collection')}
                onClick={this.props.closeModal}
              >
                {this.props.t(
                  'modals:undeliverable-address.choose-click-collect-slot'
                )}
              </FormattedLink>
              <FormattedLink
                className="button button-secondary"
                href={this.props.l('/slots/delivery')}
                onClick={this.props.closeModal}
              >
                {this.props.t(
                  'modals:undeliverable-address.choose-new-address'
                )}
              </FormattedLink>
            </ModalButtonContainer>
          </ModalActions>
        </ModalContent>
      </Modal>
    );
  }
}
