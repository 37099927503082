import React from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import { TextShortForm, FontSizes } from '@ddsweb/text';
import { DefaultStyledProps } from '@ddsweb/types';
import { colors } from '@ddsweb/selectors';
import { DietaryTagText } from '#/experiments/oop-1922/types';
import { productTileDietaryInfoVariants } from '#/experiments/oop-1922/constants';

const { dietaryIcon, dietaryTags, dietaryLogo } = productTileDietaryInfoVariants;

const DietaryTextComponent = styled(TextShortForm)`
  color: ${(props: DietaryTagText & DefaultStyledProps): string =>
    props.textType === dietaryIcon ? colors.text(props) : colors.inverse(props)};

  &.font-weight {
    font-weight: bold;
  }
`;

export const DietaryText = (props: DietaryTagText): JSX.Element | null => {
  const { label, textType } = props;

  if (textType === dietaryLogo) return null;

  return (
    <DietaryTextComponent
      className={classnames({ 'font-weight': textType === dietaryTags })}
      textType={textType}
      size={textType === dietaryTags ? FontSizes.extraSmall : FontSizes.small}
      inheritColor
    >
      {label}
    </DietaryTextComponent>
  );
};
