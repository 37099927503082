import isServer from '#/conditional-resources/conditions/is-server';
import { isOnDemandDeliveryShoppingMethod } from '#/lib/shopping-method-util';
import { ON_DEMAND } from '#/constants/shopping-methods';

export default (state, props) =>
  isServer(state)
    ? props?.req?.path.includes(ON_DEMAND) ?? false
    : isOnDemandDeliveryShoppingMethod(
        props?.routeProps?.params?.shoppingMethod
      );
