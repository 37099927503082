export const VARIATION_KEY = 'searchBoxPosition';
export const FEATURE_KEY = 'GHS-<region>_1972_searchBoxPosition';

export enum MovingSearchBarVariants {
  DEFAULT = 'default',
  LEFT = 'left',
  CENTER = 'center',
  CENTER_WIDE = 'center-wide',
}

export const MovingSearchBarFeature = {
  featureKey: FEATURE_KEY,
  variationKey: VARIATION_KEY,
};
