import React from 'react';
import { ConnectedProps } from 'react-redux';
import { compose } from 'react-recompose';
import { connect } from '#/lib/render/connect-deep-compare';
import helpers from '#/lib/decorators/helpers';
import InfoMessage from '#/components/shared/info-message';
import { getFormattedDateTime } from '#/components/shared/formatted-time/selector';

type OwnProps = {
  showAmendUnvailabilityText?: boolean | undefined;
  amendCutoff?: string;
  isOrderDue: boolean;
};

type HelperProps = {
  t: (key: string, options?: object) => string;
  c: (key: string) => string;
};

const mapStateToProps = (state: Store, ownProps: OwnProps & HelperProps): { amendCutoffDateTime: string } => {
  return {
    amendCutoffDateTime: getFormattedDateTime(state, ownProps.amendCutoff || null, ownProps.c),
  };
};

const connector = connect(mapStateToProps);

type Props = OwnProps & HelperProps & ConnectedProps<typeof connector>;

export const AmendOrderAvailabilityText: React.FC<Props> = ({
  showAmendUnvailabilityText,
  t: translate,
  amendCutoffDateTime,
  isOrderDue,
}) => {
  return (
    <div className="trolley-order-summary--amend-message-container">
      {showAmendUnvailabilityText && (
        <InfoMessage html={false}>
          <p>{translate(`checkout:order-summary.amend-not-available-text`)}</p>
        </InfoMessage>
      )}
      {!isOrderDue && !showAmendUnvailabilityText && (
        <InfoMessage html={false}>
          <>
            <p>{translate('orders:my-orders.you-can-change-until')}</p>
            <p>{amendCutoffDateTime}</p>
          </>
        </InfoMessage>
      )}
    </div>
  );
};

const enhance = compose<Props, OwnProps>(helpers(['t', 'c']), connector);

export default enhance(AmendOrderAvailabilityText);
