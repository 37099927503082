import { Request, Response } from 'express';
import { promotionDateDisplayFeature } from '#/experiments/oop-892/constants';
import { getDeviceTypeFromReq } from '#/lib/device-type-utils';
import { getVariantForExperimentUsingRequest } from '#/lib/optimizely-manager';
import { getVariationForExperiment } from '#/lib/optimizely-manager';
import { PlainObject } from '#/types';

type TPromotionConfig = { featureKey: string; params: [{ name: string; value: string }] };

export const getPromotionDateDisplayVariantWithState = (state: PlainObject): string => {
  const hasSlot = state.trolley?.bookedSlot?.status !== null;
  if (!hasSlot) return promotionDateDisplayFeature.variants.default;
  const variant = getVariationForExperiment(
    state,
    promotionDateDisplayFeature.key,
    {},
    promotionDateDisplayFeature.useCustomerId,
  );
  return variant !== null ? variant : promotionDateDisplayFeature.variants.default;
};

export const getPromotionDateDisplayVariantWithReqRes = (req: Request, res: Response): string =>
  getVariantForExperimentUsingRequest(
    req,
    res,
    promotionDateDisplayFeature.key,
    getDeviceTypeFromReq(req),
    promotionDateDisplayFeature.useCustomerId,
  );

export const getPromotionDateDisplayConfigWithReqRes = (req: Request, res: Response): TPromotionConfig[] => {
  const hasSlot = !!res.locals?.props?.trolley?.slot;
  if (!hasSlot) return [] as TPromotionConfig[];

  const variant = getPromotionDateDisplayVariantWithReqRes(req, res);
  if (variant && variant !== promotionDateDisplayFeature.variants.default) {
    return [
      {
        featureKey: 'ghs_promotions',
        params: [
          {
            name: 'filter_with',
            value: 'slot',
          },
        ],
      },
    ];
  }
  return [] as TPromotionConfig[];
};
