import { OfferData, Attribute } from '../types';
import { NEW, SPONSORED, WHYNOTTRY } from '../../constants';

export interface OfferDataPayload {
  attributes?: string[];
  missedPromotion?: boolean;
  offerText?: string;
  promotionId?: string;
  proposition?: string;
}

interface SashAnalyticsPayload {
  isNew?: boolean;
  isSponsoredProduct?: boolean;
  isWhyNotTry?: boolean;
}

export function offerData(payload: OfferDataPayload): OfferData {
  const { missedPromotion, promotionId, proposition } = payload;
  const output: OfferData = !proposition
    ? {
        offerId: promotionId,
      }
    : {
        proposition,
      };

  if (typeof missedPromotion !== 'undefined') {
    output.offerMet = !missedPromotion;
  }

  return output;
}

export function getSashAnalyticsAttributes(payload: SashAnalyticsPayload): Attribute[] {
  const attributes: Attribute[] = [];
  const { isNew, isSponsoredProduct, isWhyNotTry } = payload;

  if (!isNew && !isSponsoredProduct && !isWhyNotTry) {
    return attributes;
  }

  if (isSponsoredProduct) {
    attributes.push({ attributeValue: SPONSORED });
  }

  if (isWhyNotTry) {
    attributes.push({ attributeValue: WHYNOTTRY });
  }

  if (isNew) {
    attributes.push({ attributeValue: NEW });
  }

  return attributes;
}
