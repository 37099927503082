import React from 'react';
import { CloseIcon } from '@ddsweb/icon';
import { XS, SECONDARY } from '@ddsweb/constants';
import { getLanguage } from '#/reducers/app';
import { getSelectedShoppingMethod } from '#/reducers/slot';
import { sessionStore } from '#/lib/data-store/client-store';
import { TConfigFunc, TTranslateFunc } from '#/lib/records/helpers.defs';
import helpers from '#/lib/decorators/helpers';
import { connect } from '#/lib/render/connect-deep-compare';
import { ShoppingMethod } from '#/constants/shopping-methods';
import SafelyOutputString from '#/components/safely-output-string';
import { basicEvent } from '#/analytics/types/basic';
import analyticsBus from '#/analytics/analyticsBus';
import {
  StyledCloseButton,
  StyledDSAcquisitionContainer,
  StyledLink,
} from '#/components/slots/ds-acquisition-banner/styled';
import { DeliverySaverInfo } from '#/components/delivery-saver-info';
import { dismissDeliverySaverAcquisitionBanner } from '#/actions/slot-action-creators';
import { NOW, SLOTS } from '#/analytics/constants';
import {
  DELIVERY_SAVER_BANNER_CLOSE,
  DELIVERY_SAVER_BANNER_VIEW_PLANS,
  DISMISS_DS_ACQUISITION_BANNER,
} from '#/constants/action-types';
import { getDsBannerOnSlotMatrixVariant } from '#/experiments/oop-2141/selectors';

type StateProps = {
  language: string;
  selectedShoppingMethod: ShoppingMethod;
  dsBannerVariant: string;
};

type TOwnProps = {
  c: TConfigFunc;
  t: TTranslateFunc;
  dismissDeliverySaverAcquisitionBanner: () => void;
} & StateProps;

const mapStateToProps = (state: Store): StateProps => ({
  language: getLanguage(state),
  selectedShoppingMethod: getSelectedShoppingMethod(state),
  dsBannerVariant: getDsBannerOnSlotMatrixVariant(state).replace('-', ''),
});

const emitDeliverySaverBannerEvent = (value: string): void => {
  basicEvent(analyticsBus, {
    action: NOW,
    type: SLOTS,
    value: value,
  });
};

const DeliverySaverAcquisitionBanner: React.FC<TOwnProps & StateProps> = (props: TOwnProps): JSX.Element => {
  const {
    c: config,
    t: translate,
    language,
    selectedShoppingMethod,
    dsBannerVariant,
    dismissDeliverySaverAcquisitionBanner,
  } = props;

  const onDSAcquisitionBannerClose = (): void => {
    sessionStore?.set(DISMISS_DS_ACQUISITION_BANNER, true);

    dismissDeliverySaverAcquisitionBanner();

    emitDeliverySaverBannerEvent(DELIVERY_SAVER_BANNER_CLOSE);
  };

  const renderOnCloseBtn = (): JSX.Element => (
    <StyledCloseButton
      onClick={onDSAcquisitionBannerClose}
      variant="link"
      icon={<CloseIcon />}
      aria-label={translate('common:close')}
      size={XS}
    />
  );

  const deliverySaverTermsAndConditionsLink = config(`links:termsConditionsDeliverySaver:${language}`);

  return (
    <DeliverySaverInfo title={translate(`slots:delivery-saver.title`)} headerLink={renderOnCloseBtn()}>
      <StyledDSAcquisitionContainer>
        <div className="ds-acquisition-banner__text">
          <SafelyOutputString>
            {translate(`slots:delivery-saver.oop-2141.${dsBannerVariant}.description_${selectedShoppingMethod}`)}
          </SafelyOutputString>
        </div>
        {deliverySaverTermsAndConditionsLink && (
          <span className="oop-2141_ds-acquisition-banner__footnote">
            <SafelyOutputString>
              {translate(`slots:delivery-saver.oop-2141.${dsBannerVariant}.delivery_saver_banner_footnote`, {
                href: deliverySaverTermsAndConditionsLink,
              })}
            </SafelyOutputString>
          </span>
        )}
        <div className="oop-2141_ds-acquisition-banner__button">
          <StyledLink
            href={config(`links:plansDeliverySaver${dsBannerVariant}:${language}`).toString()}
            variant="textButton"
            buttonVariant={SECONDARY}
            target="_blank"
            rel="noopener"
            onClick={(): void => emitDeliverySaverBannerEvent(DELIVERY_SAVER_BANNER_VIEW_PLANS)}
            className="delivery-saver-banner__view-plans"
          >
            {translate(`slots:delivery-saver.oop-2141.${dsBannerVariant}.banner-button-label`)}
          </StyledLink>
        </div>
      </StyledDSAcquisitionContainer>
    </DeliverySaverInfo>
  );
};

export default helpers(['t', 'c'])(
  connect(mapStateToProps, { dismissDeliverySaverAcquisitionBanner })(DeliverySaverAcquisitionBanner),
);
