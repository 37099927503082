import getResourceNameFromConfig from '#/conditional-resources/helpers/get-resource-name-from-config';

export default function getUnconditionalResourceNames(
  relevantResourcesFromRoutes
) {
  return relevantResourcesFromRoutes.reduce((unconditional, resource) => {
    if (typeof resource === 'string') {
      return [...unconditional, getResourceNameFromConfig(resource)];
    }

    return unconditional;
  }, []);
}
