import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ResponsiveBackground } from '../responsive-background';

/*
 * Encapsulates standard home page widget styling,
 * background images and borders
 */

const HomeWidget = ({
  backgroundImageUrl,
  border,
  children,
  cropDirection,
  height,
  widgetName,
  maxWidth,
  breakpoints,
  uid
}) => (
  <div
    className={classnames(`home-widget home-widget--${widgetName}`, {
      'has-border': border
    })}
    style={{ height }}
  >
    <ResponsiveBackground
      className="home-widget--background"
      url={backgroundImageUrl}
      breakpoints={breakpoints}
      maxWidth={maxWidth}
      clipping={cropDirection}
      uid={uid}
    />
    <div className="home-widget--content">{children}</div>
  </div>
);

HomeWidget.propTypes = {
  backgroundImageUrl: PropTypes.string,
  border: PropTypes.bool,
  breakpoints: PropTypes.arrayOf(
    PropTypes.shape({
      imageWidth: PropTypes.number,
      screenWidth: PropTypes.number
    })
  ),
  children: PropTypes.node,
  cropDirection: PropTypes.oneOf(['left', 'right', 'center']),
  height: PropTypes.string,
  maxWidth: PropTypes.number.isRequired,
  uid: PropTypes.number,
  widgetName: PropTypes.string.isRequired
};

HomeWidget.defaultProps = {
  cropDirection: 'center'
};

export { HomeWidget };
