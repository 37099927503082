import styled from 'styled-components';
import { spacing } from '@beans/selectors';
import LinkButtonWithFeedback from '#/components/shared/link-button-with-feedback';

export const StyledButtonContainer = styled.div`
  margin: ${spacing.md} 0;
`;

export const StyledLinkButtonWithFeedback = styled(LinkButtonWithFeedback)`
  margin-bottom: ${spacing.sm};
`;
