import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from '#/lib/render/connect-deep-compare';
import helpers from '#/lib/decorators/helpers';
import PromotionsSelector from './promotions-selector';
import SharedFilterSelector from './shared-filter-selector';
import { AVAILABLE } from '#/constants/facet-categories';
import { POLITE } from '#/constants/common';
import FilterOptions from './filter-options';
import CountOrShowMoreLink from '#/components/sort-n-filter/filter-by/count-or-show-more-link';
import AppliedFilters from './applied-filters';
import SortControl from './sort-control';
import FilterSelector from './filter-selector';
import { hyphenate } from '#/utils/text-utils';
import * as facets from '#/constants/facet-categories';
import { getCurrentFilters } from '#/reducers/filter';
import LifestyleAndDietaryFooter from '#/components/sort-n-filter/lifestyle-and-dietary-footer';
import {
  FILTER_OPTION_RADIO,
  FILTER_OPTION_CHECKBOX
} from '#/constants/filter-option';
import {
  FILTER_OPTIONS_AVAILABLE,
  FILTER_OPTIONS_BRAND,
  FILTER_OPTIONS_CATEGORY,
  FILTER_OPTIONS_DIETARY,
  FILTER_OPTIONS_OFFERS,
  FILTER_OPTIONS_PRODUCT_SOURCE,
  FILTER_OPTIONS_SUPERDEPARTMENT
} from '#/constants/filter-options';
import { getTrolleyShoppingMethod } from '#/selectors/trolley';
import { isOnDemandShoppingMethod } from '#/lib/shopping-method-util';
import { OFFERS } from '#/constants/facet-categories';
import GHSMarketplaceFooter from '../ghs-marketplace-footer';
import { MULTI_SEARCH_URL, SEARCH_URL } from '#/selectors/ui/constants';
import { getIsCurrentPage } from '#/reducers/app';
import { isShopUrl } from '#/selectors/product-details';

const mapStateToProps = (state, ownProps) => {
  const shoppingMethod = getTrolleyShoppingMethod(state);
  const isOnDemandShopping = isOnDemandShoppingMethod(shoppingMethod);

  return {
    currentFilters: getCurrentFilters(state),
    showDietaryFilter:
      ownProps.f('enableLifestyleAndDietaryProductFilters') &&
      ownProps.showDietaryFilter,
    shouldRenderCategoriesFilter: !isOnDemandShopping,
    showMarketPlaceFilter:
      ownProps.c('showMarketPlaceFilter') &&
      !isOnDemandShopping &&
      (getIsCurrentPage(state, [SEARCH_URL, MULTI_SEARCH_URL]) ||
        isShopUrl(state))
  };
};

function createFacetType(
  facetName,
  displayMode,
  displayAllOptionsWhenSelected,
  displayAllOption
) {
  const facetNameVal =
    facetName === facets.PRODUCT_SOURCE ? facetName : facetName.toLowerCase();
  return {
    facetName: facetNameVal,
    displayMode,
    displayAllOptionsWhenSelected,
    displayAllOption
  };
}

@helpers(['t', 'f', 'c'])
@connect(mapStateToProps)
export default class FilterBy extends PureComponent {
  static propTypes = {
    count: PropTypes.number.isRequired,
    currentFilters: PropTypes.object.isRequired,
    extractFacetList: PropTypes.func,
    f: PropTypes.func.isRequired,
    facets: PropTypes.array,
    isStickyFilterVariant: PropTypes.bool,
    onChange: PropTypes.func,
    renderVeganMessage: PropTypes.func,
    showBrandsFilter: PropTypes.bool,
    showAvailabilityAsDropdown: PropTypes.bool,
    showCategoriesFilter: PropTypes.bool,
    showCount: PropTypes.bool,
    showDietaryFilter: PropTypes.bool,
    showOfferAsDropdown: PropTypes.bool,
    showPromotionsFilter: PropTypes.bool,
    showSuperDepartmentFilter: PropTypes.bool,
    sortOptions: PropTypes.array,
    t: PropTypes.func.isRequired
  };

  static defaultProps = {
    isStickyFilterVariant: false,
    showPromotionsFilter: true,
    showCategoriesFilter: true,
    showCount: true,
    showSuperDepartmentFilter: false,
    showDietaryFilter: true,
    showBrandsFilter: true,
    showAvailabilityAsDropdown: false,
    showOfferAsDropdown: false,
    sortOptions: []
  };

  constructor() {
    super();

    this.state = {
      visible: null
    };

    this.filterToFacetsMap = new Map([
      [
        FILTER_OPTIONS_CATEGORY,
        [
          createFacetType(
            facets.SUPERDEPARTMENT,
            FILTER_OPTION_CHECKBOX,
            false,
            false
          ),
          createFacetType(
            facets.DEPARTMENT,
            FILTER_OPTION_CHECKBOX,
            false,
            false
          ),
          createFacetType(facets.AREA, FILTER_OPTION_CHECKBOX, false, false),
          createFacetType(facets.AISLE, FILTER_OPTION_CHECKBOX, false, false),
          createFacetType(facets.SHELF, FILTER_OPTION_CHECKBOX, true, false)
        ]
      ],

      [
        FILTER_OPTIONS_SUPERDEPARTMENT,
        [
          createFacetType(
            facets.SUPERDEPARTMENT,
            FILTER_OPTION_RADIO,
            true,
            true
          )
        ]
      ],

      [
        FILTER_OPTIONS_BRAND,
        [createFacetType(facets.BRAND, FILTER_OPTION_CHECKBOX, true, false)]
      ],

      [
        FILTER_OPTIONS_DIETARY,
        [createFacetType(facets.DIETARY, FILTER_OPTION_CHECKBOX, true, false)]
      ],

      [
        FILTER_OPTIONS_AVAILABLE,
        [
          createFacetType(
            facets.AVAILABLE,
            FILTER_OPTION_CHECKBOX,
            false,
            false
          )
        ]
      ],

      [
        FILTER_OPTIONS_OFFERS,
        [
          createFacetType(
            facets.PROMOTION,
            FILTER_OPTION_CHECKBOX,
            false,
            false
          )
        ]
      ],
      [
        FILTER_OPTIONS_PRODUCT_SOURCE,
        [
          createFacetType(
            facets.PRODUCT_SOURCE,
            FILTER_OPTION_RADIO,
            true,
            false
          )
        ]
      ]
    ]);
  }

  componentWillReceiveProps(nextProps) {
    const { facets } = this.props;

    if (this.state.visible && facets !== nextProps.facets) {
      const facetNamesForFilter = this.filterToFacetsMap
        .get(this.state.visible)
        .map(type => type.facetName);

      const visibleFilterHasFacets = nextProps.facets.some(facet => {
        return (
          facetNamesForFilter.includes(facet.category.toLowerCase()) &&
          facet.facets.length
        );
      });

      if (!visibleFilterHasFacets) {
        this.toggle(this.state.visible);
      }
    }
  }

  componentWillUnmount() {
    this.removeCloseHandler();
  }

  toggle = filter => {
    this.setState({
      visible: this.state.visible === filter ? null : filter
    });

    if (this.state.filter !== null) {
      this.addCloseHandler();
    } else {
      this.removeCloseHandler();
    }
  };

  closeAll = evt => {
    if (evt?.target?.classList?.contains('filter-select')) {
      evt.stopPropagation();
    }
    this.setState({
      visible: null
    });

    this.removeCloseHandler();
  };

  addCloseHandler() {
    const { isStickyFilterVariant = false } = this.props;
    if (typeof document !== 'undefined') {
      document.addEventListener('click', this.closeAll, isStickyFilterVariant);
    }
  }

  removeCloseHandler() {
    const { isStickyFilterVariant = false } = this.props;
    if (typeof document !== 'undefined') {
      document.removeEventListener(
        'click',
        this.closeAll,
        isStickyFilterVariant
      );
    }
  }

  sortOptions() {
    const { sortOptions, t: translate } = this.props;

    return sortOptions.map(key => ({
      label: translate(`sort-and-filter:sort-options.${hyphenate(key)}`),
      value: key
    }));
  }

  renderNewFilter() {
    const { extractFacetList, currentFilters } = this.props;
    const category = facets.NEW_PRODUCT.toLowerCase();
    const facet = extractFacetList([category]);

    return (
      facet &&
      !!facet.facets.length && (
        <FilterSelector
          category={category}
          filter={facet}
          selected={!!currentFilters.new}
        />
      )
    );
  }

  renderFavouritesFilter() {
    const { extractFacetList, currentFilters } = this.props;
    const category = facets.FAVOURITES.toLowerCase();
    const facet = extractFacetList([category]);

    return (
      facet &&
      !!facet.facets.length && (
        <FilterSelector
          category={category}
          filter={facet}
          selected={!!currentFilters.favourites}
          showCount={false}
          ariaLive={POLITE}
        />
      )
    );
  }

  renderPromotionsFilter() {
    const {
      extractFacetList,
      currentFilters,
      showOfferAsDropdown
    } = this.props;

    const facet = extractFacetList([facets.PROMOTION.toLowerCase()]);

    if (facet && showOfferAsDropdown) {
      return this.renderFilterOptions(FILTER_OPTIONS_OFFERS, OFFERS, {
        smoothTopLeft: true
      });
    }
    return (
      facet && (
        <PromotionsSelector
          promotions={facet}
          selected={typeof currentFilters.promotion !== 'undefined'}
        />
      )
    );
  }

  renderAvailableFilter() {
    const {
      extractFacetList,
      currentFilters,
      showAvailabilityAsDropdown,
      isStickyFilterVariant
    } = this.props;
    const filterName = AVAILABLE.toLowerCase();
    const facet = extractFacetList([filterName]);
    const filtersToRemove = [];
    const selected = typeof currentFilters[filterName] !== 'undefined';
    const id = 'available-selector';

    if (facet && showAvailabilityAsDropdown) {
      return this.renderFilterOptions(FILTER_OPTIONS_AVAILABLE, AVAILABLE, {
        smoothTopLeft: true
      });
    }

    return (
      facet && (
        <SharedFilterSelector
          id={id}
          filterData={facet}
          selected={selected}
          filterName={filterName}
          translationKeys={
            isStickyFilterVariant
              ? 'sort-and-filter:available-sticky'
              : 'sort-and-filter:available'
          }
          filtersToRemove={filtersToRemove}
        />
      )
    );
  }

  renderCategoriesFilter() {
    return this.renderFilterOptions(FILTER_OPTIONS_CATEGORY, 'categories', {
      smoothTopLeft: true
    });
  }

  renderBrandsFilter() {
    return this.renderFilterOptions(FILTER_OPTIONS_BRAND, 'brands');
  }

  renderDietaryFilter() {
    return this.renderFilterOptions(
      FILTER_OPTIONS_DIETARY,
      'lifestyle-and-dietary',
      {
        lastChild: true,
        header: this.props.t('sort-and-filter:lifestyle-and-dietary-header'),
        footer: <LifestyleAndDietaryFooter />
      }
    );
  }

  renderSuperDepartmentFilter() {
    return this.renderFilterOptions(
      FILTER_OPTIONS_SUPERDEPARTMENT,
      'categories',
      {
        smoothTopLeft: true
      }
    );
  }

  renderSuperDeptAndCategoriesFilters() {
    const { showSuperDepartmentFilter, showCategoriesFilter } = this.props;

    return (
      <Fragment>
        {showSuperDepartmentFilter && this.renderSuperDepartmentFilter()}
        {showCategoriesFilter && this.renderCategoriesFilter()}
      </Fragment>
    );
  }

  renderProductSourceFilter = () => {
    const { t } = this.props;
    return this.renderFilterOptions(
      FILTER_OPTIONS_PRODUCT_SOURCE,
      'tesco-marketplace-products',
      {
        header: t('sort-and-filter:tesco-marketplace-products-header'),
        footer: <GHSMarketplaceFooter />,
        isProductSource: true
      }
    );
  };

  renderFilterOptions(
    filterNamespace,
    translationKey,
    additionalFilterOptions = {}
  ) {
    const {
      extractFacetList,
      t: translate,
      renderVeganMessage,
      showCount,
      showOfferAsDropdown
    } = this.props;

    const facetTypes = this.filterToFacetsMap.get(filterNamespace);

    const facets = extractFacetList(
      facetTypes.map(x => x.facetName),
      filterNamespace
    ) || {
      facets: []
    };

    const label = showOfferAsDropdown
      ? translate(`sort-and-filter:dropdown-variant.${translationKey}`)
      : translate(
          `sort-and-filter:${translationKey}${showCount ? '-count' : ''}`,
          { smart_count: facets.facets.length }
        );
    return (
      <FilterOptions
        facetTypes={facetTypes}
        id={`filter-${filterNamespace}`}
        items={facets}
        label={label}
        show={this.state.visible === filterNamespace && !!facets.facets.length}
        showLessLabel={translate(`sort-and-filter:fewer-${translationKey}`)}
        showMoreLabel={translate(`sort-and-filter:more-${translationKey}`)}
        smoothTopLeft={false}
        onOpenOrClose={this.toggle}
        type={filterNamespace}
        {...additionalFilterOptions}
        renderVeganMessage={renderVeganMessage}
      />
    );
  }

  render() {
    const {
      sortOptions,
      count,
      isStickyFilterVariant,
      showPromotionsFilter,
      showBrandsFilter,
      showDietaryFilter,
      shouldRenderCategoriesFilter,
      showAvailabilityAsDropdown,
      showOfferAsDropdown,
      t: translate,
      showMarketPlaceFilter
    } = this.props;
    const showOptions = !!this.state.visible;
    const className = classNames('new-filter-by', {
      'show-options': showOptions
    });
    const hasSortOptions = sortOptions.length > 0;
    const filterClassName = classNames('filter-by__selector-wrapper');

    return (
      <div className={className}>
        <CountOrShowMoreLink count={count} />
        <div className={classNames('sort-and-filter-by-container')}>
          <div className="filter-by-container" data-auto="filter-by-container">
            <span className="filter-by-section-label">
              {`${translate('sort-and-filter:filter-by')}:`}
            </span>
            <ul className={filterClassName}>
              {this.renderNewFilter()}
              {!showOfferAsDropdown &&
                showPromotionsFilter &&
                this.renderPromotionsFilter()}
              {!showAvailabilityAsDropdown && this.renderAvailableFilter()}
              {this.renderFavouritesFilter()}
            </ul>
            <ul className="selects-row grid">
              {showOfferAsDropdown &&
                showPromotionsFilter &&
                this.renderPromotionsFilter()}
              {shouldRenderCategoriesFilter &&
                this.renderSuperDeptAndCategoriesFilters()}
              {showAvailabilityAsDropdown && this.renderAvailableFilter()}
              {showBrandsFilter && this.renderBrandsFilter()}
              {showDietaryFilter && this.renderDietaryFilter()}
              {showMarketPlaceFilter && (
                <div className="desktop-product-source-filter">
                  {this.renderProductSourceFilter()}
                </div>
              )}
            </ul>
          </div>
          {hasSortOptions && (
            <SortControl
              options={this.sortOptions()}
              isShowMarketPlaceFilter={showMarketPlaceFilter}
            />
          )}
          {showMarketPlaceFilter && (
            <ul className="tab-product-source-filter selects-row product-source-row grid">
              {this.renderProductSourceFilter()}
            </ul>
          )}

          {(!showOptions || isStickyFilterVariant) && (
            <AppliedFilters
              onFilterClick={this.onAvailableFilterClick}
              showPromotionAsApplied={showOfferAsDropdown}
            />
          )}
        </div>
      </div>
    );
  }
}
