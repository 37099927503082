import React from 'react';
import PropTypes from 'prop-types';
import DefaultLayout from '#/components/layouts/default';
import { connect } from '#/lib/render/connect-deep-compare';
import { getIsAmendBasket, getItems } from '#/selectors/trolley';
import { getQuantity } from '#/selectors/item';

const mapStateToProps = state => ({
  isAmendBasket: getIsAmendBasket(state),
  items: getItems(state)
});

@connect(mapStateToProps)
export default class TrolleyLayoutSPA extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    isAmendBasket: PropTypes.bool.isRequired,
    items: PropTypes.array.isRequired
  };

  render() {
    const items = this.props.items.filter(getQuantity);
    const showFooter = !!items.length;

    const customClassName = 'trolley-page';

    return (
      <DefaultLayout
        {...this.props}
        children={this.props.children}
        showMiniTrolley={false}
        showFooter={showFooter}
        simpleFooter={true}
        customClassName={customClassName}
      />
    );
  }
}
