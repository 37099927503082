export const sortByDepartment = items => {
  let departmentNames = [];
  // get the unique departmentNames
  items.forEach(item => {
    departmentNames.indexOf(item.product.departmentName) === -1 &&
      departmentNames.push(item.product.departmentName);
  });
  // group items in an object by department name
  const itemsPerDepartmentName = departmentNames.reduce(
    (obj, departmentName) => {
      obj[departmentName] = [];
      return obj;
    },
    {}
  );
  // sort items alphabetically by department
  items.forEach(item => {
    itemsPerDepartmentName[item.product.departmentName].push(item);
  });
  departmentNames.forEach(departmentName => {
    itemsPerDepartmentName[departmentName] = itemsPerDepartmentName[
      departmentName
    ].sort((a, b) => a.product.title.localeCompare(b.product.title));
  });
  // return items, grouped by department names sorted alphabetically
  return Object.values(itemsPerDepartmentName)
    .sort(([a], [b]) =>
      a.product.departmentName.localeCompare(b.product.departmentName)
    )
    .flat();
};
