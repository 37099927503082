import { DELIVERY as HOME_DELIVERY, ON_DEMAND } from '#/constants/shopping-methods';
import { ComponentType } from '#/analytics/bertie/types';
import { RECOMMENDATION_CAROUSEL_TYPE } from '#/constants/panel-types';

export const CONTENT_INTERACT_OP = 'contentInteractOp';
export const UI_EXPERIMENT_RENDERED = 'UIExperimentRendered';
export const UI_EVENT_BASIC_EVENT = 'UIEventBasicEvent';

//  UIEventBasicEvent analytics types
export const SIDEBAR = 'sidebar';
export const LHN = 'lhn';
export const CONTEXT_CARD = 'context card';
export const CHECKOUT = 'checkout';
export const BASKET_LOGO = 'basket logo';
export const PLP = 'plp';
export const SLOTS = 'slots';
export const TREX = 'trex';
export const APP_DOWNLOAD = 'app download';
export const TOP_NAV = 'top nav';
export const NAVIGATION = 'navigation';
export const SUPERDEPARTMENT = 'superDepartment';
export const SUPERDEPARTMENTS = 'superDepartments';
export const REGISTRATION = 'registration';
export const PDP_REVIEWS = 'reviews pdp';
export const VIEW_AND_BUY = 'view and buy';
export const MY_ORDERS = 'my orders';
export const ADD_ALL_PRODUCTS = 'addAllProducts';
export const CONTEXT_CARD_IMPRESSION = 'context card impression';
export const ONDEMAND_EVENT_TYPE = 'express delivery';
export const ADHOC_BANNER = 'adhoc banner';
export const BASKET_SEARCH = 'basket search';
export const BASKET_SEARCH_BAR = 'search bar';
export const FAVOURITES_ICON = 'favourites icon';
export const SHOP_FAVOURITES = 'shop favourites';
export const ADD_USUALS = 'add usuals';
export const SHOP_USUALS = 'shop usuals';
export const SHOP_PREVIOUSLY_BOUGHT = 'shop previously bought';
export const SIDE_BASKET = 'side basket';

//WISMO Analytics types
export const WISMO_EVENT_TYPE = {
  [HOME_DELIVERY]: 'wismo',
  [ON_DEMAND]: 'ondemand wismo',
};

//  basketOp analytics tile identifiers
export const PAGE_IDENTIFIER = 'Page';
export const WNT_IDENTIFIER = 'CMD:WNT';
export const TREX_IDENTIFIER = 'recommender:trex';
export const SUB_IDENTIFIER = 'RCMD:SUB';
export const CAROUSEL_FAVORITES_IDENTIFIER = 'carousel:favourites';
export const MISSING_IDENTIFIER = 'NA';
export const NOTIFICATION_BANNER = 'notification banner';
export const ADD_PRODUCT = 'addProduct';
export const REMOVE_PRODUCT = 'removeProduct';

//  UIEventBasicEvent analytics type values
export const BOOK_A_SLOT = 'book a slot';
export const CHANGE_SLOT = 'change slot';
export const BOOK_A_CC_SLOT = 'book a click+collect slot';
export const BOOK_ANOTHER_SLOT = 'book another slot';
const UNAVAILABLE_MODAL_ANALYTICS_KEY = 'unavailable modal';
export const MODAL_ANALYTICS_IMPRESSION = `${UNAVAILABLE_MODAL_ANALYTICS_KEY}:impression`;
export const MODAL_ANALYTICS_CLOSE = `${UNAVAILABLE_MODAL_ANALYTICS_KEY}:close`;
export const MODAL_ANALYTICS_BOOK_HD = `${UNAVAILABLE_MODAL_ANALYTICS_KEY}:book home delivery`;
export const MODAL_ANALYTICS_BOOK_CC = `${UNAVAILABLE_MODAL_ANALYTICS_KEY}:book click+collect`;
export const VIEW_BASKET = 'view basket';
export const CONTINUE_SHOPPING = 'continue shopping';
export const START_SHOPPING = 'start shopping';
export const SHOP_FAVORITES = 'shop favourites';
export const BACK_TO_TOP = 'back to top';
export const BURGER_MENU = 'burger menu';
export const CAROUSEL_RIGHT_ARROW = 'right arrow';
export const SHOW_MORE_REVIEWS = 'show 10 more';
export const PRODUCT_TILE = 'product tile';
export const OFFERS_HOPOS = 'offersHopos';
export const HOPOS = 'hopos';
export const HP_NOTIFICATION_EVT_VALUE = 'hp link';
export const OFFERS_PDP = 'offersPDP';
export const REQUIRE_INVOICE = 'require invoice';
export const WITHOUT_INVOICE = 'without invoice';
export const USE_INVOICE_DETAIL = 'use invoice detail';
export const MAKE_CHANGES = 'make changes';
export const WEIGHT_LIMIT = 'weight limit';
export const SIZE_LIMIT = 'size limit';
export const TRACK_DELIVERY = 'track delivery';
export const TRY_CLICK_AND_COLLECT = 'try click and collect';
export const DONATE_TO_UKRAINE = 'donate to ukraine';
export const CANCEL = 'cancelled';
export const PICKER_NOTE = 'picker note';
export const SUBSTITUTION = 'substitution';
export const PRODUCT_TILE_OPT_IN = 'product tile:opt in';
export const PRODUCT_TILE_OPT_OUT = 'product tile:opt out';

export const PRODUCT_SUBSTITUTION_OPT_IN = 'opt in:substitution';
export const PRODUCT_SUBSTITUTION_OPT_OUT = 'opt out:substitution';

export const REVIEW_BASKET = 'review basket';
export const STAR_RATING = 'star rating';
export const BROWSE_THE_STORE = 'browse the store';
export const TROLLEY = 'trolley';
export const INSPIRE_ME = 'inspire me';
export const INSPIRATION_AND_EVENTS = 'Inspiration & Events';

export const ALL_DEPARTMENTS = 'all departments';

// WISMO Event values for UIEventBasicEvent
export const AMEND_CUT_OFF = 'amend cut off';
export const ACTIVE = 'active';
export const ACTIVE_LATE = 'active:late';
export const ACTIVE_NEXT_STOP = 'active:next stop';
export const ACTIVE_ARRIVED = 'active:arrived';
export const ACTIVE_ARRIVED_EARLY = 'active:arrived early';
export const ACTIVE_DELIVERED = 'active:delivered';
export const NOT_AVAILABLE = 'unavailable';
export const CANCELLED = 'cancelled order modal:impression';
export const NOT_AVAILABLE_ERROR = 'updates unavailable, check back later';

//  UIEventBasicEvent analytics action
export const DELAY = 'delay';
export const NOW = 'now';
export const CLOSE = 'close';

export const COLLECT = 'collect';
export const SLOT_GRID = 'slot grid';

//  DCS Content types for UIContentRender and CONTENT_INTERACT_OP
export const CONTENT_TYPE_STATIC = 'static';
export const CONTENT_TYPE_AUTOPLAY_VIDEO = 'auto-play video';
export const CONTENT_TYPE_VIDEO = 'video';

//  CONTENT_INTERACT_OP interaction types
export const PLAY = 'play';
export const PAUSE = 'pause';
export const FULLSCREEN = 'fullscreen';
export const MILE_1 = 'mile-1';
export const MILE_2 = 'mile-2';
export const MILE_3 = 'mile-3';
export const COMPLETE = 'complete';
export const CLICK = 'click';
export const GHS = 'ghs';

export const DELIVERY = 'delivery';
export const MORE_INFO = 'more info';
export const CHANGE_CONTACT_DETAILS = 'change contact details';
export const OUTSIDE_DELIVERY = 'address not covered by delivery method';
export const BASKET = 'basket';
export const BASKET_LIMIT_EXCEEDED = 'basket limit exceeded';

//  UIEventBasket analytics type values
export const BASKET_LIMIT_BREACH = 'basket limit breach';
export const BASKET_WITHIN_LIMIT = 'within limit';

//onDemand availability statues for UIEventBasicEvent
export const ONDEMAND_DELIVERY = 'ondemand';
export const ONDEMAND_DELIVERY_AVAILABLE = 'eligible:available';
export const ONDEMAND_DELIVERY_UNAVAILABLE = 'eligible:unavailable';

// Slot analytics type values
export const IMPRESSED_SLOT_OP = 'impressedSlotOp';

export const COMPONENT_TYPES: Record<ComponentType | string, string> = {
  t: RECOMMENDATION_CAROUSEL_TYPE,
  c: 'content',
  s: 'standard',
  p: 'personalised',
  d: 'sponsored',
  r: 'pTrex',
};
export const ALDI_PRICE_MATCH = 'aldi price match';
export const LOW_EVERYDAY_PRICE = 'low everyday pricing';
export const RELATED_SEARCH = 'related search';
export const FAVORITES = 'favourites';

export const ACCORDION_EXPAND = 'accordion expand';
export const ACCORDION_COLLAPSE = 'accordion collapse';
export const CHANGE_COLLECTION_POINT = 'change collection point';
export const BACK_TO_THE_TOP = 'back to the top';

// Offer sash analytics values
export const SPONSORED = 'spons';
export const WHYNOTTRY = 'wnt';
export const NEW = 'new';
export const CHANGE_DELIVERY_POINT = 'change delivery details';
export const DROPDOWN = 'drop down';

export const EDIT_ADDRESS_VALUE = 'edit details';
export const ADD_ADDRESS_VALUE = 'add a new address';

// UIEventBasket basket status
export const GROCERY_BASKET = 'ghs only';
export const MARKETPLACE_BASKET = 'marketplace only';
export const MIXED_BASKET = 'ghs and marketplace';
//  Best value badge analytics
export const HIGHLIGHT_SLOT = 'highlight slot';
export const HIGHLIGHT_SLOT_IMPRESSION = 'highlight slot:impression';
export const BEST_VALUE = 'best value';
export const ACTIVE_BANNER_IMPRESSION = 'active plan banner:impression';

export const STAR_RATING_TYPE = 'feedback';

export const QUICK_BASKET_ANALYTICS_TYPE = 'quick basket modal';
export const QUICK_BASKET_ADD_REGULARS = 'add regulars';
export const QUICK_BASKET_IMPRESSION = 'impression';
export const QUICK_BASKET_SKIP = 'skip';
export const QUICK_BASKET_CLOSE = 'close';

export const ONE_TILE_CURRENCY = 'GBP';
export const ONE_TILE_LOCALE = 'en-GB';

export const QUICK_BASKET_COMPONENT_NAME = 'quick basket';
export const QUICK_BASKET_COMPONENT_TYPE = '';
export const QUICK_BASKET_DISPLAY_AREA = 'grd';
export const QUICK_BASKET_PAGE_TYPE = 'sl';
export const QUICK_BASKET_ADD_SELECTED = 'addSelected';

export const MARKETPLACE_ONBOARDING = 'marketplace onboarding';
export const MARKETPLACE_ONBOARDING_IMPRESSION = 'marketplace onboarding:impression';
export const GOT_IT = 'got it';
export const FIND_OUT_MORE = 'find out more';
export const BASKET_ADD = 'basket add';
