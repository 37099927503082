import { Component } from 'react';
import PropTypes from 'prop-types';
import AnalyticsBus from '#/analytics/analyticsBus';
import { connect } from '#/lib/render/connect-deep-compare';
import {
  getAppName,
  getAppRegion,
  getBuildVersion,
  getIsCurrentPage
} from '#/reducers/app';
import { getOrderStoreId } from '#/reducers/order';
import { getUserStoreId, getUserLocationUuid } from '#/reducers/user';

const mapStateToProps = state => {
  const isOnOrderConfirmationPage = getIsCurrentPage(state, [
    '/checkout/confirmation'
  ]);
  const userStoreId = isOnOrderConfirmationPage
    ? getOrderStoreId(state)
    : getUserStoreId(state);

  return {
    appName: getAppName(state),
    appRegion: getAppRegion(state),
    buildVersion: getBuildVersion(state),
    userStoreId,
    userLocationUUID: getUserLocationUuid(state)
  };
};

@connect(mapStateToProps)
export default class AnalyticsSiteEvent extends Component {
  static propTypes = {
    appName: PropTypes.string.isRequired,
    appRegion: PropTypes.string.isRequired,
    buildVersion: PropTypes.string.isRequired,
    userStoreId: PropTypes.string,
    userLocationUUID: PropTypes.string
  };

  static defaultProps = {
    userStoreId: '',
    userLocationUUID: ''
  };

  componentDidMount() {
    this.firePageLoadedEvent();
  }

  firePageLoadedEvent() {
    const {
      appName,
      appRegion,
      buildVersion,
      userStoreId,
      userLocationUUID
    } = this.props;

    AnalyticsBus().emit('siteData', {
      application: appName,
      buildVersion,
      country: appRegion,
      storeId: userStoreId,
      locationId: userLocationUUID
    });
  }

  render() {
    return null;
  }
}
