import React from 'react';
import PropTypes from 'prop-types';
import { WidgetContainer } from '../widget-container';
import CMSAnalytics from '../helpers/cms-analytics';
import { HomeWidget } from '../home-widget';
import Link from '../../link-check-spa';

const Banner = props => {
  const { background, height, link, placement } = props;

  const title = props.title && props.title.trim();
  const subtitle = props.subtitle && props.subtitle.trim();

  const textContent = (title || subtitle) && (
    <div className="ui-banner-widget__content">
      <div className="ui-banner-widget__title-wrapper">
        {title && <h3 className="ui-banner-widget__title">{title}</h3>}
        {subtitle && <p className="ui-banner-widget__subtitle">{subtitle}</p>}
      </div>
    </div>
  );

  return (
    <CMSAnalytics data={props}>
      <WidgetContainer className="ui-banner-widget" widgetName="banner">
        <Link to={link.href}>
          <HomeWidget
            backgroundImageUrl={background && background.src}
            cropDirection={background ? background.clipping : null}
            height={height}
            widgetName="banner"
            breakpoints={background.breakpoints}
            maxWidth={background.maxWidth}
            uid={placement}
          >
            {textContent}
          </HomeWidget>
        </Link>
      </WidgetContainer>
    </CMSAnalytics>
  );
};

Banner.propTypes = {
  background: PropTypes.object,
  height: PropTypes.string,
  link: PropTypes.object,
  placement: PropTypes.number,
  subtitle: PropTypes.string,
  title: PropTypes.string
};

export { Banner };
