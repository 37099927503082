import analyticsBus from '#/analytics/analyticsBus';
import FormSubmit from '#/components/form-submit';
import { PAY_AT_DOOR_CODE } from '#/constants/payment';
import helpers from '#/lib/decorators/helpers';
import React, { useCallback } from 'react';

export type OwnProps = {
  variant: string;
  confirmationUrl: string;
  text: string;
  t: (key: string) => string;
};

const PayAtDoorButton: React.FC<OwnProps> = (props: OwnProps) => {
  const { confirmationUrl, text, variant, t: translate } = props;

  const onSubmitPayAtDoorForm = useCallback(() => {
    analyticsBus().emit('UIEventBasicEvent', {
      action: 'delay',
      type: 'order summary',
      value: 'pay at door',
    });
  }, []);

  return (
    <FormSubmit
      buttonText={text}
      buttonAutoTag="pay-at-door--submit"
      submitUrl={confirmationUrl}
      onFormSubmit={onSubmitPayAtDoorForm}
      variant={variant}
      spinnerText={translate('orders:orders-ddl.please-wait')}
    >
      <input type="hidden" name="paymentMethod" value={PAY_AT_DOOR_CODE} />
    </FormSubmit>
  );
};

export default helpers(['t'])(PayAtDoorButton);
