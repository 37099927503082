import React from 'react';
import helpers from '#/lib/decorators/helpers';
import { ConnectedProps } from 'react-redux';
import { TConfigFunc, TTranslateFunc } from '#/lib/records/helpers.defs';
import { connect } from '#/lib/render/connect-deep-compare';
import { csatConfigType, feedbackConfigType, MapStateToPropsRetType } from './index.defs';
import { getPageNo } from '#/reducers/results';
import { getAppRegion } from '#/reducers/app';
import { FAVORITES, USUALS } from '#/constants/tile-types';
import { LAST_ORDER_TAB } from '#/constants/favorites';
import { STAR, TEXT } from '#/constants/feedback-types';
import InteractiveStarRating from '#/components/star-rating/interactive-star-rating';
import TextFeedback from '#/components/feedback/text-feedback';
import { getCSATConfig } from '#/selectors/experiments';

type OwnProps = {
  tileType: string;
  shouldShowInteractiveStarRating: boolean;
  c: TConfigFunc;
  t: TTranslateFunc;
};

const mapStateToProps = (state: Store, { tileType }): MapStateToPropsRetType => ({
  pageNo: getPageNo(state),
  region: getAppRegion(state),
  csatConfig: getCSATConfig(state, tileType),
});

const connector = connect(mapStateToProps);
type TProps = OwnProps & MapStateToPropsRetType & ConnectedProps<typeof connector>;

const Feedback = (props: TProps): JSX.Element | null => {
  const { c: config, t: translate, pageNo, shouldShowInteractiveStarRating, tileType, region, csatConfig } = props;
  const legendTextPrevBought = translate('favorites:favorites-feedback-ratings-label');
  const legendTextUsuals = translate('favorites:favorites-feedback-ratings-label-usuals');
  const legendTextLastOrder = translate('favorites:favorites-feedback-ratings-label-lastorder');
  const ratingAriaLabelPrevBought = translate('favorites:favorites-feedback-ratings-aria-label');
  const ratingAriaLabelUsuals = translate('favorites:favorites-feedback-ratings-aria-label-usuals');
  const ratingAriaLabelLastOrder = translate('favorites:favorites-feedback-ratings-aria-label-lastorder');
  const ratingAriaLabel = `${csatConfig?.title}${translate('favorites:feedback-ratings-aria-suffix')}`;
  const refererUrlStarRatingPrevBought = config('opinionLab:refererUrls:favorites') as string;
  const refererUrlStarRatingUsuals = config('opinionLab:refererUrls:favoritesUsuals') as string;
  const refererUrlStarRatingLastOrder = config('opinionLab:refererUrls:favoritesLastOrder') as string;
  const refererUrlTextFeedback = config('opinionLab:refererUrls:textFeedback') as string;
  const feedbackConfig = {
    refererUrlTextFeedback,
    ratingAriaLabel,
    isFavoritesPage: true,
  };

  const renderFeedbackComponent = (
    csatConfig: csatConfigType | undefined,
    renderFeedbackConfig: feedbackConfigType,
  ): JSX.Element | null => {
    switch (csatConfig?.type) {
      case STAR:
        return (
          <InteractiveStarRating
            isFavoritesPage={renderFeedbackConfig.isFavoritesPage}
            legendAriaLabel={renderFeedbackConfig.ratingAriaLabel}
            legendText={csatConfig.title}
            refererUrl={renderFeedbackConfig.refererUrl}
          />
        );
      case TEXT:
        return <TextFeedback csatConfig={csatConfig} refererUrl={renderFeedbackConfig.refererUrlTextFeedback} />;
      default:
        return null;
    }
  };

  switch (tileType) {
    case FAVORITES:
      if ((pageNo === 1 || shouldShowInteractiveStarRating) && region === 'UK') {
        return renderFeedbackComponent(
          { title: legendTextPrevBought, type: STAR },
          { ...feedbackConfig, ratingAriaLabel: ratingAriaLabelPrevBought, refererUrl: refererUrlStarRatingPrevBought },
        );
      }
      return null;
    case USUALS:
      return renderFeedbackComponent(
        { title: legendTextUsuals, type: STAR },
        { ...feedbackConfig, ratingAriaLabel: ratingAriaLabelUsuals, refererUrl: refererUrlStarRatingUsuals },
      );
    case LAST_ORDER_TAB:
      return renderFeedbackComponent(
        { title: legendTextLastOrder, type: STAR },
        { ...feedbackConfig, ratingAriaLabel: ratingAriaLabelLastOrder, refererUrl: refererUrlStarRatingLastOrder },
      );
    default:
      return null;
  }
};

export default helpers(['c', 't'])(connector(Feedback));
