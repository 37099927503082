import { recursive as recursiveMerge } from 'merge';
import { DEFAULT_CONSUMER } from '../constants/config';
import { isObject, isUndefined } from '../utils/misc';

function removeIfNull(obj, key, removeFunc) {
  if (obj[key] === null) {
    return removeFunc();
  } else if (typeof obj[key] === 'object') {
    return removeNulls(obj[key]);
  }
}

function removeNulls(obj) {
  if (Array.isArray(obj)) {
    let i = obj.length;

    while (i--) {
      removeIfNull(obj, i, () => obj.splice(i, 1));
    }
  } else {
    for (const k in obj) {
      removeIfNull(obj, k, () => {
        const v = obj[k];

        delete obj[k];

        return v;
      });
    }
  }

  return obj;
}

function scan(keys) {
  const keysClone = keys.slice();

  for (let c = 1, l = keysClone.length; c < l; c++) {
    const suffix = keys.slice(1, c + 1).join('_');

    keysClone[c] = `${keys[0]}_${suffix}`;
  }

  return keysClone;
}

export function getRegionConsumerConfig(config, region, consumer, key) {
  // This function is designed to fetch the value associated with the key from the consumer config (if available), or
  // the default config. If they are both plain JS objects, we will recursively merge these values before returning the
  // value

  const keyString = key ? `:${key}` : '';

  let consumerConfigValue;

  // Fetch a consumerConfigValue only if the consumer is not default (otherwise we will do a duplicate fetch below)
  if (consumer !== DEFAULT_CONSUMER) {
    consumerConfigValue = config.get(`SITES:${region}:${consumer}${keyString}`);
  }

  // If this value 'exists' (all falsey values except `undefined` are valid), and is not an object (therefore no reason
  // to recursively merge), then we can return that value straight back
  if (consumerConfigValue !== undefined && !isObject(consumerConfigValue))
    return consumerConfigValue;

  // Fetch the defaultConfigValue value
  const defaultConfigValue = config.get(
    `SITES:${region}:${DEFAULT_CONSUMER}${keyString}`
  );

  // If defaultConfigValue not defined, we can return the consumerConfigValue, regardless of its value
  if (defaultConfigValue === undefined) return consumerConfigValue;

  // If consumerConfigValue not defined, we can now return the defaultConfigValue, regardless of its value
  if (consumerConfigValue === undefined) return defaultConfigValue;

  // We now already know that `consumerConfigValue` is defined AND an object, so we can check to see if
  // `defaultConfigValue` is too, and merge these values to return
  if (isObject(defaultConfigValue)) {
    return recursiveMerge(true, defaultConfigValue, consumerConfigValue);
  }

  // Return `consumerConfigValue` if no other value has been returned (use case - where `consumerConfigValue` is an
  // object but `defaultConfigValue` isn't...)
  return consumerConfigValue;
}

export function findInConfig(key, region, consumer, configGetter) {
  let value;

  // Get consumer specific value (and return if it exists)
  if (consumer) value = configGetter(`SITES:${region}:${consumer}:${key}`);

  if (value !== undefined) return value;

  // Get the default config value for region
  value = configGetter(`SITES:${region}:${DEFAULT_CONSUMER}:${key}`);

  if (value === undefined) {
    // Return key from main global config if not found in regional config
    return configGetter(key);
  }

  return value;
}

export function cleanObject(obj) {
  const newObj = Array.isArray(obj) ? [] : {};

  return removeNulls(Object.assign(newObj, obj));
}

export function getRegionalKeys(key, options, region) {
  options = isObject(options) ? options : {};
  key = key.toLowerCase();

  if (!/.*\:.*/.test(key)) {
    key = `common:${key}`;
  }

  const keys = [key, options.context, region].filter(data => !!data);

  return scan(keys).reverse();
}

export function replaceEnvValues(data) {
  const mangoApiEnvVar = process.env.MANGOAPI;

  if (isUndefined(mangoApiEnvVar)) {
    return data;
  }

  const mangoApiValue = `${mangoApiEnvVar || 'master'}.`;

  return JSON.parse(
    JSON.stringify(data).replace(/<<mango-api-prefix>>/g, mangoApiValue)
  );
}
