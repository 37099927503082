import moment from 'moment-timezone';
import { getLocalDate } from '#/lib/slot/slot-utils';

export type IConfig = (value: string) => string | boolean;

const configFormat = (minutes: number, config?: IConfig): string | undefined => {
  const showCustomTimeFormat = config?.('showCustomTimeFormat') as boolean;
  if (showCustomTimeFormat && config) {
    const shortTimeFormat = config('shortTimeFormat') as string;
    const longTimeFormat = config('longTimeFormat') as string;
    return minutes ? longTimeFormat : shortTimeFormat;
  }
  return undefined;
};

export const formatTimeFromConfig = (
  momentTime: moment.Moment,
  timezone: string,
  language: string,
  config?: IConfig,
): string => {
  const localDate = getLocalDate(momentTime, timezone, language);
  const formatFromConfig = configFormat(localDate.minutes(), config);
  const format = formatFromConfig || 'HH:mm';

  return localDate.format(format);
};

export const formattedSlotTime = (
  startMoment: string | moment.Moment,
  endMoment: string | moment.Moment,
  timezone: string,
  language: string,
  config?: IConfig,
): string => {
  const startMomentObject = startMoment instanceof moment ? (startMoment as moment.Moment) : moment(startMoment);
  const endMomentObject = startMoment instanceof moment ? (endMoment as moment.Moment) : moment(endMoment);

  const startTimeStr = formatTimeFromConfig(startMomentObject, timezone, language, config);
  const endTimeStr = formatTimeFromConfig(endMomentObject, timezone, language, config);
  return `${startTimeStr} - ${endTimeStr}`;
};

export const formattedDateTimeFromConfig = (
  momentTime: string | moment.Moment | null,
  timezone: string,
  language: string,
  config?: IConfig,
): string => {
  const momentObject = momentTime instanceof moment ? (momentTime as moment.Moment) : moment(momentTime);
  const localDate = getLocalDate(momentObject, timezone, language);
  const formatFromConfig = configFormat(localDate.minutes(), config);
  const format = `dddd, LL ${formatFromConfig || 'HH:mm'}`;

  return localDate.format(format);
};
