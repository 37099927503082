import React from 'react';
import PropTypes from 'prop-types';

const InfoPanel = ({ mainTitle, mainDescription, subDescription }) =>
  mainTitle || mainDescription || subDescription ? (
    <div className="ui-responsive-slider--info">
      <h3 className="ui-responsive-slider--info-title">{mainTitle}</h3>
      <p className="ui-responsive-slider--info-main">{mainDescription}</p>
      <p className="ui-responsive-slider--info-sub">{subDescription}</p>
    </div>
  ) : (
    <noscript />
  );
// returned <noscript /> due to limitation of React 0.14 - fixed in React 15
// https://github.com/facebook/react/issues/5355

InfoPanel.propTypes = {
  mainDescription: PropTypes.string,
  mainTitle: PropTypes.string,
  subDescription: PropTypes.string
};

export { InfoPanel };
