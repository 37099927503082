import React from 'react';

import { StyledDL, StyledDT } from './styled';

type Props = {
  basketMaxItemCountLimit: number;
  items: number;
  t: (key: string, options?: Record<string, unknown>) => string;
};

const ItemsInBasketMessage: React.FC<Props> = ({ basketMaxItemCountLimit, items, t }) => (
  <StyledDL>
    <StyledDT>
      <div>{`${t('checkout:order-summary.items-in-trolley')}`}</div>

      {basketMaxItemCountLimit > 0 && (
        <div>
          {`${t('checkout:order-summary.items-in-trolley-maximum', {
            limit: basketMaxItemCountLimit,
          })}`}
        </div>
      )}
    </StyledDT>

    <dd>{items}</dd>
  </StyledDL>
);

export default ItemsInBasketMessage;
