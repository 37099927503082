import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ResponsiveBackground } from '../responsive-background';
import Link from '../../link-check-spa';

const BasicTradeTile = props => {
  const {
    background,
    body,
    componentClickHandler,
    fullWidthTablet,
    hideIcon,
    hoverEffect,
    placement,
    priority,
    selected,
    title,
    link
  } = props;

  const stampClasses = classnames(
    'trade-tile-type-basic',
    [priority ? `trade-tile_${priority}` : null],
    { tile__has_body: body },
    { tile__has_no_body: !body },
    { tile_full_width: fullWidthTablet },
    { 'trade-tile-selected': selected },
    { 'trade-tile-hover-effect': hoverEffect },
    { 'trade-tile-no-img': !background && !background.src }
  );
  const onClick = () => componentClickHandler?.(props);

  return (
    <div className={stampClasses} onClick={onClick}>
      <Link
        className="trade-tile-type-basic_link"
        to={link && link.href ? link.href : null}
      >
        {background && background.src ? (
          <span className="trade-tile-type-basic_img">
            <ResponsiveBackground
              className="trade-tile-bg"
              url={background && background.src}
              breakpoints={background && background.breakpoints}
              clipping={background && background.clipping}
              maxWidth={background.maxWidth}
              uid={placement}
            />
          </span>
        ) : null}

        <span className="trade-tile-type-basic_copy">
          {title ? (
            <h2 className="trade-tile-type-basic_title">{title}</h2>
          ) : null}
          {body ? (
            <p className="trade-tile-type-basic_description">{body}</p>
          ) : null}
          {link && link.label ? (
            <span className="trade-tile-type-basic_cta">
              <span className="cta-text">
                {link.label}
                {!hideIcon ? (
                  <span
                    className={classnames(
                      'cta-icon',
                      { 'icon-chevron_right': !link.newWindow },
                      { 'icon-link-blue': link.newWindow }
                    )}
                  />
                ) : null}
              </span>
            </span>
          ) : null}
        </span>
      </Link>
    </div>
  );
};

BasicTradeTile.propTypes = {
  background: PropTypes.shape({
    breakpoints: PropTypes.array,
    clipping: PropTypes.oneOf(['left', 'center', 'right']),
    maxWidth: PropTypes.number,
    src: PropTypes.string
  }),
  body: PropTypes.string,
  componentClickHandler: PropTypes.func,
  fullWidthTablet: PropTypes.bool,
  hideIcon: PropTypes.bool,
  hoverEffect: PropTypes.bool,
  link: PropTypes.shape({
    href: PropTypes.string,
    label: PropTypes.string,
    newWindow: PropTypes.bool
  }),
  placement: PropTypes.number,
  priority: PropTypes.string,
  selected: PropTypes.bool,
  title: PropTypes.string
};

export { BasicTradeTile };
