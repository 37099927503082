import React from 'react';
import { compose } from 'react-recompose';
import RadioButtonGroup from '@ddsweb/radio-button-group';
import helpers from '#/lib/decorators/helpers';
import { SLOT_FOR_EIGHT_HOURS, SLOT_FOR_ONE_HOUR, SLOT_FOR_TWO_HOURS } from '#/constants/slot-group';
import { StyledSafeForm } from '../styled';
import { SlotGroup } from '#/custom-typings/redux-store/slot.defs';

const getLabel = (
  group: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  translate: (...args: any[]) => string,
): {
  descriptionText: string;
  labelText: string;
} => {
  let labelText = translate('slots:common.flexi-saver-slot');
  let descriptionText = translate('slots:common.flexi-saver-slot-info');

  if (group === SLOT_FOR_ONE_HOUR || group === SLOT_FOR_TWO_HOURS) {
    labelText = translate('slots:common.fixed-hour-slot', { hour: group });
    descriptionText = translate('slots:common.fixed-hour-slot-info', {
      hour: group,
    });
  } else if (group === SLOT_FOR_EIGHT_HOURS) {
    labelText = translate('slots:common.priority-slot');
    descriptionText = translate('slots:common.priority-slot-info');
  }

  return {
    labelText,
    descriptionText,
  };
};

type OwnProps = {
  onChangeSlotGroup: (slotGroup: SlotGroup) => void;
  currentSlotGroup: number;
  slotGroups: number[];
  isSlotUIReskinV2Variant: boolean;
};

export type HelperProps = {
  t: (key: string, options?: object) => string;
};

export type Props = OwnProps & HelperProps;

export const GroupSelector: React.FC<Props> = ({
  onChangeSlotGroup,
  currentSlotGroup,
  slotGroups,
  isSlotUIReskinV2Variant,
  t: translate,
}) => {
  const slotGroupChangeHandler = (event: React.FormEvent<HTMLInputElement>): void => {
    const slotGroup = Number(event.currentTarget.value) as SlotGroup;
    onChangeSlotGroup(slotGroup);
  };

  const slotGroupRadioButtons = slotGroups.map((group: number) => {
    const { descriptionText, labelText } = getLabel(group, translate);
    return {
      labelText,
      descriptionText: descriptionText,
      value: group,
      id: `slotGroup_${group}`,
      radioProps: { size: isSlotUIReskinV2Variant ? 'sm' : 'md' },
    };
  });

  return (
    <StyledSafeForm autoComplete="off" isSlotUIReskinV2Variant={isSlotUIReskinV2Variant}>
      <RadioButtonGroup
        legendText={translate('slots:common.choose-a-slot-type')}
        name="slotTypeSelector"
        checkedValue={Number(currentSlotGroup)}
        radioButtons={slotGroupRadioButtons}
        onChange={slotGroupChangeHandler}
        required
      />
    </StyledSafeForm>
  );
};

const enhance = compose<Props, OwnProps>(helpers(['t']));

export default enhance(GroupSelector);
