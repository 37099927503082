import { Request, Response } from 'express';
import { getFeatureConfigurationFromReqRes, updateRegionInFeatureKey } from '#/lib/optimizely-manager';
import {
  ExperimentConfig,
  SearchContentTileFeature,
  SearchContentTileVariants,
} from '#/experiments/oop-2021/constants';
import { Product } from '#/lib/records/item.defs';

export type ProductData = {
  productItems: Array<Product>;
};

export const getContentSearchWithReqRes = (req: Request, res: Response): ExperimentConfig => {
  return getFeatureConfigurationFromReqRes(
    req,
    res,
    updateRegionInFeatureKey(SearchContentTileFeature.featureKey, req.context.region),
    SearchContentTileFeature,
    SearchContentTileFeature.serviceConfig,
  );
};

export const getShouldFetchContentTile = ({ searchContentTile }: ExperimentConfig): boolean =>
  searchContentTile === SearchContentTileVariants.ENABLED;

export const getTrimmedText = (text: string, allowedChars: number): string => {
  if (text && text.trim().length > allowedChars) {
    return text.trim().substring(0, allowedChars);
  }

  return text?.trim();
};
