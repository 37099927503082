import React from 'react';
import PropTypes from 'prop-types';
import { StandaloneLink } from '@ddsweb/link';
import { css } from 'styled-components';
import InfoMessage from '#/components/products/info-message';
import Link from '#/components/link-check-spa';

const spaLink = ({ children, className, href, onClick }) => (
  <Link to={href} className={className} onClick={onClick}>
    {children}
  </Link>
);

const linkStyles = css`
  -webkit-font-smoothing: auto;

  .beans-link__icon {
    margin-left: 4px;

    svg {
      height: 11px;
      width: 10px;
      stroke-width: 2.8px;
    }
  }
`;

const renderLink = link => (
  <div className="product-info-message__link">
    <StandaloneLink
      emphasized
      spaLink={spaLink}
      className="product-info-message__link-button"
      href={link.href}
      icon={{ graphic: 'forwardLink', position: { global: 'right' } }}
      onClick={link.onClick}
      styles={linkStyles}
    >
      {link.text}
    </StandaloneLink>
  </div>
);

const renderMessages = messages =>
  messages.map((props, i) => (
    <li key={i}>
      <InfoMessage {...props} />
    </li>
  ));

const renderMessage = messages => <InfoMessage {...messages[0]} />;

const renderMessageList = messages =>
  messages.length > 1 ? (
    <ul>{renderMessages(messages)}</ul>
  ) : (
    renderMessage(messages)
  );

const InfoMessageList = ({ link, messages }) => (
  <div className="product-info-message-list">
    {renderMessageList(messages)}
    {link ? renderLink(link) : null}
  </div>
);

InfoMessageList.propTypes = {
  link: PropTypes.shape({
    href: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func
  }),
  messages: PropTypes.array.isRequired
};

export default InfoMessageList;
