export function calculateUnit(productDisplayType) {
  switch (productDisplayType) {
    case 'Quantity':
      return 'pcs';
    case 'Weight':
    case 'QuantityOrWeight':
      return 'kg';
  }
}

export const createEmptyResponse = (count = 0) => ({
  count,
  results: {
    productItems: [],
    pageInformation: {},
    facetLists: []
  }
});

export const getTranslationForQuantity = (translate, val) => {
  return val.match(/^[0-9]+$/)
    ? `${val} ${translate('product-tile:pcs')}`
    : val;
};
