import React from 'react';
import { DEFAULT_PAGE_NAME } from '#/constants/query-strings';
import NavigationButton from '#/experiments/oop-1824/components/navigation-button';
import helpers from '#/lib/decorators/helpers';
import { connect } from '#/lib/render/connect-deep-compare';
import { updateParamsInUrl } from '#/lib/url/url-utils';
import { getCurrentUrl } from '#/reducers/app';
import { getItemsPerPage } from '#/reducers/results';

type StateProps = {
  currentUrl: string;
  itemsPerPage: number;
};

type MultiPageResultSelectorProps = {
  currentPageNumber: number;
  currentUrl: string;
  itemsPerPage: number;
  spread: number;
  paginationLabels: {
    currentPageLabel: string;
    changePageLabel: string;
  };
  totalPages: number;
  pageQueryName: string;
  t: (key: string) => string;
  onPageChange?: (page: number, url: string) => void;
};

const mapStateToProps = (state: Store): StateProps => ({
  currentUrl: getCurrentUrl(state),
  itemsPerPage: getItemsPerPage(state),
});

const generateUrl = (
  currentUrl: string,
  pageNo: number,
  itemsPerPage: number,
  pageQueryName = DEFAULT_PAGE_NAME,
): string => {
  return updateParamsInUrl(currentUrl, { [pageQueryName]: pageNo });
};

const renderPageNumberDisplayMode = (
  currentPage: number,
  totalPages: number,
  translate: (key: string, options?: object) => string,
): React.ReactNode => {
  return (
    <span className="pagination--slim-page-selector">
      {translate('navigation:multi-search-navigation-count', {
        currentPage,
        totalPages,
      })}
    </span>
  );
};

const MultiPageResultSelector: React.FC<MultiPageResultSelectorProps> = props => {
  const {
    currentPageNumber: currentPage,
    currentUrl,
    itemsPerPage,
    spread = 2,
    onPageChange,
    paginationLabels,
    pageQueryName,
    totalPages: total,
    t: translate,
  } = props;
  const currentPageLabel = paginationLabels.currentPageLabel;
  const goToPageXLabel = paginationLabels.changePageLabel;
  const prevUrl = generateUrl(currentUrl, currentPage - 1, itemsPerPage, pageQueryName);
  const nextUrl = generateUrl(currentUrl, currentPage + 1, itemsPerPage, pageQueryName);
  let i = currentPage - spread;
  let length = currentPage + spread;

  if (i === 3) {
    //  prevent 1 ... 3 pagination
    i = 2;
  }

  if (length === total - 2) {
    //  prevent 8 ... 10 pagination
    length++;
  }

  const handlePageClick = (e: React.MouseEvent, currentPage: number, url: string): void => {
    e.nativeEvent.stopPropagation();

    if (typeof onPageChange === 'function') {
      e.preventDefault();
      onPageChange(currentPage, url);
    }
  };

  const handlePrevPageClick = (e: React.MouseEvent): void => {
    handlePageClick(e, currentPage - 1, prevUrl);
  };

  const handleNextPageClick = (e: React.MouseEvent): void => {
    handlePageClick(e, currentPage + 1, nextUrl);
  };

  return (
    <nav className="pagination--multi-page-selector-wrapper">
      <ul>
        {renderPageNumberDisplayMode(currentPage, total, translate)}
        <NavigationButton
          disabled={currentPage <= 1}
          href={prevUrl}
          icon="backwardLink"
          key="prev"
          label={translate(currentPageLabel)}
          onClickHandler={handlePrevPageClick}
        />
        <NavigationButton
          href={nextUrl}
          disabled={currentPage === total}
          icon="forwardLink"
          key="next"
          label={translate(goToPageXLabel)}
          onClickHandler={handleNextPageClick}
        />
      </ul>
    </nav>
  );
};

export default connect(mapStateToProps)(helpers(['c', 't'])(MultiPageResultSelector));
