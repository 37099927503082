import optimizely from '@optimizely/optimizely-sdk';
import { logExperiment as baseLogExperiment } from '@peas/apm';
import { handleResponse } from '../lib/requests/request-utils';
import fetch from '#/lib/requests/fetch-with-logging';
import OptimizelyWrapper from './optimizely-wrapper';
import { getOptimizelyData } from '#/reducers/experiments';
import { getAtrc, getUserHashedUId, getUserUuid } from '#/reducers/user';
import { getAppRegion } from '#/reducers/app';
import analyticsBus from '#/analytics/analyticsBus';
import { UI_EXPERIMENT_RENDERED } from '#/analytics/constants';
import {
  getAttributesFromReqAndRes,
  getAttributesFromState
} from '#/lib/optimizely-attributes';
import { getUniqueIdForStateOrProps } from '#/lib/optimizely-attributes-helpers';
import {
  experimentsDefaultData,
  featureVariableDataType
} from '#/constants/experiments';
import { getUserUniqueId } from '#/utils/user-utils';

const instances = new Map();

const clearActiveTestData = () => {
  // this object is for informational purpose. Optimizely doesn't depend on it
  window.optimizelyData = [];
};

const addToActiveTestData = item => {
  window.optimizelyData.push(item);

  dispatchAnalytics(item);
};

const dispatchAnalytics = ({ experiment, variation }) => {
  const payload = {
    experimentName: experiment,
    variationKey: variation
  };

  analyticsBus().emit(UI_EXPERIMENT_RENDERED, payload);
};

const hasExperimentBeenAdded = (experiment, variation) => {
  return window.optimizelyData.some(
    item => item.experiment === experiment && item.variation === variation
  );
};

const generateInstanceKey = (revision, options) =>
  `${revision}, ${JSON.stringify(options)}`;

export const exposeActiveTestDataBatch = experiments => {
  if (!experiments) {
    return;
  }

  if (!Array.isArray(experiments)) {
    const keys = Object.keys(experiments);
    const keysLength = keys.length;

    for (let i = 0; i < keysLength; i++) {
      const key = keys[i];

      if (key === 'undefined' || key === 'null') {
        continue;
      }

      exposeActiveTestData({
        experiment: key,
        variation: experiments[key]
      });
    }
    return;
  }

  experiments.forEach(experiment => {
    if (experiment) {
      exposeActiveTestData(experiment);
    }
  });
};

export const exposeActiveTestData = ({
  experiment,
  variation,
  attributes,
  featureKeyOverride
}) => {
  if (!experiment || !variation || typeof window === 'undefined') {
    return;
  }

  const analyticsExperimentName = featureKeyOverride || experiment;

  if (!hasExperimentBeenAdded(analyticsExperimentName, variation)) {
    logExperiment(
      {
        experimentName: experiment,
        variant: variation
      },
      featureKeyOverride
    );

    addToActiveTestData({
      experiment: analyticsExperimentName,
      variation,
      attributes
    });
  }
};

function logExperiment(experimentAttributes, featureKeyOverride) {
  if (experimentAttributes?.variant == null) {
    return;
  }

  // if clientside and the experiment has been exposed for this route already don't run the code again
  if (
    process.env.CLIENT_SIDE &&
    hasExperimentBeenAdded(
      featureKeyOverride || experimentAttributes.experimentName,
      experimentAttributes.variant
    )
  ) {
    return;
  }

  baseLogExperiment(experimentAttributes);
}

export const fetchOptimizelyDataFile = async config => {
  const { url, timeout, host, traceId } = config;
  const request = {
    method: 'GET',
    timeout,
    headers: {
      'Content-Type': 'application/json',
      'User-Agent': traceId,
      apihost: host
    }
  };
  let response = null;

  response = await fetch(url, request).then(handleResponse);

  return response;
};

export const getInstanceFromState = (state, isFeatureToggle = false) => {
  const dataFile = getOptimizelyData(state);
  const enableDebug = state.ui.debugExperiments || false;
  const optimizelyEnabled = state.ui.optimizelyEnabled || isFeatureToggle;
  return getInstance(dataFile, { enableDebug, optimizelyEnabled });
};

export const getInstance = (dataFile = experimentsDefaultData, options) => {
  const { enableDebug, optimizelyEnabled } = options;

  const logger = {
    log: (LOG_LEVEL, LOG_MESSAGE) => {
      if (enableDebug) {
        console.log(`${LOG_MESSAGE}`); // eslint-disable-line no-console
      }
    }
  };

  const instanceKey = generateInstanceKey(dataFile.revision, options);
  let storedInstance = instances.get(instanceKey);

  if (!storedInstance) {
    storedInstance = new OptimizelyWrapper(
      optimizely,
      {
        datafile: JSON.stringify(dataFile),
        skipJSONValidation: true,
        logger
      },
      { optimizelyEnabled }
    );
    instances.set(instanceKey, storedInstance);
  }

  return storedInstance;
};

export const getVariationForExperiment = (
  state,
  experiment,
  attributes = {},
  useCustomerId = false
) => {
  const atrc = getAtrc(state);
  const hashedUId = getUserHashedUId(state) || null;
  const optimizelyInstance = getInstanceFromState(state);
  const region = getAppRegion(state);
  const userUniqueId = getUserUniqueId(region, getUserUuid(state), hashedUId);

  const args = {
    atrc,
    uniqueId: userUniqueId,
    useCustomerId
  };

  const uniqueId = getUniqueIdForStateOrProps(args);

  const optimizelyAttributes = getAttributesFromState(
    state,
    useCustomerId,
    attributes
  );

  const { variation } = optimizelyInstance.activate(
    experiment,
    uniqueId,
    optimizelyAttributes
  );

  if (variation) {
    // this fetches the features experiment key, we don't need to run this if there is no variation
    const experimentKey = optimizelyInstance.getFeatureVariableString(
      experiment,
      'experimentKey',
      uniqueId,
      optimizelyAttributes
    );
    const featureKeyOverride = experiment;

    // in this function the global optimizelyData object uses the feature key as the identifier
    // to cache the impression, other use cases use the experiment key
    // changing this would have an impact on the data emitted to analytics so overriding this use case only
    exposeActiveTestData({
      attributes: optimizelyAttributes,
      experiment: experimentKey,
      variation,
      featureKeyOverride
    });

    logExperiment(
      {
        experimentName: experimentKey,
        variant: variation
      },
      featureKeyOverride
    );
  }

  return variation;
};

if (typeof window !== 'undefined') {
  window.optimizelyData = [];
  analyticsBus().on('app:routeChanging', clearActiveTestData);
}

export const getVariationForExperimentWithoutState = (
  experiment,
  experimentsData,
  optimizelyAttributes,
  enableDebug = false,
  optimizelyEnabled = true
) => {
  const optimizelyInstance = getInstance(experimentsData, {
    enableDebug,
    optimizelyEnabled
  });
  return optimizelyInstance.activate(
    experiment,
    optimizelyAttributes.uniqueId,
    optimizelyAttributes
  );
};

/* @todo remove 'deviceType` from argument here.
  once webpack tree-shaking is improved
  this added here as parsing device-type from UA would have dependecies which we dont want in client bundle
  (case of json request where mustard.js has not run yet and req.device not present)
 */
export const getVariantForExperimentUsingRequest = (
  req,
  res,
  experiment,
  deviceType,
  useCustomerId = false,
  additionalAttributes = {}
) => {
  const { variation } = getVariationForExperimentWithoutState(
    experiment,
    res.locals.props.experiments,
    getAttributesFromReqAndRes(
      req,
      res,
      useCustomerId,
      deviceType,
      additionalAttributes
    ),
    req.f('debugExperiments'),
    res.locals.optimizelyEnabled
  );

  logExperiment({
    experimentName: experiment,
    variant: variation
  });

  return variation;
};

export const getFeatureConfigurationFromReqRes = (
  req,
  res,
  featureKey,
  featureConfig = {},
  additionalAttributes = {}
) => {
  const {
    serviceConfig: { params: featureVariables = {} } = {},
    useCustomerId = false
  } = featureConfig;

  const fallbackConfiguration = {
    experiment: null,
    variation: null,
    ...featureVariables
  };

  const {
    locals: {
      props: { experiments, helpers }
    }
  } = res;

  const optimizelyInstance = getOptimizelyInstance(
    experiments,
    helpers.f('debugExperiments'),
    res.locals.optimizelyEnabled
  );

  const optimizelyAttributes = getAttributesFromReqAndRes(
    req,
    res,
    useCustomerId,
    undefined,
    additionalAttributes
  );
  const featureVariableKeys = Object.keys(featureVariables);

  // wrap this block in a try-catch to make it more resilient to
  // unknown errors in the optimizelyInstance API
  try {
    return (
      getFeatureConfiguration({
        featureKey,
        featureVariableKeys,
        uniqueId: optimizelyAttributes.uniqueId,
        optimizelyAttributes,
        optimizelyInstance
      }) || fallbackConfiguration
    );
  } catch (e) {
    console.warn(e); // eslint-disable-line no-console

    return fallbackConfiguration;
  }
};

const getOptimizelyInstance = (
  experiments,
  enableDebug = false,
  optimizelyEnabled = false,
  isFeatureToggle = false
) => {
  return getInstanceFromState(
    {
      experiments,
      ui: {
        debugExperiments: enableDebug,
        optimizelyEnabled
      }
    },
    isFeatureToggle
  );
};

export const getFeatureVariableFromState = (
  state,
  featureKey,
  variableKey,
  options = {},
  isFeatureToggle = false,
  dataType = featureVariableDataType.string
) => {
  const {
    attributes = {},
    defaultValue = undefined,
    useCustomerId = false
  } = options;

  const optimizelyInstance = getInstanceFromState(state, isFeatureToggle);
  const optimizelyAttributes = getAttributesFromState(
    state,
    useCustomerId,
    attributes
  );
  const featureVariableKeys = [variableKey];

  // wrap this block in a try-catch to make it more resilient to
  // unknown errors in the optimizelyInstance API
  try {
    const featureConfiguration = getFeatureConfiguration({
      featureKey,
      featureVariableKeys,
      uniqueId: optimizelyAttributes.uniqueId,
      optimizelyAttributes,
      optimizelyInstance,
      dataType
    });

    return featureConfiguration && featureConfiguration[variableKey];
  } catch (e) {
    console.warn(e); // eslint-disable-line no-console

    return defaultValue;
  }
};

export const getFeatureVariableStringFromState = (
  state,
  featureKey,
  variableKey,
  options = {},
  isFeatureToggle = false
) =>
  getFeatureVariableFromState(
    state,
    featureKey,
    variableKey,
    options,
    isFeatureToggle,
    featureVariableDataType.string
  );

export const getFeatureVariableJsonFromState = (
  state,
  featureKey,
  variableKey,
  options = {},
  isFeatureToggle = false
) =>
  getFeatureVariableFromState(
    state,
    featureKey,
    variableKey,
    options,
    isFeatureToggle,
    featureVariableDataType.json
  );

const getFeatureConfiguration = ({
  featureKey,
  featureVariableKeys = [],
  uniqueId,
  optimizelyAttributes,
  optimizelyInstance,
  dataType = featureVariableDataType.string
}) => {
  const { isEnabled } = optimizelyInstance.isFeatureEnabled(
    featureKey,
    uniqueId,
    optimizelyAttributes
  );

  if (isEnabled) {
    const getFeatureVariable = {
      string: key =>
        optimizelyInstance.getFeatureVariableString(
          featureKey,
          key,
          uniqueId,
          optimizelyAttributes
        ),
      json: key =>
        optimizelyInstance.getFeatureVariableJson(
          featureKey,
          key,
          uniqueId,
          optimizelyAttributes
        )
    };

    const experimentDetails = {
      experiment: getFeatureVariable.string('experimentKey'),
      variation: getFeatureVariable.string('variationKey')
    };

    exposeActiveTestData({
      ...experimentDetails,
      attributes: optimizelyAttributes
    });

    logExperiment({
      experimentName: experimentDetails.experiment,
      variant: experimentDetails.variation
    });

    const variableConfigDetails = featureVariableKeys.reduce(
      (accm, variableKey) => {
        const variableValue = getFeatureVariable[dataType]?.(variableKey);

        accm[variableKey] = variableValue;

        return accm;
      },
      {}
    );

    return {
      ...experimentDetails,
      ...variableConfigDetails
    };
  }
};

export const isFeatureEnabled = (state, featureKey, option = {}) => {
  const {
    useCustomerId,
    additionalAttributes,
    isFeatureToggle = false
  } = option;
  const optimizelyInstance = getInstanceFromState(state, isFeatureToggle);
  const atrc = getAtrc(state);
  const optimizelyAttributes = getAttributesFromState(
    state,
    useCustomerId,
    additionalAttributes
  );
  const { isEnabled } = optimizelyInstance.isFeatureEnabled(
    featureKey,
    atrc,
    optimizelyAttributes
  );

  logExperiment({
    experimentName: featureKey,
    variant: isEnabled
  });

  return isEnabled;
};

export const isFeatureEnabledFromReqRes = (
  req,
  res,
  featureKey,
  options = {}
) => {
  const {
    locals: {
      props: { experiments, helpers }
    }
  } = res;

  const { additionalAttributes = {}, isFeatureToggle, useCustomerId } = options;

  const optimizelyInstance = getOptimizelyInstance(
    experiments,
    helpers.f('debugExperiments'),
    res.locals.optimizelyEnabled,
    isFeatureToggle
  );

  const optimizelyAttributes = getAttributesFromReqAndRes(
    req,
    res,
    useCustomerId,
    undefined,
    additionalAttributes
  );

  const { isEnabled } = optimizelyInstance.isFeatureEnabled(
    featureKey,
    optimizelyAttributes.uniqueId,
    optimizelyAttributes
  );

  logExperiment({
    experimentName: featureKey,
    variant: isEnabled
  });

  return isEnabled;
};

export const setEventTrackingFromReqAndRes = (
  req,
  res,
  eventName,
  enableDebug = false,
  optimizelyEnabled = true,
  useCustomerId = false
) => {
  const {
    locals: {
      props: { experiments }
    }
  } = res;
  const optimizelyInstance = getInstance(experiments, {
    enableDebug,
    optimizelyEnabled
  });

  const optimizelyAttributes = getAttributesFromReqAndRes(
    req,
    res,
    useCustomerId
  );

  return optimizelyInstance.setEventTracking(
    eventName,
    optimizelyAttributes.uniqueId,
    optimizelyAttributes
  );
};

export const formatOptimizelyFeatureKey = (siteId, region, keyName) => {
  return `${siteId}-${region}_${keyName}`;
};

export const updateRegionInKey = (key, state) =>
  key.replace('<region>', getAppRegion(state));

export const updateRegionInFeatureKey = (featureKey, region) =>
  featureKey.replace('<region>', region);

export function getConfigForFeaturesFromReqRes(req, res, featureConfigs = []) {
  const configs = [];
  const featureExperiment = {};

  featureConfigs.forEach(featureConfig => {
    const optimizelyFeatureKey = updateRegionInKey(
      featureConfig.featureKey,
      res.locals.props
    );

    const featureConfigurationValues = getFeatureConfigurationFromReqRes(
      req,
      res,
      optimizelyFeatureKey,
      featureConfig
    );

    featureExperiment[featureConfigurationValues.experiment] =
      featureConfigurationValues.variation;

    const { featureKey, params } = featureConfig.serviceConfig;
    configs.push({
      featureKey: featureKey || optimizelyFeatureKey,
      params: buildServiceParams(params, featureConfigurationValues)
    });
  });

  return { configs, featureExperiment };
}

/*
  Returns params which will be passed to MANGO
*/
function buildServiceParams(params = {}, featureConfigs) {
  const serviceParams = Object.keys(params);
  return serviceParams.map(name => ({
    name,
    value: featureConfigs[name]
  }));
}
