const locationsPerPage = 10;

export function getLocationsPerPage() {
  return locationsPerPage;
}

export function getPrevLocationsPage(curr) {
  return Math.max(1, curr - 1);
}

export function getNextLocationsPage(totalLocations, currPage) {
  return Math.min(getLastLocationsPage(totalLocations), currPage + 1);
}

export function getLastLocationsPage(totalLocations) {
  return Math.ceil(totalLocations / locationsPerPage);
}

export function getNextLocationsCount(totalLocations, currPage) {
  return currPage < getLastLocationsPage(totalLocations) - 1
    ? locationsPerPage
    : Math.max(0, totalLocations - currPage * locationsPerPage);
}

export function getSelectedLocationPage(selectedIndex) {
  return selectedIndex < 0
    ? null
    : Math.ceil((selectedIndex + 1) / locationsPerPage);
}
