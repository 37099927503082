import React from 'react';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import { enableBatching } from 'redux-batched-actions';
import thunkMiddleware from 'redux-thunk';
import { ThemeProvider } from '@ddsweb/theme';
import { registerApmErrorHandler } from '@peas/apm';
import getAnalytics from '#/analytics/analyticsBus';
import App from '../../components/app'; // this somehow doesn't work with # alias path
import { getDefaultThemeOverrides } from '#/components/styles/default-theme-provider-overrides';
import { initApm, ApmTool, errorHandlerConfig } from '#/lib/apm';
import Helpers from '#/lib/helpers';
import locales from '#/lib/i18n/locales';
import { getReduxLogger } from '#/lib/render/redux-logger';
import { defaultTransit } from '#/lib/render/transit-helper';
import reducers from '#/reducers';
import { getDefaultStateFromProps as getDefaultResourcesStateFromProps } from '#/reducers/resources';

export function repopulateResourcesOnState(state, props) {
  state.resources = getDefaultResourcesStateFromProps(props);
}

export default function(Page) {
  const helpers = new Helpers(document);
  if (helpers.c('apmTool') === ApmTool.NEWRELIC) {
    initApm(ApmTool.NEWRELIC, true);
    registerApmErrorHandler(errorHandlerConfig);
  }

  // Mobile Safari uses a bfcache that may not respond to http cache-control headers.
  // This will force a page reload when the page is being reloaded from cache.
  window.onpageshow = function(event) {
    if (event.persisted) {
      return window.location.reload();
    }
  };

  const attributes = document.body;
  const { props: propsJson, reduxState } = attributes.dataset;

  attributes.removeAttribute('data-props');
  attributes.removeAttribute('data-redux-state');

  let props = {};
  let preloadedState;

  if (propsJson && reduxState) {
    try {
      props = JSON.parse(propsJson);
      preloadedState = defaultTransit.fromJSON(reduxState);
      repopulateResourcesOnState(preloadedState, props);
    } catch (e) {
      // At this point something is seriously wrong but catching the error
      // prevents all other JavaScript from stopping as well.
      // eslint-disable-next-line no-console
      console.error(
        'There was an error trying to parse "data-props" or "data-redux-state"'
      );
    }
  }

  locales(document.body.getAttribute('data-language'));

  const middlewares = [thunkMiddleware];

  const reduxLogger = getReduxLogger();
  if (reduxLogger) {
    middlewares.push(reduxLogger);
  }

  const composeEnhancers =
    (process.env.NODE_ENV !== 'production' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;
  const store = createStore(
    enableBatching(reducers),
    preloadedState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  if (props.asyncPage && typeof _satellite !== 'undefined') {
    window.asyncPage = true;
    setTimeout(() => {
      getAnalytics().onceTimed(
        'app:asyncPageComplete',
        () => {
          _satellite.pageBottom(); // eslint-disable-line no-undef
        },
        parseInt(helpers.c('waitForAsyncPage'), 10)
      );
    }, 0);
  }

  return hydrate(
    <ThemeProvider overrides={getDefaultThemeOverrides()}>
      <Provider store={store}>
        <App {...props}>
          <Page {...props} />
        </App>
      </Provider>
    </ThemeProvider>,
    document.getElementById('content')
  );
}
