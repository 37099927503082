import React from 'react';
import PropTypes from 'prop-types';
import { getDisplayName } from 'react-recompose';
import { contextTypes as helpersContextTypes } from '../../components/create-context';

export const helperNames = [
  'appBannerCookie',
  'asset',
  'browserVersion',
  'browserTypeVersion',
  'c',
  'currentPathname',
  'f',
  'hasFeature',
  'isChromeless',
  'isStockAndroid',
  'l',
  'loginUrl',
  'registrationUrl',
  'scenario',
  't',
  'timezone',
  'basePath',
  'externalSecureLink',
  'host',
];

type AnyProps = {
  [key: string]: unknown;
};

function addHelpers(requestedHelpers: string[] = []) {
  return (ComposedComponent: React.ComponentType<AnyProps>): React.ComponentType<AnyProps> => {
    class Helpers extends React.Component {
      static displayName: string;
      static WrappedComponent: React.ComponentType<AnyProps>;

      bindedSelectedHelpers: { [key: string]: string };

      static contextTypes = {
        helpers: PropTypes.shape(helpersContextTypes).isRequired,
      };

      constructor(props: object, context: object) {
        super(props, context);
        const contextHelpers = this.context.helpers;
        const boundSelectedHelpers: { [key: string]: string } = {};

        for (const requestedHelperName of requestedHelpers) {
          if (typeof contextHelpers[requestedHelperName] === 'undefined') {
            throw new Error(
              `Requested helper ${requestedHelperName} could not be found. Please ensure all spelling is correct.`,
            );
          }

          boundSelectedHelpers[requestedHelperName] = contextHelpers[requestedHelperName].bind(contextHelpers);
        }

        this.bindedSelectedHelpers = boundSelectedHelpers;
      }

      render(): React.ReactNode {
        return <ComposedComponent {...Object.assign({}, this.props, this.bindedSelectedHelpers)} />;
      }
    }

    Helpers.displayName = `Helpers(${getDisplayName(ComposedComponent)})`;
    Helpers.WrappedComponent = ComposedComponent;

    return Helpers;
  };
}

export default function helpers(optionsOrComponent: string[] = []): Function {
  if (optionsOrComponent.length === 0) {
    throw new Error('Helpers decorator need to be passed at least one helper');
  }

  return addHelpers(optionsOrComponent);
}
