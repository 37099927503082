import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import CouponTile from '../coupon-tile';
import { TRANSITION_DURATION } from '../../../constants/coupons';
import helpers from '../../../lib/decorators/helpers';

function renderListItems(coupons) {
  const anySubmitting = coupons.some(coupon => coupon.submitting);

  return coupons
    .slice()
    .reverse()
    .map((coupon, index) => (
      <li key={index}>
        <CouponTile coupon={coupon} disabled={anySubmitting} />
      </li>
    ));
}

function renderCouponsSubList(coupons, title, enableCouponHeadings) {
  return (
    <div className="coupon-list__sub-list">
      {enableCouponHeadings && (
        <h4 className="coupon-list__sub-heading">{title}</h4>
      )}
      <ReactCSSTransitionGroup
        transitionName="slide"
        transitionEnterTimeout={TRANSITION_DURATION}
        transitionLeaveTimeout={TRANSITION_DURATION}
        component="ul"
      >
        {renderListItems(coupons)}
      </ReactCSSTransitionGroup>
    </div>
  );
}

function CouponList(props) {
  const { c, compactMode, availableCoupons, t, unavailableCoupons } = props;
  const enableCouponHeadings = c('coupons:enableHeadings');

  return (
    <div
      className={classnames('coupon-list', {
        'coupon-list--compact': compactMode
      })}
    >
      {enableCouponHeadings && (
        <h3 className="panel-comp__title">
          {t('checkout:order-summary.coupon-list-title')}
        </h3>
      )}
      {availableCoupons.length > 0 &&
        renderCouponsSubList(
          availableCoupons,
          t('checkout:order-summary.available-coupons-heading'),
          enableCouponHeadings
        )}
      {unavailableCoupons.length > 0 &&
        renderCouponsSubList(
          unavailableCoupons,
          t('checkout:order-summary.unavailable-coupons-heading'),
          enableCouponHeadings
        )}
      {compactMode && <div className="coupon-list__fade" />}
    </div>
  );
}

CouponList.propTypes = {
  availableCoupons: PropTypes.array.isRequired,
  c: PropTypes.func.isRequired,
  compactMode: PropTypes.bool,
  t: PropTypes.func.isRequired,
  unavailableCoupons: PropTypes.array.isRequired
};

CouponList.defaultProps = {
  compactMode: false
};

export default helpers(['c', 't'])(CouponList);
