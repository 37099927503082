import { favRestOfShelfCtaFeature, UnavailableItemHeightVariants } from '#/experiments/oop-1017/constants';
import { getFeatureVariableStringFromState } from '#/lib/optimizely-manager';

const {
  default: defaultVariant,
  removeLinksWithPositiveWording,
  seeAlternativesSub,
  seeAlternativesUnavailable,
} = UnavailableItemHeightVariants;

export const getUnavailableItemHeightVariant = (state: Store): UnavailableItemHeightVariants => {
  const { useCustomerId, featureKey, variationKey } = favRestOfShelfCtaFeature;
  const featureVariable = getFeatureVariableStringFromState(state, featureKey, variationKey, {
    useCustomerId,
  });

  return featureVariable || UnavailableItemHeightVariants.default;
};

export const getShouldShowLinks = (variant: UnavailableItemHeightVariants): boolean => variant === defaultVariant;

export const getShouldShowNewSubsHeading = (variant: UnavailableItemHeightVariants): boolean =>
  variant === removeLinksWithPositiveWording;

export const getShouldShowSeeAlternativesOnSubs = (variant: UnavailableItemHeightVariants): boolean =>
  variant === seeAlternativesSub;

export const getShouldShowSeeAlternativesAsBuyboxAction = (variant: UnavailableItemHeightVariants): boolean =>
  variant === seeAlternativesUnavailable;
