import React from 'react';
import Button from '@ddsweb/button';
import Heading from '@ddsweb/heading';
import { Text } from '@ddsweb/text';
import { CLOSE } from '#/analytics/constants';
import { openModal } from '#/actions/ui-action-creators';
import { ModalData } from '#/reducers/types.defs';
import { FAKE_DOOR_THANK_YOU_MODAL } from '#/constants/modal-names';
import { TTranslateFunc } from '#/lib/records/helpers.defs';
import { connect } from '#/lib/render/connect-deep-compare';
import helpers from '#/lib/decorators/helpers';
import { sessionStore } from '#/lib/data-store/client-store';
import {
  DISMISS_INTERESTED_IN_SLOT_NOTIFY_ME,
  IM_INTERESTED,
  NO_THANKS,
  UNAVAILABLE_MODAL,
} from '#/experiments/oop-2034/constants';
import {
  ModalButtonContainer,
  StyledButton,
  StyledModal,
  StyledTextContainer,
  StyledText,
} from '#/experiments/oop-2034/components/interested-in-this-service-modal/styled';
import { getNotifyMeThankYouModalData, triggerNotifyMeAnalyticsEvent } from '#/experiments/oop-2034/helpers';

export type InterestedInThisServiceModalProps = {
  t: TTranslateFunc;
  openModal: (modalName: string, modalData: ModalData | null, addToUrl: boolean) => void;
  modalData: {
    onChangeHandler: () => void;
  };
};

const InterestedInThisServiceModal = (props: InterestedInThisServiceModalProps): JSX.Element => {
  const {
    t: translate,
    openModal,
    modalData: { onChangeHandler },
  } = props;

  const onClickHandler = (buttonType: string): void => {
    sessionStore?.set(DISMISS_INTERESTED_IN_SLOT_NOTIFY_ME, true);

    triggerNotifyMeAnalyticsEvent(UNAVAILABLE_MODAL + buttonType);

    openModal(FAKE_DOOR_THANK_YOU_MODAL, getNotifyMeThankYouModalData(translate, onChangeHandler), false);
  };

  const onChange = (): void => {
    triggerNotifyMeAnalyticsEvent(UNAVAILABLE_MODAL + CLOSE);
    onChangeHandler();
  };

  const modalHeading = translate('modals:oop-2034-notify-me.interested-in-this-service-heading');

  return (
    <StyledModal
      id={'interested-in-this-service-modal'}
      accessibleLabel={modalHeading}
      onChange={onChange}
      dynamicHeight
      maxHeight={'572px'}
      open
    >
      <Heading headingLevel="1" visualSize="headline3">
        {modalHeading}
      </Heading>
      <StyledTextContainer>
        <Text as="p">{translate('modals:oop-2034-notify-me.interested-in-this-service-line-1')}</Text>
        <StyledText forwardedAs="p">
          {translate('modals:oop-2034-notify-me.interested-in-this-service-line-2')}
        </StyledText>
      </StyledTextContainer>
      <ModalButtonContainer>
        <Button id={'interested-button'} variant="secondary" onClick={(): void => onClickHandler(IM_INTERESTED)}>
          {translate('modals:oop-2034-notify-me.interested')}
        </Button>
        <StyledButton id={'no-thanks-button'} variant="secondary" onClick={(): void => onClickHandler(NO_THANKS)}>
          {translate('modals:oop-2034-notify-me.no-thanks')}
        </StyledButton>
      </ModalButtonContainer>
    </StyledModal>
  );
};

export default helpers(['t'])(connect(null, { openModal })(InterestedInThisServiceModal));
