import { Fields, Field } from '#/custom-typings/redux-store/form.defs';

export interface FormValidationRule {
  validate: (value: string | undefined) => boolean;
  getMessage: () => string;
  type?: string;
}

export interface FormValidators {
  [fieldId: string]: FormValidationRule[];
}

export const validateRules = (rules: FormValidationRule[], value: string | undefined): Field => {
  const error = rules.find(rule => typeof rule.validate === 'function' && rule.validate(value));

  const hasError = !!error;

  const validationState: Field = {
    value,
    hasError,
    errorMessage: hasError ? error.getMessage() : null,
  };

  if (error && error.type) {
    validationState.type = error && error.type;
  }

  return validationState;
};

export const hasValidationFailed = (validationState: Fields): boolean =>
  Object.values(validationState).some(value => value.hasError);

export function evaluateFormValidationState(
  validators: FormValidators,
  formState: Record<string, string | undefined> = {},
): Fields {
  return Object.keys(validators).reduce<Fields>((acc, key) => {
    const validationRules = validators[key]; //get validation rules for key(form-field)
    const input = formState[key]; //get the user keyed-in value for key
    acc[key] = validateRules(validationRules, input); //update accumulator with result of validation for key
    return acc;
  }, {});
}
