import { FAVORITES, CATEGORY, PROMOTIONS, SEARCH, BUYLIST, USUALS } from '#/constants/result-types';
import { BROWSE } from '#/constants/page-names';

export const POSTCODE_SEARCH = 'postcode search';
export const ADDRESS_SEARCH = 'address search';
export const MAP_SELECT = 'map select';
export const LIST_SELECT = 'list select';
export const PRODUCT_TILE = 'product tile';
export const UNAVAILABLE_PRODUCT = 'unavailable product';
export const CAROUSEL_TILE = 'carousel';
export const SPECIAL_OFFERS = 'special offers';
export const OFFERS_FOR_YOU_TAB = 'offers-for-you';

export const analyticsPageByName = {
  [USUALS]: FAVORITES,
  [OFFERS_FOR_YOU_TAB]: FAVORITES,
  [CATEGORY]: BROWSE,
  [PROMOTIONS]: SPECIAL_OFFERS,
  [SEARCH]: SEARCH,
  [BUYLIST]: BUYLIST,
};
export const GHS_SELLER_ID = 'ghs';
