import { createItem } from '#/lib/records';
import { getCurrency } from '#/reducers/app';
import { buildProductAnalyticsPayload } from '#/actions/trolley/analytics-action-creators';
import {
  getAnalyticsBasketId,
  getAnalyticsMarketplaceBasketId,
  getHasBasketBreachedByVolumeOrWeight
} from '#/selectors/trolley';
import analyticsBus from '#/analytics/analyticsBus';
import { basicEvent } from '#/analytics/types/basic';
import {
  PAGE_IDENTIFIER,
  ADD_ALL_PRODUCTS,
  BASKET_LIMIT_BREACH,
  BASKET_WITHIN_LIMIT,
  ONDEMAND_EVENT_TYPE,
  TRACK_DELIVERY,
  DELAY
} from '#/analytics/constants';
import { emitBasketOp } from '#/analytics/bertie/events';
import { getRecentOrderContextValue } from '#/experiments/oop-1720/helpers';

export const fireAddAllAnalytics = (data, state, analyticsOptions = {}) => {
  const products = data.updates.items
    .filter(item => item.successful)
    .map(filteredItem => {
      const orderItem = data.orderItems.find(
        item => item.productItem.product.id == filteredItem.id
      );
      const { quantity, weight, customerUnitChoice } = orderItem;

      const orderItemAnalyticsPayload = {
        ...orderItem.productItem,
        customerUnitChoice,
        quantity,
        weight
      };
      const item = createItem(orderItemAnalyticsPayload);

      return buildProductAnalyticsPayload(
        item,
        quantity,
        getCurrency(state),
        analyticsOptions
      );
    });

  const basketBreachedOrInLimit = getHasBasketBreachedByVolumeOrWeight(state)
    ? BASKET_LIMIT_BREACH
    : BASKET_WITHIN_LIMIT;

  const { identifier = PAGE_IDENTIFIER, position } = analyticsOptions;

  let payload = {
    identifier,
    interactionType: ADD_ALL_PRODUCTS,
    basketId: getAnalyticsBasketId(state),
    basketFullness: basketBreachedOrInLimit,
    flag: {
      isOpen: false
    },
    uniqueProducts: data.items.length,
    product: products,
    ...(position && { position })
  };

  // TODO: Remove this as part of LEGO-31735
  if (identifier?.includes(PAGE_IDENTIFIER)) {
    payload.contextValue = getRecentOrderContextValue(state);
  }

  payload.secondBasketId = getAnalyticsMarketplaceBasketId(state);

  emitBasketOp(payload);
};

export const emitTrackDeliveryEvent = () => {
  basicEvent(analyticsBus, {
    type: ONDEMAND_EVENT_TYPE,
    value: TRACK_DELIVERY,
    action: DELAY
  });
};
