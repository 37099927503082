import { MY_FAVOURITES } from '#/constants/facet-categories';
import { USUALS_TAB, LAST_ORDER_TAB } from '#/constants/favorites';
import { updateParamsInUrl } from '#/lib/url/url-utils';
import { getFavoritesLanguageLink } from '#/utils/favorite-utils';
import { getLanguageLink, getRedirectUrl } from '#/reducers/app';
import { getIsUserAuthenticated } from '#/reducers/user';
import { TConfigFunc, TTranslateFunc } from '#/lib/records/helpers.defs';

type TOptions = {
  translate: TTranslateFunc;
  config: TConfigFunc;
  shouldShowFavDropdown: boolean;
  favMenuOpen: boolean;
};

export type ChildMenu = {
  label: string;
  name: string;
  externalUrl: string;
};

const updateUrl = (state: Store, navItemUrl: string): string =>
  getRedirectUrl(state, navItemUrl, getIsUserAuthenticated(state), '');

const updateQueryParameters = (state: Store, tab: string, tabHeaderUrl: string): string => {
  return updateUrl(state, updateParamsInUrl(tabHeaderUrl, { tab }));
};

export const getFavChildMenu = (state: Store, options: TOptions): ChildMenu[] => {
  const { config, translate } = options;

  const favChildMenu = [
    {
      label: MY_FAVOURITES,
      name: translate('product-list:previously-bought'),
      externalUrl: getFavoritesLanguageLink(state, '/favorites'),
    },
  ];

  const favLink = getLanguageLink(state, '/favorites');
  if (config('usualsEnabled')) {
    favChildMenu.push({
      label: MY_FAVOURITES,
      name: translate('product-list:usuals'),
      externalUrl: updateQueryParameters(state, USUALS_TAB, favLink),
    });
  }

  if (config('newFavNavEnabled') && config('lastOrderEnabled')) {
    favChildMenu.push({
      label: MY_FAVOURITES,
      name: translate('product-list:last-order-tab.title'),
      externalUrl: updateQueryParameters(state, LAST_ORDER_TAB, favLink),
    });
  }

  return favChildMenu;
};
