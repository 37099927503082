import Modal from '@ddsweb/modal';
import styled from 'styled-components';
import { colors, spacing } from '@ddsweb/selectors';
import { mediumSmallMax, smallMax } from '#/constants/viewports';

export const StyledQuickBasketModal = styled(Modal)`
  .ddsweb-modal__modal-container {
    max-height: unset;
    border: 1px solid ${colors.disabled};
    height: auto;
    width: 480px;
  }

  .ddsweb-modal__mask {
    z-index: 1008;
  }

  &.large-modal {
    .ddsweb-modal__modal-container {
      max-width: 708px;
      width: 708px;
      max-height: 100%;
      height: inherit;

      @media (max-width: ${smallMax}) {
        max-height: unset;
      }
    }

    @media (max-width: ${mediumSmallMax}) {
      .content-action {
        display: none;
      }

      .ddsweb-modal__close-link {
        display: none;
      }

      .ddsweb-modal__modal-container {
        padding: ${spacing.md} 0 calc(${spacing.xxxl} * 2);
        max-height: calc(100% - 52px);
        min-width: 100%;
        width: 100%;
        top: 26px;
      }

      .ddsweb-modal__mask {
        padding: 0;
        background: transparent;
        transition: top 0.3s;
        top: 0;
      }

      .ddsweb-modal__scrollable-container {
        padding: 0;
      }

      &.with-header-search {
        .ddsweb-modal__modal-container {
          max-height: calc(100% - 102px);
          top: 51px;
        }
      }
    }
  }

  &.small-modal {
    .ddsweb-modal__modal-container {
      max-height: 270px;
    }
    .ddsweb-modal__scrollable-container {
      height: 100%;
    }
  }
`;
