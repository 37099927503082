import { EXPERIMENTS } from '#/constants/spa-resource';
import { getExperimentsResource } from '#/selectors/resources';

export const type = EXPERIMENTS;

export function handler(dispatch, getState) {
  const { data } = getExperimentsResource(getState());

  if (!data) {
    return;
  }

  dispatch({
    type: 'UPDATE_EXPERIMENTS',
    value: data
  });
}
