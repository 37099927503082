import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from '#/lib/render/connect-deep-compare';
import SafeForm from '#/components/shared/safe-form';
import { getCurrentUrl } from '#/reducers/app';
import helpers from '#/lib/decorators/helpers';
import { updateParamsInUrl } from '#/lib/url/url-utils';
import { getTrolleyItemByBaseProductId } from '#/selectors/trolley';
import { getIsUserAuthenticated } from '#/reducers/user';
import {
  getCatchWeight,
  getSeedProductId,
  getProductId
} from '#/selectors/item';

const mapStateToProps = (state, ownProps) => {
  const seedProductId = getSeedProductId(ownProps.item);

  return {
    applyAsAdjustment:
      !!ownProps.c('addByAdjustment') && !getIsUserAuthenticated(state),
    currentUrl: getCurrentUrl(state),
    itemForRemoval:
      seedProductId && getTrolleyItemByBaseProductId(state, seedProductId)
  };
};

@helpers(['c', 'l'])
@connect(mapStateToProps)
export default class TrolleyForm extends Component {
  static propTypes = {
    anchorId: PropTypes.string,
    applyAsAdjustment: PropTypes.bool.isRequired,
    backToUrl: PropTypes.string,
    children: PropTypes.node,
    currentUrl: PropTypes.string.isRequired,
    identifier: PropTypes.string,
    item: PropTypes.object.isRequired,
    itemForRemoval: PropTypes.object,
    l: PropTypes.func.isRequired,
    oldItemQuantity: PropTypes.number,
    oldItemUnitChoice: PropTypes.string,
    updateReturnUrlWithProductId: PropTypes.bool,
    userRegistered: PropTypes.bool.isRequired
  };

  static defaultProps = {
    backToUrl: '#',
    identifier: '',
    updateReturnUrlWithProductId: false
  };

  onSubmitTrolleyForm = event => {
    if (this.props.userRegistered) {
      event.preventDefault();
    }
  };

  setParamsWithProductId(updateReturnUrlWithProductId, productId) {
    return updateReturnUrlWithProductId ? { productAdded: productId } : null;
  }

  setParamsWithRedirect(userRegistered, identifier) {
    return !userRegistered && identifier === 'Page'
      ? { redirectIfUnavailable: true }
      : null;
  }

  getReturnUrl = (id, anchorId = '') => {
    const {
      currentUrl,
      updateReturnUrlWithProductId,
      userRegistered,
      identifier
    } = this.props;

    const params = {
      ...this.setParamsWithProductId(updateReturnUrlWithProductId, id),
      ...this.setParamsWithRedirect(userRegistered, identifier)
    };

    const returnUrl = updateParamsInUrl(currentUrl, params);
    return `${returnUrl}${anchorId}`;
  };

  render() {
    const { identifier, item, itemForRemoval, anchorId } = this.props;
    const id = getProductId(item);

    // note: this form contains multiple buttons that post to different urls
    // we add a hidden submit at the top of the form so that pressing enter key
    // when in form defaults to default form action

    return (
      <SafeForm
        action={this.props.l(`/trolley/items/${id}?_method=PUT`)}
        method="POST"
        onSubmit={this.onSubmitTrolleyForm}
      >
        <input type="submit" className="hidden" value="submit" />
        <input name="id" type="hidden" value={id} />
        {identifier && (
          <input name="identifier" type="hidden" value={identifier} />
        )}
        {itemForRemoval && (
          <input
            name="removeProductId"
            type="hidden"
            value={getProductId(itemForRemoval)}
          />
        )}
        <input name="anchorId" type="hidden" value={anchorId} />
        <input
          name="returnUrl"
          type="hidden"
          value={this.getReturnUrl(id, anchorId)}
        />
        <input
          key="backToUrl"
          name="backToUrl"
          type="hidden"
          value={this.props.backToUrl}
        />
        <input
          name="oldValue"
          type="hidden"
          value={this.props.oldItemQuantity}
        />
        <input
          name="oldUnitChoice"
          type="hidden"
          value={this.props.oldItemUnitChoice}
        />
        <input name="catchWeight" type="hidden" value={getCatchWeight(item)} />
        {this.props.applyAsAdjustment && (
          <input name="adjustment" type="hidden" value={true} />
        )}
        {this.props.children}
      </SafeForm>
    );
  }
}
