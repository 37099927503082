import { getFeatureVariableStringFromState } from '#/lib/optimizely-manager';

export const getOptimizelyFeatureVariableString = (
  featureKey,
  variableKey,
  useCustomerId,
  attributes,
  defaultValue
) => (dispatch, getState) =>
  getFeatureVariableStringFromState(getState(), featureKey, variableKey, {
    attributes,
    defaultValue,
    useCustomerId
  });
