import styled from 'styled-components';
import { Heading } from '@ddsweb/heading';
import { colors, spacing } from '@ddsweb/selectors';
import { BREAKPOINTS, WHITE, BLUE_TESCO } from '@ddsweb/constants';
import { mediumSmallMax } from '#/constants/viewports';

export const MultiSwapButtonWrapper = styled.div`
  width: 60%;
  margin-bottom: 40px;
  margin-top: -32px;
  @media (max-width: ${mediumSmallMax}) {
    width: 100%;
  }
  @media (max-width: ${BREAKPOINTS.tabletLarge}px) {
    margin-bottom: 50px;
  }
`;

export const CarouselWrapper = styled.div`
  .multi-swap {
    border: 1px solid ${colors.lines};
    margin-top: ${spacing.sm};
    padding: ${spacing.sm};
    [data-auto='product-tile'] {
      border: ${spacing.xxx} solid ${colors.warning};
      .product-info-message-section {
        display: block;
      }
      .product-info-section-small {
        display: none;
      }
    }
    [id^='ddsweb-carousel-item'] {
      padding-right: 0px;
      margin-right: -4px;
    }
    button:disabled,
    button:disabled:hover {
      background-color: ${WHITE};
      color: ${BLUE_TESCO};
      border: ${spacing.xxx} solid ${BLUE_TESCO};
    }
  }
`;

export const StyledHeading = styled(Heading)`
  margin-top: ${spacing.md};
  margin-bottom: ${spacing.sm};
`;
