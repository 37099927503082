import styled from 'styled-components';
import Icon from '@beans/icon';
import Button from '@beans/button';
import { colors, spacing, fontSize } from '@beans/selectors';
import Modal from '@beans/modal';
import { BodyText } from '@beans/typography';
import RadioButtonGroup from '@beans/radio-button-group';

export const StyledDiv = styled.div`
  font-family: 'TESCO Modern', Arial, sans-serif;
  & .beans-menu__menu-list {
    right: 8px;
    width: 230px;
    box-shadow: 0 0 0 ${spacing.xx} #99cbe1;
    border: 2px solid ${colors.active};

    .beans-actions-menu__menu-item {
      a {
        padding: ${spacing.xs} ${spacing.sm};
        font-size: ${fontSize.xs};
      }
    }
  }
`;

export const StyledIcon = styled(Icon)`
  height: 20px;
  width: 21px;
  &.icon-listView {
    position: absolute;
    top: 19px;
    left: 2px;
  }

  path {
    fill: ${({ isIconFilled }: { isIconFilled: boolean }): string =>
      ((isIconFilled ? colors.background : colors.white) as unknown) as string};
  }
`;

export const StyledButton = styled(Button)`
  height: 41px;
  width: 44px;
  :hover,
  :focus {
    color: ${colors.primary};
  }
`;

export const StyledModal = styled(Modal)`
  .beans-modal__content-container {
    padding: 0;

    .beans-modal__scrollable-container {
      padding: ${spacing.md} ${spacing.sm};

      .beans-form-group__children {
        margin: 0;
      }
    }
  }
  .beans-modal__mask {
    z-index: 16;
  }
`;
StyledModal.displayName = 'StyledModal';

export const StyledTitle = styled(BodyText)`
  && {
    color: ${colors.heading};
    margin-top: ${spacing.md};
  }
`;

export const StyledModalButton = styled(Button)`
  margin-top: ${spacing.md};
  :disabled {
    color: ${colors.grayscale};
  }
`;

export const StyledText = styled(BodyText)`
  && {
    color: ${colors.heading};
    font-size: 20px;
  }
`;

export const StyledBodyText = styled(BodyText)`
  margin: ${spacing.md} 0;
`;

export const StyledRadioButtonGroup = styled(RadioButtonGroup)`
  .beans-form-group__legend {
    padding: ${spacing.md} 0;
    font-weight: normal;
    color: ${colors.grayscale};
  }
  .beans-radio-button__container {
    span.beans-radio-button__visible {
      border-color: ${colors.disabled};
    }
  }
`;
