import url from 'url';

export const getBackToResultLink = (backToUrl, itemId) => {
  const parsedUrl = url.parse(backToUrl);

  parsedUrl.hash = itemId;

  return url.format(parsedUrl);
};

export const isFromPDP = link => /\/products\//.test(link);
export const isFromSearch = link => /\/search?/.test(link);
export const isFromBrowse = link => /\/shop\//.test(link);
