import styled from 'styled-components';
import CookiesNotification from '@ddsweb/cookies-notification';
import { base, colors, fontSize, spacing } from '@ddsweb/selectors';
import { media } from '@ddsweb/foundation';

export const StyledCookiesNotification = styled(CookiesNotification)`
  &.ddsweb-cookies-notification__container {
    border-top: 1px solid ${colors.lines};
    position: static;
    font-family: ${base.fontFamily};
    min-height: 158px;
    padding: 0;
    display: flex;

    > div {
      max-width: 1260px;
      padding: 0 ${spacing.xl};
      width: 100%;

      ${media.belowTablet`
        padding: 0 ${spacing.sm};
      `}

      > :nth-child(2) {
        align-self: center;
        .ddsweb-cookies-notification__form {
          margin-bottom: ${spacing.sm};

          .ddsweb-cookies-notification__button {
            margin-top: 0px;
            margin-bottom: 0px;
          }
        }

        .ddsweb-cookies-notification__link {
          margin-top: 0px;
          margin-bottom: 0px;
        }

        ${media.belowTablet`
          display: flex;
          justify-content: center;

          .ddsweb-cookies-notification__form {
            width: 50%;
            margin-right: ${spacing.sm};
            margin-bottom: 0px;

            .ddsweb-cookies-notification__button {
              width: 100%;
            }
          }

          .ddsweb-cookies-notification__link {
            width: 50%;
          }
        `};

        @media (max-width: 640px) {
          flex-direction: column;

          .ddsweb-cookies-notification__form {
            width: 100%;
            margin-right: 0px;
            margin-bottom: ${spacing.sm};
          }

          .ddsweb-cookies-notification__link {
            width: 100%;
          }
        }
      }
    }

    .ddsweb-link__text {
      font-size: ${fontSize.xs};
    }

    .ddsweb-cookies-notification__body-container {
      color: ${colors.grayscale};
      font-size: ${fontSize.xs};
      ${media.belowTablet`
        padding-bottom: ${spacing.lg};
      `}
    }

    ${media.aboveMobileLarge`
      border-bottom: 1px solid ${colors.lines};
    `};

    ${media.belowTablet`
      padding-top: ${spacing.xl};
      padding-bottom: ${spacing.xl};
      border-bottom: 0;
    `}

    .ddsweb-cookies-notification__form {
      min-width: 200px;
    }
  }
`;
StyledCookiesNotification.displayName = 'StyledCookieNotification';
