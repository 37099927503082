export const VARIATION_KEY = 'special_offer_filter';
export const FEATURE_KEY = 'GHS-<region>_special_offer_filter';
export const SEARCH_URL = '/search';
export const SHOP_URL = '/shop';

export type ExperimentConfig = {
  experiment: string;
  variation: string;
  [VARIATION_KEY]: string;
};

export enum SpecialOffersFilterVariants {
  DEFAULT = 'default',
  GREY = 'grey',
  YELLOW = 'yellow',
  CLUB_CARD_GREY = 'club_card_grey',
  CLUB_CARD_YELLOW = 'club_card_yellow',
}

export const SpecialOffersFilterFeature = {
  featureKey: FEATURE_KEY,
  variationKey: VARIATION_KEY,
};

export const SPECIAL_OFFERS = 'Special Offers';
export const OFFERS_AND_CLUBCARD_PRICES = 'Offers & Clubcard Prices';

export type FilterAttributesVariant = {
  label: string;
  desktopClassName: string;
  mobileClassName: string;
} | null;

export type MobileTextAndBackgroundColourVariant = {
  textColourClassName: string;
  backgroundColourClassName: string | null;
};

export type FilterTitleAndVariantClassName = {
  filterTitle: string;
  variantClassName: string | null;
};

export type FilterLableAndClassName = {
  label: string;
  className: string;
};

export type ClassNameAndWrapperClassName = {
  className: string;
  wrapperClassName: string | null;
};
