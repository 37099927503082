import { transformPromotionsSpecialOffersContent } from '#/utils/dcs-utils';
import { transformPromotedOfferProducts } from '#/lib/records/product-utils';
import { RECEIVE_PROMOTED_OFFERS } from '#/constants/action-types';
import { PlainObject } from '#/types';
import { Product } from '#/lib/records/item.defs';
import { PromotedOffer, PromotionsContent } from '#/custom-typings/redux-store/promoted-offers.defs';
import { Item } from '#/lib/records/item';

type PromotedOffers = Array<Item | PromotedOffer>;

const getTransformedPromotedOffers = (promotedOffers: PromotedOffer[]): PromotedOffer[] => {
  return promotedOffers.map((offer: PromotedOffer) => ({
    ...offer,
    products: transformPromotedOfferProducts(offer.products as Product[]),
  }));
};

export default (state: Partial<Store> = {}, action: { type: string; value: PlainObject }): PlainObject => {
  switch (action.type) {
    case RECEIVE_PROMOTED_OFFERS:
      return {
        ...state,
        promotedOffers: getTransformedPromotedOffers(action.value as PromotedOffer[]),
      };
    default:
      return state;
  }
};

export const getDefaultStateFromProps = (
  props: PlainObject,
): { promotedOffers: PromotedOffers; promotionsContent: PromotionsContent } => {
  const offers = props.promotedOffers;
  let promotedOffers = {} as PromotedOffers;

  if (offers) {
    promotedOffers = getTransformedPromotedOffers(offers);
  }

  const promotionsContent =
    (props.cmsData?.promotionsContent && transformPromotionsSpecialOffersContent(props.cmsData?.promotionsContent)) ||
    {};

  return {
    promotedOffers,
    promotionsContent,
  };
};

// export const getInterrupts = state => state.dcs.interrupts;
export const getPromotedOffers = (state: Store): PromotedOffers => state.promotedOffers.promotedOffers;
export const getPromotionsContent = (state: Store): PromotionsContent => state.promotedOffers.promotionsContent;

export const hasPromotedOffers = (state: Store): boolean => {
  const promotedOffers = getPromotedOffers(state);

  return !!promotedOffers && promotedOffers.length > 0;
};
