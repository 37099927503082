import React from 'react';
import { TConfigFunc } from '#/lib/records/helpers.defs';
import {
  disconnectObserver,
  defineObservers,
  observe,
  isTopIntersection,
  isBottomIntersection,
  getHeaderAnimation,
  getHeaderOffset,
  HeaderIntersectionObserver,
} from '../helpers';
import { LAYOUT_WIDTH_BREAKPOINT } from '../constants';

type TOwnProps = {
  displayCookieInterrupt: boolean;
  isAmendBasket: boolean;
};

type THelperProps = {
  c: TConfigFunc;
};

export type TProps = TOwnProps & THelperProps;

class StickyElement extends React.Component<TProps> {
  topObserver: HeaderIntersectionObserver = null;
  bottomObserver: HeaderIntersectionObserver = null;
  topRef: React.RefObject<Element>;
  bottomRef: React.RefObject<Element>;

  state = {
    fixedHeader: false,
    headerOffset: 0,
    headerAnimation: null,
  };

  constructor(props: TProps) {
    super(props);
    this.topRef = React.createRef();
    this.bottomRef = React.createRef();
  }

  componentDidMount(): void {
    const headerOffset = this.getHeaderOffset();
    this.updateHeaderOffset(headerOffset);
    this.onWindowResize = this.onWindowResize.bind(this);
    window.addEventListener('resize', this.onWindowResize);
    this.setCssVariables();
  }

  componentWillUnmount(): void {
    disconnectObserver(this.topObserver);
    disconnectObserver(this.bottomObserver);
  }

  onTopIntersection = (elements: IntersectionObserverEntry[]): void => {
    const isIntersecting = isTopIntersection(elements, this.state.headerOffset);
    this.setState({ fixedHeader: isIntersecting });
    this.setState({ headerAnimation: null });
  };

  onBottomIntersection = (elements: IntersectionObserverEntry[]): void => {
    const isIntersecting = isBottomIntersection(elements);
    this.setState({ fixedHeader: isIntersecting });
    this.setState({ headerAnimation: getHeaderAnimation(isIntersecting) });
  };

  updateHeaderOffset = (headerOffset: number): void => {
    this.setState({ headerOffset });
    const { topObserver, bottomObserver } = defineObservers(
      this.onTopIntersection,
      this.onBottomIntersection,
      headerOffset,
    );
    disconnectObserver(this.topObserver);
    disconnectObserver(this.bottomObserver);
    this.topObserver = topObserver;
    this.bottomObserver = bottomObserver;
    observe(this.topObserver, this.topRef.current);
    observe(this.bottomObserver, this.bottomRef.current);
  };

  getHeaderOffset = (): number => {
    const { c: config, displayCookieInterrupt, isAmendBasket } = this.props;
    const isCookieBanner = config('cookiePreferences') && displayCookieInterrupt;
    return window.innerWidth < LAYOUT_WIDTH_BREAKPOINT ? 0 : getHeaderOffset(isCookieBanner, isAmendBasket);
  };

  setCssVariables = (): void => {
    const slotSelector = document.getElementsByClassName('slot-selector--tab-content')[0];
    if (slotSelector) {
      document.documentElement.style.setProperty(
        '--slots-sticky-header-width',
        ((slotSelector as unknown) as HTMLElement)?.offsetWidth + 'px',
      );
      document.documentElement.style.setProperty(
        '--slots-sticky-header-offset-left',
        ((slotSelector as unknown) as HTMLElement)?.getBoundingClientRect().x + 'px',
      );
    }
  };

  onWindowResize = (): void => {
    this.setCssVariables();
    if (window.innerWidth < LAYOUT_WIDTH_BREAKPOINT && this.state?.headerOffset > 0) {
      this.updateHeaderOffset(0);
    }
    if (window.innerWidth >= LAYOUT_WIDTH_BREAKPOINT && this.state.headerOffset === 0) {
      const headerOffset = this.getHeaderOffset();
      this.updateHeaderOffset(headerOffset);
    }
  };
}

export default StickyElement;
