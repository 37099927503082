import styled from 'styled-components';
import { spacing, colors } from '@ddsweb/selectors';
import { mediumMin } from '#/constants/viewports';
import { TProps } from './index.defs';

export const StarRatingWrapper = styled.div`
  padding: ${spacing.xxl} ${spacing.md};
  margin-top: ${spacing.sm};
  background-color: ${(props: TProps): string =>
    String(props.isFavoritesPage ? colors.backgroundDark : colors.background)};
  border: solid 1px ${colors.disabled};
  

  @media (min-width: ${mediumMin}) {
    ${(props: TProps): string => (props.isFavoritesPage ? 'margin-top: 0px' : '')}
  }};
`;

export const StarRatingContainer = styled.div`
  margin-top: ${spacing.sm};
  min-height: 69px;
`;
