import React from 'react';
import PropTypes from 'prop-types';
import { WEIGHT_UNIT_KG } from '#/constants/common';
import formatNumber from '#/lib/string-formatting/number-formatter';
import { round, gaussianRound } from '#/lib/string-formatting/format';

const DECIMAL_PLACES = 2;

class SelectWeight extends React.PureComponent {
  constructor(props) {
    super(props);
    this.priceConfig = {
      ...props.currency,
      showAsciiSymbol: true
    };
    this.state = {
      selectedValue: this.getDisplayValue(
        props.displayIndex,
        props.averageWeight
      )
    };
    this.handleChange = this.handleChange.bind(this);
  }

  static propTypes = {
    averageWeight: PropTypes.number.isRequired,
    currency: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    displayIndex: PropTypes.number,
    id: PropTypes.string.isRequired,
    max: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    price: PropTypes.number.isRequired
  };

  static defaultProps = {
    disabled: false,
    displayIndex: 1
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      selectedValue: this.getDisplayValue(
        nextProps.displayIndex,
        nextProps.averageWeight
      )
    });
  }

  handleChange(e) {
    this.props.onChange(e);
  }

  getDisplayValue(index, averageWeight) {
    const value = round(index * averageWeight, 2);

    return value || formatNumber(value);
  }

  render() {
    let i, displayQty, formattedPrice, label;
    const { id, disabled, averageWeight, max, price } = this.props;
    const options = [];
    const selectedValue = this.state.selectedValue;

    for (i = 1; i <= max; i++) {
      displayQty = this.getDisplayValue(i, averageWeight);
      formattedPrice = formatNumber(
        gaussianRound(i * price, DECIMAL_PLACES),
        this.priceConfig
      );
      label = `${displayQty}${WEIGHT_UNIT_KG} / ${formattedPrice}`;

      options.push(
        <option key={i + 1} value={displayQty}>
          {label}
        </option>
      );
    }

    return disabled ? (
      <span data-auto="product-input" className="product-input">
        {`${selectedValue}${WEIGHT_UNIT_KG}`}
      </span>
    ) : (
      <div className="inputControl-dropdown-wrapper">
        <div
          data-auto="weighted-product-control"
          className="weightedProduct-wrapper"
        >
          <select
            data-auto="weightedProduct-dropdown"
            id={id}
            className="needsclick weightedProduct-dropdown"
            name="newValue"
            value={selectedValue}
            onChange={this.handleChange}
          >
            {options}
          </select>
          <span className="weightedProduct-text icon-chevron_down">
            {`${selectedValue}${WEIGHT_UNIT_KG}`}
          </span>
        </div>
      </div>
    );
  }
}

export default SelectWeight;
