import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import helpers from '../../../lib/decorators/helpers';

@helpers(['t'])
export default class CollectionSteps extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired
  };

  render() {
    const steps = [1, 2, 3, 4].map(index => {
      return (
        <li className={`collection-step collection-step--${index}`} key={index}>
          <div className="collection-step--box">
            <h3 className="collection-step--title">
              {this.props.t(`slots:collection-steps.${index}.title`)}
            </h3>
            <p className="collection-step--content">
              {this.props.t(`slots:collection-steps.${index}.content`)}
            </p>
          </div>
        </li>
      );
    });

    return <ul className="collection-steps">{steps}</ul>;
  }
}
