import { StickyHeaderOffset, timesOfTheDay } from '../constants';
import moment from 'moment-timezone';
import { Slot } from '#/lib/records/slot.defs';

export const getHeaderOffset = (isCookieBanner: string | number | boolean, isAmendBasket: boolean): number => {
  let topOffset = 0;

  if (isCookieBanner) {
    topOffset += StickyHeaderOffset.COOKIE_BANNER;
  }

  if (isAmendBasket) {
    topOffset += StickyHeaderOffset.AMEND_BASKET;
  }

  return topOffset;
};

export const getSlotTimeOfTheDay = (slot: Slot, timezone: string): string => {
  if (!slot.start || !timezone) {
    return '';
  }

  const slotStart = moment(slot.start)
    .tz(timezone)
    .format('HH:mm');

  if (moment(slotStart, 'HH:mm') >= moment(timesOfTheDay.EVENING.startHour, 'HH:mm').tz(timezone)) {
    return timesOfTheDay.EVENING.label;
  } else if (moment(slotStart, 'HH:mm') >= moment(timesOfTheDay.AFTERNOON.startHour, 'HH:mm').tz(timezone)) {
    return timesOfTheDay.AFTERNOON.label;
  } else {
    return timesOfTheDay.MORNING.label;
  }
};

export const getSlotsGroupedByTimesOfTheDay = (slots: Slot[][], timezone: string): Record<string, Slot[][]> =>
  slots.reduce((group: Record<string, Slot[][]>, slot: Slot[]) => {
    const timesOfTheDay: string = getSlotTimeOfTheDay(slot[0] || slot, timezone);

    if (timesOfTheDay) {
      group[timesOfTheDay] = group[timesOfTheDay] ?? [];
      group[timesOfTheDay].push(slot);
    }

    return group;
  }, {});
