import React from 'react';
import classnames from 'classnames';
import ResponsiveImage from '@ddsweb/responsive-image';
import { noImagePath } from '#/constants/category-chips';
import helpers from '#/lib/decorators/helpers';
import { TAssetFunc } from '#/lib/records/helpers.defs';
import { ButtonText, ImageButtonWrapper, OuterLinkBox } from '#/components/image-button/styled';

export type OwnProps = {
  image: string;
  eIndex: number;
  label: string;
  url: string;
  active: boolean;
  onClick: (currentEle: Element) => void;
  asset: TAssetFunc;
};
interface DOMEvent<T extends HTMLElement> extends Event {
  readonly currentTarget: T;
}

export const ImageButton = ({ eIndex, image, label, url, active, onClick, asset }: OwnProps): JSX.Element => {
  const classes = classnames('image-button', {
    'image-button--active': active,
  });

  const handleClick = (event: DOMEvent<HTMLInputElement>): void => {
    event.stopPropagation();
    event.preventDefault();
    onClick(event.currentTarget);
  };

  return (
    <OuterLinkBox onClick={handleClick} to={url} className={classes} data-index={eIndex} aria-label={label}>
      <ImageButtonWrapper>
        <ResponsiveImage
          className="btn-img"
          src={image}
          alt={label}
          title={label}
          loading="lazy"
          fallbackSrc={asset(noImagePath)}
          width="32px"
          height="32px"
        />
        <ButtonText as="p" tagName="div" className="label-text">
          {label}
        </ButtonText>
      </ImageButtonWrapper>
    </OuterLinkBox>
  );
};

export default helpers(['asset'])(ImageButton);
