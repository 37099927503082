import React from 'react';
import PropTypes from 'prop-types';
import { WARNING } from '@ddsweb/constants';
import { InLineMessaging } from '@ddsweb/messaging';
import Text from '@ddsweb/text';
import { compose } from 'redux';
import helpers from '#/lib/decorators/helpers';
import { connect } from '#/lib/render/connect-deep-compare';
import {
  getSelectedShoppingMethod,
  getSelectedWeekSlots,
  getBookedDays
} from '#/reducers/slot';
import { DELIVERY, COLLECTION } from '#/constants/shopping-methods';
import SafelyOutputString from '#/components/safely-output-string';
import { formatBookedDaysHumanized } from '#/lib/slot/slot-utils';

const sameDayWarningMessage = props => {
  const { t: translate, shoppingMethod, bookedDays } = props;
  const numberOfBookedDays = bookedDays.length;

  if (numberOfBookedDays === 0) {
    return null;
  }

  const formattedBookedDays = formatBookedDaysHumanized(bookedDays, translate);

  const text = translate(`slots:common.no-same-day-booking.${shoppingMethod}`, {
    smart_count: numberOfBookedDays,
    days:
      numberOfBookedDays > 1
        ? formattedBookedDays.slice(0, numberOfBookedDays - 1).join(', ')
        : formattedBookedDays[0],
    lastDay: formattedBookedDays[numberOfBookedDays - 1]
  });

  return (
    <InLineMessaging
      variant={WARNING}
      assistiveText={translate('common:warning')}
    >
      <Text>
        <SafelyOutputString>{text}</SafelyOutputString>
      </Text>
    </InLineMessaging>
  );
};

sameDayWarningMessage.propTypes = {
  id: PropTypes.string.isRequired,
  shoppingMethod: PropTypes.oneOf([DELIVERY, COLLECTION]).isRequired,
  slots: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const slots = getSelectedWeekSlots(state);
  return {
    shoppingMethod: getSelectedShoppingMethod(state),
    slots,
    bookedDays: getBookedDays(state, { slots })
  };
};

export default compose(
  connect(mapStateToProps),
  helpers(['t'])
)(sameDayWarningMessage);
