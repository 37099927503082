import React from 'react';
import { connect } from '#/lib/render/connect-deep-compare';
import ContextualBanner from '#/components/shared/contextual-banner';
import { getDCSPageResource } from '#/selectors/resources';
import { getSelectedShoppingMethod } from '#/reducers/slot';
import { isOnDemandShoppingMethod } from '#/lib/shopping-method-util';
type TOwnProps = {
  dcsProps: any;
  isOnDemandSelected: boolean;
};

const mapStateToProps = (state: any) => {
  const dcsPageResource = getDCSPageResource(state);
  const dcsProps = dcsPageResource?.data?.content || {};
  const isOnDemandSelected = isOnDemandShoppingMethod(getSelectedShoppingMethod(state));

  return {
    dcsProps,
    isOnDemandSelected,
  };
};

type TProps = TOwnProps & ReturnType<typeof mapStateToProps>;

const DCSContextualBanner: React.FC<TProps> = (props: TProps) => {
  const { dcsProps: { contextualBannerProps } = {}, isOnDemandSelected } = props;

  return contextualBannerProps && !isOnDemandSelected ? <ContextualBanner {...contextualBannerProps} /> : null;
};

export default connect(mapStateToProps)(DCSContextualBanner);
