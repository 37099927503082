import React from 'react';
import PropTypes from 'prop-types';

const SectionMessage = props => {
  const { id, message, size, styles } = props;
  // If no or invalid size prop supplied default to medium font-size
  const fontSizeClass = size === 'medium' || size === 'large' ? size : 'medium';

  return (
    <div
      className="ui-components-library--section-message"
      id={id}
      data-auto="slots-section-message"
      style={styles}
    >
      {message ? (
        <props.headingTag
          className={`ui-components-library--section-message--content ${fontSizeClass}`}
        >
          {message}
        </props.headingTag>
      ) : (
        <span className="ui-components-library--section-message--content divider" />
      )}
    </div>
  );
};

SectionMessage.propTypes = {
  headingTag: PropTypes.string,
  id: PropTypes.string,
  message: PropTypes.node,
  size: PropTypes.string,
  styles: PropTypes.object
};

SectionMessage.defaultProps = {
  headingTag: 'h2'
};

export { SectionMessage };
