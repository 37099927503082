import { debounce } from '#/utils/misc';
import bertie from '#/analytics/analyticsBus';
import { FAVOURITES, AVAILABLE } from '#/constants/facet-categories';
import { FacetList } from '#/lib/requests/graphql-queries/fragments/facet-lists.defs';

const productFilterOpenAnalyticsEvent = debounce(() => {
  bertie().emit('UIEventBasicEvent', { action: 'now', type: 'filter', value: 'lifestyle and dietary' });
}, 3000);

const productFilterAnalyticsEvent = debounce((currentFilters: Record<string, unknown>, currentFacets: FacetList) => {
  let analyticsPayload = {};

  currentFacets.forEach(facetList => {
    const selectedFilters = facetList.facets.reduce((list, facet) => {
      if (facet.isSelected) {
        // @ts-expect-error concat accepts non-array argument but its type definitions expects otherwise
        return list.concat(facet.facetName);
      }

      return list;
    }, []);

    // LEGO-4464: Favourites has single filter, return boolean rather than array.
    const value = FAVOURITES === facetList.category || selectedFilters;

    let filterName = facetList.category.toLowerCase();
    let filterValue = selectedFilters.length > 0 ? value : false;

    if (filterName === AVAILABLE) {
      filterName = 'hideUnavailableItems';
      filterValue = selectedFilters.length > 0;
    }

    analyticsPayload = {
      ...analyticsPayload,
      [filterName]: filterValue,
    };
  });

  bertie().emit('UIEventFilterOp', {
    ...Object.keys(currentFilters).reduce(
      (final, filter) => ({
        ...final,
        [filter]: !!currentFilters[filter],
      }),
      {},
    ),
    ...analyticsPayload,
    sortBy: currentFilters.sortBy || 'relevance',
  });
}, 3000);

export { productFilterAnalyticsEvent, productFilterOpenAnalyticsEvent };
