import {
  LOCATION_SELECTED,
  SHOW_LOCATIONS_PAGE,
  SHOW_FEWER_LOCATIONS,
  NEW_LOCATIONS_DATA,
  SEARCH_LOCATIONS_BY_SUGGESTION_ADDRESS
} from '#/constants/action-types';
import { updateSlots } from '#/actions/slot-action-creators/update-slots';
import { getLocations } from '#/reducers/location';
import { getSelectedLocationPage } from '#/lib/slot/locations';
import { getSelectedLocationId } from '#/reducers/slot';

export function selectLocationId(locationId) {
  return (dispatch, getState) => {
    const locations = getLocations(getState());
    const location = locations.find(loc => loc.locationId === locationId);

    dispatch({ type: LOCATION_SELECTED, value: location });
  };
}

export function showLocationsPage(page) {
  return dispatch => {
    dispatch({
      type: SHOW_LOCATIONS_PAGE,
      value: page
    });
  };
}

export const showLessLocations = () => (dispatch, getState) => {
  const state = getState();

  dispatch({
    type: SHOW_FEWER_LOCATIONS,
    value: getSelectedLocationPage(
      getLocations(state).findIndex(
        location => location.locationId === getSelectedLocationId(state)
      )
    )
  });
};

export const updateLocations = data => {
  return dispatch => {
    dispatch({
      type: NEW_LOCATIONS_DATA,
      value: {
        locations: data.locations
      }
    });
  };
};

export function searchLocationsBySuggestedAddress(address) {
  return async dispatch => {
    dispatch({ type: SEARCH_LOCATIONS_BY_SUGGESTION_ADDRESS, value: address });

    await dispatch(
      updateSlots({ addressId: address.id }, { forceUpdate: true })
    );
  };
}

export function setSuggestedAddress(address) {
  return dispatch => {
    dispatch({ type: SEARCH_LOCATIONS_BY_SUGGESTION_ADDRESS, value: address });
  };
}
