import styled from 'styled-components';
import { spacing, colors, fontSize } from '@ddsweb/selectors';
import { media } from '@ddsweb/foundation';

const ContextCardFooter = styled.div`
  display: flex;
  flex-wrap: nowrap;
  background: ${colors.backgroundInfo};
  padding: ${spacing.xs};

  && img {
    align-self: center;

    ${media.belowDesktop`
      display: none
    `};
  }

  && p {
    margin: 0 ${spacing.lg};

    ${media.belowDesktop`
      margin: 0;
    `};
  }
`;

ContextCardFooter.displayName = 'ContextCardFooter';

export const CardContainer = styled.div`
  ${media.belowTablet`
  min-height: 85px;
`};
  ${media.belowMobileLarge`
  min-height: 124px;
`};
`;

export default ContextCardFooter;

export const BookSlotContextCardWrapper = styled.div`
  && .context-card-book-a-slot__text {
    margin-top: ${({ addMargin }) => (addMargin ? 0 : spacing.sm)};
    ${media.belowTablet`
     margin-top: ${({ addMargin }) => (addMargin ? 0 : '5px')};
   `};
  }

  && .contact-your-courier {
    margin-top: ${spacing.sm};
    font-size: ${fontSize.xs};
    ${media.belowTablet`
     margin-top: 5px;
     font-size: ${fontSize.xx};
   `};
  }
`;

export const CountdownTimeText = styled.span`
  font-weight: bold;
`;
CountdownTimeText.displayName = 'CountdownTimeText';
