import { TROLLEY_CONTENTS } from '#/constants/spa-resource';
import { updateTrolley } from '#/actions/trolley/trolley-action-creators';
import { updateHYFRecommendations } from '#/actions/recommendations-action-creators';
import { getTrolleyContentsResource } from '#/selectors/resources';

export const type = TROLLEY_CONTENTS;
export const params = [
  'forceUpdate',
  'includeCoupons',
  'includeHYF',
  'includeSubstitutions',
  'preview'
];
export const cacheable = false;

export function handler(dispatch, getState) {
  const { data } = getTrolleyContentsResource(getState());

  if (!data) {
    return;
  }

  dispatch(updateTrolley(data, false, true));

  if (data.hasHYFRecommendations)
    dispatch(
      updateHYFRecommendations({
        hasHYFRecommendations: data.hasHYFRecommendations
      })
    );
}
