import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { connect } from '../../lib/render/connect-deep-compare';
import classSet from 'classnames';
import { closeLeftNav } from '../../actions/ui-action-creators';
import HeaderSignedIn from './header-signed-in';
import HeaderSignedOut from './header-signed-out';
import Overlay from '../shared/overlay';
import LeftHandNavigation from './left-hand-navigation';
import { getIsMobile, getLanguage, getRegisterUrl } from '#/reducers/app';
import helpers from '../../lib/decorators/helpers';
import { getIsGroceriesMenuOpen } from '#/selectors/ui';

const mapStateToProps = state => ({
  isOpen: state.ui.leftNavOpen,
  isMobile: getIsMobile(state),
  showGroceries: getIsGroceriesMenuOpen(state),
  registerUrl: getRegisterUrl(state),
  language: getLanguage(state)
});

@connect(mapStateToProps, {
  closeLeftNav
})
@helpers(['isChromeless', 't'])
export default class MainNavigation extends Component {
  static propTypes = {
    closeLeftNav: PropTypes.func.isRequired,
    cmsNav: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    isChromeless: PropTypes.func,
    isMobile: PropTypes.bool.isRequired,
    isOpen: PropTypes.bool.isRequired,
    isUserAuthenticated: PropTypes.bool.isRequired,
    isUserRegistered: PropTypes.bool.isRequired,
    language: PropTypes.string.isRequired,
    noOfPendingOrders: PropTypes.number,
    registerUrl: PropTypes.string.isRequired,
    selectedFacetLists: PropTypes.array,
    showGroceries: PropTypes.bool.isRequired,
    t: PropTypes.func,
    taxonomyState: PropTypes.object.isRequired,
    userDisplayName: PropTypes.string
  };
  static defaultProps = {
    selectedFacetLists: []
  };
  componentDidUpdate() {
    /* For Accessibility */
    if (this.props.isOpen) {
      ReactDOM.findDOMNode(this.refs['main-menu']).focus();
    }
  }

  closeNav = e => {
    e.preventDefault();
    this.props.closeLeftNav();
  };

  closeOverlay = e => {
    e.preventDefault();
    this.props.closeLeftNav();
  };

  render() {
    if (!this.props.isChromeless()) {
      const mainNavClasses = classSet({
        'main-nav': true,
        'main-nav-open': this.props.isOpen
      });
      const headerComponent = this.props.isUserAuthenticated ? (
        <HeaderSignedIn
          isRegistered={this.props.isUserRegistered}
          registerUrl={this.props.registerUrl}
          name={this.props.userDisplayName}
          language={this.props.language}
        />
      ) : (
        <HeaderSignedOut />
      );

      return (
        <div className="left-hand-navigation">
          <Overlay close={this.closeOverlay} isOpen={this.props.isOpen} />
          <nav className={mainNavClasses} id="nav">
            <a
              aria-controls="nav"
              aria-expanded={this.props.isOpen}
              className="main-header-button main-header-icon-button pull-right icon icon-burger_menu_icon"
              href="#"
              rel="nofollow"
              onClick={this.closeNav}
              role="button"
            >
              <span className="visually-hidden">
                {this.props.t('navigation:close-navigation')}
              </span>
            </a>
            <div className="main-nav-inner">
              <div className="fixed-container">
                <div className="fixed-top">
                  {headerComponent}
                  <div className="list-container fixed-middle">
                    <LeftHandNavigation
                      ref="main-menu"
                      selectedFacetLists={this.props.selectedFacetLists}
                      isUserAuthenticated={this.props.isUserAuthenticated}
                      cmsNav={this.props.cmsNav}
                      noOfPendingOrders={this.props.noOfPendingOrders}
                    />
                  </div>
                </div>
              </div>
              <a
                aria-controls="nav"
                className="overlay-close"
                href="#"
                onClick={this.closeNav}
              >
                <span className="visually-hidden">
                  {this.props.t('common:close')}
                </span>
              </a>
            </div>
          </nav>
        </div>
      );
    }

    return null;
  }
}
