import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Feedback from '../../shared/feedback';
import { toRgba } from '../helpers/trade-tile';

export class EventButton extends PureComponent {
  static propTypes = {
    button: PropTypes.shape({
      secondary: PropTypes.bool,
      back: PropTypes.bool,
      centered: PropTypes.bool,
      margin: PropTypes.bool,
      isFeedback: PropTypes.bool,
      noLink: PropTypes.bool
    }),
    componentClickHandler: PropTypes.func,
    id: PropTypes.number,
    link: PropTypes.shape({
      href: PropTypes.string,
      label: PropTypes.string,
      newWindow: PropTypes.bool,
      style: PropTypes.object
    }),
    placement: PropTypes.number
  };

  onClick = () => {
    const props = this.props;
    const handler = props.componentClickHandler;

    if (typeof handler === 'function') {
      return handler(props);
    }
  };

  render() {
    const { link = {}, id, placement, button = {} } = this.props;
    const ButtonWrapper = button.isFeedback ? Feedback : 'div';
    const ButtonTag = button.noLink ? 'span' : 'a';
    const buttonId = id || placement;
    const hasBackground = link.style && link.style.backgroundColor;
    const hasTextColor = link.style && link.style.color;
    const extrafeedbackProps = button.isFeedback && {
      tag: 'span',
      location: link.label || 'event_button'
    };
    const buttonClasses = classnames('event-button-wrapper', {
      button_center: button.centered,
      button_margin: button.margin,
      button_back: button.back,
      'event-button-primary': !button.secondary && !link.style,
      'event-button-secondary': button.secondary,
      'event-button-custom': hasBackground && !button.secondary
    });
    const buttonIconClasses = classnames('link-icon', {
      'icon-chevron_right': button.secondary,
      'icon-chevron_right-white': !button.secondary
    });
    const buttonIconStyle = hasTextColor && {
      background: `url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2229%22%20height%3D%2229%22%20viewBox%3D%220%200%2058%2058%22%3E%3Cpath%20fill%3D%22%23${link.style.color.substring(
        1
      )}%22%20d%3D%22M19.917%2015.84a1.352%201.352%200%200%200%200%201.92L31.26%2029.005%2019.917%2040.248a1.356%201.356%200%200%200%200%201.924l1.457%201.44c.534.532%201.41.532%201.943%200l13.785-13.646a1.356%201.356%200%200%200%200-1.924L23.317%2014.396a1.39%201.39%200%200%200-1.943%200l-1.457%201.444z%22%2F%3E%3C%2Fsvg%3E) no-repeat center` // eslint-disable-line max-len
    };

    return (
      <ButtonWrapper className={buttonClasses} {...extrafeedbackProps}>
        {buttonId && hasBackground && (
          <style>
            {`.event-button-wrapper #event-button-${buttonId}:hover {
              box-shadow: ${toRgba(
                link.style.backgroundColor,
                '0.4'
              )} 0 0 0 4px;
            }`}
          </style>
        )}
        {buttonId && hasTextColor && (
          <style>
            {`.event-button-wrapper #event-button-${buttonId}:hover .link-label {
                border-bottom-color: ${link.style.color};
            }`}
          </style>
        )}
        <ButtonTag
          id={buttonId && `event-button-${buttonId}`}
          className="event-button"
          style={link.style}
          onClick={this.onClick}
          href={
            link.href && !button.isFeedback && !button.noLink ? link.href : null
          }
          target={link.newWindow ? link.label || '_blank' : null}
          rel="noopener noreferrer"
        >
          {link.label && <span className="link-label">{link.label}</span>}
          <span className={buttonIconClasses} style={buttonIconStyle} />
        </ButtonTag>
      </ButtonWrapper>
    );
  }
}
