import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import isValid from '../../lib/validation/is-valid';

export default class InputValidation extends PureComponent {
  constructor(props) {
    super(props);

    if (process.env.CLIENT_SIDE) {
      this.props.register(this, this.validate);
    }

    const validationEnabled =
      this.props.displayError || this.props.defaultValue != null;
    const errors = validationEnabled ? this.getErrors(true) : [];

    this.state = {
      errors,
      validationEnabled
    };
  }

  static propTypes = {
    charPattern: PropTypes.string,
    defaultValue: PropTypes.any,
    disabled: PropTypes.bool.isRequired,
    displayError: PropTypes.bool.isRequired,
    maxLength: PropTypes.number,
    mustMatch: PropTypes.bool.isRequired,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool.isRequired,
    register: PropTypes.func
  };

  getErrors = force => {
    if (this.props.disabled === true || this.props.readOnly === true) {
      return [];
    }

    return Object.keys(this.props)
      .filter(
        key =>
          (force || this.state.validationEnabled) &&
          key.indexOf('data-err') === 0
      )
      .filter(key => {
        const name = getValidatorName(key);
        let value =
          this.state && typeof this.state.value === 'string'
            ? this.state.value
            : this.props.defaultValue;

        // When using autofill, change is not fired so react will not know
        // the input value. Get from DOM directly.
        if (process.env.CLIENT_SIDE && !value && this.refs && this.refs.input) {
          value = this.refs.input.value;
        }

        return !isValid(name, value, this.props.mustMatch);
      })
      .map(key => this.props[key]);
  };

  clearErrors = () => {
    return this.setState({
      errors: []
    });
  };

  onChange = event => {
    this.setState(
      {
        value: event.target.value
      },
      () => this.props.register()
    );

    if (this.props.onChange) {
      this.props.onChange();
    }
  };

  onBlur = event => {
    this.setState(
      {
        validationEnabled: true
      },
      () => this.props.register()
    );

    if (this.props.onBlur) {
      this.props.onBlur(event);
    }
  };

  onKeyPress = event => {
    if (
      this.props.maxLength != null &&
      this.props.maxLength <= event.target.value.length
    ) {
      this.errorInputVisualFeedback(event);
    }

    if (this.props.charPattern != null) {
      if (!this.props.charPattern.test(String.fromCharCode(event.charCode))) {
        return this.errorInputVisualFeedback(event);
      }
    }
  };

  errorInputVisualFeedback = event => {
    event.preventDefault();
    this.setState({
      elementClass: 'input-validation-error pulse animated-input'
    });

    if (process.env.CLIENT_SIDE) {
      return window.setTimeout(this.clearInputError, 500);
    }
  };

  clearInputError = () => {
    return this.setState({
      elementClass: ''
    });
  };

  validate = force => {
    if (force) {
      this.setState({
        validationEnabled: true
      });
    }

    const errors = this.getErrors(force);

    this.setState({
      errors
    });

    return errors;
  };
}

const getValidatorName = name =>
  name
    .split('-')
    .slice(2)
    .join('_');
