import ClientStoreWrapper from './client-store-wrapper';

const getLocalStoreInstance = () => {
  let localStoreWrapper;

  if (typeof localStorage !== 'undefined') {
    localStoreWrapper = new ClientStoreWrapper(localStorage);
  }

  return localStoreWrapper;
};

const getSessionStoreInstance = () => {
  let sessionStoreWrapper;

  if (typeof sessionStorage !== 'undefined') {
    sessionStoreWrapper = new ClientStoreWrapper(sessionStorage);
  }

  return sessionStoreWrapper;
};

export const localStore = getLocalStoreInstance();
export const sessionStore = getSessionStoreInstance();
