const defaultConfig = {
  // If the element gets within 100px in the Y axis, start the download.
  rootMargin: '100px 0px',
  threshold: 0.01
};

export const isObserverSupported =
  process.env.CLIENT_SIDE && 'IntersectionObserver' in window;

export const getObserver = (handler, config = defaultConfig) => {
  if (isObserverSupported) {
    return new IntersectionObserver(handler, config);
  }

  return null;
};
