import React from 'react';
import classnames from 'classnames';
import { connect } from '#/lib/render/connect-deep-compare';
import { Text } from '@ddsweb/text';
import { BasicAccordion } from '@beans/accordion-next';
import { TConfigFunc, TTranslateFunc } from '#/lib/records/helpers.defs';
import helpers from '#/lib/decorators/helpers';
import {
  StyledBookASlotAccordionText,
  StyledBookASlotAccordionHeader,
  StyledBookASlotAccordionAddress,
  StyledBookASlotAccordionAddressInfo,
  StyledBookASlotAccordionAddressInfoTitle,
  StyledBookASlotAccordionAddressInfoContentDetailsHeading,
  StyledBookASlotAccordionAddressInfoContent,
  StyledBookASlotAccordionAddressInfoContentDetails,
  StyledBookASlotAccordion,
  StyledTitle,
} from '#/experiments/oop-1627/components/styled';
import {
  getShowSlotUIReskinV2,
  getShowAddNewAddressHeaderVariant,
  getShowAddNewAddressHeaderAndSimplifyVariant,
} from '#/experiments/oop-2205/selectors';

import analyticsBus from '#/analytics/analyticsBus';
import { NOW, ACCORDION_EXPAND, ACCORDION_COLLAPSE, CHANGE_COLLECTION_POINT } from '#/analytics/constants';
import { formatAddress } from '#/experiments/oop-2205/components/helpers';

type Address = {
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  addressLine4: string;
  addressLine5: string;
  addressLine6: string;
  id: string;
  name: string;
  postcode: string;
};

type ParentProps = {
  content: JSX.Element;
  selectedLocation: {
    address: Address;
    latitude: number;
    longitude: number;
  };
  showSlotUIReskin: boolean;
  isAddNewAdressHeaderVariant: boolean;
  isAddNewAdressHeaderAndSimplifyVariant: boolean;
};

type OwnProps = {
  t: TTranslateFunc;
  c: TConfigFunc;
};

type OwnState = {
  showSlotUIReskin: boolean;
  isAddNewAdressHeaderVariant: boolean;
  isAddNewAdressHeaderAndSimplifyVariant: boolean;
};

export type Props = ParentProps & OwnProps;

const mapStateToProps = (state: Store): OwnState => {
  const showSlotUIReskin = getShowSlotUIReskinV2(state);
  return {
    showSlotUIReskin,
    isAddNewAdressHeaderVariant: getShowAddNewAddressHeaderVariant(state),
    isAddNewAdressHeaderAndSimplifyVariant: getShowAddNewAddressHeaderAndSimplifyVariant(state),
  };
};

const AccordionStoreMap = ({
  content,
  t: translate,
  selectedLocation,
  showSlotUIReskin,
  isAddNewAdressHeaderVariant,
  isAddNewAdressHeaderAndSimplifyVariant,
}: Props): JSX.Element => {
  const { address } = selectedLocation;

  const handleAccordionClick = (event: React.SyntheticEvent): void => {
    const isOpen = (event.target as HTMLElement).ariaExpanded;
    analyticsBus().emit('UIEventBasicEvent', {
      action: NOW,
      type: isOpen === 'true' ? ACCORDION_COLLAPSE : ACCORDION_EXPAND,
      value: CHANGE_COLLECTION_POINT,
    });
  };

  const renderAddressInfoDetails = (): JSX.Element[] => {
    const output: JSX.Element[] = [];
    if (address?.name && !showSlotUIReskin) {
      output.push(
        <StyledBookASlotAccordionAddressInfoContentDetailsHeading
          headingLevel="3"
          visualSize="headline4"
          key="addressName"
        >
          {address?.name}
        </StyledBookASlotAccordionAddressInfoContentDetailsHeading>,
      );
    }

    if (showSlotUIReskin) {
      output.push(
        <div key="fullAddress">
          {isAddNewAdressHeaderVariant && (
            <StyledTitle headingLevel="2" visualSize="headline4">
              {translate('slots:oop2205.2205-c.your-collection-location')}
            </StyledTitle>
          )}
          {isAddNewAdressHeaderAndSimplifyVariant && (
            <StyledTitle headingLevel="2" visualSize="headline4">
              {translate('slots:oop2205.2205-d.you-will-collect-from')}
            </StyledTitle>
          )}
          {!isAddNewAdressHeaderAndSimplifyVariant && (
            <StyledBookASlotAccordionHeader headingLevel="2" visualSize="headline5">
              {translate(
                isAddNewAdressHeaderVariant
                  ? 'slots:oop2205.2205-c.collection-address-heading'
                  : 'slots:oop2205.collection-location',
              )}
            </StyledBookASlotAccordionHeader>
          )}
          {address && (
            <Text as="span" size="standard" data-testid="collection-address">
              {formatAddress(address, { withName: true })}
            </Text>
          )}
        </div>,
      );
    } else {
      address &&
        output.push(
          <StyledBookASlotAccordionText key="fullAddress" data-testid="collection-address">
            {Object.values(address)
              .slice(1)
              .filter(item => item)
              .join(', ')}
          </StyledBookASlotAccordionText>,
        );
    }
    return output;
  };

  return (
    <StyledBookASlotAccordion
      className={classnames({
        '--slot-ui-reskin': showSlotUIReskin,
      })}
    >
      <StyledBookASlotAccordionAddress showSlotUIReskin={showSlotUIReskin}>
        <StyledBookASlotAccordionAddressInfo>
          {!showSlotUIReskin && (
            <StyledBookASlotAccordionAddressInfoTitle headingLevel="2">
              {translate('slots:oop1627.your-collection-location')}
            </StyledBookASlotAccordionAddressInfoTitle>
          )}

          <StyledBookASlotAccordionAddressInfoContent>
            <StyledBookASlotAccordionAddressInfoContentDetails>
              {renderAddressInfoDetails()}
            </StyledBookASlotAccordionAddressInfoContentDetails>
          </StyledBookASlotAccordionAddressInfoContent>
        </StyledBookASlotAccordionAddressInfo>
      </StyledBookASlotAccordionAddress>
      <BasicAccordion
        containerClassName="book-a-slot-accordion-container"
        behaviour={'toggle'}
        content={[
          {
            itemClassName: 'book-a-slot-accordion-item',
            headerClassName: `book-a-slot-accordion-header ${
              showSlotUIReskin ? 'book-a-slot-accordion-header--slot-ui-reskin' : ''
            }`,
            panelClassName: 'book-a-slot-accordion-panel',
            headingLevel: 'h4',
            iconVariant: 'secondary',
            heading: translate('slots:oop1627.change-collection-point'),
            id: 'store-map-accordion',
            panel: content,
            handleClick: handleAccordionClick,
          },
        ]}
        data-auto="store-map-accordion"
      />
    </StyledBookASlotAccordion>
  );
};

export default helpers(['t'])(connect(mapStateToProps)(AccordionStoreMap));
