import React, { SyntheticEvent, useCallback, useState, useEffect, useRef } from 'react';
import { TabsMenuProps, TabWithSubTextProps } from '@ddsweb/tabs-menu';
import { Panel } from '@ddsweb/tabs';
import { LinkProps } from '@ddsweb/link';
import Link from '#/components/link-check-spa';
import { StyledTabsMenu, StyledBeansTabs, StyledTabWithSubText } from '#/components/pages/favorites/styled';
import { FAVORITES_TAB, USUALS_TAB, LAST_ORDER_TAB } from '#/constants/favorites';
import { OFFERS_FOR_YOU_TAB } from '#/experiments/oop-1557/constants/favorites';

type Tab = { href: string; id: string; label: string; subtext: string };

type Props = {
  tabs: Array<Tab>;
  selectedTab: string;
  children: JSX.Element;
};

const SPALink = (props: LinkProps): JSX.Element => {
  const { children, href, ...otherProps } = props;
  return (
    <Link to={href} {...otherProps}>
      {children}
    </Link>
  );
};

const renderTab = (tabProps: TabWithSubTextProps): JSX.Element => (
  <StyledTabWithSubText {...tabProps} spaLink={SPALink} />
);

const FavTabs: React.FC<Props> = props => {
  const { selectedTab: selectedTabFromProp, children, tabs: tabsFromProps } = props;
  const [selectedTab, setSelectedTab] = useState(selectedTabFromProp);
  const refs = useRef<Record<string, HTMLAnchorElement>>({});

  const getDomRef = (id: string) => (ref: HTMLAnchorElement): void => {
    refs.current[id] = ref;
  };

  const tabs = tabsFromProps.map(tab => {
    return {
      ...tab,
      domRef: getDomRef(tab.id),
    };
  });

  useEffect(() => {
    if (selectedTab !== selectedTabFromProp) {
      setSelectedTab(selectedTabFromProp);
    }
  }, [selectedTabFromProp]);

  useEffect(() => {
    refs.current[selectedTab]?.scrollIntoView({ block: 'center', inline: 'center' });
  }, [selectedTab]);

  const onTabsMenuItemClick = (_: SyntheticEvent, { id }: { id: string }): void => {
    setSelectedTab(id);
  };

  const renderTabsMenu = useCallback((tabsMenuProps: TabsMenuProps): JSX.Element => {
    tabsMenuProps.onTabsMenuItemClick = onTabsMenuItemClick;

    return <StyledTabsMenu {...tabsMenuProps} tab={renderTab} />;
  }, []);

  const renderPanel = (): Array<JSX.Element> =>
    tabs.map(tab => {
      if (tab.id === LAST_ORDER_TAB) {
        return <Panel key={tab.id}>{selectedTab === LAST_ORDER_TAB && children}</Panel>;
      }
      if (tab.id === USUALS_TAB) {
        return <Panel key={tab.id}>{selectedTab === USUALS_TAB && children}</Panel>;
      }
      if (tab.id === OFFERS_FOR_YOU_TAB) {
        return <Panel key={tab.id}>{selectedTab === OFFERS_FOR_YOU_TAB && children}</Panel>;
      }

      return <Panel key={tab.id}>{selectedTab === FAVORITES_TAB && children}</Panel>;
    });

  return (
    <StyledBeansTabs root activeTabID={selectedTab} tabs={tabs} tabsMenu={renderTabsMenu} className="favorites-tabs">
      {renderPanel()}
    </StyledBeansTabs>
  );
};

export default FavTabs;
