import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '#/lib/render/connect-deep-compare';
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import querystring from 'querystring';
import Link from '#/components/link';
import Map from '#/components/map';
import LocationList from '#/components/map/location-list';
import slotRangeUtils from '#/lib/slot/slot-range-utils';
import AddressSearch from '#/components/slots/address-search';
import MobileMapLink from '#/components/map/mobile-map-link/';
import {
  getLocationsPerPage,
  getPrevLocationsPage,
  getNextLocationsPage,
  getNextLocationsCount
} from '#/lib/slot/locations';
import {
  updateParamsInUrl,
  updatePageUrlWithoutRefresh
} from '#/lib/url/url-utils';
import SpinnerOverlay from '#/components/overlay-spinner';
import { CollectionMultipleAddressSelector } from '#/components/slots/multiple-address-selector';
import { changeDeliveryAddress } from '#/actions/trolley/trolley-action-creators';
import {
  showOrderTypeChangeWarning,
  changeSlotDate,
  updateSlotsForLocation,
  clearSuggestionSelectedAddress
} from '#/actions/slot-action-creators';
import { CHANGE_DELIVERY_ADDRESS } from '#/constants/slot-warning-modal-types';
import helpers from '#/lib/decorators/helpers';
import { slotCurrentlyBooked, formatUrl } from '#/lib/slot/slot-utils';
import { getItemsCount, getSlot, getStoreId } from '#/selectors/trolley';
import {
  getSelectedDate,
  getLocationsExpanded,
  getSlotsPendingStatus,
  getLocationsPage,
  getSelectedCollectionLocationId,
  getSelectedDateSlots,
  getSelectedWeekSlots,
  getSelectedShoppingMethod,
  getSelectedLocationId,
  getSuggestionSelectedAddressId
} from '#/reducers/slot';
import { ADDRESS_SEARCH, LIST_SELECT } from '#/constants/analytics';
import { MAP_SELECT } from '#/constants/analytics';
import { getHasAcceptedSlotWarningTypes } from '#/reducers/ui';
import { getCurrentUrl, getIsDesktop, getTimezone } from '#/reducers/app';
import {
  showLocationsPage,
  showLessLocations,
  selectLocationId
} from '#/actions/location-action-creators';
import { COLLECTION } from '#/constants/shopping-methods';
import { getLanguageLink } from '#/reducers/app';
import { emitSlotOp } from '#/analytics/bertie/events';
import { SHOPPING_METHOD_PROP_TYPE } from '#/components/slots/prop-types';
import { getDeliveryAddressForTrollyId } from '#/selectors/detailed-addresses';
import { getAllAddresses } from '#/selectors/addresses';
import { SUGGESTED_ADDRESS } from '#/constants/spa-resource';
import { sessionStore } from '#/lib/data-store/client-store';

function getLocationLink(path, locationId) {
  return updateParamsInUrl(path, { locationId });
}

const SHOW_MORE_LOCATIONS = 'showMoreLocations';
const SHOW_LESS_LOCATIONS = 'showLessLocations';
const SHOW_PREV_LOCATIONS = 'showPrevLocations';
const SHOW_NEXT_LOCATIONS = 'showNextLocations';
const LOCATIONS_PER_PAGE_IN_COLLAPSED_MODE = 5;

const generateSelectedStoreMapQueryString = (
  bookedSlotLocation,
  selectedDate,
  locationId,
  addressId
) => {
  const bookedSlotLocationId = bookedSlotLocation
    ? bookedSlotLocation.locationId
    : null;

  return querystring.stringify({
    bookedSlotLocation: bookedSlotLocationId,
    selectedDate,
    locationId,
    addressId
  });
};

const mapStateToProps = (state, { c: config }) => ({
  changeAddressCookieAccepted: state.ui.changeAddressCookieAccepted,
  currentSlot: getSlot(state),
  addresses: getAllAddresses(state),
  hasAcceptedSlotWarningTypes: getHasAcceptedSlotWarningTypes(
    state,
    CHANGE_DELIVERY_ADDRESS
  ),
  itemCount: getItemsCount(state),
  selectedDate: getSelectedDate(state),
  currentUrl: getCurrentUrl(state),
  slotsCollectionURL: getLanguageLink(state, `/slots/${COLLECTION}/`),
  groceriesWithLanguage: getLanguageLink(state, ''),
  addressChangeURL: getLanguageLink(
    state,
    `/slots/${COLLECTION}/?addressChange=true`
  ),
  locationsExpanded: getLocationsExpanded(state),
  areLocationsPending: getSlotsPendingStatus(state),
  areSlotsPending: getSlotsPendingStatus(state),
  locationsPage: getLocationsPage(state),
  selectedCollectionLocationId: getSelectedCollectionLocationId(state),
  isDesktop: getIsDesktop(state),
  selectedDateSlots: getSelectedDateSlots(state),
  selectedWeekSlots: getSelectedWeekSlots(state),
  selectedShoppingMethod: getSelectedShoppingMethod(state),
  storeId: getStoreId(state),
  locationId: getSelectedLocationId(state),
  addressId: getDeliveryAddressForTrollyId(state),
  timezone: getTimezone(state),
  suggestionSelectedAddressId: getSuggestionSelectedAddressId(state),
  showSearchByAddress: config('showSearchByAddress')
});

const mapDispatchToProps = {
  changeDeliveryAddress,
  showOrderTypeChangeWarning,
  clearSuggestionSelectedAddress,
  selectLocationId,
  updateSlotsForLocation,
  showLocationsPage,
  showLessLocations,
  changeSlotDate
};

@helpers(['c', 't'])
@connect(mapStateToProps, mapDispatchToProps)
export default class SelectedSlotMap extends Component {
  static propTypes = {
    addressChangeURL: PropTypes.string.isRequired,
    addresses: PropTypes.array,
    addressId: PropTypes.string,
    areLocationsPending: PropTypes.bool,
    bookedSlotLocation: PropTypes.object,
    c: PropTypes.func.isRequired,
    changeAddressCookieAccepted: PropTypes.bool,
    changeDeliveryAddress: PropTypes.func.isRequired,
    changeLocationEnabled: PropTypes.bool,
    changeSlotDate: PropTypes.func.isRequired,
    clearSuggestionSelectedAddress: PropTypes.func.isRequired,
    currentSlot: PropTypes.object,
    hasAcceptedSlotWarningTypes: PropTypes.bool.isRequired,
    isDesktop: PropTypes.bool.isRequired,
    itemCount: PropTypes.number.isRequired,
    locationId: PropTypes.string.isRequired,
    locations: PropTypes.array.isRequired,
    locationsExpanded: PropTypes.bool,
    locationsPage: PropTypes.number,
    selectedCollectionLocationId: PropTypes.string,
    selectedDate: PropTypes.object.isRequired,
    selectedDateSlots: PropTypes.array.isRequired,
    selectedLocation: PropTypes.object.isRequired,
    selectedShoppingMethod: SHOPPING_METHOD_PROP_TYPE,
    selectedWeekSlots: PropTypes.object.isRequired,
    selectLocationId: PropTypes.func.isRequired,
    showLessLocations: PropTypes.func.isRequired,
    showLocationsPage: PropTypes.func.isRequired,
    showOrderTypeChangeWarning: PropTypes.func.isRequired,
    showSearchByAddress: PropTypes.bool.isRequired,
    slotsCollectionURL: PropTypes.string.isRequired,
    storeId: PropTypes.string.isRequired,
    suggestionSelectedAddressId: PropTypes.string,
    t: PropTypes.func.isRequired,
    timezone: PropTypes.string.isRequired,
    updateSlotsForLocation: PropTypes.func.isRequired
  };

  static defaultProps = {
    locationsPage: 1,
    locationsExpanded: false
  };

  state = {
    addressSelectType: null
  };

  constructor(props, context) {
    super(props, context);
    const { c: config, t: translate } = this.props;
    this.changeAddressUrl = config('changeAddressUrl');
    this.collectionShowMoreLocation = translate(
      'slots:collection.show-more-locations'
    );
    this.collectionShowLessLocation = translate(
      'slots:collection.show-less-locations'
    );
    this.slotWarningUrl = config('slotWarningUrl');
    this.state = { isListOpen: false };
  }

  locationsActionButton({ formattedDate, className, qs, copy, name }) {
    return (
      <Link
        key={copy}
        className={`button ${className}`}
        href={`${this.props.slotsCollectionURL}${formattedDate}?${qs}#selected-slot-map`}
        onClick={this.locationsListChangeHandler}
        name={name}
      >
        {copy}
      </Link>
    );
  }

  onLocationClick = async (event, locationId) => {
    event.preventDefault();

    const { currentUrl, selectLocationId, updateSlotsForLocation } = this.props;

    const newUrl = updateParamsInUrl(currentUrl, { locationId });

    selectLocationId(locationId);
    updatePageUrlWithoutRefresh(window, newUrl);

    this.setState({
      addressSelectType: LIST_SELECT
    });

    await updateSlotsForLocation(locationId);
    this.emitSlotEvent();
  };

  cookiePreferencesSetForAddressChange() {
    const {
      changeAddressCookieAccepted,
      hasAcceptedSlotWarningTypes
    } = this.props;
    return changeAddressCookieAccepted || hasAcceptedSlotWarningTypes;
  }

  slotBooked() {
    const { currentSlot } = this.props;
    return currentSlot && slotCurrentlyBooked(currentSlot);
  }

  showWarning() {
    const { c: config, itemCount } = this.props;
    return (
      config('showOrderTypeChangeWarning') &&
      itemCount &&
      this.slotBooked() &&
      !this.cookiePreferencesSetForAddressChange()
    );
  }

  handleAddressChange = async event => {
    const {
      clearSuggestionSelectedAddress,
      changeDeliveryAddress,
      showOrderTypeChangeWarning,
      currentUrl
    } = this.props;

    if (this.showWarning()) {
      const changeAddressInfo = {
        addressId: event.target.value,
        modalType: CHANGE_DELIVERY_ADDRESS
      };

      return showOrderTypeChangeWarning(changeAddressInfo);
    }

    // clear suggested selected address, remove addressId from URL, clear session storage
    clearSuggestionSelectedAddress();
    sessionStore.remove(SUGGESTED_ADDRESS);
    updatePageUrlWithoutRefresh(
      window,
      updateParamsInUrl(currentUrl, { addressId: null })
    );

    await changeDeliveryAddress(event.target.value, '', '', null);
    this.emitSlotEvent();
  };

  getSubmitUrl() {
    if (this.showWarning()) {
      return this.props.currentUrl;
    }

    return this.changeAddressUrl;
  }

  getSubmitMethod() {
    if (this.showWarning()) {
      return 'GET';
    }

    return 'POST';
  }

  getCurrentAddressChangeUrl = () => {
    return this.props.addressChangeURL;
  };

  locationsListChangeHandler = event => {
    event.preventDefault();

    const evtTarget = event.target;
    const locationsPerPage = getLocationsPerPage();
    let locationsExpanded, locationId, locationsPage;
    const {
      selectedDate,
      showLocationsPage,
      showLessLocations,
      changeSlotDate,
      selectedLocation: selectedLocationProp,
      locationsExpanded: locationsExpandedProp,
      locationsPage: locationsPageProp,
      locations,
      groceriesWithLanguage // used  only in location list component
    } = this.props;

    if (evtTarget.name === SHOW_MORE_LOCATIONS) {
      locationsExpanded = true;
      locationId = selectedLocationProp.locationId;
      locationsPage = locationsPageProp;
    } else if (evtTarget.name === SHOW_LESS_LOCATIONS) {
      locationsExpanded = false;
      locationId = selectedLocationProp.locationId;
      locationsPage = locationsPageProp;
    } else if (evtTarget.name === SHOW_PREV_LOCATIONS) {
      let prevLocationsPage = getPrevLocationsPage(locationsPageProp);

      locationsExpanded = locationsExpandedProp;
      locationId =
        locations[(prevLocationsPage - 1) * locationsPerPage].locationId;
      locationsPage = prevLocationsPage;
    } else if (evtTarget.name === SHOW_NEXT_LOCATIONS) {
      let totalLocations = locations.length;

      locationsExpanded = locationsExpandedProp;
      locationId = locations[locationsPageProp * locationsPerPage].locationId;
      locationsPage = getNextLocationsPage(totalLocations, locationsPageProp);
    }

    const newUrl =
      updateParamsInUrl(
        `${groceriesWithLanguage}${formatUrl(
          COLLECTION,
          selectedDate,
          locationId
        )}`,
        {
          locationsExpanded: locationsExpanded,
          locationsPage
        }
      ) + '#selected-slot-map';

    if (locationsExpanded) {
      showLocationsPage(parseInt(locationsPage || 1, 10));
    } else {
      showLessLocations();
    }
    changeSlotDate(slotRangeUtils.formatDate(selectedDate));
    updatePageUrlWithoutRefresh(window, newUrl);
  };

  mapPointClickHandler = async (_, locationId) => {
    const {
      selectLocationId,
      updateSlotsForLocation,
      slotsCollectionURL
    } = this.props;
    this.setState({
      addressSelectType: MAP_SELECT
    });
    const url = getLocationLink(slotsCollectionURL, locationId);

    selectLocationId(locationId);
    updatePageUrlWithoutRefresh(window, url);

    await updateSlotsForLocation(locationId);
    this.emitSlotEvent();
  };

  addressSearch = (identifier, showChangeAddressModal) => (
    <AddressSearch
      onChange={this.emitSlotEvent}
      inputId={`address-search-input-${identifier}`}
    >
      <CollectionMultipleAddressSelector
        submitUrl={this.getSubmitUrl()}
        method={this.getSubmitMethod()}
        onChange={event => this.handleAddressChange(event)}
        currentUrl={this.getCurrentAddressChangeUrl}
        identifier={`address-selector-${identifier}`}
        showChangeAddressModal={showChangeAddressModal}
      />
    </AddressSearch>
  );

  emitSlotEvent = () => {
    const {
      isDesktop,
      locationId,
      selectedDateSlots,
      selectedShoppingMethod,
      selectedWeekSlots,
      storeId,
      addressId,
      timezone
    } = this.props;

    emitSlotOp(
      isDesktop ? selectedWeekSlots : selectedDateSlots,
      selectedShoppingMethod,
      storeId,
      locationId,
      addressId,
      timezone
    );
  };

  render() {
    const {
      locationsPage,
      locationsExpanded,
      locations,
      selectedLocation,
      t: translate,
      selectedCollectionLocationId,
      selectedDate,
      bookedSlotLocation,
      areLocationsPending,
      showSearchByAddress,
      suggestionSelectedAddressId
    } = this.props;

    const formattedDate = slotRangeUtils.formatDate(selectedDate);
    const totalLocations = locations.length;
    const locationsPerPage = getLocationsPerPage();
    const expandedLocationsFrom = locationsPerPage * (locationsPage - 1);
    const collapsedLocationsFrom =
      Math.floor(
        (selectedLocation.displayOrder - 1) /
          LOCATIONS_PER_PAGE_IN_COLLAPSED_MODE
      ) * LOCATIONS_PER_PAGE_IN_COLLAPSED_MODE;

    const locationsPaginated = locationsExpanded
      ? locations.slice(
          expandedLocationsFrom,
          expandedLocationsFrom + locationsPerPage
        )
      : locations.slice(
          collapsedLocationsFrom,
          collapsedLocationsFrom + LOCATIONS_PER_PAGE_IN_COLLAPSED_MODE
        );

    const showMoreQs = querystring.stringify({
      locationId: selectedLocation.locationId,
      addressId: suggestionSelectedAddressId,
      locationsExpanded: true,
      locationsPage: locationsPage
    });
    const showMoreLocations =
      !locationsExpanded &&
      this.locationsActionButton({
        formattedDate,
        className: 'selected-slot-map--show-more-button',
        qs: showMoreQs,
        copy: this.collectionShowMoreLocation,
        name: SHOW_MORE_LOCATIONS
      });

    const showLessQs = querystring.stringify({
      locationsExpanded: false
    });
    const showLessLocations =
      locationsExpanded &&
      locationsPage === 1 &&
      this.locationsActionButton({
        formattedDate,
        className: 'selected-slot-map--show-less-button',
        qs: showLessQs,
        copy: this.collectionShowLessLocation,
        name: SHOW_LESS_LOCATIONS
      });

    const paginated = totalLocations > locationsPerPage && locationsExpanded;
    const prevLocationsPage = getPrevLocationsPage(locationsPage);
    const showPrevQsShared = {
      locationsExpanded: true,
      locationsPage: prevLocationsPage
    };
    const showPrevLocations =
      paginated &&
      locationsPage > 1 &&
      this.locationsActionButton({
        locationsExpanded,
        formattedDate,
        className: 'selected-slot-map--show-previous-button',
        qs: querystring.stringify({
          locationId:
            locations[(prevLocationsPage - 1) * locationsPerPage].locationId,
          ...showPrevQsShared
        }),
        copy: translate('slots:collection.show-previous-locations', {
          number: locationsPerPage
        }),
        name: SHOW_PREV_LOCATIONS
      });

    const lastLocationsPage = Math.ceil(totalLocations / locationsPerPage);
    const showNextQsShared = {
      locationsExpanded: true,
      locationsPage: getNextLocationsPage(totalLocations, locationsPage)
    };
    const showNextLocations =
      paginated &&
      locationsPage < lastLocationsPage &&
      this.locationsActionButton({
        locationsExpanded,
        formattedDate,
        className: 'selected-slot-map--show-next-button',
        qs: querystring.stringify({
          locationId: locations[locationsPage * locationsPerPage].locationId,
          ...showNextQsShared
        }),
        copy: translate('slots:collection.show-next-locations', {
          number: getNextLocationsCount(totalLocations, locationsPage)
        }),
        name: SHOW_NEXT_LOCATIONS
      });

    const selectedStoreMapQueryString = generateSelectedStoreMapQueryString(
      bookedSlotLocation,
      formattedDate,
      selectedLocation.locationId,
      suggestionSelectedAddressId
    );

    const actions = totalLocations > 5 && [
      <div
        className="small-only selected-slot-map--buttons"
        key="map-overlay-actions"
      >
        <MobileMapLink
          querystring={selectedStoreMapQueryString}
          text={this.collectionShowMoreLocation}
        />
      </div>,
      <div
        className="hidden-small selected-slot-map--buttons"
        key="list-actions"
      >
        {showMoreLocations}
        {showLessLocations}
        {showPrevLocations}
        {showNextLocations}
      </div>
    ];

    const showChangeAddressModal = this.showWarning();

    return (
      <div
        data-testid="selected-slot-map-synthetics"
        className="selected-slot-map expanded"
        id="selected-slot-map"
      >
        {showSearchByAddress && (
          <div className="selected-slot-map--content-top hidden-large">
            {this.addressSearch('small', showChangeAddressModal)}
          </div>
        )}
        <ReactCSSTransitionGroup
          transitionName="slide"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={500}
          className="selected-slot-map--content"
          component="div"
        >
          {showSearchByAddress && (
            <div className="large-only">
              {this.addressSearch('large', showChangeAddressModal)}
            </div>
          )}
          <LocationList
            key="list"
            addressSelectType={this.state.addressSelectType}
            locations={locationsPaginated}
            selectedLocation={selectedLocation}
            selectedStoreMapQueryString={selectedStoreMapQueryString}
            showSelectedStoreOnMapLink={true}
            searchType={ADDRESS_SEARCH}
            expanded={locationsExpanded}
            onLocationClick={this.onLocationClick}
          />
          {actions}
        </ReactCSSTransitionGroup>
        <div className="selected-slot-map--map-wrapper">
          <Map
            center={{
              latitude: selectedLocation.latitude,
              longitude: selectedLocation.longitude
            }}
            points={locations}
            selectedPoint={selectedCollectionLocationId}
            onPointClick={this.mapPointClickHandler}
            disablePanning={false}
            disableTouchInput={false}
            disableZooming={false}
          />
        </div>
        <SpinnerOverlay isLoading={areLocationsPending} />
      </div>
    );
  }
}
