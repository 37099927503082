import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { setMultiSearchPageNumber } from '#/actions/search-action-creators';
import {
  MULTISEARCH,
  emitMultiSearchEvent,
  storeDataForAnalytics
} from '#/analytics/types/search';
import Link from '#/components/link-check-spa';
import MultiSearchListItem from '#/components/multi-search/multi-search-results-list/multi-search-list-items';
import PageSelector from '#/components/pagination/page-selector';
import MultiPageResultSelector from '#/experiments/oop-1824/components/multi-search-page-selector';
import { MULTI_SEARCH_PILLS_PER_PAGE } from '#/experiments/oop-1824/constants';
import { getShouldShowImprovedMultiSearch } from '#/experiments/oop-1824/selectors';
import { MULTI_SEARCH_PAGE, PAGE_NUMBER } from '#/constants/query-strings';
import helpers from '#/lib/decorators/helpers';
import { connect } from '#/lib/render/connect-deep-compare';
import { updatePageUrlWithoutRefresh } from '#/lib/url/url-utils';
import { getLanguageLink } from '#/reducers/app';
import {
  getMultiSearchListItems,
  getMultiSearchPageNumber,
  getSearchedTerm
} from '#/reducers/search';

const multiSearchItemsPerPage = 8;

const mapStateToProps = state => {
  const showImprovedMultiSearch = getShouldShowImprovedMultiSearch(state);
  const itemsPerPage = showImprovedMultiSearch
    ? MULTI_SEARCH_PILLS_PER_PAGE
    : multiSearchItemsPerPage;
  return {
    itemsPerPage,
    multiSearchEditLink: getLanguageLink(state, '/multi-search'),
    multiSearchList: getMultiSearchListItems(state),
    multiSearchPage: getMultiSearchPageNumber(state),
    searchResultsBaseUrl: getLanguageLink(state, '/multi-search-results'),
    selectedListItem: getSearchedTerm(state),
    showImprovedMultiSearch
  };
};

@helpers(['t'])
@connect(mapStateToProps, { setMultiSearchPageNumber })
export default class MultiSearchResultsList extends React.Component {
  static propTypes = {
    itemsPerPage: PropTypes.number,
    multiSearchEditLink: PropTypes.string.isRequired,
    multiSearchList: PropTypes.arrayOf(PropTypes.string),
    multiSearchPage: PropTypes.number.isRequired,
    searchResultsBaseUrl: PropTypes.string.isRequired,
    selectedListItem: PropTypes.string.isRequired,
    setMultiSearchPageNumber: PropTypes.func.isRequired,
    showImprovedMultiSearch: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired
  };

  static defaultProps = {
    itemsPerPage: 8,
    multiSearchList: []
  };

  constructor(props) {
    super(props);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleListItemClick = this.handleListItemClick.bind(this);
  }

  componentDidMount() {
    this.sendAnalyticsData();
  }

  sendAnalyticsData() {
    const { selectedListItem, multiSearchList = [] } = this.props;
    const searchTermDataForAnalytics = {
      searchTerm: selectedListItem,
      msListLength: multiSearchList.length,
      msListPosition: multiSearchList.indexOf(selectedListItem)
    };

    storeDataForAnalytics(MULTISEARCH, searchTermDataForAnalytics);
  }

  handlePageChange(page, url) {
    this.props.setMultiSearchPageNumber(page);
    updatePageUrlWithoutRefresh(window, url);
  }

  handleListItemClick() {
    emitMultiSearchEvent('edit');
  }

  render() {
    const {
      itemsPerPage,
      multiSearchEditLink,
      multiSearchList,
      multiSearchPage,
      t: translate,
      searchResultsBaseUrl,
      selectedListItem,
      showImprovedMultiSearch
    } = this.props;
    const noOfItems = multiSearchList.length;
    const totalPages = Math.ceil(multiSearchList.length / itemsPerPage);

    const listTitle = showImprovedMultiSearch
      ? translate('search:multi-search.results-list-title')
      : translate('search:multi-search.results-title');
    const text = {
      title: listTitle,
      editListLink: translate('search:multi-search.results-edit-list'),
      paginationLabels: {
        currentPageLabel: 'navigation:current-ms-results-page',
        changePageLabel: 'navigation:go-to-ms-results-page-x'
      }
    };
    const PageSelectorComponent = showImprovedMultiSearch
      ? MultiPageResultSelector
      : PageSelector;

    const resultListClassName = classnames('multi-search-results-list', {
      'multi-search-results-list--pills-design': showImprovedMultiSearch
    });
    const headerClassName = classnames('multi-search-results-list__header', {
      'multi-search-results-list__header--pills-design': showImprovedMultiSearch
    });
    const bodyClassName = classnames('multi-search-results-list__body', {
      'multi-search-results-list__body--pills-design': showImprovedMultiSearch
    });

    if (noOfItems === 0) {
      return null;
    }

    return (
      <div className={resultListClassName} data-auto="multisearch-results-list">
        <div className={headerClassName}>
          <h2 className="multi-search-results-list__title">{text.title}</h2>
          <span className="multi-search-results-list__item-count">
            {translate('search:multi-search.results-count', { noOfItems })}
          </span>
          <Link
            className="multi-search-results-list__edit-link"
            to={multiSearchEditLink}
            onClick={this.handleListItemClick}
            data-auto="multisearch-edit-list-link"
          >
            {text.editListLink}
          </Link>
          <a href="#results" rel="nofollow" className="visually-hidden">
            {translate('search:skip-to-results')}
          </a>
        </div>
        <div className={bodyClassName}>
          <MultiSearchListItem
            itemsPerPage={itemsPerPage}
            selectedListItem={selectedListItem}
            multiSearchPage={multiSearchPage}
            multiSearchList={multiSearchList}
            searchResultsBaseUrl={searchResultsBaseUrl}
            showImprovedMultiSearch={showImprovedMultiSearch}
            setListItemRef={this.setListItemRef}
            scrollItemIntoView={this.scrollItemIntoView}
          />

          <PageSelectorComponent
            currentPageNumber={multiSearchPage}
            displayMode={PAGE_NUMBER}
            pageQueryName={MULTI_SEARCH_PAGE}
            totalPages={totalPages}
            onPageChange={this.handlePageChange}
            paginationLabels={text.paginationLabels}
          />
          <div className="multi-search-results-list__fade" />
        </div>
      </div>
    );
  }
}
