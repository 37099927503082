export const QUANTITY = 'Quantity';
export const WEIGHT = 'Weight';
export const QUANTITY_OR_WEIGHT = 'QuantityOrWeight';
export const CATCH_WEIGHT = 'CatchWeight';
export const WHY_NOT_TRY = 'WhyNotTry';

export const CATCH_WEIGHT_PRODUCTS = 'CatchWeightProducts';
export const VARIABLE_WEIGHT_PRODUCTS = 'VariableWeightProducts';

export const VIEW_AND_BUY_TYPE_OFFERS = 'special offers';
export const VIEW_AND_BUY_TYPE_FAVORITES = 'favourites';
export const TOP_OFFERS = 'Top Offers';

export type DisplayType =
  | typeof QUANTITY
  | typeof WEIGHT
  | typeof QUANTITY_OR_WEIGHT
  | typeof CATCH_WEIGHT
  | typeof WHY_NOT_TRY
  | typeof CATCH_WEIGHT_PRODUCTS
  | typeof VARIABLE_WEIGHT_PRODUCTS
  | typeof VIEW_AND_BUY_TYPE_OFFERS
  | typeof VIEW_AND_BUY_TYPE_FAVORITES
  | typeof TOP_OFFERS;
