import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import Link from '../link-check-spa';

const PaginationButton = props => {
  const classes = classnames('pagination--button', props.className || '', {
    disabled: props.disabled,
    highlight: props.highlight
  });

  const label = props.label || undefined;
  const linkProps = {
    className: classes,
    onClick: !props.disabled ? props.onClickHandler : () => {},
    name: label ? label.replace(/ /g, '-').toLowerCase() : label,
    title: label,
    'aria-label': label
  };

  if (!props.disabled) linkProps.to = props.href;

  return (
    <li className="pagination-btn-holder">
      <Link {...linkProps}>
        <span className={props.icon} aria-hidden="true">
          {props.value}
        </span>
      </Link>
    </li>
  );
};

PaginationButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  highlight: PropTypes.bool,
  href: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.string,
  onClickHandler: PropTypes.func,
  rel: PropTypes.string,
  value: PropTypes.string.isRequired
};

PaginationButton.defaultProps = {
  value: ''
};

export default PaginationButton;
