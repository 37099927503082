import React from 'react';
import PropTypes from 'prop-types';
import page from 'page';
import classnames from 'classnames';
import delimitQuery from '../mixins/delimit-query';
import { removeFilterCategoryFromUrl } from '#/utils/product-filter-utils';
import {
  DIETARY,
  SUPERDEPARTMENT,
  PROMOTION,
  FAVOURITES,
  PRODUCT_SOURCE
} from '#/constants/facet-categories';
import { getClassNameAndWrapperClassName } from '#/experiments/oop-2189/selectors';

function onClick(event, count, url, showAllOption, currentPathname, category) {
  if (count !== 0) {
    const newUrl = showAllOption
      ? removeFilterCategoryFromUrl(category, url, currentPathname)
      : url;

    page(newUrl);
  }

  event.preventDefault();
}

function renderCheckbox(selected) {
  return (
    <span
      className={classnames(
        'checked-status',
        selected
          ? 'icon-checkbox-square-checked'
          : 'icon-checkbox-square-unchecked'
      )}
    />
  );
}

function renderRadio(selected, isDisabled) {
  return (
    <span
      className={classnames(
        'radio-button',
        selected ? 'radio-button--on' : 'radio-button--off',
        isDisabled && 'radio-button--disabled'
      )}
    />
  );
}

function getRenderOption(category, selected, singleSelection, isDisabled) {
  switch (category.toLowerCase()) {
    case DIETARY.toLowerCase():
      return singleSelection ? renderRadio(selected) : renderCheckbox(selected);
    case SUPERDEPARTMENT.toLowerCase():
      return renderRadio(selected);
    case PRODUCT_SOURCE.toLowerCase():
      return renderRadio(selected, isDisabled);
    default:
      return renderCheckbox(selected);
  }
}

function renderCount(count, isBackgroundYellow) {
  let className = 'count';

  if (isBackgroundYellow) {
    className = 'count black_text';
  }

  return (
    count !== null &&
    typeof count !== 'undefined' && (
      <span className={className}> {`(${count})`} </span>
    )
  );
}

function renderLabel(
  name,
  count,
  isFavourite,
  isPromotion,
  isDietary,
  isDisabled,
  variantClassName,
  isProductSource
) {
  let className = classnames('label', { highlight: isPromotion && count });
  let wrapperClassName = 'label-wrapper';

  ({ className, wrapperClassName } = getClassNameAndWrapperClassName(
    variantClassName,
    isPromotion,
    count,
    className,
    wrapperClassName
  ));

  const isBackgroundYellow = wrapperClassName !== 'label-wrapper';

  return (
    <span className={wrapperClassName} aria-disabled={isDisabled}>
      <span
        className={classnames(`${className}`, {
          showFullText: isProductSource
        })}
      >
        {name}
      </span>
      {!(isFavourite || isDietary) && renderCount(count, isBackgroundYellow)}
    </span>
  );
}

const removeFilterCategory = (
  qs,
  filtersToRemove = [],
  isRemoveFilterKey = true
) => {
  const view = (qs.viewAll || '').split(',');
  filtersToRemove.forEach(filter => {
    const key = filter.toLowerCase();
    const index = view.indexOf(key);
    if (index > -1) {
      view.splice(index, 1);
    }
    if (isRemoveFilterKey) {
      delete qs[key];
    }
  });
  if (view.length && view.join(',').length) {
    qs.viewAll = view.join(',');
  } else {
    delete qs.viewAll;
  }
};

const FilterElement = ({
  ariaLabelledBy,
  category,
  count,
  currentUrl,
  filtersToRemove,
  id,
  listIsExpanded,
  name,
  selected,
  showAllOption,
  singleSelection,
  title,
  currentPathname,
  allowMultipleFilterSelections,
  variantClassName
}) => {
  category = category === PRODUCT_SOURCE ? category : category.toLowerCase();

  let qs = delimitQuery.parseQuerystring(currentUrl);

  const superdepartmentKey = SUPERDEPARTMENT.toLowerCase();
  const promotionKey = PROMOTION.toLowerCase();
  const favoritesKey = FAVOURITES.toLowerCase();

  const isPromotion = category === promotionKey;
  const isFavourite = category === favoritesKey;
  const isSuperDeparment = category === superdepartmentKey;

  /*
   GIVEN allowMultipleFilterSelections is false.
     WHEN user selects a special offers filter
        THEN special offer filter should be selected AND category filter should be de-selected (apart from defaul one)
    
    WHEN user selects a category filter
        THEN catogory filter should be selected AND special offers filter should be de-selected
   */
  if (!allowMultipleFilterSelections) {
    removeFilterCategory(qs, filtersToRemove);
  }

  delete qs.page;
  delete qs.offset;

  if (showAllOption) {
    delete qs[category];
  } else if (selected) {
    const isRemoveFilterKey = !qs?.[category]?.includes(',');
    qs = delimitQuery.removeFromQuerystring(qs, category, id);
    removeFilterCategory(qs, [category], isRemoveFilterKey);

    if (isSuperDeparment) {
      delete qs.department;
    }
  } else if (singleSelection) {
    qs[category] = id;
  } else {
    qs = delimitQuery.addToQuerystring(qs, category, id);
  }

  if (!selected && !listIsExpanded) {
    qs = delimitQuery.addToQuerystring(qs, 'viewAll', category.toLowerCase());
  }

  const isDietary = category === DIETARY.toLowerCase() && showAllOption;
  const url = delimitQuery.createHref(currentUrl, qs);
  const isDisabled = count === 0;

  name = isPromotion ? title : name;

  const isProductSource = category === PRODUCT_SOURCE;

  let Tag = 'span';
  let props = {
    className: classnames('filter-element', { disabled: isDisabled }),
    onClick: event =>
      onClick(
        event,
        count,
        url,
        showAllOption,
        currentPathname,
        category,
        selected
      )
  };

  if (!isDisabled) {
    Tag = 'a';
    props = {
      ...props,
      href: url,
      ...(ariaLabelledBy && { 'aria-labelledby': ariaLabelledBy })
    };
  }

  return (
    <Tag {...props} data-auto="filter-option" data-auto-selected={selected}>
      {getRenderOption(category, selected, singleSelection, isDisabled)}
      {renderLabel(
        isPromotion ? title : name,
        count,
        isFavourite,
        isPromotion,
        isDietary,
        isDisabled,
        variantClassName,
        isProductSource
      )}
    </Tag>
  );
};

FilterElement.propTypes = {
  ariaLabelledBy: PropTypes.string,
  category: PropTypes.string,
  count: PropTypes.number,
  currentPathname: PropTypes.string,
  currentUrl: PropTypes.string,
  hasTooltip: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  listIsExpanded: PropTypes.bool,
  name: PropTypes.string,
  renderTooltip: PropTypes.func,
  selected: PropTypes.bool,
  showAllOption: PropTypes.bool,
  singleSelection: PropTypes.bool,
  title: PropTypes.string
};

export default FilterElement;
