import React from 'react';
import Buybox, { ActionLink } from '@beans/buy-box';
import { SECONDARY } from '@ddsweb/constants';
import Link from '#/components/link-check-spa';
import SafelyOutputString from '#/components/safely-output-string';
import { TBuyboxRestOfShelfData } from '#/selectors/beans-product-tile/buybox-data';
import { basicEvent } from '#/analytics/types/basic';
import analyticsBus from '#/analytics/analyticsBus';
import { PRODUCT_TILE, UNAVAILABLE_PRODUCT } from '#/constants/analytics';

type Props = TBuyboxRestOfShelfData & {
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
};

export const RestOfShelfBuybox = (props: Props): JSX.Element => {
  const { accessibilityText, href, restOfShelfText, renderBuyboxActions = undefined } = props;

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    if (props?.onClick) {
      props?.onClick(event);
    }

    basicEvent(analyticsBus, {
      type: 'rest of shelf',
      value: `${PRODUCT_TILE}:${UNAVAILABLE_PRODUCT}`,
      action: 'delay',
    });
  };

  const renderActions = (): JSX.Element => (
    <Link to={href} onClick={handleClick}>
      <ActionLink buttonVariant={SECONDARY}>
        <SafelyOutputString>{restOfShelfText}</SafelyOutputString>
      </ActionLink>
    </Link>
  );

  const actions = renderBuyboxActions || renderActions;

  return (
    <Buybox
      data-auto="rest-of-shelf-buybox"
      aria-label={accessibilityText}
      action={actions({ classes: 'hidden-small' })}
    />
  );
};
