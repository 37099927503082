/**
 * Optimisation in future for Favourite filter - Should disable the filter when there are no results
 * But Since we don't know the binCount of the filter we display the this componet for now
 * The Component
 *  - is used to show the modal popup when there are no results after applying Favourite filter.
 *  - has Error Message and a Button. On click of button it removes the favourite filter and reloads the page.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from '#/lib/render/connect-deep-compare';
import Link from '../../link';
import Modal from '../../shared/modal';
import ModalContent from '../../shared/modal/content';
import helpers from '#/lib/decorators/helpers';
import { removeFilterCategoryFromUrl } from '#/utils/product-filter-utils';
import { closeModal } from '#/actions/ui-action-creators';
import { removeModalFromUrl } from '#/lib/url/modal-utils';
import { removeAllFiltersUrl } from '#/lib/filter-helpers';
import {
  getCurrentPathname,
  getCurrentUrl,
  getLanguageLink
} from '#/reducers/app';
import { isOnFavouritesPage } from '#/lib/favorites-helpers';
import { PROMOTION } from '#/constants/facet-categories';
import { getFavFilterNavigationVariant } from '#/experiments/oop-1662/selectors';
import { getIsFavFilterNavigationDisabled } from '#/experiments/oop-1662/helpers';

function mapStateToProps(state) {
  const currentUrl = getCurrentUrl(state);
  const isFavFilterNavigationDisabled = getIsFavFilterNavigationDisabled(
    getFavFilterNavigationVariant(state)
  );

  return {
    currentUrl,
    currentPathname: getCurrentPathname(state),
    shouldRemoveFiltersFromUrl:
      isOnFavouritesPage(currentUrl) && isFavFilterNavigationDisabled,
    homePageUrl: getLanguageLink(state, '/')
  };
}

@connect(mapStateToProps, { closeModal })
@helpers(['t'])
export default class NoResultModal extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    currentPathname: PropTypes.string.isRequired,
    currentUrl: PropTypes.string.isRequired,
    filter: PropTypes.string,
    shouldRemoveFiltersFromUrl: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    homePageUrl: PropTypes.string
  };

  onClick = () => {
    this.props.closeModal();
  };

  isCategoryRootUrl = (url, urlWithoutFilter) => {
    return url === urlWithoutFilter;
  };

  render() {
    const {
      filter,
      currentUrl,
      currentPathname,
      shouldRemoveFiltersFromUrl,
      t: translate,
      homePageUrl
    } = this.props;

    const filtersToRemove = shouldRemoveFiltersFromUrl ? [PROMOTION] : [];

    const urlWithoutFilter = filter
      ? removeFilterCategoryFromUrl(
          filter,
          currentUrl,
          currentPathname,
          filtersToRemove
        )
      : removeAllFiltersUrl(currentUrl);

    const urlWithoutModalAndFilter = removeModalFromUrl(urlWithoutFilter);

    const redirectUrl = this.isCategoryRootUrl(currentUrl, urlWithoutFilter)
      ? homePageUrl
      : urlWithoutModalAndFilter;

    return (
      <Modal
        className="no-result--modal"
        title={translate('search:no-results-message')}
        showLink={false}
      >
        <ModalContent>
          <div className="modal-actions-button-container">
            <Link
              className="button button-primary small"
              href={redirectUrl}
              onClick={this.onClick}
            >
              {translate('common:ok')}
            </Link>
          </div>
        </ModalContent>
      </Modal>
    );
  }
}
