import { createSelector } from 'reselect';
import { getSelectedShoppingMethod } from '#/reducers/slot';
import { getIsCollectionSlotBookedOrExpired } from '#/selectors/slot';
import { getTrolleyDeliveryAddressId } from '#/selectors/trolley';
import { getDefaultAddress, getAllAddresses } from '#/selectors/addresses';
import { isClickAndCollectShoppingMethod } from '#/lib/shopping-method-util';

export const getDeliveryAddressForTrolly = createSelector(
  getIsCollectionSlotBookedOrExpired,
  getDefaultAddress,
  getTrolleyDeliveryAddressId,
  getAllAddresses,
  (
    isCollectionSlotBookedOrExpired,
    defaultDeliveryAddress,
    trolleyDeliveryAddressId,
    allAddresses
  ) => {
    const deliveryAddressId = isCollectionSlotBookedOrExpired
      ? defaultDeliveryAddress.id
      : trolleyDeliveryAddressId;
    return (
      allAddresses.find(({ id }) => id === deliveryAddressId) ||
      defaultDeliveryAddress
    );
  }
);

export const getDeliveryAddressForTrollyId = state =>
  getDeliveryAddressForTrolly(state)?.id;

export const getIsFulfillableAddress = createSelector(
  getSelectedShoppingMethod,
  getDeliveryAddressForTrolly,
  (selectedShoppingMethod, deliveryAddress) => {
    const isCollectionShoppingMethod = isClickAndCollectShoppingMethod(
      selectedShoppingMethod
    );

    const { isBusinessAddress, isBlockedAddress } = deliveryAddress;
    const isFulfillable =
      !isBlockedAddress && (!isBusinessAddress || isCollectionShoppingMethod);
    return isFulfillable;
  }
);
