import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { generateIdPropFromTitle } from '#/lib/dcs/dcs-helpers';

const ContentTitle = props => {
  const {
    title,
    titleColor,
    subtitle,
    subtitleColor,
    level,
    centered,
    noHorizontalSpacing
  } = props;
  const Tag = level ? `h${level}` : 'h2';

  const uiTitleClass = classnames(
    'ui-c-l--content-title',
    'clear',
    { 'ui-c-l--content-title--centered': centered },
    { 'ui-c-l--content-title--has_subtitle': subtitle },
    { 'ui-c-l--content-title--no__horizontal': noHorizontalSpacing }
  );

  return (
    <div className={uiTitleClass}>
      <Tag {...generateIdPropFromTitle(title)} className="content-title__tag">
        <span className="content-title__txt" style={{ color: titleColor }}>
          {title}
        </span>
      </Tag>
      {subtitle ? (
        <p className="content-title__subtitle" style={{ color: subtitleColor }}>
          {subtitle}
        </p>
      ) : null}
    </div>
  );
};

ContentTitle.propTypes = {
  centered: PropTypes.bool,
  level: PropTypes.number,
  noHorizontalSpacing: PropTypes.bool,
  subtitle: PropTypes.string,
  subtitleColor: PropTypes.string,
  title: PropTypes.string.isRequired,
  titleColor: PropTypes.string
};

export { ContentTitle };
