export const CANCELLED = 'Cancelled';
export const CREATED = 'Created';
export const RESERVED = 'Reserved';
export const EXPIRED = 'Expired';
export const BOOKED = 'Booked';
export const RECEIVED = 'Received';
export const PENDING = 'Pending';
export const AMENDED = 'Amended';
export const FAILED_PAYMENT_COLLECTION = 'FailedPaymentCollection';
export const ALL = 'All'; //used in grapghql query to retrieve all orders i.e previous and pending
export const PREVIOUS = 'Previous';
export const ORDER_DUE = 'OrderDue';
export const ORDER_DUE_WITH_WISMO = 'OrderDueWithWismo';
export const DELIVERY_SCHEDULED = 'DELIVERY_SCHEDULED';
export const NOT_AVAILABLE = 'NOT_AVAILABLE';
export const DELIVERED = 'Delivered';
export const COLLECTED = 'Collected';

/** WISMO Constants */
export enum WISMO_PUNCTUALITY {
  EARLY = 'EARLY',
  DELAYED = 'DELAYED',
  ON_TIME = 'ON_TIME',
}

export enum SUPPORTED_PAYMENT_RETRY_OPTION {
  EXPIRED = 'EXPIRED',
  ENABLED = 'ENABLED',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
}

export enum SUPPORTED_WISMO_TRACKING_STATUS {
  TRIP_FINALIZED = 'TRIP_FINALIZED',
  PREPARING_ORDER = 'PREPARING_ORDER',
  DELIVERY_SCHEDULED = 'DELIVERY_SCHEDULED',
  ARRIVED_AT_CUSTOMER_LOCATION = 'ARRIVED_AT_CUSTOMER_LOCATION',
  DELIVERED = 'DELIVERED',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  CANCELLED = 'CANCELLED',
}

export enum WCC_WISMO_TRACKING_STATUS {
  PENDING = 'Pending',
  READY_TO_COLLECT = 'Readytocollect',
  BRING_BAGS = 'Bringbags',
}

export enum ORDER_PAYMENT_MODE {
  PAY_AT_DOOR_CODE = 'PaymentDoorCard',
  PAY_ONLINE_CODE = 'PaymentOnline',
}
