import React from 'react';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { browserHistory, Router } from 'react-router';
import { applyMiddleware, compose, createStore } from 'redux';
import { enableBatching } from 'redux-batched-actions';
import thunkMiddleware from 'redux-thunk';
import { ThemeProvider } from '@ddsweb/theme';
import { registerApmErrorHandler } from '@peas/apm';
import getAnalytics from '#/analytics/analyticsBus';
import { getDefaultThemeOverrides } from '#/components/styles/default-theme-provider-overrides';
import { initApm, ApmTool, errorHandlerConfig } from '#/lib/apm';
import Helpers from '#/lib/helpers';
import locales from '#/lib/i18n/locales';
import { repopulateResourcesOnState } from '#/lib/render/client';
import { getReduxLogger } from '#/lib/render/redux-logger';
import { defaultTransit } from '#/lib/render/transit-helper';
import reducers from '#/reducers';
import { createElementFactory } from '#/resources/spa-utils';
import createRoutes from '#/routes/spa-routes';
import initSoftNavigationTimings from '#/lib/apm/soft-navigations';
import { isMobileApp } from '#/utils/user-agent-utils';
import { shouldSoftRefresh } from '#/utils/soft-refresh';

export function isAccessTokenExpired(helpers) {
  if (helpers.c('useExternalAuthentication')) {
    const tokenExpiry = document.cookie
      .split('; ')
      .find(row => row.indexOf('OAuth.TokensExpiryTime=') === 0)
      ?.split('=')[1];

    return shouldSoftRefresh({ userAgent: navigator.userAgent, tokenExpiry });
  } else {
    return false;
  }
}

export const checkSoftRefreshOnPageTransitions = ({ helpers, language }) => {
  document.addEventListener(
    'click',
    e => {
      let anchor = e.target;
      while (
        anchor != null &&
        anchor.nodeName !== 'a' &&
        anchor.nodeName !== 'A'
      ) {
        anchor = anchor.parentElement;
      }

      const href = anchor?.getAttribute('href');
      if (href == null || href.startsWith('#')) {
        return;
      }

      let redirectLocation;
      try {
        redirectLocation = new URL(href, window.location.origin);
      } catch {
        return;
      }

      if (
        (redirectLocation.protocol === 'http:' ||
          redirectLocation.protocol === 'https:') &&
        isAccessTokenExpired(helpers)
      ) {
        try {
          // Browser support for this is good, but not every single browser
          // in which case we don't do the auto login
          const url = new URL(
            helpers.c('externalLoginUrl')[language],
            helpers.c('externalOneAccountDomain') ?? window.location.origin
          );
          url.searchParams.set('from', redirectLocation.toString());
          url.searchParams.set('prompt', 'none');

          e.preventDefault();
          e.stopPropagation();
          e.stopImmediatePropagation();
          window.location.assign(url.toString());
        } catch (e) {
          console.error('Failed to perform soft-refresh redirect', e);
        }
      }
    },
    // Ensure we process this event before pagejs does
    { capture: true }
  );
};

const softRefreshIsEnabled = false;
export default () => {
  const helpers = new Helpers(document);
  if (helpers.c('apmTool') === ApmTool.NEWRELIC) {
    initApm(ApmTool.NEWRELIC, true);
    initSoftNavigationTimings();
    registerApmErrorHandler(errorHandlerConfig);
  }

  // Mobile Safari uses a bfcache that may not respond to http cache-control headers.
  // This will force a page reload when the page is being reloaded from cache.
  window.onpageshow = function(event) {
    if (event.persisted) {
      return window.location.reload();
    }
  };

  const attributes = document.body;

  const { props: propsJson, reduxState, language } = attributes.dataset;

  attributes.removeAttribute('data-props');
  attributes.removeAttribute('data-redux-state');

  const alternativeLanguage = attributes.getAttribute(
    'data-alternate-language'
  );

  let props = {};
  let preloadedState;

  if (propsJson && reduxState) {
    try {
      props = JSON.parse(propsJson);
      preloadedState = defaultTransit.fromJSON(reduxState);

      // the resources are not immutable.js objects and so do not work completely reliably
      // with transit. For example props with a value of {} become null after
      // serialisation / deserialisation leading to some seriously confusing bugs :).
      // instead we get the state from the props which are good old fashioned JSON.
      repopulateResourcesOnState(preloadedState, props);
    } catch (e) {
      // At this point something is seriously wrong but catching the error
      // prevents all other JavaScript from stopping as well.
      // eslint-disable-next-line no-console
      console.error(
        'There was an error trying to parse "data-props" or "data-redux-state"'
      );
    }
  }

  locales(attributes.getAttribute('data-language'));

  const languages = [language];

  if (alternativeLanguage) {
    languages.push(alternativeLanguage);
  }

  const middlewares = [thunkMiddleware];

  const reduxLogger = getReduxLogger();
  if (reduxLogger) {
    middlewares.push(reduxLogger);
  }

  if (process.env.NODE_ENV !== 'production') {
    const { freezeMiddleware } = require('./freeze-state-middleware');
    middlewares.push(freezeMiddleware);
  }

  // Create redux store
  const composeEnhancers =
    (process.env.NODE_ENV !== 'production' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;
  const store = createStore(
    enableBatching(reducers),
    preloadedState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  const features = JSON.parse(attributes.getAttribute('data-features') || '{}');
  const f = feature => features[feature];

  const routes = createRoutes(languages, f, helpers.c.bind(helpers));

  if (props.asyncPage && typeof _satellite !== 'undefined') {
    window.asyncPage = true;
    setTimeout(() => {
      getAnalytics().onceTimed(
        'app:asyncPageComplete',
        () => {
          _satellite.pageBottom(); // eslint-disable-line no-undef
        },
        parseInt(helpers.c('waitForAsyncPage'), 10)
      );
    }, 0);
  }

  if (softRefreshIsEnabled && !isMobileApp(navigator.userAgent)) {
    checkSoftRefreshOnPageTransitions({ helpers, language });
  }

  hydrate(
    <ThemeProvider overrides={getDefaultThemeOverrides()}>
      <Provider store={store}>
        <Router
          routes={routes}
          history={browserHistory}
          createElement={createElementFactory(props)}
        />
      </Provider>
    </ThemeProvider>,
    document.getElementById('content')
  );
};
