import styled from 'styled-components';
import { colors, fontFamily, fontSize, spacing } from '@ddsweb/selectors';
import { media } from '@ddsweb/foundation';
import Icon from '@beans/icon';

export const BasketIcon = styled(Icon)`
  margin-right: ${spacing.xs};
`;

export const StickyBarWrapper = styled.div`
  ${media.aboveMobileLarge`
    height: ${props => props.stickyBarHeight}px;
  `};
`;
StickyBarWrapper.displayName = 'stickyBarWrapper';

export const StickyBarCookieWrapper = styled.div`
  position: fixed;
  width: 100%;
  @media (min-width: 768px) {
    bottom: ${props => (props.oop1882bcdeBottom ? 0 : 'auto')};
    top: ${props => (props.oop1882bcdeBottom ? 'auto' : 0)};
  }
`;

export const InnerWrapper = styled.div`
  background: ${props => (props.isDark ? colors.active : colors.backgroundInfo)};
  width: 100%;
  left: 0;
  position: relative;
  .sticky-bar-message {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-right: ${spacing.sm};
    width: 70%;
    height: 100%;
    font-family: ${fontFamily.heading};
    font-size: ${fontSize.xs};
    color: ${props => (props.isDark ? colors.white : colors.heading)};
    .expiry-time {
      color: ${props => (props.isDark ? colors.white : colors.grayscale)};
    }
    @media (min-width: 768px) {
      &.oop1882-cde-cta {
        width: 53%;
      }
    }
  }
  .sticky-bar-message-display-none {
    @media (max-width: 767px) {
      display: none;
    }
  }
  .sticky-bar-controls {
    width: 30%;
    display: flex;
    justify-content: flex-end;
    height: 100%;
    align-items: center;
    font-size: ${fontSize.xs};
    gap: ${spacing.sm};

    &.oop-1882-cde-cta {
      @media (min-width: 768px) {
        margin-left: auto;
      }
    }

    .amend-cancel-button-wrapper {
      width: 100%;
    }
    .amend-cancel-button-wrapper-display-none {
      @media (max-width: 767px) {
        display: none;
      }
    }

    @media (min-width: 768px) {
      a {
        max-width: 200px;
        padding: 0 18px;
      }
    }
  }
  .sticky-bar-content,
  .amend-order-bar-content {
    padding: 0 ${spacing.xl};
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: ${props => (props.hasBasketIcon ? 'none' : '1260px')};
  }
  @media (min-width: 768px) {
    padding: ${spacing.xs} 0;
    border-top: ${props => (props.oop1882bcdeBottom && !props.isDark ? `1px solid ${colors.lines}` : 'none')};
    top: 0;
    border-bottom: ${props => (props.oop1882bcdeBottom ? 'none' : `1px solid ${colors.lines}`)};
  }
  @media (max-width: 767px) {
    background: ${props =>
      props.isDark ? colors.active : props.hideAmendBanner ? colors.white : colors.backgroundInfo};
    transition: transform 1s ease;
    padding: ${spacing.sm} ${spacing.sm};
    bottom: 0;
    z-index: 11;
    width: 100%;
    border-top: 1px solid ${colors.lines};
    .sticky-bar-content {
      width: 100%;
      padding: 0;
      flex-direction: column;
      align-items: flex-start;
    }
    .sticky-bar-message {
      width: 100%;
      padding: 0 0 ${spacing.md};
      flex-direction: column;
      align-items: flex-start;
      min-height: initial;
      .making-changes {
        margin-bottom: ${spacing.xx};
      }
    }
    .sticky-bar-controls {
      width: 100%;
      height: 100%;
      justify-content: center;
      .continue-checkout,
      .checkout-button {
        width: 100%;

        @media (max-width: 555px) {
          margin-left: 0;
        }
      }
      .continue-checkout-m0 {
        @media (max-width: 767px) {
          margin: 0;
        }
      }

      @media (max-width: 555px) {
        flex-direction: column-reverse;
      }
    }
    .sticky-bar-controls.oop-1882-cde-cta {
      @media (max-width: 555px) {
        flex-direction: row;
      }
      @media (max-width: 375px) {
        flex-direction: column-reverse;
      }
    }
  }
`;
InnerWrapper.displayName = 'innerWrapper';
