/**
 * Experiment constants for Home Delivery Slot Matrix Ui improvements
 */

export const hdSlotMatrixUiImprovements = {
  key: '1338',
  variants: {
    default: '1338-a',
    stickyHeader: '1338-b',
    timesOfTheDay: '1338-c',
    stickyHeaderTimesOfTheDay: '1338-d',
  },
  useCustomerId: true,
};

export enum StickyHeaderOffset {
  COOKIE_BANNER = 158,
  AMEND_BASKET = 66,
}

export const timesOfTheDay = {
  MORNING: { label: 'morning', startHour: '08:00' },
  AFTERNOON: { label: 'afternoon', startHour: '12:00' },
  EVENING: { label: 'evening', startHour: '18:00' },
};

export const timesOfTheDayRowClass = 'time-of-day-oop';

export const REGION_IE = 'IE';
