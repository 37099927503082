import { getFeatureVariableStringFromState, updateRegionInKey } from '#/lib/optimizely-manager';
import { SearchContentTileFeature, SearchContentTileVariants } from '#/experiments/oop-2021/constants';
import { Item } from '#/lib/records/item';
import { ContentItem } from '../types';

export const getContentSearchVariant = (state: Store): SearchContentTileVariants => {
  const { featureKey, variationKey, useCustomerId } = SearchContentTileFeature;
  const regionInKey = updateRegionInKey(featureKey, state);
  const featureVariable = getFeatureVariableStringFromState(state, regionInKey, variationKey, { useCustomerId });

  return featureVariable || SearchContentTileVariants.DISABLED;
};

export const getShouldShowContentTile = (state: Store): boolean =>
  getContentSearchVariant(state) === SearchContentTileVariants.ENABLED;

export const getContentId = (item: Item | ContentItem): string => item?.content?.id as string;
