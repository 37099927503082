import { addApmData } from '#/lib/apm';
import { transformBasketItem } from '#/lib/records/product-utils';

export default function handleTrolleyResponse(trolley) {
  if (trolley) {
    addApmData('amend_mode', String(trolley.isAmendBasket));
    addApmData('shoppingMethod', String(trolley.shoppingMethod));
  }

  return transformBasketItem(trolley);
}
