/* ------------------------------------------------------------------------------------------
|   Constants for Experiments
|
|   Going forward use the below format for experiment constants to avoid confusion
|   and for use in any functions that require the keys / values
|
|   Notes:
|     - The const name should NOT have the experiment number in, use a name (e.g. sideBasketExperiment)
|     - Variant names should NOT have the experiment number in, use a name (e.g. hiddenSideBasket)
|     - useCustomerId should ALWAYS be put in EVEN if false and EVEN if unused, it helps to keep it obvious
|
|   Example:
|
|   export const commonNameForExperimentNotNumber = {
|     key: 'oopXXX',
|     variants: {
|       default: '403-a',
|       commonNameForVariantNotNumber: '403-b'
|     },
|     useCustomerId: true
|   };
|
*--------------------------------------------------------------------------------------------
*/

export const favoritesInterruptFeature = {
  featureKey: 'GHS-<region>_dcs_favinterrupt',
  experimentKey: 'oop413',
  useCustomerId: true,
  serviceConfig: {
    params: {
      experimentId: null,
      variantId: null
    }
  }
};

export const buylistBottomRecommenders = {
  key: 'oop547',
  variants: {
    default: '547-a',
    enabled: '547-b'
  },
  useCustomerId: false
};

export const firstTimeShopper = {
  key: 'oop550',
  variants: {
    default: '550-a',
    tooltipMessageB: '550-b',
    tooltipMessageC: '550-c'
  },
  useCustomerId: true,
  getMessageType: function(variant) {
    switch (variant) {
      case this.variants.tooltipMessageB:
        return 'b';
      case this.variants.tooltipMessageC:
        return 'c';
      default:
        return null;
    }
  },
  getCookieKey: customerId => `fts-tooltip_${customerId}`,
  cookieExpiry: 21 * 24 * 3600 // 21 days
};

export const experimentsDefaultData = { revision: 'NO_DATA' };

export const browseFeature = {
  featureKey: 'GHS-<region>_browse',
  variationKey: 'browse_config',
  serviceConfig: {
    params: {
      browse_config: 'default'
    }
  },
  useCustomerId: true
};

/*
 * Experiment constants for resources/search.js - mango search api.
 */
export const searchFeature = {
  featureKey: 'GHS-<region>_search',
  variationKey: 'search_config',
  serviceConfig: {
    params: {
      search_config: 'default',
      search_engine: 'default'
    }
  },
  useCustomerId: true
};

export const searchOnDemandFeature = {
  featureKey: 'GHS-<region>_search-2',
  variationKey: 'search_config',
  serviceConfig: {
    params: {
      search_config: 'default',
      search_engine: 'default'
    }
  },
  useCustomerId: true
};

/*
 * Experiment constants for ProductTile Variants on Carousel component
 */
export const ghsCarouselSpaceOptimizationExperiment = {
  key: 'oop661',
  variants: {
    default: '661-a',
    showReviewRestOfShelfAndSplOffer: '661-b',
    showSpecialOffer: '661-c'
  },
  useCustomerId: false
};

export const specialOffersPromoVariants = {
  key: 'oop638',
  variants: {
    default: '638-a',
    DDLPromoWithSash: '638-b',
    DDLPromoWithNoSash: '638-c'
  },
  useCustomerId: false
};

export const checkoutPromotionRecommendationsFeature = {
  featureKey: 'GHS-UK_checkout-recommendations',
  variationKey: 'types',
  serviceConfig: {
    params: {
      types: null
    }
  },
  useCustomerId: true
};

export const featureVariableDataType = {
  string: 'string',
  json: 'json'
};

export const favCSATConfig = {
  featureKey: 'GHS-UK_favouriteFeedback-<page>',
  useCustomerId: true,
  keys: ['type', 'title', 'cta']
};
