import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { generateIdPropFromTitle } from '#/lib/dcs/dcs-helpers';

const TradeTableSmall = props => {
  const { style, tableData } = props;

  /* Set table headings */
  const tableDataHead =
    tableData &&
    tableData.headings &&
    tableData.headings.map((dataHeading, index) => (
      <th
        {...generateIdPropFromTitle(dataHeading)}
        key={generateIdPropFromTitle(dataHeading)?.id || index}
        style={style?.heading}
      >
        {dataHeading}
      </th>
    ));

  /* Set table Body */
  const tableDataInner =
    tableData &&
    tableData.rows &&
    tableData.rows.map((dataRow, index) => {
      const tdDataRow = dataRow.map((tdRow, tDindex) => {
        const curStyle = index % 2 ? style && style.tr1 : style && style.tr2;

        return (
          <td key={tDindex} style={curStyle}>
            {tdRow[tableData.headings[tDindex]]}
          </td>
        );
      });

      return <tr key={index}>{tdDataRow}</tr>;
    });

  const tableClasses = classnames('ui-trade-table-small', {
    'ui-trade-table-mobile_resize':
      tableData && tableData.headings && tableData.headings.length > 2
  });

  /* Render the main component */
  return (
    <table className={tableClasses}>
      <thead>
        <tr>{tableDataHead}</tr>
      </thead>
      <tbody>{tableDataInner}</tbody>
    </table>
  );
};

TradeTableSmall.propTypes = {
  style: PropTypes.object,
  tableData: PropTypes.object
};

export { TradeTableSmall };
