import React from 'react';
import List from '.';
import { connect } from '#/lib/render/connect-deep-compare';
import { getInterrupts } from '#/reducers/interrupts';
import { FAVORITES } from '#/constants/tile-types';

const mapStateToProps = (state, ownProps) => {
  const props = {};

  if (ownProps.tileType === FAVORITES) {
    props.interrupts = getInterrupts(state);
  }

  return props;
};

const ListContainer = props => <List {...props} />;

export default connect(mapStateToProps)(ListContainer);
