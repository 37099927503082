import { getVariationForExperiment } from '#/lib/optimizely-manager';
import { cncMatrixImprovementsVariants } from '../constants';
import { isClickAndCollectShoppingMethod } from '#/lib/shopping-method-util';
import { getSelectedShoppingMethod } from '#/reducers/slot';

const { key, useCustomerId, variants } = cncMatrixImprovementsVariants;

const getVariant = (state: Store): string =>
  getVariationForExperiment(state, key, {}, useCustomerId) || variants.default;

export const getIsStickyHeaderSlotCellsUiUpdateVariant = (state: Store): boolean => {
  if (!getIsClickAndCollect(state)) {
    return false;
  }

  const variant = getVariant(state);
  const { stickyHeaderSlotCellsUiUpdate, stickyHeaderSlotCellsUiUpdateHyphen } = variants;
  return [stickyHeaderSlotCellsUiUpdate, stickyHeaderSlotCellsUiUpdateHyphen].includes(variant);
};

export const getIsHyphenVariant = (state: Store): boolean => {
  if (!getIsClickAndCollect(state)) {
    return false;
  }

  const variant = getVariant(state);
  const { hyphen, stickyHeaderSlotCellsUiUpdateHyphen } = variants;
  return [hyphen, stickyHeaderSlotCellsUiUpdateHyphen].includes(variant);
};

export const getIsClickAndCollect = (state: Store): boolean =>
  isClickAndCollectShoppingMethod(getSelectedShoppingMethod(state));
