import React from 'react';
import PropTypes from 'prop-types';
import { connect } from '#/lib/render/connect-deep-compare';
import CreateContext from '#/components/create-context';
import BodyClassNames from '#/components/body-class-names';
import AnalyticsSiteEvent from '#/components/analytics/site-event';
import AnalyticsCustomerEvent from '#/components/analytics/customer-event';
import AnalyticsBasketEvent from '#/components/analytics/basket-event';
import pageLoadedAnalyticsEvent from '#/analytics/types/page';
import ErrorBoundary from '#/components/error-boundary';
import { extractPropsFromWrappedResources } from '#/resources/spa-utils';
import { getLanguage, getAppRegion } from '#/reducers/app';
import ApmPageAttributes from '#/components/analytics/apm-page-attributes';
import { getIsFiveTileEnabledOnPage } from '#/selectors/app';

const mapStateToProps = state => ({
  language: getLanguage(state),
  region: getAppRegion(state),
  isFiveTileEnabled: getIsFiveTileEnabledOnPage(state)
});

/**
 * Topmost component of the application. Responsible for creating the main
 * context that runs through the application and for rendering the correct layout.
 */
@connect(mapStateToProps)
export default class App extends React.Component {
  static propTypes = {
    analyticsBus: PropTypes.object,
    breadcrumbs: PropTypes.array,
    children: PropTypes.node.isRequired,
    helpers: PropTypes.object,
    hideCookiePolicy: PropTypes.bool,
    isFiveTileEnabled: PropTypes.bool,
    language: PropTypes.string.isRequired,
    pendingOrders: PropTypes.array,
    region: PropTypes.string.isRequired,
    specialOfferType: PropTypes.string,
    trolley: PropTypes.object,
    trolleyPayloadSent: PropTypes.object,
    user: PropTypes.object
  };

  sendPageLoadedEvent() {
    const { language, region, resources } = this.props;
    const { hostname, pathname = '', search = '' } = window.location;
    const breadcrumbs =
      extractPropsFromWrappedResources('breadcrumbs', resources) || [];
    const pageTitle =
      extractPropsFromWrappedResources('pageTitle', resources) ||
      document.title;

    pageLoadedAnalyticsEvent({
      pageURL: `${hostname}${pathname}${search}`,
      pageLanguage: language,
      breadcrumbs,
      pageTitle,
      region
    });
  }

  componentDidMount() {
    this.sendPageLoadedEvent();
  }

  render() {
    const {
      helpers,
      children,
      analyticsBus,
      isFiveTileEnabled,
      ...pageProps
    } = this.props;
    const className = isFiveTileEnabled ? 'support-xxl-viewport' : '';
    return (
      <>
        <BodyClassNames>
          <CreateContext helpers={helpers} analyticsBus={analyticsBus}>
            <ErrorBoundary>
              <div className={className}>
                {React.cloneElement(children, pageProps)}
                {typeof window !== 'undefined' && !window.isSPA && (
                  <div>
                    <ApmPageAttributes isErrorPage={this.props.error} />
                    <AnalyticsSiteEvent />
                    <AnalyticsCustomerEvent />
                    <AnalyticsBasketEvent {...pageProps} />
                  </div>
                )}
              </div>
            </ErrorBoundary>
          </CreateContext>
        </BodyClassNames>
      </>
    );
  }
}
