import React, { PureComponent } from 'react';
import classnames from 'classnames';
// @ts-ignore
import ResponsiveImage from '@ddsweb/responsive-image';
import { connect } from '#/lib/render/connect-deep-compare';
import { getObserver } from '#/lib/browser/intersection-observer';
import { getCutsMustard } from '#/reducers/app.js';
import Link from '#/components/link-check-spa';

const srcPlaceholder = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
const mapStateToProps = (state: any) => ({
  cutsMustard: getCutsMustard(state),
});

type TOwnProps = {
  isUnavailable: boolean;
  image?: string;
  alt: string;
  hideFromScreenReader: boolean;
  href: string;
  clickable: boolean;
  onClick: Function;
};

type TProps = TOwnProps & ReturnType<typeof mapStateToProps>;
class BeansLazyImage extends PureComponent<TProps, any> {
  state = {
    isVisible: !this.props.cutsMustard,
  };
  observer: any;
  image: any;

  componentDidMount() {
    if (!this.state.isVisible) {
      this.observer = getObserver(this.intersection);
      this.observe();
    }
  }

  componentWillUnmount() {
    if (this.observer) {
      this.observer.disconnect();
      this.observer = null;
    }
  }

  intersection = (images: Array<{ intersectionRatio: number }>) => {
    // Are we in viewport?
    if (this.observer && images[0].intersectionRatio > 0) {
      // Stop watching and load the image
      this.observer.disconnect();
      this.load();
    }
  };

  observe() {
    if (this.observer) {
      this.observer.observe(this.image);
    } else {
      this.load();
    }
  }

  load() {
    this.setState({
      isVisible: true,
    });
  }

  handleClick = () => {
    const { onClick } = this.props;
    if (onClick) {
      onClick();
    }
  };

  hasImage = (src: string | string[]) => src && src.indexOf('/noimage') === -1;
  getImageSrc() {
    const { isVisible } = this.state;
    const { image } = this.props;
    let src = image || '';
    let srcSet = '';

    //  90x90 doesn't have a 'no image'
    if (this.hasImage(src)) {
      const smallSrc = src.replace(/_135x135|_225x225/, '_90x90');
      src = !isVisible ? srcPlaceholder : src.replace('_135x135', '_225x225');

      srcSet = `${smallSrc} 768w, ${src} 4000w`;
    }

    return { src, srcSet };
  }

  getDomRef = (node: HTMLDivElement) => (this.image = node);

  renderImage() {
    const { isUnavailable, alt } = this.props;
    const { src, srcSet } = this.getImageSrc();
    const className = classnames({
      'product-image': true,
      grayscale: isUnavailable,
    });
    return (
      <div className="product-image__container">
        <ResponsiveImage
          domRef={this.getDomRef}
          src={src}
          srcSet={srcSet}
          className={className}
          alt={alt}
          minHeight="100%"
        />
      </div>
    );
  }

  render() {
    const image = this.renderImage();
    const { hideFromScreenReader, href, clickable } = this.props;

    // This ensures that `aria-hidden` isn't serialised if false (as its unnecessary code)
    const fromScreenReader = hideFromScreenReader || undefined;

    return href && clickable ? (
      <Link
        onClick={this.handleClick}
        aria-hidden={fromScreenReader}
        className="product-image-wrapper"
        to={href}
        tabIndex={-1}
      >
        {image}
      </Link>
    ) : (
      <span aria-hidden={fromScreenReader} className="product-image-wrapper">
        {image}
      </span>
    );
  }
}
export default connect(mapStateToProps)(BeansLazyImage);
