import React from 'react';
import PropTypes from 'prop-types';
import Link from '#/components/link-check-spa';
import helpers from '#/lib/decorators/helpers';

const TextLink = ({ textKey, url, t, titleKey, onClick }) => {
  let otherProps = {};

  if (onClick) otherProps.onClick = onClick;

  return (
    <div className="text-link">
      <Link
        to={url}
        target="_blank"
        rel="noopener"
        title={t(titleKey)}
        {...otherProps}
      >
        <span className="text-link--text">{t(textKey)}</span>
        <span className="icon icon-chevron_right" />
      </Link>
    </div>
  );
};

TextLink.propTypes = {
  onClick: PropTypes.func,
  t: PropTypes.func.isRequired,
  textKey: PropTypes.string.isRequired,
  titleKey: PropTypes.string,
  url: PropTypes.string.isRequired
};

TextLink.defaultProps = {
  titleKey: 'common:new-window'
};

export default helpers(['t'])(TextLink);
