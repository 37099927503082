import styled from 'styled-components';
import { ContentCarousel } from '@ddsweb/carousel';
import { spacing } from '@ddsweb/selectors';

export const CarouselWrapper = styled(ContentCarousel)`
    padding-bottom: ${spacing.sm};

    & ul.ddsweb-carousel__items-container {
      width: unset;
    }
    
    & li.ddsweb-carousel__item {
      width: unset;
      margin-right: ${spacing.xs};
      margin-left: 0;

      &:first-child {
        padding-left: 6px;
        margin-left: ${spacing.sm};
      }
    }

    .ddsweb-carousel__item {
      &__item {
        width: unset;
      }
    }
  }
`;

export const StyledCategoryChips = styled.div`
  display: flex;
  flex-flow: row wrap;
  padding-bottom: ${spacing.xl};
  & a.image-button {
    margin-right: ${spacing.xs};
    margin-bottom: ${spacing.xs};
  }
`;
