import { QUANTITY, QUANTITY_OR_WEIGHT } from '#/constants/display-types';
import { EPW_WITHOUT_AMEND_AVAILABILITY_OVERRIDE } from '#/constants/unavailable-product-type';
import { Items } from '#/custom-typings/redux-store/trolley.defs';
import { Item } from '#/lib/records/item';
import {
  getProductIsForSale,
  isInBasketAndExcluded,
  getProductStatus,
  getIsNewlyAdded,
  getPieces,
  getProductDisplayType,
} from '#/selectors/item';

/**
 * Returns the total quantity of products in the map, accounting
 * for products purchased per kg / unit
 * @param items Map of items
 * @param isAmendBasket
 */
export default function getTotalQuantityOfProducts(items: Items | Array<Item>, isAmendBasket = false): number {
  let total = 0;

  items.forEach((item: Item) => {
    const isAddToTotal = !(
      (isAmendBasket && !getProductIsForSale(item) && getIsNewlyAdded(item) && isInBasketAndExcluded(item)) ||
      (isAmendBasket && getProductStatus(item) === EPW_WITHOUT_AMEND_AVAILABILITY_OVERRIDE) ||
      (!isAmendBasket && !getProductIsForSale(item))
    );

    if (isAddToTotal) {
      if ([QUANTITY, QUANTITY_OR_WEIGHT].includes(getProductDisplayType(item))) {
        total += getPieces(item);
      } else {
        total += 1;
      }
    }
  });

  return total;
}
