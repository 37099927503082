import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { request } from '#/lib/client-fetch';
import SafeForm from '#/components/shared/safe-form';
import helpers from '#/lib/decorators/helpers';
import { closeCookiePolicy } from '#/actions/app-actions-creators';
import {
  getHasAcceptedCookiePolicy,
  getLanguage,
  getFullCurrentUrl
} from '#/reducers/app';
import { connect } from '#/lib/render/connect-deep-compare';
import SafelyOutputString from '#/components/safely-output-string';
import { getDisplayCookieInterrupt } from '#/reducers/ui';
import { updateParamsInUrl } from '#/lib/url/url-utils';
import { StyledCookiesNotification } from '#/components/shared/cookie-policy/styled';

const COOKIE_NAME = 'cookiesAccepted';

const mapStateToProps = state => ({
  cookiePolicyAccepted: getHasAcceptedCookiePolicy(state),
  language: getLanguage(state),
  displayCookieInterrupt: getDisplayCookieInterrupt(state),
  currentUrl: getFullCurrentUrl(state)
});

@connect(mapStateToProps, { closeCookiePolicy })
@helpers(['c', 'l', 't'])
export default class CookiePolicy extends PureComponent {
  static propTypes = {
    c: PropTypes.func.isRequired,
    closeCookiePolicy: PropTypes.func.isRequired,
    cookiePolicyAccepted: PropTypes.bool.isRequired,
    currentUrl: PropTypes.string.isRequired,
    displayCookieInterrupt: PropTypes.bool.isRequired,
    l: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    onClose: PropTypes.func,
    t: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this._onSubmit = this._onSubmit.bind(this);
    this.cookieValue = Date.now();
    this.state = {
      preservedReferrer: ''
    };
  }

  componentDidMount() {
    const { c: config, cookiePolicyAccepted } = this.props;

    if (
      !cookiePolicyAccepted &&
      !config('cookiePreferences') &&
      !!config('shouldShowCookieBanner')
    ) {
      this._setCookie();
    }
    if (typeof window !== undefined) {
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({ preservedReferrer: document.referrer });
    }
  }

  getDescription() {
    const helpLink = this.props.c(
      'links:privacyCookies:'.concat(this.props.language)
    );
    const privacyCookiePolicyLink = this.createLink(
      helpLink,
      this.props.t('home:cookie-policy.privacy-cookie-policy')
    );
    const cookiesSimilarTechnologiesLink = this.createLink(
      helpLink,
      this.props.t('home:cookie-policy.cookies-similar-technologies')
    );

    return this.props.t('home:cookie-policy.description', {
      privacyCookiePolicyLink,
      cookiesSimilarTechnologiesLink
    });
  }

  createLink(url, description, shouldAddReturnUrl) {
    if (shouldAddReturnUrl) {
      let { currentUrl } = this.props;
      const preservedReferrer = this.state.preservedReferrer;
      url = updateParamsInUrl(url, {
        returnUrl: currentUrl,
        ...(preservedReferrer && {
          preservedReferrer
        })
      });
    }
    return `<a href="${url}">${description}</a>`;
  }

  getBodyContent() {
    const { c: config, language, t: translate } = this.props;
    const privacyLink = config('links:privacyCookies:'.concat(language));
    const manageLink = config(
      'links:manageCookiePreferences:'.concat(language)
    );
    const privacyPolicyLink = this.createLink(
      privacyLink,
      translate('common:cookie-preferences-banner.cookies-policy')
    );
    const manageCookiesLink = this.createLink(
      manageLink,
      translate('common:cookie-preferences-banner.manage-cookies'),
      true
    );

    return translate('common:cookie-preferences-banner.body', {
      manageCookiesLink,
      privacyPolicyLink
    });
  }

  renderCookiePreferencesBanner() {
    let { language, c: config, currentUrl, t: translate } = this.props;
    const manageCookiePrefsUrl = config(
      'links:manageCookiePreferences:'.concat(language)
    );
    const preservedReferrer = this.state.preservedReferrer;
    const linkButtonHref = updateParamsInUrl(manageCookiePrefsUrl, {
      returnUrl: currentUrl,
      ...(preservedReferrer && {
        preservedReferrer
      })
    });
    const setCookiePrefsUrl = config(
      'links:setCookiePreferences:'.concat(language)
    );
    const submitButtonUrl = updateParamsInUrl(setCookiePrefsUrl, {
      returnUrl: currentUrl
    });

    const renderProps = {
      headline: translate('common:cookie-preferences-banner.headline'),
      linkButtonText: translate(
        'common:cookie-preferences-banner.manage-cookies'
      ),
      linkButtonHref,
      rejectCookiesButtonText: translate(
        'common:cookie-preferences-banner.reject-cookies'
      ),
      preservedReferrer,
      submitButtonText: translate(
        'common:cookie-preferences-banner.accept-cookies'
      ),
      showLinkButton: false,
      showRejectCookiesButton: true,
      submitButtonUrl
    };

    return (
      <StyledCookiesNotification {...renderProps}>
        <SafelyOutputString>{this.getBodyContent()}</SafelyOutputString>
      </StyledCookiesNotification>
    );
  }

  render() {
    let contents = [];
    const {
      c: config,
      cookiePolicyAccepted,
      displayCookieInterrupt,
      l: language,
      t: translate
    } = this.props;

    if (!config('cookiePreferences')) {
      if (!!config('shouldShowCookieBanner') && !cookiePolicyAccepted) {
        contents = (
          <div>
            <section className="cookie-policy">
              <SafeForm
                action={language('/set-cookie?_method=PUT')}
                method="POST"
                onSubmit={this._onSubmit}
              >
                <div className="cookie-policy-content">
                  {!config('hideCookiePolicyHeader') && (
                    <span className="cookie-policy-header">
                      {translate('home:cookie-policy.title')}
                    </span>
                  )}
                  <span>
                    <SafelyOutputString>
                      {this.getDescription()}
                    </SafelyOutputString>
                  </span>
                </div>
                <input name="cookieName" type="hidden" value={COOKIE_NAME} />
                <input
                  name="cookieValue"
                  type="hidden"
                  value={this.cookieValue}
                />
                <button className="cookie-policy__button" type="submit">
                  <span className="cookie-policy__close-label">
                    {translate('common:accept-cookie-label')}
                  </span>
                  <span className="icon-close-white" />
                </button>
              </SafeForm>
            </section>
          </div>
        );
      }
    } else if (displayCookieInterrupt) {
      contents = this.renderCookiePreferencesBanner();
    }

    return (
      <ReactCSSTransitionGroup
        transitionEnterTimeout={500}
        transitionLeaveTimeout={500}
        transitionName="slide"
      >
        {contents}
      </ReactCSSTransitionGroup>
    );
  }

  _onSubmit(event) {
    event.preventDefault();

    this.props.closeCookiePolicy();

    if (this.props.onClose) {
      this.props.onClose();
    }

    return false;
  }

  _setCookie() {
    request.post(this.props.l('/set-cookie?_method=PUT'), {
      body: JSON.stringify({
        cookieName: COOKIE_NAME,
        cookieValue: this.cookieValue
      })
    });
  }
}
