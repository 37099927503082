import React, { PureComponent } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from '#/lib/render/connect-deep-compare';
import helpers from '#/lib/decorators/helpers';
import { lockBodyScroll, openFilterMenu } from '#/actions/ui-action-creators';
import { updateCancelUrl } from '#/actions/filter-action-creators';
import analyticsBus from '#/analytics/analyticsBus';
import { getCurrentUrl } from '#/reducers/app';

const mapStateToProps = state => ({
  currentUrl: getCurrentUrl(state)
});

@helpers(['t'])
@connect(mapStateToProps, { lockBodyScroll, openFilterMenu, updateCancelUrl })
export default class FilterByMobile extends PureComponent {
  static propTypes = {
    count: PropTypes.number.isRequired,
    currentUrl: PropTypes.string.isRequired,
    lockBodyScroll: PropTypes.func.isRequired,
    openFilterMenu: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    updateCancelUrl: PropTypes.func.isRequired
  };

  fireOnClickAnalyticsEvent = () => {
    analyticsBus().emit('UIEventBasicEvent', {
      action: 'now',
      type: 'sort and filter',
      value: 'mweb'
    });
  };

  onClick = evt => {
    evt.preventDefault();
    this.props.openFilterMenu();
    this.fireOnClickAnalyticsEvent();
    this.props.lockBodyScroll();
    this.props.updateCancelUrl(this.props.currentUrl);
  };

  render() {
    return (
      <div className={classNames('filter-by-mobile')}>
        <a
          data-auto="filter-by-mobile-button"
          className="button button-secondary small"
          href="#sort-n-filter"
          onClick={this.onClick}
        >
          <span>{this.props.t('sort-and-filter:sort-and-filter')}</span>
          <span className="items-count" data-auto="items-count">
            {' (' + this.props.count + ')'}
          </span>
        </a>
      </div>
    );
  }
}
