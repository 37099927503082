export const GRID = 'grid';
export const LIST = 'list';
export const SLOT_VIEW_MODE_URL_PARAM = 'slotViewMode';
export const RETURN_URL = 'returnUrl';

export enum SlotViewModes {
  SLOT_RECOMMENDER = 'slotRecommender',
  SLOT_RECOMMENDER_TILE = 'slotRecommenderTile',
  GRID = 'grid',
  LIST = 'list',
}

//  UIEventBasicEvent analytics type & type value
export const HIGHLIGHT_SLOT = 'highlight slot';
export const HIGHLIGHT_SLOT_IMPRESSION = 'highlight slot:impression';
export const NEXT_AVAILABLE = 'next available';
export const LAST_BOOKED = 'last booked';
export const BEST_VALUE = 'best value';

export const SLOT_BEFORE_DISCOUNT_PRICE = 'slotBeforeDiscountPrice';
