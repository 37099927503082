/**
 * This file serves an important part in Slots page flow and functionality.
 * Whenever making changes to this file make sure to update the related
 * documentation diagrams present in dir: web/docs/slots
 *
 * @author beetlejuice
 */

import moment, { Moment } from 'moment-timezone';
import { createSelector, OutputSelector } from 'reselect';
import produce from 'immer';
import {
  byWeek as rangeByWeek,
  createSlotRange,
  formatDate,
  getFirstDayOfWeekRangeForDay,
  getWeekRangeForDay,
} from '#/lib/slot/slot-range-utils';
import {
  sanitiseDate,
  getSlotTimeIsoWeekday,
  getFirstAvailableSlotForDay,
  hasPendingOrderWithSameAddressForDate,
  hasAvailableSlots,
  getSlotTimesInUtcWithTimezone,
} from '#/lib/slot/slot-utils';
import {
  RECEIVE_FULFILMENT_OPTIONS_DETAIL,
  RECEIVE_FULFILMENT_ESTIMATED_ARRIVAL,
  NEW_SLOT_DATA_PENDING,
  NEW_SLOT_DATA,
  CHANGING_ADDRESS,
  NEW_SLOT_DATA_FROM_RESOURCES,
  CHANGE_SLOT_TYPE,
  CLOSE_CHANGE_SLOT_TYPE,
  CHANGE_SLOT_DATE,
  CHANGE_SHOPPING_METHOD,
  NO_SLOT_DATA_PENDING,
  LOCATION_SELECTED,
  SHOW_LOCATIONS_PAGE,
  SHOW_FEWER_LOCATIONS,
  SEARCH_LOCATIONS_BY_SUGGESTION_ADDRESS,
  CLEAR_SUGGESTION_SELECTED_ADDRESS,
  UPDATE_SLOT_GROUP,
  UPDATE_SELECTED_SLOT_GROUP,
  RESET_SELECTED_LOCATION,
  SYNC_SELECTED_ACTUAL_VALUES,
  CHANGING_ADDRESS_SUCCESS,
  CLOSE_CHANGING_ADDRESS_SUCCESS_BANNER,
  CLEAR_COLLECTION_SELECTED_LOCATION,
  DISMISS_DS_ACQUISITION_BANNER,
} from '#/constants/action-types';
import { getFormattedDate } from '#/lib/i18n/date-utils';
import { AVAILABLE } from '#/constants/slot-statuses';
import { DELIVERY, COLLECTION, ON_DEMAND, ShoppingMethod } from '#/constants/shopping-methods';
import { getCurrentUrl, getTimezone, getLanguage } from './app';
import { getBookedSlot, getTrolleyShoppingMethod, getTrolleyDeliveryAddress } from '#/selectors/trolley';
import {
  getDeliverySaverApplicableDays,
  hasFreeSameDayDelivery,
  isAnytimeDeliverySaverPlan,
} from '#/reducers/customer';
import { getLocations } from '#/reducers/location';
import { updateParamsInUrl } from '#/lib/url/url-utils';
import { FULFILMENT_METADATA, FULFILMENT_OPTIONS_DETAIL, SLOT, TROLLEY_CONTENTS } from '#/constants/spa-resource';
import { getSelectedLocationPage } from '#/lib/slot/locations';
import { getPendingOrdersByDate } from '#/selectors/order-list-details';
import {
  isCollectionShoppingMethod,
  isDeliveryShoppingMethod,
  isOnDemandShoppingMethod,
} from '#/lib/shopping-method-util';
import { getAvailableShoppingMethods } from '#/selectors/available-shopping-methods';
import {
  ChangeSlotInfo,
  FulfilmentOptionsDetail,
  Ui,
  Options,
  SlotGroupState,
  DeliveryMethodStructure,
  OtherMethodsStructure,
  SlotState,
  SlotsGrid,
  SlotGroup,
  GroupedShoppingMethod,
} from '#/custom-typings/redux-store/slot.defs';
import { Slot } from '#/lib/records/slot.defs';
import { Trolley } from '#/lib/records/trolley.defs';
import { MomentInput } from 'moment';
import { TrolleyState } from '#/custom-typings/redux-store/trolley.defs';
import { TOrder } from '#/custom-typings/redux-store/orders-ddl.defs';
import { FulfilmentEstimatedArrival } from '#/resources/fulfilment-estimated-arrival.defs';
import { FULFILMENT_ESTIMATED_ARRIVAL } from '#/constants/spa-resource';
import { AddressSuggestion } from '#/lib/requests/addressSearch/address-search';
import { getSubscriptionsState, hasActiveDeliverySaverSubscriptions } from '#/reducers/subscriptions';
import { sessionStore } from '#/lib/data-store/client-store';
import { TConfigFunc } from '#/lib/records/helpers.defs';

type Location = {
  displayOrder: number;
  locationId: string;
  isSelected: boolean;
};

export type SlotRange = {
  dehydrate: unknown;
  range: unknown;
  start: moment.MomentInput;
  weekLimit: number;
};

type SlotProps = {
  shoppingMethod?: string;
  currentSlotGroup?: number | null;
  changeSlotInfo?: ChangeSlotInfo;
  slotRange?: SlotRange;
  locationsExpanded?: boolean;
  resources?:
    | {
        [key: string]: {
          data: {
            [key: string]: unknown;
          };
        };
      }
    | undefined;
  locations?: Location[];
  locationsPage?: number;
  trolley?: Trolley;
  showSlotExpiredNotification?: boolean;
  availableSlotGroups?: number[];
  timezone?: string | undefined;
  slots?: Slot[];
};

type GetDefaultShoppingMethodUIState = (
  shoppingMethod: string,
  storeId: string,
  currentSlotGroup: number | null,
  selectedDate: Moment,
  slots: Slot[],
) =>
  | {}
  | {
      [key: string]: {
        [key: number]: {
          [key: string]: (string | null)[];
        };
      };
    }
  | {
      [key: string]: {
        [key: string]: (string | null)[];
      };
    };

type DraftSlotState = {
  fulfilmentOptionsDetail: FulfilmentOptionsDetail;
  fulfilmentEstimatedArrival: FulfilmentEstimatedArrival;
  slotGroup: {
    [key: string]: SlotGroup[];
  };
  slots: Slot[] | {};
  ui: Ui;
  locationId?: string | undefined;
};

type Data = {
  locationId: string;
  storeId: number;
  shoppingMethod: ShoppingMethod;
  availableSlotGroups: SlotGroup[];
  slots: Slot[];
  currentSlotGroup: SlotGroup;
  fulfilmentLocation: {};
  slotGroup: SlotGroup;
  weekLimit: number;
  startDate: moment.MomentInput;
  selectedDate: moment.MomentInput;
  changeSlotInfo: null | ChangeSlotInfo;
  locationsPage?: number;
};

type DynamicDeliveryTime = {
  isDynamicDeliveryTimeAvailable: boolean;
  value: number;
  unit: string;
  range: {
    min: number;
    max: number;
  };
};

type Value = {
  availableShoppingMethods: ShoppingMethod[];
  groupedShoppingMethods: GroupedShoppingMethod[];
  options: Options[];
  data: Data;
  timezone: string;
  selectedDate: moment.MomentInput;
  locationId: string;
  collectionLocationId: string | number;
  deliveryLocationId: number;
  date: moment.MomentInput;
  slotGroup: SlotGroup;
  time: DynamicDeliveryTime;
};

type ActionType = {
  type: string;
  value: number | number[] | Value | ShoppingMethod | ChangeSlotInfo | AddressSuggestion | boolean;
};
const DATE_FORMAT = 'YYYY-MM-DD';

const getSlotsIndexedById = ({ slots, timezone }: SlotProps, defaultSlots = {}): Slot[] => {
  const slotsInUtcWithTimezone = getSlotTimesInUtcWithTimezone(slots as Slot[], timezone as string);
  return slotsInUtcWithTimezone.reduce((accumSlots, slot) => {
    // This is to cater for the new getFulfilment response from mango
    const slotId = ((slot.slotId || slot.id) as unknown) as number;
    accumSlots[slotId] = slot;
    return accumSlots;
  }, defaultSlots as Slot[]);
};

export const getSlotsForDate = (date: string, slots: Slot[]): Slot[] =>
  slots.filter((slot: { start: Moment | string | null }) => formatDate(slot.start) === date);

const getSelectedOrFirstAvailableSlotDate = (
  selectedDate: Moment,
  activeSlots: Slot[],
  slotRange: SlotRange,
): Moment => {
  const slotsForSelectedDay = getSlotsForDate(formatDate(selectedDate), activeSlots);
  const hasSlotForSelectedDay = getFirstAvailableSlotForDay(slotsForSelectedDay);

  if (!hasSlotForSelectedDay) {
    let firstAvailableSlot: Slot | undefined;

    getWeekRangeForDay(slotRange, selectedDate).by('days', (day: moment.Moment) => {
      const slotsForDay = getSlotsForDate(formatDate(day), activeSlots);
      if (!firstAvailableSlot) {
        firstAvailableSlot = getFirstAvailableSlotForDay(slotsForDay);
      }
    });

    if (firstAvailableSlot) {
      return sanitiseDate(firstAvailableSlot.start as moment.Moment);
    }
  }

  return selectedDate;
};

const calculateSelectedDate = (
  slotRange: SlotRange,
  selectedDate: moment.MomentInput,
  activeSlots: Slot[],
  timezone: string | undefined,
): Moment => {
  const utcSelectedDate = moment.utc(selectedDate);

  return slotRange.start === formatDate(utcSelectedDate, timezone)
    ? getSelectedOrFirstAvailableSlotDate(utcSelectedDate, activeSlots, slotRange)
    : utcSelectedDate;
};

const getDefaultShoppingMethodUIState: GetDefaultShoppingMethodUIState = (
  shoppingMethod,
  storeId,
  currentSlotGroup,
  selectedDate,
  slots,
) => {
  const hasSlotGroup = shoppingMethod === DELIVERY;

  if (shoppingMethod && storeId && selectedDate) {
    if (hasSlotGroup && currentSlotGroup) {
      return {
        [storeId]: {
          [currentSlotGroup]: {
            [selectedDate.format(DATE_FORMAT)]: slots.map(slot => slot.slotId),
          },
        },
      };
    } else if (!hasSlotGroup) {
      return {
        [storeId]: {
          [selectedDate.format(DATE_FORMAT)]: slots.map(slot => slot.slotId),
        },
      };
    }
  }

  return {};
};

const getDataFromResource = ({ resources }: SlotProps, resource: string, key: string): unknown =>
  resources && resources[resource] && resources[resource].data && resources[resource].data[key];

const getSlotDataFromResourceIfAvailable = ({ resources, slots }: SlotProps): Slot[] | undefined => {
  const resourceData = resources && resources[SLOT] && resources[SLOT].data;

  if (!resourceData) return slots;
  if (resourceData.slots) return resourceData.slots as Slot[];

  return slots;
};

// Selectors
const getUIState = ({ slot }: Store): Ui => slot.ui;

const getAllSlotGroups = ({ slot: { slotGroup } }: Store): SlotGroupState => slotGroup;

const getActualSelectedDate = createSelector(getUIState, ({ selectedDate }) => selectedDate);

export const getSlotRange = createSelector(getUIState, getTimezone, ({ startDate, weekLimit }, timezone) =>
  createSlotRange(weekLimit, startDate, timezone),
);

export const getActiveWeek = createSelector(getSlotRange, getActualSelectedDate, (slotRange, selectedDate) =>
  formatDate(getFirstDayOfWeekRangeForDay(slotRange, selectedDate)),
);

const getSlotsObject = ({ slot: { slots } }: Store): Store['slot']['slots'] => slots;

export const getSelectedShoppingMethod = createSelector(
  getUIState,
  ({ selectedShoppingMethod }) => selectedShoppingMethod,
);

export const getSlotGridShoppingMethod = (state: Store): string => state.slot.ui.slotGrid.shoppingMethod;
export const getSlotGridSlotGroup = (state: Store): SlotGroup => state.slot.ui.slotGrid.slotGroup;
export const getSlotGridDate = (state: Store): Moment | MomentInput => state.slot.ui.slotGrid.date;
export const getSlotGridLocationId = (state: Store): string | number => state.slot.ui.slotGrid.locationId;

const getWeekOfSlotGridDate = createSelector(getSlotRange, getSlotGridDate, (slotRange, selectedDate) =>
  formatDate(getFirstDayOfWeekRangeForDay(slotRange, selectedDate)),
);

export const getWeekOfActualDateMomentObject = createSelector(getWeekOfSlotGridDate, activeWeek =>
  moment.utc(activeWeek),
);

export const getActiveWeekMomentObject = createSelector(getActiveWeek, activeWeek => moment.utc(activeWeek));

export const getCurrentSlotGroup = createSelector(getUIState, ({ selectedSlotGroup }) => selectedSlotGroup);

export const getSlotsPendingStatus = createSelector(getUIState, ({ isLoading }) => isLoading);

export const getSelectedWeekRange = createSelector(getSlotRange, getActiveWeekMomentObject, (slotRange, activeWeek) =>
  getWeekRangeForDay(slotRange, activeWeek),
);

export const getSlotRangeByWeeks = createSelector(getSlotRange, slotRange => slotRange && rangeByWeek(slotRange));

export const getSelectedDeliveryLocationId = createSelector(
  getUIState,
  ({ selectedDeliveryLocationId }) => selectedDeliveryLocationId,
);

export const getSelectedCollectionLocationId = createSelector(
  getUIState,
  ({ selectedCollectionLocationId }) => selectedCollectionLocationId,
);

export const getSelectedLocationId = createSelector(
  getSelectedShoppingMethod,
  getSelectedDeliveryLocationId,
  getSelectedCollectionLocationId,
  (shoppingMethod, deliveryLocationId, collectionLocationId) =>
    shoppingMethod === DELIVERY || isOnDemandShoppingMethod(shoppingMethod) ? deliveryLocationId : collectionLocationId,
);

export const getSelectedLocation = createSelector(
  getLocations,
  getSelectedLocationId,
  (locations, selectedLocationId) =>
    locations.find((location: { locationId: number }) => location.locationId === selectedLocationId) || {},
);

export const getChangingAddressSuccessBanner = createSelector(
  getUIState,
  ({ changingAddressSuccessBanner }) => changingAddressSuccessBanner,
);

export const getDismissDeliverySaverAcquisitionBanner = createSelector(
  getUIState,
  ({ dismissDeliverySaverAcquisitionBanner }) => {
    const dismissDSAcquisitionBannerFromStorage =
      sessionStore?.get(DISMISS_DS_ACQUISITION_BANNER) ||
      sessionStore?.get(DISMISS_DS_ACQUISITION_BANNER) === undefined;

    return dismissDeliverySaverAcquisitionBanner || dismissDSAcquisitionBannerFromStorage;
  },
);

export const getDisplayDeliverySaverAcquisitionBanner = (config: TConfigFunc, state: Store): boolean => {
  const enabledOnDelivery = config('slots:deliverySaverBanner:delivery');
  const enabledOnCollection = config('slots:deliverySaverBanner:collection');

  const selector = createSelector(
    getSelectedShoppingMethod,
    getDismissDeliverySaverAcquisitionBanner,
    getSubscriptionsState,
    (shoppingMethod, dismissDeliverySaverAcquisitionBanner, subscriptions) => {
      if (dismissDeliverySaverAcquisitionBanner) {
        return false;
      }

      if (hasActiveDeliverySaverSubscriptions(subscriptions)) {
        return false;
      }

      if (isDeliveryShoppingMethod(shoppingMethod)) {
        return !!enabledOnDelivery;
      }

      if (isCollectionShoppingMethod(shoppingMethod)) {
        return !!enabledOnCollection;
      }

      return false;
    },
  );
  return selector(state);
};

export const getAvailableSlotGroups = createSelector(
  getAllSlotGroups,
  getSelectedLocationId,
  (slotGroups, selectedLocationId) => slotGroups[selectedLocationId],
);

const getActiveSlotIdsForSelectedSlots = createSelector(
  getSelectedShoppingMethod,
  getCurrentSlotGroup,
  getSelectedLocationId,
  getActiveWeek,
  getUIState,
  (selectedShoppingMethod, selectedSlotGroup, locationId, activeWeek, ui) => {
    const uiStateByLocation =
      (ui.slotGrid[selectedShoppingMethod] && ui.slotGrid[selectedShoppingMethod][locationId]) || {};

    // Because slotGroup does not exist in the state structure for collection slots, we have to reference each of these
    // differently to ensure we are picking the correct data

    return selectedShoppingMethod === DELIVERY
      ? (((uiStateByLocation as unknown) as DeliveryMethodStructure)[selectedSlotGroup] || {})[activeWeek]
      : (uiStateByLocation as OtherMethodsStructure)[activeWeek];
  },
);

const getSlotIdsForWeekOfSlotGridDate = createSelector(
  getSlotGridShoppingMethod,
  getSlotGridSlotGroup,
  getSlotGridLocationId,
  getWeekOfSlotGridDate,
  getUIState,
  (selectedShoppingMethod, selectedSlotGroup, locationId, activeWeek, ui) => {
    const uiStateByLocation =
      (ui.slotGrid[selectedShoppingMethod as ShoppingMethod] &&
        ui.slotGrid[selectedShoppingMethod as ShoppingMethod][locationId]) ||
      {};

    // Because slotGroup does not exist in the state structure for collection slots, we have to reference each of these
    // differently to ensure we are picking the correct data

    return selectedShoppingMethod === DELIVERY
      ? (((uiStateByLocation as unknown) as DeliveryMethodStructure)[selectedSlotGroup] || {})[activeWeek]
      : uiStateByLocation[activeWeek];
  },
);

export const hasFetchedActiveSlots = createSelector(getActiveSlotIdsForSelectedSlots, slotIds => !!slotIds);

export const getActiveSlotIdsForSlotGrid = createSelector(getSlotIdsForWeekOfSlotGridDate, slotIds => slotIds || []);

export const getActiveSlotsForSlotGrid = createSelector(
  getActiveSlotIdsForSlotGrid,
  getSlotsObject,
  (activeSlotIds, slots) => (activeSlotIds as number[]).map((slotId: number) => (slots as Slot[])[slotId]),
);

export const getLocationsExpanded = createSelector(getUIState, ({ locationsExpanded }) => locationsExpanded);

export const getLocationsPage = createSelector(getUIState, ({ locationsPage }) => locationsPage);

export const getBookedSlotStatus = createSelector(getUIState, ({ isBookedSlotExpired }) => isBookedSlotExpired);

export const getShouldApplySamedaySurcharge = (state: Store) => (slotTime: string): boolean | undefined => {
  const deliverySaverApplicableDays = getDeliverySaverApplicableDays(state);
  if (deliverySaverApplicableDays.length !== 0) {
    const isSlotToday = moment(slotTime)
      .utc()
      .isSame(moment(), 'day');
    const isSlotIncludedInPlan = deliverySaverApplicableDays.includes(getSlotTimeIsoWeekday(slotTime));
    const shoppingMethod = getSelectedShoppingMethod(state);
    if (!isSlotToday || hasFreeSameDayDelivery(state)) {
      return false;
    }

    if (shoppingMethod == DELIVERY && !isSlotIncludedInPlan) {
      return isAnytimeDeliverySaverPlan(state);
    }

    return true;
  }
};

export const getDeliveryActiveSlotId = createSelector(
  getSelectedShoppingMethod,
  getSelectedLocationId,
  getUIState,
  getTimezone,
  getLanguage,
  (shoppingMethod, selectedLocationId, uiState, timezone, language) => {
    const date = getFormattedDate(new Date(), DATE_FORMAT, timezone, language);
    return (uiState.slotGrid[shoppingMethod] as DeliveryMethodStructure)?.[selectedLocationId]?.[date]?.[0];
  },
);

export const getSelectedDate = createSelector(
  getSlotRange,
  getActiveSlotsForSlotGrid,
  getActualSelectedDate,
  getTimezone,
  (slotRange, activeSlots, selectedDate, timezone) =>
    calculateSelectedDate(slotRange, selectedDate, activeSlots, timezone),
);

const getSlotDate = createSelector(
  getSlotRange,
  getActiveSlotsForSlotGrid,
  getSlotGridDate,
  getTimezone,
  (slotRange, activeSlots, selectedDate, timezone) =>
    calculateSelectedDate(slotRange, selectedDate as moment.MomentInput, activeSlots, timezone),
);

export const getSelectedDateSlots = createSelector(
  getActiveSlotsForSlotGrid,
  getSlotDate,
  (activeSlots, selectedDate) => getSlotsForDate(formatDate(selectedDate), activeSlots),
);

const getSlotsForWeek = (slotRange: SlotRange, date: Moment, activeSlots: Slot[]): Record<string, Slot[]> => {
  const range = getWeekRangeForDay(slotRange, date);
  const days: Record<string, Slot[]> = {};

  range.by('days', (day: Moment) => {
    const key = formatDate(day);

    days[key] = getSlotsForDate(key, activeSlots);
  });

  return days;
};

export const getSelectedWeekSlots = createSelector(
  getSlotRange,
  getActiveSlotsForSlotGrid,
  getWeekOfActualDateMomentObject,
  (slotRange, activeSlots, activeWeek) => getSlotsForWeek(slotRange, activeWeek, activeSlots),
);

const getSlotsForWeekOop1324 = (slotRange: SlotRange, date: moment.Moment, activeSlots: Slot[]): Slot[] => {
  const range = getWeekRangeForDay(slotRange, date);
  const slots: Slot[] = [];

  range.by('days', (day: Moment) => {
    const date = formatDate(day);
    slots.push(...getSlotsForDate(date, activeSlots));
  });

  return slots;
};

export const getSelectedWeekSlotsOop1324 = createSelector(
  getSlotRange,
  getActiveSlotsForSlotGrid,
  getWeekOfActualDateMomentObject,
  (slotRange, activeSlots, activeWeek) => getSlotsForWeekOop1324(slotRange, activeWeek, activeSlots),
);

export const getDefaultSlotsPath = (
  f: ((featureKey?: string | string[]) => string) | undefined,
  getFullSlotConfiguration = false,
): OutputSelector<
  Store,
  string,
  (
    res1: Slot,
    res2: string,
    res3: ShoppingMethod,
    res4: string | number,
    res5: SlotGroup,
    res6: ShoppingMethod,
    res7: unknown,
    res8: unknown,
  ) => string
> =>
  createSelector(
    [
      getBookedSlot,
      getActiveWeek,
      getSelectedShoppingMethod,
      getSelectedCollectionLocationId,
      getCurrentSlotGroup,
      getTrolleyShoppingMethod,
      getCurrentUrl,
      getAvailableShoppingMethods,
    ],
    (
      bookedSlot,
      activeWeek,
      selectedShoppingMethod,
      selectedCollectionLocationId,
      selectedSlotGroup,
      trolleyShoppingMethod,
      currentUrl,
      availableShoppingMethods,
    ) => {
      const hasBookedSlot = !!(bookedSlot && bookedSlot.status);
      const showHub = f && f('showSlotsHub') && !hasBookedSlot;
      const isSlotsPage = /\/slots\/(collection|delivery)/.test(currentUrl);
      const defaultSlotPath = '/slots';

      if (showHub && !isSlotsPage) {
        return defaultSlotPath;
      }

      const defaultShoppingMethod = (availableShoppingMethods && availableShoppingMethods[0]) ?? DELIVERY;

      const slotType = isSlotsPage
        ? selectedShoppingMethod
        : hasBookedSlot
        ? trolleyShoppingMethod
        : defaultShoppingMethod;

      let path = slotType;

      if (getFullSlotConfiguration) {
        path += activeWeek ? `/${activeWeek}` : '';

        const params = {
          locationId: slotType === COLLECTION ? selectedCollectionLocationId : null,
          slotGroup: selectedSlotGroup && !path.includes(COLLECTION) ? selectedSlotGroup : null,
        };
        path = updateParamsInUrl(path, params);
      }

      return `${defaultSlotPath}/${path}`;
    },
  );

export const getSelectedAddress = createSelector(
  getSelectedShoppingMethod,
  getSelectedLocation,
  getTrolleyDeliveryAddress,
  (selectedShoppingMethod, selectedLocation, deliveryAddress) =>
    selectedShoppingMethod === DELIVERY ? { address: deliveryAddress } : selectedLocation,
);

export const getSuggestionSelectedAddress = createSelector(getUIState, ui => ui.suggestionSelectedAddress);

export const getSuggestionSelectedAddressId = createSelector(
  getSuggestionSelectedAddress,
  suggestionSelectedAddress => suggestionSelectedAddress?.id || '',
);

export const isSameDayMultipleDeliveryWarningForDate = (createSelector(
  getSelectedAddress,
  // Method is deprecated and it will be deleted, no need to be typed
  // @ts-ignore
  getPendingOrdersByDate,
  (_: unknown, { slots }: SlotState) => slots,
  (_: unknown, { date }: Record<string, unknown>) => date,
  (selectedAddress, pendingOrders, slots, date) =>
    hasPendingOrderWithSameAddressForDate(
      (pendingOrders as Record<string, TOrder[]>)[date as string],
      selectedAddress,
    ) && !hasAvailableSlots(slots as Slot[]),
) as unknown) as (state: Store, { date, slots }: Record<string, unknown>) => boolean;

export const isSameDayMultipleDeliveryWarningForWeek = (
  state: Store,
  { slots }: SlotState,
): Record<string, unknown> => {
  const flagsForWeeks: Record<string, unknown> = {};
  Object.entries(slots).forEach(([date, slots]) => {
    flagsForWeeks[date] = isSameDayMultipleDeliveryWarningForDate(state, {
      date,
      slots,
    });
  });
  return flagsForWeeks;
};

export const getBookedDays = (state: Store, { slots }: SlotState): string[] => {
  const bookedDays: string[] = [];
  Object.entries(slots).forEach(([date, slots]) => {
    if (
      isSameDayMultipleDeliveryWarningForDate(state, {
        date,
        slots,
      })
    ) {
      bookedDays.push(date);
    }
  });
  return bookedDays;
};

export const getIsBookedSlotAvailableInState = (state: Store | TrolleyState): boolean => {
  const bookedSlot = getBookedSlot(state as Store);
  if (!bookedSlot) return false;

  const { start, end, group, locationId } = bookedSlot;
  const timezone = getTimezone(state as Store);
  const slotStart = moment.utc(start).tz(timezone);
  const slotEnd = moment.utc(end).tz(timezone);

  return Object.values((state as Store).slot.slots).some(
    slot =>
      (slot.start as Moment).isSame(slotStart) &&
      (slot.end as Moment).isSame(slotEnd) &&
      slot.group === group &&
      slot.locationId === locationId &&
      slot.status === AVAILABLE,
  );
};

export const updateSlotAndUIState = (
  draft: DraftSlotState,
  shoppingMethod: ShoppingMethod,
  selectedDate: moment.Moment,
  deliveryLocationId: number,
  collectionLocationId: string | number,
  availableSlotGroups: SlotGroup[],
  slots: Slot[],
  timezone: string,
  slotGroup: SlotGroup,
  fulfilmentLocation: {},
): void => {
  draft.ui.selectedShoppingMethod = shoppingMethod;

  const slotRange = getSlotRange({ slot: draft, app: { timezone } } as Store);

  const activeWeek = formatDate(getFirstDayOfWeekRangeForDay(slotRange, selectedDate));

  if (deliveryLocationId) {
    draft.ui.selectedDeliveryLocationId = deliveryLocationId;
  }
  if (collectionLocationId) {
    draft.ui.selectedCollectionLocationId = collectionLocationId;
  }
  const locationId = getSelectedLocationId({
    slot: draft,
  } as Store);
  draft.slotGroup[locationId] = availableSlotGroups;
  draft.ui.slotGrid.locationId = locationId;

  draft.slots = getSlotsIndexedById({ slots, timezone }, draft.slots);

  if (slotGroup) draft.ui.selectedSlotGroup = slotGroup;
  if (fulfilmentLocation) draft.ui.fulfilmentLocation = fulfilmentLocation;

  // We need to structure the update differently from delivery to collection, as slotGroup is not a valid field for
  // collection, and whilst we could store slotGroup against collection as a dummy value, it would need to be updated
  // very time we switch shopping types to remain referenced by the UI, which breaks persistence. Therefore, it is
  // better to treat them differently and have one function to update the slots in the UI state

  const slotGridShoppingMethod = draft.ui.slotGrid[shoppingMethod];

  //This caters to the case where UI state doesn't contain any data for current shopping method. Example for this is
  // 'light delivery', where there is no concept of slots hence no UI state to display them
  if (!slotGridShoppingMethod) {
    return;
  }
  if (!slotGridShoppingMethod[locationId]) {
    slotGridShoppingMethod[locationId] = {};
  }

  const slotsUIForCurrentLocation = slotGridShoppingMethod[locationId];

  if (shoppingMethod === DELIVERY) {
    if (!slotsUIForCurrentLocation[slotGroup]) {
      (slotGridShoppingMethod as DeliveryMethodStructure)[locationId][slotGroup] = {};
    }
    (slotGridShoppingMethod as DeliveryMethodStructure)[locationId][slotGroup][activeWeek] = slots.map(
      slot => slot.slotId,
    );
    return;
  }

  slotGridShoppingMethod[locationId][activeWeek] = slots.map(slot => slot.slotId);
};

const clearInactiveWeeks = (draft: DraftSlotState, timezone: string): void => {
  const {
    ui: { selectedDate, selectedSlotGroup, selectedShoppingMethod, slotGrid },
  } = draft;
  if (!selectedShoppingMethod || isOnDemandShoppingMethod(selectedShoppingMethod)) {
    return;
  }
  const slotRange = getSlotRange({ slot: draft, app: { timezone } } as Store);
  const activeWeek = formatDate(getFirstDayOfWeekRangeForDay(slotRange, selectedDate));
  const locationId = getSelectedLocationId({
    slot: draft,
  } as Store);
  if (selectedShoppingMethod === DELIVERY) {
    slotGrid[selectedShoppingMethod][locationId][selectedSlotGroup] = {
      [activeWeek]: slotGrid[selectedShoppingMethod][locationId][selectedSlotGroup][activeWeek],
    };
  } else {
    slotGrid[selectedShoppingMethod][locationId] = {
      [activeWeek]: slotGrid[selectedShoppingMethod][locationId][activeWeek],
    };
  }
};

export default function slotReducer(state = {} as SlotState, { type, value }: ActionType): SlotState {
  return produce(state, draft => {
    const { ui } = draft;
    const { ui: { slotGrid } = {} } = draft;

    switch (type) {
      case RECEIVE_FULFILMENT_OPTIONS_DETAIL:
        draft.fulfilmentOptionsDetail = value as Value;
        break;
      case RECEIVE_FULFILMENT_ESTIMATED_ARRIVAL:
        draft.fulfilmentEstimatedArrival = value as Value;
        break;
      case NEW_SLOT_DATA_FROM_RESOURCES:
        const {
          timezone,
          data: {
            locationId: collectionLocationId,
            storeId: deliveryLocationId,
            shoppingMethod,
            availableSlotGroups,
            slots,
            slotGroup,
            fulfilmentLocation,
            weekLimit,
            startDate,
            selectedDate,
            changeSlotInfo,
          },
        } = value as Value;

        if (changeSlotInfo) ui.changeSlotInfo = changeSlotInfo;

        const selectedDateMoment = moment.utc(selectedDate || startDate).tz(timezone);

        ui.selectedDate = selectedDateMoment;
        if (!ui.startDate) ui.startDate = moment.utc(startDate).tz(timezone);
        ui.slotGrid.date = selectedDate || startDate;
        ui.slotGrid.shoppingMethod = shoppingMethod;
        ui.slotGrid.slotGroup = slotGroup;
        ui.weekLimit = weekLimit;

        // mutates in the draft argument that is passed to it
        updateSlotAndUIState(
          draft,
          shoppingMethod,
          selectedDateMoment,
          deliveryLocationId,
          collectionLocationId,
          availableSlotGroups,
          slots,
          timezone,
          slotGroup,
          fulfilmentLocation,
        );

        ui.isLoading = false;

        break;
      case NEW_SLOT_DATA: {
        const {
          locationId: collectionLocationId,
          storeId: deliveryLocationId,
          shoppingMethod,
          availableSlotGroups,
          slots,
          currentSlotGroup,
          fulfilmentLocation,
        } = (value as Value).data;

        // mutates in the draft argument that is passed to it
        updateSlotAndUIState(
          draft,
          shoppingMethod,
          moment.utc((value as Value).data.selectedDate),
          deliveryLocationId,
          collectionLocationId,
          availableSlotGroups,
          slots,
          (value as Value).timezone,
          currentSlotGroup,
          fulfilmentLocation,
        );

        ui.isLoading = false;

        break;
      }
      case NEW_SLOT_DATA_PENDING:
        ui.isLoading = true;
        ui.changingAddressSuccessBanner = false;
        break;
      case NO_SLOT_DATA_PENDING:
        clearInactiveWeeks(draft, (value as Value).timezone);
        ui.isLoading = false;
        break;
      case CHANGE_SLOT_DATE:
        ui.selectedDate = moment.utc((value as Value).selectedDate).tz((value as Value).timezone);
        break;
      case CHANGE_SHOPPING_METHOD:
        ui.selectedShoppingMethod = value as ShoppingMethod;
        break;
      case CHANGE_SLOT_TYPE:
      case CHANGING_ADDRESS:
        ui.changeSlotInfo = value as ChangeSlotInfo;
        break;
      case CHANGING_ADDRESS_SUCCESS:
        ui.changeSlotInfo = value as ChangeSlotInfo;
        ui.changingAddressSuccessBanner = true;
        break;
      case CLOSE_CHANGING_ADDRESS_SUCCESS_BANNER:
        ui.changingAddressSuccessBanner = false;
        break;
      case CLOSE_CHANGE_SLOT_TYPE:
        ui.changeSlotInfo = null;
        break;
      case LOCATION_SELECTED: {
        ui.selectedCollectionLocationId = (value as Value).locationId;
        break;
      }
      case SEARCH_LOCATIONS_BY_SUGGESTION_ADDRESS:
        ui.suggestionSelectedAddress = value as AddressSuggestion;
        break;
      case CLEAR_SUGGESTION_SELECTED_ADDRESS:
        ui.suggestionSelectedAddress = null;
        break;
      case SHOW_LOCATIONS_PAGE:
        ui.locationsExpanded = true;
        ui.locationsPage = value as number;
        break;
      case SHOW_FEWER_LOCATIONS:
        ui.locationsExpanded = false;
        ui.locationsPage = value as number;
        break;
      case SEARCH_LOCATIONS_BY_SUGGESTION_ADDRESS:
        ui.suggestionSelectedAddress = value as AddressSuggestion;
        break;
      case UPDATE_SLOT_GROUP:
        draft.slotGroup[state.locationId as string] = value as SlotGroup[];
        break;
      case UPDATE_SELECTED_SLOT_GROUP:
        ui.selectedSlotGroup = (value as Value).slotGroup;
        ui.selectedDate = moment.utc((value as Value).date);
        break;
      case RESET_SELECTED_LOCATION:
        ui.selectedCollectionLocationId = (value as Value).collectionLocationId;
        ui.selectedDeliveryLocationId = (value as Value).deliveryLocationId;
        break;
      case CLEAR_COLLECTION_SELECTED_LOCATION:
        ui.selectedCollectionLocationId = '';
        break;
      case SYNC_SELECTED_ACTUAL_VALUES:
        (slotGrid as SlotsGrid).slotGroup = state.ui.selectedSlotGroup;
        (slotGrid as SlotsGrid).shoppingMethod = state.ui.selectedShoppingMethod;
        (slotGrid as SlotsGrid).locationId = getSelectedLocationId({ slot: state } as Store);
        (slotGrid as SlotsGrid).date = state.ui.selectedDate;
        break;
      case DISMISS_DS_ACQUISITION_BANNER:
        ui.dismissDeliverySaverAcquisitionBanner = true;
        break;
    }
  });
}

export const getDefaultStateFromProps = (props: SlotProps): SlotState => {
  const { timezone } = props;

  const shoppingMethod =
    (getDataFromResource(props, SLOT, 'shoppingMethod') as ShoppingMethod) || (props.shoppingMethod as ShoppingMethod);

  const currentSlotGroup = (getDataFromResource(props, SLOT, 'slotGroup') as SlotGroup) || props.currentSlotGroup || 1;

  const changeSlotInfo =
    (getDataFromResource(props, SLOT, 'changeSlotInfo') as ChangeSlotInfo) || (props.changeSlotInfo as object);

  const weekLimit =
    (getDataFromResource(props, SLOT, 'weekLimit') as number) || ((props.slotRange || {}).weekLimit as number);
  const startDate =
    (getDataFromResource(props, SLOT, 'startDate') as string) || ((props.slotRange || {}).start as string);
  const locationsExpanded = props.locationsExpanded;
  const fulfilmentLocation = (getDataFromResource(props, SLOT, 'fulfilmentLocation') as object) || {};

  const slotRange =
    (weekLimit && startDate && createSlotRange(weekLimit, moment.utc(startDate), timezone)) ||
    (props.slotRange && createSlotRange(props.slotRange.weekLimit, moment.utc(props.slotRange.start), timezone));

  const locations =
    ((((props.resources || {}).location || {}).data as unknown) as Location[]) || (props.locations as Location[]) || [];
  const locationsPage =
    props.locationsPage ||
    getSelectedLocationPage(
      ((locations as Location[]) || []).findIndex((loc: { isSelected: boolean }) => loc.isSelected),
    ) ||
    1;
  const selectedLocation =
    (locations && locations.find((location: { isSelected: boolean }) => location.isSelected)) || ({} as Location);

  let locationId;

  const deliveryLocationId =
    (getDataFromResource(props, SLOT, 'storeId') as number) ||
    (getDataFromResource(props, TROLLEY_CONTENTS, 'storeId') as number) ||
    (((props.trolley && props.trolley.storeId) as unknown) as number) ||
    -1;

  const isAmendBasket =
    getDataFromResource(props, TROLLEY_CONTENTS, 'isAmendBasket') || (props.trolley && props.trolley.isAmendBasket);

  const showSlotExpiredNotification = isAmendBasket ? (props.showSlotExpiredNotification as boolean) : false;

  const collectionLocationId =
    (getDataFromResource(props, SLOT, 'locationId') as string) || selectedLocation.locationId;

  if (shoppingMethod === DELIVERY) {
    locationId = deliveryLocationId;
  } else {
    locationId = collectionLocationId;
  }

  const slotData = getSlotDataFromResourceIfAvailable(props);

  const selectedDateDefault = (getDataFromResource(props, SLOT, 'selectedDate') as moment.MomentInput) || startDate;

  const selectedDate: Moment | undefined =
    slotRange && slotData && calculateSelectedDate(slotRange, selectedDateDefault, slotData, timezone);

  const availableSlotGroups = props.availableSlotGroups;

  const activeWeek = selectedDate && getFirstDayOfWeekRangeForDay(slotRange, selectedDate);

  const resources = props.resources;
  const fulfilmentOptionsDetail = resources?.[FULFILMENT_OPTIONS_DETAIL]?.data as FulfilmentOptionsDetail;
  const fulfilmentEstimatedArrival = resources?.[FULFILMENT_ESTIMATED_ARRIVAL]?.data as FulfilmentEstimatedArrival;
  const slotGroups =
    (getDataFromResource(props, FULFILMENT_METADATA, 'availableSlotGroups') as SlotGroup[]) || availableSlotGroups;
  return {
    fulfilmentOptionsDetail,
    fulfilmentEstimatedArrival,
    slotGroup: slotGroups ? { [locationId as string]: slotGroups } : {},
    slots: slotData ? getSlotsIndexedById({ slots: slotData, timezone }) : {},
    ui: {
      startDate,
      weekLimit,
      isLoading: false,
      selectedDate: selectedDate as moment.Moment | string | null,
      selectedDeliveryLocationId: deliveryLocationId,
      selectedCollectionLocationId: collectionLocationId,
      selectedSlotGroup: currentSlotGroup,
      selectedShoppingMethod: shoppingMethod,
      suggestionSelectedAddress: null,
      locationsPage,
      locationsExpanded,
      isBookedSlotExpired: showSlotExpiredNotification,
      fulfilmentLocation,
      changeSlotInfo,
      changingAddressSuccessBanner: false,
      dismissDeliverySaverAcquisitionBanner: false,
      selectedAddress: null,
      slotGrid: {
        shoppingMethod,
        locationId,
        slotGroup: currentSlotGroup,
        date: selectedDate,
        [DELIVERY]:
          shoppingMethod === DELIVERY
            ? getDefaultShoppingMethodUIState(
                shoppingMethod,
                locationId as string,
                currentSlotGroup,
                activeWeek,
                slotData as Slot[],
              )
            : {},
        [COLLECTION]:
          shoppingMethod === COLLECTION
            ? getDefaultShoppingMethodUIState(
                shoppingMethod,
                locationId as string,
                currentSlotGroup,
                activeWeek,
                slotData as Slot[],
              )
            : {},
        [ON_DEMAND]:
          shoppingMethod === ON_DEMAND
            ? getDefaultShoppingMethodUIState(
                shoppingMethod,
                locationId as string,
                currentSlotGroup,
                activeWeek,
                slotData as Slot[],
              )
            : {},
      },
    },
  };
};
