import { TROLLEY } from '#/constants/page-names';
import { dcsV3Config } from '#/constants/dcs';

export const trexMultiPlacement = {
  key: 'trex-multiplacement', // GHS-UK_ part created with vars from optimizely
  useCustomerId: false
};

export const homepageDcsContent = {
  key: dcsV3Config.featureKey,
  serviceConfig: {
    params: {
      experimentId: null,
      variantId: null
    }
  },
  useCustomerId: true
};

export const favoritesSubsFeature = {
  featureKey: 'GHS-<region>_substitutions',
  variationKey: 'service',
  serviceConfig: {
    params: {
      service: 'gapi',
      experimentId: null,
      variantId: null,
      strategy: 'rtd'
    }
  },
  useCustomerId: true,
  allowedPaths: [TROLLEY]
};

export const baggingOptionsFeatureToggle = {
  featureKey: 'GHS-<region>_bagging-options',
  variationKey: 'bagoption',
  serviceConfig: {
    params: {
      experimentId: 'oop952',
      variantId: null,
      bagoption: 'on'
    }
  },
  useCustomerId: true
};

export const lastOrderCtaInMinibasketFeature = {
  featureKey: 'GHS-<region>_last-order-cta-in-minibasket',
  variationKey: 'lastOrderCta',
  useCustomerId: true
};

export const skipOffersModal = {
  featureKey: 'ghs-all_onecheckout_skip-offer-hyf',
  variationKey: 'skipOffers',
  variants: {
    default: 'default',
    showSkipModal: 'skip_offers_HYF'
  },
  useCustomerId: true
};
