import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import helpers from '#/lib/decorators/helpers';
import { getObserver } from '#/lib/browser/intersection-observer';
import { connect } from '#/lib/render/connect-deep-compare';
import { TileImageContainer } from '#/components/cms-driven/tile-image-container';
import { CTATextWithChevron } from '#/components/cms-driven/cta-link-with-chevron';
import Link from '#/components/link';
import { getCutsMustard } from '#/reducers/app';

const mapStateToProps = state => ({
  cutsMustard: getCutsMustard(state)
});

@helpers(['asset'])
@connect(mapStateToProps)
export class TradeTileBase extends React.PureComponent {
  constructor(props) {
    super(props);

    this.outerTagRef = React.createRef();

    this.state = {
      isVisible: !props.cutsMustard
    };
  }

  static propTypes = {
    asset: PropTypes.func.isRequired,
    background: PropTypes.shape({
      texturedImageUrl: PropTypes.string
    }),
    body: PropTypes.string.isRequired,
    ctaColor: PropTypes.shape({
      color: PropTypes.string
    }),
    cutsMustard: PropTypes.bool.isRequired,
    image: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    panelColour: PropTypes.object,
    ping: PropTypes.object,
    placement: PropTypes.number,
    primaryLink: PropTypes.object.isRequired,
    secondaryLink: PropTypes.object,
    stampClasses: PropTypes.string.isRequired,
    textColor: PropTypes.shape({
      color: PropTypes.string
    }),
    title: PropTypes.string.isRequired
  };

  static defaultProps = {
    onClick: null,
    secondaryLink: null,
    ping: null,
    placement: 1,
    panelColour: null
  };

  componentDidMount() {
    if (!this.state.isVisible) {
      this.observer = getObserver(this.intersection);
      this.observe();
    }
  }

  componentWillUnmount() {
    if (this.observer) {
      this.observer.disconnect();
      this.observer = null;
    }
  }

  load() {
    this.setState({
      isVisible: true
    });
  }

  intersection = elements => {
    // Are we in viewport?
    if (this.observer && elements[0].intersectionRatio > 0) {
      // Stop watching and load content
      this.observer.disconnect();
      this.load();
    }
  };

  observe() {
    if (this.observer) {
      this.observer.observe(this.outerTagRef.current);
    } else {
      this.load();
    }
  }

  render() {
    const {
      asset,
      background,
      body,
      ctaColor,
      image,
      onClick,
      panelColour,
      ping,
      placement,
      primaryLink,
      secondaryLink,
      stampClasses,
      textColor,
      title
    } = this.props;
    const OuterTag = primaryLink?.href && !secondaryLink?.href ? Link : 'div';
    const InnerTag = secondaryLink?.href ? Link : 'span';

    const tileContentStyles = Object.assign(
      {
        backgroundImage:
          background && background.texturedImageUrl && this.state.isVisible
            ? `url("${asset(background.texturedImageUrl)}")`
            : null
      },
      panelColour
    );
    const contentTextStyles = {
      color: textColor && textColor.color ? textColor.color : null
    };
    const ctaStyles = {
      color: ctaColor && ctaColor.color ? ctaColor.color : null
    };

    return (
      <div
        ref={this.outerTagRef}
        className={classnames('trade-tile', stampClasses)}
        onClick={onClick}
      >
        <OuterTag
          className="tile__parent-link clear"
          to={primaryLink && primaryLink.href}
          style={tileContentStyles}
          target={primaryLink && primaryLink.newWindow ? '_blank' : null}
        >
          <TileImageContainer image={image} ping={ping} placement={placement} />
          <div className="tile__caption-wrapper">
            <h3 className="tile__heading" style={contentTextStyles}>
              {title}
            </h3>
            {body && (
              <p className="tile__body-text" style={contentTextStyles}>
                {body}
              </p>
            )}
            {primaryLink && primaryLink.label && (
              <div className="tile__cta-wrapper">
                <InnerTag
                  className="tile__cta-link tile__cta-link--primary"
                  to={primaryLink.href}
                  target={primaryLink.newWindow ? '_blank' : null}
                  style={ctaStyles}
                >
                  <CTATextWithChevron
                    textLabel={primaryLink.label}
                    newWindow={primaryLink.newWindow}
                    strokeColor={ctaStyles.color}
                  />
                </InnerTag>
                {secondaryLink && secondaryLink.href && (
                  <Link
                    className="tile__cta-link tile__cta-link--secondary"
                    to={secondaryLink.href}
                    target={secondaryLink.newWindow && secondaryLink.label}
                    rel="noreferrer noopener"
                    style={ctaStyles}
                  >
                    <CTATextWithChevron
                      textLabel={secondaryLink.label}
                      newWindow={secondaryLink.newWindow}
                      strokeColor={ctaStyles.color}
                    />
                  </Link>
                )}
              </div>
            )}
          </div>
        </OuterTag>
      </div>
    );
  }
}
