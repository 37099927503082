import moment from 'moment';
import { getLocalDate } from '#/lib/slot/slot-utils';

/**
 *  Formats given date to local short date e.g. 10/12/1990, 10.12.1990
 *
 *  @param {string} date - Moment-supported input
 *  @param {string} timezone - Valid timezone, e.g. 'Europe/London'
 *  @param {string} language - Valid language, e.g. 'en-GB'
 *  @returns {string|undefined} - String if able to format, otherwise undefined
 */
export function formatToShortDate(date, timezone, language) {
  if (date) {
    const m = moment(date);

    if (m.isValid()) {
      return m
        .tz(timezone)
        .locale(language)
        .format('L');
    }
  }
}

const COLON = /:/;
const EXTRACT_TIME = /\d{4}-\d{2}-\d{2}T(\d{2}:\d{2}):\d{2}.?\d+Z/;

export function extractTime(dateString) {
  if (dateString) {
    const matched = EXTRACT_TIME.exec(dateString);

    return matched && matched[1];
  }
}

export function getTimeOnDay(dayTimeStamp, time, language, timezone) {
  time = extractTime(time);

  if (time) {
    const [hh, mm] = time.split(COLON);

    return getLocalDate(dayTimeStamp, language, timezone)
      .hours(hh)
      .minutes(mm);
  }
}

export function getFormattedDate(date, formatString, timezone, language) {
  if (date) {
    const m = moment(date);

    if (m.isValid()) {
      if (timezone && language) {
        return m
          .tz(timezone)
          .locale(language)
          .format(formatString);
      }
      return m.format(formatString);
    }
  }
}

export function isPresentDay(date, timezone, language) {
  const m = moment
    .utc(date)
    .tz(timezone)
    .locale(language);

  return m.isSame(
    moment
      .utc()
      .tz(timezone)
      .locale(language),
    'day'
  );
}

export function isDateAfter(dateA, dateB, timezone, language) {
  const mA = moment
    .utc(dateA)
    .tz(timezone)
    .locale(language);
  const mB = moment
    .utc(dateB)
    .tz(timezone)
    .locale(language);

  return mA.isAfter(mB);
}

export function formatToDayOfWeek(date, timezone, language) {
  if (date) {
    const m = moment(date);

    if (m.isValid()) {
      return m
        .tz(timezone)
        .locale(language)
        .format('dddd');
    }
  }
}

export function getNthWorkingDay(days, startDate) {
  const date = startDate ? new Date(startDate) : new Date();
  const currentDay = date.getDay() - 1;
  const weekendDays = 2 * parseInt((currentDay + days) / 5);
  const milliseconds = date.setDate(date.getDate() + days + weekendDays);
  return new Date(milliseconds);
}
