import React from 'react';
import PropTypes from 'prop-types';
import { connect } from '../../../lib/render/connect-deep-compare';
import {
  addCoupon,
  updateCoupon
} from '../../../actions/coupon-action-creators';
import helpers from '../../../lib/decorators/helpers';
import InputWithLimitedCharacters from '../../shared/input-with-limited-characters';
import OverlaySpinner from '../../overlay-spinner';
import SafeForm from '../../shared/safe-form';
import SmartSubmitButton from '../../shared/smart-submit-button';
import {
  COUPON_STATUS_SUCCESS_SELECTED_UNQUALIFIED,
  COUPON_STATUS_ERROR,
  DEFAULT_ERROR_CODE
} from '../../../constants/coupons';
import { getIsAmendBasket } from '#/selectors/trolley';
import { getCurrentUrl, getLanguageLink } from '../../../reducers/app';
import FormValidation from '../../form-validation';
import FieldError from '../../error/field-error-message';

const mapStateToProps = state => {
  const { trolley } = state;

  return {
    couponLink: getLanguageLink(state, '/coupons'),
    currentUrl: getCurrentUrl(state),
    isAmendBasket: getIsAmendBasket(state),
    inputValue: trolley.addCouponFormCouponCode,
    errorInfo: trolley.addCouponFormErrorInfo,
    status: trolley.addCouponFormStatus,
    submitting: trolley.addCouponFormSubmitting
  };
};

@helpers(['c', 't'])
@connect(mapStateToProps, {
  addCoupon,
  updateCoupon
})
export default class CouponForm extends FormValidation {
  static propTypes = {
    addCoupon: PropTypes.func.isRequired,
    c: PropTypes.func.isRequired,
    couponLink: PropTypes.string.isRequired,
    currentUrl: PropTypes.string.isRequired,
    errorInfo: PropTypes.string,
    inputValue: PropTypes.string,
    isAmendBasket: PropTypes.bool.isRequired,
    status: PropTypes.string.isRequired,
    submitting: PropTypes.bool,
    t: PropTypes.func.isRequired,
    updateCoupon: PropTypes.func.isRequired
  };

  static defaultProps = {
    inputValue: '',
    submitting: false
  };

  constructor(props) {
    super(props);
    this.text = {
      buttonPrimaryText: props.t('checkout:payment-summary.use-code'),
      buttonSecondaryText: props.t(
        'checkout:payment-summary.use-code-checking'
      ),
      eCouponCodePlaceholder: props.t('checkout:payment-summary.ecoupon-code')
    };
  }

  couponChange = e => {
    return this.props.updateCoupon(e.target.value);
  };

  handleSubmit = e => {
    e.preventDefault();

    if (this.props.inputValue && this.onSubmit(e)) {
      this.props.addCoupon(this.props.inputValue);
    }
  };

  renderErrorMessage() {
    let errorMsg;

    if (this.props.c('coupons:useLocaleForErrors')) {
      const errorInfo = this.props.errorInfo.toLowerCase();
      const supportedErrorCode = this.props
        .c('coupons:errorCodes')
        .includes(errorInfo)
        ? errorInfo
        : DEFAULT_ERROR_CODE;

      errorMsg = this.props.t(`checkout:coupon-errors.${supportedErrorCode}`);
    } else {
      errorMsg = this.props.errorInfo;
    }

    return (
      <FieldError
        text={`${this.props.t('checkout:common.ecoupons')} ${
          this.props.inputValue
        }`}
        showErrorIcon={true}
        flashMessage={errorMsg}
      />
    );
  }

  renderSuccessMessage() {
    const isSelectedUnqualified =
      this.props.status === COUPON_STATUS_SUCCESS_SELECTED_UNQUALIFIED;
    const messageKey = isSelectedUnqualified
      ? 'trolley:ewallet.add-coupon-success-selected-unqualified'
      : 'trolley:ewallet.add-coupon-success-selected-qualified';

    return (
      <div className="coupons-added-message">
        <p>{this.props.t(messageKey)}</p>
      </div>
    );
  }

  displayFlashMessage() {
    const couponError = this.props.status === COUPON_STATUS_ERROR;

    return (
      <div className="coupon-flash">
        {couponError ? this.renderErrorMessage() : this.renderSuccessMessage()}
      </div>
    );
  }

  render() {
    // IGHS returns error message in response, whilst UK returns a key which the UI then use to retrieve message
    const {
      couponLink,
      currentUrl,
      inputValue,
      status,
      submitting
    } = this.props;

    return (
      <SafeForm
        action={couponLink}
        className="coupon-form"
        method="post"
        noValidate
        onChange={this.onChange}
        onSubmit={this.handleSubmit}
      >
        <div className="coupon-form__inner">
          <OverlaySpinner isLoading={submitting} />
          <input type="hidden" name="returnUrl" value={currentUrl} />
          <div className="coupon-input">
            <label className="obscure" htmlFor="code">
              {this.text.eCouponCodePlaceholder}
            </label>
            <InputWithLimitedCharacters
              autoCapitalize="off"
              autoComplete="off"
              autoCorrect="off"
              dataAuto="coupon-code-input"
              defaultValue={inputValue}
              id="code"
              name="code"
              onChange={this.couponChange}
              onPaste={this.couponChange}
              pattern={/^[a-zA-Z0-9\\-]+$/}
              placeholder={this.text.eCouponCodePlaceholder}
              register={this.register}
              spellCheck={false}
              value={inputValue}
              disabled={submitting}
              enableErrorPopdown={false}
            />
          </div>
          <SmartSubmitButton
            disabled={submitting}
            buttonStyles="button button-secondary coupon-button"
            dataAuto="use-code"
            primaryText={this.text.buttonPrimaryText}
            secondaryText={this.text.buttonSecondaryText}
          />
        </div>
        {!submitting && status && this.displayFlashMessage()}
      </SafeForm>
    );
  }
}
