import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const PageDivider = props => {
  const { hasLine } = props;
  const lineClasses = classnames('line-divider', { border: hasLine });

  return <span className={lineClasses} />;
};

PageDivider.propTypes = {
  hasLine: PropTypes.bool
};

export { PageDivider };
