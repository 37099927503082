import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { StyledBreadcrumb } from './styled';
import classnames from 'classnames';
import { connect } from '#/lib/render/connect-deep-compare';
import helpers from '#/lib/decorators/helpers';
import analyticsBus from '#/analytics/analyticsBus';
import Link from '#/components/link-check-spa';
import BackLink from '@ddsweb/link';
import { basicEvent } from '#/analytics/types/basic';
import { HOME } from '#/constants/page-names';
import { isFromSearch } from '#/lib/breadcrumb-helpers';
import { getLanguage } from '#/reducers/app';

const mapStateToProps = state => ({
  language: getLanguage(state)
});

const buildAnalyticPayload = title => ({
  type: 'breadcrumb',
  action: 'delay',
  value: title
});

function breadcrumbValue(currentTitle, props) {
  const index = props.trails.findIndex(trail => trail.label === currentTitle); // get index of trail that was clicked
  if (index < 1) {
    // first element in trail is always home
    return HOME;
  }

  const superCategoryTrail = props.trails[1].label; // second element is always super category
  const restOfTrail = props.trails.slice(2, index + 1); // create array of remaining trail

  // for search page the last element is null
  let reducedValue = restOfTrail.reduce(
    (acc, curr) => acc + (curr.label ? `:${curr.label}` : ''),
    superCategoryTrail
  );

  // if it is a supercategory or category append :all to the string
  if (index === 1 || index === 2) {
    reducedValue = `${reducedValue}:all`;
  }

  return reducedValue;
}

const clickBackToSearch = () => {
  basicEvent(analyticsBus, buildAnalyticPayload('back to search results'));
};

const getClickBreadcrumb = props => event => {
  const currentTitle =
    event.target.innerText !== '' ? event.target.innerText : event.target.title;
  basicEvent(
    analyticsBus,
    buildAnalyticPayload(breadcrumbValue(currentTitle, props))
  );
};

const isPreviousPageSearch = ({ backToUrl, language }) => {
  return backToUrl && isFromSearch(backToUrl.split(language).pop());
};

const spaLink = ({
  children,
  className,
  href,
  onClick,
  'aria-label': ariaLabel
}) => (
  <Link
    to={href}
    className={className}
    onClick={onClick}
    aria-label={ariaLabel}
  >
    {children}
  </Link>
);

const renderBackToResultLink = props => {
  const { backToUrl, t: translate, l, language } = props;
  if (!backToUrl) return null;
  const link = backToUrl.split(language).pop();
  if (!isPreviousPageSearch(props)) return;

  return (
    <BackLink
      href={l(link)}
      icon={{ graphic: 'backwardLink', position: { global: 'left' } }}
      className="breadcrumbs__back-link"
      onClick={clickBackToSearch}
      variant="standalone"
      spaLink={spaLink}
    >
      {translate('product-tile:back-to-search-results')}
    </BackLink>
  );
};

const Breadcrumbs = props => {
  const { t: translate, c: config, trails } = props;

  if (config('hideBreadCrumb')) return null;

  const { homeLink, otherLinks } = useMemo(() => {
    const [homeLink, ...otherLinks] = trails.map((trail, index) => {
      return {
        [index ? 'text' : 'ariaLabel']: trail.label,
        href: trail.linkTo,
        useSPALink: true,
        spaLink: spaLink,
        ...(index > 0 && index === trails.length - 1 && { current: true })
      };
    });

    return { homeLink, otherLinks };
  }, [trails]);

  return (
    <div className="breadcrumbs">
      {renderBackToResultLink(props)}
      <div
        className={classnames('breadcrumbs__content', {
          'breadcrumbs__content--mobile-hide': isPreviousPageSearch(props)
        })}
      >
        <StyledBreadcrumb
          spaLink={spaLink}
          home={homeLink}
          links={otherLinks}
          backToText={translate('product-tile:back-to-aisle', {
            aisleName: ''
          })}
          onClick={getClickBreadcrumb(props)}
          backToLink={otherLinks?.length > 1}
        />
      </div>
    </div>
  );
};

Breadcrumbs.propTypes = {
  backToUrl: PropTypes.string,
  backToUrlText: PropTypes.string,
  l: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  trails: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
      linkTo: PropTypes.string,
      catId: PropTypes.string
    })
  ).isRequired
};

export default connect(mapStateToProps)(helpers(['l', 't', 'c'])(Breadcrumbs));
