import { cloneItem } from '#/utils/clone-item';
import { Item } from '#/lib/records/item';

type ItemTypes = Map<string, Item> | Item[];

const cloneItems = <Items extends ItemTypes>(items: Items): Items => {
  if (Array.isArray(items)) {
    const result = items.map<Item>(item => cloneItem(item));
    return result as Items;
  }

  if (items instanceof Map) {
    const clonedItems = new Map<string, Item>();
    items.forEach((item, key) => clonedItems.set(key, cloneItem(item)));
    return clonedItems as Items;
  }

  return items;
};

export { cloneItems };
