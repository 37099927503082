export const cncMatrixImprovementsVariants = {
  key: '1335',
  variants: {
    default: '1335-a',
    stickyHeaderSlotCellsUiUpdate: '1335-b',
    hyphen: '1335-c',
    stickyHeaderSlotCellsUiUpdateHyphen: '1335-d',
  },
  useCustomerId: true,
};

export enum StickyHeaderOffset {
  COOKIE_BANNER_AND_AMEND_BASKET = 225,
  COOKIE_BANNER = 158,
  AMEND_BASKET = 66,
  DEFAULT = 0,
}

// crossing point between medium and large viewport (in pixels)
export const LAYOUT_WIDTH_BREAKPOINT = 768;
