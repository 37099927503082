import LinkButtonWithFeedback from '#/components/shared/link-button-with-feedback';
import helpers from '#/lib/decorators/helpers';
import React from 'react';
import PayAtDoorButton from '../pay-at-door-button';

export type OwnProps = {
  c: Function;
  isPayAtDoorAmendOrder: boolean;
  payOnlineUrl: string;
  confirmationUrl: string;
  t: Function;
};

const AmendPaymentOptionButton: React.FC<OwnProps> = (props: OwnProps) => {
  const { c: config, confirmationUrl, isPayAtDoorAmendOrder, payOnlineUrl, t: translate } = props;

  if (isPayAtDoorAmendOrder) {
    return (
      <PayAtDoorButton
        confirmationUrl={confirmationUrl}
        text={translate('checkout:continue-confirmation')}
        variant="primary"
      />
    );
  }
  return (
    <LinkButtonWithFeedback
      ddl
      enableSpinner={config('checkout:enableSubmittingSpinner')}
      href={payOnlineUrl}
      primaryText={translate('checkout:continue-payment')}
    />
  );
};

export default helpers(['c', 't'])(AmendPaymentOptionButton);
