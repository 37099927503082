import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import DDSIcon from '@ddsweb/icon';
import { CloseIcon } from '@ddsweb/icon';
import { Heading } from '@ddsweb/heading';
import { XL, GREY_MID_3, GREY_DARK_2, SECONDARY } from '@ddsweb/constants';
import { connect } from '#/lib/render/connect-deep-compare';
import DisplayOnlyInJs from '#/components/shared/display-only-in-js';
import DisplayOnlyInNoJs from '#/components/shared/display-only-in-no-js';
import SearchFeedback from '#/components/search-feedback';
import { openNavMenu } from '#/actions/ui-action-creators';
import Icon from '#/components/shared/icon';
import Link from '#/components/link-check-spa';
import helpers from '#/lib/decorators/helpers';
import { getLanguageLink } from '#/reducers/app';
import RecommenderWrapper from '#/components/recommender';
import { SEARCH, FULL_BASKET } from '#/constants/result-types';
import { SEARCH_DISPLAY_AREA } from '#/constants/recommendations';
import analyticsBus from '#/analytics/analyticsBus';
import { LAST_ORDER_TAB } from '#/constants/favorites';
import { CLEAR_SEARCH } from '#/components/basket-search/constants';
import {
  StyledBodyText,
  StyledIconContainer,
  StyledButton,
  StyledContainer
} from './styled';
import { getFavoritesLanguageLink } from '#/utils/favorite-utils';

const mapDispatchToProps = dispatch => ({
  onBrowseButtonClick() {
    dispatch(openNavMenu());
  }
});

const mapStateToProps = state => ({
  homepageLink: getLanguageLink(state),
  shopLink: getLanguageLink(state, '/shop'),
  promotionsLink: getLanguageLink(state, '/promotions'),
  favoritePageLink: getFavoritesLanguageLink(state, '/favorites')
});

@helpers(['t', 'c'])
@connect(mapStateToProps, mapDispatchToProps, null)
export default class EmptySection extends Component {
  static propTypes = {
    c: PropTypes.func.isRequired,
    className: PropTypes.string,
    clearSearchBar: PropTypes.func,
    favouritePageLink: PropTypes.string.isRequired,
    feedbackEnabled: PropTypes.bool,
    headingText: PropTypes.string,
    homepageLink: PropTypes.string.isRequired,
    icon: PropTypes.string,
    onBrowseButtonClick: PropTypes.func.isRequired,
    primaryButton: PropTypes.string,
    primaryButtonText: PropTypes.string,
    promotionsLink: PropTypes.string,
    shopLink: PropTypes.string,
    showBeansIcon: PropTypes.bool,
    showEmptyTrolleyButtons: PropTypes.bool,
    showSubHeadingMobile: PropTypes.bool,
    subHeadingText: PropTypes.string,
    t: PropTypes.func.isRequired,
    type: PropTypes.string
  };

  static defaultProps = {
    primaryButton: 'browse',
    showSubHeadingMobile: false,
    icon: '',
    showEmptyTrolleyButtons: true,
    className: ''
  };

  renderTescoRecommenders() {
    const { type } = this.props;

    if (type === SEARCH) {
      return (
        <RecommenderWrapper
          page={type}
          configKey={`empty_${type}`}
          displayArea={SEARCH_DISPLAY_AREA}
        />
      );
    }
  }

  focusInput = input => {
    input && input.focus();
  };

  triggerBertieEvent = eventData => {
    analyticsBus().emit('UIEventBasicEvent', eventData);
  };

  triggerEventSearchAndBrowse = (type, value) => {
    switch (type) {
      case LAST_ORDER_TAB:
        this.triggerBertieEvent({
          action: 'now',
          type: 'last order',
          value
        });
        break;
      case FULL_BASKET:
        this.triggerBertieEvent({
          action: 'now',
          type: 'basket',
          value
        });
        break;
    }
  };

  searchButtonCilckHandler = evt => {
    if (evt) {
      evt.preventDefault();
    }

    this.focusInput(document.querySelector('#search-input'));

    const { type } = this.props;

    this.triggerEventSearchAndBrowse(type, 'search');
  };

  favCTAClickHandler = () => {
    this.triggerBertieEvent({
      action: 'delay',
      type: 'basket',
      value: 'shop favourites'
    });
  };

  getEmptyTrolleySearchButton = () => {
    const { t: translate, homepageLink } = this.props;
    return (
      <>
        <DisplayOnlyInJs display="inline-block">
          <button
            className="empty-section--button empty-section--search-button button button-primary"
            data-auto="empty-section--search-button"
            onClick={this.searchButtonCilckHandler}
          >
            {translate('layout:search')}
          </button>
        </DisplayOnlyInJs>
        <DisplayOnlyInNoJs display="inline-block">
          <Link
            className="empty-section--button empty-section--homepage-button button button-primary"
            to={homepageLink}
          >
            {translate('checkout:order-confirmation.back-to-homepage')}
          </Link>
        </DisplayOnlyInNoJs>
      </>
    );
  };

  getButtonDivider = () => {
    const { t: translate } = this.props;
    return (
      <div className="empty-section--spacer-text">
        {translate('checkout:common.or')}
      </div>
    );
  };

  getEmptyTrolleyFavCTAButton = () => {
    const { t: translate, favoritePageLink } = this.props;
    return (
      <Link
        data-auto="empty-section--shop-favorites-button"
        className="empty-section--button empty-section--shop-favorites-button button button-primary"
        to={favoritePageLink}
        onClick={this.favCTAClickHandler}
      >
        {translate('trolley:trolley-favourites.button-text')}
      </Link>
    );
  };

  browseButtonClickHandler = evt => {
    if (evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }

    const { onBrowseButtonClick, type } = this.props;

    this.triggerEventSearchAndBrowse(type, 'browse the store');

    onBrowseButtonClick();
  };

  getPrimaryButton = () => {
    const {
      t: translate,
      primaryButton,
      promotionsLink,
      shopLink,
      clearSearchBar,
      primaryButtonText
    } = this.props;

    const buttonText =
      primaryButtonText || translate('home:new-user-banner.browse-the-store');

    return (
      <>
        {primaryButton === 'browse' && (
          <Link
            data-auto="empty-section--browse-button"
            className="empty-section--button empty-section--browse-button button button-primary"
            to={shopLink}
            onClick={this.browseButtonClickHandler}
          >
            {buttonText}
          </Link>
        )}

        {primaryButton === 'promotions' && (
          <Link
            className="empty-section--button empty-section--promotions-button button button-primary"
            to={promotionsLink}
          >
            {translate('promotions:title')}
          </Link>
        )}

        {primaryButton === CLEAR_SEARCH && (
          <StyledButton
            onClick={clearSearchBar}
            variant={SECONDARY}
            icon={<CloseIcon className="checkmark-icon" />}
          >
            {translate('trolley:basket.clear-search')}
          </StyledButton>
        )}
      </>
    );
  };

  getEmptyTrolleyButtons = () => {
    const { type, c: config } = this.props;
    const showShopFavCTAButton = config('trolleyPageFavCTA');

    if (type === FULL_BASKET && showShopFavCTAButton) {
      return (
        <>
          {this.getEmptyTrolleyFavCTAButton()}
          {this.getButtonDivider()}
          {this.getPrimaryButton()}
        </>
      );
    }

    return (
      <>
        {this.getPrimaryButton()}
        {this.getButtonDivider()}
        {this.getEmptyTrolleySearchButton()}
      </>
    );
  };

  renderIcon = (icon, useBeanIcon) => {
    return useBeanIcon ? (
      <StyledIconContainer className="empty-section--icon-container">
        <DDSIcon
          graphic={icon}
          background={GREY_MID_3}
          size={XL}
          stroke={GREY_DARK_2}
        />
      </StyledIconContainer>
    ) : (
      <div className="empty-section--icon-container">
        <Icon name={icon} />
      </div>
    );
  };

  render() {
    const {
      feedbackEnabled,
      headingText,
      showSubHeadingMobile,
      subHeadingText,
      showEmptyTrolleyButtons,
      className
    } = this.props;

    return (
      <div data-auto="empty-section" className="empty-section main">
        <div className={classnames('empty-section--wrapper', className)}>
          <StyledIconContainer>
            <DDSIcon
              graphic="search"
              background={GREY_MID_3}
              size={XL}
              stroke={GREY_DARK_2}
            />
          </StyledIconContainer>

          <div
            data-auto="empty-section--message"
            className="empty-section--empty-text"
          >
            <Heading headingLevel="3">{headingText}</Heading>

            <div
              className={classnames({
                'empty-section--sub-heading': true,
                'show-sub-heading-mobile': showSubHeadingMobile
              })}
            >
              <StyledBodyText forwardedAs="p">{subHeadingText}</StyledBodyText>
            </div>
          </div>

          <StyledContainer
            className="empty-section--buttons-container"
            showEmptyTrolleyButtons={showEmptyTrolleyButtons}
          >
            {showEmptyTrolleyButtons
              ? this.getEmptyTrolleyButtons()
              : this.getPrimaryButton()}
          </StyledContainer>

          {this.renderTescoRecommenders()}
          <div className="empty-section--buttons-container">
            {feedbackEnabled && <SearchFeedback isEmptyResult={true} />}
          </div>
        </div>
      </div>
    );
  }
}
