import moment from 'moment-timezone';
import { createSelector } from 'reselect';
import { getAppRegion, getIsMobile, getTimezone } from '#/reducers/app';
import {
  getAmendOrderId,
  getGuidePrice,
  getIsAmendBasket,
  getIsTrolleyEmpty,
  getLastSelectedSlot,
} from '#/selectors/trolley';
import {
  PENDING,
  AMENDED,
  ORDER_DUE_WITH_WISMO,
  CANCELLED,
  FAILED_PAYMENT_COLLECTION,
  SUPPORTED_PAYMENT_RETRY_OPTION,
  ORDER_DUE,
} from '#/constants/order-statuses';
import {
  isPendingOrder,
  isAfterAmendCutoff,
  isCancelled,
  sortOrdersBySlotStartTime,
  isWismoTrackingStatusUnAvailable,
  isSupportedTrackingStatus,
  getDeliveryTrackingStatus,
  isOnDemandWismoOrder,
  mapDeliveryTrackingStatus,
  getWismoStepperOrder,
  isHomeDeliveryWismoOrder,
  isLegacyWismoOrder,
  sortOrdersByDeliveryStartTime,
  showCancelledContextCard,
  isOrderWithinSlotWindow,
} from '#/utils/orders';
import { getImHereButtonSessionKeyTemplate } from '#/components/home/context-cards/im-here-button/selector';
import { TOrder } from '#/custom-typings/redux-store/orders-ddl.defs';
import { WismoConfigurationDetails } from '#/custom-typings/redux-store/order-list-details.defs';
import { getDateOnly } from '#/lib/date-helpers';
import { sessionStore } from '#/lib/data-store/client-store.js';
import { getHasSlot } from '#/selectors/trolley/slots';
import { SORT_BY, UK } from '#/constants/common';
import { getKeyWithoutSuffix } from '#/utils/string';
import { TReturnType, imHereStatusValue } from '#/components/home/context-cards/im-here-button/selector';
import { COLLECTION } from '#/constants/shopping-methods';
import { MARKETPLACE_ORDER_SUMMARY } from '#/constants/order-split-view';

export const getOrderListDetails = (state: Store): Store['orderListDetails'] | undefined => state.orderListDetails;

export const getOrderItems = (state: Store): TOrder[] | undefined => {
  const orderListDetails = getOrderListDetails(state);
  return orderListDetails?.items;
};

export const getPendingOrders = createSelector<Store, TOrder[] | undefined, string, TOrder[]>(
  getOrderItems,
  getTimezone,
  (orderList, timezone) => {
    if (!orderList || orderList.length < 1) return [];

    const sortedOrders = sortOrdersBySlotStartTime(orderList, timezone);
    return sortedOrders.filter((order: TOrder) => isPendingOrder(order, timezone));
  },
);

export const getHasPendingOrder = createSelector(getPendingOrders, pendingOrders => pendingOrders?.length > 0);

export const getPendingOrdersByDate = createSelector(getPendingOrders, pendingOrders => {
  const result: Record<string, TOrder[]> = {};

  pendingOrders.forEach(order => {
    if (order.status === PENDING) {
      const date = getDateOnly(order.slot.start);

      result[date] = result[date] || [];
      result[date].push(order);
    }
  });

  return result;
});

export const getRecentNonMarketplacePendingOrder = createSelector(
  getOrderItems,
  getIsAmendBasket,
  getTimezone,
  getAmendOrderId,
  getPendingOrders,
  (orderList, isAmendBasket, timezone, amendOrderId, pendingOrders) => {
    if (!orderList || orderList.length < 1) return null;
    const now = moment()
      .utc()
      .tz(timezone);

    const result =
      isAmendBasket &&
      !!amendOrderId &&
      sortOrdersBySlotStartTime(orderList, timezone).filter(
        (order: TOrder) =>
          moment(order.slot.end)
            .tz(timezone)
            .isAfter(now) &&
          !isCancelled(order.status) &&
          isAmendBasket &&
          getKeyWithoutSuffix(order?.id) === getKeyWithoutSuffix(amendOrderId),
      );

    const amendOrder = (result?.length > 0 && result[result.length - 1]) || null;

    const currentOrder = isAmendBasket
      ? amendOrder
      : pendingOrders
          .filter(order => order.splitView[0].__typename !== MARKETPLACE_ORDER_SUMMARY)
          .find(order => !order.deliveryTracking || isSupportedTrackingStatus(order)) || null;

    if (currentOrder) {
      currentOrder.isInAmendMode = isAmendBasket;
    }
    return currentOrder;
  },
);

export const getWismoConfiguration = (state: Store): WismoConfigurationDetails | undefined => {
  const orderListDetails = getOrderListDetails(state);
  return orderListDetails?.wismoConfiguration;
};

const prioritiseOnDemandWismoOrder = (onDemandOrdersSortedBySlot: TOrder[], timezone: string): TOrder | undefined => {
  /**
   *  ****** prioritisation rules to display ondemand order *****
   * show the Express Delivery that is arriving the soonest
   * if delivery info for the next order in order list is not available, then prioritise the order with earliest slot
   * When, next order in the order list has delivery info available
   * AND delivery info for the current order is not available, then prioritise the next order in queue
   * When both current order and next order in the queue has delivery info available
   * compare and prioritise the order that has the earliest delivery start time
   */

  const ordersWithStatus = onDemandOrdersSortedBySlot.filter(
    (order: TOrder) => !isWismoTrackingStatusUnAvailable(order),
  );

  if (ordersWithStatus?.length > 0) {
    // only one ondemand order with tracking info in order list
    if (ordersWithStatus?.length === 1) {
      return getWismoStepperOrder(ordersWithStatus);
    }
    // prioritise the orders that are having delivery tracking time
    const ordersWithDeliveryTime = ordersWithStatus.filter(
      (order: TOrder) => order.deliveryTracking?.deliveryWindow?.start,
    );

    // all eligible ondemand orders are yet to be scheduled. Hence pick the order that has earliest slot time
    if (!ordersWithDeliveryTime?.length) {
      return getWismoStepperOrder(ordersWithStatus);
    }
    // prioritise the order that has delivery time, when all other orders are yet to be scheduled
    if (ordersWithDeliveryTime?.length === 1) {
      return getWismoStepperOrder(ordersWithDeliveryTime);
    }

    // prioritise the order that has earliest delivery time
    sortOrdersByDeliveryStartTime(ordersWithDeliveryTime, timezone);

    const wismoOrder = getWismoStepperOrder(ordersWithDeliveryTime);
    return wismoOrder;
  }
};

const getHomeDeliveryWismoOrder = (orderList: TOrder[], timezone: string): TOrder | undefined => {
  const homeDeliveryWismoStepperOrders = orderList.filter((order: TOrder) => isHomeDeliveryWismoOrder(order, timezone));

  if (homeDeliveryWismoStepperOrders?.length > 0) {
    const sortedHomeDeliveryWismoStepperOrders = sortOrdersBySlotStartTime(homeDeliveryWismoStepperOrders, timezone);
    const wismoOrder = getWismoStepperOrder(sortedHomeDeliveryWismoStepperOrders);
    return mapDeliveryTrackingStatus(wismoOrder);
  }
};

const getLegacyWismoOrder = (orderList: TOrder[], timezone: string): TOrder | undefined => {
  const legacyWismoOrders = orderList.filter((order: TOrder) => isLegacyWismoOrder(order, timezone));

  if (legacyWismoOrders?.length > 0) {
    const sortedLegacyWismoOrders = sortOrdersBySlotStartTime(legacyWismoOrders, timezone);
    return sortedLegacyWismoOrders[0];
  }
};

export const getCurrentNonMarketplaceWismoOrder = createSelector<
  Store,
  TOrder[] | undefined,
  WismoConfigurationDetails | undefined,
  string,
  TOrder | null
>(getOrderItems, getWismoConfiguration, getTimezone, (orderList, wismoConfiguration, timezone) => {
  const nonMPOrderList = orderList?.filter(order => order.splitView[0].__typename !== MARKETPLACE_ORDER_SUMMARY);
  if (!nonMPOrderList || nonMPOrderList.length < 1) return null;

  if (!wismoConfiguration) return null;

  const { wismoOrderDeliveredDisplayInMinutes, wismoEnabled } = wismoConfiguration;

  if (!wismoEnabled) return null;

  const onDemandOrderList = nonMPOrderList.filter(order =>
    isOnDemandWismoOrder(order, timezone, wismoOrderDeliveredDisplayInMinutes),
  );

  let orderWithUnavailableStatus = null;
  let wismoOrder;

  // there exists ondemand order delivery
  if (onDemandOrderList?.length > 0) {
    /**
     * TODO: NOT_AVAILABLE status is not in use, for now; predicted to be helpful in future
     * so maintain the applicable code
     */
    onDemandOrderList.forEach(mapDeliveryTrackingStatus);

    const onDemandOrdersSortedBySlot = sortOrdersBySlotStartTime(onDemandOrderList, timezone);

    orderWithUnavailableStatus = onDemandOrdersSortedBySlot.filter((order: TOrder) =>
      isWismoTrackingStatusUnAvailable(order),
    );

    wismoOrder = prioritiseOnDemandWismoOrder(onDemandOrdersSortedBySlot, timezone);
    if (wismoOrder) return wismoOrder;
  }

  //experiment 824-b is activated
  wismoOrder = getHomeDeliveryWismoOrder(nonMPOrderList, timezone);
  if (wismoOrder) return wismoOrder;

  wismoOrder = getLegacyWismoOrder(nonMPOrderList, timezone);
  if (wismoOrder) return wismoOrder;

  /**
   * THIS IS NOT IN USE NOW, maintained for future whenever NOT_AVAILABLE will be handled ***
   * In case of not available status for ondemand order,
   * if there is another order that has tracking info available,
   * that order takes priority to get displayed as per other priority rules in wismo,
   * otherwise, not available info will be displayed for ondemand order
   */
  return orderWithUnavailableStatus && orderWithUnavailableStatus[0]
    ? getWismoStepperOrder(orderWithUnavailableStatus)
    : null;
});

export const getMostRecentNonMarketplaceCancelledOrder = (state: Store): TOrder | null => {
  const orderList = getOrderItems(state);
  const timezone = getTimezone(state);

  const cancelledOrders = orderList?.filter(
    order => isCancelled(order.status) && order.splitView[0].__typename !== MARKETPLACE_ORDER_SUMMARY,
  );

  if (cancelledOrders?.length) {
    // filter out all the cancelled orders after today
    const cancelledOrdersFromToday = cancelledOrders.filter(order =>
      moment(order.slot.start).isAfter(moment().startOf('day')),
    );
    // sort cancelledOrdersFromToday for the most recent cancelled order to be at the top
    const sortedCancelledOrders = sortOrdersBySlotStartTime(cancelledOrdersFromToday, timezone, true, SORT_BY.ASC);
    return sortedCancelledOrders[0];
  }
  return null;
};

const selectRecentOrder = createSelector(
  getAmendOrderId,
  getPendingOrders,
  getCurrentNonMarketplaceWismoOrder,
  getRecentNonMarketplacePendingOrder,
  getMostRecentNonMarketplaceCancelledOrder,
  (amendOrderId, pendingOrders, currentWismoOrder, recentPendingOrder, recentCancelledOrder) => {
    if (amendOrderId) {
      return pendingOrders.find(order => order.id === amendOrderId);
    } else {
      return currentWismoOrder || recentPendingOrder || recentCancelledOrder;
    }
  },
);

const getContextCardStatus = createSelector(
  selectRecentOrder,
  getIsAmendBasket,
  getCurrentNonMarketplaceWismoOrder,
  getHasSlot,
  getIsTrolleyEmpty,
  getTimezone,
  getIsMobile,
  getAppRegion,
  (recentOrder, isAmendBasket, currentWismoOrder, hasSlot, IsTrolleyEmpty, timezone, isMobile, region) => {
    if (!recentOrder) return null;
    const shouldShowWismoContextCard = !!currentWismoOrder;
    const shouldShowAmendContextCard = isAmendBasket;
    const shouldShowCancelledContextCard = showCancelledContextCard(recentOrder, timezone);
    const paymentInfo = Array.isArray(recentOrder.fulfilment?.payments) ? recentOrder.fulfilment?.payments[0] : null;
    const retryPaymentAvailable = paymentInfo?.retryOption === SUPPORTED_PAYMENT_RETRY_OPTION.ENABLED;
    const retryPaymentExpired = paymentInfo?.retryOption === SUPPORTED_PAYMENT_RETRY_OPTION.EXPIRED;
    const shouldShowFailedPaymentContextCardCollection =
      (retryPaymentExpired || retryPaymentAvailable) && recentOrder.shoppingMethod === COLLECTION;

    const shouldShowOrderDueContextCardWithImHere =
      recentOrder.shoppingMethod === COLLECTION &&
      recentOrder.status === PENDING &&
      isMobile &&
      recentOrder?.fulfilment?.outlet?.isSmartPhoneRequired &&
      isOrderWithinSlotWindow(recentOrder?.slot, timezone);

    // get the status from the recent order if there are items in the basket or the region is 'UK'
    // if these conditions are met, the recent order card will replace the book a slot card
    const shouldShowSlotBookedContextCard =
      !isAfterAmendCutoff(recentOrder, timezone) &&
      !isCancelled(recentOrder.status) &&
      !hasSlot &&
      (IsTrolleyEmpty || region === UK);

    if (shouldShowAmendContextCard) return AMENDED;
    else if (shouldShowWismoContextCard) return ORDER_DUE_WITH_WISMO;
    else if (shouldShowFailedPaymentContextCardCollection) return FAILED_PAYMENT_COLLECTION;
    else if (shouldShowCancelledContextCard) return CANCELLED;
    else if (shouldShowOrderDueContextCardWithImHere) return ORDER_DUE;
    else if (shouldShowSlotBookedContextCard) return recentOrder.status;
    return null;
  },
);

export const getRecentOrder = createSelector(
  selectRecentOrder,
  getContextCardStatus,
  getIsTrolleyEmpty,
  getLastSelectedSlot,
  getGuidePrice,
  (recentOrder, status, IsTrolleyEmpty, slot, guidePrice) => {
    if (status) {
      const response = {
        address: recentOrder.address,
        expiry: recentOrder.amendExpiryTimeStamp,
        id: recentOrder.id,
        orderNo: recentOrder.orderNo,
        shoppingMethod: recentOrder.shoppingMethod,
        slot: recentOrder.slot,
        storeId: recentOrder.storeId,
        locationUuid: recentOrder.locationUuid,
        status,
        guidePrice: recentOrder.guidePrice,
        confirmationDateTime: recentOrder.confirmationDateTime,
        wismoSlot: recentOrder.deliveryTracking,
        splitView: recentOrder.splitView,
        ...(recentOrder.showWismoStepper ? { showWismoStepper: recentOrder.showWismoStepper } : null),
        payment: Array.isArray(recentOrder.fulfilment?.payments) ? recentOrder.fulfilment?.payments[0] : null,
      };

      return response;
    } else {
      // We fall into this branch if the user has booked a slot or if their trolley has items, but they don't have
      // an order due with Wismo data.

      return {
        expiry: slot.reservationExpiry,
        status: slot.status,
        slot,
        isEmpty: IsTrolleyEmpty,
        cannotPurchaseTrolley: guidePrice === 0 && !IsTrolleyEmpty,
      };
    }
  },
);

export const getWismoOrderAddressName = createSelector(
  getCurrentNonMarketplaceWismoOrder,
  wismoOrder => wismoOrder?.address?.name,
);

export const getWismoOrderTrackingStatus = createSelector(getCurrentNonMarketplaceWismoOrder, wismoOrder =>
  getDeliveryTrackingStatus(wismoOrder),
);

export const getCurrentCancelledOrder = (
  state: Store,
  { cancelledOrderNo }: { cancelledOrderNo: string },
): TOrder | null => {
  const orderList = getOrderItems(state);
  return (!!cancelledOrderNo && orderList?.find(order => order.orderNo === cancelledOrderNo)) || null;
};

export const getPreviousOrders = createSelector(
  getOrderItems,
  getPendingOrders,
  getTimezone,
  (orderList, pendingOrders, timezone) => {
    const previousOrders = orderList?.filter(
      order => !pendingOrders.some(pendingOrder => pendingOrder.id === order.id),
    );
    return sortOrdersBySlotStartTime(previousOrders, timezone, false, SORT_BY.DESC) || [];
  },
);

export const getHasOrders = createSelector(getOrderItems, orders => !!orders && orders.length > 0);

export const getImHereStatusFromRedux = createSelector(
  getOrderItems,
  (state: Store, orderNo: string) => orderNo,
  (items, orderNo): TReturnType | undefined => {
    return items?.find(item => item.orderNo === orderNo)?.imHereStatus;
  },
);

const getImHereStatusFromSessionStore = (locationUuid: string, orderNo: string): boolean => {
  if (sessionStore) {
    const currentOrderKey: string = getImHereButtonSessionKeyTemplate(locationUuid, orderNo);
    const loggedOrderKey = sessionStore.get(currentOrderKey);

    if (loggedOrderKey) {
      return true;
    }
  }
  return false;
};

export const getImHereConfirmed = (state: Store, locationUuid: string, orderNo: string): boolean => {
  // To be left only with redux check when MANGO will deliver ImHere flag
  return (
    getImHereStatusFromRedux(state, orderNo) === imHereStatusValue.RECEIVED ||
    getImHereStatusFromSessionStore(locationUuid, orderNo)
  );
};
