import React from 'react';
import PropTypes from 'prop-types';
import { connect } from '#/lib/render/connect-deep-compare';
import classNames from 'classnames';
import Link from '#/components/link';
import { addModalToUrl } from '#/lib/url/modal-utils';
import { AMEND_CANCEL_MODAL } from '#/constants/modal-names';
import { openModal } from '#/actions/ui-action-creators';
import { getCurrentUrl } from '#/reducers/app';

function CancelAmendButton(props) {
  const handleOnClick = e => {
    e.preventDefault();
    props.openModal(AMEND_CANCEL_MODAL);
  };

  const inverse = props.inverse || false;

  if (props.ddl) {
    return (
      <Link
        className={props.classes}
        disabled={props.disabled}
        href={addModalToUrl(props.currentUrl, AMEND_CANCEL_MODAL)}
        onClick={handleOnClick}
        ddl={props.ddl}
        buttonVariant="secondary"
        stretch
        inverse={inverse}
      >
        {props.buttonText}
      </Link>
    );
  }

  return (
    <Link
      className={classNames(
        'button button-secondary small',
        {
          'button-disabled': props.disabled
        },
        props.classes
      )}
      disabled={props.disabled}
      href={addModalToUrl(props.currentUrl, AMEND_CANCEL_MODAL)}
      onClick={handleOnClick}
      inverse={inverse}
    >
      {props.buttonText}
    </Link>
  );
}

CancelAmendButton.propTypes = {
  buttonText: PropTypes.string.isRequired,
  classes: PropTypes.string,
  currentUrl: PropTypes.string.isRequired,
  ddl: PropTypes.bool,
  disabled: PropTypes.bool,
  inverse: PropTypes.bool,
  openModal: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  currentUrl: getCurrentUrl(state),
  openModal: state.ui.openModal
});

export default connect(mapStateToProps, { openModal })(CancelAmendButton);
