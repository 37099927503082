import { Theme } from '@ddsweb/types';

export interface ColorsInterface {
  [key: string]: string;
}

export const RED = 'red';
export const BLUE = 'blue';
export const GREY = 'grey';
export const YELLOW = 'yellow';
export const DDL_BLUE = 'ddl-blue-2';
export const TESCO_BLUE = 'blue-1';
export const defaultColor = TESCO_BLUE;

const colors: ColorsInterface = {
  [RED]: RED,
  [BLUE]: BLUE,
  [GREY]: GREY,
  [YELLOW]: YELLOW,
  [DDL_BLUE]: DDL_BLUE,
  [TESCO_BLUE]: TESCO_BLUE,
};

export const getHexColorFromTheme = (theme: Theme, color?: string | undefined): string => {
  const validColor = color ? colors[color] || colors[defaultColor] : colors[defaultColor];
  switch (validColor) {
    case RED:
      return theme.colors.tescoRed;
    case BLUE:
      return '#008dc8'; // TODO: Not a theme color yet
    case GREY:
      return theme.colors.grayscale;
    case YELLOW:
      return theme.colors.promo;
    case DDL_BLUE:
      return theme.colors.active;
    case TESCO_BLUE:
    default:
      return theme.colors.tescoBlue;
  }
};

export default colors;
