import styled, { css } from 'styled-components';
import { DefaultStyledProps } from '@ddsweb/types';
import { colors, spacing, lineHeight } from '@ddsweb/selectors';
import { MEDIUM, MEDIUM_LARGE, NOTIFICAION } from '#/components/product-tiles/temp-style-constants';
import { CLUBCARD_LOGO_SIZE } from '#/components/promotions/clubcard-logo/styles';
import { TESCO_MODERN_FONT_FAMILY } from '@ddsweb/constants';

export const StyledPromotionsWithClubcardPriceContainer = styled.div`
  margin: 0;

  &a:hover,
  a:hover {
    text-decoration: none;
  }

  @media ${MEDIUM} {
    margin-top: ${spacing.md};
  }
`;

export const StyledPromotionsOfferContent = styled.div<{
  showCCPriceLogoInSquare: boolean;
  hasPromotionSecondaryText: boolean;
}>`
  display: flex;
  align-self: flex-end;
  background-color: ${colors.promo};
  margin: 0;
  height: ${CLUBCARD_LOGO_SIZE};
  width: calc(100% - ${CLUBCARD_LOGO_SIZE});
  ${({ showCCPriceLogoInSquare }: { showCCPriceLogoInSquare: boolean }): string =>
    showCCPriceLogoInSquare &&
    css`
      font-size: 11px;
    `}
`;

export const StyledClubcardLogo = styled.div`
  position: absolute;
`;

export const ProductPromotionStyling = styled.div<{ isVerticalTile: boolean; hasPromotionSecondaryText: boolean }>`
  && {
    font-family: ${TESCO_MODERN_FONT_FAMILY};

    ${({ isVerticalTile }: { isVerticalTile: boolean }): string =>
      !isVerticalTile &&
      css`
        @media ${MEDIUM_LARGE} {
          padding-left: ${spacing.sm};
        }
      `}

    .offer-text {
      font-weight: bold;
      color: #de1020;
      display: block;
      line-height: ${lineHeight.smHeading};
      margin-top: ${spacing.xxx};
    }

    ${({ isYellowClubcardPromotion }: { isYellowClubcardPromotion: boolean }): string =>
      isYellowClubcardPromotion &&
      css`
        display: flex;
        flex-direction: column;

        @media ${MEDIUM_LARGE} {
          padding-left: 0;
        }

        .offer-text {
          color: ${colors.base};
          padding: 0 ${spacing.xs};
          margin: 0;
          align-self: center;
          max-height: ${CLUBCARD_LOGO_SIZE};
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          word-break: break-word;

          @supports (
            -webkit-line-clamp: ${({ hasPromotionSecondaryText }: { hasPromotionSecondaryText: boolean }): number => (hasPromotionSecondaryText ? 1 : 2)}
          ) {
            display: -webkit-box;
            -webkit-line-clamp: ${({ hasPromotionSecondaryText }: { hasPromotionSecondaryText: boolean }): number =>
              hasPromotionSecondaryText ? 1 : 2};
            -webkit-box-orient: vertical;
            white-space: initial;
          }
        }
      `}

    ${({
      hasPromotionSecondaryText,
      isYellowClubcardPromotion,
    }: {
      hasPromotionSecondaryText: boolean;
      isYellowClubcardPromotion: boolean;
    }): string =>
      hasPromotionSecondaryText &&
      css`
        .offer-secondary-text {
          color: #333333;
          display: block;
          line-height: 14px;
          &:hover {
            text-decoration: underline;
            text-decoration-color: ${isYellowClubcardPromotion ? `${colors.base}` : '#de1020'};
          }
        }
        .offer-text {
          padding: 0;
          align-self: unset;
        }
        .promotions-offer-content {
          flex-direction: column;
          padding: ${spacing.sm};
          .offer-text {
            @supports (-webkit-line-clamp: 1) {
              display: -webkit-box;
              -webkit-line-clamp: 1;
            }
          }
        }
      `}


    :hover {
      .offer-text,
      .offer-secondary-text {
        text-decoration: underline;
        text-decoration-color: ${({ isYellowClubcardPromotion }: { isYellowClubcardPromotion: boolean }): string =>
          isYellowClubcardPromotion ? `${colors.base}` : '#de1020'};
      }
      .dates {
        text-decoration: underline;
        text-decoration-color: ${colors.text};
      }
    }
    .dates {
      color: ${colors.text};
      display: block;
      line-height: ${({ isVerticalTile }: { isVerticalTile: boolean }): string => (isVerticalTile ? '22px' : '18px')};
      margin: ${spacing.xs} 0;
    }
  }
`;

type TColor = (props: DefaultStyledProps) => string;

export const StyledPromotionInfoBox = styled.div<{ missedPromotion: boolean }>`
  padding: 6px ${spacing.sm};
  margin: 6px ${spacing.sm} ${spacing.sm} 0;
  background-color: ${({ missedPromotion }: { missedPromotion: boolean }): string | TColor =>
    missedPromotion ? NOTIFICAION.WARNING : ((colors.backgroundSuccess as unknown) as TColor)};

  @media ${MEDIUM_LARGE} {
    margin: 6px ${spacing.sm};
  }
`;
