import React from 'react';
import helpers from '#/lib/decorators/helpers';
import { connect } from '#/lib/render/connect-deep-compare';
import { getLocations } from '#/reducers/location';
import { getRecentNonMarketplacePendingOrder } from '#/selectors/order-list-details';
import { getSelectedLocation, getSelectedShoppingMethod } from '#/reducers/slot';
import { getBookedSlotLocation } from '#/selectors/slot';
import { getIsAmendBasket, getCurrentValidSlot } from '#/selectors/trolley';
import { SectionMessage } from '#/components/cms-driven/section-message';
import CollectionAddress from '#/components/collection-address';
import SelectedSlotMap from '#/components/slots/selected-slot-map';
import { TrolleyState } from '#/custom-typings/redux-store/trolley.defs';
import AccordionStoreMap from '#/experiments/oop-1627/components/accordion-store-map';
import { isSimpleLocationDetails } from '#/experiments/oop-1627/selectors';
import { getShowSlotUIReskinV2 } from '#/experiments/oop-2205/selectors';
import AddressSlotInfoReskin from '#/experiments/oop-2205/components/address-slot-info-reskin';

type TOwnProps = {
  selectedShoppingMethod: TrolleyState['shoppingMethod'];
  t: (key: string, data?: object) => string;
};

const mapStateToProps = (state: any) => {
  const bookedSlotLocation = getBookedSlotLocation(state);
  const isAmendBasket = getIsAmendBasket(state);
  const showSlotUIReskin = getShowSlotUIReskinV2(state);

  return {
    bookedSlotLocation,
    currentValidSlot: getCurrentValidSlot(state),
    isAmendBasket,
    isSimpleLocationDetails: !(isAmendBasket && bookedSlotLocation) ? isSimpleLocationDetails(state) : null,
    locations: getLocations(state),
    recentPendingOrder: getRecentNonMarketplacePendingOrder(state),
    selectedLocation: getSelectedLocation(state),
    selectedShoppingMethod: getSelectedShoppingMethod(state),
    showSlotUIReskin,
  };
};

type TProps = TOwnProps & ReturnType<typeof mapStateToProps>;

const CollectionAddressSection: React.FC<TProps> = (props: TProps) => {
  const {
    currentValidSlot,
    isAmendBasket,
    isSimpleLocationDetails,
    locations,
    recentPendingOrder,
    selectedLocation,
    selectedShoppingMethod,
    t: translate,
    bookedSlotLocation,
    showSlotUIReskin,
  } = props;

  return (
    <div
      className={`${
        isSimpleLocationDetails ? 'oop1627-book-a-slot__section-container' : 'book-a-slot__section-container'
      } ${showSlotUIReskin ? 'slots-ui-reskin' : ''}`}
    >
      {isSimpleLocationDetails ? (
        <AccordionStoreMap
          content={
            <SelectedSlotMap
              locations={locations}
              bookedSlotLocation={bookedSlotLocation}
              changeLocationEnabled={!isAmendBasket}
              selectedLocation={selectedLocation}
            />
          }
          selectedLocation={selectedLocation}
        />
      ) : (
        <>
          {(!showSlotUIReskin || !isAmendBasket) && (
            <div className="book-a-slot__section-message">
              <SectionMessage size="large" message={translate('slots:your-collection-point')} />
            </div>
          )}
          {isAmendBasket && bookedSlotLocation ? (
            <>
              {showSlotUIReskin ? (
                <AddressSlotInfoReskin
                  shoppingMethod={selectedShoppingMethod}
                  address={recentPendingOrder?.address}
                  slot={currentValidSlot}
                />
              ) : (
                <CollectionAddress
                  shoppingMethod={selectedShoppingMethod}
                  bookedSlotLocation={bookedSlotLocation}
                  address={recentPendingOrder?.address}
                  slot={currentValidSlot}
                />
              )}
            </>
          ) : (
            <SelectedSlotMap
              locations={locations}
              bookedSlotLocation={bookedSlotLocation}
              changeLocationEnabled={!isAmendBasket}
              selectedLocation={selectedLocation}
            />
          )}
        </>
      )}
    </div>
  );
};

export default helpers(['t'])(connect(mapStateToProps)(CollectionAddressSection));
