import { getVariationForExperiment } from '#/lib/optimizely-manager';
import { basketUnavailableModalVariants } from '../constants';

export type SlotExpiryVariantType = typeof basketUnavailableModalVariants.variants[keyof typeof basketUnavailableModalVariants.variants];

const { key, useCustomerId, variants } = basketUnavailableModalVariants;

export function getBasketUnavailableModalVariant(state: Store): SlotExpiryVariantType {
  return getVariationForExperiment(state, key, {}, useCustomerId) || variants.default;
}

export const showItemUnavailableModal = (state: Store): boolean => {
  return getBasketUnavailableModalVariant(state) === variants.showItemUnavailableModal;
};
