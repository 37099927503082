import React, { useState } from 'react';
import helpers from '#/lib/decorators/helpers';
import { TTranslateFunc } from '#/lib/records/helpers.defs';
import { StyledButton } from './styled';
import Icon from '@ddsweb/icon';
import { RIGHT, SM, SECONDARY } from '@ddsweb/constants';
import {
  emitAnalyticsEventForSeeLessButton,
  emitAnalyticsEventForSeeMoreButton,
} from '#/components/analytics/category-chips-event';

interface DOMEvent<T extends HTMLElement> extends Event {
  readonly currentTarget: T;
}

export type Props = {
  t: TTranslateFunc;
};

const CategoryPillButton: React.FC<Props> = (props: Props) => {
  const [seeMore, setSeeMore] = useState(false);
  const { t: translate } = props;
  const seeMoreText = translate('product-list:oop-2191.see-more-categories');
  const seeLessText = translate('product-list:oop-2191.see-less-categories');

  const handleShowMoreClick = (event: DOMEvent<HTMLInputElement>): void => {
    const gridRow = document.getElementById('pills-grid');
    event.stopPropagation();
    event.preventDefault();
    if (gridRow) {
      gridRow.style.height = !seeMore ? 'auto' : '168px';
    }
    !seeMore ? emitAnalyticsEventForSeeMoreButton() : emitAnalyticsEventForSeeLessButton();
    setSeeMore(!seeMore);
  };

  const buttonText = !seeMore ? seeMoreText : seeLessText;
  return (
    <StyledButton
      data-testid="show-more-button"
      aria-label={buttonText}
      aria-expanded={seeMore}
      size={SM}
      icon={<Icon graphic={seeMore ? 'contract' : 'expand'} />}
      iconPosition={RIGHT}
      variant={SECONDARY}
      onClick={handleShowMoreClick}
    >
      {buttonText}
    </StyledButton>
  );
};

export default helpers(['t'])(CategoryPillButton);
