import styled, { css } from 'styled-components';
import TextareaGroup from '@ddsweb/textarea-group';
import CheckboxWithLabel from '@ddsweb/checkbox-with-label';
import { spacing, colors, icons } from '@ddsweb/selectors';
import Spinner from '@ddsweb/spinner';
import Icon from '@ddsweb/icon';
import { Text } from '@ddsweb/text';
import Button from '@ddsweb/button';

export const Wrapper = styled.div`
  padding: 0 ${spacing.sm} ${spacing.sm};
`;

export const PickerNotesProgressWrapper = styled.div`
  position: relative;
  margin-top: -${spacing.xl};
  min-height: ${spacing.xl};
`;

export const StyledTextareaGroup = styled(TextareaGroup)`
  & textarea {
    resize: none;
    height: auto;
    white-space: nowrap;
  }
  .ddsweb-form-group__label.ddsweb-label {
    font-weight: normal;
  }
`;
StyledTextareaGroup.displayName = 'StyledTextareaGroup';

export const StyledSpinner = styled(Spinner)`
  height: ${icons.xsBackgroundSize};
  width: ${icons.xsBackgroundSize};
  align-items: center;
`;

export const StyledButton = styled(Button)`
  .ddsweb-button__container {
    justify-content: flex-start;
  }
`;
StyledButton.displayName = 'AddANoteButton';

export const StyledIcon = styled(Icon)`
  width: ${icons.xsBackgroundSize};
  & path {
    stroke: ${colors.success};
  }
`;

export const StyledFootnote = styled(Text)`
  width: 80%;
`;

export const StatusWrapper = styled.div`
  display: flex;
  height: 28px;
  align-items: center;
  gap: ${spacing.xs};

  & .error {
    color: ${colors.tescoRed};
  }

  & .saved {
    color: ${colors.success};
  }
`;

export const SeparatorWrapper = styled.div`
  margin-bottom: ${spacing.lg};
`;

export const commonLineStyle = css`
  content: '';
  position: absolute;
  display: block;
  height: 10px;
  background-color: ${colors.background};
  border-bottom: 1px solid #b4b4b4;
`;

export const LeftLine = styled.div`
  ${commonLineStyle};
  width: 19px;
  left: 0;
`;

export const Arrow = styled.div`
  ${commonLineStyle};
  width: 10px;
  left: 21px;
  transform: rotate(225deg);
  border-right: 1px solid #b4b4b4;
  margin-top: 5px;
`;

export const RightLine = styled.div`
  ${commonLineStyle};
  left: ${spacing.xxxl};
  right: 0;
`;

export const StyledCheckboxWithLabel = styled(CheckboxWithLabel)`
  padding: 0 ${spacing.sm} ${spacing.sm};
  align-self: flex-start;
`;
