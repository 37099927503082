import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import helpers from '#/lib/decorators/helpers';
import { connect } from '#/lib/render/connect-deep-compare';
import { getLanguage, getTimezone } from '#/reducers/app';

const mapStateToProps = state => ({
  language: getLanguage(state),
  timezone: getTimezone(state)
});

export const DateTile = ({
  c: config,
  date,
  timezone,
  language,
  useFullBasketCardStyles = false
}) => {
  const mDate = moment(date)
    .tz(timezone)
    .locale(language);
  const formats = config('format') || {};
  const dayOfWeek = mDate.format('ddd');
  const dayOfMonth = mDate.format(formats.dayOfMonth || 'D');
  const month = mDate.format(formats.month || 'MMM');

  const formatedDayOfWeek =
    dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1);
  const formattedMonth = month.charAt(0).toUpperCase() + month.slice(1);

  const showDateFullStop = config('showDateFullStop');
  const isGlobalHeader = config('isGlobalHeader');

  // defined as single string outside of jsx to fix screen reader reading out spaces between words
  // HU has different format
  const basketCardStyledText =
    language === 'en-HU' || language === 'hu-HU'
      ? `${formattedMonth} ${dayOfMonth}., ${formatedDayOfWeek}`
      : showDateFullStop
      ? `${formatedDayOfWeek} ${dayOfMonth}. ${formattedMonth}`
      : `${formatedDayOfWeek} ${dayOfMonth} ${formattedMonth}`;

  return !useFullBasketCardStyles ? (
    <span
      className={classnames('context-card-date-tile', {
        'context-card-date-tile--global-header': isGlobalHeader
      })}
    >
      <span className="day">{dayOfWeek}</span>
      <span className="date">{dayOfMonth}</span>
      <span className="month">{month}</span>
    </span>
  ) : (
    <span>{basketCardStyledText}</span>
  );
};

DateTile.propTypes = {
  c: PropTypes.func.isRequired,
  date: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired
};

export default connect(mapStateToProps)(helpers(['c'])(DateTile));
