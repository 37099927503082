export const regularPriceClubCardTileUKTemplate = {
  xxp_clubcard_price_square: {
    valueContent: [
      {
        text: `%{value1}%{value3}`,
        size: 'xl'
      }
    ],
    mode: 'clubcard',
    variant: 'squareLarge',
    regularPrice: {
      valueContent: [
        { size: 'xxx', text: 'Regular Price' },
        {
          size: 'sm',
          text: '%{value2}%{value1}'
        }
      ],
      mode: 'regularPrice',
      variant: 'horizontalMicro'
    }
  },
  xxp_clubcard_price_square_each: {
    valueContent: [
      {
        text: `%{value1}%{value3}`,
        size: 'md'
      },
      {
        text: 'each',
        size: 'xxxx'
      }
    ],
    mode: 'clubcard',
    variant: 'squareLarge',
    regularPrice: {
      valueContent: [
        { size: 'xxx', text: 'Regular Price' },
        {
          size: ['xs', 'xs', 'xs', 'xs', 'xs', 'xx', 'xx', 'xx'],
          text: '%{value2}%{value1} each'
        }
      ],
      mode: 'regularPrice',
      variant: 'horizontalMicro'
    }
  },
  xxp_clubcard_price_rect: {
    valueContent: [
      {
        text: `%{value1}%{value3}`,
        size: 'sm'
      }
    ],
    mode: 'clubcard',
    variant: 'horizontal',
    regularPrice: {
      valueContent: [
        { size: 'xsc', text: 'Regular Price' },
        {
          size: 'sm',
          text: '%{value2}%{value1}'
        }
      ],
      mode: 'regularPrice',
      variant: 'horizontal'
    }
  },
  xxp_clubcard_price_rect_each: {
    valueContent: [
      {
        text: `%{value1}%{value3} each`,
        size: 'sm'
      }
    ],
    mode: 'clubcard',
    variant: 'horizontal',
    regularPrice: {
      valueContent: [
        { size: 'xsc', text: 'Regular Price' },
        {
          size: 'sm',
          text: '%{value2}%{value1}'
        },
        { size: 'xsc', text: 'each' }
      ],
      mode: 'regularPrice',
      variant: 'horizontal'
    }
  },
  x_pound_clubcard_price_square: {
    valueContent: [
      {
        text: `%{value2}%{value1}`,
        size: ['xl', 'xl', 'md', 'sm', 'xs']
      }
    ],
    mode: 'clubcard',
    variant: 'squareLarge',
    regularPrice: {
      valueContent: [
        { size: 'xxx', text: 'Regular Price' },
        {
          size: 'sm',
          text: '%{value2}%{value1}'
        }
      ],
      mode: 'regularPrice',
      variant: 'horizontalMicro'
    }
  },
  x_pound_clubcard_price_square_each: {
    valueContent: [
      {
        text: `%{value2}%{value1}`,
        size: ['md', 'md', 'sm', 'xs']
      },
      {
        text: 'each',
        size: 'xxxx'
      }
    ],
    mode: 'clubcard',
    variant: 'squareLarge',
    regularPrice: {
      valueContent: [
        { size: 'xxx', text: 'Regular Price' },
        {
          size: ['xs', 'xs', 'xs', 'xs', 'xs', 'xx', 'xx', 'xx'],
          text: '%{value2}%{value1} each'
        }
      ],
      mode: 'regularPrice',
      variant: 'horizontalMicro'
    }
  },
  x_pound_clubcard_price_rect: {
    valueContent: [
      {
        size: 'sm',
        text: '%{value2}%{value1}'
      }
    ],
    mode: 'clubcard',
    variant: 'horizontal',
    regularPrice: {
      valueContent: [
        { size: 'xsc', text: 'Regular Price' },
        {
          size: 'sm',
          text: '%{value2}%{value1}'
        }
      ],
      mode: 'regularPrice',
      variant: 'horizontal'
    }
  },
  x_pound_clubcard_price_rect_each: {
    valueContent: [
      {
        size: 'sm',
        text: '%{value2}%{value1} each'
      }
    ],
    mode: 'clubcard',
    variant: 'horizontal',
    regularPrice: {
      valueContent: [
        { size: 'xsc', text: 'Regular Price' },
        {
          size: 'sm',
          text: '%{value2}%{value1}'
        },
        { size: 'xsc', text: 'each' }
      ],
      mode: 'regularPrice',
      variant: 'horizontal'
    }
  },
  any_x_for_x_pound_clubcard_price_square: {
    valueContent: [
      {
        text: 'Any',
        size: 'xxxx'
      },
      {
        text: '%{value1} for %{value3}%{value2}',
        size: 'xs'
      }
    ],
    mode: 'clubcard',
    variant: 'squareLarge',
    regularPrice: {
      valueContent: [
        { size: 'xxx', text: 'Regular Price' },
        {
          size: 'sm',
          text: '%{value2}%{value1}'
        }
      ],
      mode: 'regularPrice',
      variant: 'horizontalMicro'
    }
  },
  any_x_for_x_pound_clubcard_price_square_each: {
    valueContent: [
      {
        text: 'Any %{value1} for',
        size: 'xxxx'
      },
      {
        text: '%{value3}%{value2}',
        size: 'xs'
      }
    ],
    mode: 'clubcard',
    variant: 'squareLarge',
    regularPrice: {
      valueContent: [
        { size: 'xxx', text: 'Regular Price' },
        {
          size: ['xs', 'xs', 'xs', 'xs', 'xs', 'xx', 'xx', 'xx'],
          text: '%{value2}%{value1} each'
        }
      ],
      mode: 'regularPrice',
      variant: 'horizontalMicro'
    }
  },
  any_x_for_x_pound_clubcard_price_rect: {
    valueContent: [
      {
        text: 'Any %{value1} for %{value3}%{value2}',
        size: 'sm'
      }
    ],
    mode: 'clubcard',
    variant: 'horizontal',
    regularPrice: {
      valueContent: [
        { size: 'xsc', text: 'Regular Price' },
        {
          size: 'sm',
          text: '%{value2}%{value1}'
        }
      ],
      mode: 'regularPrice',
      variant: 'horizontal'
    }
  },
  any_x_for_x_pound_clubcard_price_rect_each: {
    valueContent: [
      {
        text: 'Any %{value1} for %{value3}%{value2}',
        size: 'sm'
      }
    ],
    mode: 'clubcard',
    variant: 'horizontal',
    regularPrice: {
      valueContent: [
        { size: 'xsc', text: 'Regular Price' },
        {
          size: 'sm',
          text: '%{value2}%{value1}'
        },
        { size: 'xsc', text: 'each' }
      ],
      mode: 'regularPrice',
      variant: 'horizontal'
    }
  }
};
