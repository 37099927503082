import React from 'react';
import { Wrapper, TextWrapper, CircleWrapper, SquareWrapper } from '#/components/promotions/clubcard-logo/styles';

export type TClubcardLogo = {
  text: string;
  className?: string;
  tileVariant?: string;
  hideOnMobile?: boolean;
  showCCPriceLogoInSquare?: boolean;
};

const ClubcardLogo = ({
  text,
  className,
  tileVariant,
  hideOnMobile,
  showCCPriceLogoInSquare,
}: TClubcardLogo): JSX.Element => (
  <Wrapper className={`${className} special-offer-sash`} tileVariant={tileVariant} hideOnMobile={hideOnMobile}>
    {showCCPriceLogoInSquare ? (
      <SquareWrapper>
        <TextWrapper>{text}</TextWrapper>
      </SquareWrapper>
    ) : (
      <CircleWrapper>
        <TextWrapper>{text}</TextWrapper>
      </CircleWrapper>
    )}
  </Wrapper>
);

export default ClubcardLogo;
