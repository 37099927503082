// Component priority field values
export const PRIORITY_HIGH = 'high';
export const PRIORITY_MEDIUM = 'medium';

// Component positioning values
export const FULL = 'full';
export const HALF = 'half';
export const THIRD = 'third';
export const QUARTER = 'quarter';
export const FIFTH = 'fifth';
export const TWO_THIRDS = 'two-thirds';
export const TWO_QUARTERS = 'two-quarters';
export const THREE_FIFTHS = 'three-fifths';
export const HORIZONTAL = 'horizontal';
export const VERTICAL = 'vertical';
export const LEFT = 'left';
export const CENTER = 'center';
export const RIGHT = 'right';

//Value Tile Types
export const SINGLE_VALUE_MESSAGE = 'singleValueMessage';
export const DOUBLE_VALUE_MESSAGE = 'doubleValueMessage';
export const FIXED_MESSAGE = 'fixedMessage';
export const REGULAR_PRICE_CLUBCARD = 'regularPriceClubCard';

// Value Tile templates for clubcard pricing
export const CLUBCARD_PRICE_TEMPLATE = 'clubcard_price';
export const X_POUND_CLUBCARD_PRICE_TEMPLATE = 'x_pound_clubcard_price';
export const XXP_CLUBCARD_PRICE_TEMPLATE = 'xxp_clubcard_price';
export const ANY_X_FOR_X_POUND_CLUBCARD_PRICE_TEMPLATE =
  'any_x_for_x_pound_clubcard_price';
export const CMS_ERROR_PREFIX = 'CMS_COMPONENT_ERROR';

// DCS v3 endpoint config
export const dcsV3Config = {
  featureKey: 'GHS_DCS_API',
  endpoint: 'v3'
};
// DCS Component Types
export const TRADE_STAMP = 'TradeStamp';
export const SPECIAL_OFFERS = 'Special-Offers';
