import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from '#/lib/render/connect-deep-compare';
import { validateFormOnSubmit } from '#/actions/form-action-creators';
import { scrollToError } from '#/lib/browser/ui-util';
import { getCsrfToken } from '#/reducers/app';

const PIXEL_FOR_BETTER_VISIBILITY_ON_SCROLL = 25;

const mapStateToProps = state => ({
  csrfToken: getCsrfToken(state)
});

@connect(mapStateToProps, { validateFormOnSubmit })
class FormWithValidation extends Component {
  static propTypes = {
    action: PropTypes.string.isRequired,
    analyticsSetting: PropTypes.shape({
      triggerAnalytics: PropTypes.bool,
      onDispatchAnalytics: PropTypes.func
    }),
    children: PropTypes.any,
    csrfToken: PropTypes.string.isRequired,
    formId: PropTypes.string.isRequired,
    method: PropTypes.string.isRequired,
    onSubmit: PropTypes.func,
    validateFormOnSubmit: PropTypes.func.isRequired,
    validators: PropTypes.object.isRequired
  };

  static defaultProps = {
    onSubmit: () => {
      document &&
        document.getElementByName(`form-${this.props.formId}`).submit();
    },
    analyticsSetting: {
      triggerAnalytics: false,
      onDispatchAnalytics: () => {}
    }
  };

  constructor(props) {
    super(props);
  }

  scrollToError = currentTarget => {
    setTimeout(
      () =>
        scrollToError(
          currentTarget,
          '.input-validation-error',
          PIXEL_FOR_BETTER_VISIBILITY_ON_SCROLL
        ),
      0
    );
  };

  onHandleSubmit = event => {
    const target = event.target;
    event.preventDefault();
    const {
      formId,
      validators,
      validateFormOnSubmit,
      analyticsSetting
    } = this.props;
    const hasValidationFailed = validateFormOnSubmit(formId, validators);

    if (hasValidationFailed) {
      if (analyticsSetting.triggerAnalytics) {
        analyticsSetting.onDispatchAnalytics();
      }
      this.scrollToError(target);
    } else {
      this.props.onSubmit(event);
    }
  };

  render() {
    const { action, method, formId } = this.props;
    const formName = `form-${formId}`;
    return (
      <form
        {...{ action, method }}
        onSubmit={this.onHandleSubmit}
        name={formName}
      >
        <input name="_csrf" type="hidden" value={this.props.csrfToken} />
        {this.props.children}
      </form>
    );
  }
}

export default FormWithValidation;
