import styled from 'styled-components';
import { fontSize, spacing } from '@ddsweb/selectors';
import Heading from '@ddsweb/heading';
import Modal from '@ddsweb/modal';
import Button from '@ddsweb/button';
import { TextButtonLink } from '@ddsweb/link';
import { BREAKPOINTS } from '@ddsweb/constants';

export const StyledModal = styled(Modal)`
  height: 256px;
  width: 351px;
  @media (min-width: ${BREAKPOINTS.desktop}px) {
    height: 180px;
  }
`;

export const StyledTitle = styled(Heading)`
  margin-bottom: ${spacing.sm};
`;

export const StyledButtonContainer = styled.div`
  margin-top: ${spacing.md};
  @media (min-width: ${BREAKPOINTS.desktop}px) {
    display: flex;
    flex-direction: row-reverse;
  }
`;

export const StyledNotNowButton = styled(Button)`
  height: 40px;
`;

export const StyledBookASlotButton = styled(TextButtonLink)`
  font-size: ${fontSize.xs};

  margin-bottom: ${spacing.sm};
  @media (min-width: ${BREAKPOINTS.desktop}px) {
    margin: 0px 0px 0px ${spacing.sm};
  }
`;
