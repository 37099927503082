import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ConnectedProps } from 'react-redux';
import { connect } from '#/lib/render/connect-deep-compare';
import { isExternalLink } from '#/lib/taxonomy/taxonomy';
import { getIsMobile, getIsTablet, getIsDesktop, getLanguageLink } from '#/reducers/app';
import { NavItemWithImage } from '#/custom-typings/redux-store/taxonomy.defs';
import { emitAnalyticsEvent, emitAnalyticsEventForCarouselControl } from '#/components/analytics/category-chips-event';
import ImageButton from '#/components/image-button';
import { getCategoryUrl } from '#/components/category-chips/category-chips-helpers';
import { CarouselWrapper, StyledCategoryChips } from '#/components/category-chips/styled';
import { replaceSpecialCharacters } from '#/utils/text-utils';
import { StyledContentCarouselContainer, StyledContentCarousel } from '#/experiments/oop-2191/components/styled';
import { ContentStampWrapper } from '#/experiments/oop-2191/components/StyledContentCarousel';
import { categoryPillV2Variants, seeMoreAccordion } from '#/experiments/oop-2191/constants';
import {
  StyledContentStampContainer,
  StyledContentStampGrid,
  StyledContentStampRow,
} from '#/experiments/oop-2191/components/category-pill-accordion/styled';
import { ContentStampWithButtonWrapper } from '#/experiments/oop-2191/components/category-pill-accordion';
import CategoryPillButton from '#/experiments/oop-2191/components/category-pill-accordion/accordion-button';

type OwnState = {
  showCarousel: boolean;
  languageLink: (url: string) => string;
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
};

export type OwnProps = {
  menuItems: Array<NavItemWithImage>;
  showCarousel: boolean;
  categoryChipVariant: string;
};

const mapStateToProps = (state: Store): OwnState => ({
  languageLink: (url: string): string => getLanguageLink(state, url),
  showCarousel: getIsMobile(state) || getIsTablet(state),
  isMobile: getIsMobile(state),
  isTablet: getIsTablet(state),
  isDesktop: getIsDesktop(state),
});

const connector = connect(mapStateToProps);
type CategoryChipsProps = OwnProps & ConnectedProps<typeof connector>;
@connector
export default class CategoryChips extends PureComponent<CategoryChipsProps> {
  static propTypes = {
    menuItems: PropTypes.array.isRequired,
    showCarousel: PropTypes.bool.isRequired,
    categoryChipVariant: PropTypes.string.isRequired,
  };

  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  carouselControlClicked: boolean;
  constructor(props: CategoryChipsProps) {
    super(props);
    this.carouselControlClicked = false;
  }

  handleCategoryClick = (element: HTMLElement | Element, url: string): void => {
    const node: NavItemWithImage = this.getSelectedNode(element);
    if (!node) {
      return;
    }

    const { router } = this.context;

    this.categoryChipNavigationEvent(node.label, node);

    if (router && !isExternalLink(url)) {
      router.push(url);
    } else {
      window.location.assign(url);
    }
  };

  categoryChipNavigationEvent = (type: string, selectedCat: NavItemWithImage): void => {
    const categoryUrl = getCategoryUrl(type, selectedCat)
      ?.replace(/\//g, ':')
      ?.replace(/-/g, ' ')
      ?.substring(1);
    emitAnalyticsEvent(categoryUrl);
  };

  getBrowseUrl = (selectedCat: NavItemWithImage, type: string): string => {
    const { languageLink } = this.props;
    const categoryUrl = getCategoryUrl(type, selectedCat);
    const link = selectedCat.externalUrl || selectedCat.promotionsUrl || `/shop${categoryUrl}`;

    return languageLink ? languageLink(link) : '';
  };

  getSelectedNode = (linkEl: HTMLElement | Element): NavItemWithImage => {
    const { menuItems } = this.props;
    const filteredMenuItems = menuItems.filter(item => !item?.hideChip);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const index: number = parseInt(linkEl.getAttribute('data-index')!, 10);
    return filteredMenuItems?.[index];
  };

  renderChipButton = (): Array<JSX.Element | undefined> => {
    const { menuItems, categoryChipVariant } = this.props;
    const {
      variants: { largeImageCarousel: largeImageCarouselVariant, largeImageNoCarousel: largeImageNoCarouselVariant },
    } = categoryPillV2Variants;
    let Component: React.ElementType = ImageButton;
    if (categoryChipVariant === largeImageCarouselVariant) {
      Component = ContentStampWrapper;
    }
    if (categoryChipVariant === largeImageNoCarouselVariant) {
      Component = ContentStampWithButtonWrapper;
    }

    return menuItems
      .filter(item => !item?.hideChip)
      .map((node: NavItemWithImage, index: number) => {
        const { name, image, selected, label } = node;
        const elemKey = `${replaceSpecialCharacters(name)}-${index}`;
        const url = this.getBrowseUrl(node, label);

        return (
          <Component
            eIndex={index}
            key={elemKey}
            url={url}
            image={image}
            label={name}
            active={selected}
            onClick={(e: HTMLElement | Element): void => this.handleCategoryClick(e, url)}
          />
        );
      });
  };

  handleControlClick = (): void => {
    if (!this.carouselControlClicked) {
      emitAnalyticsEventForCarouselControl();
      this.carouselControlClicked = true;
    }
  };

  componentWillUnmount = (): void => {
    this.carouselControlClicked = false;
  };

  showCategoryPillButton = (): boolean => {
    const { menuItems, isMobile, isTablet, isDesktop } = this.props;
    const { defaultMobileItemsLength, defaultTabletItemsLength, defaultDesktopItemsLength } = seeMoreAccordion;
    const filteredmenuItemsLength = menuItems.filter(menuItem => menuItem.hideChip === false).length;

    if (
      (filteredmenuItemsLength > defaultMobileItemsLength && isMobile) ||
      (filteredmenuItemsLength > defaultTabletItemsLength && isTablet) ||
      (filteredmenuItemsLength > defaultDesktopItemsLength && isDesktop)
    ) {
      return true;
    }

    return false;
  };

  renderCategoryChipComponent = (): React.ElementType => {
    const { showCarousel, categoryChipVariant } = this.props;
    const {
      variants: {
        default: defaultVariant,
        largeImageCarousel: largeImageCarouselVariant,
        largeImageNoCarousel: largeImageNoCarouselVariant,
      },
    } = categoryPillV2Variants;

    // Variant B - largeImageCarousel
    if (categoryChipVariant === largeImageCarouselVariant) {
      return StyledContentCarouselContainer;
    }

    // Variant C - largeImageNoCarousel
    if (categoryChipVariant === largeImageNoCarouselVariant) {
      return StyledContentStampContainer;
    }

    // Default Variant for Mobile and Tablet
    if (categoryChipVariant === defaultVariant && showCarousel) {
      return CarouselWrapper;
    }

    // Default Variant for Desktop
    return StyledCategoryChips;
  };

  render(): JSX.Element {
    const { categoryChipVariant } = this.props;
    const Component: React.ElementType = this.renderCategoryChipComponent();
    const {
      variants: { largeImageCarousel: largeImageCarouselVariant, largeImageNoCarousel: largeImageNoCarouselVariant },
    } = categoryPillV2Variants;

    return (
      <Component
        id="pill-carousel"
        itemWidth={categoryChipVariant === largeImageCarouselVariant ? '132px' : '220px'}
        onControlClick={this.handleControlClick}
      >
        {categoryChipVariant === largeImageNoCarouselVariant ? (
          <>
            <StyledContentStampGrid id="pills-grid">
              <StyledContentStampRow>{this.renderChipButton()}</StyledContentStampRow>
            </StyledContentStampGrid>
            {this.showCategoryPillButton() && <CategoryPillButton />}
          </>
        ) : categoryChipVariant === largeImageCarouselVariant ? (
          <StyledContentCarousel onControlClick={this.handleControlClick}>
            {this.renderChipButton()}
          </StyledContentCarousel>
        ) : (
          this.renderChipButton()
        )}
      </Component>
    );
  }
}
