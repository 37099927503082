import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { LEGACY } from '#/components/product-tiles/common/constants';
import {
  FAVORITES_CATEGORY,
  FUZZY,
  PROMOTIONS,
  SPELLCHECKER
} from '#/constants/result-types';
import { FLEXI } from '#/constants/tile-types';
import helpers from '#/lib/decorators/helpers';
import {
  exposeActiveTestData,
  exposeActiveTestDataBatch
} from '#/lib/optimizely-manager';
import { connect } from '#/lib/render/connect-deep-compare';
import { getCurrentPath, getLanguage } from '#/reducers/app';
import {
  getCancelUrl,
  getCurrentFilters,
  getFacets,
  getFilters,
  getIsUpdating,
  getSortOptions
} from '#/reducers/filter';
import {
  getCount,
  getServerSideExperiments,
  getUpdatingStatus
} from '#/reducers/results';
import { getHeaderMultiSearchLinkVisibility } from '#/reducers/ui';
import { getUser } from '#/reducers/user';
import { areMultiSearchResults } from '#/selectors/results/are-multi-search-results';
import { getIsAmendBasket, getIsOnDemandDelivery } from '#/selectors/trolley';
import getScrollDoc from 'scroll-doc';
import Breadcrumbs from '#/components/breadcrumbs';
import CategoryChips from '#/components/category-chips';
import { DfpContainer } from '#/components/cms-driven/dfp-container';
import { DfpPanelWrappper } from '#/components/cms-driven/dfp-panel-wrapper';
import { selectedFacetLists } from '#/components/mixins/filters';
import MultiSearchResultsList from '#/components/multi-search/multi-search-results-list';
import FilterRootPage from '#/components/sort-n-filter/filter-root';
import HasResults from '#/components/results/has-results';
import NoResults from '#/components/results/no-results';
import ResultTitle from '#/components/results/shared/result-title';
import { isSelectedTabFavorites } from '#/lib/favorites-helpers';
import { displayNoResultModal } from '#/actions/ui-action-creators';
import { CATEGORY as CATEGORY_RESULT_TYPE } from '#/constants/result-types';
import SearchPills from '#/experiments/oop-1826/components/search-pills';
import { getShouldShowRelatedSearchInTop } from '#/experiments/oop-1826/selectors';
import { getCategoryChipsVariant } from '#/experiments/oop-2191/selectors';
import { categoryPillV2Variants } from '#/experiments/oop-2191/constants';

const scrollDoc = process.env.CLIENT_SIDE ? getScrollDoc() : null;

const mapStateToProps = (state, ownProps) => {
  const shouldShowResults = !!getCount(state);
  const url = getCurrentPath(state);

  return {
    experimentsFromServer: getServerSideExperiments(state),
    isMultiSearch: areMultiSearchResults(state),
    language: getLanguage(state),
    filterStoreFacets: getFacets(state),
    filterStoreCurrentFilter: getCurrentFilters(state),
    filterStoreFilters: getFilters(state),
    filterStoreIsUpdating: getIsUpdating(state),
    filterStoreSortOptions: getSortOptions(state),
    filterStoreCancelUrl: getCancelUrl(state),
    isAmendBasket: getIsAmendBasket(state),
    shouldShowResults,
    isUpdating: getUpdatingStatus(state),
    multiSearchEnabled: ownProps.f('multiSearchEnabled'),
    multiSearchLinkVisible: getHeaderMultiSearchLinkVisibility(state),
    shouldShowGridView: isSelectedTabFavorites(url),
    user: getUser(state),
    showCategoryChips: !getIsOnDemandDelivery(state),
    showRelatedSearchInTop: getShouldShowRelatedSearchInTop(state),
    categoryChipVariant: getCategoryChipsVariant(state)
  };
};

const mapDispatchToProps = { displayNoResultModal };

@helpers(['f', 't', 'c'])
@connect(mapStateToProps, mapDispatchToProps)
export default class Results extends Component {
  static propTypes = {
    actionButtons: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    ),
    breadcrumbs: PropTypes.array,
    dfp: PropTypes.shape({
      pageId: PropTypes.string.isRequired,
      targeting: PropTypes.object
    }),
    experiments: PropTypes.object,
    experimentsFromServer: PropTypes.any,
    f: PropTypes.func.isRequired,
    filterStoreCancelUrl: PropTypes.string,
    filterStoreCurrentFilter: PropTypes.object,
    filterStoreFacets: PropTypes.array,
    filterStoreFilters: PropTypes.array,
    filterStoreIsUpdating: PropTypes.bool,
    filterStoresOpen: PropTypes.bool,
    filterStoreSortOptions: PropTypes.array,
    hasHeadingTag: PropTypes.bool,
    headingTag: PropTypes.string,
    isAmendBasket: PropTypes.bool.isRequired,
    isGrid: PropTypes.bool,
    isGrouped: PropTypes.bool,
    isMultiSearch: PropTypes.bool.isRequired,
    isSearchResult: PropTypes.bool,
    isUpdating: PropTypes.bool,
    language: PropTypes.string.isRequired,
    listData: PropTypes.object,
    matchType: PropTypes.string,
    multiSearchEnabled: PropTypes.bool.isRequired,
    multiSearchLinkVisible: PropTypes.bool,
    productTileVariant: PropTypes.string,
    query: PropTypes.string,
    renderProductFilters: PropTypes.func,
    renderShowMore: PropTypes.func,
    resultData: PropTypes.object,
    resultType: PropTypes.string.isRequired,
    sectionMessageHeadingTag: PropTypes.string,
    shouldShowResults: PropTypes.bool.isRequired,
    shouldShowGridView: PropTypes.bool.isRequired,
    showBrandsFilter: PropTypes.bool,
    showCategoriesFilter: PropTypes.bool,
    showCategoryChips: PropTypes.bool.isRequired,
    showDietaryFilter: PropTypes.bool,
    showFilters: PropTypes.bool,
    showMiniTrolley: PropTypes.bool,
    showMobilePagination: PropTypes.bool,
    showPagination: PropTypes.bool,
    showPromotionsFilter: PropTypes.bool,
    showRecommendedCarousel: PropTypes.bool,
    showRelatedSearchInTop: PropTypes.bool,
    showSuperDepartmentFilter: PropTypes.bool,
    showUnavailableWarning: PropTypes.bool,
    shouldUseDDSPagination: PropTypes.bool,
    t: PropTypes.func.isRequired,
    tileType: PropTypes.string,
    triggerAnalyticsEvent: PropTypes.bool,
    user: PropTypes.object.isRequired,
    categoryChipVariant: PropTypes.string,
    viewAllFilterFlags: PropTypes.array,
    withListOverlay: PropTypes.bool,
    isInSpecialOffersExperiment: PropTypes.bool
  };

  static defaultProps = {
    actionButtons: [],
    breadcrumbs: [],
    hasHeadingTag: true,
    headingTag: 'h1',
    isGrid: true,
    isSearchResult: true,
    tileType: FLEXI,
    shouldShowGridView: false,
    showFilters: true,
    showMiniTrolley: true,
    showMobilePagination: false,
    showPagination: true,
    shouldUseDDSPagination: false,
    showPromotionsFilter: true,
    showRecommendedCarousel: true,
    productTileVariant: LEGACY,
    withListOverlay: false,
    isInSpecialOffersExperiment: false
  };

  constructor(props) {
    super(props);
    this.state = {
      filtersOpen: this.props.filterStoresOpen
    };
    this.topEl = React.createRef();
    this.enableDfpProductList = props.f('dfpProductList');
  }

  componentDidMount() {
    const { experimentsFromServer = [] } = this.props;
    if (Array.isArray(experimentsFromServer)) {
      experimentsFromServer.forEach(experiment =>
        exposeActiveTestData(experiment)
      );
    } else {
      // For legacy format of experiments being a key map
      exposeActiveTestDataBatch(experimentsFromServer);
    }

    this.handleNoResults();
  }

  handleNoResults = () => {
    const { shouldShowResults, displayNoResultModal, resultType } = this.props;

    if (resultType === CATEGORY_RESULT_TYPE && !shouldShowResults) {
      displayNoResultModal();
    }
  };

  shouldComponentUpdate(nextProps) {
    // Don't trigger a re-render when the component status is already updating and hasn't changed...
    return !(
      this.props.isUpdating && this.props.isUpdating === nextProps.isUpdating
    );
  }

  displayFilter = () => {
    this.setState({ filtersOpen: true });
  };

  hideFilter = () => {
    this.setState({ filtersOpen: false });
  };

  getMultiSearchList() {
    if (this.props.multiSearchEnabled && this.props.isMultiSearch) {
      return <MultiSearchResultsList />;
    }

    return null;
  }
  renderBanner() {
    const { dfp } = this.props;

    if (!(dfp && this.enableDfpProductList)) {
      return;
    }

    const { pageId, targeting } = dfp;

    return (
      <div className="results-dfp results__dfp-panel">
        <DfpContainer pageId={pageId} targeting={targeting} />
        <DfpPanelWrappper targeting={targeting} />
      </div>
    );
  }

  getSearchHeading(headingProps) {
    return <ResultTitle {...headingProps} />;
  }

  getTitle(hasResults) {
    const { hasHeadingTag, isSearchResult, query, matchType } = this.props;
    const HeadingTag = this.props.headingTag;
    const spellcheckerHeadingTextMessage = 'search:spellchecker-products-found';
    const spellcheckerHeadingSubTextMessage =
      'search:spellchecker-no-products-found';
    const fuzzyHeadingTextMessage = 'search:no-products-found';
    const fuzzyHeadingSubTextMessage = 'search:showing-closest-matches';

    if (!hasHeadingTag) {
      return null;
    }
    if (isSearchResult) {
      if (hasResults && matchType === SPELLCHECKER) {
        return this.getSearchHeading({
          headingTextMessage: spellcheckerHeadingTextMessage,
          headingTextQuery: this.props.resultData.pageInformation.query
            .actualTerm,
          headingSubTextMessage: spellcheckerHeadingSubTextMessage,
          headingSubTextQuery: this.props.resultData.pageInformation.query
            .searchTerm,
          headingTag: this.props.headingTag
        });
      }

      if (hasResults && matchType === FUZZY) {
        return this.getSearchHeading({
          headingTextMessage: fuzzyHeadingTextMessage,
          headingTextQuery: query,
          headingSubTextMessage: fuzzyHeadingSubTextMessage,
          headingTag: this.props.headingTag
        });
      }

      return (
        <HeadingTag
          data-auto="search-results-page-heading"
          className="heading query"
        >
          {`${this.props.t('search:results-for')} “${this.props.query}”`}
        </HeadingTag>
      );
    }

    return <HeadingTag className="heading query">{query}</HeadingTag>;
  }

  getNoResultsComponent() {
    const { filterStoreCurrentFilter } = this.props;
    const resultType =
      filterStoreCurrentFilter &&
      filterStoreCurrentFilter.superdepartment &&
      this.props.resultType !== PROMOTIONS
        ? FAVORITES_CATEGORY
        : this.props.resultType;

    return <NoResults query={this.props.query} type={resultType} />;
  }

  getAccessibleMSTitle() {
    if (this.props.isMultiSearch) {
      return (
        <span aria-live="polite" className="visually-hidden">
          {this.props.t('search:multi-search-results-page')}
        </span>
      );
    }
  }

  renderResults() {
    const element = this.topEl.current;
    const scrollYPos =
      element && scrollDoc
        ? element.getBoundingClientRect().top -
          scrollDoc.getBoundingClientRect().top
        : 0;
    const props = this.props;

    return (
      <HasResults
        actionButtons={props.actionButtons}
        breadcrumbs={props.breadcrumbs}
        dfp={props.dfp}
        experiments={props.experiments}
        facetLists={props.filterStoreFacets}
        filtersUpdating={props.filterStoreIsUpdating}
        hideFiltersOverlay={this.hideFilter}
        isAmendBasket={props.isAmendBasket}
        isGrid={props.isGrid}
        isGrouped={props.isGrouped}
        listData={props.listData}
        query={props.query}
        productTileVariant={props.productTileVariant}
        renderShowMore={props.renderShowMore}
        sectionMessageHeadingTag={props.sectionMessageHeadingTag}
        scrollTopPos={scrollYPos}
        showFilters={props.showFilters}
        showMobilePagination={props.showMobilePagination}
        showFiltersOverlay={this.displayFilter}
        showPagination={props.showPagination}
        showSuperDepartmentFilter={props.showSuperDepartmentFilter}
        showCategoriesFilter={props.showCategoriesFilter}
        showPromotionsFilter={props.showPromotionsFilter}
        showRecommendedCarousel={props.showRecommendedCarousel}
        showBrandsFilter={props.showBrandsFilter}
        showDietaryFilter={props.showDietaryFilter}
        showUnavailableWarning={props.showUnavailableWarning}
        sortOptions={props.filterStoreSortOptions}
        tileType={props.tileType}
        resultType={props.resultType}
        user={props.user}
        triggerAnalyticsEvent={props.triggerAnalyticsEvent}
        withListOverlay={props.withListOverlay}
        searchSuggestions={props.resultData?.suggestions?.searchTerms}
        renderProductFilters={props.renderProductFilters}
        shouldUseDDSPagination={props.shouldUseDDSPagination}
      />
    );
  }

  render() {
    const {
      results: { taxonomy = [] } = {},
      shouldShowResults,
      breadcrumbs,
      language,
      query,
      filterStoreFacets,
      showFilters,
      filterStoresOpen,
      filterStoreCancelUrl,
      filterStoreFilters,
      showPromotionsFilter,
      filterStoreSortOptions,
      filterStoreIsUpdating,
      viewAllFilterFlags,
      showMiniTrolley,
      shouldShowGridView,
      showRelatedSearchInTop,
      isAmendBasket,
      multiSearchLinkVisible,
      showCategoryChips,
      resultData,
      isInSpecialOffersExperiment,
      categoryChipVariant
    } = this.props;
    const { suggestions: { searchTerms } = {} } = resultData || {};
    const hasResults = shouldShowResults;
    let breadcrumbsComponent;
    const breadcrumbsProp = breadcrumbs;
    const breadcrumbsCount = breadcrumbs.length;

    if (breadcrumbsCount) {
      let breadcrumbBackToUrl =
        breadcrumbsCount > 2
          ? breadcrumbsProp[breadcrumbsCount - 2].linkTo
          : null;

      breadcrumbBackToUrl =
        breadcrumbBackToUrl && breadcrumbBackToUrl.split(language).pop();
      breadcrumbsComponent = (
        <Breadcrumbs trails={breadcrumbsProp} backToUrl={breadcrumbBackToUrl} />
      );
    }

    const heading = (
      <div
        className={classnames('heading-label', {
          'heading-label-with-variant':
            categoryChipVariant ===
            categoryPillV2Variants.variants.largeImageCarousel,
          invisible: !query
        })}
        id="results"
      >
        {this.getAccessibleMSTitle()}
        {this.getTitle(hasResults)}
      </div>
    );

    const results = hasResults
      ? this.renderResults()
      : this.getNoResultsComponent();
    const selectedFacetListsArray = selectedFacetLists(filterStoreFacets);
    const filterRootPage = showFilters && (
      <FilterRootPage
        displayed={filterStoresOpen}
        filterCancelUrl={filterStoreCancelUrl}
        filters={filterStoreFilters}
        onCancel={this.hideFilter}
        selectedFacetLists={selectedFacetListsArray}
        showPromotionsFilter={showPromotionsFilter}
        sortOptions={filterStoreSortOptions}
        updating={filterStoreIsUpdating}
        viewAllFlags={viewAllFilterFlags}
      />
    );

    const productList = !this.state.filtersOpen && (
      <div
        id="product-list"
        className={classnames('page-container', {
          'no-results': !hasResults
        })}
      >
        <div id="top" ref={this.topEl} />
        <div
          className={classnames('product-list-view', {
            'no-results': !hasResults,
            'has-trolley': showMiniTrolley,
            'has-fav-switch': shouldShowGridView
          })}
        >
          {breadcrumbsComponent}
          {!isInSpecialOffersExperiment ? this.renderBanner() : null}
          {showRelatedSearchInTop && searchTerms?.length > 0 ? (
            <SearchPills relatedSearchTerms={searchTerms} />
          ) : null}

          {this.getMultiSearchList()}
          {heading}
          {showCategoryChips && taxonomy && taxonomy.length > 0 && (
            <CategoryChips
              menuItems={taxonomy}
              categoryChipVariant={categoryChipVariant}
            />
          )}
          {results}
        </div>
      </div>
    );

    return (
      <div
        className={classnames('results-page', {
          'no-results': !hasResults,
          amending: isAmendBasket,
          'multi-search--visible': multiSearchLinkVisible
        })}
      >
        {productList}
        {filterRootPage}
      </div>
    );
  }
}
