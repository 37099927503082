import {
  getProductPrice,
  getProductUnitOfMeasure,
  getProductUnitPrice,
  getProductDepositAmount,
  getProductDrsChargeAmount,
} from '#/selectors/item';
import { getCurrency } from '#/reducers/app';
import formatNumber from '#/lib/string-formatting/number-formatter';
import { Item } from '#/lib/records/item';
import { getAmountWithCurrencyOrMinorCurrency } from '#/lib/string-formatting/currency-formatter';
import { TCurrency } from '#/custom-typings/redux-store/app.defs';

const LEFT = 'left';

type TTranslateFunc = (...args: any[]) => string;

export type TPriceData = {
  priceText: string;
  priceSubtext: string;
  drsText?: string;
  assistiveDrsText?: string;
};

export const getDisplayPrice = (currency: any, price: number): string => {
  const { symbolPosition, symbol, spaceBetweenSymbolAndValue } = currency;
  const space = spaceBetweenSymbolAndValue ? ' ' : '';
  const value = formatNumber(Math.abs(price), currency);

  if (symbolPosition === LEFT) return `${symbol}${space}${value}`;

  return `${value}${space}${symbol}`;
};

const getPriceSubtext = (item: Item, translation: TTranslateFunc, currency: any): string => {
  const unitOfMeasure = getProductUnitOfMeasure(item);
  const unitPrice = getProductUnitPrice(item);
  const price = getDisplayPrice(currency, unitPrice);
  const unit =
    translation(`product-tile:unit.${unitOfMeasure}`, null, {
      returnNullIfFalsy: true,
    }) || unitOfMeasure;
  const perQuantity = `${price}/${unit}`;

  return perQuantity;
};

const getPriceDeposit = (item: Item, translation: TTranslateFunc, currency: any) => {
  const depositAmount = getProductDepositAmount(item);

  if (depositAmount) {
    const currencyConfig = { ...currency, showAsciiSymbol: true };
    const depositPrice = formatNumber(depositAmount, currencyConfig);
    return {
      depositAmount: `${translation('product-tile:deposit-text-1')} ${depositPrice} ${translation(
        'product-tile:deposit-text-2',
      )}`,
    };
  }

  return {};
};

export const getDRSText = (drsAmount: number, translation: TTranslateFunc, currency: TCurrency): string => {
  return `+ ${getAmountWithCurrencyOrMinorCurrency(drsAmount, currency?.symbol, currency?.isoCode)} ${translation(
    'deposit',
  )}`;
};

export const getAssistiveDrsText = (drsAmount: number, translation: TTranslateFunc, currency: TCurrency): string => {
  return `+ ${currency?.symbol}${drsAmount} ${translation('deposit')}`;
};

export const getPriceData = (state: any, item: Item, translation: TTranslateFunc, isTrolley?: boolean): TPriceData => {
  const currency = getCurrency(state);
  const sellableUnitPrice = getProductPrice(item);
  const drsAmount = getProductDrsChargeAmount(item);

  return {
    priceText: getDisplayPrice(currency, sellableUnitPrice),
    priceSubtext: getPriceSubtext(item, translation, currency),
    ...(!isTrolley &&
      drsAmount && {
        drsText: getDRSText(drsAmount, translation, currency),
        assistiveDrsText: getAssistiveDrsText(drsAmount, translation, currency),
      }),
    ...getPriceDeposit(item, translation, currency),
  };
};
