import { Item } from '#/lib/records/item';
import { Dispatch, GetStore } from '#/custom-typings/redux-store/common';
import { cloneItem } from '#/utils/clone-item';
import { itemsToMap } from '#/lib/records/product-utils';
import { updatePage, deleteFavouriteItemJSOnly } from '#/actions/results-actions';
import { getLanguageLink } from '#/reducers/app';
import { getProductId } from '#/selectors/item';
import { updateResource } from '#/actions/resources-action-creators';
import { FAVORITES } from '#/constants/spa-resource';
import { getFavoritesResource, getResource } from '#/selectors/resources';

export const removeFromFavorites = (productId: string) => {
  return (dispatch: Dispatch, getState: GetStore): void => {
    const { pages, pageNo, resultsType, supportsFlexiPageSize, experiments } = getState().results;
    const currentPageNo = pageNo - 1;
    const pageData = pages[currentPageNo];
    const items = pageData instanceof Map ? [...pageData.values()] : pageData;
    const index = items.findIndex((item: Item) => getProductId(item) === productId);
    if (index > -1) {
      const item = items[index];
      const updatedItem = cloneItem({
        ...item,
        product: {
          ...item.product,
          isInFavourites: false,
        },
      });
      items[index] = updatedItem;
      dispatch(updatePage(currentPageNo, itemsToMap(items), resultsType, supportsFlexiPageSize, experiments));

      const url = getLanguageLink(getState(), `/favorites/items/${productId}`);
      dispatch(deleteFavouriteItemJSOnly(url, false, false));

      const currentResourceData = getResource(resultsType)(getState());
      if (currentResourceData) {
        dispatch(updateResource(resultsType, currentResourceData.data, Date.now()));
      }

      const currentFavResourceData = getFavoritesResource(getState());
      if (currentFavResourceData) {
        dispatch(updateResource(FAVORITES, currentFavResourceData.data, Date.now()));
      }
    }
  };
};
