import moment from 'moment-timezone';
import formatNumber from '#/lib/string-formatting/number-formatter';
import { Promotion, PromotionPrice } from '#/lib/records/item.defs';

type TReconstructOffer = {
  showReconstructedOfferMsg: boolean;
  symbol: string;
};

const PROMOTION_ATTRIBUTE_CLUBCARD_PRICING = 'CLUBCARD_PRICING';

export const getInclusivePromoEndDate = (endDate: string | undefined, timezone: string): string => {
  const endDateUTC = moment.utc(endDate);
  const isInputDateinUTC = endDate === endDateUTC.format();
  const newEndDate = endDateUTC
    .tz(timezone)
    .clone()
    .subtract(1, 'seconds');
  return isInputDateinUTC ? moment.utc(newEndDate).format() : newEndDate.format();
};

export const reconstructCutPriceOfferText = (
  offerText: string,
  curSymbol: string,
  beforeDiscount: number,
  afterDiscount: number,
): string => {
  const beforeDiscountAmount = formatNumber(beforeDiscount, { decimalPlaces: 2 });
  const afterDiscountAmount = formatNumber(afterDiscount, { decimalPlaces: 2 });

  return `${offerText} Was ${curSymbol}${beforeDiscountAmount} Now ${curSymbol}${afterDiscountAmount}`;
};

export const reconstructOfferText = (
  offerText: string,
  attributes: string[] | undefined,
  reconstructOfferArgs: TReconstructOffer,
  price?: PromotionPrice,
): string => {
  const isClubcardPricing = attributes?.includes(PROMOTION_ATTRIBUTE_CLUBCARD_PRICING);
  const { showReconstructedOfferMsg, symbol } = reconstructOfferArgs;

  return price?.beforeDiscount && price.afterDiscount && !isClubcardPricing && !!showReconstructedOfferMsg
    ? reconstructCutPriceOfferText(offerText, symbol, price.beforeDiscount, price.afterDiscount)
    : offerText;
};

export const getModifiedPromotion = (
  promotion: Promotion,
  timezone: string,
  reconstructOfferArgs: TReconstructOffer,
): Promotion => {
  const { endDate, price, offerText, attributes } = promotion;

  return {
    ...promotion,
    endDate: endDate && getInclusivePromoEndDate(endDate, timezone),
    offerText: reconstructOfferText(offerText, attributes, reconstructOfferArgs, price),
  };
};
