import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { ResponsiveBackground } from '../responsive-background';

const TradeTileRight = props => {
  const {
    title,
    body,
    priority,
    fullWidthTablet,
    tileType,
    tileInverted,
    panelColor,
    primary_link,
    secondary_link,
    background,
    componentClickHandler,
    placement
  } = props;

  const stampClasses = classnames(
    'trade-tile',
    'trade-tile__type-right',
    [`trade-tile__${priority}`],
    { tile__has_body: body },
    { tile__has_no_body: !body },
    tileType ? [`trade-tile__${tileType}`] : null,
    { tile_full_width: fullWidthTablet },
    { tile_alternate_img: tileInverted },
    { tile_has_panel: panelColor && panelColor.backgroundColor }
  );
  const onClick = () => componentClickHandler?.(props);

  const OuterTag =
    secondary_link && secondary_link.href && secondary_link.label ? 'div' : 'a';
  const InnerTag =
    secondary_link && secondary_link.href && secondary_link.label
      ? 'a'
      : 'span';

  return (
    <div className={stampClasses} onClick={onClick}>
      <OuterTag
        className="tile--a clear"
        href={primary_link && primary_link.href}
        target={primary_link && primary_link.newWindow ? '_blank' : null}
        style={panelColor}
      >
        <span className="img-container">
          <ResponsiveBackground
            className="tile--img"
            url={background.src}
            maxWidth={background.maxWidth}
            uid={placement}
          />
        </span>
        <span className="tile--caption-wrapper">
          <span className="caption--title">{title}</span>
          {body ? <span className="caption--body">{body}</span> : null}
          {primary_link && primary_link.label ? (
            <span className="caption--cta-wrapper">
              <InnerTag
                className="caption--cta-text"
                href={primary_link.href}
                target={
                  primary_link && primary_link.newWindow ? '_blank' : null
                }
              >
                {primary_link.label}
                <span
                  className={classnames(
                    'caption--cta-icon',
                    { 'icon-chevron_right': !primary_link.newWindow },
                    { 'icon-link-blue': primary_link.newWindow }
                  )}
                />
              </InnerTag>
            </span>
          ) : null}
          {secondary_link && secondary_link.label ? (
            <span className="caption--cta-wrapper-secondary">
              <span className="caption--cta-wrapper">
                <a
                  className="caption--cta-text caption--cta-text-secondary"
                  href={secondary_link.href}
                  target={
                    secondary_link && secondary_link.newWindow ? '_blank' : null
                  }
                >
                  {secondary_link.label}
                  <span
                    className={classnames(
                      'caption--cta-icon',
                      { 'icon-chevron_right': !secondary_link.newWindow },
                      { 'icon-link-blue': secondary_link.newWindow }
                    )}
                  />
                </a>
              </span>
            </span>
          ) : null}
        </span>
      </OuterTag>
    </div>
  );
};

TradeTileRight.propTypes = {
  background: PropTypes.shape({
    src: PropTypes.string,
    maxWidth: PropTypes.number
  }),
  body: PropTypes.string,
  componentClickHandler: PropTypes.func,
  fullWidthTablet: PropTypes.bool,
  panelColor: PropTypes.object,
  placement: PropTypes.number,
  primary_link: PropTypes.shape({
    href: PropTypes.string,
    label: PropTypes.string
  }),
  priority: PropTypes.string.isRequired,
  secondary_link: PropTypes.shape({
    href: PropTypes.string,
    label: PropTypes.string,
    newWindow: PropTypes.bool
  }),
  tileInverted: PropTypes.bool,
  tileType: PropTypes.string,
  title: PropTypes.string.isRequired
};

export { TradeTileRight };
