import { request } from '../lib/client-fetch';
import { getLanguageLink } from '../reducers/app';
import {
  UPDATE_CONTACT_PREFERENCES,
  UPDATE_PERSONAL_DETAILS,
  CONNECTION_TIMEOUT,
  UPDATE_CLUBCARD,
  UPDATE_CLUBCARD_ERROR,
  RESET_CLUBCARD,
  RECEIVE_ACCOUNT_PHONE_NUMBER
} from '../constants/action-types';

function catchHandler(res, dispatch) {
  if (res && res.status === 503) {
    dispatch({
      type: CONNECTION_TIMEOUT
    });
  }
}

export function updatePersonalDetails(
  { title, firstName, lastName, primaryPhone, secondaryPhone },
  callback
) {
  return function(dispatch, getState) {
    const body = {
      title,
      firstName,
      lastName,
      primaryPhone,
      secondaryPhone
    };

    return request
      .put(getLanguageLink(getState(), '/account/personal-details'), {
        body: JSON.stringify(body)
      })
      .then(res => {
        if (res.errors) {
          callback(false);
        } else {
          dispatch({
            type: UPDATE_PERSONAL_DETAILS,
            userDetails: {
              title,
              firstName,
              lastName,
              primaryPhone,
              secondaryPhone
            }
          });

          callback(true);
        }
      })
      .catch(res => {
        catchHandler(res, dispatch);
      });
  };
}

export function updateContactPreferences(preference) {
  return function(dispatch, getState) {
    const body = {
      CustomerPreference: preference ? '1' : '0'
    };

    return request
      .post(getLanguageLink(getState(), '/account/contact-preferences'), {
        body: JSON.stringify(body)
      })
      .then(res => {
        dispatch({
          type: UPDATE_CONTACT_PREFERENCES,
          value: preference
        });
        return res.status;
      })
      .catch(err => {
        //revert ui to previous value
        dispatch({
          type: UPDATE_CONTACT_PREFERENCES,
          value: !preference
        });
        return err;
      });
  };
}

export function updateClubcardNumber(value, prefix, callback) {
  return function(dispatch, getState) {
    const body = {
      clubCardNumber: value,
      clubCardNumberPrefix: prefix
    };

    return request
      .post(getLanguageLink(getState(), '/account/clubcard'), {
        body: JSON.stringify(body)
      })
      .then(res => {
        if (res.errors) {
          dispatch({
            type: UPDATE_CLUBCARD_ERROR,
            errors: res.errors || []
          });

          callback(false);
        } else {
          dispatch({
            type: UPDATE_CLUBCARD,
            value: prefix.concat(value.replace(/.(?=.{4,}$)/g, '*'))
          });

          callback(true);
        }
      })
      .catch(res => {
        catchHandler(res, dispatch);
      });
  };
}

export function resetClubcardNumber() {
  return {
    type: RESET_CLUBCARD
  };
}

export function receiveAccountPhoneNumber(data) {
  return {
    type: RECEIVE_ACCOUNT_PHONE_NUMBER,
    value: data
  };
}
