import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { formatAddress } from '#/lib/string-formatting/format';
import MobileMapLink from '../mobile-map-link';
import helpers from '#/lib/decorators/helpers';
import { updateParamsInUrl } from '#/lib/url/url-utils';
import { precisionRound } from '#/utils/number-utils';
import analyticsBus from '#/analytics/analyticsBus';

function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

function getFormattedLocationDistance(location) {
  const { distance, distanceUnit } = location;
  const needsFormatting = isNumeric(distance) && !!distanceUnit;

  return needsFormatting
    ? `${precisionRound(distance, 1)} ${distanceUnit}`
    : distance;
}

@helpers(['l', 'c', 't'])
export default class LocationList extends Component {
  static propTypes = {
    addressSelectType: PropTypes.string,
    bookedSlotLocationId: PropTypes.string,
    c: PropTypes.func.isRequired,
    expanded: PropTypes.bool,
    l: PropTypes.func.isRequired,
    locations: PropTypes.arrayOf(
      PropTypes.shape({
        address: PropTypes.object,
        displayOrder: PropTypes.number,
        distance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        distanceUnit: PropTypes.string,
        isSelected: PropTypes.bool,
        latitude: PropTypes.string,
        locationId: PropTypes.string,
        longitude: PropTypes.string,
        referenceId: PropTypes.string
      })
    ).isRequired,
    onLocationClick: PropTypes.func,
    selectedDate: PropTypes.string,
    selectedLocation: PropTypes.object,
    selectedStoreMapQueryString: PropTypes.string,
    showSelectedStoreOnMapLink: PropTypes.bool,
    t: PropTypes.func.isRequired
  };

  static defaultProps = {
    expanded: false
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.selectedLocationAnalytics();
  }

  componentDidUpdate(prevProps) {
    const { expanded } = this.props;
    const changeOfExpandedState = !prevProps.expanded && expanded;
    if (changeOfExpandedState) {
      this.focusOnLink(prevProps);
    }
    if (this.isSelectedLocationDifferent(prevProps) && !changeOfExpandedState) {
      this.selectedLocationAnalytics();
    }
  }

  focusOnLink(prevProps) {
    this.linkEls[prevProps.locations.length].focus();
  }

  isSelectedLocationDifferent(prevProps) {
    const {
      locations: prevLocations,
      selectedLocation: { displayOrder: prevSelectedLocationIndex }
    } = prevProps;
    const locations = this.props.locations;
    const selectedLocationIndex = this.props.selectedLocation.displayOrder;
    const locationsAreDifferent = locations.some(
      prevLocation =>
        !prevLocations.find(location => {
          return (
            prevLocation.displayOrder === location.displayOrder &&
            prevLocation.distance === location.distance &&
            prevLocation.locationId === location.locationId &&
            prevSelectedLocationIndex === selectedLocationIndex
          );
        })
    );
    return locationsAreDifferent;
  }

  locationClickHandler = locId => {
    const { onLocationClick } = this.props;

    return evt => {
      return onLocationClick && onLocationClick(evt, locId);
    };
  };

  selectedLocationAnalytics() {
    const {
      locations,
      selectedLocation,
      searchType,
      addressSelectType
    } = this.props;
    const { displayOrder, distance, referenceId, distanceUnit } =
      locations.find(loc => loc.locationId === selectedLocation.locationId) ||
      {};
    const payload = {
      action: 'now',
      value: 'click collect interaction',
      type: addressSelectType || searchType,
      storeRank: displayOrder,
      storeDistance: distance,
      storeID: referenceId,
      storeDistanceUnit: distanceUnit
    };
    analyticsBus().emit('UIEventBasicEvent', payload);
  }

  generateLocations() {
    const { locations, selectedLocation } = this.props;
    const selectedLocationIndex = locations.findIndex(
      loc => loc.locationId === selectedLocation.locationId
    );

    return locations
      .filter(location => location.address)
      .map((location, index) => {
        const locationId = location.locationId;
        const selected =
          selectedLocation && selectedLocation.locationId === locationId;
        const selectedDate =
          this.props.bookedSlotLocationId === locationId
            ? ''
            : this.props.selectedDate || '';

        const icon = selected ? (
          <span className="location-list--icon-tick icon-tick-white" />
        ) : (
          <span className="location-list--icon-content">{`${location.displayOrder}`}</span>
        );

        const address = selected && (
          <div>{formatAddress(location.address)}</div>
        );

        const href = this.props.l(`/slots/collection/${selectedDate}`);
        const query = {
          locationId
        };
        const hrefWithQuery = updateParamsInUrl(href, query);

        return (
          <li
            key={locationId}
            className={classnames('location-list--item', {
              'is-selected': selected,
              'hidden-small': selectedLocationIndex < 5 ? index >= 5 : index < 5
            })}
            role="presentation"
          >
            <a
              ref={el => (this.linkEls[index] = el)}
              role="radio"
              aria-checked={selected}
              href={hrefWithQuery}
              onClick={this.locationClickHandler(locationId)}
              target={null}
            >
              <div className="location-list--item-wrapper">
                <div
                  className={classnames('location-list--icon-wrapper', {
                    'position-top': selected
                  })}
                >
                  <span className="location-list--icon icon-circle">
                    {icon}
                  </span>
                </div>

                <div className="location-list--item-content">
                  <div className="location-list--content-title">
                    {location.address.name}
                  </div>
                  {address}
                </div>
                <div
                  className={classnames('location-list--distance', {
                    'position-top': selected
                  })}
                >
                  {getFormattedLocationDistance(location)}
                </div>
              </div>
            </a>
            {this.props.showSelectedStoreOnMapLink && (
              <div className="small-only location-list--map-link">
                <MobileMapLink
                  querystring={this.props.selectedStoreMapQueryString}
                  text={this.props.t('slots:collections.see-on-map')}
                  chevron={true}
                />
              </div>
            )}
          </li>
        );
      });
  }

  render() {
    this.linkEls = [];

    return (
      <div className="location-list--wrapper">
        <ol
          className="location-list"
          role="radiogroup"
          aria-label={this.props.t('slots:choose-collection-point')}
        >
          {this.generateLocations()}
        </ol>
      </div>
    );
  }
}
