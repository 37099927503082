import { getObserver } from '#/lib/browser/intersection-observer';
import { StickyHeaderOffset } from '../constants';

export type HeaderIntersectionObserver = IntersectionObserver | null;

type HeaderIntersectionObservers = {
  topObserver: HeaderIntersectionObserver;
  bottomObserver: HeaderIntersectionObserver;
};

export const defineObservers = (
  onTopIntersection: (elements: IntersectionObserverEntry[]) => void,
  onBottomIntersection: (elements: IntersectionObserverEntry[]) => void,
  headerOffset: number,
): HeaderIntersectionObservers => {
  const rootMargin = `-${headerOffset}px 0px 0px`;
  const topObserver = getObserver(onTopIntersection, {
    rootMargin,
    threshold: 1,
  });
  const bottomObserver = getObserver(onBottomIntersection, {
    rootMargin: `-${headerOffset + 50}px 0px 0px`,
    threshold: 0.01,
  });

  return { topObserver, bottomObserver };
};

export const observe = (observer: HeaderIntersectionObserver, ref: Element | null): void => {
  if (observer && ref) {
    observer.observe(ref);
  }
};

export const getHeaderOffset = (isCookieBanner: string | number | boolean, isAmendBasket: boolean): number => {
  if (isCookieBanner && isAmendBasket) {
    return StickyHeaderOffset.COOKIE_BANNER_AND_AMEND_BASKET;
  }
  if (isCookieBanner) {
    return StickyHeaderOffset.COOKIE_BANNER;
  }
  if (isAmendBasket) {
    return StickyHeaderOffset.AMEND_BASKET;
  }
  return StickyHeaderOffset.DEFAULT;
};

export const isTopIntersection = (elements: IntersectionObserverEntry[], headerOffset: number): boolean => {
  return !elements[0]?.isIntersecting && elements[0]?.boundingClientRect.top < headerOffset;
};

export const isBottomIntersection = (elements: IntersectionObserverEntry[]): boolean => {
  return elements[0]?.isIntersecting && elements[0]?.boundingClientRect.top < 0;
};

export const getHeaderAnimation = (isIntersecting: boolean): string => {
  return isIntersecting ? 'slideIn' : 'slideOut';
};

export const disconnectObserver = (observer: HeaderIntersectionObserver): void => {
  if (observer) {
    observer?.disconnect();
    observer = null;
  }
};
