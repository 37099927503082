/*
  envFeatureDisabled:
  A utility function designed to determine if, based on environment variables, the feature passed in has been disabled.
  This allows us to temporarily disable specific features without having to do a release.
 */

export const envFeatureDisabled = (feature, disabledFeatures = '') => {
  if (!feature) {
    throw new Error('A feature is required');
  }

  const envDisabledFeatures = (
    getDisabledFeaturesFromProcess || disabledFeatures
  ).split(' ');

  return envDisabledFeatures.indexOf(feature) > -1;
};

const getDisabledFeaturesFromProcess =
  process && process.env && process.env.DISABLED_FEATURES;
