import React from 'react';
import PropTypes from 'prop-types';
import { connect } from '#/lib/render/connect-deep-compare';
import classnames from 'classnames';
import Footer from '#/components/footer';
import HeaderWrapper from '#/components/headers/header/header-wrapper';
import LayoutModals from '#/components/modals/layout-modals';
import LeftHandNavigation from '#/components/left-hand-navigation';
import MiniTrolley from '#/components/mini-trolley/mini-trolley';
import {
  getBagCharge,
  getSlot,
  getCouponSavings,
  getGuidePrice,
  getIsAmendBasket,
  getIsBagless,
  getIsTrolleyUpdating,
  getTotalPrice
} from '#/selectors/trolley';
import { getIosAppId, getIsMobile } from '#/reducers/app';
import {
  getIsUserRegistered,
  getIsUserAuthenticated,
  getUserDisplayName
} from '#/reducers/user';
import { getSlotExpiryThresholdMinutes, getStickyBarName } from '#/reducers/ui';
import { chooseStickyBar } from '#/actions/ui-action-creators';
import helpers from '#/lib/decorators/helpers';
import { createMetaTag } from '#/lib/html-helpers';
import SmartBanner from '#/components/shared/app-smart-banner';
import { IOS_APP } from '#/constants/app-banner';
import { STICKY_EXCEPTIONS_LIST } from '#/constants/sticky-trolley-exceptions';
import { DELIVERY, COLLECTION, ON_DEMAND } from '#/constants/shopping-methods';
import { setSlotExpiryTimer } from '#/lib/slot/slot-utils';
import { getTrolleyShoppingMethod } from '#/selectors/trolley';
import AmendOrderBar from '#/components/sticky-bar/amend-order-bar';
import { getCurrentUrl } from '#/reducers/app';
import { isOnFavouritesPage } from '#/lib/favorites-helpers';
import {
  getAmendBannerPositionStyleVariant,
  getOop1882bcdeBottom
} from '#/experiments/oop-1882/selectors';
import { getShowSlotUIReskinV2 } from '#/experiments/oop-2205/selectors';

const mapStateToProps = (state, { c: config }) => ({
  bagCharge: getBagCharge(state),
  bookedSlot: getSlot(state),
  couponSavings: getCouponSavings(state),
  guidePrice: getGuidePrice(state),
  isAmendBasket: getIsAmendBasket(state),
  isBagless: getIsBagless(state),
  isUpdating: getIsTrolleyUpdating(state),
  isUserAuthenticated: getIsUserAuthenticated(state),
  isMobile: getIsMobile(state),
  isUserRegistered: getIsUserRegistered(state),
  iosAppId: getIosAppId(state),
  slotExpiryThresholdMinutes: getSlotExpiryThresholdMinutes(state),
  shoppingMethod: getTrolleyShoppingMethod(state),
  stickyBarExpanded: state.ui.stickyBarExpanded,
  stickyBarName: getStickyBarName(state),
  taxonomyState: state.taxonomy.taxonomyState,
  totalPrice: getTotalPrice(state),
  userDisplayName: getUserDisplayName(state),
  enableFavShoppingHub:
    isOnFavouritesPage(getCurrentUrl(state)) && config('newFavNavEnabled'),
  amendBannerVariant: getAmendBannerPositionStyleVariant(state),
  showSlotUIReskin: getShowSlotUIReskinV2(state)
});
@helpers(['browserTypeVersion', 'c'])
@connect(mapStateToProps, { chooseStickyBar })
export default class DefaultLayout extends React.Component {
  static propTypes = {
    backToUrl: PropTypes.string,
    bagCharge: PropTypes.number.isRequired,
    bookedSlot: PropTypes.object,
    browserTypeVersion: PropTypes.func.isRequired,
    c: PropTypes.func.isRequired,
    children: PropTypes.any.isRequired,
    chooseStickyBar: PropTypes.func.isRequired,
    className: PropTypes.string,
    cmsNav: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    couponSavings: PropTypes.number.isRequired,
    customClassName: PropTypes.string,
    enableFavShoppingHub: PropTypes.bool.isRequired,
    groceryTabInOpenState: PropTypes.bool,
    guidePrice: PropTypes.number.isRequired,
    includeAppBanner: PropTypes.bool,
    iosAppId: PropTypes.string,
    isAmendBasket: PropTypes.bool.isRequired,
    isBagless: PropTypes.bool.isRequired,
    isHomePage: PropTypes.bool,
    isMobile: PropTypes.bool,
    isSlotPage: PropTypes.bool,
    isUpdating: PropTypes.bool.isRequired,
    isUserAuthenticated: PropTypes.bool.isRequired,
    isUserRegistered: PropTypes.bool.isRequired,
    main: PropTypes.bool,
    noOfPendingOrders: PropTypes.number,
    recentPendingOrder: PropTypes.object,
    shoppingMethod: PropTypes.string,
    showFooter: PropTypes.bool,
    showMiniTrolley: PropTypes.bool,
    showTrolleySpacing: PropTypes.bool,
    simpleFooter: PropTypes.bool,
    slotExpiryThresholdMinutes: PropTypes.number.isRequired,
    stickyBarExpanded: PropTypes.bool.isRequired,
    stickyBarName: PropTypes.string,
    taxonomyState: PropTypes.object.isRequired,
    totalPrice: PropTypes.number.isRequired,
    user: PropTypes.object,
    userDisplayName: PropTypes.string,
    showSlotUIReskin: PropTypes.bool
  };

  static defaultProps = {
    customClassName: '',
    isHomePage: false,
    main: true,
    showFooter: true,
    showMiniTrolley: true,
    showTrolleySpacing: true
  };

  constructor(props) {
    super(props);
    this.browserTypeVersion = this.props.browserTypeVersion();

    this.configKey = {
      enableFooter: props.c('productDetails:enableFooter'),
      enableHeaderWrapperAndMiniTrolley: props.c(
        'productDetails:enableHeaderWrapperAndMiniTrolley'
      )
    };
  }

  addRemoveIOSAppBanner() {
    const appBannerMetaTag = document.getElementById(IOS_APP);

    if (!this.props.isHomePage && appBannerMetaTag) {
      appBannerMetaTag.parentNode.removeChild(appBannerMetaTag);
    } else if (this.requireIOSMetaTag(appBannerMetaTag)) {
      createMetaTag({
        id: IOS_APP,
        name: 'apple-itunes-app',
        content: `app-id=${this.props.iosAppId}`
      });
    }
  }

  requireIOSMetaTag(appBannerMetaTag) {
    return (
      this.props.isHomePage &&
      this.props.iosAppId &&
      !appBannerMetaTag &&
      this.props.includeAppBanner
    );
  }

  componentDidMount() {
    this.addRemoveIOSAppBanner();
    const {
      bookedSlot,
      chooseStickyBar,
      slotExpiryThresholdMinutes
    } = this.props;

    this.clearExpiryTimer = setSlotExpiryTimer(
      bookedSlot,
      slotExpiryThresholdMinutes,
      chooseStickyBar
    );
  }

  componentWillUnmount() {
    if (typeof this.clearExpiryTimer === 'function') {
      this.clearExpiryTimer();
    }
  }

  renderHeaderWrapper = () => (
    <HeaderWrapper
      cmsNav={this.props.cmsNav}
      taxonomyState={this.props.taxonomyState}
      isHomePage={this.props.isHomePage}
      showMiniTrolley={this.props.showMiniTrolley}
      user={this.props.user}
      noOfPendingOrders={this.props.noOfPendingOrders}
      groceryTabInOpenState={this.props.groceryTabInOpenState}
    />
  );

  isSticky(browserTypeVersion) {
    return STICKY_EXCEPTIONS_LIST.indexOf(browserTypeVersion) === -1;
  }

  renderMiniTrolley = (props, browserTypeVersion) => (
    <MiniTrolley
      {...props}
      isStickyTrolley={this.isSticky(browserTypeVersion)}
    />
  );

  renderStickyBar() {
    return <AmendOrderBar />;
  }

  render() {
    let miniTrolley = null;
    const {
      props,
      configKey: { enableHeaderWrapperAndMiniTrolley, enableFooter }
    } = this;

    const {
      backToUrl,
      bagCharge,
      c: config,
      children,
      cmsNav,
      couponSavings,
      className,
      customClassName,
      guidePrice,
      includeAppBanner,
      isAmendBasket,
      isBagless,
      isHomePage,
      isMobile,
      isSlotPage,
      isUpdating,
      isUserAuthenticated,
      isUserRegistered,
      main,
      noOfPendingOrders,
      shoppingMethod,
      showFooter,
      showMiniTrolley,
      showTrolleySpacing,
      simpleFooter,
      stickyBarExpanded,
      stickyBarName,
      taxonomyState,
      totalPrice,
      userDisplayName,
      enableFavShoppingHub,
      showSlotUIReskin,
      children: {
        props: { routeProps }
      }
    } = props;

    const pathname = routeProps?.location.pathname;

    const isCurrentlyOnSlotsPage = !!pathname?.match(
      `slots\/(${COLLECTION}|${DELIVERY}|${ON_DEMAND})`
    );

    //  Below was added to NOT render miniTrolley on mobile devices regardless
    //  of this.props.showMiniTrolley (for performance purposes)
    if (showMiniTrolley && !isMobile && enableHeaderWrapperAndMiniTrolley) {
      if (isHomePage) {
        if (isUserAuthenticated) {
          miniTrolley = this.renderMiniTrolley(
            {
              isRegistered: isUserRegistered,
              isAuthenticated: isUserAuthenticated,
              isAmendBasket: isAmendBasket,
              backToUrl: backToUrl
            },
            this.browserTypeVersion
          );
        }
      } else {
        miniTrolley = this.renderMiniTrolley(
          {
            isRegistered: isUserRegistered,
            isAuthenticated: isUserAuthenticated,
            isSlotPage: isSlotPage,
            backToUrl: backToUrl
          },
          this.browserTypeVersion
        );
      }
    }

    const footer = showFooter && enableFooter && (
      <Footer showFooterLogo={showFooter} simpleFooter={simpleFooter} />
    );

    const showNewAmendBar = config('showNewAmendBar');

    const classes = classnames(
      className,
      `content-wrapper ${customClassName} `,
      {
        'amend-mode': isAmendBasket,
        'has-new-amend-banner': isAmendBasket && showNewAmendBar,
        'home-page-container': isHomePage,
        'sticky-bar-visible': !!stickyBarName,
        'sticky-bar-expanded': stickyBarExpanded,
        'fav-shopping-hub': enableFavShoppingHub,
        'amend-bottom-1882-bcde': getOop1882bcdeBottom(
          this.props.amendBannerVariant
        ),
        'slot-reskin-white-background':
          showSlotUIReskin && isCurrentlyOnSlotsPage,
        mobile: isMobile
      }
    );

    return (
      <div className={classes}>
        {enableHeaderWrapperAndMiniTrolley && this.renderStickyBar()}
        {includeAppBanner && <SmartBanner />}
        {enableHeaderWrapperAndMiniTrolley && this.renderHeaderWrapper()}
        <div
          role="main"
          id="main"
          className={classnames({
            'has-trolley': showTrolleySpacing && miniTrolley,
            'has-trolley-no-spacing':
              isHomePage || (!showTrolleySpacing && miniTrolley),
            main
          })}
        >
          {[
            <div className="main__content" key="main__content">
              {children}
            </div>,
            miniTrolley && (
              <div className="main__sidebar" key="main__sidebar">
                {miniTrolley}
              </div>
            )
          ]}
          <LeftHandNavigation
            cmsNav={cmsNav}
            isUserAuthenticated={isUserAuthenticated}
            isUserRegistered={isUserRegistered}
            noOfPendingOrders={noOfPendingOrders}
            taxonomyState={taxonomyState}
            userDisplayName={userDisplayName}
          />
        </div>
        <LayoutModals
          couponSavings={couponSavings}
          guidePrice={guidePrice}
          isAmendBasket={isAmendBasket}
          isUpdating={isUpdating}
          isBagless={isBagless}
          bagCharge={bagCharge}
          totalPrice={totalPrice}
          shoppingMethod={shoppingMethod}
          selectedShoppingMethod={shoppingMethod}
        />
        {footer}
      </div>
    );
  }
}
