import styled from 'styled-components';
import { TESCO_MODERN_FONT_FAMILY } from '@ddsweb/constants';

export const StyledContainer = styled.div`
  &.ondemand {
    font-family: ${TESCO_MODERN_FONT_FAMILY};
  }
`;

export const StyledTabContentContainer = styled.div`
  position: relative;
`;
