import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import FilterSelect from '../filter-select';
import FacetOptions from '../facet-options';
import helpers from '#/lib/decorators/helpers';
import {
  FILTER_OPTION_RADIO,
  FILTER_OPTION_CHECKBOX
} from '#/constants/filter-option';
import { connect } from '#/lib/render/connect-deep-compare';
import { getCurrentFacets } from '#/reducers/filter';
import {
  MEDIUM_BREAKPOINT,
  NUM_COLUMNS_MEDIUM,
  NUM_COLUMNS_LARGE,
  FILTER_OPTIONS_PRODUCT_SOURCE
} from '#/constants/filter-options';
import { debounce } from '#/utils/misc';

function determineColumnCount() {
  let columnCount = NUM_COLUMNS_MEDIUM;

  if (
    typeof document !== 'undefined' &&
    document.body.clientWidth >= MEDIUM_BREAKPOINT
  ) {
    columnCount = NUM_COLUMNS_LARGE;
  }

  return columnCount;
}

function mapStateToProps(state, ownProps) {
  return {
    currentFacets: getCurrentFacets(
      state,
      ownProps.c('facetsAllowingMultipleSelections'),
      ownProps.c('categoryDelimiter')
    )
  };
}

@helpers(['c'])
@connect(mapStateToProps)
export default class FilterOptions extends Component {
  static propTypes = {
    currentFacets: PropTypes.object.isRequired,
    facetTypes: PropTypes.arrayOf(
      PropTypes.shape({
        facetName: PropTypes.string.isRequired,
        displayMode: PropTypes.oneOf([
          FILTER_OPTION_RADIO,
          FILTER_OPTION_CHECKBOX
        ]).isRequired,
        displayAllOptionsWhenSelected: PropTypes.bool.isRequired,
        displayAllOption: PropTypes.bool.isRequired
      })
    ).isRequired,
    footer: PropTypes.node,
    header: PropTypes.node,
    id: PropTypes.string.isRequired,
    items: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
    lastChild: PropTypes.bool,
    onOpenOrClose: PropTypes.func,
    renderVeganMessage: PropTypes.func,
    show: PropTypes.bool.isRequired,
    showLessLabel: PropTypes.string.isRequired,
    showMoreLabel: PropTypes.string.isRequired,
    smoothTopLeft: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired,
    isProductSource: PropTypes.bool
  };

  static defaultProps = {
    lastChild: false
  };

  constructor() {
    super();

    this.state = {
      focus: null,
      numColumns: NUM_COLUMNS_LARGE
    };
  }

  componentDidMount() {
    this.determineNumColumns();
    window.addEventListener('resize', this.determineNumColumns);
  }

  shouldComponentUpdate(nextProps) {
    const { show, items } = this.props;

    return nextProps.show !== show || nextProps.items !== items;
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.determineNumColumns);
  }

  determineNumColumns = debounce(() => {
    this.setState({ numColumns: determineColumnCount() });
  }, 50);

  getFacetItemsToShow({ facetName, displayAllOptionsWhenSelected }) {
    const { items, currentFacets } = this.props;
    const optionsCategory =
      facetName === FILTER_OPTIONS_PRODUCT_SOURCE
        ? items.categoryId
        : items.category?.toLowerCase();

    if (currentFacets[facetName] && currentFacets[facetName].length) {
      if (optionsCategory === facetName && displayAllOptionsWhenSelected) {
        return items.facets;
      }

      return currentFacets[facetName];
    }

    if (optionsCategory === facetName) {
      return items.facets;
    }

    return [];
  }

  renderFacetOptions() {
    const {
      facetTypes,
      showMoreLabel,
      showLessLabel,
      renderVeganMessage
    } = this.props;

    return facetTypes.map((facetType, i) => {
      const {
        facetName,
        displayAllOptionsWhenSelected,
        displayAllOption,
        displayMode
      } = facetType;
      const items = this.getFacetItemsToShow(facetType);

      return (
        !!items.length && (
          <li className="current-filters" key={i} data-auto={facetName}>
            <FacetOptions
              displayAllOptionsWhenSelected={displayAllOptionsWhenSelected}
              displayAllOption={displayAllOption}
              displayMode={displayMode}
              facetName={facetName}
              items={items}
              numColumns={this.state.numColumns}
              descendantFacetNames={facetTypes
                .slice(i + 1)
                .map(x => x.facetName)}
              showMoreLabel={showMoreLabel}
              showLessLabel={showLessLabel}
              renderVeganMessage={renderVeganMessage}
            />
          </li>
        )
      );
    });
  }

  handleClick = e => {
    e.nativeEvent.stopImmediatePropagation();
  };

  onOpenOrClose = () => {
    const { type, onOpenOrClose } = this.props;
    const handler = onOpenOrClose;

    if (typeof handler === 'function') {
      return handler(type);
    }
  };

  render() {
    const {
      id,
      items,
      label,
      show,
      smoothTopLeft,
      lastChild,
      header,
      footer,
      isProductSource
    } = this.props;

    return (
      <li data-auto={id}>
        <FilterSelect
          controls={id}
          count={items.facets.length}
          label={label}
          onClick={this.onOpenOrClose}
          open={show}
        />
        <div
          aria-hidden={!show}
          className={classnames('filter-options-container', {
            open: show,
            'smooth-top-left': smoothTopLeft,
            'last-child': lastChild,
            'product-source-container': isProductSource
          })}
          id={id}
          onClick={this.handleClick}
          data-auto={`${id}-dropdown`}
        >
          <div
            className={classnames('filter-options', {
              'filter-options-product-source': isProductSource
            })}
          >
            {header}

            <ul
              aria-label={label}
              className={classnames({
                'facets-product-source': isProductSource
              })}
            >
              {this.renderFacetOptions()}
            </ul>

            {footer && (
              <div
                className={classnames('filter-options-footer', {
                  'product-source-footer': isProductSource
                })}
              >
                {footer}
              </div>
            )}
          </div>
        </div>
      </li>
    );
  }
}
