import { createSelector } from 'reselect';
import {
  getResultsPages,
  getResultsPagesByTypeFactory
} from '#/selectors/results/get-pages';
import {
  getResultsPageNumber,
  getResultsPageNumberByTypeFactory
} from '#/selectors/results/get-page-number';
import { cloneItems } from '#/utils/clone-items';

function getPage(pages, pageNo) {
  const page = pages[pageNo - 1];
  return (page && cloneItems(page)) || new Map();
}

export const getResultsPage = createSelector(
  [getResultsPages, getResultsPageNumber],
  (pages, pageNo) => getPage(pages, pageNo)
);

export const getResultsPageByTypeFactory = resultsType =>
  createSelector(
    [
      getResultsPagesByTypeFactory(resultsType),
      getResultsPageNumberByTypeFactory(resultsType)
    ],
    (pages, pageNo) => getPage(pages, pageNo)
  );
