import React, { useEffect } from 'react';
import { ConnectedProps } from 'react-redux';
import { compose } from 'react-recompose';
import { getOrderSummaryUrlFromState } from '#/utils/checkout-utils';
import { closeModal } from '#/actions/ui-action-creators';
import analyticsBus from '#/analytics/analyticsBus';
import { basicEvent } from '#/analytics/types/basic';
import { DELAY, NOW } from '#/analytics/constants';
import { SKIP_OFFERS_MODAL } from '#/constants/modal-names';
import Modal from '#/components/shared/modal';
import ModalActions from '#/components/shared/modal/actions';
import ModalContent from '#/components/shared/modal/content';
import Link from '#/components/link';
import { connect } from '#/lib/render/connect-deep-compare';
import helpers from '#/lib/decorators/helpers';
import { TConfigFunc, TTranslateFunc } from '#/lib/records/helpers.defs';

type HelperProps = {
  t: TTranslateFunc;
  c: TConfigFunc;
};

type ModalProps = {
  modalData: {
    continueHref: string;
    showRecCopy: boolean;
  };
};

type State = {
  orderSummaryUrl: string;
};

const mapDispatchToProps = {
  closeModal,
};

const mapStateToProps = (state: Store): State => ({
  orderSummaryUrl: getOrderSummaryUrlFromState(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = HelperProps & ModalProps & ConnectedProps<typeof connector>;

const SkipOffersModal = ({
  t: translate,
  closeModal,
  modalData,
  orderSummaryUrl,
}: Props): React.ReactElement | null => {
  const { continueHref, showRecCopy } = modalData || {};

  useEffect(() => {
    basicEvent(analyticsBus, {
      type: 'checkout:flow',
      value: 'skip offers popup impression',
      action: NOW,
    });
  }, []);

  const sharedOnClick = (type: string): void => {
    closeModal(SKIP_OFFERS_MODAL);
    basicEvent(analyticsBus, {
      type: 'checkout:flow',
      value: type,
      action: DELAY,
    });
  };

  const skipOnClick = (): void => {
    sharedOnClick('skip offers click');
  };

  const offersOnClick = (): void => {
    sharedOnClick('see offers click');
  };

  const copy = showRecCopy ? 'recs' : 'offers';

  return (
    <Modal closeModal={closeModal} title={translate(`trolley:skip-${copy}-modal.title`)}>
      <ModalContent>
        <p>{translate(`trolley:skip-${copy}-modal.message`)}</p>
        <ModalActions dual>
          <div className="modal-actions-button-container auto-width">
            <Link
              className="button button-secondary small skip-modal-button-padding"
              href={orderSummaryUrl}
              onClick={skipOnClick}
              disableAnalytics={true}
            >
              {translate(`trolley:skip-${copy}-modal.skip`)}
            </Link>
          </div>
          <div className="modal-actions-button-container auto-width">
            <Link
              className="button button-primary small skip-modal-button-padding"
              href={continueHref}
              onClick={offersOnClick}
              disableAnalytics={true}
            >
              {translate(`trolley:skip-${copy}-modal.${copy}`)}
            </Link>
          </div>
        </ModalActions>
      </ModalContent>
    </Modal>
  );
};

const enhance = compose<Props, ModalProps>(helpers(['c', 't']), connector);
export default enhance(SkipOffersModal);
