import React from 'react';
import styled from 'styled-components';
import { breakpoints, spacing } from '@ddsweb/selectors';
import SecondaryNavigation from '../secondary-navigation';
import Link from '#/components/link-check-spa';
import { getCurrentUrl } from '#/reducers/app';
import { connect } from '#/lib/render/connect-deep-compare';

type OwnState = {
  currentUrl: string;
};

const mapStateToProps = (state: Store): OwnState => ({
  currentUrl: getCurrentUrl(state),
});

const SecondaryNavigationWrapper = styled.div`
  margin: ${spacing.md} 0 0 0;
`;

const SecondaryNavigationListItem = styled.li`
  margin: 0;
  padding: 0;
  list-style: none;
  a {
    word-break: initial;
  }
`;

const SecondaryNavigationMenuList = styled.ul`
  display: flex;
  flex-direction: column;
  column-gap: ${spacing.md};
  row-gap: ${spacing.xs};
  @media (min-width: ${breakpoints.mobileLarge}px) {
    flex-direction: row;
  }
  justify-content: space-between;
`;

export const SecondaryNavigationNav = styled.nav`
  display: block;
`;

export type OwnProps = {
  title: string;
  liData: {
    label: string;
    href: string;
  }[];
  componentClickHandler: (props: unknown) => void;
  currentUrl: string;
};

export const SecondaryNavigationContent = (props: OwnProps): JSX.Element => {
  const { title, liData, componentClickHandler } = props;
  const onClick = (): void => componentClickHandler(props);

  const SecondaryNavigationList = liData
    .map((object, index) => {
      if (object.href) {
        return (
          <SecondaryNavigationListItem key={`${index}-${object.label}`}>
            <Link to={object.href} onClick={onClick}>
              {object.label}
            </Link>
          </SecondaryNavigationListItem>
        );
      }
      return null;
    })
    .filter(Boolean);

  return (
    <SecondaryNavigationWrapper>
      <SecondaryNavigation title={title}>
        <SecondaryNavigationNav>
          <SecondaryNavigationMenuList>{SecondaryNavigationList}</SecondaryNavigationMenuList>
        </SecondaryNavigationNav>
      </SecondaryNavigation>
    </SecondaryNavigationWrapper>
  );
};

export default connect(mapStateToProps)(SecondaryNavigationContent);
