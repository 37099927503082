/**
 * Experiment constants for special offers page name
 */
export const specialOffersPageNameVariants = {
  key: 'oop1029',
  variants: {
    default: '1029-a',
    showClubcardPricesName: '1029-b',
  },
};
