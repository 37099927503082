import { Component } from 'react';
import PropTypes from 'prop-types';
import { getObserver } from '../../lib/browser/intersection-observer';

export default class LazyComponent extends Component {
  static propTypes = {
    fetchData: PropTypes.func.isRequired,
    target: PropTypes.string.isRequired
  };

  componentDidMount() {
    const carouselObserverConfig = {
      rootMargin: this.getRootMargin(),
      threshold: 0.01
    };

    this.observer = getObserver(this.intersection, carouselObserverConfig);
    this.observe();
  }

  componentWillUnmount() {
    const targetCarousel = this.getTarget();

    if (this.observer && targetCarousel && this.observed) {
      this.observer.unobserve(targetCarousel);
    }
  }

  getRootMargin = () => `${this.getClientHeight()}px 0px`;

  getClientHeight = () => {
    if (process.env.CLIENT_SIDE) {
      return (
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight
      );
    }

    return 1000;
  };

  getTarget = () => document.querySelectorAll(this.props.target)[0];

  intersection = elements => {
    if (elements[0].intersectionRatio > 0) {
      this.observer.unobserve(elements[0].target);
      this.observed = false;
      this.props.fetchData();
    }
  };

  observe = () => {
    if (this.observer) {
      const targetCarousel = this.getTarget();

      if (targetCarousel) {
        this.observed = true;
        this.observer.observe(targetCarousel);
      }
    } else {
      setTimeout(() => this.props.fetchData(), 0);
    }
  };

  render() {
    return null;
  }
}
