import styled, { css, keyframes } from 'styled-components';
import Button from '@ddsweb/button';
import { media } from '@ddsweb/foundation';
import { spacing } from '@ddsweb/selectors';
import { BREAKPOINTS } from '@ddsweb/constants';
import { PRIMARY } from '@ddsweb/constants';

const backToTopFadeIn = keyframes`
   0% { opacity: 0; }
   100% { opacity: 1; }
`;

const backToTop = css`
  position: sticky;
  animation: ${backToTopFadeIn} 0.3s linear 0s 1;
  bottom: ${spacing.md};
  display: flex;
  justify-self: center;
  z-index: 5;
  margin: auto;
  width: 60px;
  height: 60px;
  border-radius: 50%;
`;

export const StyledButton = styled(Button)`
  ${backToTop}

  &.with-amend-basket {
    bottom: 200px;
    ${media.aboveMobileLarge`
      bottom: 148px;
    `}
  }

  &.with-cookie-banner {
    bottom: calc(${({ bottom }: { bottom: number }): number => bottom}px + ${spacing.md});
  }

  ${media.aboveTablet`
    left: 50%;
    transform: translateX(-50%);
    margin: 0px -12px;
    &.with-amend-basket {
      bottom: ${spacing.md};
    }
    &.with-cookie-banner {
      bottom: ${spacing.md};
    }
  `}

  &.trolley-in-amend {
    bottom: 204px;
    margin-bottom: 12px;
    @media (min-width: ${BREAKPOINTS.mobileLarge}px) {
      bottom: 184px;
    }
    @media (min-width: ${BREAKPOINTS.tablet}px) {
      bottom: 132px;
    }
    @media (min-width: ${BREAKPOINTS.tabletLarge}px) {
      bottom: 75px;
    }
  }

  &.trolley-page {
    bottom: 68px;
    margin-bottom: 12px;
    @media (min-width: ${BREAKPOINTS.tablet}px) {
      bottom: 8px;
    }
  }
`;

export const StyledIcon = styled('span')`
  display: inline-block;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.4247 5.57575L12.0004 5.15149L11.5762 5.57575L6.57617 10.5758L7.4247 11.4243L11.4004 7.44855V19H12.6004V7.44855L16.5762 11.4243L17.4247 10.5758L12.4247 5.57575Z' fill='${({
    iconVariant,
  }: {
    iconVariant: string;
  }): string => ((iconVariant === PRIMARY ? '%23ffffff' : '%2300539f') as unknown) as string}'/%3E%3C/svg%3E%0A");
  height: ${spacing.xl};
  width: ${spacing.xl};
  margin: -20px;
  height: 40px;
  width: 40px;
  background-size: contain;
`;
