import React, { useEffect } from 'react';
import { ConnectedProps } from 'react-redux';
import { compose } from 'react-recompose';
import moment from 'moment-timezone';
import { WithRouterProps } from 'react-router';
import Button from '@ddsweb/button';
import { PRIMARY, SECONDARY } from '@ddsweb/constants';
import helpers from '#/lib/decorators/helpers';
import { TConfigFunc, TTranslateFunc } from '#/lib/records/helpers.defs';
import { connect } from '#/lib/render/connect-deep-compare';
import { getTrolleySlotStartTime, getTrolleySlotEndTime } from '#/selectors/trolley';
import FormattedSlotTime from '#/components/shared/formatted-time/formattedSlotTime';
import { getLocalDate } from '#/lib/slot/slot-utils';
import addressToSpecifiedFormat from '#/lib/string-formatting/format-address';
import { getLanguage, getLanguageLink, getTimezone } from '#/reducers/app';
import { getTrolleySlotReservationExpiry } from '#/selectors/trolley';
import { IConfig, formatTimeFromConfig } from '#/components/shared/formatted-time/utils';
import { ButtonContainer, Content, StyledHeading, StyledCheckoutText, StyledBodyText } from './styled';
import { getIsFirstTimeShopper } from '#/reducers/user';
import { getFavoritesLanguageLink } from '#/utils/favorite-utils';
import { closeModal } from '#/actions/ui-action-creators';
import { SHOP_FAVOURITES, START_SHOPPING } from '#/analytics/constants';
import { getSelectedShoppingMethod, getSelectedLocation } from '#/reducers/slot';
import { isCollectionShoppingMethod } from '#/lib/shopping-method-util';
import { getDeliveryLocation } from '#/selectors/trolley';
import { getAddressById } from '#/selectors/addresses';
import { emitQuickBasketClickUIEvent, getQuickBasketModalImpression } from '#/analytics/helpers/slot';
import withCheckedSPA, { CheckSPAForRedirect } from '#/components/withCheckedSPA';

type State = {
  isFirstTimeShopper: boolean;
  addressInfo: string;
  slotStart: string | moment.Moment | null;
  slotEnd: string | moment.Moment | null;
  formattedDate?: string;
  expireTime: string;
  startShoppingUrl: string;
  favoritesPageLink: string;
  quickBasketModalImpression: string;
};

type HelperProps = {
  t: TTranslateFunc;
  c: IConfig;
};

const mapStateToProps = (state: Store, { c: config }: HelperProps): State => {
  const timezone = getTimezone(state);
  const language = getLanguage(state);
  const selectedShoppingMethod = getSelectedShoppingMethod(state);
  const isCollection = isCollectionShoppingMethod(selectedShoppingMethod);

  let detailedAddress;
  let addressMeta;
  if (isCollection) {
    detailedAddress = getSelectedLocation(state)?.address;
    addressMeta = [
      'name',
      'addressLine1',
      'addressLine2',
      'addressLine3',
      'addressLine4',
      'addressLine5',
      'addressLine6',
      'postcode',
    ];
  } else {
    const address = getDeliveryLocation(state);
    detailedAddress = getAddressById(state, address?.id);
    addressMeta = ['addressLine1', 'addressLine2', 'addressLine3', 'addressLine4', 'addressLine5', 'addressLine6'];
  }

  const addressInfo = addressToSpecifiedFormat(detailedAddress, addressMeta).join(', ');

  const trollyReservationExpiry = getTrolleySlotReservationExpiry(state);
  const reservationDate = getLocalDate(trollyReservationExpiry as moment.Moment, timezone, language);
  const expireTime = formatTimeFromConfig(reservationDate, timezone, language, config);

  let formattedDate;
  const slotStart = getTrolleySlotStartTime(state);
  if (slotStart) formattedDate = getLocalDate(slotStart as moment.Moment, timezone, language).format('dddd D MMMM');
  const quickBasketModalImpression = getQuickBasketModalImpression(state);

  return {
    startShoppingUrl: getLanguageLink(state, '/'),
    favoritesPageLink: getFavoritesLanguageLink(state, '/favorites'),
    slotEnd: getTrolleySlotEndTime(state),
    isFirstTimeShopper: getIsFirstTimeShopper(state),
    addressInfo,
    formattedDate,
    slotStart,
    expireTime,
    quickBasketModalImpression,
  };
};

interface Props extends WithRouterProps {
  t: TTranslateFunc;
  c: TConfigFunc;
}

const connector = connect(mapStateToProps, { closeModal });
export type SlotInfoProps = Props & ConnectedProps<typeof connector> & { checkSPAForRedirect: CheckSPAForRedirect };

const SlotInfo = (props: SlotInfoProps): JSX.Element => {
  const {
    t: translate,
    isFirstTimeShopper,
    slotStart,
    slotEnd,
    addressInfo,
    formattedDate,
    expireTime,
    startShoppingUrl,
    favoritesPageLink,
    closeModal,
    quickBasketModalImpression,
    checkSPAForRedirect,
  } = props;

  useEffect(() => {
    if (quickBasketModalImpression) {
      emitQuickBasketClickUIEvent(quickBasketModalImpression);
    }
  }, []);

  const onStartShoppingClickHandler = (): void => {
    emitQuickBasketClickUIEvent(START_SHOPPING);
    closeModal();

    checkSPAForRedirect(startShoppingUrl);
  };

  const onShopFavClickHandler = (): void => {
    emitQuickBasketClickUIEvent(SHOP_FAVOURITES);
    closeModal();
    checkSPAForRedirect(favoritesPageLink);
  };

  return (
    <>
      <StyledHeading headingLevel="4">{translate('modals:quick-basket.title')}</StyledHeading>
      <Content>
        <StyledBodyText forwardedAs="p" className="slot-booked-time">
          <FormattedSlotTime startTime={slotStart} endTime={slotEnd} />
          {`, `}
          {formattedDate}
          {'.'}
        </StyledBodyText>
        <StyledCheckoutText forwardedAs="p">
          {translate('modals:quick-basket.checkout-by', {
            time: expireTime,
          })}
        </StyledCheckoutText>
        <StyledBodyText forwardedAs="p">{addressInfo}</StyledBodyText>
        <StyledBodyText forwardedAs="p" className="body-text">
          {translate('modals:quick-basket.add-essentials')}
        </StyledBodyText>
      </Content>
      <ButtonContainer>
        <Button variant={isFirstTimeShopper ? PRIMARY : SECONDARY} onClick={onStartShoppingClickHandler}>
          {translate('modals:quick-basket.start-shopping-btn')}
        </Button>
        {!isFirstTimeShopper && (
          <Button variant={PRIMARY} onClick={onShopFavClickHandler}>
            {translate('modals:quick-basket.shop-fav-btn')}
          </Button>
        )}
      </ButtonContainer>
    </>
  );
};

const enhance = compose<SlotInfoProps, null>(helpers(['t', 'c', 'f']), connector, withCheckedSPA);

export default enhance(SlotInfo);
