const HYPHEN = '-';

// Copy of node-polyglot interpolate method so that we can keep on using it.
// ### interpolate
//
// Does the dirty work. Creates a `RegExp` object for each
// interpolation placeholder.
export function interpolate(input, options) {
  let phrase = input;

  for (const arg in options) {
    if (arg !== '_' && options.hasOwnProperty(arg)) {
      // We create a new `RegExp` each time instead of using a more-efficient
      // string replace so that the same argument can be replaced multiple times
      // in the same phrase.
      phrase = phrase.replace(new RegExp(`%\\{${arg}\\}`, 'g'), options[arg]);
    }
  }

  return phrase;
}

export function hyphenate(camelCased) {
  return camelCased.replace(
    /([a-z][A-Z])/g,
    g => `${g[0]}-${g[1].toLowerCase()}`
  );
}

export function camelCase(hyphenated) {
  return hyphenated.replace(/-([a-z])/g, g => g[1].toUpperCase());
}

export const MATCH_ROGUE_CHARS = /[^a-z0-9.,\-'/£*&\s"><()}{[\]]/im;

export function isValidText(str = '') {
  return !MATCH_ROGUE_CHARS.test(str);
}

export function camelCaseToKebabCase(str) {
  // Implementation copied from @beans/helpers, v19, not exported at the time of writing
  // https://github.dev.global.tesco.org/TescoSharedPlatform/beans/blob/master/packages/helpers/src/kebab-case/index.ts
  return str
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/\s+/g, '-')
    .toLowerCase();
}

/**
 * @param {*} str
 * @param {*} delimeter
 * @description Replaces the special characters in the string with the delimeter
 * @returns string
 */
export const replaceSpecialCharacters = (str, delimeter = HYPHEN) =>
  str
    .toLowerCase()
    .replace(/[^a-zA-Z0-9 ]/g, '')
    .replace(/ +/g, delimeter);
