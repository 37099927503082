import bertie from '../analyticsBus';
import { bookedSlotPayload } from '../helpers/slot';

export function bookedSlotAnalyticsEvent({
  start,
  end,
  charge,
  currency,
  shoppingMethod,
  timezone,
  locationId,
  deliverySaverCurrent
}) {
  bertie().emit('UIEventSlotBooked', {
    ...bookedSlotPayload({
      start,
      end,
      charge,
      currency,
      method: shoppingMethod,
      timezone,
      locationId,
      deliverySaverCurrent
    })
  });
}
