import { getFeatureVariableStringFromState, updateRegionInKey } from '#/lib/optimizely-manager';
import { categoryPillV2Variants } from '#/experiments/oop-2191/constants';

const {
  variationKey,
  variants: { default: defaultVariant },
  featureKey,
} = categoryPillV2Variants;

export const getCategoryChipsVariant = (state: Store): string => {
  const regionInKey = updateRegionInKey(featureKey, state);

  return getFeatureVariableStringFromState(state, regionInKey, variationKey) || defaultVariant;
};
