/**
 * Experiment constants for showing an alternative sitemap title in the footer (MFE test)
 */
export const sitemapFooterVariants = {
  key: 'ghs-uk_mfe-footer_test',
  variants: {
    default: '1646-a',
    altSitemapTitle: '1646-b',
  },
  useCustomerId: true,
};
