import React from 'react';
import ResponsiveImage from '@ddsweb/responsive-image';
import {
  StyledBodyContainer,
  StyledBodyTextRow,
  StyledIcon,
  StyledBodyText,
} from '#/components/modals/market-place-product-modal/styled';
import { TConfigFunc, TTranslateFunc } from '#/lib/records/helpers.defs';

type Props = {
  t: TTranslateFunc;
  c: TConfigFunc;
};

const MarketPlaceProductBodyContent = (props: Props): JSX.Element => {
  const { t: translate, c: config } = props;
  const tPrefix = `modals:market-place-product-modal`;

  function renderMPBenefitsRow(index: string): JSX.Element {
    return (
      <StyledBodyTextRow key={`${index}-row`}>
        <StyledIcon graphic="benefits" />
        <StyledBodyText>{translate(`${tPrefix}.descriptionPoint${index}`)}</StyledBodyText>
      </StyledBodyTextRow>
    );
  }

  return (
    <>
      <ResponsiveImage
        src={config('marketPlaceModalImageLink')}
        alt={translate(`${tPrefix}.title`)}
        sizes={[
          {
            breakpoint: 'tabletLarge',
            mediaDirection: 'min',
            size: '375px',
          },
          {
            breakpoint: 'tablet',
            mediaDirection: 'min',
            size: '375px',
          },
          {
            size: '375px',
          },
        ]}
      />
      <StyledBodyContainer>{['1', '2', '3'].map(renderMPBenefitsRow)}</StyledBodyContainer>
    </>
  );
};

export default MarketPlaceProductBodyContent;
