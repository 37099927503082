import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from '#/lib/render/connect-deep-compare';
import {
  formatUrl,
  getLocalDate,
  hasAvailableSlots,
  getIsSameDayFromMoment
} from '#/lib/slot/slot-utils';
import helpers from '#/lib/decorators/helpers';
import slotRangeUtils from '#/lib/slot/slot-range-utils';
import { getLanguage, getLanguageLink, getTimezone } from '#/reducers/app';
import { COLLECTION } from '#/constants/shopping-methods';
import { formatDate } from '#/lib/slot/slot-range-utils';
import { updatePageUrlWithoutRefresh } from '#/lib/url/url-utils';
import { changeSlotDate } from '#/actions/slot-action-creators';
import { getSelectedWeekSlots } from '#/reducers/slot';
import { VisuallyHidden } from '#/components/shared/styled';

const mapDispatchToProps = {
  changeSlotDate
};
const mapStateToProps = state => ({
  language: getLanguage(state),
  languageLink: getLanguageLink(state, ''),
  selectedWeekSlots: getSelectedWeekSlots(state),
  timezone: getTimezone(state)
});

@connect(mapStateToProps, mapDispatchToProps)
@helpers(['isStockAndroid', 't', 'c'])
export default class DaySelector extends PureComponent {
  static propTypes = {
    c: PropTypes.func.isRequired,
    changeSlotDate: PropTypes.func.isRequired,
    currentSlotGroup: PropTypes.number,
    isStockAndroid: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    languageLink: PropTypes.string.isRequired,
    selectedDate: PropTypes.object.isRequired,
    selectedLocation: PropTypes.object,
    selectedWeekSlots: PropTypes.object.isRequired,
    shoppingMethod: PropTypes.oneOf(['delivery', 'collection']).isRequired,
    slotRange: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    timezone: PropTypes.string.isRequired
  };

  static defaultProps = {
    selectedLocation: {}
  };

  constructor(props) {
    super(props);
    this.state = this.getPreviousAndNextSlotGroupDates(props);
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this.getPreviousAndNextSlotGroupDates(nextProps));
  }

  getPreviousAndNextSlotGroupDates = ({ slotRange, selectedDate }) => {
    return {
      previousWeekDate: slotRangeUtils.getStartOfPreviousWeek(
        slotRange,
        selectedDate
      ),
      nextWeekDate: slotRangeUtils.getStartOfNextWeek(slotRange, selectedDate)
    };
  };

  getSlotGroupLink = date => {
    const {
      currentSlotGroup,
      languageLink,
      selectedLocation,
      shoppingMethod
    } = this.props;

    return (
      languageLink +
      formatUrl(
        shoppingMethod,
        date,
        selectedLocation.locationId,
        currentSlotGroup
      )
    );
  };

  getSlotDayChangeUrl = selectedDay => {
    const {
      languageLink,
      shoppingMethod,
      selectedLocation,
      currentSlotGroup
    } = this.props;

    const locationId =
      selectedLocation !== null && shoppingMethod === COLLECTION
        ? selectedLocation.locationId
        : null;

    return (
      languageLink +
      formatUrl(shoppingMethod, selectedDay, locationId, currentSlotGroup)
    );
  };

  slotDayChangeHandler = (event, selectedDay) => {
    event.preventDefault();

    const formattedDate = formatDate(selectedDay);
    const { changeSlotDate } = this.props;

    updatePageUrlWithoutRefresh(window, this.getSlotDayChangeUrl(selectedDay));
    changeSlotDate(formattedDate);
  };

  onKeyPressed = e => {
    const daySelectKeys = [' ', 'Tab', 'Enter'];
    if (daySelectKeys.includes(e.key)) {
      return true;
    }
    const { activeElement } = document;
    const liSelector = 'li';
    const aSelector = 'a';
    const ulSelector = 'ul';
    const ARROW_RIGHT = 'ArrowRight';
    const ARROW_LEFT = 'ArrowLeft';

    const liElement = activeElement.closest(liSelector);
    const ulElement = liElement.closest(ulSelector);

    if (!liElement || !ulElement) {
      return null;
    }

    if (e.key === ARROW_RIGHT) {
      const siblingElement = liElement.nextSibling;
      if (siblingElement) {
        siblingElement.querySelector(aSelector).focus();
        e.preventDefault();
      } else {
        ulElement.firstChild.querySelector(aSelector).focus();
        e.preventDefault();
      }
    } else if (e.key === ARROW_LEFT) {
      const siblingElement = liElement.previousSibling;
      if (siblingElement) {
        siblingElement.querySelector(aSelector).focus();
        e.preventDefault();
      } else {
        ulElement.lastChild.querySelector(aSelector).focus();
        e.preventDefault();
      }
    }
  };

  renderSlotGroupLink = (slotDate, iconClass, translationKey) =>
    slotDate.isValid() ? (
      <a
        href={this.getSlotGroupLink(slotDate)}
        onClick={event => this.slotDayChangeHandler(event, slotDate)}
      >
        <div className={iconClass} />
        <VisuallyHidden data-visually-hidden>
          {this.props.t(translationKey)}
        </VisuallyHidden>
      </a>
    ) : null;

  renderDayListItems() {
    const {
      c: config,
      language,
      selectedDate,
      selectedWeekSlots,
      t: translate,
      timezone
    } = this.props;
    const dayItems = [];

    const screenReaderDateFormatLong =
      config('format')?.ordinalIndicator?.long || 'dddd Do MMMM';

    const screenReaderDateFormatShort =
      config('format')?.ordinalIndicator?.short || 'Do MMMM';

    Object.entries(selectedWeekSlots).forEach(([date, slots]) => {
      const localDate = getLocalDate(date, timezone, language);
      const isToday = getIsSameDayFromMoment(localDate);
      const isTodayEnabled = config('showFirstSlotColAsToday');
      const selected = selectedDate.isSame(localDate, 'day');

      const todayText = translate('slots:common.today');

      const shouldShowToday = isToday && isTodayEnabled;

      const tabIndex = selected ? 0 : null;

      dayItems.push(
        <li
          className={classnames('day-selector__list-item', {
            'day-selector__list-item--selected': selected,
            'day-selector__list-item--unavailable': !hasAvailableSlots(slots)
          })}
          data-date={slotRangeUtils.formatDate(localDate)}
          key={localDate.format()}
          role="presentation"
        >
          <a
            aria-controls={`${localDate}-tab`.toLocaleLowerCase()}
            aria-selected={selected}
            href={this.getSlotGroupLink(localDate)}
            id={`${localDate}`.toLocaleLowerCase()}
            onClick={event => this.slotDayChangeHandler(event, localDate)}
            onKeyDown={this.onKeyPressed}
            role="tab"
            tabIndex={tabIndex}
          >
            <span className="day-selector__day-name" aria-hidden="true">
              {shouldShowToday ? todayText : localDate.format('ddd')}
            </span>
            <br />
            <span aria-hidden="true" className="day-selector__day-date">
              <span className="day-selector__day-date__value">
                {localDate.format('DD')}
              </span>
            </span>

            <VisuallyHidden data-visually-hidden>
              {shouldShowToday
                ? `${todayText} ${localDate.format(
                    screenReaderDateFormatShort
                  )}`
                : localDate.format(screenReaderDateFormatLong)}
            </VisuallyHidden>
          </a>
        </li>
      );
    });

    return dayItems;
  }

  render() {
    const { t: translate } = this.props;
    return (
      <div
        className={classnames('day-selector', {
          'day-selector--stock-android': this.props.isStockAndroid()
        })}
      >
        <div className="day-selector__column day-selector__column--arrow">
          {this.renderSlotGroupLink(
            this.state.previousWeekDate,
            'icon-chevron_left',
            'slots:previous-week'
          )}
        </div>
        <div className="day-selector__column">
          <ul
            className="day-selector__list"
            role="tablist"
            aria-label={translate('slots:common.choose-a-slot-date')}
          >
            {this.renderDayListItems()}
          </ul>
        </div>
        <div className="day-selector__column day-selector__column--arrow">
          {this.renderSlotGroupLink(
            this.state.nextWeekDate,
            'icon-chevron_right',
            'slots:next-week'
          )}
        </div>
      </div>
    );
  }
}
