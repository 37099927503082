import React from 'react';
import AmendOrderAvailabilityText from '#/components/trolley/trolley-order-summary/amend-availability-msg';
import { ORDER_SUMMARY, ORDER_CONFIRMATION, PENDING_ORDER } from '#/constants/page-names';

type TProps = {
  isAmendOrderAvailable?: boolean | undefined;
  page: string;
  amendCutoff: string;
  isOrderDue: boolean;
};

export const AmendInfoMessage: React.FC<TProps> = ({ page, isAmendOrderAvailable, amendCutoff, isOrderDue }) => {
  if ([ORDER_SUMMARY, PENDING_ORDER, ORDER_CONFIRMATION].includes(page)) {
    if (!isAmendOrderAvailable)
      return <AmendOrderAvailabilityText showAmendUnvailabilityText={true} isOrderDue={isOrderDue} />;

    if (page !== ORDER_CONFIRMATION) {
      return (
        <AmendOrderAvailabilityText
          showAmendUnvailabilityText={false}
          amendCutoff={amendCutoff}
          isOrderDue={isOrderDue}
        />
      );
    }
  }

  return null;
};

export default AmendInfoMessage;
