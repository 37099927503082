import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { compose } from 'react-recompose';
import ActionableLayout from '#/components/layouts/actionable';
import helpers from '#/lib/decorators/helpers';
import { connect } from '#/lib/render/connect-deep-compare';
import { getLanguageLink } from '#/reducers/app';
import { getSelectedLocation, getSelectedLocationId, getSuggestionSelectedAddressId } from '#/reducers/slot';

const mapStateToProps = (state: any) => ({
  // @ts-expect-error the typing of getLanguageLink is not correct typed in a js file
  languageLink: url => getLanguageLink(state, url, ''),
  selectedLocation: getSelectedLocation(state),
  selectedLocationId: getSelectedLocationId(state),
  suggestionSelectedAddressId: getSuggestionSelectedAddressId(state),
});

type TOwnProps = {
  children: any;
  languageLink: (url: string) => string;
  selectedDate?: string;
  selectedLocation: { locationId: string };
  selectedLocationId: string;
  suggestionSelectedAddressId: string;
  t: (key: string, data?: object) => string;
};

const enhance = compose<TOwnProps, {}>(helpers(['t']), connect(mapStateToProps));

const ChooseALocationLayoutSPA: React.FC<TOwnProps> = (props: TOwnProps) => {
  const generateUrl = useCallback(locationId => {
    const { languageLink, selectedDate, suggestionSelectedAddressId } = props;

    const urlTemplate = languageLink(`/slots/collection/${selectedDate || ''}`);
    const params = { locationId: locationId || '', addressId: suggestionSelectedAddressId };
    const queryParams = new URLSearchParams(params as Record<string, string>);

    return languageLink(`${urlTemplate}?${queryParams.toString()}`);
  }, []);

  const {
    selectedLocation: { locationId },
    selectedLocationId,

    t: translate,
  } = props;

  return (
    <ActionableLayout
      {...props}
      cancelText={translate('common:cancel')}
      cancelUrl={generateUrl(selectedLocationId)}
      doneUrl={generateUrl(locationId)}
      title={translate('slots:collection.choose-store')}
    />
  );
};

ChooseALocationLayoutSPA.displayName = 'ChooseALocationLayoutSPA';

ChooseALocationLayoutSPA.propTypes = {
  children: PropTypes.object.isRequired,
  languageLink: PropTypes.func.isRequired,
  selectedDate: PropTypes.string,
  selectedLocation: PropTypes.shape({
    locationId: PropTypes.string.isRequired,
  }).isRequired,
  selectedLocationId: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default enhance(ChooseALocationLayoutSPA);
