import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  SEARCH,
  CATEGORY as CATEGORY_RESULT_TYPE
} from '#/constants/result-types';
import { searchAnalyticsEvent } from '#/analytics/types/search';
import { connect } from '#/lib/render/connect-deep-compare';
import helpers from '#/lib/decorators/helpers';
import { pluck } from '#/lib/object-helpers';
import { toJS } from '#/utils/immutable-utils';
import { getDisplayArea } from '#/lib/recommender-helpers';
import { getIsUpdating } from '#/reducers/filter';
import { getProductIsForSale, isProduct } from '#/selectors/item';
import { getSubstituteFeatureExperiment } from '#/selectors/substitution';
import AnalyticsRenderedProductsEvent from '#/components/analytics/rendered-products-event';
import CountOrShowMoreLink from '#/components/sort-n-filter/filter-by/count-or-show-more-link';
import ProductFilter from '#/components/products/product-filter';
import OverlaySpinner from '#/components/overlay-spinner';
import ListContainer from './list/container';
import { HEAD, FOOT } from '#/constants/common';
import {
  StyledNotification,
  StyledBodyText
} from '#/components/products/product-list/styled';
import { INFO } from '#/constants/notification-variants';
import { GRID, LIST } from '#/constants/favorites';
import { isSelectedTabFavorites } from '#/lib/favorites-helpers';
import { getCurrentUrl } from '#/reducers/app';
import { getShouldShowContentTile } from '#/experiments/oop-2021/selectors';
import { BasketTypes } from '#/lib/records/split-basket';
import {
  logAllSponsoredMedia,
  clearLoggedSponsoredMedia
} from '#/utils/log-sponsored-media';

const mapStateToProps = state => ({
  substituteFeatureExperiment: getSubstituteFeatureExperiment(state),
  filterStoreIsUpdating: getIsUpdating(state),
  showContentTile: getShouldShowContentTile(state),
  isSelectedTabFavorites: isSelectedTabFavorites(getCurrentUrl(state))
});

@helpers(['c', 't'])
@connect(mapStateToProps)
export default class ProductList extends Component {
  static propTypes = {
    actionButtons: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    ),
    breadcrumbs: PropTypes.array,
    c: PropTypes.func.isRequired,
    dfp: PropTypes.shape({
      pageId: PropTypes.string.isRequired,
      targeting: PropTypes.object
    }),
    experiments: PropTypes.object,
    facetLists: PropTypes.array,
    filterStoreIsUpdating: PropTypes.bool,
    hashedEmail: PropTypes.string,
    hideFiltersOverlay: PropTypes.func,
    identifier: PropTypes.string,
    infoMessage: PropTypes.string,
    isAmendBasket: PropTypes.bool,
    isGrid: PropTypes.bool,
    isGroceriesBasket: PropTypes.bool,
    isGrouped: PropTypes.bool,
    isSelectedTabFavorites: PropTypes.bool,
    items: PropTypes.object.isRequired,
    itemsPerPage: PropTypes.number,
    listData: PropTypes.object.isRequired,
    onRenderRecommendations: PropTypes.func,
    pageNo: PropTypes.number,
    pages: PropTypes.array,
    productTileVariant: PropTypes.string,
    query: PropTypes.string,
    renderProductFilters: PropTypes.func,
    resultType: PropTypes.string,
    sectionMessageHeadingTag: PropTypes.string,
    selectedItems: PropTypes.array,
    showBrandsFilter: PropTypes.bool,
    showCategoriesFilter: PropTypes.bool,
    showDietaryFilter: PropTypes.bool,
    showFilters: PropTypes.bool,
    showMobilePagination: PropTypes.bool,
    showPromotionsFilter: PropTypes.bool,
    showSuperDepartmentFilter: PropTypes.bool,
    showUnavailableWarning: PropTypes.bool,
    sortOptions: PropTypes.array,
    substituteFeatureExperiment: PropTypes.string,
    superDepartments: PropTypes.array,
    t: PropTypes.func.isRequired,
    tileType: PropTypes.string.isRequired,
    totalCount: PropTypes.number,
    triggerAnalyticsEvent: PropTypes.bool,
    userRegistered: PropTypes.bool.isRequired,
    useExpander: PropTypes.bool,
    withListOverlay: PropTypes.bool
  };

  static defaultProps = {
    actionButtons: [],
    breadcrumbs: [],
    isGrid: false,
    isGrouped: false,
    selectedItems: [],
    showFilters: false,
    showCategoriesFilter: true,
    showMobilePagination: false,
    showSuperDepartmentFilter: false,
    showPromotionsFilter: true,
    showUnavailableWarning: false,
    triggerAnalyticsEvent: true,
    useExpander: false,
    withListOverlay: false,
    renderProductFilters: props => <ProductFilter {...props} />
  };

  constructor() {
    super();
    this.state = { removeFromFavouritesModalOpen: false };
  }

  shouldComponentUpdate(nextProps) {
    const {
      facetLists,
      isAmendBasket,
      items,
      pageNo,
      pages,
      userRegistered,
      filterStoreIsUpdating,
      isSelectedTabFavorites,
      isGrid
    } = this.props;

    return (
      isAmendBasket !== nextProps.isAmendBasket ||
      userRegistered !== nextProps.userRegistered ||
      items !== nextProps.items ||
      (pages && pages !== nextProps.pages) ||
      pageNo !== nextProps.pageNo ||
      facetLists !== nextProps.facetLists ||
      filterStoreIsUpdating !== nextProps.filterStoreIsUpdating ||
      (isSelectedTabFavorites && isGrid !== nextProps.isGrid)
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.items !== this.props.items) {
      // This component is re-rendered when both filtering & paginating.
      // When paginating old products are still in list, below ensures we only
      // send new items to analytics for pagination, all products when filtering
      if (this.shouldTriggerSearchAnalytics(prevProps, this.props)) {
        searchAnalyticsEvent(
          this.props.query,
          this.props.totalCount,
          this.props.resultType,
          1,
          this.props.items.size
        );
      }
    }
  }

  componentWillUnmount() {
    clearLoggedSponsoredMedia();
  }

  shouldTriggerSearchAnalytics(prevProps, props) {
    return (
      props.resultType === SEARCH &&
      prevProps.query === props.query &&
      prevProps.totalCount !== props.totalCount
    );
  }

  makeLists() {
    const {
      pageNo,
      pages,
      resultType,
      showMobilePagination,
      productTileVariant,
      breadcrumbs
    } = this.props;
    const listProps = pluck(this.props, [
      'hideQuantityToggle',
      'identifier',
      'isAmendBasket',
      'isGrid',
      'isGroceriesBasket',
      'isGrouped',
      'items',
      'listData',
      'sectionMessageHeadingTag',
      'totalCount',
      'tileType',
      'userRegistered',
      'experiments',
      'superDepartments',
      'useExpander'
    ]);
    const pageType =
      resultType === CATEGORY_RESULT_TYPE
        ? getDisplayArea(breadcrumbs, resultType)
        : resultType;

    // TODO fix issue here where multiple ListContainers are re-rendered despite having standard pagination.
    // This presumably exists for edge case of switching between portrait and landscape on a tablet, but is
    // likely having a significant effect on performance.
    // Also `showMobilePagination` needs to be named differently as seems to only apply to favourites results.
    if (pages && !showMobilePagination) {
      // paginated list
      return toJS(
        pages.map((pageItems, pageIndex) => {
          if (pageItems) {
            const prevPage = pageIndex ? pages[pageIndex - 1] : null;

            const previousGroupName = prevPage?.length
              ? prevPage[prevPage.length - 1].groupName
              : null;

            listProps.items = pageItems;

            return (
              <div
                className={classnames(resultType, {
                  'product-list--page': true,
                  'product-list--current-page': pageIndex === pageNo - 1
                })}
                key={`list-${pageIndex}`}
              >
                <ListContainer
                  {...listProps}
                  pageNo={pageIndex + 1}
                  previousGroupName={previousGroupName}
                  resultType={resultType}
                  productTileVariant={productTileVariant}
                  pageType={pageType}
                />
              </div>
            );
          }

          return null;
        })
      );
    }

    // non-paginated list
    return (
      <ListContainer
        {...listProps}
        resultType={resultType}
        productTileVariant={productTileVariant}
      />
    );
  }

  renderActionButtons(position) {
    return this.props.actionButtons.map((ele, ind) => {
      if (typeof ele === 'function') {
        const ComponentToRender = ele;
        return (
          <div key={ind} className="product-list__action-button">
            <ComponentToRender position={position} />
          </div>
        );
      }
      return (
        <div key={ind} className="product-list__action-button">
          {ele}
        </div>
      );
    });
  }

  getHasSponsoredProducts = () =>
    this.getItems().some(item => item.isSponsoredProduct);

  getItems = () => {
    const { items } = this.props;
    return Array.isArray(items) ? items : Array.from(items.values());
  };

  getProductsFromResults = () =>
    this.getItems().filter(item => isProduct(item));

  render() {
    // We have to use `toArray` here as on some devices Immutable will add the
    // keys to the items in production builds. It's recommended to use `toSeq`
    // but if we do that the `ReactContext` doesn't work right because the
    // elements are lazily created. `ReactContext` will be parent-based in 0.14
    // so we can hopefully just use `toSeq` then.
    // https://github.com/facebook/react/issues/3469

    const {
      actionButtons,
      breadcrumbs,
      className,
      facetLists,
      filterStoreIsUpdating,
      hideFiltersOverlay,
      infoMessage,
      isGrid,
      items,
      itemsPerPage,
      query,
      pageNo,
      resultType,
      showBrandsFilter,
      showCategoriesFilter,
      showDietaryFilter,
      showFilters,
      showPromotionsFilter,
      showSuperDepartmentFilter,
      showUnavailableWarning,
      sortOptions,
      substituteFeatureExperiment,
      t: translate,
      totalCount,
      triggerAnalyticsEvent,
      withListOverlay,
      isSelectedTabFavorites,
      showContentTile,
      useExpander,
      basketType,
      isMixedBasket,
      allItems
    } = this.props;
    const layoutType = isSelectedTabFavorites
      ? isGrid
        ? GRID
        : LIST
      : undefined;

    const shouldRenderCountOrShowMore =
      typeof totalCount !== 'undefined' && !showFilters;
    const pageType =
      resultType === CATEGORY_RESULT_TYPE
        ? getDisplayArea(breadcrumbs, resultType)
        : resultType;

    let count = 0;

    items.forEach(item => {
      if (getProductIsForSale(item)) count++;
    });

    logAllSponsoredMedia(items);

    const hasSponsoredProducts = this.getHasSponsoredProducts();
    const productList = showContentTile ? this.getProductsFromResults() : items;

    return (
      <>
        {showFilters &&
          this.props.renderProductFilters({
            breadcrumbs,
            count,
            facetLists,
            hideFiltersOverlay,
            query,
            showBrandsFilter,
            showCategoriesFilter,
            showDietaryFilter,
            showPromotionsFilter,
            showSuperDepartmentFilter,
            sortOptions
          })}
        <div
          className={`product-list-container ${className ? className : ''}`}
          data-auto="product-list-container"
        >
          {showUnavailableWarning && (
            <div className="unavailable-warning info-message">
              {translate('product-list:unavailable')}
            </div>
          )}
          {(shouldRenderCountOrShowMore || actionButtons.length > 0) && (
            <div className="product-list__controls product-list__controls--head">
              {shouldRenderCountOrShowMore && (
                <CountOrShowMoreLink count={totalCount} />
              )}
              {actionButtons.length > 0 && (
                <div className="product-list__action-buttons">
                  {this.renderActionButtons(HEAD)}
                </div>
              )}
            </div>
          )}
          <div className="product-lists">
            {infoMessage && (
              <StyledNotification showIcon variant={INFO}>
                <StyledBodyText>{infoMessage}</StyledBodyText>
              </StyledNotification>
            )}
            <div
              className={classnames('product-lists-wrapper', {
                expander: useExpander && this.getItems().length > 3
              })}
            >
              {withListOverlay && (
                <OverlaySpinner
                  isLoading={filterStoreIsUpdating}
                  largeOverlay
                />
              )}
              {this.makeLists()}
            </div>
          </div>
          {actionButtons.length > 0 && (
            <div className="product-list__controls">
              <div className="product-list__action-buttons product-list__action-buttons--foot">
                {this.renderActionButtons(FOOT)}
              </div>
            </div>
          )}
          {triggerAnalyticsEvent &&
            !(isMixedBasket && basketType === BasketTypes.MARKETPLACE) && (
              <AnalyticsRenderedProductsEvent
                componentName={hasSponsoredProducts ? 'sponsored' : undefined}
                componentType={hasSponsoredProducts ? 'd' : undefined}
                displayArea={isGrid ? 'grd' : 'ukn'}
                items={isMixedBasket ? allItems : productList}
                itemsPerPage={itemsPerPage}
                pageNo={pageNo}
                pageType={pageType}
                resultsCount={totalCount}
                sendSponsoredId={hasSponsoredProducts}
                seedLookup={substituteFeatureExperiment}
                {...(layoutType && {
                  layoutType: layoutType
                })}
              />
            )}
        </div>
      </>
    );
  }
}
