export const FILTER_OPTIONS_SUPERDEPARTMENT = 'superdepartment';
export const FILTER_OPTIONS_BRAND = 'brands';
export const FILTER_OPTIONS_DIETARY = 'lifestyle-and-dietary';
export const FILTER_OPTIONS_CATEGORY = 'categories';
export const FILTER_OPTIONS_AVAILABLE = 'available';
export const FILTER_OPTIONS_OFFERS = 'promotions';
export const FILTER_OPTIONS_PRODUCT_SOURCE = 'productSource';

// Any changes to these constants will also require changes to the styles
export const MEDIUM_BREAKPOINT = 991; // corresponds to @medium-max in grid.less
export const NUM_COLUMNS_MEDIUM = 3; // number of columns to use on medium width devices (e.g. iPad portrait)
export const NUM_COLUMNS_LARGE = 4; // number of columns to use on large width devices (e.g. iPad landscape)
export const NUM_ROWS = 4; // max number of rows to display when not expanded
