export const slotNotifyMeVariants = {
  key: '2034',
  featureKey: 'ghs-all_slot-notify-me-fd',
  variationKey: 'variationKey',
  variants: {
    default: '2034-a',
    slotNotifyMe: '2034-b',
  },
  useCustomerId: true,
};

export const DISMISS_INTERESTED_IN_SLOT_NOTIFY_ME = 'dismissInterestedInSlotNotifyMe';
export const UNAVAILABLE_NOTIFY_ME = 'unavailable:notify me';
export const UNAVAILABLE_MODAL = 'unavailable modal:';
export const UNAVAILABLE_NOTED_MODAL = 'unavailable noted modal:';
export const IMPRESSION = 'impression';
export const IM_INTERESTED = 'im interested';
export const NO_THANKS = 'no thanks';
export const OK = 'ok';
