import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Swiper } from '../swiper';

class CarouselWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = { activeSlide: 0 };
    this.selectSlide = slideIndex => {
      this.setState({ activeSlide: slideIndex });
    };
    this.swipeRight = this.swipeRight.bind(this);
    this.swipeLeft = this.swipeLeft.bind(this);
  }

  swipeRight() {
    const currentSlide = this.state.activeSlide;

    if (currentSlide > 0) {
      this.selectSlide(currentSlide - 1);
    } else {
      this.selectSlide(this.props.children.length - 1);
    }
  }

  swipeLeft() {
    const currentSlide = this.state.activeSlide;

    if (currentSlide < this.props.children.length - 1) {
      this.selectSlide(currentSlide + 1);
    } else {
      this.selectSlide(0);
    }
  }

  render() {
    const isActive = slideIndex => this.state.activeSlide === slideIndex;
    const id = this.props.children[0].props.placement;

    // Hidden Top level radio buttons
    const slidesControl = this.props.children.map((slide, index) => {
      const boundSelectSlide = this.selectSlide.bind(null, index);

      return (
        <input
          type="radio"
          checked={isActive(index)}
          name={`slider_id${id}`}
          key={index}
          id={`id${id}_slide_${index}`}
          className="carousel_input"
          onChange={boundSelectSlide}
        />
      );
    });

    const slidesControlDots = this.props.children.map((slide, index) => (
      // This is not accessible, but carousels are generally bad for accessibility
      // Discussions to be had on how to take this forward
      // eslint-disable-next-line jsx-a11y/label-has-associated-control
      <label key={index} htmlFor={`id${id}_slide_${index}`} />
    ));

    const carouselClasses = classnames(
      'ui-ddl-carousel',
      'clear',
      { mobileOnly: this.props.mobileOnly },
      { previewNextSlide: this.props.previewNextSlide },
      { indentWrapper: this.props.indentWrapper },
      { fullWidth: this.props.fullWidth },
      { 'big-tiles-on-mobile': this.props.bigTilesOnMobile }
    );

    return (
      <Swiper onSwipeLeft={this.swipeLeft} onSwipeRight={this.swipeRight}>
        <div className={carouselClasses}>
          {slidesControl}
          <div className="carousel__slides">
            <div className="carousel__overflow">
              <div className="inner">
                {this.props.children.map((child, index) => (
                  <div className="carousel__item" key={index}>
                    <child.type {...child.props} />
                  </div>
                ))}
              </div>
            </div>
          </div>
          {this.props.children.length > 1 ? ( // If more then one child, render controls
            <div className="carousel__controls">
              <button
                className="slider_control control_prev icon-chevron_left"
                onClick={this.swipeRight}
              >
                {'Previous'}
              </button>
              <div className="carousel__dots">{slidesControlDots}</div>
              <button
                className="slider_control control_Next icon-chevron_right"
                onClick={this.swipeLeft}
              >
                {'Next'}
              </button>
            </div>
          ) : null}
        </div>
      </Swiper>
    );
  }
}

CarouselWrapper.propTypes = {
  children: PropTypes.array,
  fullWidth: PropTypes.bool,
  indentWrapper: PropTypes.bool,
  mobileOnly: PropTypes.bool,
  previewNextSlide: PropTypes.bool
};

export { CarouselWrapper };
