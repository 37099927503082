/**
 * Experiment constants for slot matrix Morning/Afternoon/Evening split
 */

export const timesOfTheDaySplitVariants = {
  key: '1834',
  featureKey: 'ghs-all_slot-am-pm-split',
  variationKey: 'experience',
  variants: {
    default: 'off',
    timesOfTheDaySplit: 'on',
  },
  useCustomerId: true,
};
