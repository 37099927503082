// taken from https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie/Simple_document.cookie_framework
// only included the methods we need, modified setCookie for increased robustness and added tests

export function removeCookie(sKey, sPath, sDomain, sameSite) {
  document.cookie = `${encodeURIComponent(
    sKey
  )}=; expires=Thu, 01 Jan 1970 00:00:00 GMT${
    sDomain ? `; domain=${sDomain}` : ''
  }${sPath ? `; path=${sPath}` : ''}${
    sameSite ? `; sameSite=${sameSite}` : ''
  }; secure`;
}

export function getCookieValue(sKey) {
  return (
    decodeURIComponent(
      document.cookie.replace(
        new RegExp(
          '(?:(?:^|.*;)\\s*' +
            encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, '\\$&') +
            '\\s*\\=\\s*([^;]*).*$)|^.*$'
        ),
        '$1'
      )
    ) || null
  );
}

export function setCookie(
  sKey,
  sValue,
  vEnd,
  sPath,
  sDomain,
  sameSite,
  encodeKeyValuePair = true
) {
  if (
    !sKey ||
    /^(?:expires|max\-age|path|domain|secure|sameSite)$/i.test(sKey)
  ) {
    return false;
  }

  let sExpires = '';

  if (vEnd) {
    switch (Object.prototype.toString.call(vEnd)) {
      case '[object Number]':
        sExpires =
          vEnd === Infinity
            ? '; expires=Fri, 31 Dec 9999 23:59:59 GMT'
            : `; max-age=${vEnd}`;
        break;
      case '[object String]':
        sExpires = `; expires=${vEnd}`;
        break;
      case '[object Date]':
        sExpires = `; expires=${vEnd.toUTCString()}`;
        break;
    }
  }

  const newKey = encodeKeyValuePair ? encodeURIComponent(sKey) : sKey;
  const newValue = encodeKeyValuePair ? encodeURIComponent(sValue) : sValue;

  document.cookie = `${newKey}=${newValue}${sExpires}${
    sDomain ? `; domain=${sDomain}` : ''
  }${sPath ? `; path=${sPath}` : ''}${
    sameSite ? `; sameSite=${sameSite}` : ''
  }; secure`;

  return true;
}

export const cookieOptions = (req, res, attributes = {}) => {
  if (attributes.sameSite === undefined) {
    attributes.sameSite = 'lax';
  } else if (attributes.sameSite === 'none' && !res.locals.setSameSiteNone) {
    delete attributes.sameSite;
  }

  if (!attributes.domain) {
    attributes.domain = req.hostname;
  }
  if (attributes.httpOnly !== false) {
    attributes.httpOnly = true;
  }
  return {
    secure: true,
    ...attributes
  };
};

export function getAllCookiesWithPrefix(req, prefix) {
  return Object.keys(req.cookies)
    .filter(cookieName => cookieName.indexOf(prefix) === 0)
    .reduce((obj, key) => {
      const keyWithoutPrefix = key.substring(prefix.length);

      obj[keyWithoutPrefix] = req.cookies[key];

      return obj;
    }, {});
}
