/* eslint react/prop-types:0  */
import React from 'react';

import {
  GENERIC_ERROR_MODAL,
  CHANGE_SLOT_TYPE_MODAL,
  BAG_OPTIONS_MODAL,
  ECOUPON_MODAL,
  EMPTY_TROLLEY_MODAL,
  REMOVE_FAVOURITE_MODAL,
  AMEND_SAVE_MODAL,
  AMEND_CANCEL_MODAL,
  AMEND_EXPIRED_MODAL,
  AUTH_REFRESH_REQUIRED_MODAL,
  NO_RESULT_MODAL,
  CONNECTION_TIMEOUT_MODAL,
  IMAGE_ZOOM_MODAL,
  ONE_ACCOUNT_ADDRESS_MODAL_BOOK_A_SLOT,
  PENDING_ORDER_CANCEL_MODAL,
  BOOK_SLOT_SELECT_UNDELIVERABLE_ADDRESS_MODAL,
  UNDELIVERABLE_ADDRESS_MODAL,
  ITEMS_UNAVAILABLE_MODAL,
  FORESEE_MODAL,
  DELETE_A_REVIEW_MODAL,
  FAKE_DOOR_THANK_YOU_MODAL,
  BASKET_CAPACITY_EXCEED_MODAL,
  INCORRECT_PHONE_NUMBER_MODAL,
  CANCEL_SLOT_BOOKING_MODAL,
  SLOT_CHANGED_IN_AMEND_MODAL,
  CARD_SAVE_MESSAGE_MODAL,
  SKIP_OFFERS_MODAL,
  CLUBCARD_OPT_IN_MODAL,
  FAV_QUICK_BASKET_MODAL,
  UNAVAILABLE_SHOPPING_METHOD_MODAL,
  SLOT_REBOOK_MODAL,
  SLOT_REMIND_MODAL,
  INTERESTED_IN_THIS_SERVICE_MODAL,
  CHANGE_DETAILS_MODAL_BOOK_A_SLOT_EDIT_ADDRESS,
  CHANGE_DETAILS_MODAL_BOOK_A_SLOT_ADD_ADDRESS,
  RELOAD_PAGE_MODAL,
  MARKETPLACE_PRODUCT_MODAL
} from '#/constants/modal-names';
import { BOOK_SLOT_MODAL } from '#/constants/trolley';

import BagOptionsModal from './bag-options-modal';
import EcouponModal from './ecoupon-modal';
import EmptyTrolleyModal from './empty-trolley-modal';
import RemoveFavouritesModal from './remove-favourites-modal';
import AmendOrderSaveModal from './amend-order-save-modal';
import AmendOrderCancelModal from './amend-order-cancel-modal';
import AmendOrderExpiredModal from './amend-order-expired-modal';
import NoResultModal from './no-result-modal';
import ConnectionTimeoutModal from './connection-timeout-modal';
import AuthRefreshRequiredModal from './auth-refresh-required-modal';
import ChangeSlotTypeModal from './order-type-change-modal';
import OneAccountAddressModal from './one-account-address-modal';
import CancelPendingOrderModal from './cancel-pending-order-modal';
import BookASlotUndeliverableAddressModal from './book-a-slot-undeliverable-address-modal';
import UndeliverableAddressModal from './undeliverable-address-modal';
import ItemsUnavailableModal from './items-unavailable-modal';
import ForeseeModal from './foresee-modal';
import ImageZoomModal from './image-zoom-modal';
import DeleteReviewModal from '#/components/modals/delete-review-modal';
import FakeDoorTestModal from './fake-door-test-modal';
import BasketCapacityExceedModal from '#/components/modals/basket-capacity-exceed-modal';
import IncorrectPhoneNumberModal from '#/components/modals/incorrect-phone-number-modal';
import BookSlotModal from '#/components/book-slot-modal';
import ClubCardOptInModal, {
  EVENT_NOW,
  EVENT_SPLIT_AND_MERGE,
  EVENT_MODAL_IMPRESSION
} from '#/components/modals/clubcard-opt-in-modal';

import CancelBookedSlotModal from '#/components/modals/cancel-book-slot-modal';
import SkipOffersModal from '#/components/modals/skip-offers-modal';
import { BasketItemUnavailableModal } from '#/experiments/oop-1583/components';
import ShoppingMethodUnavailableModal from '#/components/modals/shopping-method-unavailable-modal';
import { CardSaveMessageModal } from '#/experiments/oop-1725/components/card-save-message-modal';
import { BASKET_ITEMS_UNAVAILABLE_MODAL_NAME } from '#/experiments/oop-1583/constants';
import analyticsBus from '#/analytics/analyticsBus';
import { UI_EVENT_BASIC_EVENT } from '#/analytics/constants';
import SlotRebookModal from '#/experiments/oop-2210/components/slot-rebook-modal';
import SlotRemindModal from '#/experiments/oop-2210/components/slot-remind-modal';
import { renderInterestedInThisServiceModal } from '#/experiments/oop-2034/components/modals/modals';
import SlotChangedInAmendModal from './slot-changed-in-amend-modal';
import QuickBasketModal from './quick-basket-modal/quick-basket-modal';
import ChangeDetailsModal from '#/components/modals/change-details-modal/ChangeDetailsModal';
import ReloadPageModal from '#/components/modals/reload-page-modal/ReloadPageModal';
import MarketPlaceProductModal from '#/components/modals/market-place-product-modal';

const renderEmptyTrolleyModal = () => <EmptyTrolleyModal />;

const renderCancelBookedSlotModal = () => <CancelBookedSlotModal />;

const renderConnectionTimeoutModal = () => <ConnectionTimeoutModal />;

const renderEcouponModal = props => (
  <EcouponModal
    couponSavings={props.couponSavings}
    guidePrice={props.guidePrice}
  />
);

const renderThankYouModal = ({ modalData }) => (
  <FakeDoorTestModal modalData={modalData} />
);

const renderIncorrectPhoneNumberModal = ({ modalData }) => (
  <IncorrectPhoneNumberModal modalData={modalData} />
);

const renderRemoveFavouriteModal = () => <RemoveFavouritesModal />;

const renderAmendSaveModal = props =>
  props.isAmendBasket && (
    <AmendOrderSaveModal
      bagCharge={props.bagCharge}
      isBagless={props.isBagless}
      couponDiscount={props.couponSavings}
      shoppingMethod={props.shoppingMethod}
      guidePrice={props.guidePrice}
      totalPrice={props.totalPrice}
    />
  );

const renderAmendCancelModal = props =>
  props.isAmendBasket && (
    <AmendOrderCancelModal cancelAmendRedirectUrl={props.l('/')} />
  );

const renderAmendExpiredModal = props =>
  props.isAmendBasket && (
    <AmendOrderExpiredModal shoppingMethod={props.shoppingMethod} />
  );

const renderChangeSlotTypeModal = props => (
  <ChangeSlotTypeModal {...props.changeSlotInfo} dispatch={props.dispatch} />
);

const renderBagOptionsModal = props => (
  <BagOptionsModal
    isUpdating={props.isUpdating}
    bagCharge={props.bagCharge}
    isBagless={props.isBagless}
  />
);

const renderAuthRefreshRequiredModal = () => <AuthRefreshRequiredModal />;

const renderNoResultModal = props => <NoResultModal filter={props.modalData} />;

const renderPendingOrderCancelModal = props => (
  <CancelPendingOrderModal modalData={props.modalData} />
);

const renderBookASlotUndeliverableAddressModal = ({
  selectedShoppingMethod
}) => (
  <BookASlotUndeliverableAddressModal shoppingMethod={selectedShoppingMethod} />
);

const getOneAccountAddressModalRenderer = (
  modalName,
  cancelButtonTextKey,
  forceTrolleyUpdateOnRefresh,
  page,
  action
) => props => (
  <OneAccountAddressModal
    forceTrolleyUpdateOnRefresh={forceTrolleyUpdateOnRefresh}
    oneAccountAction={action}
    name={props.customerFirstName}
    modalName={modalName}
    modalData={props.modalData}
    page={page}
    cancelButtonText={props.t(
      `modals:one-account-address-modal.${cancelButtonTextKey}`
    )}
  />
);

const renderUndeliverableAddressModal = props => (
  <UndeliverableAddressModal {...props} />
);

const renderItemsUnavailableModal = props => (
  <ItemsUnavailableModal {...props} />
);

const renderImageZoomModal = props => <ImageZoomModal {...props} />;

const renderCardSaveMessageModal = ({ modalData }) => (
  <CardSaveMessageModal {...modalData} />
);

const renderForeseeModal = props => <ForeseeModal {...props} />;
const renderDeleteReviewModal = ({ modalData }) => (
  <DeleteReviewModal reviewId={modalData} />
);

const renderBasketCapacityExceedModal = () => <BasketCapacityExceedModal />;

const renderSkipOffersModal = props => <SkipOffersModal {...props} />;
const renderItemUnavailableModal = () => <BasketItemUnavailableModal />;

const renderUnavailablShoppingMethodModal = () => (
  <ShoppingMethodUnavailableModal />
);

const renderFavQuickBasketModal = ({ modalData }) => (
  <QuickBasketModal {...modalData} />
);

const renderClubCardOptInModal = () => {
  analyticsBus().emit(UI_EVENT_BASIC_EVENT, {
    action: EVENT_NOW,
    type: EVENT_SPLIT_AND_MERGE,
    value: EVENT_MODAL_IMPRESSION
  });
  return <ClubCardOptInModal />;
};

const renderBookSlotModal = () => <BookSlotModal />;

const renderSlotRebookModal = ({ modalData }) => {
  return <SlotRebookModal {...modalData} />;
};
const renderSlotRemindModal = ({ modalData }) => (
  <SlotRemindModal {...modalData} />
);
export const renderSlotChangedInAmendModal = () => <SlotChangedInAmendModal />;

const renderChangeDetailsModal = ({ modalName }) => (
  <ChangeDetailsModal modalName={modalName} isOpen={true} />
);

const renderMarketPlaceProductModal = () => <MarketPlaceProductModal />;

const renderReloadPageModal = () => <ReloadPageModal isOpen={true} />;
export const Modals = {
  [AMEND_SAVE_MODAL]: renderAmendSaveModal,
  [AMEND_CANCEL_MODAL]: renderAmendCancelModal,
  [AMEND_EXPIRED_MODAL]: renderAmendExpiredModal,
  [CONNECTION_TIMEOUT_MODAL]: renderConnectionTimeoutModal,
  [GENERIC_ERROR_MODAL]: renderConnectionTimeoutModal,
  [BAG_OPTIONS_MODAL]: renderBagOptionsModal,
  [ECOUPON_MODAL]: renderEcouponModal,
  [EMPTY_TROLLEY_MODAL]: renderEmptyTrolleyModal,
  [FAKE_DOOR_THANK_YOU_MODAL]: renderThankYouModal,
  [IMAGE_ZOOM_MODAL]: renderImageZoomModal,
  [INCORRECT_PHONE_NUMBER_MODAL]: renderIncorrectPhoneNumberModal,
  [CHANGE_SLOT_TYPE_MODAL]: renderChangeSlotTypeModal,
  [REMOVE_FAVOURITE_MODAL]: renderRemoveFavouriteModal,
  [AUTH_REFRESH_REQUIRED_MODAL]: renderAuthRefreshRequiredModal,
  [NO_RESULT_MODAL]: renderNoResultModal,
  [PENDING_ORDER_CANCEL_MODAL]: renderPendingOrderCancelModal,
  [CANCEL_SLOT_BOOKING_MODAL]: renderCancelBookedSlotModal,
  [ONE_ACCOUNT_ADDRESS_MODAL_BOOK_A_SLOT]: getOneAccountAddressModalRenderer(
    ONE_ACCOUNT_ADDRESS_MODAL_BOOK_A_SLOT,
    'book-a-slot.no',
    true,
    'book-a-slot'
  ),
  [BOOK_SLOT_SELECT_UNDELIVERABLE_ADDRESS_MODAL]: renderBookASlotUndeliverableAddressModal,
  [UNDELIVERABLE_ADDRESS_MODAL]: renderUndeliverableAddressModal,
  [ITEMS_UNAVAILABLE_MODAL]: renderItemsUnavailableModal,
  [FORESEE_MODAL]: renderForeseeModal,
  [DELETE_A_REVIEW_MODAL]: renderDeleteReviewModal,
  [BASKET_CAPACITY_EXCEED_MODAL]: renderBasketCapacityExceedModal,
  [CARD_SAVE_MESSAGE_MODAL]: renderCardSaveMessageModal,
  [SKIP_OFFERS_MODAL]: renderSkipOffersModal,
  [BASKET_ITEMS_UNAVAILABLE_MODAL_NAME]: renderItemUnavailableModal,
  [UNAVAILABLE_SHOPPING_METHOD_MODAL]: renderUnavailablShoppingMethodModal,
  [CLUBCARD_OPT_IN_MODAL]: renderClubCardOptInModal,
  [FAV_QUICK_BASKET_MODAL]: renderFavQuickBasketModal,
  [BOOK_SLOT_MODAL]: renderBookSlotModal,
  [SLOT_CHANGED_IN_AMEND_MODAL]: renderSlotChangedInAmendModal,
  [SLOT_REBOOK_MODAL]: renderSlotRebookModal,
  [SLOT_REMIND_MODAL]: renderSlotRemindModal,
  [INTERESTED_IN_THIS_SERVICE_MODAL]: renderInterestedInThisServiceModal,
  [CHANGE_DETAILS_MODAL_BOOK_A_SLOT_EDIT_ADDRESS]: () =>
    renderChangeDetailsModal({
      modalName: CHANGE_DETAILS_MODAL_BOOK_A_SLOT_EDIT_ADDRESS
    }),
  [CHANGE_DETAILS_MODAL_BOOK_A_SLOT_ADD_ADDRESS]: () =>
    renderChangeDetailsModal({
      modalName: CHANGE_DETAILS_MODAL_BOOK_A_SLOT_ADD_ADDRESS
    }),
  [RELOAD_PAGE_MODAL]: renderReloadPageModal,
  [MARKETPLACE_PRODUCT_MODAL]: renderMarketPlaceProductModal
};
