import { FC, useEffect } from 'react';
import { connect } from '#/lib/render/connect-deep-compare';
import { emitSlotOp } from '#/analytics/bertie/events';
import { getIsDesktop, getTimezone } from '#/reducers/app';
import {
  getSelectedDateSlots,
  getSelectedLocationId,
  getSelectedShoppingMethod,
  getSelectedWeekSlots,
} from '#/reducers/slot';
import { getStoreId } from '#/selectors/trolley';
import { getDeliveryAddressForTrollyId } from '#/selectors/detailed-addresses';

export type OwnProps = Record<string, never>;
export type StateProps = {
  isDesktop: ReturnType<typeof getIsDesktop>;
  selectedWeekSlots: ReturnType<typeof getSelectedWeekSlots>;
  selectedDateSlots: ReturnType<typeof getSelectedDateSlots>;
  selectedShoppingMethod: ReturnType<typeof getSelectedShoppingMethod>;
  storeId: ReturnType<typeof getStoreId>;
  locationId: ReturnType<typeof getSelectedLocationId>;
  addressId: ReturnType<typeof getDeliveryAddressForTrollyId>;
  timezone: ReturnType<typeof getTimezone>;
};
export type Props = OwnProps & StateProps;

const mapStateToProps = (state: Store): StateProps => ({
  isDesktop: getIsDesktop(state),
  selectedWeekSlots: getSelectedWeekSlots(state),
  selectedDateSlots: getSelectedDateSlots(state),
  selectedShoppingMethod: getSelectedShoppingMethod(state),
  storeId: getStoreId(state),
  locationId: getSelectedLocationId(state),
  addressId: getDeliveryAddressForTrollyId(state),
  timezone: getTimezone(state),
});

const Component: FC<Props> = ({
  isDesktop,
  selectedWeekSlots,
  selectedDateSlots,
  selectedShoppingMethod,
  storeId,
  locationId,
  addressId,
  timezone,
}) => {
  useEffect(() => {
    emitSlotOp(
      isDesktop ? selectedWeekSlots : selectedDateSlots,
      selectedShoppingMethod,
      storeId,
      locationId as string,
      addressId as string,
      timezone,
    );
  }, []);

  return null;
};

export const SlotAnalytics: FC<OwnProps> = connect(mapStateToProps)(Component);
