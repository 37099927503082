import React, { MouseEventHandler } from 'react';
import Button from '@ddsweb/button';
import { StyledDivider, AccordionContentContainer, StyledIcon } from '../styled';

export type TProps = {
  isOpen: boolean;
  classes: string;
  onClick: MouseEventHandler;
  displayHeader: unknown;
  displayIcon: 'contract' | 'expand';
};

export const DDSAccordion: React.FC<TProps> = ({ isOpen, children, classes, onClick, displayHeader, displayIcon }) => {
  return (
    <>
      <AccordionContentContainer isOpen={isOpen}>
        {children}
        <StyledDivider />
      </AccordionContentContainer>
      <Button className={classes} variant="secondary" fullWidth data-auto="accordion" onClick={onClick}>
        {displayHeader}
        <StyledIcon graphic={displayIcon} size="sm" />
      </Button>
    </>
  );
};
