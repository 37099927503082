import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Actions = props => (
  <div
    className={classnames('modal-actions', {
      'modal-actions--dual': props.dual,
      'modal-actions--stacked': props.stacked
    })}
  >
    {props.children}
  </div>
);

Actions.propTypes = {
  children: PropTypes.node,
  dual: PropTypes.bool,
  stacked: PropTypes.bool
};

export default Actions;
