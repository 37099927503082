// Convert hex to RGB
const hextoRGBA = hex => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;

  hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null;
};

const toRgba = (hex, opacity) => {
  if (hex.charAt(0) !== '#') {
    return hex;
  }

  const rgbaHex = hextoRGBA(hex);

  if (rgbaHex && rgbaHex.hasOwnProperty('r') && opacity) {
    return `rgba(${rgbaHex.r}, ${rgbaHex.g}, ${rgbaHex.b}, ${opacity})`;
  }

  return hex;
};

export { hextoRGBA, toRgba };
