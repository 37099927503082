import React, { FC, useState } from 'react';
import { compose } from 'react-recompose';
import { connect } from '#/lib/render/connect-deep-compare';
import { closeModal } from '#/actions/ui-action-creators';
import { ConnectedProps } from 'react-redux';
import { ModalButtonContainer, ModalHeading, JoinClubCardButton, StyledModal, StyledBoldText } from './styled';
import helpers from '#/lib/decorators/helpers';
import { TTranslateFunc } from '#/lib/records/helpers.defs';
import Button from '@ddsweb/button';
import CheckboxWithLabel from '@ddsweb/checkbox-with-label';
import { Text } from '@ddsweb/text';
import { getExternalClubcardUrl } from '#/reducers/app';
import { setCookie } from '#/lib/cookie/cookie-utils';
import { CLUBCARD_OPT_IN_COOKIE } from '#/constants/clubcard-details';
import analyticsBus from '#/analytics/analyticsBus';
import { basicEvent } from '#/analytics/types/basic';

export const EVENT_SPLIT_AND_MERGE = 'split&merge';
export const EVENT_MODAL_IMPRESSION = 'modal:impression';
export const EVENT_NOT_NOW = 'not now';
export const EVENT_NOW = 'now';
export const EVENT_CHECKBOX = 'checkbox';
export const EVENT_CHECKED = 'checked';
export const EVENT_UNCHECKED = 'unchecked';
export const EVENT_JOIN_CLUBCARD = 'join clubcard';
export const EVENT_CLOSE = 'close';

type OwnState = { clubcardOptInHref: string };

export type OwnProps = {
  c: Function;
};

export const mapStateToProps = (state: Store, { c: config }: OwnProps): OwnState => ({
  clubcardOptInHref: getExternalClubcardUrl(state, config),
});

const connector = connect(mapStateToProps, { closeModal });

type HelperProps = {
  t: TTranslateFunc;
};

export type Props = OwnProps & HelperProps & ConnectedProps<typeof connector>;

const ClubCardOptInModalComponent: FC<Props> = ({ closeModal, t: translate, clubcardOptInHref }) => {
  const [isChecked, setIsChecked] = useState(false);
  const checkboxLabel = translate('account:clubcard-opt-in.do-not-show-again-checkbox');

  const onJoinClubCardClick = (): void => {
    basicEvent(analyticsBus, {
      action: EVENT_NOW,
      type: EVENT_SPLIT_AND_MERGE,
      value: `${EVENT_JOIN_CLUBCARD}:${EVENT_CHECKBOX}:${isChecked ? EVENT_CHECKED : EVENT_UNCHECKED}`,
    });
  };

  const onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setIsChecked(e.target.checked);
    basicEvent(analyticsBus, {
      action: EVENT_NOW,
      type: EVENT_SPLIT_AND_MERGE,
      value: `${EVENT_CHECKBOX}:${e.target.checked ? EVENT_CHECKED : EVENT_UNCHECKED}`,
    });
  };

  const onNotNowClick = (): void => {
    basicEvent(analyticsBus, {
      action: EVENT_NOW,
      type: EVENT_SPLIT_AND_MERGE,
      value: `${EVENT_NOT_NOW}:${EVENT_CHECKBOX}:${isChecked ? EVENT_CHECKED : EVENT_UNCHECKED}`,
    });
    setCookieAndCloseModal();
  };

  const onModalXClose = (e: { action: string; id: string; open: boolean }): void => {
    if (e?.action === 'close') {
      basicEvent(analyticsBus, {
        action: EVENT_NOW,
        type: EVENT_SPLIT_AND_MERGE,
        value: `${EVENT_CLOSE}:${EVENT_CHECKBOX}:${isChecked ? EVENT_CHECKED : EVENT_UNCHECKED}`,
      });
    }
    setCookieAndCloseModal();
  };

  const setCookieAndCloseModal = (): void => {
    if (isChecked) {
      setCookie(CLUBCARD_OPT_IN_COOKIE, '1', Infinity, '/groceries');
    }
    closeModal();
  };

  return (
    <StyledModal open ssr persist id={'clubcard-opt-in-modal'} onChange={onModalXClose}>
      <ModalHeading>{translate('account:clubcard-opt-in.heading')}</ModalHeading>
      <Text as="p">{translate('account:clubcard-opt-in.details-1')}</Text>
      <Text as="p">{translate('account:clubcard-opt-in.details-2')}</Text>
      <Text as="p">
        <StyledBoldText> {translate('account:clubcard-opt-in.details-3')}</StyledBoldText>
      </Text>
      <ModalButtonContainer>
        <Button variant="secondary" onClick={onNotNowClick}>
          {translate('account:clubcard-opt-in.not-now-button')}
        </Button>
        <JoinClubCardButton
          ddl
          primaryText={translate('account:clubcard-opt-in.join-clubcard-button')}
          href={clubcardOptInHref}
          onClick={onJoinClubCardClick}
        />
      </ModalButtonContainer>
      <CheckboxWithLabel
        checkboxProps={{ 'aria-label': checkboxLabel }}
        checked={isChecked}
        labelText={checkboxLabel}
        onChange={onCheckboxChange}
      />
    </StyledModal>
  );
};

const enhance = compose(helpers(['t', 'c']), connector);
export default enhance(ClubCardOptInModalComponent);
