import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import helpers from '../../lib/decorators/helpers';
import Link from '../link-check-spa';
import { connect } from '../../lib/render/connect-deep-compare';
import analyticsBus from '../../analytics/analyticsBus';
import { basicEvent } from '../../analytics/types/basic';
import {
  getUrlHash,
  updatePageUrlWithoutRefresh,
  updateParamsInUrl,
  updateUrlHash,
  parseQueryString
} from '../../lib/url/url-utils';
import { getCurrentUrl } from '../../reducers/app';
import {
  getItemsPerPage,
  getOffset,
  getPageNo,
  getPageFirstItemId,
  getPageLastItemId
} from '../../reducers/results';
import { paginate } from '../../actions/results-actions';
import { TOP, BOTTOM } from '../../constants/common';
import { DEFAULT_PAGE_NAME } from '../../constants/query-strings';

const mapStateToProps = state => ({
  currentUrl: getCurrentUrl(state),
  firstItemId: getPageFirstItemId(state),
  offset: getOffset(state),
  pageNo: getPageNo(state),
  itemsPerPage: getItemsPerPage(state),
  lastItemId: getPageLastItemId(state)
});

@helpers(['c', 't'])
@connect(mapStateToProps, { paginate })
@withRouter
export default class ShowMoreLessLink extends PureComponent {
  static propTypes = {
    c: PropTypes.func.isRequired,
    currentUrl: PropTypes.string.isRequired,
    firstItemId: PropTypes.string.isRequired,
    itemsPerPage: PropTypes.number.isRequired,
    lastItemId: PropTypes.string.isRequired,
    offset: PropTypes.number.isRequired,
    pageNo: PropTypes.number.isRequired,
    paginate: PropTypes.func.isRequired,
    params: PropTypes.objectOf(PropTypes.any).isRequired,
    position: PropTypes.oneOf([TOP, BOTTOM]),
    routes: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
    t: PropTypes.func.isRequired
  };

  componentDidMount() {
    if (process.env.CLIENT_SIDE) {
      this.scrollToView();
    }
  }

  componentDidUpdate() {
    if (process.env.CLIENT_SIDE) {
      setTimeout(() => {
        this.scrollToView();
      }, 0);
    }
  }

  getNewPageNo = (newItemsPerPage, listItemsPerPage) => {
    if (newItemsPerPage === listItemsPerPage) {
      const newPageNo =
        (this.props.pageNo * this.props.itemsPerPage) / newItemsPerPage;

      return this.props.position === BOTTOM ? newPageNo : newPageNo - 1;
    }

    if (newItemsPerPage > listItemsPerPage) {
      return Math.ceil(
        (this.props.pageNo * listItemsPerPage) / newItemsPerPage
      );
    }
  };

  getHashValueForScroll = (maxItemsPerPage, newItemsPerPage) => {
    const isOffsetDevisibleBySize = this.props.offset % newItemsPerPage === 0;

    if (!isOffsetDevisibleBySize && newItemsPerPage === maxItemsPerPage) {
      return this.props.position === TOP
        ? this.props.firstItemId
        : this.props.lastItemId;
    }
  };

  getLinkProps = () => {
    const listItemsPerPage = this.props.c('list-items-per-page');
    const maxItemsPerPage = this.props.c('max-list-items-per-page');
    const newItemsPerPage =
      this.props.itemsPerPage === listItemsPerPage
        ? maxItemsPerPage
        : listItemsPerPage;
    const newPageNo = this.getNewPageNo(newItemsPerPage, listItemsPerPage);
    let to = updateParamsInUrl(this.props.currentUrl, {
      [DEFAULT_PAGE_NAME]: newPageNo,
      count: newItemsPerPage
    });
    const hashValue = this.getHashValueForScroll(
      maxItemsPerPage,
      newItemsPerPage
    );

    to = hashValue ? updateUrlHash(to, hashValue) : to;

    const onClick = event => {
      event.preventDefault();
      updatePageUrlWithoutRefresh(window, to);

      this.props.paginate(
        { params: this.props.params, routes: this.props.routes },
        parseQueryString(to)
      );

      basicEvent(analyticsBus, {
        action: 'delay',
        type: 'show products per page',
        value: newItemsPerPage.toString()
      });
    };

    return {
      to,
      onClick,
      children: this.props.t('navigation:show-more-less-per-page', {
        newItemsPerPage
      }),
      rel: 'nofollow'
    };
  };

  scrollToView() {
    const hashValue = getUrlHash(document.location.href);

    if (hashValue) {
      const element = document.getElementById(hashValue);
      element && element.scrollIntoView();
    }
  }

  render() {
    const linkProps = this.getLinkProps();

    return (
      <div className="show-more-less__link">
        <Link {...linkProps} />
      </div>
    );
  }
}
