import { Price } from '#/lib/records/item.defs';
import { transformSubstitutions } from '#/lib/records/product-utils';
import { Item } from '#/lib/records/item';
import { ResultType } from '#/experiments/oop-2021/types';

type BasketData = {
  items: Array<Item>;
};

export default function transformBasketItem(data: BasketData): BasketData | undefined {
  if (data) {
    const { items, ...rest } = data;

    return {
      ...rest,
      items: ((items || []).map(item => {
        const { product, ...restOfItem } = item;
        const { price, promotions, ...restOfProduct } = product;
        const substitutions = transformSubstitutions(product);

        return {
          // needed for @mfe/basket provider
          id: product.id,
          ...restOfItem,
          type: ResultType.PRODUCT,
          product: {
            ...restOfProduct,
            ...(price as Price),
            substitutions,
          },
          promotions: promotions || [],
        };
      }) as unknown) as Array<Item>,
    };
  }
}
