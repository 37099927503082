import { clearSuggestions } from '#/actions/search-action-creators';
import { SuggestionData, SuggestionResult } from '#/actions/search-action-creators.defs';
import { UPDATE_SEARCH_SUGGESTIONS } from '#/constants/action-types';
import { Dispatch } from '#/custom-typings/redux-store/common';
import { SearchState } from '#/custom-typings/redux-store/search.defs';
import { RELATED_SEARCH_CONFIG } from '#/experiments/oop-1826/constants';
import { triggerRelatedSearchAnalyticsEvent } from '#/experiments/oop-1826/helpers/analytics';

export const fetchRelatedSearches = (dispatch: Dispatch, searchTerm: string, apiUrl: string): void => {
  const url = `${apiUrl}${encodeURIComponent(searchTerm)}&config=${RELATED_SEARCH_CONFIG}`;
  const initiatedAt = new Date();

  fetch(url)
    .then(res => res.json())
    .then(data => onFetchSuggestionsSuccess(data, dispatch, searchTerm, initiatedAt))
    .catch(() => {
      dispatch(clearSuggestions(initiatedAt));
    });
};

export const onFetchSuggestionsSuccess = (
  data: SuggestionData,
  dispatch: Dispatch,
  searchTerm: SearchState['searchTerm'] = '',
  initiatedAt: Date,
): void => {
  const suggestions = Array.isArray(data.results) ? data.results.map((result: SuggestionResult) => result.query) : [];

  suggestions.length && triggerRelatedSearchAnalyticsEvent();

  dispatch({
    type: UPDATE_SEARCH_SUGGESTIONS,
    suggestions,
    suggestionsFor: searchTerm,
    isPrediction: false,
    suggestionsFrom: initiatedAt,
  });
};
