const endSlashRegEx = /[\/]$/;
const urlProtocolRegEx = /^(http(s)?:)?\/\//;
const dataUrlRegEx = /^data:/;

function normalizePath(path = '') {
  if (!urlProtocolRegEx.test(path)) {
    if (path && path.charAt(0) !== '/') {
      path = '/' + path;
    }
  }

  path = path.replace(endSlashRegEx, '');

  return path;
}

export function formAssetUrl(file = '', baseStaticUrl = '', manifest = {}) {
  if (!dataUrlRegEx.test(file)) {
    file = normalizePath(file);
  }
  baseStaticUrl = normalizePath(baseStaticUrl);

  file = manifest[file] || file;

  // Check if the asset path is already a full URL or data URI and return the file as is if so.
  if (urlProtocolRegEx.test(file) || dataUrlRegEx.test(file)) {
    return file;
  }

  return baseStaticUrl + file;
}
