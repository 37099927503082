import formatLog from '../lib/format-log';

export function isLanguageInPath(basePath, path) {
  return new RegExp(`^(?:\\/${basePath})?\\/(\\w{2}-\\w{2})`, 'i').test(path);
}

export function isBasePathInPath(basePath, path) {
  return (
    typeof basePath === 'string' &&
    basePath.length > 0 &&
    new RegExp(`^\\/(${basePath})`, 'i').test(path)
  );
}

export function getBaseRoute(basePath, language) {
  const basePathRoute =
    typeof basePath === 'string' && basePath.length ? `/${basePath}` : '';

  return `${basePathRoute}/${language}`;
}

export function canUseFeatureFlipping(envVariable, configFlag) {
  return (envVariable && envVariable.toLowerCase() === 'true') || configFlag;
}

export function getPlatformFromTaxonomyState(taxonomyState, platform) {
  const hasTargetPlatform = !!taxonomyState.targetPlatform;
  const matchesTargetPlatform =
    taxonomyState.targetPlatform && taxonomyState.targetPlatform === platform;
  const shouldReturnTaxonomyState = !hasTargetPlatform || matchesTargetPlatform;

  return shouldReturnTaxonomyState ? taxonomyState : {};
}

export const getResponseFormat = (res, req, err, type, status) => {
  // eslint-disable-next-line no-console
  console.error(
    formatLog(
      err,
      req.site ? req.site.region : undefined,
      req.language,
      req.user != null ? req.user.id : undefined,
      type
    )
  );
  res.status(status);

  return res.format({
    html() {
      res.renderStream('pages/error/index.js', {
        pageTitle: req.t('common:pages.error'),
        props: {
          type: status,
          error: true,
          traceId: req.context.requestTraceID
        }
      });
    },
    json() {
      res.json({});
    }
  });
};

/* Turning on just GTM on error pages */
export function setErrorPageDependencies(res) {
  const { locals } = res;
  const { usingAppJS, gtmLibUrl, enableGoogleTM } = locals;

  if (usingAppJS) {
    const thirdPartyDependenciesDelay = (locals.thirdPartyDependenciesDelay = []);

    if (enableGoogleTM) {
      gtmLibUrl
        ? thirdPartyDependenciesDelay.push(gtmLibUrl)
        : thirdPartyDependenciesDelay;

      locals.spaBundles = [];
    }
  }
}
