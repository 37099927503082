export function getTwoRandomizedItemsFromArr<T>(arr: T[]): [T, T] {
  const mid: number = Math.floor(arr.length / 2);
  const firstHalf: T[] = arr.slice(0, mid);
  const secondHalf: T[] = arr.slice(mid);

  const getRandomItem = (array: T[]): T => {
    const randomIndex: number = Math.floor(Math.random() * array.length);
    return array[randomIndex];
  };

  const randomItemFromFirstHalf: T = getRandomItem(firstHalf);
  const randomItemFromSecondHalf: T = getRandomItem(secondHalf);

  return [randomItemFromFirstHalf, randomItemFromSecondHalf];
}
