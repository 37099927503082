/* eslint react/prop-types:0  */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RenderBrowseCategorySuggestion from '#/components/products/product-tile/tile-components/shared/render-browse-category-suggestion';
import RenderControls from '#/components/products/product-tile/tile-components/shared/render-controls';
import RenderPrice from '#/components/products/product-tile/tile-components/shared/render-price';
import RenderPromotions from '#/components/products/product-tile/tile-components/shared/render-promotions';
import RenderUnavailableBrowseCategoryLink from '#/components/products/product-tile/tile-components/shared/render-unavailable-browse-category-link';
import RenderBrowseCategoryLink from '#/components/products/product-tile/tile-components/shared/render-browse-category-link';
import RenderInfoMessages from '#/components/products/product-tile/tile-components/shared/render-info-messages';
import ProductImage from '#/components/products/product-tile/tile-components/product-image';
import ProductTitle from '#/components/products/product-tile/tile-components/product-title';
import UnavailableTile from '#/components/products/product-tile/tile-configurations/trolley/unavailable';
import helpers from '#/lib/decorators/helpers';
import LineItemPreference from '#/components/products/product-tile/tile-configurations/trolley/itemPreference';
import { TROLLEY } from '#/constants/tile-types';
import {
  isRdgViolated,
  isLeadTimeProductInOrder,
  getRdgDateRestriction,
  getRdgTimeRestriction,
  getRdgUnavailableDateRestriction
} from '#/utils/restrictions-util';
import ProductTileActions from '#/components/products/product-tile/tile-components/product-tile-actions';
import classNames from 'classnames';
import { connect } from '#/lib/render/connect-deep-compare';
import { getAisleNameFromItem } from '#/reducers/results';
import { getCurrentPathname } from '#/reducers/app';
import {
  getProductShelfId,
  getProductShelfName,
  getProductDefaultImageUrl,
  getProductAisleId,
  hasRestrictedOrderAmendment,
  isProductBrowsableOnPDP,
  getRestOfShelfUrl,
  getRdgRestrictions,
  isInBasket,
  getIsSubstitute,
  getProductIsForSale,
  getProductTitle,
  isInFavourites,
  hasSubstitution,
  getProductId
} from '#/selectors/item';
import {
  getIsTrolleyItemUpdating,
  getTrolleyShoppingMethod
} from '#/selectors/trolley';
import { isOnDemandShoppingMethod } from '#/lib/shopping-method-util';

const mapStateToProps = (state, { item }) => {
  const shoppingMethod = getTrolleyShoppingMethod(state);
  const productId = getProductId(item);

  return {
    aisleName: getAisleNameFromItem(state, item),
    currentPathname: getCurrentPathname(state),
    isItemUpdating: getIsTrolleyItemUpdating(state, productId),
    isOnDemandDelivery: isOnDemandShoppingMethod(shoppingMethod)
  };
};

@connect(mapStateToProps)
@helpers(['t', 'l', 'c'])
export default class TrolleyTile extends Component {
  static propTypes = {
    aisleName: PropTypes.string.isRequired,
    basketFeedbackRender: PropTypes.func.isRequired,
    c: PropTypes.func.isRequired,
    controlProps: PropTypes.object,
    currentPathname: PropTypes.string.isRequired,
    excludeMessages: PropTypes.array,
    href: PropTypes.string,
    identifier: PropTypes.string,
    isAmendBasket: PropTypes.bool,
    isAvailableEpwOverride: PropTypes.bool.isRequired,
    isItemUpdating: PropTypes.bool,
    isOnDemandDelivery: PropTypes.bool,
    item: PropTypes.object.isRequired,
    l: PropTypes.func.isRequired,
    selectedUnit: PropTypes.string,
    sellableUnitPrice: PropTypes.number,
    showDatesOnlyOnMissedSlot: PropTypes.bool,
    showHeartIcon: PropTypes.bool,
    showRestOfShelf: PropTypes.bool,
    showUnavailableMessage: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    userRegistered: PropTypes.bool
  };

  static defaultProps = {
    excludeMessages: [],
    isItemUpdating: false,
    showDatesOnlyOnMissedSlot: false,
    showHeartIcon: true,
    showRestOfShelf: true
  };

  constructor(props) {
    super(props);
    this.analyticsSetting = {
      enableMissedOfferTracking: this.props.c(
        'enableMissedOfferTrackingInAnalytics'
      )
    };
  }

  renderTrolleyTile(available) {
    const props = this.props;
    const {
      aisleName,
      basketFeedbackRender,
      currentPathname,
      item,
      isAmendBasket,
      isItemUpdating,
      href,
      showRestOfShelf,
      showHeartIcon,
      excludeMessages,
      showDatesOnlyOnMissedSlot
    } = props;
    const isSubstituteItem = getIsSubstitute(item);
    const isItemInBasket = isInBasket(item);
    const isRestrictedOrderAmendmentProduct = hasRestrictedOrderAmendment(item);
    const rdgRestrictions = getRdgRestrictions(item);
    const timeRestrictedDelivery = getRdgTimeRestriction(rdgRestrictions);
    const dateRestrictedDelivery = getRdgDateRestriction(rdgRestrictions);
    const unavailableRestrictedDelivery = getRdgUnavailableDateRestriction(
      rdgRestrictions
    );

    const isShowingRestrictedMessage =
      isRestrictedOrderAmendmentProduct ||
      isRdgViolated([
        timeRestrictedDelivery,
        dateRestrictedDelivery,
        unavailableRestrictedDelivery
      ]);

    const shouldRenderRemoveIcon = !(
      (isSubstituteItem && !isItemInBasket) ||
      isRestrictedOrderAmendmentProduct ||
      isLeadTimeProductInOrder(dateRestrictedDelivery)
    );

    const title = getProductTitle(item);
    const notSubstituteItemOrInBasket = !isSubstituteItem || isItemInBasket;
    const showProductActions = isInFavourites(item) || shouldRenderRemoveIcon;
    const productId = getProductId(item);
    const showCompletePromoMessage = !excludeMessages.includes('completeOffer');

    return (
      <div
        className="horizontal-tile trolley-tile"
        key="trolley-tile"
        id={`tile-${productId}`}
        data-auto-id={productId}
      >
        <div
          className={classNames('tile-content', {
            'tile-content--has-product-tile-actions': showProductActions
          })}
        >
          <div className="tile-content--upper">
            {
              <ProductTileActions
                item={item}
                showHeartIcon={showHeartIcon}
                removeType={shouldRenderRemoveIcon ? 'trolley' : 'none'}
              />
            }
            <ProductImage
              alt={title}
              hideFromScreenReader={true}
              href={href}
              image={getProductDefaultImageUrl(item)}
              isUnavailable={!available}
              clickable={this.props.productBrowsableOnPDP}
            />
            <div className="product-details--wrapper">
              <div className="product-details--content">
                <ProductTitle
                  id={getProductId(item)}
                  title={title}
                  isPDPBrowsable={isProductBrowsableOnPDP(item)}
                  displayAvailable={available}
                />
                <RenderPromotions
                  isAmendBasket={isAmendBasket}
                  isAlreadyShowingRdgMsg={isShowingRestrictedMessage}
                  item={item}
                  largeViewPromotion={true}
                  showCompletePromoMessage={showCompletePromoMessage}
                  showDatesOnlyOnMissedSlot={showDatesOnlyOnMissedSlot}
                  displayAvailable={available}
                />
                {!available && (
                  <p className="unavailable-product">
                    <span className="unavailable-product-message">
                      {props.t('product-tile:product-unavailable-trolley')}
                    </span>
                  </p>
                )}
                {showRestOfShelf && (
                  <RenderBrowseCategoryLink
                    aisleName={aisleName}
                    aisleId={getProductAisleId(item)}
                    currentPathname={currentPathname}
                    isAvailableOrHasSub={
                      getProductIsForSale(item) || hasSubstitution(item)
                    }
                    restOfShelfUrl={getRestOfShelfUrl(item)}
                    shelfId={getProductShelfId(item)}
                    shelfName={getProductShelfName(item)}
                    tileType={TROLLEY}
                  />
                )}
              </div>
              <RenderInfoMessages
                cssClassName="product-info-message-section"
                item={item}
                hasBackgroundColor={true}
                excludeMessages={excludeMessages}
                isAmendBasket={props.isAmendBasket}
                restrictedOrderAmendmentOverrideMessage={props.t(
                  'trolley:warnings.restricted-order-amendment-message'
                )}
              />
            </div>
            <RenderControls
              isAmendBasket={props.isAmendBasket}
              displayAvailable={available}
              identifier={props.identifier}
              analyticsSettings={this.analyticsSetting}
              {...props.controlProps}
            />
          </div>

          <div className="tile-content--lower">
            <div className="tile-content--lower-right">
              {available && (
                <div className="basket-feedback__wrapper">
                  {isInBasket(item) && basketFeedbackRender()}
                </div>
              )}
              {notSubstituteItemOrInBasket && (
                <div className="product-price-total">
                  <RenderPrice
                    isAvailableEpwOverride={props.isAvailableEpwOverride}
                    isUpdating={isItemUpdating}
                    item={item}
                    showTotalPrice={true}
                    sellableUnitPrice={props.sellableUnitPrice}
                    selectedUnit={props.selectedUnit}
                    displayAvailable={available}
                  />
                </div>
              )}
            </div>
            {notSubstituteItemOrInBasket && (
              <LineItemPreference
                item={item}
                pickerNotesInputLimit={props.c('pickerNotesInputLimit')}
                isAvailable={available}
              />
            )}
            <RenderInfoMessages
              cssClassName="hidden-medium product-info-section-small"
              item={item}
              excludeMessages={excludeMessages}
              isAmendBasket={props.isAmendBasket}
              restrictedOrderAmendmentOverrideMessage={props.t(
                'trolley:warnings.restricted-order-amendment-message'
              )}
            />
          </div>
        </div>
        <RenderPromotions
          isAmendBasket={isAmendBasket}
          item={item}
          displayAvailable={available}
          showCompletePromoMessage={showCompletePromoMessage}
          showDatesOnlyOnMissedSlot={showDatesOnlyOnMissedSlot}
          isAlreadyShowingRdgMsg={isShowingRestrictedMessage}
        />
        <RenderBrowseCategorySuggestion
          item={item}
          displayAvailable={available}
          isAvailableEpwOverride={props.isAvailableEpwOverride}
        />
        <RenderUnavailableBrowseCategoryLink
          item={item}
          displayAvailable={available}
        />
      </div>
    );
  }

  renderUnavailableTile() {
    const {
      isAmendBasket,
      isAvailableEpwOverride,
      isOnDemandDelivery,
      item,
      href,
      userRegistered,
      productBrowsableOnPDP
    } = this.props;

    return (
      <UnavailableTile
        isAvailableEpwOverride={isAvailableEpwOverride}
        item={item}
        href={href}
        userRegistered={userRegistered}
        isAmendBasket={isAmendBasket}
        productBrowsableOnPDP={productBrowsableOnPDP}
        isOnDemandDelivery={isOnDemandDelivery}
      />
    );
  }

  render() {
    const { item, showUnavailableMessage, isAvailableEpwOverride } = this.props;
    const available = getProductIsForSale(item) || isAvailableEpwOverride;
    const showUnavailableTile = showUnavailableMessage && !available;

    return showUnavailableTile
      ? this.renderUnavailableTile()
      : this.renderTrolleyTile(available);
  }
}
