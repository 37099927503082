import { openModal } from '../actions/ui-action-creators';
import { CONNECTION_TIMEOUT } from '../constants/action-types';
import {
  AUTH_REFRESH_REQUIRED_MODAL,
  GENERIC_ERROR_MODAL
} from '../constants/modal-names';

export function openAuthRefreshModal(reduxDispatch) {
  reduxDispatch(openModal(AUTH_REFRESH_REQUIRED_MODAL));
}

export default function(rejValue, reduxDispatch, defaultCaseHandler) {
  const { status, parsedBody } = rejValue;

  if (typeof reduxDispatch !== 'function') {
    reduxDispatch = () => {};
  }

  const redirectTo = parsedBody && parsedBody['redirect-to'];

  switch (status) {
    case 401:
      if (redirectTo) {
        window.location.assign(redirectTo);
      } else {
        openAuthRefreshModal(reduxDispatch);
      }

      break;

    case 419:
      reduxDispatch({ type: GENERIC_ERROR_MODAL });
      break;

    case 503:
      reduxDispatch({ type: CONNECTION_TIMEOUT });
      break;

    default:
      if (typeof defaultCaseHandler === 'function') {
        defaultCaseHandler(status);
      }

      break;
  }
}
