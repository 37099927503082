import React from 'react';
import PropTypes from 'prop-types';
import helpers from '#/lib/decorators/helpers';
import { connect } from '#/lib/render/connect-deep-compare';
import ShowMoreLess from '#/components/show-more-less';
import ItemsCount from './items-count';
import { TOP } from '#/constants/common';
import { getSupportsFlexiPageSize } from '#/reducers/results';

const mapStateToProps = state => ({
  supportsFlexiPageSize: getSupportsFlexiPageSize(state)
});

const CountOrShowMoreLink = ({ c, count, supportsFlexiPageSize }) =>
  c('displayShowMoreLess') && supportsFlexiPageSize ? (
    <ShowMoreLess
      className={'items-count__filter-caption'}
      position={TOP}
      withStatusRole
    />
  ) : (
    <ItemsCount count={count} />
  );

CountOrShowMoreLink.propTypes = {
  c: PropTypes.func.isRequired,
  count: PropTypes.number,
  supportsFlexiPageSize: PropTypes.bool.isRequired
};
export default connect(mapStateToProps)(helpers(['c'])(CountOrShowMoreLink));
