import React from 'react';
import PropTypes from 'prop-types';

// React reuses synthetic events from a pool for performance reasons.
// Therefore to be certain the data is not mutated between touchstart
// and touchmove we copy the properties we care about.
const copyTouches = touches =>
  Array.from(touches, ({ identifier, screenX, screenY }) => ({
    identifier,
    screenX,
    screenY
  }));

class Swiper extends React.Component {
  constructor(props) {
    super(props);
    this.handlers = {
      onSwipeLeft: props.onSwipeLeft,
      onSwipeRight: props.onSwipeRight
    };
    this.resetTouches();
    this.touchStart = this.touchStart.bind(this);
    this.touchMove = this.touchMove.bind(this);
  }

  touchStart(e) {
    this.touches = copyTouches(e.touches);
  }

  touchMove(e) {
    // the user could be touching the screen with several fingers
    // for simplicity, we'll only consider the first
    const movedTouch = e.changedTouches.item(0);
    const startTouch = this.touches.find(
      touch => touch.identifier === movedTouch.identifier
    );

    if (startTouch) {
      const dx = movedTouch.screenX - startTouch.screenX;
      const dy = movedTouch.screenY - startTouch.screenY;

      if (Math.abs(dx) > Math.abs(dy)) {
        if (dx > 0) {
          this.swipe('onSwipeRight');
        } else {
          this.swipe('onSwipeLeft');
        }
      }
    }
  }

  resetTouches() {
    // We don't use this.state as we don't want the component to re-render every
    // time this is mutated
    this.touches = [];
  }

  swipe(handler) {
    this.resetTouches(); // swipes are descrete rather than continuous actions
    this.handlers[handler]();
  }

  render() {
    return (
      <div onTouchStart={this.touchStart} onTouchMove={this.touchMove}>
        {this.props.children}
      </div>
    );
  }
}

Swiper.propTypes = {
  children: PropTypes.node.isRequired,
  onSwipeLeft: PropTypes.func,
  onSwipeRight: PropTypes.func
};

export { Swiper };
