import React from 'react';
import { connect } from '#/lib/render/connect-deep-compare';
import moment from 'moment-timezone';
import Text from '@ddsweb/text';
import helpers from '#/lib/decorators/helpers';
import { getLanguage, getTimezone } from '#/reducers/app';
import FormattedSlotTime from '#/components/shared/formatted-time/formattedSlotTime';
import { TConfigFunc, TTranslateFunc } from '#/lib/records/helpers.defs';
import { COLLECTION, ShoppingMethod } from '#/constants/shopping-methods';
import {
  StyledAddressSlotInfoReskinContainer,
  StyledHeading,
} from '#/experiments/oop-2205/components/address-slot-info-reskin/styled';
import { TCurrency } from '#/custom-typings/redux-store/app.defs';
import Price from '#/components/shared/price';
import { Slot } from '#/lib/records/slot.defs';
import { formatAddress } from '#/experiments/oop-2205/components/helpers';

export type Address = {
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  addressLine4: string;
  addressLine5: string;
  addressLine6: string;
  id: string;
  name: string;
  postcode: string;
};

type TStateProps = {
  language: string;
  timezone: string;
};

export type TOwnProps = TStateProps & {
  t: TTranslateFunc;
  c: TConfigFunc;
  shoppingMethod: ShoppingMethod;
  address: Address;
  slot: Slot;
};

const mapStateToProps = (state: Store): TStateProps => ({
  language: getLanguage(state),
  timezone: getTimezone(state),
});

const AddressSlotInfo: React.FC<TOwnProps> = (props: TOwnProps) => {
  const { shoppingMethod, t: translate, address, c: config, slot, timezone, language } = props;

  const date = moment(slot.start)
    .tz(timezone)
    .locale(language)
    .format('dddd Do MMMM');

  const currency = config('currency') as TCurrency;

  return (
    <StyledAddressSlotInfoReskinContainer shoppingMethod={shoppingMethod}>
      <div className="address-wrapper">
        {address && (
          <div className="address-slot-info--address-block--reskin">
            <StyledHeading id="address-heading" headingLevel="2" visualSize="headline5">
              {shoppingMethod === COLLECTION
                ? translate('slots:oop2205.collection-location')
                : translate('slots:oop2205.delivery-address-heading')}
            </StyledHeading>
            <Text as="p" id="address-text">
              {formatAddress(address, { withName: true })}
            </Text>
          </div>
        )}
        <>
          <StyledHeading id="booked-for-heading" headingLevel="3" visualSize="headline5">
            {translate('slots:oop2205.booked-for')}
          </StyledHeading>
          <Text as={'p'}>
            {`${date}, `}
            <FormattedSlotTime startTime={slot.start} endTime={slot.end} />
          </Text>
        </>
        <>
          <StyledHeading id="charge-heading" headingLevel="3" visualSize="headline5">
            {shoppingMethod === COLLECTION
              ? translate('slots:oop2205.pick-and-pack')
              : translate('slots:oop2205.pick-pack-and-deliver')}
          </StyledHeading>
          <Text as={'p'}>
            <Price {...currency} value={slot.charge} />
          </Text>
        </>
      </div>
    </StyledAddressSlotInfoReskinContainer>
  );
};

export default helpers(['t', 'c'])(connect(mapStateToProps)(AddressSlotInfo));
