import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import CheckboxDDL from '#/components/shared/checkbox-ddl';
import { connect } from '#/lib/render/connect-deep-compare';
import LazyImage from '#/components/image/lazy-image';
import { EventButton } from '../event-button';
import { getIsMobile } from '#/reducers/app';
import helpers from '#/lib/decorators/helpers';
import {
  emitRenderedContentOp,
  emitContentInteractOp
} from '#/analytics/bertie/events';

const mapStateToProps = state => ({
  isMobile: getIsMobile(state)
});
@connect(mapStateToProps)
@helpers(['asset', 't'])
export default class CompetitionBanner extends Component {
  static propTypes = {
    asset: PropTypes.func.isRequired,
    backgrounds: PropTypes.shape({
      mainSrc: PropTypes.string,
      mainColor: PropTypes.string,
      textPanelColor: PropTypes.string
    }),
    brandImage: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string
    }),
    competitionId: PropTypes.string,
    completionText: PropTypes.shape({
      text: PropTypes.string,
      color: PropTypes.string
    }),
    cta: PropTypes.shape({
      text: PropTypes.string,
      textColor: PropTypes.string,
      color: PropTypes.string
    }),
    heading: PropTypes.shape({
      text: PropTypes.string,
      color: PropTypes.string
    }),
    isMobile: PropTypes.bool.isRequired,
    isUserAuthenticated: PropTypes.bool.isRequired,
    loginUrl: PropTypes.string.isRequired,
    packshotImage: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string
    }),
    subHeading: PropTypes.shape({
      text: PropTypes.string,
      color: PropTypes.string
    }),
    t: PropTypes.func.isRequired,
    terms: PropTypes.shape({
      href: PropTypes.string,
      text: PropTypes.string,
      color: PropTypes.string,
      linkText: PropTypes.string
    })
  };

  constructor(props) {
    super(props);

    this.state = {
      checkboxChecked: false,
      checkboxDisabled: false,
      error: false,
      competitionEntered: false
    };

    this.text = {
      errorText: props.t('common:agree-to-terms'),
      accessibilityHeaderText: props.t(
        'common:competition-banner-accessible-header'
      ),
      anonymousLogin: props.t('common:competition-banner-anonymous'),
      signIn: props.t('common:sign-in').toLowerCase()
    };
  }

  componentDidMount() {
    emitRenderedContentOp({
      content: [
        {
          displayArea: 'cpt',
          modulePosition: 1,
          pageType: 'buylist',
          panel: [
            {
              campaignId: this.props.competitionId,
              componentId: null,
              posInModule: 1
            }
          ]
        }
      ]
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.competitionEntered) {
      this.completionText && this.completionText.focus();
    }

    if (prevState.error) {
      this.errorText && this.errorText.focus();
    }
  }

  toggleCheckBox = () => {
    this.setState({
      checkboxChecked: !this.state.checkboxChecked,
      error: false
    });
  };

  handleCtaButton = () => {
    if (this.state.checkboxChecked) {
      emitContentInteractOp({
        campaignId: this.props.competitionId,
        displayArea: 'cpt',
        pageType: 'buylist',
        panel: [
          {
            posInModule: 1
          }
        ],
        modulePosition: 1
      });

      this.setState({
        competitionEntered: true,
        checkboxDisabled: true
      });
    } else {
      this.setState({
        error: true
      });
    }
  };

  renderAction = () => {
    if (!this.props.isUserAuthenticated) {
      return (
        <div
          className="text-panel__login-message"
          style={{ color: this.props.heading.color }}
        >
          <span>
            <span aria-hidden="true">{`${this.text.anonymousLogin} `}</span>
            <a
              href={this.props.loginUrl}
              style={{ color: this.props.heading.color }}
            >
              <span
                aria-label={`${this.text.anonymousLogin} ${this.text.signIn}`}
              >
                {this.text.signIn}
              </span>
            </a>
          </span>
        </div>
      );
    }

    if (this.state.competitionEntered) {
      return (
        <div
          className="text-panel__completed-text"
          style={{ color: this.props.completionText.color }}
        >
          <span
            ref={node => {
              this.completionText = node;
            }}
            role="alert"
          >
            {this.props.completionText.text}
          </span>
        </div>
      );
    }

    return (
      <EventButton
        link={{
          label: this.props.cta.text,
          style: {
            backgroundColor: this.props.cta.color,
            color: this.props.cta.textColor
          }
        }}
        id={1}
        componentClickHandler={this.handleCtaButton}
      />
    );
  };

  render() {
    const props = this.props;
    const {
      backgrounds,
      brandImage,
      packshotImage,
      isMobile,
      heading,
      subHeading,
      terms,
      isUserAuthenticated
    } = props;

    const styles = {
      imagePanelStyles: {
        ...(backgrounds.mainSrc && {
          background: `url('${props.asset(backgrounds.mainSrc)}')`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'left top',
          backgroundSize: 'contain'
        }),
        backgroundColor: backgrounds.mainColor
      },
      textPanelStyles: {
        background: backgrounds.textPanelColor
      },
      termsStyles: {
        color: terms.color
      },
      headingStyles: {
        color: heading.color
      },
      subHeadingStyles: {
        color: subHeading.color
      }
    };

    return (
      <div className="competition-banner-container">
        <h2 className="visually-hidden">{this.text.accessibilityHeaderText}</h2>
        <div className="competition-banner" style={styles.imagePanelStyles}>
          <div className="image-panel__brand-image-wrapper">
            <LazyImage
              className="image-panel__brand-image"
              src={brandImage.src}
              alt={brandImage.alt}
            />
          </div>
          {!isMobile && (
            <div className="image-panel__pack-shot-image-wrapper">
              <LazyImage
                className="image-panel__pack-shot-image"
                src={packshotImage.src}
                alt={packshotImage.alt}
              />
            </div>
          )}
          <div
            className={classnames('text-panel', {
              'logged-out': !isUserAuthenticated
            })}
            style={styles.textPanelStyles}
          >
            <span
              className="text-panel-text text-panel__heading"
              style={styles.headingStyles}
            >
              {heading.text}
            </span>
            {subHeading.text && (
              <span
                className="text-panel-text text-panel__sub-heading"
                style={styles.subHeadingStyles}
              >
                {subHeading.text}
              </span>
            )}
            {isUserAuthenticated && (
              <div className="text-panel-terms-checkbox">
                <CheckboxDDL
                  id="competition-banner-checkbox"
                  checked={this.state.checkboxChecked}
                  disabled={this.state.checkboxDisabled}
                  error={this.state.error}
                  onChange={this.toggleCheckBox}
                >
                  <span style={styles.termsStyles}>
                    {`${terms.text} `}
                    <a
                      data-testid="text-panel-link"
                      rel="noopener noreferrer"
                      href={terms.href}
                      style={styles.termsStyles}
                      target="_blank"
                    >
                      <span>{terms.linkText}</span>
                    </a>
                  </span>
                </CheckboxDDL>
              </div>
            )}
            {this.state.error && (
              <span
                className="error-message"
                ref={node => {
                  this.errorText = node;
                }}
                role="alert"
              >
                {this.text.errorText}
              </span>
            )}
            {this.renderAction()}
          </div>
        </div>
      </div>
    );
  }
}
