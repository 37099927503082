import styled from 'styled-components';
import BeansTabs from '@ddsweb/tabs';
import { spacing, colors, fontSize } from '@ddsweb/selectors';
import { media } from '@ddsweb/foundation';
import Text from '@ddsweb/text';
import Notification from '@ddsweb/notification';
import { GREY_LIGHT_1 } from '@ddsweb/constants';
import TabsMenu, { TabWithSubText } from '@ddsweb/tabs-menu';
import { mediumSmallMin, mediumSmallMax, extraLarge } from '#/constants/viewports';
import { SMALL } from '#/components/product-tiles/temp-style-constants';

export const Container = styled.div`
  position: relative;
  .product-list__section-message {
    display: none;
  }
  .product-list__controls--head,
  .product-list__action-buttons--foot {
    margin-top: 0;
    margin-bottom: ${spacing.lg};
  }

  ${media.belowTablet`
    margin: 0 ${spacing.xs};
    .back-to-top .back-to-top__link {
      justify-content: center;
      float: none;
    }

    .product-list__controls--head {
      margin: ${spacing.sm} 0 ${spacing.xxxl} 0;
    }

    .product-list__action-buttons.product-list__action-buttons--foot{
      margin:${spacing.lg} 0;
    }
  `}

  .product-list__controls--head {
    margin-bottom: ${spacing.lg};
  }

  //TODO: Remove if oop-1720 is not productionized
  &.sticky-action-buttons {
    .product-list__controls--head {
      background: ${GREY_LIGHT_1};
      padding: ${spacing.sm};
      position: sticky;
      top: 0;
      z-index: 2;
      margin-bottom: 0;

      ${media.belowTablet` margin: ${spacing.sm} 0;`}
    }

    &.amend-basket-bar {
      .product-list__controls--head {
        ${media.aboveTablet` top:67px;`}
      }
    }

    .product-list__action-buttons--foot {
      display: none;
    }
  }

  @media ${SMALL} {
    &.sticky-action-buttons {
      .product-list__controls--head {
        transition: top 0.1s;
        top: ${({ headerHeight }: { headerHeight: string }): string => `${headerHeight}px`};
      }
    }
  }

  //TODO: Remove if oop-1720 is not productionized
  .product-list__action-buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: ${spacing.sm};
    .product-list__action-button {
      margin-left: 0;
      margin-bottom: 0;
    }
  }

  //TODO: Remove if oop-1720 is not productionized
  ${media.mobileLargeOnly`
  .product-list__action-buttons {
    flex-direction: column;
  }
`}
`;

export const StyledBeansTabs = styled(BeansTabs)`
  .favorites-tab-header__content-sub-text {
    display: none;
  }
  & .ddsweb-tabs-menu__container {
    margin-left: 7px;
    @media (min-width: ${mediumSmallMin}) {
      margin-left: 18px;
    }
    @media (min-width: ${extraLarge}) {
      margin-left: ${spacing.xl};
    }
    .ddsweb-tabs-menu__list-container {
      margin-bottom: ${spacing.xx};
      z-index: 1;
      display: block;
      border-bottom: none;
    }
  }
`;

export const StyledTabsMenu = styled(TabsMenu)`
  .ddsweb-tabs-menu__menu-item {
    border-color: ${colors.disabled};
    @media (max-width: ${mediumSmallMax}) {
      width: 196px;
      .ddsweb-tabs-menu__tab-link {
        &#favourites {
          margin: 0;
        }
        &#usuals {
          margin: 0 ${spacing.xs};
        }
      }
    }
  }
`;

export const StyledTabWithSubText = styled(TabWithSubText)`
  .ddsweb-tabs-menu__tab-label-subtext {
    font-size: ${fontSize.xxx};
    white-space: normal;
  }
  @media (max-width: ${mediumSmallMax}) {
    .ddsweb-tabs-menu__tab-label {
      padding-left: 0;
      padding-right: 0;
    }
  }
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  ${media.belowTablet`
  justify-content: center;
  button.ddsweb-button__container{
    width: 100%;
  }
`}
`;

export const StyledBodyText = styled(Text)`
  font-weight: 600;
  color: ${colors.base};
`;

export const StyledNotification = styled(Notification)`
  margin-bottom: ${spacing.sm};
`;
StyledNotification.displayName = 'StyledNotification';

export const StyledOnDemandNotificationBanner = styled(Notification)`
  ${media.belowTablet` margin: ${spacing.sm};`}
`;
