import { TROLLEY_CONTENTS, USUALS } from '#/constants/spa-resource';
import { getUsualsResource } from '#/selectors/resources';
import { receiveFavoritesData } from '#/actions/ui-action-creators';
import { updateResults } from '#/actions/results-actions';
import {
  updateCurrentUrl,
  updateFromNewResults
} from '#/actions/filter-action-creators';
import { addResultsAPMData } from '#/utils/favorite-utils';

export const type = USUALS;
export const params = ['query'];
export const dependencies = [TROLLEY_CONTENTS];

export function handler(dispatch, getState, options = {}) {
  const state = getState();
  const { data } = getUsualsResource(state);

  if (!data || !data?.updateResults) {
    return;
  }

  const { results, selectedFavoritesTab } = data;

  if (selectedFavoritesTab) {
    dispatch(receiveFavoritesData(selectedFavoritesTab));
  }

  if (results && results.productItems) {
    dispatch(updateResults(results, type, false));

    if (options.updateCurrentURL) {
      dispatch(updateCurrentUrl(options.updateCurrentURL));
      dispatch(updateFromNewResults(results, options.updateCurrentURL));
    }
  }

  addResultsAPMData(data, 'usuals');
}
