import {
  AMEND_COUPONS_JOURNEY,
  AMEND_PAYMENT_JOURNEY
} from '../constants/journey-names';
import {
  AMEND_COUPONS_STICKY_BAR,
  AMEND_PAYMENT_STICKY_BAR,
  DEFAULT_AMEND_STICKY_BAR
} from '../constants/sticky-bar-names';

// TODO: Removing this unused import is somehow breaking tests due to circular module references in the slots reducers and selectors. Fixing this would be too big of a refactor for now.
import { slotExpiresWithinThreshold } from '#/lib/slot/slot-utils'; // eslint-disable-line @typescript-eslint/no-unused-vars

export function chooseStickyBarName({
  isAmendBasket,
  journey,
  displayCookieInterrupt
}) {
  if (isAmendBasket && journey) {
    switch (journey) {
      case AMEND_COUPONS_JOURNEY:
        return AMEND_COUPONS_STICKY_BAR;
      case AMEND_PAYMENT_JOURNEY:
        return AMEND_PAYMENT_STICKY_BAR;
      default:
        return DEFAULT_AMEND_STICKY_BAR;
    }
  } else if (isAmendBasket || displayCookieInterrupt) {
    return DEFAULT_AMEND_STICKY_BAR;
  }

  return null;
}
