import { createItem } from '#/lib/records';
import { cloneItem } from '#/utils/clone-item';
import { getGtin13FromImageUrl } from '#/lib/records/product-utils';
import { getProductSubstitutions } from '#/selectors/item';
import { Item } from '#/lib/records/item';
import { Product } from '#/lib/records/item.defs';

type SeedProduct = {
  id: string | undefined;
  gtin13: string | null;
  name: string | undefined;
};

const createSeedProduct = (product: Product, item: Item): SeedProduct => {
  return {
    id: product.baseProductId || product.id || item.id,
    gtin13: getGtin13FromImageUrl(product.defaultImageUrl) || getGtin13FromImageUrl(item.defaultImageUrl),
    name: product.title || item.title,
  };
};

/*
  This helper migrates logic from ./items-to-map without actually converting
  items into a Map. It contains logic related handling of promotions, subs, etc.
  At the end of remove-immutable epic original items-to-map is to be removed.
 */
export default function transformItems(items: Array<Item> = []): Array<Item> {
  if (items.length === 0 || ('product' in items[0] && items[0].catchWeight)) {
    return items;
  }

  return items.map((item: Item) => {
    const updatedItem = cloneItem(item);
    const product = updatedItem.product;
    const subs = getProductSubstitutions(updatedItem);

    product.substitutions = subs.map(subItem => {
      const seedProduct = createSeedProduct(product, item);
      const updatedSubItem = cloneItem({ ...subItem, isSubstitute: true, seedProduct });

      return createItem(updatedSubItem);
    });

    return createItem(updatedItem);
  });
}
