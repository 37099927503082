import React from 'react';
import PropTypes from 'prop-types';
import { connect } from '#/lib/render/connect-deep-compare';
import Modal from '#/components/shared/modal';
import SafeForm from '#/components/shared/safe-form';
import ModalActions from '#/components/shared/modal/actions';
import ModalContent from '#/components/shared/modal/content';
import { closeModal } from '#/actions/ui-action-creators';
import helpers from '#/lib/decorators/helpers';
import { getCurrentUrl } from '#/reducers/app';
import analyticsBus from '#/analytics/analyticsBus';
import { basicEvent } from '#/analytics/types/basic';
import { getAmendOrderId, getAmendOrderNo } from '#/selectors/trolley';

const mapStateToProps = state => ({
  amendOrderNo: getAmendOrderNo(state),
  amendOrderId: getAmendOrderId(state),
  currentUrl: getCurrentUrl(state)
});
@connect(mapStateToProps, { closeModal })
@helpers(['l', 't'])
export default class ExpiredModal extends React.Component {
  static propTypes = {
    amendOrderId: PropTypes.string.isRequired,
    amendOrderNo: PropTypes.string.isRequired,
    closeModal: PropTypes.func.isRequired,
    currentUrl: PropTypes.string.isRequired,
    l: PropTypes.func.isRequired,
    shoppingMethod: PropTypes.string,
    t: PropTypes.func.isRequired
  };

  UNSAFE_componentWillMount() {
    basicEvent(analyticsBus, {
      action: 'now',
      type: 'functional',
      value: 'amend expire',
      orderID: this.props.amendOrderId
    });
  }

  render() {
    const {
      amendOrderNo,
      closeModal,
      currentUrl,
      l: language,
      shoppingMethod,
      t: translate
    } = this.props;

    return (
      <Modal
        title={translate('orders:amend.time-expired')}
        closeModal={closeModal}
        showLink={false}
      >
        <ModalContent>
          <p>
            {translate(`orders:amend.${shoppingMethod}.amend-mode-expired`)}
          </p>
          <ModalActions>
            <SafeForm
              method="POST"
              action={language('/orders/amend?_method=DELETE')}
            >
              <input type="hidden" name="returnUrl" value={currentUrl} />
              <input type="hidden" name="orderNo" value={amendOrderNo} />
              <button
                className="js-expired-changes-modal-ok button button-primary small"
                type="submit"
              >
                {translate('ok')}
              </button>
            </SafeForm>
          </ModalActions>
        </ModalContent>
      </Modal>
    );
  }
}
