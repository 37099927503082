import { isProductBrowsableOnPDP, getProductTitle, getProductId } from '#/selectors/item';
import { updateParamsInUrl } from '#/lib/url/url-utils';
import { getCurrentPath, getLanguageLink } from '#/reducers/app';

import { TTranslateFunc, TConfigFunc } from '#/lib/records/helpers.defs';
import { Item } from '#/lib/records/item';

export type TWriteARewiewData = {
  reviewSubmissionUrl: string;
  ariaLabel: string;
  reviewLinkText: string;
} | null;

export const getWriteAReviewData = (
  state: Store,
  item: Item,
  feature: TConfigFunc,
  translate: TTranslateFunc,
  shouldShowWriteAReview = true,
): TWriteARewiewData => {
  const showWriteAReview =
    shouldShowWriteAReview && feature('enableRatingsReviewsSubmission') && isProductBrowsableOnPDP(item);

  if (!showWriteAReview) return null;

  const productId = getProductId(item);
  const productTitle = getProductTitle(item);
  const currentPath = getCurrentPath(state);
  const reviewSubUrl = updateParamsInUrl(`/reviews/submission/${productId}`, {
    from: currentPath,
  });
  const reviewSubmissionUrl = getLanguageLink(state, reviewSubUrl);
  const ariaLabel = `${translate('ratings-reviews:write-a-review-for')} ${productTitle}`;
  const reviewLinkText = translate('ratings-reviews:write-a-review');

  return { reviewSubmissionUrl, ariaLabel, reviewLinkText };
};
