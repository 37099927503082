import {
  FETCHING_INSTRUCTIONS,
  INSTRUCTIONS_CHANGED,
  INSTRUCTIONS_ERROR,
  INSTRUCTIONS_SAVED,
  INSTRUCTIONS_SAVING,
  SET_INSTRUCTIONS,
} from '../constants/action-types';
import { getOldInstructions } from '../reducers/instructions';
import { request } from '../lib/client-fetch';
import { Dispatch } from '#/custom-typings/redux-store/common';

type SaveInstructions = {
  dispatch: Dispatch;
  getState: Function;
  instructions: string;
  returnUrl: string;
  saveUrl: string;
  userClick: boolean;
};

type ApiCallSaveInstructions = {
  dispatch: Dispatch;
  instructions: string;
  saveUrl: string;
  requestBody: { [key: string]: string | undefined };
};

let debouncer: null | ReturnType<typeof setTimeout> = null;

const saveInstructions = ({
  dispatch,
  getState,
  instructions,
  returnUrl,
  saveUrl,
  userClick,
}: SaveInstructions): void => {
  if (debouncer) {
    clearTimeout(debouncer);
  }

  const state = getState();
  const oldInstructions = getOldInstructions(state);

  const requestBody = {
    newValue: instructions,
    oldValue: oldInstructions,
    returnUrl,
  };

  if (userClick) {
    makeSaveInstructionsApiCall({
      dispatch,
      saveUrl,
      instructions,
      requestBody,
    });
  } else {
    debouncer = setTimeout(
      () =>
        makeSaveInstructionsApiCall({
          dispatch,
          saveUrl,
          instructions,
          requestBody,
        }),
      2500,
    );
  }
};

const makeSaveInstructionsApiCall = async ({
  dispatch,
  saveUrl,
  instructions,
  requestBody,
}: ApiCallSaveInstructions): Promise<void> => {
  dispatch({ type: INSTRUCTIONS_SAVING });
  try {
    await request.put(saveUrl, { body: JSON.stringify(requestBody) });
    dispatch({
      type: INSTRUCTIONS_SAVED,
      oldInstructions: instructions,
    });
  } catch {
    dispatch({
      type: INSTRUCTIONS_ERROR,
    });
  }
};

export const instructionsError = (): {
  type: string;
} => {
  return {
    type: INSTRUCTIONS_ERROR,
  };
};

type InstructionsChanged = {
  instructions: string;
  returnUrl: string;
  saveMethod?: Function;
  saveUrl: string;
  userClick?: boolean;
};

export const instructionsChanged = ({
  instructions,
  returnUrl,
  saveMethod,
  saveUrl,
  userClick,
}: InstructionsChanged): { (dispatch: Dispatch, getSate: Function): void } => {
  if (debouncer) {
    clearTimeout(debouncer);
  }

  return (dispatch, getState): void => {
    const save = saveMethod || saveInstructions;

    save({ dispatch, getState, saveUrl, instructions, returnUrl, userClick });

    if (!userClick) {
      dispatch({
        type: INSTRUCTIONS_CHANGED,
        instructions,
      });
    }
  };
};

export const refreshInstructions = ({
  instructionsUrl,
}: {
  instructionsUrl: string;
}): ((dispatch: Dispatch) => void) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: FETCHING_INSTRUCTIONS,
    });

    request.get(instructionsUrl).then(response => {
      dispatch({
        type: SET_INSTRUCTIONS,
        instructions: response.value,
      });
    });
  };
};
