export const parseCookieString = str => {
  const cookies = str.split(';');

  return (cookies || []).reduce((cookiesObj, cookie) => {
    const [key, value] = cookie.split('=');

    cookiesObj[key.trim()] = value && value.trim();

    return cookiesObj;
  }, {});
};
