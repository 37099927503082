import { SUBSCRIPTIONS } from '#/constants/spa-resource';
import { updateSubscriptions } from '#/actions/update-subscriptions';
import { getSubscriptionsdResource } from '#/selectors/resources';

export const type = SUBSCRIPTIONS;

export const params = [];

export const cacheable = true;

export const dependencies = [];

export function handler(dispatch: Function, getState: Function): void {
  const subscriptionsData = getSubscriptionsdResource(getState())?.data;

  if (!subscriptionsData) {
    return;
  }
  dispatch(updateSubscriptions(subscriptionsData));
}
