//	Feature for allowing customers to select a product from mutliple subs
export const basketMultiProductSwaps = {
  key: '2080',
  featureKey: 'ghs-all_basket-multi-product-swaps',
  variationKey: 'experience',
  variants: {
    default: 'default',
    multiSwapsUserInitiated: 'multi-swaps-initiated',
    multiSwapsDefaultPanel: 'multi-swaps-default',
  },
  useCustomerId: true,
};

export const MULTI_SUBS_TYPE = 'multi subs';
export const FIND_SWAP = 'find a swap';
//Analytics
export const MULTI_SUBS_COMPONENT = 'multiSubs';
export const CAROUSEL_DISPLAY_AREA = 'csl';
export const COMPONENT_TYPE = 's';
export const CAROUSEL_MULTI_SUBS = 'carousel:multi subs';
export const CAROUSEL_SEED_LOOKUP = 'sub:trex';
export const FULL_BASKET_PAGE_TYPE = 'tf';
