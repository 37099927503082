import React from 'react';
import PropTypes from 'prop-types';
import helpers from '../../lib/decorators/helpers';
import SafelyOutputString from '#/components/safely-output-string';

const Count = ({ max, min, t, total }) => {
  const transKey =
    min === 1 && max === total ? 'search:all-results' : 'search:results-count';

  // ensure the upper bound is not larger than the total
  max = total > max ? max : total;

  return (
    <p className="pagination__results-count">
      <SafelyOutputString>
        {t(transKey, { max, min, total })}
      </SafelyOutputString>
    </p>
  );
};

Count.propTypes = {
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired
};

export default helpers(['t'])(Count);
