import { AccountPageState } from '#/custom-typings/redux-store/account-page.defs';
import { UserDetails } from '#/custom-typings/redux-store/user.defs';

export interface TAccountSettings {
  addressMetadata?: Record<string, unknown> | null;
  clubcard?: {
    errors: string[];
    clubcardNumber: string;
  };
  contactPreferences?: {
    updated?: boolean;
    allowContact?: boolean;
  };
  editingSection?: string | null;
  email?: string;
  pendingPersonalDetails?: UserDetails;
  personalDetailsValidationError?: boolean;
}

export interface TAccountPageProps {
  accountSettings?: TAccountSettings;
}

export default (state: AccountPageState = {}): AccountPageState => state;

export const getDefaultStateFromProps = ({ accountSettings }: TAccountPageProps): AccountPageState => {
  if (!accountSettings) {
    return {};
  }

  return {
    editingSection: accountSettings.editingSection || null,
  };
};

export const getAccountEditingSection = ({ accountPage = {} }: { accountPage?: AccountPageState }): string | null =>
  accountPage.editingSection || null;
