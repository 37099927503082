import { GREY } from '#/constants/colors';
import { TTranslateFunc } from '#/lib/records/helpers.defs';
import { Item } from '#/lib/records/item';
import { TFlashSashInfo } from '#/selectors/beans-product-tile/promotions-data';
import { promoTypeSubstitutions } from '#/experiments/oop-2011/constants';

export type GetFlashSashInfo = {
  translate: TTranslateFunc;
  item: Item;
  isInFavoritesGrid: boolean;
};

export const getUpdatedSashListWithProbations = ({
  item,
  translate,
  isInFavoritesGrid = false,
}: GetFlashSashInfo): TFlashSashInfo[] => {
  const sashList: TFlashSashInfo[] = [];

  if (isInFavoritesGrid && item.isSubstitute) {
    sashList.push({
      text: translate('product-tile:substitute'),
      color: GREY,
      type: promoTypeSubstitutions.substitutions,
    });
  }

  return sashList;
};
