import { colors } from '@beans/selectors';
import styled from 'styled-components';

export const StyledSlotListHeader = styled('div')`
  && {
    @media (max-width: 599px) {
      display: none;
    }
    @media (min-width: 600px) {
      background-color: ${colors.white};
      position: fixed;
      top: 0;
      z-index: 12;

      &.animation {
        transition: opacity 0.1s ease;

        &.slideOut {
          opacity: 0;
        }

        &.slideIn {
          opacity: 1;
        }
      }
    }
  }
`;
