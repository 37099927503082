/**
 * Experiment constants for removing Weight Toggle
 */

export const productTileWeightToggleVariants = {
  featureKey: 'GHS-ALL_product-tile-testing',
  variationKey: 'productTile_config',
  variants: {
    default: 'tile-3',
    hideWeightToggle: 'tile-4',
  },
};
