import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

/**
 * Represents the header of a TabItem.
 *
 * If a `className` is passed in then this is applied to the surrounding <li>.
 */
const TabHeader = ({
  children,
  className,
  showTabHeaderStyles,
  isActive,
  shouldDisplayOop761TabheaderVariation
}) => {
  const isTabHeaderStyles =
    (showTabHeaderStyles ?? true) && !shouldDisplayOop761TabheaderVariation;
  const isNoIconTabHeader =
    (!showTabHeaderStyles ?? false) && !shouldDisplayOop761TabheaderVariation;
  const classes = {
    tabheader: isTabHeaderStyles,
    noicontabheader: isNoIconTabHeader,
    active: isActive,
    [className]: true
  };

  const childrenWithProps = React.Children.map(children, child => {
    const cloneProps = {
      role: 'tab',
      ['aria-selected']: isActive
    };

    if (child.type !== 'div') {
      cloneProps.isActive = isActive;
    }

    return React.cloneElement(child, cloneProps);
  });

  return (
    <li role="presentation" className={classnames(classes)}>
      {childrenWithProps}
    </li>
  );
};

TabHeader.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isActive: PropTypes.bool,
  shouldDisplayOop761TabheaderVariation: PropTypes.bool
};

TabHeader.defaultProps = {
  className: '',
  isActive: false,
  shouldDisplayOop761TabheaderVariation: false
};

export default TabHeader;
