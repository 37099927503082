import { createItem } from '#/lib/records';
import { Price, Product } from '#/lib/records/item.defs';
import { Item } from '#/lib/records/item';

export default function transformPromotedOfferProducts(products: Array<Product> = []): Array<Item> {
  return (products.map(item => {
    const price = item.price as Price;

    const product = {
      ...item,
      isForSale: true,
      price: price?.value || item.value || price,
      promotions: (item.promotions || []).map(promotion => {
        const { offerText, description, promotionId, id, ...rest } = promotion;

        return {
          ...rest,
          offerText: offerText || description,
          promotionId: promotionId || id,
        };
      }),
    };

    if (product.product) {
      Object.assign(product, product.product);
    }

    return createItem({ product } as Item);
  }) as unknown) as Array<Item>;
}
