import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from '#/lib/render/connect-deep-compare';
import classNames from 'classnames';
import Price from '#/components/shared/price';
import GuidePriceContent from '#/components/headers/header/header-guide-price/guide-price-content';
import helpers from '#/lib/decorators/helpers';
import {
  getTrolleyRequestInProgress,
  getGuidePrice,
  getShowPendingQty,
  getTrolleyStateAndPendingQty,
  getUpdatingCatchWeightItem,
  getHasOnlyMarketplaceItems,
  isGroceryOnlySubBasket
} from '#/selectors/trolley';
import { getCurrency } from '#/reducers/app';
import { getIsUserAuthenticated } from '#/reducers/user';
import { getQuantity } from '#/selectors/item';
import GuidePriceContentV2 from './guide-price-content-v2';

const mapStateToProps = (state, { c: config }) => ({
  currency: getCurrency(state),
  displayAsGuidePrice: config('displayAsGuidePrice'),
  guidePrice: getGuidePrice(state),
  isUserAuthenticated: getIsUserAuthenticated(state),
  isTrolleyLoading: getTrolleyRequestInProgress(state),
  pendingQty: getTrolleyStateAndPendingQty(state).pendingQty,
  showPendingQty: getShowPendingQty(state),
  updatingCatchWeightItem: getUpdatingCatchWeightItem(state),
  isMarketplaceOnlySubBasket: getHasOnlyMarketplaceItems(state),
  isGroceryOnlySubBasket: isGroceryOnlySubBasket(state),
  isGlobalHeader: config('isGlobalHeader')
});

@helpers(['t', 'c'])
@connect(mapStateToProps)
export default class HeaderGuidePrice extends PureComponent {
  static propTypes = {
    currency: PropTypes.object.isRequired,
    displayAsGuidePrice: PropTypes.bool.isRequired,
    guidePrice: PropTypes.number,
    isTrolleyLoading: PropTypes.bool,
    isUserAuthenticated: PropTypes.bool.isRequired,
    pendingQty: PropTypes.number,
    showPendingQty: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    updatingCatchWeightItem: PropTypes.object,
    isMarketplaceOnlySubBasket: PropTypes.bool,
    isGroceryOnlySubBasket: PropTypes.bool,
    isTrolleyEmpty: PropTypes.bool
  };

  getPriceTitle = (appendedString = '') => {
    const {
      t,
      displayAsGuidePrice,
      isGroceryOnlySubBasket,
      isMarketplaceOnlySubBasket,
      guidePrice
    } = this.props;
    let priceTitle;
    if (displayAsGuidePrice) {
      if (isMarketplaceOnlySubBasket)
        priceTitle = t('common:guide-price-mp' + appendedString);
      else if (isGroceryOnlySubBasket || guidePrice === 0)
        priceTitle = t('common:guide-price' + appendedString);
      else priceTitle = t('common:guide-price-mixed-basket' + appendedString);
    } else priceTitle = t('common:price' + appendedString);
    return priceTitle;
  };

  render() {
    const { isGlobalHeader, isUserAuthenticated } = this.props;
    let updateLabel;
    let quantityLabel;
    const guidePriceLabel = (
      <Fragment>
        <span className="visually-hidden">{this.getPriceTitle('-long')}</span>
        <span aria-hidden="true">{this.getPriceTitle()}</span>
      </Fragment>
    );

    if (this.props.updatingCatchWeightItem !== null) {
      updateLabel = this.props.t('trolley:updating-trolley');
      quantityLabel = getQuantity(this.props.updatingCatchWeightItem);
    } else {
      updateLabel =
        this.props.pendingQty >= 0
          ? this.props.t('trolley:adding-to-trolley')
          : this.props.t('trolley:removing-to-trolley');
      quantityLabel = this.props.showPendingQty
        ? Math.max(1, Math.abs(this.props.pendingQty))
        : '';
    }

    return (
      <div
        className={classNames('header-guide-price', {
          'is-updating': this.props.isTrolleyLoading
        })}
        data-product-count={this.props.pendingQty}
      >
        {isGlobalHeader ? (
          isUserAuthenticated && (
            <GuidePriceContentV2
              className="main-container"
              mainLabel={
                <Price
                  {...Object.assign(
                    {
                      className: 'amount',
                      value: this.props.guidePrice,
                      enableMaxLimit: true
                    },
                    this.props.currency
                  )}
                />
              }
              subLabel={guidePriceLabel}
            />
          )
        ) : (
          <>
            <GuidePriceContent
              className="main-container"
              hiddenText={this.props.t('common:pages.trolley')}
              iconClass="icon-cart"
              isAuthenticated={this.props.isUserAuthenticated}
              mainLabel={
                <Price
                  {...Object.assign(
                    {
                      className: 'amount',
                      value: this.props.guidePrice,
                      enableMaxLimit: true
                    },
                    this.props.currency
                  )}
                />
              }
              subLabel={guidePriceLabel}
            />
            <GuidePriceContent
              className="status-container"
              iconClass="header-guide-price--spinner"
              isAuthenticated={this.props.isUserAuthenticated}
              mainLabel={quantityLabel}
              subLabel={updateLabel}
            />
          </>
        )}
      </div>
    );
  }
}
