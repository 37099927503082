import styled from 'styled-components';
import TextareaGroup from '@ddsweb/textarea-group';
import Button from '@ddsweb/button';
import { colors, spacing } from '@ddsweb/selectors';

export const StyledTextAreaFeedback = styled(TextareaGroup)`
  & textarea {
    height: auto;
    white-space: nowrap;
    margin-top: ${spacing.sm};
  }
  .ddsweb-form-group__label.beans-label {
    font-weight: normal;
  }
`;
StyledTextAreaFeedback.displayName = 'StyledTextAreaFeedback';

export const StyledSubmitButton = styled(Button)`
  & {
    margin-top: ${spacing.sm};
    width: auto;
  }
  &:disabled {
    background-color: ${colors.primary};
    color: ${colors.white};
  }
`;
StyledSubmitButton.displayName = 'StyledSubmitButton';

export const TextFeedbackWrapper = styled.div`
  & {
    margin-top: ${spacing.lg};
    padding: ${spacing.sm};
    background-color: ${colors.backgroundDark};
  }
`;
TextFeedbackWrapper.displayName = 'TextFeedbackWrapper';
