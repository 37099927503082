import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import helpers from '../../lib/decorators/helpers';
import analyticsBus from '../../analytics/analyticsBus';
import { basicEvent } from '../../analytics/types/basic';
import DisplayOnlyInJs from '../shared/display-only-in-js';
import { getCutsMustard, getDeviceType, getTraceId } from '#/reducers/app';
import { getExperienceCookieAccepted } from '#/reducers/ui';
import {
  getActiveExperimentsFromWindow,
  enableFeedback
} from '#/lib/open-feedback-window';
import {
  getUserStoreId,
  getIsFirstTimeShopper,
  getUserHashedUId
} from '#/reducers/user';
import { connect } from '#/lib/render/connect-deep-compare';

const mapStateToProps = state => ({
  cutsMustard: getCutsMustard(state),
  deviceType: getDeviceType(state),
  isFirstTimeShopper: getIsFirstTimeShopper(state),
  hashedUId: getUserHashedUId(state),
  storeId: getUserStoreId(state),
  traceId: getTraceId(state),
  isExperienceCookieAccepted: getExperienceCookieAccepted(state)
});

@helpers(['t', 'c', 'f'])
@connect(mapStateToProps)
export default class SearchFeedback extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      positiveFeedback: false
    };
  }

  static propTypes = {
    cutsMustard: PropTypes.bool,
    deviceType: PropTypes.string,
    hashedUId: PropTypes.string.isRequired,
    isEmptyResult: PropTypes.bool,
    isFirstTimeShopper: PropTypes.bool,
    storeId: PropTypes.string,
    traceId: PropTypes.string.isRequired,
    isExperienceCookieAccepted: PropTypes.bool,
    t: PropTypes.func.isRequired,
    c: PropTypes.func.isRequired,
    f: PropTypes.func.isRequired
  };

  static defaultProps = {
    isEmptyResult: false
  };

  handleClick(event) {
    const feedbackButtonValue = event.target.value;

    if (feedbackButtonValue === 'yes') {
      this.displayThankYou();
    } else {
      this.displayOpinionLabFeedback();
    }

    basicEvent(analyticsBus, {
      type: 'feedback:opinionlab',
      value: 'search successful:' + feedbackButtonValue,
      action: 'now'
    });
  }

  displayThankYou() {
    this.setState({ positiveFeedback: true });
  }

  displayOpinionLabFeedback() {
    const {
      cutsMustard,
      isFirstTimeShopper,
      deviceType,
      storeId,
      hashedUId,
      traceId
    } = this.props;
    const customerVariables = {
      cutsMustard: String(cutsMustard),
      deviceType: deviceType,
      isFirsttimeShopper: String(isFirstTimeShopper),
      storeId: storeId,
      ghsUUID: hashedUId,
      ghs_customerID: hashedUId,
      traceId
    };

    if (window?.OOo?.oo_launchFeedbackSearch) {
      window.OOo.oo_launchFeedbackSearch({
        ...customerVariables,
        ...getActiveExperimentsFromWindow()
      });
    }
  }

  getFeedbackButton() {
    const yesText = this.props.t('yes');
    const noText = this.props.t('no');

    return this.props.isEmptyResult ? (
      <button
        className="provide-feedback button-secondary"
        onClick={this.handleClick}
        value="no"
      >
        {this.props.t('search:provide-feedback')}
      </button>
    ) : (
      <div>
        <button
          className="feedback-positive button-secondary"
          onClick={this.handleClick}
          value="yes"
        >
          {yesText}
        </button>
        <button
          className="feedback-negative button-secondary"
          onClick={this.handleClick}
          value="no"
        >
          {noText}
        </button>
      </div>
    );
  }

  render() {
    const { c: config, f: features, isExperienceCookieAccepted } = this.props;

    if (
      !enableFeedback(
        config,
        features('feedbackLinks'),
        isExperienceCookieAccepted
      )
    ) {
      return null;
    }

    const emptySearchFeedbackMessage = this.props.t(
      'search:no-results.how-can-we-improve-feedback-message'
    );

    const nonEmptySearchFeedbackMessage = this.props.t(
      'search:did-you-find-feedback-message'
    );
    const feedbackQuery = this.props.isEmptyResult
      ? emptySearchFeedbackMessage
      : nonEmptySearchFeedbackMessage;
    const feedbackClassnames = classnames('feedback-container', {
      'feedback-thankyou-container': this.state.positiveFeedback,
      'feedback-container-emptyPage': this.props.isEmptyResult
    });

    return (
      <DisplayOnlyInJs>
        <div className={feedbackClassnames}>
          {!this.state.positiveFeedback ? (
            <div>
              <span className="feedback-container-message">
                {feedbackQuery}
              </span>
              <div className="feedback-container-button">
                {this.getFeedbackButton()}
              </div>
            </div>
          ) : (
            <div className="feedback-thankyou-message">
              {this.props.t('search:thank-you-for-your-feedback')}
            </div>
          )}
        </div>
      </DisplayOnlyInJs>
    );
  }
}
