import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { logApmError } from '#/lib/apm';
import ErrorBoundaryModal from '../modals/error-boundary-modal';

export default class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired
  };

  constructor() {
    super();

    this.state = {
      errors: [],
      hasError: false
    };
  }

  componentDidCatch(error, info) {
    this.setState({
      errors: [...this.state.errors, { error, info }],
      hasError: true
    });

    if (window) {
      const formattedError = new Error(`React Boundary Error:
                  ${error.stack}
                  componentStack:
                  ${info.componentStack}`);

      logApmError(formattedError);
    }

    // eslint-disable-next-line no-console
    console.error(error);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorBoundaryModal />;
    }

    return this.props.children;
  }
}
