import {
  CONTENT_TYPE_AUTOPLAY_VIDEO,
  CONTENT_TYPE_VIDEO,
  CONTENT_TYPE_STATIC,
  UI_EVENT_BASIC_EVENT,
  CONTENT_INTERACT_OP
} from '../constants';

// Content type for UIContentRender and UIContentClick events: STATIC, AUTOPLAY_VIDEO or VIDEO
export const getContentEventType = componentData => {
  if (componentData.video) {
    return componentData.video.autoPlay
      ? CONTENT_TYPE_AUTOPLAY_VIDEO
      : CONTENT_TYPE_VIDEO;
  }

  return CONTENT_TYPE_STATIC;
};

export const getEventType = data => {
  return data?.catId === 'EventButton'
    ? UI_EVENT_BASIC_EVENT
    : CONTENT_INTERACT_OP;
};

export const getContentTitle = data => {
  return data?.title || data?.link?.label;
};
