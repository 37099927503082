import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { compose } from 'redux';
import helpers from '#/lib/decorators/helpers';
import { connect } from '#/lib/render/connect-deep-compare';
import { getTrolleyShoppingMethod } from '#/selectors/trolley';
import { getLanguageLink } from '#/reducers/app';
import { changeShoppingMethod } from '#/actions/slot-action-creators';
import {
  isClickAndCollectShoppingMethod,
  isDeliveryShoppingMethod,
  isOnDemandShoppingMethod
} from '#/lib/shopping-method-util';
import { SHOPPING_METHOD_PROP_TYPE } from '#/components/slots/prop-types';
import ClickableTab from '#/components/slots/option-tabs/header/clickable-tab';
import DisabledTab from '#/components/slots/option-tabs/header/disabled-tab';
import { ON_DEMAND } from '#/constants/shopping-methods';

const OptionTabsHeader = ({
  shoppingMethod,
  showIcons,
  isEnabled,
  isActive,
  currentShoppingMethod,
  changeShoppingMethod,
  slotsTypeUrl,
  t: translate,
  onKeyPressed,
  shouldDisplayOop761TabheaderVariation
}) => {
  const showHeaderDesc = !showIcons && !shouldDisplayOop761TabheaderVariation;

  const automationAttr = isActive
    ? 'option-tabs-text-active'
    : 'option-tabs-text-inactive';

  const linkStyles = classNames(
    `option-tabs__tabheader-link ${shoppingMethod}`,
    {
      'tabheader-link--text': showIcons,
      'noicontabheader-link--text': !showIcons,
      disabled: !isEnabled
    }
  );

  const titleStyles = classNames({
    [`option-tabs__tabheader-link-title ${shoppingMethod}`]: !shouldDisplayOop761TabheaderVariation,
    disabled: !isEnabled
  });

  const displayVanIcon = isDeliveryShoppingMethod(shoppingMethod);

  const iconStyles = classNames({
    [`option-tabs__tabheader-link-icon ${shoppingMethod}`]: showIcons,
    'icon-van': isEnabled && displayVanIcon,
    'icon-van-disabled': !isEnabled && displayVanIcon,
    'icon-store': isEnabled && isClickAndCollectShoppingMethod(shoppingMethod),
    'icon-store-disabled':
      !isEnabled && isClickAndCollectShoppingMethod(shoppingMethod)
  });

  const changeShoppingMethodHandler = () => {
    changeShoppingMethod(shoppingMethod, currentShoppingMethod);
  };

  const tabHeaderText = isOnDemandShoppingMethod(shoppingMethod)
    ? translate(`common:shopping-method_${shoppingMethod}-tab-header`)
    : translate(`common:shopping-method_${shoppingMethod}`);

  const tabContent = (
    <div>
      {!shouldDisplayOop761TabheaderVariation && (
        <span className={iconStyles} />
      )}
      <span className={titleStyles} data-auto={automationAttr}>
        {tabHeaderText}
      </span>
    </div>
  );

  const tabHeaderDescription =
    shoppingMethod === ON_DEMAND
      ? null
      : translate(`common:shopping-method-${shoppingMethod}-desc`);

  return isEnabled ? (
    <ClickableTab
      id={`${shoppingMethod}-tab`}
      data-testid={`${shoppingMethod}-tab`}
      className={linkStyles}
      aria-controls={`${shoppingMethod}-tab`}
      aria-selected={isActive}
      role="tab"
      onClick={changeShoppingMethodHandler}
      to={slotsTypeUrl}
      isActive={isActive}
      tabIndex={isActive ? '0' : '-1'}
      onKeyDown={onKeyPressed}
      showVariant={shouldDisplayOop761TabheaderVariation}
    >
      {tabContent}
      {showHeaderDesc && (
        <div className={`option-tabs__tabheader-desc ${shoppingMethod}`}>
          {tabHeaderDescription}
        </div>
      )}
    </ClickableTab>
  ) : (
    <DisabledTab
      role="tab"
      aria-controls={shoppingMethod}
      aria-selected={isActive}
      aria-disabled="true"
      className={linkStyles}
      showVariant={shouldDisplayOop761TabheaderVariation}
    >
      {tabContent}
    </DisabledTab>
  );
};

OptionTabsHeader.propTypes = {
  changeShoppingMethod: PropTypes.func.isRequired,
  currentShoppingMethod: SHOPPING_METHOD_PROP_TYPE,
  isActive: PropTypes.bool,
  isEnabled: PropTypes.bool.isRequired,
  onKeyPressed: PropTypes.func.isRequired,
  shoppingMethod: SHOPPING_METHOD_PROP_TYPE.isRequired,
  shouldDisplayOop761TabheaderVariation: PropTypes.bool,
  showIcons: PropTypes.bool,
  slotsTypeUrl: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => ({
  currentShoppingMethod: getTrolleyShoppingMethod(state),
  slotsTypeUrl: getLanguageLink(state, `/slots/${ownProps.shoppingMethod}`)
});

const mapDispatchToProps = {
  changeShoppingMethod
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  helpers(['t'])
)(OptionTabsHeader);
