/**
 * Experiment constants for changing the delivery saver banner onto slot matrix page
 */

export const dsBannerVariants = {
  key: '2141',
  featureKey: 'GHS-all-dsbanner_slotmatrix',
  variationKey: 'variationKey',
  variants: {
    default: '2141-a',
    anytimeHDHomepage: '2141-b',
    anytimeHDCheckout: '2141-c',
    totalHDHomepage: '2141-d',
    totalHDCheckout: '2141-e',
  },
  useCustomerId: true,
};
