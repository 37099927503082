import styled from 'styled-components';
import Modal from '@ddsweb/modal';
import { spacing, fontSize } from '@ddsweb/selectors';
import { SubHeading, BodyText } from '@ddsweb/typography';
import { media } from '@ddsweb/foundation';

export const StyledModal = styled(Modal)`
  .ddsweb-modal__mask {
    z-index: 16;
  }
`;

export const Content = styled.div`
  margin-bottom: ${spacing.lg};
  margin-right: ${spacing.xxxl};

  ${media.belowMobileLarge`margin-right: 0`};
`;

export const ButtonContainer = styled.section`
  display: flex;
  justify-content: space-between;
  gap: ${spacing.md};

  ${media.belowMobileLarge`flex-wrap: wrap;`};
  & > a {
    font-size: ${fontSize.xs};
    width: 100%;
  }
`;

export const StyledHeading = styled(SubHeading)`
  margin: ${spacing.md} 0;
  font-size: ${fontSize.sm};
`;

export const StyledBodyText = styled(BodyText)`
  padding: ${spacing.xs} 0;
`;
