import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Heading from '@ddsweb/heading';
import Text from '@ddsweb/text';
import analyticsBus from '#/analytics/analyticsBus';
import { basicEvent } from '#/analytics/types/basic';
import {
  CAROUSEL_RIGHT_ARROW,
  TREX,
  NOW,
  TREX_IDENTIFIER
} from '#/analytics/constants';
import CarouselTile from '#/components/product-tiles/vertical-tile/variant/carousel-tile';
import { getIsAmendBasket, getAnalyticsBasketId } from '#/selectors/trolley';
import { getAppISORegionCode } from '#/reducers/app';
import { getTrexTitle, getTrexPageId } from '#/reducers/recommendations';
import { getAnalyticsSegments } from '#/reducers/user';
import { connect } from '#/lib/render/connect-deep-compare';
import helpers from '#/lib/decorators/helpers';
import {
  dispatchTrexAnalytics,
  getRecommenderConfig
} from '#/lib/recommender-helpers';
import {
  TILE_COMPONENT_TYPE,
  CAROUSEL_COMPONENT_TYPE,
  UNKNOWN_PAGEID,
  CAROUSEL,
  SIDELIST,
  GRID,
  DCS_CAROUSEL_ANALYTICS_TYPE
} from '#/constants/recommendations';
import { openModal } from '#/actions/ui-action-creators';
import { FLEXI, BEANS } from '#/constants/tile-types';

import { SectionMessage } from '#/components/cms-driven/section-message';
import Carousel from '#/components/carousel';
import ProductTile from '#/components/products/product-tile';
import Spinner from '#/components/shared/spinner';
import { getBaseProductId, getSellerId } from '#/selectors/item';
import { CAROUSEL_TILE } from '#/constants/analytics';

import { emitContentInteractOp } from '#/analytics/bertie/events';
import ProductList from '#/components/products/product-list';
import { VERTICAL } from '#/components/product-tiles/common/constants';
import { CHECKOUT_RECOMMENDATIONS } from '#/constants/result-types';

const mapStateToProps = (state, ownProps) => {
  const {
    targetTPNB,
    title,
    productTileType,
    recommendationsKey,
    ibycPageId
  } = ownProps;

  return {
    analyticsBasketId: getAnalyticsBasketId(state),
    analyticsSegments: getAnalyticsSegments(state),
    isAmendBasket: getIsAmendBasket(state),
    ISORegionCode: getAppISORegionCode(state),
    title: title || getTrexTitle(state, targetTPNB),
    trexId: ibycPageId || getTrexPageId(state, targetTPNB, recommendationsKey),
    productTileType: productTileType || BEANS
  };
};
@helpers(['c', 't', 'f'])
@connect(mapStateToProps, { openModal })
export default class Recommender extends PureComponent {
  static propTypes = {
    analyticsBasketId: PropTypes.string,
    analyticsComponentName: PropTypes.string,
    analyticsSegments: PropTypes.array.isRequired,
    c: PropTypes.func.isRequired,
    classes: PropTypes.string,
    configKey: PropTypes.string.isRequired,
    displayArea: PropTypes.string,
    hasTrexError: PropTypes.bool,
    ibycPageId: PropTypes.string,
    isAmendBasket: PropTypes.bool.isRequired,
    isChosenForYouCarousel: PropTypes.bool,
    isTrexCarousel: PropTypes.bool,
    isTrexEmpty: PropTypes.bool,
    lastTrex: PropTypes.bool.isRequired,
    moduleType: PropTypes.string.isRequired,
    openModal: PropTypes.func.isRequired,
    panelPos: PropTypes.number,
    productTileType: PropTypes.string,
    recommendationDisplayType: PropTypes.string,
    recommendations: PropTypes.object,
    ISORegionCode: PropTypes.string,
    showBeansCarousel: PropTypes.bool.isRequired,
    showInfoMessage: PropTypes.bool,
    showLoading: PropTypes.bool,
    showPromotion: PropTypes.bool,
    showRestOfShelf: PropTypes.bool,
    showWriteReview: PropTypes.bool,
    t: PropTypes.func.isRequired,
    targetTPNB: PropTypes.string,
    title: PropTypes.string,
    trexId: PropTypes.string,
    trexIdentifier: PropTypes.string,
    isIBYCRecommendation: PropTypes.bool
  };

  static defaultProps = {
    isTrexCarousel: true,
    recommendationDisplayType: CAROUSEL,
    showRestOfShelf: true,
    showWriteReview: true,
    showPromotion: true,
    showInfoMessage: true,
    trexIdentifier: TREX_IDENTIFIER,
    isChosenForYouCarousel: false
  };

  constructor(props) {
    super(props);

    const {
      hasTrexError,
      isTrexEmpty,
      recommendations,
      recommendationDisplayType,
      showLoading,
      productTileType
    } = this.props;
    const hasRecommendations = recommendations?.size > 0;
    const showUnavailableMsg = hasTrexError || isTrexEmpty;
    const isLoadingActive =
      showLoading && !hasRecommendations && !showUnavailableMsg;

    this.recommenderConfig = getRecommenderConfig(props);
    this.notifiedAnalytics = false;
    this.notifiedCarouselAnalytics = false;
    this.componentType =
      recommendationDisplayType === SIDELIST
        ? TILE_COMPONENT_TYPE
        : CAROUSEL_COMPONENT_TYPE;
    this.pTileType = productTileType === BEANS ? BEANS : FLEXI;
    this.state = {
      loadingActive: isLoadingActive,
      renderLoading: isLoadingActive,
      showPlaceholder:
        showLoading && (!hasRecommendations || showUnavailableMsg),
      wrapperStyles: {
        height: null
      }
    };

    this.contentRef = React.createRef();
  }

  componentDidMount() {
    const props = this.props;
    const { recommendations, isTrexCarousel } = props;

    if (!this.notifiedAnalytics && recommendations?.size > 0) {
      const componentType = isTrexCarousel ? null : DCS_CAROUSEL_ANALYTICS_TYPE;
      this.notifiedAnalytics = true;
      dispatchTrexAnalytics(props, this.recommenderConfig, true, componentType);
    }
  }

  componentWillUnmount() {
    window.cancelAnimationFrame(this.transitionFrame);
    clearTimeout(this.transitionTimeout);
  }

  componentDidUpdate() {
    const props = this.props;
    const {
      hasTrexError,
      isTrexEmpty,
      lastTrex,
      recommendations,
      isTrexCarousel
    } = props;
    const { loadingActive } = this.state;

    if (recommendations?.size > 0 && !this.notifiedAnalytics) {
      const componentType = isTrexCarousel ? null : DCS_CAROUSEL_ANALYTICS_TYPE;
      this.notifiedAnalytics = true;

      dispatchTrexAnalytics(
        props,
        this.recommenderConfig,
        lastTrex,
        componentType
      );

      if (loadingActive) {
        this.transitionOutLoading();
      }
    } else if (loadingActive && (hasTrexError || isTrexEmpty)) {
      this.transitionOutLoading();
    }
  }

  transitionOutLoading = () => {
    if (typeof window === 'undefined') {
      return;
    }

    window.cancelAnimationFrame(this.transitionFrame);
    clearTimeout(this.transitionTimeout);

    const { hasTrexError, isTrexEmpty } = this.props;
    const contentHeight =
      !hasTrexError && !isTrexEmpty
        ? `${this.contentRef.current.offsetHeight}px`
        : null;
    const wrapperStyles = this.state.wrapperStyles;

    this.transitionFrame = window.requestAnimationFrame(() => {
      this.setState(
        {
          wrapperStyles: {
            ...wrapperStyles,
            height: contentHeight
          },
          loadingActive: false
        },
        () => {
          this.transitionTimeout = setTimeout(this.clearLoadingTransition, 400);
        }
      );
    });
  };

  clearLoadingTransition = () => {
    const wrapperStyles = this.state.wrapperStyles;
    const { hasTrexError, isTrexEmpty, recommendations } = this.props;
    const hasRecommendations = recommendations?.size > 0;

    this.setState({
      wrapperStyles: {
        ...wrapperStyles,
        height: null
      },
      renderLoading: false,
      showPlaceholder: !hasRecommendations || hasTrexError || isTrexEmpty
    });
  };

  onClickAnalytics = (
    props,
    baseProductId,
    sellerId,
    interactionType,
    placement,
    campaignId,
    media,
    personalisationModel = undefined
  ) => {
    const { isTrexCarousel, analyticsBasketId } = this.props;

    const payload = {
      componentName: this.props.analyticsComponentName,
      componentType: isTrexCarousel
        ? this.recommenderConfig.panelType
        : DCS_CAROUSEL_ANALYTICS_TYPE,
      displayArea: props.moduleType,
      media,
      modulePosition: props.panelPos,
      interactionType,
      pageType: props.pageId || this.recommenderConfig.pageId,
      panel: [
        {
          campaignId,
          product: {
            tpnb: baseProductId,
            personalisationModel: personalisationModel,
            sellerId
          },
          posInModule: placement
        }
      ],
      contextValue: analyticsBasketId
    };

    return () => emitContentInteractOp(payload);
  };

  getCarouselTileProps = (item, itemIndex, placement, tile) => {
    const {
      t: translate,
      trexId,
      pageId,
      c: config,
      f: feature,
      trexIdentifier,
      analyticsComponentName,
      isTrexCarousel,
      ISORegionCode
    } = this.props;
    const baseProductId = getBaseProductId(item);
    const { modelMetadata } = item?.product;
    const personalisationModel = modelMetadata
      ? `${modelMetadata.version}-${modelMetadata.name}`
      : undefined;
    const sellerId = getSellerId(item);
    const { isSponsoredProduct } = item;
    const interactionType = isSponsoredProduct ? 'sponsored:citrus' : 'PDP';
    const campaignId = isSponsoredProduct ? item?.product?.adId : trexId;
    const media = isSponsoredProduct
      ? {
          catalogueCountry: ISORegionCode
        }
      : undefined;

    const onDetailsClick = this.onClickAnalytics(
      this.props,
      baseProductId,
      sellerId,
      interactionType,
      placement,
      campaignId,
      media,
      personalisationModel
    );

    return {
      item,
      identifier: isTrexCarousel ? trexIdentifier : analyticsComponentName,
      itemIndex,
      onDetailsClick,
      tile,
      feature,
      translate,
      config,
      resultType: pageId
    };
  };

  getRecommenderProductTiles = tileType => {
    const {
      isAmendBasket,
      trexId,
      recommendations,
      showRestOfShelf,
      showWriteReview,
      showPromotion,
      showInfoMessage,
      recommendationDisplayType,
      pageId = 'unknown_pageId'
    } = this.props;

    let placement = 0;
    const excludeMessages = !showInfoMessage
      ? ['blueRdgRestrictions', 'variableWeightProduct']
      : [];

    return [...recommendations.entries()].map(([key, item]) => {
      placement++;
      const baseProductId = getBaseProductId(item);
      const sellerId = getSellerId(item);

      return tileType === BEANS ? (
        <div className="beans-carousel-wrapper" key={key}>
          <CarouselTile
            key={key}
            {...this.getCarouselTileProps(item, key, placement, CAROUSEL_TILE)}
          />
        </div>
      ) : (
        <ProductTile
          key={key}
          type={tileType}
          identifier={TREX_IDENTIFIER}
          isAmendBasket={isAmendBasket}
          item={item}
          listData={{}}
          tile={CAROUSEL_TILE}
          onDetailsClick={this.onClickAnalytics(
            this.props,
            baseProductId,
            sellerId,
            'PDP',
            placement,
            trexId
          )}
          onRestOfShelfClick={this.onClickAnalytics(
            this.props,
            baseProductId,
            sellerId,
            'ROS',
            placement,
            trexId
          )}
          showRestOfShelf={showRestOfShelf}
          showWriteReview={showWriteReview}
          showPromotion={showPromotion}
          excludeMessages={excludeMessages}
          pageId={pageId}
          recommendationDisplayType={recommendationDisplayType}
          resultType={pageId}
        />
      );
    });
  };

  onCarouselButtonClick = direction => {
    const { analyticsComponentName } = this.props;

    if (
      !this.notifiedCarouselAnalytics &&
      (direction === 'forward' || direction === 'right')
    ) {
      basicEvent(analyticsBus, {
        type: analyticsComponentName || TREX,
        value: CAROUSEL_RIGHT_ARROW,
        action: NOW
      });
    }
    this.notifiedCarouselAnalytics = true;
  };

  renderCarousel() {
    const productTiles = this.getRecommenderProductTiles(this.pTileType);
    const { pageId = UNKNOWN_PAGEID } = this.props;

    if (productTiles.length > 1) {
      return (
        <Carousel
          notifyAnalytics={this.onCarouselButtonClick}
          profilerKey={pageId}
        >
          {productTiles}
        </Carousel>
      );
    } else {
      return productTiles;
    }
  }

  renderSidelist() {
    const productTiles = this.getRecommenderProductTiles(FLEXI);

    return <>{productTiles}</>;
  }

  renderUnavailableMsg() {
    const { hasTrexError, isTrexEmpty, t: translate } = this.props;

    if (!hasTrexError && !isTrexEmpty) {
      return null;
    }

    return (
      <div className="recommender__unavailable-wrapper">
        <Heading headingLevel="5">
          {translate('recommendations:error.heading')}
        </Heading>
        <Text>{translate('recommendations:error.message')}</Text>
      </div>
    );
  }

  renderContent() {
    const {
      recommendations,
      recommendationDisplayType,
      title,
      showBeansCarousel,
      isIBYCRecommendation
    } = this.props;

    const hasRecommendations = recommendations?.size > 0;
    const unavailableMsg = !hasRecommendations && this.renderUnavailableMsg();

    if (unavailableMsg) {
      return unavailableMsg;
    } else if (!hasRecommendations) {
      return null;
    }

    let recommenderContent;

    if (recommendationDisplayType === SIDELIST) {
      recommenderContent = this.renderSidelist();
    } else if (recommendationDisplayType === GRID) {
      recommenderContent = (
        <ProductList
          items={recommendations}
          isSearchResult={false}
          viewAllFilterFlags={false}
          showFilters={false}
          isGrid={true}
          tileType={VERTICAL}
          productTileVariant={VERTICAL}
          resultType={CHECKOUT_RECOMMENDATIONS}
          triggerAnalyticsEvent={false}
        />
      );
    } else {
      recommenderContent = this.renderCarousel();
    }

    return (
      <>
        {showBeansCarousel ? (
          <>
            {!isIBYCRecommendation ? (
              <div
                className={classnames('section-heading', {
                  'left-align': false
                })}
              >
                <h1 className="heading">{title}</h1>
              </div>
            ) : null}
          </>
        ) : (
          <div className="product-list__section-message">
            <SectionMessage message={title} />
          </div>
        )}
        {recommenderContent}
      </>
    );
  }

  renderLoading() {
    const { isIBYCRecommendation } = this.props;
    <div
      className={classnames('recommender__loading', {
        'recommender__loading-color': isIBYCRecommendation
      })}
    >
      <div className="recommender__loading-wrapper">
        <Spinner />
      </div>
    </div>;
  }

  render() {
    const { recommendationDisplayType, recommendations, classes } = this.props;
    const {
      wrapperStyles,
      loadingActive,
      renderLoading,
      showPlaceholder
    } = this.state;
    const hasRecommendations = recommendations?.size > 0;

    if (!hasRecommendations && !showPlaceholder) {
      return null;
    }

    const wrapperClassNames = classnames(
      'recommender',
      `recommender--${recommendationDisplayType}`,
      {
        'recommender--loading-active': loadingActive,
        'recommender--placeholder': showPlaceholder
      },
      classes
    );

    return (
      <div className={wrapperClassNames} style={wrapperStyles}>
        {renderLoading ? this.renderLoading() : null}
        <div ref={this.contentRef} className="recommender__wrapper">
          {this.renderContent()}
        </div>
      </div>
    );
  }
}
