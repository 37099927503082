import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import StarIcon from './star-icon';

export default class StarRating extends Component {
  static propTypes = {
    defaultValue: PropTypes.number,
    errorClass: PropTypes.string,
    errorMessage: PropTypes.string,
    inlineDescriptors: PropTypes.bool,
    isError: PropTypes.bool,
    legendText: PropTypes.string.isRequired,
    name: PropTypes.string,
    onClick: PropTypes.func,
    ratingLabels: PropTypes.array.isRequired
  };

  static defaultProps = {
    isError: false,
    inlineDescriptors: false,
    name: 'rating-stars',
    errorClass: ''
  };

  constructor(props) {
    super(props);
    this.state = {
      hoveredOnStarIndex: null,
      starRating: this.isValidNumber(props.defaultValue)
        ? props.ratingLabels.length - props.defaultValue
        : null,
      starError: this.props.isError
    };
    this.ratingLabels = this.props.ratingLabels.reverse();
    this.ratingsLength = this.ratingLabels.length;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.isError !== nextProps.isError) {
      this.setState({
        starError: nextProps.isError
      });
    }
  }

  handleClick = index => () => {
    if (this.state.starRating !== index) {
      this.setState({ starRating: index });
      if (this.props.onClick) {
        this.props.onClick(this.getRatingValue(index));
      }
    }

    if (this.state.starError) {
      this.setState({ starError: false });
    }
  };

  handleMouseEnter = index => () => {
    this.setState({ hoveredOnStarIndex: index });
  };

  handleMouseLeave = () => {
    this.setState({ hoveredOnStarIndex: null });
  };

  isValidNumber(number) {
    return typeof number === 'number';
  }

  getRatingText() {
    const { hoveredOnStarIndex, starRating, starError } = this.state;

    if (hoveredOnStarIndex === null) {
      if (starError) {
        return this.props.errorMessage;
      }

      if (this.isValidNumber(starRating)) {
        return this.ratingLabels[starRating];
      }

      return null;
    }

    return this.ratingLabels[hoveredOnStarIndex];
  }

  getRatingValue(index) {
    return this.ratingsLength - index;
  }

  isChecked(index) {
    if (!this.isValidNumber(this.state.starRating)) return false;

    return index === this.state.starRating;
  }

  renderRatings(ratingLabels) {
    return ratingLabels.map((ratingText, index) => {
      const id = `rating-radio-${index}`;
      const ratingValue = this.getRatingValue(index);
      const checked = this.isChecked(index);

      return (
        <React.Fragment key={`fragment-${id}`}>
          <input
            type="radio"
            name={this.props.name}
            id={id}
            key={`input-${id}`}
            value={ratingValue}
            className="visually-hidden star-rating__input"
            checked={checked}
            onClick={this.handleClick(index)}
            data-auto="star-rating__input"
          />
          <label
            className="star-rating__label"
            key={`label-${id}`}
            htmlFor={id}
            onMouseEnter={this.handleMouseEnter(index)}
            onMouseLeave={this.handleMouseLeave}
            data-auto="rating-star-button"
          >
            <StarIcon />
            <span className="visually-hidden">{ratingText}</span>
          </label>
        </React.Fragment>
      );
    });
  }

  render() {
    return (
      <fieldset
        className={classnames('star-rating', {
          [`star-rating--error ${this.props.errorClass}`]: this.state.starError
        })}
        data-auto="star-rating"
      >
        <legend className="star-rating__legend">{`${this.props.legendText}`}</legend>
        <div
          className={classnames('star-rating__rateable', {
            'star-rating__rateable--inline': this.props.inlineDescriptors
          })}
        >
          {this.renderRatings(this.ratingLabels)}
        </div>
        <span
          className={classnames('star-rating__rating-text', {
            'star-rating__rating-text--inline': this.props.inlineDescriptors
          })}
          data-auto="star-rating__rating-text"
        >
          {this.getRatingText()}
        </span>
      </fieldset>
    );
  }
}
