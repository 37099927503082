import {
  canBeDelivered,
  getCatchWeight,
  getMaxQty,
  getProductAverageWeight,
  getProductId,
  getProductTitle,
  getQuantity,
  hasRestrictedOrderAmendment,
  hasToggle,
  isCatchWeightProduct,
  isRdgRestricted,
  qtyDecrementable,
  qtyIncrementable,
  getUnit,
  isWeightOnly,
} from '#/selectors/item';
import { WEIGHT_UNIT_KG } from '#/constants/common';
import { getAddFormParams } from '#/selectors/beans-product-tile/add-form-params-data';
import { getCatchweightData } from '#/selectors/beans-product-tile/catchweight-data';
import { getLastSelectedSlot } from '#/selectors/trolley';
import getToggleWeightData, { TToggleWeightData } from '#/selectors/beans-product-tile/toggle-weight-data';
import getWeightOnlyData, { TWeightOnlyData } from '#/selectors/beans-product-tile/weight-only-data';
import { round } from '#/lib/string-formatting/format';
import { Item } from '#/lib/records/item';
import { isAvailableEpwOverride } from './product-availability-data';
import { getShouldHideWeightToggle } from '#/experiments/oop-1294/selectors';

// -- Constants

export const CATCHWEIGHT_QUANTITY_TYPE = 'catchweight-quantity';
export const CATCHWEIGHT_SUCCESS_MESSAGE = '%{basketValue} x %{unit} in basket';
export const SIMPLE_QUANTITY_TYPE = 'simple-quantity';
export const SIMPLE_SUCCESS_MESSAGE = '%{basketValue} in basket';
export const TOGGLE_QUANTITY_TYPE = 'toggle-weight-quantity';
export const TOGGLE_SUCCESS_MESSAGE = '%{basketValue} in basket';

// -- Types

type TTranslation = (...args: any[]) => string;

type TDisabledControls =
  | boolean
  | {
      add: boolean;
      remove: boolean;
    };

type TQuantityData = {
  disabled: TDisabledControls;
  formData: ReturnType<typeof getAddFormParams>;
  itemQuantityInStore: number;
  itemUnitInStore: string;
  maxQuantity: number;
  quantityControlsId: string;
  productTitle: string;
  buttonText: {
    add: string;
    update: string;
  };
};

type TAdditionalCatchweightData = ReturnType<typeof getCatchweightData> & {
  quantityType: typeof CATCHWEIGHT_QUANTITY_TYPE;
  successMessage: string;
  options: null;
};

type TAdditionalSimpleItemData = {
  quantityType: typeof SIMPLE_QUANTITY_TYPE;
  variantOptions: null;
  initialVariantValue: undefined;
  successMessage: string;
  options: null;
};

type TAdditionalWeightData = {
  quantityType: typeof TOGGLE_QUANTITY_TYPE;
  successMessage: string;
  options: Record<string, any> | undefined;
  initialVariantValue?: string;
};

type TCatchweightItemQuantity = TQuantityData & TAdditionalCatchweightData;

type TSimpleItemQuantity = TQuantityData & TAdditionalSimpleItemData;

type TToggleWeightQuantity = TQuantityData & TAdditionalWeightData & TToggleWeightData;

type TWeightOnlyQuantity = TQuantityData & TAdditionalWeightData & TWeightOnlyData;

export type TQuantityControlsData =
  | TCatchweightItemQuantity
  | TSimpleItemQuantity
  | TToggleWeightQuantity
  | TWeightOnlyQuantity;

// -- Utils

const itemCantBeDelivered = (state: Store, item: Item): boolean => {
  const slot = getLastSelectedSlot(state);

  return !canBeDelivered(item, slot);
};

const itemControlsDisabled = (state: Store, item: Item): TDisabledControls => {
  const disabled = isRdgRestricted(item) || hasRestrictedOrderAmendment(item) || itemCantBeDelivered(state, item);

  if (disabled) return true;

  return {
    add: !qtyIncrementable(item) || isAvailableEpwOverride(state, item),
    remove: !qtyDecrementable(item),
  };
};

const getItemQtyInStore = (item: Item): number => {
  if (getUnit(item) == WEIGHT_UNIT_KG) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return round(Math.max(getQuantity(item), 0) / getProductAverageWeight(item)!);
  } else {
    return getQuantity(item);
  }
};

const getBaseData = (state: Store, item: Item, identifier: string, translation: TTranslation): TQuantityData => {
  const productTitle = getProductTitle(item);

  const itemQuantityInStore = getItemQtyInStore(item) || 0;

  return {
    disabled: itemControlsDisabled(state, item),
    formData: getAddFormParams(state, item, identifier),
    quantityControlsId: `quantity-controls-${getProductId(item)}`,
    itemQuantityInStore,
    productTitle,
    itemUnitInStore: getUnit(item),
    maxQuantity: getMaxQty(item),
    buttonText: {
      add: translation('product-list:add'),
      update: translation('common:update'),
    },
  };
};

const getCatchweightQuantityData = (
  state: Store,
  item: Item,
  catchWeightBasketText: string,
  translation: TTranslation,
): TAdditionalCatchweightData => ({
  quantityType: CATCHWEIGHT_QUANTITY_TYPE,
  successMessage: `${getItemQtyInStore(item)} x ${getCatchWeight(item)}${catchWeightBasketText}`,
  options: null,
  ...getCatchweightData(state, item, translation),
});

const getSimpleQuantityData = (item: Item, basketText: string): TAdditionalSimpleItemData => ({
  quantityType: SIMPLE_QUANTITY_TYPE,
  successMessage: `${getItemQtyInStore(item)} ${basketText}`,
  variantOptions: null,
  initialVariantValue: undefined,
  options: null,
});

const getToggleWeightQuantityData = (
  state: Store,
  item: Item,
  basketText: string,
  translation: TTranslation,
): TAdditionalWeightData & TToggleWeightData => {
  const qtyInStore = getItemQtyInStore(item);

  return {
    quantityType: TOGGLE_QUANTITY_TYPE,
    successMessage: getUnit(item) == WEIGHT_UNIT_KG ? `${qtyInStore}kg ${basketText}` : `${qtyInStore} ${basketText}`,
    ...getToggleWeightData(state, item, translation),
  };
};

const getWeightOnlyQuantityData = (
  state: Store,
  item: Item,
  basketText: string,
  translation: TTranslation,
): TAdditionalWeightData & TWeightOnlyData => ({
  quantityType: TOGGLE_QUANTITY_TYPE,
  successMessage: `${getItemQtyInStore(item)}${getUnit(item)} ${basketText}`,
  ...getWeightOnlyData(state, item, translation),
});

// -- Export
export const getQuantityControlData = (
  state: Store,
  item: Item,
  identifier: string,
  translation: TTranslation,
): TQuantityControlsData => {
  const baseData = getBaseData(state, item, identifier, translation);
  const shouldHideWeightToggle = getShouldHideWeightToggle(state);
  const basketText = translation('product-tile:in-trolley');
  if (!shouldHideWeightToggle && hasToggle(item)) {
    return { ...baseData, ...getToggleWeightQuantityData(state, item, basketText, translation) };
  }

  if (isWeightOnly(item)) {
    return { ...baseData, ...getWeightOnlyQuantityData(state, item, basketText, translation) };
  }

  if (isCatchWeightProduct(item)) {
    const catchWeightBasketText = `${translation('product-tile:unit.kg')} ${basketText}`;

    return { ...baseData, ...getCatchweightQuantityData(state, item, catchWeightBasketText, translation) };
  }

  return { ...baseData, ...getSimpleQuantityData(item, basketText) };
};
