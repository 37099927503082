import styled from 'styled-components';
import { media } from '@ddsweb/foundation';
import Button from '@ddsweb/button';

export const StyledButton = styled(Button)`
  width: auto;
  height: 36px;
  margin-top: 10px;
  ${media.aboveTablet` 
    margin-top: 12px;
  `};
`;
