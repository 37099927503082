import { createSelector } from 'reselect';
import {
  getResultsPages,
  getResultsPagesByTypeFactory
} from '#/selectors/results/get-pages';
import { getProductId } from '#/selectors/item';

function getAllItems(pages) {
  return pages.reduce((acc, itemsMap) => {
    if (itemsMap) {
      itemsMap.forEach(item => {
        acc.set(getProductId(item), item);
      });
    }

    return acc;
  }, new Map());
}

export const getAllResultsItems = createSelector(getResultsPages, pages =>
  getAllItems(pages)
);

export const getAllResultsItemsByTypeFactory = resultsType =>
  createSelector(getResultsPagesByTypeFactory(resultsType), pages =>
    getAllItems(pages)
  );
