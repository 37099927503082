import React from 'react';
import SafelyOutputString from '#/components/safely-output-string';
import { StyledButtonContainer, StyledHeading, StyledText } from './styled';

export type DialogContentProps = {
  title: string;
  body: string;
  backButton: JSX.Element | undefined;
  primaryButton: JSX.Element | undefined;
};

export const DialogContent = (props: DialogContentProps): JSX.Element => {
  const { title, body, backButton, primaryButton } = props;

  return (
    <>
      <StyledHeading headingLevel="1" visualSize="headline3">
        {title}
      </StyledHeading>
      <StyledText as="p">
        <SafelyOutputString>{body}</SafelyOutputString>
      </StyledText>
      {(backButton || primaryButton) && (
        <StyledButtonContainer>
          {primaryButton}
          {backButton}
        </StyledButtonContainer>
      )}
    </>
  );
};

export default DialogContent;
