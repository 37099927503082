import React from 'react';
import PropTypes from 'prop-types';
import Component from '../../results';
import { connect } from '#/lib/render/connect-deep-compare';
import { FLEXI } from '#/constants/tile-types';
import { BUYLIST as BUYLIST_RESULT_TYPE } from '#/constants/result-types';
import { areItemsGrouped } from '#/selectors/results/are-items-grouped';
import { getBuylistName } from '#/reducers/buylist';
import { getBuylistByIdResource } from '#/selectors/resources';
import { VERTICAL } from '#/components/product-tiles/common/constants';

const mapStateToProps = state => ({
  isGrouped: areItemsGrouped(state),
  buylistName: getBuylistName(state),
  buylistByIdResource: getBuylistByIdResource(state)
});

@connect(mapStateToProps)
export default class BuyList extends React.Component {
  static propTypes = {
    buylistByIdResource: PropTypes.object.isRequired,
    buylistName: PropTypes.string,
    isGrouped: PropTypes.bool
  };

  render() {
    const { isGrouped, buylistName } = this.props;

    return (
      <Component
        isSearchResult={false}
        showFilters={false}
        type="buy-list"
        resultType={BUYLIST_RESULT_TYPE}
        tileType={FLEXI}
        query={buylistName}
        isGrouped={isGrouped}
        productTileVariant={VERTICAL}
      />
    );
  }
}
