import { getQueryParamByKey } from '#/lib/url/url-utils';
import { ADDRESSES, TROLLEY_CONTENTS } from '#/constants/spa-resource';
import { getTrolleyDeliveryAddress } from '#/selectors/trolley';
import { getCollectionAddressIdFromCookie } from '#/lib/slot/slot-helpers';

export function getFormattedAddress(address) {
  return !address
    ? []
    : Object.keys(address)
        .filter(key => /^addressLine/.test(key))
        .map(key => address[key]);
}

export const getDefaultAddressId = addressesResource => {
  return addressesResource.defaultAddress?.uuid;
};

/**
 * getSelectedAddressId method: returns address uuid by following priority
 * 1 - if present, returns addressId from request paramter
 * 2 - if present, returns addressId from referer url parameters
 * 3 - if present, return addressId from COLLECTION_COOKIE cookie
 * 4 - if trolleyContents resource has deliveryAddress, return uuid
 * 5 - if trolleyContents resource has deliveryAddress but no uuid, extract it from allAddresses
 * 6 - if none above, return null
 */
export const getSelectedAddressId = (
  headersReferer,
  params,
  loadedResources,
  cookies
) => {
  const addressIdKey = 'addressId';
  let addressIdFromReferer = null;
  let addressIdFromCookie = null;
  let addressId = null;

  if (headersReferer && headersReferer.includes(addressIdKey)) {
    addressIdFromReferer = getQueryParamByKey(headersReferer, addressIdKey);
  }

  addressIdFromCookie = getCollectionAddressIdFromCookie(cookies);

  addressId =
    params?.query?.addressId || addressIdFromReferer || addressIdFromCookie;

  if (!addressId) {
    const trolleyAddress = getTrolleyDeliveryAddress({
      trolley: loadedResources[TROLLEY_CONTENTS]
    });

    if (trolleyAddress?.uuid) {
      addressId = trolleyAddress.uuid;
    } else if (trolleyAddress) {
      const trolleyAddressUUIDfromAllAddresses = loadedResources[
        ADDRESSES
      ]?.allAddresses.find(address => address?.id === trolleyAddress?.id)?.uuid;

      if (trolleyAddressUUIDfromAllAddresses) {
        addressId = trolleyAddressUUIDfromAllAddresses;
      }
    }
  }

  return addressId;
};
