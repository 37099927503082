import { createItem } from '#/lib/records';
import { Items } from '#/custom-typings/redux-store/trolley.defs';
import { cloneItem } from '#/utils/clone-item';
import { cloneItems } from '#/utils/clone-items';
import { getGtin13FromImageUrl } from '#/lib/records/product-utils';
import { getProductSubstitutions } from '#/selectors/item';
import { Item } from '#/lib/records/item';

// Convert JS items array to a map
export default function itemsToMap(items: Array<Item> | Items = []): Items {
  if (items instanceof Map) return items;

  if (items.length === 0) {
    return new Map();
  }

  //TODO This will
  if ('product' in items[0] && items[0].catchWeight) {
    return items.reduce((map, item) => {
      let subs = getProductSubstitutions(item);
      if (subs && !(subs instanceof Map) && subs.length > 0) {
        if (typeof subs === 'object') {
          subs = Object.keys(subs).map(key => subs?.[key]);
        }

        if (Array.isArray(subs)) {
          subs = subs.map(subItem => {
            return { ...subItem, isSubstitute: true };
          });

          item.product.substitutions = subs;
        }
      }

      if (item?.content?.id) {
        map.set(item?.content?.id, item);
      } else {
        map.set(item.product.id || item.id, item);
      }
      return map;
    }, new Map());
  }

  return cloneItems(items).reduce((map, item) => {
    const updatedItem = cloneItem(item);
    const product = updatedItem.product;
    let subs = product && getProductSubstitutions(updatedItem);
    const productId = product?.id;
    const contentId = updatedItem?.content?.id;

    if (subs && !(subs instanceof Map)) {
      if (typeof subs === 'object') {
        subs = Object.keys(subs).map(key => subs?.[key]);
      }

      if (Array.isArray(subs)) {
        subs = subs.map(subItem => {
          const seedProduct = {
            id: product.baseProductId || product.id || item.id,
            gtin13: getGtin13FromImageUrl(product.defaultImageUrl) || getGtin13FromImageUrl(item.defaultImageUrl),
            name: product.title || item.title,
          };
          const updatedSubItem = cloneItem({ ...subItem, isSubstitute: true, seedProduct });

          return createItem(updatedSubItem);
        });

        product.substitutions = subs;
      }
    }
    if (contentId) {
      map.set(contentId, updatedItem);
    } else {
      map.set(productId, createItem(updatedItem));
    }
    return map;
  }, new Map());
}
