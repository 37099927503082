import MobileDetect from 'mobile-detect';
import getDeviceInfo from '#/lib/browser/device-characteristics';
import { DESKTOP, MOBILE, TABLET, IOS, IPHONE_OS } from '#/constants/platforms';

export const getDeviceType = userAgent => {
  const mobileDetect = new MobileDetect(userAgent);
  let deviceType = DESKTOP;

  if (mobileDetect.phone() !== null) {
    deviceType = MOBILE;
  } else if (mobileDetect.tablet() !== null) {
    deviceType = TABLET;
  }

  return deviceType;
};

export const getDeviceTypeFromReq = req => {
  return (
    (req.device && req.device.deviceType) ||
    processDeviceCharacteristics(req).deviceType ||
    getDeviceType(req.headers['user-agent'])
  );
};

export const isIOS = operatingSystem =>
  operatingSystem.toLowerCase() === IPHONE_OS.toLowerCase() ||
  operatingSystem.toLowerCase() === IOS.toLowerCase();

export const isIOSTabletVersionBelow9 = (
  deviceType,
  operatingSystem,
  operatingSystemMajorVersion
) =>
  deviceType === TABLET &&
  isIOS(operatingSystem) &&
  operatingSystemMajorVersion < 9;

export const processDeviceCharacteristics = req => {
  //  we can get this from Akamai request header
  const deviceCharacteristics =
    req.c('server:akamai:device:enabled') &&
    req.headers[req.c('server:akamai:device:characteristicsHeader')];

  return deviceCharacteristics ? getDeviceInfo(deviceCharacteristics) : {};
};
