import { PrimaryNavigationList } from '#/components/horizontal-taxonomy/types';
import { TTranslateFunc } from '#/lib/records/helpers.defs';
import { TBasicEventProps, basicEvent } from '#/analytics/types/basic';
import analyticsBus from '#/analytics/analyticsBus';
import { TOP_NAV, DELAY } from '#/analytics/constants';
import { NAV_MAX_LENGTH } from '#/constants/horizontal-taxanomy';
import { DEPARTMENT } from '#/constants/taxonomy-levels';
import { PRIMARY_NAV_ALL_DEPT_MENU_ID } from '#/components/primary-navigation/constants';

type AllGroceriesList = {
  desktop: boolean;
  mobile: string;
  id: string;
  href: string;
  name: string;
  children: PrimaryNavigationList;
  ariaLabel: string;
};

const AllRoute = '/all';

export const loadingMenu = [
  {
    id: 'loading',
    href: '#',
    name: 'Loading...',
    hasPopup: true,
    children: null,
  },
];

export const getAllGroceries = (navList: PrimaryNavigationList, translate: TTranslateFunc): AllGroceriesList => {
  return {
    desktop: true,
    mobile: 'Groceries',
    id: PRIMARY_NAV_ALL_DEPT_MENU_ID,
    href: '',
    name: translate('navigation:all-departments'),
    children: navList,
    ariaLabel: translate('navigation:all-departments'),
  };
};

const getTaxonomyMenus = (navList: PrimaryNavigationList): PrimaryNavigationList => {
  const menus = navList.map(navItem => {
    const { url, name, label } = navItem;
    const convertedName = name?.replace(/ /g, '-');
    const truncatedName = name && name.length > NAV_MAX_LENGTH ? `${name.slice(0, NAV_MAX_LENGTH)}...` : name;

    return {
      desktop: true,
      mobile: name,
      id: convertedName,
      href: `${url}${AllRoute}`,
      name: truncatedName,
      label,
      ariaLabel: name,
      title: name,
    };
  });

  return menus;
};

const getAllGroceriesRightTaxonomyMenus = (navList: PrimaryNavigationList): PrimaryNavigationList => {
  const menus = getTaxonomyMenus(navList);
  return [...menus];
};

export const getTaxonomyData = (navList: PrimaryNavigationList): PrimaryNavigationList => {
  const taxonomyMenus: PrimaryNavigationList = getAllGroceriesRightTaxonomyMenus(navList);
  return taxonomyMenus;
};

export const fireAnalyticsOnMenuSelection = (selectedElementId: string, label: string, navigable: boolean): void => {
  const DOUBLE_COLON = '::';
  const PIPE = '|';
  const SPACE = ' ';

  if (navigable) {
    let analyticsValue = selectedElementId.includes(DOUBLE_COLON)
      ? selectedElementId.split(DOUBLE_COLON).pop()
      : selectedElementId;
    analyticsValue = analyticsValue?.replace(/-/g, SPACE);
    analyticsValue = analyticsValue?.split(PIPE)[1]?.toLowerCase();

    const menuType = label !== '' ? { menuType: label } : {};

    const eventObject: TBasicEventProps = {
      ...menuType,
      type: TOP_NAV,
      value: `${DEPARTMENT}:${analyticsValue}`,
      action: DELAY,
    };

    basicEvent(analyticsBus, eventObject);
  }
};
