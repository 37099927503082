import { TTranslateFunc } from '#/lib/records/helpers.defs';
import React, { FC } from 'react';
import Price from '#/components/shared/price';
import ValueBar from '@ddsweb/value-bar';
import { ValueMessageWrapper } from './style';
import { TCurrency } from '#/custom-typings/redux-store/app.defs';
import Heading from '@ddsweb/heading';

export const BasketSavings: FC<{
  savings: number;
  currency: TCurrency;
  t: TTranslateFunc;
  sideBasket?: boolean;
}> = ({ savings, currency, t, sideBasket }) => {
  const savingsAmount = <Price className="amount" value={savings} enableMaxLimit={true} {...currency} />;
  return (
    <ValueMessageWrapper sideBasket={sideBasket}>
      <ValueBar rightLabel={savingsAmount}>
        <Heading headingLevel="5">{t('trolley:offer-savings')}</Heading>
      </ValueBar>
    </ValueMessageWrapper>
  );
};
