import { FAVORITES, TROLLEY_CONTENTS } from '#/constants/spa-resource';
import {
  receiveFavoritesData,
  receiveFavoritesLayoutData
} from '#/actions/ui-action-creators';
import { updateResults } from '#/actions/results-actions';
import { RECEIVE_FAVORITE_INTERRUPTS } from '#/constants/action-types';
import {
  updateCurrentUrl,
  updateFromNewResults
} from '#/actions/filter-action-creators';
import { getFavoritesResource } from '#/selectors/resources';
import { addResultsAPMData } from '#/utils/favorite-utils';

export const type = FAVORITES;
export const params = ['query'];
export const dependencies = [TROLLEY_CONTENTS];

export function handler(dispatch, getState, options = {}) {
  const state = getState();
  const { data } = getFavoritesResource(state);
  if (!data) {
    return;
  }

  const {
    results,
    selectedFavoritesTab,
    selectedFavoritesLayout,
    interrupts
  } = data;

  if (selectedFavoritesTab) {
    dispatch(receiveFavoritesData(selectedFavoritesTab));
  }

  if (selectedFavoritesLayout) {
    dispatch(receiveFavoritesLayoutData(selectedFavoritesLayout));
  }
  if (interrupts) {
    dispatch({
      type: RECEIVE_FAVORITE_INTERRUPTS,
      value: interrupts
    });
  }

  if (results && results.productItems) {
    dispatch(updateResults(results, type, true));

    if (options.updateCurrentURL) {
      dispatch(updateCurrentUrl(options.updateCurrentURL));
      dispatch(updateFromNewResults(results, options.updateCurrentURL));
    }
  }

  addResultsAPMData(data, 'favorites');
}
