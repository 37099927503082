import { Items } from '#/custom-typings/redux-store/trolley.defs';
import { cloneItem } from '#/utils/clone-item';
import { cloneItems } from '#/utils/clone-items';
import { Item } from '#/lib/records/item';
import { getById, idInItems } from '#/lib/records/product-utils';
import { getProductId } from '#/selectors/item';

export default function merge(baseItems: Items | Array<Item>, items: Items | Array<Item>): Items {
  const mergedItems = new Map();

  const clonedItems = cloneItems(items);
  const itemsArray = clonedItems instanceof Map ? [...clonedItems.values()] : clonedItems;
  const reversedItems = itemsArray.reverse();

  reversedItems.forEach(item => {
    const id = getProductId(item);

    if (!idInItems(id, baseItems)) {
      mergedItems.set(id, item);
    }
  });

  baseItems.forEach((item: Item) => {
    const id = getProductId(item);

    if (idInItems(id, items)) {
      mergedItems.set(id, cloneItem(getById(items, id) as Item));
    } else {
      mergedItems.set(id, cloneItem(item));
    }
  });

  return mergedItems;
}
