import React from 'react';
import { InspirationalHeroBanner as Banner } from '@ddsweb/banner';
import { PRIMARY, SECONDARY, BLANK_TARGET, FULL, HALF } from '@ddsweb/constants';
import { spacing } from '@ddsweb/selectors';
import styled from 'styled-components';

const StyledBanner = styled(Banner)`
  margin: ${spacing.xl} auto;
`;

export type InspirationalHeroBannerProps = {
  bodyText: string;
  contentGroupVariant: typeof FULL | typeof HALF;
  headline: string;
  image: {
    alt: string;
    large: string;
    small?: string;
  };
  inverse?: boolean;
  links: Array<{
    ariaLabel?: string;
    buttonVariant?: typeof PRIMARY | typeof SECONDARY;
    href: string;
    icon?: string;
    target?: Array<typeof BLANK_TARGET>;
    text: string;
  }>;
  logo?: {
    alt: string;
    showOnMobile?: boolean;
    src: string;
  };
  standfirstText?: string;
};

const InspirationalHeroBanner = (props: InspirationalHeroBannerProps): JSX.Element => {
  const { bodyText, contentGroupVariant, headline, image, inverse, links, logo, standfirstText } = props;

  return (
    <StyledBanner
      bodyText={bodyText}
      contentGroupVariant={contentGroupVariant}
      headline={headline}
      image={image}
      inverse={inverse}
      links={links}
      logo={logo}
      standfirstText={standfirstText}
    />
  );
};

export { InspirationalHeroBanner };
