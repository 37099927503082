import styled, { css } from 'styled-components';
import { Heading } from '@ddsweb/heading';
import Divider from '@ddsweb/divider';
import { InLineMessaging } from '@ddsweb/messaging';
import Link from '@ddsweb/link';
import { visuallyHide } from '@ddsweb/foundation';
import { BREAKPOINTS } from '@ddsweb/constants';
import { fontSize, fontStyles, spacing, colors, lineHeight, breakpoints } from '@ddsweb/selectors';
import { Accordion } from '@ddsweb/accordion';
import Icon from '@ddsweb/icon';

type TItemList = {
  highlight?: boolean;
  pad?: boolean;
  discount?: boolean;
  info?: boolean;
  noSpacing?: boolean;
};

export const VisuallyHiddenLabel = styled.span`
  ${visuallyHide}
`;

export const StyledBasketSummaryContainer = styled.div`
  background-color: ${colors.white};
  border-color: ${colors.lines};
  border-style: solid;
  border-width: 1px;
  position: relative;
`;

export const ScrollableContainer = styled.div`
  @media (min-width: ${BREAKPOINTS.tablet}px) {
    overflow-y: auto;
    max-height: calc(100vh - 230px);
  }
`;

export const StyledHeader = styled.header`
  margin: ${spacing.lg} ${spacing.md} ${spacing.md} ${spacing.md};
  @media (min-width: ${BREAKPOINTS.tablet}px) {
    border-bottom: 1px solid ${colors.linesLight};
  }
`;

export const StyledHeading = styled(Heading)`
  font-size: ${fontSize.md};
  @media (min-width: ${BREAKPOINTS.tablet}px) {
    padding-bottom: ${spacing.md};
  }
`;

export const StyledSubTitle = styled(Heading)`
  font-size: ${fontSize.sm};
  @media (min-width: ${BREAKPOINTS.tablet}px) {
    padding-bottom: ${spacing.md};
  }
`;

export const StyledBody = styled.div`
  margin: 0 ${spacing.md} 0;
`;

export const FixedBody = styled.div`
  margin: 0 ${spacing.md} ${spacing.sm};
  @media (max-width: ${BREAKPOINTS.tablet - 1}px) {
    margin-top: ${spacing.sm};
    margin-bottom: ${spacing.xs};
  }
`;

export const StyledDivider = styled(Divider)`
  margin-top: ${spacing.xx};
  margin-bottom: ${spacing.sm};
`;

export const StyledIcon = styled(Icon)`
  margin-left: ${spacing.xs};
`;

export const StyledDividerMobile = styled(Divider)`
  margin: 0 ${spacing.sm};
  width: auto;
  @media (min-width: ${BREAKPOINTS.tablet}px) {
    display: none;
  }
`;

export const StyledInLineMessaging = styled(InLineMessaging)`
  @media (max-width: ${BREAKPOINTS.tablet - 1}px) {
    margin: 0 ${spacing.sm} ${spacing.sm};
    div {
      padding-top: 0;
      margin-top: -1px;
    }
  }
  @media (min-width: ${BREAKPOINTS.tablet}px) {
    margin-bottom: ${spacing.sm};
  }
  line-height: 20px;
  color: ${colors.text};
`;

export const StyledAccordion = styled(Accordion)`
  border-bottom: 0;
  border-top: 0;
`;

export const StyledItemList = styled.dl<{
  highlight?: boolean;
  pad?: boolean;
  discount?: boolean;
  noSpacing?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  padding: ${(props: TItemList): string => (props.noSpacing !== false ? `0 0 ${spacing.xs} 0` : `${spacing.xx}`)};
  padding-bottom: ${(props: TItemList): unknown => (props.pad !== false ? spacing.xs : 0)};
  ${(props: TItemList): unknown => (props.highlight ? fontStyles.xsHeading : fontStyles.xsText)};
  font-style: ${(props: TItemList): string => (props.discount ? 'italic' : 'normal')};
`;

export const StyledTotalPrice = styled.dl<{
  pad?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  padding: 0 0 ${spacing.xs} 0;
  padding-bottom: ${(props: TItemList): unknown => (props.pad !== false ? spacing.xs : 0)};
  @media (min-width: ${BREAKPOINTS.tablet}px) {
    ${(props: TItemList): unknown => (props.highlight ? fontStyles.xsHeading : fontStyles.xsText)};
  }
  @media (max-width: ${BREAKPOINTS.tablet - 1}px) {
    ${fontStyles.xsText}
  }
`;

export const StyledGuidePriceLink = styled(Link)`
  font-weight: initial;
  font-size: ${fontSize.xs};
  line-height: ${lineHeight.xlHeading};
  display: block;
  padding: ${spacing.xx} 0 ${spacing.sm} 0;
`;

export const StyledValueMessageContainer = styled.div`
  margin-bottom: ${spacing.sm};
`;

export const StyledTotal = styled.dl`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${spacing.sm};
  ${fontStyles.smHeading};
`;

export const StyledHiddenOnMobile = styled.div`
  @media (max-width: ${BREAKPOINTS.tablet - 1}px) {
    display: none;
  }
`;

export const HiddenOnTabletDesktop = styled.div`
  @media (min-width: ${BREAKPOINTS.tablet}px) {
    display: none;
  }
`;

// This is for the prices to fit on one line on tablet viewport
const sharedStyle = css`
  min-width: 80px;
  text-align: right;
`;

export const StyledDD = styled.dd`
  ${sharedStyle}
`;

export const StyledDT = styled.dt`
  display: flex;
  flex-direction: row;
  div {
    margin-right: ${spacing.xx};
  }
  @media (min-width: ${breakpoints.tablet}px) and (max-width: ${breakpoints.tabletLarge}px) {
    flex-direction: column;
    div:last-child {
      margin-left: 0;
    }
  }
`;

export const StyledValue = styled.span`
  ${sharedStyle}
`;

export const StyledDeliveryMessage = styled.span`
  text-align: right;
  max-width: 100px;
  word-break: normal;
  display: block;
`;

export const AccordionContentContainer = styled.div`
  display: ${(props: { isOpen: boolean }): string | boolean => !props.isOpen && 'none'};
`;

export const SpinnerWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 1;
`;
