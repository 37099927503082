import { COMPETITORS } from './competitors';
import { ADDITIONAL_INFO } from './additional-info';

export const RECOMMENDER_ITEM_VALUE = `fragment RecommenderItem on ProductInterface {
  typename: __typename
  ... on ProductType {
    context {
      type
      ... on ProductContextOfferType {
        linkTo
        offerType
      }
    }
  }
  ... on MPProduct {
    context {
      type
      ... on ProductContextOfferType {
        linkTo
        offerType
      }
    }
  }
  id
  modelMetadata{
    name
    version
  }
  gtin
  adId
  baseProductId
  title
  brandName
  shortDescription
  defaultImageUrl
  superDepartmentId
  superDepartmentName
  departmentId
  departmentName
  aisleId
  aisleName
  shelfId
  shelfName
  displayType
  productType
  averageWeight
  bulkBuyLimit
  maxQuantityAllowed: bulkBuyLimit
  groupBulkBuyLimit
  bulkBuyLimitMessage
  bulkBuyLimitGroupId
  timeRestrictedDelivery
  restrictedDelivery
  isForSale
  isInFavourites
  isNew
  isRestrictedOrderAmendment
  status
  maxWeight
  minWeight
  increment
  details {
    components {
      ...Competitors
      ...AdditionalInfo
    }
  }
  catchWeightList{
    price
    weight
  }
  price {
    price: actual
    unitPrice
    unitOfMeasure
  }
  foodIcons @include(if: $includeDietaryIcons)
  reviews @include(if: $includeReviews) {
    stats {
      noOfReviews
      overallRating
      overallRatingRange
    }
  }
  charges { 
    ... on ProductDepositReturnCharge { 
     __typename
     amount
    }
  }
  promotions {
    promotionId: id
    promotionType
    startDate
    endDate
    unitSellingInfo
    offerText: description
    price {
      beforeDiscount
      afterDiscount
    }
    attributes
  }
  restrictions(
    startDateTime:$startDateTime
    endDateTime:$endDateTime
    basketItems:$basketItems
  ) {
    type
    isViolated
    message
  }
}
${COMPETITORS}
${ADDITIONAL_INFO}`;

export const RECOMMENDER_ITEM_KEY = `productItems: products {
  ...RecommenderItem
}`;
