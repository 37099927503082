import React, { useEffect, useState } from 'react';
import { compose } from 'react-recompose';
import { ConnectedProps } from 'react-redux';
import { Moment } from 'moment-timezone';
import analyticsBus from '#/analytics/analyticsBus';
import { basicEvent } from '#/analytics/types/basic';
import { DELAY, LHN, SIDEBAR } from '#/analytics/constants';
import helpers from '#/lib/decorators/helpers';
import { Slot } from '#/lib/records/slot.defs';
import { TConfigFunc, TTranslateFunc } from '#/lib/records/helpers.defs';
import { getSlotReservationExpiry, getOnDemandDynamicDeliveryTime, getFulfilmentEstimatedTime } from '#/selectors/slot';
import {
  getBasketMaxItemCountLimit,
  getCharges,
  getCurrentValidSlot,
  getGuidePrice,
  getHasBasketBreachedByVolumeOrWeight,
} from '#/selectors/trolley';
import { isBooked } from '#/selectors/slot/slot-record';
import { hasValidSlot } from '#/lib/trolley/trolley-utils';
import BeansSlotDetails from './beans-slot-details';
import { ContentRow, CountdownTimerWrapper, TextSpan } from './styled';
import { getLanguage, getTimezone } from '#/reducers/app';
import { getVariant } from '#/components/context-cards/slot/beans-slot-details/helper';
import { connect } from '#/lib/render/connect-deep-compare';
import { isSlotExpiring } from '#/lib/date-helpers';
import { getOnDemandDynamicDeliveryData } from '#/lib/shopping-method-util';
import CountdownTimer from '#/components/shared/countdown-timer';
import { CALLBACK_KEYS } from '#/components/shared/countdown-timer/callback-keys';
import { INFO, WARNING, ERROR } from '#/constants/notification-variants';
import { ShoppingMethod } from '#/constants/shopping-methods';
import { DynamicDeliveryTime } from '#/custom-typings/redux-store/slot.defs';
import BasketSlotContextCardSlotBooked from '#/components/trolley/full-trolley/basket-slot-card/slot-booked';
import { getCountryTranslation } from '#/i18n/helpers';
import { IntlProvider, MessageFormatElement } from 'react-intl';
import { openModal } from '#/actions/ui-action-creators';

type OwnState = {
  isExpiring: boolean;
  isSlotBookedProp: boolean;
  slotExpiry?: string | Moment | null;
  minutesBeforeExpiry: number;
  onDemandDynamicDeliveryTime: DynamicDeliveryTime;
  fulfilmentEstimatedArrivalTime: DynamicDeliveryTime;
  timeLeft: number;
  guidePrice: number;
  language: string;
  charges: {
    minimumBasketValue?: number;
    surcharge?: number;
  };
  isValidSlot: boolean;
  hasBasketBreachedByVolumeOrWeight: boolean;
  constraints: {
    maxItemCount: number;
  };
};

type OwnProps = {
  shoppingMethod: ShoppingMethod;
  slot: Slot;
  slotsLanguageLink: string;
  amountChanging: number;
  isLHN: boolean;
  displayOnFullBasket: boolean;
  status: string;
};

type HelperProps = {
  t: TTranslateFunc;
  c: TConfigFunc;
};

type Props = OwnProps & HelperProps & ConnectedProps<typeof connector>;

const mapStateToProps = (state: Store, { slot, c: config }: { slot: Slot; c: TConfigFunc }): OwnState => {
  const language = getLanguage(state);
  const timezone = getTimezone(state);
  const isSlotBookedProp = isBooked(slot);

  const minutesBeforeExpiry = config('ondemand:minutesBeforeSlotExpiryWarning') as number;

  const { isExpiring, timeLeft } = isSlotExpiring(slot.reservationExpiry, minutesBeforeExpiry, timezone, language);

  const slotExpiry = getSlotReservationExpiry(slot);

  return {
    isExpiring: isSlotBookedProp && isExpiring,
    isSlotBookedProp,
    slotExpiry,
    timeLeft,
    minutesBeforeExpiry,
    onDemandDynamicDeliveryTime: getOnDemandDynamicDeliveryTime(state),
    fulfilmentEstimatedArrivalTime: getFulfilmentEstimatedTime(state),
    guidePrice: getGuidePrice(state),
    language,
    charges: getCharges(state),
    hasBasketBreachedByVolumeOrWeight: getHasBasketBreachedByVolumeOrWeight(state),
    constraints: { maxItemCount: getBasketMaxItemCountLimit(state) },
    isValidSlot: hasValidSlot(getCurrentValidSlot(state)),
  };
};

const connector = connect(mapStateToProps, { openModal });

export type VariantType = typeof INFO | typeof WARNING | typeof ERROR;

export const OnDemandSlotDetails: React.FC<Props> = props => {
  const {
    isExpiring,
    isSlotBookedProp,
    shoppingMethod,
    t: translate,
    slotExpiry,
    minutesBeforeExpiry,
    amountChanging,
    onDemandDynamicDeliveryTime,
    fulfilmentEstimatedArrivalTime,
    slotsLanguageLink,
    guidePrice,
    language,
    isLHN,
    timeLeft,
    constraints,
    charges,
    hasBasketBreachedByVolumeOrWeight,
    isValidSlot,
    displayOnFullBasket,
  } = props;

  const defaultVariant = timeLeft > 0 ? getVariant(isSlotBookedProp, isExpiring) : ERROR;

  const [variant, setVariant] = useState<VariantType>(defaultVariant);

  const [isSlotBooked, setSlotBooked] = useState(isSlotBookedProp);

  const { isDynamicDeliveryTimeAvailable } = onDemandDynamicDeliveryTime;

  const dynamicDeliveryTimeObj = isDynamicDeliveryTimeAvailable
    ? onDemandDynamicDeliveryTime
    : fulfilmentEstimatedArrivalTime;

  const { onDemandDynamicDeliverySuffix, unit, min, max } = getOnDemandDynamicDeliveryData(
    true,
    true,
    dynamicDeliveryTimeObj,
  );

  const slotBooked = isSlotBooked && timeLeft > 0;

  const onDemandToCheckoutText = translate(`slots:${shoppingMethod}.checkout-in`);

  useEffect(() => {
    if (variant === ERROR && !isSlotBooked && isSlotBookedProp) {
      if (timeLeft > 0) {
        setSlotBooked(isSlotBookedProp);
        setVariant(getVariant(isSlotBookedProp, isExpiring));
      } else {
        setSlotBooked(false);
      }
    }
  }, [variant, timeLeft, isExpiring, isSlotBookedProp]);

  const link = slotBooked
    ? displayOnFullBasket
      ? translate(`common:slot-context-card.change-your-slot`)
      : translate(`slots:${shoppingMethod}.change-your-slot`)
    : translate(`slots:${shoppingMethod}.book-a-slot`);

  const getHeader = (): string => {
    const header = `slots:${shoppingMethod}.booked-static-header`;
    if (slotBooked || displayOnFullBasket) {
      if (onDemandDynamicDeliverySuffix) {
        return translate(`${header}${onDemandDynamicDeliverySuffix}`, {
          unit,
          min,
          max,
        });
      }
      return translate(header);
    }
    return translate(`slots:${shoppingMethod}.slot-expired-title`);
  };

  const zeroRemainingCB = (): void => {
    setVariant(ERROR);
    setSlotBooked(false);
  };

  const getShoppingMethodText = (): string => {
    if (displayOnFullBasket) {
      if (slotBooked) {
        return translate(`common:slot-context-card.${shoppingMethod}`);
      } else {
        return translate('common:slot-context-card.book-new-slot');
      }
    }
    return translate(`common:shopping-method_${shoppingMethod}`);
  };

  const customCallbacks = [
    {
      cbKey: CALLBACK_KEYS.ONDEMAND_MINIBASKET_5MINS,
      cb: (): void => setVariant(WARNING),
      customTime: minutesBeforeExpiry * 60,
    },
  ];

  const localisation = {
    currency: { iso: 'GBP' },
    locale: 'en-GB',
  };

  const getRemainingTimeToCheckout = (
    slotExpiry: Moment | string | null | undefined,
    onDemandToCheckoutText: string,
  ): JSX.Element => {
    return (
      <>
        <span> {onDemandToCheckoutText}</span>
        <CountdownTimer
          countdownEnd={slotExpiry}
          cbKey={CALLBACK_KEYS.ONDEMAND_ZERO_REMAINING}
          cb={zeroRemainingCB}
          customcb={customCallbacks}
        />
      </>
    );
  };

  const getRemainingTimeToCheckoutFullBasket = (
    slotExpiry: Moment | string | null | undefined,
    onDemandToCheckoutText: string,
  ): JSX.Element => {
    return (
      <>
        {onDemandToCheckoutText}
        <CountdownTimerWrapper>
          <CountdownTimer
            countdownEnd={slotExpiry}
            cbKey={CALLBACK_KEYS.ONDEMAND_ZERO_REMAINING}
            cb={zeroRemainingCB}
            customcb={customCallbacks}
          />
        </CountdownTimerWrapper>
      </>
    );
  };

  const emitSlotAnalyticsEvent = (): void => {
    basicEvent(analyticsBus, {
      action: DELAY,
      type: isLHN ? LHN : SIDEBAR,
      value: slotBooked ? `${shoppingMethod}:change slot` : `${shoppingMethod}:expired rebook`,
    });
  };

  const text = slotBooked
    ? displayOnFullBasket
      ? getRemainingTimeToCheckoutFullBasket(slotExpiry, onDemandToCheckoutText)
      : getRemainingTimeToCheckout(slotExpiry, onDemandToCheckoutText)
    : translate('context-cards:slot-expired-full-trolley');

  const header = getHeader();
  const countryTranslation = getCountryTranslation(language) as
    | Record<string, string>
    | Record<string, MessageFormatElement[]>
    | undefined;

  const shoppingMethodText = translate(`slots:${shoppingMethod}.title`);

  return displayOnFullBasket ? (
    <IntlProvider messages={countryTranslation} locale={language}>
      <BasketSlotContextCardSlotBooked
        buttonLink={slotsLanguageLink}
        buttonText={link}
        header={header}
        shoppingMethodText={getShoppingMethodText()}
        slotMessage={text}
        variant={variant}
        guidePrice={guidePrice}
        language={language}
        amountChanging={amountChanging}
        charges={charges}
        localisation={localisation}
        hasBasketBreachedByVolumeOrWeight={hasBasketBreachedByVolumeOrWeight}
        constraints={constraints}
        shoppingMethod={shoppingMethod}
        isValidSlot={isValidSlot}
      />
    </IntlProvider>
  ) : (
    <BeansSlotDetails header={header} variant={variant} link={link} onClickHandler={emitSlotAnalyticsEvent}>
      <ContentRow error={!slotBooked} forwardedAs="p">
        {shoppingMethodText}
      </ContentRow>
      <ContentRow small forwardedAs="p">
        <TextSpan>{text}</TextSpan>
      </ContentRow>
    </BeansSlotDetails>
  );
};

const enhance = compose<Props, OwnProps>(helpers(['t', 'c']), connector);

export default enhance(OnDemandSlotDetails);
