import { UPDATE_EXPERIMENTS } from '../constants/action-types';
import { firstTimeShopper } from '#/constants/experiments';
import { getIsFirstTimeShopper, getCID } from '#/reducers/user';

// TODO: Removing this unused import is somehow breaking tests due to circular module references in the slots selectors. Fixing this would be out of scope for the experiment cleanup.
import { getIsShowOnceBuyListModalOpen } from '#/reducers/ui'; // eslint-disable-line @typescript-eslint/no-unused-vars

import { getVariationForExperiment } from '#/lib/optimizely-manager';
import { getCookieValue } from '#/lib/cookie/cookie-utils';

export default (state = {}, action) => {
  switch (action.type) {
    case UPDATE_EXPERIMENTS:
      return action.value || state;
    default:
      return state;
  }
};

export const getDefaultStateFromProps = props => {
  return props.experiments || {};
};

export const getOptimizelyData = ({ experiments }) => experiments;

export const getFirstTimeShopperTooltipVariant = state => {
  if (typeof window === 'undefined' || !getIsFirstTimeShopper(state)) {
    return null;
  }
  const customerId = getCID(state);
  if (getCookieValue(firstTimeShopper.getCookieKey(customerId))) {
    return null;
  }

  const variation = getVariationForExperiment(
    state,
    firstTimeShopper.key,
    {},
    firstTimeShopper.useCustomerId
  );

  return firstTimeShopper.getMessageType(variation);
};
