import React from 'react';
import PropTypes from 'prop-types';
import { Footnote } from '@beans/typography';
import { SectionMessage } from '#/components/cms-driven';
import helpers from '#/lib/decorators/helpers';
import { FulfilmentStepsContainer, StyledStepIcon, StepsContainer, IconStamp, IconContainer } from './styled';

type HelperProps = {
  t: (key: string, options?: { [key: string]: string }) => string;
};

export type TProps = HelperProps;

type stepsKeys = {
  step: number;
  icon: string;
};

const COLLECTION_STEPS: stepsKeys[] = [
  {
    step: 1,
    icon: 'pickupTime',
  },
  {
    step: 2,
    icon: 'basket',
  },
  {
    step: 3,
    icon: 'helpingHand',
  },
  {
    step: 4,
    icon: 'ccInfo',
  },
];
const HowItWorksSectionCollectionTab = (props: TProps): JSX.Element => {
  const { t: translate } = props;

  const sectionTitle = translate('slots:collection.how-it-works.heading');
  const fulfilmentSteps = COLLECTION_STEPS.map(({ step, icon }, index) => {
    const text = translate(`slots:collection.how-it-works.step-${step}`);
    return (
      <IconStamp key={index}>
        <IconContainer>
          <StyledStepIcon icon={icon} />
        </IconContainer>
        <div>
          <Footnote>{text}</Footnote>
        </div>
      </IconStamp>
    );
  });

  return (
    <>
      <SectionMessage message={sectionTitle} size="large" />
      <FulfilmentStepsContainer>
        <StepsContainer>{fulfilmentSteps}</StepsContainer>
      </FulfilmentStepsContainer>
    </>
  );
};

HowItWorksSectionCollectionTab.propTypes = {
  t: PropTypes.func.isRequired,
};

export default helpers(['t'])(HowItWorksSectionCollectionTab);
