/**
 * Experiment constants for promotion date display feature experiment
 */
export const promotionDateDisplayFeature = {
  key: 'oop892',
  variants: {
    default: '892-a',
    showActivePromotionsWithDateRanges: '892-b',
    showActivePromotionsWithoutDateRanges: '892-c',
  },
  useCustomerId: true,
};
