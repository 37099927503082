import { SUPERDEPARTMENT, AISLE, AISLES, DEPARTMENT } from '#/constants/taxonomy-levels';
import { NavList, NavItem } from 'custom-typings/redux-store/taxonomy.defs';
import { sessionStore } from '#/lib/data-store/client-store';

export const SELECTED_TAXONOMY = 'selectedTaxonomy';

type NewTaxonomy = {
  getCategoryChildren: Function;
  getCategoryByName: Function;
  getCategoryByUrl: Function;
  getCategoryByCatId: Function;
  length?: Function;
  pop?: Function;
};

type Categories = NavList | {};

type ConstructMapValues = {
  [itemId: string]: string;
};

type ConstructMaps = {
  nameMap: ConstructMapValues;
  urlMap: ConstructMapValues;
};

function constructMaps(categories: Categories): ConstructMaps {
  return Object.keys(categories).reduce(
    (acc, catId) => {
      const { name, url } = categories[catId];

      acc.nameMap[name] = catId;
      acc.urlMap[url] = catId;

      return acc;
    },
    { nameMap: {}, urlMap: {} },
  );
}

export function newTaxonomy(categories: Categories): NewTaxonomy {
  const { nameMap, urlMap } = constructMaps(categories);

  const getCategoryByCatId = (catId): NavItem => categories[catId];

  const getCategoryByUrl = (url): NavItem => {
    const catId = urlMap[url];

    return getCategoryByCatId(catId);
  };

  const getCategoryByName = (name): NavItem => {
    const catId = nameMap[name];

    return getCategoryByCatId(catId);
  };

  const getCategoryChildren = (id): Categories => {
    const cat = getCategoryByCatId(id);

    if (!cat) {
      throw new Error(`Unknown catId: "${id}"`);
    }

    return (cat.children || []).map(getCategoryByCatId);
  };

  return Object.freeze({
    getCategoryChildren,
    getCategoryByName,
    getCategoryByUrl,
    getCategoryByCatId,
  });
}

export function newTaxonomyFromTree(roots: NavList = []): NewTaxonomy {
  const result = {};
  const stack: NavList = [...roots];
  let length = roots.length;

  while (length > 0) {
    const item = stack.shift();
    --length;
    if (item) {
      result[item.catId] = {
        ...item,
        children: (item.children || []).map(c => c.catId),
      };

      if (item.children) {
        length = stack.unshift(...item.children);
      }
    }
  }

  return newTaxonomy(result);
}

export function stripNav(nav: NavList = []): NavList {
  return [...nav].map(navItem => {
    delete navItem.parent;

    if (navItem.children) {
      // We can delete the children at aisle level as we know we wont require this info when building the menus.
      // This would need to change if ever adding additional levels, but offers enough of a benefit to include here now
      // `aisle` in UK - `aisles` in IGHS
      if (navItem.label === AISLE || navItem.label === AISLES) {
        delete navItem.children;
      } else {
        navItem.children = stripNav(navItem.children);
      }
    }

    return navItem;
  });
}

export function isBrowsable(type: string, children: NavList): boolean {
  return type !== SUPERDEPARTMENT && !children?.length;
}

export function isExternalLink(url: string): boolean {
  return !!url.match(/^((?!groceries).)*$/gm);
}

export async function storeSelectedTaxonomy(menuType: string, id: string): Promise<void> {
  if (!sessionStorage || !menuType) {
    return;
  }

  const currentState = (menuType !== SUPERDEPARTMENT && sessionStore?.get(SELECTED_TAXONOMY)) || {};
  const updatedStorage = {
    ...currentState,
    ...(menuType === DEPARTMENT && { [AISLE]: null }),
    [menuType]: id,
  };

  sessionStore?.set(SELECTED_TAXONOMY, JSON.stringify(updatedStorage));
}
