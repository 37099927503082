import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TabHeader from './tab-header';
import helpers from '#/lib/decorators/helpers';
import { BaseElement, scrollableHorizontal } from '@ddsweb/foundation';
import styled from 'styled-components';
/**
 * Represents a tab control that has one tab active at a time.
 *
 * Takes multiple TabItem components as children and the one with `isActive`
 * set to true is displayed as the content.
 *
 * If `headerClassName` is supplied then this is forwarded to each TabHeader.
 */

export const ScrollableContainer = styled(BaseElement)`
  ${props => props.showIcons && `${scrollableHorizontal}`}
`;

@helpers(['c'])
class Tabs extends React.Component {
  static propTypes = {
    c: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    displayTabContent: PropTypes.bool,
    headerClassName: PropTypes.string,
    shouldDisplayOop761TabheaderVariation: PropTypes.bool,
    showIcons: PropTypes.bool,
    tabAriaLabel: PropTypes.string
  };

  static defaultProps = {
    className: '',
    displayTabContent: true,
    headerClassName: '',
    shouldDisplayOop761TabheaderVariation: false
  };

  _getActiveTab() {
    return React.Children.toArray(this.props.children).find(
      child => child.props.isActive
    );
  }

  _getTabHeaders() {
    const {
      children,
      displayTabContent,
      showIcons,
      shouldDisplayOop761TabheaderVariation
    } = this.props;
    return React.Children.toArray(children)
      .filter(item => !item.props.isHidden)
      .map((item, i) => {
        const headerItem = displayTabContent ? item.props.header : item;

        return (
          <TabHeader
            data-testid="week-selector-tab-header-synth"
            className={item.props.headerClassName}
            isActive={item.props.isActive}
            key={i}
            showTabHeaderStyles={showIcons}
            shouldDisplayOop761TabheaderVariation={
              shouldDisplayOop761TabheaderVariation
            }
          >
            {headerItem}
          </TabHeader>
        );
      });
  }

  render() {
    const {
      className,
      c: config,
      displayTabContent,
      showIcons,
      tabAriaLabel
    } = this.props;
    const activeChild = this._getActiveTab();
    const tabPanelAttributes = activeChild
      ? activeChild.props.tabPanelAttributes
      : null;
    return (
      <div className={`tabs ${className}`}>
        <ScrollableContainer showIcons={showIcons}>
          <ul
            role="tablist"
            className="tabs-header-container"
            aria-label={tabAriaLabel}
          >
            {this._getTabHeaders()}
          </ul>
        </ScrollableContainer>
        {displayTabContent && (
          <div
            role="tabpanel"
            className={classNames('tabs__content', {
              'medium-padding-bottom': config('slots:showAboutOurChargesLink')
            })}
            {...tabPanelAttributes}
          >
            {this._getActiveTab()}
          </div>
        )}
      </div>
    );
  }
}

export default Tabs;
