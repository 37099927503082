import { removeAllFiltersUrl } from './filter-helpers';
import { updateParamsInUrl } from './url/url-utils';
import { FAVORITES_TAB } from '#/constants/favorites';

export function createUrlForNewTab(currentUrl, tab) {
  const params = [
    {
      parameter: 'tab',
      value: tab
    },
    {
      parameter: 'id',
      value: null
    },
    {
      parameter: 'page',
      value: null
    },
    {
      parameter: 'offset',
      value: null
    }
  ];

  return removeAllFiltersUrl(
    params.reduce((url, param) => {
      return updateParamsInUrl(url, { [param.parameter]: param.value });
    }, currentUrl)
  );
}

export const isOnFavouritesPage = currentPage =>
  currentPage?.indexOf('favorites') > -1;

export const isSelectedTabFavorites = (currentPage = '') => {
  if (!isOnFavouritesPage(currentPage)) {
    return false;
  }

  return currentPage.includes('tab')
    ? currentPage.includes(`tab=${FAVORITES_TAB}`)
    : true;
};
