import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Link from '../link-check-spa';
import { basicEvent } from '#/analytics/types/basic';
import analyticsBus from '#/analytics/analyticsBus';
import Spinner from './spinner';
import { TextButtonLink } from '@ddsweb/link';
import { ReferrerPolicies } from '#/constants/referrer-policy';

export default class LinkButtonWithFeedback extends Component {
  static propTypes = {
    bref: PropTypes.string,
    buttonStyles: PropTypes.string,
    ddl: PropTypes.bool,
    disabled: PropTypes.bool,
    enableSpinner: PropTypes.bool,
    href: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    primaryText: PropTypes.string,
    referrerPolicy: PropTypes.string,
    submitting: PropTypes.bool
  };

  static defaultProps = {
    bref: null,
    buttonStyles: 'button button-primary',
    disabled: false,
    primaryText: 'Loading...',
    submitting: false,
    referrerPolicy: ReferrerPolicies.STRICT_ORIGIN_WHEN_CROSS_ORIGIN
  };

  constructor(props, state) {
    super(props, state);

    this.state = {
      submitting: false
    };
  }

  handleClick = e => {
    this.setState({
      submitting: true
    });
    this.dispatchAnalytics();

    if (this.props.onClick) {
      this.props.onClick(e);
    }
  };

  dispatchAnalytics() {
    if (this.props.bref) {
      basicEvent(analyticsBus, {
        type: 'basicLink',
        value: this.props.bref
      });
    }
  }

  render() {
    const {
      ddl,
      href,
      bref,
      primaryText,
      disabled,
      enableSpinner,
      className,
      referrerPolicy
    } = this.props;
    const { submitting } = this.state;

    if (ddl) {
      return (
        <TextButtonLink
          stretch
          className={className}
          onClick={this.handleClick}
          href={href}
          data-bref={bref}
          disabled={submitting || disabled}
          referrerPolicy={referrerPolicy}
        >
          {primaryText}
        </TextButtonLink>
      );
    }
    return (
      <div className={`button-with-feedback ${className}`}>
        {!disabled ? (
          <Link
            onClick={this.handleClick}
            to={href}
            data-bref={bref}
            className={classnames('button', 'button-primary', {
              'button-disabled': submitting || disabled
            })}
            referrerPolicy={referrerPolicy}
          >
            {primaryText}
          </Link>
        ) : (
          <div
            className={classnames('button', 'button-primary', {
              'button-disabled': submitting || disabled
            })}
          >
            {primaryText}
          </div>
        )}
        {enableSpinner && <Spinner showSpinner={submitting} />}
      </div>
    );
  }
}
