import React, { useMemo } from 'react';
import { StyledAppBar } from '../utility-header';
import helpers from '#/lib/decorators/helpers';
import {
  getCsrfToken,
  getCutsMustard,
  getDeviceType,
  getLanguage,
  getLanguageLink,
  getMyAccountUrl,
  getTraceId,
} from '#/reducers/app';
import { connect } from '#/lib/render/connect-deep-compare';
import {
  getIsFirstTimeShopper,
  getIsUserAuthenticated,
  getUserHashedUId,
  getUserStoreId,
  getUserUuid,
} from '#/reducers/user';
import { getIsSlotNotifyMeVariant } from '#/experiments/oop-2034/selectors';
import { getShowFavNavLinkTooltip } from '#/experiments/oop-1263/selectors';
import { getExperienceCookieAccepted } from '#/reducers/ui';
import { AppBarConfig, getAppbarMenuItems } from './utils';
import { appBarStyles } from './styled';
import { TConfigFunc, TTranslateFunc } from '#/lib/records/helpers.defs';

type StoreProps = {
  ordersUrl: string;
  isUserAuthenticated: boolean;
  language: string;
  cutsMustard: boolean;
  deviceType: string;
  isFirstTimeShopper: boolean;
  storeId: string;
  hashedUId: string;
  traceId: string;
  accountUrl: string;
  logoutUrl: string;
  shouldSetInterestedInSlotNotifyMeStorage: boolean;
  shouldSetFavNavTooltipStorage: boolean;
  uuid: string;
  csrfToken: string;
  isExperienceCookieAccepted: boolean;
};
export type Props = StoreProps & {
  c: TConfigFunc;
  t: TTranslateFunc;
};
const AppBar = (props: Props): JSX.Element => {
  const {
    c: config,
    t,
    ordersUrl,
    isUserAuthenticated,
    language,
    cutsMustard,
    deviceType,
    isFirstTimeShopper,
    storeId,
    hashedUId,
    traceId,
    accountUrl,
    logoutUrl,
    shouldSetInterestedInSlotNotifyMeStorage,
    shouldSetFavNavTooltipStorage,
    uuid,
    csrfToken,
    isExperienceCookieAccepted,
  } = props;

  const appBar = config('appBar') as AppBarConfig[];
  const menuItems = useMemo(() => {
    return getAppbarMenuItems({
      appBar,
      isUserAuthenticated,
      isExperienceCookieAccepted,
      ordersUrl,
      translate: t,
      config,
      accountUrl,
      logoutUrl,
      csrfToken,
      shouldSetFavNavTooltipStorage,
      shouldSetInterestedInSlotNotifyMeStorage,
      uuid,
      cutsMustard,
      deviceType,
      isFirstTimeShopper,
      storeId,
      hashedUId,
      language,
      traceId,
    });
  }, [
    t,
    config,
    appBar,
    isUserAuthenticated,
    isExperienceCookieAccepted,
    ordersUrl,
    accountUrl,
    logoutUrl,
    csrfToken,
    shouldSetFavNavTooltipStorage,
    shouldSetInterestedInSlotNotifyMeStorage,
    uuid,
    cutsMustard,
    deviceType,
    isFirstTimeShopper,
    storeId,
    hashedUId,
    language,
    traceId,
  ]);

  return (
    <>
      <StyledAppBar
        data-testid="app-bar"
        menu={menuItems}
        styles={appBarStyles}
        className="app-bar"
        //TODO - To be reverted once 1512px max-width support is done by other teams
        //  fixedFrom={'xl'}
      />
    </>
  );
};

const mapStateToProps = (state: Store, ownProps: Props): StoreProps => {
  return {
    ordersUrl: getLanguageLink(state, '/orders'),
    isUserAuthenticated: getIsUserAuthenticated(state),
    language: getLanguage(state),
    cutsMustard: getCutsMustard(state),
    deviceType: getDeviceType(state),
    isFirstTimeShopper: getIsFirstTimeShopper(state),
    storeId: getUserStoreId(state),
    hashedUId: getUserHashedUId(state),
    traceId: getTraceId(state),
    accountUrl: getMyAccountUrl(state, ownProps.c('externalMyAccountUrl')),
    logoutUrl: getLanguageLink(state, '/logout'),
    shouldSetInterestedInSlotNotifyMeStorage: getIsSlotNotifyMeVariant(state),
    shouldSetFavNavTooltipStorage: getShowFavNavLinkTooltip(state),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    csrfToken: getCsrfToken(state as any),
    uuid: getUserUuid(state),
    isExperienceCookieAccepted: getExperienceCookieAccepted(state),
  };
};

export default helpers(['t', 'c'])(connect(mapStateToProps)(AppBar));
