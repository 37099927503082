import styled from 'styled-components';
import { spacing } from '@ddsweb/selectors';
import { Text } from '@ddsweb/text';

export const DarkGreyBodyText = styled(Text)`
  && {
    padding-top: 15px;
    padding-left: ${spacing.xs};
    font-weight: bold;
  }
`;
