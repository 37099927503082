import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import helpers from '#/lib/decorators/helpers';
import { connect } from '#/lib/render/connect-deep-compare';
import { addModalToUrl } from '#/lib/url/modal-utils';
import { getLocalizedTimeFactory } from '#/lib/time';
import { TABLET } from '#/constants/platforms';
import { ECOUPON_MODAL } from '#/constants/modal-names';
import {
  closeLeftNav,
  openModal,
  openNavMenu
} from '#/actions/ui-action-creators';
import { selectTaxonomyItem } from '#/actions/taxonomy-action-creators';
import {
  NO_SLOT_BOOKED,
  SLOT_BOOKED_EMPTY,
  SLOT_BOOKED_FULL,
  SLOT_EXPIRED,
  SLOT_EXPIRY_WARNING,
  AMEND_MODE,
  PENDING_ORDER,
  RESERVED,
  EXPIRED,
  UNAVAILABLE
} from './constants';
import {
  getCurrentUrl,
  getIsCurrentPage,
  getLanguage,
  getTimezone,
  getLanguageLink
} from '#/reducers/app';
import {
  getIsAmendBasket,
  getItemsCount,
  getSlot,
  getCurrentValidSlot,
  getTrolleyShoppingMethod,
  isOnlyCollectionAvailable,
  getUpdatingCatchWeightItem,
  getTrolleyStateAndPendingQty
} from '#/selectors/trolley';
import { getQuantity } from '#/selectors/item';
import { getHasPendingOrder } from '#/selectors/order-list-details'; // This legacy usage is to support the pages that are not yet migrated (eg. BookASlot page)
import { getNavList } from '#/reducers/taxonomy';
import { getDefaultSlotsPath } from '#/reducers/slot';
import NotBooked from '#/components/context-cards/slot/no-slot-booked';
import Booked from '#/components/context-cards/slot/slot-booked';
import { SUPERDEPARTMENT } from '#/constants/taxonomy-levels';
import analyticsBus from '#/analytics/analyticsBus';
import { basicEvent } from '#/analytics/types/basic';
import {
  BOOK_A_SLOT,
  BOOK_ANOTHER_SLOT,
  DELAY,
  TROLLEY
} from '#/analytics/constants';

const mapStateToProps = (state, { f: feature }) => {
  const timezone = getTimezone(state);
  const language = getLanguage(state);
  const currentUrl = getCurrentUrl(state);
  const standardizeTime = getLocalizedTimeFactory(timezone, language);
  const slotsLanguageLink = getLanguageLink(
    state,
    getDefaultSlotsPath(feature)(state)
  );
  const shopLanguageLink = getLanguageLink(state, `/shop`);
  const eCouponLanguageLink = getLanguageLink(
    state,
    addModalToUrl(currentUrl, ECOUPON_MODAL)
  );
  const isFDOXMessagingEnabled = feature('showFDOXMessaging');
  const showAmendChangeSlot = feature('allowAmendSlot');
  const showAmendAddItems = showAmendChangeSlot && /\/slots/.test(currentUrl);
  const hasPendingOrder = getHasPendingOrder(state);
  const isOnlyCollection = isOnlyCollectionAvailable(state);
  const updatingCatchWeightItem = getUpdatingCatchWeightItem(state);
  const amountChanging = updatingCatchWeightItem
    ? getQuantity(updatingCatchWeightItem)
    : getTrolleyStateAndPendingQty(state).pendingQty;

  return {
    bookedSlot: getCurrentValidSlot(state),
    standardizeTime,
    currentUrl,
    isAmendBasket: getIsAmendBasket(state),
    slotsLanguageLink,
    shopLanguageLink,
    eCouponLanguageLink,
    itemsCount: getItemsCount(state),
    showBookAnotherSlot:
      !getIsAmendBasket(state) && // the basket is NOT in amend mode
      getIsCurrentPage(state, ['']) &&
      hasPendingOrder && // there's a recent pending order
      !getItemsCount(state) && // there are no items in the trolley
      !getSlot(state), // there is no booked slot
    shoppingMethod: getTrolleyShoppingMethod(state),
    showAmendAddItems,
    showAmendChangeSlot,
    taxonomy: getNavList(state),
    isFDOXMessagingEnabled,
    collectionSlotPageLink: getLanguageLink(state, '/slots/collection'),
    isOnlyCollection,
    amountChanging,
    hasPendingOrder
  };
};

@helpers(['c', 'f', 't', 'l'])
@connect(mapStateToProps, {
  closeLeftNav,
  openModal,
  openNavMenu,
  selectTaxonomyItem
})
export default class ContextCard extends Component {
  static propTypes = {
    bookedSlot: PropTypes.object,
    c: PropTypes.func.isRequired,
    closeLeftNav: PropTypes.func.isRequired,
    collectionSlotPageLink: PropTypes.string.isRequired,
    currentUrl: PropTypes.string.isRequired,
    displayOnFullBasket: PropTypes.bool,
    eCouponLanguageLink: PropTypes.string,
    expandable: PropTypes.bool,
    f: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired, // to ensure multiple instances don't conflict
    isAmendBasket: PropTypes.bool.isRequired,
    isAuthenticated: PropTypes.bool,
    hasPendingOrder: PropTypes.bool,
    isFDOXMessagingEnabled: PropTypes.bool.isRequired,
    isLHN: PropTypes.bool,
    isOnlyCollection: PropTypes.bool,
    itemsCount: PropTypes.number.isRequired,
    l: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    openNavMenu: PropTypes.func.isRequired,
    selectTaxonomyItem: PropTypes.func.isRequired,
    shopLanguageLink: PropTypes.string.isRequired,
    shoppingMethod: PropTypes.string,
    showAmendAddItems: PropTypes.bool,
    showAmendChangeSlot: PropTypes.bool,
    showBookAnotherSlot: PropTypes.bool.isRequired,
    showCheckoutButtonForFullTrolley: PropTypes.bool,
    slotsLanguageLink: PropTypes.string.isRequired,
    standardizeTime: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    taxonomy: PropTypes.array
  };

  static defaultProps = {
    showCheckoutButtonForFullTrolley: true,
    displayOnFullBasket: false
  };

  constructor(props) {
    super(props);
    const taxonomy = Array.isArray(props.taxonomy) ? props.taxonomy : [];
    this.taxonomyFirstItem = taxonomy.length > 0 ? taxonomy[0] : {};
  }

  getAllowAmendSlotButton() {
    const { t: translate, slotsLanguageLink, displayOnFullBasket } = this.props;
    return {
      buttonLink: slotsLanguageLink,
      buttonText: displayOnFullBasket
        ? translate('common:slot-context-card.change-your-slot')
        : translate('context-cards:change-slot'),
      onClick: this.generateClickHandler(this.emitChangeSlotAnalyticsEvent)
    };
  }

  getAmendModeAction() {
    const {
      shopLanguageLink,
      t: translate,
      c: config,
      eCouponLanguageLink,
      showAmendAddItems,
      showAmendChangeSlot
    } = this.props;

    if (showAmendAddItems) {
      // on slots page show add items
      return {
        buttonLink: shopLanguageLink,
        onClick: this.generateClickHandler(this.handleAddItems),
        buttonText: translate('context-cards:add-items')
      };
    } else if (showAmendChangeSlot) {
      // otherwise, if enabled, show amend slot
      return this.getAllowAmendSlotButton();
    } else if (config('contextCardLinkEnabled')) {
      return {
        buttonLink: eCouponLanguageLink,
        onClick: this.generateClickHandler(this.handleOpenModal),
        buttonText: translate('orders:amend.add-ecoupons-vouchers')
      };
    }

    return {
      buttonLink: eCouponLanguageLink,
      onClick: this.generateClickHandler(this.handleOpenModal),
      buttonText: translate('orders:amend.add-ecoupons-vouchers')
    };
  }

  handleOpenModal = event => {
    event.preventDefault();
    this.props.openModal(ECOUPON_MODAL);
  };

  handleAddItems = event => {
    const { openNavMenu, selectTaxonomyItem } = this.props;
    event.preventDefault();
    event.stopPropagation();
    openNavMenu();
    selectTaxonomyItem(SUPERDEPARTMENT, this.taxonomyFirstItem, TABLET);
  };

  getIntroText = () => {
    const {
      isFDOXMessagingEnabled,
      isOnlyCollection,
      t: translate
    } = this.props;
    let introTextKey = '';

    if (isFDOXMessagingEnabled && isOnlyCollection) {
      introTextKey = 'slots:fulfilment-card.not-booked.collection.intro-fdox';
    } else if (isFDOXMessagingEnabled && !isOnlyCollection) {
      introTextKey = 'slots:fulfilment-card.not-booked.intro-fdox';
    } else if (!isFDOXMessagingEnabled && isOnlyCollection) {
      introTextKey = 'slots:fulfilment-card.not-booked.collection.intro';
    } else if (!isFDOXMessagingEnabled && !isOnlyCollection) {
      introTextKey = 'slots:fulfilment-card.not-booked.intro';
    }

    return translate(introTextKey);
  };

  getCardDetails() {
    const {
      slotsLanguageLink,
      t: translate,
      isOnlyCollection,
      displayOnFullBasket
    } = this.props;

    return new Map([
      [
        NO_SLOT_BOOKED,
        {
          buttonLink: slotsLanguageLink,
          buttonText: (
            <span>
              <span className="visually-hidden">
                {translate(
                  isOnlyCollection
                    ? 'slots:fulfilment-card.not-booked.collection.cta-long'
                    : 'slots:fulfilment-card.not-booked.cta-long'
                )}
              </span>
              <span aria-hidden="true">
                {translate(
                  isOnlyCollection
                    ? 'slots:fulfilment-card.not-booked.collection.cta-long'
                    : 'slots:fulfilment-card.not-booked.cta-title'
                )}
              </span>
            </span>
          ),
          introText: this.getIntroText(),
          CardType: NotBooked,
          className: 'not-booked',
          onClick: this.generateClickHandler(this.onHandleClickBookASlot)
        }
      ],
      [
        PENDING_ORDER,
        {
          buttonLink: slotsLanguageLink,
          buttonText: translate('common:pages.book-another-slot'),
          introText: displayOnFullBasket
            ? translate('common:slot-context-card.place-multiple-order-notice')
            : translate('common:pages.multiple-order-notice'),
          CardType: NotBooked,
          className: 'not-booked',
          onClick: this.generateClickHandler(this.onHandleClickBookASlot)
        }
      ],
      [
        SLOT_BOOKED_EMPTY,
        {
          buttonLink: slotsLanguageLink,
          buttonText: displayOnFullBasket
            ? translate('common:slot-context-card.change-your-slot')
            : translate('context-cards:change-slot'),
          CardType: Booked,
          className: 'booked',
          onClick: this.generateClickHandler(this.emitChangeSlotAnalyticsEvent)
        }
      ],
      [
        SLOT_BOOKED_FULL,
        {
          buttonLink: slotsLanguageLink,
          buttonText: displayOnFullBasket
            ? translate('common:slot-context-card.change-your-slot')
            : translate('context-cards:change-slot'),
          CardType: Booked,
          className: 'booked',
          onClick: this.generateClickHandler(this.emitChangeSlotAnalyticsEvent)
        }
      ],
      [
        SLOT_EXPIRED,
        {
          buttonLink: slotsLanguageLink,
          buttonText: (
            <span>
              <span className="visually-hidden">
                {translate('common:pages.slots-long')}
              </span>
              <span aria-hidden="true">
                {displayOnFullBasket
                  ? translate('common:pages.slots')
                  : translate('common:pages.book-another-slot')}
              </span>
            </span>
          ),
          CardType: Booked,
          className: 'expired',
          onClick: this.generateClickHandler(this.emitChangeSlotAnalyticsEvent)
        }
      ],
      [
        AMEND_MODE,
        {
          ...this.getAmendModeAction(),
          CardType: Booked,
          className: 'context-card-amend-mode'
        }
      ]
    ]);
  }

  generateClickHandler(additionalHandler) {
    return ev => {
      this.props.closeLeftNav();

      if (typeof additionalHandler === 'function') {
        additionalHandler(ev);
      }
    };
  }

  emitChangeSlotAnalyticsEvent = () => {
    const { isLHN } = this.props;
    basicEvent(analyticsBus, {
      type: isLHN ? 'LHN' : TROLLEY,
      value: 'change slot',
      action: 'delay'
    });
  };

  onHandleClickBookASlot = () => {
    this.emitBookASlotAnalyticsEvent(BOOK_A_SLOT);
  };

  onHandleClickBookAnotherSlot = () => {
    this.emitBookASlotAnalyticsEvent(BOOK_ANOTHER_SLOT);
  };

  emitBookASlotAnalyticsEvent(value) {
    if (!this.props.isLHN) {
      basicEvent(analyticsBus, {
        type: TROLLEY,
        value,
        action: DELAY
      });
    }
  }

  getSlotBookedCardStatus() {
    const { itemsCount, showCheckoutButtonForFullTrolley } = this.props;
    return itemsCount && showCheckoutButtonForFullTrolley
      ? SLOT_BOOKED_FULL
      : SLOT_BOOKED_EMPTY;
  }

  determineCardStatus() {
    const {
      bookedSlot: slot,
      showBookAnotherSlot,
      isAmendBasket,
      displayOnFullBasket,
      hasPendingOrder
    } = this.props;
    let cardStatus = NO_SLOT_BOOKED;

    if (
      showBookAnotherSlot ||
      (displayOnFullBasket && hasPendingOrder && slot.status === NO_SLOT_BOOKED)
    ) {
      cardStatus = PENDING_ORDER;
    } else if (isAmendBasket) {
      cardStatus = AMEND_MODE;
    } else if (slot && slot.status === RESERVED) {
      cardStatus = this.getSlotBookedCardStatus();
    } else if (
      slot &&
      (slot.status === EXPIRED || slot.status === UNAVAILABLE)
    ) {
      cardStatus = SLOT_EXPIRED;
    }

    return cardStatus;
  }

  isAboutToExpire(cardStatus) {
    if (cardStatus === SLOT_BOOKED_FULL || cardStatus === SLOT_BOOKED_EMPTY) {
      const current = new Date();
      const expiry = new Date(this.props.bookedSlot.reservationExpiry);

      return expiry - current < SLOT_EXPIRY_WARNING;
    }

    return false;
  }

  getText = () => {
    return `${this.getHeading()} ${this.getMessage()}`;
  };

  getHeading = () => {
    const { shoppingMethod, t: translate } = this.props;
    const cardStatus = this.determineCardStatus();

    return cardStatus === SLOT_EXPIRED
      ? `${translate('context-cards:slot-expired')}.`
      : translate(`common:shopping-method_${shoppingMethod}`);
  };

  getMessage = () => {
    const {
      bookedSlot: { start, end, reservationExpiry },
      standardizeTime,
      t: translate
    } = this.props;
    const cardStatus = this.determineCardStatus();

    const startTime = standardizeTime(start);
    const endTime = standardizeTime(end);
    const expiryTime = standardizeTime(reservationExpiry);
    const slotBeginning = startTime.format('dddd Do MMMM, H:mm');
    const slotEnding = endTime.format('H:mm');
    const checkoutBy = translate('context-cards:checkout-by', {
      time: expiryTime.format('HH:mm')
    });

    switch (cardStatus) {
      case SLOT_EXPIRED:
        return translate('context-cards:book-new-slot');
      case AMEND_MODE:
        return `${slotBeginning} - ${slotEnding}. ${translate(
          'orders:amend.making-changes'
        )}`;
      default:
        return `${slotBeginning} - ${slotEnding}. ${checkoutBy}`;
    }
  };

  render() {
    const {
      id,
      isAuthenticated,
      amountChanging,
      shoppingMethod,
      bookedSlot,
      t: translate,
      expandable,
      slotsLanguageLink,
      displayOnFullBasket,
      c: config
    } = this.props;
    const cardStatus = this.determineCardStatus();
    const isAboutToExpire = this.isAboutToExpire(cardStatus);
    const isExpandable =
      typeof expandable !== 'undefined'
        ? expandable
        : cardStatus !== NO_SLOT_BOOKED;
    const cardDetails = this.getCardDetails().get(cardStatus);
    const {
      buttonLink,
      onClick,
      buttonText,
      CardType,
      introText
    } = cardDetails;
    let { className } = cardDetails;
    const isGlobalHeader = config('isGlobalHeader');
    className = isAboutToExpire ? 'about-to-expire' : className;

    return (
      <div
        className={classnames('context-cards--slot', {
          'context-card-global-header-enabled': isGlobalHeader,
          'context-card-expandable': isExpandable,
          'context-card--auto-height': displayOnFullBasket
        })}
      >
        <h2 className="visually-hidden">
          {translate('slots:common.your-slot')}
        </h2>
        <CardType
          buttonLink={buttonLink}
          buttonText={buttonText}
          onClick={onClick}
          className={className}
          checkboxId={`${id}-expander`}
          expandable={isExpandable}
          introText={introText}
          isAuthenticated={isAuthenticated}
          isAboutToExpire={isAboutToExpire}
          status={cardStatus}
          shoppingMethod={shoppingMethod}
          slot={bookedSlot}
          slotsLanguageLink={slotsLanguageLink}
          amountChanging={amountChanging}
          isLHN={this.props.isLHN}
          displayOnFullBasket={displayOnFullBasket}
        />
      </div>
    );
  }
}
