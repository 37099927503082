import 'moment-timezone';
import { getProductIsForSale } from '#/selectors/item';
import { isValid } from '#/selectors/slot/slot-record';
import { cloneItems } from '#/utils/clone-items';
import { getById } from '../records/product-utils';
import { getMarketplaceSplitView } from '#/selectors/trolley';
import { BasketTypes } from '../records/split-basket';

export function hasItems(items) {
  return items && [...items.values()].some(item => getProductIsForSale(item));
}

export function canCheckout(items, slot) {
  return hasItems(items) && hasValidSlot(slot);
}

export function hasValidSlot(slot) {
  return slot && isValid(slot);
}

export function setGroupByProductsLimit(items) {
  const productsByBulkBuyLimitGroups = groupByBulkBuyLimitGroup(items);

  if (Object.keys(productsByBulkBuyLimitGroups).length > 0) {
    const productsReachedBBLG = getBulkBuyLimit(productsByBulkBuyLimitGroups);

    items = setBulkBuyLimitReached(items, productsReachedBBLG);
  }

  return items;
}

function groupByBulkBuyLimitGroup(items) {
  const productsByBulkBuyLimitGroups = {};

  if (items) {
    const itemsInBasket = items;

    itemsInBasket.forEach(basketItem => {
      const groupId = basketItem.product.bulkBuyLimitGroupId;

      if (groupId && basketItem.product.groupBulkBuyLimit >= 0) {
        if (!productsByBulkBuyLimitGroups[groupId]) {
          productsByBulkBuyLimitGroups[groupId] = {
            products: [
              {
                id: basketItem.product.id,
                bulkBuyLimit: basketItem.product.bulkBuyLimit,
                quantity: basketItem.quantity
              }
            ],
            bulkBuyLimit: basketItem.product.groupBulkBuyLimit,
            qtyInBasket: basketItem.quantity
          };
        } else {
          productsByBulkBuyLimitGroups[groupId].qtyInBasket +=
            basketItem.quantity;
          productsByBulkBuyLimitGroups[groupId].products.push({
            id: basketItem.product.id,
            bulkBuyLimit: basketItem.product.bulkBuyLimit,
            quantity: basketItem.quantity
          });
        }
      }
    });
  }

  return productsByBulkBuyLimitGroups;
}

function getBulkBuyLimit(bblgGroups) {
  const bulkBuyLimitProducts = [];

  Object.keys(bblgGroups).map(groupId => {
    const group = bblgGroups[groupId];

    group.products.map(product => {
      const notItemRestricted = group.bulkBuyLimit !== 0;

      bulkBuyLimitProducts.push({
        id: product.id,
        itemLimitReached: product.quantity >= product.bulkBuyLimit,
        groupLimitReached: notItemRestricted
          ? group.qtyInBasket >= group.bulkBuyLimit
          : false,
        groupBulkBuyQuantity: notItemRestricted ? group.qtyInBasket : 0
      });
    });
  });

  return bulkBuyLimitProducts;
}

function setBulkBuyLimitReached(items, products) {
  const updatedItems = cloneItems(items);

  products.map(
    ({ groupBulkBuyQuantity, groupLimitReached, id, itemLimitReached }) => {
      const updatedItem = updatedItems && getById(updatedItems, id);
      if (updatedItem) {
        updatedItem.groupLimitReached = groupLimitReached;
        updatedItem.itemLimitReached = itemLimitReached;
        updatedItem.groupBulkBuyQuantity = groupBulkBuyQuantity;
      }
    }
  );

  return updatedItems;
}

export function getCouponFromCouponsArray(coupons, couponCodeOrId) {
  if (coupons) {
    couponCodeOrId = couponCodeOrId
      .toString()
      .toLowerCase()
      .trim();

    return coupons.find(
      coupon =>
        coupon.id.toString().toLowerCase() === couponCodeOrId ||
        coupon.code.toLowerCase() === couponCodeOrId
    );
  }
}

export function isOutOfCatchment({ availableShoppingMethods } = {}) {
  return availableShoppingMethods?.length === 0 ?? true;
}

export const isMarketPlaceOnlyBasket = basket => {
  if (!basket.splitView) return false;
  const marketplaceBasket = getMarketplaceSplitView(basket.splitView);

  if (!marketplaceBasket) return false;
  if (marketplaceBasket?.items?.length === 0) return false;
  const groceriesBasket = basket.splitView?.find(
    subBasket =>
      subBasket.typename === BasketTypes.GROCERY ||
      subBasket.typename === BasketTypes.IGHS_GROCERY
  );
  if (
    groceriesBasket &&
    (groceriesBasket?.length > 0 || groceriesBasket?.items?.length > 0)
  )
    return false;
  return (
    basket.items?.length === marketplaceBasket.items?.length ||
    basket.totalItems === marketplaceBasket.items?.length
  );
};

export const hasItemsOfType = (items, basketType) =>
  [...items.values()].some(
    item =>
      (item.product?.typename === 'MPProduct' &&
        basketType === BasketTypes.MARKETPLACE) ||
      (item.product?.typename === 'ProductType' &&
        basketType === BasketTypes.GROCERY) ||
      (item.product?.typename === 'IGHSProduct' &&
        basketType === BasketTypes.IGHS_GROCERY)
  );
