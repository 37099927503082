import React from 'react';
import { ConnectedProps } from 'react-redux';
import styled, { css } from 'styled-components';
import DdsWebValueTile from '@ddsweb/value-tile';
import { base, spacing } from '@ddsweb/selectors';
import { GREY_MID_2, WHITE } from '@ddsweb/constants';
import { RegularPriceClubcardTile } from './regular-price-clubcard-tile';
import {
  VARIANT_SQUARE_LARGE,
  VARIANT_SQUARE,
  VALUE_TILE_NEW_MODE,
  VALUE_TILE_CLUBCARD_MODE,
  VALUE_TILE_REGULAR_PRICE,
  VARIANT_HORIZONTAL_MICRO,
  VARIANT_HORIZONTAL,
  VALUE_TILE_REGULAR_PRICE_CLUBCARD,
} from './value-tile-const';
import { withErrorBoundary } from '#/components/shared/error-boundary';
import { connect } from '#/lib/render/connect-deep-compare';
import { CMS_ERROR_PREFIX } from '#/constants/dcs';
import { SMALL_EU, SMALL_UK } from '#/constants/regions';
import { getAppRegion } from '#/reducers/app';

const ValueTileWrapper = styled.p`
  font-family: ${base.fontFamily};

  .ddsweb-value-tile__container {
    justify-content: center;

    ${props =>
      props.tileVariant === VARIANT_HORIZONTAL &&
      props.mode === VALUE_TILE_CLUBCARD_MODE &&
      css`
        width: 100%;
      `};

    ${props =>
      // We should not be adding any styles based on tile modes and variants here as all value tile
      // variations should be created in ddsweb.
      // TODO: LEGO-19676 - Remove these styles and enhance ddsweb' value tile to support the styles
      props.tileVariant !== VARIANT_HORIZONTAL &&
      props.mode !== VALUE_TILE_CLUBCARD_MODE &&
      css`
        padding: ${spacing.sm} 0;
      `};
  }
`;
ValueTileWrapper.displayName = 'ValueTileWrapper';

const isUKClubCardMode = (region: string, mode: string | undefined): boolean =>
  region === SMALL_UK && (mode === VALUE_TILE_CLUBCARD_MODE || mode === VALUE_TILE_REGULAR_PRICE_CLUBCARD);

const getTileVariant = (isFlatTile: boolean, region: string, mode: string | undefined): string =>
  isFlatTile ? VARIANT_HORIZONTAL : isUKClubCardMode(region, mode) ? VARIANT_SQUARE : VARIANT_SQUARE_LARGE;

export type TProps = {
  isAsterisk: boolean;
  isFlatTile: boolean;
  isSlimTradeBanner: boolean;
  mode?: typeof VALUE_TILE_NEW_MODE | typeof VALUE_TILE_CLUBCARD_MODE | typeof VALUE_TILE_REGULAR_PRICE_CLUBCARD;
  offerDetailHiddenText: string;
  termsApplyHiddenText: string;
  valueContent: { text: string; size: string; styles?: string | object }[];
  values: string[];
  valueTileColor: string | null;
  variant?: typeof VARIANT_HORIZONTAL | typeof VARIANT_SQUARE_LARGE | typeof VARIANT_SQUARE;
} & regularPriceProps;

type regularPriceProps = {
  hasRegularPrice?: boolean;
  regularPrice?: {
    values: string[];
    valueContent: { text: string; size: string; styles?: string | object }[];
    mode?: typeof VALUE_TILE_REGULAR_PRICE;
    variant?: typeof VARIANT_HORIZONTAL | typeof VARIANT_HORIZONTAL_MICRO;
    regularPriceValueText?: string | null;
  };
};

export type OwnState = {
  region: string;
};

const mapStateToProps = (state: Store): OwnState => ({
  region: getAppRegion(state)?.toLowerCase(),
});

const connector = connect(mapStateToProps);

type Props = TProps & OwnState & ConnectedProps<typeof connector>;

const ValueTileComp: React.FC<Props> = (props: Props) => {
  const {
    isAsterisk = false,
    isFlatTile,
    isSlimTradeBanner,
    mode,
    offerDetailHiddenText,
    termsApplyHiddenText,
    valueContent,
    values,
    hasRegularPrice,
    region,
    regularPrice,
    variant,
    valueTileColor,
  } = props;

  const isUKRegion = region === SMALL_UK;
  const clubcardRegion = isUKRegion ? SMALL_UK : SMALL_EU;

  const tileVariant = getTileVariant(isFlatTile && !isSlimTradeBanner, region, mode);

  const valueTileStyles: Record<string, string | number> = {};

  if (valueTileColor) {
    valueTileStyles.backgroundColor = valueTileColor;

    if (valueTileColor === WHITE) {
      valueTileStyles.borderLeft = `1px solid ${GREY_MID_2}`;
    }
  }

  if (isUKRegion) {
    if (valueContent) {
      return (
        <ValueTileWrapper mode={mode} tileVariant={tileVariant}>
          <span className="visually-hidden">{offerDetailHiddenText}</span>
          <DdsWebValueTile
            asterisk={isAsterisk}
            mode={mode}
            position
            template={valueContent}
            region={clubcardRegion}
            values={values}
            variant={{ global: tileVariant }}
            styles={valueTileStyles}
            regularPriceValueText={regularPrice?.regularPriceValueText}
          />
          {isAsterisk && <span className="visually-hidden">{termsApplyHiddenText}</span>}
        </ValueTileWrapper>
      );
    } else {
      return null;
    }
  } else {
    return !!hasRegularPrice ? (
      <RegularPriceClubcardTile
        isAsterisk={isAsterisk}
        mode={mode}
        valueContent={valueContent}
        values={values}
        tileVariant={variant}
        region={clubcardRegion}
        regularPrice={regularPrice}
        offerDetailHiddenText={offerDetailHiddenText}
        termsApplyHiddenText={termsApplyHiddenText}
      />
    ) : valueContent ? (
      <ValueTileWrapper mode={mode} tileVariant={tileVariant}>
        <span className="visually-hidden">{offerDetailHiddenText}</span>
        <DdsWebValueTile
          asterisk={isAsterisk}
          mode={mode}
          position
          template={valueContent}
          region={clubcardRegion}
          stretch
          values={values}
          variant={{ global: tileVariant }}
          styles={valueTileStyles}
        />
        {isAsterisk && <span className="visually-hidden">{termsApplyHiddenText}</span>}
      </ValueTileWrapper>
    ) : null;
  }
};

const ValueTile = withErrorBoundary(CMS_ERROR_PREFIX, 'ValueTile')(connector(ValueTileComp));
export { ValueTile };
