import React from 'react';
import classnames from 'classnames';
import moment from 'moment-timezone';
import SafeForm from '#/components/shared/safe-form';
import StringToNode from '#/components/shared/string-to-node';
import { BOOK, UNBOOK } from '#/constants/mutate-fulfilment-types';
import { ShoppingMethod } from '#/constants/shopping-methods';
import helpers from '#/lib/decorators/helpers';
import { TTranslateFn, TDeliverySlot, TBookedSlot } from './index.defs';
import { DynamicDeliveryTime } from '#/custom-typings/redux-store/slot.defs';
import { connect } from '#/lib/render/connect-deep-compare';
import { isOnDemandShoppingMethod, getOnDemandDynamicDeliveryData } from '#/lib/shopping-method-util';
import { getOnDemandDynamicDeliveryTime } from '#/selectors/slot';
import CapacityReachedContextCard from './capacity-reached-context-card';
import {
  StyledFootnote,
  StyledButton,
  StyledBodyText,
  DeliverySection,
  DeliverySectionDetails,
  BookDeliveryButton,
  SlotDetails,
} from './styled';
import { TTranslateFunc } from '#/lib/records/helpers.defs';

type TPayload = {
  slotId?: string;
  slotAction: typeof BOOK | typeof UNBOOK;
};

type TDeliveryThresholdMessage = {
  deliveryThreshold: number;
  currencySymbol: string;
  t: TTranslateFn;
  shoppingMethod: ShoppingMethod;
};

type TDeliveryMethodDetails = {
  shoppingMethod: ShoppingMethod;
  t: TTranslateFn;
};

type TBookExpressDeliveryButton = {
  payload: TPayload;
  bookButtonActionUrl: string;
  currentUrl: string;
  t: TTranslateFn;
  shoppingMethod: ShoppingMethod;
  isBooked: boolean;
  handleSubmit: (...args: any[]) => void;
  isDeliverySlotAvailable: boolean;
  isSlotExpired: boolean;
};

type TBookDeliverySection = {
  commonProps: {
    handleSubmit: (...args: any[]) => void;
    currency: {
      symbol: string;
    };
    language: string;
    currentUrl: string;
    bookButtonActionUrl: string;
    deliveryThresholds: {
      deliveryThreshold?: number;
    };
  };
  shoppingMethod: ShoppingMethod;
  trolleyShoppingMethod: ShoppingMethod;
  isBooked: boolean;
  deliverySlot: TDeliverySlot;
  bookedSlot?: TBookedSlot;
  isSlotExpired: boolean;
};

type TDeliveryDateDetails = {
  shoppingMethod: ShoppingMethod;
  deliveryDate: string;
  t: TTranslateFunc;
  onDemandDynamicDeliveryTime: DynamicDeliveryTime;
};

type OwnState = {
  onDemandDynamicDeliveryTime: DynamicDeliveryTime;
};

const mapStateToProps = (state: Store): OwnState => ({
  onDemandDynamicDeliveryTime: getOnDemandDynamicDeliveryTime(state),
});

export const DeliveryDateDetails = helpers(['t'])(
  connect(mapStateToProps)(
    ({
      shoppingMethod,
      deliveryDate,
      t: translate,
      onDemandDynamicDeliveryTime,
    }: TDeliveryDateDetails): JSX.Element | null => {
      const isOnDemandShopping = isOnDemandShoppingMethod(shoppingMethod);

      const { onDemandDynamicDeliverySuffix, unit, min, max } = getOnDemandDynamicDeliveryData(
        isOnDemandShopping,
        true,
        onDemandDynamicDeliveryTime,
      );

      return (
        <SlotDetails>
          <StyledFootnote>
            {translate(`slots:${shoppingMethod}.delivered-by${onDemandDynamicDeliverySuffix}`, {
              unit,
              min,
              max,
            })}
          </StyledFootnote>
          <StyledBodyText>{deliveryDate}</StyledBodyText>
        </SlotDetails>
      );
    },
  ),
);

export const DeliveryThresholdMessage = helpers(['t'])(
  ({
    deliveryThreshold,
    currencySymbol,
    t: translate,
    shoppingMethod,
  }: TDeliveryThresholdMessage): JSX.Element | null => {
    if (!deliveryThreshold) return null;

    return (
      <StyledFootnote>
        {translate(`slots:${shoppingMethod}.delivery-threshold`, { deliveryThreshold, currencySymbol })}
      </StyledFootnote>
    );
  },
);

export const DeliveryMethodDetails = helpers(['t'])(
  ({ shoppingMethod, t: translate }: TDeliveryMethodDetails): JSX.Element => {
    const commonDetailsList = [['order-today'], ['delivery-time']];

    const commonDetails = commonDetailsList.map((translation, index) => (
      <StyledFootnote key={index}>
        <StringToNode text={translate(`slots:${shoppingMethod}.${translation[0]}`)} />
      </StyledFootnote>
    ));

    return <>{commonDetails}</>;
  },
);

export const BookExpressDeliveryButton = helpers(['t'])(
  ({
    payload,
    bookButtonActionUrl,
    currentUrl,
    t: translate,
    shoppingMethod,
    isBooked,
    handleSubmit,
    isDeliverySlotAvailable,
  }: TBookExpressDeliveryButton): JSX.Element => (
    <SafeForm action={bookButtonActionUrl} method="POST" onSubmit={handleSubmit(payload)}>
      <input type="hidden" name="slotId" value={payload.slotId} />
      <input type="hidden" name="slotAction" value={payload.slotAction} />
      <input type="hidden" name="returnUrl" value={currentUrl} />
      <StyledButton
        disabled={!isDeliverySlotAvailable}
        className={classnames({ booked: isBooked })}
        type="submit"
        data-auto="book-delivery"
      >
        {isBooked ? translate(`slots:${shoppingMethod}.booked`) : translate(`slots:${shoppingMethod}.book-delivery`)}
      </StyledButton>
    </SafeForm>
  ),
);

const BookDeliverySection = (props: TBookDeliverySection): JSX.Element => {
  const { commonProps, isBooked, shoppingMethod, deliverySlot, bookedSlot, isSlotExpired } = props;
  const isDeliveryUnavailable = deliverySlot ? Object.keys(deliverySlot).length === 0 : true;

  if (isDeliveryUnavailable) return <CapacityReachedContextCard />;

  const { currency, deliveryThresholds, language, currentUrl, bookButtonActionUrl, handleSubmit } = commonProps;
  const currencySymbol = currency.symbol;
  const { deliveryThreshold } = deliveryThresholds;
  const isBookedAndSlotNotExpired = isBooked && !isSlotExpired;

  const relevantSlot = isBookedAndSlotNotExpired && bookedSlot?.status ? bookedSlot : deliverySlot;

  const deliveryDate = moment(relevantSlot?.countdown?.deliveryDate)
    .locale(language)
    .format('ddd D MMM');

  const payload = {
    slotId: isBookedAndSlotNotExpired ? bookedSlot?.slotId : deliverySlot?.slotId,
    slotAction: isBookedAndSlotNotExpired ? UNBOOK : BOOK,
  };

  return (
    <DeliverySection>
      <DeliverySectionDetails>
        <DeliveryMethodDetails shoppingMethod={shoppingMethod} />
        <DeliveryThresholdMessage
          deliveryThreshold={deliveryThreshold}
          currencySymbol={currencySymbol}
          shoppingMethod={shoppingMethod}
        />
      </DeliverySectionDetails>
      <BookDeliveryButton shoppingMethod={shoppingMethod}>
        <DeliveryDateDetails shoppingMethod={shoppingMethod} deliveryDate={deliveryDate} />
        <BookExpressDeliveryButton
          payload={payload}
          bookButtonActionUrl={bookButtonActionUrl}
          currentUrl={currentUrl}
          shoppingMethod={shoppingMethod}
          isBooked={isBookedAndSlotNotExpired}
          handleSubmit={handleSubmit}
          isDeliverySlotAvailable={!isDeliveryUnavailable}
        />
      </BookDeliveryButton>
    </DeliverySection>
  );
};

export default BookDeliverySection;
