import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import helpers from '#/lib/decorators/helpers';
import { basicEvent } from '#/analytics/types/basic';
import { shouldShowRestOfShelf, generateSlugs } from '#/lib/url/url-utils';
import analyticsBus from '#/analytics/analyticsBus';
import SafelyOutputString from '#/components/safely-output-string';
import RestOfShelfLink from '#/components/products/product-tile/tile-components/shared/tile-link';
import { connect } from '#/lib/render/connect-deep-compare';
import { getIsOnDemandDelivery } from '#/selectors/trolley';

const mapStateToProps = state => ({
  showRestOfShelf: !getIsOnDemandDelivery(state)
});

@helpers(['l', 't'])
@connect(mapStateToProps)
export default class RenderBrowseCategoryLink extends PureComponent {
  static propTypes = {
    aisleId: PropTypes.string,
    aisleName: PropTypes.string,
    currentPathname: PropTypes.string.isRequired,
    isAvailableOrHasSub: PropTypes.bool.isRequired,
    l: PropTypes.func.isRequired,
    onClick: PropTypes.func,
    restOfShelfUrl: PropTypes.string,
    shelfId: PropTypes.string,
    shelfName: PropTypes.string,
    showRestOfShelf: PropTypes.bool,
    t: PropTypes.func.isRequired,
    tileType: PropTypes.string
  };

  static defaultProps = {
    tileType: 'product tile'
  };

  handleClick = () => {
    if (this.props.onClick) {
      this.props.onClick();
    }

    basicEvent(analyticsBus, {
      type: 'rest of shelf',
      value: this.props.tileType,
      action: 'delay'
    });
  };

  render() {
    // NB: IGHS uses 'rest of aisle', UK uses 'rest of shelf'. we don't get shelf data from ighs apis
    const {
      aisleId,
      aisleName,
      currentPathname: currPathname,
      shelfId,
      shelfName,
      showRestOfShelf,
      restOfShelfUrl,
      isAvailableOrHasSub,
      l,
      t
    } = this.props;
    const { shelfIdSlug, aisleIdSlug } = generateSlugs(
      aisleName,
      shelfName,
      aisleId
    );
    const currentPathname = decodeURIComponent(currPathname);
    let restOfCatUrl;
    let restOfCatName;

    if (shelfId && shelfName) {
      restOfCatUrl = restOfShelfUrl || `categories/${shelfId}`;
      restOfCatName = shelfName;
    } else if (aisleId) {
      restOfCatUrl = `categories/${aisleId}`;
      restOfCatName = aisleName || '';
    } else {
      return null;
    }
    const linkText = `${t('product-tile:rest-of-shelf-long', {
      shelfName: restOfCatName
    })}`;

    const showRestOfShelfSection = restOfCatUrl && showRestOfShelf;

    if (
      isAvailableOrHasSub &&
      shouldShowRestOfShelf(
        currentPathname,
        shelfId || aisleId,
        shelfIdSlug,
        aisleIdSlug
      )
    ) {
      return (
        showRestOfShelfSection && (
          <RestOfShelfLink
            className="browse-category"
            href={l(restOfCatUrl)}
            onClick={this.handleClick}
            dataAuto="rest-of-shelf-link"
          >
            <SafelyOutputString>{linkText}</SafelyOutputString>
          </RestOfShelfLink>
        )
      );
    }

    return null;
  }
}
