import React from 'react';
import PropTypes from 'prop-types';
import FilterRadioButton from './filter-radio-button';
import { hyphenate } from '../../utils/text-utils';

const FilterSortItem = ({ currentUrl, config, sortOption, translate }) => {
  const sortValue = translate(
    `sort-and-filter:sort-options.${hyphenate(sortOption)}`
  );
  const defaultSortValue = config('default-sort-option');

  return (
    <li>
      <FilterRadioButton
        currentUrl={currentUrl}
        defaultSortValue={defaultSortValue}
        value={sortOption}
        sortValue={sortValue}
      />
    </li>
  );
};

FilterSortItem.propTypes = {
  config: PropTypes.func.isRequired,
  currentUrl: PropTypes.string.isRequired,
  sortOption: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired
};

export default FilterSortItem;
