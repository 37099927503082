import { getFeatureVariableStringFromState } from '#/lib/optimizely-manager';
import { productTileDietaryInfo, productTileDietaryInfoVariants } from '#/experiments/oop-1922/constants';
import { isDietaryInfoEnabled } from '#/experiments/oop-1922/helpers';

const getVariant = (state: Store): productTileDietaryInfoVariants => {
  const { featureKey, useCustomerId, variationKey } = productTileDietaryInfo;
  const attributes = {};

  return (
    getFeatureVariableStringFromState(state, featureKey, variationKey, { useCustomerId, attributes }) ||
    productTileDietaryInfoVariants.default
  );
};

export const getProductTileDietaryInfoVariant = (state: Store): productTileDietaryInfoVariants => getVariant(state);

export const getIsDietaryInfoFeatureEnabled = (state: Store): boolean => {
  const dietaryInfoVariant = getProductTileDietaryInfoVariant(state);
  return isDietaryInfoEnabled(dietaryInfoVariant);
};
