import uuidv4 from 'uuid/v4';
import { randomBytes } from 'crypto';

const version = '00';
const flags = '01';

//https://www.w3.org/TR/trace-context/#traceparent-header

export function getTraceParentId() {
  const uuid = uuidv4();
  var regex = new RegExp('-', 'g');
  const formattedTraceId = uuid.replace(regex, '');
  const parentId = randomBytes(8).toString('hex');
  const traceParentId = [version, formattedTraceId, parentId, flags].join('-');
  return traceParentId;
}
