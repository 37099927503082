import React from 'react';
import PropTypes from 'prop-types';
import helpers from '../../../lib/decorators/helpers';
import SafelyOutputString from '#/components/safely-output-string';
import classnames from 'classnames';

//ighs api currently sends error messages which contains html tags
const FieldError = props => {
  return (
    <div
      className={classnames('field-error-message', {
        'icon-error-circle': props.showErrorIcon,
        'field-error-message--without-padding': !props.showErrorIcon
      })}
    >
      {props.text && <p>{props.text}</p>}
      <strong>
        <SafelyOutputString>{props.flashMessage}</SafelyOutputString>
      </strong>
    </div>
  );
};

FieldError.propTypes = {
  flashMessage: PropTypes.string.isRequired,
  showErrorIcon: PropTypes.bool.isRequired,
  text: PropTypes.string
};

export default helpers(['t'])(FieldError);
