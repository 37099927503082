import React from 'react';
import classnames from 'classnames';
import SafeForm from '#/components/shared/safe-form';
import StringToNode from '#/components/shared/string-to-node';
import { BOOK, UNBOOK } from '#/constants/mutate-fulfilment-types';
import helpers from '#/lib/decorators/helpers';
import { connect } from '#/lib/render/connect-deep-compare';
import { getServiceAvailableByShoppingMethod, getOnDemandDynamicDeliveryTime } from '#/selectors/slot';
import { isBooked as isSlotBooked } from '#/selectors/slot/slot-record';
import {
  StyledFootnote,
  StyledButton,
  DeliverySection,
  DeliverySectionDetails,
  BookOnDemandDeliveryButton,
} from '../styled';
import { TTranslateFn, TDeliverySlot, TBookedSlot } from '../index.defs';
import { ShoppingMethod, DYNAMIC_DELIVERY } from '#/constants/shopping-methods';
import { DynamicDeliveryTime } from '#/custom-typings/redux-store/slot.defs';

type TPayload = {
  slotId?: string;
  slotAction: typeof BOOK | typeof UNBOOK;
};

export type TDeliveryThresholdMessage = {
  deliveryThreshold: number;
  currency: {
    isoCode: string;
    symbol: string;
  };
  t: TTranslateFn;
  shoppingMethod: ShoppingMethod;
};

export type TDeliveryMethodDetails = {
  currency: {
    isoCode: string;
    symbol: string;
  };
  language: string;
  charge: number | null | undefined;
  t: TTranslateFn;
  shoppingMethod: ShoppingMethod;
  onDemandDynamicDeliveryTime: DynamicDeliveryTime;
};

type TBookExpressDeliveryButton = {
  payload: TPayload;
  bookButtonActionUrl: string;
  currentUrl: string;
  t: TTranslateFn;
  shoppingMethod: ShoppingMethod;
  isBooked: boolean;
  handleSubmit: (payload: TPayload) => void;
  isOndemandSlotAvailable: boolean;
  isSlotExpired: boolean;
};

type TBookDeliverySection = {
  commonProps: {
    charge: number | null | undefined;
    handleSubmit: (payload: TPayload) => void;
    currency: {
      isoCode: string;
      symbol: string;
    };
    language: string;
    currentUrl: string;
    bookButtonActionUrl: string;
    deliveryThresholds: {
      deliveryThreshold?: number;
    };
  };
  shoppingMethod: ShoppingMethod;
  trolleyShoppingMethod: ShoppingMethod;
  isBooked: boolean;
  deliverySlot: TDeliverySlot;
  bookedSlot?: TBookedSlot;
  isOndemandSlotAvailable: boolean;
  isSlotExpired: boolean;
  onDemandDynamicDeliveryTime: DynamicDeliveryTime;
};

const mapStateToProps = (
  state: Store,
  { shoppingMethod }: { shoppingMethod: ShoppingMethod },
): {
  isOndemandSlotAvailable: boolean;
  onDemandDynamicDeliveryTime: DynamicDeliveryTime;
} => ({
  isOndemandSlotAvailable: getServiceAvailableByShoppingMethod(state, shoppingMethod),
  onDemandDynamicDeliveryTime: getOnDemandDynamicDeliveryTime(state),
});

export const DeliveryThresholdMessage = helpers(['t'])(
  ({ deliveryThreshold, currency, shoppingMethod, t: translate }: TDeliveryThresholdMessage): JSX.Element | null => {
    if (!deliveryThreshold) return null;
    return (
      <StyledFootnote>
        {translate(`slots:${shoppingMethod}.delivery-threshold`, {
          deliveryThreshold,
          currencySymbol: currency.symbol,
        })}
      </StyledFootnote>
    );
  },
);

export const DeliveryMethodDetails = helpers(['t'])(
  ({
    t: translate,
    charge,
    currency,
    language,
    shoppingMethod,
    onDemandDynamicDeliveryTime,
  }: TDeliveryMethodDetails): JSX.Element => {
    const { isDynamicDeliveryTimeAvailable, unit, range } = onDemandDynamicDeliveryTime;
    const { min, max } = range ?? {};
    const commonDetailsCopy = isDynamicDeliveryTimeAvailable ? `order-today${DYNAMIC_DELIVERY}` : 'order-today';

    const commonDetails = (
      <StyledFootnote>
        <StringToNode
          text={translate(`slots:ondemand.${commonDetailsCopy}`, {
            unit,
            min,
            max,
          })}
        />
      </StyledFootnote>
    );

    let pickPackDetails;

    if (charge !== null && charge !== undefined) {
      pickPackDetails = (
        <StyledFootnote>
          {isDynamicDeliveryTimeAvailable ? (
            <>
              <StringToNode text={translate(`slots:ondemand.delivery-time-line-1${DYNAMIC_DELIVERY}`)} />
              <br />
            </>
          ) : null}
          <StringToNode
            text={translate(`slots:${shoppingMethod}.delivery-time-line-2`, {
              charge: new Intl.NumberFormat(language, {
                style: 'currency',
                currency: currency.isoCode,
                minimumFractionDigits: 2,
              }).format(charge as number),
            })}
          />
        </StyledFootnote>
      );
    }

    return (
      <>
        {commonDetails}
        {pickPackDetails}
      </>
    );
  },
);

export const BookExpressDeliveryButton = helpers(['t'])(
  ({
    payload,
    bookButtonActionUrl,
    currentUrl,
    t: translate,
    isBooked,
    handleSubmit,
    isOndemandSlotAvailable,
    shoppingMethod,
  }: TBookExpressDeliveryButton): JSX.Element => (
    <SafeForm action={bookButtonActionUrl} method="POST" onSubmit={handleSubmit(payload)}>
      <input type="hidden" name="slotId" value={payload.slotId} />
      <input type="hidden" name="slotAction" value={payload.slotAction} />
      <input type="hidden" name="returnUrl" value={currentUrl} />
      <StyledButton
        disabled={!isOndemandSlotAvailable}
        className={classnames({ booked: isBooked })}
        type="submit"
        data-auto="book-delivery"
        data-testid="book-whoosh-synthetics"
      >
        {isBooked ? translate(`slots:${shoppingMethod}.booked`) : translate(`slots:${shoppingMethod}.book-delivery`)}
      </StyledButton>
    </SafeForm>
  ),
);

const OndemandBookDeliverySection = (props: TBookDeliverySection): JSX.Element => {
  const {
    bookedSlot,
    commonProps,
    deliverySlot,
    isBooked,
    isOndemandSlotAvailable,
    isSlotExpired,
    shoppingMethod,
    onDemandDynamicDeliveryTime,
  } = props;
  const { charge, currency, currentUrl, bookButtonActionUrl, deliveryThresholds, language, handleSubmit } = commonProps;
  const { deliveryThreshold } = deliveryThresholds;
  //@ts-ignore
  const isBookedAndSlotNotExpired = isSlotBooked(bookedSlot) && isBooked && !isSlotExpired;
  const payload = {
    slotId: isBookedAndSlotNotExpired ? bookedSlot?.slotId : deliverySlot?.slotId,
    slotAction: isBookedAndSlotNotExpired ? UNBOOK : BOOK,
  };

  return (
    <DeliverySection>
      <DeliverySectionDetails isButtonDisabled={!charge && !isOndemandSlotAvailable}>
        <DeliveryMethodDetails
          currency={currency}
          charge={charge}
          language={language}
          shoppingMethod={shoppingMethod}
          onDemandDynamicDeliveryTime={onDemandDynamicDeliveryTime}
        />
        <DeliveryThresholdMessage
          deliveryThreshold={deliveryThreshold}
          currency={currency}
          shoppingMethod={shoppingMethod}
        />
      </DeliverySectionDetails>
      <BookOnDemandDeliveryButton>
        <BookExpressDeliveryButton
          payload={payload}
          bookButtonActionUrl={bookButtonActionUrl}
          currentUrl={currentUrl}
          shoppingMethod={shoppingMethod}
          isBooked={isBookedAndSlotNotExpired}
          handleSubmit={handleSubmit}
          isOndemandSlotAvailable={isOndemandSlotAvailable}
        />
      </BookOnDemandDeliveryButton>
    </DeliverySection>
  );
};

export default connect(mapStateToProps)(OndemandBookDeliverySection);
