import moment from 'moment';

import cs from './cs-CZ';
import en from './en-GB';
import hu from './hu-HU';
import sk from './sk-SK';

const locales = { cs, en, hu, sk };

export default function(lang) {
  if (moment.locales().includes(lang)) {
    return moment.locale(lang);
  } else {
    const config = locales[lang.split('-')[0]];

    return moment.defineLocale(lang, config);
  }
}
