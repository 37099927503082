import React from 'react';
import { hasSquareOfferSash } from '#/components/products/product-tile/helpers';
import VerticalTile, { BaseProps } from '#/components/product-tiles/vertical-tile/core';
import { FLEXI } from '#/components/product-tiles/vertical-tile/core/constants';
import { connect } from '#/lib/render/connect-deep-compare';
import { getCurrentUrl, getLanguageLink } from '#/reducers/app';
import { isAvailableEpwOverride, isProductAvailable } from '#/selectors/beans-product-tile/product-availability-data';
import { getRestOfShelfLinkData } from '#/selectors/beans-product-tile/rest-of-shelf-link-data';
import {
  shouldShowRestOfShelfButton,
  getRestOfShelfText,
} from '#/selectors/beans-product-tile/rest-of-shelf-buybox-data';
import { getWriteAReviewData } from '#/selectors/beans-product-tile/write-a-review-data';
import { getProductAisleId, getRestOfShelfUrl, isSponsoredProduct } from '#/selectors/item';
import ContentStamp from '#/experiments/oop-2021/components';

export type StateProps = {
  available: boolean;
  isSponsoredProduct?: boolean;
  showRestOfShelfButton?: boolean;
  restOfShelfLink?: ReturnType<typeof getRestOfShelfLinkData>;
  restOfShelfUrl?: string | null;
  restOfShelfText?: string | null;
  showSquareOfferSash?: boolean;
  variationKey?: string;
  writeAReviewData?: ReturnType<typeof getWriteAReviewData>;
};

type OwnProps = BaseProps;

const mapStateToProps = (state: Store, { config, feature, item, translate }: OwnProps): StateProps => {
  const available = isProductAvailable(state, item);
  const showRestOfShelfButton =
    shouldShowRestOfShelfButton(item, config, getCurrentUrl(state)) && !isAvailableEpwOverride(state, item);
  const restOfShelfUrl = getRestOfShelfUrl(item) || `/categories/${getProductAisleId(item)}`;

  return {
    available,
    isSponsoredProduct: isSponsoredProduct(item),
    restOfShelfLink: getRestOfShelfLinkData(state, item, translate, config),
    restOfShelfText: showRestOfShelfButton ? getRestOfShelfText(item, translate) : null,
    restOfShelfUrl: showRestOfShelfButton ? getLanguageLink(state, restOfShelfUrl) : null,
    showRestOfShelfButton,
    showSquareOfferSash: hasSquareOfferSash(item, available),
    writeAReviewData: getWriteAReviewData(state, item, feature, translate),
  };
};

type Props = BaseProps & StateProps;

export const FlexiTile = (props: Props): JSX.Element => {
  const { item, itemIndex } = props;

  if (item?.content) {
    return <ContentStamp content={item?.content} index={itemIndex} />;
  } else {
    return <VerticalTile {...props} variant={FLEXI} />;
  }
};

export default connect(mapStateToProps)(FlexiTile);
