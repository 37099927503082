import React from 'react';
import { pure } from 'react-recompose';

export type GuidePriceContentProps = {
  className?: string;
  hiddenText?: string;
  iconClass: string;
  isAuthenticated: boolean;
  mainLabel: React.ReactNode;
  subLabel: React.ReactNode;
};

const GuidePriceContent: React.FC<GuidePriceContentProps> = ({
  className,
  hiddenText,
  iconClass,
  isAuthenticated,
  mainLabel,
  subLabel,
}: GuidePriceContentProps) => {
  return (
    <div className={`header-guide-price--container ${className}`}>
      {isAuthenticated ? (
        <div className="header-guide-price--label">
          <span className="header-guide-price--sub-label">{subLabel}</span>
          <div className="header-guide-price--main-label" data-auto="basket-guide-price">
            {mainLabel}
          </div>
        </div>
      ) : null}
      <span className={iconClass}>
        <span className="visually-hidden">{hiddenText}</span>
      </span>
    </div>
  );
};

export default pure(GuidePriceContent);
