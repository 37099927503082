import React from 'react';
import { PRIMARY, SECONDARY } from '@ddsweb/constants';
import { connect } from '#/lib/render/connect-deep-compare';
import helpers from '#/lib/decorators/helpers';
import { TTranslateFunc } from '#/lib/records/helpers.defs';
import { formatUrl } from '#/lib/slot/slot-utils';

import { closeModal } from '#/actions/ui-action-creators';
import { getLanguageLink } from '#/reducers/app';
import { StyledModal, ModalButtonContainer, StyledText, StyledButton, StyledLink } from '../styled';
import { Heading } from '@ddsweb/heading';
import { getTrolleyShoppingMethod } from '#/selectors/trolley';
import { sessionStore } from '#/lib/data-store/client-store';
import { SHOWN_SLOT_REBOOK_MODAL_KEY } from '#/experiments/oop-2210/constants';
import {
  rebookModalClose,
  rebookModalImpression,
  rebookModalNotNow,
  rebookModalRebook,
} from '#/experiments/oop-2210/analytics';
import { consts } from '@ddsweb/modal';

type SlotRebookModalStateProps = { selectedShoppingMethodLink: string };

type SlotRebookModalOwnProps = {
  closeModal: () => void;
  t: TTranslateFunc;
};

export type SlotRebookModalProps = SlotRebookModalOwnProps & SlotRebookModalStateProps;

const mapStateToProps = (state: Store): SlotRebookModalStateProps => {
  const trolleyShoppingMethod = getTrolleyShoppingMethod(state);
  return {
    selectedShoppingMethodLink: getLanguageLink(state, formatUrl(trolleyShoppingMethod)),
  };
};

const SlotRebookModal = (props: SlotRebookModalProps): JSX.Element | null => {
  const { closeModal, t: translate, selectedShoppingMethodLink } = props;
  const tPrefix = `modals:oop-2210-slot-rebook-modal`;
  const modalHeading = translate(`${tPrefix}.title`);

  const onCancelButtonHandler = (): void => {
    sessionStore?.set(SHOWN_SLOT_REBOOK_MODAL_KEY, true);
    rebookModalNotNow();
    closeModal();
  };

  const handleRebookClick = (): void => {
    sessionStore?.set(SHOWN_SLOT_REBOOK_MODAL_KEY, true);
    rebookModalRebook();
    closeModal();
  };

  const onChange = (props: { action: string }): void => {
    if (props.action === consts.CLOSE) {
      sessionStore?.set(SHOWN_SLOT_REBOOK_MODAL_KEY, true);
      rebookModalClose();
      closeModal();
    }
  };

  rebookModalImpression();

  return (
    <StyledModal
      id="rebook-expiry-slot-modal"
      accessibleLabel={modalHeading}
      onChange={onChange}
      dynamicHeight
      maxHeight={'572px'}
      open
      ssr
      persist
    >
      <Heading headingLevel="1" visualSize="headline3">
        {modalHeading}
      </Heading>
      <StyledText forwardedAs="p">{translate(`${tPrefix}.description`)}</StyledText>
      <ModalButtonContainer>
        <StyledLink
          buttonVariant={PRIMARY}
          stretch
          variant="textButton"
          href={selectedShoppingMethodLink}
          onClick={handleRebookClick}
        >
          {translate(`${tPrefix}.rebook-slot`)}
        </StyledLink>
        <StyledButton variant={SECONDARY} onClick={onCancelButtonHandler}>
          {translate(`${tPrefix}.not-now`)}
        </StyledButton>
      </ModalButtonContainer>
    </StyledModal>
  );
};
export default helpers(['t'])(connect(mapStateToProps, { closeModal })(SlotRebookModal));
