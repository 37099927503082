import { getProductIsForSale } from '#/selectors/item';
import { Item } from '#/lib/records/item';
import { isAvailableEpwOverride } from '#/selectors/beans-product-tile/product-availability-data';

isAvailableEpwOverride;
type TImageData = {
  image?: string;
  itemIsUnavailable: boolean;
};

export type TBeansImageData = TImageData;

export const getBeansImageData = (state: any, item: Item): TBeansImageData => {
  const image = item?.product?.defaultImageUrl;
  const itemIsUnavailable = !isAvailableEpwOverride(state, item) ? !getProductIsForSale(item) : false;

  return {
    image,
    itemIsUnavailable,
  };
};
