import React from 'react';
import PropTypes from 'prop-types';
import { WidgetContainer } from '../widget-container';
import CMSAnalytics from '../helpers/cms-analytics';
import { HomeWidget } from '../home-widget';
import Link from '../../link-check-spa';

const BuyList = props => {
  const { background, link, title, responsive, placement } = props;

  // Ensure there are no gaps in the layout
  const doubleSmall = props.doubleSmall;
  const doubleMedium =
    typeof props.doubleMedium === 'undefined'
      ? [2, 3].indexOf(props.posInPanel % 4) > -1
      : props.doubleMedium;
  const doubleLarge =
    typeof props.doubleLarge === 'undefined'
      ? [3, 4].indexOf(props.posInPanel % 6) > -1
      : props.doubleLarge;

  const {
    smallWidth,
    doubleSmallWidth,
    mediumWidth,
    doubleMediumWidth,
    largeWidth,
    doubleLargeWidth
  } = responsive;

  const breakpoints = [
    {
      imageWidth: doubleSmall ? doubleSmallWidth : smallWidth,
      screenWidth: 0
    },
    {
      imageWidth: doubleMedium ? doubleMediumWidth : mediumWidth,
      screenWidth: 600
    },
    {
      imageWidth: doubleLarge ? doubleLargeWidth : largeWidth,
      screenWidth: 992
    }
  ];

  return (
    <CMSAnalytics data={props}>
      <WidgetContainer
        className="ui-buy-list-widget"
        doubleSmall={doubleSmall}
        doubleMedium={doubleMedium}
        doubleLarge={doubleLarge}
        widgetName="buylist"
      >
        <HomeWidget
          backgroundImageUrl={background && background.src}
          cropDirection={background ? background.clipping : null}
          widgetName="buylist"
          breakpoints={breakpoints}
          maxWidth={doubleMediumWidth}
          uid={placement}
        >
          <Link className="ui-buy-list-widget--link" to={link.href}>
            <span className="ui-buy-list-widget--label-container">
              <span className="ui-buy-list-widget--title">{title}</span>
              <span className="ui-buy-list-widget--icon" />
            </span>
          </Link>
        </HomeWidget>
      </WidgetContainer>
    </CMSAnalytics>
  );
};

BuyList.propTypes = {
  background: PropTypes.object,
  doubleLarge: PropTypes.bool,
  doubleMedium: PropTypes.bool,
  doubleSmall: PropTypes.bool,
  link: PropTypes.object,
  placement: PropTypes.number,
  posInPanel: PropTypes.number,
  responsive: PropTypes.shape({
    smallWidth: PropTypes.number,
    doubleSmallWidth: PropTypes.number,
    mediumWidth: PropTypes.number,
    doubleMediumWidth: PropTypes.number,
    largeWidth: PropTypes.number,
    doubleLargeWidth: PropTypes.number
  }),
  title: PropTypes.string.isRequired
};

export { BuyList };
