import styled, { css } from 'styled-components';
import { colors } from '@ddsweb/selectors';
import { media } from '@ddsweb/foundation';

/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const H3 = styled.h3<{ disabled: boolean; preventDisabledTitleStyle: boolean }>`
  a {
    ${p => p.disabled && `color: ${p.preventDisabledTitleStyle ? colors.textDark(p) : colors.grayscale(p)}`};
  }
  ${({ hasActions, shouldShowPromoteFavorites }: { hasActions: boolean; shouldShowPromoteFavorites: boolean }) =>
    (shouldShowPromoteFavorites || hasActions) &&
    css`
      ${media.belowMobileLarge`
        width: calc(100% - 30px);
      `};
    `}
`;
