/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
// @ts-nocheck

import React, { Component } from 'react';
import deepEqual from 'fast-deep-equal/es6';
import { RatingNonInteractive } from '@ddsweb/rating';
import { VERTICAL } from '@ddsweb/constants';
import Link from '@ddsweb/link';
import { connect } from '#/lib/render/connect-deep-compare';
import { CustomerUnitChoice } from '#/lib/records/item.defs';
import FavoriteHeartIcon from '#/components/product-tiles/common/favorite-heart-icon';
import LinkCheckSpa from '#/components/link-check-spa';
import InfoMessagesWrapper from '#/components/product-tiles/common/info-messages';
import BeansLazyImage from '#/components/product-tiles/common/beans-image';
import { H3 } from '#/components/product-tiles/common/product-title/styles';
import { PRODUCT_TILE } from '#/constants/analytics';
import { getTrexProdPosAndPageId } from '#/actions/trolley/analytics-action-creators';
import { basicEvent } from '#/analytics/types/basic';
import analyticsBus from '#/analytics/analyticsBus';
import { TREX_IDENTIFIER } from '#/analytics/constants';
import { QUANTITY_TYPE as UNIT_PCS } from '#/constants/common';
import {
  getAdditionalTitleStyles,
  quantityControlsStyles,
  StyledBuyBoxWrapper,
  StyledProductActions,
  StyledProductDetailsContent,
  StyledPromotionsAboveMobile,
  StyledPromotionsMobile,
  StyledPromotionsWithBuyBoxWrapper,
  StyledTileContentWrapper,
  StyledTiledContent,
  StyledVerticalTile,
  StyledVerticalTileWrapper,
  StyledOffersSashListWrapper,
} from '#/components/product-tiles/vertical-tile/core/styles';
import { getAppRegion, getCurrentUrl } from '#/reducers/app';
import { CATCH_WEIGHT } from '#/constants/display-types';
import RestOfShelfLink from '#/components/product-tiles/common/rest-of-shelf-link';
import RestOfShelfButton from '#/components/product-tiles/common/rest-of-shelf-button';
import { BuyboxContainer } from '#/components/product-tiles/common/buybox-container';
import { WriteAReviewLink } from '#/components/product-tiles/common/write-a-review-link';
import {
  getProductDisplayType,
  getProductId,
  getProductTitle,
  hasPromotion,
  isInFavourites,
  qtyIncrementable,
  getProductStartRatings,
  getSeedProductId,
  getTagList,
  getProductDrsChargeAmount,
} from '#/selectors/item';
import { getTrolleyItemByBaseProductId } from '#/selectors/trolley';
import { isProductDisabled } from '#/selectors/beans-product-tile/product-availability-data';
import { TFlashSashInfo } from '#/selectors/beans-product-tile/promotions-data';
import { getBuyBoxData, VIEW_AND_BUY_BUYBOX } from '#/selectors/beans-product-tile/buybox-data';
import { getInfoMessagesData } from '#/selectors/beans-product-tile/info-message-data';
import Promotions from '#/components/product-tiles/common/promotions';
import { withCatchWeight } from '#/lib/records/item-utils';
import { promoType } from '#/components/product-tiles/constants';
import { StateProps as FlexiTileStateProps } from '#/components/product-tiles/vertical-tile/variant/flexi-tile';
import { StateProps as CarouselTileStateProps } from '#/components/product-tiles/vertical-tile/variant/carousel-tile';
import { FIXED, FLEXI } from './constants';
import { getFlashSashInfo } from '#/components/products/product-tile/helpers';
import { BaseProps as IBaseProps, BaseStateProps, withProductTile } from '#/components/product-tiles/with-product-tile';
import { getShouldShowPromoteFavorites } from '#/experiments/oop-1693/selectors';
import FavoriteHeartIconWithDropDown from '#/experiments/oop-1693/components';
import { anonymousUserAdd } from '#/components/product-tiles/common/buybox-container/quantity-controls-buybox/anonymous-user-add-util';
import SellerInformation from '#/components/product-tiles/common/seller-info';
import { DietaryIconWithText } from '#/experiments/oop-1922/components/tag-variants/dietary-icon-with-text';
import { DietaryLogo } from '#/experiments/oop-1922/components/tag-variants/dietary-logo';
import { DietaryLogoWithProductImageWrapper } from '#/experiments/oop-1922/styled';
import { removeItem } from '#/actions/trolley/trolley-action-creators';
import { Item } from '#/lib/records/item';
import { isMultiSwapsEnabled } from '#/experiments/oop-1934/selectors';
import { CAROUSEL_MULTI_SUBS, CAROUSEL_SEED_LOOKUP } from '#/experiments/oop-1934/constants';
import { getSelectedFavoritesLayout } from '#/reducers/ui';
import { GRID } from '#/constants/favorites';
import { isSelectedTabFavorites } from '#/lib/favorites-helpers';
import regions from '#/gulp/regions';

type AnalyticsOptions = {
  enableMissedOfferTracking?: boolean;
  identifier?: string;
  sendAnalytics?: boolean;
  gridPos?: string;
};
export interface BaseProps extends IBaseProps {
  deleteActionButton?: JSX.Element;
  onDetailsClick?: () => void;
  variant: typeof FLEXI | typeof FIXED;
  tileVariant?: string;
  preventDisabledTitleStyle?: boolean;
  substitutionFor: Item | null;
  removeItem: (item: Item, analyticsOptions: AnalyticsOptions) => void;
  itemOriginalIndex?: number;
  isMutiSwapTestEnabled?: boolean;
}

type StateProps = {
  buybox: ReturnType<typeof getBuyBoxData>;
  disabled: boolean;
  flashSashInfo: TFlashSashInfo | null;
  flashSashInfoList: TFlashSashInfo[] | null;
  getProductDisplayType: ReturnType<typeof getProductDisplayType>;
  hasPromotion: boolean;
  infoMessages: ReturnType<typeof getInfoMessagesData>;
  isProductQtyIncrementable: boolean;
  productDrsChargeAmount?: number | null;
  productId: string;
  productStartRatings: ReturnType<typeof getProductStartRatings>;
  region: string;
  shouldShowSmallerPrice: boolean;
  showCCPriceLogoInSquare: boolean;
  showFavHeartIcon: boolean;
  showRestOfShelfLink: boolean;
  showWriteAReviewLink: boolean;
  title: string;
};

export type Props = BaseProps & FlexiTileStateProps & CarouselTileStateProps & StateProps & BaseStateProps;

type State = {
  catchWeight?: number;
};

const mapStateToProps = (
  state: Store,
  { item, translate, config, viewAndBuyButtonUrl, identifier, feature, showFavHeartIcon, resultType }: BaseProps,
): StateProps => {
  const shouldShowPromoteFavorites = getShouldShowPromoteFavorites(state, resultType);
  const seedProductId = getSeedProductId(item);
  const isMutiSwapTestEnabled = isMultiSwapsEnabled(state);
  const isInFavouritesGrid = isSelectedTabFavorites(getCurrentUrl(state)) && getSelectedFavoritesLayout(state) === GRID;
  return {
    buybox: getBuyBoxData(state, item, translate, config, identifier, viewAndBuyButtonUrl, VERTICAL),
    disabled: isProductDisabled(item),
    getProductDisplayType: getProductDisplayType(item),
    hasPromotion: hasPromotion(item),
    infoMessages: getInfoMessagesData({
      state,
      item,
      translate,
      restrictedOrderAmendmentOverrideMessage: true,
      config,
      feature,
    }),
    isProductQtyIncrementable: qtyIncrementable(item),
    productId: getProductId(item),
    title: getProductTitle(item),
    showFavHeartIcon: showFavHeartIcon ?? (isInFavourites(item) || shouldShowPromoteFavorites),
    flashSashInfo: getFlashSashInfo({ translate, item }),
    showCCPriceLogoInSquare: config('showCCPriceLogoInSquare'),
    shouldShowPromoteFavorites,
    trexProdPosAndPageId: identifier?.includes(TREX_IDENTIFIER)
      ? { ...getTrexProdPosAndPageId(state, getProductId(item)), identifier }
      : undefined,
    flashSashInfoList: getTagList({ translate, item }, isInFavouritesGrid),
    productStartRatings: getProductStartRatings(item),
    substitutionFor:
      seedProductId && isMutiSwapTestEnabled ? getTrolleyItemByBaseProductId(state, seedProductId) : null,
    isMutiSwapTestEnabled,
    productDrsChargeAmount: getProductDrsChargeAmount(item),
    region: getAppRegion(state),
  };
};

const mapDispatchToProps = {
  removeItem,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const scrollToAddedSubstitute = (): void => {
  window.scrollTo(0, 0); // Assume added substitute is at top of page
};
export class VerticalTile extends Component<Props, State> {
  static defaultProps = {
    variant: FLEXI,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      catchWeight: props.item?.catchWeight,
    };
  }

  shouldComponentUpdate(nextProps: BaseProps): boolean {
    return (
      !deepEqual(this.props.item, nextProps.item) ||
      !deepEqual(this.props.promotions, nextProps.promotions) ||
      !deepEqual(this.props.className, nextProps.className)
    );
  }

  renderInfoMessages(className: string): JSX.Element {
    const { infoMessages: data } = this.props;
    return <InfoMessagesWrapper infoMessagesData={data} className={className} />;
  }

  onDetailsClick = (): void => {
    const { viewAndBuyButtonUrl, onDetailsClick } = this.props;

    if (viewAndBuyButtonUrl && viewAndBuyButtonUrl.length > 0) {
      basicEvent(analyticsBus, {
        type: 'view and buy special offers',
        value: 'product tile',
        action: 'delay',
      });
    }
    if (typeof onDetailsClick === 'function') {
      onDetailsClick();
    }
  };

  productSpaLink = (props: Props): JSX.Element => {
    return this.renderSpaLink(props);
  };

  renderSpaLink = (props: Props, callBack?: () => void) => {
    const { 'data-auto': dataAuto, href, children, className, 'aria-label': ariaLabel, role } = props;
    return (
      <LinkCheckSpa
        aria-label={ariaLabel}
        role={role}
        data-auto={dataAuto}
        to={href}
        className={className}
        onClick={callBack ?? this.onDetailsClick}
      >
        {children}
      </LinkCheckSpa>
    );
  };

  onCatchWeightChange = (catchWeight: number): void => {
    this.props.setCatchWeightTo(this.props.item, catchWeight);
    this.setState({ catchWeight });
  };

  onQuantityChange = (quantityValue: number, variantValue: CustomerUnitChoice = UNIT_PCS): void => {
    const {
      identifier,
      itemIndex,
      analyticsSettings,
      item,
      itemOriginalIndex,
      substitutionFor,
      removeItem,
    } = this.props;
    const { catchWeight } = this.state;

    if (substitutionFor) {
      const newItem = { ...item, isSubstitute: false, seedProduct: undefined };

      const substituteTilePosition = substitutionFor?.product?.substitutions?.findIndex(
        sub => sub?.product.id === newItem?.product?.id,
      );

      const parentPosition = itemOriginalIndex + 1;

      this.props.incrementItemBy(
        quantityValue,
        catchWeight ? withCatchWeight(newItem, catchWeight) : newItem,
        {
          enableMissedOfferTracking: false,
          identifier: CAROUSEL_MULTI_SUBS,
          contextValue: 'grid position:' + parentPosition,
          position: substituteTilePosition + 1,
          product: {
            seedLookup: CAROUSEL_SEED_LOOKUP,
            seedProduct: {
              tpnb: item?.seedProduct?.id,
            },
          },
          ...analyticsSettings,
        },
        variantValue,
      );
      removeItem(substitutionFor, { sendAnalytics: false });
      scrollToAddedSubstitute();
      return;
    }

    this.props.incrementItemBy(
      quantityValue,
      catchWeight ? withCatchWeight(item, catchWeight) : item,
      {
        enableMissedOfferTracking: false,
        identifier,
        gridPos: itemIndex,
        ...analyticsSettings,
      },
      variantValue,
    );
  };

  onAnonymousUserAdd = (quantityValue: number, variantValue: CustomerUnitChoice = UNIT_PCS): void => {
    const {
      buybox: { formData },
      identifier,
      item,
      itemIndex,
      trexProdPosAndPageId,
    } = this.props;

    anonymousUserAdd(
      item,
      formData,
      quantityValue,
      variantValue,
      trexProdPosAndPageId ? { ...trexProdPosAndPageId } : { gridPos: itemIndex, identifier },
    );
  };

  renderFavoritesHeartIcon = (): JSX.Element => {
    const { shouldShowPromoteFavorites, item, translate, productId } = this.props;

    if (shouldShowPromoteFavorites) {
      return (
        <FavoriteHeartIconWithDropDown
          translate={translate}
          productId={productId}
          isInFavourites={isInFavourites(item)}
        />
      );
    }

    return <FavoriteHeartIcon translate={translate} />;
  };

  renderStarRating = (): JSX.Element => {
    const {
      translate,
      productStartRatings: { averageRating, noOfReviews, overallRatingRange },
    } = this.props;
    if (!noOfReviews) {
      return null;
    }

    return (
      <>
        <RatingNonInteractive
          tabIndex="0"
          className="plp-rating"
          value={averageRating}
          aria-label={translate('ratings-reviews:customer-ratings', { averageRating, overallRatingRange })}
          length={overallRatingRange}
          hint={`${averageRating} (${noOfReviews})`}
        />
      </>
    );
  };

  render(): JSX.Element {
    const {
      addFormParams,
      available,
      beansImage: { image, itemIsUnavailable },
      buybox,
      className,
      deleteActionButton,
      disabled,
      flashSashInfo,
      flashSashInfoList,
      getProductDisplayType,
      hasPromotion,
      href,
      identifier,
      isCatchWeightProduct,
      isMutiSwapTestEnabled,
      isProductQtyIncrementable,
      isRestrictedDisabled,
      onPromotionsClick,
      preventDisabledTitleStyle,
      productDrsChargeAmount,
      productId,
      promotions,
      region,
      renderTermText,
      restOfShelfLink,
      restOfShelfText,
      restOfShelfUrl,
      resultType,
      sellerInfo,
      shouldShowPromoteFavorites,
      showCCPriceLogoInSquare,
      showFavHeartIcon,
      showRestOfShelfButton,
      showRestOfShelfLink,
      showWriteAReviewLink,
      tileVariant,
      title,
      translate,
      variant,
      writeAReviewData,
    } = this.props;

    const { item: { product: { foodIcons } = { icons: [] } } = { item: { product: { foodIcons: [] } } } } = this.props;
    const hasActions = showFavHeartIcon;
    const showClubcardPriceOnPromotions =
      flashSashInfo?.type === promoType.clubcardPrice || (sellerInfo?.isPartneredProduct && hasPromotion);

    const buyBoxWrapper = (
      <StyledBuyBoxWrapper variant={variant}>
        <BuyboxContainer
          data-auto="product-controls"
          translate={translate}
          buyboxData={buybox}
          increment={this.onQuantityChange}
          onCatchWeightChange={this.onCatchWeightChange}
          addFormParams={addFormParams}
          identifier={identifier}
          buyboxStyles={quantityControlsStyles}
          isProductQtyIncrementable={isProductQtyIncrementable}
          resultType={resultType}
          onAnonymousUserAdd={this.onAnonymousUserAdd}
        />
      </StyledBuyBoxWrapper>
    );

    return (
      <StyledVerticalTileWrapper variant={variant} className={className}>
        <StyledVerticalTile
          data-auto="product-tile"
          data-auto-type={isCatchWeightProduct ? CATCH_WEIGHT : getProductDisplayType}
          data-auto-available={available}
          data-auto-is-restricted-disabled={isRestrictedDisabled}
          data-auto-is-substitute={false}
          isUnavailable={!available}
          hasPromotion={hasPromotion}
          isCatchWeightProduct={isCatchWeightProduct}
          shouldEnableOverflow={shouldShowPromoteFavorites}
        >
          <StyledTileContentWrapper className="ddsweb" key="ddsweb" variant={variant}>
            <StyledTiledContent
              hasPromotion={hasPromotion}
              hasActions={hasActions}
              id={`tile-${productId}`}
              data-auto-id={productId}
              variant={isMutiSwapTestEnabled ? FIXED : variant}
            >
              <StyledOffersSashListWrapper
                className="sash-list-wrapper"
                shouldWrap={showFavHeartIcon}
                OffersList={flashSashInfoList}
                tileType={PRODUCT_TILE}
                tileVariant={tileVariant}
                showCCPriceLogoInSquare={showCCPriceLogoInSquare}
                foodIcons={foodIcons}
              />

              {deleteActionButton}

              <DietaryLogoWithProductImageWrapper>
                <DietaryLogo content={foodIcons} />
                <BeansLazyImage
                  data-auto="product-image"
                  alt={title}
                  hideFromScreenReader
                  href={href}
                  image={image}
                  isUnavailable={itemIsUnavailable}
                  clickable={!disabled}
                  onClick={this.onDetailsClick}
                />
              </DietaryLogoWithProductImageWrapper>

              <div className="product-details--wrapper">
                <H3
                  disabled={disabled}
                  preventDisabledTitleStyle={preventDisabledTitleStyle}
                  shouldShowPromoteFavorites={shouldShowPromoteFavorites}
                  hasActions={hasActions}
                >
                  <Link
                    data-auto="product-tile--title"
                    variant="standalone"
                    href={href}
                    disabled={disabled}
                    as={this.productSpaLink}
                    // @ts-expect-error cannot pass extra props to be used in styled component
                    hasActions={hasActions}
                    styles={getAdditionalTitleStyles}
                  >
                    {title}
                  </Link>
                </H3>
                {this.renderStarRating()}
                {hasActions && (
                  <StyledProductActions data-auto="product-actions">
                    {this.renderFavoritesHeartIcon()}
                  </StyledProductActions>
                )}
                {sellerInfo?.isPartneredProduct && <SellerInformation {...sellerInfo} translate={translate} />}
                {!showClubcardPriceOnPromotions && (
                  <StyledPromotionsAboveMobile
                    clickHandler={onPromotionsClick}
                    data-auto="product-promotions"
                    isVerticalTile
                    productDrsChargeAmount={productDrsChargeAmount}
                    promotions={promotions}
                    region={region}
                    renderTermText={renderTermText}
                    showCCPriceLogoInSquare={showCCPriceLogoInSquare}
                    translate={translate}
                    variant={variant}
                  />
                )}
                <DietaryIconWithText content={foodIcons} />
                <StyledProductDetailsContent hasActions={hasActions} variant={variant}>
                  {writeAReviewData && buybox.buyboxType !== VIEW_AND_BUY_BUYBOX && (
                    <WriteAReviewLink {...writeAReviewData} />
                  )}
                  {restOfShelfLink && (
                    <RestOfShelfLink
                      href={restOfShelfLink.href}
                      linkText={restOfShelfLink.linkText}
                      tileType={PRODUCT_TILE}
                    />
                  )}
                  {this.renderInfoMessages('product-info-message-section')}
                </StyledProductDetailsContent>

                {showRestOfShelfButton ? (
                  <RestOfShelfButton restOfShelfUrl={restOfShelfUrl} restOfShelfText={restOfShelfText} />
                ) : showClubcardPriceOnPromotions ? (
                  <StyledPromotionsWithBuyBoxWrapper>
                    <StyledPromotionsAboveMobile
                      clickHandler={onPromotionsClick}
                      clubcardFlashSashInfoText={showClubcardPriceOnPromotions && flashSashInfo?.text}
                      data-auto="product-promotions"
                      isVerticalTile
                      isYellowClubcardPromotion={showClubcardPriceOnPromotions}
                      productDrsChargeAmount={productDrsChargeAmount}
                      promotions={promotions}
                      region={region}
                      renderTermText={renderTermText}
                      showCCPriceLogoInSquare={showCCPriceLogoInSquare}
                      tileVariant={VERTICAL}
                      translate={translate}
                      variant={variant}
                    />
                    {buyBoxWrapper}
                  </StyledPromotionsWithBuyBoxWrapper>
                ) : (
                  buyBoxWrapper
                )}
              </div>

              {this.renderInfoMessages('hidden-medium product-info-section-small')}
            </StyledTiledContent>
            {hasPromotion && (
              <StyledPromotionsMobile variant={variant}>
                <Promotions
                  clickHandler={onPromotionsClick}
                  clubcardFlashSashInfoText={showClubcardPriceOnPromotions && flashSashInfo?.text}
                  data-auto="product-promotions-details-mobile"
                  isVerticalTile
                  isYellowClubcardPromotion={showClubcardPriceOnPromotions}
                  productDrsChargeAmount={productDrsChargeAmount}
                  promotions={promotions}
                  region={region}
                  renderTermText={renderTermText}
                  showCCPriceLogoInSquare={showCCPriceLogoInSquare}
                  tileVariant={VERTICAL}
                  translate={translate}
                />
              </StyledPromotionsMobile>
            )}
          </StyledTileContentWrapper>
        </StyledVerticalTile>
      </StyledVerticalTileWrapper>
    );
  }
}

export default connector(withProductTile(VerticalTile));
