export type MenuItem = {
  label: string;
  name: string;
  externalUrl?: string;
  children?: MenuItem[];
  newTab?: boolean;
  url?: string;
  showExternalIcon?: boolean; // Optional nested children
};

export type Menu = {
  children: MenuItem[];
};

export const MEALS_AND_RECIPE_MENU_KEY = 'mealsAndRecipes';
export const RECIPE_MENU_KEY = 'recipes';
export const MEALS_AND_RECIPES_MENU_GHS: Menu = {
  children: [
    {
      label: 'superDepartment',
      name: 'Breakfast ideas',
      externalUrl: '/zone/breakfast',
    },
    {
      label: 'superDepartment',
      name: 'Lunch ideas',
      externalUrl: '/zone/lunchbox',
    },
    {
      label: 'superDepartment',
      name: 'Dinner ideas',
      url: '/Dinner ideas',
      children: [
        {
          label: 'department',
          name: `That's dinner sorted`,
          externalUrl: '/zone/thats-dinner-sorted',
        },
        {
          label: 'department',
          name: 'Great nights in',
          externalUrl: 'zone/great-nights-in',
        },
        {
          label: 'department',
          name: 'Finest Dine In only £12: Main meal, side, dessert and drink',
          externalUrl: `/buylists/tesco-finest-meal-deal/all-meal-deal`,
        },
      ],
    },
    {
      label: 'superDepartment',
      name: 'Plant based & Vegetarian',
      externalUrl: '/zone/plant-based-and-vegetarian',
    },
    {
      label: 'superDepartment',
      name: 'Recipes - Tesco Real Food',
      externalUrl: 'https://realfood.tesco.com/',
      newTab: true,
      showExternalIcon: true,
    },
  ],
};

export const MEALS_AND_RECIPES_MENU_DEMAND = {
  children: [
    {
      label: 'superDepartment',
      name: 'Whoosh offers',
      externalUrl: '/zone/whoosh-special-offers',
    },
    {
      label: 'superDepartment',
      name: 'Breakfast ideas',
      url: '/Breakfast ideas',
      children: [
        {
          label: 'department',
          name: 'Breakfast essentials',
          externalUrl: '/buylists/whoosh/breakfast-basics',
        },
        {
          label: 'department',
          name: 'Weekend brunch',
          externalUrl: '/buylists/whoosh/brunch',
        },
      ],
    },
    {
      label: 'superDepartment',
      name: 'Lunch ideas',
      url: '/Lunch ideas',
      children: [
        {
          label: 'department',
          name: '£3.40 meal deal',
          externalUrl: '/buylists/whoosh-meal-deals/all-3pound-meal-deal',
        },
        {
          label: 'department',
          name: 'Lunch essentials',
          externalUrl: '/buylists/whoosh/lunch-essentials',
        },
      ],
    },
    {
      label: 'superDepartment',
      name: 'Dinner ideas',
      url: '/Dinner ideas',
      children: [
        {
          label: 'department',
          name: 'Whoosh Mealtimes',
          externalUrl: '/zone/whoosh-mealtimes',
        },
        {
          label: 'department',
          name: '£12 Finest Meal Deal',
          externalUrl: '/buylists/whoosh-meal-deals/all-finest-meal-deal',
        },
        {
          label: 'department',
          name: 'Stir Fry Meal Deal',
          externalUrl: '/buylists/whoosh-meal-deals/all-stir-fry-meal-deal',
        },
      ],
    },
    {
      label: 'superDepartment',
      name: 'Recipes - Tesco Real Food',
      newTab: true,
      showExternalIcon: true,
      externalUrl: 'https://realfood.tesco.com/',
    },
  ],
};

export const VARIATION_KEY = 'topNav-mealsandrecipes';
export const FEATURE_KEY = 'GHS-<region>_1946_topNav-mealsandrecipes';

export type ExperimentConfig = {
  experiment: string;
  variation: string;
  [VARIATION_KEY]: string;
};

export enum MealsAndRecipesVariants {
  DEFAULT = 'default',
  MEALS_AND_RECIPES = 'mealsandrecipes',
}

export const MealsAndRecipesFeature = {
  featureKey: FEATURE_KEY,
  variationKey: VARIATION_KEY,
};

//analytics
export const DELAY = 'delay';
export const NOW = 'now';
export const MEALS_AND_RECIPES_NAV = 'meals & recipes';
export const ANALYTICS_TYPE = 'meals & recipes navigation';
export const TESCO_REAL_FOOD = 'Recipes - Tesco Real Food';
export const TESCO_REAL_FOOD_VALUE = 'Recipes Tesco Real Food';
export const TOP_NAV = 'top nav';
