import { Request, Response } from 'express';
import { getFeatureConfigurationFromReqRes } from '#/lib/optimizely-manager';
import { favFilterNavigation, favFilterNavigationVariants } from '#/experiments/oop-1662/constants';
import { PROMOTION, AVAILABLE } from '#/constants/facet-categories';
import { FacetList } from '#/lib/requests/graphql-queries/fragments/facet-lists.defs';

type FilterNavigationVariants = {
  experiment: string;
  variation: string;
  stickyConfig: favFilterNavigationVariants;
};

type FavFacetsToRemove = {
  [key: string]: Array<string>;
};

const getVariant = (req: Request, res: Response): FilterNavigationVariants => {
  const { featureKey } = favFilterNavigation;
  const attributes = { hasFavourites: String(!res.locals.props?.user?.isFirstTimeBuyer) };

  return getFeatureConfigurationFromReqRes(req, res, featureKey, favFilterNavigation, attributes);
};

export const getFilterNavigationVariant = (req: Request, res: Response): FilterNavigationVariants =>
  getVariant(req, res);

export const getIsStickySearchFilterEnabled = (variant: favFilterNavigationVariants): boolean =>
  variant === favFilterNavigationVariants.search;

export const getIsFilterDropDownEnabled = (variant: favFilterNavigationVariants): boolean =>
  variant === favFilterNavigationVariants.filterDropDown;

export const getIsFavFilterNavigationDisabled = (variant: favFilterNavigationVariants): boolean =>
  variant === favFilterNavigationVariants.default;

export const getIsStickyFilterVariant = (variant: favFilterNavigationVariants): boolean =>
  [favFilterNavigationVariants.filterDropDown, favFilterNavigationVariants.filterDropDownTick].includes(variant);

export const updateFacetList = (req: Request, res: Response, facetList: FacetList = []): FacetList => {
  const { stickyConfig } = getFilterNavigationVariant(req, res);
  const isFilterDropDownEnabled = getIsFilterDropDownEnabled(stickyConfig);
  const isStickyVariant = getIsStickyFilterVariant(stickyConfig);
  const isFavFilterNavigationDisabled = getIsFavFilterNavigationDisabled(stickyConfig);

  if (isFilterDropDownEnabled) {
    const promotionsFacet = facetList.find(facet => facet.category === PROMOTION);
    if (promotionsFacet) {
      promotionsFacet.facets[0].facetName = req.t('sort-and-filter:dropdown-variant.on-offer-now');
    }
  }

  if (isStickyVariant) {
    const availableFacet = facetList.find(facet => facet.category === AVAILABLE);
    if (availableFacet) {
      availableFacet.facets[0].facetName = req.t('sort-and-filter:available-sticky');
    }
  }

  if (!isFavFilterNavigationDisabled) {
    facetList = removeFiltersFromFacetList(facetList, req.c('favFacetsToRemove') as FavFacetsToRemove);
  }

  return facetList;
};

const removeFiltersFromFacetList = (facetList: FacetList = [], facetsToRemove: FavFacetsToRemove): FacetList => {
  const updatedFacetList: FacetList = [];
  for (let i = 0; i < facetList.length; i++) {
    const { category, facets } = facetList[i];
    if (facetsToRemove[category]) {
      const listOfFiltersToRemove = facetsToRemove[category];
      const updateFacets = facets.filter(({ facetId }) => !listOfFiltersToRemove.includes(facetId));
      if (updateFacets.length > 0) {
        updatedFacetList.push({ ...facetList[i], facets: updateFacets });
      }
    } else {
      updatedFacetList.push({ ...facetList[i] });
    }
  }

  return updatedFacetList;
};
