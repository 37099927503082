import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ResponsiveBackground } from '#/components/cms-driven/responsive-background';
import TradeCta from '#/components/cms-driven/trade_cta';
import Link from '#/components/link';
import { ValueTile } from '#/components/cms-driven/value-tile';
import { convertValueTileItems } from '#/lib/dcs/adapter/valueTileAdapter';
import helpers from '#/lib/decorators/helpers';
import {
  includesRegularPrice,
  includesActualClubcardPrice
} from '#/lib/dcs/dcs-helpers';
import { withErrorBoundary } from '#/components/shared/error-boundary';
import { CMS_ERROR_PREFIX } from '#/constants/dcs';

const TradeStamp = props => {
  const {
    c: config,
    priority,
    image,
    link,
    title,
    valueTile,
    breakpoints,
    maxWidth,
    componentClickHandler,
    placement
  } = props;

  const returnOfferFlag = () => {
    const { symbol, unitSymbol } = config('currency');
    const region = config('REGION');

    if (valueTile && valueTile.template_type) {
      return (
        <ValueTile
          {...convertValueTileItems(valueTile, symbol, unitSymbol, region)}
          offerDetailHiddenText={props.t('promotions:offer-details')}
          termsApplyHiddenText={props.t('promotions:terms-apply')}
        />
      );
    }

    return null;
  };

  const stampClasses = classnames('ui-stamp-widget', [
    `ui-stamp-widget__${priority}`
  ]);
  const onClick = () => componentClickHandler?.(props);

  const OuterTag = link?.href ? Link : 'div';

  const enableRegularPrice = config('regularPriceDisplay');

  const hasRegularPrice = includesRegularPrice(valueTile);

  const isAnyXXClubcardPriceVariant =
    enableRegularPrice &&
    valueTile?.template_type === 'any_x_for_x_pound_clubcard_price';

  const isActualClubcardPriceDisplayed =
    hasRegularPrice &&
    includesActualClubcardPrice(
      valueTile?.valueTileType,
      valueTile?.template_type
    );

  const isFlatTileWithoutActualClubardPrice =
    valueTile?.is_flat_tile && !isActualClubcardPriceDisplayed;

  const stampImgContainerClasses = classnames('stamp__img-container', {
    'stamp__img-container--regular-price': enableRegularPrice,
    'stamp__img-container--regular-price-any_xx-variant': isAnyXXClubcardPriceVariant,
    'stamp__img-container--non-regular-price-flat-tile':
      enableRegularPrice && isFlatTileWithoutActualClubardPrice
  });

  const tradeStampCaptionClasses = classnames('stamp--caption-wrapper-title', {
    'stamp--caption-wrapper-title-regular-price': enableRegularPrice
  });

  return (
    <div className={stampClasses} onClick={onClick}>
      <div className="stamp-widget--inner">
        <OuterTag className="stamp--a" to={link?.href}>
          <div className={stampImgContainerClasses}>
            <ResponsiveBackground
              className="stamp--img"
              url={image}
              breakpoints={breakpoints}
              maxWidth={maxWidth}
              uid={props.placement}
              alt=" "
            />
            {returnOfferFlag()}
          </div>
          <span className="stamp--caption-wrapper">
            <h3 className={tradeStampCaptionClasses}>{title}</h3>
            {link?.href && <TradeCta uid={placement} centered />}
          </span>
        </OuterTag>
      </div>
    </div>
  );
};

TradeStamp.propTypes = {
  breakpoints: PropTypes.array,
  c: PropTypes.func,
  classes: PropTypes.string,
  componentClickHandler: PropTypes.func,
  f: PropTypes.func,
  image: PropTypes.string,
  link: PropTypes.shape({
    href: PropTypes.string
  }),
  maxWidth: PropTypes.number.isRequired,
  placement: PropTypes.number,
  priority: PropTypes.string,
  t: PropTypes.func,
  title: PropTypes.string.isRequired,
  valueTile: PropTypes.object
};

const wrappedTradeStamp = withErrorBoundary(
  CMS_ERROR_PREFIX,
  'TradeStamp'
)(helpers(['t', 'c'])(TradeStamp));

export { wrappedTradeStamp as TradeStamp };
