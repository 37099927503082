import React from 'react';
import { ConnectedProps } from 'react-redux';
import helpers from '#/lib/decorators/helpers';
import { DeleteButton } from '#/components/product-tiles/common/delete-button';
import { TTranslateFunc } from '#/lib/records/helpers.defs';
import { getLanguageLink } from '#/reducers/app';
import { deleteFavouriteItemJSOnly } from '#/actions/results-actions';
import { connect } from '#/lib/render/connect-deep-compare';
import analyticsBus from '#/analytics/analyticsBus';
import { basicEvent } from '#/analytics/types/basic';
import { FAVORITES, NOW } from '#/analytics/constants';

type OwnProps = {
  productId: string;
  productTitle: string;
  t: TTranslateFunc;
};

type OwnState = {
  deleteFavoriteUrl: string;
};

export const mapStateToProps = (state: Store, { productId }: OwnProps): OwnState => {
  const deleteFavoriteUrl = getLanguageLink(state, `/favorites/items/${productId}`);

  return {
    deleteFavoriteUrl,
  };
};

const mapDispatchToProps = { deleteFavouriteItemJSOnly };

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = OwnProps & OwnState & ConnectedProps<typeof connector>;

const DeleteFavorite = (props: Props): JSX.Element => {
  const { t: translate, productTitle, deleteFavoriteUrl, deleteFavouriteItemJSOnly } = props;

  const deleteButtonHandler = (): void => {
    sendAnalytics();
    deleteFavouriteItemJSOnly(deleteFavoriteUrl);
  };

  const sendAnalytics = (): void => {
    basicEvent(analyticsBus, {
      type: FAVORITES,
      value: 'delete favourites items',
      action: NOW,
    });
  };

  const accessibleText = translate('product-list:remove-from-favourites', { productName: productTitle });

  return <DeleteButton handleDeleteClick={deleteButtonHandler} accessibleText={accessibleText} />;
};

export default connector(helpers(['t'])(DeleteFavorite));
