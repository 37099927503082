import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { spacing } from '@ddsweb/selectors';
import { visuallyHide } from '@ddsweb/foundation';
import { connect } from '#/lib/render/connect-deep-compare';
import {
  DIETARY_ICONS,
  DIETARY_BACKGROUND_COLOR,
  productTileDietaryInfoVariants,
} from '#/experiments/oop-1922/constants';
import {
  VeganIcon,
  VegetarianIcon,
  VegetarianLogoIcon,
  VeganLogoIcon,
} from '#/experiments/oop-1922/components/assets/icons';
import { DietaryTag } from '#/experiments/oop-1922/types';
import { getProductTileDietaryInfoVariant } from '#/experiments/oop-1922/selectors';

export const VisuallyHidden = styled.span`
  ${visuallyHide};
`;

const StyleWrapper = styled.div`
  background: ${DIETARY_BACKGROUND_COLOR};
  display: flex;
  justify-content: center;
  align-items: center;

  &.icon-wrapper {
    padding: 3px ${spacing.xxx};
    min-height: ${spacing.xl};
    min-width: ${spacing.xl};
  }
`;

const { dietaryTags, dietaryLogo } = productTileDietaryInfoVariants;

const mapDispatchToProps = (state: Store): { dietaryVariantType: string } => ({
  dietaryVariantType: getProductTileDietaryInfoVariant(state),
});

export const Component = ({ label, dietaryVariantType }: DietaryTag): JSX.Element | null => {
  if (!label || typeof label !== 'string') return null;

  const { VEGETARIAN, VEGAN } = DIETARY_ICONS;

  const tagLabel = label.toLowerCase();

  switch (tagLabel) {
    case VEGETARIAN:
      return (
        <StyleWrapper title={tagLabel} className={classnames({ 'icon-wrapper': dietaryVariantType !== dietaryTags })}>
          {dietaryVariantType === dietaryLogo ? <VegetarianLogoIcon /> : <VegetarianIcon />}
          <VisuallyHidden>{tagLabel}</VisuallyHidden>
        </StyleWrapper>
      );
    case VEGAN:
      return (
        <StyleWrapper title={tagLabel} className={classnames({ 'icon-wrapper': dietaryVariantType !== dietaryTags })}>
          {dietaryVariantType === dietaryLogo ? <VeganLogoIcon /> : <VeganIcon />}
          <VisuallyHidden>{tagLabel}</VisuallyHidden>
        </StyleWrapper>
      );
    default:
      return null;
  }
};

export const DietarySvgIconComponent = connect(mapDispatchToProps)(Component);
