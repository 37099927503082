import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from '../../lib/render/connect-deep-compare';

const mapStateToProps = state => {
  const { ui } = state;
  return {
    amendOrderBarOpen: ui.amendOrderBarOpen,
    groceriesOpen: ui.groceriesOpen,
    leftNavOpen: ui.leftNavOpen,
    overlayShown: ui.overlayShown,
    scrollLocked: ui.scrollLocked,
    isLoading: ui.isLoading
  };
};

@connect(mapStateToProps)
export default class BodyClassNames extends React.Component {
  static propTypes = {
    amendOrderBarOpen: PropTypes.bool.isRequired,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
    ]),
    groceriesOpen: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    leftNavOpen: PropTypes.bool.isRequired,
    overlayShown: PropTypes.bool.isRequired,
    scrollLocked: PropTypes.bool.isRequired
  };

  componentDidMount() {
    this.setBodyClass();
  }

  componentDidUpdate() {
    this.setBodyClass();
  }

  setBodyClass() {
    const classes = classNames({
      'overlay-shown': this.props.overlayShown,
      'groceries-open': this.props.groceriesOpen,
      'left-nav-open': this.props.leftNavOpen,
      'amend-order-bar-open': this.props.amendOrderBarOpen,
      'is-loading': this.props.isLoading
    });

    document.body.className = classes;
  }

  render() {
    if (this.props.children) {
      return React.Children.only(this.props.children);
    }

    return null;
  }
}
