import React from 'react';
import { withRouter } from 'react-router';
import { getDisplayName } from 'react-recompose';
import { getCurrentUrl } from '#/reducers/app';
import { connect } from '#/lib/render/connect-deep-compare';
import { compose } from 'react-recompose';
import analyticsBus from '#/analytics/analyticsBus';
import helpers from '#/lib/decorators/helpers';
import { paginate } from '#/actions/results-actions';
import { updatePageUrlWithoutRefresh, updateParamsInUrl, parseQueryString } from '#/lib/url/url-utils';
import { TConfigFunc } from '#/lib/records/helpers.defs';
import {
  getUpdatingStatus as resultsUpdating,
  getPageNo,
  getShowMoreCount,
  getTotalCount,
  getItemsPerPage,
} from '#/reducers/results';
import { getIsUpdating as filtersUpdating } from '#/reducers/filter';

type OwnState = {
  href: string;
  isUpdating: boolean;
  pageNo: number;
  showMoreCount: number;
  totalAvailableItems: number;
  totalLoadedItems: number;
};
type OwnProps = {
  paginate: (a: object, b: object) => Promise<void>;
  params: object;
  routes: Array<object>;
  c: TConfigFunc;
};

type Props = OwnProps & OwnState;

export const withLoadMore = <P extends object>(Component: React.ComponentType<P>): React.ComponentClass => {
  const mapStateToProps = (state: Store): OwnState => {
    const pageNo = getPageNo(state);
    const itemsPerPage = getItemsPerPage(state);
    const totalLoadedItems = pageNo * itemsPerPage;

    return {
      href: updateParamsInUrl(getCurrentUrl(state), {
        page: pageNo + 1,
      }),
      isUpdating: resultsUpdating(state) || filtersUpdating(state),
      pageNo,
      showMoreCount: getShowMoreCount(state),
      totalAvailableItems: getTotalCount(state),
      totalLoadedItems,
    };
  };

  class WithLoadMore extends React.Component<Props> {
    static displayName: string;
    static WrappedComponent: React.ComponentType<P>;

    handleClick = async (eventCallback: Function): Promise<void> => {
      const { paginate, params, routes, c: config, href } = this.props;
      await paginate({ params: params, routes: routes }, parseQueryString(href));

      analyticsBus().onceTimed(
        'app:asyncPageComplete',
        eventCallback,
        parseInt(config('waitForAsyncPage') as string, 10),
      );
      updatePageUrlWithoutRefresh(window, href);
    };

    render(): JSX.Element | null {
      if (this.props.showMoreCount <= 0) {
        return null;
      }
      return <Component {...(this.props as P)} handleClick={this.handleClick} />;
    }
  }

  WithLoadMore.displayName = `withLoadMore(${getDisplayName(Component)})`;
  WithLoadMore.WrappedComponent = Component;

  const enhance = compose(withRouter, helpers(['t', 'c']), connect(mapStateToProps, { paginate }));

  return enhance(WithLoadMore);
};
