import React, { FC, useEffect } from 'react';
import ContentCarousel from './content-carousel';
import { createSPALink } from '@ddsweb/helpers';
import Link from '#/components/link-check-spa';
import { connect } from '#/lib/render/connect-deep-compare';
import { StyledTextButton } from '#/experiments/oop-1826/components/search-pills/styled';
import { storeDataForAnalytics } from '#/analytics/types/search';
import { RELATED_SEARCH_TYPE } from '#/experiments/oop-1826/constants';
import { getLanguageLink } from '#/reducers/app';
import { SEARCH, RIGHT, SECONDARY } from '#/experiments/oop-1826/components/search-pills/constants';
import { triggerRelatedSearchAnalyticsEvent } from '#/experiments/oop-1826/helpers/analytics';

type SpaLinkProps = {
  onClick?: () => void;
  to: string;
};

type OwnState = {
  relatedSearchUrl: string;
};

const mapStateToProps = (state: Store): OwnState => ({
  relatedSearchUrl: getLanguageLink(state, '/search?query='),
});

const SpaLink: FC<SpaLinkProps> = createSPALink(Link, ({ onClick, href, ...restProps }) => ({
  onClick: onClick,
  to: href,
  ...restProps,
}));

const handleClick = (item: string, index: number, itemCount: number): void => {
  const options = {
    searchTermTyped: item,
    searchTerm: item,
    termSelectionMethod: RELATED_SEARCH_TYPE,
    suggestions: itemCount,
    selectedSuggestionIndex: index + 1,
  };

  storeDataForAnalytics(RELATED_SEARCH_TYPE, options);
};

const SearchPills = ({
  relatedSearchTerms,
  relatedSearchUrl,
}: {
  relatedSearchTerms: Array<{ suggestionQuery: string }>;
  relatedSearchUrl: string;
}): JSX.Element => {
  useEffect(() => {
    triggerRelatedSearchAnalyticsEvent();
  }, []);

  return (
    <ContentCarousel id="search-pills-carousel" itemWidth="100%">
      {relatedSearchTerms?.map(({ suggestionQuery }, index) => (
        <StyledTextButton
          key={suggestionQuery}
          title={suggestionQuery}
          variant="textButton"
          icon={{ graphic: SEARCH, position: { global: RIGHT } }}
          href={`${relatedSearchUrl}${suggestionQuery}`}
          spaLink={SpaLink}
          onClick={handleClick.bind(null, suggestionQuery, index, relatedSearchTerms.length)}
          buttonVariant={SECONDARY}
        >
          {suggestionQuery}
        </StyledTextButton>
      ))}
    </ContentCarousel>
  );
};

export default connect(mapStateToProps)(SearchPills);
