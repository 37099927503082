import React, { ReactNode } from 'react';
import moment from 'moment-timezone';
import { COLLECTION, ShoppingMethod } from '#/constants/shopping-methods';
import { formatUrl, formatWeekText } from '#/lib/slot/slot-utils';
import { getLanguage, getLanguageLink, getTimezone } from '#/reducers/app';
import { getLocationsExpanded, getLocationsPage, getSelectedWeekRange, getSlotRangeByWeeks } from '#/reducers/slot';
import helpers from '#/lib/decorators/helpers';
import { connect } from '#/lib/render/connect-deep-compare';
import { updatePageUrlWithoutRefresh } from '#/lib/url/url-utils';
import { formatDate } from '#/lib/slot/slot-range-utils';
import { showLocationsPage, showLessLocations } from '#/actions/location-action-creators';
import { changeSlotGroup, changeSlotDate } from '#/actions/slot-action-creators';
import { TConfigFunc, TTranslateFunc } from '#/lib/records/helpers.defs';
import { SlotGroup } from '#/custom-typings/redux-store/slot.defs';
import { StyledSlotContainer, StyledTabsMenu } from './styled';

type DateRange = {
  start: moment.Moment;
  end: moment.Moment;
};

export type TStateProps = {
  language: string;
  timezone: string;
  locationsExpanded: boolean | undefined;
  locationsPage: number;
  languageLink: (url: string) => string;
  selectedWeekRange: DateRange;
  slotRangeByWeeks: DateRange[];
};

type TDispatchProps = {
  changeSlotGroup: (nextGroup: SlotGroup, date: string) => Promise<void>;
  showLocationsPage: (page: number) => void;
  showLessLocations: () => void;
  changeSlotDate: (date: string) => Promise<void>;
};

export type TOwnProps = TStateProps &
  TDispatchProps & {
    c: TConfigFunc;
    t: TTranslateFunc;
    children: ReactNode;
    shoppingMethod: ShoppingMethod;
    selectedLocation: { locationId: string };
    showLessLocations: () => void;
    showLocationsPage: (page: number) => void;
    changeSlotDate: (date: string) => Promise<void>;
    currentSlotGroup: number;
  };

const mapStateToProps = (state: Store): TStateProps => {
  return {
    language: getLanguage(state),
    timezone: getTimezone(state),
    locationsExpanded: getLocationsExpanded(state),
    locationsPage: getLocationsPage(state),
    languageLink: (url: string): string => getLanguageLink(state, url),
    selectedWeekRange: getSelectedWeekRange(state),
    slotRangeByWeeks: getSlotRangeByWeeks(state),
  };
};

const mapDispatchToProps = {
  changeSlotGroup,
  showLocationsPage,
  showLessLocations,
  changeSlotDate,
};

const SlotWeekTabs = (props: TOwnProps): JSX.Element => {
  const { slotRangeByWeeks, selectedWeekRange, timezone, language, c: config, t: translate } = props;
  const formats = { dateRange: { long: 'D MMM', short: 'D' } };

  const tabsMenuItems = slotRangeByWeeks.map(week => {
    const title = formatWeekText(week, timezone, language, formats);
    const accessibleTitle = formatWeekText(
      week,
      timezone,
      language,
      config('accessibleFormat') as {
        dateRange?: { long: string; short: string } | undefined;
        month?: string | undefined;
      },
    );
    const accessibleReadableTitle = accessibleTitle
      .replace('-', ` ${translate('slots:common.time-interval.date-to')} `)
      .concat(translate('slots:common.time-interval.date-until'))
      .split('.')
      .join('');

    return {
      href: '#',
      id: title,
      label: title,
      ariaLabel: accessibleReadableTitle,
      ariaControls: `${translate('slots:common.between')}-${accessibleTitle}-tab`.toLocaleLowerCase(),
      onClick: (event: React.MouseEvent<HTMLElement>) => slotDateChangeHandler(event, week.start),
    };
  });

  const selectedWeekRangeId = formatWeekText(selectedWeekRange, timezone, language, formats);

  const getActiveTabContent = (): ReactNode => {
    return React.Children.toArray(props.children).find(child => {
      if (React.isValidElement(child)) {
        return child.props.isActive;
      }
      return false;
    });
  };

  const getSlotUrl = (date: moment.Moment, slotGroup: number): string => {
    const { languageLink, shoppingMethod, selectedLocation } = props;

    return languageLink(
      formatUrl(
        shoppingMethod,
        date,
        selectedLocation !== null && shoppingMethod === COLLECTION ? selectedLocation.locationId : null,
        slotGroup,
      ),
    );
  };

  const slotDateChangeHandler = (event: React.MouseEvent<HTMLElement>, weekStartDate: moment.Moment): void => {
    event.preventDefault();

    const {
      showLessLocations,
      showLocationsPage,
      locationsExpanded,
      locationsPage,
      changeSlotDate,
      currentSlotGroup,
    } = props;

    const formattedDate = formatDate(weekStartDate);

    if (locationsExpanded) {
      showLocationsPage(locationsPage || 1);
    } else {
      showLessLocations();
    }

    updatePageUrlWithoutRefresh(window, getSlotUrl(weekStartDate, currentSlotGroup));

    changeSlotDate(formattedDate);
  };

  return (
    <div>
      <StyledTabsMenu menuItems={tabsMenuItems} activeTabID={selectedWeekRangeId} />
      <StyledSlotContainer role="tabpanel">{getActiveTabContent()}</StyledSlotContainer>
    </div>
  );
};

export default helpers(['c', 't'])(connect(mapStateToProps, mapDispatchToProps)(SlotWeekTabs));
