/**
 * Takes current inputs required for Google DFP both containers and reports
 * Returns object to pass to DFP component as prop
 * REQUIRED: data.user
 * OPTIONAL: data.shelf/ data.search are the current additional fields require
 * but we are allowing any additional fields to be passed as this may change
 */
export default data => {
  const nocid = data.user.isAuthenticated ? 'no' : 'yes';
  const returnTargetObject = {
    ...data,
    nocid,
    store: data.user.storeId,
    tppid: data.user.hashedUId
  };

  delete returnTargetObject.user;

  return returnTargetObject;
};
