import analyticsBus from '../../analytics/analyticsBus';
import { basicEvent } from '../types/basic';
export const emitRegisterAnalytics = value => {
  const payload = {
    type: 'register',
    value: value,
    action: 'now'
  };
  basicEvent(analyticsBus, payload);
};
