export default function getIn<O, T>(
  obj: O,
  path: string | string[],
  defaultValue: T | undefined = undefined,
): T | O | undefined {
  const travel = regexp =>
    String.prototype.split
      .call(path, regexp)
      .filter(Boolean)
      .reduce((res, key) => (res !== null && res !== undefined ? res[key] : res), obj);
  const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/); // for handling lodash string paths .,[] notations as per (you don't need lodash)
  return result === undefined || result === obj ? defaultValue : result;
}
