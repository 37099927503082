import { ADDRESSES } from '#/constants/spa-resource';
import { receiveAddresses } from '#/actions/addresses-action-creators';
import { getAddressesStateResource } from '#/selectors/resources';

export const type = ADDRESSES;

export function handler(dispatch, getState): void {
  const state = getState();
  const addressData = getAddressesStateResource(state)?.data;

  if (!addressData) {
    return;
  }

  dispatch(receiveAddresses(addressData));
}
