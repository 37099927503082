import addressToSpecifiedFormat from '#/lib/string-formatting/format-address';
import { Address } from '#/experiments/oop-2205/components/address-slot-info-reskin';

export const formatAddress = (address: Address, { withName = false } = {}): string => {
  const fields: string[] = (withName ? ['name'] : []).concat([
    'addressLine1',
    'addressLine2',
    'addressLine3',
    'addressLine4',
    'addressLine5',
    'addressLine6',
    'postcode',
  ]);

  const dedup = (arr: string[]): string[] => [...new Set(arr)];

  const formattedAddress = dedup(addressToSpecifiedFormat(address, fields));
  return formattedAddress.join(', ');
};
