import React from 'react';
import { connect } from '#/lib/render/connect-deep-compare';
import helpers from '#/lib/decorators/helpers';
import {
  getDeliveryLocation,
  getItems,
  getTrolleyShoppingMethod,
  getFreeDeliveryStatus,
  hasValidSlot,
} from '#/selectors/trolley';
import SlotContextCard from '#/components/slots/slot-context-card';
import { TrolleyState } from '#/custom-typings/redux-store/trolley.defs';
import { AddressData } from '#/reducers/addresses';

type TState = {
  currentShoppingMethod: TrolleyState['shoppingMethod'];
  freeDeliveryStatus: string | undefined;
  hasTrolleyItems: boolean;
  hasValidSlot: boolean;
  isFDOXMessagingEnabled: string;
  trolleyDeliveryAddress: AddressData | undefined;
};

const mapStateToProps = (state: Store, ownProps: TOwnProps): TState => {
  const isFDOXMessagingEnabled = ownProps.f('showFDOXMessaging');
  return {
    currentShoppingMethod: getTrolleyShoppingMethod(state),
    freeDeliveryStatus: isFDOXMessagingEnabled && getFreeDeliveryStatus(state),
    hasTrolleyItems: Boolean(getItems(state)?.length),
    hasValidSlot: hasValidSlot(state),
    isFDOXMessagingEnabled,
    trolleyDeliveryAddress: getDeliveryLocation(state),
  };
};

type TOwnProps = {
  f: (key: string) => string;
  hideNoSlotContextCard: boolean;
  isSlotExpired: boolean;
  onDemandSlotExpiredCB: () => void;
};

type TProps = TOwnProps & TState;

const SlotContextCardContainer: React.FC<TProps> = (props: TProps) => {
  const {
    currentShoppingMethod,
    freeDeliveryStatus,
    hasTrolleyItems,
    hasValidSlot,
    isFDOXMessagingEnabled,
    isSlotExpired,
    onDemandSlotExpiredCB,
    trolleyDeliveryAddress,
  } = props;

  if (!hasValidSlot || isSlotExpired) {
    return null;
  }

  return (
    <SlotContextCard
      address={trolleyDeliveryAddress}
      freeDeliveryStatus={freeDeliveryStatus}
      hasTrolleyItems={hasTrolleyItems}
      isFDOXMessagingEnabled={isFDOXMessagingEnabled}
      onDemandSlotExpiredCB={onDemandSlotExpiredCB}
      shoppingMethod={currentShoppingMethod}
    />
  );
};

export default helpers(['f'])(connect(mapStateToProps)(SlotContextCardContainer));
