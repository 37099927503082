import React from 'react';
import helpers from '#/lib/decorators/helpers';
import classnames from 'classnames';
import { connect } from '#/lib/render/connect-deep-compare';
import { getSelectedShoppingMethod } from '#/reducers/slot';
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { isOnDemandShoppingMethod } from '#/lib/shopping-method-util';
import Instructions from '#/components/instructions';
import { getIsRenderShoppingMethodInstruction } from '#/selectors/slot';
import { getShowSlotUIReskinV2 } from '#/experiments/oop-2205/selectors';
import { TrolleyState } from '#/custom-typings/redux-store/trolley.defs';

const mapStateToProps = (state: any) => {
  const selectedShoppingMethod = getSelectedShoppingMethod(state);
  return {
    selectedShoppingMethod,
    isSlotUIReskin: getShowSlotUIReskinV2(state),
    showShoppingInstruction:
      getIsRenderShoppingMethodInstruction(state) && !isOnDemandShoppingMethod(selectedShoppingMethod),
  };
};

type TOwnProps = {
  c: (key: string) => string;
  isSlotUIReskin: boolean;
  showShoppingInstruction: boolean;
  selectedShoppingMethod: TrolleyState['shoppingMethod'];
};

const ShoppingInstruction: React.FC<TOwnProps> = (props: TOwnProps) => {
  const { c: config, isSlotUIReskin, showShoppingInstruction, selectedShoppingMethod } = props;

  return showShoppingInstruction ? (
    <ReactCSSTransitionGroup
      component="div"
      transitionName="expand"
      transitionEnterTimeout={300}
      transitionLeaveTimeout={300}
    >
      <div
        className={classnames('content-section-with-border', {
          'content-section-with-border__with-radius': !isSlotUIReskin,
        })}
        key="hasSlot"
      >
        <div className="book-a-slot--instructions">
          <Instructions
            maxLength={Number(config('deliveryInstructionsMaxCharacters')) || 0}
            shoppingMethod={selectedShoppingMethod}
          />
        </div>
      </div>
    </ReactCSSTransitionGroup>
  ) : null;
};
export default helpers(['c'])(connect(mapStateToProps)(ShoppingInstruction));
