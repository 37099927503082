import SafeForm from '#/components/shared/safe-form';
import Spinner from '@ddsweb/spinner';
import React, { useCallback, useState } from 'react';
import { SubmitButton, SpinnerContainer } from './styled';

export type OwnProps = {
  children?: React.ReactNode;
  buttonAutoTag?: string;
  onFormSubmit?: () => void;
  submitUrl: string;
  spinnerText: string;
  buttonText: string;
  variant: string;
};

const FormSubmit: React.FC<OwnProps> = ({
  children,
  buttonAutoTag,
  submitUrl,
  buttonText,
  onFormSubmit,
  variant,
  spinnerText,
}: OwnProps) => {
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const submitForm = useCallback(() => {
    setIsSubmittingForm(true);

    if (typeof onFormSubmit === 'function') {
      onFormSubmit();
    }
  }, []);

  const text = isSubmittingForm ? spinnerText : buttonText;

  return (
    <SafeForm action={submitUrl} method="POST" onSubmit={submitForm}>
      {children}
      <SubmitButton block data-auto={buttonAutoTag} type="submit" disabled={isSubmittingForm} variant={variant}>
        {text}
        {isSubmittingForm && (
          <SpinnerContainer>
            <Spinner accessibleLabel={spinnerText} size="xs" />
          </SpinnerContainer>
        )}
      </SubmitButton>
    </SafeForm>
  );
};

export default FormSubmit;
