/* eslint-disable @typescript-eslint/explicit-function-return-type */
import styled from 'styled-components';
import LocalNavigation from '@ddsweb/local-navigation';
import { BREAKPOINTS, TESCO_MODERN_FONT_FAMILY } from '@ddsweb/constants';
import { spacing, fontSize, colors } from '@ddsweb/selectors';
import { media } from '@ddsweb/foundation';

export const StyledMainMenu = styled(LocalNavigation)`
  //TODO - To be reverted once 1512px max-width support is done by other teams
  // max-width: 1512px;
  max-width: 1260px;
  margin: auto;
  font-family: ${TESCO_MODERN_FONT_FAMILY};
  & li.ddsweb-main-menu__menu-item {
    &:nth-child(1) {
      display: none;
    }

    & div.ddsweb-menu__menu-item-popup-icon-container svg {
      width: ${spacing.md};
      height: ${spacing.sm};
      stroke-width: 2px;
    }
    & div.ddsweb-menu__menu-item-popup-icon-container {
      top: 45%;
    }
    & a.ddsweb-menu__menu-item-node {
      white-space: nowrap;
    }
  }

  & nav.menu-items ul li:last-child {
    display: block;
  }

  ${(props: { menuItems: string[]; visibleMenuItems: { aboveDesktop: number } }) =>
    props?.menuItems?.length > props?.visibleMenuItems?.aboveDesktop
      ? media.aboveDesktop`
      li:last-child {
        display: block;
      }
  `
      : media.aboveDesktop`
      li:last-child {
        display: none !important;
      }
  `};

  & li.ddsweb-sliding-submenu__menu-item {
    background-color: white;
    font-size: ${fontSize.xs};

    & a.ddsweb-menu__menu-item-node {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      position: relative;
      display: block;
      padding-right: 30px;

      & span.ddsweb-link__icon-container {
        position: absolute;
        top: 10px;
        right: 0;
        margin-right: ${spacing.sm};
      }
    }
  }
  .ddsweb-main-menu__menu-list.ddsweb-menu__menu-list {
    height: auto;
  }
  .ddsweb-button--external-text-button {
    width: auto;
    /** using !important to bypass the global {top: 8px} rule applied to close button*/
    top: 0px !important; // lesshint importantRule: false
  }
`;

export const StyledMenuContainer = styled.div`
 @media (max-width: ${BREAKPOINTS.tabletLarge - 1}px) {
     display:none;
  }
  & nav.menu-items {
   //TODO - To be reverted once 1512px max-width support is done by other teams
    // max-width: 1512px;
      max-width: 1260px;
  }
  background-color: ${colors.backgroundDark};
  position: relative;
  margin: 0 auto;
  z-index: ${(props: { isMenuOpen: boolean }): number => (props.isMenuOpen ? 12 : 11)};
  border-bottom: 1px solid ${colors.lines};
  border-top: 1px solid ${colors.lines};
  & nav.menu-items .ddsweb-main-menu__menu-list {
    background-color: ${colors.backgroundDark};
  }
  & nav.menu-items ul li.ddsweb-main-menu__menu-item {
    ${media.belowTabletLarge`
      &:last-child{
        display:block;
      }
    `}
    ${media.aboveTablet`
      &:last-child{
        display:block;
      }
    `}
    ${media.belowDesktop`
      &:last-child{
        display:block;
      }
    `}
  }

   .ddsweb-modal__mask {
      background: linear-gradient(to bottom, transparent 500px, rgba(0, 0, 0, 0.7) 500px);
    }

  .ddsweb-modal__modal-container {
   position: absolute;
    left: 0;
    width: 100vw;
    margin-left: calc(-50vw + 50%);
  }
    .ddsweb-modal__inner-container {
     //TODO - To be reverted once 1512px max-width support is done by other teams
    // max-width: 1512px;
    max-width: 1260px;
    margin: auto;
    border-bottom: none;
    }
`;
