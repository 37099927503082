import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { SECONDARY } from '@ddsweb/constants';
import helpers from '#/lib/decorators/helpers';
import { TConfigFunc, TTranslateFunc } from '#/lib/records/helpers.defs';
import { backToTopHandler } from '#/components/shared/back-to-top';
import { debounce } from '#/utils/misc';
import analyticsBus from '#/analytics/analyticsBus';
import { basicEvent } from '#/analytics/types/basic';
import { getIsAmendBasket } from '#/selectors/trolley';
import { connect } from '#/lib/render/connect-deep-compare';
import { BACK_TO_THE_TOP, FAVORITES, NOW } from '#/analytics/constants';
import { StyledButton, StyledIcon } from '#/components/shared/back-to-top/styled';
import { getDisplayCookieInterrupt } from '#/reducers/ui';

type StateProps = {
  isAmendBasket: boolean;
  displayCookieInterrupt: boolean;
};

type BackToTopProps = StateProps & {
  t: TTranslateFunc;
  c: TConfigFunc;
  analyticsType?: string;
  variant?: string;
  isTrolleyPage?: boolean;
};

type HTMLElementRef = {
  element: HTMLElement | null;
};

const mapStateToProps = (state: Store): StateProps => ({
  isAmendBasket: getIsAmendBasket(state),
  displayCookieInterrupt: getDisplayCookieInterrupt(state),
});

export const BackToTopFixed = ({
  t: translate,
  c: config,
  isAmendBasket,
  displayCookieInterrupt,
  analyticsType = FAVORITES,
  variant = SECONDARY,
  isTrolleyPage = false,
}: BackToTopProps): JSX.Element => {
  const [shouldShowButton, setShouldShowButton] = useState(false);
  const [bottom, setBottom] = useState(0);
  const cookieBanner = useRef<HTMLElementRef>({ element: null });

  const showHideButton = debounce((): void => {
    setShouldShowButton(window.scrollY > window.innerHeight * 2);
  }, 300);

  const updateBottom = (): void => {
    const cookieBoundingRect = cookieBanner.current.element?.getBoundingClientRect() ?? { top: 0 };

    setBottom(window.innerHeight - cookieBoundingRect?.top);
  };

  useEffect(() => {
    cookieBanner.current.element = document.getElementById('sticky-bar-cookie-wrapper');
    window.addEventListener('scroll', showHideButton);
    window.addEventListener('resize', updateBottom);
    updateBottom();
    showHideButton();

    return (): void => {
      window.removeEventListener('scroll', showHideButton);
      window.removeEventListener('resize', updateBottom);
    };
  }, []);

  const onClickHandler = (event: Event): void => {
    event.preventDefault();

    backToTopHandler();

    basicEvent(analyticsBus, {
      action: NOW,
      type: analyticsType,
      value: BACK_TO_THE_TOP,
    });
  };

  const hasCookieBanner = config('cookiePreferences') && displayCookieInterrupt;
  return (
    <>
      {shouldShowButton && (
        <StyledButton
          onClick={onClickHandler}
          bottom={bottom}
          aria-label={translate('common:back-to-the-top')}
          icon={<StyledIcon className={classnames('icon')} iconVariant={variant} />}
          className={classnames({
            'trolley-page': isTrolleyPage && !isAmendBasket,
            'with-amend-basket': isAmendBasket && !isTrolleyPage,
            'trolley-in-amend': isTrolleyPage && isAmendBasket,
            'with-cookie-banner': hasCookieBanner,
          })}
          variant={variant}
        />
      )}
    </>
  );
};

export default connect(mapStateToProps)(helpers(['t', 'c'])(BackToTopFixed));
