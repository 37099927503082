import { Component } from 'react';
import PropTypes from 'prop-types';
import _Helpers from '../../lib/helpers';
import getAnalyticsBus from '../../analytics/analyticsBus';

export const contextTypes = {
  asset: PropTypes.func.isRequired,
  c: PropTypes.func.isRequired,
  currentPathname: PropTypes.func.isRequired,
  f: PropTypes.func.isRequired,
  hasFeature: PropTypes.func.isRequired,
  host: PropTypes.func.isRequired,
  isChromeless: PropTypes.func.isRequired,
  isStockAndroid: PropTypes.func.isRequired,
  l: PropTypes.func.isRequired,
  loginUrl: PropTypes.func.isRequired,
  registrationUrl: PropTypes.func.isRequired,
  scenario: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  timezone: PropTypes.func.isRequired,
  basePath: PropTypes.func.isRequired
};

export default class CreateContext extends Component {
  static propTypes = {
    analyticsBus: PropTypes.object,
    children: PropTypes.node,
    helpers: PropTypes.object
  };

  static childContextTypes = {
    analyticsBus: PropTypes.func.isRequired,
    helpers: PropTypes.shape(contextTypes).isRequired
  };

  constructor(props, context) {
    super(props, context);

    if (process.env.CLIENT_SIDE) {
      this._helpers = new _Helpers(document);
    } else {
      this._helpers = new _Helpers(this.props.helpers);
    }

    const analyticsBus = this.props.analyticsBus || this.getAnalyticsBus();

    this._childContext = {
      analyticsBus: () => analyticsBus,
      helpers: this._helpers
    };
  }

  getAnalyticsBus() {
    return getAnalyticsBus();
  }

  getChildContext() {
    return this._childContext;
  }

  render() {
    return this.props.children;
  }
}
