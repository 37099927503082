import React, { FunctionComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'react-recompose';
import { getAppRegion } from '#/reducers/app';
import helpers from '#/lib/decorators/helpers';
import { getIsAmendBasket, getItems, getSlot } from '#/selectors/trolley';
import { hasItems } from '#/lib/trolley/trolley-utils';
import DefaultLayout from '#/components/layouts/default';
import { NavItem } from 'custom-typings/redux-store/taxonomy.defs';

export type OwnProps = {
  c: (key: string) => unknown;
  cmsNav: NavItem;
};

type StateProps = {
  bookedSlot: unknown;
  hasTrolleyItems: boolean;
  isAmendBasket: boolean;
  region: string;
};

const mapStateToProps = (state: Store): StateProps => {
  return {
    bookedSlot: getSlot(state),
    hasTrolleyItems: hasItems(getItems(state)),
    isAmendBasket: getIsAmendBasket(state),
    region: getAppRegion(state),
  };
};
const connector = connect(mapStateToProps);

type Props = OwnProps & ConnectedProps<typeof connector>;

const BookASlotLayoutSPAComponent: FunctionComponent<Props> = ({
  bookedSlot,
  children,
  cmsNav,
  hasTrolleyItems,
  isAmendBasket,
  region,
}) => {
  let customClassName = '';
  if (!isAmendBasket) {
    customClassName = hasTrolleyItems ? 'large-bottom-bar' : 'small-bottom-bar';
  }

  customClassName += region === 'UK' ? ' white-background' : '';

  return (
    <DefaultLayout
      bookedSlot={bookedSlot}
      isAmendBasket={isAmendBasket}
      cmsNav={cmsNav}
      customClassName={customClassName}
      headerFixed
    >
      {children}
    </DefaultLayout>
  );
};

const enhance = compose<Props, OwnProps>(helpers(['c']), connector);

export const BookASlotLayoutSPA: FunctionComponent<OwnProps> = (enhance(
  BookASlotLayoutSPAComponent,
) as unknown) as FunctionComponent<OwnProps>;
