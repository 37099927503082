import React from 'react';
import PropTypes from 'prop-types';
import Button from '@ddsweb/button';
import Icon from '@ddsweb/icon';
import { connect } from '#/lib/render/connect-deep-compare';
import Modal from '#/components/shared/modal';
import ModalContent from '#/components/shared/modal/content';
import { IMAGE_ZOOM_MODAL } from '#/constants/modal-names';
import ImageViewer from '#/components/products/product-details-templates/shared/image-viewer';
import { closeModal } from '#/actions/ui-action-creators';
import { getProductItem } from '#/selectors/product-details';
import helpers from '#/lib/decorators/helpers';
import {
  getProductIsForSale,
  hasPromotion,
  getProductDefaultImageUrl,
  getProductTitle,
  getProductImages,
  getProductId
} from '#/selectors/item';

const ImageZoomModal = props => {
  const handleClick = () => {
    props.closeModal(IMAGE_ZOOM_MODAL);
  };
  const item = props.item;
  const available = getProductIsForSale(item);
  const defaultImage =
    (props.modalData && props.modalData.selectedUrl) ||
    getProductDefaultImageUrl(item);
  return (
    <Modal className="modal__image-wrapper" closeModal={handleClick}>
      <ModalContent>
        <div className="grocery-product-modal">
          <ImageViewer
            available={available}
            defaultImageUrl={defaultImage}
            id={getProductId(item)}
            images={getProductImages(item)}
            hasPromotion={available && hasPromotion(item)}
            title={getProductTitle(item)}
            zoomable={false}
          />
          <div className="grocery-product-modal__close-button display-only-in-js">
            <Button
              id="close-zoom-modal"
              className="display-only-in-js"
              variant="secondary"
              aria-label={props.t('product-tile:close-zoom-modal')}
              onClick={handleClick}
              icon={<Icon graphic="close" />}
            />
          </div>
          <div className="display-only-in-no-js visually-hidden">
            {props.t('product-tile:close-zoom-modal-no-js')}
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};

ImageZoomModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  item: PropTypes.object,
  modalData: PropTypes.shape({
    selectedUrl: PropTypes.string
  }),
  t: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  item: getProductItem(state)
});

export default connect(mapStateToProps, { closeModal })(
  helpers(['t'])(ImageZoomModal)
);
