import produce from 'immer';
import { getResourceData } from '../resources/resource-util';
import { RECEIVE_REVIEWS, REPORT_A_REVIEW, UPDATE_REVIEWS } from '#/constants/action-types';
import { PRODUCT_DETAILS } from '#/constants/spa-resource';
import { ProductsReviewDetails, RatingAndReviewsState, Review } from '#/custom-typings/redux-store/rating-reviews.defs';
import { ProductDetails } from '#/resources/product-details.defs';
import { Entry, PageInfo, Reviews, Stats } from '#/lib/requests/get-product-reviews.defs';
import { RatingAndReviewActions } from '#/actions/ratings-reviews-action-creators.defs';
import { ServerProps } from '#/reducers/types.defs';
import { Resources } from '#/resources/types.defs';

export const formatReviews = ({ reviews }: { reviews?: Reviews }): ProductsReviewDetails | undefined => {
  const { entries = [], stats = {}, product = {}, info = {} } = reviews || {};
  if (product.tpnb) {
    return {
      [product.tpnb]: {
        stats: { ...stats },
        reviews: getReviews(entries),
        pageInfo: info,
      },
    };
  }
};

const getReviews = (entries: Array<Entry>): Array<Review> =>
  entries.map(({ author, rating, reviewId, submissionDateTime, summary, syndicated, syndicationSource, text }) => ({
    rating: rating && rating.value,
    nickname: (author && author.nickname) || '',
    submissionDateTime: submissionDateTime || null,
    ownReview: !!(author && author.authoredByMe),
    summary: summary || (text || '').substr(0, 50),
    text: text || '',
    reviewId: reviewId,
    syndicated: syndicated,
    syndicationSource: syndicationSource,
  }));

export default function ratingsReviews(
  state: RatingAndReviewsState = {},
  action: RatingAndReviewActions,
): RatingAndReviewsState {
  switch (action.type) {
    case RECEIVE_REVIEWS:
      return { ...formatReviews(action.value) };
    case UPDATE_REVIEWS:
      return { ...action.value };
    case REPORT_A_REVIEW:
      return reportReviewForModeration(state, action.value);
    default:
      return state;
  }
}

const getReviewsFromResource = (resources: Resources): Reviews | undefined => {
  const productDetails: Partial<ProductDetails> = getResourceData(resources, PRODUCT_DETAILS) || {};

  return productDetails.product?.reviews;
};

export function getDefaultStateFromProps(props: ServerProps): ProductsReviewDetails {
  const reviews = getReviewsFromResource(props.resources);

  return {
    ...formatReviews({ reviews }),
  };
}

export const getProductReviewsByTPNB = ({ ratingsReviews }: Store, tpnb: string): Array<Review> =>
  (ratingsReviews[tpnb] && ratingsReviews[tpnb].reviews) || [];

export const getProductReviewStatsByTPNB = ({ ratingsReviews }: Store, tpnb: string): Stats => {
  const statsForTpnb = ratingsReviews[tpnb] && ratingsReviews[tpnb].stats;

  return statsForTpnb || {};
};

export const getProductReviewsPageInfoByTPNB = ({ ratingsReviews }: Store, tpnb: string): PageInfo => {
  const pageInfo = ratingsReviews[tpnb] && ratingsReviews[tpnb].pageInfo;

  return pageInfo || {};
};

export const getReviewsCount = (state: Store, tpnb: string): number => getProductReviewsByTPNB(state, tpnb).length;

export const getReviewById = (state: Store, tpnb: string, reviewId: string): Review | undefined =>
  getProductReviewsByTPNB(state, tpnb).find(review => review.reviewId === reviewId);

export const getHasUserReportedReview = (state: Store, tpnb: string, reviewId: string): boolean => {
  const review = getReviewById(state, tpnb, reviewId);
  return !!(review && review.reportedForModeration);
};

export const reportReviewForModeration = (
  state: RatingAndReviewsState,
  { tpnb, reviewId }: { tpnb: string; reviewId: string },
): RatingAndReviewsState => {
  const reviewInfoByTpnb = state[tpnb];

  if (!reviewInfoByTpnb || !reviewInfoByTpnb.reviews) {
    return {
      ...state,
    };
  }

  const reviews = produce(reviewInfoByTpnb.reviews, draft => {
    const review = draft.find(review => review.reviewId === reviewId);

    if (review) {
      review.reportedForModeration = true;
    }
  });

  return {
    ...state,
    [tpnb]: {
      ...state[tpnb],
      reviews,
    },
  };
};
