import React from 'react';
import helpers from '#/lib/decorators/helpers';
import SafeForm from '#/components/shared/safe-form';
import { Separator } from '#/components/product-tiles/horizontal-tile/variant/trolley-tile/separator';
import { StyledTextareaGroup } from '../styled';
import { TTranslateFunc } from '#/lib/records/helpers.defs';
import PickerNotesProgress from '#/components/product-tiles/horizontal-tile/variant/trolley-tile/picker-notes-progress';
import { isValidText } from '#/utils/text-utils';

const MAX_COUNT = 55;

type Props = {
  productId: string;
  t: TTranslateFunc;
  returnUrl: string;
  substitutionOption: string;
  onBlur: () => void;
  onChange: (arg: string) => void;
  defaultValue?: string;
  status: string | undefined;
};

const PickerNotesDropdown = ({
  returnUrl,
  substitutionOption,
  productId,
  t: translate,
  onBlur,
  onChange,
  defaultValue = '',
  status,
}: Props): React.ReactNode => {
  const [value, setValue] = React.useState(defaultValue);
  const [error, setError] = React.useState(value.length >= MAX_COUNT);

  const onChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    const value = event.target.value;
    const newCount = value.length;
    if (isValidText(value) && newCount <= MAX_COUNT) {
      setValue(value);
      setError(newCount >= MAX_COUNT);
      onChange(value);
    }
  };

  const onBlurHandler = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    const value = event.target.value;
    if (!error && value) {
      onBlur();
    }
  };

  return (
    <>
      <Separator />
      <SafeForm action="" method="post">
        <StyledTextareaGroup
          count={value.length}
          countLabelText=""
          maxCount={MAX_COUNT}
          maxLength={MAX_COUNT}
          rows={1}
          defaultValue={defaultValue}
          value={value}
          id={`notes-${productId}-js`}
          labelText={translate('product-tile:picker-note-instructions')}
          required
          onBlur={onBlurHandler}
          onChange={onChangeHandler}
          placeholder={translate('product-tile:picker-note-placeholder')}
          countTextProps={{ emphasized: true, 'aria-live': 'polite' }}
        />
        <input type="hidden" name="productID" value={productId} />
        <input type="hidden" name="substitutionOption" value={substitutionOption} />
        <input type="hidden" id="returnUrl" name="returnUrl" value={returnUrl} />
      </SafeForm>
      <PickerNotesProgress status={status} showErrorMessage={error} />
    </>
  );
};

export default helpers(['t'])(PickerNotesDropdown);
