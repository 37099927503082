import { Attribute } from '#/analytics/bertie/types';

export const getDietaryAttributeValue = (foodIcons: Array<string> | undefined): Attribute[] => {
  const attributes: Attribute[] = [];

  if (foodIcons && Array.isArray(foodIcons)) {
    foodIcons.forEach(icon => attributes.push({ attributeValue: icon.toLowerCase() }));
  }

  return attributes;
};
