import {
  COLLECTION,
  DELIVERY,
  ON_DEMAND,
  DYNAMIC_DELIVERY,
  DELIVERY_TODAY
} from '#/constants/shopping-methods';

export function isClickAndCollectShoppingMethod(shoppingMethod) {
  return shoppingMethod === COLLECTION;
}

export const isDeliveryShoppingMethod = shoppingMethod =>
  shoppingMethod === DELIVERY;

export const isOnDemandShoppingMethod = shoppingMethod =>
  shoppingMethod === ON_DEMAND;

export const isOnDemandDeliveryShoppingMethod = shoppingMethod =>
  shoppingMethod === ON_DEMAND;

export const isCollectionShoppingMethod = shoppingMethod =>
  shoppingMethod === COLLECTION;

export const onDemandTranslationSuffix = isOnDemandShoppingMethod =>
  isOnDemandShoppingMethod ? `-${ON_DEMAND}` : '';

export const getOnDemandDynamicDeliveryData = (
  isOnDemandDelivery = false,
  isOnDemandDynamicDelivery = false,
  onDemandDynamicDeliveryTime,
  showDeliveryToday = false
) => {
  if (
    isOnDemandDelivery &&
    isOnDemandDynamicDelivery &&
    onDemandDynamicDeliveryTime?.isDynamicDeliveryTimeAvailable &&
    !showDeliveryToday
  ) {
    const {
      isDynamicDeliveryTimeAvailable,
      unit,
      range: { min, max }
    } = onDemandDynamicDeliveryTime;

    return {
      onDemandDynamicDeliverySuffix: DYNAMIC_DELIVERY,
      unit,
      min,
      max,
      showDynamicTime: isDynamicDeliveryTimeAvailable
    };
  } else if (
    isOnDemandDelivery &&
    isOnDemandDynamicDelivery &&
    showDeliveryToday
  ) {
    return {
      onDemandDynamicDeliverySuffix: DELIVERY_TODAY
    };
  }

  return {
    onDemandDynamicDeliverySuffix: ''
  };
};
