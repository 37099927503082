const months = 'január_február_marec_apríl_máj_jún_júl_august_september_október_november_december'.split(
  '_'
);
const monthsShort = 'jan_feb_mar_apr_máj_jún_júl_aug_sep_okt_nov_dec'.split(
  '_'
);

function plural(n) {
  return n > 1 && n < 5;
}

function translate(number, withoutSuffix, key, isFuture) {
  const result = `${number} `;

  switch (key) {
    case 's': // a few seconds / in a few seconds / a few seconds ago
      return withoutSuffix || isFuture ? 'pár sekúnd' : 'pár sekundami';
    case 'm': // a minute / in a minute / a minute ago
      return withoutSuffix ? 'minúta' : isFuture ? 'minútu' : 'minútou';
    case 'mm': // 9 minutes / in 9 minutes / 9 minutes ago
      if (withoutSuffix || isFuture) {
        return result + (plural(number) ? 'minúty' : 'minút');
      }

      return `${result}minútami`;

    case 'h': // an hour / in an hour / an hour ago
      return withoutSuffix ? 'hodina' : isFuture ? 'hodinu' : 'hodinou';
    case 'hh': // 9 hours / in 9 hours / 9 hours ago
      if (withoutSuffix || isFuture) {
        return result + (plural(number) ? 'hodiny' : 'hodín');
      }

      return `${result}hodinami`;

    case 'd': // a day / in a day / a day ago
      return withoutSuffix || isFuture ? 'deň' : 'dňom';
    case 'dd': // 9 days / in 9 days / 9 days ago
      if (withoutSuffix || isFuture) {
        return result + (plural(number) ? 'dni' : 'dní');
      }

      return `${result}dňami`;

    case 'M': // a month / in a month / a month ago
      return withoutSuffix || isFuture ? 'mesiac' : 'mesiacom';
    case 'MM': // 9 months / in 9 months / 9 months ago
      if (withoutSuffix || isFuture) {
        return result + (plural(number) ? 'mesiace' : 'mesiacov');
      }

      return `${result}mesiacmi`;

    case 'y': // a year / in a year / a year ago
      return withoutSuffix || isFuture ? 'rok' : 'rokom';
    case 'yy': // 9 years / in 9 years / 9 years ago
      if (withoutSuffix || isFuture) {
        return result + (plural(number) ? 'roky' : 'rokov');
      }

      return `${result}rokmi`;
  }
}
const config = {
  months,
  monthsShort,
  monthsParse: (function(months, monthsShort) {
    let i;
    const _monthsParse = [];

    for (i = 0; i < 12; i++) {
      // use custom parser to solve problem with July (červenec)
      _monthsParse[i] = new RegExp(`^${months[i]}$|^${monthsShort[i]}$`, 'i');
    }

    return _monthsParse;
  })(months, monthsShort),
  weekdays: 'nedeľa_pondelok_utorok_streda_štvrtok_piatok_sobota'.split('_'),
  weekdaysShort: 'ne_po_ut_st_št_pi_so'.split('_'),
  weekdaysMin: 'ne_po_ut_st_št_pi_so'.split('_'),
  longDateFormat: {
    LT: 'H:mm',
    L: 'DD.MM.YYYY',
    LL: 'D. MMMM YYYY',
    LLL: 'D. MMMM YYYY LT',
    lll: 'ddd D. MMM YYYY',
    LLLL: 'dddd D. MMMM YYYY LT'
  },
  calendar: {
    sameDay: '[dnes o] LT',
    nextDay: '[zajtra o] LT',
    nextWeek() {
      switch (this.day()) {
        case 0:
          return '[v nedeľu o] LT';
        case 1:
        case 2:
          return '[v] dddd [o] LT';
        case 3:
          return '[v stredu o] LT';
        case 4:
          return '[vo štvrtok o] LT';
        case 5:
          return '[v piatok o] LT';
        case 6:
          return '[v sobotu o] LT';
      }
    },
    lastDay: '[včera o] LT',
    lastWeek() {
      switch (this.day()) {
        case 0:
          return '[minulú nedeľu o] LT';
        case 1:
        case 2:
          return '[minulý] dddd [o] LT';
        case 3:
          return '[minulú stredu o] LT';
        case 4:
        case 5:
          return '[minulý] dddd [o] LT';
        case 6:
          return '[minulú sobotu o] LT';
      }
    },
    sameElse: 'L'
  },
  relativeTime: {
    future: 'za %s',
    past: 'pred %s',
    s: translate,
    m: translate,
    mm: translate,
    h: translate,
    hh: translate,
    d: translate,
    dd: translate,
    M: translate,
    MM: translate,
    y: translate,
    yy: translate
  },
  ordinal: '%d.',
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4 // The week that contains Jan 4th is the first week of the year.
  }
};

export default config;
