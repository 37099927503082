import * as accountPhoneNumberSignature from './account-phone-number';
import * as addressesSignature from './addresses';
import * as appStateSignature from './app-state';
import * as buyListIdSignature from './buylist-id';
import * as buyListSignature from './buylist';
import * as dcsPageSignature from './dcs-page';
import * as experimentsSignature from './experiments';
import * as favoritesCarouselSignature from './favorites-carousel';
import * as favoritesSignature from './favorites';
import * as formSignature from './form';
import * as fulfilmentMetadataSignature from './fulfilment-metadata';
import * as fulfilmentOptionsDetailSignature from './fulfilment-options-details';
import * as haveYouForgottenSignature from './have-you-forgotten';
import * as homePageSignature from './home-page';
import * as multiSearchListSignature from './multi-search-list';
import * as orderListDetailsSignature from './order-list-details';
import * as productCategoriesSignature from './product-categories';
import * as productDetailsSignature from './product-details';
import * as productReviewsSignature from './product-reviews';
import * as productsByCategorySignature from './products-by-category';
import * as promotionsAllSignature from './promotions-all';
import * as promotionsByDepartmentSignature from './promotions-by-department';
import * as promotionsContentSignature from './promotions-content';
import * as promotionsIdOrTypeSignature from './promotions-id-or-type';
import * as searchSignature from './search';
import * as subscriptionsSignature from '#/resource-signatures/subscriptions';
import * as taxonomySignature from './taxonomy';
import * as cmsNavSignature from './cmsNav';
import * as trolleyContentsSignature from './trolley-contents';
import * as usualsSignature from './usuals';
import * as zonePageSignature from './zone-page';
import * as slotSignature from './slot';
import * as locationSignature from './location';
import * as lastOrderTabSignature from '#/resource-signatures/last-order';
import * as fulfilmentEstimatedArrivalSingature from '#/resource-signatures/fulfilment-estimated-arrival';
import * as offersForYouSignature from '#/experiments/oop-1557/resource-signatures/offers-for-you';
import * as browseEntrypointsSignature from '#/experiments/oop-1894/resource-signatures/browseEntrypoints';

export default {
  [accountPhoneNumberSignature.type]: accountPhoneNumberSignature,
  [addressesSignature.type]: addressesSignature,
  [appStateSignature.type]: appStateSignature,
  [browseEntrypointsSignature.type]: browseEntrypointsSignature,
  [buyListIdSignature.type]: buyListIdSignature,
  [buyListSignature.type]: buyListSignature,
  [dcsPageSignature.type]: dcsPageSignature,
  [experimentsSignature.type]: experimentsSignature,
  [favoritesCarouselSignature.type]: favoritesCarouselSignature,
  [favoritesSignature.type]: favoritesSignature,
  [formSignature.type]: formSignature,
  [fulfilmentMetadataSignature.type]: fulfilmentMetadataSignature,
  [fulfilmentOptionsDetailSignature.type]: fulfilmentOptionsDetailSignature,
  [haveYouForgottenSignature.type]: haveYouForgottenSignature,
  [homePageSignature.type]: homePageSignature,
  [multiSearchListSignature.type]: multiSearchListSignature,
  [orderListDetailsSignature.type]: orderListDetailsSignature,
  [productCategoriesSignature.type]: productCategoriesSignature,
  [productDetailsSignature.type]: productDetailsSignature,
  [productReviewsSignature.type]: productReviewsSignature,
  [productsByCategorySignature.type]: productsByCategorySignature,
  [promotionsAllSignature.type]: promotionsAllSignature,
  [promotionsByDepartmentSignature.type]: promotionsByDepartmentSignature,
  [promotionsContentSignature.type]: promotionsContentSignature,
  [promotionsIdOrTypeSignature.type]: promotionsIdOrTypeSignature,
  [searchSignature.type]: searchSignature,
  [taxonomySignature.type]: taxonomySignature,
  [cmsNavSignature.type]: cmsNavSignature,
  [trolleyContentsSignature.type]: trolleyContentsSignature,
  [usualsSignature.type]: usualsSignature,
  [lastOrderTabSignature.type]: lastOrderTabSignature,
  [zonePageSignature.type]: zonePageSignature,
  [slotSignature.type]: slotSignature,
  [locationSignature.type]: locationSignature,
  [fulfilmentEstimatedArrivalSingature.type]: fulfilmentEstimatedArrivalSingature,
  [subscriptionsSignature.type]: subscriptionsSignature,
  [offersForYouSignature.type]: offersForYouSignature
};
