import analyticsBus from '#/analytics/analyticsBus';
import { DELAY } from '#/analytics/constants';
import { basicEvent } from '#/analytics/types/basic';
import { FAV_MENU_KEY, FAV_ANALYTICS_NAVIGATION } from '#/experiments/oop-1716/constants';

export const emitFavTopDropdownItemClick = (tabName: string): void => {
  basicEvent(analyticsBus, {
    action: DELAY,
    type: FAV_ANALYTICS_NAVIGATION,
    value: `${FAV_MENU_KEY}:${tabName}`,
  });
};
