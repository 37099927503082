import styled from 'styled-components';
import TextButtonLink from '@beans/link';
import { spacing } from '@beans/selectors';
import { mediumSmallMax, mediumMin } from '#/constants/viewports';

export const StyledTextButtonLink = styled(TextButtonLink)`
  &.hidden-small {
    width: 100%;
    @media (max-width: ${mediumSmallMax}) {
      display: none;
    }
  }
  &.hidden-small-medium {
    width: 100%;
    margin-top: ${spacing.sm};
    @media (min-width: ${mediumMin}) {
      display: none;
    }
  }
  &.beans-link__anchor {
    padding: 0 ${spacing.lg};
  }
`;
