import React from 'react';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';

const ErrorPopdown = ({ errors }) => {
  return (
    <ReactCSSTransitionGroup
      transitionName="slide"
      transitionEnterTimeout={500}
      transitionLeaveTimeout={500}
      aria-live="assertive"
      className="errors"
      component="div"
      role="alert"
    >
      {errors != null && errors.length > 0 ? (
        <ReactCSSTransitionGroup
          transitionName="slide"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={500}
          component="ul"
        >
          {errors.map(error => (
            <li key={error}>{error}</li>
          ))}
        </ReactCSSTransitionGroup>
      ) : null}
    </ReactCSSTransitionGroup>
  );
};

ErrorPopdown.propTypes = {
  errors: PropTypes.array.isRequired
};

export default ErrorPopdown;
