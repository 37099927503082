import React from 'react';
import styled from 'styled-components';
import { media } from '@ddsweb/foundation';
import Text from '@ddsweb/text';
import { Heading } from '@ddsweb/heading';
import { colors, fontSize, spacing } from '@ddsweb/selectors';
import Modal from '@ddsweb/modal';
import LinkButtonWithFeedback from '#/components/shared/link-button-with-feedback';

export const StyledBoldText = styled(Text)`
  font-weight: bold;
`;

export const StyledModal = styled(Modal)`
  z-index: 9999;

  p {
    line-height: 1.5;
  }

  .ddsweb-modal__mask {
    padding-bottom: 40px;
  }

  .ddsweb-modal__modal-container {
    height: 423px;
  }

  .ddsweb-modal__content-container {
    overflow: hidden;
  }

  .ddsweb-modal__close-link {
    background-color: ${colors.white};
    color: ${colors.tescoBlue};

    &:hover,
    &:focus {
      box-shadow: rgba(255, 255, 255, 0.4) 0px 0px 0px 4px;
      transition-duration: 0.3s;
      transition-timing-function: ease;
      transition-delay: 0s;
      transition-property: box-shadow;

      &:after {
        opacity: 0;
      }
    }
  }

  .ddsweb-modal__scrollable-container > *:not(:last-child) {
    margin-bottom: ${spacing.md};
  }
`;

export const ModalHeading = styled(Heading)`
  font-size: ${fontSize.md};
  line-height: 1.2;
`;

export const ModalButtonContainer = styled.div`
  display: flex;
  flex-direction: row;

  & .ddsweb-button--text-button,
  & .beans-link__text {
    font-size: ${fontSize.xs};
  }

  & .ddsweb-button--text-button {
    height: 40px;
    padding: 0px 20px;

    &:hover,
    &:focus {
      box-shadow: rgba(0, 83, 159, 0.4) 0px 0px 0px 4px;
      transition-duration: 0.3s;
      transition-timing-function: ease;
      transition-delay: 0s;
      transition-property: box-shadow;

      &:after {
        opacity: 0;
      }
    }
  }

  & > * {
    width: auto;
  }

  & > *:not(:last-child) {
    margin: 0 ${spacing.md} ${spacing.sm} 0;
  }

  ${media.aboveTablet`
    flex-direction: row;

    & > *:not(:last-child) {
      margin: 0 ${spacing.sm} 0 0;
    }
  `}
`;

export const JoinClubCardButton = styled((props: Record<string, unknown>) => <LinkButtonWithFeedback {...props} />)`
  && {
    font-size: ${fontSize.xs};
  }
`;
