import { RECENT_ORDER_DROPDOWN_ID, favoritesRecentOrderVariants } from '#/experiments/oop-1720/constants';
import { LAST_ORDER_TAB } from '#/constants/favorites';
import { getShouldShowOrderDropdown } from '#/experiments/oop-1720/selectors';
import { PREVIOUS_ORDER_VALUE } from '#/experiments/oop-1720/constants';
import moment from 'moment-timezone';
import { createSelector } from 'reselect';
import { COLLECTED, DELIVERED } from '#/constants/order-statuses';
import { TOrder } from '#/custom-typings/redux-store/orders-ddl.defs';
import { getCurrentUrl, getTimezone } from '#/reducers/app';
import { getOrderItems } from '#/selectors/order-list-details';
import { getQueryParamByKey } from '#/lib/url/url-utils';

export const getShouldShowNewDropDown = (variant: favoritesRecentOrderVariants): boolean =>
  variant === favoritesRecentOrderVariants.newDropdown;

export const getShouldShowDDSDropdown = (variant: favoritesRecentOrderVariants): boolean =>
  variant === favoritesRecentOrderVariants.ddsDropdown;

export const getRecentOrderContextValue = (state: Store, contextValue: string): string => {
  const currentUrl = getCurrentUrl(state);
  const currentTab = getQueryParamByKey(currentUrl, 'tab');
  if (currentTab === LAST_ORDER_TAB && getShouldShowOrderDropdown(state)) {
    const orderIndex = (document?.getElementById(RECENT_ORDER_DROPDOWN_ID) as HTMLSelectElement)?.selectedIndex | 0;
    return `${PREVIOUS_ORDER_VALUE}:${orderIndex + 1}`;
  }
  return contextValue;
};

export const getOrderItemsList = (state: Store): TOrder[] => getOrderItems(state) || [];

export const transformItems = (orderItems: TOrder[], timezone: string): TOrder[] => {
  const currentDate = moment.utc().tz(timezone);
  const sixWeeksAgo = currentDate.clone().subtract(6, 'weeks');

  const fulfilOrderItems = orderItems.filter(
    order =>
      moment.utc(order.slot.end).isSameOrAfter(sixWeeksAgo) &&
      (order.status === COLLECTED || order.status === DELIVERED),
  );

  const updatedOrderItems = [...fulfilOrderItems].sort((a, b) => {
    const dateA = moment(b.slot.end);
    const dateB = moment(a.slot.end);

    // sort in descending order (latest dates first)
    return dateA.isAfter(dateB) ? 1 : dateA.isBefore(dateB) ? -1 : 0;
  });

  return updatedOrderItems;
};

export const getRecentOrderItems = createSelector([getOrderItemsList, getTimezone], transformItems);
