// @ts-nocheck
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import styled, { css } from 'styled-components';
import { colors, fontSize, spacing } from '@ddsweb/selectors';
import { Promotions, PromotionsProps } from '#/components/product-tiles/common/promotions';
import { ProductPromotionStyling } from '#/components/product-tiles/common/promotions/styles';
import {
  MEDIUM,
  SMALL_MEDIUM,
  SPACING_MEDIUM,
  SMALL,
  SMALL_MIN,
} from '#/components/product-tiles/temp-style-constants';
import { FLEXI, FIXED } from './constants';
import OffersSashList from '#/components/product-tiles/common/offers-sash-list';

const HEART_ICON_WIDTH = 21;
const HEART_ICON_GUTTER = 12;

const PRODUCT_TILE_ACTIONS_BUTTON = '30px';
const PRODUCT_TILE_ACTIONS_WIDTH = '45px';

const FIXED_TILE_WIDTH = '242px';

export const StyledVerticalTileWrapper = styled.div`
  position: relative;
  max-width: 100%;
  width: ${({ variant }) => (variant === FIXED ? FIXED_TILE_WIDTH : 'initial')};
  height: ${({ variant }) => (variant === FIXED ? '100%' : 'initial')};

  & + .recommender {
    margin-top: ${SPACING_MEDIUM};
  }

  @media ${SMALL_MIN} {
    width: ${({ variant }) => (variant === FIXED ? FIXED_TILE_WIDTH : '100%')};
    .flexi-tile {
      height: 100%;
    }
  }
`;

export const StyledVerticalTile = styled.div`
  background-color: ${colors.background};
  border: 1px solid ${colors.lines};
  overflow: ${({ shouldEnableOverflow }) => (shouldEnableOverflow ? 'visible' : 'hidden')};
  position: relative;
  max-width: 100%;
  height: 100%;

  ${({ isUnavailable }) =>
    isUnavailable &&
    css`
      border-color: ${colors.lines};

      .product-info-message {
        display: none;

        &.with-warning-background {
          display: block;
        }
      }
    `}
`;

export const StyledTileContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100%;

  .product-info-message-section {
    text-align: left;
    display: ${({ variant }) => (variant === FLEXI ? 'none' : 'initial ')};
  }

  .product-info-section-small {
    clear: both;
    margin-left: 0;
    display: ${({ variant }) => (variant === FLEXI ? 'initial' : 'none ')};
  }

  @media ${MEDIUM} {
    .product-info-message-section {
      display: block;
      padding: 0;
    }
  }
`;

const columnContentStyles = css`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;

  .product-details--wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .product-image-wrapper {
    float: none;
    height: 135px;
    margin: 0 auto;
    width: 135px;

    .product-image {
      height: 135px;
      width: 135px;
    }
  }
`;

const rowContentStyles = css`
  .product-details--wrapper {
    margin-left: 84px;
    padding-left: ${SPACING_MEDIUM};
  }

  .product-image-wrapper {
    float: left;
    height: 84px;
    width: 84px;

    ${({ showSquareOfferSash }) =>
      showSquareOfferSash &&
      css`
        padding-top: 56px;

        @media ${MEDIUM} {
          padding-top: 0;
        }
      `}

    .product-image {
      height: 84px;
      width: 84px;
    }
  }
`;

export const StyledTiledContent = styled.div`
  min-height: 104px;
  padding: ${SPACING_MEDIUM};
  width: 100%;

  h3 {
    margin-bottom: ${spacing.xs};
  }

  ${({ variant }) => (variant === FLEXI ? rowContentStyles : columnContentStyles)};

  @media ${SMALL_MIN} {
    ${columnContentStyles}

    h3 {
      margin-bottom: ${spacing.md};
    }

    .product-details--wrapper {
      margin: ${css`
        ${spacing.md} 0 0
      `};
      padding: 0;
    }
  }
`;

export const StyledBuyBoxWrapper = styled.div`
  margin-top: ${spacing.xx};
  display: flex;
  flex-grow: 1;

  @media ${MEDIUM} {
    margin: 0;
    padding: ${spacing.md} 0 0;

    .buybox-container {
      padding: 0;
    }
  }
`;

export const StyledProductDetailsContent = styled.div`
  a {
    padding: 0 0 ${spacing.xs};
  }

  ${({ hasActions, variant }) =>
    variant === FLEXI &&
    hasActions &&
    css`
      padding-right: ${HEART_ICON_WIDTH + HEART_ICON_GUTTER}px;
    `}

  @media ${MEDIUM} {
    text-align: left;
    padding-right: initial;
  }
`;

export const StyledPromotionsMobile = styled.div`
  border-top: 1px solid ${colors.lines};
  padding: ${spacing.sm};
  display: ${({ variant }) => (variant === FIXED ? 'none' : 'flex')};

  & > div:first-of-type {
    width: 100%;
  }

  ${ProductPromotionStyling} {
    padding: 0;
  }

  @media ${MEDIUM} {
    display: none;
  }
`;

export const StyledPromotionsAboveMobile = styled((props: PromotionsProps): JSX.Element => <Promotions {...props} />)`
  @media ${SMALL_MEDIUM} {
    display: ${({ variant }) => (variant === FIXED ? 'initial' : 'none')};
  }
`;

export const StyledPromotionsWithBuyBoxWrapper = styled.div`
  margin-top: auto;
`;

export const StyledProductActions = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  .favorite-heart-icon {
    margin: ${SPACING_MEDIUM};
    float: right;
  }

  form {
    float: right;
  }

  .favourite-icon {
    float: left;
    width: ${PRODUCT_TILE_ACTIONS_WIDTH};
  }

  .product-tile--delete-container {
    position: relative;
    display: inline-block;
    margin-right: 0;
    width: ${PRODUCT_TILE_ACTIONS_BUTTON};
  }

  .favorite-heart-icon {
    margin: ${HEART_ICON_GUTTER};
  }

  @media ${SMALL} {
    max-width: ${PRODUCT_TILE_ACTIONS_WIDTH};

    .product-tile--delete-container {
      display: block;
      width: ${PRODUCT_TILE_ACTIONS_WIDTH};
    }

    &--has-remove-button {
      .favorite-heart-icon {
        margin-top: 20px;
      }
    }
  }
`;

export const getAdditionalTitleStyles = css`
  @media ${SMALL_MEDIUM} {
    display: inline;
    margin: 0;
    box-decoration-break: clone;
  }

  ${({ hasActions }) =>
    hasActions &&
    css`
      padding: 0 33px 0 0;

      @media ${MEDIUM} {
        padding: 0;
      }
    `}
  text-decoration: none;
  &:focus,
  &:hover {
    .ddsweb-link__text {
      text-decoration: underline;
    }
  }
`;

export const quantityControlsStyles = css`
  .beans-price__text {
    font-size: ${fontSize.sm};
  }
`;

export const StyledOffersSashListWrapper = styled(OffersSashList)`
  margin-right: ${({ shouldWrap }: { shouldWrap: boolean }): string =>
    shouldWrap ? ((spacing.lg as unknown) as string) : '0'};
`;
