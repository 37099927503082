export const BEANS = 'beans';
export const FLEXI = 'flexi';
export const TROLLEY = 'trolley';
export const FAVORITES = 'favorites';
export const USUALS = 'usuals';
export const PREVIOUS_ORDER = 'previous-order';
export const SHOPPING_LISTS = 'shopping-lists';
export const PENDING_ORDER = 'pending-order';
export const BUYLIST = 'buylist';
export const HORIZONTAL_EMBEDDED = 'horizontal-embedded';
export const HAVE_YOU_FORGOTTEN = 'have-you-forgotten';
export const CATEGORY = 'category';
export const SEARCH = 'search';
export const PRODUCT_DETAIL_TILE = 'product-detail-tile';
