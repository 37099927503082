import React, { FC } from 'react';
import helpers from '#/lib/decorators/helpers';
import { TTranslateFunc } from '#/lib/records/helpers.defs';
import { COLLECTION } from '#/constants/shopping-methods';
import { connect } from '#/lib/render/connect-deep-compare';
import { openModal } from '#/actions/ui-action-creators';
import { ConnectedProps } from 'react-redux';
import { CARD_SAVE_MESSAGE_MODAL } from '#/constants/modal-names';
import { InlinePayAtDoorMessage, PayAtDoorButtonLink, PayOnlineButton } from '#/experiments/oop-1725/components/styled';

const connector = connect(null, { openModal });

export type OwnProps = {
  payAtDoorHref: string;
  payOnlineHref: string;
  onPayOnlineButtonClick?: () => void;
  shoppingMethod: string;
};

type HelperProps = {
  t: TTranslateFunc;
};

export type Props = OwnProps & HelperProps & ConnectedProps<typeof connector>;

const InlinePaymentOptionsButtonsComponent: FC<Props> = ({
  payAtDoorHref,
  payOnlineHref,
  t: translate,
  onPayOnlineButtonClick,
  shoppingMethod,
  openModal,
}) => {
  const openCardMessageModal = (): void =>
    openModal(CARD_SAVE_MESSAGE_MODAL, { payAtDoorHref, payOnlineHref, onPayOnlineButtonClick }, false);

  return (
    <>
      <PayOnlineButton
        ddl
        primaryText={translate('checkout:order-summary.oop-1725.pay-online-action-text')}
        href={payOnlineHref}
        onClick={onPayOnlineButtonClick}
      />
      <InlinePayAtDoorMessage>
        <span>{translate('checkout:order-summary.oop-1725.pay-at-door-action-text-prefix')}</span>
        <span> </span>
        <PayAtDoorButtonLink onClick={openCardMessageModal} variant="link">
          {translate(
            shoppingMethod === COLLECTION
              ? 'checkout:order-summary.oop-1725.pay-on-collection-action-text'
              : 'checkout:order-summary.oop-1725.pay-at-door-action-text',
          )}
        </PayAtDoorButtonLink>
      </InlinePayAtDoorMessage>
    </>
  );
};

export const InlinePaymentOptionsButtons: FC<OwnProps> = helpers(['t'])(
  connector(InlinePaymentOptionsButtonsComponent),
);
