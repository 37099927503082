import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from '#/lib/render/connect-deep-compare';
import helpers from '#/lib/decorators/helpers';
import SafeForm from '#/components/shared/safe-form';
import { cancelSlot } from '#/actions/slot-action-creators';
import { formatUrl } from '#/lib/slot/slot-utils';
import { getUserStoreId } from '#/reducers/user';
import {
  getCurrentSlotGroup,
  getSelectedShoppingMethod,
  getSlotsPendingStatus
} from '#/reducers/slot';
import { isUnavailableHDSlotWithCollectLink } from '#/selectors/slot';
import { GRID, LIST, SlotViewModes } from '#/constants/slot-views';
import { COLLECTION } from '#/constants/shopping-methods';
import SlotRecommenderTileBookedSlotButton from '../slot-recommender/slot-recommender-tile-booked-slot-button';

/**
 * Represents a button that displays to the user that this slot is booked.
 */

const mapStateToProps = (state, { c: config }) => ({
  userStoreId: getUserStoreId(state),
  shoppingMethod: getSelectedShoppingMethod(state),
  currentSlotGroup: getCurrentSlotGroup(state),
  isSlotLoading: getSlotsPendingStatus(state),
  applyCollectLinkStyleVariant: isUnavailableHDSlotWithCollectLink(
    state,
    config
  )
});

@helpers(['c', 'l', 't'])
@connect(mapStateToProps, { cancelSlot })
export default class BookedSlot extends PureComponent {
  static propTypes = {
    accessibleLabel: PropTypes.string.isRequired,
    applyCollectLinkStyleVariant: PropTypes.bool.isRequired,
    c: PropTypes.func,
    showAmendSlotChangeCopy: PropTypes.bool,
    cancelSlot: PropTypes.func.isRequired,
    currentSlotGroup: PropTypes.number,
    deliverySaverWarning: PropTypes.bool,
    disableUnbooking: PropTypes.bool,
    isSlotLoading: PropTypes.bool,
    l: PropTypes.func,
    locationId: PropTypes.string,
    onKeyPressed: PropTypes.func,
    saveButtonRef: PropTypes.func.isRequired,
    shoppingMethod: PropTypes.oneOf(['delivery', 'collection']).isRequired,
    slotPageAnchorTag: PropTypes.string.isRequired,
    slotStart: PropTypes.object.isRequired,
    slotViewMode: PropTypes.oneOf([GRID, LIST]).isRequired,
    t: PropTypes.func,
    updated: PropTypes.bool,
    userStoreId: PropTypes.string
  };

  onSubmit = e => {
    e.preventDefault();
    this.props.cancelSlot();
  };

  getReturnurl() {
    const {
      shoppingMethod,
      slotStart,
      currentSlotGroup,
      slotViewMode,
      slotPageAnchorTag,
      locationId
    } = this.props;

    const returnUrl = formatUrl(
      shoppingMethod,
      slotStart,
      shoppingMethod === COLLECTION ? locationId : null,
      currentSlotGroup,
      null,
      null,
      slotViewMode
    );
    if (slotPageAnchorTag) {
      return `${returnUrl}#${slotPageAnchorTag}`;
    }
    return returnUrl;
  }

  render() {
    const {
      accessibleLabel,
      deliverySaverWarning,
      disableUnbooking,
      isSlotLoading,
      applyCollectLinkStyleVariant,
      l: language,
      onKeyPressed,
      showAmendSlotChangeCopy,
      slotPageAnchorTag,
      slotViewMode,
      saveButtonRef,
      t: translate,
      isLargeScreen,
      updated
    } = this.props;

    const deliverySaverWarningArrow = deliverySaverWarning ? (
      <div className="delivery-warning-arrow-container">
        <div className="delivery-warning-arrow-border-container">
          <div className="delivery-warning-arrow-border" />
        </div>
        <div className="delivery-warning-arrow-border-container">
          <div className="delivery-warning-arrow" />
        </div>
      </div>
    ) : null;

    if (slotViewMode === SlotViewModes.SLOT_RECOMMENDER_TILE) {
      return (
        <SlotRecommenderTileBookedSlotButton
          isSlotLoading={isSlotLoading}
          onSubmit={this.onSubmit}
          onKeyDown={onKeyPressed}
          disabled={disableUnbooking}
        />
      );
    }

    return (
      <SafeForm
        action={language('/slots/current?_method=DELETE')}
        method="post"
        onSubmit={this.onSubmit}
      >
        <input name="returnUrl" type="hidden" value={this.getReturnurl()} />
        <button
          data-testid={`${isLargeScreen && 'slot-booked-button-synthetics'}`}
          onKeyDown={onKeyPressed}
          ref={saveButtonRef}
          className={classnames(
            'button button-primary small booked-slot--button slot-selector__button',
            {
              'booked-slot--button--cc-link-enabled': applyCollectLinkStyleVariant
            }
          )}
          disabled={disableUnbooking}
        >
          <span className="visually-hidden">{accessibleLabel}</span>
          <span className="slot-booked">
            {updated && !showAmendSlotChangeCopy
              ? translate('slots:common.updated')
              : translate('slots:common.booked')}
          </span>
          {
            /* non-js workaround: offset an anchor from sticky header to scroll to the slot after acted upon
             1) offset class applied needed mainly in mobile slot list view,
             2) offset class is useful when a sticky modal in desktop is shown
             3) when a <span> is used here, the offset css position on it doesnt seem to be honored by browsers
             4) need a separate span as it would css offset it's content
             */
            <a
              id={slotPageAnchorTag}
              className="slot-anchor-offset-from-header"
            />
          }
        </button>
        {deliverySaverWarningArrow}
      </SafeForm>
    );
  }
}
