import React from 'react';
import classnames from 'classnames';

export type SlotGridFixedHeaderProps = {
  fixedHeader: boolean;
  headerOffset: number;
  headerAnimation: string;
  tableHeaderCells: JSX.Element[];
};

const StickySlotGridHeader = (props: SlotGridFixedHeaderProps): JSX.Element => {
  const { fixedHeader, tableHeaderCells, headerOffset, headerAnimation } = props;
  return (
    <table
      style={{
        top: `${headerOffset}px`,
      }}
      className={classnames('slot-grid__table-header--fixed', {
        animation: headerAnimation,
        slideIn: headerAnimation === 'slideIn',
        slideOut: headerAnimation === 'slideOut',
        'hide-from-view': !fixedHeader,
      })}
    >
      <thead>
        <tr>{tableHeaderCells.map(cell => cell)}</tr>
      </thead>
    </table>
  );
};

export default StickySlotGridHeader;
