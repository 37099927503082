import React, { Component } from 'react';
import PropTypes from 'prop-types';
import page from 'page';
import classnames from 'classnames';
import FilterOption from '../filter-option';
import { addFilterCategoryToUrl } from '#/lib/filter-helpers';
import { removeFilterCategoryFromUrl } from '#/utils/product-filter-utils';
import { PROMOTION, SUPERDEPARTMENT } from '#/constants/facet-categories';
import helpers from '#/lib/decorators/helpers';
import { getCurrentUrl, getCurrentPathname } from '#/reducers/app';
import { connect } from '#/lib/render/connect-deep-compare';
import { isOnFavouritesPage } from '#/lib/favorites-helpers';
import { getFavFilterNavigationVariant } from '#/experiments/oop-1662/selectors';
import { getIsFavFilterNavigationDisabled } from '#/experiments/oop-1662/helpers';
import {
  getFilterAttributes,
  getFilterLabelAndClassName,
  isTruncate
} from '#/experiments/oop-2189/selectors';

const translationKeys = {
  promotions: 'sort-and-filter:promotions'
};

function sumBinCount(facets) {
  return facets.reduce((prev, curr) => prev + curr.binCount, 0);
}

function mapStateToProps(state) {
  const isFavFilterNavigationDisabled = getIsFavFilterNavigationDisabled(
    getFavFilterNavigationVariant(state)
  );

  const currentUrl = getCurrentUrl(state);
  const isOnFavouritesPageFlag = isOnFavouritesPage(currentUrl);
  return {
    currentUrl,
    currentPathname: getCurrentPathname(state),
    showBinCount: !isOnFavouritesPageFlag,
    shouldRemoveFilters:
      isOnFavouritesPageFlag && isFavFilterNavigationDisabled,
    filterAttributes: getFilterAttributes(state)
  };
}

@helpers(['c', 't'])
@connect(mapStateToProps)
export default class PromotionsSelector extends Component {
  static propTypes = {
    ariaLabelledBy: PropTypes.string,
    c: PropTypes.func.isRequired,
    currentPathname: PropTypes.string.isRequired,
    currentUrl: PropTypes.string.isRequired,
    hasTooltip: PropTypes.bool,
    promotions: PropTypes.object.isRequired,
    renderTooltip: PropTypes.func,
    selected: PropTypes.bool.isRequired,
    shouldRemoveFilters: PropTypes.bool.isRequired,
    showBinCount: PropTypes.bool.isRequired,
    filterAttributes: PropTypes.object,
    t: PropTypes.func.isRequired
  };

  render() {
    let url;
    const {
      ariaLabelledBy,
      selected,
      currentUrl,
      currentPathname,
      hasTooltip,
      promotions,
      c: config,
      t: translate,
      showBinCount,
      shouldRemoveFilters,
      renderTooltip,
      filterAttributes
    } = this.props;

    if (selected) {
      url = removeFilterCategoryFromUrl(PROMOTION, currentUrl, currentPathname);
    } else {
      const filtersToRemove = shouldRemoveFilters ? [SUPERDEPARTMENT] : [];

      url = addFilterCategoryToUrl({
        categoryDelimiter: config('categoryDelimiter'),
        currentPathname,
        currentUrl,
        filterData: promotions,
        filterName: PROMOTION.toLowerCase(),
        filtersToRemove
      });
    }

    const numPromotions = sumBinCount(promotions.facets);
    let className = 'promotions-selector';
    let label = translate(translationKeys.promotions);

    ({ label, className } = getFilterLabelAndClassName(
      label,
      filterAttributes,
      className
    ));

    const shouldTruncateLabel = isTruncate(filterAttributes);

    return (
      <FilterOption
        className={classnames(className, {
          disabled: numPromotions === 0 && showBinCount
        })}
        shouldTruncateLabel={shouldTruncateLabel}
        count={numPromotions}
        showCount={showBinCount}
        id={'promotions-selector'}
        label={label}
        onChange={e => {
          e.preventDefault();
          /* Should call page method if either of following is true
              1. numPromotions have value greater than 0 
              2. showBinCount is false (current url is of favourties page)

              On favourites page we are not getting binCount as part of promotion filter from Mango,
              also we need to enable this filter even if we do not have binCount.
              So, need to add this check.
              // Reomve this check (showBinCount) once Mango starts returing binCount for promotion filter on favourites page.
           */
          if (numPromotions || !showBinCount) {
            page(url);
          }
        }}
        selected={selected}
        url={url}
        longLabelsAllowed={true}
        hasTooltip={hasTooltip}
        renderTooltip={renderTooltip}
        ariaLabelledBy={ariaLabelledBy}
      />
    );
  }
}
