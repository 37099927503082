/*
 The values of these constants are used in cookies.
 They should be kept short to minimise http request/response size.
 Changing values that have already been used to create cookies for users, may mean
 those users see onboarding banners they have seen and closed before.
 */

export const ONBOARDING_COOKIE_PREFIX = 'ob:';

export const ONBOARDING_BANNER_NAMES = {
  // E.g. HOMEPAGE: 'homepage'
  MULTISEARCH_BANNER: 'multiSearchOnboarding'
};

export const ONBOARDING_BANNER_STATES = {
  VISIBLE: '1',
  HIDDEN: '0'
};
