import { getCookieValue } from '#/lib/cookie/cookie-utils';

export const getReduxLogger = () => {
  if (process.env.NODE_ENV !== 'production') {
    const { createLogger } = require('redux-logger');
    return createLogger({
      logger: {
        log: function() {
          if (window.reduxLogger === 'on' || getCookieValue('reduxLogger'))
            console.log(...arguments); // eslint-disable-line no-console
        },
        group: function() {
          if (window.reduxLogger === 'on' || getCookieValue('reduxLogger'))
            console.group(...arguments); // eslint-disable-line no-console
        },
        groupCollapsed: function() {
          if (window.reduxLogger === 'on' || getCookieValue('reduxLogger'))
            console.groupCollapsed(...arguments); // eslint-disable-line no-console
        },
        groupEnd: () =>
          (window.reduxLogger === 'on' || getCookieValue('reduxLogger')) &&
          console.groupEnd(), // eslint-disable-line no-console
        trace: () =>
          (window.reduxLogger === 'on' || getCookieValue('reduxLogger')) &&
          console.trace() // eslint-disable-line no-console
      }
    });
  }
};
