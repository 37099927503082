import { useMemo } from 'react';
import { getItemPromotionLink } from '#/lib/records/item-utils';
import { formatToShortDate } from '#/lib/i18n/date-utils';

import { MISSED_DELIVERY_SLOT } from '#/constants/slot-warning-modal-types';

import analyticsBus from '#/analytics/analyticsBus';
import { basicEvent } from '#/analytics/types/basic';

export const isTrolleyPageRegEx = new RegExp(`\/trolley`, 'i');

export const isTrolleyPageByUrl = currentUrl => {
  const currentUrlSplit = currentUrl.split('?');
  const testableUrl = currentUrlSplit[0];
  return isTrolleyPageRegEx.test(testableUrl);
};

export const getPromotionValidText = (
  promotion,
  timezone,
  language,
  translation,
  config
) => {
  const start = formatToShortDate(promotion.startDate, timezone, language);
  const time = formatToShortDate(promotion.endDate, timezone, language);
  const shouldTranslateStart = start && config('showPromotionStartDate');

  return translation(
    'product-tile:valid-until',
    shouldTranslateStart ? { start, time } : { time }
  );
};

export const canShowPromotionsText = (showDatesOnlyOnMissedSlot, warnings) => {
  const hasValidDeliverySlot = !(
    warnings && warnings.warningType === MISSED_DELIVERY_SLOT
  );

  return !(showDatesOnlyOnMissedSlot && hasValidDeliverySlot);
};

export const makeHandleClick = tile => () =>
  basicEvent(analyticsBus, {
    type: 'hopos',
    value: tile,
    action: 'delay'
  });

export const useProductPromotions = (
  promotion,
  offersIcid,
  timezone,
  language,
  translation,
  config,
  showDatesOnlyOnMissedSlot,
  warnings,
  tile,
  currentUrl,
  showPromotionInfoBox,
  makeLanguageLink
) => {
  const promotionsLink = useMemo(
    () => makeLanguageLink(getItemPromotionLink(promotion, offersIcid)),
    [promotion.id, offersIcid]
  );

  const promotionsText = useMemo(
    () =>
      canShowPromotionsText(showDatesOnlyOnMissedSlot, warnings)
        ? getPromotionValidText(
            promotion,
            timezone,
            language,
            translation,
            config
          )
        : null,
    [showDatesOnlyOnMissedSlot, warnings]
  );

  const handleClick = makeHandleClick(tile);

  const isTrolleyPage = isTrolleyPageByUrl(currentUrl);

  const missedPromotion = promotion.missedPromotion && showPromotionInfoBox;

  return {
    isTrolleyPage,
    missedPromotion,
    promotionsLink,
    promotionsText,
    handleClick
  };
};
